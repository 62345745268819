import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import axios from "axios";
import { Link, Redirect } from "react-router-dom";

class Checkpw extends Component {
  constructor(props) {
    super(props);

    this.state = {
      m: 0,
      agentId: "",
      otp: "",
      newPassword: "",
      confirmPassword: "",
      goodtogo: false,
    };
  }

  componentDidMount() {
    localStorage.clear();
  }

  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };

  handleEmail_mob = (e) => {
    $(".error").hide();
    this.setState({
      agentId: e.target.value,
    });
  };

  handleotp = (e) => {
    e.preventDefault();
    this.setState({
      otp: e.target.value,
    });
  };

  handleNewPassword = (e) => {
    e.preventDefault();
    this.setState({
      newPassword: e.target.value,
    });
  };

  handleConfirmPassword = (e) => {
    e.preventDefault();
    this.setState({
      confirmPassword: e.target.value,
    });
  };

  showNewPassword = (e) => {
    e.preventDefault();
    const { m } = this.state;
    if (m == 0) {
      $("#new_pss").attr("type", "text");
      $("#btn_pass2").css({
        background: '#fff url("/img/hidden.svg")',
        "background-repeat": "no-repeat",
        "background-size": "22px 22px",
        "background-position": "center",
      });
      this.setState({
        m: 1,
      });
    } else {
      $("#new_pss").attr("type", "password");
      $("#btn_pass2").css({
        background: '#fff url("/img/show.svg")',
        "background-repeat": "no-repeat",
        "background-size": "22px 22px",
        "background-position": "center",
      });
      this.setState({
        m: 0,
      });
    }
  };

  showConfirmPassword = (e) => {
    e.preventDefault();
    const { m } = this.state;
    if (m === 0) {
      $("#confirm_pss").attr("type", "text");
      $("#btn_pass1").css({
        background: '#fff url("/img/hidden.svg")',
        "background-repeat": "no-repeat",
        "background-size": "22px 22px",
        "background-position": "center",
      });
      this.setState({
        m: 1,
      });
    } else {
      $("#confirm_pss").attr("type", "password");
      $("#btn_pass1").css({
        background: '#fff url("/img/show.svg")',
        "background-repeat": "no-repeat",
        "background-size": "22px 22px",
        "background-position": "center",
      });
      this.setState({
        m: 0,
      });
    }
  };

  handleSubmitbtn = (e) => {
    e.preventDefault();
    if (this.state.agentId.trim() == "") {
      $(".error").show();
      $(".error").html("Required fields cannot be empty!");
    } else {
      $("#submit_btn").hide();
      $(".submit-loader-gif").show();
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=forgetpassword",
          {
            agentId: this.state.agentId,
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          
          if (res.data.code == 200 && res.data.status == "success") {
            $(".submit-loader-gif").hide();
            $("#page_1_cpw").hide();
            $("#page_2_cpw").show();
            this.controlOtpTimer();
          } else if (res.data.code == 201 && res.data.status == "invalid") {
            $("#submit_btn").show();
            $(".submit-loader-gif").hide();
            this.showNotification(res.data.message);
          } else if (res.data.code == 201 && res.data.status == "notexist") {
            $("#submit_btn").show();
            $(".submit-loader-gif").hide();
            this.showNotification(res.data.message);
          } else if (res.data.code == 201 && res.data.status == "alreadysent") {
            $("#submit_btn").show();
            $(".submit-loader-gif").hide();
            this.showNotification(res.data.message);
          } else if (res.data.code == 201 && res.data.status == "fail") {
            $("#submit_btn").show();
            $(".submit-loader-gif").hide();
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          this.showNotification("Something went wrong");
        });
    }
  };

  handleOtpSubmitbtn = () => {
    if (
      this.state.otp.trim() == "" ||
      this.state.newPassword.trim() == "" ||
      this.state.confirmPassword.trim() == ""
    ) {
      $(".error_cpw").show();
      $(".error_cpw").css("color", "red");
      $(".error_cpw").html("Required fields cannot be empty!");
    } else if (this.state.otp.trim().length != 6) {
      $(".error_cpw").show();
      $(".error_cpw").html("Invalid OTP");
    } else if (
      this.state.newPassword.length < 8 ||
      this.state.newPassword.length > 21
    ) {
      $(".error_cpw").show();
      $(".error_cpw").css("color", "red");
      $(".error_cpw").html("Password must be between 8 to 21 letters");
    } else if (this.state.newPassword != this.state.confirmPassword) {
      $(".error_cpw").show();
      $(".error_cpw").css("color", "red");
      $(".error_cpw").html("Passwords don't match! Try again");
    } else {
      $(".error_cpw").hide();
      $(".resend_cpw").attr("disabled", true);
      $(".next_cpw").hide();
      $(".resend_cpw_t").hide();
      $(".submit-loader-gif").show();
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=resetpasswordweb",
          {
            agentId: this.state.agentId,
            resetotp: this.state.otp,
            password: this.state.newPassword,
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          
          if (res.data.code == 200 && res.data.status == "success") {
            this.props.history.push(`/?faccess=${this.state.agentId}`);
          } else if (res.data.code == 201 && res.data.status == "invalidotp") {
            $(".error_cpw").show();
            $(".error_cpw").css("color", "red");
            $(".error_cpw").html(res.data.message);
            $(".resend_cpw").attr("disabled", false);
            $(".next_cpw").show();
            $(".submit-loader-gif").hide();
          } else if (res.data.code == 201 && res.data.status == "fail") {
            $(".error_cpw").show();
            $(".error_cpw").css("color", "red");
            $(".error_cpw").html(res.data.message);
            $(".resend_cpw").attr("disabled", false);
            $(".next_cpw").show();
            $(".submit-loader-gif").hide();
          }
        })
        .catch((err) => {
          this.showNotification("Something went wrong");
        });
    }
  };

  controlOtpTimer = () => {
    $(".error_cpw").show();
    $(".resend_cpw_t").show();
    $(".error_cpw").css("color", "green");
    $(".error_cpw").html("OTP sent");
    var counter = 60;
    $(".resend_cpw").attr("disabled", true);
    var interval = setInterval(function () {
      counter--;
      if (counter <= 0) {
        clearInterval(interval);
        $(".resend_cpwtimer").attr("disabled", false);
        $(".resend_cpw_t").hide();
        $(".resend_cpw").attr("disabled", false);
        $(".login_btnverify").attr("disabled", false);
        return;
      } else {
        $(".login_btnverify").attr("disabled", true);
        $(".resend_cpwtimer").text(counter + "s");
      }
    }, 1000);
  };

  handleResend = (e) => {
    $(".error_cpw").hide();
    $(".resend_cpwtimer").hide();
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=resendpassotp",
        {
          agentId: this.state.agentId,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        
        $(".resend_cpwtimer").show();

        if (res.data.code == 200 && res.data.status == "success") {
          this.controlOtpTimer();
          this.showNotification(res.data.message);
        } else if (res.data.code == 201 && res.data.status == "fail") {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something went wrong");
      });
  };

  render() {
    return (
      <div className="phone">
        <Helmet>
          <link
            rel="stylesheet"
            href={process.env.PUBLIC_URL + "/css/setPassword.css"}
          />
          <link
            rel="stylesheet"
            href={process.env.PUBLIC_URL + "/css/loginWithPss.css"}
          />
          <link
            rel="stylesheet"
            href={process.env.PUBLIC_URL + "/css/verifyUrself.css"}
          />
          <link
            rel="stylesheet"
            href={process.env.PUBLIC_URL + "/css/loginL.css"}
          />
        </Helmet>
        <div id="notifContainer"></div>
        <div className="row pd1 pc">
          <div className="col-xs-7 cl7 left-div">
            <center className="cnt_margin">
              <a href="https://legal251.com/">
                <img
                  src={process.env.PUBLIC_URL + "/img/Legal_logo_full.svg"}
                  className="legal_logo"
                  alt="legal logo"
                />
              </a>
              <img
                src={process.env.PUBLIC_URL + "/img/cpw_sideimg.svg"}
                className="login_img"
                alt="image_"
              />
            </center>
          </div>
          <div className="col-xs-5 bg-2 d-md-flex justify-content-center align-items-center">
            <center>
              {/* <!-- legal logo --> */}
              <a href="https://legal251.com/">
                <img
                  src={process.env.PUBLIC_URL + "/img/Legal_logo_full.svg"}
                  className="legal_logo mobile-res-data"
                  alt="legal logo"
                />
              </a>
              <div className="y-box">
                <div id="page_1_cpw">
                  <h1 className="pass_txt">Forgot Password</h1>
                  <div className="pass_div_inner_div">
                    <div>
                      <p className="cpw_text text-left">
                        Enter your Agent Id.:
                      </p>
                      <div className="input-group ">
                        <p className="border_txt">Partner ID</p>
                        <input
                          type="text"
                          className="form-control inp_l"
                          maxLength="50"
                          placeholder="Type here..."
                          id="email_mob"
                          value={this.state.agentId}
                          onChange={this.handleEmail_mob}
                        />
                      </div>
                      <div className="error_div1">
                        <p className="error text-center">
                          This is the demo error message
                        </p>
                      </div>
                    </div>

                    <center>
                      <button
                        className="next btn_timer"
                        id="submit_btn"
                        onClick={this.handleSubmitbtn}
                      >
                        Next
                      </button>
                      <img
                        className="submit-loader-gif"
                        src={process.env.PUBLIC_URL + "/img/loader.gif"}
                        alt="loader"
                      />
                    </center>
                  </div>
                </div>

                <div id="page_2_cpw">
                  <p className="pass_txtverify">Forgot Password </p>
                  <center>
                    <div>
                      <p className="cpw_text text-center">
                        Please enter the OTP with new password
                      </p>

                      <div className="input-group border_cpw">
                        <p className="border_txtm">Partner ID</p>
                        <input
                          className="form-control inp_l3"
                          readOnly
                          id="mobile_number"
                          value={this.state.agentId.toUpperCase()}
                        />
                      </div>

                      {/* <!-- otp --> */}
                      <div className="input-group border_cpw">
                        <p className="border_txtotp"> OTP</p>
                        <input
                          type="tel"
                          className="form-control inp_lotp"
                          maxLength="6"
                          minLength="6"
                          placeholder="Enter OTP..."
                          id="otp"
                          value={this.state.otp}
                          onChange={this.handleotp}
                        />
                      </div>

                      <div className="input-group border_cpw">
                        <p className="border_txtp"> New password</p>
                        <input
                          type="password"
                          className="form-control inp_l2"
                          id="new_pss"
                          maxLength="21"
                          placeholder="**********"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          value={this.state.newPassword}
                          onChange={this.handleNewPassword}
                        />
                        <div className="input-group-append">
                          <button
                            className="login_btnp"
                            id="btn_pass2"
                            type="button"
                            onClick={this.showNewPassword}
                          ></button>
                        </div>
                      </div>

                      <div className="input-group border_cpwl">
                        <p className="border_txtpss"> Confirm password</p>
                        <input
                          type="password"
                          className="form-control inp_l2c"
                          maxLength="21"
                          id="confirm_pss"
                          placeholder="**********"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          value={this.state.confirmPassword}
                          onChange={this.handleConfirmPassword}
                        />
                        <div className="input-group-append">
                          <button
                            className="login_btnp"
                            id="btn_pass1"
                            type="button"
                            onClick={this.showConfirmPassword}
                          ></button>
                        </div>
                      </div>
                    </div>
                    <div>
                      <button
                        className="resend_cpw"
                        onClick={this.handleResend}
                      >
                        Re-send
                      </button>
                      <p className="resend_cpw_t">
                        Re-send OTP in{" "}
                        <span className="resend_cpwtimer">
                          30<span>s</span>
                        </span>
                      </p>
                      <p className="error_cpw">This is demo error message</p>
                    </div>
                  </center>

                  {/* <!-- next --> */}
                  <center className="my-1">
                    {" "}
                    <button
                      className="next_cpw"
                      onClick={this.handleOtpSubmitbtn}
                    >
                      Submit
                    </button>
                    <img
                      className="submit-loader-gif"
                      src={process.env.PUBLIC_URL + "/img/loader.gif"}
                      alt="loader"
                    />
                  </center>
                </div>
              </div>

              {/* <!-- legal image --> */}
              <img
                src={process.env.PUBLIC_URL + "/img/cpw_sideimg.svg"}
                className="login_img mobile-res-data"
                alt="image_"
              />
            </center>
          </div>
        </div>
      </div>
    );
  }
}

export default Checkpw;

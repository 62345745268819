import React, { Component } from "react";
import axios from "axios";
import $ from "jquery";
import { Redirect, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { createBrowserHistory } from "history";
import emmitersdfsd from "../eventEmtte";
var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);
let Decuserid;
let Decagentid;
let Decjwttoken;
let mainDecagenttype;
let DecmainsideMenuAccess;

const history = createBrowserHistory();
let emmiter = new emmitersdfsd();
class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notificationarray: [],
      notiusername: "",
      notiemail: "",
      notiecontact: "",
      notieuserid: "",
      m: 0,
      logout: false,
      walletAmount: "-",
      supportName: "",
      supportContact: "",
      supportEmail: "",
      rejectreason: "",
      rejectother: "",
    };
  }

  handlenotiprofilewapi = () => {
    axios({
      method: "post",
      url: process.env.REACT_APP_API_BASE_URL + "p=notificationProfile",
      data: {
        agentId: Decagentid,
      },
      headers: {
        AuthKey: process.env.REACT_APP_API_KEY,
        "App-Token-Access": Decjwttoken,
      },
      auth: {
        username: process.env.REACT_APP_API_USERNAME,
        password: process.env.REACT_APP_API_PASSWORD,
      },
    })
      .then((resp) => {
        if (resp.data.code == 200 && resp.data.status == "success") {
          this.setState({
            notificationarray: resp.data.notifications,
            notiusername: resp.data.username,
            notiemail: resp.data.email,
            notiecontact: resp.data.contact,
            notieuserid: resp.data.AgentId,
            walletAmount: resp.data.remainingAmount,

            supportContact: resp.data.supportContact,
            supportEmail: resp.data.supportEmail,
          });
          localStorage.setItem("supportName", resp.data.supportName);
          localStorage.setItem("supportContact", resp.data.supportContact);
          localStorage.setItem("supportEmail", resp.data.supportEmail);
        } else if (
          resp.data.code == "201" &&
          resp.data.status == "invalidauth"
        ) {
          this.logout();
        }
      })
      .catch((error) => {});
  };
  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };
  componentDidMount() {
    let mainuserid = localStorage.getItem("relleIsugad");
    Decuserid = encryptor.decrypt(mainuserid);
    let mainagentid = localStorage.getItem("qazxswedcvfrtgb");
    Decagentid = encryptor.decrypt(mainagentid);
    let mainjwttoken = localStorage.getItem("toeljgtkewlna");
    Decjwttoken = encryptor.decrypt(mainjwttoken);
    mainDecagenttype = localStorage.getItem("qwedfgewerewrwer");
    let mainsideMenuAccess = localStorage.getItem("sideMenuAccess");
    DecmainsideMenuAccess = encryptor.decrypt(mainsideMenuAccess);

    if (mainDecagenttype != "") {
      $(".nictlogo").show();
    }

    emmiter.on("click", (data) => {
      // console.log("dfs", data);
    });

    // emmiter.dispatch("cartCount", () => {
    //   console.log("sdfsdfsdf",sessionStorage.getItem("data"));
    // })

    this.handlenotiprofilewapi();
  }
  DeleteAccount = () => {
    if (this.state.rejectreason.trim() == "") {
      $(".bterr").show();
      $(".bterr").text("Please select reason");
      $(".rejectresblack").css("border", "1px solid black");
      $(".rejectrred").css("border", "1px solid red");
    } else if (
      this.state.rejectreason == "other" &&
      this.state.rejectother == ""
    ) {
      $(".bterr").show();
      $(".bterr").text("Please enter reason");
      $(".rejectresblack").css("border", "1px solid black");
      $(".rejectresdiv").css("border", "1px solid red");
    } else {
      $(".sendOtpLoader").show();
      $("#submitOtpbtn").hide();
      axios({
        method: "post",
        url: process.env.REACT_APP_API_BASE_URL + "p=deleteAgentAccount",
        data: {
          agentId: Decagentid,
          deleteReason: this.state.rejectreason,
          otherReason: this.state.rejectother,
        },
        headers: {
          AuthKey: process.env.REACT_APP_API_KEY,
          "App-Token-Access": Decjwttoken,
        },
        auth: {
          username: process.env.REACT_APP_API_USERNAME,
          password: process.env.REACT_APP_API_PASSWORD,
        },
      })
        .then((res) => {
          if (res.data.code == 200 && res.data.status == "success") {
            $("#deleteAccountconfirm").modal("hide");
            $(".sendOtpBtn").hide();

            this.showNotification(res.data.message);
            this.setState({
              rejectreason: "",
              rejectother: "",
            });
            this.logout();
          } else if (res.data.code == 201 && res.data.status == "notfound") {
            $(".sendOtpLoader").hide();
            $("#submitOtpbtn").show();
            this.showNotification(res.data.message);
          } else if (
            res.data.code == 201 &&
            res.data.status == "alreadyexist"
          ) {
            $(".sendOtpLoader").hide();
            $("#submitOtpbtn").show();
            // this.showNotification(res.data.message);
            this.showNotification(res.data.message);
          } else if (
            res.data.code == 201 &&
            res.data.status == "unverifiedAccount"
          ) {
            $(".sendOtpLoader").hide();
            $("#submitOtpbtn").show();
            this.showNotification(res.data.message);
          } else if (res.data.code == 201 && res.data.status == "fail") {
            // this.showNotification(res.data.message);
            $(".sendOtpLoader").hide();
            $("#submitOtpbtn").show();
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            $(".sendOtpLoader").hide();
            $("#submitOtpbtn").show();
            this.logout();
          } else if (res.data.code == 203 && res.data.status == "sessionend") {
            $(".sendOtpLoader").hide();
            $("#submitOtpbtn").show();
            this.logout();
          }
        })
        .catch((err) => {
          $("#myuser-loader").hide();
          this.showNotification("Something went wrong");
        });
    }
  };
  handlerejectrea = (e) => {
    this.setState(
      {
        rejectreason: e.target.value,
      },
      () => {
        if (this.state.rejectreason == "other") {
          $(".rejectother").css("display", "block");
        } else {
          $(".rejectother").css("display", "none");
        }
      }
    );
  };
  sidebaropen = () => {
    if (this.state.m == 0) {
      $("#sidebarp").addClass("active");
      $(".overlay").addClass("active");
      $(".collapse.in").toggleClass("in");
      $("#help").addClass("sidebar_footer");
      this.setState({
        m: 1,
      });
    } else {
      $("#sidebarp").removeClass("active");
      $(".overlay").removeClass("active");
      $(".collapse.in").toggleClass("in");
      $("#help").removeClass("sidebar_footer");
      this.setState({
        m: 0,
      });
    }
  };

  sidebarclose = () => {
    $("#sidebarp").removeClass("active");
    $(".overlay").removeClass("active");
    $("#help").removeClass("sidebar_footer");
    this.setState({
      m: 0,
    });
  };
  logout = () => {
    localStorage.removeItem("gglltakoinoeenl");
    localStorage.removeItem("toeljgtkewlna");
    localStorage.removeItem("qazxswedcvfrtgb");
    this.setState({
      logout: true,
    });
  };

  rendernotiData() {
    return this.state.notificationarray.map((activedoc, index) => {
      return (
        <li key={index}>
          <div className="displayflex top-text-block">
            <div className="imgnotidiv">
              <center>
                <img
                  src={process.env.PUBLIC_URL + "/img/user.jpg"}
                  className="user_img"
                  alt="user"
                />
              </center>
            </div>
            <div className="contnotidiv">
              <div className="top-text-heading">{activedoc.notification}</div>
              <div className="top-text-light">
                {activedoc.datetime} &nbsp;&nbsp;from {activedoc.from}
              </div>
            </div>
          </div>
        </li>
      );
    });
  }

  showWallet = () => {
    this.props.history.push({ pathname: "/commission" });
  };

  render() {
    if (this.state.logout == true) {
      return <Redirect to="/" />;
    }
    return (
      <>
        <div className="overlay" onClick={this.sidebarclose}></div>
        <div>
          <nav className="navbar navbar-expand-lg nav_p" id="navv">
            <button
              type="button"
              id="sidebarCollapse"
              onClick={this.sidebaropen}
            >
              <i className="fas fa-align-left"></i>
              <span>
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/menu.svg"}
                />
              </span>
            </button>

            <Link to="/dashboard" className="navbar-brand nav_brnd">
              {" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Legal_logo_full.svg"}
                id="pc"
                alt="legal251 logo"
                className="lgl_logo"
              />{" "}
            </Link>

            <Link to="/cart" className="hideonres mr-3">
              <button className="headercartbtn">
                <img
                  src={process.env.PUBLIC_URL + "/img/Path_24327.svg"}
                  alt="cart"
                  className="header_cart_img"
                />
                <span className="total_cart_item">
                  {/* {
                  sessionStorage.getItem("data").length
                  } */}

                  {sessionStorage.getItem("data") == null ||
                  sessionStorage.getItem("data") == undefined ||
                  sessionStorage.getItem("data") == "" ? (
                    0
                  ) : (
                    <>{JSON.parse(sessionStorage.getItem("data")).length}</>
                  )}
                </span>
              </button>
            </Link>

            <button className="search_btn_p"></button>
            <input
              className="form-control search_open"
              type="text"
              disabled
              placeholder="Search"
            />

            <button className="logout_btn_p" onClick={this.logout}>
              <img
                src={process.env.PUBLIC_URL + "/img/logout.svg"}
                alt="logout"
              />
            </button>

            <div className="btn-group pull-right top-head-dropdown">
              <button
                type="button"
                className="noti_btn_p"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  src={process.env.PUBLIC_URL + "/img/noti_bell.svg"}
                  alt="Notification bell"
                />
              </button>
              <ul className="dropdown-menu dropdown-menu-right">
                <div className="notificationsheader">
                  <li className="text-center drop_noti_txt">Notifications</li>
                </div>
                <div className="scroll1">{this.rendernotiData()}</div>
              </ul>
            </div>

            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <div className="navbar-nav nav_n">
                <div className="nav-item active nav_item1">
                  <div className="dropdown">
                    <button
                      className="nav-link profile_btn"
                      data-toggle="dropdown"
                      type="button"
                      id="dropdownMenuButton"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Profile
                    </button>
                    <div
                      className="dropdown-menu  dropdown_1"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <div className="row row_1">
                        <div className="col-md-4">
                          <p className="dropdown-item dropdown_txt">
                            Full name:{" "}
                          </p>
                        </div>
                        <div className="col-md-8">
                          <p
                            className="dropdown-item dropdown_txtb"
                            title={this.state.notiusername}
                          >
                            {this.state.notiusername.substring(0, 24)}
                            {this.state.notiusername.length > 24 && "..."}
                          </p>
                        </div>
                      </div>
                      <div className="border_row"></div>
                      <div className="row row_1">
                        <div className="col-md-4">
                          <p className="dropdown-item dropdown_txt">
                            Email ID:
                          </p>
                        </div>
                        <div className="col-md-8">
                          <p
                            className="dropdown-item dropdown_txtb"
                            title={this.state.notiemail}
                          >
                            {this.state.notiemail.substring(0, 24)}
                            {this.state.notiemail.length > 24 && "..."}
                          </p>
                        </div>
                      </div>
                      <div className="border_row"></div>
                      <div className="row row_1">
                        <div className="col-md-4">
                          <p className="dropdown-item dropdown_txt">
                            Mobile No.:
                          </p>
                        </div>
                        <div className="col-md-8">
                          <p className="dropdown-item dropdown_txtb">
                            {this.state.notiecontact.substring(0, 24)}
                            {this.state.notiecontact.length > 24 && "..."}
                          </p>
                        </div>
                      </div>
                      <div className="border_row"></div>
                      <div className="row row_1">
                        <div className="col-md-4">
                          <p className="dropdown-item dropdown_txt">
                            Customer ID:
                          </p>
                        </div>
                        <div className="col-md-8">
                          <p className="dropdown-item dropdown_txtb">
                            {" "}
                            {this.state.notieuserid.substring(0, 24)}
                            {this.state.notieuserid.length > 24 && "..."}
                          </p>
                        </div>
                      </div>
                      <div className="border_row"></div>

                      <div className="row row_1 justify-content-center">
                        <div className="">
                          <Link to="/profile" className="">
                            <button className="btn dropdown_txt">
                              View Complete Profile{" "}
                              <img
                                className="ml-1"
                                width={"13px"}
                                src={
                                  process.env.PUBLIC_URL +
                                  "/img/viewProfile.svg"
                                }
                                alt="img"
                              />
                            </button>
                          </Link>
                        </div>
                      </div>
                      <div className="border_row"></div>

                      <div className="row row_1  row_2 justify-content-center">
                        <div className="">
                          {/* <Link to="/profile" className=""> */}
                          <button
                            className="btn ml-auto agentlinkuser-btn fs-5 mt-1 mb-1"
                            onClick={() => {
                              $("#deleteAccount").modal("show");
                            }}
                          >
                            Delete Account{" "}
                            {/* <img
                              className="ml-1"
                              width={"13px"}
                              src={
                                process.env.PUBLIC_URL + "/img/viewProfile.svg"
                              }
                              alt="img"
                            /> */}
                          </button>
                          {/* </Link> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="nav-item nav_service">
                  <Link
                    to="/addservice"
                    className="nav-link service_btn"
                    href="#"
                  >
                    &nbsp;&nbsp; Buy service
                  </Link>
                </div>

                <img src={mainDecagenttype} alt="call" className="nictlogo" />

                <div className="nav-item nav_search">
                  <input
                    className="form-control search_inp"
                    type="text"
                    disabled
                    placeholder="Search"
                  />
                </div>
                <Link to="/cart" className="hihecard_d mr-3">
                  <button className="headercartbtn">
                    <img
                      src={process.env.PUBLIC_URL + "/img/Path_24327.svg"}
                      alt="cart"
                      className="header_cart_img"
                    />
                    <span className="total_cart_item">
                      {/* {
                  sessionStorage.getItem("data").length
                  } */}

                      {sessionStorage.getItem("data") == null ||
                      sessionStorage.getItem("data") == undefined ||
                      sessionStorage.getItem("data") == "" ? (
                        0
                      ) : (
                        <>{JSON.parse(sessionStorage.getItem("data")).length}</>
                      )}
                    </span>
                  </button>
                </Link>
                <div className="d-flex justify-content-center align-items-center mr-2 wallet-btn">
                  <Link
                    className="d-flex"
                    to={{ pathname: "/commission", state: { type: "wallet" } }}
                  >
                    {" "}
                    <img
                      src={process.env.PUBLIC_URL + "/img/wallet-solid.svg"}
                      alt="call"
                      className="callimg mr-3"
                    />
                    <p className="m-0 wallet-amount">
                      ₹. {this.state.walletAmount}
                    </p>{" "}
                  </Link>
                </div>
                {/* <div className="nav-item nav_service">
                               <p className="m-0 wallet-amount wallet-btn">₹. {this.state.walletAmount}</p>
                </div> */}
                <div className="nav-item nav_img d-flex justify-content-center align-items-center">
                  <a
                    href="https://web.whatsapp.com/send?phone=917772877729&amp;text=Hello%20Team%20Legal251!"
                    target="_blank"
                  >
                    {" "}
                    <img
                      src={process.env.PUBLIC_URL + "/img/call.svg"}
                      alt="call"
                      className="callimg"
                    />
                  </a>
                </div>
                <div className="nav-item nav_number d-flex justify-content-center align-items-center">
                  <a
                    href="tel:7772877729"
                    target="_blank"
                    className="phone_number"
                  >
                    {" "}
                    <img
                      src={process.env.PUBLIC_URL + "/img/phone.svg"}
                      alt="phone"
                    />{" "}
                    (+91) 77728 77729{" "}
                  </a>
                </div>
                <div className="nav-item nav_notif d-flex justify-content-center align-items-center">
                  <div className="btn-group pull-right top-head-dropdown">
                    <button
                      type="button"
                      className="noti"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Notification{" "}
                      <img
                        src={process.env.PUBLIC_URL + "/img/noti_bell.svg"}
                        alt="Notification bell"
                      />
                    </button>

                    <ul className="dropdown-menu dropdown-menu-right ">
                      <div className="notificationsheader text-center drop_noti_txt1">
                        Notifications
                      </div>
                      <div className="scroll1">{this.rendernotiData()}</div>
                    </ul>
                  </div>
                </div>

                <div className="nav-item nav_logout d-flex justify-content-center align-items-center">
                  <button className="logout" onClick={this.logout}>
                    {" "}
                    Logout{" "}
                    <img
                      src={process.env.PUBLIC_URL + "/img/logout.svg"}
                      alt="Logout"
                    />{" "}
                  </button>
                </div>
              </div>
            </div>
          </nav>

          <nav id="sidebarp" className="sidebar1">
            <div className="phone_sidebar">
              <div className="side_div_7 active">
                <img
                  src={process.env.PUBLIC_URL + "/img/legalwhitelogo.svg"}
                  className="legal_phone"
                  id="phone"
                  alt="legal251 logo"
                />
              </div>
              <div className="side_div_4 sidebar_txt active">
                <Link to="/dashboard">Overview</Link>
              </div>
              <div className="side_div_4 sidebar_txt">
                {" "}
                <Link to="/myuser">My Users</Link>
              </div>
              <div className="side_div_4 sidebar_txt">
                {" "}
                <Link to="/paymentinvoices">User Invoices / Payments</Link>
              </div>
              <div className="side_div_4 sidebar_txt">
                <Link to="/documentrecords">Documents Records</Link>
              </div>
              <div className="side_div_4 sidebar_txt">
                <Link to="/addservice">Add service</Link>
              </div>
              <div className="side_div_4 sidebar_txt">
                <Link to="/agentservices">Agent Services</Link>
              </div>
              <div className="side_div_4 sidebar_txt">
                <Link to="/commission">My Commission</Link>
              </div>
              <div className="side_div_4 sidebar_txt">
                <Link to="/support">Support</Link>
              </div>
              <div className="side_div_4 sidebar_txt">
                <Link to="/materials">Materials</Link>
              </div>
              <div className="side_div_4 sidebar_txt">
                <Link to="/profile">Profile</Link>
              </div>
              {
          DecmainsideMenuAccess == "RP" ? 
              <div className="side_div_1 sidebar_txt">
                <Link to="/referral-lead">Lead</Link>
              </div> : <></> }
              <div className="dropdown-divider drop_margin"></div>
              <div className="d-flex justify-content-center align-items-center mr-2 wallet-btn ml-2 py-1">
                <img
                  src={process.env.PUBLIC_URL + "/img/wallet-solid.svg"}
                  alt="call"
                  className="callimg mr-3"
                />
                <p className="m-0 wallet-amount text-dark">
                  ₹. {this.state.walletAmount}
                </p>
              </div>
              <div className="">
                <div className="side_div_6 sidebar_txt " id="help">
                  <a href="https://legal251.com/contact" target="_blank">
                    Help{" "}
                    <img
                      src={process.env.PUBLIC_URL + "/img/Path 181.svg"}
                      alt="help"
                    />
                  </a>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div
          className="modal fade"
          id="deleteAccount"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content back-modal">
              <div className="">
                <center style={{ marginTop: "20px" }}>
                  <h3 className="additemmodalhead text-dark" id="">
                    Are you sure you want to
                  </h3>
                  <h3
                    className="additemmodalhead text-danger"
                    id=""
                    style={{ color: "hsl(359.68deg 100% 63.73%)" }}
                  >
                    <img
                      src="/img/danger_1.svg"
                      style={{ paddingRight: "15px", width: "32px" }}
                    />
                    Delete your Account ?
                  </h3>
                  <p className="px-3" style={{ color: "rgb(102 102 102)" }}>
                    The action you are performing is not reversible. After
                    deleting If you want to recover your Account than please
                    contact us at{" "}
                    <span
                      className="font-weight-bold"
                      style={{ color: "rgb(35 61 82)" }}
                    >
                      (+91)7728-7729
                    </span>{" "}
                    or email us:
                    <a
                      href="support@legal251.com"
                      className="font-weight-bold"
                      style={{ color: "rgb(35 61 82)" }}
                    >
                      support@legal251.com{" "}
                    </a>
                    within 7 days of deleting the account.
                  </p>
                </center>
                <div
                  className="d-flex px-3"
                  style={{ paddingTop: "15px", border: "2px dotted darkgrey" }}
                >
                  <span>Note: </span>
                  <p className="" style={{ color: "rgb(102 102 102)" }}>
                    Make sure that you downloaded your all documents before
                    deleting the account.
                  </p>
                </div>

                <div className="col-lg-12 col-12 ">
                  <fieldset className=" my-fieldset agent-left text-left rejectrred rejectresblack">
                    <legend className="agent-legend ">
                      &nbsp;Delete Reason&nbsp;
                    </legend>
                    <select
                      className="agent-input "
                      value={this.state.rejectreason}
                      onChange={(e) => {
                        this.handlerejectrea(e);
                      }}
                    >
                      <option value="" className="selectcateselectoption2">
                        Select Delete reason
                      </option>
                      <option
                        value=" I didn't find it useful"
                        className="selectcateselectoption2"
                      >
                        I didn't find it useful
                      </option>
                      <option
                        value="Created a second account"
                        className="selectcateselectoption2"
                      >
                        Created a second account
                      </option>
                      <option
                        value=" Privacy concerns"
                        className="selectcateselectoption2"
                      >
                        Privacy concerns
                      </option>
                      <option
                        value="Want to remove something"
                        className="selectcateselectoption2"
                      >
                        Want to remove something
                      </option>

                      <option value="other" className="selectcateselectoption2">
                        Other
                      </option>
                    </select>
                  </fieldset>
                </div>

                {this.state.rejectreason == "other" ? (
                  <div className="col-lg-12 col-12 ">
                    <fieldset className=" my-fieldset my-fieldset1 agent-left text-left rejectresdiv rejectresblack">
                      <legend
                        className={
                          this.state.rejectreason == "other"
                            ? "agent-legend"
                            : "agent-legend1"
                        }
                      >
                        &nbsp;Enter Reason&nbsp;
                      </legend>
                      <textarea
                        className="agent-input rejectres"
                        value={this.state.rejectother}
                        maxLength="250"
                        onChange={(e) => {
                          this.setState({
                            rejectother: e.target.value,
                          });
                        }}
                        placeholder="Please enter reason"
                        type="text"
                      />
                    </fieldset>
                  </div>
                ) : null}
              </div>
              <div className="delmodalbtndiv">
                <div className="mobres">
                  <button
                    className="conformdeletebtn"
                    onClick={() => {
                      $("#deleteAccountconfirm").modal("show");
                      $("#deleteAccount").modal("hide");
                    }}
                    style={{ backgroundColor: "hsl(359.68deg 100% 63.73%)" }}
                  >
                    Delete Account
                  </button>
                </div>
                <div className="mobres">
                  <button
                    className="conformdeletebtn px-5 bg-white text-dark border"
                    onClick={() => {
                      $("#deleteAccount").modal("hide");
                      this.setState({
                        rejectreason: "",
                        rejectother: "",
                      });
                      // setnewCustomerId("");
                      // setnewBusinessId("");
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="deleteAccountconfirm"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content back-modal">
              <div className="">
                <div className="w-100 d-flex">
                  <button
                    type="button"
                    className="border-0 bg-transparent ml-auto p-2 pr-3  text-right my-back-modal-close"
                    data-dismiss="modal"
                    onClick={() => {
                      $("#deleteAccountconfirm").hide();
                    }}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/img/Page-1.svg"}
                      width="17px"
                    />
                  </button>
                </div>
                <center>
                  <h4 className="additemmodalhead text-dark" id="">
                    Please confirm again
                  </h4>
                  <h4
                    className="additemmodalhead text-danger mb-3"
                    id=""
                    style={{ color: "hsl(359.68deg 100% 63.73%)" }}
                  >
                    <span className="text-dark">to</span> Delete your Account ?
                  </h4>
                  <p className="px-3" style={{ color: "rgb(102 102 102)" }}>
                    <span>Note: </span>Make sure that you downloaded your all
                    documents before deleting the account.
                  </p>
                </center>
                <center>
                  <p className="bterr warn"></p>
                </center>
              </div>
              <div className="delmodalbtndiv">
                <button
                  type="button"
                  className="conformdeletebtn"
                  onClick={() => {
                    this.DeleteAccount();
                  }}
                  id="submitOtpbtn"
                  style={{ backgroundColor: "hsl(359.68deg 100% 63.73%)" }}
                >
                  Confirm
                </button>
                <img
                  className=" p-2 pl-3 pr-3 sendOtpLoader"
                  src="/img/loader.gif"
                  width="80px"
                  alt=""
                />
                <button
                  type="button"
                  className="conformdeletebtn px-5 bg-white text-dark border"
                  onClick={() => {
                    $("#deleteAccount").modal("show");
                    $("#deleteAccountconfirm").modal("hide");
                    // setnewCustomerId("");
                    // setnewBusinessId("");
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Header;

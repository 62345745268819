import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import { Link, Redirect } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";
var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);

let Decuserid;
let Decname;
let Decemail;
let Deccontact;
let temp = "general";

export class Useroption extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userID: "",
      userName: "",
      userEmail: "",
      userContact: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    $(document.body).css("background-color", "#2d2d2d");
    let mainid = localStorage.getItem("relleIsugad");
    Decuserid = encryptor.decrypt(mainid);
    // ----------------------------temporary -----------------------------------------


    let temptoken = localStorage.getItem("fdgshdjfkgjhyhdnxhsgdhskcj")
    let Dectemptoken = encryptor.decrypt(temptoken)
    let paramsUserId2 = this.props.match.params.id2;
    if (Dectemptoken != null && Dectemptoken == paramsUserId2) {
      temp = "temporary"
      $(".useropt__rn__addBtn").hide()
    } else {
      temp = "general"
      $(".useropt__rn__addBtn").show()
    }

    // ----------------------------temporary -----------------------------------------
    if (this.props.location.state) {
    
      Decname = this.props.location.state.name;
      Decemail = this.props.location.state.email;
      Deccontact = this.props.location.state.contact;
      // Decuserid = this.this.props.location.state.userid
      let paramsId = this.props.match.params.id2;
      if (paramsId != Decuserid) {
        this.props.history.push("/dashboard");
      } else {
        this.setState({
          userID: Decuserid,
          userName: Decname,
          userEmail: Decemail,
          userContact: Deccontact,
        });
        this.props.history.replace(`/myuser/${Decuserid}`, null);
      }
    } else {
      this.props.history.push({
        pathname: "/myuser",
      });
    }
  }

  openServices = () => {
    this.props.history.push({
      pathname: `/myuser/${Decuserid}/userservices`,
    });
  };

  openDocRecords = () => {
    
    this.props.history.push({
      pathname: `/documentrecords/${Decuserid}/documentrecords`,
    });
  };

  openPendingInv = () => {
    this.props.history.push({
      pathname: `/paymentinvoices/${Decuserid}/paymentinvoice`,
    });
  };

  openQuery = () => {
    this.props.history.push({
      pathname: "/agentservices/usersupport", state: {id : this.state.userID, email: this.state.userEmail, contact: this.state.userContact, name: this.state.userName}
    } );
  };

  addServices = () => {
    this.props.history.push({
      pathname: `/addservice/${Decuserid}/addservice`, state: {name: this.state.userName}
    });
  };

  backbutton = () => {
    this.props.history.push({
      pathname: "/myuser",
    });
  };

  render() {
    if (
      localStorage.getItem("gglltakoinoeenl") ==
        process.env.REACT_APP_LOGIN_KEY &&
      localStorage.getItem("toeljgtkewlna") != null &&
      localStorage.getItem("relleIsugad") != null
    ) {
      return (
        <div className="cont">
          <div id="notifContainer"></div>
          <Helmet>
            <link
              rel="stylesheet"
              href={process.env.PUBLIC_URL + "/css/select2.css"}
            />
          </Helmet>

          <Header />

          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
            className="sidebaropenicon"
          />
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"}
            className="sidebarexpandicon"
          />
          <div className="overlay1"></div>
          <Sidebar/>

          <div className="arrow-left3"></div>
          <div className="white_div">
            <div className="white_box">
              <div className="overviewrow">
                <div className="row paddingmarginzero">
                  {/*  */}
                  <div className="col-md-9 col-12 paddingmarginzero">
                    <div className="border_r">
                      <div className="agent__useropt__main__div">
                        <div className="agent__services__header">
                          <img
                            src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
                            alt="back"
                            className="agent__useropt__backbtn"
                            onClick={this.backbutton}
                          />
                        </div>
                        <p className="agent__overview__head pl-2">
                          User Options:
                        </p>

                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-xl-4 col-md-6 col-12 mb-4">
                              <div
                                className="card useropt__card"
                                onClick={this.openServices}
                              >
                                <h1 className="useropt__card__head">
                                  Services
                                </h1>
                                <img
                                  alt="loading..."
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/img/Group 5511.svg"
                                  }
                                  className="useropt__card__img"
                                />
                              </div>
                            </div>

                            <div className="col-xl-4 col-md-6 col-12 mb-4">
                              <div
                                className="card useropt__card"
                                onClick={this.openDocRecords}
                              >
                                <h1 className="useropt__card__head">
                                  Document Records
                                </h1>
                                <img
                                  alt="loading..."
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/img/Group 5514.svg"
                                  }
                                  className="useropt__card__img"
                                />
                              </div>
                            </div>

                            <div className="col-xl-4 col-md-6 col-12 mb-4">
                              <div
                                className="card useropt__card"
                                onClick={this.openPendingInv}
                              >
                                <h1 className="useropt__card__head">
                                  Pending Invoices / payments
                                </h1>
                                <img
                                  alt="loading..."
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/img/Group 5507.svg"
                                  }
                                  className="useropt__card__img"
                                />
                              </div>
                            </div>

                            <div className="col-xl-4 col-md-6 col-12 mb-4">
                              <div
                                className="card useropt__card"
                                onClick={this.openQuery}
                              >
                                <h1 className="useropt__card__head">
                                  Raise Ticket
                                </h1>
                                <img
                                  alt="loading..."
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/img/Group 5510.svg"
                                  }
                                  className="useropt__card__img"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*  */}
                  <div className="col-md-3 col-12 paddingmarginzero">
                    <div className="row">
                      <div className="useropt__rs__div">
                        <img
                          src={process.env.PUBLIC_URL + "/img/Group_5505.svg"}
                          alt="profile"
                          className="useropt__rs__img"
                        />
                      </div>
                      <div className="useropt_rs_data__div">
                        <p className="useropt_rs_data">
                          <span className="useropt_rs__head">
                            User-ID:&nbsp;
                          </span>
                          {this.state.userID}
                        </p>
                        <p className="useropt_rs_data">
                          <span className="useropt_rs__head">
                            User Name:&nbsp;
                          </span>
                          {this.state.userName}
                        </p>
                        <p className="useropt_rs_data">
                          <span className="useropt_rs__head">Email:&nbsp;</span>
                          {this.state.userEmail}
                        </p>
                        <p className="useropt_rs_data">
                          <span className="useropt_rs__head">
                            Contact:&nbsp;
                          </span>
                          {this.state.userContact}
                        </p>
                      </div>
                      <div className="useropt__rs__div">
                        <button
                          onClick={this.addServices}
                          className="useropt__rn__addBtn"
                        >
                          <img
                            src={process.env.PUBLIC_URL + "/img/Path_111.svg"}
                          />{" "}
                          Add Service to this user
                        </button>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default Useroption;

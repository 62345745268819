import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $, { timers } from "jquery";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";

var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);
let Decuserid;
let Decjwttoken;
let Decagentid;
let Decserviceid = "OA2";
let Decservicename = "OA";
let Decplan = "A";
let selectshow;
let temp = "general";
var sharedocumentarray = [
  {
    documentvisiblename: "",
    link: "",
  },
];
export class DocumentrecordOA extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeservicemainarray: [],
      deliveredDocuments: [],
      monthYearDetails: [],
      activeservicemainarraylength: "0",
      deliveredDocumentslength: "0",
      SelectedYear: "",
      data: [],
      month: "",
      dataindex: 0,
      sharewhatsapp_radio: "Personal",
      shareemail_radio: "Legal251",
      whatsppcontactno: "",
      Selectedwhatsapptimeperiod: "select",
      Selectedemailtimeperiod: "select",
      customwhatsappdate: "",
      customemaildate: "",
      sharedocumentname: "",
      sharedocumentlink: "",
      emailmail: "",
    };
  }
  handleYearChange = (e) => {
    selectshow = true;
    this.setState({
      month: "select",
      SelectedYear: e.target.value,
      dataindex: e.target[e.target.selectedIndex].id,
      activeservicemainarray: [],
      deliveredDocuments: [],
      activeservicemainarraylength: 0,
      deliveredDocumentslength: 0,
    });
  };
  handleMonthChange = (e) => {
    this.setState(
      {
        month: e.target.value,
      },
      () => {
        this.getserviceInformationApi2();
      }
    );
  };
  checkdatesent(sent) {
    if (sent == null) {
      return <>Service not locked yet</>;
    } else {
      return <>{sent}</>;
    }
  }
  monthsToBeShown() {
    if (this.state.monthYearDetails.length > 0) {
      return this.state.monthYearDetails[this.state.dataindex].Month.map(
        (month, index) => {
          if (selectshow == true && index == 0) {
            return (
              <>
                <option key="select" value="select" selected disabled>
                  select
                </option>
                <option key={month} value={month}>
                  {month}
                </option>
              </>
            );
          } else {
            return (
              <option key={month} value={month}>
                {month}
              </option>
            );
          }
        }
      );
    }
  }
  YearsToBeShown() {
    return this.state.monthYearDetails.map((month, index) => {
      return (
        <option key={month.FY} value={month.FY} id={index}>
          {month.FY}
        </option>
      );
    });
  }

  logout = () => {
    localStorage.removeItem("gglltakoinoeenl");
    localStorage.removeItem("toeljgtkewlna");
    localStorage.removeItem("qazxswedcvfrtgb");
    localStorage.removeItem("lshhihfnkff");
    this.props.history.push({
      pathname: `/`,
    });
  };
  getserviceInformationApi = () => {
    $(".serviceoverlay").show();
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=documentDetails",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: this.state.month,
          requestType: temp,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((resp) => {
        $(".serviceoverlay").hide();

        if (resp.data.code == 200 && resp.data.status == "success") {
          this.setState({
            activeservicemainarray: resp.data.uploadedDocuments,
            deliveredDocuments: resp.data.deliveredDocuments,
            activeservicemainarraylength: resp.data.uploadedDocuments.length,
            deliveredDocumentslength: resp.data.deliveredDocuments.length,
            monthYearDetails: resp.data.monthYearDetails,
          });
          $(".myservicequiteimgstart2").show();
        } else if (
          resp.data.code == "201" &&
          resp.data.status == "invalidauth"
        ) {
          this.logout();
        } else if (resp.data.code == "201" && resp.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          this.showNotification(resp.data.message);
        }

        if (resp.data.monthYearDetails.length != 0) {
          this.setState({
            SelectedYear: resp.data.monthYearDetails[0].FY,
          });
        }
      })
      .catch((err) => {
        $(".serviceoverlay").hide();
      });
  };

  getserviceInformationApi2 = () => {
    $(".serviceoverlay").show();
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=documentDetails",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: this.state.month,
          requestType: temp,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((resp) => {
        $(".serviceoverlay").hide();

        if (resp.data.code == 200 && resp.data.status == "success") {
          this.setState({
            activeservicemainarray: resp.data.uploadedDocuments,
            deliveredDocuments: resp.data.deliveredDocuments,
            activeservicemainarraylength: resp.data.uploadedDocuments.length,
            deliveredDocumentslength: resp.data.deliveredDocuments.length,
          });
        } else if (
          resp.data.code == "201" &&
          resp.data.status == "invalidauth"
        ) {
          this.logout();
        } else if (resp.data.code == "201" && resp.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          this.showNotification(resp.data.message);
        }
      })
      .catch((err) => {
        $(".serviceoverlay").hide();
      });
  };
  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    $(document.body).css("background-color", "#2d2d2d");
    let mainuserid = localStorage.getItem("relleIsugad");
    Decuserid = encryptor.decrypt(mainuserid);
    let mainagentid = localStorage.getItem("qazxswedcvfrtgb");
    Decagentid = encryptor.decrypt(mainagentid);
    let mainjwttoken = localStorage.getItem("toeljgtkewlna");
    Decjwttoken = encryptor.decrypt(mainjwttoken);
    let mainserviceid = localStorage.getItem("vrellvaiecstiadeceig");
    let mainaservicename = localStorage.getItem("eieltaiclsraaemevcvnge");
    let mainplan = localStorage.getItem("ipacneglatvlael");
    Decserviceid = encryptor.decrypt(mainserviceid);
    Decservicename = encryptor.decrypt(mainaservicename);
    Decplan = encryptor.decrypt(mainplan);
    // ----------------------------temporary -----------------------------------------


    let temptoken = localStorage.getItem("fdgshdjfkgjhyhdnxhsgdhskcj")
    let Dectemptoken = encryptor.decrypt(temptoken)
    let paramsUserId2 = this.props.match.params.id2;
    if (Dectemptoken != null && Dectemptoken == paramsUserId2) {
      temp = "temporary"
    } else {
      temp = "general"
    }

    // ----------------------------temporary -----------------------------------------
    if (mainserviceid != null && mainaservicename != null && mainplan != null) {
      let paramsId = this.props.match.params.id;
      let paramsUserId = this.props.match.params.id2;
      Decserviceid = encryptor.decrypt(mainserviceid);
      Decservicename = encryptor.decrypt(mainaservicename);
       Decplan = encryptor.decrypt(mainplan);
       if (paramsId != Decserviceid || paramsUserId != Decuserid) {
         this.props.history.push("/dashboard");
       }
     } else {
       this.props.history.push("/dashboard");
    }
    this.getserviceInformationApi();

     
    let str = document.getElementsByClassName("myservicesortbyfilterbystatus").innerHTML = this.props.location.pathname.replaceAll("/", " > ")
    let mainstr = document.getElementsByClassName("myservicesortbyfilterbystatus").innerHTML = str.replace(" > ", " ")
    $(".myservicesortbyfilterbystatus").html(mainstr)
  }

  completerender() {
    if (this.state.deliveredDocuments.length > 0) {
      return this.state.deliveredDocuments.map((activedoc, index) => {
        return (
          <div className="displayflex documentmaincard" key={index}>
            <div>
              <img
                src={process.env.PUBLIC_URL + "/img/Path 247.svg"}
                className="docutypeimg"
              />
            </div>
            <div className="docucontdiv">
              <div className="row">
                <div className="col-md-6">
                  <p className="nameofdoc">{activedoc.mainName}</p>
                  <p className="datesenttext">
                    Date received:-{" "}
                    <span className="datesentspan">{activedoc.datesent}</span>
                  </p>
                </div>
                <div className="col-md-6">
                  <div className="fl_right">
                    <div className="displayflex">
                      <div className="viewbtndivpadding">
                        <a
                          href={activedoc.documentLink}
                          target="_blank"
                          className="viewbtn"
                        >
                          View{" "}
                          <img
                            src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                          />
                        </a>
                      </div>
                      <div>
                        <a
                          href={activedoc.documentLink}
                          className="download_btn"
                          download
                          target="_blank"
                        >
                          Download{" "}
                          <img
                            src={
                              process.env.PUBLIC_URL + "/img/download_icon.svg"
                            }
                            alt="download"
                          />
                        </a>
                      </div>
                    </div>
                    <p className="docushareviatext">
                      Share via:{" "}
                      <a
                        onClick={() =>
                          this.whatsapp(
                            activedoc.mainName,
                            activedoc.documentLink
                          )
                        }
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/whatsapp.svg"}
                          className="docushareviawhats"
                        />
                      </a>{" "}
                      <a
                        onClick={() =>
                          this.email(
                            activedoc.mainName,
                            activedoc.documentLink
                          )
                        }
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/mail.svg"}
                          className="docushareviamail"
                        />
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.deliveredDocuments.length == 0) {
      return (
        <center className="myservicequiteimgcenter">
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
            className="myservicequiteimgstart2"
          />
        </center>
      );
    }
  }

  handlewhatsppcontactno = (e) => {
    this.setState({
      whatsppcontactno: e.target.value,
    });
  };
  handleemailmail = (e) => {
    this.setState({
      emailmail: e.target.value,
    });
  };

  handlecustomwhatsappdateChange = (e) => {
    this.setState({
      customwhatsappdate: e.target.value,
    });
  };

  handlecustomemaildateChange = (e) => {
    this.setState({
      customemaildate: e.target.value,
    });
  };

  handlewhatsapptimeperiodChange = (e) => {
    this.setState({
      Selectedwhatsapptimeperiod: e.target.value,
    });
    if (e.target.value == "custom") {
      $("#whatsappdateinput").show();
    } else if (e.target.value == "always") {
      $("#whatsappdateinput").hide();
      $(".sharedocmentwhatsapplifeerrormessage").show();
    } else {
      $("#whatsappdateinput").hide();
      $(".sharedocmentwhatsapplifeerrormessage").hide();
    }
  };
  handleemailtimeperiodChange = (e) => {
    $(".sharedocmentemaildateerrormessage").hide();
    this.setState({
      Selectedemailtimeperiod: e.target.value,
    });
    if (e.target.value == "custom") {
      $("#emaildateinput").show();
    } else if (e.target.value == "always") {
      $("#emaildateinput").hide();
      $(".sharedocmentemaillifeerrormessage").show();
    } else {
      $("#emaildateinput").hide();
      $(".sharedocmentemaillifeerrormessage").hide();
    }
  };

  whatsapp = (docname, link) => {
    $(".myAddModal1").modal({ backdrop: "static", keyboard: false });
    this.setState({
      sharedocumentname: docname,
      sharedocumentlink: link,
    });
    sharedocumentarray[0].documentname = docname;
    sharedocumentarray[0].link = link;
  };

  email = (docname, link) => {
    $(".myAddModal2").modal({ backdrop: "static", keyboard: false });
    this.setState({
      sharedocumentname: docname,
      sharedocumentlink: link,
    });
    sharedocumentarray[0].documentname = docname;
    sharedocumentarray[0].link = link;
  };
  submitemail = () => {
    let ToDate = new Date();
    if (this.state.emailmail.trim() === "") {
      $(".sharedocmentemailerrormessage").show();
      $(".sharedocmentemailerrormessage").html("Please enter  E-mail");
    } else if (this.state.emailmail.trim().length > 50) {
      $(".sharedocmentemailerrormessage").show();
      $(".sharedocmentemailerrormessage").html(
        "E-mail should be less than 50 characters"
      );
    } else if (
      !this.state.emailmail
        .trim()
        .match(/^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i)
    ) {
      $(".sharedocmentemailerrormessage").show();
      $(".sharedocmentemailerrormessage").html("Please enter  valid E-mail");
    } else if (this.state.Selectedemailtimeperiod == "select") {
      $(".sharedocmentemailerrormessage").hide();
      $(".sharedocmentemailselecterrormessage").show();
      $(".sharedocmentemailselecterrormessage").html("Please select a option");
    } else if (
      this.state.Selectedemailtimeperiod == "custom" &&
      this.state.customemaildate == ""
    ) {
      $(".sharedocmentemailerrormessage").hide();
      $(".sharedocmentemailselecterrormessage").hide();
      $(".sharedocmentemaildateerrormessage").html("Please enter date");
      $(".sharedocmentemaildateerrormessage").show();
    } else if (
      this.state.Selectedemailtimeperiod == "custom" &&
      new Date(this.state.customemaildate).getTime() < ToDate.getTime()
    ) {
      $(".sharedocmentemailerrormessage").hide();
      $(".sharedocmentemailselecterrormessage").hide();
      $(".sharedocmentemaildateerrormessage").html("Please enter a vaild date");
      $(".sharedocmentemaildateerrormessage").show();
    } else {
      $(".upload_btn20_70").hide();
      $(".upload_btnloader_70").show();
      $(".cancelbtn_70").attr("disabled", true);
      var localemaildate = this.state.customemaildate;
      if (
        this.state.Selectedemailtimeperiod != "custom" &&
        this.state.customemaildate != ""
      ) {
        localemaildate = "";
      }

      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=sharedocumentEmailNormal",
          {
            userId: Decuserid,
            requestType: temp,
            agentId: Decagentid,
            emailto: this.state.emailmail.trim(),
            duration: this.state.Selectedemailtimeperiod,
            custom: localemaildate,
            type: this.state.shareemail_radio,
            shareableDocument: sharedocumentarray,
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((resp) => {
          $(".upload_btn20_70").show();
          $(".upload_btnloader_70").hide();
          $(".cancelbtn_70").attr("disabled", false);
          $(".myAddModal2").modal("hide");
          $(".sharedocmentemaillifeerrormessage").hide();
          $(".sharedocmentemailerrormessage").hide();
          $(".sharedocmentemailselecterrormessage").hide();
          $(".sharedocmentemaildateerrormessage").hide();
          var localemailmail = this.state.emailmail;
          var localradio = this.state.shareemail_radio;
          this.setState({
            Selectedemailtimeperiod: "select",
            emailmail: "",
            shareemail_radio: "Legal251",
            customemaildate: "",
          });
          $("#emaildateinput").hide();
          if (resp.data.code == 200 && resp.data.status == "success") {
            if (localradio == "Personal") {
              window.open(
                `mailto:${localemailmail}?subject=Shared%20Documents%20-%20Legal251&body=Hi%20There%20!!%20I%20am%20sharing%20following%20documents%20with%20you%20via%20my%20Legal251%20Panel:-%0a${resp.data.documentLinks[0].documentname}%0a${resp.data.documentLinks[0].link}`,
                "_blank"
              );
            } else {
              $(".myAddModal3").modal({ backdrop: "static", keyboard: false });
              setTimeout(function () {
                $(".myAddModal3").modal("hide");
              }, 3000);
            }
          } else if (
            resp.data.code == "201" &&
            resp.data.status == "invalidauth"
          ) {
            this.logout();
          }
          else if (resp.data.code == "201" && resp.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          }
          else {
            this.showNotification(resp.data.message);
          }
        })
        .catch((err) => {
          this.showNotification("Something went wrong, Try again!");
        });
    }
  };

  submitwhatsapp = () => {
    let ToDate = new Date();
    if (this.state.whatsppcontactno.trim() === "") {
      $(".sharedocmentwhatsapperrormessage").show();
      $(".sharedocmentwhatsapperrormessage").html("Please enter  Mobile No");
    } else if (!this.state.whatsppcontactno.trim().match(/^[6-9]\d{9}$/)) {
      $(".sharedocmentwhatsapperrormessage").show();
      $(".sharedocmentwhatsapperrormessage").html(
        "Please enter  valid Mobile No"
      );
    } else if (this.state.Selectedwhatsapptimeperiod == "select") {
      $(".sharedocmentwhatsapperrormessage").hide();
      $(".sharedocmentwhatsappselecterrormessage").show();
      $(".sharedocmentwhatsappselecterrormessage").html(
        "Please select a option"
      );
    } else if (
      this.state.Selectedwhatsapptimeperiod == "custom" &&
      this.state.customwhatsappdate == ""
    ) {
      $(".sharedocmentwhatsapperrormessage").hide();
      $(".sharedocmentwhatsappselecterrormessage").hide();
      $(".sharedocmentwhatsappdateerrormessage").html("Please enter date");
      $(".sharedocmentwhatsappdateerrormessage").show();
    } else if (
      this.state.Selectedwhatsapptimeperiod == "custom" &&
      new Date(this.state.customwhatsappdate).getTime() < ToDate.getTime()
    ) {
      $(".sharedocmentwhatsapperrormessage").hide();
      $(".sharedocmentwhatsappselecterrormessage").hide();
      $(".sharedocmentwhatsappdateerrormessage").html(
        "Please a enter vaild date"
      );
      $(".sharedocmentwhatsappdateerrormessage").show();
    } else {
      $(".upload_btn20_90").hide();
      $(".upload_btnloader_90").show();
      $(".cancelbtn_90").attr("disabled", true);
      var localwhatsappdate = this.state.customwhatsappdate;
      if (
        this.state.Selectedwhatsapptimeperiod != "custom" &&
        this.state.customwhatsappdate != ""
      ) {
        localwhatsappdate = "";
      }
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=sharedocumentLinkNormal",
          {
            userId: Decuserid,
            requestType: temp,
            agentId: Decagentid,
            duration: this.state.Selectedwhatsapptimeperiod,
            custom: localwhatsappdate,
            shareableDocument: sharedocumentarray,
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((resp) => {
          $(".upload_btn20_90").show();
          $(".upload_btnloader_90").hide();
          $(".cancelbtn_90").attr("disabled", false);
          $(".myAddModal1").modal("hide");
          $(".sharedocmentwhatsapperrormessage").hide();
          $(".sharedocmentwhatsapplifeerrormessage").hide();
          $(".sharedocmentwhatsappselecterrormessage").hide();
          $(".sharedocmentwhatsappdateerrormessage").hide();
          var localwhatsppcontactno = this.state.whatsppcontactno;
          this.setState({
            Selectedwhatsapptimeperiod: "select",
            customemaildate: "",
            whatsppcontactno: "",
          });
          $("#whatsappdateinput").hide();

          if (resp.data.code == 200 && resp.data.status == "success") {
            window.open(
              `https://web.whatsapp.com/send?phone=91${localwhatsppcontactno}&text=Hi%20There%20!!%20I%20am%20sharing%20following%20documents%20with%20you%20via%20my%20Legal251%20Panel:-%0a${resp.data.documentLinks[0].documentname}%0a${resp.data.documentLinks[0].link}`,
              "_blank"
            );
          } else if (
            resp.data.code == "201" &&
            resp.data.status == "invalidauth"
          ) {
            this.logout();
          }
          else if (resp.data.code == "201" && resp.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          }
          else {
            this.showNotification(resp.data.message);
          }
        })
        .catch((err) => {
          this.showNotification("Something went wrong, Try again!");
        });
    }
  };

  sharewhatsapp_radioChange = (e) => {
    this.setState({
      sharewhatsapp_radio: e.currentTarget.value,
    });
  };
  shareemail_radioChange = (e) => {
    this.setState({
      shareemail_radio: e.currentTarget.value,
    });
  };

  activerender() {
    if (this.state.activeservicemainarray.length > 0) {
      return this.state.activeservicemainarray.map((activedoc, index) => {
        return (
          <div className="displayflex documentmaincard" key={index}>
            <div>
              <img
                src={process.env.PUBLIC_URL + "/img/Path 247.svg"}
                className="docutypeimg"
              />
            </div>
            <div className="docucontdiv">
              <div className="row">
                <div className="col-md-6">
                  <p className="nameofdoc">{activedoc.mainName}</p>
                  <p className="datesenttext">
                    Date sent:-{" "}
                    <span className="datesentspan">
                      {this.checkdatesent(activedoc.datesent)}
                    </span>
                  </p>
                </div>
                <div className="col-md-6">
                  <div className="fl_right">
                    <div className="displayflex">
                      <div className="viewbtndivpadding">
                        <a
                          href={activedoc.documentLink}
                          target="_blank"
                          className="viewbtn"
                        >
                          View{" "}
                          <img
                            src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                          />
                        </a>
                      </div>
                      <div>
                        <a
                          href={activedoc.documentLink}
                          target="_blank"
                          className="download_btn"
                          download
                        >
                          Download{" "}
                          <img
                            src={
                              process.env.PUBLIC_URL + "/img/download_icon.svg"
                            }
                            alt="download"
                          />
                        </a>
                      </div>
                    </div>
                    <p className="docushareviatext">
                      Share via:{" "}
                      <a
                        onClick={() =>
                          this.whatsapp(
                            activedoc.mainName,
                            activedoc.documentLink
                          )
                        }
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/whatsapp.svg"}
                          className="docushareviawhats"
                        />
                      </a>{" "}
                      <a
                        onClick={() =>
                          this.email(
                            activedoc.mainName,
                            activedoc.documentLink
                          )
                        }
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/mail.svg"}
                          className="docushareviamail"
                        />
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.activeservicemainarray.length == 0) {
      return (
        <center className="myservicequiteimgcenter">
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
            className="myservicequiteimgstart2"
          />
        </center>
      );
    }
  }
  backbutton = () => {
    this.props.history.push({
      pathname: `/documentrecords/${Decuserid}/documentrecords`,
    });
  };
  render() {
    if (
      localStorage.getItem("gglltakoinoeenl") ==
      process.env.REACT_APP_LOGIN_KEY &&
      localStorage.getItem("toeljgtkewlna") != null &&
      localStorage.getItem("qazxswedcvfrtgb") != null
    ) {
      return (
        <div className="cont">
          <div id="notifContainer"></div>

          <Helmet>
            <link
              rel="stylesheet"
              href={process.env.PUBLIC_URL + "/css/select2.css"}
            />
          </Helmet>

          <Header />

          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
            className="sidebaropenicon"
          />
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
            onClick={this.backbutton}
            className="sidebarbackicon"
          />
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"}
            className="sidebarexpandicon"
          />

          <div className="overlay1"></div>
          <div className="sidebar sidebarshift">
            <div className="side_div_2 sidebar_txt active">
              <Link to="/dashboard">Overview</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <Link to="/myservices">My Services</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <a href="#">Payments Invoices</a>
            </div>
            <div className="side_div_1 sidebar_txt">
              <a href="#">Documents Record</a>
            </div>
            <div className="side_div_1 sidebar_txt">
              <a href="#">Add service</a>
            </div>
            <div></div>
          </div>
          <div className="arrow-left2 visibilityhidden"></div>
          <div className="white_div expandwhite_div">
            <div className="serviceoverlay">
              <div className="serviceoverlayinside">
                <center>
                  <img
                    src={process.env.PUBLIC_URL + "/img/loader.gif"}
                    className="serviceloader"
                  />
                </center>
              </div>
            </div>
            <div className="white_box">
              <div className="documentheader">
                <div className="row">
                <div className="myservicesortbyfilterbystatus mt-1 ml-1">
                  
                  </div>
                  <div className="col-md-4"></div>
                  <div className="col-md-4">
                    <center>
                      <p className="docuservicename">Online Accounting</p>
                    </center>
                  </div>
                  <div className="col-md-4">
                    <div className="fl_right">
                      <select
                        className="selectfinancialyear2"
                        value={this.state.SelectedYear}
                        onChange={this.handleYearChange}
                        id="10"
                      >
                        {this.YearsToBeShown()}
                      </select>
                      <select
                        className="selectfinancialmonth2"
                        value={this.state.month}
                        onChange={this.handleMonthChange}
                      >
                        {this.monthsToBeShown()}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <center>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/iconmonstr-download-19.svg"
                      }
                      className="down_img3"
                      alt="download"
                    />
                    <p className="gstuploaddouc">
                      Uploaded Documents (
                      {this.state.activeservicemainarraylength})
                    </p>
                  </center>
                  <div className="doucumainscrolldiv">
                    <div className="doucumainscrolldivinside">
                      {this.activerender()}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 colbtwborder">
                  <center>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/iconmonstr-download-18.svg"
                      }
                      className="down_img3"
                      alt="download"
                    />
                    <p className="gstuploaddouc">
                      Delivered Documents ({this.state.deliveredDocumentslength}
                      )
                    </p>
                  </center>
                  <div className="doucumainscrolldiv">
                    <div className="doucumainscrolldivinside">
                      {this.completerender()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade myAddModal1">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-body uploadpopupbody">
                  <p className="sharepopupheading">
                    Please enter the recipient's mobile no.
                  </p>
                  <div className="sharepopupinputdiv">
                    <img
                      src={process.env.PUBLIC_URL + "/img/whatsapp.svg"}
                      className="docushareviawhatspopup"
                    />
                    <input
                      type="text"
                      className="sharepopupinput"
                      maxLength="10"
                      placeholder="Type here..."
                      value={this.state.whatsppcontactno}
                      onChange={this.handlewhatsppcontactno}
                    />
                    <p className="sharedocmentwhatsapperrormessage">
                      error message
                    </p>
                  </div>
                  <p className="sharepopupheading text-left">Share via:</p>
                  {/* <input
                    type="radio"
                    id="test30"
                    name="radio-group"
                    value="Yes"
                    checked={this.state.sharewhatsapp_radio === "Yes"}
                    onChange={this.sharewhatsapp_radioChange}
                  />
                  <label htmlFor="test30" className="uploadradiotext">
                    Legal251
                  </label> */}

                  <input
                    type="radio"
                    id="test31"
                    name="radio-group"
                    value="Personal"
                    checked={this.state.sharewhatsapp_radio === "Personal"}
                    onChange={this.sharewhatsapp_radioChange}
                  />
                  <label htmlFor="test31" className="uploadradiotext ">
                    Personal Mobile no.
                  </label>
                  <p className="sharepopupheading text-left">Share for:</p>
                  <select
                    className="selectimeperiod"
                    value={this.state.Selectedwhatsapptimeperiod}
                    onChange={this.handlewhatsapptimeperiodChange}
                  >
                    <option value="select" disabled>
                      select time period
                    </option>
                    <option value="1hr">share for 1 hour</option>
                    <option value="1day">share for 1 day</option>
                    <option value="3days">share for 3 day</option>
                    <option value="1week">share for 1 week</option>
                    <option value="1month">share for 1 month</option>
                    <option value="custom">custom</option>
                    <option value="always">lifetime</option>
                  </select>
                  <p className="sharedocmentwhatsapplifeerrormessage">
                    We don't recommend you to share documents for lifetime for
                    security reasons Instead you can select a custom time
                    period.
                  </p>
                  <p className="sharedocmentwhatsappselecterrormessage">
                    error message
                  </p>
                  <input
                    type="date"
                    id="whatsappdateinput"
                    className="othertextinput"
                    value={this.state.customwhatsappdate}
                    onChange={this.handlecustomwhatsappdateChange}
                    placeholder="Type here..."
                  />
                  <p className="sharedocmentwhatsappdateerrormessage">
                    error message
                  </p>
                  <br />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn cancelbtn_90"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20 upload_btn20_90"
                        onClick={this.submitwhatsapp}
                      >
                        Send&nbsp;
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_19481.svg"}
                          alt="download"
                        />
                      </button>
                      <button className="upload_btnloader upload_btnloader_90">
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade myAddModal2">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-body uploadpopupbody">
                  <p className="sharepopupheading">
                    Please enter the recipient's email ID
                  </p>
                  <div className="sharepopupinputdiv">
                    <img
                      src={process.env.PUBLIC_URL + "/img/Group_4339.svg"}
                      className="docushareviawhatspopup"
                    />
                    <input
                      type="text"
                      className="sharepopupinput"
                      maxLength="50"
                      placeholder="Type here..."
                      value={this.state.emailmail}
                      onChange={this.handleemailmail}
                    />
                    <p className="sharedocmentemailerrormessage">
                      error message
                    </p>
                  </div>
                  <p className="sharepopupheading text-left">Share via:</p>
                  <input
                    type="radio"
                    id="test40"
                    name="radio-group2"
                    value="Legal251"
                    checked={this.state.shareemail_radio === "Legal251"}
                    onChange={this.shareemail_radioChange}
                  />
                  <label htmlFor="test40" className="uploadradiotext">
                    Legal251
                  </label>

                  <input
                    type="radio"
                    id="test41"
                    name="radio-group2"
                    value="Personal"
                    checked={this.state.shareemail_radio === "Personal"}
                    onChange={this.shareemail_radioChange}
                  />
                  <label
                    htmlFor="test41"
                    className="uploadradiotext rightradio"
                  >
                    Personal Mail
                  </label>
                  <p className="sharepopupheading text-left">Share for:</p>
                  <select
                    className="selectimeperiod"
                    value={this.state.Selectedemailtimeperiod}
                    onChange={this.handleemailtimeperiodChange}
                  >
                    <option value="select" disabled>
                      select time period
                    </option>
                    <option value="1hr">share for 1 hour</option>
                    <option value="1day">share for 1 day</option>
                    <option value="3days">share for 3 day</option>
                    <option value="1week">share for 1 week</option>
                    <option value="1month">share for 1 month</option>
                    <option value="custom">custom</option>
                    <option value="always">lifetime</option>
                  </select>
                  <p className="sharedocmentemaillifeerrormessage">
                    We don't recommend you to share documents for lifetime for
                    security reasons Instead you can select a custom time
                    period.
                  </p>
                  <p className="sharedocmentemailselecterrormessage">
                    error message
                  </p>
                  <input
                    type="date"
                    id="emaildateinput"
                    className="othertextinput"
                    value={this.state.customemaildate}
                    onChange={this.handlecustomemaildateChange}
                    placeholder="Type here..."
                  />
                  <p className="sharedocmentemaildateerrormessage">
                    error message
                  </p>
                  <br />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn cancelbtn_70"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20 upload_btn20_70"
                        onClick={this.submitemail}
                      >
                        Send&nbsp;
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_19481.svg"}
                          alt="download"
                        />
                      </button>
                      <button className="upload_btnloader upload_btnloader_70">
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade myAddModal3 ">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-body uploadpopupbody">
                  <center>
                    <p className="sharesucesstext">Sent Sucessfully</p>
                    <img
                      src={process.env.PUBLIC_URL + "/img/Path_19474.svg"}
                      className="sharesucessimg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default DocumentrecordOA;

import React, { Component } from "react";
import $ from "jquery";
import { Link, Redirect } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { Helmet } from "react-helmet";
import CommHistory from "./CommHistory";
import ReactPaginate from "react-paginate";
import axios from "axios";
import filterFn2 from "./TargetedFilterFcn";
import Sidebar from "./Sidebar";

var today = new Date();
const CurrFYear = today.getFullYear();
const Currmonth = today.getMonth();
let monthsArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
let Fyears = [];

var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);
let Decjwttoken;
let Decagentid;
let baseUrl = process.env.REACT_APP_API_BASE_URL;
export class AgentPanel_Commission extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 0,
      mydata: [],
      mydata2: [],
      pageCount: 1,
      PER_PAGE: 12,
      commDropDownValue: "P",
      cashinHand: 0,
      commissionPaid: 0,
      customerSupport: 0,
      download: 0,
      servicePurchased: 0,
      type: "P",
      year: "",
      month: "",
      commission: 1,
      history: true,
      walletHis: [],
      entry: 10,
      pageCount: 0,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    $(document.body).css("background-color", "#2d2d2d");
    let mainjwttoken = localStorage.getItem("toeljgtkewlna");
    Decjwttoken = encryptor.decrypt(mainjwttoken);
    let mainagentid = localStorage.getItem("qazxswedcvfrtgb");
    Decagentid = encryptor.decrypt(mainagentid);
    const script = document.createElement("script");
    script.src = "/js/main.js";
    script.async = true;
    document.body.appendChild(script);

    this.setState({
      year: CurrFYear,
      month: monthsArray[Currmonth]
    });
    Fyears = [];
    for (var i = 2021; i <= CurrFYear; i++) {
      Fyears.unshift(i);
    }

    $("#fy-month-dropdown").css("visibility", "hidden");

    $("#invaliduserinfoinput").hide();
    $(".sendOtpBtn").show();
    // $("#success-modal").hide()
    //  $("#unsuccess-modal").hide()
    $("#userinfomodal").show();
    
    filterFn2("supportComTable")
    filterFn2("purchaseComTable")
    filterFn2("settlementTable")
    

if (this.props.location.state) {
  if (this.props.location.state.type=="wallet") {
                $(".empty-abs1").hide()
                $(".empty-abs2").show()
                $(".nullImg2").css("visibility", "visible")
                $(".nullImg").css("visibility", "hidden")
                $(".empty-abs1").css('z-index', 0)
                $(".empty-abs2").css('z-index', 9)
                $("#fy-month-dropdown").css("visibility", "hidden");
                $("#summary1").removeClass("active");
                $("#history1").removeClass("active");
                $("#CommissionRates1").removeClass("active");
                $("#wallet1").addClass("active");
                $("#summary2").removeClass("active");
                $("#history2").removeClass("active");
                $("#CommissionRates2").removeClass("active");
                $("#wallet2").addClass("active");
                $("#tab1").hide();
                $("#tab2").hide();
                $("#tab3").hide();
                $("#tab4").show();
                $(".comm__slider").css("width","151.078px");
                
    this.setState(
      { head: "wallet" , commission: 4 },
      this.wallethistory
    );
    
  }
}else{
  this.summary();
}

  }

  addYears = () => {
    return Fyears.map((year, index) => {
      return (
        <option key={index} value={year}>
          {year}
        </option>
      );
    });
  };

  summary = () => {
    $(".empty-abs1").hide();
    $(".empty-abs2").hide();
    axios
      .post(
        `${baseUrl}p=commissionSummary`,
        {
          agentId: Decagentid,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {

          this.setState({
            cashinHand: res.data.allcount.cashinHand,
            commissionPaid: res.data.allcount.commissionPaid,
            customerSupport: res.data.allcount.customerSupport,
            download: res.data.allcount.download,
            servicePurchased: res.data.allcount.servicePurchased,
          });
        } else if (
          res.data.code == "201" &&
          res.data.status == "invalidauth"
        ) {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something went wrong");
      });
  };
  fetchData = () => {

    $("#history1-loader").show();
    $("#history2-loader").show();
    $(".empty-abs1").hide();
    $(".empty-abs2").hide();

    axios
      .post(
        `${baseUrl}p=commissionHistory`,
        {
          agentId: Decagentid,
          Year: this.state.year,
          Month: this.state.month,
          type: this.state.type,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {


        $("#history1-loader").hide();
        $("#history2-loader").hide();


        if (res.data.code == "200" && res.data.status == "success") {
          if (res.data.history.length == 0) {
            if (this.state.history) {
              $(".empty-abs1").show();
            }
          }
          this.setState({ mydata: res.data.history });

        } else if (res.data.code == "201" && res.data.status == "fail") {

          this.showNotification(res.data.message);
        } else {

          this.showNotification(res.data.message);
        }

      })
      .catch((err) => {
        $("#history1-loader").hide();
        $("#history2-loader").hide();
        this.showNotification("Something went wrong");
      });
  };

  settlement = () => {
    $("#settlement-loader").show();
    $(".empty-abs1").hide();
    $(".empty-abs2").hide();

    axios
      .post(
        `${baseUrl}p=commissionSettlement`,
        {
          agentId: Decagentid,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {

        $("#settlement-loader").hide();
        if (res.data.code == "200" && res.data.status == "success") {
          if (res.data.settlements.length == 0) {
            if (!this.state.history) {
              $(".empty-abs2").show();
            }
          }
          this.setState({ mydata2: res.data.settlements });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#settlement-loader").hide();
        this.showNotification("Something went wrong");
      });
  };

  wallethistory = () => {
    $("#wallethistory-loader").show();
    $(".empty-abs1").hide();
    $(".empty-abs2").hide();

    axios
      .post(
        `${baseUrl}p=walletHistory`,
        {
          agentId: Decagentid,
          pageCount: this.state.currentPage + 1,
          entry: this.state.entry,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {

        $("#wallethistory-loader").hide();
        if (res.data.code == "200" && res.data.status == "success") {

        
          if (res.data.data.length == 0) {
            if (!this.state.mydata) {
              $(".empty-abs2").show();
            }
          }
          this.setState({ mydata: res.data.data });
          this.setState({ pageCount: res.data.totalpageCount });
          this.setState({ walletHis: res.data.data });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#wallethistory-loader").hide();
        this.showNotification("Something went wrong");
      });
  };

  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };
  handledropCommDownValueChange = (e) => {
    this.setState(
      {
        commDropDownValue: e.target.value,
      },
      () => {
        if (this.state.commDropDownValue == "PartnerPurchsed") {
          this.setState({ type: "P", mydata: [] }, this.fetchData);
          $("#comm__table__1").hide();
          $("#comm__table1__pagination").hide();
          $("#comm__table__2").show();
          $("#comm__table2__pagination").show();
        } else if (this.state.commDropDownValue == "CustomerSupport") {
          this.setState({ type: "S", mydata: [] }, this.fetchData);
          $("#comm__table__2").hide();
          $("#comm__table2__pagination").hide();
          $("#comm__table__1").show();
          $("#comm__table1__pagination").show();
        }
      }
    );
  };

  isfetchSummary = () => {
    if (this.state.commission == 2 || this.state.commission == 3 || this.state.commission == 4) {
      this.setState({ mydata2: [], mydata: [], history: false, walletHis: [] }, this.summary)
    }
  }
  isfetchHistory = () => {
    if (this.state.commission == 1 || this.state.commission == 3 || this.state.commission == 4) {
      this.setState({ mydata: [], history: true, walletHis: [] }, this.fetchData)
    }
  }
  isfetchSettlement = () => {
    if (this.state.commission == 1 || this.state.commission == 2 || this.state.commission == 4) {
      this.setState({ mydata2: [], history: false, walletHis: [] }, this.settlement)
    }
  }

  isfetchWallethistory = () => {
    if (this.state.commission == 1 || this.state.commission == 2 || this.state.commission == 3) {
      this.setState({ mydata2: [], history: false }, this.wallethistory)
    }
  }

  handlePageClick = ({ selected: selectedPage }) => {
    this.setState({ mydata: [] });
    this.setState({ currentPage: selectedPage }, this.wallethistory);
  };

  render() {
    if (
      localStorage.getItem("gglltakoinoeenl") ==
      process.env.REACT_APP_LOGIN_KEY &&
      localStorage.getItem("toeljgtkewlna") != null &&
      localStorage.getItem("qazxswedcvfrtgb") != null
    ) {
      return (
        <div className="cont">
          <div id="notifContainer"></div>
          <Helmet>
            <link
              rel="stylesheet"
              href={process.env.PUBLIC_URL + "/css/select2.css"}
            />
          </Helmet>

          <Header />
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
            className="sidebaropenicon"
          />
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
            className="sidebarbackicon"
          />
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"}
            className="sidebarexpandicon"
          />

          <div className="overlay1"></div>
          <Sidebar />

          <div className="arrow-left8"></div>
          <div className="white_div">
            <div className="white_box">
              <div className="main-row">
                <div className="agent__comm__header">
                  <div className="agent__comm__header__1">
                    <img
                      src="/img/Path_231.svg"
                      alt="back"
                      className="agent__useropt__backbtn d-none"
                    />

                    <form className="gayab">
                      <input
                        type="text"
                        className="agent__comm__search_box"
                        placeholder="Search by services"
                      />
                    </form>
                  </div>

                  <div className="agent-navigation-tab" id="comm__heads__1">
                    <header className="tabs-nav MT-3 position-relative hor_scrollbar">
                      <ul className="d-flex agent-navigation-tab-ul ">
                        <li
                          className="active agent-navigation-tab-li agent-navigation-tab-li-n-res"
                          id="summary1"
                          onClick={() => {
                            $(".empty-abs1").hide()
                            $(".empty-abs2").hide()
                            $(".empty-abs1").css("z-index", 0)
                            $(".empty-abs2").css("z-index", 0)
                            this.isfetchSummary()
                            this.setState({ commission: 1 })
                            $("#fy-month-dropdown").css("visibility", "hidden");
                          }}
                        >
                          <a href="#tab1" className="px-2 py-1">
                            Summary
                          </a>
                        </li>
                        <li
                          className="agent-navigation-tab-li agent-navigation-tab-li-n-res"
                          id="history1"
                          onClick={() => {
                            $(".nullImg2").css("visibility", "hidden")
                            $(".nullImg").css("visibility", "visible")
                            $(".empty-abs2").hide()
                            $(".empty-abs1").show()
                            $(".empty-abs1").css('z-index', 9)
                            $(".empty-abs2").css('z-index', 0)
                            this.isfetchHistory()
                            this.setState({ commission: 2 })
                            $("#fy-month-dropdown").css(
                              "visibility",
                              "visible"
                            );
                          }}
                        >
                          <a href="#tab2" className="px-2 py-1">
                            History
                          </a>
                        </li>
                        <li
                          className="agent-navigation-tab-li agent-navigation-tab-li-n-res"
                          id="CommissionRates1"
                          onClick={() => {
                            $(".empty-abs1").hide()
                            $(".empty-abs2").show()
                            $(".nullImg2").css("visibility", "visible")
                            $(".nullImg").css("visibility", "hidden")
                            $(".empty-abs1").css('z-index', 0)
                            $(".empty-abs2").css('z-index', 9)
                            this.isfetchSettlement()
                            this.setState({ commission: 3 })
                            $("#fy-month-dropdown").css("visibility", "hidden");
                          }}
                        >
                          <a href="#tab3" className="px-2 py-1">
                            Settlement
                          </a>
                        </li>
                        <li
                          className="agent-navigation-tab-li agent-navigation-tab-li-n-res "
                          id="wallet1"
                          onClick={() => {
                            $(".empty-abs1").hide()
                            $(".empty-abs2").show()
                            $(".nullImg2").css("visibility", "visible")
                            $(".nullImg").css("visibility", "hidden")
                            $(".empty-abs1").css('z-index', 0)
                            $(".empty-abs2").css('z-index', 9)
                            this.isfetchWallethistory()
                            this.setState({ commission: 4 })
                            $("#fy-month-dropdown").css("visibility", "hidden");
                          }}
                        >
                          <a href="#tab4" className="px-2 py-1 wallhis">
                          Wallet History
                          </a>
                        </li>
                        <hr className="comm__navigation__tab__vertical__line__1" />
                        <hr className="comm__navigation__tab__vertical__line__2" />
                        <hr className="comm__navigation__tab__vertical__line__3" />
                        <div className="comm__slider comm__slider_n_res"></div>
                      </ul>
                    </header>
                  </div>

                  <div
                    className="agent__selectcateselect__div"
                    id="fy-month-dropdown"
                  >
                    <select
                      className="agent__selectcateselect"
                      value={this.state.year}
                      onChange={(e) => {
                        this.setState({ year: e.target.value })
                        this.fetchData()
                      }}
                    >
                      {this.addYears()}
                    </select>
                    <select
                      className="agent__selectcateselect"
                      value={this.state.month}
                      onChange={(e) => {
                        this.setState({ month: e.target.value }, () => this.fetchData())
                      }}
                    >
                      <option value="Jan" className="selectcateselectoption2">
                        January
                      </option>
                      <option value="Feb" className="selectcateselectoption2">
                        February
                      </option>
                      <option value="Mar" className="selectcateselectoption2">
                        March
                      </option>
                      <option value="Apr" className="selectcateselectoption2">
                        April
                      </option>
                      <option value="May" className="selectcateselectoption2">
                        May
                      </option>
                      <option value="Jun" className="selectcateselectoption2">
                        June
                      </option>
                      <option value="Jul" className="selectcateselectoption2">
                        July
                      </option>
                      <option value="Aug" className="selectcateselectoption2">
                        August
                      </option>
                      <option value="Sep" className="selectcateselectoption2">
                        September
                      </option>
                      <option value="Oct" className="selectcateselectoption2">
                        October
                      </option>
                      <option value="Nov" className="selectcateselectoption2">
                        November
                      </option>
                      <option value="Dec" className="selectcateselectoption2">
                        December
                      </option>
                    </select>
                  </div>
                </div>

                {/* ipad */}
                <div className="agent-navigation-tab" id="comm__heads__2">
                  <header className="tabs-nav MT-3 position-relative hor_scrollbar">
                    <ul className="d-flex agent-navigation-tab-ul ">
                      <li
                        className="active agent-navigation-tab-li agent-navigation-tab-li-n-res"
                        id="summary2"
                        onClick={() => {
                          $(".empty-abs1").hide()
                          $(".empty-abs2").hide()

                          $(".empty-abs1").css("z-index", 0)
                          $(".empty-abs2").css("z-index", 0)
                          this.isfetchSummary()
                          this.setState({ commission: 1 })

                          $("#fy-month-dropdown").css("visibility", "hidden");
                        }}
                      >
                        <a href="#tab1" className="px-2 py-1">
                          Summary
                        </a>
                      </li>
                      <li
                        className="agent-navigation-tab-li agent-navigation-tab-li-n-res"
                        id="history2"
                        onClick={() => {
                          $(".nullImg2").css("visibility", "hidden")
                          $(".nullImg").css("visibility", "visible")
                          $(".empty-abs2").hide()
                          $(".empty-abs1").show()
                          $(".empty-abs1").css('z-index', 9)
                          $(".empty-abs2").css('z-index', 0)
                          this.isfetchHistory()

                          this.setState({ commission: 2 })
                          $("#fy-month-dropdown").css("visibility", "visible");
                        }}
                      >
                        <a href="#tab2" className="px-2 py-1">
                          History
                        </a>
                      </li>
                      <li
                        className="agent-navigation-tab-li agent-navigation-tab-li-n-res"
                        id="CommissionRates2"
                        onClick={() => {
                          $(".empty-abs1").hide()
                          $(".empty-abs2").show()
                          $(".nullImg2").css("visibility", "visible")
                          $(".nullImg").css("visibility", "hidden")
                          $(".empty-abs1").css("z-index", 0)
                          $(".empty-abs2").css("z-index", 9)
                          this.isfetchSettlement()

                          this.setState({ commission: 3 })
                          $("#fy-month-dropdown").css("visibility", "hidden");
                        }}
                      >
                        <a href="#tab3" className="px-2 py-1">
                          Settlement
                        </a>
                      </li>
                      <li
                        className="agent-navigation-tab-li agent-navigation-tab-li-n-res"
                        id="wallet2"
                        onClick={() => {
                          $(".empty-abs1").hide()
                          $(".empty-abs2").show()
                          $(".nullImg2").css("visibility", "visible")
                          $(".nullImg").css("visibility", "hidden")
                          $(".empty-abs1").css("z-index", 0)
                          $(".empty-abs2").css("z-index", 9)
                          this.isfetchWallethistory()

                          this.setState({ commission: 4 })
                          $("#fy-month-dropdown").css("visibility", "hidden");
                        }}
                      >
                        <a href="#tab4" className="px-2 py-1">
                          Wallet History
                        </a>
                      </li>
                      {/* <hr className="comm__navigation__tab__vertical__line__1" />
                      <hr className="comm__navigation__tab__vertical__line__2" /> */}
                      <div className="comm__slider comm__slider_n_res"></div>
                    </ul>
                  </header>
                </div>

                <div className="comm__header2 row">
                  <a href="#" className="btn comm__header2__view__btn commissionRatesBtn">
                    View Commission Rates
                    <img
                      className="ml-2"
                      src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                      alt=""
                    />
                  </a>
                </div>

                <div className="tabs-content py-md-3 mt-md-0 mt-2">
                  <div id="tab1" className="tab-content">
                    <div className="container-fluid mt-5">
                      <div className="row commission__row">
                        <div className="commission__col">
                          <div className="card commission__card">
                            <div className="commission__card__img_div">
                              <img
                                alt="loading..."
                                src={process.env.PUBLIC_URL + "/img/Group.svg"}
                                className="commission__card__img1"
                              />
                            </div>
                            <div className="commission__card__text_div">
                              <p className="commission__card__total">Total</p>
                              <p className="commission__card__totalVal">
                                {this.state.commissionPaid}
                              </p>
                              <h1 className="commission__card__head">
                                Commission Paid
                              </h1>
                            </div>
                          </div>
                        </div>

                        <div className="commission__col">
                          <div className="card commission__card">
                            <div className="commission__card__img_div">
                              <img
                                alt="loading..."
                                src={
                                  process.env.PUBLIC_URL + "/img/Group 5567.svg"
                                }
                                className="commission__card__img2"
                              />
                            </div>
                            <div className="commission__card__text_div">
                              <p className="commission__card__total">Total</p>
                              <p className="commission__card__totalVal">
                                {this.state.cashinHand}
                              </p>
                              <h1 className="commission__card__head">
                                In Cash By Customer
                              </h1>
                            </div>
                          </div>
                        </div>

                        <div className="commission__col">
                          <div className="card commission__card">
                            <div className="commission__card__img_div">
                              <img
                                alt="loading..."
                                src={
                                  process.env.PUBLIC_URL + "/img/Group3233.svg"
                                }
                                className="commission__card__img3"
                              />
                            </div>
                            <div className="commission__card__text_div">
                              <p className="commission__card__total">Total</p>
                              <p className="commission__card__totalVal">
                                {this.state.servicePurchased}
                              </p>
                              <h1 className="commission__card__head">
                                Partner Purchased Services
                              </h1>
                            </div>
                          </div>
                        </div>

                        <div className="commission__col">
                          <div className="card commission__card">
                            <div className="commission__card__img_div">
                              <img
                                alt="loading..."
                                src={
                                  process.env.PUBLIC_URL + "/img/Group 5566.svg"
                                }
                                className="commission__card__img4"
                              />
                            </div>
                            <div className="commission__card__text_div">
                              <p className="commission__card__total">Total</p>
                              <p className="commission__card__totalVal">
                                {this.state.customerSupport}
                              </p>
                              <h1 className="commission__card__head">
                                Customer Support Services
                              </h1>
                            </div>
                          </div>
                        </div>

                        <div className="commission__col">
                          <div className="card commission__card">
                            <div className="commission__card__img_div">
                              <img
                                alt="loading..."
                                src={
                                  process.env.PUBLIC_URL +
                                  "/img/google play store.svg"
                                }
                                className="commission__card__img5"
                              />
                            </div>
                            <div className="commission__card__text_div">
                              <p className="commission__card__total">Total</p>
                              <p className="commission__card__totalVal">
                                {this.state.download}
                              </p>
                              <h1 className="commission__card__head">
                                App Downloads
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="tab2" className="tab-content">
                    <select
                      className="agent__history__selectcateselect"
                      value={this.state.commDropDownValue}
                      onChange={this.handledropCommDownValueChange}
                    >
                      <option
                        value="PartnerPurchsed"
                        className="selectcateselectoption2"
                      >
                        Partner Purchased
                      </option>
                      <option
                        value="CustomerSupport"
                        className="selectcateselectoption2"
                      >
                        Customer Support
                      </option>
                    </select>

                    <div
                      className="table-responsive my-table comm__table"
                      id="comm__table__1"
                    >
                      <table className="table mr-3" id="supportComTable">
                        <thead className="my-thead ">
                          <tr className="supportComTable">
                            <th className="my-th sno" data-type="number">Sno  <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt="" /></th>
                            <th className="my-th services">Support date <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt="" /></th>
                            <th className="my-th userid useridcom ">
                              Service Name
                              <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt="" /></th>
                            <th className="my-th userid useridcom ">User ID <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt="" /></th>
                            <th className="my-th userid useridcom ">
                              Support Amount
                              <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt="" /></th>
                            <th className="my-th email">Payment Type <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt="" /></th>
                          </tr>
                        </thead>
                        <tbody className="my-tbody my-tbodysupport">

                          <div className="loader-div" id="history1-loader">
                            <img src="/img/loader.gif" />
                          </div>

                          {this.state.mydata.length != 0 ? (
                            this.state.mydata.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td scope="row" className="sno" data-type="number">
                                    {index + 1}
                                  </td>
                                  <td className="services ">{data.date}</td>
                                  <td className="userid useridcom ">
                                    {data.serviceName}
                                  </td>
                                  <td className="userid useridcom ">
                                    {data.userId}
                                  </td>
                                  <td className="userid useridcom ">
                                    {data.supportAmount}
                                  </td>

                                  <td className="email ptype">
                                    Direct cash by customer
                                  </td>
                                </tr>
                              );
                            })
                          ) : (

                            <div className="empty-abs1 mt-1" id="">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/img/quiteempty.svg"
                                }
                                className="nullImg"
                              />
                            </div>

                          )}
                        </tbody>
                      </table>
                    </div>

                    <div className="mt-3 w-100" id="comm__table1__pagination">

                    </div>

                    {/* table2 */}
                    <div
                      className="table-responsive my-table comm__table"
                      id="comm__table__2"
                    >
                      <table className="table mr-3" id="purchaseComTable">
                        <thead className="my-thead ">
                          <tr className="purchaseComTable">
                            <th className="my-th sno" data-type="number">Sno<img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt="" /></th>
                            <th className="my-th services">Purchase date<img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt="" /></th>
                            <th className="my-th userid useridcom2 ">
                              Service Name
                              <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt="" /></th>
                            <th className="my-th userid useridcom2 ">
                              User ID
                              <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt="" /></th>
                            <th className="my-th userid useridcom2 ">
                              Purchase Amount
                              <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt="" /></th>
                            <th className="my-th userid useridcom2 ">
                              Service Charge
                              <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt="" /></th>
                            <th className="my-th userid useridcom3 ">
                              Service Charge With GST
                              <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt="" /></th>
                            <th className="my-th userid useridcom2 ">
                              Govt Fees
                              <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt="" /></th>
                            <th className="my-th userid useridcom2">
                              Commission Percent
                              <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt="" /></th>
                            <th className="my-th userid useridcom2">
                              Total Commission
                              <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt="" /></th>
                            <th className="my-th userid useridcom4">
                              Commission Payable(After TDS 5%)
                              <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt="" /></th>
                          </tr>
                        </thead>
                        <tbody className="my-tbody my-tbodysupport">

                          <div className="loader-div" id="history2-loader">
                            <img src="/img/loader.gif" />
                          </div>

                          {this.state.mydata.length != 0 ? (
                            this.state.mydata.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td scope="row" className="sno" data-type="number">
                                    {index + 1}
                                  </td>
                                  <td className="userid services  ">
                                    {data.date}
                                  </td>
                                  <td className="userid useridcom2 ">{data.serviceName}</td>
                                  <td className="userid useridcom2 ">
                                    {data.userId}
                                  </td>
                                  <td className="userid useridcom2 ">
                                    {data.purchaseAmount}
                                  </td>
                                  <td className="userid useridcom2 ">
                                    {data.serviceCharge}
                                  </td>
                                  <td className="userid useridcom3 ">
                                    {data.serviceChargeWithGST}
                                  </td>
                                  <td className="userid useridcom2 ">
                                    {data.govtFees}
                                  </td>
                                  <td className="userid useridcom2 ">
                                    {data.commissionPercent} %
                                  </td>
                                  <td className="userid useridcom2 ">
                                    {data.totalCommission}
                                  </td>
                                  <td className="userid useridcom4 ">
                                    {data.commissionPayable}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (

                            <div className="empty-abs1 mt-1" id="">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/img/quiteempty.svg"
                                }
                                className="nullImg"
                              />
                            </div>

                          )}
                        </tbody>
                      </table>
                    </div>

                    <div className="mt-3 w-100" id="comm__table2__pagination">
                      {/* <ReactPaginate
                        previousLabel={
                          <img
                            src={process.env.PUBLIC_URL + "/img/Path_21772.svg"}
                            width="15px"
                          />
                        }
                        nextLabel={
                          <img
                            src={process.env.PUBLIC_URL + "/img/Path_21773.svg"}
                            width="15px"
                          />
                        }
                        pageCount={this.state.pageCount}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        previousLinkClassName={"pagination__link"}
                        nextLinkClassName={"pagination__link"}
                        disabledClassName={"pagination__link--disabled"}
                        activeClassName={"pagination__link----active"}
                      /> */}
                    </div>
                  </div>
                  <div id="tab3" className="tab-content">
                    <div
                      className="table-responsive my-table comm__table"
                      id="comm__table__2"
                    >
                      <table className="table mr-3" id="settlementTable">
                        <thead className="my-thead ">
                          <tr className="settlementTable">
                            <th className="my-th sno" data-type="number">Sno <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt="" /></th>
                            <th className="my-th services">Settlement date <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt="" /></th>
                            <th className="my-th userid useridcom2 ">Gross Amount <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt="" /></th>
                            <th className="my-th userid useridcom2 tds">TDS (5%) <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt="" /></th>
                            <th className="my-th userid useridcom2 ">Transfered Amount <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt="" /></th>
                            <th className="my-th userid useridcom2 tds">Mode <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt="" /></th>
                            <th className="my-th userid useridcom2 ">Ref no <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt="" /></th>
                            <th className="my-th userid useridcom2 tds">Month <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt="" /></th>
                            <th className="my-th userid useridcom2 tds">Year <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt="" /></th>
                            <th className="my-th userid useridcom2 ">
                              Bank Status
                              <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt="" /></th>
                          </tr>
                        </thead>
                        <tbody className="my-tbody my-tbodysupport">

                          <div className="loader-div" id="settlement-loader">
                            <img src="/img/loader.gif" />
                          </div>

                          {this.state.mydata2.length != 0 ? (
                            this.state.mydata2.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td scope="row" className="sno" data-type="number">
                                    {index + 1}
                                  </td>
                                  <td className="userid services  ">
                                    {data.date}
                                  </td>
                                  <td className="userid useridcom2 ">
                                    {data.gross}
                                  </td> <td className="userid useridcom2 tds">
                                    {data.tds}
                                  </td>
                                  <td className="userid useridcom2">
                                    {data.tAmount}
                                  </td>
                                  <td className="userid useridcom2 tds">
                                    {data.mode}
                                  </td>
                                  <td className="userid useridcom2 ">
                                    {data.refno}
                                  </td>
                                  <td className="userid useridcom2 tds">
                                    {data.month}
                                  </td>
                                  <td className="userid useridcom2 tds">
                                    {data.year}
                                  </td>
                                  <td className="userid useridcom2 ">
                                    {data.status}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <>
                              <div className="empty-abs2 mt-1" id="">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/img/quiteempty.svg"
                                  }
                                  className="nullImg2"
                                />
                              </div>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div id="tab4" className="tab-content">
                    <div
                      className="table-responsive my-table comm__table"
                      id="comm__table__2"
                    >
                      <table className="table mr-3" id="settlementTable">
                        <thead className="my-thead ">
                          <tr className="settlementTable">
                            <th className="my-th sno" data-type="number" title="Sno">Sno <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt="" /></th>
                            <th className="my-th services tds" title="Date">Date <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt="" /></th>
                            <th className="my-th userid useridcom2 tds" title="Reference Id">Reference Id <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt="" /></th>
                            <th className="my-th userid useridcom2 tds" title="User ID">User ID <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt="" /></th>
                            <th className="my-th userid useridcom2 tds" title="Type ">Type <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt="" /></th>
                            <th className="my-th userid useridcom2 tds" title="Wallet Type">Wallet Type <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt="" /></th>
                            {/* <th className="my-th userid useridcom2 ">Recharge ID <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt="" /></th> */}
                            <th className="my-th userid useridcom2 " title="Amount"> Amount <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt="" /></th>

                          </tr>
                        </thead>
                        <tbody className="my-tbody my-tbodysupport v_scroll">

                          <div className="loader-div" id="wallethistory-loader">
                            <img src="/img/loader.gif" />
                          </div>

                          {this.state.mydata.length != 0 ? (
                            this.state.mydata.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td scope="row" className="sno" data-type="number" >
                                  {(this.state.entry * (this.state.currentPage)) + index + 1}
                                  </td>
                                  <td className="userid services tds " title={data.datetime}>
                                    {data.datetime}
                                  </td>
                                  <td className="userid useridcom2 tds " title={data.shortype == "R" ? data.rechargeID : data.serviceId}>
                                    {data.shortype == "R" ? data.rechargeID : data.serviceId}
                                  </td> <td className="userid useridcom2 tds " title={data.userID}>
                                    {data.userID}
                                  </td>
                                  <td className="userid useridcom2 tds " title={data.type}>
                                    {data.type}
                                  </td>
                                  <td className="userid useridcom2 tds " title={data.walletType}>
                                    {data.walletType}
                                  </td>
                                  {/* <td className="userid useridcom2 ">
                                    {data.rechargeID}
                                  </td> */}
                                  {
                                    data.shortype == "D" ?
                                    <td className="userid useridcom2 text-danger" title={data.deductionAmount}>
                                   - {data.deductionAmount}
                                  </td>
                                  : 
                                  <td className="userid useridcom2 text-success" title={data.deductionAmount}>
                                  + {data.deductionAmount}
                                </td>
                                  }
                                 
                                  
                                </tr>
                              );
                            })
                          ) : (
                            <>
                              <div className="empty-abs2 mt-1" id="">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/img/quiteempty.svg"
                                  }
                                  className="nullImg2"
                                />
                              </div>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="mt-3 w-100">
                  <ReactPaginate
                    previousLabel={
                      <img
                        src={process.env.PUBLIC_URL + "/img/Path_21772.svg"}
                        width="15px"
                      />
                    }
                    nextLabel={
                      <img
                        src={process.env.PUBLIC_URL + "/img/Path_21773.svg"}
                        width="15px"
                      />
                    }
                    pageCount={this.state.pageCount}
                    onPageChange={this.handlePageClick}
                    forcePage={this.state.currentPage}
                    containerClassName={"pagination"}
                    previousLinkClassName={"pagination__link"}
                    nextLinkClassName={"pagination__link"}
                    disabledClassName={"pagination__link--disabled"}
                    activeClassName={"pagination__link----active"}
                  />
                </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
          <Footer />
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default AgentPanel_Commission;

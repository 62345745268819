import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import axios from "axios";
import { Redirect } from "react-router";
import jsonData from "../State__and__dist.json"

let Decuserid;
let Decagentid;
let Decjwttoken;
var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var fullname = "";
var citydistrict = "";
var servicename = "";
var servicestate = "";

let supportName;
let supportContact;
let supportEmail;
var encryptor = require("simple-encryptor")(key);

var encryptor = require("simple-encryptor")(key);

class InduslandBankCreditCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            serviceselectvalue: "",
            citydistrictselectvalue: "",
            Fullname: "",
            Email: "",
            Mobile_no: "",
            pancard: "",
            netIncome: "",
            averageIncome: "",
            existingcreditcard_yn: "false",
            existingcreditcard_name: "",
            city: "",
            dob: "",
            servicefullname: "",
            serviceemail: "",
            servicemobileno: "",
            occupation: "",
            fathername: "",
            Mothername: "",
            aadhaarno: "",
            pincode: "",
            applicantaddress: "",
            Applicant_Designation: "",
            companyname: "",
            companyaddress: "",
            Govt_Department: "",
            Office_Address: "",
            creditcard_name: "",
            cardlimit: "",
            cardopenmandy: "",
            
supportName:"",
supportContact:"",
supportEmail:"",
        };
    }


    handlePannumberChange = (e) => {
        this.setState({
            Pannumber: e.target.value,
        });
    };


    componentDidMount() {
        window.scrollTo(0, 0);
        $(document.body).css("background-color", "#2d2d2d");
        let mainuserid = localStorage.getItem("relleIsugad");
        Decuserid = encryptor.decrypt(mainuserid);
        let mainjwttoken = localStorage.getItem("toeljgtkewlna");
        let mainagentid = localStorage.getItem("qazxswedcvfrtgb");
        Decagentid = encryptor.decrypt(mainagentid);
        Decjwttoken = encryptor.decrypt(mainjwttoken);
        let mainfullname = sessionStorage.getItem("ielndmlgeaefelvsdrlucaa");
        let maincitydistrict = sessionStorage.getItem("ylcvciretaireditdgisdtacesl");
        let mainservicename = sessionStorage.getItem("srleleddgaeaemvcnia");
        let user__state = sessionStorage.getItem("hjtsrevanxckawshdyeh");
        fullname = encryptor.decrypt(mainfullname);
        citydistrict = encryptor.decrypt(maincitydistrict);
        servicename = encryptor.decrypt(mainservicename);
        servicestate = encryptor.decrypt(user__state);
        supportName = localStorage.getItem("supportName");
        supportContact = localStorage.getItem("supportContact");
        supportEmail = localStorage.getItem("supportEmail");
        
        this.setState({
          supportName:supportName,
          supportContact:supportContact,
          supportEmail:supportEmail,
        })
        this.setState({
            citydistrictselectvalue: citydistrict,
            servicefullname: fullname,
            serviceselectvalue: servicename,
        });

        let str = document.getElementsByClassName("myservicesortbyfilterbystatus").innerHTML = this.props.location.pathname.replaceAll("/", " > ")
        let mainstr = document.getElementsByClassName("myservicesortbyfilterbystatus").innerHTML = str.replace(" > ", " ")
        $(".myservicesortbyfilterbystatus").html(mainstr)
    }

    logout = () => {
        localStorage.removeItem("gglltakoinoeenl");
        localStorage.removeItem("toeljgtkewlna");
        localStorage.removeItem("qazxswedcvfrtgb");
        this.props.history.push({
            pathname: `/`,
        });
    };
    showNotification = (message) => {
        const notifContainer = document.getElementById("notifContainer");
        const notif = document.createElement("div");
        notif.classList.add("notifBox");
        notif.innerText = message;
        notifContainer.appendChild(notif);
        setTimeout(() => $(notif).addClass("pop"), 10);
        setTimeout(() => $(notif).removeClass("pop"), 3000);
        setTimeout(() => $(notif).remove(), 3100);
    };

    filterDistricts = () => {
        servicestate = jsonData[0][this.state.citydistrictselectvalue]
    };



    handlepancardChange = (e) => {
        this.setState({
            pancard: e.target.value.toUpperCase(),
        });
    };
    handleIncomeChange = (e) => {
        this.setState({
            Income: e.target.value.replace(/\D/g, ''),
        });
    };
   


    createPurchaseReqNormal = () => {
        $(".errorside").hide();
        $("#dob1").css({
            "border-bottom-color": "#01355D",
        });
        $("#AnnualProfit2").css({
            "border-bottom-color": "#01355D",
        });
        $("#Salarytype3").css({
            "border-bottom-color": "#01355D",
        });
        $("#Loanamount4").css({
            "border-bottom-color": "#01355D",
        });

        var sep = new Date(this.state.dob);
        var today = new Date();
        var diffD = Math.floor((sep - today) / (1000 * 60 * 60 * 24));

        const mobvnoregex = /^([+]\d{2})?\d{10}$/;
        const contactNoregex = /^[6-9]\d{9}$/;
        const emailregex = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
        const addhaarregex = /(^[0-9]{4}[0-9]{4}[0-9]{4}$)|(^[0-9]{4}\s[0-9]{4}\s[0-9]{4}$)|(^[0-9]{4}-[0-9]{4}-[0-9]{4}$)/;
        var panregex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

        if (this.state.occupation.trim() == "") {
            $(".errorside").show();
            $(".errorsidespan").show().html("Please select Occupation");
            $('.serviceinputbottomcommonclass').css({
                "border-bottom-color": "#01355D",
            });
            $("#Occupation1").css({
                "border-bottom": "2px solid #fe0c0c",
            });
            $("#Occupation1").focus();
        }
        else if (this.state.dob.trim() == "") {
            $('.errorside').show()
            $('.errorsidespan').show().html(
                ' Please enter your date of birth.');
                $('.serviceinputbottomcommonclass').css({
                    "border-bottom-color": "#01355D",
                });
            $('#dob').css({
                'border-bottom-color': '#fe0c0c'
            });
            $('#dob').focus();
        } else if (diffD == -1 || diffD == 0 || diffD > 0) {
            $('.errorside').show()
            $('.errorsidespan').show().html(
                ' Please enter your date of birth.');
                $('.serviceinputbottomcommonclass').css({
                    "border-bottom-color": "#01355D",
                });
            $('#dob').css({
                'border-bottom-color': '#fe0c0c'
            });
            $('#dob').focus();
        } else if (this.state.pancard.trim() == "") {
            $('.errorside').show()
            $('.errorsidespan').show().html(
                'Please enter Pan Card no');
                $('.serviceinputbottomcommonclass').css({
                    "border-bottom-color": "#01355D",
                });
            $('#salariedPan').css({
                'border-bottom-color': '#fe0c0c'
            });
            $('#salariedPan').focus();
        } else if (!panregex.test(this.state.pancard)) {
            $('.errorside').show()
            $('.errorsidespan').show().html(
                'Please enter valid Pan Card no');
                $('.serviceinputbottomcommonclass').css({
                    "border-bottom-color": "#01355D",
                });
            $('#salariedPan').css({
                'border-bottom-color': '#fe0c0c'
            });
            $('#salariedPan').focus();
        }  else if (this.state.fathername.trim() == "") {
            $(".errorside").show();
            $(".errorsidespan").show().html("Please enter father name");
            $('.serviceinputbottomcommonclass').css({
                "border-bottom-color": "#01355D",
            });
            $("#fathernameinput").css({
                "border-bottom": "2px solid #fe0c0c",
            });
            $("#fathernameinput").focus();
        } else if (this.state.Mothername.trim() == "") {
            $(".errorside").show();
            $(".errorsidespan").show().html("Please enter mother name");
            $('.serviceinputbottomcommonclass').css({
                "border-bottom-color": "#01355D",
            });
            $("#Mothernameinput").css({
                "border-bottom": "2px solid #fe0c0c",
            });
            $("#Mothernameinput").focus();
        } else if (this.state.aadhaarno.trim() == "") {
            $(".errorside").show();
            $(".errorsidespan").show().html("Please enter aadhar number");
            $('.serviceinputbottomcommonclass').css({
                "border-bottom-color": "#01355D",
            });
            $("#Aadhaarno").css({
                "border-bottom": "2px solid #fe0c0c",
            });
            $("#Aadhaarno").focus();
        } else if (!addhaarregex.test(this.state.aadhaarno)) {
            $(".errorside").show();
            $(".errorsidespan").show().html("Please enter valid aadhar number");
            $('.serviceinputbottomcommonclass').css({
                "border-bottom-color": "#01355D",
            });
            $("#Aadhaarno").css({
                "border-bottom": "2px solid #fe0c0c",
            });
            $("#Aadhaarno").focus();
        }  
        else if ((this.state.occupation == "SALARIED"||this.state.occupation == "Self Employed Business") && this.state.companyname.trim() == "") {
            $(".errorside").show();
            $(".errorsidespan").show().html("Please enter company name");
            $('.serviceinputbottomcommonclass').css({
                "border-bottom-color": "#01355D",
            });
            $("#companynameinput").css({
                "border-bottom": "2px solid #fe0c0c",
            });
            $("#companynameinput").focus();
        } else if ((this.state.occupation == "SALARIED"||this.state.occupation == "Self Employed Business") && this.state.companyaddress.trim() == "") {
            $(".errorside").show();
            $(".errorsidespan").show().html("Please enter company address");
            $('.serviceinputbottomcommonclass').css({
                "border-bottom-color": "#01355D",
            });
            $("#companyaddressinput").css({
                "border-bottom": "2px solid #fe0c0c",
            });
            $("#companyaddressinput").focus();
        } else if (this.state.pincode.trim() =="") {
            $(".errorside").show();
            $(".errorsidespan").show().html("Please enter pincode");
            $('.serviceinputbottomcommonclass').css({
                "border-bottom-color": "#01355D",
            });
            $("#pincodeinput").css({
                "border-bottom": "2px solid #fe0c0c",
            });
            $("#pincodeinput").focus();
        } else if (this.state.applicantaddress.trim() == "") {
            $(".errorside").show();
            $(".errorsidespan").show().html("Please enter applicant address");
            $('.serviceinputbottomcommonclass').css({
                "border-bottom-color": "#01355D",
            });
            $("#ApplicantAddressinput").css({
                "border-bottom": "2px solid #fe0c0c",
            });
            $("#ApplicantAddressinput").focus();
        } else if (this.state.Applicant_Designation.trim() == "") {
            $(".errorside").show();
            $(".errorsidespan").show().html("Please enter applicant designation");
            $('.serviceinputbottomcommonclass').css({
                "border-bottom-color": "#01355D",
            });
            $("#ApplicantDesignationinput").css({
                "border-bottom": "2px solid #fe0c0c",
            });
            $("#ApplicantDesignationinput").focus();
        } else if (this.state.occupation == "GOVERNMENT JOB" && this.state.Govt_Department.trim() == "") {
            $(".errorside").show();
            $(".errorsidespan").show().html("Please enter govt department");
            $('.serviceinputbottomcommonclass').css({
                "border-bottom-color": "#01355D",
            });
            $("#Govt_department").css({
                "border-bottom": "2px solid #fe0c0c",
            });
            $("#Govt_department").focus();
        } else if (this.state.occupation == "GOVERNMENT JOB" && this.state.Office_Address.trim() == "") {
            $(".errorside").show();
            $(".errorsidespan").show().html("Please enter office address");
            $('.serviceinputbottomcommonclass').css({
                "border-bottom-color": "#01355D",
            });
            $("#Office_address").css({
                "border-bottom": "2px solid #fe0c0c",
            });
            $("#Office_address").focus();
        } else if (this.state.netIncome.trim() == "") {
            $(".errorside").show();
            $(".errorsidespan").show().html("Please enter Monthy Income");
            $('.serviceinputbottomcommonclass').css({
                "border-bottom-color": "#01355D",
            });
            $("#netMonthlyIncome").css({
                 "border-bottom-color": "#fe0c0c",
            });
            $("#netMonthlyIncome").focus();
        } else if (this.state.netIncome < 0) {
            $(".errorside").show();
            $(".errorsidespan")
                .show()
                .html("Please enter greater than 0 Monthy Income");
                $('.serviceinputbottomcommonclass').css({
                    "border-bottom-color": "#01355D",
                });
            $("#netMonthlyIncome").css({
                 "border-bottom-color": "#fe0c0c",
            });
            $("#netMonthlyIncome").focus();
        }   else if (
            this.state.existingcreditcard_yn == "true" &&
            this.state.existingcreditcard_name.trim() == ""
        ) {
            $(".errorside").show();
            $(".errorsidespan").show().html("Please select bank");
            $('.serviceinputbottomcommonclass').css({
                "border-bottom-color": "#01355D",
            });
            $("#cc-bank").css({
                "border-bottom-color": "#fe0c0c",
            });
    
            $("#cc-bank").focus();
        } else if (
            this.state.existingcreditcard_yn == "true" &&
            this.state.existingcreditcard_name == "OTHER" &&
            this.state.creditcard_name.trim() == ""
        ) {
            $(".errorside").show();
            $(".errorsidespan").show().html("Please enter bank name");
            $('.serviceinputbottomcommonclass').css({
                "border-bottom-color": "#01355D",
            });
            $("#card_name").css({
                 "border-bottom-color": "#fe0c0c",
            });
    
            $("#card_name").focus();
        } else if (
            this.state.existingcreditcard_yn == "true" &&
            this.state.cardlimit.trim() == ""
        ) {
            $(".errorside").show();
            $(".errorsidespan").show().html("Please enter card limit");
            $('.serviceinputbottomcommonclass').css({
                "border-bottom-color": "#01355D",
            });
            $("#card_limit").css({
                "border-bottom-color": "#fe0c0c",
            });
    
            $("#card_limit").focus();
        } else if (
            this.state.existingcreditcard_yn == "true" &&
            this.state.cardopenmandy.trim() == ""
        ) {
            $(".errorside").show();
            $(".errorsidespan").show().html("Please select card opening month and year");
            $('.serviceinputbottomcommonclass').css({
                "border-bottom-color": "#01355D",
            });
            $("#card_mandy").css({
                 "border-bottom-color": "#fe0c0c",
            });
    
            $("#card_mandy").focus();
        }  else {
            $(".errorside").hide();
            this.filterDistricts();
            $(".proceednowbutton").hide();
            $(".addserviceloader").show();
            var fathername = this.state.fathername;
            const fathernamestr = fathername.split(" ");
    
            for (var i = 0; i < fathernamestr.length; i++) {
                fathernamestr[i] = fathernamestr[i].charAt(0).toUpperCase() + fathernamestr[i].slice(1);
            }
            const fathernamestr2 = fathernamestr.join(" ");


            var mothername = this.state.Mothername;
            const mothernamestr = mothername.split(" ");
    
            for (var i = 0; i < mothernamestr.length; i++) {
                mothernamestr[i] = mothernamestr[i].charAt(0).toUpperCase() + mothernamestr[i].slice(1);
            }
            const mothernamestr2 = mothernamestr.join(" ");
            axios
                .post(
                    process.env.REACT_APP_API_BASE_URL + "p=createLoanPurchase",
                    {
                        userId: Decuserid,
                        agentId: Decagentid,
                        serviceName: "CRED",
                        fname: this.state.servicefullname,
                        occupation: this.state.occupation,
                        city: this.state.citydistrictselectvalue,
                        state: servicestate,
                        dob: this.state.dob,
                        monthlyincome: this.state.netIncome,
                        pancard: this.state.pancard,
                        bankname: "Induslnd Bank",
                        isExisting: this.state.existingcreditcard_yn,
                        isExistingbankname: this.state.existingcreditcard_name,
                        adharCard: this.state.aadhaarno,
                        fatherName: fathernamestr2,
                        motherName: mothernamestr2,
                        companyName: this.state.companyname,
                        companyAddress: this.state.companyaddress,
                        pinCode: this.state.pincode,
                        applicantAddress: this.state.applicantaddress,
                        applicantDesignation: this.state.Applicant_Designation,
                        govtDepartment: this.state.Govt_Department,
                        officeAddress: this.state.Office_Address,
                        creditcard_name: this.state.creditcard_name,
                        existingCardLimit: this.state.cardlimit,
                        openingMonthYear: this.state.cardopenmandy,
                        comeFrom:"AGP"
                    },
                    {
                        headers: {
                            Authkey: process.env.REACT_APP_API_KEY,
                            "App-Token-Access": Decjwttoken,
                        },
                        auth: {
                            username: process.env.REACT_APP_API_USERNAME,
                            password: process.env.REACT_APP_API_PASSWORD,
                        },
                    }
                )
                .then((res) => {
                    if (res.data.code == "200" && res.data.status == "success") {
                        $(".proceednowbutton").show();
                        $(".addserviceloader").hide();

                        this.props.history.push({
                            pathname: `/addservice/paymentsuccessfull2`,
                            state: {
                                servicerequestid: res.data.ServiceId,
                            },
                        });
                    } else if (
                        res.data.code == "201" &&
                        res.data.status == "invalidauth"
                    ) {
                        this.logout();
                    } else {
                        $(".proceednowbutton").show();
                        $(".addserviceloader").hide();
                        this.showNotification(res.data.message);
                    }
                })
                .catch((err) => { });
        }
    };


    backbutton = () => {
        this.props.history.push({
            pathname: `/addservice/${Decuserid}/addservice`,
        });
    };
    citydistrictselecthandle = (event) => {
        this.setState({
            citydistrictselectvalue: event.target.value,
        });

    };
    handlefullnameChange = (e) => {
        this.setState({
            Fullname: e.target.value,
        });
    };
    handleserviceserviceemailChange = (e) => {
        this.setState({
            serviceemail: e.target.value.toLowerCase(),
        });
    };
    handlemobilenoChange = (e) => {
        this.setState({
            Mobile_no: e.target.value,
        });
    };

    handledobChange = (e) => {
        this.setState({
            dob: e.target.value,

        });

    };
   

    handlenetIncome = (e) => {
        this.setState({
            netIncome: e.target.value.replace(/\D/g, ''),
        });
    }

 

    handleexistingcardradio = (e) => {
        this.setState({
            existingcreditcard_yn: e.target.value,
        });
        if (e.target.value == "true") {
            $(".dnone").css("display", "block")
        } else if (e.target.value == "false") {
            $(".dnone").css("display", "none")
            $('.errorsidespan').show().html(
                '');
        }
    }

    handleexistingcreditcardname = (e) => {
        this.setState({
            existingcreditcard_name: e.target.value,
        });
    }

    handleemail = (e) => {
        this.setState({
            Email: e.target.value,
        });
    }

    handleOccupationChange = (e) => {
        this.setState({
            occupation: e.target.value,
        });
    };

    

handlefathernameChange = (e) => {
    this.setState({
        fathername: e.target.value,
    });
}

handleMothernameChange = (e) => {
    this.setState({
        Mothername: e.target.value,
    });
}

handleaadharnoChange = (e) => {
    this.setState({
        aadhaarno: e.target.value.replace(/\D/g,''),
    });
}

handlecompanynameChange = (e) => {
    this.setState({
        companyname: e.target.value,
    });
}

handlecompanyaddressChange = (e) => {
    this.setState({
        companyaddress: e.target.value,
    });
}

handlepincodeChange = (e) => {
    this.setState({
        pincode: e.target.value.replace(/\D/g, ''),
    });
}

handleApplicantaddress = (e) => {
    this.setState({
        applicantaddress: e.target.value,
    });
}


handleApplicantdesignation = (e) => {
    this.setState({
        Applicant_Designation: e.target.value,
    });
}

handleGovdepartment = (e) => {
    this.setState({
        Govt_Department: e.target.value,
    });
}

handleOfficeaddress = (e) => {
    this.setState({
        Office_Address: e.target.value,
    });
}


handlecreditcardname = (e) => {
    this.setState({
        creditcard_name: e.target.value,
    });
}



handlecardlimit = (e) => {
    this.setState({
        cardlimit: e.target.value.replace(/\D/g, ''),
    });
}

handlecardopenmandy = (e) => {
    this.setState({
        cardopenmandy: e.target.value,
    });
}

handlesupport = () => {
    var x = document.getElementById("myDIV");
    if (x.style.display == "block") {
        x.style.display = "none";
      }else {
        x.style.display = "block";
      }
  }

    render() {


        if (
            localStorage.getItem("gglltakoinoeenl") == process.env.REACT_APP_LOGIN_KEY &&
            localStorage.getItem("toeljgtkewlna") != null &&
            localStorage.getItem("qazxswedcvfrtgb") != null
        ) {
            return (
                <div className="cont">
                    <div id="notifContainer"></div>
                    <Helmet>
                    <link rel="stylesheet" href={process.env.PUBLIC_URL + "/css/select2.css"} />
                    </Helmet>

                    <Header />

                    <img
                        src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
                        className="sidebaropenicon"
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
                        onClick={this.backbutton}
                        className="sidebarbackicon"
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"}
                        className="sidebarexpandicon"
                    />

                    <div className="overlay1"></div>
                    <div className="sidebar sidebarshift">
                        <div className="side_div_2 sidebar_txt active">
                            <Link to="/dashboard">Overview</Link>
                        </div>
                        <div className="side_div_1 sidebar_txt">
                            <Link to="/myservices">My Services</Link>
                        </div>
                        <div className="side_div_1 sidebar_txt">
                            <a href="#">Payments Invoices</a>
                        </div>
                        <div className="side_div_1 sidebar_txt">
                            <a href="#">Documents Record</a>
                        </div>
                        <div className="side_div_1 sidebar_txt">
                            <a href="#">Add service</a>
                        </div>
                        <div></div>
                    </div>
                    <div className="arrow-left2 visibilityhidden"></div>
                    <div className="white_div expandwhite_div">
                        <div className="white_box">
                            <div className="white_boxscrolldiv">
                                <center>
                                    {" "}
                                    <p className="download">Add Service</p>
                                </center>
                                <div className="row addserivemaindiv">
                                    <div className="col-md-4">
                                        <div className="addservicetextinputdiv">
                                            <div>
                                                {" "}
                                                <label className="adddervicelabel">Full name:-</label>
                                            </div>
                                            <div>
                                                <input
                                                    type="text"
                                                    className="addservicetextinput"
                                                    value={this.state.servicefullname}
                                                    placeholder="Type here..."
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div>
                                            <center>
                                                <div>
                                                    <label className="adddervicelabel">Select service:-</label>
                                                </div>

                                                <div className="addserviceselectdiv">
                                                    <Select2
                                                        value={this.state.citydistrictselectvalue}
                                                        data={[
                                                            "Adilabad",
                                                            "Agar Malwa",
                                                            "Agra",
                                                            "Ahmedabad",
                                                            "Ahmednagar",
                                                            "Aizawl",
                                                            "Ajmer",
                                                            "Akola",
                                                            "Alappuzha",
                                                            "Aligarh",
                                                            "Alipurduar",
                                                            "Alirajpur",
                                                            "Allahabad",
                                                            "Almora",
                                                            "Alwar",
                                                            "Ambala",
                                                            "Ambedkar Nagar",
                                                            "Amethi (Chatrapati Sahuji Mahraj Nagar)",
                                                            "Amravati",
                                                            "Amreli",
                                                            "Amritsar",
                                                            "Amroha",
                                                            "Anand",
                                                            "Anantapur",
                                                            "Anantnag",
                                                            "Angul",
                                                            "Anjaw",
                                                            "Anuppur",
                                                            "Araria",
                                                            "Aravalli",
                                                            "Ariyalur",
                                                            "Arwal",
                                                            "Ashoknagar",
                                                            "Auraiya",
                                                            "Aurangabad (BH)",
                                                            "Aurangabad (MH)",
                                                            "Azamgarh",
                                                            "Bagalkot",
                                                            "Bageshwar",
                                                            "Baghpat",
                                                            "Bahraich",
                                                            "Baksa",
                                                            "Balaghat",
                                                            "Balangir",
                                                            "Balasore",
                                                            "Ballari (Bellary)",
                                                            "Ballia",
                                                            "Balod",
                                                            "Baloda Bazar",
                                                            "Balrampur (CG)",
                                                            "Balrampur (UP)",
                                                            "Banaskantha (Palanpur)",
                                                            "Banda",
                                                            "Bandipore",
                                                            "Banka",
                                                            "Bankura",
                                                            "Banswara",
                                                            "Barabanki",
                                                            "Baramulla",
                                                            "Baran",
                                                            "Bareilly",
                                                            "Bargarh",
                                                            "Barmer",
                                                            "Barnala",
                                                            "Barpeta",
                                                            "Barwani",
                                                            "Bastar",
                                                            "Basti",
                                                            "Bathinda",
                                                            "Beed",
                                                            "Begusarai",
                                                            "Belagavi (Belgaum)",
                                                            "Bemetara",
                                                            "Bengaluru (Bangalore) Rural",
                                                            "Bengaluru (Bangalore) Urban",
                                                            "Betul",
                                                            "Bhadohi",
                                                            "Bhadradri Kothagudem",
                                                            "Bhadrak",
                                                            "Bhagalpur",
                                                            "Bhandara",
                                                            "Bharatpur",
                                                            "Bharuch",
                                                            "Bhavnagar",
                                                            "Bhilwara",
                                                            "Bhind",
                                                            "Bhiwani",
                                                            "Bhojpur",
                                                            "Bhopal",
                                                            "Bidar",
                                                            "Bijapur",
                                                            "Bijnor",
                                                            "Bikaner",
                                                            "Bilaspur (CG)",
                                                            "Bilaspur (HP)",
                                                            "Birbhum",
                                                            "Bishnupur",
                                                            "Biswanath",
                                                            "Bokaro",
                                                            "Bongaigaon",
                                                            "Botad",
                                                            "Boudh",
                                                            "Budaun",
                                                            "Budgam",
                                                            "Bulandshahr",
                                                            "Buldhana",
                                                            "Bundi",
                                                            "Burhanpur",
                                                            "Buxar",
                                                            "Cachar",
                                                            "Central Delhi",
                                                            "Chamarajanagar",
                                                            "Chamba",
                                                            "Chamoli",
                                                            "Champawat",
                                                            "Champhai",
                                                            "Chandauli",
                                                            "Chandel",
                                                            "Chandigarh",
                                                            "Chandrapur",
                                                            "Changlang",
                                                            "Charaideo",
                                                            "Charkhi Dadri",
                                                            "Chatra",
                                                            "Chengalpattu",
                                                            "Chennai",
                                                            "Chhatarpur",
                                                            "Chhindwara",
                                                            "Chhota Udepur",
                                                            "Chikballapur",
                                                            "Chikkamagaluru (Chikmagalur)",
                                                            "Chirang",
                                                            "Chitradurga",
                                                            "Chitrakoot",
                                                            "Chittoor",
                                                            "Chittorgarh",
                                                            "Churachandpur",
                                                            "Churu",
                                                            "Coimbatore",
                                                            "Cooch Behar",
                                                            "Cuddalore",
                                                            "Cuttack",
                                                            "Dadra & Nagar Haveli",
                                                            "Dahod",
                                                            "Dakshin Dinajpur (South Dinajpur)",
                                                            "Dakshina Kannada",
                                                            "Daman",
                                                            "Damoh",
                                                            "Dangs (Ahwa)",
                                                            "Dantewada (South Bastar)",
                                                            "Darbhanga",
                                                            "Darjeeling",
                                                            "Darrang",
                                                            "Datia",
                                                            "Dausa",
                                                            "Davangere",
                                                            "Dehradun",
                                                            "Deogarh",
                                                            "Deoghar",
                                                            "Deoria",
                                                            "Devbhoomi Dwarka",
                                                            "Dewas",
                                                            "Dhalai",
                                                            "Dhamtari",
                                                            "Dhanbad",
                                                            "Dhar",
                                                            "Dharmapuri",
                                                            "Dharwad",
                                                            "Dhemaji",
                                                            "Dhenkanal",
                                                            "Dholpur",
                                                            "Dhubri",
                                                            "Dhule",
                                                            "Dibang Valley",
                                                            "Dibrugarh",
                                                            "Dima Hasao (North Cachar Hills)",
                                                            "Dimapur",
                                                            "Dindigul",
                                                            "Dindori",
                                                            "Diu",
                                                            "Doda",
                                                            "Dumka",
                                                            "Dungarpur",
                                                            "Durg",
                                                            "East Champaran (Motihari)",
                                                            "East Delhi",
                                                            "East Garo Hills",
                                                            "East Godavari",
                                                            "East Jaintia Hills",
                                                            "East Kameng",
                                                            "East Khasi Hills",
                                                            "East Siang",
                                                            "East Sikkim",
                                                            "East Singhbhum",
                                                            "Ernakulam",
                                                            "Erode",
                                                            "Etah",
                                                            "Etawah",
                                                            "Faizabad",
                                                            "Faridabad",
                                                            "Faridkot",
                                                            "Farrukhabad",
                                                            "Fatehabad",
                                                            "Fatehgarh Sahib",
                                                            "Fatehpur",
                                                            "Fazilka",
                                                            "Ferozepur",
                                                            "Firozabad",
                                                            "Gadag",
                                                            "Gadchiroli",
                                                            "Gajapati",
                                                            "Ganderbal",
                                                            "Gandhinagar",
                                                            "Ganjam",
                                                            "Garhwa",
                                                            "Gariyaband",
                                                            "Gautam Buddha Nagar",
                                                            "Gaya",
                                                            "Ghaziabad",
                                                            "Ghazipur",
                                                            "Gir Somnath",
                                                            "Giridih",
                                                            "Goalpara",
                                                            "Godda",
                                                            "Golaghat",
                                                            "Gomati",
                                                            "Gonda",
                                                            "Gondia",
                                                            "Gopalganj",
                                                            "Gorakhpur",
                                                            "Gumla",
                                                            "Guna",
                                                            "Guntur",
                                                            "Gurdaspur",
                                                            "Gurugram (Gurgaon)",
                                                            "Gwalior",
                                                            "Hailakandi",
                                                            "Hamirpur (HP)",
                                                            "Hamirpur (UP)",
                                                            "Hanumangarh",
                                                            "Hapur (Panchsheel Nagar)",
                                                            "Harda",
                                                            "Hardoi",
                                                            "Haridwar",
                                                            "Hassan",
                                                            "Hathras",
                                                            "Haveri",
                                                            "Hazaribag",
                                                            "Hingoli",
                                                            "Hisar",
                                                            "Hojai",
                                                            "Hooghly",
                                                            "Hoshangabad",
                                                            "Hoshiarpur",
                                                            "Howrah",
                                                            "Hyderabad",
                                                            "Idukki",
                                                            "Imphal East",
                                                            "Imphal West",
                                                            "Indore",
                                                            "Jabalpur",
                                                            "Jagatsinghapur",
                                                            "Jagtial",
                                                            "Jaipur",
                                                            "Jaisalmer",
                                                            "Jajpur",
                                                            "Jalandhar",
                                                            "Jalaun",
                                                            "Jalgaon",
                                                            "Jalna",
                                                            "Jalore",
                                                            "Jalpaiguri",
                                                            "Jammu",
                                                            "Jamnagar",
                                                            "Jamtara",
                                                            "Jamui",
                                                            "Jangaon",
                                                            "Janjgir-Champa",
                                                            "Jashpur",
                                                            "Jaunpur",
                                                            "Jayashankar Bhoopalpally",
                                                            "Jehanabad",
                                                            "Jhabua",
                                                            "Jhajjar",
                                                            "Jhalawar",
                                                            "Jhansi",
                                                            "Jhargram",
                                                            "Jharsuguda",
                                                            "Jhunjhunu",
                                                            "Jind",
                                                            "Jiribam",
                                                            "Jodhpur",
                                                            "Jogulamba Gadwal",
                                                            "Jorhat",
                                                            "Junagadh",
                                                            "Kabirdham (Kawardha)",
                                                            "Kachchh",
                                                            "Kaimur (Bhabua)",
                                                            "Kaithal",
                                                            "Kakching",
                                                            "Kalaburagi (Gulbarga)",
                                                            "Kalahandi",
                                                            "Kalimpong",
                                                            "Kallakurichi",
                                                            "Kamareddy",
                                                            "Kamjong",
                                                            "Kamle",
                                                            "Kamrup",
                                                            "Kamrup Metropolitan",
                                                            "Kanchipuram",
                                                            "Kandhamal",
                                                            "Kangpokpi",
                                                            "Kangra",
                                                            "Kanker (North Bastar)",
                                                            "Kannauj",
                                                            "Kannur",
                                                            "Kanpur Dehat",
                                                            "Kanpur Nagar",
                                                            "Kanshiram Nagar (Kasganj)",
                                                            "Kanyakumari",
                                                            "Kapurthala",
                                                            "Karaikal",
                                                            "Karauli",
                                                            "Karbi Anglong",
                                                            "Kargil",
                                                            "Karimganj",
                                                            "Karimnagar",
                                                            "Karnal",
                                                            "Karur",
                                                            "Kasaragod",
                                                            "Kathua",
                                                            "Katihar",
                                                            "Katni",
                                                            "Kaushambi",
                                                            "Kendrapara",
                                                            "Kendujhar (Keonjhar)",
                                                            "Khagaria",
                                                            "Khammam",
                                                            "Khandwa",
                                                            "Khargone",
                                                            "Kheda (Nadiad)",
                                                            "Khordha",
                                                            "Khowai",
                                                            "Khunti",
                                                            "Kinnaur",
                                                            "Kiphire",
                                                            "Kishanganj",
                                                            "Kishtwar",
                                                            "Kodagu",
                                                            "Koderma",
                                                            "Kohima",
                                                            "Kokrajhar",
                                                            "Kolar",
                                                            "Kolasib",
                                                            "Kolhapur",
                                                            "Kolkata",
                                                            "Kollam",
                                                            "Komaram Bheem Asifabad",
                                                            "Kondagaon",
                                                            "Koppal",
                                                            "Koraput",
                                                            "Korba",
                                                            "Korea (Koriya)",
                                                            "Kota",
                                                            "Kottayam",
                                                            "Kozhikode",
                                                            "Kra Daadi",
                                                            "Krishna",
                                                            "Krishnagiri",
                                                            "Kulgam",
                                                            "Kullu",
                                                            "Kupwara",
                                                            "Kurnool",
                                                            "Kurukshetra",
                                                            "Kurung Kumey",
                                                            "Kushinagar (Padrauna)",
                                                            "Lahaul & Spiti",
                                                            "Lakhimpur",
                                                            "Lakhimpur - Kheri",
                                                            "Lakhisarai",
                                                            "Lakshadweep",
                                                            "Lalitpur",
                                                            "Latehar",
                                                            "Latur",
                                                            "Lawngtlai",
                                                            "Leh",
                                                            "Lepa Rada",
                                                            "Lohardaga",
                                                            "Lohit",
                                                            "Longding",
                                                            "Longleng",
                                                            "Lower Dibang Valley",
                                                            "Lower Siang",
                                                            "Lower Subansiri",
                                                            "Lucknow",
                                                            "Ludhiana",
                                                            "Lunglei",
                                                            "Madhepura",
                                                            "Madhubani",
                                                            "Madurai",
                                                            "Mahabubabad",
                                                            "Mahabubnagar",
                                                            "Maharajganj",
                                                            "Mahasamund",
                                                            "Mahe",
                                                            "Mahendragarh",
                                                            "Mahisagar",
                                                            "Mahoba",
                                                            "Mainpuri",
                                                            "Majuli",
                                                            "Malappuram",
                                                            "Malda",
                                                            "Malkangiri",
                                                            "Mamit",
                                                            "Mancherial",
                                                            "Mandi",
                                                            "Mandla",
                                                            "Mandsaur",
                                                            "Mandya",
                                                            "Mansa",
                                                            "Mathura",
                                                            "Mau",
                                                            "Mayurbhanj",
                                                            "Medak",
                                                            "Medchal",
                                                            "Meerut",
                                                            "Mehsana",
                                                            "Mirzapur",
                                                            "Moga",
                                                            "Mokokchung",
                                                            "Mon",
                                                            "Moradabad",
                                                            "Morbi",
                                                            "Morena",
                                                            "Morigaon",
                                                            "Muktsar",
                                                            "Mumbai City",
                                                            "Mumbai Suburban",
                                                            "Mungeli",
                                                            "Munger (Monghyr)",
                                                            "Murshidabad",
                                                            "Muzaffarnagar",
                                                            "Muzaffarpur",
                                                            "Mysuru (Mysore)",
                                                            "Nabarangpur",
                                                            "Nadia",
                                                            "Nagaon",
                                                            "Nagapattinam",
                                                            "Nagarkurnool",
                                                            "Nagaur",
                                                            "Nagpur",
                                                            "Nainital",
                                                            "Nalanda",
                                                            "Nalbari",
                                                            "Nalgonda",
                                                            "Namakkal",
                                                            "Namsai",
                                                            "Nanded",
                                                            "Nandurbar",
                                                            "Narayanpur",
                                                            "Narmada (Rajpipla)",
                                                            "Narsinghpur",
                                                            "Nashik",
                                                            "Navsari",
                                                            "Nawada",
                                                            "Nawanshahr (Shahid Bhagat Singh Nagar)",
                                                            "Nayagarh",
                                                            "Neemuch",
                                                            "New Delhi",
                                                            "Nicobar",
                                                            "Nilgiris",
                                                            "Nirmal",
                                                            "Nizamabad",
                                                            "Noney",
                                                            "North 24 Parganas",
                                                            "North Delhi",
                                                            "North East Delhi",
                                                            "North Garo Hills",
                                                            "North Goa",
                                                            "North Sikkim",
                                                            "North Tripura",
                                                            "North West Delhi",
                                                            "North and Middle Andaman",
                                                            "Nuapada",
                                                            "Nuh",
                                                            "Osmanabad",
                                                            "Pakke Kessang",
                                                            "Pakur",
                                                            "Palakkad",
                                                            "Palamu",
                                                            "Palghar",
                                                            "Pali",
                                                            "Palwal",
                                                            "Panchkula",
                                                            "Panchmahal (Godhra)",
                                                            "Panipat",
                                                            "Panna",
                                                            "Papum Pare",
                                                            "Parbhani",
                                                            "Paschim (West) Burdwan (Bardhaman)",
                                                            "Paschim Medinipur (West Medinipur)",
                                                            "Patan",
                                                            "Pathanamthitta",
                                                            "Pathankot",
                                                            "Patiala",
                                                            "Patna",
                                                            "Pauri Garhwal",
                                                            "Peddapalli",
                                                            "Perambalur",
                                                            "Peren",
                                                            "Phek",
                                                            "Pherzawl",
                                                            "Pilibhit",
                                                            "Pithoragarh",
                                                            "Poonch",
                                                            "Porbandar",
                                                            "Prakasam",
                                                            "Pratapgarh (RJ)",
                                                            "Pratapgarh (UP)",
                                                            "Puducherry",
                                                            "Pudukkottai",
                                                            "Pulwama",
                                                            "Pune",
                                                            "Purba Burdwan (Bardhaman)",
                                                            "Purba Medinipur (East Medinipur)",
                                                            "Puri",
                                                            "Purnia (Purnea)",
                                                            "Purulia",
                                                            "RaeBareli",
                                                            "Raichur",
                                                            "Raigad",
                                                            "Raigarh",
                                                            "Raipur",
                                                            "Raisen",
                                                            "Rajanna Sircilla",
                                                            "Rajgarh",
                                                            "Rajkot",
                                                            "Rajnandgaon",
                                                            "Rajouri",
                                                            "Rajsamand",
                                                            "Ramanagara",
                                                            "Ramanathapuram",
                                                            "Ramban",
                                                            "Ramgarh",
                                                            "Rampur",
                                                            "Ranchi",
                                                            "Rangareddy",
                                                            "Ranipet",
                                                            "Ratlam",
                                                            "Ratnagiri",
                                                            "Rayagada",
                                                            "Reasi",
                                                            "Rewa",
                                                            "Rewari",
                                                            "Ri Bhoi",
                                                            "Rohtak",
                                                            "Rohtas",
                                                            "Rudraprayag",
                                                            "Rupnagar",
                                                            "Sabarkantha (Himmatnagar)",
                                                            "Sagar",
                                                            "Saharanpur",
                                                            "Saharsa",
                                                            "Sahibganj",
                                                            "Sahibzada Ajit Singh Nagar (Mohali)",
                                                            "Saiha",
                                                            "Salem",
                                                            "Samastipur",
                                                            "Samba",
                                                            "Sambalpur",
                                                            "Sambhal (Bhim Nagar)",
                                                            "Sangareddy",
                                                            "Sangli",
                                                            "Sangrur",
                                                            "Sant Kabir Nagar",
                                                            "Saran",
                                                            "Satara",
                                                            "Satna",
                                                            "Sawai Madhopur",
                                                            "Sehore",
                                                            "Senapati",
                                                            "Seoni",
                                                            "Sepahijala",
                                                            "Seraikela-Kharsawan",
                                                            "Serchhip",
                                                            "Shahdara",
                                                            "Shahdol",
                                                            "Shahjahanpur",
                                                            "Shajapur",
                                                            "Shamali (Prabuddh Nagar)",
                                                            "Sheikhpura",
                                                            "Sheohar",
                                                            "Sheopur",
                                                            "Shi Yomi",
                                                            "Shimla",
                                                            "Shivamogga (Shimoga)",
                                                            "Shivpuri",
                                                            "Shopian",
                                                            "Shravasti",
                                                            "Siang",
                                                            "Siddharth Nagar",
                                                            "Siddipet",
                                                            "Sidhi",
                                                            "Sikar",
                                                            "Simdega",
                                                            "Sindhudurg",
                                                            "Singrauli",
                                                            "Sirmaur (Sirmour)",
                                                            "Sirohi",
                                                            "Sirsa",
                                                            "Sitamarhi",
                                                            "Sitapur",
                                                            "Sivaganga",
                                                            "Sivasagar",
                                                            "Siwan",
                                                            "Solan",
                                                            "Solapur",
                                                            "Sonbhadra",
                                                            "Sonepur",
                                                            "Sonipat",
                                                            "Sonitpur",
                                                            "South 24 Parganas",
                                                            "South Andaman",
                                                            "South Delhi",
                                                            "South East Delhi",
                                                            "South Garo Hills",
                                                            "South Goa",
                                                            "South Salamara-Mankachar",
                                                            "South Sikkim",
                                                            "South Tripura",
                                                            "South West Delhi",
                                                            "South West Garo Hills",
                                                            "South West Khasi Hills",
                                                            "Sri Ganganagar",
                                                            "Sri Potti Sriramulu Nellore ",
                                                            "Srikakulam",
                                                            "Srinagar",
                                                            "Sukma",
                                                            "Sultanpur",
                                                            "Sundargarh",
                                                            "Supaul",
                                                            "Surajpur",
                                                            "Surat",
                                                            "Surendranagar",
                                                            "Surguja",
                                                            "Suryapet",
                                                            "Tamenglong",
                                                            "Tapi (Vyara)",
                                                            "Tarn Taran",
                                                            "Tawang",
                                                            "Tehri Garhwal",
                                                            "Tengnoupal",
                                                            "Tenkasi",
                                                            "Thane",
                                                            "Thanjavur",
                                                            "Theni",
                                                            "Thiruvananthapuram",
                                                            "Thoothukudi (Tuticorin)",
                                                            "Thoubal",
                                                            "Thrissur",
                                                            "Tikamgarh",
                                                            "Tinsukia",
                                                            "Tirap",
                                                            "Tiruchirappalli",
                                                            "Tirunelveli",
                                                            "Tirupathur",
                                                            "Tiruppur",
                                                            "Tiruvallur",
                                                            "Tiruvannamalai",
                                                            "Tiruvarur",
                                                            "Tonk",
                                                            "Tuensang",
                                                            "Tumakuru (Tumkur)",
                                                            "Udaipur",
                                                            "Udalguri",
                                                            "Udham Singh Nagar",
                                                            "Udhampur",
                                                            "Udupi",
                                                            "Ujjain",
                                                            "Ukhrul",
                                                            "Umaria",
                                                            "Una",
                                                            "Unakoti",
                                                            "Unnao",
                                                            "Upper Siang",
                                                            "Upper Subansiri",
                                                            "Uttar Dinajpur (North Dinajpur)",
                                                            "Uttara Kannada (Karwar)",
                                                            "Uttarkashi",
                                                            "Vadodara",
                                                            "Vaishali",
                                                            "Valsad",
                                                            "Varanasi",
                                                            "Vellore",
                                                            "Vidisha",
                                                            "Vijayapura (Bijapur)",
                                                            "Vikarabad",
                                                            "Viluppuram",
                                                            "Virudhunagar",
                                                            "Visakhapatnam",
                                                            "Vizianagaram",
                                                            "Wanaparthy",
                                                            "Warangal (Rural)",
                                                            "Warangal (Urban)",
                                                            "Wardha",
                                                            "Washim",
                                                            "Wayanad",
                                                            "West Champaran",
                                                            "West Delhi",
                                                            "West Garo Hills",
                                                            "West Godavari",
                                                            "West Jaintia Hills",
                                                            "West Kameng",
                                                            "West Karbi Anglong",
                                                            "West Khasi Hills",
                                                            "West Siang",
                                                            "West Sikkim",
                                                            "West Singhbhum",
                                                            "West Tripura",
                                                            "Wokha",
                                                            "YSR District, Kadapa (Cuddapah)",
                                                            "Yadadri Bhuvanagiri",
                                                            "Yadgir",
                                                            "Yamunanagar",
                                                            "Yanam",
                                                            "Yavatmal",
                                                            "Zunheboto",
                                                        ]}
                                                        options={{
                                                            placeholder: "Select your city/district...",
                                                        }}
                                                        disabled
                                                    />
                                                </div>
                                            </center>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div>
                                            <div>
                                                <label className="adddervicelabel">Select service:-</label>
                                            </div>
                                            <div className="addserviceselectdiv">
                                                <Select2
                                                    value={this.state.serviceselectvalue}
                                                    data={[
                                                        "GST Registration",
                                                        "GST Return Filing",
                                                        "ITR Filing",
                                                        "Trademark Registration",
                                                        "Gumasta Licence (S&E)",
                                                        "Trade Licence",
                                                        "Food Licence",
                                                        "Business Loan",
                                                        "Indusind Bank Credit Card"
                                                    ]}
                                                    options={{
                                                        placeholder: "Select service...",
                                                    }}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row addserivemaindiv">
                                    <div className="col-md-7">
                                        <div className="servicecol2">
                                            <p className="serviceamainheading">Indusland Bank CreditCard</p>
                                            <p className="serviceamainheadingtext">
                                            Now apply for your IndusInd Bank Credit Card with the help of <br />
                                            our professionals and start unboxing your happiness.
                                            </p>










                                            <div id="formpricediv">
                                                <div className="service1formdiv">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <select
                                                                className="serviceinputselect2 sp-Margin serviceinputbottomcommonclass"
                                                                id="Occupation1"
                                                                value={this.state.occupation}
                                                                onChange={this.handleOccupationChange}>
                                                                <option value="">Select Employment Type</option>
                                                                <option value="SALARIED">Salaried</option>
                                                                <option value="HOUSE PERSON">
                                                                    House Person
                                                                </option>
                                                                <option value="RETIRED">Retired</option>
                                                                <option value="GOVERNMENT JOB">
                                                                    Government Job
                                                                </option>
                                                                <option value="SELF EMPLOYED BUSINESS">
                                                                    Self Employed Business
                                                                </option>
                                                                <option value="SELF EMPLOYED PROFESSIONAL ">
                                                                    Self Employed Professional
                                                                </option>
                                                                <option value="STUDENT">Student</option>
                                                            </select>
                                                            <br />
                                                            <br />
                                                            <input
                                                                type="text"
                                                                pattern="[A-Za-z ]{3,150}"
                                                                className=" serviceinput capatalizeMe serviceinputbottomcommonclass"
                                                                name="fathername"
                                                                maxLength="100"
                                                                id="fathernameinput"
                                                                value={this.state.fathername}
                                                                onChange={this.handlefathernameChange}
                                                                placeholder="Father Name"
                                                            />
                                                            <br />
                                                            <br />
                                                            <input
                                                                type="text"
                                                                pattern="[A-Za-z ]{3,150}"
                                                                className=" serviceinput capatalizeMe serviceinputbottomcommonclass"
                                                                name="mothername"
                                                                maxLength="100"
                                                                id="Mothernameinput"
                                                                value={this.state.Mothername}
                                                                onChange={this.handleMothernameChange}
                                                                placeholder="Mother Name"
                                                            />
                                                            <br />
                                                            <br />
                                                            <input
                                                                type="text"
                                                                className=" serviceinput serviceinputbottomcommonclass"
                                                                name=""
                                                                maxLength="12"
                                                                id="Aadhaarno"
                                                                value={this.state.aadhaarno}
                                                                onChange={this.handleaadharnoChange}
                                                                placeholder="Aadhaar Number"
                                                            />
                                                            {this.state.occupation == "SALARIED" || this.state.occupation == "SELF EMPLOYED BUSINESS" || this.state.occupation == "SELF EMPLOYED PROFESSIONAL" ? (
                                                                <>
                                                                    <br />
                                                                    <br />
                                                                    <input
                                                                        type="text"
                                                                        pattern="[A-Za-z ]{3,150}"
                                                                        className=" serviceinput capatalizeMe serviceinputbottomcommonclass"
                                                                        name="Company/ Business name"
                                                                        maxLength="100"
                                                                        id="companynameinput"
                                                                        value={this.state.companyname}
                                                                        onChange={this.handlecompanynameChange}
                                                                        placeholder="Company Name"
                                                                    />

                                                                    <br />
                                                                    <br />
                                                                    <input
                                                                        type="text"
                                                                        pattern="[A-Za-z ]{3,150}"
                                                                        className=" serviceinput capatalizeMe serviceinputbottomcommonclass"
                                                                        name="Company / Business Address"
                                                                        maxLength="200"
                                                                        id="companyaddressinput"
                                                                        value={this.state.companyaddress}
                                                                        onChange={this.handlecompanyaddressChange}
                                                                        placeholder="Company Address "
                                                                    />
                                                                </>) : null}


                                                            <br />
                                                            <br />
                                                            <input
                                                                type="text"
                                                                className=" serviceinput serviceinputbottomcommonclass"
                                                                name=""
                                                                id="pincodeinput"
                                                                value={this.state.pincode}
                                                                onChange={this.handlepincodeChange}
                                                                maxLength="6"
                                                                placeholder="Pincode"
                                                            />
                                                            <br />
                                                            <br />
                                                            <input
                                                                type="text"
                                                                pattern="[A-Za-z ]{3,150}"
                                                                className=" serviceinput capatalizeMe serviceinputbottomcommonclass"
                                                                name="Applicant Address"
                                                                maxLength="100"
                                                                id="ApplicantAddressinput"
                                                                value={this.state.applicantaddress}
                                                                onChange={this.handleApplicantaddress}
                                                                placeholder="Applicant Address"
                                                            />
                                                            <br />
                                                            <br />
                                                            <input
                                                                type="text"
                                                                pattern="[A-Za-z ]{3,150}"
                                                                className=" serviceinput capatalizeMe serviceinputbottomcommonclass"
                                                                name="Applicant Designation"
                                                                maxLength="100"
                                                                id="ApplicantDesignationinput"
                                                                placeholder="Applicant Designation"
                                                                onChange={this.handleApplicantdesignation}
                                                                value={this.state.Applicant_Designation}
                                                            />

                                                            {this.state.occupation == "GOVERNMENT JOB" ? (
                                                                <>
                                                                    {" "}
                                                                    <br />
                                                                    <br />
                                                                    <input
                                                                        type="text"
                                                                        pattern="[A-Za-z ]{3,150}"
                                                                        className=" serviceinput capatalizeMe serviceinputbottomcommonclass"
                                                                        name="GOVT DEPARTMENT"
                                                                        maxLength="100"
                                                                        id="Govt_department"
                                                                        placeholder="Govt Department"
                                                                        onChange={this.handleGovdepartment}
                                                                        value={this.state.Govt_Department}
                                                                    />
                                                                    <br />
                                                                    <br />
                                                                    <input
                                                                        type="text"
                                                                        pattern="[A-Za-z ]{3,150}"
                                                                        className=" serviceinput capatalizeMe serviceinputbottomcommonclass"
                                                                        name="GOVT DEPARTMENT"
                                                                        maxLength="100"
                                                                        id="Office_address"
                                                                        placeholder="Office Address"
                                                                        onChange={this.handleOfficeaddress}
                                                                        value={this.state.Office_Address}
                                                                    />
                                                                </>
                                                            ) : null}
                                                            <br />
                                                            <br />
                                                            <label
                                                                className="fieldlabels sty2"
                                                                for="cc-bank"
                                                            >
                                                                Date Of Birth:{" "}
                                                            </label>
                                                            <input
                                                                type="date"
                                                                className=" serviceinput serviceinputbottomcommonclass"
                                                                name="dob"
                                                                id="dob"
                                                                maxLength="100"
                                                                placeholder="Date of Birth"
                                                                value={this.state.dob}
                                                                onChange={this.handledobChange}
                                                            />

                                                            <br />
                                                            <br />
                                                            <input
                                                                type="text"
                                                                className=" serviceinput serviceinputbottomcommonclass"
                                                                name="Pan"
                                                                id="salariedPan"
                                                                placeholder="PAN No"
                                                                maxLength="10"
                                                                value={this.state.pancard}
                                                                onChange={this.handlepancardChange}
                                                            />
                                                            <br />
                                                            <br />

                                                            <input
                                                                type="text"
                                                                className=" serviceinput serviceinputbottomcommonclass"
                                                                name="netMonthlyIncome"
                                                                id="netMonthlyIncome"
                                                                maxLength="10"
                                                                placeholder="Net Monthly Income"
                                                                value={this.state.netIncome}
                                                                onChange={this.handlenetIncome}
                                                                
                                                            />

                                                            <br />
                                                            <br />

                                                            <div className="self-emoloyee ">
                                                                <div>
                                                                    <label className="fieldlabels sty" for="">
                                                                        Do you have any existing credit card:{" "}
                                                                    </label>
                                                                </div>
                                                                <div className="occupation-selection hra_rad_btn_d rad_sty">
                                                                    <input
                                                                        type="radio"
                                                                        id="test1"
                                                                        name="radio"
                                                                        className="hrsradio mr-1 "
                                                                        checked={
                                                                            this.state.existingcreditcard_yn ===
                                                                            "true"
                                                                        }
                                                                        value="true"
                                                                        onChange={this.handleexistingcardradio}
                                                                    />
                                                                    <label
                                                                        className="radio-btn m_city mr-2 "
                                                                        for="test1"
                                                                    >
                                                                        Yes
                                                                    </label>

                                                                    <input
                                                                        type="radio"
                                                                        id="test2"
                                                                        className="hrsradio mr-1 "
                                                                        name="radio"
                                                                        checked={
                                                                            this.state.existingcreditcard_yn ===
                                                                            "false"
                                                                        }
                                                                        check
                                                                        value="false"
                                                                        onChange={this.handleexistingcardradio}
                                                                    />
                                                                    <label className="radio-btn " for="test2">
                                                                        No
                                                                    </label>
                                                                </div>
                                                                <br />

                                                                <div className="dnone bname">
                                                                    <div>
                                                                        {" "}
                                                                        <label
                                                                            className="fieldlabels sty2"
                                                                            for="cc-bank"
                                                                        >
                                                                            Which Bank credit card you have:{" "}
                                                                        </label>
                                                                    </div>
                                                                    <select
                                                                        className="serviceinputselect2 sp-Margin serviceinputbottomcommonclass"
                                                                        name="cc-bank"
                                                                        id="cc-bank"
                                                                        maxLength="100"
                                                                        placeholder=""
                                                                        value={this.state.existingcreditcard_name}
                                                                        onChange={this.handleexistingcreditcardname}
                                                                    >
                                                                        <option value="">Select Bank Name</option>
                                                                        <option value="American Express">American Express</option>
                                                                        <option value="Axis Bank">Axis Bank</option>
                                                                        <option value="ICICI Bank">ICICI Bank</option>
                                                                        <option value="CITI Bank">CITI Bank</option>
                                                                        <option value="HSBC">HSBC</option>
                                                                        <option value="HDFC">HDFC</option>
                                                                        <option value="Kotak Mahindra Bank">Kotak Mahindra Bank</option>
                                                                        <option value="SBI">SBI</option>
                                                                        <option value="Standard Charted">Standard Charted</option>
                                                                        <option value="RBL">RBL</option>
                                                                        <option value="OTHER">Other</option>
                                                                    </select>
                                                                    {this.state.existingcreditcard_name == "OTHER" ? (
                                                                        <>
                                                                            <br />
                                                                            <br />
                                                                            <input
                                                                                type="text"
                                                                                className=" serviceinput serviceinputbottomcommonclass"
                                                                                name="netMonthlyIncome"
                                                                                id="card_name"
                                                                                min="100"
                                                                                placeholder="Bank Name"
                                                                                value={this.state.creditcard_name}
                                                                                onChange={this.handlecreditcardname}
                                                                            />
                                                                        </>) : null}

                                                                    <br />
                                                                    <br />
                                                                    <input
                                                                        type="text"
                                                                        className=" serviceinput serviceinputbottomcommonclass"
                                                                        name="netMonthlyIncome"
                                                                        id="card_limit"
                                                                        maxLength="10"
                                                                        placeholder="Existing Card Limit"
                                                                        value={this.state.cardlimit}
                                                                        onChange={this.handlecardlimit}
                                                                    />

                                                                    <br />
                                                                    <br />
                                                                    <label
                                                                        className="fieldlabels sty2"
                                                                        for="cc-bank"
                                                                    >
                                                                        Opening month and year:{" "}
                                                                    </label>
                                                                    <input
                                                                        type="month"
                                                                        className=" serviceinput serviceinputbottomcommonclass"
                                                                        name="cardopendate"
                                                                        id="card_mandy"
                                                                        maxLength="100"
                                                                        placeholder="Date of Birth"
                                                                        value={this.state.cardopenmandy}
                                                                        onChange={this.handlecardopenmandy}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <p className="errorside">
                                                                    <img
                                                                        src={
                                                                        process.env.PUBLIC_URL +
                                                                        "/img/Icon_feather-alert-circle.svg"
                                                                        }
                                                                        className="errorsideimg mr-2"
                                                                    />
                                                                    <span className="errorsidespan"></span>
                                                                </p>
                                                            </div>

                                                            <div className="">
                                                                <div className="axis_c_card_process_btn">
                                                                    <button
                                                                        className="proceednowbutton"
                                                                        id="submit"
                                                                        onClick={this.createPurchaseReqNormal}
                                                                    >
                                                                        Proceed now
                                                                        <img
                                                                            src="/img/Path 4125.svg"
                                                                            class="proceednowbuttonicon_axis_card"
                                                                        ></img>
                                                                    </button>
                                                                    <img className="addserviceloader" src={process.env.PUBLIC_URL + "/img/loader.gif"} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <center>
                                                                <br />
                                                                <p className="serviceprice">
                                                                    <sup className="servicepricesup">
                                                                        @No Charges
                                                                    </sup>
                                                                </p>
                                                                <p className="servicepricelimited">
                                                                    (*Limited time period offer)
                                                                </p>
                                                                <br />
                                                                <img
                                                                    src={
                                                                        process.env.PUBLIC_URL + "/img/1 (37).png"
                                                                    }
                                                                    className="serviceillustration"
                                                                />
                                                            </center>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                    <div className="col-md-5">
                                        <img
                                            src={process.env.PUBLIC_URL + "/img/trilustration.png"}
                                            className="serviceilustration"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="myDIV">

                        <div className='supportDetails'>
                            <img
                                src={process.env.PUBLIC_URL + "/img/24-7_Support.png"}

                                alt="image"
                            />
                            <div>
                                <h5>{supportName}</h5>
                                <p>Agent Support Executive</p>
                                <span><b>Contact: </b> +91 {supportContact}</span>
                                <p className='mb-1'><b>Working Hours: </b> 11:00 AM - 06:30 PM</p>
                            </div>
                        </div>


                    </div>
                    <button className="support" onClick={this.handlesupport} >
                        <img src={process.env.PUBLIC_URL + "/img/headPhone.svg"} width="50%" height="50px" />
                    </button>

                    <Footer />
                </div>
            );
        } else {
            return <Redirect to="/" />;
        }
    }
}

export default InduslandBankCreditCard
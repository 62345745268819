import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import { Redirect } from "react-router";
import jsonData from "../State__and__dist.json"
import customservices from "../customservices.json"
import AllServices from "../AllServices.json"
import Sidebar from "./Sidebar";

let user__state;
var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);
let Decuserid;
let sessiondata;
let Decagentid;
let Decjwttoken;
let Services;
let supportName;
let supportContact;
let supportEmail;

export class Addservice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      serviceselectvalue: "",
      citydistrictselectvalue: "",
      servicefullname: "",
      serviceName: [
        "GST Registration",
      ],
      supportName: "",
      supportContact: "",
      supportEmail: "",

    };
  }
  logout = () => {
    localStorage.removeItem("gglltakoinoeenl");
    localStorage.removeItem("toeljgtkewlna");
    localStorage.removeItem("qazxswedcvfrtgb");
    localStorage.removeItem("lshhihfnkff");
    this.props.history.push({
      pathname: `/`,
    });
  };
  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };
  citydistrictselecthandle = (event) => {
    this.setState({
      citydistrictselectvalue: event.target.value,
    });

  };
  filterDistricts = () => {

    user__state = jsonData[0][this.state.citydistrictselectvalue]

  };
  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };

  selectservicehandle = (event) => {
    sessiondata = JSON.parse(sessionStorage.getItem("data"))
    if (sessiondata == null || sessiondata == undefined || sessiondata == "") {
      sessiondata = []


    } else if (sessiondata[0].userId == "") {

      sessiondata = []
      sessionStorage.removeItem('data');

    }

    if (event.target.value != "") {
      if (
        this.state.servicefullname.trim() != "" &&
        this.state.citydistrictselectvalue != ""
      ) {
        this.setState({
          serviceselectvalue: event.target.value,
        });
        this.filterDistricts();
        sessionStorage.removeItem("ptfgfgjhlqwemcggdfg");
        sessionStorage.removeItem("lashkjdasfurtghghj");
        sessionStorage.removeItem("lksadnlkdnalksdnkksnd");
        if (event.target.value == "GST Return Filing") {
          var mySentence = this.state.servicefullname.trim();
          mySentence = mySentence.replace(/\s+/g, " ");
          const words = mySentence.split(" ");

          for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
          }

          var mywords = words.join(" ");


          sessionStorage.setItem(
            "ielndmlgeaefelvsdrlucaa",
            encryptor.encrypt(mywords.trim()));
          sessionStorage.setItem(
            "ylcvciretaireditdgisdtacesl",
            encryptor.encrypt(this.state.citydistrictselectvalue)
          );
          sessionStorage.setItem(
            "srleleddgaeaemvcnia",
            encryptor.encrypt(event.target.value)
          );
          sessionStorage.setItem(
            "hjtsrevanxckawshdyeh",
            encryptor.encrypt(user__state)
          );
          this.props.history.push({
            pathname: `/addservice/${Decuserid}/addservice/gstrf`,
          });
        } else if (event.target.value == "GST Registration") {
          var mySentence = this.state.servicefullname.trim();
          mySentence = mySentence.replace(/\s+/g, " ");
          const words = mySentence.split(" ");

          for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
          }

          var mywords = words.join(" ");
          sessionStorage.setItem(
            "ielndmlgeaefelvsdrlucaa",
            encryptor.encrypt(mywords.trim()));
          sessionStorage.setItem(
            "ylcvciretaireditdgisdtacesl",
            encryptor.encrypt(this.state.citydistrictselectvalue)
          );
          sessionStorage.setItem(
            "srleleddgaeaemvcnia",
            encryptor.encrypt(event.target.value)
          );
          sessionStorage.setItem(
            "hjtsrevanxckawshdyeh",
            encryptor.encrypt(user__state)
          );
          this.props.history.push({
            pathname: `/addservice/${Decuserid}/addservice/gstr`,
          });
        } else if (event.target.value == "ITR Filing") {
          var mySentence = this.state.servicefullname.trim();
          mySentence = mySentence.replace(/\s+/g, " ");
          const words = mySentence.split(" ");

          for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
          }

          var mywords = words.join(" ");


          sessionStorage.setItem(
            "ielndmlgeaefelvsdrlucaa",
            encryptor.encrypt(mywords.trim()));
          sessionStorage.setItem(
            "ylcvciretaireditdgisdtacesl",
            encryptor.encrypt(this.state.citydistrictselectvalue)
          );
          sessionStorage.setItem(
            "srleleddgaeaemvcnia",
            encryptor.encrypt(event.target.value)
          );
          sessionStorage.setItem(
            "hjtsrevanxckawshdyeh",
            encryptor.encrypt(user__state)
          );
          this.props.history.push({
            pathname: `/addservice/${Decuserid}/addservice/itrf`,
          });
        } else if (event.target.value == "Trademark Registration") {
          var mySentence = this.state.servicefullname.trim();
          mySentence = mySentence.replace(/\s+/g, " ");
          const words = mySentence.split(" ");

          for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
          }

          var mywords = words.join(" ");


          sessionStorage.setItem(
            "ielndmlgeaefelvsdrlucaa",
            encryptor.encrypt(mywords.trim()));
          sessionStorage.setItem(
            "ylcvciretaireditdgisdtacesl",
            encryptor.encrypt(this.state.citydistrictselectvalue)
          );
          sessionStorage.setItem(
            "srleleddgaeaemvcnia",
            encryptor.encrypt(event.target.value)
          );
          sessionStorage.setItem(
            "hjtsrevanxckawshdyeh",
            encryptor.encrypt(user__state)
          );
          this.props.history.push({
            pathname: `/addservice/${Decuserid}/addservice/tr`,
          });
        } else if (event.target.value == "Online Accounting") {
          var mySentence = this.state.servicefullname.trim();
          mySentence = mySentence.replace(/\s+/g, " ");
          const words = mySentence.split(" ");

          for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
          }

          var mywords = words.join(" ");


          sessionStorage.setItem(
            "ielndmlgeaefelvsdrlucaa",
            encryptor.encrypt(mywords.trim()));
          sessionStorage.setItem(
            "ylcvciretaireditdgisdtacesl",
            encryptor.encrypt(this.state.citydistrictselectvalue)
          );
          sessionStorage.setItem(
            "srleleddgaeaemvcnia",
            encryptor.encrypt(event.target.value)
          );
          sessionStorage.setItem(
            "hjtsrevanxckawshdyeh",
            encryptor.encrypt(user__state)
          );
          this.props.history.push({
            pathname: `/addservice/${Decuserid}/addservice/oa`,
          });
        } else if (event.target.value == "Gumasta Licence (S&E)") {

          if (user__state == "Madhya Pradesh") {
            var mySentence = this.state.servicefullname.trim();
            mySentence = mySentence.replace(/\s+/g, " ");
            const words = mySentence.split(" ");

            for (let i = 0; i < words.length; i++) {
              words[i] = words[i][0].toUpperCase() + words[i].substr(1);
            }

            var mywords = words.join(" ");


            sessionStorage.setItem(
              "ielndmlgeaefelvsdrlucaa",
              encryptor.encrypt(mywords.trim()));
            sessionStorage.setItem(
              "ylcvciretaireditdgisdtacesl",
              encryptor.encrypt(this.state.citydistrictselectvalue)
            );
            sessionStorage.setItem(
              "srleleddgaeaemvcnia",
              encryptor.encrypt(event.target.value)
            );
            sessionStorage.setItem(
              "hjtsrevanxckawshdyeh",
              encryptor.encrypt(user__state)
            );
            this.props.history.push({
              pathname: `/addservice/${Decuserid}/addservice/gumasta`,
            });
          } else {
            this.setState({
              serviceselectvalue: "",
            });
            this.showNotification("This Service is only available for users in Madhya Pradesh only!")
          }

        } else if (event.target.value == "Trade Licence") {
          if (user__state == "Madhya Pradesh") {
            var mySentence = this.state.servicefullname.trim();
            mySentence = mySentence.replace(/\s+/g, " ");
            const words = mySentence.split(" ");

            for (let i = 0; i < words.length; i++) {
              words[i] = words[i][0].toUpperCase() + words[i].substr(1);
            }

            var mywords = words.join(" ");


            sessionStorage.setItem(
              "ielndmlgeaefelvsdrlucaa",
              encryptor.encrypt(mywords.trim()));
            sessionStorage.setItem(
              "ylcvciretaireditdgisdtacesl",
              encryptor.encrypt(this.state.citydistrictselectvalue)
            );
            sessionStorage.setItem(
              "srleleddgaeaemvcnia",
              encryptor.encrypt(event.target.value)
            );
            sessionStorage.setItem(
              "hjtsrevanxckawshdyeh",
              encryptor.encrypt(user__state)
            );
            this.props.history.push({
              pathname: `/addservice/${Decuserid}/addservice/tl`,
            });
          } else {
            this.setState({
              serviceselectvalue: "",
            });
            this.showNotification("This Service is only available for users in Madhya Pradesh only!")
          }
        } else if (event.target.value == "Food Licence") {
          var mySentence = this.state.servicefullname.trim();
          mySentence = mySentence.replace(/\s+/g, " ");
          const words = mySentence.split(" ");

          for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
          }

          var mywords = words.join(" ");


          sessionStorage.setItem(
            "ielndmlgeaefelvsdrlucaa",
            encryptor.encrypt(mywords.trim()));
          sessionStorage.setItem(
            "ylcvciretaireditdgisdtacesl",
            encryptor.encrypt(this.state.citydistrictselectvalue)
          );
          sessionStorage.setItem(
            "srleleddgaeaemvcnia",
            encryptor.encrypt(event.target.value)
          );
          sessionStorage.setItem(
            "hjtsrevanxckawshdyeh",
            encryptor.encrypt(user__state)
          );
          this.props.history.push({
            pathname: `/addservice/${Decuserid}/addservice/fl`,
          });
        } else if (event.target.value == "Legal Notice Reply") {
          var mySentence = this.state.servicefullname.trim();
          mySentence = mySentence.replace(/\s+/g, " ");
          const words = mySentence.split(" ");

          for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
          }

          var mywords = words.join(" ");


          sessionStorage.setItem(
            "ielndmlgeaefelvsdrlucaa",
            encryptor.encrypt(mywords.trim()));
          sessionStorage.setItem(
            "ylcvciretaireditdgisdtacesl",
            encryptor.encrypt(this.state.citydistrictselectvalue)
          );
          sessionStorage.setItem(
            "srleleddgaeaemvcnia",
            encryptor.encrypt(event.target.value)
          );
          sessionStorage.setItem(
            "hjtsrevanxckawshdyeh",
            encryptor.encrypt(user__state)
          );
          this.props.history.push({
            pathname: `/addservice/${Decuserid}/addservice/repl`,
          });
        } else if (event.target.value == "Personal Loan") {
          var mySentence = this.state.servicefullname.trim();
          mySentence = mySentence.replace(/\s+/g, " ");
          const words = mySentence.split(" ");

          for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
          }

          var mywords = words.join(" ");


          sessionStorage.setItem(
            "ielndmlgeaefelvsdrlucaa",
            encryptor.encrypt(mywords.trim()));
          sessionStorage.setItem(
            "ylcvciretaireditdgisdtacesl",
            encryptor.encrypt(this.state.citydistrictselectvalue)
          );
          sessionStorage.setItem(
            "srleleddgaeaemvcnia",
            encryptor.encrypt(event.target.value)
          );
          sessionStorage.setItem(
            "hjtsrevanxckawshdyeh",
            encryptor.encrypt(user__state)
          );
          this.props.history.push({
            pathname: `/addservice/${Decuserid}/addservice/personal-loan`,
          });
        } else if (event.target.value == "Home Loan") {
          var mySentence = this.state.servicefullname.trim();
          mySentence = mySentence.replace(/\s+/g, " ");
          const words = mySentence.split(" ");

          for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
          }

          var mywords = words.join(" ");


          sessionStorage.setItem(
            "ielndmlgeaefelvsdrlucaa",
            encryptor.encrypt(mywords.trim()));
          sessionStorage.setItem(
            "ylcvciretaireditdgisdtacesl",
            encryptor.encrypt(this.state.citydistrictselectvalue)
          );
          sessionStorage.setItem(
            "srleleddgaeaemvcnia",
            encryptor.encrypt(event.target.value)
          );
          sessionStorage.setItem(
            "hjtsrevanxckawshdyeh",
            encryptor.encrypt(user__state)
          );
          this.props.history.push({
            pathname: `/addservice/${Decuserid}/addservice/home-loan`,
          });
        } else if (event.target.value == "Business Loan") {
          var mySentence = this.state.servicefullname.trim();
          mySentence = mySentence.replace(/\s+/g, " ");
          const words = mySentence.split(" ");

          for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
          }

          var mywords = words.join(" ");


          sessionStorage.setItem(
            "ielndmlgeaefelvsdrlucaa",
            encryptor.encrypt(mywords.trim()));
          sessionStorage.setItem(
            "ylcvciretaireditdgisdtacesl",
            encryptor.encrypt(this.state.citydistrictselectvalue)
          );
          sessionStorage.setItem(
            "srleleddgaeaemvcnia",
            encryptor.encrypt(event.target.value)
          );
          sessionStorage.setItem(
            "hjtsrevanxckawshdyeh",
            encryptor.encrypt(user__state)
          );
          this.props.history.push({
            pathname: `/addservice/${Decuserid}/addservice/business-loan`,
          });
        } else if (event.target.value == "Loan Against Property") {
          var mySentence = this.state.servicefullname.trim();
          mySentence = mySentence.replace(/\s+/g, " ");
          const words = mySentence.split(" ");

          for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
          }

          var mywords = words.join(" ");


          sessionStorage.setItem(
            "ielndmlgeaefelvsdrlucaa",
            encryptor.encrypt(mywords.trim()));
          sessionStorage.setItem(
            "ylcvciretaireditdgisdtacesl",
            encryptor.encrypt(this.state.citydistrictselectvalue)
          );
          sessionStorage.setItem(
            "srleleddgaeaemvcnia",
            encryptor.encrypt(event.target.value)
          );
          sessionStorage.setItem(
            "hjtsrevanxckawshdyeh",
            encryptor.encrypt(user__state)
          );
          this.props.history.push({
            pathname: `/addservice/${Decuserid}/addservice/loan-against-property`,
          });
        } else if (event.target.value == "Car Loan") {
          var mySentence = this.state.servicefullname.trim();
          mySentence = mySentence.replace(/\s+/g, " ");
          const words = mySentence.split(" ");

          for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
          }

          var mywords = words.join(" ");


          sessionStorage.setItem(
            "ielndmlgeaefelvsdrlucaa",
            encryptor.encrypt(mywords.trim()));
          sessionStorage.setItem(
            "ylcvciretaireditdgisdtacesl",
            encryptor.encrypt(this.state.citydistrictselectvalue)
          );
          sessionStorage.setItem(
            "srleleddgaeaemvcnia",
            encryptor.encrypt(event.target.value)
          );
          sessionStorage.setItem(
            "hjtsrevanxckawshdyeh",
            encryptor.encrypt(user__state)
          );
          this.props.history.push({
            pathname: `/addservice/${Decuserid}/addservice/car-loan`,
          });
        } else if (event.target.value == "Gold Loan") {
          var mySentence = this.state.servicefullname.trim();
          mySentence = mySentence.replace(/\s+/g, " ");
          const words = mySentence.split(" ");

          for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
          }

          var mywords = words.join(" ");


          sessionStorage.setItem(
            "ielndmlgeaefelvsdrlucaa",
            encryptor.encrypt(mywords.trim()));
          sessionStorage.setItem(
            "ylcvciretaireditdgisdtacesl",
            encryptor.encrypt(this.state.citydistrictselectvalue)
          );
          sessionStorage.setItem(
            "srleleddgaeaemvcnia",
            encryptor.encrypt(event.target.value)
          );
          sessionStorage.setItem(
            "hjtsrevanxckawshdyeh",
            encryptor.encrypt(user__state)
          );
          this.props.history.push({
            pathname: `/addservice/${Decuserid}/addservice/gold-loan`,
          });
        } else if (event.target.value == "Axis Bank Credit Card") {
          var mySentence = this.state.servicefullname.trim();
          mySentence = mySentence.replace(/\s+/g, " ");
          const words = mySentence.split(" ");

          for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
          }

          var mywords = words.join(" ");


          sessionStorage.setItem(
            "ielndmlgeaefelvsdrlucaa",
            encryptor.encrypt(mywords.trim()));
          sessionStorage.setItem(
            "ylcvciretaireditdgisdtacesl",
            encryptor.encrypt(this.state.citydistrictselectvalue)
          );
          sessionStorage.setItem(
            "srleleddgaeaemvcnia",
            encryptor.encrypt(event.target.value)
          );
          sessionStorage.setItem(
            "hjtsrevanxckawshdyeh",
            encryptor.encrypt(user__state)
          );
          this.props.history.push({
            pathname: `/addservice/${Decuserid}/addservice/axis-bank-credit-card`,
          });
        } else if (event.target.value == "Indusind Bank Credit Card") {
          var mySentence = this.state.servicefullname.trim();
          mySentence = mySentence.replace(/\s+/g, " ");
          const words = mySentence.split(" ");

          for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
          }

          var mywords = words.join(" ");


          sessionStorage.setItem(
            "ielndmlgeaefelvsdrlucaa",
            encryptor.encrypt(mywords.trim()));
          sessionStorage.setItem(
            "ylcvciretaireditdgisdtacesl",
            encryptor.encrypt(this.state.citydistrictselectvalue)
          );
          sessionStorage.setItem(
            "srleleddgaeaemvcnia",
            encryptor.encrypt(event.target.value)
          );
          sessionStorage.setItem(
            "hjtsrevanxckawshdyeh",
            encryptor.encrypt(user__state)
          );
          this.props.history.push({
            pathname: `/addservice/${Decuserid}/addservice/indusind-bank-credit-card`,
          });
        } else if (event.target.value == "Yes Bank Credit Card") {
          var mySentence = this.state.servicefullname.trim();
          mySentence = mySentence.replace(/\s+/g, " ");
          const words = mySentence.split(" ");

          for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
          }

          var mywords = words.join(" ");


          sessionStorage.setItem(
            "ielndmlgeaefelvsdrlucaa",
            encryptor.encrypt(mywords.trim()));
          sessionStorage.setItem(
            "ylcvciretaireditdgisdtacesl",
            encryptor.encrypt(this.state.citydistrictselectvalue)
          );
          sessionStorage.setItem(
            "srleleddgaeaemvcnia",
            encryptor.encrypt(event.target.value)
          );
          sessionStorage.setItem(
            "hjtsrevanxckawshdyeh",
            encryptor.encrypt(user__state)
          );
          this.props.history.push({
            pathname: `/addservice/${Decuserid}/addservice/yes-bank-credit-card`,
          });
        } else if (event.target.value == "Stashfin Bank Credit Card") {
          var mySentence = this.state.servicefullname.trim();
          mySentence = mySentence.replace(/\s+/g, " ");
          const words = mySentence.split(" ");

          for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
          }

          var mywords = words.join(" ");


          sessionStorage.setItem(
            "ielndmlgeaefelvsdrlucaa",
            encryptor.encrypt(mywords.trim()));
          sessionStorage.setItem(
            "ylcvciretaireditdgisdtacesl",
            encryptor.encrypt(this.state.citydistrictselectvalue)
          );
          sessionStorage.setItem(
            "srleleddgaeaemvcnia",
            encryptor.encrypt(event.target.value)
          );
          sessionStorage.setItem(
            "hjtsrevanxckawshdyeh",
            encryptor.encrypt(user__state)
          );
          this.props.history.push({
            pathname: `/addservice/${Decuserid}/addservice/stashfin-bank-credit-card`,
          });
        } else if (event.target.value == "State Bank of India Credit Card") {
          var mySentence = this.state.servicefullname.trim();
          mySentence = mySentence.replace(/\s+/g, " ");
          const words = mySentence.split(" ");

          for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
          }

          var mywords = words.join(" ");


          sessionStorage.setItem(
            "ielndmlgeaefelvsdrlucaa",
            encryptor.encrypt(mywords.trim()));
          sessionStorage.setItem(
            "ylcvciretaireditdgisdtacesl",
            encryptor.encrypt(this.state.citydistrictselectvalue)
          );
          sessionStorage.setItem(
            "srleleddgaeaemvcnia",
            encryptor.encrypt(event.target.value)
          );
          sessionStorage.setItem(
            "hjtsrevanxckawshdyeh",
            encryptor.encrypt(user__state)
          );
          this.props.history.push({
            pathname: `/addservice/${Decuserid}/addservice/state-bank-of-india-credit-card`,
          });
        } else if (event.target.value == "AU Bank Credit Card") {
          var mySentence = this.state.servicefullname.trim();
          mySentence = mySentence.replace(/\s+/g, " ");
          const words = mySentence.split(" ");

          for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
          }

          var mywords = words.join(" ");


          sessionStorage.setItem(
            "ielndmlgeaefelvsdrlucaa",
            encryptor.encrypt(mywords.trim()));
          sessionStorage.setItem(
            "ylcvciretaireditdgisdtacesl",
            encryptor.encrypt(this.state.citydistrictselectvalue)
          );
          sessionStorage.setItem(
            "srleleddgaeaemvcnia",
            encryptor.encrypt(event.target.value)
          );
          sessionStorage.setItem(
            "hjtsrevanxckawshdyeh",
            encryptor.encrypt(user__state)
          );
          this.props.history.push({
            pathname: `/addservice/${Decuserid}/addservice/au-bank-credit-card`,
          });
        } else if (event.target.value == "Gold Loan") {
          var mySentence = this.state.servicefullname.trim();
          mySentence = mySentence.replace(/\s+/g, " ");
          const words = mySentence.split(" ");

          for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
          }

          var mywords = words.join(" ");


          sessionStorage.setItem(
            "ielndmlgeaefelvsdrlucaa",
            encryptor.encrypt(mywords.trim()));
          sessionStorage.setItem(
            "ylcvciretaireditdgisdtacesl",
            encryptor.encrypt(this.state.citydistrictselectvalue)
          );
          sessionStorage.setItem(
            "srleleddgaeaemvcnia",
            encryptor.encrypt(event.target.value)
          );
          sessionStorage.setItem(
            "hjtsrevanxckawshdyeh",
            encryptor.encrypt(user__state)
          );
          this.props.history.push({
            pathname: `/addservice/${Decuserid}/addservice/gold-loan`,
          });
        } else if (event.target.value == "Annual ROC Compliance(Company)") {

          var mySentence = this.state.servicefullname.trim();
          mySentence = mySentence.replace(/\s+/g, " ");
          const words = mySentence.split(" ");

          for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
          }

          var mywords = words.join(" ");


          sessionStorage.setItem(
            "ielndmlgeaefelvsdrlucaa",
            encryptor.encrypt(mywords.trim()));
          sessionStorage.setItem(
            "ylcvciretaireditdgisdtacesl",
            encryptor.encrypt(this.state.citydistrictselectvalue)
          );
          sessionStorage.setItem(
            "srleleddgaeaemvcnia",
            encryptor.encrypt(event.target.value)
          );
          sessionStorage.setItem(
            "hjtsrevanxckawshdyeh",
            encryptor.encrypt(user__state)
          );
          this.props.history.push({
            pathname: `/addservice/${Decuserid}/addservice/roccomp`,
          });


        } else if (event.target.value == "Annual ROC Compliance(LLP)") {

          var mySentence = this.state.servicefullname.trim();
          mySentence = mySentence.replace(/\s+/g, " ");
          const words = mySentence.split(" ");

          for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
          }

          var mywords = words.join(" ");


          sessionStorage.setItem(
            "ielndmlgeaefelvsdrlucaa",
            encryptor.encrypt(mywords.trim()));
          sessionStorage.setItem(
            "ylcvciretaireditdgisdtacesl",
            encryptor.encrypt(this.state.citydistrictselectvalue)
          );
          sessionStorage.setItem(
            "srleleddgaeaemvcnia",
            encryptor.encrypt(event.target.value)
          );
          sessionStorage.setItem(
            "hjtsrevanxckawshdyeh",
            encryptor.encrypt(user__state)
          );
          this.props.history.push({
            pathname: `/addservice/${Decuserid}/addservice/rocllp`,
          });


        } else if (event.target.value == "CMA Reports") {

          var mySentence = this.state.servicefullname.trim();
          mySentence = mySentence.replace(/\s+/g, " ");
          const words = mySentence.split(" ");

          for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
          }

          var mywords = words.join(" ");


          sessionStorage.setItem(
            "ielndmlgeaefelvsdrlucaa",
            encryptor.encrypt(mywords.trim()));
          sessionStorage.setItem(
            "ylcvciretaireditdgisdtacesl",
            encryptor.encrypt(this.state.citydistrictselectvalue)
          );
          sessionStorage.setItem(
            "srleleddgaeaemvcnia",
            encryptor.encrypt(event.target.value)
          );
          sessionStorage.setItem(
            "hjtsrevanxckawshdyeh",
            encryptor.encrypt(user__state)
          );
          this.props.history.push({
            pathname: `/addservice/${Decuserid}/addservice/cma`,
          });


        } else if (event.target.value == "TDS Return Filing") {

          var mySentence = this.state.servicefullname.trim();
          mySentence = mySentence.replace(/\s+/g, " ");
          const words = mySentence.split(" ");

          for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
          }

          var mywords = words.join(" ");


          sessionStorage.setItem(
            "ielndmlgeaefelvsdrlucaa",
            encryptor.encrypt(mywords.trim()));
          sessionStorage.setItem(
            "ylcvciretaireditdgisdtacesl",
            encryptor.encrypt(this.state.citydistrictselectvalue)
          );
          sessionStorage.setItem(
            "srleleddgaeaemvcnia",
            encryptor.encrypt(event.target.value)
          );
          sessionStorage.setItem(
            "hjtsrevanxckawshdyeh",
            encryptor.encrypt(user__state)
          );
          this.props.history.push({
            pathname: `/addservice/${Decuserid}/addservice/tds`,
          });


        } else if (event.target.value == "Net Worth Certificate Consultancy") {

          var mySentence = this.state.servicefullname.trim();
          mySentence = mySentence.replace(/\s+/g, " ");
          const words = mySentence.split(" ");

          for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
          }

          var mywords = words.join(" ");


          sessionStorage.setItem(
            "ielndmlgeaefelvsdrlucaa",
            encryptor.encrypt(mywords.trim()));
          sessionStorage.setItem(
            "ylcvciretaireditdgisdtacesl",
            encryptor.encrypt(this.state.citydistrictselectvalue)
          );
          sessionStorage.setItem(
            "srleleddgaeaemvcnia",
            encryptor.encrypt(event.target.value)
          );
          sessionStorage.setItem(
            "hjtsrevanxckawshdyeh",
            encryptor.encrypt(user__state)
          );
          this.props.history.push({
            pathname: `/addservice/${Decuserid}/addservice/nwc`,
          });


        } else if (event.target.value == "Income Tax Audit Consultancy") {

          var mySentence = this.state.servicefullname.trim();
          mySentence = mySentence.replace(/\s+/g, " ");
          const words = mySentence.split(" ");

          for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
          }

          var mywords = words.join(" ");


          sessionStorage.setItem(
            "ielndmlgeaefelvsdrlucaa",
            encryptor.encrypt(mywords.trim()));
          sessionStorage.setItem(
            "ylcvciretaireditdgisdtacesl",
            encryptor.encrypt(this.state.citydistrictselectvalue)
          );
          sessionStorage.setItem(
            "srleleddgaeaemvcnia",
            encryptor.encrypt(event.target.value)
          );
          sessionStorage.setItem(
            "hjtsrevanxckawshdyeh",
            encryptor.encrypt(user__state)
          );
          this.props.history.push({
            pathname: `/addservice/${Decuserid}/addservice/ita`,
          });


        } else if (event.target.value == "Statutory Audit Consultancy") {

          var mySentence = this.state.servicefullname.trim();
          mySentence = mySentence.replace(/\s+/g, " ");
          const words = mySentence.split(" ");

          for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
          }

          var mywords = words.join(" ");


          sessionStorage.setItem(
            "ielndmlgeaefelvsdrlucaa",
            encryptor.encrypt(mywords.trim()));
          sessionStorage.setItem(
            "ylcvciretaireditdgisdtacesl",
            encryptor.encrypt(this.state.citydistrictselectvalue)
          );
          sessionStorage.setItem(
            "srleleddgaeaemvcnia",
            encryptor.encrypt(event.target.value)
          );
          sessionStorage.setItem(
            "hjtsrevanxckawshdyeh",
            encryptor.encrypt(user__state)
          );
          this.props.history.push({
            pathname: `/addservice/${Decuserid}/addservice/sta`,
          });


        } else if (event.target.value == "Project Report Prepration") {

          var mySentence = this.state.servicefullname.trim();
          mySentence = mySentence.replace(/\s+/g, " ");
          const words = mySentence.split(" ");

          for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
          }

          var mywords = words.join(" ");


          sessionStorage.setItem(
            "ielndmlgeaefelvsdrlucaa",
            encryptor.encrypt(mywords.trim()));
          sessionStorage.setItem(
            "ylcvciretaireditdgisdtacesl",
            encryptor.encrypt(this.state.citydistrictselectvalue)
          );
          sessionStorage.setItem(
            "srleleddgaeaemvcnia",
            encryptor.encrypt(event.target.value)
          );
          sessionStorage.setItem(
            "hjtsrevanxckawshdyeh",
            encryptor.encrypt(user__state)
          );
          this.props.history.push({
            pathname: `/addservice/${Decuserid}/addservice/pr`,
          });


        } else if (event.target.value == "PF Return Filing") {

          var mySentence = this.state.servicefullname.trim();
          mySentence = mySentence.replace(/\s+/g, " ");
          const words = mySentence.split(" ");

          for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
          }

          var mywords = words.join(" ");


          sessionStorage.setItem(
            "ielndmlgeaefelvsdrlucaa",
            encryptor.encrypt(mywords.trim()));
          sessionStorage.setItem(
            "ylcvciretaireditdgisdtacesl",
            encryptor.encrypt(this.state.citydistrictselectvalue)
          );
          sessionStorage.setItem(
            "srleleddgaeaemvcnia",
            encryptor.encrypt(event.target.value)
          );
          sessionStorage.setItem(
            "hjtsrevanxckawshdyeh",
            encryptor.encrypt(user__state)
          );
          this.props.history.push({
            pathname: `/addservice/${Decuserid}/addservice/pfrf`,
          });


        } else if (event.target.value == "ESIC Return Filing") {

          var mySentence = this.state.servicefullname.trim();
          mySentence = mySentence.replace(/\s+/g, " ");
          const words = mySentence.split(" ");

          for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
          }

          var mywords = words.join(" ");


          sessionStorage.setItem(
            "ielndmlgeaefelvsdrlucaa",
            encryptor.encrypt(mywords.trim()));
          sessionStorage.setItem(
            "ylcvciretaireditdgisdtacesl",
            encryptor.encrypt(this.state.citydistrictselectvalue)
          );
          sessionStorage.setItem(
            "srleleddgaeaemvcnia",
            encryptor.encrypt(event.target.value)
          );
          sessionStorage.setItem(
            "hjtsrevanxckawshdyeh",
            encryptor.encrypt(user__state)
          );
          this.props.history.push({
            pathname: `/addservice/${Decuserid}/addservice/esicrf`,
          });


        } else {
          let name = event.target.value
          let valueindex = this.state.serviceName.indexOf(name);
          let mainshortCode = Object.keys(AllServices)[valueindex]
          let result = customservices[mainshortCode]
          let goodToGo = true
          if (result.applicableState.length > 0) {
              if (!result.applicableState.includes(user__state)) {
                goodToGo = false
              } else {
                goodToGo = true
              }
            
          }
          if(result.notapplicableState.length > 0){
            if(result.notapplicableState.includes(user__state)){
              goodToGo = false
            }else {
              goodToGo = true
            } 
          }
          if (goodToGo) {
            var mySentence = this.state.servicefullname.trim();
            mySentence = mySentence.replace(/\s+/g, " ");
            const words = mySentence.split(" ");

            for (let i = 0; i < words.length; i++) {
              words[i] = words[i][0].toUpperCase() + words[i].substr(1);
            }

            var mywords = words.join(" ");


            sessionStorage.setItem(
              "ielndmlgeaefelvsdrlucaa",
              encryptor.encrypt(mywords.trim()));
            sessionStorage.setItem(
              "ylcvciretaireditdgisdtacesl",
              encryptor.encrypt(this.state.citydistrictselectvalue)
            );
            sessionStorage.setItem(
              "srleleddgaeaemvcnia",
              encryptor.encrypt(event.target.value)
            );
            sessionStorage.setItem(
              "hjtsrevanxckawshdyeh",
              encryptor.encrypt(user__state)
            );
            sessionStorage.setItem(
              "piytydfgsfggfdgfgfdg",
              encryptor.encrypt(result.shortCode)
            );
            this.props.history.push({
              pathname: `/addservice/${Decuserid}/addservice/${result.shortCode.toLowerCase()}`, state: result
            });
          } else {
            this.setState({
              serviceselectvalue: "",
            });
            this.showNotification("This Service is not available in your state!")
          }
        }
      } else if (this.state.servicefullname.trim() == "") {
        this.showNotification("Please enter your name.");
        this.setState({
          serviceselectvalue: "",
        });
      }
      else if (this.state.citydistrictselectvalue.trim() == "") {
        this.showNotification("Please enter your city/district.");
        this.setState({
          serviceselectvalue: "",
        });
      }
    }
  };
  handleservicefullnameChange = (e) => {
    this.setState({
      servicefullname: e.target.value,
    });
  };

  componentDidMount() {
    this.setState({
      serviceName: Object.values(AllServices)
    })
    if (this.props.location.state) {
      if (this.props.location.state.name) {
        this.setState({ servicefullname: this.props.location.state.name })
      }
    }
    window.scrollTo(0, 0);
    $(document.body).css("background-color", "#2d2d2d");
    let mainuserid = localStorage.getItem("relleIsugad");
    let mainjwttoken = localStorage.getItem("toeljgtkewlna");
    Decuserid = encryptor.decrypt(mainuserid);
    Decjwttoken = encryptor.decrypt(mainjwttoken);
    if (localStorage.getItem("fdgshdjfkgjhyhdnxhsgdhskcj") != null && localStorage.getItem("fdgshdjfkgjhyhdnxhsgdhskcj").length > 0) {
      this.props.history.push({
        pathname: "/dashboard",
      });
    }

    supportName = localStorage.getItem("supportName");
    supportContact = localStorage.getItem("supportContact");
    supportEmail = localStorage.getItem("supportEmail");

    this.setState({
      supportName: supportName,
      supportContact: supportContact,
      supportEmail: supportEmail,
    })

    sessionStorage.setItem("srewrercgdgfdgf", encryptor.encrypt(false));

  }

  render() {
    if (
      localStorage.getItem("gglltakoinoeenl") == process.env.REACT_APP_LOGIN_KEY &&
      localStorage.getItem("toeljgtkewlna") != null &&
      localStorage.getItem("qazxswedcvfrtgb") != null
    ) {
      return (
        <div className="cont">
          <div id="notifContainer"></div>
          <Helmet>
            <link
              rel="stylesheet"
              href={process.env.PUBLIC_URL + "/css/select2.css"}
            />
            <link rel="stylesheet" href="css/services.css" />
          </Helmet>

          <Header />

          <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Path_217.svg"} className="sidebaropenicon" />
          <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
            onClick={this.backbutton}
            className="sidebarbackicon"
          />
          <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"}
            className="sidebarexpandicon"
          />

          <div className="overlay1"></div>
          <Sidebar />
          <div className="arrow-left6"></div>
          <div className="white_div">
            <div className="white_box">
              <center>
                {" "}
                <p className="download">Add Service</p>
              </center>
              <div className="row addserivemaindiv">
                <div className="col-md-4">
                  <div className="addservicetextinputdiv">
                    <div>
                      {" "}
                      <label className="adddervicelabel">Full name:-</label>
                    </div>
                    <div>
                      <input
                        type="text"
                        className="addservicetextinput"
                        value={this.state.servicefullname}
                        maxLength="100"
                        onChange={this.handleservicefullnameChange}
                        placeholder="Type here..."
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div>
                    <center>
                      <div>
                        <label className="adddervicelabel">Select City / District:-</label>
                      </div>

                      <div className="addserviceselectdiv">
                        <Select2
                          onChange={this.citydistrictselecthandle}
                          value={this.state.citydistrictselectvalue}
                          data={[
                            "Adilabad",
                            "Agar Malwa",
                            "Agra",
                            "Ahmedabad",
                            "Ahmednagar",
                            "Aizawl",
                            "Ajmer",
                            "Akola",
                            "Alappuzha",
                            "Aligarh",
                            "Alipurduar",
                            "Alirajpur",
                            "Allahabad",
                            "Almora",
                            "Alwar",
                            "Ambala",
                            "Ambedkar Nagar",
                            "Amethi (Chatrapati Sahuji Mahraj Nagar)",
                            "Amravati",
                            "Amreli",
                            "Amritsar",
                            "Amroha",
                            "Anand",
                            "Anantapur",
                            "Anantnag",
                            "Angul",
                            "Anjaw",
                            "Anuppur",
                            "Araria",
                            "Aravalli",
                            "Ariyalur",
                            "Arwal",
                            "Ashoknagar",
                            "Auraiya",
                            "Aurangabad (BH)",
                            "Aurangabad (MH)",
                            "Azamgarh",
                            "Bagalkot",
                            "Bageshwar",
                            "Baghpat",
                            "Bahraich",
                            "Baksa",
                            "Balaghat",
                            "Balangir",
                            "Balasore",
                            "Ballari (Bellary)",
                            "Ballia",
                            "Balod",
                            "Baloda Bazar",
                            "Balrampur (CG)",
                            "Balrampur (UP)",
                            "Banaskantha (Palanpur)",
                            "Banda",
                            "Bandipore",
                            "Banka",
                            "Bankura",
                            "Banswara",
                            "Barabanki",
                            "Baramulla",
                            "Baran",
                            "Bareilly",
                            "Bargarh",
                            "Barmer",
                            "Barnala",
                            "Barpeta",
                            "Barwani",
                            "Bastar",
                            "Basti",
                            "Bathinda",
                            "Beed",
                            "Begusarai",
                            "Belagavi (Belgaum)",
                            "Bemetara",
                            "Bengaluru (Bangalore) Rural",
                            "Bengaluru (Bangalore) Urban",
                            "Betul",
                            "Bhadohi",
                            "Bhadradri Kothagudem",
                            "Bhadrak",
                            "Bhagalpur",
                            "Bhandara",
                            "Bharatpur",
                            "Bharuch",
                            "Bhavnagar",
                            "Bhilwara",
                            "Bhind",
                            "Bhiwani",
                            "Bhojpur",
                            "Bhopal",
                            "Bidar",
                            "Bijapur",
                            "Bijnor",
                            "Bikaner",
                            "Bilaspur (CG)",
                            "Bilaspur (HP)",
                            "Birbhum",
                            "Bishnupur",
                            "Biswanath",
                            "Bokaro",
                            "Bongaigaon",
                            "Botad",
                            "Boudh",
                            "Budaun",
                            "Budgam",
                            "Bulandshahr",
                            "Buldhana",
                            "Bundi",
                            "Burhanpur",
                            "Buxar",
                            "Cachar",
                            "Central Delhi",
                            "Chamarajanagar",
                            "Chamba",
                            "Chamoli",
                            "Champawat",
                            "Champhai",
                            "Chandauli",
                            "Chandel",
                            "Chandigarh",
                            "Chandrapur",
                            "Changlang",
                            "Charaideo",
                            "Charkhi Dadri",
                            "Chatra",
                            "Chengalpattu",
                            "Chennai",
                            "Chhatarpur",
                            "Chhindwara",
                            "Chhota Udepur",
                            "Chikballapur",
                            "Chikkamagaluru (Chikmagalur)",
                            "Chirang",
                            "Chitradurga",
                            "Chitrakoot",
                            "Chittoor",
                            "Chittorgarh",
                            "Churachandpur",
                            "Churu",
                            "Coimbatore",
                            "Cooch Behar",
                            "Cuddalore",
                            "Cuttack",
                            "Dadra & Nagar Haveli",
                            "Dahod",
                            "Dakshin Dinajpur (South Dinajpur)",
                            "Dakshina Kannada",
                            "Daman",
                            "Damoh",
                            "Dangs (Ahwa)",
                            "Dantewada (South Bastar)",
                            "Darbhanga",
                            "Darjeeling",
                            "Darrang",
                            "Datia",
                            "Dausa",
                            "Davangere",
                            "Dehradun",
                            "Deogarh",
                            "Deoghar",
                            "Deoria",
                            "Devbhoomi Dwarka",
                            "Dewas",
                            "Dhalai",
                            "Dhamtari",
                            "Dhanbad",
                            "Dhar",
                            "Dharmapuri",
                            "Dharwad",
                            "Dhemaji",
                            "Dhenkanal",
                            "Dholpur",
                            "Dhubri",
                            "Dhule",
                            "Dibang Valley",
                            "Dibrugarh",
                            "Dima Hasao (North Cachar Hills)",
                            "Dimapur",
                            "Dindigul",
                            "Dindori",
                            "Diu",
                            "Doda",
                            "Dumka",
                            "Dungarpur",
                            "Durg",
                            "East Champaran (Motihari)",
                            "East Delhi",
                            "East Garo Hills",
                            "East Godavari",
                            "East Jaintia Hills",
                            "East Kameng",
                            "East Khasi Hills",
                            "East Siang",
                            "East Sikkim",
                            "East Singhbhum",
                            "Ernakulam",
                            "Erode",
                            "Etah",
                            "Etawah",
                            "Faizabad",
                            "Faridabad",
                            "Faridkot",
                            "Farrukhabad",
                            "Fatehabad",
                            "Fatehgarh Sahib",
                            "Fatehpur",
                            "Fazilka",
                            "Ferozepur",
                            "Firozabad",
                            "Gadag",
                            "Gadchiroli",
                            "Gajapati",
                            "Ganderbal",
                            "Gandhinagar",
                            "Ganjam",
                            "Garhwa",
                            "Gariyaband",
                            "Gautam Buddha Nagar",
                            "Gaya",
                            "Ghaziabad",
                            "Ghazipur",
                            "Gir Somnath",
                            "Giridih",
                            "Goalpara",
                            "Godda",
                            "Golaghat",
                            "Gomati",
                            "Gonda",
                            "Gondia",
                            "Gopalganj",
                            "Gorakhpur",
                            "Gumla",
                            "Guna",
                            "Guntur",
                            "Gurdaspur",
                            "Gurugram (Gurgaon)",
                            "Gwalior",
                            "Hailakandi",
                            "Hamirpur (HP)",
                            "Hamirpur (UP)",
                            "Hanumangarh",
                            "Hapur (Panchsheel Nagar)",
                            "Harda",
                            "Hardoi",
                            "Haridwar",
                            "Hassan",
                            "Hathras",
                            "Haveri",
                            "Hazaribag",
                            "Hingoli",
                            "Hisar",
                            "Hojai",
                            "Hooghly",
                            "Hoshangabad",
                            "Hoshiarpur",
                            "Howrah",
                            "Hyderabad",
                            "Idukki",
                            "Imphal East",
                            "Imphal West",
                            "Indore",
                            "Jabalpur",
                            "Jagatsinghapur",
                            "Jagtial",
                            "Jaipur",
                            "Jaisalmer",
                            "Jajpur",
                            "Jalandhar",
                            "Jalaun",
                            "Jalgaon",
                            "Jalna",
                            "Jalore",
                            "Jalpaiguri",
                            "Jammu",
                            "Jamnagar",
                            "Jamtara",
                            "Jamui",
                            "Jangaon",
                            "Janjgir-Champa",
                            "Jashpur",
                            "Jaunpur",
                            "Jayashankar Bhoopalpally",
                            "Jehanabad",
                            "Jhabua",
                            "Jhajjar",
                            "Jhalawar",
                            "Jhansi",
                            "Jhargram",
                            "Jharsuguda",
                            "Jhunjhunu",
                            "Jind",
                            "Jiribam",
                            "Jodhpur",
                            "Jogulamba Gadwal",
                            "Jorhat",
                            "Junagadh",
                            "Kabirdham (Kawardha)",
                            "Kachchh",
                            "Kaimur (Bhabua)",
                            "Kaithal",
                            "Kakching",
                            "Kalaburagi (Gulbarga)",
                            "Kalahandi",
                            "Kalimpong",
                            "Kallakurichi",
                            "Kamareddy",
                            "Kamjong",
                            "Kamle",
                            "Kamrup",
                            "Kamrup Metropolitan",
                            "Kanchipuram",
                            "Kandhamal",
                            "Kangpokpi",
                            "Kangra",
                            "Kanker (North Bastar)",
                            "Kannauj",
                            "Kannur",
                            "Kanpur Dehat",
                            "Kanpur Nagar",
                            "Kanshiram Nagar (Kasganj)",
                            "Kanyakumari",
                            "Kapurthala",
                            "Karaikal",
                            "Karauli",
                            "Karbi Anglong",
                            "Kargil",
                            "Karimganj",
                            "Karimnagar",
                            "Karnal",
                            "Karur",
                            "Kasaragod",
                            "Kathua",
                            "Katihar",
                            "Katni",
                            "Kaushambi",
                            "Kendrapara",
                            "Kendujhar (Keonjhar)",
                            "Khagaria",
                            "Khammam",
                            "Khandwa",
                            "Khargone",
                            "Kheda (Nadiad)",
                            "Khordha",
                            "Khowai",
                            "Khunti",
                            "Kinnaur",
                            "Kiphire",
                            "Kishanganj",
                            "Kishtwar",
                            "Kodagu",
                            "Koderma",
                            "Kohima",
                            "Kokrajhar",
                            "Kolar",
                            "Kolasib",
                            "Kolhapur",
                            "Kolkata",
                            "Kollam",
                            "Komaram Bheem Asifabad",
                            "Kondagaon",
                            "Koppal",
                            "Koraput",
                            "Korba",
                            "Korea (Koriya)",
                            "Kota",
                            "Kottayam",
                            "Kozhikode",
                            "Kra Daadi",
                            "Krishna",
                            "Krishnagiri",
                            "Kulgam",
                            "Kullu",
                            "Kupwara",
                            "Kurnool",
                            "Kurukshetra",
                            "Kurung Kumey",
                            "Kushinagar (Padrauna)",
                            "Lahaul & Spiti",
                            "Lakhimpur",
                            "Lakhimpur - Kheri",
                            "Lakhisarai",
                            "Lakshadweep",
                            "Lalitpur",
                            "Latehar",
                            "Latur",
                            "Lawngtlai",
                            "Leh",
                            "Lepa Rada",
                            "Lohardaga",
                            "Lohit",
                            "Longding",
                            "Longleng",
                            "Lower Dibang Valley",
                            "Lower Siang",
                            "Lower Subansiri",
                            "Lucknow",
                            "Ludhiana",
                            "Lunglei",
                            "Madhepura",
                            "Madhubani",
                            "Madurai",
                            "Mahabubabad",
                            "Mahabubnagar",
                            "Maharajganj",
                            "Mahasamund",
                            "Mahe",
                            "Mahendragarh",
                            "Mahisagar",
                            "Mahoba",
                            "Mainpuri",
                            "Majuli",
                            "Malappuram",
                            "Malda",
                            "Malkangiri",
                            "Mamit",
                            "Mancherial",
                            "Mandi",
                            "Mandla",
                            "Mandsaur",
                            "Mandya",
                            "Mansa",
                            "Mathura",
                            "Mau",
                            "Mayurbhanj",
                            "Medak",
                            "Medchal",
                            "Meerut",
                            "Mehsana",
                            "Mirzapur",
                            "Moga",
                            "Mokokchung",
                            "Mon",
                            "Moradabad",
                            "Morbi",
                            "Morena",
                            "Morigaon",
                            "Muktsar",
                            "Mumbai City",
                            "Mumbai Suburban",
                            "Mungeli",
                            "Munger (Monghyr)",
                            "Murshidabad",
                            "Muzaffarnagar",
                            "Muzaffarpur",
                            "Mysuru (Mysore)",
                            "Nabarangpur",
                            "Nadia",
                            "Nagaon",
                            "Nagapattinam",
                            "Nagarkurnool",
                            "Nagaur",
                            "Nagpur",
                            "Nainital",
                            "Nalanda",
                            "Nalbari",
                            "Nalgonda",
                            "Namakkal",
                            "Namsai",
                            "Nanded",
                            "Nandurbar",
                            "Narayanpur",
                            "Narmada (Rajpipla)",
                            "Narsinghpur",
                            "Nashik",
                            "Navsari",
                            "Nawada",
                            "Nawanshahr (Shahid Bhagat Singh Nagar)",
                            "Nayagarh",
                            "Neemuch",
                            "New Delhi",
                            "Nicobar",
                            "Nilgiris",
                            "Nirmal",
                            "Nizamabad",
                            "Noney",
                            "North 24 Parganas",
                            "North Delhi",
                            "North East Delhi",
                            "North Garo Hills",
                            "North Goa",
                            "North Sikkim",
                            "North Tripura",
                            "North West Delhi",
                            "North and Middle Andaman",
                            "Nuapada",
                            "Nuh",
                            "Osmanabad",
                            "Pakke Kessang",
                            "Pakur",
                            "Palakkad",
                            "Palamu",
                            "Palghar",
                            "Pali",
                            "Palwal",
                            "Panchkula",
                            "Panchmahal (Godhra)",
                            "Panipat",
                            "Panna",
                            "Papum Pare",
                            "Parbhani",
                            "Paschim (West) Burdwan (Bardhaman)",
                            "Paschim Medinipur (West Medinipur)",
                            "Patan",
                            "Pathanamthitta",
                            "Pathankot",
                            "Patiala",
                            "Patna",
                            "Pauri Garhwal",
                            "Peddapalli",
                            "Perambalur",
                            "Peren",
                            "Phek",
                            "Pherzawl",
                            "Pilibhit",
                            "Pithoragarh",
                            "Poonch",
                            "Porbandar",
                            "Prakasam",
                            "Pratapgarh (RJ)",
                            "Pratapgarh (UP)",
                            "Puducherry",
                            "Pudukkottai",
                            "Pulwama",
                            "Pune",
                            "Purba Burdwan (Bardhaman)",
                            "Purba Medinipur (East Medinipur)",
                            "Puri",
                            "Purnia (Purnea)",
                            "Purulia",
                            "RaeBareli",
                            "Raichur",
                            "Raigad",
                            "Raigarh",
                            "Raipur",
                            "Raisen",
                            "Rajanna Sircilla",
                            "Rajgarh",
                            "Rajkot",
                            "Rajnandgaon",
                            "Rajouri",
                            "Rajsamand",
                            "Ramanagara",
                            "Ramanathapuram",
                            "Ramban",
                            "Ramgarh",
                            "Rampur",
                            "Ranchi",
                            "Rangareddy",
                            "Ranipet",
                            "Ratlam",
                            "Ratnagiri",
                            "Rayagada",
                            "Reasi",
                            "Rewa",
                            "Rewari",
                            "Ri Bhoi",
                            "Rohtak",
                            "Rohtas",
                            "Rudraprayag",
                            "Rupnagar",
                            "Sabarkantha (Himmatnagar)",
                            "Sagar",
                            "Saharanpur",
                            "Saharsa",
                            "Sahibganj",
                            "Sahibzada Ajit Singh Nagar (Mohali)",
                            "Saiha",
                            "Salem",
                            "Samastipur",
                            "Samba",
                            "Sambalpur",
                            "Sambhal (Bhim Nagar)",
                            "Sangareddy",
                            "Sangli",
                            "Sangrur",
                            "Sant Kabir Nagar",
                            "Saran",
                            "Satara",
                            "Satna",
                            "Sawai Madhopur",
                            "Sehore",
                            "Senapati",
                            "Seoni",
                            "Sepahijala",
                            "Seraikela-Kharsawan",
                            "Serchhip",
                            "Shahdara",
                            "Shahdol",
                            "Shahjahanpur",
                            "Shajapur",
                            "Shamali (Prabuddh Nagar)",
                            "Sheikhpura",
                            "Sheohar",
                            "Sheopur",
                            "Shi Yomi",
                            "Shimla",
                            "Shivamogga (Shimoga)",
                            "Shivpuri",
                            "Shopian",
                            "Shravasti",
                            "Siang",
                            "Siddharth Nagar",
                            "Siddipet",
                            "Sidhi",
                            "Sikar",
                            "Simdega",
                            "Sindhudurg",
                            "Singrauli",
                            "Sirmaur (Sirmour)",
                            "Sirohi",
                            "Sirsa",
                            "Sitamarhi",
                            "Sitapur",
                            "Sivaganga",
                            "Sivasagar",
                            "Siwan",
                            "Solan",
                            "Solapur",
                            "Sonbhadra",
                            "Sonepur",
                            "Sonipat",
                            "Sonitpur",
                            "South 24 Parganas",
                            "South Andaman",
                            "South Delhi",
                            "South East Delhi",
                            "South Garo Hills",
                            "South Goa",
                            "South Salamara-Mankachar",
                            "South Sikkim",
                            "South Tripura",
                            "South West Delhi",
                            "South West Garo Hills",
                            "South West Khasi Hills",
                            "Sri Ganganagar",
                            "Sri Potti Sriramulu Nellore ",
                            "Srikakulam",
                            "Srinagar",
                            "Sukma",
                            "Sultanpur",
                            "Sundargarh",
                            "Supaul",
                            "Surajpur",
                            "Surat",
                            "Surendranagar",
                            "Surguja",
                            "Suryapet",
                            "Tamenglong",
                            "Tapi (Vyara)",
                            "Tarn Taran",
                            "Tawang",
                            "Tehri Garhwal",
                            "Tengnoupal",
                            "Tenkasi",
                            "Thane",
                            "Thanjavur",
                            "Theni",
                            "Thiruvananthapuram",
                            "Thoothukudi (Tuticorin)",
                            "Thoubal",
                            "Thrissur",
                            "Tikamgarh",
                            "Tinsukia",
                            "Tirap",
                            "Tiruchirappalli",
                            "Tirunelveli",
                            "Tirupathur",
                            "Tiruppur",
                            "Tiruvallur",
                            "Tiruvannamalai",
                            "Tiruvarur",
                            "Tonk",
                            "Tuensang",
                            "Tumakuru (Tumkur)",
                            "Udaipur",
                            "Udalguri",
                            "Udham Singh Nagar",
                            "Udhampur",
                            "Udupi",
                            "Ujjain",
                            "Ukhrul",
                            "Umaria",
                            "Una",
                            "Unakoti",
                            "Unnao",
                            "Upper Siang",
                            "Upper Subansiri",
                            "Uttar Dinajpur (North Dinajpur)",
                            "Uttara Kannada (Karwar)",
                            "Uttarkashi",
                            "Vadodara",
                            "Vaishali",
                            "Valsad",
                            "Varanasi",
                            "Vellore",
                            "Vidisha",
                            "Vijayapura (Bijapur)",
                            "Vikarabad",
                            "Viluppuram",
                            "Virudhunagar",
                            "Visakhapatnam",
                            "Vizianagaram",
                            "Wanaparthy",
                            "Warangal (Rural)",
                            "Warangal (Urban)",
                            "Wardha",
                            "Washim",
                            "Wayanad",
                            "West Champaran",
                            "West Delhi",
                            "West Garo Hills",
                            "West Godavari",
                            "West Jaintia Hills",
                            "West Kameng",
                            "West Karbi Anglong",
                            "West Khasi Hills",
                            "West Siang",
                            "West Sikkim",
                            "West Singhbhum",
                            "West Tripura",
                            "Wokha",
                            "YSR District, Kadapa (Cuddapah)",
                            "Yadadri Bhuvanagiri",
                            "Yadgir",
                            "Yamunanagar",
                            "Yanam",
                            "Yavatmal",
                            "Zunheboto",
                          ]}
                          options={{
                            placeholder: "Select your city/district...",
                          }}
                        />
                      </div>
                    </center>
                  </div>
                </div>
                <div className="col-md-4">
                  <div>
                    <div>
                      <label className="adddervicelabel">Select service:-</label>
                    </div>
                    <div className="addserviceselectdiv">
                      <Select2
                        id="serviceselect"
                        onChange={this.selectservicehandle}
                        value={this.state.serviceselectvalue}
                        data={this.state.serviceName}
                        options={{
                          placeholder: "Select service...",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default Addservice;

import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "./Header";
import Footer from "./Footer";
import $, { data } from "jquery";
import axios from "axios";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import filterFn2 from "./TargetedFilterFcn";
import Sidebar from "./Sidebar";
import JSZipUtils from "jszip-utils";
import JSZip from "jszip";
import Select2 from "react-select2-wrapper";
import AllServices from "../AllServices1.json"
import jsonData from "../State__and__dist.json"

let Decagentid;
let Decjwttoken;
var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);
let Doclink;
let Docname;
let servicestate;
let baseUrl = process.env.REACT_APP_API_BASE_URL;
export class Referrallead extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refferalHistory: [],
      agent: true,
      type: "agent",
      pageCount: 0,
      entry: 10,
      currentPage: 0,
      Selectedwhatsapptimeperiod: "select",
      Selectedemailtimeperiod: "select",
      sharedocumentname: "",
      sharedocumentlink: "",
      ticket: false,


      name:"",
      email:"",
      mobileNo:"",
      citydistrictselectvalue:"",
      serviceselectvalue:"",
      otherServicename:""
    };
  }

  handlePageClick = ({ selected: selectedPage }) => {
    this.setState({ refferalHistory: [] });
    this.setState({ currentPage: selectedPage }, this.referralpartnerHis);
  };
  componentDidMount = () => {
    this.setState({
        serviceName: Object.values(AllServices)
      })
    window.scrollTo(0, 0);
    $(document.body).css("background-color", "#2d2d2d");
    $("#agentquerybtn").show();
    const script = document.createElement("script");
    script.src = "/js/main.js";
    script.async = true;

    document.body.appendChild(script);
    let mainagentid = localStorage.getItem("qazxswedcvfrtgb");
    Decagentid = encryptor.decrypt(mainagentid);
    let mainjwttoken = localStorage.getItem("toeljgtkewlna");
    Decjwttoken = encryptor.decrypt(mainjwttoken);

    if (this.props.location) {
      if (this.props.location.lead != null || this.props.location.lead != undefined) {
        this.showNotification(this.props.location.lead.message)
      }
    }
  };



  logout = () => {
    localStorage.removeItem("gglltakoinoeenl");
    localStorage.removeItem("toeljgtkewlna");
    localStorage.removeItem("qazxswedcvfrtgb");
    localStorage.removeItem("lshhihfnkff");
    this.props.history.push({
      pathname: `/`,
    });
  };
  referralpartnerHis = () => {
    $("#ticketagent-loader").show();
    $("#ticketagent-empty").hide();
    $("#ticketuser-empty").hide();

    axios
      .post(
        `${baseUrl}p=referralPartnerHistory`,
        {
          agentId: Decagentid.trim(),
          pageCount: this.state.currentPage + 1,
          entry: this.state.entry,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == 200 && res.data.status == "success") {
          $("#ticketagent-loader").hide();
          $("#ticketagent-empty").hide();
            this.setState({
              pageCount: res.data.totalpageCount,
              refferalHistory: res.data.promotionData,
            });
        } else if (res.data.code == 201 && res.data.status == "fail") {
          this.showNotification(res.data.message);
          $(".ticketagent-loader").hide();
          $("#ticketagent-empty").show();
        } else if (
          res.data.code == "201" &&
          res.data.status == "invalidauth"
        ) {
          this.logout();
        } else {
          $(".ticketagent-loader").hide();
          $("#ticketagent-empty").show();
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {});
  };
  


  renderlatestdata = () => {
    return this.state.refferalHistory.map((data, index) => {
      return (
        <tr key={index}>
          <td scope="row" className="sno">
            {this.state.entry * this.state.currentPage + index + 1}
          </td>
          <td className="detailsusers">{data.agentId}</td>
          <td className="servicessupport">{data.customerName}</td>
          <td className="servicessupport">{data.customerEmail}</td>
          <td className="servicessupport">{data.customerContact}</td>

          <td className="descsupport">
            {" "}
            {data.state}
          </td>
          <td className="detailsusers">
            {data.city}
          </td>
          <td className="detailsusers">
            {data.leadStatus}
          </td>
          <td className="detailsusers">
            {data.referralId}
          </td>
          <td className="servicessupport">
          {this.serviceFullname(data.serviceName)}
          </td>
          {/* <td className="status">{data.status}</td>
          <td className="details">
            <button className="viewbtn" onClick={() => this.clickViewBtn(data)}>
              View <img src={process.env.PUBLIC_URL + "/img/Path 19498.svg"} />
            </button>
          </td> */}
        </tr>
      );
    });
  };

  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };

  filterDistricts = () => {
    servicestate = jsonData[0][this.state.citydistrictselectvalue]
  };

  citydistrictselecthandle = (event) => {
    this.setState({
      citydistrictselectvalue: event.target.value,
    });

  };

  selectservicehandle = (event) => {
    if (event.target.value != "") {
      this.setState({
        serviceselectvalue: event.target.value,
      });
    }
  }

  getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
  }

  serviceFullname = (shortName) => {
    if(AllServices[shortName] == undefined){
      return shortName
    } else{
      return AllServices[shortName]
    }
    
  }

  handleOtherServiceChange = (e) => {
    this.setState({
      otherServicename: e.target.value,
    })
  }

  wordCapetilize = (word) => {
    var mySentence = word.trim();
    mySentence = mySentence.replace(/\s+/g, " ");
    const words = mySentence.split(" ");

    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    
    return words.join(" ");
  }

  handleagentsubmit = () => {
    $("#invalidagentquerytype").hide();
    $("#invalidagentsubject").hide();
    $("#invalidagentdesc").hide();
    $("#nullagentsubject").hide();
    $("#nullagentdesc").hide();
    let shortkey;
    const contactNoregex = /^[6-9]\d{9}$/;
    const emailregex = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    if (this.state.name.trim() == "") {
      $("#invalidagentquerytype").show();
      $("#agentQueryType").focus();
    } else if (this.state.email.trim() != "" && !emailregex.test(this.state.email)) {
        $("#validEmail").show();
        // $(".inpemail").focus();
    }  else if (this.state.mobileNo.trim() == "") {
      $("#nullagentsubject").show();
    //   $("#agentsubject").focus();
    } else if (this.state.mobileNo.trim() != "" && !contactNoregex.test(this.state.mobileNo)) {
        $("#invalidagentsubject").show();
        // $("#agentsubject").focus();
      } else if (this.state.citydistrictselectvalue.trim() == "") {
      $("#nullagentdesc").show();
    //   $("#agentsubject").focus();
    } 
    else if (this.state.serviceselectvalue.trim() == "") {
      $("#selectservice").show();
    //   $("#agentdesc").focus();
     } else if (this.state.serviceselectvalue == "Other"  &&  this.state.otherServicename == "") {
      $("#otherserv").show();
    //   $("#agentdesc").focus();
     }  else {
        this.filterDistricts();
        
      if(this.state.serviceselectvalue == "Other"){
         shortkey = this.wordCapetilize(this.state.otherServicename);
      } else{
         shortkey = this.getKeyByValue(AllServices, this.state.serviceselectvalue);
      }

      
      var FullName = this.wordCapetilize(this.state.name);
      
            
      $(".sendOtpLoader").show();
      $("#agentquerybtn").hide();
      $("#invalidagentquerytype").hide();
      $("#invalidagentsubject").hide();
      $("#invalidagentdesc").hide();
      $("#nullagentsubject").hide();
      $("#nullagentdesc").hide();

      axios
        .post(`${baseUrl}p=partnerReferralLead`, 
        {
            agentId: Decagentid,
            cusstomerName: FullName,
            customerContact: this.state.mobileNo,
            customerEmail: this.state.email,
            citydistrict: this.state.citydistrictselectvalue,
            state: servicestate,
            serviceName: shortkey
        }
        , {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        })
        .then((res) => {
          if (res.data.code == 200 && res.data.status == "success") {
            // this.showNotification(res.data.message);
          
            sessionStorage.setItem(
              "ielndmlgeaefelvsdrlucaa",
              encryptor.encrypt(this.state.name));
              sessionStorage.setItem(
                "srleleddgaeaemvcnia",
                encryptor.encrypt(shortkey)
              );
              sessionStorage.setItem(
                "uegiqlsartdele",
                encryptor.encrypt(res.data.referralId)
              );
              sessionStorage.setItem(
                "aprcillgee",
                encryptor.encrypt(parseFloat(""))
              );
              sessionStorage.setItem("evlgsoaeetgfl", encryptor.encrypt(0));
              sessionStorage.setItem("lslgerotnscmvheearaei", encryptor.encrypt(shortkey));
              sessionStorage.setItem("egalllpna", encryptor.encrypt("none"));
              sessionStorage.setItem("srewrercgdgfdgf", encryptor.encrypt(true));

              this.setState({
                name:"",
                mobileNo:"",
                email:"",
                citydistrictselectvalue:"",
                serviceselectvalue:"",
                otherServicename:""
              });

            
            this.props.history.push({
              pathname: `/addservice/checkout`,
              lead: { message: res.data.message },
            });
            $("#choose_STRecallPlan").show();
            $("#X-btn_STRecallPlan").hide();
            $(".sendOtpLoader").hide();
            $("#agentquerybtn").show();
          } else if (res.data.code == 201 && res.data.status == "fail") {
            this.showNotification(res.data.message);
            $("#agentquerybtn").show();
            $(".sendOtpLoader").hide();
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          }
        })
        .catch((err) => {
          //
          this.showNotification("Something went wrong");
          $(".sendOtpLoader").hide();
          $(".sendOtpBtn").show();
          $("#agentquerybtn").show();
        });
    }
  };

  render() {
    if (
      localStorage.getItem("gglltakoinoeenl") ==
        process.env.REACT_APP_LOGIN_KEY &&
      localStorage.getItem("toeljgtkewlna") != null &&
      localStorage.getItem("qazxswedcvfrtgb") != null
    ) {
      return (
        <div>
          <div>
            <div className="cont">
              <div id="notifContainer"></div>
              <Helmet>
                <link
                  rel="stylesheet"
                  href={process.env.PUBLIC_URL + "/css/select2.css"}
                />
                <link rel="stylesheet" href="css/services.css" />
              </Helmet>

              <Header />

              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
                className="sidebaropenicon"
              />
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
                className="sidebarbackicon"
              />
              <img
                alt="loading..."
                src={
                  process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"
                }
                className="sidebarexpandicon"
              />

              <div className="overlay1"></div>
              <Sidebar />

              <div className="arrow-left12"></div>
              <div className="white_div">
                <div className="white_box" id="promotion_white_box">
                  <div className="main-row">
                    <div className="agent__support__header">
                      <div className="agent__support__header__1">
                        <img
                          src="/img/Path_231.svg"
                          alt="back"
                          className="agent__useropt__backbtn"
                        />

                        <form>
                          <input
                            type="text"
                            className="agent__comm__search_box  support__search"
                            placeholder="Search by services"
                          />
                        </form>
                      </div>

                      <div
                        className="agent-navigation-tab"
                        id="promotion__heads__1"
                      >
                        <header className="tabs-nav MT-3 position-relative">
                          <ul className="d-flex support-navigation-tab-ul">
                            <li
                              className="active agent-navigation-tab-li"
                              id="summary1"
                            >
                              <a
                                href="#tab1"
                                className="px-2 py-1"
                                onClick={() => this.setState({ ticket: false })}
                              >
                                Create Lead
                              </a>
                            </li>
                            <li
                              className="agent-navigation-tab-li"
                              id="history1"
                            >
                              <a
                                id="ticket-agent1"
                                href="#tab2"
                                onClick={() => {
                                  this.referralpartnerHis()
                                  this.setState({ ticket: true });
                                }}
                                className="px-2 py-1"
                              >
                                Lead History
                              </a>
                            </li>
                            <hr className="comm__navigation__tab__vertical__line__22" />
                            <div className="comm__slider9 comm__slider12"></div>
                          </ul>
                        </header>
                      </div>

                      <div className="agent__support__div invisible">
                        <select className="agent__support__selectcateselect">
                          <option
                            value="volvo"
                            className="selectcateselectoption2"
                          >
                            FY
                          </option>
                        </select>
                      </div>
                    </div>

                    {/* ipad */}
                    <div
                      className="agent-navigation-tab"
                      id="promotion__heads__2"
                    >
                      <header className="tabs-nav MT-3 position-relative">
                        <ul className="d-flex support-navigation-tab-ul">
                          <li
                            className="active agent-navigation-tab-li"
                            id="summary2"
                            onClick={() => this.setState({ ticket: false })}
                          >
                            <a href="#tab1">Create Lead</a>
                          </li>
                          <li className="agent-navigation-tab-li" id="history2">
                            <a
                              id="ticket-agent2"
                              href="#tab2"
                              onClick={() => {
                                this.referralpartnerHis()
                                this.setState({ ticket: true });
                              }}
                            >
                              Lead History
                            </a>
                          </li>

                          {/* <hr className="comm__navigation__tab__vertical__line__11" />
                          <hr className="comm__navigation__tab__vertical__line__22" /> */}
                          <div
                            className="comm__slider9 comm__slider12"
                            id="notForSmallScreens"
                          ></div>
                        </ul>
                      </header>
                    </div>

                    <div className="tabs-content pb-md-3 mt-md-0 mt-2">
                      <div id="tab1" className="tab-content">
                        <div className="support__scroll_div">
                          <div className="resflex agentSupportdiv">
                            <div className="col-12 col-md-12 col-lg-7 ">
                              {/* <h4 className="mb-3 mt-4 agen">Agent Support:</h4> */}
                              {/* <h2 className="agsup-head mb-4">
                                Have any query in mind!!
                                <br /> Get support from <span>
                                  Legal251
                                </span>{" "}
                                Team
                              </h2> */}
                              {/* <p className="m-0 att">Attention:</p>
                              <p className="att2">
                                👉 Expected Wait Time on all Service channels is
                                1-2 days.🙏
                              </p> */}

                              <div className="agentQueryform mt-2">
                                {/* <h4 className="mb-2 ">Raise Support Query</h4> */}
                                <p className="m-0 mb-1">
                                Customer Name <span>*</span>:
                                </p>
                               
                                <input
                                  id="agentsubject"
                                  className=" query-desc capatalizeMe"
                                  maxLength="120"
                                  type="text"
                                  value={this.state.name}
                                  onChange={(e) =>
                                    this.setState({
                                      name: e.target.value,
                                    })
                                  }
                                />
                                <p
                                  className=" mt-2 warn text-danger align-items-center"
                                  id="invalidagentquerytype"
                                >
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Icon_feather-alert-circle.svg"
                                    }
                                    width="12px"
                                    className=" mr-1"
                                  />{" "}
                                  Please Enter Customer Name
                                </p>
                                <p className="m-0 mt-4 mb-1">
                                  Email (optional):
                                </p>
                                <input
                                  id="agentsubject"
                                  className=" query-desc inpemail"
                                  maxLength="256"
                                  type="text"
                                  value={this.state.email}
                                  onChange={(e) =>
                                    this.setState({
                                        email: e.target.value,
                                    })
                                  }
                                />
                                <p
                                  className=" mt-2 warn text-danger align-items-center"
                                  id="Email"
                                >
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Icon_feather-alert-circle.svg"
                                    }
                                    width="12px"
                                    className=" mr-1"
                                  />{" "}
                                  Please enter email.
                                </p>
                                <p
                                  className=" mt-2 warn text-danger align-items-center"
                                  id="validEmail"
                                >
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Icon_feather-alert-circle.svg"
                                    }
                                    width="12px"
                                    className=" mr-1"
                                  />{" "}
                                  Please enter valid email.
                                </p>
                                <p className="m-0 mt-4 mb-1">
                                  Mobile Number <span>*</span>:
                                </p>
                                <input
                                  id="agentsubject"
                                  className=" query-desc"
                                  maxLength="10"
                                  type="tel"
                                  value={this.state.mobileNo}
                                  onChange={(e) =>
                                    this.setState({
                                        mobileNo: e.target.value.replace(/\D/g, ''),
                                    })
                                  }
                                />
                               
                                <p
                                  className=" mt-2 warn text-danger align-items-center"
                                  id="nullagentsubject"
                                >
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Icon_feather-alert-circle.svg"
                                    }
                                    width="12px"
                                    className=" mr-1"
                                  />{" "}
                                  Please enter Mobile Number.
                                </p>
                                <p
                                  className=" mt-2 warn text-danger align-items-center"
                                  id="invalidagentsubject"
                                >
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Icon_feather-alert-circle.svg"
                                    }
                                    width="12px"
                                    className=" mr-1"
                                  />{" "}
                                  Please enter valid Mobile Number
                                </p>

                                <p className="m-0 mt-4 mb-1">
                                Select City / District <span>*</span>:
                                </p>
                                    <div id="citydisc">
                                <Select2
                                    onChange={this.citydistrictselecthandle}
                                    value={this.state.citydistrictselectvalue}
                                    data={[
                                        "Adilabad",
                                        "Agar Malwa",
                                        "Agra",
                                        "Ahmedabad",
                                        "Ahmednagar",
                                        "Aizawl",
                                        "Ajmer",
                                        "Akola",
                                        "Alappuzha",
                                        "Aligarh",
                                        "Alipurduar",
                                        "Alirajpur",
                                        "Allahabad",
                                        "Almora",
                                        "Alwar",
                                        "Ambala",
                                        "Ambedkar Nagar",
                                        "Amethi (Chatrapati Sahuji Mahraj Nagar)",
                                        "Amravati",
                                        "Amreli",
                                        "Amritsar",
                                        "Amroha",
                                        "Anand",
                                        "Anantapur",
                                        "Anantnag",
                                        "Angul",
                                        "Anjaw",
                                        "Anuppur",
                                        "Araria",
                                        "Aravalli",
                                        "Ariyalur",
                                        "Arwal",
                                        "Ashoknagar",
                                        "Auraiya",
                                        "Aurangabad (BH)",
                                        "Aurangabad (MH)",
                                        "Azamgarh",
                                        "Bagalkot",
                                        "Bageshwar",
                                        "Baghpat",
                                        "Bahraich",
                                        "Baksa",
                                        "Balaghat",
                                        "Balangir",
                                        "Balasore",
                                        "Ballari (Bellary)",
                                        "Ballia",
                                        "Balod",
                                        "Baloda Bazar",
                                        "Balrampur (CG)",
                                        "Balrampur (UP)",
                                        "Banaskantha (Palanpur)",
                                        "Banda",
                                        "Bandipore",
                                        "Banka",
                                        "Bankura",
                                        "Banswara",
                                        "Barabanki",
                                        "Baramulla",
                                        "Baran",
                                        "Bareilly",
                                        "Bargarh",
                                        "Barmer",
                                        "Barnala",
                                        "Barpeta",
                                        "Barwani",
                                        "Bastar",
                                        "Basti",
                                        "Bathinda",
                                        "Beed",
                                        "Begusarai",
                                        "Belagavi (Belgaum)",
                                        "Bemetara",
                                        "Bengaluru (Bangalore) Rural",
                                        "Bengaluru (Bangalore) Urban",
                                        "Betul",
                                        "Bhadohi",
                                        "Bhadradri Kothagudem",
                                        "Bhadrak",
                                        "Bhagalpur",
                                        "Bhandara",
                                        "Bharatpur",
                                        "Bharuch",
                                        "Bhavnagar",
                                        "Bhilwara",
                                        "Bhind",
                                        "Bhiwani",
                                        "Bhojpur",
                                        "Bhopal",
                                        "Bidar",
                                        "Bijapur",
                                        "Bijnor",
                                        "Bikaner",
                                        "Bilaspur (CG)",
                                        "Bilaspur (HP)",
                                        "Birbhum",
                                        "Bishnupur",
                                        "Biswanath",
                                        "Bokaro",
                                        "Bongaigaon",
                                        "Botad",
                                        "Boudh",
                                        "Budaun",
                                        "Budgam",
                                        "Bulandshahr",
                                        "Buldhana",
                                        "Bundi",
                                        "Burhanpur",
                                        "Buxar",
                                        "Cachar",
                                        "Central Delhi",
                                        "Chamarajanagar",
                                        "Chamba",
                                        "Chamoli",
                                        "Champawat",
                                        "Champhai",
                                        "Chandauli",
                                        "Chandel",
                                        "Chandigarh",
                                        "Chandrapur",
                                        "Changlang",
                                        "Charaideo",
                                        "Charkhi Dadri",
                                        "Chatra",
                                        "Chengalpattu",
                                        "Chennai",
                                        "Chhatarpur",
                                        "Chhindwara",
                                        "Chhota Udepur",
                                        "Chikballapur",
                                        "Chikkamagaluru (Chikmagalur)",
                                        "Chirang",
                                        "Chitradurga",
                                        "Chitrakoot",
                                        "Chittoor",
                                        "Chittorgarh",
                                        "Churachandpur",
                                        "Churu",
                                        "Coimbatore",
                                        "Cooch Behar",
                                        "Cuddalore",
                                        "Cuttack",
                                        "Dadra & Nagar Haveli",
                                        "Dahod",
                                        "Dakshin Dinajpur (South Dinajpur)",
                                        "Dakshina Kannada",
                                        "Daman",
                                        "Damoh",
                                        "Dangs (Ahwa)",
                                        "Dantewada (South Bastar)",
                                        "Darbhanga",
                                        "Darjeeling",
                                        "Darrang",
                                        "Datia",
                                        "Dausa",
                                        "Davangere",
                                        "Dehradun",
                                        "Deogarh",
                                        "Deoghar",
                                        "Deoria",
                                        "Devbhoomi Dwarka",
                                        "Dewas",
                                        "Dhalai",
                                        "Dhamtari",
                                        "Dhanbad",
                                        "Dhar",
                                        "Dharmapuri",
                                        "Dharwad",
                                        "Dhemaji",
                                        "Dhenkanal",
                                        "Dholpur",
                                        "Dhubri",
                                        "Dhule",
                                        "Dibang Valley",
                                        "Dibrugarh",
                                        "Dima Hasao (North Cachar Hills)",
                                        "Dimapur",
                                        "Dindigul",
                                        "Dindori",
                                        "Diu",
                                        "Doda",
                                        "Dumka",
                                        "Dungarpur",
                                        "Durg",
                                        "East Champaran (Motihari)",
                                        "East Delhi",
                                        "East Garo Hills",
                                        "East Godavari",
                                        "East Jaintia Hills",
                                        "East Kameng",
                                        "East Khasi Hills",
                                        "East Siang",
                                        "East Sikkim",
                                        "East Singhbhum",
                                        "Ernakulam",
                                        "Erode",
                                        "Etah",
                                        "Etawah",
                                        "Faizabad",
                                        "Faridabad",
                                        "Faridkot",
                                        "Farrukhabad",
                                        "Fatehabad",
                                        "Fatehgarh Sahib",
                                        "Fatehpur",
                                        "Fazilka",
                                        "Ferozepur",
                                        "Firozabad",
                                        "Gadag",
                                        "Gadchiroli",
                                        "Gajapati",
                                        "Ganderbal",
                                        "Gandhinagar",
                                        "Ganjam",
                                        "Garhwa",
                                        "Gariyaband",
                                        "Gautam Buddha Nagar",
                                        "Gaya",
                                        "Ghaziabad",
                                        "Ghazipur",
                                        "Gir Somnath",
                                        "Giridih",
                                        "Goalpara",
                                        "Godda",
                                        "Golaghat",
                                        "Gomati",
                                        "Gonda",
                                        "Gondia",
                                        "Gopalganj",
                                        "Gorakhpur",
                                        "Gumla",
                                        "Guna",
                                        "Guntur",
                                        "Gurdaspur",
                                        "Gurugram (Gurgaon)",
                                        "Gwalior",
                                        "Hailakandi",
                                        "Hamirpur (HP)",
                                        "Hamirpur (UP)",
                                        "Hanumangarh",
                                        "Hapur (Panchsheel Nagar)",
                                        "Harda",
                                        "Hardoi",
                                        "Haridwar",
                                        "Hassan",
                                        "Hathras",
                                        "Haveri",
                                        "Hazaribag",
                                        "Hingoli",
                                        "Hisar",
                                        "Hojai",
                                        "Hooghly",
                                        "Hoshangabad",
                                        "Hoshiarpur",
                                        "Howrah",
                                        "Hyderabad",
                                        "Idukki",
                                        "Imphal East",
                                        "Imphal West",
                                        "Indore",
                                        "Jabalpur",
                                        "Jagatsinghapur",
                                        "Jagtial",
                                        "Jaipur",
                                        "Jaisalmer",
                                        "Jajpur",
                                        "Jalandhar",
                                        "Jalaun",
                                        "Jalgaon",
                                        "Jalna",
                                        "Jalore",
                                        "Jalpaiguri",
                                        "Jammu",
                                        "Jamnagar",
                                        "Jamtara",
                                        "Jamui",
                                        "Jangaon",
                                        "Janjgir-Champa",
                                        "Jashpur",
                                        "Jaunpur",
                                        "Jayashankar Bhoopalpally",
                                        "Jehanabad",
                                        "Jhabua",
                                        "Jhajjar",
                                        "Jhalawar",
                                        "Jhansi",
                                        "Jhargram",
                                        "Jharsuguda",
                                        "Jhunjhunu",
                                        "Jind",
                                        "Jiribam",
                                        "Jodhpur",
                                        "Jogulamba Gadwal",
                                        "Jorhat",
                                        "Junagadh",
                                        "Kabirdham (Kawardha)",
                                        "Kachchh",
                                        "Kaimur (Bhabua)",
                                        "Kaithal",
                                        "Kakching",
                                        "Kalaburagi (Gulbarga)",
                                        "Kalahandi",
                                        "Kalimpong",
                                        "Kallakurichi",
                                        "Kamareddy",
                                        "Kamjong",
                                        "Kamle",
                                        "Kamrup",
                                        "Kamrup Metropolitan",
                                        "Kanchipuram",
                                        "Kandhamal",
                                        "Kangpokpi",
                                        "Kangra",
                                        "Kanker (North Bastar)",
                                        "Kannauj",
                                        "Kannur",
                                        "Kanpur Dehat",
                                        "Kanpur Nagar",
                                        "Kanshiram Nagar (Kasganj)",
                                        "Kanyakumari",
                                        "Kapurthala",
                                        "Karaikal",
                                        "Karauli",
                                        "Karbi Anglong",
                                        "Kargil",
                                        "Karimganj",
                                        "Karimnagar",
                                        "Karnal",
                                        "Karur",
                                        "Kasaragod",
                                        "Kathua",
                                        "Katihar",
                                        "Katni",
                                        "Kaushambi",
                                        "Kendrapara",
                                        "Kendujhar (Keonjhar)",
                                        "Khagaria",
                                        "Khammam",
                                        "Khandwa",
                                        "Khargone",
                                        "Kheda (Nadiad)",
                                        "Khordha",
                                        "Khowai",
                                        "Khunti",
                                        "Kinnaur",
                                        "Kiphire",
                                        "Kishanganj",
                                        "Kishtwar",
                                        "Kodagu",
                                        "Koderma",
                                        "Kohima",
                                        "Kokrajhar",
                                        "Kolar",
                                        "Kolasib",
                                        "Kolhapur",
                                        "Kolkata",
                                        "Kollam",
                                        "Komaram Bheem Asifabad",
                                        "Kondagaon",
                                        "Koppal",
                                        "Koraput",
                                        "Korba",
                                        "Korea (Koriya)",
                                        "Kota",
                                        "Kottayam",
                                        "Kozhikode",
                                        "Kra Daadi",
                                        "Krishna",
                                        "Krishnagiri",
                                        "Kulgam",
                                        "Kullu",
                                        "Kupwara",
                                        "Kurnool",
                                        "Kurukshetra",
                                        "Kurung Kumey",
                                        "Kushinagar (Padrauna)",
                                        "Lahaul & Spiti",
                                        "Lakhimpur",
                                        "Lakhimpur - Kheri",
                                        "Lakhisarai",
                                        "Lakshadweep",
                                        "Lalitpur",
                                        "Latehar",
                                        "Latur",
                                        "Lawngtlai",
                                        "Leh",
                                        "Lepa Rada",
                                        "Lohardaga",
                                        "Lohit",
                                        "Longding",
                                        "Longleng",
                                        "Lower Dibang Valley",
                                        "Lower Siang",
                                        "Lower Subansiri",
                                        "Lucknow",
                                        "Ludhiana",
                                        "Lunglei",
                                        "Madhepura",
                                        "Madhubani",
                                        "Madurai",
                                        "Mahabubabad",
                                        "Mahabubnagar",
                                        "Maharajganj",
                                        "Mahasamund",
                                        "Mahe",
                                        "Mahendragarh",
                                        "Mahisagar",
                                        "Mahoba",
                                        "Mainpuri",
                                        "Majuli",
                                        "Malappuram",
                                        "Malda",
                                        "Malkangiri",
                                        "Mamit",
                                        "Mancherial",
                                        "Mandi",
                                        "Mandla",
                                        "Mandsaur",
                                        "Mandya",
                                        "Mansa",
                                        "Mathura",
                                        "Mau",
                                        "Mayurbhanj",
                                        "Medak",
                                        "Medchal",
                                        "Meerut",
                                        "Mehsana",
                                        "Mirzapur",
                                        "Moga",
                                        "Mokokchung",
                                        "Mon",
                                        "Moradabad",
                                        "Morbi",
                                        "Morena",
                                        "Morigaon",
                                        "Muktsar",
                                        "Mumbai City",
                                        "Mumbai Suburban",
                                        "Mungeli",
                                        "Munger (Monghyr)",
                                        "Murshidabad",
                                        "Muzaffarnagar",
                                        "Muzaffarpur",
                                        "Mysuru (Mysore)",
                                        "Nabarangpur",
                                        "Nadia",
                                        "Nagaon",
                                        "Nagapattinam",
                                        "Nagarkurnool",
                                        "Nagaur",
                                        "Nagpur",
                                        "Nainital",
                                        "Nalanda",
                                        "Nalbari",
                                        "Nalgonda",
                                        "Namakkal",
                                        "Namsai",
                                        "Nanded",
                                        "Nandurbar",
                                        "Narayanpur",
                                        "Narmada (Rajpipla)",
                                        "Narsinghpur",
                                        "Nashik",
                                        "Navsari",
                                        "Nawada",
                                        "Nawanshahr (Shahid Bhagat Singh Nagar)",
                                        "Nayagarh",
                                        "Neemuch",
                                        "New Delhi",
                                        "Nicobar",
                                        "Nilgiris",
                                        "Nirmal",
                                        "Nizamabad",
                                        "Noney",
                                        "North 24 Parganas",
                                        "North Delhi",
                                        "North East Delhi",
                                        "North Garo Hills",
                                        "North Goa",
                                        "North Sikkim",
                                        "North Tripura",
                                        "North West Delhi",
                                        "North and Middle Andaman",
                                        "Nuapada",
                                        "Nuh",
                                        "Osmanabad",
                                        "Pakke Kessang",
                                        "Pakur",
                                        "Palakkad",
                                        "Palamu",
                                        "Palghar",
                                        "Pali",
                                        "Palwal",
                                        "Panchkula",
                                        "Panchmahal (Godhra)",
                                        "Panipat",
                                        "Panna",
                                        "Papum Pare",
                                        "Parbhani",
                                        "Paschim (West) Burdwan (Bardhaman)",
                                        "Paschim Medinipur (West Medinipur)",
                                        "Patan",
                                        "Pathanamthitta",
                                        "Pathankot",
                                        "Patiala",
                                        "Patna",
                                        "Pauri Garhwal",
                                        "Peddapalli",
                                        "Perambalur",
                                        "Peren",
                                        "Phek",
                                        "Pherzawl",
                                        "Pilibhit",
                                        "Pithoragarh",
                                        "Poonch",
                                        "Porbandar",
                                        "Prakasam",
                                        "Pratapgarh (RJ)",
                                        "Pratapgarh (UP)",
                                        "Puducherry",
                                        "Pudukkottai",
                                        "Pulwama",
                                        "Pune",
                                        "Purba Burdwan (Bardhaman)",
                                        "Purba Medinipur (East Medinipur)",
                                        "Puri",
                                        "Purnia (Purnea)",
                                        "Purulia",
                                        "RaeBareli",
                                        "Raichur",
                                        "Raigad",
                                        "Raigarh",
                                        "Raipur",
                                        "Raisen",
                                        "Rajanna Sircilla",
                                        "Rajgarh",
                                        "Rajkot",
                                        "Rajnandgaon",
                                        "Rajouri",
                                        "Rajsamand",
                                        "Ramanagara",
                                        "Ramanathapuram",
                                        "Ramban",
                                        "Ramgarh",
                                        "Rampur",
                                        "Ranchi",
                                        "Rangareddy",
                                        "Ranipet",
                                        "Ratlam",
                                        "Ratnagiri",
                                        "Rayagada",
                                        "Reasi",
                                        "Rewa",
                                        "Rewari",
                                        "Ri Bhoi",
                                        "Rohtak",
                                        "Rohtas",
                                        "Rudraprayag",
                                        "Rupnagar",
                                        "Sabarkantha (Himmatnagar)",
                                        "Sagar",
                                        "Saharanpur",
                                        "Saharsa",
                                        "Sahibganj",
                                        "Sahibzada Ajit Singh Nagar (Mohali)",
                                        "Saiha",
                                        "Salem",
                                        "Samastipur",
                                        "Samba",
                                        "Sambalpur",
                                        "Sambhal (Bhim Nagar)",
                                        "Sangareddy",
                                        "Sangli",
                                        "Sangrur",
                                        "Sant Kabir Nagar",
                                        "Saran",
                                        "Satara",
                                        "Satna",
                                        "Sawai Madhopur",
                                        "Sehore",
                                        "Senapati",
                                        "Seoni",
                                        "Sepahijala",
                                        "Seraikela-Kharsawan",
                                        "Serchhip",
                                        "Shahdara",
                                        "Shahdol",
                                        "Shahjahanpur",
                                        "Shajapur",
                                        "Shamali (Prabuddh Nagar)",
                                        "Sheikhpura",
                                        "Sheohar",
                                        "Sheopur",
                                        "Shi Yomi",
                                        "Shimla",
                                        "Shivamogga (Shimoga)",
                                        "Shivpuri",
                                        "Shopian",
                                        "Shravasti",
                                        "Siang",
                                        "Siddharth Nagar",
                                        "Siddipet",
                                        "Sidhi",
                                        "Sikar",
                                        "Simdega",
                                        "Sindhudurg",
                                        "Singrauli",
                                        "Sirmaur (Sirmour)",
                                        "Sirohi",
                                        "Sirsa",
                                        "Sitamarhi",
                                        "Sitapur",
                                        "Sivaganga",
                                        "Sivasagar",
                                        "Siwan",
                                        "Solan",
                                        "Solapur",
                                        "Sonbhadra",
                                        "Sonepur",
                                        "Sonipat",
                                        "Sonitpur",
                                        "South 24 Parganas",
                                        "South Andaman",
                                        "South Delhi",
                                        "South East Delhi",
                                        "South Garo Hills",
                                        "South Goa",
                                        "South Salamara-Mankachar",
                                        "South Sikkim",
                                        "South Tripura",
                                        "South West Delhi",
                                        "South West Garo Hills",
                                        "South West Khasi Hills",
                                        "Sri Ganganagar",
                                        "Sri Potti Sriramulu Nellore ",
                                        "Srikakulam",
                                        "Srinagar",
                                        "Sukma",
                                        "Sultanpur",
                                        "Sundargarh",
                                        "Supaul",
                                        "Surajpur",
                                        "Surat",
                                        "Surendranagar",
                                        "Surguja",
                                        "Suryapet",
                                        "Tamenglong",
                                        "Tapi (Vyara)",
                                        "Tarn Taran",
                                        "Tawang",
                                        "Tehri Garhwal",
                                        "Tengnoupal",
                                        "Tenkasi",
                                        "Thane",
                                        "Thanjavur",
                                        "Theni",
                                        "Thiruvananthapuram",
                                        "Thoothukudi (Tuticorin)",
                                        "Thoubal",
                                        "Thrissur",
                                        "Tikamgarh",
                                        "Tinsukia",
                                        "Tirap",
                                        "Tiruchirappalli",
                                        "Tirunelveli",
                                        "Tirupathur",
                                        "Tiruppur",
                                        "Tiruvallur",
                                        "Tiruvannamalai",
                                        "Tiruvarur",
                                        "Tonk",
                                        "Tuensang",
                                        "Tumakuru (Tumkur)",
                                        "Udaipur",
                                        "Udalguri",
                                        "Udham Singh Nagar",
                                        "Udhampur",
                                        "Udupi",
                                        "Ujjain",
                                        "Ukhrul",
                                        "Umaria",
                                        "Una",
                                        "Unakoti",
                                        "Unnao",
                                        "Upper Siang",
                                        "Upper Subansiri",
                                        "Uttar Dinajpur (North Dinajpur)",
                                        "Uttara Kannada (Karwar)",
                                        "Uttarkashi",
                                        "Vadodara",
                                        "Vaishali",
                                        "Valsad",
                                        "Varanasi",
                                        "Vellore",
                                        "Vidisha",
                                        "Vijayapura (Bijapur)",
                                        "Vikarabad",
                                        "Viluppuram",
                                        "Virudhunagar",
                                        "Visakhapatnam",
                                        "Vizianagaram",
                                        "Wanaparthy",
                                        "Warangal (Rural)",
                                        "Warangal (Urban)",
                                        "Wardha",
                                        "Washim",
                                        "Wayanad",
                                        "West Champaran",
                                        "West Delhi",
                                        "West Garo Hills",
                                        "West Godavari",
                                        "West Jaintia Hills",
                                        "West Kameng",
                                        "West Karbi Anglong",
                                        "West Khasi Hills",
                                        "West Siang",
                                        "West Sikkim",
                                        "West Singhbhum",
                                        "West Tripura",
                                        "Wokha",
                                        "YSR District, Kadapa (Cuddapah)",
                                        "Yadadri Bhuvanagiri",
                                        "Yadgir",
                                        "Yamunanagar",
                                        "Yanam",
                                        "Yavatmal",
                                        "Zunheboto",
                                    ]}
                                    options={{
                                        placeholder: "Select your city/district...",
                                    }}
                                    />
                                    </div>
                                     <p
                                  className=" mt-2 warn text-danger align-items-center"
                                  id="nullagentdesc"
                                >
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Icon_feather-alert-circle.svg"
                                    }
                                    width="12px"
                                    className=" mr-1"
                                  />{" "}
                                  Please select City / District.
                                </p>

                                <p className="m-0 mt-4 mb-1">
                                Select Service <span>*</span>:
                                </p>
                                    <div id="slsec">
                                <Select2
                                    id="serviceselect"
                                    onChange={this.selectservicehandle}
                                    value={this.state.serviceselectvalue}
                                    data={this.state.serviceName}
                                    options={{
                                    placeholder: "Select service...",
                                    }}
                                />


                                
                                </div>
                                <p
                                  className=" mt-2 warn text-danger align-items-center"
                                  id="selectservice"
                                >
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Icon_feather-alert-circle.svg"
                                    }
                                    width="12px"
                                    className=" mr-1"
                                  />{" "}
                                  Please select service.
                                </p>
                               
                               
                                {
                                      this.state.serviceselectvalue == "Other" ? 

                                      <>
                                       <p className="m-0 mt-4 mb-1">
                                      Enter Service Name <span>*</span>:
                                      </p>
                                          
                                      <input placeholder="Enter service name" class="query-desc capatalizeMe" type="text" value={this.state.otherServicename} maxLength="100" onChange={(e) => {this.handleOtherServiceChange(e)}} />
                                      <p
                                        className=" mt-2 warn text-danger align-items-center"
                                        id="otherserv"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Icon_feather-alert-circle.svg"
                                          }
                                          width="12px"
                                          className=" mr-1"
                                        />{" "}
                                        Please select service.
                                      </p>
                                      </> 
                                      : <></>
                                    }
                               

                                <br />
                                <img
                                  className=" p-2 pl-3 pr-3 sendOtpLoader"
                                  src="/img/loader.gif"
                                  width="80px"
                                  alt=""
                                />
                                <button
                                  className="btn submit-btn text-white mb-3 mt-4"
                                  onClick={this.handleagentsubmit}
                                  id="agentquerybtn"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-5">
                              <p className="forinstantsupportline forinstantsupportline1">
                                For Instant support{" "}
                                <img
                                  alt="loading..."
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/img/lightning.png"
                                  }
                                  className="supportlighticon"
                                />{" "}
                                please call over <span>+91 77728-77729</span>{" "}
                                Monday to Friday ( 11.00 AM - 6.00 PM)
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div id="tab2" className="tab-content">
                        <div className="row justify-content-end mr-1">
                          <div className="dropdown ml-md-0 ml-lg-2 ml-2 px-0">
                            <select
                              className="agent__support__table__selectcateselect"
                              value={this.state.entry}
                              onChange={(e) => {
                                this.setState({
                                  entry: e.target.value,
                                  pageCount: 0,
                                });
                                this.handlePageClick({ selected: 0 });
                              }}
                            >
                              <option
                                value="10"
                                className="selectcateselectoption2"
                              >
                                10
                              </option>
                              <option
                                value="20"
                                className="selectcateselectoption2"
                              >
                                20
                              </option>
                              <option
                                value="50"
                                className="selectcateselectoption2"
                              >
                                50
                              </option>
                              <option
                                value="100"
                                className="selectcateselectoption2"
                              >
                                100
                              </option>
                            </select>
                          </div>
                          
                        </div>
                     
                          <div className="table-responsive my-table ticket-table">
                            <table className="table mr-3" id="AQTable">
                              <thead className="my-thead ">
                                <tr className="AQTable">
                                  <th className="my-th sno" data-type="number">
                                    Sno
                                    <img
                                      className="arrowImg rot0"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/downArrow.png"
                                      }
                                      height="15px"
                                      alt=""
                                    />
                                  </th>
                                  <th className="my-th detailsusers">
                                    Agent ID
                                    <img
                                      className="arrowImg rot0"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/downArrow.png"
                                      }
                                      height="15px"
                                      alt=""
                                    />
                                  </th>
                                  <th className="my-th servicessupport">
                                    Customer Name
                                    <img
                                      className="arrowImg rot0"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/downArrow.png"
                                      }
                                      height="15px"
                                      alt=""
                                    />
                                  </th>
                                  <th className="my-th servicessupport">
                                  Customer Email
                                    <img
                                      className="arrowImg rot0"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/downArrow.png"
                                      }
                                      height="15px"
                                      alt=""
                                    />
                                  </th>
                                  <th className="my-th servicessupport">
                                  Customer Number
                                    <img
                                      className="arrowImg rot0"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/downArrow.png"
                                      }
                                      height="15px"
                                      alt=""
                                    />
                                  </th>

                                  <th className="my-th descsupport">
                                    State
                                    <img
                                      className="arrowImg rot0"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/downArrow.png"
                                      }
                                      height="15px"
                                      alt=""
                                    />
                                  </th>
                                  <th className="my-th detailsusers">
                                    City{" "}
                                    <img
                                      className="arrowImg rot0"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/downArrow.png"
                                      }
                                      height="15px"
                                      alt=""
                                    />
                                  </th>
                                  <th className="my-th detailsusers">
                                    Status{" "}
                                    <img
                                      className="arrowImg rot0"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/downArrow.png"
                                      }
                                      height="15px"
                                      alt=""
                                    />
                                  </th>
                                  <th className="my-th detailsusers">
                                    Referral Id{" "}
                                    <img
                                      className="arrowImg rot0"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/downArrow.png"
                                      }
                                      height="15px"
                                      alt=""
                                    />
                                  </th>
                                  <th className="my-th servicessupport">
                                  Service Name{" "}
                                    <img
                                      className="arrowImg rot0"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/downArrow.png"
                                      }
                                      height="15px"
                                      alt=""
                                    />
                                  </th>
                                  
                                  {/* <th className="my-th status">Status</th>
                                <th className="my-th details">Details</th> */}
                                </tr>
                              </thead>

                              <div
                                className="loader-abs warn"
                                id="ticketagent-loader"
                              >
                                <img src="/img/loader.gif" />
                              </div>

                              <div
                                className="empty-abs warn"
                                id="ticketagent-empty"
                              >
                                <img
                                  className="nullImg"
                                  src="/img/quiteempty.svg"
                                />
                              </div>

                              <tbody className="my-tbody my-tbodysupport">
                                {this.renderlatestdata()}
                              </tbody>
                            </table>
                          </div>
                        
                        <div className="mt-3 w-100">
                          <ReactPaginate
                            previousLabel={
                              <img
                                src={
                                  process.env.PUBLIC_URL + "/img/Path_21772.svg"
                                }
                                width="15px"
                              />
                            }
                            nextLabel={
                              <img
                                src={
                                  process.env.PUBLIC_URL + "/img/Path_21773.svg"
                                }
                                width="15px"
                              />
                            }
                            pageCount={this.state.pageCount}
                            onPageChange={this.handlePageClick}
                            forcePage={this.state.currentPage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"pagination__link"}
                            nextLinkClassName={"pagination__link"}
                            disabledClassName={"pagination__link--disabled"}
                            activeClassName={"pagination__link----active"}
                          />
                        </div>
                      </div>

                     
                    </div>
                  </div>
                </div>
              </div>
             
              <Footer />

            </div>
          </div>
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default Referrallead;

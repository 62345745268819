import React from "react";
import { BrowserRouter, Route, Router, Switch } from "react-router-dom";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import Agentregistration from "./components/Agentregistration";
import Checkout__AgentRegistration from "./components/Checkout__AgentRegistration";
import Paymentsuccessfull__agent from "./components/Paymentsuccessfull__agent";
import Paymentfailed____agent from "./components/Paymentfailed____agent";
import Error from './components/Error'
import Myusers from './components/Myusers'
import Agentsupport from "./components/AgentSupport";
import Usersupport from "./components/Usersupport";
import CommHistory from "./components/CommHistory";
import Dashboard from "./components/Dashboard";
import Useroption from "./components/Useroption";
import AgentPanelServices from "./components/AgentPanelServices";
import AgentPanel_Commission from "./components/Commission";
import SetPassword from "./components/SetPassword";
import VerifyYourself from "./components/VerifyYourself";
import MyServices from "./components/MyServices";
import Activegstr from "./components/Activegstr";
import ActiveTr from "./components/ActiveTr";
import Completegstr from "./components/Completegstr";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import Activegsrtf from "./components/Activegsrtf";
import Activeitrf from "./components/Activeitrf";
import Completegsrtf from "./components/Completegsrtf";
import CompleteTr from "./components/CompleteTr";
import Completeitrf from "./components/Completitrf";
import Addservice from "./components/Addservice";
import Addservicegstrf from "./components/Addservicegstrf";
import Addserviceitrf from "./components/Addserviceitrf";
// import Addserviceother from "./components/Addserviceother";


import PaymentInvoice from "./components/PaymentInvoice";
import DocumentsRecord from "./components/DocumentsRecord";
import Addservicegstr from "./components/Addservicegstr";
import Addservicetr from "./components/Addservicetr";
import Checkpw from "./components/Checkpw";


import Addservicenewuserrepl from "./components/Addservicenewuserrepl";
// import Addservicenewuserother from "./components/Addservicenewuserother";
import Addservicenewusergstrf from "./components/Addservicenewusergstrf";
import Addservicenewusergstr from "./components/Addservicenewusergstr";
import Addservicenewuseritrf from "./components/Addservicenewuseritrf";
import Addservicenewusertr from "./components/Addservicenewusertr";
import Checkoutnew from "./components/Checkoutnew"
import Checkout from "./components/Checkout"
import Documentrecorditrf from "./components/Documentrecorditrf";
import Documentrecordgstr from "./components/Documentrecordgstr";
import Documentrecordgstrf from "./components/Documentrecordgstrf";
import Documentrecordtr from "./components/Documentrecordtr";
import Paymentsuccessfull__user from "./components/Paymentsuccessfull__user";
import Paymentfailed__user from "./components/Paymentfailed__user";
import Paymentsuccessfull__newuser from "./components/Paymentsuccessfull__newuser" ;
import Documentrecordagent from "./components/Documentrecordagent";
import Paymentinvoicesagent from "./components/Paymentinvoicesagent";
import Partnerpurchased from "./components/Partnerpurchased";
import Login from "./components/Login";
import Addagentservice from "./components/Addagentservice";
import Paymentfailed__newuser from "./components/Paymentfailed__newuser";
import ActiveOA from "./components/ActiveOa";
import CompleteOA from "./components/CompleteOA";
import DocumentrecordOA from "./components/DocumentrecordOA";
import Addserviceoa from "./components/Addserviceoa";
import Addservicerepl from "./components/Addservicerepl";
import Addservicepersonalloan from "./components/Addservicepersonalloan";
import Addservicebusinessloan from "./components/Addservicebusinessloan";
import Addservicehomeloan from "./components/Addservicehomeloan";
import AddserviceLoanAgainstProperty from "./components/AddserviceLoanAgainstProperty";
import AddserviceCarLoan from "./components/AddserviceCarLoan";
import Addservicegoldloan from "./components/Addservicegoldloan";



import Addservicenewuserhomeloan from "./components/Addservicenewuserhomeloan";
import Addservicenewusepersonalloan from "./components/Addservicenewusepersonalloan";
import AddservicenewuseLoanAgainstProperty from "./components/AddservicenewuseLoanAgainstProperty";
import Addservicenewusegoldloan from "./components/Addservicenewusegoldloan";
import AddservicenewuseCarLoan from "./components/AddservicenewuseCarLoan";
import Addservicenewusebusinessloan from "./components/Addservicenewusebusinessloan";




import Addservicenewuseroa from "./components/Addservicenewuseroa"
import ActiveGumasta from "./components/ActiveGumasta";
import Addservicenewusergumasta from "./components/Addservicenewusergumasta";
import Addservicenewusertradelicence from "./components/Addservicenewusertradelicence";
import Addservicenewuserfoodlicence from "./components/Addservicenewuserfoodlicence";
import ActiveFoodlicence from "./components/Activefoodlicence";
import ActiveTradeLicence from "./components/ActiveTradeLicence";
import Completegumasta from "./components/Completegumasta";
import CompleteTradeLicence from "./components/CompleteTradeLicence";
import CompleteFoodLicence from "./components/CompleteFoodlicence";
import Completecustom from "./components/Completecustom";
import Documentrecordtradelicence from "./components/Documentrecordtradelicence";
import Documentrecordfoodlicence from "./components/Documentrecordfoodlicence";
import Addservicegumasta from "./components/Addservicegumasta";
import Addservicetradelicence from "./components/Addservicetradelicence";
import Addservicefoodlicence from "./components/Addservicefoodlicence";
import Documentrecordgumasta from "./components/Documentrecordgumasta";
import Whitebox from "./newservicescomponents/UiComponents/Whitebox"
import Activecustom from "./components/Activecustom";
import Addservicecustom from "./components/Addservicecustom";
import Addservicenewusercustom from "./components/Addservicenewusercustom";
import Profile from "./components/Profile";
import Materials from "./components/Materials";
import Overview from "./components/Overview";
import Documentrecordcustom from "./components/Documentrecordcustom";
import Paymentsuccessfull__Loan, { Paymentsuccessfull__newuserLoan } from "./components/Paymentsuccessfull__newuserLoan";
import InduslandBankCreditCard from "./components/AddserviceInduslandBankCreditCard";
import StashfinCreditcard from "./components/AddserviceStashfinCreditCard";
import SBICreditCard from "./components/AddserviceSBICreditCard";
import AUCreditCard from "./components/AddserviceAUCreditCard"
import AddservicenewuserInduslandBankCreditCard from "./components/AddservicenewuserInduslandCred";
import AddservicenewuseSBICreditcard from "./components/AddservicenewuseSBICreditcard";
import AddservicenewuseAUCreditcard from "./components/AddservicenewuseAUCreditcard";
import AddservicenewuserStashfinCreditCard from "./components/AddservicenewuseStashfinCreditcard";
import JcpayLegalmerge from "./components/JcpayLegalmerge";
import Cart from "./components/Cart.js"
import IncompleteAgentregistration from "./components/IncompleteAgentregistration";
import Addservicenewuserroccomp from "./components/Addservicenewuserroccomp";
import Addservicenewuserrocllp from "./components/Addservicenewuserrocllp";
import Addservicenewusercma from "./components/Addservicenewusercma";
import Addserviceroccomp from "./components/Addserviceroccomp";
import Addservicerocllp from "./components/Addservicerocllp";
import Addservicecma from "./components/Addservicecma";
import Addservicenewusertds from "./components/Addservicenewusertds";
import Addservicetds from "./components/Addservicetds";
import Addservicesta from "./components/Addservicesta";
import Addservicenwc from "./components/Addservicenwc";
import Addserviceita from "./components/Addserviceita";
import Addservicenewuserita from "./components/Addservicenewuserita";
import Addservicenewusersta from "./components/Addservicenewusersta";
import Addservicenewusernwc from "./components/Addservicenewusernwc";
import AddservicePRP from "./components/AddservicePRP.js";
import AddservicenewuserPRP from "./components/AddservicenewuserPRP.js";
import Referrallead from "./components/Referrallead.js";
import Addservicenewuserpfrf from "./components/Addservicenewuserpfrf.js";
import Addservicepfrf from "./components/Addservicepfrf.js";
import Addservicenewuseresicrf from "./components/Addservicenewuseresicrf.js";
import Addserviceesicrf from "./components/Addserviceesicrf.js";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/jcpayid" component={JcpayLegalmerge} />
          <Route exact path="/register" component={Agentregistration} />
          <Route exact path="/register/incompletedata/" component={IncompleteAgentregistration} />
          <Route exact path="/checkout" component={Checkout__AgentRegistration} />
          <Route exact path="/paymentsuccessfull" component={Paymentsuccessfull__agent} />
          <Route exact path="/paymentfailed" component={Paymentfailed____agent} />
          <Route exact path="/myuser" component={Myusers} />
          <Route exact path="/support" component={Agentsupport} />
          <Route exact path="/agentservices/usersupport" component={Usersupport} />
          <Route exact path="/commissionhistory" component={CommHistory} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/overview" component={Overview} />
          <Route exact path="/myuser/:id2" component={Useroption} />
          <Route exact path="/agentservices" component={AgentPanelServices} />
          <Route exact path="/commission" component={AgentPanel_Commission} />
          <Route exact path="/documentrecords" component={Documentrecordagent} />
          <Route exact path="/paymentinvoices" component={Paymentinvoicesagent} />
          <Route exact path="/Partnerpurchased" component={Partnerpurchased} />
          <Route exact path="/cart" component={Cart} />
          <Route exact path="/referral-lead" component={Referrallead} />
          <Route
            exact
            path="/loginviamail/gettoken/:id"
            component={SetPassword}
          />
          <Route exact path="/verifycontactno" component={VerifyYourself} />
          <Route exact path="/myuser/:id2/userservices" component={MyServices} />
          <Route
            exact
            path="/myuser/:id2/userservices/active/gstr/:id"
            component={Activegstr}
          />
          <Route exact path="/myuser/:id2/userservices/active/tr/:id" component={ActiveTr} />
          <Route
            exact
            path="/myuser/:id2/userservices/active/itrf/:id"
            component={Activeitrf}
          />
          <Route
            exact
            path="/myuser/:id2/userservices/active/gstrf/:id"
            component={Activegsrtf}
          />
          <Route
            exact
            path="/myuser/:id2/userservices/active/oa/:id"
            component={ActiveOA}
          />
          <Route
            exact
            path="/myuser/:id2/userservices/active/gumasta/:id"
            component={ActiveGumasta}
          />
          <Route
            exact
            path="/myuser/:id2/userservices/active/tl/:id"
            component={ActiveTradeLicence}
          />
           <Route
          exact
          path="/myuser/:id2/userservices/active/fl/:id"
          component={ActiveFoodlicence}
        />
      
          <Route
            exact
            path="/myuser/:id2/userservices/completed/gstrf/:id"
            component={Completegsrtf}
          />
          <Route
            exact
            path="/myuser/:id2/userservices/completed/tr/:id"
            component={CompleteTr}
          />
          <Route
            exact
            path="/myuser/:id2/userservices/completed/itrf/:id"
            component={Completeitrf}
          />
          <Route
            exact
            path="/myuser/:id2/userservices/completed/gstr/:id"
            component={Completegstr}
          />
          <Route
            exact
            path="/myuser/:id2/userservices/completed/gumasta/:id"
            component={Completegumasta}
          />
          <Route
            exact
            path="/myuser/:id2/userservices/completed/tl/:id"
            component={CompleteTradeLicence}
          />
          <Route
            exact
            path="/myuser/:id2/userservices/completed/fl/:id"
            component={CompleteFoodLicence}
          />

          <Route
            exact
            path="/myuser/:id2/userservices/completed/oa/:id"
            component={CompleteOA}
          />
          <Route
            exact
            path="/documentrecords/:id2/documentrecords/itrf/:id"
            component={Documentrecorditrf}
          />
          <Route
            exact
            path="/documentrecords/:id2/documentrecords/gstr/:id"
            component={Documentrecordgstr}
          />
          <Route
            exact
            path="/documentrecords/:id2/documentrecords/tr/:id"
            component={Documentrecordtr}
          />
          <Route
            exact
            path="/documentrecords/:id2/documentrecords/gstrf/:id"
            component={Documentrecordgstrf}
          />
          <Route
            exact
            path="/documentrecords/:id2/documentrecords/oa/:id"
            component={DocumentrecordOA}
          />
          <Route
            exact
            path="/documentrecords/:id2/documentrecords/gumasta/:id"
            component={Documentrecordgumasta}
          />
          <Route
            exact
            path="/documentrecords/:id2/documentrecords/tl/:id"
            component={Documentrecordtradelicence}
          />
          <Route
            exact
            path="/documentrecords/:id2/documentrecords/fl/:id"
            component={Documentrecordfoodlicence}
          />
          <Route
            exact
            path="/documentrecords/:id2/documentrecords/:id3/:id"
            component={Documentrecordcustom}
          />
          <Route exact path="/paymentinvoices/:id2/paymentinvoice" component={PaymentInvoice} />
          <Route exact path="/documentrecords/:id2/documentrecords" component={DocumentsRecord} />
          <Route exact path="/addservice/:id2/addservice" component={Addservice} />
          <Route exact path="/addservice/:id2/addservice/gstrf" component={Addservicegstrf} />
          <Route exact path="/addservice/:id2/addservice/itrf" component={Addserviceitrf} />
          <Route exact path="/addservice/:id2/addservice/gstr" component={Addservicegstr} />
          <Route exact path="/addservice/:id2/addservice/tr" component={Addservicetr} />
          <Route exact path="/addservice/:id2/addservice/oa" component={Addserviceoa} />
          <Route exact path="/addservice/:id2/addservice/gumasta" component={Addservicegumasta} />
          <Route exact path="/addservice/:id2/addservice/tl" component={Addservicetradelicence} />
          <Route exact path="/addservice/:id2/addservice/fl" component={Addservicefoodlicence} />
          <Route exact path="/addservice/:id2/addservice/repl" component={Addservicerepl} />
          <Route exact path="/addservice/:id2/addservice/personal-loan" component={Addservicepersonalloan} />
          <Route exact path="/addservice/:id2/addservice/business-loan" component={Addservicebusinessloan} />
          <Route exact path="/addservice/:id2/addservice/home-loan" component={Addservicehomeloan} />
          <Route exact path="/addservice/home-loan" component={Addservicenewuserhomeloan} />
          <Route exact path="/addservice/business-loan" component={Addservicenewusebusinessloan} />
          <Route exact path="/addservice/personal-loan" component={Addservicenewusepersonalloan} />
          <Route exact path="/addservice/gold-loan" component={Addservicenewusegoldloan} />
          <Route exact path="/addservice/car-loan" component={AddservicenewuseCarLoan} />
          <Route exact path="/addservice/loan-against-property" component={AddservicenewuseLoanAgainstProperty} />
          <Route exact path="/addservice/:id2/addservice/loan-against-property" component={AddserviceLoanAgainstProperty} />
          <Route exact path="/addservice/:id2/addservice/car-loan" component={AddserviceCarLoan} />
          <Route exact path="/addservice/:id2/addservice/gold-loan" component={Addservicegoldloan} />
          {/* <Route exact path="/addservice/:id2/addservice/other" component={Addserviceother} /> */}
          <Route exact path="/addservice/:id2/addservice/checkout" component={Checkout} />
          <Route exact path="/addservice/:id2/addservice/indusind-bank-credit-card" component={InduslandBankCreditCard} />
          <Route exact path="/addservice/:id2/addservice/stashfin-bank-credit-card" component={StashfinCreditcard} />
          <Route exact path="/addservice/:id2/addservice/state-bank-of-india-credit-card" component={SBICreditCard} />
          <Route exact path="/addservice/:id2/addservice/au-bank-credit-card" component={AUCreditCard} />
          <Route exact path="/addservice/:id2/addservice/pr" component={AddservicePRP} />
          <Route exact path="/addservice/indusind-bank-credit-card" component={AddservicenewuserInduslandBankCreditCard}/>
          <Route exact path="/addservice/sbi-bank-credit-card" component={AddservicenewuseSBICreditcard}/>
          <Route exact path="/addservice/au-bank-credit-card" component={AddservicenewuseAUCreditcard}/>
          <Route exact path="/addservice/stashfin-bank-credit-card" component={AddservicenewuserStashfinCreditCard}/>
          <Route exact path="/addservice" component={Addagentservice}/>
          <Route exact path="/addservice/gstrf" component={Addservicenewusergstrf} />
          <Route exact path="/addservice/itrf" component={Addservicenewuseritrf} />
          <Route exact path="/addservice/gstr" component={Addservicenewusergstr} />
          <Route exact path="/addservice/tr" component={Addservicenewusertr} />
          <Route exact path="/addservice/gumasta" component={Addservicenewusergumasta} />
          <Route exact path="/addservice/tl" component={Addservicenewusertradelicence} />
          <Route exact path="/addservice/fl" component={Addservicenewuserfoodlicence} />
          <Route exact path="/addservice/oa" component={Addservicenewuseroa} />
          <Route exact path="/addservice/repl" component={Addservicenewuserrepl} />
          <Route exact path="/addservice/:id2/addservice/roccomp" component={Addserviceroccomp} />
          <Route exact path="/addservice/:id2/addservice/rocllp" component={Addservicerocllp} />
          <Route exact path="/addservice/:id2/addservice/cma" component={Addservicecma} />
          <Route exact path="/addservice/:id2/addservice/tds" component={Addservicetds} />
          <Route exact path="/addservice/roccomp" component={Addservicenewuserroccomp} />
          <Route exact path="/addservice/rocllp" component={Addservicenewuserrocllp} />
          <Route exact path="/addservice/cma" component={Addservicenewusercma} />
          <Route exact path="/addservice/tds" component={Addservicenewusertds} />
          <Route exact path="/addservice/:id2/addservice/sta" component={Addservicesta} />
          <Route exact path="/addservice/:id2/addservice/nwc" component={Addservicenwc} />
          <Route exact path="/addservice/:id2/addservice/ita" component={Addserviceita} />
          <Route exact path="/addservice/ita" component={Addservicenewuserita} />
          <Route exact path="/addservice/nwc" component={Addservicenewusernwc} />
          <Route exact path="/addservice/sta" component={Addservicenewusersta} />
          <Route exact path="/addservice/pr" component={AddservicenewuserPRP} />
          <Route exact path="/addservice/pfrf" component={Addservicenewuserpfrf} />
          <Route exact path="/addservice/:id2/addservice/pfrf" component={Addservicepfrf} />
          <Route exact path="/addservice/esicrf" component={Addservicenewuseresicrf} />
          <Route exact path="/addservice/:id2/addservice/esicrf" component={Addserviceesicrf} />



          {/* <Route exact path="/addservice/other" component={Addservicenewuserother} /> */}
          <Route exact path="/addservice/checkout" component={Checkoutnew} />
          

          <Route path="/forgotpassword" component={Checkpw} />
          <Route path="/profile" component={Profile} />
          <Route path="/materials" component={Materials} />
          

          <Route
            exact
            path="/addservice/:id2/addservice/paymentsuccessfull"
            component={Paymentsuccessfull__user}
          />
          <Route
            exact
            path="/addservice/:id2/addservice/paymentsuccessfull2"
            component={Paymentsuccessfull__Loan}
          />

          <Route
            exact
            path="/addservice/paymentsuccessfull"
            component={Paymentsuccessfull__newuser}
          />
          <Route
            exact
            path="/addservice/paymentsuccessfull2"
            component={Paymentsuccessfull__newuserLoan}
          />
          <Route
            exact
            path="/addservice/paymentfailed"
            component={Paymentfailed__newuser}
          />
          <Route
            exact
            path="/addservice/:id2/addservice/paymentfailed"
            component={Paymentfailed__user}
          />
          <Route exact path="/addservice/:id3" component={Addservicenewusercustom} />
          <Route exact path="/addservice/:id2/addservice/:id3" component={Addservicecustom} />
          <Route
          exact
          path="/myuser/:id2/userservices/active/:id3/:id"
          component={Activecustom}
        />
           <Route
            exact
            path="/myuser/:id2/userservices/completed/:id3/:id"
            component={Completecustom}
          />
          <Route component={Error} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;

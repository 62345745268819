import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import { Redirect } from "react-router";

var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);
let Decuserid;
let Decagentid;
let Decjwttoken;
export class Paymentsuccessfull__user extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: "",
      orderid: "",
      serviceId: "",
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    $(document.body).css("background-color", "#2d2d2d");
    let mainuserid = localStorage.getItem("relleIsugad");
    Decuserid = encryptor.decrypt(mainuserid);
let mainagentid= localStorage.getItem("qazxswedcvfrtgb");
    Decagentid = encryptor.decrypt(mainagentid);
    let mainjwttoken = localStorage.getItem("toeljgtkewlna");
    Decjwttoken = encryptor.decrypt(mainjwttoken);

    if (this.props.location.state != undefined) {
      var str = this.props.location.state.servicerequestid;
      var mainorderid = this.props.location.state.orderid;
      var serviceshortname=this.props.location.state.serviceshortname;
      if(serviceshortname=="LOAN")
      { $(".DownloadInvoicehide").hide();}
      var n = str.indexOf("_");
      str = str.slice(0, n);
      this.setState({
        orderid: mainorderid,
        serviceId: str,
      });
      this.props.history.replace(`/addservice/${Decuserid}/addservice/paymentsuccessfull`, null);
    } else {
      this.props.history.push({
        pathname: `/dashboard`,
      });
    }
    var d = new Date();
    var curr_date = d.getDate();
    var curr_month = d.getMonth(); //Months are zero based
    var curr_year = d.getFullYear();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var date = new Date();
    var hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
    var am_pm = date.getHours() >= 12 ? "PM" : "AM";
    hours = hours < 10 ? "0" + hours : hours;
    var minutes =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var seconds =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    var time = hours + ":" + minutes + ":" + seconds + " " + am_pm;

    var maindate =
      curr_date + " " + monthNames[curr_month] + " " + curr_year + ", " + time;
    this.setState({
      date: maindate,
    });
  }
  logout = () => {
    localStorage.removeItem("gglltakoinoeenl");
    localStorage.removeItem("toeljgtkewlna");
    localStorage.removeItem("qazxswedcvfrtgb");
    localStorage.removeItem("lshhihfnkff");
    this.props.history.push({
      pathname: `/`,
    });
  };
  backbutton = () => {
    this.props.history.push({
      pathname: "/dashboard",
    });
  };
  render() {
    if (
      localStorage.getItem("gglltakoinoeenl") ==
      process.env.REACT_APP_LOGIN_KEY &&
      localStorage.getItem("toeljgtkewlna") != null &&
      localStorage.getItem("qazxswedcvfrtgb") != null
    ) {
      return (
        <div className="cont">
          <Helmet>
            <link rel="stylesheet" href={process.env.PUBLIC_URL + "/css/select2.css"} />
          </Helmet>

          <Header />

          <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
            className="sidebaropenicon"
          />
          <img
            onClick={this.backbutton}
            src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
            className="sidebarbackicon"
          />
          <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"}
            className="sidebarexpandicon"
          />

          <div className="overlay1"></div>
          <div className="sidebar sidebarshift">
            <div className="side_div_2 sidebar_txt active">
              <Link to="/dashboard">Overview</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <Link to="/myservices">My Services</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <a href="#">Payments Invoices</a>
            </div>
            <div className="side_div_1 sidebar_txt">
              <a href="#">Documents Record</a>
            </div>
            <div className="side_div_1 sidebar_txt">
              <a href="#">Add service</a>
            </div>
            <div></div>
          </div>
          <div className="arrow-left2 visibilityhidden"></div>
          <div className="white_div expandwhite_div">
            <div className="white_box">
              <div className="white_boxscrolldiv">
                <div className="container-fluid">
                  <center>
                    <p className="success-heading">
                      Payment successful
                      <img
                        className="tick-icon"
                        src={process.env.PUBLIC_URL + "/img/blue-tick.svg"}
                        alt=""
                      />{" "}
                    </p>
                    <p className="thankyoutext">Thank you for choosing us!</p>
                    <p className="datetext">
                      {/* 24 Nov, 2020, 02:20 PM  */}
                      {this.state.date}
                    </p>
                  </center>
                  <div className="row">
                    <div className="col-md-6">
                      <center>
                        <p className="ServiceIDheading">
                          Service ID:-{" "}
                          <span className="ServiceIDheadingspan">
                            {this.state.serviceId}
                          </span>
                        </p>
                        <hr className="paymenthr" />
                        <div className="DownloadInvoicehide">
                        <p className="Transactionheading">
                          Transaction ID:-{" "}
                          <span className="ServiceIDheadingspan">
                            {this.state.orderid}
                          </span>
                        </p>
                        <br />
                        <br />

                        <a
                          target="_blank"
                          href={`https://docs.legal251.com/invoices/${this.state.serviceId}_${Decuserid}.pdf`}
                          className="DownloadInvoice"
                        >
                          Download Invoice
                        </a>
</div>
                        <br />
                        <br />
                        <br />

                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_15951.svg"}
                          alt=""
                          className="quoteimg img-fluid"
                        />
                      </center>
                    </div>
                    <div className="col-md-6">
                      <center>
                        <div className="custompadd">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/img/payment_sucessful.svg"
                            }
                            className="paymentsucessimg img-fluid"
                            alt=""
                          />
                        </div>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default Paymentsuccessfull__user;

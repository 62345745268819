import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { Link } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import { Redirect } from "react-router";
import jsonData from "../State__and__dist.json";
import AllPricing from "../data.json";
import json2 from "../State_shortcode.json";


let Decagentid;
let Decjwttoken;
var key = process.env.REACT_APP_ENCRYPTOR_KEY;
let servicestate;
var myform;
var data= [];
var sessiondata;
var servicename;
let supportName;
let supportContact;
let supportEmail;
let pricingJson;
var encryptor = require("simple-encryptor")(key);

class Addserviceitrf extends Component {
  constructor(props) {
    super(props);

    this.state = {
      serviceselectvalue: "",
      mainplan: "",
      mainduration: "",
      mainpackage: "S",
      citydistrictselectvalue: "",
      servicefullname: "",
      serviceemail:"",
      servicemobileno:"",
      
supportName:"",
supportContact:"",
supportEmail:"",
    };
  }

  componentDidMount() {

    window.scrollTo(0, 0);
    myform = $('#multiform').hide(0);
    $(document.body).css("background-color", "#2d2d2d");
    let mainjwttoken = localStorage.getItem("toeljgtkewlna");
    let mainagentid = localStorage.getItem("qazxswedcvfrtgb");
    Decagentid = encryptor.decrypt(mainagentid);
    Decjwttoken = encryptor.decrypt(mainjwttoken);
    supportName = localStorage.getItem("supportName");
    supportContact = localStorage.getItem("supportContact");
    supportEmail = localStorage.getItem("supportEmail");
    
    this.setState({
      supportName:supportName,
      supportContact:supportContact,
      supportEmail:supportEmail,
    })

    
    sessiondata = JSON.parse(sessionStorage.getItem("data"))
  
    if (sessiondata == null || sessiondata == undefined  || sessiondata == "") {
      sessiondata=[]
      

    }
    // else  {
    //   sessiondata = JSON.parse(sessionStorage.getItem("data"))
    //  }

    if(sessiondata.length > 0){
     if(sessiondata[0].userId == ""){
    
      var semail = sessionStorage.getItem("newuseremail")
      var scontact = sessionStorage.getItem("newusercontact")
      if(scontact != ""  &&  semail != ""){
        
      
  
        this.setState({
          serviceemail:semail,
          servicemobileno:scontact,
        })
      }
     }else if(sessiondata[0].userId != ""){
      sessiondata=[]
      sessionStorage.removeItem('data');
      
     }
      
    }else{
     
      this.setState({
        serviceemail:"",
        servicemobileno:""
       })
    }


    fetch(process.env.REACT_APP_API_PRICING_JSON_URL)
    .then(response => response.json())
    .then(data => {
      // Store the JSON data in localStorage
      localStorage.setItem('lshhihfnkff', JSON.stringify(data));
      this.priceUpdate()
    })
    .catch(error => console.error('Error fetching data:', error));
  }

  priceUpdate = () => {
    pricingJson = JSON.parse(localStorage.getItem("lshhihfnkff"));
    var priceG = pricingJson["REPLG"].price
    var priceD = pricingJson["REPLD"].price
    this.setState({
        REPLG: Math.round(priceG * 0.18 + priceG),
        REPLD: Math.round(priceD * 0.18 + priceD)
    })
}


  addToCartfirstdropdowngetstartedclick = (e) => {

    const contactNoregex = /^[6-9]\d{9}$/;
    const emailregex = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    if (this.state.servicefullname.trim() == "") {

      $('.errorside').show()
      $('.errorsidespan').show().html(
        ' Please enter your name.');
      $('#name').css({
        'border-bottom-color': '#fe0c0c'
      });
      $('#name').focus();
    }
    else if (this.state.servicefullname.trim() == "" || this.state.servicefullname.length > 50) {
      $('.errorside').show()
      $('.errorsidespan').show().html(
        ' Please enter a valid name.');
      $('#name').css({
        'border-bottom-color': '#fe0c0c'
      });
      $('#name').focus();
    }
    else if (
      this.state.serviceemail.trim() == "") {
      $('.errorside').show()
      $('.errorsidespan').show().html(
        ' Please enter your email.');
      $('#name').css({
        'border-bottom-color': '#809aae'
      });
      $('#email').css({
        'border-bottom-color': '#fe0c0c'
      });
      $('#email').focus();
    }
    else if (
      !emailregex.test(this.state.serviceemail) ||
      this.state.serviceemail.length > 50
    ) {
      $('.errorside').show()
      $('.errorsidespan').show().html(
        ' Please enter valid email.');
      $('#email').css({
        'border-bottom-color': '#fe0c0c'
      });
      $('#email').focus();
    }

    else if (
      this.state.servicemobileno.trim() == "") {
      $('.errorside').show()
      $('.errorsidespan').show().html(
        ' Please enter your number.');
      $('#name').css({
        'border-bottom-color': '#809aae'
      });
      $('#email').css({
        'border-bottom-color': '#809aae'
      });
      $('#contact').css({
        'border-bottom-color': '#fe0c0c'
      });
      $('#contact').focus();
    }
    else if (
      this.state.servicemobileno.trim() == "" ||
      !contactNoregex.test(this.state.servicemobileno)) {
      $('.errorside').show()
      $('.errorsidespan').show().html(
        ' Please enter valid number.');
      $('#name').css({
        'border-bottom-color': '#809aae'
      });
      $('#email').css({
        'border-bottom-color': '#809aae'
      });
      $('#contact').css({
        'border-bottom-color': '#fe0c0c'
      });
      $('#contact').focus();
    }
    else if (this.state.citydistrictselectvalue.trim() == "") {
      $('.errorside').show()
      $('.errorsidespan').show().html(
        ' Please enter your city.');
      $('#name').css({
        'border-bottom-color': '#809aae'
      });
      $('#email').css({
        'border-bottom-color': '#809aae'
      });
      $('#contact').css({
        'border-bottom-color': '#809aae'
      });
      $('#city').css({
        'border-bottom-color': '#fe0c0c'
      });
      $('#city').focus();

    }else {
      
      $(e.target).closest('.formplacer').append(myform.slideUp());
      $(".hidebusscon1").show();
      $(".hidebusscon2").show();
  
      this.filterDistricts()
          this.upcard()
      
   
    }

    
}

upcard = () => {
  var stateshortcode = Object.keys(json2[0]).find(key => json2[0][key] === servicestate);
  var mainobject=pricingJson["REPL"+this.state.mainplan]
  var gstvalue=mainobject.price*0.18
  sessionStorage.setItem("newuseremail", (this.state.serviceemail))
      sessionStorage.setItem("newusercontact", (this.state.servicemobileno))
  data = {
      "userId": "",
      "serviceName": "REPL",
      "purchasename": this.state.servicefullname,
      "citydistrict": this.state.citydistrictselectvalue,
      "state": servicestate,
      "serviceType": "",
      "duration": "",
      "plan": this.state.mainplan,
      "turnover": "",
      "package": "",
      "price":mainobject.price,
      "gstvalue":gstvalue,
      "govtfees":mainobject.govtfees,
      "userId": "",
      "email":this.state.serviceemail,
      "contact": this.state.servicemobileno,
      "billingName": this.state.servicefullname,
      "billingCity": this.state.citydistrictselectvalue,
      "billingState": jsonData[0][this.state.citydistrictselectvalue],
      "servicefullname":"Legal Notice Reply",
      "stateShortCode": stateshortcode,
  }

  var sessiondata; 
  sessiondata = sessionStorage.getItem("data")
  if (sessiondata == null || sessiondata == undefined  || sessiondata == "") {
    sessiondata=[]
  }
  else  {
   sessiondata = JSON.parse(sessionStorage.getItem("data"))
  }
  
      if (sessiondata.length == 0 ) {
        var localvar = []
        localvar.push(data)
        sessionStorage.setItem("data", JSON.stringify(localvar))
        this.showNotification("Item Added to Cart")
      }
      else if (sessiondata.length > 0) {
        var arraylen = sessiondata.length;
        // for (let index = 0; index < arraylen; index++) {
        //   if (sessiondata[index].serviceName == "REPL") {
        //     sessiondata.splice(index, 1)
        //     break
        //   }
        // }
        sessiondata.push(data)
        sessionStorage.setItem("data", JSON.stringify(sessiondata))
        this.showNotification("Item Added to Cart")
      }
      this.setState({
          dataupdated: true,
        });


}


 

  firstdropdowngetstartedclick = (e) => {
    $(".getform").hide();
    $(".plangetstartedbtn").show();
    $(e.target).closest('.formplacer').append(myform.slideDown());
    $(".hidebusscon1").hide();
    $(".hidebusscon2").show();
    this.setState(
      {
        mainplan: "G",
        citydistrictselectvalue: "",
        servicefullname: "",
       
      }
    );
  };
  seconddropdowngetstartedclick = (e) => {
    $(".getform2").hide();
    $(".plangetstartedbtn").show();
    $(e.target).closest('.formplacer').append(myform.slideDown());
    $(".hidebusscon1").show();
    $(".hidebusscon2").hide();
    this.setState(
      {
        mainplan: "D",
        citydistrictselectvalue: "",
        servicefullname: "",
  
      }
    );
  };



  filterDistricts = () => {
    servicestate = jsonData[0][this.state.citydistrictselectvalue]
  };
  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };
  createPurchaseReqNormal = () => {
    const contactNoregex = /^[6-9]\d{9}$/;
    const emailregex = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    if (this.state.servicefullname.trim() == "") {

      $('.errorside').show()
      $('.errorsidespan').show().html(
        ' Please enter your name.');
      $('#name').css({
        'border-bottom-color': '#fe0c0c'
      });
      $('#name').focus();
    }
    else if (this.state.servicefullname.trim() == "" || this.state.servicefullname.length > 50) {
      $('.errorside').show()
      $('.errorsidespan').show().html(
        ' Please enter a valid name.');
      $('#name').css({
        'border-bottom-color': '#fe0c0c'
      });
      $('#name').focus();
    }
    else if (
      this.state.serviceemail.trim() == "") {
      $('.errorside').show()
      $('.errorsidespan').show().html(
        ' Please enter your email.');
      $('#name').css({
        'border-bottom-color': '#809aae'
      });
      $('#email').css({
        'border-bottom-color': '#fe0c0c'
      });
      $('#email').focus();
    }
    else if (
      !emailregex.test(this.state.serviceemail) ||
      this.state.serviceemail.length > 50
    ) {
      $('.errorside').show()
      $('.errorsidespan').show().html(
        ' Please enter valid email.');
      $('#email').css({
        'border-bottom-color': '#fe0c0c'
      });
      $('#email').focus();
    }

    else if (
      this.state.servicemobileno.trim() == "") {
      $('.errorside').show()
      $('.errorsidespan').show().html(
        ' Please enter your number.');
      $('#name').css({
        'border-bottom-color': '#809aae'
      });
      $('#email').css({
        'border-bottom-color': '#809aae'
      });
      $('#contact').css({
        'border-bottom-color': '#fe0c0c'
      });
      $('#contact').focus();
    }
    else if (
      this.state.servicemobileno.trim() == "" ||
      !contactNoregex.test(this.state.servicemobileno)) {
      $('.errorside').show()
      $('.errorsidespan').show().html(
        ' Please enter valid number.');
      $('#name').css({
        'border-bottom-color': '#809aae'
      });
      $('#email').css({
        'border-bottom-color': '#809aae'
      });
      $('#contact').css({
        'border-bottom-color': '#fe0c0c'
      });
      $('#contact').focus();
    }
    else if (this.state.citydistrictselectvalue.trim() == "") {
      $('.errorside').show()
      $('.errorsidespan').show().html(
        ' Please enter your city.');
      $('#name').css({
        'border-bottom-color': '#809aae'
      });
      $('#email').css({
        'border-bottom-color': '#809aae'
      });
      $('#contact').css({
        'border-bottom-color': '#809aae'
      });
      $('#city').css({
        'border-bottom-color': '#fe0c0c'
      });
      $('#city').focus();

    }
    else {
      this.filterDistricts();
      $(".planformsubmitbutton").hide();
      $("#addserviceloadermain").show();
      var mySentence = this.state.servicefullname.trim();
    mySentence=mySentence.replace(/\s+/g, " ");
    const words = mySentence.split(" ");
    
    for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    
  var mywords= words.join(" ");
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + 'p=createPurchaseNew',
        {
          agentId: Decagentid,
          contact: this.state.servicemobileno,
          email: this.state.serviceemail,
          serviceName: "REPL",
          purchasename: mywords,
          citydistrict: this.state.citydistrictselectvalue,
          state: servicestate,
          serviceType: "",
          duration: "",
          plan: this.state.mainplan,
          turnover: "",
          package: "",
          comeFrom:"AGP"
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD
          }
        }
      )
      .then((res) => {

        
        if (res.data.code == "200" && res.data.status == "success") {
          $(".planformsubmitbutton").show();
          $("#addserviceloadermain").hide();
          $(".planformsubmitbutton").attr("disabled", false);
          sessionStorage.setItem(
            "uegiqlsartdele",
            encryptor.encrypt(res.data.requestid)
          );
          sessionStorage.setItem(
            "aprcillgee",
            encryptor.encrypt(parseFloat(res.data.price))
          );
          sessionStorage.setItem("ssdfsdfwekrfewpfk", encryptor.encrypt(parseFloat(res.data.gstvalue)));
          sessionStorage.setItem("lslgerotnscmvheearaei", encryptor.encrypt("REPL"));
          sessionStorage.setItem("evlgsoaeetgfl", encryptor.encrypt(0));
          sessionStorage.setItem(
            "egalllpna",
            encryptor.encrypt(this.state.mainplan)
          );
          // sessionStorage.setItem("relleIsugad", encryptor.encrypt(res.data.userId));
          sessionStorage.setItem(
            "ielndmlgeaefelvsdrlucaa",
            encryptor.encrypt(mywords.trim()));
          sessionStorage.setItem(
            "ylcvciretaireditdgisdtacesl",
            encryptor.encrypt(this.state.citydistrictselectvalue)
          );
          sessionStorage.setItem(
            "srleleddgaeaemvcnia",
            encryptor.encrypt("Legal Notice Reply")
          );
          this.props.history.push({
            pathname: `/addservice/checkout`,
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          $(".planformsubmitbutton").show();
          $("#addserviceloadermain").hide();
          $(".planformsubmitbutton").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => { });
    }
  };
  logout = () => {
    localStorage.removeItem("gglltakoinoeenl");
    localStorage.removeItem("toeljgtkewlna");
    localStorage.removeItem("qazxswedcvfrtgb");
    localStorage.removeItem("lshhihfnkff");
    this.props.history.push({
      pathname: `/`,
    });
  };
  backbutton = () => {
    this.props.history.push({
      pathname: `/addservice`,
    });
  };
  citydistrictselecthandle = (event) => {
    this.setState({
      citydistrictselectvalue: event.target.value,
    });
    
  };
  handleservicefullnameChange = (e) => {
    this.setState({
      servicefullname: e.target.value,
    });
  };
  handleserviceserviceemailChange= (e) => {
    this.setState({
      serviceemail: e.target.value.toLowerCase(),
    });
  };
  handleserviceservicemobilenoChange= (e) => {
    this.setState({
      servicemobileno: e.target.value,
    });
  };
  handlesupport = () => {
    var x = document.getElementById("myDIV");
    if (x.style.display == "block") {
      x.style.display = "none";
    }else {
      x.style.display = "block";
    }
  }
  render() {
    if (
      localStorage.getItem("gglltakoinoeenl") == process.env.REACT_APP_LOGIN_KEY &&
      localStorage.getItem("toeljgtkewlna") != null &&
      localStorage.getItem("qazxswedcvfrtgb") != null
    ) {
      return (
        <div className="cont">
          <div id="notifContainer"></div>
          <Helmet>
            <link rel="stylesheet" href={process.env.PUBLIC_URL + "/css/select2new2.css"} />
          </Helmet>

          <Header />

          <img
            src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
            className="sidebaropenicon"
          />
          <img
            src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
            onClick={this.backbutton}
            className="sidebarbackicon"
          />
          <img
            src={process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"}
            className="sidebarexpandicon"
          />

          <div className="overlay1"></div>
          <div className="sidebar sidebarshift">
            <div className="side_div_2 sidebar_txt active">
              <Link to="/dashboard">Overview</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <Link to="/myservices">My Services</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <a href="#">Payments Invoices</a>
            </div>
            <div className="side_div_1 sidebar_txt">
              <a href="#">Documents Record</a>
            </div>
            <div className="side_div_1 sidebar_txt">
              <a href="#">Add service</a>
            </div>
            <div></div>
          </div>
          <div id="multiform" className="multiformdiv">
            <p className="planformtext1">Name:</p>
            <input type="text"  pattern="[A-Za-z ]{3,150}" id="name" className="appendnameinput planforminput"  value={this.state.servicefullname}
                        maxLength = "100"
                        onChange={this.handleservicefullnameChange} required />
            <p className="planform2">Email:</p>
            <input type="email" maxLength="50" id="email" className="appendemailinput planforminput" 
            value={this.state.serviceemail}
            onChange={this.handleserviceserviceemailChange} required />
            <p className="planform2">Contact no:</p>
            <input type="tel" pattern="[6-9]{1}[0-9]{9}" maxLength="10" id="contact" className="appendcontactno planforminput"   value={this.state.servicemobileno}
                                onChange={this.handleserviceservicemobilenoChange}/>
            <p className="planform2">Select Location:</p>
            <Select2 id="city"
            onChange={this.citydistrictselecthandle}
              value={this.state.citydistrictselectvalue}
              data={["Adilabad",
              "Agar Malwa",
              "Agra",
              "Ahmedabad",
              "Ahmednagar",
              "Aizawl",
              "Ajmer",
              "Akola",
              "Alappuzha",
              "Aligarh",
              "Alipurduar",
              "Alirajpur",
              "Allahabad",
              "Almora",
              "Alwar",
              "Ambala",
              "Ambedkar Nagar",
              "Amethi (Chatrapati Sahuji Mahraj Nagar)",
              "Amravati",
              "Amreli",
              "Amritsar",
              "Amroha",
              "Anand",
              "Anantapur",
              "Anantnag",
              "Angul",
              "Anjaw",
              "Anuppur",
              "Araria",
              "Aravalli",
              "Ariyalur",
              "Arwal",
              "Ashoknagar",
              "Auraiya",
              "Aurangabad (BH)",
              "Aurangabad (MH)",
              "Azamgarh",
              "Bagalkot",
              "Bageshwar",
              "Baghpat",
              "Bahraich",
              "Baksa",
              "Balaghat",
              "Balangir",
              "Balasore",
              "Ballari (Bellary)",
              "Ballia",
              "Balod",
              "Baloda Bazar",
              "Balrampur (CG)",
              "Balrampur (UP)",
              "Banaskantha (Palanpur)",
              "Banda",
              "Bandipore",
              "Banka",
              "Bankura",
              "Banswara",
              "Barabanki",
              "Baramulla",
              "Baran",
              "Bareilly",
              "Bargarh",
              "Barmer",
              "Barnala",
              "Barpeta",
              "Barwani",
              "Bastar",
              "Basti",
              "Bathinda",
              "Beed",
              "Begusarai",
              "Belagavi (Belgaum)",
              "Bemetara",
              "Bengaluru (Bangalore) Rural",
              "Bengaluru (Bangalore) Urban",
              "Betul",
              "Bhadohi",
              "Bhadradri Kothagudem",
              "Bhadrak",
              "Bhagalpur",
              "Bhandara",
              "Bharatpur",
              "Bharuch",
              "Bhavnagar",
              "Bhilwara",
              "Bhind",
              "Bhiwani",
              "Bhojpur",
              "Bhopal",
              "Bidar",
              "Bijapur",
              "Bijnor",
              "Bikaner",
              "Bilaspur (CG)",
              "Bilaspur (HP)",
              "Birbhum",
              "Bishnupur",
              "Biswanath",
              "Bokaro",
              "Bongaigaon",
              "Botad",
              "Boudh",
              "Budaun",
              "Budgam",
              "Bulandshahr",
              "Buldhana",
              "Bundi",
              "Burhanpur",
              "Buxar",
              "Cachar",
              "Central Delhi",
              "Chamarajanagar",
              "Chamba",
              "Chamoli",
              "Champawat",
              "Champhai",
              "Chandauli",
              "Chandel",
              "Chandigarh",
              "Chandrapur",
              "Changlang",
              "Charaideo",
              "Charkhi Dadri",
              "Chatra",
              "Chengalpattu",
              "Chennai",
              "Chhatarpur",
              "Chhindwara",
              "Chhota Udepur",
              "Chikballapur",
              "Chikkamagaluru (Chikmagalur)",
              "Chirang",
              "Chitradurga",
              "Chitrakoot",
              "Chittoor",
              "Chittorgarh",
              "Churachandpur",
              "Churu",
              "Coimbatore",
              "Cooch Behar",
              "Cuddalore",
              "Cuttack",
              "Dadra & Nagar Haveli",
              "Dahod",
              "Dakshin Dinajpur (South Dinajpur)",
              "Dakshina Kannada",
              "Daman",
              "Damoh",
              "Dangs (Ahwa)",
              "Dantewada (South Bastar)",
              "Darbhanga",
              "Darjeeling",
              "Darrang",
              "Datia",
              "Dausa",
              "Davangere",
              "Dehradun",
              "Deogarh",
              "Deoghar",
              "Deoria",
              "Devbhoomi Dwarka",
              "Dewas",
              "Dhalai",
              "Dhamtari",
              "Dhanbad",
              "Dhar",
              "Dharmapuri",
              "Dharwad",
              "Dhemaji",
              "Dhenkanal",
              "Dholpur",
              "Dhubri",
              "Dhule",
              "Dibang Valley",
              "Dibrugarh",
              "Dima Hasao (North Cachar Hills)",
              "Dimapur",
              "Dindigul",
              "Dindori",
              "Diu",
              "Doda",
              "Dumka",
              "Dungarpur",
              "Durg",
              "East Champaran (Motihari)",
              "East Delhi",
              "East Garo Hills",
              "East Godavari",
              "East Jaintia Hills",
              "East Kameng",
              "East Khasi Hills",
              "East Siang",
              "East Sikkim",
              "East Singhbhum",
              "Ernakulam",
              "Erode",
              "Etah",
              "Etawah",
              "Faizabad",
              "Faridabad",
              "Faridkot",
              "Farrukhabad",
              "Fatehabad",
              "Fatehgarh Sahib",
              "Fatehpur",
              "Fazilka",
              "Ferozepur",
              "Firozabad",
              "Gadag",
              "Gadchiroli",
              "Gajapati",
              "Ganderbal",
              "Gandhinagar",
              "Ganjam",
              "Garhwa",
              "Gariyaband",
              "Gautam Buddha Nagar",
              "Gaya",
              "Ghaziabad",
              "Ghazipur",
              "Gir Somnath",
              "Giridih",
              "Goalpara",
              "Godda",
              "Golaghat",
              "Gomati",
              "Gonda",
              "Gondia",
              "Gopalganj",
              "Gorakhpur",
              "Gumla",
              "Guna",
              "Guntur",
              "Gurdaspur",
              "Gurugram (Gurgaon)",
              "Gwalior",
              "Hailakandi",
              "Hamirpur (HP)",
              "Hamirpur (UP)",
              "Hanumangarh",
              "Hapur (Panchsheel Nagar)",
              "Harda",
              "Hardoi",
              "Haridwar",
              "Hassan",
              "Hathras",
              "Haveri",
              "Hazaribag",
              "Hingoli",
              "Hisar",
              "Hojai",
              "Hooghly",
              "Hoshangabad",
              "Hoshiarpur",
              "Howrah",
              "Hyderabad",
              "Idukki",
              "Imphal East",
              "Imphal West",
              "Indore",
              "Jabalpur",
              "Jagatsinghapur",
              "Jagtial",
              "Jaipur",
              "Jaisalmer",
              "Jajpur",
              "Jalandhar",
              "Jalaun",
              "Jalgaon",
              "Jalna",
              "Jalore",
              "Jalpaiguri",
              "Jammu",
              "Jamnagar",
              "Jamtara",
              "Jamui",
              "Jangaon",
              "Janjgir-Champa",
              "Jashpur",
              "Jaunpur",
              "Jayashankar Bhoopalpally",
              "Jehanabad",
              "Jhabua",
              "Jhajjar",
              "Jhalawar",
              "Jhansi",
              "Jhargram",
              "Jharsuguda",
              "Jhunjhunu",
              "Jind",
              "Jiribam",
              "Jodhpur",
              "Jogulamba Gadwal",
              "Jorhat",
              "Junagadh",
              "Kabirdham (Kawardha)",
              "Kachchh",
              "Kaimur (Bhabua)",
              "Kaithal",
              "Kakching",
              "Kalaburagi (Gulbarga)",
              "Kalahandi",
              "Kalimpong",
              "Kallakurichi",
              "Kamareddy",
              "Kamjong",
              "Kamle",
              "Kamrup",
              "Kamrup Metropolitan",
              "Kanchipuram",
              "Kandhamal",
              "Kangpokpi",
              "Kangra",
              "Kanker (North Bastar)",
              "Kannauj",
              "Kannur",
              "Kanpur Dehat",
              "Kanpur Nagar",
              "Kanshiram Nagar (Kasganj)",
              "Kanyakumari",
              "Kapurthala",
              "Karaikal",
              "Karauli",
              "Karbi Anglong",
              "Kargil",
              "Karimganj",
              "Karimnagar",
              "Karnal",
              "Karur",
              "Kasaragod",
              "Kathua",
              "Katihar",
              "Katni",
              "Kaushambi",
              "Kendrapara",
              "Kendujhar (Keonjhar)",
              "Khagaria",
              "Khammam",
              "Khandwa",
              "Khargone",
              "Kheda (Nadiad)",
              "Khordha",
              "Khowai",
              "Khunti",
              "Kinnaur",
              "Kiphire",
              "Kishanganj",
              "Kishtwar",
              "Kodagu",
              "Koderma",
              "Kohima",
              "Kokrajhar",
              "Kolar",
              "Kolasib",
              "Kolhapur",
              "Kolkata",
              "Kollam",
              "Komaram Bheem Asifabad",
              "Kondagaon",
              "Koppal",
              "Koraput",
              "Korba",
              "Korea (Koriya)",
              "Kota",
              "Kottayam",
              "Kozhikode",
              "Kra Daadi",
              "Krishna",
              "Krishnagiri",
              "Kulgam",
              "Kullu",
              "Kupwara",
              "Kurnool",
              "Kurukshetra",
              "Kurung Kumey",
              "Kushinagar (Padrauna)",
              "Lahaul & Spiti",
              "Lakhimpur",
              "Lakhimpur - Kheri",
              "Lakhisarai",
              "Lakshadweep",
              "Lalitpur",
              "Latehar",
              "Latur",
              "Lawngtlai",
              "Leh",
              "Lepa Rada",
              "Lohardaga",
              "Lohit",
              "Longding",
              "Longleng",
              "Lower Dibang Valley",
              "Lower Siang",
              "Lower Subansiri",
              "Lucknow",
              "Ludhiana",
              "Lunglei",
              "Madhepura",
              "Madhubani",
              "Madurai",
              "Mahabubabad",
              "Mahabubnagar",
              "Maharajganj",
              "Mahasamund",
              "Mahe",
              "Mahendragarh",
              "Mahisagar",
              "Mahoba",
              "Mainpuri",
              "Majuli",
              "Malappuram",
              "Malda",
              "Malkangiri",
              "Mamit",
              "Mancherial",
              "Mandi",
              "Mandla",
              "Mandsaur",
              "Mandya",
              "Mansa",
              "Mathura",
              "Mau",
              "Mayurbhanj",
              "Medak",
              "Medchal",
              "Meerut",
              "Mehsana",
              "Mirzapur",
              "Moga",
              "Mokokchung",
              "Mon",
              "Moradabad",
              "Morbi",
              "Morena",
              "Morigaon",
              "Muktsar",
              "Mumbai City",
              "Mumbai Suburban",
              "Mungeli",
              "Munger (Monghyr)",
              "Murshidabad",
              "Muzaffarnagar",
              "Muzaffarpur",
              "Mysuru (Mysore)",
              "Nabarangpur",
              "Nadia",
              "Nagaon",
              "Nagapattinam",
              "Nagarkurnool",
              "Nagaur",
              "Nagpur",
              "Nainital",
              "Nalanda",
              "Nalbari",
              "Nalgonda",
              "Namakkal",
              "Namsai",
              "Nanded",
              "Nandurbar",
              "Narayanpur",
              "Narmada (Rajpipla)",
              "Narsinghpur",
              "Nashik",
              "Navsari",
              "Nawada",
              "Nawanshahr (Shahid Bhagat Singh Nagar)",
              "Nayagarh",
              "Neemuch",
              "New Delhi",
              "Nicobar",
              "Nilgiris",
              "Nirmal",
              "Nizamabad",
              "Noney",
              "North 24 Parganas",
              "North Delhi",
              "North East Delhi",
              "North Garo Hills",
              "North Goa",
              "North Sikkim",
              "North Tripura",
              "North West Delhi",
              "North and Middle Andaman",
              "Nuapada",
              "Nuh",
              "Osmanabad",
              "Pakke Kessang",
              "Pakur",
              "Palakkad",
              "Palamu",
              "Palghar",
              "Pali",
              "Palwal",
              "Panchkula",
              "Panchmahal (Godhra)",
              "Panipat",
              "Panna",
              "Papum Pare",
              "Parbhani",
              "Paschim (West) Burdwan (Bardhaman)",
              "Paschim Medinipur (West Medinipur)",
              "Patan",
              "Pathanamthitta",
              "Pathankot",
              "Patiala",
              "Patna",
              "Pauri Garhwal",
              "Peddapalli",
              "Perambalur",
              "Peren",
              "Phek",
              "Pherzawl",
              "Pilibhit",
              "Pithoragarh",
              "Poonch",
              "Porbandar",
              "Prakasam",
              "Pratapgarh (RJ)",
              "Pratapgarh (UP)",
              "Puducherry",
              "Pudukkottai",
              "Pulwama",
              "Pune",
              "Purba Burdwan (Bardhaman)",
              "Purba Medinipur (East Medinipur)",
              "Puri",
              "Purnia (Purnea)",
              "Purulia",
              "RaeBareli",
              "Raichur",
              "Raigad",
              "Raigarh",
              "Raipur",
              "Raisen",
              "Rajanna Sircilla",
              "Rajgarh",
              "Rajkot",
              "Rajnandgaon",
              "Rajouri",
              "Rajsamand",
              "Ramanagara",
              "Ramanathapuram",
              "Ramban",
              "Ramgarh",
              "Rampur",
              "Ranchi",
              "Rangareddy",
              "Ranipet",
              "Ratlam",
              "Ratnagiri",
              "Rayagada",
              "Reasi",
              "Rewa",
              "Rewari",
              "Ri Bhoi",
              "Rohtak",
              "Rohtas",
              "Rudraprayag",
              "Rupnagar",
              "Sabarkantha (Himmatnagar)",
              "Sagar",
              "Saharanpur",
              "Saharsa",
              "Sahibganj",
              "Sahibzada Ajit Singh Nagar (Mohali)",
              "Saiha",
              "Salem",
              "Samastipur",
              "Samba",
              "Sambalpur",
              "Sambhal (Bhim Nagar)",
              "Sangareddy",
              "Sangli",
              "Sangrur",
              "Sant Kabir Nagar",
              "Saran",
              "Satara",
              "Satna",
              "Sawai Madhopur",
              "Sehore",
              "Senapati",
              "Seoni",
              "Sepahijala",
              "Seraikela-Kharsawan",
              "Serchhip",
              "Shahdara",
              "Shahdol",
              "Shahjahanpur",
              "Shajapur",
              "Shamali (Prabuddh Nagar)",
              "Sheikhpura",
              "Sheohar",
              "Sheopur",
              "Shi Yomi",
              "Shimla",
              "Shivamogga (Shimoga)",
              "Shivpuri",
              "Shopian",
              "Shravasti",
              "Siang",
              "Siddharth Nagar",
              "Siddipet",
              "Sidhi",
              "Sikar",
              "Simdega",
              "Sindhudurg",
              "Singrauli",
              "Sirmaur (Sirmour)",
              "Sirohi",
              "Sirsa",
              "Sitamarhi",
              "Sitapur",
              "Sivaganga",
              "Sivasagar",
              "Siwan",
              "Solan",
              "Solapur",
              "Sonbhadra",
              "Sonepur",
              "Sonipat",
              "Sonitpur",
              "South 24 Parganas",
              "South Andaman",
              "South Delhi",
              "South East Delhi",
              "South Garo Hills",
              "South Goa",
              "South Salamara-Mankachar",
              "South Sikkim",
              "South Tripura",
              "South West Delhi",
              "South West Garo Hills",
              "South West Khasi Hills",
              "Sri Ganganagar",
              "Sri Potti Sriramulu Nellore ",
              "Srikakulam",
              "Srinagar",
              "Sukma",
              "Sultanpur",
              "Sundargarh",
              "Supaul",
              "Surajpur",
              "Surat",
              "Surendranagar",
              "Surguja",
              "Suryapet",
              "Tamenglong",
              "Tapi (Vyara)",
              "Tarn Taran",
              "Tawang",
              "Tehri Garhwal",
              "Tengnoupal",
              "Tenkasi",
              "Thane",
              "Thanjavur",
              "Theni",
              "Thiruvananthapuram",
              "Thoothukudi (Tuticorin)",
              "Thoubal",
              "Thrissur",
              "Tikamgarh",
              "Tinsukia",
              "Tirap",
              "Tiruchirappalli",
              "Tirunelveli",
              "Tirupathur",
              "Tiruppur",
              "Tiruvallur",
              "Tiruvannamalai",
              "Tiruvarur",
              "Tonk",
              "Tuensang",
              "Tumakuru (Tumkur)",
              "Udaipur",
              "Udalguri",
              "Udham Singh Nagar",
              "Udhampur",
              "Udupi",
              "Ujjain",
              "Ukhrul",
              "Umaria",
              "Una",
              "Unakoti",
              "Unnao",
              "Upper Siang",
              "Upper Subansiri",
              "Uttar Dinajpur (North Dinajpur)",
              "Uttara Kannada (Karwar)",
              "Uttarkashi",
              "Vadodara",
              "Vaishali",
              "Valsad",
              "Varanasi",
              "Vellore",
              "Vidisha",
              "Vijayapura (Bijapur)",
              "Vikarabad",
              "Viluppuram",
              "Virudhunagar",
              "Visakhapatnam",
              "Vizianagaram",
              "Wanaparthy",
              "Warangal (Rural)",
              "Warangal (Urban)",
              "Wardha",
              "Washim",
              "Wayanad",
              "West Champaran",
              "West Delhi",
              "West Garo Hills",
              "West Godavari",
              "West Jaintia Hills",
              "West Kameng",
              "West Karbi Anglong",
              "West Khasi Hills",
              "West Siang",
              "West Sikkim",
              "West Singhbhum",
              "West Tripura",
              "Wokha",
              "YSR District, Kadapa (Cuddapah)",
              "Yadadri Bhuvanagiri",
              "Yadgir",
              "Yamunanagar",
              "Yanam",
              "Yavatmal",
              "Zunheboto"
              ]}
              options={{
                placeholder: "City / District"
              }}

            />
                          <div className="errorsidediv" >
                                <p className="errorside"><img src={process.env.PUBLIC_URL + "/img/Icon_feather-alert-circle.svg"} className="errorsideimg" />
                                  <span className="errorsidespan"></span>
                                </p>
                              </div>
            <center>
            <img className="addserviceloader" id="addserviceloadermain" src={process.env.PUBLIC_URL + "/img/loader.gif"} alt="" />
              <button id="normalsubmit" onClick={this.createPurchaseReqNormal} class type="submit" className="planformsubmitbutton">SUBMIT</button>
              <button
                          className="addtocartbutton2"
                          id="submit"
                          onClick={this.addToCartfirstdropdowngetstartedclick}
                        >
                          Add to cart
                          &nbsp;<img
                      src={process.env.PUBLIC_URL + "/img/Path_33782_1.svg"}
                    />
                        </button>
            </center>
          </div>


          <div className="arrow-left2 visibilityhidden"></div>
          <div className="white_div expandwhite_div">
            <div className="white_box">
              <div className="white_boxscrolldiv">
                <center>
                  {" "}
                  <p className="download">Legal Notice Reply</p>
                </center>
                <br />
                <br />
                <p className="download2">Feel free just go with general, you can upgrade it later if required as per expert consultation.</p>
                <div className="plantwocards" >
                  <br />
                  <div className="row paddingmarginzero">
                  <div className="col-sm-6 plans2cardcol">
                      <div className="bluestartercard">
                        <p className="planmainheading">General</p>
                        <div className="plancardinsidepadding">

                          <div className="formplacer ">
                            <div className="hidebusscon1">
                            <p className="plancustomprice"><sup className="moresize">₹</sup>{this.state.REPLG}</p>
                              <center>
                                <button
                                  className="getform plangetstartedbtn"
                                  id="b1"
                                  onClick={this.firstdropdowngetstartedclick}
                                >
                                  GET STARTED
                                </button>
                                <img
                                  src={
                                    process.env.PUBLIC_URL + "/img/loader.gif"
                                  }
                                  className="addserviceloader"
                                  id="addserviceloaderid1"
                                />
                              </center>
                              <p
                                                                    className="plancommonlinetext">
                                
                                Preferable in general matters where examination of documents is not required.
                                                                </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 plans2cardcol">
                      <div className="basicplancard">
                        <p className="planmainheading">Detailed</p>
                        <div className="plancardinsidepadding">
                          <div className="formplacer ">
                            <div className="hidebusscon2">
                              <center>
                              <p className="plancustomprice"><sup className="moresize">₹</sup>{this.state.REPLD}</p>
                                <button
                                  className="getform2 plangetstartedbtn"
                                  onClick={this.seconddropdowngetstartedclick}
                                  id="b2"
                                >
                                  GET STARTED
                                </button>
                                <img
                                  src={
                                    process.env.PUBLIC_URL + "/img/loader.gif"
                                  }
                                  className="addserviceloader"
                                  id="addserviceloaderid2"
                                />
                              </center>
                              <p
                                                                    className="plancommonlinetext">
                                
                                Preferable in matters where examination of documents is required.
                                                                </p>
                             
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="myDIV">

<div className='supportDetails'>
    <img
        src={process.env.PUBLIC_URL + "/img/24-7_Support.png"}

        alt="image"
    />
    <div>
        <h5>{supportName}</h5>
        <p>Agent Support Executive</p>
        <span><b>Contact: </b> +91 {supportContact}</span>
        <p className='mb-1'><b>Working Hours: </b> 11:00 AM - 06:30 PM</p>
    </div>
</div>


</div>
<button className="support" onClick={this.handlesupport} >
<img src={process.env.PUBLIC_URL + "/img/headPhone.svg"} width="50%" height="50px" />
</button>

          <Footer />
        </div>
      );

    } else {
      return <Redirect to="/" />;
    }
  }
}

export default Addserviceitrf;

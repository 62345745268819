import React, { Component } from "react";
import $ from "jquery";
import { Helmet } from "react-helmet";
import { Link, Redirect } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import axios from "axios";
import Sidebar from "./Sidebar";
var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);
let baseUrl = process.env.REACT_APP_API_BASE_URL;
let Decjwttoken;
let Decagentid;
let supportName;
let supportContact;
let supportEmail;
const itrf = "ITRF";
const gstrf = "GSTRF";
const gstr = "GSTR";
const tr = "TR";
class AgentPanel__dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cards: [],
      searchCard: [],
      filteredData: [],
      searchFilterData: [],
      notification: [],
      filter: false,
      goodtogo: false,
      searchinput: "",
      username: "",
      userid: "",
      searchinputtype: "",
      searchdone: false,
      latest: true,
      filterbug: false,
      language: "English",
      supportName: "",
      supportContact: "",
      supportEmail: "",
      supportname: "",
      supportcontact: "",
      supportemail: "",
    };
  }
  fbug = () => {
    if (this.state.filter && !this.state.searchdone) {
      this.setState({ filterbug: true });
    }
  };
  filterItrf = (t) => {
    if (!this.state.filterbug) {
      if (t == "all") {
        this.setState({ filter: false });
      } else {
        this.setState({ filter: true }, this.fbug);
        let filter = this.state.cards.filter((el) => {
          if (el.pendingServices.includes(t)) {
            return true;
          }
        });
        let searchFilter = this.state.searchCard.filter((el) => {
          if (el.pendingServices.includes(t)) {
            return true;
          }
        });

        this.setState({
          filteredData: filter,
          searchFilterData: searchFilter,
        });
      }
    } else {
      if (t == "all") {
        this.setState({
          searchdone: false,
          filter: false,
          searchinput: "",
          username: "",
          userid: "",
          filterbug: false,
        });
      } else {
        this.setState({ filter: true });
        let filter = this.state.cards.filter((el) => {
          if (el.pendingServices.includes(t)) {
            return true;
          } else return false;
        });
        this.setState({
          filteredData: filter,
          searchinput: "",
          username: "",
          userid: "",
          searchdone: false,
          filterbug: false,
        });
      }
    }
  };

  filterExpiring = (t) => {
    if (!this.state.filterbug) {
      this.setState({ filter: true }, this.fbug);
      let filter = this.state.cards.filter((el) => {
        if (el.expiringServices.includes(t)) {
          return true;
        }
      });
      let searchFilter = this.state.searchCard.filter((el) => {
        if (el.expiringServices.includes(t)) {
          return true;
        }
      });

      this.setState({ filteredData: filter, searchFilterData: searchFilter });
    } else {
      this.setState({ filter: true });
      let filter = this.state.cards.filter((el) => {
        if (el.expiringServices.includes(t)) {
          return true;
        }
      });

      this.setState({
        filteredData: filter,
        searchinput: "",
        searchdone: false,
        filterbug: false,
      });
    }
  };
  searchcard = () => {
    if (!this.state.filter) {
      let mycards;
      if (this.state.searchinputtype == "userId") {
        mycards = this.state.cards.filter((value, index) => {
          if (value.userId.includes(this.state.userid)) {
            return true;
          } else {
            return false;
          }
        });
      } else if (this.state.searchinputtype == "username") {
        mycards = this.state.cards.filter((value, index) => {
          if (value.username.includes(this.state.username)) {
            return true;
          } else {
            return false;
          }
        });
      } else if (this.state.searchinputtype == "email") {
        mycards = this.state.cards.find((value, index) => {
          if (value.email == this.state.searchinput) {
            return true;
          } else {
            return false;
          }
        });
      } else if (this.state.searchinputtype == "contact") {
        mycards = this.state.cards.find((value, index) => {
          if (value.contact == this.state.searchinput) {
            return true;
          } else {
            return false;
          }
        });
      } else {
        return false;
      }

      if (
        mycards &&
        this.state.username.trim() == "" &&
        this.state.userid.trim() == ""
      ) {
        this.setState(
          { searchCard: [mycards], searchdone: true },
          this.showSearchdata
        );
      } else if (
        mycards &&
        this.state.username != "" &&
        this.state.userid.trim() == ""
      ) {
        this.setState(
          { searchCard: mycards, searchdone: true },
          this.showSearchdata
        );
      } else if (
        mycards &&
        this.state.username.trim() == "" &&
        this.state.userid != ""
      ) {
        this.setState(
          { searchCard: mycards, searchdone: true },
          this.showSearchdata
        );
      } else {
        this.setState({ searchCard: [], searchdone: false });
        this.showNotification("No data found");
      }
    } else {
      let mycards2;
      if (this.state.searchinputtype == "userId") {
        mycards2 = this.state.filteredData.filter((value, index) => {
          if (value.userId.includes(this.state.userid)) {
            return true;
          } else {
            return false;
          }
        });
      } else if (this.state.searchinputtype == "username") {
        mycards2 = this.state.filteredData.filter((value, index) => {
          if (value.username.includes(this.state.username)) {
            return true;
          } else {
            return false;
          }
        });
      } else if (this.state.searchinputtype == "email") {
        mycards2 = this.state.filteredData.find((value, index) => {
          if (value.email == this.state.searchinput) {
            return true;
          } else {
            return false;
          }
        });
      } else if (this.state.searchinputtype == "contact") {
        mycards2 = this.state.filteredData.find((value, index) => {
          if (value.contact == this.state.searchinput) {
            return true;
          } else {
            return false;
          }
        });
      } else {
        return false;
      }

      if (
        mycards2 &&
        this.state.username.trim() == "" &&
        this.state.userid.trim() == ""
      ) {
        this.setState(
          { searchFilterData: [mycards2], searchdone: true },
          this.showSearchdata
        );
      } else if (
        mycards2 &&
        this.state.username != "" &&
        this.state.userid.trim() == ""
      ) {
        this.setState(
          { searchFilterData: mycards2, searchdone: true },
          this.showSearchdata
        );
      } else if (
        mycards2 &&
        this.state.username.trim() == "" &&
        this.state.userid != ""
      ) {
        this.setState(
          { searchFilterData: mycards2, searchdone: true },
          this.showSearchdata
        );
      } else {
        this.setState({ searchFilterData: [], searchdone: false });
        this.showNotification("No data found");
      }
    }
  };

  showPromotion = () => {
    this.props.history.push({ pathname: "/materials" })
  }

  handleSearchinput = (e) => {
    e.preventDefault();
    const contactNoregex = /^[6-9]\d{9}$/;
    const emailregex = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    if (
      this.state.searchinput.trim() != "" &&
      this.state.searchinput.length < 100
    ) {
      if (emailregex.test(this.state.searchinput)) {
        this.setState({ searchinputtype: "email" }, this.searchcard);
      } else if (contactNoregex.test(this.state.searchinput)) {
        this.setState({ searchinputtype: "contact" }, this.searchcard);
      } else if (isNaN(this.state.searchinput)) {
        var mySentence = this.state.searchinput.trim();
        mySentence = mySentence.replace(/\s+/g, " ");
        const words = mySentence.split(" ");

        for (let i = 0; i < words.length; i++) {
          words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        }
        var mywords = words.join(" ");
        this.setState(
          { username: mywords.trim(), userid: "", searchinputtype: "username" },
          this.searchcard
        );
      } else if (!isNaN(this.state.searchinput)) {
        this.setState(
          {
            userid: this.state.searchinput,
            username: "",
            searchinputtype: "userId",
          },
          this.searchcard
        );
      } else {
        this.showNotification("Invalid Search");
      }
    } else {
      this.setState({ searchdone: false }, this.showData);
      this.showNotification("No data found");
    }
  };
  componentDidMount() {
    if (this.props.location.state) {
      if (this.props.location.state.timeout) {
        this.showNotification("Timeout !");
        this.props.history.replace("/dashboard", null);
      }
    }
    window.scrollTo(0, 0);
    $(document.body).css("background-color", "#2d2d2d");
    let mainagentid = localStorage.getItem("qazxswedcvfrtgb");
    Decagentid = encryptor.decrypt(mainagentid);
    let mainjwttoken = localStorage.getItem("toeljgtkewlna");
    Decjwttoken = encryptor.decrypt(mainjwttoken);

    this.getoverdueServicesApi();
    this.getservicename();
  }

  handlenotiprofilewapi = () => {
    axios({
      method: "post",
      url: process.env.REACT_APP_API_BASE_URL + "p=notificationProfile",
      data: {
        agentId: Decagentid,
      },
      headers: {
        AuthKey: process.env.REACT_APP_API_KEY,
        "App-Token-Access": Decjwttoken,
      },
      auth: {
        username: process.env.REACT_APP_API_USERNAME,
        password: process.env.REACT_APP_API_PASSWORD,
      },
    })
      .then((resp) => {
        if (resp.data.code == 200 && resp.data.status == "success") {
          supportName = resp.data.supportName
          supportContact = resp.data.supportContact
          supportEmail = resp.data.supportEmail
          this.setState({
            supportname: supportName,
            supportcontact: supportContact,
            supportemail: supportEmail,
          })

        } else if (
          resp.data.code == "201" &&
          resp.data.status == "invalidauth"
        ) {
          this.logout();
        }
      })
      .catch((error) => { });
  };


  getservicename = () => {
    supportName = localStorage.getItem("supportName");
    supportContact = localStorage.getItem("supportContact");
    supportEmail = localStorage.getItem("supportEmail");
   
    if (supportName != null && supportContact != null && supportEmail != null) {
      this.setState({
        supportname: supportName,
        supportcontact: supportContact,
        supportemail: supportEmail,
      })
    }
    else {
      this.handlenotiprofilewapi()
    }

  }

  logout = () => {
    localStorage.removeItem("gglltakoinoeenl");
    localStorage.removeItem("toeljgtkewlna");
    localStorage.removeItem("qazxswedcvfrtgb");
    localStorage.removeItem("lshhihfnkff");
    this.props.history.push({
      pathname: `/`,
    });
  };

  getoverdueServicesApi = () => {
    $(".pageloader").show();
    $("#dataDiv").hide();
    axios
      .post(
        `${baseUrl}p=overdueServices`,
        {
          agentId: Decagentid,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        $(".pageloader").hide();

        if (res.data.code == 200 && res.data.status == "success") {
          this.setState(
            {
              cards: res.data.overdueServices,
            },
            () => {
              $("#dataDiv").show();
              this.showData();
            }
          );
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification("Something went wrong ");
        }
      })
      .catch((err) => {
        this.showNotification("Something went wrong ");
      });
  };
  TrainingMaterial = () => {
    this.props.history.push({ pathname: "/materials", state: { type: "Training", } })
  }
  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };

  clickViewBtn = (doc) => {
    localStorage.setItem("relleIsugad", encryptor.encrypt(doc.userId));
    localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
    this.props.history.push({
      pathname: `/myuser/${doc.userId}`,
      state: { name: doc.username, email: doc.email, contact: doc.contact },
    });
  };
  showSearchdata = () => {
    if (!this.state.filter && this.state.searchdone) {
      if (this.state.latest) {
        if (this.state.searchCard.length > 0) {
          return this.state.searchCard.map((doc, index) => {
            return (
              <div
                className="col-xl-4 col-12 mb-1
           agent__card__col"
                key={index}
              >
                <div className="card agent__card">
                  <div className="agent__card__data">
                    <div className="agent__card__data__name">
                      {doc.username}
                    </div>
                    <div className="agent__card__data__para">
                      <p className="agent__card__data__title">
                        User ID:-&nbsp;
                      </p>
                      <p className="agent__card__data__lead">{doc.userId}</p>
                    </div>
                    <div className="agent__card__data__para">
                      <p className="agent__card__data__title">Status:-&nbsp;</p>
                      <p className="agent__card__data__lead">{doc.status}</p>
                    </div>
                    <div className="agent__card__data__para">
                      <p className="agent__card__data__title">
                        Linked on:-&nbsp;
                      </p>
                      <p className="agent__card__data__lead">{doc.linkedon}</p>
                    </div>
                    <div className="agent__card__data__para">
                      <p className="agent__card__data__title">
                        Services:-&nbsp;
                      </p>
                      <span className="agent__card__data__lead">
                        {doc.services.length == 0 ? (
                          <span>-</span>
                        ) : (
                          doc.services.map((numbers, index) => {
                            if (index == doc.services.length - 1) {
                              return <span key={index}>{numbers}</span>;
                            } else {
                              return <span key={index}>{numbers + ","}</span>;
                            }
                          })
                        )}
                      </span>
                    </div>
                    <div className="agent__card__data__para agent__card__blue">
                      <p className="agent__card__data__title">
                        Pending Services:-&nbsp;
                        <span className="agent__card__data__lead">
                          {doc.pendingServices.length == 0 ? (
                            <span>-</span>
                          ) : (
                            doc.pendingServices.map((numbers, index) => {
                              if (index == doc.pendingServices.length - 1) {
                                return <span key={index}>{numbers}</span>;
                              } else {
                                return <span key={index}>{numbers + ","}</span>;
                              }
                            })
                          )}
                        </span>
                      </p>
                    </div>
                    <div className="agent__card__data__para agent__card__red">
                      <p className="agent__card__data__title">
                        Expiring Services:-
                        {/* </p> */}
                        <span className="agent__card__data__lead">
                          {doc.expiringServices.length == 0 ? (
                            <span>-</span>
                          ) : (
                            doc.expiringServices.map((numbers, index) => {
                              if (index == doc.expiringServices.length - 1) {
                                return <span key={index}>{numbers}</span>;
                              } else {
                                return <span key={index}>{numbers + ","}</span>;
                              }
                            })
                          )}
                        </span>
                      </p>
                    </div>

                    {/* <Link
                  to={{
                    pathname: "/useroption",
                    state: {
                      userid: doc.userId,
                      name: doc.username,
                      email: doc.email,
                      contact: doc.contact,
                    },
                  }}
                  className="agent__viewbtn mt-2"
                >
                  View{" "}
                  <img src={process.env.PUBLIC_URL + "/img/Path 19498.svg"} />
                </Link> */}

                    <button
                      className="agent__viewbtn mt-2"
                      onClick={() => this.clickViewBtn(doc)}
                    >
                      View{" "}
                      <img
                        src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                      />
                    </button>
                  </div>
                </div>
              </div>
            );
          });
        } else if (this.state.searchCard.length == 0) {
          return (
            <div
              className="d-flex justify-content-center align-items-center h-100 w-100 mt-3"
              id="noDataimg2"
            >
              <img
                className="nullImg"
                src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              />
            </div>
          );
        }
      } else {
        if (this.state.searchCard.length > 0) {
          return this.state.searchCard
            .slice()
            .reverse()
            .map((doc, index) => {
              return (
                <div
                  className="col-xl-4 col-12 mb-1
           agent__card__col"
                  key={index}
                >
                  <div className="card agent__card">
                    <div className="agent__card__data">
                      <div className="agent__card__data__name">
                        {doc.username}
                      </div>
                      <div className="agent__card__data__para">
                        <p className="agent__card__data__title">
                          User ID:-&nbsp;
                        </p>
                        <p className="agent__card__data__lead">{doc.userId}</p>
                      </div>
                      <div className="agent__card__data__para">
                        <p className="agent__card__data__title">
                          Status:-&nbsp;
                        </p>
                        <p className="agent__card__data__lead">{doc.status}</p>
                      </div>
                      <div className="agent__card__data__para">
                        <p className="agent__card__data__title">
                          Linked on:-&nbsp;
                        </p>
                        <p className="agent__card__data__lead">
                          {doc.linkedon}
                        </p>
                      </div>
                      <div className="agent__card__data__para">
                        <p className="agent__card__data__title">
                          Services:-&nbsp;
                        </p>
                        <span className="agent__card__data__lead">
                          {doc.services.length == 0 ? (
                            <span>-</span>
                          ) : (
                            doc.services.map((numbers, index) => {
                              if (index == doc.services.length - 1) {
                                return <span key={index}>{numbers}</span>;
                              } else {
                                return <span key={index}>{numbers + ","}</span>;
                              }
                            })
                          )}
                        </span>
                      </div>
                      <div className="agent__card__data__para agent__card__blue">
                        <p className="agent__card__data__title">
                          Pending Services:-&nbsp;
                          <span className="agent__card__data__lead">
                            {doc.pendingServices.length == 0 ? (
                              <span>-</span>
                            ) : (
                              doc.pendingServices.map((numbers, index) => {
                                if (index == doc.pendingServices.length - 1) {
                                  return <span key={index}>{numbers}</span>;
                                } else {
                                  return (
                                    <span key={index}>{numbers + ","}</span>
                                  );
                                }
                              })
                            )}
                          </span>
                        </p>
                      </div>
                      <div className="agent__card__data__para agent__card__red">
                        <p className="agent__card__data__title">
                          Expiring Services:-
                          {/* </p> */}
                          <span className="agent__card__data__lead">
                            {doc.expiringServices.length == 0 ? (
                              <span>-</span>
                            ) : (
                              doc.expiringServices.map((numbers, index) => {
                                if (index == doc.expiringServices.length - 1) {
                                  return <span key={index}>{numbers}</span>;
                                } else {
                                  return (
                                    <span key={index}>{numbers + ","}</span>
                                  );
                                }
                              })
                            )}
                          </span>
                        </p>
                      </div>

                      {/* <Link
                  to={{
                    pathname: "/useroption",
                    state: {
                      userid: doc.userId,
                      name: doc.username,
                      email: doc.email,
                      contact: doc.contact,
                    },
                  }}
                  className="agent__viewbtn mt-2"
                >
                  View{" "}
                  <img src={process.env.PUBLIC_URL + "/img/Path 19498.svg"} />
                </Link> */}

                      <button
                        className="agent__viewbtn mt-2"
                        onClick={() => this.clickViewBtn(doc)}
                      >
                        View{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              );
            });
        } else if (this.state.searchCard.length == 0) {
          return (
            <div
              className="d-flex justify-content-center align-items-center h-100 w-100 mt-3"
              id="noDataimg2"
            >
              <img
                className="nullImg"
                src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              />
            </div>
          );
        }
      }
    } else {
      if (this.state.latest) {
        if (this.state.searchFilterData.length > 0) {
          return this.state.searchFilterData.map((doc, index) => {
            return (
              <div
                className="col-xl-4 col-12 mb-1
           agent__card__col"
                key={index}
              >
                <div className="card agent__card">
                  <div className="agent__card__data">
                    <div className="agent__card__data__name">
                      {doc.username}
                    </div>
                    <div className="agent__card__data__para">
                      <p className="agent__card__data__title">
                        User ID:-&nbsp;
                      </p>
                      <p className="agent__card__data__lead">{doc.userId}</p>
                    </div>
                    <div className="agent__card__data__para">
                      <p className="agent__card__data__title">Status:-&nbsp;</p>
                      <p className="agent__card__data__lead">{doc.status}</p>
                    </div>
                    <div className="agent__card__data__para">
                      <p className="agent__card__data__title">
                        Linked on:-&nbsp;
                      </p>
                      <p className="agent__card__data__lead">{doc.linkedon}</p>
                    </div>
                    <div className="agent__card__data__para">
                      <p className="agent__card__data__title">
                        Services:-&nbsp;
                      </p>
                      <span className="agent__card__data__lead">
                        {doc.services.length == 0 ? (
                          <span>-</span>
                        ) : (
                          doc.services.map((numbers, index) => {
                            if (index == doc.services.length - 1) {
                              return <span key={index}>{numbers}</span>;
                            } else {
                              return <span key={index}>{numbers + ","}</span>;
                            }
                          })
                        )}
                      </span>
                    </div>
                    <div className="agent__card__data__para agent__card__blue">
                      <p className="agent__card__data__title">
                        Pending Services:-&nbsp;
                        <span className="agent__card__data__lead">
                          {doc.pendingServices.length == 0 ? (
                            <span>-</span>
                          ) : (
                            doc.pendingServices.map((numbers, index) => {
                              if (index == doc.pendingServices.length - 1) {
                                return <span key={index}>{numbers}</span>;
                              } else {
                                return <span key={index}>{numbers + ","}</span>;
                              }
                            })
                          )}
                        </span>
                      </p>
                    </div>
                    <div className="agent__card__data__para agent__card__red">
                      <p className="agent__card__data__title">
                        Expiring Services:-
                        {/* </p> */}
                        <span className="agent__card__data__lead">
                          {doc.expiringServices.length == 0 ? (
                            <span>-</span>
                          ) : (
                            doc.expiringServices.map((numbers, index) => {
                              if (index == doc.expiringServices.length - 1) {
                                return <span key={index}>{numbers}</span>;
                              } else {
                                return <span key={index}>{numbers + ","}</span>;
                              }
                            })
                          )}
                        </span>
                      </p>
                    </div>

                    {/* <Link
                  to={{
                    pathname: "/useroption",
                    state: {
                      userid: doc.userId,
                      name: doc.username,
                      email: doc.email,
                      contact: doc.contact,
                    },
                  }}
                  className="agent__viewbtn mt-2"
                >
                  View{" "}
                  <img src={process.env.PUBLIC_URL + "/img/Path 19498.svg"} />
                </Link> */}

                    <button
                      className="agent__viewbtn mt-2"
                      onClick={() => this.clickViewBtn(doc)}
                    >
                      View{" "}
                      <img
                        src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                      />
                    </button>
                  </div>
                </div>
              </div>
            );
          });
        } else if (this.state.searchFilterData.length == 0) {
          return (
            <div
              className="d-flex justify-content-center align-items-center h-100 w-100 mt-3"
              id="noDataimg2"
            >
              <img
                className="nullImg"
                src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              />
            </div>
          );
        }
      } else {
        if (this.state.searchFilterData.length > 0) {
          return this.state.searchFilterData
            .slice()
            .reverse()
            .map((doc, index) => {
              return (
                <div
                  className="col-xl-4 col-12 mb-1
           agent__card__col"
                  key={index}
                >
                  <div className="card agent__card">
                    <div className="agent__card__data">
                      <div className="agent__card__data__name">
                        {doc.username}
                      </div>
                      <div className="agent__card__data__para">
                        <p className="agent__card__data__title">
                          User ID:-&nbsp;
                        </p>
                        <p className="agent__card__data__lead">{doc.userId}</p>
                      </div>
                      <div className="agent__card__data__para">
                        <p className="agent__card__data__title">
                          Status:-&nbsp;
                        </p>
                        <p className="agent__card__data__lead">{doc.status}</p>
                      </div>
                      <div className="agent__card__data__para">
                        <p className="agent__card__data__title">
                          Linked on:-&nbsp;
                        </p>
                        <p className="agent__card__data__lead">
                          {doc.linkedon}
                        </p>
                      </div>
                      <div className="agent__card__data__para">
                        <p className="agent__card__data__title">
                          Services:-&nbsp;
                        </p>
                        <span className="agent__card__data__lead">
                          {doc.services.length == 0 ? (
                            <span>-</span>
                          ) : (
                            doc.services.map((numbers, index) => {
                              if (index == doc.services.length - 1) {
                                return <span key={index}>{numbers}</span>;
                              } else {
                                return <span key={index}>{numbers + ","}</span>;
                              }
                            })
                          )}
                        </span>
                      </div>
                      <div className="agent__card__data__para agent__card__blue">
                        <p className="agent__card__data__title">
                          Pending Services:-&nbsp;
                          <span className="agent__card__data__lead">
                            {doc.pendingServices.length == 0 ? (
                              <span>-</span>
                            ) : (
                              doc.pendingServices.map((numbers, index) => {
                                if (index == doc.pendingServices.length - 1) {
                                  return <span key={index}>{numbers}</span>;
                                } else {
                                  return (
                                    <span key={index}>{numbers + ","}</span>
                                  );
                                }
                              })
                            )}
                          </span>
                        </p>
                      </div>
                      <div className="agent__card__data__para agent__card__red">
                        <p className="agent__card__data__title">
                          Expiring Services:-
                          {/* </p> */}
                          <span className="agent__card__data__lead">
                            {doc.expiringServices.length == 0 ? (
                              <span>-</span>
                            ) : (
                              doc.expiringServices.map((numbers, index) => {
                                if (index == doc.expiringServices.length - 1) {
                                  return <span key={index}>{numbers}</span>;
                                } else {
                                  return (
                                    <span key={index}>{numbers + ","}</span>
                                  );
                                }
                              })
                            )}
                          </span>
                        </p>
                      </div>

                      {/* <Link
                  to={{
                    pathname: "/useroption",
                    state: {
                      userid: doc.userId,
                      name: doc.username,
                      email: doc.email,
                      contact: doc.contact,
                    },
                  }}
                  className="agent__viewbtn mt-2"
                >
                  View{" "}
                  <img src={process.env.PUBLIC_URL + "/img/Path 19498.svg"} />
                </Link> */}

                      <button
                        className="agent__viewbtn mt-2"
                        onClick={() => this.clickViewBtn(doc)}
                      >
                        View{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              );
            });
        } else if (this.state.searchFilterData.length == 0) {
          return (
            <div
              className="d-flex justify-content-center align-items-center h-100 w-100 mt-3"
              id="noDataimg2"
            >
              <img
                className="nullImg"
                src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              />
            </div>
          );
        }
      }
    }
  };
  showData = () => {
    if (!this.state.filter) {
      if (this.state.latest) {
        if (this.state.cards.length > 0) {
          return this.state.cards.map((doc, index) => {
            return (
              <div
                className="col-xl-4 col-12 mb-1
           agent__card__col"
                key={index}
              >
                <div className="card agent__card">
                  <div className="agent__card__data">
                    <div className="agent__card__data__name">
                      {doc.username}
                    </div>
                    <div className="agent__card__data__para">
                      <p className="agent__card__data__title">
                        User ID:-&nbsp;
                      </p>
                      <p className="agent__card__data__lead">{doc.userId}</p>
                    </div>
                    <div className="agent__card__data__para">
                      <p className="agent__card__data__title">Status:-&nbsp;</p>
                      <p className="agent__card__data__lead">{doc.status}</p>
                    </div>
                    <div className="agent__card__data__para">
                      <p className="agent__card__data__title">
                        Linked on:-&nbsp;
                      </p>
                      <p className="agent__card__data__lead">{doc.linkedon}</p>
                    </div>
                    <div className="agent__card__data__para">
                      <p className="agent__card__data__title">
                        Services:-&nbsp;
                      </p>
                      <p className="agent__card__data__lead">
                        {doc.services.length == 0 ? (
                          <span>-</span>
                        ) : (
                          doc.services.map((numbers, index) => {
                            if (index == doc.services.length - 1) {
                              return <span key={index}>{numbers}</span>;
                            } else {
                              return <span key={index}>{numbers + ","}</span>;
                            }
                          })
                        )}
                      </p>
                    </div>
                    <div className="agent__card__data__para agent__card__blue">
                      <p className="agent__card__data__title">
                        Pending Services:-&nbsp;
                      </p>
                      <p className="agent__card__data__lead">
                        {doc.pendingServices.length == 0 ? (
                          <span>-</span>
                        ) : (
                          doc.pendingServices.map((numbers, index) => {
                            if (index == doc.pendingServices.length - 1) {
                              return <span key={index}>{numbers}</span>;
                            } else {
                              return <span key={index}>{numbers + ","}</span>;
                            }
                          })
                        )}
                      </p>
                    </div>
                    <div className="agent__card__data__para agent__card__red">
                      <p className="agent__card__data__title">
                        Expiring Services:-&nbsp;
                      </p>
                      <p className="agent__card__data__lead">
                        {doc.expiringServices.length == 0 ? (
                          <span>-</span>
                        ) : (
                          doc.expiringServices.map((numbers, index) => {
                            if (index == doc.expiringServices.length - 1) {
                              return <span key={index}>{numbers}</span>;
                            } else {
                              return <span key={index}>{numbers + ","}</span>;
                            }
                          })
                        )}
                      </p>
                    </div>

                    {/* <Link
                  to={{
                    pathname: "/useroption",
                    state: {
                      userid: doc.userId,
                      name: doc.username,
                      email: doc.email,
                      contact: doc.contact,
                    },
                  }}
                  className="agent__viewbtn mt-2"
                >
                  View{" "}
                  <img src={process.env.PUBLIC_URL + "/img/Path 19498.svg"} />
                </Link> */}

                    <button
                      className="agent__viewbtn mt-2"
                      onClick={() => this.clickViewBtn(doc)}
                    >
                      View{" "}
                      <img
                        src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                      />
                    </button>
                  </div>
                </div>
              </div>
            );
          });
        } else if (this.state.cards.length == 0) {
          return (
            <div
              className="d-flex justify-content-center align-items-center h-100 w-100 mt-3"
              id="noDataimg"
            >
              <img
                className="nullImg"
                src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              />
            </div>
          );
        }
      } else {
        if (this.state.cards.length > 0) {
          return this.state.cards
            .slice()
            .reverse()
            .map((doc, index) => {
              return (
                <div
                  className="col-xl-4 col-12 mb-1
           agent__card__col"
                  key={index}
                >
                  <div className="card agent__card">
                    <div className="agent__card__data">
                      <div className="agent__card__data__name">
                        {doc.username}
                      </div>
                      <div className="agent__card__data__para">
                        <p className="agent__card__data__title">
                          User ID:-&nbsp;
                        </p>
                        <p className="agent__card__data__lead">{doc.userId}</p>
                      </div>
                      <div className="agent__card__data__para">
                        <p className="agent__card__data__title">
                          Status:-&nbsp;
                        </p>
                        <p className="agent__card__data__lead">{doc.status}</p>
                      </div>
                      <div className="agent__card__data__para">
                        <p className="agent__card__data__title">
                          Linked on:-&nbsp;
                        </p>
                        <p className="agent__card__data__lead">
                          {doc.linkedon}
                        </p>
                      </div>
                      <div className="agent__card__data__para">
                        <p className="agent__card__data__title">
                          Services:-&nbsp;
                        </p>
                        <p className="agent__card__data__lead">
                          {doc.services.length == 0 ? (
                            <span>-</span>
                          ) : (
                            doc.services.map((numbers, index) => {
                              if (index == doc.services.length - 1) {
                                return <span key={index}>{numbers}</span>;
                              } else {
                                return <span key={index}>{numbers + ","}</span>;
                              }
                            })
                          )}
                        </p>
                      </div>
                      <div className="agent__card__data__para agent__card__blue">
                        <p className="agent__card__data__title">
                          Pending Services:-&nbsp;
                        </p>
                        <p className="agent__card__data__lead">
                          {doc.pendingServices.length == 0 ? (
                            <span>-</span>
                          ) : (
                            doc.pendingServices.map((numbers, index) => {
                              if (index == doc.pendingServices.length - 1) {
                                return <span key={index}>{numbers}</span>;
                              } else {
                                return <span key={index}>{numbers + ","}</span>;
                              }
                            })
                          )}
                        </p>
                      </div>
                      <div className="agent__card__data__para agent__card__red">
                        <p className="agent__card__data__title">
                          Expiring Services:-&nbsp;
                        </p>
                        <p className="agent__card__data__lead">
                          {doc.expiringServices.length == 0 ? (
                            <span>-</span>
                          ) : (
                            doc.expiringServices.map((numbers, index) => {
                              if (index == doc.expiringServices.length - 1) {
                                return <span key={index}>{numbers}</span>;
                              } else {
                                return <span key={index}>{numbers + ","}</span>;
                              }
                            })
                          )}
                        </p>
                      </div>

                      {/* <Link
                  to={{
                    pathname: "/useroption",
                    state: {
                      userid: doc.userId,
                      name: doc.username,
                      email: doc.email,
                      contact: doc.contact,
                    },
                  }}
                  className="agent__viewbtn mt-2"
                >
                  View{" "}
                  <img src={process.env.PUBLIC_URL + "/img/Path 19498.svg"} />
                </Link> */}

                      <button
                        className="agent__viewbtn mt-2"
                        onClick={() => this.clickViewBtn(doc)}
                      >
                        View{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              );
            });
        } else if (this.state.cards.length == 0) {
          // $("#noDataimg").show();
          return (
            <div
              className="d-flex justify-content-center align-items-center h-100 w-100 mt-3"
              id="noDataimg"
            >
              <img
                className="nullImg"
                src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              />
            </div>
          );
        }
      }
    } else {
      if (this.state.latest) {
        if (this.state.filteredData.length > 0) {
          return this.state.filteredData.map((doc, index) => {
            return (
              <div
                className="col-xl-4 col-12 mb-1
           agent__card__col"
                key={index}
              >
                <div className="card agent__card">
                  <div className="agent__card__data">
                    <div className="agent__card__data__name">
                      {doc.username}
                    </div>
                    <div className="agent__card__data__para">
                      <p className="agent__card__data__title">
                        User ID:-&nbsp;
                      </p>
                      <p className="agent__card__data__lead">{doc.userId}</p>
                    </div>
                    <div className="agent__card__data__para">
                      <p className="agent__card__data__title">Status:-&nbsp;</p>
                      <p className="agent__card__data__lead">{doc.status}</p>
                    </div>
                    <div className="agent__card__data__para">
                      <p className="agent__card__data__title">
                        Linked on:-&nbsp;
                      </p>
                      <p className="agent__card__data__lead">{doc.linkedon}</p>
                    </div>
                    <div className="agent__card__data__para">
                      <p className="agent__card__data__title">
                        Services:-&nbsp;
                      </p>
                      <p className="agent__card__data__lead">
                        {doc.services.length == 0 ? (
                          <span>-</span>
                        ) : (
                          doc.services.map((numbers, index) => {
                            if (index == doc.services.length - 1) {
                              return <span key={index}>{numbers}</span>;
                            } else {
                              return <span key={index}>{numbers + ","}</span>;
                            }
                          })
                        )}
                      </p>
                    </div>
                    <div className="agent__card__data__para agent__card__blue">
                      <p className="agent__card__data__title">
                        Pending Services:-&nbsp;
                      </p>
                      <p className="agent__card__data__lead">
                        {doc.pendingServices.length == 0 ? (
                          <span>-</span>
                        ) : (
                          doc.pendingServices.map((numbers, index) => {
                            if (index == doc.pendingServices.length - 1) {
                              return <span key={index}>{numbers}</span>;
                            } else {
                              return <span key={index}>{numbers + ","}</span>;
                            }
                          })
                        )}
                      </p>
                    </div>
                    <div className="agent__card__data__para agent__card__red">
                      <p className="agent__card__data__title">
                        Expiring Services:-&nbsp;
                      </p>
                      <p className="agent__card__data__lead">
                        {doc.expiringServices.length == 0 ? (
                          <span>-</span>
                        ) : (
                          doc.expiringServices.map((numbers, index) => {
                            if (index == doc.expiringServices.length - 1) {
                              return <span key={index}>{numbers}</span>;
                            } else {
                              return <span key={index}>{numbers + ","}</span>;
                            }
                          })
                        )}
                      </p>
                    </div>

                    {/* <Link
                  to={{
                    pathname: "/useroption",
                    state: {
                      userid: doc.userId,
                      name: doc.username,
                      email: doc.email,
                      contact: doc.contact,
                    },
                  }}
                  className="agent__viewbtn mt-2"
                >
                  View{" "}
                  <img src={process.env.PUBLIC_URL + "/img/Path 19498.svg"} />
                </Link> */}

                    <button
                      className="agent__viewbtn mt-2"
                      onClick={() => this.clickViewBtn(doc)}
                    >
                      View{" "}
                      <img
                        src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                      />
                    </button>
                  </div>
                </div>
              </div>
            );
          });
        } else if (this.state.filteredData.length == 0) {
          // $("#noDataimg").show();
          return (
            <div
              className="d-flex justify-content-center align-items-center h-100 w-100 mt-3"
              id="noDataimg"
            >
              <img
                className="nullImg"
                src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              />
            </div>
          );
        }
      } else {
        if (this.state.filteredData.length > 0) {
          return this.state.filteredData
            .slice()
            .reverse()
            .map((doc, index) => {
              return (
                <div
                  className="col-xl-4 col-12 mb-1
           agent__card__col"
                  key={index}
                >
                  <div className="card agent__card">
                    <div className="agent__card__data">
                      <div className="agent__card__data__name">
                        {doc.username}
                      </div>
                      <div className="agent__card__data__para">
                        <p className="agent__card__data__title">
                          User ID:-&nbsp;
                        </p>
                        <p className="agent__card__data__lead">{doc.userId}</p>
                      </div>
                      <div className="agent__card__data__para">
                        <p className="agent__card__data__title">
                          Status:-&nbsp;
                        </p>
                        <p className="agent__card__data__lead">{doc.status}</p>
                      </div>
                      <div className="agent__card__data__para">
                        <p className="agent__card__data__title">
                          Linked on:-&nbsp;
                        </p>
                        <p className="agent__card__data__lead">
                          {doc.linkedon}
                        </p>
                      </div>
                      <div className="agent__card__data__para">
                        <p className="agent__card__data__title">
                          Services:-&nbsp;
                        </p>
                        <p className="agent__card__data__lead">
                          {doc.services.length == 0 ? (
                            <span>-</span>
                          ) : (
                            doc.services.map((numbers, index) => {
                              if (index == doc.services.length - 1) {
                                return <span key={index}>{numbers}</span>;
                              } else {
                                return <span key={index}>{numbers + ","}</span>;
                              }
                            })
                          )}
                        </p>
                      </div>
                      <div className="agent__card__data__para agent__card__blue">
                        <p className="agent__card__data__title">
                          Pending Services:-&nbsp;
                        </p>
                        <p className="agent__card__data__lead">
                          {doc.pendingServices.length == 0 ? (
                            <span>-</span>
                          ) : (
                            doc.pendingServices.map((numbers, index) => {
                              if (index == doc.pendingServices.length - 1) {
                                return <span key={index}>{numbers}</span>;
                              } else {
                                return <span key={index}>{numbers + ","}</span>;
                              }
                            })
                          )}
                        </p>
                      </div>
                      <div className="agent__card__data__para agent__card__red">
                        <p className="agent__card__data__title">
                          Expiring Services:-&nbsp;
                        </p>
                        <p className="agent__card__data__lead">
                          {doc.expiringServices.length == 0 ? (
                            <span>-</span>
                          ) : (
                            doc.expiringServices.map((numbers, index) => {
                              if (index == doc.expiringServices.length - 1) {
                                return <span key={index}>{numbers}</span>;
                              } else {
                                return <span key={index}>{numbers + ","}</span>;
                              }
                            })
                          )}
                        </p>
                      </div>

                      {/* <Link
                  to={{
                    pathname: "/useroption",
                    state: {
                      userid: doc.userId,
                      name: doc.username,
                      email: doc.email,
                      contact: doc.contact,
                    },
                  }}
                  className="agent__viewbtn mt-2"
                >
                  View{" "}
                  <img src={process.env.PUBLIC_URL + "/img/Path 19498.svg"} />
                </Link> */}

                      <button
                        className="agent__viewbtn mt-2"
                        onClick={() => this.clickViewBtn(doc)}
                      >
                        View{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              );
            });
        } else if (this.state.filteredData.length == 0) {
          // $("#noDataimg").show();
          return (
            <div
              className="d-flex justify-content-center align-items-center h-100 w-100 mt-3"
              id="noDataimg"
            >
              <img
                className="nullImg"
                src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              />
            </div>
          );
        }
      }
    }
  };

  render() {
    if (
      localStorage.getItem("gglltakoinoeenl") ==
      process.env.REACT_APP_LOGIN_KEY &&
      localStorage.getItem("toeljgtkewlna") != null &&
      localStorage.getItem("qazxswedcvfrtgb") != null
    ) {
      return (
        <div className="cont">
          <Helmet>
            <link
              rel="stylesheet"
              href={process.env.PUBLIC_URL + "/css/select2.css"}
            />
          </Helmet>
          <div id="notifContainer"></div>

          <Header />

          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
            className="sidebaropenicon"
          />
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
            className="sidebarbackicon"
          />
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"}
            className="sidebarexpandicon"
          />
          <div className="overlay1"></div>
          <Sidebar />

          <div className="arrow-left2"></div>
          <div className="white_div">
            <div className="white_box">
              <div className="overviewrow">
                <div className="row paddingmarginzero">
                  {/*  */}
                  <div className="col-md-9 col-12 paddingmarginzero">
                    <p className="agent__overview__head pl-2">
                      Overdue Services
                    </p>
                    <div className="border_r">
                      <div className="agent__overview__main__div">
                        <div className="agent__overview__header">
                          <div>
                            <form onSubmit={this.handleSearchinput}>
                              <input
                                type="text"
                                className="agent__overview__search_box"
                                placeholder="Search by UserId or name"
                                value={this.state.searchinput}
                                maxLength="100"
                                onChange={(e) => {
                                  this.setState({
                                    searchinput: e.target.value,
                                  });
                                  this.setState({
                                    searchdone: false,
                                    searchCard: [],
                                  });
                                }}
                              />
                            </form>
                          </div>
                          <div className="agent__filter__btn__div">
                            <div className="dropdown px-md-3 px-0">
                              <button
                                className="btn agent__filter__btn bg__white"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img
                                  alt="loading..."
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/img/Path 19648.svg"
                                  }
                                />
                                <span>Sort by</span>
                              </button>
                              <div
                                className="dropdown-menu drop myser-mar-l-05"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <a
                                  className="dropdown-item cursorpointer"
                                  onClick={() =>
                                    this.setState({ latest: true })
                                  }
                                >
                                  Latest to Oldest
                                </a>
                                <a
                                  className="dropdown-item cursorpointer"
                                  onClick={() =>
                                    this.setState({ latest: false })
                                  }
                                >
                                  Oldest to Latest
                                </a>
                              </div>
                            </div>

                            <div className="dropdown">
                              <button
                                className="btn agent__filter__btn bg__white"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img
                                  alt="loading..."
                                  src={
                                    process.env.PUBLIC_URL + "/img/Path 210.svg"
                                  }
                                />
                                <span>Filter by</span>
                              </button>
                              <div
                                className="dropdown-menu drop agent__dropdown__filter dash-mar-l-04"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <a
                                  className="dropdown-item cursorpointer"
                                  onClick={() => this.filterItrf("all")}
                                >
                                  All
                                </a>
                                <a
                                  className="dropdown-item cursorpointer"
                                  onClick={() => this.filterItrf(gstr)}
                                >
                                  Pending GSTR
                                </a>
                                <a
                                  className="dropdown-item cursorpointer"
                                  onClick={() => this.filterItrf(gstrf)}
                                >
                                  Pending GSTRF
                                </a>
                                <a
                                  className="dropdown-item cursorpointer"
                                  onClick={() => this.filterItrf(itrf)}
                                >
                                  Pending ITRF
                                </a>
                                <a
                                  className="dropdown-item cursorpointer"
                                  onClick={() => this.filterItrf(tr)}
                                >
                                  Pending TR
                                </a>
                                <a
                                  className="dropdown-item cursorpointer"
                                  onClick={() => this.filterItrf("OA")}
                                >
                                  Pending OA
                                </a>
                                <a
                                  className="dropdown-item cursorpointer"
                                  onClick={() => this.filterItrf("GUMASTA")}
                                >
                                  Pending GUMASTA
                                </a>
                                <a
                                  className="dropdown-item cursorpointer"
                                  onClick={() => this.filterItrf("TL")}
                                >
                                  Pending Trade Licence
                                </a>
                                <a
                                  className="dropdown-item cursorpointer"
                                  onClick={() => this.filterItrf("FL")}
                                >
                                  Pending Food Licence
                                </a>
                                <a
                                  className="dropdown-item cursorpointer"
                                  onClick={() => this.filterExpiring(itrf)}
                                >
                                  Expiring ITRF
                                </a>
                                <a
                                  className="dropdown-item cursorpointer"
                                  onClick={() => this.filterExpiring(gstrf)}
                                >
                                  Expiring GSTRF
                                </a>
                                <a
                                  className="dropdown-item cursorpointer"
                                  onClick={() => this.filterExpiring("OA")}
                                >
                                  Expiring OA
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="pageloader">
                          <center>
                            <img
                              className="loader__image"
                              src={process.env.PUBLIC_URL + "/img/loader.gif"}
                            />
                          </center>
                        </div>

                        <div className="agent__scrolldiv" id="dataDiv">
                          <div className="container-fluid">
                            {!this.state.searchdone ? (
                              <div className="row">{this.showData()}</div>
                            ) : (
                              <div className="row">{this.showSearchdata()}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*  */}
                  <div className="col-md-3  active_doc paddingmarginzero">
                    <div className="mm2 mymm2new">
                    {this.state.supportname != "" && this.state.supportcontact != ""?

                      <div className='supportDetails supportDetailsnew'>
                          <img
                              src={process.env.PUBLIC_URL + "/img/24-7_Support.png"}

                              alt="image"
                          />
                          <div>
                              <h5>{this.state.supportname}</h5>
                              <p>Agent Support Executive</p>
                              <span><b>Contact: </b> +91 {this.state.supportcontact}</span>
                              <p className='mb-1'><b>Working Hours: </b> 11:00 AM - 06:30 PM</p>
                          </div>
                      </div>

                      : null}

                      <center>
                        <button onClick={this.showPromotion} className=" dashBoardBtn">Share Promotional Material</button>
                        <button onClick={this.TrainingMaterial} className="dashBoardBtn mt-4">Training Material</button>
                        <button
                          data-toggle="modal"
                          data-src="https://www.youtube.com/embed/fiC-nHoFJEY"
                          data-target="#youtubeModalmain"
                          className="dashBoardBtn mt-4"
                        >
                          Watch Tutorials
                        </button>
                      </center>
                      <center>
                        <a
                          className="video-btn"
                          data-toggle="modal"
                          data-src="https://www.youtube.com/embed/fiC-nHoFJEY"
                          data-target="#youtubeModalmain"
                        >
                          <img
                            src={process.env.PUBLIC_URL + "/img/play.jpg"}
                            id="play"
                            className="play_img mt-3"
                            alt="image"
                          />
                        </a>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="youtubeModal1"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered yt_modal "
              role="document"
            >
              <div className="modal-content">
                <div className="modal-body modal_b">
                  <button
                    type="button"
                    className="close close1"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      className="embed-responsive-item"
                      id="video"
                      allowscriptaccess="always"
                      src="https://www.youtube.com/embed/nrRu4_aZb8g"
                      allowFullScreen="allowfullscreen"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="youtubeModal2"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered yt_modal "
              role="document"
            >
              <div className="modal-content">
                <div className="modal-body modal_b">
                  <button
                    type="button"
                    className="close close1"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      className="embed-responsive-item"
                      id="video"
                      allowscriptaccess="always"
                      src="https://www.youtube.com/embed/_D-Bh8KkQdw"
                      allowFullScreen="allowfullscreen"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="youtubeModal3"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered yt_modal "
              role="document"
            >
              <div className="modal-content">
                <div className="modal-body modal_b">
                  <button
                    type="button"
                    className="close close1"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      className="embed-responsive-item"
                      id="video"
                      allowscriptaccess="always"
                      src="https://www.youtube.com/embed/qPgrOl_p6xU"
                      allowFullScreen="allowfullscreen"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="youtubeModal4"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered yt_modal "
              role="document"
            >
              <div className="modal-content">
                <div className="modal-body modal_b">
                  <button
                    type="button"
                    className="close close1"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      className="embed-responsive-item"
                      id="video"
                      allowscriptaccess="always"
                      src="https://www.youtube.com/embed/RAOCth_jnIo"
                      allowFullScreen="allowfullscreen"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="youtubeModal5"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered yt_modal "
              role="document"
            >
              <div className="modal-content">
                <div className="modal-body modal_b">
                  <button
                    type="button"
                    className="close close1"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      className="embed-responsive-item"
                      id="video"
                      allowscriptaccess="always"
                      src="https://www.youtube.com/embed/PwBZR3dxP_Y"
                      allowFullScreen="allowfullscreen"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="youtubeModal6"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered yt_modal "
              role="document"
            >
              <div className="modal-content">
                <div className="modal-body modal_b">
                  <button
                    type="button"
                    className="close close1"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      className="embed-responsive-item"
                      id="video"
                      allowscriptaccess="always"
                      src="https://www.youtube.com/embed/PwB3nGW76BU"
                      allowFullScreen="allowfullscreen"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="youtubeModal7"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered yt_modal "
              role="document"
            >
              <div className="modal-content">
                <div className="modal-body modal_b">
                  <button
                    type="button"
                    className="close close1"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      className="embed-responsive-item"
                      id="video"
                      allowscriptaccess="always"
                      src="https://www.youtube.com/embed/i2LAOION1cU"
                      allowFullScreen="allowfullscreen"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="youtubeModalmain"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered yt_modal "
              role="document"
            >
              <div className="modal-content videoModal">
                <div className="modal-body modal_b mt-4">
                  <button
                    type="button"
                    className="close close1 closeMain mr-2"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div className="navigation-tab modalNavigation">
                    <header className="tabs-nav position-relative">
                      <ul className="d-flex p-0 ">
                        <li className=" navigation-tab-li" id="activeTab">
                          <a
                            href="#"
                            id="englishTab"
                            className="px-2 py-1 modalTab activemodalTab"
                            onClick={() => {
                              if (this.state.language != "English") {
                                this.setState({ language: "English" }, () => {
                                  $("#englishTab").addClass("activemodalTab");
                                  $("#hindiTab").removeClass("activemodalTab");
                                });
                              }
                            }}
                          >
                            हिन्दी
                          </a>
                        </li>
                        <li className="navigation-tab-li" id="completedTab">
                          <a
                            href="#"
                            id="hindiTab"
                            className="px-2 py-1 modalTab "
                            onClick={() => {
                              if (this.state.language != "Hindi") {
                                this.setState({ language: "Hindi" }, () => {
                                  $("#hindiTab").addClass("activemodalTab");
                                  $("#englishTab").removeClass(
                                    "activemodalTab"
                                  );
                                });
                              }
                            }}
                          >
                            English
                          </a>
                        </li>
                      </ul>
                    </header>
                  </div>

                  {this.state.language == "English" ? (
                    <div className="row justify-content-center m-0 spMargin">
                      <div className="mm videoDiv">
                        <center>
                          <a
                            className="video-btn"
                            data-toggle="modal"
                            data-src="https://www.youtube.com/embed/nrRu4_aZb8g"
                            data-target="#youtubeModal1"
                            onClick={() => {
                              $("#youtubeModalmain").modal("hide");
                            }}
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/img/Legal251_Agent_Panel_Introduction.png"
                              }
                              id="play"
                              className="play_img playImg2"
                              alt="image"
                            />
                          </a>
                        </center>
                        <p className="videoTitle mb-0">एजेंट पेनल की जानकारी</p>
                      </div>
                      <div className="mm videoDiv">
                        <center>
                          <a
                            className="video-btn"
                            data-toggle="modal"
                            data-src="https://www.youtube.com/embed/_D-Bh8KkQdw"
                            data-target="#youtubeModal2"
                            onClick={() => {
                              $("#youtubeModalmain").modal("hide");
                            }}
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/img/Service_Purchase_for_New_Existing_and_Link_User_.png"
                              }
                              id="play"
                              className="play_img playImg2"
                              alt="image"
                            />
                          </a>
                        </center>
                        <p className="videoTitle mb-0">
                          नए और मौजूदा कस्टमर की सर्विस कैसे परचेस करे ?
                        </p>
                      </div>
                      <div className="mm videoDiv">
                        <center>
                          <a
                            className="video-btn"
                            data-toggle="modal"
                            data-src="https://www.youtube.com/embed/qPgrOl_p6xU"
                            data-target="#youtubeModal3"
                            onClick={() => {
                              $("#youtubeModalmain").modal("hide");
                            }}
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/img/Help_Support__Complain_for_Users.png"
                              }
                              id="play"
                              className="play_img playImg2"
                              alt="image"
                            />
                          </a>
                        </center>
                        <p className="videoTitle mb-0">
                          एजेंट अपने ग्राहकों के लिए सहायता और कम्प्लेन दर्ज
                          कैसे करे
                        </p>
                      </div>
                      <div className="mm videoDiv">
                        <center>
                          <a
                            className="video-btn"
                            data-toggle="modal"
                            data-src="https://www.youtube.com/embed/RAOCth_jnIo"
                            data-target="#youtubeModal4"
                            onClick={() => {
                              $("#youtubeModalmain").modal("hide");
                            }}
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/img/Providing_Fast_Service_to_Walk_in_Customers.png"
                              }
                              id="play"
                              className="play_img playImg2"
                              alt="image"
                            />
                          </a>
                        </center>
                        <p className="videoTitle mb-0">
                          मौजूदा यूजर को फ़ास्ट सर्विसेस कैसे दे ?
                        </p>
                      </div>
                      <div className="mm videoDiv">
                        <center>
                          <a
                            className="video-btn"
                            data-toggle="modal"
                            data-src="https://www.youtube.com/embed/PwBZR3dxP_Y"
                            data-target="#youtubeModal5"
                            onClick={() => {
                              $("#youtubeModalmain").modal("hide");
                            }}
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/img/Upload_Document_in_any_service.png"
                              }
                              id="play"
                              className="play_img playImg2"
                              alt="image"
                            />
                          </a>
                        </center>
                        <p className="videoTitle mb-0">
                          सर्विसेस में डाक्यूमेंट्स कैसे अपलोड करे ?
                        </p>
                      </div>
                      <div className="mm videoDiv">
                        <center>
                          <a
                            className="video-btn"
                            data-toggle="modal"
                            data-src="https://www.youtube.com/embed/PwB3nGW76BU"
                            data-target="#youtubeModal6"
                            onClick={() => {
                              $("#youtubeModalmain").modal("hide");
                            }}
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/img/Help_Support__Complain_for_Agents.png"
                              }
                              id="play"
                              className="play_img playImg2"
                              alt="image"
                            />
                          </a>
                        </center>
                        <p className="videoTitle mb-0">
                          एजेंट अपनी सहायता , सपोर्ट और कम्प्लेन दर्ज करे
                        </p>
                      </div>
                      <div className="mm videoDiv">
                        <center>
                          <a
                            className="video-btn"
                            data-toggle="modal"
                            data-src="https://www.youtube.com/embed/i2LAOION1cU"
                            data-target="#youtubeModal7"
                            onClick={() => {
                              $("#youtubeModalmain").modal("hide");
                            }}
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/img/Create_Password_New_Agent.png"
                              }
                              id="play"
                              className="play_img playImg2"
                              alt="image"
                            />
                          </a>
                        </center>
                        <p className="videoTitle mb-0">
                          नए एजेंट अपना पासवर्ड कैसे बनाये ?
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="row justify-content-center m-0 spMargin">
                      <div className="mm videoDiv">
                        <center>
                          <a
                            className="video-btn"
                            data-toggle="modal"
                            data-src="https://www.youtube.com/embed/nrRu4_aZb8g"
                            data-target="#youtubeModal1"
                            onClick={() => {
                              $("#youtubeModalmain").modal("hide");
                            }}
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/img/Legal251_Agent_Panel_Introduction.png"
                              }
                              id="play"
                              className="play_img playImg2"
                              alt="image"
                            />
                          </a>
                        </center>
                        <p className="videoTitle mb-0">
                          Legal251 Agent Panel Introduction
                        </p>
                      </div>
                      <div className="mm videoDiv">
                        <center>
                          <a
                            className="video-btn"
                            data-toggle="modal"
                            data-src="https://www.youtube.com/embed/_D-Bh8KkQdw"
                            data-target="#youtubeModal2"
                            onClick={() => {
                              $("#youtubeModalmain").modal("hide");
                            }}
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/img/Service_Purchase_for_New_Existing_and_Link_User_.png"
                              }
                              id="play"
                              className="play_img playImg2"
                              alt="image"
                            />
                          </a>
                        </center>
                        <p className="videoTitle mb-0">
                          Service Purchase for New, Existing and Link User
                        </p>
                      </div>
                      <div className="mm videoDiv">
                        <center>
                          <a
                            className="video-btn"
                            data-toggle="modal"
                            data-src="https://www.youtube.com/embed/qPgrOl_p6xU"
                            data-target="#youtubeModal3"
                            onClick={() => {
                              $("#youtubeModalmain").modal("hide");
                            }}
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/img/Help_Support__Complain_for_Users.png"
                              }
                              id="play"
                              className="play_img playImg2"
                              alt="image"
                            />
                          </a>
                        </center>
                        <p className="videoTitle mb-0">
                          Help, Support & Complain for Users
                        </p>
                      </div>
                      <div className="mm videoDiv">
                        <center>
                          <a
                            className="video-btn"
                            data-toggle="modal"
                            data-src="https://www.youtube.com/embed/RAOCth_jnIo"
                            data-target="#youtubeModal4"
                            onClick={() => {
                              $("#youtubeModalmain").modal("hide");
                            }}
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/img/Providing_Fast_Service_to_Walk_in_Customers.png"
                              }
                              id="play"
                              className="play_img playImg2"
                              alt="image"
                            />
                          </a>
                        </center>
                        <p className="videoTitle mb-0">
                          Providing Fast Service to Walk in Customers
                        </p>
                      </div>
                      <div className="mm videoDiv">
                        <center>
                          <a
                            className="video-btn"
                            data-toggle="modal"
                            data-src="https://www.youtube.com/embed/PwBZR3dxP_Y"
                            data-target="#youtubeModal5"
                            onClick={() => {
                              $("#youtubeModalmain").modal("hide");
                            }}
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/img/Upload_Document_in_any_service.png"
                              }
                              id="play"
                              className="play_img playImg2"
                              alt="image"
                            />
                          </a>
                        </center>
                        <p className="videoTitle mb-0">
                          Upload Document in any service
                        </p>
                      </div>
                      <div className="mm videoDiv">
                        <center>
                          <a
                            className="video-btn"
                            data-toggle="modal"
                            data-src="https://www.youtube.com/embed/PwB3nGW76BU"
                            data-target="#youtubeModal6"
                            onClick={() => {
                              $("#youtubeModalmain").modal("hide");
                            }}
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/img/Help_Support__Complain_for_Agents.png"
                              }
                              id="play"
                              className="play_img playImg2"
                              alt="image"
                            />
                          </a>
                        </center>
                        <p className="videoTitle mb-0">
                          Help, Support & Complain for Agents
                        </p>
                      </div>
                      <div className="mm videoDiv">
                        <center>
                          <a
                            className="video-btn"
                            data-toggle="modal"
                            data-src="https://www.youtube.com/embed/i2LAOION1cU"
                            data-target="#youtubeModal7"
                            onClick={() => {
                              $("#youtubeModalmain").modal("hide");
                            }}
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/img/Create_Password_New_Agent.png"
                              }
                              id="play"
                              className="play_img playImg2"
                              alt="image"
                            />
                          </a>
                        </center>
                        <p className="videoTitle mb-0">
                          Create Password New Agent?
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default AgentPanel__dashboard;

import * as base32 from 'hi-base32';
const totp = require("totp-generator");

export function generatetotp(userId, apiparmeter) {
var mainkey=process.env.REACT_APP_TOTOP_KEY+userId+apiparmeter
var base32finalstring= base32.encode(mainkey);

    const token = totp(base32finalstring, {
        digits: 6,
        algorithm: "SHA-512",
        period: 30,
      });
    return token;
  }
  

import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";

import Footer from "./Footer";
import { Link } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import { Redirect } from "react-router";
import HeaderAgent from "./HeaderAgent";


export class Paymentfailed____agent extends Component {
  constructor(props) {
    super(props);

  }
  componentDidMount() {
    window.scrollTo(0, 0);
    $(document.body).css("background-color", "#2d2d2d");
   
    if(this.props.location.state ){
      let data = this.props.location.state.data; 
      this.props.history.replace('/paymentfailed', null);
    }else this.props.history.push("/register")
   
  }
 
  render() {

      return (
        <div className="cont">
          <Helmet>
            <link rel="stylesheet" href={process.env.PUBLIC_URL + "/css/select2.css"} />
          </Helmet>

          <HeaderAgent />

          <img
            src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
            className="sidebaropenicon"
          />
          <img
            src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
            
            className="sidebarbackicon"
            onClick={this.props.history.push("/register")}
          />
          <img
            src={process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"}
            className="sidebarexpandicon"
          />

          <div className="overlay1"></div>
          <div className="sidebar sidebarshift">
            <div className="side_div_2 sidebar_txt active">
              <Link to="/dashboard">Overview</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <Link to="/myservices">My Services</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <a href="#">Payments Invoices</a>
            </div>
            <div className="side_div_1 sidebar_txt">
              <a href="#">Documents Record</a>
            </div>
            <div className="side_div_1 sidebar_txt">
              <a href="#">Add service</a>
            </div>
            <div></div>
          </div>
          <div className="arrow-left2 visibilityhidden"></div>
          <div className="white_div expandwhite_div">
            <div className="white_box">
              <div className="white_boxscrolldiv">
                <center>
                  <p className="oopsheading">Oops!</p>
                  <p className="paymentfailheading">Payment failed!</p>
                  <p className="paymentfailline">
                    "If any amount is deducted from your side,
                    <br /> it'll be credited back with in 7 days."
                  </p>
                  <img
                    src={process.env.PUBLIC_URL + "/img/paymentfail.svg"}
                    className="paymentfailimg"
                  />
                </center>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      );

  }
}

export default Paymentfailed____agent;

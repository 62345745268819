import React, { Component } from "react";
import { Helmet } from "react-helmet";
import ReCAPTCHA from "react-google-recaptcha";
import $ from "jquery";
import axios from "axios";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);
let Decuserid;
let Decagentid;
let id4;
let id1;
let id5;
let clrinterval = false;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile_input: "",
      password_input: "",
      readonly_input: "",
      captcha: false,
      m: 0,
      status: 0,
      agentId: "",
      date: "",
      mobtime: 60,
      requestid: "",
      mobileotp: "",
    };
  }
  countdown() {
    this.setState({ rtime: 60 });
    $("#mobileresend-loader").hide();
    function clear() {
      clearInterval(interval);
      $("#mobileresend-loader").hide();
    }
    let interval = setInterval(() => {
      if (this.state.rtime == 1 || clrinterval) {
        clear(interval);
        $("#mobileresend-loader").hide();
      } else {
      }
      this.setState({ rtime: this.state.rtime - 1 });
    }, 1000);
  }
  resendusermobOtp = () => {
    if(this.state.mobtime == "0"){
      this.setState({ mobtime: 60 });
    }
    
    $("#invalidtempusermobileotp").hide();
    $("#invalidtempuseremailotp").hide();
    $("#otpsentsuccesstemp").hide();

    if (this.state.mobtime == 0) {
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=accountRecoveryotp",
          {
            agentId: this.state.agentId,
          },
          {
            headers: { Authkey: process.env.REACT_APP_API_KEY },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == 200 && res.data.status == "success") {
            $("#otpsentsuccesstemp").show();
            this.setState({ mobtime: 60 }, this.mobcountdown);
          } else if (res.data.code == 201 && res.data.status == "fail") {
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          }
        })
        .catch((err) => {
          //
          this.showNotification("Something went wrong");
          // $(".sendOtpLoader").hide();
          //   $(".sendOtpBtn").show();
        });
    } else {
      return null;
    }
  };
  mobcountdown() {
    this.setState({ mobtime: 60 });
    $("#mobileresend-loader").hide();
    function clear() {
      clearInterval(interval);
      $("#mobileresend-loader").hide();
    }
    let interval = setInterval(() => {
      if (this.state.mobtime == 1 || clrinterval) {
        clear(interval);
        $("#mobileresend-loader").hide();
      } else {
      }
      this.setState({ mobtime: this.state.mobtime - 1 });
    }, 1000);
  }
  componentDidMount() {
    $(document.body).css("background-color", "#fff");
    window.scrollTo(0, 0);
    document.querySelector("head").innerHTML +=
      '<link rel="stylesheet" href="/css/loginL.css" type="text/css"/>';
    document.querySelector("head").innerHTML +=
      '<link rel="stylesheet" href="/css/loginWithPss.css" type="text/css"/>';

    const queryParams = new URLSearchParams(window.location.search);
    id1 = queryParams.get("partnerid");
    id4 = queryParams.get("agentId");
    id5 = queryParams.get("token");
    if (id1 != null) {
      this.setState({
        mobile_input: id1,
      });
    } else if (id4 != null && id5 != null) {
      this.dataHit();
    }
  }

  dataHit = () => {
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=accessToken",
        {
          AccessID: id4,
          Token: id5,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == 200 && res.data.status == "success") {
          localStorage.setItem(
            "qazxswedcvfrtgb",
            encryptor.encrypt(res.data.agentId)
          );
          localStorage.setItem(
            "toeljgtkewlna",
            encryptor.encrypt(res.data.jwt)
          );
          localStorage.setItem("qwedfgewerewrwer", res.data.logo);
          localStorage.setItem(
            "gglltakoinoeenl",
            process.env.REACT_APP_LOGIN_KEY
          );
          this.props.history.push({
            pathname: `/dashboard`,
          });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((error) => {});
  };

  handleContact = (e) => {
    $(".error").hide();
    this.setState({
      mobile_input: e.target.value.trim(),
    });
  };

  handlePassword = (e) => {
    $(".error").hide();
    this.setState({
      password_input: e.target.value,
    });
  };

  handleReadonly_input = (e) => {
    this.setState({
      readonly_input: e.target.value.trim(),
    });
  };

  switchDiv = (e) => {
    $(".box2").addClass("animate__animated animate__fadeIn");
    $(".box2").show();
    $(".box1").hide();
  };

  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 10000);
    setTimeout(() => $(notif).remove(), 10100);
  };
  // verify login
  handleLoginBtn = (e) => {
    e.preventDefault();
    if (this.state.mobile_input.trim() == "") {
      $(".inp_l").css("border-color", "red");
      $(".error").show();
      $(".error_01").html("Required fields cannot be empty!");
    } else if (!this.state.mobile_input.match(/^.{1,10}$/)) {
      $(".inp_l").css("border-color", "red");
      $(".error").show();
      $(".error_01").html("Please enter a Partner ID");
    } else {
      $(".login_btn").addClass("bg-loader");
      $(".login_btn").removeClass("bg-arrow");
      $(".login_btn").attr("disabled", true);

      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=checkLogin",
          {
            agentId: this.state.mobile_input.toUpperCase(),
          },
          {
            headers: { Authkey: process.env.REACT_APP_API_KEY },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == 200 && res.data.status == "success") {
            $(".error").hide();
            this.setState(
              {
                readonly_input: this.state.mobile_input.toUpperCase(),
                status: res.data.status,
              },
              () => {
                this.switchDiv();
              }
            );
          } else if (res.data.code == 201 && res.data.status == "fail") {
            $(".login_btn").removeClass("bg-loader");
            $(".login_btn").addClass("bg-arrow");
            $(".login_btn").attr("disabled", false);
            $(".error").show();
            $(".error_01").html(res.data.message);
          } else if (res.data.code == 201 && res.data.status == "trouble") {
            $(".login_btn").removeClass("bg-loader");
            $(".login_btn").addClass("bg-arrow");
            $(".login_btn").attr("disabled", false);
            $(".error").show();
            $(".error_01").html(res.data.message);
          } else if (
            res.data.status == 202 &&
            res.data.message == "Delete Request Active"
          ) {
            const deletionDate = new Date(res.data.deletedDateTime);

            // Calculate the days left for permanent account deletion
            const currentDate = new Date();
            const daysLeft = Math.floor(
              (deletionDate - currentDate) / (24 * 60 * 60 * 1000)
            );

            // Update component state with the calculated days left
            this.setState({ daysLeft });
            this.setState({
              // readonly_input: this.state.mobile_input.toUpperCase(),
              status: res.data.status,
              agentId: res.data.AgentId,
              date: res.data.deletedDateTime,
            });
            $(".accountrecovery").show();
            $(".logindiv").hide();
            $(".login_btn").removeClass("bg-loader");
            $(".login_btn").addClass("bg-arrow");
            $(".login_btn").attr("disabled", false);
            $(".error").show();
            $(".error_01").html(res.data.message);
          }
        })
        .catch((err) => {});
    }
  };

  showPassword = (e) => {
    e.preventDefault();
    if (this.state.m == 0) {
      $(".pss").attr("type", "text");
      $("#btn_pass, .btn_1 ").css({
        background: '#fff url("/img/hidden.svg")',
        "background-repeat": "no-repeat",
        "background-size": "22px 22px",
        "background-position": "center",
      });
      this.setState({
        m: 1,
      });
    } else {
      $(".pss").attr("type", "password");
      $("#btn_pass, .btn_1 ").css({
        background: '#fff url("/img/show.svg")',
        "background-repeat": "no-repeat",
        "background-size": "22px 22px",
        "background-position": "center",
      });
      this.setState({
        m: 0,
      });
    }
  };

  // captcha
  handleCaptcha = (value) => {
    this.setState({
      captcha: true,
    });
  };

  // login
  handleSubmit = (e) => {
    if (this.state.password_input.trim() == "") {
      $(".border_3").css("border-color", "red");
      $(".error").show();
      $(".error_login").html("Required fields cannot be empty!");
    } else if (!this.state.captcha) {
      $(".error").show();
      $(".error_login").html("Captcha required!");
    } else {
      $(".submit").hide();
      $(".submit-loader").show();
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=login",
          {
            agentId: this.state.mobile_input.toUpperCase(),
            password: this.state.password_input.trim(),
          },
          {
            headers: { Authkey: process.env.REACT_APP_API_KEY },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == 200 && res.data.status == "success") {
            localStorage.setItem("sideMenuAccess", encryptor.encrypt(res.data.agentType));
            localStorage.setItem(
              "qazxswedcvfrtgb",
              encryptor.encrypt(res.data.agentId)
            );
            localStorage.setItem(
              "toeljgtkewlna",
              encryptor.encrypt(res.data.jwt)
            );
            localStorage.setItem("qwedfgewerewrwer", res.data.logo);
            localStorage.setItem(
              "gglltakoinoeenl",
              process.env.REACT_APP_LOGIN_KEY
            );
            this.props.history.push({
              pathname: `/dashboard`,
            });
          } else if (res.data.code == 201 && res.data.status == "invalid") {
            $(".error").show();
            $(".error_login").html("Invalid login credentials");
            $(".submit").show();
            $(".submit-loader").hide();
          } else if (res.data.code == 201 && res.data.status == "fail") {
            $(".error").show();
            $(".error_login").html("Something went wrong");
            $(".submit").show();
            $(".submit-loader").hide();
          }
        })
        .catch((err) => {});
    }
  };
  SubmitOTP = () => {
    $("#invalidtempusermobileotp").hide();
    $("#invalidtempuseremailotp").hide();
    $("#otpsentsuccesstemp").hide();
    if (this.state.mobileotp.trim() == "") {
      $(".invalidmobile").html("Please enter mobile OTP");
      $(".invalidemail").html("Please enter email OTP");
      $("#invalidtempusermobileotp").show();
      $("#invalidtempuseremailotp").show();
    } else if (
      this.state.mobileotp.trim() == "" ||
      this.state.mobileotp.length > 6 ||
      this.state.mobileotp.length < 6 ||
      isNaN(this.state.mobileotp)
    ) {
      $(".invalidmobile").html("Please enter valid mobile OTP");
      $("#invalidtempusermobileotp").show();
    } else {
      // $("#otpmodalagentservices").hide();
      $(".sendOtpLoader").show();
      $("#submitOtpbtn").hide();
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=accountrecoveryverifyotp",

          {
            agentId: this.state.agentId,
            // requestid: this.state.requestid,
            otp: this.state.mobileotp.trim(),
          },
          {
            headers: { Authkey: process.env.REACT_APP_API_KEY },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == 200 && res.data.status == "success") {
            $("#Temp-User").modal("hide")
            $(".logindiv").show();
            this.showNotification(res.data.message);
            
            this.setState({
              status: res.data.code
            },() => {
              this.props.history.push({
                pathname: `/`,
  
              });
            })

            
          } else if (
            res.data.code == 201 &&
            res.data.status == "contactotpinvalid"
          ) {
            $(".invalidmobile").html("Please enter valid mobile OTP");
            $("#invalidtempusermobileotp").show();
            $(".sendOtpLoader").hide();
            $("#submitOtpbtn").show();
          } else if (
            res.data.code == 201 &&
            res.data.status == "bothotpinvalid"
          ) {
            $(".invalidmobile").html("Please enter valid mobile OTP");
            $("#otpmodalagentservices").show();
            $("#invalidtempusermobileotp").show();
            $("#invalidtempuseremailotp").show();
            $(".sendOtpLoader").hide();
            $("#submitOtpbtn").show();
          } else if (res.data.code == 201 && res.data.status == "fail") {
            this.showNotification(res.data.message);
            // $("#agentuserinfomodal").hide();
            // $("#otpmodalagentservices").hide();
            $(".sendOtpLoader").hide();
            $("#submitOtpbtn").show();
            // $("#unsuccess-modal").show();
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else {
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          //
          this.showNotification("Something went wrong");
          $(".sendOtpLoader").hide();
          $("#submitOtpbtn").show();
          // $(".sendOtpLoader").hide();
          //   $(".sendOtpBtn").show();
        });
    }
  };
  AcceptRecovery = () => {
    $(".proceednowbutton").hide();
    $(".addserviceloader").show();
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=accountRecoveryotp",
        {
          agentId: this.state.agentId,
        },
        {
          headers: { Authkey: process.env.REACT_APP_API_KEY },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )

      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          $(".addserviceloader").hide();

          $(".proceednowbutton").show();
          this.setState({ requestid: res.data.requestid });
          this.mobcountdown();
          $("#Temp-User").modal({ backdrop: "static", keyboard: false });
          $(".sendOtpBtn").show();
          $("#agentuserinfomodal").show();
          $("#otpmodalagentservices").hide();
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          $(".proceednowbutton").show();
          $(".sendOtpLoader").hide();
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {});
  };

  render() {
    if (
      localStorage.getItem("gglltakoinoeenl") ==
        process.env.REACT_APP_LOGIN_KEY &&
      localStorage.getItem("toeljgtkewlna") != null &&
      localStorage.getItem("qazxswedcvfrtgb") != null
    ) {
      return <Redirect to="/dashboard" />;
    } else {
      return (
        <div className="phone">
          <div id="notifContainer"></div>
          <div className="row pd1 pc">
            <div className="col-xs-7 cl7 left-div">
              <center className="cnt_margin left-div-body">
                <a href="https://legal251.com/">
                  <img
                    src={process.env.PUBLIC_URL + "/img/Legal_logo_full.svg"}
                    className="legal_logo"
                    alt="legal logo"
                  />
                </a>
                <div className="login-iframe">
                  <iframe
                    width="1019"
                    height="auto"
                    src="https://www.youtube.com/embed/UG7VDgRJTcY"
                    title="Legal251 Partner Program"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen="allowfullscreen"
                  ></iframe>
                </div>
              </center>
            </div>
            <div className="col-xs-5 bg-2 d-md-flex justify-content-center align-items-center">
              <div className="logindiv">
                <Link to="/register" className="registerlink">
                  Register
                </Link>
                <center>
                  <div className="top-text">
                    <p>Call on 77728-77729 to join Partner Program</p>
                  </div>
                  {/* <!-- legal logo --> */}
                  <a href="https://legal251.com/">
                    <img
                      src={process.env.PUBLIC_URL + "/img/Legal_logo_full.svg"}
                      className="legal_logo mobile-res-data"
                      alt="legal logo"
                    />
                  </a>

                  <div className="y-box">
                    {/* <!-- login --> */}
                    <p className="text-center login-txt">Login</p>
                    <div className="box1 pb-5">
                      <div className="input-group">
                        <p className="border_txt"> Partner ID</p>
                        <input
                          type="text"
                          id="user"
                          maxLength="50"
                          className="form-control inp_l mb-2"
                          placeholder="Type here..."
                          value={this.state.mobile_input}
                          onChange={this.handleContact}
                        />
                        <div className="input-group-append">
                          <button
                            className="login_btn bg-arrow"
                            onClick={this.handleLoginBtn}
                            type="submit"
                          ></button>
                        </div>
                      </div>
                      <center>
                        <Link to="/forgotpassword" className="forgot_p">
                          Forgot Password?
                        </Link>
                      </center>
                      {/* <!-- error message --> */}
                      <div className="error_div">
                        <p className="error text-center error_01 .mt-n3">
                          This is the demo error message
                        </p>
                      </div>
                    </div>
                    {/* <!-- login with pass --> */}
                    <div className="box2">
                      {/* <!-- login --> */}
                      <div className="border_2">
                        <p className="border_txt">Partner ID</p>
                        <input
                          type="text"
                          className="form-control inp_l1"
                          id="user2"
                          readOnly
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          value={this.state.readonly_input}
                          onChange={this.handleReadonly_input}
                        />
                      </div>
                      {/* <!-- password --> */}
                      <div className="input-group border_3">
                        <p className="border_txt1">Enter password</p>
                        <input
                          type="password"
                          className="form-control inp_l2 pss"
                          maxLength="30"
                          placeholder="**********"
                          id="password"
                          value={this.state.password_input}
                          onChange={this.handlePassword}
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                        />
                        <div className="input-group-append">
                          <button
                            className="login_btnp btn_1"
                            id="btn_pass"
                            onClick={this.showPassword}
                            type="button"
                          ></button>
                        </div>
                      </div>
                      {/* <!-- forgot password --> */}
                      <center>
                        <Link to="/forgotpassword" className="forgot_p">
                          Forgot Password?
                        </Link>
                      </center>
                      {/* <!-- captacha --> */}
                      <form className="pt-2 g-recaptcha-div">
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_CAPCHASITEKEY}
                          secretkey={process.env.REACT_APP_CAPCHASECRETKEY}
                          onChange={this.handleCaptcha}
                          className="g-recaptcha"
                        />
                      </form>
                      {/* <!-- error message --> */}
                      <div className="error_div1">
                        <p className="error text-center error_login">
                          This is the demo error message
                        </p>
                      </div>
                      {/* <!-- submit button --> */}
                      <center>
                        <button className="submit" onClick={this.handleSubmit}>
                          Submit
                        </button>
                        <img
                          className="submit-loader"
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loader"
                        />
                      </center>
                    </div>
                  </div>

                  <p class="downloadtext">
                    Download <span>Legal251 Partners</span> App
                  </p>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.legal251.partners"
                    class="googleplaybutton"
                  >
                    <p>
                      <img
                        src="https://legal251.com/images/google_play-icon.svg"
                        class="googleplayimg"
                      />
                      <span class="getittext">
                        <span class="getitspan">GET IT ON</span>
                        <br />
                        Google Play
                      </span>
                    </p>
                  </a>

                  {/* <!-- legal image --> */}

                  <div className="login-iframe login_img mobile-res-data">
                    <iframe
                      width="1019"
                      height="573"
                      src="https://www.youtube.com/embed/UG7VDgRJTcY"
                      title="Legal251 Partner Program"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                </center>
              </div>

              {this.state.status == 202 ? (
                <div className="accountrecovery">
                  <h2 className="font-weight-bold text-center downloadaccount">
                    Account Recovery
                  </h2>
                  <div className="my-4">
                    <p className=" text-center" style={{ fontWeight: "400" }}>
                      We received a request to delete your account on Date{" "}
                      {this.state.date} still you can recover your account.{" "}
                      <span className="text-danger">
                        {this.state.daysLeft} Days left for permanent account deletion.
                      </span>
                    </p>
                  </div>

                  <h2 className="font-weight-bold text-center my-4 downloadaccount ">
                    Do you want to recover your account
                  </h2>

                  <div className="d-flex " style={{ fontWeight: "400" }}>
                    <span>Note:- </span>
                    <p className="">
                      You can not create new account until your old account is
                      permanently deleted.
                    </p>
                  </div>

                  <div className="text-center">
                    <img
                      src="/img/ic_recovery.svg"
                      alt="recovery"
                      className="text-success"
                      style={{ width: "35%" }}
                    />
                    <h3>Account recovery</h3>
                  </div>

                  <div className="delmodalbtndiv">
                    {/* <div className="row">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6"> */}
                      <button
                      className="conformdeletebtn px-5 text-white border bg-success"
                      onClick={() => this.AcceptRecovery()}
                    >
                      Yes
                    </button>
                      {/* </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6"> */}
                      <button
                      className="conformdeletebtn px-5 text-white border bg-danger"
                      onClick={() => {
                        $(".logindiv").show();
                        $(".accountrecovery").hide();
                        // setnewCustomerId("");
                        // setnewBusinessId("");
                      }}
                    >
                      No
                    </button>
                      {/* </div> */}
                    
                    
                    {/* </div> */}
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="modal fade" id="Temp-User">
            <div
              className="modal-dialog modal-dialog-centered "
              role="document"
            >
              <div className="modal-content back-modal" id="agentuserinfomodal">
                <div className="w-100 d-flex">
                  <button
                    type="button"
                    className="border-0 bg-transparent ml-auto p-2 pr-3  text-right my-back-modal-close"
                    data-dismiss="modal"
                    onClick={() => {
                      this.setState({ rtime: 1 });
                      $("#invalidagentuserinfoinput").hide();
                    }}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/img/Page-1.svg"}
                      width="17px"
                    />
                  </button>
                </div>
                <div className="text-center">
                  <h4 className="my-linkmodal-text enterotp">Enter OTP</h4>
                </div>
                <div className="input-userinfo otpinput emailotp">
                  <h5 className="otpinput-head">
                    Enter OTP sent to user's mobile
                  </h5>
                  <input
                    className="Userinfo-input otp-input"
                    maxLength="6"
                    type="tel"
                    value={this.state.mobileotp}
                    onChange={(e) =>
                      this.setState({ mobileotp: e.target.value })
                    }
                    id="userinfoinput"
                    placeholder="Enter OTP here..."
                  />
                  <p
                    className=" mt-2 warn align-items-center m-0"
                    id="invalidtempusermobileotp"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/Icon_feather-alert-circle.svg"
                      }
                      width="15px"
                      className=" mr-1"
                    />{" "}
                    <span className="invalidmobile"></span>
                  </p>
                </div>
                <div className="d-flex justify-content-between  otpmodaloptions">
                  <h6
                    className="mob-text-muted mobresendOTP"
                    id=""
                    onClick={this.resendusermobOtp}
                  >
                    Resend OTP{" "}
                    {this.state.mobtime != 0
                      ? `(${this.state.mobtime} Sec)`
                      : " "}
                  </h6>
                </div>
                {/* <div className="input-userinfo otpinput emailotp">
                  <h5 className="otpinput-head">
                    Enter OTP sent to user's email
                  </h5>
                  <input
                    className="Userinfo-input otp-input "
                    maxLength="6"
                    type="tel"
                    value={this.state.emailotp}
                    onChange={(e) =>
                      this.setState({ emailotp: e.target.value })
                    }
                    id="userinfoinput"
                    placeholder="Enter OTP here..."
                  />
                  <p
                    className=" mt-2 warn align-items-center m-0"
                    id="invalidtempuseremailotp"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/Icon_feather-alert-circle.svg"
                      }
                      width="15px"
                      className=" mr-1"
                    />{" "}
                    <span className="invalidemail"></span>
                  </p>
                </div>

                <div className="d-flex justify-content-between  otpmodaloptions">
                  <h6
                    className="email-text-muted emailresendOTP"
                    id=""
                    onClick={this.resenduseremailOtp}
                  >
                    Resend OTP{" "}
                    {this.state.emailtime != 0
                      ? `(${this.state.emailtime} Sec)`
                      : " "}
                  </h6>
                </div> */}
                <p className="" id="otpsentsuccesstemp">
                  OTP sent successfully{" "}
                </p>
                <div className="modal-footer border-0 justify-content-center mb-4">
                  <img
                    className=" p-2 pl-3 pr-3 sendOtpLoader"
                    src="/img/loader.gif"
                    width="80px"
                    alt=""
                  />
                  <button
                    type="button"
                    onClick={this.SubmitOTP}
                    className="btn  send-btn text-white"
                    id="submitOtpbtn"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Login;

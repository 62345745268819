import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { Link } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import { Redirect } from "react-router";
import AllPricing from "../data.json";
import json2 from "../State_shortcode.json";

let Decuserid;
let Decagentid;
let Decjwttoken;
var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var fullname = "";
var citydistrict = "";
var servicename = "";
var servicestate = "";
var upgradeplan = false;
var serviceid;
var data = [];
let supportName;
let supportContact;
let supportEmail;
let pricingJson;
var encryptor = require("simple-encryptor")(key);

class Addserviceitrf extends Component {
  constructor(props) {
    super(props);

    this.state = {
      serviceselectvalue: "",
      mainplan: "",
      mainduration: "",
      mainpackage: "S",
      citydistrictselectvalue: "",
      servicefullname: "",
      firstdropdownvalue: "Y",
      seconddropdownvalue: "Y",
      thirddropdownvalue: "Y",
      fourdropdownvalue: "Y",
      fivedropdownvalue: "Y",
      sixdropdownvalue: "Y",
      sevendropdownvalue: "Y",
      eightdropdownvalue: "Y",
      ninedropdownvalue: "Y",
      supportName:"",
      supportContact:"",
      supportEmail:"",

      // Salaried Person  //

      // STARTER //

      // Without GST Price
      ITRFSSY: "",
      ITRFSSY2: "",

      // No Discount Price
      ITRFSSYnodis: "",
      ITRFSSY2nodis: "",

      // Discount Percentage
      ITRFSSYDisPer: "",
      ITRFSSY2DisPer: "",

      // BASIC  //

      // Without GST Price
      ITRFSBY: "",
      ITRFSBY2: "",

      // No Discount Price
      ITRFSBYnodis: "",
      ITRFSBY2nodis: "",

      // Discount Percentage
      ITRFSBYDisPer: "",
      ITRFSBY2DisPer: "",

      // PRO //

      // Without GST Price
      ITRFSPY: "",
      ITRFSPY2: "",

      // No Discount Price
      ITRFSPYnodis: "",
      ITRFSPY2nodis: "",

      // Discount Percentage
      ITRFSPYDisPer: "",
      ITRFSPY2DisPer: "",

      // PREMIUM //

      // Without GST Price
      ITRFSPRY: "",
      ITRFSPRY2: "",

      // No Discount Price
      ITRFSPRYnodis: "",
      ITRFSPRY2nodis: "",

      // Discount Percentage
      ITRFSPRYDisPer: "",
      ITRFSPRY2DisPer: "",

      // Business / Profession //

      // STARTER //

      // Without GST Price
      ITRFBSY: "",
      ITRFBSY2: "",

      // No Discount Price
      ITRFBSYnodis: "",
      ITRFBSY2nodis: "",

      // Discount Percentage
      ITRFBSYDisPer: "",
      ITRFBSY2DisPer: "",

      // BASIC //

      // Without GST Price
      ITRFBBY: "",
      ITRFBBY2: "",

      // No Discount Price
      ITRFBBYnodis: "",
      ITRFBBY2nodis: "",

      // Discount Percentage
      ITRFBBYDisPer: "",
      ITRFBBY2DisPer: "",

      // PRO //

      // Without GST Price
      ITRFBPY: "",
      ITRFBPY2: "",

      // No Discount Price
      ITRFBPYnodis: "",
      ITRFBPY2nodis: "",

      // Discount Percentage
      ITRFBPYDisPer: "",
      ITRFBPY2DisPer: "",

      // PRO+ //

      // Without GST Price
      ITRFBPPY: "",
      ITRFBPPY2: "",

      ITRFBPPYnodis: "",
      ITRFBPPY2nodis: "",

      // Discount Percentage
      ITRFBPPYDisPer: "",
      ITRFBPPY2DisPer: "",

      // PREMIUM //

      // Without GST Price
      ITRFBPRY: "",
      ITRFBPRY2: "",

      // No Discount Price
      ITRFBPRYnodis: "",
      ITRFBPRY2nodis: "",

      // Discount Percentage
      ITRFBPRYDisPer: "",
      ITRFBPRY2DisPer: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    $(document.body).css("background-color", "#2d2d2d");
    let mainuserid = localStorage.getItem("relleIsugad");
    Decuserid = encryptor.decrypt(mainuserid);
    let mainjwttoken = localStorage.getItem("toeljgtkewlna");
    let mainagentid = localStorage.getItem("qazxswedcvfrtgb");
    Decagentid = encryptor.decrypt(mainagentid);
    Decjwttoken = encryptor.decrypt(mainjwttoken);
    let mainfullname = sessionStorage.getItem("ielndmlgeaefelvsdrlucaa");
    let maincitydistrict = sessionStorage.getItem("ylcvciretaireditdgisdtacesl");
    let mainservicename = sessionStorage.getItem("srleleddgaeaemvcnia");
    let user__state = sessionStorage.getItem("hjtsrevanxckawshdyeh");
    let mainserviceid = sessionStorage.getItem("ptfgfgjhlqwemcggdfg");
    let mainupgradeplan = sessionStorage.getItem("lashkjdasfurtghghj");
    fullname = encryptor.decrypt(mainfullname);
    citydistrict = encryptor.decrypt(maincitydistrict);
    servicename = encryptor.decrypt(mainservicename);
    servicestate = encryptor.decrypt(user__state);
    serviceid = encryptor.decrypt(mainserviceid);
    upgradeplan = encryptor.decrypt(mainupgradeplan);
    if (upgradeplan != null) {
      if (upgradeplan == true) {
        upgradeplan = true
      }
      else {
        upgradeplan = false
      }
    }
    else {
      upgradeplan = false
    }
    this.setState({
      citydistrictselectvalue: citydistrict,
      servicefullname: fullname,
      serviceselectvalue: servicename,
    });
    supportName = localStorage.getItem("supportName");
    supportContact = localStorage.getItem("supportContact");
    supportEmail = localStorage.getItem("supportEmail");
    
    this.setState({
      supportName:supportName,
      supportContact:supportContact,
      supportEmail:supportEmail,
    })

    fetch(process.env.REACT_APP_API_PRICING_JSON_URL)
        .then((response) => response.json())
        .then((data) => {
          // Store the JSON data in localStorage
          localStorage.setItem("lshhihfnkff", JSON.stringify(data));
          this.priceUpdate();
        })
        .catch((error) => console.error("Error fetching data:", error));
  }

  priceUpdate = () => {
    pricingJson = JSON.parse(localStorage.getItem("lshhihfnkff"));

    // Salaried Person

    // STARTER

    let ITRFSSY = pricingJson["ITRFSSY"].price;
    let ITRFSSY2 = pricingJson["ITRFSSY2"].price;

    // No Discount Price
    let ITRFSSYnodi = pricingJson["ITRFSSY"].nodiscountprice;
    let ITRFSSY2nodi = pricingJson["ITRFSSY2"].nodiscountprice;

    // Discount Percentage
    let ITRFSSYDisPe = pricingJson["ITRFSSY"].discountpercentage;
    let ITRFSSY2DisPe = pricingJson["ITRFSSY2"].discountpercentage;

    this.setState({
      ITRFSSY: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(ITRFSSY * 0.18 + ITRFSSY)),
      ITRFSSY2: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(ITRFSSY2 * 0.18 + ITRFSSY2)),

      // No Discount Price
      ITRFSSYnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(ITRFSSYnodi),
      ITRFSSY2nodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(ITRFSSY2nodi),

      // Discount Percentage
      ITRFSSYDisPer: ITRFSSYDisPe,
      ITRFSSY2DisPer: ITRFSSY2DisPe,
    });

    // BASIC

    let ITRFSBY = pricingJson["ITRFSBY"].price;
    let ITRFSBY2 = pricingJson["ITRFSBY2"].price;

    // No Discount Price
    let ITRFSBYnodi = pricingJson["ITRFSBY"].nodiscountprice;
    let ITRFSBY2nodi = pricingJson["ITRFSBY2"].nodiscountprice;

    // Discount Percentage
    let ITRFSBYDisPe = pricingJson["ITRFSBY"].discountpercentage;
    let ITRFSBY2DisPe = pricingJson["ITRFSBY2"].discountpercentage;

    this.setState({
      ITRFSBY: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(ITRFSBY * 0.18 + ITRFSBY)),
      ITRFSBY2: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(ITRFSBY2 * 0.18 + ITRFSBY2)),

      // No Discount Price
      ITRFSBYnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(ITRFSBYnodi),
      ITRFSBY2nodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(ITRFSBY2nodi),

      // Discount Percentage
      ITRFSBYDisPer: ITRFSBYDisPe,
      ITRFSBY2DisPer: ITRFSBY2DisPe,
    });

    // PRO

    let ITRFSPY = pricingJson["ITRFSPY"].price;
    let ITRFSPY2 = pricingJson["ITRFSPY2"].price;

    // No Discount Price
    let ITRFSPYnodi = pricingJson["ITRFSPY"].nodiscountprice;
    let ITRFSPY2nodi = pricingJson["ITRFSPY2"].nodiscountprice;

    // Discount Percentage
    let ITRFSPYDisPe = pricingJson["ITRFSPY"].discountpercentage;
    let ITRFSPY2DisPe = pricingJson["ITRFSPY2"].discountpercentage;

    this.setState({
      ITRFSPY: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(ITRFSPY * 0.18 + ITRFSPY)),
      ITRFSPY2: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(ITRFSPY2 * 0.18 + ITRFSPY2)),

      // No Discount Price
      ITRFSPYnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(ITRFSPYnodi),
      ITRFSPY2nodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(ITRFSPY2nodi),

      // Discount Percentage
      ITRFSPYDisPer: ITRFSPYDisPe,
      ITRFSPY2DisPer: ITRFSPY2DisPe,
    });

    // PREMIUM

    let ITRFSPRY = pricingJson["ITRFSPRY"].price;
    let ITRFSPRY2 = pricingJson["ITRFSPRY2"].price;

    // No Discount Price
    let ITRFSPRYnodi = pricingJson["ITRFSPRY"].nodiscountprice;
    let ITRFSPRY2nodi = pricingJson["ITRFSPRY2"].nodiscountprice;

    // Discount Percentage
    let ITRFSPRYDisPe = pricingJson["ITRFSPRY"].discountpercentage;
    let ITRFSPRY2DisPe = pricingJson["ITRFSPRY2"].discountpercentage;

    this.setState({
      ITRFSPRY: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(ITRFSPRY * 0.18 + ITRFSPRY)),
      ITRFSPRY2: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(ITRFSPRY2 * 0.18 + ITRFSPRY2)),

      // No Discount Price
      ITRFSPRYnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(ITRFSPRYnodi),
      ITRFSPRY2nodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(ITRFSPRY2nodi),

      // Discount Percentage
      ITRFSPRYDisPer: ITRFSPRYDisPe,
      ITRFSPRY2DisPer: ITRFSPRY2DisPe,
    });

    // Business / Profession

    // STARTER

    let ITRFBSY = pricingJson["ITRFBSY"].price;
    let ITRFBSY2 = pricingJson["ITRFBSY2"].price;

    // No Discount Price
    let ITRFBSYnodi = pricingJson["ITRFBSY"].nodiscountprice;
    let ITRFBSY2nodi = pricingJson["ITRFBSY2"].nodiscountprice;

    // Discount Percentage
    let ITRFBSYDisPe = pricingJson["ITRFBSY"].discountpercentage;
    let ITRFBSY2DisPe = pricingJson["ITRFBSY2"].discountpercentage;

    this.setState({
      ITRFBSY: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(ITRFBSY * 0.18 + ITRFBSY)),
      ITRFBSY2: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(ITRFBSY2 * 0.18 + ITRFBSY2)),

      // No Discount Price
      ITRFBSYnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(ITRFBSYnodi),
      ITRFBSY2nodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(ITRFBSY2nodi),

      // Discount Percentage
      ITRFBSYDisPer: ITRFBSYDisPe,
      ITRFBSY2DisPer: ITRFBSY2DisPe,
    });

    // BASIC

    let ITRFBBY = pricingJson["ITRFBBY"].price;
    let ITRFBBY2 = pricingJson["ITRFBBY2"].price;

    // No Discount Price
    let ITRFBBYnodi = pricingJson["ITRFBBY"].nodiscountprice;
    let ITRFBBY2nodi = pricingJson["ITRFBBY2"].nodiscountprice;

    // Discount Percentage
    let ITRFBBYDisPe = pricingJson["ITRFBBY"].discountpercentage;
    let ITRFBBY2DisPe = pricingJson["ITRFBBY2"].discountpercentage;

    this.setState({
      ITRFBBY: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(ITRFBBY * 0.18 + ITRFBBY)),
      ITRFBBY2: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(ITRFBBY2 * 0.18 + ITRFBBY2)),

      // No Discount Price
      ITRFBBYnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(ITRFBBYnodi),
      ITRFBBY2nodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(ITRFBBY2nodi),

      // Discount Percentage
      ITRFBBYDisPer: ITRFBBYDisPe,
      ITRFBBY2DisPer: ITRFBBY2DisPe,
    });

    // PRO

    let ITRFBPY = pricingJson["ITRFBPY"].price;
    let ITRFBPY2 = pricingJson["ITRFBPY2"].price;

    // No Discount Price
    let ITRFBPYnodi = pricingJson["ITRFBPY"].nodiscountprice;
    let ITRFBPY2nodi = pricingJson["ITRFBPY2"].nodiscountprice;

    // Discount Percentage
    let ITRFBPYDisPe = pricingJson["ITRFBPY"].discountpercentage;
    let ITRFBPY2DisPe = pricingJson["ITRFBPY2"].discountpercentage;

    this.setState({
      ITRFBPY: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(ITRFBPY * 0.18 + ITRFBPY)),
      ITRFBPY2: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(ITRFBPY2 * 0.18 + ITRFBPY2)),

      // No Discount Price
      ITRFBPYnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(ITRFBPYnodi),
      ITRFBPY2nodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(ITRFBPY2nodi),

      // Discount Percentage
      ITRFBPYDisPer: ITRFBPYDisPe,
      ITRFBPY2DisPer: ITRFBPY2DisPe,
    });

    // PRO+

    let ITRFBPPY = pricingJson["ITRFBPPY"].price;
    let ITRFBPPY2 = pricingJson["ITRFBPPY2"].price;

    // No Discount Price
    let ITRFBPPYnodi = pricingJson["ITRFBPPY"].nodiscountprice;
    let ITRFBPPY2nodi = pricingJson["ITRFBPPY2"].nodiscountprice;

    // Discount Percentage
    let ITRFBPPYDisPe = pricingJson["ITRFBPPY"].discountpercentage;
    let ITRFBPPY2DisPe = pricingJson["ITRFBPPY2"].discountpercentage;

    this.setState({
      ITRFBPPY: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(ITRFBPPY * 0.18 + ITRFBPPY)),
      ITRFBPPY2: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(ITRFBPPY2 * 0.18 + ITRFBPPY2)),

      // No Discount Price
      ITRFBPPYnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(ITRFBPPYnodi),
      ITRFBPPY2nodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(ITRFBPPY2nodi),

      // Discount Percentage
      ITRFBPPYDisPer: ITRFBPPYDisPe,
      ITRFBPPY2DisPer: ITRFBPPY2DisPe,
    });

    // PREMIUM

    let ITRFBPRY = pricingJson["ITRFBPRY"].price;
    let ITRFBPRY2 = pricingJson["ITRFBPRY2"].price;

    // No Discount Price
    let ITRFBPRYnodi = pricingJson["ITRFBPRY"].nodiscountprice;
    let ITRFBPRY2nodi = pricingJson["ITRFBPRY2"].nodiscountprice;

    // Discount Percentage
    let ITRFBPRYDisPe = pricingJson["ITRFBPRY"].discountpercentage;
    let ITRFBPRY2DisPe = pricingJson["ITRFBPRY2"].discountpercentage;

    this.setState({
      ITRFBPRY: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(ITRFBPRY * 0.18 + ITRFBPRY)),
      ITRFBPRY2: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(ITRFBPRY2 * 0.18 + ITRFBPRY2)),

      // No Discount Price
      ITRFBPRYnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(ITRFBPRYnodi),
      ITRFBPRY2nodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(ITRFBPRY2nodi),

      // Discount Percentage
      ITRFBPRYDisPer: ITRFBPRYDisPe,
      ITRFBPRY2DisPer: ITRFBPRY2DisPe,
    });
  };

  updateplan = () => {
    var stateshortcode = Object.keys(json2[0]).find(key => json2[0][key] === servicestate);
    var mainobject=pricingJson["ITRF"+this.state.mainpackage+this.state.mainplan+this.state.mainduration]
    var gstvalue=mainobject.price*0.18
    data = {
      "userId": Decuserid,
      "agentId":Decagentid,
      "serviceName": "ITRF",
      "purchasename": fullname,
      "citydistrict": citydistrict,
      "state": servicestate,
      "serviceType": "",
      "duration": this.state.mainduration,
      "plan": this.state.mainplan,
      "turnover": "",
      "package": this.state.mainpackage,
      "price":mainobject.price,
      "gstvalue":gstvalue,
      "govtfees":mainobject.govtfees,
      "servicefullname":servicename,
      "stateShortCode": stateshortcode,
    }

    var sessiondata; 
    sessiondata = sessionStorage.getItem("data")
    if (sessiondata == null || sessiondata == undefined  || sessiondata == "") {
      sessiondata=[]
    }
    else  {
     sessiondata = JSON.parse(sessionStorage.getItem("data"))
    }
    
        if (sessiondata.length == 0 ) {
          var localvar = []
          localvar.push(data)
          sessionStorage.setItem("data", JSON.stringify(localvar))
          this.showNotification("Item Added to Cart")
        }
        else if (sessiondata.length > 0) {
          var arraylen = sessiondata.length;
          // for (let index = 0; index < arraylen; index++) {
          //   if (sessiondata[index].serviceName == "ITRF") {
          //     sessiondata.splice(index, 1)
          //     break
          //   }
          // }
          sessiondata.push(data)
          sessionStorage.setItem("data", JSON.stringify(sessiondata))
          this.showNotification("Item Added to Cart")
        } 
        this.setState({
          dataupdated: true,
        });

  };


  handlesalariedbtn = () => {
    this.setState({
      mainpackage: "S",
    });
    $(".Businessdiv").hide();
    $(".Salarieddiv").show();
    $(".Salariedperbut").addClass("activesal");
    $(".bussprobut").removeClass("activebuss");
    $(".Salariedperbut").addClass("itrplans");
    $(".bussprobut").removeClass("itrplans");
  };

  handlebussbtn = () => {
    this.setState({
      mainpackage: "B",
    });
    $(".Salarieddiv").hide();
    $(".Businessdiv").show();
    $(".bussprobut").addClass("activebuss");
    $(".Salariedperbut").removeClass("activesal");
    $(".Salariedperbut").removeClass("itrplans");
    $(".bussprobut").addClass("itrplans");
  };

  firstdropdownlichange = (e) => {
    this.setState({
      firstdropdownvalue: $(e.currentTarget).attr("value"),
    });
    var getValue = $(e.currentTarget).html();
    $(".firstdropdown").html(getValue);
  };
  seconddropdownlichange = (e) => {
    this.setState({
      seconddropdownvalue: $(e.currentTarget).attr("value"),
    });
    var getValue = $(e.currentTarget).html();
    $(".seconddropdown").html(getValue);
  };
  thirddropdownlichange = (e) => {
    this.setState({
      thirddropdownvalue: $(e.currentTarget).attr("value"),
    });
    var getValue = $(e.currentTarget).html();
    $(".thirddropdown").html(getValue);
  };
  fourdropdownlichange = (e) => {
    this.setState({
      fourdropdownvalue: $(e.currentTarget).attr("value"),
    });
    var getValue = $(e.currentTarget).html();
    $(".fourdropdown").html(getValue);
  };
  fivedropdownlichange = (e) => {
    this.setState({
      fivedropdownvalue: $(e.currentTarget).attr("value"),
    });
    var getValue = $(e.currentTarget).html();
    $(".fivedropdown").html(getValue);
  };
  sixdropdownlichange = (e) => {
    this.setState({
      sixdropdownvalue: $(e.currentTarget).attr("value"),
    });
    var getValue = $(e.currentTarget).html();
    $(".sixdropdown").html(getValue);
  };
  sevendropdownlichange = (e) => {
    this.setState({
      sevendropdownvalue: $(e.currentTarget).attr("value"),
    });
    var getValue = $(e.currentTarget).html();
    $(".sevendropdown").html(getValue);
  };
  eightdropdownlichange = (e) => {
    this.setState({
      eightdropdownvalue: $(e.currentTarget).attr("value"),
    });
    var getValue = $(e.currentTarget).html();
    $(".eightdropdown").html(getValue);
  };
  ninedropdownlichange = (e) => {
    this.setState({
      ninedropdownvalue: $(e.currentTarget).attr("value"),
    });
    var getValue = $(e.currentTarget).html();
    $(".ninedropdown").html(getValue);
  };

  firstdropdowngetstartedclick = (e) => {
    $(".getform").hide();
    $("#addserviceloaderid1").show();
    $(".plangetstartedbtn").attr("disabled", true);
    this.setState(
      {
        mainduration: this.state.firstdropdownvalue,
        mainplan: "S",
      },
      () => {
        this.createPurchaseReqNormal();
      }
    );
  };

  addToCartfirstdropdowngetstartedclick = () => {
    this.setState(
      {
        mainduration: this.state.firstdropdownvalue,
        mainplan: "S",
      },
      () => {
        this.updateplan();
      }
    );
  };
  seconddropdowngetstartedclick = (e) => {
    $(".getform2").hide();
    $("#addserviceloaderid2").show();
    $(".plangetstartedbtn").attr("disabled", true);
    this.setState(
      {
        mainduration: this.state.seconddropdownvalue,
        mainplan: "B",
      },
      () => {
        this.createPurchaseReqNormal();
      }
    );
  };
  addToCartseconddropdowngetstartedclick = () => {
    this.setState(
      {
        mainduration: this.state.seconddropdownvalue,
        mainplan: "B",
      },
      () => {
        this.updateplan();
      }
    );
  };
  thirddropdowngetstartedclick = (e) => {
    $(".getform3").hide();
    $("#addserviceloaderid3").show();
    $(".plangetstartedbtn").attr("disabled", true);
    this.setState(
      {
        mainduration: this.state.thirddropdownvalue,
        mainplan: "P",
      },
      () => {
        this.createPurchaseReqNormal();
      }
    );
  };
  addToCartthirddropdowngetstartedclick = () => {
    this.setState(
      {
        mainduration: this.state.thirddropdownvalue,
        mainplan: "P",
      },
      () => {
        this.updateplan();
      }
    );
  };
  fourdropdowngetstartedclick = (e) => {
    $(".getform4").hide();
    $("#addserviceloaderid4").show();
    $(".plangetstartedbtn").attr("disabled", true);
    this.setState(
      {
        mainduration: this.state.fourdropdownvalue,
        mainplan: "PP",
      },
      () => {
        this.createPurchaseReqNormal();
      }
    );
  };
  addToCartfourdropdowngetstartedclick = () => {
    this.setState(
      {
        mainduration: this.state.fourdropdownvalue,
        mainplan: "PP",
      },
      () => {
        this.updateplan();
      }
    );
  };
  fivedropdowngetstartedclick = (e) => {
    $(".getform5").hide();
    $("#addserviceloaderid5").show();
    $(".plangetstartedbtn").attr("disabled", true);
    this.setState(
      {
        mainduration: this.state.fivedropdownvalue,
        mainplan: "PR",
      },
      () => {
        this.createPurchaseReqNormal();
      }
    );
  };
  addToCartfivedropdowngetstartedclick = () => {
    this.setState(
      {
        mainduration: this.state.fivedropdownvalue,
        mainplan: "PR",
      },
      () => {
        this.updateplan();
      }
    );
  };

  sixdropdowngetstartedclick = (e) => {
    $(".getform6").hide();
    $("#addserviceloaderid6").show();
    $(".plangetstartedbtn").attr("disabled", true);
    this.setState(
      {
        mainduration: this.state.sixdropdownvalue,
        mainplan: "S",
      },
      () => {
        this.createPurchaseReqNormal();
      }
    );
  };

  addToCartsixdropdowngetstartedclick = () => {
    this.setState(
      {
        mainduration: this.state.sixdropdownvalue,
        mainplan: "S",
      },
      () => {
        this.updateplan();
      }
    );
  };
  
  sevendropdowngetstartedclick = (e) => {
    $(".getform7").hide();
    $("#addserviceloaderid7").show();
    $(".plangetstartedbtn").attr("disabled", true);
    this.setState(
      {
        mainduration: this.state.sevendropdownvalue,
        mainplan: "B",
      },
      () => {
        this.createPurchaseReqNormal();
      }
    );
  };
  addToCartsevendropdowngetstartedclick = () => {
    this.setState(
      {
        mainduration: this.state.sevendropdownvalue,
        mainplan: "B",
      },
      () => {
        this.updateplan();
      }
    );
  };
  eightdropdowngetstartedclick = (e) => {
    $(".getform8").hide();
    $("#addserviceloaderid8").show();
    $(".plangetstartedbtn").attr("disabled", true);
    this.setState(
      {
        mainduration: this.state.eightdropdownvalue,
        mainplan: "P",
      },
      () => {
        this.createPurchaseReqNormal();
      }
    );
  };
  addToCarteightdropdowngetstartedclick = () => {
    this.setState(
      {
        mainduration: this.state.eightdropdownvalue,
        mainplan: "P",
      },
      () => {
        this.updateplan();
      }
    );
  };
  ninedropdowngetstartedclick = (e) => {
    $(".getform9").hide();
    $("#addserviceloaderid9").show();
    $(".plangetstartedbtn").attr("disabled", true);
    this.setState(
      {
        mainduration: this.state.ninedropdownvalue,
        mainplan: "PR",
      },
      () => {
        this.createPurchaseReqNormal();
      }
    );
  };
  addToCartninedropdowngetstartedclick = () => {
    this.setState(
      {
        mainduration: this.state.ninedropdownvalue,
        mainplan: "PR",
      },
      () => {
        this.updateplan();
      }
    );
  };
  
  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };
  createPurchaseReqNormal = () => {
    if (upgradeplan == true) {
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + 'p=createPurchaseReqSpecial',
          {
            userId: Decuserid,
            agentId: Decagentid,
            serviceName: "ITRF",
            serviceId: serviceid,
            purchasename: fullname,
            citydistrict: citydistrict,
            state: servicestate,
            serviceType: "",
            duration: this.state.mainduration,
            plan: this.state.mainplan,
            turnover: "",
            package: this.state.mainpackage,
            renew: "false",
            upgradeplan: "true",
            comeFrom:"AGP"
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD
            }
          }
        ).then((res) => {


          if (res.data.code == "200" && res.data.status == "success") {
            $(".plangetstartedbtn").show();
            $(".addserviceloader").hide();
            $(".plangetstartedbtn").attr("disabled", false);
            sessionStorage.setItem(
              "uegiqlsartdele",
              encryptor.encrypt(res.data.requestid)
            );
            sessionStorage.setItem(
              "aprcillgee",
              encryptor.encrypt(parseFloat(res.data.price))
            );
            sessionStorage.setItem("ssdfsdfwekrfewpfk", encryptor.encrypt(parseFloat(res.data.gstvalue)));
            sessionStorage.setItem("lslgerotnscmvheearaei", encryptor.encrypt("ITRF"));
            sessionStorage.setItem("evlgsoaeetgfl", encryptor.encrypt(0));
            sessionStorage.setItem(
              "egalllpna",
              encryptor.encrypt(this.state.mainplan)
            );

            this.props.history.push({
              pathname: `/addservice/${Decuserid}/addservice/checkout`,
            });
          } else if (res.data.code == "201" && res.data.status == "invalidauth") {
            this.logout();
          } else {
            $(".plangetstartedbtn").show();
            $(".addserviceloader").hide();
            $(".plangetstartedbtn").attr("disabled", false);
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => { });
    }
    else {
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + 'p=createPurchaseReqNormal',
          {
            userId: Decuserid,
            agentId: Decagentid,
            serviceName: "ITRF",
            purchasename: fullname,
            citydistrict: citydistrict,
            state: servicestate,
            serviceType: "",
            duration: this.state.mainduration,
            plan: this.state.mainplan,
            turnover: "",
            package: this.state.mainpackage,
            comeFrom:"AGP"
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD
            }
          }
        )
        .then((res) => {


          if (res.data.code == "200" && res.data.status == "success") {
            $(".plangetstartedbtn").show();
            $(".addserviceloader").hide();
            $(".plangetstartedbtn").attr("disabled", false);
            sessionStorage.setItem(
              "uegiqlsartdele",
              encryptor.encrypt(res.data.requestid)
            );
            sessionStorage.setItem(
              "aprcillgee",
              encryptor.encrypt(parseFloat(res.data.price))
            );
            sessionStorage.setItem("ssdfsdfwekrfewpfk", encryptor.encrypt(parseFloat(res.data.gstvalue)));
            sessionStorage.setItem("lslgerotnscmvheearaei", encryptor.encrypt("ITRF"));
            sessionStorage.setItem("evlgsoaeetgfl", encryptor.encrypt(0));
            sessionStorage.setItem(
              "egalllpna",
              encryptor.encrypt(this.state.mainplan)
            );

            this.props.history.push({
              pathname: `/addservice/${Decuserid}/addservice/checkout`,
            });
          } else if (res.data.code == "201" && res.data.status == "invalidauth") {
            this.logout();
          } else {
            $(".plangetstartedbtn").show();
            $(".addserviceloader").hide();
            $(".plangetstartedbtn").attr("disabled", false);
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => { });
    }
  };
  logout = () => {
    localStorage.removeItem("gglltakoinoeenl");
    localStorage.removeItem("toeljgtkewlna");
    localStorage.removeItem("qazxswedcvfrtgb");
    localStorage.removeItem("lshhihfnkff");
    this.props.history.push({
      pathname: `/`,
    });
  };
  backbutton = () => {
    this.props.history.push({
      pathname: `/addservice/${Decuserid}/addservice`,
    });
  };

  handlesupport = () => {
    var x = document.getElementById("myDIV");
    if (x.style.display == "block") {
      x.style.display = "none";
    }else {
      x.style.display = "block";
    }
  }
  render() {
    if (
      localStorage.getItem("gglltakoinoeenl") == process.env.REACT_APP_LOGIN_KEY &&
      localStorage.getItem("toeljgtkewlna") != null &&
      localStorage.getItem("qazxswedcvfrtgb") != null
    ) {
      if (
        sessionStorage.getItem("ielndmlgeaefelvsdrlucaa") != null &&
        sessionStorage.getItem("ylcvciretaireditdgisdtacesl") != null &&
        sessionStorage.getItem("srleleddgaeaemvcnia") != null
      ) {
        return (
          <div className="cont">
            <div id="notifContainer"></div>
            <Helmet>
              <link rel="stylesheet" href={process.env.PUBLIC_URL + "/css/select2.css"} />
            </Helmet>

            <Header />

            <img
              src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
              className="sidebaropenicon"
            />
            <img
              src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
              onClick={this.backbutton}
              className="sidebarbackicon"
            />
            <img
              src={process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"}
              className="sidebarexpandicon"
            />

            <div className="overlay1"></div>
            <div className="sidebar sidebarshift">
              <div className="side_div_2 sidebar_txt active">
                <Link to="/dashboard">Overview</Link>
              </div>
              <div className="side_div_1 sidebar_txt">
                <Link to="/myservices">My Services</Link>
              </div>
              <div className="side_div_1 sidebar_txt">
                <a href="#">Payments Invoices</a>
              </div>
              <div className="side_div_1 sidebar_txt">
                <a href="#">Documents Record</a>
              </div>
              <div className="side_div_1 sidebar_txt">
                <a href="#">Add service</a>
              </div>
              <div></div>
            </div>
            <div className="arrow-left2 visibilityhidden"></div>
            <div className="white_div expandwhite_div">
              <div className="white_box">
                <div className="white_boxscrolldiv">
                  <center>
                    {" "}
                    <p className="download">Add Service</p>
                  </center>
                  <div className="row addserivemaindiv">
                    <div className="col-md-4">
                      <div className="addservicetextinputdiv">
                        <div>
                          {" "}
                          <label className="adddervicelabel">Full name:-</label>
                        </div>
                        <div>
                          <input
                            type="text"
                            className="addservicetextinput"
                            value={this.state.servicefullname}
                            placeholder="Type here..."
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div>
                        <center>
                          <div>
                            <label className="adddervicelabel">
                              Select service:-
                            </label>
                          </div>

                          <div className="addserviceselectdiv">
                            <Select2
                              value={this.state.citydistrictselectvalue}
                              data={[
                                "Adilabad",
                                "Agar Malwa",
                                "Agra",
                                "Ahmedabad",
                                "Ahmednagar",
                                "Aizawl",
                                "Ajmer",
                                "Akola",
                                "Alappuzha",
                                "Aligarh",
                                "Alipurduar",
                                "Alirajpur",
                                "Allahabad",
                                "Almora",
                                "Alwar",
                                "Ambala",
                                "Ambedkar Nagar",
                                "Amethi (Chatrapati Sahuji Mahraj Nagar)",
                                "Amravati",
                                "Amreli",
                                "Amritsar",
                                "Amroha",
                                "Anand",
                                "Anantapur",
                                "Anantnag",
                                "Angul",
                                "Anjaw",
                                "Anuppur",
                                "Araria",
                                "Aravalli",
                                "Ariyalur",
                                "Arwal",
                                "Ashoknagar",
                                "Auraiya",
                                "Aurangabad (BH)",
                                "Aurangabad (MH)",
                                "Azamgarh",
                                "Bagalkot",
                                "Bageshwar",
                                "Baghpat",
                                "Bahraich",
                                "Baksa",
                                "Balaghat",
                                "Balangir",
                                "Balasore",
                                "Ballari (Bellary)",
                                "Ballia",
                                "Balod",
                                "Baloda Bazar",
                                "Balrampur (CG)",
                                "Balrampur (UP)",
                                "Banaskantha (Palanpur)",
                                "Banda",
                                "Bandipore",
                                "Banka",
                                "Bankura",
                                "Banswara",
                                "Barabanki",
                                "Baramulla",
                                "Baran",
                                "Bareilly",
                                "Bargarh",
                                "Barmer",
                                "Barnala",
                                "Barpeta",
                                "Barwani",
                                "Bastar",
                                "Basti",
                                "Bathinda",
                                "Beed",
                                "Begusarai",
                                "Belagavi (Belgaum)",
                                "Bemetara",
                                "Bengaluru (Bangalore) Rural",
                                "Bengaluru (Bangalore) Urban",
                                "Betul",
                                "Bhadohi",
                                "Bhadradri Kothagudem",
                                "Bhadrak",
                                "Bhagalpur",
                                "Bhandara",
                                "Bharatpur",
                                "Bharuch",
                                "Bhavnagar",
                                "Bhilwara",
                                "Bhind",
                                "Bhiwani",
                                "Bhojpur",
                                "Bhopal",
                                "Bidar",
                                "Bijapur",
                                "Bijnor",
                                "Bikaner",
                                "Bilaspur (CG)",
                                "Bilaspur (HP)",
                                "Birbhum",
                                "Bishnupur",
                                "Biswanath",
                                "Bokaro",
                                "Bongaigaon",
                                "Botad",
                                "Boudh",
                                "Budaun",
                                "Budgam",
                                "Bulandshahr",
                                "Buldhana",
                                "Bundi",
                                "Burhanpur",
                                "Buxar",
                                "Cachar",
                                "Central Delhi",
                                "Chamarajanagar",
                                "Chamba",
                                "Chamoli",
                                "Champawat",
                                "Champhai",
                                "Chandauli",
                                "Chandel",
                                "Chandigarh",
                                "Chandrapur",
                                "Changlang",
                                "Charaideo",
                                "Charkhi Dadri",
                                "Chatra",
                                "Chengalpattu",
                                "Chennai",
                                "Chhatarpur",
                                "Chhindwara",
                                "Chhota Udepur",
                                "Chikballapur",
                                "Chikkamagaluru (Chikmagalur)",
                                "Chirang",
                                "Chitradurga",
                                "Chitrakoot",
                                "Chittoor",
                                "Chittorgarh",
                                "Churachandpur",
                                "Churu",
                                "Coimbatore",
                                "Cooch Behar",
                                "Cuddalore",
                                "Cuttack",
                                "Dadra & Nagar Haveli",
                                "Dahod",
                                "Dakshin Dinajpur (South Dinajpur)",
                                "Dakshina Kannada",
                                "Daman",
                                "Damoh",
                                "Dangs (Ahwa)",
                                "Dantewada (South Bastar)",
                                "Darbhanga",
                                "Darjeeling",
                                "Darrang",
                                "Datia",
                                "Dausa",
                                "Davangere",
                                "Dehradun",
                                "Deogarh",
                                "Deoghar",
                                "Deoria",
                                "Devbhoomi Dwarka",
                                "Dewas",
                                "Dhalai",
                                "Dhamtari",
                                "Dhanbad",
                                "Dhar",
                                "Dharmapuri",
                                "Dharwad",
                                "Dhemaji",
                                "Dhenkanal",
                                "Dholpur",
                                "Dhubri",
                                "Dhule",
                                "Dibang Valley",
                                "Dibrugarh",
                                "Dima Hasao (North Cachar Hills)",
                                "Dimapur",
                                "Dindigul",
                                "Dindori",
                                "Diu",
                                "Doda",
                                "Dumka",
                                "Dungarpur",
                                "Durg",
                                "East Champaran (Motihari)",
                                "East Delhi",
                                "East Garo Hills",
                                "East Godavari",
                                "East Jaintia Hills",
                                "East Kameng",
                                "East Khasi Hills",
                                "East Siang",
                                "East Sikkim",
                                "East Singhbhum",
                                "Ernakulam",
                                "Erode",
                                "Etah",
                                "Etawah",
                                "Faizabad",
                                "Faridabad",
                                "Faridkot",
                                "Farrukhabad",
                                "Fatehabad",
                                "Fatehgarh Sahib",
                                "Fatehpur",
                                "Fazilka",
                                "Ferozepur",
                                "Firozabad",
                                "Gadag",
                                "Gadchiroli",
                                "Gajapati",
                                "Ganderbal",
                                "Gandhinagar",
                                "Ganjam",
                                "Garhwa",
                                "Gariyaband",
                                "Gautam Buddha Nagar",
                                "Gaya",
                                "Ghaziabad",
                                "Ghazipur",
                                "Gir Somnath",
                                "Giridih",
                                "Goalpara",
                                "Godda",
                                "Golaghat",
                                "Gomati",
                                "Gonda",
                                "Gondia",
                                "Gopalganj",
                                "Gorakhpur",
                                "Gumla",
                                "Guna",
                                "Guntur",
                                "Gurdaspur",
                                "Gurugram (Gurgaon)",
                                "Gwalior",
                                "Hailakandi",
                                "Hamirpur (HP)",
                                "Hamirpur (UP)",
                                "Hanumangarh",
                                "Hapur (Panchsheel Nagar)",
                                "Harda",
                                "Hardoi",
                                "Haridwar",
                                "Hassan",
                                "Hathras",
                                "Haveri",
                                "Hazaribag",
                                "Hingoli",
                                "Hisar",
                                "Hojai",
                                "Hooghly",
                                "Hoshangabad",
                                "Hoshiarpur",
                                "Howrah",
                                "Hyderabad",
                                "Idukki",
                                "Imphal East",
                                "Imphal West",
                                "Indore",
                                "Jabalpur",
                                "Jagatsinghapur",
                                "Jagtial",
                                "Jaipur",
                                "Jaisalmer",
                                "Jajpur",
                                "Jalandhar",
                                "Jalaun",
                                "Jalgaon",
                                "Jalna",
                                "Jalore",
                                "Jalpaiguri",
                                "Jammu",
                                "Jamnagar",
                                "Jamtara",
                                "Jamui",
                                "Jangaon",
                                "Janjgir-Champa",
                                "Jashpur",
                                "Jaunpur",
                                "Jayashankar Bhoopalpally",
                                "Jehanabad",
                                "Jhabua",
                                "Jhajjar",
                                "Jhalawar",
                                "Jhansi",
                                "Jhargram",
                                "Jharsuguda",
                                "Jhunjhunu",
                                "Jind",
                                "Jiribam",
                                "Jodhpur",
                                "Jogulamba Gadwal",
                                "Jorhat",
                                "Junagadh",
                                "Kabirdham (Kawardha)",
                                "Kachchh",
                                "Kaimur (Bhabua)",
                                "Kaithal",
                                "Kakching",
                                "Kalaburagi (Gulbarga)",
                                "Kalahandi",
                                "Kalimpong",
                                "Kallakurichi",
                                "Kamareddy",
                                "Kamjong",
                                "Kamle",
                                "Kamrup",
                                "Kamrup Metropolitan",
                                "Kanchipuram",
                                "Kandhamal",
                                "Kangpokpi",
                                "Kangra",
                                "Kanker (North Bastar)",
                                "Kannauj",
                                "Kannur",
                                "Kanpur Dehat",
                                "Kanpur Nagar",
                                "Kanshiram Nagar (Kasganj)",
                                "Kanyakumari",
                                "Kapurthala",
                                "Karaikal",
                                "Karauli",
                                "Karbi Anglong",
                                "Kargil",
                                "Karimganj",
                                "Karimnagar",
                                "Karnal",
                                "Karur",
                                "Kasaragod",
                                "Kathua",
                                "Katihar",
                                "Katni",
                                "Kaushambi",
                                "Kendrapara",
                                "Kendujhar (Keonjhar)",
                                "Khagaria",
                                "Khammam",
                                "Khandwa",
                                "Khargone",
                                "Kheda (Nadiad)",
                                "Khordha",
                                "Khowai",
                                "Khunti",
                                "Kinnaur",
                                "Kiphire",
                                "Kishanganj",
                                "Kishtwar",
                                "Kodagu",
                                "Koderma",
                                "Kohima",
                                "Kokrajhar",
                                "Kolar",
                                "Kolasib",
                                "Kolhapur",
                                "Kolkata",
                                "Kollam",
                                "Komaram Bheem Asifabad",
                                "Kondagaon",
                                "Koppal",
                                "Koraput",
                                "Korba",
                                "Korea (Koriya)",
                                "Kota",
                                "Kottayam",
                                "Kozhikode",
                                "Kra Daadi",
                                "Krishna",
                                "Krishnagiri",
                                "Kulgam",
                                "Kullu",
                                "Kupwara",
                                "Kurnool",
                                "Kurukshetra",
                                "Kurung Kumey",
                                "Kushinagar (Padrauna)",
                                "Lahaul & Spiti",
                                "Lakhimpur",
                                "Lakhimpur - Kheri",
                                "Lakhisarai",
                                "Lakshadweep",
                                "Lalitpur",
                                "Latehar",
                                "Latur",
                                "Lawngtlai",
                                "Leh",
                                "Lepa Rada",
                                "Lohardaga",
                                "Lohit",
                                "Longding",
                                "Longleng",
                                "Lower Dibang Valley",
                                "Lower Siang",
                                "Lower Subansiri",
                                "Lucknow",
                                "Ludhiana",
                                "Lunglei",
                                "Madhepura",
                                "Madhubani",
                                "Madurai",
                                "Mahabubabad",
                                "Mahabubnagar",
                                "Maharajganj",
                                "Mahasamund",
                                "Mahe",
                                "Mahendragarh",
                                "Mahisagar",
                                "Mahoba",
                                "Mainpuri",
                                "Majuli",
                                "Malappuram",
                                "Malda",
                                "Malkangiri",
                                "Mamit",
                                "Mancherial",
                                "Mandi",
                                "Mandla",
                                "Mandsaur",
                                "Mandya",
                                "Mansa",
                                "Mathura",
                                "Mau",
                                "Mayurbhanj",
                                "Medak",
                                "Medchal",
                                "Meerut",
                                "Mehsana",
                                "Mirzapur",
                                "Moga",
                                "Mokokchung",
                                "Mon",
                                "Moradabad",
                                "Morbi",
                                "Morena",
                                "Morigaon",
                                "Muktsar",
                                "Mumbai City",
                                "Mumbai Suburban",
                                "Mungeli",
                                "Munger (Monghyr)",
                                "Murshidabad",
                                "Muzaffarnagar",
                                "Muzaffarpur",
                                "Mysuru (Mysore)",
                                "Nabarangpur",
                                "Nadia",
                                "Nagaon",
                                "Nagapattinam",
                                "Nagarkurnool",
                                "Nagaur",
                                "Nagpur",
                                "Nainital",
                                "Nalanda",
                                "Nalbari",
                                "Nalgonda",
                                "Namakkal",
                                "Namsai",
                                "Nanded",
                                "Nandurbar",
                                "Narayanpur",
                                "Narmada (Rajpipla)",
                                "Narsinghpur",
                                "Nashik",
                                "Navsari",
                                "Nawada",
                                "Nawanshahr (Shahid Bhagat Singh Nagar)",
                                "Nayagarh",
                                "Neemuch",
                                "New Delhi",
                                "Nicobar",
                                "Nilgiris",
                                "Nirmal",
                                "Nizamabad",
                                "Noney",
                                "North 24 Parganas",
                                "North Delhi",
                                "North East Delhi",
                                "North Garo Hills",
                                "North Goa",
                                "North Sikkim",
                                "North Tripura",
                                "North West Delhi",
                                "North and Middle Andaman",
                                "Nuapada",
                                "Nuh",
                                "Osmanabad",
                                "Pakke Kessang",
                                "Pakur",
                                "Palakkad",
                                "Palamu",
                                "Palghar",
                                "Pali",
                                "Palwal",
                                "Panchkula",
                                "Panchmahal (Godhra)",
                                "Panipat",
                                "Panna",
                                "Papum Pare",
                                "Parbhani",
                                "Paschim (West) Burdwan (Bardhaman)",
                                "Paschim Medinipur (West Medinipur)",
                                "Patan",
                                "Pathanamthitta",
                                "Pathankot",
                                "Patiala",
                                "Patna",
                                "Pauri Garhwal",
                                "Peddapalli",
                                "Perambalur",
                                "Peren",
                                "Phek",
                                "Pherzawl",
                                "Pilibhit",
                                "Pithoragarh",
                                "Poonch",
                                "Porbandar",
                                "Prakasam",
                                "Pratapgarh (RJ)",
                                "Pratapgarh (UP)",
                                "Puducherry",
                                "Pudukkottai",
                                "Pulwama",
                                "Pune",
                                "Purba Burdwan (Bardhaman)",
                                "Purba Medinipur (East Medinipur)",
                                "Puri",
                                "Purnia (Purnea)",
                                "Purulia",
                                "RaeBareli",
                                "Raichur",
                                "Raigad",
                                "Raigarh",
                                "Raipur",
                                "Raisen",
                                "Rajanna Sircilla",
                                "Rajgarh",
                                "Rajkot",
                                "Rajnandgaon",
                                "Rajouri",
                                "Rajsamand",
                                "Ramanagara",
                                "Ramanathapuram",
                                "Ramban",
                                "Ramgarh",
                                "Rampur",
                                "Ranchi",
                                "Rangareddy",
                                "Ranipet",
                                "Ratlam",
                                "Ratnagiri",
                                "Rayagada",
                                "Reasi",
                                "Rewa",
                                "Rewari",
                                "Ri Bhoi",
                                "Rohtak",
                                "Rohtas",
                                "Rudraprayag",
                                "Rupnagar",
                                "Sabarkantha (Himmatnagar)",
                                "Sagar",
                                "Saharanpur",
                                "Saharsa",
                                "Sahibganj",
                                "Sahibzada Ajit Singh Nagar (Mohali)",
                                "Saiha",
                                "Salem",
                                "Samastipur",
                                "Samba",
                                "Sambalpur",
                                "Sambhal (Bhim Nagar)",
                                "Sangareddy",
                                "Sangli",
                                "Sangrur",
                                "Sant Kabir Nagar",
                                "Saran",
                                "Satara",
                                "Satna",
                                "Sawai Madhopur",
                                "Sehore",
                                "Senapati",
                                "Seoni",
                                "Sepahijala",
                                "Seraikela-Kharsawan",
                                "Serchhip",
                                "Shahdara",
                                "Shahdol",
                                "Shahjahanpur",
                                "Shajapur",
                                "Shamali (Prabuddh Nagar)",
                                "Sheikhpura",
                                "Sheohar",
                                "Sheopur",
                                "Shi Yomi",
                                "Shimla",
                                "Shivamogga (Shimoga)",
                                "Shivpuri",
                                "Shopian",
                                "Shravasti",
                                "Siang",
                                "Siddharth Nagar",
                                "Siddipet",
                                "Sidhi",
                                "Sikar",
                                "Simdega",
                                "Sindhudurg",
                                "Singrauli",
                                "Sirmaur (Sirmour)",
                                "Sirohi",
                                "Sirsa",
                                "Sitamarhi",
                                "Sitapur",
                                "Sivaganga",
                                "Sivasagar",
                                "Siwan",
                                "Solan",
                                "Solapur",
                                "Sonbhadra",
                                "Sonepur",
                                "Sonipat",
                                "Sonitpur",
                                "South 24 Parganas",
                                "South Andaman",
                                "South Delhi",
                                "South East Delhi",
                                "South Garo Hills",
                                "South Goa",
                                "South Salamara-Mankachar",
                                "South Sikkim",
                                "South Tripura",
                                "South West Delhi",
                                "South West Garo Hills",
                                "South West Khasi Hills",
                                "Sri Ganganagar",
                                "Sri Potti Sriramulu Nellore ",
                                "Srikakulam",
                                "Srinagar",
                                "Sukma",
                                "Sultanpur",
                                "Sundargarh",
                                "Supaul",
                                "Surajpur",
                                "Surat",
                                "Surendranagar",
                                "Surguja",
                                "Suryapet",
                                "Tamenglong",
                                "Tapi (Vyara)",
                                "Tarn Taran",
                                "Tawang",
                                "Tehri Garhwal",
                                "Tengnoupal",
                                "Tenkasi",
                                "Thane",
                                "Thanjavur",
                                "Theni",
                                "Thiruvananthapuram",
                                "Thoothukudi (Tuticorin)",
                                "Thoubal",
                                "Thrissur",
                                "Tikamgarh",
                                "Tinsukia",
                                "Tirap",
                                "Tiruchirappalli",
                                "Tirunelveli",
                                "Tirupathur",
                                "Tiruppur",
                                "Tiruvallur",
                                "Tiruvannamalai",
                                "Tiruvarur",
                                "Tonk",
                                "Tuensang",
                                "Tumakuru (Tumkur)",
                                "Udaipur",
                                "Udalguri",
                                "Udham Singh Nagar",
                                "Udhampur",
                                "Udupi",
                                "Ujjain",
                                "Ukhrul",
                                "Umaria",
                                "Una",
                                "Unakoti",
                                "Unnao",
                                "Upper Siang",
                                "Upper Subansiri",
                                "Uttar Dinajpur (North Dinajpur)",
                                "Uttara Kannada (Karwar)",
                                "Uttarkashi",
                                "Vadodara",
                                "Vaishali",
                                "Valsad",
                                "Varanasi",
                                "Vellore",
                                "Vidisha",
                                "Vijayapura (Bijapur)",
                                "Vikarabad",
                                "Viluppuram",
                                "Virudhunagar",
                                "Visakhapatnam",
                                "Vizianagaram",
                                "Wanaparthy",
                                "Warangal (Rural)",
                                "Warangal (Urban)",
                                "Wardha",
                                "Washim",
                                "Wayanad",
                                "West Champaran",
                                "West Delhi",
                                "West Garo Hills",
                                "West Godavari",
                                "West Jaintia Hills",
                                "West Kameng",
                                "West Karbi Anglong",
                                "West Khasi Hills",
                                "West Siang",
                                "West Sikkim",
                                "West Singhbhum",
                                "West Tripura",
                                "Wokha",
                                "YSR District, Kadapa (Cuddapah)",
                                "Yadadri Bhuvanagiri",
                                "Yadgir",
                                "Yamunanagar",
                                "Yanam",
                                "Yavatmal",
                                "Zunheboto"
                              ]}
                              options={{
                                placeholder: "Select your city/district...",
                              }}
                              disabled
                            />
                          </div>
                        </center>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div>
                        <div>
                          <label className="adddervicelabel">Select service:-</label>
                        </div>
                        <div className="addserviceselectdiv">
                          <Select2
                            value={this.state.serviceselectvalue}
                            data={[
                              "GST Registration",
                              "GST Return Filing",
                              "ITR Filing",
                              "Trademark Registration",
                            ]}
                            options={{
                              placeholder: "Select service...",
                            }}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <center>
                    <button
                      value="S"
                      className="Salariedperbut itrplans activesal"
                      onClick={this.handlesalariedbtn}
                    >
                      Salaried Person{" "}
                    </button>
                    <button
                      value="B"
                      className="bussprobut"
                      onClick={this.handlebussbtn}
                    >
                      Business / Profession
                    </button>
                  </center>
                  <hr className="buttonbetweenrsd" />
                  <br />
                  <br />

                  <div className="Businessdiv">
                    <br />
                    <div className="row paddingmarginzero">
                      <div className="col-md-3 col-sm-6 businesscol">
                        <div className="bluestartercard">
                          <p className="planmainheading">Starter</p>
                          <div className="plancardinsidepadding">
                            <div className="formplacer ">
                              <div className="plandropdownpadding">
                                <p className="selectatenureheading">
                                  Select the Tenure:
                                </p>
                                <div className="dropdown">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Icon ionic-ios-arrow-down.svg"
                                    }
                                    className="servicedropdownimg servicedropdownimg1"
                                  />
                                  <button
                                    id="dLabel"
                                    className="dropdown-select firstdropdown plandropdownbutton"
                                    type="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <div className="plandropdowninsidediv">
                                      <div className="plandropdowninsideeachdiv">
                                        <span className="plantimespan">
                                          1 Year &nbsp;&nbsp;@
                                        </span>
                                        <span className="planmainpricespan">
                                          {" "}
                                          {this.state.ITRFBSY}
                                        </span>
                                      </div>
                                      <div className="planpricesavediv">
                                        <span className="planlinecutpricespan">
                                        {this.state.ITRFBSYnodis}
                                        </span>
                                        <br />
                                        <span className="plansavespan">
                                          {" "}
                                          Save {this.state.ITRFBSYDisPer}
                                        </span>
                                      </div>
                                    </div>
                                  </button>
                                  <ul
                                    className="dropdown-menu firstdropdown-menu plandropdownul"
                                    aria-labelledby="dLabel"
                                  >
                                    <li
                                    value="Y"
                                    className="linebtplandrop"
                                    onClick={this.firstdropdownlichange}
                                  >
                                    <div className="plandropdowninsidediv">
                                      <div className="plandropdowninsideeachdiv">
                                        <span className="plantimespan">
                                          1 Year &nbsp;&nbsp;@
                                        </span>
                                        <span className="planmainpricespan">
                                          {" "}
                                          {this.state.ITRFBSY}
                                        </span>
                                      </div>
                                      <div className="planpricesavediv">
                                        <span className="planlinecutpricespan">
                                        {this.state.ITRFBSYnodis}
                                        </span>
                                        <br />
                                        <span className="plansavespan">
                                          {" "}
                                          Save {this.state.ITRFBSYDisPer}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  <li
                                    value="Y2"
                                    onClick={this.firstdropdownlichange}
                                  >
                                    <div className="plandropdowninsidediv">
                                      <div className="plandropdowninsideeachdiv">
                                        <span className="plantimespan">
                                          2 Year &nbsp;&nbsp;@
                                        </span>
                                        <span className="planmainpricespan">
                                          {" "}
                                          {this.state.ITRFBSY2}
                                        </span>
                                      </div>
                                      <div className="planpricesavediv">
                                        <span className="planlinecutpricespan">
                                        {this.state.ITRFBSY2nodis}
                                        </span>
                                        <br />
                                        <span className="plansavespan">
                                          {" "}
                                          Save {this.state.ITRFBSY2DisPer}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="hidebusscon1">
                                <center>
                                  <button
                                    className="getform plangetstartedbtn"
                                    id="b1"
                                    onClick={this.firstdropdowngetstartedclick}
                                  >
                                    GET STARTED
                                  </button>
                                  <button class="addtocartbutton2" onClick={this.addToCartfirstdropdowngetstartedclick} id="submit" >Add to cart &nbsp;<img src="/img/Path_33782_1.svg" /></button>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/img/loader.gif"
                                    }
                                    className="addserviceloader"
                                    id="addserviceloaderid1"
                                  />
                                </center>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Estimated Income/Turnover (section 44AD/44ADA)
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Dashboard for managing service
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Mobile application support
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Documents sharing facility
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Download deliverables any time
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Password sharing
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Round the clock support
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Time to time updates & notifications
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 businesscol">
                        <div className="bluestartercard">
                          <p className="planmainheading">basic</p>
                          <div className="plancardinsidepadding">
                            <div className="formplacer ">
                              <div className="plandropdownpadding">
                                <p className="selectatenureheading">
                                  Select the Tenure:
                                </p>
                                <div className="dropdown">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Icon ionic-ios-arrow-down.svg"
                                    }
                                    className="servicedropdownimg servicedropdownimg2"
                                  />
                                  <button
                                    id="dLabel"
                                    className="dropdown-select seconddropdown plandropdownbutton"
                                    type="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <div className="plandropdowninsidediv">
                                      <div className="plandropdowninsideeachdiv">
                                        <span className="plantimespan">
                                          1 Year &nbsp;&nbsp;@
                                        </span>
                                        <span className="planmainpricespan">
                                          {" "}
                                          {this.state.ITRFBBY}
                                        </span>
                                      </div>
                                      <div className="planpricesavediv">
                                        <span className="planlinecutpricespan">
                                        {this.state.ITRFBBYnodis}
                                        </span>
                                        <br />
                                        <span className="plansavespan">
                                          {" "}
                                          Save  {this.state.ITRFBBYDisPer}
                                        </span>
                                      </div>
                                    </div>
                                  </button>
                                  <ul
                                    className="dropdown-menu seconddropdown-menu plandropdownul"
                                    aria-labelledby="dLabel"
                                  >
                                    <li
                                      value="Y"
                                      className="linebtplandrop"
                                      onClick={this.seconddropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.ITRFBBY}
                                          </span>
                                        </div>
                                        <div className="planpricesavediv">
                                          <span className="planlinecutpricespan">
                                          {this.state.ITRFBBYnodis}
                                          </span>
                                          <br />
                                          <span className="plansavespan">
                                            {" "}
                                            Save {this.state.ITRFBBYDisPer}
                                          </span>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Y2"
                                      onClick={this.seconddropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            2 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.ITRFBBY2}
                                          </span>
                                        </div>
                                        <div className="planpricesavediv">
                                          <span className="planlinecutpricespan">
                                          {this.state.ITRFBBY2nodis}
                                          </span>
                                          <br />
                                          <span className="plansavespan">
                                            {" "}
                                            Save {this.state.ITRFBBY2DisPer}
                                          </span>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="hidebusscon2">
                                <center>
                                  <button
                                    className="getform2 plangetstartedbtn"
                                    onClick={this.seconddropdowngetstartedclick}
                                    id="b2"
                                  >
                                    GET STARTED
                                  </button>
                                  <button
                                    className="addtocartbutton2"
                                    id="submit"
                                    onClick={
                                        this.addToCartseconddropdowngetstartedclick
                                    }
                                  >
                                    Add to cart &nbsp;
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path_33782_1.svg"
                                      }
                                    />
                                  </button>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/img/loader.gif"
                                    }
                                    className="addserviceloader"
                                    id="addserviceloaderid2"
                                  />
                                </center>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Estimated Income/Turnover (section 44AD/44ADA)
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Bank Transaction view
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  P&L and balance sheet preparation
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Dashboard for managing service
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Mobile application support
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Documents sharing facility
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Download deliverables any time
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Password sharing
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Round the clock support
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Time to time updates & notifications
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 businesscol">
                        <div className="basicplancard">
                          <img
                            src={process.env.PUBLIC_URL + "/img/Group_3320.svg"}
                            className="bussinesschoicemanyimg"
                          />
                          <p className="planmainheading">pro</p>
                          <div className="plancardinsidepadding">
                            <div className="formplacer ">
                              <div className="plandropdownpadding">
                                <p className="selectatenureheading">
                                  Select the Tenure:
                                </p>
                                <div className="dropdown">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Icon ionic-ios-arrow-down.svg"
                                    }
                                    className="servicedropdownimg servicedropdownimg3"
                                  />
                                  <button
                                    id="dLabel"
                                    className="dropdown-select thirddropdown plandropdownbutton"
                                    type="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <div className="plandropdowninsidediv">
                                      <div className="plandropdowninsideeachdiv">
                                        <span className="plantimespan">
                                          1 Year &nbsp;&nbsp;@
                                        </span>
                                        <span className="planmainpricespan">
                                          {" "}
                                          {this.state.ITRFBPY}
                                        </span>
                                      </div>
                                      <div className="planpricesavediv">
                                        <span className="planlinecutpricespan">
                                        {this.state.ITRFBPYnodis}
                                        </span>
                                        <br />
                                        <span className="plansavespan">
                                          {" "}
                                          Save {this.state.ITRFBPYDisPer}
                                        </span>
                                      </div>
                                    </div>
                                  </button>
                                  <ul
                                    className="dropdown-menu thirddropdown-menu plandropdownul"
                                    aria-labelledby="dLabel"
                                  >
                                    <li
                                      value="Y"
                                      className="linebtplandrop"
                                      onClick={this.thirddropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.ITRFBPY}
                                          </span>
                                        </div>
                                        <div className="planpricesavediv">
                                          <span className="planlinecutpricespan">
                                          {this.state.ITRFBPYnodis}
                                          </span>
                                          <br />
                                          <span className="plansavespan">
                                            {" "}
                                            Save {this.state.ITRFBPYDisPer}
                                          </span>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Y2"
                                      onClick={this.thirddropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            2 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.ITRFBPY2}
                                          </span>
                                        </div>
                                        <div className="planpricesavediv">
                                          <span className="planlinecutpricespan">
                                          {this.state.ITRFBPY2nodis}
                                          </span>
                                          <br />
                                          <span className="plansavespan">
                                            {" "}
                                            Save {this.state.ITRFBPY2DisPer}
                                          </span>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="hidebusscon3">
                                <center>
                                  <button
                                    className="getform3 plangetstartedbtn"
                                    onClick={this.thirddropdowngetstartedclick}
                                    id="b3"
                                  >
                                    GET STARTED
                                  </button>
                                  <button
                                    className="addtocartbutton2"
                                    id="submit"
                                    onClick={
                                        this.addToCartthirddropdowngetstartedclick
                                    }
                                  >
                                    Add to cart &nbsp;
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path_33782_1.svg"
                                      }
                                    />
                                  </button>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/img/loader.gif"
                                    }
                                    className="addserviceloader"
                                    id="addserviceloaderid3"
                                  />
                                </center>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Estimated Income/Turnover (section 44AD/44ADA)
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Bank Transaction view
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  P&L and balance sheet preparation
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Income from other than business or profession
                                  included
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Dashboard for managing service
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Mobile application support
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Documents sharing facility
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Download deliverables any time
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Password sharing
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Round the clock support
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Time to time updates & notifications
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 businesscol">
                        <div className="salariedprocard">
                          <p className="planmainheading">pro+</p>
                          <div className="plancardinsidepadding">
                            <div className="formplacer">
                              <div className="plandropdownpadding">
                                <p className="selectatenureheading">
                                  Select the Tenure:
                                </p>
                                <div className="dropdown">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Icon ionic-ios-arrow-down.svg"
                                    }
                                    className="servicedropdownimg servicedropdownimg4"
                                  />
                                  <button
                                    id="dLabel"
                                    className="dropdown-select fourdropdown plandropdownbutton"
                                    type="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <div className="plandropdowninsidediv">
                                      <div className="plandropdowninsideeachdiv">
                                        <span className="plantimespan">
                                          1 Year &nbsp;&nbsp;@
                                        </span>
                                        <span className="planmainpricespan">
                                          {" "}
                                          {this.state.ITRFBPPY}
                                        </span>
                                      </div>
                                      <div className="planpricesavediv">
                                        <span className="planlinecutpricespan">
                                        {this.state.ITRFBPPYnodis}
                                        </span>
                                        <br />
                                        <span className="plansavespan">
                                          {" "}
                                          Save {this.state.ITRFBPPYDisPer}
                                        </span>
                                      </div>
                                    </div>
                                  </button>
                                  <ul
                                    className="dropdown-menu fourdropdown-menu plandropdownul"
                                    aria-labelledby="dLabel"
                                  >
                                    <li
                                      value="Y"
                                      className="linebtplandrop"
                                      onClick={this.fourdropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.ITRFBPPY}
                                          </span>
                                        </div>
                                        <div className="planpricesavediv">
                                          <span className="planlinecutpricespan">
                                          {this.state.ITRFBPPYnodis}
                                          </span>
                                          <br />
                                          <span className="plansavespan">
                                            {" "}
                                            Save {this.state.ITRFBPPYDisPer}
                                          </span>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Y2"
                                      onClick={this.fourdropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            2 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.ITRFBPPY2}
                                          </span>
                                        </div>
                                        <div className="planpricesavediv">
                                          <span className="planlinecutpricespan">
                                          {this.state.ITRFBPPY2nodis}
                                          </span>
                                          <br />
                                          <span className="plansavespan">
                                            {" "}
                                            Save {this.state.ITRFBPPY2DisPer}
                                          </span>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="hidebusscon4">
                                <center>
                                  <button
                                    className="getform4 plangetstartedbtn"
                                    id="b4"
                                    onClick={this.fourdropdowngetstartedclick}
                                  >
                                    GET STARTED
                                  </button>
                                  <button
                                    className="addtocartbutton2"
                                    id="submit"
                                    onClick={
                                        this.addToCartfourdropdowngetstartedclick
                                    }
                                  >
                                    Add to cart &nbsp;
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path_33782_1.svg"
                                      }
                                    />
                                  </button>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/img/loader.gif"
                                    }
                                    className="addserviceloader"
                                    id="addserviceloaderid4"
                                  />
                                </center>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Estimated Income/Turnover (section 44AD/44ADA)
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Bank Transaction view
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  P&L and balance sheet preparation
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Income from other than business or profession
                                  included
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Filing through Balance Sheet
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Mobile application support
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Documents sharing facility
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Download deliverables any time
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Password sharing
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Round the clock support
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Time to time updates & notifications
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 businesscol">
                        <div className="salariedpremiumcard">
                          <p className="planmainheading">
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/premium star.svg"
                              }
                              className="premiumstarimg"
                            />
                            premium{" "}
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/premium star.svg"
                              }
                              className="premiumstarimg"
                            />
                          </p>
                          <div className="plancardinsidepadding">
                            <div className="formplacer">
                              <div className="plandropdownpadding">
                                <p className="selectatenureheading">
                                  Select the Tenure:
                                </p>
                                <div className="dropdown">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Icon ionic-ios-arrow-down.svg"
                                    }
                                    className="servicedropdownimg servicedropdownimg5"
                                  />
                                  <button
                                    id="dLabel"
                                    className="dropdown-select fivedropdown plandropdownbutton"
                                    type="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <div className="plandropdowninsidediv">
                                      <div className="plandropdowninsideeachdiv">
                                        <span className="plantimespan">
                                          1 Year &nbsp;&nbsp;@
                                        </span>
                                        <span className="planmainpricespan">
                                          {" "}
                                          {this.state.ITRFBPRY}
                                        </span>
                                      </div>
                                      <div className="planpricesavediv">
                                        <span className="planlinecutpricespan">
                                        {this.state.ITRFBPRYnodis}
                                        </span>
                                        <br />
                                        <span className="plansavespan">
                                          {" "}
                                          Save {this.state.ITRFBPRYDisPer}
                                        </span>
                                      </div>
                                    </div>
                                  </button>
                                  <ul
                                    className="dropdown-menu fivedropdown-menu plandropdownul"
                                    aria-labelledby="dLabel"
                                  >
                                    <li
                                      value="Y"
                                      className="linebtplandrop"
                                      onClick={this.fivedropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.ITRFBPRY}
                                          </span>
                                        </div>
                                        <div className="planpricesavediv">
                                          <span className="planlinecutpricespan">
                                          {this.state.ITRFBPRYnodis}
                                          </span>
                                          <br />
                                          <span className="plansavespan">
                                            {" "}
                                            Save {this.state.ITRFBPRYDisPer}
                                          </span>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Y2"
                                      onClick={this.fivedropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            2 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.ITRFBPRY2}
                                          </span>
                                        </div>
                                        <div className="planpricesavediv">
                                          <span className="planlinecutpricespan">
                                          {this.state.ITRFBPRY2nodis}
                                          </span>
                                          <br />
                                          <span className="plansavespan">
                                            {" "}
                                            Save {this.state.ITRFBPRY2DisPer}
                                          </span>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="hidebusscon5">
                                <center>
                                  <button
                                    className="getform5 plangetstartedbtn"
                                    id="b5"
                                    onClick={this.fivedropdowngetstartedclick}
                                  >
                                    GET STARTED
                                  </button>
                                  <button
                                    className="addtocartbutton2"
                                    id="submit"
                                    onClick={
                                        this.addToCartfivedropdowngetstartedclick
                                    }
                                  >
                                    Add to cart &nbsp;
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path_33782_1.svg"
                                      }
                                    />
                                  </button>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/img/loader.gif"
                                    }
                                    className="addserviceloader"
                                    id="addserviceloaderid5"
                                  />
                                </center>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Estimated Income/Turnover (section 44AD/44ADA)
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Bank Transaction view
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  P&L and balance sheet preparation
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Income from other than business or profession
                                  included
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Filing through Balance Sheet
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Filing through Audit Report
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Mobile application support
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Documents sharing facility
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Download deliverables any time
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Password sharing
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Round the clock support
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Time to time updates & notifications
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="Salarieddiv">
                    <br />
                    <div className="row paddingmarginzero">
                      <div className="col-md-3 col-sm-6 Salariedcol">
                        <div className="bluestartercard">
                          <p className="planmainheading">Starter</p>
                          <div className="plancardinsidepadding">
                            <div className="formplacer">
                              <div className="plandropdownpadding">
                                <p className="selectatenureheading">
                                  Select the Tenure:
                                </p>
                                <div className="dropdown">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Icon ionic-ios-arrow-down.svg"
                                    }
                                    className="salarieddropdownimg servicedropdownimg6"
                                  />
                                  <button
                                    id="dLabel"
                                    className="dropdown-select sixdropdown plandropdownbutton"
                                    type="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <div className="plandropdowninsidediv">
                                      <div className="plandropdowninsideeachdiv">
                                        <span className="plantimespan">
                                          1 Year &nbsp;&nbsp;@
                                        </span>
                                        <span className="planmainpricespan">
                                          {" "}
                                          {this.state.ITRFSSY}
                                        </span>
                                      </div>
                                      <div className="planpricesavediv">
                                        <span className="planlinecutpricespan">
                                        {this.state.ITRFSSYnodis}
                                        </span>
                                        <br />
                                        <span className="plansavespan">
                                          {" "}
                                          Save {this.state.ITRFSSYDisPer}
                                        </span>
                                      </div>
                                    </div>
                                  </button>
                                  <ul
                                    className="dropdown-menu sixdropdown-menu plandropdownul"
                                    aria-labelledby="dLabel"
                                  >
                                    <li
                                      value="Y"
                                      className="linebtplandrop"
                                      onClick={this.sixdropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.ITRFSSY}
                                          </span>
                                        </div>
                                        <div className="planpricesavediv">
                                          <span className="planlinecutpricespan">
                                          {this.state.ITRFSSYnodis}
                                          </span>
                                          <br />
                                          <span className="plansavespan">
                                            {" "}
                                            Save {this.state.ITRFSSYDisPer}
                                          </span>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Y2"
                                      onClick={this.sixdropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            2 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.ITRFSSY2}
                                          </span>
                                        </div>
                                        <div className="planpricesavediv">
                                          <span className="planlinecutpricespan">
                                          {this.state.ITRFSSY2nodis}
                                          </span>
                                          <br />
                                          <span className="plansavespan">
                                            {" "}
                                            Save {this.state.ITRFSSY2DisPer}
                                          </span>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="hidesalcon1">
                                <center>
                                  <button
                                    className="getform6 plangetstartedbtn"
                                    id="s1"
                                    onClick={this.sixdropdowngetstartedclick}
                                  >
                                    GET STARTED
                                  </button>
                                  <button
                                    className="addtocartbutton2"
                                    id="submit"
                                    onClick={
                                        this.addToCartsixdropdowngetstartedclick
                                    }
                                  >
                                    Add to cart &nbsp;
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path_33782_1.svg"
                                      }
                                    />
                                  </button>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/img/loader.gif"
                                    }
                                    className="addserviceloader"
                                    id="addserviceloaderid6"
                                  />
                                </center>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Form-16
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Dashboard for managing service
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Mobile application support
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Documents sharing facility
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Download deliverables any time
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Password sharing
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Round the clock support
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Time to time updates & notifications
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 Salariedcol">
                        <div className="basicplancard">
                          <p className="planmainheading">basic</p>
                          <div className="plancardinsidepadding">
                            <div className="formplacer">
                              <div className="plandropdownpadding">
                                <p className="selectatenureheading">
                                  Select the Tenure:
                                </p>
                                <div className="dropdown">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Icon ionic-ios-arrow-down.svg"
                                    }
                                    className="salarieddropdownimg servicedropdownimg7"
                                  />
                                  <button
                                    id="dLabel"
                                    className="dropdown-select sevendropdown plandropdownbutton"
                                    type="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <div className="plandropdowninsidediv">
                                      <div className="plandropdowninsideeachdiv">
                                        <span className="plantimespan">
                                          1 Year &nbsp;&nbsp;@
                                        </span>
                                        <span className="planmainpricespan">
                                          {" "}
                                          {this.state.ITRFSBY}
                                        </span>
                                      </div>
                                      <div className="planpricesavediv">
                                        <span className="planlinecutpricespan">
                                        {this.state.ITRFSBYnodis}
                                        </span>
                                        <br />
                                        <span className="plansavespan">
                                          {" "}
                                          Save {this.state.ITRFSBYDisPer}
                                        </span>
                                      </div>
                                    </div>
                                  </button>
                                  <ul
                                    className="dropdown-menu sevendropdown-menu plandropdownul"
                                    aria-labelledby="dLabel"
                                  >
                                    <li
                                      value="Y"
                                      className="linebtplandrop"
                                      onClick={this.sevendropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.ITRFSBY}
                                          </span>
                                        </div>
                                        <div className="planpricesavediv">
                                          <span className="planlinecutpricespan">
                                          {this.state.ITRFSBYnodis}
                                          </span>
                                          <br />
                                          <span className="plansavespan">
                                            {" "}
                                            Save {this.state.ITRFSBYDisPer}
                                          </span>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Y2"
                                      onClick={this.sevendropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            2 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.ITRFSBY2}
                                          </span>
                                        </div>
                                        <div className="planpricesavediv">
                                          <span className="planlinecutpricespan">
                                          {this.state.ITRFSBY2nodis}
                                          </span>
                                          <br />
                                          <span className="plansavespan">
                                            {" "}
                                            Save {this.state.ITRFSBY2DisPer}
                                          </span>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="hidesalcon2">
                                <center>
                                  <button
                                    className="getform7 plangetstartedbtn"
                                    id="s2"
                                    onClick={this.sevendropdowngetstartedclick}
                                  >
                                    GET STARTED
                                  </button>
                                  <button
                                    className="addtocartbutton2"
                                    id="submit"
                                    onClick={
                                        this.addToCartsevendropdowngetstartedclick
                                    }
                                  >
                                    Add to cart &nbsp;
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path_33782_1.svg"
                                      }
                                    />
                                  </button>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/img/loader.gif"
                                    }
                                    className="addserviceloader"
                                    id="addserviceloaderid7"
                                  />
                                </center>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Form-16
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  With Arrears of Salary
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Dashboard for managing service
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Mobile application support
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Documents sharing facility
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Download deliverables any time
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Password sharing
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Round the clock support
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Time to time updates & notifications
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 Salariedcol">
                        <div className="salariedprocard">
                          <img
                            src={process.env.PUBLIC_URL + "/img/Group_3320.svg"}
                            className="salariedcchocieporimg"
                          />
                          <p className="planmainheading">pro</p>
                          <div className="plancardinsidepadding">
                            <div className="formplacer">
                              <div className="plandropdownpadding">
                                <p className="selectatenureheading">
                                  Select the Tenure:
                                </p>
                                <div className="dropdown">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Icon ionic-ios-arrow-down.svg"
                                    }
                                    className="salarieddropdownimg servicedropdownimg8"
                                  />
                                  <button
                                    id="dLabel"
                                    className="dropdown-select eightdropdown plandropdownbutton"
                                    type="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <div className="plandropdowninsidediv">
                                      <div className="plandropdowninsideeachdiv">
                                        <span className="plantimespan">
                                          1 Year &nbsp;&nbsp;@
                                        </span>
                                        <span className="planmainpricespan">
                                          {" "}
                                          {this.state.ITRFSPY}
                                        </span>
                                      </div>
                                      <div className="planpricesavediv">
                                        <span className="planlinecutpricespan">
                                        {this.state.ITRFSPYnodis}
                                        </span>
                                        <br />
                                        <span className="plansavespan">
                                          {" "}
                                          Save {this.state.ITRFSPYDisPer}
                                        </span>
                                      </div>
                                    </div>
                                  </button>
                                  <ul
                                    className="dropdown-menu eightdropdown-menu plandropdownul"
                                    aria-labelledby="dLabel"
                                  >
                                    <li
                                      value="Y"
                                      className="linebtplandrop"
                                      onClick={this.eightdropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.ITRFSPY}
                                          </span>
                                        </div>
                                        <div className="planpricesavediv">
                                          <span className="planlinecutpricespan">
                                          {this.state.ITRFSPYnodis}
                                          </span>
                                          <br />
                                          <span className="plansavespan">
                                            {" "}
                                            Save {this.state.ITRFSPYDisPer}
                                          </span>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Y2"
                                      onClick={this.eightdropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            2 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.ITRFSPY2}
                                          </span>
                                        </div>
                                        <div className="planpricesavediv">
                                          <span className="planlinecutpricespan">
                                          {this.state.ITRFSPY2nodis}
                                          </span>
                                          <br />
                                          <span className="plansavespan">
                                            {" "}
                                            Save {this.state.ITRFSPY2DisPer}
                                          </span>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="hidesalcon3">
                                <center>
                                  <button
                                    className="getform8 plangetstartedbtn"
                                    id="s3"
                                    onClick={this.eightdropdowngetstartedclick}
                                  >
                                    GET STARTED
                                  </button>
                                  <button
                                    className="addtocartbutton2"
                                    id="submit"
                                    onClick={
                                        this.addToCarteightdropdowngetstartedclick
                                    }
                                  >
                                    Add to cart &nbsp;
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path_33782_1.svg"
                                      }
                                    />
                                  </button>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/img/loader.gif"
                                    }
                                    className="addserviceloader"
                                    id="addserviceloaderid8"
                                  />
                                </center>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Form-16
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  With Arrears of Salary
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Additional Income from other than Form-16
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Dashboard for managing service
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Mobile application support
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Documents sharing facility
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Download deliverables any time
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Password sharing
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Round the clock support
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Time to time updates & notifications
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 Salariedcol">
                        <div className="salariedpremiumcard">
                          <p className="planmainheading">
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/premium star.svg"
                              }
                              className="premiumstarimg"
                            />
                            premium{" "}
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/premium star.svg"
                              }
                              className="premiumstarimg"
                            />
                          </p>
                          <div className="plancardinsidepadding">
                            <div className="formplacer ">
                              <div className="plandropdownpadding">
                                <p className="selectatenureheading">
                                  Select the Tenure:
                                </p>
                                <div className="dropdown">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Icon ionic-ios-arrow-down.svg"
                                    }
                                    className="salarieddropdownimg servicedropdownimg9"
                                  />
                                  <button
                                    id="dLabel"
                                    className="dropdown-select ninedropdown plandropdownbutton"
                                    type="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <div className="plandropdowninsidediv">
                                      <div className="plandropdowninsideeachdiv">
                                        <span className="plantimespan">
                                          1 Year &nbsp;&nbsp;@
                                        </span>
                                        <span className="planmainpricespan">
                                          {" "}
                                          {this.state.ITRFSPRY}
                                        </span>
                                      </div>
                                      <div className="planpricesavediv">
                                        <span className="planlinecutpricespan">
                                        {this.state.ITRFSPRYnodis}
                                        </span>
                                        <br />
                                        <span className="plansavespan">
                                          {" "}
                                          Save {this.state.ITRFSPRYDisPer}
                                        </span>
                                      </div>
                                    </div>
                                  </button>
                                  <ul
                                    className="dropdown-menu ninedropdown-menu plandropdownul"
                                    aria-labelledby="dLabel"
                                  >
                                    <li
                                      value="Y"
                                      className="linebtplandrop"
                                      onClick={this.ninedropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.ITRFSPRY}
                                          </span>
                                        </div>
                                        <div className="planpricesavediv">
                                          <span className="planlinecutpricespan">
                                          {this.state.ITRFSPRYnodis}
                                          </span>
                                          <br />
                                          <span className="plansavespan">
                                            {" "}
                                            Save {this.state.ITRFSPRYDisPer}
                                          </span>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Y2"
                                      onClick={this.ninedropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            2 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.ITRFSPRY2}
                                          </span>
                                        </div>
                                        <div className="planpricesavediv">
                                          <span className="planlinecutpricespan">
                                          {this.state.ITRFSPRY2nodis}
                                          </span>
                                          <br />
                                          <span className="plansavespan">
                                            {" "}
                                            Save {this.state.ITRFSPRY2DisPer}
                                          </span>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="hidesalcon4">
                                <center>
                                  <button
                                    className="getform9 plangetstartedbtn"
                                    id="s4"
                                    onClick={this.ninedropdowngetstartedclick}
                                  >
                                    GET STARTED
                                  </button>
                                  <button
                                    className="addtocartbutton2"
                                    id="submit"
                                    onClick={
                                        this.addToCartninedropdowngetstartedclick
                                    }
                                  >
                                    Add to cart &nbsp;
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path_33782_1.svg"
                                      }
                                    />
                                  </button>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/img/loader.gif"
                                    }
                                    className="addserviceloader"
                                    id="addserviceloaderid9"
                                  />
                                </center>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Form-16
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  With Arrears of Salary
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Additional Income from other than Form-16
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Balance Sheet
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Dashboard for managing service
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Mobile application support
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Documents sharing facility
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Download deliverables any time
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Password sharing
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Round the clock support
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Time to time updates & notifications
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="myDIV">

<div className='supportDetails'>
    <img
        src={process.env.PUBLIC_URL + "/img/24-7_Support.png"}

        alt="image"
    />
    <div>
        <h5>{supportName}</h5>
        <p>Agent Support Executive</p>
        <span><b>Contact: </b> +91 {supportContact}</span>
        <p className='mb-1'><b>Working Hours: </b> 11:00 AM - 06:30 PM</p>
    </div>
</div>


</div>
<button className="support" onClick={this.handlesupport} >
<img src={process.env.PUBLIC_URL + "/img/headPhone.svg"} width="50%" height="50px" />
</button>

            <Footer />
          </div>
        );
      }
      else {
        return <Redirect to="/dashboard" />;
      }
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default Addserviceitrf;

import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "./Header";
import Footer from "./Footer";
import $, { data } from "jquery";
import axios from "axios";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import filterFn2 from "./TargetedFilterFcn";
import Sidebar from "./Sidebar";
import JSZipUtils from "jszip-utils";
import JSZip from "jszip";

let Decagentid;
let Decjwttoken;
var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);
let Doclink;
let Docname;
let baseUrl = process.env.REACT_APP_API_BASE_URL;
export class Agentsupport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // agentid: "PA1",
      myagentdata: [],
      myuserdata: [],
      agent: true,
      agentquerytype: "Query related to",
      commDropDownValue: "AQ",
      agentsubject: "",
      agentdesc: "",
      type: "agent",
      pageCount: 0,
      entry: 10,
      currentPage: 0,
      sharewhatsapp_radio: "Personal",
      shareemail_radio: "Personal",
      whatsppcontactno: "",
      Selectedwhatsapptimeperiod: "select",
      Selectedemailtimeperiod: "select",
      customwhatsappdate: "",
      customemaildate: "",
      sharedocumentname: "",
      sharedocumentlink: "",
      emailmail: "",
      ticket: false,
      promotionall: [],
      promotionfl: [],
      promotiontl: [],
      promotiontr: [],
      promotiongumasta: [],
      promotiongstr: [],
      promotiongstrf: [],
      promotionoa: [],
      promotionitrf: [],
      promotionalmaterial: [],
      fileName: "",
      fileBase64: "",
    };
  }

  handlePageClick = ({ selected: selectedPage }) => {
    this.setState({ myuserdata: [], myagentdata: [] });
    this.setState({ currentPage: selectedPage }, this.loadticket);
  };
  componentDidMount = () => {
    window.scrollTo(0, 0);
    $(document.body).css("background-color", "#2d2d2d");
    $("#agentquerybtn").show();
    const script = document.createElement("script");
    script.src = "/js/main.js";
    script.async = true;

    document.body.appendChild(script);
    let mainagentid = localStorage.getItem("qazxswedcvfrtgb");
    Decagentid = encryptor.decrypt(mainagentid);
    let mainjwttoken = localStorage.getItem("toeljgtkewlna");
    Decjwttoken = encryptor.decrypt(mainjwttoken);

    this.Fetchpromotionmaterial();
    if (this.props.location.state) {
      if (this.props.location.state.type == "user") {
        $("#summary1").removeClass("active");
        $("#history1").addClass("active");
        $("#summary2").removeClass("active");
        $("#history2").addClass("active");
        $(".tab-content").hide();
        $(".tab-content").eq(1).show();
        $("#ticket-agent1").click();
        $(".comm__slider9").css("width", "26%");
        $("#notForSmallScreens").hide();
        this.setState(
          { type: "user", commDropDownValue: "UQ" },
          this.loadticket
        );
        this.props.history.replace("/support", null);
      } else if (this.props.location.state.type == "editdetails") {
        this.setState({ agentquerytype: "EDIT" });
        this.props.history.replace("/support", null);
      }

      filterFn2("AQTable");
      filterFn2("UQTable");
    }
  };
  handlewhatsppcontactno = (e) => {
    this.setState({
      whatsppcontactno: e.target.value,
    });
  };
  handleemailmail = (e) => {
    this.setState({
      emailmail: e.target.value,
    });
  };

  handlecustomwhatsappdateChange = (e) => {
    this.setState({
      customwhatsappdate: e.target.value,
    });
  };

  handlecustomemaildateChange = (e) => {
    this.setState({
      customemaildate: e.target.value,
    });
  };

  handlewhatsapptimeperiodChange = (e) => {
    this.setState({
      Selectedwhatsapptimeperiod: e.target.value,
    });
    if (e.target.value == "custom") {
      $("#whatsappdateinput").show();
    } else if (e.target.value == "always") {
      $("#whatsappdateinput").hide();
      $(".sharedocmentwhatsapplifeerrormessage").show();
    } else {
      $("#whatsappdateinput").hide();
      $(".sharedocmentwhatsapplifeerrormessage").hide();
    }
  };
  handleemailtimeperiodChange = (e) => {
    $(".sharedocmentemaildateerrormessage").hide();
    this.setState({
      Selectedemailtimeperiod: e.target.value,
    });
    if (e.target.value == "custom") {
      $("#emaildateinput").show();
    } else if (e.target.value == "always") {
      $("#emaildateinput").hide();
      $(".sharedocmentemaillifeerrormessage").show();
    } else {
      $("#emaildateinput").hide();
      $(".sharedocmentemaillifeerrormessage").hide();
    }
  };
  submitemail = () => {
    if (this.state.emailmail.trim() === "") {
      $(".sharedocmentemailerrormessage").show();
      $(".sharedocmentemailerrormessage").html("Please enter  E-mail");
    } else if (this.state.emailmail.trim().length > 50) {
      $(".sharedocmentemailerrormessage").show();
      $(".sharedocmentemailerrormessage").html(
        "E-mail should be less than 50 characters"
      );
    } else if (
      !this.state.emailmail
        .trim()
        .match(/^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i)
    ) {
      $(".sharedocmentemailerrormessage").show();
      $(".sharedocmentemailerrormessage").html("Please enter  valid E-mail");
    } else {
      var localemailmail = this.state.emailmail;

      window.open(
        `mailto:${localemailmail}?subject=Shared%20Documents%20-%20Legal251&body=Hi%20There%20!!%20I%20am%20sharing%20following%20documents%20with%20you%20via%20my%20Legal251%20Panel:-%0a${Docname}%0a${Doclink}`,
        "_blank"
      );
    }
  };

  submitwhatsapp = () => {
    if (this.state.whatsppcontactno.trim() === "") {
      $(".sharedocmentwhatsapperrormessage").show();
      $(".sharedocmentwhatsapperrormessage").html("Please enter  Mobile No");
    } else if (!this.state.whatsppcontactno.trim().match(/^[6-9]\d{9}$/)) {
      $(".sharedocmentwhatsapperrormessage").show();
      $(".sharedocmentwhatsapperrormessage").html(
        "Please enter  valid Mobile No"
      );
    } else {
      var localwhatsppcontactno = this.state.whatsppcontactno;

      $("#whatsappdateinput").hide();
      window.open(
        `https://web.whatsapp.com/send?phone=91${localwhatsppcontactno}&text=Hi%20There%20!!%20I%20am%20sharing%20following%20documents%20with%20you%20via%20my%20Legal251%20Panel:-%0a${Docname}%0a${Doclink}`,
        "_blank"
      );
    }
  };

  sharewhatsapp_radioChange = (e) => {
    this.setState({
      sharewhatsapp_radio: e.currentTarget.value,
    });
  };
  shareemail_radioChange = (e) => {
    this.setState({
      shareemail_radio: e.currentTarget.value,
    });
  };
  whatsapp = () => {
    $(".myAddModal1").modal("show");
    $(".myAddModal1").modal({ backdrop: "static", keyboard: false });
  };
  email = () => {
    $(".myAddModal2").modal("show");
    $(".myAddModal2").modal({ backdrop: "static", keyboard: false });
  };
  logout = () => {
    localStorage.removeItem("gglltakoinoeenl");
    localStorage.removeItem("toeljgtkewlna");
    localStorage.removeItem("qazxswedcvfrtgb");
    localStorage.removeItem("lshhihfnkff");
    this.props.history.push({
      pathname: `/`,
    });
  };
  loadticket = () => {
    $("#ticketagent-loader").show();
    $("#ticketagent-empty").hide();
    $("#ticketuser-empty").hide();

    axios
      .post(
        `${baseUrl}p=ticket`,
        {
          agentId: Decagentid.trim(),
          type: this.state.type,
          pageCount: this.state.currentPage + 1,
          entry: this.state.entry,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == 200 && res.data.status == "success") {
          $("#ticketagent-loader").hide();
          if (this.state.type == "user") {
            this.setState({
              pageCount: res.data.totalpageCount,
              myuserdata: res.data.tickets,
            });
          } else {
            this.setState({
              pageCount: res.data.totalpageCount,
              myagentdata: res.data.tickets,
            });
          }
          if (this.state.type == "agent" && res.data.tickets.length == 0) {
            $("#ticketagent-empty").show();
            $("#ticketuser-empty").hide();
          } else if (
            this.state.type == "user" &&
            res.data.tickets.length == 0
          ) {
            $("#ticketagent-empty").hide();
            $("#ticketuser-empty").show();
          }
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {});
  };
  handledropCommDownValueChange = (e) => {
    this.setState({ commDropDownValue: e.target.value });

    if (e.target.value == "AQ") {
      this.setState({ type: "agent" }, this.handlePageClick({ selected: 0 }));
    } else {
      this.setState({ type: "user" }, this.handlePageClick({ selected: 0 }));
    }
  };
  renderoldest = () => {
    return this.state.myuserdata
      .slice()
      .reverse()
      .map((data, index) => {
        return (
          <tr key={index}>
            <td scope="row" className="snousers">
              {this.state.entry * this.state.currentPage + index + 1}
            </td>
            <td className="useridsupportusers userid-td">{data.queryNumber}</td>
            <td className="datendtimeusers userid-td">{data.Dtime}</td>
            <td className="servicessupportusers">{data.queryType}</td>
            <td className="emailusers">{data.email}</td>
            <td className="contactusers ">{data.contact}</td>
            <td className="useridusers">
              {data.userId}
              {/* {data.services.length == 0 ? (
                <span>-</span>
              ) : (
                data.services.map((numbers, index) => {
  
                  if (index == data.services.length - 1) {
                    return <span key={index}>{numbers}</span>;
                  } else {
                    return <span key={index}>{numbers + ","}</span>;
                  }
                })
              )} */}
            </td>
            <td className="statususers">{data.status}</td>

            {/* <td className="status">{data.status}</td> */}
            <td className="detailsusers">
              <button
                className="viewbtn"
                onClick={() => {
                  $("#Query-Desc").modal("show");
                  $("#subject-para").text(data.subject);
                  $("#desc-para").text(data.description);
                }}
              >
                View{" "}
                <img src={process.env.PUBLIC_URL + "/img/Path 19498.svg"} />
              </button>
            </td>
            <td className="detailsusers">
              {data.attachmenturl == "-" ? (
                <>-</>
              ) : (
                <a className="viewbtn" href={data.attachmenturl}>
                  View{" "}
                  <img src={process.env.PUBLIC_URL + "/img/Path 19498.svg"} />
                </a>
              )}
            </td>
          </tr>
        );
      });
  };

  checkQuery = (queryType) => {
    switch (queryType) {
      case "RF":
        return "Refund";

      case "PYR":
        // code block
        return "Payment Related";
      case "AR":
        // code block
        return "Agent Related";
      case "TI":
        // code block
        return "Technical Issue";
      case "AMC":
        // code block
        return "Account Management and Changes";
      case "UAA":
        // code block
        return "Unable to Access Account";
      case "TNR":
        // code block
        return "Team Not Responding";
      case "TAMD":
        // code block
        return "Team asking unnecessary / more documents";
      case "SNV":
        // code block
        return "Service not visible";
      case "SW":
        // code block
        return "Slow working";
      case "II":
        // code block
        return "Invoicing Issue";
      default:
        // code block
        return "Other";
    }
  };

  renderlatestdata = () => {
    return this.state.myagentdata.map((data, index) => {
      return (
        <tr key={index}>
          <td scope="row" className="sno">
            {this.state.entry * this.state.currentPage + index + 1}
          </td>
          <td className="useridsupport userid-td">{data.queryNumber}</td>
          <td className="datendtime userid-td">{data.Dtime}</td>
          <td className="servicessupport">{data.queryType}</td>
          <td className="status">{data.status}</td>

          <td className="descsupport">
            {" "}
            <button
              className="viewbtn"
              onClick={() => {
                $("#Query-Desc").modal("show");
                $("#subject-para").text(data.subject);
                $("#desc-para").text(data.description);
              }}
            >
              View <img src={process.env.PUBLIC_URL + "/img/Path 19498.svg"} />
            </button>
            {/* {data.services.length == 0 ? (
              <span>-</span>
            ) : (
              data.services.map((numbers, index) => {
             
                if (index == data.services.length - 1) {
                  return <span key={index}>{numbers}</span>;
                } else {
                  return <span key={index}>{numbers + ","}</span>;
                }
              })
            )} */}
          </td>
          <td className="detailsusers">
            {data.attachmenturl == "-" ? (
              <>-</>
            ) : (
              <a className="viewbtn" href={data.attachmenturl}>
                View{" "}
                <img src={process.env.PUBLIC_URL + "/img/Path 19498.svg"} />
              </a>
            )}
          </td>
          {/* <td className="status">{data.status}</td>
          <td className="details">
            <button className="viewbtn" onClick={() => this.clickViewBtn(data)}>
              View <img src={process.env.PUBLIC_URL + "/img/Path 19498.svg"} />
            </button>
          </td> */}
        </tr>
      );
    });
  };

  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };

  handleagentsubmit = () => {
    $("#invalidagentquerytype").hide();
    $("#invalidagentsubject").hide();
    $("#invalidagentdesc").hide();
    $("#nullagentsubject").hide();
    $("#nullagentdesc").hide();
    if (this.state.agentquerytype == "Query related to") {
      $("#invalidagentquerytype").show();
      $("#agentQueryType").focus();
    } else if (this.state.agentsubject.trim() == "") {
      $("#nullagentsubject").show();
      $("#agentsubject").focus();
    } else if (this.state.agentsubject.length > 50) {
      $("#invalidagentsubject").show();
      $("#agentsubject").focus();
    } else if (this.state.agentdesc.trim() == "") {
      $("#nullagentdesc").show();
      $("#agentdesc").focus();
    } else if (this.state.agentdesc.length > 200) {
      $("#invalidagentdesc").show();
      $("#agentdesc").focus();
    } else {
      $(".sendOtpLoader").show();
      $("#agentquerybtn").hide();
      $("#invalidagentquerytype").hide();
      $("#invalidagentsubject").hide();
      $("#invalidagentdesc").hide();
      $("#nullagentsubject").hide();
      $("#nullagentdesc").hide();

      var formData = new FormData();
      formData.append("agentId", Decagentid);
      formData.append("query", this.state.agentquerytype.trim());
      formData.append("subject", this.state.agentsubject.trim());
      formData.append("description", this.state.agentdesc.trim());
      formData.append("file", this.state.fileBase64);
      formData.append("filename", this.state.fileName);
      axios
        .post(`${baseUrl}p=agentSupportquery`, formData, {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        })
        .then((res) => {
          if (res.data.code == 200 && res.data.status == "success") {
            this.showNotification(res.data.message);
            this.setState({
              agentquerytype: "Query related to",
              agentsubject: "",
              agentdesc: "",
              fileName: "",
              fileBase64: "",
            });
            $("#choose_STRecallPlan").show();
            $("#X-btn_STRecallPlan").hide();
            $(".sendOtpLoader").hide();
            $("#agentquerybtn").show();
          } else if (res.data.code == 201 && res.data.status == "fail") {
            this.showNotification(res.data.message);
            $("#agentquerybtn").show();
            $(".sendOtpLoader").hide();
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          }
        })
        .catch((err) => {
          //
          this.showNotification("Something went wrong");
          $(".sendOtpLoader").hide();
          $(".sendOtpBtn").show();
          $("#agentquerybtn").show();
        });
    }
  };

  Fetchpromotionmaterial = () => {
    $(".promotionLoader").show();
    axios
      .post(
        `${baseUrl}p=promotionalMaterial`,
        {
          agentId: Decagentid.trim(),
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        $(".promotionLoader").hide();
        if (res.data.code == 200 && res.data.status == "success") {
          this.setState({
            promotionall: res.data.material.All,
            promotionfl: res.data.material.FL,
            promotiontl: res.data.material.TL,
            promotiontr: res.data.material.TR,
            promotiongumasta: res.data.material.GUMASTA,
            promotiongstr: res.data.material.GSTR,
            promotiongstrf: res.data.material.GSTRF,
            promotionoa: res.data.material.OA,
            promotionitrf: res.data.material.ITRF,
            promotionalmaterial: res.data.material.Promotional,
          });
        } else if (res.data.code == 201 && res.data.status == "fail") {
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        }
      })
      .catch((err) => {
        this.showNotification("Something went wrong");
      });
  };

  isloadticket = () => {
    if (!this.state.ticket) {
      this.loadticket();
    }
  };
  promotionalmaterial = (value) => {
    var promotionmat = [];
    promotionmat = value;

    if (promotionmat.length > 0) {
      return promotionmat.map((activedoc, index) => {
        return (
          <div className="col-xl-2 col-md-4 col-12 mb-4 specialpad" key={index}>
            <div className="card position-relative border-0 supportcard">
              <div className="card-body py-3 px-3 supportcardbody">
                <div
                  className="d-flex flex-column justify-content-center align-items-center cursorpointer supportcardmargin"
                  // onClick={this.handleCheckVaultDocs}
                >
                  <img
                    src={process.env.PUBLIC_URL + "/img/Path 247.svg"}
                    className="vault_card_img supportimg"
                  />
                </div>
                <p className="vault_card_docName">{activedoc.key}</p>
                <div>
                  <div className="d-flex justify-content-around align-items-center">
                    <p className="vault_card_docName">Share via:</p>
                    <img
                      onClick={() => {
                        this.whatsapp();
                        Doclink = activedoc.value;
                        Docname = activedoc.key;
                      }}
                      src={process.env.PUBLIC_URL + "/img/whatsapp.svg"}
                      className="vault_card_socials"
                    />
                    <img
                      onClick={() => {
                        this.email();
                        Doclink = activedoc.value;
                        Docname = activedoc.key;
                      }}
                      src={process.env.PUBLIC_URL + "/img/mail.svg"}
                      className="vault_card_socials"
                    />
                  </div>

                  <div className="d-flex mt-3 justify-content-center">
                    <a
                      href={activedoc.value}
                      className="btn-sm vault_card_btn w-100 py-1 donthoverme"
                      id="download_btn"
                      target="_blank"
                    >
                      Download{" "}
                      <span>
                        <img
                          className="vault_card_btn_img"
                          src="img/download_icon.svg"
                          alt="download"
                        />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else {
      return (
        <>
          <img
            className=" p-2 pl-3 pr-3 promotionLoader"
            src="/img/loader.gif"
            width="130px"
            alt=""
          />
        </>
      );
    }
  };

  promotionshowallfilter = () => {
    $(".promotionmain").show();
  };
  promotionallfilter = () => {
    $(".promotionmain").hide();
    $("#all").show();
  };
  promotionGSTRfilter = () => {
    $(".promotionmain").hide();
    $("#gstr").show();
  };
  promotionGSTRFfilter = () => {
    $(".promotionmain").hide();
    $("#gstrf").show();
  };

  promotionTradeLicencefilter = () => {
    $(".promotionmain").hide();
    $("#tl").show();
  };
  promotionOAfilter = () => {
    $(".promotionmain").hide();
    $("#oa").show();
  };
  promotionITRFfilter = () => {
    $(".promotionmain").hide();
    $("#irtf").show();
  };
  promotionTRfilter = () => {
    $(".promotionmain").hide();
    $("#tr").show();
  };
  promotionFoodLicencefilter = () => {
    $(".promotionmain").hide();
    $("#fl").show();
  };
  promotionPromotionalfilter = () => {
    $(".promotionmain").hide();
    $("#Promotional").show();
  };
  promotionGUMASTAfilter = () => {
    $(".promotionmain").hide();
    $("#all").show();
  };

  // STRecallPlan
  handleSTRecallPlanChange = (e) => {
    const myfile = e.target.files;
    if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
          },
          () => {
            $("#choose_STRecallPlan").hide();
            $("#X-btn_STRecallPlan").show();
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
            });
          }
        });
      $("#choose_STRecallPlan").hide();
      $("#X-btn_STRecallPlan").show();
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  removeSTRecallPlan = () => {
    $("#choose_STRecallPlan").show();
    $("#X-btn_STRecallPlan").hide();
    this.setState({
      fileName: "",
      fileBase64: "",
    });
  };

  render() {
    if (
      localStorage.getItem("gglltakoinoeenl") ==
        process.env.REACT_APP_LOGIN_KEY &&
      localStorage.getItem("toeljgtkewlna") != null &&
      localStorage.getItem("qazxswedcvfrtgb") != null
    ) {
      return (
        <div>
          <div>
            <div className="cont">
              <div id="notifContainer"></div>
              <Helmet>
                <link
                  rel="stylesheet"
                  href={process.env.PUBLIC_URL + "/css/select2.css"}
                />
                <link rel="stylesheet" href="css/services.css" />
              </Helmet>

              <Header />

              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
                className="sidebaropenicon"
              />
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
                className="sidebarbackicon"
              />
              <img
                alt="loading..."
                src={
                  process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"
                }
                className="sidebarexpandicon"
              />

              <div className="overlay1"></div>
              <Sidebar />

              <div className="arrow-left9"></div>
              <div className="white_div">
                <div className="white_box" id="promotion_white_box">
                  <div className="main-row">
                    <div className="agent__support__header">
                      <div className="agent__support__header__1">
                        <img
                          src="/img/Path_231.svg"
                          alt="back"
                          className="agent__useropt__backbtn"
                        />

                        <form>
                          <input
                            type="text"
                            className="agent__comm__search_box  support__search"
                            placeholder="Search by services"
                          />
                        </form>
                      </div>

                      <div
                        className="agent-navigation-tab"
                        id="promotion__heads__1"
                      >
                        <header className="tabs-nav MT-3 position-relative">
                          <ul className="d-flex support-navigation-tab-ul">
                            <li
                              className="active agent-navigation-tab-li"
                              id="summary1"
                            >
                              <a
                                href="#tab1"
                                className="px-2 py-1"
                                onClick={() => this.setState({ ticket: false })}
                              >
                                Support
                              </a>
                            </li>
                            <li
                              className="agent-navigation-tab-li"
                              id="history1"
                            >
                              <a
                                id="ticket-agent1"
                                href="#tab2"
                                onClick={() => {
                                  this.isloadticket();
                                  this.setState({ ticket: true });
                                }}
                                className="px-2 py-1"
                              >
                                Tickets
                              </a>
                            </li>
                            <hr className="comm__navigation__tab__vertical__line__22" />
                            <div className="comm__slider9"></div>
                          </ul>
                        </header>
                      </div>

                      <div className="agent__support__div invisible">
                        <select className="agent__support__selectcateselect">
                          <option
                            value="volvo"
                            className="selectcateselectoption2"
                          >
                            FY
                          </option>
                        </select>
                      </div>
                    </div>

                    {/* ipad */}
                    <div
                      className="agent-navigation-tab"
                      id="promotion__heads__2"
                    >
                      <header className="tabs-nav MT-3 position-relative">
                        <ul className="d-flex support-navigation-tab-ul">
                          <li
                            className="active agent-navigation-tab-li"
                            id="summary2"
                            onClick={() => this.setState({ ticket: false })}
                          >
                            <a href="#tab1">Support</a>
                          </li>
                          <li className="agent-navigation-tab-li" id="history2">
                            <a
                              id="ticket-agent2"
                              href="#tab2"
                              onClick={() => {
                                this.isloadticket();
                                this.setState({ ticket: true });
                              }}
                            >
                              Tickets
                            </a>
                          </li>

                          {/* <hr className="comm__navigation__tab__vertical__line__11" />
                          <hr className="comm__navigation__tab__vertical__line__22" /> */}
                          <div
                            className="comm__slider9"
                            id="notForSmallScreens"
                          ></div>
                        </ul>
                      </header>
                    </div>

                    <div className="tabs-content pb-md-3 mt-md-0 mt-2">
                      <div id="tab1" className="tab-content">
                        <div className="support__scroll_div">
                          <div className="resflex agentSupportdiv">
                            <div className="col-12 col-md-12 col-lg-7 ">
                              <h4 className="mb-3 mt-4 agen">Agent Support:</h4>
                              <h2 className="agsup-head mb-4">
                                Have any query in mind!!
                                <br /> Get support from <span>
                                  Legal251
                                </span>{" "}
                                Team
                              </h2>
                              <p className="m-0 att">Attention:</p>
                              <p className="att2">
                                👉 Expected Wait Time on all Service channels is
                                1-2 days.🙏
                              </p>

                              <div className="agentQueryform mt-2">
                                <h4 className="mb-2 ">Raise Support Query</h4>
                                <p className="m-0 mb-1">
                                  Choose your query type <span>*</span>:
                                </p>
                                <select
                                  name="Query"
                                  id="agentQueryType"
                                  className="query-select "
                                  value={this.state.agentquerytype}
                                  onChange={(e) =>
                                    this.setState({
                                      agentquerytype: e.target.value,
                                    })
                                  }
                                >
                                  <option value="Query related to" disabled>
                                    Query related to
                                  </option>
                                  <option value="AMC">
                                    Account Management & Charges
                                  </option>
                                  <option value="KBA">
                                    KYC & Bank Account
                                  </option>
                                  <option value="CS">
                                    Commission Settlement
                                  </option>
                                  <option value="EDIT">
                                    Edit Profile Details
                                  </option>
                                  <option value="PYR">
                                    Payment Related - fail/other
                                  </option>
                                  <option value="TI">Technical Issue</option>
                                  <option value="CDI">
                                    Change Details & Information
                                  </option>
                                  <option value="CR">Customer Related</option>
                                  <option value="TNR">
                                    Team Not Responding{" "}
                                  </option>
                                  <option value="TAMD">
                                    Team asking unnecessary / more documents{" "}
                                  </option>
                                  <option value="SNV">
                                    Service not visible{" "}
                                  </option>
                                  <option value="SW">Slow working </option>
                                  <option value="II">Invoicing Issue </option>
                                  <option value="O">Other</option>
                                </select>
                                <p
                                  className=" mt-2 warn text-danger align-items-center"
                                  id="invalidagentquerytype"
                                >
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Icon_feather-alert-circle.svg"
                                    }
                                    width="12px"
                                    className=" mr-1"
                                  />{" "}
                                  Please select query type
                                </p>
                                <p className="m-0 mt-4 mb-1">
                                  Please enter your subject here <span>*</span>:
                                </p>
                                <input
                                  id="agentsubject"
                                  className=" query-desc"
                                  maxLength="50"
                                  type="text"
                                  value={this.state.agentsubject}
                                  onChange={(e) =>
                                    this.setState({
                                      agentsubject: e.target.value,
                                    })
                                  }
                                />
                                <p
                                  className=" mt-2 warn text-danger align-items-center"
                                  id="nullagentsubject"
                                >
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Icon_feather-alert-circle.svg"
                                    }
                                    width="12px"
                                    className=" mr-1"
                                  />{" "}
                                  Please enter the subject.
                                </p>
                                <p
                                  className=" mt-2 warn text-danger align-items-center"
                                  id="invalidagentsubject"
                                >
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Icon_feather-alert-circle.svg"
                                    }
                                    width="12px"
                                    className=" mr-1"
                                  />{" "}
                                  Please enter valid input (max length: 50)
                                </p>
                                <p className="m-0 mt-4 mb-1">
                                  Description <span>*</span>:
                                </p>
                                <textarea
                                  id="agentdesc"
                                  className=" query-desc"
                                  maxLength="200"
                                  rows="4"
                                  value={this.state.agentdesc}
                                  onChange={(e) =>
                                    this.setState({ agentdesc: e.target.value })
                                  }
                                ></textarea>
                                <p
                                  className=" mt-2 warn text-danger align-items-center"
                                  id="nullagentdesc"
                                >
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Icon_feather-alert-circle.svg"
                                    }
                                    width="12px"
                                    className=" mr-1"
                                  />{" "}
                                  Please enter the description.
                                </p>
                                <p
                                  className=" mt-2 warn text-danger align-items-center"
                                  id="invalidagentdesc"
                                >
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Icon_feather-alert-circle.svg"
                                    }
                                    width="12px"
                                    className=" mr-1"
                                  />{" "}
                                  Please enter description in 200 words only.
                                </p>

                                <p className="m-0 mt-4 mb-1">
                                  Upload attachment(optional):
                                </p>
                                <label
                                  htmlFor="ul_file_56pSTRecallPlan"
                                  className="choose_btn"
                                  id="choose_STRecallPlan"
                                >
                                  Choose file{" "}
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Path_15188.svg"
                                    }
                                    alt="file"
                                  />
                                </label>
                                <input
                                  type="file"
                                  multiple
                                  name="file[]"
                                  className="upload-photo"
                                  id="ul_file_56pSTRecallPlan"
                                  onClick={(event) => {
                                    event.target.value = null;
                                  }}
                                  onChange={(e) =>
                                    this.handleSTRecallPlanChange(e)
                                  }
                                />
                                <button
                                  className="X-btn"
                                  id="X-btn_STRecallPlan"
                                  onClick={(e) => this.removeSTRecallPlan(e)}
                                >
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Path_1025.svg"
                                    }
                                  />
                                </button>

                                <br />
                                <img
                                  className=" p-2 pl-3 pr-3 sendOtpLoader"
                                  src="/img/loader.gif"
                                  width="80px"
                                  alt=""
                                />
                                <button
                                  className="btn submit-btn text-white mb-3 mt-4"
                                  onClick={this.handleagentsubmit}
                                  id="agentquerybtn"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-5">
                              <p className="forinstantsupportline forinstantsupportline1">
                                For Instant support{" "}
                                <img
                                  alt="loading..."
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/img/lightning.png"
                                  }
                                  className="supportlighticon"
                                />{" "}
                                please call over <span>+91 77728-77729</span>{" "}
                                Monday to Friday ( 11.00 AM - 6.00 PM)
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div id="tab2" className="tab-content">
                        <div className="row justify-content-end mr-1">
                          <div className="dropdown ml-md-0 ml-lg-2 ml-2 px-0">
                            <select
                              className="agent__support__table__selectcateselect"
                              value={this.state.entry}
                              onChange={(e) => {
                                this.setState({
                                  entry: e.target.value,
                                  pageCount: 0,
                                });
                                this.handlePageClick({ selected: 0 });
                              }}
                            >
                              <option
                                value="10"
                                className="selectcateselectoption2"
                              >
                                10
                              </option>
                              <option
                                value="20"
                                className="selectcateselectoption2"
                              >
                                20
                              </option>
                              <option
                                value="50"
                                className="selectcateselectoption2"
                              >
                                50
                              </option>
                              <option
                                value="100"
                                className="selectcateselectoption2"
                              >
                                100
                              </option>
                            </select>
                          </div>
                          <div>
                            <select
                              className="agent__support__table__selectcateselect"
                              value={this.state.commDropDownValue}
                              onChange={this.handledropCommDownValueChange}
                            >
                              <option
                                value="AQ"
                                className="selectcateselectoption2"
                              >
                                Agent Queries
                              </option>
                              <option
                                value="UQ"
                                className="selectcateselectoption2"
                              >
                                User Queries
                              </option>
                            </select>
                          </div>
                        </div>
                        {this.state.commDropDownValue == "AQ" ? (
                          <div className="table-responsive my-table ticket-table">
                            <table className="table mr-3" id="AQTable">
                              <thead className="my-thead ">
                                <tr className="AQTable">
                                  <th className="my-th sno" data-type="number">
                                    Sno
                                    <img
                                      className="arrowImg rot0"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/downArrow.png"
                                      }
                                      height="15px"
                                      alt=""
                                    />
                                  </th>
                                  <th className="my-th useridsupport">
                                    Query no
                                    <img
                                      className="arrowImg rot0"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/downArrow.png"
                                      }
                                      height="15px"
                                      alt=""
                                    />
                                  </th>
                                  <th className="my-th datendtime">
                                    Date & Time
                                    <img
                                      className="arrowImg rot0"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/downArrow.png"
                                      }
                                      height="15px"
                                      alt=""
                                    />
                                  </th>
                                  <th className="my-th servicessupport">
                                    Query type
                                    <img
                                      className="arrowImg rot0"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/downArrow.png"
                                      }
                                      height="15px"
                                      alt=""
                                    />
                                  </th>
                                  <th className="my-th status">
                                    Status
                                    <img
                                      className="arrowImg rot0"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/downArrow.png"
                                      }
                                      height="15px"
                                      alt=""
                                    />
                                  </th>

                                  <th className="my-th descsupport">
                                    Query Details
                                    <img
                                      className="arrowImg rot0"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/downArrow.png"
                                      }
                                      height="15px"
                                      alt=""
                                    />
                                  </th>
                                  <th className="my-th detailsusers">
                                    Attachment{" "}
                                    <img
                                      className="arrowImg rot0"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/downArrow.png"
                                      }
                                      height="15px"
                                      alt=""
                                    />
                                  </th>
                                  {/* <th className="my-th status">Status</th>
                                <th className="my-th details">Details</th> */}
                                </tr>
                              </thead>

                              <div
                                className="loader-abs warn"
                                id="ticketagent-loader"
                              >
                                <img src="/img/loader.gif" />
                              </div>

                              <div
                                className="empty-abs warn"
                                id="ticketagent-empty"
                              >
                                <img
                                  className="nullImg"
                                  src="/img/quiteempty.svg"
                                />
                              </div>

                              <tbody className="my-tbody my-tbodysupport">
                                {this.renderlatestdata()}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <div className="table-responsive my-table ticket-table">
                            <table className="table mr-3" id="UQTable">
                              <thead className="my-thead ">
                                <tr className="UQTable">
                                  <th
                                    className="my-th snousers"
                                    data-type="number"
                                  >
                                    Sno
                                    <img
                                      className="arrowImg rot0"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/downArrow.png"
                                      }
                                      height="15px"
                                      alt=""
                                    />
                                  </th>
                                  <th className="my-th useridsupportusers">
                                    Query no
                                    <img
                                      className="arrowImg rot0"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/downArrow.png"
                                      }
                                      height="15px"
                                      alt=""
                                    />
                                  </th>
                                  <th className="my-th datendtimeusers">
                                    Date & Time
                                    <img
                                      className="arrowImg rot0"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/downArrow.png"
                                      }
                                      height="15px"
                                      alt=""
                                    />
                                  </th>
                                  <th className="my-th servicessupportusers">
                                    Query Type
                                    <img
                                      className="arrowImg rot0"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/downArrow.png"
                                      }
                                      height="15px"
                                      alt=""
                                    />
                                  </th>
                                  <th className="my-th emailusers">
                                    Email
                                    <img
                                      className="arrowImg rot0"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/downArrow.png"
                                      }
                                      height="15px"
                                      alt=""
                                    />
                                  </th>
                                  <th className="my-th contactusers ">
                                    Contact
                                    <img
                                      className="arrowImg rot0"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/downArrow.png"
                                      }
                                      height="15px"
                                      alt=""
                                    />
                                  </th>
                                  <th className="my-th useridusers">
                                    userid
                                    <img
                                      className="arrowImg rot0"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/downArrow.png"
                                      }
                                      height="15px"
                                      alt=""
                                    />
                                  </th>
                                  <th className="my-th statususers">
                                    Status
                                    <img
                                      className="arrowImg rot0"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/downArrow.png"
                                      }
                                      height="15px"
                                      alt=""
                                    />
                                  </th>

                                  <th className="my-th detailsdescusers">
                                    Query Details
                                    <img
                                      className="arrowImg rot0"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/downArrow.png"
                                      }
                                      height="15px"
                                      alt=""
                                    />
                                  </th>
                                  <th className="my-th detailsusers">
                                    Attachment{" "}
                                    <img
                                      className="arrowImg rot0"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/downArrow.png"
                                      }
                                      height="15px"
                                      alt=""
                                    />
                                  </th>
                                </tr>
                              </thead>

                              <div
                                className="loader-abs"
                                id="ticketagent-loader"
                              >
                                <img src="/img/loader.gif" />
                              </div>

                              <div className="empty-abs" id="ticketuser-empty">
                                <img
                                  className="nullImg"
                                  src="/img/quiteempty.svg"
                                />
                              </div>

                              <tbody className="my-tbody my-tbodysupport">
                                {this.renderoldest()}
                              </tbody>
                            </table>
                          </div>
                        )}
                        <div className="mt-3 w-100">
                          <ReactPaginate
                            previousLabel={
                              <img
                                src={
                                  process.env.PUBLIC_URL + "/img/Path_21772.svg"
                                }
                                width="15px"
                              />
                            }
                            nextLabel={
                              <img
                                src={
                                  process.env.PUBLIC_URL + "/img/Path_21773.svg"
                                }
                                width="15px"
                              />
                            }
                            pageCount={this.state.pageCount}
                            onPageChange={this.handlePageClick}
                            forcePage={this.state.currentPage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"pagination__link"}
                            nextLinkClassName={"pagination__link"}
                            disabledClassName={"pagination__link--disabled"}
                            activeClassName={"pagination__link----active"}
                          />
                        </div>
                      </div>

                      <div id="tab3" className="tab-content">
                        <div className="dropdown">
                          <button
                            className="btn header-btn py-1 px-2"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <img
                              alt="loading..."
                              src={process.env.PUBLIC_URL + "/img/Path 210.svg"}
                            />{" "}
                            <span>Filter by</span>
                          </button>
                          <div
                            className="dropdown-menu drop "
                            aria-labelledby="dropdownMenuButton"
                          >
                            <a
                              className="dropdown-item cursorpointer"
                              onClick={this.promotionshowallfilter}
                            >
                              Show all
                            </a>
                            <a
                              className="dropdown-item cursorpointer"
                              onClick={this.promotionallfilter}
                            >
                              All Services
                            </a>
                            <a
                              className="dropdown-item cursorpointer"
                              onClick={this.promotionGSTRfilter}
                            >
                              GSTR
                            </a>
                            <a
                              className="dropdown-item cursorpointer"
                              onClick={this.promotionGSTRFfilter}
                            >
                              GSTRF
                            </a>
                            <a
                              className="dropdown-item cursorpointer"
                              onClick={this.promotionITRFfilter}
                            >
                              ITRF
                            </a>
                            <a
                              className="dropdown-item cursorpointer"
                              onClick={this.promotionTRfilter}
                            >
                              TR
                            </a>
                            <a
                              className="dropdown-item cursorpointer"
                              onClick={this.promotionOAfilter}
                            >
                              OA
                            </a>
                            <a
                              className="dropdown-item cursorpointer"
                              onClick={this.promotionGUMASTAfilter}
                            >
                              GUMASTA
                            </a>
                            <a
                              className="dropdown-item cursorpointer"
                              onClick={this.promotionTradeLicencefilter}
                            >
                              Trade Licence
                            </a>
                            <a
                              className="dropdown-item cursorpointer"
                              onClick={this.promotionFoodLicencefilter}
                            >
                              Food Licence
                            </a>
                            <a
                              className="dropdown-item cursorpointer"
                              onClick={this.promotionPromotionalfilter}
                            >
                              Promotional
                            </a>
                          </div>
                        </div>
                        <div className="vaultscrolldiv vaultscrolldiv2">
                          <div className="container-fluid">
                            <div id="all" className="promotionmain">
                              <p className="agent__overview__head pl-2">
                                All Services :-
                              </p>
                              <div className="row">
                                {this.promotionalmaterial(
                                  this.state.promotionall
                                )}
                              </div>
                            </div>
                            <div id="fl" className="promotionmain">
                              <p className="agent__overview__head pl-2">
                                Food Licence :-
                              </p>
                              <div className="row">
                                {this.promotionalmaterial(
                                  this.state.promotionfl
                                )}
                              </div>
                            </div>
                            <div id="gstr" className="promotionmain">
                              <p className="agent__overview__head pl-2">
                                GST Registration :-
                              </p>
                              <div className="row">
                                {this.promotionalmaterial(
                                  this.state.promotiongstr
                                )}
                              </div>
                            </div>
                            <div id="gstrf" className="promotionmain">
                              <p className="agent__overview__head pl-2">
                                GST Return Filing :-
                              </p>
                              <div className="row">
                                {this.promotionalmaterial(
                                  this.state.promotiongstrf
                                )}
                              </div>
                            </div>
                            <div id="Gumasta" className="promotionmain">
                              <p className="agent__overview__head pl-2">
                                Gumasta Licence(S&E) :-
                              </p>
                              <div className="row">
                                {this.promotionalmaterial(
                                  this.state.promotiongumasta
                                )}
                              </div>
                            </div>
                            <div id="itrf" className="promotionmain">
                              <p className="agent__overview__head pl-2">
                                Income Tax Return :-
                              </p>
                              <div className="row">
                                {this.promotionalmaterial(
                                  this.state.promotionitrf
                                )}
                              </div>
                            </div>
                            <div id="oa" className="promotionmain">
                              <p className="agent__overview__head pl-2">
                                Online Accounting :-
                              </p>
                              <div className="row">
                                {this.promotionalmaterial(
                                  this.state.promotionoa
                                )}
                              </div>
                            </div>
                            <div id="tl" className="promotionmain">
                              <p className="agent__overview__head pl-2">
                                Trade Licence :-
                              </p>
                              <div className="row">
                                {this.promotionalmaterial(
                                  this.state.promotiontl
                                )}
                              </div>
                            </div>
                            <div id="tr" className="promotionmain">
                              <p className="agent__overview__head pl-2">
                                Trademark Registration :-
                              </p>
                              <div className="row">
                                {this.promotionalmaterial(
                                  this.state.promotiontr
                                )}
                              </div>
                            </div>
                            <div id="Promotional" className="promotionmain">
                              <p className="agent__overview__head pl-2">
                                Promotional :-
                              </p>
                              <div className="row">
                                {this.promotionalmaterial(
                                  this.state.promotionalmaterial
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal fade myAddModal1">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content uploadpopupcontent">
                    <div className="modal-body uploadpopupbody">
                      <p className="sharepopupheading">
                        Please enter the recipient's mobile no.
                      </p>
                      <div className="sharepopupinputdiv">
                        <img
                          src={process.env.PUBLIC_URL + "/img/whatsapp.svg"}
                          className="docushareviawhatspopup"
                        />
                        <input
                          type="text"
                          className="sharepopupinput"
                          maxLength="10"
                          placeholder="Type here..."
                          value={this.state.whatsppcontactno}
                          onChange={this.handlewhatsppcontactno}
                        />
                        <p className="sharedocmentwhatsapperrormessage">
                          error message
                        </p>
                      </div>
                      <p className="sharepopupheading text-left">Share via:</p>
                      {/* <input
                    type="radio"
                    id="test30"
                    name="radio-group"
                    value="Yes"
                    checked={this.state.sharewhatsapp_radio === "Yes"}
                    onChange={this.sharewhatsapp_radioChange}
                  />
                  <label htmlFor="test30" className="uploadradiotext">
                    Legal251
                  </label> */}

                      <input
                        type="radio"
                        id="test31"
                        name="radio-group"
                        value="Personal"
                        checked={this.state.sharewhatsapp_radio === "Personal"}
                        onChange={this.sharewhatsapp_radioChange}
                      />
                      <label htmlFor="test31" className="uploadradiotext ">
                        Personal Mobile no.
                      </label>
                      {/* <p className="sharepopupheading text-left">Share for:</p>
                  <select
                    className="selectimeperiod"
                    value={this.state.Selectedwhatsapptimeperiod}
                    onChange={this.handlewhatsapptimeperiodChange}
                  >
                    <option value="select" disabled>
                      select time period
                    </option>
                    <option value="1hr">share for 1 hour</option>
                    <option value="1day">share for 1 day</option>
                    <option value="3days">share for 3 day</option>
                    <option value="1week">share for 1 week</option>
                    <option value="1month">share for 1 month</option>
                    <option value="custom">custom</option>
                    <option value="always">lifetime</option>
                  </select>
                  <p className="sharedocmentwhatsapplifeerrormessage">
                    We don't recommend you to share documents for lifetime for
                    security reasons Instead you can select a custom time
                    period.
                  </p>
                  <p className="sharedocmentwhatsappselecterrormessage">
                    error message
                  </p> */}
                      <input
                        type="date"
                        id="whatsappdateinput"
                        className="othertextinput"
                        value={this.state.customwhatsappdate}
                        onChange={this.handlecustomwhatsappdateChange}
                        placeholder="Type here..."
                      />
                      <p className="sharedocmentwhatsappdateerrormessage">
                        error message
                      </p>
                      <br />
                      <div className="modal_btns_div">
                        <div className="displayflex">
                          <div className="cancelbuttondiv">
                            <button
                              className="cancelbtn cancelbtn_90"
                              data-dismiss="modal"
                            >
                              Cancel
                            </button>
                          </div>
                          <button
                            className="upload_btn20 upload_btn20_90"
                            onClick={this.submitwhatsapp}
                          >
                            Send&nbsp;
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/Path_19481.svg"
                              }
                              alt="download"
                            />
                          </button>
                          <button className="upload_btnloader upload_btnloader_90">
                            <img
                              src={process.env.PUBLIC_URL + "/img/loader.gif"}
                              alt="loading"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />

              <div className="modal fade myAddModal2">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content uploadpopupcontent">
                    <div className="modal-body uploadpopupbody">
                      <p className="sharepopupheading">
                        Please enter the recipient's email ID
                      </p>
                      <div className="sharepopupinputdiv">
                        <img
                          src={process.env.PUBLIC_URL + "/img/Group_4339.svg"}
                          className="docushareviawhatspopup"
                        />
                        <input
                          type="text"
                          className="sharepopupinput"
                          maxLength="50"
                          placeholder="Type here..."
                          value={this.state.emailmail}
                          onChange={this.handleemailmail}
                        />
                        <p className="sharedocmentemailerrormessage">
                          error message
                        </p>
                      </div>
                      <p className="sharepopupheading text-left">Share via:</p>
                      {/* <input
                        type="radio"
                        id="test40"
                        name="radio-group2"
                        value="Legal251"
                        checked={this.state.shareemail_radio === "Legal251"}
                        onChange={this.shareemail_radioChange}
                      />
                      <label htmlFor="test40" className="uploadradiotext">
                        Legal251
                      </label> */}

                      <input
                        type="radio"
                        id="test41"
                        name="radio-group2"
                        value="Personal"
                        checked={this.state.shareemail_radio === "Personal"}
                        onChange={this.shareemail_radioChange}
                      />
                      <label
                        htmlFor="test41"
                        className="uploadradiotext rightradio"
                      >
                        Personal Mail
                      </label>
                      {/* <p className="sharepopupheading text-left">Share for:</p>
                  <select
                    className="selectimeperiod"
                    value={this.state.Selectedemailtimeperiod}
                    onChange={this.handleemailtimeperiodChange}
                  >
                    <option value="select" disabled>
                      select time period
                    </option>
                    <option value="1hr">share for 1 hour</option>
                    <option value="1day">share for 1 day</option>
                    <option value="3days">share for 3 day</option>
                    <option value="1week">share for 1 week</option>
                    <option value="1month">share for 1 month</option>
                    <option value="custom">custom</option>
                    <option value="always">lifetime</option>
                  </select>
                  <p className="sharedocmentemaillifeerrormessage">
                    We don't recommend you to share documents for lifetime for
                    security reasons Instead you can select a custom time
                    period.
                  </p>
                  <p className="sharedocmentemailselecterrormessage">
                    error message
                  </p> */}
                      <input
                        type="date"
                        id="emaildateinput"
                        className="othertextinput"
                        value={this.state.customemaildate}
                        onChange={this.handlecustomemaildateChange}
                        placeholder="Type here..."
                      />
                      <p className="sharedocmentemaildateerrormessage">
                        error message
                      </p>
                      <br />
                      <div className="modal_btns_div">
                        <div className="displayflex">
                          <div className="cancelbuttondiv">
                            <button
                              className="cancelbtn cancelbtn_70"
                              data-dismiss="modal"
                            >
                              Cancel
                            </button>
                          </div>
                          <button
                            className="upload_btn20 upload_btn20_70"
                            onClick={this.submitemail}
                          >
                            Send&nbsp;
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/Path_19481.svg"
                              }
                              alt="download"
                            />
                          </button>
                          <button className="upload_btnloader upload_btnloader_70">
                            <img
                              src={process.env.PUBLIC_URL + "/img/loader.gif"}
                              alt="loading"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="Query-Desc"
                tabIndex="-1"
                role="dialog"
                data-backdrop="static"
                data-keyboard="false"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered "
                  role="document"
                >
                  <div className="modal-content back-modal " id="viewDesc">
                    <div className="w-100 d-flex">
                      <button
                        type="button"
                        className="border-0 bg-transparent ml-auto p-2 pr-3  text-right my-back-modal-close"
                        data-dismiss="modal"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/Page-1.svg"}
                          width="17px"
                        />
                      </button>
                    </div>
                    <div className="modal-body border-0 text-center py-0">
                      <h4 className="my-warn-text unlock-text">Subject</h4>
                      <p
                        className="my-success-para m-0 mt-3 contotp descmodalfont"
                        id="subject-para"
                      ></p>
                      <h4 className="my-warn-text unlock-text mt-3">
                        Description
                      </h4>

                      <p
                        className="my-success-para m-0 mt-3 contotp descmodalfont"
                        id="desc-para"
                      ></p>
                    </div>
                    <div className="modal-footer border-0 justify-content-center mb-4"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default Agentsupport;

import React, { Component } from 'react'
import $ from "jquery";
import Header from "./Header";

let supportName;
let supportContact;
let supportEmail;


export class SupportDetailsMobile extends Component {
  constructor(props) {
    super(props)

    this.state = {
      supportName: "",
      supportContact: "",
      supportEmail: "",
    }
  }





  componentDidMount(){
    supportName = localStorage.getItem("supportName");
    supportContact = localStorage.getItem("supportContact");
    supportEmail = localStorage.getItem("supportEmail");
    this.setState({
      supportName: supportName,
      supportContact: supportContact,
      supportEmail: supportEmail,
    })
  }

  
  render() {
    return (
      <div className='supportDetailsMobile'>
        <div className='supportDetails  mb-2 SupportMobile'>
          <div className='SupportMobilerow ml-0'>
            <img
              src={process.env.PUBLIC_URL + "/img/careIcon.svg"}

              alt="image"
            />
            <div>
              <h5>{this.props.name}</h5>
              <p>{this.props.role}</p>

            </div>
          </div>
          <p className='mb-1 workingHours'><b>Working Hours: </b> 10:30 AM - 07:30 PM</p>

          <div className='SupportMobilerow ml-0'>
            <div className='ml-0'>
              <p className='ContactSupport'><b>Contact: </b></p>
              <p> +91 {this.props.contact.substring(0, 5)}-{this.props.contact.substring(5)}</p>


            </div>
            <div>
              <a href={`tel:+91${this.props.contact}`} className='btn callNowBtn'> <img
                src={process.env.PUBLIC_URL + "/img/callSupport.svg"}

                alt="image"
              /> &nbsp;Call Now</a>
            </div>
          </div>
        </div>

        <div className='supportDetails SupportMobile'>

          <div className='SupportMobilerownew ml-0'>
            <img
                src={process.env.PUBLIC_URL + "/img/24-7_Support.png"}

                alt="image"
              />
            <div>
            <h5>{this.state.supportName}</h5>
            <p>Agent Support Executive</p>

            </div>
          </div>
          <p className='mb-1 workingHours'><b>Working Hours: </b> 11:00 AM - 06:30 PM</p>
          
          <div className='SupportMobilerow ml-0'>
            <div className='ml-0'>
              <p className='ContactSupport'><b>Contact: </b></p>
              <p> +91 {this.state.supportContact}</p>


            </div>
            <div>
              <a href={`tel:+91${this.state.supportContact}`} className='btn callNowBtn'> <img
                src={process.env.PUBLIC_URL + "/img/callSupport.svg"}

                alt="image"
              /> &nbsp;Call Now</a>
            </div>
          </div>


          
        </div>
        <div onClick={() => {
          $(".SupportMobile").toggle("display")
          $(".white_div").click(function () {
            $(".SupportMobile").hide()
          });
        }}>
          <div className='SupportIconMobile ' >
            <img
              src={process.env.PUBLIC_URL + "/img/headPhone.svg"}

              alt="image"
            />
          </div>
        </div>

      </div>
    )
  }
}

export default SupportDetailsMobile
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { Link } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import { Redirect } from "react-router";
import jsonData from "../State__and__dist.json";
import AllPricing from "../data.json";
import json2 from "../State_shortcode.json";

let servicestate;
let Decagentid;
let Decjwttoken;
let Decuserid;
var data = [];
var sessiondata;
let supportName;
let supportContact;
let supportEmail;
let pricingJson;
var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var myform;
var encryptor = require("simple-encryptor")(key);
class Addservicenewuserpfrf extends Component {
  constructor(props) {
    super(props);

    this.state = {
      serviceselectvalue: "",
      citydistrictselectvalue: "",
      servicefullname: "",
      serviceemail: "",
      servicemobileno: "",
      dropDownValue: "1",
      mainpackage: "",
      supportName: "",
      supportContact: "",
      supportEmail: "",

      PFRF1: "",
      PFRF2: "",
      PFRF3: "",
      PFRF4: "",
      PFRF5: "",
      PFRF6: "",

      custName: "",
      custDesignation: "",
      custEmail: "",
      custContactNo: "",
      custTurnover: "",

      // No Discount Price

      // TDSRFY1Snodis: "",
      // TDSRFY1Bnodis: "",
      // TDSRFY2Snodis: "",
      // TDSRFY2Bnodis: "",
      // TDSRFY3Snodis: "",
      // TDSRFY4Snodis: "",

      // // Discount Percentage

      // TDSRFY1SDisPer: "",
      // TDSRFY1BDisPer: "",
      // TDSRFY2SDisPer: "",
      // TDSRFY2BDisPer: "",
      // TDSRFY3SDisPer: "",
      // TDSRFY4SDisPer: "",
    };
  }
  
  firstdropdowngetstartedclick = (e) => {
    $(".plangetstartedbtn").show();
    $(".getform").hide();
    $(e.target).closest(".formplacer").append(myform.slideDown());
    $(".hidesalcon1").hide();
    $(".hidesalcon2").show();
    $(".hidesalcon3").show();
    $(".hidesalcon4").show();
    $(".hidesalcon5").show();
    $(".hidesalcon6").show();
    $(".hidesalcon7").show();
    $(".hidesalcon8").show();
    $(".hidesalcon9").show();
    $(".hidesalcon10").show();
    $(".hidesalcon11").show();
    $(".hidesalcon12").show();
    $(".hidesalcon13").show();
    $(".hidesalcon14").show();
    $(".hidesalcon15").show();
    $(".hidesalcon16").show();
    $(".hidesalcon17").show();
    $(".hidesalcon18").show();
    $(".hidesalcon19").show();
    $(".hidesalcon20").show();

    this.setState({
      mainduration: "M",
      mainplan: "",
      citydistrictselectvalue: "",
      servicefullname: "",
    });
  };
  seconddropdowngetstartedclick = (e) => {
    $(".plangetstartedbtn").show();
    $(".getform2").hide();
    $(e.target).closest(".formplacer").append(myform.slideDown());
    $(".hidesalcon1").show();
    $(".hidesalcon2").hide();
    $(".hidesalcon3").show();
    $(".hidesalcon4").show();
    $(".hidesalcon5").show();
    $(".hidesalcon6").show();
    $(".hidesalcon7").show();
    $(".hidesalcon8").show();
    $(".hidesalcon9").show();
    $(".hidesalcon10").show();
    $(".hidesalcon11").show();
    $(".hidesalcon12").show();
    $(".hidesalcon13").show();
    $(".hidesalcon14").show();
    $(".hidesalcon15").show();
    $(".hidesalcon16").show();
    $(".hidesalcon17").show();
    $(".hidesalcon18").show();
    $(".hidesalcon19").show();
    $(".hidesalcon20").show();

    this.setState({
      mainduration: "M",
      mainplan: "",
      citydistrictselectvalue: "",
      servicefullname: "",
    });
  };
  thirddropdowngetstartedclick = (e) => {
    $(".plangetstartedbtn").show();
    $(".getform3").hide();
    $(e.target).closest(".formplacer").append(myform.slideDown());
    $(".hidesalcon1").show();
    $(".hidesalcon2").show();
    $(".hidesalcon3").hide();
    $(".hidesalcon4").show();
    $(".hidesalcon5").show();
    $(".hidesalcon6").show();
    $(".hidesalcon7").show();
    $(".hidesalcon8").show();
    $(".hidesalcon9").show();
    $(".hidesalcon10").show();
    $(".hidesalcon11").show();
    $(".hidesalcon12").show();
    $(".hidesalcon13").show();
    $(".hidesalcon14").show();
    $(".hidesalcon15").show();
    $(".hidesalcon16").show();
    $(".hidesalcon17").show();
    $(".hidesalcon18").show();
    $(".hidesalcon19").show();
    $(".hidesalcon20").show();

    this.setState({
      mainduration: "M",
      mainplan: "",
      citydistrictselectvalue: "",
      servicefullname: "",
    });
  };
  fourdropdowngetstartedclick = (e) => {
    $(".plangetstartedbtn").show();
    $(".getform4").hide();
    $(e.target).closest(".formplacer").append(myform.slideDown());
    $(".hidesalcon1").show();
    $(".hidesalcon2").show();
    $(".hidesalcon3").show();
    $(".hidesalcon4").hide();
    $(".hidesalcon5").show();
    $(".hidesalcon6").show();
    $(".hidesalcon7").show();
    $(".hidesalcon8").show();
    $(".hidesalcon9").show();
    $(".hidesalcon10").show();
    $(".hidesalcon11").show();
    $(".hidesalcon12").show();
    $(".hidesalcon13").show();
    $(".hidesalcon14").show();
    $(".hidesalcon15").show();
    $(".hidesalcon16").show();
    $(".hidesalcon17").show();
    $(".hidesalcon18").show();
    $(".hidesalcon19").show();
    $(".hidesalcon20").show();

    this.setState({
      mainduration: "M",
      mainplan: "",
      citydistrictselectvalue: "",
      servicefullname: "",
    });
  };
  fivedropdowngetstartedclick = (e) => {
    $(".plangetstartedbtn").show();
    $(".getform5").hide();
    $(e.target).closest(".formplacer").append(myform.slideDown());
    $(".hidesalcon1").show();
    $(".hidesalcon2").show();
    $(".hidesalcon3").show();
    $(".hidesalcon4").show();
    $(".hidesalcon5").hide();
    $(".hidesalcon6").show();
    $(".hidesalcon7").show();
    $(".hidesalcon8").show();
    $(".hidesalcon9").show();
    $(".hidesalcon10").show();
    $(".hidesalcon11").show();
    $(".hidesalcon12").show();
    $(".hidesalcon13").show();
    $(".hidesalcon14").show();
    $(".hidesalcon15").show();
    $(".hidesalcon16").show();
    $(".hidesalcon17").show();
    $(".hidesalcon18").show();
    $(".hidesalcon19").show();
    $(".hidesalcon20").show();

    this.setState({
      mainduration: "M",
      mainplan: "",
      citydistrictselectvalue: "",
      servicefullname: "",
    });
  };

  sixdropdowngetstartedclick = (e) => {
    $(".plangetstartedbtn").show();
    $(".getform6").hide();
    $(e.target).closest(".formplacer").append(myform.slideDown());
    $(".hidesalcon1").show();
    $(".hidesalcon2").show();
    $(".hidesalcon3").show();
    $(".hidesalcon4").show();
    $(".hidesalcon5").show();
    $(".hidesalcon6").hide();
    $(".hidesalcon7").show();
    $(".hidesalcon8").show();
    $(".hidesalcon9").show();
    $(".hidesalcon10").show();
    $(".hidesalcon11").show();
    $(".hidesalcon12").show();
    $(".hidesalcon13").show();
    $(".hidesalcon14").show();
    $(".hidesalcon15").show();
    $(".hidesalcon16").show();
    $(".hidesalcon17").show();
    $(".hidesalcon18").show();
    $(".hidesalcon19").show();
    $(".hidesalcon20").show();

    this.setState({
      mainduration: "M",
      mainplan: "",
      citydistrictselectvalue: "",
      servicefullname: "",
    });
  };
 
  
  componentDidMount() {
    window.scrollTo(0, 0);
    myform = $("#multiform").hide(0);
    $(".WithoutInventorydiv").hide();

    $(document.body).css("background-color", "#2d2d2d");
    let mainjwttoken = localStorage.getItem("toeljgtkewlna");
    let mainagentid = localStorage.getItem("qazxswedcvfrtgb");
    let mainuserid = localStorage.getItem("relleIsugad");
    Decuserid = encryptor.decrypt(mainuserid);
    Decagentid = encryptor.decrypt(mainagentid);
    Decjwttoken = encryptor.decrypt(mainjwttoken);
    supportName = localStorage.getItem("supportName");
    supportContact = localStorage.getItem("supportContact");
    supportEmail = localStorage.getItem("supportEmail");

    this.setState({
      supportName: supportName,
      supportContact: supportContact,
      supportEmail: supportEmail,
    });

    sessiondata = JSON.parse(sessionStorage.getItem("data"));

    if (sessiondata == null || sessiondata == undefined || sessiondata == "") {
      sessiondata = [];
    }
    // else  {
    //   sessiondata = JSON.parse(sessionStorage.getItem("data"))
    //  }

    if (sessiondata.length > 0) {
      if (sessiondata[0].userId == "") {
        var semail = sessionStorage.getItem("newuseremail");
        var scontact = sessionStorage.getItem("newusercontact");
        if (scontact != "" && semail != "") {
          this.setState({
            serviceemail: semail,
            servicemobileno: scontact,
          });
        }
      } else if (sessiondata[0].userId != "") {
        sessiondata = [];
        sessionStorage.removeItem("data");
      }
    } else {
      this.setState({
        serviceemail: "",
        servicemobileno: "",
      });
    }

     fetch(process.env.REACT_APP_API_PRICING_JSON_URL)
      .then(response => response.json())
      .then(data => {
        // Store the JSON data in localStorage
        localStorage.setItem('lshhihfnkff', JSON.stringify(data));
        this.priceUpdate()
      })
      .catch(error => console.error('Error fetching data:', error));

      console.log("123",pricingJson );
  }

  priceUpdate = () => {
    pricingJson = JSON.parse(localStorage.getItem("lshhihfnkff"));
  
    let PFRF1 = pricingJson["PFRF1"].price ;
    let PFRF2 = pricingJson["PFRF2"].price ;
    let PFRF3 = pricingJson["PFRF3"].price ;
    let PFRF4 = pricingJson["PFRF4"].price ;
    let PFRF5 = pricingJson["PFRF5"].price ;
    let PFRF6 = pricingJson["PFRF6"].price ;


    this.setState({
      PFRF1:  Math.round(PFRF1 * 0.18 + PFRF1),
      PFRF2:  Math.round(PFRF2 * 0.18 + PFRF2),
      PFRF3:  Math.round(PFRF3 * 0.18 + PFRF3),
      PFRF4:  Math.round(PFRF4 * 0.18 + PFRF4),
      PFRF5:  Math.round(PFRF5 * 0.18 + PFRF5),
      PFRF6:  Math.round(PFRF6 * 0.18 + PFRF6),

      // No Discount Price

      // TDSRFY1Snodis: new Intl.NumberFormat("en-IN", {
      //   style: "currency",
      //   currency: "INR",
      // }).format(TDSRFY1Snodi),
      // TDSRFY1Bnodis: new Intl.NumberFormat("en-IN", {
      //   style: "currency",
      //   currency: "INR",
      // }).format(TDSRFY1Bnodi),
      // TDSRFY2Snodis: new Intl.NumberFormat("en-IN", {
      //   style: "currency",
      //   currency: "INR",
      // }).format(TDSRFY2Snodi),
      // TDSRFY2Bnodis: new Intl.NumberFormat("en-IN", {
      //   style: "currency",
      //   currency: "INR",
      // }).format(TDSRFY2Bnodi),
      // TDSRFY3Snodis: new Intl.NumberFormat("en-IN", {
      //   style: "currency",
      //   currency: "INR",
      // }).format(TDSRFY3Snodi),
      // TDSRFY4Snodis: new Intl.NumberFormat("en-IN", {
      //   style: "currency",
      //   currency: "INR",
      // }).format(TDSRFY4Snodi),

      //  // Discount Percentage
   
      // TDSRFY1SDisPer: TDSRFY1SDisPe,
      // TDSRFY1BDisPer: TDSRFY1BDisPe,
      // TDSRFY2SDisPer: TDSRFY2SDisPe,
      // TDSRFY2BDisPer: TDSRFY2BDisPe,
      // TDSRFY3SDisPer: TDSRFY3SDisPe,
      // TDSRFY4SDisPer: TDSRFY4SDisPe,
    })
  }

  addToCartseconddropdowngetstartedclick = () => {
    this.upcard();
  };

  upcard = () => {
    const contactNoregex = /^[6-9]\d{9}$/;
    const emailregex = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    if (this.state.servicefullname.trim() == "") {
      $(".errorside").show();
      $(".errorsidespan").show().html(" Please enter your name.");
      $("#name").css({
        "border-bottom-color": "#fe0c0c",
      });
      $("#name").focus();
    } else if (
      this.state.servicefullname.trim() == "" ||
      this.state.servicefullname.length > 50
    ) {
      $(".errorside").show();
      $(".errorsidespan").show().html(" Please enter a valid name.");
      $("#name").css({
        "border-bottom-color": "#fe0c0c",
      });
      $("#name").focus();
    } else if (this.state.serviceemail.trim() == "") {
      $(".errorside").show();
      $(".errorsidespan").show().html(" Please enter your email.");
      $("#name").css({
        "border-bottom-color": "#809aae",
      });
      $("#email").css({
        "border-bottom-color": "#fe0c0c",
      });
      $("#email").focus();
    } else if (
      !emailregex.test(this.state.serviceemail) ||
      this.state.serviceemail.length > 50
    ) {
      $(".errorside").show();
      $(".errorsidespan").show().html(" Please enter valid email.");
      $("#email").css({
        "border-bottom-color": "#fe0c0c",
      });
      $("#email").focus();
    } else if (this.state.servicemobileno.trim() == "") {
      $(".errorside").show();
      $(".errorsidespan").show().html(" Please enter your number.");
      $("#name").css({
        "border-bottom-color": "#809aae",
      });
      $("#email").css({
        "border-bottom-color": "#809aae",
      });
      $("#contact").css({
        "border-bottom-color": "#fe0c0c",
      });
      $("#contact").focus();
    } else if (
      this.state.servicemobileno.trim() == "" ||
      !contactNoregex.test(this.state.servicemobileno)
    ) {
      $(".errorside").show();
      $(".errorsidespan").show().html(" Please enter valid number.");
      $("#name").css({
        "border-bottom-color": "#809aae",
      });
      $("#email").css({
        "border-bottom-color": "#809aae",
      });
      $("#contact").css({
        "border-bottom-color": "#fe0c0c",
      });
      $("#contact").focus();
    } else if (this.state.citydistrictselectvalue.trim() == "") {
      $(".errorside").show();
      $(".errorsidespan").show().html(" Please enter your city.");
      $("#name").css({
        "border-bottom-color": "#809aae",
      });
      $("#email").css({
        "border-bottom-color": "#809aae",
      });
      $("#contact").css({
        "border-bottom-color": "#809aae",
      });
      $("#city").css({
        "border-bottom-color": "#fe0c0c",
      });
      $("#city").focus();
    } else {
      this.filterDistricts();

      var mySentence = this.state.servicefullname.trim();
      mySentence = mySentence.replace(/\s+/g, " ");
      const words = mySentence.split(" ");

      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }

      var mywords = words.join(" ");
      
      var mainobject =
      pricingJson[ "PFRF"  + (this.state.dropDownValue == "7" ? "6" : this.state.dropDownValue) ];
      var stateshortcode = Object.keys(json2[0]).find(
        (key) => json2[0][key] === servicestate
      );
      var gstvalue = mainobject.price * 0.18;
      sessionStorage.setItem("newuseremail", this.state.serviceemail);
      sessionStorage.setItem("newusercontact", this.state.servicemobileno);
      data = {
        userId: "",
        serviceName: "PFRF",
        purchasename: this.state.servicefullname,
        citydistrict: this.state.citydistrictselectvalue,
        state: servicestate,
        serviceType: "",
        duration: this.state.mainduration,
        plan: this.state.mainplan,
        turnover: this.state.dropDownValue == "7" ? "6" : this.state.dropDownValue,
        package: this.state.mainpackage,
        price: mainobject.price,
        gstvalue: gstvalue,
        govtfees: mainobject.govtfees,
        servicefullname: "PF Return Filing",
        billingName: this.state.servicefullname,
        billingCity: this.state.citydistrictselectvalue,
        billingState: jsonData[0][this.state.citydistrictselectvalue],
        stateShortCode: stateshortcode,
      };

      var sessiondata;
      sessiondata = sessionStorage.getItem("data");
      if (
        sessiondata == null ||
        sessiondata == undefined ||
        sessiondata == ""
      ) {
        sessiondata = [];
      } else {
        sessiondata = JSON.parse(sessionStorage.getItem("data"));
      }

      if (sessiondata.length == 0) {
        var localvar = [];
        localvar.push(data);
        sessionStorage.setItem("data", JSON.stringify(localvar));
        this.showNotification("Item Added to Cart");
      } else if (sessiondata.length > 0) {
        var arraylen = sessiondata.length;
        // for (let index = 0; index < arraylen; index++) {
        //   if (sessiondata[index].serviceName == "PFRF") {
        //     sessiondata.splice(index, 1);
        //     break;
        //   }
        // }
        sessiondata.push(data);
        sessionStorage.setItem("data", JSON.stringify(sessiondata));
        this.showNotification("Item Added to Cart");
      }
      this.setState({
        dataupdated: true,
      });
    }
  };
  handledropDownValueChange = () => {
    if (this.state.dropDownValue == "1" ) {
      $("#1").show();
      $("#2").hide();
      $("#3").hide();
      $("#4").hide();
      $("#5").hide();
      $("#6").hide();
      $("#7").hide();
      $("#8").hide();
      $("#9").hide();
      $("#10").hide();
    } else if (
      this.state.dropDownValue == "2" 
    ) {
      $("#1").hide();
      $("#2").show();
      $("#3").hide();
      $("#4").hide();
      $("#5").hide();
      $("#6").hide();
      $("#7").hide();
      $("#8").hide();
      $("#9").hide();
      $("#10").hide();
    } else if (
      this.state.dropDownValue == "3" 
    ) {
      $("#1").hide();
      $("#2").hide();
      $("#3").show();
      $("#4").hide();
      $("#5").hide();
      $("#6").hide();
      $("#7").hide();
      $("#8").hide();
      $("#9").hide();
      $("#10").hide();
    } else if (
      this.state.dropDownValue == "4" 
    ) {
      $("#1").hide();
      $("#2").hide();
      $("#3").hide();
      $("#4").show();
      $("#5").hide();
      $("#6").hide();
      $("#7").hide();
      $("#8").hide();
      $("#9").hide();
      $("#10").hide();
    } else if (
      this.state.dropDownValue == "5" 
    ) {
      $("#1").hide();
      $("#2").hide();
      $("#3").hide();
      $("#4").hide();
      $("#5").show();
      $("#6").hide();
      $("#7").hide();
      $("#8").hide();
      $("#9").hide();
      $("#10").hide();
    } else if (
      this.state.dropDownValue == "6" 
    ) {
      $("#1").hide();
      $("#2").hide();
      $("#3").hide();
      $("#4").hide();
      $("#5").hide();
      $("#6").show();
      $("#7").hide();
      $("#8").hide();
      $("#9").hide();
      $("#10").hide();
    } else if (
      this.state.dropDownValue == "7" 
    ) {
      $("#1").hide();
      $("#2").hide();
      $("#3").hide();
      $("#4").hide();
      $("#5").hide();
      $("#6").hide();
      $("#7").show();
      $("#8").hide();
      $("#9").hide();
      $("#10").hide();
    } 
  };

  // toggleContent = () => {
  //   if ($(".morepaymentpoint2").attr("aria-expanded") === "true") {
  //     $(".morepaymentpoint2").text("More");
  //   } else if ($(".morepaymentpoint2").attr("aria-expanded") === "false") {
  //     $(".morepaymentpoint2").text("Less");
  //   } else {
  //     $(".morepaymentpoint2").text("Less");
  //   }
  // };

  // planInclusions = () => {
  //   return (
  //     <div id="accordion">
  //       <div className="card bg-white border-0">
  //         <div className="card-header bg-white border-0 py-0" id="headingOne">
  //           <div
  //             className="mb-0 row mr-2  align-items-baseline"
  //             data-toggle="collapse"
  //             data-target="#collapseOne"
  //             aria-expanded="false"
  //             aria-controls="collapseOne"
  //           >
  //             <div
  //               className="text-left btn w-100 bg-white m-0 col-9 ques-2 p-0"
  //               data-toggle="collapse"
  //               data-target="#collapseOne"
  //               aria-expanded="false"
  //               aria-controls="collapseOne"
  //             >
  //               <p className="planstarlinetext">
  //                 <img
  //                   alt="loading..."
  //                   src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
  //                   className="planstarlineimg"
  //                 />
  //                 Included Transactions:-
  //               </p>
  //             </div>
  //             <div className="ml-auto btn pr-0 faq-btn">
  //               <img src="/img//Icon ionic-ios-arrow-down.svg" />
  //             </div>
  //           </div>
  //         </div>

  //         <div
  //           id="collapseOne"
  //           className="collapse "
  //           aria-labelledby="headingOne"
  //           data-parent="#accordion"
  //         >
  //           <div className="card-body pl-1">
  //             <p className="plancommonlinetext">
  //               <img
  //                 alt="loading..."
  //                 src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //                 className="plancircleimg"
  //               />
  //               Sales and Purchase Invoice
  //             </p>
  //             <p className="plancommonlinetext">
  //               <img
  //                 alt="loading..."
  //                 src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //                 className="plancircleimg"
  //               />
  //               Service Invoice
  //             </p>
  //             <p className="plancommonlinetext">
  //               <img
  //                 alt="loading..."
  //                 src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //                 className="plancircleimg"
  //               />
  //               Cash and Bank Entries
  //             </p>
  //             <p className="plancommonlinetext">
  //               <img
  //                 alt="loading..."
  //                 src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //                 className="plancircleimg"
  //               />
  //               Income and Expenses Entries
  //             </p>
  //             <p className="plancommonlinetext">
  //               <img
  //                 alt="loading..."
  //                 src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //                 className="plancircleimg"
  //               />
  //               All Other Financial Transactions
  //             </p>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="card bg-white border-0">
  //         <div className="card-header bg-white border-0 py-0" id="headingTwo">
  //           <div
  //             className="mb-0 row mr-2  align-items-baseline"
  //             data-toggle="collapse"
  //             data-target="#collapseTwo"
  //             aria-expanded="false"
  //             aria-controls="collapseTwo"
  //           >
  //             <div className="text-left btn w-100 bg-white m-0 col-9 ques-2 p-0">
  //               <p className="planstarlinetext">
  //                 <img
  //                   alt="loading..."
  //                   src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
  //                   className="planstarlineimg"
  //                 />
  //                 Balance Enquiry :-
  //               </p>
  //             </div>
  //             <div className="ml-auto btn pr-0 faq-btn">
  //               <img src="/img//Icon ionic-ios-arrow-down.svg" />
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //       <div
  //         id="collapseTwo"
  //         className="collapse"
  //         aria-labelledby="headingTwo"
  //         data-parent="#accordion"
  //       >
  //         <div className="card-body pl-1">
  //           <p className="plancommonlinetext">
  //             <img
  //               alt="loading..."
  //               src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //               className="plancircleimg"
  //             />
  //             Cash Balance
  //           </p>
  //           <p className="plancommonlinetext">
  //             <img
  //               alt="loading..."
  //               src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //               className="plancircleimg"
  //             />
  //             Party Ledger Balance
  //           </p>
  //           <p className="plancommonlinetext">
  //             <img
  //               alt="loading..."
  //               src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //               className="plancircleimg"
  //             />
  //             Party Last Transaction
  //           </p>
  //           <p className="plancommonlinetext">
  //             <img
  //               alt="loading..."
  //               src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //               className="plancircleimg"
  //             />
  //             Sales and Purchase Figures
  //           </p>
  //           <p className="plancommonlinetext">
  //             <img
  //               alt="loading..."
  //               src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //               className="plancircleimg"
  //             />
  //             Taxation Liability Balance
  //           </p>
  //         </div>
  //       </div>

  //       <div className="card bg-white border-0">
  //         <div className="card-header bg-white border-0 py-0" id="headingThree">
  //           <div
  //             className="mb-0 row mr-2  align-items-baseline"
  //             data-toggle="collapse"
  //             data-target="#collapseThree"
  //             aria-expanded="false"
  //             aria-controls="collapseThree"
  //           >
  //             <div className="text-left btn w-100 bg-white m-0 col-9 ques-2 p-0">
  //               <p className="planstarlinetext">
  //                 <img
  //                   alt="loading..."
  //                   src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
  //                   className="planstarlineimg"
  //                 />
  //                 Reconciliation :-
  //               </p>
  //             </div>
  //             <div className="ml-auto btn pr-0 faq-btn">
  //               <img src="/img//Icon ionic-ios-arrow-down.svg" />
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //       <div
  //         id="collapseThree"
  //         className="collapse"
  //         aria-labelledby="headingThree"
  //         data-parent="#accordion"
  //       >
  //         <div className="card-body pl-1">
  //           <p className="plancommonlinetext">
  //             <img
  //               alt="loading..."
  //               src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //               className="plancircleimg"
  //             />
  //             Bank Accounts
  //           </p>
  //           <p className="plancommonlinetext">
  //             <img
  //               alt="loading..."
  //               src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //               className="plancircleimg"
  //             />
  //             Loan Accounts
  //           </p>
  //           <p className="plancommonlinetext">
  //             <img
  //               alt="loading..."
  //               src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //               className="plancircleimg"
  //             />
  //             Party Accounts on Demand
  //           </p>
  //         </div>
  //       </div>

  //       <div className="card bg-white border-0">
  //         <div className="card-header bg-white border-0 py-0" id="headingFour">
  //           <div
  //             className="mb-0 row mr-2  align-items-baseline"
  //             data-toggle="collapse"
  //             data-target="#collapseFour"
  //             aria-expanded="false"
  //             aria-controls="collapseFour"
  //           >
  //             <div className="text-left btn w-100 bg-white m-0 col-9 ques-2 p-0">
  //               <p className="planstarlinetext">
  //                 <img
  //                   alt="loading..."
  //                   src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
  //                   className="planstarlineimg"
  //                 />
  //                 Ledger and Statements:-
  //               </p>
  //             </div>
  //             <div className="ml-auto btn pr-0 faq-btn">
  //               <img src="/img//Icon ionic-ios-arrow-down.svg" />
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //       <div
  //         id="collapseFour"
  //         className="collapse"
  //         aria-labelledby="headingFour"
  //         data-parent="#accordion"
  //       >
  //         <div className="card-body pl-1">
  //           <p className="plancommonlinetext">
  //             <img
  //               alt="loading..."
  //               src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //               className="plancircleimg"
  //             />
  //             Party Ledger on Demand
  //           </p>
  //           <p className="plancommonlinetext">
  //             <img
  //               alt="loading..."
  //               src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //               className="plancircleimg"
  //             />
  //             Tax Liabilty Ledger on Demand
  //           </p>
  //           <p className="plancommonlinetext">
  //             <img
  //               alt="loading..."
  //               src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //               className="plancircleimg"
  //             />
  //             Stock Statement on Demand
  //           </p>
  //         </div>
  //       </div>

  //       <div className="card bg-white border-0">
  //         <div className="card-header bg-white border-0 py-0" id="headingFive">
  //           <div className="mb-0 row mr-2  align-items-baseline">
  //             <div className="text-left btn w-100 bg-white m-0 col-9 ques-2 p-0">
  //               <p className="planstarlinetext">
  //                 <img
  //                   alt="loading..."
  //                   src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
  //                   className="planstarlineimg"
  //                 />
  //                 Books Finanalization:-
  //               </p>
  //             </div>
  //             <div className="ml-auto btn pr-0 faq-btn"></div>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="card bg-white border-0">
  //         <div className="card-header bg-white border-0 py-0" id="headingSix">
  //           <div
  //             className="mb-0 row mr-2  align-items-baseline"
  //             data-toggle="collapse"
  //             data-target="#collapseSix"
  //             aria-expanded="false"
  //             aria-controls="collapseSix"
  //           >
  //             <div className="text-left btn w-100 bg-white m-0 col-9 ques-2 p-0">
  //               <p className="planstarlinetext">
  //                 <img
  //                   alt="loading..."
  //                   src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
  //                   className="planstarlineimg"
  //                 />
  //                 Included Reports:-
  //               </p>
  //             </div>
  //             <div className="ml-auto btn pr-0 faq-btn">
  //               <img src="/img//Icon ionic-ios-arrow-down.svg" />
  //             </div>
  //           </div>
  //         </div>
  //         <div
  //           id="collapseSix"
  //           className="collapse"
  //           aria-labelledby="headingSix"
  //           data-parent="#accordion"
  //         >
  //           <div className="card-body pl-1">
  //             <p className="plancommonlinetext">
  //               <img
  //                 alt="loading..."
  //                 src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //                 className="plancircleimg"
  //               />
  //               Trial Balance
  //             </p>
  //             <p className="plancommonlinetext">
  //               <img
  //                 alt="loading..."
  //                 src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //                 className="plancircleimg"
  //               />
  //               Balance Sheet
  //             </p>
  //             <p className="plancommonlinetext">
  //               <img
  //                 alt="loading..."
  //                 src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //                 className="plancircleimg"
  //               />
  //               Profit and Loss Account
  //             </p>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="card bg-white border-0">
  //         <div className="card-header bg-white border-0 py-0" id="headingSeven">
  //           <div className="mb-0 row mr-2  align-items-baseline">
  //             <div className="text-left btn w-100 bg-white m-0 col-9 ques-2 p-0">
  //               <p className="planstarlinetext">
  //                 <img
  //                   alt="loading..."
  //                   src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
  //                   className="planstarlineimg"
  //                 />
  //                 Yearly Books of Accounts (PDF):-
  //               </p>
  //             </div>
  //             <div className="ml-auto btn pr-0 faq-btn"></div>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="card bg-white border-0">
  //         <div className="card-header bg-white border-0 py-0" id="headingEight">
  //           <div className="mb-0 row mr-2  align-items-baseline">
  //             <div className="text-left btn w-100 bg-white m-0 col-9 ques-2 p-0">
  //               <p className="planstarlinetext">
  //                 <img
  //                   alt="loading..."
  //                   src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
  //                   className="planstarlineimg"
  //                 />
  //                 Yearly Books of Accounts (Hard Copy):-
  //               </p>
  //             </div>
  //             <div className="ml-auto btn pr-0 faq-btn"></div>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="card bg-white border-0">
  //         <div className="card-header bg-white border-0 py-0">
  //           <div
  //             className="mb-0 row mr-2  align-items-baseline"
  //             id="headingTen"
  //             data-toggle="collapse"
  //             data-target="#collapseTen"
  //             aria-expanded="false"
  //             aria-controls="collapseTen"
  //           >
  //             <div className="text-left btn w-100 bg-white m-0 col-8 ques-2 p-0">
  //               <p className="planstarlinetext">
  //                 <img
  //                   alt="loading..."
  //                   src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
  //                   className="planstarlineimg"
  //                 />
  //                 More:-
  //               </p>
  //             </div>
  //             <div className="ml-auto btn pr-0">
  //               <img src="/img//Icon ionic-ios-arrow-down.svg" />
  //             </div>
  //           </div>
  //         </div>
  //         <div
  //           id="collapseTen"
  //           className="collapse"
  //           aria-labelledby="headingTen"
  //           data-parent="#accordion"
  //         >
  //           <div className="card-body pl-1">
  //             <p className="plancommonlinetext">
  //               <img
  //                 alt="loading..."
  //                 src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //                 className="plantickimg"
  //               />
  //               E-way bill facility
  //             </p>
  //             <p className="plancommonlinetext">
  //               <img
  //                 alt="loading..."
  //                 src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //                 className="plantickimg"
  //               />
  //               Dashboard for managing service
  //             </p>
  //             <p className="plancommonlinetext">
  //               <img
  //                 alt="loading..."
  //                 src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //                 className="plantickimg"
  //               />
  //               Mobile application support
  //             </p>
  //             <p className="plancommonlinetext">
  //               <img
  //                 alt="loading..."
  //                 src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //                 className="plantickimg"
  //               />
  //               Documents sharing facility
  //             </p>
  //             <p className="plancommonlinetext">
  //               <img
  //                 alt="loading..."
  //                 src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //                 className="plantickimg"
  //               />
  //               Download deliverables any time
  //             </p>
  //             <p className="plancommonlinetext">
  //               <img
  //                 alt="loading..."
  //                 src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //                 className="plantickimg"
  //               />
  //               Password sharing
  //             </p>
  //             <p className="plancommonlinetext">
  //               <img
  //                 alt="loading..."
  //                 src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //                 className="plantickimg"
  //               />
  //               Round the clock support
  //             </p>
  //             <p className="plancommonlinetext">
  //               <img
  //                 alt="loading..."
  //                 src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //                 className="plantickimg"
  //               />
  //               Time to time updates & notifications
  //             </p>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  // planInclusionspro = () => {
  //   return (
  //     <div id="accordion">
  //       <div className="card bg-white border-0">
  //         <div className="card-header bg-white border-0 py-0" id="headingNine">
  //           <div
  //             className="mb-0 row mr-2  align-items-baseline"
  //             data-toggle="collapse"
  //             data-target="#collapseNine"
  //             aria-expanded="false"
  //             aria-controls="collapseNine"
  //           >
  //             <div
  //               className="text-left btn w-100 bg-white m-0 col-9 ques-2 p-0"
  //               data-toggle="collapse"
  //               data-target="#collapseNine"
  //               aria-expanded="false"
  //               aria-controls="collapseNine"
  //             >
  //               <p className="planstarlinetext">
  //                 <img
  //                   alt="loading..."
  //                   src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
  //                   className="planstarlineimg"
  //                 />
  //                 Included Invoice Making:-
  //               </p>
  //             </div>
  //             <div className="ml-auto btn pr-0 faq-btn">
  //               <img src="/img//Icon ionic-ios-arrow-down.svg" />
  //             </div>
  //           </div>
  //         </div>

  //         <div
  //           id="collapseNine"
  //           className="collapse "
  //           aria-labelledby="headingNine"
  //           data-parent="#accordion"
  //         >
  //           <div className="card-body pl-1">
  //             <p className="plancommonlinetext">
  //               <img
  //                 alt="loading..."
  //                 src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //                 className="plancircleimg"
  //               />
  //               Sales Invoice
  //             </p>
  //             <p className="plancommonlinetext">
  //               <img
  //                 alt="loading..."
  //                 src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //                 className="plancircleimg"
  //               />
  //               Service Invoice
  //             </p>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="card bg-white border-0">
  //         <div
  //           className="card-header bg-white border-0 py-0"
  //           id="headingEleven"
  //         >
  //           <div
  //             className="mb-0 row mr-2  align-items-baseline"
  //             data-toggle="collapse"
  //             data-target="#collapseEleven"
  //             aria-expanded="false"
  //             aria-controls="collapseEleven"
  //           >
  //             <div
  //               className="text-left btn w-100 bg-white m-0 col-9 ques-2 p-0"
  //               data-toggle="collapse"
  //               data-target="#collapseEleven"
  //               aria-expanded="false"
  //               aria-controls="collapseEleven"
  //             >
  //               <p className="planstarlinetext">
  //                 <img
  //                   alt="loading..."
  //                   src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
  //                   className="planstarlineimg"
  //                 />
  //                 Included Transactions:-
  //               </p>
  //             </div>
  //             <div className="ml-auto btn pr-0 faq-btn">
  //               <img src="/img//Icon ionic-ios-arrow-down.svg" />
  //             </div>
  //           </div>
  //         </div>

  //         <div
  //           id="collapseEleven"
  //           className="collapse "
  //           aria-labelledby="headingEleven"
  //           data-parent="#accordion"
  //         >
  //           <div className="card-body pl-1">
  //             <p className="plancommonlinetext">
  //               <img
  //                 alt="loading..."
  //                 src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //                 className="plancircleimg"
  //               />
  //               Sales and Purchase Invoice
  //             </p>
  //             <p className="plancommonlinetext">
  //               <img
  //                 alt="loading..."
  //                 src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //                 className="plancircleimg"
  //               />
  //               Service Invoice
  //             </p>
  //             <p className="plancommonlinetext">
  //               <img
  //                 alt="loading..."
  //                 src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //                 className="plancircleimg"
  //               />
  //               Cash and Bank Entries
  //             </p>
  //             <p className="plancommonlinetext">
  //               <img
  //                 alt="loading..."
  //                 src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //                 className="plancircleimg"
  //               />
  //               Income and Expenses Entries
  //             </p>
  //             <p className="plancommonlinetext">
  //               <img
  //                 alt="loading..."
  //                 src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //                 className="plancircleimg"
  //               />
  //               All Other Financial Transactions
  //             </p>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="card bg-white border-0">
  //         <div
  //           className="card-header bg-white border-0 py-0"
  //           id="headingTwelve"
  //         >
  //           <div
  //             className="mb-0 row mr-2  align-items-baseline"
  //             data-toggle="collapse"
  //             data-target="#collapseTwelve"
  //             aria-expanded="false"
  //             aria-controls="collapseTwelve"
  //           >
  //             <div className="text-left btn w-100 bg-white m-0 col-9 ques-2 p-0">
  //               <p className="planstarlinetext">
  //                 <img
  //                   alt="loading..."
  //                   src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
  //                   className="planstarlineimg"
  //                 />
  //                 Balance Enquiry :-
  //               </p>
  //             </div>
  //             <div className="ml-auto btn pr-0 faq-btn">
  //               <img src="/img//Icon ionic-ios-arrow-down.svg" />
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //       <div
  //         id="collapseTwelve"
  //         className="collapse"
  //         aria-labelledby="headingTwelve"
  //         data-parent="#accordion"
  //       >
  //         <div className="card-body pl-1">
  //           <p className="plancommonlinetext">
  //             <img
  //               alt="loading..."
  //               src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //               className="plancircleimg"
  //             />
  //             Cash Balance
  //           </p>
  //           <p className="plancommonlinetext">
  //             <img
  //               alt="loading..."
  //               src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //               className="plancircleimg"
  //             />
  //             Party Ledger Balance
  //           </p>
  //           <p className="plancommonlinetext">
  //             <img
  //               alt="loading..."
  //               src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //               className="plancircleimg"
  //             />
  //             Party Last Transaction
  //           </p>
  //           <p className="plancommonlinetext">
  //             <img
  //               alt="loading..."
  //               src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //               className="plancircleimg"
  //             />
  //             Sales and Purchase Figures
  //           </p>
  //           <p className="plancommonlinetext">
  //             <img
  //               alt="loading..."
  //               src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //               className="plancircleimg"
  //             />
  //             Taxation Liability Balance
  //           </p>
  //         </div>
  //       </div>

  //       <div className="card bg-white border-0">
  //         <div
  //           className="card-header bg-white border-0 py-0"
  //           id="headingThrteen"
  //         >
  //           <div
  //             className="mb-0 row mr-2  align-items-baseline"
  //             data-toggle="collapse"
  //             data-target="#collapseThrteen"
  //             aria-expanded="false"
  //             aria-controls="collapseThrteen"
  //           >
  //             <div className="text-left btn w-100 bg-white m-0 col-9 ques-2 p-0">
  //               <p className="planstarlinetext">
  //                 <img
  //                   alt="loading..."
  //                   src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
  //                   className="planstarlineimg"
  //                 />
  //                 Reconciliation :-
  //               </p>
  //             </div>
  //             <div className="ml-auto btn pr-0 faq-btn">
  //               <img src="/img//Icon ionic-ios-arrow-down.svg" />
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //       <div
  //         id="collapseThrteen"
  //         className="collapse"
  //         aria-labelledby="headingThrteen"
  //         data-parent="#accordion"
  //       >
  //         <div className="card-body pl-1">
  //           <p className="plancommonlinetext">
  //             <img
  //               alt="loading..."
  //               src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //               className="plancircleimg"
  //             />
  //             Bank Accounts
  //           </p>
  //           <p className="plancommonlinetext">
  //             <img
  //               alt="loading..."
  //               src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //               className="plancircleimg"
  //             />
  //             Loan Accounts
  //           </p>
  //           <p className="plancommonlinetext">
  //             <img
  //               alt="loading..."
  //               src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //               className="plancircleimg"
  //             />
  //             Party Accounts on Demand
  //           </p>
  //         </div>
  //       </div>

  //       <div className="card bg-white border-0">
  //         <div
  //           className="card-header bg-white border-0 py-0"
  //           id="headingFourteen"
  //         >
  //           <div
  //             className="mb-0 row mr-2  align-items-baseline"
  //             data-toggle="collapse"
  //             data-target="#collapseFourteen"
  //             aria-expanded="false"
  //             aria-controls="collapseFourteen"
  //           >
  //             <div className="text-left btn w-100 bg-white m-0 col-9 ques-2 p-0">
  //               <p className="planstarlinetext">
  //                 <img
  //                   alt="loading..."
  //                   src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
  //                   className="planstarlineimg"
  //                 />
  //                 Ledger and Statements:-
  //               </p>
  //             </div>
  //             <div className="ml-auto btn pr-0 faq-btn">
  //               <img src="/img//Icon ionic-ios-arrow-down.svg" />
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //       <div
  //         id="collapseFourteen"
  //         className="collapse"
  //         aria-labelledby="headingFourteen"
  //         data-parent="#accordion"
  //       >
  //         <div className="card-body pl-1">
  //           <p className="plancommonlinetext">
  //             <img
  //               alt="loading..."
  //               src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //               className="plancircleimg"
  //             />
  //             Party Ledger on Demand
  //           </p>
  //           <p className="plancommonlinetext">
  //             <img
  //               alt="loading..."
  //               src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //               className="plancircleimg"
  //             />
  //             Tax Liabilty Ledger on Demand
  //           </p>
  //           <p className="plancommonlinetext">
  //             <img
  //               alt="loading..."
  //               src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //               className="plancircleimg"
  //             />
  //             Stock Statement on Demand
  //           </p>
  //         </div>
  //       </div>

  //       <div className="card bg-white border-0">
  //         <div
  //           className="card-header bg-white border-0 py-0"
  //           id="headingFifteen"
  //         >
  //           <div className="mb-0 row mr-2  align-items-baseline">
  //             <div className="text-left btn w-100 bg-white m-0 col-9 ques-2 p-0">
  //               <p className="planstarlinetext">
  //                 <img
  //                   alt="loading..."
  //                   src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
  //                   className="planstarlineimg"
  //                 />
  //                 Books Finanalization:-
  //               </p>
  //             </div>
  //             <div className="ml-auto btn pr-0 faq-btn"></div>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="card bg-white border-0">
  //         <div
  //           className="card-header bg-white border-0 py-0"
  //           id="headingSixteen"
  //         >
  //           <div
  //             className="mb-0 row mr-2  align-items-baseline"
  //             data-toggle="collapse"
  //             data-target="#collapseSixteen"
  //             aria-expanded="false"
  //             aria-controls="collapseSixteen"
  //           >
  //             <div className="text-left btn w-100 bg-white m-0 col-9 ques-2 p-0">
  //               <p className="planstarlinetext">
  //                 <img
  //                   alt="loading..."
  //                   src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
  //                   className="planstarlineimg"
  //                 />
  //                 Included Reports:-
  //               </p>
  //             </div>
  //             <div className="ml-auto btn pr-0 faq-btn">
  //               <img src="/img//Icon ionic-ios-arrow-down.svg" />
  //             </div>
  //           </div>
  //         </div>
  //         <div
  //           id="collapseSixteen"
  //           className="collapse"
  //           aria-labelledby="headingSixteen"
  //           data-parent="#accordion"
  //         >
  //           <div className="card-body pl-1">
  //             <p className="plancommonlinetext">
  //               <img
  //                 alt="loading..."
  //                 src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //                 className="plancircleimg"
  //               />
  //               Trial Balance
  //             </p>
  //             <p className="plancommonlinetext">
  //               <img
  //                 alt="loading..."
  //                 src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //                 className="plancircleimg"
  //               />
  //               Balance Sheet
  //             </p>
  //             <p className="plancommonlinetext">
  //               <img
  //                 alt="loading..."
  //                 src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //                 className="plancircleimg"
  //               />
  //               Profit and Loss Account
  //             </p>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="card bg-white border-0">
  //         <div
  //           className="card-header bg-white border-0 py-0"
  //           id="headingSeventeen"
  //         >
  //           <div className="mb-0 row mr-2  align-items-baseline">
  //             <div className="text-left btn w-100 bg-white m-0 col-9 ques-2 p-0">
  //               <p className="planstarlinetext">
  //                 <img
  //                   alt="loading..."
  //                   src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
  //                   className="planstarlineimg"
  //                 />
  //                 Yearly Books of Accounts (PDF):-
  //               </p>
  //             </div>
  //             <div className="ml-auto btn pr-0 faq-btn"></div>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="card bg-white border-0">
  //         <div
  //           className="card-header bg-white border-0 py-0"
  //           id="headingEighteen"
  //         >
  //           <div className="mb-0 row mr-2  align-items-baseline">
  //             <div className="text-left btn w-100 bg-white m-0 col-9 ques-2 p-0">
  //               <p className="planstarlinetext">
  //                 <img
  //                   alt="loading..."
  //                   src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
  //                   className="planstarlineimg"
  //                 />
  //                 Yearly Books of Accounts (Hard Copy):-
  //               </p>
  //             </div>
  //             <div className="ml-auto btn pr-0 faq-btn"></div>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="card bg-white border-0">
  //         <div className="card-header bg-white border-0 py-0">
  //           <div
  //             className="mb-0 row mr-2  align-items-baseline"
  //             id="headingTwenty"
  //             data-toggle="collapse"
  //             data-target="#collapseTwenty"
  //             aria-expanded="false"
  //             aria-controls="collapseTwenty"
  //           >
  //             <div className="text-left btn w-100 bg-white m-0 col-8 ques-2 p-0">
  //               <p className="planstarlinetext">
  //                 <img
  //                   alt="loading..."
  //                   src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
  //                   className="planstarlineimg"
  //                 />
  //                 More:-
  //               </p>
  //             </div>
  //             <div className="ml-auto btn pr-0">
  //               <img src="/img//Icon ionic-ios-arrow-down.svg" />
  //             </div>
  //           </div>
  //         </div>
  //         <div
  //           id="collapseTwenty"
  //           className="collapse"
  //           aria-labelledby="headingTwenty"
  //           data-parent="#accordion"
  //         >
  //           <div className="card-body pl-1">
  //             <p className="plancommonlinetext">
  //               <img
  //                 alt="loading..."
  //                 src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //                 className="plantickimg"
  //               />
  //               E-way bill facility
  //             </p>
  //             <p className="plancommonlinetext">
  //               <img
  //                 alt="loading..."
  //                 src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //                 className="plantickimg"
  //               />
  //               Dashboard for managing service
  //             </p>
  //             <p className="plancommonlinetext">
  //               <img
  //                 alt="loading..."
  //                 src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //                 className="plantickimg"
  //               />
  //               Mobile application support
  //             </p>
  //             <p className="plancommonlinetext">
  //               <img
  //                 alt="loading..."
  //                 src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //                 className="plantickimg"
  //               />
  //               Documents sharing facility
  //             </p>
  //             <p className="plancommonlinetext">
  //               <img
  //                 alt="loading..."
  //                 src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //                 className="plantickimg"
  //               />
  //               Download deliverables any time
  //             </p>
  //             <p className="plancommonlinetext">
  //               <img
  //                 alt="loading..."
  //                 src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //                 className="plantickimg"
  //               />
  //               Password sharing
  //             </p>
  //             <p className="plancommonlinetext">
  //               <img
  //                 alt="loading..."
  //                 src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //                 className="plantickimg"
  //               />
  //               Round the clock support
  //             </p>
  //             <p className="plancommonlinetext">
  //               <img
  //                 alt="loading..."
  //                 src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
  //                 className="plantickimg"
  //               />
  //               Time to time updates & notifications
  //             </p>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  logout = () => {
    localStorage.removeItem("gglltakoinoeenl");
    localStorage.removeItem("toeljgtkewlna");
    localStorage.removeItem("qazxswedcvfrtgb");
    localStorage.removeItem("lshhihfnkff");
    this.props.history.push({
      pathname: `/`,
    });
  };
  filterDistricts = () => {
    servicestate = jsonData[0][this.state.citydistrictselectvalue];
  };
  createPurchaseReqNormal = () => {
    const contactNoregex = /^[6-9]\d{9}$/;
    const emailregex = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    if (this.state.servicefullname.trim() == "") {
      $(".errorside").show();
      $(".errorsidespan").show().html(" Please enter your name.");
      $("#name").css({
        "border-bottom-color": "#fe0c0c",
      });
      $("#name").focus();
    } else if (
      this.state.servicefullname.trim() == "" ||
      this.state.servicefullname.length > 50
    ) {
      $(".errorside").show();
      $(".errorsidespan").show().html(" Please enter a valid name.");
      $("#name").css({
        "border-bottom-color": "#fe0c0c",
      });
      $("#name").focus();
    } else if (this.state.serviceemail.trim() == "") {
      $(".errorside").show();
      $(".errorsidespan").show().html(" Please enter your email.");
      $("#name").css({
        "border-bottom-color": "#809aae",
      });
      $("#email").css({
        "border-bottom-color": "#fe0c0c",
      });
      $("#email").focus();
    } else if (
      !emailregex.test(this.state.serviceemail) ||
      this.state.serviceemail.length > 50
    ) {
      $(".errorside").show();
      $(".errorsidespan").show().html(" Please enter valid email.");
      $("#email").css({
        "border-bottom-color": "#fe0c0c",
      });
      $("#email").focus();
    } else if (this.state.servicemobileno.trim() == "") {
      $(".errorside").show();
      $(".errorsidespan").show().html(" Please enter your number.");
      $("#name").css({
        "border-bottom-color": "#809aae",
      });
      $("#email").css({
        "border-bottom-color": "#809aae",
      });
      $("#contact").css({
        "border-bottom-color": "#fe0c0c",
      });
      $("#contact").focus();
    } else if (
      this.state.servicemobileno.trim() == "" ||
      !contactNoregex.test(this.state.servicemobileno)
    ) {
      $(".errorside").show();
      $(".errorsidespan").show().html(" Please enter valid number.");
      $("#name").css({
        "border-bottom-color": "#809aae",
      });
      $("#email").css({
        "border-bottom-color": "#809aae",
      });
      $("#contact").css({
        "border-bottom-color": "#fe0c0c",
      });
      $("#contact").focus();
    } else if (this.state.citydistrictselectvalue.trim() == "") {
      $(".errorside").show();
      $(".errorsidespan").show().html(" Please enter your city.");
      $("#name").css({
        "border-bottom-color": "#809aae",
      });
      $("#email").css({
        "border-bottom-color": "#809aae",
      });
      $("#contact").css({
        "border-bottom-color": "#809aae",
      });
      $("#city").css({
        "border-bottom-color": "#fe0c0c",
      });
      $("#city").focus();
    } else {
      this.filterDistricts();
      var mySentence = this.state.servicefullname.trim();
      mySentence = mySentence.replace(/\s+/g, " ");
      const words = mySentence.split(" ");

      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }

      var mywords = words.join(" ");
      $(".planformsubmitbutton").hide();
      $("#addserviceloadermain").show();
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=createPurchaseNew",
          {
            agentId: Decagentid,
            contact: this.state.servicemobileno,
            email: this.state.serviceemail,
            serviceName: "PFRF",
            purchasename: mywords,
            citydistrict: this.state.citydistrictselectvalue,
            state: servicestate,
            serviceType: "",
            duration: this.state.mainduration,
            plan: this.state.mainplan,
            turnover: this.state.dropDownValue == "7" ? "6" : this.state.dropDownValue,
            package: this.state.mainpackage,
            comeFrom: "AGP",
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            $(".planformsubmitbutton").show();
            $("#addserviceloadermain").hide();
            $(".planformsubmitbutton").attr("disabled", false);
            sessionStorage.setItem(
              "uegiqlsartdele",
              encryptor.encrypt(res.data.requestid)
            );
            sessionStorage.setItem(
              "aprcillgee",
              encryptor.encrypt(parseFloat(res.data.price))
            );
            sessionStorage.setItem(
              "ssdfsdfwekrfewpfk",
              encryptor.encrypt(parseFloat(res.data.gstvalue))
            );
            sessionStorage.setItem(
              "lslgerotnscmvheearaei",
              encryptor.encrypt("PFRF")
            );
            sessionStorage.setItem("evlgsoaeetgfl", encryptor.encrypt(0));
            sessionStorage.setItem(
              "egalllpna",
              encryptor.encrypt(this.state.mainplan)
            );
            // sessionStorage.setItem("relleIsugad", encryptor.encrypt(res.data.userId));
            sessionStorage.setItem(
              "ielndmlgeaefelvsdrlucaa",
              encryptor.encrypt(mywords.trim())
            );
            sessionStorage.setItem(
              "ylcvciretaireditdgisdtacesl",
              encryptor.encrypt(this.state.citydistrictselectvalue)
            );
            sessionStorage.setItem(
              "srleleddgaeaemvcnia",
              encryptor.encrypt("PF Return Filing")
            );
            this.props.history.push({
              pathname: `/addservice/checkout`,
            });
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else {
            $(".planformsubmitbutton").show();
            $("#addserviceloadermain").hide();
            $(".planformsubmitbutton").attr("disabled", false);
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {});
    }
  };

  handleCustomPurchase = () => {
    const contactNoregex = /^[6-9]\d{9}$/;
    const emailregex = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
   
    if(this.state.custName.trim() == "") {
      $("#errorside1").show()
      $("#errorside1").text("Please enter name")
    } else if(this.state.custDesignation.trim() == "") {
      $("#errorside1").show()
      $("#errorside1").text("Please enter designation")
    } else if(this.state.custEmail.trim() == "") {
      $("#errorside1").show()
      $("#errorside1").text("Please enter email")
    } else if( !emailregex.test(this.state.custEmail) || this.state.custEmail.length > 50 ) {
      $("#errorside1").show()
      $("#errorside1").text("Please enter valid email")
    } else if(this.state.custContactNo.trim() == "") {
      $("#errorside1").show()
      $("#errorside1").text("Please enter contact number")
    } else if(!contactNoregex.test(this.state.custContactNo) || this.state.custContactNo.length > 10) {
      $("#errorside1").show()
      $("#errorside1").text("Please enter valid contact number")
    } else if(this.state.custTurnover.trim() == "") {
      $("#errorside1").show()
      $("#errorside1").text("Please enter Turnover")
    } else {
      $("#errorside1").hide()
      $("#e-loader").show()
      $("#e-submit").hide()
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=createPurchaseFormEntry",
          {
            userId: Decuserid,
            agentId: Decagentid,
            purchaseName: this.state.custName,
            email: this.state.custEmail,
            contact: this.state.custContactNo,
            serviceName: "PFRF",
            designation: this.state.custDesignation,
            turnover : this.state.custTurnover,
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.showNotification(res.data.message);
            $("#e-loader").hide()
            $("#e-submit").show()
            this.setState({
              custName: "",
              custEmail: "",
              custContactNo: "",
              custDesignation: "",
              custTurnover: "",
            })
            
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else {
            this.showNotification(res.data.message);
            $("#e-loader").hide()
            $("#e-submit").show()
          }
        })
        .catch((err) => {});
    }
  }

  // handleWithInventorybtn = () => {
  //   this.setState(
  //     {
  //       mainpackage: "WI",
  //       dropDownValue: "1",
  //     },
  //     () => {
  //       this.handledropDownValueChange();
  //       $(".WithoutInventorydiv").hide();
  //       $(".WithInventorydiv").show();
  //       $(".WithInventoryperbut").addClass("activewi");
  //       $(".WithoutInventoryprobut").removeClass("activewoi");
  //       $(".WithInventoryperbut").addClass("oaplans");
  //       $(".WithoutInventoryprobut").removeClass("oaplans");
  //     }
  //   );
  // };

  // handleWithoutInventorybtn = () => {
  //   this.setState(
  //     {
  //       mainpackage: "WOI",
  //       dropDownValue: "1",
  //     },
  //     () => {
  //       this.handledropDownValueChange();
  //       $(".WithInventorydiv").hide();
  //       $(".WithoutInventorydiv").show();
  //       $(".WithoutInventoryprobut").addClass("activewoi");
  //       $(".WithInventoryperbut").removeClass("activewi");
  //       $(".WithInventoryperbut").removeClass("oaplans");
  //       $(".WithoutInventoryprobut").addClass("oaplans");
  //     }
  //   );
  // };

  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };
  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };
  backbutton = () => {
    this.props.history.push({
      pathname: `/addservice`,
    });
  };
  citydistrictselecthandle = (event) => {
    this.setState({
      citydistrictselectvalue: event.target.value,
    });
  };
  handleservicefullnameChange = (e) => {
    this.setState({
      servicefullname: e.target.value,
    });
  };
  handleserviceserviceemailChange = (e) => {
    this.setState({
      serviceemail: e.target.value.toLowerCase(),
    });
  };
  handleserviceservicemobilenoChange = (e) => {
    this.setState({
      servicemobileno: e.target.value,
    });
  };
  handlesupport = () => {
    var x = document.getElementById("myDIV");
    if (x.style.display == "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  };
  render() {
    if (
      localStorage.getItem("gglltakoinoeenl") ==
        process.env.REACT_APP_LOGIN_KEY &&
      localStorage.getItem("toeljgtkewlna") != null &&
      localStorage.getItem("qazxswedcvfrtgb") != null
    ) {
      return (
        <div className="cont">
          <div id="notifContainer"></div>
          <Helmet>
            <link
              rel="stylesheet"
              href={process.env.PUBLIC_URL + "/css/select2new2.css"}
            />
          </Helmet>

          <Header />

          <img
            src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
            className="sidebaropenicon"
          />
          <img
            src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
            onClick={this.backbutton}
            className="sidebarbackicon"
          />
          <img
            src={process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"}
            className="sidebarexpandicon"
          />

          <div className="overlay1"></div>
          <div className="sidebar sidebarshift">
            <div className="side_div_2 sidebar_txt active">
              <Link to="/dashboard">Overview</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <Link to="/myservices">My Services</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <a href="#">Payments Invoices</a>
            </div>
            <div className="side_div_1 sidebar_txt">
              <a href="#">Documents Record</a>
            </div>
            <div className="side_div_1 sidebar_txt">
              <a href="#">Add service</a>
            </div>
            <div></div>
          </div>

          <div id="multiform" className="multiformdiv">
            <p className="planformtext1">Name:</p>
            <input
              type="text"
              pattern="[A-Za-z ]{3,150}"
              id="name"
              className="appendnameinput planforminput"
              value={this.state.servicefullname}
              maxLength="100"
              onChange={this.handleservicefullnameChange}
              required
            />
            <p className="planform2">Email:</p>
            <input
              type="email"
              maxLength="50"
              id="email"
              className="appendemailinput planforminput"
              value={this.state.serviceemail}
              onChange={this.handleserviceserviceemailChange}
              required
            />
            <p className="planform2">Contact no:</p>
            <input
              type="tel"
              pattern="[6-9]{1}[0-9]{9}"
              maxLength="10"
              id="contact"
              className="appendcontactno planforminput"
              value={this.state.servicemobileno}
              onChange={this.handleserviceservicemobilenoChange}
            />
            <p className="planform2">Select Location:</p>
            <Select2
              id="city"
              onChange={this.citydistrictselecthandle}
              value={this.state.citydistrictselectvalue}
              data={[
                "Adilabad",
                "Agar Malwa",
                "Agra",
                "Ahmedabad",
                "Ahmednagar",
                "Aizawl",
                "Ajmer",
                "Akola",
                "Alappuzha",
                "Aligarh",
                "Alipurduar",
                "Alirajpur",
                "Allahabad",
                "Almora",
                "Alwar",
                "Ambala",
                "Ambedkar Nagar",
                "Amethi (Chatrapati Sahuji Mahraj Nagar)",
                "Amravati",
                "Amreli",
                "Amritsar",
                "Amroha",
                "Anand",
                "Anantapur",
                "Anantnag",
                "Angul",
                "Anjaw",
                "Anuppur",
                "Araria",
                "Aravalli",
                "Ariyalur",
                "Arwal",
                "Ashoknagar",
                "Auraiya",
                "Aurangabad (BH)",
                "Aurangabad (MH)",
                "Azamgarh",
                "Bagalkot",
                "Bageshwar",
                "Baghpat",
                "Bahraich",
                "Baksa",
                "Balaghat",
                "Balangir",
                "Balasore",
                "Ballari (Bellary)",
                "Ballia",
                "Balod",
                "Baloda Bazar",
                "Balrampur (CG)",
                "Balrampur (UP)",
                "Banaskantha (Palanpur)",
                "Banda",
                "Bandipore",
                "Banka",
                "Bankura",
                "Banswara",
                "Barabanki",
                "Baramulla",
                "Baran",
                "Bareilly",
                "Bargarh",
                "Barmer",
                "Barnala",
                "Barpeta",
                "Barwani",
                "Bastar",
                "Basti",
                "Bathinda",
                "Beed",
                "Begusarai",
                "Belagavi (Belgaum)",
                "Bemetara",
                "Bengaluru (Bangalore) Rural",
                "Bengaluru (Bangalore) Urban",
                "Betul",
                "Bhadohi",
                "Bhadradri Kothagudem",
                "Bhadrak",
                "Bhagalpur",
                "Bhandara",
                "Bharatpur",
                "Bharuch",
                "Bhavnagar",
                "Bhilwara",
                "Bhind",
                "Bhiwani",
                "Bhojpur",
                "Bhopal",
                "Bidar",
                "Bijapur",
                "Bijnor",
                "Bikaner",
                "Bilaspur (CG)",
                "Bilaspur (HP)",
                "Birbhum",
                "Bishnupur",
                "Biswanath",
                "Bokaro",
                "Bongaigaon",
                "Botad",
                "Boudh",
                "Budaun",
                "Budgam",
                "Bulandshahr",
                "Buldhana",
                "Bundi",
                "Burhanpur",
                "Buxar",
                "Cachar",
                "Central Delhi",
                "Chamarajanagar",
                "Chamba",
                "Chamoli",
                "Champawat",
                "Champhai",
                "Chandauli",
                "Chandel",
                "Chandigarh",
                "Chandrapur",
                "Changlang",
                "Charaideo",
                "Charkhi Dadri",
                "Chatra",
                "Chengalpattu",
                "Chennai",
                "Chhatarpur",
                "Chhindwara",
                "Chhota Udepur",
                "Chikballapur",
                "Chikkamagaluru (Chikmagalur)",
                "Chirang",
                "Chitradurga",
                "Chitrakoot",
                "Chittoor",
                "Chittorgarh",
                "Churachandpur",
                "Churu",
                "Coimbatore",
                "Cooch Behar",
                "Cuddalore",
                "Cuttack",
                "Dadra & Nagar Haveli",
                "Dahod",
                "Dakshin Dinajpur (South Dinajpur)",
                "Dakshina Kannada",
                "Daman",
                "Damoh",
                "Dangs (Ahwa)",
                "Dantewada (South Bastar)",
                "Darbhanga",
                "Darjeeling",
                "Darrang",
                "Datia",
                "Dausa",
                "Davangere",
                "Dehradun",
                "Deogarh",
                "Deoghar",
                "Deoria",
                "Devbhoomi Dwarka",
                "Dewas",
                "Dhalai",
                "Dhamtari",
                "Dhanbad",
                "Dhar",
                "Dharmapuri",
                "Dharwad",
                "Dhemaji",
                "Dhenkanal",
                "Dholpur",
                "Dhubri",
                "Dhule",
                "Dibang Valley",
                "Dibrugarh",
                "Dima Hasao (North Cachar Hills)",
                "Dimapur",
                "Dindigul",
                "Dindori",
                "Diu",
                "Doda",
                "Dumka",
                "Dungarpur",
                "Durg",
                "East Champaran (Motihari)",
                "East Delhi",
                "East Garo Hills",
                "East Godavari",
                "East Jaintia Hills",
                "East Kameng",
                "East Khasi Hills",
                "East Siang",
                "East Sikkim",
                "East Singhbhum",
                "Ernakulam",
                "Erode",
                "Etah",
                "Etawah",
                "Faizabad",
                "Faridabad",
                "Faridkot",
                "Farrukhabad",
                "Fatehabad",
                "Fatehgarh Sahib",
                "Fatehpur",
                "Fazilka",
                "Ferozepur",
                "Firozabad",
                "Gadag",
                "Gadchiroli",
                "Gajapati",
                "Ganderbal",
                "Gandhinagar",
                "Ganjam",
                "Garhwa",
                "Gariyaband",
                "Gautam Buddha Nagar",
                "Gaya",
                "Ghaziabad",
                "Ghazipur",
                "Gir Somnath",
                "Giridih",
                "Goalpara",
                "Godda",
                "Golaghat",
                "Gomati",
                "Gonda",
                "Gondia",
                "Gopalganj",
                "Gorakhpur",
                "Gumla",
                "Guna",
                "Guntur",
                "Gurdaspur",
                "Gurugram (Gurgaon)",
                "Gwalior",
                "Hailakandi",
                "Hamirpur (HP)",
                "Hamirpur (UP)",
                "Hanumangarh",
                "Hapur (Panchsheel Nagar)",
                "Harda",
                "Hardoi",
                "Haridwar",
                "Hassan",
                "Hathras",
                "Haveri",
                "Hazaribag",
                "Hingoli",
                "Hisar",
                "Hojai",
                "Hooghly",
                "Hoshangabad",
                "Hoshiarpur",
                "Howrah",
                "Hyderabad",
                "Idukki",
                "Imphal East",
                "Imphal West",
                "Indore",
                "Jabalpur",
                "Jagatsinghapur",
                "Jagtial",
                "Jaipur",
                "Jaisalmer",
                "Jajpur",
                "Jalandhar",
                "Jalaun",
                "Jalgaon",
                "Jalna",
                "Jalore",
                "Jalpaiguri",
                "Jammu",
                "Jamnagar",
                "Jamtara",
                "Jamui",
                "Jangaon",
                "Janjgir-Champa",
                "Jashpur",
                "Jaunpur",
                "Jayashankar Bhoopalpally",
                "Jehanabad",
                "Jhabua",
                "Jhajjar",
                "Jhalawar",
                "Jhansi",
                "Jhargram",
                "Jharsuguda",
                "Jhunjhunu",
                "Jind",
                "Jiribam",
                "Jodhpur",
                "Jogulamba Gadwal",
                "Jorhat",
                "Junagadh",
                "Kabirdham (Kawardha)",
                "Kachchh",
                "Kaimur (Bhabua)",
                "Kaithal",
                "Kakching",
                "Kalaburagi (Gulbarga)",
                "Kalahandi",
                "Kalimpong",
                "Kallakurichi",
                "Kamareddy",
                "Kamjong",
                "Kamle",
                "Kamrup",
                "Kamrup Metropolitan",
                "Kanchipuram",
                "Kandhamal",
                "Kangpokpi",
                "Kangra",
                "Kanker (North Bastar)",
                "Kannauj",
                "Kannur",
                "Kanpur Dehat",
                "Kanpur Nagar",
                "Kanshiram Nagar (Kasganj)",
                "Kanyakumari",
                "Kapurthala",
                "Karaikal",
                "Karauli",
                "Karbi Anglong",
                "Kargil",
                "Karimganj",
                "Karimnagar",
                "Karnal",
                "Karur",
                "Kasaragod",
                "Kathua",
                "Katihar",
                "Katni",
                "Kaushambi",
                "Kendrapara",
                "Kendujhar (Keonjhar)",
                "Khagaria",
                "Khammam",
                "Khandwa",
                "Khargone",
                "Kheda (Nadiad)",
                "Khordha",
                "Khowai",
                "Khunti",
                "Kinnaur",
                "Kiphire",
                "Kishanganj",
                "Kishtwar",
                "Kodagu",
                "Koderma",
                "Kohima",
                "Kokrajhar",
                "Kolar",
                "Kolasib",
                "Kolhapur",
                "Kolkata",
                "Kollam",
                "Komaram Bheem Asifabad",
                "Kondagaon",
                "Koppal",
                "Koraput",
                "Korba",
                "Korea (Koriya)",
                "Kota",
                "Kottayam",
                "Kozhikode",
                "Kra Daadi",
                "Krishna",
                "Krishnagiri",
                "Kulgam",
                "Kullu",
                "Kupwara",
                "Kurnool",
                "Kurukshetra",
                "Kurung Kumey",
                "Kushinagar (Padrauna)",
                "Lahaul & Spiti",
                "Lakhimpur",
                "Lakhimpur - Kheri",
                "Lakhisarai",
                "Lakshadweep",
                "Lalitpur",
                "Latehar",
                "Latur",
                "Lawngtlai",
                "Leh",
                "Lepa Rada",
                "Lohardaga",
                "Lohit",
                "Longding",
                "Longleng",
                "Lower Dibang Valley",
                "Lower Siang",
                "Lower Subansiri",
                "Lucknow",
                "Ludhiana",
                "Lunglei",
                "Madhepura",
                "Madhubani",
                "Madurai",
                "Mahabubabad",
                "Mahabubnagar",
                "Maharajganj",
                "Mahasamund",
                "Mahe",
                "Mahendragarh",
                "Mahisagar",
                "Mahoba",
                "Mainpuri",
                "Majuli",
                "Malappuram",
                "Malda",
                "Malkangiri",
                "Mamit",
                "Mancherial",
                "Mandi",
                "Mandla",
                "Mandsaur",
                "Mandya",
                "Mansa",
                "Mathura",
                "Mau",
                "Mayurbhanj",
                "Medak",
                "Medchal",
                "Meerut",
                "Mehsana",
                "Mirzapur",
                "Moga",
                "Mokokchung",
                "Mon",
                "Moradabad",
                "Morbi",
                "Morena",
                "Morigaon",
                "Muktsar",
                "Mumbai City",
                "Mumbai Suburban",
                "Mungeli",
                "Munger (Monghyr)",
                "Murshidabad",
                "Muzaffarnagar",
                "Muzaffarpur",
                "Mysuru (Mysore)",
                "Nabarangpur",
                "Nadia",
                "Nagaon",
                "Nagapattinam",
                "Nagarkurnool",
                "Nagaur",
                "Nagpur",
                "Nainital",
                "Nalanda",
                "Nalbari",
                "Nalgonda",
                "Namakkal",
                "Namsai",
                "Nanded",
                "Nandurbar",
                "Narayanpur",
                "Narmada (Rajpipla)",
                "Narsinghpur",
                "Nashik",
                "Navsari",
                "Nawada",
                "Nawanshahr (Shahid Bhagat Singh Nagar)",
                "Nayagarh",
                "Neemuch",
                "New Delhi",
                "Nicobar",
                "Nilgiris",
                "Nirmal",
                "Nizamabad",
                "Noney",
                "North 24 Parganas",
                "North Delhi",
                "North East Delhi",
                "North Garo Hills",
                "North Goa",
                "North Sikkim",
                "North Tripura",
                "North West Delhi",
                "North and Middle Andaman",
                "Nuapada",
                "Nuh",
                "Osmanabad",
                "Pakke Kessang",
                "Pakur",
                "Palakkad",
                "Palamu",
                "Palghar",
                "Pali",
                "Palwal",
                "Panchkula",
                "Panchmahal (Godhra)",
                "Panipat",
                "Panna",
                "Papum Pare",
                "Parbhani",
                "Paschim (West) Burdwan (Bardhaman)",
                "Paschim Medinipur (West Medinipur)",
                "Patan",
                "Pathanamthitta",
                "Pathankot",
                "Patiala",
                "Patna",
                "Pauri Garhwal",
                "Peddapalli",
                "Perambalur",
                "Peren",
                "Phek",
                "Pherzawl",
                "Pilibhit",
                "Pithoragarh",
                "Poonch",
                "Porbandar",
                "Prakasam",
                "Pratapgarh (RJ)",
                "Pratapgarh (UP)",
                "Puducherry",
                "Pudukkottai",
                "Pulwama",
                "Pune",
                "Purba Burdwan (Bardhaman)",
                "Purba Medinipur (East Medinipur)",
                "Puri",
                "Purnia (Purnea)",
                "Purulia",
                "RaeBareli",
                "Raichur",
                "Raigad",
                "Raigarh",
                "Raipur",
                "Raisen",
                "Rajanna Sircilla",
                "Rajgarh",
                "Rajkot",
                "Rajnandgaon",
                "Rajouri",
                "Rajsamand",
                "Ramanagara",
                "Ramanathapuram",
                "Ramban",
                "Ramgarh",
                "Rampur",
                "Ranchi",
                "Rangareddy",
                "Ranipet",
                "Ratlam",
                "Ratnagiri",
                "Rayagada",
                "Reasi",
                "Rewa",
                "Rewari",
                "Ri Bhoi",
                "Rohtak",
                "Rohtas",
                "Rudraprayag",
                "Rupnagar",
                "Sabarkantha (Himmatnagar)",
                "Sagar",
                "Saharanpur",
                "Saharsa",
                "Sahibganj",
                "Sahibzada Ajit Singh Nagar (Mohali)",
                "Saiha",
                "Salem",
                "Samastipur",
                "Samba",
                "Sambalpur",
                "Sambhal (Bhim Nagar)",
                "Sangareddy",
                "Sangli",
                "Sangrur",
                "Sant Kabir Nagar",
                "Saran",
                "Satara",
                "Satna",
                "Sawai Madhopur",
                "Sehore",
                "Senapati",
                "Seoni",
                "Sepahijala",
                "Seraikela-Kharsawan",
                "Serchhip",
                "Shahdara",
                "Shahdol",
                "Shahjahanpur",
                "Shajapur",
                "Shamali (Prabuddh Nagar)",
                "Sheikhpura",
                "Sheohar",
                "Sheopur",
                "Shi Yomi",
                "Shimla",
                "Shivamogga (Shimoga)",
                "Shivpuri",
                "Shopian",
                "Shravasti",
                "Siang",
                "Siddharth Nagar",
                "Siddipet",
                "Sidhi",
                "Sikar",
                "Simdega",
                "Sindhudurg",
                "Singrauli",
                "Sirmaur (Sirmour)",
                "Sirohi",
                "Sirsa",
                "Sitamarhi",
                "Sitapur",
                "Sivaganga",
                "Sivasagar",
                "Siwan",
                "Solan",
                "Solapur",
                "Sonbhadra",
                "Sonepur",
                "Sonipat",
                "Sonitpur",
                "South 24 Parganas",
                "South Andaman",
                "South Delhi",
                "South East Delhi",
                "South Garo Hills",
                "South Goa",
                "South Salamara-Mankachar",
                "South Sikkim",
                "South Tripura",
                "South West Delhi",
                "South West Garo Hills",
                "South West Khasi Hills",
                "Sri Ganganagar",
                "Sri Potti Sriramulu Nellore ",
                "Srikakulam",
                "Srinagar",
                "Sukma",
                "Sultanpur",
                "Sundargarh",
                "Supaul",
                "Surajpur",
                "Surat",
                "Surendranagar",
                "Surguja",
                "Suryapet",
                "Tamenglong",
                "Tapi (Vyara)",
                "Tarn Taran",
                "Tawang",
                "Tehri Garhwal",
                "Tengnoupal",
                "Tenkasi",
                "Thane",
                "Thanjavur",
                "Theni",
                "Thiruvananthapuram",
                "Thoothukudi (Tuticorin)",
                "Thoubal",
                "Thrissur",
                "Tikamgarh",
                "Tinsukia",
                "Tirap",
                "Tiruchirappalli",
                "Tirunelveli",
                "Tirupathur",
                "Tiruppur",
                "Tiruvallur",
                "Tiruvannamalai",
                "Tiruvarur",
                "Tonk",
                "Tuensang",
                "Tumakuru (Tumkur)",
                "Udaipur",
                "Udalguri",
                "Udham Singh Nagar",
                "Udhampur",
                "Udupi",
                "Ujjain",
                "Ukhrul",
                "Umaria",
                "Una",
                "Unakoti",
                "Unnao",
                "Upper Siang",
                "Upper Subansiri",
                "Uttar Dinajpur (North Dinajpur)",
                "Uttara Kannada (Karwar)",
                "Uttarkashi",
                "Vadodara",
                "Vaishali",
                "Valsad",
                "Varanasi",
                "Vellore",
                "Vidisha",
                "Vijayapura (Bijapur)",
                "Vikarabad",
                "Viluppuram",
                "Virudhunagar",
                "Visakhapatnam",
                "Vizianagaram",
                "Wanaparthy",
                "Warangal (Rural)",
                "Warangal (Urban)",
                "Wardha",
                "Washim",
                "Wayanad",
                "West Champaran",
                "West Delhi",
                "West Garo Hills",
                "West Godavari",
                "West Jaintia Hills",
                "West Kameng",
                "West Karbi Anglong",
                "West Khasi Hills",
                "West Siang",
                "West Sikkim",
                "West Singhbhum",
                "West Tripura",
                "Wokha",
                "YSR District, Kadapa (Cuddapah)",
                "Yadadri Bhuvanagiri",
                "Yadgir",
                "Yamunanagar",
                "Yanam",
                "Yavatmal",
                "Zunheboto",
              ]}
              options={{
                placeholder: "City / District",
              }}
            />
            <div className="errorsidediv">
              <p className="errorside">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/img/Icon_feather-alert-circle.svg"
                  }
                  className="errorsideimg"
                />
                <span className="errorsidespan"></span>
              </p>
            </div>
            <center>
              <img
                className="addserviceloader"
                id="addserviceloadermain"
                src={process.env.PUBLIC_URL + "/img/loader.gif"}
                alt=""
              />
              <button
                id="normalsubmit"
                onClick={this.createPurchaseReqNormal}
                type="submit"
                className="planformsubmitbutton"
              >
                SUBMIT
              </button>
              <button
                className="addtocartbutton2"
                id="submit"
                onClick={this.addToCartseconddropdowngetstartedclick}
              >
                Add to cart &nbsp;
                <img src={process.env.PUBLIC_URL + "/img/Path_33782_1.svg"} />
              </button>
            </center>
          </div>

          <div className="arrow-left2 visibilityhidden"></div>
          <div className="white_div expandwhite_div">
            <div className="white_box">
              <div className="white_boxscrolldiv">
                <center>
                  {" "}
                  <p className="download">Add Service</p>
                </center>

                <center>
                  <p className="pleaseselecttunovertext">
                    Please Select the Number of Employees :
                  </p>
                  <select
                    id="turnover"
                    className="selectturnover"
                    value={this.state.dropDownValue}
                    onChange={(e) => {
                      this.setState(
                        { dropDownValue: e.target.value },() => {
                          this.handledropDownValueChange()
                        }
                      );
                    }}
                  >
                      <option value="1">01 to 20 Employees</option>
                      <option value="2">21 to 40 Employees</option>
                      <option value="3">41 to 75 Employees</option>
                      <option value="4">76 to 100 Employees</option>
                      <option value="5">101 to 150 Employees</option>
                      <option value="6">Above 150 Employees</option>
                      <option value="7">Nill Return</option>
                    {/* <option value="5">
                      800 Parties / Deductites and above
                    </option> */}
                  </select>
                </center>

                <div className="WithInventorydiv">
                  <div className="plantwocards plantwocards1" id="1">
                    <br />
                    <div className="row paddingmarginzero">
                      <div className="col-sm-12 plans2cardcol">
                        <div className="bluestartercard">
                          {/* <p className="planmainheading">Starter</p> */}
                          <div className="plancardinsidepadding" style={{"padding": "5px"}}>
                            <div className="formplacer">
                            <div className="plandropdownpadding">
                                <p class="tdsreturnprice">
                                  <span>₹</span>{this.state.PFRF1}<span>/Monthly</span>
                                </p>
                                {/* <p class="tdsreturncutprice">
                                  <span>{this.state.TDSRFY1Snodis}</span> Save {this.state.TDSRFY1SDisPer}
                                </p> */}
                              </div>
                              <div className="hidesalcon1">
                                <center>
                                  <button
                                    className="getform plangetstartedbtn"
                                    id="3S"
                                    onClick={this.firstdropdowngetstartedclick}
                                  >
                                    GET STARTED
                                  </button>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/img/loader.gif"
                                    }
                                    className="addserviceloader"
                                    id="addserviceloaderid1"
                                  />
                                </center>
                               

                                <p className="planstarlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Icon open-star.svg"
                                    }
                                    className="planstarlineimg"
                                  />
                                  Included:-
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Form 5A Submission
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  One-time employee detail updation
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  One-time data updation for employee
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  UAN Number allocation/updation
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Monthly Record updation for employees
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Monthly ECR Working
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Monthly Challan Preparation
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Monthly Challan and return submission & payment (on behalf of the client)
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Updation for challan submission & payment confirmation
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Digital Signature Uploading/Verification Returns
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    
                    </div>
                  </div>
                  <div className="plantwocards plantwocards1 planhide" id="2">
                    <br />
                    <div className="row paddingmarginzero">
                      <div className="col-sm-12 plans2cardcol">
                        <div className="bluestartercard">
                          {/* <p className="planmainheading">Starter</p> */}
                          <div className="plancardinsidepadding" style={{"padding": "5px"}}>
                            <div className="formplacer">
                            <div className="plandropdownpadding">
                                <p class="tdsreturnprice">
                                  <span>₹</span>{this.state.PFRF2}<span>/Monthly</span>
                                </p>
                                {/* <p class="tdsreturncutprice">
                                  <span>{this.state.TDSRFY2Snodis}</span> Save {this.state.TDSRFY2SDisPer}
                                </p> */}
                              </div>
                              <div className="hidesalcon2">
                                <center>
                                  <button
                                    className="getform2 plangetstartedbtn"
                                    id="4S"
                                    onClick={this.seconddropdowngetstartedclick}
                                  >
                                    GET STARTED
                                  </button>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/img/loader.gif"
                                    }
                                    className="addserviceloader"
                                    id="addserviceloaderid3"
                                  />
                                </center>
                                

                                <p className="planstarlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Icon open-star.svg"
                                    }
                                    className="planstarlineimg"
                                  />
                                  Included:-
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Form 5A Submission
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                 One-time employee detail updation
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  One-time data updation for employee
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  UAN Number allocation/updation
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Monthly Record updation for employees
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Monthly ECR Working
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Monthly Challan Preparation
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Monthly Challan and return submission & payment (on behalf of the client)
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                 Updation for challan submission & payment confirmation
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Digital Signature Uploading/Verification Returns
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                  <div className="plantwocards plantwocards1 planhide" id="3">
                    <br />
                    <div className="row paddingmarginzero">
                      <div className="col-sm-12 plans2cardcol">
                        <div className="bluestartercard">
                          {/* <p className="planmainheading">Starter</p> */}
                          <div className="plancardinsidepadding" style={{"padding": "5px"}}>
                            <div className="formplacer">
                            <div className="plandropdownpadding">
                                <p class="tdsreturnprice">
                                  <span>₹</span>{this.state.PFRF3}<span>/Monthly</span>
                                </p>
                                {/* <p class="tdsreturncutprice">
                                  <span>{this.state.TDSRFY3Snodis}</span> Save {this.state.TDSRFY3SDisPer}
                                </p> */}
                              </div>
                              <div className="hidesalcon3">
                                <center>
                                  <button
                                    className="getform3 plangetstartedbtn"
                                    id="5S"
                                    onClick={this.thirddropdowngetstartedclick}
                                  >
                                    GET STARTED
                                  </button>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/img/loader.gif"
                                    }
                                    className="addserviceloader"
                                    id="addserviceloaderid5"
                                  />
                                </center>
                                
                                

                                <p className="planstarlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Icon open-star.svg"
                                    }
                                    className="planstarlineimg"
                                  />
                                  Included:-
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Form 5A Submission
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  One-time employee detail updation
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  One-time data updation for employee
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                 UAN Number allocation/updation
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Monthly Record updation for employees
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Monthly ECR Working
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Monthly Challan Preparation
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Monthly Challan and return submission & payment (on behalf of the client)
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Updation for challan submission & payment confirmation
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Digital Signature Uploading/Verification Returns
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="plantwocards plantwocards1 planhide" id="4">
                    <br />
                    <div className="row paddingmarginzero">
                      <div className="col-sm-12 plans2cardcol">
                        <div className="bluestartercard">
                          {/* <p className="planmainheading">Starter</p> */}
                          <div className="plancardinsidepadding" style={{"padding": "5px"}}>
                            <div className="formplacer">
                            <div className="plandropdownpadding">
                                <p class="tdsreturnprice">
                                  <span>₹</span>{this.state.PFRF4}<span>/Monthly</span>
                                </p>
                                {/* <p class="tdsreturncutprice">
                                  <span>{this.state.TDSRFY4Snodis}</span> Save {this.state.TDSRFY4SDisPer}
                                </p> */}
                              </div>
                              <div className="hidesalcon4">
                                <center>
                                  <button
                                    className="getform4 plangetstartedbtn"
                                    id="5S"
                                    onClick={this.fourdropdowngetstartedclick}
                                  >
                                    GET STARTED
                                  </button>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/img/loader.gif"
                                    }
                                    className="addserviceloader"
                                    id="addserviceloaderid7"
                                  />
                                </center>
                                

                                <p className="planstarlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Icon open-star.svg"
                                    }
                                    className="planstarlineimg"
                                  />
                                  Included:-
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Form 5A Submission
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  One-time employee detail updation
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  One-time data updation for employee
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  UAN Number allocation/updation
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Monthly Record updation for employees
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Monthly ECR Working
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Monthly Challan Preparation
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Monthly Challan and return submission & payment (on behalf of the client)
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Updation for challan submission & payment confirmation
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Digital Signature Uploading/Verification Returns
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="plantwocards plantwocards1 planhide" id="5">
                    <br />
                    <div className="row paddingmarginzero">
                      <div className="col-sm-12 plans2cardcol">
                        <div className="bluestartercard">
                          {/* <p className="planmainheading">Starter</p> */}
                          <div className="plancardinsidepadding" style={{"padding": "5px"}}>
                            <div className="formplacer">
                            <div className="plandropdownpadding">
                                <p class="tdsreturnprice">
                                  <span>₹</span>{this.state.PFRF5}<span>/Monthly</span>
                                </p>
                                {/* <p class="tdsreturncutprice">
                                  <span>{this.state.TDSRFY4Snodis}</span> Save {this.state.TDSRFY4SDisPer}
                                </p> */}
                              </div>
                              <div className="hidesalcon5">
                                <center>
                                  <button
                                    className="getform5 plangetstartedbtn"
                                    id="5S"
                                    onClick={this.fivedropdowngetstartedclick}
                                  >
                                    GET STARTED
                                  </button>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/img/loader.gif"
                                    }
                                    className="addserviceloader"
                                    id="addserviceloaderid7"
                                  />
                                </center>
                                

                                <p className="planstarlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Icon open-star.svg"
                                    }
                                    className="planstarlineimg"
                                  />
                                  Included:-
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Form 5A Submission
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                 One-time employee detail updation
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  One-time data updation for employee
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  UAN Number allocation/updation
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Monthly Record updation for employees
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Monthly ECR Working
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Monthly Challan Preparation
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Monthly Challan and return submission & payment (on behalf of the client)
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Updation for challan submission & payment confirmation
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Digital Signature Uploading/Verification Returns
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="plantwocards plantwocards1 planhide" id="6">
                    <br />

                    <div className="bluestartercard">
                      <p className="planmainheading">ENTERPRISE SOLUTION</p>
                      <div className="plancardinsidepadding">
                        <div className="formplacer">
                        <center>
                            <input
                              className="enterpriseinput placeholdercolor text-center"
                              type="text"
                              maxlength="120"
                              pattern="[A-Za-z ]{3,150}"
                              id="e-name"
                              placeholder="Contact Person Name"
                              onChange={(e) => {
                                this.setState({
                                  custName: e.target.value,
                                });
                              }}
                              value={this.state.custName}
                              required
                            />
                            <input
                              className="enterpriseinput placeholdercolor text-center"
                              type="text"
                              maxlength="50"
                              pattern="[A-Za-z ]{2,50}"
                              id="e-designation"
                              placeholder="Contact Person Designation"
                              onChange={(e) => {
                                this.setState({
                                  custDesignation: e.target.value,
                                });
                              }}
                              value={this.state.custDesignation}
                              required
                            />
                            <input
                              className="enterpriseinput placeholdercolor text-center"
                              type="email"
                              maxlength="256"
                              id="e-email"
                              placeholder="Email"
                              onChange={(e) => {
                                this.setState({
                                  custEmail: e.target.value,
                                });
                              }}
                              value={this.state.custEmail}
                              required
                            />
                            <input
                              className="enterpriseinput placeholdercolor text-center"
                              type="tel"
                              pattern="[6-9]{1}[0-9]{9}"
                              maxlength="10"
                              id="e-contact"
                              placeholder="Contact no."
                              onChange={(e) => {
                                this.setState({
                                  custContactNo: e.target.value.replace(/\D/g, ''),
                                });
                              }}
                              value={this.state.custContactNo}
                              required
                            />
                            <input
                              className="enterpriseinput placeholdercolor text-center"
                              type="number"
                              maxlength="15"
                              id="e-turnover"
                              placeholder="Tentative Parties Quarterly"
                              onChange={(e) => {
                                this.setState({
                                  custTurnover: e.target.value,
                                });
                              }}
                              value={this.state.custTurnover}
                              required
                            />
                             <button
                                    id="e-submit"
                                    onClick={(e) => this.handleCustomPurchase()}
                                    className="custsub"
                                  >
                                    SUBMIT
                                  </button>
                                  <p id="errorside1" className="errorside">
                                    <img
                                      src="/img/Group_401.svg"
                                      className="errorsideimg"
                                    />
                                  </p>
                                  <p id="successside" className="errorside"></p>
                                  <img
                                    className="addserviceloader"
                                    id="e-loader"
                                    src="/img/homecontact/Rolling.svg"
                                    alt=""
                                  />
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="plantwocards plantwocards1 planhide" id="7">
                    <br />
                    <div className="row paddingmarginzero">
                      <div className="col-sm-12 plans2cardcol">
                        <div className="bluestartercard">
                          {/* <p className="planmainheading">Starter</p> */}
                          <div className="plancardinsidepadding" style={{"padding": "5px"}}>
                            <div className="formplacer">
                            <div className="plandropdownpadding">
                                <p class="tdsreturnprice">
                                  <span>₹</span>{this.state.PFRF6}<span>/Monthly</span>
                                </p>
                                {/* <p class="tdsreturncutprice">
                                  <span>{this.state.TDSRFY4Snodis}</span> Save {this.state.TDSRFY4SDisPer}
                                </p> */}
                              </div>
                              <div className="hidesalcon6">
                                <center>
                                  <button
                                    className="getform6 plangetstartedbtn"
                                    id="5S"
                                    onClick={this.sixdropdowngetstartedclick}
                                  >
                                    GET STARTED
                                  </button>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/img/loader.gif"
                                    }
                                    className="addserviceloader"
                                    id="addserviceloaderid7"
                                  />
                                </center>
                                

                                <p className="planstarlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Icon open-star.svg"
                                    }
                                    className="planstarlineimg"
                                  />
                                  Included:-
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Form Submission
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                 Monthly Challan Preparation
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Monthly Challan and return submission & payment (on behalf of the client)
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Updation for challan submission & payment confirmation
                                </p>
                                <p className="plancommonlinetext">
                                  <img
                                    alt="loading..."
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Mask Group 201.svg"
                                    }
                                    className="plantickimg"
                                  />
                                  Digital Signature Uploading/Verification Returns
                                </p>
                                
                                
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="myDIV">
            <div className="supportDetails">
              <img
                src={process.env.PUBLIC_URL + "/img/24-7_Support.png"}
                alt="image"
              />
              <div>
                <h5>{supportName}</h5>
                <p>Agent Support Executive</p>
                <span>
                  <b>Contact: </b> +91 {supportContact}
                </span>
                <p className="mb-1">
                  <b>Working Hours: </b> 11:00 AM - 06:30 PM
                </p>
              </div>
            </div>
          </div>
          <button className="support" onClick={this.handlesupport}>
            <img
              src={process.env.PUBLIC_URL + "/img/headPhone.svg"}
              width="50%"
              height="50px"
            />
          </button>

          <Footer />
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default Addservicenewuserpfrf;

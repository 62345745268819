import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import { Redirect } from "react-router";

let Decuserid;
let Decagentid;
let Decjwttoken;
var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);
export class Paymentfailed__newuser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: "",
      orderid: "",
      serviceId: "",
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    $(document.body).css("background-color", "#2d2d2d");
    let mainuserid = localStorage.getItem("relleIsugad");
    Decuserid = encryptor.decrypt(mainuserid);
let mainagentid= localStorage.getItem("qazxswedcvfrtgb");
    Decagentid = encryptor.decrypt(mainagentid);
    let mainjwttoken = localStorage.getItem("toeljgtkewlna");
    Decjwttoken = encryptor.decrypt(mainjwttoken);
    if (this.props.location.state != undefined) {
    
      if (this.props.location.state.Paymentfailed == true) {
        this.props.history.replace("/addservice/paymentfailed", null);
      } else {
        this.props.history.push({
          pathname: `/dashboard`,
        });
      }
    } else {
      this.props.history.push({
        pathname: `/dashboard`,
      });
    }
  }
  logout = () => {
    localStorage.removeItem("gglltakoinoeenl");
    localStorage.removeItem("toeljgtkewlna");
    localStorage.removeItem("qazxswedcvfrtgb");
    localStorage.removeItem("lshhihfnkff");
    this.props.history.push({
      pathname: `/`,
    });
  };
  render() {
    // if (
    //   localStorage.getItem("gglltakoinoeenl") == process.env.REACT_APP_LOGIN_KEY &&
    //   localStorage.getItem("toeljgtkewlna") != null &&
    //   localStorage.getItem("qazxswedcvfrtgb") != null
    // ) {
      return (
        <div className="cont">
          <Helmet>
            <link rel="stylesheet" href={process.env.PUBLIC_URL + "/css/select2.css"} />
          </Helmet>

          <Header />

          <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
            className="sidebaropenicon"
          />
          <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
            className="sidebarbackicon"
          />
          <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"}
            className="sidebarexpandicon"
          />

          <div className="overlay1"></div>
          <div className="sidebar sidebarshift">
            <div className="side_div_2 sidebar_txt active">
              <Link to="/dashboard">Overview</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <Link to="/myservices">My Services</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <a href="#">Payments Invoices</a>
            </div>
            <div className="side_div_1 sidebar_txt">
              <a href="#">Documents Record</a>
            </div>
            <div className="side_div_1 sidebar_txt">
              <a href="#">Add service</a>
            </div>
            <div></div>
          </div>
          <div className="arrow-left2 visibilityhidden"></div>
          <div className="white_div expandwhite_div">
            <div className="white_box">
              <div className="white_boxscrolldiv">
                <center>
                  <p className="oopsheading">Oops!</p>
                  <p className="paymentfailheading">Payment failed!</p>
                  <p className="paymentfailline">
                    "If any amount is deducted from your side,
                    <br /> it'll be credited back with in 7 days."
                  </p>
                  <img
                    src={process.env.PUBLIC_URL + "/img/paymentfail.svg"}
                    className="paymentfailimg"
                  />
                </center>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      );
    // } else {
    //   return <Redirect to="/" />;
    // }
  }
}

export default Paymentfailed__newuser;

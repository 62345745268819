import React, { Component } from 'react'

export default class TrainingMaterialcard extends Component {
    constructor(props){
        super(props)
    }
  render() {
    return (
      <div className="col-xl-2 col-md-4 col-12 mb-4 specialpad">
        <div className="card position-relative border-0">
          <div className="card-body py-3 px-3 supportcardbody">
            <div
              className="d-flex flex-column justify-content-center align-items-center cursorpointer supportcardmargin"
              // onClick={this.handleCheckVaultDocs}
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/img/Path 247.svg"
                }
                className="vault_card_img supportimg"
              />
            </div>
            <p className="vault_card_docName">
              {this.props.name}
            </p>
            <div className="my-flexcard">


              <div className="d-flex mt-3 justify-content-center">
                <a
                  href={this.props.url}
                  className="btn-sm vault_card_btn w-100 py-1 donthoverme"
                  id="download_btn"
                  target="_blank"
                >
                  Download{" "}
                  <span>
                    <img
                      className="vault_card_btn_img"
                      src="img/download_icon.svg"
                      alt="download"
                    />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "./Header";
import Footer from "./Footer";
import $, { data } from "jquery";
import axios from "axios";
import { Redirect } from "react-router";
import filterFn2 from "./TargetedFilterFcn";
import Sidebar from "./Sidebar";

var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);
let baseUrl = process.env.REACT_APP_API_BASE_URL;
let Decagentid;
let Decjwttoken;
var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);
const pending = "pending";
const complete = "complete";
const invaliddoc = "invaliddoc";
const review = "under review";
let clrinterval = false
export class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mydata: [],
      mySearch: [],
      filteredData: [],
      filter: false,
      pageCount: 0,
      latest: true,
      userinfo: "contact",
      userinfoplaceholder: "Enter mobile no. here",
      userinfoinput: "",
      mobileotp: "",
      emailotp: "",
      rtime: 90,
      userid: "",
      searchinputtype: "",
      searchinput: "",
      entry: 10,
      currentPage: 0,
      searchdone: false,
    };
  }

  fetchData = () => {
    $("#myuser-loader").show();
    $("#myuser-empty").hide();
    axios
      .post(
        `${baseUrl}p=myUser`,
        {
          agentId: Decagentid,
          pageCount: this.state.currentPage + 1,
          entry: this.state.entry,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == 200 && res.data.status == "success") {
          $("#myuser-loader").hide();
          if (res.data.myUser.length == 0) {
            $("#myuser-empty").show();
          }
          this.setState({ mydata: res.data.myUser });
          this.setState({ myUser: res.data.myUser });
          this.setState({ pageCount: res.data.totalpageCount });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          $("#myuser-loader").hide();
          this.showNotification("Something went wrong");
        }
      })
      .catch((err) => {
        $("#myuser-loader").hide();
        this.showNotification("Something went wrong");
      });
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    $(document.body).css("background-color", "#2d2d2d");
    // $("#invaliduserinfoinput").hide();
    $(".sendOtpBtn").show();
    // $("#success-modal").hide()
    //  $("#unsuccess-modal").hide()
    $("#userinfomodal").show();

    let mainagentid = localStorage.getItem("qazxswedcvfrtgb");
    Decagentid = encryptor.decrypt(mainagentid);
    let mainjwttoken = localStorage.getItem("toeljgtkewlna");
    Decjwttoken = encryptor.decrypt(mainjwttoken);
    this.fetchData();
    filterFn2("myUserTable")
    sessionStorage.setItem("srewrercgdgfdgf", encryptor.encrypt(false));
  };

  logout = () => {
    localStorage.removeItem("gglltakoinoeenl");
    localStorage.removeItem("toeljgtkewlna");
    localStorage.removeItem("qazxswedcvfrtgb");
    localStorage.removeItem("lshhihfnkff");
    this.props.history.push({
      pathname: `/`,
    });
  };

  handlePageClick = ({ selected: selectedPage }) => {
    this.setState({ mydata: [] });
    this.setState({ currentPage: selectedPage }, this.fetchData);
  };
  userinfoplaceholder = () => {
    if (this.state.userinfo == "contact") {
      this.setState({ userinfoplaceholder: "Enter mobile no. here" });
    } else if (this.state.userinfo == "email") {
      this.setState({ userinfoplaceholder: "Enter email here" });
    } else {
      this.setState({ userinfoplaceholder: "Enter user id" });
    }
  };
  filterbystatus = (t) => {

      $("#myuser-empty").hide();
      if(t == "all"){
        this.setState({ filter: false }, this.state.latest?this.renderlatest: this.renderoldest);
      }else{
    this.setState({ filter: true });
    let filtStatus = this.state.mydata.filter(el => {
      if(el.status == t){
        return true
      }else {return false}
    }) ;
    
    this.setState({filteredData : filtStatus}, this.state.latest?this.renderlatest: this.renderoldest)}
  }
  SendOTP = (e) => {
    clrinterval = false
  
    this.setState({ mobileotp: "",emailotp: ""})
    e.preventDefault();
    $("#invalidusermobileotp").hide();
    $("#invaliduseremailotp").hide();
    $("#otpsentsuccess").hide();
    $("#invaliduserinfoinput").hide();
    $("#invaliduserdetails").hide();
    $("#unverifieduser").hide();
    $("#userexists").hide();
    // this.setState({ userinfoinput: e.target.value });
    const contactNoregex = /^[6-9]\d{9}$/;
    const emailregex = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    if (this.state.userinfoinput.trim() == "" && this.state.userinfo == "contact") {
      $(".erruserinfo").html("Please enter mobile no");
      $("#invaliduserinfoinput").show();
    } else if (
      this.state.userinfo == "contact" &&
      isNaN(this.state.userinfoinput)
    ) { $(".erruserinfo").html("Please enter valid mobile no");
      $("#invaliduserinfoinput").show();
    } else if (
      this.state.userinfo == "contact" &&
      !contactNoregex.test(this.state.userinfoinput)
    ) { $(".erruserinfo").html("Please enter valid mobile no");
      $("#invaliduserinfoinput").show();
    } else if (
      this.state.userinfo == "contact" &&
      this.state.userinfoinput.length > 10
    ) { $(".erruserinfo").html("Please enter valid mobile no");
      $("#invaliduserinfoinput").show();
    }else if(this.state.userinfoinput.trim() =="" && this.state.userinfo == "email"){
      $("#invaliduserinfoinput").show();
      $(".erruserinfo").html("Please enter email");
    } else if (
      this.state.userinfo == "email" &&
      !emailregex.test(this.state.userinfoinput)
    ) { $(".erruserinfo").html("Please enter valid email");
      $("#invaliduserinfoinput").show();
    } else if (
      this.state.userinfo == "email" &&
      this.state.userinfoinput.length > 50
    ) { $(".erruserinfo").html("Please enter valid email");
      $("#invaliduserinfoinput").show();
    } else if (
      this.state.userinfo == "userId" &&
      this.state.userinfoinput.length > 20
    ) { $(".erruserinfo").html("Please enter valid userid");
      $("#invaliduserinfoinput").show();
    } else if (
      this.state.userinfo == "userId" &&
      isNaN(this.state.userinfoinput)
    ) {$(".erruserinfo").html("Please enter valid userid");
      $("#invaliduserinfoinput").show();
    } else {
      $(".sendOtpLoader").show();
      $(".sendOtpBtn").hide();
      $(".erruserinfo").html("Please enter userid");
      $("#invaliduserinfoinput").hide();

      axios
        .post(
          `${baseUrl}p=linkUser`,
          {
            agentId: Decagentid,
            inputtype: this.state.userinfo.trim(),
            inputvalue: this.state.userinfoinput.trim(),
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          
          if (res.data.code == 200 && res.data.status == "success") {
            $(".sendOtpLoader").hide();
            $(".sendOtpBtn").show();
            this.setState({ userid: res.data.UserID });
            // this.showNotification(res.data.message);
            $("#userinfomodal").hide();
            $("#otpmodal").show();
            this.countdown();
          } else if (res.data.code == 201 && res.data.status == "notfound") {
            // this.showNotification(res.data.message);
            $("#invaliduserdetails").show();
            $(".sendOtpLoader").hide();
            $(".sendOtpBtn").show();
          } else if (
            res.data.code == 201 &&
            res.data.status == "alreadyexist"
          ) {
            // this.showNotification(res.data.message);
          $(".useralreadyexists").html(res.data.message);
            $("#userexists").show();
            $(".sendOtpLoader").hide();
            $(".sendOtpBtn").show();
          } else if (
            res.data.code == 201 &&
            res.data.status == "unverifiedAccount"
          ) {
            $("#unverifiedUser").html(res.data.message);
           
            $("#unverifieduser").show();
            $(".sendOtpLoader").hide();
            $(".sendOtpBtn").show();
          } else if (res.data.code == 201 && res.data.status == "fail") {
            // this.showNotification(res.data.message);
            $("#unverifiedUser").html(res.data.message);
           
            $("#unverifieduser").show();
            $(".sendOtpLoader").hide();
            $(".sendOtpBtn").show();
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          }
        })
        .catch((err) => {
          // 
          this.showNotification("Something went wrong");
          $(".sendOtpLoader").hide();
          $(".sendOtpBtn").show();
        });
    }
  };

  clickViewBtn = (data) => {
    localStorage.setItem("relleIsugad", encryptor.encrypt(data.userId));
    localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
    
    this.props.history.push({
      pathname: `/myuser/${data.userId}`,
      state: { name: data.username, email: data.email, contact: data.contact, requesttype: "temporary" },
    });
  };
  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };
  countdown() {
    this.setState({ rtime: 90 });
    $("#mobileresend-loader").hide();
    function clear() {
      clearInterval(interval);
      $("#mobileresend-loader").hide();
    }
    let interval = setInterval(() => {
      if (this.state.rtime == 1 || clrinterval) {
        clear(interval);
        $("#mobileresend-loader").hide();
      } else {
      }
      this.setState({ rtime: this.state.rtime - 1 });
    }, 1000);
  }
  SubmitOTP = () => {
    $("#invalidusermobileotp").hide();
    $("#invaliduseremailotp").hide();
    $("#otpsentsuccess").hide();
    if (
      this.state.mobileotp.trim() == "" &&
      this.state.mobileotp.trim() == ""
    ) {
      $(".invalidmobile").html("Please enter mobile OTP")
      $(".invalidemail").html("Please enter email OTP")
      $("#invalidusermobileotp").show();
      $("#invaliduseremailotp").show();
    } else if (
      this.state.mobileotp.trim() == "" ||
      this.state.mobileotp.length > 6 ||
      this.state.mobileotp.length < 6 ||
      isNaN(this.state.mobileotp)
    ) {$(".invalidmobile").html("Please enter valid mobile OTP")
      $("#invalidusermobileotp").show();
    } else if (
      this.state.emailotp.trim() == "" ||
      this.state.emailotp.length > 6 ||
      this.state.emailotp.length < 6 ||
      isNaN(this.state.emailotp)
    ) {$(".invalidemail").html("Please enter valid email OTP")
      $("#invaliduseremailotp").show();
    } else {

      axios
        .post(
          `${baseUrl}p=verifyuserotp`,
          {
            agentId: Decagentid,
            userId: this.state.userid.trim(),
            contactOTP: this.state.mobileotp.trim(),
            emailOTP: this.state.emailotp.trim(),
            type: "linkUser",
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          
          if (res.data.code == 200 && res.data.status == "success") {
            $(".sendOtpLoader").hide();
            $(".sendOtpBtn").show();
            this.setState({ userid: res.data.userId });
            // this.showNotification(res.data.message)
            $("#userinfomodal").hide();
            $("#otpmodal").hide();
            $("#success-modal").show();
            this.countdown();
          } else if (
            res.data.code == 201 &&
            res.data.status == "contactotpinvalid"
          ) {  $(".invalidmobile").html("Please enter mobile OTP")
          
            $("#invalidusermobileotp").show();
          } else if (
            res.data.code == 201 &&
            res.data.status == "emailotpinvalid"
          ) { 
          $(".invalidemail").html("Please enter email OTP")
            $("#invaliduseremailotp").show();
          } else if (
            res.data.code == 201 &&
            res.data.status == "bothotpinvalid"
          ) {
            $(".invalidmobile").html("Please enter mobile OTP")
            $(".invalidemail").html("Please enter email OTP")
            $("#otpmodal").show();
            $("#invalidusermobileotp").show();
            $("#invaliduseremailotp").show();
          } else if (res.data.code == 201 && res.data.status == "fail") {
            this.showNotification(res.data.message);
            $("#userinfomodal").hide();
            $("#otpmodal").hide();
            $("#unsuccess-modal").show();
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          }
          else {
      
          }
        })
        .catch((err) => {
          // 
          this.showNotification("Something went wrong");
          // $(".sendOtpLoader").hide();
          //   $(".sendOtpBtn").show();
        });
    }
  };
  resenduserOtp = () => {
    
    $("#invalidusermobileotp").hide();
    $("#invaliduseremailotp").hide();
    $("#otpsentsuccess").hide();
    if (this.state.rtime == 0) {
      axios
        .post(
          `${baseUrl}p=linkUser`,
          {
            agentId: Decagentid,
            inputtype: "userId",
            inputvalue: this.state.userid.trim(),
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          
          if (res.data.code == 200 && res.data.status == "success") {
            $("#otpsentsuccess").show();
            this.setState({ rtime: 90 }, this.countdown);
          } else if (res.data.code == 201 && res.data.status == "fail") {
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          }
        })
        .catch((err) => {
          // 
          this.showNotification("Something went wrong");
          // $(".sendOtpLoader").hide();
          //   $(".sendOtpBtn").show();
        });
    } else {
      return null;
    }
  };
  changeuserinfo = () => {
    $("#invalidusermobileotp").hide();
    $("#invaliduseremailotp").hide();
    $("#userinfomodal").show();
    $("#otpmodal").hide();
    clrinterval = true
  };
  linkUser = () => {
    $("#link-User").modal({ backdrop: "static", keyboard: false });
        $("#invalidusermobileotp").hide();
    $("#invaliduseremailotp").hide();
    $("#otpsentsuccess").hide();
    $("#invaliduserinfoinput").hide();
    $("#invaliduserdetails").hide();
    $("#unverifieduser").hide();
    $("#userexists").hide();
    $(".sendOtpBtn").show();
    $("#success-modal").hide();
    $("#unsuccess-modal").hide();
    $("#userinfomodal").show();
    $("#unlockotp").hide();
    $("#otpmodal").hide();
  };
  rendersearch= () => {
    $(".filter-sort-btn").attr("disabled", true)
    $("#myuser-loader").hide();
    return this.state.mySearch.map((data, index) => {
      return (
        <tr key={index}>
          <td scope="row" className="sno">
            {index + 1}
          </td>
          <td className="userid userid-td">{data.userId}</td>
          <td className="username">{data.username}</td>
          <td className="email">{data.emailverifySt == true ? <span className="text-success">{data.email}</span> : <span className="text-danger">{data.email}</span>}</td>
          <td className="contact ">{data.contactverifySt == true ? <span className="text-success">{data.contact}</span> : <span className="text-danger">{data.contact}</span>}</td>
          <td className="services">
            {data.services.length == 0 ? <span >-</span> :data.services.map((numbers, index) => {
              
              if (index == data.services.length - 1) {
                return <span key={index}>{numbers}</span>;
              } else {
                return <span key={index}>{numbers + ","}</span>;
              }
            })}
          </td>
          <td className="status">{data.status == null ? <span>-</span> : data.status}</td>
          <td className="details">
            <button
              className="agent__viewbtn mt-2"
              onClick={() => this.clickViewBtn(data)}
            >
              View <img src={process.env.PUBLIC_URL + "/img/Path 19498.svg"} />
            </button>
          </td>
        </tr>
      );
    });
  }
  renderlatest = () => {
    $("#myuser-empty").hide();
    $(".filter-sort-btn").removeAttr("disabled")
    // this.filterbystatus()
    if (!this.state.filter) {
      if(this.state.mydata.length == 0){
        $("#myuser-empty").show();
      }else {
        
    return this.state.mydata.map((data, index) => {
      return (
        <tr key={index}>
          <td scope="row" className="sno">
          {(this.state.entry*(this.state.currentPage)) + index + 1 }
          </td>
          <td className="userid userid-td">{data.userId}</td>
          <td className="username">{data.username}</td>
          <td className="email">{data.emailverifySt == true ? <span className="text-success">{data.email}</span> : <span className="text-danger">{data.email}</span>}</td>
            <td className="contact ">{data.contactverifySt == true ? <span className="text-success">{data.contact}</span> : <span className="text-danger">{data.contact}</span>}</td>
          <td className="services">
            {data.services.length == 0 ? <span >-</span> : data.services.map((numbers, index) => {
              
              if (index == data.services.length - 1) {
                return <span key={index}>{numbers}</span>;
              } else {
                return <span key={index}>{numbers + ","}</span>;
              }
            })}
          </td>
          <td className="status">{data.status == null ? <span>-</span> : data.status}</td>
          <td className="details">
            <button
              className="agent__viewbtn mt-2"
              onClick={() => this.clickViewBtn(data)}
            >
              View <img src={process.env.PUBLIC_URL + "/img/Path 19498.svg"} />
            </button>
          </td>
        </tr>
      );
    });}}else {
      if (this.state.filteredData.length == 0) {
        $("#myuser-empty").show();
      } else   return this.state.filteredData.map((data, index) => {
        return (
          <tr key={index}>
            <td scope="row" className="sno">
            {(this.state.entry*(this.state.currentPage - 1)) + index + 1 }
            </td>
            <td className="userid userid-td">{data.userId}</td>
            <td className="username">{data.username}</td>
            <td className="email">{data.email}</td>
            <td className="contact ">{data.contact}</td>
            <td className="services">
              {data.services.length == 0 ? <span >-</span> :data.services.map((numbers, index) => {
                
                if (index == data.services.length - 1) {
                  return <span key={index}>{numbers}</span>;
                } else {
                  return <span key={index}>{numbers + ","}</span>;
                }
              })}
            </td>
            <td className="status">{data.status == null ? <span>-</span> : data.status}</td>
            <td className="details">
              <button
                className="agent__viewbtn mt-2"
                onClick={() => this.clickViewBtn(data)}
              >
                View <img src={process.env.PUBLIC_URL + "/img/Path 19498.svg"} />
              </button>
            </td>
          </tr>
        );})

    }
  };

  renderoldest = () => {
    $("#myuser-empty").hide();
    $(".filter-sort-btn").removeAttr("disabled")
    if (!this.state.filter) {
    if(this.state.mydata.length == 0){
      $("#myuser-empty").show();
    }else{
    return this.state.mydata
      .slice()
      .reverse()
      .map((data, index) => {
        return (
          <tr key={index}>
            <td scope="row" className="sno">
            {(this.state.entry*(this.state.currentPage - 1)) + index + 1 }
            </td>
            <td className="userid userid-td">{data.userId}</td>
            <td className="username">{data.username}</td>
            <td className="email">{data.emailverifySt == true ? <span className="text-success">{data.email}</span> : <span className="text-danger">{data.email}</span>}</td>
            <td className="contact ">{data.contactverifySt == true ? <span className="text-success">{data.contact}</span> : <span className="text-danger">{data.contact}</span>}</td>
            <td className="services">
              {data.services.length == 0 ? <span >-</span> :data.services.map((numbers, index) => {
                
                if (index == data.services.length - 1) {
                  return <span key={index}>{numbers}</span>;
                } else {
                  return <span key={index}>{numbers + ","}</span>;
                }
              })}
            </td>
            <td className="status">{data.status == null ? <span>-</span> : data.status}</td>
            <td className="details">
            <button
              className="agent__viewbtn mt-2"
              onClick={() => this.clickViewBtn(data)}
            >
              View <img src={process.env.PUBLIC_URL + "/img/Path 19498.svg"} />
            </button>
            </td>
          </tr>
        );
      });}}else {
        if (this.state.filteredData.length == 0) {
          $("#myuser-empty").show();
        }else return this.state.filteredData
      .slice()
      .reverse()
      .map((data, index) => {
       
        return (
          <tr key={index}>
            <td scope="row" className="sno">
            {(this.state.entry*(this.state.currentPage - 1)) + index + 1 }
            </td>
            <td className="userid userid-td">{data.userId}</td>
            <td className="username">{data.username}</td>
            <td className="email">{data.email}</td>
            <td className="contact ">{data.contact}</td>
            <td className="services">
              {data.services.length == 0 ? <span >-</span> :data.services.map((numbers, index) => {
                
                if (index == data.services.length - 1) {
                  return <span key={index}>{numbers}</span>;
                } else {
                  return <span key={index}>{numbers + ","}</span>;
                }
              })}
            </td>
            <td className="status">{data.status == null ? <span>-</span> : data.status}</td>
            <td className="details">
            <button
              className="agent__viewbtn mt-2"
              onClick={() => this.clickViewBtn(data)}
            >
              View <img src={process.env.PUBLIC_URL + "/img/Path 19498.svg"} />
            </button>
            </td>
          </tr>
        );
      });
      }
  };
  searchdata = () => {
    
   
    axios
      .post(
        `${baseUrl}p=searchUser`,
        {
          agentId: Decagentid,
          type: this.state.searchinputtype.trim(),
          value: this.state.searchinput.trim(),
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        
        $("#searchfielduser").removeAttr('disabled');
        if(res.data.code == "200" && res.data.status == "success"){
         
          this.setState({mySearch: res.data.myUser ,searchdone: true})
     
          if(res.data.myUser.length == 0){
            $("#myuser-empty").show()}
        }else {
          // this.setState({searchdone: false})
          this.showNotification(res.data.message)
        }
      }).catch(err => {
        $("#searchfielduser").removeAttr('disabled');
        // this.setState({searchdone: false})
        this.showNotification("Something went wrong");
      })
  };
  handleSearchinput = (e) => {
    
    $("#searchfielduser").attr("disabled", "disabled")
    e.preventDefault();
    const contactNoregex = /^[6-9]\d{9}$/;
    const emailregex = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
   
    if(this.state.searchinput.trim() != "" && this.state.searchinput.length < 100){
      $("#myuser-loader").show();
      // this.setState({searchdone: true})
  
     
    if (emailregex.test(this.state.searchinput)) {
      this.setState({ searchinputtype: "email" }, this.searchdata);
    } else if (contactNoregex.test(this.state.searchinput)) {
      
      this.setState({ searchinputtype: "contact" }, this.searchdata);
    } else if (isNaN(this.state.searchinput)) {
      var mySentence = this.state.searchinput.trim();
      mySentence=mySentence.replace(/\s+/g, " ");
      const words = mySentence.split(" ");
      
      for (let i = 0; i < words.length; i++) {
          words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
    var mywords= words.join(" ");
    this.setState({ searchinputtype: "username" });
      this.setState({ searchinput: mywords.trim() }, this.searchdata);
    } else if (!isNaN(this.state.searchinput)) {
     
      this.setState({ searchinputtype: "userId" }, this.searchdata);
    } else {
      this.showNotification("Invalid Search");
    }
  }
    else { this.setState({searchdone: false});
    this.showNotification("No data found")
    // $("#myuser-empty").show()
    this.setState({searchinput: ""})
    $("#searchfielduser").removeAttr('disabled');
  }
    
    // $("#myuser-loader").hide();
  };

  render() {
    if (
      localStorage.getItem("gglltakoinoeenl") ==
        process.env.REACT_APP_LOGIN_KEY &&
      localStorage.getItem("toeljgtkewlna") != null &&
      localStorage.getItem("qazxswedcvfrtgb") != null
    ) {
      
      $(".resendOTP").addClass("text-muted");
      if (this.state.rtime == 0) {
        $(".resendOTP").removeClass("text-muted");
      }

      return (
        <div>
          <div className="cont">
            <div id="notifContainer"></div>
            <Helmet>
              <link
                rel="stylesheet"
                href={process.env.PUBLIC_URL + "/css/select2.css"}
              />
              <link rel="stylesheet" href="css/services.css" />
            </Helmet>

            <Header />

            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
              className="sidebaropenicon"
            />
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
              className="sidebarbackicon"
            />
            <img
              alt="loading..."
              src={
                process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"
              }
              className="sidebarexpandicon"
            />

            <div className="overlay1"></div>
            <Sidebar/>

            <div className="arrow-left3 "></div>
            <div className="white_div">
              <div className="white_box">
                <div className="row main-row">
                  <div className="row justify-content-between align-items-center w-100 mb-3 ml-3 mr-3">
                    <div className="row col-7 justify-content-between  px-2">
                      <div className="col-lg-6 col-7">
                        <form onSubmit={this.handleSearchinput}>
                          <input
                            type="text"
                            id="searchfielduser"
                            className="agent__overview__search_box"
                            placeholder="Search by UserId or name"
                            value={this.state.searchinput}
                            maxLength="100"
                            autoComplete="off"
                            onChange={(e) =>
                              {this.setState({ searchinput: e.target.value, searchdone: false, mySearch: [] })
                              }
                            }
                          />
                        </form>
                      </div>
                      <h4 className="agentheader-text col-lg-3 col-7">
                        My Users
                      </h4>
                    </div>
                    <button
                      className="d-flex btn ml-auto agentlinkuser-btn "
                      onClick={this.linkUser}
                      data-toggle="modal"
                      data-target="#link-User"
                    >
                      <img
                        className="mr-2"
                        src={process.env.PUBLIC_URL + "/img/Path_111.svg"}
                        width="13px"
                      />{" "}
                      Link User
                    </button>
                    <div className="  pl-0 button-bar">
                      <div className="dropdown px-md-3 px-0">
                        <button
                          className="btn agent-header-btn header-btn py-1 px-md-2 px-lg-4 px-2 filter-sort-btn"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <img
                            src={process.env.PUBLIC_URL + "/img/Path 19648.svg"}
                            alt=""
                          />
                          <span>Sort by</span>
                        </button>
                        <div
                          className="dropdown-menu drop myser-mar-l-05"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a
                            className="dropdown-item cursorpointer"
                            onClick={() => this.setState({ latest: true })}
                          >
                            Latest to Oldest
                          </a>
                          <a
                            className="dropdown-item cursorpointer"
                            onClick={() => this.setState({ latest: false })}
                          >
                            Oldest to Latest
                          </a>
                        </div>
                      </div>
                      <div className="dropdown">
                              <button
                                className="btn agent-header-btn header-btn py-1 px-md-2 px-lg-4 px-2 filter-sort-btn"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img
                                  alt="loading..."
                                  src={
                                    process.env.PUBLIC_URL + "/img/Path 210.svg"
                                  }
                                />
                                <span>Filter by</span>
                              </button>
                              <div
                                className="dropdown-menu drop agent__dropdown__filter dash-mar-l-04"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <a
                                  className="dropdown-item cursorpointer"
                                  onClick={() => this.filterbystatus("all")}
                                >
                                All
                                </a>
                                <a
                                  className="dropdown-item cursorpointer"
                                  onClick={() => this.filterbystatus(pending)}
                                >
                                Pending
                                </a>
                                <a
                                  className="dropdown-item cursorpointer"
                                  onClick={() => this.filterbystatus(complete)}
                                >
                                  Complete
                                </a>
                                <a
                                  className="dropdown-item cursorpointer"
                                  onClick={() => this.filterbystatus(review)}
                                >
                                Under Review
                                </a>
                                <a
                                  className="dropdown-item cursorpointer"
                                  onClick={() => this.filterbystatus(invaliddoc)}
                                >
                                  Invalid Doc
                                </a>
                             
                              </div>
                            </div>
                      <div className="dropdown ml-md-0 ml-lg-2 ml-2 px-2">
                        <select
                          className="agent__selectcateselectagent filter-sort-btn"
                          value={this.state.entry}
                          onChange={(e) => {
                            this.setState(
                              { entry: e.target.value, pageCount: 0 },
                              this.handlePageClick({selected: 0})
                            );
                          }}
                        >
                          <option
                            value="10"
                            className="selectcateselectoption2"
                          >
                            10
                          </option>
                          <option
                            value="20"
                            className="selectcateselectoption2"
                          >
                            20
                          </option>
                          <option
                            value="50"
                            className="selectcateselectoption2"
                          >
                            50
                          </option>
                          <option
                            value="100"
                            className="selectcateselectoption2"
                          >
                            100
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive my-table">
                    <table className="table mr-3" id="myUserTable">
                      <thead className="my-thead ">
                        <tr className="myUserTable">
                          <th className="my-th sno" data-type="number">Sno <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt=""/></th>
                          <th className="my-th userid">User Id <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt=""/></th>
                          <th className="my-th username">Username <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt=""/></th>
                          <th className="my-th email">Email <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt=""/></th>
                          <th className="my-th contact ">Contact <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt=""/></th>
                          <th className="my-th services">Services <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt=""/></th>
                          <th className="my-th status">Status <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt=""/></th>
                          <th className="my-th details">Details <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt=""/></th>
                        </tr>
                      </thead>
                     
                        <div className="loader-abs " id="myuser-loader">
                          <img
                            src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          />
                        </div>

                        <div className="empty-abs" id="myuser-empty">
                          <img
                            src={process.env.PUBLIC_URL + "/img/quiteempty.svg"} className="nullImg"
                          />
                        </div>
                      
                      <tbody className="my-tbody my-tbodysupport">
                        {this.state.searchdone? this.rendersearch():this.state.latest
                          ? this.renderlatest()
                          : this.renderoldest()}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="mt-3 w-100">
                  <ReactPaginate
                    previousLabel={
                      <img
                        src={process.env.PUBLIC_URL + "/img/Path_21772.svg"}
                        width="15px"
                      />
                    }
                    nextLabel={
                      <img
                        src={process.env.PUBLIC_URL + "/img/Path_21773.svg"}
                        width="15px"
                      />
                    }
                    pageCount={this.state.pageCount}
                    onPageChange={this.handlePageClick}
                    forcePage={this.state.currentPage}
                    containerClassName={"pagination"}
                    previousLinkClassName={"pagination__link"}
                    nextLinkClassName={"pagination__link"}
                    disabledClassName={"pagination__link--disabled"}
                    activeClassName={"pagination__link----active"}
                  />
                </div>
              </div>
            </div>

            <Footer />
          </div>
          <div
            className="modal fade"
            id="link-User"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered "
              role="document"
            >
              <div className="modal-content back-modal " id="userinfomodal">
                <div className="w-100 d-flex">
                  <button
                    type="button"
                    className="border-0 bg-transparent ml-auto p-2 pr-3  text-right my-back-modal-close"
                    data-dismiss="modal"
                    onClick={() => {this.setState({ rtime: 1 })
                    $("#invaliduserinfoinput").hide();}}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/img/Page-1.svg"}
                      width="17px"
                    />
                  </button>
                </div>
                <div className="text-center">
                  <h4 className="my-linkmodal-text ">User Information</h4>
                </div>
                <div className="modal-body linkuser-modal border-0 text-center pb-0">
                  <select
                    name="linkUser"
                    id="LinkUser"
                    value={this.state.userinfo}
                    onChange={(e) => {   $("#invaliduserinfoinput").hide();
                    $("#invaliduserdetails").hide();
                    $("#unverifieduser").hide();
                    $("#userexists").hide();
                      this.setState({ userinfo: e.target.value });
                      if (e.target.value == "contact") {
                        $("#userinfoinput").attr(
                          "placeholder",
                          "Enter mobile no. here"
                        );
                        $("#userinfoinput").attr("maxLength", "10");
                        this.setState({ userinfoinput: "" });
                      } else if (e.target.value == "email") {
                        $("#userinfoinput").attr(
                          "placeholder",
                          "Enter email here"
                        );
                        $("#userinfoinput").attr("maxLength", "50");
                        this.setState({ userinfoinput: "" });
                      } else {
                        $("#userinfoinput").attr(
                          "placeholder",
                          "Enter user id here"
                        );
                        $("#userinfoinput").attr("maxLength", "10");
                        this.setState({ userinfoinput: "" });
                      }
                    }}
                  >
                    <option value="contact">Mobile Number</option>
                    <option value="email">Email</option>
                    <option value="userId">User Id</option>
                  </select>
                </div>
                <div className="input-userinfo">
                  <input
                    className="Userinfo-input"
                    maxLength="10"
                    value={this.state.userinfoinput}
                    onChange={(e) =>
                      this.setState({ userinfoinput: e.target.value })
                    }
                    id="userinfoinput"
                    type="text"
                    placeholder={this.state.userinfoplaceholder}
                  />
                  <p
                    className=" mt-2 warn align-items-center"
                    id="invaliduserinfoinput"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/Icon_feather-alert-circle.svg"
                      }
                      width="12px"
                      className=" mr-1"
                    />{" "}
                    <span className="erruserinfo"></span>
                  </p>
                  <p
                    className=" mt-2 warn align-items-center"
                    id="invaliduserdetails"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/Icon_feather-alert-circle.svg"
                      }
                      width="12px"
                      className=" mr-1"
                    />{" "}
                    No such user found !
                  </p>
                  <p className=" mt-2 warn align-items-center" id="userexists">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/Icon_feather-alert-circle.svg"
                      }
                      width="12px"
                      className=" mr-1"
                    />{" "}
                    <span className="useralreadyexists"></span>
                  </p>
                  <p
                    className=" mt-2  warn align-items-center"
                    id="unverifieduser"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/Icon_feather-alert-circle.svg"
                      }
                      width="12px"
                      className=" mr-1"
                    />{" "}
                    <span id="unverifiedUser"></span>
                  </p>
                </div>
                <div className="modal-footer border-0 justify-content-center mb-4">
                  <img
                    className=" p-2 pl-3 pr-3 sendOtpLoader"
                    src="/img/loader.gif"
                    width="80px"
                    alt=""
                  />
                  <button
                    type="button"
                    onClick={(e) => this.SendOTP(e)}
                    className="btn  send-btn text-white  sendOtpBtn"
                  >
                    Send OTP
                  </button>
                </div>
              </div>

              <div className="modal-content back-modal " id="otpmodal">
                <div className="w-100 d-flex">
                  <button
                    type="button"
                    className="border-0 bg-transparent ml-auto p-2 pr-3  text-right my-back-modal-close"
                    data-dismiss="modal"
                    onClick={() => {this.setState({ rtime: 1 , userinfoinput: ""})
                    $("#invaliduserinfoinput").hide();}}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/img/Page-1.svg"}
                      width="17px"
                    />
                  </button>
                </div>
                <div className="text-center">
                  <h4 className="my-linkmodal-text enterotp">Enter OTP</h4>
                </div>
                <div className="input-userinfo otpinput emailotp">
                  <h5 className="otpinput-head">
                    Enter OTP sent to user's mobile
                  </h5>
                  <input
                    className="Userinfo-input otp-input"
                    maxLength="6"
                    type="tel"
                    value={this.state.mobileotp}
                    onChange={(e) =>
                      this.setState({ mobileotp: e.target.value })
                    }
                    id="userinfoinput"
                    placeholder="Enter OTP here..."
                  />
                  <p
                    className=" mt-2 warn align-items-center m-0"
                    id="invalidusermobileotp"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/Icon_feather-alert-circle.svg"
                      }
                      width="15px"
                      className=" mr-1"
                    />{" "}
                    <span className="invalidmobile"></span>
                  </p>
                </div>
                <div className="input-userinfo otpinput emailotp">
                  <h5 className="otpinput-head">
                    Enter OTP sent to user's email
                  </h5>
                  <input
                    className="Userinfo-input otp-input "
                    maxLength="6"
                    type="tel"
                    value={this.state.emailotp}
                    onChange={(e) =>
                      this.setState({ emailotp: e.target.value })
                    }
                    id="userinfoinput"
                    placeholder="Enter OTP here..."
                  />
                  <p
                    className=" mt-2 warn align-items-center m-0"
                    id="invaliduseremailotp"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/Icon_feather-alert-circle.svg"
                      }
                      width="15px"
                      className=" mr-1"
                    />{" "}
                    <span className="invalidemail"></span>
                  </p>
                </div>

                <div className="d-flex justify-content-between  otpmodaloptions">
                  <h6 onClick={this.changeuserinfo}>Change mobile/email</h6>{" "}
                  <h6
                    className="text-muted resendOTP
                    "
                    id=""
                    onClick={this.resenduserOtp}
                  >
                    Resend OTP  {this.state.rtime != 0 ? `(${this.state.rtime} Sec)` : " "}
                  </h6>
                </div>
                <p className="" id="otpsentsuccess">
                  OTP sent successfully{" "}
                </p>
                <div className="modal-footer border-0 justify-content-center mb-4">
                  <img
                    className=" p-2 pl-3 pr-3 sendOtpLoader"
                    src="/img/loader.gif"
                    width="80px"
                    alt=""
                  />
                  <button
                    type="button"
                    onClick={this.SubmitOTP}
                    className="btn  send-btn text-white"
                    id="submitOtpbtn"
                  >
                    Submit
                  </button>
                </div>
              </div>

              <div className="modal-content back-modal " id="success-modal">
                <div className="modal-body border-0 text-center pb-0 mt-4">
                  <h4 className="my-warn-text ">
                    User Link Requested Successfully
                  </h4>
                  <img
                    className="p-2"
                    src={process.env.PUBLIC_URL + "/img/Group 5485.svg"}
                    width="100px"
                  />
                  <p className="my-success-para m-0">
                    link request has been created. it will be approved after
                    reviewing and contacting customer.
                  </p>
                </div>
                <div className="modal-footer border-0 justify-content-center mb-4">
                  <button
                    type="button"
                    className="btn  send-btn text-white  "
                    data-dismiss="modal"
                    id="backtoHome"
                    onClick={() => {$("#invaliduserinfoinput").show();}}
                  >
                    Home
                  </button>
                </div>
              </div>

              <div className="modal-content back-modal " id="unsuccess-modal">
                <div className="modal-body border-0 text-center pb-0 mt-4 mb-3">
                  <h4 className="my-warn-text px-3 mb-3">
                    User Link Unsuccessfull
                  </h4>
                  <img
                    className="p-2"
                    src={process.env.PUBLIC_URL + "/img/Group_5486.svg"}
                    width="100px"
                  />
                </div>
                <div className="modal-footer border-0 justify-content-center mb-4">
                  <button
                    type="button"
                    className="btn  send-btn text-white  "
                    data-dismiss="modal"
                    id="backtoHome"
                  >
                    Home
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default Page;

import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import { Redirect } from "react-router";
import Sidebar from "./Sidebar";
import AllServices from "../AllServices.json"

let baseUrl = process.env.REACT_APP_API_BASE_URL;
var pendingpaymentGSTR = [];
var pendingpaymentGSTRF = [];
var pendingpaymentITRF = [];
var pendingpaymentTR = [];
var pendingpaymentOA = [];
var pendingpaymentGUMASTA = [];
var pendingpaymentTradeLicence = [];
var pendingpaymentFoodLicence = [];
var completepaymentGSTR = [];
var completepaymentGSTRF = [];
var completepaymentITRF = [];
var completepaymentTR = [];
var completepaymentOA = [];
var completepaymentGUMASTA = [];
var completepaymentTradeLicence = [];
var completepaymentFoodLicence = [];
let Decuserid;
let Decagentid;
let Decjwttoken;
let temp = "general";
var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);
class DocumentsRecords extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pendingpaymentstate: "Latest",
      completepaymentstate: "Latest",
      completepaymentmainarray: [],
      pendingpaymentmainarray: [],
      userid: "",
      email: "",
      contact: "",
      username: "",
      mobileotp: "",
      emailotp: "",
      rtime: 90,
      serviceid: "",
      servicename: "",
      servicetype: "active",
      myplan: "",
    };
  }

  backbutton = () => {
    if (
      this.state.userid != "" &&
      this.state.email != "" &&
      this.state.contact != "" &&
      this.state.username != ""
    ) {
      this.props.history.push({
        pathname: `/myuser/${Decuserid}`,
        search: "?query=abc",
        state: {
          userid: this.state.userid,
          email: this.state.email,
          contact: this.state.contact,
          name: this.state.username,
        },
      });
    } else {
      this.props.history.push({
        pathname: "/documentrecord",
      });
    }
  };

  pendingpaymentGSTRfilter = () => {
    this.setState(
      {
        pendingpaymentstate: "GSTR",
      },
      () => {
        if (pendingpaymentGSTR.length == 0) {
          $(".myservicequiteimgstart").show();
        }
      }
    );
  };
  pendingpaymentGSTRFfilter = () => {
    this.setState(
      {
        pendingpaymentstate: "GSTRF",
      },
      () => {
        if (pendingpaymentGSTRF.length == 0) {
          $(".myservicequiteimgstart").show();
        }
      }
    );
  };
  pendingpaymentITRFfilter = () => {
    this.setState(
      {
        pendingpaymentstate: "ITRF",
      },
      () => {
        if (pendingpaymentITRF.length == 0) {
          $(".myservicequiteimgstart").show();
        }
      }
    );
  };
  pendingpaymentTRfilter = () => {
    this.setState(
      {
        pendingpaymentstate: "TR",
      },
      () => {
        if (pendingpaymentTR.length == 0) {
          $(".myservicequiteimgstart").show();
        }
      }
    );
  };
  pendingpaymentOAfilter = () => {
    this.setState(
      {
        pendingpaymentstate: "OA",
      },
      () => {
        if (pendingpaymentOA.length == 0) {
          $(".myservicequiteimgstart").show();
        }
      }
    );
  };
  pendingpaymentGUMASTAfilter = () => {
    this.setState(
      {
        pendingpaymentstate: "GUMASTA",
      },
      () => {
        if (pendingpaymentGUMASTA.length == 0) {
          $(".myservicequiteimgstart").show();
        }
      }
    );
  };
  pendingpaymentTradeLicencefilter = () => {
    this.setState(
      {
        pendingpaymentstate: "TL",
      },
      () => {
        if (this.pendingpaymentTradeLicencefilter.length == 0) {
          $(".myservicequiteimgstart").show();
        }
      }
    );
  };
  pendingpaymentFoodLicencefilter = () => {
    this.setState(
      {
        pendingpaymentstate: "FL",
      },
      () => {
        if (pendingpaymentFoodLicence.length == 0) {
          $(".myservicequiteimgstart").show();
        }
      }
    );
  };

  completepaymentGSTRfilter = () => {
    this.setState(
      {
        completepaymentstate: "GSTR",
      },
      () => {
        if (completepaymentGSTR.length == 0) {
          $(".myservicequiteimgstart").show();
        }
      }
    );
  };
  completepaymentGSTRFfilter = () => {
    this.setState(
      {
        completepaymentstate: "GSTRF",
      },
      () => {
        if (completepaymentGSTRF.length == 0) {
          $(".myservicequiteimgstart").show();
        }
      }
    );
  };
  completepaymentITRFfilter = () => {
    this.setState(
      {
        completepaymentstate: "ITRF",
      },
      () => {
        if (completepaymentITRF.length == 0) {
          $(".myservicequiteimgstart").show();
        }
      }
    );
  };
  completepaymentTRfilter = () => {
    this.setState(
      {
        completepaymentstate: "TR",
      },
      () => {
        if (completepaymentTR.length == 0) {
          $(".myservicequiteimgstart").show();
        }
      }
    );
  };
  completepaymentOAfilter = () => {
    this.setState(
      {
        completepaymentstate: "OA",
      },
      () => {
        if (completepaymentOA.length == 0) {
          $(".myservicequiteimgstart").show();
        }
      }
    );
  };
  completepaymentGUMASTAfilter = () => {
    this.setState(
      {
        completepaymentstate: "GUMASTA",
      },
      () => {
        if (completepaymentGUMASTA.length == 0) {
          $(".myservicequiteimgstart").show();
        }
      }
    );
  };
  completepaymentTradeLicencefilter = () => {
    this.setState(
      {
        completepaymentstate: "TL",
      },
      () => {
        if (completepaymentTradeLicence.length == 0) {
          $(".myservicequiteimgstart").show();
        }
      }
    );
  };
  completepaymentFoodLicencefilter = () => {
    this.setState(
      {
        completepaymentstate: "FL",
      },
      () => {
        if (completepaymentFoodLicence.length == 0) {
          $(".myservicequiteimgstart").show();
        }
      }
    );
  };
  handlemyserviceclick = (serviceId, serviceName, plan, type) => {
    this.setState({ servicetype: "active" });
    this.setState({ myplan: plan });
    if (type == "lock") {
      this.setState({ serviceid: serviceId, servicename: serviceName });
      $("#unlockotp").show();
      $("#unlockotpmodal").hide();
      $("#Unlock-Service").modal("show");
    } else {
      if (plan == null) {
        plan = "";
      }
      localStorage.setItem(
        "vrellvaiecstiadeceig",
        encryptor.encrypt(serviceId)
      );
      localStorage.setItem(
        "eieltaiclsraaemevcvnge",
        encryptor.encrypt(serviceName)
      );
      localStorage.setItem("ipacneglatvlael", encryptor.encrypt(plan));

      if (serviceName == "TR") {
        this.props.history.push({
          pathname: `/documentrecords/${Decuserid}/documentrecords/tr/${serviceId}`,
        });
      } else if (serviceName == "GSTR") {
        this.props.history.push({
          pathname: `/documentrecords/${Decuserid}/documentrecords/gstr/${serviceId}`,
        });
      } else if (serviceName == "GSTRF") {
        this.props.history.push({
          pathname: `/documentrecords/${Decuserid}/documentrecords/gstrf/${serviceId}`,
        });
      } else if (serviceName == "ITRF") {
        this.props.history.push({
          pathname: `/documentrecords/${Decuserid}/documentrecords/itrf/${serviceId}`,
        });
      } else if (serviceName == "OA") {
        this.props.history.push({
          pathname: `/documentrecords/${Decuserid}/documentrecords/oa/${serviceId}`,
        });
      } else if (serviceName == "GUMASTA") {
        this.props.history.push({
          pathname: `/documentrecords/${Decuserid}/documentrecords/gumasta/${serviceId}`,
        });
      } else if (serviceName == "TL") {
        this.props.history.push({
          pathname: `/documentrecords/${Decuserid}/documentrecords/tl/${serviceId}`,
        });
      } else if (serviceName == "FL") {
        this.props.history.push({
          pathname: `/documentrecords/${Decuserid}/documentrecords/fl/${serviceId}`,
        });
      }
      else  {
        this.props.history.push({
          pathname: `/documentrecords/${Decuserid}/documentrecords/${serviceName.toLowerCase()}/${serviceId}`,
        });
      }
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    pendingpaymentGSTR = [];
    pendingpaymentGSTRF = [];
    pendingpaymentITRF = [];
    pendingpaymentTR = [];
    pendingpaymentOA = [];
    pendingpaymentGUMASTA = [];
    pendingpaymentTradeLicence = [];
    pendingpaymentFoodLicence = [];

    completepaymentGSTR = [];
    completepaymentGSTRF = [];
    completepaymentITRF = [];
    completepaymentTR = [];
    completepaymentOA = [];
    completepaymentGUMASTA = [];
    completepaymentTradeLicence = [];
    completepaymentFoodLicence = [];
    $(".sendUnlockdocOtpLoader").hide();
    $("#sendUnlockdocOtp").show();
    $("#submitunlockdocOtpbtn").show();
    $(document.body).css("background-color", "#2d2d2d");
    let mainuserid = localStorage.getItem("relleIsugad");
    Decuserid = encryptor.decrypt(mainuserid);
    let mainagentid = localStorage.getItem("qazxswedcvfrtgb");
    Decagentid = encryptor.decrypt(mainagentid);
    let mainjwttoken = localStorage.getItem("toeljgtkewlna");
    Decjwttoken = encryptor.decrypt(mainjwttoken);
    if (mainuserid != null) {
      let paramsId = this.props.match.params.id2;

      if (paramsId != Decuserid) {
        this.props.history.push("/myuser");
      }
    } else {
      this.props.history.push("/myuser");
    }
    // ----------------------------temporary -----------------------------------------


    let temptoken = localStorage.getItem("fdgshdjfkgjhyhdnxhsgdhskcj")
    let Dectemptoken = encryptor.decrypt(temptoken)
    let paramsUserId2 = this.props.match.params.id2;
    if (Dectemptoken != null && Dectemptoken == paramsUserId2) {
      temp = "temporary"
    } else {
      temp = "general"
    }

    // ----------------------------temporary -----------------------------------------
    window.addEventListener("click", function (event) {
      if (event.target.className != "search-box") {
        $(".search-box").removeClass("search-focus");
      }
    });
    this.handledocrecordapi();
     
    let str = document.getElementsByClassName("myservicesortbyfilterbystatus").innerHTML = this.props.location.pathname.replaceAll("/", " > ")
    let mainstr = document.getElementsByClassName("myservicesortbyfilterbystatus").innerHTML = str.replace(" > ", " ")
    $(".myservicesortbyfilterbystatus").html(mainstr)
  }

  handleSearchBox1 = (e) => {
    e.stopPropagation();
    $("#search-box-1").addClass("search-focus");
  };

  handleSearchBox2 = (e) => {
    e.stopPropagation();
    $("#search-box-2").addClass("search-focus");
  };
  logout = () => {
    localStorage.removeItem("gglltakoinoeenl");
    localStorage.removeItem("toeljgtkewlna");
    localStorage.removeItem("qazxswedcvfrtgb");
    localStorage.removeItem("lshhihfnkff");
    this.props.history.push({
      pathname: `/`,
    });
  };
  countdown() {
    this.setState({ rtime: 90 });
    $("#mobileresend-loader").hide();
    function clear() {
      clearInterval(interval);
      $("#mobileresend-loader").hide();
    }
    let interval = setInterval(() => {
      if (this.state.rtime == 1) {
        clear(interval);
        $("#mobileresend-loader").hide();
      } else {
      }
      this.setState({ rtime: this.state.rtime - 1 });
    }, 1000);
  }
  handledocrecordapi = () => {
    $(".myseriveloaderdiv").show();
    $(".header-btn").hide();
    axios({
      method: "post",
      url: process.env.REACT_APP_API_BASE_URL + "p=allDocRecords",
      data: {
        userId: Decuserid,
        requestType: temp,
        agentId: Decagentid,
      },
      headers: {
        Authkey: process.env.REACT_APP_API_KEY,
        "App-Token-Access": Decjwttoken,
      },
      auth: {
        username: process.env.REACT_APP_API_USERNAME,
        password: process.env.REACT_APP_API_PASSWORD,
      },
    })
      .then((resp) => {
        $(".myseriveloaderdiv").hide();
        $(".header-btn").show();

        if (resp.data.code == 200 && resp.data.status == "success") {

          var myArray = resp.data.activeServices.filter(function (obj) {
            return obj.serviceName !== "EXEC";
          });
          var myArray2 = resp.data.completedServices.filter(function (obj) {
            return obj.serviceName !== "EXEC";
          });


          this.setState(
            {
              pendingpaymentmainarray: myArray,
              completepaymentmainarray: myArray2,
              userid: resp.data.userId,
              email: resp.data.email,
              contact: resp.data.contact,
              username: resp.data.username,
            },
            () => {
              if (
                this.state.pendingpaymentmainarray.length == 0 ||
                this.state.completepaymentmainarray.length == 0
              ) {
                $(".myservicequiteimgstart").show();
              }
            }
          );
        } else if (
          resp.data.code == "201" &&
          resp.data.status == "invalidauth"
        ) {
          this.logout();
        }
        else if (resp.data.code == "201" && resp.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
         
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        }
        else if (resp.data.code == "203" && resp.data.status == "notfound") {
          this.setState(
            {
              pendingpaymentmainarray: [],
              completepaymentmainarray: [],
            },
            () => {
              if (
                this.state.pendingpaymentmainarray.length == 0 ||
                this.state.completepaymentmainarray.length == 0
              ) {
                $(".myservicequiteimgstart").show();
              }
            }
          );
        } else {
          this.showNotification(resp.data.message);
        }
      })
      .catch((error) => {
        $(".myseriveloaderdiv").hide();
      });
  };

  serviceunlock = (serviceid, servicename) => {
    $(".sendUnlockdocOtpLoader").show();
    $("#sendUnlockdocOtp").hide();
    axios
      .post(
        `${baseUrl}p=serviceUnlock`,
        {
          agentId: Decagentid,
          userId: Decuserid,
          requestType: temp,
          serviceId: serviceid,
          serviceName: servicename,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == 200 && res.data.status == "success") {
          $(".sendUnlockdocOtpLoader").hide();
          $("#sendUnlockdocOtp").show();

          // this.showNotification(res.data.message);
          $("#unlockotpmodal").show();
          $("#unlockotp").hide();
          this.countdown();
        } else if (res.data.code == 201 && res.data.status == "fail") {
          this.showNotification(res.data.message);
          $(".sendUnlockdocOtpLoader").hide();
          $("#sendUnlockdocOtp").show();
        }
      })
      .catch((err) => {
        // 
        // this.showNotification("Something went wrong");
        // $(".sendOtpLoader").hide();
        // $(".sendOtpBtn").show();
      });
  };
  SubmitOTP = () => {
    $("#invalidunlockmobileotp").hide();
    $("#invalidunlockemailotp").hide();
    $("#otpsentunlocksuccess").hide();
    if (
      this.state.mobileotp.trim() == "" &&
      this.state.emailotp.trim() == ""
    ) {
      $(".invalidmobile").html("Please enter mobile OTP")
      $(".invalidemail").html("Please enter email OTP")
      $("#invalidunlockmobileotp").show();
      $("#invalidunlockemailotp").show();
    } else if (
      this.state.mobileotp.trim() == "" ||
      this.state.mobileotp.length > 6 ||
      this.state.mobileotp.length < 6 ||
      isNaN(this.state.mobileotp)
    ) {$(".invalidmobile").html("Please enter valid mobile OTP")
      $("#invalidunlockmobileotp").show();
    } else if (
      this.state.emailotp.trim() == "" ||
      this.state.emailotp.length > 6 ||
      this.state.emailotp.length < 6 ||
      isNaN(this.state.emailotp)
    ) {$(".invalidemail").html("Please enter valid email OTP")
      $("#invalidunlockemailotp").show();
    } else {
      // $("#otpmodal").hide();
      $(".sendUnlockdocOtpLoader").show();
      $("#submitunlockdocOtpbtn").hide();
      axios
        .post(
          `${baseUrl}p=verifyuserotp`,
          {
            agentId: Decagentid,
            userId: Decuserid,
            requestType: temp,
            contactOTP: this.state.mobileotp,
            emailOTP: this.state.emailotp,
            type: "serviceUnlock",
            serviceId: this.state.serviceid,
            serviceName: this.state.servicename,
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          
          if (res.data.code == 200 && res.data.status == "success") {
            $("#submitunlockdocOtpbtn").show();
            $(".sendOtpLoader").hide();
            $(".sendOtpBtn").show();
            $(".sendUnlockdocOtpLoader").hide();
            localStorage.setItem(
              "vrellvaiecstiadeceig",
              encryptor.encrypt(this.state.serviceid)
            );
            localStorage.setItem(
              "eieltaiclsraaemevcvnge",
              encryptor.encrypt(this.state.servicename)
            );
            localStorage.setItem(
              "ipacneglatvlael",
              encryptor.encrypt(this.state.myplan)
            );
            if (
              this.state.servicename == "TR" 
            ) {
              $("#Unlock-Service").modal("hide");
              this.props.history.push({
                pathname: `/documentrecords/${Decuserid}/documentrecords/tr/${this.state.serviceid}`,
              });
            } else if (
              this.state.servicename == "GSTR"
            ) {
              $("#Unlock-Service").modal("hide");
              this.props.history.push({
                pathname: `/documentrecords/${Decuserid}/documentrecords/gstr/${this.state.serviceid}`,
              });
            } else if (
              this.state.servicename == "GSTRF" 
            ) {
              $("#Unlock-Service").modal("hide");
              this.props.history.push({
                pathname: `/documentrecords/${Decuserid}/documentrecords/gstrf/${this.state.serviceid}`,
              });
            } else if (
              this.state.servicename == "ITRF" 
            ) {
              $("#Unlock-Service").modal("hide");
              this.props.history.push({
                pathname: `/documentrecords/${Decuserid}/documentrecords/itrf/${this.state.serviceid}`,
              });
            } else if (
              this.state.servicename == "OA" 
            ) {
              $("#Unlock-Service").modal("hide");
              this.props.history.push({
                pathname: `/documentrecords/${Decuserid}/documentrecords/oa/${this.state.serviceid}`,
              });
            } else if (
              this.state.servicename == "GUMASTA"
            ) {
              $("#Unlock-Service").modal("hide");
              this.props.history.push({
                pathname: `/documentrecords/${Decuserid}/documentrecords/gumasta/${this.state.serviceid}`,
              });
            } else if (
              this.state.servicename == "TL" 
            ) {
              $("#Unlock-Service").modal("hide");
              this.props.history.push({
                pathname: `/documentrecords/${Decuserid}/documentrecords/tl/${this.state.serviceid}`,
              });
            } else if (
              this.state.servicename == "FL" 
            ) {
              $("#Unlock-Service").modal("hide");
              this.props.history.push({
                pathname: `/documentrecords/${Decuserid}/documentrecords/fl/${this.state.serviceid}`,
              });
            }
            else
            {     $("#Unlock-Service").modal("hide");
            this.props.history.push({
              pathname: `/documentrecords/${Decuserid}/documentrecords/${this.state.servicename.toLowerCase()}/${this.state.serviceid}`,
            });
            }
            this.countdown();
          } else if (
            res.data.code == 201 &&
            res.data.status == "contactotpinvalid"
          ) {$(".invalidmobile").html("Please enter valid mobile OTP")
            $("#invalidunlockmobileotp").show();
            $(".sendUnlockdocOtpLoader").hide();
            $("#submitunlockdocOtpbtn").show();
          } else if (
            res.data.code == 201 &&
            res.data.status == "emailotpinvalid"
          ) {$(".invalidemail").html("Please enter valid email OTP")
            $("#invalidunlockemailotp").show();
            $(".sendUnlockdocOtpLoader").hide();
            $("#submitunlockdocOtpbtn").show();
          } else if (
            res.data.code == 201 &&
            res.data.status == "bothotpinvalid"
          ) {$(".invalidmobile").html("Please enter valid mobile OTP")
          $(".invalidemail").html("Please enter valid email OTP")
            $("#invalidunlockmobileotp").show();
            $("#invalidunlockemailotp").show();
            $(".sendUnlockdocOtpLoader").hide();
            $("#submitunlockdocOtpbtn").show();
          } else if (res.data.code == 201 && res.data.status == "fail") {
            this.showNotification(res.data.message);
            $("#submitunlockdocOtpbtn").show();
            $(".sendUnlockdocOtpLoader").hide();
          }
        })
        .catch((err) => {
          // 
          $(".sendUnlockdocOtpLoader").hide();
          $("#submitunlockdocOtpbtn").show();
          this.showNotification("Something went wrong");
        });
    }
  };
  resenduserOtp = () => {
    
    $("#invalidunlockmobileotp").hide();
    $("#invalidunlockemailotp").hide();
    $("#otpsentunlocksuccess").hide();
    if (this.state.rtime == 0) {
      axios
        .post(
          `${baseUrl}p=serviceUnlock`,
          {
            agentId: Decagentid,
            userId: Decuserid,
            requestType: temp,
            serviceId: this.state.serviceid,
            serviceName: this.state.servicename,
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          
          if (res.data.code == 200 && res.data.status == "success") {
            $("#otpsentunlocksuccess").show();
            this.setState({ rtime: 90 }, this.countdown);
          } else if (res.data.code == 201 && res.data.status == "fail") {
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          // 
          this.showNotification("Something went wrong");
          $(".sendOtpLoader").hide();
          $(".sendOtpBtn").show();
        });
    } else {
      return null;
    }
  };
  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };
  renderpendingpaymentData() {
    if (this.state.pendingpaymentstate == "Latest") {
      pendingpaymentGSTR = [];
      pendingpaymentGSTRF = [];
      pendingpaymentITRF = [];
      pendingpaymentTR = [];
      pendingpaymentOA = [];
      pendingpaymentGUMASTA = [];
      pendingpaymentTradeLicence = [];
      pendingpaymentFoodLicence = [];
      if (this.state.pendingpaymentmainarray.length > 0) {
        return this.state.pendingpaymentmainarray.map((activedoc, index) => {
          if (
            this.state.pendingpaymentmainarray[index].serviceName.includes(
              "GSTR"
            ) &&
            !this.state.pendingpaymentmainarray[index].serviceName.includes(
              "GSTRF"
            )
          ) {
            pendingpaymentGSTR.push(this.state.pendingpaymentmainarray[index]);
          } else if (
            this.state.pendingpaymentmainarray[index].serviceName.includes(
              "GSTRF"
            )
          ) {
            pendingpaymentGSTRF.push(this.state.pendingpaymentmainarray[index]);
          } else if (
            this.state.pendingpaymentmainarray[index].serviceName.includes(
              "ITRF"
            )
          ) {
            pendingpaymentITRF.push(this.state.pendingpaymentmainarray[index]);
          } else if (
            this.state.pendingpaymentmainarray[index].serviceName.includes("TR")
          ) {
            pendingpaymentTR.push(this.state.pendingpaymentmainarray[index]);
          } else if (
            this.state.pendingpaymentmainarray[index].serviceName.includes("OA")
          ) {
            pendingpaymentOA.push(this.state.pendingpaymentmainarray[index]);
          } else if (
            this.state.pendingpaymentmainarray[index].serviceName.includes("GUMASTA")
          ) {
            pendingpaymentGUMASTA.push(this.state.pendingpaymentmainarray[index]);
          } else if (
            this.state.pendingpaymentmainarray[index].serviceName.includes("TL")
          ) {
            pendingpaymentTradeLicence.push(this.state.pendingpaymentmainarray[index]);
          } else if (
            this.state.pendingpaymentmainarray[index].serviceName.includes("FL")
          ) {
            pendingpaymentFoodLicence.push(this.state.pendingpaymentmainarray[index]);
          }
          return (
            <div className="col-md-6 col-12 card-gap" key={index}>
              <div className="card border-0">
                <div className="card-body py-3 px-3"onClick={() =>
                      this.handlemyserviceclick(
                        activedoc.serviceId,
                        activedoc.serviceName,
                        activedoc.plan,
                        activedoc.type
                      )
                    }>
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.userName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Started on:-&nbsp;</span>
                      {activedoc.purchasedOn}
                    </p>
                    <div className="pt-3 d-flex justify-content-between">
                      <div className="d-flex justify-content-center mr-2">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/iconmonstr-download-19.svg"
                          }
                          alt="sent"
                          className="sent-img"
                        />
                        <span className="data-title px-1">Sent:-&nbsp;</span>
                        {activedoc.documentsent}
                      </div>
                      <div className="d-flex justify-content-center">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/iconmonstr-download-18.svg"
                          }
                          alt="sent"
                          className="sent-img"
                        />
                        <span className="data-title px-1">
                          Received:-&nbsp;
                        </span>
                        {activedoc.documentreceived}
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn-sm payment-btn-download w-100 py-1 mt-1"
                    onClick={() =>
                      this.handlemyserviceclick(
                        activedoc.serviceId,
                        activedoc.serviceName,
                        activedoc.plan,
                        activedoc.type
                      )
                    }
                  >
                    Download{" "}
                    <span>
                      <img
                        src={process.env.PUBLIC_URL + "/img/download_icon.svg"}
                        alt="upgrade"
                      />
                    </span>
                  </button>
                </div>
                {activedoc.type == "lock" ? (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                    className="addServices-card-img"
                  />
                ) : (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group 852.svg"}
                    className="addServices-card-img"
                  />
                )}
              </div>
            </div>
          );
        });
      } else if (this.state.pendingpaymentmainarray.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              src={process.env.PUBLIC_URL + "/img/dontactivesevice.svg"}
              className="myservicequiteimgstart"
            />
          </center>
        );
      }
    } else if (this.state.pendingpaymentstate == "ITRF") {
      if (pendingpaymentITRF.length > 0) {
        return pendingpaymentITRF.map((activedoc, index) => {
          return (
            <div className="col-md-6 col-12 card-gap" key={index}>
              <div className="card border-0">
                <div className="card-body py-3 px-3"onClick={() =>
                      this.handlemyserviceclick(
                        activedoc.serviceId,
                        activedoc.serviceName,
                        activedoc.plan,
                        activedoc.type
                      )
                    }>
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.userName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Started on:-&nbsp;</span>
                      {activedoc.purchasedOn}
                    </p>
                    <div className="pt-3 d-flex justify-content-between">
                      <div className="d-flex justify-content-center mr-2">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/iconmonstr-download-19.svg"
                          }
                          alt="sent"
                          className="sent-img"
                        />
                        <span className="data-title px-1">Sent:-&nbsp;</span>
                        {activedoc.documentsent}
                      </div>
                      <div className="d-flex justify-content-center">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/iconmonstr-download-18.svg"
                          }
                          alt="sent"
                          className="sent-img"
                        />
                        <span className="data-title px-1">
                          Received:-&nbsp;
                        </span>
                        {activedoc.documentreceived}
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn-sm payment-btn-download w-100 py-1 mt-1"
                    onClick={() =>
                      this.handlemyserviceclick(
                        activedoc.serviceId,
                        activedoc.serviceName,
                        activedoc.plan,
                        activedoc.type
                      )
                    }
                  >
                    Download{" "}
                    <span>
                      <img
                        src={process.env.PUBLIC_URL + "/img/download_icon.svg"}
                        alt="upgrade"
                      />
                    </span>
                  </button>
                </div>
                {activedoc.type == "lock" ? (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                    className="addServices-card-img"
                  />
                ) : (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group 852.svg"}
                    className="addServices-card-img"
                  />
                )}
              </div>
            </div>
          );
        });
      } else if (pendingpaymentITRF.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    } else if (this.state.pendingpaymentstate == "GSTR") {
      if (pendingpaymentGSTR.length > 0) {
        return pendingpaymentGSTR.map((activedoc, index) => {
          return (
            <div className="col-md-6 col-12 card-gap" key={index}>
              <div className="card border-0">
                <div className="card-body py-3 px-3"onClick={() =>
                      this.handlemyserviceclick(
                        activedoc.serviceId,
                        activedoc.serviceName,
                        activedoc.plan,
                        activedoc.type
                      )
                    }>
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.userName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Started on:-&nbsp;</span>
                      {activedoc.purchasedOn}
                    </p>
                    <div className="pt-3 d-flex justify-content-between">
                      <div className="d-flex justify-content-center mr-2">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/iconmonstr-download-19.svg"
                          }
                          alt="sent"
                          className="sent-img"
                        />
                        <span className="data-title px-1">Sent:-&nbsp;</span>
                        {activedoc.documentsent}
                      </div>
                      <div className="d-flex justify-content-center">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/iconmonstr-download-18.svg"
                          }
                          alt="sent"
                          className="sent-img"
                        />
                        <span className="data-title px-1">
                          Received:-&nbsp;
                        </span>
                        {activedoc.documentreceived}
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn-sm payment-btn-download w-100 py-1 mt-1"
                    onClick={() =>
                      this.handlemyserviceclick(
                        activedoc.serviceId,
                        activedoc.serviceName,
                        activedoc.plan,
                        activedoc.type
                      )
                    }
                  >
                    Download{" "}
                    <span>
                      <img
                        src={process.env.PUBLIC_URL + "/img/download_icon.svg"}
                        alt="upgrade"
                      />
                    </span>
                  </button>
                </div>
                {activedoc.type == "lock" ? (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                    className="addServices-card-img"
                  />
                ) : (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group 852.svg"}
                    className="addServices-card-img"
                  />
                )}
              </div>
            </div>
          );
        });
      } else if (pendingpaymentGSTR.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    } else if (this.state.pendingpaymentstate == "GSTRF") {
      if (pendingpaymentGSTRF.length > 0) {
        return pendingpaymentGSTRF.map((activedoc, index) => {
          return (
            <div className="col-md-6 col-12 card-gap" key={index}>
              <div className="card border-0">
                <div className="card-body py-3 px-3"onClick={() =>
                      this.handlemyserviceclick(
                        activedoc.serviceId,
                        activedoc.serviceName,
                        activedoc.plan,
                        activedoc.type
                      )
                    }>
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.userName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Started on:-&nbsp;</span>
                      {activedoc.purchasedOn}
                    </p>
                    <div className="pt-3 d-flex justify-content-between">
                      <div className="d-flex justify-content-center mr-2">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/iconmonstr-download-19.svg"
                          }
                          alt="sent"
                          className="sent-img"
                        />
                        <span className="data-title px-1">Sent:-&nbsp;</span>
                        {activedoc.documentsent}
                      </div>
                      <div className="d-flex justify-content-center">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/iconmonstr-download-18.svg"
                          }
                          alt="sent"
                          className="sent-img"
                        />
                        <span className="data-title px-1">
                          Received:-&nbsp;
                        </span>
                        {activedoc.documentreceived}
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn-sm payment-btn-download w-100 py-1 mt-1"
                    onClick={() =>
                      this.handlemyserviceclick(
                        activedoc.serviceId,
                        activedoc.serviceName,
                        activedoc.plan,
                        activedoc.type
                      )
                    }
                  >
                    Download{" "}
                    <span>
                      <img
                        src={process.env.PUBLIC_URL + "/img/download_icon.svg"}
                        alt="upgrade"
                      />
                    </span>
                  </button>
                </div>
                {activedoc.type == "lock" ? (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                    className="addServices-card-img"
                  />
                ) : (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group 852.svg"}
                    className="addServices-card-img"
                  />
                )}
              </div>
            </div>
          );
        });
      } else if (pendingpaymentGSTRF.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    } else if (this.state.pendingpaymentstate == "TR") {
      if (pendingpaymentTR.length > 0) {
        return pendingpaymentTR.map((activedoc, index) => {
          return (
            <div className="col-md-6 col-12 card-gap" key={index}>
              <div className="card border-0">
                <div className="card-body py-3 px-3"onClick={() =>
                      this.handlemyserviceclick(
                        activedoc.serviceId,
                        activedoc.serviceName,
                        activedoc.plan,
                        activedoc.type
                      )
                    }>
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.userName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Started on:-&nbsp;</span>
                      {activedoc.purchasedOn}
                    </p>
                    <div className="pt-3 d-flex justify-content-between">
                      <div className="d-flex justify-content-center mr-2">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/iconmonstr-download-19.svg"
                          }
                          alt="sent"
                          className="sent-img"
                        />
                        <span className="data-title px-1">Sent:-&nbsp;</span>
                        {activedoc.documentsent}
                      </div>
                      <div className="d-flex justify-content-center">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/iconmonstr-download-18.svg"
                          }
                          alt="sent"
                          className="sent-img"
                        />
                        <span className="data-title px-1">
                          Received:-&nbsp;
                        </span>
                        {activedoc.documentreceived}
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn-sm payment-btn-download w-100 py-1 mt-1"
                    onClick={() =>
                      this.handlemyserviceclick(
                        activedoc.serviceId,
                        activedoc.serviceName,
                        activedoc.plan,
                        activedoc.type
                      )
                    }
                  >
                    Download{" "}
                    <span>
                      <img
                        src={process.env.PUBLIC_URL + "/img/download_icon.svg"}
                        alt="upgrade"
                      />
                    </span>
                  </button>
                </div>
                {activedoc.type == "lock" ? (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                    className="addServices-card-img"
                  />
                ) : (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group 852.svg"}
                    className="addServices-card-img"
                  />
                )}
              </div>
            </div>
          );
        });
      } else if (pendingpaymentTR.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    } else if (this.state.pendingpaymentstate == "OA") {
      if (pendingpaymentOA.length > 0) {
        return pendingpaymentOA.map((activedoc, index) => {
          return (
            <div className="col-md-6 col-12 card-gap" key={index}>
              <div className="card border-0">
                <div className="card-body py-3 px-3"onClick={() =>
                      this.handlemyserviceclick(
                        activedoc.serviceId,
                        activedoc.serviceName,
                        activedoc.plan,
                        activedoc.type
                      )
                    }>
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.userName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Started on:-&nbsp;</span>
                      {activedoc.purchasedOn}
                    </p>
                    <div className="pt-3 d-flex justify-content-between">
                      <div className="d-flex justify-content-center mr-2">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/iconmonstr-download-19.svg"
                          }
                          alt="sent"
                          className="sent-img"
                        />
                        <span className="data-title px-1">Sent:-&nbsp;</span>
                        {activedoc.documentsent}
                      </div>
                      <div className="d-flex justify-content-center">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/iconmonstr-download-18.svg"
                          }
                          alt="sent"
                          className="sent-img"
                        />
                        <span className="data-title px-1">
                          Received:-&nbsp;
                        </span>
                        {activedoc.documentreceived}
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn-sm payment-btn-download w-100 py-1 mt-1"
                    onClick={() =>
                      this.handlemyserviceclick(
                        activedoc.serviceId,
                        activedoc.serviceName,
                        activedoc.plan,
                        activedoc.type
                      )
                    }
                  >
                    Download{" "}
                    <span>
                      <img
                        src={process.env.PUBLIC_URL + "/img/download_icon.svg"}
                        alt="upgrade"
                      />
                    </span>
                  </button>
                </div>
                {activedoc.type == "lock" ? (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                    className="addServices-card-img"
                  />
                ) : (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group 852.svg"}
                    className="addServices-card-img"
                  />
                )}
              </div>
            </div>
          );
        });
      } else if (pendingpaymentOA.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    } else if (this.state.pendingpaymentstate == "GUMASTA") {
      if (pendingpaymentGUMASTA.length > 0) {
        return pendingpaymentGUMASTA.map((activedoc, index) => {
          return (
            <div className="col-md-6 col-12 card-gap" key={index}>
              <div className="card border-0">
                <div className="card-body py-3 px-3"onClick={() =>
                      this.handlemyserviceclick(
                        activedoc.serviceId,
                        activedoc.serviceName,
                        activedoc.plan,
                        activedoc.type
                      )
                    }>
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.userName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Started on:-&nbsp;</span>
                      {activedoc.purchasedOn}
                    </p>
                    <div className="pt-3 d-flex justify-content-between">
                      <div className="d-flex justify-content-center mr-2">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/iconmonstr-download-19.svg"
                          }
                          alt="sent"
                          className="sent-img"
                        />
                        <span className="data-title px-1">Sent:-&nbsp;</span>
                        {activedoc.documentsent}
                      </div>
                      <div className="d-flex justify-content-center">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/iconmonstr-download-18.svg"
                          }
                          alt="sent"
                          className="sent-img"
                        />
                        <span className="data-title px-1">
                          Received:-&nbsp;
                        </span>
                        {activedoc.documentreceived}
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn-sm payment-btn-download w-100 py-1 mt-1"
                    onClick={() =>
                      this.handlemyserviceclick(
                        activedoc.serviceId,
                        activedoc.serviceName,
                        activedoc.plan,
                        activedoc.type
                      )
                    }
                  >
                    Download{" "}
                    <span>
                      <img
                        src={process.env.PUBLIC_URL + "/img/download_icon.svg"}
                        alt="upgrade"
                      />
                    </span>
                  </button>
                </div>
                {activedoc.type == "lock" ? (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                    className="addServices-card-img"
                  />
                ) : (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group 852.svg"}
                    className="addServices-card-img"
                  />
                )}
              </div>
            </div>
          );
        });
      } else if (pendingpaymentGUMASTA.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    } else if (this.state.pendingpaymentstate == "TL") {
      if (pendingpaymentTradeLicence.length > 0) {
        return pendingpaymentTradeLicence.map((activedoc, index) => {
          return (
            <div className="col-md-6 col-12 card-gap" key={index}>
              <div className="card border-0">
                <div className="card-body py-3 px-3"onClick={() =>
                      this.handlemyserviceclick(
                        activedoc.serviceId,
                        activedoc.serviceName,
                        activedoc.plan,
                        activedoc.type
                      )
                    }>
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.userName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Started on:-&nbsp;</span>
                      {activedoc.purchasedOn}
                    </p>
                    <div className="pt-3 d-flex justify-content-between">
                      <div className="d-flex justify-content-center mr-2">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/iconmonstr-download-19.svg"
                          }
                          alt="sent"
                          className="sent-img"
                        />
                        <span className="data-title px-1">Sent:-&nbsp;</span>
                        {activedoc.documentsent}
                      </div>
                      <div className="d-flex justify-content-center">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/iconmonstr-download-18.svg"
                          }
                          alt="sent"
                          className="sent-img"
                        />
                        <span className="data-title px-1">
                          Received:-&nbsp;
                        </span>
                        {activedoc.documentreceived}
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn-sm payment-btn-download w-100 py-1 mt-1"
                    onClick={() =>
                      this.handlemyserviceclick(
                        activedoc.serviceId,
                        activedoc.serviceName,
                        activedoc.plan,
                        activedoc.type
                      )
                    }
                  >
                    Download{" "}
                    <span>
                      <img
                        src={process.env.PUBLIC_URL + "/img/download_icon.svg"}
                        alt="upgrade"
                      />
                    </span>
                  </button>
                </div>
                {activedoc.type == "lock" ? (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                    className="addServices-card-img"
                  />
                ) : (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group 852.svg"}
                    className="addServices-card-img"
                  />
                )}
              </div>
            </div>
          );
        });
      } else if (pendingpaymentTradeLicence.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    } else if (this.state.pendingpaymentstate == "FL") {
      if (pendingpaymentFoodLicence.length > 0) {
        return pendingpaymentFoodLicence.map((activedoc, index) => {
          return (
            <div className="col-md-6 col-12 card-gap" key={index}>
              <div className="card border-0">
                <div className="card-body py-3 px-3"onClick={() =>
                      this.handlemyserviceclick(
                        activedoc.serviceId,
                        activedoc.serviceName,
                        activedoc.plan,
                        activedoc.type
                      )
                    }>
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.userName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Started on:-&nbsp;</span>
                      {activedoc.purchasedOn}
                    </p>
                    <div className="pt-3 d-flex justify-content-between">
                      <div className="d-flex justify-content-center mr-2">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/iconmonstr-download-19.svg"
                          }
                          alt="sent"
                          className="sent-img"
                        />
                        <span className="data-title px-1">Sent:-&nbsp;</span>
                        {activedoc.documentsent}
                      </div>
                      <div className="d-flex justify-content-center">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/iconmonstr-download-18.svg"
                          }
                          alt="sent"
                          className="sent-img"
                        />
                        <span className="data-title px-1">
                          Received:-&nbsp;
                        </span>
                        {activedoc.documentreceived}
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn-sm payment-btn-download w-100 py-1 mt-1"
                    onClick={() =>
                      this.handlemyserviceclick(
                        activedoc.serviceId,
                        activedoc.serviceName,
                        activedoc.plan,
                        activedoc.type
                      )
                    }
                  >
                    Download{" "}
                    <span>
                      <img
                        src={process.env.PUBLIC_URL + "/img/download_icon.svg"}
                        alt="upgrade"
                      />
                    </span>
                  </button>
                </div>
                {activedoc.type == "lock" ? (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                    className="addServices-card-img"
                  />
                ) : (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group 852.svg"}
                    className="addServices-card-img"
                  />
                )}
              </div>
            </div>
          );
        });
      } else if (pendingpaymentFoodLicence.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    }
  }

  rendercompletepaymentData() {
    if (this.state.completepaymentstate == "Latest") {
      completepaymentGSTR = [];
      completepaymentGSTRF = [];
      completepaymentITRF = [];
      completepaymentTR = [];
      completepaymentOA = [];
      completepaymentGUMASTA = [];
      completepaymentTradeLicence = [];
      completepaymentFoodLicence = [];

      if (this.state.completepaymentmainarray.length > 0) {
        return this.state.completepaymentmainarray.map((activedoc, index) => {
          if (
            this.state.completepaymentmainarray[index].serviceName.includes(
              "GSTR"
            ) &&
            !this.state.completepaymentmainarray[index].serviceName.includes(
              "GSTRF"
            )
          ) {
            completepaymentGSTR.push(
              this.state.completepaymentmainarray[index]
            );
          } else if (
            this.state.completepaymentmainarray[index].serviceName.includes(
              "GSTRF"
            )
          ) {
            completepaymentGSTRF.push(
              this.state.completepaymentmainarray[index]
            );
          } else if (
            this.state.completepaymentmainarray[index].serviceName.includes(
              "ITRF"
            )
          ) {
            completepaymentITRF.push(
              this.state.completepaymentmainarray[index]
            );
          } else if (
            this.state.completepaymentmainarray[index].serviceName.includes(
              "TR"
            )
          ) {
            completepaymentTR.push(this.state.completepaymentmainarray[index]);
          } else if (
            this.state.completepaymentmainarray[index].serviceName.includes(
              "OA"
            )
          ) {
            completepaymentOA.push(this.state.completepaymentmainarray[index]);
          } else if (
            this.state.completepaymentmainarray[index].serviceName.includes(
              "GUMASTA"
            )
          ) {
            completepaymentGUMASTA.push(this.state.completepaymentmainarray[index]);
          } else if (
            this.state.completepaymentmainarray[index].serviceName.includes(
              "TL"
            )
          ) {
            completepaymentTradeLicence.push(this.state.completepaymentmainarray[index]);
          } else if (
            this.state.completepaymentmainarray[index].serviceName.includes(
              "FL"
            )
          ) {
            completepaymentFoodLicence.push(this.state.completepaymentmainarray[index]);
          }
          return (
            <div className="col-md-6 col-12 card-gap" key={index}>
              <div className="card border-0">
                <div className="card-body py-3 px-3"onClick={() =>
                      this.handlemyserviceclick(
                        activedoc.serviceId,
                        activedoc.serviceName,
                        activedoc.plan,
                        activedoc.type
                      )
                    }>
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.userName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Closed on:-&nbsp;</span>
                      {activedoc.completedOn}
                    </p>
                    <div className="pt-3 d-flex justify-content-between">
                      <div className="d-flex justify-content-center mr-2">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/iconmonstr-download-19.svg"
                          }
                          alt="sent"
                          className="sent-img"
                        />
                        <span className="data-title px-1">Sent:-&nbsp;</span>
                        {activedoc.documentsent}
                      </div>
                      <div className="d-flex justify-content-center">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/iconmonstr-download-18.svg"
                          }
                          alt="sent"
                          className="sent-img"
                        />
                        <span className="data-title px-1">
                          Received:-&nbsp;
                        </span>
                        {activedoc.documentreceived}
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn-sm payment-btn-download w-100 py-1 mt-1"
                    onClick={() =>
                      this.handlemyserviceclick(
                        activedoc.serviceId,
                        activedoc.serviceName,
                        activedoc.plan,
                        activedoc.type
                      )
                    }
                  >
                    Download{" "}
                    <span>
                      <img
                        src={process.env.PUBLIC_URL + "/img/download_icon.svg"}
                        alt="upgrade"
                      />
                    </span>
                  </button>
                </div>
                {activedoc.type == "lock" ? (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                    className="addServices-card-img"
                    
                  />
                ) : (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group 2521.svg"}
                    className="addServices-card-img"
                  />
                )}
              </div>
            </div>
          );
        });
      } else if (this.state.completepaymentmainarray.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimgstart"
            />
          </center>
        );
      }
    } else if (this.state.completepaymentstate == "GSTR") {
      if (completepaymentGSTR.length > 0) {
        return completepaymentGSTR.map((activedoc, index) => {
          return (
            <div className="col-md-6 col-12 card-gap" key={index}>
              <div className="card border-0">
                <div className="card-body py-3 px-3"onClick={() =>
                      this.handlemyserviceclick(
                        activedoc.serviceId,
                        activedoc.serviceName,
                        activedoc.plan,
                        activedoc.type
                      )
                    }>
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.userName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Closed on:-&nbsp;</span>
                      {activedoc.completedOn}
                    </p>
                    <div className="pt-3 d-flex justify-content-between">
                      <div className="d-flex justify-content-center mr-2">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/iconmonstr-download-19.svg"
                          }
                          alt="sent"
                          className="sent-img"
                        />
                        <span className="data-title px-1">Sent:-&nbsp;</span>
                        {activedoc.documentsent}
                      </div>
                      <div className="d-flex justify-content-center">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/iconmonstr-download-18.svg"
                          }
                          alt="sent"
                          className="sent-img"
                        />
                        <span className="data-title px-1">
                          Received:-&nbsp;
                        </span>
                        {activedoc.documentreceived}
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn-sm payment-btn-download w-100 py-1 mt-1"
                    onClick={() =>
                      this.handlemyserviceclick(
                        activedoc.serviceId,
                        activedoc.serviceName,
                        activedoc.plan,
                        activedoc.type
                      )
                    }
                  >
                    Download{" "}
                    <span>
                      <img
                        src={process.env.PUBLIC_URL + "/img/download_icon.svg"}
                        alt="upgrade"
                      />
                    </span>
                  </button>
                </div>
                {activedoc.type == "lock" ? (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                    className="addServices-card-img"
                  />
                ) : (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group 2521.svg"}
                    className="addServices-card-img"
                  />
                )}
              </div>
            </div>
          );
        });
      } else if (completepaymentGSTR.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    } else if (this.state.completepaymentstate == "GSTRF") {
      if (completepaymentGSTRF.length > 0) {
        return completepaymentGSTRF.map((activedoc, index) => {
          return (
            <div className="col-md-6 col-12 card-gap" key={index}>
              <div className="card border-0">
                <div className="card-body py-3 px-3"onClick={() =>
                      this.handlemyserviceclick(
                        activedoc.serviceId,
                        activedoc.serviceName,
                        activedoc.plan,
                        activedoc.type
                      )
                    }>
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.userName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Closed on:-&nbsp;</span>
                      {activedoc.completedOn}
                    </p>
                    <div className="pt-3 d-flex justify-content-between">
                      <div className="d-flex justify-content-center mr-2">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/iconmonstr-download-19.svg"
                          }
                          alt="sent"
                          className="sent-img"
                        />
                        <span className="data-title px-1">Sent:-&nbsp;</span>
                        {activedoc.documentsent}
                      </div>
                      <div className="d-flex justify-content-center">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/iconmonstr-download-18.svg"
                          }
                          alt="sent"
                          className="sent-img"
                        />
                        <span className="data-title px-1">
                          Received:-&nbsp;
                        </span>
                        {activedoc.documentreceived}
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn-sm payment-btn-download w-100 py-1 mt-1"
                    onClick={() =>
                      this.handlemyserviceclick(
                        activedoc.serviceId,
                        activedoc.serviceName,
                        activedoc.plan,
                        activedoc.type
                      )
                    }
                  >
                    Download{" "}
                    <span>
                      <img
                        src={process.env.PUBLIC_URL + "/img/download_icon.svg"}
                        alt="upgrade"
                      />
                    </span>
                  </button>
                </div>
                {activedoc.type == "lock" ? (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                    className="addServices-card-img"
                  />
                ) : (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group 2521.svg"}
                    className="addServices-card-img"
                  />
                )}
              </div>
            </div>
          );
        });
      } else if (completepaymentGSTRF.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    } else if (this.state.completepaymentstate == "ITRF") {
      if (completepaymentITRF.length > 0) {
        return completepaymentITRF.map((activedoc, index) => {
          return (
            <div className="col-md-6 col-12 card-gap" key={index}>
              <div className="card border-0">
                <div className="card-body py-3 px-3"onClick={() =>
                      this.handlemyserviceclick(
                        activedoc.serviceId,
                        activedoc.serviceName,
                        activedoc.plan,
                        activedoc.type
                      )
                    }>
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.userName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Closed on:-&nbsp;</span>
                      {activedoc.completedOn}
                    </p>
                    <div className="pt-3 d-flex justify-content-between">
                      <div className="d-flex justify-content-center mr-2">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/iconmonstr-download-19.svg"
                          }
                          alt="sent"
                          className="sent-img"
                        />
                        <span className="data-title px-1">Sent:-&nbsp;</span>
                        {activedoc.documentsent}
                      </div>
                      <div className="d-flex justify-content-center">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/iconmonstr-download-18.svg"
                          }
                          alt="sent"
                          className="sent-img"
                        />
                        <span className="data-title px-1">
                          Received:-&nbsp;
                        </span>
                        {activedoc.documentreceived}
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn-sm payment-btn-download w-100 py-1 mt-1"
                    onClick={() =>
                      this.handlemyserviceclick(
                        activedoc.serviceId,
                        activedoc.serviceName,
                        activedoc.plan,
                        activedoc.type
                      )
                    }
                  >
                    Download{" "}
                    <span>
                      <img
                        src={process.env.PUBLIC_URL + "/img/download_icon.svg"}
                        alt="upgrade"
                      />
                    </span>
                  </button>
                </div>
                {activedoc.type == "lock" ? (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                    className="addServices-card-img"
                  />
                ) : (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group 2521.svg"}
                    className="addServices-card-img"
                  />
                )}
              </div>
            </div>
          );
        });
      } else if (completepaymentITRF.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    } else if (this.state.completepaymentstate == "TR") {
      if (completepaymentTR.length > 0) {
        return completepaymentTR.map((activedoc, index) => {
          return (
            <div className="col-md-6 col-12 card-gap" key={index}>
              <div className="card border-0">
                <div className="card-body py-3 px-3"onClick={() =>
                      this.handlemyserviceclick(
                        activedoc.serviceId,
                        activedoc.serviceName,
                        activedoc.plan,
                        activedoc.type
                      )
                    }>
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.userName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Closed on:-&nbsp;</span>
                      {activedoc.completedOn}
                    </p>
                    <div className="pt-3 d-flex justify-content-between">
                      <div className="d-flex justify-content-center mr-2">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/iconmonstr-download-19.svg"
                          }
                          alt="sent"
                          className="sent-img"
                        />
                        <span className="data-title px-1">Sent:-&nbsp;</span>
                        {activedoc.documentsent}
                      </div>
                      <div className="d-flex justify-content-center">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/iconmonstr-download-18.svg"
                          }
                          alt="sent"
                          className="sent-img"
                        />
                        <span className="data-title px-1">
                          Received:-&nbsp;
                        </span>
                        {activedoc.documentreceived}
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn-sm payment-btn-download w-100 py-1 mt-1"
                    onClick={() =>
                      this.handlemyserviceclick(
                        activedoc.serviceId,
                        activedoc.serviceName,
                        activedoc.plan,
                        activedoc.type
                      )
                    }
                  >
                    Download{" "}
                    <span>
                      <img
                        src={process.env.PUBLIC_URL + "/img/download_icon.svg"}
                        alt="upgrade"
                      />
                    </span>
                  </button>
                </div>
                {activedoc.type == "lock" ? (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                    className="addServices-card-img"
                  />
                ) : (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group 2521.svg"}
                    className="addServices-card-img"
                  />
                )}
              </div>
            </div>
          );
        });
      } else if (completepaymentTR.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    } else if (this.state.completepaymentstate == "OA") {
      if (completepaymentOA.length > 0) {
        return completepaymentOA.map((activedoc, index) => {
          return (
            <div className="col-md-6 col-12 card-gap" key={index}>
              <div className="card border-0">
                <div className="card-body py-3 px-3"onClick={() =>
                      this.handlemyserviceclick(
                        activedoc.serviceId,
                        activedoc.serviceName,
                        activedoc.plan,
                        activedoc.type
                      )
                    }>
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.userName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Closed on:-&nbsp;</span>
                      {activedoc.completedOn}
                    </p>
                    <div className="pt-3 d-flex justify-content-between">
                      <div className="d-flex justify-content-center mr-2">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/iconmonstr-download-19.svg"
                          }
                          alt="sent"
                          className="sent-img"
                        />
                        <span className="data-title px-1">Sent:-&nbsp;</span>
                        {activedoc.documentsent}
                      </div>
                      <div className="d-flex justify-content-center">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/iconmonstr-download-18.svg"
                          }
                          alt="sent"
                          className="sent-img"
                        />
                        <span className="data-title px-1">
                          Received:-&nbsp;
                        </span>
                        {activedoc.documentreceived}
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn-sm payment-btn-download w-100 py-1 mt-1"
                    onClick={() =>
                      this.handlemyserviceclick(
                        activedoc.serviceId,
                        activedoc.serviceName,
                        activedoc.plan,
                        activedoc.type
                      )
                    }
                  >
                    Download{" "}
                    <span>
                      <img
                        src={process.env.PUBLIC_URL + "/img/download_icon.svg"}
                        alt="upgrade"
                      />
                    </span>
                  </button>
                </div>
                {activedoc.type == "lock" ? (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                    className="addServices-card-img"
                  />
                ) : (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group 2521.svg"}
                    className="addServices-card-img"
                  />
                )}
              </div>
            </div>
          );
        });
      } else if (completepaymentOA.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    } else if (this.state.completepaymentstate == "GUMASTA") {
      if (completepaymentGUMASTA.length > 0) {
        return completepaymentGUMASTA.map((activedoc, index) => {
          return (
            <div className="col-md-6 col-12 card-gap" key={index}>
              <div className="card border-0">
                <div className="card-body py-3 px-3"onClick={() =>
                      this.handlemyserviceclick(
                        activedoc.serviceId,
                        activedoc.serviceName,
                        activedoc.plan,
                        activedoc.type
                      )
                    }>
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.userName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Closed on:-&nbsp;</span>
                      {activedoc.completedOn}
                    </p>
                    <div className="pt-3 d-flex justify-content-between">
                      <div className="d-flex justify-content-center mr-2">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/iconmonstr-download-19.svg"
                          }
                          alt="sent"
                          className="sent-img"
                        />
                        <span className="data-title px-1">Sent:-&nbsp;</span>
                        {activedoc.documentsent}
                      </div>
                      <div className="d-flex justify-content-center">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/iconmonstr-download-18.svg"
                          }
                          alt="sent"
                          className="sent-img"
                        />
                        <span className="data-title px-1">
                          Received:-&nbsp;
                        </span>
                        {activedoc.documentreceived}
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn-sm payment-btn-download w-100 py-1 mt-1"
                    onClick={() =>
                      this.handlemyserviceclick(
                        activedoc.serviceId,
                        activedoc.serviceName,
                        activedoc.plan,
                        activedoc.type
                      )
                    }
                  >
                    Download{" "}
                    <span>
                      <img
                        src={process.env.PUBLIC_URL + "/img/download_icon.svg"}
                        alt="upgrade"
                      />
                    </span>
                  </button>
                </div>
                {activedoc.type == "lock" ? (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                    className="addServices-card-img"
                  />
                ) : (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group 2521.svg"}
                    className="addServices-card-img"
                  />
                )}
              </div>
            </div>
          );
        });
      } else if (completepaymentGUMASTA.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    } else if (this.state.completepaymentstate == "TL") {
      if (completepaymentTradeLicence.length > 0) {
        return completepaymentTradeLicence.map((activedoc, index) => {
          return (
            <div className="col-md-6 col-12 card-gap" key={index}>
              <div className="card border-0">
                <div className="card-body py-3 px-3"onClick={() =>
                      this.handlemyserviceclick(
                        activedoc.serviceId,
                        activedoc.serviceName,
                        activedoc.plan,
                        activedoc.type
                      )
                    }>
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.userName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Closed on:-&nbsp;</span>
                      {activedoc.completedOn}
                    </p>
                    <div className="pt-3 d-flex justify-content-between">
                      <div className="d-flex justify-content-center mr-2">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/iconmonstr-download-19.svg"
                          }
                          alt="sent"
                          className="sent-img"
                        />
                        <span className="data-title px-1">Sent:-&nbsp;</span>
                        {activedoc.documentsent}
                      </div>
                      <div className="d-flex justify-content-center">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/iconmonstr-download-18.svg"
                          }
                          alt="sent"
                          className="sent-img"
                        />
                        <span className="data-title px-1">
                          Received:-&nbsp;
                        </span>
                        {activedoc.documentreceived}
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn-sm payment-btn-download w-100 py-1 mt-1"
                    onClick={() =>
                      this.handlemyserviceclick(
                        activedoc.serviceId,
                        activedoc.serviceName,
                        activedoc.plan,
                        activedoc.type
                      )
                    }
                  >
                    Download{" "}
                    <span>
                      <img
                        src={process.env.PUBLIC_URL + "/img/download_icon.svg"}
                        alt="upgrade"
                      />
                    </span>
                  </button>
                </div>
                {activedoc.type == "lock" ? (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                    className="addServices-card-img"
                  />
                ) : (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group 2521.svg"}
                    className="addServices-card-img"
                  />
                )}
              </div>
            </div>
          );
        });
      } else if (completepaymentTradeLicence.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    } else if (this.state.completepaymentstate == "FL") {
      if (completepaymentFoodLicence.length > 0) {
        return completepaymentFoodLicence.map((activedoc, index) => {
          return (
            <div className="col-md-6 col-12 card-gap" key={index}>
              <div className="card border-0">
                <div className="card-body py-3 px-3"onClick={() =>
                      this.handlemyserviceclick(
                        activedoc.serviceId,
                        activedoc.serviceName,
                        activedoc.plan,
                        activedoc.type
                      )
                    }>
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.userName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Closed on:-&nbsp;</span>
                      {activedoc.completedOn}
                    </p>
                    <div className="pt-3 d-flex justify-content-between">
                      <div className="d-flex justify-content-center mr-2">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/iconmonstr-download-19.svg"
                          }
                          alt="sent"
                          className="sent-img"
                        />
                        <span className="data-title px-1">Sent:-&nbsp;</span>
                        {activedoc.documentsent}
                      </div>
                      <div className="d-flex justify-content-center">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/iconmonstr-download-18.svg"
                          }
                          alt="sent"
                          className="sent-img"
                        />
                        <span className="data-title px-1">
                          Received:-&nbsp;
                        </span>
                        {activedoc.documentreceived}
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn-sm payment-btn-download w-100 py-1 mt-1"
                    onClick={() =>
                      this.handlemyserviceclick(
                        activedoc.serviceId,
                        activedoc.serviceName,
                        activedoc.plan,
                        activedoc.type
                      )
                    }
                  >
                    Download{" "}
                    <span>
                      <img
                        src={process.env.PUBLIC_URL + "/img/download_icon.svg"}
                        alt="upgrade"
                      />
                    </span>
                  </button>
                </div>
                {activedoc.type == "lock" ? (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                    className="addServices-card-img"
                  />
                ) : (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group 2521.svg"}
                    className="addServices-card-img"
                  />
                )}
              </div>
            </div>
          );
        });
      } else if (completepaymentFoodLicence.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    }
  }
  getservicefullname(name) {
    var fullname=  AllServices[name]
    return <>{fullname}</>;
   }
  render() {
    if (
      localStorage.getItem("gglltakoinoeenl") ==
      process.env.REACT_APP_LOGIN_KEY &&
      localStorage.getItem("toeljgtkewlna") != null &&
      localStorage.getItem("qazxswedcvfrtgb") != null
    ) {
      $("#resendUnlockdocOTP").addClass("text-muted");
      if (this.state.rtime == 0) {
        $("#resendUnlockdocOTP").removeClass("text-muted");
      }
      return (
        <div className="cont">
          <div id="notifContainer"></div>
          <Helmet>
            <link
              rel="stylesheet"
              href={process.env.PUBLIC_URL + "/css/select2.css"}
            />
            <link rel="stylesheet" href="css/services.css" />
          </Helmet>

          <Header />

          <img
            src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
            className="sidebaropenicon"
          />
          <img
            src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
            className="sidebarbackicon"
          />
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"}
            className="sidebarexpandicon"
          />

          <div className="overlay1"></div>
          <Sidebar/>
          <div className="arrow-left5"></div>
          <div className="white_div">
            <div className="white_box">
              <div className="row main-row">
              <div className="myservicesortbyfilterbystatus ml-1">
                  
                  </div>
                {/* <!-- active services --> */}
                <div className="col-xl-6 col-12">
                  {/* <!-- header --> */}
                  <div className="inner-header d-flex justify-content-between px-2">
                    {/* <!-- search --> */}
                    <div className="pb-md-0 pb-2">
                      <img
                        alt="loading..."
                        src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
                        className="backicon__btn"
                        onClick={this.backbutton}
                      />
                    </div>

                    {/* <!-- nav-tabs --> */}
                    <div className="payment-nav-tab">
                      <header className="position-relative">
                        <ul className="payment-nav-tab-ul">
                          <li className="payment-nav-tab-li">
                            Active Services
                          </li>
                        </ul>
                      </header>
                    </div>

                    {/* <!-- dropdowns -->              */}
                    <div className="dropdown ml-auto">
                      <button
                        className="btn header-btn py-1 px-2"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 210.svg"}
                          alt=""
                        />{" "}
                        <span>Filter by</span>
                      </button>
                      <div
                        className="dropdown-menu drop mar-l-05"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a
                          className="dropdown-item cursorpointer"
                          onClick={this.pendingpaymentGSTRfilter}
                        >
                          GSTR
                        </a>
                        <a
                          className="dropdown-item cursorpointer"
                          onClick={this.pendingpaymentGSTRFfilter}
                        >
                          GSTRF
                        </a>
                        <a
                          className="dropdown-item cursorpointer"
                          onClick={this.pendingpaymentITRFfilter}
                        >
                          ITRF
                        </a>
                        <a
                          className="dropdown-item cursorpointer"
                          onClick={this.pendingpaymentTRfilter}
                        >
                          TR
                        </a>
                        <a
                          className="dropdown-item cursorpointer"
                          onClick={this.pendingpaymentOAfilter}
                        >
                          OA
                        </a>
                        <a
                          className="dropdown-item cursorpointer"
                          onClick={this.pendingpaymentGUMASTAfilter}
                        >
                          GUMASTA
                        </a>
                        <a
                          className="dropdown-item cursorpointer"
                          onClick={this.pendingpaymentTradeLicencefilter}
                        >
                          Trade Licence
                        </a>
                        <a
                          className="dropdown-item cursorpointer"
                          onClick={this.pendingpaymentFoodLicencefilter}
                        >
                          Food Licence
                        </a>
                      </div>
                    </div>
                  </div>

                  {/* <!-- page-content --> */}
                  <div className="tabs-content py-md-3 pt-5 pb-2 mt-md-0 mt-2">
                    <div className="myservicescrolldiv">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="myseriveloaderdiv">
                            <div className="myseriveloaderinsidediv2">
                              <center>
                                <img
                                  src={
                                    process.env.PUBLIC_URL + "/img/loader.gif"
                                  }
                                  className="myseriveloader"
                                />
                              </center>
                            </div>
                          </div>
                          {this.renderpendingpaymentData()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- completed services -->           */}
                <div className="col-xl-6 col-12 partition-border">
                  {/* <!-- header --> */}
                  <div className="inner-header d-flex flex-md-row justify-content-between px-2">
                    {/* <!-- search --> */}
                    <div className="pb-md-0 pb-2">
                      {/* <form>
                        <input
                          type="text"
                          className="search-box"
                          id="search-box-2"
                          placeholder="Search"
                          onClick={this.handleSearchBox2}
                        />
                      </form> */}
                    </div>

                    {/* <!-- nav-tabs --> */}
                    <div className="payment-nav-tab">
                      <header className="position-relative">
                        <ul className="payment-nav-tab-ul">
                          <li className="payment-nav-tab-li">
                            Completed Services
                          </li>
                        </ul>
                      </header>
                    </div>

                    {/* <!-- dropdowns -->              */}
                    <div className="dropdown ml-auto">
                      <button
                        className="btn header-btn py-1 px-2"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 210.svg"}
                          alt=""
                        />{" "}
                        <span>Filter by</span>
                      </button>
                      <div
                        className="dropdown-menu drop mar-l-05"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a
                          className="dropdown-item cursorpointer"
                          onClick={this.completepaymentGSTRfilter}
                        >
                          GSTR
                        </a>
                        <a
                          className="dropdown-item cursorpointer"
                          onClick={this.completepaymentGSTRFfilter}
                        >
                          GSTRF
                        </a>
                        <a
                          className="dropdown-item cursorpointer"
                          onClick={this.completepaymentITRFfilter}
                        >
                          ITRF
                        </a>
                        <a
                          className="dropdown-item cursorpointer"
                          onClick={this.completepaymentTRfilter}
                        >
                          TR
                        </a>
                        <a
                          className="dropdown-item cursorpointer"
                          onClick={this.completepaymentOAfilter}
                        >
                          OA
                        </a>
                        <a
                          className="dropdown-item cursorpointer"
                          onClick={this.completepaymentGUMASTAfilter}
                        >
                          GUMASTA
                        </a>
                        <a
                          className="dropdown-item cursorpointer"
                          onClick={this.completepaymentTradeLicencefilter}
                        >
                          Trade Licence
                        </a>
                        <a
                          className="dropdown-item cursorpointer"
                          onClick={this.completepaymentFoodLicencefilter}
                        >
                          Food Licence
                        </a>
                      </div>
                    </div>
                  </div>

                  {/* <!-- page-content --> */}
                  <div className="tabs-content py-md-3 pt-5 pb-2 mt-md-0 mt-2">
                    <div className="myservicescrolldiv">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="myseriveloaderdiv">
                            <div className="myseriveloaderinsidediv2">
                              <center>
                                <img
                                  src={
                                    process.env.PUBLIC_URL + "/img/loader.gif"
                                  }
                                  className="myseriveloader"
                                />
                              </center>
                            </div>
                          </div>
                          {this.rendercompletepaymentData()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
          <div
            className="modal fade"
            id="Unlock-Service"
            tabIndex="-1"
            role="dialog"
            data-backdrop="static"
            data-keyboard="false"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered "
              role="document"
            >
              <div className="modal-content back-modal " id="unlockotpmodal">
                <div className="w-100 d-flex">
                  <button
                    type="button"
                    className="border-0 bg-transparent ml-auto p-2 pr-3  text-right my-back-modal-close"
                    data-dismiss="modal"
                    onClick={() => {this.setState({ rtime: 1 });
                    $("#invalidunlockmobileotp").hide();
                    $("#invalidunlockemailotp").hide();
                    $("#otpsentunlocksuccess").hide();}}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/img/Page-1.svg"}
                      width="17px"
                    />
                  </button>
                </div>
                <div className="text-center">
                  <h4 className="my-linkmodal-text enterotp">Enter OTP</h4>
                </div>
                <div className="input-userinfo otpinput emailotp">
                  <h5 className="otpinput-head">
                    Enter OTP sent to user's mobile
                  </h5>
                  <input
                    className="Userinfo-input otp-input"
                    maxLength="6"
                    type="tel"
                    value={this.state.mobileotp}
                    onChange={(e) =>
                      this.setState({ mobileotp: e.target.value })
                    }
                    id="unlockmobileinput"
                    placeholder="Enter OTP here..."
                  />
                  <p
                    className=" mt-2 warn align-items-center m-0"
                    id="invalidunlockmobileotp"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/Icon_feather-alert-circle.svg"
                      }
                      width="15px"
                      className=" mr-1"
                    />{" "}
                    <span className="invalidmobile"></span>
                  </p>
                </div>
                <div className="input-userinfo otpinput emailotp">
                  <h5 className="otpinput-head">
                    Enter OTP sent to user's email
                  </h5>
                  <input
                    className="Userinfo-input otp-input "
                    maxLength="6"
                    type="tel"
                    value={this.state.emailotp}
                    onChange={(e) =>
                      this.setState({ emailotp: e.target.value })
                    }
                    id="unlockemailinput"
                    placeholder="Enter OTP here..."
                  />
                  <p
                    className=" mt-2 warn align-items-center m-0"
                    id="invalidunlockemailotp"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/Icon_feather-alert-circle.svg"
                      }
                      width="15px"
                      className=" mr-1"
                    />{" "}
                    <span className="invalidemail"></span>
                  </p>
                </div>

                <div className="d-flex justify-content-end  otpmodaloptions">
                  {/* <h6 onClick={this.changeuserinfo}>Change mobile/email</h6>{" "} */}
                  <h6
                    className="text-muted"
                    id="resendUnlockdocOTP"
                    onClick={this.resenduserOtp}
                  >
                    Resend OTP{" "}
                    {this.state.rtime != 0 ? `(${this.state.rtime} Sec)` : " "}
                  </h6>
                </div>
                <p className="mb-0" id="otpsentunlocksuccess">
                  OTP sent successfully{" "}
                </p>
                <div className="modal-footer border-0 justify-content-center mb-4">
                  <img
                    className=" p-2 pl-3 pr-3 sendUnlockdocOtpLoader"
                    src="/img/loader.gif"
                    width="80px"
                    alt=""
                  />
                  <button
                    type="button"
                    onClick={this.SubmitOTP}
                    className="btn  send-btn text-white"
                    id="submitunlockdocOtpbtn"
                  >
                    Submit
                  </button>
                </div>
              </div>

              <div className="modal-content back-modal " id="unlockotp">
                <div className="w-100 d-flex">
                  <button
                    type="button"
                    className="border-0 bg-transparent ml-auto p-2 pr-3  text-right my-back-modal-close"
                    data-dismiss="modal"
                    onClick={() => this.setState({ rtime: 1 })}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/img/Page-1.svg"}
                      width="17px"
                    />
                  </button>
                </div>
                <div className="modal-body border-0 text-center pb-0">
                  <h4 className="my-warn-text unlock-text">
                    Send OTP to unlock user's information!
                  </h4>
                  <img
                    className="p-2"
                    src={process.env.PUBLIC_URL + "/img/Group 5572.svg"}
                    width="120px"
                  />
                  <p className="my-success-para m-0 contotp">
                    Continue to send OTP ?
                  </p>
                </div>
                <div className="modal-footer border-0 justify-content-center mb-4">
                  <img
                    className=" p-2 pl-3 pr-3 sendUnlockdocOtpLoader"
                    src="/img/loader.gif"
                    width="80px"
                    alt=""
                  />
                  <button
                    type="button"
                    onClick={() =>
                      this.serviceunlock(
                        this.state.serviceid,
                        this.state.servicename
                      )
                    }
                    className="btn  send-btn text-white  "
                    id="sendUnlockdocOtp"
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
     
      return <Redirect to="/" />;
    }
  }
}

export default DocumentsRecords;

import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $, { isNumeric } from "jquery";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import SupportDetails from "./SupportDetails";
import SupportDetailsMobile from "./SupportDetailsMobile";
import JSZipUtils from "jszip-utils";
import JSZip from "jszip";
import { saveAs } from "file-saver";
var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);
let Decserviceid;
let Decservicename;
let Decplan;
let Decagentid;
let Decuserid;
let Decjwttoken;
let mainaction = "upload";
let temp = "general";
let HideProceedBtn = "No";
let ChooseNowBtnsDisable = "No";
let supportName;
let supportContact;
let supportEmail;

// Sole P/I
let mainactionSAadhar = "upload";
let SAadharStatus;
let mainactionSPan = "upload";
let SPanStatus;
let mainactionSPic = "upload";
let SPicStatus;
let mainactionSBP = "upload";
let SBPStatus;
let mainactionSGumasta = "upload";
let SGumastaStatus;
let mainactionSTradeLicence = "upload";
let STradeLicenceStatus;
let mainactionSCopyOfRegistry = "upload";
let SCopyOfRegistryStatus;
let mainactionSRentAgreement = "upload";
let SRentAgreementStatus;
let mainactionSPR = "upload";
let SPRStatus;
let SStatusArray = [
  {
    doc: "SAadharStatus",
    status: SAadharStatus,
  },
  {
    doc: "SPanStatus",
    status: SPanStatus,
  },
  {
    doc: "SPicStatus",
    status: SPicStatus,
  },
  {
    doc: "SBPStatus",
    status: SBPStatus,
  },
  {
    doc: "SGumastaStatus",
    status: SGumastaStatus,
  },
  {
    doc: "STradeLicenceStatus",
    status: STradeLicenceStatus,
  },
  {
    doc: "SPRStatus",
    status: SPRStatus,
  },
];
let SOptStatusArray = [
  {
    doc: "SCopyOfRegistryStatus",
    status: SCopyOfRegistryStatus,
  },
  {
    doc: "SRentAgreementStatus",
    status: SRentAgreementStatus,
  },
];
let SadditionalReupload = false;
let SadditionalFilesIndex;
let SadditionalReuploadIndex;
let SadditionalReuploadStatus;
let SadditionalFilesNames = [];
let Sproceednowforword;
let AnyReuploadOccuredS = "No";

// Partnership
let mainactionPAadhar1 = "upload";
let PAadhar1Status;
let mainactionPAadhar2 = "upload";
let PAadhar2Status;
let mainactionPPan1 = "upload";
let PPan1Status;
let mainactionPPan2 = "upload";
let PPan2Status;
let mainactionPPic1 = "upload";
let PPic1Status;
let mainactionPPic2 = "upload";
let PPic2Status;
let mainactionPBP = "upload";
let PBPStatus;
let mainactionPGumasta = "upload";
let PGumastaStatus;
let mainactionPTradeLicence = "upload";
let PTradeLicenceStatus;
let mainactionPDeed = "upload";
let PDeedStatus;
let mainactionPCopyOfRegistry = "upload";
let PCopyOfRegistryStatus;
let mainactionPRentAgreement = "upload";
let PRentAgreementStatus;
let mainactionPPR = "upload";
let PPRStatus;

let PStatusArray = [
  {
    doc: "PAadhar1Status",
    status: PAadhar1Status,
  },
  {
    doc: "PAadhar2Status",
    status: PAadhar2Status,
  },
  {
    doc: "PPan1Status",
    status: PPan1Status,
  },
  {
    doc: "PPan2Status",
    status: PPan2Status,
  },
  {
    doc: "PPic1Status",
    status: PPic1Status,
  },
  {
    doc: "PPic2Status",
    status: PPic2Status,
  },
  {
    doc: "PBPStatus",
    status: PBPStatus,
  },
  {
    doc: "PGumastaStatus",
    status: PGumastaStatus,
  },
  {
    doc: "PTradeLicenceStatus",
    status: PTradeLicenceStatus,
  },
  {
    doc: "PPRStatus",
    status: PPRStatus,
  },
  {
    doc: "PDeedStatus",
    status: PDeedStatus,
  },
];
let POptStatusArray = [
  {
    doc: "PCopyOfRegistryStatus",
    status: PCopyOfRegistryStatus,
  },
  {
    doc: "PRentAgreementStatus",
    status: PRentAgreementStatus,
  },
];
let PadditionalReupload = false;
let PadditionalFilesIndex;
let PadditionalReuploadIndex;
let PadditionalReuploadStatus;
let PadditionalFilesNames = [];
let Pproceednowforword;
let AnyReuploadOccuredP = "No";

// Private Limited
let mainactionPLAadhar1 = "upload";
let PLAadhar1Status;
let mainactionPLAadhar2 = "upload";
let PLAadhar2Status;
let mainactionPLPan1 = "upload";
let PLPan1Status;
let mainactionPLPan2 = "upload";
let PLPan2Status;
let mainactionPLPic1 = "upload";
let PLPic1Status;
let mainactionPLPic2 = "upload";
let PLPic2Status;
let mainactionPLPan3 = "upload";
let PLPan3Status;
let mainactionPLCI = "upload";
let PLCIStatus;
let mainactionPLMOA = "upload";
let PLMOAStatus;
let mainactionPLAOA = "upload";
let PLAOAStatus;
let mainactionPLBR = "upload";
let PLBRStatus;
let mainactionPLBP = "upload";
let PLBPStatus;
let mainactionPLGumasta = "upload";
let PLGumastaStatus;
let mainactionPLTradeLicence = "upload";
let PLTradeLicenceStatus;
let mainactionPLCopyOfRegistry = "upload";
let PLCopyOfRegistryStatus;
let mainactionPLRentAgreement = "upload";
let PLRentAgreementStatus;
let mainactionPLPR = "upload";
let PLPRStatus;
let PLStatusArray = [
  {
    doc: "PAadhar1Status",
    status: PAadhar1Status,
  },
  {
    doc: "PLAadhar2Status",
    status: PLAadhar2Status,
  },
  {
    doc: "PLPan1Status",
    status: PLPan1Status,
  },
  {
    doc: "PLPan2Status",
    status: PLPan2Status,
  },
  {
    doc: "PLPic1Status",
    status: PLPic1Status,
  },
  {
    doc: "PLPic2Status",
    status: PLPic2Status,
  },
  {
    doc: "PLPan3Status",
    status: PLPan3Status,
  },
  {
    doc: "PLCIStatus",
    status: PLCIStatus,
  },
  {
    doc: "PLMOAStatus",
    status: PLMOAStatus,
  },
  {
    doc: "PLAOAStatus",
    status: PLAOAStatus,
  },
  {
    doc: "PLBRStatus",
    status: PLBRStatus,
  },
  {
    doc: "PLBPStatus",
    status: PLBPStatus,
  },
  {
    doc: "PLGumastaStatus",
    status: PLGumastaStatus,
  },
  {
    doc: "PLTradeLicenceStatus",
    status: PLTradeLicenceStatus,
  },
  {
    doc: "PLPRStatus",
    status: PLPRStatus,
  },
];
let PLOptStatusArray = [
  {
    doc: "PLCopyOfRegistryStatus",
    status: PLCopyOfRegistryStatus,
  },
  {
    doc: "PLRentAgreementStatus",
    status: PLRentAgreementStatus,
  },
];
let PLadditionalReupload = false;
let PLadditionalFilesIndex;
let PLadditionalReuploadIndex;
let PLadditionalReuploadStatus;
let PLadditionalFilesNames = [];
let PLproceednowforword;
let AnyReuploadOccuredPL = "No";

// HUF
let mainactionHUFAadhar = "upload";
let HUFAadharStatus;
let mainactionHUFPan1 = "upload";
let HUFPan1Status;
let mainactionHUFPic = "upload";
let HUFPicStatus;
let mainactionHUFPan2 = "upload";
let HUFPan2Status;
let mainactionHUFDeed = "upload";
let HUFDeedStatus;
let mainactionHUFBP = "upload";
let HUFBPStatus;
let mainactionHUFGumasta = "upload";
let HUFGumastaStatus;
let mainactionHUFTradeLicence = "upload";
let HUFTradeLicenceStatus;
let mainactionHUFCopyOfRegistry = "upload";
let HUFCopyOfRegistryStatus;
let mainactionHUFRentAgreement = "upload";
let HUFRentAgreementStatus;
let mainactionHUFPR = "upload";
let HUFPRStatus;
let HUFStatusArray = [
  {
    doc: "HUFAadharStatus",
    status: HUFAadharStatus,
  },
  {
    doc: "HUFPan1Status",
    status: HUFPan1Status,
  },
  {
    doc: "HUFPicStatus",
    status: HUFPicStatus,
  },
  {
    doc: "HUFPan2Status",
    status: HUFPan2Status,
  },

  {
    doc: "HUFDeedStatus",
    status: HUFDeedStatus,
  },
  {
    doc: "HUFBPStatus",
    status: HUFBPStatus,
  },
  {
    doc: "HUFGumastaStatus",
    status: HUFGumastaStatus,
  },
  {
    doc: "HUFTradeLicenceStatus",
    status: HUFTradeLicenceStatus,
  },
  {
    doc: "HUFPRStatus",
    status: HUFPRStatus,
  },
];
let HUFOptStatusArray = [
  {
    doc: "HUFCopyOfRegistryStatus",
    status: HUFCopyOfRegistryStatus,
  },
  {
    doc: "HUFRentAgreementStatus",
    status: HUFRentAgreementStatus,
  },
];
let HUFadditionalReupload = false;
let HUFadditionalFilesIndex;
let HUFadditionalReuploadIndex;
let HUFadditionalReuploadStatus;
let HUFadditionalFilesNames = [];
let HUFproceednowforword;
let AnyReuploadOccuredHUF = "No";

// ST
let mainactionSTAadhar1 = "upload";
let STAadhar1Status;
let mainactionSTAadhar2 = "upload";
let STAadhar2Status;
let mainactionSTPan1 = "upload";
let STPan1Status;
let mainactionSTPan2 = "upload";
let STPan2Status;
let mainactionSTPic1 = "upload";
let STPic1Status;
let mainactionSTPic2 = "upload";
let STPic2Status;
let mainactionSTPan3 = "upload";
let STPan3Status;
let mainactionSTCR = "upload";
let STCRStatus;
let mainactionSTBP = "upload";
let STBPStatus;
let mainactionSTBR = "upload";
let STBRStatus;
let mainactionSTGumasta = "upload";
let STGumastaStatus;
let mainactionSTTradeLicence = "upload";
let STTradeLicenceStatus;
let mainactionSTCopyOfRegistry = "upload";
let STCopyOfRegistryStatus;
let mainactionSTRentAgreement = "upload";
let STRentAgreementStatus;
let mainactionSTPR = "upload";
let STPRStatus;
let STStatusArray = [
  {
    doc: "STAadhar1Status",
    status: STAadhar1Status,
  },
  {
    doc: "STAadhar2Status",
    status: STAadhar2Status,
  },
  {
    doc: "STPan1Status",
    status: STPan1Status,
  },
  {
    doc: "STPan2Status",
    status: STPan2Status,
  },
  {
    doc: "STPic1Status",
    status: STPic1Status,
  },
  {
    doc: "STPic2Status",
    status: STPic2Status,
  },
  {
    doc: "STPan3Status",
    status: STPan3Status,
  },
  {
    doc: "STCRStatus",
    status: STCRStatus,
  },
  {
    doc: "STBPStatus",
    status: STBPStatus,
  },
  {
    doc: "STBRStatus",
    status: STBRStatus,
  },
  {
    doc: "STGumastaStatus",
    status: STGumastaStatus,
  },
  {
    doc: "STTradeLicenceStatus",
    status: STTradeLicenceStatus,
  },
  {
    doc: "STPRStatus",
    status: STPRStatus,
  },
];
let STOptStatusArray = [
  {
    doc: "STCopyOfRegistryStatus",
    status: STCopyOfRegistryStatus,
  },
  {
    doc: "STRentAgreementStatus",
    status: STRentAgreementStatus,
  },
];
let STadditionalReupload = false;
let STadditionalFilesIndex;
let STadditionalReuploadIndex;
let STadditionalReuploadStatus;
let STadditionalFilesNames = [];
let STproceednowforword;
let AnyReuploadOccuredST = "No";

export class Activegstr extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // general
      dropDownValue: "",
      serviceType: "",
      fileName: "File Name",
      fileBase64: "",
      documentType: "",
      documentName: "",
      comment: "",
      commentHistroyarray: [],
      // S
      SAadharDocLink: "#",
      SPanDocLink: "#",
      SPicDocLink: "#",
      SBPDocLink: "#",
      SGumastaDocLink: "#",
      STradeLicenceDocLink: "#",
      SbusinessNature: "",
      SAddressProofType_radio: "CopyOfRegistry",
      SCopyOfRegistryDocLink: "#",
      SRentAgreementDocLink: "#",
      SPRDocLink: "#",
      IsSAddFileDisabled: true,
      SadditionalFiles: [],
      api_SadditionalFiles: "No",
      SAdd_DocName: "",
      // P
      PAadhar1DocLink: "#",
      PAadhar2DocLink: "#",
      PPan1DocLink: "#",
      PPan2DocLink: "#",
      PPic1DocLink: "#",
      PPic2DocLink: "#",
      PBPDocLink: "#",
      PGumastaDocLink: "#",
      PTradeLicenceDocLink: "#",
      PDeedDocLink: "#",
      PbusinessNature: "",
      PAddressProofType_radio: "CopyOfRegistry",
      PCopyOfRegistryDocLink: "#",
      PRentAgreementDocLink: "#",
      PPRDocLink: "#",
      IsPAddFileDisabled: true,
      PadditionalFiles: [],
      api_PadditionalFiles: "No",
      PAdd_DocName: "",
      // PL
      PLAadhar1DocLink: "#",
      PLAadhar2DocLink: "#",
      PLPan1DocLink: "#",
      PLPan2DocLink: "#",
      PLPic1DocLink: "#",
      PLPic2DocLink: "#",
      PLPan3DocLink: "#",
      PLCIDocLink: "#",
      PLMOADocLink: "#",
      PLAOADocLink: "#",
      PLBRDocLink: "#",
      PLBPDocLink: "#",
      PLGumastaDocLink: "#",
      PLTradeLicenceDocLink: "#",
      PLbusinessNature: "",
      PLAddressProofType_radio: "CopyOfRegistry",
      PLCopyOfRegistryDocLink: "#",
      PLRentAgreementDocLink: "#",
      PLPRDocLink: "#",
      IsPLAddFileDisabled: true,
      PLadditionalFiles: [],
      api_PLadditionalFiles: "No",
      PLAdd_DocName: "",
      // HUF
      HUFAadharDocLink: "#",
      HUFPan1DocLink: "#",
      HUFPicDocLink: "#",
      HUFPan2DocLink: "#",
      HUFDeedDocLink: "#",
      HUFBPDocLink: "#",
      HUFGumastaDocLink: "#",
      HUFTradeLicenceDocLink: "#",
      HUFbusinessNature: "",
      HUFAddressProofType_radio: "CopyOfRegistry",
      HUFCopyOfRegistryDocLink: "#",
      HUFRentAgreementDocLink: "#",
      HUFPRDocLink: "#",
      IsHUFAddFileDisabled: true,
      HUFadditionalFiles: [],
      api_HUFadditionalFiles: "No",
      HUFAdd_DocName: "",
      // ST
      STAadhar1DocLink: "#",
      STAadhar2DocLink: "#",
      STPan1DocLink: "#",
      STPan2DocLink: "#",
      STPic1DocLink: "#",
      STPic2DocLink: "#",
      STPan3DocLink: "#",
      STCRDocLink: "#",
      STBPDocLink: "#",
      STBRDocLink: "#",
      STGumastaDocLink: "#",
      STTradeLicenceDocLink: "#",
      STbusinessNature: "",
      STAddressProofType_radio: "CopyOfRegistry",
      STCopyOfRegistryDocLink: "#",
      STRentAgreementDocLink: "#",
      STPRDocLink: "#",
      IsSTAddFileDisabled: true,
      STadditionalFiles: [],
      api_STadditionalFiles: "No",
      STAdd_DocName: "",
      Name:"",
      Contact:"",
      Timeline: [],
      Timelinedata: false,
      timelcount: 2,
      filtertimel: [],
      deliveredDocuments: [],
      referralPartner: true
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    $(document.body).css("background-color", "#2d2d2d");
    let mainuserid = localStorage.getItem("relleIsugad");
    Decuserid = encryptor.decrypt(mainuserid);
    let mainagentid = localStorage.getItem("qazxswedcvfrtgb");
    Decagentid = encryptor.decrypt(mainagentid);
    let mainjwttoken = localStorage.getItem("toeljgtkewlna");
    Decjwttoken = encryptor.decrypt(mainjwttoken);
    let mainserviceid = localStorage.getItem("vrellvaiecstiadeceig");
    let mainaservicename = localStorage.getItem("eieltaiclsraaemevcvnge");
    let mainplan = localStorage.getItem("ipacneglatvlael");
    supportName = localStorage.getItem("supportName");
    supportContact = localStorage.getItem("supportContact");
    supportEmail = localStorage.getItem("supportEmail");
    
    this.setState({
      supportName:supportName,
      supportContact:supportContact,
      supportEmail:supportEmail,
    })

    if(this.state.referralPartner == true){
      const script = document.createElement("script");
      script.src = "/js/main.js";
      script.async = true;
      document.body.appendChild(script);
    }
    // ----------------------------temporary -----------------------------------------

    let temptoken = localStorage.getItem("fdgshdjfkgjhyhdnxhsgdhskcj");
    let Dectemptoken = encryptor.decrypt(temptoken);
    let paramsUserId2 = this.props.match.params.id2;
    if (Dectemptoken != null && Dectemptoken == paramsUserId2) {
      temp = "temporary";
    } else {
      temp = "general";
    }

    // ----------------------------temporary -----------------------------------------
    if (mainserviceid != null && mainaservicename != null && mainplan != null) {
      let paramsId = this.props.match.params.id;
      let paramsUserId = this.props.match.params.id2;
      Decserviceid = encryptor.decrypt(mainserviceid);
      Decservicename = encryptor.decrypt(mainaservicename);
      Decplan = encryptor.decrypt(mainplan);
      if (paramsId != Decserviceid || paramsUserId != Decuserid) {
        this.props.history.push("/dashboard");
      }
    } else {
      this.props.history.push("/dashboard");
    }
    this.getserviceInformationApi();

    let str = (document.getElementsByClassName(
      "myservicesortbyfilterbystatus"
    ).innerHTML = this.props.location.pathname.replaceAll("/", " > "));
    let mainstr = (document.getElementsByClassName(
      "myservicesortbyfilterbystatus"
    ).innerHTML = str.replace(" > ", " "));
    $(".myservicesortbyfilterbystatus").html(mainstr);

    this.getnameanumber();

    // $(".serviceoverlay").show();
  }

  getnameanumber = () => {
    axios.post(
      process.env.REACT_APP_API_BASE_URL + "p=empexecutiveDetails",
      {
        agentId: Decagentid,
        serviceName: Decservicename,
        serviceId: Decserviceid, 
      },
      {
        headers: {
          Authkey: process.env.REACT_APP_API_KEY,
          "App-Token-Access": Decjwttoken,
        },
        auth: {
          username: process.env.REACT_APP_API_USERNAME,
          password: process.env.REACT_APP_API_PASSWORD,
        },
      }
    )
    .then((res) => {
      if (res.data.code == "200" && res.data.status == "success") {
        this.setState({
          Name: res.data.teamLeaderName,
          Contact: res.data.teamLeaderNumber,
        })
      } else if (res.data.code == "201" && res.data.status == "invalidauth") {
        this.logout();
      } else {
          this.showNotification(res.data.message);
        } 
    })
  }

  getserviceInformationApi = () => {
    $(".serviceoverlay").show();
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=serviceInformation",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          plan: Decplan,
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        $(".serviceoverlay").hide();
        if (res.data.code == "200" && res.data.status == "success") {

          this.setState({
            deliveredDocuments: res.data.deliveredDocuments,
          });

          HideProceedBtn = "No";
          $(".proceednowbtn").show();
          if (res.data.documents.length == 0) {
            $(".proceednowbtn").attr("disabled", true);
          }
          if (
            res.data.inputFields.length == 0 ||
            res.data.inputFields.AddressProofType == null
          ) {
            this.setState(
              {
                SAddressProofType_radio: "CopyOfRegistry",
                PAddressProofType_radio: "CopyOfRegistry",
                PLAddressProofType_radio: "CopyOfRegistry",
                HUFAddressProofType_radio: "CopyOfRegistry",
                STAddressProofType_radio: "CopyOfRegistry",
              },
              () => {
                $(".CopyOfRegistry").css("display", "flex");
                $(".RentAgreement").hide();
              }
            );
          }
          if (res.data.serviceType == null) {
            this.setState(
              {
                dropDownValue: "volvo",
                SAdd_DocName: "",
                SadditionalFiles: [1],
                api_SadditionalFiles: "No",
                PAdd_DocName: "",
                PadditionalFiles: [1],
                api_PadditionalFiles: "No",
                PLAdd_DocName: "",
                PLadditionalFiles: [1],
                api_PLadditionalFiles: "No",
                HUFAdd_DocName: "",
                HUFadditionalFiles: [1],
                api_HUFadditionalFiles: "No",
                STAdd_DocName: "",
                STadditionalFiles: [1],
                api_STadditionalFiles: "No",
              },
              () => {
                if (this.state.dropDownValue == "volvo") {
                  $("#gstr_default_Img").show();
                  $("#gstr_SolePI").hide();
                  $("#gstr_P_LLP").hide();
                  $("#gstr_PL").hide();
                  $("#gstr_HUF").hide();
                  $("#gstr_Society").hide();
                  SStatusArray.map((a) => (a.status = "open"));
                  SOptStatusArray.map((a) => (a.status = "open"));
                  PStatusArray.map((a) => (a.status = "open"));
                  POptStatusArray.map((a) => (a.status = "open"));
                  PLStatusArray.map((a) => (a.status = "open"));
                  PLOptStatusArray.map((a) => (a.status = "open"));
                  HUFStatusArray.map((a) => (a.status = "open"));
                  HUFOptStatusArray.map((a) => (a.status = "open"));
                  STStatusArray.map((a) => (a.status = "open"));
                  STOptStatusArray.map((a) => (a.status = "open"));
                }
              }
            );
          } else if (res.data.serviceType == "GSTR_SolePI_Documents") {
            $(".selectcateselect").attr("disabled", true);
            this.setState(
              {
                dropDownValue: "GSTR_SolePI_Documents",
                serviceType: "GSTR_SolePI_Documents",
              },
              () => {
                if (this.state.dropDownValue == "GSTR_SolePI_Documents") {
                  $("#gstr_default_Img").hide();
                  $("#gstr_SolePI").show();
                  $("#gstr_P_LLP").hide();
                  $("#gstr_PL").hide();
                  $("#gstr_HUF").hide();
                  $("#gstr_Society").hide();
                }
              }
            );
            if (res.data.informationStatus == "lock") {
              if (res.data.addDocInformation.length == 0) {
                $("#SadditionalDocumentDiv").hide();
              } else {
                $("#SAdddocumentbtn").hide();
              }
            }
            if (res.data.addDocInformation.length > 0) {
              this.setState(
                {
                  SadditionalFiles: [...res.data.addDocInformation],
                  SAdd_DocName: "File Name",
                  api_SadditionalFiles: "Yes",
                },
                () => {}
              );
            } else if (
              res.data.addDocInformation.length == 0 &&
              res.data.informationStatus != "lock"
            ) {
              this.setState({
                SAdd_DocName: "",
                SadditionalFiles: [1],
                api_SadditionalFiles: "No",
              });
            }
            if (res.data.documents.length > 0) {
              let doc = res.data.documents;
              if (doc[0].status == "remove") {
                mainactionSAadhar = "upload";
                SAadharStatus = "remove";
                SStatusArray[0].status = SAadharStatus;
                this.setState(
                  {
                    SAadharDocLink: doc[0].AadhaarCard,
                  },
                  () => {
                    $("#viewDocLink_SAadhar").css("display", "block");
                    $("#choose_SAadhar").hide();
                    $("#X-btn_SAadhar").show();
                    $("#done-btn_SAadhar").hide();
                    $("#review-btn_SAadhar").hide();
                  }
                );
              } else if (doc[0].status == "review") {
                mainactionSAadhar = "upload";
                SAadharStatus = "review";
                SStatusArray[0].status = SAadharStatus;
                this.setState(
                  {
                    SAadharDocLink: doc[0].AadhaarCard,
                  },
                  () => {
                    $("#viewDocLink_SAadhar").css("display", "block");
                    $("#choose_SAadhar").hide();
                    $("#X-btn_SAadhar").hide();
                    $("#done-btn_SAadhar").hide();
                    $("#review-btn_SAadhar").show();
                  }
                );
              } else if (doc[0].status == "reupload") {
                mainactionSAadhar = "reupload";
                SAadharStatus = "reupload";
                SStatusArray[0].status = SAadharStatus;
                $("#choose_SAadhar").show();
                $("#viewDocLink_SAadhar").hide();
                $("#X-btn_SAadhar").hide();
                $("#done-btn_SAadhar").hide();
                $("#review-btn_SAadhar").hide();
              } else if (doc[0].status == "done") {
                SAadharStatus = "done";
                mainactionSAadhar = "upload";
                SStatusArray[0].status = SAadharStatus;
                $("#choose_SAadhar").hide();
                $("#X-btn_SAadhar").hide();
                $("#done-btn_SAadhar").show();
                $("#review-btn_SAadhar").hide();
              } else {
                SAadharStatus = "open";
                SStatusArray[0].status = SAadharStatus;
              }
              if (doc[1].status == "remove") {
                mainactionSPan = "upload";
                SPanStatus = "remove";
                SStatusArray[1].status = SPanStatus;
                this.setState(
                  {
                    SPanDocLink: doc[1].PANCard,
                  },
                  () => {
                    $("#viewDocLink_SPan").css("display", "block");
                    $("#choose_SPan").hide();
                    $("#X-btn_SPan").show();
                    $("#done-btn_SPan").hide();
                    $("#review-btn_SPan").hide();
                  }
                );
              } else if (doc[1].status == "review") {
                mainactionSPan = "upload";
                SPanStatus = "review";
                SStatusArray[1].status = SPanStatus;
                this.setState(
                  {
                    SPanDocLink: doc[1].PANCard,
                  },
                  () => {
                    $("#viewDocLink_SPan").css("display", "block");
                    $("#choose_SPan").hide();
                    $("#X-btn_SPan").hide();
                    $("#done-btn_SPan").hide();
                    $("#review-btn_SPan").show();
                  }
                );
              } else if (doc[1].status == "reupload") {
                mainactionSPan = "reupload";
                SPanStatus = "reupload";
                SStatusArray[1].status = SPanStatus;
                $("#choose_SPan").show();
                $("#viewDocLink_SPan").hide();
                $("#X-btn_SPan").hide();
                $("#done-btn_SPan").hide();
                $("#review-btn_SPan").hide();
              } else if (doc[1].status == "done") {
                SPanStatus = "done";
                mainactionSPan = "upload";
                SStatusArray[1].status = SPanStatus;
                $("#choose_SPan").hide();
                $("#X-btn_SPan").hide();
                $("#done-btn_SPan").show();
                $("#review-btn_SPan").hide();
              } else {
                SPanStatus = "open";
                SStatusArray[1].status = SPanStatus;
              }
              if (doc[2].status == "remove") {
                mainactionSPic = "upload";
                SPicStatus = "remove";
                SStatusArray[2].status = SPicStatus;
                this.setState(
                  {
                    SPicDocLink: doc[2].SolePhoto,
                  },
                  () => {
                    $("#viewDocLink_SPic").css("display", "block");
                    $("#choose_SPic").hide();
                    $("#X-btn_SPic").show();
                    $("#done-btn_SPic").hide();
                    $("#review-btn_SPic").hide();
                  }
                );
              } else if (doc[2].status == "review") {
                mainactionSPic = "upload";
                SPicStatus = "review";
                SStatusArray[2].status = SPicStatus;
                this.setState(
                  {
                    SPicDocLink: doc[2].SolePhoto,
                  },
                  () => {
                    $("#viewDocLink_SPic").css("display", "block");
                    $("#choose_SPic").hide();
                    $("#X-btn_SPic").hide();
                    $("#done-btn_SPic").hide();
                    $("#review-btn_SPic").show();
                  }
                );
              } else if (doc[2].status == "reupload") {
                mainactionSPic = "reupload";
                SPicStatus = "reupload";
                SStatusArray[2].status = SPicStatus;
                $("#choose_SPic").show();
                $("#viewDocLink_SPic").hide();
                $("#X-btn_SPic").hide();
                $("#done-btn_SPic").hide();
                $("#review-btn_SPic").hide();
              } else if (doc[2].status == "done") {
                SPicStatus = "done";
                mainactionSPic = "upload";
                SStatusArray[2].status = SPicStatus;
                $("#choose_SPic").hide();
                $("#X-btn_SPic").hide();
                $("#done-btn_SPic").show();
                $("#review-btn_SPic").hide();
              } else {
                SPicStatus = "open";
                SStatusArray[2].status = SPicStatus;
              }
              if (doc[3].status == "remove") {
                mainactionSBP = "upload";
                SBPStatus = "remove";
                SStatusArray[3].status = SBPStatus;
                this.setState(
                  {
                    SBPDocLink: doc[3].BankProof,
                  },
                  () => {
                    $("#viewDocLink_SBP").css("display", "block");
                    $("#choose_SBP").hide();
                    $("#X-btn_SBP").show();
                    $("#done-btn_SBP").hide();
                    $("#review-btn_SBP").hide();
                  }
                );
              } else if (doc[3].status == "review") {
                mainactionSBP = "upload";
                SBPStatus = "review";
                SStatusArray[3].status = SBPStatus;
                this.setState(
                  {
                    SBPDocLink: doc[3].BankProof,
                  },
                  () => {
                    $("#viewDocLink_SBP").css("display", "block");
                    $("#choose_SBP").hide();
                    $("#X-btn_SBP").hide();
                    $("#done-btn_SBP").hide();
                    $("#review-btn_SBP").show();
                  }
                );
              } else if (doc[3].status == "reupload") {
                mainactionSBP = "reupload";
                SBPStatus = "reupload";
                SStatusArray[3].status = SBPStatus;
                $("#choose_SBP").show();
                $("#viewDocLink_SBP").hide();
                $("#X-btn_SBP").hide();
                $("#done-btn_SBP").hide();
                $("#review-btn_SBP").hide();
              } else if (doc[3].status == "done") {
                SBPStatus = "done";
                mainactionSBP = "upload";
                SStatusArray[3].status = SBPStatus;
                $("#choose_SBP").hide();
                $("#X-btn_SBP").hide();
                $("#done-btn_SBP").show();
                $("#review-btn_SBP").hide();
              } else {
                SBPStatus = "open";
                SStatusArray[3].status = SBPStatus;
              }
              if (doc[4].status == "remove") {
                mainactionSGumasta = "upload";
                SGumastaStatus = "remove";
                SStatusArray[4].status = SGumastaStatus;
                this.setState(
                  {
                    SGumastaDocLink: doc[4].Gumasta,
                  },
                  () => {
                    $("#viewDocLink_SGumasta").css("display", "block");
                    $("#choose_SGumasta").hide();
                    $("#X-btn_SGumasta").show();
                    $("#done-btn_SGumasta").hide();
                    $("#review-btn_SGumasta").hide();
                  }
                );
              } else if (doc[4].status == "review") {
                mainactionSGumasta = "upload";
                SGumastaStatus = "review";
                SStatusArray[4].status = SGumastaStatus;
                this.setState(
                  {
                    SGumastaDocLink: doc[4].Gumasta,
                  },
                  () => {
                    $("#viewDocLink_SGumasta").css("display", "block");
                    $("#choose_SGumasta").hide();
                    $("#X-btn_SGumasta").hide();
                    $("#done-btn_SGumasta").hide();
                    $("#review-btn_SGumasta").show();
                  }
                );
              } else if (doc[4].status == "reupload") {
                mainactionSGumasta = "reupload";
                SGumastaStatus = "reupload";
                SStatusArray[4].status = SGumastaStatus;
                $("#choose_SGumasta").show();
                $("#viewDocLink_SGumasta").hide();
                $("#X-btn_SGumasta").hide();
                $("#done-btn_SGumasta").hide();
                $("#review-btn_SGumasta").hide();
              } else if (doc[4].status == "done") {
                SGumastaStatus = "done";
                mainactionSGumasta = "upload";
                SStatusArray[4].status = SGumastaStatus;
                $("#choose_SGumasta").hide();
                $("#X-btn_SGumasta").hide();
                $("#done-btn_SGumasta").show();
                $("#review-btn_SGumasta").hide();
              } else {
                SGumastaStatus = "open";
                SStatusArray[4].status = SGumastaStatus;
              }
              if (doc[5].status == "remove") {
                mainactionSTradeLicence = "upload";
                STradeLicenceStatus = "remove";
                SStatusArray[5].status = STradeLicenceStatus;
                this.setState(
                  {
                    STradeLicenceDocLink: doc[5].TradeLicence,
                  },
                  () => {
                    $("#viewDocLink_STradeLicence").css("display", "block");
                    $("#choose_STradeLicence").hide();
                    $("#X-btn_STradeLicence").show();
                    $("#done-btn_STradeLicence").hide();
                    $("#review-btn_STradeLicence").hide();
                  }
                );
              } else if (doc[5].status == "review") {
                mainactionSTradeLicence = "upload";
                STradeLicenceStatus = "review";
                SStatusArray[5].status = STradeLicenceStatus;
                this.setState(
                  {
                    STradeLicenceDocLink: doc[5].TradeLicence,
                  },
                  () => {
                    $("#viewDocLink_STradeLicence").css("display", "block");
                    $("#choose_STradeLicence").hide();
                    $("#X-btn_STradeLicence").hide();
                    $("#done-btn_STradeLicence").hide();
                    $("#review-btn_STradeLicence").show();
                  }
                );
              } else if (doc[5].status == "reupload") {
                mainactionSTradeLicence = "reupload";
                STradeLicenceStatus = "reupload";
                SStatusArray[5].status = STradeLicenceStatus;
                $("#choose_STradeLicence").show();
                $("#viewDocLink_STradeLicence").hide();
                $("#X-btn_STradeLicence").hide();
                $("#done-btn_STradeLicence").hide();
                $("#review-btn_STradeLicence").hide();
              } else if (doc[5].status == "done") {
                STradeLicenceStatus = "done";
                mainactionSTradeLicence = "upload";
                SStatusArray[5].status = STradeLicenceStatus;
                $("#choose_STradeLicence").hide();
                $("#X-btn_STradeLicence").hide();
                $("#done-btn_STradeLicence").show();
                $("#review-btn_STradeLicence").hide();
              } else {
                STradeLicenceStatus = "open";
                SStatusArray[5].status = STradeLicenceStatus;
              }
              if (doc[6].status == "remove") {
                mainactionSCopyOfRegistry = "upload";
                SCopyOfRegistryStatus = "remove";
                SOptStatusArray[0].status = SCopyOfRegistryStatus;
                this.setState(
                  {
                    SCopyOfRegistryDocLink: doc[6].CopyOfRegistry,
                  },
                  () => {
                    $("#viewDocLink_SCopyOfRegistry").css("display", "block");
                    $("#choose_SCopyOfRegistry").hide();
                    $("#X-btn_SCopyOfRegistry").show();
                    $("#done-btn_SCopyOfRegistry").hide();
                    $("#review-btn_SCopyOfRegistry").hide();
                  }
                );
              } else if (doc[6].status == "review") {
                mainactionSCopyOfRegistry = "upload";
                SCopyOfRegistryStatus = "review";
                SOptStatusArray[0].status = SCopyOfRegistryStatus;
                this.setState(
                  {
                    SCopyOfRegistryDocLink: doc[6].CopyOfRegistry,
                  },
                  () => {
                    $("#viewDocLink_SCopyOfRegistry").css("display", "block");
                    $("#choose_SCopyOfRegistry").hide();
                    $("#X-btn_SCopyOfRegistry").hide();
                    $("#done-btn_SCopyOfRegistry").hide();
                    $("#review-btn_SCopyOfRegistry").show();
                  }
                );
              } else if (doc[6].status == "reupload") {
                mainactionSCopyOfRegistry = "reupload";
                SCopyOfRegistryStatus = "reupload";
                SOptStatusArray[0].status = SCopyOfRegistryStatus;
                $("#choose_SCopyOfRegistry").show();
                $("#viewDocLink_SCopyOfRegistry").hide();
                $("#X-btn_SCopyOfRegistry").hide();
                $("#done-btn_SCopyOfRegistry").hide();
                $("#review-btn_SCopyOfRegistry").hide();
              } else if (doc[6].status == "done") {
                SCopyOfRegistryStatus = "done";
                mainactionSCopyOfRegistry = "upload";
                SOptStatusArray[0].status = SCopyOfRegistryStatus;
                $("#choose_SCopyOfRegistry").hide();
                $("#X-btn_SCopyOfRegistry").hide();
                $("#done-btn_SCopyOfRegistry").show();
                $("#review-btn_SCopyOfRegistry").hide();
              } else {
                SCopyOfRegistryStatus = "open";
                SOptStatusArray[0].status = SCopyOfRegistryStatus;
              }
              if (doc[7].status == "remove") {
                mainactionSRentAgreement = "upload";
                SRentAgreementStatus = "remove";
                SOptStatusArray[1].status = SRentAgreementStatus;
                this.setState(
                  {
                    SRentAgreementDocLink: doc[7].RentAgreement,
                  },
                  () => {
                    $("#viewDocLink_SRentAgreement").css("display", "block");
                    $("#choose_SRentAgreement").hide();
                    $("#X-btn_SRentAgreement").show();
                    $("#done-btn_SRentAgreement").hide();
                    $("#review-btn_SRentAgreement").hide();
                  }
                );
              } else if (doc[7].status == "review") {
                mainactionSRentAgreement = "upload";
                SRentAgreementStatus = "review";
                SOptStatusArray[1].status = SRentAgreementStatus;
                this.setState(
                  {
                    SRentAgreementDocLink: doc[7].RentAgreement,
                  },
                  () => {
                    $("#viewDocLink_SRentAgreement").css("display", "block");
                    $("#choose_SRentAgreement").hide();
                    $("#X-btn_SRentAgreement").hide();
                    $("#done-btn_SRentAgreement").hide();
                    $("#review-btn_SRentAgreement").show();
                  }
                );
              } else if (doc[7].status == "reupload") {
                mainactionSRentAgreement = "reupload";
                SRentAgreementStatus = "reupload";
                SOptStatusArray[1].status = SRentAgreementStatus;
                $("#choose_SRentAgreement").show();
                $("#viewDocLink_SRentAgreement").hide();
                $("#X-btn_SRentAgreement").hide();
                $("#done-btn_SRentAgreement").hide();
                $("#review-btn_SRentAgreement").hide();
              } else if (doc[7].status == "done") {
                SRentAgreementStatus = "done";
                mainactionSRentAgreement = "upload";
                SOptStatusArray[1].status = SRentAgreementStatus;
                $("#choose_SRentAgreement").hide();
                $("#X-btn_SRentAgreement").hide();
                $("#done-btn_SRentAgreement").show();
                $("#review-btn_SRentAgreement").hide();
              } else {
                SRentAgreementStatus = "open";
                SOptStatusArray[1].status = SRentAgreementStatus;
              }
              if (doc[8].status == "remove") {
                mainactionSPR = "upload";
                SPRStatus = "remove";
                SStatusArray[6].status = SPRStatus;
                this.setState(
                  {
                    SPRDocLink: doc[8].PropertyTaxReceipt,
                  },
                  () => {
                    $("#viewDocLink_SPR").css("display", "block");
                    $("#choose_SPR").hide();
                    $("#X-btn_SPR").show();
                    $("#done-btn_SPR").hide();
                    $("#review-btn_SPR").hide();
                  }
                );
              } else if (doc[8].status == "review") {
                mainactionSPR = "upload";
                SPRStatus = "review";
                SStatusArray[6].status = SPRStatus;
                this.setState(
                  {
                    SPRDocLink: doc[8].PropertyTaxReceipt,
                  },
                  () => {
                    $("#viewDocLink_SPR").css("display", "block");
                    $("#choose_SPR").hide();
                    $("#X-btn_SPR").hide();
                    $("#done-btn_SPR").hide();
                    $("#review-btn_SPR").show();
                  }
                );
              } else if (doc[8].status == "reupload") {
                mainactionSPR = "reupload";
                SPRStatus = "reupload";
                SStatusArray[6].status = SPRStatus;
                $("#choose_SPR").show();
                $("#viewDocLink_SPR").hide();
                $("#X-btn_SPR").hide();
                $("#done-btn_SPR").hide();
                $("#review-btn_SPR").hide();
              } else if (doc[8].status == "done") {
                SPRStatus = "done";
                mainactionSPR = "upload";
                SStatusArray[6].status = SPRStatus;
                $("#choose_SPR").hide();
                $("#X-btn_SPR").hide();
                $("#done-btn_SPR").show();
                $("#review-btn_SPR").hide();
              } else {
                SPRStatus = "open";
                SStatusArray[6].status = SPRStatus;
              }
            }
            if (res.data.inputFields.length > 0) {
              if (
                res.data.inputFields[0].AddressProofType == "CopyOfRegistry"
              ) {
                this.setState(
                  {
                    SAddressProofType_radio: "CopyOfRegistry",
                  },
                  () => {
                    $(".CopyOfRegistry").css("display", "flex");
                    $(".RentAgreement").hide();
                  }
                );
              } else if (
                res.data.inputFields[0].AddressProofType == "RentAgreement"
              ) {
                this.setState(
                  {
                    SAddressProofType_radio: "RentAgreement",
                  },
                  () => {
                    $(".RentAgreement").css("display", "flex");
                    $(".CopyOfRegistry").hide();
                  }
                );
              }
              if (
                res.data.inputFields[1].status == "review" ||
                res.data.inputFields[1].status == "done"
              ) {
                this.setState({
                  SbusinessNature: res.data.inputFields[1].Nature,
                });
                $(".uploadtextarea").attr("disabled", true);
              }
            }
            this.ScheckRemainingReuploads();
            this.checkhandleSProceedBtn();
          } else if (res.data.serviceType == "GSTR_P_LLP_Documents") {
            $(".selectcateselect").attr("disabled", true);
            this.setState(
              {
                dropDownValue: "GSTR_P_LLP_Documents",
                serviceType: "GSTR_P_LLP_Documents",
              },
              () => {
                if (this.state.dropDownValue == "GSTR_P_LLP_Documents") {
                  $("#gstr_default_Img").hide();
                  $("#gstr_SolePI").hide();
                  $("#gstr_P_LLP").show();
                  $("#gstr_PL").hide();
                  $("#gstr_HUF").hide();
                  $("#gstr_Society").hide();
                }
              }
            );
            if (res.data.informationStatus == "lock") {
              if (res.data.addDocInformation.length == 0) {
                $("#PadditionalDocumentDiv").hide();
              } else {
                $("#PAdddocumentbtn").hide();
              }
            }
            if (res.data.addDocInformation.length > 0) {
              this.setState(
                {
                  PadditionalFiles: [...res.data.addDocInformation],
                  PAdd_DocName: "File Name",
                  api_PadditionalFiles: "Yes",
                },
                () => {}
              );
            } else if (
              res.data.addDocInformation.length == 0 &&
              res.data.informationStatus != "lock"
            ) {
              this.setState({
                PAdd_DocName: "",
                PadditionalFiles: [1],
                api_PadditionalFiles: "No",
              });
            }
            if (res.data.documents.length > 0) {
              let doc = res.data.documents;
              if (doc[0].status == "remove") {
                mainactionPAadhar1 = "upload";
                PAadhar1Status = "remove";
                PStatusArray[0].status = PAadhar1Status;
                this.setState(
                  {
                    PAadhar1DocLink: doc[0].AadhaarCardApplicant1,
                  },
                  () => {
                    $("#viewDocLink_PAadhar1").css("display", "block");
                    $("#choose_PAadhar1").hide();
                    $("#X-btn_PAadhar1").show();
                    $("#done-btn_PAadhar1").hide();
                    $("#review-btn_PAadhar1").hide();
                  }
                );
              } else if (doc[0].status == "review") {
                mainactionPAadhar1 = "upload";
                PAadhar1Status = "review";
                PStatusArray[0].status = PAadhar1Status;
                this.setState(
                  {
                    PAadhar1DocLink: doc[0].AadhaarCardApplicant1,
                  },
                  () => {
                    $("#viewDocLink_PAadhar1").css("display", "block");
                    $("#choose_PAadhar1").hide();
                    $("#X-btn_PAadhar1").hide();
                    $("#done-btn_PAadhar1").hide();
                    $("#review-btn_PAadhar1").show();
                  }
                );
              } else if (doc[0].status == "reupload") {
                mainactionPAadhar1 = "reupload";
                PAadhar1Status = "reupload";
                PStatusArray[0].status = PAadhar1Status;
                $("#choose_PAadhar1").show();
                $("#viewDocLink_PAadhar1").hide();
                $("#X-btn_PAadhar1").hide();
                $("#done-btn_PAadhar1").hide();
                $("#review-btn_PAadhar1").hide();
              } else if (doc[0].status == "done") {
                PAadhar1Status = "done";
                mainactionPAadhar1 = "upload";
                PStatusArray[0].status = PAadhar1Status;
                $("#choose_PAadhar1").hide();
                $("#X-btn_PAadhar1").hide();
                $("#done-btn_PAadhar1").show();
                $("#review-btn_PAadhar1").hide();
              } else {
                PAadhar1Status = "open";
                PStatusArray[0].status = PAadhar1Status;
              }
              if (doc[1].status == "remove") {
                mainactionPAadhar2 = "upload";
                PAadhar2Status = "remove";
                PStatusArray[1].status = PAadhar2Status;
                this.setState(
                  {
                    PAadhar2DocLink: doc[1].AadhaarCardApplicant2,
                  },
                  () => {
                    $("#viewDocLink_PAadhar2").css("display", "block");
                    $("#choose_PAadhar2").hide();
                    $("#X-btn_PAadhar2").show();
                    $("#done-btn_PAadhar2").hide();
                    $("#review-btn_PAadhar2").hide();
                  }
                );
              } else if (doc[1].status == "review") {
                mainactionPAadhar2 = "upload";
                PAadhar2Status = "review";
                PStatusArray[1].status = PAadhar2Status;
                this.setState(
                  {
                    PAadhar2DocLink: doc[1].AadhaarCardApplicant2,
                  },
                  () => {
                    $("#viewDocLink_PAadhar2").css("display", "block");
                    $("#choose_PAadhar2").hide();
                    $("#X-btn_PAadhar2").hide();
                    $("#done-btn_PAadhar2").hide();
                    $("#review-btn_PAadhar2").show();
                  }
                );
              } else if (doc[1].status == "reupload") {
                mainactionPAadhar2 = "reupload";
                PAadhar2Status = "reupload";
                PStatusArray[1].status = PAadhar2Status;
                $("#choose_PAadhar2").show();
                $("#viewDocLink_PAadhar2").hide();
                $("#X-btn_PAadhar2").hide();
                $("#done-btn_PAadhar2").hide();
                $("#review-btn_PAadhar2").hide();
              } else if (doc[1].status == "done") {
                PAadhar2Status = "done";
                mainactionPAadhar2 = "upload";
                PStatusArray[1].status = PAadhar2Status;
                $("#choose_PAadhar2").hide();
                $("#X-btn_PAadhar2").hide();
                $("#done-btn_PAadhar2").show();
                $("#review-btn_PAadhar2").hide();
              } else {
                PAadhar2Status = "open";
                PStatusArray[1].status = PAadhar2Status;
              }
              if (doc[2].status == "remove") {
                mainactionPPan1 = "upload";
                PPan1Status = "remove";
                PStatusArray[2].status = PPan1Status;
                this.setState(
                  {
                    PPan1DocLink: doc[2].PANCardApplicant1,
                  },
                  () => {
                    $("#viewDocLink_PPan1").css("display", "block");
                    $("#choose_PPan1").hide();
                    $("#X-btn_PPan1").show();
                    $("#done-btn_PPan1").hide();
                    $("#review-btn_PPan1").hide();
                  }
                );
              } else if (doc[2].status == "review") {
                mainactionPPan1 = "upload";
                PPan1Status = "review";
                PStatusArray[2].status = PPan1Status;
                this.setState(
                  {
                    PPan1DocLink: doc[2].PANCardApplicant1,
                  },
                  () => {
                    $("#viewDocLink_PPan1").css("display", "block");
                    $("#choose_PPan1").hide();
                    $("#X-btn_PPan1").hide();
                    $("#done-btn_PPan1").hide();
                    $("#review-btn_PPan1").show();
                  }
                );
              } else if (doc[2].status == "reupload") {
                mainactionPPan1 = "reupload";
                PPan1Status = "reupload";
                PStatusArray[2].status = PPan1Status;
                $("#choose_PPan1").show();
                $("#viewDocLink_PPan1").hide();
                $("#X-btn_PPan1").hide();
                $("#done-btn_PPan1").hide();
                $("#review-btn_PPan1").hide();
              } else if (doc[2].status == "done") {
                PPan1Status = "done";
                mainactionPPan1 = "upload";
                PStatusArray[2].status = PPan1Status;
                $("#choose_PPan1").hide();
                $("#X-btn_PPan1").hide();
                $("#done-btn_PPan1").show();
                $("#review-btn_PPan1").hide();
              } else {
                PPan1Status = "open";
                PStatusArray[2].status = PPan1Status;
              }
              if (doc[3].status == "remove") {
                mainactionPPan2 = "upload";
                PPan2Status = "remove";
                PStatusArray[3].status = PPan2Status;
                this.setState(
                  {
                    PPan2DocLink: doc[3].PANCardApplicant2,
                  },
                  () => {
                    $("#viewDocLink_PPan2").css("display", "block");
                    $("#choose_PPan2").hide();
                    $("#X-btn_PPan2").show();
                    $("#done-btn_PPan2").hide();
                    $("#review-btn_PPan2").hide();
                  }
                );
              } else if (doc[3].status == "review") {
                mainactionPPan2 = "upload";
                PPan2Status = "review";
                PStatusArray[3].status = PPan2Status;
                this.setState(
                  {
                    PPan2DocLink: doc[3].PANCardApplicant2,
                  },
                  () => {
                    $("#viewDocLink_PPan2").css("display", "block");
                    $("#choose_PPan2").hide();
                    $("#X-btn_PPan2").hide();
                    $("#done-btn_PPan2").hide();
                    $("#review-btn_PPan2").show();
                  }
                );
              } else if (doc[3].status == "reupload") {
                mainactionPPan2 = "reupload";
                PPan2Status = "reupload";
                PStatusArray[3].status = PPan2Status;
                $("#choose_PPan2").show();
                $("#viewDocLink_PPan2").hide();
                $("#X-btn_PPan2").hide();
                $("#done-btn_PPan2").hide();
                $("#review-btn_PPan2").hide();
              } else if (doc[3].status == "done") {
                PPan2Status = "done";
                mainactionPPan2 = "upload";
                PStatusArray[3].status = PPan2Status;
                $("#choose_PPan2").hide();
                $("#X-btn_PPan2").hide();
                $("#done-btn_PPan2").show();
                $("#review-btn_PPan2").hide();
              } else {
                PPan2Status = "open";
                PStatusArray[3].status = PPan2Status;
              }
              if (doc[4].status == "remove") {
                mainactionPPic1 = "upload";
                PPic1Status = "remove";
                PStatusArray[4].status = PPic1Status;
                this.setState(
                  {
                    PPic1DocLink: doc[4].ApplicantPhoto1,
                  },
                  () => {
                    $("#viewDocLink_PPic1").css("display", "block");
                    $("#choose_PPic1").hide();
                    $("#X-btn_PPic1").show();
                    $("#done-btn_PPic1").hide();
                    $("#review-btn_PPic1").hide();
                  }
                );
              } else if (doc[4].status == "review") {
                mainactionPPic1 = "upload";
                PPic1Status = "review";
                PStatusArray[4].status = PPic1Status;
                this.setState(
                  {
                    PPic1DocLink: doc[4].ApplicantPhoto1,
                  },
                  () => {
                    $("#viewDocLink_PPic1").css("display", "block");
                    $("#choose_PPic1").hide();
                    $("#X-btn_PPic1").hide();
                    $("#done-btn_PPic1").hide();
                    $("#review-btn_PPic1").show();
                  }
                );
              } else if (doc[4].status == "reupload") {
                mainactionPPic1 = "reupload";
                PPic1Status = "reupload";
                PStatusArray[4].status = PPic1Status;
                $("#choose_PPic1").show();
                $("#viewDocLink_PPic1").hide();
                $("#X-btn_PPic1").hide();
                $("#done-btn_PPic1").hide();
                $("#review-btn_PPic1").hide();
              } else if (doc[4].status == "done") {
                PPic1Status = "done";
                mainactionPPic1 = "upload";
                PStatusArray[4].status = PPic1Status;
                $("#choose_PPic1").hide();
                $("#X-btn_PPic1").hide();
                $("#done-btn_PPic1").show();
                $("#review-btn_PPic1").hide();
              } else {
                PPic1Status = "open";
                PStatusArray[4].status = PPic1Status;
              }
              if (doc[5].status == "remove") {
                mainactionPPic2 = "upload";
                PPic2Status = "remove";
                PStatusArray[5].status = PPic2Status;
                this.setState(
                  {
                    PPic2DocLink: doc[5].ApplicantPhoto2,
                  },
                  () => {
                    $("#viewDocLink_PPic2").css("display", "block");
                    $("#choose_PPic2").hide();
                    $("#X-btn_PPic2").show();
                    $("#done-btn_PPic2").hide();
                    $("#review-btn_PPic2").hide();
                  }
                );
              } else if (doc[5].status == "review") {
                mainactionPPic2 = "upload";
                PPic2Status = "review";
                PStatusArray[5].status = PPic2Status;
                this.setState(
                  {
                    PPic2DocLink: doc[5].ApplicantPhoto2,
                  },
                  () => {
                    $("#viewDocLink_PPic2").css("display", "block");
                    $("#choose_PPic2").hide();
                    $("#X-btn_PPic2").hide();
                    $("#done-btn_PPic2").hide();
                    $("#review-btn_PPic2").show();
                  }
                );
              } else if (doc[5].status == "reupload") {
                mainactionPPic2 = "reupload";
                PPic2Status = "reupload";
                PStatusArray[5].status = PPic2Status;
                $("#choose_PPic2").show();
                $("#viewDocLink_PPic2").hide();
                $("#X-btn_PPic2").hide();
                $("#done-btn_PPic2").hide();
                $("#review-btn_PPic2").hide();
              } else if (doc[5].status == "done") {
                PPic2Status = "done";
                mainactionPPic2 = "upload";
                PStatusArray[5].status = PPic2Status;
                $("#choose_PPic2").hide();
                $("#X-btn_PPic2").hide();
                $("#done-btn_PPic2").show();
                $("#review-btn_PPic2").hide();
              } else {
                PPic2Status = "open";
                PStatusArray[5].status = PPic2Status;
              }
              if (doc[6].status == "remove") {
                mainactionPBP = "upload";
                PBPStatus = "remove";
                PStatusArray[6].status = PBPStatus;
                this.setState(
                  {
                    PBPDocLink: doc[6].BankProof,
                  },
                  () => {
                    $("#viewDocLink_PBP").css("display", "block");
                    $("#choose_PBP").hide();
                    $("#X-btn_PBP").show();
                    $("#done-btn_PBP").hide();
                    $("#review-btn_PBP").hide();
                  }
                );
              } else if (doc[6].status == "review") {
                mainactionPBP = "upload";
                PBPStatus = "review";
                PStatusArray[6].status = PBPStatus;
                this.setState(
                  {
                    PBPDocLink: doc[6].BankProof,
                  },
                  () => {
                    $("#viewDocLink_PBP").css("display", "block");
                    $("#choose_PBP").hide();
                    $("#X-btn_PBP").hide();
                    $("#done-btn_PBP").hide();
                    $("#review-btn_PBP").show();
                  }
                );
              } else if (doc[6].status == "reupload") {
                mainactionPBP = "reupload";
                PBPStatus = "reupload";
                PStatusArray[6].status = PBPStatus;
                $("#choose_PBP").show();
                $("#viewDocLink_PBP").hide();
                $("#X-btn_PBP").hide();
                $("#done-btn_PBP").hide();
                $("#review-btn_PBP").hide();
              } else if (doc[6].status == "done") {
                PBPStatus = "done";
                mainactionPBP = "upload";
                PStatusArray[6].status = PBPStatus;
                $("#choose_PBP").hide();
                $("#X-btn_PBP").hide();
                $("#done-btn_PBP").show();
                $("#review-btn_PBP").hide();
              } else {
                PBPStatus = "open";
                PStatusArray[6].status = PBPStatus;
              }
              if (doc[7].status == "remove") {
                mainactionPGumasta = "upload";
                PGumastaStatus = "remove";
                PStatusArray[7].status = PGumastaStatus;
                this.setState(
                  {
                    PGumastaDocLink: doc[7].Gumasta,
                  },
                  () => {
                    $("#viewDocLink_PGumasta").css("display", "block");
                    $("#choose_PGumasta").hide();
                    $("#X-btn_PGumasta").show();
                    $("#done-btn_PGumasta").hide();
                    $("#review-btn_PGumasta").hide();
                  }
                );
              } else if (doc[7].status == "review") {
                mainactionPGumasta = "upload";
                PGumastaStatus = "review";
                PStatusArray[7].status = PGumastaStatus;
                this.setState(
                  {
                    PGumastaDocLink: doc[7].Gumasta,
                  },
                  () => {
                    $("#viewDocLink_PGumasta").css("display", "block");
                    $("#choose_PGumasta").hide();
                    $("#X-btn_PGumasta").hide();
                    $("#done-btn_PGumasta").hide();
                    $("#review-btn_PGumasta").show();
                  }
                );
              } else if (doc[7].status == "reupload") {
                mainactionPGumasta = "reupload";
                PGumastaStatus = "reupload";
                PStatusArray[7].status = PGumastaStatus;
                $("#choose_PGumasta").show();
                $("#viewDocLink_PGumasta").hide();
                $("#X-btn_PGumasta").hide();
                $("#done-btn_PGumasta").hide();
                $("#review-btn_PGumasta").hide();
              } else if (doc[7].status == "done") {
                PGumastaStatus = "done";
                mainactionPGumasta = "upload";
                PStatusArray[7].status = PGumastaStatus;
                $("#choose_PGumasta").hide();
                $("#X-btn_PGumasta").hide();
                $("#done-btn_PGumasta").show();
                $("#review-btn_PGumasta").hide();
              } else {
                PGumastaStatus = "open";
                PStatusArray[7].status = PGumastaStatus;
              }
              if (doc[8].status == "remove") {
                mainactionPTradeLicence = "upload";
                PTradeLicenceStatus = "remove";
                PStatusArray[8].status = PTradeLicenceStatus;
                this.setState(
                  {
                    PTradeLicenceDocLink: doc[8].TradeLicence,
                  },
                  () => {
                    $("#viewDocLink_PTradeLicence").css("display", "block");
                    $("#choose_PTradeLicence").hide();
                    $("#X-btn_PTradeLicence").show();
                    $("#done-btn_PTradeLicence").hide();
                    $("#review-btn_PTradeLicence").hide();
                  }
                );
              } else if (doc[8].status == "review") {
                mainactionPTradeLicence = "upload";
                PTradeLicenceStatus = "review";
                PStatusArray[8].status = PTradeLicenceStatus;
                this.setState(
                  {
                    PTradeLicenceDocLink: doc[8].TradeLicence,
                  },
                  () => {
                    $("#viewDocLink_PTradeLicence").css("display", "block");
                    $("#choose_PTradeLicence").hide();
                    $("#X-btn_PTradeLicence").hide();
                    $("#done-btn_PTradeLicence").hide();
                    $("#review-btn_PTradeLicence").show();
                  }
                );
              } else if (doc[8].status == "reupload") {
                mainactionPTradeLicence = "reupload";
                PTradeLicenceStatus = "reupload";
                PStatusArray[8].status = PTradeLicenceStatus;
                $("#choose_PTradeLicence").show();
                $("#viewDocLink_PTradeLicence").hide();
                $("#X-btn_PTradeLicence").hide();
                $("#done-btn_PTradeLicence").hide();
                $("#review-btn_PTradeLicence").hide();
              } else if (doc[8].status == "done") {
                PTradeLicenceStatus = "done";
                mainactionPTradeLicence = "upload";
                PStatusArray[8].status = PTradeLicenceStatus;
                $("#choose_PTradeLicence").hide();
                $("#X-btn_PTradeLicence").hide();
                $("#done-btn_PTradeLicence").show();
                $("#review-btn_PTradeLicence").hide();
              } else {
                PTradeLicenceStatus = "open";
                PStatusArray[8].status = PTradeLicenceStatus;
              }
              if (doc[9].status == "remove") {
                mainactionPCopyOfRegistry = "upload";
                PCopyOfRegistryStatus = "remove";
                POptStatusArray[0].status = PCopyOfRegistryStatus;
                this.setState(
                  {
                    PCopyOfRegistryDocLink: doc[9].CopyOfRegistry,
                  },
                  () => {
                    $("#viewDocLink_PCopyOfRegistry").css("display", "block");
                    $("#choose_PCopyOfRegistry").hide();
                    $("#X-btn_PCopyOfRegistry").show();
                    $("#done-btn_PCopyOfRegistry").hide();
                    $("#review-btn_PCopyOfRegistry").hide();
                  }
                );
              } else if (doc[9].status == "review") {
                mainactionPCopyOfRegistry = "upload";
                PCopyOfRegistryStatus = "review";
                POptStatusArray[0].status = PCopyOfRegistryStatus;
                this.setState(
                  {
                    PCopyOfRegistryDocLink: doc[9].CopyOfRegistry,
                  },
                  () => {
                    $("#viewDocLink_PCopyOfRegistry").css("display", "block");
                    $("#choose_PCopyOfRegistry").hide();
                    $("#X-btn_PCopyOfRegistry").hide();
                    $("#done-btn_PCopyOfRegistry").hide();
                    $("#review-btn_PCopyOfRegistry").show();
                  }
                );
              } else if (doc[9].status == "reupload") {
                mainactionPCopyOfRegistry = "reupload";
                PCopyOfRegistryStatus = "reupload";
                POptStatusArray[0].status = PCopyOfRegistryStatus;
                $("#choose_PCopyOfRegistry").show();
                $("#viewDocLink_PCopyOfRegistry").hide();
                $("#X-btn_PCopyOfRegistry").hide();
                $("#done-btn_PCopyOfRegistry").hide();
                $("#review-btn_PCopyOfRegistry").hide();
              } else if (doc[9].status == "done") {
                PCopyOfRegistryStatus = "done";
                mainactionPCopyOfRegistry = "upload";
                POptStatusArray[0].status = PCopyOfRegistryStatus;
                $("#choose_PCopyOfRegistry").hide();
                $("#X-btn_PCopyOfRegistry").hide();
                $("#done-btn_PCopyOfRegistry").show();
                $("#review-btn_PCopyOfRegistry").hide();
              } else {
                PCopyOfRegistryStatus = "open";
                POptStatusArray[0].status = PCopyOfRegistryStatus;
              }
              if (doc[10].status == "remove") {
                mainactionPRentAgreement = "upload";
                PRentAgreementStatus = "remove";
                POptStatusArray[1].status = PRentAgreementStatus;
                this.setState(
                  {
                    PRentAgreementDocLink: doc[10].RentAgreement,
                  },
                  () => {
                    $("#viewDocLink_PRentAgreement").css("display", "block");
                    $("#choose_PRentAgreement").hide();
                    $("#X-btn_PRentAgreement").show();
                    $("#done-btn_PRentAgreement").hide();
                    $("#review-btn_PRentAgreement").hide();
                  }
                );
              } else if (doc[10].status == "review") {
                mainactionPRentAgreement = "upload";
                PRentAgreementStatus = "review";
                POptStatusArray[1].status = PRentAgreementStatus;
                this.setState(
                  {
                    PRentAgreementDocLink: doc[10].RentAgreement,
                  },
                  () => {
                    $("#viewDocLink_PRentAgreement").css("display", "block");
                    $("#choose_PRentAgreement").hide();
                    $("#X-btn_PRentAgreement").hide();
                    $("#done-btn_PRentAgreement").hide();
                    $("#review-btn_PRentAgreement").show();
                  }
                );
              } else if (doc[10].status == "reupload") {
                mainactionPRentAgreement = "reupload";
                PRentAgreementStatus = "reupload";
                POptStatusArray[1].status = PRentAgreementStatus;
                $("#choose_PRentAgreement").show();
                $("#viewDocLink_PRentAgreement").hide();
                $("#X-btn_PRentAgreement").hide();
                $("#done-btn_PRentAgreement").hide();
                $("#review-btn_PRentAgreement").hide();
              } else if (doc[10].status == "done") {
                PRentAgreementStatus = "done";
                mainactionPRentAgreement = "upload";
                POptStatusArray[1].status = PRentAgreementStatus;
                $("#choose_PRentAgreement").hide();
                $("#X-btn_PRentAgreement").hide();
                $("#done-btn_PRentAgreement").show();
                $("#review-btn_PRentAgreement").hide();
              } else {
                PRentAgreementStatus = "open";
                POptStatusArray[1].status = PRentAgreementStatus;
              }
              if (doc[11].status == "remove") {
                mainactionPPR = "upload";
                PPRStatus = "remove";
                PStatusArray[9].status = PPRStatus;
                this.setState(
                  {
                    PPRDocLink: doc[11].PropertyTaxReceipt,
                  },
                  () => {
                    $("#viewDocLink_PPR").css("display", "block");
                    $("#choose_PPR").hide();
                    $("#X-btn_PPR").show();
                    $("#done-btn_PPR").hide();
                    $("#review-btn_PPR").hide();
                  }
                );
              } else if (doc[11].status == "review") {
                mainactionPPR = "upload";
                PPRStatus = "review";
                PStatusArray[9].status = PPRStatus;
                this.setState(
                  {
                    PPRDocLink: doc[11].PropertyTaxReceipt,
                  },
                  () => {
                    $("#viewDocLink_PPR").css("display", "block");
                    $("#choose_PPR").hide();
                    $("#X-btn_PPR").hide();
                    $("#done-btn_PPR").hide();
                    $("#review-btn_PPR").show();
                  }
                );
              } else if (doc[11].status == "reupload") {
                mainactionPPR = "reupload";
                PPRStatus = "reupload";
                PStatusArray[9].status = PPRStatus;
                $("#choose_PPR").show();
                $("#viewDocLink_PPR").hide();
                $("#X-btn_PPR").hide();
                $("#done-btn_PPR").hide();
                $("#review-btn_PPR").hide();
              } else if (doc[11].status == "done") {
                PPRStatus = "done";
                mainactionPPR = "upload";
                PStatusArray[9].status = PPRStatus;
                $("#choose_PPR").hide();
                $("#X-btn_PPR").hide();
                $("#done-btn_PPR").show();
                $("#review-btn_PPR").hide();
              } else {
                PPRStatus = "open";
                PStatusArray[9].status = PPRStatus;
              }
              if (doc[12].status == "remove") {
                mainactionPDeed = "upload";
                PDeedStatus = "remove";
                PStatusArray[10].status = PDeedStatus;
                this.setState(
                  {
                    PDeedDocLink: doc[12].PartnershipDeed,
                  },
                  () => {
                    $("#viewDocLink_PDeed").css("display", "block");
                    $("#choose_PDeed").hide();
                    $("#X-btn_PDeed").show();
                    $("#done-btn_PDeed").hide();
                    $("#review-btn_PDeed").hide();
                  }
                );
              } else if (doc[12].status == "review") {
                mainactionPDeed = "upload";
                PDeedStatus = "review";
                PStatusArray[10].status = PDeedStatus;
                this.setState(
                  {
                    PDeedDocLink: doc[12].PartnershipDeed,
                  },
                  () => {
                    $("#viewDocLink_PDeed").css("display", "block");
                    $("#choose_PDeed").hide();
                    $("#X-btn_PDeed").hide();
                    $("#done-btn_PDeed").hide();
                    $("#review-btn_PDeed").show();
                  }
                );
              } else if (doc[12].status == "reupload") {
                mainactionPDeed = "reupload";
                PDeedStatus = "reupload";
                PStatusArray[10].status = PDeedStatus;
                $("#choose_PDeed").show();
                $("#viewDocLink_PDeed").hide();
                $("#X-btn_PDeed").hide();
                $("#done-btn_PDeed").hide();
                $("#review-btn_PDeed").hide();
              } else if (doc[12].status == "done") {
                PDeedStatus = "done";
                mainactionPDeed = "upload";
                PStatusArray[10].status = PDeedStatus;
                $("#choose_PDeed").hide();
                $("#X-btn_PDeed").hide();
                $("#done-btn_PDeed").show();
                $("#review-btn_PDeed").hide();
              } else {
                PDeedStatus = "open";
                PStatusArray[10].status = PDeedStatus;
              }
            }
            if (res.data.inputFields.length > 0) {
              if (
                res.data.inputFields[0].AddressProofType == "CopyOfRegistry"
              ) {
                this.setState(
                  {
                    PAddressProofType_radio: "CopyOfRegistry",
                  },
                  () => {
                    $(".CopyOfRegistry").css("display", "flex");
                    $(".RentAgreement").hide();
                  }
                );
              } else if (
                res.data.inputFields[0].AddressProofType == "RentAgreement"
              ) {
                this.setState(
                  {
                    PAddressProofType_radio: "RentAgreement",
                  },
                  () => {
                    $(".RentAgreement").css("display", "flex");
                    $(".CopyOfRegistry").hide();
                  }
                );
              }
              if (
                res.data.inputFields[1].status == "review" ||
                res.data.inputFields[1].status == "done"
              ) {
                this.setState({
                  PbusinessNature: res.data.inputFields[1].Nature,
                });
                $(".uploadtextarea").attr("disabled", true);
              }
            }
            this.PcheckRemainingReuploads();
            this.checkhandlePProceedBtn();
          } else if (res.data.serviceType == "GSTR_PRL_PL_OP_Documents") {
            $(".selectcateselect").attr("disabled", true);
            this.setState(
              {
                dropDownValue: "GSTR_PRL_PL_OP_Documents",
                serviceType: "GSTR_PRL_PL_OP_Documents",
              },
              () => {
                if (this.state.dropDownValue == "GSTR_PRL_PL_OP_Documents") {
                  $("#gstr_default_Img").hide();
                  $("#gstr_SolePI").hide();
                  $("#gstr_P_LLP").hide();
                  $("#gstr_PL").show();
                  $("#gstr_HUF").hide();
                  $("#gstr_Society").hide();
                }
              }
            );
            if (res.data.informationStatus == "lock") {
              if (res.data.addDocInformation.length == 0) {
                $("#PLadditionalDocumentDiv").hide();
              } else {
                $("#PLAdddocumentbtn").hide();
              }
            }
            if (res.data.addDocInformation.length > 0) {
              this.setState(
                {
                  PLadditionalFiles: [...res.data.addDocInformation],
                  PLAdd_DocName: "File Name",
                  api_PLadditionalFiles: "Yes",
                },
                () => {}
              );
            } else if (
              res.data.addDocInformation.length == 0 &&
              res.data.informationStatus != "lock"
            ) {
              this.setState({
                PLAdd_DocName: "",
                PLadditionalFiles: [1],
                api_PLadditionalFiles: "No",
              });
            }
            if (res.data.documents.length > 0) {
              let doc = res.data.documents;
              if (doc[0].status == "remove") {
                mainactionPLAadhar1 = "upload";
                PLAadhar1Status = "remove";
                PLStatusArray[0].status = PLAadhar1Status;
                this.setState(
                  {
                    PLAadhar1DocLink: doc[0].AadhaarCardApplicant1,
                  },
                  () => {
                    $("#viewDocLink_PLAadhar1").css("display", "block");
                    $("#choose_PLAadhar1").hide();
                    $("#X-btn_PLAadhar1").show();
                    $("#done-btn_PLAadhar1").hide();
                    $("#review-btn_PLAadhar1").hide();
                  }
                );
              } else if (doc[0].status == "review") {
                mainactionPLAadhar1 = "upload";
                PLAadhar1Status = "review";
                PLStatusArray[0].status = PLAadhar1Status;
                this.setState(
                  {
                    PLAadhar1DocLink: doc[0].AadhaarCardApplicant1,
                  },
                  () => {
                    $("#viewDocLink_PLAadhar1").css("display", "block");
                    $("#choose_PLAadhar1").hide();
                    $("#X-btn_PLAadhar1").hide();
                    $("#done-btn_PLAadhar1").hide();
                    $("#review-btn_PLAadhar1").show();
                  }
                );
              } else if (doc[0].status == "reupload") {
                mainactionPLAadhar1 = "reupload";
                PLAadhar1Status = "reupload";
                PLStatusArray[0].status = PLAadhar1Status;
                $("#choose_PLAadhar1").show();
                $("#viewDocLink_PLAadhar1").hide();
                $("#X-btn_PLAadhar1").hide();
                $("#done-btn_PLAadhar1").hide();
                $("#review-btn_PLAadhar1").hide();
              } else if (doc[0].status == "done") {
                PLAadhar1Status = "done";
                mainactionPLAadhar1 = "upload";
                PLStatusArray[0].status = PLAadhar1Status;
                $("#choose_PLAadhar1").hide();
                $("#X-btn_PLAadhar1").hide();
                $("#done-btn_PLAadhar1").show();
                $("#review-btn_PLAadhar1").hide();
              } else {
                PLAadhar1Status = "open";
                PLStatusArray[0].status = PLAadhar1Status;
              }
              if (doc[1].status == "remove") {
                mainactionPLAadhar2 = "upload";
                PLAadhar2Status = "remove";
                PLStatusArray[1].status = PLAadhar2Status;
                this.setState(
                  {
                    PLAadhar2DocLink: doc[1].AadhaarCardApplicant2,
                  },
                  () => {
                    $("#viewDocLink_PLAadhar2").css("display", "block");
                    $("#choose_PLAadhar2").hide();
                    $("#X-btn_PLAadhar2").show();
                    $("#done-btn_PLAadhar2").hide();
                    $("#review-btn_PLAadhar2").hide();
                  }
                );
              } else if (doc[1].status == "review") {
                mainactionPLAadhar2 = "upload";
                PLAadhar2Status = "review";
                PLStatusArray[1].status = PLAadhar2Status;
                this.setState(
                  {
                    PLAadhar2DocLink: doc[1].AadhaarCardApplicant2,
                  },
                  () => {
                    $("#viewDocLink_PLAadhar2").css("display", "block");
                    $("#choose_PLAadhar2").hide();
                    $("#X-btn_PLAadhar2").hide();
                    $("#done-btn_PLAadhar2").hide();
                    $("#review-btn_PLAadhar2").show();
                  }
                );
              } else if (doc[1].status == "reupload") {
                mainactionPLAadhar2 = "reupload";
                PLAadhar2Status = "reupload";
                PLStatusArray[1].status = PLAadhar2Status;
                $("#choose_PLAadhar2").show();
                $("#viewDocLink_PLAadhar2").hide();
                $("#X-btn_PLAadhar2").hide();
                $("#done-btn_PLAadhar2").hide();
                $("#review-btn_PLAadhar2").hide();
              } else if (doc[1].status == "done") {
                PLAadhar2Status = "done";
                mainactionPLAadhar2 = "upload";
                PLStatusArray[1].status = PLAadhar2Status;
                $("#choose_PLAadhar2").hide();
                $("#X-btn_PLAadhar2").hide();
                $("#done-btn_PLAadhar2").show();
                $("#review-btn_PLAadhar2").hide();
              } else {
                PLAadhar2Status = "open";
                PLStatusArray[1].status = PLAadhar2Status;
              }
              if (doc[2].status == "remove") {
                mainactionPLPan1 = "upload";
                PLPan1Status = "remove";
                PLStatusArray[2].status = PLPan1Status;
                this.setState(
                  {
                    PLPan1DocLink: doc[2].PANCardApplicant1,
                  },
                  () => {
                    $("#viewDocLink_PLPan1").css("display", "block");
                    $("#choose_PLPan1").hide();
                    $("#X-btn_PLPan1").show();
                    $("#done-btn_PLPan1").hide();
                    $("#review-btn_PLPan1").hide();
                  }
                );
              } else if (doc[2].status == "review") {
                mainactionPLPan1 = "upload";
                PLPan1Status = "review";
                PLStatusArray[2].status = PLPan1Status;
                this.setState(
                  {
                    PLPan1DocLink: doc[2].PANCardApplicant1,
                  },
                  () => {
                    $("#viewDocLink_PLPan1").css("display", "block");
                    $("#choose_PLPan1").hide();
                    $("#X-btn_PLPan1").hide();
                    $("#done-btn_PLPan1").hide();
                    $("#review-btn_PLPan1").show();
                  }
                );
              } else if (doc[2].status == "reupload") {
                mainactionPLPan1 = "reupload";
                PLPan1Status = "reupload";
                PLStatusArray[2].status = PLPan1Status;
                $("#choose_PLPan1").show();
                $("#viewDocLink_PLPan1").hide();
                $("#X-btn_PLPan1").hide();
                $("#done-btn_PLPan1").hide();
                $("#review-btn_PLPan1").hide();
              } else if (doc[2].status == "done") {
                PLPan1Status = "done";
                mainactionPLPan1 = "upload";
                PLStatusArray[2].status = PLPan1Status;
                $("#choose_PLPan1").hide();
                $("#X-btn_PLPan1").hide();
                $("#done-btn_PLPan1").show();
                $("#review-btn_PLPan1").hide();
              } else {
                PLPan1Status = "open";
                PLStatusArray[2].status = PLPan1Status;
              }
              if (doc[3].status == "remove") {
                mainactionPLPan2 = "upload";
                PLPan2Status = "remove";
                PLStatusArray[3].status = PLPan2Status;
                this.setState(
                  {
                    PLPan2DocLink: doc[3].PANCardApplicant2,
                  },
                  () => {
                    $("#viewDocLink_PLPan2").css("display", "block");
                    $("#choose_PLPan2").hide();
                    $("#X-btn_PLPan2").show();
                    $("#done-btn_PLPan2").hide();
                    $("#review-btn_PLPan2").hide();
                  }
                );
              } else if (doc[3].status == "review") {
                mainactionPLPan2 = "upload";
                PLPan2Status = "review";
                PLStatusArray[3].status = PLPan2Status;
                this.setState(
                  {
                    PLPan2DocLink: doc[3].PANCardApplicant2,
                  },
                  () => {
                    $("#viewDocLink_PLPan2").css("display", "block");
                    $("#choose_PLPan2").hide();
                    $("#X-btn_PLPan2").hide();
                    $("#done-btn_PLPan2").hide();
                    $("#review-btn_PLPan2").show();
                  }
                );
              } else if (doc[3].status == "reupload") {
                mainactionPLPan2 = "reupload";
                PLPan2Status = "reupload";
                PLStatusArray[3].status = PLPan2Status;
                $("#choose_PLPan2").show();
                $("#viewDocLink_PLPan2").hide();
                $("#X-btn_PLPan2").hide();
                $("#done-btn_PLPan2").hide();
                $("#review-btn_PLPan2").hide();
              } else if (doc[3].status == "done") {
                PLPan2Status = "done";
                mainactionPLPan2 = "upload";
                PLStatusArray[3].status = PLPan2Status;
                $("#choose_PLPan2").hide();
                $("#X-btn_PLPan2").hide();
                $("#done-btn_PLPan2").show();
                $("#review-btn_PLPan2").hide();
              } else {
                PLPan2Status = "open";
                PLStatusArray[3].status = PLPan2Status;
              }
              if (doc[4].status == "remove") {
                mainactionPLPic1 = "upload";
                PLPic1Status = "remove";
                PLStatusArray[4].status = PLPic1Status;
                this.setState(
                  {
                    PLPic1DocLink: doc[4].ApplicantPhoto1,
                  },
                  () => {
                    $("#viewDocLink_PLPic1").css("display", "block");
                    $("#choose_PLPic1").hide();
                    $("#X-btn_PLPic1").show();
                    $("#done-btn_PLPic1").hide();
                    $("#review-btn_PLPic1").hide();
                  }
                );
              } else if (doc[4].status == "review") {
                mainactionPLPic1 = "upload";
                PLPic1Status = "review";
                PLStatusArray[4].status = PLPic1Status;
                this.setState(
                  {
                    PLPic1DocLink: doc[4].ApplicantPhoto1,
                  },
                  () => {
                    $("#viewDocLink_PLPic1").css("display", "block");
                    $("#choose_PLPic1").hide();
                    $("#X-btn_PLPic1").hide();
                    $("#done-btn_PLPic1").hide();
                    $("#review-btn_PLPic1").show();
                  }
                );
              } else if (doc[4].status == "reupload") {
                mainactionPLPic1 = "reupload";
                PLPic1Status = "reupload";
                PLStatusArray[4].status = PLPic1Status;
                $("#choose_PLPic1").show();
                $("#viewDocLink_PLPic1").hide();
                $("#X-btn_PLPic1").hide();
                $("#done-btn_PLPic1").hide();
                $("#review-btn_PLPic1").hide();
              } else if (doc[4].status == "done") {
                PLPic1Status = "done";
                mainactionPLPic1 = "upload";
                PLStatusArray[4].status = PLPic1Status;
                $("#choose_PLPic1").hide();
                $("#X-btn_PLPic1").hide();
                $("#done-btn_PLPic1").show();
                $("#review-btn_PLPic1").hide();
              } else {
                PLPic1Status = "open";
                PLStatusArray[4].status = PLPic1Status;
              }
              if (doc[5].status == "remove") {
                mainactionPLPic2 = "upload";
                PLPic2Status = "remove";
                PLStatusArray[5].status = PLPic2Status;
                this.setState(
                  {
                    PLPic2DocLink: doc[5].ApplicantPhoto2,
                  },
                  () => {
                    $("#viewDocLink_PLPic2").css("display", "block");
                    $("#choose_PLPic2").hide();
                    $("#X-btn_PLPic2").show();
                    $("#done-btn_PLPic2").hide();
                    $("#review-btn_PLPic2").hide();
                  }
                );
              } else if (doc[5].status == "review") {
                mainactionPLPic2 = "upload";
                PLPic2Status = "review";
                PLStatusArray[5].status = PLPic2Status;
                this.setState(
                  {
                    PLPic2DocLink: doc[5].ApplicantPhoto2,
                  },
                  () => {
                    $("#viewDocLink_PLPic2").css("display", "block");
                    $("#choose_PLPic2").hide();
                    $("#X-btn_PLPic2").hide();
                    $("#done-btn_PLPic2").hide();
                    $("#review-btn_PLPic2").show();
                  }
                );
              } else if (doc[5].status == "reupload") {
                mainactionPLPic2 = "reupload";
                PLPic2Status = "reupload";
                PLStatusArray[5].status = PLPic2Status;
                $("#choose_PLPic2").show();
                $("#viewDocLink_PLPic2").hide();
                $("#X-btn_PLPic2").hide();
                $("#done-btn_PLPic2").hide();
                $("#review-btn_PLPic2").hide();
              } else if (doc[5].status == "done") {
                PLPic2Status = "done";
                mainactionPLPic2 = "upload";
                PLStatusArray[5].status = PLPic2Status;
                $("#choose_PLPic2").hide();
                $("#X-btn_PLPic2").hide();
                $("#done-btn_PLPic2").show();
                $("#review-btn_PLPic2").hide();
              } else {
                PLPic2Status = "open";
                PLStatusArray[5].status = PLPic2Status;
              }
              if (doc[6].status == "remove") {
                mainactionPLPan3 = "upload";
                PLPan3Status = "remove";
                PLStatusArray[6].status = PLPan3Status;
                this.setState(
                  {
                    PLPan3DocLink: doc[6].PANCardCompany,
                  },
                  () => {
                    $("#viewDocLink_PLPan3").css("display", "block");
                    $("#choose_PLPan3").hide();
                    $("#X-btn_PLPan3").show();
                    $("#done-btn_PLPan3").hide();
                    $("#review-btn_PLPan3").hide();
                  }
                );
              } else if (doc[6].status == "review") {
                mainactionPLPan3 = "upload";
                PLPan3Status = "review";
                PLStatusArray[6].status = PLPan3Status;
                this.setState(
                  {
                    PLPan3DocLink: doc[6].PANCardCompany,
                  },
                  () => {
                    $("#viewDocLink_PLPan3").css("display", "block");
                    $("#choose_PLPan3").hide();
                    $("#X-btn_PLPan3").hide();
                    $("#done-btn_PLPan3").hide();
                    $("#review-btn_PLPan3").show();
                  }
                );
              } else if (doc[6].status == "reupload") {
                mainactionPLPan3 = "reupload";
                PLPan3Status = "reupload";
                PLStatusArray[6].status = PLPan3Status;
                $("#choose_PLPan3").show();
                $("#viewDocLink_PLPan3").hide();
                $("#X-btn_PLPan3").hide();
                $("#done-btn_PLPan3").hide();
                $("#review-btn_PLPan3").hide();
              } else if (doc[6].status == "done") {
                PLPan3Status = "done";
                mainactionPLPan3 = "upload";
                PLStatusArray[6].status = PLPan3Status;
                $("#choose_PLPan3").hide();
                $("#X-btn_PLPan3").hide();
                $("#done-btn_PLPan3").show();
                $("#review-btn_PLPan3").hide();
              } else {
                PLPan3Status = "open";
                PLStatusArray[6].status = PLPan3Status;
              }
              if (doc[7].status == "remove") {
                mainactionPLCI = "upload";
                PLCIStatus = "remove";
                PLStatusArray[7].status = PLCIStatus;
                this.setState(
                  {
                    PLCIDocLink: doc[7].CertificateOfIncorporation,
                  },
                  () => {
                    $("#viewDocLink_PLCI").css("display", "block");
                    $("#choose_PLCI").hide();
                    $("#X-btn_PLCI").show();
                    $("#done-btn_PLCI").hide();
                    $("#review-btn_PLCI").hide();
                  }
                );
              } else if (doc[7].status == "review") {
                mainactionPLCI = "upload";
                PLCIStatus = "review";
                PLStatusArray[7].status = PLCIStatus;
                this.setState(
                  {
                    PLCIDocLink: doc[7].CertificateOfIncorporation,
                  },
                  () => {
                    $("#viewDocLink_PLCI").css("display", "block");
                    $("#choose_PLCI").hide();
                    $("#X-btn_PLCI").hide();
                    $("#done-btn_PLCI").hide();
                    $("#review-btn_PLCI").show();
                  }
                );
              } else if (doc[7].status == "reupload") {
                mainactionPLCI = "reupload";
                PLCIStatus = "reupload";
                PLStatusArray[7].status = PLCIStatus;
                $("#choose_PLCI").show();
                $("#viewDocLink_PLCI").hide();
                $("#X-btn_PLCI").hide();
                $("#done-btn_PLCI").hide();
                $("#review-btn_PLCI").hide();
              } else if (doc[7].status == "done") {
                PLCIStatus = "done";
                mainactionPLCI = "upload";
                PLStatusArray[7].status = PLCIStatus;
                $("#choose_PLCI").hide();
                $("#X-btn_PLCI").hide();
                $("#done-btn_PLCI").show();
                $("#review-btn_PLCI").hide();
              } else {
                PLCIStatus = "open";
                PLStatusArray[7].status = PLCIStatus;
              }
              if (doc[8].status == "remove") {
                mainactionPLMOA = "upload";
                PLMOAStatus = "remove";
                PLStatusArray[8].status = PLMOAStatus;
                this.setState(
                  {
                    PLMOADocLink: doc[8].MOA,
                  },
                  () => {
                    $("#viewDocLink_PLMOA").css("display", "block");
                    $("#choose_PLMOA").hide();
                    $("#X-btn_PLMOA").show();
                    $("#done-btn_PLMOA").hide();
                    $("#review-btn_PLMOA").hide();
                  }
                );
              } else if (doc[8].status == "review") {
                mainactionPLMOA = "upload";
                PLMOAStatus = "review";
                PLStatusArray[8].status = PLMOAStatus;
                this.setState(
                  {
                    PLMOADocLink: doc[8].MOA,
                  },
                  () => {
                    $("#viewDocLink_PLMOA").css("display", "block");
                    $("#choose_PLMOA").hide();
                    $("#X-btn_PLMOA").hide();
                    $("#done-btn_PLMOA").hide();
                    $("#review-btn_PLMOA").show();
                  }
                );
              } else if (doc[8].status == "reupload") {
                mainactionPLMOA = "reupload";
                PLMOAStatus = "reupload";
                PLStatusArray[8].status = PLMOAStatus;
                $("#choose_PLMOA").show();
                $("#viewDocLink_PLMOA").hide();
                $("#X-btn_PLMOA").hide();
                $("#done-btn_PLMOA").hide();
                $("#review-btn_PLMOA").hide();
              } else if (doc[8].status == "done") {
                PLMOAStatus = "done";
                mainactionPLMOA = "upload";
                PLStatusArray[8].status = PLMOAStatus;
                $("#choose_PLMOA").hide();
                $("#X-btn_PLMOA").hide();
                $("#done-btn_PLMOA").show();
                $("#review-btn_PLMOA").hide();
              } else {
                PLMOAStatus = "open";
                PLStatusArray[8].status = PLMOAStatus;
              }
              if (doc[9].status == "remove") {
                mainactionPLAOA = "upload";
                PLAOAStatus = "remove";
                PLStatusArray[9].status = PLAOAStatus;
                this.setState(
                  {
                    PLAOADocLink: doc[9].AOA,
                  },
                  () => {
                    $("#viewDocLink_PLAOA").css("display", "block");
                    $("#choose_PLAOA").hide();
                    $("#X-btn_PLAOA").show();
                    $("#done-btn_PLAOA").hide();
                    $("#review-btn_PLAOA").hide();
                  }
                );
              } else if (doc[9].status == "review") {
                mainactionPLAOA = "upload";
                PLAOAStatus = "review";
                PLStatusArray[9].status = PLAOAStatus;
                this.setState(
                  {
                    PLAOADocLink: doc[9].AOA,
                  },
                  () => {
                    $("#viewDocLink_PLAOA").css("display", "block");
                    $("#choose_PLAOA").hide();
                    $("#X-btn_PLAOA").hide();
                    $("#done-btn_PLAOA").hide();
                    $("#review-btn_PLAOA").show();
                  }
                );
              } else if (doc[9].status == "reupload") {
                mainactionPLAOA = "reupload";
                PLAOAStatus = "reupload";
                PLStatusArray[9].status = PLAOAStatus;
                $("#choose_PLAOA").show();
                $("#viewDocLink_PLAOA").hide();
                $("#X-btn_PLAOA").hide();
                $("#done-btn_PLAOA").hide();
                $("#review-btn_PLAOA").hide();
              } else if (doc[9].status == "done") {
                PLAOAStatus = "done";
                mainactionPLAOA = "upload";
                PLStatusArray[9].status = PLAOAStatus;
                $("#choose_PLAOA").hide();
                $("#X-btn_PLAOA").hide();
                $("#done-btn_PLAOA").show();
                $("#review-btn_PLAOA").hide();
              } else {
                PLAOAStatus = "open";
                PLStatusArray[9].status = PLAOAStatus;
              }
              if (doc[10].status == "remove") {
                mainactionPLBR = "upload";
                PLBRStatus = "remove";
                PLStatusArray[10].status = PLBRStatus;
                this.setState(
                  {
                    PLBRDocLink: doc[10].BoardResolution,
                  },
                  () => {
                    $("#viewDocLink_PLBR").css("display", "block");
                    $("#choose_PLBR").hide();
                    $("#X-btn_PLBR").show();
                    $("#done-btn_PLBR").hide();
                    $("#review-btn_PLBR").hide();
                  }
                );
              } else if (doc[10].status == "review") {
                mainactionPLBR = "upload";
                PLBRStatus = "review";
                PLStatusArray[10].status = PLBRStatus;
                this.setState(
                  {
                    PLBRDocLink: doc[10].BoardResolution,
                  },
                  () => {
                    $("#viewDocLink_PLBR").css("display", "block");
                    $("#choose_PLBR").hide();
                    $("#X-btn_PLBR").hide();
                    $("#done-btn_PLBR").hide();
                    $("#review-btn_PLBR").show();
                  }
                );
              } else if (doc[10].status == "reupload") {
                mainactionPLBR = "reupload";
                PLBRStatus = "reupload";
                PLStatusArray[10].status = PLBRStatus;
                $("#choose_PLBR").show();
                $("#viewDocLink_PLBR").hide();
                $("#X-btn_PLBR").hide();
                $("#done-btn_PLBR").hide();
                $("#review-btn_PLBR").hide();
              } else if (doc[10].status == "done") {
                PLBRStatus = "done";
                mainactionPLBR = "upload";
                PLStatusArray[10].status = PLBRStatus;
                $("#choose_PLBR").hide();
                $("#X-btn_PLBR").hide();
                $("#done-btn_PLBR").show();
                $("#review-btn_PLBR").hide();
              } else {
                PLBRStatus = "open";
                PLStatusArray[10].status = PLBRStatus;
              }
              if (doc[11].status == "remove") {
                mainactionPLBP = "upload";
                PLBPStatus = "remove";
                PLStatusArray[11].status = PLBPStatus;
                this.setState(
                  {
                    PLBPDocLink: doc[11].BankProof,
                  },
                  () => {
                    $("#viewDocLink_PLBP").css("display", "block");
                    $("#choose_PLBP").hide();
                    $("#X-btn_PLBP").show();
                    $("#done-btn_PLBP").hide();
                    $("#review-btn_PLBP").hide();
                  }
                );
              } else if (doc[11].status == "review") {
                mainactionPLBP = "upload";
                PLBPStatus = "review";
                PLStatusArray[11].status = PLBPStatus;
                this.setState(
                  {
                    PLBPDocLink: doc[11].BankProof,
                  },
                  () => {
                    $("#viewDocLink_PLBP").css("display", "block");
                    $("#choose_PLBP").hide();
                    $("#X-btn_PLBP").hide();
                    $("#done-btn_PLBP").hide();
                    $("#review-btn_PLBP").show();
                  }
                );
              } else if (doc[11].status == "reupload") {
                mainactionPLBP = "reupload";
                PLBPStatus = "reupload";
                PLStatusArray[11].status = PLBPStatus;
                $("#choose_PLBP").show();
                $("#viewDocLink_PLBP").hide();
                $("#X-btn_PLBP").hide();
                $("#done-btn_PLBP").hide();
                $("#review-btn_PLBP").hide();
              } else if (doc[11].status == "done") {
                PLBPStatus = "done";
                mainactionPLBP = "upload";
                PLStatusArray[11].status = PLBPStatus;
                $("#choose_PLBP").hide();
                $("#X-btn_PLBP").hide();
                $("#done-btn_PLBP").show();
                $("#review-btn_PLBP").hide();
              } else {
                PLBPStatus = "open";
                PLStatusArray[11].status = PLBPStatus;
              }
              if (doc[12].status == "remove") {
                mainactionPLGumasta = "upload";
                PLGumastaStatus = "remove";
                PLStatusArray[12].status = PLGumastaStatus;
                this.setState(
                  {
                    PLGumastaDocLink: doc[12].Gumasta,
                  },
                  () => {
                    $("#viewDocLink_PLGumasta").css("display", "block");
                    $("#choose_PLGumasta").hide();
                    $("#X-btn_PLGumasta").show();
                    $("#done-btn_PLGumasta").hide();
                    $("#review-btn_PLGumasta").hide();
                  }
                );
              } else if (doc[12].status == "review") {
                mainactionPLGumasta = "upload";
                PLGumastaStatus = "review";
                PLStatusArray[12].status = PLGumastaStatus;
                this.setState(
                  {
                    PLGumastaDocLink: doc[12].Gumasta,
                  },
                  () => {
                    $("#viewDocLink_PLGumasta").css("display", "block");
                    $("#choose_PLGumasta").hide();
                    $("#X-btn_PLGumasta").hide();
                    $("#done-btn_PLGumasta").hide();
                    $("#review-btn_PLGumasta").show();
                  }
                );
              } else if (doc[12].status == "reupload") {
                mainactionPLGumasta = "reupload";
                PLGumastaStatus = "reupload";
                PLStatusArray[12].status = PLGumastaStatus;
                $("#choose_PLGumasta").show();
                $("#viewDocLink_PLGumasta").hide();
                $("#X-btn_PLGumasta").hide();
                $("#done-btn_PLGumasta").hide();
                $("#review-btn_PLGumasta").hide();
              } else if (doc[12].status == "done") {
                PLGumastaStatus = "done";
                mainactionPLGumasta = "upload";
                PLStatusArray[12].status = PLGumastaStatus;
                $("#choose_PLGumasta").hide();
                $("#X-btn_PLGumasta").hide();
                $("#done-btn_PLGumasta").show();
                $("#review-btn_PLGumasta").hide();
              } else {
                PLGumastaStatus = "open";
                PLStatusArray[12].status = PLGumastaStatus;
              }
              if (doc[13].status == "remove") {
                mainactionPLTradeLicence = "upload";
                PLTradeLicenceStatus = "remove";
                PLStatusArray[13].status = PLTradeLicenceStatus;
                this.setState(
                  {
                    PLTradeLicenceDocLink: doc[13].TradeLicence,
                  },
                  () => {
                    $("#viewDocLink_PLTradeLicence").css("display", "block");
                    $("#choose_PLTradeLicence").hide();
                    $("#X-btn_PLTradeLicence").show();
                    $("#done-btn_PLTradeLicence").hide();
                    $("#review-btn_PLTradeLicence").hide();
                  }
                );
              } else if (doc[13].status == "review") {
                mainactionPLTradeLicence = "upload";
                PLTradeLicenceStatus = "review";
                PLStatusArray[13].status = PLTradeLicenceStatus;
                this.setState(
                  {
                    PLTradeLicenceDocLink: doc[13].TradeLicence,
                  },
                  () => {
                    $("#viewDocLink_PLTradeLicence").css("display", "block");
                    $("#choose_PLTradeLicence").hide();
                    $("#X-btn_PLTradeLicence").hide();
                    $("#done-btn_PLTradeLicence").hide();
                    $("#review-btn_PLTradeLicence").show();
                  }
                );
              } else if (doc[13].status == "reupload") {
                mainactionPLTradeLicence = "reupload";
                PLTradeLicenceStatus = "reupload";
                PLStatusArray[13].status = PLTradeLicenceStatus;
                $("#choose_PLTradeLicence").show();
                $("#viewDocLink_PLTradeLicence").hide();
                $("#X-btn_PLTradeLicence").hide();
                $("#done-btn_PLTradeLicence").hide();
                $("#review-btn_PLTradeLicence").hide();
              } else if (doc[13].status == "done") {
                PLTradeLicenceStatus = "done";
                mainactionPLTradeLicence = "upload";
                PLStatusArray[13].status = PLTradeLicenceStatus;
                $("#choose_PLTradeLicence").hide();
                $("#X-btn_PLTradeLicence").hide();
                $("#done-btn_PLTradeLicence").show();
                $("#review-btn_PLTradeLicence").hide();
              } else {
                PLTradeLicenceStatus = "open";
                PLStatusArray[13].status = PLTradeLicenceStatus;
              }
              if (doc[14].status == "remove") {
                mainactionPLCopyOfRegistry = "upload";
                PLCopyOfRegistryStatus = "remove";
                PLOptStatusArray[0].status = PLCopyOfRegistryStatus;
                this.setState(
                  {
                    PLCopyOfRegistryDocLink: doc[14].CopyOfRegistry,
                  },
                  () => {
                    $("#viewDocLink_PLCopyOfRegistry").css("display", "block");
                    $("#choose_PLCopyOfRegistry").hide();
                    $("#X-btn_PLCopyOfRegistry").show();
                    $("#done-btn_PLCopyOfRegistry").hide();
                    $("#review-btn_PLCopyOfRegistry").hide();
                  }
                );
              } else if (doc[14].status == "review") {
                mainactionPLCopyOfRegistry = "upload";
                PLCopyOfRegistryStatus = "review";
                PLOptStatusArray[0].status = PLCopyOfRegistryStatus;
                this.setState(
                  {
                    PLCopyOfRegistryDocLink: doc[14].CopyOfRegistry,
                  },
                  () => {
                    $("#viewDocLink_PLCopyOfRegistry").css("display", "block");
                    $("#choose_PLCopyOfRegistry").hide();
                    $("#X-btn_PLCopyOfRegistry").hide();
                    $("#done-btn_PLCopyOfRegistry").hide();
                    $("#review-btn_PLCopyOfRegistry").show();
                  }
                );
              } else if (doc[14].status == "reupload") {
                mainactionPLCopyOfRegistry = "reupload";
                PLCopyOfRegistryStatus = "reupload";
                PLOptStatusArray[0].status = PLCopyOfRegistryStatus;
                $("#choose_PLCopyOfRegistry").show();
                $("#viewDocLink_PLCopyOfRegistry").hide();
                $("#X-btn_PLCopyOfRegistry").hide();
                $("#done-btn_PLCopyOfRegistry").hide();
                $("#review-btn_PLCopyOfRegistry").hide();
              } else if (doc[14].status == "done") {
                PLCopyOfRegistryStatus = "done";
                mainactionPLCopyOfRegistry = "upload";
                PLOptStatusArray[0].status = PLCopyOfRegistryStatus;
                $("#choose_PLCopyOfRegistry").hide();
                $("#X-btn_PLCopyOfRegistry").hide();
                $("#done-btn_PLCopyOfRegistry").show();
                $("#review-btn_PLCopyOfRegistry").hide();
              } else {
                PLCopyOfRegistryStatus = "open";
                PLOptStatusArray[0].status = PLCopyOfRegistryStatus;
              }
              if (doc[15].status == "remove") {
                mainactionPLRentAgreement = "upload";
                PLRentAgreementStatus = "remove";
                PLOptStatusArray[1].status = PLRentAgreementStatus;
                this.setState(
                  {
                    PLRentAgreementDocLink: doc[15].RentAgreement,
                  },
                  () => {
                    $("#viewDocLink_PLRentAgreement").css("display", "block");
                    $("#choose_PLRentAgreement").hide();
                    $("#X-btn_PLRentAgreement").show();
                    $("#done-btn_PLRentAgreement").hide();
                    $("#review-btn_PLRentAgreement").hide();
                  }
                );
              } else if (doc[15].status == "review") {
                mainactionPLRentAgreement = "upload";
                PLRentAgreementStatus = "review";
                PLOptStatusArray[1].status = PLRentAgreementStatus;
                this.setState(
                  {
                    PLRentAgreementDocLink: doc[15].RentAgreement,
                  },
                  () => {
                    $("#viewDocLink_PLRentAgreement").css("display", "block");
                    $("#choose_PLRentAgreement").hide();
                    $("#X-btn_PLRentAgreement").hide();
                    $("#done-btn_PLRentAgreement").hide();
                    $("#review-btn_PLRentAgreement").show();
                  }
                );
              } else if (doc[15].status == "reupload") {
                mainactionPLRentAgreement = "reupload";
                PLRentAgreementStatus = "reupload";
                PLOptStatusArray[1].status = PLRentAgreementStatus;
                $("#choose_PLRentAgreement").show();
                $("#viewDocLink_PLRentAgreement").hide();
                $("#X-btn_PLRentAgreement").hide();
                $("#done-btn_PLRentAgreement").hide();
                $("#review-btn_PLRentAgreement").hide();
              } else if (doc[15].status == "done") {
                PLRentAgreementStatus = "done";
                mainactionPLRentAgreement = "upload";
                PLOptStatusArray[1].status = PLRentAgreementStatus;
                $("#choose_PLRentAgreement").hide();
                $("#X-btn_PLRentAgreement").hide();
                $("#done-btn_PLRentAgreement").show();
                $("#review-btn_PLRentAgreement").hide();
              } else {
                PLRentAgreementStatus = "open";
                PLOptStatusArray[1].status = PLRentAgreementStatus;
              }
              if (doc[16].status == "remove") {
                mainactionPLPR = "upload";
                PLPRStatus = "remove";
                PLStatusArray[14].status = PLPRStatus;
                this.setState(
                  {
                    PLPRDocLink: doc[16].PropertyTaxReceipt,
                  },
                  () => {
                    $("#viewDocLink_PLPR").css("display", "block");
                    $("#choose_PLPR").hide();
                    $("#X-btn_PLPR").show();
                    $("#done-btn_PLPR").hide();
                    $("#review-btn_PLPR").hide();
                  }
                );
              } else if (doc[16].status == "review") {
                mainactionPLPR = "upload";
                PLPRStatus = "review";
                PLStatusArray[14].status = PLPRStatus;
                this.setState(
                  {
                    PLPRDocLink: doc[16].PropertyTaxReceipt,
                  },
                  () => {
                    $("#viewDocLink_PLPR").css("display", "block");
                    $("#choose_PLPR").hide();
                    $("#X-btn_PLPR").hide();
                    $("#done-btn_PLPR").hide();
                    $("#review-btn_PLPR").show();
                  }
                );
              } else if (doc[16].status == "reupload") {
                mainactionPLPR = "reupload";
                PLPRStatus = "reupload";
                PLStatusArray[14].status = PLPRStatus;
                $("#choose_PLPR").show();
                $("#viewDocLink_PLPR").hide();
                $("#X-btn_PLPR").hide();
                $("#done-btn_PLPR").hide();
                $("#review-btn_PLPR").hide();
              } else if (doc[16].status == "done") {
                PLPRStatus = "done";
                mainactionPLPR = "upload";
                PLStatusArray[14].status = PLPRStatus;
                $("#choose_PLPR").hide();
                $("#X-btn_PLPR").hide();
                $("#done-btn_PLPR").show();
                $("#review-btn_PLPR").hide();
              } else {
                PLPRStatus = "open";
                PLStatusArray[14].status = PLPRStatus;
              }
            }
            if (res.data.inputFields.length > 0) {
              if (
                res.data.inputFields[0].AddressProofType == "CopyOfRegistry"
              ) {
                this.setState(
                  {
                    PLAddressProofType_radio: "CopyOfRegistry",
                  },
                  () => {
                    $(".CopyOfRegistry").css("display", "flex");
                    $(".RentAgreement").hide();
                  }
                );
              } else if (
                res.data.inputFields[0].AddressProofType == "RentAgreement"
              ) {
                this.setState(
                  {
                    PLAddressProofType_radio: "RentAgreement",
                  },
                  () => {
                    $(".RentAgreement").css("display", "flex");
                    $(".CopyOfRegistry").hide();
                  }
                );
              }
              if (
                res.data.inputFields[1].status == "review" ||
                res.data.inputFields[1].status == "done"
              ) {
                this.setState({
                  PLbusinessNature: res.data.inputFields[1].Nature,
                });
                $(".uploadtextarea").attr("disabled", true);
              }
            }
            this.PLcheckRemainingReuploads();
            this.checkhandlePLProceedBtn();
          } else if (res.data.serviceType == "GSTR_HUF_Documents") {
            $(".selectcateselect").attr("disabled", true);
            this.setState(
              {
                dropDownValue: "GSTR_HUF_Documents",
                serviceType: "GSTR_HUF_Documents",
              },
              () => {
                if (this.state.dropDownValue == "GSTR_HUF_Documents") {
                  $("#gstr_default_Img").hide();
                  $("#gstr_SolePI").hide();
                  $("#gstr_P_LLP").hide();
                  $("#gstr_PL").hide();
                  $("#gstr_HUF").show();
                  $("#gstr_Society").hide();
                }
              }
            );
            if (res.data.informationStatus == "lock") {
              if (res.data.addDocInformation.length == 0) {
                $("#HUFadditionalDocumentDiv").hide();
              } else {
                $("#HUFAdddocumentbtn").hide();
              }
            }
            if (res.data.addDocInformation.length > 0) {
              this.setState(
                {
                  HUFadditionalFiles: [...res.data.addDocInformation],
                  HUFAdd_DocName: "File Name",
                  api_HUFadditionalFiles: "Yes",
                },
                () => {}
              );
            } else if (
              res.data.addDocInformation.length == 0 &&
              res.data.informationStatus != "lock"
            ) {
              this.setState({
                HUFAdd_DocName: "",
                HUFadditionalFiles: [1],
                api_HUFadditionalFiles: "No",
              });
            }
            if (res.data.documents.length > 0) {
              let doc = res.data.documents;
              if (doc[0].status == "remove") {
                mainactionHUFAadhar = "upload";
                HUFAadharStatus = "remove";
                HUFStatusArray[0].status = HUFAadharStatus;
                this.setState(
                  {
                    HUFAadharDocLink: doc[0].AadhaarCardKarta,
                  },
                  () => {
                    $("#viewDocLink_HUFAadhar").css("display", "block");
                    $("#choose_HUFAadhar").hide();
                    $("#X-btn_HUFAadhar").show();
                    $("#done-btn_HUFAadhar").hide();
                    $("#review-btn_HUFAadhar").hide();
                  }
                );
              } else if (doc[0].status == "review") {
                mainactionHUFAadhar = "upload";
                HUFAadharStatus = "review";
                HUFStatusArray[0].status = HUFAadharStatus;
                this.setState(
                  {
                    HUFAadharDocLink: doc[0].AadhaarCardKarta,
                  },
                  () => {
                    $("#viewDocLink_HUFAadhar").css("display", "block");
                    $("#choose_HUFAadhar").hide();
                    $("#X-btn_HUFAadhar").hide();
                    $("#done-btn_HUFAadhar").hide();
                    $("#review-btn_HUFAadhar").show();
                  }
                );
              } else if (doc[0].status == "reupload") {
                mainactionHUFAadhar = "reupload";
                HUFAadharStatus = "reupload";
                HUFStatusArray[0].status = HUFAadharStatus;
                $("#choose_HUFAadhar").show();
                $("#viewDocLink_HUFAadhar").hide();
                $("#X-btn_HUFAadhar").hide();
                $("#done-btn_HUFAadhar").hide();
                $("#review-btn_HUFAadhar").hide();
              } else if (doc[0].status == "done") {
                HUFAadharStatus = "done";
                mainactionHUFAadhar = "upload";
                HUFStatusArray[0].status = HUFAadharStatus;
                $("#choose_HUFAadhar").hide();
                $("#X-btn_HUFAadhar").hide();
                $("#done-btn_HUFAadhar").show();
                $("#review-btn_HUFAadhar").hide();
              } else {
                HUFAadharStatus = "open";
                HUFStatusArray[0].status = HUFAadharStatus;
              }
              if (doc[1].status == "remove") {
                mainactionHUFPan1 = "upload";
                HUFPan1Status = "remove";
                HUFStatusArray[1].status = HUFPan1Status;
                this.setState(
                  {
                    HUFPan1DocLink: doc[1].PANCardKarta,
                  },
                  () => {
                    $("#viewDocLink_HUFPan1").css("display", "block");
                    $("#choose_HUFPan1").hide();
                    $("#X-btn_HUFPan1").show();
                    $("#done-btn_HUFPan1").hide();
                    $("#review-btn_HUFPan1").hide();
                  }
                );
              } else if (doc[1].status == "review") {
                mainactionHUFPan1 = "upload";
                HUFPan1Status = "review";
                HUFStatusArray[1].status = HUFPan1Status;
                this.setState(
                  {
                    HUFPan1DocLink: doc[1].PANCardKarta,
                  },
                  () => {
                    $("#viewDocLink_HUFPan1").css("display", "block");
                    $("#choose_HUFPan1").hide();
                    $("#X-btn_HUFPan1").hide();
                    $("#done-btn_HUFPan1").hide();
                    $("#review-btn_HUFPan1").show();
                  }
                );
              } else if (doc[1].status == "reupload") {
                mainactionHUFPan1 = "reupload";
                HUFPan1Status = "reupload";
                HUFStatusArray[1].status = HUFPan1Status;
                $("#choose_HUFPan1").show();
                $("#viewDocLink_HUFPan1").hide();
                $("#X-btn_HUFPan1").hide();
                $("#done-btn_HUFPan1").hide();
                $("#review-btn_HUFPan1").hide();
              } else if (doc[1].status == "done") {
                HUFPan1Status = "done";
                mainactionHUFPan1 = "upload";
                HUFStatusArray[1].status = HUFPan1Status;
                $("#choose_HUFPan1").hide();
                $("#X-btn_HUFPan1").hide();
                $("#done-btn_HUFPan1").show();
                $("#review-btn_HUFPan1").hide();
              } else {
                HUFPan1Status = "open";
                HUFStatusArray[1].status = HUFPan1Status;
              }
              if (doc[2].status == "remove") {
                mainactionHUFPic = "upload";
                HUFPicStatus = "remove";
                HUFStatusArray[2].status = HUFPicStatus;
                this.setState(
                  {
                    HUFPicDocLink: doc[2].ApplicantPhoto1,
                  },
                  () => {
                    $("#viewDocLink_HUFPic").css("display", "block");
                    $("#choose_HUFPic").hide();
                    $("#X-btn_HUFPic").show();
                    $("#done-btn_HUFPic").hide();
                    $("#review-btn_HUFPic").hide();
                  }
                );
              } else if (doc[2].status == "review") {
                mainactionHUFPic = "upload";
                HUFPicStatus = "review";
                HUFStatusArray[2].status = HUFPicStatus;
                this.setState(
                  {
                    HUFPicDocLink: doc[2].ApplicantPhoto1,
                  },
                  () => {
                    $("#viewDocLink_HUFPic").css("display", "block");
                    $("#choose_HUFPic").hide();
                    $("#X-btn_HUFPic").hide();
                    $("#done-btn_HUFPic").hide();
                    $("#review-btn_HUFPic").show();
                  }
                );
              } else if (doc[2].status == "reupload") {
                mainactionHUFPic = "reupload";
                HUFPicStatus = "reupload";
                HUFStatusArray[2].status = HUFPicStatus;
                $("#choose_HUFPic").show();
                $("#viewDocLink_HUFPic").hide();
                $("#X-btn_HUFPic").hide();
                $("#done-btn_HUFPic").hide();
                $("#review-btn_HUFPic").hide();
              } else if (doc[2].status == "done") {
                HUFPicStatus = "done";
                mainactionHUFPic = "upload";
                HUFStatusArray[2].status = HUFPicStatus;
                $("#choose_HUFPic").hide();
                $("#X-btn_HUFPic").hide();
                $("#done-btn_HUFPic").show();
                $("#review-btn_HUFPic").hide();
              } else {
                HUFPicStatus = "open";
                HUFStatusArray[2].status = HUFPicStatus;
              }
              if (doc[3].status == "remove") {
                mainactionHUFPan2 = "upload";
                HUFPan2Status = "remove";
                HUFStatusArray[3].status = HUFPan2Status;
                this.setState(
                  {
                    HUFPan2DocLink: doc[3].PANCardHUF,
                  },
                  () => {
                    $("#viewDocLink_HUFPan2").css("display", "block");
                    $("#choose_HUFPan2").hide();
                    $("#X-btn_HUFPan2").show();
                    $("#done-btn_HUFPan2").hide();
                    $("#review-btn_HUFPan2").hide();
                  }
                );
              } else if (doc[3].status == "review") {
                mainactionHUFPan2 = "upload";
                HUFPan2Status = "review";
                HUFStatusArray[3].status = HUFPan2Status;
                this.setState(
                  {
                    HUFPan2DocLink: doc[3].PANCardHUF,
                  },
                  () => {
                    $("#viewDocLink_HUFPan2").css("display", "block");
                    $("#choose_HUFPan2").hide();
                    $("#X-btn_HUFPan2").hide();
                    $("#done-btn_HUFPan2").hide();
                    $("#review-btn_HUFPan2").show();
                  }
                );
              } else if (doc[3].status == "reupload") {
                mainactionHUFPan2 = "reupload";
                HUFPan2Status = "reupload";
                HUFStatusArray[3].status = HUFPan2Status;
                $("#choose_HUFPan2").show();
                $("#viewDocLink_HUFPan2").hide();
                $("#X-btn_HUFPan2").hide();
                $("#done-btn_HUFPan2").hide();
                $("#review-btn_HUFPan2").hide();
              } else if (doc[3].status == "done") {
                HUFPan2Status = "done";
                mainactionHUFPan2 = "upload";
                HUFStatusArray[3].status = HUFPan2Status;
                $("#choose_HUFPan2").hide();
                $("#X-btn_HUFPan2").hide();
                $("#done-btn_HUFPan2").show();
                $("#review-btn_HUFPan2").hide();
              } else {
                HUFPan2Status = "open";
                HUFStatusArray[3].status = HUFPan2Status;
              }
              if (doc[4].status == "remove") {
                mainactionHUFDeed = "upload";
                HUFDeedStatus = "remove";
                HUFStatusArray[4].status = HUFDeedStatus;
                this.setState(
                  {
                    HUFDeedDocLink: doc[4].HUFDeedDocument,
                  },
                  () => {
                    $("#viewDocLink_HUFDeed").css("display", "block");
                    $("#choose_HUFDeed").hide();
                    $("#X-btn_HUFDeed").show();
                    $("#done-btn_HUFDeed").hide();
                    $("#review-btn_HUFDeed").hide();
                  }
                );
              } else if (doc[4].status == "review") {
                mainactionHUFDeed = "upload";
                HUFDeedStatus = "review";
                HUFStatusArray[4].status = HUFDeedStatus;
                this.setState(
                  {
                    HUFDeedDocLink: doc[4].HUFDeedDocument,
                  },
                  () => {
                    $("#viewDocLink_HUFDeed").css("display", "block");
                    $("#choose_HUFDeed").hide();
                    $("#X-btn_HUFDeed").hide();
                    $("#done-btn_HUFDeed").hide();
                    $("#review-btn_HUFDeed").show();
                  }
                );
              } else if (doc[4].status == "reupload") {
                mainactionHUFDeed = "reupload";
                HUFDeedStatus = "reupload";
                HUFStatusArray[4].status = HUFDeedStatus;
                $("#choose_HUFDeed").show();
                $("#viewDocLink_HUFDeed").hide();
                $("#X-btn_HUFDeed").hide();
                $("#done-btn_HUFDeed").hide();
                $("#review-btn_HUFDeed").hide();
              } else if (doc[4].status == "done") {
                HUFDeedStatus = "done";
                mainactionHUFDeed = "upload";
                HUFStatusArray[4].status = HUFDeedStatus;
                $("#choose_HUFDeed").hide();
                $("#X-btn_HUFDeed").hide();
                $("#done-btn_HUFDeed").show();
                $("#review-btn_HUFDeed").hide();
              } else {
                HUFDeedStatus = "open";
                HUFStatusArray[4].status = HUFDeedStatus;
              }
              if (doc[5].status == "remove") {
                mainactionHUFBP = "upload";
                HUFBPStatus = "remove";
                HUFStatusArray[5].status = HUFBPStatus;
                this.setState(
                  {
                    HUFBPDocLink: doc[5].BankProof,
                  },
                  () => {
                    $("#viewDocLink_HUFBP").css("display", "block");
                    $("#choose_HUFBP").hide();
                    $("#X-btn_HUFBP").show();
                    $("#done-btn_HUFBP").hide();
                    $("#review-btn_HUFBP").hide();
                  }
                );
              } else if (doc[5].status == "review") {
                mainactionHUFBP = "upload";
                HUFBPStatus = "review";
                HUFStatusArray[5].status = HUFBPStatus;
                this.setState(
                  {
                    HUFBPDocLink: doc[5].BankProof,
                  },
                  () => {
                    $("#viewDocLink_HUFBP").css("display", "block");
                    $("#choose_HUFBP").hide();
                    $("#X-btn_HUFBP").hide();
                    $("#done-btn_HUFBP").hide();
                    $("#review-btn_HUFBP").show();
                  }
                );
              } else if (doc[5].status == "reupload") {
                mainactionHUFBP = "reupload";
                HUFBPStatus = "reupload";
                HUFStatusArray[5].status = HUFBPStatus;
                $("#choose_HUFBP").show();
                $("#viewDocLink_HUFBP").hide();
                $("#X-btn_HUFBP").hide();
                $("#done-btn_HUFBP").hide();
                $("#review-btn_HUFBP").hide();
              } else if (doc[5].status == "done") {
                HUFBPStatus = "done";
                mainactionHUFBP = "upload";
                HUFStatusArray[5].status = HUFBPStatus;
                $("#choose_HUFBP").hide();
                $("#X-btn_HUFBP").hide();
                $("#done-btn_HUFBP").show();
                $("#review-btn_HUFBP").hide();
              } else {
                HUFBPStatus = "open";
                HUFStatusArray[5].status = HUFBPStatus;
              }
              if (doc[6].status == "remove") {
                mainactionHUFGumasta = "upload";
                HUFGumastaStatus = "remove";
                HUFStatusArray[6].status = HUFGumastaStatus;
                this.setState(
                  {
                    HUFGumastaDocLink: doc[6].Gumasta,
                  },
                  () => {
                    $("#viewDocLink_HUFGumasta").css("display", "block");
                    $("#choose_HUFGumasta").hide();
                    $("#X-btn_HUFGumasta").show();
                    $("#done-btn_HUFGumasta").hide();
                    $("#review-btn_HUFGumasta").hide();
                  }
                );
              } else if (doc[6].status == "review") {
                mainactionHUFGumasta = "upload";
                HUFGumastaStatus = "review";
                HUFStatusArray[6].status = HUFGumastaStatus;
                this.setState(
                  {
                    HUFGumastaDocLink: doc[6].Gumasta,
                  },
                  () => {
                    $("#viewDocLink_HUFGumasta").css("display", "block");
                    $("#choose_HUFGumasta").hide();
                    $("#X-btn_HUFGumasta").hide();
                    $("#done-btn_HUFGumasta").hide();
                    $("#review-btn_HUFGumasta").show();
                  }
                );
              } else if (doc[6].status == "reupload") {
                mainactionHUFGumasta = "reupload";
                HUFGumastaStatus = "reupload";
                HUFStatusArray[6].status = HUFGumastaStatus;
                $("#choose_HUFGumasta").show();
                $("#viewDocLink_HUFGumasta").hide();
                $("#X-btn_HUFGumasta").hide();
                $("#done-btn_HUFGumasta").hide();
                $("#review-btn_HUFGumasta").hide();
              } else if (doc[6].status == "done") {
                HUFGumastaStatus = "done";
                mainactionHUFGumasta = "upload";
                HUFStatusArray[6].status = HUFGumastaStatus;
                $("#choose_HUFGumasta").hide();
                $("#X-btn_HUFGumasta").hide();
                $("#done-btn_HUFGumasta").show();
                $("#review-btn_HUFGumasta").hide();
              } else {
                HUFGumastaStatus = "open";
                HUFStatusArray[6].status = HUFGumastaStatus;
              }
              if (doc[7].status == "remove") {
                mainactionHUFTradeLicence = "upload";
                HUFTradeLicenceStatus = "remove";
                HUFStatusArray[7].status = HUFTradeLicenceStatus;
                this.setState(
                  {
                    HUFTradeLicenceDocLink: doc[7].TradeLicence,
                  },
                  () => {
                    $("#viewDocLink_HUFTradeLicence").css("display", "block");
                    $("#choose_HUFTradeLicence").hide();
                    $("#X-btn_HUFTradeLicence").show();
                    $("#done-btn_HUFTradeLicence").hide();
                    $("#review-btn_HUFTradeLicence").hide();
                  }
                );
              } else if (doc[7].status == "review") {
                mainactionHUFTradeLicence = "upload";
                HUFTradeLicenceStatus = "review";
                HUFStatusArray[7].status = HUFTradeLicenceStatus;
                this.setState(
                  {
                    HUFTradeLicenceDocLink: doc[7].TradeLicence,
                  },
                  () => {
                    $("#viewDocLink_HUFTradeLicence").css("display", "block");
                    $("#choose_HUFTradeLicence").hide();
                    $("#X-btn_HUFTradeLicence").hide();
                    $("#done-btn_HUFTradeLicence").hide();
                    $("#review-btn_HUFTradeLicence").show();
                  }
                );
              } else if (doc[7].status == "reupload") {
                mainactionHUFTradeLicence = "reupload";
                HUFTradeLicenceStatus = "reupload";
                HUFStatusArray[7].status = HUFTradeLicenceStatus;
                $("#choose_HUFTradeLicence").show();
                $("#viewDocLink_HUFTradeLicence").hide();
                $("#X-btn_HUFTradeLicence").hide();
                $("#done-btn_HUFTradeLicence").hide();
                $("#review-btn_HUFTradeLicence").hide();
              } else if (doc[7].status == "done") {
                HUFTradeLicenceStatus = "done";
                mainactionHUFTradeLicence = "upload";
                HUFStatusArray[7].status = HUFTradeLicenceStatus;
                $("#choose_HUFTradeLicence").hide();
                $("#X-btn_HUFTradeLicence").hide();
                $("#done-btn_HUFTradeLicence").show();
                $("#review-btn_HUFTradeLicence").hide();
              } else {
                HUFTradeLicenceStatus = "open";
                HUFStatusArray[7].status = HUFTradeLicenceStatus;
              }
              if (doc[8].status == "remove") {
                mainactionHUFCopyOfRegistry = "upload";
                HUFCopyOfRegistryStatus = "remove";
                HUFOptStatusArray[0].status = HUFCopyOfRegistryStatus;
                this.setState(
                  {
                    HUFCopyOfRegistryDocLink: doc[8].CopyOfRegistry,
                  },
                  () => {
                    $("#viewDocLink_HUFCopyOfRegistry").css("display", "block");
                    $("#choose_HUFCopyOfRegistry").hide();
                    $("#X-btn_HUFCopyOfRegistry").show();
                    $("#done-btn_HUFCopyOfRegistry").hide();
                    $("#review-btn_HUFCopyOfRegistry").hide();
                  }
                );
              } else if (doc[8].status == "review") {
                mainactionHUFCopyOfRegistry = "upload";
                HUFCopyOfRegistryStatus = "review";
                HUFOptStatusArray[0].status = HUFCopyOfRegistryStatus;
                this.setState(
                  {
                    HUFCopyOfRegistryDocLink: doc[8].CopyOfRegistry,
                  },
                  () => {
                    $("#viewDocLink_HUFCopyOfRegistry").css("display", "block");
                    $("#choose_HUFCopyOfRegistry").hide();
                    $("#X-btn_HUFCopyOfRegistry").hide();
                    $("#done-btn_HUFCopyOfRegistry").hide();
                    $("#review-btn_HUFCopyOfRegistry").show();
                  }
                );
              } else if (doc[8].status == "reupload") {
                mainactionHUFCopyOfRegistry = "reupload";
                HUFCopyOfRegistryStatus = "reupload";
                HUFOptStatusArray[0].status = HUFCopyOfRegistryStatus;
                $("#choose_HUFCopyOfRegistry").show();
                $("#viewDocLink_HUFCopyOfRegistry").hide();
                $("#X-btn_HUFCopyOfRegistry").hide();
                $("#done-btn_HUFCopyOfRegistry").hide();
                $("#review-btn_HUFCopyOfRegistry").hide();
              } else if (doc[8].status == "done") {
                HUFCopyOfRegistryStatus = "done";
                mainactionHUFCopyOfRegistry = "upload";
                HUFOptStatusArray[0].status = HUFCopyOfRegistryStatus;
                $("#choose_HUFCopyOfRegistry").hide();
                $("#X-btn_HUFCopyOfRegistry").hide();
                $("#done-btn_HUFCopyOfRegistry").show();
                $("#review-btn_HUFCopyOfRegistry").hide();
              } else {
                HUFCopyOfRegistryStatus = "open";
                HUFOptStatusArray[0].status = HUFCopyOfRegistryStatus;
              }
              if (doc[9].status == "remove") {
                mainactionHUFRentAgreement = "upload";
                HUFRentAgreementStatus = "remove";
                HUFOptStatusArray[1].status = HUFRentAgreementStatus;
                this.setState(
                  {
                    HUFRentAgreementDocLink: doc[9].RentAgreement,
                  },
                  () => {
                    $("#viewDocLink_HUFRentAgreement").css("display", "block");
                    $("#choose_HUFRentAgreement").hide();
                    $("#X-btn_HUFRentAgreement").show();
                    $("#done-btn_HUFRentAgreement").hide();
                    $("#review-btn_HUFRentAgreement").hide();
                  }
                );
              } else if (doc[9].status == "review") {
                mainactionHUFRentAgreement = "upload";
                HUFRentAgreementStatus = "review";
                HUFOptStatusArray[1].status = HUFRentAgreementStatus;
                this.setState(
                  {
                    HUFRentAgreementDocLink: doc[9].RentAgreement,
                  },
                  () => {
                    $("#viewDocLink_HUFRentAgreement").css("display", "block");
                    $("#choose_HUFRentAgreement").hide();
                    $("#X-btn_HUFRentAgreement").hide();
                    $("#done-btn_HUFRentAgreement").hide();
                    $("#review-btn_HUFRentAgreement").show();
                  }
                );
              } else if (doc[9].status == "reupload") {
                mainactionHUFRentAgreement = "reupload";
                HUFRentAgreementStatus = "reupload";
                HUFOptStatusArray[1].status = HUFRentAgreementStatus;
                $("#choose_HUFRentAgreement").show();
                $("#viewDocLink_HUFRentAgreement").hide();
                $("#X-btn_HUFRentAgreement").hide();
                $("#done-btn_HUFRentAgreement").hide();
                $("#review-btn_HUFRentAgreement").hide();
              } else if (doc[9].status == "done") {
                HUFRentAgreementStatus = "done";
                mainactionHUFRentAgreement = "upload";
                HUFOptStatusArray[1].status = HUFRentAgreementStatus;
                $("#choose_HUFRentAgreement").hide();
                $("#X-btn_HUFRentAgreement").hide();
                $("#done-btn_HUFRentAgreement").show();
                $("#review-btn_HUFRentAgreement").hide();
              } else {
                HUFRentAgreementStatus = "open";
                HUFOptStatusArray[1].status = HUFRentAgreementStatus;
              }
              if (doc[10].status == "remove") {
                mainactionHUFPR = "upload";
                HUFPRStatus = "remove";
                HUFStatusArray[8].status = HUFPRStatus;
                this.setState(
                  {
                    HUFPRDocLink: doc[10].PropertyTaxReceipt,
                  },
                  () => {
                    $("#viewDocLink_HUFPR").css("display", "block");
                    $("#choose_HUFPR").hide();
                    $("#X-btn_HUFPR").show();
                    $("#done-btn_HUFPR").hide();
                    $("#review-btn_HUFPR").hide();
                  }
                );
              } else if (doc[10].status == "review") {
                mainactionHUFPR = "upload";
                HUFPRStatus = "review";
                HUFStatusArray[8].status = HUFPRStatus;
                this.setState(
                  {
                    HUFPRDocLink: doc[10].PropertyTaxReceipt,
                  },
                  () => {
                    $("#viewDocLink_HUFPR").css("display", "block");
                    $("#choose_HUFPR").hide();
                    $("#X-btn_HUFPR").hide();
                    $("#done-btn_HUFPR").hide();
                    $("#review-btn_HUFPR").show();
                  }
                );
              } else if (doc[10].status == "reupload") {
                mainactionHUFPR = "reupload";
                HUFPRStatus = "reupload";
                HUFStatusArray[8].status = HUFPRStatus;
                $("#choose_HUFPR").show();
                $("#viewDocLink_HUFPR").hide();
                $("#X-btn_HUFPR").hide();
                $("#done-btn_HUFPR").hide();
                $("#review-btn_HUFPR").hide();
              } else if (doc[10].status == "done") {
                HUFPRStatus = "done";
                mainactionHUFPR = "upload";
                HUFStatusArray[8].status = HUFPRStatus;
                $("#choose_HUFPR").hide();
                $("#X-btn_HUFPR").hide();
                $("#done-btn_HUFPR").show();
                $("#review-btn_HUFPR").hide();
              } else {
                HUFPRStatus = "open";
                HUFStatusArray[8].status = HUFPRStatus;
              }
            }
            if (res.data.inputFields.length > 0) {
              if (
                res.data.inputFields[0].AddressProofType == "CopyOfRegistry"
              ) {
                this.setState(
                  {
                    HUFAddressProofType_radio: "CopyOfRegistry",
                  },
                  () => {
                    $(".CopyOfRegistry").css("display", "flex");
                    $(".RentAgreement").hide();
                  }
                );
              } else if (
                res.data.inputFields[0].AddressProofType == "RentAgreement"
              ) {
                this.setState(
                  {
                    HUFAddressProofType_radio: "RentAgreement",
                  },
                  () => {
                    $(".RentAgreement").css("display", "flex");
                    $(".CopyOfRegistry").hide();
                  }
                );
              }
              if (
                res.data.inputFields[1].status == "review" ||
                res.data.inputFields[1].status == "done"
              ) {
                this.setState({
                  HUFbusinessNature: res.data.inputFields[1].Nature,
                });
                $(".uploadtextarea").attr("disabled", true);
              }
            }
            this.HUFcheckRemainingReuploads();
            this.checkhandleHUFProceedBtn();
          } else if (res.data.serviceType == "GSTR_STC_Documents") {
            $(".selectcateselect").attr("disabled", true);
            this.setState(
              {
                dropDownValue: "GSTR_STC_Documents",
                serviceType: "GSTR_STC_Documents",
              },
              () => {
                if (this.state.dropDownValue == "GSTR_STC_Documents") {
                  $("#gstr_default_Img").hide();
                  $("#gstr_SolePI").hide();
                  $("#gstr_P_LLP").hide();
                  $("#gstr_PL").hide();
                  $("#gstr_HUF").hide();
                  $("#gstr_Society").show();
                }
              }
            );
            if (res.data.informationStatus == "lock") {
              if (res.data.addDocInformation.length == 0) {
                $("#STadditionalDocumentDiv").hide();
              } else {
                $("#STAdddocumentbtn").hide();
              }
            }
            if (res.data.addDocInformation.length > 0) {
              this.setState(
                {
                  STadditionalFiles: [...res.data.addDocInformation],
                  STAdd_DocName: "File Name",
                  api_STadditionalFiles: "Yes",
                },
                () => {}
              );
            } else if (
              res.data.addDocInformation.length == 0 &&
              res.data.informationStatus != "lock"
            ) {
              this.setState({
                STAdd_DocName: "",
                STadditionalFiles: [1],
                api_STadditionalFiles: "No",
              });
            }
            if (res.data.documents.length > 0) {
              let doc = res.data.documents;
              if (doc[0].status == "remove") {
                mainactionSTAadhar1 = "upload";
                STAadhar1Status = "remove";
                STStatusArray[0].status = STAadhar1Status;
                this.setState(
                  {
                    STAadhar1DocLink: doc[0].AadhaarCardApplicant1,
                  },
                  () => {
                    $("#viewDocLink_STAadhar1").css("display", "block");
                    $("#choose_STAadhar1").hide();
                    $("#X-btn_STAadhar1").show();
                    $("#done-btn_STAadhar1").hide();
                    $("#review-btn_STAadhar1").hide();
                  }
                );
              } else if (doc[0].status == "review") {
                mainactionSTAadhar1 = "upload";
                STAadhar1Status = "review";
                STStatusArray[0].status = STAadhar1Status;
                this.setState(
                  {
                    STAadhar1DocLink: doc[0].AadhaarCardApplicant1,
                  },
                  () => {
                    $("#viewDocLink_STAadhar1").css("display", "block");
                    $("#choose_STAadhar1").hide();
                    $("#X-btn_STAadhar1").hide();
                    $("#done-btn_STAadhar1").hide();
                    $("#review-btn_STAadhar1").show();
                  }
                );
              } else if (doc[0].status == "reupload") {
                mainactionSTAadhar1 = "reupload";
                STAadhar1Status = "reupload";
                STStatusArray[0].status = STAadhar1Status;
                $("#choose_STAadhar1").show();
                $("#viewDocLink_STAadhar1").hide();
                $("#X-btn_STAadhar1").hide();
                $("#done-btn_STAadhar1").hide();
                $("#review-btn_STAadhar1").hide();
              } else if (doc[0].status == "done") {
                STAadhar1Status = "done";
                mainactionSTAadhar1 = "upload";
                STStatusArray[0].status = STAadhar1Status;
                $("#choose_STAadhar1").hide();
                $("#X-btn_STAadhar1").hide();
                $("#done-btn_STAadhar1").show();
                $("#review-btn_STAadhar1").hide();
              } else {
                STAadhar1Status = "open";
                STStatusArray[0].status = STAadhar1Status;
              }
              if (doc[1].status == "remove") {
                mainactionSTAadhar2 = "upload";
                STAadhar2Status = "remove";
                STStatusArray[1].status = STAadhar2Status;
                this.setState(
                  {
                    STAadhar2DocLink: doc[1].AadhaarCardApplicant2,
                  },
                  () => {
                    $("#viewDocLink_STAadhar2").css("display", "block");
                    $("#choose_STAadhar2").hide();
                    $("#X-btn_STAadhar2").show();
                    $("#done-btn_STAadhar2").hide();
                    $("#review-btn_STAadhar2").hide();
                  }
                );
              } else if (doc[1].status == "review") {
                mainactionSTAadhar2 = "upload";
                STAadhar2Status = "review";
                STStatusArray[1].status = STAadhar2Status;
                this.setState(
                  {
                    STAadhar2DocLink: doc[1].AadhaarCardApplicant2,
                  },
                  () => {
                    $("#viewDocLink_STAadhar2").css("display", "block");
                    $("#choose_STAadhar2").hide();
                    $("#X-btn_STAadhar2").hide();
                    $("#done-btn_STAadhar2").hide();
                    $("#review-btn_STAadhar2").show();
                  }
                );
              } else if (doc[1].status == "reupload") {
                mainactionSTAadhar2 = "reupload";
                STAadhar2Status = "reupload";
                STStatusArray[1].status = STAadhar2Status;
                $("#choose_STAadhar2").show();
                $("#viewDocLink_STAadhar2").hide();
                $("#X-btn_STAadhar2").hide();
                $("#done-btn_STAadhar2").hide();
                $("#review-btn_STAadhar2").hide();
              } else if (doc[1].status == "done") {
                STAadhar2Status = "done";
                mainactionSTAadhar2 = "upload";
                STStatusArray[1].status = STAadhar2Status;
                $("#choose_STAadhar2").hide();
                $("#X-btn_STAadhar2").hide();
                $("#done-btn_STAadhar2").show();
                $("#review-btn_STAadhar2").hide();
              } else {
                STAadhar2Status = "open";
                STStatusArray[1].status = STAadhar2Status;
              }
              if (doc[2].status == "remove") {
                mainactionSTPan1 = "upload";
                STPan1Status = "remove";
                STStatusArray[2].status = STPan1Status;
                this.setState(
                  {
                    STPan1DocLink: doc[2].PANCardApplicant1,
                  },
                  () => {
                    $("#viewDocLink_STPan1").css("display", "block");
                    $("#choose_STPan1").hide();
                    $("#X-btn_STPan1").show();
                    $("#done-btn_STPan1").hide();
                    $("#review-btn_STPan1").hide();
                  }
                );
              } else if (doc[2].status == "review") {
                mainactionSTPan1 = "upload";
                STPan1Status = "review";
                STStatusArray[2].status = STPan1Status;
                this.setState(
                  {
                    STPan1DocLink: doc[2].PANCardApplicant1,
                  },
                  () => {
                    $("#viewDocLink_STPan1").css("display", "block");
                    $("#choose_STPan1").hide();
                    $("#X-btn_STPan1").hide();
                    $("#done-btn_STPan1").hide();
                    $("#review-btn_STPan1").show();
                  }
                );
              } else if (doc[2].status == "reupload") {
                mainactionSTPan1 = "reupload";
                STPan1Status = "reupload";
                STStatusArray[2].status = STPan1Status;
                $("#choose_STPan1").show();
                $("#viewDocLink_STPan1").hide();
                $("#X-btn_STPan1").hide();
                $("#done-btn_STPan1").hide();
                $("#review-btn_STPan1").hide();
              } else if (doc[2].status == "done") {
                STPan1Status = "done";
                mainactionSTPan1 = "upload";
                STStatusArray[2].status = STPan1Status;
                $("#choose_STPan1").hide();
                $("#X-btn_STPan1").hide();
                $("#done-btn_STPan1").show();
                $("#review-btn_STPan1").hide();
              } else {
                STPan1Status = "open";
                STStatusArray[2].status = STPan1Status;
              }
              if (doc[3].status == "remove") {
                mainactionSTPan2 = "upload";
                STPan2Status = "remove";
                STStatusArray[3].status = STPan2Status;
                this.setState(
                  {
                    STPan2DocLink: doc[3].PANCardApplicant2,
                  },
                  () => {
                    $("#viewDocLink_STPan2").css("display", "block");
                    $("#choose_STPan2").hide();
                    $("#X-btn_STPan2").show();
                    $("#done-btn_STPan2").hide();
                    $("#review-btn_STPan2").hide();
                  }
                );
              } else if (doc[3].status == "review") {
                mainactionSTPan2 = "upload";
                STPan2Status = "review";
                STStatusArray[3].status = STPan2Status;
                this.setState(
                  {
                    STPan2DocLink: doc[3].PANCardApplicant2,
                  },
                  () => {
                    $("#viewDocLink_STPan2").css("display", "block");
                    $("#choose_STPan2").hide();
                    $("#X-btn_STPan2").hide();
                    $("#done-btn_STPan2").hide();
                    $("#review-btn_STPan2").show();
                  }
                );
              } else if (doc[3].status == "reupload") {
                mainactionSTPan2 = "reupload";
                STPan2Status = "reupload";
                STStatusArray[3].status = STPan2Status;
                $("#choose_STPan2").show();
                $("#viewDocLink_STPan2").hide();
                $("#X-btn_STPan2").hide();
                $("#done-btn_STPan2").hide();
                $("#review-btn_STPan2").hide();
              } else if (doc[3].status == "done") {
                STPan2Status = "done";
                mainactionSTPan2 = "upload";
                STStatusArray[3].status = STPan2Status;
                $("#choose_STPan2").hide();
                $("#X-btn_STPan2").hide();
                $("#done-btn_STPan2").show();
                $("#review-btn_STPan2").hide();
              } else {
                STPan2Status = "open";
                STStatusArray[3].status = STPan2Status;
              }
              if (doc[4].status == "remove") {
                mainactionSTPic1 = "upload";
                STPic1Status = "remove";
                STStatusArray[4].status = STPic1Status;
                this.setState(
                  {
                    STPic1DocLink: doc[4].ApplicantPhoto1,
                  },
                  () => {
                    $("#viewDocLink_STPic1").css("display", "block");
                    $("#choose_STPic1").hide();
                    $("#X-btn_STPic1").show();
                    $("#done-btn_STPic1").hide();
                    $("#review-btn_STPic1").hide();
                  }
                );
              } else if (doc[4].status == "review") {
                mainactionSTPic1 = "upload";
                STPic1Status = "review";
                STStatusArray[4].status = STPic1Status;
                this.setState(
                  {
                    STPic1DocLink: doc[4].ApplicantPhoto1,
                  },
                  () => {
                    $("#viewDocLink_STPic1").css("display", "block");
                    $("#choose_STPic1").hide();
                    $("#X-btn_STPic1").hide();
                    $("#done-btn_STPic1").hide();
                    $("#review-btn_STPic1").show();
                  }
                );
              } else if (doc[4].status == "reupload") {
                mainactionSTPic1 = "reupload";
                STPic1Status = "reupload";
                STStatusArray[4].status = STPic1Status;
                $("#choose_STPic1").show();
                $("#viewDocLink_STPic1").hide();
                $("#X-btn_STPic1").hide();
                $("#done-btn_STPic1").hide();
                $("#review-btn_STPic1").hide();
              } else if (doc[4].status == "done") {
                STPic1Status = "done";
                mainactionSTPic1 = "upload";
                STStatusArray[4].status = STPic1Status;
                $("#choose_STPic1").hide();
                $("#X-btn_STPic1").hide();
                $("#done-btn_STPic1").show();
                $("#review-btn_STPic1").hide();
              } else {
                STPic1Status = "open";
                STStatusArray[4].status = STPic1Status;
              }
              if (doc[5].status == "remove") {
                mainactionSTPic2 = "upload";
                STPic2Status = "remove";
                STStatusArray[5].status = STPic2Status;
                this.setState(
                  {
                    STPic2DocLink: doc[5].ApplicantPhoto2,
                  },
                  () => {
                    $("#viewDocLink_STPic2").css("display", "block");
                    $("#choose_STPic2").hide();
                    $("#X-btn_STPic2").show();
                    $("#done-btn_STPic2").hide();
                    $("#review-btn_STPic2").hide();
                  }
                );
              } else if (doc[5].status == "review") {
                mainactionSTPic2 = "upload";
                STPic2Status = "review";
                STStatusArray[5].status = STPic2Status;
                this.setState(
                  {
                    STPic2DocLink: doc[5].ApplicantPhoto2,
                  },
                  () => {
                    $("#viewDocLink_STPic2").css("display", "block");
                    $("#choose_STPic2").hide();
                    $("#X-btn_STPic2").hide();
                    $("#done-btn_STPic2").hide();
                    $("#review-btn_STPic2").show();
                  }
                );
              } else if (doc[5].status == "reupload") {
                mainactionSTPic2 = "reupload";
                STPic2Status = "reupload";
                STStatusArray[5].status = STPic2Status;
                $("#choose_STPic2").show();
                $("#viewDocLink_STPic2").hide();
                $("#X-btn_STPic2").hide();
                $("#done-btn_STPic2").hide();
                $("#review-btn_STPic2").hide();
              } else if (doc[5].status == "done") {
                STPic2Status = "done";
                mainactionSTPic2 = "upload";
                STStatusArray[5].status = STPic2Status;
                $("#choose_STPic2").hide();
                $("#X-btn_STPic2").hide();
                $("#done-btn_STPic2").show();
                $("#review-btn_STPic2").hide();
              } else {
                STPic2Status = "open";
                STStatusArray[5].status = STPic2Status;
              }
              if (doc[6].status == "remove") {
                mainactionSTPan3 = "upload";
                STPan3Status = "remove";
                STStatusArray[6].status = STPan3Status;
                this.setState(
                  {
                    STPan3DocLink: doc[6].PANCardSTC,
                  },
                  () => {
                    $("#viewDocLink_STPan3").css("display", "block");
                    $("#choose_STPan3").hide();
                    $("#X-btn_STPan3").show();
                    $("#done-btn_STPan3").hide();
                    $("#review-btn_STPan3").hide();
                  }
                );
              } else if (doc[6].status == "review") {
                mainactionSTPan3 = "upload";
                STPan3Status = "review";
                STStatusArray[6].status = STPan3Status;
                this.setState(
                  {
                    STPan3DocLink: doc[6].PANCardSTC,
                  },
                  () => {
                    $("#viewDocLink_STPan3").css("display", "block");
                    $("#choose_STPan3").hide();
                    $("#X-btn_STPan3").hide();
                    $("#done-btn_STPan3").hide();
                    $("#review-btn_STPan3").show();
                  }
                );
              } else if (doc[6].status == "reupload") {
                mainactionSTPan3 = "reupload";
                STPan3Status = "reupload";
                STStatusArray[6].status = STPan3Status;
                $("#choose_STPan3").show();
                $("#viewDocLink_STPan3").hide();
                $("#X-btn_STPan3").hide();
                $("#done-btn_STPan3").hide();
                $("#review-btn_STPan3").hide();
              } else if (doc[6].status == "done") {
                STPan3Status = "done";
                mainactionSTPan3 = "upload";
                STStatusArray[6].status = STPan3Status;
                $("#choose_STPan3").hide();
                $("#X-btn_STPan3").hide();
                $("#done-btn_STPan3").show();
                $("#review-btn_STPan3").hide();
              } else {
                STPan3Status = "open";
                STStatusArray[6].status = STPan3Status;
              }
              if (doc[7].status == "remove") {
                mainactionSTCR = "upload";
                STCRStatus = "remove";
                STStatusArray[7].status = STCRStatus;
                this.setState(
                  {
                    STCRDocLink: doc[7].CertificateOfRegistration,
                  },
                  () => {
                    $("#viewDocLink_STCR").css("display", "block");
                    $("#choose_STCR").hide();
                    $("#X-btn_STCR").show();
                    $("#done-btn_STCR").hide();
                    $("#review-btn_STCR").hide();
                  }
                );
              } else if (doc[7].status == "review") {
                mainactionSTCR = "upload";
                STCRStatus = "review";
                STStatusArray[7].status = STCRStatus;
                this.setState(
                  {
                    STCRDocLink: doc[7].CertificateOfRegistration,
                  },
                  () => {
                    $("#viewDocLink_STCR").css("display", "block");
                    $("#choose_STCR").hide();
                    $("#X-btn_STCR").hide();
                    $("#done-btn_STCR").hide();
                    $("#review-btn_STCR").show();
                  }
                );
              } else if (doc[7].status == "reupload") {
                mainactionSTCR = "reupload";
                STCRStatus = "reupload";
                STStatusArray[7].status = STCRStatus;
                $("#choose_STCR").show();
                $("#viewDocLink_STCR").hide();
                $("#X-btn_STCR").hide();
                $("#done-btn_STCR").hide();
                $("#review-btn_STCR").hide();
              } else if (doc[7].status == "done") {
                STCRStatus = "done";
                mainactionSTCR = "upload";
                STStatusArray[7].status = STCRStatus;
                $("#choose_STCR").hide();
                $("#X-btn_STCR").hide();
                $("#done-btn_STCR").show();
                $("#review-btn_STCR").hide();
              } else {
                STCRStatus = "open";
                STStatusArray[7].status = STCRStatus;
              }
              if (doc[8].status == "remove") {
                mainactionSTBP = "upload";
                STBPStatus = "remove";
                STStatusArray[8].status = STBPStatus;
                this.setState(
                  {
                    STBPDocLink: doc[8].BankProof,
                  },
                  () => {
                    $("#viewDocLink_STBP").css("display", "block");
                    $("#choose_STBP").hide();
                    $("#X-btn_STBP").show();
                    $("#done-btn_STBP").hide();
                    $("#review-btn_STBP").hide();
                  }
                );
              } else if (doc[8].status == "review") {
                mainactionSTBP = "upload";
                STBPStatus = "review";
                STStatusArray[8].status = STBPStatus;
                this.setState(
                  {
                    STBPDocLink: doc[8].BankProof,
                  },
                  () => {
                    $("#viewDocLink_STBP").css("display", "block");
                    $("#choose_STBP").hide();
                    $("#X-btn_STBP").hide();
                    $("#done-btn_STBP").hide();
                    $("#review-btn_STBP").show();
                  }
                );
              } else if (doc[8].status == "reupload") {
                mainactionSTBP = "reupload";
                STBPStatus = "reupload";
                STStatusArray[8].status = STBPStatus;
                $("#choose_STBP").show();
                $("#viewDocLink_STBP").hide();
                $("#X-btn_STBP").hide();
                $("#done-btn_STBP").hide();
                $("#review-btn_STBP").hide();
              } else if (doc[8].status == "done") {
                STBPStatus = "done";
                mainactionSTBP = "upload";
                STStatusArray[8].status = STBPStatus;
                $("#choose_STBP").hide();
                $("#X-btn_STBP").hide();
                $("#done-btn_STBP").show();
                $("#review-btn_STBP").hide();
              } else {
                STBPStatus = "open";
                STStatusArray[8].status = STBPStatus;
              }
              if (doc[9].status == "remove") {
                mainactionSTBR = "upload";
                STBRStatus = "remove";
                STStatusArray[9].status = STBRStatus;
                this.setState(
                  {
                    STBRDocLink: doc[9].BoardResolution,
                  },
                  () => {
                    $("#viewDocLink_STBR").css("display", "block");
                    $("#choose_STBR").hide();
                    $("#X-btn_STBR").show();
                    $("#done-btn_STBR").hide();
                    $("#review-btn_STBR").hide();
                  }
                );
              } else if (doc[9].status == "review") {
                mainactionSTBR = "upload";
                STBRStatus = "review";
                STStatusArray[9].status = STBRStatus;
                this.setState(
                  {
                    STBRDocLink: doc[9].BoardResolution,
                  },
                  () => {
                    $("#viewDocLink_STBR").css("display", "block");
                    $("#choose_STBR").hide();
                    $("#X-btn_STBR").hide();
                    $("#done-btn_STBR").hide();
                    $("#review-btn_STBR").show();
                  }
                );
              } else if (doc[9].status == "reupload") {
                mainactionSTBR = "reupload";
                STBRStatus = "reupload";
                STStatusArray[9].status = STBRStatus;
                $("#choose_STBR").show();
                $("#viewDocLink_STBR").hide();
                $("#X-btn_STBR").hide();
                $("#done-btn_STBR").hide();
                $("#review-btn_STBR").hide();
              } else if (doc[9].status == "done") {
                STBRStatus = "done";
                mainactionSTBR = "upload";
                STStatusArray[9].status = STBRStatus;
                $("#choose_STBR").hide();
                $("#X-btn_STBR").hide();
                $("#done-btn_STBR").show();
                $("#review-btn_STBR").hide();
              } else {
                STBRStatus = "open";
                STStatusArray[9].status = STBRStatus;
              }
              if (doc[10].status == "remove") {
                mainactionSTGumasta = "upload";
                STGumastaStatus = "remove";
                STStatusArray[10].status = STGumastaStatus;
                this.setState(
                  {
                    STGumastaDocLink: doc[10].Gumasta,
                  },
                  () => {
                    $("#viewDocLink_STGumasta").css("display", "block");
                    $("#choose_STGumasta").hide();
                    $("#X-btn_STGumasta").show();
                    $("#done-btn_STGumasta").hide();
                    $("#review-btn_STGumasta").hide();
                  }
                );
              } else if (doc[10].status == "review") {
                mainactionSTGumasta = "upload";
                STGumastaStatus = "review";
                STStatusArray[10].status = STGumastaStatus;
                this.setState(
                  {
                    STGumastaDocLink: doc[10].Gumasta,
                  },
                  () => {
                    $("#viewDocLink_STGumasta").css("display", "block");
                    $("#choose_STGumasta").hide();
                    $("#X-btn_STGumasta").hide();
                    $("#done-btn_STGumasta").hide();
                    $("#review-btn_STGumasta").show();
                  }
                );
              } else if (doc[10].status == "reupload") {
                mainactionSTGumasta = "reupload";
                STGumastaStatus = "reupload";
                STStatusArray[10].status = STGumastaStatus;
                $("#choose_STGumasta").show();
                $("#viewDocLink_STGumasta").hide();
                $("#X-btn_STGumasta").hide();
                $("#done-btn_STGumasta").hide();
                $("#review-btn_STGumasta").hide();
              } else if (doc[10].status == "done") {
                STGumastaStatus = "done";
                mainactionSTGumasta = "upload";
                STStatusArray[10].status = STGumastaStatus;
                $("#choose_STGumasta").hide();
                $("#X-btn_STGumasta").hide();
                $("#done-btn_STGumasta").show();
                $("#review-btn_STGumasta").hide();
              } else {
                STGumastaStatus = "open";
                STStatusArray[10].status = STGumastaStatus;
              }
              if (doc[11].status == "remove") {
                mainactionSTTradeLicence = "upload";
                STTradeLicenceStatus = "remove";
                STStatusArray[11].status = STTradeLicenceStatus;
                this.setState(
                  {
                    STTradeLicenceDocLink: doc[11].TradeLicence,
                  },
                  () => {
                    $("#viewDocLink_STTradeLicence").css("display", "block");
                    $("#choose_STTradeLicence").hide();
                    $("#X-btn_STTradeLicence").show();
                    $("#done-btn_STTradeLicence").hide();
                    $("#review-btn_STTradeLicence").hide();
                  }
                );
              } else if (doc[11].status == "review") {
                mainactionSTTradeLicence = "upload";
                STTradeLicenceStatus = "review";
                STStatusArray[11].status = STTradeLicenceStatus;
                this.setState(
                  {
                    STTradeLicenceDocLink: doc[11].TradeLicence,
                  },
                  () => {
                    $("#viewDocLink_STTradeLicence").css("display", "block");
                    $("#choose_STTradeLicence").hide();
                    $("#X-btn_STTradeLicence").hide();
                    $("#done-btn_STTradeLicence").hide();
                    $("#review-btn_STTradeLicence").show();
                  }
                );
              } else if (doc[11].status == "reupload") {
                mainactionSTTradeLicence = "reupload";
                STTradeLicenceStatus = "reupload";
                STStatusArray[11].status = STTradeLicenceStatus;
                $("#choose_STTradeLicence").show();
                $("#viewDocLink_STTradeLicence").hide();
                $("#X-btn_STTradeLicence").hide();
                $("#done-btn_STTradeLicence").hide();
                $("#review-btn_STTradeLicence").hide();
              } else if (doc[11].status == "done") {
                STTradeLicenceStatus = "done";
                mainactionSTTradeLicence = "upload";
                STStatusArray[11].status = STTradeLicenceStatus;
                $("#choose_STTradeLicence").hide();
                $("#X-btn_STTradeLicence").hide();
                $("#done-btn_STTradeLicence").show();
                $("#review-btn_STTradeLicence").hide();
              } else {
                STTradeLicenceStatus = "open";
                STStatusArray[11].status = STTradeLicenceStatus;
              }
              if (doc[12].status == "remove") {
                mainactionSTCopyOfRegistry = "upload";
                STCopyOfRegistryStatus = "remove";
                STOptStatusArray[0].status = STCopyOfRegistryStatus;
                this.setState(
                  {
                    STCopyOfRegistryDocLink: doc[12].CopyOfRegistry,
                  },
                  () => {
                    $("#viewDocLink_STCopyOfRegistry").css("display", "block");
                    $("#choose_STCopyOfRegistry").hide();
                    $("#X-btn_STCopyOfRegistry").show();
                    $("#done-btn_STCopyOfRegistry").hide();
                    $("#review-btn_STCopyOfRegistry").hide();
                  }
                );
              } else if (doc[12].status == "review") {
                mainactionSTCopyOfRegistry = "upload";
                STCopyOfRegistryStatus = "review";
                STOptStatusArray[0].status = STCopyOfRegistryStatus;
                this.setState(
                  {
                    STCopyOfRegistryDocLink: doc[12].CopyOfRegistry,
                  },
                  () => {
                    $("#viewDocLink_STCopyOfRegistry").css("display", "block");
                    $("#choose_STCopyOfRegistry").hide();
                    $("#X-btn_STCopyOfRegistry").hide();
                    $("#done-btn_STCopyOfRegistry").hide();
                    $("#review-btn_STCopyOfRegistry").show();
                  }
                );
              } else if (doc[12].status == "reupload") {
                mainactionSTCopyOfRegistry = "reupload";
                STCopyOfRegistryStatus = "reupload";
                STOptStatusArray[0].status = STCopyOfRegistryStatus;
                $("#choose_STCopyOfRegistry").show();
                $("#viewDocLink_STCopyOfRegistry").hide();
                $("#X-btn_STCopyOfRegistry").hide();
                $("#done-btn_STCopyOfRegistry").hide();
                $("#review-btn_STCopyOfRegistry").hide();
              } else if (doc[12].status == "done") {
                STCopyOfRegistryStatus = "done";
                mainactionSTCopyOfRegistry = "upload";
                STOptStatusArray[0].status = STCopyOfRegistryStatus;
                $("#choose_STCopyOfRegistry").hide();
                $("#X-btn_STCopyOfRegistry").hide();
                $("#done-btn_STCopyOfRegistry").show();
                $("#review-btn_STCopyOfRegistry").hide();
              } else {
                STCopyOfRegistryStatus = "open";
                STOptStatusArray[0].status = STCopyOfRegistryStatus;
              }
              if (doc[13].status == "remove") {
                mainactionSTRentAgreement = "upload";
                STRentAgreementStatus = "remove";
                STOptStatusArray[1].status = STRentAgreementStatus;
                this.setState(
                  {
                    STRentAgreementDocLink: doc[13].RentAgreement,
                  },
                  () => {
                    $("#viewDocLink_STRentAgreement").css("display", "block");
                    $("#choose_STRentAgreement").hide();
                    $("#X-btn_STRentAgreement").show();
                    $("#done-btn_STRentAgreement").hide();
                    $("#review-btn_STRentAgreement").hide();
                  }
                );
              } else if (doc[13].status == "review") {
                mainactionSTRentAgreement = "upload";
                STRentAgreementStatus = "review";
                STOptStatusArray[1].status = STRentAgreementStatus;
                this.setState(
                  {
                    STRentAgreementDocLink: doc[13].RentAgreement,
                  },
                  () => {
                    $("#viewDocLink_STRentAgreement").css("display", "block");
                    $("#choose_STRentAgreement").hide();
                    $("#X-btn_STRentAgreement").hide();
                    $("#done-btn_STRentAgreement").hide();
                    $("#review-btn_STRentAgreement").show();
                  }
                );
              } else if (doc[13].status == "reupload") {
                mainactionSTRentAgreement = "reupload";
                STRentAgreementStatus = "reupload";
                STOptStatusArray[1].status = STRentAgreementStatus;
                $("#choose_STRentAgreement").show();
                $("#viewDocLink_STRentAgreement").hide();
                $("#X-btn_STRentAgreement").hide();
                $("#done-btn_STRentAgreement").hide();
                $("#review-btn_STRentAgreement").hide();
              } else if (doc[13].status == "done") {
                STRentAgreementStatus = "done";
                mainactionSTRentAgreement = "upload";
                STOptStatusArray[1].status = STRentAgreementStatus;
                $("#choose_STRentAgreement").hide();
                $("#X-btn_STRentAgreement").hide();
                $("#done-btn_STRentAgreement").show();
                $("#review-btn_STRentAgreement").hide();
              } else {
                STRentAgreementStatus = "open";
                STOptStatusArray[1].status = STRentAgreementStatus;
              }
              if (doc[14].status == "remove") {
                mainactionSTPR = "upload";
                STPRStatus = "remove";
                STStatusArray[12].status = STPRStatus;
                this.setState(
                  {
                    STPRDocLink: doc[14].RentAgreement,
                  },
                  () => {
                    $("#viewDocLink_STPR").css("display", "block");
                    $("#choose_STPR").hide();
                    $("#X-btn_STPR").show();
                    $("#done-btn_STPR").hide();
                    $("#review-btn_STPR").hide();
                  }
                );
              } else if (doc[14].status == "review") {
                mainactionSTPR = "upload";
                STPRStatus = "review";
                STStatusArray[12].status = STPRStatus;
                this.setState(
                  {
                    STPRDocLink: doc[14].RentAgreement,
                  },
                  () => {
                    $("#viewDocLink_STPR").css("display", "block");
                    $("#choose_STPR").hide();
                    $("#X-btn_STPR").hide();
                    $("#done-btn_STPR").hide();
                    $("#review-btn_STPR").show();
                  }
                );
              } else if (doc[14].status == "reupload") {
                mainactionSTPR = "reupload";
                STPRStatus = "reupload";
                STStatusArray[12].status = STPRStatus;
                $("#choose_STPR").show();
                $("#viewDocLink_STPR").hide();
                $("#X-btn_STPR").hide();
                $("#done-btn_STPR").hide();
                $("#review-btn_STPR").hide();
              } else if (doc[14].status == "done") {
                STPRStatus = "done";
                mainactionSTPR = "upload";
                STStatusArray[12].status = STPRStatus;
                $("#choose_STPR").hide();
                $("#X-btn_STPR").hide();
                $("#done-btn_STPR").show();
                $("#review-btn_STPR").hide();
              } else {
                STPRStatus = "open";
                STStatusArray[12].status = STPRStatus;
              }
            }
            if (res.data.inputFields.length > 0) {
              if (
                res.data.inputFields[0].AddressProofType == "CopyOfRegistry"
              ) {
                this.setState(
                  {
                    STAddressProofType_radio: "CopyOfRegistry",
                  },
                  () => {
                    $(".CopyOfRegistry").css("display", "flex");
                    $(".RentAgreement").hide();
                  }
                );
              } else if (
                res.data.inputFields[0].AddressProofType == "RentAgreement"
              ) {
                this.setState(
                  {
                    STAddressProofType_radio: "RentAgreement",
                  },
                  () => {
                    $(".RentAgreement").css("display", "flex");
                    $(".CopyOfRegistry").hide();
                  }
                );
              }
              if (
                res.data.inputFields[1].status == "review" ||
                res.data.inputFields[1].status == "done"
              ) {
                this.setState({
                  STbusinessNature: res.data.inputFields[1].Nature,
                });
                $(".uploadtextarea").attr("disabled", true);
              }
            }
            this.STcheckRemainingReuploads();
            this.checkhandleSTProceedBtn();
          } else {
            this.showNotification("Something went wrong");
          }

          if (
            res.data.informationStatus == "lock" &&
            res.data.mainstatus == "false"
          ) {
            HideProceedBtn = "Yes";
            $(".proceednowbtn").hide();
            $("#test1-1").attr("disabled", true);
            $("#test1-2").attr("disabled", true);
            $("#test2-1").attr("disabled", true);
            $("#test2-2").attr("disabled", true);
            $("#test3-1").attr("disabled", true);
            $("#test3-2").attr("disabled", true);
            $("#test4-1").attr("disabled", true);
            $("#test4-2").attr("disabled", true);
            $("#test5-1").attr("disabled", true);
            $("#test5-2").attr("disabled", true);
          } else if (
            res.data.informationStatus == "lock" &&
            res.data.mainstatus == "true"
          ) {
            $("#test1-1").attr("disabled", true);
            $("#test1-2").attr("disabled", true);
            $("#test2-1").attr("disabled", true);
            $("#test2-2").attr("disabled", true);
            $("#test3-1").attr("disabled", true);
            $("#test3-2").attr("disabled", true);
            $("#test4-1").attr("disabled", true);
            $("#test4-2").attr("disabled", true);
            $("#test5-1").attr("disabled", true);
            $("#test5-2").attr("disabled", true);
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "docuploadnotallowed") {
          this.setState({
            referralPartner:false,
          })
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $(".serviceoverlay").hide();
      });
  };

  b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });

    return blob;
  };

  checkDropdownValue = () => {
    let sremove = this.statusCheckFunction("remove", SStatusArray);
    let sreview = this.statusCheckFunction("review", SStatusArray);
    let sdone = this.statusCheckFunction("done", SStatusArray);
    let sOptremove = this.statusCheckFunction("remove", SOptStatusArray);
    let sOptreview = this.statusCheckFunction("review", SOptStatusArray);
    let sOptdone = this.statusCheckFunction("done", SOptStatusArray);
    let saddremove = this.statusCheckFunction(
      "remove",
      this.state.SadditionalFiles
    );
    let saddreview = this.statusCheckFunction(
      "review",
      this.state.SadditionalFiles
    );
    let sadddone = this.statusCheckFunction(
      "done",
      this.state.SadditionalFiles
    );
    let premove = this.statusCheckFunction("remove", PStatusArray);
    let preview = this.statusCheckFunction("review", PStatusArray);
    let pdone = this.statusCheckFunction("done", PStatusArray);
    let pOptremove = this.statusCheckFunction("remove", POptStatusArray);
    let pOptreview = this.statusCheckFunction("review", POptStatusArray);
    let pOptdone = this.statusCheckFunction("done", POptStatusArray);
    let paddremove = this.statusCheckFunction(
      "remove",
      this.state.PadditionalFiles
    );
    let paddreview = this.statusCheckFunction(
      "review",
      this.state.PadditionalFiles
    );
    let padddone = this.statusCheckFunction(
      "done",
      this.state.PadditionalFiles
    );
    let plremove = this.statusCheckFunction("remove", PLStatusArray);
    let plreview = this.statusCheckFunction("review", PLStatusArray);
    let pldone = this.statusCheckFunction("done", PLStatusArray);
    let plOptremove = this.statusCheckFunction("remove", PLOptStatusArray);
    let plOptreview = this.statusCheckFunction("review", PLOptStatusArray);
    let plOptdone = this.statusCheckFunction("done", PLOptStatusArray);
    let pladdremove = this.statusCheckFunction(
      "remove",
      this.state.PLadditionalFiles
    );
    let pladdreview = this.statusCheckFunction(
      "review",
      this.state.PLadditionalFiles
    );
    let pladddone = this.statusCheckFunction(
      "done",
      this.state.PLadditionalFiles
    );
    let hufremove = this.statusCheckFunction("remove", HUFStatusArray);
    let hufreview = this.statusCheckFunction("review", HUFStatusArray);
    let hufdone = this.statusCheckFunction("done", HUFStatusArray);
    let hufOptremove = this.statusCheckFunction("remove", HUFOptStatusArray);
    let hufOptreview = this.statusCheckFunction("review", HUFOptStatusArray);
    let hufOptdone = this.statusCheckFunction("done", HUFOptStatusArray);
    let hufaddremove = this.statusCheckFunction(
      "remove",
      this.state.HUFadditionalFiles
    );
    let hufaddreview = this.statusCheckFunction(
      "review",
      this.state.HUFadditionalFiles
    );
    let hufadddone = this.statusCheckFunction(
      "done",
      this.state.HUFadditionalFiles
    );
    let stremove = this.statusCheckFunction("remove", STStatusArray);
    let streview = this.statusCheckFunction("review", STStatusArray);
    let stdone = this.statusCheckFunction("done", STStatusArray);
    let stOptremove = this.statusCheckFunction("remove", STOptStatusArray);
    let stOptreview = this.statusCheckFunction("review", STOptStatusArray);
    let stOptdone = this.statusCheckFunction("done", STOptStatusArray);
    let staddremove = this.statusCheckFunction(
      "remove",
      this.state.STadditionalFiles
    );
    let staddreview = this.statusCheckFunction(
      "review",
      this.state.STadditionalFiles
    );
    let stadddone = this.statusCheckFunction(
      "done",
      this.state.STadditionalFiles
    );

    if (this.state.dropDownValue == "GSTR_SolePI_Documents") {
      if (
        sremove == undefined &&
        sreview == undefined &&
        sdone == undefined &&
        sOptremove == undefined &&
        sOptreview == undefined &&
        sOptdone == undefined &&
        saddremove == undefined &&
        saddreview == undefined &&
        sadddone == undefined
      ) {
        $(".selectcateselect").attr("disabled", false);
      } else {
        $(".selectcateselect").attr("disabled", true);
      }
    } else if (this.state.dropDownValue == "GSTR_P_LLP_Documents") {
      if (
        premove == undefined &&
        preview == undefined &&
        pdone == undefined &&
        pOptremove == undefined &&
        pOptreview == undefined &&
        pOptdone == undefined &&
        paddremove == undefined &&
        paddreview == undefined &&
        padddone == undefined
      ) {
        $(".selectcateselect").attr("disabled", false);
      } else {
        $(".selectcateselect").attr("disabled", true);
      }
    } else if (this.state.dropDownValue == "GSTR_PRL_PL_OP_Documents") {
      if (
        plremove == undefined &&
        plreview == undefined &&
        pldone == undefined &&
        plOptremove == undefined &&
        plOptreview == undefined &&
        plOptdone == undefined &&
        pladdremove == undefined &&
        pladdreview == undefined &&
        pladddone == undefined
      ) {
        $(".selectcateselect").attr("disabled", false);
      } else {
        $(".selectcateselect").attr("disabled", true);
      }
    } else if (this.state.dropDownValue == "GSTR_HUF_Documents") {
      if (
        hufremove == undefined &&
        hufreview == undefined &&
        hufdone == undefined &&
        hufOptremove == undefined &&
        hufOptreview == undefined &&
        hufOptdone == undefined &&
        hufaddremove == undefined &&
        hufaddreview == undefined &&
        hufadddone == undefined
      ) {
        $(".selectcateselect").attr("disabled", false);
      } else {
        $(".selectcateselect").attr("disabled", true);
      }
    } else if (this.state.dropDownValue == "GSTR_STC_Documents") {
      if (
        stremove == undefined &&
        streview == undefined &&
        stdone == undefined &&
        stOptremove == undefined &&
        stOptreview == undefined &&
        stOptdone == undefined &&
        staddremove == undefined &&
        staddreview == undefined &&
        stadddone == undefined
      ) {
        $(".selectcateselect").attr("disabled", false);
      } else {
        $(".selectcateselect").attr("disabled", true);
      }
    }
  };

  logout = () => {
    localStorage.removeItem("gglltakoinoeenl");
    localStorage.removeItem("toeljgtkewlna");
    localStorage.removeItem("relleIsugad");
    this.props.history.push({
      pathname: `/`,
    });
  };
  handledropDownValueChange = (e) => {
    $(".proceednowbtn").attr("disabled", true);
    $("#volvo").attr("disabled", true);
    this.setState(
      {
        dropDownValue: e.target.value,
      },
      () => {
        if (this.state.dropDownValue == "GSTR_SolePI_Documents") {
          this.setState(
            {
              dropDownValue: "GSTR_SolePI_Documents",
              serviceType: "GSTR_SolePI_Documents",
            },
            () => {
              if (this.state.dropDownValue == "GSTR_SolePI_Documents") {
                $("#gstr_default_Img").hide();
                $("#gstr_SolePI").show();
                $("#gstr_P_LLP").hide();
                $("#gstr_PL").hide();
                $("#gstr_HUF").hide();
                $("#gstr_Society").hide();
              }
            }
          );
        } else if (this.state.dropDownValue == "GSTR_P_LLP_Documents") {
          this.setState(
            {
              dropDownValue: "GSTR_P_LLP_Documents",
              serviceType: "GSTR_P_LLP_Documents",
            },
            () => {
              if (this.state.dropDownValue == "GSTR_P_LLP_Documents") {
                $("#gstr_default_Img").hide();
                $("#gstr_SolePI").hide();
                $("#gstr_P_LLP").show();
                $("#gstr_PL").hide();
                $("#gstr_HUF").hide();
                $("#gstr_Society").hide();
              }
            }
          );
        } else if (this.state.dropDownValue == "GSTR_PRL_PL_OP_Documents") {
          this.setState(
            {
              dropDownValue: "GSTR_PRL_PL_OP_Documents",
              serviceType: "GSTR_PRL_PL_OP_Documents",
            },
            () => {
              if (this.state.dropDownValue == "GSTR_PRL_PL_OP_Documents") {
                $("#gstr_default_Img").hide();
                $("#gstr_SolePI").hide();
                $("#gstr_P_LLP").hide();
                $("#gstr_PL").show();
                $("#gstr_HUF").hide();
                $("#gstr_Society").hide();
              }
            }
          );
        } else if (this.state.dropDownValue == "GSTR_HUF_Documents") {
          this.setState(
            {
              dropDownValue: "GSTR_HUF_Documents",
              serviceType: "GSTR_HUF_Documents",
            },
            () => {
              if (this.state.dropDownValue == "GSTR_HUF_Documents") {
                $("#gstr_default_Img").hide();
                $("#gstr_SolePI").hide();
                $("#gstr_P_LLP").hide();
                $("#gstr_PL").hide();
                $("#gstr_HUF").show();
                $("#gstr_Society").hide();
              }
            }
          );
        } else if (this.state.dropDownValue == "GSTR_STC_Documents") {
          this.setState(
            {
              dropDownValue: "GSTR_STC_Documents",
              serviceType: "GSTR_STC_Documents",
            },
            () => {
              if (this.state.dropDownValue == "GSTR_STC_Documents") {
                $("#gstr_default_Img").hide();
                $("#gstr_SolePI").hide();
                $("#gstr_P_LLP").hide();
                $("#gstr_PL").hide();
                $("#gstr_HUF").hide();
                $("#gstr_Society").show();
              }
            }
          );
        }
      }
    );
  };

  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };

  statusCheckFunction = (status, array) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i].status == status) {
        return i;
      }
    }
  };

  handleComment = (e) => {
    this.setState({
      comment: e.target.value,
    });
  };

  renderCommenthistory() {
    if (this.state.commentHistroyarray.length > 0) {
      return this.state.commentHistroyarray.map((activedoc, index) => {
        if (activedoc.commentBy == "user" && activedoc.special == "no") {
          return (
            <div className="rightconvtextdiv" key={index}>
              <p className="rightconvtext">{activedoc.comment}</p>
              <p className="rightconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "user" &&
          activedoc.special == "green"
        ) {
          return (
            <div className="rightconvtextdiv" key={index}>
              <p className="rightgreenconvtext">{activedoc.comment}</p>
              <p className="rightconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "employee" &&
          activedoc.special == "no"
        ) {
          return (
            <div className="leftconvtextdiv" key={index}>
              <p className="leftconvtext">{activedoc.comment}</p>
              <p className="leftconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "user" &&
          activedoc.special == "red"
        ) {
          return (
            <div className="rightunappconvtextdiv" key={index}>
              <p className="rightunappconvtext">{activedoc.comment}</p>
              <p className="rightconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "employee" &&
          activedoc.special == "green"
        ) {
          return (
            <div className="leftgreenconvtextdiv" key={index}>
              <p className="leftgreenconvtext">{activedoc.comment}</p>
              <p className="leftconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "employee" &&
          activedoc.special == "red"
        ) {
          return (
            <div className="leftconvtextdiv" key={index}>
              <p className="leftunappconvtext">{activedoc.comment}</p>
              <p className="leftconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        }
      });
    } else if (this.state.commentHistroyarray.length == 0) {
      return <p className="convservicename">No comments available!</p>;
    }
  }

  // Sole P/I

  // SAadhar
  handleSAadharChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "AadhaarCard",
          },
          () => {
            $("#SAadharModal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "AadhaarCard",
          });
        }
      });
      $("#SAadharModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSAadhar = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_SAadhar").attr("disabled", true);
      $("#upload_btn20_SAadhar").hide();
      $("#upload_btnloader_SAadhar").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  SAadharDocLink: res.data.data.AadhaarCard,
                },
                () => $("#viewDocLink_SAadhar").css("display", "block")
              );
              SAadharStatus = "remove";
              SStatusArray[0].status = SAadharStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#SAadharModal").modal("hide");
              $("#cancelbtn_SAadhar").attr("disabled", false);
              $("#upload_btn20_SAadhar").show();
              $("#upload_btnloader_SAadhar").hide();
              $("#choose_SAadhar").hide();
              $("#X-btn_SAadhar").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_SAadhar").attr("disabled", false);
            $("#upload_btn20_SAadhar").show();
            $("#upload_btnloader_SAadhar").hide();
            $("#SAadharModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_SAadhar").attr("disabled", false);
            $("#upload_btn20_SAadhar").show();
            $("#upload_btnloader_SAadhar").hide();
            $("#SAadharModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_SAadhar").attr("disabled", false);
          $("#upload_btn20_SAadhar").show();
          $("#upload_btnloader_SAadhar").hide();
          $("#SAadharModal").modal("hide");

          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSAadhar = () => {
    $("#X-btn_SAadhar").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "AadhaarCard",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          SAadharStatus = res.data.data.status;
          SStatusArray[0].status = SAadharStatus;
          this.checkDropdownValue();
          this.checkhandleSProceedBtn();
          $("#choose_SAadhar").show();
          $("#X-btn_SAadhar").hide();
          $("#X-btn_SAadhar").attr("disabled", false);
          $("#viewDocLink_SAadhar").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_SAadhar").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_SAadhar").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSAadharseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SAadharseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "AadhaarCard",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#SAadharseecommentsbtn").hide();
          $("#SAadharhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSAadharhidecommentsbtn = () => {
    $("#SAadharseecommentsbtn").show();
    $("#SAadharseecommentsbtn").attr("disabled", false);
    $("#SAadharhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // SPan
  handleSPanChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "PANCard",
          },
          () => {
            $("#SPanModal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "PANCard",
          });
        }
      });
      $("#SPanModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSPan = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_SPan").attr("disabled", true);
      $("#upload_btn20_SPan").hide();
      $("#upload_btnloader_SPan").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  SPanDocLink: res.data.data.PANCard,
                },
                () => $("#viewDocLink_SPan").css("display", "block")
              );
              SPanStatus = "remove";
              SStatusArray[1].status = SPanStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#SPanModal").modal("hide");
              $("#cancelbtn_SPan").attr("disabled", false);
              $("#upload_btn20_SPan").show();
              $("#upload_btnloader_SPan").hide();
              $("#choose_SPan").hide();
              $("#X-btn_SPan").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_SPan").attr("disabled", false);
            $("#upload_btn20_SPan").show();
            $("#upload_btnloader_SPan").hide();
            $("#SPanModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_SPan").attr("disabled", false);
            $("#upload_btn20_SPan").show();
            $("#upload_btnloader_SPan").hide();
            $("#SPanModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_SPan").attr("disabled", false);
          $("#upload_btn20_SPan").show();
          $("#upload_btnloader_SPan").hide();
          $("#SPanModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSPan = () => {
    $("#X-btn_SPan").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "PANCard",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          SPanStatus = res.data.data.status;
          SStatusArray[1].status = SPanStatus;
          this.checkDropdownValue();
          this.checkhandleSProceedBtn();
          $("#choose_SPan").show();
          $("#X-btn_SPan").hide();
          $("#X-btn_SPan").attr("disabled", false);
          $("#viewDocLink_SPan").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_SPan").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_SPan").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSPanseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SPanseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PANCard",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#SPanseecommentsbtn").hide();
          $("#SPanhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSPanhidecommentsbtn = () => {
    $("#SPanseecommentsbtn").show();
    $("#SPanseecommentsbtn").attr("disabled", false);
    $("#SPanhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // SPic
  handleSPicChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "SolePhoto",
          },
          () => {
            $("#SPicModal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "SolePhoto",
          });
        }
      });
      $("#SPicModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSPic = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_SPic").attr("disabled", true);
      $("#upload_btn20_SPic").hide();
      $("#upload_btnloader_SPic").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  SPicDocLink: res.data.data.SolePhoto,
                },
                () => $("#viewDocLink_SPic").css("display", "block")
              );
              SPicStatus = "remove";
              SStatusArray[2].status = SPicStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#SPicModal").modal("hide");
              $("#cancelbtn_SPic").attr("disabled", false);
              $("#upload_btn20_SPic").show();
              $("#upload_btnloader_SPic").hide();
              $("#choose_SPic").hide();
              $("#X-btn_SPic").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_SPic").attr("disabled", false);
            $("#upload_btn20_SPic").show();
            $("#upload_btnloader_SPic").hide();
            $("#SPicModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_SPic").attr("disabled", false);
            $("#upload_btn20_SPic").show();
            $("#upload_btnloader_SPic").hide();
            $("#SPicModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_SPic").attr("disabled", false);
          $("#upload_btn20_SPic").show();
          $("#upload_btnloader_SPic").hide();
          $("#SPicModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSPic = () => {
    $("#X-btn_SPic").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "SolePhoto",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          SPicStatus = res.data.data.status;
          SStatusArray[2].status = SPicStatus;
          this.checkDropdownValue();
          this.checkhandleSProceedBtn();
          $("#choose_SPic").show();
          $("#X-btn_SPic").hide();
          $("#X-btn_SPic").attr("disabled", false);
          $("#viewDocLink_SPic").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_SPic").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_SPic").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSPicseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SPicseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "SolePhoto",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#SPicseecommentsbtn").hide();
          $("#SPichidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSPichidecommentsbtn = () => {
    $("#SPicseecommentsbtn").show();
    $("#SPicseecommentsbtn").attr("disabled", false);
    $("#SPichidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // SBP
  handleSBPChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "BankProof",
          },
          () => {
            $("#SBPModal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "BankProof",
          });
        }
      });
      $("#SBPModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSBP = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_SBP").attr("disabled", true);
      $("#upload_btn20_SBP").hide();
      $("#upload_btnloader_SBP").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  SBPDocLink: res.data.data.BankProof,
                },
                () => $("#viewDocLink_SBP").css("display", "block")
              );
              SBPStatus = "remove";
              SStatusArray[3].status = SBPStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#SBPModal").modal("hide");
              $("#cancelbtn_SBP").attr("disabled", false);
              $("#upload_btn20_SBP").show();
              $("#upload_btnloader_SBP").hide();
              $("#choose_SBP").hide();
              $("#X-btn_SBP").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_SBP").attr("disabled", false);
            $("#upload_btn20_SBP").show();
            $("#upload_btnloader_SBP").hide();
            $("#SBPModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_SBP").attr("disabled", false);
            $("#upload_btn20_SBP").show();
            $("#upload_btnloader_SBP").hide();
            $("#SBPModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_SBP").attr("disabled", false);
          $("#upload_btn20_SBP").show();
          $("#upload_btnloader_SBP").hide();
          $("#SBPModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSBP = () => {
    $("#X-btn_SBP").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "BankProof",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          SBPStatus = res.data.data.status;
          SStatusArray[3].status = SBPStatus;
          this.checkDropdownValue();
          this.checkhandleSProceedBtn();
          $("#choose_SBP").show();
          $("#X-btn_SBP").hide();
          $("#X-btn_SBP").attr("disabled", false);
          $("#viewDocLink_SBP").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_SBP").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_SBP").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSBPseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SBPseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "BankProof",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#SBPseecommentsbtn").hide();
          $("#SBPhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSBPhidecommentsbtn = () => {
    $("#SBPseecommentsbtn").show();
    $("#SBPseecommentsbtn").attr("disabled", false);
    $("#SBPhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // SGumasta
  handleSGumastaChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "Gumasta",
          },
          () => {
            $("#SGumastaModal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "Gumasta",
          });
        }
      });
      $("#SGumastaModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSGumasta = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_SGumasta").attr("disabled", true);
      $("#upload_btn20_SGumasta").hide();
      $("#upload_btnloader_SGumasta").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  SGumastaDocLink: res.data.data.Gumasta,
                },
                () => $("#viewDocLink_SGumasta").css("display", "block")
              );
              SGumastaStatus = "remove";
              SStatusArray[4].status = SGumastaStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#SGumastaModal").modal("hide");
              $("#cancelbtn_SGumasta").attr("disabled", false);
              $("#upload_btn20_SGumasta").show();
              $("#upload_btnloader_SGumasta").hide();
              $("#choose_SGumasta").hide();
              $("#X-btn_SGumasta").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_SGumasta").attr("disabled", false);
            $("#upload_btn20_SGumasta").show();
            $("#upload_btnloader_SGumasta").hide();
            $("#SGumastaModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_SGumasta").attr("disabled", false);
            $("#upload_btn20_SGumasta").show();
            $("#upload_btnloader_SGumasta").hide();
            $("#SGumastaModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_SGumasta").attr("disabled", false);
          $("#upload_btn20_SGumasta").show();
          $("#upload_btnloader_SGumasta").hide();
          $("#SGumastaModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSGumasta = () => {
    $("#X-btn_SGumasta").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "Gumasta",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          SGumastaStatus = res.data.data.status;
          SStatusArray[4].status = SGumastaStatus;
          this.checkDropdownValue();
          this.checkhandleSProceedBtn();
          $("#choose_SGumasta").show();
          $("#X-btn_SGumasta").hide();
          $("#X-btn_SGumasta").attr("disabled", false);
          $("#viewDocLink_SGumasta").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_SGumasta").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_SGumasta").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSGumastaseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SGumastaseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "Gumasta",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#SGumastaseecommentsbtn").hide();
          $("#SGumastahidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSGumastahidecommentsbtn = () => {
    $("#SGumastaseecommentsbtn").show();
    $("#SGumastaseecommentsbtn").attr("disabled", false);
    $("#SGumastahidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STradeLicence
  handleSTradeLicenceChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "TradeLicence",
          },
          () => {
            $("#STradeLicenceModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "TradeLicence",
          });
        }
      });
      $("#STradeLicenceModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTradeLicence = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STradeLicence").attr("disabled", true);
      $("#upload_btn20_STradeLicence").hide();
      $("#upload_btnloader_STradeLicence").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STradeLicenceDocLink: res.data.data.TradeLicence,
                },
                () => $("#viewDocLink_STradeLicence").css("display", "block")
              );
              STradeLicenceStatus = "remove";
              SStatusArray[5].status = STradeLicenceStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#STradeLicenceModal").modal("hide");
              $("#cancelbtn_STradeLicence").attr("disabled", false);
              $("#upload_btn20_STradeLicence").show();
              $("#upload_btnloader_STradeLicence").hide();
              $("#choose_STradeLicence").hide();
              $("#X-btn_STradeLicence").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STradeLicence").attr("disabled", false);
            $("#upload_btn20_STradeLicence").show();
            $("#upload_btnloader_STradeLicence").hide();
            $("#STradeLicenceModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STradeLicence").attr("disabled", false);
            $("#upload_btn20_STradeLicence").show();
            $("#upload_btnloader_STradeLicence").hide();
            $("#STradeLicenceModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STradeLicence").attr("disabled", false);
          $("#upload_btn20_STradeLicence").show();
          $("#upload_btnloader_STradeLicence").hide();
          $("#STradeLicenceModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTradeLicence = () => {
    $("#X-btn_STradeLicence").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "TradeLicence",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STradeLicenceStatus = res.data.data.status;
          SStatusArray[5].status = STradeLicenceStatus;
          this.checkDropdownValue();
          this.checkhandleSProceedBtn();
          $("#choose_STradeLicence").show();
          $("#X-btn_STradeLicence").hide();
          $("#X-btn_STradeLicence").attr("disabled", false);
          $("#viewDocLink_STradeLicence").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STradeLicence").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STradeLicence").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTradeLicenceseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STradeLicenceseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "TradeLicence",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STradeLicenceseecommentsbtn").hide();
          $("#STradeLicencehidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTradeLicencehidecommentsbtn = () => {
    $("#STradeLicenceseecommentsbtn").show();
    $("#STradeLicenceseecommentsbtn").attr("disabled", false);
    $("#STradeLicencehidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // SbusinessNature

  handleSbusinessNatureChange = (e) => {
    this.setState({
      SbusinessNature: e.target.value,
    });
  };

  // SAddressProofType
  SAddressProofType_radioChange = (e) => {
    this.setState(
      {
        SAddressProofType_radio: e.currentTarget.value,
      },
      () => {
        if (this.state.SAddressProofType_radio != "CopyOfRegistry") {
          $(".RentAgreement").css("display", "flex");
          $(".CopyOfRegistry").hide();
        } else if (this.state.SAddressProofType_radio == "CopyOfRegistry") {
          $(".CopyOfRegistry").css("display", "flex");
          $(".RentAgreement").hide();
        }
        this.checkhandleSProceedBtn();
      }
    );
  };

  // SCopyOfRegistry
  handleSCopyOfRegistryChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "CopyOfRegistry",
          },
          () => {
            $("#SCopyOfRegistryModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "CopyOfRegistry",
          });
        }
      });
      $("#SCopyOfRegistryModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSCopyOfRegistry = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_SCopyOfRegistry").attr("disabled", true);
      $("#upload_btn20_SCopyOfRegistry").hide();
      $("#upload_btnloader_SCopyOfRegistry").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  SCopyOfRegistryDocLink: res.data.data.CopyOfRegistry,
                },
                () => $("#viewDocLink_SCopyOfRegistry").css("display", "block")
              );
              SCopyOfRegistryStatus = "remove";
              SOptStatusArray[0].status = SCopyOfRegistryStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#SCopyOfRegistryModal").modal("hide");
              $("#cancelbtn_SCopyOfRegistry").attr("disabled", false);
              $("#upload_btn20_SCopyOfRegistry").show();
              $("#upload_btnloader_SCopyOfRegistry").hide();
              $("#choose_SCopyOfRegistry").hide();
              $("#X-btn_SCopyOfRegistry").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_SCopyOfRegistry").attr("disabled", false);
            $("#upload_btn20_SCopyOfRegistry").show();
            $("#upload_btnloader_SCopyOfRegistry").hide();
            $("#SCopyOfRegistryModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_SCopyOfRegistry").attr("disabled", false);
            $("#upload_btn20_SCopyOfRegistry").show();
            $("#upload_btnloader_SCopyOfRegistry").hide();
            $("#SCopyOfRegistryModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_SCopyOfRegistry").attr("disabled", false);
          $("#upload_btn20_SCopyOfRegistry").show();
          $("#upload_btnloader_SCopyOfRegistry").hide();
          $("#SCopyOfRegistryModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSCopyOfRegistry = () => {
    $("#X-btn_SCopyOfRegistry").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "CopyOfRegistry",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          SCopyOfRegistryStatus = res.data.data.status;
          SOptStatusArray[0].status = SCopyOfRegistryStatus;
          this.checkDropdownValue();
          this.checkhandleSProceedBtn();
          $("#choose_SCopyOfRegistry").show();
          $("#X-btn_SCopyOfRegistry").hide();
          $("#X-btn_SCopyOfRegistry").attr("disabled", false);
          $("#viewDocLink_SCopyOfRegistry").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_SCopyOfRegistry").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_SCopyOfRegistry").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSCopyOfRegistryseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SCopyOfRegistryseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "CopyOfRegistry",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#SCopyOfRegistryseecommentsbtn").hide();
          $("#SCopyOfRegistryhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSCopyOfRegistryhidecommentsbtn = () => {
    $("#SCopyOfRegistryseecommentsbtn").show();
    $("#SCopyOfRegistryseecommentsbtn").attr("disabled", false);
    $("#SCopyOfRegistryhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // SRentAgreement
  handleSRentAgreementChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "RentAgreement",
          },
          () => {
            $("#SRentAgreementModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "RentAgreement",
          });
        }
      });
      $("#SRentAgreementModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSRentAgreement = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_SRentAgreement").attr("disabled", true);
      $("#upload_btn20_SRentAgreement").hide();
      $("#upload_btnloader_SRentAgreement").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  SRentAgreementDocLink: res.data.data.RentAgreement,
                },
                () => $("#viewDocLink_SRentAgreement").css("display", "block")
              );
              SRentAgreementStatus = "remove";
              SOptStatusArray[1].status = SRentAgreementStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#SRentAgreementModal").modal("hide");
              $("#cancelbtn_SRentAgreement").attr("disabled", false);
              $("#upload_btn20_SRentAgreement").show();
              $("#upload_btnloader_SRentAgreement").hide();
              $("#choose_SRentAgreement").hide();
              $("#X-btn_SRentAgreement").show();
            }
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_SRentAgreement").attr("disabled", false);
            $("#upload_btn20_SRentAgreement").show();
            $("#upload_btnloader_SRentAgreement").hide();
            $("#SRentAgreementModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_SRentAgreement").attr("disabled", false);
            $("#upload_btn20_SRentAgreement").show();
            $("#upload_btnloader_SRentAgreement").hide();
            $("#SRentAgreementModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_SRentAgreement").attr("disabled", false);
          $("#upload_btn20_SRentAgreement").show();
          $("#upload_btnloader_SRentAgreement").hide();
          $("#SRentAgreementModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSRentAgreement = () => {
    $("#X-btn_SRentAgreement").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "RentAgreement",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          SRentAgreementStatus = res.data.data.status;
          SOptStatusArray[1].status = SRentAgreementStatus;
          this.checkDropdownValue();
          this.checkhandleSProceedBtn();
          $("#choose_SRentAgreement").show();
          $("#X-btn_SRentAgreement").hide();
          $("#X-btn_SRentAgreement").attr("disabled", false);
          $("#viewDocLink_SRentAgreement").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_SRentAgreement").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_SRentAgreement").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSRentAgreementseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SRentAgreementseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "RentAgreement",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#SRentAgreementseecommentsbtn").hide();
          $("#SRentAgreementhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSRentAgreementhidecommentsbtn = () => {
    $("#SRentAgreementseecommentsbtn").show();
    $("#SRentAgreementseecommentsbtn").attr("disabled", false);
    $("#SRentAgreementhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // SPR
  handleSPRChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "PropertyTaxReceipt",
          },
          () => {
            $("#SPRModal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "PropertyTaxReceipt",
          });
        }
      });
      $("#SPRModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSPR = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_SPR").attr("disabled", true);
      $("#upload_btn20_SPR").hide();
      $("#upload_btnloader_SPR").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  SPRDocLink: res.data.data.PropertyTaxReceipt,
                },
                () => $("#viewDocLink_SPR").css("display", "block")
              );
              SPRStatus = "remove";
              SStatusArray[6].status = SPRStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#SPRModal").modal("hide");
              $("#cancelbtn_SPR").attr("disabled", false);
              $("#upload_btn20_SPR").show();
              $("#upload_btnloader_SPR").hide();
              $("#choose_SPR").hide();
              $("#X-btn_SPR").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_SPR").attr("disabled", false);
            $("#upload_btn20_SPR").show();
            $("#upload_btnloader_SPR").hide();
            $("#SPRModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_SPR").attr("disabled", false);
            $("#upload_btn20_SPR").show();
            $("#upload_btnloader_SPR").hide();
            $("#SPRModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_SPR").attr("disabled", false);
          $("#upload_btn20_SPR").show();
          $("#upload_btnloader_SPR").hide();
          $("#SPRModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSPR = () => {
    $("#X-btn_SPR").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "PropertyTaxReceipt",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          SPRStatus = res.data.data.status;
          SStatusArray[6].status = SPRStatus;
          this.checkDropdownValue();
          this.checkhandleSProceedBtn();
          $("#choose_SPR").show();
          $("#X-btn_SPR").hide();
          $("#X-btn_SPR").attr("disabled", false);
          $("#viewDocLink_SPR").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_SPR").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_SPR").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSPRseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SPRseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PropertyTaxReceipt",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#SPRseecommentsbtn").hide();
          $("#SPRhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSPRhidecommentsbtn = () => {
    $("#SPRseecommentsbtn").show();
    $("#SPRseecommentsbtn").attr("disabled", false);
    $("#SPRhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // SAdditional
  handleSAddFileChange = (e, index, status) => {
    SadditionalReuploadIndex = index;
    SadditionalReuploadStatus = status;
    $(".upload_btn20_7").show();
    $(".upload_btnloader_7").hide();
    const file = e.target.files[0];
    if (!SadditionalFilesNames.includes(this.state.SAdd_DocName.trim())) {
      const myfile = e.target.files;
      if (ChooseNowBtnsDisable == "Yes") {
        this.showNotification(
          "Files cannot be uploaded when the service is locked!"
        );
      } else if (myfile.length == 1) {
        const file = myfile[0];
        if (file.type.match("audio.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.type.match("video.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.size > 104857600) {
          this.showNotification("File too big!");
        } else if (file.name.length > 100) {
          this.showNotification("File name limit exceed!");
        } else {
          this.setState(
            {
              fileName: file.name,
              fileBase64: file,
              documentType: "additional",
              documentName: this.state.SAdd_DocName,
            },
            () => {
              $(".SAddModal").modal({ backdrop: "static", keyboard: false });
            }
          );
        }
      } else if (myfile.length > 1) {
        var zip = new JSZip();
        for (let i = 0; i < myfile.length; i++) {
          zip.file(myfile[i].name, myfile[i], { base64: true });
        }
        zip.generateAsync({ type: "blob" }).then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "additional",
              documentName: this.state.SAdd_DocName,
            });
          }
        });
        $(".SAddModal").modal({ backdrop: "static", keyboard: false });
      } else {
        this.showNotification("File cannot be null!");
      }
    } else {
      this.showNotification(
        "Document names can not be the same, Try again with different name!"
      );
    }
  };

  uploadSFile = (FileIndex, FileStatus) => {
    let AdditionaldocumentName1;
    if (FileStatus == "reupload") {
      $(".proceednowbtn").attr("disabled", true);
      let fileTobeUploaded = this.state.SadditionalFiles[FileIndex];
      AdditionaldocumentName1 = Object.keys(fileTobeUploaded)[0];
      mainaction = "reupload";
    } else {
      AdditionaldocumentName1 = this.state.documentName;
      mainaction = "upload";
    }
    if (AdditionaldocumentName1.trim() == "") {
      $(".SAddModal").modal("hide");
      this.showNotification("Enter document Name first");
    } else if (this.state.comment.length <= 250) {
      $(".cancelbtn_7").attr("disabled", true);
      $(".upload_btn20_7").hide();
      $(".upload_btnloader_7").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName",AdditionaldocumentName1);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            $(".SAddModal").modal("hide");
            $(".cancelbtn_7").attr("disabled", false);
            $(`#${FileIndex}SFileseecommentsbtn`).attr("disabled", false);
            if (SadditionalReupload == false) {
              SadditionalFilesNames.push(this.state.SAdd_DocName);
              this.state.SadditionalFiles.pop();
              this.setState(
                {
                  comment: "",
                  SadditionalFiles: [
                    ...this.state.SadditionalFiles,
                    res.data.data,
                  ],
                },
                () => {
                  this.setState(
                    {
                      api_SadditionalFiles: "Yes",
                    },
                    () => {
                      this.SadditionalFilesArea();
                      this.checkDropdownValue();
                      this.checkhandleSProceedBtn();
                    }
                  );

                  $(".SadditionalInput").attr("disabled", true);
                }
              );
            } else if (SadditionalReupload == true) {
              $(".proceednowbtn").attr("disabled", false);
              var array = [...this.state.SadditionalFiles];
              array.splice(FileIndex, 1, res.data.data);
              this.setState(
                {
                  comment: "",
                  api_SadditionalFiles: "Yes",
                  SadditionalFiles: array,
                },
                () => {
                  this.SadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleSProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".SAddModal").modal("hide");
            this.showNotification("Something Went Wrong!");
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".SAddModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          this.showNotification("Something went wrong, Try again!");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSFile = (index) => {
    $("#X-btn_7").attr("disabled", true);
    let fileTobeDeleted = this.state.SadditionalFiles[index];
    let NameOfAddFile = Object.keys(fileTobeDeleted)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "additional",
          documentName: NameOfAddFile,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          var array = [...this.state.SadditionalFiles];
          if (res.data.data.status == "reupload") {
            array[index].status = "reupload";
            this.setState(
              {
                SadditionalFiles: array,
              },
              () => {
                this.SadditionalFilesArea();
                this.checkDropdownValue();
                this.checkhandleSProceedBtn();
              }
            );
            this.showNotification(res.data.message);
          } else {
            array.splice(index, 1);
            if (array.length > 0) {
              this.setState(
                {
                  SadditionalFiles: array,
                },
                () => {
                  this.SadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleSProceedBtn();
                }
              );
            } else if (array.length == 0) {
              this.setState(
                {
                  SadditionalFiles: [],
                  SAdd_DocName: "",
                  api_SadditionalFiles: "No",
                  IsSAddFileDisabled: true,
                },
                () => {
                  this.SadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleSProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_7").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSFileseeComments = (index) => {
    $(".commentsbtn").attr("disabled", false);
    $(`#${index}SFileseecommentsbtn`).attr("disabled", true);
    let SelectedFile = this.state.SadditionalFiles[index];
    let NameOfAddFile = Object.keys(SelectedFile)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "additional",
          documentName: NameOfAddFile,
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(`#${index}SFileseecommentsbtn`).hide();
          $(".hidecommentsbtn").hide();
          $(`#${index}SFilehidecommentsbtn`).show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSFilehideComments = (index) => {
    $(".seecommentsbtn").show();
    $(`#${index}SFileseecommentsbtn`).show();
    $(`#${index}SFileseecommentsbtn`).attr("disabled", false);
    $(`#${index}SFilehidecommentsbtn`).hide();
    $(".hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  checkAddCommentSFiles = (FileIndex, isDisabled) => {
    if (isDisabled == true) {
      return (
        <>
          <button
            className="seecommentsbtn SFileseecommentsbtn"
            id={`${FileIndex}SFileseecommentsbtn`}
            disabled
            onClick={() => this.handleSFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn SFilehidecommentsbtn"
            id={`${FileIndex}SFilehidecommentsbtn`}
            onClick={() => this.handleSFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    } else if (isDisabled == false) {
      return (
        <>
          <button
            className="seecommentsbtn commentsbtn SFileseecommentsbtn"
            id={`${FileIndex}SFileseecommentsbtn`}
            onClick={() => this.handleSFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn SFilehidecommentsbtn"
            id={`${FileIndex}SFilehidecommentsbtn`}
            onClick={() => this.handleSFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    }
  };

  checkStatusSAddFiles = (status, link, index) => {
    SadditionalFilesIndex = index;
    if (HideProceedBtn == "Yes") {
      $(".proceednowbtn").hide();
      if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
          </>
        );
      }
    } else {
      if (status == undefined) {
        status = "open";
      }
      if (status == "open") {
        mainaction = "upload";
        SadditionalReupload = true;
        $(".proceednowbtn").show();
        return (
          <>
            <label
              htmlFor={`ul_file_SAdd${index}`}
              className="choose_btn choose_7"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              multiple
              name="file[]"
              className="upload-photo"
              id={`ul_file_SAdd${index}`}
              // disabled={this.state.IsSAddFileDisabled}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handleSAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "remove") {
        mainaction = "upload";
        $(".proceednowbtn").show();
        return (
          <>
            <button
              className="X-btn X-btn_7"
              onClick={() => this.removeSFile(index)}
            >
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_1025.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "reupload") {
        SadditionalReupload = true;
        mainaction = "reupload";
        $(".proceednowbtn").show();
        $(".proceednowbtn").attr("disabled", true);
        return (
          <>
            <label
              htmlFor={`ul_file_SAdd${index}`}
              className="choose_btn choose_7"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              multiple
              name="file[]"
              className="upload-photo"
              id={`ul_file_SAdd${index}`}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handleSAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
          </>
        );
      }
    }
  };

  AdditionalSFileDisabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero SadditionalInput"
        value={NameOfAddFile}
        disabled
        onChange={(e) => this.handleSAdd_DocNameChange(e)}
      />
    );
  };

  AdditionalSFileEnabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero SadditionalInput"
        value={NameOfAddFile}
        onChange={(e) => this.handleSAdd_DocNameChange(e)}
      />
    );
  };

  SadditionalFilesArea = () => {
    SadditionalFilesNames = [];
    if (this.state.api_SadditionalFiles == "Yes") {
      return this.state.SadditionalFiles.map((file, index) => {
        let NameOfAddFile = Object.keys(file)[0];
        SadditionalFilesNames.push(NameOfAddFile);
        let link = file[Object.keys(file)[0]];
        let AdditionalFileInputField;
        let commentDisabled;
        if (file.status == "open" || file.status == undefined) {
          AdditionalFileInputField =
            this.AdditionalSFileEnabledField(NameOfAddFile);
          commentDisabled = true;
        } else if (file.status != "open") {
          AdditionalFileInputField =
            this.AdditionalSFileDisabledField(NameOfAddFile);
          commentDisabled = false;
        }
        return (
          <div className="displayflex" key={index}>
            <div className="uploadnocol">
              <p className="uploadadditionalsidetext">{index + 1}&#41;</p>
            </div>
            <div className="uploadadditionaldatacol">
              <div className="row mb-2">
                <div className="col-md-5">{AdditionalFileInputField}</div>
                <div className="col-md-7">
                  <div className="float-md-right float-left">
                    {this.checkAddCommentSFiles(index, commentDisabled)}
                    {this.checkStatusSAddFiles(file.status, link, index)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.api_SadditionalFiles == "No") {
      return (
        <div className="displayflex">
          <div className="uploadnocol">
            <p className="uploadadditionalsidetext">1&#41;</p>
          </div>
          <div className="uploadadditionaldatacol">
            <div className="row mb-2">
              <div className="col-md-5">
                <input
                  type="text"
                  placeholder="Document name..."
                  maxLength="150"
                  className="uploadtextfield marginzero"
                  value={this.state.SAdd_DocName}
                  onChange={(e) => this.handleSAdd_DocNameChange(e)}
                />
              </div>
              <div className="col-md-7">
                <div className="fl_right">
                  {this.checkAddCommentSFiles(0, true)}
                  {this.checkStatusSAddFiles("open", null, 0)}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  handleSAdd_DocNameChange = (e) => {
    this.setState(
      {
        SAdd_DocName: e.target.value,
      },
      () => {
        if (this.state.SAdd_DocName.trim().length > 0) {
          this.setState({
            IsSAddFileDisabled: false,
          });
        } else {
          this.setState({
            IsSAddFileDisabled: true,
          });
        }
      }
    );
  };

  handleAddSFileBtn = (FileIndex) => {
    const { SAdd_DocName, SadditionalFiles } = this.state;

    let prevFile = SadditionalFiles[FileIndex];

    let NameOfPrevFile = Object.keys(prevFile)[0];
    let valueOffile = prevFile[Object.keys(prevFile)[0]];

    if (FileIndex == undefined) {
      this.showNotification("empty fields");
    } else if (SAdd_DocName.trim() == "") {
      this.showNotification("Required fields cannot be empty!");
    } else if (valueOffile == null) {
      this.showNotification("Choose a file");
    } else {
      this.setState(
        {
          SadditionalFiles: [...this.state.SadditionalFiles, ""],
          SAdd_DocName: "",
          IsSAddFileDisabled: true,
        },
        () => {
          $(".SadditionalInput").attr("disabled", false);
        }
      );
    }
  };

  // Proceed Checks
  ScheckRemainingReuploads = () => {
    if (
      SAadharStatus != "reupload" &&
      SPanStatus != "reupload" &&
      SPicStatus != "reupload" &&
      SBPStatus != "reupload" &&
      SGumastaStatus != "reupload" &&
      STradeLicenceStatus != "reupload" &&
      SCopyOfRegistryStatus != "reupload" &&
      SRentAgreementStatus != "reupload" &&
      SPRStatus != "reupload"
    ) {
      AnyReuploadOccuredS = "No";
    } else {
      $(".proceednowbtn1").show();
      AnyReuploadOccuredS = "Yes";
    }
  };

  checkhandleSProceedBtn = () => {
    let statusCheckReupload = this.statusCheckFunction(
      "reupload",
      SStatusArray
    );
    let SadditionalStatusArrayCheck = this.statusCheckFunction(
      "reupload",
      this.state.SadditionalFiles
    );
    let goodtogo = "No";
    if (this.state.SAddressProofType_radio == "CopyOfRegistry") {
      if (
        SOptStatusArray[0].status != "reupload" &&
        SOptStatusArray[0].status != "upload" &&
        SOptStatusArray[0].status != "open"
      ) {
        goodtogo = "Yes";
      } else {
        goodtogo = "No";
      }
    } else if (this.state.SAddressProofType_radio == "RentAgreement") {
      if (
        SOptStatusArray[1].status != "reupload" &&
        SOptStatusArray[1].status != "upload" &&
        SOptStatusArray[1].status != "open"
      ) {
        goodtogo = "Yes";
      } else {
        goodtogo = "No";
      }
    } else {
      goodtogo = "No";
    }

    if (
      AnyReuploadOccuredS == "Yes" &&
      statusCheckReupload == undefined &&
      goodtogo == "Yes" &&
      SadditionalStatusArrayCheck == undefined
    ) {
      $(".proceednowbtn1").attr("disabled", false);
      Sproceednowforword = "success";
    } else if (
      SAadharStatus == "remove" &&
      SPanStatus == "remove" &&
      SPicStatus == "remove" &&
      SBPStatus == "remove" &&
      SGumastaStatus == "remove" &&
      STradeLicenceStatus == "remove" &&
      SPRStatus == "remove" &&
      goodtogo == "Yes"
    ) {
      $(".proceednowbtn1").attr("disabled", false);
      Sproceednowforword = "success";
    } else {
      $(".proceednowbtn1").attr("disabled", true);
      Sproceednowforword = "fail";
    }
  };

  handleSProceedBtn = () => {
    this.checkhandleSProceedBtn();
    if (this.state.SbusinessNature.trim() == "") {
      this.showNotification("Required fields can not be empty!");
    } else if (Sproceednowforword == "success") {
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=lockService",
          {
            userId: Decuserid,
            requestType: temp,
            agentId: Decagentid,

            serviceId: Decserviceid,
            serviceName: Decservicename,
            serviceType: this.state.serviceType,
            FY: "",
            Month: "",
            inputFields: [
              {
                inputFieldName: "AddressProofType",
                inputFieldValue: this.state.SAddressProofType_radio,
                inputFieldtype: "general",
              },
              {
                inputFieldName: "Nature",
                inputFieldValue: this.state.SbusinessNature,
                inputFieldtype: "general",
              },
            ],
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.showNotification(res.data.message);
            this.getserviceInformationApi();
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else {
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {});
    }
  };

  // Partnership

  // PAadhar1
  handlePAadhar1Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "AadhaarCardApplicant1",
          },
          () => {
            $("#PAadhar1Modal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "AadhaarCardApplicant1",
          });
        }
      });
      $("#PAadhar1Modal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPAadhar1 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PAadhar1").attr("disabled", true);
      $("#upload_btn20_PAadhar1").hide();
      $("#upload_btnloader_PAadhar1").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PAadhar1DocLink: res.data.data.AadhaarCardApplicant1,
                },
                () => $("#viewDocLink_PAadhar1").css("display", "block")
              );
              PAadhar1Status = "remove";
              PStatusArray[0].status = PAadhar1Status;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PAadhar1Modal").modal("hide");
              $("#cancelbtn_PAadhar1").attr("disabled", false);
              $("#upload_btn20_PAadhar1").show();
              $("#upload_btnloader_PAadhar1").hide();
              $("#choose_PAadhar1").hide();
              $("#X-btn_PAadhar1").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PAadhar1").attr("disabled", false);
            $("#upload_btn20_PAadhar1").show();
            $("#upload_btnloader_PAadhar1").hide();
            $("#PAadhar1Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PAadhar1").attr("disabled", false);
            $("#upload_btn20_PAadhar1").show();
            $("#upload_btnloader_PAadhar1").hide();
            $("#PAadhar1Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PAadhar1").attr("disabled", false);
          $("#upload_btn20_PAadhar1").show();
          $("#upload_btnloader_PAadhar1").hide();
          $("#PAadhar1Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePAadhar1 = () => {
    $("#X-btn_PAadhar1").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "AadhaarCardApplicant1",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PAadhar1Status = res.data.data.status;
          PStatusArray[0].status = PAadhar1Status;
          this.checkDropdownValue();
          this.checkhandlePProceedBtn();
          $("#choose_PAadhar1").show();
          $("#X-btn_PAadhar1").hide();
          $("#X-btn_PAadhar1").attr("disabled", false);
          $("#viewDocLink_PAadhar1").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PAadhar1").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PAadhar1").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePAadhar1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PAadhar1seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "AadhaarCardApplicant1",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PAadhar1seecommentsbtn").hide();
          $("#PAadhar1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePAadhar1hidecommentsbtn = () => {
    $("#PAadhar1seecommentsbtn").show();
    $("#PAadhar1seecommentsbtn").attr("disabled", false);
    $("#PAadhar1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PAadhar2
  handlePAadhar2Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "AadhaarCardApplicant2",
          },
          () => {
            $("#PAadhar2Modal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "AadhaarCardApplicant2",
          });
        }
      });
      $("#PAadhar2Modal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPAadhar2 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PAadhar2").attr("disabled", true);
      $("#upload_btn20_PAadhar2").hide();
      $("#upload_btnloader_PAadhar2").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PAadhar2DocLink: res.data.data.AadhaarCardApplicant2,
                },
                () => $("#viewDocLink_PAadhar2").css("display", "block")
              );
              PAadhar2Status = "remove";
              PStatusArray[1].status = PAadhar2Status;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PAadhar2Modal").modal("hide");
              $("#cancelbtn_PAadhar2").attr("disabled", false);
              $("#upload_btn20_PAadhar2").show();
              $("#upload_btnloader_PAadhar2").hide();
              $("#choose_PAadhar2").hide();
              $("#X-btn_PAadhar2").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PAadhar2").attr("disabled", false);
            $("#upload_btn20_PAadhar2").show();
            $("#upload_btnloader_PAadhar2").hide();
            $("#PAadhar2Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PAadhar2").attr("disabled", false);
            $("#upload_btn20_PAadhar2").show();
            $("#upload_btnloader_PAadhar2").hide();
            $("#PAadhar2Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PAadhar2").attr("disabled", false);
          $("#upload_btn20_PAadhar2").show();
          $("#upload_btnloader_PAadhar2").hide();
          $("#PAadhar2Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePAadhar2 = () => {
    $("#X-btn_PAadhar2").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "AadhaarCardApplicant2",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PAadhar2Status = res.data.data.status;
          PStatusArray[1].status = PAadhar2Status;
          this.checkDropdownValue();
          this.checkhandlePProceedBtn();
          $("#choose_PAadhar2").show();
          $("#X-btn_PAadhar2").hide();
          $("#X-btn_PAadhar2").attr("disabled", false);
          $("#viewDocLink_PAadhar2").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PAadhar2").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PAadhar2").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePAadhar2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PAadhar2seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "AadhaarCardApplicant2",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PAadhar2seecommentsbtn").hide();
          $("#PAadhar2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePAadhar2hidecommentsbtn = () => {
    $("#PAadhar2seecommentsbtn").show();
    $("#PAadhar2seecommentsbtn").attr("disabled", false);
    $("#PAadhar2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PPan1
  handlePPan1Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "PANCardApplicant1",
          },
          () => {
            $("#PPan1Modal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "PANCardApplicant1",
          });
        }
      });
      $("#PPan1Modal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPPan1 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PPan1").attr("disabled", true);
      $("#upload_btn20_PPan1").hide();
      $("#upload_btnloader_PPan1").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PPan1DocLink: res.data.data.PANCardApplicant1,
                },
                () => $("#viewDocLink_PPan1").css("display", "block")
              );
              PPan1Status = "remove";
              PStatusArray[2].status = PPan1Status;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PPan1Modal").modal("hide");
              $("#cancelbtn_PPan1").attr("disabled", false);
              $("#upload_btn20_PPan1").show();
              $("#upload_btnloader_PPan1").hide();
              $("#choose_PPan1").hide();
              $("#X-btn_PPan1").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PPan1").attr("disabled", false);
            $("#upload_btn20_PPan1").show();
            $("#upload_btnloader_PPan1").hide();
            $("#PPan1Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PPan1").attr("disabled", false);
            $("#upload_btn20_PPan1").show();
            $("#upload_btnloader_PPan1").hide();
            $("#PPan1Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PPan1").attr("disabled", false);
          $("#upload_btn20_PPan1").show();
          $("#upload_btnloader_PPan1").hide();
          $("#PPan1Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePPan1 = () => {
    $("#X-btn_PPan1").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "PANCardApplicant1",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PPan1Status = res.data.data.status;
          PStatusArray[2].status = PPan1Status;
          this.checkDropdownValue();
          this.checkhandlePProceedBtn();
          $("#choose_PPan1").show();
          $("#X-btn_PPan1").hide();
          $("#X-btn_PPan1").attr("disabled", false);
          $("#viewDocLink_PPan1").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PPan1").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PPan1").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePPan1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PPan1seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PANCardApplicant1",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PPan1seecommentsbtn").hide();
          $("#PPan1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePPan1hidecommentsbtn = () => {
    $("#PPan1seecommentsbtn").show();
    $("#PPan1seecommentsbtn").attr("disabled", false);
    $("#PPan1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PPan2
  handlePPan2Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "PANCardApplicant2",
          },
          () => {
            $("#PPan2Modal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "PANCardApplicant2",
          });
        }
      });
      $("#PPan2Modal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPPan2 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PPan2").attr("disabled", true);
      $("#upload_btn20_PPan2").hide();
      $("#upload_btnloader_PPan2").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PPan2DocLink: res.data.data.PANCardApplicant2,
                },
                () => $("#viewDocLink_PPan2").css("display", "block")
              );
              PPan2Status = "remove";
              PStatusArray[3].status = PPan2Status;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PPan2Modal").modal("hide");
              $("#cancelbtn_PPan2").attr("disabled", false);
              $("#upload_btn20_PPan2").show();
              $("#upload_btnloader_PPan2").hide();
              $("#choose_PPan2").hide();
              $("#X-btn_PPan2").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PPan2").attr("disabled", false);
            $("#upload_btn20_PPan2").show();
            $("#upload_btnloader_PPan2").hide();
            $("#PPan2Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PPan2").attr("disabled", false);
            $("#upload_btn20_PPan2").show();
            $("#upload_btnloader_PPan2").hide();
            $("#PPan2Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PPan2").attr("disabled", false);
          $("#upload_btn20_PPan2").show();
          $("#upload_btnloader_PPan2").hide();
          $("#PPan2Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePPan2 = () => {
    $("#X-btn_PPan2").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "PANCardApplicant2",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PPan2Status = res.data.data.status;
          PStatusArray[3].status = PPan2Status;
          this.checkDropdownValue();
          this.checkhandlePProceedBtn();
          $("#choose_PPan2").show();
          $("#X-btn_PPan2").hide();
          $("#X-btn_PPan2").attr("disabled", false);
          $("#viewDocLink_PPan2").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PPan2").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PPan2").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePPan2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PPan2seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PANCardApplicant2",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PPan2seecommentsbtn").hide();
          $("#PPan2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePPan2hidecommentsbtn = () => {
    $("#PPan2seecommentsbtn").show();
    $("#PPan2seecommentsbtn").attr("disabled", false);
    $("#PPan2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PPic1
  handlePPic1Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "ApplicantPhoto1",
          },
          () => {
            $("#PPic1Modal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "ApplicantPhoto1",
          });
        }
      });
      $("#PPic1Modal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPPic1 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PPic1").attr("disabled", true);
      $("#upload_btn20_PPic1").hide();
      $("#upload_btnloader_PPic1").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PPic1DocLink: res.data.data.ApplicantPhoto1,
                },
                () => $("#viewDocLink_PPic1").css("display", "block")
              );
              PPic1Status = "remove";
              PStatusArray[4].status = PPic1Status;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PPic1Modal").modal("hide");
              $("#cancelbtn_PPic1").attr("disabled", false);
              $("#upload_btn20_PPic1").show();
              $("#upload_btnloader_PPic1").hide();
              $("#choose_PPic1").hide();
              $("#X-btn_PPic1").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PPic1").attr("disabled", false);
            $("#upload_btn20_PPic1").show();
            $("#upload_btnloader_PPic1").hide();
            $("#PPic1Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PPic1").attr("disabled", false);
            $("#upload_btn20_PPic1").show();
            $("#upload_btnloader_PPic1").hide();
            $("#PPic1Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PPic1").attr("disabled", false);
          $("#upload_btn20_PPic1").show();
          $("#upload_btnloader_PPic1").hide();
          $("#PPic1Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePPic1 = () => {
    $("#X-btn_PPic1").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "ApplicantPhoto1",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PPic1Status = res.data.data.status;
          PStatusArray[4].status = PPic1Status;
          this.checkDropdownValue();
          this.checkhandlePProceedBtn();
          $("#choose_PPic1").show();
          $("#X-btn_PPic1").hide();
          $("#X-btn_PPic1").attr("disabled", false);
          $("#viewDocLink_PPic1").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PPic1").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PPic1").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePPic1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PPic1seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ApplicantPhoto1",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PPic1seecommentsbtn").hide();
          $("#PPic1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePPic1hidecommentsbtn = () => {
    $("#PPic1seecommentsbtn").show();
    $("#PPic1seecommentsbtn").attr("disabled", false);
    $("#PPic1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PPic2
  handlePPic2Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "ApplicantPhoto2",
          },
          () => {
            $("#PPic2Modal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "ApplicantPhoto2",
          });
        }
      });
      $("#PPic2Modal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPPic2 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PPic2").attr("disabled", true);
      $("#upload_btn20_PPic2").hide();
      $("#upload_btnloader_PPic2").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PPic2DocLink: res.data.data.ApplicantPhoto2,
                },
                () => $("#viewDocLink_PPic2").css("display", "block")
              );
              PPic2Status = "remove";
              PStatusArray[5].status = PPic2Status;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PPic2Modal").modal("hide");
              $("#cancelbtn_PPic2").attr("disabled", false);
              $("#upload_btn20_PPic2").show();
              $("#upload_btnloader_PPic2").hide();
              $("#choose_PPic2").hide();
              $("#X-btn_PPic2").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PPic2").attr("disabled", false);
            $("#upload_btn20_PPic2").show();
            $("#upload_btnloader_PPic2").hide();
            $("#PPic2Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PPic2").attr("disabled", false);
            $("#upload_btn20_PPic2").show();
            $("#upload_btnloader_PPic2").hide();
            $("#PPic2Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PPic2").attr("disabled", false);
          $("#upload_btn20_PPic2").show();
          $("#upload_btnloader_PPic2").hide();
          $("#PPic2Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePPic2 = () => {
    $("#X-btn_PPic2").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "ApplicantPhoto2",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PPic2Status = res.data.data.status;
          PStatusArray[5].status = PPic2Status;
          this.checkDropdownValue();
          this.checkhandlePProceedBtn();
          $("#choose_PPic2").show();
          $("#X-btn_PPic2").hide();
          $("#X-btn_PPic2").attr("disabled", false);
          $("#viewDocLink_PPic2").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PPic2").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PPic2").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePPic2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PPic2seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ApplicantPhoto2",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PPic2seecommentsbtn").hide();
          $("#PPic2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePPic2hidecommentsbtn = () => {
    $("#PPic2seecommentsbtn").show();
    $("#PPic2seecommentsbtn").attr("disabled", false);
    $("#PPic2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PBP
  handlePBPChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "BankProof",
          },
          () => {
            $("#PBPModal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "BankProof",
          });
        }
      });
      $("#PBPModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPBP = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PBP").attr("disabled", true);
      $("#upload_btn20_PBP").hide();
      $("#upload_btnloader_PBP").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PBPDocLink: res.data.data.BankProof,
                },
                () => $("#viewDocLink_PBP").css("display", "block")
              );
              PBPStatus = "remove";
              PStatusArray[6].status = PBPStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PBPModal").modal("hide");
              $("#cancelbtn_PBP").attr("disabled", false);
              $("#upload_btn20_PBP").show();
              $("#upload_btnloader_PBP").hide();
              $("#choose_PBP").hide();
              $("#X-btn_PBP").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PBP").attr("disabled", false);
            $("#upload_btn20_PBP").show();
            $("#upload_btnloader_PBP").hide();
            $("#PBPModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PBP").attr("disabled", false);
            $("#upload_btn20_PBP").show();
            $("#upload_btnloader_PBP").hide();
            $("#PBPModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PBP").attr("disabled", false);
          $("#upload_btn20_PBP").show();
          $("#upload_btnloader_PBP").hide();
          $("#PBPModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePBP = () => {
    $("#X-btn_PBP").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "BankProof",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PBPStatus = res.data.data.status;
          PStatusArray[6].status = PBPStatus;
          this.checkDropdownValue();
          this.checkhandlePProceedBtn();
          $("#choose_PBP").show();
          $("#X-btn_PBP").hide();
          $("#X-btn_PBP").attr("disabled", false);
          $("#viewDocLink_PBP").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PBP").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PBP").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePBPseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PBPseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "BankProof",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PBPseecommentsbtn").hide();
          $("#PBPhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePBPhidecommentsbtn = () => {
    $("#PBPseecommentsbtn").show();
    $("#PBPseecommentsbtn").attr("disabled", false);
    $("#PBPhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PGumasta
  handlePGumastaChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "Gumasta",
          },
          () => {
            $("#PGumastaModal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "Gumasta",
          });
        }
      });
      $("#PGumastaModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPGumasta = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PGumasta").attr("disabled", true);
      $("#upload_btn20_PGumasta").hide();
      $("#upload_btnloader_PGumasta").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PGumastaDocLink: res.data.data.Gumasta,
                },
                () => $("#viewDocLink_PGumasta").css("display", "block")
              );
              PGumastaStatus = "remove";
              PStatusArray[7].status = PGumastaStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PGumastaModal").modal("hide");
              $("#cancelbtn_PGumasta").attr("disabled", false);
              $("#upload_btn20_PGumasta").show();
              $("#upload_btnloader_PGumasta").hide();
              $("#choose_PGumasta").hide();
              $("#X-btn_PGumasta").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PGumasta").attr("disabled", false);
            $("#upload_btn20_PGumasta").show();
            $("#upload_btnloader_PGumasta").hide();
            $("#PGumastaModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PGumasta").attr("disabled", false);
            $("#upload_btn20_PGumasta").show();
            $("#upload_btnloader_PGumasta").hide();
            $("#PGumastaModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PGumasta").attr("disabled", false);
          $("#upload_btn20_PGumasta").show();
          $("#upload_btnloader_PGumasta").hide();
          $("#PGumastaModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePGumasta = () => {
    $("#X-btn_PGumasta").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "Gumasta",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PGumastaStatus = res.data.data.status;
          PStatusArray[7].status = PGumastaStatus;
          this.checkDropdownValue();
          this.checkhandlePProceedBtn();
          $("#choose_PGumasta").show();
          $("#X-btn_PGumasta").hide();
          $("#X-btn_PGumasta").attr("disabled", false);
          $("#viewDocLink_PGumasta").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PGumasta").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PGumasta").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePGumastaseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PGumastaseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "Gumasta",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PGumastaseecommentsbtn").hide();
          $("#PGumastahidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePGumastahidecommentsbtn = () => {
    $("#PGumastaseecommentsbtn").show();
    $("#PGumastaseecommentsbtn").attr("disabled", false);
    $("#PGumastahidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PTradeLicence
  handlePTradeLicenceChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "TradeLicence",
          },
          () => {
            $("#PTradeLicenceModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "TradeLicence",
          });
        }
      });
      $("#PTradeLicenceModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPTradeLicence = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PTradeLicence").attr("disabled", true);
      $("#upload_btn20_PTradeLicence").hide();
      $("#upload_btnloader_PTradeLicence").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PTradeLicenceDocLink: res.data.data.TradeLicence,
                },
                () => $("#viewDocLink_PTradeLicence").css("display", "block")
              );
              PTradeLicenceStatus = "remove";
              PStatusArray[8].status = PTradeLicenceStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PTradeLicenceModal").modal("hide");
              $("#cancelbtn_PTradeLicence").attr("disabled", false);
              $("#upload_btn20_PTradeLicence").show();
              $("#upload_btnloader_PTradeLicence").hide();
              $("#choose_PTradeLicence").hide();
              $("#X-btn_PTradeLicence").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PTradeLicence").attr("disabled", false);
            $("#upload_btn20_PTradeLicence").show();
            $("#upload_btnloader_PTradeLicence").hide();
            $("#PTradeLicenceModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PTradeLicence").attr("disabled", false);
            $("#upload_btn20_PTradeLicence").show();
            $("#upload_btnloader_PTradeLicence").hide();
            $("#PTradeLicenceModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PTradeLicence").attr("disabled", false);
          $("#upload_btn20_PTradeLicence").show();
          $("#upload_btnloader_PTradeLicence").hide();
          $("#PTradeLicenceModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePTradeLicence = () => {
    $("#X-btn_PTradeLicence").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "TradeLicence",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PTradeLicenceStatus = res.data.data.status;
          PStatusArray[8].status = PTradeLicenceStatus;
          this.checkDropdownValue();
          this.checkhandlePProceedBtn();
          $("#choose_PTradeLicence").show();
          $("#X-btn_PTradeLicence").hide();
          $("#X-btn_PTradeLicence").attr("disabled", false);
          $("#viewDocLink_PTradeLicence").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PTradeLicence").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PTradeLicence").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePTradeLicenceseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PTradeLicenceseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "TradeLicence",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PTradeLicenceseecommentsbtn").hide();
          $("#PTradeLicencehidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePTradeLicencehidecommentsbtn = () => {
    $("#PTradeLicenceseecommentsbtn").show();
    $("#PTradeLicenceseecommentsbtn").attr("disabled", false);
    $("#PTradeLicencehidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PDeed
  handlePDeedChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "PartnershipDeed",
          },
          () => {
            $("#PDeedModal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "PartnershipDeed",
          });
        }
      });
      $("#PDeedModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPDeed = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PDeed").attr("disabled", true);
      $("#upload_btn20_PDeed").hide();
      $("#upload_btnloader_PDeed").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PDeedDocLink: res.data.data.PartnershipDeed,
                },
                () => $("#viewDocLink_PDeed").css("display", "block")
              );
              PDeedStatus = "remove";
              PStatusArray[10].status = PDeedStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PDeedModal").modal("hide");
              $("#cancelbtn_PDeed").attr("disabled", false);
              $("#upload_btn20_PDeed").show();
              $("#upload_btnloader_PDeed").hide();
              $("#choose_PDeed").hide();
              $("#X-btn_PDeed").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PDeed").attr("disabled", false);
            $("#upload_btn20_PDeed").show();
            $("#upload_btnloader_PDeed").hide();
            $("#PDeedModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PDeed").attr("disabled", false);
            $("#upload_btn20_PDeed").show();
            $("#upload_btnloader_PDeed").hide();
            $("#PDeedModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PDeed").attr("disabled", false);
          $("#upload_btn20_PDeed").show();
          $("#upload_btnloader_PDeed").hide();
          $("#PDeedModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePDeed = () => {
    $("#X-btn_PDeed").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "PartnershipDeed",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PDeedStatus = res.data.data.status;
          PStatusArray[10].status = PDeedStatus;
          this.checkDropdownValue();
          this.checkhandlePProceedBtn();
          $("#choose_PDeed").show();
          $("#X-btn_PDeed").hide();
          $("#X-btn_PDeed").attr("disabled", false);
          $("#viewDocLink_PDeed").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PDeed").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PDeed").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePDeedseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PDeedseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PartnershipDeed",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PDeedseecommentsbtn").hide();
          $("#PDeedhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePDeedhidecommentsbtn = () => {
    $("#PDeedseecommentsbtn").show();
    $("#PDeedseecommentsbtn").attr("disabled", false);
    $("#PDeedhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PbusinessNature

  handlePbusinessNatureChange = (e) => {
    this.setState({
      PbusinessNature: e.target.value,
    });
  };

  // PAddressProofType
  PAddressProofType_radioChange = (e) => {
    this.setState(
      {
        PAddressProofType_radio: e.currentTarget.value,
      },
      () => {
        if (this.state.PAddressProofType_radio != "CopyOfRegistry") {
          $(".RentAgreement").css("display", "flex");
          $(".CopyOfRegistry").hide();
        } else if (this.state.PAddressProofType_radio == "CopyOfRegistry") {
          $(".CopyOfRegistry").css("display", "flex");
          $(".RentAgreement").hide();
        }
        this.checkhandlePProceedBtn();
      }
    );
  };

  // PCopyOfRegistry
  handlePCopyOfRegistryChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "CopyOfRegistry",
          },
          () => {
            $("#PCopyOfRegistryModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "CopyOfRegistry",
          });
        }
      });
      $("#PCopyOfRegistryModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPCopyOfRegistry = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PCopyOfRegistry").attr("disabled", true);
      $("#upload_btn20_PCopyOfRegistry").hide();
      $("#upload_btnloader_PCopyOfRegistry").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PCopyOfRegistryDocLink: res.data.data.CopyOfRegistry,
                },
                () => $("#viewDocLink_PCopyOfRegistry").css("display", "block")
              );
              PCopyOfRegistryStatus = "remove";
              POptStatusArray[0].status = PCopyOfRegistryStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PCopyOfRegistryModal").modal("hide");
              $("#cancelbtn_PCopyOfRegistry").attr("disabled", false);
              $("#upload_btn20_PCopyOfRegistry").show();
              $("#upload_btnloader_PCopyOfRegistry").hide();
              $("#choose_PCopyOfRegistry").hide();
              $("#X-btn_PCopyOfRegistry").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PCopyOfRegistry").attr("disabled", false);
            $("#upload_btn20_PCopyOfRegistry").show();
            $("#upload_btnloader_PCopyOfRegistry").hide();
            $("#PCopyOfRegistryModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PCopyOfRegistry").attr("disabled", false);
            $("#upload_btn20_PCopyOfRegistry").show();
            $("#upload_btnloader_PCopyOfRegistry").hide();
            $("#PCopyOfRegistryModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PCopyOfRegistry").attr("disabled", false);
          $("#upload_btn20_PCopyOfRegistry").show();
          $("#upload_btnloader_PCopyOfRegistry").hide();
          $("#PCopyOfRegistryModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePCopyOfRegistry = () => {
    $("#X-btn_PCopyOfRegistry").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "CopyOfRegistry",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PCopyOfRegistryStatus = res.data.data.status;
          POptStatusArray[0].status = PCopyOfRegistryStatus;
          this.checkDropdownValue();
          this.checkhandlePProceedBtn();
          $("#choose_PCopyOfRegistry").show();
          $("#X-btn_PCopyOfRegistry").hide();
          $("#X-btn_PCopyOfRegistry").attr("disabled", false);
          $("#viewDocLink_PCopyOfRegistry").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PCopyOfRegistry").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PCopyOfRegistry").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePCopyOfRegistryseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PCopyOfRegistryseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "CopyOfRegistry",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PCopyOfRegistryseecommentsbtn").hide();
          $("#PCopyOfRegistryhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePCopyOfRegistryhidecommentsbtn = () => {
    $("#PCopyOfRegistryseecommentsbtn").show();
    $("#PCopyOfRegistryseecommentsbtn").attr("disabled", false);
    $("#PCopyOfRegistryhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PRentAgreement
  handlePRentAgreementChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "RentAgreement",
          },
          () => {
            $("#PRentAgreementModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "RentAgreement",
          });
        }
      });
      $("#PRentAgreementModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPRentAgreement = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PRentAgreement").attr("disabled", true);
      $("#upload_btn20_PRentAgreement").hide();
      $("#upload_btnloader_PRentAgreement").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PRentAgreementDocLink: res.data.data.RentAgreement,
                },
                () => $("#viewDocLink_PRentAgreement").css("display", "block")
              );
              PRentAgreementStatus = "remove";
              POptStatusArray[1].status = PRentAgreementStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PRentAgreementModal").modal("hide");
              $("#cancelbtn_PRentAgreement").attr("disabled", false);
              $("#upload_btn20_PRentAgreement").show();
              $("#upload_btnloader_PRentAgreement").hide();
              $("#choose_PRentAgreement").hide();
              $("#X-btn_PRentAgreement").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PRentAgreement").attr("disabled", false);
            $("#upload_btn20_PRentAgreement").show();
            $("#upload_btnloader_PRentAgreement").hide();
            $("#PRentAgreementModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PRentAgreement").attr("disabled", false);
            $("#upload_btn20_PRentAgreement").show();
            $("#upload_btnloader_PRentAgreement").hide();
            $("#PRentAgreementModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PRentAgreement").attr("disabled", false);
          $("#upload_btn20_PRentAgreement").show();
          $("#upload_btnloader_PRentAgreement").hide();
          $("#PRentAgreementModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePRentAgreement = () => {
    $("#X-btn_PRentAgreement").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "RentAgreement",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PRentAgreementStatus = res.data.data.status;
          POptStatusArray[1].status = PRentAgreementStatus;
          this.checkDropdownValue();
          this.checkhandlePProceedBtn();
          $("#choose_PRentAgreement").show();
          $("#X-btn_PRentAgreement").hide();
          $("#X-btn_PRentAgreement").attr("disabled", false);
          $("#viewDocLink_PRentAgreement").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PRentAgreement").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PRentAgreement").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePRentAgreementseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PRentAgreementseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "RentAgreement",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PRentAgreementseecommentsbtn").hide();
          $("#PRentAgreementhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePRentAgreementhidecommentsbtn = () => {
    $("#PRentAgreementseecommentsbtn").show();
    $("#PRentAgreementseecommentsbtn").attr("disabled", false);
    $("#PRentAgreementhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PPR
  handlePPRChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "PropertyTaxReceipt",
          },
          () => {
            $("#PPRModal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "PropertyTaxReceipt",
          });
        }
      });
      $("#PPRModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPPR = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PPR").attr("disabled", true);
      $("#upload_btn20_PPR").hide();
      $("#upload_btnloader_PPR").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PPRDocLink: res.data.data.PropertyTaxReceipt,
                },
                () => $("#viewDocLink_PPR").css("display", "block")
              );
              PPRStatus = "remove";
              PStatusArray[9].status = PPRStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PPRModal").modal("hide");
              $("#cancelbtn_PPR").attr("disabled", false);
              $("#upload_btn20_PPR").show();
              $("#upload_btnloader_PPR").hide();
              $("#choose_PPR").hide();
              $("#X-btn_PPR").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PPR").attr("disabled", false);
            $("#upload_btn20_PPR").show();
            $("#upload_btnloader_PPR").hide();
            $("#PPRModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PPR").attr("disabled", false);
            $("#upload_btn20_PPR").show();
            $("#upload_btnloader_PPR").hide();
            $("#PPRModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PPR").attr("disabled", false);
          $("#upload_btn20_PPR").show();
          $("#upload_btnloader_PPR").hide();
          $("#PPRModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePPR = () => {
    $("#X-btn_PPR").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "PropertyTaxReceipt",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PPRStatus = res.data.data.status;
          PStatusArray[9].status = PPRStatus;
          this.checkDropdownValue();
          this.checkhandlePProceedBtn();
          $("#choose_PPR").show();
          $("#X-btn_PPR").hide();
          $("#X-btn_PPR").attr("disabled", false);
          $("#viewDocLink_PPR").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PPR").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PPR").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePPRseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PPRseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PropertyTaxReceipt",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PPRseecommentsbtn").hide();
          $("#PPRhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePPRhidecommentsbtn = () => {
    $("#PPRseecommentsbtn").show();
    $("#PPRseecommentsbtn").attr("disabled", false);
    $("#PPRhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PAdditional
  handlePAddFileChange = (e, index, status) => {
    PadditionalReuploadIndex = index;
    PadditionalReuploadStatus = status;
    $(".upload_btn20_7").show();
    $(".upload_btnloader_7").hide();
    const file = e.target.files[0];
    if (!PadditionalFilesNames.includes(this.state.PAdd_DocName.trim())) {
      const myfile = e.target.files;
      if (ChooseNowBtnsDisable == "Yes") {
        this.showNotification(
          "Files cannot be uploaded when the service is locked!"
        );
      } else if (myfile.length == 1) {
        const file = myfile[0];
        if (file.type.match("audio.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.type.match("video.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.size > 104857600) {
          this.showNotification("File too big!");
        } else if (file.name.length > 100) {
          this.showNotification("File name limit exceed!");
        } else {
          this.setState(
            {
              fileName: file.name,
              fileBase64: file,
              documentType: "additional",
              documentName: this.state.PAdd_DocName,
            },
            () => {
              $(".PAddModal").modal({ backdrop: "static", keyboard: false });
            }
          );
        }
      } else if (myfile.length > 1) {
        var zip = new JSZip();
        for (let i = 0; i < myfile.length; i++) {
          zip.file(myfile[i].name, myfile[i], { base64: true });
        }
        zip.generateAsync({ type: "blob" }).then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "additional",
              documentName: this.state.PAdd_DocName,
            });
          }
        });
        $(".PAddModal").modal({ backdrop: "static", keyboard: false });
      } else {
        this.showNotification("File cannot be null!");
      }
    } else {
      this.showNotification(
        "Document names can not be the same, Try again with different name!"
      );
    }
  };

  uploadPFile = (FileIndex, FileStatus) => {
    let AdditionaldocumentName1;
    if (FileStatus == "reupload") {
      $(".proceednowbtn").attr("disabled", true);
      let fileTobeUploaded = this.state.PadditionalFiles[FileIndex];
      AdditionaldocumentName1 = Object.keys(fileTobeUploaded)[0];
      mainaction = "reupload";
    } else {
      AdditionaldocumentName1 = this.state.documentName;
      mainaction = "upload";
    }
    if (AdditionaldocumentName1.trim() == "") {
      $(".PAddModal").modal("hide");
      this.showNotification("Enter document Name first");
    } else if (this.state.comment.length <= 250) {
      $(".cancelbtn_7").attr("disabled", true);
      $(".upload_btn20_7").hide();
      $(".upload_btnloader_7").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", AdditionaldocumentName1);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            $(".PAddModal").modal("hide");
            $(".cancelbtn_7").attr("disabled", false);
            $(`#${FileIndex}PFileseecommentsbtn`).attr("disabled", false);
            if (PadditionalReupload == false) {
              PadditionalFilesNames.push(this.state.PAdd_DocName);
              this.state.PadditionalFiles.pop();
              this.setState(
                {
                  comment: "",
                  PadditionalFiles: [
                    ...this.state.PadditionalFiles,
                    res.data.data,
                  ],
                },
                () => {
                  this.setState(
                    {
                      api_PadditionalFiles: "Yes",
                    },
                    () => {
                      this.PadditionalFilesArea();
                      this.checkDropdownValue();
                      this.checkhandlePProceedBtn();
                    }
                  );

                  $(".PadditionalInput").attr("disabled", true);
                }
              );
            } else if (PadditionalReupload == true) {
              $(".proceednowbtn").attr("disabled", false);
              var array = [...this.state.PadditionalFiles];
              array.splice(FileIndex, 1, res.data.data);
              this.setState(
                {
                  comment: "",
                  api_PadditionalFiles: "Yes",
                  PadditionalFiles: array,
                },
                () => {
                  this.PadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandlePProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".PAddModal").modal("hide");
            this.showNotification("Something Went Wrong!");
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".PAddModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          this.showNotification("Something went wrong, Try again!");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePFile = (index) => {
    $("#X-btn_7").attr("disabled", true);
    let fileTobeDeleted = this.state.PadditionalFiles[index];
    let NameOfAddFile = Object.keys(fileTobeDeleted)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "additional",
          documentName: NameOfAddFile,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          var array = [...this.state.PadditionalFiles];
          if (res.data.data.status == "reupload") {
            array[index].status = "reupload";
            this.setState(
              {
                PadditionalFiles: array,
              },
              () => {
                this.PadditionalFilesArea();
                this.checkDropdownValue();
                this.checkhandlePProceedBtn();
              }
            );
            this.showNotification(res.data.message);
          } else {
            array.splice(index, 1);
            if (array.length > 0) {
              this.setState(
                {
                  PadditionalFiles: array,
                },
                () => {
                  this.PadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandlePProceedBtn();
                }
              );
            } else if (array.length == 0) {
              this.setState(
                {
                  PadditionalFiles: [],
                  PAdd_DocName: "",
                  api_PadditionalFiles: "No",
                  IsPAddFileDisabled: true,
                },
                () => {
                  this.PadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandlePProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_7").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePFileseeComments = (index) => {
    $(".commentsbtn").attr("disabled", false);
    $(`#${index}PFileseecommentsbtn`).attr("disabled", true);
    let SelectedFile = this.state.PadditionalFiles[index];
    let NameOfAddFile = Object.keys(SelectedFile)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "additional",
          documentName: NameOfAddFile,
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(`#${index}PFileseecommentsbtn`).hide();
          $(".hidecommentsbtn").hide();
          $(`#${index}PFilehidecommentsbtn`).show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePFilehideComments = (index) => {
    $(".seecommentsbtn").show();
    $(`#${index}PFileseecommentsbtn`).show();
    $(`#${index}PFileseecommentsbtn`).attr("disabled", false);
    $(`#${index}PFilehidecommentsbtn`).hide();
    $(".hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  checkAddCommentPFiles = (FileIndex, isDisabled) => {
    if (isDisabled == true) {
      return (
        <>
          <button
            className="seecommentsbtn PFileseecommentsbtn"
            id={`${FileIndex}PFileseecommentsbtn`}
            disabled
            onClick={() => this.handlePFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn PFilehidecommentsbtn"
            id={`${FileIndex}PFilehidecommentsbtn`}
            onClick={() => this.handlePFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    } else if (isDisabled == false) {
      return (
        <>
          <button
            className="seecommentsbtn commentsbtn PFileseecommentsbtn"
            id={`${FileIndex}PFileseecommentsbtn`}
            onClick={() => this.handlePFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn PFilehidecommentsbtn"
            id={`${FileIndex}PFilehidecommentsbtn`}
            onClick={() => this.handlePFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    }
  };

  checkStatusPAddFiles = (status, link, index) => {
    PadditionalFilesIndex = index;
    if (HideProceedBtn == "Yes") {
      $(".proceednowbtn").hide();
      if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
          </>
        );
      }
    } else {
      if (status == undefined) {
        status = "open";
      }
      if (status == "open") {
        mainaction = "upload";
        PadditionalReupload = true;
        $(".proceednowbtn").show();
        return (
          <>
            <label
              htmlFor={`ul_file_PAdd${index}`}
              className="choose_btn choose_7"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              multiple
              name="file[]"
              className="upload-photo"
              id={`ul_file_PAdd${index}`}
              // disabled={this.state.IsPAddFileDisabled}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handlePAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "remove") {
        mainaction = "upload";
        $(".proceednowbtn").show();
        return (
          <>
            <button
              className="X-btn X-btn_7"
              onClick={() => this.removePFile(index)}
            >
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_1025.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "reupload") {
        PadditionalReupload = true;
        mainaction = "reupload";
        $(".proceednowbtn").show();
        $(".proceednowbtn").attr("disabled", true);
        return (
          <>
            <label
              htmlFor={`ul_file_PAdd${index}`}
              className="choose_btn choose_7"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              multiple
              name="file[]"
              className="upload-photo"
              id={`ul_file_PAdd${index}`}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handlePAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
          </>
        );
      }
    }
  };

  AdditionalPFileDisabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero PadditionalInput"
        value={NameOfAddFile}
        disabled
        onChange={(e) => this.handlePAdd_DocNameChange(e)}
      />
    );
  };

  AdditionalPFileEnabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero PadditionalInput"
        value={NameOfAddFile}
        onChange={(e) => this.handlePAdd_DocNameChange(e)}
      />
    );
  };

  PadditionalFilesArea = () => {
    PadditionalFilesNames = [];
    if (this.state.api_PadditionalFiles == "Yes") {
      return this.state.PadditionalFiles.map((file, index) => {
        let NameOfAddFile = Object.keys(file)[0];
        PadditionalFilesNames.push(NameOfAddFile);
        let link = file[Object.keys(file)[0]];
        let AdditionalFileInputField;
        let commentDisabled;
        if (file.status == "open" || file.status == undefined) {
          AdditionalFileInputField =
            this.AdditionalPFileEnabledField(NameOfAddFile);
          commentDisabled = true;
        } else if (file.status != "open") {
          AdditionalFileInputField =
            this.AdditionalPFileDisabledField(NameOfAddFile);
          commentDisabled = false;
        }
        return (
          <div className="displayflex" key={index}>
            <div className="uploadnocol">
              <p className="uploadadditionalsidetext">{index + 1}&#41;</p>
            </div>
            <div className="uploadadditionaldatacol">
              <div className="row mb-2">
                <div className="col-md-5">{AdditionalFileInputField}</div>
                <div className="col-md-7">
                  <div className="float-md-right float-left">
                    {this.checkAddCommentPFiles(index, commentDisabled)}
                    {this.checkStatusPAddFiles(file.status, link, index)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.api_PadditionalFiles == "No") {
      return (
        <div className="displayflex">
          <div className="uploadnocol">
            <p className="uploadadditionalsidetext">1&#41;</p>
          </div>
          <div className="uploadadditionaldatacol">
            <div className="row mb-2">
              <div className="col-md-5">
                <input
                  type="text"
                  placeholder="Document name..."
                  maxLength="150"
                  className="uploadtextfield marginzero"
                  value={this.state.PAdd_DocName}
                  onChange={(e) => this.handlePAdd_DocNameChange(e)}
                />
              </div>
              <div className="col-md-7">
                <div className="fl_right">
                  {this.checkAddCommentPFiles(0, true)}
                  {this.checkStatusPAddFiles("open", null, 0)}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  handlePAdd_DocNameChange = (e) => {
    this.setState(
      {
        PAdd_DocName: e.target.value,
      },
      () => {
        if (this.state.PAdd_DocName.trim().length > 0) {
          this.setState({
            IsPAddFileDisabled: false,
          });
        } else {
          this.setState({
            IsPAddFileDisabled: true,
          });
        }
      }
    );
  };

  handleAddPFileBtn = (FileIndex) => {
    const { PAdd_DocName, PadditionalFiles } = this.state;

    let prevFile = PadditionalFiles[FileIndex];
    let NameOfPrevFile = Object.keys(prevFile)[0];
    let valueOffile = prevFile[Object.keys(prevFile)[0]];

    if (FileIndex == undefined) {
      this.showNotification("empty fields");
    } else if (PAdd_DocName.trim() == "") {
      this.showNotification("Required fields cannot be empty!");
    } else if (valueOffile == null) {
      this.showNotification("Choose a file");
    } else {
      this.setState(
        {
          PadditionalFiles: [...this.state.PadditionalFiles, ""],
          PAdd_DocName: "",
          IsPAddFileDisabled: true,
        },
        () => {
          $(".PadditionalInput").attr("disabled", false);
        }
      );
    }
  };

  // Proceed Checks
  PcheckRemainingReuploads = () => {
    if (
      PAadhar1Status != "reupload" &&
      PAadhar2Status != "reupload" &&
      PPan1Status != "reupload" &&
      PPan2Status != "reupload" &&
      PPic1Status != "reupload" &&
      PPic2Status != "reupload" &&
      PBPStatus != "reupload" &&
      PGumastaStatus != "reupload" &&
      PTradeLicenceStatus != "reupload" &&
      PDeedStatus != "reupload" &&
      PCopyOfRegistryStatus != "reupload" &&
      PRentAgreementStatus != "reupload" &&
      PPRStatus != "reupload"
    ) {
      AnyReuploadOccuredP = "No";
    } else {
      $(".proceednowbtn2").show();
      AnyReuploadOccuredP = "Yes";
    }
  };

  checkhandlePProceedBtn = () => {
    let statusCheckReupload = this.statusCheckFunction(
      "reupload",
      PStatusArray
    );
    let PadditionalStatusArrayCheck = this.statusCheckFunction(
      "reupload",
      this.state.PadditionalFiles
    );
    let goodtogo = "No";
    if (this.state.PAddressProofType_radio == "CopyOfRegistry") {
      if (
        POptStatusArray[0].status != "reupload" &&
        POptStatusArray[0].status != "upload" &&
        POptStatusArray[0].status != "open"
      ) {
        goodtogo = "Yes";
      } else {
        goodtogo = "No";
      }
    } else if (this.state.PAddressProofType_radio == "RentAgreement") {
      if (
        POptStatusArray[1].status != "reupload" &&
        POptStatusArray[1].status != "upload" &&
        POptStatusArray[1].status != "open"
      ) {
        goodtogo = "Yes";
      } else {
        goodtogo = "No";
      }
    } else {
      goodtogo = "No";
    }

    if (
      AnyReuploadOccuredP == "Yes" &&
      statusCheckReupload == undefined &&
      goodtogo == "Yes" &&
      PadditionalStatusArrayCheck == undefined
    ) {
      $(".proceednowbtn2").attr("disabled", false);
      Pproceednowforword = "success";
    } else if (
      PAadhar1Status == "remove" &&
      PAadhar2Status == "remove" &&
      PPan1Status == "remove" &&
      PPan2Status == "remove" &&
      PPic1Status == "remove" &&
      PPic2Status == "remove" &&
      PBPStatus == "remove" &&
      PGumastaStatus == "remove" &&
      PTradeLicenceStatus == "remove" &&
      PDeedStatus == "remove" &&
      PPRStatus == "remove" &&
      goodtogo == "Yes"
    ) {
      $(".proceednowbtn2").attr("disabled", false);
      Pproceednowforword = "success";
    } else {
      $(".proceednowbtn2").attr("disabled", true);
      Pproceednowforword = "fail";
    }
  };

  handlePProceedBtn = () => {
    this.checkhandlePProceedBtn();
    if (this.state.PbusinessNature.trim() == "") {
      this.showNotification("Required fields can not be empty!");
    } else if (Pproceednowforword == "success") {
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=lockService",
          {
            userId: Decuserid,
            requestType: temp,
            agentId: Decagentid,

            serviceId: Decserviceid,
            serviceName: Decservicename,
            serviceType: this.state.serviceType,
            FY: "",
            Month: "",
            inputFields: [
              {
                inputFieldName: "AddressProofType",
                inputFieldValue: this.state.PAddressProofType_radio,
                inputFieldtype: "general",
              },
              {
                inputFieldName: "Nature",
                inputFieldValue: this.state.PbusinessNature,
                inputFieldtype: "general",
              },
            ],
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.showNotification(res.data.message);
            this.getserviceInformationApi();
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else {
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {});
    }
  };

  // Private Limited

  // PLAadhar
  handlePLAadhar1Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "AadhaarCardApplicant1",
          },
          () => {
            $("#PLAadhar1Modal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "AadhaarCardApplicant1",
          });
        }
      });
      $("#PLAadhar1Modal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLAadhar1 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLAadhar1").attr("disabled", true);
      $("#upload_btn20_PLAadhar1").hide();
      $("#upload_btnloader_PLAadhar1").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLAadhar1DocLink: res.data.data.AadhaarCardApplicant1,
                },
                () => $("#viewDocLink_PLAadhar1").css("display", "block")
              );
              PLAadhar1Status = "remove";
              PLStatusArray[0].status = PLAadhar1Status;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLAadhar1Modal").modal("hide");
              $("#cancelbtn_PLAadhar1").attr("disabled", false);
              $("#upload_btn20_PLAadhar1").show();
              $("#upload_btnloader_PLAadhar1").hide();
              $("#choose_PLAadhar1").hide();
              $("#X-btn_PLAadhar1").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLAadhar1").attr("disabled", false);
            $("#upload_btn20_PLAadhar1").show();
            $("#upload_btnloader_PLAadhar1").hide();
            $("#PLAadhar1Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLAadhar1").attr("disabled", false);
            $("#upload_btn20_PLAadhar1").show();
            $("#upload_btnloader_PLAadhar1").hide();
            $("#PLAadhar1Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLAadhar1").attr("disabled", false);
          $("#upload_btn20_PLAadhar1").show();
          $("#upload_btnloader_PLAadhar1").hide();
          $("#PLAadhar1Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLAadhar1 = () => {
    $("#X-btn_PLAadhar1").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "AadhaarCardApplicant1",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLAadhar1Status = res.data.data.status;
          PLStatusArray[0].status = PLAadhar1Status;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLAadhar1").show();
          $("#X-btn_PLAadhar1").hide();
          $("#X-btn_PLAadhar1").attr("disabled", false);
          $("#viewDocLink_PLAadhar1").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLAadhar1").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLAadhar1").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLAadhar1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLAadhar1seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "AadhaarCardApplicant1",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLAadhar1seecommentsbtn").hide();
          $("#PLAadhar1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLAadhar1hidecommentsbtn = () => {
    $("#PLAadhar1seecommentsbtn").show();
    $("#PLAadhar1seecommentsbtn").attr("disabled", false);
    $("#PLAadhar1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLAadhar2
  handlePLAadhar2Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "AadhaarCardApplicant2",
          },
          () => {
            $("#PLAadhar2Modal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "AadhaarCardApplicant2",
          });
        }
      });
      $("#PLAadhar2Modal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLAadhar2 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLAadhar2").attr("disabled", true);
      $("#upload_btn20_PLAadhar2").hide();
      $("#upload_btnloader_PLAadhar2").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLAadhar2DocLink: res.data.data.AadhaarCardApplicant2,
                },
                () => $("#viewDocLink_PLAadhar2").css("display", "block")
              );
              PLAadhar2Status = "remove";
              PLStatusArray[1].status = PLAadhar2Status;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLAadhar2Modal").modal("hide");
              $("#cancelbtn_PLAadhar2").attr("disabled", false);
              $("#upload_btn20_PLAadhar2").show();
              $("#upload_btnloader_PLAadhar2").hide();
              $("#choose_PLAadhar2").hide();
              $("#X-btn_PLAadhar2").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLAadhar2").attr("disabled", false);
            $("#upload_btn20_PLAadhar2").show();
            $("#upload_btnloader_PLAadhar2").hide();
            $("#PLAadhar2Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLAadhar2").attr("disabled", false);
            $("#upload_btn20_PLAadhar2").show();
            $("#upload_btnloader_PLAadhar2").hide();
            $("#PLAadhar2Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLAadhar2").attr("disabled", false);
          $("#upload_btn20_PLAadhar2").show();
          $("#upload_btnloader_PLAadhar2").hide();
          $("#PLAadhar2Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLAadhar2 = () => {
    $("#X-btn_PLAadhar2").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "AadhaarCardApplicant2",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLAadhar2Status = res.data.data.status;
          PLStatusArray[1].status = PLAadhar2Status;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLAadhar2").show();
          $("#X-btn_PLAadhar2").hide();
          $("#X-btn_PLAadhar2").attr("disabled", false);
          $("#viewDocLink_PLAadhar2").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLAadhar2").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLAadhar2").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLAadhar2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLAadhar2seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "AadhaarCardApplicant2",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLAadhar2seecommentsbtn").hide();
          $("#PLAadhar2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLAadhar2hidecommentsbtn = () => {
    $("#PLAadhar2seecommentsbtn").show();
    $("#PLAadhar2seecommentsbtn").attr("disabled", false);
    $("#PLAadhar2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLPan1
  handlePLPan1Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "PANCardApplicant1",
          },
          () => {
            $("#PLPan1Modal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "PANCardApplicant1",
          });
        }
      });
      $("#PLPan1Modal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLPan1 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLPan1").attr("disabled", true);
      $("#upload_btn20_PLPan1").hide();
      $("#upload_btnloader_PLPan1").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLPan1DocLink: res.data.data.PANCardApplicant1,
                },
                () => $("#viewDocLink_PLPan1").css("display", "block")
              );
              PLPan1Status = "remove";
              PLStatusArray[2].status = PLPan1Status;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLPan1Modal").modal("hide");
              $("#cancelbtn_PLPan1").attr("disabled", false);
              $("#upload_btn20_PLPan1").show();
              $("#upload_btnloader_PLPan1").hide();
              $("#choose_PLPan1").hide();
              $("#X-btn_PLPan1").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLPan1").attr("disabled", false);
            $("#upload_btn20_PLPan1").show();
            $("#upload_btnloader_PLPan1").hide();
            $("#PLPan1Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLPan1").attr("disabled", false);
            $("#upload_btn20_PLPan1").show();
            $("#upload_btnloader_PLPan1").hide();
            $("#PLPan1Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLPan1").attr("disabled", false);
          $("#upload_btn20_PLPan1").show();
          $("#upload_btnloader_PLPan1").hide();
          $("#PLPan1Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLPan1 = () => {
    $("#X-btn_PLPan1").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "PANCardApplicant1",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLPan1Status = res.data.data.status;
          PLStatusArray[2].status = PLPan1Status;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLPan1").show();
          $("#X-btn_PLPan1").hide();
          $("#X-btn_PLPan1").attr("disabled", false);
          $("#viewDocLink_PLPan1").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLPan1").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLPan1").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLPan1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLPan1seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PANCardApplicant1",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLPan1seecommentsbtn").hide();
          $("#PLPan1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLPan1hidecommentsbtn = () => {
    $("#PLPan1seecommentsbtn").show();
    $("#PLPan1seecommentsbtn").attr("disabled", false);
    $("#PLPan1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLPan2
  handlePLPan2Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "PANCardApplicant2",
          },
          () => {
            $("#PLPan2Modal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "PANCardApplicant2",
          });
        }
      });
      $("#PLPan2Modal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLPan2 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLPan2").attr("disabled", true);
      $("#upload_btn20_PLPan2").hide();
      $("#upload_btnloader_PLPan2").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLPan2DocLink: res.data.data.PANCardApplicant2,
                },
                () => $("#viewDocLink_PLPan2").css("display", "block")
              );
              PLPan2Status = "remove";
              PLStatusArray[3].status = PLPan2Status;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLPan2Modal").modal("hide");
              $("#cancelbtn_PLPan2").attr("disabled", false);
              $("#upload_btn20_PLPan2").show();
              $("#upload_btnloader_PLPan2").hide();
              $("#choose_PLPan2").hide();
              $("#X-btn_PLPan2").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLPan2").attr("disabled", false);
            $("#upload_btn20_PLPan2").show();
            $("#upload_btnloader_PLPan2").hide();
            $("#PLPan2Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLPan2").attr("disabled", false);
            $("#upload_btn20_PLPan2").show();
            $("#upload_btnloader_PLPan2").hide();
            $("#PLPan2Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLPan2").attr("disabled", false);
          $("#upload_btn20_PLPan2").show();
          $("#upload_btnloader_PLPan2").hide();
          $("#PLPan2Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLPan2 = () => {
    $("#X-btn_PLPan2").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "PANCardApplicant2",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLPan2Status = res.data.data.status;
          PLStatusArray[3].status = PLPan2Status;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLPan2").show();
          $("#X-btn_PLPan2").hide();
          $("#X-btn_PLPan2").attr("disabled", false);
          $("#viewDocLink_PLPan2").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLPan2").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLPan2").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLPan2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLPan2seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PANCardApplicant2",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLPan2seecommentsbtn").hide();
          $("#PLPan2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLPan2hidecommentsbtn = () => {
    $("#PLPan2seecommentsbtn").show();
    $("#PLPan2seecommentsbtn").attr("disabled", false);
    $("#PLPan2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLPic1
  handlePLPic1Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "ApplicantPhoto1",
          },
          () => {
            $("#PLPic1Modal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "ApplicantPhoto1",
          });
        }
      });
      $("#PLPic1Modal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLPic1 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLPic1").attr("disabled", true);
      $("#upload_btn20_PLPic1").hide();
      $("#upload_btnloader_PLPic1").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLPic1DocLink: res.data.data.ApplicantPhoto1,
                },
                () => $("#viewDocLink_PLPic1").css("display", "block")
              );
              PLPic1Status = "remove";
              PLStatusArray[4].status = PLPic1Status;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLPic1Modal").modal("hide");
              $("#cancelbtn_PLPic1").attr("disabled", false);
              $("#upload_btn20_PLPic1").show();
              $("#upload_btnloader_PLPic1").hide();
              $("#choose_PLPic1").hide();
              $("#X-btn_PLPic1").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLPic1").attr("disabled", false);
            $("#upload_btn20_PLPic1").show();
            $("#upload_btnloader_PLPic1").hide();
            $("#PLPic1Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLPic1").attr("disabled", false);
            $("#upload_btn20_PLPic1").show();
            $("#upload_btnloader_PLPic1").hide();
            $("#PLPic1Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLPic1").attr("disabled", false);
          $("#upload_btn20_PLPic1").show();
          $("#upload_btnloader_PLPic1").hide();
          $("#PLPic1Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLPic1 = () => {
    $("#X-btn_PLPic1").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "ApplicantPhoto1",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLPic1Status = res.data.data.status;
          PLStatusArray[4].status = PLPic1Status;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLPic1").show();
          $("#X-btn_PLPic1").hide();
          $("#X-btn_PLPic1").attr("disabled", false);
          $("#viewDocLink_PLPic1").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLPic1").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLPic1").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLPic1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLPic1seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ApplicantPhoto1",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLPic1seecommentsbtn").hide();
          $("#PLPic1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLPic1hidecommentsbtn = () => {
    $("#PLPic1seecommentsbtn").show();
    $("#PLPic1seecommentsbtn").attr("disabled", false);
    $("#PLPic1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLPic2
  handlePLPic2Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "ApplicantPhoto2",
          },
          () => {
            $("#PLPic2Modal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "ApplicantPhoto2",
          });
        }
      });
      $("#PLPic2Modal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLPic2 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLPic2").attr("disabled", true);
      $("#upload_btn20_PLPic2").hide();
      $("#upload_btnloader_PLPic2").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLPic2DocLink: res.data.data.ApplicantPhoto2,
                },
                () => $("#viewDocLink_PLPic2").css("display", "block")
              );
              PLPic2Status = "remove";
              PLStatusArray[5].status = PLPic2Status;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLPic2Modal").modal("hide");
              $("#cancelbtn_PLPic2").attr("disabled", false);
              $("#upload_btn20_PLPic2").show();
              $("#upload_btnloader_PLPic2").hide();
              $("#choose_PLPic2").hide();
              $("#X-btn_PLPic2").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLPic2").attr("disabled", false);
            $("#upload_btn20_PLPic2").show();
            $("#upload_btnloader_PLPic2").hide();
            $("#PLPic2Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLPic2").attr("disabled", false);
            $("#upload_btn20_PLPic2").show();
            $("#upload_btnloader_PLPic2").hide();
            $("#PLPic2Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLPic2").attr("disabled", false);
          $("#upload_btn20_PLPic2").show();
          $("#upload_btnloader_PLPic2").hide();
          $("#PLPic2Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLPic2 = () => {
    $("#X-btn_PLPic2").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "ApplicantPhoto2",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLPic2Status = res.data.data.status;
          PLStatusArray[5].status = PLPic2Status;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLPic2").show();
          $("#X-btn_PLPic2").hide();
          $("#X-btn_PLPic2").attr("disabled", false);
          $("#viewDocLink_PLPic2").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLPic2").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLPic2").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLPic2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLPic2seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ApplicantPhoto2",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLPic2seecommentsbtn").hide();
          $("#PLPic2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLPic2hidecommentsbtn = () => {
    $("#PLPic2seecommentsbtn").show();
    $("#PLPic2seecommentsbtn").attr("disabled", false);
    $("#PLPic2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLPan3
  handlePLPan3Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "PANCardCompany",
          },
          () => {
            $("#PLPan3Modal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "PANCardCompany",
          });
        }
      });
      $("#PLPan3Modal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLPan3 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLPan3").attr("disabled", true);
      $("#upload_btn20_PLPan3").hide();
      $("#upload_btnloader_PLPan3").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLPan3DocLink: res.data.data.PANCardCompany,
                },
                () => $("#viewDocLink_PLPan3").css("display", "block")
              );
              PLPan3Status = "remove";
              PLStatusArray[6].status = PLPan3Status;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLPan3Modal").modal("hide");
              $("#cancelbtn_PLPan3").attr("disabled", false);
              $("#upload_btn20_PLPan3").show();
              $("#upload_btnloader_PLPan3").hide();
              $("#choose_PLPan3").hide();
              $("#X-btn_PLPan3").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLPan3").attr("disabled", false);
            $("#upload_btn20_PLPan3").show();
            $("#upload_btnloader_PLPan3").hide();
            $("#PLPan3Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLPan3").attr("disabled", false);
            $("#upload_btn20_PLPan3").show();
            $("#upload_btnloader_PLPan3").hide();
            $("#PLPan3Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLPan3").attr("disabled", false);
          $("#upload_btn20_PLPan3").show();
          $("#upload_btnloader_PLPan3").hide();
          $("#PLPan3Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLPan3 = () => {
    $("#X-btn_PLPan3").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "PANCardCompany",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLPan3Status = res.data.data.status;
          PLStatusArray[6].status = PLPan3Status;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLPan3").show();
          $("#X-btn_PLPan3").hide();
          $("#X-btn_PLPan3").attr("disabled", false);
          $("#viewDocLink_PLPan3").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLPan3").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLPan3").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLPan3seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLPan3seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PANCardCompany",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLPan3seecommentsbtn").hide();
          $("#PLPan3hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLPan3hidecommentsbtn = () => {
    $("#PLPan3seecommentsbtn").show();
    $("#PLPan3seecommentsbtn").attr("disabled", false);
    $("#PLPan3hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLCI
  handlePLCIChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "CertificateOfIncorporation",
          },
          () => {
            $("#PLCIModal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "CertificateOfIncorporation",
          });
        }
      });
      $("#PLCIModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLCI = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLCI").attr("disabled", true);
      $("#upload_btn20_PLCI").hide();
      $("#upload_btnloader_PLCI").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLCIDocLink: res.data.data.CertificateOfIncorporation,
                },
                () => $("#viewDocLink_PLCI").css("display", "block")
              );
              PLCIStatus = "remove";
              PLStatusArray[7].status = PLCIStatus;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLCIModal").modal("hide");
              $("#cancelbtn_PLCI").attr("disabled", false);
              $("#upload_btn20_PLCI").show();
              $("#upload_btnloader_PLCI").hide();
              $("#choose_PLCI").hide();
              $("#X-btn_PLCI").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLCI").attr("disabled", false);
            $("#upload_btn20_PLCI").show();
            $("#upload_btnloader_PLCI").hide();
            $("#PLCIModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLCI").attr("disabled", false);
            $("#upload_btn20_PLCI").show();
            $("#upload_btnloader_PLCI").hide();
            $("#PLCIModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLCI").attr("disabled", false);
          $("#upload_btn20_PLCI").show();
          $("#upload_btnloader_PLCI").hide();
          $("#PLCIModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLCI = () => {
    $("#X-btn_PLCI").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "CertificateOfIncorporation",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLCIStatus = res.data.data.status;
          PLStatusArray[7].status = PLCIStatus;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLCI").show();
          $("#X-btn_PLCI").hide();
          $("#X-btn_PLCI").attr("disabled", false);
          $("#viewDocLink_PLCI").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLCI").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLCI").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLCIseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLCIseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "CertificateOfIncorporation",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLCIseecommentsbtn").hide();
          $("#PLCIhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLCIhidecommentsbtn = () => {
    $("#PLCIseecommentsbtn").show();
    $("#PLCIseecommentsbtn").attr("disabled", false);
    $("#PLCIhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLMOA
  handlePLMOAChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "MOA",
          },
          () => {
            $("#PLMOAModal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "MOA",
          });
        }
      });
      $("#PLMOAModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLMOA = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLMOA").attr("disabled", true);
      $("#upload_btn20_PLMOA").hide();
      $("#upload_btnloader_PLMOA").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLMOADocLink: res.data.data.MOA,
                },
                () => $("#viewDocLink_PLMOA").css("display", "block")
              );
              PLMOAStatus = "remove";
              PLStatusArray[8].status = PLMOAStatus;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLMOAModal").modal("hide");
              $("#cancelbtn_PLMOA").attr("disabled", false);
              $("#upload_btn20_PLMOA").show();
              $("#upload_btnloader_PLMOA").hide();
              $("#choose_PLMOA").hide();
              $("#X-btn_PLMOA").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLMOA").attr("disabled", false);
            $("#upload_btn20_PLMOA").show();
            $("#upload_btnloader_PLMOA").hide();
            $("#PLMOAModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLMOA").attr("disabled", false);
            $("#upload_btn20_PLMOA").show();
            $("#upload_btnloader_PLMOA").hide();
            $("#PLMOAModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLMOA").attr("disabled", false);
          $("#upload_btn20_PLMOA").show();
          $("#upload_btnloader_PLMOA").hide();
          $("#PLMOAModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLMOA = () => {
    $("#X-btn_PLMOA").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "MOA",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLMOAStatus = res.data.data.status;
          PLStatusArray[8].status = PLMOAStatus;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLMOA").show();
          $("#X-btn_PLMOA").hide();
          $("#X-btn_PLMOA").attr("disabled", false);
          $("#viewDocLink_PLMOA").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLMOA").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLMOA").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLMOAseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLMOAseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "MOA",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLMOAseecommentsbtn").hide();
          $("#PLMOAhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLMOAhidecommentsbtn = () => {
    $("#PLMOAseecommentsbtn").show();
    $("#PLMOAseecommentsbtn").attr("disabled", false);
    $("#PLMOAhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLAOA
  handlePLAOAChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "AOA",
          },
          () => {
            $("#PLAOAModal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "AOA",
          });
        }
      });
      $("#PLAOAModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLAOA = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLAOA").attr("disabled", true);
      $("#upload_btn20_PLAOA").hide();
      $("#upload_btnloader_PLAOA").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLAOADocLink: res.data.data.AOA,
                },
                () => $("#viewDocLink_PLAOA").css("display", "block")
              );
              PLAOAStatus = "remove";
              PLStatusArray[9].status = PLAOAStatus;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLAOAModal").modal("hide");
              $("#cancelbtn_PLAOA").attr("disabled", false);
              $("#upload_btn20_PLAOA").show();
              $("#upload_btnloader_PLAOA").hide();
              $("#choose_PLAOA").hide();
              $("#X-btn_PLAOA").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLAOA").attr("disabled", false);
            $("#upload_btn20_PLAOA").show();
            $("#upload_btnloader_PLAOA").hide();
            $("#PLAOAModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLAOA").attr("disabled", false);
            $("#upload_btn20_PLAOA").show();
            $("#upload_btnloader_PLAOA").hide();
            $("#PLAOAModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLAOA").attr("disabled", false);
          $("#upload_btn20_PLAOA").show();
          $("#upload_btnloader_PLAOA").hide();
          $("#PLAOAModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLAOA = () => {
    $("#X-btn_PLAOA").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "AOA",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLAOAStatus = res.data.data.status;
          PLStatusArray[9].status = PLAOAStatus;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLAOA").show();
          $("#X-btn_PLAOA").hide();
          $("#X-btn_PLAOA").attr("disabled", false);
          $("#viewDocLink_PLAOA").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLAOA").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLAOA").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLAOAseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLAOAseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "AOA",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLAOAseecommentsbtn").hide();
          $("#PLAOAhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLAOAhidecommentsbtn = () => {
    $("#PLAOAseecommentsbtn").show();
    $("#PLAOAseecommentsbtn").attr("disabled", false);
    $("#PLAOAhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLBR
  handlePLBRChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "BoardResolution",
          },
          () => {
            $("#PLBRModal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "BoardResolution",
          });
        }
      });
      $("#PLBRModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLBR = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLBR").attr("disabled", true);
      $("#upload_btn20_PLBR").hide();
      $("#upload_btnloader_PLBR").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLBRDocLink: res.data.data.BoardResolution,
                },
                () => $("#viewDocLink_PLBR").css("display", "block")
              );
              PLBRStatus = "remove";
              PLStatusArray[10].status = PLBRStatus;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLBRModal").modal("hide");
              $("#cancelbtn_PLBR").attr("disabled", false);
              $("#upload_btn20_PLBR").show();
              $("#upload_btnloader_PLBR").hide();
              $("#choose_PLBR").hide();
              $("#X-btn_PLBR").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLBR").attr("disabled", false);
            $("#upload_btn20_PLBR").show();
            $("#upload_btnloader_PLBR").hide();
            $("#PLBRModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLBR").attr("disabled", false);
            $("#upload_btn20_PLBR").show();
            $("#upload_btnloader_PLBR").hide();
            $("#PLBRModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLBR").attr("disabled", false);
          $("#upload_btn20_PLBR").show();
          $("#upload_btnloader_PLBR").hide();
          $("#PLBRModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLBR = () => {
    $("#X-btn_PLBR").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "BoardResolution",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLBRStatus = res.data.data.status;
          PLStatusArray[10].status = PLBRStatus;
          this.checkhandlePLProceedBtn();
          this.checkDropdownValue();
          $("#choose_PLBR").show();
          $("#X-btn_PLBR").hide();
          $("#X-btn_PLBR").attr("disabled", false);
          $("#viewDocLink_PLBR").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLBR").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLBR").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLBRseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLBRseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "BoardResolution",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLBRseecommentsbtn").hide();
          $("#PLBRhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLBRhidecommentsbtn = () => {
    $("#PLBRseecommentsbtn").show();
    $("#PLBRseecommentsbtn").attr("disabled", false);
    $("#PLBRhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLBP
  handlePLBPChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "BankProof",
          },
          () => {
            $("#PLBPModal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "BankProof",
          });
        }
      });
      $("#PLBPModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLBP = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLBP").attr("disabled", true);
      $("#upload_btn20_PLBP").hide();
      $("#upload_btnloader_PLBP").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLBPDocLink: res.data.data.BankProof,
                },
                () => $("#viewDocLink_PLBP").css("display", "block")
              );
              PLBPStatus = "remove";
              PLStatusArray[11].status = PLBPStatus;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLBPModal").modal("hide");
              $("#cancelbtn_PLBP").attr("disabled", false);
              $("#upload_btn20_PLBP").show();
              $("#upload_btnloader_PLBP").hide();
              $("#choose_PLBP").hide();
              $("#X-btn_PLBP").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLBP").attr("disabled", false);
            $("#upload_btn20_PLBP").show();
            $("#upload_btnloader_PLBP").hide();
            $("#PLBPModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLBP").attr("disabled", false);
            $("#upload_btn20_PLBP").show();
            $("#upload_btnloader_PLBP").hide();
            $("#PLBPModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLBP").attr("disabled", false);
          $("#upload_btn20_PLBP").show();
          $("#upload_btnloader_PLBP").hide();
          $("#PLBPModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLBP = () => {
    $("#X-btn_PLBP").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "BankProof",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLBPStatus = res.data.data.status;
          PLStatusArray[11].status = PLBPStatus;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLBP").show();
          $("#X-btn_PLBP").hide();
          $("#X-btn_PLBP").attr("disabled", false);
          $("#viewDocLink_PLBP").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLBP").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLBP").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLBPseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLBPseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "BankProof",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLBPseecommentsbtn").hide();
          $("#PLBPhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLBPhidecommentsbtn = () => {
    $("#PLBPseecommentsbtn").show();
    $("#PLBPseecommentsbtn").attr("disabled", false);
    $("#PLBPhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLGumasta
  handlePLGumastaChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "Gumasta",
          },
          () => {
            $("#PLGumastaModal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "Gumasta",
          });
        }
      });
      $("#PLGumastaModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLGumasta = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLGumasta").attr("disabled", true);
      $("#upload_btn20_PLGumasta").hide();
      $("#upload_btnloader_PLGumasta").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLGumastaDocLink: res.data.data.Gumasta,
                },
                () => $("#viewDocLink_PLGumasta").css("display", "block")
              );
              PLGumastaStatus = "remove";
              PLStatusArray[12].status = PLGumastaStatus;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLGumastaModal").modal("hide");
              $("#cancelbtn_PLGumasta").attr("disabled", false);
              $("#upload_btn20_PLGumasta").show();
              $("#upload_btnloader_PLGumasta").hide();
              $("#choose_PLGumasta").hide();
              $("#X-btn_PLGumasta").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLGumasta").attr("disabled", false);
            $("#upload_btn20_PLGumasta").show();
            $("#upload_btnloader_PLGumasta").hide();
            $("#PLGumastaModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLGumasta").attr("disabled", false);
            $("#upload_btn20_PLGumasta").show();
            $("#upload_btnloader_PLGumasta").hide();
            $("#PLGumastaModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLGumasta").attr("disabled", false);
          $("#upload_btn20_PLGumasta").show();
          $("#upload_btnloader_PLGumasta").hide();
          $("#PLGumastaModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLGumasta = () => {
    $("#X-btn_PLGumasta").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "Gumasta",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLGumastaStatus = res.data.data.status;
          PLStatusArray[12].status = PLGumastaStatus;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLGumasta").show();
          $("#X-btn_PLGumasta").hide();
          $("#X-btn_PLGumasta").attr("disabled", false);
          $("#viewDocLink_PLGumasta").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLGumasta").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLGumasta").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLGumastaseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLGumastaseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "Gumasta",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLGumastaseecommentsbtn").hide();
          $("#PLGumastahidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLGumastahidecommentsbtn = () => {
    $("#PLGumastaseecommentsbtn").show();
    $("#PLGumastaseecommentsbtn").attr("disabled", false);
    $("#PLGumastahidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLTradeLicence
  handlePLTradeLicenceChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "TradeLicence",
          },
          () => {
            $("#PLTradeLicenceModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "TradeLicence",
          });
        }
      });
      $("#PLTradeLicenceModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLTradeLicence = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLTradeLicence").attr("disabled", true);
      $("#upload_btn20_PLTradeLicence").hide();
      $("#upload_btnloader_PLTradeLicence").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLTradeLicenceDocLink: res.data.data.TradeLicence,
                },
                () => $("#viewDocLink_PLTradeLicence").css("display", "block")
              );
              PLTradeLicenceStatus = "remove";
              PLStatusArray[13].status = PLTradeLicenceStatus;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLTradeLicenceModal").modal("hide");
              $("#cancelbtn_PLTradeLicence").attr("disabled", false);
              $("#upload_btn20_PLTradeLicence").show();
              $("#upload_btnloader_PLTradeLicence").hide();
              $("#choose_PLTradeLicence").hide();
              $("#X-btn_PLTradeLicence").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLTradeLicence").attr("disabled", false);
            $("#upload_btn20_PLTradeLicence").show();
            $("#upload_btnloader_PLTradeLicence").hide();
            $("#PLTradeLicenceModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLTradeLicence").attr("disabled", false);
            $("#upload_btn20_PLTradeLicence").show();
            $("#upload_btnloader_PLTradeLicence").hide();
            $("#PLTradeLicenceModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLTradeLicence").attr("disabled", false);
          $("#upload_btn20_PLTradeLicence").show();
          $("#upload_btnloader_PLTradeLicence").hide();
          $("#PLTradeLicenceModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLTradeLicence = () => {
    $("#X-btn_PLTradeLicence").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "TradeLicence",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLTradeLicenceStatus = res.data.data.status;
          PLStatusArray[13].status = PLTradeLicenceStatus;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLTradeLicence").show();
          $("#X-btn_PLTradeLicence").hide();
          $("#X-btn_PLTradeLicence").attr("disabled", false);
          $("#viewDocLink_PLTradeLicence").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLTradeLicence").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLTradeLicence").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLTradeLicenceseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLTradeLicenceseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "TradeLicence",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLTradeLicenceseecommentsbtn").hide();
          $("#PLTradeLicencehidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLTradeLicencehidecommentsbtn = () => {
    $("#PLTradeLicenceseecommentsbtn").show();
    $("#PLTradeLicenceseecommentsbtn").attr("disabled", false);
    $("#PLTradeLicencehidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLbusinessNature

  handlePLbusinessNatureChange = (e) => {
    this.setState({
      PLbusinessNature: e.target.value,
    });
  };

  // PLAddressProofType
  PLAddressProofType_radioChange = (e) => {
    this.setState(
      {
        PLAddressProofType_radio: e.currentTarget.value,
      },
      () => {
        if (this.state.PLAddressProofType_radio != "CopyOfRegistry") {
          $(".RentAgreement").css("display", "flex");
          $(".CopyOfRegistry").hide();
        } else if (this.state.PLAddressProofType_radio == "CopyOfRegistry") {
          $(".CopyOfRegistry").css("display", "flex");
          $(".RentAgreement").hide();
        }
        this.checkhandlePLProceedBtn();
      }
    );
  };

  // PLCopyOfRegistry
  handlePLCopyOfRegistryChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "CopyOfRegistry",
          },
          () => {
            $("#PLCopyOfRegistryModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "CopyOfRegistry",
          });
        }
      });
      $("#PLCopyOfRegistryModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLCopyOfRegistry = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLCopyOfRegistry").attr("disabled", true);
      $("#upload_btn20_PLCopyOfRegistry").hide();
      $("#upload_btnloader_PLCopyOfRegistry").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLCopyOfRegistryDocLink: res.data.data.CopyOfRegistry,
                },
                () => $("#viewDocLink_PLCopyOfRegistry").css("display", "block")
              );
              PLCopyOfRegistryStatus = "remove";
              PLOptStatusArray[0].status = PLCopyOfRegistryStatus;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLCopyOfRegistryModal").modal("hide");
              $("#cancelbtn_PLCopyOfRegistry").attr("disabled", false);
              $("#upload_btn20_PLCopyOfRegistry").show();
              $("#upload_btnloader_PLCopyOfRegistry").hide();
              $("#choose_PLCopyOfRegistry").hide();
              $("#X-btn_PLCopyOfRegistry").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLCopyOfRegistry").attr("disabled", false);
            $("#upload_btn20_PLCopyOfRegistry").show();
            $("#upload_btnloader_PLCopyOfRegistry").hide();
            $("#PLCopyOfRegistryModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLCopyOfRegistry").attr("disabled", false);
            $("#upload_btn20_PLCopyOfRegistry").show();
            $("#upload_btnloader_PLCopyOfRegistry").hide();
            $("#PLCopyOfRegistryModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLCopyOfRegistry").attr("disabled", false);
          $("#upload_btn20_PLCopyOfRegistry").show();
          $("#upload_btnloader_PLCopyOfRegistry").hide();
          $("#PLCopyOfRegistryModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLCopyOfRegistry = () => {
    $("#X-btn_PLCopyOfRegistry").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "CopyOfRegistry",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLCopyOfRegistryStatus = res.data.data.status;
          PLOptStatusArray[0].status = PLCopyOfRegistryStatus;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLCopyOfRegistry").show();
          $("#X-btn_PLCopyOfRegistry").hide();
          $("#X-btn_PLCopyOfRegistry").attr("disabled", false);
          $("#viewDocLink_PLCopyOfRegistry").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLCopyOfRegistry").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLCopyOfRegistry").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLCopyOfRegistryseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLCopyOfRegistryseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "CopyOfRegistry",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLCopyOfRegistryseecommentsbtn").hide();
          $("#PLCopyOfRegistryhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLCopyOfRegistryhidecommentsbtn = () => {
    $("#PLCopyOfRegistryseecommentsbtn").show();
    $("#PLCopyOfRegistryseecommentsbtn").attr("disabled", false);
    $("#PLCopyOfRegistryhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLRentAgreement
  handlePLRentAgreementChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "RentAgreement",
          },
          () => {
            $("#PLRentAgreementModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "RentAgreement",
          });
        }
      });
      $("#PLRentAgreementModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLRentAgreement = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLRentAgreement").attr("disabled", true);
      $("#upload_btn20_PLRentAgreement").hide();
      $("#upload_btnloader_PLRentAgreement").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLRentAgreementDocLink: res.data.data.RentAgreement,
                },
                () => $("#viewDocLink_PLRentAgreement").css("display", "block")
              );
              PLRentAgreementStatus = "remove";
              PLOptStatusArray[1].status = PLRentAgreementStatus;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLRentAgreementModal").modal("hide");
              $("#cancelbtn_PLRentAgreement").attr("disabled", false);
              $("#upload_btn20_PLRentAgreement").show();
              $("#upload_btnloader_PLRentAgreement").hide();
              $("#choose_PLRentAgreement").hide();
              $("#X-btn_PLRentAgreement").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLRentAgreement").attr("disabled", false);
            $("#upload_btn20_PLRentAgreement").show();
            $("#upload_btnloader_PLRentAgreement").hide();
            $("#PLRentAgreementModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLRentAgreement").attr("disabled", false);
            $("#upload_btn20_PLRentAgreement").show();
            $("#upload_btnloader_PLRentAgreement").hide();
            $("#PLRentAgreementModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLRentAgreement").attr("disabled", false);
          $("#upload_btn20_PLRentAgreement").show();
          $("#upload_btnloader_PLRentAgreement").hide();
          $("#PLRentAgreementModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLRentAgreement = () => {
    $("#X-btn_PLRentAgreement").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "RentAgreement",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLRentAgreementStatus = res.data.data.status;
          PLOptStatusArray[1].status = PLRentAgreementStatus;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLRentAgreement").show();
          $("#X-btn_PLRentAgreement").hide();
          $("#X-btn_PLRentAgreement").attr("disabled", false);
          $("#viewDocLink_PLRentAgreement").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLRentAgreement").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLRentAgreement").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLRentAgreementseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLRentAgreementseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "RentAgreement",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLRentAgreementseecommentsbtn").hide();
          $("#PLRentAgreementhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLRentAgreementhidecommentsbtn = () => {
    $("#PLRentAgreementseecommentsbtn").show();
    $("#PLRentAgreementseecommentsbtn").attr("disabled", false);
    $("#PLRentAgreementhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLPR
  handlePLPRChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "PropertyTaxReceipt",
          },
          () => {
            $("#PLPRModal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "PropertyTaxReceipt",
          });
        }
      });
      $("#PLPRModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLPR = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLPR").attr("disabled", true);
      $("#upload_btn20_PLPR").hide();
      $("#upload_btnloader_PLPR").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLPRDocLink: res.data.data.PropertyTaxReceipt,
                },
                () => $("#viewDocLink_PLPR").css("display", "block")
              );
              PLPRStatus = "remove";
              PLStatusArray[14].status = PLPRStatus;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLPRModal").modal("hide");
              $("#cancelbtn_PLPR").attr("disabled", false);
              $("#upload_btn20_PLPR").show();
              $("#upload_btnloader_PLPR").hide();
              $("#choose_PLPR").hide();
              $("#X-btn_PLPR").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLPR").attr("disabled", false);
            $("#upload_btn20_PLPR").show();
            $("#upload_btnloader_PLPR").hide();
            $("#PLPRModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLPR").attr("disabled", false);
            $("#upload_btn20_PLPR").show();
            $("#upload_btnloader_PLPR").hide();
            $("#PLPRModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLPR").attr("disabled", false);
          $("#upload_btn20_PLPR").show();
          $("#upload_btnloader_PLPR").hide();
          $("#PLPRModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLPR = () => {
    $("#X-btn_PLPR").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "PropertyTaxReceipt",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLPRStatus = res.data.data.status;
          PLStatusArray[14].status = PLPRStatus;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLPR").show();
          $("#X-btn_PLPR").hide();
          $("#X-btn_PLPR").attr("disabled", false);
          $("#viewDocLink_PLPR").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLPR").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLPR").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLPRseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLPRseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PropertyTaxReceipt",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLPRseecommentsbtn").hide();
          $("#PLPRhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLPRhidecommentsbtn = () => {
    $("#PLPRseecommentsbtn").show();
    $("#PLPRseecommentsbtn").attr("disabled", false);
    $("#PLPRhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLAdditional
  handlePLAddFileChange = (e, index, status) => {
    PLadditionalReuploadIndex = index;
    PLadditionalReuploadStatus = status;
    $(".upload_btn20_7").show();
    $(".upload_btnloader_7").hide();
    const file = e.target.files[0];
    if (!PLadditionalFilesNames.includes(this.state.PLAdd_DocName.trim())) {
      const myfile = e.target.files;
      if (ChooseNowBtnsDisable == "Yes") {
        this.showNotification(
          "Files cannot be uploaded when the service is locked!"
        );
      } else if (myfile.length == 1) {
        const file = myfile[0];
        if (file.type.match("audio.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.type.match("video.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.size > 104857600) {
          this.showNotification("File too big!");
        } else if (file.name.length > 100) {
          this.showNotification("File name limit exceed!");
        } else {
          this.setState(
            {
              fileName: file.name,
              fileBase64: file,
              documentType: "additional",
              documentName: this.state.PLAdd_DocName,
            },
            () => {
              $(".PLAddModal").modal({ backdrop: "static", keyboard: false });
            }
          );
        }
      } else if (myfile.length > 1) {
        var zip = new JSZip();
        for (let i = 0; i < myfile.length; i++) {
          zip.file(myfile[i].name, myfile[i], { base64: true });
        }
        zip.generateAsync({ type: "blob" }).then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "additional",
              documentName: this.state.PLAdd_DocName,
            });
          }
        });
        $(".PLAddModal").modal({ backdrop: "static", keyboard: false });
      } else {
        this.showNotification("File cannot be null!");
      }
    } else {
      this.showNotification(
        "Document names can not be the same, Try again with different name!"
      );
    }
  };

  uploadPLFile = (FileIndex, FileStatus) => {
    let AdditionaldocumentName1;
    if (FileStatus == "reupload") {
      $(".proceednowbtn").attr("disabled", true);
      let fileTobeUploaded = this.state.PLadditionalFiles[FileIndex];
      AdditionaldocumentName1 = Object.keys(fileTobeUploaded)[0];
      mainaction = "reupload";
    } else {
      AdditionaldocumentName1 = this.state.documentName;
      mainaction = "upload";
    }
    if (AdditionaldocumentName1.trim() == "") {
      $(".PLAddModal").modal("hide");
      this.showNotification("Enter document Name first");
    } else if (this.state.comment.length <= 250) {
      $(".cancelbtn_7").attr("disabled", true);
      $(".upload_btn20_7").hide();
      $(".upload_btnloader_7").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", AdditionaldocumentName1);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            $(".PLAddModal").modal("hide");
            $(".cancelbtn_7").attr("disabled", false);
            $(`#${FileIndex}PLFileseecommentsbtn`).attr("disabled", false);
            if (PLadditionalReupload == false) {
              PLadditionalFilesNames.push(this.state.PLAdd_DocName);
              this.state.PLadditionalFiles.pop();
              this.setState(
                {
                  comment: "",
                  PLadditionalFiles: [
                    ...this.state.PLadditionalFiles,
                    res.data.data,
                  ],
                },
                () => {
                  this.setState(
                    {
                      api_PLadditionalFiles: "Yes",
                    },
                    () => {
                      this.PLadditionalFilesArea();
                      this.checkDropdownValue();
                      this.checkhandlePLProceedBtn();
                    }
                  );

                  $(".PLadditionalInput").attr("disabled", true);
                }
              );
            } else if (PLadditionalReupload == true) {
              $(".proceednowbtn").attr("disabled", false);
              var array = [...this.state.PLadditionalFiles];
              array.splice(FileIndex, 1, res.data.data);
              this.setState(
                {
                  comment: "",
                  api_PLadditionalFiles: "Yes",
                  PLadditionalFiles: array,
                },
                () => {
                  this.PLadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandlePLProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".PLAddModal").modal("hide");
            this.showNotification("Something Went Wrong!");
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".PLAddModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          this.showNotification("Something went wrong, Try again!");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLFile = (index) => {
    $("#X-btn_7").attr("disabled", true);
    let fileTobeDeleted = this.state.PLadditionalFiles[index];
    let NameOfAddFile = Object.keys(fileTobeDeleted)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "additional",
          documentName: NameOfAddFile,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          var array = [...this.state.PLadditionalFiles];
          if (res.data.data.status == "reupload") {
            array[index].status = "reupload";
            this.setState(
              {
                PLadditionalFiles: array,
              },
              () => {
                this.PLadditionalFilesArea();
                this.checkDropdownValue();
                this.checkhandlePLProceedBtn();
              }
            );
            this.showNotification(res.data.message);
          } else {
            array.splice(index, 1);
            if (array.length > 0) {
              this.setState(
                {
                  PLadditionalFiles: array,
                },
                () => {
                  this.PLadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandlePLProceedBtn();
                }
              );
            } else if (array.length == 0) {
              this.setState(
                {
                  PLadditionalFiles: [],
                  PLAdd_DocName: "",
                  api_PLadditionalFiles: "No",
                  IsPLAddFileDisabled: true,
                },
                () => {
                  this.PLadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandlePLProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_7").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLFileseeComments = (index) => {
    $(".commentsbtn").attr("disabled", false);
    $(`#${index}PLFileseecommentsbtn`).attr("disabled", true);
    let SelectedFile = this.state.PLadditionalFiles[index];
    let NameOfAddFile = Object.keys(SelectedFile)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "additional",
          documentName: NameOfAddFile,
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(`#${index}PLFileseecommentsbtn`).hide();
          $(".hidecommentsbtn").hide();
          $(`#${index}PLFilehidecommentsbtn`).show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLFilehideComments = (index) => {
    $(".seecommentsbtn").show();
    $(`#${index}PLFileseecommentsbtn`).show();
    $(`#${index}PLFileseecommentsbtn`).attr("disabled", false);
    $(`#${index}PLFilehidecommentsbtn`).hide();
    $(".hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  checkAddCommentPLFiles = (FileIndex, isDisabled) => {
    if (isDisabled == true) {
      return (
        <>
          <button
            className="seecommentsbtn PLFileseecommentsbtn"
            id={`${FileIndex}PLFileseecommentsbtn`}
            disabled
            onClick={() => this.handlePLFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn PLFilehidecommentsbtn"
            id={`${FileIndex}PLFilehidecommentsbtn`}
            onClick={() => this.handlePLFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    } else if (isDisabled == false) {
      return (
        <>
          <button
            className="seecommentsbtn commentsbtn PLFileseecommentsbtn"
            id={`${FileIndex}PLFileseecommentsbtn`}
            onClick={() => this.handlePLFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn PLFilehidecommentsbtn"
            id={`${FileIndex}PLFilehidecommentsbtn`}
            onClick={() => this.handlePLFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    }
  };

  checkStatusPLAddFiles = (status, link, index) => {
    PLadditionalFilesIndex = index;
    if (HideProceedBtn == "Yes") {
      $(".proceednowbtn").hide();
      if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
          </>
        );
      }
    } else {
      if (status == undefined) {
        status = "open";
      }
      if (status == "open") {
        mainaction = "upload";
        PLadditionalReupload = true;
        $(".proceednowbtn").show();
        return (
          <>
            <label
              htmlFor={`ul_file_PLAdd${index}`}
              className="choose_btn choose_7"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              multiple
              name="file[]"
              className="upload-photo"
              id={`ul_file_PLAdd${index}`}
              // disabled={this.state.IsPLAddFileDisabled}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handlePLAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "remove") {
        mainaction = "upload";
        $(".proceednowbtn").show();
        return (
          <>
            <button
              className="X-btn X-btn_7"
              onClick={() => this.removePLFile(index)}
            >
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_1025.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "reupload") {
        PLadditionalReupload = true;
        mainaction = "reupload";
        $(".proceednowbtn").show();
        $(".proceednowbtn").attr("disabled", true);
        return (
          <>
            <label
              htmlFor={`ul_file_PLAdd${index}`}
              className="choose_btn choose_7"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              multiple
              name="file[]"
              className="upload-photo"
              id={`ul_file_PLAdd${index}`}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handlePLAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
          </>
        );
      }
    }
  };

  AdditionalPLFileDisabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero PLadditionalInput"
        value={NameOfAddFile}
        disabled
        onChange={(e) => this.handlePLAdd_DocNameChange(e)}
      />
    );
  };

  AdditionalPLFileEnabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero PLadditionalInput"
        value={NameOfAddFile}
        onChange={(e) => this.handlePLAdd_DocNameChange(e)}
      />
    );
  };

  PLadditionalFilesArea = () => {
    PLadditionalFilesNames = [];
    if (this.state.api_PLadditionalFiles == "Yes") {
      return this.state.PLadditionalFiles.map((file, index) => {
        let NameOfAddFile = Object.keys(file)[0];
        PLadditionalFilesNames.push(NameOfAddFile);
        let link = file[Object.keys(file)[0]];
        let AdditionalFileInputField;
        let commentDisabled;
        if (file.status == "open" || file.status == undefined) {
          AdditionalFileInputField =
            this.AdditionalPLFileEnabledField(NameOfAddFile);
          commentDisabled = true;
        } else if (file.status != "open") {
          AdditionalFileInputField =
            this.AdditionalPLFileDisabledField(NameOfAddFile);
          commentDisabled = false;
        }
        return (
          <div className="displayflex" key={index}>
            <div className="uploadnocol">
              <p className="uploadadditionalsidetext">{index + 1}&#41;</p>
            </div>
            <div className="uploadadditionaldatacol">
              <div className="row mb-2">
                <div className="col-md-5">{AdditionalFileInputField}</div>
                <div className="col-md-7">
                  <div className="float-md-right float-left">
                    {this.checkAddCommentPLFiles(index, commentDisabled)}
                    {this.checkStatusPLAddFiles(file.status, link, index)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.api_PLadditionalFiles == "No") {
      return (
        <div className="displayflex">
          <div className="uploadnocol">
            <p className="uploadadditionalsidetext">1&#41;</p>
          </div>
          <div className="uploadadditionaldatacol">
            <div className="row mb-2">
              <div className="col-md-5">
                <input
                  type="text"
                  placeholder="Document name..."
                  maxLength="150"
                  className="uploadtextfield marginzero"
                  value={this.state.PLAdd_DocName}
                  onChange={(e) => this.handlePLAdd_DocNameChange(e)}
                />
              </div>
              <div className="col-md-7">
                <div className="fl_right">
                  {this.checkAddCommentPLFiles(0, true)}
                  {this.checkStatusPLAddFiles("open", null, 0)}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  handlePLAdd_DocNameChange = (e) => {
    this.setState(
      {
        PLAdd_DocName: e.target.value,
      },
      () => {
        if (this.state.PLAdd_DocName.trim().length > 0) {
          this.setState({
            IsPLAddFileDisabled: false,
          });
        } else {
          this.setState({
            IsPLAddFileDisabled: true,
          });
        }
      }
    );
  };

  handleAddPLFileBtn = (FileIndex) => {
    const { PLAdd_DocName, PLadditionalFiles } = this.state;

    let prevFile = PLadditionalFiles[FileIndex];
    let NameOfPrevFile = Object.keys(prevFile)[0];
    let valueOffile = prevFile[Object.keys(prevFile)[0]];

    if (FileIndex == undefined) {
      this.showNotification("empty fields");
    } else if (PLAdd_DocName.trim() == "") {
      this.showNotification("Required fields cannot be empty!");
    } else if (valueOffile == null) {
      this.showNotification("Choose a file");
    } else {
      this.setState(
        {
          PLadditionalFiles: [...this.state.PLadditionalFiles, ""],
          PLAdd_DocName: "",
          IsPLAddFileDisabled: true,
        },
        () => {
          $(".PLadditionalInput").attr("disabled", false);
        }
      );
    }
  };

  // Proceed Checks
  PLcheckRemainingReuploads = () => {
    if (
      PLAadhar1Status != "reupload" &&
      PLAadhar2Status != "reupload" &&
      PLPan1Status != "reupload" &&
      PLPan2Status != "reupload" &&
      PLPic1Status != "reupload" &&
      PLPic2Status != "reupload" &&
      PLPan3Status != "reupload" &&
      PLCIStatus != "reupload" &&
      PLMOAStatus != "reupload" &&
      PLAOAStatus != "reupload" &&
      PLBRStatus != "reupload" &&
      PLBPStatus != "reupload" &&
      PLGumastaStatus != "reupload" &&
      PLTradeLicenceStatus != "reupload" &&
      PLCopyOfRegistryStatus != "reupload" &&
      PLRentAgreementStatus != "reupload" &&
      PLPRStatus != "reupload"
    ) {
      AnyReuploadOccuredPL = "No";
    } else {
      $(".proceednowbtn3").show();
      AnyReuploadOccuredPL = "Yes";
    }
  };

  checkhandlePLProceedBtn = () => {
    let statusCheckReupload = this.statusCheckFunction(
      "reupload",
      PLStatusArray
    );
    let PLadditionalStatusArrayCheck = this.statusCheckFunction(
      "reupload",
      this.state.PLadditionalFiles
    );
    let goodtogo = "No";
    if (this.state.PLAddressProofType_radio == "CopyOfRegistry") {
      if (
        PLOptStatusArray[0].status != "reupload" &&
        PLOptStatusArray[0].status != "upload" &&
        PLOptStatusArray[0].status != "open"
      ) {
        goodtogo = "Yes";
      } else {
        goodtogo = "No";
      }
    } else if (this.state.PLAddressProofType_radio == "RentAgreement") {
      if (
        PLOptStatusArray[1].status != "reupload" &&
        PLOptStatusArray[1].status != "upload" &&
        PLOptStatusArray[1].status != "open"
      ) {
        goodtogo = "Yes";
      } else {
        goodtogo = "No";
      }
    } else {
      goodtogo = "No";
    }

    if (
      AnyReuploadOccuredPL == "Yes" &&
      statusCheckReupload == undefined &&
      goodtogo == "Yes" &&
      PLadditionalStatusArrayCheck == undefined
    ) {
      $(".proceednowbtn3").attr("disabled", false);
      PLproceednowforword = "success";
    } else if (
      PLAadhar1Status == "remove" &&
      PLAadhar2Status == "remove" &&
      PLPan1Status == "remove" &&
      PLPan2Status == "remove" &&
      PLPic1Status == "remove" &&
      PLPic2Status == "remove" &&
      PLPan3Status == "remove" &&
      PLCIStatus == "remove" &&
      PLMOAStatus == "remove" &&
      PLAOAStatus == "remove" &&
      PLBRStatus == "remove" &&
      PLBPStatus == "remove" &&
      PLGumastaStatus == "remove" &&
      PLTradeLicenceStatus == "remove" &&
      PLPRStatus == "remove" &&
      goodtogo == "Yes"
    ) {
      $(".proceednowbtn3").attr("disabled", false);
      PLproceednowforword = "success";
    } else {
      $(".proceednowbtn3").attr("disabled", true);
      PLproceednowforword = "fail";
    }
  };

  handlePLProceedBtn = () => {
    this.checkhandlePLProceedBtn();
    if (this.state.PLbusinessNature.trim() == "") {
      this.showNotification("Required fields can not be empty!");
    } else if (PLproceednowforword == "success") {
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=lockService",
          {
            userId: Decuserid,
            requestType: temp,
            agentId: Decagentid,

            serviceId: Decserviceid,
            serviceName: Decservicename,
            serviceType: this.state.serviceType,
            FY: "",
            Month: "",
            inputFields: [
              {
                inputFieldName: "AddressProofType",
                inputFieldValue: this.state.PLAddressProofType_radio,
                inputFieldtype: "general",
              },
              {
                inputFieldName: "Nature",
                inputFieldValue: this.state.PLbusinessNature,
                inputFieldtype: "general",
              },
            ],
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.showNotification(res.data.message);
            this.getserviceInformationApi();
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else {
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {});
    }
  };

  // HUF

  // HUFAadhar
  handleHUFAadharChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "AadhaarCardKarta",
          },
          () => {
            $("#HUFAadharModal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "AadhaarCardKarta",
          });
        }
      });
      $("#HUFAadharModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadHUFAadhar = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_HUFAadhar").attr("disabled", true);
      $("#upload_btn20_HUFAadhar").hide();
      $("#upload_btnloader_HUFAadhar").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  HUFAadharDocLink: res.data.data.AadhaarCardKarta,
                },
                () => $("#viewDocLink_HUFAadhar").css("display", "block")
              );
              HUFAadharStatus = "remove";
              HUFStatusArray[0].status = HUFAadharStatus;
              this.checkDropdownValue();
              this.checkhandleHUFProceedBtn();
              $("#HUFAadharModal").modal("hide");
              $("#cancelbtn_HUFAadhar").attr("disabled", false);
              $("#upload_btn20_HUFAadhar").show();
              $("#upload_btnloader_HUFAadhar").hide();
              $("#choose_HUFAadhar").hide();
              $("#X-btn_HUFAadhar").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_HUFAadhar").attr("disabled", false);
            $("#upload_btn20_HUFAadhar").show();
            $("#upload_btnloader_HUFAadhar").hide();
            $("#HUFAadharModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_HUFAadhar").attr("disabled", false);
            $("#upload_btn20_HUFAadhar").show();
            $("#upload_btnloader_HUFAadhar").hide();
            $("#HUFAadharModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_HUFAadhar").attr("disabled", false);
          $("#upload_btn20_HUFAadhar").show();
          $("#upload_btnloader_HUFAadhar").hide();
          $("#HUFAadharModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeHUFAadhar = () => {
    $("#X-btn_HUFAadhar").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "AadhaarCardKarta",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          HUFAadharStatus = res.data.data.status;
          HUFStatusArray[0].status = HUFAadharStatus;
          this.checkDropdownValue();
          this.checkhandleHUFProceedBtn();
          $("#choose_HUFAadhar").show();
          $("#X-btn_HUFAadhar").hide();
          $("#X-btn_HUFAadhar").attr("disabled", false);
          $("#viewDocLink_HUFAadhar").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_HUFAadhar").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_HUFAadhar").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleHUFAadharseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFAadharseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "AadhaarCardKarta",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#HUFAadharseecommentsbtn").hide();
          $("#HUFAadharhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFAadharhidecommentsbtn = () => {
    $("#HUFAadharseecommentsbtn").show();
    $("#HUFAadharseecommentsbtn").attr("disabled", false);
    $("#HUFAadharhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // HUFPan1
  handleHUFPan1Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "PANCardKarta",
          },
          () => {
            $("#HUFPan1Modal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "PANCardKarta",
          });
        }
      });
      $("#HUFPan1Modal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadHUFPan1 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_HUFPan1").attr("disabled", true);
      $("#upload_btn20_HUFPan1").hide();
      $("#upload_btnloader_HUFPan1").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  HUFPan1DocLink: res.data.data.PANCardKarta,
                },
                () => $("#viewDocLink_HUFPan1").css("display", "block")
              );
              HUFPan1Status = "remove";
              HUFStatusArray[1].status = HUFPan1Status;
              this.checkDropdownValue();
              this.checkhandleHUFProceedBtn();
              $("#HUFPan1Modal").modal("hide");
              $("#cancelbtn_HUFPan1").attr("disabled", false);
              $("#upload_btn20_HUFPan1").show();
              $("#upload_btnloader_HUFPan1").hide();
              $("#choose_HUFPan1").hide();
              $("#X-btn_HUFPan1").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_HUFPan1").attr("disabled", false);
            $("#upload_btn20_HUFPan1").show();
            $("#upload_btnloader_HUFPan1").hide();
            $("#HUFPan1Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_HUFPan1").attr("disabled", false);
            $("#upload_btn20_HUFPan1").show();
            $("#upload_btnloader_HUFPan1").hide();
            $("#HUFPan1Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_HUFPan1").attr("disabled", false);
          $("#upload_btn20_HUFPan1").show();
          $("#upload_btnloader_HUFPan1").hide();
          $("#HUFPan1Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeHUFPan1 = () => {
    $("#X-btn_HUFPan1").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "PANCardKarta",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          HUFPan1Status = res.data.data.status;
          HUFStatusArray[1].status = HUFPan1Status;
          this.checkDropdownValue();
          this.checkhandleHUFProceedBtn();
          $("#choose_HUFPan1").show();
          $("#X-btn_HUFPan1").hide();
          $("#X-btn_HUFPan1").attr("disabled", false);
          $("#viewDocLink_HUFPan1").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_HUFPan1").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_HUFPan1").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleHUFPan1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFPan1seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PANCardKarta",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#HUFPan1seecommentsbtn").hide();
          $("#HUFPan1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFPan1hidecommentsbtn = () => {
    $("#HUFPan1seecommentsbtn").show();
    $("#HUFPan1seecommentsbtn").attr("disabled", false);
    $("#HUFPan1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // HUFPic
  handleHUFPicChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "ApplicantPhoto1",
          },
          () => {
            $("#HUFPicModal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "ApplicantPhoto1",
          });
        }
      });
      $("#HUFPicModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadHUFPic = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_HUFPic").attr("disabled", true);
      $("#upload_btn20_HUFPic").hide();
      $("#upload_btnloader_HUFPic").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  HUFPicDocLink: res.data.data.ApplicantPhoto1,
                },
                () => $("#viewDocLink_HUFPic").css("display", "block")
              );
              HUFPicStatus = "remove";
              HUFStatusArray[2].status = HUFPicStatus;
              this.checkDropdownValue();
              this.checkhandleHUFProceedBtn();
              $("#HUFPicModal").modal("hide");
              $("#cancelbtn_HUFPic").attr("disabled", false);
              $("#upload_btn20_HUFPic").show();
              $("#upload_btnloader_HUFPic").hide();
              $("#choose_HUFPic").hide();
              $("#X-btn_HUFPic").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_HUFPic").attr("disabled", false);
            $("#upload_btn20_HUFPic").show();
            $("#upload_btnloader_HUFPic").hide();
            $("#HUFPicModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_HUFPic").attr("disabled", false);
            $("#upload_btn20_HUFPic").show();
            $("#upload_btnloader_HUFPic").hide();
            $("#HUFPicModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_HUFPic").attr("disabled", false);
          $("#upload_btn20_HUFPic").show();
          $("#upload_btnloader_HUFPic").hide();
          $("#HUFPicModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeHUFPic = () => {
    $("#X-btn_HUFPic").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "ApplicantPhoto1",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          HUFPicStatus = res.data.data.status;
          HUFStatusArray[2].status = HUFPicStatus;
          this.checkDropdownValue();
          this.checkhandleHUFProceedBtn();
          $("#choose_HUFPic").show();
          $("#X-btn_HUFPic").hide();
          $("#X-btn_HUFPic").attr("disabled", false);
          $("#viewDocLink_HUFPic").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_HUFPic").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_HUFPic").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleHUFPicseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFPicseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ApplicantPhoto1",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#HUFPicseecommentsbtn").hide();
          $("#HUFPichidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFPichidecommentsbtn = () => {
    $("#HUFPicseecommentsbtn").show();
    $("#HUFPicseecommentsbtn").attr("disabled", false);
    $("#HUFPichidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // HUFPan2
  handleHUFPan2Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "PANCardHUF",
          },
          () => {
            $("#HUFPan2Modal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "PANCardHUF",
          });
        }
      });
      $("#HUFPan2Modal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadHUFPan2 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_HUFPan2").attr("disabled", true);
      $("#upload_btn20_HUFPan2").hide();
      $("#upload_btnloader_HUFPan2").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  HUFPan2DocLink: res.data.data.PANCardHUF,
                },
                () => $("#viewDocLink_HUFPan2").css("display", "block")
              );
              HUFPan2Status = "remove";
              HUFStatusArray[3].status = HUFPan2Status;
              this.checkDropdownValue();
              this.checkhandleHUFProceedBtn();
              $("#HUFPan2Modal").modal("hide");
              $("#cancelbtn_HUFPan2").attr("disabled", false);
              $("#upload_btn20_HUFPan2").show();
              $("#upload_btnloader_HUFPan2").hide();
              $("#choose_HUFPan2").hide();
              $("#X-btn_HUFPan2").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_HUFPan2").attr("disabled", false);
            $("#upload_btn20_HUFPan2").show();
            $("#upload_btnloader_HUFPan2").hide();
            $("#HUFPan2Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_HUFPan2").attr("disabled", false);
            $("#upload_btn20_HUFPan2").show();
            $("#upload_btnloader_HUFPan2").hide();
            $("#HUFPan2Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_HUFPan2").attr("disabled", false);
          $("#upload_btn20_HUFPan2").show();
          $("#upload_btnloader_HUFPan2").hide();
          $("#HUFPan2Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeHUFPan2 = () => {
    $("#X-btn_HUFPan2").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "PANCardHUF",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          HUFPan2Status = res.data.data.status;
          HUFStatusArray[3].status = HUFPan2Status;
          this.checkDropdownValue();
          this.checkhandleHUFProceedBtn();
          $("#choose_HUFPan2").show();
          $("#X-btn_HUFPan2").hide();
          $("#X-btn_HUFPan2").attr("disabled", false);
          $("#viewDocLink_HUFPan2").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_HUFPan2").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_HUFPan2").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleHUFPan2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFPan2seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PANCardHUF",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#HUFPan2seecommentsbtn").hide();
          $("#HUFPan2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFPan2hidecommentsbtn = () => {
    $("#HUFPan2seecommentsbtn").show();
    $("#HUFPan2seecommentsbtn").attr("disabled", false);
    $("#HUFPan2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // HUFDeed
  handleHUFDeedChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "HUFDeedDocument",
          },
          () => {
            $("#HUFDeedModal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "HUFDeedDocument",
          });
        }
      });
      $("#HUFDeedModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadHUFDeed = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_HUFDeed").attr("disabled", true);
      $("#upload_btn20_HUFDeed").hide();
      $("#upload_btnloader_HUFDeed").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  HUFDeedDocLink: res.data.data.HUFDeedDocument,
                },
                () => $("#viewDocLink_HUFDeed").css("display", "block")
              );
              HUFDeedStatus = "remove";
              HUFStatusArray[4].status = HUFDeedStatus;
              this.checkDropdownValue();
              this.checkhandleHUFProceedBtn();
              $("#HUFDeedModal").modal("hide");
              $("#cancelbtn_HUFDeed").attr("disabled", false);
              $("#upload_btn20_HUFDeed").show();
              $("#upload_btnloader_HUFDeed").hide();
              $("#choose_HUFDeed").hide();
              $("#X-btn_HUFDeed").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_HUFDeed").attr("disabled", false);
            $("#upload_btn20_HUFDeed").show();
            $("#upload_btnloader_HUFDeed").hide();
            $("#HUFDeedModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_HUFDeed").attr("disabled", false);
            $("#upload_btn20_HUFDeed").show();
            $("#upload_btnloader_HUFDeed").hide();
            $("#HUFDeedModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_HUFDeed").attr("disabled", false);
          $("#upload_btn20_HUFDeed").show();
          $("#upload_btnloader_HUFDeed").hide();
          $("#HUFDeedModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeHUFDeed = () => {
    $("#X-btn_HUFDeed").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "HUFDeedDocument",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          HUFDeedStatus = res.data.data.status;
          HUFStatusArray[4].status = HUFDeedStatus;
          this.checkDropdownValue();
          this.checkhandleHUFProceedBtn();
          $("#choose_HUFDeed").show();
          $("#X-btn_HUFDeed").hide();
          $("#X-btn_HUFDeed").attr("disabled", false);
          $("#viewDocLink_HUFDeed").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_HUFDeed").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_HUFDeed").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleHUFDeedseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFDeedseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "HUFDeedDocument",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#HUFDeedseecommentsbtn").hide();
          $("#HUFDeedhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFDeedhidecommentsbtn = () => {
    $("#HUFDeedseecommentsbtn").show();
    $("#HUFDeedseecommentsbtn").attr("disabled", false);
    $("#HUFDeedhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // HUFBP
  handleHUFBPChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "BankProof",
          },
          () => {
            $("#HUFBPModal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "BankProof",
          });
        }
      });
      $("#HUFBPModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadHUFBP = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_HUFBP").attr("disabled", true);
      $("#upload_btn20_HUFBP").hide();
      $("#upload_btnloader_HUFBP").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  HUFBPDocLink: res.data.data.BankProof,
                },
                () => $("#viewDocLink_HUFBP").css("display", "block")
              );
              HUFBPStatus = "remove";
              HUFStatusArray[5].status = HUFBPStatus;
              this.checkDropdownValue();
              this.checkhandleHUFProceedBtn();
              $("#HUFBPModal").modal("hide");
              $("#cancelbtn_HUFBP").attr("disabled", false);
              $("#upload_btn20_HUFBP").show();
              $("#upload_btnloader_HUFBP").hide();
              $("#choose_HUFBP").hide();
              $("#X-btn_HUFBP").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_HUFBP").attr("disabled", false);
            $("#upload_btn20_HUFBP").show();
            $("#upload_btnloader_HUFBP").hide();
            $("#HUFBPModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_HUFBP").attr("disabled", false);
            $("#upload_btn20_HUFBP").show();
            $("#upload_btnloader_HUFBP").hide();
            $("#HUFBPModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_HUFBP").attr("disabled", false);
          $("#upload_btn20_HUFBP").show();
          $("#upload_btnloader_HUFBP").hide();
          $("#HUFBPModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeHUFBP = () => {
    $("#X-btn_HUFBP").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "BankProof",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          HUFBPStatus = res.data.data.status;
          HUFStatusArray[5].status = HUFBPStatus;
          this.checkDropdownValue();
          this.checkhandleHUFProceedBtn();
          $("#choose_HUFBP").show();
          $("#X-btn_HUFBP").hide();
          $("#X-btn_HUFBP").attr("disabled", false);
          $("#viewDocLink_HUFBP").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_HUFBP").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_HUFBP").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleHUFBPseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFBPseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "BankProof",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#HUFBPseecommentsbtn").hide();
          $("#HUFBPhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFBPhidecommentsbtn = () => {
    $("#HUFBPseecommentsbtn").show();
    $("#HUFBPseecommentsbtn").attr("disabled", false);
    $("#HUFBPhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // HUFGumasta
  handleHUFGumastaChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "Gumasta",
          },
          () => {
            $("#HUFGumastaModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "Gumasta",
          });
        }
      });
      $("#HUFGumastaModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadHUFGumasta = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_HUFGumasta").attr("disabled", true);
      $("#upload_btn20_HUFGumasta").hide();
      $("#upload_btnloader_HUFGumasta").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  HUFGumastaDocLink: res.data.data.Gumasta,
                },
                () => $("#viewDocLink_HUFGumasta").css("display", "block")
              );
              HUFGumastaStatus = "remove";
              HUFStatusArray[6].status = HUFGumastaStatus;
              this.checkDropdownValue();
              this.checkhandleHUFProceedBtn();
              $("#HUFGumastaModal").modal("hide");
              $("#cancelbtn_HUFGumasta").attr("disabled", false);
              $("#upload_btn20_HUFGumasta").show();
              $("#upload_btnloader_HUFGumasta").hide();
              $("#choose_HUFGumasta").hide();
              $("#X-btn_HUFGumasta").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_HUFGumasta").attr("disabled", false);
            $("#upload_btn20_HUFGumasta").show();
            $("#upload_btnloader_HUFGumasta").hide();
            $("#HUFGumastaModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_HUFGumasta").attr("disabled", false);
            $("#upload_btn20_HUFGumasta").show();
            $("#upload_btnloader_HUFGumasta").hide();
            $("#HUFGumastaModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_HUFGumasta").attr("disabled", false);
          $("#upload_btn20_HUFGumasta").show();
          $("#upload_btnloader_HUFGumasta").hide();
          $("#HUFGumastaModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeHUFGumasta = () => {
    $("#X-btn_HUFGumasta").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "Gumasta",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          HUFGumastaStatus = res.data.data.status;
          HUFStatusArray[6].status = HUFGumastaStatus;
          this.checkDropdownValue();
          this.checkhandleHUFProceedBtn();
          $("#choose_HUFGumasta").show();
          $("#X-btn_HUFGumasta").hide();
          $("#X-btn_HUFGumasta").attr("disabled", false);
          $("#viewDocLink_HUFGumasta").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_HUFGumasta").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_HUFGumasta").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleHUFGumastaseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFGumastaseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "Gumasta",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#HUFGumastaseecommentsbtn").hide();
          $("#HUFGumastahidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFGumastahidecommentsbtn = () => {
    $("#HUFGumastaseecommentsbtn").show();
    $("#HUFGumastaseecommentsbtn").attr("disabled", false);
    $("#HUFGumastahidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // HUFTradeLicence
  handleHUFTradeLicenceChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "TradeLicence",
          },
          () => {
            $("#HUFTradeLicenceModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "TradeLicence",
          });
        }
      });
      $("#HUFTradeLicenceModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadHUFTradeLicence = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_HUFTradeLicence").attr("disabled", true);
      $("#upload_btn20_HUFTradeLicence").hide();
      $("#upload_btnloader_HUFTradeLicence").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  HUFTradeLicenceDocLink: res.data.data.TradeLicence,
                },
                () => $("#viewDocLink_HUFTradeLicence").css("display", "block")
              );
              HUFTradeLicenceStatus = "remove";
              HUFStatusArray[7].status = HUFTradeLicenceStatus;
              this.checkDropdownValue();
              this.checkhandleHUFProceedBtn();
              $("#HUFTradeLicenceModal").modal("hide");
              $("#cancelbtn_HUFTradeLicence").attr("disabled", false);
              $("#upload_btn20_HUFTradeLicence").show();
              $("#upload_btnloader_HUFTradeLicence").hide();
              $("#choose_HUFTradeLicence").hide();
              $("#X-btn_HUFTradeLicence").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_HUFTradeLicence").attr("disabled", false);
            $("#upload_btn20_HUFTradeLicence").show();
            $("#upload_btnloader_HUFTradeLicence").hide();
            $("#HUFTradeLicenceModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_HUFTradeLicence").attr("disabled", false);
            $("#upload_btn20_HUFTradeLicence").show();
            $("#upload_btnloader_HUFTradeLicence").hide();
            $("#HUFTradeLicenceModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_HUFTradeLicence").attr("disabled", false);
          $("#upload_btn20_HUFTradeLicence").show();
          $("#upload_btnloader_HUFTradeLicence").hide();
          $("#HUFTradeLicenceModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeHUFTradeLicence = () => {
    $("#X-btn_HUFTradeLicence").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "TradeLicence",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          HUFTradeLicenceStatus = res.data.data.status;
          HUFStatusArray[7].status = HUFTradeLicenceStatus;
          this.checkDropdownValue();
          this.checkhandleHUFProceedBtn();
          $("#choose_HUFTradeLicence").show();
          $("#X-btn_HUFTradeLicence").hide();
          $("#X-btn_HUFTradeLicence").attr("disabled", false);
          $("#viewDocLink_HUFTradeLicence").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_HUFTradeLicence").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_HUFTradeLicence").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleHUFTradeLicenceseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFTradeLicenceseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "TradeLicence",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#HUFTradeLicenceseecommentsbtn").hide();
          $("#HUFTradeLicencehidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFTradeLicencehidecommentsbtn = () => {
    $("#HUFTradeLicenceseecommentsbtn").show();
    $("#HUFTradeLicenceseecommentsbtn").attr("disabled", false);
    $("#HUFTradeLicencehidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // HUFbusinessNature

  handleHUFbusinessNatureChange = (e) => {
    this.setState({
      HUFbusinessNature: e.target.value,
    });
  };

  // HUFAddressProofType
  HUFAddressProofType_radioChange = (e) => {
    this.setState(
      {
        HUFAddressProofType_radio: e.currentTarget.value,
      },
      () => {
        if (this.state.HUFAddressProofType_radio != "CopyOfRegistry") {
          $(".RentAgreement").css("display", "flex");
          $(".CopyOfRegistry").hide();
        } else if (this.state.HUFAddressProofType_radio == "CopyOfRegistry") {
          $(".CopyOfRegistry").css("display", "flex");
          $(".RentAgreement").hide();
        }
        this.checkhandleHUFProceedBtn();
      }
    );
  };
  // HUFCopyOfRegistry
  handleHUFCopyOfRegistryChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "CopyOfRegistry",
          },
          () => {
            $("#HUFCopyOfRegistryModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "CopyOfRegistry",
          });
        }
      });
      $("#HUFCopyOfRegistryModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadHUFCopyOfRegistry = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_HUFCopyOfRegistry").attr("disabled", true);
      $("#upload_btn20_HUFCopyOfRegistry").hide();
      $("#upload_btnloader_HUFCopyOfRegistry").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  HUFCopyOfRegistryDocLink: res.data.data.CopyOfRegistry,
                },
                () =>
                  $("#viewDocLink_HUFCopyOfRegistry").css("display", "block")
              );
              HUFCopyOfRegistryStatus = "remove";
              HUFOptStatusArray[0].status = HUFCopyOfRegistryStatus;
              this.checkDropdownValue();
              this.checkhandleHUFProceedBtn();
              $("#HUFCopyOfRegistryModal").modal("hide");
              $("#cancelbtn_HUFCopyOfRegistry").attr("disabled", false);
              $("#upload_btn20_HUFCopyOfRegistry").show();
              $("#upload_btnloader_HUFCopyOfRegistry").hide();
              $("#choose_HUFCopyOfRegistry").hide();
              $("#X-btn_HUFCopyOfRegistry").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_HUFCopyOfRegistry").attr("disabled", false);
            $("#upload_btn20_HUFCopyOfRegistry").show();
            $("#upload_btnloader_HUFCopyOfRegistry").hide();
            $("#HUFCopyOfRegistryModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_HUFCopyOfRegistry").attr("disabled", false);
            $("#upload_btn20_HUFCopyOfRegistry").show();
            $("#upload_btnloader_HUFCopyOfRegistry").hide();
            $("#HUFCopyOfRegistryModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_HUFCopyOfRegistry").attr("disabled", false);
          $("#upload_btn20_HUFCopyOfRegistry").show();
          $("#upload_btnloader_HUFCopyOfRegistry").hide();
          $("#HUFCopyOfRegistryModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeHUFCopyOfRegistry = () => {
    $("#X-btn_HUFCopyOfRegistry").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "CopyOfRegistry",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          HUFCopyOfRegistryStatus = res.data.data.status;
          HUFOptStatusArray[0].status = HUFCopyOfRegistryStatus;
          this.checkDropdownValue();
          this.checkhandleHUFProceedBtn();
          $("#choose_HUFCopyOfRegistry").show();
          $("#X-btn_HUFCopyOfRegistry").hide();
          $("#X-btn_HUFCopyOfRegistry").attr("disabled", false);
          $("#viewDocLink_HUFCopyOfRegistry").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_HUFCopyOfRegistry").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_HUFCopyOfRegistry").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleHUFCopyOfRegistryseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFCopyOfRegistryseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "CopyOfRegistry",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#HUFCopyOfRegistryseecommentsbtn").hide();
          $("#HUFCopyOfRegistryhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFCopyOfRegistryhidecommentsbtn = () => {
    $("#HUFCopyOfRegistryseecommentsbtn").show();
    $("#HUFCopyOfRegistryseecommentsbtn").attr("disabled", false);
    $("#HUFCopyOfRegistryhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // HUFRentAgreement
  handleHUFRentAgreementChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "RentAgreement",
          },
          () => {
            $("#HUFRentAgreementModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "RentAgreement",
          });
        }
      });
      $("#HUFRentAgreementModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadHUFRentAgreement = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_HUFRentAgreement").attr("disabled", true);
      $("#upload_btn20_HUFRentAgreement").hide();
      $("#upload_btnloader_HUFRentAgreement").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  HUFRentAgreementDocLink: res.data.data.RentAgreement,
                },
                () => $("#viewDocLink_HUFRentAgreement").css("display", "block")
              );
              HUFRentAgreementStatus = "remove";
              HUFOptStatusArray[1].status = HUFRentAgreementStatus;
              this.checkDropdownValue();
              this.checkhandleHUFProceedBtn();
              $("#HUFRentAgreementModal").modal("hide");
              $("#cancelbtn_HUFRentAgreement").attr("disabled", false);
              $("#upload_btn20_HUFRentAgreement").show();
              $("#upload_btnloader_HUFRentAgreement").hide();
              $("#choose_HUFRentAgreement").hide();
              $("#X-btn_HUFRentAgreement").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_HUFRentAgreement").attr("disabled", false);
            $("#upload_btn20_HUFRentAgreement").show();
            $("#upload_btnloader_HUFRentAgreement").hide();
            $("#HUFRentAgreementModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_HUFRentAgreement").attr("disabled", false);
            $("#upload_btn20_HUFRentAgreement").show();
            $("#upload_btnloader_HUFRentAgreement").hide();
            $("#HUFRentAgreementModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_HUFRentAgreement").attr("disabled", false);
          $("#upload_btn20_HUFRentAgreement").show();
          $("#upload_btnloader_HUFRentAgreement").hide();
          $("#HUFRentAgreementModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeHUFRentAgreement = () => {
    $("#X-btn_HUFRentAgreement").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "RentAgreement",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          HUFRentAgreementStatus = res.data.data.status;
          HUFOptStatusArray[1].status = HUFRentAgreementStatus;
          this.checkDropdownValue();
          this.checkhandleHUFProceedBtn();
          $("#choose_HUFRentAgreement").show();
          $("#X-btn_HUFRentAgreement").hide();
          $("#X-btn_HUFRentAgreement").attr("disabled", false);
          $("#viewDocLink_HUFRentAgreement").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          $("#X-btn_HUFRentAgreement").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_HUFRentAgreement").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleHUFRentAgreementseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFRentAgreementseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "RentAgreement",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#HUFRentAgreementseecommentsbtn").hide();
          $("#HUFRentAgreementhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFRentAgreementhidecommentsbtn = () => {
    $("#HUFRentAgreementseecommentsbtn").show();
    $("#HUFRentAgreementseecommentsbtn").attr("disabled", false);
    $("#HUFRentAgreementhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // HUFPR
  handleHUFPRChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "PropertyTaxReceipt",
          },
          () => {
            $("#HUFPRModal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "PropertyTaxReceipt",
          });
        }
      });
      $("#HUFPRModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadHUFPR = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_HUFPR").attr("disabled", true);
      $("#upload_btn20_HUFPR").hide();
      $("#upload_btnloader_HUFPR").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  HUFPRDocLink: res.data.data.PropertyTaxReceipt,
                },
                () => $("#viewDocLink_HUFPR").css("display", "block")
              );
              HUFPRStatus = "remove";
              HUFStatusArray[8].status = HUFPRStatus;
              this.checkDropdownValue();
              this.checkhandleHUFProceedBtn();
              $("#HUFPRModal").modal("hide");
              $("#cancelbtn_HUFPR").attr("disabled", false);
              $("#upload_btn20_HUFPR").show();
              $("#upload_btnloader_HUFPR").hide();
              $("#choose_HUFPR").hide();
              $("#X-btn_HUFPR").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_HUFPR").attr("disabled", false);
            $("#upload_btn20_HUFPR").show();
            $("#upload_btnloader_HUFPR").hide();
            $("#HUFPRModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_HUFPR").attr("disabled", false);
            $("#upload_btn20_HUFPR").show();
            $("#upload_btnloader_HUFPR").hide();
            $("#HUFPRModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_HUFPR").attr("disabled", false);
          $("#upload_btn20_HUFPR").show();
          $("#upload_btnloader_HUFPR").hide();
          $("#HUFPRModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeHUFPR = () => {
    $("#X-btn_HUFPR").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "PropertyTaxReceipt",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          HUFPRStatus = res.data.data.status;
          HUFStatusArray[8].status = HUFPRStatus;
          this.checkDropdownValue();
          this.checkhandleHUFProceedBtn();
          $("#choose_HUFPR").show();
          $("#X-btn_HUFPR").hide();
          $("#X-btn_HUFPR").attr("disabled", false);
          $("#viewDocLink_HUFPR").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_HUFPR").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_HUFPR").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleHUFPRseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFPRseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PropertyTaxReceipt",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#HUFPRseecommentsbtn").hide();
          $("#HUFPRhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFPRhidecommentsbtn = () => {
    $("#HUFPRseecommentsbtn").show();
    $("#HUFPRseecommentsbtn").attr("disabled", false);
    $("#HUFPRhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // HUFAdditional
  handleHUFAddFileChange = (e, index, status) => {
    HUFadditionalReuploadIndex = index;
    HUFadditionalReuploadStatus = status;
    $(".upload_btn20_7").show();
    $(".upload_btnloader_7").hide();
    const file = e.target.files[0];
    if (!HUFadditionalFilesNames.includes(this.state.HUFAdd_DocName.trim())) {
      const myfile = e.target.files;
      if (ChooseNowBtnsDisable == "Yes") {
        this.showNotification(
          "Files cannot be uploaded when the service is locked!"
        );
      } else if (myfile.length == 1) {
        const file = myfile[0];
        if (file.type.match("audio.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.type.match("video.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.size > 104857600) {
          this.showNotification("File too big!");
        } else if (file.name.length > 100) {
          this.showNotification("File name limit exceed!");
        } else {
          this.setState(
            {
              fileName: file.name,
              fileBase64: file,
              documentType: "additional",
              documentName: this.state.HUFAdd_DocName,
            },
            () => {
              $(".HUFAddModal").modal({ backdrop: "static", keyboard: false });
            }
          );
        }
      } else if (myfile.length > 1) {
        var zip = new JSZip();
        for (let i = 0; i < myfile.length; i++) {
          zip.file(myfile[i].name, myfile[i], { base64: true });
        }
        zip.generateAsync({ type: "blob" }).then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "additional",
              documentName: this.state.HUFAdd_DocName,
            });
          }
        });
        $(".HUFAddModal").modal({ backdrop: "static", keyboard: false });
      } else {
        this.showNotification("File cannot be null!");
      }
    } else {
      this.showNotification(
        "Document names can not be the same, Try again with different name!"
      );
    }
  };

  uploadHUFFile = (FileIndex, FileStatus) => {
    let AdditionaldocumentName1;
    if (FileStatus == "reupload") {
      $(".proceednowbtn").attr("disabled", true);
      let fileTobeUploaded = this.state.HUFadditionalFiles[FileIndex];
      AdditionaldocumentName1 = Object.keys(fileTobeUploaded)[0];
      mainaction = "reupload";
    } else {
      AdditionaldocumentName1 = this.state.documentName;
      mainaction = "upload";
    }
    if (AdditionaldocumentName1.trim() == "") {
      $(".HUFAddModal").modal("hide");
      this.showNotification("Enter document Name first");
    } else if (this.state.comment.length <= 250) {
      $(".cancelbtn_7").attr("disabled", true);
      $(".upload_btn20_7").hide();
      $(".upload_btnloader_7").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", AdditionaldocumentName1);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            $(".HUFAddModal").modal("hide");
            $(".cancelbtn_7").attr("disabled", false);
            $(`#${FileIndex}HUFFileseecommentsbtn`).attr("disabled", false);
            if (HUFadditionalReupload == false) {
              HUFadditionalFilesNames.push(this.state.HUFAdd_DocName);
              this.state.HUFadditionalFiles.pop();
              this.setState(
                {
                  comment: "",
                  HUFadditionalFiles: [
                    ...this.state.HUFadditionalFiles,
                    res.data.data,
                  ],
                },
                () => {
                  this.setState(
                    {
                      api_HUFadditionalFiles: "Yes",
                    },
                    () => {
                      this.HUFadditionalFilesArea();
                      this.checkDropdownValue();
                      this.checkhandleHUFProceedBtn();
                    }
                  );

                  $(".HUFadditionalInput").attr("disabled", true);
                }
              );
            } else if (HUFadditionalReupload == true) {
              $(".proceednowbtn").attr("disabled", false);
              var array = [...this.state.HUFadditionalFiles];
              array.splice(FileIndex, 1, res.data.data);
              this.setState(
                {
                  comment: "",
                  api_HUFadditionalFiles: "Yes",
                  HUFadditionalFiles: array,
                },
                () => {
                  this.HUFadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleHUFProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".HUFAddModal").modal("hide");
            this.showNotification("Something Went Wrong!");
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".HUFAddModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          this.showNotification("Something went wrong, Try again!");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeHUFFile = (index) => {
    $("#X-btn_7").attr("disabled", true);
    let fileTobeDeleted = this.state.HUFadditionalFiles[index];
    let NameOfAddFile = Object.keys(fileTobeDeleted)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "additional",
          documentName: NameOfAddFile,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          var array = [...this.state.HUFadditionalFiles];
          if (res.data.data.status == "reupload") {
            array[index].status = "reupload";
            this.setState(
              {
                HUFadditionalFiles: array,
              },
              () => {
                this.HUFadditionalFilesArea();
                this.checkDropdownValue();
                this.checkhandleHUFProceedBtn();
              }
            );
            this.showNotification(res.data.message);
          } else {
            array.splice(index, 1);
            if (array.length > 0) {
              this.setState(
                {
                  HUFadditionalFiles: array,
                },
                () => {
                  this.HUFadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleHUFProceedBtn();
                }
              );
            } else if (array.length == 0) {
              this.setState(
                {
                  HUFadditionalFiles: [],
                  HUFAdd_DocName: "",
                  api_HUFadditionalFiles: "No",
                  IsHUFAddFileDisabled: true,
                },
                () => {
                  this.HUFadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleHUFProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_7").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleHUFFileseeComments = (index) => {
    $(".commentsbtn").attr("disabled", false);
    $(`#${index}HUFFileseecommentsbtn`).attr("disabled", true);
    let SelectedFile = this.state.HUFadditionalFiles[index];
    let NameOfAddFile = Object.keys(SelectedFile)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "additional",
          documentName: NameOfAddFile,
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(`#${index}HUFFileseecommentsbtn`).hide();
          $(".hidecommentsbtn").hide();
          $(`#${index}HUFFilehidecommentsbtn`).show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFFilehideComments = (index) => {
    $(".seecommentsbtn").show();
    $(`#${index}HUFFileseecommentsbtn`).show();
    $(`#${index}HUFFileseecommentsbtn`).attr("disabled", false);
    $(`#${index}HUFFilehidecommentsbtn`).hide();
    $(".hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  checkAddCommentHUFFiles = (FileIndex, isDisabled) => {
    if (isDisabled == true) {
      return (
        <>
          <button
            className="seecommentsbtn HUFFileseecommentsbtn"
            id={`${FileIndex}HUFFileseecommentsbtn`}
            disabled
            onClick={() => this.handleHUFFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn HUFFilehidecommentsbtn"
            id={`${FileIndex}HUFFilehidecommentsbtn`}
            onClick={() => this.handleHUFFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    } else if (isDisabled == false) {
      return (
        <>
          <button
            className="seecommentsbtn commentsbtn HUFFileseecommentsbtn"
            id={`${FileIndex}HUFFileseecommentsbtn`}
            onClick={() => this.handleHUFFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn HUFFilehidecommentsbtn"
            id={`${FileIndex}HUFFilehidecommentsbtn`}
            onClick={() => this.handleHUFFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    }
  };

  checkStatusHUFAddFiles = (status, link, index) => {
    HUFadditionalFilesIndex = index;
    if (HideProceedBtn == "Yes") {
      $(".proceednowbtn").hide();
      if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
          </>
        );
      }
    } else {
      if (status == undefined) {
        status = "open";
      }
      if (status == "open") {
        mainaction = "upload";
        HUFadditionalReupload = true;
        $(".proceednowbtn").show();
        return (
          <>
            <label
              htmlFor={`ul_file_HUFAdd${index}`}
              className="choose_btn choose_7"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              multiple
              name="file[]"
              className="upload-photo"
              id={`ul_file_HUFAdd${index}`}
              // disabled={this.state.IsHUFAddFileDisabled}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handleHUFAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "remove") {
        mainaction = "upload";
        $(".proceednowbtn").show();
        return (
          <>
            <button
              className="X-btn X-btn_7"
              onClick={() => this.removeHUFFile(index)}
            >
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_1025.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "reupload") {
        HUFadditionalReupload = true;
        mainaction = "reupload";
        $(".proceednowbtn").show();
        $(".proceednowbtn").attr("disabled", true);
        return (
          <>
            <label
              htmlFor={`ul_file_HUFAdd${index}`}
              className="choose_btn choose_7"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              multiple
              name="file[]"
              className="upload-photo"
              id={`ul_file_HUFAdd${index}`}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handleHUFAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
          </>
        );
      }
    }
  };

  AdditionalHUFFileDisabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero HUFadditionalInput"
        value={NameOfAddFile}
        disabled
        onChange={(e) => this.handleHUFAdd_DocNameChange(e)}
      />
    );
  };

  AdditionalHUFFileEnabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero HUFadditionalInput"
        value={NameOfAddFile}
        onChange={(e) => this.handleHUFAdd_DocNameChange(e)}
      />
    );
  };

  HUFadditionalFilesArea = () => {
    HUFadditionalFilesNames = [];
    if (this.state.api_HUFadditionalFiles == "Yes") {
      return this.state.HUFadditionalFiles.map((file, index) => {
        let NameOfAddFile = Object.keys(file)[0];
        HUFadditionalFilesNames.push(NameOfAddFile);
        let link = file[Object.keys(file)[0]];
        let AdditionalFileInputField;
        let commentDisabled;
        if (file.status == "open" || file.status == undefined) {
          AdditionalFileInputField =
            this.AdditionalHUFFileEnabledField(NameOfAddFile);
          commentDisabled = true;
        } else if (file.status != "open") {
          AdditionalFileInputField =
            this.AdditionalHUFFileDisabledField(NameOfAddFile);
          commentDisabled = false;
        }
        return (
          <div className="displayflex" key={index}>
            <div className="uploadnocol">
              <p className="uploadadditionalsidetext">{index + 1}&#41;</p>
            </div>
            <div className="uploadadditionaldatacol">
              <div className="row mb-2" key={index}>
                <div className="col-md-5">{AdditionalFileInputField}</div>
                <div className="col-md-7">
                  <div className="float-md-right float-left">
                    {this.checkAddCommentHUFFiles(index, commentDisabled)}
                    {this.checkStatusHUFAddFiles(file.status, link, index)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.api_HUFadditionalFiles == "No") {
      return (
        <div className="displayflex">
          <div className="uploadnocol">
            <p className="uploadadditionalsidetext">1&#41;</p>
          </div>
          <div className="uploadadditionaldatacol">
            <div className="row mb-2">
              <div className="col-md-5">
                <input
                  type="text"
                  placeholder="Document name..."
                  maxLength="150"
                  className="uploadtextfield marginzero"
                  value={this.state.HUFAdd_DocName}
                  onChange={(e) => this.handleHUFAdd_DocNameChange(e)}
                />
              </div>
              <div className="col-md-7">
                <div className="fl_right">
                  {this.checkAddCommentHUFFiles(0, true)}
                  {this.checkStatusHUFAddFiles("open", null, 0)}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  handleHUFAdd_DocNameChange = (e) => {
    this.setState(
      {
        HUFAdd_DocName: e.target.value,
      },
      () => {
        if (this.state.HUFAdd_DocName.trim().length > 0) {
          this.setState({
            IsHUFAddFileDisabled: false,
          });
        } else {
          this.setState({
            IsHUFAddFileDisabled: true,
          });
        }
      }
    );
  };

  handleAddHUFFileBtn = (FileIndex) => {
    const { HUFAdd_DocName, HUFadditionalFiles } = this.state;

    let prevFile = HUFadditionalFiles[FileIndex];
    let NameOfPrevFile = Object.keys(prevFile)[0];
    let valueOffile = prevFile[Object.keys(prevFile)[0]];

    if (FileIndex == undefined) {
      this.showNotification("empty fields");
    } else if (HUFAdd_DocName.trim() == "") {
      this.showNotification("Required fields cannot be empty!");
    } else if (valueOffile == null) {
      this.showNotification("Choose a file");
    } else {
      this.setState(
        {
          HUFadditionalFiles: [...this.state.HUFadditionalFiles, ""],
          HUFAdd_DocName: "",
          IsHUFAddFileDisabled: true,
        },
        () => {
          $(".HUFadditionalInput").attr("disabled", false);
        }
      );
    }
  };

  // Proceed Checks
  HUFcheckRemainingReuploads = () => {
    if (
      HUFAadharStatus != "reupload" &&
      HUFPan1Status != "reupload" &&
      HUFPicStatus != "reupload" &&
      HUFPan2Status != "reupload" &&
      HUFDeedStatus != "reupload" &&
      HUFBPStatus != "reupload" &&
      HUFGumastaStatus != "reupload" &&
      HUFTradeLicenceStatus != "reupload" &&
      HUFCopyOfRegistryStatus != "reupload" &&
      HUFRentAgreementStatus != "reupload" &&
      HUFPRStatus != "reupload"
    ) {
      AnyReuploadOccuredHUF = "No";
    } else {
      $(".proceednowbtn4").show();
      AnyReuploadOccuredHUF = "Yes";
    }
  };

  checkhandleHUFProceedBtn = () => {
    let statusCheckReupload = this.statusCheckFunction(
      "reupload",
      HUFStatusArray
    );
    let HUFadditionalStatusArrayCheck = this.statusCheckFunction(
      "reupload",
      this.state.HUFadditionalFiles
    );
    let goodtogo = "No";
    if (this.state.HUFAddressProofType_radio == "CopyOfRegistry") {
      if (
        HUFOptStatusArray[0].status != "reupload" &&
        HUFOptStatusArray[0].status != "upload" &&
        HUFOptStatusArray[0].status != "open"
      ) {
        goodtogo = "Yes";
      } else {
        goodtogo = "No";
      }
    } else if (this.state.HUFAddressProofType_radio == "RentAgreement") {
      if (
        HUFOptStatusArray[1].status != "reupload" &&
        HUFOptStatusArray[1].status != "upload" &&
        HUFOptStatusArray[1].status != "open"
      ) {
        goodtogo = "Yes";
      } else {
        goodtogo = "No";
      }
    } else {
      goodtogo = "No";
    }

    if (
      AnyReuploadOccuredHUF == "Yes" &&
      statusCheckReupload == undefined &&
      goodtogo == "Yes" &&
      HUFadditionalStatusArrayCheck == undefined
    ) {
      $(".proceednowbtn4").attr("disabled", false);
      HUFproceednowforword = "success";
    } else if (
      HUFAadharStatus == "remove" &&
      HUFPan1Status == "remove" &&
      HUFPicStatus == "remove" &&
      HUFPan2Status == "remove" &&
      HUFDeedStatus == "remove" &&
      HUFBPStatus == "remove" &&
      HUFGumastaStatus == "remove" &&
      HUFTradeLicenceStatus == "remove" &&
      HUFPRStatus == "remove" &&
      goodtogo == "Yes"
    ) {
      $(".proceednowbtn4").attr("disabled", false);
      HUFproceednowforword = "success";
    } else {
      $(".proceednowbtn4").attr("disabled", true);
      HUFproceednowforword = "fail";
    }
  };

  handleHUFProceedBtn = () => {
    this.checkhandleHUFProceedBtn();
    if (this.state.HUFbusinessNature.trim() == "") {
      this.showNotification("Required fields can not be empty!");
    } else if (HUFproceednowforword == "success") {
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=lockService",
          {
            userId: Decuserid,
            requestType: temp,
            agentId: Decagentid,

            serviceId: Decserviceid,
            serviceName: Decservicename,
            serviceType: this.state.serviceType,
            FY: "",
            Month: "",
            inputFields: [
              {
                inputFieldName: "AddressProofType",
                inputFieldValue: this.state.HUFAddressProofType_radio,
                inputFieldtype: "general",
              },
              {
                inputFieldName: "Nature",
                inputFieldValue: this.state.HUFbusinessNature,
                inputFieldtype: "general",
              },
            ],
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.showNotification(res.data.message);
            this.getserviceInformationApi();
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else {
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {});
    }
  };

  // ST

  // STAadhar1
  handleSTAadhar1Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "AadhaarCardApplicant1",
          },
          () => {
            $("#STAadhar1Modal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "AadhaarCardApplicant1",
          });
        }
      });
      $("#STAadhar1Modal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTAadhar1 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STAadhar1").attr("disabled", true);
      $("#upload_btn20_STAadhar1").hide();
      $("#upload_btnloader_STAadhar1").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STAadhar1DocLink: res.data.data.AadhaarCardApplicant1,
                },
                () => $("#viewDocLink_STAadhar1").css("display", "block")
              );
              STAadhar1Status = "remove";
              STStatusArray[0].status = STAadhar1Status;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STAadhar1Modal").modal("hide");
              $("#cancelbtn_STAadhar1").attr("disabled", false);
              $("#upload_btn20_STAadhar1").show();
              $("#upload_btnloader_STAadhar1").hide();
              $("#choose_STAadhar1").hide();
              $("#X-btn_STAadhar1").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STAadhar1").attr("disabled", false);
            $("#upload_btn20_STAadhar1").show();
            $("#upload_btnloader_STAadhar1").hide();
            $("#STAadhar1Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STAadhar1").attr("disabled", false);
            $("#upload_btn20_STAadhar1").show();
            $("#upload_btnloader_STAadhar1").hide();
            $("#STAadhar1Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STAadhar1").attr("disabled", false);
          $("#upload_btn20_STAadhar1").show();
          $("#upload_btnloader_STAadhar1").hide();
          $("#STAadhar1Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTAadhar1 = () => {
    $("#X-btn_STAadhar1").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "AadhaarCardApplicant1",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STAadhar1Status = res.data.data.status;
          STStatusArray[0].status = STAadhar1Status;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STAadhar1").show();
          $("#X-btn_STAadhar1").hide();
          $("#X-btn_STAadhar1").attr("disabled", false);
          $("#viewDocLink_STAadhar1").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STAadhar1").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STAadhar1").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTAadhar1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STAadhar1seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "AadhaarCardApplicant1",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STAadhar1seecommentsbtn").hide();
          $("#STAadhar1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTAadhar1hidecommentsbtn = () => {
    $("#STAadhar1seecommentsbtn").show();
    $("#STAadhar1seecommentsbtn").attr("disabled", false);
    $("#STAadhar1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STAadhar2
  handleSTAadhar2Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "AadhaarCardApplicant2",
          },
          () => {
            $("#STAadhar2Modal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "AadhaarCardApplicant2",
          });
        }
      });
      $("#STAadhar2Modal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTAadhar2 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STAadhar2").attr("disabled", true);
      $("#upload_btn20_STAadhar2").hide();
      $("#upload_btnloader_STAadhar2").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STAadhar2DocLink: res.data.data.AadhaarCardApplicant2,
                },
                () => $("#viewDocLink_STAadhar2").css("display", "block")
              );
              STAadhar2Status = "remove";
              STStatusArray[1].status = STAadhar2Status;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STAadhar2Modal").modal("hide");
              $("#cancelbtn_STAadhar2").attr("disabled", false);
              $("#upload_btn20_STAadhar2").show();
              $("#upload_btnloader_STAadhar2").hide();
              $("#choose_STAadhar2").hide();
              $("#X-btn_STAadhar2").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STAadhar2").attr("disabled", false);
            $("#upload_btn20_STAadhar2").show();
            $("#upload_btnloader_STAadhar2").hide();
            $("#STAadhar2Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STAadhar2").attr("disabled", false);
            $("#upload_btn20_STAadhar2").show();
            $("#upload_btnloader_STAadhar2").hide();
            $("#STAadhar2Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STAadhar2").attr("disabled", false);
          $("#upload_btn20_STAadhar2").show();
          $("#upload_btnloader_STAadhar2").hide();
          $("#STAadhar2Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTAadhar2 = () => {
    $("#X-btn_STAadhar2").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "AadhaarCardApplicant2",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STAadhar2Status = res.data.data.status;
          STStatusArray[1].status = STAadhar2Status;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STAadhar2").show();
          $("#X-btn_STAadhar2").hide();
          $("#X-btn_STAadhar2").attr("disabled", false);
          $("#viewDocLink_STAadhar2").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STAadhar2").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STAadhar2").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTAadhar2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STAadhar2seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "AadhaarCardApplicant2",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STAadhar2seecommentsbtn").hide();
          $("#STAadhar2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTAadhar2hidecommentsbtn = () => {
    $("#STAadhar2seecommentsbtn").show();
    $("#STAadhar2seecommentsbtn").attr("disabled", false);
    $("#STAadhar2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STPan1
  handleSTPan1Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "PANCardApplicant1",
          },
          () => {
            $("#STPan1Modal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "PANCardApplicant1",
          });
        }
      });
      $("#STPan1Modal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTPan1 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STPan1").attr("disabled", true);
      $("#upload_btn20_STPan1").hide();
      $("#upload_btnloader_STPan1").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STPan1DocLink: res.data.data.PANCardApplicant1,
                },
                () => $("#viewDocLink_STPan1").css("display", "block")
              );
              STPan1Status = "remove";
              STStatusArray[2].status = STPan1Status;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STPan1Modal").modal("hide");
              $("#cancelbtn_STPan1").attr("disabled", false);
              $("#upload_btn20_STPan1").show();
              $("#upload_btnloader_STPan1").hide();
              $("#choose_STPan1").hide();
              $("#X-btn_STPan1").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STPan1").attr("disabled", false);
            $("#upload_btn20_STPan1").show();
            $("#upload_btnloader_STPan1").hide();
            $("#STPan1Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STPan1").attr("disabled", false);
            $("#upload_btn20_STPan1").show();
            $("#upload_btnloader_STPan1").hide();
            $("#STPan1Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STPan1").attr("disabled", false);
          $("#upload_btn20_STPan1").show();
          $("#upload_btnloader_STPan1").hide();
          $("#STPan1Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTPan1 = () => {
    $("#X-btn_STPan1").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "PANCardApplicant1",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STPan1Status = res.data.data.status;
          STStatusArray[2].status = STPan1Status;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STPan1").show();
          $("#X-btn_STPan1").hide();
          $("#X-btn_STPan1").attr("disabled", false);
          $("#viewDocLink_STPan1").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STPan1").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STPan1").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTPan1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STPan1seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PANCardApplicant1",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STPan1seecommentsbtn").hide();
          $("#STPan1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTPan1hidecommentsbtn = () => {
    $("#STPan1seecommentsbtn").show();
    $("#STPan1seecommentsbtn").attr("disabled", false);
    $("#STPan1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STPan2
  handleSTPan2Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "PANCardApplicant2",
          },
          () => {
            $("#STPan2Modal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "PANCardApplicant2",
          });
        }
      });
      $("#STPan2Modal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTPan2 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STPan2").attr("disabled", true);
      $("#upload_btn20_STPan2").hide();
      $("#upload_btnloader_STPan2").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STPan2DocLink: res.data.data.PANCardApplicant2,
                },
                () => $("#viewDocLink_STPan2").css("display", "block")
              );
              STPan2Status = "remove";
              STStatusArray[3].status = STPan2Status;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STPan2Modal").modal("hide");
              $("#cancelbtn_STPan2").attr("disabled", false);
              $("#upload_btn20_STPan2").show();
              $("#upload_btnloader_STPan2").hide();
              $("#choose_STPan2").hide();
              $("#X-btn_STPan2").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STPan2").attr("disabled", false);
            $("#upload_btn20_STPan2").show();
            $("#upload_btnloader_STPan2").hide();
            $("#STPan2Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STPan2").attr("disabled", false);
            $("#upload_btn20_STPan2").show();
            $("#upload_btnloader_STPan2").hide();
            $("#STPan2Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STPan2").attr("disabled", false);
          $("#upload_btn20_STPan2").show();
          $("#upload_btnloader_STPan2").hide();
          $("#STPan2Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTPan2 = () => {
    $("#X-btn_STPan2").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "PANCardApplicant2",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STPan2Status = res.data.data.status;
          STStatusArray[3].status = STPan2Status;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STPan2").show();
          $("#X-btn_STPan2").hide();
          $("#X-btn_STPan2").attr("disabled", false);
          $("#viewDocLink_STPan2").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STPan2").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STPan2").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTPan2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STPan2seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PANCardApplicant2",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STPan2seecommentsbtn").hide();
          $("#STPan2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTPan2hidecommentsbtn = () => {
    $("#STPan2seecommentsbtn").show();
    $("#STPan2seecommentsbtn").attr("disabled", false);
    $("#STPan2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STPic1
  handleSTPic1Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "ApplicantPhoto1",
          },
          () => {
            $("#STPic1Modal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "ApplicantPhoto1",
          });
        }
      });
      $("#STPic1Modal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTPic1 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STPic1").attr("disabled", true);
      $("#upload_btn20_STPic1").hide();
      $("#upload_btnloader_STPic1").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STPic1DocLink: res.data.data.ApplicantPhoto1,
                },
                () => $("#viewDocLink_STPic1").css("display", "block")
              );
              STPic1Status = "remove";
              STStatusArray[4].status = STPic1Status;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STPic1Modal").modal("hide");
              $("#cancelbtn_STPic1").attr("disabled", false);
              $("#upload_btn20_STPic1").show();
              $("#upload_btnloader_STPic1").hide();
              $("#choose_STPic1").hide();
              $("#X-btn_STPic1").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STPic1").attr("disabled", false);
            $("#upload_btn20_STPic1").show();
            $("#upload_btnloader_STPic1").hide();
            $("#STPic1Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STPic1").attr("disabled", false);
            $("#upload_btn20_STPic1").show();
            $("#upload_btnloader_STPic1").hide();
            $("#STPic1Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STPic1").attr("disabled", false);
          $("#upload_btn20_STPic1").show();
          $("#upload_btnloader_STPic1").hide();
          $("#STPic1Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTPic1 = () => {
    $("#X-btn_STPic1").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "ApplicantPhoto1",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STPic1Status = res.data.data.status;
          STStatusArray[4].status = STPic1Status;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STPic1").show();
          $("#X-btn_STPic1").hide();
          $("#X-btn_STPic1").attr("disabled", false);
          $("#viewDocLink_STPic1").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STPic1").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STPic1").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTPic1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STPic1seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ApplicantPhoto1",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STPic1seecommentsbtn").hide();
          $("#STPic1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTPic1hidecommentsbtn = () => {
    $("#STPic1seecommentsbtn").show();
    $("#STPic1seecommentsbtn").attr("disabled", false);
    $("#STPic1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STPic2
  handleSTPic2Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "ApplicantPhoto2",
          },
          () => {
            $("#STPic2Modal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "ApplicantPhoto2",
          });
        }
      });
      $("#STPic2Modal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTPic2 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STPic2").attr("disabled", true);
      $("#upload_btn20_STPic2").hide();
      $("#upload_btnloader_STPic2").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STPic2DocLink: res.data.data.ApplicantPhoto2,
                },
                () => $("#viewDocLink_STPic2").css("display", "block")
              );
              STPic2Status = "remove";
              STStatusArray[5].status = STPic2Status;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STPic2Modal").modal("hide");
              $("#cancelbtn_STPic2").attr("disabled", false);
              $("#upload_btn20_STPic2").show();
              $("#upload_btnloader_STPic2").hide();
              $("#choose_STPic2").hide();
              $("#X-btn_STPic2").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STPic2").attr("disabled", false);
            $("#upload_btn20_STPic2").show();
            $("#upload_btnloader_STPic2").hide();
            $("#STPic2Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STPic2").attr("disabled", false);
            $("#upload_btn20_STPic2").show();
            $("#upload_btnloader_STPic2").hide();
            $("#STPic2Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STPic2").attr("disabled", false);
          $("#upload_btn20_STPic2").show();
          $("#upload_btnloader_STPic2").hide();
          $("#STPic2Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTPic2 = () => {
    $("#X-btn_STPic2").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "ApplicantPhoto2",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STPic2Status = res.data.data.status;
          STStatusArray[5].status = STPic2Status;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STPic2").show();
          $("#X-btn_STPic2").hide();
          $("#X-btn_STPic2").attr("disabled", false);
          $("#viewDocLink_STPic2").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STPic2").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STPic2").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTPic2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STPic2seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ApplicantPhoto2",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STPic2seecommentsbtn").hide();
          $("#STPic2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTPic2hidecommentsbtn = () => {
    $("#STPic2seecommentsbtn").show();
    $("#STPic2seecommentsbtn").attr("disabled", false);
    $("#STPic2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STPan3
  handleSTPan3Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "PANCardSTC",
          },
          () => {
            $("#STPan3Modal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "PANCardSTC",
          });
        }
      });
      $("#STPan3Modal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTPan3 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STPan3").attr("disabled", true);
      $("#upload_btn20_STPan3").hide();
      $("#upload_btnloader_STPan3").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STPan3DocLink: res.data.data.PANCardSTC,
                },
                () => $("#viewDocLink_STPan3").css("display", "block")
              );
              STPan3Status = "remove";
              STStatusArray[6].status = STPan3Status;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STPan3Modal").modal("hide");
              $("#cancelbtn_STPan3").attr("disabled", false);
              $("#upload_btn20_STPan3").show();
              $("#upload_btnloader_STPan3").hide();
              $("#choose_STPan3").hide();
              $("#X-btn_STPan3").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STPan3").attr("disabled", false);
            $("#upload_btn20_STPan3").show();
            $("#upload_btnloader_STPan3").hide();
            $("#STPan3Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STPan3").attr("disabled", false);
            $("#upload_btn20_STPan3").show();
            $("#upload_btnloader_STPan3").hide();
            $("#STPan3Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STPan3").attr("disabled", false);
          $("#upload_btn20_STPan3").show();
          $("#upload_btnloader_STPan3").hide();
          $("#STPan3Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTPan3 = () => {
    $("#X-btn_STPan3").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "PANCardSTC",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STPan3Status = res.data.data.status;
          STStatusArray[6].status = STPan3Status;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STPan3").show();
          $("#X-btn_STPan3").hide();
          $("#X-btn_STPan3").attr("disabled", false);
          $("#viewDocLink_STPan3").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STPan3").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STPan3").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTPan3seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STPan3seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PANCardSTC",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STPan3seecommentsbtn").hide();
          $("#STPan3hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTPan3hidecommentsbtn = () => {
    $("#STPan3seecommentsbtn").show();
    $("#STPan3seecommentsbtn").attr("disabled", false);
    $("#STPan3hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STCR
  handleSTCRChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "CertificateOfRegistration",
          },
          () => {
            $("#STCRModal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "CertificateOfRegistration",
          });
        }
      });
      $("#STCRModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTCR = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STCR").attr("disabled", true);
      $("#upload_btn20_STCR").hide();
      $("#upload_btnloader_STCR").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STCRDocLink: res.data.data.CertificateOfRegistration,
                },
                () => $("#viewDocLink_STCR").css("display", "block")
              );
              STCRStatus = "remove";
              STStatusArray[7].status = STCRStatus;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STCRModal").modal("hide");
              $("#cancelbtn_STCR").attr("disabled", false);
              $("#upload_btn20_STCR").show();
              $("#upload_btnloader_STCR").hide();
              $("#choose_STCR").hide();
              $("#X-btn_STCR").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STCR").attr("disabled", false);
            $("#upload_btn20_STCR").show();
            $("#upload_btnloader_STCR").hide();
            $("#STCRModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STCR").attr("disabled", false);
            $("#upload_btn20_STCR").show();
            $("#upload_btnloader_STCR").hide();
            $("#STCRModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STCR").attr("disabled", false);
          $("#upload_btn20_STCR").show();
          $("#upload_btnloader_STCR").hide();
          $("#STCRModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTCR = () => {
    $("#X-btn_STCR").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "CertificateOfRegistration",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STCRStatus = res.data.data.status;
          STStatusArray[7].status = STCRStatus;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STCR").show();
          $("#X-btn_STCR").hide();
          $("#X-btn_STCR").attr("disabled", false);
          $("#viewDocLink_STCR").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STCR").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STCR").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTCRseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STCRseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "CertificateOfRegistration",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STCRseecommentsbtn").hide();
          $("#STCRhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTCRhidecommentsbtn = () => {
    $("#STCRseecommentsbtn").show();
    $("#STCRseecommentsbtn").attr("disabled", false);
    $("#STCRhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STBP
  handleSTBPChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "BankProof",
          },
          () => {
            $("#STBPModal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "BankProof",
          });
        }
      });
      $("#STBPModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTBP = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STBP").attr("disabled", true);
      $("#upload_btn20_STBP").hide();
      $("#upload_btnloader_STBP").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STBPDocLink: res.data.data.BankProof,
                },
                () => $("#viewDocLink_STBP").css("display", "block")
              );
              STBPStatus = "remove";
              STStatusArray[8].status = STBPStatus;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STBPModal").modal("hide");
              $("#cancelbtn_STBP").attr("disabled", false);
              $("#upload_btn20_STBP").show();
              $("#upload_btnloader_STBP").hide();
              $("#choose_STBP").hide();
              $("#X-btn_STBP").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STBP").attr("disabled", false);
            $("#upload_btn20_STBP").show();
            $("#upload_btnloader_STBP").hide();
            $("#STBPModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STBP").attr("disabled", false);
            $("#upload_btn20_STBP").show();
            $("#upload_btnloader_STBP").hide();
            $("#STBPModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STBP").attr("disabled", false);
          $("#upload_btn20_STBP").show();
          $("#upload_btnloader_STBP").hide();
          $("#STBPModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTBP = () => {
    $("#X-btn_STBP").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "BankProof",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STBPStatus = res.data.data.status;
          STStatusArray[8].status = STBPStatus;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STBP").show();
          $("#X-btn_STBP").hide();
          $("#X-btn_STBP").attr("disabled", false);
          $("#viewDocLink_STBP").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STBP").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STBP").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTBPseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STBPseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "BankProof",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STBPseecommentsbtn").hide();
          $("#STBPhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTBPhidecommentsbtn = () => {
    $("#STBPseecommentsbtn").show();
    $("#STBPseecommentsbtn").attr("disabled", false);
    $("#STBPhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STBR
  handleSTBRChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "BoardResolution",
          },
          () => {
            $("#STBRModal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "BoardResolution",
          });
        }
      });
      $("#STBRModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTBR = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STBR").attr("disabled", true);
      $("#upload_btn20_STBR").hide();
      $("#upload_btnloader_STBR").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STBRDocLink: res.data.data.BoardResolution,
                },
                () => $("#viewDocLink_STBR").css("display", "block")
              );
              STBRStatus = "remove";
              STStatusArray[9].status = STBRStatus;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STBRModal").modal("hide");
              $("#cancelbtn_STBR").attr("disabled", false);
              $("#upload_btn20_STBR").show();
              $("#upload_btnloader_STBR").hide();
              $("#choose_STBR").hide();
              $("#X-btn_STBR").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STBR").attr("disabled", false);
            $("#upload_btn20_STBR").show();
            $("#upload_btnloader_STBR").hide();
            $("#STBRModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STBR").attr("disabled", false);
            $("#upload_btn20_STBR").show();
            $("#upload_btnloader_STBR").hide();
            $("#STBRModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STBR").attr("disabled", false);
          $("#upload_btn20_STBR").show();
          $("#upload_btnloader_STBR").hide();
          $("#STBRModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTBR = () => {
    $("#X-btn_STBR").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "BoardResolution",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STBRStatus = res.data.data.status;
          STStatusArray[9].status = STBRStatus;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STBR").show();
          $("#X-btn_STBR").hide();
          $("#X-btn_STBR").attr("disabled", false);
          $("#viewDocLink_STBR").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STBR").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STBR").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTBRseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STBRseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "BoardResolution",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STBRseecommentsbtn").hide();
          $("#STBRhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTBRhidecommentsbtn = () => {
    $("#STBRseecommentsbtn").show();
    $("#STBRseecommentsbtn").attr("disabled", false);
    $("#STBRhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STGumasta
  handleSTGumastaChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "Gumasta",
          },
          () => {
            $("#STGumastaModal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "Gumasta",
          });
        }
      });
      $("#STGumastaModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTGumasta = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STGumasta").attr("disabled", true);
      $("#upload_btn20_STGumasta").hide();
      $("#upload_btnloader_STGumasta").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STGumastaDocLink: res.data.data.Gumasta,
                },
                () => $("#viewDocLink_STGumasta").css("display", "block")
              );
              STGumastaStatus = "remove";
              STStatusArray[10].status = STGumastaStatus;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STGumastaModal").modal("hide");
              $("#cancelbtn_STGumasta").attr("disabled", false);
              $("#upload_btn20_STGumasta").show();
              $("#upload_btnloader_STGumasta").hide();
              $("#choose_STGumasta").hide();
              $("#X-btn_STGumasta").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STGumasta").attr("disabled", false);
            $("#upload_btn20_STGumasta").show();
            $("#upload_btnloader_STGumasta").hide();
            $("#STGumastaModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STGumasta").attr("disabled", false);
            $("#upload_btn20_STGumasta").show();
            $("#upload_btnloader_STGumasta").hide();
            $("#STGumastaModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STGumasta").attr("disabled", false);
          $("#upload_btn20_STGumasta").show();
          $("#upload_btnloader_STGumasta").hide();
          $("#STGumastaModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTGumasta = () => {
    $("#X-btn_STGumasta").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "Gumasta",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STGumastaStatus = res.data.data.status;
          STStatusArray[10].status = STGumastaStatus;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STGumasta").show();
          $("#X-btn_STGumasta").hide();
          $("#X-btn_STGumasta").attr("disabled", false);
          $("#viewDocLink_STGumasta").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STGumasta").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STGumasta").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTGumastaseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STGumastaseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "Gumasta",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STGumastaseecommentsbtn").hide();
          $("#STGumastahidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTGumastahidecommentsbtn = () => {
    $("#STGumastaseecommentsbtn").show();
    $("#STGumastaseecommentsbtn").attr("disabled", false);
    $("#STGumastahidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STTradeLicence
  handleSTTradeLicenceChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "TradeLicence",
          },
          () => {
            $("#STTradeLicenceModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "TradeLicence",
          });
        }
      });
      $("#STTradeLicenceModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTTradeLicence = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STTradeLicence").attr("disabled", true);
      $("#upload_btn20_STTradeLicence").hide();
      $("#upload_btnloader_STTradeLicence").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STTradeLicenceDocLink: res.data.data.TradeLicence,
                },
                () => $("#viewDocLink_STTradeLicence").css("display", "block")
              );
              STTradeLicenceStatus = "remove";
              STStatusArray[11].status = STTradeLicenceStatus;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STTradeLicenceModal").modal("hide");
              $("#cancelbtn_STTradeLicence").attr("disabled", false);
              $("#upload_btn20_STTradeLicence").show();
              $("#upload_btnloader_STTradeLicence").hide();
              $("#choose_STTradeLicence").hide();
              $("#X-btn_STTradeLicence").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STTradeLicence").attr("disabled", false);
            $("#upload_btn20_STTradeLicence").show();
            $("#upload_btnloader_STTradeLicence").hide();
            $("#STTradeLicenceModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STTradeLicence").attr("disabled", false);
            $("#upload_btn20_STTradeLicence").show();
            $("#upload_btnloader_STTradeLicence").hide();
            $("#STTradeLicenceModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STTradeLicence").attr("disabled", false);
          $("#upload_btn20_STTradeLicence").show();
          $("#upload_btnloader_STTradeLicence").hide();
          $("#STTradeLicenceModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTTradeLicence = () => {
    $("#X-btn_STTradeLicence").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "TradeLicence",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STTradeLicenceStatus = res.data.data.status;
          STStatusArray[11].status = STTradeLicenceStatus;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STTradeLicence").show();
          $("#X-btn_STTradeLicence").hide();
          $("#X-btn_STTradeLicence").attr("disabled", false);
          $("#viewDocLink_STTradeLicence").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STTradeLicence").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STTradeLicence").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTTradeLicenceseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STTradeLicenceseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "TradeLicence",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STTradeLicenceseecommentsbtn").hide();
          $("#STTradeLicencehidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTTradeLicencehidecommentsbtn = () => {
    $("#STTradeLicenceseecommentsbtn").show();
    $("#STTradeLicenceseecommentsbtn").attr("disabled", false);
    $("#STTradeLicencehidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STbusinessNature

  handleSTbusinessNatureChange = (e) => {
    this.setState({
      STbusinessNature: e.target.value,
    });
  };

  // STAddressProofType
  STAddressProofType_radioChange = (e) => {
    this.setState(
      {
        STAddressProofType_radio: e.currentTarget.value,
      },
      () => {
        if (this.state.STAddressProofType_radio != "CopyOfRegistry") {
          $(".RentAgreement").css("display", "flex");
          $(".CopyOfRegistry").hide();
        } else if (this.state.STAddressProofType_radio == "CopyOfRegistry") {
          $(".CopyOfRegistry").css("display", "flex");
          $(".RentAgreement").hide();
        }
        this.checkhandleSTProceedBtn();
      }
    );
  };

  // STCopyOfRegistry
  handleSTCopyOfRegistryChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "CopyOfRegistry",
          },
          () => {
            $("#STCopyOfRegistryModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "CopyOfRegistry",
          });
        }
      });
      $("#STCopyOfRegistryModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTCopyOfRegistry = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STCopyOfRegistry").attr("disabled", true);
      $("#upload_btn20_STCopyOfRegistry").hide();
      $("#upload_btnloader_STCopyOfRegistry").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STCopyOfRegistryDocLink: res.data.data.CopyOfRegistry,
                },
                () => $("#viewDocLink_STCopyOfRegistry").css("display", "block")
              );
              STCopyOfRegistryStatus = "remove";
              STOptStatusArray[0].status = STCopyOfRegistryStatus;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STCopyOfRegistryModal").modal("hide");
              $("#cancelbtn_STCopyOfRegistry").attr("disabled", false);
              $("#upload_btn20_STCopyOfRegistry").show();
              $("#upload_btnloader_STCopyOfRegistry").hide();
              $("#choose_STCopyOfRegistry").hide();
              $("#X-btn_STCopyOfRegistry").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STCopyOfRegistry").attr("disabled", false);
            $("#upload_btn20_STCopyOfRegistry").show();
            $("#upload_btnloader_STCopyOfRegistry").hide();
            $("#STCopyOfRegistryModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STCopyOfRegistry").attr("disabled", false);
            $("#upload_btn20_STCopyOfRegistry").show();
            $("#upload_btnloader_STCopyOfRegistry").hide();
            $("#STCopyOfRegistryModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STCopyOfRegistry").attr("disabled", false);
          $("#upload_btn20_STCopyOfRegistry").show();
          $("#upload_btnloader_STCopyOfRegistry").hide();
          $("#STCopyOfRegistryModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTCopyOfRegistry = () => {
    $("#X-btn_STCopyOfRegistry").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "CopyOfRegistry",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STCopyOfRegistryStatus = res.data.data.status;
          STOptStatusArray[0].status = STCopyOfRegistryStatus;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STCopyOfRegistry").show();
          $("#X-btn_STCopyOfRegistry").hide();
          $("#X-btn_STCopyOfRegistry").attr("disabled", false);
          $("#viewDocLink_STCopyOfRegistry").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STCopyOfRegistry").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STCopyOfRegistry").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTCopyOfRegistryseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STCopyOfRegistryseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "CopyOfRegistry",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STCopyOfRegistryseecommentsbtn").hide();
          $("#STCopyOfRegistryhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTCopyOfRegistryhidecommentsbtn = () => {
    $("#STCopyOfRegistryseecommentsbtn").show();
    $("#STCopyOfRegistryseecommentsbtn").attr("disabled", false);
    $("#STCopyOfRegistryhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STRentAgreement
  handleSTRentAgreementChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "RentAgreement",
          },
          () => {
            $("#STRentAgreementModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "RentAgreement",
          });
        }
      });
      $("#STRentAgreementModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTRentAgreement = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STRentAgreement").attr("disabled", true);
      $("#upload_btn20_STRentAgreement").hide();
      $("#upload_btnloader_STRentAgreement").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STRentAgreementDocLink: res.data.data.RentAgreement,
                },
                () => $("#viewDocLink_STRentAgreement").css("display", "block")
              );
              STRentAgreementStatus = "remove";
              STOptStatusArray[1].status = STRentAgreementStatus;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STRentAgreementModal").modal("hide");
              $("#cancelbtn_STRentAgreement").attr("disabled", false);
              $("#upload_btn20_STRentAgreement").show();
              $("#upload_btnloader_STRentAgreement").hide();
              $("#choose_STRentAgreement").hide();
              $("#X-btn_STRentAgreement").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STRentAgreement").attr("disabled", false);
            $("#upload_btn20_STRentAgreement").show();
            $("#upload_btnloader_STRentAgreement").hide();
            $("#STRentAgreementModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STRentAgreement").attr("disabled", false);
            $("#upload_btn20_STRentAgreement").show();
            $("#upload_btnloader_STRentAgreement").hide();
            $("#STRentAgreementModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STRentAgreement").attr("disabled", false);
          $("#upload_btn20_STRentAgreement").show();
          $("#upload_btnloader_STRentAgreement").hide();
          $("#STRentAgreementModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTRentAgreement = () => {
    $("#X-btn_STRentAgreement").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "RentAgreement",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STRentAgreementStatus = res.data.data.status;
          STOptStatusArray[1].status = STRentAgreementStatus;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STRentAgreement").show();
          $("#X-btn_STRentAgreement").hide();
          $("#X-btn_STRentAgreement").attr("disabled", false);
          $("#viewDocLink_STRentAgreement").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STRentAgreement").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STRentAgreement").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTRentAgreementseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STRentAgreementseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "RentAgreement",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STRentAgreementseecommentsbtn").hide();
          $("#STRentAgreementhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTRentAgreementhidecommentsbtn = () => {
    $("#STRentAgreementseecommentsbtn").show();
    $("#STRentAgreementseecommentsbtn").attr("disabled", false);
    $("#STRentAgreementhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STPR
  handleSTPRChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "PropertyTaxReceipt",
          },
          () => {
            $("#STPRModal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "PropertyTaxReceipt",
          });
        }
      });
      $("#STPRModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTPR = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STPR").attr("disabled", true);
      $("#upload_btn20_STPR").hide();
      $("#upload_btnloader_STPR").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STPRDocLink: res.data.data.PropertyTaxReceipt,
                },
                () => $("#viewDocLink_STPR").css("display", "block")
              );
              STPRStatus = "remove";
              STStatusArray[12].status = STPRStatus;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STPRModal").modal("hide");
              $("#cancelbtn_STPR").attr("disabled", false);
              $("#upload_btn20_STPR").show();
              $("#upload_btnloader_STPR").hide();
              $("#choose_STPR").hide();
              $("#X-btn_STPR").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STPR").attr("disabled", false);
            $("#upload_btn20_STPR").show();
            $("#upload_btnloader_STPR").hide();
            $("#STPRModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STPR").attr("disabled", false);
            $("#upload_btn20_STPR").show();
            $("#upload_btnloader_STPR").hide();
            $("#STPRModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STPR").attr("disabled", false);
          $("#upload_btn20_STPR").show();
          $("#upload_btnloader_STPR").hide();
          $("#STPRModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTPR = () => {
    $("#X-btn_STPR").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "PropertyTaxReceipt",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STPRStatus = res.data.data.status;
          STStatusArray[12].status = STPRStatus;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STPR").show();
          $("#X-btn_STPR").hide();
          $("#X-btn_STPR").attr("disabled", false);
          $("#viewDocLink_STPR").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STPR").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STPR").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTPRseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STPRseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PropertyTaxReceipt",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STPRseecommentsbtn").hide();
          $("#STPRhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTPRhidecommentsbtn = () => {
    $("#STPRseecommentsbtn").show();
    $("#STPRseecommentsbtn").attr("disabled", false);
    $("#STPRhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STAdditional
  handleSTAddFileChange = (e, index, status) => {
    STadditionalReuploadIndex = index;
    STadditionalReuploadStatus = status;
    $(".upload_btn20_7").show();
    $(".upload_btnloader_7").hide();
    const file = e.target.files[0];
    if (!STadditionalFilesNames.includes(this.state.STAdd_DocName.trim())) {
      const myfile = e.target.files;
      if (ChooseNowBtnsDisable == "Yes") {
        this.showNotification(
          "Files cannot be uploaded when the service is locked!"
        );
      } else if (myfile.length == 1) {
        const file = myfile[0];
        if (file.type.match("audio.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.type.match("video.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.size > 104857600) {
          this.showNotification("File too big!");
        } else if (file.name.length > 100) {
          this.showNotification("File name limit exceed!");
        } else {
          this.setState(
            {
              fileName: file.name,
              fileBase64: file,
              documentType: "additional",
              documentName: this.state.STAdd_DocName,
            },
            () => {
              $(".STAddModal").modal({ backdrop: "static", keyboard: false });
            }
          );
        }
      } else if (myfile.length > 1) {
        var zip = new JSZip();
        for (let i = 0; i < myfile.length; i++) {
          zip.file(myfile[i].name, myfile[i], { base64: true });
        }
        zip.generateAsync({ type: "blob" }).then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "additional",
              documentName: this.state.STAdd_DocName,
            });
          }
        });
        $(".STAddModal").modal({ backdrop: "static", keyboard: false });
      } else {
        this.showNotification("File cannot be null!");
      }
    } else {
      this.showNotification(
        "Document names can not be the same, Try again with different name!"
      );
    }
  };

  uploadSTFile = (FileIndex, FileStatus) => {
    let AdditionaldocumentName1;
    if (FileStatus == "reupload") {
      $(".proceednowbtn").attr("disabled", true);
      let fileTobeUploaded = this.state.STadditionalFiles[FileIndex];
      AdditionaldocumentName1 = Object.keys(fileTobeUploaded)[0];
      mainaction = "reupload";
    } else {
      AdditionaldocumentName1 = this.state.documentName;
      mainaction = "upload";
    }
    if (AdditionaldocumentName1.trim() == "") {
      $(".STAddModal").modal("hide");
      this.showNotification("Enter document Name first");
    } else if (this.state.comment.length <= 250) {
      $(".cancelbtn_7").attr("disabled", true);
      $(".upload_btn20_7").hide();
      $(".upload_btnloader_7").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", AdditionaldocumentName1);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            $(".STAddModal").modal("hide");
            $(".cancelbtn_7").attr("disabled", false);
            $(`#${FileIndex}STFileseecommentsbtn`).attr("disabled", false);
            if (STadditionalReupload == false) {
              STadditionalFilesNames.push(this.state.STAdd_DocName);
              this.state.STadditionalFiles.pop();
              this.setState(
                {
                  comment: "",
                  STadditionalFiles: [
                    ...this.state.STadditionalFiles,
                    res.data.data,
                  ],
                },
                () => {
                  this.setState(
                    {
                      api_STadditionalFiles: "Yes",
                    },
                    () => {
                      this.STadditionalFilesArea();
                      this.checkDropdownValue();
                      this.checkhandleSTProceedBtn();
                    }
                  );

                  $(".STadditionalInput").attr("disabled", true);
                }
              );
            } else if (STadditionalReupload == true) {
              $(".proceednowbtn").attr("disabled", false);
              var array = [...this.state.STadditionalFiles];
              array.splice(FileIndex, 1, res.data.data);
              this.setState(
                {
                  comment: "",
                  api_STadditionalFiles: "Yes",
                  STadditionalFiles: array,
                },
                () => {
                  this.STadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleSTProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".STAddModal").modal("hide");
            this.showNotification("Something Went Wrong!");
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".STAddModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          this.showNotification("Something went wrong, Try again!");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTFile = (index) => {
    $("#X-btn_7").attr("disabled", true);
    let fileTobeDeleted = this.state.STadditionalFiles[index];
    let NameOfAddFile = Object.keys(fileTobeDeleted)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "additional",
          documentName: NameOfAddFile,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          var array = [...this.state.STadditionalFiles];
          if (res.data.data.status == "reupload") {
            array[index].status = "reupload";
            this.setState(
              {
                STadditionalFiles: array,
              },
              () => {
                this.STadditionalFilesArea();
                this.checkDropdownValue();
                this.checkhandleSTProceedBtn();
              }
            );
            this.showNotification(res.data.message);
          } else {
            array.splice(index, 1);
            if (array.length > 0) {
              this.setState(
                {
                  STadditionalFiles: array,
                },
                () => {
                  this.STadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleSTProceedBtn();
                }
              );
            } else if (array.length == 0) {
              this.setState(
                {
                  STadditionalFiles: [],
                  STAdd_DocName: "",
                  api_STadditionalFiles: "No",
                  IsSTAddFileDisabled: true,
                },
                () => {
                  this.STadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleSTProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_7").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTFileseeComments = (index) => {
    $(".commentsbtn").attr("disabled", false);
    $(`#${index}STFileseecommentsbtn`).attr("disabled", true);
    let SelectedFile = this.state.STadditionalFiles[index];
    let NameOfAddFile = Object.keys(SelectedFile)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "additional",
          documentName: NameOfAddFile,
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(`#${index}STFileseecommentsbtn`).hide();
          $(".hidecommentsbtn").hide();
          $(`#${index}STFilehidecommentsbtn`).show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTFilehideComments = (index) => {
    $(".seecommentsbtn").show();
    $(`#${index}STFileseecommentsbtn`).show();
    $(`#${index}STFileseecommentsbtn`).attr("disabled", false);
    $(`#${index}STFilehidecommentsbtn`).hide();
    $(".hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  checkAddCommentSTFiles = (FileIndex, isDisabled) => {
    if (isDisabled == true) {
      return (
        <>
          <button
            className="seecommentsbtn STFileseecommentsbtn"
            id={`${FileIndex}STFileseecommentsbtn`}
            disabled
            onClick={() => this.handleSTFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn STFilehidecommentsbtn"
            id={`${FileIndex}STFilehidecommentsbtn`}
            onClick={() => this.handleSTFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    } else if (isDisabled == false) {
      return (
        <>
          <button
            className="seecommentsbtn commentsbtn STFileseecommentsbtn"
            id={`${FileIndex}STFileseecommentsbtn`}
            onClick={() => this.handleSTFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn STFilehidecommentsbtn"
            id={`${FileIndex}STFilehidecommentsbtn`}
            onClick={() => this.handleSTFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    }
  };

  checkStatusSTAddFiles = (status, link, index) => {
    STadditionalFilesIndex = index;
    if (HideProceedBtn == "Yes") {
      $(".proceednowbtn").hide();
      if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
          </>
        );
      }
    } else {
      if (status == undefined) {
        status = "open";
      }
      if (status == "open") {
        mainaction = "upload";
        STadditionalReupload = true;
        $(".proceednowbtn").show();
        return (
          <>
            <label
              htmlFor={`ul_file_STAdd${index}`}
              className="choose_btn choose_7"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              multiple
              name="file[]"
              className="upload-photo"
              id={`ul_file_STAdd${index}`}
              // disabled={this.state.IsSTAddFileDisabled}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handleSTAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "remove") {
        mainaction = "upload";
        $(".proceednowbtn").show();
        return (
          <>
            <button
              className="X-btn X-btn_7"
              onClick={() => this.removeSTFile(index)}
            >
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_1025.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "reupload") {
        STadditionalReupload = true;
        mainaction = "reupload";
        $(".proceednowbtn").show();
        $(".proceednowbtn").attr("disabled", true);
        return (
          <>
            <label
              htmlFor={`ul_file_STAdd${index}`}
              className="choose_btn choose_7"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              multiple
              name="file[]"
              className="upload-photo"
              id={`ul_file_STAdd${index}`}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handleSTAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
          </>
        );
      }
    }
  };

  AdditionalSTFileDisabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero STadditionalInput"
        value={NameOfAddFile}
        disabled
        onChange={(e) => this.handleSTAdd_DocNameChange(e)}
      />
    );
  };

  AdditionalSTFileEnabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero STadditionalInput"
        value={NameOfAddFile}
        onChange={(e) => this.handleSTAdd_DocNameChange(e)}
      />
    );
  };

  STadditionalFilesArea = () => {
    STadditionalFilesNames = [];
    if (this.state.api_STadditionalFiles == "Yes") {
      return this.state.STadditionalFiles.map((file, index) => {
        let NameOfAddFile = Object.keys(file)[0];
        STadditionalFilesNames.push(NameOfAddFile);
        let link = file[Object.keys(file)[0]];
        let AdditionalFileInputField;
        let commentDisabled;
        if (file.status == "open" || file.status == undefined) {
          AdditionalFileInputField =
            this.AdditionalSTFileEnabledField(NameOfAddFile);
          commentDisabled = true;
        } else if (file.status != "open") {
          AdditionalFileInputField =
            this.AdditionalSTFileDisabledField(NameOfAddFile);
          commentDisabled = false;
        }
        return (
          <div className="displayflex" key={index}>
            <div className="uploadnocol">
              <p className="uploadadditionalsidetext">{index + 1}&#41;</p>
            </div>
            <div className="uploadadditionaldatacol">
              <div className="row mb-2">
                <div className="col-md-5">{AdditionalFileInputField}</div>
                <div className="col-md-7">
                  <div className="float-md-right float-left">
                    {this.checkAddCommentSTFiles(index, commentDisabled)}
                    {this.checkStatusSTAddFiles(file.status, link, index)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.api_STadditionalFiles == "No") {
      return (
        <div className="displayflex">
          <div className="uploadnocol">
            <p className="uploadadditionalsidetext">1&#41;</p>
          </div>
          <div className="uploadadditionaldatacol">
            <div className="row mb-2">
              <div className="col-md-5">
                <input
                  type="text"
                  placeholder="Document name..."
                  maxLength="150"
                  className="uploadtextfield marginzero"
                  value={this.state.STAdd_DocName}
                  onChange={(e) => this.handleSTAdd_DocNameChange(e)}
                />
              </div>
              <div className="col-md-7">
                <div className="fl_right">
                  {this.checkAddCommentSTFiles(0, true)}
                  {this.checkStatusSTAddFiles("open", null, 0)}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  handleSTAdd_DocNameChange = (e) => {
    this.setState(
      {
        STAdd_DocName: e.target.value,
      },
      () => {
        if (this.state.STAdd_DocName.trim().length > 0) {
          this.setState({
            IsSTAddFileDisabled: false,
          });
        } else {
          this.setState({
            IsSTAddFileDisabled: true,
          });
        }
      }
    );
  };

  handleAddSTFileBtn = (FileIndex) => {
    const { STAdd_DocName, STadditionalFiles } = this.state;

    let prevFile = STadditionalFiles[FileIndex];
    let NameOfPrevFile = Object.keys(prevFile)[0];
    let valueOffile = prevFile[Object.keys(prevFile)[0]];

    if (FileIndex == undefined) {
      this.showNotification("empty fields");
    } else if (STAdd_DocName.trim() == "") {
      this.showNotification("Required fields cannot be empty!");
    } else if (valueOffile == null) {
      this.showNotification("Choose a file");
    } else {
      this.setState(
        {
          STadditionalFiles: [...this.state.STadditionalFiles, ""],
          STAdd_DocName: "",
          IsSTAddFileDisabled: true,
        },
        () => {
          $(".STadditionalInput").attr("disabled", false);
        }
      );
    }
  };

  // Proceed Checks
  STcheckRemainingReuploads = () => {
    if (
      STAadhar1Status != "reupload" &&
      STAadhar2Status != "reupload" &&
      STPan1Status != "reupload" &&
      STPan2Status != "reupload" &&
      STPic1Status != "reupload" &&
      STPic2Status != "reupload" &&
      STPan3Status != "reupload" &&
      STCRStatus != "reupload" &&
      STBPStatus != "reupload" &&
      STBRStatus != "reupload" &&
      STGumastaStatus != "reupload" &&
      STTradeLicenceStatus != "reupload" &&
      STCopyOfRegistryStatus != "reupload" &&
      STRentAgreementStatus != "reupload" &&
      STPRStatus != "reupload"
    ) {
      AnyReuploadOccuredST = "No";
    } else {
      $(".proceednowbtn5").show();
      AnyReuploadOccuredST = "Yes";
    }
  };

  checkhandleSTProceedBtn = () => {
    let statusCheckReupload = this.statusCheckFunction(
      "reupload",
      STStatusArray
    );
    let STadditionalStatusArrayCheck = this.statusCheckFunction(
      "reupload",
      this.state.STadditionalFiles
    );
    let goodtogo = "No";
    if (this.state.STAddressProofType_radio == "CopyOfRegistry") {
      if (
        STOptStatusArray[0].status != "reupload" &&
        STOptStatusArray[0].status != "upload" &&
        STOptStatusArray[0].status != "open"
      ) {
        goodtogo = "Yes";
      } else {
        goodtogo = "No";
      }
    } else if (this.state.STAddressProofType_radio == "RentAgreement") {
      if (
        STOptStatusArray[1].status != "reupload" &&
        STOptStatusArray[1].status != "upload" &&
        STOptStatusArray[1].status != "open"
      ) {
        goodtogo = "Yes";
      } else {
        goodtogo = "No";
      }
    } else {
      goodtogo = "No";
    }

    if (
      AnyReuploadOccuredST == "Yes" &&
      statusCheckReupload == undefined &&
      goodtogo == "Yes" &&
      STadditionalStatusArrayCheck == undefined
    ) {
      $(".proceednowbtn5").attr("disabled", false);
      STproceednowforword = "success";
    } else if (
      STAadhar1Status == "remove" &&
      STAadhar2Status == "remove" &&
      STPan1Status == "remove" &&
      STPan2Status == "remove" &&
      STPic1Status == "remove" &&
      STPic2Status == "remove" &&
      STPan3Status == "remove" &&
      STCRStatus == "remove" &&
      STBPStatus == "remove" &&
      STBRStatus == "remove" &&
      STGumastaStatus == "remove" &&
      STTradeLicenceStatus == "remove" &&
      STPRStatus == "remove" &&
      goodtogo == "Yes"
    ) {
      $(".proceednowbtn5").attr("disabled", false);
      STproceednowforword = "success";
    } else {
      $(".proceednowbtn5").attr("disabled", true);
      STproceednowforword = "fail";
    }
  };

  handleSTProceedBtn = () => {
    this.checkhandleHUFProceedBtn();
    if (this.state.STbusinessNature.trim() == "") {
      this.showNotification("Required fields can not be empty!");
    } else if (STproceednowforword == "success") {
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=lockService",
          {
            userId: Decuserid,
            requestType: temp,
            agentId: Decagentid,

            serviceId: Decserviceid,
            serviceName: Decservicename,
            serviceType: this.state.serviceType,
            FY: "",
            Month: "",
            inputFields: [
              {
                inputFieldName: "AddressProofType",
                inputFieldValue: this.state.STAddressProofType_radio,
                inputFieldtype: "general",
              },
              {
                inputFieldName: "Nature",
                inputFieldValue: this.state.STbusinessNature,
                inputFieldtype: "general",
              },
            ],
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.showNotification(res.data.message);
            this.getserviceInformationApi();
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else {
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {});
    }
  };
  backbutton = () => {
    this.props.history.push({
      pathname: `/myuser/${Decuserid}/userservices`,
    });
  };

  
    
  handletimeline = () => {
    $("#timeline_btnloader").show();
    axios.post(
      process.env.REACT_APP_API_BASE_URL + "p=timeLine",
      {
        agentId: Decagentid,
        userId: Decuserid,
        serviceId: Decserviceid, 
      },
      {
        headers: {
          Authkey: process.env.REACT_APP_API_KEY,
          "App-Token-Access": Decjwttoken,
        },
        auth: {
          username: process.env.REACT_APP_API_USERNAME,
          password: process.env.REACT_APP_API_PASSWORD,
        },
      }
    )
    .then((res) => {
      if (res.data.code == "200" && res.data.status == "success") {
        
        if (res.data.timeLine.length > 0) {
          let length = res.data.timeLine.length;
          let ftimeline =  res.data.timeLine.filter((items,index) => {
            return (index <= this.state.timelcount )
          })
          this.setState({ 
            filtertimel: ftimeline,
            timelcount: this.state.timelcount + 3,
            Timeline: res.data.timeLine,
            Timelinedata: true,
          });
          this.setState({ 
            
          });
          // .splice(this.state.timelcount,length)
        }
      } else if (res.data.code == "201" && res.data.status == "invalidauth") {
        this.logout();
      } else { 
          this.showNotification(res.data.message);
      } 
    })
  }

  handlemoretimeline = () => {
 
    let ftimeline =  this.state.Timeline.filter((items,index) => {
      return (index <= this.state.timelcount )
    })
    this.setState({ 
      filtertimel: ftimeline,
      timelcount: this.state.timelcount + 3
    });
   
  }

  completerender() {
    if (this.state.deliveredDocuments.length > 0) {
      return this.state.deliveredDocuments.map((activedoc, index) => {
        return (
          <div className="displayflex documentmaincard" key={index}>
            <div>
              <img
                src={process.env.PUBLIC_URL + "/img/Path 247.svg"}
                className="docutypeimg"
              />
            </div>
            <div className="docucontdiv">
              <div className="row">
                <div className="col-md-6">
                  <p className="nameofdoc">{activedoc.documentName}</p>
                  <p className="datesenttext">
                    Date received:-{" "}
                    <span className="datesentspan">{activedoc.datesent}</span>
                  </p>
                </div>
                <div className="col-md-6">
                  <div className="fl_right">
                    <div className="displayflex">
                      <div className="viewbtndivpadding">
                        <a
                          href={activedoc.documentLink}
                          target="_blank"
                          className="viewbtn"
                        >
                          View{" "}
                          <img
                            src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                          />
                        </a>
                      </div>
                      <div>
                        <a
                          href={activedoc.documentLink}
                          className="download_btn"
                          target="_blank"
                          download
                        >
                          Download{" "}
                          <img
                            src={
                              process.env.PUBLIC_URL + "/img/download_icon.svg"
                            }
                            alt="download"
                          />
                        </a>
                      </div>
                    </div>
                    <p className="docushareviatext">
                      Share via:{" "}
                      <a onClick={() =>
                        this.whatsapp(
                          activedoc.documentName,
                          activedoc.documentLink
                        )
                      }>
                        <img
                          src={process.env.PUBLIC_URL + "/img/whatsapp.svg"}
                          className="docushareviawhats"
                        />
                      </a>{" "}
                      <a onClick={() =>
                        this.email(
                          activedoc.documentName,
                          activedoc.documentLink
                        )
                      }>
                        <img
                          src={process.env.PUBLIC_URL + "/img/mail.svg"}
                          className="docushareviamail"
                        />
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.deliveredDocuments.length == 0) {
      return (
        <center className="myservicequiteimgcenter">
          <img alt="loading..." src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
            className="emptydeliveredDocuments"
          />
        </center>
      );
    }
  }


  render() {
    if (
      localStorage.getItem("gglltakoinoeenl") ==
        process.env.REACT_APP_LOGIN_KEY &&
      localStorage.getItem("toeljgtkewlna") != null &&
      localStorage.getItem("qazxswedcvfrtgb") != null
    ) {
      return (
        <div className="cont">
          <div id="notifContainer"></div>
          <Helmet>
            <link
              rel="stylesheet"
              href={process.env.PUBLIC_URL + "/css/select2.css"}
            />
          </Helmet>

          <Header />

          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
            className="sidebaropenicon"
          />
          <img
            onClick={this.backbutton}
            src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
            className="sidebarbackicon"
          />
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"}
            className="sidebarexpandicon"
          />

          <div className="overlay1"></div>
          <div className="sidebar sidebarshift">
            <div className="side_div_2 sidebar_txt active">
              <Link to="/dashboard">Overview</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <Link to="/myservices">My Services</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <Link to="/paymentinvoice">Payments Invoices</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <Link to="/documentrecords">Documents Record</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <Link to="/addservice">Add service</Link>
            </div>
            <div></div>
          </div>
          <div className="arrow-left2 visibilityhidden"></div>
          <div className="white_div expandwhite_div">
            <div className="serviceoverlay">
              <div className="serviceoverlayinside">
                <center>
                  <img
                    src={process.env.PUBLIC_URL + "/img/loader.gif"}
                    className="serviceloader"
                  />
                </center>
              </div>
            </div>
            <div className="white_box">
            {this.state.referralPartner ? 
              <div className="uploaddocu">
                <div className="row">
                  {/* <div className="myservicesortbyfilterbystatus mt-1 ml-1"></div> */}
                  <div className="col-lg-9 mt-3">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="inner-header d-flex justify-content-between">
                          <div className="slider-navigation-tab">
                            <header className="tabs-nav position-relative">
                              <ul className="d-flex slider-navigation-tab-ul">
                                <li
                                  className="active slider-navigation-tab-li"
                                  id="activeTab"
                                >
                                  <a href="#tab1" className="px-2 py-1">
                                    Information given
                                  </a>
                                </li>
                                <li
                                  className="slider-navigation-tab-li"
                                  id="completedTab"
                                >
                                  <a href="#tab2" className="px-2 py-1">
                                    Deliverables
                                  </a>
                                </li>
                                <hr className="slider-navigation-tab-vertical-line" />
                                <div className="sliderpage-slider"></div>
                              </ul>
                            </header>
                          </div>
                        </div>
                        {/* <p className="uploadtext">Upload Information :-</p> */}
                      </div>
                      <div className="col-md-6 selectcateselectpadding">
                        <select
                          className="selectcateselect"
                          value={this.state.dropDownValue}
                          onChange={this.handledropDownValueChange}
                        >
                          <option
                            value="volvo"
                            id="volvo"
                            className="selectcateselectoption2"
                          >
                            Select category...
                          </option>
                          <option
                            value="GSTR_SolePI_Documents"
                            className="selectcateselectoption"
                          >
                            Sole Proprietorship / Individual
                          </option>
                          <option
                            value="GSTR_P_LLP_Documents"
                            className="selectcateselectoption"
                          >
                            Partnership / LLP
                          </option>
                          <option
                            value="GSTR_PRL_PL_OP_Documents"
                            className="selectcateselectoption"
                          >
                            Private limited / Public limited / One person
                            company
                          </option>
                          <option
                            value="GSTR_HUF_Documents"
                            className="selectcateselectoption"
                          >
                            HUF
                          </option>
                          <option
                            value="GSTR_STC_Documents"
                            className="selectcateselectoption"
                          >
                            Society / Trust / Club
                          </option>
                        </select>
                      </div>
                    </div>
                    </div>
                  </div>

                  <div className="tabs-content">
                <div id="tab1" className="tab-content">
                <div className="row">
                {/* <div className="myservicesortbyfilterbystatus mt-1 ml-1"> </div> */}
                  <div className="col-lg-9 ">

                    {/* None */}
                    <center id="gstr_default_Img">
                      <img
                        src={process.env.PUBLIC_URL + "/img/Group_3582.svg"}
                        className="selectcategoryimg"
                      />
                    </center>

                    {/* Sole P/I */}
                    <div id="gstr_SolePI">
                      <div className="uploadscrolldiv">
                        <div className="uploadscrollcardinside">
                          {/* SAadhar */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Aadhaar card
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    Front and back both side
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/AadhaarCard.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="SAadharseecommentsbtn"
                                      onClick={this.handleSAadharseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="SAadharhidecommentsbtn"
                                      onClick={
                                        this.handleSAadharhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_1"
                                      className="choose_btn"
                                      id="choose_SAadhar"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_1"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleSAadharChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_SAadhar"
                                      onClick={(e) => this.removeSAadhar(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_SAadhar"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_SAadhar"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.SAadharDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_SAadhar"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* SPan */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    PAN Card
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PANCard.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="SPanseecommentsbtn"
                                      onClick={this.handleSPanseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="SPanhidecommentsbtn"
                                      onClick={this.handleSPanhidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_2"
                                      className="choose_btn"
                                      id="choose_SPan"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_2"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) => this.handleSPanChange(e)}
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_SPan"
                                      onClick={(e) => this.removeSPan(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_SPan"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__SPan"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.SPanDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_SPan"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* SPic */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Passport size Photograph of the proprietor
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PassportSizePhoto.jpeg"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="SPicseecommentsbtn"
                                      onClick={this.handleSPicseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="SPichidecommentsbtn"
                                      onClick={this.handleSPichidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_3"
                                      className="choose_btn"
                                      id="choose_SPic"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_3"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) => this.handleSPicChange(e)}
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_SPic"
                                      onClick={(e) => this.removeSPic(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_SPic"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__SPic"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.SPicDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_SPic"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* SBP */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Bank Proof
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    Bank statement or a cancelled cheque
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/BankProof.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="SBPseecommentsbtn"
                                      onClick={this.handleSBPseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="SBPhidecommentsbtn"
                                      onClick={this.handleSBPhidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_4"
                                      className="choose_btn"
                                      id="choose_SBP"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_4"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) => this.handleSBPChange(e)}
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_SBP"
                                      onClick={(e) => this.removeSBP(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_SBP"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__SBP"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.SBPDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_SBP"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* SGumasta */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Shop & Establishment Licence (Gumasta)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/Gumasta.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="SGumastaseecommentsbtn"
                                      onClick={
                                        this.handleSGumastaseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="SGumastahidecommentsbtn"
                                      onClick={
                                        this.handleSGumastahidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_5"
                                      className="choose_btn"
                                      id="choose_SGumasta"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_5"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleSGumastaChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_SGumasta"
                                      onClick={(e) => this.removeSGumasta(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_SGumasta"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__SGumasta"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.SGumastaDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_SGumasta"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* STradeLicence */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Trade Licence (Municipal Corporation)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/TradeLicence.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="STradeLicenceseecommentsbtn"
                                      onClick={
                                        this.handleSTradeLicenceseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="STradeLicencehidecommentsbtn"
                                      onClick={
                                        this.handleSTradeLicencehidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_6"
                                      className="choose_btn"
                                      id="choose_STradeLicence"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_6"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleSTradeLicenceChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_STradeLicence"
                                      onClick={(e) =>
                                        this.removeSTradeLicence(e)
                                      }
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_STradeLicence"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__STradeLicence"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.STradeLicenceDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_STradeLicence"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* SNature */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <p className="uploadnormaltext">
                                Nature of your Business / Brand:-
                                <sup className="requireddocstar">*</sup>
                              </p>
                              <textarea
                                className="uploadtextarea"
                                placeholder="Ex:- Kirana Shop / Shoes Brand / Hardware company..."
                                value={this.state.SbusinessNature}
                                onChange={this.handleSbusinessNatureChange}
                              ></textarea>
                            </div>
                          </div>

                          {/* SAddressProofType */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <p className="uploadnormaltext">
                                Office address proof:
                                <sup className="requireddocstar">*</sup>
                              </p>

                              <input
                                type="radio"
                                id="test1-1"
                                name="radio-group1"
                                value="CopyOfRegistry"
                                checked={
                                  this.state.SAddressProofType_radio ===
                                  "CopyOfRegistry"
                                }
                                onChange={this.SAddressProofType_radioChange}
                              />
                              <label
                                htmlFor="test1-1"
                                className="uploadradiotext"
                              >
                                Own office
                              </label>
                              <br />
                              <input
                                type="radio"
                                id="test1-2"
                                name="radio-group1"
                                value="RentAgreement"
                                checked={
                                  this.state.SAddressProofType_radio ===
                                  "RentAgreement"
                                }
                                onChange={this.SAddressProofType_radioChange}
                              />
                              <label
                                htmlFor="test1-2"
                                className="uploadradiotext"
                              >
                                Rented office
                              </label>
                            </div>
                          </div>

                          {/* SCopyOfRegistry */}
                          <div className="displayflex CopyOfRegistry">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Copy of Registry
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/CopyOfRegistry.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="SCopyOfRegistryseecommentsbtn"
                                      onClick={
                                        this.handleSCopyOfRegistryseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="SCopyOfRegistryhidecommentsbtn"
                                      onClick={
                                        this
                                          .handleSCopyOfRegistryhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_7"
                                      className="choose_btn"
                                      id="choose_SCopyOfRegistry"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_7"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleSCopyOfRegistryChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_SCopyOfRegistry"
                                      onClick={(e) =>
                                        this.removeSCopyOfRegistry(e)
                                      }
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_SCopyOfRegistry"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__SCopyOfRegistry"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.SCopyOfRegistryDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_SCopyOfRegistry"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* SRentAgreement */}
                          <div className="displayflex RentAgreement">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Rent agreement
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/RentAgreement.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="SRentAgreementseecommentsbtn"
                                      onClick={
                                        this.handleSRentAgreementseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="SRentAgreementhidecommentsbtn"
                                      onClick={
                                        this.handleSRentAgreementhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_8"
                                      className="choose_btn"
                                      id="choose_SRentAgreement"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_8"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleSRentAgreementChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_SRentAgreement"
                                      onClick={(e) =>
                                        this.removeSRentAgreement(e)
                                      }
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_SRentAgreement"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__SRentAgreement"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.SRentAgreementDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_SRentAgreement"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* SPR */}
                          <div className="displayflex lastdisplayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploadlastdatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Property Tax Receipt
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    (in the name of owner)
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PropertyTaxReceipt.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="SPRseecommentsbtn"
                                      onClick={this.handleSPRseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="SPRhidecommentsbtn"
                                      onClick={this.handleSPRhidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_9"
                                      className="choose_btn"
                                      id="choose_SPR"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_9"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) => this.handleSPRChange(e)}
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_SPR"
                                      onClick={(e) => this.removeSPR(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_SPR"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__SPR"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.SPRDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_SPR"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* SAdditional */}
                          <div id="SadditionalDocumentDiv">
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadadditionalsidetext visibilityhidden">
                                  1&#41;
                                </p>
                              </div>
                              <div className="uploadadditionaldatacol">
                                <p className="uploadadditionalnormaltext">
                                  Additional documents:-
                                </p>
                              </div>
                            </div>
                            {this.SadditionalFilesArea()}

                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadadditionalsidetext visibilityhidden">
                                  1&#41;
                                </p>
                              </div>
                              <div className="uploadadditionaldatacol">
                                <button
                                  className="adddocumentbtn"
                                  id="SAdddocumentbtn"
                                  onClick={() =>
                                    this.handleAddSFileBtn(
                                      SadditionalFilesIndex
                                    )
                                  }
                                >
                                  Add document{" "}
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Path 19524.svg"
                                    }
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <center>
                        <button
                          className="proceednowbtn proceednowbtn1"
                          onClick={this.handleSProceedBtn}
                        >
                          Proceed now{" "}
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/tick.svg"}
                          />
                        </button>
                      </center>
                    </div>
                    {/* Partnership */}
                    <div id="gstr_P_LLP">
                      <div className="uploadscrolldiv">
                        <div className="uploadscrollcardinside">
                          {/* PAadhar1 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Aadhaar card (Partner 1)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    Front and back both side
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/AadhaarCard.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PAadhar1seecommentsbtn"
                                      onClick={
                                        this.handlePAadhar1seecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PAadhar1hidecommentsbtn"
                                      onClick={
                                        this.handlePAadhar1hidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_10"
                                      className="choose_btn"
                                      id="choose_PAadhar1"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_10"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePAadhar1Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PAadhar1"
                                      onClick={(e) => this.removePAadhar1(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PAadhar1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PAadhar1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PAadhar1DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PAadhar1"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PAadhar2 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Aadhaar card (Partner 2)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    Front and back both side
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/AadhaarCard.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PAadhar2seecommentsbtn"
                                      onClick={
                                        this.handlePAadhar2seecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PAadhar2hidecommentsbtn"
                                      onClick={
                                        this.handlePAadhar2hidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_11"
                                      className="choose_btn"
                                      id="choose_PAadhar2"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_11"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePAadhar2Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PAadhar2"
                                      onClick={(e) => this.removePAadhar2(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PAadhar2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PAadhar2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PAadhar2DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PAadhar2"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PPan1 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    PAN Card (Partner 1)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PANCard.pdf
"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PPan1seecommentsbtn"
                                      onClick={this.handlePPan1seecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PPan1hidecommentsbtn"
                                      onClick={this.handlePPan1hidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_12"
                                      className="choose_btn"
                                      id="choose_PPan1"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_12"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePPan1Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PPan1"
                                      onClick={(e) => this.removePPan1(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PPan1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PPan1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PPan1DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PPan1"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PPan2 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    PAN Card (Partner 2)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PANCard.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PPan2seecommentsbtn"
                                      onClick={this.handlePPan2seecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PPan2hidecommentsbtn"
                                      onClick={this.handlePPan2hidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_13"
                                      className="choose_btn"
                                      id="choose_PPan2"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_13"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePPan2Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PPan2"
                                      onClick={(e) => this.removePPan2(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PPan2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PPan2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PPan2DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PPan2"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PPic1 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Passport size Photograph (Partner 1)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PassportSizePhoto.jpeg"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PPic1seecommentsbtn"
                                      onClick={this.handlePPic1seecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PPic1hidecommentsbtn"
                                      onClick={this.handlePPic1hidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_14"
                                      className="choose_btn"
                                      id="choose_PPic1"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_14"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePPic1Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PPic1"
                                      onClick={(e) => this.removePPic1(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PPic1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PPic1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PPic1DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PPic1"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PPic2 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Passport size Photograph (Partner 2)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PassportSizePhoto.jpeg"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PPic2seecommentsbtn"
                                      onClick={this.handlePPic2seecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PPic2hidecommentsbtn"
                                      onClick={this.handlePPic2hidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_15"
                                      className="choose_btn"
                                      id="choose_PPic2"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_15"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePPic2Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PPic2"
                                      onClick={(e) => this.removePPic2(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PPic2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PPic2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PPic2DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PPic2"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PBP */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Bank Proof
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    Bank statement or a cancelled cheque
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/BankProof.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PBPseecommentsbtn"
                                      onClick={this.handlePBPseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PBPhidecommentsbtn"
                                      onClick={this.handlePBPhidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_16"
                                      className="choose_btn"
                                      id="choose_PBP"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_16"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) => this.handlePBPChange(e)}
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PBP"
                                      onClick={(e) => this.removePBP(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PBP"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PBP"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PBPDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PBP"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PGumasta */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Shop & Establishment Licence (Gumasta)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/Gumasta.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PGumastaseecommentsbtn"
                                      onClick={
                                        this.handlePGumastaseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PGumastahidecommentsbtn"
                                      onClick={
                                        this.handlePGumastahidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_17"
                                      className="choose_btn"
                                      id="choose_PGumasta"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_17"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePGumastaChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PGumasta"
                                      onClick={(e) => this.removePGumasta(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PGumasta"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PGumasta"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PGumastaDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PGumasta"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PTradeLicence */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Trade Licence (Municipal Corporation)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/TradeLicence.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PTradeLicenceseecommentsbtn"
                                      onClick={
                                        this.handlePTradeLicenceseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PTradeLicencehidecommentsbtn"
                                      onClick={
                                        this.handlePTradeLicencehidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_18"
                                      className="choose_btn"
                                      id="choose_PTradeLicence"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_18"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePTradeLicenceChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PTradeLicence"
                                      onClick={(e) =>
                                        this.removePTradeLicence(e)
                                      }
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PTradeLicence"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PTradeLicence"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PTradeLicenceDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PTradeLicence"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* PDeed */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Partnership Deed
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PartnershipDeed.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PDeedseecommentsbtn"
                                      onClick={this.handlePDeedseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PDeedhidecommentsbtn"
                                      onClick={this.handlePDeedhidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_18Deed"
                                      className="choose_btn"
                                      id="choose_PDeed"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_18Deed"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePDeedChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PDeed"
                                      onClick={(e) => this.removePDeed(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PDeed"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PDeed"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PDeedDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PDeed"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* PNature */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <p className="uploadnormaltext">
                                Nature of your Business / Brand:-
                                <sup className="requireddocstar">*</sup>
                              </p>
                              <textarea
                                className="uploadtextarea"
                                placeholder="Ex:- Kirana Shop / Shoes Brand / Hardware company..."
                                value={this.state.PbusinessNature}
                                onChange={this.handlePbusinessNatureChange}
                              ></textarea>
                            </div>
                          </div>

                          {/* PAddressProofType */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <p className="uploadnormaltext">
                                Office address proof:
                                <sup className="requireddocstar">*</sup>
                              </p>

                              <input
                                type="radio"
                                id="test2-1"
                                name="radio-group2"
                                value="CopyOfRegistry"
                                checked={
                                  this.state.PAddressProofType_radio ===
                                  "CopyOfRegistry"
                                }
                                onChange={this.PAddressProofType_radioChange}
                              />
                              <label
                                htmlFor="test2-1"
                                className="uploadradiotext"
                              >
                                Own office
                              </label>
                              <br />
                              <input
                                type="radio"
                                id="test2-2"
                                name="radio-group2"
                                value="RentAgreement"
                                checked={
                                  this.state.PAddressProofType_radio ===
                                  "RentAgreement"
                                }
                                onChange={this.PAddressProofType_radioChange}
                              />
                              <label
                                htmlFor="test2-2"
                                className="uploadradiotext"
                              >
                                Rented office
                              </label>
                            </div>
                          </div>

                          {/* PCopyOfRegistry */}
                          <div className="displayflex CopyOfRegistry">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Copy of Registry
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/CopyOfRegistry.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PCopyOfRegistryseecommentsbtn"
                                      onClick={
                                        this.handlePCopyOfRegistryseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PCopyOfRegistryhidecommentsbtn"
                                      onClick={
                                        this
                                          .handlePCopyOfRegistryhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_19"
                                      className="choose_btn"
                                      id="choose_PCopyOfRegistry"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_19"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePCopyOfRegistryChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PCopyOfRegistry"
                                      onClick={(e) =>
                                        this.removePCopyOfRegistry(e)
                                      }
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PCopyOfRegistry"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PCopyOfRegistry"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PCopyOfRegistryDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PCopyOfRegistry"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PRentAgreement */}
                          <div className="displayflex RentAgreement">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Rent agreement
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/RentAgreement.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PRentAgreementseecommentsbtn"
                                      onClick={
                                        this.handlePRentAgreementseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PRentAgreementhidecommentsbtn"
                                      onClick={
                                        this.handlePRentAgreementhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_20"
                                      className="choose_btn"
                                      id="choose_PRentAgreement"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_20"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePRentAgreementChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PRentAgreement"
                                      onClick={(e) =>
                                        this.removePRentAgreement(e)
                                      }
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PRentAgreement"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PRentAgreement"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PRentAgreementDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PRentAgreement"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PPR */}
                          <div className="displayflex lastdisplayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploadlastdatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Property Tax Receipt
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    (in the name of owner)
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PropertyTaxReceipt.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PPRseecommentsbtn"
                                      onClick={this.handlePPRseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PPRhidecommentsbtn"
                                      onClick={this.handlePPRhidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_21"
                                      className="choose_btn"
                                      id="choose_PPR"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_21"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) => this.handlePPRChange(e)}
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PPR"
                                      onClick={(e) => this.removePPR(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PPR"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PPR"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PPRDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PPR"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PAdditional */}
                          <div id="PadditionalDocumentDiv">
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadadditionalsidetext visibilityhidden">
                                  1&#41;
                                </p>
                              </div>
                              <div className="uploadadditionaldatacol">
                                <p className="uploadadditionalnormaltext">
                                  Additional documents:-
                                </p>
                              </div>
                            </div>
                            {this.PadditionalFilesArea()}

                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadadditionalsidetext visibilityhidden">
                                  1&#41;
                                </p>
                              </div>
                              <div className="uploadadditionaldatacol">
                                <button
                                  className="adddocumentbtn"
                                  id="PAdddocumentbtn"
                                  onClick={() =>
                                    this.handleAddPFileBtn(
                                      PadditionalFilesIndex
                                    )
                                  }
                                >
                                  Add document{" "}
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Path 19524.svg"
                                    }
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <center>
                        <button
                          className="proceednowbtn proceednowbtn2"
                          onClick={this.handlePProceedBtn}
                        >
                          Proceed now{" "}
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/tick.svg"}
                          />
                        </button>
                      </center>
                    </div>
                    {/* Private limited */}
                    <div id="gstr_PL">
                      <div className="uploadscrolldiv">
                        <div className="uploadscrollcardinside">
                          {/* PLAadhar1 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Aadhaar card (Director 1)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    Front and back both side
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/AadhaarCard.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PLAadhar1seecommentsbtn"
                                      onClick={
                                        this.handlePLAadhar1seecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PLAadhar1hidecommentsbtn"
                                      onClick={
                                        this.handlePLAadhar1hidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_22"
                                      className="choose_btn"
                                      id="choose_PLAadhar1"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_22"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePLAadhar1Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PLAadhar1"
                                      onClick={(e) => this.removePLAadhar1(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PLAadhar1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PLAadhar1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PLAadhar1DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PLAadhar1"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PLAadhar2 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Aadhaar card (Director 2)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    Front and back both side
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/AadhaarCard.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PLAadhar2seecommentsbtn"
                                      onClick={
                                        this.handlePLAadhar2seecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PLAadhar2hidecommentsbtn"
                                      onClick={
                                        this.handlePLAadhar2hidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_23"
                                      className="choose_btn"
                                      id="choose_PLAadhar2"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_23"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePLAadhar2Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PLAadhar2"
                                      onClick={(e) => this.removePLAadhar2(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PLAadhar2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PLAadhar2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PLAadhar2DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PLAadhar2"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PLPan1 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    PAN Card (Director 1)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PANCard.pdf
"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PLPan1seecommentsbtn"
                                      onClick={this.handlePLPan1seecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PLPan1hidecommentsbtn"
                                      onClick={this.handlePLPan1hidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_24"
                                      className="choose_btn"
                                      id="choose_PLPan1"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_24"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePLPan1Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PLPan1"
                                      onClick={(e) => this.removePLPan1(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PLPan1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PLPan1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PLPan1DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PLPan1"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PLPan2 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    PAN Card (Director 2)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PANCard.pdf
"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PLPan2seecommentsbtn"
                                      onClick={this.handlePLPan2seecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PLPan2hidecommentsbtn"
                                      onClick={this.handlePLPan2hidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_25"
                                      className="choose_btn"
                                      id="choose_PLPan2"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_25"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePLPan2Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PLPan2"
                                      onClick={(e) => this.removePLPan2(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PLPan2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PLPan2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PLPan2DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PLPan2"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PLPic1 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Passport size Photograph (Director 1)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PassportSizePhoto.jpeg"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PLPic1seecommentsbtn"
                                      onClick={this.handlePLPic1seecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PLPic1hidecommentsbtn"
                                      onClick={this.handlePLPic1hidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_26"
                                      className="choose_btn"
                                      id="choose_PLPic1"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_26"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePLPic1Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PLPic1"
                                      onClick={(e) => this.removePLPic1(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PLPic1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PLPic1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PLPic1DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PLPic1"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PLPic2 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Passport size Photograph (Director 2)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PassportSizePhoto.jpeg"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PLPic2seecommentsbtn"
                                      onClick={this.handlePLPic2seecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PLPic2hidecommentsbtn"
                                      onClick={this.handlePLPic2hidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_27"
                                      className="choose_btn"
                                      id="choose_PLPic2"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_27"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePLPic2Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PLPic2"
                                      onClick={(e) => this.removePLPic2(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PLPic2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PLPic2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PLPic2DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PLPic2"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PLPan3 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Company’s PAN card
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PANCardCompany.pdf
"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PLPan3seecommentsbtn"
                                      onClick={this.handlePLPan3seecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PLPan3hidecommentsbtn"
                                      onClick={this.handlePLPan3hidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_28"
                                      className="choose_btn"
                                      id="choose_PLPan3"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_28"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePLPan3Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PLPan3"
                                      onClick={(e) => this.removePLPan3(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PLPan3"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PLPan3"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PLPan3DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PLPan3"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PLCI */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Certificate of Incorporation
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/CertificateOfIncorporation.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PLCIseecommentsbtn"
                                      onClick={this.handlePLCIseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PLCIhidecommentsbtn"
                                      onClick={this.handlePLCIhidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_29"
                                      className="choose_btn"
                                      id="choose_PLCI"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_29"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) => this.handlePLCIChange(e)}
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PLCI"
                                      onClick={(e) => this.removePLCI(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PLCI"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PLCI"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PLCIDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PLCI"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PLMOA */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    MOA<sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/MOA.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PLMOAseecommentsbtn"
                                      onClick={this.handlePLMOAseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PLMOAhidecommentsbtn"
                                      onClick={this.handlePLMOAhidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_30"
                                      className="choose_btn"
                                      id="choose_PLMOA"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_30"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePLMOAChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PLMOA"
                                      onClick={(e) => this.removePLMOA(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PLMOA"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PLMOA"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PLMOADocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PLMOA"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PLAOA */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    AOA<sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/CertificateOfIncorporation.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PLAOAseecommentsbtn"
                                      onClick={this.handlePLAOAseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PLAOAhidecommentsbtn"
                                      onClick={this.handlePLAOAhidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_31"
                                      className="choose_btn"
                                      id="choose_PLAOA"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_31"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePLAOAChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PLAOA"
                                      onClick={(e) => this.removePLAOA(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PLAOA"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PLAOA"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PLAOADocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PLAOA"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PLBR */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Board Resolution
                                  </p>
                                  <p className="uploadinstr">
                                    In favor of authorized signatory
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/BoardResolution.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PLBRseecommentsbtn"
                                      onClick={this.handlePLBRseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PLBRhidecommentsbtn"
                                      onClick={this.handlePLBRhidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_32"
                                      className="choose_btn"
                                      id="choose_PLBR"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_32"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) => this.handlePLBRChange(e)}
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PLBR"
                                      onClick={(e) => this.removePLBR(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PLBR"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PLBR"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PLBRDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PLBR"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PLBP */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Bank Proof
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    Bank statement or a cancelled cheque
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/BankProof.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PLBPseecommentsbtn"
                                      onClick={this.handlePLBPseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PLBPhidecommentsbtn"
                                      onClick={this.handlePLBPhidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_33"
                                      className="choose_btn"
                                      id="choose_PLBP"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_33"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) => this.handlePLBPChange(e)}
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PLBP"
                                      onClick={(e) => this.removePLBP(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PLBP"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PLBP"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PLBPDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PLBP"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PLGumasta */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Shop & Establishment Licence (Gumasta)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/Gumasta.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PLGumastaseecommentsbtn"
                                      onClick={
                                        this.handlePLGumastaseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PLGumastahidecommentsbtn"
                                      onClick={
                                        this.handlePLGumastahidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_34"
                                      className="choose_btn"
                                      id="choose_PLGumasta"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_34"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePLGumastaChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PLGumasta"
                                      onClick={(e) => this.removePLGumasta(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PLGumasta"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PLGumasta"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PLGumastaDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PLGumasta"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PLTradeLicence */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Trade Licence (Municipal Corporation)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/TradeLicence.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PLTradeLicenceseecommentsbtn"
                                      onClick={
                                        this.handlePLTradeLicenceseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PLTradeLicencehidecommentsbtn"
                                      onClick={
                                        this.handlePLTradeLicencehidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_35"
                                      className="choose_btn"
                                      id="choose_PLTradeLicence"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_35"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePLTradeLicenceChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PLTradeLicence"
                                      onClick={(e) =>
                                        this.removePLTradeLicence(e)
                                      }
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PLTradeLicence"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PLTradeLicence"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PLTradeLicenceDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PLTradeLicence"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PLNature */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <p className="uploadnormaltext">
                                Nature of your Business / Brand:-
                                <sup className="requireddocstar">*</sup>
                              </p>
                              <textarea
                                className="uploadtextarea"
                                placeholder="Ex:- Kirana Shop / Shoes Brand / Hardware company..."
                                value={this.state.PLbusinessNature}
                                onChange={this.handlePLbusinessNatureChange}
                              ></textarea>
                            </div>
                          </div>

                          {/* PLAddressProofType */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <p className="uploadnormaltext">
                                Office address proof:
                                <sup className="requireddocstar">*</sup>
                              </p>

                              <input
                                type="radio"
                                id="test3-1"
                                name="radio-group3"
                                value="CopyOfRegistry"
                                checked={
                                  this.state.PLAddressProofType_radio ===
                                  "CopyOfRegistry"
                                }
                                onChange={this.PLAddressProofType_radioChange}
                              />
                              <label
                                htmlFor="test3-1"
                                className="uploadradiotext"
                              >
                                Own office
                              </label>
                              <br />
                              <input
                                type="radio"
                                id="test3-2"
                                name="radio-group3"
                                value="RentAgreement"
                                checked={
                                  this.state.PLAddressProofType_radio ===
                                  "RentAgreement"
                                }
                                onChange={this.PLAddressProofType_radioChange}
                              />
                              <label
                                htmlFor="test3-2"
                                className="uploadradiotext"
                              >
                                Rented office
                              </label>
                            </div>
                          </div>

                          {/* PLCopyOfRegistry */}
                          <div className="displayflex CopyOfRegistry">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Copy of Registry
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/CopyOfRegistry.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PLCopyOfRegistryseecommentsbtn"
                                      onClick={
                                        this
                                          .handlePLCopyOfRegistryseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PLCopyOfRegistryhidecommentsbtn"
                                      onClick={
                                        this
                                          .handlePLCopyOfRegistryhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_36"
                                      className="choose_btn"
                                      id="choose_PLCopyOfRegistry"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_36"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePLCopyOfRegistryChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PLCopyOfRegistry"
                                      onClick={(e) =>
                                        this.removePLCopyOfRegistry(e)
                                      }
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PLCopyOfRegistry"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PLCopyOfRegistry"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PLCopyOfRegistryDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PLCopyOfRegistry"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PLRentAgreement */}
                          <div className="displayflex RentAgreement">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Rent agreement
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/RentAgreement.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PLRentAgreementseecommentsbtn"
                                      onClick={
                                        this.handlePLRentAgreementseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PLRentAgreementhidecommentsbtn"
                                      onClick={
                                        this
                                          .handlePLRentAgreementhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_37"
                                      className="choose_btn"
                                      id="choose_PLRentAgreement"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_37"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePLRentAgreementChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PLRentAgreement"
                                      onClick={(e) =>
                                        this.removePLRentAgreement(e)
                                      }
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PLRentAgreement"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PLRentAgreement"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PLRentAgreementDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PLRentAgreement"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PLPR */}
                          <div className="displayflex lastdisplayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploadlastdatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Property Tax Receipt
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    (in the name of owner)
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PropertyTaxReceipt.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PLPRseecommentsbtn"
                                      onClick={this.handlePLPRseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PLPRhidecommentsbtn"
                                      onClick={this.handlePLPRhidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_38"
                                      className="choose_btn"
                                      id="choose_PLPR"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_38"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) => this.handlePLPRChange(e)}
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PLPR"
                                      onClick={(e) => this.removePLPR(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PLPR"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__PLPR"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PLPRDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PLPR"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PLAdditional */}
                          <div id="PLadditionalDocumentDiv">
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadadditionalsidetext visibilityhidden">
                                  1&#41;
                                </p>
                              </div>
                              <div className="uploadadditionaldatacol">
                                <p className="uploadadditionalnormaltext">
                                  Additional documents:-
                                </p>
                              </div>
                            </div>
                            {this.PLadditionalFilesArea()}

                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadadditionalsidetext visibilityhidden">
                                  1&#41;
                                </p>
                              </div>
                              <div className="uploadadditionaldatacol">
                                <button
                                  className="adddocumentbtn"
                                  id="PLAdddocumentbtn"
                                  onClick={() =>
                                    this.handleAddPLFileBtn(
                                      PLadditionalFilesIndex
                                    )
                                  }
                                >
                                  Add document{" "}
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Path 19524.svg"
                                    }
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <center>
                        <button
                          className="proceednowbtn proceednowbtn3"
                          onClick={this.handlePLProceedBtn}
                        >
                          Proceed now{" "}
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/tick.svg"}
                          />
                        </button>
                      </center>
                    </div>
                    {/* HUF */}
                    <div id="gstr_HUF">
                      <div className="uploadscrolldiv">
                        <div className="uploadscrollcardinside">
                          {/* HUFAadhar */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Aadhaar card of Karta
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    Front and back both side
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/AadhaarCard.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="HUFAadharseecommentsbtn"
                                      onClick={
                                        this.handleHUFAadharseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="HUFAadharhidecommentsbtn"
                                      onClick={
                                        this.handleHUFAadharhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_39"
                                      className="choose_btn"
                                      id="choose_HUFAadhar"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_39"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleHUFAadharChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_HUFAadhar"
                                      onClick={(e) => this.removeHUFAadhar(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_HUFAadhar"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__HUFAadhar"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.HUFAadharDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_HUFAadhar"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* HUFPan1 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    PAN card of Karta
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PANCard.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="HUFPan1seecommentsbtn"
                                      onClick={this.handleHUFPan1seecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="HUFPan1hidecommentsbtn"
                                      onClick={
                                        this.handleHUFPan1hidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_40"
                                      className="choose_btn"
                                      id="choose_HUFPan1"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_40"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleHUFPan1Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_HUFPan1"
                                      onClick={(e) => this.removeHUFPan1(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_HUFPan1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__HUFPan1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.HUFPan1DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_HUFPan1"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* HUFPic */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Passport size Photo of Applicant
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PassportSizePhoto.jpeg"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="HUFPicseecommentsbtn"
                                      onClick={this.handleHUFPicseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="HUFPichidecommentsbtn"
                                      onClick={this.handleHUFPichidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_41"
                                      className="choose_btn"
                                      id="choose_HUFPic"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_41"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleHUFPicChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_HUFPic"
                                      onClick={(e) => this.removeHUFPic(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_HUFPic"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__HUFPic"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.HUFPicDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_HUFPic"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* HUFPan2 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    PAN card of HUF
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PANCard.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="HUFPan2seecommentsbtn"
                                      onClick={this.handleHUFPan2seecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="HUFPan2hidecommentsbtn"
                                      onClick={
                                        this.handleHUFPan2hidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_42"
                                      className="choose_btn"
                                      id="choose_HUFPan2"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_42"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleHUFPan2Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_HUFPan2"
                                      onClick={(e) => this.removeHUFPan2(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_HUFPan2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__HUFPan2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.HUFPan2DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_HUFPan2"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* HUFDeed */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    HUF Deed Document
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/CertificateOfIncorporation.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="HUFDeedseecommentsbtn"
                                      onClick={this.handleHUFDeedseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="HUFDeedhidecommentsbtn"
                                      onClick={
                                        this.handleHUFDeedhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_43"
                                      className="choose_btn"
                                      id="choose_HUFDeed"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_43"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleHUFDeedChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_HUFDeed"
                                      onClick={(e) => this.removeHUFDeed(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_HUFDeed"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__HUFDeed"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.HUFDeedDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_HUFDeed"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* HUFBP */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Bank Proof
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    Bank statement or a cancelled cheque
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/BankProof.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="HUFBPseecommentsbtn"
                                      onClick={this.handleHUFBPseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="HUFBPhidecommentsbtn"
                                      onClick={this.handleHUFBPhidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_44"
                                      className="choose_btn"
                                      id="choose_HUFBP"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_44"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleHUFBPChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_HUFBP"
                                      onClick={(e) => this.removeHUFBP(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_HUFBP"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__HUFBP"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.HUFBPDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_HUFBP"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* HUFGumasta */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Shop & Establishment Licence (Gumasta)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/Gumasta.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="HUFGumastaseecommentsbtn"
                                      onClick={
                                        this.handleHUFGumastaseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="HUFGumastahidecommentsbtn"
                                      onClick={
                                        this.handleHUFGumastahidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_45"
                                      className="choose_btn"
                                      id="choose_HUFGumasta"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_45"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleHUFGumastaChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_HUFGumasta"
                                      onClick={(e) => this.removeHUFGumasta(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_HUFGumasta"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__HUFGumasta"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.HUFGumastaDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_HUFGumasta"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* HUFTradeLicence */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Trade Licence (Municipal Corporation)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/TradeLicence.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="HUFTradeLicenceseecommentsbtn"
                                      onClick={
                                        this.handleHUFTradeLicenceseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="HUFTradeLicencehidecommentsbtn"
                                      onClick={
                                        this
                                          .handleHUFTradeLicencehidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_46"
                                      className="choose_btn"
                                      id="choose_HUFTradeLicence"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_46"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleHUFTradeLicenceChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_HUFTradeLicence"
                                      onClick={(e) =>
                                        this.removeHUFTradeLicence(e)
                                      }
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_HUFTradeLicence"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__HUFTradeLicence"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.HUFTradeLicenceDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_HUFTradeLicence"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* HUFNature */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <p className="uploadnormaltext">
                                Nature of your Business / Brand:-
                                <sup className="requireddocstar">*</sup>
                              </p>
                              <textarea
                                className="uploadtextarea"
                                placeholder="Ex:- Kirana Shop / Shoes Brand / Hardware company..."
                                value={this.state.HUFbusinessNature}
                                onChange={this.handleHUFbusinessNatureChange}
                              ></textarea>
                            </div>
                          </div>

                          {/* HUFAddressProofType */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <p className="uploadnormaltext">
                                Office address proof:
                                <sup className="requireddocstar">*</sup>
                              </p>

                              <input
                                type="radio"
                                id="test4-1"
                                name="radio-group4"
                                value="CopyOfRegistry"
                                checked={
                                  this.state.HUFAddressProofType_radio ===
                                  "CopyOfRegistry"
                                }
                                onChange={this.HUFAddressProofType_radioChange}
                              />
                              <label
                                htmlFor="test4-1"
                                className="uploadradiotext"
                              >
                                Own office
                              </label>
                              <br />
                              <input
                                type="radio"
                                id="test4-2"
                                name="radio-group4"
                                value="RentAgreement"
                                checked={
                                  this.state.HUFAddressProofType_radio ===
                                  "RentAgreement"
                                }
                                onChange={this.HUFAddressProofType_radioChange}
                              />
                              <label
                                htmlFor="test4-2"
                                className="uploadradiotext"
                              >
                                Rented office
                              </label>
                            </div>
                          </div>

                          {/* HUFCopyOfRegistry */}
                          <div className="displayflex CopyOfRegistry">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Copy of Registry
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/CopyOfRegistry.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="HUFCopyOfRegistryseecommentsbtn"
                                      onClick={
                                        this
                                          .handleHUFCopyOfRegistryseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="HUFCopyOfRegistryhidecommentsbtn"
                                      onClick={
                                        this
                                          .handleHUFCopyOfRegistryhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_47"
                                      className="choose_btn"
                                      id="choose_HUFCopyOfRegistry"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_47"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleHUFCopyOfRegistryChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_HUFCopyOfRegistry"
                                      onClick={(e) =>
                                        this.removeHUFCopyOfRegistry(e)
                                      }
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_HUFCopyOfRegistry"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__HUFCopyOfRegistry"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.HUFCopyOfRegistryDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_HUFCopyOfRegistry"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* HUFRentAgreement */}
                          <div className="displayflex RentAgreement">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Rent agreement
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/RentAgreement.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="HUFRentAgreementseecommentsbtn"
                                      onClick={
                                        this
                                          .handleHUFRentAgreementseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="HUFRentAgreementhidecommentsbtn"
                                      onClick={
                                        this
                                          .handleHUFRentAgreementhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_48"
                                      className="choose_btn"
                                      id="choose_HUFRentAgreement"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_48"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleHUFRentAgreementChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_HUFRentAgreement"
                                      onClick={(e) =>
                                        this.removeHUFRentAgreement(e)
                                      }
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_HUFRentAgreement"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__HUFRentAgreement"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.HUFRentAgreementDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_HUFRentAgreement"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* HUFPR */}
                          <div className="displayflex lastdisplayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploadlastdatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Property Tax Receipt
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    (in the name of owner)
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PropertyTaxReceipt.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="HUFPRseecommentsbtn"
                                      onClick={this.handleHUFPRseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="HUFPRhidecommentsbtn"
                                      onClick={this.handleHUFPRhidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_49"
                                      className="choose_btn"
                                      id="choose_HUFPR"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_49"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleHUFPRChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_HUFPR"
                                      onClick={(e) => this.removeHUFPR(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_HUFPR"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__HUFPR"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.HUFPRDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_HUFPR"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* HUFAdditional */}
                          <div id="HUFadditionalDocumentDiv">
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadadditionalsidetext visibilityhidden">
                                  1&#41;
                                </p>
                              </div>
                              <div className="uploadadditionaldatacol">
                                <p className="uploadadditionalnormaltext">
                                  Additional documents:-
                                </p>
                              </div>
                            </div>
                            {this.HUFadditionalFilesArea()}

                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadadditionalsidetext visibilityhidden">
                                  1&#41;
                                </p>
                              </div>
                              <div className="uploadadditionaldatacol">
                                <button
                                  className="adddocumentbtn"
                                  id="HUFAdddocumentbtn"
                                  onClick={() =>
                                    this.handleAddHUFFileBtn(
                                      HUFadditionalFilesIndex
                                    )
                                  }
                                >
                                  Add document{" "}
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Path 19524.svg"
                                    }
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <center>
                        <button
                          className="proceednowbtn proceednowbtn4"
                          onClick={this.handleHUFProceedBtn}
                        >
                          Proceed now{" "}
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/tick.svg"}
                          />
                        </button>
                      </center>
                    </div>
                    {/* Society/ trust/ club */}
                    <div id="gstr_Society">
                      <div className="uploadscrolldiv">
                        <div className="uploadscrollcardinside">
                          {/* STAadhar1 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Aadhaar card (President or Secretary) (1)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    Front and back both side
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/AadhaarCard.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="STAadhar1seecommentsbtn"
                                      onClick={
                                        this.handleSTAadhar1seecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="STAadhar1hidecommentsbtn"
                                      onClick={
                                        this.handleSTAadhar1hidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_50"
                                      className="choose_btn"
                                      id="choose_STAadhar1"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_50"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleSTAadhar1Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_STAadhar1"
                                      onClick={(e) => this.removeSTAadhar1(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_STAadhar1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__STAadhar1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.STAadhar1DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_STAadhar1"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* STAadhar2 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Aadhaar card (President or Secretary) (2)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    Front and back both side
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/AadhaarCard.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="STAadhar2seecommentsbtn"
                                      onClick={
                                        this.handleSTAadhar2seecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="STAadhar2hidecommentsbtn"
                                      onClick={
                                        this.handleSTAadhar2hidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_51"
                                      className="choose_btn"
                                      id="choose_STAadhar2"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_51"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleSTAadhar2Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_STAadhar2"
                                      onClick={(e) => this.removeSTAadhar2(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_STAadhar2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__STAadhar2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.STAadhar2DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_STAadhar2"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* STPan1 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    PAN Card (President or Secretary) (1)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PANCard.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="STPan1seecommentsbtn"
                                      onClick={this.handleSTPan1seecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="STPan1hidecommentsbtn"
                                      onClick={this.handleSTPan1hidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_52"
                                      className="choose_btn"
                                      id="choose_STPan1"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_52"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleSTPan1Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_STPan1"
                                      onClick={(e) => this.removeSTPan1(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_STPan1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__STPan1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.STPan1DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_STPan1"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* STPan2 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    PAN Card (President or Secretary) (2)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PANCard.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="STPan2seecommentsbtn"
                                      onClick={this.handleSTPan2seecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="STPan2hidecommentsbtn"
                                      onClick={this.handleSTPan2hidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_53"
                                      className="choose_btn"
                                      id="choose_STPan2"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_53"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleSTPan2Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_STPan2"
                                      onClick={(e) => this.removeSTPan2(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_STPan2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__STPan2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.STPan2DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_STPan2"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* STPic1 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Passport size Photo (President or Secretary)
                                    (1)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PassportSizePhoto.jpeg"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="STPic1seecommentsbtn"
                                      onClick={this.handleSTPic1seecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="STPic1hidecommentsbtn"
                                      onClick={this.handleSTPic1hidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_54"
                                      className="choose_btn"
                                      id="choose_STPic1"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_54"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleSTPic1Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_STPic1"
                                      onClick={(e) => this.removeSTPic1(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_STPic1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__STPic1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.STPic1DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_STPic1"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* STPic2 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Passport size Photo (President or Secretary)
                                    (2)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PassportSizePhoto.jpeg"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="STPic2seecommentsbtn"
                                      onClick={this.handleSTPic2seecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="STPic2hidecommentsbtn"
                                      onClick={this.handleSTPic2hidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_55"
                                      className="choose_btn"
                                      id="choose_STPic2"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_55"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleSTPic2Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_STPic2"
                                      onClick={(e) => this.removeSTPic2(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_STPic2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__STPic2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.STPic2DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_STPic2"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* STPan3 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    PAN card of Society / Trust / Club
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PANCard.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="STPan3seecommentsbtn"
                                      onClick={this.handleSTPan3seecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="STPan3hidecommentsbtn"
                                      onClick={this.handleSTPan3hidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_56"
                                      className="choose_btn"
                                      id="choose_STPan3"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_56"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleSTPan3Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_STPan3"
                                      onClick={(e) => this.removeSTPan3(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_STPan3"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__STPan3"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.STPan3DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_STPan3"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* STCR */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Society Registration Certificate
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/CertificateOfRegistration.pdf "
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="STCRseecommentsbtn"
                                      onClick={this.handleSTCRseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="STCRhidecommentsbtn"
                                      onClick={this.handleSTCRhidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_57"
                                      className="choose_btn"
                                      id="choose_STCR"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_57"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) => this.handleSTCRChange(e)}
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_STCR"
                                      onClick={(e) => this.removeSTCR(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_STCR"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__STCR"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.STCRDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_STCR"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* STBP */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Bank Proof
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    Bank statement or a cancelled cheque
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/BankProof.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="STBPseecommentsbtn"
                                      onClick={this.handleSTBPseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="STBPhidecommentsbtn"
                                      onClick={this.handleSTBPhidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_58"
                                      className="choose_btn"
                                      id="choose_STBP"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_58"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) => this.handleSTBPChange(e)}
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_STBP"
                                      onClick={(e) => this.removeSTBP(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_STBP"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__STBP"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.STBPDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_STBP"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* STBR */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Board Resolution
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    In favor of authorized signatory
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/BoardResolution.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="STBRseecommentsbtn"
                                      onClick={this.handleSTBRseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="STBRhidecommentsbtn"
                                      onClick={this.handleSTBRhidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_59"
                                      className="choose_btn"
                                      id="choose_STBR"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_59"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) => this.handleSTBRChange(e)}
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_STBR"
                                      onClick={(e) => this.removeSTBR(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_STBR"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__STBR"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.STBRDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_STBR"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* STGumasta */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Shop & Establishment Licence (Gumasta)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/Gumasta.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="STGumastaseecommentsbtn"
                                      onClick={
                                        this.handleSTGumastaseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="STGumastahidecommentsbtn"
                                      onClick={
                                        this.handleSTGumastahidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_60"
                                      className="choose_btn"
                                      id="choose_STGumasta"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_60"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleSTGumastaChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_STGumasta"
                                      onClick={(e) => this.removeSTGumasta(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_STGumasta"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__STGumasta"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.STGumastaDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_STGumasta"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* STTradeLicence */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Trade Licence (Municipal Corporation)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/TradeLicence.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="STTradeLicenceseecommentsbtn"
                                      onClick={
                                        this.handleSTTradeLicenceseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="STTradeLicencehidecommentsbtn"
                                      onClick={
                                        this.handleSTTradeLicencehidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_61"
                                      className="choose_btn"
                                      id="choose_STTradeLicence"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_61"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleSTTradeLicenceChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_STTradeLicence"
                                      onClick={(e) =>
                                        this.removeSTTradeLicence(e)
                                      }
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_STTradeLicence"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__STTradeLicence"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.STTradeLicenceDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_STTradeLicence"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* STNature */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <p className="uploadnormaltext">
                                Nature of your Business / Brand:-
                                <sup className="requireddocstar">*</sup>
                              </p>
                              <textarea
                                className="uploadtextarea"
                                placeholder="Ex:- Kirana Shop / Shoes Brand / Hardware company..."
                                value={this.state.STbusinessNature}
                                onChange={this.handleSTbusinessNatureChange}
                              ></textarea>
                            </div>
                          </div>

                          {/* STAddressProofType */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <p className="uploadnormaltext">
                                Office address proof:
                                <sup className="requireddocstar">*</sup>
                              </p>
                              <input
                                type="radio"
                                id="test5-1"
                                name="radio-group5"
                                value="CopyOfRegistry"
                                checked={
                                  this.state.STAddressProofType_radio ===
                                  "CopyOfRegistry"
                                }
                                onChange={this.STAddressProofType_radioChange}
                              />
                              <label
                                htmlFor="test5-1"
                                className="uploadradiotext"
                              >
                                Own office
                              </label>
                              <br />
                              <input
                                type="radio"
                                id="test5-2"
                                name="radio-group5"
                                value="RentAgreement"
                                checked={
                                  this.state.STAddressProofType_radio ===
                                  "RentAgreement"
                                }
                                onChange={this.STAddressProofType_radioChange}
                              />
                              <label
                                htmlFor="test5-2"
                                className="uploadradiotext"
                              >
                                Rented office
                              </label>
                            </div>
                          </div>

                          {/* STCopyOfRegistry */}
                          <div className="displayflex CopyOfRegistry">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Copy of Registry
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/CopyOfRegistry.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="STCopyOfRegistryseecommentsbtn"
                                      onClick={
                                        this
                                          .handleSTCopyOfRegistryseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="STCopyOfRegistryhidecommentsbtn"
                                      onClick={
                                        this
                                          .handleSTCopyOfRegistryhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_62"
                                      className="choose_btn"
                                      id="choose_STCopyOfRegistry"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_62"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleSTCopyOfRegistryChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_STCopyOfRegistry"
                                      onClick={(e) =>
                                        this.removeSTCopyOfRegistry(e)
                                      }
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_STCopyOfRegistry"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__STCopyOfRegistry"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.STCopyOfRegistryDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_STCopyOfRegistry"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* STRentAgreement */}
                          <div className="displayflex RentAgreement">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Rent agreement
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/RentAgreement.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="STRentAgreementseecommentsbtn"
                                      onClick={
                                        this.handleSTRentAgreementseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="STRentAgreementhidecommentsbtn"
                                      onClick={
                                        this
                                          .handleSTRentAgreementhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_63"
                                      className="choose_btn"
                                      id="choose_STRentAgreement"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_63"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleSTRentAgreementChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_STRentAgreement"
                                      onClick={(e) =>
                                        this.removeSTRentAgreement(e)
                                      }
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_STRentAgreement"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__STRentAgreement"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.STRentAgreementDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_STRentAgreement"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* STPR */}
                          <div className="displayflex lastdisplayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploadlastdatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Property Tax Receipt
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    (in the name of owner)
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PropertyTaxReceipt.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="STPRseecommentsbtn"
                                      onClick={this.handleSTPRseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="STPRhidecommentsbtn"
                                      onClick={this.handleSTPRhidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_64"
                                      className="choose_btn"
                                      id="choose_STPR"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_64"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) => this.handleSTPRChange(e)}
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_STPR"
                                      onClick={(e) => this.removeSTPR(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_STPR"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn__STPR"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.STPRDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_STPR"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* STAdditional */}
                          <div id="STadditionalDocumentDiv">
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadadditionalsidetext visibilityhidden">
                                  1&#41;
                                </p>
                              </div>
                              <div className="uploadadditionaldatacol">
                                <p className="uploadadditionalnormaltext">
                                  Additional documents:-
                                </p>
                              </div>
                            </div>
                            {this.STadditionalFilesArea()}

                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadadditionalsidetext visibilityhidden">
                                  1&#41;
                                </p>
                              </div>
                              <div className="uploadadditionaldatacol">
                                <button
                                  className="adddocumentbtn"
                                  id="STAdddocumentbtn"
                                  onClick={() =>
                                    this.handleAddSTFileBtn(
                                      STadditionalFilesIndex
                                    )
                                  }
                                >
                                  Add document{" "}
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Path 19524.svg"
                                    }
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <center>
                        <button
                          className="proceednowbtn proceednowbtn5"
                          onClick={this.handleSTProceedBtn}
                        >
                          Proceed now{" "}
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/tick.svg"}
                          />
                        </button>
                      </center>
                    </div>
                  </div>
                  {/* comments */}
                  <div className="col-lg-3 colbtwborder">
                    <div className="commentimg-section">
                      <SupportDetails
                        name={this.state.Name}
                        role={"Relationship Manager"}
                        contact={this.state.Contact}
                      />
                           <div className='supportDetails'>
                      <img
                        src={process.env.PUBLIC_URL + "/img/24-7_Support.png"}

                        alt="image"
                      />
                      <div>
                        <h5>{supportName}</h5>
                        <p>Agent Support Executive</p>
                        <span><b>Contact: </b> +91 {supportContact}</span>
                        <p className='mb-1'><b>Working Hours: </b> 11:00 AM - 06:30 PM</p>
                      </div>
                    </div>
                      <div className="mm">
                        <center>
                          <a
                            className="video-btn"
                            data-toggle="modal"
                            data-src="https://www.youtube.com/embed/fiC-nHoFJEY"
                            data-target="#youtubeModal"
                          >
                            <img
                              src={process.env.PUBLIC_URL + "/img/play.jpg"}
                              id="play"
                              className="play_img play_img2"
                              alt="image"
                            />
                          </a>
                        </center>
                      </div>

                      <button  data-toggle="modal" onClick={this.handletimeline}  data-src="" data-target="#timeline"  className="dashBoardBtn mt-5 newbmar" >
                        Timeline
                      </button>

                      <p class="uploadinstr mt-2 uploddoc">You can also upload documents using Whatsapp on <a
                                  href="https://web.whatsapp.com/send?phone=917772877729&amp;text=Hello%20Team%20Legal251!"
                                  target="_blank"
                                >
                                  {" "}
                                  <img
                                    src={process.env.PUBLIC_URL + "/img/call.svg"}
                                    alt="call"
                                    className="callimg"
                                  />
                                </a> +91 77728-77729</p>
                 
                    </div>
                    <div className="TDRightSide">
                      <div className="convheader">
                        <div>
                          <img
                            src={process.env.PUBLIC_URL + "/img/person.png"}
                            className="converpersonimg"
                          />
                        </div>
                        <div className="convheadercontdiv">
                          <p className="convservicename">{this.state.Name}</p>
                          <p className="convpersonname">Relationship Manager</p>
                          <p className="convuniquename">
                            Contact:{" "}
                            <span style={{ color: "#2d2d2d" }}>
                                  {this.state.Contact}
                            </span>
                          </p>
                          <p className="convuniquename">
                            Service ID :{" "}
                            <span className="convuniquenamespan">
                              {Decserviceid}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="convmaindiv">
                        {this.renderCommenthistory()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="tab2" className="tab-content">
                <div className="doucumainscrolldiv2">
                      <div className="doucumainscrolldivinside2">
                        {this.completerender()}
                      </div>
                    </div>
                </div>
            </div>
          </div>
          :<center>
            <img alt="notaccess" className="notuploaddoc" src={process.env.PUBLIC_URL + "/img/Warning-rafiki.svg"} />

            <h4 class="my-linkmodal-text youcant">You can't upload document because you're Referal Partner. Please contact support executive for any help and more information.</h4>
            </center>
          }
          </div>
          </div>
          <SupportDetailsMobile
            name={this.state.Name}
            role={"Relationship Manager"}
            contact={this.state.Contact}
          />

          <Footer />

          <div className="modal fade" id="SAadharModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SAadhar"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SAadhar"
                        onClick={this.uploadSAadhar}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SAadhar"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="SPanModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SPan"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SPan"
                        onClick={this.uploadSPan}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SPan"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="SPicModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SPic"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SPic"
                        onClick={this.uploadSPic}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SPic"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="SBPModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SBP"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SBP"
                        onClick={this.uploadSBP}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SBP"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="SGumastaModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SGumasta"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SGumasta"
                        onClick={this.uploadSGumasta}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SGumasta"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STradeLicenceModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STradeLicence"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STradeLicence"
                        onClick={this.uploadSTradeLicence}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STradeLicence"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="SCopyOfRegistryModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SCopyOfRegistry"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SCopyOfRegistry"
                        onClick={this.uploadSCopyOfRegistry}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SCopyOfRegistry"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="SRentAgreementModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SRentAgreement"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SRentAgreement"
                        onClick={this.uploadSRentAgreement}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SRentAgreement"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="SPRModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SPR"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SPR"
                        onClick={this.uploadSPR}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SPR"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade SAddModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    maxLength="250"
                    placeholder="Type here..."
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn cancelbtn_7"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20 upload_btn20_7"
                        onClick={() =>
                          this.uploadSFile(
                            SadditionalReuploadIndex,
                            SadditionalReuploadStatus
                          )
                        }
                      >
                        Upload
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />
                      </button>
                      <button className="upload_btnloader upload_btnloader_7">
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Partnership */}
          <div className="modal fade" id="PAadhar1Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PAadhar1"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PAadhar1"
                        onClick={this.uploadPAadhar1}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PAadhar1"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PAadhar2Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PAadhar2"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PAadhar2"
                        onClick={this.uploadPAadhar2}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PAadhar2"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PPan1Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PPan1"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PPan1"
                        onClick={this.uploadPPan1}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PPan1"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PPan2Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PPan2"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PPan2"
                        onClick={this.uploadPPan2}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PPan2"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PPic1Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PPic1"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PPic1"
                        onClick={this.uploadPPic1}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PPic1"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PPic2Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PPic2"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PPic2"
                        onClick={this.uploadPPic2}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PPic2"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PBPModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PBP"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PBP"
                        onClick={this.uploadPBP}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PBP"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PGumastaModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PGumasta"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PGumasta"
                        onClick={this.uploadPGumasta}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PGumasta"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PTradeLicenceModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PTradeLicence"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PTradeLicence"
                        onClick={this.uploadPTradeLicence}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PTradeLicence"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="PDeedModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PDeed"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PDeed"
                        onClick={this.uploadPDeed}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PDeed"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="PCopyOfRegistryModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PCopyOfRegistry"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PCopyOfRegistry"
                        onClick={this.uploadPCopyOfRegistry}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PCopyOfRegistry"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PRentAgreementModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PRentAgreement"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PRentAgreement"
                        onClick={this.uploadPRentAgreement}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PRentAgreement"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PPRModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PPR"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PPR"
                        onClick={this.uploadPPR}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PPR"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade PAddModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    maxLength="250"
                    placeholder="Type here..."
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn cancelbtn_7"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20 upload_btn20_7"
                        onClick={() =>
                          this.uploadPFile(
                            PadditionalReuploadIndex,
                            PadditionalReuploadStatus
                          )
                        }
                      >
                        Upload
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />
                      </button>
                      <button className="upload_btnloader upload_btnloader_7">
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLAadhar1Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLAadhar1"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLAadhar1"
                        onClick={this.uploadPLAadhar1}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLAadhar1"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLAadhar2Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLAadhar2"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLAadhar2"
                        onClick={this.uploadPLAadhar2}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLAadhar2"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLPan1Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLPan1"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLPan1"
                        onClick={this.uploadPLPan1}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLPan1"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLPan2Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLPan2"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLPan2"
                        onClick={this.uploadPLPan2}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLPan2"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLPic1Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLPic1"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLPic1"
                        onClick={this.uploadPLPic1}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLPic1"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLPic2Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLPic2"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLPic2"
                        onClick={this.uploadPLPic2}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLPic2"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLPan3Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLPan3"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLPan3"
                        onClick={this.uploadPLPan3}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLPan3"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLCIModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLCI"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLCI"
                        onClick={this.uploadPLCI}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLCI"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLMOAModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLMOA"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLMOA"
                        onClick={this.uploadPLMOA}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLMOA"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLAOAModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLAOA"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLAOA"
                        onClick={this.uploadPLAOA}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLAOA"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLBRModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLBR"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLBR"
                        onClick={this.uploadPLBR}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLBR"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLBPModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLBP"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLBP"
                        onClick={this.uploadPLBP}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLBP"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLGumastaModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLGumasta"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLGumasta"
                        onClick={this.uploadPLGumasta}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLGumasta"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLTradeLicenceModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLTradeLicence"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLTradeLicence"
                        onClick={this.uploadPLTradeLicence}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLTradeLicence"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLCopyOfRegistryModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLCopyOfRegistry"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLCopyOfRegistry"
                        onClick={this.uploadPLCopyOfRegistry}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLCopyOfRegistry"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLRentAgreementModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLRentAgreement"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLRentAgreement"
                        onClick={this.uploadPLRentAgreement}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLRentAgreement"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLPRModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLPR"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLPR"
                        onClick={this.uploadPLPR}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLPR"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade PLAddModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    maxLength="250"
                    placeholder="Type here..."
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn cancelbtn_7"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20 upload_btn20_7"
                        onClick={() =>
                          this.uploadPLFile(
                            PLadditionalReuploadIndex,
                            PLadditionalReuploadStatus
                          )
                        }
                      >
                        Upload
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />
                      </button>
                      <button className="upload_btnloader upload_btnloader_7">
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFAadharModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFAadhar"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFAadhar"
                        onClick={this.uploadHUFAadhar}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFAadhar"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFPan1Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFPan1"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFPan1"
                        onClick={this.uploadHUFPan1}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFPan1"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFPicModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFPic"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFPic"
                        onClick={this.uploadHUFPic}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFPic"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFPan2Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFPan2"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFPan2"
                        onClick={this.uploadHUFPan2}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFPan2"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFDeedModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFDeed"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFDeed"
                        onClick={this.uploadHUFDeed}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFDeed"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFBPModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFBP"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFBP"
                        onClick={this.uploadHUFBP}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFBP"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFGumastaModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFGumasta"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFGumasta"
                        onClick={this.uploadHUFGumasta}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFGumasta"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFTradeLicenceModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFTradeLicence"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFTradeLicence"
                        onClick={this.uploadHUFTradeLicence}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFTradeLicence"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFCopyOfRegistryModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFCopyOfRegistry"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFCopyOfRegistry"
                        onClick={this.uploadHUFCopyOfRegistry}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFCopyOfRegistry"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFRentAgreementModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFRentAgreement"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFRentAgreement"
                        onClick={this.uploadHUFRentAgreement}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFRentAgreement"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFPRModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFPR"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFPR"
                        onClick={this.uploadHUFPR}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFPR"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade HUFAddModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    maxLength="250"
                    placeholder="Type here..."
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn cancelbtn_7"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20 upload_btn20_7"
                        onClick={() =>
                          this.uploadHUFFile(
                            HUFadditionalReuploadIndex,
                            HUFadditionalReuploadStatus
                          )
                        }
                      >
                        Upload
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />
                      </button>
                      <button className="upload_btnloader upload_btnloader_7">
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STAadhar1Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STAadhar1"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STAadhar1"
                        onClick={this.uploadSTAadhar1}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STAadhar1"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STAadhar2Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STAadhar2"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STAadhar2"
                        onClick={this.uploadSTAadhar2}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STAadhar2"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STPan1Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STPan1"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STPan1"
                        onClick={this.uploadSTPan1}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STPan1"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STPan2Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STPan2"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STPan2"
                        onClick={this.uploadSTPan2}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STPan2"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STPic1Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STPic1"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STPic1"
                        onClick={this.uploadSTPic1}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STPic1"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STPic2Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STPic2"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STPic2"
                        onClick={this.uploadSTPic2}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STPic2"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STPan3Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STPan3"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STPan3"
                        onClick={this.uploadSTPan3}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STPan3"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STCRModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STCR"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STCR"
                        onClick={this.uploadSTCR}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STCR"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STBPModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STBP"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STBP"
                        onClick={this.uploadSTBP}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STBP"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STBRModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STBR"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STBR"
                        onClick={this.uploadSTBR}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STBR"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STGumastaModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STGumasta"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STGumasta"
                        onClick={this.uploadSTGumasta}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STGumasta"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STTradeLicenceModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STTradeLicence"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STTradeLicence"
                        onClick={this.uploadSTTradeLicence}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STTradeLicence"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STCopyOfRegistryModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STCopyOfRegistry"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STCopyOfRegistry"
                        onClick={this.uploadSTCopyOfRegistry}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STCopyOfRegistry"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STRentAgreementModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STRentAgreement"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STRentAgreement"
                        onClick={this.uploadSTRentAgreement}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STRentAgreement"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STPRModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STPR"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STPR"
                        onClick={this.uploadSTPR}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STPR"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="youtubeModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered yt_modal "
              role="document"
            >
              <div className="modal-content">
                <div className="modal-body modal_b">
                  <button
                    type="button"
                    className="close close1"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      className="embed-responsive-item"
                      id="video"
                      allowscriptaccess="always"
                      src="https://www.youtube.com/embed/fiC-nHoFJEY?&amp;modestbranding=1&amp;showinfo=0"
                      allowFullScreen="allowfullscreen"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade STAddModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    maxLength="250"
                    placeholder="Type here..."
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn cancelbtn_7"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20 upload_btn20_7"
                        onClick={() =>
                          this.uploadSTFile(
                            STadditionalReuploadIndex,
                            STadditionalReuploadStatus
                          )
                        }
                      >
                        Upload
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />
                      </button>
                      <button className="upload_btnloader upload_btnloader_7">
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade"
            id="timeline"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true">

            <div
              className="modal-dialog modal-dialog-centered yt_modal "
              role="document"
            >
              <div className="modal-content videoModal">
                <div className="modal-body modal_b mt-4 ">
                  <button
                    type="button"
                    className="close close1 close1tl closeMain mr-2 dbtime"
                    data-dismiss="modal"
                    aria-label="Close"

                  >
                    <span aria-hidden="true">&times;</span>
                  </button>

                  {this.state.Timelinedata ?


                    this.state.Timeline.length > 0 ?

                      <div class="timeline scrollbtimel">
                        <div class="container">
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="timeline-container">
                                <div class="timeline-end">
                                  <button id="load-more" onClick={this.handlemoretimeline} >More..</button>
                                </div>
                                <div class="timeline-continue">

                                  {this.state.filtertimel.map((item, index) => {

                                    if (index % 2 == 0) {
                                      return (

                                        <div class="row timeline-left">
                                          <div class="col-md-6 d-md-none d-block">
                                            <p class="timeline-date">

                                            </p>
                                          </div>
                                          <div class="col-md-6">
                                            <div class="timeline-box">
                                              <div class="timeline-icon d-md-none d-block">
                                                <i class="fa fa-business-time"></i>
                                              </div>

                                              <div class="timeline-text">
                                                <p class="mainSpan">{item.time}</p>
                                                <h3>{item.timeLine}</h3>
                                                <p class="mode_p">Date : <span class="mode_span"> {item.date}</span></p>
                                                <h5 className="tlremark">{item.remark}</h5>
                                                {/* <p class="mode_p">
                                                      <span class="mode_span">
                                                          {{$TimeLine->TimeLine}}
                                                      </span>
                                                  </p>  */}


                                              </div>

                                              <div class="timeline-icon d-md-block d-none">
                                                <i class="fa fa-business-time"></i>
                                              </div>

                                            </div>
                                          </div>
                                          <div class="col-md-6 d-md-block d-none">
                                            <p class="timeline-date">

                                            </p>
                                          </div>
                                        </div>

                                      )
                                    } else {
                                      return (
                                        <div class="row timeline-right">
                                          <div class="col-md-6">
                                            <p class="timeline-date">
                                            </p>
                                          </div>
                                          <div class="col-md-6">
                                            <div class="timeline-box">
                                              <div class="timeline-icon">
                                                <i class="fa fa-gift"></i>
                                              </div>

                                              <div class="timeline-text">
                                                <p class="mainSpan">{item.time}</p>
                                                {/* <p class="">khkh</p> */}

                                                <h3>{item.timeLine}</h3>
                                                <p class="mode_p">Date : <span class="mode_span">{item.date} </span></p>
                                                <h5 className="tlremark">{item.remark}</h5>
                                                {/* <p class="mode_p">
                                                                              <span class="mode_span">
                                                                                  
                                                                              </span>
                                                                          </p> */}


                                              </div>

                                            </div>
                                          </div>
                                        </div>
                                      )
                                    }




                                  })}




                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      <center className="">
                        <img
                          alt="loading..."
                          src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
                          className="emptydeliveredDocuments"
                        />
                      </center>

                    : null
                  }
                  <center>
                    <img
                      className="timelineloder"
                      src={process.env.PUBLIC_URL + "/img/loader.gif"}
                      alt="loading"
                    />
                  </center>
                </div>
              </div>
            </div>

          </div>

        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}
export default Activegstr;

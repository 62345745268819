import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import SupportDetails from "./SupportDetails";
import SupportDetailsMobile from "./SupportDetailsMobile";
import JSZipUtils from "jszip-utils";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { generatetotp } from "./utils.js";

var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);
var today = new Date();
const year = today.getFullYear();
const Currmonth = today.getMonth() + 1;
let type;
let Decserviceid;
let Decservicename;
let Decplan;
let Decagentid;
let Decuserid;
let Decjwttoken;
let temp = "general";
let mainaction = "upload";
let ChooseNowBtnsDisable = "No";
let rejectstatus = "No";
let OptReuploadCountS = 0;
let OptReuploadCountP = 0;
let OptReuploadCountPF = 0;
let OptReuploadCountCL = 0;
let POptReupload = 0;
let SOptReupload = 0;
let PFOptReupload = 0;
let CLOptReupload = 0;
let supportName;
let supportContact;
let supportEmail;

// s
let mainactionSAadhar = "upload";
let SAadharStatus;
let mainactionSPan = "upload";
let SPanStatus;
let mainactionSBp = "upload";
let SBpStatus;
let mainactionSPBS = "upload";
let SPBSStatus;
let mainactionSPR = "upload";
let SPRStatus;
let mainactionSBankS = "upload";
let SBankSStatus;
let mainactionSF16 = "upload";
let SF16Status;
let SincDadditionalReupload = false;
let SincDAdditionalFilesIndex;
let SincDadditionalReuploadIndex;
let SincDadditionalReuploadStatus;
let SincDAdditionalFilesNames = [];
let SadditionalReupload = false;
let SAdditionalFilesIndex;
let SadditionalReuploadIndex;
let SadditionalReuploadStatus;
let SAdditionalFilesNames = [];
let SStatusArray = [
  {
    doc: "SAadharStatus",
    status: SAadharStatus,
  },
  {
    doc: "SPanStatus",
    status: SPanStatus,
  },
  {
    doc: "SBpStatus",
    status: SBpStatus,
  },
];

let SOptStatusArray = [
  {
    doc: "SPBSStatus",
    status: SPBSStatus,
  },
  {
    doc: "SPRStatus",
    status: SPRStatus,
  },
  {
    doc: "SF16Status",
    status: SF16Status,
  },
  {
    doc: "SBankSStatus",
    status: SBankSStatus,
  },
];
let SincDadditionalStatusArrayCheck;
let SadditionalStatusArrayCheck;
let anySincDAdditionalReuploadRemaining;
let anySAdditionalReuploadRemaining;
let SOptReview = "No";
let AnyReuploadOccuredS = "No";
let Sproceednowforword;

// P
let mainactionPAadhar = "upload";
let PAadharStatus;
let mainactionPPan = "upload";
let PPanStatus;
let mainactionPBP = "upload";
let PBPStatus;
let mainactionPBS = "upload";
let PBSStatus;
let mainactionPPR = "upload";
let PPRStatus;
let PincDadditionalReupload = false;
let PincDAdditionalFilesIndex;
let PincDadditionalReuploadIndex;
let PincDadditionalReuploadStatus;
let PincDAdditionalFilesNames = [];
let mainactionPData = "upload";
let PDataStatus;
let mainactionPBankS = "upload";
let PBankSStatus;
let mainactionPGSTReturn = "upload";
let PGSTReturnStatus;
let mainactionPTDSReturn = "upload";
let PTDSReturnStatus;
let mainactionPChallan = "upload";
let PChallanStatus;
let PadditionalReupload = false;
let PAdditionalFilesIndex;
let PadditionalReuploadIndex;
let PadditionalReuploadStatus;
let PAdditionalFilesNames = [];
let PStatusArray = [
  {
    doc: "PAadharStatus",
    status: PAadharStatus,
  },
  {
    doc: "PPanStatus",
    status: PPanStatus,
  },
  {
    doc: "PBPStatus",
    status: PBPStatus,
  },
  {
    doc: "PDataStatus",
    status: PDataStatus,
  },
  {
    doc: "PBankSStatus",
    status: PBankSStatus,
  },
];

let POptStatusArray = [
  {
    doc: "PBSStatus",
    status: PBSStatus,
  },
  {
    doc: "PPRStatus",
    status: PPRStatus,
  },
  {
    doc: "PGSTReturnStatus",
    status: PGSTReturnStatus,
  },
  {
    doc: "PTDSReturnStatus",
    status: PTDSReturnStatus,
  },
  {
    doc: "PChallanStatus",
    status: PChallanStatus,
  },
];
let PincDadditionalStatusArrayCheck;
let PadditionalStatusArrayCheck;
let anyPincDAdditionalReuploadRemaining;
let anyPAdditionalReuploadRemaining;
let POptReview = "No";
let AnyReuploadOccuredP = "No";
let Pproceednowforword;

// PF
let mainactionPFAadhar1 = "upload";
let PFAadhar1Status;
let mainactionPFAadhar2 = "upload";
let PFAadhar2Status;
let mainactionPFPan1 = "upload";
let PFPan1Status;
let mainactionPFPan2 = "upload";
let PFPan2Status;
let mainactionPFDeed = "upload";
let PFDeedStatus;
let mainactionPFBP = "upload";
let PFBPStatus;
let mainactionPFRC = "upload";
let PFRCStatus;
let mainactionPFPBS_A = "upload";
let PFPBS_AStatus;
let mainactionPFPR = "upload";
let PFPRStatus;
let PFincDadditionalReupload = false;
let PFincDAdditionalFilesIndex;
let PFincDadditionalReuploadIndex;
let PFincDadditionalReuploadStatus;
let PFincDAdditionalFilesNames = [];
let mainactionPFData = "upload";
let PFDataStatus;
let mainactionPFBankS = "upload";
let PFBankSStatus;
let mainactionPFGSTReturn = "upload";
let PFGSTReturnStatus;
let mainactionPFTDSReturn = "upload";
let PFTDSReturnStatus;
let mainactionPFChallan = "upload";
let PFChallanStatus;
let PFadditionalReupload = false;
let PFAdditionalFilesIndex;
let PFadditionalReuploadIndex;
let PFadditionalReuploadStatus;
let PFAdditionalFilesNames = [];
let PFStatusArray = [
  {
    doc: "PFAadhar1Status",
    status: PFAadhar1Status,
  },
  {
    doc: "PFAadhar2Status",
    status: PFAadhar2Status,
  },
  {
    doc: "PFPan1Status",
    status: PFPan1Status,
  },
  {
    doc: "PFPan2Status",
    status: PFPan2Status,
  },
  {
    doc: "PFDeedStatus",
    status: PFDeedStatus,
  },
  {
    doc: "PFBPStatus",
    status: PFBPStatus,
  },
  {
    doc: "PFDataStatus",
    status: PFDataStatus,
  },
  {
    doc: "PFBankSStatus",
    status: PFBankSStatus,
  },
];
let PFOptStatusArray = [
  {
    doc: "PFPBS_AStatus",
    status: PFPBS_AStatus,
  },
  {
    doc: "PFPRStatus",
    status: PFPRStatus,
  },
  {
    doc: "PFGSTReturnStatus",
    status: PFGSTReturnStatus,
  },
  {
    doc: "PFTDSReturnStatus",
    status: PFTDSReturnStatus,
  },
  {
    doc: "PFChallanStatus",
    status: PFChallanStatus,
  },
  {
    doc: "PFRCStatus",
    status: PFRCStatus,
  },
];
let PFincDadditionalStatusArrayCheck;
let PFadditionalStatusArrayCheck;
let anyPFincDAdditionalReuploadRemaining;
let anyPFAdditionalReuploadRemaining;
let PFOptReview = "No";
let AnyReuploadOccuredPF = "No";
let PFproceednowforword;

// CL
let mainactionCLAadhar1 = "upload";
let CLAadhar1Status;
let mainactionCLAadhar2 = "upload";
let CLAadhar2Status;
let mainactionCLPan1 = "upload";
let CLPan1Status;
let mainactionCLPan2 = "upload";
let CLPan2Status;
let mainactionCLIC = "upload";
let CLICStatus;
let mainactionCLMOA = "upload";
let CLMOAStatus;
let mainactionCLAOA = "upload";
let CLAOAStatus;
let mainactionCLBP = "upload";
let CLBPStatus;
let mainactionCLPBS_A = "upload";
let CLPBS_AStatus;
let mainactionCLPR = "upload";
let CLPRStatus;
let CLincDadditionalReupload = false;
let CLincDAdditionalFilesIndex;
let CLincDadditionalReuploadIndex;
let CLincDadditionalReuploadStatus;
let CLincDAdditionalFilesNames = [];
let mainactionCLData = "upload";
let CLDataStatus;
let mainactionCLBankS = "upload";
let CLBankSStatus;
let mainactionCLGSTReturn = "upload";
let CLGSTReturnStatus;
let mainactionCLTDSReturn = "upload";
let CLTDSReturnStatus;
let mainactionCLChallan = "upload";
let CLChallanStatus;
let CLadditionalReupload = false;
let CLAdditionalFilesIndex;
let CLadditionalReuploadIndex;
let CLadditionalReuploadStatus;
let CLAdditionalFilesNames = [];
let CLStatusArray = [
  {
    doc: "CLAadhar1Status",
    status: CLAadhar1Status,
  },
  {
    doc: "CLAadhar2Status",
    status: CLAadhar2Status,
  },
  {
    doc: "CLPan1Status",
    status: CLPan1Status,
  },
  {
    doc: "CLPan2Status",
    status: CLPan2Status,
  },
  {
    doc: "CLICStatus",
    status: CLICStatus,
  },
  {
    doc: "CLMOAStatus",
    status: CLMOAStatus,
  },
  {
    doc: "CLAOAStatus",
    status: CLAOAStatus,
  },
  {
    doc: "CLBPStatus",
    status: CLBPStatus,
  },
  {
    doc: "CLDataStatus",
    status: CLDataStatus,
  },
  {
    doc: "CLBankSStatus",
    status: CLBankSStatus,
  },
];
let CLOptStatusArray = [
  {
    doc: "CLPBS_AStatus",
    status: CLPBS_AStatus,
  },
  {
    doc: "CLPRStatus",
    status: CLPRStatus,
  },
  {
    doc: "CLGSTReturnStatus",
    status: CLGSTReturnStatus,
  },
  {
    doc: "CLTDSReturnStatus",
    status: CLTDSReturnStatus,
  },
  {
    doc: "CLChallanStatus",
    status: CLChallanStatus,
  },
];
let CLincDadditionalStatusArrayCheck;
let CLadditionalStatusArrayCheck;
let anyCLincDAdditionalReuploadRemaining;
let anyCLAdditionalReuploadRemaining;
let AnyReuploadOccuredCL = "No";
let CLOptReview = "No";
let CLproceednowforword;

export class Activeitrf extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // general
      dropDownValue: "",
      Fyear: "",
      Preyear: "",
      thyear:"",
      SelectedYear: "",
      serviceType: "",
      fileName: "File Name",
      fileBase64: "",
      documentType: "",
      documentName: "",
      comment: "",
      commentHistroyarray: [],
      // S
      SAadharDocLink: "#",
      SPanDocLink: "#",
      SBpDocLink: "#",
      SPBSDocLink: "#",
      SPRDocLink: "#",
      SBankSDocLink: "#",
      SF16DocLink: "",
      IsSincDAddFileDisabled: true,
      SincDAdditionalFiles: [],
      api_SincDAdditionalFiles: "No",
      SincDAdd_DocName: "",
      IsSAddFileDisabled: true,
      SAdditionalFiles: [],
      api_SAdditionalFiles: "No",
      SAdd_DocName: "",
      // P
      PAadharDocLink: "#",
      PPanDocLink: "#",
      PBPDocLink: "#",
      PBSDocLink: "#",
      PPRDocLink: "#",
      IsPincDAddFileDisabled: true,
      PincDAdditionalFiles: [],
      api_PincDAdditionalFiles: "No",
      PincDAdd_DocName: "",
      PDataDocLink: "#",
      PBankSDocLink: "#",
      PGSTReturnDocLink: "#",
      PTDSReturnDocLink: "#",
      PChallanDocLink: "#",
      IsPAddFileDisabled: true,
      PAdditionalFiles: [],
      api_PAdditionalFiles: "No",
      PAdd_DocName: "",
      // PF
      PFAadhar1DocLink: "#",
      PFAadhar2DocLink: "#",
      PFPan1DocLink: "#",
      PFPan2DocLink: "#",
      PFDeedDocLink: "#",
      PFBPDocLink: "#",
      PFRCDocLink: "#",
      PFPBS_ADocLink: "#",
      PFPRDocLink: "#",
      IsPFincDAddFileDisabled: true,
      PFincDAdditionalFiles: [],
      api_PFincDAdditionalFiles: "No",
      PFincDAdd_DocName: "",
      PFDataDocLink: "#",
      PFBankSDocLink: "#",
      PFGSTReturnDocLink: "#",
      PFTDSReturnDocLink: "#",
      PFChallanDocLink: "#",
      IsPFAddFileDisabled: true,
      PFAdditionalFiles: [],
      api_PFAdditionalFiles: "No",
      PFAdd_DocName: "",
      // CL
      CLAadhar1DocLink: "#",
      CLAadhar2DocLink: "#",
      CLPan1DocLink: "#",
      CLPan2DocLink: "#",
      CLICDocLink: "#",
      CLMOADocLink: "#",
      CLAOADocLink: "#",
      CLPBS_ADocLink: "#",
      CLPRDocLink: "#",
      IsCLincDAddFileDisabled: true,
      CLincDAdditionalFiles: [],
      api_CLincDAdditionalFiles: "No",
      CLincDAdd_DocName: "",
      CLDataDocLink: "#",
      CLBankSDocLink: "#",
      CLGSTReturnDocLink: "#",
      CLTDSReturnDocLink: "#",
      CLChallanDocLink: "#",
      IsCLAddFileDisabled: true,
      CLAdditionalFiles: [],
      api_CLAdditionalFiles: "No",
      CLAdd_DocName: "",

      itrfusername: "",
      itrfpassword: "",
      showpassword: false,
      Name: "",
      Contact: "",
      Timeline: [],
      Timelinedata: false,
      Timeline: [],
      timelcount: 2,
      filtertimel: [],
      deliveredDocuments: [],
      referralPartner: true
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    $(document.body).css("background-color", "#2d2d2d");
    let mainuserid = localStorage.getItem("relleIsugad");
    Decuserid = encryptor.decrypt(mainuserid);
    let mainagentid = localStorage.getItem("qazxswedcvfrtgb");
    Decagentid = encryptor.decrypt(mainagentid);
    let mainjwttoken = localStorage.getItem("toeljgtkewlna");
    Decjwttoken = encryptor.decrypt(mainjwttoken);
    let mainserviceid = localStorage.getItem("vrellvaiecstiadeceig");
    let mainaservicename = localStorage.getItem("eieltaiclsraaemevcvnge");
    let mainplan = localStorage.getItem("ipacneglatvlael");
    supportName = localStorage.getItem("supportName");
    supportContact = localStorage.getItem("supportContact");
    supportEmail = localStorage.getItem("supportEmail");

    this.setState({
      supportName: supportName,
      supportContact: supportContact,
      supportEmail: supportEmail,
    });


    AnyReuploadOccuredS="No"
    AnyReuploadOccuredP="No"
    AnyReuploadOccuredPF="No"
    AnyReuploadOccuredCL="No"

    if(this.state.referralPartner == true){
      const script = document.createElement("script");
      script.src = "/js/main.js";
      script.async = true;
      document.body.appendChild(script);
    }
    // ----------------------------temporary -----------------------------------------

    let temptoken = localStorage.getItem("fdgshdjfkgjhyhdnxhsgdhskcj");
    let Dectemptoken = encryptor.decrypt(temptoken);
    let paramsUserId2 = this.props.match.params.id2;
    if (Dectemptoken != null && Dectemptoken == paramsUserId2) {
      temp = "temporary";
    } else {
      temp = "general";
    }
    // ----------------------------temporary -----------------------------------------
    if (mainserviceid != null && mainaservicename != null && mainplan != null) {
      let paramsId = this.props.match.params.id;
      let paramsUserId = this.props.match.params.id2;
      Decserviceid = encryptor.decrypt(mainserviceid);
      Decservicename = encryptor.decrypt(mainaservicename);
      Decplan = encryptor.decrypt(mainplan);
      if (paramsId != Decserviceid || paramsUserId != Decuserid) {
        this.props.history.push("/dashboard");
      }
    } else {
      this.props.history.push("/dashboard");
    }
    if (Currmonth >= 4) {
      this.setState(
        {
          Fyear: `${year}-${year + 1}`,
          Preyear: `${year - 1}-${year}`,
          thyear: `${year - 2}-${year -1}`,
          SelectedYear: `${year}-${year + 1}`,
        },
        () => {
          this.getserviceInformationApi();
          this.getdeliveredDocuments();
        }
      );
    } else if (Currmonth < 4) {
      this.setState(
        {
          Fyear: `${year - 1}-${year}`,
          Preyear: `${year - 2}-${year - 1}`,
          thyear: `${year - 3}-${year - 2}`,
          SelectedYear: `${year - 1}-${year}`,
        },
        () => {
          this.getserviceInformationApi();
          this.getdeliveredDocuments();
        }
      );
    }

    let str = (document.getElementsByClassName(
      "myservicesortbyfilterbystatus"
    ).innerHTML = this.props.location.pathname.replaceAll("/", " > "));
    let mainstr = (document.getElementsByClassName(
      "myservicesortbyfilterbystatus"
    ).innerHTML = str.replace(" > ", " "));
    $(".myservicesortbyfilterbystatus").html(mainstr);

    this.getnameanumber();
  }

  getnameanumber = () => {
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=empexecutiveDetails",
        {
          agentId: Decagentid,
          serviceName: Decservicename,
          serviceId: Decserviceid,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            Name: res.data.teamLeaderName,
            Contact: res.data.teamLeaderNumber,
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      });
  };

  logout = () => {
    localStorage.removeItem("gglltakoinoeenl");
    localStorage.removeItem("toeljgtkewlna");
    localStorage.removeItem("relleIsugad");
    this.props.history.push({
      pathname: `/`,
    });
  };
  getserviceInformationApi = () => {
    $(".serviceoverlay").show();
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=serviceInformation",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        $(".serviceoverlay").hide();
        if (res.data.code == "200" && res.data.status == "success") {
          if (res.data.deliveredStatus == "true") {
            this.setserviceInformationApi(res.data.oldServiceId);
          } else {
            ChooseNowBtnsDisable = "No";
            $(".upload-photo").attr("disabled", false);
            rejectstatus = "No";
            OptReuploadCountS = 0;
            OptReuploadCountP = 0;
            OptReuploadCountPF = 0;
            OptReuploadCountCL = 0;

            if (
              res.data.fyStatus == "clear" &&
              res.data.informationStatus == "clear"
            ) {
              $(".proceednowbtn1").attr("disabled", true);
              $(".proceednowbtn2").attr("disabled", true);
              $(".proceednowbtn3").attr("disabled", true);
              $(".proceednowbtn4").attr("disabled", true);
            }

            // dropdown values
            if (res.data.serviceType == null) {
              this.setState(
                {
                  dropDownValue: "volvo",
                  SincDAdd_DocName: "",
                  SincDAdditionalFiles: [1],
                  api_SincDAdditionalFiles: "No",
                  SAdd_DocName: "",
                  SAdditionalFiles: [1],
                  api_SAdditionalFiles: "No",
                  PincDAdd_DocName: "",
                  PincDAdditionalFiles: [1],
                  api_PincDAdditionalFiles: "No",
                  PAdd_DocName: "",
                  PAdditionalFiles: [1],
                  api_PAdditionalFiles: "No",
                  PFincDAdd_DocName: "",
                  PFincDAdditionalFiles: [1],
                  api_PFincDAdditionalFiles: "No",
                  PFAdd_DocName: "",
                  PFAdditionalFiles: [1],
                  api_PFAdditionalFiles: "No",
                  CLincDAdd_DocName: "",
                  CLincDAdditionalFiles: [1],
                  api_CLincDAdditionalFiles: "No",
                  CLAdd_DocName: "",
                  CLAdditionalFiles: [1],
                  api_CLAdditionalFiles: "No",
                },
                () => {
                  if (this.state.dropDownValue == "volvo") {
                    $("#Itrf_default_Img").show();
                    $("#ItrfSalariedSection").hide();
                    $("#ItrfproprietorSection").hide();
                    $("#ItrfPartnershipFirm").hide();
                    $("#ItrfCompany_LLP").hide();
                    SStatusArray.map((a) => (a.status = "open"));
                    SOptStatusArray.map((a) => (a.status = "open"));
                    PStatusArray.map((a) => (a.status = "open"));
                    POptStatusArray.map((a) => (a.status = "open"));
                    PFStatusArray.map((a) => (a.status = "open"));
                    PFOptStatusArray.map((a) => (a.status = "open"));
                    CLStatusArray.map((a) => (a.status = "open"));
                    CLOptStatusArray.map((a) => (a.status = "open"));
                  }
                }
              );
            } else if (res.data.serviceType == "ITRF_S_OneTime_Documents") {
              $(".selectcateselect1").attr("disabled", true);
              this.setState(
                {
                  dropDownValue: "ITRF_S_OneTime_Documents",
                  serviceType: "ITRF_S_OneTime_Documents",
                },
                () => {
                  if (this.state.dropDownValue == "ITRF_S_OneTime_Documents") {
                    $("#Itrf_default_Img").hide();
                    $("#ItrfSalariedSection").show();
                    $("#ItrfproprietorSection").hide();
                    $("#ItrfPartnershipFirm").hide();
                    $("#ItrfCompany_LLP").hide();
                  }
                }
              );
              if (res.data.documents.length > 0) {
                let doc = res.data.documents;
                if (doc[0].status == "remove") {
                  mainactionSF16 = "upload";
                  SF16Status = "remove";
                  SOptStatusArray[2].status = SF16Status;
                  this.setState(
                    {
                      SF16DocLink: doc[0].Form16,
                    },
                    () => {
                      $("#viewDocLink_SF16").css("display", "block");
                      $("#choose_SF16").hide();
                      $("#X-btn_SF16").show();
                      $("#done-btn_SF16").hide();
                      $("#review-btn_SF16").hide();
                    }
                  );
                } else if (doc[0].status == "review") {
                  mainactionSF16 = "upload";
                  SF16Status = "review";
                  SOptStatusArray[2].status = SF16Status;
                  this.setState(
                    {
                      SF16DocLink: doc[0].Form16,
                    },
                    () => {
                      $("#viewDocLink_SF16").css("display", "block");
                      $("#choose_SF16").hide();
                      $("#X-btn_SF16").hide();
                      $("#done-btn_SF16").hide();
                      $("#review-btn_SF16").show();
                    }
                  );
                } else if (doc[0].status == "reupload") {
                  mainactionSF16 = "reupload";
                  SF16Status = "reupload";
                  SOptStatusArray[2].status = SF16Status;
                  this.setState({}, () => {
                    $("#choose_SF16").show();
                    $("#viewDocLink_SF16").hide();
                    $("#X-btn_SF16").hide();
                    $("#done-btn_SF16").hide();
                    $("#review-btn_SF16").hide();
                  });
                } else if (doc[0].status == "done") {
                  mainactionSF16 = "upload";
                  SF16Status = "done";
                  SOptStatusArray[2].status = SF16Status;
                  this.setState(
                    {
                      SF16DocLink: doc[0].Form16,
                    },
                    () => {
                      $("#choose_SF16").hide();
                      $("#viewDocLink_SF16").css("display", "block");
                      $("#X-btn_SF16").hide();
                      $("#done-btn_SF16").show();
                      $("#review-btn_SF16").hide();
                    }
                  );
                } else {
                  mainactionSF16 = "upload";
                  SF16Status = "open";
                  SOptStatusArray[2].status = SF16Status;
                  $("#viewDocLink_SF16").hide();
                  $("#choose_SF16").show();
                  $("#X-btn_SF16").hide();
                  $("#done-btn_SF16").hide();
                  $("#review-btn_SF16").hide();
                }

                if (doc[1].status == "remove") {
                  mainactionSBankS = "upload";
                  SBankSStatus = "remove";
                  SOptStatusArray[3].status = SBankSStatus;
                  this.setState(
                    {
                      SBankSDocLink: doc[1].BankStatement,
                    },
                    () => {
                      $("#viewDocLink_SBankS").css("display", "block");
                      $("#choose_SBankS").hide();
                      $("#X-btn_SBankS").show();
                      $("#done-btn_SBankS").hide();
                      $("#review-btn_SBankS").hide();
                    }
                  );
                } else if (doc[1].status == "review") {
                  mainactionSBankS = "upload";
                  SBankSStatus = "review";
                  SOptStatusArray[3].status = SBankSStatus;
                  this.setState(
                    {
                      SBankSDocLink: doc[1].BankStatement,
                    },
                    () => {
                      $("#viewDocLink_SBankS").css("display", "block");
                      $("#choose_SBankS").hide();
                      $("#X-btn_SBankS").hide();
                      $("#done-btn_SBankS").hide();
                      $("#review-btn_SBankS").show();
                    }
                  );
                } else if (doc[1].status == "reupload") {
                  mainactionSBankS = "reupload";
                  SBankSStatus = "reupload";
                  SOptStatusArray[3].status = SBankSStatus;
                  this.setState({}, () => {
                    $("#choose_SBankS").show();
                    $("#viewDocLink_SBankS").hide();
                    $("#X-btn_SBankS").hide();
                    $("#done-btn_SBankS").hide();
                    $("#review-btn_SBankS").hide();
                  });
                } else if (doc[1].status == "done") {
                  mainactionSBankS = "upload";
                  SBankSStatus = "done";
                  SOptStatusArray[3].status = SBankSStatus;
                  this.setState(
                    {
                      SBankSDocLink: doc[1].BankStatement,
                    },
                    () => {
                      $("#choose_SBankS").hide();
                      $("#viewDocLink_SBankS").css("display", "block");
                      $("#X-btn_SBankS").hide();
                      $("#done-btn_SBankS").show();
                      $("#review-btn_SBankS").hide();
                    }
                  );
                } else {
                  mainactionSBankS = "upload";
                  SBankSStatus = "open";
                  SOptStatusArray[3].status = SBankSStatus;
                  $("#viewDocLink_SBankS").hide();
                  $("#choose_SBankS").show();
                  $("#X-btn_SBankS").hide();
                  $("#done-btn_SBankS").hide();
                  $("#review-btn_SBankS").hide();
                }
              } else {
                $("#choose_SBankS").show();
                $("#viewDocLink_SBankS").hide();
                $("#X-btn_SBankS").hide();
                $("#done-btn_SBankS").hide();
                $("#review-btn_SBankS").hide();

                $("#choose_SF16").show();
                $("#viewDocLink_SF16").hide();
                $("#X-btn_SF16").hide();
                $("#done-btn_SF16").hide();
                $("#review-btn_SF16").hide();
              }
              if (res.data.oneTime.length > 0) {
                if (res.data.oneTime[0].documents.length > 0) {
                  let onetime = res.data.oneTime[0].documents;
                  if (onetime[0].status == "remove") {
                    mainactionSAadhar = "upload";
                    SAadharStatus = "remove";
                    SStatusArray[0].status = SAadharStatus;
                    this.setState(
                      {
                        SAadharDocLink: onetime[0].AadhaarCard,
                      },
                      () => {
                        $("#viewDocLink_SAadhar").css("display", "block");
                        $("#choose_SAadhar").hide();
                        $("#X-btn_SAadhar").show();
                        $("#done-btn_SAadhar").hide();
                        $("#review-btn_SAadhar").hide();
                      }
                    );
                  } else if (onetime[0].status == "review") {
                    mainactionSAadhar = "upload";
                    SAadharStatus = "review";
                    SStatusArray[0].status = SAadharStatus;
                    this.setState(
                      {
                        SAadharDocLink: onetime[0].AadhaarCard,
                      },
                      () => {
                        $("#viewDocLink_SAadhar").css("display", "block");
                        $("#choose_SAadhar").hide();
                        $("#X-btn_SAadhar").hide();
                        $("#done-btn_SAadhar").hide();
                        $("#review-btn_SAadhar").show();
                      }
                    );
                  } else if (onetime[0].status == "reupload") {
                    mainactionSAadhar = "reupload";
                    SAadharStatus = "reupload";
                    SStatusArray[0].status = SAadharStatus;
                    this.setState({}, () => {
                      $("#choose_SAadhar").show();
                      $("#viewDocLink_SAadhar").hide();
                      $("#X-btn_SAadhar").hide();
                      $("#done-btn_SAadhar").hide();
                      $("#review-btn_SAadhar").hide();
                    });
                  } else if (onetime[0].status == "done") {
                    mainactionSAadhar = "upload";
                    SAadharStatus = "done";
                    SStatusArray[0].status = SAadharStatus;
                    this.setState(
                      {
                        SAadharDocLink: onetime[0].AadhaarCard,
                      },
                      () => {
                        $("#viewDocLink_SAadhar").css("display", "block");
                        $("#choose_SAadhar").hide();
                        $("#X-btn_SAadhar").hide();
                        $("#done-btn_SAadhar").show();
                        $("#review-btn_SAadhar").hide();
                      }
                    );
                  } else {
                    mainactionSAadhar = "upload";
                    SAadharStatus = "open";
                    SStatusArray[0].status = SAadharStatus;
                    $("#viewDocLink_SAadhar").hide();
                    $("#choose_SAadhar").show();
                    $("#X-btn_SAadhar").hide();
                    $("#done-btn_SAadhar").hide();
                    $("#review-btn_SAadhar").hide();
                  }
                  if (onetime[1].status == "remove") {
                    mainactionSPan = "upload";
                    SPanStatus = "remove";
                    SStatusArray[1].status = SPanStatus;
                    this.setState(
                      {
                        SPanDocLink: onetime[1].PANCard,
                      },
                      () => {
                        $("#viewDocLink_SPan").css("display", "block");
                        $("#choose_SPan").hide();
                        $("#X-btn_SPan").show();
                        $("#done-btn_SPan").hide();
                        $("#review-btn_SPan").hide();
                      }
                    );
                  } else if (onetime[1].status == "review") {
                    mainactionSPan = "upload";
                    SPanStatus = "review";
                    SStatusArray[1].status = SPanStatus;
                    this.setState(
                      {
                        SPanDocLink: onetime[1].PANCard,
                      },
                      () => {
                        $("#viewDocLink_SPan").css("display", "block");
                        $("#choose_SPan").hide();
                        $("#X-btn_SPan").hide();
                        $("#done-btn_SPan").hide();
                        $("#review-btn_SPan").show();
                      }
                    );
                  } else if (onetime[1].status == "reupload") {
                    mainactionSPan = "reupload";
                    SPanStatus = "reupload";
                    SStatusArray[1].status = SPanStatus;
                    this.setState({}, () => {
                      $("#choose_SPan").show();
                      $("#viewDocLink_SPan").hide();
                      $("#X-btn_SPan").hide();
                      $("#done-btn_SPan").hide();
                      $("#review-btn_SPan").hide();
                    });
                  } else if (onetime[1].status == "done") {
                    mainactionSPan = "upload";
                    SPanStatus = "done";
                    SStatusArray[1].status = SPanStatus;
                    this.setState(
                      {
                        SPanDocLink: onetime[1].PANCard,
                      },
                      () => {
                        $("#viewDocLink_SPan").css("display", "block");
                        $("#choose_SPan").hide();
                        $("#X-btn_SPan").hide();
                        $("#done-btn_SPan").show();
                        $("#review-btn_SPan").hide();
                      }
                    );
                  } else {
                    mainactionSPan = "upload";
                    SPanStatus = "open";
                    SStatusArray[1].status = SPanStatus;
                    $("#viewDocLink_SPan").hide();
                    $("#choose_SPan").show();
                    $("#X-btn_SPan").hide();
                    $("#done-btn_SPan").hide();
                    $("#review-btn_SPan").hide();
                  }
                  if (onetime[2].status == "remove") {
                    mainactionSBp = "upload";
                    SBpStatus = "remove";
                    SStatusArray[2].status = SBpStatus;
                    this.setState(
                      {
                        SBpDocLink: onetime[2].BankProof,
                      },
                      () => {
                        $("#viewDocLink_SBp").css("display", "block");
                        $("#choose_SBp").hide();
                        $("#X-btn_SBp").show();
                        $("#done-btn_SBp").hide();
                        $("#review-btn_SBp").hide();
                      }
                    );
                  } else if (onetime[2].status == "review") {
                    mainactionSBp = "upload";
                    SBpStatus = "review";
                    SStatusArray[2].status = SBpStatus;
                    this.setState(
                      {
                        SBpDocLink: onetime[2].BankProof,
                      },
                      () => {
                        $("#viewDocLink_SBp").css("display", "block");
                        $("#choose_SBp").hide();
                        $("#X-btn_SBp").hide();
                        $("#done-btn_SBp").hide();
                        $("#review-btn_SBp").show();
                      }
                    );
                  } else if (onetime[2].status == "reupload") {
                    mainactionSBp = "reupload";
                    SBpStatus = "reupload";
                    SStatusArray[2].status = SBpStatus;
                    this.setState({}, () => {
                      $("#choose_SBp").show();
                      $("#viewDocLink_SBp").hide();
                      $("#X-btn_SBp").hide();
                      $("#done-btn_SBp").hide();
                      $("#review-btn_SBp").hide();
                    });
                  } else if (onetime[2].status == "done") {
                    mainactionSBp = "upload";
                    SBpStatus = "done";
                    SStatusArray[2].status = SBpStatus;
                    this.setState(
                      {
                        SBpDocLink: onetime[2].BankProof,
                      },
                      () => {
                        $("#viewDocLink_SBp").css("display", "block");
                        $("#choose_SBp").hide();
                        $("#X-btn_SBp").hide();
                        $("#done-btn_SBp").show();
                        $("#review-btn_SBp").hide();
                      }
                    );
                  } else {
                    mainactionSBp = "upload";
                    SBpStatus = "open";
                    SStatusArray[2].status = SBpStatus;
                    $("#viewDocLink_SBp").hide();
                    $("#choose_SBp").show();
                    $("#X-btn_SBp").hide();
                    $("#done-btn_SBp").hide();
                    $("#review-btn_SBp").hide();
                  }
                  if (onetime[3].status == "remove") {
                    mainactionSPR = "upload";
                    SPRStatus = "remove";
                    SOptStatusArray[1].status = SPRStatus;
                    this.setState(
                      {
                        SPRDocLink: onetime[3].PreviousReturns,
                      },
                      () => {
                        $("#viewDocLink_SPR").css("display", "block");
                        $("#choose_SPR").hide();
                        $("#X-btn_SPR").show();
                        $("#done-btn_SPR").hide();
                        $("#review-btn_SPR").hide();
                      }
                    );
                  } else if (onetime[3].status == "review") {
                    mainactionSPR = "upload";
                    SPRStatus = "review";
                    SOptStatusArray[1].status = SPRStatus;
                    this.setState(
                      {
                        SPRDocLink: onetime[3].PreviousReturns,
                      },
                      () => {
                        $("#viewDocLink_SPR").css("display", "block");
                        $("#choose_SPR").hide();
                        $("#X-btn_SPR").hide();
                        $("#done-btn_SPR").hide();
                        $("#review-btn_SPR").show();
                      }
                    );
                  } else if (onetime[3].status == "reupload") {
                    mainactionSPR = "reupload";
                    SPRStatus = "reupload";
                    SOptStatusArray[1].status = SPRStatus;
                    this.setState({}, () => {
                      $("#choose_SPR").show();
                      $("#viewDocLink_SPR").hide();
                      $("#X-btn_SPR").hide();
                      $("#done-btn_SPR").hide();
                      $("#review-btn_SPR").hide();
                    });
                  } else if (onetime[3].status == "done") {
                    mainactionSPR = "upload";
                    SPRStatus = "done";
                    SOptStatusArray[1].status = SPRStatus;
                    this.setState(
                      {
                        SPRDocLink: onetime[3].PreviousReturns,
                      },
                      () => {
                        $("#viewDocLink_SPR").css("display", "block");
                        $("#choose_SPR").hide();
                        $("#X-btn_SPR").hide();
                        $("#done-btn_SPR").show();
                        $("#review-btn_SPR").hide();
                      }
                    );
                  } else {
                    mainactionSPR = "upload";
                    SPRStatus = "open";
                    SOptStatusArray[1].status = SPRStatus;
                    $("#viewDocLink_SPR").hide();
                    $("#choose_SPR").show();
                    $("#X-btn_SPR").hide();
                    $("#done-btn_SPR").hide();
                    $("#review-btn_SPR").hide();
                  }
                  if (onetime[4].status == "remove") {
                    mainactionSPBS = "upload";
                    SPBSStatus = "remove";
                    SOptStatusArray[0].status = SPBSStatus;
                    this.setState(
                      {
                        SPBSDocLink: onetime[4].PreviousBS,
                      },
                      () => {
                        $("#viewDocLink_SPBS").css("display", "block");
                        $("#choose_SPBS").hide();
                        $("#X-btn_SPBS").show();
                        $("#done-btn_SPBS").hide();
                        $("#review-btn_SPBS").hide();
                      }
                    );
                  } else if (onetime[4].status == "review") {
                    mainactionSPBS = "upload";
                    SPBSStatus = "review";
                    SOptStatusArray[0].status = SPBSStatus;
                    this.setState(
                      {
                        SPBSDocLink: onetime[4].PreviousBS,
                      },
                      () => {
                        $("#viewDocLink_SPBS").css("display", "block");
                        $("#choose_SPBS").hide();
                        $("#X-btn_SPBS").hide();
                        $("#done-btn_SPBS").hide();
                        $("#review-btn_SPBS").show();
                      }
                    );
                  } else if (onetime[4].status == "reupload") {
                    mainactionSPBS = "reupload";
                    SPBSStatus = "reupload";
                    SOptStatusArray[0].status = SPBSStatus;
                    this.setState({}, () => {
                      $("#choose_SPBS").show();
                      $("#viewDocLink_SPBS").hide();
                      $("#X-btn_SPBS").hide();
                      $("#done-btn_SPBS").hide();
                      $("#review-btn_SPBS").hide();
                    });
                  } else if (onetime[4].status == "done") {
                    mainactionSPBS = "upload";
                    SPBSStatus = "done";
                    SOptStatusArray[0].status = SPBSStatus;
                    this.setState(
                      {
                        SPBSDocLink: onetime[4].PreviousBS,
                      },
                      () => {
                        $("#viewDocLink_SPBS").css("display", "block");
                        $("#choose_SPBS").hide();
                        $("#X-btn_SPBS").hide();
                        $("#done-btn_SPBS").show();
                        $("#review-btn_SPBS").hide();
                      }
                    );
                  } else {
                    mainactionSPBS = "upload";
                    SPBSStatus = "open";
                    SOptStatusArray[0].status = SPBSStatus;
                    $("#viewDocLink_SPBS").hide();
                    $("#choose_SPBS").show();
                    $("#X-btn_SPBS").hide();
                    $("#done-btn_SPBS").hide();
                    $("#review-btn_SPBS").hide();
                  }
                }
              } else {
                $("#choose_SPBS").show();
                $("#viewDocLink_SPBS").hide();
                $("#X-btn_SPBS").hide();
                $("#done-btn_SPBS").hide();
                $("#review-btn_SPBS").hide();

                $("#choose_SPR").show();
                $("#viewDocLink_SPR").hide();
                $("#X-btn_SPR").hide();
                $("#done-btn_SPR").hide();
                $("#review-btn_SPR").hide();

                $("#choose_SBp").show();
                $("#viewDocLink_SBp").hide();
                $("#X-btn_SBp").hide();
                $("#done-btn_SBp").hide();
                $("#review-btn_SBp").hide();

                $("#choose_SPan").show();
                $("#viewDocLink_SPan").hide();
                $("#X-btn_SPan").hide();
                $("#done-btn_SPan").hide();
                $("#review-btn_SPan").hide();

                $("#choose_SAadhar").show();
                $("#viewDocLink_SAadhar").hide();
                $("#X-btn_SAadhar").hide();
                $("#done-btn_SAadhar").hide();
                $("#review-btn_SAadhar").hide();
              }

              if (
                res.data.fyStatus == "lock" ||
                res.data.fyStatus == "complete"
              ) {
                $(".proceednowbtn").hide();
                if (res.data.incD[0].documents.length == 0) {
                  $("#SincDadditionalDocumentDiv").hide();
                } else {
                  $("#SincDadddocumentbtn").hide();
                }
                if (res.data.addDocInformation.length == 0) {
                  $("#SadditionalDocumentDiv").hide();
                } else {
                  $("#Sadddocumentbtn").hide();
                }
              }
              if (res.data.incD[0].documents.length > 0) {
                $("#SincDadditionalDocumentDiv").show();
                $("#SincDadddocumentbtn").hide();
                this.setState({
                  SincDAdditionalFiles: [...res.data.incD[0].documents],
                  SincDAdd_DocName: "File Name",
                  api_SincDAdditionalFiles: "Yes",
                });
              } else if (
                res.data.incD[0].documents.length == 0 &&
                res.data.fyStatus != "lock" &&
                res.data.fyStatus != "complete"
              ) {
                $("#SincDadditionalDocumentDiv").show();
                this.setState({
                  SincDAdd_DocName: "",
                  SincDAdditionalFiles: [1],
                  api_SincDAdditionalFiles: "No",
                });
              }

              if (res.data.addDocInformation.length > 0) {
                $("#SadditionalDocumentDiv").show();
                $("#Sadddocumentbtn").hide();
                this.setState({
                  SAdditionalFiles: [...res.data.addDocInformation],
                  SAdd_DocName: "File Name",
                  api_SAdditionalFiles: "Yes",
                });
              } else if (
                res.data.addDocInformation.length == 0 &&
                res.data.fyStatus != "lock" &&
                res.data.fyStatus != "complete"
              ) {
                $("#SadditionalDocumentDiv").show();
                $("#Sadddocumentbtn").show();
                this.setState({
                  SAdd_DocName: "",
                  SAdditionalFiles: [1],
                  api_SAdditionalFiles: "No",
                });
              }

              this.ScheckRemainingReuploads();
              let statusCheck = this.statusCheckFunction(
                "reupload",
                SStatusArray
              );
              SincDadditionalStatusArrayCheck = this.statusCheckFunction(
                "reupload",
                this.state.SincDAdditionalFiles
              );
              SadditionalStatusArrayCheck = this.statusCheckFunction(
                "reupload",
                this.state.SAdditionalFiles
              );

              if (
                AnyReuploadOccuredS == "Yes" &&
                statusCheck == undefined &&
                SincDadditionalStatusArrayCheck == undefined &&
                SadditionalStatusArrayCheck == undefined
              ) {
                SOptReview = "Yes";
                this.countOptReuploadS("reupload", SOptStatusArray);
              } else {
                SOptReview = "No";
              }
              if (
                res.data.rejectstatus == "false" &&
                res.data.mainstatus == "true"
              ) {
                rejectstatus = "Yes";
              }
            } else if (res.data.serviceType == "ITRF_BP_OneTime_Documents") {
              $(".selectcateselect1").attr("disabled", true);
              this.setState(
                {
                  dropDownValue: "ITRF_BP_OneTime_Documents",
                  serviceType: "ITRF_BP_OneTime_Documents",
                },
                () => {
                  if (this.state.dropDownValue == "ITRF_BP_OneTime_Documents") {
                    $("#Itrf_default_Img").hide();
                    $("#ItrfSalariedSection").hide();
                    $("#ItrfproprietorSection").show();
                    $("#ItrfPartnershipFirm").hide();
                    $("#ItrfCompany_LLP").hide();
                  }
                }
              );

              if (res.data.oneTime.length > 0) {
                if (res.data.oneTime[0].documents.length > 0) {
                  let onetime = res.data.oneTime[0].documents;
                  if (onetime[0].status == "remove") {
                    mainactionPAadhar = "upload";
                    PAadharStatus = "remove";
                    PStatusArray[0].status = PAadharStatus;
                    this.setState(
                      {
                        PAadharDocLink: onetime[0].AadhaarCard,
                      },
                      () => {
                        $("#viewDocLink_PAadhar").css("display", "block");
                        $("#choose_PAadhar").hide();
                        $("#X-btn_PAadhar").show();
                        $("#done-btn_PAadhar").hide();
                        $("#review-btn_PAadhar").hide();
                      }
                    );
                  } else if (onetime[0].status == "review") {
                    mainactionPAadhar = "upload";
                    PAadharStatus = "review";
                    PStatusArray[0].status = PAadharStatus;
                    this.setState(
                      {
                        PAadharDocLink: onetime[0].AadhaarCard,
                      },
                      () => {
                        $("#viewDocLink_PAadhar").css("display", "block");
                        $("#choose_PAadhar").hide();
                        $("#X-btn_PAadhar").hide();
                        $("#done-btn_PAadhar").hide();
                        $("#review-btn_PAadhar").show();
                      }
                    );
                  } else if (onetime[0].status == "reupload") {
                    mainactionPAadhar = "reupload";
                    PAadharStatus = "reupload";
                    PStatusArray[0].status = PAadharStatus;
                    this.setState({}, () => {
                      $("#choose_PAadhar").show();
                      $("#viewDocLink_PAadhar").hide();
                      $("#X-btn_PAadhar").hide();
                      $("#done-btn_PAadhar").hide();
                      $("#review-btn_PAadhar").hide();
                    });
                  } else if (onetime[0].status == "done") {
                    mainactionPAadhar = "upload";
                    PAadharStatus = "done";
                    PStatusArray[0].status = PAadharStatus;
                    this.setState(
                      {
                        PAadharDocLink: onetime[0].AadhaarCard,
                      },
                      () => {
                        $("#viewDocLink_PAadhar").css("display", "block");
                        $("#choose_PAadhar").hide();
                        $("#X-btn_PAadhar").hide();
                        $("#done-btn_PAadhar").show();
                        $("#review-btn_PAadhar").hide();
                      }
                    );
                  } else {
                    mainactionPAadhar = "upload";
                    PAadharStatus = "open";
                    PStatusArray[0].status = PAadharStatus;
                    $("#viewDocLink_PAadhar").hide();
                    $("#choose_PAadhar").show();
                    $("#X-btn_PAadhar").hide();
                    $("#done-btn_PAadhar").hide();
                    $("#review-btn_PAadhar").hide();
                  }
                  if (onetime[1].status == "remove") {
                    mainactionPPan = "upload";
                    PPanStatus = "remove";
                    PStatusArray[1].status = PPanStatus;
                    this.setState(
                      {
                        PPanDocLink: onetime[1].PANCard,
                      },
                      () => {
                        $("#viewDocLink_PPan").css("display", "block");
                        $("#choose_PPan").hide();
                        $("#X-btn_PPan").show();
                        $("#done-btn_PPan").hide();
                        $("#review-btn_PPan").hide();
                      }
                    );
                  } else if (onetime[1].status == "review") {
                    mainactionPPan = "upload";
                    PPanStatus = "review";
                    PStatusArray[1].status = PPanStatus;
                    this.setState(
                      {
                        PPanDocLink: onetime[1].PANCard,
                      },
                      () => {
                        $("#viewDocLink_PPan").css("display", "block");
                        $("#choose_PPan").hide();
                        $("#X-btn_PPan").hide();
                        $("#done-btn_PPan").hide();
                        $("#review-btn_PPan").show();
                      }
                    );
                  } else if (onetime[1].status == "reupload") {
                    mainactionPPan = "reupload";
                    PPanStatus = "reupload";
                    PStatusArray[1].status = PPanStatus;
                    this.setState({}, () => {
                      $("#choose_PPan").show();
                      $("#viewDocLink_PPan").hide();
                      $("#X-btn_PPan").hide();
                      $("#done-btn_PPan").hide();
                      $("#review-btn_PPan").hide();
                    });
                  } else if (onetime[1].status == "done") {
                    mainactionPPan = "upload";
                    PPanStatus = "done";
                    PStatusArray[1].status = PPanStatus;
                    this.setState(
                      {
                        PPanDocLink: onetime[1].PANCard,
                      },
                      () => {
                        $("#viewDocLink_PPan").css("display", "block");
                        $("#choose_PPan").hide();
                        $("#X-btn_PPan").hide();
                        $("#done-btn_PPan").show();
                        $("#review-btn_PPan").hide();
                      }
                    );
                  } else {
                    mainactionPPan = "upload";
                    PPanStatus = "open";
                    PStatusArray[1].status = PPanStatus;
                    $("#viewDocLink_PPan").hide();
                    $("#choose_PPan").show();
                    $("#X-btn_PPan").hide();
                    $("#done-btn_PPan").hide();
                    $("#review-btn_PPan").hide();
                  }
                  if (onetime[2].status == "remove") {
                    mainactionPBP = "upload";
                    PBPStatus = "remove";
                    PStatusArray[2].status = PBPStatus;
                    this.setState(
                      {
                        PBPDocLink: onetime[2].BankProof,
                      },
                      () => {
                        $("#viewDocLink_PBP").css("display", "block");
                        $("#choose_PBP").hide();
                        $("#X-btn_PBP").show();
                        $("#done-btn_PBP").hide();
                        $("#review-btn_PBP").hide();
                      }
                    );
                  } else if (onetime[2].status == "review") {
                    mainactionPBP = "upload";
                    PBPStatus = "review";
                    PStatusArray[2].status = PBPStatus;
                    this.setState(
                      {
                        PBPDocLink: onetime[2].BankProof,
                      },
                      () => {
                        $("#viewDocLink_PBP").css("display", "block");
                        $("#choose_PBP").hide();
                        $("#X-btn_PBP").hide();
                        $("#done-btn_PBP").hide();
                        $("#review-btn_PBP").show();
                      }
                    );
                  } else if (onetime[2].status == "reupload") {
                    mainactionPBP = "reupload";
                    PBPStatus = "reupload";
                    PStatusArray[2].status = PBPStatus;
                    this.setState({}, () => {
                      $("#choose_PBP").show();
                      $("#viewDocLink_PBP").hide();
                      $("#X-btn_PBP").hide();
                      $("#done-btn_PBP").hide();
                      $("#review-btn_PBP").hide();
                    });
                  } else if (onetime[2].status == "done") {
                    mainactionPBP = "upload";
                    PBPStatus = "done";
                    PStatusArray[2].status = PBPStatus;
                    this.setState(
                      {
                        PBPDocLink: onetime[2].BankProof,
                      },
                      () => {
                        $("#viewDocLink_PBP").css("display", "block");
                        $("#choose_PBP").hide();
                        $("#X-btn_PBP").hide();
                        $("#done-btn_PBP").show();
                        $("#review-btn_PBP").hide();
                      }
                    );
                  } else {
                    mainactionPBP = "upload";
                    PBPStatus = "open";
                    PStatusArray[2].status = PBPStatus;
                    $("#viewDocLink_PBP").hide();
                    $("#choose_PBP").show();
                    $("#X-btn_PBP").hide();
                    $("#done-btn_PBP").hide();
                    $("#review-btn_PBP").hide();
                  }
                  if (onetime[4].status == "remove") {
                    mainactionPBS = "upload";
                    PBSStatus = "remove";
                    POptStatusArray[0].status = PBSStatus;
                    this.setState(
                      {
                        PBSDocLink: onetime[4].PreviousBS_Audit,
                      },
                      () => {
                        $("#viewDocLink_PBS").css("display", "block");
                        $("#choose_PBS").hide();
                        $("#X-btn_PBS").show();
                        $("#done-btn_PBS").hide();
                        $("#review-btn_PBS").hide();
                      }
                    );
                  } else if (onetime[4].status == "review") {
                    mainactionPBS = "upload";
                    PBSStatus = "review";
                    POptStatusArray[0].status = PBSStatus;
                    this.setState(
                      {
                        PBSDocLink: onetime[4].PreviousBS_Audit,
                      },
                      () => {
                        $("#viewDocLink_PBS").css("display", "block");
                        $("#choose_PBS").hide();
                        $("#X-btn_PBS").hide();
                        $("#done-btn_PBS").hide();
                        $("#review-btn_PBS").show();
                      }
                    );
                  } else if (onetime[4].status == "reupload") {
                    mainactionPBS = "reupload";
                    PBSStatus = "reupload";
                    POptStatusArray[0].status = PBSStatus;
                    this.setState({}, () => {
                      $("#choose_PBS").show();
                      $("#viewDocLink_PBS").hide();
                      $("#X-btn_PBS").hide();
                      $("#done-btn_PBS").hide();
                      $("#review-btn_PBS").hide();
                    });
                  } else if (onetime[4].status == "done") {
                    mainactionPBS = "upload";
                    PBSStatus = "done";
                    POptStatusArray[0].status = PBSStatus;
                    this.setState(
                      {
                        PBSDocLink: onetime[4].PreviousBS_Audit,
                      },
                      () => {
                        $("#viewDocLink_PBS").css("display", "block");
                        $("#choose_PBS").hide();
                        $("#X-btn_PBS").hide();
                        $("#done-btn_PBS").show();
                        $("#review-btn_PBS").hide();
                      }
                    );
                  } else {
                    mainactionPBS = "upload";
                    PBSStatus = "open";
                    POptStatusArray[0].status = PBSStatus;
                    $("#viewDocLink_PBS").hide();
                    $("#choose_PBS").show();
                    $("#X-btn_PBS").hide();
                    $("#done-btn_PBS").hide();
                    $("#review-btn_PBS").hide();
                  }
                  if (onetime[3].status == "remove") {
                    mainactionPPR = "upload";
                    PPRStatus = "remove";
                    POptStatusArray[1].status = PPRStatus;
                    this.setState(
                      {
                        PPRDocLink: onetime[3].PreviousReturns,
                      },
                      () => {
                        $("#viewDocLink_PPR").css("display", "block");
                        $("#choose_PPR").hide();
                        $("#X-btn_PPR").show();
                        $("#done-btn_PPR").hide();
                        $("#review-btn_PPR").hide();
                      }
                    );
                  } else if (onetime[3].status == "review") {
                    mainactionPPR = "upload";
                    PPRStatus = "review";
                    POptStatusArray[1].status = PPRStatus;
                    this.setState(
                      {
                        PPRDocLink: onetime[3].PreviousReturns,
                      },
                      () => {
                        $("#viewDocLink_PPR").css("display", "block");
                        $("#choose_PPR").hide();
                        $("#X-btn_PPR").hide();
                        $("#done-btn_PPR").hide();
                        $("#review-btn_PPR").show();
                      }
                    );
                  } else if (onetime[3].status == "reupload") {
                    mainactionPPR = "reupload";
                    PPRStatus = "reupload";
                    POptStatusArray[1].status = PPRStatus;
                    this.setState({}, () => {
                      $("#choose_PPR").show();
                      $("#viewDocLink_PPR").hide();
                      $("#X-btn_PPR").hide();
                      $("#done-btn_PPR").hide();
                      $("#review-btn_PPR").hide();
                    });
                  } else if (onetime[3].status == "done") {
                    mainactionPPR = "upload";
                    PPRStatus = "done";
                    POptStatusArray[1].status = PPRStatus;
                    this.setState(
                      {
                        PPRDocLink: onetime[3].PreviousReturns,
                      },
                      () => {
                        $("#viewDocLink_PPR").css("display", "block");
                        $("#choose_PPR").hide();
                        $("#X-btn_PPR").hide();
                        $("#done-btn_PPR").show();
                        $("#review-btn_PPR").hide();
                      }
                    );
                  } else {
                    mainactionPPR = "upload";
                    PPRStatus = "open";
                    POptStatusArray[1].status = PPRStatus;
                    $("#viewDocLink_PPR").hide();
                    $("#choose_PPR").show();
                    $("#X-btn_PPR").hide();
                    $("#done-btn_PPR").hide();
                    $("#review-btn_PPR").hide();
                  }
                }
              } else {
                $("#viewDocLink_PAadhar").hide();
                $("#choose_PAadhar").show();
                $("#X-btn_PAadhar").hide();
                $("#done-btn_PAadhar").hide();
                $("#review-btn_PAadhar").hide();

                $("#viewDocLink_PPan").hide();
                $("#choose_PPan").show();
                $("#X-btn_PPan").hide();
                $("#done-btn_PPan").hide();
                $("#review-btn_PPan").hide();

                $("#viewDocLink_PBP").hide();
                $("#choose_PBP").show();
                $("#X-btn_PBP").hide();
                $("#done-btn_PBP").hide();
                $("#review-btn_PBP").hide();

                $("#viewDocLink_PBS").hide();
                $("#choose_PBS").show();
                $("#X-btn_PBS").hide();
                $("#done-btn_PBS").hide();
                $("#review-btn_PBS").hide();

                $("#viewDocLink_PPR").hide();
                $("#choose_PPR").show();
                $("#X-btn_PPR").hide();
                $("#done-btn_PPR").hide();
                $("#review-btn_PPR").hide();
              }
              if (res.data.documents.length > 0) {
                let doc = res.data.documents;
                if (doc[0].status == "remove") {
                  mainactionPData = "upload";
                  PDataStatus = "remove";
                  PStatusArray[3].status = PDataStatus;
                  this.setState(
                    {
                      PDataDocLink: doc[0].Data,
                    },
                    () => {
                      $("#viewDocLink_PData").css("display", "block");
                      $("#choose_PData").hide();
                      $("#X-btn_PData").show();
                      $("#done-btn_PData").hide();
                      $("#review-btn_PData").hide();
                    }
                  );
                } else if (doc[0].status == "review") {
                  mainactionPData = "upload";
                  PDataStatus = "review";
                  PStatusArray[3].status = PDataStatus;
                  this.setState(
                    {
                      PDataDocLink: doc[0].Data,
                    },
                    () => {
                      $("#viewDocLink_PData").css("display", "block");
                      $("#choose_PData").hide();
                      $("#X-btn_PData").hide();
                      $("#done-btn_PData").hide();
                      $("#review-btn_PData").show();
                    }
                  );
                } else if (doc[0].status == "reupload") {
                  mainactionPData = "reupload";
                  PDataStatus = "reupload";
                  PStatusArray[3].status = PDataStatus;
                  this.setState({}, () => {
                    $("#choose_PData").show();
                    $("#viewDocLink_PData").hide();
                    $("#X-btn_PData").hide();
                    $("#done-btn_PData").hide();
                    $("#review-btn_PData").hide();
                  });
                } else if (doc[0].status == "done") {
                  mainactionPData = "upload";
                  PDataStatus = "done";
                  PStatusArray[3].status = PDataStatus;
                  this.setState(
                    {
                      PDataDocLink: doc[0].Data,
                    },
                    () => {
                      $("#viewDocLink_PData").css("display", "block");
                      $("#choose_PData").hide();
                      $("#X-btn_PData").hide();
                      $("#done-btn_PData").show();
                      $("#review-btn_PData").hide();
                    }
                  );
                } else {
                  mainactionPData = "upload";
                  PDataStatus = "open";
                  PStatusArray[3].status = PDataStatus;
                  $("#viewDocLink_PData").hide();
                  $("#choose_PData").show();
                  $("#X-btn_PData").hide();
                  $("#done-btn_PData").hide();
                  $("#review-btn_PData").hide();
                }
                if (doc[1].status == "remove") {
                  mainactionPGSTReturn = "upload";
                  PGSTReturnStatus = "remove";
                  POptStatusArray[2].status = PGSTReturnStatus;
                  this.setState(
                    {
                      PGSTReturnDocLink: doc[1].GSTReturn,
                    },
                    () => {
                      $("#viewDocLink_PGSTReturn").css("display", "block");
                      $("#choose_PGSTReturn").hide();
                      $("#X-btn_PGSTReturn").show();
                      $("#done-btn_PGSTReturn").hide();
                      $("#review-btn_PGSTReturn").hide();
                    }
                  );
                } else if (doc[1].status == "review") {
                  mainactionPGSTReturn = "upload";
                  PGSTReturnStatus = "review";
                  POptStatusArray[2].status = PGSTReturnStatus;
                  this.setState(
                    {
                      PGSTReturnDocLink: doc[1].GSTReturn,
                    },
                    () => {
                      $("#viewDocLink_PGSTReturn").css("display", "block");
                      $("#choose_PGSTReturn").hide();
                      $("#X-btn_PGSTReturn").hide();
                      $("#done-btn_PGSTReturn").hide();
                      $("#review-btn_PGSTReturn").show();
                    }
                  );
                } else if (doc[1].status == "reupload") {
                  mainactionPGSTReturn = "reupload";
                  PGSTReturnStatus = "reupload";
                  POptStatusArray[2].status = PGSTReturnStatus;
                  this.setState({}, () => {
                    $("#choose_PGSTReturn").show();
                    $("#viewDocLink_PGSTReturn").hide();
                    $("#X-btn_PGSTReturn").hide();
                    $("#done-btn_PGSTReturn").hide();
                    $("#review-btn_PGSTReturn").hide();
                  });
                } else if (doc[1].status == "done") {
                  mainactionPGSTReturn = "upload";
                  PGSTReturnStatus = "done";
                  POptStatusArray[2].status = PGSTReturnStatus;
                  this.setState(
                    {
                      PGSTReturnDocLink: doc[1].GSTReturn,
                    },
                    () => {
                      $("#viewDocLink_PGSTReturn").css("display", "block");
                      $("#choose_PGSTReturn").hide();
                      $("#X-btn_PGSTReturn").hide();
                      $("#done-btn_PGSTReturn").show();
                      $("#review-btn_PGSTReturn").hide();
                    }
                  );
                } else {
                  mainactionPGSTReturn = "upload";
                  PGSTReturnStatus = "open";
                  POptStatusArray[2].status = PGSTReturnStatus;
                  $("#viewDocLink_PGSTReturn").hide();
                  $("#choose_PGSTReturn").show();
                  $("#X-btn_PGSTReturn").hide();
                  $("#done-btn_PGSTReturn").hide();
                  $("#review-btn_PGSTReturn").hide();
                }
                if (doc[2].status == "remove") {
                  mainactionPTDSReturn = "upload";
                  PTDSReturnStatus = "remove";
                  POptStatusArray[3].status = PTDSReturnStatus;
                  this.setState(
                    {
                      PTDSReturnDocLink: doc[2].TDSReturn,
                    },
                    () => {
                      $("#viewDocLink_PTDSReturn").css("display", "block");
                      $("#choose_PTDSReturn").hide();
                      $("#X-btn_PTDSReturn").show();
                      $("#done-btn_PTDSReturn").hide();
                      $("#review-btn_PTDSReturn").hide();
                    }
                  );
                } else if (doc[2].status == "review") {
                  mainactionPTDSReturn = "upload";
                  PTDSReturnStatus = "review";
                  POptStatusArray[3].status = PTDSReturnStatus;
                  this.setState(
                    {
                      PTDSReturnDocLink: doc[2].TDSReturn,
                    },
                    () => {
                      $("#viewDocLink_PTDSReturn").css("display", "block");
                      $("#choose_PTDSReturn").hide();
                      $("#X-btn_PTDSReturn").hide();
                      $("#done-btn_PTDSReturn").hide();
                      $("#review-btn_PTDSReturn").show();
                    }
                  );
                } else if (doc[2].status == "reupload") {
                  mainactionPTDSReturn = "reupload";
                  PTDSReturnStatus = "reupload";
                  POptStatusArray[3].status = PTDSReturnStatus;
                  this.setState({}, () => {
                    $("#choose_PTDSReturn").show();
                    $("#viewDocLink_PTDSReturn").hide();
                    $("#X-btn_PTDSReturn").hide();
                    $("#done-btn_PTDSReturn").hide();
                    $("#review-btn_PTDSReturn").hide();
                  });
                } else if (doc[2].status == "done") {
                  mainactionPTDSReturn = "upload";
                  PTDSReturnStatus = "done";
                  POptStatusArray[3].status = PTDSReturnStatus;
                  this.setState(
                    {
                      PTDSReturnDocLink: doc[2].TDSReturn,
                    },
                    () => {
                      $("#viewDocLink_PTDSReturn").css("display", "block");
                      $("#choose_PTDSReturn").hide();
                      $("#X-btn_PTDSReturn").hide();
                      $("#done-btn_PTDSReturn").show();
                      $("#review-btn_PTDSReturn").hide();
                    }
                  );
                } else {
                  mainactionPTDSReturn = "upload";
                  PTDSReturnStatus = "open";
                  POptStatusArray[3].status = PTDSReturnStatus;
                  $("#viewDocLink_PTDSReturn").hide();
                  $("#choose_PTDSReturn").show();
                  $("#X-btn_PTDSReturn").hide();
                  $("#done-btn_PTDSReturn").hide();
                  $("#review-btn_PTDSReturn").hide();
                }
                if (doc[3].status == "remove") {
                  mainactionPChallan = "upload";
                  PChallanStatus = "remove";
                  POptStatusArray[4].status = PChallanStatus;
                  this.setState(
                    {
                      PChallanDocLink: doc[3].Challan,
                    },
                    () => {
                      $("#viewDocLink_PChallan").css("display", "block");
                      $("#choose_PChallan").hide();
                      $("#X-btn_PChallan").show();
                      $("#done-btn_PChallan").hide();
                      $("#review-btn_PChallan").hide();
                    }
                  );
                } else if (doc[3].status == "review") {
                  mainactionPChallan = "upload";
                  PChallanStatus = "review";
                  POptStatusArray[4].status = PChallanStatus;
                  this.setState(
                    {
                      PChallanDocLink: doc[3].Challan,
                    },
                    () => {
                      $("#viewDocLink_PChallan").css("display", "block");
                      $("#choose_PChallan").hide();
                      $("#X-btn_PChallan").hide();
                      $("#done-btn_PChallan").hide();
                      $("#review-btn_PChallan").show();
                    }
                  );
                } else if (doc[3].status == "reupload") {
                  mainactionPChallan = "reupload";
                  PChallanStatus = "reupload";
                  POptStatusArray[4].status = PChallanStatus;
                  this.setState({}, () => {
                    $("#choose_PChallan").show();
                    $("#viewDocLink_PChallan").hide();
                    $("#X-btn_PChallan").hide();
                    $("#done-btn_PChallan").hide();
                    $("#review-btn_PChallan").hide();
                  });
                } else if (doc[3].status == "done") {
                  mainactionPChallan = "upload";
                  PChallanStatus = "done";
                  POptStatusArray[4].status = PChallanStatus;
                  this.setState(
                    {
                      PChallanDocLink: doc[3].Challan,
                    },
                    () => {
                      $("#viewDocLink_PChallan").css("display", "block");
                      $("#choose_PChallan").hide();
                      $("#X-btn_PChallan").hide();
                      $("#done-btn_PChallan").show();
                      $("#review-btn_PChallan").hide();
                    }
                  );
                } else {
                  mainactionPChallan = "upload";
                  PChallanStatus = "open";
                  POptStatusArray[4].status = PChallanStatus;
                  $("#viewDocLink_PChallan").hide();
                  $("#choose_PChallan").show();
                  $("#X-btn_PChallan").hide();
                  $("#done-btn_PChallan").hide();
                  $("#review-btn_PChallan").hide();
                }
                if (doc[4].status == "remove") {
                  mainactionPBankS = "upload";
                  PBankSStatus = "remove";
                  PStatusArray[4].status = PBankSStatus;
                  this.setState(
                    {
                      PBankSDocLink: doc[4].BankStatement,
                    },
                    () => {
                      $("#viewDocLink_PBankS").css("display", "block");
                      $("#choose_PBankS").hide();
                      $("#X-btn_PBankS").show();
                      $("#done-btn_PBankS").hide();
                      $("#review-btn_PBankS").hide();
                    }
                  );
                } else if (doc[4].status == "review") {
                  mainactionPBankS = "upload";
                  PBankSStatus = "review";
                  PStatusArray[4].status = PBankSStatus;
                  this.setState(
                    {
                      PBankSDocLink: doc[4].BankStatement,
                    },
                    () => {
                      $("#viewDocLink_PBankS").css("display", "block");
                      $("#choose_PBankS").hide();
                      $("#X-btn_PBankS").hide();
                      $("#done-btn_PBankS").hide();
                      $("#review-btn_PBankS").show();
                    }
                  );
                } else if (doc[4].status == "reupload") {
                  mainactionPBankS = "reupload";
                  PBankSStatus = "reupload";
                  PStatusArray[4].status = PBankSStatus;
                  this.setState({}, () => {
                    $("#choose_PBankS").show();
                    $("#viewDocLink_PBankS").hide();
                    $("#X-btn_PBankS").hide();
                    $("#done-btn_PBankS").hide();
                    $("#review-btn_PBankS").hide();
                  });
                } else if (doc[4].status == "done") {
                  mainactionPBankS = "upload";
                  PBankSStatus = "done";
                  PStatusArray[4].status = PBankSStatus;
                  this.setState(
                    {
                      PBankSDocLink: doc[4].BankStatement,
                    },
                    () => {
                      $("#viewDocLink_PBankS").css("display", "block");
                      $("#choose_PBankS").hide();
                      $("#X-btn_PBankS").hide();
                      $("#done-btn_PBankS").show();
                      $("#review-btn_PBankS").hide();
                    }
                  );
                } else {
                  mainactionPBankS = "upload";
                  PBankSStatus = "open";
                  PStatusArray[4].status = PBankSStatus;
                  $("#viewDocLink_PBankS").hide();
                  $("#choose_PBankS").show();
                  $("#X-btn_PBankS").hide();
                  $("#done-btn_PBankS").hide();
                  $("#review-btn_PBankS").hide();
                }
              } else {
                $("#viewDocLink_PData").hide();
                $("#choose_PData").show();
                $("#X-btn_PData").hide();
                $("#done-btn_PData").hide();
                $("#review-btn_PData").hide();

                $("#viewDocLink_PBankS").hide();
                $("#choose_PBankS").show();
                $("#X-btn_PBankS").hide();
                $("#done-btn_PBankS").hide();
                $("#review-btn_PBankS").hide();

                $("#viewDocLink_PGSTReturn").hide();
                $("#choose_PGSTReturn").show();
                $("#X-btn_PGSTReturn").hide();
                $("#done-btn_PGSTReturn").hide();
                $("#review-btn_PGSTReturn").hide();

                $("#viewDocLink_PTDSReturn").hide();
                $("#choose_PTDSReturn").show();
                $("#X-btn_PTDSReturn").hide();
                $("#done-btn_PTDSReturn").hide();
                $("#review-btn_PTDSReturn").hide();

                $("#viewDocLink_PChallan").hide();
                $("#choose_PChallan").show();
                $("#X-btn_PChallan").hide();
                $("#done-btn_PChallan").hide();
                $("#review-btn_PChallan").hide();
              }
              if (
                res.data.fyStatus == "lock" ||
                res.data.fyStatus == "complete"
              ) {
                $(".proceednowbtn").hide();
                if (res.data.incD[0].documents.length == 0) {
                  $("#PincDadditionalDocumentDiv").hide();
                } else {
                  $("#PincDadddocumentbtn").hide();
                }
                if (res.data.addDocInformation.length == 0) {
                  $("#PadditionalDocumentDiv").hide();
                } else {
                  $("#Padddocumentbtn").hide();
                }
              }
              if (res.data.incD[0].documents.length > 0) {
                $("#PincDadditionalDocumentDiv").show();
                $("#PincDadddocumentbtn").hide();
                this.setState(
                  {
                    PincDAdditionalFiles: [...res.data.incD[0].documents],
                    PincDAdd_DocName: "File Name",
                    api_PincDAdditionalFiles: "Yes",
                  },
                  () => {}
                );
              } else if (
                res.data.incD[0].documents.length == 0 &&
                res.data.fyStatus != "lock" &&
                res.data.fyStatus != "complete"
              ) {
                $("#PincDadditionalDocumentDiv").show();
                this.setState({
                  PincDAdd_DocName: "",
                  PincDAdditionalFiles: [1],
                  api_PincDAdditionalFiles: "No",
                });
              }
              if (res.data.addDocInformation.length > 0) {
                $("#PadditionalDocumentDiv").show();
                $("#Padddocumentbtn").hide();
                this.setState(
                  {
                    PAdditionalFiles: [...res.data.addDocInformation],
                    PAdd_DocName: "File Name",
                    api_PAdditionalFiles: "Yes",
                  },
                  () => {}
                );
              } else if (
                res.data.addDocInformation.length == 0 &&
                res.data.fyStatus != "lock" &&
                res.data.fyStatus != "complete"
              ) {
                $("#PadditionalDocumentDiv").show();
                $("#Padddocumentbtn").show();
                this.setState({
                  PAdd_DocName: "",
                  PAdditionalFiles: [1],
                  api_PAdditionalFiles: "No",
                });
              }

              this.PcheckRemainingReuploads();
              let statusCheck = this.statusCheckFunction(
                "reupload",
                PStatusArray
              );
              PincDadditionalStatusArrayCheck = this.statusCheckFunction(
                "reupload",
                this.state.PincDAdditionalFiles
              );
              PadditionalStatusArrayCheck = this.statusCheckFunction(
                "reupload",
                this.state.PAdditionalFiles
              );

              if (
                AnyReuploadOccuredP == "Yes" &&
                statusCheck == undefined &&
                PincDadditionalStatusArrayCheck == undefined &&
                PadditionalStatusArrayCheck == undefined
              ) {
                POptReview = "Yes";
                this.countOptReuploadP("reupload", POptStatusArray);
              } else {
                POptReview = "No";
              }
              if (
                res.data.rejectstatus == "false" &&
                res.data.mainstatus == "true"
              ) {
                rejectstatus = "Yes";
              }
              // this.PcheckRemainingReuploads();
              // this.checkhandlePProceedBtn();
            } else if (res.data.serviceType == "ITRF_BPP_OneTime_Documents") {
              $(".selectcateselect1").attr("disabled", true);
              this.setState(
                {
                  dropDownValue: "ITRF_BPP_OneTime_Documents",
                  serviceType: "ITRF_BPP_OneTime_Documents",
                },
                () => {
                  if (
                    this.state.dropDownValue == "ITRF_BPP_OneTime_Documents"
                  ) {
                    $("#Itrf_default_Img").hide();
                    $("#ItrfSalariedSection").hide();
                    $("#ItrfproprietorSection").hide();
                    $("#ItrfPartnershipFirm").show();
                    $("#ItrfCompany_LLP").hide();
                  }
                }
              );
              if (res.data.oneTime.length > 0) {
                if (res.data.oneTime[0].documents.length > 0) {
                  let onetime = res.data.oneTime[0].documents;
                  if (onetime[0].status == "remove") {
                    mainactionPFAadhar1 = "upload";
                    PFAadhar1Status = "remove";
                    PFStatusArray[0].status = PFAadhar1Status;
                    this.setState(
                      {
                        PFAadhar1DocLink: onetime[0].AadhaarCardApplicant1,
                      },
                      () => {
                        $("#viewDocLink_PFAadhar1").css("display", "block");
                        $("#choose_PFAadhar1").hide();
                        $("#X-btn_PFAadhar1").show();
                        $("#done-btn_PFAadhar1").hide();
                        $("#review-btn_PFAadhar1").hide();
                      }
                    );
                  } else if (onetime[0].status == "review") {
                    mainactionPFAadhar1 = "upload";
                    PFAadhar1Status = "review";
                    PFStatusArray[0].status = PFAadhar1Status;
                    this.setState(
                      {
                        PFAadhar1DocLink: onetime[0].AadhaarCardApplicant1,
                      },
                      () => {
                        $("#viewDocLink_PFAadhar1").css("display", "block");
                        $("#choose_PFAadhar1").hide();
                        $("#X-btn_PFAadhar1").hide();
                        $("#done-btn_PFAadhar1").hide();
                        $("#review-btn_PFAadhar1").show();
                      }
                    );
                  } else if (onetime[0].status == "reupload") {
                    mainactionPFAadhar1 = "reupload";
                    PFAadhar1Status = "reupload";
                    PFStatusArray[0].status = PFAadhar1Status;
                    this.setState({}, () => {
                      $("#choose_PFAadhar1").show();
                      $("#viewDocLink_PFAadhar1").hide();
                      $("#X-btn_PFAadhar1").hide();
                      $("#done-btn_PFAadhar1").hide();
                      $("#review-btn_PFAadhar1").hide();
                    });
                  } else if (onetime[0].status == "done") {
                    mainactionPFAadhar1 = "upload";
                    PFAadhar1Status = "done";
                    PFStatusArray[0].status = PFAadhar1Status;
                    this.setState(
                      {
                        PFAadhar1DocLink: onetime[0].AadhaarCardApplicant1,
                      },
                      () => {
                        $("#viewDocLink_PFAadhar1").css("display", "block");
                        $("#choose_PFAadhar1").hide();
                        $("#X-btn_PFAadhar1").hide();
                        $("#done-btn_PFAadhar1").show();
                        $("#review-btn_PFAadhar1").hide();
                      }
                    );
                  } else {
                    mainactionPFAadhar1 = "upload";
                    PFAadhar1Status = "open";
                    PFStatusArray[0].status = PFAadhar1Status;
                    $("#viewDocLink_PFAadhar1").hide();
                    $("#choose_PFAadhar1").show();
                    $("#X-btn_PFAadhar1").hide();
                    $("#done-btn_PFAadhar1").hide();
                    $("#review-btn_PFAadhar1").hide();
                  }
                  if (onetime[1].status == "remove") {
                    mainactionPFAadhar2 = "upload";
                    PFAadhar2Status = "remove";
                    PFStatusArray[1].status = PFAadhar2Status;
                    this.setState(
                      {
                        PFAadhar2DocLink: onetime[1].AadhaarCardApplicant2,
                      },
                      () => {
                        $("#viewDocLink_PFAadhar2").css("display", "block");
                        $("#choose_PFAadhar2").hide();
                        $("#X-btn_PFAadhar2").show();
                        $("#done-btn_PFAadhar2").hide();
                        $("#review-btn_PFAadhar2").hide();
                      }
                    );
                  } else if (onetime[1].status == "review") {
                    mainactionPFAadhar2 = "upload";
                    PFAadhar2Status = "review";
                    PFStatusArray[1].status = PFAadhar2Status;
                    this.setState(
                      {
                        PFAadhar2DocLink: onetime[1].AadhaarCardApplicant2,
                      },
                      () => {
                        $("#viewDocLink_PFAadhar2").css("display", "block");
                        $("#choose_PFAadhar2").hide();
                        $("#X-btn_PFAadhar2").hide();
                        $("#done-btn_PFAadhar2").hide();
                        $("#review-btn_PFAadhar2").show();
                      }
                    );
                  } else if (onetime[1].status == "reupload") {
                    mainactionPFAadhar2 = "reupload";
                    PFAadhar2Status = "reupload";
                    PFStatusArray[1].status = PFAadhar2Status;
                    this.setState({}, () => {
                      $("#choose_PFAadhar2").show();
                      $("#viewDocLink_PFAadhar2").hide();
                      $("#X-btn_PFAadhar2").hide();
                      $("#done-btn_PFAadhar2").hide();
                      $("#review-btn_PFAadhar2").hide();
                    });
                  } else if (onetime[1].status == "done") {
                    mainactionPFAadhar2 = "upload";
                    PFAadhar2Status = "done";
                    PFStatusArray[1].status = PFAadhar2Status;
                    this.setState(
                      {
                        PFAadhar2DocLink: onetime[1].AadhaarCardApplicant2,
                      },
                      () => {
                        $("#viewDocLink_PFAadhar2").css("display", "block");
                        $("#choose_PFAadhar2").hide();
                        $("#X-btn_PFAadhar2").hide();
                        $("#done-btn_PFAadhar2").show();
                        $("#review-btn_PFAadhar2").hide();
                      }
                    );
                  } else {
                    mainactionPFAadhar2 = "upload";
                    PFAadhar2Status = "open";
                    PFStatusArray[1].status = PFAadhar2Status;
                    $("#viewDocLink_PFAadhar2").hide();
                    $("#choose_PFAadhar2").show();
                    $("#X-btn_PFAadhar2").hide();
                    $("#done-btn_PFAadhar2").hide();
                    $("#review-btn_PFAadhar2").hide();
                  }
                  if (onetime[2].status == "remove") {
                    mainactionPFPan1 = "upload";
                    PFPan1Status = "remove";
                    PFStatusArray[2].status = PFPan1Status;
                    this.setState(
                      {
                        PFPan1DocLink: onetime[2].PANCardApplicant1,
                      },
                      () => {
                        $("#viewDocLink_PFPan1").css("display", "block");
                        $("#choose_PFPan1").hide();
                        $("#X-btn_PFPan1").show();
                        $("#done-btn_PFPan1").hide();
                        $("#review-btn_PFPan1").hide();
                      }
                    );
                  } else if (onetime[2].status == "review") {
                    mainactionPFPan1 = "upload";
                    PFPan1Status = "review";
                    PFStatusArray[2].status = PFPan1Status;
                    this.setState(
                      {
                        PFPan1DocLink: onetime[2].PANCardApplicant1,
                      },
                      () => {
                        $("#viewDocLink_PFPan1").css("display", "block");
                        $("#choose_PFPan1").hide();
                        $("#X-btn_PFPan1").hide();
                        $("#done-btn_PFPan1").hide();
                        $("#review-btn_PFPan1").show();
                      }
                    );
                  } else if (onetime[2].status == "reupload") {
                    mainactionPFPan1 = "reupload";
                    PFPan1Status = "reupload";
                    PFStatusArray[2].status = PFPan1Status;
                    this.setState({}, () => {
                      $("#choose_PFPan1").show();
                      $("#viewDocLink_PFPan1").hide();
                      $("#X-btn_PFPan1").hide();
                      $("#done-btn_PFPan1").hide();
                      $("#review-btn_PFPan1").hide();
                    });
                  } else if (onetime[2].status == "done") {
                    mainactionPFPan1 = "upload";
                    PFPan1Status = "done";
                    PFStatusArray[2].status = PFPan1Status;
                    this.setState(
                      {
                        PFPan1DocLink: onetime[2].PANCardApplicant1,
                      },
                      () => {
                        $("#choose_PFPan1").hide();
                        $("#viewDocLink_PFPan1").css("display", "block");
                        $("#X-btn_PFPan1").hide();
                        $("#done-btn_PFPan1").show();
                        $("#review-btn_PFPan1").hide();
                      }
                    );
                  } else {
                    mainactionPFPan1 = "upload";
                    PFPan1Status = "open";
                    PFStatusArray[2].status = PFPan1Status;
                    $("#viewDocLink_PFPan1").hide();
                    $("#choose_PFPan1").show();
                    $("#X-btn_PFPan1").hide();
                    $("#done-btn_PFPan1").hide();
                    $("#review-btn_PFPan1").hide();
                  }
                  if (onetime[3].status == "remove") {
                    mainactionPFPan2 = "upload";
                    PFPan2Status = "remove";
                    PFStatusArray[3].status = PFPan2Status;
                    this.setState(
                      {
                        PFPan2DocLink: onetime[3].PANCardApplicant2,
                      },
                      () => {
                        $("#viewDocLink_PFPan2").css("display", "block");
                        $("#choose_PFPan2").hide();
                        $("#X-btn_PFPan2").show();
                        $("#done-btn_PFPan2").hide();
                        $("#review-btn_PFPan2").hide();
                      }
                    );
                  } else if (onetime[3].status == "review") {
                    mainactionPFPan2 = "upload";
                    PFPan2Status = "review";
                    PFStatusArray[3].status = PFPan2Status;
                    this.setState(
                      {
                        PFPan2DocLink: onetime[3].PANCardApplicant2,
                      },
                      () => {
                        $("#viewDocLink_PFPan2").css("display", "block");
                        $("#choose_PFPan2").hide();
                        $("#X-btn_PFPan2").hide();
                        $("#done-btn_PFPan2").hide();
                        $("#review-btn_PFPan2").show();
                      }
                    );
                  } else if (onetime[3].status == "reupload") {
                    mainactionPFPan2 = "reupload";
                    PFPan2Status = "reupload";
                    PFStatusArray[3].status = PFPan2Status;
                    this.setState({}, () => {
                      $("#choose_PFPan2").show();
                      $("#viewDocLink_PFPan2").hide();
                      $("#X-btn_PFPan2").hide();
                      $("#done-btn_PFPan2").hide();
                      $("#review-btn_PFPan2").hide();
                    });
                  } else if (onetime[3].status == "done") {
                    mainactionPFPan2 = "upload";
                    PFPan2Status = "done";
                    PFStatusArray[3].status = PFPan2Status;
                    this.setState(
                      {
                        PFPan2DocLink: onetime[3].PANCardApplicant2,
                      },
                      () => {
                        $("#choose_PFPan2").hide();
                        $("#viewDocLink_PFPan2").css("display", "block");
                        $("#X-btn_PFPan2").hide();
                        $("#done-btn_PFPan2").show();
                        $("#review-btn_PFPan2").hide();
                      }
                    );
                  } else {
                    mainactionPFPan2 = "upload";
                    PFPan2Status = "open";
                    PFStatusArray[3].status = PFPan2Status;
                    $("#viewDocLink_PFPan2").hide();
                    $("#choose_PFPan2").show();
                    $("#X-btn_PFPan2").hide();
                    $("#done-btn_PFPan2").hide();
                    $("#review-btn_PFPan2").hide();
                  }
                  if (onetime[4].status == "remove") {
                    mainactionPFDeed = "upload";
                    PFDeedStatus = "remove";
                    PFStatusArray[4].status = PFDeedStatus;
                    this.setState(
                      {
                        PFDeedDocLink: onetime[4].Deed,
                      },
                      () => {
                        $("#viewDocLink_PFDeed").css("display", "block");
                        $("#choose_PFDeed").hide();
                        $("#X-btn_PFDeed").show();
                        $("#done-btn_PFDeed").hide();
                        $("#review-btn_PFDeed").hide();
                      }
                    );
                  } else if (onetime[4].status == "review") {
                    mainactionPFDeed = "upload";
                    PFDeedStatus = "review";
                    PFStatusArray[4].status = PFDeedStatus;
                    this.setState(
                      {
                        PFDeedDocLink: onetime[4].Deed,
                      },
                      () => {
                        $("#viewDocLink_PFDeed").css("display", "block");
                        $("#choose_PFDeed").hide();
                        $("#X-btn_PFDeed").hide();
                        $("#done-btn_PFDeed").hide();
                        $("#review-btn_PFDeed").show();
                      }
                    );
                  } else if (onetime[4].status == "reupload") {
                    mainactionPFDeed = "reupload";
                    PFDeedStatus = "reupload";
                    PFStatusArray[4].status = PFDeedStatus;
                    this.setState({}, () => {
                      $("#choose_PFDeed").show();
                      $("#viewDocLink_PFDeed").hide();
                      $("#X-btn_PFDeed").hide();
                      $("#done-btn_PFDeed").hide();
                      $("#review-btn_PFDeed").hide();
                    });
                  } else if (onetime[4].status == "done") {
                    mainactionPFDeed = "upload";
                    PFDeedStatus = "done";
                    PFStatusArray[4].status = PFDeedStatus;
                    this.setState(
                      {
                        PFDeedDocLink: onetime[4].Deed,
                      },
                      () => {
                        $("#choose_PFDeed").hide();
                        $("#viewDocLink_PFDeed").css("display", "block");
                        $("#X-btn_PFDeed").hide();
                        $("#done-btn_PFDeed").show();
                        $("#review-btn_PFDeed").hide();
                      }
                    );
                  } else {
                    mainactionPFDeed = "upload";
                    PFDeedStatus = "open";
                    PFStatusArray[4].status = PFDeedStatus;
                    $("#viewDocLink_PFDeed").hide();
                    $("#choose_PFDeed").show();
                    $("#X-btn_PFDeed").hide();
                    $("#done-btn_PFDeed").hide();
                    $("#review-btn_PFDeed").hide();
                  }
                  if (onetime[5].status == "remove") {
                    mainactionPFBP = "upload";
                    PFBPStatus = "remove";
                    PFStatusArray[5].status = PFBPStatus;
                    this.setState(
                      {
                        PFBPDocLink: onetime[5].BankProof,
                      },
                      () => {
                        $("#viewDocLink_PFBP").css("display", "block");
                        $("#choose_PFBP").hide();
                        $("#X-btn_PFBP").show();
                        $("#done-btn_PFBP").hide();
                        $("#review-btn_PFBP").hide();
                      }
                    );
                  } else if (onetime[5].status == "review") {
                    mainactionPFBP = "upload";
                    PFBPStatus = "review";
                    PFStatusArray[5].status = PFBPStatus;
                    this.setState(
                      {
                        PFBPDocLink: onetime[5].BankProof,
                      },
                      () => {
                        $("#viewDocLink_PFBP").css("display", "block");
                        $("#choose_PFBP").hide();
                        $("#X-btn_PFBP").hide();
                        $("#done-btn_PFBP").hide();
                        $("#review-btn_PFBP").show();
                      }
                    );
                  } else if (onetime[5].status == "reupload") {
                    mainactionPFBP = "reupload";
                    PFBPStatus = "reupload";
                    PFStatusArray[5].status = PFBPStatus;
                    this.setState({}, () => {
                      $("#choose_PFBP").show();
                      $("#viewDocLink_PFBP").hide();
                      $("#X-btn_PFBP").hide();
                      $("#done-btn_PFBP").hide();
                      $("#review-btn_PFBP").hide();
                    });
                  } else if (onetime[5].status == "done") {
                    mainactionPFBP = "upload";
                    PFBPStatus = "done";
                    PFStatusArray[5].status = PFBPStatus;
                    this.setState(
                      {
                        PFBPDocLink: onetime[5].BankProof,
                      },
                      () => {
                        $("#viewDocLink_PFBP").css("display", "block");
                        $("#choose_PFBP").hide();
                        $("#X-btn_PFBP").hide();
                        $("#done-btn_PFBP").show();
                        $("#review-btn_PFBP").hide();
                      }
                    );
                  } else {
                    mainactionPFBP = "upload";
                    PFBPStatus = "open";
                    PFStatusArray[5].status = PFBPStatus;
                    $("#viewDocLink_PFBP").hide();
                    $("#choose_PFBP").show();
                    $("#X-btn_PFBP").hide();
                    $("#done-btn_PFBP").hide();
                    $("#review-btn_PFBP").hide();
                  }
                  if (onetime[6].status == "remove") {
                    mainactionPFRC = "upload";
                    PFRCStatus = "remove";
                    PFOptStatusArray[5].status = PFRCStatus;
                    this.setState(
                      {
                        PFRCDocLink: onetime[6].RegistrationCertificate,
                      },
                      () => {
                        $("#viewDocLink_PFRC").css("display", "block");
                        $("#choose_PFRC").hide();
                        $("#X-btn_PFRC").show();
                        $("#done-btn_PFRC").hide();
                        $("#review-btn_PFRC").hide();
                      }
                    );
                  } else if (onetime[6].status == "review") {
                    mainactionPFRC = "upload";
                    PFRCStatus = "review";
                    PFOptStatusArray[5].status = PFRCStatus;
                    this.setState(
                      {
                        PFRCDocLink: onetime[6].RegistrationCertificate,
                      },
                      () => {
                        $("#viewDocLink_PFRC").css("display", "block");
                        $("#choose_PFRC").hide();
                        $("#X-btn_PFRC").hide();
                        $("#done-btn_PFRC").hide();
                        $("#review-btn_PFRC").show();
                      }
                    );
                  } else if (onetime[6].status == "reupload") {
                    mainactionPFRC = "reupload";
                    PFRCStatus = "reupload";
                    PFOptStatusArray[5].status = PFRCStatus;
                    this.setState({}, () => {
                      $("#choose_PFRC").show();
                      $("#viewDocLink_PFRC").hide();
                      $("#X-btn_PFRC").hide();
                      $("#done-btn_PFRC").hide();
                      $("#review-btn_PFRC").hide();
                    });
                  } else if (onetime[6].status == "done") {
                    mainactionPFRC = "upload";
                    PFRCStatus = "done";
                    PFOptStatusArray[5].status = PFRCStatus;
                    this.setState(
                      {
                        PFRCDocLink: onetime[6].RegistrationCertificate,
                      },
                      () => {
                        $("#choose_PFRC").hide();
                        $("#viewDocLink_PFRC").css("display", "block");
                        $("#X-btn_PFRC").hide();
                        $("#done-btn_PFRC").show();
                        $("#review-btn_PFRC").hide();
                      }
                    );
                  } else {
                    mainactionPFRC = "upload";
                    PFRCStatus = "open";
                    PFOptStatusArray[5].status = PFRCStatus;
                    $("#viewDocLink_PFRC").hide();
                    $("#choose_PFRC").show();
                    $("#X-btn_PFRC").hide();
                    $("#done-btn_PFRC").hide();
                    $("#review-btn_PFRC").hide();
                  }
                  if (onetime[7].status == "remove") {
                    mainactionPFPBS_A = "upload";
                    PFPBS_AStatus = "remove";
                    PFOptStatusArray[0].status = PFPBS_AStatus;
                    this.setState(
                      {
                        PFPBS_ADocLink: onetime[7].PreviousBS_Audit,
                      },
                      () => {
                        $("#viewDocLink_PFPBS_A").css("display", "block");
                        $("#choose_PFPBS_A").hide();
                        $("#X-btn_PFPBS_A").show();
                        $("#done-btn_PFPBS_A").hide();
                        $("#review-btn_PFPBS_A").hide();
                      }
                    );
                  } else if (onetime[7].status == "review") {
                    mainactionPFPBS_A = "upload";
                    PFPBS_AStatus = "review";
                    PFOptStatusArray[0].status = PFPBS_AStatus;
                    this.setState(
                      {
                        PFPBS_ADocLink: onetime[7].PreviousBS_Audit,
                      },
                      () => {
                        $("#viewDocLink_PFPBS_A").css("display", "block");
                        $("#choose_PFPBS_A").hide();
                        $("#X-btn_PFPBS_A").hide();
                        $("#done-btn_PFPBS_A").hide();
                        $("#review-btn_PFPBS_A").show();
                      }
                    );
                  } else if (onetime[7].status == "reupload") {
                    mainactionPFPBS_A = "reupload";
                    PFPBS_AStatus = "reupload";
                    PFOptStatusArray[0].status = PFPBS_AStatus;
                    this.setState({}, () => {
                      $("#choose_PFPBS_A").show();
                      $("#viewDocLink_PFPBS_A").hide();
                      $("#X-btn_PFPBS_A").hide();
                      $("#done-btn_PFPBS_A").hide();
                      $("#review-btn_PFPBS_A").hide();
                    });
                  } else if (onetime[7].status == "done") {
                    mainactionPFPBS_A = "upload";
                    PFPBS_AStatus = "done";
                    PFOptStatusArray[0].status = PFPBS_AStatus;
                    this.setState(
                      {
                        PFPBS_ADocLink: onetime[7].PreviousBS_Audit,
                      },
                      () => {
                        $("#choose_PFPBS_A").hide();
                        $("#viewDocLink_PFPBS_A").css("display", "block");
                        $("#X-btn_PFPBS_A").hide();
                        $("#done-btn_PFPBS_A").show();
                        $("#review-btn_PFPBS_A").hide();
                      }
                    );
                  } else {
                    mainactionPFPBS_A = "upload";
                    PFPBS_AStatus = "open";
                    PFOptStatusArray[0].status = PFPBS_AStatus;
                    $("#viewDocLink_PFPBS_A").hide();
                    $("#choose_PFPBS_A").show();
                    $("#X-btn_PFPBS_A").hide();
                    $("#done-btn_PFPBS_A").hide();
                    $("#review-btn_PFPBS_A").hide();
                  }
                  if (onetime[8].status == "remove") {
                    mainactionPFPR = "upload";
                    PFPRStatus = "remove";
                    PFOptStatusArray[1].status = PFPRStatus;
                    this.setState(
                      {
                        PFPRDocLink: onetime[8].PreviousReturns,
                      },
                      () => {
                        $("#viewDocLink_PFPR").css("display", "block");
                        $("#choose_PFPR").hide();
                        $("#X-btn_PFPR").show();
                        $("#done-btn_PFPR").hide();
                        $("#review-btn_PFPR").hide();
                      }
                    );
                  } else if (onetime[8].status == "review") {
                    mainactionPFPR = "upload";
                    PFPRStatus = "review";
                    PFOptStatusArray[1].status = PFPRStatus;
                    this.setState(
                      {
                        PFPRDocLink: onetime[8].PreviousReturns,
                      },
                      () => {
                        $("#viewDocLink_PFPR").css("display", "block");
                        $("#choose_PFPR").hide();
                        $("#X-btn_PFPR").hide();
                        $("#done-btn_PFPR").hide();
                        $("#review-btn_PFPR").show();
                      }
                    );
                  } else if (onetime[8].status == "reupload") {
                    mainactionPFPR = "reupload";
                    PFPRStatus = "reupload";
                    PFOptStatusArray[1].status = PFPRStatus;
                    this.setState({}, () => {
                      $("#choose_PFPR").show();
                      $("#viewDocLink_PFPR").hide();
                      $("#X-btn_PFPR").hide();
                      $("#done-btn_PFPR").hide();
                      $("#review-btn_PFPR").hide();
                    });
                  } else if (onetime[8].status == "done") {
                    mainactionPFPR = "upload";
                    PFPRStatus = "done";
                    PFOptStatusArray[1].status = PFPRStatus;
                    this.setState(
                      {
                        PFPRDocLink: onetime[8].PreviousReturns,
                      },
                      () => {
                        $("#choose_PFPR").hide();
                        $("#viewDocLink_PFPR").css("display", "block");
                        $("#X-btn_PFPR").hide();
                        $("#done-btn_PFPR").show();
                        $("#review-btn_PFPR").hide();
                      }
                    );
                  } else {
                    mainactionPFPR = "upload";
                    PFPRStatus = "open";
                    PFOptStatusArray[1].status = PFPRStatus;
                    $("#viewDocLink_PFPR").hide();
                    $("#choose_PFPR").show();
                    $("#X-btn_PFPR").hide();
                    $("#done-btn_PFPR").hide();
                    $("#review-btn_PFPR").hide();
                  }
                }
              } else {
                $("#viewDocLink_PFAadhar1").hide();
                $("#choose_PFAadhar1").show();
                $("#X-btn_PFAadhar1").hide();
                $("#done-btn_PFAadhar1").hide();
                $("#review-btn_PFAadhar1").hide();

                $("#viewDocLink_PFAadhar2").hide();
                $("#choose_PFAadhar2").show();
                $("#X-btn_PFAadhar2").hide();
                $("#done-btn_PFAadhar2").hide();
                $("#review-btn_PFAadhar2").hide();

                $("#viewDocLink_PFPan1").hide();
                $("#choose_PFPan1").show();
                $("#X-btn_PFPan1").hide();
                $("#done-btn_PFPan1").hide();
                $("#review-btn_PFPan1").hide();

                $("#viewDocLink_PFPan2").hide();
                $("#choose_PFPan2").show();
                $("#X-btn_PFPan2").hide();
                $("#done-btn_PFPan2").hide();
                $("#review-btn_PFPan2").hide();

                $("#viewDocLink_PFDeed").hide();
                $("#choose_PFDeed").show();
                $("#X-btn_PFDeed").hide();
                $("#done-btn_PFDeed").hide();
                $("#review-btn_PFDeed").hide();

                $("#viewDocLink_PFBP").hide();
                $("#choose_PFBP").show();
                $("#X-btn_PFBP").hide();
                $("#done-btn_PFBP").hide();
                $("#review-btn_PFBP").hide();

                $("#viewDocLink_PFRC").hide();
                $("#choose_PFRC").show();
                $("#X-btn_PFRC").hide();
                $("#done-btn_PFRC").hide();
                $("#review-btn_PFRC").hide();

                $("#viewDocLink_PFPBS_A").hide();
                $("#choose_PFPBS_A").show();
                $("#X-btn_PFPBS_A").hide();
                $("#done-btn_PFPBS_A").hide();
                $("#review-btn_PFPBS_A").hide();

                $("#viewDocLink_PFPR").hide();
                $("#choose_PFPR").show();
                $("#X-btn_PFPR").hide();
                $("#done-btn_PFPR").hide();
                $("#review-btn_PFPR").hide();
              }
              if (
                res.data.fyStatus == "lock" ||
                res.data.fyStatus == "complete"
              ) {
                $(".proceednowbtn").hide();
                if (res.data.incD[0].documents.length == 0) {
                  $("#PFincDadditionalDocumentDiv").hide();
                } else {
                  $("#PFincDadddocumentbtn").hide();
                }
                if (res.data.addDocInformation.length == 0) {
                  $("#PFadditionalDocumentDiv").hide();
                } else {
                  $("#PFadddocumentbtn").hide();
                }
              }
              if (res.data.incD[0].documents.length > 0) {
                $("#PFincDadditionalDocumentDiv").show();
                $("#PFincDadddocumentbtn").hide();
                this.setState(
                  {
                    PFincDAdditionalFiles: [...res.data.incD[0].documents],
                    PFincDAdd_DocName: "File Name",
                    api_PFincDAdditionalFiles: "Yes",
                  },
                  () => {}
                );
              } else if (
                res.data.incD[0].documents.length == 0 &&
                res.data.fyStatus != "lock" &&
                res.data.fyStatus != "complete"
              ) {
                $("#PFincDadditionalDocumentDiv").show();
                this.setState({
                  PFincDAdd_DocName: "",
                  PFincDAdditionalFiles: [1],
                  api_PFincDAdditionalFiles: "No",
                });
              }
              if (res.data.addDocInformation.length > 0) {
                $("#PFadditionalDocumentDiv").show();
                $("#PFadddocumentbtn").hide();
                this.setState(
                  {
                    PFAdditionalFiles: [...res.data.addDocInformation],
                    PFAdd_DocName: "File Name",
                    api_PFAdditionalFiles: "Yes",
                  },
                  () => {}
                );
              } else if (
                res.data.addDocInformation.length == 0 &&
                res.data.fyStatus != "lock" &&
                res.data.fyStatus != "complete"
              ) {
                $("#PFadditionalDocumentDiv").show();
                $("#PFadddocumentbtn").show();
                this.setState({
                  PFAdd_DocName: "",
                  PFAdditionalFiles: [1],
                  api_PFAdditionalFiles: "No",
                });
              }
              if (res.data.documents.length > 0) {
                let doc = res.data.documents;
                if (doc[0].status == "remove") {
                  mainactionPFData = "upload";
                  PFDataStatus = "remove";
                  PFStatusArray[6].status = PFDataStatus;
                  this.setState(
                    {
                      PFDataDocLink: doc[0].Data,
                    },
                    () => {
                      $("#viewDocLink_PFData").css("display", "block");
                      $("#choose_PFData").hide();
                      $("#X-btn_PFData").show();
                      $("#done-btn_PFData").hide();
                      $("#review-btn_PFData").hide();
                    }
                  );
                } else if (doc[0].status == "review") {
                  mainactionPFData = "upload";
                  PFDataStatus = "review";
                  PFStatusArray[6].status = PFDataStatus;
                  this.setState(
                    {
                      PFDataDocLink: doc[0].Data,
                    },
                    () => {
                      $("#viewDocLink_PFData").css("display", "block");
                      $("#choose_PFData").hide();
                      $("#X-btn_PFData").hide();
                      $("#done-btn_PFData").hide();
                      $("#review-btn_PFData").show();
                    }
                  );
                } else if (doc[0].status == "reupload") {
                  mainactionPFData = "reupload";
                  PFDataStatus = "reupload";
                  PFStatusArray[6].status = PFDataStatus;
                  this.setState({}, () => {
                    $("#choose_PFData").show();
                    $("#viewDocLink_PFData").hide();
                    $("#X-btn_PFData").hide();
                    $("#done-btn_PFData").hide();
                    $("#review-btn_PFData").hide();
                  });
                } else if (doc[0].status == "done") {
                  mainactionPFData = "upload";
                  PFDataStatus = "done";
                  PFStatusArray[6].status = PFDataStatus;
                  this.setState(
                    {
                      PFDataDocLink: doc[0].Data,
                    },
                    () => {
                      $("#choose_PFData").hide();
                      $("#viewDocLink_PFData").css("display", "block");
                      $("#X-btn_PFData").hide();
                      $("#done-btn_PFData").show();
                      $("#review-btn_PFData").hide();
                    }
                  );
                } else {
                  mainactionPFData = "upload";
                  PFDataStatus = "open";
                  PFStatusArray[6].status = PFDataStatus;
                  $("#viewDocLink_PFData").hide();
                  $("#choose_PFData").show();
                  $("#X-btn_PFData").hide();
                  $("#done-btn_PFData").hide();
                  $("#review-btn_PFData").hide();
                }

                if (doc[1].status == "remove") {
                  mainactionPFGSTReturn = "upload";
                  PFGSTReturnStatus = "remove";
                  PFOptStatusArray[2].status = PFGSTReturnStatus;
                  this.setState(
                    {
                      PFGSTReturnDocLink: doc[1].GSTReturn,
                    },
                    () => {
                      $("#viewDocLink_PFGSTReturn").css("display", "block");
                      $("#choose_PFGSTReturn").hide();
                      $("#X-btn_PFGSTReturn").show();
                      $("#done-btn_PFGSTReturn").hide();
                      $("#review-btn_PFGSTReturn").hide();
                    }
                  );
                } else if (doc[1].status == "review") {
                  mainactionPFGSTReturn = "upload";
                  PFGSTReturnStatus = "review";
                  PFOptStatusArray[2].status = PFGSTReturnStatus;
                  this.setState(
                    {
                      PFGSTReturnDocLink: doc[1].GSTReturn,
                    },
                    () => {
                      $("#viewDocLink_PFGSTReturn").css("display", "block");
                      $("#choose_PFGSTReturn").hide();
                      $("#X-btn_PFGSTReturn").hide();
                      $("#done-btn_PFGSTReturn").hide();
                      $("#review-btn_PFGSTReturn").show();
                    }
                  );
                } else if (doc[1].status == "reupload") {
                  mainactionPFGSTReturn = "reupload";
                  PFGSTReturnStatus = "reupload";
                  PFOptStatusArray[2].status = PFGSTReturnStatus;
                  this.setState({}, () => {
                    $("#choose_PFGSTReturn").show();
                    $("#viewDocLink_PFGSTReturn").hide();
                    $("#X-btn_PFGSTReturn").hide();
                    $("#done-btn_PFGSTReturn").hide();
                    $("#review-btn_PFGSTReturn").hide();
                  });
                } else if (doc[1].status == "done") {
                  mainactionPFGSTReturn = "upload";
                  PFGSTReturnStatus = "done";
                  PFOptStatusArray[2].status = PFGSTReturnStatus;
                  this.setState(
                    {
                      PFGSTReturnDocLink: doc[1].GSTReturn,
                    },
                    () => {
                      $("#viewDocLink_PFGSTReturn").css("display", "block");
                      $("#choose_PFGSTReturn").hide();
                      $("#X-btn_PFGSTReturn").hide();
                      $("#done-btn_PFGSTReturn").show();
                      $("#review-btn_PFGSTReturn").hide();
                    }
                  );
                } else {
                  mainactionPFGSTReturn = "upload";
                  PFGSTReturnStatus = "open";
                  PFOptStatusArray[2].status = PFGSTReturnStatus;
                  $("#viewDocLink_PFGSTReturn").hide();
                  $("#choose_PFGSTReturn").show();
                  $("#X-btn_PFGSTReturn").hide();
                  $("#done-btn_PFGSTReturn").hide();
                  $("#review-btn_PFGSTReturn").hide();
                }

                if (doc[2].status == "remove") {
                  mainactionPFTDSReturn = "upload";
                  PFTDSReturnStatus = "remove";
                  PFOptStatusArray[3].status = PFTDSReturnStatus;
                  this.setState(
                    {
                      PFTDSReturnDocLink: doc[2].TDSReturn,
                    },
                    () => {
                      $("#viewDocLink_PFTDSReturn").css("display", "block");
                      $("#choose_PFTDSReturn").hide();
                      $("#X-btn_PFTDSReturn").show();
                      $("#done-btn_PFTDSReturn").hide();
                      $("#review-btn_PFTDSReturn").hide();
                    }
                  );
                } else if (doc[2].status == "review") {
                  mainactionPFTDSReturn = "upload";
                  PFTDSReturnStatus = "review";
                  PFOptStatusArray[3].status = PFTDSReturnStatus;
                  this.setState(
                    {
                      PFTDSReturnDocLink: doc[2].TDSReturn,
                    },
                    () => {
                      $("#viewDocLink_PFTDSReturn").css("display", "block");
                      $("#choose_PFTDSReturn").hide();
                      $("#X-btn_PFTDSReturn").hide();
                      $("#done-btn_PFTDSReturn").hide();
                      $("#review-btn_PFTDSReturn").show();
                    }
                  );
                } else if (doc[2].status == "reupload") {
                  mainactionPFTDSReturn = "reupload";
                  PFTDSReturnStatus = "reupload";
                  PFOptStatusArray[3].status = PFTDSReturnStatus;
                  this.setState({}, () => {
                    $("#choose_PFTDSReturn").show();
                    $("#viewDocLink_PFTDSReturn").hide();
                    $("#X-btn_PFTDSReturn").hide();
                    $("#done-btn_PFTDSReturn").hide();
                    $("#review-btn_PFTDSReturn").hide();
                  });
                } else if (doc[2].status == "done") {
                  mainactionPFTDSReturn = "upload";
                  PFTDSReturnStatus = "done";
                  PFOptStatusArray[3].status = PFTDSReturnStatus;
                  this.setState(
                    {
                      PFTDSReturnDocLink: doc[2].TDSReturn,
                    },
                    () => {
                      $("#choose_PFTDSReturn").hide();
                      $("#viewDocLink_PFTDSReturn").css("display", "block");
                      $("#X-btn_PFTDSReturn").hide();
                      $("#done-btn_PFTDSReturn").show();
                      $("#review-btn_PFTDSReturn").hide();
                    }
                  );
                } else {
                  mainactionPFTDSReturn = "upload";
                  PFTDSReturnStatus = "open";
                  PFOptStatusArray[3].status = PFTDSReturnStatus;
                  $("#viewDocLink_PFTDSReturn").hide();
                  $("#choose_PFTDSReturn").show();
                  $("#X-btn_PFTDSReturn").hide();
                  $("#done-btn_PFTDSReturn").hide();
                  $("#review-btn_PFTDSReturn").hide();
                }

                if (doc[3].status == "remove") {
                  mainactionPFChallan = "upload";
                  PFChallanStatus = "remove";
                  PFOptStatusArray[4].status = PFChallanStatus;
                  this.setState(
                    {
                      PFChallanDocLink: doc[3].Challan,
                    },
                    () => {
                      $("#viewDocLink_PFChallan").css("display", "block");
                      $("#choose_PFChallan").hide();
                      $("#X-btn_PFChallan").show();
                      $("#done-btn_PFChallan").hide();
                      $("#review-btn_PFChallan").hide();
                    }
                  );
                } else if (doc[3].status == "review") {
                  mainactionPFChallan = "upload";
                  PFChallanStatus = "review";
                  PFOptStatusArray[4].status = PFChallanStatus;
                  this.setState(
                    {
                      PFChallanDocLink: doc[3].Challan,
                    },
                    () => {
                      $("#viewDocLink_PFChallan").css("display", "block");
                      $("#choose_PFChallan").hide();
                      $("#X-btn_PFChallan").hide();
                      $("#done-btn_PFChallan").hide();
                      $("#review-btn_PFChallan").show();
                    }
                  );
                } else if (doc[3].status == "reupload") {
                  mainactionPFChallan = "reupload";
                  PFChallanStatus = "reupload";
                  PFOptStatusArray[4].status = PFChallanStatus;
                  this.setState({}, () => {
                    $("#choose_PFChallan").show();
                    $("#viewDocLink_PFChallan").hide();
                    $("#X-btn_PFChallan").hide();
                    $("#done-btn_PFChallan").hide();
                    $("#review-btn_PFChallan").hide();
                  });
                } else if (doc[3].status == "done") {
                  mainactionPFChallan = "upload";
                  PFChallanStatus = "done";
                  PFOptStatusArray[4].status = PFChallanStatus;
                  this.setState(
                    {
                      PFChallanDocLink: doc[3].Challan,
                    },
                    () => {
                      $("#choose_PFChallan").hide();
                      $("#viewDocLink_PFChallan").css("display", "block");
                      $("#X-btn_PFChallan").hide();
                      $("#done-btn_PFChallan").show();
                      $("#review-btn_PFChallan").hide();
                    }
                  );
                } else {
                  mainactionPFChallan = "upload";
                  PFChallanStatus = "open";
                  PFOptStatusArray[4].status = PFChallanStatus;
                  $("#viewDocLink_PFChallan").hide();
                  $("#choose_PFChallan").show();
                  $("#X-btn_PFChallan").hide();
                  $("#done-btn_PFChallan").hide();
                  $("#review-btn_PFChallan").hide();
                }
                if (doc[4].status == "remove") {
                  mainactionPFBankS = "upload";
                  PFBankSStatus = "remove";
                  PFStatusArray[7].status = PFBankSStatus;
                  this.setState(
                    {
                      PFBankSDocLink: doc[4].BankStatement,
                    },
                    () => {
                      $("#viewDocLink_PFBankS").css("display", "block");
                      $("#choose_PFBankS").hide();
                      $("#X-btn_PFBankS").show();
                      $("#done-btn_PFBankS").hide();
                      $("#review-btn_PFBankS").hide();
                    }
                  );
                } else if (doc[4].status == "review") {
                  mainactionPFBankS = "upload";
                  PFBankSStatus = "review";
                  PFStatusArray[7].status = PFBankSStatus;
                  this.setState(
                    {
                      PFBankSDocLink: doc[4].BankStatement,
                    },
                    () => {
                      $("#viewDocLink_PFBankS").css("display", "block");
                      $("#choose_PFBankS").hide();
                      $("#X-btn_PFBankS").hide();
                      $("#done-btn_PFBankS").hide();
                      $("#review-btn_PFBankS").show();
                    }
                  );
                } else if (doc[4].status == "reupload") {
                  mainactionPFBankS = "reupload";
                  PFBankSStatus = "reupload";
                  PFStatusArray[7].status = PFBankSStatus;
                  this.setState({}, () => {
                    $("#choose_PFBankS").show();
                    $("#viewDocLink_PFBankS").hide();
                    $("#X-btn_PFBankS").hide();
                    $("#done-btn_PFBankS").hide();
                    $("#review-btn_PFBankS").hide();
                  });
                } else if (doc[4].status == "done") {
                  mainactionPFBankS = "upload";
                  PFBankSStatus = "done";
                  PFStatusArray[7].status = PFBankSStatus;
                  this.setState(
                    {
                      PFBankSDocLink: doc[4].BankStatement,
                    },
                    () => {
                      $("#choose_PFBankS").hide();
                      $("#viewDocLink_PFBankS").css("display", "block");
                      $("#X-btn_PFBankS").hide();
                      $("#done-btn_PFBankS").show();
                      $("#review-btn_PFBankS").hide();
                    }
                  );
                } else {
                  mainactionPFBankS = "upload";
                  PFBankSStatus = "open";
                  PFStatusArray[7].status = PFBankSStatus;
                  $("#viewDocLink_PFBankS").hide();
                  $("#choose_PFBankS").show();
                  $("#X-btn_PFBankS").hide();
                  $("#done-btn_PFBankS").hide();
                  $("#review-btn_PFBankS").hide();
                }
              } else {
                $("#viewDocLink_PFData").hide();
                $("#choose_PFData").show();
                $("#X-btn_PFData").hide();
                $("#done-btn_PFData").hide();
                $("#review-btn_PFData").hide();

                $("#viewDocLink_PFBankS").hide();
                $("#choose_PFBankS").show();
                $("#X-btn_PFBankS").hide();
                $("#done-btn_PFBankS").hide();
                $("#review-btn_PFBankS").hide();

                $("#viewDocLink_PFGSTReturn").hide();
                $("#choose_PFGSTReturn").show();
                $("#X-btn_PFGSTReturn").hide();
                $("#done-btn_PFGSTReturn").hide();
                $("#review-btn_PFGSTReturn").hide();

                $("#viewDocLink_PFTDSReturn").hide();
                $("#choose_PFTDSReturn").show();
                $("#X-btn_PFTDSReturn").hide();
                $("#done-btn_PFTDSReturn").hide();
                $("#review-btn_PFTDSReturn").hide();

                $("#viewDocLink_PFChallan").hide();
                $("#choose_PFChallan").show();
                $("#X-btn_PFChallan").hide();
                $("#done-btn_PFChallan").hide();
                $("#review-btn_PFChallan").hide();
              }
              this.PFcheckRemainingReuploads();
              let statusCheck = this.statusCheckFunction(
                "reupload",
                PFStatusArray
              );
              PFincDadditionalStatusArrayCheck = this.statusCheckFunction(
                "reupload",
                this.state.PFincDAdditionalFiles
              );
              PFadditionalStatusArrayCheck = this.statusCheckFunction(
                "reupload",
                this.state.PFAdditionalFiles
              );

              if (
                AnyReuploadOccuredPF == "Yes" &&
                statusCheck == undefined &&
                PFincDadditionalStatusArrayCheck == undefined &&
                PFadditionalStatusArrayCheck == undefined
              ) {
                PFOptReview = "Yes";
                this.countOptReuploadPF("reupload", PFOptStatusArray);
              } else {
                PFOptReview = "No";
              }
              if (
                res.data.rejectstatus == "false" &&
                res.data.mainstatus == "true"
              ) {
                rejectstatus = "Yes";
              }
              // this.PFcheckRemainingReuploads();
              // this.checkhandlePFProceedBtn();
            } else if (res.data.serviceType == "ITRF_BPC_OneTime_Documents") {
              $(".selectcateselect1").attr("disabled", true);
              this.setState(
                {
                  dropDownValue: "ITRF_BPC_OneTime_Documents",
                  serviceType: "ITRF_BPC_OneTime_Documents",
                },
                () => {
                  if (
                    this.state.dropDownValue == "ITRF_BPC_OneTime_Documents"
                  ) {
                    $("#Itrf_default_Img").hide();
                    $("#ItrfSalariedSection").hide();
                    $("#ItrfproprietorSection").hide();
                    $("#ItrfPartnershipFirm").hide();
                    $("#ItrfCompany_LLP").show();
                  }
                }
              );
              if (res.data.oneTime.length > 0) {
                if (res.data.oneTime[0].documents.length > 0) {
                  let onetime = res.data.oneTime[0].documents;
                  if (onetime[0].status == "remove") {
                    mainactionCLAadhar1 = "upload";
                    CLAadhar1Status = "remove";
                    CLStatusArray[0].status = CLAadhar1Status;
                    this.setState(
                      {
                        CLAadhar1DocLink: onetime[0].AadhaarCardApplicant1,
                      },
                      () => {
                        $("#viewDocLink_CLAadhar1").css("display", "block");
                        $("#choose_CLAadhar1").hide();
                        $("#X-btn_CLAadhar1").show();
                        $("#done-btn_CLAadhar1").hide();
                        $("#review-btn_CLAadhar1").hide();
                      }
                    );
                  } else if (onetime[0].status == "review") {
                    mainactionCLAadhar1 = "upload";
                    CLAadhar1Status = "review";
                    CLStatusArray[0].status = CLAadhar1Status;
                    this.setState(
                      {
                        CLAadhar1DocLink: onetime[0].AadhaarCardApplicant1,
                      },
                      () => {
                        $("#viewDocLink_CLAadhar1").css("display", "block");
                        $("#choose_CLAadhar1").hide();
                        $("#X-btn_CLAadhar1").hide();
                        $("#done-btn_CLAadhar1").hide();
                        $("#review-btn_CLAadhar1").show();
                      }
                    );
                  } else if (onetime[0].status == "reupload") {
                    mainactionCLAadhar1 = "reupload";
                    CLAadhar1Status = "reupload";
                    CLStatusArray[0].status = CLAadhar1Status;
                    this.setState({}, () => {
                      $("#choose_CLAadhar1").show();
                      $("#viewDocLink_CLAadhar1").hide();
                      $("#X-btn_CLAadhar1").hide();
                      $("#done-btn_CLAadhar1").hide();
                      $("#review-btn_CLAadhar1").hide();
                    });
                  } else if (onetime[0].status == "done") {
                    CLAadhar1Status = "done";
                    CLStatusArray[0].status = CLAadhar1Status;
                    mainactionCLAadhar1 = "upload";
                    this.setState(
                      {
                        CLAadhar1DocLink: onetime[0].AadhaarCardApplicant1,
                      },
                      () => {
                        $("#choose_CLAadhar1").hide();
                        $("#viewDocLink_CLAadhar1").css("display", "block");
                        $("#X-btn_CLAadhar1").hide();
                        $("#done-btn_CLAadhar1").show();
                        $("#review-btn_CLAadhar1").hide();
                      }
                    );
                  } else {
                    mainactionCLAadhar1 = "upload";
                    CLAadhar1Status = "open";
                    CLStatusArray[0].status = CLAadhar1Status;
                    $("#viewDocLink_CLAadhar1").hide();
                    $("#choose_CLAadhar1").show();
                    $("#X-btn_CLAadhar1").hide();
                    $("#done-btn_CLAadhar1").hide();
                    $("#review-btn_CLAadhar1").hide();
                  }
                  if (onetime[1].status == "remove") {
                    mainactionCLAadhar2 = "upload";
                    CLAadhar2Status = "remove";
                    CLStatusArray[1].status = CLAadhar2Status;
                    this.setState(
                      {
                        CLAadhar2DocLink: onetime[1].AadhaarCardApplicant2,
                      },
                      () => {
                        $("#viewDocLink_CLAadhar2").css("display", "block");
                        $("#choose_CLAadhar2").hide();
                        $("#X-btn_CLAadhar2").show();
                        $("#done-btn_CLAadhar2").hide();
                        $("#review-btn_CLAadhar2").hide();
                      }
                    );
                  } else if (onetime[1].status == "review") {
                    mainactionCLAadhar2 = "upload";
                    CLAadhar2Status = "review";
                    CLStatusArray[1].status = CLAadhar2Status;
                    this.setState(
                      {
                        CLAadhar2DocLink: onetime[1].AadhaarCardApplicant2,
                      },
                      () => {
                        $("#viewDocLink_CLAadhar2").css("display", "block");
                        $("#choose_CLAadhar2").hide();
                        $("#X-btn_CLAadhar2").hide();
                        $("#done-btn_CLAadhar2").hide();
                        $("#review-btn_CLAadhar2").show();
                      }
                    );
                  } else if (onetime[1].status == "reupload") {
                    mainactionCLAadhar2 = "reupload";
                    CLAadhar2Status = "reupload";
                    CLStatusArray[1].status = CLAadhar2Status;
                    this.setState({}, () => {
                      $("#choose_CLAadhar2").show();
                      $("#viewDocLink_CLAadhar2").hide();
                      $("#X-btn_CLAadhar2").hide();
                      $("#done-btn_CLAadhar2").hide();
                      $("#review-btn_CLAadhar2").hide();
                    });
                  } else if (onetime[1].status == "done") {
                    CLAadhar2Status = "done";
                    CLStatusArray[1].status = CLAadhar2Status;
                    mainactionCLAadhar2 = "upload";
                    this.setState(
                      {
                        CLAadhar2DocLink: onetime[1].AadhaarCardApplicant2,
                      },
                      () => {
                        $("#viewDocLink_CLAadhar2").css("display", "block");
                        $("#choose_CLAadhar2").hide();
                        $("#X-btn_CLAadhar2").hide();
                        $("#done-btn_CLAadhar2").show();
                        $("#review-btn_CLAadhar2").hide();
                      }
                    );
                  } else {
                    mainactionCLAadhar2 = "upload";
                    CLAadhar2Status = "open";
                    CLStatusArray[1].status = CLAadhar2Status;
                    $("#viewDocLink_CLAadhar2").hide();
                    $("#choose_CLAadhar2").show();
                    $("#X-btn_CLAadhar2").hide();
                    $("#done-btn_CLAadhar2").hide();
                    $("#review-btn_CLAadhar2").hide();
                  }
                  if (onetime[2].status == "remove") {
                    mainactionCLPan1 = "upload";
                    CLPan1Status = "remove";
                    CLStatusArray[2].status = CLPan1Status;
                    this.setState(
                      {
                        CLPan1DocLink: onetime[2].PANCardApplicant1,
                      },
                      () => {
                        $("#viewDocLink_CLPan1").css("display", "block");
                        $("#choose_CLPan1").hide();
                        $("#X-btn_CLPan1").show();
                        $("#done-btn_CLPan1").hide();
                        $("#review-btn_CLPan1").hide();
                      }
                    );
                  } else if (onetime[2].status == "review") {
                    mainactionCLPan1 = "upload";
                    CLPan1Status = "review";
                    CLStatusArray[2].status = CLPan1Status;
                    this.setState(
                      {
                        CLPan1DocLink: onetime[2].PANCardApplicant1,
                      },
                      () => {
                        $("#viewDocLink_CLPan1").css("display", "block");
                        $("#choose_CLPan1").hide();
                        $("#X-btn_CLPan1").hide();
                        $("#done-btn_CLPan1").hide();
                        $("#review-btn_CLPan1").show();
                      }
                    );
                  } else if (onetime[2].status == "reupload") {
                    mainactionCLPan1 = "reupload";
                    CLPan1Status = "reupload";
                    CLStatusArray[2].status = CLPan1Status;
                    this.setState({}, () => {
                      $("#choose_CLPan1").show();
                      $("#viewDocLink_CLPan1").hide();
                      $("#X-btn_CLPan1").hide();
                      $("#done-btn_CLPan1").hide();
                      $("#review-btn_CLPan1").hide();
                    });
                  } else if (onetime[2].status == "done") {
                    CLPan1Status = "done";
                    CLStatusArray[2].status = CLPan1Status;
                    mainactionCLPan1 = "upload";
                    this.setState(
                      {
                        CLPan1DocLink: onetime[2].PANCardApplicant1,
                      },
                      () => {
                        $("#choose_CLPan1").hide();
                        $("#viewDocLink_CLPan1").css("display", "block");
                        $("#X-btn_CLPan1").hide();
                        $("#done-btn_CLPan1").show();
                        $("#review-btn_CLPan1").hide();
                      }
                    );
                  } else {
                    mainactionCLPan1 = "upload";
                    CLPan1Status = "open";
                    CLStatusArray[2].status = CLPan1Status;
                    $("#viewDocLink_CLPan1").hide();
                    $("#choose_CLPan1").show();
                    $("#X-btn_CLPan1").hide();
                    $("#done-btn_CLPan1").hide();
                    $("#review-btn_CLPan1").hide();
                  }
                  if (onetime[3].status == "remove") {
                    mainactionCLPan2 = "upload";
                    CLPan2Status = "remove";
                    CLStatusArray[3].status = CLPan2Status;
                    this.setState(
                      {
                        CLPan2DocLink: onetime[3].PANCardApplicant2,
                      },
                      () => {
                        $("#viewDocLink_CLPan2").css("display", "block");
                        $("#choose_CLPan2").hide();
                        $("#X-btn_CLPan2").show();
                        $("#done-btn_CLPan2").hide();
                        $("#review-btn_CLPan2").hide();
                      }
                    );
                  } else if (onetime[3].status == "review") {
                    mainactionCLPan2 = "upload";
                    CLPan2Status = "review";
                    CLStatusArray[3].status = CLPan2Status;
                    this.setState(
                      {
                        CLPan2DocLink: onetime[3].PANCardApplicant2,
                      },
                      () => {
                        $("#viewDocLink_CLPan2").css("display", "block");
                        $("#choose_CLPan2").hide();
                        $("#X-btn_CLPan2").hide();
                        $("#done-btn_CLPan2").hide();
                        $("#review-btn_CLPan2").show();
                      }
                    );
                  } else if (onetime[3].status == "reupload") {
                    mainactionCLPan2 = "reupload";
                    CLPan2Status = "reupload";
                    CLStatusArray[3].status = CLPan2Status;
                    this.setState({}, () => {
                      $("#choose_CLPan2").show();
                      $("#viewDocLink_CLPan2").hide();
                      $("#X-btn_CLPan2").hide();
                      $("#done-btn_CLPan2").hide();
                      $("#review-btn_CLPan2").hide();
                    });
                  } else if (onetime[3].status == "done") {
                    CLPan2Status = "done";
                    CLStatusArray[3].status = CLPan2Status;
                    mainactionCLPan2 = "upload";
                    this.setState(
                      {
                        CLPan2DocLink: onetime[3].PANCardApplicant2,
                      },
                      () => {
                        $("#choose_CLPan2").hide();
                        $("#viewDocLink_CLPan2").css("display", "block");
                        $("#X-btn_CLPan2").hide();
                        $("#done-btn_CLPan2").show();
                        $("#review-btn_CLPan2").hide();
                      }
                    );
                  } else {
                    mainactionCLPan2 = "upload";
                    CLPan2Status = "open";
                    CLStatusArray[3].status = CLPan2Status;
                    $("#viewDocLink_CLPan2").hide();
                    $("#choose_CLPan2").show();
                    $("#X-btn_CLPan2").hide();
                    $("#done-btn_CLPan2").hide();
                    $("#review-btn_CLPan2").hide();
                  }
                  if (onetime[4].status == "remove") {
                    mainactionCLIC = "upload";
                    CLICStatus = "remove";
                    CLStatusArray[4].status = CLICStatus;
                    this.setState(
                      {
                        CLICDocLink: onetime[4].IncorporationCertificate,
                      },
                      () => {
                        $("#viewDocLink_CLIC").css("display", "block");
                        $("#choose_CLIC").hide();
                        $("#X-btn_CLIC").show();
                        $("#done-btn_CLIC").hide();
                        $("#review-btn_CLIC").hide();
                      }
                    );
                  } else if (onetime[4].status == "review") {
                    mainactionCLIC = "upload";
                    CLICStatus = "review";
                    CLStatusArray[4].status = CLICStatus;
                    this.setState(
                      {
                        CLICDocLink: onetime[4].IncorporationCertificate,
                      },
                      () => {
                        $("#viewDocLink_CLIC").css("display", "block");
                        $("#choose_CLIC").hide();
                        $("#X-btn_CLIC").hide();
                        $("#done-btn_CLIC").hide();
                        $("#review-btn_CLIC").show();
                      }
                    );
                  } else if (onetime[4].status == "reupload") {
                    mainactionCLIC = "reupload";
                    CLICStatus = "reupload";
                    CLStatusArray[4].status = CLICStatus;
                    this.setState({}, () => {
                      $("#choose_CLIC").show();
                      $("#viewDocLink_CLIC").hide();
                      $("#X-btn_CLIC").hide();
                      $("#done-btn_CLIC").hide();
                      $("#review-btn_CLIC").hide();
                    });
                  } else if (onetime[4].status == "done") {
                    CLICStatus = "done";
                    CLStatusArray[4].status = CLICStatus;
                    mainactionCLIC = "upload";
                    this.setState(
                      {
                        CLICDocLink: onetime[4].IncorporationCertificate,
                      },
                      () => {
                        $("#choose_CLIC").hide();
                        $("#viewDocLink_CLIC").css("display", "block");
                        $("#X-btn_CLIC").hide();
                        $("#done-btn_CLIC").show();
                        $("#review-btn_CLIC").hide();
                      }
                    );
                  } else {
                    mainactionCLIC = "upload";
                    CLICStatus = "open";
                    CLStatusArray[4].status = CLICStatus;
                    $("#viewDocLink_CLIC").hide();
                    $("#choose_CLIC").show();
                    $("#X-btn_CLIC").hide();
                    $("#done-btn_CLIC").hide();
                    $("#review-btn_CLIC").hide();
                  }
                  if (onetime[5].status == "remove") {
                    mainactionCLMOA = "upload";
                    CLMOAStatus = "remove";
                    CLStatusArray[5].status = CLMOAStatus;
                    this.setState(
                      {
                        CLMOADocLink: onetime[5].MOA,
                      },
                      () => {
                        $("#viewDocLink_CLMOA").css("display", "block");
                        $("#choose_CLMOA").hide();
                        $("#X-btn_CLMOA").show();
                        $("#done-btn_CLMOA").hide();
                        $("#review-btn_CLMOA").hide();
                      }
                    );
                  } else if (onetime[5].status == "review") {
                    mainactionCLMOA = "upload";
                    CLMOAStatus = "review";
                    CLStatusArray[5].status = CLMOAStatus;
                    this.setState(
                      {
                        CLMOADocLink: onetime[5].MOA,
                      },
                      () => {
                        $("#viewDocLink_CLMOA").css("display", "block");
                        $("#choose_CLMOA").hide();
                        $("#X-btn_CLMOA").hide();
                        $("#done-btn_CLMOA").hide();
                        $("#review-btn_CLMOA").show();
                      }
                    );
                  } else if (onetime[5].status == "reupload") {
                    mainactionCLMOA = "reupload";
                    CLMOAStatus = "reupload";
                    CLStatusArray[5].status = CLMOAStatus;
                    this.setState({}, () => {
                      $("#choose_CLMOA").show();
                      $("#viewDocLink_CLMOA").hide();
                      $("#X-btn_CLMOA").hide();
                      $("#done-btn_CLMOA").hide();
                      $("#review-btn_CLMOA").hide();
                    });
                  } else if (onetime[5].status == "done") {
                    CLMOAStatus = "done";
                    CLStatusArray[5].status = CLMOAStatus;
                    mainactionCLMOA = "upload";
                    this.setState(
                      {
                        CLMOADocLink: onetime[5].MOA,
                      },
                      () => {
                        $("#choose_CLMOA").hide();
                        $("#viewDocLink_CLMOA").css("display", "block");
                        $("#X-btn_CLMOA").hide();
                        $("#done-btn_CLMOA").show();
                        $("#review-btn_CLMOA").hide();
                      }
                    );
                  } else {
                    mainactionCLMOA = "upload";
                    CLMOAStatus = "open";
                    CLStatusArray[5].status = CLMOAStatus;
                    $("#viewDocLink_CLMOA").hide();
                    $("#choose_CLMOA").show();
                    $("#X-btn_CLMOA").hide();
                    $("#done-btn_CLMOA").hide();
                    $("#review-btn_CLMOA").hide();
                  }
                  if (onetime[6].status == "remove") {
                    mainactionCLAOA = "upload";
                    CLAOAStatus = "remove";
                    CLStatusArray[6].status = CLAOAStatus;
                    this.setState(
                      {
                        CLAOADocLink: onetime[6].AOA,
                      },
                      () => {
                        $("#viewDocLink_CLAOA").css("display", "block");
                        $("#choose_CLAOA").hide();
                        $("#X-btn_CLAOA").show();
                        $("#done-btn_CLAOA").hide();
                        $("#review-btn_CLAOA").hide();
                      }
                    );
                  } else if (onetime[6].status == "review") {
                    mainactionCLAOA = "upload";
                    CLAOAStatus = "review";
                    CLStatusArray[6].status = CLAOAStatus;
                    this.setState(
                      {
                        CLAOADocLink: onetime[6].AOA,
                      },
                      () => {
                        $("#viewDocLink_CLAOA").css("display", "block");
                        $("#choose_CLAOA").hide();
                        $("#X-btn_CLAOA").hide();
                        $("#done-btn_CLAOA").hide();
                        $("#review-btn_CLAOA").show();
                      }
                    );
                  } else if (onetime[6].status == "reupload") {
                    mainactionCLAOA = "reupload";
                    CLAOAStatus = "reupload";
                    CLStatusArray[6].status = CLAOAStatus;
                    this.setState({}, () => {
                      $("#choose_CLAOA").show();
                      $("#viewDocLink_CLAOA").hide();
                      $("#X-btn_CLAOA").hide();
                      $("#done-btn_CLAOA").hide();
                      $("#review-btn_CLAOA").hide();
                    });
                  } else if (onetime[6].status == "done") {
                    CLAOAStatus = "done";
                    CLStatusArray[6].status = CLAOAStatus;
                    mainactionCLAOA = "upload";
                    this.setState(
                      {
                        CLAOADocLink: onetime[6].AOA,
                      },
                      () => {
                        $("#choose_CLAOA").hide();
                        $("#viewDocLink_CLAOA").css("display", "block");
                        $("#X-btn_CLAOA").hide();
                        $("#done-btn_CLAOA").show();
                        $("#review-btn_CLAOA").hide();
                      }
                    );
                  } else {
                    mainactionCLAOA = "upload";
                    CLAOAStatus = "open";
                    CLStatusArray[6].status = CLAOAStatus;
                    $("#viewDocLink_CLAOA").hide();
                    $("#choose_CLAOA").show();
                    $("#X-btn_CLAOA").hide();
                    $("#done-btn_CLAOA").hide();
                    $("#review-btn_CLAOA").hide();
                  }
                  if (onetime[7].status == "remove") {
                    mainactionCLBP = "upload";
                    CLBPStatus = "remove";
                    CLStatusArray[7].status = CLBPStatus;
                    this.setState(
                      {
                        CLBPDocLink: onetime[7].BankProof,
                      },
                      () => {
                        $("#viewDocLink_CLBP").css("display", "block");
                        $("#choose_CLBP").hide();
                        $("#X-btn_CLBP").show();
                        $("#done-btn_CLBP").hide();
                        $("#review-btn_CLBP").hide();
                      }
                    );
                  } else if (onetime[7].status == "review") {
                    mainactionCLBP = "upload";
                    CLBPStatus = "review";
                    CLStatusArray[7].status = CLBPStatus;
                    this.setState(
                      {
                        CLBPDocLink: onetime[7].BankProof,
                      },
                      () => {
                        $("#viewDocLink_CLBP").css("display", "block");
                        $("#choose_CLBP").hide();
                        $("#X-btn_CLBP").hide();
                        $("#done-btn_CLBP").hide();
                        $("#review-btn_CLBP").show();
                      }
                    );
                  } else if (onetime[7].status == "reupload") {
                    mainactionCLBP = "reupload";
                    CLBPStatus = "reupload";
                    CLStatusArray[7].status = CLBPStatus;
                    this.setState({}, () => {
                      $("#choose_CLBP").show();
                      $("#viewDocLink_CLBP").hide();
                      $("#X-btn_CLBP").hide();
                      $("#done-btn_CLBP").hide();
                      $("#review-btn_CLBP").hide();
                    });
                  } else if (onetime[7].status == "done") {
                    CLBPStatus = "done";
                    CLStatusArray[7].status = CLBPStatus;
                    mainactionCLBP = "upload";
                    this.setState(
                      {
                        CLBPDocLink: onetime[7].BankProof,
                      },
                      () => {
                        $("#viewDocLink_CLBP").css("display", "block");
                        $("#choose_CLBP").hide();
                        $("#X-btn_CLBP").hide();
                        $("#done-btn_CLBP").show();
                        $("#review-btn_CLBP").hide();
                      }
                    );
                  } else {
                    mainactionCLBP = "upload";
                    CLBPStatus = "open";
                    CLStatusArray[7].status = CLBPStatus;
                    $("#viewDocLink_CLBP").hide();
                    $("#choose_CLBP").show();
                    $("#X-btn_CLBP").hide();
                    $("#done-btn_CLBP").hide();
                    $("#review-btn_CLBP").hide();
                  }
                  if (onetime[8].status == "remove") {
                    mainactionCLPBS_A = "upload";
                    CLPBS_AStatus = "remove";
                    CLOptStatusArray[0].status = CLPBS_AStatus;
                    this.setState(
                      {
                        CLPBS_ADocLink: onetime[8].PreviousBS_Audit,
                      },
                      () => {
                        $("#viewDocLink_CLPBS_A").css("display", "block");
                        $("#choose_CLPBS_A").hide();
                        $("#X-btn_CLPBS_A").show();
                        $("#done-btn_CLPBS_A").hide();
                        $("#review-btn_CLPBS_A").hide();
                      }
                    );
                  } else if (onetime[8].status == "review") {
                    mainactionCLPBS_A = "upload";
                    CLPBS_AStatus = "review";
                    CLOptStatusArray[0].status = CLPBS_AStatus;
                    this.setState(
                      {
                        CLPBS_ADocLink: onetime[8].PreviousBS_Audit,
                      },
                      () => {
                        $("#viewDocLink_CLPBS_A").css("display", "block");
                        $("#choose_CLPBS_A").hide();
                        $("#X-btn_CLPBS_A").hide();
                        $("#done-btn_CLPBS_A").hide();
                        $("#review-btn_CLPBS_A").show();
                      }
                    );
                  } else if (onetime[8].status == "reupload") {
                    mainactionCLPBS_A = "reupload";
                    CLPBS_AStatus = "reupload";
                    CLOptStatusArray[0].status = CLPBS_AStatus;
                    this.setState({}, () => {
                      $("#choose_CLPBS_A").show();
                      $("#viewDocLink_CLPBS_A").hide();
                      $("#X-btn_CLPBS_A").hide();
                      $("#done-btn_CLPBS_A").hide();
                      $("#review-btn_CLPBS_A").hide();
                    });
                  } else if (onetime[8].status == "done") {
                    CLPBS_AStatus = "done";
                    CLOptStatusArray[0].status = CLPBS_AStatus;
                    mainactionCLPBS_A = "upload";
                    this.setState(
                      {
                        CLPBS_ADocLink: onetime[8].PreviousBS_Audit,
                      },
                      () => {
                        $("#choose_CLPBS_A").hide();
                        $("#viewDocLink_CLPBS_A").css("display", "block");
                        $("#X-btn_CLPBS_A").hide();
                        $("#done-btn_CLPBS_A").show();
                        $("#review-btn_CLPBS_A").hide();
                      }
                    );
                  } else {
                    mainactionCLPBS_A = "upload";
                    CLPBS_AStatus = "open";
                    CLOptStatusArray[0].status = CLPBS_AStatus;
                    $("#viewDocLink_CLPBS_A").hide();
                    $("#choose_CLPBS_A").show();
                    $("#X-btn_CLPBS_A").hide();
                    $("#done-btn_CLPBS_A").hide();
                    $("#review-btn_CLPBS_A").hide();
                  }
                  if (onetime[9].status == "remove") {
                    mainactionCLPR = "upload";
                    CLPRStatus = "remove";
                    CLOptStatusArray[1].status = CLPRStatus;
                    this.setState(
                      {
                        CLPRDocLink: onetime[9].PreviousReturns,
                      },
                      () => {
                        $("#viewDocLink_CLPR").css("display", "block");
                        $("#choose_CLPR").hide();
                        $("#X-btn_CLPR").show();
                        $("#done-btn_CLPR").hide();
                        $("#review-btn_CLPR").hide();
                      }
                    );
                  } else if (onetime[9].status == "review") {
                    mainactionCLPR = "upload";
                    CLPRStatus = "review";
                    CLOptStatusArray[1].status = CLPRStatus;
                    this.setState(
                      {
                        CLPRDocLink: onetime[9].PreviousReturns,
                      },
                      () => {
                        $("#viewDocLink_CLPR").css("display", "block");
                        $("#choose_CLPR").hide();
                        $("#X-btn_CLPR").hide();
                        $("#done-btn_CLPR").hide();
                        $("#review-btn_CLPR").show();
                      }
                    );
                  } else if (onetime[9].status == "reupload") {
                    mainactionCLPR = "reupload";
                    CLPRStatus = "reupload";
                    CLOptStatusArray[1].status = CLPRStatus;
                    this.setState({}, () => {
                      $("#choose_CLPR").show();
                      $("#viewDocLink_CLPR").hide();
                      $("#X-btn_CLPR").hide();
                      $("#done-btn_CLPR").hide();
                      $("#review-btn_CLPR").hide();
                    });
                  } else if (onetime[9].status == "done") {
                    CLPRStatus = "done";
                    CLOptStatusArray[1].status = CLPRStatus;
                    mainactionCLPR = "upload";
                    this.setState(
                      {
                        CLPRDocLink: onetime[9].PreviousReturns,
                      },
                      () => {
                        $("#choose_CLPR").hide();
                        $("#viewDocLink_CLPR").css("display", "block");
                        $("#X-btn_CLPR").hide();
                        $("#done-btn_CLPR").show();
                        $("#review-btn_CLPR").hide();
                      }
                    );
                  } else {
                    mainactionCLPR = "upload";
                    CLPRStatus = "open";
                    CLOptStatusArray[1].status = CLPRStatus;
                    $("#viewDocLink_CLPR").hide();
                    $("#choose_CLPR").show();
                    $("#X-btn_CLPR").hide();
                    $("#done-btn_CLPR").hide();
                    $("#review-btn_CLPR").hide();
                  }
                }
              } else {
                $("#viewDocLink_CLAadhar1").hide();
                $("#choose_CLAadhar1").show();
                $("#X-btn_CLAadhar1").hide();
                $("#done-btn_CLAadhar1").hide();
                $("#review-btn_CLAadhar1").hide();

                $("#viewDocLink_CLAadhar2").hide();
                $("#choose_CLAadhar2").show();
                $("#X-btn_CLAadhar2").hide();
                $("#done-btn_CLAadhar2").hide();
                $("#review-btn_CLAadhar2").hide();

                $("#viewDocLink_CLPan1").hide();
                $("#choose_CLPan1").show();
                $("#X-btn_CLPan1").hide();
                $("#done-btn_CLPan1").hide();
                $("#review-btn_CLPan1").hide();

                $("#viewDocLink_CLPan2").hide();
                $("#choose_CLPan2").show();
                $("#X-btn_CLPan2").hide();
                $("#done-btn_CLPan2").hide();
                $("#review-btn_CLPan2").hide();

                $("#viewDocLink_CLIC").hide();
                $("#choose_CLIC").show();
                $("#X-btn_CLIC").hide();
                $("#done-btn_CLIC").hide();
                $("#review-btn_CLIC").hide();

                $("#viewDocLink_CLMOA").hide();
                $("#choose_CLMOA").show();
                $("#X-btn_CLMOA").hide();
                $("#done-btn_CLMOA").hide();
                $("#review-btn_CLMOA").hide();

                $("#viewDocLink_CLAOA").hide();
                $("#choose_CLAOA").show();
                $("#X-btn_CLAOA").hide();
                $("#done-btn_CLAOA").hide();
                $("#review-btn_CLAOA").hide();

                $("#viewDocLink_CLBP").hide();
                $("#choose_CLBP").show();
                $("#X-btn_CLBP").hide();
                $("#done-btn_CLBP").hide();
                $("#review-btn_CLBP").hide();

                $("#viewDocLink_CLPBS_A").hide();
                $("#choose_CLPBS_A").show();
                $("#X-btn_CLPBS_A").hide();
                $("#done-btn_CLPBS_A").hide();
                $("#review-btn_CLPBS_A").hide();

                $("#viewDocLink_CLPR").hide();
                $("#choose_CLPR").show();
                $("#X-btn_CLPR").hide();
                $("#done-btn_CLPR").hide();
                $("#review-btn_CLPR").hide();
              }
              if (
                res.data.fyStatus == "lock" ||
                res.data.fyStatus == "complete"
              ) {
                $(".proceednowbtn").hide();
                if (res.data.incD[0].documents.length == 0) {
                  $("#CLincDadditionalDocumentDiv").hide();
                } else {
                  $("#CLincDadddocumentbtn").hide();
                }
                if (res.data.addDocInformation.length == 0) {
                  $("#CLadditionalDocumentDiv").hide();
                } else {
                  $("#CLadddocumentbtn").hide();
                }
              }
              if (res.data.incD[0].documents.length > 0) {
                $("#CLincDadditionalDocumentDiv").show();
                $("#CLincDadddocumentbtn").hide();
                this.setState(
                  {
                    CLincDAdditionalFiles: [...res.data.incD[0].documents],
                    CLincDAdd_DocName: "File Name",
                    api_CLincDAdditionalFiles: "Yes",
                  },
                  () => {}
                );
              } else if (
                res.data.incD[0].documents.length == 0 &&
                res.data.fyStatus != "lock" &&
                res.data.fyStatus != "complete"
              ) {
                $("#CLincDadditionalDocumentDiv").show();
                this.setState({
                  CLincDAdd_DocName: "",
                  CLincDAdditionalFiles: [1],
                  api_CLincDAdditionalFiles: "No",
                });
              }
              if (res.data.addDocInformation.length > 0) {
                $("#CLadditionalDocumentDiv").show();
                $("#CLadddocumentbtn").hide();
                this.setState(
                  {
                    CLAdditionalFiles: [...res.data.addDocInformation],
                    CLAdd_DocName: "File Name",
                    api_CLAdditionalFiles: "Yes",
                  },
                  () => {}
                );
              } else if (
                res.data.addDocInformation.length == 0 &&
                res.data.fyStatus != "lock" &&
                res.data.fyStatus != "complete"
              ) {
                $("#CLadditionalDocumentDiv").show();
                $("#CLadddocumentbtn").show();
                this.setState({
                  CLAdd_DocName: "",
                  CLAdditionalFiles: [1],
                  api_CLAdditionalFiles: "No",
                });
              }
              if (res.data.documents.length > 0) {
                let doc = res.data.documents;
                if (doc[0].status == "remove") {
                  mainactionCLData = "upload";
                  CLDataStatus = "remove";
                  CLStatusArray[8].status = CLDataStatus;
                  this.setState(
                    {
                      CLDataDocLink: doc[0].Data,
                    },
                    () => {
                      $("#viewDocLink_CLData").css("display", "block");
                      $("#choose_CLData").hide();
                      $("#X-btn_CLData").show();
                      $("#done-btn_CLData").hide();
                      $("#review-btn_CLData").hide();
                    }
                  );
                } else if (doc[0].status == "review") {
                  mainactionCLData = "upload";
                  CLDataStatus = "review";
                  CLStatusArray[8].status = CLDataStatus;
                  this.setState(
                    {
                      CLDataDocLink: doc[0].Data,
                    },
                    () => {
                      $("#viewDocLink_CLData").css("display", "block");
                      $("#choose_CLData").hide();
                      $("#X-btn_CLData").hide();
                      $("#done-btn_CLData").hide();
                      $("#review-btn_CLData").show();
                    }
                  );
                } else if (doc[0].status == "reupload") {
                  mainactionCLData = "reupload";
                  CLDataStatus = "reupload";
                  CLStatusArray[8].status = CLDataStatus;
                  this.setState({}, () => {
                    $("#choose_CLData").show();
                    $("#viewDocLink_CLData").hide();
                    $("#X-btn_CLData").hide();
                    $("#done-btn_CLData").hide();
                    $("#review-btn_CLData").hide();
                  });
                } else if (doc[0].status == "done") {
                  CLDataStatus = "done";
                  CLStatusArray[8].status = CLDataStatus;
                  mainactionCLData = "upload";
                  this.setState(
                    {
                      CLDataDocLink: doc[0].Data,
                    },
                    () => {
                      $("#choose_CLData").hide();
                      $("#viewDocLink_CLData").css("display", "block");
                      $("#X-btn_CLData").hide();
                      $("#done-btn_CLData").show();
                      $("#review-btn_CLData").hide();
                    }
                  );
                } else {
                  mainactionCLData = "upload";
                  CLDataStatus = "open";
                  CLStatusArray[8].status = CLDataStatus;
                  $("#viewDocLink_CLData").hide();
                  $("#choose_CLData").show();
                  $("#X-btn_CLData").hide();
                  $("#done-btn_CLData").hide();
                  $("#review-btn_CLData").hide();
                }

                if (doc[1].status == "remove") {
                  mainactionCLGSTReturn = "upload";
                  CLGSTReturnStatus = "remove";
                  CLOptStatusArray[2].status = CLGSTReturnStatus;
                  this.setState(
                    {
                      CLGSTReturnDocLink: doc[1].GSTReturn,
                    },
                    () => {
                      $("#viewDocLink_CLGSTReturn").css("display", "block");
                      $("#choose_CLGSTReturn").hide();
                      $("#X-btn_CLGSTReturn").show();
                      $("#done-btn_CLGSTReturn").hide();
                      $("#review-btn_CLGSTReturn").hide();
                    }
                  );
                } else if (doc[1].status == "review") {
                  mainactionCLGSTReturn = "upload";
                  CLGSTReturnStatus = "review";
                  CLOptStatusArray[2].status = CLGSTReturnStatus;
                  this.setState(
                    {
                      CLGSTReturnDocLink: doc[1].GSTReturn,
                    },
                    () => {
                      $("#viewDocLink_CLGSTReturn").css("display", "block");
                      $("#choose_CLGSTReturn").hide();
                      $("#X-btn_CLGSTReturn").hide();
                      $("#done-btn_CLGSTReturn").hide();
                      $("#review-btn_CLGSTReturn").show();
                    }
                  );
                } else if (doc[1].status == "reupload") {
                  mainactionCLGSTReturn = "reupload";
                  CLGSTReturnStatus = "reupload";
                  CLOptStatusArray[2].status = CLGSTReturnStatus;
                  this.setState({}, () => {
                    $("#choose_CLGSTReturn").show();
                    $("#viewDocLink_CLGSTReturn").hide();
                    $("#X-btn_CLGSTReturn").hide();
                    $("#done-btn_CLGSTReturn").hide();
                    $("#review-btn_CLGSTReturn").hide();
                  });
                } else if (doc[1].status == "done") {
                  CLGSTReturnStatus = "done";
                  CLOptStatusArray[2].status = CLGSTReturnStatus;
                  mainactionCLGSTReturn = "upload";
                  this.setState(
                    {
                      CLGSTReturnDocLink: doc[1].GSTReturn,
                    },
                    () => {
                      $("#choose_CLGSTReturn").hide();
                      $("#viewDocLink_CLGSTReturn").css("display", "block");
                      $("#X-btn_CLGSTReturn").hide();
                      $("#done-btn_CLGSTReturn").show();
                      $("#review-btn_CLGSTReturn").hide();
                    }
                  );
                } else {
                  mainactionCLGSTReturn = "upload";
                  CLGSTReturnStatus = "open";
                  CLOptStatusArray[2].status = CLGSTReturnStatus;
                  $("#viewDocLink_CLGSTReturn").hide();
                  $("#choose_CLGSTReturn").show();
                  $("#X-btn_CLGSTReturn").hide();
                  $("#done-btn_CLGSTReturn").hide();
                  $("#review-btn_CLGSTReturn").hide();
                }

                if (doc[2].status == "remove") {
                  mainactionCLTDSReturn = "upload";
                  CLTDSReturnStatus = "remove";
                  CLOptStatusArray[3].status = CLTDSReturnStatus;
                  this.setState(
                    {
                      CLTDSReturnDocLink: doc[2].TDSReturn,
                    },
                    () => {
                      $("#viewDocLink_CLTDSReturn").css("display", "block");
                      $("#choose_CLTDSReturn").hide();
                      $("#X-btn_CLTDSReturn").show();
                      $("#done-btn_CLTDSReturn").hide();
                      $("#review-btn_CLTDSReturn").hide();
                    }
                  );
                } else if (doc[2].status == "review") {
                  mainactionCLTDSReturn = "upload";
                  CLTDSReturnStatus = "review";
                  CLOptStatusArray[3].status = CLTDSReturnStatus;
                  this.setState(
                    {
                      CLTDSReturnDocLink: doc[2].TDSReturn,
                    },
                    () => {
                      $("#viewDocLink_CLTDSReturn").css("display", "block");
                      $("#choose_CLTDSReturn").hide();
                      $("#X-btn_CLTDSReturn").hide();
                      $("#done-btn_CLTDSReturn").hide();
                      $("#review-btn_CLTDSReturn").show();
                    }
                  );
                } else if (doc[2].status == "reupload") {
                  mainactionCLTDSReturn = "reupload";
                  CLTDSReturnStatus = "reupload";
                  CLOptStatusArray[3].status = CLTDSReturnStatus;
                  this.setState({}, () => {
                    $("#choose_CLTDSReturn").show();
                    $("#viewDocLink_CLTDSReturn").hide();
                    $("#X-btn_CLTDSReturn").hide();
                    $("#done-btn_CLTDSReturn").hide();
                    $("#review-btn_CLTDSReturn").hide();
                  });
                } else if (doc[2].status == "done") {
                  CLTDSReturnStatus = "done";
                  CLOptStatusArray[3].status = CLTDSReturnStatus;
                  mainactionCLTDSReturn = "upload";
                  this.setState(
                    {
                      CLTDSReturnDocLink: doc[2].TDSReturn,
                    },
                    () => {
                      $("#choose_CLTDSReturn").hide();
                      $("#viewDocLink_CLTDSReturn").css("display", "block");
                      $("#X-btn_CLTDSReturn").hide();
                      $("#done-btn_CLTDSReturn").show();
                      $("#review-btn_CLTDSReturn").hide();
                    }
                  );
                } else {
                  mainactionCLTDSReturn = "upload";
                  CLTDSReturnStatus = "open";
                  CLOptStatusArray[3].status = CLTDSReturnStatus;
                  $("#viewDocLink_CLTDSReturn").hide();
                  $("#choose_CLTDSReturn").show();
                  $("#X-btn_CLTDSReturn").hide();
                  $("#done-btn_CLTDSReturn").hide();
                  $("#review-btn_CLTDSReturn").hide();
                }

                if (doc[3].status == "remove") {
                  mainactionCLChallan = "upload";
                  CLChallanStatus = "remove";
                  CLOptStatusArray[4].status = CLChallanStatus;
                  this.setState(
                    {
                      CLChallanDocLink: doc[3].Challan,
                    },
                    () => {
                      $("#viewDocLink_CLChallan").css("display", "block");
                      $("#choose_CLChallan").hide();
                      $("#X-btn_CLChallan").show();
                      $("#done-btn_CLChallan").hide();
                      $("#review-btn_CLChallan").hide();
                    }
                  );
                } else if (doc[3].status == "review") {
                  mainactionCLChallan = "upload";
                  CLChallanStatus = "review";
                  CLOptStatusArray[4].status = CLChallanStatus;
                  this.setState(
                    {
                      CLChallanDocLink: doc[3].Challan,
                    },
                    () => {
                      $("#viewDocLink_CLChallan").css("display", "block");
                      $("#choose_CLChallan").hide();
                      $("#X-btn_CLChallan").hide();
                      $("#done-btn_CLChallan").hide();
                      $("#review-btn_CLChallan").show();
                    }
                  );
                } else if (doc[3].status == "reupload") {
                  mainactionCLChallan = "reupload";
                  CLChallanStatus = "reupload";
                  CLOptStatusArray[4].status = CLChallanStatus;
                  this.setState({}, () => {
                    $("#choose_CLChallan").show();
                    $("#viewDocLink_CLChallan").hide();
                    $("#X-btn_CLChallan").hide();
                    $("#done-btn_CLChallan").hide();
                    $("#review-btn_CLChallan").hide();
                  });
                } else if (doc[3].status == "done") {
                  CLChallanStatus = "done";
                  CLOptStatusArray[4].status = CLChallanStatus;
                  mainactionCLChallan = "upload";
                  this.setState(
                    {
                      CLChallanDocLink: doc[3].Challan,
                    },
                    () => {
                      $("#choose_CLChallan").hide();
                      $("#viewDocLink_CLChallan").css("display", "block");
                      $("#X-btn_CLChallan").hide();
                      $("#done-btn_CLChallan").show();
                      $("#review-btn_CLChallan").hide();
                    }
                  );
                } else {
                  mainactionCLChallan = "upload";
                  CLChallanStatus = "open";
                  CLOptStatusArray[4].status = CLChallanStatus;
                  $("#viewDocLink_CLChallan").hide();
                  $("#choose_CLChallan").show();
                  $("#X-btn_CLChallan").hide();
                  $("#done-btn_CLChallan").hide();
                  $("#review-btn_CLChallan").hide();
                }
                if (doc[4].status == "remove") {
                  mainactionCLBankS = "upload";
                  CLBankSStatus = "remove";
                  CLStatusArray[9].status = CLBankSStatus;
                  this.setState(
                    {
                      CLBankSDocLink: doc[4].BankStatement,
                    },
                    () => {
                      $("#viewDocLink_CLBankS").css("display", "block");
                      $("#choose_CLBankS").hide();
                      $("#X-btn_CLBankS").show();
                      $("#done-btn_CLBankS").hide();
                      $("#review-btn_CLBankS").hide();
                    }
                  );
                } else if (doc[4].status == "review") {
                  mainactionCLBankS = "upload";
                  CLBankSStatus = "review";
                  CLStatusArray[9].status = CLBankSStatus;
                  this.setState(
                    {
                      CLBankSDocLink: doc[4].BankStatement,
                    },
                    () => {
                      $("#viewDocLink_CLBankS").css("display", "block");
                      $("#choose_CLBankS").hide();
                      $("#X-btn_CLBankS").hide();
                      $("#done-btn_CLBankS").hide();
                      $("#review-btn_CLBankS").show();
                    }
                  );
                } else if (doc[4].status == "reupload") {
                  mainactionCLBankS = "reupload";
                  CLBankSStatus = "reupload";
                  CLStatusArray[9].status = CLBankSStatus;
                  this.setState({}, () => {
                    $("#choose_CLBankS").show();
                    $("#viewDocLink_CLBankS").hide();
                    $("#X-btn_CLBankS").hide();
                    $("#done-btn_CLBankS").hide();
                    $("#review-btn_CLBankS").hide();
                  });
                } else if (doc[4].status == "done") {
                  CLBankSStatus = "done";
                  CLStatusArray[9].status = CLBankSStatus;
                  mainactionCLBankS = "upload";
                  this.setState(
                    {
                      CLBankSDocLink: doc[4].BankStatement,
                    },
                    () => {
                      $("#choose_CLBankS").hide();
                      $("#viewDocLink_CLBankS").css("display", "block");
                      $("#X-btn_CLBankS").hide();
                      $("#done-btn_CLBankS").show();
                      $("#review-btn_CLBankS").hide();
                    }
                  );
                } else {
                  mainactionCLBankS = "upload";
                  CLBankSStatus = "open";
                  CLStatusArray[9].status = CLBankSStatus;
                  $("#viewDocLink_CLBankS").hide();
                  $("#choose_CLBankS").show();
                  $("#X-btn_CLBankS").hide();
                  $("#done-btn_CLBankS").hide();
                  $("#review-btn_CLBankS").hide();
                }
              } else {
                $("#viewDocLink_CLData").hide();
                $("#choose_CLData").show();
                $("#X-btn_CLData").hide();
                $("#done-btn_CLData").hide();
                $("#review-btn_CLData").hide();

                $("#viewDocLink_CLBankS").hide();
                $("#choose_CLBankS").show();
                $("#X-btn_CLBankS").hide();
                $("#done-btn_CLBankS").hide();
                $("#review-btn_CLBankS").hide();

                $("#viewDocLink_CLGSTReturn").hide();
                $("#choose_CLGSTReturn").show();
                $("#X-btn_CLGSTReturn").hide();
                $("#done-btn_CLGSTReturn").hide();
                $("#review-btn_CLGSTReturn").hide();

                $("#viewDocLink_CLTDSReturn").hide();
                $("#choose_CLTDSReturn").show();
                $("#X-btn_CLTDSReturn").hide();
                $("#done-btn_CLTDSReturn").hide();
                $("#review-btn_CLTDSReturn").hide();

                $("#viewDocLink_CLChallan").hide();
                $("#choose_CLChallan").show();
                $("#X-btn_CLChallan").hide();
                $("#done-btn_CLChallan").hide();
                $("#review-btn_CLChallan").hide();
              }
              this.CLcheckRemainingReuploads();
              let statusCheck = this.statusCheckFunction(
                "reupload",
                CLStatusArray
              );
              CLincDadditionalStatusArrayCheck = this.statusCheckFunction(
                "reupload",
                this.state.CLincDAdditionalFiles
              );
              CLadditionalStatusArrayCheck = this.statusCheckFunction(
                "reupload",
                this.state.CLAdditionalFiles
              );

              if (
                AnyReuploadOccuredCL == "Yes" &&
                statusCheck == undefined &&
                CLincDadditionalStatusArrayCheck == undefined &&
                CLadditionalStatusArrayCheck == undefined
              ) {
                CLOptReview = "Yes";
                this.countOptReuploadCL("reupload", CLOptStatusArray);
              } else {
                CLOptReview = "No";
              }
              if (
                res.data.rejectstatus == "false" &&
                res.data.mainstatus == "true"
              ) {
                rejectstatus = "Yes";
              }
              // this.CLcheckRemainingReuploads();
              // this.checkhandleCLProceedBtn();
            }
            if (res.data.oneTime[0].inputFields.length > 0) {
              if (
                res.data.oneTime[0].inputFields[0].PortalUserID != null &&
                res.data.oneTime[0].inputFields[1].PortalPassword != null
              ) {
                this.setState({
                  itrfusername: res.data.oneTime[0].inputFields[0].PortalUserID,
                  itrfpassword:
                    res.data.oneTime[0].inputFields[1].PortalPassword,
                });
                $(".itrfusername").attr("disabled", true);
                $(".itrfpassword").attr("disabled", true);
              }
            }
            if (res.data.mainstatus == "true" && res.data.fyStatus == "lock") {
              $(".proceednowbtn1").show();
              $(".proceednowbtn2").show();
              $(".proceednowbtn3").show();
              $(".proceednowbtn4").show();
              $(".proceednowbtn1").attr("disabled", true);
              $(".proceednowbtn2").attr("disabled", true);
              $(".proceednowbtn3").attr("disabled", true);
              $(".proceednowbtn4").attr("disabled", true);
            } else if (
              res.data.mainstatus == "false" &&
              (res.data.fyStatus == "clear" || res.data.fyStatus == "unlock")
            ) {
              ChooseNowBtnsDisable = "No";
              $(".upload-photo").attr("disabled", false);
              $("#SincDadddocumentbtn").show();
              $("#Sadddocumentbtn").show();
              $("#PincDadddocumentbtn").show();
              $("#Padddocumentbtn").show();
              $("#PFincDadddocumentbtn").show();
              $("#PFadddocumentbtn").show();
              $("#CLincDadddocumentbtn").show();
              $("#CLadddocumentbtn").show();
            }
            this.ScheckRemainingReuploads();
            this.checkhandleSProceedBtn();
            this.PcheckRemainingReuploads();
            this.checkhandlePProceedBtn();
            this.PFcheckRemainingReuploads();
            this.checkhandlePFProceedBtn();
            this.CLcheckRemainingReuploads();
            this.checkhandleCLProceedBtn();

            if (
              res.data.mainstatus == "false" &&
              (res.data.fyStatus == "lock" || res.data.fyStatus == "complete")
            ) {
              $(".upload-photo").attr("disabled", true);
              $(".proceednowbtn1").hide();
              $(".proceednowbtn2").hide();
              $(".proceednowbtn3").hide();
              $(".proceednowbtn4").hide();
              ChooseNowBtnsDisable = "Yes";
            }
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "docuploadnotallowed") {
          this.setState({
            referralPartner:false,
          })
          this.showNotification(res.data.message);
        }  else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          this.showNotification("Something went wrong");
        }
      })
      .catch((err) => {
        $(".serviceoverlay").hide();
      });
  };

  setserviceInformationApi = (serviceid) => {
    $(".serviceoverlay").show();
    var totptoken = generatetotp(
      Decuserid,
      "p=serviceInformation".toUpperCase()
    );
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=serviceInformation",
        {
          userId: Decuserid,
          serviceId: serviceid,
          serviceName: Decservicename,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
            requestid: totptoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        $(".serviceoverlay").hide();
        if (res.data.code == "200" && res.data.status == "success") {
          ChooseNowBtnsDisable = "No";
          $(".upload-photo").attr("disabled", false);
          rejectstatus = "No";
          OptReuploadCountS = 0;
          OptReuploadCountP = 0;
          OptReuploadCountPF = 0;
          OptReuploadCountCL = 0;

          if (
            res.data.fyStatus == "clear" &&
            res.data.informationStatus == "clear"
          ) {
            $(".proceednowbtn1").attr("disabled", true);
            $(".proceednowbtn2").attr("disabled", true);
            $(".proceednowbtn3").attr("disabled", true);
            $(".proceednowbtn4").attr("disabled", true);
          }

          // dropdown values
          if (res.data.serviceType == null) {
            this.setState(
              {
                dropDownValue: "volvo",
                SincDAdd_DocName: "",
                SincDAdditionalFiles: [1],
                api_SincDAdditionalFiles: "No",
                SAdd_DocName: "",
                SAdditionalFiles: [1],
                api_SAdditionalFiles: "No",
                PincDAdd_DocName: "",
                PincDAdditionalFiles: [1],
                api_PincDAdditionalFiles: "No",
                PAdd_DocName: "",
                PAdditionalFiles: [1],
                api_PAdditionalFiles: "No",
                PFincDAdd_DocName: "",
                PFincDAdditionalFiles: [1],
                api_PFincDAdditionalFiles: "No",
                PFAdd_DocName: "",
                PFAdditionalFiles: [1],
                api_PFAdditionalFiles: "No",
                CLincDAdd_DocName: "",
                CLincDAdditionalFiles: [1],
                api_CLincDAdditionalFiles: "No",
                CLAdd_DocName: "",
                CLAdditionalFiles: [1],
                api_CLAdditionalFiles: "No",
              },
              () => {
                if (this.state.dropDownValue == "volvo") {
                  $("#Itrf_default_Img").show();
                  $("#ItrfSalariedSection").hide();
                  $("#ItrfproprietorSection").hide();
                  $("#ItrfPartnershipFirm").hide();
                  $("#ItrfCompany_LLP").hide();
                  SStatusArray.map((a) => (a.status = "open"));
                  SOptStatusArray.map((a) => (a.status = "open"));
                  PStatusArray.map((a) => (a.status = "open"));
                  POptStatusArray.map((a) => (a.status = "open"));
                  PFStatusArray.map((a) => (a.status = "open"));
                  PFOptStatusArray.map((a) => (a.status = "open"));
                  CLStatusArray.map((a) => (a.status = "open"));
                  CLOptStatusArray.map((a) => (a.status = "open"));
                }
              }
            );
          } else if (res.data.serviceType == "ITRF_S_OneTime_Documents") {
            $(".selectcateselect1").attr("disabled", true);
            this.setState(
              {
                dropDownValue: "ITRF_S_OneTime_Documents",
                serviceType: "ITRF_S_OneTime_Documents",
              },
              () => {
                if (this.state.dropDownValue == "ITRF_S_OneTime_Documents") {
                  $("#Itrf_default_Img").hide();
                  $("#ItrfSalariedSection").show();
                  $("#ItrfproprietorSection").hide();
                  $("#ItrfPartnershipFirm").hide();
                  $("#ItrfCompany_LLP").hide();
                }
              }
            );
            if (res.data.documents.length > 0) {
              let doc = res.data.documents;
              if (doc[0].status == "remove") {
                mainactionSF16 = "upload";
                SF16Status = "remove";
                SOptStatusArray[2].status = SF16Status;
                this.setState(
                  {
                    SF16DocLink: doc[0].Form16,
                  },
                  () => {
                    $("#viewDocLink_SF16").css("display", "block");
                    $("#choose_SF16").hide();
                    $("#X-btn_SF16").show();
                    $("#done-btn_SF16").hide();
                    $("#review-btn_SF16").hide();
                  }
                );
              } else if (doc[0].status == "review") {
                mainactionSF16 = "upload";
                SF16Status = "review";
                SOptStatusArray[2].status = SF16Status;
                this.setState(
                  {
                    SF16DocLink: doc[0].Form16,
                  },
                  () => {
                    $("#viewDocLink_SF16").css("display", "block");
                    $("#choose_SF16").hide();
                    $("#X-btn_SF16").hide();
                    $("#done-btn_SF16").hide();
                    $("#review-btn_SF16").show();
                  }
                );
              } else if (doc[0].status == "reupload") {
                mainactionSF16 = "reupload";
                SF16Status = "reupload";
                SOptStatusArray[2].status = SF16Status;
                this.setState({}, () => {
                  $("#choose_SF16").show();
                  $("#viewDocLink_SF16").hide();
                  $("#X-btn_SF16").hide();
                  $("#done-btn_SF16").hide();
                  $("#review-btn_SF16").hide();
                });
              } else if (doc[0].status == "done") {
                mainactionSF16 = "upload";
                SF16Status = "done";
                SOptStatusArray[2].status = SF16Status;
                this.setState(
                  {
                    SF16DocLink: doc[0].Form16,
                  },
                  () => {
                    $("#choose_SF16").hide();
                    $("#viewDocLink_SF16").css("display", "block");
                    $("#X-btn_SF16").hide();
                    $("#done-btn_SF16").show();
                    $("#review-btn_SF16").hide();
                  }
                );
              } else {
                mainactionSF16 = "upload";
                SF16Status = "open";
                SOptStatusArray[2].status = SF16Status;
                $("#viewDocLink_SF16").hide();
                $("#choose_SF16").show();
                $("#X-btn_SF16").hide();
                $("#done-btn_SF16").hide();
                $("#review-btn_SF16").hide();
              }

              if (doc[1].status == "remove") {
                mainactionSBankS = "upload";
                SBankSStatus = "remove";
                SOptStatusArray[3].status = SBankSStatus;
                this.setState(
                  {
                    SBankSDocLink: doc[1].BankStatement,
                  },
                  () => {
                    $("#viewDocLink_SBankS").css("display", "block");
                    $("#choose_SBankS").hide();
                    $("#X-btn_SBankS").show();
                    $("#done-btn_SBankS").hide();
                    $("#review-btn_SBankS").hide();
                  }
                );
              } else if (doc[1].status == "review") {
                mainactionSBankS = "upload";
                SBankSStatus = "review";
                SOptStatusArray[3].status = SBankSStatus;
                this.setState(
                  {
                    SBankSDocLink: doc[1].BankStatement,
                  },
                  () => {
                    $("#viewDocLink_SBankS").css("display", "block");
                    $("#choose_SBankS").hide();
                    $("#X-btn_SBankS").hide();
                    $("#done-btn_SBankS").hide();
                    $("#review-btn_SBankS").show();
                  }
                );
              } else if (doc[1].status == "reupload") {
                mainactionSBankS = "reupload";
                SBankSStatus = "reupload";
                SOptStatusArray[3].status = SBankSStatus;
                this.setState({}, () => {
                  $("#choose_SBankS").show();
                  $("#viewDocLink_SBankS").hide();
                  $("#X-btn_SBankS").hide();
                  $("#done-btn_SBankS").hide();
                  $("#review-btn_SBankS").hide();
                });
              } else if (doc[1].status == "done") {
                mainactionSBankS = "upload";
                SBankSStatus = "done";
                SOptStatusArray[3].status = SBankSStatus;
                this.setState(
                  {
                    SBankSDocLink: doc[1].BankStatement,
                  },
                  () => {
                    $("#choose_SBankS").hide();
                    $("#viewDocLink_SBankS").css("display", "block");
                    $("#X-btn_SBankS").hide();
                    $("#done-btn_SBankS").show();
                    $("#review-btn_SBankS").hide();
                  }
                );
              } else {
                mainactionSBankS = "upload";
                SBankSStatus = "open";
                SOptStatusArray[3].status = SBankSStatus;
                $("#viewDocLink_SBankS").hide();
                $("#choose_SBankS").show();
                $("#X-btn_SBankS").hide();
                $("#done-btn_SBankS").hide();
                $("#review-btn_SBankS").hide();
              }
            } else {
              $("#choose_SBankS").show();
              $("#viewDocLink_SBankS").hide();
              $("#X-btn_SBankS").hide();
              $("#done-btn_SBankS").hide();
              $("#review-btn_SBankS").hide();

              $("#choose_SF16").show();
              $("#viewDocLink_SF16").hide();
              $("#X-btn_SF16").hide();
              $("#done-btn_SF16").hide();
              $("#review-btn_SF16").hide();
            }
            if (res.data.oneTime.length > 0) {
              if (res.data.oneTime[0].documents.length > 0) {
                let onetime = res.data.oneTime[0].documents;
                if (onetime[0].status == "remove") {
                  mainactionSAadhar = "upload";
                  SAadharStatus = "remove";
                  SStatusArray[0].status = SAadharStatus;
                  this.setState(
                    {
                      SAadharDocLink: onetime[0].AadhaarCard,
                    },
                    () => {
                      $("#viewDocLink_SAadhar").css("display", "block");
                      $("#choose_SAadhar").hide();
                      $("#X-btn_SAadhar").show();
                      $("#done-btn_SAadhar").hide();
                      $("#review-btn_SAadhar").hide();
                    }
                  );
                } else if (onetime[0].status == "review") {
                  mainactionSAadhar = "upload";
                  SAadharStatus = "review";
                  SStatusArray[0].status = SAadharStatus;
                  this.setState(
                    {
                      SAadharDocLink: onetime[0].AadhaarCard,
                    },
                    () => {
                      $("#viewDocLink_SAadhar").css("display", "block");
                      $("#choose_SAadhar").hide();
                      $("#X-btn_SAadhar").hide();
                      $("#done-btn_SAadhar").hide();
                      $("#review-btn_SAadhar").show();
                    }
                  );
                } else if (onetime[0].status == "reupload") {
                  mainactionSAadhar = "reupload";
                  SAadharStatus = "reupload";
                  SStatusArray[0].status = SAadharStatus;
                  this.setState({}, () => {
                    $("#choose_SAadhar").show();
                    $("#viewDocLink_SAadhar").hide();
                    $("#X-btn_SAadhar").hide();
                    $("#done-btn_SAadhar").hide();
                    $("#review-btn_SAadhar").hide();
                  });
                } else if (onetime[0].status == "done") {
                  mainactionSAadhar = "upload";
                  SAadharStatus = "done";
                  SStatusArray[0].status = SAadharStatus;
                  this.setState(
                    {
                      SAadharDocLink: onetime[0].AadhaarCard,
                    },
                    () => {
                      $("#viewDocLink_SAadhar").css("display", "block");
                      $("#choose_SAadhar").hide();
                      $("#X-btn_SAadhar").hide();
                      $("#done-btn_SAadhar").show();
                      $("#review-btn_SAadhar").hide();
                    }
                  );
                } else {
                  mainactionSAadhar = "upload";
                  SAadharStatus = "open";
                  SStatusArray[0].status = SAadharStatus;
                  $("#viewDocLink_SAadhar").hide();
                  $("#choose_SAadhar").show();
                  $("#X-btn_SAadhar").hide();
                  $("#done-btn_SAadhar").hide();
                  $("#review-btn_SAadhar").hide();
                }
                if (onetime[1].status == "remove") {
                  mainactionSPan = "upload";
                  SPanStatus = "remove";
                  SStatusArray[1].status = SPanStatus;
                  this.setState(
                    {
                      SPanDocLink: onetime[1].PANCard,
                    },
                    () => {
                      $("#viewDocLink_SPan").css("display", "block");
                      $("#choose_SPan").hide();
                      $("#X-btn_SPan").show();
                      $("#done-btn_SPan").hide();
                      $("#review-btn_SPan").hide();
                    }
                  );
                } else if (onetime[1].status == "review") {
                  mainactionSPan = "upload";
                  SPanStatus = "review";
                  SStatusArray[1].status = SPanStatus;
                  this.setState(
                    {
                      SPanDocLink: onetime[1].PANCard,
                    },
                    () => {
                      $("#viewDocLink_SPan").css("display", "block");
                      $("#choose_SPan").hide();
                      $("#X-btn_SPan").hide();
                      $("#done-btn_SPan").hide();
                      $("#review-btn_SPan").show();
                    }
                  );
                } else if (onetime[1].status == "reupload") {
                  mainactionSPan = "reupload";
                  SPanStatus = "reupload";
                  SStatusArray[1].status = SPanStatus;
                  this.setState({}, () => {
                    $("#choose_SPan").show();
                    $("#viewDocLink_SPan").hide();
                    $("#X-btn_SPan").hide();
                    $("#done-btn_SPan").hide();
                    $("#review-btn_SPan").hide();
                  });
                } else if (onetime[1].status == "done") {
                  mainactionSPan = "upload";
                  SPanStatus = "done";
                  SStatusArray[1].status = SPanStatus;
                  this.setState(
                    {
                      SPanDocLink: onetime[1].PANCard,
                    },
                    () => {
                      $("#viewDocLink_SPan").css("display", "block");
                      $("#choose_SPan").hide();
                      $("#X-btn_SPan").hide();
                      $("#done-btn_SPan").show();
                      $("#review-btn_SPan").hide();
                    }
                  );
                } else {
                  mainactionSPan = "upload";
                  SPanStatus = "open";
                  SStatusArray[1].status = SPanStatus;
                  $("#viewDocLink_SPan").hide();
                  $("#choose_SPan").show();
                  $("#X-btn_SPan").hide();
                  $("#done-btn_SPan").hide();
                  $("#review-btn_SPan").hide();
                }
                if (onetime[2].status == "remove") {
                  mainactionSBp = "upload";
                  SBpStatus = "remove";
                  SStatusArray[2].status = SBpStatus;
                  this.setState(
                    {
                      SBpDocLink: onetime[2].BankProof,
                    },
                    () => {
                      $("#viewDocLink_SBp").css("display", "block");
                      $("#choose_SBp").hide();
                      $("#X-btn_SBp").show();
                      $("#done-btn_SBp").hide();
                      $("#review-btn_SBp").hide();
                    }
                  );
                } else if (onetime[2].status == "review") {
                  mainactionSBp = "upload";
                  SBpStatus = "review";
                  SStatusArray[2].status = SBpStatus;
                  this.setState(
                    {
                      SBpDocLink: onetime[2].BankProof,
                    },
                    () => {
                      $("#viewDocLink_SBp").css("display", "block");
                      $("#choose_SBp").hide();
                      $("#X-btn_SBp").hide();
                      $("#done-btn_SBp").hide();
                      $("#review-btn_SBp").show();
                    }
                  );
                } else if (onetime[2].status == "reupload") {
                  mainactionSBp = "reupload";
                  SBpStatus = "reupload";
                  SStatusArray[2].status = SBpStatus;
                  this.setState({}, () => {
                    $("#choose_SBp").show();
                    $("#viewDocLink_SBp").hide();
                    $("#X-btn_SBp").hide();
                    $("#done-btn_SBp").hide();
                    $("#review-btn_SBp").hide();
                  });
                } else if (onetime[2].status == "done") {
                  mainactionSBp = "upload";
                  SBpStatus = "done";
                  SStatusArray[2].status = SBpStatus;
                  this.setState(
                    {
                      SBpDocLink: onetime[2].BankProof,
                    },
                    () => {
                      $("#viewDocLink_SBp").css("display", "block");
                      $("#choose_SBp").hide();
                      $("#X-btn_SBp").hide();
                      $("#done-btn_SBp").show();
                      $("#review-btn_SBp").hide();
                    }
                  );
                } else {
                  mainactionSBp = "upload";
                  SBpStatus = "open";
                  SStatusArray[2].status = SBpStatus;
                  $("#viewDocLink_SBp").hide();
                  $("#choose_SBp").show();
                  $("#X-btn_SBp").hide();
                  $("#done-btn_SBp").hide();
                  $("#review-btn_SBp").hide();
                }
                if (onetime[3].status == "remove") {
                  mainactionSPR = "upload";
                  SPRStatus = "remove";
                  SOptStatusArray[1].status = SPRStatus;
                  this.setState(
                    {
                      SPRDocLink: onetime[3].PreviousReturns,
                    },
                    () => {
                      $("#viewDocLink_SPR").css("display", "block");
                      $("#choose_SPR").hide();
                      $("#X-btn_SPR").show();
                      $("#done-btn_SPR").hide();
                      $("#review-btn_SPR").hide();
                    }
                  );
                } else if (onetime[3].status == "review") {
                  mainactionSPR = "upload";
                  SPRStatus = "review";
                  SOptStatusArray[1].status = SPRStatus;
                  this.setState(
                    {
                      SPRDocLink: onetime[3].PreviousReturns,
                    },
                    () => {
                      $("#viewDocLink_SPR").css("display", "block");
                      $("#choose_SPR").hide();
                      $("#X-btn_SPR").hide();
                      $("#done-btn_SPR").hide();
                      $("#review-btn_SPR").show();
                    }
                  );
                } else if (onetime[3].status == "reupload") {
                  mainactionSPR = "reupload";
                  SPRStatus = "reupload";
                  SOptStatusArray[1].status = SPRStatus;
                  this.setState({}, () => {
                    $("#choose_SPR").show();
                    $("#viewDocLink_SPR").hide();
                    $("#X-btn_SPR").hide();
                    $("#done-btn_SPR").hide();
                    $("#review-btn_SPR").hide();
                  });
                } else if (onetime[3].status == "done") {
                  mainactionSPR = "upload";
                  SPRStatus = "done";
                  SOptStatusArray[1].status = SPRStatus;
                  this.setState(
                    {
                      SPRDocLink: onetime[3].PreviousReturns,
                    },
                    () => {
                      $("#viewDocLink_SPR").css("display", "block");
                      $("#choose_SPR").hide();
                      $("#X-btn_SPR").hide();
                      $("#done-btn_SPR").show();
                      $("#review-btn_SPR").hide();
                    }
                  );
                } else {
                  mainactionSPR = "upload";
                  SPRStatus = "open";
                  SOptStatusArray[1].status = SPRStatus;
                  $("#viewDocLink_SPR").hide();
                  $("#choose_SPR").show();
                  $("#X-btn_SPR").hide();
                  $("#done-btn_SPR").hide();
                  $("#review-btn_SPR").hide();
                }
                if (onetime[4].status == "remove") {
                  mainactionSPBS = "upload";
                  SPBSStatus = "remove";
                  SOptStatusArray[0].status = SPBSStatus;
                  this.setState(
                    {
                      SPBSDocLink: onetime[4].PreviousBS,
                    },
                    () => {
                      $("#viewDocLink_SPBS").css("display", "block");
                      $("#choose_SPBS").hide();
                      $("#X-btn_SPBS").show();
                      $("#done-btn_SPBS").hide();
                      $("#review-btn_SPBS").hide();
                    }
                  );
                } else if (onetime[4].status == "review") {
                  mainactionSPBS = "upload";
                  SPBSStatus = "review";
                  SOptStatusArray[0].status = SPBSStatus;
                  this.setState(
                    {
                      SPBSDocLink: onetime[4].PreviousBS,
                    },
                    () => {
                      $("#viewDocLink_SPBS").css("display", "block");
                      $("#choose_SPBS").hide();
                      $("#X-btn_SPBS").hide();
                      $("#done-btn_SPBS").hide();
                      $("#review-btn_SPBS").show();
                    }
                  );
                } else if (onetime[4].status == "reupload") {
                  mainactionSPBS = "reupload";
                  SPBSStatus = "reupload";
                  SOptStatusArray[0].status = SPBSStatus;
                  this.setState({}, () => {
                    $("#choose_SPBS").show();
                    $("#viewDocLink_SPBS").hide();
                    $("#X-btn_SPBS").hide();
                    $("#done-btn_SPBS").hide();
                    $("#review-btn_SPBS").hide();
                  });
                } else if (onetime[4].status == "done") {
                  mainactionSPBS = "upload";
                  SPBSStatus = "done";
                  SOptStatusArray[0].status = SPBSStatus;
                  this.setState(
                    {
                      SPBSDocLink: onetime[4].PreviousBS,
                    },
                    () => {
                      $("#viewDocLink_SPBS").css("display", "block");
                      $("#choose_SPBS").hide();
                      $("#X-btn_SPBS").hide();
                      $("#done-btn_SPBS").show();
                      $("#review-btn_SPBS").hide();
                    }
                  );
                } else {
                  mainactionSPBS = "upload";
                  SPBSStatus = "open";
                  SOptStatusArray[0].status = SPBSStatus;
                  $("#viewDocLink_SPBS").hide();
                  $("#choose_SPBS").show();
                  $("#X-btn_SPBS").hide();
                  $("#done-btn_SPBS").hide();
                  $("#review-btn_SPBS").hide();
                }
              }
            } else {
              $("#choose_SPBS").show();
              $("#viewDocLink_SPBS").hide();
              $("#X-btn_SPBS").hide();
              $("#done-btn_SPBS").hide();
              $("#review-btn_SPBS").hide();

              $("#choose_SPR").show();
              $("#viewDocLink_SPR").hide();
              $("#X-btn_SPR").hide();
              $("#done-btn_SPR").hide();
              $("#review-btn_SPR").hide();

              $("#choose_SBp").show();
              $("#viewDocLink_SBp").hide();
              $("#X-btn_SBp").hide();
              $("#done-btn_SBp").hide();
              $("#review-btn_SBp").hide();

              $("#choose_SPan").show();
              $("#viewDocLink_SPan").hide();
              $("#X-btn_SPan").hide();
              $("#done-btn_SPan").hide();
              $("#review-btn_SPan").hide();

              $("#choose_SAadhar").show();
              $("#viewDocLink_SAadhar").hide();
              $("#X-btn_SAadhar").hide();
              $("#done-btn_SAadhar").hide();
              $("#review-btn_SAadhar").hide();
            }

            if (
              res.data.fyStatus == "lock" ||
              res.data.fyStatus == "complete"
            ) {
              $(".proceednowbtn").hide();
              if (res.data.incD[0].documents.length == 0) {
                $("#SincDadditionalDocumentDiv").hide();
              } else {
                $("#SincDadddocumentbtn").hide();
              }
              if (res.data.addDocInformation.length == 0) {
                $("#SadditionalDocumentDiv").hide();
              } else {
                $("#Sadddocumentbtn").hide();
              }
            }
            if (res.data.incD[0].documents.length > 0) {
              $("#SincDadditionalDocumentDiv").show();
              $("#SincDadddocumentbtn").hide();
              this.setState({
                SincDAdditionalFiles: [...res.data.incD[0].documents],
                SincDAdd_DocName: "File Name",
                api_SincDAdditionalFiles: "Yes",
              });
            } else if (
              res.data.incD[0].documents.length == 0 &&
              res.data.fyStatus != "lock" &&
              res.data.fyStatus != "complete"
            ) {
              $("#SincDadditionalDocumentDiv").show();
              this.setState({
                SincDAdd_DocName: "",
                SincDAdditionalFiles: [1],
                api_SincDAdditionalFiles: "No",
              });
            }

            if (res.data.addDocInformation.length > 0) {
              $("#SadditionalDocumentDiv").show();
              $("#Sadddocumentbtn").hide();
              this.setState({
                SAdditionalFiles: [...res.data.addDocInformation],
                SAdd_DocName: "File Name",
                api_SAdditionalFiles: "Yes",
              });
            } else if (
              res.data.addDocInformation.length == 0 &&
              res.data.fyStatus != "lock" &&
              res.data.fyStatus != "complete"
            ) {
              $("#SadditionalDocumentDiv").show();
              $("#Sadddocumentbtn").show();
              this.setState({
                SAdd_DocName: "",
                SAdditionalFiles: [1],
                api_SAdditionalFiles: "No",
              });
            }

            this.ScheckRemainingReuploads();
            let statusCheck = this.statusCheckFunction(
              "reupload",
              SStatusArray
            );
            SincDadditionalStatusArrayCheck = this.statusCheckFunction(
              "reupload",
              this.state.SincDAdditionalFiles
            );
            SadditionalStatusArrayCheck = this.statusCheckFunction(
              "reupload",
              this.state.SAdditionalFiles
            );

            if (
              AnyReuploadOccuredS == "Yes" &&
              statusCheck == undefined &&
              SincDadditionalStatusArrayCheck == undefined &&
              SadditionalStatusArrayCheck == undefined
            ) {
              SOptReview = "Yes";
              this.countOptReuploadS("reupload", SOptStatusArray);
            } else {
              SOptReview = "No";
            }
            if (
              res.data.rejectstatus == "false" &&
              res.data.mainstatus == "true"
            ) {
              rejectstatus = "Yes";
            }
          } else if (res.data.serviceType == "ITRF_BP_OneTime_Documents") {
            $(".selectcateselect1").attr("disabled", true);
            this.setState(
              {
                dropDownValue: "ITRF_BP_OneTime_Documents",
                serviceType: "ITRF_BP_OneTime_Documents",
              },
              () => {
                if (this.state.dropDownValue == "ITRF_BP_OneTime_Documents") {
                  $("#Itrf_default_Img").hide();
                  $("#ItrfSalariedSection").hide();
                  $("#ItrfproprietorSection").show();
                  $("#ItrfPartnershipFirm").hide();
                  $("#ItrfCompany_LLP").hide();
                }
              }
            );

            if (res.data.oneTime.length > 0) {
              if (res.data.oneTime[0].documents.length > 0) {
                let onetime = res.data.oneTime[0].documents;
                if (onetime[0].status == "remove") {
                  mainactionPAadhar = "upload";
                  PAadharStatus = "remove";
                  PStatusArray[0].status = PAadharStatus;
                  this.setState(
                    {
                      PAadharDocLink: onetime[0].AadhaarCard,
                    },
                    () => {
                      $("#viewDocLink_PAadhar").css("display", "block");
                      $("#choose_PAadhar").hide();
                      $("#X-btn_PAadhar").show();
                      $("#done-btn_PAadhar").hide();
                      $("#review-btn_PAadhar").hide();
                    }
                  );
                } else if (onetime[0].status == "review") {
                  mainactionPAadhar = "upload";
                  PAadharStatus = "review";
                  PStatusArray[0].status = PAadharStatus;
                  this.setState(
                    {
                      PAadharDocLink: onetime[0].AadhaarCard,
                    },
                    () => {
                      $("#viewDocLink_PAadhar").css("display", "block");
                      $("#choose_PAadhar").hide();
                      $("#X-btn_PAadhar").hide();
                      $("#done-btn_PAadhar").hide();
                      $("#review-btn_PAadhar").show();
                    }
                  );
                } else if (onetime[0].status == "reupload") {
                  mainactionPAadhar = "reupload";
                  PAadharStatus = "reupload";
                  PStatusArray[0].status = PAadharStatus;
                  this.setState({}, () => {
                    $("#choose_PAadhar").show();
                    $("#viewDocLink_PAadhar").hide();
                    $("#X-btn_PAadhar").hide();
                    $("#done-btn_PAadhar").hide();
                    $("#review-btn_PAadhar").hide();
                  });
                } else if (onetime[0].status == "done") {
                  mainactionPAadhar = "upload";
                  PAadharStatus = "done";
                  PStatusArray[0].status = PAadharStatus;
                  this.setState(
                    {
                      PAadharDocLink: onetime[0].AadhaarCard,
                    },
                    () => {
                      $("#viewDocLink_PAadhar").css("display", "block");
                      $("#choose_PAadhar").hide();
                      $("#X-btn_PAadhar").hide();
                      $("#done-btn_PAadhar").show();
                      $("#review-btn_PAadhar").hide();
                    }
                  );
                } else {
                  mainactionPAadhar = "upload";
                  PAadharStatus = "open";
                  PStatusArray[0].status = PAadharStatus;
                  $("#viewDocLink_PAadhar").hide();
                  $("#choose_PAadhar").show();
                  $("#X-btn_PAadhar").hide();
                  $("#done-btn_PAadhar").hide();
                  $("#review-btn_PAadhar").hide();
                }
                if (onetime[1].status == "remove") {
                  mainactionPPan = "upload";
                  PPanStatus = "remove";
                  PStatusArray[1].status = PPanStatus;
                  this.setState(
                    {
                      PPanDocLink: onetime[1].PANCard,
                    },
                    () => {
                      $("#viewDocLink_PPan").css("display", "block");
                      $("#choose_PPan").hide();
                      $("#X-btn_PPan").show();
                      $("#done-btn_PPan").hide();
                      $("#review-btn_PPan").hide();
                    }
                  );
                } else if (onetime[1].status == "review") {
                  mainactionPPan = "upload";
                  PPanStatus = "review";
                  PStatusArray[1].status = PPanStatus;
                  this.setState(
                    {
                      PPanDocLink: onetime[1].PANCard,
                    },
                    () => {
                      $("#viewDocLink_PPan").css("display", "block");
                      $("#choose_PPan").hide();
                      $("#X-btn_PPan").hide();
                      $("#done-btn_PPan").hide();
                      $("#review-btn_PPan").show();
                    }
                  );
                } else if (onetime[1].status == "reupload") {
                  mainactionPPan = "reupload";
                  PPanStatus = "reupload";
                  PStatusArray[1].status = PPanStatus;
                  this.setState({}, () => {
                    $("#choose_PPan").show();
                    $("#viewDocLink_PPan").hide();
                    $("#X-btn_PPan").hide();
                    $("#done-btn_PPan").hide();
                    $("#review-btn_PPan").hide();
                  });
                } else if (onetime[1].status == "done") {
                  mainactionPPan = "upload";
                  PPanStatus = "done";
                  PStatusArray[1].status = PPanStatus;
                  this.setState(
                    {
                      PPanDocLink: onetime[1].PANCard,
                    },
                    () => {
                      $("#viewDocLink_PPan").css("display", "block");
                      $("#choose_PPan").hide();
                      $("#X-btn_PPan").hide();
                      $("#done-btn_PPan").show();
                      $("#review-btn_PPan").hide();
                    }
                  );
                } else {
                  mainactionPPan = "upload";
                  PPanStatus = "open";
                  PStatusArray[1].status = PPanStatus;
                  $("#viewDocLink_PPan").hide();
                  $("#choose_PPan").show();
                  $("#X-btn_PPan").hide();
                  $("#done-btn_PPan").hide();
                  $("#review-btn_PPan").hide();
                }
                if (onetime[2].status == "remove") {
                  mainactionPBP = "upload";
                  PBPStatus = "remove";
                  PStatusArray[2].status = PBPStatus;
                  this.setState(
                    {
                      PBPDocLink: onetime[2].BankProof,
                    },
                    () => {
                      $("#viewDocLink_PBP").css("display", "block");
                      $("#choose_PBP").hide();
                      $("#X-btn_PBP").show();
                      $("#done-btn_PBP").hide();
                      $("#review-btn_PBP").hide();
                    }
                  );
                } else if (onetime[2].status == "review") {
                  mainactionPBP = "upload";
                  PBPStatus = "review";
                  PStatusArray[2].status = PBPStatus;
                  this.setState(
                    {
                      PBPDocLink: onetime[2].BankProof,
                    },
                    () => {
                      $("#viewDocLink_PBP").css("display", "block");
                      $("#choose_PBP").hide();
                      $("#X-btn_PBP").hide();
                      $("#done-btn_PBP").hide();
                      $("#review-btn_PBP").show();
                    }
                  );
                } else if (onetime[2].status == "reupload") {
                  mainactionPBP = "reupload";
                  PBPStatus = "reupload";
                  PStatusArray[2].status = PBPStatus;
                  this.setState({}, () => {
                    $("#choose_PBP").show();
                    $("#viewDocLink_PBP").hide();
                    $("#X-btn_PBP").hide();
                    $("#done-btn_PBP").hide();
                    $("#review-btn_PBP").hide();
                  });
                } else if (onetime[2].status == "done") {
                  mainactionPBP = "upload";
                  PBPStatus = "done";
                  PStatusArray[2].status = PBPStatus;
                  this.setState(
                    {
                      PBPDocLink: onetime[2].BankProof,
                    },
                    () => {
                      $("#viewDocLink_PBP").css("display", "block");
                      $("#choose_PBP").hide();
                      $("#X-btn_PBP").hide();
                      $("#done-btn_PBP").show();
                      $("#review-btn_PBP").hide();
                    }
                  );
                } else {
                  mainactionPBP = "upload";
                  PBPStatus = "open";
                  PStatusArray[2].status = PBPStatus;
                  $("#viewDocLink_PBP").hide();
                  $("#choose_PBP").show();
                  $("#X-btn_PBP").hide();
                  $("#done-btn_PBP").hide();
                  $("#review-btn_PBP").hide();
                }
                if (onetime[4].status == "remove") {
                  mainactionPBS = "upload";
                  PBSStatus = "remove";
                  POptStatusArray[0].status = PBSStatus;
                  this.setState(
                    {
                      PBSDocLink: onetime[4].PreviousBS_Audit,
                    },
                    () => {
                      $("#viewDocLink_PBS").css("display", "block");
                      $("#choose_PBS").hide();
                      $("#X-btn_PBS").show();
                      $("#done-btn_PBS").hide();
                      $("#review-btn_PBS").hide();
                    }
                  );
                } else if (onetime[4].status == "review") {
                  mainactionPBS = "upload";
                  PBSStatus = "review";
                  POptStatusArray[0].status = PBSStatus;
                  this.setState(
                    {
                      PBSDocLink: onetime[4].PreviousBS_Audit,
                    },
                    () => {
                      $("#viewDocLink_PBS").css("display", "block");
                      $("#choose_PBS").hide();
                      $("#X-btn_PBS").hide();
                      $("#done-btn_PBS").hide();
                      $("#review-btn_PBS").show();
                    }
                  );
                } else if (onetime[4].status == "reupload") {
                  mainactionPBS = "reupload";
                  PBSStatus = "reupload";
                  POptStatusArray[0].status = PBSStatus;
                  this.setState({}, () => {
                    $("#choose_PBS").show();
                    $("#viewDocLink_PBS").hide();
                    $("#X-btn_PBS").hide();
                    $("#done-btn_PBS").hide();
                    $("#review-btn_PBS").hide();
                  });
                } else if (onetime[4].status == "done") {
                  mainactionPBS = "upload";
                  PBSStatus = "done";
                  POptStatusArray[0].status = PBSStatus;
                  this.setState(
                    {
                      PBSDocLink: onetime[4].PreviousBS_Audit,
                    },
                    () => {
                      $("#viewDocLink_PBS").css("display", "block");
                      $("#choose_PBS").hide();
                      $("#X-btn_PBS").hide();
                      $("#done-btn_PBS").show();
                      $("#review-btn_PBS").hide();
                    }
                  );
                } else {
                  mainactionPBS = "upload";
                  PBSStatus = "open";
                  POptStatusArray[0].status = PBSStatus;
                  $("#viewDocLink_PBS").hide();
                  $("#choose_PBS").show();
                  $("#X-btn_PBS").hide();
                  $("#done-btn_PBS").hide();
                  $("#review-btn_PBS").hide();
                }
                if (onetime[3].status == "remove") {
                  mainactionPPR = "upload";
                  PPRStatus = "remove";
                  POptStatusArray[1].status = PPRStatus;
                  this.setState(
                    {
                      PPRDocLink: onetime[3].PreviousReturns,
                    },
                    () => {
                      $("#viewDocLink_PPR").css("display", "block");
                      $("#choose_PPR").hide();
                      $("#X-btn_PPR").show();
                      $("#done-btn_PPR").hide();
                      $("#review-btn_PPR").hide();
                    }
                  );
                } else if (onetime[3].status == "review") {
                  mainactionPPR = "upload";
                  PPRStatus = "review";
                  POptStatusArray[1].status = PPRStatus;
                  this.setState(
                    {
                      PPRDocLink: onetime[3].PreviousReturns,
                    },
                    () => {
                      $("#viewDocLink_PPR").css("display", "block");
                      $("#choose_PPR").hide();
                      $("#X-btn_PPR").hide();
                      $("#done-btn_PPR").hide();
                      $("#review-btn_PPR").show();
                    }
                  );
                } else if (onetime[3].status == "reupload") {
                  mainactionPPR = "reupload";
                  PPRStatus = "reupload";
                  POptStatusArray[1].status = PPRStatus;
                  this.setState({}, () => {
                    $("#choose_PPR").show();
                    $("#viewDocLink_PPR").hide();
                    $("#X-btn_PPR").hide();
                    $("#done-btn_PPR").hide();
                    $("#review-btn_PPR").hide();
                  });
                } else if (onetime[3].status == "done") {
                  mainactionPPR = "upload";
                  PPRStatus = "done";
                  POptStatusArray[1].status = PPRStatus;
                  this.setState(
                    {
                      PPRDocLink: onetime[3].PreviousReturns,
                    },
                    () => {
                      $("#viewDocLink_PPR").css("display", "block");
                      $("#choose_PPR").hide();
                      $("#X-btn_PPR").hide();
                      $("#done-btn_PPR").show();
                      $("#review-btn_PPR").hide();
                    }
                  );
                } else {
                  mainactionPPR = "upload";
                  PPRStatus = "open";
                  POptStatusArray[1].status = PPRStatus;
                  $("#viewDocLink_PPR").hide();
                  $("#choose_PPR").show();
                  $("#X-btn_PPR").hide();
                  $("#done-btn_PPR").hide();
                  $("#review-btn_PPR").hide();
                }
              }
            } else {
              $("#viewDocLink_PAadhar").hide();
              $("#choose_PAadhar").show();
              $("#X-btn_PAadhar").hide();
              $("#done-btn_PAadhar").hide();
              $("#review-btn_PAadhar").hide();

              $("#viewDocLink_PPan").hide();
              $("#choose_PPan").show();
              $("#X-btn_PPan").hide();
              $("#done-btn_PPan").hide();
              $("#review-btn_PPan").hide();

              $("#viewDocLink_PBP").hide();
              $("#choose_PBP").show();
              $("#X-btn_PBP").hide();
              $("#done-btn_PBP").hide();
              $("#review-btn_PBP").hide();

              $("#viewDocLink_PBS").hide();
              $("#choose_PBS").show();
              $("#X-btn_PBS").hide();
              $("#done-btn_PBS").hide();
              $("#review-btn_PBS").hide();

              $("#viewDocLink_PPR").hide();
              $("#choose_PPR").show();
              $("#X-btn_PPR").hide();
              $("#done-btn_PPR").hide();
              $("#review-btn_PPR").hide();
            }
            if (res.data.documents.length > 0) {
              let doc = res.data.documents;
              if (doc[0].status == "remove") {
                mainactionPData = "upload";
                PDataStatus = "remove";
                PStatusArray[3].status = PDataStatus;
                this.setState(
                  {
                    PDataDocLink: doc[0].Data,
                  },
                  () => {
                    $("#viewDocLink_PData").css("display", "block");
                    $("#choose_PData").hide();
                    $("#X-btn_PData").show();
                    $("#done-btn_PData").hide();
                    $("#review-btn_PData").hide();
                  }
                );
              } else if (doc[0].status == "review") {
                mainactionPData = "upload";
                PDataStatus = "review";
                PStatusArray[3].status = PDataStatus;
                this.setState(
                  {
                    PDataDocLink: doc[0].Data,
                  },
                  () => {
                    $("#viewDocLink_PData").css("display", "block");
                    $("#choose_PData").hide();
                    $("#X-btn_PData").hide();
                    $("#done-btn_PData").hide();
                    $("#review-btn_PData").show();
                  }
                );
              } else if (doc[0].status == "reupload") {
                mainactionPData = "reupload";
                PDataStatus = "reupload";
                PStatusArray[3].status = PDataStatus;
                this.setState({}, () => {
                  $("#choose_PData").show();
                  $("#viewDocLink_PData").hide();
                  $("#X-btn_PData").hide();
                  $("#done-btn_PData").hide();
                  $("#review-btn_PData").hide();
                });
              } else if (doc[0].status == "done") {
                mainactionPData = "upload";
                PDataStatus = "done";
                PStatusArray[3].status = PDataStatus;
                this.setState(
                  {
                    PDataDocLink: doc[0].Data,
                  },
                  () => {
                    $("#viewDocLink_PData").css("display", "block");
                    $("#choose_PData").hide();
                    $("#X-btn_PData").hide();
                    $("#done-btn_PData").show();
                    $("#review-btn_PData").hide();
                  }
                );
              } else {
                mainactionPData = "upload";
                PDataStatus = "open";
                PStatusArray[3].status = PDataStatus;
                $("#viewDocLink_PData").hide();
                $("#choose_PData").show();
                $("#X-btn_PData").hide();
                $("#done-btn_PData").hide();
                $("#review-btn_PData").hide();
              }
              if (doc[1].status == "remove") {
                mainactionPGSTReturn = "upload";
                PGSTReturnStatus = "remove";
                POptStatusArray[2].status = PGSTReturnStatus;
                this.setState(
                  {
                    PGSTReturnDocLink: doc[1].GSTReturn,
                  },
                  () => {
                    $("#viewDocLink_PGSTReturn").css("display", "block");
                    $("#choose_PGSTReturn").hide();
                    $("#X-btn_PGSTReturn").show();
                    $("#done-btn_PGSTReturn").hide();
                    $("#review-btn_PGSTReturn").hide();
                  }
                );
              } else if (doc[1].status == "review") {
                mainactionPGSTReturn = "upload";
                PGSTReturnStatus = "review";
                POptStatusArray[2].status = PGSTReturnStatus;
                this.setState(
                  {
                    PGSTReturnDocLink: doc[1].GSTReturn,
                  },
                  () => {
                    $("#viewDocLink_PGSTReturn").css("display", "block");
                    $("#choose_PGSTReturn").hide();
                    $("#X-btn_PGSTReturn").hide();
                    $("#done-btn_PGSTReturn").hide();
                    $("#review-btn_PGSTReturn").show();
                  }
                );
              } else if (doc[1].status == "reupload") {
                mainactionPGSTReturn = "reupload";
                PGSTReturnStatus = "reupload";
                POptStatusArray[2].status = PGSTReturnStatus;
                this.setState({}, () => {
                  $("#choose_PGSTReturn").show();
                  $("#viewDocLink_PGSTReturn").hide();
                  $("#X-btn_PGSTReturn").hide();
                  $("#done-btn_PGSTReturn").hide();
                  $("#review-btn_PGSTReturn").hide();
                });
              } else if (doc[1].status == "done") {
                mainactionPGSTReturn = "upload";
                PGSTReturnStatus = "done";
                POptStatusArray[2].status = PGSTReturnStatus;
                this.setState(
                  {
                    PGSTReturnDocLink: doc[1].GSTReturn,
                  },
                  () => {
                    $("#viewDocLink_PGSTReturn").css("display", "block");
                    $("#choose_PGSTReturn").hide();
                    $("#X-btn_PGSTReturn").hide();
                    $("#done-btn_PGSTReturn").show();
                    $("#review-btn_PGSTReturn").hide();
                  }
                );
              } else {
                mainactionPGSTReturn = "upload";
                PGSTReturnStatus = "open";
                POptStatusArray[2].status = PGSTReturnStatus;
                $("#viewDocLink_PGSTReturn").hide();
                $("#choose_PGSTReturn").show();
                $("#X-btn_PGSTReturn").hide();
                $("#done-btn_PGSTReturn").hide();
                $("#review-btn_PGSTReturn").hide();
              }
              if (doc[2].status == "remove") {
                mainactionPTDSReturn = "upload";
                PTDSReturnStatus = "remove";
                POptStatusArray[3].status = PTDSReturnStatus;
                this.setState(
                  {
                    PTDSReturnDocLink: doc[2].TDSReturn,
                  },
                  () => {
                    $("#viewDocLink_PTDSReturn").css("display", "block");
                    $("#choose_PTDSReturn").hide();
                    $("#X-btn_PTDSReturn").show();
                    $("#done-btn_PTDSReturn").hide();
                    $("#review-btn_PTDSReturn").hide();
                  }
                );
              } else if (doc[2].status == "review") {
                mainactionPTDSReturn = "upload";
                PTDSReturnStatus = "review";
                POptStatusArray[3].status = PTDSReturnStatus;
                this.setState(
                  {
                    PTDSReturnDocLink: doc[2].TDSReturn,
                  },
                  () => {
                    $("#viewDocLink_PTDSReturn").css("display", "block");
                    $("#choose_PTDSReturn").hide();
                    $("#X-btn_PTDSReturn").hide();
                    $("#done-btn_PTDSReturn").hide();
                    $("#review-btn_PTDSReturn").show();
                  }
                );
              } else if (doc[2].status == "reupload") {
                mainactionPTDSReturn = "reupload";
                PTDSReturnStatus = "reupload";
                POptStatusArray[3].status = PTDSReturnStatus;
                this.setState({}, () => {
                  $("#choose_PTDSReturn").show();
                  $("#viewDocLink_PTDSReturn").hide();
                  $("#X-btn_PTDSReturn").hide();
                  $("#done-btn_PTDSReturn").hide();
                  $("#review-btn_PTDSReturn").hide();
                });
              } else if (doc[2].status == "done") {
                mainactionPTDSReturn = "upload";
                PTDSReturnStatus = "done";
                POptStatusArray[3].status = PTDSReturnStatus;
                this.setState(
                  {
                    PTDSReturnDocLink: doc[2].TDSReturn,
                  },
                  () => {
                    $("#viewDocLink_PTDSReturn").css("display", "block");
                    $("#choose_PTDSReturn").hide();
                    $("#X-btn_PTDSReturn").hide();
                    $("#done-btn_PTDSReturn").show();
                    $("#review-btn_PTDSReturn").hide();
                  }
                );
              } else {
                mainactionPTDSReturn = "upload";
                PTDSReturnStatus = "open";
                POptStatusArray[3].status = PTDSReturnStatus;
                $("#viewDocLink_PTDSReturn").hide();
                $("#choose_PTDSReturn").show();
                $("#X-btn_PTDSReturn").hide();
                $("#done-btn_PTDSReturn").hide();
                $("#review-btn_PTDSReturn").hide();
              }
              if (doc[3].status == "remove") {
                mainactionPChallan = "upload";
                PChallanStatus = "remove";
                POptStatusArray[4].status = PChallanStatus;
                this.setState(
                  {
                    PChallanDocLink: doc[3].Challan,
                  },
                  () => {
                    $("#viewDocLink_PChallan").css("display", "block");
                    $("#choose_PChallan").hide();
                    $("#X-btn_PChallan").show();
                    $("#done-btn_PChallan").hide();
                    $("#review-btn_PChallan").hide();
                  }
                );
              } else if (doc[3].status == "review") {
                mainactionPChallan = "upload";
                PChallanStatus = "review";
                POptStatusArray[4].status = PChallanStatus;
                this.setState(
                  {
                    PChallanDocLink: doc[3].Challan,
                  },
                  () => {
                    $("#viewDocLink_PChallan").css("display", "block");
                    $("#choose_PChallan").hide();
                    $("#X-btn_PChallan").hide();
                    $("#done-btn_PChallan").hide();
                    $("#review-btn_PChallan").show();
                  }
                );
              } else if (doc[3].status == "reupload") {
                mainactionPChallan = "reupload";
                PChallanStatus = "reupload";
                POptStatusArray[4].status = PChallanStatus;
                this.setState({}, () => {
                  $("#choose_PChallan").show();
                  $("#viewDocLink_PChallan").hide();
                  $("#X-btn_PChallan").hide();
                  $("#done-btn_PChallan").hide();
                  $("#review-btn_PChallan").hide();
                });
              } else if (doc[3].status == "done") {
                mainactionPChallan = "upload";
                PChallanStatus = "done";
                POptStatusArray[4].status = PChallanStatus;
                this.setState(
                  {
                    PChallanDocLink: doc[3].Challan,
                  },
                  () => {
                    $("#viewDocLink_PChallan").css("display", "block");
                    $("#choose_PChallan").hide();
                    $("#X-btn_PChallan").hide();
                    $("#done-btn_PChallan").show();
                    $("#review-btn_PChallan").hide();
                  }
                );
              } else {
                mainactionPChallan = "upload";
                PChallanStatus = "open";
                POptStatusArray[4].status = PChallanStatus;
                $("#viewDocLink_PChallan").hide();
                $("#choose_PChallan").show();
                $("#X-btn_PChallan").hide();
                $("#done-btn_PChallan").hide();
                $("#review-btn_PChallan").hide();
              }
              if (doc[4].status == "remove") {
                mainactionPBankS = "upload";
                PBankSStatus = "remove";
                PStatusArray[4].status = PBankSStatus;
                this.setState(
                  {
                    PBankSDocLink: doc[4].BankStatement,
                  },
                  () => {
                    $("#viewDocLink_PBankS").css("display", "block");
                    $("#choose_PBankS").hide();
                    $("#X-btn_PBankS").show();
                    $("#done-btn_PBankS").hide();
                    $("#review-btn_PBankS").hide();
                  }
                );
              } else if (doc[4].status == "review") {
                mainactionPBankS = "upload";
                PBankSStatus = "review";
                PStatusArray[4].status = PBankSStatus;
                this.setState(
                  {
                    PBankSDocLink: doc[4].BankStatement,
                  },
                  () => {
                    $("#viewDocLink_PBankS").css("display", "block");
                    $("#choose_PBankS").hide();
                    $("#X-btn_PBankS").hide();
                    $("#done-btn_PBankS").hide();
                    $("#review-btn_PBankS").show();
                  }
                );
              } else if (doc[4].status == "reupload") {
                mainactionPBankS = "reupload";
                PBankSStatus = "reupload";
                PStatusArray[4].status = PBankSStatus;
                this.setState({}, () => {
                  $("#choose_PBankS").show();
                  $("#viewDocLink_PBankS").hide();
                  $("#X-btn_PBankS").hide();
                  $("#done-btn_PBankS").hide();
                  $("#review-btn_PBankS").hide();
                });
              } else if (doc[4].status == "done") {
                mainactionPBankS = "upload";
                PBankSStatus = "done";
                PStatusArray[4].status = PBankSStatus;
                this.setState(
                  {
                    PBankSDocLink: doc[4].BankStatement,
                  },
                  () => {
                    $("#viewDocLink_PBankS").css("display", "block");
                    $("#choose_PBankS").hide();
                    $("#X-btn_PBankS").hide();
                    $("#done-btn_PBankS").show();
                    $("#review-btn_PBankS").hide();
                  }
                );
              } else {
                mainactionPBankS = "upload";
                PBankSStatus = "open";
                PStatusArray[4].status = PBankSStatus;
                $("#viewDocLink_PBankS").hide();
                $("#choose_PBankS").show();
                $("#X-btn_PBankS").hide();
                $("#done-btn_PBankS").hide();
                $("#review-btn_PBankS").hide();
              }
            } else {
              $("#viewDocLink_PData").hide();
              $("#choose_PData").show();
              $("#X-btn_PData").hide();
              $("#done-btn_PData").hide();
              $("#review-btn_PData").hide();

              $("#viewDocLink_PBankS").hide();
              $("#choose_PBankS").show();
              $("#X-btn_PBankS").hide();
              $("#done-btn_PBankS").hide();
              $("#review-btn_PBankS").hide();

              $("#viewDocLink_PGSTReturn").hide();
              $("#choose_PGSTReturn").show();
              $("#X-btn_PGSTReturn").hide();
              $("#done-btn_PGSTReturn").hide();
              $("#review-btn_PGSTReturn").hide();

              $("#viewDocLink_PTDSReturn").hide();
              $("#choose_PTDSReturn").show();
              $("#X-btn_PTDSReturn").hide();
              $("#done-btn_PTDSReturn").hide();
              $("#review-btn_PTDSReturn").hide();

              $("#viewDocLink_PChallan").hide();
              $("#choose_PChallan").show();
              $("#X-btn_PChallan").hide();
              $("#done-btn_PChallan").hide();
              $("#review-btn_PChallan").hide();
            }
            if (
              res.data.fyStatus == "lock" ||
              res.data.fyStatus == "complete"
            ) {
              $(".proceednowbtn").hide();
              if (res.data.incD[0].documents.length == 0) {
                $("#PincDadditionalDocumentDiv").hide();
              } else {
                $("#PincDadddocumentbtn").hide();
              }
              if (res.data.addDocInformation.length == 0) {
                $("#PadditionalDocumentDiv").hide();
              } else {
                $("#Padddocumentbtn").hide();
              }
            }
            if (res.data.incD[0].documents.length > 0) {
              $("#PincDadditionalDocumentDiv").show();
              $("#PincDadddocumentbtn").hide();
              this.setState(
                {
                  PincDAdditionalFiles: [...res.data.incD[0].documents],
                  PincDAdd_DocName: "File Name",
                  api_PincDAdditionalFiles: "Yes",
                },
                () => {}
              );
            } else if (
              res.data.incD[0].documents.length == 0 &&
              res.data.fyStatus != "lock" &&
              res.data.fyStatus != "complete"
            ) {
              $("#PincDadditionalDocumentDiv").show();
              this.setState({
                PincDAdd_DocName: "",
                PincDAdditionalFiles: [1],
                api_PincDAdditionalFiles: "No",
              });
            }
            if (res.data.addDocInformation.length > 0) {
              $("#PadditionalDocumentDiv").show();
              $("#Padddocumentbtn").hide();
              this.setState(
                {
                  PAdditionalFiles: [...res.data.addDocInformation],
                  PAdd_DocName: "File Name",
                  api_PAdditionalFiles: "Yes",
                },
                () => {}
              );
            } else if (
              res.data.addDocInformation.length == 0 &&
              res.data.fyStatus != "lock" &&
              res.data.fyStatus != "complete"
            ) {
              $("#PadditionalDocumentDiv").show();
              $("#Padddocumentbtn").show();
              this.setState({
                PAdd_DocName: "",
                PAdditionalFiles: [1],
                api_PAdditionalFiles: "No",
              });
            }

            this.PcheckRemainingReuploads();
            let statusCheck = this.statusCheckFunction(
              "reupload",
              PStatusArray
            );
            PincDadditionalStatusArrayCheck = this.statusCheckFunction(
              "reupload",
              this.state.PincDAdditionalFiles
            );
            PadditionalStatusArrayCheck = this.statusCheckFunction(
              "reupload",
              this.state.PAdditionalFiles
            );

            if (
              AnyReuploadOccuredP == "Yes" &&
              statusCheck == undefined &&
              PincDadditionalStatusArrayCheck == undefined &&
              PadditionalStatusArrayCheck == undefined
            ) {
              POptReview = "Yes";
              this.countOptReuploadP("reupload", POptStatusArray);
            } else {
              POptReview = "No";
            }
            if (
              res.data.rejectstatus == "false" &&
              res.data.mainstatus == "true"
            ) {
              rejectstatus = "Yes";
            }
            // this.PcheckRemainingReuploads();
            // this.checkhandlePProceedBtn();
          } else if (res.data.serviceType == "ITRF_BPP_OneTime_Documents") {
            $(".selectcateselect1").attr("disabled", true);
            this.setState(
              {
                dropDownValue: "ITRF_BPP_OneTime_Documents",
                serviceType: "ITRF_BPP_OneTime_Documents",
              },
              () => {
                if (this.state.dropDownValue == "ITRF_BPP_OneTime_Documents") {
                  $("#Itrf_default_Img").hide();
                  $("#ItrfSalariedSection").hide();
                  $("#ItrfproprietorSection").hide();
                  $("#ItrfPartnershipFirm").show();
                  $("#ItrfCompany_LLP").hide();
                }
              }
            );
            if (res.data.oneTime.length > 0) {
              if (res.data.oneTime[0].documents.length > 0) {
                let onetime = res.data.oneTime[0].documents;
                if (onetime[0].status == "remove") {
                  mainactionPFAadhar1 = "upload";
                  PFAadhar1Status = "remove";
                  PFStatusArray[0].status = PFAadhar1Status;
                  this.setState(
                    {
                      PFAadhar1DocLink: onetime[0].AadhaarCardApplicant1,
                    },
                    () => {
                      $("#viewDocLink_PFAadhar1").css("display", "block");
                      $("#choose_PFAadhar1").hide();
                      $("#X-btn_PFAadhar1").show();
                      $("#done-btn_PFAadhar1").hide();
                      $("#review-btn_PFAadhar1").hide();
                    }
                  );
                } else if (onetime[0].status == "review") {
                  mainactionPFAadhar1 = "upload";
                  PFAadhar1Status = "review";
                  PFStatusArray[0].status = PFAadhar1Status;
                  this.setState(
                    {
                      PFAadhar1DocLink: onetime[0].AadhaarCardApplicant1,
                    },
                    () => {
                      $("#viewDocLink_PFAadhar1").css("display", "block");
                      $("#choose_PFAadhar1").hide();
                      $("#X-btn_PFAadhar1").hide();
                      $("#done-btn_PFAadhar1").hide();
                      $("#review-btn_PFAadhar1").show();
                    }
                  );
                } else if (onetime[0].status == "reupload") {
                  mainactionPFAadhar1 = "reupload";
                  PFAadhar1Status = "reupload";
                  PFStatusArray[0].status = PFAadhar1Status;
                  this.setState({}, () => {
                    $("#choose_PFAadhar1").show();
                    $("#viewDocLink_PFAadhar1").hide();
                    $("#X-btn_PFAadhar1").hide();
                    $("#done-btn_PFAadhar1").hide();
                    $("#review-btn_PFAadhar1").hide();
                  });
                } else if (onetime[0].status == "done") {
                  mainactionPFAadhar1 = "upload";
                  PFAadhar1Status = "done";
                  PFStatusArray[0].status = PFAadhar1Status;
                  this.setState(
                    {
                      PFAadhar1DocLink: onetime[0].AadhaarCardApplicant1,
                    },
                    () => {
                      $("#viewDocLink_PFAadhar1").css("display", "block");
                      $("#choose_PFAadhar1").hide();
                      $("#X-btn_PFAadhar1").hide();
                      $("#done-btn_PFAadhar1").show();
                      $("#review-btn_PFAadhar1").hide();
                    }
                  );
                } else {
                  mainactionPFAadhar1 = "upload";
                  PFAadhar1Status = "open";
                  PFStatusArray[0].status = PFAadhar1Status;
                  $("#viewDocLink_PFAadhar1").hide();
                  $("#choose_PFAadhar1").show();
                  $("#X-btn_PFAadhar1").hide();
                  $("#done-btn_PFAadhar1").hide();
                  $("#review-btn_PFAadhar1").hide();
                }
                if (onetime[1].status == "remove") {
                  mainactionPFAadhar2 = "upload";
                  PFAadhar2Status = "remove";
                  PFStatusArray[1].status = PFAadhar2Status;
                  this.setState(
                    {
                      PFAadhar2DocLink: onetime[1].AadhaarCardApplicant2,
                    },
                    () => {
                      $("#viewDocLink_PFAadhar2").css("display", "block");
                      $("#choose_PFAadhar2").hide();
                      $("#X-btn_PFAadhar2").show();
                      $("#done-btn_PFAadhar2").hide();
                      $("#review-btn_PFAadhar2").hide();
                    }
                  );
                } else if (onetime[1].status == "review") {
                  mainactionPFAadhar2 = "upload";
                  PFAadhar2Status = "review";
                  PFStatusArray[1].status = PFAadhar2Status;
                  this.setState(
                    {
                      PFAadhar2DocLink: onetime[1].AadhaarCardApplicant2,
                    },
                    () => {
                      $("#viewDocLink_PFAadhar2").css("display", "block");
                      $("#choose_PFAadhar2").hide();
                      $("#X-btn_PFAadhar2").hide();
                      $("#done-btn_PFAadhar2").hide();
                      $("#review-btn_PFAadhar2").show();
                    }
                  );
                } else if (onetime[1].status == "reupload") {
                  mainactionPFAadhar2 = "reupload";
                  PFAadhar2Status = "reupload";
                  PFStatusArray[1].status = PFAadhar2Status;
                  this.setState({}, () => {
                    $("#choose_PFAadhar2").show();
                    $("#viewDocLink_PFAadhar2").hide();
                    $("#X-btn_PFAadhar2").hide();
                    $("#done-btn_PFAadhar2").hide();
                    $("#review-btn_PFAadhar2").hide();
                  });
                } else if (onetime[1].status == "done") {
                  mainactionPFAadhar2 = "upload";
                  PFAadhar2Status = "done";
                  PFStatusArray[1].status = PFAadhar2Status;
                  this.setState(
                    {
                      PFAadhar2DocLink: onetime[1].AadhaarCardApplicant2,
                    },
                    () => {
                      $("#viewDocLink_PFAadhar2").css("display", "block");
                      $("#choose_PFAadhar2").hide();
                      $("#X-btn_PFAadhar2").hide();
                      $("#done-btn_PFAadhar2").show();
                      $("#review-btn_PFAadhar2").hide();
                    }
                  );
                } else {
                  mainactionPFAadhar2 = "upload";
                  PFAadhar2Status = "open";
                  PFStatusArray[1].status = PFAadhar2Status;
                  $("#viewDocLink_PFAadhar2").hide();
                  $("#choose_PFAadhar2").show();
                  $("#X-btn_PFAadhar2").hide();
                  $("#done-btn_PFAadhar2").hide();
                  $("#review-btn_PFAadhar2").hide();
                }
                if (onetime[2].status == "remove") {
                  mainactionPFPan1 = "upload";
                  PFPan1Status = "remove";
                  PFStatusArray[2].status = PFPan1Status;
                  this.setState(
                    {
                      PFPan1DocLink: onetime[2].PANCardApplicant1,
                    },
                    () => {
                      $("#viewDocLink_PFPan1").css("display", "block");
                      $("#choose_PFPan1").hide();
                      $("#X-btn_PFPan1").show();
                      $("#done-btn_PFPan1").hide();
                      $("#review-btn_PFPan1").hide();
                    }
                  );
                } else if (onetime[2].status == "review") {
                  mainactionPFPan1 = "upload";
                  PFPan1Status = "review";
                  PFStatusArray[2].status = PFPan1Status;
                  this.setState(
                    {
                      PFPan1DocLink: onetime[2].PANCardApplicant1,
                    },
                    () => {
                      $("#viewDocLink_PFPan1").css("display", "block");
                      $("#choose_PFPan1").hide();
                      $("#X-btn_PFPan1").hide();
                      $("#done-btn_PFPan1").hide();
                      $("#review-btn_PFPan1").show();
                    }
                  );
                } else if (onetime[2].status == "reupload") {
                  mainactionPFPan1 = "reupload";
                  PFPan1Status = "reupload";
                  PFStatusArray[2].status = PFPan1Status;
                  this.setState({}, () => {
                    $("#choose_PFPan1").show();
                    $("#viewDocLink_PFPan1").hide();
                    $("#X-btn_PFPan1").hide();
                    $("#done-btn_PFPan1").hide();
                    $("#review-btn_PFPan1").hide();
                  });
                } else if (onetime[2].status == "done") {
                  mainactionPFPan1 = "upload";
                  PFPan1Status = "done";
                  PFStatusArray[2].status = PFPan1Status;
                  this.setState(
                    {
                      PFPan1DocLink: onetime[2].PANCardApplicant1,
                    },
                    () => {
                      $("#choose_PFPan1").hide();
                      $("#viewDocLink_PFPan1").css("display", "block");
                      $("#X-btn_PFPan1").hide();
                      $("#done-btn_PFPan1").show();
                      $("#review-btn_PFPan1").hide();
                    }
                  );
                } else {
                  mainactionPFPan1 = "upload";
                  PFPan1Status = "open";
                  PFStatusArray[2].status = PFPan1Status;
                  $("#viewDocLink_PFPan1").hide();
                  $("#choose_PFPan1").show();
                  $("#X-btn_PFPan1").hide();
                  $("#done-btn_PFPan1").hide();
                  $("#review-btn_PFPan1").hide();
                }
                if (onetime[3].status == "remove") {
                  mainactionPFPan2 = "upload";
                  PFPan2Status = "remove";
                  PFStatusArray[3].status = PFPan2Status;
                  this.setState(
                    {
                      PFPan2DocLink: onetime[3].PANCardApplicant2,
                    },
                    () => {
                      $("#viewDocLink_PFPan2").css("display", "block");
                      $("#choose_PFPan2").hide();
                      $("#X-btn_PFPan2").show();
                      $("#done-btn_PFPan2").hide();
                      $("#review-btn_PFPan2").hide();
                    }
                  );
                } else if (onetime[3].status == "review") {
                  mainactionPFPan2 = "upload";
                  PFPan2Status = "review";
                  PFStatusArray[3].status = PFPan2Status;
                  this.setState(
                    {
                      PFPan2DocLink: onetime[3].PANCardApplicant2,
                    },
                    () => {
                      $("#viewDocLink_PFPan2").css("display", "block");
                      $("#choose_PFPan2").hide();
                      $("#X-btn_PFPan2").hide();
                      $("#done-btn_PFPan2").hide();
                      $("#review-btn_PFPan2").show();
                    }
                  );
                } else if (onetime[3].status == "reupload") {
                  mainactionPFPan2 = "reupload";
                  PFPan2Status = "reupload";
                  PFStatusArray[3].status = PFPan2Status;
                  this.setState({}, () => {
                    $("#choose_PFPan2").show();
                    $("#viewDocLink_PFPan2").hide();
                    $("#X-btn_PFPan2").hide();
                    $("#done-btn_PFPan2").hide();
                    $("#review-btn_PFPan2").hide();
                  });
                } else if (onetime[3].status == "done") {
                  mainactionPFPan2 = "upload";
                  PFPan2Status = "done";
                  PFStatusArray[3].status = PFPan2Status;
                  this.setState(
                    {
                      PFPan2DocLink: onetime[3].PANCardApplicant2,
                    },
                    () => {
                      $("#choose_PFPan2").hide();
                      $("#viewDocLink_PFPan2").css("display", "block");
                      $("#X-btn_PFPan2").hide();
                      $("#done-btn_PFPan2").show();
                      $("#review-btn_PFPan2").hide();
                    }
                  );
                } else {
                  mainactionPFPan2 = "upload";
                  PFPan2Status = "open";
                  PFStatusArray[3].status = PFPan2Status;
                  $("#viewDocLink_PFPan2").hide();
                  $("#choose_PFPan2").show();
                  $("#X-btn_PFPan2").hide();
                  $("#done-btn_PFPan2").hide();
                  $("#review-btn_PFPan2").hide();
                }
                if (onetime[4].status == "remove") {
                  mainactionPFDeed = "upload";
                  PFDeedStatus = "remove";
                  PFStatusArray[4].status = PFDeedStatus;
                  this.setState(
                    {
                      PFDeedDocLink: onetime[4].Deed,
                    },
                    () => {
                      $("#viewDocLink_PFDeed").css("display", "block");
                      $("#choose_PFDeed").hide();
                      $("#X-btn_PFDeed").show();
                      $("#done-btn_PFDeed").hide();
                      $("#review-btn_PFDeed").hide();
                    }
                  );
                } else if (onetime[4].status == "review") {
                  mainactionPFDeed = "upload";
                  PFDeedStatus = "review";
                  PFStatusArray[4].status = PFDeedStatus;
                  this.setState(
                    {
                      PFDeedDocLink: onetime[4].Deed,
                    },
                    () => {
                      $("#viewDocLink_PFDeed").css("display", "block");
                      $("#choose_PFDeed").hide();
                      $("#X-btn_PFDeed").hide();
                      $("#done-btn_PFDeed").hide();
                      $("#review-btn_PFDeed").show();
                    }
                  );
                } else if (onetime[4].status == "reupload") {
                  mainactionPFDeed = "reupload";
                  PFDeedStatus = "reupload";
                  PFStatusArray[4].status = PFDeedStatus;
                  this.setState({}, () => {
                    $("#choose_PFDeed").show();
                    $("#viewDocLink_PFDeed").hide();
                    $("#X-btn_PFDeed").hide();
                    $("#done-btn_PFDeed").hide();
                    $("#review-btn_PFDeed").hide();
                  });
                } else if (onetime[4].status == "done") {
                  mainactionPFDeed = "upload";
                  PFDeedStatus = "done";
                  PFStatusArray[4].status = PFDeedStatus;
                  this.setState(
                    {
                      PFDeedDocLink: onetime[4].Deed,
                    },
                    () => {
                      $("#choose_PFDeed").hide();
                      $("#viewDocLink_PFDeed").css("display", "block");
                      $("#X-btn_PFDeed").hide();
                      $("#done-btn_PFDeed").show();
                      $("#review-btn_PFDeed").hide();
                    }
                  );
                } else {
                  mainactionPFDeed = "upload";
                  PFDeedStatus = "open";
                  PFStatusArray[4].status = PFDeedStatus;
                  $("#viewDocLink_PFDeed").hide();
                  $("#choose_PFDeed").show();
                  $("#X-btn_PFDeed").hide();
                  $("#done-btn_PFDeed").hide();
                  $("#review-btn_PFDeed").hide();
                }
                if (onetime[5].status == "remove") {
                  mainactionPFBP = "upload";
                  PFBPStatus = "remove";
                  PFStatusArray[5].status = PFBPStatus;
                  this.setState(
                    {
                      PFBPDocLink: onetime[5].BankProof,
                    },
                    () => {
                      $("#viewDocLink_PFBP").css("display", "block");
                      $("#choose_PFBP").hide();
                      $("#X-btn_PFBP").show();
                      $("#done-btn_PFBP").hide();
                      $("#review-btn_PFBP").hide();
                    }
                  );
                } else if (onetime[5].status == "review") {
                  mainactionPFBP = "upload";
                  PFBPStatus = "review";
                  PFStatusArray[5].status = PFBPStatus;
                  this.setState(
                    {
                      PFBPDocLink: onetime[5].BankProof,
                    },
                    () => {
                      $("#viewDocLink_PFBP").css("display", "block");
                      $("#choose_PFBP").hide();
                      $("#X-btn_PFBP").hide();
                      $("#done-btn_PFBP").hide();
                      $("#review-btn_PFBP").show();
                    }
                  );
                } else if (onetime[5].status == "reupload") {
                  mainactionPFBP = "reupload";
                  PFBPStatus = "reupload";
                  PFStatusArray[5].status = PFBPStatus;
                  this.setState({}, () => {
                    $("#choose_PFBP").show();
                    $("#viewDocLink_PFBP").hide();
                    $("#X-btn_PFBP").hide();
                    $("#done-btn_PFBP").hide();
                    $("#review-btn_PFBP").hide();
                  });
                } else if (onetime[5].status == "done") {
                  mainactionPFBP = "upload";
                  PFBPStatus = "done";
                  PFStatusArray[5].status = PFBPStatus;
                  this.setState(
                    {
                      PFBPDocLink: onetime[5].BankProof,
                    },
                    () => {
                      $("#viewDocLink_PFBP").css("display", "block");
                      $("#choose_PFBP").hide();
                      $("#X-btn_PFBP").hide();
                      $("#done-btn_PFBP").show();
                      $("#review-btn_PFBP").hide();
                    }
                  );
                } else {
                  mainactionPFBP = "upload";
                  PFBPStatus = "open";
                  PFStatusArray[5].status = PFBPStatus;
                  $("#viewDocLink_PFBP").hide();
                  $("#choose_PFBP").show();
                  $("#X-btn_PFBP").hide();
                  $("#done-btn_PFBP").hide();
                  $("#review-btn_PFBP").hide();
                }
                if (onetime[6].status == "remove") {
                  mainactionPFRC = "upload";
                  PFRCStatus = "remove";
                  PFOptStatusArray[5].status = PFRCStatus;
                  this.setState(
                    {
                      PFRCDocLink: onetime[6].RegistrationCertificate,
                    },
                    () => {
                      $("#viewDocLink_PFRC").css("display", "block");
                      $("#choose_PFRC").hide();
                      $("#X-btn_PFRC").show();
                      $("#done-btn_PFRC").hide();
                      $("#review-btn_PFRC").hide();
                    }
                  );
                } else if (onetime[6].status == "review") {
                  mainactionPFRC = "upload";
                  PFRCStatus = "review";
                  PFOptStatusArray[5].status = PFRCStatus;
                  this.setState(
                    {
                      PFRCDocLink: onetime[6].RegistrationCertificate,
                    },
                    () => {
                      $("#viewDocLink_PFRC").css("display", "block");
                      $("#choose_PFRC").hide();
                      $("#X-btn_PFRC").hide();
                      $("#done-btn_PFRC").hide();
                      $("#review-btn_PFRC").show();
                    }
                  );
                } else if (onetime[6].status == "reupload") {
                  mainactionPFRC = "reupload";
                  PFRCStatus = "reupload";
                  PFOptStatusArray[5].status = PFRCStatus;
                  this.setState({}, () => {
                    $("#choose_PFRC").show();
                    $("#viewDocLink_PFRC").hide();
                    $("#X-btn_PFRC").hide();
                    $("#done-btn_PFRC").hide();
                    $("#review-btn_PFRC").hide();
                  });
                } else if (onetime[6].status == "done") {
                  mainactionPFRC = "upload";
                  PFRCStatus = "done";
                  PFOptStatusArray[5].status = PFRCStatus;
                  this.setState(
                    {
                      PFRCDocLink: onetime[6].RegistrationCertificate,
                    },
                    () => {
                      $("#choose_PFRC").hide();
                      $("#viewDocLink_PFRC").css("display", "block");
                      $("#X-btn_PFRC").hide();
                      $("#done-btn_PFRC").show();
                      $("#review-btn_PFRC").hide();
                    }
                  );
                } else {
                  mainactionPFRC = "upload";
                  PFRCStatus = "open";
                  PFOptStatusArray[5].status = PFRCStatus;
                  $("#viewDocLink_PFRC").hide();
                  $("#choose_PFRC").show();
                  $("#X-btn_PFRC").hide();
                  $("#done-btn_PFRC").hide();
                  $("#review-btn_PFRC").hide();
                }
                if (onetime[7].status == "remove") {
                  mainactionPFPBS_A = "upload";
                  PFPBS_AStatus = "remove";
                  PFOptStatusArray[0].status = PFPBS_AStatus;
                  this.setState(
                    {
                      PFPBS_ADocLink: onetime[7].PreviousBS_Audit,
                    },
                    () => {
                      $("#viewDocLink_PFPBS_A").css("display", "block");
                      $("#choose_PFPBS_A").hide();
                      $("#X-btn_PFPBS_A").show();
                      $("#done-btn_PFPBS_A").hide();
                      $("#review-btn_PFPBS_A").hide();
                    }
                  );
                } else if (onetime[7].status == "review") {
                  mainactionPFPBS_A = "upload";
                  PFPBS_AStatus = "review";
                  PFOptStatusArray[0].status = PFPBS_AStatus;
                  this.setState(
                    {
                      PFPBS_ADocLink: onetime[7].PreviousBS_Audit,
                    },
                    () => {
                      $("#viewDocLink_PFPBS_A").css("display", "block");
                      $("#choose_PFPBS_A").hide();
                      $("#X-btn_PFPBS_A").hide();
                      $("#done-btn_PFPBS_A").hide();
                      $("#review-btn_PFPBS_A").show();
                    }
                  );
                } else if (onetime[7].status == "reupload") {
                  mainactionPFPBS_A = "reupload";
                  PFPBS_AStatus = "reupload";
                  PFOptStatusArray[0].status = PFPBS_AStatus;
                  this.setState({}, () => {
                    $("#choose_PFPBS_A").show();
                    $("#viewDocLink_PFPBS_A").hide();
                    $("#X-btn_PFPBS_A").hide();
                    $("#done-btn_PFPBS_A").hide();
                    $("#review-btn_PFPBS_A").hide();
                  });
                } else if (onetime[7].status == "done") {
                  mainactionPFPBS_A = "upload";
                  PFPBS_AStatus = "done";
                  PFOptStatusArray[0].status = PFPBS_AStatus;
                  this.setState(
                    {
                      PFPBS_ADocLink: onetime[7].PreviousBS_Audit,
                    },
                    () => {
                      $("#choose_PFPBS_A").hide();
                      $("#viewDocLink_PFPBS_A").css("display", "block");
                      $("#X-btn_PFPBS_A").hide();
                      $("#done-btn_PFPBS_A").show();
                      $("#review-btn_PFPBS_A").hide();
                    }
                  );
                } else {
                  mainactionPFPBS_A = "upload";
                  PFPBS_AStatus = "open";
                  PFOptStatusArray[0].status = PFPBS_AStatus;
                  $("#viewDocLink_PFPBS_A").hide();
                  $("#choose_PFPBS_A").show();
                  $("#X-btn_PFPBS_A").hide();
                  $("#done-btn_PFPBS_A").hide();
                  $("#review-btn_PFPBS_A").hide();
                }
                if (onetime[8].status == "remove") {
                  mainactionPFPR = "upload";
                  PFPRStatus = "remove";
                  PFOptStatusArray[1].status = PFPRStatus;
                  this.setState(
                    {
                      PFPRDocLink: onetime[8].PreviousReturns,
                    },
                    () => {
                      $("#viewDocLink_PFPR").css("display", "block");
                      $("#choose_PFPR").hide();
                      $("#X-btn_PFPR").show();
                      $("#done-btn_PFPR").hide();
                      $("#review-btn_PFPR").hide();
                    }
                  );
                } else if (onetime[8].status == "review") {
                  mainactionPFPR = "upload";
                  PFPRStatus = "review";
                  PFOptStatusArray[1].status = PFPRStatus;
                  this.setState(
                    {
                      PFPRDocLink: onetime[8].PreviousReturns,
                    },
                    () => {
                      $("#viewDocLink_PFPR").css("display", "block");
                      $("#choose_PFPR").hide();
                      $("#X-btn_PFPR").hide();
                      $("#done-btn_PFPR").hide();
                      $("#review-btn_PFPR").show();
                    }
                  );
                } else if (onetime[8].status == "reupload") {
                  mainactionPFPR = "reupload";
                  PFPRStatus = "reupload";
                  PFOptStatusArray[1].status = PFPRStatus;
                  this.setState({}, () => {
                    $("#choose_PFPR").show();
                    $("#viewDocLink_PFPR").hide();
                    $("#X-btn_PFPR").hide();
                    $("#done-btn_PFPR").hide();
                    $("#review-btn_PFPR").hide();
                  });
                } else if (onetime[8].status == "done") {
                  mainactionPFPR = "upload";
                  PFPRStatus = "done";
                  PFOptStatusArray[1].status = PFPRStatus;
                  this.setState(
                    {
                      PFPRDocLink: onetime[8].PreviousReturns,
                    },
                    () => {
                      $("#choose_PFPR").hide();
                      $("#viewDocLink_PFPR").css("display", "block");
                      $("#X-btn_PFPR").hide();
                      $("#done-btn_PFPR").show();
                      $("#review-btn_PFPR").hide();
                    }
                  );
                } else {
                  mainactionPFPR = "upload";
                  PFPRStatus = "open";
                  PFOptStatusArray[1].status = PFPRStatus;
                  $("#viewDocLink_PFPR").hide();
                  $("#choose_PFPR").show();
                  $("#X-btn_PFPR").hide();
                  $("#done-btn_PFPR").hide();
                  $("#review-btn_PFPR").hide();
                }
              }
            } else {
              $("#viewDocLink_PFAadhar1").hide();
              $("#choose_PFAadhar1").show();
              $("#X-btn_PFAadhar1").hide();
              $("#done-btn_PFAadhar1").hide();
              $("#review-btn_PFAadhar1").hide();

              $("#viewDocLink_PFAadhar2").hide();
              $("#choose_PFAadhar2").show();
              $("#X-btn_PFAadhar2").hide();
              $("#done-btn_PFAadhar2").hide();
              $("#review-btn_PFAadhar2").hide();

              $("#viewDocLink_PFPan1").hide();
              $("#choose_PFPan1").show();
              $("#X-btn_PFPan1").hide();
              $("#done-btn_PFPan1").hide();
              $("#review-btn_PFPan1").hide();

              $("#viewDocLink_PFPan2").hide();
              $("#choose_PFPan2").show();
              $("#X-btn_PFPan2").hide();
              $("#done-btn_PFPan2").hide();
              $("#review-btn_PFPan2").hide();

              $("#viewDocLink_PFDeed").hide();
              $("#choose_PFDeed").show();
              $("#X-btn_PFDeed").hide();
              $("#done-btn_PFDeed").hide();
              $("#review-btn_PFDeed").hide();

              $("#viewDocLink_PFBP").hide();
              $("#choose_PFBP").show();
              $("#X-btn_PFBP").hide();
              $("#done-btn_PFBP").hide();
              $("#review-btn_PFBP").hide();

              $("#viewDocLink_PFRC").hide();
              $("#choose_PFRC").show();
              $("#X-btn_PFRC").hide();
              $("#done-btn_PFRC").hide();
              $("#review-btn_PFRC").hide();

              $("#viewDocLink_PFPBS_A").hide();
              $("#choose_PFPBS_A").show();
              $("#X-btn_PFPBS_A").hide();
              $("#done-btn_PFPBS_A").hide();
              $("#review-btn_PFPBS_A").hide();

              $("#viewDocLink_PFPR").hide();
              $("#choose_PFPR").show();
              $("#X-btn_PFPR").hide();
              $("#done-btn_PFPR").hide();
              $("#review-btn_PFPR").hide();
            }
            if (
              res.data.fyStatus == "lock" ||
              res.data.fyStatus == "complete"
            ) {
              $(".proceednowbtn").hide();
              if (res.data.incD[0].documents.length == 0) {
                $("#PFincDadditionalDocumentDiv").hide();
              } else {
                $("#PFincDadddocumentbtn").hide();
              }
              if (res.data.addDocInformation.length == 0) {
                $("#PFadditionalDocumentDiv").hide();
              } else {
                $("#PFadddocumentbtn").hide();
              }
            }
            if (res.data.incD[0].documents.length > 0) {
              $("#PFincDadditionalDocumentDiv").show();
              $("#PFincDadddocumentbtn").hide();
              this.setState(
                {
                  PFincDAdditionalFiles: [...res.data.incD[0].documents],
                  PFincDAdd_DocName: "File Name",
                  api_PFincDAdditionalFiles: "Yes",
                },
                () => {}
              );
            } else if (
              res.data.incD[0].documents.length == 0 &&
              res.data.fyStatus != "lock" &&
              res.data.fyStatus != "complete"
            ) {
              $("#PFincDadditionalDocumentDiv").show();
              this.setState({
                PFincDAdd_DocName: "",
                PFincDAdditionalFiles: [1],
                api_PFincDAdditionalFiles: "No",
              });
            }
            if (res.data.addDocInformation.length > 0) {
              $("#PFadditionalDocumentDiv").show();
              $("#PFadddocumentbtn").hide();
              this.setState(
                {
                  PFAdditionalFiles: [...res.data.addDocInformation],
                  PFAdd_DocName: "File Name",
                  api_PFAdditionalFiles: "Yes",
                },
                () => {}
              );
            } else if (
              res.data.addDocInformation.length == 0 &&
              res.data.fyStatus != "lock" &&
              res.data.fyStatus != "complete"
            ) {
              $("#PFadditionalDocumentDiv").show();
              $("#PFadddocumentbtn").show();
              this.setState({
                PFAdd_DocName: "",
                PFAdditionalFiles: [1],
                api_PFAdditionalFiles: "No",
              });
            }
            if (res.data.documents.length > 0) {
              let doc = res.data.documents;
              if (doc[0].status == "remove") {
                mainactionPFData = "upload";
                PFDataStatus = "remove";
                PFStatusArray[6].status = PFDataStatus;
                this.setState(
                  {
                    PFDataDocLink: doc[0].Data,
                  },
                  () => {
                    $("#viewDocLink_PFData").css("display", "block");
                    $("#choose_PFData").hide();
                    $("#X-btn_PFData").show();
                    $("#done-btn_PFData").hide();
                    $("#review-btn_PFData").hide();
                  }
                );
              } else if (doc[0].status == "review") {
                mainactionPFData = "upload";
                PFDataStatus = "review";
                PFStatusArray[6].status = PFDataStatus;
                this.setState(
                  {
                    PFDataDocLink: doc[0].Data,
                  },
                  () => {
                    $("#viewDocLink_PFData").css("display", "block");
                    $("#choose_PFData").hide();
                    $("#X-btn_PFData").hide();
                    $("#done-btn_PFData").hide();
                    $("#review-btn_PFData").show();
                  }
                );
              } else if (doc[0].status == "reupload") {
                mainactionPFData = "reupload";
                PFDataStatus = "reupload";
                PFStatusArray[6].status = PFDataStatus;
                this.setState({}, () => {
                  $("#choose_PFData").show();
                  $("#viewDocLink_PFData").hide();
                  $("#X-btn_PFData").hide();
                  $("#done-btn_PFData").hide();
                  $("#review-btn_PFData").hide();
                });
              } else if (doc[0].status == "done") {
                mainactionPFData = "upload";
                PFDataStatus = "done";
                PFStatusArray[6].status = PFDataStatus;
                this.setState(
                  {
                    PFDataDocLink: doc[0].Data,
                  },
                  () => {
                    $("#choose_PFData").hide();
                    $("#viewDocLink_PFData").css("display", "block");
                    $("#X-btn_PFData").hide();
                    $("#done-btn_PFData").show();
                    $("#review-btn_PFData").hide();
                  }
                );
              } else {
                mainactionPFData = "upload";
                PFDataStatus = "open";
                PFStatusArray[6].status = PFDataStatus;
                $("#viewDocLink_PFData").hide();
                $("#choose_PFData").show();
                $("#X-btn_PFData").hide();
                $("#done-btn_PFData").hide();
                $("#review-btn_PFData").hide();
              }

              if (doc[1].status == "remove") {
                mainactionPFGSTReturn = "upload";
                PFGSTReturnStatus = "remove";
                PFOptStatusArray[2].status = PFGSTReturnStatus;
                this.setState(
                  {
                    PFGSTReturnDocLink: doc[1].GSTReturn,
                  },
                  () => {
                    $("#viewDocLink_PFGSTReturn").css("display", "block");
                    $("#choose_PFGSTReturn").hide();
                    $("#X-btn_PFGSTReturn").show();
                    $("#done-btn_PFGSTReturn").hide();
                    $("#review-btn_PFGSTReturn").hide();
                  }
                );
              } else if (doc[1].status == "review") {
                mainactionPFGSTReturn = "upload";
                PFGSTReturnStatus = "review";
                PFOptStatusArray[2].status = PFGSTReturnStatus;
                this.setState(
                  {
                    PFGSTReturnDocLink: doc[1].GSTReturn,
                  },
                  () => {
                    $("#viewDocLink_PFGSTReturn").css("display", "block");
                    $("#choose_PFGSTReturn").hide();
                    $("#X-btn_PFGSTReturn").hide();
                    $("#done-btn_PFGSTReturn").hide();
                    $("#review-btn_PFGSTReturn").show();
                  }
                );
              } else if (doc[1].status == "reupload") {
                mainactionPFGSTReturn = "reupload";
                PFGSTReturnStatus = "reupload";
                PFOptStatusArray[2].status = PFGSTReturnStatus;
                this.setState({}, () => {
                  $("#choose_PFGSTReturn").show();
                  $("#viewDocLink_PFGSTReturn").hide();
                  $("#X-btn_PFGSTReturn").hide();
                  $("#done-btn_PFGSTReturn").hide();
                  $("#review-btn_PFGSTReturn").hide();
                });
              } else if (doc[1].status == "done") {
                mainactionPFGSTReturn = "upload";
                PFGSTReturnStatus = "done";
                PFOptStatusArray[2].status = PFGSTReturnStatus;
                this.setState(
                  {
                    PFGSTReturnDocLink: doc[1].GSTReturn,
                  },
                  () => {
                    $("#viewDocLink_PFGSTReturn").css("display", "block");
                    $("#choose_PFGSTReturn").hide();
                    $("#X-btn_PFGSTReturn").hide();
                    $("#done-btn_PFGSTReturn").show();
                    $("#review-btn_PFGSTReturn").hide();
                  }
                );
              } else {
                mainactionPFGSTReturn = "upload";
                PFGSTReturnStatus = "open";
                PFOptStatusArray[2].status = PFGSTReturnStatus;
                $("#viewDocLink_PFGSTReturn").hide();
                $("#choose_PFGSTReturn").show();
                $("#X-btn_PFGSTReturn").hide();
                $("#done-btn_PFGSTReturn").hide();
                $("#review-btn_PFGSTReturn").hide();
              }

              if (doc[2].status == "remove") {
                mainactionPFTDSReturn = "upload";
                PFTDSReturnStatus = "remove";
                PFOptStatusArray[3].status = PFTDSReturnStatus;
                this.setState(
                  {
                    PFTDSReturnDocLink: doc[2].TDSReturn,
                  },
                  () => {
                    $("#viewDocLink_PFTDSReturn").css("display", "block");
                    $("#choose_PFTDSReturn").hide();
                    $("#X-btn_PFTDSReturn").show();
                    $("#done-btn_PFTDSReturn").hide();
                    $("#review-btn_PFTDSReturn").hide();
                  }
                );
              } else if (doc[2].status == "review") {
                mainactionPFTDSReturn = "upload";
                PFTDSReturnStatus = "review";
                PFOptStatusArray[3].status = PFTDSReturnStatus;
                this.setState(
                  {
                    PFTDSReturnDocLink: doc[2].TDSReturn,
                  },
                  () => {
                    $("#viewDocLink_PFTDSReturn").css("display", "block");
                    $("#choose_PFTDSReturn").hide();
                    $("#X-btn_PFTDSReturn").hide();
                    $("#done-btn_PFTDSReturn").hide();
                    $("#review-btn_PFTDSReturn").show();
                  }
                );
              } else if (doc[2].status == "reupload") {
                mainactionPFTDSReturn = "reupload";
                PFTDSReturnStatus = "reupload";
                PFOptStatusArray[3].status = PFTDSReturnStatus;
                this.setState({}, () => {
                  $("#choose_PFTDSReturn").show();
                  $("#viewDocLink_PFTDSReturn").hide();
                  $("#X-btn_PFTDSReturn").hide();
                  $("#done-btn_PFTDSReturn").hide();
                  $("#review-btn_PFTDSReturn").hide();
                });
              } else if (doc[2].status == "done") {
                mainactionPFTDSReturn = "upload";
                PFTDSReturnStatus = "done";
                PFOptStatusArray[3].status = PFTDSReturnStatus;
                this.setState(
                  {
                    PFTDSReturnDocLink: doc[2].TDSReturn,
                  },
                  () => {
                    $("#choose_PFTDSReturn").hide();
                    $("#viewDocLink_PFTDSReturn").css("display", "block");
                    $("#X-btn_PFTDSReturn").hide();
                    $("#done-btn_PFTDSReturn").show();
                    $("#review-btn_PFTDSReturn").hide();
                  }
                );
              } else {
                mainactionPFTDSReturn = "upload";
                PFTDSReturnStatus = "open";
                PFOptStatusArray[3].status = PFTDSReturnStatus;
                $("#viewDocLink_PFTDSReturn").hide();
                $("#choose_PFTDSReturn").show();
                $("#X-btn_PFTDSReturn").hide();
                $("#done-btn_PFTDSReturn").hide();
                $("#review-btn_PFTDSReturn").hide();
              }

              if (doc[3].status == "remove") {
                mainactionPFChallan = "upload";
                PFChallanStatus = "remove";
                PFOptStatusArray[4].status = PFChallanStatus;
                this.setState(
                  {
                    PFChallanDocLink: doc[3].Challan,
                  },
                  () => {
                    $("#viewDocLink_PFChallan").css("display", "block");
                    $("#choose_PFChallan").hide();
                    $("#X-btn_PFChallan").show();
                    $("#done-btn_PFChallan").hide();
                    $("#review-btn_PFChallan").hide();
                  }
                );
              } else if (doc[3].status == "review") {
                mainactionPFChallan = "upload";
                PFChallanStatus = "review";
                PFOptStatusArray[4].status = PFChallanStatus;
                this.setState(
                  {
                    PFChallanDocLink: doc[3].Challan,
                  },
                  () => {
                    $("#viewDocLink_PFChallan").css("display", "block");
                    $("#choose_PFChallan").hide();
                    $("#X-btn_PFChallan").hide();
                    $("#done-btn_PFChallan").hide();
                    $("#review-btn_PFChallan").show();
                  }
                );
              } else if (doc[3].status == "reupload") {
                mainactionPFChallan = "reupload";
                PFChallanStatus = "reupload";
                PFOptStatusArray[4].status = PFChallanStatus;
                this.setState({}, () => {
                  $("#choose_PFChallan").show();
                  $("#viewDocLink_PFChallan").hide();
                  $("#X-btn_PFChallan").hide();
                  $("#done-btn_PFChallan").hide();
                  $("#review-btn_PFChallan").hide();
                });
              } else if (doc[3].status == "done") {
                mainactionPFChallan = "upload";
                PFChallanStatus = "done";
                PFOptStatusArray[4].status = PFChallanStatus;
                this.setState(
                  {
                    PFChallanDocLink: doc[3].Challan,
                  },
                  () => {
                    $("#choose_PFChallan").hide();
                    $("#viewDocLink_PFChallan").css("display", "block");
                    $("#X-btn_PFChallan").hide();
                    $("#done-btn_PFChallan").show();
                    $("#review-btn_PFChallan").hide();
                  }
                );
              } else {
                mainactionPFChallan = "upload";
                PFChallanStatus = "open";
                PFOptStatusArray[4].status = PFChallanStatus;
                $("#viewDocLink_PFChallan").hide();
                $("#choose_PFChallan").show();
                $("#X-btn_PFChallan").hide();
                $("#done-btn_PFChallan").hide();
                $("#review-btn_PFChallan").hide();
              }
              if (doc[4].status == "remove") {
                mainactionPFBankS = "upload";
                PFBankSStatus = "remove";
                PFStatusArray[7].status = PFBankSStatus;
                this.setState(
                  {
                    PFBankSDocLink: doc[4].BankStatement,
                  },
                  () => {
                    $("#viewDocLink_PFBankS").css("display", "block");
                    $("#choose_PFBankS").hide();
                    $("#X-btn_PFBankS").show();
                    $("#done-btn_PFBankS").hide();
                    $("#review-btn_PFBankS").hide();
                  }
                );
              } else if (doc[4].status == "review") {
                mainactionPFBankS = "upload";
                PFBankSStatus = "review";
                PFStatusArray[7].status = PFBankSStatus;
                this.setState(
                  {
                    PFBankSDocLink: doc[4].BankStatement,
                  },
                  () => {
                    $("#viewDocLink_PFBankS").css("display", "block");
                    $("#choose_PFBankS").hide();
                    $("#X-btn_PFBankS").hide();
                    $("#done-btn_PFBankS").hide();
                    $("#review-btn_PFBankS").show();
                  }
                );
              } else if (doc[4].status == "reupload") {
                mainactionPFBankS = "reupload";
                PFBankSStatus = "reupload";
                PFStatusArray[7].status = PFBankSStatus;
                this.setState({}, () => {
                  $("#choose_PFBankS").show();
                  $("#viewDocLink_PFBankS").hide();
                  $("#X-btn_PFBankS").hide();
                  $("#done-btn_PFBankS").hide();
                  $("#review-btn_PFBankS").hide();
                });
              } else if (doc[4].status == "done") {
                mainactionPFBankS = "upload";
                PFBankSStatus = "done";
                PFStatusArray[7].status = PFBankSStatus;
                this.setState(
                  {
                    PFBankSDocLink: doc[4].BankStatement,
                  },
                  () => {
                    $("#choose_PFBankS").hide();
                    $("#viewDocLink_PFBankS").css("display", "block");
                    $("#X-btn_PFBankS").hide();
                    $("#done-btn_PFBankS").show();
                    $("#review-btn_PFBankS").hide();
                  }
                );
              } else {
                mainactionPFBankS = "upload";
                PFBankSStatus = "open";
                PFStatusArray[7].status = PFBankSStatus;
                $("#viewDocLink_PFBankS").hide();
                $("#choose_PFBankS").show();
                $("#X-btn_PFBankS").hide();
                $("#done-btn_PFBankS").hide();
                $("#review-btn_PFBankS").hide();
              }
            } else {
              $("#viewDocLink_PFData").hide();
              $("#choose_PFData").show();
              $("#X-btn_PFData").hide();
              $("#done-btn_PFData").hide();
              $("#review-btn_PFData").hide();

              $("#viewDocLink_PFBankS").hide();
              $("#choose_PFBankS").show();
              $("#X-btn_PFBankS").hide();
              $("#done-btn_PFBankS").hide();
              $("#review-btn_PFBankS").hide();

              $("#viewDocLink_PFGSTReturn").hide();
              $("#choose_PFGSTReturn").show();
              $("#X-btn_PFGSTReturn").hide();
              $("#done-btn_PFGSTReturn").hide();
              $("#review-btn_PFGSTReturn").hide();

              $("#viewDocLink_PFTDSReturn").hide();
              $("#choose_PFTDSReturn").show();
              $("#X-btn_PFTDSReturn").hide();
              $("#done-btn_PFTDSReturn").hide();
              $("#review-btn_PFTDSReturn").hide();

              $("#viewDocLink_PFChallan").hide();
              $("#choose_PFChallan").show();
              $("#X-btn_PFChallan").hide();
              $("#done-btn_PFChallan").hide();
              $("#review-btn_PFChallan").hide();
            }
            this.PFcheckRemainingReuploads();
            let statusCheck = this.statusCheckFunction(
              "reupload",
              PFStatusArray
            );
            PFincDadditionalStatusArrayCheck = this.statusCheckFunction(
              "reupload",
              this.state.PFincDAdditionalFiles
            );
            PFadditionalStatusArrayCheck = this.statusCheckFunction(
              "reupload",
              this.state.PFAdditionalFiles
            );

            if (
              AnyReuploadOccuredPF == "Yes" &&
              statusCheck == undefined &&
              PFincDadditionalStatusArrayCheck == undefined &&
              PFadditionalStatusArrayCheck == undefined
            ) {
              PFOptReview = "Yes";
              this.countOptReuploadPF("reupload", PFOptStatusArray);
            } else {
              PFOptReview = "No";
            }
            if (
              res.data.rejectstatus == "false" &&
              res.data.mainstatus == "true"
            ) {
              rejectstatus = "Yes";
            }
            // this.PFcheckRemainingReuploads();
            // this.checkhandlePFProceedBtn();
          } else if (res.data.serviceType == "ITRF_BPC_OneTime_Documents") {
            $(".selectcateselect1").attr("disabled", true);
            this.setState(
              {
                dropDownValue: "ITRF_BPC_OneTime_Documents",
                serviceType: "ITRF_BPC_OneTime_Documents",
              },
              () => {
                if (this.state.dropDownValue == "ITRF_BPC_OneTime_Documents") {
                  $("#Itrf_default_Img").hide();
                  $("#ItrfSalariedSection").hide();
                  $("#ItrfproprietorSection").hide();
                  $("#ItrfPartnershipFirm").hide();
                  $("#ItrfCompany_LLP").show();
                }
              }
            );
            if (res.data.oneTime.length > 0) {
              if (res.data.oneTime[0].documents.length > 0) {
                let onetime = res.data.oneTime[0].documents;
                if (onetime[0].status == "remove") {
                  mainactionCLAadhar1 = "upload";
                  CLAadhar1Status = "remove";
                  CLStatusArray[0].status = CLAadhar1Status;
                  this.setState(
                    {
                      CLAadhar1DocLink: onetime[0].AadhaarCardApplicant1,
                    },
                    () => {
                      $("#viewDocLink_CLAadhar1").css("display", "block");
                      $("#choose_CLAadhar1").hide();
                      $("#X-btn_CLAadhar1").show();
                      $("#done-btn_CLAadhar1").hide();
                      $("#review-btn_CLAadhar1").hide();
                    }
                  );
                } else if (onetime[0].status == "review") {
                  mainactionCLAadhar1 = "upload";
                  CLAadhar1Status = "review";
                  CLStatusArray[0].status = CLAadhar1Status;
                  this.setState(
                    {
                      CLAadhar1DocLink: onetime[0].AadhaarCardApplicant1,
                    },
                    () => {
                      $("#viewDocLink_CLAadhar1").css("display", "block");
                      $("#choose_CLAadhar1").hide();
                      $("#X-btn_CLAadhar1").hide();
                      $("#done-btn_CLAadhar1").hide();
                      $("#review-btn_CLAadhar1").show();
                    }
                  );
                } else if (onetime[0].status == "reupload") {
                  mainactionCLAadhar1 = "reupload";
                  CLAadhar1Status = "reupload";
                  CLStatusArray[0].status = CLAadhar1Status;
                  this.setState({}, () => {
                    $("#choose_CLAadhar1").show();
                    $("#viewDocLink_CLAadhar1").hide();
                    $("#X-btn_CLAadhar1").hide();
                    $("#done-btn_CLAadhar1").hide();
                    $("#review-btn_CLAadhar1").hide();
                  });
                } else if (onetime[0].status == "done") {
                  CLAadhar1Status = "done";
                  CLStatusArray[0].status = CLAadhar1Status;
                  mainactionCLAadhar1 = "upload";
                  this.setState(
                    {
                      CLAadhar1DocLink: onetime[0].AadhaarCardApplicant1,
                    },
                    () => {
                      $("#choose_CLAadhar1").hide();
                      $("#viewDocLink_CLAadhar1").css("display", "block");
                      $("#X-btn_CLAadhar1").hide();
                      $("#done-btn_CLAadhar1").show();
                      $("#review-btn_CLAadhar1").hide();
                    }
                  );
                } else {
                  mainactionCLAadhar1 = "upload";
                  CLAadhar1Status = "open";
                  CLStatusArray[0].status = CLAadhar1Status;
                  $("#viewDocLink_CLAadhar1").hide();
                  $("#choose_CLAadhar1").show();
                  $("#X-btn_CLAadhar1").hide();
                  $("#done-btn_CLAadhar1").hide();
                  $("#review-btn_CLAadhar1").hide();
                }
                if (onetime[1].status == "remove") {
                  mainactionCLAadhar2 = "upload";
                  CLAadhar2Status = "remove";
                  CLStatusArray[1].status = CLAadhar2Status;
                  this.setState(
                    {
                      CLAadhar2DocLink: onetime[1].AadhaarCardApplicant2,
                    },
                    () => {
                      $("#viewDocLink_CLAadhar2").css("display", "block");
                      $("#choose_CLAadhar2").hide();
                      $("#X-btn_CLAadhar2").show();
                      $("#done-btn_CLAadhar2").hide();
                      $("#review-btn_CLAadhar2").hide();
                    }
                  );
                } else if (onetime[1].status == "review") {
                  mainactionCLAadhar2 = "upload";
                  CLAadhar2Status = "review";
                  CLStatusArray[1].status = CLAadhar2Status;
                  this.setState(
                    {
                      CLAadhar2DocLink: onetime[1].AadhaarCardApplicant2,
                    },
                    () => {
                      $("#viewDocLink_CLAadhar2").css("display", "block");
                      $("#choose_CLAadhar2").hide();
                      $("#X-btn_CLAadhar2").hide();
                      $("#done-btn_CLAadhar2").hide();
                      $("#review-btn_CLAadhar2").show();
                    }
                  );
                } else if (onetime[1].status == "reupload") {
                  mainactionCLAadhar2 = "reupload";
                  CLAadhar2Status = "reupload";
                  CLStatusArray[1].status = CLAadhar2Status;
                  this.setState({}, () => {
                    $("#choose_CLAadhar2").show();
                    $("#viewDocLink_CLAadhar2").hide();
                    $("#X-btn_CLAadhar2").hide();
                    $("#done-btn_CLAadhar2").hide();
                    $("#review-btn_CLAadhar2").hide();
                  });
                } else if (onetime[1].status == "done") {
                  CLAadhar2Status = "done";
                  CLStatusArray[1].status = CLAadhar2Status;
                  mainactionCLAadhar2 = "upload";
                  this.setState(
                    {
                      CLAadhar2DocLink: onetime[1].AadhaarCardApplicant2,
                    },
                    () => {
                      $("#viewDocLink_CLAadhar2").css("display", "block");
                      $("#choose_CLAadhar2").hide();
                      $("#X-btn_CLAadhar2").hide();
                      $("#done-btn_CLAadhar2").show();
                      $("#review-btn_CLAadhar2").hide();
                    }
                  );
                } else {
                  mainactionCLAadhar2 = "upload";
                  CLAadhar2Status = "open";
                  CLStatusArray[1].status = CLAadhar2Status;
                  $("#viewDocLink_CLAadhar2").hide();
                  $("#choose_CLAadhar2").show();
                  $("#X-btn_CLAadhar2").hide();
                  $("#done-btn_CLAadhar2").hide();
                  $("#review-btn_CLAadhar2").hide();
                }
                if (onetime[2].status == "remove") {
                  mainactionCLPan1 = "upload";
                  CLPan1Status = "remove";
                  CLStatusArray[2].status = CLPan1Status;
                  this.setState(
                    {
                      CLPan1DocLink: onetime[2].PANCardApplicant1,
                    },
                    () => {
                      $("#viewDocLink_CLPan1").css("display", "block");
                      $("#choose_CLPan1").hide();
                      $("#X-btn_CLPan1").show();
                      $("#done-btn_CLPan1").hide();
                      $("#review-btn_CLPan1").hide();
                    }
                  );
                } else if (onetime[2].status == "review") {
                  mainactionCLPan1 = "upload";
                  CLPan1Status = "review";
                  CLStatusArray[2].status = CLPan1Status;
                  this.setState(
                    {
                      CLPan1DocLink: onetime[2].PANCardApplicant1,
                    },
                    () => {
                      $("#viewDocLink_CLPan1").css("display", "block");
                      $("#choose_CLPan1").hide();
                      $("#X-btn_CLPan1").hide();
                      $("#done-btn_CLPan1").hide();
                      $("#review-btn_CLPan1").show();
                    }
                  );
                } else if (onetime[2].status == "reupload") {
                  mainactionCLPan1 = "reupload";
                  CLPan1Status = "reupload";
                  CLStatusArray[2].status = CLPan1Status;
                  this.setState({}, () => {
                    $("#choose_CLPan1").show();
                    $("#viewDocLink_CLPan1").hide();
                    $("#X-btn_CLPan1").hide();
                    $("#done-btn_CLPan1").hide();
                    $("#review-btn_CLPan1").hide();
                  });
                } else if (onetime[2].status == "done") {
                  CLPan1Status = "done";
                  CLStatusArray[2].status = CLPan1Status;
                  mainactionCLPan1 = "upload";
                  this.setState(
                    {
                      CLPan1DocLink: onetime[2].PANCardApplicant1,
                    },
                    () => {
                      $("#choose_CLPan1").hide();
                      $("#viewDocLink_CLPan1").css("display", "block");
                      $("#X-btn_CLPan1").hide();
                      $("#done-btn_CLPan1").show();
                      $("#review-btn_CLPan1").hide();
                    }
                  );
                } else {
                  mainactionCLPan1 = "upload";
                  CLPan1Status = "open";
                  CLStatusArray[2].status = CLPan1Status;
                  $("#viewDocLink_CLPan1").hide();
                  $("#choose_CLPan1").show();
                  $("#X-btn_CLPan1").hide();
                  $("#done-btn_CLPan1").hide();
                  $("#review-btn_CLPan1").hide();
                }
                if (onetime[3].status == "remove") {
                  mainactionCLPan2 = "upload";
                  CLPan2Status = "remove";
                  CLStatusArray[3].status = CLPan2Status;
                  this.setState(
                    {
                      CLPan2DocLink: onetime[3].PANCardApplicant2,
                    },
                    () => {
                      $("#viewDocLink_CLPan2").css("display", "block");
                      $("#choose_CLPan2").hide();
                      $("#X-btn_CLPan2").show();
                      $("#done-btn_CLPan2").hide();
                      $("#review-btn_CLPan2").hide();
                    }
                  );
                } else if (onetime[3].status == "review") {
                  mainactionCLPan2 = "upload";
                  CLPan2Status = "review";
                  CLStatusArray[3].status = CLPan2Status;
                  this.setState(
                    {
                      CLPan2DocLink: onetime[3].PANCardApplicant2,
                    },
                    () => {
                      $("#viewDocLink_CLPan2").css("display", "block");
                      $("#choose_CLPan2").hide();
                      $("#X-btn_CLPan2").hide();
                      $("#done-btn_CLPan2").hide();
                      $("#review-btn_CLPan2").show();
                    }
                  );
                } else if (onetime[3].status == "reupload") {
                  mainactionCLPan2 = "reupload";
                  CLPan2Status = "reupload";
                  CLStatusArray[3].status = CLPan2Status;
                  this.setState({}, () => {
                    $("#choose_CLPan2").show();
                    $("#viewDocLink_CLPan2").hide();
                    $("#X-btn_CLPan2").hide();
                    $("#done-btn_CLPan2").hide();
                    $("#review-btn_CLPan2").hide();
                  });
                } else if (onetime[3].status == "done") {
                  CLPan2Status = "done";
                  CLStatusArray[3].status = CLPan2Status;
                  mainactionCLPan2 = "upload";
                  this.setState(
                    {
                      CLPan2DocLink: onetime[3].PANCardApplicant2,
                    },
                    () => {
                      $("#choose_CLPan2").hide();
                      $("#viewDocLink_CLPan2").css("display", "block");
                      $("#X-btn_CLPan2").hide();
                      $("#done-btn_CLPan2").show();
                      $("#review-btn_CLPan2").hide();
                    }
                  );
                } else {
                  mainactionCLPan2 = "upload";
                  CLPan2Status = "open";
                  CLStatusArray[3].status = CLPan2Status;
                  $("#viewDocLink_CLPan2").hide();
                  $("#choose_CLPan2").show();
                  $("#X-btn_CLPan2").hide();
                  $("#done-btn_CLPan2").hide();
                  $("#review-btn_CLPan2").hide();
                }
                if (onetime[4].status == "remove") {
                  mainactionCLIC = "upload";
                  CLICStatus = "remove";
                  CLStatusArray[4].status = CLICStatus;
                  this.setState(
                    {
                      CLICDocLink: onetime[4].IncorporationCertificate,
                    },
                    () => {
                      $("#viewDocLink_CLIC").css("display", "block");
                      $("#choose_CLIC").hide();
                      $("#X-btn_CLIC").show();
                      $("#done-btn_CLIC").hide();
                      $("#review-btn_CLIC").hide();
                    }
                  );
                } else if (onetime[4].status == "review") {
                  mainactionCLIC = "upload";
                  CLICStatus = "review";
                  CLStatusArray[4].status = CLICStatus;
                  this.setState(
                    {
                      CLICDocLink: onetime[4].IncorporationCertificate,
                    },
                    () => {
                      $("#viewDocLink_CLIC").css("display", "block");
                      $("#choose_CLIC").hide();
                      $("#X-btn_CLIC").hide();
                      $("#done-btn_CLIC").hide();
                      $("#review-btn_CLIC").show();
                    }
                  );
                } else if (onetime[4].status == "reupload") {
                  mainactionCLIC = "reupload";
                  CLICStatus = "reupload";
                  CLStatusArray[4].status = CLICStatus;
                  this.setState({}, () => {
                    $("#choose_CLIC").show();
                    $("#viewDocLink_CLIC").hide();
                    $("#X-btn_CLIC").hide();
                    $("#done-btn_CLIC").hide();
                    $("#review-btn_CLIC").hide();
                  });
                } else if (onetime[4].status == "done") {
                  CLICStatus = "done";
                  CLStatusArray[4].status = CLICStatus;
                  mainactionCLIC = "upload";
                  this.setState(
                    {
                      CLICDocLink: onetime[4].IncorporationCertificate,
                    },
                    () => {
                      $("#choose_CLIC").hide();
                      $("#viewDocLink_CLIC").css("display", "block");
                      $("#X-btn_CLIC").hide();
                      $("#done-btn_CLIC").show();
                      $("#review-btn_CLIC").hide();
                    }
                  );
                } else {
                  mainactionCLIC = "upload";
                  CLICStatus = "open";
                  CLStatusArray[4].status = CLICStatus;
                  $("#viewDocLink_CLIC").hide();
                  $("#choose_CLIC").show();
                  $("#X-btn_CLIC").hide();
                  $("#done-btn_CLIC").hide();
                  $("#review-btn_CLIC").hide();
                }
                if (onetime[5].status == "remove") {
                  mainactionCLMOA = "upload";
                  CLMOAStatus = "remove";
                  CLStatusArray[5].status = CLMOAStatus;
                  this.setState(
                    {
                      CLMOADocLink: onetime[5].MOA,
                    },
                    () => {
                      $("#viewDocLink_CLMOA").css("display", "block");
                      $("#choose_CLMOA").hide();
                      $("#X-btn_CLMOA").show();
                      $("#done-btn_CLMOA").hide();
                      $("#review-btn_CLMOA").hide();
                    }
                  );
                } else if (onetime[5].status == "review") {
                  mainactionCLMOA = "upload";
                  CLMOAStatus = "review";
                  CLStatusArray[5].status = CLMOAStatus;
                  this.setState(
                    {
                      CLMOADocLink: onetime[5].MOA,
                    },
                    () => {
                      $("#viewDocLink_CLMOA").css("display", "block");
                      $("#choose_CLMOA").hide();
                      $("#X-btn_CLMOA").hide();
                      $("#done-btn_CLMOA").hide();
                      $("#review-btn_CLMOA").show();
                    }
                  );
                } else if (onetime[5].status == "reupload") {
                  mainactionCLMOA = "reupload";
                  CLMOAStatus = "reupload";
                  CLStatusArray[5].status = CLMOAStatus;
                  this.setState({}, () => {
                    $("#choose_CLMOA").show();
                    $("#viewDocLink_CLMOA").hide();
                    $("#X-btn_CLMOA").hide();
                    $("#done-btn_CLMOA").hide();
                    $("#review-btn_CLMOA").hide();
                  });
                } else if (onetime[5].status == "done") {
                  CLMOAStatus = "done";
                  CLStatusArray[5].status = CLMOAStatus;
                  mainactionCLMOA = "upload";
                  this.setState(
                    {
                      CLMOADocLink: onetime[5].MOA,
                    },
                    () => {
                      $("#choose_CLMOA").hide();
                      $("#viewDocLink_CLMOA").css("display", "block");
                      $("#X-btn_CLMOA").hide();
                      $("#done-btn_CLMOA").show();
                      $("#review-btn_CLMOA").hide();
                    }
                  );
                } else {
                  mainactionCLMOA = "upload";
                  CLMOAStatus = "open";
                  CLStatusArray[5].status = CLMOAStatus;
                  $("#viewDocLink_CLMOA").hide();
                  $("#choose_CLMOA").show();
                  $("#X-btn_CLMOA").hide();
                  $("#done-btn_CLMOA").hide();
                  $("#review-btn_CLMOA").hide();
                }
                if (onetime[6].status == "remove") {
                  mainactionCLAOA = "upload";
                  CLAOAStatus = "remove";
                  CLStatusArray[6].status = CLAOAStatus;
                  this.setState(
                    {
                      CLAOADocLink: onetime[6].AOA,
                    },
                    () => {
                      $("#viewDocLink_CLAOA").css("display", "block");
                      $("#choose_CLAOA").hide();
                      $("#X-btn_CLAOA").show();
                      $("#done-btn_CLAOA").hide();
                      $("#review-btn_CLAOA").hide();
                    }
                  );
                } else if (onetime[6].status == "review") {
                  mainactionCLAOA = "upload";
                  CLAOAStatus = "review";
                  CLStatusArray[6].status = CLAOAStatus;
                  this.setState(
                    {
                      CLAOADocLink: onetime[6].AOA,
                    },
                    () => {
                      $("#viewDocLink_CLAOA").css("display", "block");
                      $("#choose_CLAOA").hide();
                      $("#X-btn_CLAOA").hide();
                      $("#done-btn_CLAOA").hide();
                      $("#review-btn_CLAOA").show();
                    }
                  );
                } else if (onetime[6].status == "reupload") {
                  mainactionCLAOA = "reupload";
                  CLAOAStatus = "reupload";
                  CLStatusArray[6].status = CLAOAStatus;
                  this.setState({}, () => {
                    $("#choose_CLAOA").show();
                    $("#viewDocLink_CLAOA").hide();
                    $("#X-btn_CLAOA").hide();
                    $("#done-btn_CLAOA").hide();
                    $("#review-btn_CLAOA").hide();
                  });
                } else if (onetime[6].status == "done") {
                  CLAOAStatus = "done";
                  CLStatusArray[6].status = CLAOAStatus;
                  mainactionCLAOA = "upload";
                  this.setState(
                    {
                      CLAOADocLink: onetime[6].AOA,
                    },
                    () => {
                      $("#choose_CLAOA").hide();
                      $("#viewDocLink_CLAOA").css("display", "block");
                      $("#X-btn_CLAOA").hide();
                      $("#done-btn_CLAOA").show();
                      $("#review-btn_CLAOA").hide();
                    }
                  );
                } else {
                  mainactionCLAOA = "upload";
                  CLAOAStatus = "open";
                  CLStatusArray[6].status = CLAOAStatus;
                  $("#viewDocLink_CLAOA").hide();
                  $("#choose_CLAOA").show();
                  $("#X-btn_CLAOA").hide();
                  $("#done-btn_CLAOA").hide();
                  $("#review-btn_CLAOA").hide();
                }
                if (onetime[7].status == "remove") {
                  mainactionCLBP = "upload";
                  CLBPStatus = "remove";
                  CLStatusArray[7].status = CLBPStatus;
                  this.setState(
                    {
                      CLBPDocLink: onetime[7].BankProof,
                    },
                    () => {
                      $("#viewDocLink_CLBP").css("display", "block");
                      $("#choose_CLBP").hide();
                      $("#X-btn_CLBP").show();
                      $("#done-btn_CLBP").hide();
                      $("#review-btn_CLBP").hide();
                    }
                  );
                } else if (onetime[7].status == "review") {
                  mainactionCLBP = "upload";
                  CLBPStatus = "review";
                  CLStatusArray[7].status = CLBPStatus;
                  this.setState(
                    {
                      CLBPDocLink: onetime[7].BankProof,
                    },
                    () => {
                      $("#viewDocLink_CLBP").css("display", "block");
                      $("#choose_CLBP").hide();
                      $("#X-btn_CLBP").hide();
                      $("#done-btn_CLBP").hide();
                      $("#review-btn_CLBP").show();
                    }
                  );
                } else if (onetime[7].status == "reupload") {
                  mainactionCLBP = "reupload";
                  CLBPStatus = "reupload";
                  CLStatusArray[7].status = CLBPStatus;
                  this.setState({}, () => {
                    $("#choose_CLBP").show();
                    $("#viewDocLink_CLBP").hide();
                    $("#X-btn_CLBP").hide();
                    $("#done-btn_CLBP").hide();
                    $("#review-btn_CLBP").hide();
                  });
                } else if (onetime[7].status == "done") {
                  CLBPStatus = "done";
                  CLStatusArray[7].status = CLBPStatus;
                  mainactionCLBP = "upload";
                  this.setState(
                    {
                      CLBPDocLink: onetime[7].BankProof,
                    },
                    () => {
                      $("#viewDocLink_CLBP").css("display", "block");
                      $("#choose_CLBP").hide();
                      $("#X-btn_CLBP").hide();
                      $("#done-btn_CLBP").show();
                      $("#review-btn_CLBP").hide();
                    }
                  );
                } else {
                  mainactionCLBP = "upload";
                  CLBPStatus = "open";
                  CLStatusArray[7].status = CLBPStatus;
                  $("#viewDocLink_CLBP").hide();
                  $("#choose_CLBP").show();
                  $("#X-btn_CLBP").hide();
                  $("#done-btn_CLBP").hide();
                  $("#review-btn_CLBP").hide();
                }
                if (onetime[8].status == "remove") {
                  mainactionCLPBS_A = "upload";
                  CLPBS_AStatus = "remove";
                  CLOptStatusArray[0].status = CLPBS_AStatus;
                  this.setState(
                    {
                      CLPBS_ADocLink: onetime[8].PreviousBS_Audit,
                    },
                    () => {
                      $("#viewDocLink_CLPBS_A").css("display", "block");
                      $("#choose_CLPBS_A").hide();
                      $("#X-btn_CLPBS_A").show();
                      $("#done-btn_CLPBS_A").hide();
                      $("#review-btn_CLPBS_A").hide();
                    }
                  );
                } else if (onetime[8].status == "review") {
                  mainactionCLPBS_A = "upload";
                  CLPBS_AStatus = "review";
                  CLOptStatusArray[0].status = CLPBS_AStatus;
                  this.setState(
                    {
                      CLPBS_ADocLink: onetime[8].PreviousBS_Audit,
                    },
                    () => {
                      $("#viewDocLink_CLPBS_A").css("display", "block");
                      $("#choose_CLPBS_A").hide();
                      $("#X-btn_CLPBS_A").hide();
                      $("#done-btn_CLPBS_A").hide();
                      $("#review-btn_CLPBS_A").show();
                    }
                  );
                } else if (onetime[8].status == "reupload") {
                  mainactionCLPBS_A = "reupload";
                  CLPBS_AStatus = "reupload";
                  CLOptStatusArray[0].status = CLPBS_AStatus;
                  this.setState({}, () => {
                    $("#choose_CLPBS_A").show();
                    $("#viewDocLink_CLPBS_A").hide();
                    $("#X-btn_CLPBS_A").hide();
                    $("#done-btn_CLPBS_A").hide();
                    $("#review-btn_CLPBS_A").hide();
                  });
                } else if (onetime[8].status == "done") {
                  CLPBS_AStatus = "done";
                  CLOptStatusArray[0].status = CLPBS_AStatus;
                  mainactionCLPBS_A = "upload";
                  this.setState(
                    {
                      CLPBS_ADocLink: onetime[8].PreviousBS_Audit,
                    },
                    () => {
                      $("#choose_CLPBS_A").hide();
                      $("#viewDocLink_CLPBS_A").css("display", "block");
                      $("#X-btn_CLPBS_A").hide();
                      $("#done-btn_CLPBS_A").show();
                      $("#review-btn_CLPBS_A").hide();
                    }
                  );
                } else {
                  mainactionCLPBS_A = "upload";
                  CLPBS_AStatus = "open";
                  CLOptStatusArray[0].status = CLPBS_AStatus;
                  $("#viewDocLink_CLPBS_A").hide();
                  $("#choose_CLPBS_A").show();
                  $("#X-btn_CLPBS_A").hide();
                  $("#done-btn_CLPBS_A").hide();
                  $("#review-btn_CLPBS_A").hide();
                }
                if (onetime[9].status == "remove") {
                  mainactionCLPR = "upload";
                  CLPRStatus = "remove";
                  CLOptStatusArray[1].status = CLPRStatus;
                  this.setState(
                    {
                      CLPRDocLink: onetime[9].PreviousReturns,
                    },
                    () => {
                      $("#viewDocLink_CLPR").css("display", "block");
                      $("#choose_CLPR").hide();
                      $("#X-btn_CLPR").show();
                      $("#done-btn_CLPR").hide();
                      $("#review-btn_CLPR").hide();
                    }
                  );
                } else if (onetime[9].status == "review") {
                  mainactionCLPR = "upload";
                  CLPRStatus = "review";
                  CLOptStatusArray[1].status = CLPRStatus;
                  this.setState(
                    {
                      CLPRDocLink: onetime[9].PreviousReturns,
                    },
                    () => {
                      $("#viewDocLink_CLPR").css("display", "block");
                      $("#choose_CLPR").hide();
                      $("#X-btn_CLPR").hide();
                      $("#done-btn_CLPR").hide();
                      $("#review-btn_CLPR").show();
                    }
                  );
                } else if (onetime[9].status == "reupload") {
                  mainactionCLPR = "reupload";
                  CLPRStatus = "reupload";
                  CLOptStatusArray[1].status = CLPRStatus;
                  this.setState({}, () => {
                    $("#choose_CLPR").show();
                    $("#viewDocLink_CLPR").hide();
                    $("#X-btn_CLPR").hide();
                    $("#done-btn_CLPR").hide();
                    $("#review-btn_CLPR").hide();
                  });
                } else if (onetime[9].status == "done") {
                  CLPRStatus = "done";
                  CLOptStatusArray[1].status = CLPRStatus;
                  mainactionCLPR = "upload";
                  this.setState(
                    {
                      CLPRDocLink: onetime[9].PreviousReturns,
                    },
                    () => {
                      $("#choose_CLPR").hide();
                      $("#viewDocLink_CLPR").css("display", "block");
                      $("#X-btn_CLPR").hide();
                      $("#done-btn_CLPR").show();
                      $("#review-btn_CLPR").hide();
                    }
                  );
                } else {
                  mainactionCLPR = "upload";
                  CLPRStatus = "open";
                  CLOptStatusArray[1].status = CLPRStatus;
                  $("#viewDocLink_CLPR").hide();
                  $("#choose_CLPR").show();
                  $("#X-btn_CLPR").hide();
                  $("#done-btn_CLPR").hide();
                  $("#review-btn_CLPR").hide();
                }
              }
            } else {
              $("#viewDocLink_CLAadhar1").hide();
              $("#choose_CLAadhar1").show();
              $("#X-btn_CLAadhar1").hide();
              $("#done-btn_CLAadhar1").hide();
              $("#review-btn_CLAadhar1").hide();

              $("#viewDocLink_CLAadhar2").hide();
              $("#choose_CLAadhar2").show();
              $("#X-btn_CLAadhar2").hide();
              $("#done-btn_CLAadhar2").hide();
              $("#review-btn_CLAadhar2").hide();

              $("#viewDocLink_CLPan1").hide();
              $("#choose_CLPan1").show();
              $("#X-btn_CLPan1").hide();
              $("#done-btn_CLPan1").hide();
              $("#review-btn_CLPan1").hide();

              $("#viewDocLink_CLPan2").hide();
              $("#choose_CLPan2").show();
              $("#X-btn_CLPan2").hide();
              $("#done-btn_CLPan2").hide();
              $("#review-btn_CLPan2").hide();

              $("#viewDocLink_CLIC").hide();
              $("#choose_CLIC").show();
              $("#X-btn_CLIC").hide();
              $("#done-btn_CLIC").hide();
              $("#review-btn_CLIC").hide();

              $("#viewDocLink_CLMOA").hide();
              $("#choose_CLMOA").show();
              $("#X-btn_CLMOA").hide();
              $("#done-btn_CLMOA").hide();
              $("#review-btn_CLMOA").hide();

              $("#viewDocLink_CLAOA").hide();
              $("#choose_CLAOA").show();
              $("#X-btn_CLAOA").hide();
              $("#done-btn_CLAOA").hide();
              $("#review-btn_CLAOA").hide();

              $("#viewDocLink_CLBP").hide();
              $("#choose_CLBP").show();
              $("#X-btn_CLBP").hide();
              $("#done-btn_CLBP").hide();
              $("#review-btn_CLBP").hide();

              $("#viewDocLink_CLPBS_A").hide();
              $("#choose_CLPBS_A").show();
              $("#X-btn_CLPBS_A").hide();
              $("#done-btn_CLPBS_A").hide();
              $("#review-btn_CLPBS_A").hide();

              $("#viewDocLink_CLPR").hide();
              $("#choose_CLPR").show();
              $("#X-btn_CLPR").hide();
              $("#done-btn_CLPR").hide();
              $("#review-btn_CLPR").hide();
            }
            if (
              res.data.fyStatus == "lock" ||
              res.data.fyStatus == "complete"
            ) {
              $(".proceednowbtn").hide();
              if (res.data.incD[0].documents.length == 0) {
                $("#CLincDadditionalDocumentDiv").hide();
              } else {
                $("#CLincDadddocumentbtn").hide();
              }
              if (res.data.addDocInformation.length == 0) {
                $("#CLadditionalDocumentDiv").hide();
              } else {
                $("#CLadddocumentbtn").hide();
              }
            }
            if (res.data.incD[0].documents.length > 0) {
              $("#CLincDadditionalDocumentDiv").show();
              $("#CLincDadddocumentbtn").hide();
              this.setState(
                {
                  CLincDAdditionalFiles: [...res.data.incD[0].documents],
                  CLincDAdd_DocName: "File Name",
                  api_CLincDAdditionalFiles: "Yes",
                },
                () => {}
              );
            } else if (
              res.data.incD[0].documents.length == 0 &&
              res.data.fyStatus != "lock" &&
              res.data.fyStatus != "complete"
            ) {
              $("#CLincDadditionalDocumentDiv").show();
              this.setState({
                CLincDAdd_DocName: "",
                CLincDAdditionalFiles: [1],
                api_CLincDAdditionalFiles: "No",
              });
            }
            if (res.data.addDocInformation.length > 0) {
              $("#CLadditionalDocumentDiv").show();
              $("#CLadddocumentbtn").hide();
              this.setState(
                {
                  CLAdditionalFiles: [...res.data.addDocInformation],
                  CLAdd_DocName: "File Name",
                  api_CLAdditionalFiles: "Yes",
                },
                () => {}
              );
            } else if (
              res.data.addDocInformation.length == 0 &&
              res.data.fyStatus != "lock" &&
              res.data.fyStatus != "complete"
            ) {
              $("#CLadditionalDocumentDiv").show();
              $("#CLadddocumentbtn").show();
              this.setState({
                CLAdd_DocName: "",
                CLAdditionalFiles: [1],
                api_CLAdditionalFiles: "No",
              });
            }
            if (res.data.documents.length > 0) {
              let doc = res.data.documents;
              if (doc[0].status == "remove") {
                mainactionCLData = "upload";
                CLDataStatus = "remove";
                CLStatusArray[8].status = CLDataStatus;
                this.setState(
                  {
                    CLDataDocLink: doc[0].Data,
                  },
                  () => {
                    $("#viewDocLink_CLData").css("display", "block");
                    $("#choose_CLData").hide();
                    $("#X-btn_CLData").show();
                    $("#done-btn_CLData").hide();
                    $("#review-btn_CLData").hide();
                  }
                );
              } else if (doc[0].status == "review") {
                mainactionCLData = "upload";
                CLDataStatus = "review";
                CLStatusArray[8].status = CLDataStatus;
                this.setState(
                  {
                    CLDataDocLink: doc[0].Data,
                  },
                  () => {
                    $("#viewDocLink_CLData").css("display", "block");
                    $("#choose_CLData").hide();
                    $("#X-btn_CLData").hide();
                    $("#done-btn_CLData").hide();
                    $("#review-btn_CLData").show();
                  }
                );
              } else if (doc[0].status == "reupload") {
                mainactionCLData = "reupload";
                CLDataStatus = "reupload";
                CLStatusArray[8].status = CLDataStatus;
                this.setState({}, () => {
                  $("#choose_CLData").show();
                  $("#viewDocLink_CLData").hide();
                  $("#X-btn_CLData").hide();
                  $("#done-btn_CLData").hide();
                  $("#review-btn_CLData").hide();
                });
              } else if (doc[0].status == "done") {
                CLDataStatus = "done";
                CLStatusArray[8].status = CLDataStatus;
                mainactionCLData = "upload";
                this.setState(
                  {
                    CLDataDocLink: doc[0].Data,
                  },
                  () => {
                    $("#choose_CLData").hide();
                    $("#viewDocLink_CLData").css("display", "block");
                    $("#X-btn_CLData").hide();
                    $("#done-btn_CLData").show();
                    $("#review-btn_CLData").hide();
                  }
                );
              } else {
                mainactionCLData = "upload";
                CLDataStatus = "open";
                CLStatusArray[8].status = CLDataStatus;
                $("#viewDocLink_CLData").hide();
                $("#choose_CLData").show();
                $("#X-btn_CLData").hide();
                $("#done-btn_CLData").hide();
                $("#review-btn_CLData").hide();
              }

              if (doc[1].status == "remove") {
                mainactionCLGSTReturn = "upload";
                CLGSTReturnStatus = "remove";
                CLOptStatusArray[2].status = CLGSTReturnStatus;
                this.setState(
                  {
                    CLGSTReturnDocLink: doc[1].GSTReturn,
                  },
                  () => {
                    $("#viewDocLink_CLGSTReturn").css("display", "block");
                    $("#choose_CLGSTReturn").hide();
                    $("#X-btn_CLGSTReturn").show();
                    $("#done-btn_CLGSTReturn").hide();
                    $("#review-btn_CLGSTReturn").hide();
                  }
                );
              } else if (doc[1].status == "review") {
                mainactionCLGSTReturn = "upload";
                CLGSTReturnStatus = "review";
                CLOptStatusArray[2].status = CLGSTReturnStatus;
                this.setState(
                  {
                    CLGSTReturnDocLink: doc[1].GSTReturn,
                  },
                  () => {
                    $("#viewDocLink_CLGSTReturn").css("display", "block");
                    $("#choose_CLGSTReturn").hide();
                    $("#X-btn_CLGSTReturn").hide();
                    $("#done-btn_CLGSTReturn").hide();
                    $("#review-btn_CLGSTReturn").show();
                  }
                );
              } else if (doc[1].status == "reupload") {
                mainactionCLGSTReturn = "reupload";
                CLGSTReturnStatus = "reupload";
                CLOptStatusArray[2].status = CLGSTReturnStatus;
                this.setState({}, () => {
                  $("#choose_CLGSTReturn").show();
                  $("#viewDocLink_CLGSTReturn").hide();
                  $("#X-btn_CLGSTReturn").hide();
                  $("#done-btn_CLGSTReturn").hide();
                  $("#review-btn_CLGSTReturn").hide();
                });
              } else if (doc[1].status == "done") {
                CLGSTReturnStatus = "done";
                CLOptStatusArray[2].status = CLGSTReturnStatus;
                mainactionCLGSTReturn = "upload";
                this.setState(
                  {
                    CLGSTReturnDocLink: doc[1].GSTReturn,
                  },
                  () => {
                    $("#choose_CLGSTReturn").hide();
                    $("#viewDocLink_CLGSTReturn").css("display", "block");
                    $("#X-btn_CLGSTReturn").hide();
                    $("#done-btn_CLGSTReturn").show();
                    $("#review-btn_CLGSTReturn").hide();
                  }
                );
              } else {
                mainactionCLGSTReturn = "upload";
                CLGSTReturnStatus = "open";
                CLOptStatusArray[2].status = CLGSTReturnStatus;
                $("#viewDocLink_CLGSTReturn").hide();
                $("#choose_CLGSTReturn").show();
                $("#X-btn_CLGSTReturn").hide();
                $("#done-btn_CLGSTReturn").hide();
                $("#review-btn_CLGSTReturn").hide();
              }

              if (doc[2].status == "remove") {
                mainactionCLTDSReturn = "upload";
                CLTDSReturnStatus = "remove";
                CLOptStatusArray[3].status = CLTDSReturnStatus;
                this.setState(
                  {
                    CLTDSReturnDocLink: doc[2].TDSReturn,
                  },
                  () => {
                    $("#viewDocLink_CLTDSReturn").css("display", "block");
                    $("#choose_CLTDSReturn").hide();
                    $("#X-btn_CLTDSReturn").show();
                    $("#done-btn_CLTDSReturn").hide();
                    $("#review-btn_CLTDSReturn").hide();
                  }
                );
              } else if (doc[2].status == "review") {
                mainactionCLTDSReturn = "upload";
                CLTDSReturnStatus = "review";
                CLOptStatusArray[3].status = CLTDSReturnStatus;
                this.setState(
                  {
                    CLTDSReturnDocLink: doc[2].TDSReturn,
                  },
                  () => {
                    $("#viewDocLink_CLTDSReturn").css("display", "block");
                    $("#choose_CLTDSReturn").hide();
                    $("#X-btn_CLTDSReturn").hide();
                    $("#done-btn_CLTDSReturn").hide();
                    $("#review-btn_CLTDSReturn").show();
                  }
                );
              } else if (doc[2].status == "reupload") {
                mainactionCLTDSReturn = "reupload";
                CLTDSReturnStatus = "reupload";
                CLOptStatusArray[3].status = CLTDSReturnStatus;
                this.setState({}, () => {
                  $("#choose_CLTDSReturn").show();
                  $("#viewDocLink_CLTDSReturn").hide();
                  $("#X-btn_CLTDSReturn").hide();
                  $("#done-btn_CLTDSReturn").hide();
                  $("#review-btn_CLTDSReturn").hide();
                });
              } else if (doc[2].status == "done") {
                CLTDSReturnStatus = "done";
                CLOptStatusArray[3].status = CLTDSReturnStatus;
                mainactionCLTDSReturn = "upload";
                this.setState(
                  {
                    CLTDSReturnDocLink: doc[2].TDSReturn,
                  },
                  () => {
                    $("#choose_CLTDSReturn").hide();
                    $("#viewDocLink_CLTDSReturn").css("display", "block");
                    $("#X-btn_CLTDSReturn").hide();
                    $("#done-btn_CLTDSReturn").show();
                    $("#review-btn_CLTDSReturn").hide();
                  }
                );
              } else {
                mainactionCLTDSReturn = "upload";
                CLTDSReturnStatus = "open";
                CLOptStatusArray[3].status = CLTDSReturnStatus;
                $("#viewDocLink_CLTDSReturn").hide();
                $("#choose_CLTDSReturn").show();
                $("#X-btn_CLTDSReturn").hide();
                $("#done-btn_CLTDSReturn").hide();
                $("#review-btn_CLTDSReturn").hide();
              }

              if (doc[3].status == "remove") {
                mainactionCLChallan = "upload";
                CLChallanStatus = "remove";
                CLOptStatusArray[4].status = CLChallanStatus;
                this.setState(
                  {
                    CLChallanDocLink: doc[3].Challan,
                  },
                  () => {
                    $("#viewDocLink_CLChallan").css("display", "block");
                    $("#choose_CLChallan").hide();
                    $("#X-btn_CLChallan").show();
                    $("#done-btn_CLChallan").hide();
                    $("#review-btn_CLChallan").hide();
                  }
                );
              } else if (doc[3].status == "review") {
                mainactionCLChallan = "upload";
                CLChallanStatus = "review";
                CLOptStatusArray[4].status = CLChallanStatus;
                this.setState(
                  {
                    CLChallanDocLink: doc[3].Challan,
                  },
                  () => {
                    $("#viewDocLink_CLChallan").css("display", "block");
                    $("#choose_CLChallan").hide();
                    $("#X-btn_CLChallan").hide();
                    $("#done-btn_CLChallan").hide();
                    $("#review-btn_CLChallan").show();
                  }
                );
              } else if (doc[3].status == "reupload") {
                mainactionCLChallan = "reupload";
                CLChallanStatus = "reupload";
                CLOptStatusArray[4].status = CLChallanStatus;
                this.setState({}, () => {
                  $("#choose_CLChallan").show();
                  $("#viewDocLink_CLChallan").hide();
                  $("#X-btn_CLChallan").hide();
                  $("#done-btn_CLChallan").hide();
                  $("#review-btn_CLChallan").hide();
                });
              } else if (doc[3].status == "done") {
                CLChallanStatus = "done";
                CLOptStatusArray[4].status = CLChallanStatus;
                mainactionCLChallan = "upload";
                this.setState(
                  {
                    CLChallanDocLink: doc[3].Challan,
                  },
                  () => {
                    $("#choose_CLChallan").hide();
                    $("#viewDocLink_CLChallan").css("display", "block");
                    $("#X-btn_CLChallan").hide();
                    $("#done-btn_CLChallan").show();
                    $("#review-btn_CLChallan").hide();
                  }
                );
              } else {
                mainactionCLChallan = "upload";
                CLChallanStatus = "open";
                CLOptStatusArray[4].status = CLChallanStatus;
                $("#viewDocLink_CLChallan").hide();
                $("#choose_CLChallan").show();
                $("#X-btn_CLChallan").hide();
                $("#done-btn_CLChallan").hide();
                $("#review-btn_CLChallan").hide();
              }
              if (doc[4].status == "remove") {
                mainactionCLBankS = "upload";
                CLBankSStatus = "remove";
                CLStatusArray[9].status = CLBankSStatus;
                this.setState(
                  {
                    CLBankSDocLink: doc[4].BankStatement,
                  },
                  () => {
                    $("#viewDocLink_CLBankS").css("display", "block");
                    $("#choose_CLBankS").hide();
                    $("#X-btn_CLBankS").show();
                    $("#done-btn_CLBankS").hide();
                    $("#review-btn_CLBankS").hide();
                  }
                );
              } else if (doc[4].status == "review") {
                mainactionCLBankS = "upload";
                CLBankSStatus = "review";
                CLStatusArray[9].status = CLBankSStatus;
                this.setState(
                  {
                    CLBankSDocLink: doc[4].BankStatement,
                  },
                  () => {
                    $("#viewDocLink_CLBankS").css("display", "block");
                    $("#choose_CLBankS").hide();
                    $("#X-btn_CLBankS").hide();
                    $("#done-btn_CLBankS").hide();
                    $("#review-btn_CLBankS").show();
                  }
                );
              } else if (doc[4].status == "reupload") {
                mainactionCLBankS = "reupload";
                CLBankSStatus = "reupload";
                CLStatusArray[9].status = CLBankSStatus;
                this.setState({}, () => {
                  $("#choose_CLBankS").show();
                  $("#viewDocLink_CLBankS").hide();
                  $("#X-btn_CLBankS").hide();
                  $("#done-btn_CLBankS").hide();
                  $("#review-btn_CLBankS").hide();
                });
              } else if (doc[4].status == "done") {
                CLBankSStatus = "done";
                CLStatusArray[9].status = CLBankSStatus;
                mainactionCLBankS = "upload";
                this.setState(
                  {
                    CLBankSDocLink: doc[4].BankStatement,
                  },
                  () => {
                    $("#choose_CLBankS").hide();
                    $("#viewDocLink_CLBankS").css("display", "block");
                    $("#X-btn_CLBankS").hide();
                    $("#done-btn_CLBankS").show();
                    $("#review-btn_CLBankS").hide();
                  }
                );
              } else {
                mainactionCLBankS = "upload";
                CLBankSStatus = "open";
                CLStatusArray[9].status = CLBankSStatus;
                $("#viewDocLink_CLBankS").hide();
                $("#choose_CLBankS").show();
                $("#X-btn_CLBankS").hide();
                $("#done-btn_CLBankS").hide();
                $("#review-btn_CLBankS").hide();
              }
            } else {
              $("#viewDocLink_CLData").hide();
              $("#choose_CLData").show();
              $("#X-btn_CLData").hide();
              $("#done-btn_CLData").hide();
              $("#review-btn_CLData").hide();

              $("#viewDocLink_CLBankS").hide();
              $("#choose_CLBankS").show();
              $("#X-btn_CLBankS").hide();
              $("#done-btn_CLBankS").hide();
              $("#review-btn_CLBankS").hide();

              $("#viewDocLink_CLGSTReturn").hide();
              $("#choose_CLGSTReturn").show();
              $("#X-btn_CLGSTReturn").hide();
              $("#done-btn_CLGSTReturn").hide();
              $("#review-btn_CLGSTReturn").hide();

              $("#viewDocLink_CLTDSReturn").hide();
              $("#choose_CLTDSReturn").show();
              $("#X-btn_CLTDSReturn").hide();
              $("#done-btn_CLTDSReturn").hide();
              $("#review-btn_CLTDSReturn").hide();

              $("#viewDocLink_CLChallan").hide();
              $("#choose_CLChallan").show();
              $("#X-btn_CLChallan").hide();
              $("#done-btn_CLChallan").hide();
              $("#review-btn_CLChallan").hide();
            }
            this.CLcheckRemainingReuploads();
            let statusCheck = this.statusCheckFunction(
              "reupload",
              CLStatusArray
            );
            CLincDadditionalStatusArrayCheck = this.statusCheckFunction(
              "reupload",
              this.state.CLincDAdditionalFiles
            );
            CLadditionalStatusArrayCheck = this.statusCheckFunction(
              "reupload",
              this.state.CLAdditionalFiles
            );

            if (
              AnyReuploadOccuredCL == "Yes" &&
              statusCheck == undefined &&
              CLincDadditionalStatusArrayCheck == undefined &&
              CLadditionalStatusArrayCheck == undefined
            ) {
              CLOptReview = "Yes";
              this.countOptReuploadCL("reupload", CLOptStatusArray);
            } else {
              CLOptReview = "No";
            }
            if (
              res.data.rejectstatus == "false" &&
              res.data.mainstatus == "true"
            ) {
              rejectstatus = "Yes";
            }
            // this.CLcheckRemainingReuploads();
            // this.checkhandleCLProceedBtn();
          }
          if (res.data.oneTime[0].inputFields.length > 0) {
            if (
              res.data.oneTime[0].inputFields[0].PortalUserID != null &&
              res.data.oneTime[0].inputFields[1].PortalPassword != null
            ) {
              this.setState({
                itrfusername: res.data.oneTime[0].inputFields[0].PortalUserID,
                itrfpassword: res.data.oneTime[0].inputFields[1].PortalPassword,
              });
              $(".itrfusername").attr("disabled", true);
              $(".itrfpassword").attr("disabled", true);
            }
          }
          if (res.data.mainstatus == "true" && res.data.fyStatus == "lock") {
            $(".proceednowbtn1").show();
            $(".proceednowbtn2").show();
            $(".proceednowbtn3").show();
            $(".proceednowbtn4").show();
            $(".proceednowbtn1").attr("disabled", true);
            $(".proceednowbtn2").attr("disabled", true);
            $(".proceednowbtn3").attr("disabled", true);
            $(".proceednowbtn4").attr("disabled", true);
          } else if (
            res.data.mainstatus == "false" &&
            (res.data.fyStatus == "clear" || res.data.fyStatus == "unlock")
          ) {
            ChooseNowBtnsDisable = "No";
            $(".upload-photo").attr("disabled", false);
            $("#SincDadddocumentbtn").show();
            $("#Sadddocumentbtn").show();
            $("#PincDadddocumentbtn").show();
            $("#Padddocumentbtn").show();
            $("#PFincDadddocumentbtn").show();
            $("#PFadddocumentbtn").show();
            $("#CLincDadddocumentbtn").show();
            $("#CLadddocumentbtn").show();
          }
          this.ScheckRemainingReuploads();
          this.checkhandleSProceedBtn();
          this.PcheckRemainingReuploads();
          this.checkhandlePProceedBtn();
          this.PFcheckRemainingReuploads();
          this.checkhandlePFProceedBtn();
          this.CLcheckRemainingReuploads();
          this.checkhandleCLProceedBtn();

          if (
            res.data.mainstatus == "false" &&
            (res.data.fyStatus == "lock" || res.data.fyStatus == "complete")
          ) {
            $(".upload-photo").attr("disabled", true);
            $(".proceednowbtn1").hide();
            $(".proceednowbtn2").hide();
            $(".proceednowbtn3").hide();
            $(".proceednowbtn4").hide();
            ChooseNowBtnsDisable = "Yes";
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "docuploadnotallowed") {
          this.setState({
            referralPartner:false,
          })
          this.showNotification(res.data.message);
        }  else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          this.showNotification("Something went wrong");
        }
      })
      .catch((err) => {
        $(".serviceoverlay").hide();
      });
  };

  checkDropdownValue = () => {
    let sremove = this.statusCheckFunction("remove", SStatusArray);
    let sreview = this.statusCheckFunction("review", SStatusArray);
    let sdone = this.statusCheckFunction("done", SStatusArray);
    let sOptremove = this.statusCheckFunction("remove", SOptStatusArray);
    let sOptreview = this.statusCheckFunction("review", SOptStatusArray);
    let sOptdone = this.statusCheckFunction("done", SOptStatusArray);
    let sincdremove = this.statusCheckFunction(
      "remove",
      this.state.SincDAdditionalFiles
    );
    let sincdreview = this.statusCheckFunction(
      "review",
      this.state.SincDAdditionalFiles
    );
    let sincddone = this.statusCheckFunction(
      "done",
      this.state.SincDAdditionalFiles
    );
    let saddremove = this.statusCheckFunction(
      "remove",
      this.state.SAdditionalFiles
    );
    let saddreview = this.statusCheckFunction(
      "review",
      this.state.SAdditionalFiles
    );
    let sadddone = this.statusCheckFunction(
      "done",
      this.state.SAdditionalFiles
    );
    let premove = this.statusCheckFunction("remove", PStatusArray);
    let preview = this.statusCheckFunction("review", PStatusArray);
    let pdone = this.statusCheckFunction("done", PStatusArray);
    let pOptremove = this.statusCheckFunction("remove", POptStatusArray);
    let pOptreview = this.statusCheckFunction("review", POptStatusArray);
    let pOptdone = this.statusCheckFunction("done", POptStatusArray);
    let pincdremove = this.statusCheckFunction(
      "remove",
      this.state.PincDAdditionalFiles
    );
    let pincdreview = this.statusCheckFunction(
      "review",
      this.state.PincDAdditionalFiles
    );
    let pincddone = this.statusCheckFunction(
      "done",
      this.state.PincDAdditionalFiles
    );
    let paddremove = this.statusCheckFunction(
      "remove",
      this.state.PAdditionalFiles
    );
    let paddreview = this.statusCheckFunction(
      "review",
      this.state.PAdditionalFiles
    );
    let padddone = this.statusCheckFunction(
      "done",
      this.state.PAdditionalFiles
    );
    let pfremove = this.statusCheckFunction("remove", PFStatusArray);
    let pfreview = this.statusCheckFunction("review", PFStatusArray);
    let pfdone = this.statusCheckFunction("done", PFStatusArray);
    let pfOptremove = this.statusCheckFunction("remove", PFOptStatusArray);
    let pfOptreview = this.statusCheckFunction("review", PFOptStatusArray);
    let pfOptdone = this.statusCheckFunction("done", PFOptStatusArray);
    let pfincdremove = this.statusCheckFunction(
      "remove",
      this.state.PFincDAdditionalFiles
    );
    let pfincdreview = this.statusCheckFunction(
      "review",
      this.state.PFincDAdditionalFiles
    );
    let pfincddone = this.statusCheckFunction(
      "done",
      this.state.PFincDAdditionalFiles
    );
    let pfaddremove = this.statusCheckFunction(
      "remove",
      this.state.PFAdditionalFiles
    );
    let pfaddreview = this.statusCheckFunction(
      "review",
      this.state.PFAdditionalFiles
    );
    let pfadddone = this.statusCheckFunction(
      "done",
      this.state.PFAdditionalFiles
    );
    let clremove = this.statusCheckFunction("remove", CLStatusArray);
    let clreview = this.statusCheckFunction("review", CLStatusArray);
    let cldone = this.statusCheckFunction("done", CLStatusArray);
    let clOptremove = this.statusCheckFunction("remove", CLOptStatusArray);
    let clOptreview = this.statusCheckFunction("review", CLOptStatusArray);
    let clOptdone = this.statusCheckFunction("done", CLOptStatusArray);
    let clincdremove = this.statusCheckFunction(
      "remove",
      this.state.CLincDAdditionalFiles
    );
    let clincdreview = this.statusCheckFunction(
      "review",
      this.state.CLincDAdditionalFiles
    );
    let clincddone = this.statusCheckFunction(
      "done",
      this.state.CLincDAdditionalFiles
    );
    let claddremove = this.statusCheckFunction(
      "remove",
      this.state.CLAdditionalFiles
    );
    let claddreview = this.statusCheckFunction(
      "review",
      this.state.CLAdditionalFiles
    );
    let cladddone = this.statusCheckFunction(
      "done",
      this.state.CLAdditionalFiles
    );

    if (this.state.dropDownValue == "ITRF_S_OneTime_Documents") {
      if (
        sremove == undefined &&
        sreview == undefined &&
        sdone == undefined &&
        sOptremove == undefined &&
        sOptreview == undefined &&
        sOptdone == undefined &&
        sincdremove == undefined &&
        sincdreview == undefined &&
        sincddone == undefined &&
        saddremove == undefined &&
        saddreview == undefined &&
        sadddone == undefined
      ) {
        $(".selectcateselect1").attr("disabled", false);
      } else {
        $(".selectcateselect1").attr("disabled", true);
      }
    } else if (this.state.dropDownValue == "ITRF_BP_OneTime_Documents") {
      if (
        premove == undefined &&
        preview == undefined &&
        pdone == undefined &&
        pOptremove == undefined &&
        pOptreview == undefined &&
        pOptdone == undefined &&
        pincdremove == undefined &&
        pincdreview == undefined &&
        pincddone == undefined &&
        paddremove == undefined &&
        paddreview == undefined &&
        padddone == undefined
      ) {
        $(".selectcateselect1").attr("disabled", false);
      } else {
        $(".selectcateselect1").attr("disabled", true);
      }
    } else if (this.state.dropDownValue == "ITRF_BPP_OneTime_Documents") {
      if (
        pfremove == undefined &&
        pfreview == undefined &&
        pfdone == undefined &&
        pfOptremove == undefined &&
        pfOptreview == undefined &&
        pfOptdone == undefined &&
        pfincdremove == undefined &&
        pfincdreview == undefined &&
        pfincddone == undefined &&
        pfaddremove == undefined &&
        pfaddreview == undefined &&
        pfadddone == undefined
      ) {
        $(".selectcateselect1").attr("disabled", false);
      } else {
        $(".selectcateselect1").attr("disabled", true);
      }
    } else if (this.state.dropDownValue == "ITRF_BPC_OneTime_Documents") {
      if (
        clremove == undefined &&
        clreview == undefined &&
        cldone == undefined &&
        clOptremove == undefined &&
        clOptreview == undefined &&
        clOptdone == undefined &&
        clincdremove == undefined &&
        clincdreview == undefined &&
        clincddone == undefined &&
        claddremove == undefined &&
        claddreview == undefined &&
        cladddone == undefined
      ) {
        $(".selectcateselect1").attr("disabled", false);
      } else {
        $(".selectcateselect1").attr("disabled", true);
      }
    }
  };

  statusCheckFunction = (status, array) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i].status == status) {
        return i;
      }
    }
  };

  countOptReuploadS = (status, array) => {
    let count = 0;
    for (var i = 0; i < array.length; i++) {
      if (array[i].status == status) {
        count++;
      }
    }
    OptReuploadCountS = count;
  };

  countOptReuploadP = (status, array) => {
    let count = 0;
    for (var i = 0; i < array.length; i++) {
      if (array[i].status == status) {
        count++;
      }
    }
    OptReuploadCountP = count;
  };

  countOptReuploadPF = (status, array) => {
    let count = 0;
    for (var i = 0; i < array.length; i++) {
      if (array[i].status == status) {
        count++;
      }
    }
    OptReuploadCountPF = count;
  };

  countOptReuploadCL = (status, array) => {
    let count = 0;
    for (var i = 0; i < array.length; i++) {
      if (array[i].status == status) {
        count++;
      }
    }
    OptReuploadCountCL = count;
  };

  countOptReupload1 = (status, array) => {
    let count = 0;
    for (var i = 0; i < array.length; i++) {
      if (array[i].status == status) {
        count++;
      }
    }
    return count;
  };

  handleYearChange = (e) => {
    e.preventDefault();
    this.setState(
      {
        SelectedYear: e.target.value,
      },
      () => {
        $(".seecommentsbtn").show();
        $(".seecommentsbtn").attr("disabled", false);
        $(".disabledBtn_cmt").attr("disabled", true);
        $(".hidecommentsbtn").hide();
        $(".TDRightSide").hide();
        $(".commentimg-section").show();
        this.getserviceInformationApi();
        this.getdeliveredDocuments();
      }
    );
  };

  renderCommenthistory() {
    if (this.state.commentHistroyarray.length > 0) {
      return this.state.commentHistroyarray.map((activedoc, index) => {
        if (activedoc.commentBy == "user" && activedoc.special == "no") {
          return (
            <div className="rightconvtextdiv" key={index}>
              <p className="rightconvtext">{activedoc.comment}</p>
              <p className="rightconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "user" &&
          activedoc.special == "green"
        ) {
          return (
            <div className="rightconvtextdiv" key={index}>
              <p className="rightgreenconvtext">{activedoc.comment}</p>
              <p className="rightconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "employee" &&
          activedoc.special == "no"
        ) {
          return (
            <div className="leftconvtextdiv" key={index}>
              <p className="leftconvtext">{activedoc.comment}</p>
              <p className="leftconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "user" &&
          activedoc.special == "red"
        ) {
          return (
            <div className="rightunappconvtextdiv" key={index}>
              <p className="rightunappconvtext">{activedoc.comment}</p>
              <p className="rightconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "employee" &&
          activedoc.special == "green"
        ) {
          return (
            <div className="leftgreenconvtextdiv" key={index}>
              <p className="leftgreenconvtext">{activedoc.comment}</p>
              <p className="leftconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "employee" &&
          activedoc.special == "red"
        ) {
          return (
            <div className="leftconvtextdiv" key={index}>
              <p className="leftunappconvtext">{activedoc.comment}</p>
              <p className="leftconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        }
      });
    } else if (this.state.commentHistroyarray.length == 0) {
      return <p className="convservicename">No comments available!</p>;
    }
  }

  handledropDownValueChange = (e) => {
    $("#volvo").attr("disabled", true);
    $(".proceednowbtn").attr("disabled", true);
    this.setState(
      {
        dropDownValue: e.target.value,
      },
      () => {
        if (this.state.dropDownValue == "ITRF_S_OneTime_Documents") {
          this.setState(
            {
              dropDownValue: "ITRF_S_OneTime_Documents",
              serviceType: "ITRF_S_OneTime_Documents",
            },
            () => {
              if (this.state.dropDownValue == "ITRF_S_OneTime_Documents") {
                $("#Itrf_default_Img").hide();
                $("#ItrfSalariedSection").show();
                $("#ItrfproprietorSection").hide();
                $("#ItrfPartnershipFirm").hide();
                $("#ItrfCompany_LLP").hide();
              }
            }
          );
        } else if (this.state.dropDownValue == "ITRF_BP_OneTime_Documents") {
          this.setState(
            {
              dropDownValue: "ITRF_BP_OneTime_Documents",
              serviceType: "ITRF_BP_OneTime_Documents",
            },
            () => {
              if (this.state.dropDownValue == "ITRF_BP_OneTime_Documents") {
                $("#Itrf_default_Img").hide();
                $("#ItrfSalariedSection").hide();
                $("#ItrfproprietorSection").show();
                $("#ItrfPartnershipFirm").hide();
                $("#ItrfCompany_LLP").hide();
              }
            }
          );
        } else if (this.state.dropDownValue == "ITRF_BPP_OneTime_Documents") {
          this.setState(
            {
              dropDownValue: "ITRF_BPP_OneTime_Documents",
              serviceType: "ITRF_BPP_OneTime_Documents",
            },
            () => {
              if (this.state.dropDownValue == "ITRF_BPP_OneTime_Documents") {
                $("#Itrf_default_Img").hide();
                $("#ItrfSalariedSection").hide();
                $("#ItrfproprietorSection").hide();
                $("#ItrfPartnershipFirm").show();
                $("#ItrfCompany_LLP").hide();
              }
            }
          );
        } else if (this.state.dropDownValue == "ITRF_BPC_OneTime_Documents") {
          this.setState(
            {
              dropDownValue: "ITRF_BPC_OneTime_Documents",
              serviceType: "ITRF_BPC_OneTime_Documents",
            },
            () => {
              if (this.state.dropDownValue == "ITRF_BPC_OneTime_Documents") {
                $("#Itrf_default_Img").hide();
                $("#ItrfSalariedSection").hide();
                $("#ItrfproprietorSection").hide();
                $("#ItrfPartnershipFirm").hide();
                $("#ItrfCompany_LLP").show();
              }
            }
          );
        }
      }
    );
  };

  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };

  handleComment = (e) => {
    this.setState({
      comment: e.target.value,
    });
  };

  // SALARIED SECTION

  // aadhar
  handleSAadharChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "onetime",
            documentName: "AadhaarCard",
          },
          () => {
            $("#SAadharModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "onetime",
              documentName: "AadhaarCard",
            });
          }
        });
      $("#SAadharModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSAadhar = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_SAadhar").attr("disabled", true);
      $("#upload_btn20_SAadhar").hide();
      $("#upload_btnloader_SAadhar").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  SAadharDocLink: res.data.data.AadhaarCard,
                },
                () => $("#viewDocLink_SAadhar").css("display", "block")
              );
              SAadharStatus = "remove";
              SStatusArray[0].status = SAadharStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#SAadharModal").modal("hide");
              $("#cancelbtn_SAadhar").attr("disabled", false);
              $("#upload_btn20_SAadhar").show();
              $("#upload_btnloader_SAadhar").hide();
              $("#choose_SAadhar").hide();
              $("#X-btn_SAadhar").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_SAadhar").attr("disabled", false);
            $("#upload_btn20_SAadhar").show();
            $("#upload_btnloader_SAadhar").hide();
            $("#SAadharModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_SAadhar").attr("disabled", false);
            $("#upload_btn20_SAadhar").show();
            $("#upload_btnloader_SAadhar").hide();
            $("#SAadharModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_SAadhar").attr("disabled", false);
          $("#upload_btn20_SAadhar").show();
          $("#upload_btnloader_SAadhar").hide();
          $("#SAadharModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSAadhar = () => {
    $("#X-btn_SAadhar").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "onetime",
          documentName: "AadhaarCard",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              SAadharStatus = res.data.data.status;
              SStatusArray[0].status = SAadharStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#choose_SAadhar").show();
              $("#X-btn_SAadhar").hide();
              $("#X-btn_SAadhar").attr("disabled", false);
              $("#viewDocLink_SAadhar").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_SAadhar").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_SAadhar").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handleSAadharseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SAadharseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "AadhaarCard",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#SAadharseecommentsbtn").hide();
          $("#SAadharhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSAadharhidecommentsbtn = () => {
    $("#SAadharseecommentsbtn").show();
    $("#SAadharseecommentsbtn").attr("disabled", false);
    $("#SAadharhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // pan
  handleSPanChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "onetime",
            documentName: "PANCard",
          },
          () => {
            $("#SPanModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "onetime",
              documentName: "PANCard",
            });
          }
        });
      $("#SPanModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSPan = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_SPan").attr("disabled", true);
      $("#upload_btn20_SPan").hide();
      $("#upload_btnloader_SPan").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  SPanDocLink: res.data.data.PANCard,
                },
                () => $("#viewDocLink_SPan").css("display", "block")
              );
              SPanStatus = "remove";
              SStatusArray[1].status = SPanStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#SPanModal").modal("hide");
              $("#cancelbtn_SPan").attr("disabled", false);
              $("#upload_btn20_SPan").show();
              $("#upload_btnloader_SPan").hide();
              $("#choose_SPan").hide();
              $("#X-btn_SPan").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_SPan").attr("disabled", false);
            $("#upload_btn20_SPan").show();
            $("#upload_btnloader_SPan").hide();
            $("#SPanModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_SPan").attr("disabled", false);
            $("#upload_btn20_SPan").show();
            $("#upload_btnloader_SPan").hide();
            $("#SPanModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_SPan").attr("disabled", false);
          $("#upload_btn20_SPan").show();
          $("#upload_btnloader_SPan").hide();
          $("#SPanModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSPan = () => {
    $("#X-btn_SPan").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "onetime",
          documentName: "PANCard",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              SPanStatus = res.data.data.status;
              SStatusArray[1].status = SPanStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#choose_SPan").show();
              $("#X-btn_SPan").hide();
              $("#X-btn_SPan").attr("disabled", false);
              $("#viewDocLink_SPan").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_SPan").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_SPan").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handleSPanseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SPanseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "PANCard",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#SPanseecommentsbtn").hide();
          $("#SPanhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSPanhidecommentsbtn = () => {
    $("#SPanseecommentsbtn").show();
    $("#SPanseecommentsbtn").attr("disabled", false);
    $("#SPanhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // SBp
  handleSBpChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "onetime",
            documentName: "BankProof",
          },
          () => {
            $("#SBpModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "onetime",
              documentName: "BankProof",
            });
          }
        });
      $("#SBpModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSBp = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_SBp").attr("disabled", true);
      $("#upload_btn20_SBp").hide();
      $("#upload_btnloader_SBp").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  SBpDocLink: res.data.data.BankProof,
                },
                () => $("#viewDocLink_SBp").css("display", "block")
              );
              SBpStatus = "remove";
              SStatusArray[2].status = SBpStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#SBpModal").modal("hide");
              $("#cancelbtn_SBp").attr("disabled", false);
              $("#upload_btn20_SBp").show();
              $("#upload_btnloader_SBp").hide();
              $("#choose_SBp").hide();
              $("#X-btn_SBp").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_SBp").attr("disabled", false);
            $("#upload_btn20_SBp").show();
            $("#upload_btnloader_SBp").hide();
            $("#SBpModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_SBp").attr("disabled", false);
            $("#upload_btn20_SBp").show();
            $("#upload_btnloader_SBp").hide();
            $("#SBpModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_SBp").attr("disabled", false);
          $("#upload_btn20_SBp").show();
          $("#upload_btnloader_SBp").hide();
          $("#SBpModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSBp = () => {
    $("#X-btn_SBp").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "onetime",
          documentName: "BankProof",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              SBpStatus = res.data.data.status;
              SStatusArray[2].status = SBpStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();

              $("#choose_SBp").show();
              $("#X-btn_SBp").hide();
              $("#X-btn_SBp").attr("disabled", false);
              $("#viewDocLink_SBp").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_SBp").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_SBp").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSBpseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SBpseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "BankProof",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#SBpseecommentsbtn").hide();
          $("#SBphidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSBphidecommentsbtn = () => {
    $("#SBpseecommentsbtn").show();
    $("#SBpseecommentsbtn").attr("disabled", false);
    $("#SBphidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // SPBS
  handleSPBSChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "onetime",
            documentName: "PreviousBS",
          },
          () => {
            $("#SPBSModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "onetime",
              documentName: "PreviousBS",
            });
          }
        });
      $("#SPBSModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSPBS = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_SPBS").attr("disabled", true);
      $("#upload_btn20_SPBS").hide();
      $("#upload_btnloader_SPBS").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            if (SOptReview == "Yes") {
              SOptReview = "No";
            }
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  SPBSDocLink: res.data.data.PreviousBS,
                },
                () => $("#viewDocLink_SPBS").css("display", "block")
              );
              SPBSStatus = "remove";
              SOptStatusArray[0].status = SPBSStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();

              $("#SPBSModal").modal("hide");
              $("#cancelbtn_SPBS").attr("disabled", false);
              $("#upload_btn20_SPBS").show();
              $("#upload_btnloader_SPBS").hide();
              $("#choose_SPBS").hide();
              $("#X-btn_SPBS").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_SPBS").attr("disabled", false);
            $("#upload_btn20_SPBS").show();
            $("#upload_btnloader_SPBS").hide();
            $("#SPBSModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_SPBS").attr("disabled", false);
            $("#upload_btn20_SPBS").show();
            $("#upload_btnloader_SPBS").hide();
            $("#SPBSModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_SPBS").attr("disabled", false);
          $("#upload_btn20_SPBS").show();
          $("#upload_btnloader_SPBS").hide();
          $("#SPBSModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSPBS = () => {
    $("#X-btn_SPBS").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "onetime",
          documentName: "PreviousBS",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          SPBSStatus = res.data.data.status;
          SOptStatusArray[0].status = SPBSStatus;
          this.checkDropdownValue();
          this.checkhandleSProceedBtn();
          $("#choose_SPBS").show();
          $("#X-btn_SPBS").hide();
          $("#X-btn_SPBS").attr("disabled", false);
          $("#viewDocLink_SPBS").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_SPBS").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_SPBS").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handleSPBSseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SPBSseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "PreviousBS",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#SPBSseecommentsbtn").hide();
          $("#SPBShidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSPBShidecommentsbtn = () => {
    $("#SPBSseecommentsbtn").show();
    $("#SPBSseecommentsbtn").attr("disabled", false);
    $("#SPBShidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // SPR
  handleSPFRChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "onetime",
            documentName: "PreviousReturns",
          },
          () => {
            $("#SPRModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "onetime",
              documentName: "PreviousReturns",
            });
          }
        });
      $("#SPRModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSPR = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_SPR").attr("disabled", true);
      $("#upload_btn20_SPR").hide();
      $("#upload_btnloader_SPR").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            if (SOptReview == "Yes") {
              SOptReview = "No";
            }
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  SPRDocLink: res.data.data.PreviousReturns,
                },
                () => $("#viewDocLink_SPR").css("display", "block")
              );
              SPRStatus = "remove";
              SOptStatusArray[1].status = SPRStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#SPRModal").modal("hide");
              $("#cancelbtn_SPR").attr("disabled", false);
              $("#upload_btn20_SPR").show();
              $("#upload_btnloader_SPR").hide();
              $("#choose_SPR").hide();
              $("#X-btn_SPR").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_SPR").attr("disabled", false);
            $("#upload_btn20_SPR").show();
            $("#upload_btnloader_SPR").hide();
            $("#SPRModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_SPR").attr("disabled", false);
            $("#upload_btn20_SPR").show();
            $("#upload_btnloader_SPR").hide();
            $("#SPRModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_SPR").attr("disabled", false);
          $("#upload_btn20_SPR").show();
          $("#upload_btnloader_SPR").hide();
          $("#SPRModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSPR = () => {
    $("#X-btn_SPR").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "onetime",
          documentName: "PreviousReturns",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              SPRStatus = res.data.data.status;
              SOptStatusArray[1].status = SPRStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#choose_SPR").show();
              $("#X-btn_SPR").hide();
              $("#X-btn_SPR").attr("disabled", false);
              $("#viewDocLink_SPR").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_SPR").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_SPR").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handleSPRseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SPRseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "PreviousReturns",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#SPRseecommentsbtn").hide();
          $("#SPRhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSPRhidecommentsbtn = () => {
    $("#SPRseecommentsbtn").show();
    $("#SPRseecommentsbtn").attr("disabled", false);
    $("#SPRhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  //SF16
  handleSF16Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "Form16",
          },
          () => {
            $("#SF16Modal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "Form16",
            });
          }
        });
      $("#SF16Modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSF16 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_SF16").attr("disabled", true);
      $("#upload_btn20_SF16").hide();
      $("#upload_btnloader_SF16").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            if (SOptReview == "Yes") {
              SOptReview = "No";
            }
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  SF16DocLink: res.data.data.Form16,
                },
                () => $("#viewDocLink_SF16").css("display", "block")
              );
              SF16Status = "remove";
              SOptStatusArray[2].status = SF16Status;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#SF16Modal").modal("hide");
              $("#cancelbtn_SF16").attr("disabled", false);
              $("#upload_btn20_SF16").show();
              $("#upload_btnloader_SF16").hide();
              $("#choose_SF16").hide();
              $("#X-btn_SF16").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_SF16").attr("disabled", false);
            $("#upload_btn20_SF16").show();
            $("#upload_btnloader_SF16").hide();
            $("#SF16Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_SF16").attr("disabled", false);
            $("#upload_btn20_SF16").show();
            $("#upload_btnloader_SF16").hide();
            $("#SF16Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_SF16").attr("disabled", false);
          $("#upload_btn20_SF16").show();
          $("#upload_btnloader_SF16").hide();
          $("#SF16Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSF16 = () => {
    $("#X-btn_SF16").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "general",
          documentName: "Form16",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              SF16Status = res.data.data.status;
              SOptStatusArray[2].status = SF16Status;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#choose_SF16").show();
              $("#X-btn_SF16").hide();
              $("#X-btn_SF16").attr("disabled", false);
              $("#viewDocLink_SF16").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_SF16").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_SF16").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handleSF16seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SF16seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "Form16",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#SF16seecommentsbtn").hide();
          $("#SF16hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSF16hidecommentsbtn = () => {
    $("#SF16seecommentsbtn").show();
    $("#SF16seecommentsbtn").attr("disabled", false);
    $("#SF16hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  //SBankS
  handleSBankSChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "BankStatement",
          },
          () => {
            $("#SBankSModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "BankStatement",
            });
          }
        });
      $("#SBankSModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSBankS = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_SBankS").attr("disabled", true);
      $("#upload_btn20_SBankS").hide();
      $("#upload_btnloader_SBankS").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            if (SOptReview == "Yes") {
              SOptReview = "No";
            }
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  SBankSDocLink: res.data.data.BankStatement,
                },
                () => $("#viewDocLink_SBankS").css("display", "block")
              );
              SBankSStatus = "remove";
              SOptStatusArray[3].status = SBankSStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#SBankSModal").modal("hide");
              $("#cancelbtn_SBankS").attr("disabled", false);
              $("#upload_btn20_SBankS").show();
              $("#upload_btnloader_SBankS").hide();
              $("#choose_SBankS").hide();
              $("#X-btn_SBankS").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_SBankS").attr("disabled", false);
            $("#upload_btn20_SBankS").show();
            $("#upload_btnloader_SBankS").hide();
            $("#SBankSModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_SBankS").attr("disabled", false);
            $("#upload_btn20_SBankS").show();
            $("#upload_btnloader_SBankS").hide();
            $("#SBankSModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_SBankS").attr("disabled", false);
          $("#upload_btn20_SBankS").show();
          $("#upload_btnloader_SBankS").hide();
          $("#SBankSModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSBankS = () => {
    $("#X-btn_SBankS").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "general",
          documentName: "BankStatement",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              SBankSStatus = res.data.data.status;
              SOptStatusArray[3].status = SBankSStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#choose_SBankS").show();
              $("#X-btn_SBankS").hide();
              $("#X-btn_SBankS").attr("disabled", false);
              $("#viewDocLink_SBankS").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_SBankS").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_SBankS").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handleSBankSseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SBankSseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "BankStatement",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#SBankSseecommentsbtn").hide();
          $("#SBankShidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSBankShidecommentsbtn = () => {
    $("#SBankSseecommentsbtn").show();
    $("#SBankSseecommentsbtn").attr("disabled", false);
    $("#SBankShidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // SincD
  handleAddSincDFileChange = (e, index, status) => {
    SincDadditionalReuploadIndex = index;
    SincDadditionalReuploadStatus = status;
    $(".upload_btn20_6").show();
    $(".upload_btnloader_6").hide();
    const file = e.target.files[0];
    if (
      !SincDAdditionalFilesNames.includes(this.state.SincDAdd_DocName.trim())
    ) {
      const myfile = e.target.files;
      if (ChooseNowBtnsDisable == "Yes") {
        this.showNotification(
          "Files cannot be uploaded when the service is locked!"
        );
      } else if (myfile.length == 1) {
        const file = myfile[0];
        if (file.type.match("audio.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.type.match("video.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.size > 104857600) {
          this.showNotification("File too big!");
        } else if (file.name.length > 100) {
          this.showNotification("File name limit exceed!");
        } else {
          this.setState(
            {
              fileName: file.name,
              fileBase64: file,
              documentType: "incd",
              documentName: this.state.SincDAdd_DocName,
            },
            () => {
              $(".SincDAddModal").modal({
                backdrop: "static",
                keyboard: false,
              });
            }
          );
        }
      } else if (myfile.length > 1) {
        var zip = new JSZip();
        for (let i = 0; i < myfile.length; i++) {
          zip.file(myfile[i].name, myfile[i], {
            base64: true,
          });
        }
        zip
          .generateAsync({
            type: "blob",
          })
          .then((content) => {
            if (content.size > 104857600) {
              this.showNotification("File too big!");
            } else {
              this.setState({
                fileName: "download.zip",
                fileBase64: content,
                documentType: "incd",
                documentName: this.state.SincDAdd_DocName,
              });
            }
          });
        $(".SincDAddModal").modal({
          backdrop: "static",
          keyboard: false,
        });
      } else {
        this.showNotification("File cannot be null!");
      }
    } else {
      this.showNotification(
        "Document names can not be the same, Try again with different name!"
      );
    }
  };

  uploadSincDFile = (FileIndex, FileStatus) => {
    let AdditionaldocumentName;
    if (FileStatus == "reupload") {
      $(".proceednowbtn").attr("disabled", true);
      let fileTobeUploaded = this.state.SincDAdditionalFiles[FileIndex];
      AdditionaldocumentName = Object.keys(fileTobeUploaded)[0];
      mainaction = "reupload";
    } else {
      AdditionaldocumentName = this.state.documentName;
      mainaction = "upload";
    }
    if (AdditionaldocumentName.trim() == "") {
      $(".SincDAddModal").modal("hide");
      this.showNotification("Enter document Name first");
    } else if (this.state.comment.length <= 250) {
      $("#cancelbtn_6").attr("disabled", true);
      $(".upload_btn20_6").hide();
      $(".upload_btnloader_6").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", AdditionaldocumentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            $(".SincDAddModal").modal("hide");
            $(`#${FileIndex}SincDFileseecommentsbtn`).attr("disabled", false);
            if (SincDadditionalReupload == false) {
              SincDAdditionalFilesNames.push(this.state.SincDAdd_DocName);
              this.state.SincDAdditionalFiles.pop();
              this.setState(
                {
                  comment: "",
                  SincDAdditionalFiles: [
                    ...this.state.SincDAdditionalFiles,
                    res.data.data,
                  ],
                },
                () => {
                  this.setState(
                    {
                      api_SincDAdditionalFiles: "Yes",
                      ProceedDisabled: false,
                    },
                    () => {
                      this.SincDAdditionalFilesArea();
                      this.checkDropdownValue();
                      this.checkhandleSProceedBtn();
                    }
                  );
                  $(".SincDadditionalInput").attr("disabled", true);
                }
              );
            } else if (SincDadditionalReupload == true) {
              var array = [...this.state.SincDAdditionalFiles];
              array.splice(FileIndex, 1, res.data.data);
              this.setState(
                {
                  comment: "",
                  api_SincDAdditionalFiles: "Yes",
                  SincDAdditionalFiles: array,
                  ProceedDisabled: false,
                },
                () => {
                  this.SincDAdditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleSProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_6").attr("disabled", false);
            $(".upload_btn20_6").show();
            $(".upload_btnloader_6").hide();
            $(".SincDAddModal").modal("hide");
            this.showNotification("Something Went Wrong!");
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_6").attr("disabled", false);
            $(".upload_btn20_6").show();
            $(".upload_btnloader_6").hide();
            $(".SincDAddModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_6").attr("disabled", false);
          $(".upload_btn20_6").show();
          $(".upload_btnloader_6").hide();
          $(".SincDAddModal").modal("hide");
          this.showNotification("Something went wrong, Try again!");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSincDFile = (index) => {
    $("#X-btn_6").attr("disabled", true);
    let fileTobeDeleted = this.state.SincDAdditionalFiles[index];
    let NameOfAddFile = Object.keys(fileTobeDeleted)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "incd",
          documentName: NameOfAddFile,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          var array = [...this.state.SincDAdditionalFiles];
          if (res.data.data.status == "reupload") {
            array[index].status = "reupload";
            this.setState(
              {
                SincDAdditionalFiles: array,
              },
              () => {
                this.SincDAdditionalFilesArea();
                this.checkhandleSProceedBtn();
              }
            );
            this.checkDropdownValue();
            this.showNotification(res.data.message);
          } else {
            array.splice(index, 1);
            if (array.length > 0) {
              this.setState(
                {
                  SincDAdditionalFiles: array,
                },
                () => {
                  this.SincDAdditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleSProceedBtn();
                }
              );
            } else if (array.length == 0) {
              this.setState(
                {
                  SincDAdditionalFiles: [],
                  SincDAdd_DocName: "",
                  api_SincDAdditionalFiles: "No",
                  IsSincDAddFileDisabled: true,
                },
                () => {
                  this.SincDAdditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleSProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_6").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSincDFileseeComments = (index) => {
    // $(".seecommentsbtn").attr("disabled", false);
    $(".commentsbtn").attr("disabled", false);
    $(`#${index}SincDFileseecommentsbtn`).attr("disabled", true);
    let SelectedFile = this.state.SincDAdditionalFiles[index];
    let NameOfAddFile = Object.keys(SelectedFile)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "incd",
          documentName: NameOfAddFile,
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(`#${index}SincDFileseecommentsbtn`).hide();
          $(".hidecommentsbtn").hide();
          $(`#${index}SincDFilehidecommentsbtn`).show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSincDFilehideComments = (index) => {
    $(".seecommentsbtn").show();
    $(`#${index}SincDFileseecommentsbtn`).show();
    $(`#${index}SincDFileseecommentsbtn`).attr("disabled", false);
    $(`#${index}SincDFilehidecommentsbtn`).hide();
    $(".hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  checkAddCommentSincDFiles = (FileIndex, isDisabled) => {
    if (isDisabled == true) {
      return (
        <>
          <button
            className="seecommentsbtn disabledBtn_cmt SincDFileseecommentsbtn"
            id={`${FileIndex}SincDFileseecommentsbtn`}
            disabled
            onClick={() => this.handleSincDFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn SincDFilehidecommentsbtn"
            id={`${FileIndex}SincDFilehidecommentsbtn`}
            onClick={() => this.handleSincDFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    } else if (isDisabled == false) {
      return (
        <>
          <button
            className="seecommentsbtn commentsbtn SincDFileseecommentsbtn"
            id={`${FileIndex}SincDFileseecommentsbtn`}
            onClick={() => this.handleSincDFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn SincDFilehidecommentsbtn"
            id={`${FileIndex}SincDFilehidecommentsbtn`}
            onClick={() => this.handleSincDFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    }
  };

  checkStatusSincDAddFiles = (status, link, index) => {
    SincDAdditionalFilesIndex = index;
    if (ChooseNowBtnsDisable == "Yes") {
      $(".proceednowbtn").hide();
      if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_6">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_6"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_6">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_6"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      }
    } else {
      if (status == undefined) {
        status = "open";
      }
      if (status == "open") {
        mainaction = "upload";
        SincDadditionalReupload = true;
        $(".proceednowbtn1").show();
        return (
          <>
            <label
              htmlFor={`ul_file_SincD${index}`}
              className="choose_btn choose_6"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              name="file[]"
              className="upload-photo"
              id={`ul_file_SincD${index}`}
              // disabled={this.state.IsSincDAddFileDisabled}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handleAddSincDFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "remove") {
        mainaction = "upload";
        $(".proceednowbtn1").show();
        return (
          <>
            <button
              className="X-btn X-btn_6"
              onClick={() => this.removeSincDFile(index)}
            >
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_1025.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_6"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_6">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_6"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "reupload") {
        SincDadditionalReupload = true;
        mainaction = "reupload";
        $(".proceednowbtn1").show();
        $(".proceednowbtn").attr("disabled", true);
        return (
          <>
            <label
              htmlFor={`ul_file_SincD${index}`}
              className="choose_btn choose_6"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              name="file[]"
              className="upload-photo"
              id={`ul_file_SincD${index}`}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handleAddSincDFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_6">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_6"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      }
    }
  };

  AdditionalSincDFileDisabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        maxLength="150"
        className="uploadtextfield marginzero SincDadditionalInput"
        value={NameOfAddFile}
        disabled
        onChange={(e) => this.handleSincDAdd_DocNameChange(e)}
      />
    );
  };

  AdditionalSincDFileEnabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero SincDadditionalInput"
        value={NameOfAddFile}
        onChange={(e) => this.handleSincDAdd_DocNameChange(e)}
      />
    );
  };

  SincDAdditionalFilesArea = () => {
    SincDAdditionalFilesNames = [];
    if (this.state.api_SincDAdditionalFiles == "Yes") {
      return this.state.SincDAdditionalFiles.map((file, index) => {
        let NameOfAddFile = Object.keys(file)[0];
        SincDAdditionalFilesNames.push(NameOfAddFile);
        let link = file[Object.keys(file)[0]];
        let AdditionalFileInputField;
        let commentDisabled;
        if (file.status == "open" || file.status == undefined) {
          AdditionalFileInputField =
            this.AdditionalSincDFileEnabledField(NameOfAddFile);
          commentDisabled = true;
        } else if (file.status != "open") {
          AdditionalFileInputField =
            this.AdditionalSincDFileDisabledField(NameOfAddFile);
          commentDisabled = false;
        }
        return (
          <div className="displayflex" key={index}>
            <div className="uploadnocol">
              <p className="uploadadditionalsidetext">{index + 1}&#41;</p>
            </div>
            <div className="uploadadditionaldatacol">
              <div className="row mb-2">
                <div className="col-md-5">{AdditionalFileInputField}</div>
                <div className="col-md-7">
                  <div className="float-md-right float-left">
                    {this.checkAddCommentSincDFiles(index, commentDisabled)}
                    {this.checkStatusSincDAddFiles(file.status, link, index)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.api_SincDAdditionalFiles == "No") {
      return (
        <div className="displayflex">
          <div className="uploadnocol">
            <p className="uploadadditionalsidetext">1&#41;</p>
          </div>
          <div className="uploadadditionaldatacol">
            <div className="row mb-2">
              <div className="col-md-5">
                <input
                  type="text"
                  placeholder="Document name..."
                  maxLength="150"
                  className="uploadtextfield marginzero"
                  value={this.state.SincDAdd_DocName}
                  onChange={(e) => this.handleSincDAdd_DocNameChange(e)}
                />
              </div>
              <div className="col-md-7">
                <div className="fl_right">
                  {this.checkAddCommentSincDFiles(0, true)}
                  {this.checkStatusSincDAddFiles("open", null, 0)}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  handleSincDAdd_DocNameChange = (e) => {
    this.setState(
      {
        SincDAdd_DocName: e.target.value,
      },
      () => {
        if (this.state.SincDAdd_DocName.trim().length > 0) {
          this.setState({
            IsSincDAddFileDisabled: false,
          });
        } else {
          this.setState({
            IsSincDAddFileDisabled: true,
          });
        }
      }
    );
  };

  handleSincDAddfileBtn = (FileIndex) => {
    const { SincDAdd_DocName, SincDAdditionalFiles } = this.state;

    let prevFile = SincDAdditionalFiles[FileIndex];
    let NameOfPrevFile = Object.keys(prevFile)[0];
    let valueOffile = prevFile[Object.keys(prevFile)[0]];

    if (FileIndex == undefined) {
      this.showNotification("empty fields");
    } else if (SincDAdd_DocName.trim() == "") {
      this.showNotification("Required fields cannot be empty!");
    } else if (valueOffile == null) {
      this.showNotification("Choose a file");
    } else {
      this.setState(
        {
          SincDAdditionalFiles: [...this.state.SincDAdditionalFiles, ""],
          SincDAdd_DocName: "",
          IsSincDAddFileDisabled: true,
        },
        () => {
          $(".SincDadditionalInput").attr("disabled", false);
        }
      );
    }
  };

  // Sadditional
  handleSAddFileChange = (e, index, status) => {
    SadditionalReuploadIndex = index;
    SadditionalReuploadStatus = status;
    $(".upload_btn20_7").show();
    $(".upload_btnloader_7").hide();
    const file = e.target.files[0];
    if (!SAdditionalFilesNames.includes(this.state.SAdd_DocName.trim())) {
      const myfile = e.target.files;
      if (ChooseNowBtnsDisable == "Yes") {
        this.showNotification(
          "Files cannot be uploaded when the service is locked!"
        );
      } else if (myfile.length == 1) {
        const file = myfile[0];
        if (file.type.match("audio.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.type.match("video.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.size > 104857600) {
          this.showNotification("File too big!");
        } else if (file.name.length > 100) {
          this.showNotification("File name limit exceed!");
        } else {
          this.setState(
            {
              fileName: file.name,
              fileBase64: file,
              documentType: "additional",
              documentName: this.state.SAdd_DocName,
            },
            () => {
              $(".SAddModal").modal({
                backdrop: "static",
                keyboard: false,
              });
            }
          );
        }
      } else if (myfile.length > 1) {
        var zip = new JSZip();
        for (let i = 0; i < myfile.length; i++) {
          zip.file(myfile[i].name, myfile[i], {
            base64: true,
          });
        }
        zip
          .generateAsync({
            type: "blob",
          })
          .then((content) => {
            if (content.size > 104857600) {
              this.showNotification("File too big!");
            } else {
              this.setState({
                fileName: "download.zip",
                fileBase64: content,
                documentType: "additional",
                documentName: this.state.SAdd_DocName,
              });
            }
          });
        $(".SAddModal").modal({
          backdrop: "static",
          keyboard: false,
        });
      } else {
        this.showNotification("File cannot be null!");
      }
    } else {
      this.showNotification(
        "Document names can not be the same, Try again with different name!"
      );
    }
  };

  uploadSFile = (FileIndex, FileStatus) => {
    let AdditionaldocumentName1;
    if (FileStatus == "reupload") {
      $(".proceednowbtn").attr("disabled", true);
      let fileTobeUploaded = this.state.SAdditionalFiles[FileIndex];
      AdditionaldocumentName1 = Object.keys(fileTobeUploaded)[0];
      mainaction = "reupload";
    } else {
      AdditionaldocumentName1 = this.state.documentName;
      mainaction = "upload";
    }
    if (AdditionaldocumentName1.trim() == "") {
      $(".SAddModal").modal("hide");
      this.showNotification("Enter document Name first");
    } else if (this.state.comment.length <= 250) {
      $(".cancelbtn_7").attr("disabled", true);
      $(".upload_btn20_7").hide();
      $(".upload_btnloader_7").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", AdditionaldocumentName1);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            $(".SAddModal").modal("hide");
            $(".cancelbtn_7").attr("disabled", false);
            $(`#${FileIndex}SFileseecommentsbtn`).attr("disabled", false);
            if (SadditionalReupload == false) {
              SAdditionalFilesNames.push(this.state.SAdd_DocName);
              this.state.SAdditionalFiles.pop();
              this.setState(
                {
                  comment: "",
                  SAdditionalFiles: [
                    ...this.state.SAdditionalFiles,
                    res.data.data,
                  ],
                },
                () => {
                  this.setState(
                    {
                      api_SAdditionalFiles: "Yes",
                      ProceedDisabled: false,
                    },
                    () => {
                      this.SAdditionalFilesArea();
                      this.checkhandleSProceedBtn();
                    }
                  );
                  this.checkDropdownValue();
                  $(".SadditionalInput").attr("disabled", true);
                }
              );
            } else if (SadditionalReupload == true) {
              var array = [...this.state.SAdditionalFiles];
              array.splice(FileIndex, 1, res.data.data);
              this.setState(
                {
                  comment: "",
                  api_SAdditionalFiles: "Yes",
                  SAdditionalFiles: array,
                  ProceedDisabled: false,
                },
                () => {
                  this.SAdditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleSProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".SAddModal").modal("hide");
            this.showNotification("Something Went Wrong!");
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".SAddModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $(".cancelbtn_7").attr("disabled", false);
          $("#upload_btn20_7").show();
          $("#upload_btnloader_7").hide();
          $(".SAddModal").modal("hide");
          this.showNotification("Something went wrong, Try again!");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSFile = (index) => {
    $("#X-btn_7").attr("disabled", true);
    let fileTobeDeleted = this.state.SAdditionalFiles[index];
    let NameOfAddFile = Object.keys(fileTobeDeleted)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "additional",
          documentName: NameOfAddFile,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          var array = [...this.state.SAdditionalFiles];
          if (res.data.data.status == "reupload") {
            array[index].status = "reupload";
            this.setState(
              {
                SAdditionalFiles: array,
              },
              () => {
                this.SAdditionalFilesArea();
                this.checkDropdownValue();
                this.checkhandleSProceedBtn();
              }
            );
            this.showNotification(res.data.message);
          } else {
            array.splice(index, 1);
            if (array.length > 0) {
              this.setState(
                {
                  SAdditionalFiles: array,
                },
                () => {
                  this.SAdditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleSProceedBtn();
                }
              );
            } else if (array.length == 0) {
              this.setState(
                {
                  SAdditionalFiles: [],
                  SAdd_DocName: "",
                  api_SAdditionalFiles: "No",
                  IsSAddFileDisabled: true,
                },
                () => {
                  this.SAdditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleSProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_7").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSFileseeComments = (index) => {
    // $(".seecommentsbtn").attr("disabled", false);
    $(".commentsbtn").attr("disabled", false);
    $(`#${index}SFileseecommentsbtn`).attr("disabled", true);
    let SelectedFile = this.state.SAdditionalFiles[index];
    let NameOfAddFile = Object.keys(SelectedFile)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "additional",
          documentName: NameOfAddFile,
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(`#${index}SFileseecommentsbtn`).hide();
          $(".hidecommentsbtn").hide();
          $(`#${index}SFilehidecommentsbtn`).show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSFilehideComments = (index) => {
    $(".seecommentsbtn").show();
    $(`#${index}SFileseecommentsbtn`).show();
    $(`#${index}SFileseecommentsbtn`).attr("disabled", false);
    $(`#${index}SFilehidecommentsbtn`).hide();
    $(".hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  checkAddCommentSFiles = (FileIndex, isDisabled) => {
    if (isDisabled == true) {
      return (
        <>
          <button
            className="seecommentsbtn disabledBtn_cmt SFileseecommentsbtn"
            id={`${FileIndex}SFileseecommentsbtn`}
            disabled
            onClick={() => this.handleSFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn SFilehidecommentsbtn"
            id={`${FileIndex}SFilehidecommentsbtn`}
            onClick={() => this.handleSFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    } else if (isDisabled == false) {
      return (
        <>
          <button
            className="seecommentsbtn commentsbtn SFileseecommentsbtn"
            id={`${FileIndex}SFileseecommentsbtn`}
            onClick={() => this.handleSFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn SFilehidecommentsbtn"
            id={`${FileIndex}SFilehidecommentsbtn`}
            onClick={() => this.handleSFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    }
  };

  checkStatusSAddFiles = (status, link, index) => {
    SAdditionalFilesIndex = index;
    if (ChooseNowBtnsDisable == "Yes") {
      $(".proceednowbtn").hide();

      if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      }
    } else {
      if (status == undefined) {
        status = "open";
      }
      if (status == "open") {
        mainaction = "upload";
        SadditionalReupload = true;
        $(".proceednowbtn1").show();
        return (
          <>
            <label
              htmlFor={`ul_file_SAdd${index}`}
              className="choose_btn choose_Sadd"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              name="file[]"
              className="upload-photo"
              id={`ul_file_SAdd${index}`}
              // disabled={this.state.IsSAddFileDisabled}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handleSAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "remove") {
        mainaction = "upload";
        $(".proceednowbtn1").show();
        return (
          <>
            <button
              className="X-btn X-btn_7"
              onClick={() => this.removeSFile(index)}
            >
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_1025.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "reupload") {
        SadditionalReupload = true;
        mainaction = "reupload";
        $(".proceednowbtn1").show();
        $(".proceednowbtn").attr("disabled", true);
        return (
          <>
            <label
              htmlFor={`ul_file_SAdd${index}`}
              className="choose_btn choose_Sadd"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              name="file[]"
              className="upload-photo"
              id={`ul_file_SAdd${index}`}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handleSAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      }
    }
  };

  AdditionalSFileDisabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero SadditionalInput"
        value={NameOfAddFile}
        disabled
        onChange={(e) => this.handleSAdd_DocNameChange(e)}
      />
    );
  };

  AdditionalSFileEnabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero SadditionalInput"
        value={NameOfAddFile}
        onChange={(e) => this.handleSAdd_DocNameChange(e)}
      />
    );
  };
  SAdditionalFilesArea = () => {
    SAdditionalFilesNames = [];
    if (this.state.api_SAdditionalFiles == "Yes") {
      return this.state.SAdditionalFiles.map((file, index) => {
        let NameOfAddFile = Object.keys(file)[0];
        SAdditionalFilesNames.push(NameOfAddFile);
        let link = file[Object.keys(file)[0]];
        let AdditionalFileInputField;
        let commentDisabled;
        if (file.status == "open" || file.status == undefined) {
          AdditionalFileInputField =
            this.AdditionalSFileEnabledField(NameOfAddFile);
          commentDisabled = true;
        } else if (file.status != "open") {
          AdditionalFileInputField =
            this.AdditionalSFileDisabledField(NameOfAddFile);
          commentDisabled = false;
        }
        return (
          <div className="displayflex" key={index}>
            <div className="uploadnocol">
              <p className="uploadadditionalsidetext">{index + 1}&#41;</p>
            </div>
            <div className="uploadadditionaldatacol">
              <div className="row mb-2">
                <div className="col-md-5">{AdditionalFileInputField}</div>
                <div className="col-md-7">
                  <div className="float-md-right float-left">
                    {this.checkAddCommentSFiles(index, commentDisabled)}
                    {this.checkStatusSAddFiles(file.status, link, index)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.api_SAdditionalFiles == "No") {
      return (
        <div className="displayflex">
          <div className="uploadnocol">
            <p className="uploadadditionalsidetext">1&#41;</p>
          </div>
          <div className="uploadadditionaldatacol">
            <div className="row mb-2">
              <div className="col-md-5">
                <input
                  type="text"
                  placeholder="Document name..."
                  maxLength="150"
                  className="uploadtextfield marginzero"
                  value={this.state.SAdd_DocName}
                  onChange={(e) => this.handleSAdd_DocNameChange(e)}
                />
              </div>
              <div className="col-md-7">
                <div className="fl_right">
                  {this.checkAddCommentSFiles(0, true)}
                  {this.checkStatusSAddFiles("open", null, 0)}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  handleSAdd_DocNameChange = (e) => {
    this.setState(
      {
        SAdd_DocName: e.target.value,
      },
      () => {
        if (this.state.SAdd_DocName.trim().length > 0) {
          this.setState({
            IsSAddFileDisabled: false,
          });
        } else {
          this.setState({
            IsSAddFileDisabled: true,
          });
        }
      }
    );
  };

  handleAddSfileBtn = (FileIndex) => {
    const { SAdd_DocName, SAdditionalFiles } = this.state;

    let prevFile = SAdditionalFiles[FileIndex];
    let NameOfPrevFile = Object.keys(prevFile)[0];
    let valueOffile = prevFile[Object.keys(prevFile)[0]];

    if (FileIndex == undefined) {
      this.showNotification("empty fields");
    } else if (SAdd_DocName.trim() == "") {
      this.showNotification("Required fields cannot be empty!");
    } else if (valueOffile == null) {
      this.showNotification("Choose a file");
    } else {
      this.setState(
        {
          SAdditionalFiles: [...this.state.SAdditionalFiles, ""],
          SAdd_DocName: "",
          IsSAddFileDisabled: true,
        },
        () => {
          $(".SadditionalInput").attr("disabled", false);
        }
      );
    }
  };

  ScheckRemainingReuploads = () => {
    if (
      SAadharStatus != "reupload" &&
      SPanStatus != "reupload" &&
      SBpStatus != "reupload" &&
      SPBSStatus != "reupload" &&
      SPRStatus != "reupload" &&
      SF16Status != "reupload" &&
      anySincDAdditionalReuploadRemaining != "Yes" &&
      anySAdditionalReuploadRemaining != "Yes"
    ) {
      AnyReuploadOccuredS = "No";
    } else {
      $(".proceednowbtn1").show();
      AnyReuploadOccuredS = "Yes";
    }
  };

  checkhandleSProceedBtn = () => {
    let statusCheck = this.statusCheckFunction("reupload", SStatusArray);
    SOptReupload = this.countOptReupload1("reupload", SOptStatusArray);
    SincDadditionalStatusArrayCheck = this.statusCheckFunction(
      "reupload",
      this.state.SincDAdditionalFiles
    );
    SadditionalStatusArrayCheck = this.statusCheckFunction(
      "reupload",
      this.state.SAdditionalFiles
    );

    if (rejectstatus == "Yes") {
      $(".proceednowbtn1").show();
      $(".proceednowbtn1").attr("disabled", false);
      Sproceednowforword = "success";
    } else if (
      AnyReuploadOccuredS == "Yes" &&
      ((SOptReview == "No" && SOptReupload != OptReuploadCountS) ||
        SOptReview == "Yes") &&
      statusCheck == undefined &&
      SincDadditionalStatusArrayCheck == undefined &&
      SadditionalStatusArrayCheck == undefined
    ) {
      $(".proceednowbtn1").attr("disabled", false);
      Sproceednowforword = "success";
    } else if (
      SAadharStatus == "remove" &&
      SPanStatus == "remove" &&
      SBpStatus == "remove" &&
      SincDadditionalStatusArrayCheck == undefined &&
      SadditionalStatusArrayCheck == undefined
    ) {
      $(".proceednowbtn1").attr("disabled", false);
      Sproceednowforword = "success";
    } else if (
      SAadharStatus == "review" &&
      SPanStatus == "review" &&
      SBpStatus == "review" &&
      SincDadditionalStatusArrayCheck == undefined &&
      SadditionalStatusArrayCheck == undefined
    ) {
      $(".proceednowbtn1").attr("disabled", false);
      Sproceednowforword = "success";
    } else if (
      SAadharStatus == "done" &&
      SPanStatus == "done" &&
      SBpStatus == "done" &&
      SincDadditionalStatusArrayCheck == undefined &&
      SadditionalStatusArrayCheck == undefined
    ) {
      $(".proceednowbtn1").attr("disabled", false);
      Sproceednowforword = "success";
    } else {
      Sproceednowforword = "fail";
      $(".proceednowbtn1").attr("disabled", true);
    }
  };

  handleSProceedBtn = () => {
    this.checkhandleSProceedBtn();
    if (Sproceednowforword == "success") {
      if (
        this.state.itrfusername.trim().length < 96 &&
        this.state.itrfpassword.trim().length < 96
      ) {
        axios
          .post(
            process.env.REACT_APP_API_BASE_URL + "p=lockService",
            {
              userId: Decuserid,
              requestType: temp,
              agentId: Decagentid,

              serviceId: Decserviceid,
              serviceName: Decservicename,
              serviceType: this.state.serviceType,
              FY: this.state.SelectedYear,
              Month: "",
              inputFields: [
                {
                  inputFieldName: "PortalUserID",
                  inputFieldValue: this.state.itrfusername.trim(),
                  inputFieldtype: "onetime",
                },
                {
                  inputFieldName: "PortalPassword",
                  inputFieldValue: this.state.itrfpassword.trim(),
                  inputFieldtype: "onetime",
                },
              ],
            },
            {
              headers: {
                Authkey: process.env.REACT_APP_API_KEY,
                "App-Token-Access": Decjwttoken,
              },
              auth: {
                username: process.env.REACT_APP_API_USERNAME,
                password: process.env.REACT_APP_API_PASSWORD,
              },
            }
          )
          .then((res) => {
            if (res.data.code == "200" && res.data.status == "success") {
              this.showNotification(res.data.message);
              this.getserviceInformationApi();
              this.getdeliveredDocuments();
            } else if (
              res.data.code == "201" &&
              res.data.status == "rejected"
            ) {
              this.showNotification(res.data.message);
              this.getserviceInformationApi();
              this.getdeliveredDocuments();
            } else if (
              res.data.code == "201" &&
              res.data.status == "invalidauth"
            ) {
              this.logout();
            } else if (res.data.code == "201" && res.data.status == "timeOut") {
              localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
              this.props.history.push({
                pathname: "/dashboard",
                state: {
                  timeout: true,
                },
              });
            } else {
              this.showNotification(res.data.message);
            }
          })
          .catch((err) => {});
      } else if (this.state.itrfusername.trim().length > 95) {
        this.showNotification("Max length exceed!");
      } else if (this.state.itrfpassword.trim().length > 95) {
        this.showNotification("Max length exceed!");
      } else {
        this.showNotification("Required fields are empty!");
      }
    } else {
      this.showNotification("Required fields are empty!");
    }
  };

  // PROPRIETOR SECTION

  // aadhar
  handlePAadharChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "onetime",
            documentName: "AadhaarCard",
          },
          () => {
            $("#PAadharModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "onetime",
              documentName: "AadhaarCard",
            });
          }
        });
      $("#PAadharModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPAadhar = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PAadhar").attr("disabled", true);
      $("#upload_btn20_PAadhar").hide();
      $("#upload_btnloader_PAadhar").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PAadharDocLink: res.data.data.AadhaarCard,
                },
                () => $("#viewDocLink_PAadhar").css("display", "block")
              );
              PAadharStatus = "remove";
              PStatusArray[0].status = PAadharStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();

              $("#PAadharModal").modal("hide");
              $("#cancelbtn_PAadhar").attr("disabled", false);
              $("#upload_btn20_PAadhar").show();
              $("#upload_btnloader_PAadhar").hide();
              $("#choose_PAadhar").hide();
              $("#X-btn_PAadhar").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PAadhar").attr("disabled", false);
            $("#upload_btn20_PAadhar").show();
            $("#upload_btnloader_PAadhar").hide();
            $("#PAadharModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PAadhar").attr("disabled", false);
            $("#upload_btn20_PAadhar").show();
            $("#upload_btnloader_PAadhar").hide();
            $("#PAadharModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PAadhar").attr("disabled", false);
          $("#upload_btn20_PAadhar").show();
          $("#upload_btnloader_PAadhar").hide();
          $("#PAadharModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePAadhar = () => {
    $("#X-btn_PAadhar").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "onetime",
          documentName: "AadhaarCard",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              PAadharStatus = res.data.data.status;
              PStatusArray[0].status = PAadharStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#choose_PAadhar").show();
              $("#X-btn_PAadhar").hide();
              $("#X-btn_PAadhar").attr("disabled", false);
              $("#viewDocLink_PAadhar").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_PAadhar").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PAadhar").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handlePAadharseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PAadharseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "AadhaarCard",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PAadharseecommentsbtn").hide();
          $("#PAadharhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePAadharhidecommentsbtn = () => {
    $("#PAadharseecommentsbtn").show();
    $("#PAadharseecommentsbtn").attr("disabled", false);
    $("#PAadharhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PPan
  handlePPanChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "onetime",
            documentName: "PANCard",
          },
          () => {
            $("#PPanModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "onetime",
              documentName: "PANCard",
            });
          }
        });
      $("#PPanModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPPan = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PPan").attr("disabled", true);
      $("#upload_btn20_PPan").hide();
      $("#upload_btnloader_PPan").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PPanDocLink: res.data.data.PANCard,
                },
                () => $("#viewDocLink_PPan").css("display", "block")
              );
              PPanStatus = "remove";
              PStatusArray[1].status = PPanStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PPanModal").modal("hide");
              $("#cancelbtn_PPan").attr("disabled", false);
              $("#upload_btn20_PPan").show();
              $("#upload_btnloader_PPan").hide();
              $("#choose_PPan").hide();
              $("#X-btn_PPan").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PPan").attr("disabled", false);
            $("#upload_btn20_PPan").show();
            $("#upload_btnloader_PPan").hide();
            $("#PPanModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PPan").attr("disabled", false);
            $("#upload_btn20_PPan").show();
            $("#upload_btnloader_PPan").hide();
            $("#PPanModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PPan").attr("disabled", false);
          $("#upload_btn20_PPan").show();
          $("#upload_btnloader_PPan").hide();
          $("#PPanModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePPan = () => {
    $("#X-btn_PPan").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "onetime",
          documentName: "PANCard",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              PPanStatus = res.data.data.status;
              PStatusArray[1].status = PPanStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#choose_PPan").show();
              $("#X-btn_PPan").hide();
              $("#X-btn_PPan").attr("disabled", false);
              $("#viewDocLink_PPan").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_PPan").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PPan").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handlePPanseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PPanseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "PANCard",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PPanseecommentsbtn").hide();
          $("#PPanhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePPanhidecommentsbtn = () => {
    $("#PPanseecommentsbtn").show();
    $("#PPanseecommentsbtn").attr("disabled", false);
    $("#PPanhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PBP
  handlePBPChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "onetime",
            documentName: "BankProof",
          },
          () => {
            $("#PBPModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "onetime",
              documentName: "BankProof",
            });
          }
        });
      $("#PBPModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPBP = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PBP").attr("disabled", true);
      $("#upload_btn20_PBP").hide();
      $("#upload_btnloader_PBP").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PBPDocLink: res.data.data.BankProof,
                },
                () => $("#viewDocLink_PBP").css("display", "block")
              );
              PBPStatus = "remove";
              PStatusArray[2].status = PBPStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PBPModal").modal("hide");
              $("#cancelbtn_PBP").attr("disabled", false);
              $("#upload_btn20_PBP").show();
              $("#upload_btnloader_PBP").hide();
              $("#choose_PBP").hide();
              $("#X-btn_PBP").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PBP").attr("disabled", false);
            $("#upload_btn20_PBP").show();
            $("#upload_btnloader_PBP").hide();
            $("#PBPModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PBP").attr("disabled", false);
            $("#upload_btn20_PBP").show();
            $("#upload_btnloader_PBP").hide();
            $("#PBPModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PBP").attr("disabled", false);
          $("#upload_btn20_PBP").show();
          $("#upload_btnloader_PBP").hide();
          $("#PBPModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePBP = () => {
    $("#X-btn_PBP").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "onetime",
          documentName: "BankProof",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              PBPStatus = res.data.data.status;
              PStatusArray[2].status = PBPStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#choose_PBP").show();
              $("#X-btn_PBP").hide();
              $("#X-btn_PBP").attr("disabled", false);
              $("#viewDocLink_PBP").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_PBP").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PBP").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handlePBPseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PBPseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "BankProof",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PBPseecommentsbtn").hide();
          $("#PBPhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePBPhidecommentsbtn = () => {
    $("#PBPseecommentsbtn").show();
    $("#PBPseecommentsbtn").attr("disabled", false);
    $("#PBPhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  //PBS
  handlePBSChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "onetime",
            documentName: "PreviousBS_Audit",
          },
          () => {
            $("#PBSModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "onetime",
              documentName: "PreviousBS_Audit",
            });
          }
        });
      $("#PBSModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPBS = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PBS").attr("disabled", true);
      $("#upload_btn20_PBS").hide();
      $("#upload_btnloader_PBS").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            if (POptReview == "Yes") {
              POptReview = "No";
            }
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PBSDocLink: res.data.data.PreviousBS_Audit,
                },
                () => $("#viewDocLink_PBS").css("display", "block")
              );
              PBSStatus = "remove";
              POptStatusArray[0].status = PBSStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PBSModal").modal("hide");
              $("#cancelbtn_PBS").attr("disabled", false);
              $("#upload_btn20_PBS").show();
              $("#upload_btnloader_PBS").hide();
              $("#choose_PBS").hide();
              $("#X-btn_PBS").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PBS").attr("disabled", false);
            $("#upload_btn20_PBS").show();
            $("#upload_btnloader_PBS").hide();
            $("#PBSModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PBS").attr("disabled", false);
            $("#upload_btn20_PBS").show();
            $("#upload_btnloader_PBS").hide();
            $("#PBSModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PBS").attr("disabled", false);
          $("#upload_btn20_PBS").show();
          $("#upload_btnloader_PBS").hide();
          $("#PBSModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePBS = () => {
    $("#X-btn_PBS").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "onetime",
          documentName: "PreviousBS_Audit",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              PBSStatus = res.data.data.status;
              POptStatusArray[0].status = PBSStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#choose_PBS").show();
              $("#X-btn_PBS").hide();
              $("#X-btn_PBS").attr("disabled", false);
              $("#viewDocLink_PBS").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_PBS").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PBS").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handlePBSseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PBSseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "PreviousBS_Audit",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PBSseecommentsbtn").hide();
          $("#PBShidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePBShidecommentsbtn = () => {
    $("#PBSseecommentsbtn").show();
    $("#PBSseecommentsbtn").attr("disabled", false);
    $("#PBShidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PPR
  handlePPFRChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "onetime",
            documentName: "PreviousReturns",
          },
          () => {
            $("#PPRModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "onetime",
              documentName: "PreviousReturns",
            });
          }
        });
      $("#PPRModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPPR = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PPR").attr("disabled", true);
      $("#upload_btn20_PPR").hide();
      $("#upload_btnloader_PPR").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            if (POptReview == "Yes") {
              POptReview = "No";
            }
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PPRDocLink: res.data.data.PreviousReturns,
                },
                () => $("#viewDocLink_PPR").css("display", "block")
              );
              PPRStatus = "remove";
              POptStatusArray[1].status = PPRStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PPRModal").modal("hide");
              $("#cancelbtn_PPR").attr("disabled", false);
              $("#upload_btn20_PPR").show();
              $("#upload_btnloader_PPR").hide();
              $("#choose_PPR").hide();
              $("#X-btn_PPR").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PPR").attr("disabled", false);
            $("#upload_btn20_PPR").show();
            $("#upload_btnloader_PPR").hide();
            $("#PPRModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PPR").attr("disabled", false);
            $("#upload_btn20_PPR").show();
            $("#upload_btnloader_PPR").hide();
            $("#PPRModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PPR").attr("disabled", false);
          $("#upload_btn20_PPR").show();
          $("#upload_btnloader_PPR").hide();
          $("#PPRModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePPR = () => {
    $("#X-btn_PPR").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "onetime",
          documentName: "PreviousReturns",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              PPRStatus = res.data.data.status;
              POptStatusArray[1].status = PPRStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#choose_PPR").show();
              $("#X-btn_PPR").hide();
              $("#X-btn_PPR").attr("disabled", false);
              $("#viewDocLink_PPR").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_PPR").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PPR").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handlePPRseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PPRseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "PreviousReturns",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PPRseecommentsbtn").hide();
          $("#PPRhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePPRhidecommentsbtn = () => {
    $("#PPRseecommentsbtn").show();
    $("#PPRseecommentsbtn").attr("disabled", false);
    $("#PPRhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PincD
  handleAddPincDFileChange = (e, index, status) => {
    PincDadditionalReuploadIndex = index;
    PincDadditionalReuploadStatus = status;
    $(".upload_btn20_6").show();
    $(".upload_btnloader_6").hide();
    const file = e.target.files[0];
    if (
      !PincDAdditionalFilesNames.includes(this.state.PincDAdd_DocName.trim())
    ) {
      const myfile = e.target.files;
      if (ChooseNowBtnsDisable == "Yes") {
        this.showNotification(
          "Files cannot be uploaded when the service is locked!"
        );
      } else if (myfile.length == 1) {
        const file = myfile[0];
        if (file.type.match("audio.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.type.match("video.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.size > 104857600) {
          this.showNotification("File too big!");
        } else if (file.name.length > 100) {
          this.showNotification("File name limit exceed!");
        } else {
          this.setState(
            {
              fileName: file.name,
              fileBase64: file,
              documentType: "incd",
              documentName: this.state.PincDAdd_DocName,
            },
            () => {
              $(".PincDAddModal").modal({
                backdrop: "static",
                keyboard: false,
              });
            }
          );
        }
      } else if (myfile.length > 1) {
        var zip = new JSZip();
        for (let i = 0; i < myfile.length; i++) {
          zip.file(myfile[i].name, myfile[i], {
            base64: true,
          });
        }
        zip
          .generateAsync({
            type: "blob",
          })
          .then((content) => {
            if (content.size > 104857600) {
              this.showNotification("File too big!");
            } else {
              this.setState({
                fileName: "download.zip",
                fileBase64: content,
                documentType: "incd",
                documentName: this.state.PincDAdd_DocName,
              });
            }
          });
        $(".PincDAddModal").modal({
          backdrop: "static",
          keyboard: false,
        });
      } else {
        this.showNotification("File cannot be null!");
      }
    } else {
      this.showNotification(
        "Document names can not be the same, Try again with different name!"
      );
    }
  };

  uploadPincDFile = (FileIndex, FileStatus) => {
    let AdditionaldocumentName;
    if (FileStatus == "reupload") {
      $(".proceednowbtn").attr("disabled", true);
      let fileTobeUploaded = this.state.PincDAdditionalFiles[FileIndex];
      AdditionaldocumentName = Object.keys(fileTobeUploaded)[0];
      mainaction = "reupload";
    } else {
      AdditionaldocumentName = this.state.documentName;
      mainaction = "upload";
    }
    if (AdditionaldocumentName.trim() == "") {
      $(".PincDAddModal").modal("hide");
      this.showNotification("Enter document Name first");
    } else if (this.state.comment.length <= 250) {
      $("#cancelbtn_6").attr("disabled", true);
      $(".upload_btn20_6").hide();
      $(".upload_btnloader_6").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", AdditionaldocumentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            $(".PincDAddModal").modal("hide");
            $(`#${FileIndex}PincDFileseecommentsbtn`).attr("disabled", false);
            if (PincDadditionalReupload == false) {
              PincDAdditionalFilesNames.push(this.state.PincDAdd_DocName);
              this.state.PincDAdditionalFiles.pop();
              this.setState(
                {
                  comment: "",
                  PincDAdditionalFiles: [
                    ...this.state.PincDAdditionalFiles,
                    res.data.data,
                  ],
                },
                () => {
                  this.setState(
                    {
                      api_PincDAdditionalFiles: "Yes",
                      ProceedDisabled: false,
                    },
                    () => {
                      this.PincDAdditionalFilesArea();
                      this.checkDropdownValue();
                      this.checkhandlePProceedBtn();
                    }
                  );

                  $(".PincDadditionalInput").attr("disabled", true);
                }
              );
            } else if (PincDadditionalReupload == true) {
              var array = [...this.state.PincDAdditionalFiles];
              array.splice(FileIndex, 1, res.data.data);
              this.setState(
                {
                  comment: "",
                  api_PincDAdditionalFiles: "Yes",
                  PincDAdditionalFiles: array,
                  ProceedDisabled: false,
                },
                () => {
                  this.PincDAdditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandlePProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_6").attr("disabled", false);
            $(".upload_btn20_6").show();
            $(".upload_btnloader_6").hide();
            $(".PincDAddModal").modal("hide");
            this.showNotification("Something Went Wrong!");
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_6").attr("disabled", false);
            $(".upload_btn20_6").show();
            $(".upload_btnloader_6").hide();
            $(".PincDAddModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          this.showNotification("Something went wrong, Try again!");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePincDFile = (index) => {
    $("#X-btn_6").attr("disabled", true);
    let fileTobeDeleted = this.state.PincDAdditionalFiles[index];
    let NameOfAddFile = Object.keys(fileTobeDeleted)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "incd",
          documentName: NameOfAddFile,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          var array = [...this.state.PincDAdditionalFiles];
          if (res.data.data.status == "reupload") {
            array[index].status = "reupload";
            this.setState(
              {
                PincDAdditionalFiles: array,
              },
              () => {
                this.PincDAdditionalFilesArea();
                this.checkDropdownValue();
                this.checkhandlePProceedBtn();
              }
            );
            this.showNotification(res.data.message);
          } else {
            array.splice(index, 1);
            if (array.length > 0) {
              this.setState(
                {
                  PincDAdditionalFiles: array,
                },
                () => {
                  this.PincDAdditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandlePProceedBtn();
                }
              );
            } else if (array.length == 0) {
              this.setState(
                {
                  PincDAdditionalFiles: [],
                  PincDAdd_DocName: "",
                  api_PincDAdditionalFiles: "No",
                  IsPincDAddFileDisabled: true,
                },
                () => {
                  this.PincDAdditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandlePProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_6").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePincDFileseeComments = (index) => {
    // $(".seecommentsbtn").attr("disabled", false);
    $(".commentsbtn").attr("disabled", false);
    $(`#${index}PincDFileseecommentsbtn`).attr("disabled", true);
    let SelectedFile = this.state.PincDAdditionalFiles[index];
    let NameOfAddFile = Object.keys(SelectedFile)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "incd",
          documentName: NameOfAddFile,
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(`#${index}PincDFileseecommentsbtn`).hide();
          $(".hidecommentsbtn").hide();
          $(`#${index}PincDFilehidecommentsbtn`).show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePincDFilehideComments = (index) => {
    $(".seecommentsbtn").show();
    $(`#${index}PincDFileseecommentsbtn`).show();
    $(`#${index}PincDFileseecommentsbtn`).attr("disabled", false);
    $(`#${index}PincDFilehidecommentsbtn`).hide();
    $(".hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  checkAddCommentPincDFiles = (FileIndex, isDisabled) => {
    if (isDisabled == true) {
      return (
        <>
          <button
            className="seecommentsbtn disabledBtn_cmt PincDFileseecommentsbtn"
            id={`${FileIndex}PincDFileseecommentsbtn`}
            disabled
            onClick={() => this.handlePincDFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn PincDFilehidecommentsbtn"
            id={`${FileIndex}PincDFilehidecommentsbtn`}
            onClick={() => this.handlePincDFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    } else if (isDisabled == false) {
      return (
        <>
          <button
            className="seecommentsbtn commentsbtn PincDFileseecommentsbtn"
            id={`${FileIndex}PincDFileseecommentsbtn`}
            onClick={() => this.handlePincDFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn PincDFilehidecommentsbtn"
            id={`${FileIndex}PincDFilehidecommentsbtn`}
            onClick={() => this.handlePincDFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    }
  };

  checkStatusPincDAddFiles = (status, link, index) => {
    PincDAdditionalFilesIndex = index;
    if (ChooseNowBtnsDisable == "Yes") {
      $(".proceednowbtn").hide();

      if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_6">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_6"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_6">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_6"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      }
    } else {
      if (status == undefined) {
        status = "open";
      }
      if (status == "open") {
        mainaction = "upload";
        PincDadditionalReupload = true;
        $(".proceednowbtn2").show();
        return (
          <>
            <label
              htmlFor={`ul_file_PincD${index}`}
              className="choose_btn choose_6"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              name="file[]"
              className="upload-photo"
              id={`ul_file_PincD${index}`}
              // disabled={this.state.IsPincDAddFileDisabled}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handleAddPincDFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "remove") {
        mainaction = "upload";
        $(".proceednowbtn2").show();
        return (
          <>
            <button
              className="X-btn X-btn_6"
              onClick={() => this.removePincDFile(index)}
            >
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_1025.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_6"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_6">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_6"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "reupload") {
        PincDadditionalReupload = true;
        mainaction = "reupload";
        $(".proceednowbtn2").show();
        $(".proceednowbtn").attr("disabled", true);
        return (
          <>
            <label
              htmlFor={`ul_file_PincD${index}`}
              className="choose_btn choose_6"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              name="file[]"
              className="upload-photo"
              id={`ul_file_PincD${index}`}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handleAddPincDFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_6">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_6"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      }
    }
  };

  AdditionalPincDFileDisabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero PincDadditionalInput"
        value={NameOfAddFile}
        disabled
        onChange={(e) => this.handlePincDAdd_DocNameChange(e)}
      />
    );
  };

  AdditionalPincDFileEnabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero PincDadditionalInput"
        value={NameOfAddFile}
        onChange={(e) => this.handlePincDAdd_DocNameChange(e)}
      />
    );
  };

  PincDAdditionalFilesArea = () => {
    PincDAdditionalFilesNames = [];
    if (this.state.api_PincDAdditionalFiles == "Yes") {
      return this.state.PincDAdditionalFiles.map((file, index) => {
        let NameOfAddFile = Object.keys(file)[0];
        PincDAdditionalFilesNames.push(NameOfAddFile);
        let link = file[Object.keys(file)[0]];
        let AdditionalFileInputField;
        let commentDisabled;
        if (file.status == "open" || file.status == undefined) {
          AdditionalFileInputField =
            this.AdditionalPincDFileEnabledField(NameOfAddFile);
          commentDisabled = true;
        } else if (file.status != "open") {
          AdditionalFileInputField =
            this.AdditionalPincDFileDisabledField(NameOfAddFile);
          commentDisabled = false;
        }
        return (
          <div className="displayflex" key={index}>
            <div className="uploadnocol">
              <p className="uploadadditionalsidetext">{index + 1}&#41;</p>
            </div>
            <div className="uploadadditionaldatacol">
              <div className="row mb-2">
                <div className="col-md-5">{AdditionalFileInputField}</div>
                <div className="col-md-7">
                  <div className="float-md-right float-left">
                    {this.checkAddCommentPincDFiles(index, commentDisabled)}
                    {this.checkStatusPincDAddFiles(file.status, link, index)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.api_PincDAdditionalFiles == "No") {
      return (
        <div className="displayflex">
          <div className="uploadnocol">
            <p className="uploadadditionalsidetext">1&#41;</p>
          </div>
          <div className="uploadadditionaldatacol">
            <div className="row mb-2">
              <div className="col-md-5">
                <input
                  type="text"
                  placeholder="Document name..."
                  maxLength="150"
                  className="uploadtextfield marginzero"
                  value={this.state.PincDAdd_DocName}
                  onChange={(e) => this.handlePincDAdd_DocNameChange(e)}
                />
              </div>
              <div className="col-md-7">
                <div className="fl_right">
                  {this.checkAddCommentPincDFiles(0, true)}
                  {this.checkStatusPincDAddFiles("open", null, 0)}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  handlePincDAdd_DocNameChange = (e) => {
    this.setState(
      {
        PincDAdd_DocName: e.target.value,
      },
      () => {
        if (this.state.PincDAdd_DocName.trim().length > 0) {
          this.setState({
            IsPincDAddFileDisabled: false,
          });
        } else {
          this.setState({
            IsPincDAddFileDisabled: true,
          });
        }
      }
    );
  };

  handlePincDAddfileBtn = (FileIndex) => {
    const { PincDAdd_DocName, PincDAdditionalFiles } = this.state;

    let prevFile = PincDAdditionalFiles[FileIndex];
    let NameOfPrevFile = Object.keys(prevFile)[0];
    let valueOffile = prevFile[Object.keys(prevFile)[0]];

    if (FileIndex == undefined) {
      this.showNotification("empty fields");
    } else if (PincDAdd_DocName.trim() == "") {
      this.showNotification("Required fields cannot be empty!");
    } else if (valueOffile == null) {
      this.showNotification("Choose a file");
    } else {
      this.setState(
        {
          PincDAdditionalFiles: [...this.state.PincDAdditionalFiles, ""],
          PincDAdd_DocName: "",
          IsPincDAddFileDisabled: true,
        },
        () => {
          $(".PincDadditionalInput").attr("disabled", false);
        }
      );
    }
  };

  // PData
  handlePDataChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "Data",
          },
          () => {
            $("#PDataModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "Data",
            });
          }
        });
      $("#PDataModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPData = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PData").attr("disabled", true);
      $("#upload_btn20_PData").hide();
      $("#upload_btnloader_PData").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PDataDocLink: res.data.data.Data,
                },
                () => $("#viewDocLink_PData").css("display", "block")
              );
              PDataStatus = "remove";
              PStatusArray[3].status = PDataStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PDataModal").modal("hide");
              $("#cancelbtn_PData").attr("disabled", false);
              $("#upload_btn20_PData").show();
              $("#upload_btnloader_PData").hide();
              $("#choose_PData").hide();
              $("#X-btn_PData").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PData").attr("disabled", false);
            $("#upload_btn20_PData").show();
            $("#upload_btnloader_PData").hide();
            $("#PDataModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PData").attr("disabled", false);
            $("#upload_btn20_PData").show();
            $("#upload_btnloader_PData").hide();
            $("#PDataModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PData").attr("disabled", false);
          $("#upload_btn20_PData").show();
          $("#upload_btnloader_PData").hide();
          $("#PDataModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePData = () => {
    $("#X-btn_PData").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "general",
          documentName: "Data",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              PDataStatus = res.data.data.status;
              PStatusArray[3].status = PDataStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#choose_PData").show();
              $("#X-btn_PData").hide();
              $("#X-btn_PData").attr("disabled", false);
              $("#viewDocLink_PData").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_PData").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PData").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handlePDataseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PDataseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "Data",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PDataseecommentsbtn").hide();
          $("#PDatahidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePDatahidecommentsbtn = () => {
    $("#PDataseecommentsbtn").show();
    $("#PDataseecommentsbtn").attr("disabled", false);
    $("#PDatahidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PBankS
  handlePBankSChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "BankStatement",
          },
          () => {
            $("#PBankSModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "BankStatement",
            });
          }
        });
      $("#PBankSModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPBankS = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PBankS").attr("disabled", true);
      $("#upload_btn20_PBankS").hide();
      $("#upload_btnloader_PBankS").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PBankSDocLink: res.data.data.BankStatement,
                },
                () => $("#viewDocLink_PBankS").css("display", "block")
              );
              PBankSStatus = "remove";
              PStatusArray[4].status = PBankSStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PBankSModal").modal("hide");
              $("#cancelbtn_PBankS").attr("disabled", false);
              $("#upload_btn20_PBankS").show();
              $("#upload_btnloader_PBankS").hide();
              $("#choose_PBankS").hide();
              $("#X-btn_PBankS").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PBankS").attr("disabled", false);
            $("#upload_btn20_PBankS").show();
            $("#upload_btnloader_PBankS").hide();
            $("#PBankSModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PBankS").attr("disabled", false);
            $("#upload_btn20_PBankS").show();
            $("#upload_btnloader_PBankS").hide();
            $("#PBankSModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PBankS").attr("disabled", false);
          $("#upload_btn20_PBankS").show();
          $("#upload_btnloader_PBankS").hide();
          $("#PBankSModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePBankS = () => {
    $("#X-btn_PBankS").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "general",
          documentName: "BankStatement",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              PBankSStatus = res.data.data.status;
              PStatusArray[4].status = PBankSStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#choose_PBankS").show();
              $("#X-btn_PBankS").hide();
              $("#X-btn_PBankS").attr("disabled", false);
              $("#viewDocLink_PBankS").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_PBankS").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PBankS").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handlePBankSseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PBankSseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "BankStatement",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PBankSseecommentsbtn").hide();
          $("#PBankShidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePBankShidecommentsbtn = () => {
    $("#PBankSseecommentsbtn").show();
    $("#PBankSseecommentsbtn").attr("disabled", false);
    $("#PBankShidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PGSTReturn
  handlePGSTReturnChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "GSTReturn",
          },
          () => {
            $("#PGSTReturnModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "GSTReturn",
            });
          }
        });
      $("#PGSTReturnModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPGSTReturn = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PGSTReturn").attr("disabled", true);
      $("#upload_btn20_PGSTReturn").hide();
      $("#upload_btnloader_PGSTReturn").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            if (POptReview == "Yes") {
              POptReview = "No";
            }
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PGSTReturnDocLink: res.data.data.GSTReturn,
                },
                () => $("#viewDocLink_PGSTReturn").css("display", "block")
              );
              PGSTReturnStatus = "remove";
              POptStatusArray[2].status = PGSTReturnStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PGSTReturnModal").modal("hide");
              $("#cancelbtn_PGSTReturn").attr("disabled", false);
              $("#upload_btn20_PGSTReturn").show();
              $("#upload_btnloader_PGSTReturn").hide();
              $("#choose_PGSTReturn").hide();
              $("#X-btn_PGSTReturn").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PGSTReturn").attr("disabled", false);
            $("#upload_btn20_PGSTReturn").show();
            $("#upload_btnloader_PGSTReturn").hide();
            $("#PGSTReturnModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PGSTReturn").attr("disabled", false);
            $("#upload_btn20_PGSTReturn").show();
            $("#upload_btnloader_PGSTReturn").hide();
            $("#PGSTReturnModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PGSTReturn").attr("disabled", false);
          $("#upload_btn20_PGSTReturn").show();
          $("#upload_btnloader_PGSTReturn").hide();
          $("#PGSTReturnModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePGSTReturn = () => {
    $("#X-btn_PGSTReturn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "general",
          documentName: "GSTReturn",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              PGSTReturnStatus = res.data.data.status;
              POptStatusArray[2].status = PGSTReturnStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#choose_PGSTReturn").show();
              $("#X-btn_PGSTReturn").hide();
              $("#X-btn_PGSTReturn").attr("disabled", false);
              $("#viewDocLink_PGSTReturn").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_PGSTReturn").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PGSTReturn").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handlePGSTReturnseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PGSTReturnseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "GSTReturn",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PGSTReturnseecommentsbtn").hide();
          $("#PGSTReturnhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePGSTReturnhidecommentsbtn = () => {
    $("#PGSTReturnseecommentsbtn").show();
    $("#PGSTReturnseecommentsbtn").attr("disabled", false);
    $("#PGSTReturnhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PTDSReturn
  handlePTDSReturnChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "TDSReturn",
          },
          () => {
            $("#PTDSReturnModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "TDSReturn",
            });
          }
        });
      $("#PTDSReturnModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPTDSReturn = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PTDSReturn").attr("disabled", true);
      $("#upload_btn20_PTDSReturn").hide();
      $("#upload_btnloader_PTDSReturn").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            if (POptReview == "Yes") {
              POptReview = "No";
            }
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PTDSReturnDocLink: res.data.data.TDSReturn,
                },
                () => $("#viewDocLink_PTDSReturn").css("display", "block")
              );
              PTDSReturnStatus = "remove";
              POptStatusArray[3].status = PTDSReturnStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PTDSReturnModal").modal("hide");
              $("#cancelbtn_PTDSReturn").attr("disabled", false);
              $("#upload_btn20_PTDSReturn").show();
              $("#upload_btnloader_PTDSReturn").hide();
              $("#choose_PTDSReturn").hide();
              $("#X-btn_PTDSReturn").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PTDSReturn").attr("disabled", false);
            $("#upload_btn20_PTDSReturn").show();
            $("#upload_btnloader_PTDSReturn").hide();
            $("#PTDSReturnModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PTDSReturn").attr("disabled", false);
            $("#upload_btn20_PTDSReturn").show();
            $("#upload_btnloader_PTDSReturn").hide();
            $("#PTDSReturnModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PTDSReturn").attr("disabled", false);
          $("#upload_btn20_PTDSReturn").show();
          $("#upload_btnloader_PTDSReturn").hide();
          $("#PTDSReturnModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePTDSReturn = () => {
    $("#X-btn_PTDSReturn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "general",
          documentName: "TDSReturn",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              PTDSReturnStatus = res.data.data.status;
              POptStatusArray[3].status = PTDSReturnStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#choose_PTDSReturn").show();
              $("#X-btn_PTDSReturn").hide();
              $("#X-btn_PTDSReturn").attr("disabled", false);
              $("#viewDocLink_PTDSReturn").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_PTDSReturn").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PTDSReturn").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handlePTDSReturnseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PTDSReturnseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "TDSReturn",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PTDSReturnseecommentsbtn").hide();
          $("#PTDSReturnhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePTDSReturnhidecommentsbtn = () => {
    $("#PTDSReturnseecommentsbtn").show();
    $("#PTDSReturnseecommentsbtn").attr("disabled", false);
    $("#PTDSReturnhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PChallan
  handlePChallanChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "Challan",
          },
          () => {
            $("#PChallanModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "Challan",
            });
          }
        });
      $("#PChallanModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPChallan = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PChallan").attr("disabled", true);
      $("#upload_btn20_PChallan").hide();
      $("#upload_btnloader_PChallan").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            if (POptReview == "Yes") {
              POptReview = "No";
            }
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PChallanDocLink: res.data.data.Challan,
                },
                () => $("#viewDocLink_PChallan").css("display", "block")
              );
              PChallanStatus = "remove";
              POptStatusArray[4].status = PChallanStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PChallanModal").modal("hide");
              $("#cancelbtn_PChallan").attr("disabled", false);
              $("#upload_btn20_PChallan").show();
              $("#upload_btnloader_PChallan").hide();
              $("#choose_PChallan").hide();
              $("#X-btn_PChallan").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PChallan").attr("disabled", false);
            $("#upload_btn20_PChallan").show();
            $("#upload_btnloader_PChallan").hide();
            $("#PChallanModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PChallan").attr("disabled", false);
            $("#upload_btn20_PChallan").show();
            $("#upload_btnloader_PChallan").hide();
            $("#PChallanModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PChallan").attr("disabled", false);
          $("#upload_btn20_PChallan").show();
          $("#upload_btnloader_PChallan").hide();
          $("#PChallanModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePChallan = () => {
    $("#X-btn_PChallan").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "general",
          documentName: "Challan",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              PChallanStatus = res.data.data.status;
              POptStatusArray[4].status = PChallanStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#choose_PChallan").show();
              $("#X-btn_PChallan").hide();
              $("#X-btn_PChallan").attr("disabled", false);
              $("#viewDocLink_PChallan").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_PChallan").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PChallan").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handlePChallanseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PChallanseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "Challan",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PChallanseecommentsbtn").hide();
          $("#PChallanhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePChallanhidecommentsbtn = () => {
    $("#PChallanseecommentsbtn").show();
    $("#PChallanseecommentsbtn").attr("disabled", false);
    $("#PChallanhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PAdditional
  handlePAddFileChange = (e, index, status) => {
    PadditionalReuploadIndex = index;
    PadditionalReuploadStatus = status;
    $(".upload_btn20_7").show();
    $(".upload_btnloader_7").hide();
    const file = e.target.files[0];
    if (!PAdditionalFilesNames.includes(this.state.PAdd_DocName.trim())) {
      const myfile = e.target.files;
      if (ChooseNowBtnsDisable == "Yes") {
        this.showNotification(
          "Files cannot be uploaded when the service is locked!"
        );
      } else if (myfile.length == 1) {
        const file = myfile[0];
        if (file.type.match("audio.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.type.match("video.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.size > 104857600) {
          this.showNotification("File too big!");
        } else if (file.name.length > 100) {
          this.showNotification("File name limit exceed!");
        } else {
          this.setState(
            {
              fileName: file.name,
              fileBase64: file,
              documentType: "additional",
              documentName: this.state.PAdd_DocName,
            },
            () => {
              $(".PAddModal").modal({
                backdrop: "static",
                keyboard: false,
              });
            }
          );
        }
      } else if (myfile.length > 1) {
        var zip = new JSZip();
        for (let i = 0; i < myfile.length; i++) {
          zip.file(myfile[i].name, myfile[i], {
            base64: true,
          });
        }
        zip
          .generateAsync({
            type: "blob",
          })
          .then((content) => {
            if (content.size > 104857600) {
              this.showNotification("File too big!");
            } else {
              this.setState({
                fileName: "download.zip",
                fileBase64: content,
                documentType: "additional",
                documentName: this.state.PAdd_DocName,
              });
            }
          });
        $(".PAddModal").modal({
          backdrop: "static",
          keyboard: false,
        });
      } else {
        this.showNotification("File cannot be null!");
      }
    } else {
      this.showNotification(
        "Document names can not be the same, Try again with different name!"
      );
    }
  };

  uploadPFile = (FileIndex, FileStatus) => {
    let AdditionaldocumentName1;
    if (FileStatus == "reupload") {
      $(".proceednowbtn").attr("disabled", true);
      let fileTobeUploaded = this.state.PAdditionalFiles[FileIndex];
      AdditionaldocumentName1 = Object.keys(fileTobeUploaded)[0];
      mainaction = "reupload";
    } else {
      AdditionaldocumentName1 = this.state.documentName;
      mainaction = "upload";
    }
    if (AdditionaldocumentName1.trim() == "") {
      $(".PAddModal").modal("hide");
      this.showNotification("Enter document Name first");
    } else if (this.state.comment.length <= 250) {
      $(".cancelbtn_7").attr("disabled", true);
      $(".upload_btn20_7").hide();
      $(".upload_btnloader_7").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", AdditionaldocumentName1);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            $(".PAddModal").modal("hide");
            $(".cancelbtn_7").attr("disabled", false);
            $(`#${FileIndex}PFileseecommentsbtn`).attr("disabled", false);
            if (PadditionalReupload == false) {
              PAdditionalFilesNames.push(this.state.PAdd_DocName);
              this.state.PAdditionalFiles.pop();
              this.setState(
                {
                  comment: "",
                  PAdditionalFiles: [
                    ...this.state.PAdditionalFiles,
                    res.data.data,
                  ],
                },
                () => {
                  this.setState(
                    {
                      api_PAdditionalFiles: "Yes",
                      ProceedDisabled: false,
                    },
                    () => {
                      this.PAdditionalFilesArea();
                      this.checkDropdownValue();
                      this.checkhandlePProceedBtn();
                    }
                  );

                  $(".PadditionalInput").attr("disabled", true);
                }
              );
            } else if (PadditionalReupload == true) {
              // $(".proceednowbtn").attr("disabled", false);
              var array = [...this.state.PAdditionalFiles];
              array.splice(FileIndex, 1, res.data.data);
              this.setState(
                {
                  comment: "",
                  api_PAdditionalFiles: "Yes",
                  PAdditionalFiles: array,
                  ProceedDisabled: false,
                },
                () => {
                  this.PAdditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandlePProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".PAddModal").modal("hide");
            this.showNotification("Something Went Wrong!");
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".PAddModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          this.showNotification("Something went wrong, Try again!");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePFile = (index) => {
    $("#X-btn_7").attr("disabled", true);
    let fileTobeDeleted = this.state.PAdditionalFiles[index];
    let NameOfAddFile = Object.keys(fileTobeDeleted)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "additional",
          documentName: NameOfAddFile,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          var array = [...this.state.PAdditionalFiles];
          if (res.data.data.status == "reupload") {
            array[index].status = "reupload";
            this.setState(
              {
                PAdditionalFiles: array,
              },
              () => {
                this.PAdditionalFilesArea();
                this.checkDropdownValue();
                this.checkhandlePProceedBtn();
              }
            );
            this.showNotification(res.data.message);
          } else {
            array.splice(index, 1);
            if (array.length > 0) {
              this.setState(
                {
                  PAdditionalFiles: array,
                },
                () => {
                  this.PAdditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandlePProceedBtn();
                }
              );
            } else if (array.length == 0) {
              this.setState(
                {
                  PAdditionalFiles: [],
                  PAdd_DocName: "",
                  api_PAdditionalFiles: "No",
                  IsPAddFileDisabled: true,
                },
                () => {
                  this.PAdditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandlePProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_7").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePFileseeComments = (index) => {
    // $(".seecommentsbtn").attr("disabled", false);
    $(".commentsbtn").attr("disabled", false);
    $(`#${index}PFileseecommentsbtn`).attr("disabled", true);
    let SelectedFile = this.state.PAdditionalFiles[index];
    let NameOfAddFile = Object.keys(SelectedFile)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "additional",
          documentName: NameOfAddFile,
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(`#${index}PFileseecommentsbtn`).hide();
          $(".hidecommentsbtn").hide();
          $(`#${index}PFilehidecommentsbtn`).show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePFilehideComments = (index) => {
    $(".seecommentsbtn").show();
    $(`#${index}PFileseecommentsbtn`).show();
    $(`#${index}PFileseecommentsbtn`).attr("disabled", false);
    $(`#${index}PFilehidecommentsbtn`).hide();
    $(".hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  checkAddCommentPFiles = (FileIndex, isDisabled) => {
    if (isDisabled == true) {
      return (
        <>
          <button
            className="seecommentsbtn disabledBtn_cmt PFileseecommentsbtn"
            id={`${FileIndex}PFileseecommentsbtn`}
            disabled
            onClick={() => this.handlePFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn PFilehidecommentsbtn"
            id={`${FileIndex}PFilehidecommentsbtn`}
            onClick={() => this.handlePFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    } else if (isDisabled == false) {
      return (
        <>
          <button
            className="seecommentsbtn commentsbtn PFileseecommentsbtn"
            id={`${FileIndex}PFileseecommentsbtn`}
            onClick={() => this.handlePFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn PFilehidecommentsbtn"
            id={`${FileIndex}PFilehidecommentsbtn`}
            onClick={() => this.handlePFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    }
  };

  checkStatusPAddFiles = (status, link, index) => {
    PAdditionalFilesIndex = index;
    if (ChooseNowBtnsDisable == "Yes") {
      $(".proceednowbtn").hide();

      if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      }
    } else {
      if (status == undefined) {
        status = "open";
      }
      if (status == "open") {
        mainaction = "upload";
        PadditionalReupload = true;
        $(".proceednowbtn2").show();
        return (
          <>
            <label
              htmlFor={`ul_file_PAdd${index}`}
              className="choose_btn choose_Padd"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              name="file[]"
              className="upload-photo"
              id={`ul_file_PAdd${index}`}
              // disabled={this.state.IsPAddFileDisabled}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handlePAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "remove") {
        mainaction = "upload";
        $(".proceednowbtn2").show();
        return (
          <>
            <button
              className="X-btn X-btn_7"
              onClick={() => this.removePFile(index)}
            >
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_1025.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "reupload") {
        PadditionalReupload = true;
        mainaction = "reupload";
        $(".proceednowbtn2").show();
        $(".proceednowbtn").attr("disabled", true);
        return (
          <>
            <label
              htmlFor={`ul_file_PAdd${index}`}
              className="choose_btn choose_Padd"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              name="file[]"
              className="upload-photo"
              id={`ul_file_PAdd${index}`}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handlePAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      }
    }
  };

  AdditionalPFileDisabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero PadditionalInput"
        value={NameOfAddFile}
        disabled
        onChange={(e) => this.handlePAdd_DocNameChange(e)}
      />
    );
  };

  AdditionalPFileEnabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero PadditionalInput"
        value={NameOfAddFile}
        onChange={(e) => this.handlePAdd_DocNameChange(e)}
      />
    );
  };

  PAdditionalFilesArea = () => {
    PAdditionalFilesNames = [];
    if (this.state.api_PAdditionalFiles == "Yes") {
      return this.state.PAdditionalFiles.map((file, index) => {
        let NameOfAddFile = Object.keys(file)[0];
        PAdditionalFilesNames.push(NameOfAddFile);
        let link = file[Object.keys(file)[0]];
        let AdditionalFileInputField;
        let commentDisabled;
        if (file.status == "open" || file.status == undefined) {
          AdditionalFileInputField =
            this.AdditionalPFileEnabledField(NameOfAddFile);
          commentDisabled = true;
        } else if (file.status != "open") {
          AdditionalFileInputField =
            this.AdditionalPFileDisabledField(NameOfAddFile);
          commentDisabled = false;
        }
        return (
          <div className="displayflex" key={index}>
            <div className="uploadnocol">
              <p className="uploadadditionalsidetext">{index + 1}&#41;</p>
            </div>
            <div className="uploadadditionaldatacol">
              <div className="row mb-2">
                <div className="col-md-5">{AdditionalFileInputField}</div>
                <div className="col-md-7">
                  <div className="float-md-right float-left">
                    {this.checkAddCommentPFiles(index, commentDisabled)}
                    {this.checkStatusPAddFiles(file.status, link, index)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.api_PAdditionalFiles == "No") {
      return (
        <div className="displayflex">
          <div className="uploadnocol">
            <p className="uploadadditionalsidetext">1&#41;</p>
          </div>
          <div className="uploadadditionaldatacol">
            <div className="row mb-2">
              <div className="col-md-5">
                <input
                  type="text"
                  placeholder="Document name..."
                  maxLength="150"
                  className="uploadtextfield marginzero"
                  value={this.state.PAdd_DocName}
                  onChange={(e) => this.handlePAdd_DocNameChange(e)}
                />
              </div>
              <div className="col-md-7">
                <div className="fl_right">
                  {this.checkAddCommentPFiles(0, true)}
                  {this.checkStatusPAddFiles("open", null, 0)}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  handlePAdd_DocNameChange = (e) => {
    this.setState(
      {
        PAdd_DocName: e.target.value,
      },
      () => {
        if (this.state.PAdd_DocName.trim().length > 0) {
          this.setState({
            IsPAddFileDisabled: false,
          });
        } else {
          this.setState({
            IsPAddFileDisabled: true,
          });
        }
      }
    );
  };

  handleAddPfileBtn = (FileIndex) => {
    const { PAdd_DocName, PAdditionalFiles } = this.state;

    let prevFile = PAdditionalFiles[FileIndex];
    let NameOfPrevFile = Object.keys(prevFile)[0];
    let valueOffile = prevFile[Object.keys(prevFile)[0]];

    if (FileIndex == undefined) {
      this.showNotification("empty fields");
    } else if (PAdd_DocName.trim() == "") {
      this.showNotification("Required fields cannot be empty!");
    } else if (valueOffile == null) {
      this.showNotification("Choose a file");
    } else {
      this.setState(
        {
          PAdditionalFiles: [...this.state.PAdditionalFiles, ""],
          PAdd_DocName: "",
          IsPAddFileDisabled: true,
        },
        () => {
          $(".PadditionalInput").attr("disabled", false);
        }
      );
    }
  };

  PcheckRemainingReuploads = () => {
    if (
      PAadharStatus != "reupload" &&
      PPanStatus != "reupload" &&
      PBPStatus != "reupload" &&
      PBSStatus != "reupload" &&
      PPRStatus != "reupload" &&
      PDataStatus != "reupload" &&
      PGSTReturnStatus != "reupload" &&
      PTDSReturnStatus != "reupload" &&
      PChallanStatus != "reupload" &&
      anyPincDAdditionalReuploadRemaining != "Yes" &&
      anyPAdditionalReuploadRemaining != "Yes"
    ) {
      AnyReuploadOccuredP = "No";
    } else {
      $(".proceednowbtn2").show();
      AnyReuploadOccuredP = "Yes";
    }
  };

  checkhandlePProceedBtn = () => {
    let statusCheck = this.statusCheckFunction("reupload", PStatusArray);
    POptReupload = this.countOptReupload1("reupload", POptStatusArray);
    PincDadditionalStatusArrayCheck = this.statusCheckFunction(
      "reupload",
      this.state.PincDAdditionalFiles
    );
    PadditionalStatusArrayCheck = this.statusCheckFunction(
      "reupload",
      this.state.PAdditionalFiles
    );

    if (rejectstatus == "Yes") {
      $(".proceednowbtn2").show();
      $(".proceednowbtn2").attr("disabled", false);
      Pproceednowforword = "success";
    } else if (
      AnyReuploadOccuredP == "Yes" &&
      ((POptReview == "No" && POptReupload != OptReuploadCountP) ||
        POptReview == "Yes") &&
      statusCheck == undefined &&
      PincDadditionalStatusArrayCheck == undefined &&
      PadditionalStatusArrayCheck == undefined
    ) {
      $(".proceednowbtn2").attr("disabled", false);
      Pproceednowforword = "success";
    } else if (
      PAadharStatus == "remove" &&
      PPanStatus == "remove" &&
      PBPStatus == "remove" &&
      PDataStatus == "remove" &&
      PBankSStatus == "remove" &&
      PincDadditionalStatusArrayCheck == undefined &&
      PadditionalStatusArrayCheck == undefined
    ) {
      $(".proceednowbtn2").attr("disabled", false);
      Pproceednowforword = "success";
    } else if (
      PAadharStatus == "review" &&
      PPanStatus == "review" &&
      PBPStatus == "review" &&
      PDataStatus == "remove" &&
      PBankSStatus == "remove" &&
      PincDadditionalStatusArrayCheck == undefined &&
      PadditionalStatusArrayCheck == undefined
    ) {
      $(".proceednowbtn2").attr("disabled", false);
      Pproceednowforword = "success";
    } else if (
      PAadharStatus == "done" &&
      PPanStatus == "done" &&
      PBPStatus == "done" &&
      PDataStatus == "remove" &&
      PBankSStatus == "remove" &&
      PincDadditionalStatusArrayCheck == undefined &&
      PadditionalStatusArrayCheck == undefined
    ) {
      $(".proceednowbtn2").attr("disabled", false);
      Pproceednowforword = "success";
    } else {
      Pproceednowforword = "fail";
      $(".proceednowbtn2").attr("disabled", true);
    }
  };

  handlePProceedBtn = () => {
    this.checkhandlePProceedBtn();
    if (Pproceednowforword == "success") {
      if (
        this.state.itrfusername.trim().length < 96 &&
        this.state.itrfpassword.trim().length < 96
      ) {
        axios
          .post(
            process.env.REACT_APP_API_BASE_URL + "p=lockService",
            {
              userId: Decuserid,
              requestType: temp,
              agentId: Decagentid,

              serviceId: Decserviceid,
              serviceName: Decservicename,
              serviceType: this.state.serviceType,
              FY: this.state.SelectedYear,
              Month: "",
              inputFields: [
                {
                  inputFieldName: "PortalUserID",
                  inputFieldValue: this.state.itrfusername.trim(),
                  inputFieldtype: "onetime",
                },
                {
                  inputFieldName: "PortalPassword",
                  inputFieldValue: this.state.itrfpassword.trim(),
                  inputFieldtype: "onetime",
                },
              ],
            },
            {
              headers: {
                Authkey: process.env.REACT_APP_API_KEY,
                "App-Token-Access": Decjwttoken,
              },
              auth: {
                username: process.env.REACT_APP_API_USERNAME,
                password: process.env.REACT_APP_API_PASSWORD,
              },
            }
          )
          .then((res) => {
            if (res.data.code == "200" && res.data.status == "success") {
              this.showNotification(res.data.message);
              this.getserviceInformationApi();
              this.getdeliveredDocuments();
            } else if (
              res.data.code == "201" &&
              res.data.status == "rejected"
            ) {
              this.showNotification(res.data.message);
              this.getserviceInformationApi();
              this.getdeliveredDocuments();
            } else if (
              res.data.code == "201" &&
              res.data.status == "invalidauth"
            ) {
              this.logout();
            } else if (res.data.code == "201" && res.data.status == "timeOut") {
              localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
              this.props.history.push({
                pathname: "/dashboard",
                state: {
                  timeout: true,
                },
              });
            } else {
              this.showNotification(res.data.message);
            }
          })
          .catch((err) => {});
      } else if (this.state.itrfusername.trim().length > 95) {
        this.showNotification("Max length exceed!");
      } else if (this.state.itrfpassword.trim().length > 95) {
        this.showNotification("Max length exceed!");
      } else {
        this.showNotification("Required fields are empty!");
      }
    } else {
      this.showNotification("Required fields are empty!");
    }
  };

  // PF section

  // PFAadhar1
  handlePFAadhar1Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "onetime",
            documentName: "AadhaarCardApplicant1",
          },
          () => {
            $("#PFAadhar1Modal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "onetime",
              documentName: "AadhaarCardApplicant1",
            });
          }
        });
      $("#PFAadhar1Modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPFAadhar1 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PFAadhar1").attr("disabled", true);
      $("#upload_btn20_PFAadhar1").hide();
      $("#upload_btnloader_PFAadhar1").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PFAadhar1DocLink: res.data.data.AadhaarCardApplicant1,
                },
                () => $("#viewDocLink_PFAadhar1").css("display", "block")
              );
              PFAadhar1Status = "remove";
              PFStatusArray[0].status = PFAadhar1Status;
              this.checkDropdownValue();
              this.checkhandlePFProceedBtn();
              $("#PFAadhar1Modal").modal("hide");
              $("#cancelbtn_PFAadhar1").attr("disabled", false);
              $("#upload_btn20_PFAadhar1").show();
              $("#upload_btnloader_PFAadhar1").hide();
              $("#choose_PFAadhar1").hide();
              $("#X-btn_PFAadhar1").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PFAadhar1").attr("disabled", false);
            $("#upload_btn20_PFAadhar1").show();
            $("#upload_btnloader_PFAadhar1").hide();
            $("#PFAadhar1Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PFAadhar1").attr("disabled", false);
            $("#upload_btn20_PFAadhar1").show();
            $("#upload_btnloader_PFAadhar1").hide();
            $("#PFAadhar1Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PFAadhar1").attr("disabled", false);
          $("#upload_btn20_PFAadhar1").show();
          $("#upload_btnloader_PFAadhar1").hide();
          $("#PFAadhar1Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePFAadhar1 = () => {
    $("#X-btn_PFAadhar1").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "onetime",
          documentName: "AadhaarCardApplicant1",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              PFAadhar1Status = res.data.data.status;
              PFStatusArray[0].status = PFAadhar1Status;
              this.checkDropdownValue();
              this.checkhandlePFProceedBtn();
              $("#choose_PFAadhar1").show();
              $("#X-btn_PFAadhar1").hide();
              $("#X-btn_PFAadhar1").attr("disabled", false);
              $("#viewDocLink_PFAadhar1").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_PFAadhar1").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PFAadhar1").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handlePFAadhar1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PFAadhar1seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "AadhaarCardApplicant1",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PFAadhar1seecommentsbtn").hide();
          $("#PFAadhar1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePFAadhar1hidecommentsbtn = () => {
    $("#PFAadhar1seecommentsbtn").show();
    $("#PFAadhar1seecommentsbtn").attr("disabled", false);
    $("#PFAadhar1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PFAadhar2
  handlePFAadhar2Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "onetime",
            documentName: "AadhaarCardApplicant2",
          },
          () => {
            $("#PFAadhar2Modal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "onetime",
              documentName: "AadhaarCardApplicant2",
            });
          }
        });
      $("#PFAadhar2Modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPFAadhar2 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PFAadhar2").attr("disabled", true);
      $("#upload_btn20_PFAadhar2").hide();
      $("#upload_btnloader_PFAadhar2").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PFAadhar2DocLink: res.data.data.AadhaarCardApplicant2,
                },
                () => $("#viewDocLink_PFAadhar2").css("display", "block")
              );
              PFAadhar2Status = "remove";
              PFStatusArray[1].status = PFAadhar2Status;
              this.checkDropdownValue();
              this.checkhandlePFProceedBtn();
              $("#PFAadhar2Modal").modal("hide");
              $("#cancelbtn_PFAadhar2").attr("disabled", false);
              $("#upload_btn20_PFAadhar2").show();
              $("#upload_btnloader_PFAadhar2").hide();
              $("#choose_PFAadhar2").hide();
              $("#X-btn_PFAadhar2").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PFAadhar2").attr("disabled", false);
            $("#upload_btn20_PFAadhar2").show();
            $("#upload_btnloader_PFAadhar2").hide();
            $("#PFAadhar2Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PFAadhar2").attr("disabled", false);
            $("#upload_btn20_PFAadhar2").show();
            $("#upload_btnloader_PFAadhar2").hide();
            $("#PFAadhar2Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PFAadhar2").attr("disabled", false);
          $("#upload_btn20_PFAadhar2").show();
          $("#upload_btnloader_PFAadhar2").hide();
          $("#PFAadhar2Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePFAadhar2 = () => {
    $("#X-btn_PFAadhar2").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "onetime",
          documentName: "AadhaarCardApplicant2",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              PFAadhar2Status = res.data.data.status;
              PFStatusArray[1].status = PFAadhar2Status;
              this.checkDropdownValue();
              this.checkhandlePFProceedBtn();
              $("#choose_PFAadhar2").show();
              $("#X-btn_PFAadhar2").hide();
              $("#X-btn_PFAadhar2").attr("disabled", false);
              $("#viewDocLink_PFAadhar2").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_PFAadhar2").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PFAadhar2").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handlePFAadhar2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PFAadhar2seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "AadhaarCardApplicant2",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PFAadhar2seecommentsbtn").hide();
          $("#PFAadhar2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePFAadhar2hidecommentsbtn = () => {
    $("#PFAadhar2seecommentsbtn").show();
    $("#PFAadhar2seecommentsbtn").attr("disabled", false);
    $("#PFAadhar2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PFPan1
  handlePFPan1Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "onetime",
            documentName: "PANCardApplicant1",
          },
          () => {
            $("#PFPan1Modal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "onetime",
              documentName: "PANCardApplicant1",
            });
          }
        });
      $("#PFPan1Modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPFPan1 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PFPan1").attr("disabled", true);
      $("#upload_btn20_PFPan1").hide();
      $("#upload_btnloader_PFPan1").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PFPan1DocLink: res.data.data.PANCardApplicant1,
                },
                () => $("#viewDocLink_PFPan1").css("display", "block")
              );
              PFPan1Status = "remove";
              PFStatusArray[2].status = PFPan1Status;
              this.checkDropdownValue();
              this.checkhandlePFProceedBtn();
              $("#PFPan1Modal").modal("hide");
              $("#cancelbtn_PFPan1").attr("disabled", false);
              $("#upload_btn20_PFPan1").show();
              $("#upload_btnloader_PFPan1").hide();
              $("#choose_PFPan1").hide();
              $("#X-btn_PFPan1").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PFPan1").attr("disabled", false);
            $("#upload_btn20_PFPan1").show();
            $("#upload_btnloader_PFPan1").hide();
            $("#PFPan1Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PFPan1").attr("disabled", false);
            $("#upload_btn20_PFPan1").show();
            $("#upload_btnloader_PFPan1").hide();
            $("#PFPan1Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PFPan1").attr("disabled", false);
          $("#upload_btn20_PFPan1").show();
          $("#upload_btnloader_PFPan1").hide();
          $("#PFPan1Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePFPan1 = () => {
    $("#X-btn_PFPan1").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "onetime",
          documentName: "PANCardApplicant1",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              PFPan1Status = res.data.data.status;
              PFStatusArray[2].status = PFPan1Status;
              this.checkDropdownValue();
              this.checkhandlePFProceedBtn();
              $("#choose_PFPan1").show();
              $("#X-btn_PFPan1").hide();
              $("#X-btn_PFPan1").attr("disabled", false);
              $("#viewDocLink_PFPan1").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_PFPan1").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PFPan1").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handlePFPan1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PFPan1seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "PANCardApplicant1",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PFPan1seecommentsbtn").hide();
          $("#PFPan1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePFPan1hidecommentsbtn = () => {
    $("#PFPan1seecommentsbtn").show();
    $("#PFPan1seecommentsbtn").attr("disabled", false);
    $("#PFPan1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PFPan2
  handlePFPan2Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "onetime",
            documentName: "PANCardApplicant2",
          },
          () => {
            $("#PFPan2Modal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "onetime",
              documentName: "PANCardApplicant2",
            });
          }
        });
      $("#PFPan2Modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPFPan2 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PFPan2").attr("disabled", true);
      $("#upload_btn20_PFPan2").hide();
      $("#upload_btnloader_PFPan2").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PFPan2DocLink: res.data.data.PANCardApplicant2,
                },
                () => $("#viewDocLink_PFPan2").css("display", "block")
              );
              PFPan2Status = "remove";
              PFStatusArray[3].status = PFPan2Status;
              this.checkDropdownValue();
              this.checkhandlePFProceedBtn();
              $("#PFPan2Modal").modal("hide");
              $("#cancelbtn_PFPan2").attr("disabled", false);
              $("#upload_btn20_PFPan2").show();
              $("#upload_btnloader_PFPan2").hide();
              $("#choose_PFPan2").hide();
              $("#X-btn_PFPan2").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PFPan2").attr("disabled", false);
            $("#upload_btn20_PFPan2").show();
            $("#upload_btnloader_PFPan2").hide();
            $("#PFPan2Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PFPan2").attr("disabled", false);
            $("#upload_btn20_PFPan2").show();
            $("#upload_btnloader_PFPan2").hide();
            $("#PFPan2Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PFPan2").attr("disabled", false);
          $("#upload_btn20_PFPan2").show();
          $("#upload_btnloader_PFPan2").hide();
          $("#PFPan2Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePFPan2 = () => {
    $("#X-btn_PFPan2").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "onetime",
          documentName: "PANCardApplicant2",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              PFPan2Status = res.data.data.status;
              PFStatusArray[3].status = PFPan2Status;
              this.checkDropdownValue();
              this.checkhandlePFProceedBtn();
              $("#choose_PFPan2").show();
              $("#X-btn_PFPan2").hide();
              $("#X-btn_PFPan2").attr("disabled", false);
              $("#viewDocLink_PFPan2").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_PFPan2").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PFPan2").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handlePFPan2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PFPan2seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "PANCardApplicant2",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PFPan2seecommentsbtn").hide();
          $("#PFPan2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePFPan2hidecommentsbtn = () => {
    $("#PFPan2seecommentsbtn").show();
    $("#PFPan2seecommentsbtn").attr("disabled", false);
    $("#PFPan2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PFDeed
  handlePFDeedChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "onetime",
            documentName: "Deed",
          },
          () => {
            $("#PFDeedModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "onetime",
              documentName: "Deed",
            });
          }
        });
      $("#PFDeedModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPFDeed = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PFDeed").attr("disabled", true);
      $("#upload_btn20_PFDeed").hide();
      $("#upload_btnloader_PFDeed").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PFDeedDocLink: res.data.data.Deed,
                },
                () => $("#viewDocLink_PFDeed").css("display", "block")
              );
              PFDeedStatus = "remove";
              PFStatusArray[4].status = PFDeedStatus;
              this.checkDropdownValue();
              this.checkhandlePFProceedBtn();
              $("#PFDeedModal").modal("hide");
              $("#cancelbtn_PFDeed").attr("disabled", false);
              $("#upload_btn20_PFDeed").show();
              $("#upload_btnloader_PFDeed").hide();
              $("#choose_PFDeed").hide();
              $("#X-btn_PFDeed").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PFDeed").attr("disabled", false);
            $("#upload_btn20_PFDeed").show();
            $("#upload_btnloader_PFDeed").hide();
            $("#PFDeedModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PFDeed").attr("disabled", false);
            $("#upload_btn20_PFDeed").show();
            $("#upload_btnloader_PFDeed").hide();
            $("#PFDeedModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PFDeed").attr("disabled", false);
          $("#upload_btn20_PFDeed").show();
          $("#upload_btnloader_PFDeed").hide();
          $("#PFDeedModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePFDeed = () => {
    $("#X-btn_PFDeed").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "onetime",
          documentName: "Deed",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              PFDeedStatus = res.data.data.status;
              PFStatusArray[4].status = PFDeedStatus;
              this.checkDropdownValue();
              this.checkhandlePFProceedBtn();
              $("#choose_PFDeed").show();
              $("#X-btn_PFDeed").hide();
              $("#X-btn_PFDeed").attr("disabled", false);
              $("#viewDocLink_PFDeed").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_PFDeed").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PFDeed").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handlePFDeedseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PFDeedseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "Deed",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PFDeedseecommentsbtn").hide();
          $("#PFDeedhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePFDeedhidecommentsbtn = () => {
    $("#PFDeedseecommentsbtn").show();
    $("#PFDeedseecommentsbtn").attr("disabled", false);
    $("#PFDeedhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PFBP
  handlePFBPChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "onetime",
            documentName: "BankProof",
          },
          () => {
            $("#PFBPModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "onetime",
              documentName: "BankProof",
            });
          }
        });
      $("#PFBPModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPFBP = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PFBP").attr("disabled", true);
      $("#upload_btn20_PFBP").hide();
      $("#upload_btnloader_PFBP").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PFBPDocLink: res.data.data.BankProof,
                },
                () => $("#viewDocLink_PFBP").css("display", "block")
              );
              PFBPStatus = "remove";
              PFStatusArray[5].status = PFBPStatus;
              this.checkDropdownValue();
              this.checkhandlePFProceedBtn();
              $("#PFBPModal").modal("hide");
              $("#cancelbtn_PFBP").attr("disabled", false);
              $("#upload_btn20_PFBP").show();
              $("#upload_btnloader_PFBP").hide();
              $("#choose_PFBP").hide();
              $("#X-btn_PFBP").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PFBP").attr("disabled", false);
            $("#upload_btn20_PFBP").show();
            $("#upload_btnloader_PFBP").hide();
            $("#PFBPModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PFBP").attr("disabled", false);
            $("#upload_btn20_PFBP").show();
            $("#upload_btnloader_PFBP").hide();
            $("#PFBPModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PFBP").attr("disabled", false);
          $("#upload_btn20_PFBP").show();
          $("#upload_btnloader_PFBP").hide();
          $("#PFBPModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePFBP = () => {
    $("#X-btn_PFBP").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "onetime",
          documentName: "BankProof",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              PFBPStatus = res.data.data.status;
              PFStatusArray[5].status = PFBPStatus;
              this.checkDropdownValue();
              this.checkhandlePFProceedBtn();
              $("#choose_PFBP").show();
              $("#X-btn_PFBP").hide();
              $("#X-btn_PFBP").attr("disabled", false);
              $("#viewDocLink_PFBP").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_PFBP").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PFBP").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handlePFBPseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PFBPseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "BankProof",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PFBPseecommentsbtn").hide();
          $("#PFBPhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePFBPhidecommentsbtn = () => {
    $("#PFBPseecommentsbtn").show();
    $("#PFBPseecommentsbtn").attr("disabled", false);
    $("#PFBPhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PFRC
  handlePFRCChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "onetime",
            documentName: "RegistrationCertificate",
          },
          () => {
            $("#PFRCModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "onetime",
              documentName: "RegistrationCertificate",
            });
          }
        });
      $("#PFRCModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPFRC = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PFRC").attr("disabled", true);
      $("#upload_btn20_PFRC").hide();
      $("#upload_btnloader_PFRC").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PFRCDocLink: res.data.data.RegistrationCertificate,
                },
                () => $("#viewDocLink_PFRC").css("display", "block")
              );
              PFRCStatus = "remove";
              PFOptStatusArray[5].status = PFRCStatus;
              this.checkDropdownValue();
              this.checkhandlePFProceedBtn();
              $("#PFRCModal").modal("hide");
              $("#cancelbtn_PFRC").attr("disabled", false);
              $("#upload_btn20_PFRC").show();
              $("#upload_btnloader_PFRC").hide();
              $("#choose_PFRC").hide();
              $("#X-btn_PFRC").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PFRC").attr("disabled", false);
            $("#upload_btn20_PFRC").show();
            $("#upload_btnloader_PFRC").hide();
            $("#PFRCModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PFRC").attr("disabled", false);
            $("#upload_btn20_PFRC").show();
            $("#upload_btnloader_PFRC").hide();
            $("#PFRCModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PFRC").attr("disabled", false);
          $("#upload_btn20_PFRC").show();
          $("#upload_btnloader_PFRC").hide();
          $("#PFRCModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePFRC = () => {
    $("#X-btn_PFRC").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "onetime",
          documentName: "RegistrationCertificate",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              PFRCStatus = res.data.data.status;
              PFOptStatusArray[5].status = PFRCStatus;
              this.checkDropdownValue();
              this.checkhandlePFProceedBtn();
              $("#choose_PFRC").show();
              $("#X-btn_PFRC").hide();
              $("#X-btn_PFRC").attr("disabled", false);
              $("#viewDocLink_PFRC").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_PFRC").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PFRC").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handlePFRCseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PFRCseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "RegistrationCertificate",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PFRCseecommentsbtn").hide();
          $("#PFRChidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePFRChidecommentsbtn = () => {
    $("#PFRCseecommentsbtn").show();
    $("#PFRCseecommentsbtn").attr("disabled", false);
    $("#PFRChidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PFPBS_A
  handlePFPBS_AChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "onetime",
            documentName: "PreviousBS_Audit",
          },
          () => {
            $("#PFPBS_AModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "onetime",
              documentName: "PreviousBS_Audit",
            });
          }
        });
      $("#PFPBS_AModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPFPBS_A = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PFPBS_A").attr("disabled", true);
      $("#upload_btn20_PFPBS_A").hide();
      $("#upload_btnloader_PFPBS_A").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            if (PFOptReview == "Yes") {
              PFOptReview = "No";
            }
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PFPBS_ADocLink: res.data.data.PreviousBS_Audit,
                },
                () => $("#viewDocLink_PFPBS_A").css("display", "block")
              );
              PFPBS_AStatus = "remove";
              PFOptStatusArray[0].status = PFPBS_AStatus;
              this.checkDropdownValue();
              this.checkhandlePFProceedBtn();
              $("#PFPBS_AModal").modal("hide");
              $("#cancelbtn_PFPBS_A").attr("disabled", false);
              $("#upload_btn20_PFPBS_A").show();
              $("#upload_btnloader_PFPBS_A").hide();
              $("#choose_PFPBS_A").hide();
              $("#X-btn_PFPBS_A").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PFPBS_A").attr("disabled", false);
            $("#upload_btn20_PFPBS_A").show();
            $("#upload_btnloader_PFPBS_A").hide();
            $("#PFPBS_AModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PFPBS_A").attr("disabled", false);
            $("#upload_btn20_PFPBS_A").show();
            $("#upload_btnloader_PFPBS_A").hide();
            $("#PFPBS_AModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PFPBS_A").attr("disabled", false);
          $("#upload_btn20_PFPBS_A").show();
          $("#upload_btnloader_PFPBS_A").hide();
          $("#PFPBS_AModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePFPBS_A = () => {
    $("#X-btn_PFPBS_A").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "onetime",
          documentName: "PreviousBS_Audit",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              PFPBS_AStatus = res.data.data.status;
              PFOptStatusArray[0].status = PFPBS_AStatus;
              this.checkDropdownValue();
              this.checkhandlePFProceedBtn();
              $("#choose_PFPBS_A").show();
              $("#X-btn_PFPBS_A").hide();
              $("#X-btn_PFPBS_A").attr("disabled", false);
              $("#viewDocLink_PFPBS_A").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_PFPBS_A").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PFPBS_A").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handlePFPBS_Aseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PFPBS_Aseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "PreviousBS_Audit",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PFPBS_Aseecommentsbtn").hide();
          $("#PFPBS_Ahidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePFPBS_Ahidecommentsbtn = () => {
    $("#PFPBS_Aseecommentsbtn").show();
    $("#PFPBS_Aseecommentsbtn").attr("disabled", false);
    $("#PFPBS_Ahidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PFPR
  handlePFPRChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "onetime",
            documentName: "PreviousReturns",
          },
          () => {
            $("#PFPRModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "onetime",
              documentName: "PreviousReturns",
            });
          }
        });
      $("#PFPRModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPFPR = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PFPR").attr("disabled", true);
      $("#upload_btn20_PFPR").hide();
      $("#upload_btnloader_PFPR").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            if (PFOptReview == "Yes") {
              PFOptReview = "No";
            }
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PFPRDocLink: res.data.data.PreviousReturns,
                },
                () => $("#viewDocLink_PFPR").css("display", "block")
              );
              PFPRStatus = "remove";
              PFOptStatusArray[1].status = PFPRStatus;
              this.checkDropdownValue();
              this.checkhandlePFProceedBtn();
              $("#PFPRModal").modal("hide");
              $("#cancelbtn_PFPR").attr("disabled", false);
              $("#upload_btn20_PFPR").show();
              $("#upload_btnloader_PFPR").hide();
              $("#choose_PFPR").hide();
              $("#X-btn_PFPR").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PFPR").attr("disabled", false);
            $("#upload_btn20_PFPR").show();
            $("#upload_btnloader_PFPR").hide();
            $("#PFPRModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PFPR").attr("disabled", false);
            $("#upload_btn20_PFPR").show();
            $("#upload_btnloader_PFPR").hide();
            $("#PFPRModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PFPR").attr("disabled", false);
          $("#upload_btn20_PFPR").show();
          $("#upload_btnloader_PFPR").hide();
          $("#PFPRModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePFPR = () => {
    $("#X-btn_PFPR").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "onetime",
          documentName: "PreviousReturns",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              PFPRStatus = res.data.data.status;
              PFOptStatusArray[1].status = PFPRStatus;
              this.checkDropdownValue();
              this.checkhandlePFProceedBtn();
              $("#choose_PFPR").show();
              $("#X-btn_PFPR").hide();
              $("#X-btn_PFPR").attr("disabled", false);
              $("#viewDocLink_PFPR").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_PFPR").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PFPR").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handlePFPRseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PFPRseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "PreviousReturns",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PFPRseecommentsbtn").hide();
          $("#PFPRhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePFPRhidecommentsbtn = () => {
    $("#PFPRseecommentsbtn").show();
    $("#PFPRseecommentsbtn").attr("disabled", false);
    $("#PFPRhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PFincD
  handleAddPFincDFileChange = (e, index, status) => {
    PFincDadditionalReuploadIndex = index;
    PFincDadditionalReuploadStatus = status;
    $(".upload_btn20_6").show();
    $(".upload_btnloader_6").hide();
    const file = e.target.files[0];
    if (
      !PFincDAdditionalFilesNames.includes(this.state.PFincDAdd_DocName.trim())
    ) {
      const myfile = e.target.files;
      if (ChooseNowBtnsDisable == "Yes") {
        this.showNotification(
          "Files cannot be uploaded when the service is locked!"
        );
      } else if (myfile.length == 1) {
        const file = myfile[0];
        if (file.type.match("audio.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.type.match("video.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.size > 104857600) {
          this.showNotification("File too big!");
        } else if (file.name.length > 100) {
          this.showNotification("File name limit exceed!");
        } else {
          this.setState(
            {
              fileName: file.name,
              fileBase64: file,
              documentType: "incd",
              documentName: this.state.PFincDAdd_DocName,
            },
            () => {
              $(".PFincDAddModal").modal({
                backdrop: "static",
                keyboard: false,
              });
            }
          );
        }
      } else if (myfile.length > 1) {
        var zip = new JSZip();
        for (let i = 0; i < myfile.length; i++) {
          zip.file(myfile[i].name, myfile[i], {
            base64: true,
          });
        }
        zip
          .generateAsync({
            type: "blob",
          })
          .then((content) => {
            if (content.size > 104857600) {
              this.showNotification("File too big!");
            } else {
              this.setState({
                fileName: "download.zip",
                fileBase64: content,
                documentType: "incd",
                documentName: this.state.PFincDAdd_DocName,
              });
            }
          });
        $(".PFincDAddModal").modal({
          backdrop: "static",
          keyboard: false,
        });
      } else {
        this.showNotification("File cannot be null!");
      }
    } else {
      this.showNotification(
        "Document names can not be the same, Try again with different name!"
      );
    }
  };

  uploadPFincDFile = (FileIndex, FileStatus) => {
    let AdditionaldocumentName;
    if (FileStatus == "reupload") {
      $(".proceednowbtn").attr("disabled", true);
      let fileTobeUploaded = this.state.PFincDAdditionalFiles[FileIndex];
      AdditionaldocumentName = Object.keys(fileTobeUploaded)[0];
      mainaction = "reupload";
    } else {
      AdditionaldocumentName = this.state.documentName;
      mainaction = "upload";
    }
    if (AdditionaldocumentName.trim() == "") {
      $(".PFincDAddModal").modal("hide");
      this.showNotification("Enter document Name first");
    } else if (this.state.comment.length <= 250) {
      $("#cancelbtn_6").attr("disabled", true);
      $(".upload_btn20_6").hide();
      $(".upload_btnloader_6").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", AdditionaldocumentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            $(".PFincDAddModal").modal("hide");
            $(`#${FileIndex}PFincDFileseecommentsbtn`).attr("disabled", false);
            if (PFincDadditionalReupload == false) {
              PFincDAdditionalFilesNames.push(this.state.PFincDAdd_DocName);
              this.state.PFincDAdditionalFiles.pop();
              this.setState(
                {
                  comment: "",
                  PFincDAdditionalFiles: [
                    ...this.state.PFincDAdditionalFiles,
                    res.data.data,
                  ],
                },
                () => {
                  this.setState(
                    {
                      api_PFincDAdditionalFiles: "Yes",
                      ProceedDisabled: false,
                    },
                    () => {
                      this.PFincDAdditionalFilesArea();
                      this.checkDropdownValue();
                      this.checkhandlePFProceedBtn();
                    }
                  );

                  $(".PFincDadditionalInput").attr("disabled", true);
                }
              );
            } else if (PFincDadditionalReupload == true) {
              // $(".proceednowbtn").attr("disabled", false);
              var array = [...this.state.PFincDAdditionalFiles];
              array.splice(FileIndex, 1, res.data.data);
              this.setState(
                {
                  comment: "",
                  api_PFincDAdditionalFiles: "Yes",
                  PFincDAdditionalFiles: array,
                  ProceedDisabled: false,
                },
                () => {
                  this.PFincDAdditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandlePFProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_6").attr("disabled", false);
            $(".upload_btn20_6").show();
            $(".upload_btnloader_6").hide();
            $(".PFincDAddModal").modal("hide");
            this.showNotification("Something Went Wrong!");
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_6").attr("disabled", false);
            $(".upload_btn20_6").show();
            $(".upload_btnloader_6").hide();
            $(".PFincDAddModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          this.showNotification("Something went wrong, Try again!");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePFincDFile = (index) => {
    $("#X-btn_6").attr("disabled", true);
    let fileTobeDeleted = this.state.PFincDAdditionalFiles[index];
    let NameOfAddFile = Object.keys(fileTobeDeleted)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "incd",
          documentName: NameOfAddFile,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          var array = [...this.state.PFincDAdditionalFiles];
          if (res.data.data.status == "reupload") {
            array[index].status = "reupload";
            this.setState(
              {
                PFincDAdditionalFiles: array,
              },
              () => {
                this.PFincDAdditionalFilesArea();
                this.checkDropdownValue();
                this.checkhandlePFProceedBtn();
              }
            );
            this.showNotification(res.data.message);
          } else {
            array.splice(index, 1);
            if (array.length > 0) {
              this.setState(
                {
                  PFincDAdditionalFiles: array,
                },
                () => {
                  this.PFincDAdditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandlePFProceedBtn();
                }
              );
            } else if (array.length == 0) {
              this.setState(
                {
                  PFincDAdditionalFiles: [],
                  PFincDAdd_DocName: "",
                  api_PFincDAdditionalFiles: "No",
                  IsPFincDAddFileDisabled: true,
                },
                () => {
                  this.PFincDAdditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandlePFProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_6").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePFincDFileseeComments = (index) => {
    // $(".seecommentsbtn").attr("disabled", false);
    $(".commentsbtn").attr("disabled", false);
    $(`#${index}PFincDFileseecommentsbtn`).attr("disabled", true);
    let SelectedFile = this.state.PFincDAdditionalFiles[index];
    let NameOfAddFile = Object.keys(SelectedFile)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "incd",
          documentName: NameOfAddFile,
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(`#${index}PFincDFileseecommentsbtn`).hide();
          $(".hidecommentsbtn").hide();
          $(`#${index}PFincDFilehidecommentsbtn`).show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePFincDFilehideComments = (index) => {
    $(".seecommentsbtn").show();
    $(`#${index}PFincDFileseecommentsbtn`).show();
    $(`#${index}PFincDFileseecommentsbtn`).attr("disabled", false);
    $(`#${index}PFincDFilehidecommentsbtn`).hide();
    $(".hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  checkAddCommentPFincDFiles = (FileIndex, isDisabled) => {
    if (isDisabled == true) {
      return (
        <>
          <button
            className="seecommentsbtn disabledBtn_cmt PFincDFileseecommentsbtn"
            id={`${FileIndex}PFincDFileseecommentsbtn`}
            disabled
            onClick={() => this.handlePFincDFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn PFincDFilehidecommentsbtn"
            id={`${FileIndex}PFincDFilehidecommentsbtn`}
            onClick={() => this.handlePFincDFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    } else if (isDisabled == false) {
      return (
        <>
          <button
            className="seecommentsbtn commentsbtn PFincDFileseecommentsbtn"
            id={`${FileIndex}PFincDFileseecommentsbtn`}
            onClick={() => this.handlePFincDFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn PFincDFilehidecommentsbtn"
            id={`${FileIndex}PFincDFilehidecommentsbtn`}
            onClick={() => this.handlePFincDFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    }
  };

  checkStatusPFincDAddFiles = (status, link, index) => {
    PFincDAdditionalFilesIndex = index;
    if (ChooseNowBtnsDisable == "Yes") {
      $(".proceednowbtn").hide();

      if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_6">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_6"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_6">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_6"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      }
    } else {
      if (status == undefined) {
        status = "open";
      }
      if (status == "open") {
        mainaction = "upload";
        PFincDadditionalReupload = true;
        $(".proceednowbtn3").show();
        return (
          <>
            <label
              htmlFor={`ul_file_PFincD${index}`}
              className="choose_btn choose_6"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              name="file[]"
              className="upload-photo"
              id={`ul_file_PFincD${index}`}
              // disabled={this.state.IsPFincDAddFileDisabled}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handleAddPFincDFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "remove") {
        mainaction = "upload";
        $(".proceednowbtn3").show();
        return (
          <>
            <button
              className="X-btn X-btn_6"
              onClick={() => this.removePFincDFile(index)}
            >
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_1025.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_6"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_6">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_6"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "reupload") {
        PFincDadditionalReupload = true;
        mainaction = "reupload";
        $(".proceednowbtn3").show();
        $(".proceednowbtn").attr("disabled", true);
        return (
          <>
            <label
              htmlFor={`ul_file_PFincD${index}`}
              className="choose_btn choose_6"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              name="file[]"
              className="upload-photo"
              id={`ul_file_PFincD${index}`}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handleAddPFincDFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_6">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_6"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      }
    }
  };

  AdditionalPFincDFileDisabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero PFincDadditionalInput"
        value={NameOfAddFile}
        disabled
        onChange={(e) => this.handlePFincDAdd_DocNameChange(e)}
      />
    );
  };

  AdditionalPFincDFileEnabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero PFincDadditionalInput"
        value={NameOfAddFile}
        onChange={(e) => this.handlePFincDAdd_DocNameChange(e)}
      />
    );
  };

  PFincDAdditionalFilesArea = () => {
    PFincDAdditionalFilesNames = [];
    if (this.state.api_PFincDAdditionalFiles == "Yes") {
      return this.state.PFincDAdditionalFiles.map((file, index) => {
        let NameOfAddFile = Object.keys(file)[0];
        PFincDAdditionalFilesNames.push(NameOfAddFile);
        let link = file[Object.keys(file)[0]];
        let AdditionalFileInputField;
        let commentDisabled;
        if (file.status == "open" || file.status == undefined) {
          AdditionalFileInputField =
            this.AdditionalPFincDFileEnabledField(NameOfAddFile);
          commentDisabled = true;
        } else if (file.status != "open") {
          AdditionalFileInputField =
            this.AdditionalPFincDFileDisabledField(NameOfAddFile);
          commentDisabled = false;
        }
        return (
          <div className="displayflex" key={index}>
            <div className="uploadnocol">
              <p className="uploadadditionalsidetext">{index + 1}&#41;</p>
            </div>
            <div className="uploadadditionaldatacol">
              <div className="row mb-2">
                <div className="col-md-5">{AdditionalFileInputField}</div>
                <div className="col-md-7">
                  <div className="float-md-right float-left">
                    {this.checkAddCommentPFincDFiles(index, commentDisabled)}
                    {this.checkStatusPFincDAddFiles(file.status, link, index)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.api_PFincDAdditionalFiles == "No") {
      return (
        <div className="displayflex">
          <div className="uploadnocol">
            <p className="uploadadditionalsidetext">1&#41;</p>
          </div>
          <div className="uploadadditionaldatacol">
            <div className="row mb-2">
              <div className="col-md-5">
                <input
                  type="text"
                  placeholder="Document name..."
                  maxLength="150"
                  className="uploadtextfield marginzero"
                  value={this.state.PFincDAdd_DocName}
                  onChange={(e) => this.handlePFincDAdd_DocNameChange(e)}
                />
              </div>
              <div className="col-md-7">
                <div className="fl_right">
                  {this.checkAddCommentPFincDFiles(0, true)}
                  {this.checkStatusPFincDAddFiles("open", null, 0)}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  handlePFincDAdd_DocNameChange = (e) => {
    this.setState(
      {
        PFincDAdd_DocName: e.target.value,
      },
      () => {
        if (this.state.PFincDAdd_DocName.trim().length > 0) {
          this.setState({
            IsPFincDAddFileDisabled: false,
          });
        } else {
          this.setState({
            IsPFincDAddFileDisabled: true,
          });
        }
      }
    );
  };

  handlePFincDAddfileBtn = (FileIndex) => {
    const { PFincDAdd_DocName, PFincDAdditionalFiles } = this.state;

    let prevFile = PFincDAdditionalFiles[FileIndex];
    let NameOfPrevFile = Object.keys(prevFile)[0];
    let valueOffile = prevFile[Object.keys(prevFile)[0]];

    if (FileIndex == undefined) {
      this.showNotification("empty fields");
    } else if (PFincDAdd_DocName.trim() == "") {
      this.showNotification("Required fields cannot be empty!");
    } else if (valueOffile == null) {
      this.showNotification("Choose a file");
    } else {
      this.setState(
        {
          PFincDAdditionalFiles: [...this.state.PFincDAdditionalFiles, ""],
          PFincDAdd_DocName: "",
          IsPFincDAddFileDisabled: true,
        },
        () => {
          $(".PFincDadditionalInput").attr("disabled", false);
        }
      );
    }
  };

  // PFData
  handlePFDataChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "Data",
          },
          () => {
            $("#PFDataModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "Data",
            });
          }
        });
      $("#PFDataModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPFData = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PFData").attr("disabled", true);
      $("#upload_btn20_PFData").hide();
      $("#upload_btnloader_PFData").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PFDataDocLink: res.data.data.Data,
                },
                () => $("#viewDocLink_PFData").css("display", "block")
              );
              PFDataStatus = "remove";
              PFStatusArray[6].status = PFDataStatus;
              this.checkDropdownValue();
              this.checkhandlePFProceedBtn();
              $("#PFDataModal").modal("hide");
              $("#cancelbtn_PFData").attr("disabled", false);
              $("#upload_btn20_PFData").show();
              $("#upload_btnloader_PFData").hide();
              $("#choose_PFData").hide();
              $("#X-btn_PFData").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PFData").attr("disabled", false);
            $("#upload_btn20_PFData").show();
            $("#upload_btnloader_PFData").hide();
            $("#PFDataModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PFData").attr("disabled", false);
            $("#upload_btn20_PFData").show();
            $("#upload_btnloader_PFData").hide();
            $("#PFDataModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PFData").attr("disabled", false);
          $("#upload_btn20_PFData").show();
          $("#upload_btnloader_PFData").hide();
          $("#PFDataModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePFData = () => {
    $("#X-btn_PFData").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "general",
          documentName: "Data",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              PFDataStatus = res.data.data.status;
              PFStatusArray[6].status = PFDataStatus;
              this.checkDropdownValue();
              this.checkhandlePFProceedBtn();
              $("#choose_PFData").show();
              $("#X-btn_PFData").hide();
              $("#X-btn_PFData").attr("disabled", false);
              $("#viewDocLink_PFData").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_PFData").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PFData").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handlePFDataseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PFDataseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "Data",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PFDataseecommentsbtn").hide();
          $("#PFDatahidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePFDatahidecommentsbtn = () => {
    $("#PFDataseecommentsbtn").show();
    $("#PFDataseecommentsbtn").attr("disabled", false);
    $("#PFDatahidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PFBankS
  handlePFBankSChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "BankStatement",
          },
          () => {
            $("#PFBankSModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "BankStatement",
            });
          }
        });
      $("#PFBankSModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPFBankS = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PFBankS").attr("disabled", true);
      $("#upload_btn20_PFBankS").hide();
      $("#upload_btnloader_PFBankS").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PFBankSDocLink: res.data.data.BankStatement,
                },
                () => $("#viewDocLink_PFBankS").css("display", "block")
              );
              PFBankSStatus = "remove";
              PFStatusArray[7].status = PFBankSStatus;
              this.checkDropdownValue();
              this.checkhandlePFProceedBtn();
              $("#PFBankSModal").modal("hide");
              $("#cancelbtn_PFBankS").attr("disabled", false);
              $("#upload_btn20_PFBankS").show();
              $("#upload_btnloader_PFBankS").hide();
              $("#choose_PFBankS").hide();
              $("#X-btn_PFBankS").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PFBankS").attr("disabled", false);
            $("#upload_btn20_PFBankS").show();
            $("#upload_btnloader_PFBankS").hide();
            $("#PFBankSModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PFBankS").attr("disabled", false);
            $("#upload_btn20_PFBankS").show();
            $("#upload_btnloader_PFBankS").hide();
            $("#PFBankSModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PFBankS").attr("disabled", false);
          $("#upload_btn20_PFBankS").show();
          $("#upload_btnloader_PFBankS").hide();
          $("#PFBankSModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePFBankS = () => {
    $("#X-btn_PFBankS").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "general",
          documentName: "BankStatement",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              PFBankSStatus = res.data.data.status;
              PFStatusArray[7].status = PFBankSStatus;
              this.checkDropdownValue();
              this.checkhandlePFProceedBtn();
              $("#choose_PFBankS").show();
              $("#X-btn_PFBankS").hide();
              $("#X-btn_PFBankS").attr("disabled", false);
              $("#viewDocLink_PFBankS").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_PFBankS").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PFBankS").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handlePFBankSseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PFBankSseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "BankStatement",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PFBankSseecommentsbtn").hide();
          $("#PFBankShidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePFBankShidecommentsbtn = () => {
    $("#PFBankSseecommentsbtn").show();
    $("#PFBankSseecommentsbtn").attr("disabled", false);
    $("#PFBankShidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PFGSTReturn
  handlePFGSTReturnChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "GSTReturn",
          },
          () => {
            $("#PFGSTReturnModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "GSTReturn",
            });
          }
        });
      $("#PFGSTReturnModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPFGSTReturn = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PFGSTReturn").attr("disabled", true);
      $("#upload_btn20_PFGSTReturn").hide();
      $("#upload_btnloader_PFGSTReturn").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            if (PFOptReview == "Yes") {
              PFOptReview = "No";
            }
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PFGSTReturnDocLink: res.data.data.GSTReturn,
                },
                () => $("#viewDocLink_PFGSTReturn").css("display", "block")
              );
              PFGSTReturnStatus = "remove";
              PFOptStatusArray[2].status = PFGSTReturnStatus;
              this.checkDropdownValue();
              this.checkhandlePFProceedBtn();
              $("#PFGSTReturnModal").modal("hide");
              $("#cancelbtn_PFGSTReturn").attr("disabled", false);
              $("#upload_btn20_PFGSTReturn").show();
              $("#upload_btnloader_PFGSTReturn").hide();
              $("#choose_PFGSTReturn").hide();
              $("#X-btn_PFGSTReturn").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PFGSTReturn").attr("disabled", false);
            $("#upload_btn20_PFGSTReturn").show();
            $("#upload_btnloader_PFGSTReturn").hide();
            $("#PFGSTReturnModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PFGSTReturn").attr("disabled", false);
            $("#upload_btn20_PFGSTReturn").show();
            $("#upload_btnloader_PFGSTReturn").hide();
            $("#PFGSTReturnModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PFGSTReturn").attr("disabled", false);
          $("#upload_btn20_PFGSTReturn").show();
          $("#upload_btnloader_PFGSTReturn").hide();
          $("#PFGSTReturnModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePFGSTReturn = () => {
    $("#X-btn_PFGSTReturn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "general",
          documentName: "GSTReturn",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              PFGSTReturnStatus = res.data.data.status;
              PFOptStatusArray[2].status = PFGSTReturnStatus;
              this.checkDropdownValue();
              this.checkhandlePFProceedBtn();
              $("#choose_PFGSTReturn").show();
              $("#X-btn_PFGSTReturn").hide();
              $("#X-btn_PFGSTReturn").attr("disabled", false);
              $("#viewDocLink_PFGSTReturn").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_PFGSTReturn").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PFGSTReturn").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handlePFGSTReturnseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PFGSTReturnseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "GSTReturn",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PFGSTReturnseecommentsbtn").hide();
          $("#PFGSTReturnhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePFGSTReturnhidecommentsbtn = () => {
    $("#PFGSTReturnseecommentsbtn").show();
    $("#PFGSTReturnseecommentsbtn").attr("disabled", false);
    $("#PFGSTReturnhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PFTDSReturn
  handlePFTDSReturnChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "TDSReturn",
          },
          () => {
            $("#PFTDSReturnModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "TDSReturn",
            });
          }
        });
      $("#PFTDSReturnModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPFTDSReturn = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PFTDSReturn").attr("disabled", true);
      $("#upload_btn20_PFTDSReturn").hide();
      $("#upload_btnloader_PFTDSReturn").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            if (PFOptReview == "Yes") {
              PFOptReview = "No";
            }
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PFTDSReturnDocLink: res.data.data.TDSReturn,
                },
                () => $("#viewDocLink_PFTDSReturn").css("display", "block")
              );
              PFTDSReturnStatus = "remove";
              PFOptStatusArray[3].status = PFTDSReturnStatus;
              this.checkDropdownValue();
              this.checkhandlePFProceedBtn();
              $("#PFTDSReturnModal").modal("hide");
              $("#cancelbtn_PFTDSReturn").attr("disabled", false);
              $("#upload_btn20_PFTDSReturn").show();
              $("#upload_btnloader_PFTDSReturn").hide();
              $("#choose_PFTDSReturn").hide();
              $("#X-btn_PFTDSReturn").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PFTDSReturn").attr("disabled", false);
            $("#upload_btn20_PFTDSReturn").show();
            $("#upload_btnloader_PFTDSReturn").hide();
            $("#PFTDSReturnModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PFTDSReturn").attr("disabled", false);
            $("#upload_btn20_PFTDSReturn").show();
            $("#upload_btnloader_PFTDSReturn").hide();
            $("#PFTDSReturnModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PFTDSReturn").attr("disabled", false);
          $("#upload_btn20_PFTDSReturn").show();
          $("#upload_btnloader_PFTDSReturn").hide();
          $("#PFTDSReturnModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePFTDSReturn = () => {
    $("#X-btn_PFTDSReturn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "general",
          documentName: "TDSReturn",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              PFTDSReturnStatus = res.data.data.status;
              PFOptStatusArray[3].status = PFTDSReturnStatus;
              this.checkDropdownValue();
              this.checkhandlePFProceedBtn();
              $("#choose_PFTDSReturn").show();
              $("#X-btn_PFTDSReturn").hide();
              $("#X-btn_PFTDSReturn").attr("disabled", false);
              $("#viewDocLink_PFTDSReturn").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_PFTDSReturn").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PFTDSReturn").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handlePFTDSReturnseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PFTDSReturnseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "TDSReturn",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PFTDSReturnseecommentsbtn").hide();
          $("#PFTDSReturnhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePFTDSReturnhidecommentsbtn = () => {
    $("#PFTDSReturnseecommentsbtn").show();
    $("#PFTDSReturnseecommentsbtn").attr("disabled", false);
    $("#PFTDSReturnhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PFChallan
  handlePFChallanChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "Challan",
          },
          () => {
            $("#PFChallanModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "Challan",
            });
          }
        });
      $("#PFChallanModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPFChallan = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PFChallan").attr("disabled", true);
      $("#upload_btn20_PFChallan").hide();
      $("#upload_btnloader_PFChallan").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            if (PFOptReview == "Yes") {
              PFOptReview = "No";
            }
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PFChallanDocLink: res.data.data.Challan,
                },
                () => $("#viewDocLink_PFChallan").css("display", "block")
              );
              PFChallanStatus = "remove";
              PFOptStatusArray[4].status = PFChallanStatus;
              this.checkDropdownValue();
              this.checkhandlePFProceedBtn();
              $("#PFChallanModal").modal("hide");
              $("#cancelbtn_PFChallan").attr("disabled", false);
              $("#upload_btn20_PFChallan").show();
              $("#upload_btnloader_PFChallan").hide();
              $("#choose_PFChallan").hide();
              $("#X-btn_PFChallan").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PFChallan").attr("disabled", false);
            $("#upload_btn20_PFChallan").show();
            $("#upload_btnloader_PFChallan").hide();
            $("#PFChallanModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PFChallan").attr("disabled", false);
            $("#upload_btn20_PFChallan").show();
            $("#upload_btnloader_PFChallan").hide();
            $("#PFChallanModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PFChallan").attr("disabled", false);
          $("#upload_btn20_PFChallan").show();
          $("#upload_btnloader_PFChallan").hide();
          $("#PFChallanModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePFChallan = () => {
    $("#X-btn_PFChallan").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "general",
          documentName: "Challan",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              PFChallanStatus = res.data.data.status;
              PFOptStatusArray[4].status = PFChallanStatus;
              this.checkDropdownValue();
              this.checkhandlePFProceedBtn();
              $("#choose_PFChallan").show();
              $("#X-btn_PFChallan").hide();
              $("#X-btn_PFChallan").attr("disabled", false);
              $("#viewDocLink_PFChallan").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_PFChallan").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PFChallan").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handlePFChallanseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PFChallanseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "Challan",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PFChallanseecommentsbtn").hide();
          $("#PFChallanhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePFChallanhidecommentsbtn = () => {
    $("#PFChallanseecommentsbtn").show();
    $("#PFChallanseecommentsbtn").attr("disabled", false);
    $("#PFChallanhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PFAdditional
  handlePFAddFileChange = (e, index, status) => {
    PFadditionalReuploadIndex = index;
    PFadditionalReuploadStatus = status;
    $(".upload_btn20_7").show();
    $(".upload_btnloader_7").hide();
    const file = e.target.files[0];
    if (!PFAdditionalFilesNames.includes(this.state.PFAdd_DocName.trim())) {
      const myfile = e.target.files;
      if (ChooseNowBtnsDisable == "Yes") {
        this.showNotification(
          "Files cannot be uploaded when the service is locked!"
        );
      } else if (myfile.length == 1) {
        const file = myfile[0];
        if (file.type.match("audio.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.type.match("video.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.size > 104857600) {
          this.showNotification("File too big!");
        } else if (file.name.length > 100) {
          this.showNotification("File name limit exceed!");
        } else {
          this.setState(
            {
              fileName: file.name,
              fileBase64: file,
              documentType: "additional",
              documentName: this.state.PFAdd_DocName,
            },
            () => {
              $(".PFAddModal").modal({
                backdrop: "static",
                keyboard: false,
              });
            }
          );
        }
      } else if (myfile.length > 1) {
        var zip = new JSZip();
        for (let i = 0; i < myfile.length; i++) {
          zip.file(myfile[i].name, myfile[i], {
            base64: true,
          });
        }
        zip
          .generateAsync({
            type: "blob",
          })
          .then((content) => {
            if (content.size > 104857600) {
              this.showNotification("File too big!");
            } else {
              this.setState({
                fileName: "download.zip",
                fileBase64: content,
                documentType: "additional",
                documentName: this.state.PFAdd_DocName,
              });
            }
          });
        $(".PFAddModal").modal({
          backdrop: "static",
          keyboard: false,
        });
      } else {
        this.showNotification("File cannot be null!");
      }
    } else {
      this.showNotification(
        "Document names can not be the same, Try again with different name!"
      );
    }
  };

  uploadPFFile = (FileIndex, FileStatus) => {
    let AdditionaldocumentName1;
    if (FileStatus == "reupload") {
      $(".proceednowbtn").attr("disabled", true);
      let fileTobeUploaded = this.state.PFAdditionalFiles[FileIndex];
      AdditionaldocumentName1 = Object.keys(fileTobeUploaded)[0];
      mainaction = "reupload";
    } else {
      AdditionaldocumentName1 = this.state.documentName;
      mainaction = "upload";
    }
    if (AdditionaldocumentName1.trim() == "") {
      $(".PFAddModal").modal("hide");
      this.showNotification("Enter document Name first");
    } else if (this.state.comment.length <= 250) {
      $(".cancelbtn_7").attr("disabled", true);
      $(".upload_btn20_7").hide();
      $(".upload_btnloader_7").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", AdditionaldocumentName1);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            $(".PFAddModal").modal("hide");
            $(".cancelbtn_7").attr("disabled", false);
            $(`#${FileIndex}PFFileseecommentsbtn`).attr("disabled", false);
            if (PFadditionalReupload == false) {
              PFAdditionalFilesNames.push(this.state.PFAdd_DocName);
              this.state.PFAdditionalFiles.pop();
              this.setState(
                {
                  comment: "",
                  PFAdditionalFiles: [
                    ...this.state.PFAdditionalFiles,
                    res.data.data,
                  ],
                },
                () => {
                  this.setState(
                    {
                      api_PFAdditionalFiles: "Yes",
                      ProceedDisabled: false,
                    },
                    () => {
                      this.PFAdditionalFilesArea();
                      this.checkDropdownValue();
                      this.checkhandlePFProceedBtn();
                    }
                  );

                  $(".PFadditionalInput").attr("disabled", true);
                }
              );
            } else if (PFadditionalReupload == true) {
              // $(".proceednowbtn").attr("disabled", false);
              var array = [...this.state.PFAdditionalFiles];
              array.splice(FileIndex, 1, res.data.data);
              this.setState(
                {
                  comment: "",
                  api_PFAdditionalFiles: "Yes",
                  PFAdditionalFiles: array,
                  ProceedDisabled: false,
                },
                () => {
                  this.PFAdditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandlePFProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".PFAddModal").modal("hide");
            this.showNotification("Something Went Wrong!");
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".PFAddModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          this.showNotification("Something went wrong, Try again!");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePFFile = (index) => {
    $("#X-btn_7").attr("disabled", true);
    let fileTobeDeleted = this.state.PFAdditionalFiles[index];
    let NameOfAddFile = Object.keys(fileTobeDeleted)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "additional",
          documentName: NameOfAddFile,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          var array = [...this.state.PFAdditionalFiles];
          if (res.data.data.status == "reupload") {
            array[index].status = "reupload";
            this.setState(
              {
                PFAdditionalFiles: array,
              },
              () => {
                this.PFAdditionalFilesArea();
                this.checkDropdownValue();
                this.checkhandlePFProceedBtn();
              }
            );
            this.showNotification(res.data.message);
          } else {
            array.splice(index, 1);
            if (array.length > 0) {
              this.setState(
                {
                  PFAdditionalFiles: array,
                },
                () => {
                  this.PFAdditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandlePFProceedBtn();
                }
              );
            } else if (array.length == 0) {
              this.setState(
                {
                  PFAdditionalFiles: [],
                  PFAdd_DocName: "",
                  api_PFAdditionalFiles: "No",
                  IsPFAddFileDisabled: true,
                },
                () => {
                  this.PFAdditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandlePFProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_7").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePFFileseeComments = (index) => {
    // $(".seecommentsbtn").attr("disabled", false);
    $(".commentsbtn").attr("disabled", false);
    $(`#${index}PFFileseecommentsbtn`).attr("disabled", true);
    let SelectedFile = this.state.PFAdditionalFiles[index];
    let NameOfAddFile = Object.keys(SelectedFile)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "additional",
          documentName: NameOfAddFile,
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(`#${index}PFFileseecommentsbtn`).hide();
          $(".hidecommentsbtn").hide();
          $(`#${index}PFFilehidecommentsbtn`).show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePFFilehideComments = (index) => {
    $(".seecommentsbtn").show();
    $(`#${index}PFFileseecommentsbtn`).show();
    $(`#${index}PFFileseecommentsbtn`).attr("disabled", false);
    $(`#${index}PFFilehidecommentsbtn`).hide();
    $(".hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  checkAddCommentPFFiles = (FileIndex, isDisabled) => {
    if (isDisabled == true) {
      return (
        <>
          <button
            className="seecommentsbtn disabledBtn_cmt PFFileseecommentsbtn"
            id={`${FileIndex}PFFileseecommentsbtn`}
            disabled
            onClick={() => this.handlePFFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn PFFilehidecommentsbtn"
            id={`${FileIndex}PFFilehidecommentsbtn`}
            onClick={() => this.handlePFFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    } else if (isDisabled == false) {
      return (
        <>
          <button
            className="seecommentsbtn commentsbtn PFFileseecommentsbtn"
            id={`${FileIndex}PFFileseecommentsbtn`}
            onClick={() => this.handlePFFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn PFFilehidecommentsbtn"
            id={`${FileIndex}PFFilehidecommentsbtn`}
            onClick={() => this.handlePFFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    }
  };

  checkStatusPFAddFiles = (status, link, index) => {
    PFAdditionalFilesIndex = index;
    if (ChooseNowBtnsDisable == "Yes") {
      $(".proceednowbtn").hide();
      if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      }
    } else {
      if (status == undefined) {
        status = "open";
      }
      if (status == "open") {
        mainaction = "upload";
        PFadditionalReupload = true;
        $(".proceednowbtn3").show();
        return (
          <>
            <label
              htmlFor={`ul_file_PFAdd${index}`}
              className="choose_btn choose_PFadd"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              name="file[]"
              className="upload-photo"
              id={`ul_file_PFAdd${index}`}
              // disabled={this.state.IsPFAddFileDisabled}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handlePFAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "remove") {
        mainaction = "upload";
        $(".proceednowbtn3").show();
        return (
          <>
            <button
              className="X-btn X-btn_7"
              onClick={() => this.removePFFile(index)}
            >
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_1025.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "reupload") {
        PFadditionalReupload = true;
        mainaction = "reupload";
        $(".proceednowbtn3").show();
        $(".proceednowbtn").attr("disabled", true);
        return (
          <>
            <label
              htmlFor={`ul_file_PFAdd${index}`}
              className="choose_btn choose_PFadd"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              name="file[]"
              className="upload-photo"
              id={`ul_file_PFAdd${index}`}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handlePFAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      }
    }
  };
  AdditionalPFFileDisabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero PFadditionalInput"
        value={NameOfAddFile}
        disabled
        onChange={(e) => this.handlePFAdd_DocNameChange(e)}
      />
    );
  };

  AdditionalPFFileEnabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero PFadditionalInput"
        value={NameOfAddFile}
        onChange={(e) => this.handlePFAdd_DocNameChange(e)}
      />
    );
  };

  PFAdditionalFilesArea = () => {
    PFAdditionalFilesNames = [];
    if (this.state.api_PFAdditionalFiles == "Yes") {
      return this.state.PFAdditionalFiles.map((file, index) => {
        let NameOfAddFile = Object.keys(file)[0];
        PFAdditionalFilesNames.push(NameOfAddFile);
        let link = file[Object.keys(file)[0]];
        let AdditionalFileInputField;
        let commentDisabled;
        if (file.status == "open" || file.status == undefined) {
          AdditionalFileInputField =
            this.AdditionalPFFileEnabledField(NameOfAddFile);
          commentDisabled = true;
        } else if (file.status != "open") {
          AdditionalFileInputField =
            this.AdditionalPFFileDisabledField(NameOfAddFile);
          commentDisabled = false;
        }
        return (
          <div className="displayflex" key={index}>
            <div className="uploadnocol">
              <p className="uploadadditionalsidetext">{index + 1}&#41;</p>
            </div>
            <div className="uploadadditionaldatacol">
              <div className="row mb-2">
                <div className="col-md-5">{AdditionalFileInputField}</div>
                <div className="col-md-7">
                  <div className="float-md-right float-left">
                    {this.checkAddCommentPFFiles(index, commentDisabled)}
                    {this.checkStatusPFAddFiles(file.status, link, index)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.api_PFAdditionalFiles == "No") {
      return (
        <div className="displayflex">
          <div className="uploadnocol">
            <p className="uploadadditionalsidetext">1&#41;</p>
          </div>
          <div className="uploadadditionaldatacol">
            <div className="row mb-2">
              <div className="col-md-5">
                <input
                  type="text"
                  placeholder="Document name..."
                  maxLength="150"
                  className="uploadtextfield marginzero"
                  value={this.state.PFAdd_DocName}
                  onChange={(e) => this.handlePFAdd_DocNameChange(e)}
                />
              </div>
              <div className="col-md-7">
                <div className="fl_right">
                  {this.checkAddCommentPFFiles(0, true)}
                  {this.checkStatusPFAddFiles("open", null, 0)}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  handlePFAdd_DocNameChange = (e) => {
    this.setState(
      {
        PFAdd_DocName: e.target.value,
      },
      () => {
        if (this.state.PFAdd_DocName.trim().length > 0) {
          this.setState({
            IsPFAddFileDisabled: false,
          });
        } else {
          this.setState({
            IsPFAddFileDisabled: true,
          });
        }
      }
    );
  };

  handleAddPFfileBtn = (FileIndex) => {
    const { PFAdd_DocName, PFAdditionalFiles } = this.state;

    let prevFile = PFAdditionalFiles[FileIndex];
    let NameOfPrevFile = Object.keys(prevFile)[0];
    let valueOffile = prevFile[Object.keys(prevFile)[0]];

    if (FileIndex == undefined) {
      this.showNotification("empty fields");
    } else if (PFAdd_DocName.trim() == "") {
      this.showNotification("Required fields cannot be empty!");
    } else if (valueOffile == null) {
      this.showNotification("Choose a file");
    } else {
      this.setState(
        {
          PFAdditionalFiles: [...this.state.PFAdditionalFiles, ""],
          PFAdd_DocName: "",
          IsPFAddFileDisabled: true,
        },
        () => {
          $(".PFadditionalInput").attr("disabled", false);
        }
      );
    }
  };

  PFcheckRemainingReuploads = () => {
    if (
      PFAadhar1Status != "reupload" &&
      PFAadhar2Status != "reupload" &&
      PFPan1Status != "reupload" &&
      PFPan2Status != "reupload" &&
      PFDeedStatus != "reupload" &&
      PFBPStatus != "reupload" &&
      PFPBS_AStatus != "reupload" &&
      PFPRStatus != "reupload" &&
      PFDataStatus != "reupload" &&
      PFGSTReturnStatus != "reupload" &&
      PFTDSReturnStatus != "reupload" &&
      PFChallanStatus != "reupload" &&
      PFRCStatus != "reupload" &&
      anyPFincDAdditionalReuploadRemaining != "Yes" &&
      anyPFAdditionalReuploadRemaining != "Yes"
    ) {
      AnyReuploadOccuredPF = "No";
    } else {
      $(".proceednowbtn3").show();
      AnyReuploadOccuredPF = "Yes";
    }
  };

  checkhandlePFProceedBtn = () => {
    let statusCheck = this.statusCheckFunction("reupload", PFStatusArray);
    PFOptReupload = this.countOptReupload1("reupload", PFOptStatusArray);
    PFincDadditionalStatusArrayCheck = this.statusCheckFunction(
      "reupload",
      this.state.PFincDAdditionalFiles
    );
    PFadditionalStatusArrayCheck = this.statusCheckFunction(
      "reupload",
      this.state.PFAdditionalFiles
    );

    if (rejectstatus == "Yes") {
      $(".proceednowbtn3").show();
      $(".proceednowbtn3").attr("disabled", false);
      PFproceednowforword = "success";
    } else if (
      AnyReuploadOccuredPF == "Yes" &&
      ((PFOptReview == "No" && PFOptReupload != OptReuploadCountPF) ||
        PFOptReview == "Yes") &&
      statusCheck == undefined &&
      PFincDadditionalStatusArrayCheck == undefined &&
      PFadditionalStatusArrayCheck == undefined
    ) {
      $(".proceednowbtn3").attr("disabled", false);
      PFproceednowforword = "success";
    } else if (
      PFAadhar1Status == "remove" &&
      PFAadhar2Status == "remove" &&
      PFPan1Status == "remove" &&
      PFPan2Status == "remove" &&
      PFDeedStatus == "remove" &&
      PFBPStatus == "remove" &&
      PFDataStatus == "remove" &&
      PFBankSStatus == "remove" &&
      PFincDadditionalStatusArrayCheck == undefined &&
      PFadditionalStatusArrayCheck == undefined
    ) {
      $(".proceednowbtn3").attr("disabled", false);
      PFproceednowforword = "success";
    } else if (
      PFAadhar1Status == "review" &&
      PFAadhar2Status == "review" &&
      PFPan1Status == "review" &&
      PFPan2Status == "review" &&
      PFDeedStatus == "review" &&
      PFBPStatus == "review" &&
      PFDataStatus == "remove" &&
      PFBankSStatus == "remove" &&
      PFincDadditionalStatusArrayCheck == undefined &&
      PFadditionalStatusArrayCheck == undefined
    ) {
      $(".proceednowbtn3").attr("disabled", false);
      PFproceednowforword = "success";
    } else if (
      PFAadhar1Status == "done" &&
      PFAadhar2Status == "done" &&
      PFPan1Status == "done" &&
      PFPan2Status == "done" &&
      PFDeedStatus == "done" &&
      PFBPStatus == "done" &&
      PFDataStatus == "remove" &&
      PFBankSStatus == "remove" &&
      PFincDadditionalStatusArrayCheck == undefined &&
      PFadditionalStatusArrayCheck == undefined
    ) {
      $(".proceednowbtn3").attr("disabled", false);
      PFproceednowforword = "success";
    } else {
      PFproceednowforword = "fail";
      $(".proceednowbtn3").attr("disabled", true);
    }
  };

  handlePFProceedBtn = () => {
    this.checkhandlePFProceedBtn();
    if (PFproceednowforword == "success") {
      if (
        this.state.itrfusername.trim().length < 96 &&
        this.state.itrfpassword.trim().length < 96
      ) {
        axios
          .post(
            process.env.REACT_APP_API_BASE_URL + "p=lockService",
            {
              userId: Decuserid,
              requestType: temp,
              agentId: Decagentid,

              serviceId: Decserviceid,
              serviceName: Decservicename,
              serviceType: this.state.serviceType,
              FY: this.state.SelectedYear,
              Month: "",
              inputFields: [
                {
                  inputFieldName: "PortalUserID",
                  inputFieldValue: this.state.itrfusername.trim(),
                  inputFieldtype: "onetime",
                },
                {
                  inputFieldName: "PortalPassword",
                  inputFieldValue: this.state.itrfpassword.trim(),
                  inputFieldtype: "onetime",
                },
              ],
            },
            {
              headers: {
                Authkey: process.env.REACT_APP_API_KEY,
                "App-Token-Access": Decjwttoken,
              },
              auth: {
                username: process.env.REACT_APP_API_USERNAME,
                password: process.env.REACT_APP_API_PASSWORD,
              },
            }
          )
          .then((res) => {
            if (res.data.code == "200" && res.data.status == "success") {
              this.showNotification(res.data.message);
              this.getserviceInformationApi();
              this.getdeliveredDocuments();
            } else if (
              res.data.code == "201" &&
              res.data.status == "rejected"
            ) {
              this.showNotification(res.data.message);
              this.getserviceInformationApi();
              this.getdeliveredDocuments();
            } else if (
              res.data.code == "201" &&
              res.data.status == "invalidauth"
            ) {
              this.logout();
            } else if (res.data.code == "201" && res.data.status == "timeOut") {
              localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
              this.props.history.push({
                pathname: "/dashboard",
                state: {
                  timeout: true,
                },
              });
            } else {
              this.showNotification(res.data.message);
            }
          })
          .catch((err) => {});
      } else if (this.state.itrfusername.trim().length > 95) {
        this.showNotification("Max length exceed!");
      } else if (this.state.itrfpassword.trim().length > 95) {
        this.showNotification("Max length exceed!");
      } else {
        this.showNotification("Required fields are empty!");
      }
    } else {
      this.showNotification("Required fields are empty!");
    }
  };

  // CL section
  // CLAadhar1
  handleCLAadhar1Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "onetime",
            documentName: "AadhaarCardApplicant1",
          },
          () => {
            $("#CLAadhar1Modal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "onetime",
              documentName: "AadhaarCardApplicant1",
            });
          }
        });
      $("#CLAadhar1Modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadCLAadhar1 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_CLAadhar1").attr("disabled", true);
      $("#upload_btn20_CLAadhar1").hide();
      $("#upload_btnloader_CLAadhar1").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  CLAadhar1DocLink: res.data.data.AadhaarCardApplicant1,
                },
                () => $("#viewDocLink_CLAadhar1").css("display", "block")
              );
              CLAadhar1Status = "remove";
              CLStatusArray[0].status = CLAadhar1Status;
              this.checkDropdownValue();
              this.checkhandleCLProceedBtn();
              $("#CLAadhar1Modal").modal("hide");
              $("#cancelbtn_CLAadhar1").attr("disabled", false);
              $("#upload_btn20_CLAadhar1").show();
              $("#upload_btnloader_CLAadhar1").hide();
              $("#choose_CLAadhar1").hide();
              $("#X-btn_CLAadhar1").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_CLAadhar1").attr("disabled", false);
            $("#upload_btn20_CLAadhar1").show();
            $("#upload_btnloader_CLAadhar1").hide();
            $("#CLAadhar1Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_CLAadhar1").attr("disabled", false);
            $("#upload_btn20_CLAadhar1").show();
            $("#upload_btnloader_CLAadhar1").hide();
            $("#CLAadhar1Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_CLAadhar1").attr("disabled", false);
          $("#upload_btn20_CLAadhar1").show();
          $("#upload_btnloader_CLAadhar1").hide();
          $("#CLAadhar1Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeCLAadhar1 = () => {
    $("#X-btn_CLAadhar1").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "onetime",
          documentName: "AadhaarCardApplicant1",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              CLAadhar1Status = res.data.data.status;
              CLStatusArray[0].status = CLAadhar1Status;
              this.checkDropdownValue();
              this.checkhandleCLProceedBtn();
              $("#choose_CLAadhar1").show();
              $("#X-btn_CLAadhar1").hide();
              $("#X-btn_CLAadhar1").attr("disabled", false);
              $("#viewDocLink_CLAadhar1").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_CLAadhar1").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_CLAadhar1").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handleCLAadhar1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#CLAadhar1seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "AadhaarCardApplicant1",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#CLAadhar1seecommentsbtn").hide();
          $("#CLAadhar1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleCLAadhar1hidecommentsbtn = () => {
    $("#CLAadhar1seecommentsbtn").show();
    $("#CLAadhar1seecommentsbtn").attr("disabled", false);
    $("#CLAadhar1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // CLAadhar2
  handleCLAadhar2Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "onetime",
            documentName: "AadhaarCardApplicant2",
          },
          () => {
            $("#CLAadhar2Modal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "onetime",
              documentName: "AadhaarCardApplicant2",
            });
          }
        });
      $("#CLAadhar2Modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadCLAadhar2 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_CLAadhar2").attr("disabled", true);
      $("#upload_btn20_CLAadhar2").hide();
      $("#upload_btnloader_CLAadhar2").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  CLAadhar2DocLink: res.data.data.AadhaarCardApplicant2,
                },
                () => $("#viewDocLink_CLAadhar2").css("display", "block")
              );
              CLAadhar2Status = "remove";
              CLStatusArray[1].status = CLAadhar2Status;
              this.checkDropdownValue();
              this.checkhandleCLProceedBtn();
              $("#CLAadhar2Modal").modal("hide");
              $("#cancelbtn_CLAadhar2").attr("disabled", false);
              $("#upload_btn20_CLAadhar2").show();
              $("#upload_btnloader_CLAadhar2").hide();
              $("#choose_CLAadhar2").hide();
              $("#X-btn_CLAadhar2").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_CLAadhar2").attr("disabled", false);
            $("#upload_btn20_CLAadhar2").show();
            $("#upload_btnloader_CLAadhar2").hide();
            $("#CLAadhar2Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_CLAadhar2").attr("disabled", false);
            $("#upload_btn20_CLAadhar2").show();
            $("#upload_btnloader_CLAadhar2").hide();
            $("#CLAadhar2Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_CLAadhar2").attr("disabled", false);
          $("#upload_btn20_CLAadhar2").show();
          $("#upload_btnloader_CLAadhar2").hide();
          $("#CLAadhar2Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeCLAadhar2 = () => {
    $("#X-btn_CLAadhar2").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "onetime",
          documentName: "AadhaarCardApplicant2",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              CLAadhar2Status = res.data.data.status;
              CLStatusArray[1].status = CLAadhar2Status;
              this.checkDropdownValue();
              this.checkhandleCLProceedBtn();
              $("#choose_CLAadhar2").show();
              $("#X-btn_CLAadhar2").hide();
              $("#X-btn_CLAadhar2").attr("disabled", false);
              $("#viewDocLink_CLAadhar2").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_CLAadhar2").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_CLAadhar2").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handleCLAadhar2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#CLAadhar2seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "AadhaarCardApplicant2",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#CLAadhar2seecommentsbtn").hide();
          $("#CLAadhar2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleCLAadhar2hidecommentsbtn = () => {
    $("#CLAadhar2seecommentsbtn").show();
    $("#CLAadhar2seecommentsbtn").attr("disabled", false);
    $("#CLAadhar2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // CLPan1
  handleCLPan1Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "onetime",
            documentName: "PANCardApplicant1",
          },
          () => {
            $("#CLPan1Modal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "onetime",
              documentName: "PANCardApplicant1",
            });
          }
        });
      $("#CLPan1Modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadCLPan1 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_CLPan1").attr("disabled", true);
      $("#upload_btn20_CLPan1").hide();
      $("#upload_btnloader_CLPan1").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  CLPan1DocLink: res.data.data.PANCardApplicant1,
                },
                () => $("#viewDocLink_CLPan1").css("display", "block")
              );
              CLPan1Status = "remove";
              CLStatusArray[2].status = CLPan1Status;
              this.checkDropdownValue();
              this.checkhandleCLProceedBtn();
              $("#CLPan1Modal").modal("hide");
              $("#cancelbtn_CLPan1").attr("disabled", false);
              $("#upload_btn20_CLPan1").show();
              $("#upload_btnloader_CLPan1").hide();
              $("#choose_CLPan1").hide();
              $("#X-btn_CLPan1").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_CLPan1").attr("disabled", false);
            $("#upload_btn20_CLPan1").show();
            $("#upload_btnloader_CLPan1").hide();
            $("#CLPan1Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_CLPan1").attr("disabled", false);
            $("#upload_btn20_CLPan1").show();
            $("#upload_btnloader_CLPan1").hide();
            $("#CLPan1Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_CLPan1").attr("disabled", false);
          $("#upload_btn20_CLPan1").show();
          $("#upload_btnloader_CLPan1").hide();
          $("#CLPan1Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeCLPan1 = () => {
    $("#X-btn_CLPan1").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "onetime",
          documentName: "PANCardApplicant1",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              CLPan1Status = res.data.data.status;
              CLStatusArray[2].status = CLPan1Status;
              this.checkDropdownValue();
              this.checkhandleCLProceedBtn();
              $("#choose_CLPan1").show();
              $("#X-btn_CLPan1").hide();
              $("#X-btn_CLPan1").attr("disabled", false);
              $("#viewDocLink_CLPan1").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_CLPan1").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_CLPan1").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handleCLPan1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#CLPan1seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "PANCardApplicant1",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#CLPan1seecommentsbtn").hide();
          $("#CLPan1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleCLPan1hidecommentsbtn = () => {
    $("#CLPan1seecommentsbtn").show();
    $("#CLPan1seecommentsbtn").attr("disabled", false);
    $("#CLPan1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // CLPAN2
  handleCLPan2Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "onetime",
            documentName: "PANCardApplicant2",
          },
          () => {
            $("#CLPan2Modal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "onetime",
              documentName: "PANCardApplicant2",
            });
          }
        });
      $("#CLPan2Modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadCLPan2 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_CLPan2").attr("disabled", true);
      $("#upload_btn20_CLPan2").hide();
      $("#upload_btnloader_CLPan2").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  CLPan2DocLink: res.data.data.PANCardApplicant2,
                },
                () => $("#viewDocLink_CLPan2").css("display", "block")
              );
              CLPan2Status = "remove";
              CLStatusArray[3].status = CLPan2Status;
              this.checkDropdownValue();
              this.checkhandleCLProceedBtn();
              $("#CLPan2Modal").modal("hide");
              $("#cancelbtn_CLPan2").attr("disabled", false);
              $("#upload_btn20_CLPan2").show();
              $("#upload_btnloader_CLPan2").hide();
              $("#choose_CLPan2").hide();
              $("#X-btn_CLPan2").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_CLPan2").attr("disabled", false);
            $("#upload_btn20_CLPan2").show();
            $("#upload_btnloader_CLPan2").hide();
            $("#CLPan2Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_CLPan2").attr("disabled", false);
            $("#upload_btn20_CLPan2").show();
            $("#upload_btnloader_CLPan2").hide();
            $("#CLPan2Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_CLPan2").attr("disabled", false);
          $("#upload_btn20_CLPan2").show();
          $("#upload_btnloader_CLPan2").hide();
          $("#CLPan2Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeCLPan2 = () => {
    $("#X-btn_CLPan2").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "onetime",
          documentName: "PANCardApplicant2",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              CLPan2Status = res.data.data.status;
              CLStatusArray[3].status = CLPan2Status;
              this.checkDropdownValue();
              this.checkhandleCLProceedBtn();
              $("#choose_CLPan2").show();
              $("#X-btn_CLPan2").hide();
              $("#X-btn_CLPan2").attr("disabled", false);
              $("#viewDocLink_CLPan2").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_CLPan2").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_CLPan2").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handleCLPan2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#CLPan2seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "PANCardApplicant2",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#CLPan2seecommentsbtn").hide();
          $("#CLPan2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleCLPan2hidecommentsbtn = () => {
    $("#CLPan2seecommentsbtn").show();
    $("#CLPan2seecommentsbtn").attr("disabled", false);
    $("#CLPan2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // CLIC
  handleCLICChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "onetime",
            documentName: "IncorporationCertificate",
          },
          () => {
            $("#CLICModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "onetime",
              documentName: "IncorporationCertificate",
            });
          }
        });
      $("#CLICModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadCLIC = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_CLIC").attr("disabled", true);
      $("#upload_btn20_CLIC").hide();
      $("#upload_btnloader_CLIC").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  CLICDocLink: res.data.data.IncorporationCertificate,
                },
                () => $("#viewDocLink_CLIC").css("display", "block")
              );
              CLICStatus = "remove";
              CLStatusArray[4].status = CLICStatus;
              this.checkDropdownValue();
              this.checkhandleCLProceedBtn();
              $("#CLICModal").modal("hide");
              $("#cancelbtn_CLIC").attr("disabled", false);
              $("#upload_btn20_CLIC").show();
              $("#upload_btnloader_CLIC").hide();
              $("#choose_CLIC").hide();
              $("#X-btn_CLIC").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_CLIC").attr("disabled", false);
            $("#upload_btn20_CLIC").show();
            $("#upload_btnloader_CLIC").hide();
            $("#CLICModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_CLIC").attr("disabled", false);
            $("#upload_btn20_CLIC").show();
            $("#upload_btnloader_CLIC").hide();
            $("#CLICModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_CLIC").attr("disabled", false);
          $("#upload_btn20_CLIC").show();
          $("#upload_btnloader_CLIC").hide();
          $("#CLICModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeCLIC = () => {
    $("#X-btn_CLIC").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "onetime",
          documentName: "IncorporationCertificate",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              CLICStatus = res.data.data.status;
              CLStatusArray[4].status = CLICStatus;
              this.checkDropdownValue();
              this.checkhandleCLProceedBtn();
              $("#choose_CLIC").show();
              $("#X-btn_CLIC").hide();
              $("#X-btn_CLIC").attr("disabled", false);
              $("#viewDocLink_CLIC").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_CLIC").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_CLIC").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handleCLICseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#CLICseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "IncorporationCertificate",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#CLICseecommentsbtn").hide();
          $("#CLIChidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleCLIChidecommentsbtn = () => {
    $("#CLICseecommentsbtn").show();
    $("#CLICseecommentsbtn").attr("disabled", false);
    $("#CLIChidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // CLMOA
  handleCLMOAChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "onetime",
            documentName: "MOA",
          },
          () => {
            $("#CLMOAModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "onetime",
              documentName: "MOA",
            });
          }
        });
      $("#CLMOAModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadCLMOA = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_CLMOA").attr("disabled", true);
      $("#upload_btn20_CLMOA").hide();
      $("#upload_btnloader_CLMOA").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  CLMOADocLink: res.data.data.MOA,
                },
                () => $("#viewDocLink_CLMOA").css("display", "block")
              );
              CLMOAStatus = "remove";
              CLStatusArray[5].status = CLMOAStatus;
              this.checkDropdownValue();
              this.checkhandleCLProceedBtn();
              $("#CLMOAModal").modal("hide");
              $("#cancelbtn_CLMOA").attr("disabled", false);
              $("#upload_btn20_CLMOA").show();
              $("#upload_btnloader_CLMOA").hide();
              $("#choose_CLMOA").hide();
              $("#X-btn_CLMOA").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_CLMOA").attr("disabled", false);
            $("#upload_btn20_CLMOA").show();
            $("#upload_btnloader_CLMOA").hide();
            $("#CLMOAModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_CLMOA").attr("disabled", false);
            $("#upload_btn20_CLMOA").show();
            $("#upload_btnloader_CLMOA").hide();
            $("#CLMOAModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_CLMOA").attr("disabled", false);
          $("#upload_btn20_CLMOA").show();
          $("#upload_btnloader_CLMOA").hide();
          $("#CLMOAModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeCLMOA = () => {
    $("#X-btn_CLMOA").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "onetime",
          documentName: "MOA",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              CLMOAStatus = res.data.data.status;
              CLStatusArray[5].status = CLMOAStatus;
              this.checkDropdownValue();
              this.checkhandleCLProceedBtn();
              $("#choose_CLMOA").show();
              $("#X-btn_CLMOA").hide();
              $("#X-btn_CLMOA").attr("disabled", false);
              $("#viewDocLink_CLMOA").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_CLMOA").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_CLMOA").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handleCLMOAseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#CLMOAseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "MOA",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#CLMOAseecommentsbtn").hide();
          $("#CLMOAhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleCLMOAhidecommentsbtn = () => {
    $("#CLMOAseecommentsbtn").show();
    $("#CLMOAseecommentsbtn").attr("disabled", false);
    $("#CLMOAhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // CLAOA
  handleCLAOAChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "onetime",
            documentName: "AOA",
          },
          () => {
            $("#CLAOAModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "onetime",
              documentName: "AOA",
            });
          }
        });
      $("#CLAOAModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadCLAOA = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_CLAOA").attr("disabled", true);
      $("#upload_btn20_CLAOA").hide();
      $("#upload_btnloader_CLAOA").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  CLAOADocLink: res.data.data.AOA,
                },
                () => $("#viewDocLink_CLAOA").css("display", "block")
              );
              CLAOAStatus = "remove";
              CLStatusArray[6].status = CLAOAStatus;
              this.checkDropdownValue();
              this.checkhandleCLProceedBtn();
              $("#CLAOAModal").modal("hide");
              $("#cancelbtn_CLAOA").attr("disabled", false);
              $("#upload_btn20_CLAOA").show();
              $("#upload_btnloader_CLAOA").hide();
              $("#choose_CLAOA").hide();
              $("#X-btn_CLAOA").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_CLAOA").attr("disabled", false);
            $("#upload_btn20_CLAOA").show();
            $("#upload_btnloader_CLAOA").hide();
            $("#CLAOAModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_CLAOA").attr("disabled", false);
            $("#upload_btn20_CLAOA").show();
            $("#upload_btnloader_CLAOA").hide();
            $("#CLAOAModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_CLAOA").attr("disabled", false);
          $("#upload_btn20_CLAOA").show();
          $("#upload_btnloader_CLAOA").hide();
          $("#CLAOAModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeCLAOA = () => {
    $("#X-btn_CLAOA").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "onetime",
          documentName: "AOA",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              CLAOAStatus = res.data.data.status;
              CLStatusArray[6].status = CLAOAStatus;
              this.checkDropdownValue();
              this.checkhandleCLProceedBtn();
              $("#choose_CLAOA").show();
              $("#X-btn_CLAOA").hide();
              $("#X-btn_CLAOA").attr("disabled", false);
              $("#viewDocLink_CLAOA").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_CLAOA").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_CLAOA").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handleCLAOAseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#CLAOAseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "AOA",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#CLAOAseecommentsbtn").hide();
          $("#CLAOAhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleCLAOAhidecommentsbtn = () => {
    $("#CLAOAseecommentsbtn").show();
    $("#CLAOAseecommentsbtn").attr("disabled", false);
    $("#CLAOAhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // CLBP
  handleCLBPChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "onetime",
            documentName: "BankProof",
          },
          () => {
            $("#CLBPModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "onetime",
              documentName: "BankProof",
            });
          }
        });
      $("#CLBPModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadCLBP = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_CLBP").attr("disabled", true);
      $("#upload_btn20_CLBP").hide();
      $("#upload_btnloader_CLBP").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  CLBPDocLink: res.data.data.BankProof,
                },
                () => $("#viewDocLink_CLBP").css("display", "block")
              );
              CLBPStatus = "remove";
              CLStatusArray[7].status = CLBPStatus;
              this.checkDropdownValue();
              this.checkhandleCLProceedBtn();
              $("#CLBPModal").modal("hide");
              $("#cancelbtn_CLBP").attr("disabled", false);
              $("#upload_btn20_CLBP").show();
              $("#upload_btnloader_CLBP").hide();
              $("#choose_CLBP").hide();
              $("#X-btn_CLBP").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_CLBP").attr("disabled", false);
            $("#upload_btn20_CLBP").show();
            $("#upload_btnloader_CLBP").hide();
            $("#CLBPModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_CLBP").attr("disabled", false);
            $("#upload_btn20_CLBP").show();
            $("#upload_btnloader_CLBP").hide();
            $("#CLBPModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_CLBP").attr("disabled", false);
          $("#upload_btn20_CLBP").show();
          $("#upload_btnloader_CLBP").hide();
          $("#CLBPModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeCLBP = () => {
    $("#X-btn_CLBP").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "onetime",
          documentName: "BankProof",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              CLBPStatus = res.data.data.status;
              CLStatusArray[7].status = CLBPStatus;
              this.checkDropdownValue();
              this.checkhandleCLProceedBtn();
              $("#choose_CLBP").show();
              $("#X-btn_CLBP").hide();
              $("#X-btn_CLBP").attr("disabled", false);
              $("#viewDocLink_CLBP").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_CLBP").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_CLBP").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handleCLBPseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#CLBPseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "BankProof",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#CLBPseecommentsbtn").hide();
          $("#CLBPhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleCLBPhidecommentsbtn = () => {
    $("#CLBPseecommentsbtn").show();
    $("#CLBPseecommentsbtn").attr("disabled", false);
    $("#CLBPhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // CLPBS_A
  handleCLPBS_AChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "onetime",
            documentName: "PreviousBS_Audit",
          },
          () => {
            $("#CLPBS_AModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "onetime",
              documentName: "PreviousBS_Audit",
            });
          }
        });
      $("#CLPBS_AModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadCLPBS_A = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_CLPBS_A").attr("disabled", true);
      $("#upload_btn20_CLPBS_A").hide();
      $("#upload_btnloader_CLPBS_A").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            if (CLOptReview == "Yes") {
              CLOptReview = "No";
            }
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  CLPBS_ADocLink: res.data.data.PreviousBS_Audit,
                },
                () => $("#viewDocLink_CLPBS_A").css("display", "block")
              );
              CLPBS_AStatus = "remove";
              CLOptStatusArray[0].status = CLPBS_AStatus;
              this.checkDropdownValue();
              this.checkhandleCLProceedBtn();
              $("#CLPBS_AModal").modal("hide");
              $("#cancelbtn_CLPBS_A").attr("disabled", false);
              $("#upload_btn20_CLPBS_A").show();
              $("#upload_btnloader_CLPBS_A").hide();
              $("#choose_CLPBS_A").hide();
              $("#X-btn_CLPBS_A").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_CLPBS_A").attr("disabled", false);
            $("#upload_btn20_CLPBS_A").show();
            $("#upload_btnloader_CLPBS_A").hide();
            $("#CLPBS_AModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_CLPBS_A").attr("disabled", false);
            $("#upload_btn20_CLPBS_A").show();
            $("#upload_btnloader_CLPBS_A").hide();
            $("#CLPBS_AModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_CLPBS_A").attr("disabled", false);
          $("#upload_btn20_CLPBS_A").show();
          $("#upload_btnloader_CLPBS_A").hide();
          $("#CLPBS_AModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeCLPBS_A = () => {
    $("#X-btn_CLPBS_A").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "onetime",
          documentName: "PreviousBS_Audit",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              CLPBS_AStatus = res.data.data.status;
              CLOptStatusArray[0].status = CLPBS_AStatus;
              this.checkDropdownValue();
              this.checkhandleCLProceedBtn();
              $("#choose_CLPBS_A").show();
              $("#X-btn_CLPBS_A").hide();
              $("#X-btn_CLPBS_A").attr("disabled", false);
              $("#viewDocLink_CLPBS_A").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_CLPBS_A").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_CLPBS_A").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handleCLPBS_Aseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#CLPBS_Aseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "PreviousBS_Audit",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#CLPBS_Aseecommentsbtn").hide();
          $("#CLPBS_Ahidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleCLPBS_Ahidecommentsbtn = () => {
    $("#CLPBS_Aseecommentsbtn").show();
    $("#CLPBS_Aseecommentsbtn").attr("disabled", false);
    $("#CLPBS_Ahidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // CLPR
  handleCLPRChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "onetime",
            documentName: "PreviousReturns",
          },
          () => {
            $("#CLPRModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "onetime",
              documentName: "PreviousReturns",
            });
          }
        });
      $("#CLPRModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadCLPR = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_CLPR").attr("disabled", true);
      $("#upload_btn20_CLPR").hide();
      $("#upload_btnloader_CLPR").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            if (CLOptReview == "Yes") {
              CLOptReview = "No";
            }
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  CLPRDocLink: res.data.data.PreviousReturns,
                },
                () => $("#viewDocLink_CLPR").css("display", "block")
              );
              CLPRStatus = "remove";
              CLOptStatusArray[1].status = CLPRStatus;
              this.checkDropdownValue();
              this.checkhandleCLProceedBtn();
              $("#CLPRModal").modal("hide");
              $("#cancelbtn_CLPR").attr("disabled", false);
              $("#upload_btn20_CLPR").show();
              $("#upload_btnloader_CLPR").hide();
              $("#choose_CLPR").hide();
              $("#X-btn_CLPR").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_CLPR").attr("disabled", false);
            $("#upload_btn20_CLPR").show();
            $("#upload_btnloader_CLPR").hide();
            $("#CLPRModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_CLPR").attr("disabled", false);
            $("#upload_btn20_CLPR").show();
            $("#upload_btnloader_CLPR").hide();
            $("#CLPRModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_CLPR").attr("disabled", false);
          $("#upload_btn20_CLPR").show();
          $("#upload_btnloader_CLPR").hide();
          $("#CLPRModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeCLPR = () => {
    $("#X-btn_CLPR").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "onetime",
          documentName: "PreviousReturns",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              CLPRStatus = res.data.data.status;
              CLOptStatusArray[1].status = CLPRStatus;
              this.checkDropdownValue();
              this.checkhandleCLProceedBtn();
              $("#choose_CLPR").show();
              $("#X-btn_CLPR").hide();
              $("#X-btn_CLPR").attr("disabled", false);
              $("#viewDocLink_CLPR").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_CLPR").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_CLPR").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handleCLPRseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#CLPRseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "PreviousReturns",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#CLPRseecommentsbtn").hide();
          $("#CLPRhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleCLPRhidecommentsbtn = () => {
    $("#CLPRseecommentsbtn").show();
    $("#CLPRseecommentsbtn").attr("disabled", false);
    $("#CLPRhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // CLincD
  handleAddCLincDFileChange = (e, index, status) => {
    CLincDadditionalReuploadIndex = index;
    CLincDadditionalReuploadStatus = status;
    $(".upload_btn20_6").show();
    $(".upload_btnloader_6").hide();
    const file = e.target.files[0];
    if (
      !CLincDAdditionalFilesNames.includes(this.state.CLincDAdd_DocName.trim())
    ) {
      const myfile = e.target.files;
      if (ChooseNowBtnsDisable == "Yes") {
        this.showNotification(
          "Files cannot be uploaded when the service is locked!"
        );
      } else if (myfile.length == 1) {
        const file = myfile[0];
        if (file.type.match("audio.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.type.match("video.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.size > 104857600) {
          this.showNotification("File too big!");
        } else if (file.name.length > 100) {
          this.showNotification("File name limit exceed!");
        } else {
          this.setState(
            {
              fileName: file.name,
              fileBase64: file,
              documentType: "incd",
              documentName: this.state.CLincDAdd_DocName,
            },
            () => {
              $(".CLincDAddModal").modal({
                backdrop: "static",
                keyboard: false,
              });
            }
          );
        }
      } else if (myfile.length > 1) {
        var zip = new JSZip();
        for (let i = 0; i < myfile.length; i++) {
          zip.file(myfile[i].name, myfile[i], {
            base64: true,
          });
        }
        zip
          .generateAsync({
            type: "blob",
          })
          .then((content) => {
            if (content.size > 104857600) {
              this.showNotification("File too big!");
            } else {
              this.setState({
                fileName: "download.zip",
                fileBase64: content,
                documentType: "incd",
                documentName: this.state.CLincDAdd_DocName,
              });
            }
          });
        $(".CLincDAddModal").modal({
          backdrop: "static",
          keyboard: false,
        });
      } else {
        this.showNotification("File cannot be null!");
      }
    } else {
      this.showNotification(
        "Document names can not be the same, Try again with different name!"
      );
    }
  };

  uploadCLincDFile = (FileIndex, FileStatus) => {
    let AdditionaldocumentName;
    if (FileStatus == "reupload") {
      $(".proceednowbtn").attr("disabled", true);
      let fileTobeUploaded = this.state.CLincDAdditionalFiles[FileIndex];
      AdditionaldocumentName = Object.keys(fileTobeUploaded)[0];
      mainaction = "reupload";
    } else {
      AdditionaldocumentName = this.state.documentName;
      mainaction = "upload";
    }
    if (AdditionaldocumentName.trim() == "") {
      $(".CLincDAddModal").modal("hide");
      this.showNotification("Enter document Name first");
    } else if (this.state.comment.length <= 250) {
      $("#cancelbtn_6").attr("disabled", true);
      $(".upload_btn20_6").hide();
      $(".upload_btnloader_6").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", AdditionaldocumentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            $(".CLincDAddModal").modal("hide");
            $(`#${FileIndex}CLincDFileseecommentsbtn`).attr("disabled", false);
            if (CLincDadditionalReupload == false) {
              CLincDAdditionalFilesNames.push(this.state.CLincDAdd_DocName);
              this.state.CLincDAdditionalFiles.pop();
              this.setState(
                {
                  comment: "",
                  CLincDAdditionalFiles: [
                    ...this.state.CLincDAdditionalFiles,
                    res.data.data,
                  ],
                },
                () => {
                  this.setState(
                    {
                      api_CLincDAdditionalFiles: "Yes",
                      ProceedDisabled: false,
                    },
                    () => {
                      this.CLincDAdditionalFilesArea();
                      this.checkDropdownValue();
                      this.checkhandleCLProceedBtn();
                    }
                  );

                  $(".CLincDadditionalInput").attr("disabled", true);
                }
              );
            } else if (CLincDadditionalReupload == true) {
              // $(".proceednowbtn").attr("disabled", false);
              var array = [...this.state.CLincDAdditionalFiles];
              array.splice(FileIndex, 1, res.data.data);
              this.setState(
                {
                  comment: "",
                  api_CLincDAdditionalFiles: "Yes",
                  CLincDAdditionalFiles: array,
                  ProceedDisabled: false,
                },
                () => {
                  this.CLincDAdditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleCLProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_6").attr("disabled", false);
            $(".upload_btn20_6").show();
            $(".upload_btnloader_6").hide();
            $(".CLincDAddModal").modal("hide");
            this.showNotification("Something Went Wrong!");
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_6").attr("disabled", false);
            $(".upload_btn20_6").show();
            $(".upload_btnloader_6").hide();
            $(".CLincDAddModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          this.showNotification("Something went wrong, Try again!");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeCLincDFile = (index) => {
    $("#X-btn_6").attr("disabled", true);
    let fileTobeDeleted = this.state.CLincDAdditionalFiles[index];
    let NameOfAddFile = Object.keys(fileTobeDeleted)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "incd",
          documentName: NameOfAddFile,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          var array = [...this.state.CLincDAdditionalFiles];
          if (res.data.data.status == "reupload") {
            array[index].status = "reupload";
            this.setState(
              {
                CLincDAdditionalFiles: array,
              },
              () => {
                this.CLincDAdditionalFilesArea();
                this.checkDropdownValue();
                this.checkhandleCLProceedBtn();
              }
            );
            this.showNotification(res.data.message);
          } else {
            array.splice(index, 1);
            if (array.length > 0) {
              this.setState(
                {
                  CLincDAdditionalFiles: array,
                },
                () => {
                  this.CLincDAdditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleCLProceedBtn();
                }
              );
            } else if (array.length == 0) {
              this.setState(
                {
                  CLincDAdditionalFiles: [],
                  CLincDAdd_DocName: "",
                  api_CLincDAdditionalFiles: "No",
                  IsCLincDAddFileDisabled: true,
                },
                () => {
                  this.CLincDAdditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleCLProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $(".X-btn_6").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleCLincDFileseeComments = (index) => {
    // $(".seecommentsbtn").attr("disabled", false);
    $(".commentsbtn").attr("disabled", false);
    $(`#${index}CLincDFileseecommentsbtn`).attr("disabled", true);
    let SelectedFile = this.state.CLincDAdditionalFiles[index];
    let NameOfAddFile = Object.keys(SelectedFile)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "incd",
          documentName: NameOfAddFile,
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(`#${index}CLincDFileseecommentsbtn`).hide();
          $(".hidecommentsbtn").hide();
          $(`#${index}CLincDFilehidecommentsbtn`).show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleCLincDFilehideComments = (index) => {
    $(".seecommentsbtn").show();
    $(`#${index}CLincDFileseecommentsbtn`).show();
    $(`#${index}CLincDFileseecommentsbtn`).attr("disabled", false);
    $(`#${index}CLincDFilehidecommentsbtn`).hide();
    $(".hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  checkAddCommentCLincDFiles = (FileIndex, isDisabled) => {
    if (isDisabled == true) {
      return (
        <>
          <button
            className="seecommentsbtn disabledBtn_cmt CLincDFileseecommentsbtn"
            id={`${FileIndex}CLincDFileseecommentsbtn`}
            disabled
            onClick={() => this.handleCLincDFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn CLincDFilehidecommentsbtn"
            id={`${FileIndex}CLincDFilehidecommentsbtn`}
            onClick={() => this.handleCLincDFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    } else if (isDisabled == false) {
      return (
        <>
          <button
            className="seecommentsbtn commentsbtn CLincDFileseecommentsbtn"
            id={`${FileIndex}CLincDFileseecommentsbtn`}
            onClick={() => this.handleCLincDFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn CLincDFilehidecommentsbtn"
            id={`${FileIndex}CLincDFilehidecommentsbtn`}
            onClick={() => this.handleCLincDFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    }
  };

  checkStatusCLincDAddFiles = (status, link, index) => {
    CLincDAdditionalFilesIndex = index;
    if (ChooseNowBtnsDisable == "Yes") {
      $(".proceednowbtn").hide();
      if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_6">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_6"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_6">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_6"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      }
    } else {
      if (status == undefined) {
        status = "open";
      }
      if (status == "open") {
        mainaction = "upload";
        CLincDadditionalReupload = true;
        $(".proceednowbtn4").show();
        return (
          <>
            <label
              htmlFor={`ul_file_CLincD${index}`}
              className="choose_btn choose_6"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              name="file[]"
              className="upload-photo"
              id={`ul_file_CLincD${index}`}
              // disabled={this.state.IsCLincDAddFileDisabled}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handleAddCLincDFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "remove") {
        mainaction = "upload";
        $(".proceednowbtn4").show();
        return (
          <>
            <button
              className="X-btn X-btn_6"
              onClick={() => this.removeCLincDFile(index)}
            >
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_1025.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_6"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_6">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_6"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "reupload") {
        CLincDadditionalReupload = true;
        mainaction = "reupload";
        $(".proceednowbtn4").show();
        $(".proceednowbtn").attr("disabled", true);
        return (
          <>
            <label
              htmlFor={`ul_file_CLincD${index}`}
              className="choose_btn choose_6"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              name="file[]"
              className="upload-photo"
              id={`ul_file_CLincD${index}`}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handleAddCLincDFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_6">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_6"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      }
    }
  };

  AdditionalCLincDFileDisabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero CLincDadditionalInput"
        value={NameOfAddFile}
        disabled
        onChange={(e) => this.handleCLincDAdd_DocNameChange(e)}
      />
    );
  };

  AdditionalCLincDFileEnabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero CLincDadditionalInput"
        value={NameOfAddFile}
        onChange={(e) => this.handleCLincDAdd_DocNameChange(e)}
      />
    );
  };

  CLincDAdditionalFilesArea = () => {
    CLincDAdditionalFilesNames = [];
    if (this.state.api_CLincDAdditionalFiles == "Yes") {
      return this.state.CLincDAdditionalFiles.map((file, index) => {
        let NameOfAddFile = Object.keys(file)[0];
        CLincDAdditionalFilesNames.push(NameOfAddFile);
        let link = file[Object.keys(file)[0]];
        let AdditionalFileInputField;
        let commentDisabled;
        if (file.status == "open" || file.status == undefined) {
          AdditionalFileInputField =
            this.AdditionalCLincDFileEnabledField(NameOfAddFile);
          commentDisabled = true;
        } else if (file.status != "open") {
          AdditionalFileInputField =
            this.AdditionalCLincDFileDisabledField(NameOfAddFile);
          commentDisabled = false;
        }
        if (file.status == "reupload") {
          anyCLincDAdditionalReuploadRemaining = "Yes";
        }
        return (
          <div className="displayflex" key={index}>
            <div className="uploadnocol">
              <p className="uploadadditionalsidetext">{index + 1}&#41;</p>
            </div>
            <div className="uploadadditionaldatacol">
              <div className="row mb-2">
                <div className="col-md-5">{AdditionalFileInputField}</div>
                <div className="col-md-7">
                  <div className="float-md-right float-left">
                    {this.checkAddCommentCLincDFiles(index, commentDisabled)}
                    {this.checkStatusCLincDAddFiles(file.status, link, index)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.api_CLincDAdditionalFiles == "No") {
      return (
        <div className="displayflex">
          <div className="uploadnocol">
            <p className="uploadadditionalsidetext">1&#41;</p>
          </div>
          <div className="uploadadditionaldatacol">
            <div className="row mb-2">
              <div className="col-md-5">
                <input
                  type="text"
                  placeholder="Document name..."
                  maxLength="150"
                  className="uploadtextfield marginzero"
                  value={this.state.CLincDAdd_DocName}
                  onChange={(e) => this.handleCLincDAdd_DocNameChange(e)}
                />
              </div>
              <div className="col-md-7">
                <div className="fl_right">
                  {this.checkAddCommentCLincDFiles(0, true)}
                  {this.checkStatusCLincDAddFiles("open", null, 0)}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  handleCLincDAdd_DocNameChange = (e) => {
    this.setState(
      {
        CLincDAdd_DocName: e.target.value,
      },
      () => {
        if (this.state.CLincDAdd_DocName.trim().length > 0) {
          this.setState({
            IsCLincDAddFileDisabled: false,
          });
        } else {
          this.setState({
            IsCLincDAddFileDisabled: true,
          });
        }
      }
    );
  };

  handleCLincDAddfileBtn = (FileIndex) => {
    const { CLincDAdd_DocName, CLincDAdditionalFiles } = this.state;

    let prevFile = CLincDAdditionalFiles[FileIndex];
    let NameOfPrevFile = Object.keys(prevFile)[0];
    let valueOffile = prevFile[Object.keys(prevFile)[0]];

    if (FileIndex == undefined) {
      this.showNotification("empty fields");
    } else if (CLincDAdd_DocName.trim() == "") {
      this.showNotification("Required fields cannot be empty!");
    } else if (valueOffile == null) {
      this.showNotification("Choose a file");
    } else {
      this.setState(
        {
          CLincDAdditionalFiles: [...this.state.CLincDAdditionalFiles, ""],
          CLincDAdd_DocName: "",
          IsCLincDAddFileDisabled: true,
        },
        () => {
          $(".CLincDadditionalInput").attr("disabled", false);
        }
      );
    }
  };

  // CLData
  handleCLDataChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "Data",
          },
          () => {
            $("#CLDataModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "Data",
            });
          }
        });
      $("#CLDataModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadCLData = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_CLData").attr("disabled", true);
      $("#upload_btn20_CLData").hide();
      $("#upload_btnloader_CLData").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  CLDataDocLink: res.data.data.Data,
                },
                () => $("#viewDocLink_CLData").css("display", "block")
              );
              CLDataStatus = "remove";
              CLStatusArray[8].status = CLDataStatus;
              this.checkDropdownValue();
              this.checkhandleCLProceedBtn();
              $("#CLDataModal").modal("hide");
              $("#cancelbtn_CLData").attr("disabled", false);
              $("#upload_btn20_CLData").show();
              $("#upload_btnloader_CLData").hide();
              $("#choose_CLData").hide();
              $("#X-btn_CLData").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_CLData").attr("disabled", false);
            $("#upload_btn20_CLData").show();
            $("#upload_btnloader_CLData").hide();
            $("#CLDataModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_CLData").attr("disabled", false);
            $("#upload_btn20_CLData").show();
            $("#upload_btnloader_CLData").hide();
            $("#CLDataModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_CLData").attr("disabled", false);
          $("#upload_btn20_CLData").show();
          $("#upload_btnloader_CLData").hide();
          $("#CLDataModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeCLData = () => {
    $("#X-btn_CLData").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "general",
          documentName: "Data",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              CLDataStatus = res.data.data.status;
              CLStatusArray[8].status = CLDataStatus;
              this.checkDropdownValue();
              this.checkhandleCLProceedBtn();
              $("#choose_CLData").show();
              $("#X-btn_CLData").hide();
              $("#X-btn_CLData").attr("disabled", false);
              $("#viewDocLink_CLData").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_CLData").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_CLData").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handleCLDataseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#CLDataseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "Data",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#CLDataseecommentsbtn").hide();
          $("#CLDatahidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleCLDatahidecommentsbtn = () => {
    $("#CLDataseecommentsbtn").show();
    $("#CLDataseecommentsbtn").attr("disabled", false);
    $("#CLDatahidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // CLBankS
  handleCLBankSChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "BankStatement",
          },
          () => {
            $("#CLBankSModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "BankStatement",
            });
          }
        });
      $("#CLBankSModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadCLBankS = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_CLBankS").attr("disabled", true);
      $("#upload_btn20_CLBankS").hide();
      $("#upload_btnloader_CLBankS").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  CLBankSDocLink: res.data.data.BankStatement,
                },
                () => $("#viewDocLink_CLBankS").css("display", "block")
              );
              CLBankSStatus = "remove";
              CLStatusArray[9].status = CLBankSStatus;
              this.checkDropdownValue();
              this.checkhandleCLProceedBtn();
              $("#CLBankSModal").modal("hide");
              $("#cancelbtn_CLBankS").attr("disabled", false);
              $("#upload_btn20_CLBankS").show();
              $("#upload_btnloader_CLBankS").hide();
              $("#choose_CLBankS").hide();
              $("#X-btn_CLBankS").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_CLBankS").attr("disabled", false);
            $("#upload_btn20_CLBankS").show();
            $("#upload_btnloader_CLBankS").hide();
            $("#CLBankSModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_CLBankS").attr("disabled", false);
            $("#upload_btn20_CLBankS").show();
            $("#upload_btnloader_CLBankS").hide();
            $("#CLBankSModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_CLBankS").attr("disabled", false);
          $("#upload_btn20_CLBankS").show();
          $("#upload_btnloader_CLBankS").hide();
          $("#CLBankSModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeCLBankS = () => {
    $("#X-btn_CLBankS").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "general",
          documentName: "BankStatement",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              CLBankSStatus = res.data.data.status;
              CLStatusArray[9].status = CLBankSStatus;
              this.checkDropdownValue();
              this.checkhandleCLProceedBtn();
              $("#choose_CLBankS").show();
              $("#X-btn_CLBankS").hide();
              $("#X-btn_CLBankS").attr("disabled", false);
              $("#viewDocLink_CLBankS").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_CLBankS").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_CLBankS").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handleCLBankSseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#CLBankSseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "BankStatement",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#CLBankSseecommentsbtn").hide();
          $("#CLBankShidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleCLBankShidecommentsbtn = () => {
    $("#CLBankSseecommentsbtn").show();
    $("#CLBankSseecommentsbtn").attr("disabled", false);
    $("#CLBankShidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // CLGSTReturn
  handleCLGSTReturnChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "GSTReturn",
          },
          () => {
            $("#CLGSTReturnModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "GSTReturn",
            });
          }
        });
      $("#CLGSTReturnModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadCLGSTReturn = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_CLGSTReturn").attr("disabled", true);
      $("#upload_btn20_CLGSTReturn").hide();
      $("#upload_btnloader_CLGSTReturn").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            if (CLOptReview == "Yes") {
              CLOptReview = "No";
            }
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  CLGSTReturnDocLink: res.data.data.GSTReturn,
                },
                () => $("#viewDocLink_CLGSTReturn").css("display", "block")
              );
              CLGSTReturnStatus = "remove";
              CLOptStatusArray[2].status = CLGSTReturnStatus;
              this.checkDropdownValue();
              this.checkhandleCLProceedBtn();
              $("#CLGSTReturnModal").modal("hide");
              $("#cancelbtn_CLGSTReturn").attr("disabled", false);
              $("#upload_btn20_CLGSTReturn").show();
              $("#upload_btnloader_CLGSTReturn").hide();
              $("#choose_CLGSTReturn").hide();
              $("#X-btn_CLGSTReturn").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_CLGSTReturn").attr("disabled", false);
            $("#upload_btn20_CLGSTReturn").show();
            $("#upload_btnloader_CLGSTReturn").hide();
            $("#CLGSTReturnModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_CLGSTReturn").attr("disabled", false);
            $("#upload_btn20_CLGSTReturn").show();
            $("#upload_btnloader_CLGSTReturn").hide();
            $("#CLGSTReturnModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_CLGSTReturn").attr("disabled", false);
          $("#upload_btn20_CLGSTReturn").show();
          $("#upload_btnloader_CLGSTReturn").hide();
          $("#CLGSTReturnModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeCLGSTReturn = () => {
    $("#X-btn_CLGSTReturn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "general",
          documentName: "GSTReturn",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              CLGSTReturnStatus = res.data.data.status;
              CLOptStatusArray[2].status = CLGSTReturnStatus;
              this.checkDropdownValue();
              this.checkhandleCLProceedBtn();
              $("#choose_CLGSTReturn").show();
              $("#X-btn_CLGSTReturn").hide();
              $("#X-btn_CLGSTReturn").attr("disabled", false);
              $("#viewDocLink_CLGSTReturn").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_CLGSTReturn").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_CLGSTReturn").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handleCLGSTReturnseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#CLGSTReturnseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "GSTReturn",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#CLGSTReturnseecommentsbtn").hide();
          $("#CLGSTReturnhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleCLGSTReturnhidecommentsbtn = () => {
    $("#CLGSTReturnseecommentsbtn").show();
    $("#CLGSTReturnseecommentsbtn").attr("disabled", false);
    $("#CLGSTReturnhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // CLTSDReturn
  handleCLTDSReturnChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "TDSReturn",
          },
          () => {
            $("#CLTDSReturnModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "TDSReturn",
            });
          }
        });
      $("#CLTDSReturnModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadCLTDSReturn = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_CLTDSReturn").attr("disabled", true);
      $("#upload_btn20_CLTDSReturn").hide();
      $("#upload_btnloader_CLTDSReturn").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            if (CLOptReview == "Yes") {
              CLOptReview = "No";
            }
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  CLTDSReturnDocLink: res.data.data.TDSReturn,
                },
                () => $("#viewDocLink_CLTDSReturn").css("display", "block")
              );
              CLTDSReturnStatus = "remove";
              CLOptStatusArray[3].status = CLTDSReturnStatus;
              this.checkDropdownValue();
              this.checkhandleCLProceedBtn();
              $("#CLTDSReturnModal").modal("hide");
              $("#cancelbtn_CLTDSReturn").attr("disabled", false);
              $("#upload_btn20_CLTDSReturn").show();
              $("#upload_btnloader_CLTDSReturn").hide();
              $("#choose_CLTDSReturn").hide();
              $("#X-btn_CLTDSReturn").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_CLTDSReturn").attr("disabled", false);
            $("#upload_btn20_CLTDSReturn").show();
            $("#upload_btnloader_CLTDSReturn").hide();
            $("#CLTDSReturnModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_CLTDSReturn").attr("disabled", false);
            $("#upload_btn20_CLTDSReturn").show();
            $("#upload_btnloader_CLTDSReturn").hide();
            $("#CLTDSReturnModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_CLTDSReturn").attr("disabled", false);
          $("#upload_btn20_CLTDSReturn").show();
          $("#upload_btnloader_CLTDSReturn").hide();
          $("#CLTDSReturnModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeCLTDSReturn = () => {
    $("#X-btn_CLTDSReturn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "general",
          documentName: "TDSReturn",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              CLTDSReturnStatus = res.data.data.status;
              CLOptStatusArray[3].status = CLTDSReturnStatus;
              this.checkDropdownValue();
              this.checkhandleCLProceedBtn();
              $("#choose_CLTDSReturn").show();
              $("#X-btn_CLTDSReturn").hide();
              $("#X-btn_CLTDSReturn").attr("disabled", false);
              $("#viewDocLink_CLTDSReturn").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_CLTDSReturn").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_CLTDSReturn").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handleCLTDSReturnseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#CLTDSReturnseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "TDSReturn",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#CLTDSReturnseecommentsbtn").hide();
          $("#CLTDSReturnhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleCLTDSReturnhidecommentsbtn = () => {
    $("#CLTDSReturnseecommentsbtn").show();
    $("#CLTDSReturnseecommentsbtn").attr("disabled", false);
    $("#CLTDSReturnhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // CLChallan
  handleCLChallanChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "Challan",
          },
          () => {
            $("#CLChallanModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "Challan",
            });
          }
        });
      $("#CLChallanModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadCLChallan = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_CLChallan").attr("disabled", true);
      $("#upload_btn20_CLChallan").hide();
      $("#upload_btnloader_CLChallan").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            if (CLOptReview == "Yes") {
              CLOptReview = "No";
            }
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  CLChallanDocLink: res.data.data.Challan,
                },
                () => $("#viewDocLink_CLChallan").css("display", "block")
              );
              CLChallanStatus = "remove";
              CLOptStatusArray[4].status = CLChallanStatus;
              this.checkDropdownValue();
              this.checkhandleCLProceedBtn();
              $("#CLChallanModal").modal("hide");
              $("#cancelbtn_CLChallan").attr("disabled", false);
              $("#upload_btn20_CLChallan").show();
              $("#upload_btnloader_CLChallan").hide();
              $("#choose_CLChallan").hide();
              $("#X-btn_CLChallan").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_CLChallan").attr("disabled", false);
            $("#upload_btn20_CLChallan").show();
            $("#upload_btnloader_CLChallan").hide();
            $("#CLChallanModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_CLChallan").attr("disabled", false);
            $("#upload_btn20_CLChallan").show();
            $("#upload_btnloader_CLChallan").hide();
            $("#CLChallanModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_CLChallan").attr("disabled", false);
          $("#upload_btn20_CLChallan").show();
          $("#upload_btnloader_CLChallan").hide();
          $("#CLChallanModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeCLChallan = () => {
    $("#X-btn_CLChallan").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "general",
          documentName: "Challan",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState(
            {
              ProceedDisabled: true,
            },
            () => {
              CLChallanStatus = res.data.data.status;
              CLOptStatusArray[4].status = CLChallanStatus;
              this.checkDropdownValue();
              this.checkhandleCLProceedBtn();
              $("#choose_CLChallan").show();
              $("#X-btn_CLChallan").hide();
              $("#X-btn_CLChallan").attr("disabled", false);
              $("#viewDocLink_CLChallan").hide();
            }
          );
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          $("#X-btn_CLChallan").attr("disabled", false);

          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_CLChallan").attr("disabled", false);

        this.showNotification("Something went wrong");
      });
  };

  handleCLChallanseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#CLChallanseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "Challan",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#CLChallanseecommentsbtn").hide();
          $("#CLChallanhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleCLChallanhidecommentsbtn = () => {
    $("#CLChallanseecommentsbtn").show();
    $("#CLChallanseecommentsbtn").attr("disabled", false);
    $("#CLChallanhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // CLAdditional
  handleCLAddFileChange = (e, index, status) => {
    CLadditionalReuploadIndex = index;
    CLadditionalReuploadStatus = status;
    $(".upload_btn20_7").show();
    $(".upload_btnloader_7").hide();
    const file = e.target.files[0];
    if (!CLAdditionalFilesNames.includes(this.state.CLAdd_DocName.trim())) {
      const myfile = e.target.files;
      if (ChooseNowBtnsDisable == "Yes") {
        this.showNotification(
          "Files cannot be uploaded when the service is locked!"
        );
      } else if (myfile.length == 1) {
        const file = myfile[0];
        if (file.type.match("audio.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.type.match("video.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.size > 104857600) {
          this.showNotification("File too big!");
        } else if (file.name.length > 100) {
          this.showNotification("File name limit exceed!");
        } else {
          this.setState(
            {
              fileName: file.name,
              fileBase64: file,
              documentType: "additional",
              documentName: this.state.CLAdd_DocName,
            },
            () => {
              $(".CLAddModal").modal({
                backdrop: "static",
                keyboard: false,
              });
            }
          );
        }
      } else if (myfile.length > 1) {
        var zip = new JSZip();
        for (let i = 0; i < myfile.length; i++) {
          zip.file(myfile[i].name, myfile[i], {
            base64: true,
          });
        }
        zip
          .generateAsync({
            type: "blob",
          })
          .then((content) => {
            if (content.size > 104857600) {
              this.showNotification("File too big!");
            } else {
              this.setState({
                fileName: "download.zip",
                fileBase64: content,
                documentType: "additional",
                documentName: this.state.CLAdd_DocName,
              });
            }
          });
        $(".CLAddModal").modal({
          backdrop: "static",
          keyboard: false,
        });
      } else {
        this.showNotification("File cannot be null!");
      }
    } else {
      this.showNotification(
        "Document names can not be the same, Try again with different name!"
      );
    }
  };

  uploadCLFile = (FileIndex, FileStatus) => {
    let AdditionaldocumentName1;
    if (FileStatus == "reupload") {
      $(".proceednowbtn").attr("disabled", true);
      let fileTobeUploaded = this.state.CLAdditionalFiles[FileIndex];
      AdditionaldocumentName1 = Object.keys(fileTobeUploaded)[0];
      mainaction = "reupload";
    } else {
      AdditionaldocumentName1 = this.state.documentName;
      mainaction = "upload";
    }
    if (AdditionaldocumentName1.trim() == "") {
      $(".CLAddModal").modal("hide");
      this.showNotification("Enter document Name first");
    } else if (this.state.comment.length <= 250) {
      $(".cancelbtn_7").attr("disabled", true);
      $(".upload_btn20_7").hide();
      $(".upload_btnloader_7").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", this.state.SelectedYear);
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", AdditionaldocumentName1);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            $(".cancelbtn_7").attr("disabled", false);
            $(".CLAddModal").modal("hide");
            $(`#${FileIndex}CLFileseecommentsbtn`).attr("disabled", false);
            if (CLadditionalReupload == false) {
              CLAdditionalFilesNames.push(this.state.CLAdd_DocName);
              this.state.CLAdditionalFiles.pop();
              this.setState(
                {
                  comment: "",
                  CLAdditionalFiles: [
                    ...this.state.CLAdditionalFiles,
                    res.data.data,
                  ],
                },
                () => {
                  this.setState(
                    {
                      api_CLAdditionalFiles: "Yes",
                      ProceedDisabled: false,
                    },
                    () => {
                      this.CLAdditionalFilesArea();
                      this.checkDropdownValue();
                      this.checkhandleCLProceedBtn();
                    }
                  );

                  $(".CLadditionalInput").attr("disabled", true);
                }
              );
            } else if (CLadditionalReupload == true) {
              var array = [...this.state.CLAdditionalFiles];
              array.splice(FileIndex, 1, res.data.data);
              this.setState(
                {
                  comment: "",
                  api_CLAdditionalFiles: "Yes",
                  CLAdditionalFiles: array,
                  ProceedDisabled: false,
                },
                () => {
                  this.CLAdditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleCLProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".CLAddModal").modal("hide");
            this.showNotification("Something Went Wrong!");
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                timeout: true,
              },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".CLAddModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          this.showNotification("Something went wrong, Try again!");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeCLFile = (index) => {
    $("#X-btn_7").attr("disabled", true);
    let fileTobeDeleted = this.state.CLAdditionalFiles[index];
    let NameOfAddFile = Object.keys(fileTobeDeleted)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          documentType: "additional",
          documentName: NameOfAddFile,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          var array = [...this.state.CLAdditionalFiles];
          if (res.data.data.status == "reupload") {
            array[index].status = "reupload";
            this.setState(
              {
                CLAdditionalFiles: array,
              },
              () => {
                this.CLAdditionalFilesArea();
                this.checkDropdownValue();
                this.checkhandleCLProceedBtn();
              }
            );
            this.showNotification(res.data.message);
          } else {
            array.splice(index, 1);
            if (array.length > 0) {
              this.setState(
                {
                  CLAdditionalFiles: array,
                },
                () => {
                  this.CLAdditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleCLProceedBtn();
                }
              );
            } else if (array.length == 0) {
              this.setState(
                {
                  CLAdditionalFiles: [],
                  CLAdd_DocName: "",
                  api_CLAdditionalFiles: "No",
                  IsCLAddFileDisabled: true,
                },
                () => {
                  this.CLAdditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleCLProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_7").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleCLFileseeComments = (index) => {
    // $(".seecommentsbtn").attr("disabled", false);
    $(".commentsbtn").attr("disabled", false);
    $(`#${index}CLFileseecommentsbtn`).attr("disabled", true);
    let SelectedFile = this.state.CLAdditionalFiles[index];
    let NameOfAddFile = Object.keys(SelectedFile)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "additional",
          documentName: NameOfAddFile,
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(`#${index}CLFileseecommentsbtn`).hide();
          $(".hidecommentsbtn").hide();
          $(`#${index}CLFilehidecommentsbtn`).show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              timeout: true,
            },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleCLFilehideComments = (index) => {
    $(".seecommentsbtn").show();
    $(`#${index}CLFileseecommentsbtn`).show();
    $(`#${index}CLFileseecommentsbtn`).attr("disabled", false);
    $(`#${index}CLFilehidecommentsbtn`).hide();
    $(".hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  checkAddCommentCLFiles = (FileIndex, isDisabled) => {
    if (isDisabled == true) {
      return (
        <>
          <button
            className="seecommentsbtn disabledBtn_cmt CLFileseecommentsbtn"
            id={`${FileIndex}CLFileseecommentsbtn`}
            disabled
            onClick={() => this.handleCLFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn CLFilehidecommentsbtn"
            id={`${FileIndex}CLFilehidecommentsbtn`}
            onClick={() => this.handleCLFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    } else if (isDisabled == false) {
      return (
        <>
          <button
            className="seecommentsbtn commentsbtn CLFileseecommentsbtn"
            id={`${FileIndex}CLFileseecommentsbtn`}
            onClick={() => this.handleCLFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn CLFilehidecommentsbtn"
            id={`${FileIndex}CLFilehidecommentsbtn`}
            onClick={() => this.handleCLFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    }
  };

  checkStatusCLAddFiles = (status, link, index) => {
    CLAdditionalFilesIndex = index;
    if (ChooseNowBtnsDisable == "Yes") {
      $(".proceednowbtn").hide();
      if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      }
    } else {
      if (status == undefined) {
        status = "open";
      }
      if (status == "open") {
        mainaction = "upload";
        CLadditionalReupload = true;
        $(".proceednowbtn4").show();
        return (
          <>
            <label
              htmlFor={`ul_file_CLAdd${index}`}
              className="choose_btn choose_7"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              multiple
              name="file[]"
              className="upload-photo"
              id={`ul_file_CLAdd${index}`}
              // disabled={this.state.IsCLAddFileDisabled}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handleCLAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "remove") {
        mainaction = "upload";
        $(".proceednowbtn4").show();
        return (
          <>
            <button
              className="X-btn X-btn_7"
              onClick={() => this.removeCLFile(index)}
            >
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_1025.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "reupload") {
        CLadditionalReupload = true;
        mainaction = "reupload";
        $(".proceednowbtn4").show();
        $(".proceednowbtn").attr("disabled", true);
        return (
          <>
            <label
              htmlFor={`ul_file_CLAdd${index}`}
              className="choose_btn choose_7"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              multiple
              name="file[]"
              className="upload-photo"
              id={`ul_file_CLAdd${index}`}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handleCLAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      }
    }
  };

  AdditionalCLFileDisabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero CLadditionalInput"
        value={NameOfAddFile}
        disabled
        onChange={(e) => this.handleCLAdd_DocNameChange(e)}
      />
    );
  };

  AdditionalCLFileEnabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero CLadditionalInput"
        value={NameOfAddFile}
        onChange={(e) => this.handleCLAdd_DocNameChange(e)}
      />
    );
  };

  CLAdditionalFilesArea = () => {
    CLAdditionalFilesNames = [];
    if (this.state.api_CLAdditionalFiles == "Yes") {
      return this.state.CLAdditionalFiles.map((file, index) => {
        let NameOfAddFile = Object.keys(file)[0];
        CLAdditionalFilesNames.push(NameOfAddFile);
        let link = file[Object.keys(file)[0]];
        let AdditionalFileInputField;
        let commentDisabled;
        if (file.status == "open" || file.status == undefined) {
          AdditionalFileInputField =
            this.AdditionalCLFileEnabledField(NameOfAddFile);
          commentDisabled = true;
        } else if (file.status != "open") {
          AdditionalFileInputField =
            this.AdditionalCLFileDisabledField(NameOfAddFile);
          commentDisabled = false;
        }
        if (file.status == "reupload") {
          anyCLAdditionalReuploadRemaining = "Yes";
        }
        return (
          <div className="displayflex" key={index}>
            <div className="uploadnocol">
              <p className="uploadadditionalsidetext">{index + 1}&#41;</p>
            </div>
            <div className="uploadadditionaldatacol">
              <div className="row mb-2">
                <div className="col-md-5">{AdditionalFileInputField}</div>
                <div className="col-md-7">
                  <div className="float-md-right float-left">
                    {this.checkAddCommentCLFiles(index, commentDisabled)}
                    {this.checkStatusCLAddFiles(file.status, link, index)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.api_CLAdditionalFiles == "No") {
      return (
        <div className="displayflex">
          <div className="uploadnocol">
            <p className="uploadadditionalsidetext">1&#41;</p>
          </div>
          <div className="uploadadditionaldatacol">
            <div className="row mb-2">
              <div className="col-md-5">
                <input
                  type="text"
                  placeholder="Document name..."
                  maxLength="150"
                  className="uploadtextfield marginzero"
                  value={this.state.CLAdd_DocName}
                  onChange={(e) => this.handleCLAdd_DocNameChange(e)}
                />
              </div>
              <div className="col-md-7">
                <div className="fl_right">
                  {this.checkAddCommentCLFiles(0, true)}
                  {this.checkStatusCLAddFiles("open", null, 0)}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  handleCLAdd_DocNameChange = (e) => {
    this.setState(
      {
        CLAdd_DocName: e.target.value,
      },
      () => {
        if (this.state.CLAdd_DocName.trim().length > 0) {
          this.setState({
            IsCLAddFileDisabled: false,
          });
        } else {
          this.setState({
            IsCLAddFileDisabled: true,
          });
        }
      }
    );
  };

  handleAddCLfileBtn = (FileIndex) => {
    const { CLAdd_DocName, CLAdditionalFiles } = this.state;

    let prevFile = CLAdditionalFiles[FileIndex];
    let NameOfPrevFile = Object.keys(prevFile)[0];
    let valueOffile = prevFile[Object.keys(prevFile)[0]];

    if (FileIndex == undefined) {
      this.showNotification("empty fields");
    } else if (CLAdd_DocName.trim() == "") {
      this.showNotification("Required fields cannot be empty!");
    } else if (valueOffile == null) {
      this.showNotification("Choose a file");
    } else {
      this.setState(
        {
          CLAdditionalFiles: [...this.state.CLAdditionalFiles, ""],
          CLAdd_DocName: "",
          IsCLAddFileDisabled: true,
        },
        () => {
          $(".CLadditionalInput").attr("disabled", false);
        }
      );
    }
  };

  CLcheckRemainingReuploads = () => {
    if (
      CLAadhar1Status != "reupload" &&
      CLAadhar2Status != "reupload" &&
      CLPan1Status != "reupload" &&
      CLPan2Status != "reupload" &&
      CLICStatus != "reupload" &&
      CLMOAStatus != "reupload" &&
      CLAOAStatus != "reupload" &&
      CLBPStatus != "reupload" &&
      CLPBS_AStatus != "reupload" &&
      CLPRStatus != "reupload" &&
      CLDataStatus != "reupload" &&
      CLGSTReturnStatus != "reupload" &&
      CLTDSReturnStatus != "reupload" &&
      CLChallanStatus != "reupload" &&
      anyCLincDAdditionalReuploadRemaining != "Yes" &&
      anyCLAdditionalReuploadRemaining != "Yes"
    ) {
      AnyReuploadOccuredCL = "No";
    } else {
      $(".proceednowbtn4").show();
      AnyReuploadOccuredCL = "Yes";
    }
  };

  checkhandleCLProceedBtn = () => {
    let statusCheck = this.statusCheckFunction("reupload", CLStatusArray);
    CLOptReupload = this.countOptReupload1("reupload", CLOptStatusArray);
    CLincDadditionalStatusArrayCheck = this.statusCheckFunction(
      "reupload",
      this.state.CLincDAdditionalFiles
    );
    CLadditionalStatusArrayCheck = this.statusCheckFunction(
      "reupload",
      this.state.CLAdditionalFiles
    );

    if (rejectstatus == "Yes") {
      $(".proceednowbtn4").show();
      $(".proceednowbtn4").attr("disabled", false);
      CLproceednowforword = "success";
    } else if (
      AnyReuploadOccuredCL == "Yes" &&
      ((CLOptReview == "No" && CLOptReupload != OptReuploadCountCL) ||
        CLOptReview == "Yes") &&
      statusCheck == undefined &&
      CLincDadditionalStatusArrayCheck == undefined &&
      CLadditionalStatusArrayCheck == undefined
    ) {
      $(".proceednowbtn4").attr("disabled", false);
      CLproceednowforword = "success";
    } else if (
      CLAadhar1Status == "remove" &&
      CLAadhar2Status == "remove" &&
      CLPan1Status == "remove" &&
      CLPan2Status == "remove" &&
      CLICStatus == "remove" &&
      CLMOAStatus == "remove" &&
      CLAOAStatus == "remove" &&
      CLBPStatus == "remove" &&
      CLDataStatus == "remove" &&
      CLBankSStatus == "remove" &&
      PFincDadditionalStatusArrayCheck == undefined &&
      PFadditionalStatusArrayCheck == undefined
    ) {
      $(".proceednowbtn4").attr("disabled", false);
      CLproceednowforword = "success";
    } else if (
      CLAadhar1Status == "review" &&
      CLAadhar2Status == "review" &&
      CLPan1Status == "review" &&
      CLPan2Status == "review" &&
      CLICStatus == "review" &&
      CLMOAStatus == "review" &&
      CLAOAStatus == "review" &&
      CLBPStatus == "review" &&
      CLDataStatus == "remove" &&
      CLBankSStatus == "remove" &&
      PFincDadditionalStatusArrayCheck == undefined &&
      PFadditionalStatusArrayCheck == undefined
    ) {
      $(".proceednowbtn4").attr("disabled", false);
      CLproceednowforword = "success";
    } else if (
      CLAadhar1Status == "done" &&
      CLAadhar2Status == "done" &&
      CLPan1Status == "done" &&
      CLPan2Status == "done" &&
      CLICStatus == "done" &&
      CLMOAStatus == "done" &&
      CLAOAStatus == "done" &&
      CLBPStatus == "done" &&
      CLDataStatus == "remove" &&
      CLBankSStatus == "remove" &&
      CLincDadditionalStatusArrayCheck == undefined &&
      CLadditionalStatusArrayCheck == undefined
    ) {
      $(".proceednowbtn4").attr("disabled", false);
      CLproceednowforword = "success";
    } else {
      CLproceednowforword = "fail";
      $(".proceednowbtn4").attr("disabled", true);
    }
  };

  handleCLProceedBtn = () => {
    this.checkhandleCLProceedBtn();
    if (CLproceednowforword == "success") {
      if (
        this.state.itrfusername.trim().length < 96 &&
        this.state.itrfpassword.trim().length < 96
      ) {
        axios
          .post(
            process.env.REACT_APP_API_BASE_URL + "p=lockService",
            {
              userId: Decuserid,
              requestType: temp,
              agentId: Decagentid,

              serviceId: Decserviceid,
              serviceName: Decservicename,
              serviceType: this.state.serviceType,
              FY: this.state.SelectedYear,
              Month: "",
              inputFields: [
                {
                  inputFieldName: "PortalUserID",
                  inputFieldValue: this.state.itrfusername.trim(),
                  inputFieldtype: "onetime",
                },
                {
                  inputFieldName: "PortalPassword",
                  inputFieldValue: this.state.itrfpassword.trim(),
                  inputFieldtype: "onetime",
                },
              ],
            },
            {
              headers: {
                Authkey: process.env.REACT_APP_API_KEY,
                "App-Token-Access": Decjwttoken,
              },
              auth: {
                username: process.env.REACT_APP_API_USERNAME,
                password: process.env.REACT_APP_API_PASSWORD,
              },
            }
          )
          .then((res) => {
            if (res.data.code == "200" && res.data.status == "success") {
              this.showNotification(res.data.message);
              this.getserviceInformationApi();
              this.getdeliveredDocuments();
            } else if (
              res.data.code == "201" &&
              res.data.status == "rejected"
            ) {
              this.showNotification(res.data.message);
              this.getserviceInformationApi();
              this.getdeliveredDocuments();
            } else if (
              res.data.code == "201" &&
              res.data.status == "invalidauth"
            ) {
              this.logout();
            } else if (res.data.code == "201" && res.data.status == "timeOut") {
              localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
              this.props.history.push({
                pathname: "/dashboard",
                state: {
                  timeout: true,
                },
              });
            } else {
              this.showNotification(res.data.message);
            }
          })
          .catch((err) => {});
      } else if (this.state.itrfusername.trim().length > 95) {
        this.showNotification("Max length exceed!");
      } else if (this.state.itrfpassword.trim().length > 95) {
        this.showNotification("Max length exceed!");
      } else {
        this.showNotification("Required fields are empty!");
      }
    } else {
      this.showNotification("Required fields are empty!");
    }
  };
  backbutton = () => {
    this.props.history.push({
      pathname: `/myuser/${Decuserid}/userservices`,
    });
  };
  showpassword = () => {
    type = $(".itrfpasswordinput").attr("type");
    if (type == "text") {
      this.setState({
        showpassword: false,
      });
      $(".itrfpasswordinput").attr("type", "password");
    } else {
      this.setState({
        showpassword: true,
      });
      $(".itrfpasswordinput").attr("type", "text");
    }
  };
  credentials = () => {
    return (
      <>
        <div className="displayflex">
          <div className="uploadnocol">
            <p className="uploadadditionalsidetext2">1)</p>
          </div>
          <div className="uploadadditionaldatacol2">
            <p className="uploadadditionalnormaltext">
              ITR portal credentials:-
            </p>
            <input
              type="text"
              placeholder="PAN Card No..."
              maxLength="95"
              value={this.state.itrfusername}
              autoComplete="off"
              onChange={(e) => this.setState({ itrfusername: e.target.value })}
              className="uploadtextfield marginzero itrfusername"
            />
          </div>
        </div>
        <div className="displayflex lastdisplayflex">
          <div className="uploadnocol">
            <p className="uploadnormaltext2">2)</p>
          </div>
          <div className="uploadlastdatacol">
            <input
              type="password"
              placeholder="Password..."
              maxLength="95"
              autoComplete="off"
              value={this.state.itrfpassword}
              onChange={(e) => this.setState({ itrfpassword: e.target.value })}
              className="uploadtextfield marginzero itrfpasswordinput itrfpassword"
            />
            {this.state.showpassword ? (
              <img
                className="showpassword-btn"
                src={process.env.PUBLIC_URL + "/img/hidden.svg"}
                onClick={() => this.showpassword()}
              />
            ) : (
              <img
                className="showpassword-btn"
                src={process.env.PUBLIC_URL + "/img/show.svg"}
                onClick={() => this.showpassword()}
              />
            )}
          </div>
        </div>
      </>
    );
  };

  handletimeline = () => {
    $("#timeline_btnloader").show();
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=timeLine",
        {
          agentId: Decagentid,
          userId: Decuserid,
          serviceId: Decserviceid,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          if (res.data.timeLine.length > 0) {
            let length = res.data.timeLine.length;
            let ftimeline = res.data.timeLine.filter((items, index) => {
              return index <= this.state.timelcount;
            });
            this.setState({
              filtertimel: ftimeline,
              timelcount: this.state.timelcount + 3,
              Timeline: res.data.timeLine,
              Timelinedata: true,
            });

            // .splice(this.state.timelcount,length)
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      });
  };

  handlemoretimeline = () => {
    let ftimeline = this.state.Timeline.filter((items, index) => {
      return index <= this.state.timelcount;
    });
    this.setState({
      filtertimel: ftimeline,
      timelcount: this.state.timelcount + 3,
    });
  };

  getdeliveredDocuments = () => {
    $(".serviceoverlay").show();
    var totptoken=generatetotp(Decuserid,"p=documentDetails".toUpperCase())
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL+'p=documentDetails',
        {
          agentId: Decagentid,
          userId: Decuserid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
          requestType: temp,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((resp) => {
        $(".serviceoverlay").hide();
        if (resp.data.code == 200 && resp.data.status == "success") {
          this.setState({
            deliveredDocuments: resp.data.deliveredDocuments,
            deliveredDocumentslength: resp.data.deliveredDocuments.length,
          });
         
        } else if (
          resp.data.code == "201" &&
          resp.data.status == "invalidauth"
        ) {
          this.logout();
        }
        if (resp.data.monthYearDetails.length != 0) {
          this.setState({
            SelectedYear: resp.data.monthYearDetails[0].FY,
          });
        } 
      })
      .catch((err) => {
        $(".serviceoverlay").hide();
        
      });
  };

  showDeliveredDocuments() {
    if (this.state.deliveredDocuments.length > 0) {
      return this.state.deliveredDocuments.map((activedoc, index) => {
        return (
          <div className="displayflex documentmaincard" key={index}>
            <div>
              <img
                src={process.env.PUBLIC_URL + "/img/Path 247.svg"}
                className="docutypeimg"
              />
            </div>
            <div className="docucontdiv">
              <div className="row">
                <div className="col-md-6">
                  <p className="nameofdoc">{activedoc.documentName}</p>
                  <p className="datesenttext">
                    Date received:-{" "}
                    <span className="datesentspan">{activedoc.datesent}</span>
                  </p>
                </div>
                <div className="col-md-6">
                  <div className="fl_right">
                    <div className="displayflex">
                      <div className="viewbtndivpadding">
                        <a
                          href={activedoc.documentLink}
                          target="_blank"
                          className="viewbtn"
                        >
                          View{" "}
                          <img
                            src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                          />
                        </a>
                      </div>
                      <div>
                        <a
                          href={activedoc.documentLink}
                          className="download_btn"
                          target="_blank"
                          download
                        >
                          Download{" "}
                          <img
                            src={
                              process.env.PUBLIC_URL + "/img/download_icon.svg"
                            }
                            alt="download"
                          />
                        </a>
                      </div>
                    </div>
                    <p className="docushareviatext">
                      Share via:{" "}
                      <a
                        onClick={() =>
                          this.whatsapp(
                            activedoc.documentName,
                            activedoc.documentLink
                          )
                        }
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/whatsapp.svg"}
                          className="docushareviawhats"
                        />
                      </a>{" "}
                      <a
                        onClick={() =>
                          this.email(
                            activedoc.documentName,
                            activedoc.documentLink
                          )
                        }
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/mail.svg"}
                          className="docushareviamail"
                        />
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.deliveredDocuments.length == 0) {
      return (
        <center className="myservicequiteimgcenter">
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
            className="emptydeliveredDocuments"
          />
        </center>
      );
    }
  }

  render() {
    if (
      localStorage.getItem("gglltakoinoeenl") ==
        process.env.REACT_APP_LOGIN_KEY &&
      localStorage.getItem("toeljgtkewlna") != null &&
      localStorage.getItem("qazxswedcvfrtgb") != null
    ) {
      return (
        <div className="cont">
          <div id="notifContainer"></div>

          <Helmet>
            <link
              rel="stylesheet"
              href={process.env.PUBLIC_URL + "/css/select2.css"}
            />
          </Helmet>
          <Header />
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
            className="sidebaropenicon"
          />
          <img
            onClick={this.backbutton}
            src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
            className="sidebarbackicon"
          />
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"}
            className="sidebarexpandicon"
          />

          <div className="overlay1"></div>
          <div className="sidebar sidebarshift">
            <div className="side_div_2 sidebar_txt active">
              <Link to="/dashboard">Overview</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <Link to="/myservices">My Services</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <Link to="/paymentinvoice">Payments Invoices</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <Link to="/documentrecords">Documents Record</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <Link to="/addservice">Add service</Link>
            </div>
            <div></div>
          </div>
          <div className="arrow-left2 visibilityhidden"></div>
          <div className="white_div expandwhite_div">
            <div className="serviceoverlay">
              <div className="serviceoverlayinside">
                <center>
                  <img
                    src={process.env.PUBLIC_URL + "/img/loader.gif"}
                    className="serviceloader"
                  />
                </center>
              </div>
            </div>
            <div className="white_box">
            {this.state.referralPartner ? 
              <div className="uploaddocu">
                <div className="row">
                  {/* <div className="myservicesortbyfilterbystatus mt-1 ml-1"></div> */}
                  <div className="col-lg-9 mt-3">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="inner-header d-flex justify-content-between">
                          <div className="slider-navigation-tab">
                            <header className="tabs-nav position-relative">
                              <ul className="d-flex slider-navigation-tab-ul">
                                <li
                                  className="active slider-navigation-tab-li"
                                  id="activeTab"
                                >
                                  <a href="#tab1" className="px-2 py-1">
                                    Information given
                                  </a>
                                </li>
                                <li
                                  className="slider-navigation-tab-li"
                                  id="completedTab"
                                >
                                  <a href="#tab2" className="px-2 py-1">
                                    Deliverables
                                  </a>
                                </li>
                                <hr className="slider-navigation-tab-vertical-line" />
                                <div className="sliderpage-slider"></div>
                              </ul>
                            </header>
                          </div>
                        </div>
                        {/* <p className="uploadtext">Upload Information :-</p> */}
                      </div>
                      <div className="col-md-6 d-flex  selectcateselectpadding">
                        <select
                          className="selectcateselect selectcateselect1"
                          value={this.state.dropDownValue}
                          onChange={this.handledropDownValueChange}
                        >
                          <option
                            value="volvo"
                            className="selectcateselectoption2"
                            id="volvo"
                          >
                            Select category...
                          </option>
                          <option
                            value="ITRF_S_OneTime_Documents"
                            className="selectcateselectoption"
                          >
                            Salaried
                          </option>
                          <option
                            value="ITRF_BP_OneTime_Documents"
                            className="selectcateselectoption"
                          >
                            Proprietor
                          </option>
                          <option
                            value="ITRF_BPP_OneTime_Documents"
                            className="selectcateselectoption"
                          >
                            Partnership firm / LLP
                          </option>
                          <option
                            value="ITRF_BPC_OneTime_Documents"
                            className="selectcateselectoption"
                          >
                            Company
                          </option>
                        </select>
                        <select
                          className="selectfinancialyear"
                          value={this.state.SelectedYear}
                          onChange={this.handleYearChange}
                        >
                          <option value={this.state.thyear}>
                            A.Y. {this.state.thyear}
                          </option>
                          <option value={this.state.Preyear}>
                            A.Y. {this.state.Preyear}
                          </option>
                          <option value={this.state.Fyear}>
                            A.Y. {this.state.Fyear}
                          </option>
                        </select>
                      </div>
                    </div>
                    </div>
</div>
                    {/* Default image */}

                    <div className="tabs-content">
                      <div id="tab1" className="tab-content">
                        <div className="row">
                          <div12 className="col-lg-9">
                            <div className="mainsectionuploadsection">
                              <div className="itrf-main-content">

                    <center id="Itrf_default_Img">
                      <img
                        src={process.env.PUBLIC_URL + "/img/Group_3582.svg"}
                        className="selectcategoryimg"
                      />
                    </center>

                    {/* Salaried section */}
                    <divdiv1 id="ItrfSalariedSection">
                      <div className="uploadscrolldiv">
                        <div className="uploadscrollcardinside">
                          {this.credentials()}
                          {/* S Aadhar */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Aadhaar card
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    Front and back both side
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/AadhaarCard.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="SAadharseecommentsbtn"
                                      onClick={this.handleSAadharseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="SAadharhidecommentsbtn"
                                      onClick={
                                        this.handleSAadharhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_1"
                                      className="choose_btn"
                                      id="choose_SAadhar"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_1"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleSAadharChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_SAadhar"
                                      onClick={(e) => this.removeSAadhar(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_SAadhar"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_SAadhar"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.SAadharDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_SAadhar"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* S  PAN Card */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    PAN Card
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PANCard.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="SPanseecommentsbtn"
                                      onClick={this.handleSPanseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="SPanhidecommentsbtn"
                                      onClick={this.handleSPanhidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_2"
                                      className="choose_btn"
                                      id="choose_SPan"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_15188.svg"
                                        }
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_2"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) => this.handleSPanChange(e)}
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_SPan"
                                      onClick={(e) => this.removeSPan(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_SPan"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_SPan"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.SPanDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_SPan"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* SBp */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Bank Proof
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    Bank passbook front page or a cancelled
                                    cheque
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/BankProof.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="SBpseecommentsbtn"
                                      onClick={this.handleSBpseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="SBphidecommentsbtn"
                                      onClick={this.handleSBphidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_3"
                                      className="choose_btn"
                                      id="choose_SBp"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_3"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) => this.handleSBpChange(e)}
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_SBp"
                                      onClick={(e) => this.removeSBp(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_SBp"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_SBp"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.SBpDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_SBp"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* SPBS */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Previous year Balance sheet
                                  </p>
                                  <p className="uploadinstr">
                                    Please attach if available
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PreviousBS.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="SPBSseecommentsbtn"
                                      onClick={this.handleSPBSseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="SPBShidecommentsbtn"
                                      onClick={this.handleSPBShidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_5"
                                      className="choose_btn"
                                      id="choose_SPBS"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_5"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) => this.handleSPBSChange(e)}
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_SPBS"
                                      onClick={(e) => this.removeSPBS(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_SPBS"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_SPBS"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.SPBSDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_SPBS"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* SPR */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Previous year return
                                  </p>
                                  <p className="uploadinstr">
                                    Please attach if available
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PreviousReturns.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="SPRseecommentsbtn"
                                      onClick={this.handleSPRseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="SPRhidecommentsbtn"
                                      onClick={this.handleSPRhidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_6"
                                      className="choose_btn"
                                      id="choose_SPR"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_6"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) => this.handleSPFRChange(e)}
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_SPR"
                                      onClick={(e) => this.removeSPR(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_SPR"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_SPR"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.SPRDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_SPR"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* SincD */}
                          <div id="SincDadditionalDocumentDiv">
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadadditionalsidetext visibilityhidden">
                                  1&#41;
                                </p>
                              </div>
                              <div className="uploadadditionaldatacol">
                                <p className="uploadadditionalnormaltext">
                                  Any Income / Deduction documents:-
                                </p>
                              </div>
                            </div>
                            {this.SincDAdditionalFilesArea()}

                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadadditionalsidetext visibilityhidden">
                                  1&#41;
                                </p>
                              </div>
                              <div className="uploadadditionaldatacol">
                                <button
                                  className="adddocumentbtn"
                                  id="SincDadddocumentbtn"
                                  onClick={() =>
                                    this.handleSincDAddfileBtn(
                                      SincDAdditionalFilesIndex
                                    )
                                  }
                                >
                                  Add document{" "}
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Path 19524.svg"
                                    }
                                  />
                                </button>
                              </div>
                            </div>
                          </div>

                          {/* SBankS */}
                          <div className="displayflex">
                            <div className="uploaddatacol">
                              <p className="uploadadditionalnormaltext">
                                Salaried (Recurring)
                              </p>
                              <div className="row">
                                <div className="col-md-5">
                                  <div className="uploadnocol d-flex">
                                    <div>
                                      <p className="uploadnormaltext itrfdocumentno mr-1"></p>
                                    </div>
                                    <div>
                                      <p className="uploadnormaltext">
                                        {" "}
                                        Bank Statement
                                      </p>
                                      <p className="uploadinstr">
                                        From 1st April to 31st March of related
                                        F.Y
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/BankStatement.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="SBankSseecommentsbtn"
                                      onClick={this.handleSBankSseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="SBankShidecommentsbtn"
                                      onClick={this.handleSBankShidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_101"
                                      className="choose_btn"
                                      id="choose_SBankS"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_101"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleSBankSChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_SBankS"
                                      onClick={(e) => this.removeSBankS(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_SBankS"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_SBankS"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.SBankSDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_SBankS"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* SF16 */}
                          <div className="displayflex lastdisplayflex">
                            <div className="uploadlastdatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <div className="uploadnocol d-flex">
                                    <div>
                                      <p className="uploadnormaltext itrfdocumentno mr-1"></p>
                                    </div>
                                    <div>
                                      <p className="uploadnormaltext">
                                        Form - 16 / Salaried Certificate
                                      </p>
                                      <p className="uploadinstr">
                                        Please attach if available
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-7 ml-md-0 ml-4">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/Form16_or_SalaryCertificate.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="SF16seecommentsbtn"
                                      onClick={this.handleSF16seecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="SF16hidecommentsbtn"
                                      onClick={this.handleSF16hidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_4"
                                      className="choose_btn"
                                      id="choose_SF16"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_4"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) => this.handleSF16Change(e)}
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_SF16"
                                      onClick={(e) => this.removeSF16(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_SF16"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_SF16"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.SF16DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_SF16"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* SAdditional */}
                          <div id="SadditionalDocumentDiv">
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadadditionalsidetext visibilityhidden">
                                  1&#41;
                                </p>
                              </div>
                              <div className="uploadadditionaldatacol">
                                <p className="uploadadditionalnormaltext">
                                  Additional documents:-
                                </p>
                              </div>
                            </div>
                            {this.SAdditionalFilesArea()}

                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadadditionalsidetext visibilityhidden">
                                  1&#41;
                                </p>
                              </div>
                              <div className="uploadadditionaldatacol">
                                <button
                                  className="adddocumentbtn"
                                  id="Sadddocumentbtn"
                                  onClick={() =>
                                    this.handleAddSfileBtn(
                                      SAdditionalFilesIndex
                                    )
                                  }
                                >
                                  Add document{" "}
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Path 19524.svg"
                                    }
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <center>
                        <button
                          className="proceednowbtn proceednowbtn1"
                          onClick={this.handleSProceedBtn}
                        >
                          Proceed now{" "}
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/tick.svg"}
                          />
                        </button>
                      </center>
                    </divdiv1>

                    {/* proprietor section */}
                    <divdiv2 id="ItrfproprietorSection">
                      <div className="uploadscrolldiv">
                        <div className="uploadscrollcardinside">
                          {this.credentials()}
                          {/* aadhar */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Aadhaar card
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    Front and back both side
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/AadhaarCard.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PAadharseecommentsbtn"
                                      onClick={this.handlePAadharseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PAadharhidecommentsbtn"
                                      onClick={
                                        this.handlePAadharhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_7"
                                      className="choose_btn"
                                      id="choose_PAadhar"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_7"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePAadharChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PAadhar"
                                      onClick={(e) => this.removePAadhar(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PAadhar"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_PAadhar"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PAadharDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PAadhar"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Pan */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    PAN Card
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PANCard.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PPanseecommentsbtn"
                                      onClick={this.handlePPanseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PPanhidecommentsbtn"
                                      onClick={this.handlePPanhidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_8"
                                      className="choose_btn"
                                      id="choose_PPan"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_8"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) => this.handlePPanChange(e)}
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PPan"
                                      onClick={(e) => this.removePPan(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PPan"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_PPan"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PPanDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PPan"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* PBP  */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Bank Proof
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    Bank passbook front page or a cancelled
                                    cheque
                                  </p>
                                </div>

                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/BankProof.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PBPseecommentsbtn"
                                      onClick={this.handlePBPseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PBPhidecommentsbtn"
                                      onClick={this.handlePBPhidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_9"
                                      className="choose_btn"
                                      id="choose_PBP"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_9"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) => this.handlePBPChange(e)}
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PBP"
                                      onClick={(e) => this.removePBP(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PBP"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_PBP"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PBPDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PBP"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* PBS */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Previous year Balance sheet
                                  </p>
                                  <p className="uploadinstr">
                                    Please attach if available
                                  </p>
                                </div>

                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PreviousBS.pdf
                                        "
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PBSseecommentsbtn"
                                      onClick={this.handlePBSseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PBShidecommentsbtn"
                                      onClick={this.handlePBPhidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_10"
                                      className="choose_btn"
                                      id="choose_PBS"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_10"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) => this.handlePBSChange(e)}
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PBS"
                                      onClick={(e) => this.removePBS(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PBS"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_PBS"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PBSDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PBS"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* PPR */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Previous year return
                                  </p>
                                  <p className="uploadinstr">
                                    Please attach if available
                                  </p>
                                </div>

                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PreviousReturns.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PPRseecommentsbtn"
                                      onClick={this.handlePPRseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PPRhidecommentsbtn"
                                      onClick={this.handlePPRhidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_11"
                                      className="choose_btn"
                                      id="choose_PPR"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_11"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) => this.handlePPFRChange(e)}
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PPR"
                                      onClick={(e) => this.removePPR(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PPR"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_PPR"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PPRDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PPR"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div id="PincDadditionalDocumentDiv">
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadadditionalsidetext visibilityhidden">
                                  1&#41;
                                </p>
                              </div>
                              <div className="uploadadditionaldatacol">
                                <p className="uploadadditionalnormaltext">
                                  Any Income / Deduction documents:-
                                </p>
                              </div>
                            </div>
                            {this.PincDAdditionalFilesArea()}

                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadadditionalsidetext visibilityhidden">
                                  1&#41;
                                </p>
                              </div>
                              <div className="uploadadditionaldatacol">
                                <button
                                  className="adddocumentbtn"
                                  id="PincDadddocumentbtn"
                                  onClick={() =>
                                    this.handlePincDAddfileBtn(
                                      PincDAdditionalFilesIndex
                                    )
                                  }
                                >
                                  Add document{" "}
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Path 19524.svg"
                                    }
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="displayflex">
                            <div className="uploaddatacol">
                              <p className="uploadadditionalnormaltext">
                                Business/Profession (Recurring)
                              </p>
                              <div className="row">
                                <div className="col-md-5">
                                  <div className="uploadnocol d-flex">
                                    <div>
                                      <p className="uploadnormaltext itrfdocumentno mr-1"></p>
                                    </div>
                                    <div>
                                      <p className="uploadnormaltext">
                                        {" "}
                                        Estimated figures / Current year Tally
                                        data / Audit report / Balance sheet{" "}
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                      <p className="uploadinstr">
                                        Please attach any one
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-7 ml-md-0 ml-4">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/Data.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PDataseecommentsbtn"
                                      onClick={this.handlePDataseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PDatahidecommentsbtn"
                                      onClick={this.handlePDatahidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_12"
                                      className="choose_btn"
                                      id="choose_PData"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_12"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePDataChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PData"
                                      onClick={(e) => this.removePData(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PData"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_PData"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PDataDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PData"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="displayflex">
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <div className="uploadnocol d-flex">
                                    <div>
                                      <p className="uploadnormaltext itrfdocumentno mr-1"></p>
                                    </div>
                                    <div>
                                      <p className="uploadnormaltext">
                                        Bank Statement{" "}
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                      <p className="uploadinstr">
                                        From 1st April to 31st March of related
                                        F.Y
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-7 ml-md-0 ml-4">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/BankStatement.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PBankSseecommentsbtn"
                                      onClick={this.handlePBankSseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PBankShidecommentsbtn"
                                      onClick={this.handlePBankShidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_102"
                                      className="choose_btn"
                                      id="choose_PBankS"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_102"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePBankSChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PBankS"
                                      onClick={(e) => this.removePBankS(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PBankS"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_PBankS"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PBankSDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PBankS"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="displayflex">
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <div className="uploadnocol d-flex">
                                    <div>
                                      <p className="uploadnormaltext itrfdocumentno mr-1"></p>
                                    </div>
                                    <div>
                                      <p className="uploadnormaltext">
                                        GST Return
                                      </p>
                                      <p className="uploadinstr">
                                        Please attach if available
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-7 ml-md-0 ml-4">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/GSTReturn.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PGSTReturnseecommentsbtn"
                                      onClick={
                                        this.handlePGSTReturnseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PGSTReturnhidecommentsbtn"
                                      onClick={
                                        this.handlePGSTReturnhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_13"
                                      className="choose_btn"
                                      id="choose_PGSTReturn"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_13"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePGSTReturnChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PGSTReturn"
                                      onClick={(e) => this.removePGSTReturn(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PGSTReturn"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_PGSTReturn"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PGSTReturnDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PGSTReturn"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="displayflex">
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <div className="uploadnocol d-flex">
                                    <div>
                                      <p className="uploadnormaltext itrfdocumentno mr-1"></p>
                                    </div>
                                    <div>
                                      <p className="uploadnormaltext">
                                        {" "}
                                        TDS Return
                                      </p>
                                      <p className="uploadinstr">
                                        Please attach if available
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-7 ml-md-0 ml-4">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/TDSReturn.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PTDSReturnseecommentsbtn"
                                      onClick={
                                        this.handlePTDSReturnseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PTDSReturnhidecommentsbtn"
                                      onClick={
                                        this.handlePTDSReturnhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_14"
                                      className="choose_btn"
                                      id="choose_PTDSReturn"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_14"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePTDSReturnChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PTDSReturn"
                                      onClick={(e) => this.removePTDSReturn(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PTDSReturn"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_PTDSReturn"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PTDSReturnDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PTDSReturn"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="displayflex lastdisplayflex">
                            <div className="uploadlastdatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <div className="uploadnocol d-flex">
                                    <div>
                                      <p className="uploadnormaltext itrfdocumentno mr-1"></p>
                                    </div>
                                    <div>
                                      <p className="uploadnormaltext">
                                        Tax deposit challan
                                      </p>
                                      <p className="uploadinstr">
                                        Please attach if available
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-7 ml-md-0 ml-4">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/Challan.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PChallanseecommentsbtn"
                                      onClick={
                                        this.handlePChallanseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PChallanhidecommentsbtn"
                                      onClick={
                                        this.handlePChallanhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_15"
                                      className="choose_btn"
                                      id="choose_PChallan"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_15"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePChallanChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PChallan"
                                      onClick={(e) => this.removePChallan(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PChallan"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_PChallan"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PChallanDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PChallan"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div id="PadditionalDocumentDiv">
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadadditionalsidetext visibilityhidden">
                                  1&#41;
                                </p>
                              </div>
                              <div className="uploadadditionaldatacol">
                                <p className="uploadadditionalnormaltext">
                                  Additional documents:-
                                </p>
                              </div>
                            </div>
                            {this.PAdditionalFilesArea()}

                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadadditionalsidetext visibilityhidden">
                                  1&#41;
                                </p>
                              </div>
                              <div className="uploadadditionaldatacol">
                                <button
                                  className="adddocumentbtn"
                                  id="Padddocumentbtn"
                                  onClick={() =>
                                    this.handleAddPfileBtn(
                                      PAdditionalFilesIndex
                                    )
                                  }
                                >
                                  Add document{" "}
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Path 19524.svg"
                                    }
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <center>
                        <button
                          className="proceednowbtn proceednowbtn2"
                          onClick={this.handlePProceedBtn}
                        >
                          Proceed now{" "}
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/tick.svg"}
                          />
                        </button>
                      </center>
                    </divdiv2>

                    {/* Partnership firm */}
                    <divdiv3 id="ItrfPartnershipFirm">
                      <div className="uploadscrolldiv">
                        <div className="uploadscrollcardinside">
                          {this.credentials()}
                          {/* PFAadhar1 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Aadhaar card (Partner 1)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    Front and back both side
                                  </p>
                                </div>

                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/AadhaarCard.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PFAadhar1seecommentsbtn"
                                      onClick={
                                        this.handlePFAadhar1seecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PFAadhar1hidecommentsbtn"
                                      onClick={
                                        this.handlePFAadhar1hidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_16"
                                      className="choose_btn"
                                      id="choose_PFAadhar1"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_16"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePFAadhar1Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PFAadhar1"
                                      onClick={(e) => this.removePFAadhar1(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PFAadhar1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_PFAadhar1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PFAadhar1DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PFAadhar1"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* PFAadhar2 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Aadhaar card (Partner 2)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    Front and back both side
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/AadhaarCard.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PFAadhar2seecommentsbtn"
                                      onClick={
                                        this.handlePFAadhar2seecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PFAadhar2hidecommentsbtn"
                                      onClick={
                                        this.handlePFAadhar2hidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_17"
                                      className="choose_btn"
                                      id="choose_PFAadhar2"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_17"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePFAadhar2Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PFAadhar2"
                                      onClick={(e) => this.removePFAadhar2(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PFAadhar2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_PFAadhar2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PFAadhar2DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PFAadhar2"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* PFPan1 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    PAN card (Partner 1)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>

                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PANCard.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PFPan1seecommentsbtn"
                                      onClick={this.handlePFPan1seecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PFPan1hidecommentsbtn"
                                      onClick={this.handlePFPan1hidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_18"
                                      className="choose_btn"
                                      id="choose_PFPan1"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_18"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePFPan1Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PFPan1"
                                      onClick={(e) => this.removePFPan1(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PFPan1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_PFPan1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PFPan1DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PFPan1"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* PFPan2 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    PAN card (Partner 2)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>

                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PANCard.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PFPan2seecommentsbtn"
                                      onClick={this.handlePFPan2seecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PFPan2hidecommentsbtn"
                                      onClick={this.handlePFPan2hidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_19"
                                      className="choose_btn"
                                      id="choose_PFPan2"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_19"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePFPan2Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PFPan2"
                                      onClick={(e) => this.removePFPan2(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PFPan2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_PFPan2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PFPan2DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PFPan2"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* PFDeed */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Partnership deed
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>

                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PartnershipDeed.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PFDeedseecommentsbtn"
                                      onClick={this.handlePFDeedseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PFDeedhidecommentsbtn"
                                      onClick={this.handlePFDeedhidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_20"
                                      className="choose_btn"
                                      id="choose_PFDeed"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_20"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePFDeedChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PFDeed"
                                      onClick={(e) => this.removePFDeed(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PFDeed"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_PFDeed"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PFDeedDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PFDeed"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* PFBP */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Bank Proof
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    Bank passbook front page or a cancelled
                                    cheque
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/BankProof.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PFBPseecommentsbtn"
                                      onClick={this.handlePFBPseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PFBPhidecommentsbtn"
                                      onClick={this.handlePFBPhidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_21"
                                      className="choose_btn"
                                      id="choose_PFBP"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_21"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) => this.handlePFBPChange(e)}
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PFBP"
                                      onClick={(e) => this.removePFBP(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PFBP"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_PFBP"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PFBPDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PFBP"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* PFRC */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Registration Certificate
                                    <sup className="requireddocstar"></sup>
                                  </p>
                                  <p className="uploadinstr">
                                    Please attach if available
                                  </p>
                                </div>

                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/CertificateOfRegistrationP.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PFRCseecommentsbtn"
                                      onClick={this.handlePFRCseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PFRChidecommentsbtn"
                                      onClick={this.handlePFRChidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_22"
                                      className="choose_btn"
                                      id="choose_PFRC"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_22"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) => this.handlePFRCChange(e)}
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PFRC"
                                      onClick={(e) => this.removePFRC(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PFRC"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_PFRC"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PFRCDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PFRC"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* PFPBS_A */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Previous year Balance sheet / Audit report
                                  </p>
                                  <p className="uploadinstr">
                                    Please attach if available
                                  </p>
                                </div>

                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PreviousReturnsORAuditReport.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PFPBS_Aseecommentsbtn"
                                      onClick={this.handlePFPBS_Aseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PFPBS_Ahidecommentsbtn"
                                      onClick={
                                        this.handlePFPBS_Ahidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_23"
                                      className="choose_btn"
                                      id="choose_PFPBS_A"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_23"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePFPBS_AChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PFPBS_A"
                                      onClick={(e) => this.removePFPBS_A(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PFPBS_A"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_PFPBS_A"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PFPBS_ADocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PFPBS_A"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* PFPR */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Previous year return
                                  </p>
                                  <p className="uploadinstr">
                                    Please attach if available
                                  </p>
                                </div>

                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PreviousReturns.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PFPRseecommentsbtn"
                                      onClick={this.handlePFPRseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PFPRhidecommentsbtn"
                                      onClick={this.handlePFPRhidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_24"
                                      className="choose_btn"
                                      id="choose_PFPR"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_24"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) => this.handlePFPRChange(e)}
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PFPR"
                                      onClick={(e) => this.removePFPR(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PFPR"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_PFPR"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PFPRDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PFPR"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* PFincD */}

                          <div id="PFincDadditionalDocumentDiv">
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadadditionalsidetext visibilityhidden">
                                  1&#41;
                                </p>
                              </div>
                              <div className="uploadadditionaldatacol">
                                <p className="uploadadditionalnormaltext">
                                  Any Income / Deduction documents:-
                                </p>
                              </div>
                            </div>
                            {this.PFincDAdditionalFilesArea()}

                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadadditionalsidetext visibilityhidden">
                                  1&#41;
                                </p>
                              </div>
                              <div className="uploadadditionaldatacol">
                                <button
                                  className="adddocumentbtn"
                                  id="PFincDadddocumentbtn"
                                  onClick={() =>
                                    this.handlePFincDAddfileBtn(
                                      PFincDAdditionalFilesIndex
                                    )
                                  }
                                >
                                  Add document{" "}
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Path 19524.svg"
                                    }
                                  />
                                </button>
                              </div>
                            </div>
                          </div>

                          {/* PFData */}
                          <div className="displayflex">
                            <div className="uploaddatacol">
                              <p className="uploadadditionalnormaltext">
                                Business/Profession (Recurring)
                              </p>
                              <div className="row">
                                <div className="col-md-5">
                                  <div className="uploadnocol d-flex">
                                    <div>
                                      <p className="uploadnormaltext itrfdocumentno mr-1"></p>
                                    </div>
                                    <div>
                                      <p className="uploadnormaltext">
                                        {" "}
                                        Estimated figures / Current year Tally
                                        data / Audit report / Balance sheet{" "}
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                      <p className="uploadinstr">
                                        Please attach any one
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-7 ml-md-0 ml-4">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/Data.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PFDataseecommentsbtn"
                                      onClick={this.handlePFDataseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PFDatahidecommentsbtn"
                                      onClick={this.handlePFDatahidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_25"
                                      className="choose_btn"
                                      id="choose_PFData"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_25"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePFDataChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PFData"
                                      onClick={(e) => this.removePFData(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PFData"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_PFData"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PFDataDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PFData"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PFBankS */}
                          <div className="displayflex">
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <div className="uploadnocol d-flex">
                                    <div>
                                      <p className="uploadnormaltext itrfdocumentno mr-1"></p>
                                    </div>
                                    <div>
                                      <p className="uploadnormaltext">
                                        Bank Statement
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                      <p className="uploadinstr">
                                        From 1st April to 31st March of related
                                        F.Y
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-7 ml-md-0 ml-4">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/BankStatement.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PFBankSseecommentsbtn"
                                      onClick={this.handlePFBankSseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PFBankShidecommentsbtn"
                                      onClick={
                                        this.handlePFBankShidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_103"
                                      className="choose_btn"
                                      id="choose_PFBankS"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_103"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePFBankSChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PFBankS"
                                      onClick={(e) => this.removePFBankS(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PFBankS"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_PFBankS"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PFBankSDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PFBankS"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PFGSTReturn */}
                          <div className="displayflex">
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <div className="uploadnocol d-flex">
                                    <div>
                                      <p className="uploadnormaltext itrfdocumentno mr-1"></p>
                                    </div>
                                    <div>
                                      <p className="uploadnormaltext">
                                        GST Return
                                      </p>
                                      <p className="uploadinstr">
                                        Please attach if available
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-7 ml-md-0 ml-4">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/GSTReturn.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PFGSTReturnseecommentsbtn"
                                      onClick={
                                        this.handlePFGSTReturnseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PFGSTReturnhidecommentsbtn"
                                      onClick={
                                        this.handlePFGSTReturnhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_26"
                                      className="choose_btn"
                                      id="choose_PFGSTReturn"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_26"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePFGSTReturnChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PFGSTReturn"
                                      onClick={(e) => this.removePFGSTReturn(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PFGSTReturn"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_PFGSTReturn"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PFGSTReturnDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PFGSTReturn"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PFTDSReturn */}
                          <div className="displayflex">
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <div className="uploadnocol d-flex">
                                    <div>
                                      <p className="uploadnormaltext itrfdocumentno mr-1"></p>
                                    </div>
                                    <div>
                                      <p className="uploadnormaltext">
                                        {" "}
                                        TDS Return
                                      </p>
                                      <p className="uploadinstr">
                                        Please attach if available
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-7 ml-md-0 ml-4">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/TDSReturn.pdf
"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PFTDSReturnseecommentsbtn"
                                      onClick={
                                        this.handlePFTDSReturnseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PFTDSReturnhidecommentsbtn"
                                      onClick={
                                        this.handlePFTDSReturnhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_27"
                                      className="choose_btn"
                                      id="choose_PFTDSReturn"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_27"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePFTDSReturnChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PFTDSReturn"
                                      onClick={(e) => this.removePFTDSReturn(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PFTDSReturn"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_PFTDSReturn"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PFTDSReturnDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PFTDSReturn"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PFChallan */}
                          <div className="displayflex lastdisplayflex">
                            <div className="uploadlastdatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <div className="uploadnocol d-flex">
                                    <div>
                                      <p className="uploadnormaltext itrfdocumentno mr-1"></p>
                                    </div>
                                    <div>
                                      <p className="uploadnormaltext">
                                        Tax deposit challan
                                      </p>
                                      <p className="uploadinstr">
                                        Please attach if available
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-7 ml-md-0 ml-4">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/Challan.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="PFChallanseecommentsbtn"
                                      onClick={
                                        this.handlePFChallanseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="PFChallanhidecommentsbtn"
                                      onClick={
                                        this.handlePFChallanhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_28"
                                      className="choose_btn"
                                      id="choose_PFChallan"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_28"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handlePFChallanChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_PFChallan"
                                      onClick={(e) => this.removePFChallan(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_PFChallan"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_PFChallan"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.PFChallanDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_PFChallan"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PFAdditional */}

                          <div id="PFadditionalDocumentDiv">
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadadditionalsidetext visibilityhidden">
                                  1&#41;
                                </p>
                              </div>
                              <div className="uploadadditionaldatacol">
                                <p className="uploadadditionalnormaltext">
                                  Additional documents:-
                                </p>
                              </div>
                            </div>
                            {this.PFAdditionalFilesArea()}

                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadadditionalsidetext visibilityhidden">
                                  1&#41;
                                </p>
                              </div>
                              <div className="uploadadditionaldatacol">
                                <button
                                  className="adddocumentbtn"
                                  id="PFadddocumentbtn"
                                  onClick={() =>
                                    this.handleAddPFfileBtn(
                                      PFAdditionalFilesIndex
                                    )
                                  }
                                >
                                  Add document{" "}
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Path 19524.svg"
                                    }
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <center>
                        <button
                          className="proceednowbtn proceednowbtn3"
                          onClick={this.handlePFProceedBtn}
                        >
                          Proceed now{" "}
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/tick.svg"}
                          />
                        </button>
                      </center>
                    </divdiv3>

                    {/* Company & LLP */}
                    <divdiv4 id="ItrfCompany_LLP">
                      <div className="uploadscrolldiv">
                        <div className="uploadscrollcardinside">
                          {this.credentials()}
                          {/* CLAadhar1 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Aadhaar card (Director 1)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    Front and back both side
                                  </p>
                                </div>

                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/AadhaarCard.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="CLAadhar1seecommentsbtn"
                                      onClick={
                                        this.handleCLAadhar1seecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="CLAadhar1hidecommentsbtn"
                                      onClick={
                                        this.handleCLAadhar1hidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_29"
                                      className="choose_btn"
                                      id="choose_CLAadhar1"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_29"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleCLAadhar1Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_CLAadhar1"
                                      onClick={(e) => this.removeCLAadhar1(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_CLAadhar1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_CLAadhar1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.CLAadhar1DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_CLAadhar1"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* CLAadhar2 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Aadhaar card (Director 2)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    Front and back both side
                                  </p>
                                </div>

                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/AadhaarCard.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="CLAadhar2seecommentsbtn"
                                      onClick={
                                        this.handleCLAadhar2seecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="CLAadhar2hidecommentsbtn"
                                      onClick={
                                        this.handleCLAadhar2hidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_30"
                                      className="choose_btn"
                                      id="choose_CLAadhar2"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_30"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleCLAadhar2Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_CLAadhar2"
                                      onClick={(e) => this.removeCLAadhar2(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_CLAadhar2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_CLAadhar2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.CLAadhar2DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_CLAadhar2"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* CLPan1 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    PAN card (Director 1)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PANCard.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="CLPan1seecommentsbtn"
                                      onClick={this.handleCLPan1seecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="CLPan1hidecommentsbtn"
                                      onClick={this.handleCLPan1hidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_31"
                                      className="choose_btn"
                                      id="choose_CLPan1"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_31"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleCLPan1Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_CLPan1"
                                      onClick={(e) => this.removeCLPan1(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_CLPan1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_CLPan1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.CLPan1DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_CLPan1"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* CLPan2 */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    PAN card (Director 2)
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PANCard.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="CLPan2seecommentsbtn"
                                      onClick={this.handleCLPan2seecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="CLPan2hidecommentsbtn"
                                      onClick={this.handleCLPan2hidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_32"
                                      className="choose_btn"
                                      id="choose_CLPan2"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_32"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleCLPan2Change(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_CLPan2"
                                      onClick={(e) => this.removeCLPan2(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_CLPan2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_CLPan2"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.CLPan2DocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_CLPan2"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* CLIC */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Incorporation Certificate
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>

                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/CertificateOfIncorporation.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="CLICseecommentsbtn"
                                      onClick={this.handleCLICseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="CLIChidecommentsbtn"
                                      onClick={this.handleCLIChidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_33"
                                      className="choose_btn"
                                      id="choose_CLIC"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_33"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) => this.handleCLICChange(e)}
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_CLIC"
                                      onClick={(e) => this.removeCLIC(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_CLIC"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_CLIC"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.CLICDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_CLIC"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* CLMOA */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    MOA<sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>

                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/MOA.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="CLMOAseecommentsbtn"
                                      onClick={this.handleCLMOAseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="CLMOAhidecommentsbtn"
                                      onClick={this.handleCLMOAhidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_34"
                                      className="choose_btn"
                                      id="choose_CLMOA"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_34"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleCLMOAChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_CLMOA"
                                      onClick={(e) => this.removeCLMOA(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_CLMOA"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_CLMOA"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.CLMOADocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_CLMOA"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* CLAOA */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    AOA
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">Soft copy</p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/AOA.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="CLAOAseecommentsbtn"
                                      onClick={this.handleCLAOAseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="CLAOAhidecommentsbtn"
                                      onClick={this.handleCLAOAhidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_35"
                                      className="choose_btn"
                                      id="choose_CLAOA"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_35"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleCLAOAChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_CLAOA"
                                      onClick={(e) => this.removeCLAOA(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_CLAOA"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_CLAOA"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.CLAOADocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_CLAOA"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* CLBP */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Bank Proof
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <p className="uploadinstr">
                                    Bank passbook front page or a cancelled
                                    cheque
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/BankProof.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="CLBPseecommentsbtn"
                                      onClick={this.handleCLBPseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="CLBPhidecommentsbtn"
                                      onClick={this.handleCLBPhidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_36"
                                      className="choose_btn"
                                      id="choose_CLBP"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_36"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) => this.handleCLBPChange(e)}
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_CLBP"
                                      onClick={(e) => this.removeCLBP(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_CLBP"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_CLBP"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.CLBPDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_CLBP"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* CLPBS_A */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Previous year Balance sheet / Audit report
                                  </p>
                                  <p className="uploadinstr">
                                    Please attach if available
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PreviousReturnsORAuditReport.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="CLPBS_Aseecommentsbtn"
                                      onClick={this.handleCLPBS_Aseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="CLPBS_Ahidecommentsbtn"
                                      onClick={
                                        this.handleCLPBS_Ahidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_37"
                                      className="choose_btn"
                                      id="choose_CLPBS_A"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_37"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleCLPBS_AChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_CLPBS_A"
                                      onClick={(e) => this.removeCLPBS_A(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_CLPBS_A"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_CLPBS_A"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.CLPBS_ADocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_CLPBS_A"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* CLPR */}
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadnormaltext documentno"></p>
                            </div>
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <p className="uploadnormaltext">
                                    Previous year return
                                  </p>
                                  <p className="uploadinstr">
                                    Please attach if available
                                  </p>
                                </div>
                                <div className="col-md-7">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/PreviousReturns.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="CLPRseecommentsbtn"
                                      onClick={this.handleCLPRseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="CLPRhidecommentsbtn"
                                      onClick={this.handleCLPRhidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_38"
                                      className="choose_btn"
                                      id="choose_CLPR"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_38"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) => this.handleCLPRChange(e)}
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_CLPR"
                                      onClick={(e) => this.removeCLPR(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_CLPR"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_CLPR"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.CLPRDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_CLPR"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* CLincD */}

                          <div id="CLincDadditionalDocumentDiv">
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadadditionalsidetext visibilityhidden">
                                  1&#41;
                                </p>
                              </div>
                              <div className="uploadadditionaldatacol">
                                <p className="uploadadditionalnormaltext">
                                  Any Income / Deduction documents:-
                                </p>
                              </div>
                            </div>
                            {this.CLincDAdditionalFilesArea()}

                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadadditionalsidetext visibilityhidden">
                                  1&#41;
                                </p>
                              </div>
                              <div className="uploadadditionaldatacol">
                                <button
                                  className="adddocumentbtn"
                                  id="CLincDadddocumentbtn"
                                  onClick={() =>
                                    this.handleCLincDAddfileBtn(
                                      CLincDAdditionalFilesIndex
                                    )
                                  }
                                >
                                  Add document{" "}
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Path 19524.svg"
                                    }
                                  />
                                </button>
                              </div>
                            </div>
                          </div>

                          {/* CLData */}
                          <div className="displayflex">
                            <div className="uploaddatacol">
                              <p className="uploadadditionalnormaltext">
                                Company & LLP (Recurring)
                              </p>
                              <div className="row">
                                <div className="col-md-5">
                                  <div className="uploadnocol d-flex">
                                    <div>
                                      <p className="uploadnormaltext itrfdocumentno mr-1"></p>
                                    </div>
                                    <div>
                                      <p className="uploadnormaltext">
                                        {" "}
                                        Current year Tally data / Audit report /
                                        Balance sheet{" "}
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                      <p className="uploadinstr">
                                        Please attach any one
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-7 ml-md-0 ml-4">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/Data.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="CLDataseecommentsbtn"
                                      onClick={this.handleCLDataseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="CLDatahidecommentsbtn"
                                      onClick={this.handleCLDatahidecommentsbtn}
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_39"
                                      className="choose_btn"
                                      id="choose_CLData"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_39"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleCLDataChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_CLData"
                                      onClick={(e) => this.removeCLData(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_CLData"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_CLData"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.CLDataDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_CLData"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* CLBankS */}
                          <div className="displayflex">
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <div className="uploadnocol d-flex">
                                    <div>
                                      <p className="uploadnormaltext itrfdocumentno mr-1"></p>
                                    </div>
                                    <div>
                                      <p className="uploadnormaltext">
                                        Bank Statement{" "}
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                      <p className="uploadinstr">
                                        From 1st April to 31st March of related
                                        F.Y
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-7 ml-md-0 ml-4">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/BankStatement.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="CLBankSseecommentsbtn"
                                      onClick={this.handleCLBankSseecommentsbtn}
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="CLBankShidecommentsbtn"
                                      onClick={
                                        this.handleCLBankShidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_104"
                                      className="choose_btn"
                                      id="choose_CLBankS"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_104"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleCLBankSChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_CLBankS"
                                      onClick={(e) => this.removeCLBankS(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_CLBankS"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_CLBankS"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.CLBankSDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_CLBankS"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* CLGSTReturn */}
                          <div className="displayflex">
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <div className="uploadnocol d-flex">
                                    <div>
                                      <p className="uploadnormaltext itrfdocumentno mr-1"></p>
                                    </div>
                                    <div>
                                      <p className="uploadnormaltext">
                                        GST Return
                                      </p>
                                      <p className="uploadinstr">
                                        Please attach if available
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-7 ml-md-0 ml-4">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/GSTReturn.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="CLGSTReturnseecommentsbtn"
                                      onClick={
                                        this.handleCLGSTReturnseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="CLGSTReturnhidecommentsbtn"
                                      onClick={
                                        this.handleCLGSTReturnhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_40"
                                      className="choose_btn"
                                      id="choose_CLGSTReturn"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_40"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleCLGSTReturnChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_CLGSTReturn"
                                      onClick={(e) => this.removeCLGSTReturn(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_CLGSTReturn"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_CLGSTReturn"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.CLGSTReturnDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_CLGSTReturn"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* CLTDSReturn */}
                          <div className="displayflex">
                            <div className="uploaddatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <div className="uploadnocol d-flex">
                                    <div>
                                      <p className="uploadnormaltext itrfdocumentno mr-1"></p>
                                    </div>
                                    <div>
                                      <p className="uploadnormaltext">
                                        {" "}
                                        TDS Return
                                      </p>
                                      <p className="uploadinstr">
                                        Please attach if available
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-7 ml-md-0 ml-4">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/TDSReturn.pdf
"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="CLTDSReturnseecommentsbtn"
                                      onClick={
                                        this.handleCLTDSReturnseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="CLTDSReturnhidecommentsbtn"
                                      onClick={
                                        this.handleCLTDSReturnhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_41"
                                      className="choose_btn"
                                      id="choose_CLTDSReturn"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_41"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleCLTDSReturnChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_CLTDSReturn"
                                      onClick={(e) => this.removeCLTDSReturn(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_CLTDSReturn"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_CLTDSReturn"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.CLTDSReturnDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_CLTDSReturn"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* CLChallan */}
                          <div className="displayflex lastdisplayflex">
                            <div className="uploadlastdatacol">
                              <div className="row">
                                <div className="col-md-5">
                                  <div className="uploadnocol d-flex">
                                    <div>
                                      <p className="uploadnormaltext itrfdocumentno mr-1"></p>
                                    </div>
                                    <div>
                                      <p className="uploadnormaltext">
                                        Tax deposit challan
                                      </p>
                                      <p className="uploadinstr">
                                        Please attach if available
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-7 ml-md-0 ml-4">
                                  <div className="fl_right">
                                    <a
                                      href="https://docs.legal251.com/example/documents/Challan.pdf"
                                      className="viewexamplelink"
                                      target="_blank"
                                    >
                                      View example document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                    <button
                                      className="seecommentsbtn commentsbtn"
                                      id="CLChallanseecommentsbtn"
                                      onClick={
                                        this.handleCLChallanseecommentsbtn
                                      }
                                    >
                                      See comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="hidecommentsbtn"
                                      id="CLChallanhidecommentsbtn"
                                      onClick={
                                        this.handleCLChallanhidecommentsbtn
                                      }
                                    >
                                      Hide comments{" "}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path 19498.svg"
                                        }
                                      />
                                    </button>
                                    <label
                                      htmlFor="ul_file_42"
                                      className="choose_btn"
                                      id="choose_CLChallan"
                                    >
                                      Choose file{" "}
                                      <img
                                        src={"/img/Path_15188.svg"}
                                        alt="file"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      multiple
                                      name="file[]"
                                      className="upload-photo"
                                      id="ul_file_42"
                                      onClick={(event) => {
                                        event.target.value = null;
                                      }}
                                      onChange={(e) =>
                                        this.handleCLChallanChange(e)
                                      }
                                    />
                                    <button
                                      className="X-btn"
                                      id="X-btn_CLChallan"
                                      onClick={(e) => this.removeCLChallan(e)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_1025.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="review-btn"
                                      id="review-btn_CLChallan"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_19663.svg"
                                        }
                                      />
                                    </button>
                                    <button
                                      className="done-btn"
                                      id="done-btn_CLChallan"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/tick.svg"
                                        }
                                      />
                                    </button>
                                    <br />
                                    <a
                                      href={this.state.CLChallanDocLink}
                                      className="viewDocLink"
                                      id="viewDocLink_CLChallan"
                                      target="_blank"
                                    >
                                      View uploaded document{" "}
                                      <img
                                        className="viewDocLinkimg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/img/Path_20245.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* CLAdditional */}

                          <div id="CLadditionalDocumentDiv">
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadadditionalsidetext visibilityhidden">
                                  1&#41;
                                </p>
                              </div>
                              <div className="uploadadditionaldatacol">
                                <p className="uploadadditionalnormaltext">
                                  Additional documents:-
                                </p>
                              </div>
                            </div>
                            {this.CLAdditionalFilesArea()}

                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadadditionalsidetext visibilityhidden">
                                  1&#41;
                                </p>
                              </div>
                              <div className="uploadadditionaldatacol">
                                <button
                                  className="adddocumentbtn"
                                  id="CLadddocumentbtn"
                                  onClick={() =>
                                    this.handleAddCLfileBtn(
                                      CLAdditionalFilesIndex
                                    )
                                  }
                                >
                                  Add document{" "}
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Path 19524.svg"
                                    }
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <center>
                        <button
                          className="proceednowbtn proceednowbtn4"
                          onClick={this.handleCLProceedBtn}
                        >
                          Proceed now{" "}
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/tick.svg"}
                          />
                        </button>
                      </center>
                    </divdiv4>
                  </div>
                  </div>
                          </div12>

                  <div className="col-lg-3 colbtwborder">
                    <div className="commentimg-section">
                      <SupportDetails
                        name={this.state.Name}
                        role={"Relationship Manager"}
                        contact={this.state.Contact}
                      />

                      <div className="supportDetails">
                        <img
                          src={process.env.PUBLIC_URL + "/img/24-7_Support.png"}
                          alt="image"
                        />
                        <div>
                          <h5>{supportName}</h5>
                          <p>Agent Support Executive</p>
                          <span>
                            <b>Contact: </b> +91 {supportContact}
                          </span>
                          <p className="mb-1">
                            <b>Working Hours: </b> 11:00 AM - 06:30 PM
                          </p>
                        </div>
                      </div>
                      <div className="mm">
                        <center>
                          <a
                            className="video-btn"
                            data-toggle="modal"
                            data-src="https://www.youtube.com/embed/fiC-nHoFJEY"
                            data-target="#youtubeModal"
                          >
                            <img
                              src={process.env.PUBLIC_URL + "/img/play.jpg"}
                              id="play"
                              className="play_img play_img2"
                              alt="image"
                            />
                          </a>
                        </center>
                      </div>
                      <button
                        data-toggle="modal"
                        onClick={this.handletimeline}
                        data-src=""
                        data-target="#timeline"
                        className="dashBoardBtn mt-5 newbmar"
                      >
                        Timeline
                      </button>

                      <p class="uploadinstr mt-2 uploddoc">You can also upload documents using Whatsapp on <a
                                  href="https://web.whatsapp.com/send?phone=917772877729&amp;text=Hello%20Team%20Legal251!"
                                  target="_blank"
                                >
                                  {" "}
                                  <img
                                    src={process.env.PUBLIC_URL + "/img/call.svg"}
                                    alt="call"
                                    className="callimg"
                                  />
                                </a> +91 77728-77729</p>
                    </div>
                    <div className="TDRightSide">
                      <div className="convheader">
                        <div>
                          <img
                            src={process.env.PUBLIC_URL + "/img/person.png"}
                            className="converpersonimg"
                          />
                        </div>
                        <div className="convheadercontdiv">
                          <p className="convservicename">{this.state.Name}</p>
                          <p className="convpersonname">Relationship Manager</p>
                          <p className="convuniquename">
                            Contact:{" "}
                            <span style={{ color: "#2d2d2d" }}>
                              {this.state.Contact}
                            </span>
                          </p>
                          <p className="convuniquename">
                            Service ID :{" "}
                            <span className="convuniquenamespan">
                              {Decserviceid}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="convmaindiv">
                        {this.renderCommenthistory()}
                      </div>
                    </div>
                  </div>

                  </div>
                      </div>
              <div id="tab2" className="tab-content">
              <div className="row">
                          <div className="col-lg-9">
                    <div className="doucumainscrolldiv2">
                      <div className="doucumainscrolldivinside2">
                        {this.showDeliveredDocuments()}
                      </div>
                      </div>
                      </div>
                      <div className="col-lg-3 colbtwborder">
                    <div className="commentimg-section">
                      <SupportDetails
                        name={this.state.Name}
                        role={"Relationship Manager"}
                        contact={this.state.Contact}
                      />

                      <div className="supportDetails">
                        <img
                          src={process.env.PUBLIC_URL + "/img/24-7_Support.png"}
                          alt="image"
                        />
                        <div>
                          <h5>{supportName}</h5>
                          <p>Agent Support Executive</p>
                          <span>
                            <b>Contact: </b> +91 {supportContact}
                          </span>
                          <p className="mb-1">
                            <b>Working Hours: </b> 11:00 AM - 06:30 PM
                          </p>
                        </div>
                      </div>
                      <div className="mm">
                        <center>
                          <a
                            className="video-btn"
                            data-toggle="modal"
                            data-src="https://www.youtube.com/embed/fiC-nHoFJEY"
                            data-target="#youtubeModal"
                          >
                            <img
                              src={process.env.PUBLIC_URL + "/img/play.jpg"}
                              id="play"
                              className="play_img play_img2"
                              alt="image"
                            />
                          </a>
                        </center>
                      </div>
                      <button
                        data-toggle="modal"
                        onClick={this.handletimeline}
                        data-src=""
                        data-target="#timeline"
                        className="dashBoardBtn mt-5 newbmar"
                      >
                        Timeline
                      </button>
                      <p class="uploadinstr mt-2 uploddoc">You can also upload documents using Whatsapp on <a href="https://web.whatsapp.com/send?phone=917772877729&amp;text=Hello%20Team%20Legal251!" target="_blank"> <img src="/img/call.svg" alt="call" class="callimg" /></a> +91 77728-77729</p>
                    </div>
                    <div className="TDRightSide">
                      <div className="convheader">
                        <div>
                          <img
                            src={process.env.PUBLIC_URL + "/img/person.png"}
                            className="converpersonimg"
                          />
                        </div>
                        <div className="convheadercontdiv">
                          <p className="convservicename">{this.state.Name}</p>
                          <p className="convpersonname">Relationship Manager</p>
                          <p className="convuniquename">
                            Contact:{" "}
                            <span style={{ color: "#2d2d2d" }}>
                              {this.state.Contact}
                            </span>
                          </p>
                          <p className="convuniquename">
                            Service ID :{" "}
                            <span className="convuniquenamespan">
                              {Decserviceid}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="convmaindiv">
                        {this.renderCommenthistory()}
                      </div>
                    </div>
                  </div>
                    </div>
                  </div>

                </div>
              </div>
              :<center>
                <img alt="notaccess" className="notuploaddoc" src={process.env.PUBLIC_URL + "/img/Warning-rafiki.svg"} />

                <h4 class="my-linkmodal-text youcant">You can't upload document because you're Referal Partner. Please contact support executive for any help and more information.</h4>
                </center>
              }
            </div>
          </div>
          <SupportDetailsMobile
            name={this.state.Name}
            role={"Relationship Manager"}
            contact={this.state.Contact}
          />

          <Footer />
          {/* Modals */}
          {/* S */}
          <div className="modal fade" id="SAadharModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SAadhar"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SAadhar"
                        onClick={this.uploadSAadhar}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SAadhar"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="SPanModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SPan"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SPan"
                        onClick={this.uploadSPan}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SPan"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="SBpModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SBp"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SBp"
                        onClick={this.uploadSBp}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SBp"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="SBankSModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SBankS"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SBankS"
                        onClick={this.uploadSBankS}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SBankS"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="SF16Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SF16"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SF16"
                        onClick={this.uploadSF16}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SF16"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="SPBSModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SPBS"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SPBS"
                        onClick={this.uploadSPBS}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SPBS"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="SPRModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SPR"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SPR"
                        onClick={this.uploadSPR}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SPR"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade SincDAddModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    maxLength="250"
                    placeholder="Type here..."
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_6"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20 upload_btn20_6"
                        onClick={() =>
                          this.uploadSincDFile(
                            SincDadditionalReuploadIndex,
                            SincDadditionalReuploadStatus
                          )
                        }
                      >
                        Upload
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />
                      </button>
                      <button className="upload_btnloader upload_btnloader_6">
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade SAddModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    maxLength="250"
                    placeholder="Type here..."
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn cancelbtn_7"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20 upload_btn20_7"
                        onClick={() =>
                          this.uploadSFile(
                            SadditionalReuploadIndex,
                            SadditionalReuploadStatus
                          )
                        }
                      >
                        Upload
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />
                      </button>
                      <button className="upload_btnloader upload_btnloader_7">
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* proprietor */}
          <div className="modal fade" id="PAadharModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PAadhar"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PAadhar"
                        onClick={this.uploadPAadhar}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PAadhar"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PPanModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PPan"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PPan"
                        onClick={this.uploadPPan}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PPan"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PBPModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PBP"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PBP"
                        onClick={this.uploadPBP}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PBP"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PBSModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PBS"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PBS"
                        onClick={this.uploadPBS}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PBS"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PPRModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PPR"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PPR"
                        onClick={this.uploadPPR}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PPR"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade PincDAddModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    maxLength="250"
                    placeholder="Type here..."
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_6"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20 upload_btn20_6"
                        onClick={() =>
                          this.uploadPincDFile(
                            PincDadditionalReuploadIndex,
                            PincDadditionalReuploadStatus
                          )
                        }
                      >
                        Upload
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />
                      </button>
                      <button className="upload_btnloader upload_btnloader_6">
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PDataModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PData"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PData"
                        onClick={this.uploadPData}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PData"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PBankSModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PBankS"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PBankS"
                        onClick={this.uploadPBankS}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PBankS"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PGSTReturnModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PGSTReturn"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PGSTReturn"
                        onClick={this.uploadPGSTReturn}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PGSTReturn"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PTDSReturnModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PTDSReturn"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PTDSReturn"
                        onClick={this.uploadPTDSReturn}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PTDSReturn"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PChallanModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PChallan"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PChallan"
                        onClick={this.uploadPChallan}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PChallan"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade PAddModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    maxLength="250"
                    placeholder="Type here..."
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn cancelbtn_7"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20 upload_btn20_7"
                        onClick={() =>
                          this.uploadPFile(
                            PadditionalReuploadIndex,
                            PadditionalReuploadStatus
                          )
                        }
                      >
                        Upload
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />
                      </button>
                      <button className="upload_btnloader upload_btnloader_7">
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PFAadhar1Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PFAadhar1"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PFAadhar1"
                        onClick={this.uploadPFAadhar1}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PFAadhar1"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PFAadhar2Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PFAadhar2"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PFAadhar2"
                        onClick={this.uploadPFAadhar2}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PFAadhar2"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PFPan1Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PFPan1"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PFPan1"
                        onClick={this.uploadPFPan1}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PFPan1"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PFPan2Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PFPan2"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PFPan2"
                        onClick={this.uploadPFPan2}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PFPan2"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PFDeedModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PFDeed"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PFDeed"
                        onClick={this.uploadPFDeed}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PFDeed"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PFBPModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PFBP"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PFBP"
                        onClick={this.uploadPFBP}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PFBP"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PFRCModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PFRC"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PFRC"
                        onClick={this.uploadPFRC}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PFRC"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PFPBS_AModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PFPBS_A"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PFPBS_A"
                        onClick={this.uploadPFPBS_A}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PFPBS_A"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PFPRModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PFPR"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PFPR"
                        onClick={this.uploadPFPR}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PFPR"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade PFincDAddModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    maxLength="250"
                    placeholder="Type here..."
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_6"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20 upload_btn20_6"
                        onClick={() =>
                          this.uploadPFincDFile(
                            PFincDadditionalReuploadIndex,
                            PFincDadditionalReuploadStatus
                          )
                        }
                      >
                        Upload
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />
                      </button>
                      <button className="upload_btnloader upload_btnloader_6">
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PFDataModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PFData"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PFData"
                        onClick={this.uploadPFData}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PFData"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PFBankSModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PFBankS"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PFBankS"
                        onClick={this.uploadPFBankS}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PFBankS"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PFGSTReturnModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PFGSTReturn"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PFGSTReturn"
                        onClick={this.uploadPFGSTReturn}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PFGSTReturn"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PFTDSReturnModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PFTDSReturn"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PFTDSReturn"
                        onClick={this.uploadPFTDSReturn}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PFTDSReturn"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PFChallanModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PFChallan"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PFChallan"
                        onClick={this.uploadPFChallan}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PFChallan"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade PFAddModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    maxLength="250"
                    placeholder="Type here..."
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn cancelbtn_7"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20 upload_btn20_7"
                        onClick={() =>
                          this.uploadPFFile(
                            PFadditionalReuploadIndex,
                            PFadditionalReuploadStatus
                          )
                        }
                      >
                        Upload
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />
                      </button>
                      <button className="upload_btnloader upload_btnloader_7">
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="CLAadhar1Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_CLAadhar1"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_CLAadhar1"
                        onClick={this.uploadCLAadhar1}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_CLAadhar1"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="CLAadhar2Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_CLAadhar2"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_CLAadhar2"
                        onClick={this.uploadCLAadhar2}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_CLAadhar2"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="CLPan1Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_CLPan1"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_CLPan1"
                        onClick={this.uploadCLPan1}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_CLPan1"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="CLPan2Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_CLPan2"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_CLPan2"
                        onClick={this.uploadCLPan2}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_CLPan2"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="CLICModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_CLIC"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_CLIC"
                        onClick={this.uploadCLIC}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_CLIC"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="CLMOAModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_CLMOA"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_CLMOA"
                        onClick={this.uploadCLMOA}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_CLMOA"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="CLAOAModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_CLAOA"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_CLAOA"
                        onClick={this.uploadCLAOA}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_CLAOA"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="CLBPModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_CLBP"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_CLBP"
                        onClick={this.uploadCLBP}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_CLBP"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="CLPBS_AModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_CLPBS_A"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_CLPBS_A"
                        onClick={this.uploadCLPBS_A}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_CLPBS_A"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="CLPRModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_CLPR"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_CLPR"
                        onClick={this.uploadCLPR}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_CLPR"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade CLincDAddModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    maxLength="250"
                    placeholder="Type here..."
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_6"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20 upload_btn20_6"
                        onClick={() =>
                          this.uploadCLincDFile(
                            CLincDadditionalReuploadIndex,
                            CLincDadditionalReuploadStatus
                          )
                        }
                      >
                        Upload
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />
                      </button>
                      <button className="upload_btnloader upload_btnloader_6">
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="CLDataModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_CLData"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_CLData"
                        onClick={this.uploadCLData}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_CLData"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="CLBankSModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_CLBankS"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_CLBankS"
                        onClick={this.uploadCLBankS}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_CLBankS"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="CLGSTReturnModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_CLGSTReturn"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_CLGSTReturn"
                        onClick={this.uploadCLGSTReturn}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_CLGSTReturn"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="CLTDSReturnModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_CLTDSReturn"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_CLTDSReturn"
                        onClick={this.uploadCLTDSReturn}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_CLTDSReturn"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="CLChallanModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_CLChallan"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_CLChallan"
                        onClick={this.uploadCLChallan}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_CLChallan"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade CLAddModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    maxLength="250"
                    placeholder="Type here..."
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn cancelbtn_7"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20 upload_btn20_7"
                        onClick={() =>
                          this.uploadCLFile(
                            CLadditionalReuploadIndex,
                            CLadditionalReuploadStatus
                          )
                        }
                      >
                        Upload
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />
                      </button>
                      <button className="upload_btnloader upload_btnloader_7">
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="youtubeModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered yt_modal "
              role="document"
            >
              <div className="modal-content">
                <div className="modal-body modal_b">
                  <button
                    type="button"
                    className="close close1"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      className="embed-responsive-item"
                      id="video"
                      allowscriptaccess="always"
                      src="https://www.youtube.com/embed/fiC-nHoFJEY?&amp;modestbranding=1&amp;showinfo=0"
                      allowFullScreen="allowfullscreen"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="timeline"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered yt_modal "
              role="document"
            >
              <div className="modal-content videoModal">
                <div className="modal-body modal_b mt-4 ">
                  <button
                    type="button"
                    className="close close1 close1tl closeMain mr-2 dbtime"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>

                  {this.state.Timelinedata ? (
                    this.state.Timeline.length > 0 ? (
                      <div class="timeline scrollbtimel">
                        <div class="container">
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="timeline-container">
                                <div class="timeline-end">
                                  <button
                                    id="load-more"
                                    onClick={this.handlemoretimeline}
                                  >
                                    More..
                                  </button>
                                </div>
                                <div class="timeline-continue">
                                  {this.state.filtertimel.map((item, index) => {
                                    if (index % 2 == 0) {
                                      return (
                                        <div class="row timeline-left">
                                          <div class="col-md-6 d-md-none d-block">
                                            <p class="timeline-date"></p>
                                          </div>
                                          <div class="col-md-6">
                                            <div class="timeline-box">
                                              <div class="timeline-icon d-md-none d-block">
                                                <i class="fa fa-business-time"></i>
                                              </div>

                                              <div class="timeline-text">
                                                <p class="mainSpan">
                                                  {item.time}
                                                </p>
                                                <h3>{item.timeLine}</h3>
                                                <p class="mode_p">
                                                  Date :{" "}
                                                  <span class="mode_span">
                                                    {" "}
                                                    {item.date}
                                                  </span>
                                                </p>
                                                <h5 className="tlremark">
                                                  {item.remark}
                                                </h5>
                                                {/* <p class="mode_p">
                                                      <span class="mode_span">
                                                          {{$TimeLine->TimeLine}}
                                                      </span>
                                                  </p>  */}
                                              </div>

                                              <div class="timeline-icon d-md-block d-none">
                                                <i class="fa fa-business-time"></i>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-md-6 d-md-block d-none">
                                            <p class="timeline-date"></p>
                                          </div>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div class="row timeline-right">
                                          <div class="col-md-6">
                                            <p class="timeline-date"></p>
                                          </div>
                                          <div class="col-md-6">
                                            <div class="timeline-box">
                                              <div class="timeline-icon">
                                                <i class="fa fa-gift"></i>
                                              </div>

                                              <div class="timeline-text">
                                                <p class="mainSpan">
                                                  {item.time}
                                                </p>
                                                {/* <p class="">khkh</p> */}

                                                <h3>{item.timeLine}</h3>
                                                <p class="mode_p">
                                                  Date :{" "}
                                                  <span class="mode_span">
                                                    {item.date}{" "}
                                                  </span>
                                                </p>
                                                <h5 className="tlremark">
                                                  {item.remark}
                                                </h5>
                                                {/* <p class="mode_p">
                                                                              <span class="mode_span">
                                                                                  
                                                                              </span>
                                                                          </p> */}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <center className="">
                        <img
                          alt="loading..."
                          src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
                          className="emptydeliveredDocuments"
                        />
                      </center>
                    )
                  ) : null}
                  <center>
                    <img
                      className="timelineloder"
                      src={process.env.PUBLIC_URL + "/img/loader.gif"}
                      alt="loading"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default Activeitrf;

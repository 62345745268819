import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import Header from "./Header";
import Footer from "./Footer";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import ScriptTag from "react-script-tag";

let Decjwttoken;
let Decagentid;
let checkoutservicesortname;
var sha256 = require("js-sha256");
var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);
var paymentstatus = "";
class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mtime: 60,
      etime: 60,
      contactOtp: "",
      emailOtp: "",
      servicefullname: "",
      serviceselectvalue: "",
      servicerequestid: "",
      serviceprice: "",
      servicegovtfees: "",
      coupun: "",
      servicetotal: "",
      serviceshortname: "",
      couponapplied: "",
      coupunpercentage: "",
      servicepriceaftercoupun: "",
      couponstatus: "false",
      checkserviceplan: "",
      gstno: "",
      servicegst: "",
      servicegstaftercoupun: "",
      flat: "P",
      Sectimeout: 60,
      mintimeout: 4,
      referral: "",
      communicationMode:"",
      createlead:"false"
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    $(document.body).css("background-color", "#2d2d2d");

    let mainjwttoken = localStorage.getItem("toeljgtkewlna");
    let mainfullname = sessionStorage.getItem("ielndmlgeaefelvsdrlucaa");
    let mainservicename = sessionStorage.getItem("srleleddgaeaemvcnia");
    let mainservicerequestid = sessionStorage.getItem("uegiqlsartdele");
    let mainprice = sessionStorage.getItem("aprcillgee");
    let maingovtfees = sessionStorage.getItem("evlgsoaeetgfl");
    let mainserviceshortform = sessionStorage.getItem("lslgerotnscmvheearaei");
    let mainserviceplan = sessionStorage.getItem("egalllpna");
    let mainagentid = localStorage.getItem("qazxswedcvfrtgb");
    let mainservicegst = sessionStorage.getItem("ssdfsdfwekrfewpfk");
    let refpartlead = sessionStorage.getItem("srewrercgdgfdgf");
    Decagentid = encryptor.decrypt(mainagentid);
    Decjwttoken = encryptor.decrypt(mainjwttoken);
    let fullname = encryptor.decrypt(mainfullname);
    let servicename = encryptor.decrypt(mainservicename);
    let requestid = encryptor.decrypt(mainservicerequestid);
    let price = encryptor.decrypt(mainprice);
    let govtfees = encryptor.decrypt(maingovtfees);
    let serviceshortform = encryptor.decrypt(mainserviceshortform);
    let serviceplan = encryptor.decrypt(mainserviceplan);
    let servicegstlocal = encryptor.decrypt(mainservicegst);
    let partlead = encryptor.decrypt(refpartlead);
    checkoutservicesortname = serviceshortform
    this.setState({
      createlead: partlead,
    })
    this.countdown();
    this.countdownemail();
    // this.pageTimeout()
    if (govtfees == 0) {
      $(".govtfeesdiv").hide();
    } else {
      $(".govtfeesdiv").show();
    }

    if (serviceplan == "none") {
      serviceplan = "-";
    } else if (serviceplan == "S") {
      serviceplan = "Starter";
    } else if (serviceplan == "B") {
      serviceplan = "Basic";
    } else if (serviceplan == "P") {
      serviceplan = "Pro";
    } else if (serviceplan == "PR") {
      serviceplan = "Premium";
    } else if (serviceplan == "PP") {
      serviceplan = "Pro+";
    }
    else if (serviceplan == "D") {
      serviceplan = "Detailed";
    }
    else if (serviceplan == "G") {
      serviceplan = "General";
    }



    if (servicename == "Trade Licence") {
      $(".govfeecheckout").show();
    }
    else {
      $(".govfeecheckout").hide();
    }
    if (this.props.location) {
      
       if (this.props.location.lead != null || this.props.location.lead != undefined) {
        this.showNotification(this.props.location.lead.message)
      }
    }


    this.setState(
      {
        servicefullname: fullname,
        serviceselectvalue: servicename,
        servicerequestid: requestid,
        serviceprice: price,
        servicegovtfees: govtfees,
        servicetotal: price + govtfees + servicegstlocal,
        serviceshortname: serviceshortform,
        checkserviceplan: serviceplan,
        servicegst: servicegstlocal,
      },
      () => { }
    );
  }
  pageTimeout = () => {
    let prp = this.props
    function clear(e) {
      clearInterval(e);
      localStorage.removeItem("qpoahdtyscd")
      localStorage.removeItem("wiosgfftsjkd")
      prp.history.push({
        pathname: `/addservice`,
      });
    }

    if (localStorage.getItem("qpoahdtyscd") == null && localStorage.getItem("wiosgfftsjkd") == null) {
      localStorage.setItem("qpoahdtyscd", 1);
      localStorage.setItem("wiosgfftsjkd", 10);
    }
    let locMintimeout = localStorage.getItem("qpoahdtyscd");
    let locSectimeout = localStorage.getItem("wiosgfftsjkd");
    this.setState({
      Sectimeout: locSectimeout, mintimeout: locMintimeout
    }, () => {
      let interval = setInterval(() => {
        if (this.state.Sectimeout == 1 && this.state.mintimeout == 0) {
          clear(interval);
        } else if (this.state.Sectimeout == 1) {
          this.setState({ Sectimeout: 60, mintimeout: this.state.mintimeout - 1 }, () => {
            localStorage.setItem("wiosgfftsjkd", 60)
            localStorage.setItem("qpoahdtyscd", this.state.mintimeout)

          });
        }
        this.setState({ Sectimeout: this.state.Sectimeout - 1 }, () => {
          localStorage.setItem("wiosgfftsjkd", this.state.Sectimeout)

        });
      }, 1000);
    })






  }
  countdownemail() {
    $("#emailresend-loadercheck").hide();
    function clear() {
      clearInterval(interval);
      $("#emailresend-loadercheck").hide();
    }
    let interval = setInterval(() => {
      if (this.state.etime == 1) {
        clear(interval);
        $("#emailresend-loadercheck").hide();
      } else {
      }
      this.setState({ etime: this.state.etime - 1 });
    }, 1000);
  }

  countdown() {
    $("#mobileresend-loadercheck").hide();
    function clear() {
      clearInterval(interval);
      $("#mobileresend-loadercheck").hide();
    }
    let interval = setInterval(() => {
      if (this.state.mtime == 1) {
        clear(interval);
        $("#mobileresend-loadercheck").hide();
      } else {
      }
      this.setState({ mtime: this.state.mtime - 1 });
    }, 1000);
  }

  handlemobileresend = () => {
    $("#mobileresendcheck").hide();
    $("#mobileresend-loadercheck").show();
    let resendotp;
    let data;

    if(this.state.createlead){
      resendotp =  "resendOtpReferralPartner"
      data = {
        agentId: Decagentid,
        referralId: this.state.servicerequestid,
        serviceName: this.state.serviceshortname,
      } 
    } else{
      resendotp =  "ResendContactOTP"
      data = {
        agentId: Decagentid,
        requestid: this.state.servicerequestid,
        serviceName: this.state.serviceshortname,
      }
    }

    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}p=${resendotp}`,
        data,
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == 200 && res.data.status == "success") {
          this.setState({ mtime: 60 });
          this.countdown();

          $("#mobileotpsentcheck").show();
          $("#invalidmobileotpcheck").hide();
          $("#mobileresendcheck").show();
          $("#mobileresend-loadercheck").hide();
        } else {
          this.showNotification(res.data.message);
          $("#mobileotpsentcheck").hide();
          $("#sendingmobile").show();
          $("#mobileresendcheck").show();
          $("#mobileresend-loadercheck").hide();
        }
      })
      .catch((err) => {
        $("#mobileotpsentcheck").hide();
        $("#mobileresendcheck").show();
        $("#mobileresend-loadercheck").hide();
        this.setState({ sendingmobile: false });
        this.showNotification("Something went wrong");
      });
  };


  handleemailotp = () => {
    $("#invalidemailotpcheck2").hide();
    $("#emailotpsentcheck2").show();
    $("#emailotpinput").prop('disabled', true);
    this.setState({ emailOtp: "NOTREC" });
  };

  handlemobileotp = () => {
    $("#invalidemailotpcheck2").show();
    $("#emailotpsentcheck2").hide();
    $("#emailotpinput").prop('disabled', false);
    this.setState({ emailOtp: "" });
  };


  handleemailresend = () => {
    $("#emailresendcheck").hide();
    $("#emailresend-loadercheck").show();

    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}p=ResendEmailOTP`,
        {
          agentId: Decagentid,
          requestid: this.state.servicerequestid,
          serviceName: this.state.serviceshortname,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == 200 && res.data.status == "success") {
          $("#emailotpsentsheck").show();
          $("#invalidemailotpcheck").hide();
          this.setState({ sendingemail: true });
          this.setState({ etime: 60 });
          $("#emailresendcheck").show();
          $("#emailresend-loadercheck").hide();
          this.countdownemail();
        } else {
          $("#emailresendcheck").show();
          $("#emailresend-loadercheck").hide();
          this.showNotification(res.data.message);
          $("#emailotpsentcheck").hide();
          this.setState({ sendingemail: false });
        }
      })
      .catch((err) => {
        $("#emailresendcheck").show();
        $("#emailresend-loadercheck").hide();
        $("#emailotpsentcheck").hide();
        this.showNotification("Something went wrong");
        this.setState({ sendingemail: false });
      });
  };

  handlecoupunChange = (e) => {
    this.setState({
      coupun: e.target.value,
    });
  };

  delcoupun = () => {
    $(".copunappliedtext").hide();
    $(".delbtn").hide();
    $("#applybtnmain").show();
    $(".success").hide();
    $(".failure").hide();
    $(".copunorginalprice").removeClass("copunline");
    $(".applycoupen").prop('disabled', false);
    this.setState({
      servicegst: this.state.servicegstaftercoupun,
      servicetotal:
        this.state.serviceprice +
        this.state.servicegovtfees +
        this.state.servicegstaftercoupun,
      servicepriceaftercoupun: "",
      servicegstaftercoupun: "",
      coupunpercentage: "",
      couponapplied: "",
      couponstatus: "false",
      coupun: "",
      flat: "P",
    });
  };

  applycoupun = () => {
    if (this.state.coupun.trim() != "") {
      $(".couponapplyloader").show();
      $("#applybtnmain").hide();
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=couponVerifyNewUser",
          {
            agentId: Decagentid,
            serviceName: this.state.serviceshortname,
            coupon: this.state.coupun,
            requestid: this.state.servicerequestid,
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            $(".copunappliedtext").show();
            $(".couponapplyloader").hide();
            $(".delbtn").show();
            $("#applybtnmain").hide();
            $(".success").show();
            $(".failure").hide();
            $(".copunorginalprice").addClass("copunline");
            $(".applycoupen").prop('disabled', true);
            this.setState({
              servicepriceaftercoupun: res.data.price,
              servicegstaftercoupun: this.state.servicegst,
              servicegst: res.data.gstvalue,
              servicetotal:
                parseFloat(res.data.price) +
                this.state.servicegovtfees +
                parseFloat(res.data.gstvalue),
              coupunpercentage: res.data.discount,
              couponapplied: this.state.coupun,
              couponstatus: "true",
              flat: res.data.type,
            });
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else {
            $(".couponapplyloader").hide();
            $(".copunappliedtext").hide();
            $(".delbtn").hide();
            $("#applybtnmain").show();
            $(".success").hide();
            $(".failure").show();
            $(".copunorginalprice").removeClass("copunline");

            this.setState({
              servicepriceaftercoupun: "",
              servicetotal:
                this.state.serviceprice +
                this.state.servicegovtfees +
                this.state.servicegst,
              coupunpercentage: "",
              couponapplied: "",
              couponstatus: "false",
            });
          }
        })
        .catch((err) => { });
    }
  };

  logout = () => {
    localStorage.removeItem("gglltakoinoeenl");
    localStorage.removeItem("toeljgtkewlna");
    localStorage.removeItem("qazxswedcvfrtgb");
    localStorage.removeItem("lshhihfnkff");
    this.props.history.push({
      pathname: `/`,
    });
  };
  backbutton = () => {
    if(this.state.createlead){
      this.props.history.push({
        pathname: `/referral-lead`,
      });
    }else{
      this.props.history.push({
        pathname: `/addservice`,
      });
    }
   

 
    
  };
  Createorder = () => {
    $("#invalidemailotpcheck").hide();
    $("#invalidmobileotpcheck").hide();
    $("#emailotpsentcheck").hide();
    $("#mobileotpsentcheck").hide();
    $("#choosecommunication").hide();
    let gstregex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    this.setState({ paynow: true });
    if ( this.state.communicationMode.trim() == "" ) {
      $("#choosecommunication").show();
    } else if (
      this.state.emailOtp.trim() == "" &&
      this.state.contactOtp.trim() == ""
    ) {
      $("#invalidemailotpcheck").show();
      $("#invalidmobileotpcheck").show();
      this.setState({ paynow: false });
    } else if (
      this.state.contactOtp.trim() == "" ||
      isNaN(this.state.contactOtp)
    ) {
      $("#invalidmobileotpcheck").show();
      this.setState({ paynow: false });
    } else if (this.state.contactOtp.length > 6 || this.state.contactOtp < 6) {
      $("#invalidmobileotpcheck").show();
      this.setState({ paynow: false });
    } else if (this.state.emailOtp.trim() == "") {
      $("#invalidemailotpcheck").show()
      this.setState({ paynow: false });
    } else if (this.state.emailOtp.length > 6 || this.state.emailOtp < 6) {
      $("#invalidemailotpcheck").show();
      this.setState({ paynow: false });
    } else if (
      this.state.gstno.trim() != "" &&
      !gstregex.test(this.state.gstno)
    ) {
      this.showNotification("Please enter a valid Gst no");
      this.setState({ paynow: false });
    } else {
      var localemailotp = this.state.emailOtp;
      if (this.state.emailOtp == "NOTREC") {
        localemailotp = "0"
      }

      $(".paynowbtn").hide();
      $(".paymentloader").show();
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=createOrderToNewUser",
          {
            agentId: Decagentid,
            serviceName: this.state.serviceshortname,
            coupon: this.state.couponapplied,
            requestid: this.state.servicerequestid,
            emailOTP: localemailotp,
            contactOTP: this.state.contactOtp,
            currency: "INR",
            gstno: this.state.gstno,
            govtfees: this.state.servicegovtfees,
            discount: this.state.coupunpercentage,
            referral: this.state.referral,
            CommunicationMode: this.state.communicationMode
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            $(".paynowbtn").show();
            $(".paymentloader").hide();
            const options = {
              key: process.env.REACT_APP_RAZORPAY_KEY,
              amount: res.data.finalamount,
              currency: "INR",
              name: this.state.servicefullname,
              description: "Regular Service",
              image: "https://legal251.com/images/common/legal.svg",
              order_id: res.data.orderid,
              theme: {
                color: "#f8951d",
              },
              notes: {
                requestid: this.state.servicerequestid,
                from: "AGP",
                servicetype: "RL",
                agentid: Decagentid,
                userid: "",
                couponstatus: this.state.couponstatus,
                couponcode: this.state.couponapplied.toUpperCase(),
                discount: this.state.coupunpercentage,
                gstnumber: this.state.gstno,
                upgrade: "false",
                renew: "false",
                invoice: "bill",
              },
              handler: (response) => {
                var mysign = sha256.hmac(
                  process.env.REACT_APP_RAZORPAY_SECRETKEY,
                  res.data.orderid + "|" + response.razorpay_payment_id
                );
                if (
                  response.razorpay_payment_id != null &&
                  response.razorpay_payment_id != false &&
                  response.razorpay_payment_id != ""
                ) {
                  if (mysign == response.razorpay_signature) {
                    sessionStorage.clear();
                    this.props.history.push({
                      pathname: `/addservice/paymentsuccessfull`,
                      state: {
                        orderid: res.data.orderid,
                        servicerequestid: this.state.servicerequestid,
                        serviceshortname: this.state.serviceshortname
                      },
                    });
                  } else {
                    sessionStorage.clear();
                    this.props.history.push({
                      pathname: `/addservice/paymentfailed`,
                      state: { Paymentfailed: true },
                    });
                  }
                } else {
                  sessionStorage.clear();
                  this.props.history.push({
                    pathname: `/addservice/paymentfailed`,
                    state: { Paymentfailed: true },
                  });
                }
              },
              prefill: {
                name: this.state.servicefullname,
                email: res.data.email,
                contact: res.data.contact,
              },
            };
            const rzp1 = new window.Razorpay(options);
            rzp1.open();
            rzp1.on("payment.failed", function (response) {
              sessionStorage.clear();
              this.props.history.push({
                pathname: `/addservice/paymentfailed`,
                state: { Paymentfailed: true },
              });
            });
          } else if (res.data.code == "200" && res.data.status == "paid") {
            sessionStorage.clear();
            this.props.history.push({
              pathname: `/addservice/paymentsuccessfull`,
              state: {
                orderid: res.data.orderid,
                servicerequestid: this.state.servicerequestid,
                serviceshortname: this.state.serviceshortname
              },
            });
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else {
            $(".paynowbtn").show();
            $(".paymentloader").hide();
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => { });
    }
  };

  reffotpverify = () => {
    $("#invalidemailotpcheck").hide();
    $("#invalidmobileotpcheck").hide();
    $("#emailotpsentcheck").hide();
    $("#mobileotpsentcheck").hide();
    $("#choosecommunication").hide();
    this.setState({ paynow: true });
     if (
      this.state.contactOtp.trim() == ""
    ) {
      $("#invalidemailotpcheck").show();
      $("#invalidmobileotpcheck").show();
    } else if (
      this.state.contactOtp.trim() == "" ||
      isNaN(this.state.contactOtp)
    ) {
      $("#invalidmobileotpcheck").show();
      this.setState({ paynow: false });
    } else if (this.state.contactOtp.length > 6 || this.state.contactOtp < 6) {
      $("#invalidmobileotpcheck").show();
      this.setState({ paynow: false });
    }  else{
     

      $(".paynowbtn").hide();
      $(".paymentloader").show();

      axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=verifyReferralOtp",
        {
          agentId: Decagentid,
          serviceName: this.state.serviceshortname,
          refId: this.state.servicerequestid,
          emailOtp: "",
          contactOtp: this.state.contactOtp,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          $(".paynowbtn").show();
          $(".paymentloader").hide();
          this.showNotification(res.data.message);
          sessionStorage.setItem("srewrercgdgfdgf", encryptor.encrypt(false));

          this.props.history.push({
            pathname: `/referral-lead`,
            lead: { message: res.data.message },
          });
          
        } else if (
          res.data.code == "201" &&
          res.data.status == "invalidauth"
        ) {
          this.logout();
        } else {
          $(".paynowbtn").show();
          $(".paymentloader").hide();
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => { });
    }
  }

  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };

  showservicetotal = (total) => {
    if (Number.isInteger(total)) {
      return total;
    } else {
      total = parseFloat(total).toFixed();
      return total;
    }
  };

  render() {
    let ServiceURL;
    if (this.state.serviceselectvalue == "GST Registration") {
      ServiceURL = "https://legal251.com/gst-registration";
    } else if (this.state.serviceselectvalue == "GST Return Filing") {
      ServiceURL = "https://legal251.com/gst-return-filing";
    } else if (this.state.serviceselectvalue == "ITR Filing") {
      ServiceURL = "https://legal251.com/income-tax-return-filing";
    } else if (this.state.serviceselectvalue == "Trademark Registration") {
      ServiceURL = "https://legal251.com/trademark-registration";
    } else if (this.state.serviceselectvalue == "Online Accounting") {
      ServiceURL = "https://legal251.com/online-accounting";
    }
    if (
      localStorage.getItem("gglltakoinoeenl") ==
      process.env.REACT_APP_LOGIN_KEY &&
      localStorage.getItem("toeljgtkewlna") != null &&
      localStorage.getItem("qazxswedcvfrtgb") != null
    ) {
      if (
        sessionStorage.getItem("ielndmlgeaefelvsdrlucaa") != null &&
        sessionStorage.getItem("srleleddgaeaemvcnia") != null &&
        sessionStorage.getItem("uegiqlsartdele") != null &&
        sessionStorage.getItem("aprcillgee") != null &&
        sessionStorage.getItem("evlgsoaeetgfl") != null &&
        sessionStorage.getItem("lslgerotnscmvheearaei") != null &&
        sessionStorage.getItem("egalllpna") != null
      ) {
        return (
          <div className="cont">
            <div id="notifContainer"></div>
            <div className="overlay"></div>
            <Helmet>
              <link
                rel="stylesheet"
                href={process.env.PUBLIC_URL + "/css/select2.css"}
              />
            </Helmet>

            <Header />

            <img
              src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
              className="sidebaropenicon"
            />
            <img
              src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
              onClick={this.backbutton}
              className="sidebarbackicon"
            />
            <img
              src={
                process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"
              }
              className="sidebarexpandicon"
            />

            <div className="overlay1"></div>
            <div className="sidebar sidebarshift">
              <div className="side_div_2 sidebar_txt active">
                <Link to="/dashboard">Overview</Link>
              </div>
              <div className="side_div_1 sidebar_txt">
                <Link to="/myservices">My Services</Link>
              </div>
              <div className="side_div_1 sidebar_txt">
                <a href="#">Payments Invoices</a>
              </div>
              <div className="side_div_1 sidebar_txt">
                <a href="#">Documents Record</a>
              </div>
              <div className="side_div_1 sidebar_txt">
                <a href="#">Add service</a>
              </div>
              <div></div>
            </div>
            <div className="arrow-left2 visibilityhidden"></div>
            <div className="white_div expandwhite_div">
              <div className="white_box">
                <div className="white_boxscrolldiv">
                  <div className="checkoutmainpadding">
                    <div className="row">
                      <div className="col-md-7 customcol1">
                        <p className="reviewheading">Review your order</p>
                        <div className="row">
                          <div className="col-sm-7 customcol3">
                            <center>
                              {" "}
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/img/review_your_order.svg"
                                }
                                className="revieworderimg"
                              />
                            </center>
                          </div>
                          <div className="col-sm-7 customcol4">
                            <div className="Packagediv">
                              <p className="Packageheading">
                                Package Includes:-
                              </p>
                              <br />
                              <div className="Packagedivinside">
                                <p className="paymentpoint paymentpointone">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Icon_awesome-location-arrow.svg"
                                    }
                                    className="registerarrow"
                                  />
                                  Dashboard for managing service
                                </p>

                                <p className="paymentpoint">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Icon_awesome-location-arrow.svg"
                                    }
                                    className="registerarrow"
                                  />{" "}
                                  Mobile application support
                                </p>
                                <p className="paymentpoint">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Icon_awesome-location-arrow.svg"
                                    }
                                    className="registerarrow"
                                  />{" "}
                                  Documents sharing facility
                                </p>
                                <p className="paymentpoint">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Icon_awesome-location-arrow.svg"
                                    }
                                    className="registerarrow"
                                  />{" "}
                                  Download deliverables any time
                                </p>
                                <p className="paymentpoint">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Icon_awesome-location-arrow.svg"
                                    }
                                    className="registerarrow"
                                  />{" "}
                                  Password sharing
                                </p>
                                <p className="paymentpoint">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Icon_awesome-location-arrow.svg"
                                    }
                                    className="registerarrow"
                                  />{" "}
                                  Round the clock support
                                </p>
                                <p className="paymentpoint">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/img/Icon_awesome-location-arrow.svg"
                                    }
                                    className="registerarrow"
                                  />{" "}
                                  Time to time updates & notifications
                                </p>
                                <a
                                  target="_blank"
                                  href={ServiceURL}
                                  className="morepaymentpoint"
                                >
                                  More
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5 customcol2">
                        <div className="detailsdivision">
                          <div className="detailsdivisionpadding">

                            {
                              this.state.createlead ? <></> :
                              <>
                              <p className="Detailsheading">Communication Mode:</p>

                              <select className="communic" value={this.state.communicationMode} onChange={(e) => this.setState({
                                communicationMode : e.target.value
                              })}>
                                <option value="">Select Communication</option>
                                <option value="With Me">With Me</option>
                                <option value="Customer Directly">Customer Directly</option>
                              </select>
                              </>

                            }

                          
                          <p className="" id="choosecommunication">
                                    <img
                                      className="mr-2 codemessageimg"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Icon_feather-alert-circle.svg"
                                      }
                                    />
                                    &nbsp; Select Communication Mode
                                  </p>
                            <p className="Detailsheading">Details:</p>
                            {/* <p className="Detailsheading">You will be redirected to homepage in {this.state.mintimeout} : {this.state.Sectimeout} minutes</p> */}
                            <div className="detailsdivisioninsidepadding">
                              <div className="row">
                                <div className="col-5">
                                  <p className="serviceregistername">
                                    Service:
                                  </p>
                                </div>
                                <div className="col-7">
                                  <p className="serviceregistername">
                                    {this.state.serviceselectvalue}
                                  </p>
                                </div>
                                {
                              this.state.createlead ? <></> : <>
                                <div className="col-5">
                                  <p className="serviceregistername2">Plan:</p>
                                </div>
                                <div className="col-7">
                                  <p className="serviceregistername2">
                                    {this.state.checkserviceplan}
                                  </p>
                                </div> </> }
                                <div className="col-5">
                                  <p className="serviceregistername2">
                                    Mobile OTP:
                                  </p>
                                </div>

                                <div className="col-7">
                                  <input
                                    type="tel"
                                    className="codeinput"
                                    placeholder="OTP"
                                    maxLength="6"
                                    value={this.state.contactOtp}
                                    onChange={(e) =>
                                      this.setState({
                                        contactOtp: e.target.value,
                                      })
                                    }
                                  />
                                  {this.state.mtime != 0 ? (
                                    <button className="applybtn op" disabled>
                                      Resend ({this.state.mtime} s)
                                    </button>
                                  ) : (
                                    <>
                                      <button
                                        id="mobileresendcheck"
                                        className="applybtn "
                                        onClick={this.handlemobileresend}
                                      >
                                        Resend
                                      </button>
                                      <img
                                        id="mobileresend-loadercheck"
                                        src="/img/loader.gif"
                                        height="50px"
                                        alt=""
                                      />{" "}
                                    </>
                                  )}

                                  <p className="" id="mobileotpsentcheck">
                                    OTP sent successfully{" "}
                                  </p>
                                  <p className="" id="invalidmobileotpcheck">
                                    <img
                                      className="mr-2 codemessageimg"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Icon_feather-alert-circle.svg"
                                      }
                                    />
                                    &nbsp; Invalid OTP
                                  </p>
                                </div>

                                {
                              this.state.createlead ? <></> :
                                <><div className="col-5">
                                  <p className="serviceregistername2">
                                    Email OTP:
                                  </p>
                                </div>

                                <div className="col-7">
                                  <input
                                    type="tel"
                                    className="codeinput"
                                    placeholder="OTP"
                                    maxLength="6"
                                    id="emailotpinput"
                                    value={this.state.emailOtp}
                                    onChange={(e) =>
                                      this.setState({
                                        emailOtp: e.target.value,
                                      })
                                    }
                                  />
                                  {this.state.etime != 0 ? (
                                    <button className="applybtn op" disabled>
                                      Resend ({this.state.etime} s)
                                    </button>
                                  ) : (
                                    <>
                                      <button
                                        className="applybtn"
                                        id="emailresendcheck"
                                        onClick={this.handleemailresend}
                                      >
                                        Resend
                                      </button>
                                      <img
                                        id="emailresend-loadercheck"
                                        src="/img/loader.gif"
                                        height="50px"
                                        alt=""
                                      />{" "}
                                      <p className="" id="invalidemailotpcheck2" onClick={this.handleemailotp}>
                                        Email OTP Not Received
                                      </p>
                                      <p className="" id="emailotpsentcheck2" onClick={this.handlemobileotp}>
                                        OTP Received
                                      </p>
                                    </>
                                  )}



                                  <p className="" id="emailotpsentcheck">
                                    OTP sent successfully{" "}
                                  </p>
                                  <p className="" id="invalidemailotpcheck">
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Icon_feather-alert-circle.svg"
                                      }
                                      className="codemessageimg"
                                    />{" "}
                                    &nbsp; Invalid OTP
                                  </p>
                                </div>
                                </>
                                }
                                {
                              this.state.createlead ? <></> : <>
                                <div className="col-5">
                                  <p className="serviceregistername2">Fees:</p>
                                </div>
                                <div className="col-7">
                                  <p className="serviceregistername2">
                                    <span className="copunorginalprice">
                                      ₹{this.state.serviceprice}
                                    </span>{" "}
                                    <span className="copunappliedtext">
                                      ₹{this.state.servicepriceaftercoupun}
                                    </span>
                                  </p>
                                </div>
                                <div className="col-5 ">
                                  <p className="serviceregistername2">
                                    GST(18%):
                                  </p>
                                </div>
                                <div className="col-7 ">
                                  <p className="serviceregistername2">
                                    ₹{this.state.servicegst}
                                  </p>
                                </div>
                                {/* <div className="col-5 ">
                                  <p className="serviceregistername2">
                                    Select:
                                  </p>
                                </div>
                                <div className="col-7 ">
                                  <p className="serviceregistername2">
                                    ₹{this.state.servicegst}
                                  </p>
                                </div> */}
                                <div
                                  id="accordion"
                                  className="col-12 gstAccordion2"
                                >
                                  <div className="card gstnoCard2 bg-transparent col-12 w-100  p-0">
                                    <div
                                      className="card-header pb-0 gstnoHeader2 pl-0"
                                      id="headingOne"
                                    >
                                      <div className="col-12 p-0">
                                        <p
                                          className="serviceregistername2 addGst2"
                                          data-toggle="collapse"
                                          data-target="#collapseOne"
                                          aria-expanded="false"
                                          aria-controls="collapseOne"
                                        >
                                          Add GST No. +
                                        </p>
                                      </div>
                                    </div>

                                    <div
                                      id="collapseOne"
                                      className="collapse"
                                      aria-labelledby="headingOne"
                                      data-parent="#accordion"
                                    >
                                      <div className="card-body row pl-0">
                                        <div
                                          className="col-5  pr-0"
                                          id="gstnumber"
                                        >
                                          <p className="serviceregistername2">
                                            GST No :
                                          </p>
                                        </div>
                                        <div
                                          className="col-lg-7 col-12"
                                          id="gstnoInput"
                                        >
                                          <input
                                            type="text"
                                            className="codeinput2 gstno2"
                                            placeholder="GST no."
                                            maxLength="15"
                                            value={this.state.gstno}
                                            onChange={(e) =>
                                              this.setState({
                                                gstno:
                                                  e.target.value.toUpperCase(),
                                              })
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  id="accordion"
                                  className="col-12 gstAccordion2"
                                >
                                  <div className="card gstnoCard2 bg-transparent col-12 w-100  p-0">
                                    <div
                                      className="card-header pb-0 gstnoHeader2  pt-0 pl-0"
                                      id="headingOne"
                                    >
                                      <div className="col-12 p-0">
                                        <p
                                          className="serviceregistername2 addGst2"
                                          data-toggle="collapse"
                                          data-target="#collapseOne2"
                                          aria-expanded="false"
                                          aria-controls="collapseOne2"
                                        >
                                          Add Referral Code. +
                                        </p>
                                      </div>
                                    </div>

                                    <div
                                      id="collapseOne2"
                                      className="collapse"
                                      aria-labelledby="headingOne"
                                      data-parent="#accordion"
                                    >
                                      <div className="card-body row pt-0 pl-0">
                                        <div
                                          className="col-5  pr-0"
                                          id="gstnumber"
                                        >
                                          <p className="serviceregistername2">
                                            Referral code :
                                          </p>
                                        </div>
                                        <div
                                          className="col-lg-7 col-12"
                                          id="gstnoInput"
                                        >
                                          <input
                                            type="text"
                                            className="codeinput2 gstno2"
                                            placeholder="Referral code."
                                            maxLength="30"
                                            value={this.state.referral}
                                            onChange={(e) =>
                                              this.setState({
                                                referral:
                                                  e.target.value.toUpperCase(),
                                              })
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div> </> }

                                {
                                  checkoutservicesortname == "LLP" || checkoutservicesortname == "OPC" || checkoutservicesortname == "SECE" || checkoutservicesortname == "PVTL" ?



                                    <div className="col-5 govtfeesdiv">
                                      <p className="serviceregistername2">
                                        Non Taxable Amount:
                                      </p>
                                    </div>


                                    :

                                    <div className="col-5 govtfeesdiv">
                                      <p className="serviceregistername2">
                                        Govt. fees:
                                      </p>
                                    </div>

                                }


                                <div className="col-7 govtfeesdiv">
                                  <p className="serviceregistername2">
                                    ₹{this.state.servicegovtfees}
                                  </p>
                                </div>

                                {
                              this.state.createlead ? <></> : 
                              <>
                                <div className="col-5">
                                  <p className="serviceregistername2">
                                    Apply promo code:
                                  </p>
                                </div>

                                <div className="col-7">
                                  <input
                                    type="text"
                                    className="codeinput applycoupen"
                                    placeholder="Code"
                                    value={this.state.coupun}
                                    onChange={this.handlecoupunChange}
                                  />
                                  <button
                                    className="applybtn"
                                    id="applybtnmain"
                                    onClick={this.applycoupun}
                                  >
                                    Apply
                                  </button>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/img/loader.gif"
                                    }
                                    className="couponapplyloader"
                                  />
                                  <button
                                    className="delbtn"
                                    onClick={this.delcoupun}
                                  >
                                    <img
                                      className="delbtnimg"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path_20158.svg"
                                      }
                                    />
                                  </button>
                                  <p className="success">
                                    Promocode applied for{" "}
                                    {this.state.coupunpercentage}
                                    {this.state.flat == "D" ? (
                                      <span>&nbsp;₹</span>
                                    ) : (
                                      <span>&nbsp;%</span>
                                    )}
                                  </p>
                                  <p className="failure">
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Icon_feather-alert-circle.svg"
                                      }
                                      className="codemessageimg"
                                    />
                                    Invalid promocode
                                  </p>
                                </div>
                                </>

                                    }
                              </div>
                            </div>
                          </div>
                          <div className="checkouthrpadding">
                            <hr className="checkoutdivhr" />
                          </div>
                          {
                              this.state.createlead ? <></> : 
                          <div className="grandtotalpadding">
                            <div className="row">
                              <div className="col-5">
                                <p className="totalheading">Grand total:</p>
                              </div>
                              <div className="col-7">
                                <p className="totalheading">
                                  ₹
                                  {this.showservicetotal(
                                    this.state.servicetotal
                                  )}
                                </p>
                              </div>
                            </div>
                          </div> }
                          <div className="paynowpadding">
                            <center>
                              {" "}
                              {
                              this.state.createlead ? 
                              
                              <button
                                className="paynowbtn"
                                onClick={() => this.reffotpverify()}
                              >
                                Submit{" "}
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/img/longarrow.svg"
                                  }
                                  alt=""
                                />
                              </button>
                              
                              : 
                              

                              <button
                                className="paynowbtn"
                                onClick={this.Createorder}
                              >
                                PAY NOW{" "}
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/img/longarrow.svg"
                                  }
                                  alt=""
                                />
                              </button>
                              
                              }
                              
                              <img
                                src={process.env.PUBLIC_URL + "/img/loader.gif"}
                                className="paymentloader"
                              />
                              <p className="govfeecheckout">*Government Fees Extra as per applicable.</p>
                            </center>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ScriptTag src="https://checkout.razorpay.com/v1/checkout.js" />
            <Footer />
          </div>
        );
      } else {
        return <Redirect to="/dashboard" />;
      }
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default Checkout;

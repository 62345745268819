import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "./Header";
import Footer from "./Footer";
import $, { data } from "jquery";
// import axios from 'axios';

export class Partnerpurchased extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 0,
      mydata: [],
      pageCount: 0,
      offset: "",
      PER_PAGE: 12,
      userinfo: "Mobile Number",
      userinfoplaceholder: "Enter mobile no. here",
      userinfoinput: "",
      mobileotp: "",
      emailotp: "",
      rtime: 60,
    };
  }

  fetchData = () => {
    fetch("https://ihsavru.me/Demo/uploads.json")
      .then((res) => res.json())
      .then((data) => {
        
        const {
          course: { uploads },
        } = data;
        this.setState({ mydata: data.course.uploads });

        this.setState({
          pageCount: Math.ceil(this.state.mydata.length / this.state.PER_PAGE),
        });
      });
  };

  componentDidMount = () => {
    this.fetchData();
    $("#invaliduserinfoinput").hide();
    $(".sendOtpBtn").show();
    // $("#success-modal").hide()
    //  $("#unsuccess-modal").hide()
    $("#userinfomodal").show();
  };
  render() {
    return (
      <div>
        <div className="arrow-left3"></div>
        <div className="white_div">
          <div className="white_box">
            <div className="row main-row">





              
              <div className="table-responsive my-table comm-table">
                <table className="table mr-3">
                  <thead className="my-thead ">
                    <tr>
                      <th className="my-th sno">Sno</th>
                      <th className="my-th services">Purchase date</th>
                      <th className="my-th userid useridcom2 ">Service Name</th>
                      <th className="my-th userid useridcom2 ">User ID</th>
                      <th className="my-th userid useridcom2 ">
                        Purchase Amount
                      </th>
                      <th className="my-th userid useridcom2">
                        Commission Percent
                      </th>
                      <th className="my-th userid useridcom2">
                        Total Commission
                      </th>
                    </tr>
                  </thead>
                  <tbody className="my-tbody">
                    {this.state.mydata
                      .slice(
                        this.state.offset,
                        this.state.offset + this.state.PER_PAGE
                      )
                      .map((data) => {
                        return (
                          <tr key={data.url}>
                            <td scope="row" className="sno">
                              1.
                            </td>
                            <td className="userid services  ">16/12/2021</td>
                            <td className="userid useridcom2 ">ITRF</td>
                            <td className="userid useridcom2 ">{data.id}</td>
                            <td className="userid useridcom2 ">10000₹ /-</td>
                            <td className="userid useridcom2 ">10 %</td>
                            <td className="userid useridcom2 ">10000₹ /-</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="mt-3 w-100">
              <ReactPaginate
                previousLabel={<img src={process.env.PUBLIC_URL +"/img/Path_21772.svg"} width="15px" />}
                nextLabel={<img src={process.env.PUBLIC_URL +"/img/Path_21773.svg"} width="15px" />}
                pageCount={this.state.pageCount}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link----active"}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Partnerpurchased;

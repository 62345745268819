import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $, { timers } from "jquery";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import SupportDetails from "./SupportDetails";
import SupportDetailsMobile from "./SupportDetailsMobile";
import JSZipUtils from "jszip-utils";
import JSZip from "jszip";
import { saveAs } from "file-saver";
var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);
var today = new Date();
let ChooseNowBtnsDisable = "No";
let Decserviceid;
let Decservicename;
let Decplan;
let Decagentid;
let Decuserid;
let Decjwttoken;
let mainstatusVar;
let additionalReupload = false;
let mainaction = "upload";
let temp = "general";
let additionalFilesIndex;
let additionalinputFieldIndex;
let additionalReuploadIndex;
let additionalReuploadStatus;
let additionalFilesNames = [];
const year = today.getFullYear();
const Currmonth = today.getMonth() + 1;
let CurrentMonthName;
let mainactionPANCard = "upload";
let PANCardStatus;
let mainactionAddressProof = "upload";
let AddressProofStatus;
let mainactionMsmeStartupCertificate = "upload";
let MsmeStartupCertificateStatus;
let mainactionProofOfTM = "upload";
let ProofOfTMStatus;
let mainactionBrandLogo = "upload";
let BrandLogoStatus;
let mainFyear;
let mainmonth;
let firsttime = 0;
let supportName;
let supportContact;
let supportEmail;
let mainDocs = [
  {
    doc: "PANCardStatus",
    status: PANCardStatus,
  },
  {
    doc: "AddressProofStatus",
    status: AddressProofStatus,
  },
];

let msmeDoc = [
  {
    doc: "MsmeStartupCertificateStatus",
    status: MsmeStartupCertificateStatus,
  },
];

let brandNameDoc = [
  {
    doc: "ProofOfTMStatus",
    status: ProofOfTMStatus,
  },
];

let brandLogoDoc = [
  {
    doc: "BrandLogoStatus",
    status: BrandLogoStatus,
  },
];

let additionalStatusArrayCheck;
let AnyReuploadOccured = "No";
let MSMEReupload = "No";
let proceednowforword;

export class Activecustom extends Component {
  constructor(props) {
    super(props);

    this.state = {
      serviceType: "",
      serviceTypeText: "",
      fileName: "File Name",
      fileBase64: "",
      documentType: "",
      documentName: "",
      comment: "",
      commentHistroyarray: [],
      brandName_radio: "No",
      trademark_radio: "Logo",
      Date: "",
      trademarkWord: "",
      businessNature: "",
      PANCardDocLink: "#",
      AddressProofDocLink: "#",
      MsmeStartupCertificateDocLink: "#",
      BrandLogoDocLink: "#",
      ProofOfTMDocLink: "#",
      IsAddFileDisabled: true,
      additionalFiles: [],
      api_additionalFiles: "No",
      Add_DocName: "",
      inputFields: [],
      api_inputFields: "No",
      IsAddinputDisabled: true,
      informationStatus: "unlock",
      inputFieldsUnlock: [{ inputFieldName: "", inputFieldValue: "", inputFieldtype: "general" }],
      api_inputFieldsunlock: "No",
      IsAddinputDisabled: true,
      Add_inputFieldName: "",
      Add_inputFieldValue: "",
      dropdownType: 1,
      Fyear: "",
      Preyear: "",
      Fmonths: [
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
        "Jan",
        "Feb",
        "Mar",
      ],
      OrderedMonths: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      SelectedMonth: "",
      SelectedYear: "",
      data: [],
      dropDownValue: "",
      documentlinklist: "",
      Name: "",
      Contact: "",
      Timeline: [],
      Timelinedata: false,
      timelcount: 2,
      filtertimel: [],
      deliveredDocuments: [],
      referralPartner: true
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    $(document.body).css("background-color", "#2d2d2d");
    firsttime = 0;
    let mainuserid = localStorage.getItem("relleIsugad");
    Decuserid = encryptor.decrypt(mainuserid);
    let mainagentid = localStorage.getItem("qazxswedcvfrtgb");
    Decagentid = encryptor.decrypt(mainagentid);
    let mainjwttoken = localStorage.getItem("toeljgtkewlna");
    Decjwttoken = encryptor.decrypt(mainjwttoken);
    let mainserviceid = localStorage.getItem("vrellvaiecstiadeceig");
    let mainaservicename = localStorage.getItem("eieltaiclsraaemevcvnge");
    let mainplan = localStorage.getItem("ipacneglatvlael");
    Decserviceid = encryptor.decrypt(mainserviceid);
    Decservicename = encryptor.decrypt(mainaservicename);
    Decplan = encryptor.decrypt(mainplan);
    supportName = localStorage.getItem("supportName");
    supportContact = localStorage.getItem("supportContact");
    supportEmail = localStorage.getItem("supportEmail");
    
    this.setState({
      supportName:supportName,
      supportContact:supportContact,
      supportEmail:supportEmail,
    })

    if(this.state.referralPartner == true){
      const script = document.createElement("script");
      script.src = "/js/main.js";
      script.async = true;
      document.body.appendChild(script);
    }

    CurrentMonthName = this.state.OrderedMonths[Currmonth - 1];
    if (Currmonth >= 4) {
      mainFyear = `${year}-${year + 1}`;
      this.setState({
        Fyear: `${year}-${year + 1}`,
        Preyear: `${year - 1}-${year}`,
        SelectedYear: `${year}-${year + 1}`,
      });
    } else if (Currmonth < 4) {
      mainFyear = `${year - 1}-${year}`;
      this.setState({
        Fyear: `${year - 1}-${year}`,
        Preyear: `${year - 2}-${year - 1}`,
        SelectedYear: `${year - 1}-${year}`,
      });
    }
    if (this.state.SelectedYear == this.state.Fyear) {
      this.ShowCurrentYearsMonth();
      this.setState({
        CurrentYearsMonths: true,
      });
    }

    mainmonth = CurrentMonthName;
    this.getserviceInformationApi();

    let str = (document.getElementsByClassName(
      "myservicesortbyfilterbystatus"
    ).innerHTML = this.props.location.pathname.replaceAll("/", " > "));
    let mainstr = (document.getElementsByClassName(
      "myservicesortbyfilterbystatus"
    ).innerHTML = str.replace(" > ", " "));
    $(".myservicesortbyfilterbystatus").html(mainstr);

    this.getnameanumber();




  }

  getnameanumber = () => {
    axios.post(
      process.env.REACT_APP_API_BASE_URL + "p=empexecutiveDetails",
      {
        agentId: Decagentid,
        serviceName: Decservicename,
        serviceId: Decserviceid,
      },
      {
        headers: {
          Authkey: process.env.REACT_APP_API_KEY,
          "App-Token-Access": Decjwttoken,
        },
        auth: {
          username: process.env.REACT_APP_API_USERNAME,
          password: process.env.REACT_APP_API_PASSWORD,
        },
      }
    )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            Name: res.data.teamLeaderName,
            Contact: res.data.teamLeaderNumber,
          })
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
  }

  getserviceInformationApi = () => {
    $(".serviceoverlay").show();
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=serviceInformation",
        {
          agentId: Decagentid,
          userId: Decuserid,
          requestType: "general",
          serviceId: Decserviceid,
          serviceName: Decservicename,
          plan: "",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        $(".serviceoverlay").hide();
        if (res.data.code == "200" && res.data.status == "success") {

          this.setState({
            deliveredDocuments: res.data.deliveredDocuments,
          });

          ChooseNowBtnsDisable = "No";

          this.setState(
            {
              serviceType: res.data.serviceType,
              informationStatus: res.data.informationStatus,
              documentlinklist: res.data.documentLink
            })
          if (res.data.informationStatus == "lock") {
            $(".proceednowbtn").hide();
            $(".maindeletebtn").hide();
            $(".adddocumentbtnmain").hide();
          }
          if (res.data.documents.length > 0) {
            this.setState(
              {
                additionalFiles: [...res.data.documents],
                Add_DocName: "File Name",
                api_additionalFiles: "Yes",
              },
              () => { }
            );
            proceednowforword = "success";
          } else if (
            res.data.informationStatus != "lock"
          ) {
            this.setState({
              Add_DocName: "",
              additionalFiles: [1],
              api_additionalFiles: "No",
            });
            proceednowforword = "fail";
            $(".proceednowbtn").attr("disabled", true);
          }

          if (res.data.inputFields.length > 0) {
            let localarray = []
            for (let i = 0; i < res.data.inputFields.length; i++) {
              let localobject = { inputFieldName: "", inputFieldValue: "", inputFieldtype: "general" }
              localobject.inputFieldName = Object.keys(res.data.inputFields[i])[0]
              localobject.inputFieldValue = Object.values(res.data.inputFields[i])[0]
              localarray.push(localobject)

            }
            this.setState({
              inputFieldsUnlock: localarray,
              Add_inputFieldName: "File Name",
              api_inputFields: "Yes",
            });
          }
          else if (
            res.data.inputFields.length == 0 &&
            res.data.informationStatus == "lock"
          ) {
            this.setState({
              Add_inputFieldName: "",
              inputFieldsUnlock: [],
              api_inputFields: "No",
            });
          }
          else if (
            res.data.inputFields.length == 0 &&
            res.data.informationStatus != "lock"
          ) {
            this.setState({
              Add_inputFieldName: "",
              inputFieldsUnlock: [{ inputFieldName: "", inputFieldValue: "", inputFieldtype: "general" }],
              api_inputFields: "No",
            });
          }




        } else if (res.data.code == "201" && res.data.status == "docuploadnotallowed") {
          this.setState({
            referralPartner:false,
          })
          this.showNotification(res.data.message);
        } 
      })
      .catch((err) => {

        $(".serviceoverlay").hide();
        this.showNotification("Something went wrong, Try again!");
      });
  };

  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };

  getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  logout = () => {
    localStorage.removeItem("gglltakoinoeenl");
    localStorage.removeItem("toeljgtkewlna");
    localStorage.removeItem("qazxswedcvfrtgb");
    localStorage.removeItem("lshhihfnkff");
    this.props.history.push({
      pathname: `/`,
    });
  };
  statusCheckFunction = (status, array) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i].status == status) {
        return i;
      }
    }
  };
  renderCommenthistory() {
    if (this.state.commentHistroyarray.length > 0) {
      return this.state.commentHistroyarray.map((activedoc, index) => {
        if (activedoc.commentBy == "user" && activedoc.special == "no") {
          return (
            <div className="rightconvtextdiv" key={index}>
              <p className="rightconvtext">{activedoc.comment}</p>
              <p className="rightconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "user" &&
          activedoc.special == "green"
        ) {
          return (
            <div className="rightconvtextdiv" key={index}>
              <p className="rightgreenconvtext">{activedoc.comment}</p>
              <p className="rightconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "employee" &&
          activedoc.special == "no"
        ) {
          return (
            <div className="leftconvtextdiv" key={index}>
              <p className="leftconvtext">{activedoc.comment}</p>
              <p className="leftconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "user" &&
          activedoc.special == "red"
        ) {
          return (
            <div className="rightunappconvtextdiv" key={index}>
              <p className="rightunappconvtext">{activedoc.comment}</p>
              <p className="rightconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "employee" &&
          activedoc.special == "green"
        ) {
          return (
            <div className="leftgreenconvtextdiv" key={index}>
              <p className="leftgreenconvtext">{activedoc.comment}</p>
              <p className="leftconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "employee" &&
          activedoc.special == "red"
        ) {
          return (
            <div className="leftconvtextdiv" key={index}>
              <p className="leftunappconvtext">{activedoc.comment}</p>
              <p className="leftconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        }
      });
    } else if (this.state.commentHistroyarray.length == 0) {
      return <p className="convservicename">No comments available!</p>;
    }
  }
  handleComment = (e) => {
    this.setState({
      comment: e.target.value,
    });
  };

  // Additional
  handleAddFileChange = (e, index, status) => {
    additionalReuploadIndex = index;
    additionalReuploadStatus = status;
    $(".upload_btn20_7").show();
    $(".upload_btnloader_7").hide();
    const file = e.target.files[0];
    if (!additionalFilesNames.includes(this.state.Add_DocName.trim())) {
      const myfile = e.target.files;
      if (ChooseNowBtnsDisable == "Yes") {
        this.showNotification(
          "Files cannot be uploaded when the service is locked!"
        );
      } else if (myfile.length == 1) {
        const file = myfile[0];
        if (file.type.match("audio.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.type.match("video.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.size > 104857600) {
          this.showNotification("File too big!");
        } else if (file.name.length > 100) {
          this.showNotification("File name limit exceed!");
        } else {
          this.setState(
            {
              fileName: file.name,
              fileBase64: file,
              documentType: "additional",
              documentName: this.state.Add_DocName,
            },
            () => {
              $(".AddModal").modal({
                backdrop: "static",
                keyboard: false,
              });
            }
          );
        }
      } else if (myfile.length > 1) {
        var zip = new JSZip();
        for (let i = 0; i < myfile.length; i++) {
          zip.file(myfile[i].name, myfile[i], {
            base64: true,
          });
        }
        zip
          .generateAsync({
            type: "blob",
          })
          .then((content) => {
            if (content.size > 104857600) {
              this.showNotification("File too big!");
            } else {
              this.setState({
                fileName: "download.zip",
                fileBase64: content,
                documentType: "additional",
                documentName: this.state.Add_DocName,
              });
            }
          });
        $(".AddModal").modal({
          backdrop: "static",
          keyboard: false,
        })
      }
    } else {
      this.showNotification(
        "Document names can not be the same, Try again with different name!"
      );
    }
  };

  uploadFile = (FileIndex, FileStatus) => {
    let AdditionaldocumentName1;
    if (FileStatus == "reupload") {
      $(".proceednowbtn").attr("disabled", true);
      let fileTobeUploaded = this.state.additionalFiles[FileIndex];
      AdditionaldocumentName1 = Object.keys(fileTobeUploaded)[0];
      mainaction = "reupload";
    } else {
      AdditionaldocumentName1 = this.state.documentName;
      mainaction = "upload";
    }
    if (AdditionaldocumentName1.trim() == "") {
      $(".AddModal").modal("hide");
      this.showNotification("Enter document Name first");
    } else if (this.state.comment.length <= 250) {
      $(".cancelbtn_7").attr("disabled", true);
      $(".upload_btn20_7").hide();
      $(".upload_btnloader_7").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", AdditionaldocumentName1);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            $(".AddModal").modal("hide");
            $(".cancelbtn_7").attr("disabled", false);
            $(`#${FileIndex}Fileseecommentsbtn`).attr("disabled", false);
            if (additionalReupload == false) {
              additionalFilesNames.push(this.state.Add_DocName);
              this.state.additionalFiles.pop();
              this.setState(
                {
                  comment: "",
                  additionalFiles: [
                    ...this.state.additionalFiles,
                    res.data.data,
                  ],
                },
                () => {
                  this.setState(
                    {
                      api_additionalFiles: "Yes",
                    },
                    () => {
                      this.additionalFilesArea();
                      this.checkhandleProceedBtn();
                    }
                  );

                  $(".additionalInput").attr("disabled", true);
                }
              );
            } else if (additionalReupload == true) {
              $(".proceednowbtn").attr("disabled", false);
              var array = [...this.state.additionalFiles];
              array.splice(FileIndex, 1, res.data.data);
              this.setState(
                {
                  comment: "",
                  api_additionalFiles: "Yes",
                  additionalFiles: array,
                },
                () => {
                  this.additionalFilesArea();
                  this.checkhandleProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".AddModal").modal("hide");
            this.showNotification("Something Went Wrong!");
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".AddModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {

          this.showNotification("Something went wrong, Try again!");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeFile = (index) => {
    $("#X-btn_7").attr("disabled", true);
    let fileTobeDeleted = this.state.additionalFiles[index];
    let NameOfAddFile = Object.keys(fileTobeDeleted)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: "",
          FY: "",
          Month: "",
          documentType: "additional",
          documentName: NameOfAddFile,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          var array = [...this.state.additionalFiles];
          if (res.data.data.status == "reupload") {
            array[index].status = "reupload";
            this.setState(
              {
                additionalFiles: array,
              },
              () => {
                this.additionalFilesArea();
                this.checkhandleProceedBtn();
              }
            );
            this.showNotification(res.data.message);
          } else {
            array.splice(index, 1);
            if (array.length > 0) {
              this.setState(
                {
                  additionalFiles: array,
                },
                () => {
                  this.additionalFilesArea();
                  this.checkhandleProceedBtn();
                }
              );
            } else if (array.length == 0) {
              this.setState(
                {
                  Add_DocName: "",
                  api_additionalFiles: "No",
                  IsAddFileDisabled: true,
                },
                () => {
                  this.additionalFilesArea();
                  this.checkhandleProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_7").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleFileseeComments = (index) => {
    $(".commentsbtn").attr("disabled", false);
    $(`#${index}Fileseecommentsbtn`).attr("disabled", true);
    let SelectedFile = this.state.additionalFiles[index];
    let NameOfAddFile = Object.keys(SelectedFile)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "additional",
          documentName: NameOfAddFile,
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(`#${index}Fileseecommentsbtn`).hide();
          $(".hidecommentsbtn").hide();
          $(`#${index}Filehidecommentsbtn`).show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleFilehideComments = (index) => {
    $(".seecommentsbtn").show();
    $(`#${index}Fileseecommentsbtn`).show();
    $(`#${index}Fileseecommentsbtn`).attr("disabled", false);
    $(`#${index}Filehidecommentsbtn`).hide();
    $(".hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  checkAddCommentFiles = (FileIndex, isDisabled) => {
    if (isDisabled == true) {
      return (
        <>
          <button
            className="seecommentsbtn Fileseecommentsbtn"
            id={`${FileIndex}Fileseecommentsbtn`}
            disabled
            onClick={() => this.handleFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn Filehidecommentsbtn"
            id={`${FileIndex}Filehidecommentsbtn`}
            onClick={() => this.handleFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    } else if (isDisabled == false) {
      return (
        <>
          <button
            className="seecommentsbtn commentsbtn Fileseecommentsbtn"
            id={`${FileIndex}Fileseecommentsbtn`}
            onClick={() => this.handleFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn Filehidecommentsbtn"
            id={`${FileIndex}Filehidecommentsbtn`}
            onClick={() => this.handleFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    }
  };

  checkStatusAddFiles = (status, link, index) => {
    additionalFilesIndex = index;
    if (ChooseNowBtnsDisable == "Yes") {
      $(".proceednowbtn").hide();
      if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">X</button>
          </>
        );
      }
    } else {
      if (status == undefined) {
        status = "open";
      }
      if (status == "open") {
        mainaction = "upload";
        additionalReupload = true;
        $(".proceednowbtn").show();
        return (
          <>
            <label
              htmlFor={`ul_file_Add${index}`}
              className="choose_btn choose_7"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              name="file[]"
              multiple
              className="upload-photo"
              id={`ul_file_Add${index}`}
              // disabled={this.state.IsAddFileDisabled}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handleAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "remove") {
        mainaction = "upload";
        $(".proceednowbtn").show();
        return (
          <>
            <button
              className="X-btn X-btn_7"
              onClick={() => this.removeFile(index)}
            >
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_1025.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "reupload") {
        additionalReupload = true;
        mainaction = "reupload";
        $(".proceednowbtn").show();
        $(".proceednowbtn").attr("disabled", true);
        return (
          <>
            <label
              htmlFor={`ul_file_Add${index}`}
              className="choose_btn choose_7"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              name="file[]"
              className="upload-photo"
              id={`ul_file_Add${index}`}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handleAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">X</button>
          </>
        );
      }
    }
  };

  checkStatusAddinput = (status, index) => {
    additionalinputFieldIndex = index;
  };

  AdditionalFileDisabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero additionalInput"
        value={NameOfAddFile}
        disabled
        onChange={(e) => this.handleAdd_DocNameChange(e)}
      />
    );
  };

  AdditionalFileEnabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero additionalInput"
        value={NameOfAddFile}
        onChange={(e) => this.handleAdd_DocNameChange(e)}
      />
    );
  };

  additionalFilesArea = () => {
    additionalFilesNames = [];
    if (this.state.api_additionalFiles == "Yes") {
      return this.state.additionalFiles.map((file, index) => {
        let NameOfAddFile = Object.keys(file)[0];
        additionalFilesNames.push(NameOfAddFile);
        let link = file[Object.keys(file)[0]];
        let AdditionalFileInputField;
        let commentDisabled;
        if (file.status == "open" || file.status == undefined) {
          AdditionalFileInputField =
            this.AdditionalFileEnabledField(NameOfAddFile);
          commentDisabled = true;
        } else if (file.status != "open") {
          AdditionalFileInputField =
            this.AdditionalFileDisabledField(NameOfAddFile);
          commentDisabled = false;
        }
        return (
          <div className="displayflex" key={index}>
            <div className="uploadnocol">
              <p className="uploadadditionalsidetext">{index + 1}&#41;</p>
            </div>
            <div className="uploadadditionaldatacol">
              <div className="row mb-2">
                <div className="col-md-5">{AdditionalFileInputField}</div>
                <div className="col-md-7">
                  <div className="float-md-right float-left">
                    {this.checkAddCommentFiles(index, commentDisabled)}
                    {this.checkStatusAddFiles(file.status, link, index)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.api_additionalFiles == "No") {
      return (
        <div className="displayflex">
          <div className="uploadnocol">
            <p className="uploadadditionalsidetext">1&#41;</p>
          </div>
          <div className="uploadadditionaldatacol">
            <div className="row mb-2">
              <div className="col-md-5">
                <input
                  type="text"
                  maxLength="150"
                  placeholder="Document name..."
                  className="uploadtextfield marginzero"
                  value={this.state.Add_DocName}
                  onChange={(e) => this.handleAdd_DocNameChange(e)}
                />
              </div>
              <div className="col-md-7">
                <div className="fl_right">
                  {this.checkAddCommentFiles(0, true)}
                  {this.checkStatusAddFiles("open", null, 0)}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
  deleteInputField = (index) => {

    let afterDeleteinputField = this.state.inputFieldsUnlock
    additionalinputFieldIndex -= 1
    afterDeleteinputField.splice(index, 1);
    this.setState({
      inputFieldsUnlock: afterDeleteinputField
    })


  }
  additionalFilesArea2 = () => {
    return this.state.inputFieldsUnlock.map((item, index) => {
      return (
        <div className="" key={index}>
          <div className="displayflex">
            <div className="uploadnocol">
              <p className="uploadadditionalsidetext">{index + 1}&#41;</p>
            </div>
            <div className="uploadadditionaldatacol">
              <div className="row mb-2">
                <div className="col-md-9 my-2">
                  <input
                    type="text"
                    placeholder="Input name..."
                    maxLength="150"
                    className="uploadtextfield marginzero additionalInput2 w-100"
                    value={item.inputFieldName}
                    onChange={(e) => this.handleAdd_inputFieldNameChange(e, index, "fieldName")}
                  />
                </div>
                <div className="col-md-9 my-2">
                  {this.checkStatusAddinput(item.status, index)}
                  <textarea
                    type="text"
                    placeholder="Input name..."
                    maxLength="150"
                    className="uploadtextfield marginzero additionalInput2 addInputHeight"
                    value={item.inputFieldValue}
                    onChange={(e) => this.handleAdd_inputFieldNameChange(e, index, "fieldValue")}
                  />
                </div>

              </div>

            </div>
            <div className="uploadnocol deleteinputField  ">
              {
                index != 0 && this.state.informationStatus != "lock" ? <button className="btn X-btn d-block ml-1 my-2 maindeletebtn" onClick={() => this.deleteInputField(index)}><img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Path_21243.svg"}
                /> Delete</button> : <button className="btn X-btn d-block ml-1 mt-1 invisible"><span className="text-white">Delete</span></button>
              }

            </div>
          </div>
        </div>

      );
    });

  };

  handleAdd_DocNameChange = (e) => {
    this.setState(
      {
        Add_DocName: e.target.value,
      },
      () => {
        if (this.state.Add_DocName.trim().length > 0) {
          this.setState({
            IsAddFileDisabled: false,
          });
        } else {
          this.setState({
            IsAddFileDisabled: true,
          });
        }
      }
    );
  };

  handleAdd_inputFieldNameChange = (e, index, type) => {
    if (type == "fieldName") {
      this.state.inputFieldsUnlock[index].inputFieldName = e.target.value
      let fieldName = this.state.inputFieldsUnlock
      this.setState(
        {

          Add_inputFieldName: e.target.value,
          inputFieldsUnlock: fieldName
        },
        () => {
          if (this.state.Add_inputFieldName.trim().length > 0 && this.state.Add_inputFieldValue.trim().length > 0) {
            this.setState({
              IsAddinputDisabled: false,
            });
          } else {
            this.setState({
              IsAddinputDisabled: true,
            });
          }
        }
      );
    } else {
      this.state.inputFieldsUnlock[index].inputFieldValue = e.target.value
      let ValueName = this.state.inputFieldsUnlock
      this.setState(
        {
          Add_inputFieldValue: e.target.value,
          inputFieldsUnlock: ValueName
        },
        () => {
          if (this.state.Add_inputFieldValue.trim().length > 0 && this.state.Add_inputFieldName.trim().length > 0) {
            this.setState({
              IsAddinputDisabled: false,
            });
          } else {
            this.setState({
              IsAddinputDisabled: true,
            });
          }
        }
      );
    }

  };


  handleAddFileBtn = (FileIndex) => {
    const { Add_DocName, additionalFiles } = this.state;

    let prevFile = additionalFiles[FileIndex];
    let NameOfPrevFile = Object.keys(prevFile)[0];
    let valueOffile = prevFile[Object.keys(prevFile)[0]];

    if (FileIndex == undefined) {
      this.showNotification("empty fields");
    } else if (Add_DocName.trim() == "") {
      this.showNotification("Required fields cannot be empty!");
    } else if (valueOffile == null) {
      this.showNotification("Choose a file");
    } else {
      this.setState(
        {
          additionalFiles: [...this.state.additionalFiles, ""],
          Add_DocName: "",
          IsAddFileDisabled: true,
        },
        () => {
          $(".additionalInput").attr("disabled", false);
        }
      );
    }
  };

  handleAddFileBtn2 = (FileIndex) => {
    const { Add_inputFieldName, inputFieldsUnlock, Add_inputFieldValue } = this.state;

    if (FileIndex == undefined) {
      this.showNotification("empty fields");
    } else if (inputFieldsUnlock[additionalinputFieldIndex].inputFieldName.trim() == "" || inputFieldsUnlock[additionalinputFieldIndex].inputFieldName.trim() == "") {
      this.showNotification("Required fields cannot be empty!");
    } else {
      this.setState(
        {
          inputFieldsUnlock: [...inputFieldsUnlock, { inputFieldName: "", inputFieldValue: "", inputFieldtype: "general" }],
          Add_inputFieldName: "",
          IsAddinputDisabled: true,
        },
        () => {
          $(".additionalInput2").attr("disabled", false);
        }
      );

    }
  };

  // checkProceed
  // checkRemainingReuploads = () => {
  //   if (
  //     PANCardStatus != "reupload" &&
  //     AddressProofStatus != "reupload" &&
  //     MsmeStartupCertificateStatus != "reupload" &&
  //     ProofOfTMStatus != "reupload" &&
  //     BrandLogoStatus != "reupload" &&
  //     mainstatusVar == "false"
  //   ) {
  //     AnyReuploadOccured = "No";
  //   } else {
  //     $(".proceednowbtn").show();
  //     AnyReuploadOccured = "Yes";
  //   }
  // };

  checkhandleProceedBtn = () => {
    additionalStatusArrayCheck = this.statusCheckFunction(
      "reupload",
      this.state.additionalFiles
    );


    if (this.state.additionalFiles.length > 0 && additionalStatusArrayCheck == undefined) {
      proceednowforword = "success"
      $(".proceednowbtn").attr("disabled", false);
    }
    else {
      proceednowforword = "fail"
      $(".proceednowbtn").attr("disabled", true);
    }
  };


  handlesave = () => {
    let moveforward = true
    let inputFields = this.state.inputFieldsUnlock
    if (this.state.inputFieldsUnlock != []) {
      let i;
      for (i in inputFields) {
        let keys = inputFields[i].inputFieldName.trim()
        let Values = inputFields[i].inputFieldValue.trim()
        if (inputFields[i].inputFieldName != "") {
          inputFields[i].inputFieldName = keys
        }
        if (inputFields[i].inputFieldValue != "") {
          inputFields[i].inputFieldValue = Values
        }

        if (i != 0 && (inputFields[i].inputFieldName.trim() == "" || inputFields[i].inputFieldValue.trim() == "")) {
          this.showNotification("Required fields can not be empty!");
          moveforward = false
        }
      }
      if (moveforward == true) {
        let proceedlocalarray = this.state.inputFieldsUnlock
        if (Object.values(this.state.inputFieldsUnlock[0])[0].trim() == "" && Object.values(this.state.inputFieldsUnlock[0])[1].trim() == "") {
          this.showNotification("Required fields can not be empty!");
        }
        else {
          $(".savebtn").attr("disabled", true);
          axios.post(process.env.REACT_APP_API_BASE_URL + "p=preLockService",
            {
              userId: Decuserid,
              requestType: temp,
              agentId: Decagentid,
              serviceId: Decserviceid,
              serviceName: Decservicename,
              serviceType: this.state.serviceType,
              FY: "",
              Month: "",
              inputFields: proceedlocalarray
            },
            {
              headers: {
                Authkey: process.env.REACT_APP_API_KEY,
                "App-Token-Access": Decjwttoken,
              },
              auth: {
                username: process.env.REACT_APP_API_USERNAME,
                password: process.env.REACT_APP_API_PASSWORD,
              },
            }
          )
            .then((res) => {
              $(".savebtn").attr("disabled", false);
              if (res.data.code == "200" && res.data.status == "success") {
                this.showNotification(res.data.message);
              } else if (
                res.data.code == "201" &&
                res.data.status == "invalidauth"
              ) {
                this.logout();
              } else if (res.data.code == "201" && res.data.status == "timeOut") {
                localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
                this.props.history.push({
                  pathname: "/dashboard",
                  state: { timeout: true },
                });
              } else {
                this.showNotification(res.data.message);
              }
            })
            .catch((err) => { })
        };
      }
      else {
        this.showNotification("Required fields can not be empty!");
      }
    }
  }



  handleProceedBtn = () => {
    let moveforward = true
    this.checkhandleProceedBtn();

    let inputFields = this.state.inputFieldsUnlock


    if (this.state.inputFieldsUnlock != []) {
      let i;
      for (i in inputFields) {
        let keys = inputFields[i].inputFieldName.trim()
        let Values = inputFields[i].inputFieldValue.trim()


        if (inputFields[i].inputFieldName != "") {
          inputFields[i].inputFieldName = keys
        }
        if (inputFields[i].inputFieldValue != "") {
          inputFields[i].inputFieldValue = Values
        }

        if (i != 0 && (inputFields[i].inputFieldName.trim() == "" || inputFields[i].inputFieldValue.trim() == "")) {
          this.showNotification("Required fields can not be empty!");
          moveforward = false
        }
      }
    }
    if (proceednowforword == "success" && moveforward == true) {
      $(".proceednowbtn").attr("disabled", true);
      let proceedlocalarray = this.state.inputFieldsUnlock
      if (Object.values(this.state.inputFieldsUnlock[0])[0].trim() == "" && Object.values(this.state.inputFieldsUnlock[0])[1].trim() == "") {
        this.setState(
          {
            inputFieldsUnlock: []
          })
        $(".addinputbtn").hide();
        proceedlocalarray = []
      }



      axios.post(process.env.REACT_APP_API_BASE_URL + "p=lockService",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          FY: "",
          Month: "",
          inputFields: proceedlocalarray
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.showNotification(res.data.message);
            this.getserviceInformationApi();
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else {
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => { })
    }
    else {
      this.showNotification("Required fields can not be empty!");
    }

  }

  backbutton = () => {
    this.props.history.push({
      pathname: `/myuser/${Decuserid}/userservices`,
    });
  };
  handleMonthChange = (e) => {
    $(".gsrtf-main-content").show();
    $(".gsrtf-select-img").hide();
    $(".seecommentsbtn").show();
    $(".seecommentsbtn").attr("disabled", false);
    $(".disabledBtn_cmt").attr("disabled", true);
    $(".hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
    mainmonth = e.target.value;
    this.getserviceInformationApi();
    this.setState({
      SelectedMonth: e.target.value,
    });
  };
  ShowCurrentYearsMonth = () => {
    const { Fmonths } = this.state;
    let diff = Currmonth - 4;
    if (diff === -1) {
      this.setState(
        {
          data: Fmonths.slice(0, 12),
          SelectedMonth: Fmonths[11],
        },
        () => {
          if (firsttime != 0) {
            this.state.data.unshift("select");
            this.setState({
              SelectedMonth: "select",
            });
            $(".gsrtf-main-content").hide();
            $(".gsrtf-select-img").show();
          } else if (firsttime == 0) {
            firsttime = firsttime + 1;
          }
        }
      );
    } else if (diff === -2) {
      this.setState(
        {
          data: Fmonths.slice(0, 11),
          SelectedMonth: Fmonths[10],
        },
        () => {
          if (firsttime != 0) {
            this.state.data.unshift("select");
            this.setState({
              SelectedMonth: "select",
            });
            $(".gsrtf-main-content").hide();
            $(".gsrtf-select-img").show();
          } else if (firsttime == 0) {
            firsttime = firsttime + 1;
          }
        }
      );
    } else if (diff === -3) {
      this.setState(
        {
          data: Fmonths.slice(0, 10),
          SelectedMonth: Fmonths[9],
        },
        () => {
          if (firsttime != 0) {
            this.state.data.unshift("select");
            this.setState({
              SelectedMonth: "select",
            });
            $(".gsrtf-main-content").hide();
            $(".gsrtf-select-img").show();
          } else if (firsttime == 0) {
            firsttime = firsttime + 1;
          }
        }
      );
    } else if (diff >= 0) {
      this.setState(
        {
          data: Fmonths.slice(0, diff + 1),
          SelectedMonth: Fmonths[diff],
        },
        () => {
          if (firsttime != 0) {
            this.state.data.unshift("select");
            this.setState({
              SelectedMonth: "select",
            });
            $(".gsrtf-main-content").hide();
            $(".gsrtf-select-img").show();
          } else if (firsttime == 0) {
            firsttime = firsttime + 1;
          }
        }
      );
    }
  };

  showLastYearsMonth = () => {
    const { Fmonths } = this.state;
    this.setState(
      {
        data: Fmonths.slice(0, 12),
      },
      () => {
        this.state.data.unshift("select");
        this.setState({
          SelectedMonth: "select",
        });
        $(".gsrtf-main-content").hide();
        $(".gsrtf-select-img").show();
      }
    );
  };
  monthsToBeShown = () => {
    const { data } = this.state;

    return data.map((month, index) => {
      if (month == "select") {
        return (
          <option key={month} disabled value={month}>
            Select
          </option>
        );
      } else if (month == "Apr") {
        return (
          <option key={month} value={month}>
            April
          </option>
        );
      } else if (month == "May") {
        return (
          <option key={month} value={month}>
            May
          </option>
        );
      } else if (month == "Jun") {
        return (
          <option key={month} value={month}>
            June
          </option>
        );
      } else if (month == "Jul") {
        return (
          <option key={month} value={month}>
            July{" "}
          </option>
        );
      } else if (month == "Aug") {
        return (
          <option key={month} value={month}>
            August
          </option>
        );
      } else if (month == "Sep") {
        return (
          <option key={month} value={month}>
            September
          </option>
        );
      } else if (month == "Oct") {
        return (
          <option key={month} value={month}>
            October
          </option>
        );
      } else if (month == "Nov") {
        return (
          <option key={month} value={month}>
            November
          </option>
        );
      } else if (month == "Dec") {
        return (
          <option key={month} value={month}>
            December
          </option>
        );
      } else if (month == "Jan") {
        return (
          <option key={month} value={month}>
            {" "}
            January
          </option>
        );
      } else if (month == "Feb") {
        return (
          <option key={month} value={month}>
            February
          </option>
        );
      } else if (month == "Mar") {
        return (
          <option key={month} value={month}>
            March
          </option>
        );
      }
    });
  };

  handledropDownValueChange = (e) => {
    $("#volvo").attr("disabled", true);
    $(".proceednowbtn").attr("disabled", true);
    this.setState(
      {
        dropDownValue: e.target.value,
      },
    );
  };
  handleYearChange = (e) => {
    e.preventDefault();
    this.setState(
      {
        SelectedYear: e.target.value,
      },
      () => {
        $(".seecommentsbtn").show();
        $(".seecommentsbtn").attr("disabled", false);
        $(".disabledBtn_cmt").attr("disabled", true);
        $(".hidecommentsbtn").hide();
        $(".TDRightSide").hide();
        $(".commentimg-section").show();
        this.getserviceInformationApi();
      }
    );
  };

  requiredDocumentbtn = () => {
    $("#docModal").modal({ backdrop: "static", keyboard: false });
    $("#docModal").modal("show")
  }

  handletimeline = () => {


    $("#timeline_btnloader").show();
    axios.post(
      process.env.REACT_APP_API_BASE_URL + "p=timeLine",
      {
        agentId: Decagentid,
        userId: Decuserid,
        serviceId: Decserviceid,
      },
      {
        headers: {
          Authkey: process.env.REACT_APP_API_KEY,
          "App-Token-Access": Decjwttoken,
        },
        auth: {
          username: process.env.REACT_APP_API_USERNAME,
          password: process.env.REACT_APP_API_PASSWORD,
        },
      }
    )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {

          if (res.data.timeLine.length > 0) {
            let length = res.data.timeLine.length;
            let ftimeline = res.data.timeLine.filter((items, index) => {
              return (index <= this.state.timelcount)
            })
            this.setState({
              filtertimel: ftimeline,
              timelcount: this.state.timelcount + 3,
              Timeline: res.data.timeLine,
              Timelinedata: true,
            });
            this.setState({

            });
            // .splice(this.state.timelcount,length)
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
  }

  handlemoretimeline = () => {

    let ftimeline = this.state.Timeline.filter((items, index) => {
      return (index <= this.state.timelcount)
    })
    this.setState({
      filtertimel: ftimeline,
      timelcount: this.state.timelcount + 3
    });

  }

  completerender() {
    if (this.state.deliveredDocuments.length > 0) {
      return this.state.deliveredDocuments.map((activedoc, index) => {
        return (
          <div className="displayflex documentmaincard" key={index}>
            <div>
              <img
                src={process.env.PUBLIC_URL + "/img/Path 247.svg"}
                className="docutypeimg"
              />
            </div>
            <div className="docucontdiv">
              <div className="row">
                <div className="col-md-6">
                  <p className="nameofdoc">{activedoc.documentName}</p>
                  <p className="datesenttext">
                    Date received:-{" "}
                    <span className="datesentspan">{activedoc.datesent}</span>
                  </p>
                </div>
                <div className="col-md-6">
                  <div className="fl_right">
                    <div className="displayflex">
                      <div className="viewbtndivpadding">
                        <a
                          href={activedoc.documentLink}
                          target="_blank"
                          className="viewbtn"
                        >
                          View{" "}
                          <img
                            src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                          />
                        </a>
                      </div>
                      <div>
                        <a
                          href={activedoc.documentLink}
                          className="download_btn"
                          target="_blank"
                          download
                        >
                          Download{" "}
                          <img
                            src={
                              process.env.PUBLIC_URL + "/img/download_icon.svg"
                            }
                            alt="download"
                          />
                        </a>
                      </div>
                    </div>
                    <p className="docushareviatext">
                      Share via:{" "}
                      <a
                        onClick={() =>
                          this.whatsapp(
                            activedoc.documentName,
                            activedoc.documentLink
                          )
                        }
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/whatsapp.svg"}
                          className="docushareviawhats"
                        />
                      </a>{" "}
                      <a
                        onClick={() =>
                          this.email(
                            activedoc.documentName,
                            activedoc.documentLink
                          )
                        }
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/mail.svg"}
                          className="docushareviamail"
                        />
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.deliveredDocuments.length == 0) {
      return (
        <center className="myservicequiteimgcenter">
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
            className="emptydeliveredDocuments"
          />
        </center>
      );
    }
  }


  render() {
    const { Fyear, Preyear } = this.state;
    // if (
    //   localStorage.getItem("gglltakoinoeenl") ==
    //   process.env.REACT_APP_LOGIN_KEY &&
    //   localStorage.getItem("toeljgtkewlna") != null &&
    //   localStorage.getItem("qazxswedcvfrtgb") != null
    // ) {
    return (
      <div className="cont">
        <div id="notifContainer"></div>

        <Helmet>
          <link
            rel="stylesheet"
            href={process.env.PUBLIC_URL + "/css/select2.css"}
          />
        </Helmet>

        <Header />

        <img
          alt="loading..."
          src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
          className="sidebaropenicon"
        />
        <img
          onClick={this.backbutton}
          src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
          className="sidebarbackicon"
        />
        <img
          alt="loading..."
          src={process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"}
          className="sidebarexpandicon"
        />

        <div className="overlay1"></div>
        <div className="sidebar sidebarshift">
          <div className="side_div_2 sidebar_txt active">
            <Link to="/dashboard">Overview</Link>
          </div>
          <div className="side_div_1 sidebar_txt">
            <Link to="/myservices">My Services</Link>
          </div>
          <div className="side_div_1 sidebar_txt">
            <a href="#">Payments Invoices</a>
          </div>
          <div className="side_div_1 sidebar_txt">
            <a href="#">Documents Record</a>
          </div>
          <div className="side_div_1 sidebar_txt">
            <a href="#">Add service</a>
          </div>
          <div></div>
        </div>
        <div className="arrow-left2 visibilityhidden"></div>
        <div className="white_div expandwhite_div">
          <div className="serviceoverlay">
            <div className="serviceoverlayinside">
              <center>
                <img
                  src={process.env.PUBLIC_URL + "/img/loader.gif"}
                  className="serviceloader"
                />
              </center>
            </div>
          </div>
          <div className="white_box">
          {this.state.referralPartner ? 
            <div className="uploaddocu">
              <div className="row">
                {/* <div className="myservicesortbyfilterbystatus mt-1 ml-1"></div> */}

                <div className="col-lg-9 mt-3">
                  <div className="row">
                    <div className="col-md-6">
                    <div className="inner-header d-flex justify-content-between">
                          <div className="slider-navigation-tab">
                            <header className="tabs-nav position-relative">
                              <ul className="d-flex slider-navigation-tab-ul">
                                <li
                                  className="active slider-navigation-tab-li"
                                  id="activeTab"
                                >
                                  <a href="#tab1" className="px-2 py-1">
                                    Information given
                                  </a>
                                </li>
                                <li
                                  className="slider-navigation-tab-li"
                                  id="completedTab"
                                >
                                  <a href="#tab2" className="px-2 py-1">
                                    Deliverables
                                  </a>
                                </li>
                                <hr className="slider-navigation-tab-vertical-line" />
                                <div className="sliderpage-slider"></div>
                              </ul>
                            </header>
                          </div>
                        </div>
                      {/* <p className="uploadtext">Upload Information :-</p> */}

                    </div>
                    {/* {this.state.dropdownType == 1 ?
                      <>
                      </>
                      : this.state.dropdownType == 2 ?
                        <div className="col-md-6 selectcateselectpadding">
                          <div className="fl_right">
                            <select
                              className="selectfinancialyear"
                              value={this.state.SelectedYear}
                              onChange={this.handleYearChange}
                            >
                              <option value={Preyear}>F.Y. {Preyear}</option>
                              <option value={Fyear}>F.Y. {Fyear}</option>
                            </select>
                            <select
                              className="selectfinancialmonth"
                              value={this.state.SelectedMonth}
                              onChange={this.handleMonthChange}
                            >
                              {this.monthsToBeShown()}
                            </select>
                          </div>
                        </div>
                        : this.state.dropdownType == 3 ?
                          <div className="col-md-6 d-flex  selectcateselectpadding justify-content-lg-end justify-content-center">
                            <select
                              className="selectfinancialyear"
                              value={this.state.SelectedYear}
                              onChange={this.handleYearChange}
                            >
                              <option value={this.state.Preyear}>
                                A.Y. {this.state.Preyear}
                              </option>
                              <option value={this.state.Fyear}>
                                A.Y. {this.state.Fyear}
                              </option>
                            </select>
                          </div>
                          : ""
                    } */}
                  </div>
                  </div>
                  </div>

                  <div className="tabs-content">
                  <div id="tab1" className="tab-content">
                    <div className="row">
                      {/* <div className="myservicesortbyfilterbystatus mt-1 ml-1"></div> */}
                      <div className="col-lg-9 mt-3">
                  <div className="uploadscrolldiv uploadscrolldiv1">
                    <div className="uploadscrollcardinside">
                      <div id="additionalDocumentDiv">
                        <div className="displayflex">
                          <div className="uploadnocol">
                            <p className="uploadadditionalsidetext visibilityhidden">
                              1&#41;
                            </p>
                          </div>
                          <div className="uploadadditionaldatacol underproc ">
                            <p className="uploadadditionalnormaltext">
                              Documents:-
                            </p>
                            <a className="btn X-btn d-block w-auto ml-auto px-2 docModalsamplebtn reqbtnhov" target="_blank" href={this.state.documentlinklist}>Required Documents list</a>
                          </div>

                        </div>
                        {this.additionalFilesArea()}

                        <div className="displayflex">
                          <div className="uploadnocol">
                            <p className="uploadadditionalsidetext visibilityhidden">
                              1&#41;
                            </p>
                          </div>
                          <div className="uploadadditionaldatacol">
                            <button
                              className="adddocumentbtn adddocumentbtnmain"
                              id="Adddocumentbtn"
                              onClick={() =>
                                this.handleAddFileBtn(additionalFilesIndex)
                              }
                            >
                              Add document{" "}
                              <img
                                src={
                                  process.env.PUBLIC_URL + "/img/Path 19524.svg"
                                }
                              />
                            </button>
                          </div>
                        </div>
                      </div>

                      <div id="additionalDocumentDiv">
                        <div className="displayflex">
                          <div className="uploadnocol">
                            <p className="uploadadditionalsidetext visibilityhidden">
                              1&#41;
                            </p>
                          </div>
                          <div className="uploadadditionaldatacol">
                            <p className="uploadadditionalnormaltext">
                            </p>
                          </div>
                        </div>
                        {this.additionalFilesArea2()}

                        <div className="displayflex">
                          <div className="uploadnocol">
                            <p className="uploadadditionalsidetext visibilityhidden">
                              1&#41;
                            </p>
                          </div>
                          <div className="uploadadditionaldatacol">
                            <button
                              className="adddocumentbtn addinputbtn adddocumentbtnmain"
                              id="Adddocumentbtn"
                              onClick={() =>
                                this.handleAddFileBtn2(additionalinputFieldIndex)
                              }
                            >
                              Add Input Field{" "}
                              <img
                                src={
                                  process.env.PUBLIC_URL + "/img/Path 19524.svg"
                                }
                              />
                            </button>
                            <button
                              className="savebtn addinputbtn adddocumentbtnmain"
                              id="Adddocumentbtn"
                              onClick={() =>
                                this.handlesave()
                              }
                            >
                              Save <img
                                src={
                                  process.env.PUBLIC_URL + "/img/save.png"
                                }
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <center>
                    <button
                      className="proceednowbtn proceednowbtn1"
                      onClick={this.handleProceedBtn}
                    >
                      Proceed now{" "}
                      <img
                        alt="loading..."
                        src={process.env.PUBLIC_URL + "/img/tick.svg"}
                      />
                    </button>
                  </center>
                </div>

                <div className="col-lg-3 colbtwborder">
                  <div className="commentimg-section">
                    <SupportDetails
                      name={this.state.Name}
                      role={"Relationship Manager"}
                      contact={this.state.Contact}
                    />

                    <div className='supportDetails'>
                      <img
                        src={process.env.PUBLIC_URL + "/img/24-7_Support.png"}

                        alt="image"
                      />
                      <div>
                        <h5>{supportName}</h5>
                        <p>Agent Support Executive</p>
                        <span><b>Contact: </b> +91 {supportContact}</span>
                        <p className='mb-1'><b>Working Hours: </b> 11:00 AM - 06:30 PM</p>
                      </div>
                    </div>
                    <div className="mm">
                      <center>
                        <a
                          className="video-btn"
                          data-toggle="modal"
                          data-src="https://www.youtube.com/embed/fiC-nHoFJEY"
                          data-target="#youtubeModal"
                        >
                          <img
                            src={process.env.PUBLIC_URL + "/img/play.jpg"}
                            id="play"
                            className="play_img play_img2"
                            alt="image"
                          />
                        </a>
                      </center>
                    </div>

                    <button data-toggle="modal" onClick={this.handletimeline} data-src="" data-target="#timeline" className="dashBoardBtn mt-5 newbmar" >
                      Timeline
                    </button>

                    <p class="uploadinstr mt-2 uploddoc">You can also upload documents using Whatsapp on <a
                                  href="https://web.whatsapp.com/send?phone=917772877729&amp;text=Hello%20Team%20Legal251!"
                                  target="_blank"
                                >
                                  {" "}
                                  <img
                                    src={process.env.PUBLIC_URL + "/img/call.svg"}
                                    alt="call"
                                    className="callimg"
                                  />
                                </a> +91 77728-77729</p>

                  </div>
                  <div className="TDRightSide">
                    <div className="convheader">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/person.png"}
                          className="converpersonimg"
                        />
                      </div>
                      <div className="convheadercontdiv">
                        <p className="convservicename">
                          {this.state.Name}
                        </p>
                        <p className="convpersonname">Relationship Manager</p>
                        <p className="convuniquename">
                          Contact:{" "}
                          <span style={{ color: "#2d2d2d" }}>
                            {this.state.Contact}
                          </span>
                        </p>
                        <p className="convuniquename">
                          Service ID :{" "}
                          <span className="convuniquenamespan">
                            {Decserviceid}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="convmaindiv">
                      {this.renderCommenthistory()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="tab2" className="tab-content">
                    <div className="doucumainscrolldiv2">
                      <div className="doucumainscrolldivinside2">
                        {this.completerender()}
                      </div>
                    </div>
                  </div>
          </div>
        </div>
        :<center>
        <img alt="notaccess" className="notuploaddoc" src={process.env.PUBLIC_URL + "/img/Warning-rafiki.svg"} />

        <h4 class="my-linkmodal-text youcant">You can't upload document because you're Referal Partner. Please contact support executive for any help and more information.</h4>
        </center>
      }
        </div>
        </div>
        <SupportDetailsMobile name={this.state.Name} role={"Relationship Manager"} contact={this.state.Contact} />

        <Footer />

        <div className="modal fade"
          id="timeline"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true">

          <div
            className="modal-dialog modal-dialog-centered yt_modal "
            role="document"
          >
            <div className="modal-content videoModal">
              <div className="modal-body modal_b mt-4 ">
                <button
                  type="button"
                  className="close close1 close1tl closeMain mr-2 dbtime"
                  data-dismiss="modal"
                  aria-label="Close"

                >
                  <span aria-hidden="true">&times;</span>
                </button>

                {this.state.Timelinedata ?


                  this.state.Timeline.length > 0 ?

                    <div class="timeline scrollbtimel">
                      <div class="container">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="timeline-container">
                              <div class="timeline-end">
                                <button id="load-more" onClick={this.handlemoretimeline} >More..</button>
                              </div>
                              <div class="timeline-continue">

                                {this.state.filtertimel.map((item, index) => {

                                  if (index % 2 == 0) {
                                    return (

                                      <div class="row timeline-left">
                                        <div class="col-md-6 d-md-none d-block">
                                          <p class="timeline-date">

                                          </p>
                                        </div>
                                        <div class="col-md-6">
                                          <div class="timeline-box">
                                            <div class="timeline-icon d-md-none d-block">
                                              <i class="fa fa-business-time"></i>
                                            </div>

                                            <div class="timeline-text">
                                              <p class="mainSpan">{item.time}</p>
                                              <h3>{item.timeLine}</h3>
                                              <p class="mode_p">Date : <span class="mode_span"> {item.date}</span></p>
                                              <h5 className="tlremark">{item.remark}</h5>
                                              {/* <p class="mode_p">
                                                      <span class="mode_span">
                                                          {{$TimeLine->TimeLine}}
                                                      </span>
                                                  </p>  */}


                                            </div>

                                            <div class="timeline-icon d-md-block d-none">
                                              <i class="fa fa-business-time"></i>
                                            </div>

                                          </div>
                                        </div>
                                        <div class="col-md-6 d-md-block d-none">
                                          <p class="timeline-date">

                                          </p>
                                        </div>
                                      </div>

                                    )
                                  } else {
                                    return (
                                      <div class="row timeline-right">
                                        <div class="col-md-6">
                                          <p class="timeline-date">
                                          </p>
                                        </div>
                                        <div class="col-md-6">
                                          <div class="timeline-box">
                                            <div class="timeline-icon">
                                              <i class="fa fa-gift"></i>
                                            </div>

                                            <div class="timeline-text">
                                              <p class="mainSpan">{item.time}</p>
                                              {/* <p class="">khkh</p> */}

                                              <h3>{item.timeLine}</h3>
                                              <p class="mode_p">Date : <span class="mode_span">{item.date} </span></p>
                                              <h5 className="tlremark">{item.remark}</h5>
                                              {/* <p class="mode_p">
                                                                              <span class="mode_span">
                                                                                  
                                                                              </span>
                                                                          </p> */}


                                            </div>

                                          </div>
                                        </div>
                                      </div>
                                    )
                                  }




                                })}




                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    <center className="">
                      <img
                        alt="loading..."
                        src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
                        className="emptydeliveredDocuments"
                      />
                    </center>

                  : null
                }
                <center>
                  <img
                    className="timelineloder"
                    src={process.env.PUBLIC_URL + "/img/loader.gif"}
                    alt="loading"
                  />
                </center>
              </div>
            </div>
          </div>

        </div>

        <div className="modal fade" id="docModal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content requiredDocumentModal">
              <div className="modal-header border-bottom-0">
                <h5 className="text-center w-100 uploadtext">Required Documents</h5>
              </div>

              <div className="modal-body uploadpopupbody">
                <div className="displayflex px-3">
                  <div className="uploadnocol">
                    <p className="uploadnormaltext">1)</p>
                  </div>
                  <div className="uploaddatacol">
                    <div className="row">
                      <div className="col-md-7">
                        <p className="uploadnormaltext">
                          GST Registration Certificate
                          <sup className="requireddocstar">*</sup>
                        </p>
                        <p className="uploadinstr">Soft copy</p>
                      </div>
                      <div className="col-md-5">
                        <div className="fl_right">
                          <a
                            href={this.state.GSTRegistrationCertificate1Link}
                            className="hidecommentsbtn d-block w-auto mt-2 px-2 docModalsamplebtn"
                            id="viewDocLink_1"
                            target="_blank"
                          >
                            View sample document{" "}
                            <img
                              className="viewDocLinkimg"
                              src={process.env.PUBLIC_URL + "/img/whitesampleicon.svg"}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="displayflex px-3">
                  <div className="uploadnocol">
                    <p className="uploadnormaltext">1)</p>
                  </div>
                  <div className="uploaddatacol">
                    <div className="row">
                      <div className="col-md-7">
                        <p className="uploadnormaltext">
                          GST Registration Certificate
                          <sup className="requireddocstar">*</sup>
                        </p>
                        <p className="uploadinstr">Soft copy</p>
                      </div>
                      <div className="col-md-5">
                        <div className="fl_right">
                          <a
                            href={this.state.GSTRegistrationCertificate1Link}
                            className="hidecommentsbtn d-block w-auto mt-2 px-2 docModalsamplebtn"
                            id="viewDocLink_1"
                            target="_blank"
                          >
                            View sample document{" "}
                            <img
                              className="viewDocLinkimg"
                              src={process.env.PUBLIC_URL + "/img/whitesampleicon.svg"}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="displayflex px-3">
                  <div className="uploadnocol">
                    <p className="uploadnormaltext">1)</p>
                  </div>
                  <div className="uploaddatacol">
                    <div className="row">
                      <div className="col-md-7">
                        <p className="uploadnormaltext">
                          GST Registration Certificate
                          <sup className="requireddocstar">*</sup>
                        </p>
                        <p className="uploadinstr">Soft copy</p>
                      </div>
                      <div className="col-md-5">
                        <div className="fl_right">
                          <a
                            href={this.state.GSTRegistrationCertificate1Link}
                            className="hidecommentsbtn d-block w-auto mt-2 px-2 docModalsamplebtn"
                            id="viewDocLink_1"
                            target="_blank"
                          >
                            View sample document{" "}
                            <img
                              className="viewDocLinkimg"
                              src={process.env.PUBLIC_URL + "/img/whitesampleicon.svg"}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="displayflex px-3">
                  <div className="uploadnocol">
                    <p className="uploadnormaltext">1)</p>
                  </div>
                  <div className="uploaddatacol">
                    <div className="row">
                      <div className="col-md-7">
                        <p className="uploadnormaltext">
                          GST Registration Certificate
                          <sup className="requireddocstar">*</sup>
                        </p>
                        <p className="uploadinstr">Soft copy</p>
                      </div>
                      <div className="col-md-5">
                        <div className="fl_right">
                          <a
                            href={this.state.GSTRegistrationCertificate1Link}
                            className="hidecommentsbtn d-block w-auto mt-2 px-2 docModalsamplebtn"
                            id="viewDocLink_1"
                            target="_blank"
                          >
                            View sample document{" "}
                            <img
                              className="viewDocLinkimg"
                              src={process.env.PUBLIC_URL + "/img/whitesampleicon.svg"}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="displayflex px-3">
                  <div className="uploadnocol">
                    <p className="uploadnormaltext">1)</p>
                  </div>
                  <div className="uploaddatacol">
                    <div className="row">
                      <div className="col-md-7">
                        <p className="uploadnormaltext">
                          GST Registration Certificate
                          <sup className="requireddocstar">*</sup>
                        </p>
                        <p className="uploadinstr">Soft copy</p>
                      </div>
                      <div className="col-md-5">
                        <div className="fl_right">
                          <a
                            href={this.state.GSTRegistrationCertificate1Link}
                            className="hidecommentsbtn d-block w-auto mt-2 px-2 docModalsamplebtn"
                            id="viewDocLink_1"
                            target="_blank"
                          >
                            View sample document{" "}
                            <img
                              className="viewDocLinkimg"
                              src={process.env.PUBLIC_URL + "/img/whitesampleicon.svg"}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="displayflex px-3">
                  <div className="uploadnocol">
                    <p className="uploadnormaltext">1)</p>
                  </div>
                  <div className="uploaddatacol">
                    <div className="row">
                      <div className="col-md-7">
                        <p className="uploadnormaltext">
                          GST Registration Certificate
                          <sup className="requireddocstar">*</sup>
                        </p>
                        <p className="uploadinstr">Soft copy</p>
                      </div>
                      <div className="col-md-5">
                        <div className="fl_right">
                          <a
                            href={this.state.GSTRegistrationCertificate1Link}
                            className="hidecommentsbtn d-block w-auto mt-2 px-2 docModalsamplebtn"
                            id="viewDocLink_1"
                            target="_blank"
                          >
                            View sample document{" "}
                            <img
                              className="viewDocLinkimg"
                              src={process.env.PUBLIC_URL + "/img/whitesampleicon.svg"}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="displayflex px-3">
                  <div className="uploadnocol">
                    <p className="uploadnormaltext">1)</p>
                  </div>
                  <div className="uploaddatacol">
                    <div className="row">
                      <div className="col-md-7">
                        <p className="uploadnormaltext">
                          GST Registration Certificate
                          <sup className="requireddocstar">*</sup>
                        </p>
                        <p className="uploadinstr">Soft copy</p>
                      </div>
                      <div className="col-md-5">
                        <div className="fl_right">
                          <a
                            href={this.state.GSTRegistrationCertificate1Link}
                            className="hidecommentsbtn d-block w-auto mt-2 px-2 docModalsamplebtn"
                            id="viewDocLink_1"
                            target="_blank"
                          >
                            View sample document{" "}
                            <img
                              className="viewDocLinkimg"
                              src={process.env.PUBLIC_URL + "/img/whitesampleicon.svg"}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="displayflex px-3">
                  <div className="uploadnocol">
                    <p className="uploadnormaltext">1)</p>
                  </div>
                  <div className="uploaddatacol">
                    <div className="row">
                      <div className="col-md-7">
                        <p className="uploadnormaltext">
                          GST Registration Certificate
                          <sup className="requireddocstar">*</sup>
                        </p>
                        <p className="uploadinstr">Soft copy</p>
                      </div>
                      <div className="col-md-5">
                        <div className="fl_right">
                          <a
                            href={this.state.GSTRegistrationCertificate1Link}
                            className="hidecommentsbtn d-block w-auto mt-2 px-2 docModalsamplebtn"
                            id="viewDocLink_1"
                            target="_blank"
                          >
                            View sample document{" "}
                            <img
                              className="viewDocLinkimg"
                              src={process.env.PUBLIC_URL + "/img/whitesampleicon.svg"}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="displayflex px-3">
                  <div className="uploadnocol">
                    <p className="uploadnormaltext">1)</p>
                  </div>
                  <div className="uploaddatacol">
                    <div className="row">
                      <div className="col-md-7">
                        <p className="uploadnormaltext">
                          GST Registration Certificate
                          <sup className="requireddocstar">*</sup>
                        </p>
                        <p className="uploadinstr">Soft copy</p>
                      </div>
                      <div className="col-md-5">
                        <div className="fl_right">
                          <a
                            href={this.state.GSTRegistrationCertificate1Link}
                            className="hidecommentsbtn d-block w-auto mt-2 px-2 docModalsamplebtn"
                            id="viewDocLink_1"
                            target="_blank"
                          >
                            View sample document{" "}
                            <img
                              className="viewDocLinkimg"
                              src={process.env.PUBLIC_URL + "/img/whitesampleicon.svg"}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="displayflex px-3">
                  <div className="uploadnocol">
                    <p className="uploadnormaltext">1)</p>
                  </div>
                  <div className="uploaddatacol">
                    <div className="row">
                      <div className="col-md-7">
                        <p className="uploadnormaltext">
                          GST Registration Certificate
                          <sup className="requireddocstar">*</sup>
                        </p>
                        <p className="uploadinstr">Soft copy</p>
                      </div>
                      <div className="col-md-5">
                        <div className="fl_right">
                          <a
                            href={this.state.GSTRegistrationCertificate1Link}
                            className="hidecommentsbtn d-block w-auto mt-2 px-2 docModalsamplebtn"
                            id="viewDocLink_1"
                            target="_blank"
                          >
                            View sample document{" "}
                            <img
                              className="viewDocLinkimg"
                              src={process.env.PUBLIC_URL + "/img/whitesampleicon.svg"}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="displayflex px-3">
                  <div className="uploadnocol">
                    <p className="uploadnormaltext">1)</p>
                  </div>
                  <div className="uploaddatacol">
                    <div className="row">
                      <div className="col-md-7">
                        <p className="uploadnormaltext">
                          GST Registration Certificate
                          <sup className="requireddocstar">*</sup>
                        </p>
                        <p className="uploadinstr">Soft copy</p>
                      </div>
                      <div className="col-md-5">
                        <div className="fl_right">
                          <a
                            href={this.state.GSTRegistrationCertificate1Link}
                            className="hidecommentsbtn d-block w-auto mt-2 px-2 docModalsamplebtn"
                            id="viewDocLink_1"
                            target="_blank"
                          >
                            View sample document{" "}
                            <img
                              className="viewDocLinkimg"
                              src={process.env.PUBLIC_URL + "/img/whitesampleicon.svg"}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="displayflex px-3">
                  <div className="uploadnocol">
                    <p className="uploadnormaltext">1)</p>
                  </div>
                  <div className="uploaddatacol">
                    <div className="row">
                      <div className="col-md-7">
                        <p className="uploadnormaltext">
                          GST Registration Certificate
                          <sup className="requireddocstar">*</sup>
                        </p>
                        <p className="uploadinstr">Soft copy</p>
                      </div>
                      <div className="col-md-5">
                        <div className="fl_right">
                          <a
                            href={this.state.GSTRegistrationCertificate1Link}
                            className="hidecommentsbtn d-block w-auto mt-2 px-2 docModalsamplebtn"
                            id="viewDocLink_1"
                            target="_blank"
                          >
                            View sample document{" "}
                            <img
                              className="viewDocLinkimg"
                              src={process.env.PUBLIC_URL + "/img/whitesampleicon.svg"}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="displayflex px-3">
                  <div className="uploadnocol">
                    <p className="uploadnormaltext">1)</p>
                  </div>
                  <div className="uploaddatacol">
                    <div className="row">
                      <div className="col-md-7">
                        <p className="uploadnormaltext">
                          GST Registration Certificate
                          <sup className="requireddocstar">*</sup>
                        </p>
                        <p className="uploadinstr">Soft copy</p>
                      </div>
                      <div className="col-md-5">
                        <div className="fl_right">
                          <a
                            href={this.state.GSTRegistrationCertificate1Link}
                            className="hidecommentsbtn d-block w-auto mt-2 px-2 docModalsamplebtn"
                            id="viewDocLink_1"
                            target="_blank"
                          >
                            View sample document{" "}
                            <img
                              className="viewDocLinkimg"
                              src={process.env.PUBLIC_URL + "/img/whitesampleicon.svg"}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="displayflex px-3">
                  <div className="uploadnocol">
                    <p className="uploadnormaltext">1)</p>
                  </div>
                  <div className="uploaddatacol">
                    <div className="row">
                      <div className="col-md-7">
                        <p className="uploadnormaltext">
                          GST Registration Certificate
                          <sup className="requireddocstar">*</sup>
                        </p>
                        <p className="uploadinstr">Soft copy</p>
                      </div>
                      <div className="col-md-5">
                        <div className="fl_right">
                          <a
                            href={this.state.GSTRegistrationCertificate1Link}
                            className="hidecommentsbtn d-block w-auto mt-2 px-2 docModalsamplebtn"
                            id="viewDocLink_1"
                            target="_blank"
                          >
                            View sample document{" "}
                            <img
                              className="viewDocLinkimg"
                              src={process.env.PUBLIC_URL + "/img/whitesampleicon.svg"}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="displayflex px-3">
                  <div className="uploadnocol">
                    <p className="uploadnormaltext">1)</p>
                  </div>
                  <div className="uploaddatacol">
                    <div className="row">
                      <div className="col-md-7">
                        <p className="uploadnormaltext">
                          GST Registration Certificate
                          <sup className="requireddocstar">*</sup>
                        </p>
                        <p className="uploadinstr">Soft copy</p>
                      </div>
                      <div className="col-md-5">
                        <div className="fl_right">
                          <a
                            href={this.state.GSTRegistrationCertificate1Link}
                            className="hidecommentsbtn d-block w-auto mt-2 px-2 docModalsamplebtn"
                            id="viewDocLink_1"
                            target="_blank"
                          >
                            View sample document{" "}
                            <img
                              className="viewDocLinkimg"
                              src={process.env.PUBLIC_URL + "/img/whitesampleicon.svg"}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
              <div className="d-flex justify-content-center">
                <button className="btn X-btn d-block my-2 docModalsamplebtn" onClick={() => $("#docModal").modal("hide")}>Close</button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade AddModal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content uploadpopupcontent">
              <div className="modal-header uploadmainpopupheader">
                <div className="uploadpopupheader">
                  <p className="popupdocunametext d-flex">
                    <div>
                      <img
                        src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                        className="popupdocumentimg"
                      />
                    </div>
                    <div className="pl-1">{this.state.fileName}</div>
                  </p>
                </div>
              </div>

              <div className="modal-body uploadpopupbody">
                <p className="uploadaddcomm">Add a comment (optional)</p>
                <input
                  type="text"
                  className="uploadpopupinput"
                  maxLength="250"
                  placeholder="Type here..."
                  value={this.state.comment}
                  onChange={this.handleComment}
                />
                <div className="modal_btns_div">
                  <div className="displayflex">
                    <div className="cancelbuttondiv">
                      <button
                        className="cancelbtn cancelbtn_7"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                    <button
                      className="upload_btn20 upload_btn20_7"
                      onClick={() =>
                        this.uploadFile(
                          additionalReuploadIndex,
                          additionalReuploadStatus
                        )
                      }
                    >
                      Upload
                      <img
                        src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                        alt="download"
                      />
                    </button>
                    <button className="upload_btnloader upload_btnloader_7">
                      <img
                        src={process.env.PUBLIC_URL + "/img/loader.gif"}
                        alt="loading"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="youtubeModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered yt_modal "
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body modal_b">
                <button
                  type="button"
                  className="close close1"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    className="embed-responsive-item"
                    id="video"
                    allowscriptaccess="always"
                    src="https://www.youtube.com/embed/fiC-nHoFJEY?&amp;modestbranding=1&amp;showinfo=0"
                    allowFullScreen="allowfullscreen"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    // } else {
    //   return <Redirect to="/" />;
    // }
  }
}
export default Activecustom;

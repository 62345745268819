import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "./Header";
import Footer from "./Footer";
import $, { data } from "jquery";
import axios from "axios";
import { Redirect } from "react-router";
import filterFn2 from "./TargetedFilterFcn";
import Sidebar from "./Sidebar";
var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);
let Decjwttoken;
let Decagentid;
const pending = "pending";
const complete = "complete";
const invaliddoc = "invaliddoc";
const review = "under review";
let baseUrl = process.env.REACT_APP_API_BASE_URL;
export class Documentrecordagent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mydata: [],
      mySearch: [],
      pageCount: 0,
      userid: "",
      latest: true,
      currentPage: 0,
      entry: 10,
      searchinputtype: "",
      searchinput: "",
      searchdone: false,
      filteredData: [],
      filter: false,
    };
  }
  rendersearch= () => {
    $(".filter-sort-btn").attr("disabled", true)
    $("#docrecord-loader").hide();
    return this.state.mySearch.map((data, index) => {
      return (
        <tr key={index}>
          <td scope="row" className="sno" data-type="number">
            {index + 1}
          </td>
          <td className="userid userid-td">{data.userId}</td>
          <td className="username">{data.username}</td>
          <td className="email">{data.emailverifySt == true ? <span className="text-success">{data.email}</span> : <span className="text-danger">{data.email}</span>}</td>
            <td className="contact ">{data.contactverifySt == true ? <span className="text-success">{data.contact}</span> : <span className="text-danger">{data.contact}</span>}</td>
          <td className="services">
            {data.services.length == 0 ? <span >-</span> : data.services.map((numbers, index) => {
              
              if (index == data.services.length - 1) {
                return <span key={index}>{numbers}</span>;
              } else {
                return <span key={index}>{numbers + ","}</span>;
              }
            })}
          </td>
          <td className="status">{data.status == null ? <span>-</span> : data.status}</td>
          <td className="details">
            <button
              className="agent__viewbtn mt-2"
              onClick={() => this.clickViewBtn(data)}
            >
              View <img src={process.env.PUBLIC_URL + "/img/Path 19498.svg"} />
            </button>
          </td>
        </tr>
      );
    });
  }
  fetchData = () => {
    $("#docrecord-loader").show();
    $("#docrecord-empty").hide();
    axios
      .post(
        `${baseUrl}p=myUser`,
        {
          agentId: Decagentid,
          pageCount: this.state.currentPage + 1,
          entry: this.state.entry,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        
        if (res.data.code == 200 && res.data.status == "success") {
          $("#docrecord-loader").hide();
          if (res.data.myUser.length == 0) {
            $("#docrecord-empty").show();
          }
          this.setState({ mydata: res.data.myUser });
          this.setState({ pageCount: res.data.totalpageCount });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          $("#docrecord-loader").hide();
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        
        $("#docrecord-loader").hide();
        this.showNotification("Something went wrong");
      });
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    $(document.body).css("background-color", "#2d2d2d");
    let mainagentid = localStorage.getItem("qazxswedcvfrtgb");
    Decagentid = encryptor.decrypt(mainagentid);
    let mainjwttoken = localStorage.getItem("toeljgtkewlna");
    Decjwttoken = encryptor.decrypt(mainjwttoken);
    this.fetchData();
    filterFn2("docRecordTable")
  };

  logout = () => {
    localStorage.removeItem("gglltakoinoeenl");
    localStorage.removeItem("toeljgtkewlna");
    localStorage.removeItem("qazxswedcvfrtgb");
    localStorage.removeItem("lshhihfnkff");
    this.props.history.push({
      pathname: `/`,
    });
  };
  searchdata = () => {
    $("#docrecord-empty").hide()
    axios
      .post(
        `${baseUrl}p=searchUser`,
        {
          agentId: Decagentid,
          type: this.state.searchinputtype.trim(),
          value: this.state.searchinput.trim(),
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        
        $("#searchfielddoc").removeAttr('disabled');
        if(res.data.code == "200" && res.data.status == "success"){
         
          this.setState({mySearch: res.data.myUser, searchdone: true})
          if(res.data.myUser.length == 0){
            $("#docrecord-empty").show()}
        }else {
          this.showNotification(res.data.message)
        }
      }).catch(err => {
        $("#searchfielddoc").removeAttr('disabled');
        this.showNotification("Something went wrong");
      })
  };
  handleSearchinput = (e) => { 
    $("#searchfielddoc").attr("disabled", "disabled")
    e.preventDefault();
    const contactNoregex = /^[6-9]\d{9}$/;
    const emailregex = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    
    if(this.state.searchinput.trim() != "" && this.state.searchinput.length < 100){
      $("#docrecord-loader").show();
    if (emailregex.test(this.state.searchinput)) {
      this.setState({ searchinputtype: "email" }, this.searchdata);
    } else if (contactNoregex.test(this.state.searchinput)) {
      
      this.setState({ searchinputtype: "contact" }, this.searchdata);
    } else if (isNaN(this.state.searchinput)) {
      var mySentence = this.state.searchinput.trim();
      mySentence=mySentence.replace(/\s+/g, " ");
      const words = mySentence.split(" ");
      
      for (let i = 0; i < words.length; i++) {
          words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
    var mywords= words.join(" ");
    this.setState({ searchinputtype: "username" });
      this.setState({ searchinput: mywords.trim() }, this.searchdata);
    } else if (!isNaN(this.state.searchinput)) {
      this.setState({ searchinputtype: "userId" }, this.searchdata);
    } else {
      this.showNotification("Invalid Search");
    }}    else { this.setState({searchdone: false, searchinput: ""});
    this.showNotification("No data found")
    $("#searchfielddoc").removeAttr('disabled');}
    // $("#docrecord-loader").hide();
  };
  handlePageClick = ({ selected: selectedPage }) => {
    this.setState({ mydata: [] });
    this.setState({ currentPage: selectedPage }, this.fetchData);
  };

  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };

  clickViewBtn = (doc) => {
    localStorage.setItem("relleIsugad", encryptor.encrypt(doc.userId));
    localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
    this.props.history.push({
      pathname: `/documentrecords/${doc.userId}/documentrecords`,
    });
  };
  renderlatestdata = () => {
    $("#docrecord-empty").hide()
    $(".filter-sort-btn").removeAttr("disabled")
    if (!this.state.filter) {
      if(this.state.mydata.length == 0){
        $("#docrecord-empty").show()
      }else{
    return this.state.mydata.map((data, index) => {
      return (
        <tr key={index}>
          <td scope="row" className="sno">
          {(this.state.entry*(this.state.currentPage)) + index + 1 }
          </td>
          <td className="userid userid-td">{data.userId}</td>
          <td className="username">{data.username}</td>
          <td className="email">{data.emailverifySt == true ? <span className="text-success">{data.email}</span> : <span className="text-danger">{data.email}</span>}</td>
            <td className="contact ">{data.contactverifySt == true ? <span className="text-success">{data.contact}</span> : <span className="text-danger">{data.contact}</span>}</td>
          <td className="services">
            {data.services.length == 0 ? <span >-</span>:data.services.map((numbers, index) => {
              
              if (index == data.services.length - 1) {
                return <span key={index}>{numbers}</span>;
              } else {
                return <span key={index}>{numbers + ","}</span>;
              }
            })}
          </td>
          <td className="status">{data.status == null ? <span>-</span> : data.status}</td>
          <td className="details">
            <button className="viewbtn" onClick={() => this.clickViewBtn(data)}>
              View <img src={process.env.PUBLIC_URL + "/img/Path 19498.svg"} />
            </button>
          </td>
        </tr>
      );
    });}}else {
      if (this.state.filteredData.length == 0) {
        $("#docrecord-empty").show();
      } else   return this.state.filteredData.map((data, index) => {
        return (
          <tr key={index}>
            <td scope="row" className="sno">
            {(this.state.entry*(this.state.currentPage)) + index + 1 }
            </td>
            <td className="userid userid-td">{data.userId}</td>
            <td className="username">{data.username}</td>
            <td className="email">{data.email}</td>
            <td className="contact ">{data.contact}</td>
            <td className="services">
              {data.services.length == 0 ? <span >-</span>:data.services.map((numbers, index) => {
                
                if (index == data.services.length - 1) {
                  return <span key={index}>{numbers}</span>;
                } else {
                  return <span key={index}>{numbers + ","}</span>;
                }
              })}
            </td>
            <td className="status">{data.status == null ? <span>-</span> : data.status}</td>
            <td className="details">
              <button className="viewbtn" onClick={() => this.clickViewBtn(data)}>
                View <img src={process.env.PUBLIC_URL + "/img/Path 19498.svg"} />
              </button>
            </td>
          </tr>
        );})

    }
  };

  renderoldest = () => {
    $("#docrecord-empty").hide()
    $(".filter-sort-btn").removeAttr("disabled")
    if (!this.state.filter) {
      if(this.state.mydata.length == 0){
        $("#docrecord-empty").show()
      }else{
    return this.state.mydata
      .slice()
      .reverse()
      .map((data, index) => {
        return (
          <tr key={index}>
            <td scope="row" className="sno">
            {(this.state.entry*(this.state.currentPage)) + index + 1 }
            </td>
            <td className="userid userid-td">{data.userId}</td>
            <td className="username">{data.username}</td>
            <td className="email">{data.emailverifySt == true ? <span className="text-success">{data.email}</span> : <span className="text-danger">{data.email}</span>}</td>
            <td className="contact ">{data.contactverifySt == true ? <span className="text-success">{data.contact}</span> : <span className="text-danger">{data.contact}</span>}</td>
            <td className="services">
              {data.services.length == 0 ? <span >-</span>:data.services.map((numbers, index) => {
                
                if (index == data.services.length - 1) {
                  return <span key={index}>{numbers}</span>;
                } else {
                  return <span key={index}>{numbers + ","}</span>;
                }
              })}
            </td>
            <td className="status">{data.status == null ? <span>-</span> : data.status}</td>
            <td className="details">
              <button
                className="viewbtn"
                onClick={() => this.clickViewBtn(data)}
              >
                View{" "}
                <img src={process.env.PUBLIC_URL + "/img/Path 19498.svg"} />
              </button>
            </td>
          </tr>
        );
      });}}else {
        if (this.state.filteredData.length == 0) {
          $("#docrecord-empty").show();
        }else {
        return this.state.filteredData
        .slice()
        .reverse()
        .map((data, index) => {
          return (
            <tr key={index}>
              <td scope="row" className="sno">
              {(this.state.entry*(this.state.currentPage)) + index + 1 }
              </td>
              <td className="userid userid-td">{data.userId}</td>
              <td className="username">{data.username}</td>
              <td className="email">{data.email}</td>
              <td className="contact ">{data.contact}</td>
              <td className="services">
                {data.services.length == 0 ? <span >-</span>:data.services.map((numbers, index) => {
                  
                  if (index == data.services.length - 1) {
                    return <span key={index}>{numbers}</span>;
                  } else {
                    return <span key={index}>{numbers + ","}</span>;
                  }
                })}
              </td>
              <td className="status">{data.status == null ? <span>-</span> : data.status}</td>
              <td className="details">
                <button
                  className="viewbtn"
                  onClick={() => this.clickViewBtn(data)}
                >
                  View{" "}
                  <img src={process.env.PUBLIC_URL + "/img/Path 19498.svg"} />
                </button>
              </td>
            </tr>
          );
        });}
      }
  };
  filterbystatus = (t) => {

    $("#docrecord-empty").hide();
  if(t == "all"){
    this.setState({ filter: false }, this.state.latest?this.renderlatestdata: this.renderoldest);
  }else{
  this.setState({ filter: true });
  let filtStatus = this.state.mydata.filter(el => {
    if(el.status == t){
      return true
    }else {return false}
  }) ;
  
  this.setState({filteredData : filtStatus}, this.state.latest?this.renderlatestdata: this.renderoldest)
}
}
  render() {
    if (
      localStorage.getItem("gglltakoinoeenl") ==
        process.env.REACT_APP_LOGIN_KEY &&
      localStorage.getItem("toeljgtkewlna") != null &&
      localStorage.getItem("qazxswedcvfrtgb") != null
    ) {
      
      return (
        <div>
          <div className="cont">
            <div id="notifContainer"></div>
            <Helmet>
              <link
                rel="stylesheet"
                href={process.env.PUBLIC_URL + "/css/select2.css"}
              />
              <link rel="stylesheet" href="css/services.css" />
            </Helmet>

            <Header />

            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
              className="sidebaropenicon"
            />
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
              className="sidebarbackicon"
            />
            <img
              alt="loading..."
              src={
                process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"
              }
              className="sidebarexpandicon"
            />

            <div className="overlay1"></div>
            <Sidebar/>

            <div className="arrow-left5"></div>
            <div className="white_div">
              <div className="white_box">
                <div className="row main-row">
                  <div className="row justify-content-lg-between justify-content-center align-items-center w-100 mb-3 ml-3 mr-3">
                    <div className="row col-lg-7 col-12 justify-content-lg-between justify-content-center ">
                      <div className="col-lg-6 col-12">
                        <form onSubmit={this.handleSearchinput}>
                          <input
                            type="text"
                            className="agent__overview__search_box"
                            placeholder="Search by UserId or name"
                            id="searchfielddoc"
                            maxLength="100"
                            autoComplete="off"
                            value={this.state.searchinput}
                            onChange={(e) => {
                              this.setState({ searchinput: e.target.value, searchdone: false, mySearch: []  });
                             
                            }
                            }
                          />
                        </form>
                      </div>
                      <h4 className="agentheader-text text-center col-lg-3 col-12 mr-lg-5 mr-0">
                        Document Records
                      </h4>
                    </div>

                    <div className="  pl-0 button-bar">
                      <div className="dropdown  px-0 px-2 pl-lg-4">
                        <button
                          className="btn agent-header-btn header-btn py-1 px-md-2 px-lg-4 px-2 filter-sort-btn"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <img
                            src={process.env.PUBLIC_URL + "/img/Path 19648.svg"}
                            alt=""
                          />
                          <span>Sort by</span>
                        </button>
                        <div
                          className="dropdown-menu drop myser-mar-l-05"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a
                            className="dropdown-item cursorpointer"
                            onClick={() => this.setState({ latest: true })}
                          >
                            Latest to Oldest
                          </a>
                          <a
                            className="dropdown-item cursorpointer"
                            onClick={() => this.setState({ latest: false })}
                          >
                            Oldest to Latest
                          </a>
                        </div>
                      </div>
                      <div className="dropdown">
                              <button
                                className="btn agent-header-btn header-btn py-1 px-md-2 px-lg-4 px-2 filter-sort-btn"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img
                                  alt="loading..."
                                  src={
                                    process.env.PUBLIC_URL + "/img/Path 210.svg"
                                  }
                                />
                                <span>Filter by</span>
                              </button>
                              <div
                                className="dropdown-menu drop agent__dropdown__filter dash-mar-l-04"
                                aria-labelledby="dropdownMenuButton"
                              ><a
                              className="dropdown-item cursorpointer"
                              onClick={() => this.filterbystatus("all")}
                            >
                            All
                            </a>
                                <a
                                  className="dropdown-item cursorpointer"
                                  onClick={() => this.filterbystatus(pending)}
                                >
                                Pending
                                </a>
                                <a
                                  className="dropdown-item cursorpointer"
                                  onClick={() => this.filterbystatus(complete)}
                                >
                                  Complete
                                </a>
                                <a
                                  className="dropdown-item cursorpointer"
                                  onClick={() => this.filterbystatus(review)}
                                >
                                Under Review
                                </a>
                                <a
                                  className="dropdown-item cursorpointer"
                                  onClick={() => this.filterbystatus(invaliddoc)}
                                >
                                  Invalid Doc
                                </a>
                             
                              </div>
                            </div>
                      <div className="dropdown ml-md-0 ml-lg-2 ml-2 px-2">
                        <select
                          className="agent__selectcateselectagent filter-sort-btn"
                          value={this.state.entry}
                          onChange={(e) => {
                            this.setState(
                              { entry: e.target.value, mydata: [], pageCount:0 },
                              this.handlePageClick({selected: 0})
                            );
                          }}
                        >
                          <option
                            value="10"
                            className="selectcateselectoption2"
                          >
                            10
                          </option>
                          <option
                            value="20"
                            className="selectcateselectoption2"
                          >
                            20
                          </option>
                          <option
                            value="50"
                            className="selectcateselectoption2"
                          >
                            50
                          </option>
                          <option
                            value="100"
                            className="selectcateselectoption2"
                          >
                            100
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive my-table">
                    <table className="table mr-3" id="docRecordTable">
                      <thead className="my-thead ">
                        <tr className="docRecordTable">
                          <th className="my-th sno" data-type="number">Sno <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt=""/></th>
                          <th className="my-th userid">User Id <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt=""/></th>
                          <th className="my-th username">Username <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt=""/></th>
                          <th className="my-th email">Email <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt=""/></th>
                          <th className="my-th contact ">Contact <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt=""/></th>
                          <th className="my-th services">Services <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt=""/></th>
                          <th className="my-th status">Status <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt=""/></th>
                          <th className="my-th details">Details <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt=""/></th>
                        </tr>
                      </thead>
                   
                        <div className="loader-abs" id="docrecord-loader">
                          <img src="/img/loader.gif" />
                        </div>
                      
                        <div className="empty-abs" id="docrecord-empty">
                          <img src="/img/quiteempty.svg" className="nullImg" />
                        </div>
                 
                      <tbody className="my-tbody my-tbodysupport">
                        {this.state.searchdone? this.rendersearch():
                        
                        this.state.latest
                          ? this.renderlatestdata()
                          : this.renderoldest()}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="mt-3 w-100">
                  <ReactPaginate
                    previousLabel={
                      <img
                        src={process.env.PUBLIC_URL + "/img/Path_21772.svg"}
                        width="15px"
                      />
                    }
                    nextLabel={
                      <img
                        src={process.env.PUBLIC_URL + "/img/Path_21773.svg"}
                        width="15px"
                      />
                    }
                    pageCount={this.state.pageCount}
                    onPageChange={this.handlePageClick}
                    forcePage={this.state.currentPage}
                    containerClassName={"pagination"}
                    previousLinkClassName={"pagination__link"}
                    nextLinkClassName={"pagination__link"}
                    disabledClassName={"pagination__link--disabled"}
                    activeClassName={"pagination__link----active"}
                  />
                </div>
              </div>
            </div>

            <Footer />
          </div>
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default Documentrecordagent;

import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import { Redirect } from "react-router";
import AllPricing from "../data.json";
import json2 from "../State_shortcode.json";

import axios from "axios";
let Decuserid;
let Decagentid;
let Decjwttoken;

let supportName;
let supportContact;
let supportEmail;
var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var fullname = "";
var citydistrict = "";
var servicename = "";
var servicestate="";
var encryptor = require("simple-encryptor")(key);
let data = [];
let pricingJson;

class Addservicetr extends Component {
  constructor(props) {
    super(props);

    this.state = {
      serviceselectvalue: "",
      citydistrictselectvalue: "",
      servicefullname: "",
      serviceapplicationtype: "",     
      supportName:"",
      supportContact:"",
      supportEmail:"",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    $(document.body).css("background-color", "#2d2d2d");
    let mainuserid = localStorage.getItem("relleIsugad");
    Decuserid = encryptor.decrypt(mainuserid);
    let mainjwttoken = localStorage.getItem("toeljgtkewlna");
let mainagentid= localStorage.getItem("qazxswedcvfrtgb");
    Decagentid = encryptor.decrypt(mainagentid);
    Decjwttoken = encryptor.decrypt(mainjwttoken);
    let mainfullname = sessionStorage.getItem("ielndmlgeaefelvsdrlucaa");
    let maincitydistrict = sessionStorage.getItem("ylcvciretaireditdgisdtacesl");
    let mainservicename = sessionStorage.getItem("srleleddgaeaemvcnia");
    let user__state = sessionStorage.getItem("hjtsrevanxckawshdyeh");
    fullname = encryptor.decrypt(mainfullname);
    citydistrict = encryptor.decrypt(maincitydistrict);
    servicename = encryptor.decrypt(mainservicename);
    servicestate= encryptor.decrypt(user__state);

    
    this.setState({
      citydistrictselectvalue: citydistrict,
      servicefullname: fullname,
      serviceselectvalue: servicename,
    });
    supportName = localStorage.getItem("supportName");
    supportContact = localStorage.getItem("supportContact");
    supportEmail = localStorage.getItem("supportEmail");
    
    this.setState({
      supportName:supportName,
      supportContact:supportContact,
      supportEmail:supportEmail,
    })

     fetch(process.env.REACT_APP_API_PRICING_JSON_URL)
      .then(response => response.json())
      .then(data => {
        // Store the JSON data in localStorage
        localStorage.setItem('lshhihfnkff', JSON.stringify(data));
        this.priceUpdate()
      })
      .catch(error => console.error('Error fetching data:', error));
  }

  priceUpdate = () => {
    pricingJson = JSON.parse(localStorage.getItem("lshhihfnkff"));
  }

  createPurchaseReqNormal = () => {
    if (this.state.serviceapplicationtype != "") {
      $(".docureqbutton").hide();
      $(".addserviceloader1").css("display", "block");
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + 'p=createPurchaseReqNormal',
          {
            userId: Decuserid,
            agentId: Decagentid,
            serviceName: "TR",
            purchasename: this.state.servicefullname,
            citydistrict: this.state.citydistrictselectvalue,
            state:servicestate,
            serviceType: this.state.serviceapplicationtype,
            duration: "",
            plan: "",
            turnover: "",
            package: "",
            comeFrom:"AGP"
          },
          {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
auth: {
    username: process.env.REACT_APP_API_USERNAME,
    password:  process.env.REACT_APP_API_PASSWORD
  }
        }
        )
        .then((res) => {
          
          
          if (res.data.code == "200" && res.data.status == "success") {
            $(".docureqbutton").show();
            $(".addserviceloader1").hide();
            sessionStorage.setItem(
              "uegiqlsartdele",
              encryptor.encrypt(res.data.requestid)
            );
            sessionStorage.setItem(
              "aprcillgee",
              encryptor.encrypt(parseFloat(res.data.price))
            );
            sessionStorage.setItem("ssdfsdfwekrfewpfk", encryptor.encrypt(parseFloat(res.data.gstvalue)));
            sessionStorage.setItem(
              "lslgerotnscmvheearaei",
              encryptor.encrypt("TR")
            );
            sessionStorage.setItem(
              "evlgsoaeetgfl",
              encryptor.encrypt(parseFloat(res.data.govtfees))
            );
            sessionStorage.setItem("egalllpna", encryptor.encrypt("none"));
            
            this.props.history.push({
              pathname:`/addservice/${Decuserid}/addservice/checkout`,
            });
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else {
            $(".docureqbutton").show();
            $(".addserviceloader1").hide();
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => { });
    }
    else {
      this.showNotification("Please select application type");
    }
  };
  handleserviceapplicationtypeChange = (e) => {
    $("#selectOpt").attr("disabled", true);
    this.setState({
      serviceapplicationtype: e.target.value,
    });
  };
  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };
  logout = () => {
    localStorage.removeItem("gglltakoinoeenl");
    localStorage.removeItem("toeljgtkewlna");
    localStorage.removeItem("qazxswedcvfrtgb");
    localStorage.removeItem("lshhihfnkff");
    this.props.history.push({
      pathname: `/`,
    });
  };
  backbutton = () => {
    this.props.history.push({
      pathname: `/addservice/${Decuserid}/addservice`,
    });
  };
  handlesupport = () => {
    var x = document.getElementById("myDIV");
    if (x.style.display == "block") {
      x.style.display = "none";
    }else {
      x.style.display = "block";
    }
  }
  addToCart = () => {
    if (this.state.serviceapplicationtype != "") {
      var stateshortcode = Object.keys(json2[0]).find(key => json2[0][key] === servicestate);
      var mainobject=pricingJson.TR
      var gstvalue=mainobject.price*0.18;
      var govtfeesvalue= mainobject["govtfees_"+this.state.serviceapplicationtype]
      data = {
        "userId": Decuserid,
        "agentId":Decagentid,
        "serviceName": "TR",
        "purchasename": this.state.servicefullname,
        "citydistrict": this.state.citydistrictselectvalue,
        "state": servicestate,
        "serviceType": this.state.serviceapplicationtype,
        "duration": "",
        "plan": "",
        "turnover": "",
        "package": "",
        "price":mainobject.price,
        "gstvalue":gstvalue,
        "govtfees":govtfeesvalue,
        "servicefullname":servicename,
        "stateShortCode": stateshortcode,
      }
  
      var sessiondata; 
      sessiondata = sessionStorage.getItem("data")
      if (sessiondata == null || sessiondata == undefined  || sessiondata == "") {
        sessiondata=[]
      }
      else  {
       sessiondata = JSON.parse(sessionStorage.getItem("data"))
      }
      
          if (sessiondata.length == 0 ) {
            var localvar = []
            localvar.push(data)
            sessionStorage.setItem("data", JSON.stringify(localvar))
            this.showNotification("Item Added to Cart")
          }
          else if (sessiondata.length > 0) {
            var arraylen = sessiondata.length;
            // for (let index = 0; index < arraylen; index++) {
            //   if (sessiondata[index].serviceName == "TR") {
            //     sessiondata.splice(index, 1)
            //     break
            //   }
            // }
            sessiondata.push(data)
            sessionStorage.setItem("data", JSON.stringify(sessiondata))
            this.showNotification("Item Added to Cart")
          }
          this.setState({
            dataupdated: true,
          });

      }
   else {
      this.showNotification("Please select application type");
    }
  }
  render() {
    if (
      localStorage.getItem("gglltakoinoeenl") == process.env.REACT_APP_LOGIN_KEY &&
      localStorage.getItem("toeljgtkewlna") != null &&
      localStorage.getItem("qazxswedcvfrtgb") != null
    ) {
      if (
        sessionStorage.getItem("ielndmlgeaefelvsdrlucaa") != null &&
        sessionStorage.getItem("ylcvciretaireditdgisdtacesl") != null &&
        sessionStorage.getItem("srleleddgaeaemvcnia") != null
      ) {
        return (
          <div className="cont">
            <div id="notifContainer"></div>
            <Helmet>
              <link rel="stylesheet" href={process.env.PUBLIC_URL + "/css/select2.css"} />
            </Helmet>

            <Header />

            <img
              src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
              className="sidebaropenicon"
            />
            <img
              src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
              onClick={this.backbutton}
              className="sidebarbackicon"
            />
            <img
              src={process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"}
              className="sidebarexpandicon"
            />

            <div className="overlay1"></div>
            <div className="sidebar sidebarshift">
              <div className="side_div_2 sidebar_txt active">
                <Link to="/dashboard">Overview</Link>
              </div>
              <div className="side_div_1 sidebar_txt">
                <Link to="/myservices">My Services</Link>
              </div>
              <div className="side_div_1 sidebar_txt">
                <a href="#">Payments Invoices</a>
              </div>
              <div className="side_div_1 sidebar_txt">
                <a href="#">Documents Record</a>
              </div>
              <div className="side_div_1 sidebar_txt">
                <a href="#">Add service</a>
              </div>
              <div></div>
            </div>
            <div className="arrow-left2 visibilityhidden"></div>
            <div className="white_div expandwhite_div">
              <div className="white_box">
                <center>
                  {" "}
                  <p className="download">Add Service</p>
                </center>
                <div className="row addserivemaindiv">
                  <div className="col-md-4">
                    <div className="addservicetextinputdiv">
                      <div>
                        {" "}
                        <label className="adddervicelabel">Full name:-</label>
                      </div>
                      <div>
                        <input
                          type="text"
                          className="addservicetextinput"
                          value={this.state.servicefullname}
                          placeholder="Type here..."
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <center>
                        <div>
                          <label className="adddervicelabel">Select service:-</label>
                        </div>

                        <div className="addserviceselectdiv">
                          <Select2
                            value={this.state.citydistrictselectvalue}
                            data={[
                              "Adilabad",
                            "Agar Malwa",
                            "Agra",
                            "Ahmedabad",
                            "Ahmednagar",
                            "Aizawl",
                            "Ajmer",
                            "Akola",
                            "Alappuzha",
                            "Aligarh",
                            "Alipurduar",
                            "Alirajpur",
                            "Allahabad",
                            "Almora",
                            "Alwar",
                            "Ambala",
                            "Ambedkar Nagar",
                            "Amethi (Chatrapati Sahuji Mahraj Nagar)",
                            "Amravati",
                            "Amreli",
                            "Amritsar",
                            "Amroha",
                            "Anand",
                            "Anantapur",
                            "Anantnag",
                            "Angul",
                            "Anjaw",
                            "Anuppur",
                            "Araria",
                            "Aravalli",
                            "Ariyalur",
                            "Arwal",
                            "Ashoknagar",
                            "Auraiya",
                            "Aurangabad (BH)",
                            "Aurangabad (MH)",
                            "Azamgarh",
                            "Bagalkot",
                            "Bageshwar",
                            "Baghpat",
                            "Bahraich",
                            "Baksa",
                            "Balaghat",
                            "Balangir",
                            "Balasore",
                            "Ballari (Bellary)",
                            "Ballia",
                            "Balod",
                            "Baloda Bazar",
                            "Balrampur (CG)",
                            "Balrampur (UP)",
                            "Banaskantha (Palanpur)",
                            "Banda",
                            "Bandipore",
                            "Banka",
                            "Bankura",
                            "Banswara",
                            "Barabanki",
                            "Baramulla",
                            "Baran",
                            "Bareilly",
                            "Bargarh",
                            "Barmer",
                            "Barnala",
                            "Barpeta",
                            "Barwani",
                            "Bastar",
                            "Basti",
                            "Bathinda",
                            "Beed",
                            "Begusarai",
                            "Belagavi (Belgaum)",
                            "Bemetara",
                            "Bengaluru (Bangalore) Rural",
                            "Bengaluru (Bangalore) Urban",
                            "Betul",
                            "Bhadohi",
                            "Bhadradri Kothagudem",
                            "Bhadrak",
                            "Bhagalpur",
                            "Bhandara",
                            "Bharatpur",
                            "Bharuch",
                            "Bhavnagar",
                            "Bhilwara",
                            "Bhind",
                            "Bhiwani",
                            "Bhojpur",
                            "Bhopal",
                            "Bidar",
                            "Bijapur",
                            "Bijnor",
                            "Bikaner",
                            "Bilaspur (CG)",
                            "Bilaspur (HP)",
                            "Birbhum",
                            "Bishnupur",
                            "Biswanath",
                            "Bokaro",
                            "Bongaigaon",
                            "Botad",
                            "Boudh",
                            "Budaun",
                            "Budgam",
                            "Bulandshahr",
                            "Buldhana",
                            "Bundi",
                            "Burhanpur",
                            "Buxar",
                            "Cachar",
                            "Central Delhi",
                            "Chamarajanagar",
                            "Chamba",
                            "Chamoli",
                            "Champawat",
                            "Champhai",
                            "Chandauli",
                            "Chandel",
                            "Chandigarh",
                            "Chandrapur",
                            "Changlang",
                            "Charaideo",
                            "Charkhi Dadri",
                            "Chatra",
                            "Chengalpattu",
                            "Chennai",
                            "Chhatarpur",
                            "Chhindwara",
                            "Chhota Udepur",
                            "Chikballapur",
                            "Chikkamagaluru (Chikmagalur)",
                            "Chirang",
                            "Chitradurga",
                            "Chitrakoot",
                            "Chittoor",
                            "Chittorgarh",
                            "Churachandpur",
                            "Churu",
                            "Coimbatore",
                            "Cooch Behar",
                            "Cuddalore",
                            "Cuttack",
                            "Dadra & Nagar Haveli",
                            "Dahod",
                            "Dakshin Dinajpur (South Dinajpur)",
                            "Dakshina Kannada",
                            "Daman",
                            "Damoh",
                            "Dangs (Ahwa)",
                            "Dantewada (South Bastar)",
                            "Darbhanga",
                            "Darjeeling",
                            "Darrang",
                            "Datia",
                            "Dausa",
                            "Davangere",
                            "Dehradun",
                            "Deogarh",
                            "Deoghar",
                            "Deoria",
                            "Devbhoomi Dwarka",
                            "Dewas",
                            "Dhalai",
                            "Dhamtari",
                            "Dhanbad",
                            "Dhar",
                            "Dharmapuri",
                            "Dharwad",
                            "Dhemaji",
                            "Dhenkanal",
                            "Dholpur",
                            "Dhubri",
                            "Dhule",
                            "Dibang Valley",
                            "Dibrugarh",
                            "Dima Hasao (North Cachar Hills)",
                            "Dimapur",
                            "Dindigul",
                            "Dindori",
                            "Diu",
                            "Doda",
                            "Dumka",
                            "Dungarpur",
                            "Durg",
                            "East Champaran (Motihari)",
                            "East Delhi",
                            "East Garo Hills",
                            "East Godavari",
                            "East Jaintia Hills",
                            "East Kameng",
                            "East Khasi Hills",
                            "East Siang",
                            "East Sikkim",
                            "East Singhbhum",
                            "Ernakulam",
                            "Erode",
                            "Etah",
                            "Etawah",
                            "Faizabad",
                            "Faridabad",
                            "Faridkot",
                            "Farrukhabad",
                            "Fatehabad",
                            "Fatehgarh Sahib",
                            "Fatehpur",
                            "Fazilka",
                            "Ferozepur",
                            "Firozabad",
                            "Gadag",
                            "Gadchiroli",
                            "Gajapati",
                            "Ganderbal",
                            "Gandhinagar",
                            "Ganjam",
                            "Garhwa",
                            "Gariyaband",
                            "Gautam Buddha Nagar",
                            "Gaya",
                            "Ghaziabad",
                            "Ghazipur",
                            "Gir Somnath",
                            "Giridih",
                            "Goalpara",
                            "Godda",
                            "Golaghat",
                            "Gomati",
                            "Gonda",
                            "Gondia",
                            "Gopalganj",
                            "Gorakhpur",
                            "Gumla",
                            "Guna",
                            "Guntur",
                            "Gurdaspur",
                            "Gurugram (Gurgaon)",
                            "Gwalior",
                            "Hailakandi",
                            "Hamirpur (HP)",
                            "Hamirpur (UP)",
                            "Hanumangarh",
                            "Hapur (Panchsheel Nagar)",
                            "Harda",
                            "Hardoi",
                            "Haridwar",
                            "Hassan",
                            "Hathras",
                            "Haveri",
                            "Hazaribag",
                            "Hingoli",
                            "Hisar",
                            "Hojai",
                            "Hooghly",
                            "Hoshangabad",
                            "Hoshiarpur",
                            "Howrah",
                            "Hyderabad",
                            "Idukki",
                            "Imphal East",
                            "Imphal West",
                            "Indore",
                            "Jabalpur",
                            "Jagatsinghapur",
                            "Jagtial",
                            "Jaipur",
                            "Jaisalmer",
                            "Jajpur",
                            "Jalandhar",
                            "Jalaun",
                            "Jalgaon",
                            "Jalna",
                            "Jalore",
                            "Jalpaiguri",
                            "Jammu",
                            "Jamnagar",
                            "Jamtara",
                            "Jamui",
                            "Jangaon",
                            "Janjgir-Champa",
                            "Jashpur",
                            "Jaunpur",
                            "Jayashankar Bhoopalpally",
                            "Jehanabad",
                            "Jhabua",
                            "Jhajjar",
                            "Jhalawar",
                            "Jhansi",
                            "Jhargram",
                            "Jharsuguda",
                            "Jhunjhunu",
                            "Jind",
                            "Jiribam",
                            "Jodhpur",
                            "Jogulamba Gadwal",
                            "Jorhat",
                            "Junagadh",
                            "Kabirdham (Kawardha)",
                            "Kachchh",
                            "Kaimur (Bhabua)",
                            "Kaithal",
                            "Kakching",
                            "Kalaburagi (Gulbarga)",
                            "Kalahandi",
                            "Kalimpong",
                            "Kallakurichi",
                            "Kamareddy",
                            "Kamjong",
                            "Kamle",
                            "Kamrup",
                            "Kamrup Metropolitan",
                            "Kanchipuram",
                            "Kandhamal",
                            "Kangpokpi",
                            "Kangra",
                            "Kanker (North Bastar)",
                            "Kannauj",
                            "Kannur",
                            "Kanpur Dehat",
                            "Kanpur Nagar",
                            "Kanshiram Nagar (Kasganj)",
                            "Kanyakumari",
                            "Kapurthala",
                            "Karaikal",
                            "Karauli",
                            "Karbi Anglong",
                            "Kargil",
                            "Karimganj",
                            "Karimnagar",
                            "Karnal",
                            "Karur",
                            "Kasaragod",
                            "Kathua",
                            "Katihar",
                            "Katni",
                            "Kaushambi",
                            "Kendrapara",
                            "Kendujhar (Keonjhar)",
                            "Khagaria",
                            "Khammam",
                            "Khandwa",
                            "Khargone",
                            "Kheda (Nadiad)",
                            "Khordha",
                            "Khowai",
                            "Khunti",
                            "Kinnaur",
                            "Kiphire",
                            "Kishanganj",
                            "Kishtwar",
                            "Kodagu",
                            "Koderma",
                            "Kohima",
                            "Kokrajhar",
                            "Kolar",
                            "Kolasib",
                            "Kolhapur",
                            "Kolkata",
                            "Kollam",
                            "Komaram Bheem Asifabad",
                            "Kondagaon",
                            "Koppal",
                            "Koraput",
                            "Korba",
                            "Korea (Koriya)",
                            "Kota",
                            "Kottayam",
                            "Kozhikode",
                            "Kra Daadi",
                            "Krishna",
                            "Krishnagiri",
                            "Kulgam",
                            "Kullu",
                            "Kupwara",
                            "Kurnool",
                            "Kurukshetra",
                            "Kurung Kumey",
                            "Kushinagar (Padrauna)",
                            "Lahaul & Spiti",
                            "Lakhimpur",
                            "Lakhimpur - Kheri",
                            "Lakhisarai",
                            "Lakshadweep",
                            "Lalitpur",
                            "Latehar",
                            "Latur",
                            "Lawngtlai",
                            "Leh",
                            "Lepa Rada",
                            "Lohardaga",
                            "Lohit",
                            "Longding",
                            "Longleng",
                            "Lower Dibang Valley",
                            "Lower Siang",
                            "Lower Subansiri",
                            "Lucknow",
                            "Ludhiana",
                            "Lunglei",
                            "Madhepura",
                            "Madhubani",
                            "Madurai",
                            "Mahabubabad",
                            "Mahabubnagar",
                            "Maharajganj",
                            "Mahasamund",
                            "Mahe",
                            "Mahendragarh",
                            "Mahisagar",
                            "Mahoba",
                            "Mainpuri",
                            "Majuli",
                            "Malappuram",
                            "Malda",
                            "Malkangiri",
                            "Mamit",
                            "Mancherial",
                            "Mandi",
                            "Mandla",
                            "Mandsaur",
                            "Mandya",
                            "Mansa",
                            "Mathura",
                            "Mau",
                            "Mayurbhanj",
                            "Medak",
                            "Medchal",
                            "Meerut",
                            "Mehsana",
                            "Mirzapur",
                            "Moga",
                            "Mokokchung",
                            "Mon",
                            "Moradabad",
                            "Morbi",
                            "Morena",
                            "Morigaon",
                            "Muktsar",
                            "Mumbai City",
                            "Mumbai Suburban",
                            "Mungeli",
                            "Munger (Monghyr)",
                            "Murshidabad",
                            "Muzaffarnagar",
                            "Muzaffarpur",
                            "Mysuru (Mysore)",
                            "Nabarangpur",
                            "Nadia",
                            "Nagaon",
                            "Nagapattinam",
                            "Nagarkurnool",
                            "Nagaur",
                            "Nagpur",
                            "Nainital",
                            "Nalanda",
                            "Nalbari",
                            "Nalgonda",
                            "Namakkal",
                            "Namsai",
                            "Nanded",
                            "Nandurbar",
                            "Narayanpur",
                            "Narmada (Rajpipla)",
                            "Narsinghpur",
                            "Nashik",
                            "Navsari",
                            "Nawada",
                            "Nawanshahr (Shahid Bhagat Singh Nagar)",
                            "Nayagarh",
                            "Neemuch",
                            "New Delhi",
                            "Nicobar",
                            "Nilgiris",
                            "Nirmal",
                            "Nizamabad",
                            "Noney",
                            "North 24 Parganas",
                            "North Delhi",
                            "North East Delhi",
                            "North Garo Hills",
                            "North Goa",
                            "North Sikkim",
                            "North Tripura",
                            "North West Delhi",
                            "North and Middle Andaman",
                            "Nuapada",
                            "Nuh",
                            "Osmanabad",
                            "Pakke Kessang",
                            "Pakur",
                            "Palakkad",
                            "Palamu",
                            "Palghar",
                            "Pali",
                            "Palwal",
                            "Panchkula",
                            "Panchmahal (Godhra)",
                            "Panipat",
                            "Panna",
                            "Papum Pare",
                            "Parbhani",
                            "Paschim (West) Burdwan (Bardhaman)",
                            "Paschim Medinipur (West Medinipur)",
                            "Patan",
                            "Pathanamthitta",
                            "Pathankot",
                            "Patiala",
                            "Patna",
                            "Pauri Garhwal",
                            "Peddapalli",
                            "Perambalur",
                            "Peren",
                            "Phek",
                            "Pherzawl",
                            "Pilibhit",
                            "Pithoragarh",
                            "Poonch",
                            "Porbandar",
                            "Prakasam",
                            "Pratapgarh (RJ)",
                            "Pratapgarh (UP)",
                            "Puducherry",
                            "Pudukkottai",
                            "Pulwama",
                            "Pune",
                            "Purba Burdwan (Bardhaman)",
                            "Purba Medinipur (East Medinipur)",
                            "Puri",
                            "Purnia (Purnea)",
                            "Purulia",
                            "RaeBareli",
                            "Raichur",
                            "Raigad",
                            "Raigarh",
                            "Raipur",
                            "Raisen",
                            "Rajanna Sircilla",
                            "Rajgarh",
                            "Rajkot",
                            "Rajnandgaon",
                            "Rajouri",
                            "Rajsamand",
                            "Ramanagara",
                            "Ramanathapuram",
                            "Ramban",
                            "Ramgarh",
                            "Rampur",
                            "Ranchi",
                            "Rangareddy",
                            "Ranipet",
                            "Ratlam",
                            "Ratnagiri",
                            "Rayagada",
                            "Reasi",
                            "Rewa",
                            "Rewari",
                            "Ri Bhoi",
                            "Rohtak",
                            "Rohtas",
                            "Rudraprayag",
                            "Rupnagar",
                            "Sabarkantha (Himmatnagar)",
                            "Sagar",
                            "Saharanpur",
                            "Saharsa",
                            "Sahibganj",
                            "Sahibzada Ajit Singh Nagar (Mohali)",
                            "Saiha",
                            "Salem",
                            "Samastipur",
                            "Samba",
                            "Sambalpur",
                            "Sambhal (Bhim Nagar)",
                            "Sangareddy",
                            "Sangli",
                            "Sangrur",
                            "Sant Kabir Nagar",
                            "Saran",
                            "Satara",
                            "Satna",
                            "Sawai Madhopur",
                            "Sehore",
                            "Senapati",
                            "Seoni",
                            "Sepahijala",
                            "Seraikela-Kharsawan",
                            "Serchhip",
                            "Shahdara",
                            "Shahdol",
                            "Shahjahanpur",
                            "Shajapur",
                            "Shamali (Prabuddh Nagar)",
                            "Sheikhpura",
                            "Sheohar",
                            "Sheopur",
                            "Shi Yomi",
                            "Shimla",
                            "Shivamogga (Shimoga)",
                            "Shivpuri",
                            "Shopian",
                            "Shravasti",
                            "Siang",
                            "Siddharth Nagar",
                            "Siddipet",
                            "Sidhi",
                            "Sikar",
                            "Simdega",
                            "Sindhudurg",
                            "Singrauli",
                            "Sirmaur (Sirmour)",
                            "Sirohi",
                            "Sirsa",
                            "Sitamarhi",
                            "Sitapur",
                            "Sivaganga",
                            "Sivasagar",
                            "Siwan",
                            "Solan",
                            "Solapur",
                            "Sonbhadra",
                            "Sonepur",
                            "Sonipat",
                            "Sonitpur",
                            "South 24 Parganas",
                            "South Andaman",
                            "South Delhi",
                            "South East Delhi",
                            "South Garo Hills",
                            "South Goa",
                            "South Salamara-Mankachar",
                            "South Sikkim",
                            "South Tripura",
                            "South West Delhi",
                            "South West Garo Hills",
                            "South West Khasi Hills",
                            "Sri Ganganagar",
                            "Sri Potti Sriramulu Nellore ",
                            "Srikakulam",
                            "Srinagar",
                            "Sukma",
                            "Sultanpur",
                            "Sundargarh",
                            "Supaul",
                            "Surajpur",
                            "Surat",
                            "Surendranagar",
                            "Surguja",
                            "Suryapet",
                            "Tamenglong",
                            "Tapi (Vyara)",
                            "Tarn Taran",
                            "Tawang",
                            "Tehri Garhwal",
                            "Tengnoupal",
                            "Tenkasi",
                            "Thane",
                            "Thanjavur",
                            "Theni",
                            "Thiruvananthapuram",
                            "Thoothukudi (Tuticorin)",
                            "Thoubal",
                            "Thrissur",
                            "Tikamgarh",
                            "Tinsukia",
                            "Tirap",
                            "Tiruchirappalli",
                            "Tirunelveli",
                            "Tirupathur",
                            "Tiruppur",
                            "Tiruvallur",
                            "Tiruvannamalai",
                            "Tiruvarur",
                            "Tonk",
                            "Tuensang",
                            "Tumakuru (Tumkur)",
                            "Udaipur",
                            "Udalguri",
                            "Udham Singh Nagar",
                            "Udhampur",
                            "Udupi",
                            "Ujjain",
                            "Ukhrul",
                            "Umaria",
                            "Una",
                            "Unakoti",
                            "Unnao",
                            "Upper Siang",
                            "Upper Subansiri",
                            "Uttar Dinajpur (North Dinajpur)",
                            "Uttara Kannada (Karwar)",
                            "Uttarkashi",
                            "Vadodara",
                            "Vaishali",
                            "Valsad",
                            "Varanasi",
                            "Vellore",
                            "Vidisha",
                            "Vijayapura (Bijapur)",
                            "Vikarabad",
                            "Viluppuram",
                            "Virudhunagar",
                            "Visakhapatnam",
                            "Vizianagaram",
                            "Wanaparthy",
                            "Warangal (Rural)",
                            "Warangal (Urban)",
                            "Wardha",
                            "Washim",
                            "Wayanad",
                            "West Champaran",
                            "West Delhi",
                            "West Garo Hills",
                            "West Godavari",
                            "West Jaintia Hills",
                            "West Kameng",
                            "West Karbi Anglong",
                            "West Khasi Hills",
                            "West Siang",
                            "West Sikkim",
                            "West Singhbhum",
                            "West Tripura",
                            "Wokha",
                            "YSR District, Kadapa (Cuddapah)",
                            "Yadadri Bhuvanagiri",
                            "Yadgir",
                            "Yamunanagar",
                            "Yanam",
                            "Yavatmal",
                            "Zunheboto",
                            ]}
                            options={{
                              placeholder: "Select your city/district...",
                            }}
                            disabled
                          />
                        </div>
                      </center>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <div>
                        <label className="adddervicelabel">Select service:-</label>
                      </div>
                      <div className="addserviceselectdiv">
                        <Select2
                          value={this.state.serviceselectvalue}
                          data={[
                            "GST Registration",
                            "GST Return Filing",
                            "ITR Filing",
                            "Trademark Registration",
                          ]}
                          options={{
                            placeholder: "Select service...",
                          }}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row addserivemaindiv">
                  <div className="col-md-7">
                    <div className="servicecol">
                      <p className="serviceamainheading">Trademark Registration</p>
                      <p className="serviceamainheadingtext">
                        Become a Brand with a ™ sign on your logo, slogan or
                        <br />
                        company name. Get your Intellectual Property
                        <br /> protected now.
                      </p>
                      <select
                        className="serviceinputselect"
                        id="applicationtype"
                        value={this.state.serviceapplicationtype}
                        onChange={this.handleserviceapplicationtypeChange}
                      >
                        <option value="" selected="" id="selectOpt" disabled="">
                          Application type...
                        </option>
                        <option value="CPG">
                          Company/Partnership/Group of Individuals
                        </option>
                        <option value="ISP">
                          Individuals/Sole Proprietorship
                        </option>
                        <option value="MS">MSME/Startup</option>
                      </select>
                      <br />
                      <button
                        className="docureqbutton"
                        onClick={this.createPurchaseReqNormal}
                      >
                        Get Started
                      </button>
                      <button
                          className="addtocartbutton"
                          id="submit"
                          onClick={this.addToCart}
                        >
                          Add to cart
                          &nbsp;<img
                      src={process.env.PUBLIC_URL + "/img/Path_33782_1.svg"}
                    />
                        </button>
                      <img
                        src={process.env.PUBLIC_URL + "/img/loader.gif"}
                        className="addserviceloader1"
                      />
                    </div>
                  </div>
                  <div className="col-md-5">
                    <img
                      src={process.env.PUBLIC_URL + "/img/trilustration.png"}
                      className="serviceilustration"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div id="myDIV">

<div className='supportDetails'>
    <img
        src={process.env.PUBLIC_URL + "/img/24-7_Support.png"}

        alt="image"
    />
    <div>
        <h5>{supportName}</h5>
        <p>Agent Support Executive</p>
        <span><b>Contact: </b> +91 {supportContact}</span>
        <p className='mb-1'><b>Working Hours: </b> 11:00 AM - 06:30 PM</p>
    </div>
</div>


</div>
<button className="support" onClick={this.handlesupport} >
<img src={process.env.PUBLIC_URL + "/img/headPhone.svg"} width="50%" height="50px" />
</button>

            <Footer />
          </div>
        );
      }
      else {
        return <Redirect to="/dashboard" />;
      }
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default Addservicetr;

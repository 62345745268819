import React, { Component } from "react";
import axios from "axios";
import $ from "jquery";
import { Redirect,Link } from "react-router-dom";

class HeaderAgent extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
}

  sidebaropen = () => {
  
    if (this.state.m == 0) {
      $('#sidebarp').addClass('active');
      $('.overlay').addClass('active');
      $('.collapse.in').toggleClass('in');
      $('#help').addClass('sidebar_footer');
      this.setState({
        m : 1
    })
      
  } else {
      $('#sidebarp').removeClass('active');
      $('.overlay').removeClass('active');
      $('.collapse.in').toggleClass('in');
      $('#help').removeClass('sidebar_footer');
      this.setState({
        m : 0
    }) 
  }
  }

  sidebarclose = () => {
    
    $('#sidebarp').removeClass('active');
    $('.overlay').removeClass('active');
    $('#help').removeClass('sidebar_footer');
        this.setState({
          m : 0
      }) 
    }
   
  

  rendernotiData() {
    return this.state.notificationarray.map((activedoc, index) => {
      return (
        <li key={index}>
          <div className="displayflex top-text-block">
            <div className="imgnotidiv">
              <center>
                <img
                  src={process.env.PUBLIC_URL + "/img/user.jpg"}
                  className="user_img"
                  alt="user"
                />
              </center>
            </div>
            <div className="contnotidiv">
              <div className="top-text-heading">{activedoc.notification}</div>
              <div className="top-text-light">
                {activedoc.datetime} &nbsp;&nbsp;from {activedoc.from}
              </div>
            </div>
          </div>
        </li>
      );
    });
  }

  render() {
    return (
     <>
     <div className="overlay" onClick={this.sidebarclose}></div>
     <div>
        <nav className="navbar navbar-expand-lg nav_p" id="navv">
          

          <Link to="/dashboard" className="navbar-brand nav_brnd agentheaderlogo"> 
            {" "}
            <img
              src={process.env.PUBLIC_URL + "/img/Legal_logo_full.svg"}
              id="pc"
              alt="legal251 logo"
              className="lgl_logo"
            />{" "}
          </Link>

          <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
            <div className="navbar-nav nav_n">
              <div className="nav-item active nav_item1">
                <div className="dropdown">
                  <div
                    className="dropdown-menu  dropdown_1"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div className="row row_1">
                      <div className="col-md-4">
                        <p className="dropdown-item dropdown_txt">
                          Full name:{" "}
                        </p>
                      </div>
                      <div className="col-md-8">
                        <p
                          className="dropdown-item dropdown_txtb"
                          
                        >
                        </p>
                      </div>
                    </div>
                    <div className="border_row"></div>
                    <div className="row row_1">
                      <div className="col-md-4">
                        <p className="dropdown-item dropdown_txt">Email ID:</p>
                      </div>
                      <div className="col-md-8">
                        <p
                          className="dropdown-item dropdown_txtb"
                        
                        >
                        </p>
                      </div>
                    </div>
                    <div className="border_row"></div>
                    <div className="row row_1">
                      <div className="col-md-4">
                        <p className="dropdown-item dropdown_txt">
                          Mobile No.:
                        </p>
                      </div>
                      <div className="col-md-8">
                        <p className="dropdown-item dropdown_txtb">
                        </p>
                      </div>
                    </div>
                    <div className="border_row"></div>
                    <div className="row row_1">
                      <div className="col-md-4">
                        <p className="dropdown-item dropdown_txt">
                          Customer ID:
                        </p>
                      </div>
                      <div className="col-md-8">
                        <p className="dropdown-item dropdown_txtb">
                         
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

             

              <div className="nav-item nav_search">
                <input
                  className="form-control search_inp"
                  type="text" disabled
                  placeholder="Search"
                />
              </div>
              <div className="nav-item nav_img ml-auto">
                <a href="https://web.whatsapp.com/send?phone=917772877729&amp;text=Hello%20Team%20Legal251!" target="_blank">
                  {" "}
                  <img
                    src={process.env.PUBLIC_URL + "/img/call.svg"}
                    alt="call"
                    className="callimg"
                  />
                </a>
              </div>
              <div className="nav-item nav_number">
                <a href="tel:7772877729" target="_blank" className="phone_number">
                  {" "}
                  <img
                    src={process.env.PUBLIC_URL + "/img/phone.svg"}
                    alt="phone"
                  />{" "}
                  (+91) 77728 77729{" "}
                </a>
              </div>
              <div className="nav-item nav_notif">
                <div className="btn-group pull-right top-head-dropdown">
            

                  <ul className="dropdown-menu dropdown-menu-right">
                
                    <div className="scroll1"></div>
                  </ul>
                </div>
              </div>
              <div className="nav-item nav_logout">
         
              </div>
            </div>
          </div>
        </nav>


      </div>
</>
    );
  }
}

export default HeaderAgent;

import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { Link } from "react-router-dom";
import Select2 from 'react-select2-wrapper';
import { Redirect } from "react-router";
import jsonData from "../State__and__dist.json"
import AllPricing from "../data.json";
import json2 from "../State_shortcode.json";


let servicestate;
let Decagentid;
let Decjwttoken;
var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var myform;
let data = [];
let sessiondata;
let supportName;
let supportContact;
let supportEmail;
let pricingJson;
var encryptor = require("simple-encryptor")(key);
class Addservicegstrf extends Component {
    constructor(props) {
        super(props)

        this.state = {
            serviceselectvalue: "",
            citydistrictselectvalue: "",
            servicefullname: "",
            serviceemail: "",
            servicemobileno: "",
            dropDownValue: "1",
            firstdropdownvalue: "Y",
            seconddropdownvalue: "Y",
            thirddropdownvalue: "Y",
            fourdropdownvalue: "Y",
            fivedropdownvalue: "Y",
            sixdropdownvalue: "Y",
            sevendropdownvalue: "Y",
            eightdropdownvalue: "Y",
            ninedropdownvalue: "Y",
            tendropdownvalue: "Y",
            elevendropdownvalue: "Y",
            twelvedropdownvalue: "Y",
            thirteendropdownvalue: "Y",
            fourteendropdownvalue: "Y",
            
            supportName:"",
            supportContact:"",
            supportEmail:"",

              // T - 1
        GSTRF1SY2: "",
        GSTRF1SY: "",
        GSTRF1SH: "",
        GSTRF1SQ: "",
        GSTRF1SM: "",
        GSTRF1BY2: "",
        GSTRF1BY: "",
        GSTRF1BH: "",
        GSTRF1BQ: "",
        GSTRF1BM: "",
        GSTRF1PY2: "",
        GSTRF1PY: "",
        GSTRF1PH: "",
        GSTRF1PQ: "",
        GSTRF1PM: "",
        GSTRF1PRY2: "",
        GSTRF1PRY: "",
        GSTRF1PRH: "",
        GSTRF1PRQ: "",
        GSTRF1PRM: "",
  
        // nodiscountprice
  
        GSTRF1SY2nodis: "",
        GSTRF1SYnodis: "",
        GSTRF1SHnodis: "",
        GSTRF1SQnodis: "",
        GSTRF1SMnodis: "",
        GSTRF1BY2nodis: "",
        GSTRF1BYnodis: "",
        GSTRF1BHnodis: "",
        GSTRF1BQnodis: "",
        GSTRF1BMnodis: "",
        GSTRF1PY2nodis: "",
        GSTRF1PYnodis: "",
        GSTRF1PHnodis: "",
        GSTRF1PQnodis: "",
        GSTRF1PMnodis: "",
        GSTRF1PRY2nodis: "",
        GSTRF1PRYnodis: "",
        GSTRF1PRHnodis: "",
        GSTRF1PRQnodis: "",
        GSTRF1PRMnodis: "",
  
        GSTRF1SY2DisPer: "",
        GSTRF1SYDisPer: "",
        GSTRF1SHDisPer: "",
        GSTRF1SQDisPer: "",
        GSTRF1SMDisPer: "",
        GSTRF1BY2DisPer: "",
        GSTRF1BYDisPer: "",
        GSTRF1BHDisPer: "",
        GSTRF1BQDisPer: "",
        GSTRF1BMDisPer: "",
        GSTRF1PY2DisPer: "",
        GSTRF1PYDisPer: "",
        GSTRF1PHDisPer: "",
        GSTRF1PQDisPer: "",
        GSTRF1PMDisPer: "",
        GSTRF1PRY2DisPer: "",
        GSTRF1PRYDisPer: "",
        GSTRF1PRHDisPer: "",
        GSTRF1PRQDisPer: "",
        GSTRF1PRMDisPer: "",
  
        // T - 2
  
        GSTRF2SY2: "",
        GSTRF2SY: "",
        GSTRF2SH: "",
        GSTRF2SQ: "",
        GSTRF2SM: "",
        GSTRF2BY2: "",
        GSTRF2BY: "",
        GSTRF2BH: "",
        GSTRF2BQ: "",
        GSTRF2BM: "",
        GSTRF2PY2: "",
        GSTRF2PY: "",
        GSTRF2PH: "",
        GSTRF2PQ: "",
        GSTRF2PM: "",
        GSTRF2PRY2: "",
        GSTRF2PRY: "",
        GSTRF2PRH: "",
        GSTRF2PRQ: "",
        GSTRF2PRM: "",
  
        // nodiscountprice
  
        GSTRF2SY2nodis: "",
        GSTRF2SYnodis: "",
        GSTRF2SHnodis: "",
        GSTRF2SQnodis: "",
        GSTRF2SMnodis: "",
        GSTRF2BY2nodis: "",
        GSTRF2BYnodis: "",
        GSTRF2BHnodis: "",
        GSTRF2BQnodis: "",
        GSTRF2BMnodis: "",
        GSTRF2PY2nodis: "",
        GSTRF2PYnodis: "",
        GSTRF2PHnodis: "",
        GSTRF2PQnodis: "",
        GSTRF2PMnodis: "",
        GSTRF2PRY2nodis: "",
        GSTRF2PRYnodis: "",
        GSTRF2PRHnodis: "",
        GSTRF2PRQnodis: "",
        GSTRF2PRMnodis: "",
  
        GSTRF2SY2DisPer: "",
        GSTRF2SYDisPer: "",
        GSTRF2SHDisPer: "",
        GSTRF2SQDisPer: "",
        GSTRF2SMDisPer: "",
        GSTRF2BY2DisPer: "",
        GSTRF2BYDisPer: "",
        GSTRF2BHDisPer: "",
        GSTRF2BQDisPer: "",
        GSTRF2BMDisPer: "",
        GSTRF2PY2DisPer: "",
        GSTRF2PYDisPer: "",
        GSTRF2PHDisPer: "",
        GSTRF2PQDisPer: "",
        GSTRF2PMDisPer: "",
        GSTRF2PRY2DisPer: "",
        GSTRF2PRYDisPer: "",
        GSTRF2PRHDisPer: "",
        GSTRF2PRQDisPer: "",
        GSTRF2PRMDisPer: "",
  
        // T - 3
  
        GSTRF3SY2: "",
        GSTRF3SY: "",
        GSTRF3SH: "",
        GSTRF3SQ: "",
        GSTRF3SM: "",
        GSTRF3BY2: "",
        GSTRF3BY: "",
        GSTRF3BH: "",
        GSTRF3BQ: "",
        GSTRF3BM: "",
  
        // nodiscountprice
  
        GSTRF3SY2nodis: "",
        GSTRF3SYnodis: "",
        GSTRF3SHnodis: "",
        GSTRF3SQnodis: "",
        GSTRF3SMnodis: "",
        GSTRF3BY2nodis: "",
        GSTRF3BYnodis: "",
        GSTRF3BHnodis: "",
        GSTRF3BQnodis: "",
        GSTRF3BMnodis: "",
  
        GSTRF3SY2DisPer: "",
        GSTRF3SYDisPer: "",
        GSTRF3SHDisPer: "",
        GSTRF3SQDisPer: "",
        GSTRF3SMDisPer: "",
        GSTRF3BY2DisPer: "",
        GSTRF3BYDisPer: "",
        GSTRF3BHDisPer: "",
        GSTRF3BQDisPer: "",
        GSTRF3BMDisPer: "",
  
        // T - 4
  
        GSTRF4SY2: "",
        GSTRF4SY: "",
        GSTRF4SH: "",
        GSTRF4SQ: "",
        GSTRF4SM: "",
        GSTRF4BY2: "",
        GSTRF4BY: "",
        GSTRF4BH: "",
        GSTRF4BQ: "",
        GSTRF4BM: "",
  
        // nodiscountprice
  
        GSTRF4SY2nodis: "",
        GSTRF4SYnodis: "",
        GSTRF4SHnodis: "",
        GSTRF4SQnodis: "",
        GSTRF4SMnodis: "",
        GSTRF4BY2nodis: "",
        GSTRF4BYnodis: "",
        GSTRF4BHnodis: "",
        GSTRF4BQnodis: "",
        GSTRF4BMnodis: "",
  
        GSTRF4SY2DisPer: "",
        GSTRF4SYDisPer: "",
        GSTRF4SHDisPer: "",
        GSTRF4SQDisPer: "",
        GSTRF4SMDisPer: "",
        GSTRF4BY2DisPer: "",
        GSTRF4BYDisPer: "",
        GSTRF4BHDisPer: "",
        GSTRF4BQDisPer: "",
        GSTRF4BMDisPer: "",
  
        // T - 5
  
        GSTRF5SY2: "",
        GSTRF5SY: "",
        GSTRF5SH: "",
        GSTRF5SQ: "",
        GSTRF5SM: "",
        GSTRF5BY2: "",
        GSTRF5BY: "",
        GSTRF5BH: "",
        GSTRF5BQ: "",
        GSTRF5BM: "",
  
        // nodiscountprice
  
        GSTRF5SY2nodis: "",
        GSTRF5SYnodis: "",
        GSTRF5SHnodis: "",
        GSTRF5SQnodis: "",
        GSTRF5SMnodis: "",
        GSTRF5BY2nodis: "",
        GSTRF5BYnodis: "",
        GSTRF5BHnodis: "",
        GSTRF5BQnodis: "",
        GSTRF5BMnodis: "",
  
        GSTRF5SY2DisPer: "",
        GSTRF5SYDisPer: "",
        GSTRF5SHDisPer: "",
        GSTRF5SQDisPer: "",
        GSTRF5SMDisPer: "",
        GSTRF5BY2DisPer: "",
        GSTRF5BYDisPer: "",
        GSTRF5BHDisPer: "",
        GSTRF5BQDisPer: "",
        GSTRF5BMDisPer: "",
        }
    }

    priceUpdate = () => {
      pricingJson = JSON.parse(localStorage.getItem("lshhihfnkff"));
      // FLCode = "FL11R00"
      // GSTRF Turnover: 1,  STARTER
  
      let GSTRF1SY2 = pricingJson["GSTRF1SY2"].price;
      let GSTRF1SY = pricingJson["GSTRF1SY"].price;
      let GSTRF1SH = pricingJson["GSTRF1SH"].price;
      let GSTRF1SQ = pricingJson["GSTRF1SQ"].price;
      let GSTRF1SM = pricingJson["GSTRF1SM"].price;
  
      let GSTRF1SY2nodi = pricingJson["GSTRF1SY2"].nodiscountprice;
      let GSTRF1SYnodi = pricingJson["GSTRF1SY"].nodiscountprice;
      let GSTRF1SHnodi = pricingJson["GSTRF1SH"].nodiscountprice;
      let GSTRF1SQnodi = pricingJson["GSTRF1SQ"].nodiscountprice;
      let GSTRF1SMnodi = pricingJson["GSTRF1SM"].nodiscountprice;
  
      let GSTRF1SY2DisPer = pricingJson["GSTRF1SY2"].discountpercentage;
      let GSTRF1SYDisPer = pricingJson["GSTRF1SY"].discountpercentage;
      let GSTRF1SHDisPer = pricingJson["GSTRF1SH"].discountpercentage;
      let GSTRF1SQDisPer = pricingJson["GSTRF1SQ"].discountpercentage;
      let GSTRF1SMDisPer = pricingJson["GSTRF1SM"].discountpercentage;
  
      this.setState({
        GSTRF1SY2: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF1SY2 * 0.18 + GSTRF1SY2)),
        GSTRF1SY: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF1SY * 0.18 + GSTRF1SY)),
        GSTRF1SH: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF1SH * 0.18 + GSTRF1SH)),
        GSTRF1SQ: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF1SQ * 0.18 + GSTRF1SQ)),
        GSTRF1SM: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF1SM * 0.18 + GSTRF1SM)),
  
        GSTRF1SY2nodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF1SY2nodi),
        GSTRF1SYnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF1SYnodi),
        GSTRF1SHnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF1SHnodi),
        GSTRF1SQnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF1SQnodi),
        GSTRF1SMnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF1SMnodi),
  
        GSTRF1SY2DisPer: GSTRF1SY2DisPer,
        GSTRF1SYDisPer: GSTRF1SYDisPer,
        GSTRF1SHDisPer: GSTRF1SHDisPer,
        GSTRF1SQDisPer: GSTRF1SQDisPer,
        GSTRF1SMDisPer: GSTRF1SMDisPer,
      });
  
      // GSTRF Turnover: 1,  BASIC
  
      let GSTRF1BY2 = pricingJson["GSTRF1BY2"].price;
      let GSTRF1BY = pricingJson["GSTRF1BY"].price;
      let GSTRF1BH = pricingJson["GSTRF1BH"].price;
      let GSTRF1BQ = pricingJson["GSTRF1BQ"].price;
      let GSTRF1BM = pricingJson["GSTRF1BM"].price;
  
      let GSTRF1BY2nodi = pricingJson["GSTRF1BY2"].nodiscountprice;
      let GSTRF1BYnodi = pricingJson["GSTRF1BY"].nodiscountprice;
      let GSTRF1BHnodi = pricingJson["GSTRF1BH"].nodiscountprice;
      let GSTRF1BQnodi = pricingJson["GSTRF1BQ"].nodiscountprice;
      let GSTRF1BMnodi = pricingJson["GSTRF1BM"].nodiscountprice;
  
      let GSTRF1BY2DisPer = pricingJson["GSTRF1BY2"].discountpercentage;
      let GSTRF1BYDisPer = pricingJson["GSTRF1BY"].discountpercentage;
      let GSTRF1BHDisPer = pricingJson["GSTRF1BH"].discountpercentage;
      let GSTRF1BQDisPer = pricingJson["GSTRF1BQ"].discountpercentage;
      let GSTRF1BMDisPer = pricingJson["GSTRF1BM"].discountpercentage;
  
      this.setState({
        GSTRF1BY2: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF1BY2 * 0.18 + GSTRF1BY2)),
        GSTRF1BY: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF1BY * 0.18 + GSTRF1BY)),
        GSTRF1BH: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF1BH * 0.18 + GSTRF1BH)),
        GSTRF1BQ: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF1BQ * 0.18 + GSTRF1BQ)),
        GSTRF1BM: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF1BM * 0.18 + GSTRF1BM)),
  
        GSTRF1BY2nodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF1BY2nodi),
        GSTRF1BYnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF1BYnodi),
        GSTRF1BHnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF1BHnodi),
        GSTRF1BQnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF1BQnodi),
        GSTRF1BMnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF1BMnodi),
  
        GSTRF1BY2DisPer: GSTRF1BY2DisPer,
        GSTRF1BYDisPer: GSTRF1BYDisPer,
        GSTRF1BHDisPer: GSTRF1BHDisPer,
        GSTRF1BQDisPer: GSTRF1BQDisPer,
        GSTRF1BMDisPer: GSTRF1BMDisPer,
      });
  
      // GSTRF Turnover: 1,  PRO
  
      let GSTRF1PY2 = pricingJson["GSTRF1PY2"].price;
      let GSTRF1PY = pricingJson["GSTRF1PY"].price;
      let GSTRF1PH = pricingJson["GSTRF1PH"].price;
      let GSTRF1PQ = pricingJson["GSTRF1PQ"].price;
      let GSTRF1PM = pricingJson["GSTRF1PM"].price;
  
      let GSTRF1PY2nodi = pricingJson["GSTRF1PY2"].nodiscountprice;
      let GSTRF1PYnodi = pricingJson["GSTRF1PY"].nodiscountprice;
      let GSTRF1PHnodi = pricingJson["GSTRF1PH"].nodiscountprice;
      let GSTRF1PQnodi = pricingJson["GSTRF1PQ"].nodiscountprice;
      let GSTRF1PMnodi = pricingJson["GSTRF1PM"].nodiscountprice;
  
      let GSTRF1PY2DisPer = pricingJson["GSTRF1PY2"].discountpercentage;
      let GSTRF1PYDisPer = pricingJson["GSTRF1PY"].discountpercentage;
      let GSTRF1PHDisPer = pricingJson["GSTRF1PH"].discountpercentage;
      let GSTRF1PQDisPer = pricingJson["GSTRF1PQ"].discountpercentage;
      let GSTRF1PMDisPer = pricingJson["GSTRF1PM"].discountpercentage;
  
      this.setState({
        GSTRF1PY2: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF1PY2 * 0.18 + GSTRF1PY2)),
        GSTRF1PY: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF1PY * 0.18 + GSTRF1PY)),
        GSTRF1PH: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF1PH * 0.18 + GSTRF1PH)),
        GSTRF1PQ: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF1PQ * 0.18 + GSTRF1PQ)),
        GSTRF1PM: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF1PM * 0.18 + GSTRF1PM)),
  
        GSTRF1PY2nodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF1PY2nodi),
        GSTRF1PYnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF1PYnodi),
        GSTRF1PHnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF1PHnodi),
        GSTRF1PQnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF1PQnodi),
        GSTRF1PMnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF1PMnodi),
  
        GSTRF1PY2DisPer: GSTRF1PY2DisPer,
        GSTRF1PYDisPer: GSTRF1PYDisPer,
        GSTRF1PHDisPer: GSTRF1PHDisPer,
        GSTRF1PQDisPer: GSTRF1PQDisPer,
        GSTRF1PMDisPer: GSTRF1PMDisPer,
      });
  
      // GSTRF Turnover: 1, PREMIU
  
      let GSTRF1PRY2 = pricingJson["GSTRF1PRY2"].price;
      let GSTRF1PRY = pricingJson["GSTRF1PRY"].price;
      let GSTRF1PRH = pricingJson["GSTRF1PRH"].price;
      let GSTRF1PRQ = pricingJson["GSTRF1PRQ"].price;
      let GSTRF1PRM = pricingJson["GSTRF1PRM"].price;
  
      let GSTRF1PRY2nodi = pricingJson["GSTRF1PRY2"].nodiscountprice;
      let GSTRF1PRYnodi = pricingJson["GSTRF1PRY"].nodiscountprice;
      let GSTRF1PRHnodi = pricingJson["GSTRF1PRH"].nodiscountprice;
      let GSTRF1PRQnodi = pricingJson["GSTRF1PRQ"].nodiscountprice;
      let GSTRF1PRMnodi = pricingJson["GSTRF1PRM"].nodiscountprice;
  
      let GSTRF1PRY2DisPer = pricingJson["GSTRF1PRY2"].discountpercentage;
      let GSTRF1PRYDisPer = pricingJson["GSTRF1PRY"].discountpercentage;
      let GSTRF1PRHDisPer = pricingJson["GSTRF1PRH"].discountpercentage;
      let GSTRF1PRQDisPer = pricingJson["GSTRF1PRQ"].discountpercentage;
      let GSTRF1PRMDisPer = pricingJson["GSTRF1PRM"].discountpercentage;
  
      this.setState({
        GSTRF1PRY2: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF1PRY2 * 0.18 + GSTRF1PRY2)),
        GSTRF1PRY: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF1PRY * 0.18 + GSTRF1PRY)),
        GSTRF1PRH: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF1PRH * 0.18 + GSTRF1PRH)),
        GSTRF1PRQ: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF1PRQ * 0.18 + GSTRF1PRQ)),
        GSTRF1PRM: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF1PRM * 0.18 + GSTRF1PRM)),
  
        GSTRF1PRY2nodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF1PRY2nodi),
        GSTRF1PRYnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF1PRYnodi),
        GSTRF1PRHnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF1PRHnodi),
        GSTRF1PRQnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF1PRQnodi),
        GSTRF1PRMnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF1PRMnodi),
  
        GSTRF1PRY2DisPer: GSTRF1PRY2DisPer,
        GSTRF1PRYDisPer: GSTRF1PRYDisPer,
        GSTRF1PRHDisPer: GSTRF1PRHDisPer,
        GSTRF1PRQDisPer: GSTRF1PRQDisPer,
        GSTRF1PRMDisPer: GSTRF1PRMDisPer,
      });
  
      // GSTRF Turnover: 2,  STARTER
  
      let GSTRF2SY2 = pricingJson["GSTRF2SY2"].price;
      let GSTRF2SY = pricingJson["GSTRF2SY"].price;
      let GSTRF2SH = pricingJson["GSTRF2SH"].price;
      let GSTRF2SQ = pricingJson["GSTRF2SQ"].price;
      let GSTRF2SM = pricingJson["GSTRF2SM"].price;
  
      let GSTRF2SY2nodi = pricingJson["GSTRF2SY2"].nodiscountprice;
      let GSTRF2SYnodi = pricingJson["GSTRF2SY"].nodiscountprice;
      let GSTRF2SHnodi = pricingJson["GSTRF2SH"].nodiscountprice;
      let GSTRF2SQnodi = pricingJson["GSTRF2SQ"].nodiscountprice;
      let GSTRF2SMnodi = pricingJson["GSTRF2SM"].nodiscountprice;
  
      let GSTRF2SY2DisPer = pricingJson["GSTRF2SY2"].discountpercentage;
      let GSTRF2SYDisPer = pricingJson["GSTRF2SY"].discountpercentage;
      let GSTRF2SHDisPer = pricingJson["GSTRF2SH"].discountpercentage;
      let GSTRF2SQDisPer = pricingJson["GSTRF2SQ"].discountpercentage;
      let GSTRF2SMDisPer = pricingJson["GSTRF2SM"].discountpercentage;
  
      this.setState({
        GSTRF2SY2: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF2SY2 * 0.18 + GSTRF2SY2)),
        GSTRF2SY: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF2SY * 0.18 + GSTRF2SY)),
        GSTRF2SH: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF2SH * 0.18 + GSTRF2SH)),
        GSTRF2SQ: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF2SQ * 0.18 + GSTRF2SQ)),
        GSTRF2SM: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF2SM * 0.18 + GSTRF2SM)),
  
        GSTRF2SY2nodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF2SY2nodi),
        GSTRF2SYnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF2SYnodi),
        GSTRF2SHnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF2SHnodi),
        GSTRF2SQnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF2SQnodi),
        GSTRF2SMnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF2SMnodi),
  
        GSTRF2SY2DisPer: GSTRF2SY2DisPer,
        GSTRF2SYDisPer: GSTRF2SYDisPer,
        GSTRF2SHDisPer: GSTRF2SHDisPer,
        GSTRF2SQDisPer: GSTRF2SQDisPer,
        GSTRF2SMDisPer: GSTRF2SMDisPer,
      });
  
      // GSTRF Turnover: 2,  BASIC
  
      let GSTRF2BY2 = pricingJson["GSTRF2BY2"].price;
      let GSTRF2BY = pricingJson["GSTRF2BY"].price;
      let GSTRF2BH = pricingJson["GSTRF2BH"].price;
      let GSTRF2BQ = pricingJson["GSTRF2BQ"].price;
      let GSTRF2BM = pricingJson["GSTRF2BM"].price;
  
      let GSTRF2BY2nodi = pricingJson["GSTRF2BY2"].nodiscountprice;
      let GSTRF2BYnodi = pricingJson["GSTRF2BY"].nodiscountprice;
      let GSTRF2BHnodi = pricingJson["GSTRF2BH"].nodiscountprice;
      let GSTRF2BQnodi = pricingJson["GSTRF2BQ"].nodiscountprice;
      let GSTRF2BMnodi = pricingJson["GSTRF2BM"].nodiscountprice;
  
      let GSTRF2BY2DisPer = pricingJson["GSTRF2BY2"].discountpercentage;
      let GSTRF2BYDisPer = pricingJson["GSTRF2BY"].discountpercentage;
      let GSTRF2BHDisPer = pricingJson["GSTRF2BH"].discountpercentage;
      let GSTRF2BQDisPer = pricingJson["GSTRF2BQ"].discountpercentage;
      let GSTRF2BMDisPer = pricingJson["GSTRF2BM"].discountpercentage;
  
      this.setState({
        GSTRF2BY2: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF2BY2 * 0.18 + GSTRF2BY2)),
        GSTRF2BY: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF2BY * 0.18 + GSTRF2BY)),
        GSTRF2BH: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF2BH * 0.18 + GSTRF2BH)),
        GSTRF2BQ: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF2BQ * 0.18 + GSTRF2BQ)),
        GSTRF2BM: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF2BM * 0.18 + GSTRF2BM)),
  
        GSTRF2BY2nodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF2BY2nodi),
        GSTRF2BYnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF2BYnodi),
        GSTRF2BHnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF2BHnodi),
        GSTRF2BQnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF2BQnodi),
        GSTRF2BMnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF2BMnodi),
  
        GSTRF2BY2DisPer: GSTRF2BY2DisPer,
        GSTRF2BYDisPer: GSTRF2BYDisPer,
        GSTRF2BHDisPer: GSTRF2BHDisPer,
        GSTRF2BQDisPer: GSTRF2BQDisPer,
        GSTRF2BMDisPer: GSTRF2BMDisPer,
      });
  
      // GSTRF Turnover: 2,  PRO
  
      let GSTRF2PY2 = pricingJson["GSTRF2PY2"].price;
      let GSTRF2PY = pricingJson["GSTRF2PY"].price;
      let GSTRF2PH = pricingJson["GSTRF2PH"].price;
      let GSTRF2PQ = pricingJson["GSTRF2PQ"].price;
      let GSTRF2PM = pricingJson["GSTRF2PM"].price;
  
      let GSTRF2PY2nodi = pricingJson["GSTRF2PY2"].nodiscountprice;
      let GSTRF2PYnodi = pricingJson["GSTRF2PY"].nodiscountprice;
      let GSTRF2PHnodi = pricingJson["GSTRF2PH"].nodiscountprice;
      let GSTRF2PQnodi = pricingJson["GSTRF2PQ"].nodiscountprice;
      let GSTRF2PMnodi = pricingJson["GSTRF2PM"].nodiscountprice;
  
      let GSTRF2PY2DisPer = pricingJson["GSTRF2PY2"].discountpercentage;
      let GSTRF2PYDisPer = pricingJson["GSTRF2PY"].discountpercentage;
      let GSTRF2PHDisPer = pricingJson["GSTRF2PH"].discountpercentage;
      let GSTRF2PQDisPer = pricingJson["GSTRF2PQ"].discountpercentage;
      let GSTRF2PMDisPer = pricingJson["GSTRF2PM"].discountpercentage;
      this.setState({
        GSTRF2PY2: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF2PY2 * 0.18 + GSTRF2PY2)),
        GSTRF2PY: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF2PY * 0.18 + GSTRF2PY)),
        GSTRF2PH: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF2PH * 0.18 + GSTRF2PH)),
        GSTRF2PQ: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF2PQ * 0.18 + GSTRF2PQ)),
        GSTRF2PM: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF2PM * 0.18 + GSTRF2PM)),
  
        GSTRF2PY2nodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF2PY2nodi),
        GSTRF2PYnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF2PYnodi),
        GSTRF2PHnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF2PHnodi),
        GSTRF2PQnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF2PQnodi),
        GSTRF2PMnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF2PMnodi),
  
        GSTRF2PY2DisPer: GSTRF2PY2DisPer,
        GSTRF2PYDisPer: GSTRF2PYDisPer,
        GSTRF2PHDisPer: GSTRF2PHDisPer,
        GSTRF2PQDisPer: GSTRF2PQDisPer,
        GSTRF2PMDisPer: GSTRF2PMDisPer,
      });
  
      // GSTRF Turnover: 2, PREMIU
  
      let GSTRF2PRY2 = pricingJson["GSTRF2PRY2"].price;
      let GSTRF2PRY = pricingJson["GSTRF2PRY"].price;
      let GSTRF2PRH = pricingJson["GSTRF2PRH"].price;
      let GSTRF2PRQ = pricingJson["GSTRF2PRQ"].price;
      let GSTRF2PRM = pricingJson["GSTRF2PRM"].price;
  
      let GSTRF2PRY2nodi = pricingJson["GSTRF2PRY2"].nodiscountprice;
      let GSTRF2PRYnodi = pricingJson["GSTRF2PRY"].nodiscountprice;
      let GSTRF2PRHnodi = pricingJson["GSTRF2PRH"].nodiscountprice;
      let GSTRF2PRQnodi = pricingJson["GSTRF2PRQ"].nodiscountprice;
      let GSTRF2PRMnodi = pricingJson["GSTRF2PRM"].nodiscountprice;
  
      let GSTRF2PRY2DisPer = pricingJson["GSTRF2PRY2"].discountpercentage;
      let GSTRF2PRYDisPer = pricingJson["GSTRF2PRY"].discountpercentage;
      let GSTRF2PRHDisPer = pricingJson["GSTRF2PRH"].discountpercentage;
      let GSTRF2PRQDisPer = pricingJson["GSTRF2PRQ"].discountpercentage;
      let GSTRF2PRMDisPer = pricingJson["GSTRF2PRM"].discountpercentage;
      this.setState({
        GSTRF2PRY2: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF2PRY2 * 0.18 + GSTRF2PRY2)),
        GSTRF2PRY: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF2PRY * 0.18 + GSTRF2PRY)),
        GSTRF2PRH: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF2PRH * 0.18 + GSTRF2PRH)),
        GSTRF2PRQ: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF2PRQ * 0.18 + GSTRF2PRQ)),
        GSTRF2PRM: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF2PRM * 0.18 + GSTRF2PRM)),
  
        GSTRF2PRY2nodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF2PRY2nodi),
        GSTRF2PRYnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF2PRYnodi),
        GSTRF2PRHnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF2PRHnodi),
        GSTRF2PRQnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF2PRQnodi),
        GSTRF2PRMnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF2PRMnodi),
  
        GSTRF2PRY2DisPer: GSTRF2PRY2DisPer,
        GSTRF2PRYDisPer: GSTRF2PRYDisPer,
        GSTRF2PRHDisPer: GSTRF2PRHDisPer,
        GSTRF2PRQDisPer: GSTRF2PRQDisPer,
        GSTRF2PRMDisPer: GSTRF2PRMDisPer,
      });
  
      // GSTRF Turnover: 3,  STARTER
  
      let GSTRF3SY2 = pricingJson["GSTRF3SY2"].price;
      let GSTRF3SY = pricingJson["GSTRF3SY"].price;
      let GSTRF3SH = pricingJson["GSTRF3SH"].price;
      let GSTRF3SQ = pricingJson["GSTRF3SQ"].price;
      let GSTRF3SM = pricingJson["GSTRF3SM"].price;
  
      let GSTRF3SY2nodi = pricingJson["GSTRF3SY2"].nodiscountprice;
      let GSTRF3SYnodi = pricingJson["GSTRF3SY"].nodiscountprice;
      let GSTRF3SHnodi = pricingJson["GSTRF3SH"].nodiscountprice;
      let GSTRF3SQnodi = pricingJson["GSTRF3SQ"].nodiscountprice;
      let GSTRF3SMnodi = pricingJson["GSTRF3SM"].nodiscountprice;
  
      let GSTRF3SY2DisPer = pricingJson["GSTRF3SY2"].discountpercentage;
      let GSTRF3SYDisPer = pricingJson["GSTRF3SY"].discountpercentage;
      let GSTRF3SHDisPer = pricingJson["GSTRF3SH"].discountpercentage;
      let GSTRF3SQDisPer = pricingJson["GSTRF3SQ"].discountpercentage;
      let GSTRF3SMDisPer = pricingJson["GSTRF3SM"].discountpercentage;
      this.setState({
        GSTRF3SY2: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF3SY2 * 0.18 + GSTRF3SY2)),
        GSTRF3SY: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF3SY * 0.18 + GSTRF3SY)),
        GSTRF3SH: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF3SH * 0.18 + GSTRF3SH)),
        GSTRF3SQ: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF3SQ * 0.18 + GSTRF3SQ)),
        GSTRF3SM: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF3SM * 0.18 + GSTRF3SM)),
  
        GSTRF3SY2nodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF3SY2nodi),
        GSTRF3SYnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF3SYnodi),
        GSTRF3SHnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF3SHnodi),
        GSTRF3SQnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF3SQnodi),
        GSTRF3SMnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF3SMnodi),
  
        GSTRF3SY2DisPer: GSTRF3SY2DisPer,
        GSTRF3SYDisPer: GSTRF3SYDisPer,
        GSTRF3SHDisPer: GSTRF3SHDisPer,
        GSTRF3SQDisPer: GSTRF3SQDisPer,
        GSTRF3SMDisPer: GSTRF3SMDisPer,
      });
  
      // GSTRF Turnover: 3,  BASIC
  
      let GSTRF3BY2 = pricingJson["GSTRF3BY2"].price;
      let GSTRF3BY = pricingJson["GSTRF3BY"].price;
      let GSTRF3BH = pricingJson["GSTRF3BH"].price;
      let GSTRF3BQ = pricingJson["GSTRF3BQ"].price;
      let GSTRF3BM = pricingJson["GSTRF3BM"].price;
  
      let GSTRF3BY2nodi = pricingJson["GSTRF3BY2"].nodiscountprice;
      let GSTRF3BYnodi = pricingJson["GSTRF3BY"].nodiscountprice;
      let GSTRF3BHnodi = pricingJson["GSTRF3BH"].nodiscountprice;
      let GSTRF3BQnodi = pricingJson["GSTRF3BQ"].nodiscountprice;
      let GSTRF3BMnodi = pricingJson["GSTRF3BM"].nodiscountprice;
  
      let GSTRF3BY2DisPer = pricingJson["GSTRF3BY2"].discountpercentage;
      let GSTRF3BYDisPer = pricingJson["GSTRF3BY"].discountpercentage;
      let GSTRF3BHDisPer = pricingJson["GSTRF3BH"].discountpercentage;
      let GSTRF3BQDisPer = pricingJson["GSTRF3BQ"].discountpercentage;
      let GSTRF3BMDisPer = pricingJson["GSTRF3BM"].discountpercentage;
  
      this.setState({
        GSTRF3BY2: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF3BY2 * 0.18 + GSTRF3BY2)),
        GSTRF3BY: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF3BY * 0.18 + GSTRF3BY)),
        GSTRF3BH: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF3BH * 0.18 + GSTRF3BH)),
        GSTRF3BQ: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF3BQ * 0.18 + GSTRF3BQ)),
        GSTRF3BM: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF3BM * 0.18 + GSTRF3BM)),
  
        GSTRF3BY2nodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF3BY2nodi),
        GSTRF3BYnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF3BYnodi),
        GSTRF3BHnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF3BHnodi),
        GSTRF3BQnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF3BQnodi),
        GSTRF3BMnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF3BMnodi),
  
        GSTRF3BY2DisPer: GSTRF3BY2DisPer,
        GSTRF3BYDisPer: GSTRF3BYDisPer,
        GSTRF3BHDisPer: GSTRF3BHDisPer,
        GSTRF3BQDisPer: GSTRF3BQDisPer,
        GSTRF3BMDisPer: GSTRF3BMDisPer,
      });
  
      // GSTRF Turnover: 4,  STARTER
  
      let GSTRF4SY2 = pricingJson["GSTRF4SY2"].price;
      let GSTRF4SY = pricingJson["GSTRF4SY"].price;
      let GSTRF4SH = pricingJson["GSTRF4SH"].price;
      let GSTRF4SQ = pricingJson["GSTRF4SQ"].price;
      let GSTRF4SM = pricingJson["GSTRF4SM"].price;
  
      let GSTRF4SY2nodi = pricingJson["GSTRF4SY2"].nodiscountprice;
      let GSTRF4SYnodi = pricingJson["GSTRF4SY"].nodiscountprice;
      let GSTRF4SHnodi = pricingJson["GSTRF4SH"].nodiscountprice;
      let GSTRF4SQnodi = pricingJson["GSTRF4SQ"].nodiscountprice;
      let GSTRF4SMnodi = pricingJson["GSTRF4SM"].nodiscountprice;
  
      let GSTRF4SY2DisPer = pricingJson["GSTRF4SY2"].discountpercentage;
      let GSTRF4SYDisPer = pricingJson["GSTRF4SY"].discountpercentage;
      let GSTRF4SHDisPer = pricingJson["GSTRF4SH"].discountpercentage;
      let GSTRF4SQDisPer = pricingJson["GSTRF4SQ"].discountpercentage;
      let GSTRF4SMDisPer = pricingJson["GSTRF4SM"].discountpercentage;
      this.setState({
        GSTRF4SY2: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF4SY2 * 0.18 + GSTRF4SY2)),
        GSTRF4SY: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF4SY * 0.18 + GSTRF4SY)),
        GSTRF4SH: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF4SH * 0.18 + GSTRF4SH)),
        GSTRF4SQ: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF4SQ * 0.18 + GSTRF4SQ)),
        GSTRF4SM: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF4SM * 0.18 + GSTRF4SM)),
  
        GSTRF4SY2nodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF4SY2nodi),
        GSTRF4SYnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF4SYnodi),
        GSTRF4SHnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF4SHnodi),
        GSTRF4SQnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF4SQnodi),
        GSTRF4SMnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF4SMnodi),
  
        GSTRF4SY2DisPer: GSTRF4SY2DisPer,
        GSTRF4SYDisPer: GSTRF4SYDisPer,
        GSTRF4SHDisPer: GSTRF4SHDisPer,
        GSTRF4SQDisPer: GSTRF4SQDisPer,
        GSTRF4SMDisPer: GSTRF4SMDisPer,
      });
  
      // GSTRF Turnover: 4,  BASIC
  
      let GSTRF4BY2 = pricingJson["GSTRF4BY2"].price;
      let GSTRF4BY = pricingJson["GSTRF4BY"].price;
      let GSTRF4BH = pricingJson["GSTRF4BH"].price;
      let GSTRF4BQ = pricingJson["GSTRF4BQ"].price;
      let GSTRF4BM = pricingJson["GSTRF4BM"].price;
  
      let GSTRF4BY2nodi = pricingJson["GSTRF4BY2"].nodiscountprice;
      let GSTRF4BYnodi = pricingJson["GSTRF4BY"].nodiscountprice;
      let GSTRF4BHnodi = pricingJson["GSTRF4BH"].nodiscountprice;
      let GSTRF4BQnodi = pricingJson["GSTRF4BQ"].nodiscountprice;
      let GSTRF4BMnodi = pricingJson["GSTRF4BM"].nodiscountprice;
  
      let GSTRF4BY2DisPer = pricingJson["GSTRF4BY2"].discountpercentage;
      let GSTRF4BYDisPer = pricingJson["GSTRF4BY"].discountpercentage;
      let GSTRF4BHDisPer = pricingJson["GSTRF4BH"].discountpercentage;
      let GSTRF4BQDisPer = pricingJson["GSTRF4BQ"].discountpercentage;
      let GSTRF4BMDisPer = pricingJson["GSTRF4BM"].discountpercentage;
      this.setState({
        GSTRF4BY2: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF4BY2 * 0.18 + GSTRF4BY2)),
        GSTRF4BY: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF4BY * 0.18 + GSTRF4BY)),
        GSTRF4BH: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF4BH * 0.18 + GSTRF4BH)),
        GSTRF4BQ: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF4BQ * 0.18 + GSTRF4BQ)),
        GSTRF4BM: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF4BM * 0.18 + GSTRF4BM)),
  
        GSTRF4BY2nodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF4BY2nodi),
        GSTRF4BYnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF4BYnodi),
        GSTRF4BHnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF4BHnodi),
        GSTRF4BQnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF4BQnodi),
        GSTRF4BMnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF4BMnodi),
  
        GSTRF4BY2DisPer: GSTRF4BY2DisPer,
        GSTRF4BYDisPer: GSTRF4BYDisPer,
        GSTRF4BHDisPer: GSTRF4BHDisPer,
        GSTRF4BQDisPer: GSTRF4BQDisPer,
        GSTRF4BMDisPer: GSTRF4BMDisPer,
      });
  
      // GSTRF Turnover: 5,  STARTER
  
      let GSTRF5SY2 = pricingJson["GSTRF5SY2"].price;
      let GSTRF5SY = pricingJson["GSTRF5SY"].price;
      let GSTRF5SH = pricingJson["GSTRF5SH"].price;
      let GSTRF5SQ = pricingJson["GSTRF5SQ"].price;
      let GSTRF5SM = pricingJson["GSTRF5SM"].price;
  
      let GSTRF5SY2nodi = pricingJson["GSTRF5SY2"].nodiscountprice;
      let GSTRF5SYnodi = pricingJson["GSTRF5SY"].nodiscountprice;
      let GSTRF5SHnodi = pricingJson["GSTRF5SH"].nodiscountprice;
      let GSTRF5SQnodi = pricingJson["GSTRF5SQ"].nodiscountprice;
      let GSTRF5SMnodi = pricingJson["GSTRF5SM"].nodiscountprice;
  
      let GSTRF5SY2DisPer = pricingJson["GSTRF5SY2"].discountpercentage;
      let GSTRF5SYDisPer = pricingJson["GSTRF5SY"].discountpercentage;
      let GSTRF5SHDisPer = pricingJson["GSTRF5SH"].discountpercentage;
      let GSTRF5SQDisPer = pricingJson["GSTRF5SQ"].discountpercentage;
      let GSTRF5SMDisPer = pricingJson["GSTRF5SM"].discountpercentage;
  
      this.setState({
        GSTRF5SY2: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF5SY2 * 0.18 + GSTRF5SY2)),
        GSTRF5SY: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF5SY * 0.18 + GSTRF5SY)),
        GSTRF5SH: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF5SH * 0.18 + GSTRF5SH)),
        GSTRF5SQ: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF5SQ * 0.18 + GSTRF5SQ)),
        GSTRF5SM: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF5SM * 0.18 + GSTRF5SM)),
  
        GSTRF5SY2nodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF5SY2nodi),
        GSTRF5SYnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF5SYnodi),
        GSTRF5SHnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF5SHnodi),
        GSTRF5SQnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF5SQnodi),
        GSTRF5SMnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF5SMnodi),
  
        GSTRF5SY2DisPer: GSTRF5SY2DisPer,
        GSTRF5SYDisPer: GSTRF5SYDisPer,
        GSTRF5SHDisPer: GSTRF5SHDisPer,
        GSTRF5SQDisPer: GSTRF5SQDisPer,
        GSTRF5SMDisPer: GSTRF5SMDisPer,
      });
  
      // GSTRF Turnover: 5,  BASIC
  
      let GSTRF5BY2 = pricingJson["GSTRF5BY2"].price;
      let GSTRF5BY = pricingJson["GSTRF5BY"].price;
      let GSTRF5BH = pricingJson["GSTRF5BH"].price;
      let GSTRF5BQ = pricingJson["GSTRF5BQ"].price;
      let GSTRF5BM = pricingJson["GSTRF5BM"].price;
  
      let GSTRF5BY2nodi = pricingJson["GSTRF5BY2"].nodiscountprice;
      let GSTRF5BYnodi = pricingJson["GSTRF5BY"].nodiscountprice;
      let GSTRF5BHnodi = pricingJson["GSTRF5BH"].nodiscountprice;
      let GSTRF5BQnodi = pricingJson["GSTRF5BQ"].nodiscountprice;
      let GSTRF5BMnodi = pricingJson["GSTRF5BM"].nodiscountprice;
  
      let GSTRF5BY2DisPer = pricingJson["GSTRF5BY2"].discountpercentage;
      let GSTRF5BYDisPer = pricingJson["GSTRF5BY"].discountpercentage;
      let GSTRF5BHDisPer = pricingJson["GSTRF5BH"].discountpercentage;
      let GSTRF5BQDisPer = pricingJson["GSTRF5BQ"].discountpercentage;
      let GSTRF5BMDisPer = pricingJson["GSTRF5BM"].discountpercentage;
  
      this.setState({
        GSTRF5BY2: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF5BY2 * 0.18 + GSTRF5BY2)),
        GSTRF5BY: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF5BY * 0.18 + GSTRF5BY)),
        GSTRF5BH: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF5BH * 0.18 + GSTRF5BH)),
        GSTRF5BQ: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF5BQ * 0.18 + GSTRF5BQ)),
        GSTRF5BM: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(Math.round(GSTRF5BM * 0.18 + GSTRF5BM)),
  
        GSTRF5BY2nodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF5BY2nodi),
        GSTRF5BYnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF5BYnodi),
        GSTRF5BHnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF5BHnodi),
        GSTRF5BQnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF5BQnodi),
        GSTRF5BMnodis: new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        }).format(GSTRF5BMnodi),
  
        GSTRF5BY2DisPer: GSTRF5BY2DisPer,
        GSTRF5BYDisPer: GSTRF5BYDisPer,
        GSTRF5BHDisPer: GSTRF5BHDisPer,
        GSTRF5BQDisPer: GSTRF5BQDisPer,
        GSTRF5BMDisPer: GSTRF5BMDisPer,
      });
    };


    firstdropdownlichange = (e) => {
        this.setState({
            firstdropdownvalue: $(e.currentTarget).attr("value")
        })
        var getValue = $(e.currentTarget).html();
        $('.firstdropdown').html(getValue);
    }
    seconddropdownlichange = (e) => {
        this.setState({
            seconddropdownvalue: $(e.currentTarget).attr("value")
        })
        var getValue = $(e.currentTarget).html();
        $('.seconddropdown').html(getValue);
    }
    thirddropdownlichange = (e) => {
        this.setState({
            thirddropdownvalue: $(e.currentTarget).attr("value")
        })
        var getValue = $(e.currentTarget).html();
        $('.thirddropdown').html(getValue);
    }
    fourdropdownlichange = (e) => {
        this.setState({
            fourdropdownvalue: $(e.currentTarget).attr("value")
        })
        var getValue = $(e.currentTarget).html();
        $('.fourdropdown').html(getValue);
    }
    fivedropdownlichange = (e) => {
        this.setState({
            fivedropdownvalue: $(e.currentTarget).attr("value")
        })
        var getValue = $(e.currentTarget).html();
        $('.fivedropdown').html(getValue);
    }
    sixdropdownlichange = (e) => {
        this.setState({
            sixdropdownvalue: $(e.currentTarget).attr("value")
        })
        var getValue = $(e.currentTarget).html();
        $('.sixdropdown').html(getValue);
    }
    sevendropdownlichange = (e) => {
        this.setState({
            sevendropdownvalue: $(e.currentTarget).attr("value")
        })
        var getValue = $(e.currentTarget).html();
        $('.sevendropdown').html(getValue);
    }
    eightdropdownlichange = (e) => {
        this.setState({
            eightdropdownvalue: $(e.currentTarget).attr("value")
        })
        var getValue = $(e.currentTarget).html();
        $('.eightdropdown').html(getValue);
    }
    ninedropdownlichange = (e) => {
        this.setState({
            ninedropdownvalue: $(e.currentTarget).attr("value")
        })
        var getValue = $(e.currentTarget).html();
        $('.ninedropdown').html(getValue);
    }
    tendropdownlichange = (e) => {
        this.setState({
            tendropdownvalue: $(e.currentTarget).attr("value")
        })
        var getValue = $(e.currentTarget).html();
        $('.tendropdown').html(getValue);
    }
    elevenropdownlichange = (e) => {
        this.setState({
            elevendropdownvalue: $(e.currentTarget).attr("value")
        })
        var getValue = $(e.currentTarget).html();
        $('.elevendropdown').html(getValue);
    }
    twelveddropdownlichange = (e) => {
        this.setState({
            twelvedropdownvalue: $(e.currentTarget).attr("value")
        })
        var getValue = $(e.currentTarget).html();
        $('.twelvedropdown').html(getValue);
    }
    thirteendropdownlichange = (e) => {
        this.setState({
            thirteendropdownvalue: $(e.currentTarget).attr("value")
        })
        var getValue = $(e.currentTarget).html();
        $('.thirteendropdown').html(getValue);
    }
    fourteendropdownlichange = (e) => {
        this.setState({
            fourteendropdownvalue: $(e.currentTarget).attr("value")
        })
        var getValue = $(e.currentTarget).html();
        $('.fourteendropdown').html(getValue);
    }

    updateplan = () => {
        const contactNoregex = /^[6-9]\d{9}$/;
        const emailregex = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
        if (this.state.servicefullname.trim() == "") {

            $('.errorside').show()
            $('.errorsidespan').show().html(
                ' Please enter your name.');
            $('#name').css({
                'border-bottom-color': '#fe0c0c'
            });
            $('#name').focus();
        }
        else if (this.state.servicefullname.trim() == "" || this.state.servicefullname.length > 50) {
            $('.errorside').show()
            $('.errorsidespan').show().html(
                ' Please enter a valid name.');
            $('#name').css({
                'border-bottom-color': '#fe0c0c'
            });
            $('#name').focus();
        }
        else if (
            this.state.serviceemail.trim() == "") {
            $('.errorside').show()
            $('.errorsidespan').show().html(
                ' Please enter your email.');
            $('#name').css({
                'border-bottom-color': '#809aae'
            });
            $('#email').css({
                'border-bottom-color': '#fe0c0c'
            });
            $('#email').focus();
        }
        else if (
            !emailregex.test(this.state.serviceemail) ||
            this.state.serviceemail.length > 50
        ) {
            $('.errorside').show()
            $('.errorsidespan').show().html(
                ' Please enter valid email.');
            $('#email').css({
                'border-bottom-color': '#fe0c0c'
            });
            $('#email').focus();
        }

        else if (
            this.state.servicemobileno.trim() == "") {
            $('.errorside').show()
            $('.errorsidespan').show().html(
                ' Please enter your number.');
            $('#name').css({
                'border-bottom-color': '#809aae'
            });
            $('#email').css({
                'border-bottom-color': '#809aae'
            });
            $('#contact').css({
                'border-bottom-color': '#fe0c0c'
            });
            $('#contact').focus();
        }
        else if (
            this.state.servicemobileno.trim() == "" ||
            !contactNoregex.test(this.state.servicemobileno)) {
            $('.errorside').show()
            $('.errorsidespan').show().html(
                ' Please enter valid number.');
            $('#name').css({
                'border-bottom-color': '#809aae'
            });
            $('#email').css({
                'border-bottom-color': '#809aae'
            });
            $('#contact').css({
                'border-bottom-color': '#fe0c0c'
            });
            $('#contact').focus();
        }
        else if (this.state.citydistrictselectvalue.trim() == "") {
            $('.errorside').show()
            $('.errorsidespan').show().html(
                ' Please enter your city.');
            $('#name').css({
                'border-bottom-color': '#809aae'
            });
            $('#email').css({
                'border-bottom-color': '#809aae'
            });
            $('#contact').css({
                'border-bottom-color': '#809aae'
            });
            $('#city').css({
                'border-bottom-color': '#fe0c0c'
            });
            $('#city').focus();

        }
        else {
            this.filterDistricts();
            
        var stateshortcode = Object.keys(json2[0]).find(key => json2[0][key] === servicestate);
        var mainobject =
        pricingJson[
            "GSTRF" +
              this.state.dropDownValue +
              this.state.mainplan +
              this.state.mainduration
          ];
        var gstvalue = mainobject.price * 0.18;
        sessionStorage.setItem("newuseremail", (this.state.serviceemail))
        sessionStorage.setItem("newusercontact", (this.state.servicemobileno))
        data = {
          userId: "",
          serviceName: "GSTRF",
          purchasename: this.state.servicefullname,
          citydistrict: this.state.citydistrictselectvalue,
          state: servicestate,
          serviceType: "",
          duration: this.state.mainduration,
          plan: this.state.mainplan,
          turnover: this.state.dropDownValue,
          package: "",
          price: mainobject.price,
          gstvalue: gstvalue,
          govtfees: mainobject.govtfees,
          servicefullname: "GST Return Filing",
          billingName: this.state.servicefullname,
        billingCity: this.state.citydistrictselectvalue,
         billingState: jsonData[0][this.state.citydistrictselectvalue],
         stateShortCode: stateshortcode,
        };
  
        var sessiondata;
        sessiondata = sessionStorage.getItem("data");
        if (sessiondata == null || sessiondata == undefined || sessiondata == "") {
          sessiondata = [];
        } else {
          sessiondata = JSON.parse(sessionStorage.getItem("data"));
        }
    
        if (sessiondata.length == 0) {
          var localvar = [];
          localvar.push(data);
          sessionStorage.setItem("data", JSON.stringify(localvar));
          this.showNotification("Item Added to Cart")
        } else if (sessiondata.length > 0) {
          var arraylen = sessiondata.length;
          // for (let index = 0; index < arraylen; index++) {
          //   if (sessiondata[index].serviceName == "GSTRF") {
          //     sessiondata.splice(index, 1);
          //     break;
          //   }
          // }
          sessiondata.push(data);
          sessionStorage.setItem("data", JSON.stringify(sessiondata));
          this.showNotification("Item Added to Cart")
        }
        this.setState({
            dataupdated: true,
          });
        }
      };




    firstdropdowngetstartedclick = (e) => {

        $(".plangetstartedbtn").show();
        $(".getform").hide();
        $(e.target).closest('.formplacer').append(myform.slideDown());
        $(".hidesalcon1").hide();
        $(".hidesalcon2").show();
        $(".hidesalcon3").show();
        $(".hidesalcon4").show();
        $(".hidesalcon5").show();
        $(".hidesalcon6").show();
        $(".hidesalcon7").show();
        $(".hidesalcon8").show();
        $(".hidesalcon9").show();
        $(".hidesalcon10").show();
        $(".hidesalcon11").show();
        $(".hidesalcon12").show();
        $(".hidesalcon13").show();
        $(".hidesalcon14").show();









        this.setState({
            mainduration: this.state.firstdropdownvalue,
            mainplan: "S",
            citydistrictselectvalue: "",
            servicefullname: "",
          
        });
    }
    addToCartfirstdropdowngetstartedclick = () => {
   
        this.updateplan();
     
  };
    seconddropdowngetstartedclick = (e) => {
        $(".plangetstartedbtn").show();
        $(".getform2").hide();
        $(e.target).closest('.formplacer').append(myform.slideDown());
        $(".hidesalcon1").show();
        $(".hidesalcon2").hide();
        $(".hidesalcon3").show();
        $(".hidesalcon4").show();
        $(".hidesalcon5").show();
        $(".hidesalcon6").show();
        $(".hidesalcon7").show();
        $(".hidesalcon8").show();
        $(".hidesalcon9").show();
        $(".hidesalcon10").show();
        $(".hidesalcon11").show();
        $(".hidesalcon12").show();
        $(".hidesalcon13").show();
        $(".hidesalcon14").show();







        this.setState({
            mainduration: this.state.seconddropdownvalue,
            mainplan: "B",
            citydistrictselectvalue: "",
            servicefullname: "",
       
        }
        );
    }
    thirddropdowngetstartedclick = (e) => {
        $(".plangetstartedbtn").show();
        $(".getform3").hide();
        $(e.target).closest('.formplacer').append(myform.slideDown());
        $(".hidesalcon1").show();
        $(".hidesalcon2").show();
        $(".hidesalcon3").hide();
        $(".hidesalcon4").show();
        $(".hidesalcon5").show();
        $(".hidesalcon6").show();
        $(".hidesalcon7").show();
        $(".hidesalcon8").show();
        $(".hidesalcon9").show();
        $(".hidesalcon10").show();
        $(".hidesalcon11").show();
        $(".hidesalcon12").show();
        $(".hidesalcon13").show();
        $(".hidesalcon14").show();




        this.setState({
            mainduration: this.state.thirddropdownvalue,
            mainplan: "P",
            citydistrictselectvalue: "",
            servicefullname: "",
         
        }
        );
    }
    fourdropdowngetstartedclick = (e) => {
        $(".plangetstartedbtn").show();
        $(".getform4").hide();
        $(e.target).closest('.formplacer').append(myform.slideDown());
        $(".hidesalcon1").show();
        $(".hidesalcon2").show();
        $(".hidesalcon3").show();
        $(".hidesalcon4").hide();
        $(".hidesalcon5").show();
        $(".hidesalcon6").show();
        $(".hidesalcon7").show();
        $(".hidesalcon8").show();
        $(".hidesalcon9").show();
        $(".hidesalcon10").show();
        $(".hidesalcon11").show();
        $(".hidesalcon12").show();
        $(".hidesalcon13").show();
        $(".hidesalcon14").show();


        this.setState({
            mainduration: this.state.fourdropdownvalue,
            mainplan: "PR",
            citydistrictselectvalue: "",
            servicefullname: "",
          
        }
        );
    }
    fivedropdowngetstartedclick = (e) => {
        $(".plangetstartedbtn").show();
        $(".getform5").hide();
        $(e.target).closest('.formplacer').append(myform.slideDown());
        $(".hidesalcon1").show();
        $(".hidesalcon2").show();
        $(".hidesalcon3").show();
        $(".hidesalcon4").show();
        $(".hidesalcon5").hide();
        $(".hidesalcon6").show();
        $(".hidesalcon7").show();
        $(".hidesalcon8").show();
        $(".hidesalcon9").show();
        $(".hidesalcon10").show();
        $(".hidesalcon11").show();
        $(".hidesalcon12").show();
        $(".hidesalcon13").show();
        $(".hidesalcon14").show();


        this.setState({
            mainduration: this.state.fivedropdownvalue,
            mainplan: "S",
            citydistrictselectvalue: "",
            servicefullname: "",
        
        }
        );
    }

    sixdropdowngetstartedclick = (e) => {
        $(".plangetstartedbtn").show();
        $(".getform6").hide();
        $(e.target).closest('.formplacer').append(myform.slideDown());
        $(".hidesalcon1").show();
        $(".hidesalcon2").show();
        $(".hidesalcon3").show();
        $(".hidesalcon4").show();
        $(".hidesalcon5").show();
        $(".hidesalcon6").hide();
        $(".hidesalcon7").show();
        $(".hidesalcon8").show();
        $(".hidesalcon9").show();
        $(".hidesalcon10").show();
        $(".hidesalcon11").show();
        $(".hidesalcon12").show();
        $(".hidesalcon13").show();
        $(".hidesalcon14").show();



        this.setState({
            mainduration: this.state.sixdropdownvalue,
            mainplan: "B",
            citydistrictselectvalue: "",
            servicefullname: "",
        
        }
        );
    }
    sevendropdowngetstartedclick = (e) => {
        $(".plangetstartedbtn").show();
        $(".getform7").hide();
        $(e.target).closest('.formplacer').append(myform.slideDown());
        $(".hidesalcon1").show();
        $(".hidesalcon2").show();
        $(".hidesalcon3").show();
        $(".hidesalcon4").show();
        $(".hidesalcon5").show();
        $(".hidesalcon6").show();
        $(".hidesalcon7").hide();
        $(".hidesalcon8").show();
        $(".hidesalcon9").show();
        $(".hidesalcon10").show();
        $(".hidesalcon11").show();
        $(".hidesalcon12").show();
        $(".hidesalcon13").show();
        $(".hidesalcon14").show();


        this.setState({
            mainduration: this.state.sevendropdownvalue,
            mainplan: "P",
            citydistrictselectvalue: "",
            servicefullname: "",
       
        }
        );
    }
    eightdropdowngetstartedclick = (e) => {
        $(".plangetstartedbtn").show();
        $(".getform8").hide();
        $(e.target).closest('.formplacer').append(myform.slideDown());
        $(".hidesalcon1").show();
        $(".hidesalcon2").show();
        $(".hidesalcon3").show();
        $(".hidesalcon4").show();
        $(".hidesalcon5").show();
        $(".hidesalcon6").show();
        $(".hidesalcon7").show();
        $(".hidesalcon8").hide();
        $(".hidesalcon9").show();
        $(".hidesalcon10").show();
        $(".hidesalcon11").show();
        $(".hidesalcon12").show();
        $(".hidesalcon13").show();
        $(".hidesalcon14").show();


        this.setState({
            mainduration: this.state.eightdropdownvalue,
            mainplan: "PR",
            citydistrictselectvalue: "",
            servicefullname: "",
          
        }
        );
    }
    ninedropdowngetstartedclick = (e) => {
        $(".plangetstartedbtn").show();
        $(".getform9").hide();
        $(e.target).closest('.formplacer').append(myform.slideDown());
        $(".hidesalcon1").show();
        $(".hidesalcon2").show();
        $(".hidesalcon3").show();
        $(".hidesalcon4").show();
        $(".hidesalcon5").show();
        $(".hidesalcon6").show();
        $(".hidesalcon7").show();
        $(".hidesalcon8").show();
        $(".hidesalcon9").hide();
        $(".hidesalcon10").show();
        $(".hidesalcon11").show();
        $(".hidesalcon12").show();
        $(".hidesalcon13").show();
        $(".hidesalcon14").show();


        this.setState({
            mainduration: this.state.ninedropdownvalue,
            mainplan: "S",
            citydistrictselectvalue: "",
            servicefullname: "",
     
        }
        );
    }
    tendropdowngetstartedclick = (e) => {
        $(".plangetstartedbtn").show();
        $(".getform10").hide();
        $(e.target).closest('.formplacer').append(myform.slideDown());
        $(".hidesalcon1").show();
        $(".hidesalcon2").show();
        $(".hidesalcon3").show();
        $(".hidesalcon4").show();
        $(".hidesalcon5").show();
        $(".hidesalcon6").show();
        $(".hidesalcon7").show();
        $(".hidesalcon8").show();
        $(".hidesalcon9").show();
        $(".hidesalcon10").hide();
        $(".hidesalcon11").show();
        $(".hidesalcon12").show();
        $(".hidesalcon13").show();
        $(".hidesalcon14").show();


        this.setState({
            mainduration: this.state.tendropdownvalue,
            mainplan: "B",
            citydistrictselectvalue: "",
            servicefullname: "",
         
        }
        );
    }

    elevendropdowngetstartedclick = (e) => {
        $(".plangetstartedbtn").show();
        $(".getform11").hide();
        $(e.target).closest('.formplacer').append(myform.slideDown());
        $(".hidesalcon1").show();
        $(".hidesalcon2").show();
        $(".hidesalcon3").show();
        $(".hidesalcon4").show();
        $(".hidesalcon5").show();
        $(".hidesalcon6").show();
        $(".hidesalcon7").show();
        $(".hidesalcon8").show();
        $(".hidesalcon9").show();
        $(".hidesalcon10").show();
        $(".hidesalcon11").hide();
        $(".hidesalcon12").show();
        $(".hidesalcon13").show();
        $(".hidesalcon14").show();


        this.setState({
            mainduration: this.state.elevendropdownvalue,
            mainplan: "S",
            citydistrictselectvalue: "",
            servicefullname: "",

        }
        );
    }
    twelvedropdowngetstartedclick = (e) => {
        $(".plangetstartedbtn").show();
        $(".getform12").hide();
        $(e.target).closest('.formplacer').append(myform.slideDown());
        $(".hidesalcon1").show();
        $(".hidesalcon2").show();
        $(".hidesalcon3").show();
        $(".hidesalcon4").show();
        $(".hidesalcon5").show();
        $(".hidesalcon6").show();
        $(".hidesalcon7").show();
        $(".hidesalcon8").show();
        $(".hidesalcon9").show();
        $(".hidesalcon10").show();
        $(".hidesalcon11").show();
        $(".hidesalcon12").hide();
        $(".hidesalcon13").show();
        $(".hidesalcon14").show();


        this.setState({
            mainduration: this.state.twelvedropdownvalue,
            mainplan: "B",
            citydistrictselectvalue: "",
            servicefullname: "",
        
        }
        );
    }
    thirteendropdowngetstartedclick = (e) => {
        $(".plangetstartedbtn").show();
        $(".getform13").hide();
        $(e.target).closest('.formplacer').append(myform.slideDown());
        $(".hidesalcon1").show();
        $(".hidesalcon2").show();
        $(".hidesalcon3").show();
        $(".hidesalcon4").show();
        $(".hidesalcon5").show();
        $(".hidesalcon6").show();
        $(".hidesalcon7").show();
        $(".hidesalcon8").show();
        $(".hidesalcon9").show();
        $(".hidesalcon10").show();
        $(".hidesalcon11").show();
        $(".hidesalcon12").show();
        $(".hidesalcon13").hide();
        $(".hidesalcon14").show();



        this.setState({
            mainduration: this.state.thirteendropdownvalue,
            mainplan: "S",
            citydistrictselectvalue: "",
            servicefullname: "",
          
        }
        );
    }
    fourteendropdowngetstartedclick = (e) => {
        $(".plangetstartedbtn").show();
        $(".getform14").hide();
        $(e.target).closest('.formplacer').append(myform.slideDown());
        $(".hidesalcon1").show();
        $(".hidesalcon2").show();
        $(".hidesalcon3").show();
        $(".hidesalcon4").show();
        $(".hidesalcon5").show();
        $(".hidesalcon6").show();
        $(".hidesalcon7").show();
        $(".hidesalcon8").show();
        $(".hidesalcon9").show();
        $(".hidesalcon10").show();
        $(".hidesalcon11").show();
        $(".hidesalcon12").show();
        $(".hidesalcon13").show();
        $(".hidesalcon14").hide();


        this.setState({
            mainduration: this.state.fourteendropdownvalue,
            mainplan: "B",
            citydistrictselectvalue: "",
            servicefullname: "",
          
        }
        );
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        myform = $('#multiform').hide(0);
        $(document.body).css("background-color", "#2d2d2d");
        let mainjwttoken = localStorage.getItem("toeljgtkewlna");
        let mainagentid = localStorage.getItem("qazxswedcvfrtgb");
        Decagentid = encryptor.decrypt(mainagentid);
        Decjwttoken = encryptor.decrypt(mainjwttoken);
        supportName = localStorage.getItem("supportName");
        supportContact = localStorage.getItem("supportContact");
        supportEmail = localStorage.getItem("supportEmail");
        
        this.setState({
          supportName:supportName,
          supportContact:supportContact,
          supportEmail:supportEmail,
        })

        sessiondata = JSON.parse(sessionStorage.getItem("data"))
  
        if (sessiondata == null || sessiondata == undefined  || sessiondata == "") {
          sessiondata=[]
          
    
        }
        // else  {
        //   sessiondata = JSON.parse(sessionStorage.getItem("data"))
        //  }
    
        if(sessiondata.length > 0){
         if(sessiondata[0].userId == ""){
        
          var semail = sessionStorage.getItem("newuseremail")
          var scontact = sessionStorage.getItem("newusercontact")
          if(scontact != ""  &&  semail != ""){
            
          
      
            this.setState({
              serviceemail:semail,
              servicemobileno:scontact,
            })
          }
         }else if(sessiondata[0].userId != ""){
     
          sessiondata=[]
          sessionStorage.removeItem('data');
          
         }
          
        }else{
         
          this.setState({
            serviceemail:"",
            servicemobileno:""
           })
        }

        fetch(process.env.REACT_APP_API_PRICING_JSON_URL)
        .then((response) => response.json())
        .then((data) => {
          // Store the JSON data in localStorage
          localStorage.setItem("lshhihfnkff", JSON.stringify(data));
          this.priceUpdate();
        })
        .catch((error) => console.error("Error fetching data:", error));
    }

    handledropDownValueChange = (e) => {
        this.setState(
            {
                dropDownValue: e.target.value,
            })
        if (e.target.value == "1") {
            $('#1').show();
            $('#2').hide();
            $('#3').hide();
            $('#4').hide();
            $('#5').hide();
        }
        else if (e.target.value == "2") {
            $('#1').hide();
            $('#2').show();
            $('#3').hide();
            $('#4').hide();
            $('#5').hide();
        }
        else if (e.target.value == "3") {
            $('#1').hide();
            $('#2').hide();
            $('#3').show();
            $('#4').hide();
            $('#5').hide();
        }
        else if (e.target.value == "4") {
            $('#1').hide();
            $('#2').hide();
            $('#3').hide();
            $('#4').show();
            $('#5').hide();
        }
        else if (e.target.value == "5") {
            $('#1').hide();
            $('#2').hide();
            $('#3').hide();
            $('#4').hide();
            $('#5').show();
        }
    }

    logout = () => {
        localStorage.removeItem("gglltakoinoeenl");
        localStorage.removeItem("toeljgtkewlna");
        localStorage.removeItem("qazxswedcvfrtgb");
        this.props.history.push({
            pathname: `/`,
        })
    }
    filterDistricts = () => {
        servicestate = jsonData[0][this.state.citydistrictselectvalue]
      };
    createPurchaseReqNormal = () => {
        const contactNoregex = /^[6-9]\d{9}$/;
        const emailregex = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
        if (this.state.servicefullname.trim() == "") {

            $('.errorside').show()
            $('.errorsidespan').show().html(
                ' Please enter your name.');
            $('#name').css({
                'border-bottom-color': '#fe0c0c'
            });
            $('#name').focus();
        }
        else if (this.state.servicefullname.trim() == "" || this.state.servicefullname.length > 50) {
            $('.errorside').show()
            $('.errorsidespan').show().html(
                ' Please enter a valid name.');
            $('#name').css({
                'border-bottom-color': '#fe0c0c'
            });
            $('#name').focus();
        }
        else if (
            this.state.serviceemail.trim() == "") {
            $('.errorside').show()
            $('.errorsidespan').show().html(
                ' Please enter your email.');
            $('#name').css({
                'border-bottom-color': '#809aae'
            });
            $('#email').css({
                'border-bottom-color': '#fe0c0c'
            });
            $('#email').focus();
        }
        else if (
            !emailregex.test(this.state.serviceemail) ||
            this.state.serviceemail.length > 50
        ) {
            $('.errorside').show()
            $('.errorsidespan').show().html(
                ' Please enter valid email.');
            $('#email').css({
                'border-bottom-color': '#fe0c0c'
            });
            $('#email').focus();
        }

        else if (
            this.state.servicemobileno.trim() == "") {
            $('.errorside').show()
            $('.errorsidespan').show().html(
                ' Please enter your number.');
            $('#name').css({
                'border-bottom-color': '#809aae'
            });
            $('#email').css({
                'border-bottom-color': '#809aae'
            });
            $('#contact').css({
                'border-bottom-color': '#fe0c0c'
            });
            $('#contact').focus();
        }
        else if (
            this.state.servicemobileno.trim() == "" ||
            !contactNoregex.test(this.state.servicemobileno)) {
            $('.errorside').show()
            $('.errorsidespan').show().html(
                ' Please enter valid number.');
            $('#name').css({
                'border-bottom-color': '#809aae'
            });
            $('#email').css({
                'border-bottom-color': '#809aae'
            });
            $('#contact').css({
                'border-bottom-color': '#fe0c0c'
            });
            $('#contact').focus();
        }
        else if (this.state.citydistrictselectvalue.trim() == "") {
            $('.errorside').show()
            $('.errorsidespan').show().html(
                ' Please enter your city.');
            $('#name').css({
                'border-bottom-color': '#809aae'
            });
            $('#email').css({
                'border-bottom-color': '#809aae'
            });
            $('#contact').css({
                'border-bottom-color': '#809aae'
            });
            $('#city').css({
                'border-bottom-color': '#fe0c0c'
            });
            $('#city').focus();

        }
        else {
            this.filterDistricts();
            var mySentence = this.state.servicefullname.trim();
            mySentence=mySentence.replace(/\s+/g, " ");
            const words = mySentence.split(" ");

            for (let i = 0; i < words.length; i++) {
                words[i] = words[i][0].toUpperCase() + words[i].substr(1);
            }

            var mywords = words.join(" ");
            $(".planformsubmitbutton").hide();
            $("#addserviceloadermain").show();
            axios.post(process.env.REACT_APP_API_BASE_URL + 'p=createPurchaseNew',
                {
                    agentId: Decagentid,
                    contact: this.state.servicemobileno,
                    email: this.state.serviceemail,
                    serviceName: "GSTRF",
                    purchasename: mywords,
                    citydistrict: this.state.citydistrictselectvalue,
                    state: servicestate,
                    serviceType: "",
                    duration: this.state.mainduration,
                    plan: this.state.mainplan,
                    turnover: this.state.dropDownValue,
                    package: "",
                    comeFrom:"AGP"
                },
                {
                    headers: {
                        Authkey: process.env.REACT_APP_API_KEY,
                        "App-Token-Access": Decjwttoken,
                    },
                    auth: {
                        username: process.env.REACT_APP_API_USERNAME,
                        password: process.env.REACT_APP_API_PASSWORD
                    }
                }
            )
                .then((res) => {

                    
                    if (res.data.code == "200" && res.data.status == "success") {
                        $(".planformsubmitbutton").show();
                        $("#addserviceloadermain").hide();
                        $(".planformsubmitbutton").attr("disabled", false);
                        sessionStorage.setItem("uegiqlsartdele", encryptor.encrypt(res.data.requestid));
                        sessionStorage.setItem("aprcillgee", encryptor.encrypt(parseFloat(res.data.price)));
                        sessionStorage.setItem("ssdfsdfwekrfewpfk", encryptor.encrypt(parseFloat(res.data.gstvalue)));
                        sessionStorage.setItem("lslgerotnscmvheearaei", encryptor.encrypt("GSTRF"));
                        sessionStorage.setItem("evlgsoaeetgfl", encryptor.encrypt(0));
                        sessionStorage.setItem("egalllpna", encryptor.encrypt(this.state.mainplan));
                        // sessionStorage.setItem("relleIsugad", encryptor.encrypt(res.data.userId));
                        sessionStorage.setItem(
                          "ielndmlgeaefelvsdrlucaa",
                          encryptor.encrypt(mywords.trim()));
                        sessionStorage.setItem(
                          "ylcvciretaireditdgisdtacesl",
                          encryptor.encrypt(this.state.citydistrictselectvalue)
                        );
                        sessionStorage.setItem(
                          "srleleddgaeaemvcnia",
                          encryptor.encrypt("GST Return Filing")
                        );
                        this.props.history.push({
                            pathname: `/addservice/checkout`,
                        })
                    }
                    else if (
                        res.data.code == "201" &&
                        res.data.status == "invalidauth"
                    ) {
                        this.logout();
                    }
                    else {
                        $(".planformsubmitbutton").show();
                        $("#addserviceloadermain").hide();
                        $(".planformsubmitbutton").attr("disabled", false);
                        this.showNotification(res.data.message);
                    }
                })
                .catch((err) => {

                });
        }
    };

    showNotification = (message) => {
        const notifContainer = document.getElementById("notifContainer");
        const notif = document.createElement("div");
        notif.classList.add("notifBox");
        notif.innerText = message;
        notifContainer.appendChild(notif);
        setTimeout(() => $(notif).addClass("pop"), 10);
        setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
    };
    showNotification = (message) => {
        const notifContainer = document.getElementById("notifContainer");
        const notif = document.createElement("div");
        notif.classList.add("notifBox");
        notif.innerText = message;
        notifContainer.appendChild(notif);
        setTimeout(() => $(notif).addClass("pop"), 10);
        setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
    };
    backbutton = () => {
        this.props.history.push({
            pathname: `/addservice`,
        });
    };
    citydistrictselecthandle = (event) => {
        this.setState({
            citydistrictselectvalue: event.target.value,
        });

    };
    handleservicefullnameChange = (e) => {
        this.setState({
            servicefullname: e.target.value,
        });
    };
    handleserviceserviceemailChange = (e) => {
        this.setState({
            serviceemail: e.target.value.toLowerCase(),
        });
    };
    handleserviceservicemobilenoChange = (e) => {
        this.setState({
            servicemobileno: e.target.value,
        });
    };
    handlesupport = () => {
        var x = document.getElementById("myDIV");
        if (x.style.display == "block") {
            x.style.display = "none";
          }else {
            x.style.display = "block";
          }
      }
    render() {
        if (localStorage.getItem("gglltakoinoeenl") == process.env.REACT_APP_LOGIN_KEY && localStorage.getItem("toeljgtkewlna") != null && localStorage.getItem("qazxswedcvfrtgb") != null) {
            return (
                <div className="cont">
                    <div id="notifContainer"></div>
                    <Helmet>
                        <link rel="stylesheet" href={process.env.PUBLIC_URL + "/css/select2new2.css"} />
                    </Helmet>

                    <Header />

                    <img
                        src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
                        className="sidebaropenicon"
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/img/Path_231.svg"} onClick={this.backbutton}
                        className="sidebarbackicon"
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"}
                        className="sidebarexpandicon"
                    />

                    <div className="overlay1"></div>
                    <div className="sidebar sidebarshift">
                        <div className="side_div_2 sidebar_txt active">
                            <Link to="/dashboard">Overview</Link>
                        </div>
                        <div className="side_div_1 sidebar_txt">
                            <Link to="/myservices">My Services</Link>
                        </div>
                        <div className="side_div_1 sidebar_txt">
                            <a href="#">Payments Invoices</a>
                        </div>
                        <div className="side_div_1 sidebar_txt">
                            <a href="#">Documents Record</a>
                        </div>
                        <div className="side_div_1 sidebar_txt">
                            <a href="#">Add service</a>
                        </div>
                        <div></div>
                    </div>



                    <div id="multiform" className="multiformdiv">
                        <p className="planformtext1">Name:</p>
                        <input type="text" pattern="[A-Za-z ]{3,150}" id="name" className="appendnameinput planforminput" value={this.state.servicefullname}
                            maxLength="100"
                            onChange={this.handleservicefullnameChange} required />
                        <p className="planform2">Email:</p>
                        <input type="email" maxLength="50" id="email" className="appendemailinput planforminput" value={this.state.serviceemail}
                            onChange={this.handleserviceserviceemailChange} required />
                        <p className="planform2">Contact no:</p>
                        <input type="tel" pattern="[6-9]{1}[0-9]{9}" maxLength="10" id="contact" className="appendcontactno planforminput" value={this.state.servicemobileno}
                            onChange={this.handleserviceservicemobilenoChange} />
                        <p className="planform2">Select Location:</p>
                        <Select2 id="city"
                            onChange={this.citydistrictselecthandle}
                            value={this.state.citydistrictselectvalue}
                            data={["Adilabad",
                            "Agar Malwa",
                            "Agra",
                            "Ahmedabad",
                            "Ahmednagar",
                            "Aizawl",
                            "Ajmer",
                            "Akola",
                            "Alappuzha",
                            "Aligarh",
                            "Alipurduar",
                            "Alirajpur",
                            "Allahabad",
                            "Almora",
                            "Alwar",
                            "Ambala",
                            "Ambedkar Nagar",
                            "Amethi (Chatrapati Sahuji Mahraj Nagar)",
                            "Amravati",
                            "Amreli",
                            "Amritsar",
                            "Amroha",
                            "Anand",
                            "Anantapur",
                            "Anantnag",
                            "Angul",
                            "Anjaw",
                            "Anuppur",
                            "Araria",
                            "Aravalli",
                            "Ariyalur",
                            "Arwal",
                            "Ashoknagar",
                            "Auraiya",
                            "Aurangabad (BH)",
                            "Aurangabad (MH)",
                            "Azamgarh",
                            "Bagalkot",
                            "Bageshwar",
                            "Baghpat",
                            "Bahraich",
                            "Baksa",
                            "Balaghat",
                            "Balangir",
                            "Balasore",
                            "Ballari (Bellary)",
                            "Ballia",
                            "Balod",
                            "Baloda Bazar",
                            "Balrampur (CG)",
                            "Balrampur (UP)",
                            "Banaskantha (Palanpur)",
                            "Banda",
                            "Bandipore",
                            "Banka",
                            "Bankura",
                            "Banswara",
                            "Barabanki",
                            "Baramulla",
                            "Baran",
                            "Bareilly",
                            "Bargarh",
                            "Barmer",
                            "Barnala",
                            "Barpeta",
                            "Barwani",
                            "Bastar",
                            "Basti",
                            "Bathinda",
                            "Beed",
                            "Begusarai",
                            "Belagavi (Belgaum)",
                            "Bemetara",
                            "Bengaluru (Bangalore) Rural",
                            "Bengaluru (Bangalore) Urban",
                            "Betul",
                            "Bhadohi",
                            "Bhadradri Kothagudem",
                            "Bhadrak",
                            "Bhagalpur",
                            "Bhandara",
                            "Bharatpur",
                            "Bharuch",
                            "Bhavnagar",
                            "Bhilwara",
                            "Bhind",
                            "Bhiwani",
                            "Bhojpur",
                            "Bhopal",
                            "Bidar",
                            "Bijapur",
                            "Bijnor",
                            "Bikaner",
                            "Bilaspur (CG)",
                            "Bilaspur (HP)",
                            "Birbhum",
                            "Bishnupur",
                            "Biswanath",
                            "Bokaro",
                            "Bongaigaon",
                            "Botad",
                            "Boudh",
                            "Budaun",
                            "Budgam",
                            "Bulandshahr",
                            "Buldhana",
                            "Bundi",
                            "Burhanpur",
                            "Buxar",
                            "Cachar",
                            "Central Delhi",
                            "Chamarajanagar",
                            "Chamba",
                            "Chamoli",
                            "Champawat",
                            "Champhai",
                            "Chandauli",
                            "Chandel",
                            "Chandigarh",
                            "Chandrapur",
                            "Changlang",
                            "Charaideo",
                            "Charkhi Dadri",
                            "Chatra",
                            "Chengalpattu",
                            "Chennai",
                            "Chhatarpur",
                            "Chhindwara",
                            "Chhota Udepur",
                            "Chikballapur",
                            "Chikkamagaluru (Chikmagalur)",
                            "Chirang",
                            "Chitradurga",
                            "Chitrakoot",
                            "Chittoor",
                            "Chittorgarh",
                            "Churachandpur",
                            "Churu",
                            "Coimbatore",
                            "Cooch Behar",
                            "Cuddalore",
                            "Cuttack",
                            "Dadra & Nagar Haveli",
                            "Dahod",
                            "Dakshin Dinajpur (South Dinajpur)",
                            "Dakshina Kannada",
                            "Daman",
                            "Damoh",
                            "Dangs (Ahwa)",
                            "Dantewada (South Bastar)",
                            "Darbhanga",
                            "Darjeeling",
                            "Darrang",
                            "Datia",
                            "Dausa",
                            "Davangere",
                            "Dehradun",
                            "Deogarh",
                            "Deoghar",
                            "Deoria",
                            "Devbhoomi Dwarka",
                            "Dewas",
                            "Dhalai",
                            "Dhamtari",
                            "Dhanbad",
                            "Dhar",
                            "Dharmapuri",
                            "Dharwad",
                            "Dhemaji",
                            "Dhenkanal",
                            "Dholpur",
                            "Dhubri",
                            "Dhule",
                            "Dibang Valley",
                            "Dibrugarh",
                            "Dima Hasao (North Cachar Hills)",
                            "Dimapur",
                            "Dindigul",
                            "Dindori",
                            "Diu",
                            "Doda",
                            "Dumka",
                            "Dungarpur",
                            "Durg",
                            "East Champaran (Motihari)",
                            "East Delhi",
                            "East Garo Hills",
                            "East Godavari",
                            "East Jaintia Hills",
                            "East Kameng",
                            "East Khasi Hills",
                            "East Siang",
                            "East Sikkim",
                            "East Singhbhum",
                            "Ernakulam",
                            "Erode",
                            "Etah",
                            "Etawah",
                            "Faizabad",
                            "Faridabad",
                            "Faridkot",
                            "Farrukhabad",
                            "Fatehabad",
                            "Fatehgarh Sahib",
                            "Fatehpur",
                            "Fazilka",
                            "Ferozepur",
                            "Firozabad",
                            "Gadag",
                            "Gadchiroli",
                            "Gajapati",
                            "Ganderbal",
                            "Gandhinagar",
                            "Ganjam",
                            "Garhwa",
                            "Gariyaband",
                            "Gautam Buddha Nagar",
                            "Gaya",
                            "Ghaziabad",
                            "Ghazipur",
                            "Gir Somnath",
                            "Giridih",
                            "Goalpara",
                            "Godda",
                            "Golaghat",
                            "Gomati",
                            "Gonda",
                            "Gondia",
                            "Gopalganj",
                            "Gorakhpur",
                            "Gumla",
                            "Guna",
                            "Guntur",
                            "Gurdaspur",
                            "Gurugram (Gurgaon)",
                            "Gwalior",
                            "Hailakandi",
                            "Hamirpur (HP)",
                            "Hamirpur (UP)",
                            "Hanumangarh",
                            "Hapur (Panchsheel Nagar)",
                            "Harda",
                            "Hardoi",
                            "Haridwar",
                            "Hassan",
                            "Hathras",
                            "Haveri",
                            "Hazaribag",
                            "Hingoli",
                            "Hisar",
                            "Hojai",
                            "Hooghly",
                            "Hoshangabad",
                            "Hoshiarpur",
                            "Howrah",
                            "Hyderabad",
                            "Idukki",
                            "Imphal East",
                            "Imphal West",
                            "Indore",
                            "Jabalpur",
                            "Jagatsinghapur",
                            "Jagtial",
                            "Jaipur",
                            "Jaisalmer",
                            "Jajpur",
                            "Jalandhar",
                            "Jalaun",
                            "Jalgaon",
                            "Jalna",
                            "Jalore",
                            "Jalpaiguri",
                            "Jammu",
                            "Jamnagar",
                            "Jamtara",
                            "Jamui",
                            "Jangaon",
                            "Janjgir-Champa",
                            "Jashpur",
                            "Jaunpur",
                            "Jayashankar Bhoopalpally",
                            "Jehanabad",
                            "Jhabua",
                            "Jhajjar",
                            "Jhalawar",
                            "Jhansi",
                            "Jhargram",
                            "Jharsuguda",
                            "Jhunjhunu",
                            "Jind",
                            "Jiribam",
                            "Jodhpur",
                            "Jogulamba Gadwal",
                            "Jorhat",
                            "Junagadh",
                            "Kabirdham (Kawardha)",
                            "Kachchh",
                            "Kaimur (Bhabua)",
                            "Kaithal",
                            "Kakching",
                            "Kalaburagi (Gulbarga)",
                            "Kalahandi",
                            "Kalimpong",
                            "Kallakurichi",
                            "Kamareddy",
                            "Kamjong",
                            "Kamle",
                            "Kamrup",
                            "Kamrup Metropolitan",
                            "Kanchipuram",
                            "Kandhamal",
                            "Kangpokpi",
                            "Kangra",
                            "Kanker (North Bastar)",
                            "Kannauj",
                            "Kannur",
                            "Kanpur Dehat",
                            "Kanpur Nagar",
                            "Kanshiram Nagar (Kasganj)",
                            "Kanyakumari",
                            "Kapurthala",
                            "Karaikal",
                            "Karauli",
                            "Karbi Anglong",
                            "Kargil",
                            "Karimganj",
                            "Karimnagar",
                            "Karnal",
                            "Karur",
                            "Kasaragod",
                            "Kathua",
                            "Katihar",
                            "Katni",
                            "Kaushambi",
                            "Kendrapara",
                            "Kendujhar (Keonjhar)",
                            "Khagaria",
                            "Khammam",
                            "Khandwa",
                            "Khargone",
                            "Kheda (Nadiad)",
                            "Khordha",
                            "Khowai",
                            "Khunti",
                            "Kinnaur",
                            "Kiphire",
                            "Kishanganj",
                            "Kishtwar",
                            "Kodagu",
                            "Koderma",
                            "Kohima",
                            "Kokrajhar",
                            "Kolar",
                            "Kolasib",
                            "Kolhapur",
                            "Kolkata",
                            "Kollam",
                            "Komaram Bheem Asifabad",
                            "Kondagaon",
                            "Koppal",
                            "Koraput",
                            "Korba",
                            "Korea (Koriya)",
                            "Kota",
                            "Kottayam",
                            "Kozhikode",
                            "Kra Daadi",
                            "Krishna",
                            "Krishnagiri",
                            "Kulgam",
                            "Kullu",
                            "Kupwara",
                            "Kurnool",
                            "Kurukshetra",
                            "Kurung Kumey",
                            "Kushinagar (Padrauna)",
                            "Lahaul & Spiti",
                            "Lakhimpur",
                            "Lakhimpur - Kheri",
                            "Lakhisarai",
                            "Lakshadweep",
                            "Lalitpur",
                            "Latehar",
                            "Latur",
                            "Lawngtlai",
                            "Leh",
                            "Lepa Rada",
                            "Lohardaga",
                            "Lohit",
                            "Longding",
                            "Longleng",
                            "Lower Dibang Valley",
                            "Lower Siang",
                            "Lower Subansiri",
                            "Lucknow",
                            "Ludhiana",
                            "Lunglei",
                            "Madhepura",
                            "Madhubani",
                            "Madurai",
                            "Mahabubabad",
                            "Mahabubnagar",
                            "Maharajganj",
                            "Mahasamund",
                            "Mahe",
                            "Mahendragarh",
                            "Mahisagar",
                            "Mahoba",
                            "Mainpuri",
                            "Majuli",
                            "Malappuram",
                            "Malda",
                            "Malkangiri",
                            "Mamit",
                            "Mancherial",
                            "Mandi",
                            "Mandla",
                            "Mandsaur",
                            "Mandya",
                            "Mansa",
                            "Mathura",
                            "Mau",
                            "Mayurbhanj",
                            "Medak",
                            "Medchal",
                            "Meerut",
                            "Mehsana",
                            "Mirzapur",
                            "Moga",
                            "Mokokchung",
                            "Mon",
                            "Moradabad",
                            "Morbi",
                            "Morena",
                            "Morigaon",
                            "Muktsar",
                            "Mumbai City",
                            "Mumbai Suburban",
                            "Mungeli",
                            "Munger (Monghyr)",
                            "Murshidabad",
                            "Muzaffarnagar",
                            "Muzaffarpur",
                            "Mysuru (Mysore)",
                            "Nabarangpur",
                            "Nadia",
                            "Nagaon",
                            "Nagapattinam",
                            "Nagarkurnool",
                            "Nagaur",
                            "Nagpur",
                            "Nainital",
                            "Nalanda",
                            "Nalbari",
                            "Nalgonda",
                            "Namakkal",
                            "Namsai",
                            "Nanded",
                            "Nandurbar",
                            "Narayanpur",
                            "Narmada (Rajpipla)",
                            "Narsinghpur",
                            "Nashik",
                            "Navsari",
                            "Nawada",
                            "Nawanshahr (Shahid Bhagat Singh Nagar)",
                            "Nayagarh",
                            "Neemuch",
                            "New Delhi",
                            "Nicobar",
                            "Nilgiris",
                            "Nirmal",
                            "Nizamabad",
                            "Noney",
                            "North 24 Parganas",
                            "North Delhi",
                            "North East Delhi",
                            "North Garo Hills",
                            "North Goa",
                            "North Sikkim",
                            "North Tripura",
                            "North West Delhi",
                            "North and Middle Andaman",
                            "Nuapada",
                            "Nuh",
                            "Osmanabad",
                            "Pakke Kessang",
                            "Pakur",
                            "Palakkad",
                            "Palamu",
                            "Palghar",
                            "Pali",
                            "Palwal",
                            "Panchkula",
                            "Panchmahal (Godhra)",
                            "Panipat",
                            "Panna",
                            "Papum Pare",
                            "Parbhani",
                            "Paschim (West) Burdwan (Bardhaman)",
                            "Paschim Medinipur (West Medinipur)",
                            "Patan",
                            "Pathanamthitta",
                            "Pathankot",
                            "Patiala",
                            "Patna",
                            "Pauri Garhwal",
                            "Peddapalli",
                            "Perambalur",
                            "Peren",
                            "Phek",
                            "Pherzawl",
                            "Pilibhit",
                            "Pithoragarh",
                            "Poonch",
                            "Porbandar",
                            "Prakasam",
                            "Pratapgarh (RJ)",
                            "Pratapgarh (UP)",
                            "Puducherry",
                            "Pudukkottai",
                            "Pulwama",
                            "Pune",
                            "Purba Burdwan (Bardhaman)",
                            "Purba Medinipur (East Medinipur)",
                            "Puri",
                            "Purnia (Purnea)",
                            "Purulia",
                            "RaeBareli",
                            "Raichur",
                            "Raigad",
                            "Raigarh",
                            "Raipur",
                            "Raisen",
                            "Rajanna Sircilla",
                            "Rajgarh",
                            "Rajkot",
                            "Rajnandgaon",
                            "Rajouri",
                            "Rajsamand",
                            "Ramanagara",
                            "Ramanathapuram",
                            "Ramban",
                            "Ramgarh",
                            "Rampur",
                            "Ranchi",
                            "Rangareddy",
                            "Ranipet",
                            "Ratlam",
                            "Ratnagiri",
                            "Rayagada",
                            "Reasi",
                            "Rewa",
                            "Rewari",
                            "Ri Bhoi",
                            "Rohtak",
                            "Rohtas",
                            "Rudraprayag",
                            "Rupnagar",
                            "Sabarkantha (Himmatnagar)",
                            "Sagar",
                            "Saharanpur",
                            "Saharsa",
                            "Sahibganj",
                            "Sahibzada Ajit Singh Nagar (Mohali)",
                            "Saiha",
                            "Salem",
                            "Samastipur",
                            "Samba",
                            "Sambalpur",
                            "Sambhal (Bhim Nagar)",
                            "Sangareddy",
                            "Sangli",
                            "Sangrur",
                            "Sant Kabir Nagar",
                            "Saran",
                            "Satara",
                            "Satna",
                            "Sawai Madhopur",
                            "Sehore",
                            "Senapati",
                            "Seoni",
                            "Sepahijala",
                            "Seraikela-Kharsawan",
                            "Serchhip",
                            "Shahdara",
                            "Shahdol",
                            "Shahjahanpur",
                            "Shajapur",
                            "Shamali (Prabuddh Nagar)",
                            "Sheikhpura",
                            "Sheohar",
                            "Sheopur",
                            "Shi Yomi",
                            "Shimla",
                            "Shivamogga (Shimoga)",
                            "Shivpuri",
                            "Shopian",
                            "Shravasti",
                            "Siang",
                            "Siddharth Nagar",
                            "Siddipet",
                            "Sidhi",
                            "Sikar",
                            "Simdega",
                            "Sindhudurg",
                            "Singrauli",
                            "Sirmaur (Sirmour)",
                            "Sirohi",
                            "Sirsa",
                            "Sitamarhi",
                            "Sitapur",
                            "Sivaganga",
                            "Sivasagar",
                            "Siwan",
                            "Solan",
                            "Solapur",
                            "Sonbhadra",
                            "Sonepur",
                            "Sonipat",
                            "Sonitpur",
                            "South 24 Parganas",
                            "South Andaman",
                            "South Delhi",
                            "South East Delhi",
                            "South Garo Hills",
                            "South Goa",
                            "South Salamara-Mankachar",
                            "South Sikkim",
                            "South Tripura",
                            "South West Delhi",
                            "South West Garo Hills",
                            "South West Khasi Hills",
                            "Sri Ganganagar",
                            "Sri Potti Sriramulu Nellore ",
                            "Srikakulam",
                            "Srinagar",
                            "Sukma",
                            "Sultanpur",
                            "Sundargarh",
                            "Supaul",
                            "Surajpur",
                            "Surat",
                            "Surendranagar",
                            "Surguja",
                            "Suryapet",
                            "Tamenglong",
                            "Tapi (Vyara)",
                            "Tarn Taran",
                            "Tawang",
                            "Tehri Garhwal",
                            "Tengnoupal",
                            "Tenkasi",
                            "Thane",
                            "Thanjavur",
                            "Theni",
                            "Thiruvananthapuram",
                            "Thoothukudi (Tuticorin)",
                            "Thoubal",
                            "Thrissur",
                            "Tikamgarh",
                            "Tinsukia",
                            "Tirap",
                            "Tiruchirappalli",
                            "Tirunelveli",
                            "Tirupathur",
                            "Tiruppur",
                            "Tiruvallur",
                            "Tiruvannamalai",
                            "Tiruvarur",
                            "Tonk",
                            "Tuensang",
                            "Tumakuru (Tumkur)",
                            "Udaipur",
                            "Udalguri",
                            "Udham Singh Nagar",
                            "Udhampur",
                            "Udupi",
                            "Ujjain",
                            "Ukhrul",
                            "Umaria",
                            "Una",
                            "Unakoti",
                            "Unnao",
                            "Upper Siang",
                            "Upper Subansiri",
                            "Uttar Dinajpur (North Dinajpur)",
                            "Uttara Kannada (Karwar)",
                            "Uttarkashi",
                            "Vadodara",
                            "Vaishali",
                            "Valsad",
                            "Varanasi",
                            "Vellore",
                            "Vidisha",
                            "Vijayapura (Bijapur)",
                            "Vikarabad",
                            "Viluppuram",
                            "Virudhunagar",
                            "Visakhapatnam",
                            "Vizianagaram",
                            "Wanaparthy",
                            "Warangal (Rural)",
                            "Warangal (Urban)",
                            "Wardha",
                            "Washim",
                            "Wayanad",
                            "West Champaran",
                            "West Delhi",
                            "West Garo Hills",
                            "West Godavari",
                            "West Jaintia Hills",
                            "West Kameng",
                            "West Karbi Anglong",
                            "West Khasi Hills",
                            "West Siang",
                            "West Sikkim",
                            "West Singhbhum",
                            "West Tripura",
                            "Wokha",
                            "YSR District, Kadapa (Cuddapah)",
                            "Yadadri Bhuvanagiri",
                            "Yadgir",
                            "Yamunanagar",
                            "Yanam",
                            "Yavatmal",
                            "Zunheboto"
                            ]}
                            options={{
                                placeholder: "City / District"
                            }}

                        />
                        <div className="errorsidediv" >
                                <p className="errorside"><img src={process.env.PUBLIC_URL + "/img/Icon_feather-alert-circle.svg"} className="errorsideimg" />
                                  <span className="errorsidespan"></span>
                                </p>
                              </div>
                        <center>
                        <img className="addserviceloader" id="addserviceloadermain" src={process.env.PUBLIC_URL + "/img/loader.gif"} alt="" />
                            <button id="normalsubmit" onClick={this.createPurchaseReqNormal} class type="submit" className="planformsubmitbutton">SUBMIT</button>
                            <button
                                                                    className="addtocartbutton2"
                                                                    id="submit"
                                                                    onClick={
                                                                        this.addToCartfirstdropdowngetstartedclick
                                                                    }
                                                                >
                                                                    Add to cart &nbsp;
                                                                    <img
                                                                        src={
                                                                            process.env.PUBLIC_URL +
                                                                            "/img/Path_33782_1.svg"
                                                                        }
                                                                    />
                                                                </button>
                        </center>
                    </div>

                    <div className="arrow-left2 visibilityhidden"></div>
                    <div className="white_div expandwhite_div">
                        <div className="white_box">
                            <div className="white_boxscrolldiv">
                                <center> <p className="download">Add Service</p>
                                </center>
                                <center>
                                    <p className="pleaseselecttunovertext">Please select Turnover:</p>
                                    <select id="turnover" className="selectturnover" value={this.state.dropDownValue} onChange={this.handledropDownValueChange}>
                                        <option value="1">₹0 to ₹50 Lakh</option>
                                        <option value="2">₹50 Lakh to ₹1.50 Crore</option>
                                        <option value="3">₹1.50 Crore to ₹10 Crore</option>
                                        <option value="4">₹10 Crore to ₹25 Crore</option>
                                        <option value="5">₹25 Crore to ₹30 Crore </option>
                                    </select>
                                </center>

                                <div className="Salarieddiv" id="1">
                                    <br />
                                    <div className="row paddingmarginzero">
                                        <div className="col-md-3 col-sm-6 Salariedcol">
                                            <div className="bluestartercard">
                                                <p className="planmainheading">
                                                    Starter</p>
                                                <div className="plancardinsidepadding">
                                                    <div className="formplacer">
                                                        <div className="plandropdownpadding">
                                                            <p className="selectatenureheading">
                                                                Select the Tenure:</p>
                                                            <div className="dropdown">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon ionic-ios-arrow-down.svg"}
                                                                    className="salarieddropdownimg servicedropdownimg1" />
                                                                <button id="dLabel"
                                                                    className="dropdown-select firstdropdown plandropdownbutton"
                                                                    type="button" data-toggle="dropdown" aria-haspopup="true"
                                                                    aria-expanded="false">
                                                                    <div className="plandropdowninsidediv">
                                                                        <div className="plandropdowninsideeachdiv">
                                                                            <span className="plantimespan">1 Year &nbsp;&nbsp;@</span>
                                                                            <span className="planmainpricespan"> {this.state.GSTRF1SY}</span>
                                                                        </div>
                                                                        <div className="plandropdowninsideeachdiv">
                                                                            <span
                                                                                className="planmonthtype">Yearly</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <span className="planlinecutpricespan">{this.state.GSTRF1SYnodis}</span>
                                                                            <br />
                                                                            <div className="plan1yearsavediv">
                                                                                <span className="plansavespan"> Save {this.state.GSTRF1SYDisPer}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </button>
                                                                <ul className="dropdown-menu firstdropdown-menu plandropdownul"
                                                                    aria-labelledby="dLabel">
                                                                           <li
                                      value="M"
                                      className="linebtplandrop"
                                      onClick={this.firstdropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Month &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF1SM}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Monthly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF1SMnodis}
                                          </span>
                                          <br />
                                          <div className="plan3monthssavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF1SMDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Q"
                                      className="linebtplandrop"
                                      onClick={this.firstdropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            3 Months &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF1SQ}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Quarterly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF1SQnodis}
                                          </span>
                                          <br />
                                          <div className="plan3monthssavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF1SQDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="H"
                                      className="linebtplandrop"
                                      onClick={this.firstdropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            6 Months &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF1SH}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Half-yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF1SHnodis}
                                          </span>
                                          <br />
                                          <div className="planhalfyearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF1SHDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Y"
                                      className="linebtplandrop"
                                      onClick={this.firstdropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF1SY}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF1SYnodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF1SYDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Y2"
                                      onClick={this.firstdropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            2 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF1SY2}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF1SY2nodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF1SY2DisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="hidesalcon1">
                                                            <center><button id="1S" className="getform plangetstartedbtn" onClick={this.firstdropdowngetstartedclick}
                                                            >GET
                                                                STARTED</button>
                                                                <img
                                                                    src={process.env.PUBLIC_URL + "/img/loader.gif"}
                                                                    className="addserviceloader" id="addserviceloaderid1"
                                                                />
                                                            </center>
                                                            <p
                                                                className="planstarlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                                                                    className="planstarlineimg" />
                                                                Requirements:-
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Ellipse 2753.svg"}
                                                                    className="plancircleimg" />
                                                                GSTR - 3B Excel file
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Ellipse 2753.svg"}
                                                                    className="plancircleimg" />
                                                                GSTR - 1 File
                                                            </p>
                                                            <p
                                                                className="planortext">
                                                                or
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Ellipse 2753.svg"}
                                                                    className="plancircleimg" />
                                                                Normal Excel file of Sales & Purchase
                                                            </p>
                                                            <p
                                                                className="planstarlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                                                                    className="planstarlineimg" />
                                                                Inclusions:-
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Dashboard for managing service
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Mobile application support
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Documents sharing facility
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Download deliverables any time
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Password sharing
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Round the clock support
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Time to time updates & notifications
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-6 Salariedcol">
                                            <div
                                                className="basicplancard">
                                                <p className="planmainheading">
                                                    basic</p>
                                                <div className="plancardinsidepadding">
                                                    <div className="formplacer">
                                                        <div className="plandropdownpadding">
                                                            <p className="selectatenureheading">
                                                                Select the Tenure:</p>
                                                            <div className="dropdown">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon ionic-ios-arrow-down.svg"}
                                                                    className="salarieddropdownimg servicedropdownimg2" />
                                                                <button id="dLabel"
                                                                    className="dropdown-select plandropdownbutton seconddropdown"
                                                                    type="button" data-toggle="dropdown" aria-haspopup="true"
                                                                    aria-expanded="false">
                                                                    <div className="plandropdowninsidediv">
                                                                        <div className="plandropdowninsideeachdiv">
                                                                            <span className="plantimespan">1 Year &nbsp;&nbsp;@</span><span className="planmainpricespan"> {this.state.GSTRF1BY}</span>
                                                                        </div>
                                                                        <div className="plandropdowninsideeachdiv">
                                                                            <span
                                                                                className="planmonthtype">Yearly</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <span className="planlinecutpricespan">{this.state.GSTRF1BYnodis}</span>
                                                                            <br />
                                                                            <div className="plan1yearsavediv">
                                                                                <span className="plansavespan"> Save {this.state.GSTRF1BYDisPer}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </button>
                                                                <ul className="dropdown-menu seconddropdown-menu plandropdownul"
                                                                    aria-labelledby="dLabel">
                                                                         <li
                                      value="M"
                                      className="linebtplandrop"
                                      onClick={this.seconddropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Month &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF1BM}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Monthly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF1BMnodis}
                                          </span>
                                          <br />
                                          <div className="plan3monthssavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF1BMDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Q"
                                      className="linebtplandrop"
                                      onClick={this.seconddropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            3 Months &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF1BQ}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Quarterly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF1BQnodis}
                                          </span>
                                          <br />
                                          <div className="plan3monthssavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF1BQDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="H"
                                      className="linebtplandrop"
                                      onClick={this.seconddropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            6 Months &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF1BH}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Half-yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF1BHnodis}
                                          </span>
                                          <br />
                                          <div className="planhalfyearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF1BHDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Y"
                                      className="linebtplandrop"
                                      onClick={this.seconddropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF1BY}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF1BYnodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF1BYDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Y2"
                                      onClick={this.seconddropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            2 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF1BY2}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF1BY2nodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF1BY2DisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="hidesalcon2">
                                                            <center><button id="1B" className="getform2 plangetstartedbtn" onClick={this.seconddropdowngetstartedclick}
                                                            >GET
                                                                STARTED</button>
                                                                <img
                                                                    src={process.env.PUBLIC_URL + "/img/loader.gif"}
                                                                    className="addserviceloader" id="addserviceloaderid2"
                                                                />
                                                            </center>
                                                            <p
                                                                className="planstarlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                                                                    className="planstarlineimg" />
                                                                Requirements:-
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Ellipse 2753.svg"}
                                                                    className="plancircleimg" />
                                                                GSTR - 3B Excel file
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Ellipse 2753.svg"}
                                                                    className="plancircleimg" />
                                                                GSTR - 1 File
                                                            </p>
                                                            <p
                                                                className="planortext">
                                                                or
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Ellipse 2753.svg"}
                                                                    className="plancircleimg" />
                                                                Normal Excel file of Sales & Purchase
                                                            </p>
                                                            <p
                                                                className="planstarlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                                                                    className="planstarlineimg" />
                                                                Inclusions:-
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                E-way bill facility
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Dashboard for managing service
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Mobile application support
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Documents sharing facility
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Download deliverables any time
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Password sharing
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Round the clock support
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Time to time updates & notifications
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-6 Salariedcol">
                                            <div
                                                className="salariedprocard">
                                                <p className="planmainheading">
                                                    pro</p>
                                                <div className="plancardinsidepadding">
                                                    <div className="formplacer">
                                                        <div className="plandropdownpadding">
                                                            <p className="selectatenureheading">
                                                                Select the Tenure:</p>
                                                            <div className="dropdown">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon ionic-ios-arrow-down.svg"}
                                                                    className="salarieddropdownimg servicedropdownimg3" />
                                                                <button id="dLabel"
                                                                    className="dropdown-select plandropdownbutton thirddropdown"
                                                                    type="button" data-toggle="dropdown" aria-haspopup="true"
                                                                    aria-expanded="false">
                                                                    <div className="plandropdowninsidediv">
                                                                        <div className="plandropdowninsideeachdiv">
                                                                            <span className="plantimespan">1 Year &nbsp;&nbsp;@</span><span className="planmainpricespan">{this.state.GSTRF1PY}</span>
                                                                        </div>
                                                                        <div className="plandropdowninsideeachdiv">
                                                                            <span
                                                                                className="planmonthtype">Yearly</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <span className="planlinecutpricespan">{this.state.GSTRF1PYnodis}</span>
                                                                            <br />
                                                                            <div className="plan1yearsavediv">
                                                                                <span className="plansavespan"> Save {this.state.GSTRF1PYDisPer}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </button>
                                                                <ul className="dropdown-menu thirddropdown-menu plandropdownul"
                                                                    aria-labelledby="dLabel"
                                                                >
                                                                    <li
                                      value="M"
                                      className="linebtplandrop"
                                      onClick={this.thirddropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Month &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF1PM}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Monthly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF1PMnodis}
                                          </span>
                                          <br />
                                          <div className="plan3monthssavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF1PMDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Q"
                                      className="linebtplandrop"
                                      onClick={this.thirddropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            3 Months &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF1PQ}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Quarterly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF1PQnodis}
                                          </span>
                                          <br />
                                          <div className="plan3monthssavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF1PQDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="H"
                                      className="linebtplandrop"
                                      onClick={this.thirddropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            6 Months &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                          {this.state.GSTRF1PH}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Half-yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF1PHnodis}
                                          </span>
                                          <br />
                                          <div className="planhalfyearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF1PHDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Y"
                                      className="linebtplandrop"
                                      onClick={this.thirddropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF1PY}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF1PYnodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF1PYDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Y2"
                                      onClick={this.thirddropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            2 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF1PY2}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF1PY2nodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF1PY2DisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="hidesalcon3">
                                                            <center><button id="1P" className="getform3 plangetstartedbtn" onClick={this.thirddropdowngetstartedclick}
                                                            >GET
                                                                STARTED</button>
                                                                <img
                                                                    src={process.env.PUBLIC_URL + "/img/loader.gif"}
                                                                    className="addserviceloader" id="addserviceloaderid3"
                                                                />
                                                            </center>
                                                            <p
                                                                className="planstarlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                                                                    className="planstarlineimg" />
                                                                Requirements:-
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Ellipse 2753.svg"}
                                                                    className="plancircleimg" />
                                                                Sales & Purchase Invoices
                                                            </p>
                                                            <p
                                                                className="planstarlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                                                                    className="planstarlineimg" />
                                                                Inclusions:-
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                GSTR-2A Reconciliation mismatch report
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Included data preparation<br /><span className="planonbasisspan">(on
                                                                    basis of
                                                                    Invoices)</span>
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Dashboard for managing service
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Mobile application support
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Documents sharing facility
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Download deliverables any time
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Password sharing
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Round the clock support
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Time to time updates & notifications
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-6 Salariedcol">
                                            <div
                                                className="salariedpremiumcard">
                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Group_3320.svg"}
                                                    className="salariedcchocieporimg" />
                                                <p className="planmainheading">
                                                    <img alt="loading..." src={process.env.PUBLIC_URL + "/img/premium star.svg"}
                                                        className="premiumstarimg" /> premium
                                                    <img alt="loading..." src={process.env.PUBLIC_URL + "/img/premium star.svg"}
                                                        className="premiumstarimg" />
                                                </p>
                                                <div className="plancardinsidepadding">
                                                    <div className="formplacer">
                                                        <div className="plandropdownpadding">
                                                            <p className="selectatenureheading">
                                                                Select the Tenure:</p>
                                                            <div className="dropdown">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon ionic-ios-arrow-down.svg"}
                                                                    className="salarieddropdownimg servicedropdownimg4" />
                                                                <button id="dLabel"
                                                                    className="dropdown-select plandropdownbutton fourdropdown"
                                                                    type="button" data-toggle="dropdown" aria-haspopup="true"
                                                                    aria-expanded="false">
                                                                    <div className="plandropdowninsidediv">
                                                                        <div className="plandropdowninsideeachdiv">
                                                                            <span className="plantimespan">1 Year &nbsp;&nbsp;@</span><span className="planmainpricespan"> {this.state.GSTRF1PRY}</span>
                                                                        </div>
                                                                        <div className="plandropdowninsideeachdiv">
                                                                            <span
                                                                                className="planmonthtype">Yearly</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <span className="planlinecutpricespan">{this.state.GSTRF1PRYnodis}</span>
                                                                            <br />
                                                                            <div className="plan1yearsavediv">
                                                                                <span className="plansavespan"> Save {this.state.GSTRF1PRYDisPer}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </button>
                                                                <ul className="dropdown-menu fourdropdown-menu plandropdownul"
                                                                    aria-labelledby="dLabel"
                                                                >
                                                                     <li
                                      value="M"
                                      className="linebtplandrop"
                                      onClick={this.fourdropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Month &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                          {this.state.GSTRF1PRM}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Monthly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF1PRMnodis}
                                          </span>
                                          <br />
                                          <div className="plan3monthssavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF1PRMDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Q"
                                      className="linebtplandrop"
                                      onClick={this.fourdropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            3 Months &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                          {this.state.GSTRF1PRQ}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Quarterly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF1PRQnodis}
                                          </span>
                                          <br />
                                          <div className="plan3monthssavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF1PRQDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="H"
                                      className="linebtplandrop"
                                      onClick={this.fourdropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            6 Months &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                          {this.state.GSTRF1PRH}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Half-yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF1PRHnodis}
                                          </span>
                                          <br />
                                          <div className="planhalfyearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF1PRHDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Y"
                                      className="linebtplandrop"
                                      onClick={this.fourdropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF1PRY}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF1PRYnodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF1PRYDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Y2"
                                      onClick={this.fourdropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            2 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF1PRY2}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF1PRY2nodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF1PRY2DisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="hidesalcon4">
                                                            <center><button id="1PR" className="getform4 plangetstartedbtn" onClick={this.fourdropdowngetstartedclick}
                                                            >GET
                                                                STARTED</button>
                                                                <img
                                                                    src={process.env.PUBLIC_URL + "/img/loader.gif"}
                                                                    className="addserviceloader" id="addserviceloaderid4"
                                                                />
                                                            </center>
                                                            <p
                                                                className="planstarlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                                                                    className="planstarlineimg" />
                                                                Requirements:-
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Ellipse 2753.svg"}
                                                                    className="plancircleimg" />
                                                                Sales & Purchase Invoices
                                                            </p>
                                                            <p
                                                                className="planstarlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                                                                    className="planstarlineimg" />
                                                                Inclusions:-
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                GSTR-2A Reconciliation mismatch report
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Included data preparation<br /><span className="planonbasisspan">(on
                                                                    basis of
                                                                    Invoices)</span>
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                E-way bill facility
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Dashboard for managing service
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Mobile application support
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Documents sharing facility
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Download deliverables any time
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Password sharing
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Round the clock support
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Time to time updates & notifications
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="Salarieddiv planhide" id="2">
                                    <br />
                                    <div className="row paddingmarginzero">
                                        <div className="col-md-3 col-sm-6 Salariedcol">
                                            <div className="bluestartercard">
                                                <p className="planmainheading">
                                                    Starter</p>
                                                <div className="plancardinsidepadding">
                                                    <div className="formplacer">
                                                        <div className="plandropdownpadding">
                                                            <p className="selectatenureheading">
                                                                Select the Tenure:</p>
                                                            <div className="dropdown">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon ionic-ios-arrow-down.svg"}
                                                                    className="salarieddropdownimg servicedropdownimg5" />
                                                                <button id="dLabel"
                                                                    className="dropdown-select plandropdownbutton fivedropdown"
                                                                    type="button" data-toggle="dropdown" aria-haspopup="true"
                                                                    aria-expanded="false">
                                                                    <div className="plandropdowninsidediv">
                                                                        <div className="plandropdowninsideeachdiv">
                                                                            <span className="plantimespan">1 Year &nbsp;&nbsp;@</span><span className="planmainpricespan">  {this.state.GSTRF2SY}</span>
                                                                        </div>
                                                                        <div className="plandropdowninsideeachdiv">
                                                                            <span
                                                                                className="planmonthtype">Yearly</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <span className="planlinecutpricespan">{this.state.GSTRF2SYnodis}</span>
                                                                            <br />
                                                                            <div className="plan1yearsavediv">
                                                                                <span className="plansavespan"> Save {this.state.GSTRF2SYDisPer}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </button>
                                                                <ul className="dropdown-menu fivedropdown-menu plandropdownul"
                                                                    aria-labelledby="dLabel"
                                                                >
                                                                  <li
                                      value="M"
                                      className="linebtplandrop"
                                      onClick={this.fivedropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Months &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF2SM}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Monthly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF2SMnodis}
                                          </span>
                                          <br />
                                          <div className="plan3monthssavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF2SMDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Q"
                                      className="linebtplandrop"
                                      onClick={this.fivedropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            3 Months &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF2SQ}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Quarterly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF2SQnodis}
                                          </span>
                                          <br />
                                          <div className="plan3monthssavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF2SQDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="H"
                                      className="linebtplandrop"
                                      onClick={this.fivedropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            6 Months &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF2SH}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Half-yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF2SHnodis}
                                          </span>
                                          <br />
                                          <div className="planhalfyearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF2SHDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Y"
                                      className="linebtplandrop"
                                      onClick={this.fivedropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF2SY}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF2SYnodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF2SYDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Y2"
                                      onClick={this.fivedropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            2 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF2SY2}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF2SY2nodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF2SY2DisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="hidesalcon5">
                                                            <center><button className="getform5 plangetstartedbtn" value="2S" onClick={this.fivedropdowngetstartedclick}
                                                            >GET
                                                                STARTED</button>
                                                                <img
                                                                    src={process.env.PUBLIC_URL + "/img/loader.gif"}
                                                                    className="addserviceloader" id="addserviceloaderid5"
                                                                />
                                                            </center>
                                                            <p
                                                                className="planstarlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                                                                    className="planstarlineimg" />
                                                                Requirements:-
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Ellipse 2753.svg"}
                                                                    className="plancircleimg" />
                                                                GSTR - 3B Excel file
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Ellipse 2753.svg"}
                                                                    className="plancircleimg" />
                                                                GSTR - 1 File
                                                            </p>
                                                            <p
                                                                className="planortext">
                                                                or
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Ellipse 2753.svg"}
                                                                    className="plancircleimg" />
                                                                Normal Excel file of Sales & Purchase
                                                            </p>
                                                            <p
                                                                className="planstarlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                                                                    className="planstarlineimg" />
                                                                Inclusions:-
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Dashboard for managing service
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Mobile application support
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Documents sharing facility
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Download deliverables any time
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Password sharing
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Round the clock support
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Time to time updates & notifications
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-6 Salariedcol">
                                            <div
                                                className="basicplancard">
                                                <p className="planmainheading">
                                                    basic</p>
                                                <div className="plancardinsidepadding">
                                                    <div className="formplacer">
                                                        <div className="plandropdownpadding">
                                                            <p className="selectatenureheading">
                                                                Select the Tenure:</p>
                                                            <div className="dropdown">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon ionic-ios-arrow-down.svg"}
                                                                    className="salarieddropdownimg servicedropdownimg6" />
                                                                <button id="dLabel"
                                                                    className="dropdown-select plandropdownbutton sixdropdown"
                                                                    type="button" data-toggle="dropdown" aria-haspopup="true"
                                                                    aria-expanded="false">
                                                                    <div className="plandropdowninsidediv">
                                                                        <div className="plandropdowninsideeachdiv">
                                                                            <span className="plantimespan">1 Year &nbsp;&nbsp;@</span><span className="planmainpricespan">  {this.state.GSTRF2BY}</span>
                                                                        </div>
                                                                        <div className="plandropdowninsideeachdiv">
                                                                            <span
                                                                                className="planmonthtype">Yearly</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <span className="planlinecutpricespan">{this.state.GSTRF2BYnodis}</span>
                                                                            <br />
                                                                            <div className="plan1yearsavediv">
                                                                                <span className="plansavespan"> Save {this.state.GSTRF2BYDisPer}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </button>
                                                                <ul className="dropdown-menu sixdropdown-menu plandropdownul"
                                                                    aria-labelledby="dLabel"
                                                                >
                                                                 <li
                                      value="M"
                                      className="linebtplandrop"
                                      onClick={this.sixdropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Month &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF2BM}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Monthly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF2BMnodis}
                                          </span>
                                          <br />
                                          <div className="plan3monthssavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF2BMDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Q"
                                      className="linebtplandrop"
                                      onClick={this.sixdropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            3 Months &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF2BQ}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Quarterly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF2BQnodis}
                                          </span>
                                          <br />
                                          <div className="plan3monthssavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF2BQDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="H"
                                      className="linebtplandrop"
                                      onClick={this.sixdropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            6 Months &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF2BH}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Half-yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF2BHnodis}
                                          </span>
                                          <br />
                                          <div className="planhalfyearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF2BHDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Y"
                                      className="linebtplandrop"
                                      onClick={this.sixdropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF2BY}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF2BYnodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF2BYDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Y2"
                                      onClick={this.sixdropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            2 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF2BY2}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF2BY2nodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF2BY2DisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="hidesalcon6">
                                                            <center><button className="getform6 plangetstartedbtn" id="2B" onClick={this.sixdropdowngetstartedclick}
                                                            >GET
                                                                STARTED</button>
                                                                <img
                                                                    src={process.env.PUBLIC_URL + "/img/loader.gif"}
                                                                    className="addserviceloader" id="addserviceloaderid6"
                                                                />
                                                            </center>
                                                            <p
                                                                className="planstarlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                                                                    className="planstarlineimg" />
                                                                Requirements:-
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Ellipse 2753.svg"}
                                                                    className="plancircleimg" />
                                                                GSTR - 3B Excel file
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Ellipse 2753.svg"}
                                                                    className="plancircleimg" />
                                                                GSTR - 1 File
                                                            </p>
                                                            <p
                                                                className="planortext">
                                                                or
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Ellipse 2753.svg"}
                                                                    className="plancircleimg" />
                                                                Normal Excel file of Sales & Purchase
                                                            </p>
                                                            <p
                                                                className="planstarlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                                                                    className="planstarlineimg" />
                                                                Inclusions:-
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                E-way bill facility
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Dashboard for managing service
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Mobile application support
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Documents sharing facility
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Download deliverables any time
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Password sharing
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Round the clock support
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Time to time updates & notifications
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-6 Salariedcol">
                                            <div
                                                className="salariedprocard">
                                                <p className="planmainheading">
                                                    pro</p>
                                                <div className="plancardinsidepadding">
                                                    <div className="formplacer">
                                                        <div className="plandropdownpadding">
                                                            <p className="selectatenureheading">
                                                                Select the Tenure:</p>
                                                            <div className="dropdown">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon ionic-ios-arrow-down.svg"}
                                                                    className="salarieddropdownimg servicedropdownimg7" />
                                                                <button id="dLabel"
                                                                    className="dropdown-select plandropdownbutton sevendropdown"
                                                                    type="button" data-toggle="dropdown" aria-haspopup="true"
                                                                    aria-expanded="false">
                                                                    <div className="plandropdowninsidediv">
                                                                        <div className="plandropdowninsideeachdiv">
                                                                            <span className="plantimespan">1 Year &nbsp;&nbsp;@</span><span className="planmainpricespan"> {this.state.GSTRF2PY}</span>
                                                                        </div>
                                                                        <div className="plandropdowninsideeachdiv">
                                                                            <span
                                                                                className="planmonthtype">Yearly</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <span className="planlinecutpricespan">{this.state.GSTRF2PYnodis}</span>
                                                                            <br />
                                                                            <div className="plan1yearsavediv">
                                                                                <span className="plansavespan"> Save {this.state.GSTRF2PYDisPer}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </button>
                                                                <ul className="dropdown-menu sevendropdown-menu plandropdownul"
                                                                    aria-labelledby="dLabel"
                                                                >
                                                                  <li
                                      value="M"
                                      className="linebtplandrop"
                                      onClick={this.sevendropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Month &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF2PM}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Monthly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF2PMnodis}
                                          </span>
                                          <br />
                                          <div className="plan3monthssavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF2PMDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Q"
                                      className="linebtplandrop"
                                      onClick={this.sevendropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            3 Months &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF2PQ}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Quarterly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF2PQnodis}
                                          </span>
                                          <br />
                                          <div className="plan3monthssavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF2PQDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="H"
                                      className="linebtplandrop"
                                      onClick={this.sevendropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            6 Months &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                          {this.state.GSTRF2PH}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Half-yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF2PHnodis}
                                          </span>
                                          <br />
                                          <div className="planhalfyearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF2PHDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Y"
                                      className="linebtplandrop"
                                      onClick={this.sevendropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF2PY}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF2PYnodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF2PYDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Y2"
                                      onClick={this.sevendropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            2 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF2PY2}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF2PY2nodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF2PY2DisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="hidesalcon7">
                                                            <center><button className="getform7 plangetstartedbtn" id="2P" onClick={this.sevendropdowngetstartedclick}
                                                            >GET
                                                                STARTED</button>
                                                                <img
                                                                    src={process.env.PUBLIC_URL + "/img/loader.gif"}
                                                                    className="addserviceloader" id="addserviceloaderid7"
                                                                />
                                                            </center>
                                                            <p
                                                                className="planstarlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                                                                    className="planstarlineimg" />
                                                                Requirements:-
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Ellipse 2753.svg"}
                                                                    className="plancircleimg" />
                                                                Sales & Purchase Invoices
                                                            </p>
                                                            <p
                                                                className="planstarlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                                                                    className="planstarlineimg" />
                                                                Inclusions:-
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                GSTR-2A Reconciliation mismatch report
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Included data preparation<br /><span className="planonbasisspan">(on
                                                                    basis of
                                                                    Invoices)</span>
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Dashboard for managing service
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Mobile application support
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Documents sharing facility
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Download deliverables any time
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Password sharing
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Round the clock support
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Time to time updates & notifications
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-6 Salariedcol">
                                            <div
                                                className="salariedpremiumcard">
                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Group_3320.svg"}
                                                    className="salariedcchocieporimg" />
                                                <p className="planmainheading">
                                                    <img alt="loading..." src={process.env.PUBLIC_URL + "/img/premium star.svg"} /> premium
                                                    <img alt="loading..." src={process.env.PUBLIC_URL + "/img/premium star.svg"}
                                                        className="premiumstarimg" />
                                                </p>
                                                <div className="plancardinsidepadding">
                                                    <div className="formplacer">
                                                        <div className="plandropdownpadding">
                                                            <p className="selectatenureheading">
                                                                Select the Tenure:</p>
                                                            <div className="dropdown">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon ionic-ios-arrow-down.svg"}
                                                                    className="salarieddropdownimg servicedropdownimg8" />
                                                                <button id="dLabel"
                                                                    className="dropdown-select plandropdownbutton eightdropdown"
                                                                    type="button" data-toggle="dropdown" aria-haspopup="true"
                                                                    aria-expanded="false">
                                                                    <div className="plandropdowninsidediv">
                                                                        <div className="plandropdowninsideeachdiv">
                                                                            <span className="plantimespan">1 Year &nbsp;&nbsp;@</span><span className="planmainpricespan">  {this.state.GSTRF2PRY}</span>
                                                                        </div>
                                                                        <div className="plandropdowninsideeachdiv">
                                                                            <span
                                                                                className="planmonthtype">Yearly</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <span className="planlinecutpricespan">{this.state.GSTRF2PRYnodis}</span>
                                                                            <br />
                                                                            <div className="plan1yearsavediv">
                                                                                <span className="plansavespan"> Save {this.state.GSTRF2PRYDisPer}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </button>
                                                                <ul className="dropdown-menu eightdropdown-menu plandropdownul"
                                                                    aria-labelledby="dLabel"
                                                                >
                                                                   <li
                                      value="M"
                                      className="linebtplandrop"
                                      onClick={this.eightdropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Month &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                          {this.state.GSTRF2PRM}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Monthly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF2PRMnodis}
                                          </span>
                                          <br />
                                          <div className="plan3monthssavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF2PRMDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Q"
                                      className="linebtplandrop"
                                      onClick={this.eightdropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            3 Months &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                          {this.state.GSTRF2PRQ}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Quarterly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF2PRQnodis}
                                          </span>
                                          <br />
                                          <div className="plan3monthssavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF2PRQDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="H"
                                      className="linebtplandrop"
                                      onClick={this.eightdropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            6 Months &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                          {this.state.GSTRF2PRH}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Half-yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF2PRHnodis}
                                          </span>
                                          <br />
                                          <div className="planhalfyearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF2PRHDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Y"
                                      className="linebtplandrop"
                                      onClick={this.eightdropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF2PRY}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF2PRYnodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF2PRYDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Y2"
                                      onClick={this.eightdropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            2 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF2PRY2}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF2PRY2nodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF2PRY2DisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="hidesalcon8">
                                                            <center><button className="getform8 plangetstartedbtn" id="2PR" onClick={this.eightdropdowngetstartedclick}
                                                            >GET
                                                                STARTED</button>
                                                                <img
                                                                    src={process.env.PUBLIC_URL + "/img/loader.gif"}
                                                                    className="addserviceloader" id="addserviceloaderid8"
                                                                />
                                                            </center>
                                                            <p
                                                                className="planstarlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                                                                    className="planstarlineimg" />
                                                                Requirements:-
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Ellipse 2753.svg"}
                                                                    className="plancircleimg" />
                                                                Sales & Purchase Invoices
                                                            </p>
                                                            <p
                                                                className="planstarlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                                                                    className="planstarlineimg" />
                                                                Inclusions:-
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                GSTR-2A Reconciliation mismatch report
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Included data preparation<br /><span className="planonbasisspan">(on
                                                                    basis of
                                                                    Invoices)</span>
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                E-way bill facility
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Dashboard for managing service
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Mobile application support
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Documents sharing facility
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Download deliverables any time
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Password sharing
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Round the clock support
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Time to time updates & notifications
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="plantwocards planhide" id="3">
                                    <br />
                                    <div className="row paddingmarginzero">
                                        <div className="col-sm-6 plans2cardcol">
                                            <div className="bluestartercard">
                                                <p className="planmainheading">
                                                    Starter</p>
                                                <div className="plancardinsidepadding">
                                                    <div className="formplacer">
                                                        <div className="plandropdownpadding">
                                                            <p className="selectatenureheading">
                                                                Select the Tenure:</p>
                                                            <div className="dropdown">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon ionic-ios-arrow-down.svg"}
                                                                    className="salarieddropdownimg servicedropdownimg9" />
                                                                <button id="dLabel"
                                                                    className="dropdown-select plandropdownbutton ninedropdown"
                                                                    type="button" data-toggle="dropdown" aria-haspopup="true"
                                                                    aria-expanded="false">
                                                                    <div className="plandropdowninsidediv">
                                                                        <div className="plandropdowninsideeachdiv">
                                                                            <span className="plantimespan">1 Year &nbsp;&nbsp;@</span><span className="planmainpricespan"> {this.state.GSTRF3SY}</span>
                                                                        </div>
                                                                        <div className="plandropdowninsideeachdiv">
                                                                            <span
                                                                                className="planmonthtype">Yearly</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <span className="planlinecutpricespan">{this.state.GSTRF3SYnodis}</span>
                                                                            <br />
                                                                            <div className="plan1yearsavediv">
                                                                                <span className="plansavespan"> Save {this.state.GSTRF3SYDisPer}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </button>
                                                                <ul className="dropdown-menu ninedropdown-menu plandropdownul"
                                                                    aria-labelledby="dLabel"
                                                                >
                                                                  <li
                                      value="M"
                                      className="linebtplandrop"
                                      onClick={this.ninedropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Months &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                          {this.state.GSTRF3SM}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Monthly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF3SMnodis}
                                          </span>
                                          <br />
                                          <div className="plan3monthssavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF3SMDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Q"
                                      className="linebtplandrop"
                                      onClick={this.ninedropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            3 Months &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                          {this.state.GSTRF3SQ}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Quarterly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF3SQnodis}
                                          </span>
                                          <br />
                                          <div className="plan3monthssavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF3SQDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="H"
                                      className="linebtplandrop"
                                      onClick={this.ninedropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            6 Months &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                          {this.state.GSTRF3SH}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Half-yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF3SHnodis}
                                          </span>
                                          <br />
                                          <div className="planhalfyearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF3SHDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Y"
                                      className="linebtplandrop"
                                      onClick={this.ninedropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF3SY}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF3SYnodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF3SYDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Y2"
                                      onClick={this.ninedropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            2 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF3SY2}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF3SY2nodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF3SY2DisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="hidesalcon9">
                                                            <center><button className="getform9 plangetstartedbtn" id="3S" onClick={this.ninedropdowngetstartedclick}
                                                            >GET
                                                                STARTED</button>
                                                                <img
                                                                    src={process.env.PUBLIC_URL + "/img/loader.gif"}
                                                                    className="addserviceloader" id="addserviceloaderid9"
                                                                />
                                                            </center>
                                                            <p
                                                                className="planstarlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                                                                    className="planstarlineimg" />
                                                                Requirements:-
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Ellipse 2753.svg"}
                                                                    className="plancircleimg" />
                                                                GSTR - 3B Excel file
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Ellipse 2753.svg"}
                                                                    className="plancircleimg" />
                                                                GSTR - 1 File
                                                            </p>
                                                            <p
                                                                className="planortext">
                                                                or
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Ellipse 2753.svg"}
                                                                    className="plancircleimg" />
                                                                Normal Excel file of Sales & Purchase
                                                            </p>
                                                            <p
                                                                className="planstarlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                                                                    className="planstarlineimg" />
                                                                Inclusions:-
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Dashboard for managing service
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Mobile application support
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Documents sharing facility
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Download deliverables any time
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Password sharing
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Round the clock support
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Time to time updates & notifications
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 plans2cardcol">
                                            <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Group_3320.svg"}
                                                className="salariedcchocieporimg" />
                                            <div
                                                className="basicplancard">
                                                <p className="planmainheading">
                                                    basic</p>
                                                <div className="plancardinsidepadding">
                                                    <div className="formplacer">
                                                        <div className="plandropdownpadding">
                                                            <p className="selectatenureheading">
                                                                Select the Tenure:</p>
                                                            <div className="dropdown">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon ionic-ios-arrow-down.svg"}
                                                                    className="salarieddropdownimg servicedropdownimg10" />
                                                                <button id="dLabel"
                                                                    className="dropdown-select plandropdownbutton tendropdown"
                                                                    type="button" data-toggle="dropdown" aria-haspopup="true"
                                                                    aria-expanded="false">
                                                                    <div className="plandropdowninsidediv">
                                                                        <div className="plandropdowninsideeachdiv">
                                                                            <span className="plantimespan">1 Year &nbsp;&nbsp;@</span><span className="planmainpricespan"> {this.state.GSTRF3BY}</span>
                                                                        </div>
                                                                        <div className="plandropdowninsideeachdiv">
                                                                            <span
                                                                                className="planmonthtype">Yearly</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <span className="planlinecutpricespan"> {this.state.GSTRF3BYnodis}</span>
                                                                            <br />
                                                                            <div className="plan1yearsavediv">
                                                                                <span className="plansavespan"> Save {this.state.GSTRF3BYDisPer}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </button>
                                                                <ul className="dropdown-menu tendropdown-menu plandropdownul"
                                                                    aria-labelledby="dLabel"
                                                                >
                                                                 <li
                                      value="M"
                                      className="linebtplandrop"
                                      onClick={this.tendropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Month &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                          {this.state.GSTRF3BM}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Monthly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF3BMnodis}
                                          </span>
                                          <br />
                                          <div className="plan3monthssavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF3BMDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Q"
                                      className="linebtplandrop"
                                      onClick={this.tendropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            3 Months &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                          {this.state.GSTRF3BQ}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Quarterly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF3BQnodis}
                                          </span>
                                          <br />
                                          <div className="plan3monthssavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF3BQDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="H"
                                      className="linebtplandrop"
                                      onClick={this.tendropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            6 Months &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                          {this.state.GSTRF3BH}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Half-yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF3BHnodis}
                                          </span>
                                          <br />
                                          <div className="planhalfyearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF3BHDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Y"
                                      className="linebtplandrop"
                                      onClick={this.tendropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF3BY}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF3BYnodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF3BYDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Y2"
                                      onClick={this.tendropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            2 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF3BY2}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF3BY2nodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF3BY2DisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="hidesalcon10">
                                                            <center><button className="getform10 plangetstartedbtn" id="3B" onClick={this.tendropdowngetstartedclick}
                                                            >GET
                                                                STARTED</button>
                                                                <img
                                                                    src={process.env.PUBLIC_URL + "/img/loader.gif"}
                                                                    className="addserviceloader" id="addserviceloaderid10"
                                                                />
                                                            </center>
                                                            <p
                                                                className="planstarlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                                                                    className="planstarlineimg" />
                                                                Requirements:-
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Ellipse 2753.svg"}
                                                                    className="plancircleimg" />
                                                                GSTR - 3B Excel file
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Ellipse 2753.svg"}
                                                                    className="plancircleimg" />
                                                                GSTR - 1 File
                                                            </p>
                                                            <p
                                                                className="planortext">
                                                                or
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Ellipse 2753.svg"}
                                                                    className="plancircleimg" />
                                                                Normal Excel file of Sales & Purchase
                                                            </p>
                                                            <p
                                                                className="planstarlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                                                                    className="planstarlineimg" />
                                                                Inclusions:-
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                E-way bill facility
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Dashboard for managing service
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Mobile application support
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Documents sharing facility
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Download deliverables any time
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Password sharing
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Round the clock support
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Time to time updates & notifications
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="plantwocards planhide" id="4">
                                    <br />
                                    <div className="row paddingmarginzero">
                                        <div className="col-sm-6 plans2cardcol">
                                            <div className="bluestartercard">
                                                <p className="planmainheading">
                                                    Starter</p>
                                                <div className="plancardinsidepadding">
                                                    <div className="formplacer">
                                                        <div className="plandropdownpadding">
                                                            <p className="selectatenureheading">
                                                                Select the Tenure:</p>
                                                            <div className="dropdown">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon ionic-ios-arrow-down.svg"}
                                                                    className="salarieddropdownimg servicedropdownimg11" />
                                                                <button id="dLabel"
                                                                    className="dropdown-select plandropdownbutton elevendropdown"
                                                                    type="button" data-toggle="dropdown" aria-haspopup="true"
                                                                    aria-expanded="false">
                                                                    <div className="plandropdowninsidediv">
                                                                        <div className="plandropdowninsideeachdiv">
                                                                            <span className="plantimespan">1 Year &nbsp;&nbsp;@</span><span className="planmainpricespan"> {this.state.GSTRF4SY}</span>
                                                                        </div>
                                                                        <div className="plandropdowninsideeachdiv">
                                                                            <span
                                                                                className="planmonthtype">Yearly</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <span className="planlinecutpricespan">{this.state.GSTRF4SYnodis}</span>
                                                                            <br />
                                                                            <div className="plan1yearsavediv">
                                                                                <span className="plansavespan"> Save {this.state.GSTRF4SYDisPer}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </button>
                                                                <ul className="dropdown-menu elevendropdown-menu plandropdownul"
                                                                    aria-labelledby="dLabel">
                                                                   <li
                                      value="M"
                                      className="linebtplandrop"
                                      onClick={this.elevenropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Month &nbsp;&nbsp;@
                                          </span>{" "}
                                          <span className="planmainpricespan">
                                          {this.state.GSTRF4SM}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Monthly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF4SMnodis}
                                          </span>
                                          <br />
                                          <div className="plan3monthssavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF4SMDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Q"
                                      className="linebtplandrop"
                                      onClick={this.elevenropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            3 Months &nbsp;&nbsp;@
                                          </span>{" "}
                                          <span className="planmainpricespan">
                                          {this.state.GSTRF4SQ}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Quarterly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF4SQnodis}
                                          </span>
                                          <br />
                                          <div className="plan3monthssavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF4SQDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="H"
                                      className="linebtplandrop"
                                      onClick={this.elevenropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            6 Months &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                          {this.state.GSTRF4SH}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Half-yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF4SHnodis}
                                          </span>
                                          <br />
                                          <div className="planhalfyearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF4SHDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Y"
                                      className="linebtplandrop"
                                      onClick={this.elevenropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF4SY}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF4SYnodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF4SYDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Y2"
                                      onClick={this.elevenropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            2 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF4SY2}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF4SY2nodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF4SY2DisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="hidesalcon11">
                                                            <center><button className="getform11 plangetstartedbtn" id="4S" onClick={this.elevendropdowngetstartedclick}
                                                            >GET
                                                                STARTED</button>
                                                                <img
                                                                    src={process.env.PUBLIC_URL + "/img/loader.gif"}
                                                                    className="addserviceloader" id="addserviceloaderid11"
                                                                />
                                                            </center>
                                                            <p
                                                                className="planstarlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                                                                    className="planstarlineimg" />
                                                                Requirements:-
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Ellipse 2753.svg"}
                                                                    className="plancircleimg" />
                                                                GSTR - 3B Excel file
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Ellipse 2753.svg"}
                                                                    className="plancircleimg" />
                                                                GSTR - 1 File
                                                            </p>
                                                            <p
                                                                className="planortext">
                                                                or
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Ellipse 2753.svg"}
                                                                    className="plancircleimg" />
                                                                Normal Excel file of Sales & Purchase
                                                            </p>
                                                            <p
                                                                className="planstarlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                                                                    className="planstarlineimg" />
                                                                Inclusions:-
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Dashboard for managing service
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Mobile application support
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Documents sharing facility
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Download deliverables any time
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Password sharing
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Round the clock support
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Time to time updates & notifications
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 plans2cardcol">
                                            <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Group_3320.svg"}
                                                className="salariedcchocieporimg" />
                                            <div
                                                className="basicplancard">
                                                <p className="planmainheading">
                                                    basic</p>
                                                <div className="plancardinsidepadding">
                                                    <div className="formplacer">
                                                        <div className="plandropdownpadding">
                                                            <p className="selectatenureheading">
                                                                Select the Tenure:</p>
                                                            <div className="dropdown">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon ionic-ios-arrow-down.svg"}
                                                                    className="salarieddropdownimg servicedropdownimg12" />
                                                                <button id="dLabel"
                                                                    className="dropdown-select plandropdownbutton twelvedropdown"
                                                                    type="button" data-toggle="dropdown" aria-haspopup="true"
                                                                    aria-expanded="false">
                                                                    <div className="plandropdowninsidediv">
                                                                        <div className="plandropdowninsideeachdiv">
                                                                            <span className="plantimespan">1 Year &nbsp;&nbsp;@</span><span className="planmainpricespan"> {this.state.GSTRF4BY}</span>
                                                                        </div>
                                                                        <div className="plandropdowninsideeachdiv">
                                                                            <span
                                                                                className="planmonthtype">Yearly</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <span className="planlinecutpricespan">{this.state.GSTRF4BYnodis}</span>
                                                                            <br />
                                                                            <div className="plan1yearsavediv">
                                                                                <span className="plansavespan"> Save {this.state.GSTRF4BYDisPer}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </button>
                                                                <ul className="dropdown-menu twelvedropdown-menu plandropdownul"
                                                                    aria-labelledby="dLabel"
                                                                >
                                                                  <li
                                      value="M"
                                      className="linebtplandrop"
                                      onClick={this.twelveddropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Month &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                          {this.state.GSTRF4BM}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Monthly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF4BMnodis}
                                          </span>
                                          <br />
                                          <div className="plan3monthssavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF4BMDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Q"
                                      className="linebtplandrop"
                                      onClick={this.twelveddropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            3 Months &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                          {this.state.GSTRF4BQ}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Quarterly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF4BQnodis}
                                          </span>
                                          <br />
                                          <div className="plan3monthssavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF4BQDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="H"
                                      className="linebtplandrop"
                                      onClick={this.twelveddropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            6 Months &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                          {this.state.GSTRF4BH}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Half-yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF4BHnodis}
                                          </span>
                                          <br />
                                          <div className="planhalfyearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF4BHDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Y"
                                      className="linebtplandrop"
                                      onClick={this.twelveddropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF4BY}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF4BYnodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF4BYDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Y2"
                                      onClick={this.twelveddropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            2 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF4BY2}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF4BY2nodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF4BY2DisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="hidesalcon12">
                                                            <center><button className="getform12 plangetstartedbtn" id="4B" onClick={this.twelvedropdowngetstartedclick}
                                                            >GET
                                                                STARTED</button>
                                                                <img
                                                                    src={process.env.PUBLIC_URL + "/img/loader.gif"}
                                                                    className="addserviceloader" id="addserviceloaderid12"
                                                                />
                                                            </center>
                                                            <p
                                                                className="planstarlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                                                                    className="planstarlineimg" />
                                                                Requirements:-
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Ellipse 2753.svg"}
                                                                    className="plancircleimg" />
                                                                GSTR - 3B Excel file
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Ellipse 2753.svg"}
                                                                    className="plancircleimg" />
                                                                GSTR - 1 File
                                                            </p>
                                                            <p
                                                                className="planortext">
                                                                or
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Ellipse 2753.svg"}
                                                                    className="plancircleimg" />
                                                                Normal Excel file of Sales & Purchase
                                                            </p>
                                                            <p
                                                                className="planstarlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                                                                    className="planstarlineimg" />
                                                                Inclusions:-
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                E-way bill facility
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Dashboard for managing service
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Mobile application support
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Documents sharing facility
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Download deliverables any time
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Password sharing
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Round the clock support
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Time to time updates & notifications
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="plantwocards planhide" id="5">
                                    <br />
                                    <div className="row paddingmarginzero">
                                        <div className="col-sm-6 plans2cardcol">
                                            <div className="bluestartercard">
                                                <p className="planmainheading">
                                                    Starter</p>
                                                <div className="plancardinsidepadding">
                                                    <div className="formplacer">
                                                        <div className="plandropdownpadding">
                                                            <p className="selectatenureheading">
                                                                Select the Tenure:</p>
                                                            <div className="dropdown">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon ionic-ios-arrow-down.svg"}
                                                                    className="salarieddropdownimg servicedropdownimg13" />
                                                                <button id="dLabel"
                                                                    className="dropdown-select plandropdownbutton thirteendropdown"
                                                                    type="button" data-toggle="dropdown" aria-haspopup="true"
                                                                    aria-expanded="false">
                                                                    <div className="plandropdowninsidediv">
                                                                        <div className="plandropdowninsideeachdiv">
                                                                            <span className="plantimespan">1 Year &nbsp;&nbsp;@</span><span className="planmainpricespan">   {this.state.GSTRF5SY}</span>
                                                                        </div>
                                                                        <div className="plandropdowninsideeachdiv">
                                                                            <span
                                                                                className="planmonthtype">Yearly</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <span className="planlinecutpricespan">{this.state.GSTRF5SYnodis}</span>
                                                                            <br />
                                                                            <div className="plan1yearsavediv">
                                                                                <span className="plansavespan"> Save {this.state.GSTRF5SYDisPer}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </button>
                                                                <ul className="dropdown-menu thirteendropdown-menu plandropdownul"
                                                                    aria-labelledby="dLabel">
                                                                  <li
                                      value="M"
                                      className="linebtplandrop"
                                      onClick={this.thirteendropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Month &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                          {this.state.GSTRF5SM}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Monthly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF5SMnodis}
                                          </span>
                                          <br />
                                          <div className="plan3monthssavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF5SMDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Q"
                                      className="linebtplandrop"
                                      onClick={this.thirteendropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            3 Months &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                          {this.state.GSTRF5SQ}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Quarterly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF5SQnodis}
                                          </span>
                                          <br />
                                          <div className="plan3monthssavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF5SQDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="H"
                                      className="linebtplandrop"
                                      onClick={this.thirteendropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            6 Months &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                          {this.state.GSTRF5SH}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Half-yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF5SHnodis}
                                          </span>
                                          <br />
                                          <div className="planhalfyearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF5SHDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Y"
                                      className="linebtplandrop"
                                      onClick={this.thirteendropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF5SY}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF5SYnodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF5SYDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Y2"
                                      onClick={this.thirteendropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            2 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF5SY2}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF5SY2nodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF5SY2DisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="hidesalcon13">
                                                            <center><button className="getform13 plangetstartedbtn" id="5S" onClick={this.thirteendropdowngetstartedclick}
                                                            >GET
                                                                STARTED</button>
                                                                <img
                                                                    src={process.env.PUBLIC_URL + "/img/loader.gif"}
                                                                    className="addserviceloader" id="addserviceloaderid13"
                                                                />
                                                            </center>
                                                            <p
                                                                className="planstarlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                                                                    className="planstarlineimg" />
                                                                Requirements:-
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Ellipse 2753.svg"}
                                                                    className="plancircleimg" />
                                                                GSTR - 3B Excel file
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Ellipse 2753.svg"}
                                                                    className="plancircleimg" />
                                                                GSTR - 1 File
                                                            </p>
                                                            <p
                                                                className="planortext">
                                                                or
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Ellipse 2753.svg"}
                                                                    className="plancircleimg" />
                                                                Normal Excel file of Sales & Purchase
                                                            </p>
                                                            <p
                                                                className="planstarlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                                                                    className="planstarlineimg" />
                                                                Inclusions:-
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Dashboard for managing service
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Mobile application support
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Documents sharing facility
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Download deliverables any time
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Password sharing
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Round the clock support
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Time to time updates & notifications
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 plans2cardcol">
                                            <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Group_3320.svg"}
                                                className="salariedcchocieporimg" />
                                            <div
                                                className="basicplancard">
                                                <p className="planmainheading">
                                                    basic</p>
                                                <div className="plancardinsidepadding">
                                                    <div className="formplacer">
                                                        <div className="plandropdownpadding">
                                                            <p className="selectatenureheading">
                                                                Select the Tenure:</p>
                                                            <div className="dropdown">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon ionic-ios-arrow-down.svg"}
                                                                    className="salarieddropdownimg servicedropdownimg14" />
                                                                <button id="dLabel"
                                                                    className="dropdown-select plandropdownbutton fourteendropdown"
                                                                    type="button" data-toggle="dropdown" aria-haspopup="true"
                                                                    aria-expanded="false">
                                                                    <div className="plandropdowninsidediv">
                                                                        <div className="plandropdowninsideeachdiv">
                                                                            <span className="plantimespan">1 Year &nbsp;&nbsp;@</span><span className="planmainpricespan"> {this.state.GSTRF5BY}</span>
                                                                        </div>
                                                                        <div className="plandropdowninsideeachdiv">
                                                                            <span
                                                                                className="planmonthtype">Yearly</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <span className="planlinecutpricespan">{this.state.GSTRF5BYnodis}</span>
                                                                            <br />
                                                                            <div className="plan1yearsavediv">
                                                                                <span className="plansavespan"> Save {this.state.GSTRF5BYDisPer}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </button>
                                                                <ul className="dropdown-menu fourteendropdown-menu plandropdownul"
                                                                    aria-labelledby="dLabel">
                                                                       <li
                                      value="M"
                                      className="linebtplandrop"
                                      onClick={this.fourteendropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Month &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                          {this.state.GSTRF5BM}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Monthly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF5BMnodis}
                                          </span>
                                          <br />
                                          <div className="plan3monthssavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF5BMDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Q"
                                      className="linebtplandrop"
                                      onClick={this.fourteendropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            3 Months &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                          {this.state.GSTRF5BQ}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Quarterly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF5BQnodis}
                                          </span>
                                          <br />
                                          <div className="plan3monthssavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF5BQDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="H"
                                      className="linebtplandrop"
                                      onClick={this.fourteendropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            6 Months &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                          {this.state.GSTRF5BH}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Half-yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF5BHnodis}
                                          </span>
                                          <br />
                                          <div className="planhalfyearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF5BHDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Y"
                                      className="linebtplandrop"
                                      onClick={this.fourteendropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF5BY}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF5BYnodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF5BYDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li
                                      value="Y2"
                                      onClick={this.fourteendropdownlichange}
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            2 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.GSTRF5BY2}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.GSTRF5BY2nodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.GSTRF5BY2DisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="hidesalcon14">
                                                            <center><button className="getform14 plangetstartedbtn" id="5B" onClick={this.fourteendropdowngetstartedclick}
                                                            >GET
                                                                STARTED</button>
                                                                <img
                                                                    src={process.env.PUBLIC_URL + "/img/loader.gif"}
                                                                    className="addserviceloader" id="addserviceloaderid14"
                                                                />
                                                            </center>
                                                            <p
                                                                className="planstarlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                                                                    className="planstarlineimg" />
                                                                Requirements:-
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Ellipse 2753.svg"}
                                                                    className="plancircleimg" />
                                                                GSTR - 3B Excel file
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Ellipse 2753.svg"}
                                                                    className="plancircleimg" />
                                                                GSTR - 1 File
                                                            </p>
                                                            <p
                                                                className="planortext">
                                                                or
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Ellipse 2753.svg"}
                                                                    className="plancircleimg" />
                                                                Normal Excel file of Sales & Purchase
                                                            </p>
                                                            <p
                                                                className="planstarlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                                                                    className="planstarlineimg" />
                                                                Inclusions:-
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                E-way bill facility
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Dashboard for managing service
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Mobile application support
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Documents sharing facility
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Download deliverables any time
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Password sharing
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Round the clock support
                                                            </p>
                                                            <p
                                                                className="plancommonlinetext">
                                                                <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                                                                    className="plantickimg" />
                                                                Time to time updates & notifications
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="myDIV">

<div className='supportDetails'>
    <img
        src={process.env.PUBLIC_URL + "/img/24-7_Support.png"}

        alt="image"
    />
    <div>
        <h5>{supportName}</h5>
        <p>Agent Support Executive</p>
        <span><b>Contact: </b> +91 {supportContact}</span>
        <p className='mb-1'><b>Working Hours: </b> 11:00 AM - 06:30 PM</p>
    </div>
</div>


</div>
<button className="support" onClick={this.handlesupport} >
<img src={process.env.PUBLIC_URL + "/img/headPhone.svg"} width="50%" height="50px" />
</button>

                    <Footer />





                </div>
            )
        }
        else {
            return <Redirect to="/" />;
        }
    }
}

export default Addservicegstrf

import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "./Header";
import Footer from "./Footer";
import $, { data } from "jquery";
import axios from 'axios';
import { Redirect } from "react-router";
import Sidebar from "./Sidebar";
import JSZipUtils from "jszip-utils";
import JSZip from "jszip";


var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);
let Decjwttoken;
let Decagentid;
let baseUrl = process.env.REACT_APP_API_BASE_URL;
export class Usersupport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // agentid:"",
      userid: "",
      username: "",
      email: "",
      contactno: "",
      querytype: "Query related to",
      subject: "",
      desc: "",
      fileName:"",
      fileBase64:""
    };
  }
  componentDidMount = () => {
    window.scrollTo(0, 0);
    $(document.body).css("background-color", "#2d2d2d");
    $("#userquerybtn").show()
    let mainagentid = localStorage.getItem("qazxswedcvfrtgb");
    Decagentid = encryptor.decrypt(mainagentid);
    let mainjwttoken = localStorage.getItem("toeljgtkewlna");
    Decjwttoken = encryptor.decrypt(mainjwttoken);

    if(this.props.location.state){
      let userdata = this.props.location.state
      this.setState({userid: userdata.id, username: userdata.name, email: userdata.email, contactno: userdata.contact})

      this.props.history.replace("/agentservices/usersupport" , null)
    }
  }
  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };

  backbutton = () => {
    this.props.history.push({
      pathname: "/agentservices",
    });
  };


  handleusersubmit = () => {
    $("#invaliduserid").hide();
    $("#invalidusername").hide();
    $("#invaliduseremail").hide();
      $("#invalidusercontactno").hide();
      $("#invalidcontactno").hide();
    $("#invaliduserqueryselect").hide();
    $("#invalidusersubject").hide();
    $("#invaliduserdesc").hide();
    $("#invaliduseridempty").hide();
    $("#invalidusernameempty").hide();
    $("#invaliduseremailempty").hide();
    $("#invalidcontactnoempty").hide();
    $("#invaliduserqueryselectempty").hide();
    $("#invalidusersubjectempty").hide();
    $("#invaliduserdescempty").hide();
    const contactNoregex = /^[6-9]\d{9}$/;
    const emailregex = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    if (this.state.userid.trim() == "") {
      $("#invaliduseridempty").show();
      $("#userid").focus();
    } else if (isNaN(this.state.userid) || this.state.userid.length > 10) {
      $("#invaliduserid").show();
      $("#userid").focus();
    } else if (this.state.username.trim() == "") {
      $("#invalidusernameempty").show();
      $("#username").focus();
    } else if (this.state.username.length > 50) {
      $("#invalidusername").show();
      $("#username").focus();
    } else if (this.state.email.trim() == "") {
      $("#invaliduseremailempty").show();
      $("#useremail").focus();
    } else if (
      !emailregex.test(this.state.email) ||
      this.state.email.length > 50
    ) {
      $("#invaliduseremail").show();
      $("#useremail").focus();
    } else if (this.state.contactno.trim() == "") {
      $("#invalidusercontactnoempty").show();
      $("#usercontactno").focus();
    } else if (!contactNoregex.test(this.state.contactno) ||
      this.state.contactno.length > 10 ||
      this.state.contactno.length < 10
    ) {
      $("#invalidusercontactno").show();
      $("#usercontactno").focus();
    } else if (this.state.querytype == "Query related to") {
      $("#invaliduserqueryselect").show();
      $("#QueryType").focus();
    } else if (this.state.subject.trim() == "") {
      $("#invalidusersubjectempty").show();
      $("#usersubject").focus();
    } else if (this.state.subject.length > 100) {
      $("#invalidusersubject").show();
      $("#usersubject").focus();
    } else if (this.state.desc.trim() == "") {
      $("#invaliduserdescempty").show();
      $("#userdesc").focus();
    } else if (this.state.desc.length > 300) {
      $("#invaliduserdesc").show();
      $("#userdesc").focus();
    } else {
      $(".sendOtpLoader").show();
      $("#userquerybtn").hide()
      $("#invaliduserid").hide();
      $("#invalidusername").hide();
      $("#invaliduseremail").hide();
      $("#invalidusercontactno").hide();
      $("#invaliduserqueryselect").hide();
      $("#invalidusersubject").hide();
      $("#invaliduserdesc").hide();
      $("#invaliduseridempty").hide();
      $("#invalidusernameempty").hide();
      $("#invaliduseremailempty").hide();
      $("#invalidusercontactnoempty").hide();
      $("#invaliduserqueryselectempty").hide();
      $("#invalidusersubjectempty").hide();
      $("#invaliduserdescempty").hide();

      var formData = new FormData();
      formData.append("agentId", Decagentid);
      formData.append("userId", this.state.userid);
      formData.append("userName", this.state.username.trim());
      formData.append("contactNo", this.state.contactno.trim());
      formData.append("email", this.state.email.trim());
      formData.append("queryType", this.state.querytype.trim());
      formData.append("subject", this.state.subject.trim());
      formData.append("description", this.state.desc.trim());
      formData.append("file", this.state.fileBase64);
      formData.append("filename", this.state.fileName);

      axios
        .post(
          `${baseUrl}p=UserSupport`,formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD
            }
          }
        )
        .then((res) => {
          
          if (res.data.code == 200 && res.data.status == "success") {
            this.showNotification(res.data.message);
            this.setState({ userid: "", username: "", email: "", contactno: "", querytype: "Query related to", subject: "", desc: "",fileName:"",fileBase64:"" })
            $("#choose_STRecallPlan").show();
            $("#X-btn_STRecallPlan").hide();
            $(".sendOtpLoader").hide();
            $("#userquerybtn").show();
          } else if (res.data.code == 201 && res.data.status == "fail") {
            this.showNotification(res.data.message);
            $("#userquerybtn").show();
            $(".sendOtpLoader").hide();
          }
        })
        .catch((err) => {
          // 
          this.showNotification("Something went wrong");
          $(".sendOtpLoader").hide();
          $(".sendOtpBtn").show();
          $("#userquerybtn").show();

        });
    }
  };


  handleSTRecallPlanChange = (e) => {
    const myfile = e.target.files;
     if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
          },
          () => {
            $("#choose_STRecallPlan").hide();
            $("#X-btn_STRecallPlan").show();
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
            });
          }
        });
        $("#choose_STRecallPlan").hide();
            $("#X-btn_STRecallPlan").show();
    } else {
      this.showNotification("File cannot be null!");
    }
  };
  
  
  
  removeSTRecallPlan = () => {
    $("#choose_STRecallPlan").show();
    $("#X-btn_STRecallPlan").hide();
    this.setState({
      fileName: "",
      fileBase64: "",
    });
  };

  render() {
    if (
      localStorage.getItem("gglltakoinoeenl") == process.env.REACT_APP_LOGIN_KEY &&
      localStorage.getItem("toeljgtkewlna") != null &&
      localStorage.getItem("qazxswedcvfrtgb") != null
    ) {
      return (
        <div>
          <div>
            <div className="cont">
              <div id="notifContainer"></div>
              <Helmet>
                <link
                  rel="stylesheet"
                  href={process.env.PUBLIC_URL + "/css/select2.css"}
                />
                <link rel="stylesheet" href="css/services.css" />
              </Helmet>

              <Header />

              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
                className="sidebaropenicon"
              />
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
                className="sidebarbackicon"
              />
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"}
                className="sidebarexpandicon"
              />

              <div className="overlay1"></div>
              <Sidebar/>

              <div className="arrow-left8"></div>
              <div className="white_div">
                <div className="white_box my-white-box">
                  <div className="resflex  agentSupportdiv">
                    <div className="col-12 col-md-12 col-lg-7 ">
                      <img
                        alt="loading..."
                        src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
                        className="backicon__btn"
                        onClick={this.backbutton}
                      />
                      <h4 className="mb-3 mt-3 agen">User Support:</h4>
                      <h2 className="agsup-head mb-4">
                        Have any query in mind !!
                        <br /> Get support from <span>Legal251</span> Team
                      </h2>
                      <p className="m-0 att">Attention:</p>
                      <p className="att2">
                        👉 Expected Wait Time on all Service channels is 1-2
                        days.🙏
                      </p>

                      <div className="agentQueryform mt-4">
                        <h4 className="mb-2 ">Raise Support Query</h4>
                        <p className="m-0 mb-1">
                          Please enter user's userid here <span>*</span>:
                        </p>
                        <input
                          id="userid"
                          className=" query-desc"
                          maxLength="10"
                          type="text"
                          value={this.state.userid}
                          onChange={(e) =>
                            this.setState({ userid: e.target.value })
                          }
                        />
                        <p
                          className=" mt-2 warn align-items-center"
                          id="invaliduserid"
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/img/Icon_feather-alert-circle.svg"
                            }
                            width="12px"
                            className=" mr-1"
                          />{" "}
                          Please provide a valid userid
                        </p>
                        <p
                          className=" mt-2 warn align-items-center"
                          id="invaliduseridempty"
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/img/Icon_feather-alert-circle.svg"
                            }
                            width="12px"
                            className=" mr-1"
                          />{" "}
                          Please enter userid
                        </p>
                        <p className="m-0 mt-4 mb-1">
                          Please enter user's name here <span>*</span>:
                        </p>
                        <input
                          id="username"
                          className=" query-desc"
                          maxLength="50"
                          type="text"
                          value={this.state.username}
                          onChange={(e) =>
                            this.setState({ username: e.target.value })
                          }
                        />
                        <p
                          className=" mt-2 warn  align-items-center"
                          id="invalidusername"
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/img/Icon_feather-alert-circle.svg"
                            }
                            width="12px"
                            className=" mr-1"
                          />{" "}
                          Please provide a valid user's name
                        </p>
                        <p
                          className=" mt-2 warn  align-items-center"
                          id="invalidusernameempty"
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/img/Icon_feather-alert-circle.svg"
                            }
                            width="12px"
                            className=" mr-1"
                          />{" "}
                          Please enter user's name
                        </p>
                        <p className="m-0 mt-4 mb-1">
                          Please enter user's email here <span>*</span>:
                        </p>
                        <input
                          id="useremail"
                          className=" query-desc"
                          maxLength="50"
                          type="text"
                          value={this.state.email}
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                        />
                        <p
                          className=" mt-2 warn align-items-center"
                          id="invaliduseremail"
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/img/Icon_feather-alert-circle.svg"
                            }
                            width="12px"
                            className=" mr-1"
                          />{" "}
                          Please provide a valid email
                        </p>
                        <p
                          className=" mt-2 warn align-items-center"
                          id="invaliduseremailempty"
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/img/Icon_feather-alert-circle.svg"
                            }
                            width="12px"
                            className=" mr-1"
                          />{" "}
                          Please enter user's email
                        </p>
                        <p className="m-0 mt-4 mb-1">
                          Please enter user's contact no. here <span>*</span>:
                        </p>
                        <input
                          type="tel"
                          id="usercontactno"
                          className=" query-desc"
                          maxLength="10"
                          value={this.state.contactno}
                          onChange={(e) =>
                            this.setState({ contactno: e.target.value })
                          }
                        />
                        <p
                          className=" mt-2 warn align-items-center"
                          id="invalidusercontactno"
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/img/Icon_feather-alert-circle.svg"
                            }
                            width="12px"
                            className=" mr-1"
                          />{" "}
                          Please provide a valid contact no.
                        </p>
                        <p
                          className=" mt-2 warn align-items-center"
                          id="invalidusercontactnoempty"
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/img/Icon_feather-alert-circle.svg"
                            }
                            width="12px"
                            className=" mr-1"
                          />{" "}
                          Please enter user's contact no.
                        </p>
                        <p className="m-0 mt-4 mb-1">Choose your query type <span>*</span>:</p>
                        <select
                          name="Query"
                          id="QueryType"
                          className="query-select"
                          value={this.state.querytype}
                          onChange={(e) =>
                            this.setState({ querytype: e.target.value })
                          }
                        >
                          <option value="Query related to" disabled>
                            Query related to
                          </option>
                          <option value="RF">Refund</option>
                          <option value="PYR">Payment Related</option>
                          <option value="AR">Agent Related</option>
                          <option value="TI">Technical Issue</option>
                          <option value="AMC">Account Management and Changes</option>
                          <option value="UAA">Unable to Access Account </option>
                          <option value="TNR">Team Not Responding </option>
                                  <option value="TAMD">Team asking unnecessary / more documents  </option>
                                  <option value="SNV">Service not visible </option>
                                  <option value="SW">Slow working </option>
                                  <option value="II">Invoicing Issue </option>
                          <option value="O">Other </option>
                        </select>
                        <p
                          className=" mt-2 warn align-items-center"
                          id="invaliduserqueryselect"
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/img/Icon_feather-alert-circle.svg"
                            }
                            width="12px"
                            className=" mr-1"
                          />{" "}
                          Please select query type
                        </p>
                        <p className="m-0 mt-4 mb-1">
                          Please enter your subject here <span>*</span>:
                        </p>
                        <input
                          id="usersubject"
                          className=" query-desc"
                          maxLength="100"
                          type="text"
                          value={this.state.subject}
                          onChange={(e) =>
                            this.setState({ subject: e.target.value })
                          }
                        />
                        <p
                          className=" mt-2 warn  align-items-center"
                          id="invalidusersubject"
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/img/Icon_feather-alert-circle.svg"
                            }
                            width="12px"
                            className=" mr-1"
                          />{" "}
                          Please provide a valid input (max length: 50 )
                        </p>
                        <p
                          className=" mt-2 warn  align-items-center"
                          id="invalidusersubjectempty"
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/img/Icon_feather-alert-circle.svg"
                            }
                            width="12px"
                            className=" mr-1"
                          />{" "}
                          Please enter subject
                        </p>
                        <p className="m-0 mt-4 mb-1">Description <span>*</span>:</p>
                        <textarea
                          id="userdesc"
                          className=" query-desc"
                          maxLength="300"
                          rows="4"
                          value={this.state.desc}
                          onChange={(e) =>
                            this.setState({ desc: e.target.value })
                          }
                        ></textarea>
                        <p
                          className=" mt-2 warn align-items-center"
                          id="invaliduserdesc"
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/img/Icon_feather-alert-circle.svg"
                            }
                            width="12px"
                            className=" mr-1"
                          />{" "}
                          Please provide a valid input (max length: 200)
                        </p>
                        <p
                          className=" mt-2 warn align-items-center"
                          id="invaliduserdescempty"
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/img/Icon_feather-alert-circle.svg"
                            }
                            width="12px"
                            className=" mr-1"
                          />{" "}
                          Please enter Description
                        </p>

                        <p className="m-0 mt-4 mb-1">Upload attachment(optional):</p>
                                <label
                                        htmlFor="ul_file_56pSTRecallPlan"
                                        className="choose_btn"
                                        id="choose_STRecallPlan"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_56pSTRecallPlan"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleSTRecallPlanChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_STRecallPlan"
                                        onClick={(e) =>
                                          this.removeSTRecallPlan(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>

                        <br />
                        <img
                          className=" p-2 pl-3 pr-3 sendOtpLoader"
                          src="/img/loader.gif"
                          width="80px"
                          alt=""
                        />
                        <button
                          className="btn submit-btn text-white mb-3 mt-4"
                          id="userquerybtn"
                          onClick={this.handleusersubmit}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-5">
                            <p className="forinstantsupportline forinstantsupportline1 forinstantsupportline2">
                            For Instant support   <img alt="loading..." src={process.env.PUBLIC_URL + "/img/lightning.png"} className="supportlighticon"/> please call over <span>+91 77728-77729</span> Monday to Friday ( 11.00 AM - 6.00 PM) 
                            </p>
                    </div>
                    {/* <div className="col-6">
                        <img src={process.env.PUBLIC_URL +"/img/Group 5562.svg" width="100%" />
                      </div> */}
                  </div>
                </div>
              </div>

              <Footer />
            </div>
          </div>
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default Usersupport;

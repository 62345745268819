import React, { Component } from "react";
import $ from "jquery";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import axios from "axios";
let id;
export class JcpayLegalmerge extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loanchecked: true,
      legalchecked: true,
      otherchecked: true,
      conditinschecked: true,
      Mainamount: "3000",
      UTRno: "",
      Refferal: "",
      selectfilenames: "Select Files",
      file: "",
      filename: "",
      filebase64: "",
      coupon: "",
      applicationno: "",
      englanguage: "true",
      createdOn: "",
      Paymenttype: "Pay Now",
      PIN:"",
      employeeid:""
    };
  }

  getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();
      // Convert the file to base64 text
      if (file) {
        reader.readAsDataURL(file);
        // on reader load somthing...
        reader.onload = () => {
          // Make a fileInfo Object
          baseURL = reader.result;
          resolve(baseURL);
        };
      }
    });
  };

  copytext = (value) => {
    navigator.clipboard.writeText(value);
    this.showNotification("Text Copied");
  };

  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };
  submitutrno = () => {
    $(".errorside").hide();

    if (this.state.Mainamount == "0") {
      $(".errorside").show();
      $(".errorsidespan").show().html("Please Select Services");
    } else if (this.state.Paymenttype == "Pay Now"&&this.state.UTRno.trim() == "") {
      $(".errorside").show();
      $(".errorsidespan").show().html("Please enter UTR No");
    } else if (this.state.Paymenttype == "Pay Now"&&this.state.filebase64 == "") {
      $(".errorside").show();
      $(".errorsidespan").show().html("Please select payment screenshot");
    } else if (this.state.conditinschecked == false) {
      $(".errorside").show();
      $(".errorsidespan").show().html("Please Accept Terms & Condition");
    } 
    else if (this.state.Paymenttype == "Pay Later"&&this.state.employeeid.trim() == "") {
      $(".errorside").show();
      $(".errorsidespan").show().html("Please enter Employee Id");
    } 
    else if (this.state.Paymenttype == "Pay Later"&&this.state.PIN.trim() == "") {
      $(".errorside").show();
      $(".errorsidespan").show().html("Please enter PIN");
    } 
    else if (this.state.Paymenttype == "Pay Later"&&this.state.PIN.trim() != process.env.REACT_APP_API_PAYLATER_PIN) {
      $(".errorside").show();
      $(".errorsidespan").show().html("Invaild Pin");
    } 
    else {
      $(".accountdetilsinputsubmit").hide();
      $(".addserviceloader").show();
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=getutrno",
          {
            agentId: "",
            jcpayId: id,
            utr: this.state.UTRno.trim(),
            RefNo: this.state.Refferal.trim(),
            fileName: this.state.filename,
            documentBase64: this.state.filebase64,
            amount: this.state.Mainamount,
            type: "both",
            coupon: this.state.coupon.trim(),
            payMode:this.state.Paymenttype,
            employeeId:this.state.employeeid
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            $(".accountdetilsinputsubmit").show();
            $(".addserviceloader").hide();
            $(".legalloadergifdiv").hide();
            $(".jcpaycontentdiv").show();
            $(".Paymentqrcodeouterdiv").hide();
            $("#pendingdiv").show();
            $("#myModal").modal("hide");
            window.scrollTo(0, 0);
            this.setState({
              applicationno: res.data.referenceNo,
            });
          } else {
            $(".accountdetilsinputsubmit").show();
            $(".addserviceloader").hide();
            $(".errorside").show();
            $(".errorsidespan").show().html(res.data.message);
          }
        })
        .catch((err) => {});
    }
  };

  handleFileInputChange1 = (e) => {
    $(".errorside").hide();
    let { file } = this.state;

    file = e.target.files[0];
    if (file.size > 10485760) {
      $(".errorside").show();
      $(".errorsidespan").show().html("File too big!");
      this.setState({ file: "" });
    } else if (file.name.length > 100) {
      $(".errorsidespan").show().html("File name limit exceed!");
      this.setState({ file: "" });
    } else if (
      file.type == "image/jpg" ||
      file.type == "image/jpeg" ||
      file.type == "image/png" ||
      file.type == "application/pdf"
    ) {
      this.getBase64(file)
        .then((result) => {
          file["base64"] = result;

          this.setState({
            filebase64: result,
            filename: file.name,
            selectfilenames: file.name,
            file,
          });
        })
        .catch((err) => {
          $(".errorside").show();
          $(".errorsidespan").show().html("Something went wrong");
        });

      this.setState({
        file: e.target.files[0],
      });
    } else {
      $(".errorside").show();
      $(".errorsidespan").show().html("File extension not allowed!");
    }
  };

  checkboxhandle3 = () => {
    if (this.state.conditinschecked == true) {
      this.setState({
        conditinschecked: false,
      });
    } else {
      this.setState({
        conditinschecked: true,
      });
    }
  };

  checkboxhandle2 = (value) => {
    if (value == "Loan") {
      if (this.state.loanchecked == true) {
        this.setState({
          loanchecked: false,
        });

        if (this.state.legalchecked == true) {
          this.setState({
            Mainamount: "1000",
          });
        } else {
          this.setState({
            Mainamount: "0",
          });
        }
      } else {
        this.setState({
          loanchecked: true,
        });

        if (this.state.legalchecked == true) {
          this.setState({
            Mainamount: "3000",
          });
        } else {
          this.setState({
            Mainamount: "2000",
          });
        }
      }
    } else if (value == "Legal") {
      if (this.state.legalchecked == true) {
        this.setState({
          legalchecked: false,
        });

        if (this.state.loanchecked == true) {
          this.setState({
            Mainamount: "2000",
          });
        } else {
          this.setState({
            Mainamount: "0",
          });
        }
      } else {
        this.setState({
          legalchecked: true,
        });
        if (this.state.loanchecked == true) {
          this.setState({
            Mainamount: "3000",
          });
        } else {
          this.setState({
            Mainamount: "1000",
          });
        }
      }
    }
  };

  handleUTRChange = (e) => {
    this.setState({
      UTRno: e.target.value,
    });
  };

  handleRefferalChange = (e) => {
    this.setState({
      Refferal: e.target.value,
    });
  };

  handlePaymenttypeChange = (e) => {
    this.setState({
      Paymenttype: e.target.value,
    });
  };

  handlecouponChange = (e) => {
    this.setState({
      coupon: e.target.value,
    });
  };

  handleemployeeidChange= (e) => {
    this.setState({
      employeeid: e.target.value,
    });
  };

  handlePINChange = (e) => {
    this.setState({
      PIN: e.target.value,
    });
  };
  checkjcpayagent = () => {
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=JcPaytolegal",
        {
          jcpayId: id,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          if (res.data.applicationStatus == "Pending") {
            $(".legalloadergifdiv").hide();
            $(".jcpaycontentdiv").show();
            $(".Paymentqrcodeouterdiv").hide();
            $("#pendingdiv").show();
            this.setState({
              applicationno: res.data.referenceNo,
              createdOn: res.data.createdOn,
            });
          } else if (res.data.applicationStatus == "Unpaid") {
            $(".legalloadergifdiv").hide();
            $(".jcpaycontentdiv").show();
            $(".Paymentqrcodeouterdiv").hide();
            $("#unpaiddiv").show();
          } else if (res.data.applicationStatus == "Paid") {
            this.props.history.push({
              pathname: `/`,
              search: `?partnerid=${res.data.agentId}`,
            });
          } else if (res.data.applicationStatus == "Reject") {
            $(".legalloadergifdiv").hide();
            $(".jcpaycontentdiv").show();
            $(".Paymentqrcodeouterdiv").hide();
            $("#rejectdiv").show();
            this.setState({
              applicationno: res.data.referenceNo,
              createdOn: res.data.createdOn,
            });
          } else if (res.data.applicationStatus == "Query") {
            $(".legalloadergifdiv").hide();
            $(".jcpaycontentdiv").show();
            $(".Paymentqrcodeouterdiv").hide();
            $("#querydiv").show();
            this.setState({
              applicationno: res.data.referenceNo,
              createdOn: res.data.createdOn,
            });
          }
        } else {
          window.location.replace("http://www.legal251.com/404");
        }
      })
      .catch((err) => {});
  };

  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    id = queryParams.get("jcpayid");
    if (id != null) {
      this.checkjcpayagent();
    }
  }

  handlelanguagechange() {
    this.setState({
      englanguage: !this.state.englanguage,
    });
  }

  render() {
    return (
      <div>
        <div id="notifContainer"></div>
        <div className="legalloadergifdiv">
          <img
            src={process.env.PUBLIC_URL + "/img/gif_legal.gif"}
            className="legalloadergif"
          />
        </div>
        <div className="jcpaycontentdiv">
          <div className="legalkhushkhabriimage"></div>
          <center>
            <img
              src={process.env.PUBLIC_URL + "/img/Group 8350@2x.png"}
              className="jcpaypartlegal"
            />
          </center>
          <div className="Paymentqrcodeouterdiv" id="unpaiddiv">
            <p className="jcpayhinditext">
              अब आप अपने पोर्टल से दे सकते है और भी अधिक सेवाएं जैसे दुकान
              स्थापना लाइसेंस, GST रजिस्ट्रेशन, ITR फाइलिंग, MSME रजिस्ट्रेशन ,
              ट्रेडमार्क रजिस्ट्रेशन, कंपनी रजिस्ट्रेशन, पार्टनरशिप फर्म
              रजिस्ट्रेशन, क्रेडिट कार्ड, होम लोन, कार लोन, पर्सनल लोन, बिजनेस
              लोन, क़ानूनी सलाह जैसी कई अनेक सेवाएं
              <br />
              for more Information visit{" "}
              <a href="https://legal251.com/" target="_blank">
                www.legal251.com
              </a>
            </p>
            <center>
              <button
                className="activatebtn"
                data-toggle="modal"
                data-target="#myModal"
              >
                Activate <span>Now</span>
              </button>
            </center>
            <div className="Paymentqrcodediv">
              <div className="row">
                <div className="col-md-4">
                  <img
                    src={process.env.PUBLIC_URL + "/img/qr.png"}
                    className="Paymentqrcode"
                  />
                  <label className="accountdetilsinputlabel text-center d-block">
                    Scan this QR Code for making payment
                  </label>
                </div>
                <div className="col-md-8">
                  <div className="Paymentqrcodeinputdiv">
                    <label className="accountdetilsinputlabel">
                      Account Name
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        class="form-control accountdetilsinput"
                        placeholder="Your Email"
                        id="mail"
                        name="text"
                        value="ISONOMY LEGAL 251 PRIVATE LIMITED"
                      />
                      <div class="input-group-append">
                        <span
                          class="input-group-text accountdetilsinputspan"
                          onClick={() =>
                            this.copytext("ISONOMY LEGAL 251 PRIVATE LIMITED")
                          }
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/img/Icon awesome-copy.svg"
                            }
                            className="copynoimg"
                          />
                          &nbsp;Copy
                        </span>
                      </div>
                    </div>
                    <label className="accountdetilsinputlabel">
                      Account Number
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        class="form-control accountdetilsinput"
                        placeholder="Your Email"
                        id="mail"
                        name="text"
                        value="50200051704466"
                      />
                      <div class="input-group-append">
                        <span
                          class="input-group-text accountdetilsinputspan"
                          onClick={() => this.copytext("50200051704466")}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/img/Icon awesome-copy.svg"
                            }
                            className="copynoimg"
                          />
                          &nbsp;Copy
                        </span>
                      </div>
                    </div>
                    <label className="accountdetilsinputlabel">IFSC Code</label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        class="form-control accountdetilsinput"
                        placeholder="Your Email"
                        id="mail"
                        name="text"
                        value="HDFC0003855"
                      />
                      <div class="input-group-append">
                        <span
                          class="input-group-text accountdetilsinputspan"
                          onClick={() => this.copytext("HDFC0003855")}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/img/Icon awesome-copy.svg"
                            }
                            className="copynoimg"
                          />
                          &nbsp;Copy
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Paymentqrcodeouterdiv" id="pendingdiv">
            <p className="jcpayhinditext">
              अब आप अपने पोर्टल से दे सकते है और भी अधिक सेवाएं जैसे दुकान
              स्थापना लाइसेंस, GST रजिस्ट्रेशन, ITR फाइलिंग, MSME रजिस्ट्रेशन ,
              ट्रेडमार्क रजिस्ट्रेशन, कंपनी रजिस्ट्रेशन, पार्टनरशिप फर्म
              रजिस्ट्रेशन, क्रेडिट कार्ड, होम लोन, कार लोन, पर्सनल लोन, बिजनेस
              लोन, क़ानूनी सलाह जैसी कई अनेक सेवाएं
              <br />
              for more Information visit{" "}
              <a href="https://legal251.com/" target="_blank">
                www.legal251.com
              </a>
            </p>

            <br />
            <br />
            <br />
            <div className="Paymentqrcodediv">
              <div className="row">
                <div className="col-md-4">
                  <center>
                    <img
                      src={process.env.PUBLIC_URL + "/img/pending.svg"}
                      className="Pendingimg"
                    />{" "}
                  </center>
                  <p className="Statustext">
                    Status:&nbsp;&nbsp;&nbsp;&nbsp;
                    <span>Pending</span>
                  </p>
                </div>
                <div className="col-md-8">
                  <div className="Paymentqrcodeinputdiv">
                    <p className="submittedondata">
                      Submitted On : {this.state.createdOn}
                    </p>
                    <label className="accountdetilsinputlabel">
                      Application Number
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        class="form-control accountdetilsinput"
                        placeholder="Your Email"
                        id="mail"
                        name="text"
                        value={this.state.applicationno}
                      />
                      <div class="input-group-append">
                        <span
                          class="input-group-text accountdetilsinputspan"
                          onClick={() =>
                            this.copytext(this.state.applicationno)
                          }
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/img/Icon awesome-copy.svg"
                            }
                            className="copynoimg"
                          />
                          &nbsp;Copy
                        </span>
                      </div>
                    </div>
                    <p className="dearapplicatext">
                      Dear applicant, your application for portal activation has
                      been submitted and is pending from our end. We will
                      connect you within 24-48 hrs. To know more, you can call
                      on <span>77728-77729</span>.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Paymentqrcodeouterdiv " id="querydiv">
            <p className="jcpayhinditext">
              अब आप अपने पोर्टल से दे सकते है और भी अधिक सेवाएं जैसे दुकान
              स्थापना लाइसेंस, GST रजिस्ट्रेशन, ITR फाइलिंग, MSME रजिस्ट्रेशन ,
              ट्रेडमार्क रजिस्ट्रेशन, कंपनी रजिस्ट्रेशन, पार्टनरशिप फर्म
              रजिस्ट्रेशन, क्रेडिट कार्ड, होम लोन, कार लोन, पर्सनल लोन, बिजनेस
              लोन, क़ानूनी सलाह जैसी कई अनेक सेवाएं
              <br />
              for more Information visit{" "}
              <a href="https://legal251.com/" target="_blank">
                www.legal251.com
              </a>
            </p>
            <br />
            <br />
            <br />
            <div className="Paymentqrcodediv">
              <div className="row">
                <div className="col-md-4">
                  <center>
                    <img
                      src={process.env.PUBLIC_URL + "/img/product.svg"}
                      className="queryimg"
                    />
                  </center>

                  <p className="Statustext">
                    Status:&nbsp;&nbsp;&nbsp;&nbsp;
                    <span>Query Raised</span>
                  </p>
                </div>
                <div className="col-md-8">
                  <div className="Paymentqrcodeinputdiv">
                    <p className="submittedondata">
                      Submitted On : {this.state.createdOn}
                    </p>
                    <label className="accountdetilsinputlabel">
                      Query Number
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        class="form-control accountdetilsinput"
                        placeholder="Your Email"
                        id="mail"
                        name="text"
                        value={this.state.applicationno}
                      />
                      <div class="input-group-append">
                        <span
                          class="input-group-text accountdetilsinputspan"
                          onClick={() =>
                            this.copytext(this.state.applicationno)
                          }
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/img/Icon awesome-copy.svg"
                            }
                            className="copynoimg"
                          />
                          &nbsp;Copy
                        </span>
                      </div>
                    </div>
                    <p className="dearapplicatext">
                      Dear applicant, the query has been raised for your portal
                      activation. Please check your inbox. Also, you can call on{" "}
                      <span>77728-77729</span> for more details.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Paymentqrcodeouterdiv " id="rejectdiv">
            <p className="jcpayhinditext">
              अब आप अपने पोर्टल से दे सकते है और भी अधिक सेवाएं जैसे दुकान
              स्थापना लाइसेंस, GST रजिस्ट्रेशन, ITR फाइलिंग, MSME रजिस्ट्रेशन ,
              ट्रेडमार्क रजिस्ट्रेशन, कंपनी रजिस्ट्रेशन, पार्टनरशिप फर्म
              रजिस्ट्रेशन, क्रेडिट कार्ड, होम लोन, कार लोन, पर्सनल लोन, बिजनेस
              लोन, क़ानूनी सलाह जैसी कई अनेक सेवाएं
              <br />
              for more Information vist{" "}
              <a href="https://legal251.com/" target="_blank">
                www.legal251.com
              </a>
            </p>
            <center>
              <button
                className="activatebtn"
                data-toggle="modal"
                data-target="#myModal"
              >
                Activate@ <span>0₹ Charge</span>
              </button>
            </center>
            <div className="Paymentqrcodediv">
              <div className="row">
                <div className="col-md-4">
                  <center>
                    <img
                      src={process.env.PUBLIC_URL + "/img/reject.svg"}
                      className="Pendingimg"
                    />
                  </center>

                  <p className="Statustext">
                    Status:&nbsp;&nbsp;&nbsp;
                    <span>Rejected</span>
                  </p>
                </div>
                <div className="col-md-8">
                  <div className="Paymentqrcodeinputdiv">
                    <p className="submittedondata">
                      Submitted On : {this.state.createdOn}
                    </p>
                    <label className="accountdetilsinputlabel">
                      Query Number
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        class="form-control accountdetilsinput"
                        placeholder="Your Email"
                        id="mail"
                        name="text"
                        value={this.state.applicationno}
                      />
                      <div class="input-group-append">
                        <span
                          class="input-group-text accountdetilsinputspan"
                          onClick={() =>
                            this.copytext(this.state.applicationno)
                          }
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/img/Icon awesome-copy.svg"
                            }
                            className="copynoimg"
                          />
                          &nbsp;Copy
                        </span>
                      </div>
                    </div>
                    <p className="dearapplicatext">
                      Dear applicant, your application for portal activation has
                      been rejected. Please check your inbox for further details
                      and proceed again. Or contact on <span>77728-77729</span>{" "}
                      for more information.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="portaltypescardmaindiv">
            <p className="portaltypesheading">Services:</p>
            <center>
              <hr className="portaltypesheadinghr" />
            </center>
            <div className="row">
              <div className="col-md-6">
                <div className="portaltypescardouter1">
                  <div className="portaltypescard">
                    <img src={process.env.PUBLIC_URL + "/img/Group 8319.svg"} />
                    <p className="portaltypescardheaing " id="legser">
                      Legal Services
                    </p>
                  </div>
                </div>
              </div>{" "}
              <div className="col-md-6">
                <div className="portaltypescardouter2">
                  <div className="portaltypescard">
                    <img src={process.env.PUBLIC_URL + "/img/Group 8321.svg"} />
                    <p className="portaltypescardheaing">Loan & Credit Card</p>
                  </div>
                </div>
              </div>
            </div>
            <p className="portaltypescardhinditext">
              100% पोर्टल चार्ज अमाउंट रिफंड होगा यदि आप 1 साल में नए पोर्टल से
              50000 या उस से अधिक का बिज़नेस कर देते हे । कमीशन भी अलग से मिलेगा
              अमाउंट पर।
            </p>
          </div>
          <p className="hindicommisiontext">
            15% से 20% लीगल सर्विसेज पर कमिशन
          </p>
          <p className="jcpayhinditext">
            नीचे दिए गए बटन पर क्लिक कर कमिशन शीट डाउनलोड कर सकते है
          </p>
          <div className="commsionsheetcardmaindiv">
            <div class="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="commsionsheetcardouterdiv">
                    <div className="commsionsheetcard">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/img/Universal declaration of human rights-pana.svg"
                        }
                      />
                      <p className="commsionsheetcardtext">लीगल सर्विस कमिशन</p>
                    </div>
                    <center>
                      <a
                        href="https://docs.legal251.com/example/documents/AgentCommissionLegalServices.pdf"
                        target="_blank"
                        className="commsionsheetcarddowbtn"
                      >
                        Download&nbsp;
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/Icon feather-download.svg"
                          }
                          alt=""
                          srcset=""
                        />
                      </a>
                    </center>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="commsionsheetcardouterdiv">
                    <div className="commsionsheetcard">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/img/Car finance-rafiki.svg"
                        }
                      />
                      <p className="commsionsheetcardtext">
                        लोन कमिशन & क्रेडिट कार्ड कमिशन
                      </p>
                    </div>
                    <center>
                      <a
                        className="commsionsheetcarddowbtn"
                        target="_blank"
                        href="https://docs.legal251.com/example/documents/AgentCommissionLoanCreditcard.pdf"
                      >
                        Download&nbsp;
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/Icon feather-download.svg"
                          }
                          alt=""
                          srcset=""
                        />
                      </a>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p class="frequentlyheading">
            FREQUENTLY ASKED <span>QUESTIONS</span>
          </p>
          <div className="jcpaymergefaqmaindiv">
            <div class="row">
              <div class="col-md-6 servicefaqcol1">
                <div class="panel-group" id="accordion">
                  <div class="panel panel-default servicefaqmainpanel">
                    <div class="panel-heading servicefaqpanelheading">
                      <h4 class="panel-title">
                        <a
                          data-toggle="collapse"
                          class="servicefaqlink"
                          id="servicefaq1"
                          data-parent="#accordion"
                          href="#collapse1"
                          aria-expanded="true"
                        >
                          1. What is Legal251?
                          <img
                            src={process.env.PUBLIC_URL + "/img/Path_20191.svg"}
                            class="servicefaqimg"
                          />
                        </a>
                      </h4>
                    </div>
                    <div
                      id="collapse1"
                      class="panel-collapse paddingzero collapse in"
                      aria-expanded="true"
                    >
                      <div class="panel-body servicefaqbody">
                        Legal251 is an online platform that provides more than
                        251 legal services, fin tech, and other utilities such
                        as GST Registration and Returns Filing, Gumasta Licence,
                        Legal Drafting, Loan services, and whatnot.{" "}
                      </div>
                    </div>
                  </div>
                  <div class="panel panel-default servicefaqmainpanel">
                    <div class="panel-heading servicefaqpanelheading">
                      <h4 class="panel-title">
                        <a
                          data-toggle="collapse"
                          class="servicefaqlink collapsed"
                          id="servicefaq2"
                          data-parent="#accordion"
                          href="#collapse2"
                          aria-expanded="false"
                        >
                          Q2. What are the uses or benefits of Legal251?
                          <img
                            src={process.env.PUBLIC_URL + "/img/Path_20191.svg"}
                            class="servicefaqimg"
                          />
                        </a>
                      </h4>
                    </div>
                    <div
                      id="collapse2"
                      class="panel-collapse collapse  paddingzero"
                      aria-expanded="false"
                    >
                      <div class="panel-body servicefaqbody">
                        Legal251 benefits are: <br />
                        <li>Time-saving.</li>
                        <li>Budget-Friendly.</li>
                        <li>Easy and Effortless.</li>
                        <li>Online Processing.</li>
                        <li>Simple and Free from human errors.</li>
                        <li>Pleasant treatment from the staff.</li>
                      </div>
                    </div>
                  </div>
                  <div class="panel panel-default servicefaqmainpanel">
                    <div class="panel-heading servicefaqpanelheading">
                      <h4 class="panel-title">
                        <a
                          data-toggle="collapse"
                          class="servicefaqlink collapsed"
                          id="servicefaq3"
                          data-parent="#accordion"
                          href="#collapse3"
                          aria-expanded="false"
                        >
                          Q3. What services do you provide?
                          <img
                            src={process.env.PUBLIC_URL + "/img/Path_20191.svg"}
                            class="servicefaqimg"
                          />
                        </a>
                      </h4>
                    </div>
                    <div
                      id="collapse3"
                      class="panel-collapse collapse paddingzero"
                      aria-expanded="false"
                    >
                      <div class="panel-body servicefaqbody">
                        Legal251 provides more than 251 legal and financial
                        services, including Company Registration, Registration,
                        Partnership Deed, Dissolution of Deeds & Licencing
                        services, i.e., Trade Licence, MSME, Gumasta, Food
                        Licence, GST Registration, ICE GATE, IEC, TAN, ESIC,
                        Firm, PF, Professional Tax and so. <br />
                        <br />
                        Filing & Accounting services such as TDS/GST/PF/ITR
                        returns, Online Accounting. Drafting services include
                        Legal Drafting, WILL, NOC, Affidavit, Power of Attorney,
                        Application Form, and Rent Agreement. Legal issues
                        include Legal Reply, Legal Notice Preparation, and Legal
                        Claim Help. <br />
                        <br />
                        All types of Loans, such as Car Loans, Home loans, and
                        other services, include Balance Sheet Preparation,
                        Project Report Preparation, Privacy Policy, and Terms &
                        Conditions Drafting. Utilities, i.e., Calculators such
                        as TDS, 80U, HRA, GST, SIP, EMI, FD, GRATUITY, SI, RD
                        Calculator, and more. <br />
                      </div>
                    </div>
                  </div>
                  <div class="panel panel-default servicefaqmainpanel">
                    <div class="panel-heading servicefaqpanelheading">
                      <h4 class="panel-title">
                        <a
                          data-toggle="collapse"
                          class="servicefaqlink collapsed"
                          id="servicefaq4"
                          data-parent="#accordion"
                          href="#collapse4"
                          aria-expanded="false"
                        >
                          Q4. What are documents required?
                          <img
                            src={process.env.PUBLIC_URL + "/img/Path_20191.svg"}
                            class="servicefaqimg"
                          />
                        </a>
                      </h4>
                    </div>
                    <div
                      id="collapse4"
                      class="panel-collapse collapse paddingzero"
                      aria-expanded="false"
                    >
                      <div class="panel-body servicefaqbody">
                        You will require the below documents for partnering with
                        Legal251:
                        <br />
                        ID or Address Proof: <br />
                        <li>
                          Aadhar Card/PAN Card/Notary/Registry/Recent
                          Electricity Bill (anyone).
                        </li>
                        Bank Proof: <br />
                        <li>
                          Canceled Cheque/Front Page of Passbook/Bank Statement
                          (anyone).
                        </li>
                        Work Address Proof: <br />
                        <li>
                          {" "}
                          Gumasta or Trade Licence/ Recent Electricity Bill/
                          Notary/Registry.
                        </li>
                        Profile Photo: <br />
                        <li>2 Passport Size Photographs.</li>
                      </div>
                    </div>
                  </div>
                  <div class="panel panel-default servicefaqmainpanel">
                    <div class="panel-heading servicefaqpanelheading">
                      <h4 class="panel-title">
                        <a
                          data-toggle="collapse"
                          class="servicefaqlink collapsed"
                          id="servicefaq5"
                          data-parent="#accordion"
                          href="#collapse5"
                          aria-expanded="false"
                        >
                          Q5. How to register?
                          <img
                            src={process.env.PUBLIC_URL + "/img/Path_20191.svg"}
                            class="servicefaqimg"
                          />
                        </a>
                      </h4>
                    </div>
                    <div
                      id="collapse5"
                      class="panel-collapse collapse paddingzero"
                      aria-expanded="false"
                    >
                      <div class="panel-body servicefaqbody">
                        You can register with us using the following steps:{" "}
                        <br />
                        <li>Click on the Activate button.</li>
                        <li>
                          A new window will open, the Portal Activation Form.
                        </li>
                        <li>
                          Transfer the mentioned amount on QR or legal251's bank
                          account.{" "}
                        </li>
                        <li>
                          Attach your Payment screenshot and payment details on
                          form and submit.
                        </li>
                        <li>
                          Your agent application will be sent for review and
                          will get approved within 24-48 hours.
                        </li>
                        <li>
                          You can email us regarding any query at our official
                          Email id support@legal251.com. Also, you will get the
                          confirmation mail of your application via mail. You
                          can check on the website if your application is
                          accepted or rejected.
                        </li>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 servicefaqcol2">
                <div class="panel-group" id="accordion2">
                  <div class="panel panel-default servicefaqmainpanel">
                    <div class="panel-heading servicefaqpanelheading">
                      <h4 class="panel-title">
                        <a
                          data-toggle="collapse"
                          class="servicefaqlink"
                          id="servicefaq6"
                          data-parent="#accordion2"
                          href="#collapse21"
                        >
                          Q6. At what time do I get the approval?
                          <img
                            src={process.env.PUBLIC_URL + "/img/Path_20191.svg"}
                            class="servicefaqimg"
                          />
                        </a>
                      </h4>
                    </div>
                    <div
                      id="collapse21"
                      class="panel-collapse collapse in paddingzero"
                    >
                      <div class="panel-body servicefaqbody">
                        It will take 24-48 hours for your application to get
                        approved.
                      </div>
                    </div>
                  </div>
                  <div class="panel panel-default servicefaqmainpanel">
                    <div class="panel-heading servicefaqpanelheading">
                      <h4 class="panel-title">
                        <a
                          data-toggle="collapse"
                          class="servicefaqlink"
                          id="servicefaq7"
                          data-parent="#accordion2"
                          href="#collapse22"
                        >
                          Q7. Where can I access my portal?
                          <img
                            src={process.env.PUBLIC_URL + "/img/Path_20191.svg"}
                            class="servicefaqimg"
                          />
                        </a>
                      </h4>
                    </div>
                    <div
                      id="collapse22"
                      class="panel-collapse collapse paddingzero"
                    >
                      <div class="panel-body servicefaqbody">
                        You can access your portal via portal.legal251.com using
                        your Portal ID and Password.
                      </div>
                    </div>
                  </div>
                  <div class="panel panel-default servicefaqmainpanel">
                    <div class="panel-heading servicefaqpanelheading">
                      <h4 class="panel-title">
                        <a
                          data-toggle="collapse"
                          class="servicefaqlink"
                          id="servicefaq8"
                          data-parent="#accordion2"
                          href="#collapse23"
                        >
                          Q8. How will I get a commission?
                          <img
                            src={process.env.PUBLIC_URL + "/img/Path_20191.svg"}
                            class="servicefaqimg"
                          />
                        </a>
                      </h4>
                    </div>
                    <div
                      id="collapse23"
                      class="panel-collapse collapse paddingzero"
                    >
                      <div class="panel-body servicefaqbody">
                        After successfully completing your service purchase, you
                        will get a commission amount from our end after one
                        month of purchase. You will get this commission amount
                        next month between 1-7 in your bank account. Terms &
                        conditions apply.
                      </div>
                    </div>
                  </div>
                  <div class="panel panel-default servicefaqmainpanel">
                    <div class="panel-heading servicefaqpanelheading">
                      <h4 class="panel-title">
                        <a
                          data-toggle="collapse"
                          class="servicefaqlink"
                          id="servicefaq9"
                          data-parent="#accordion2"
                          href="#collapse24"
                        >
                          Q9. What promotional Material(s) will I get?
                          <img
                            src={process.env.PUBLIC_URL + "/img/Path_20191.svg"}
                            class="servicefaqimg"
                          />
                        </a>
                      </h4>
                    </div>
                    <div
                      id="collapse24"
                      class="panel-collapse collapse paddingzero"
                    >
                      <div class="panel-body servicefaqbody">
                        You will get a physical banner and all digital promotion
                        material and utilities.
                      </div>
                    </div>
                  </div>
                  <div class="panel panel-default servicefaqmainpanel">
                    <div class="panel-heading servicefaqpanelheading">
                      <h4 class="panel-title">
                        <a
                          data-toggle="collapse"
                          class="servicefaqlink"
                          id="servicefaq10"
                          data-parent="#accordion2"
                          href="#collapse25"
                        >
                          Q10. What types of commissions will I get?
                          <img
                            src={process.env.PUBLIC_URL + "/img/Path_20191.svg"}
                            class="servicefaqimg"
                          />
                        </a>
                      </h4>
                    </div>
                    <div
                      id="collapse25"
                      class="panel-collapse collapse paddingzero"
                    >
                      <div class="panel-body servicefaqbody">
                        You may get three types of commission: Legal Service
                        Commission, Loan Commission, and Credit Card Commission.
                        The Legal Service Commission is of two categories:
                        Service Purchase Commission and Assistance Commission.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal" id="myModal">
            <div class="modal-dialog  modal-dialog-centered jcactivationmodal">
              <div class="modal-content jcactivationmodalinnner">
                <div class="modal-body jcactivationmodalinnnerbody">
                  <img
                    src={process.env.PUBLIC_URL + "/img/close-black.svg"}
                    className="closejcpaymodel"
                    data-dismiss="modal"
                    alt=""
                    srcset=""
                  />
                  <button
                    onClick={() => this.handlelanguagechange()}
                    className="languageicon"
                  >
                    {" "}
                    {this.state.englanguage ? "English" : "हिंदी"}{" "}
                  </button>
                  <p className="portalactivationheading">
                    Portal Activation Form
                  </p>
                  <p className="portaltypestextheading">Services</p>

                  <div className="chooseportalcardouterdiv">
                    <div className="row">
                      <div className="col-sm-4 col-md-4">
                        <div className="chooseportalcardouterdiv2 pafmar">
                          <div className="chooseportalcard">
                            <div className="float-right">
                              <label class="main" htmlFor="#Legal">
                                <input
                                  type="checkbox"
                                  id="Legal"
                                  value="Legal"
                                  disabled
                                  checked={this.state.legalchecked}
                                />
                                <span class="geekmark"></span>
                              </label>
                            </div>
                            <br /> <br />
                            <p className="chooseportaltext1">
                              {" "}
                              {this.state.englanguage
                                ? "लीगल सर्विसेस"
                                : "Legal Services"}{" "}
                            </p>
                            {/* <p className="chooseportaltext3">₹ 1000</p> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4 col-md-4">
                        <div className="chooseportalcardouterdiv2">
                          <div className="chooseportalcard">
                            <div className="float-right">
                              <label class="main" htmlFor="#Loan">
                                <input
                                  type="checkbox"
                                  id="Loan"
                                  value="Loan"
                                  disabled
                                  checked={this.state.loanchecked}
                                />
                                <span class="geekmark"></span>
                              </label>
                            </div>
                            <br /> <br />
                            <p className="chooseportaltext1">
                              {this.state.englanguage
                                ? "लोन & क्रेडिट कार्ड"
                                : "Loan & Credit Card"}
                            </p>
                            {/* <p className="chooseportaltext3">₹ 2000</p> */}
                          </div>{" "}
                        </div>
                      </div>

                      <div className="col-sm-4 col-md-4">
                        <div className="chooseportalcardouterdiv2">
                          <div className="chooseportalcard">
                            <div className="float-right">
                              <label class="main" htmlFor="#Other">
                                <input
                                  type="checkbox"
                                  id="Other"
                                  value="Other"
                                  disabled
                                  checked={this.state.otherchecked}
                                />
                                <span class="geekmark"></span>
                              </label>
                            </div>
                            <br /> <br />
                            <p className="chooseportaltext1">
                              {this.state.englanguage
                                ? "आने वाली सभी नयी सर्विसेज"
                                : "Upcoming all new services"}
                            </p>
                            {/* <p className="chooseportaltext3">₹ 2000</p> */}
                          </div>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="chooseportaltext2">
                    {this.state.englanguage
                      ? "100% पोर्टल चार्ज अमाउंट रिफंड होगा यदि आप 1 साल में नए पोर्टल से 50000 या उस से अधिक का बिज़नेस कर देते हे । कमीशन भी अलग से मिलेगा अमाउंट पर।"
                      : "100% Portal Charge amount will be refunded if you do business of 50000 or more with in 1 year. Commission also given on amount."}
                  </p>
                  <center>
                    <button className="activatebtn2">
                      Total Amount: <span>₹ {this.state.Mainamount} </span>
                    </button>
                    <p className="amounttransnote">
                      {this.state.englanguage
                        ? "कृपया ऊपर दर्शाया गया अमाउंट ट्रांसफर कर नीचे जानकारी अपलोड करें"
                        : "Please transfer the above amount within the bank details showing on portal and upload the screenshot or payment proof below."}
                    </p>
                  </center>

                  <div className="jcpopupinputfielddiv">
                    <div className="row">
                    <div className="col-md-3">
                        <div className="jcpopupinputfielddivinside">
                          <label className="accountdetilsinputlabel">
                            Payment type
                          </label>
                          <select
                            type="text"
                            class="form-control amountdetilsinput8"
                            id="mail"
                            name="text"
                            onChange={this.handlePaymenttypeChange}
                            value={this.state.Paymenttype}
                          >
                            <option value="Pay Now">Pay Now</option>
                            <option value="Pay Later">Pay Later</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="jcpopupinputfielddivinside">
                          <label className="accountdetilsinputlabel">
                            Amount
                          </label>
                          <input
                            type="text"
                            class="form-control amountdetilsinput1"
                            placeholder="Your Email"
                            id="mail"
                            value={this.state.Mainamount}
                            name="text"
                          />
                        </div>
                      </div>
                     
                      {this.state.Paymenttype == "Pay Now" ? 
                     <>

                      <div className="col-md-3">
                        <div className="jcpopupinputfielddivinside">
                          <label className="accountdetilsinputlabel">
                            UTR Number<span>*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control amountdetilsinput2"
                            onChange={this.handleUTRChange}
                            value={this.state.UTRno}
                            id="mail"
                            maxLength="80"
                            name="text"
                            placeholder="Enter UTR Number"
                          />
                        </div>
                      </div>
                     
                     
                      <div className="col-md-3">
                        <div className="jcpopupinputfielddivinside">
                          <label className="accountdetilsinputlabel">
                            Screenshot<span>*</span>
                          </label>
                          <div class="input-group mb-3">
                            <input
                              type="text"
                              class="form-control amountdetilsinput4"
                              placeholder="Your Email"
                              id="mail"
                              name="text"
                              value={this.state.selectfilenames}
                            />
                            <div class="input-group-append">
                              <label class="custom-file-upload">
                                <input
                                  type="file"
                                  onClick={(event) => {
                                    event.target.value = null;
                                  }}
                                  accept="image/jpg , image/jpeg , image/png, application/pdf"
                                  onChange={this.handleFileInputChange1}
                                  hidden
                                />
                                Choose file
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                     </>: null}
                     
                     
                     

                      <div className="col-md-3">
                        <div className="jcpopupinputfielddivinside">
                          <label className="accountdetilsinputlabel">
                            Refferal
                          </label>
                          <input
                            type="text"
                            class="form-control amountdetilsinput3"
                            id="mail"
                            name="text"
                            maxLength="30"
                            placeholder=""
                            onChange={this.handleRefferalChange}
                            value={this.state.Refferal}
                          />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="jcpopupinputfielddivinside">
                          <label className="accountdetilsinputlabel2">
                            Remark
                          </label>
                          <input
                            type="text"
                            class="form-control amountdetilsinput5"
                            placeholder="Enter Remark"
                            maxLength="30"
                            id="mail"
                            value={this.state.coupon}
                            onChange={this.handlecouponChange}
                            name="text"
                          />
                        </div>
                      </div>
                      {this.state.Paymenttype == "Pay Later" ? 
                     <>
                     <div className="col-md-3">
                      <div className="jcpopupinputfielddivinside">
                        <label className="accountdetilsinputlabel3">
                        Employee Id<span>*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control amountdetilsinput9"
                          placeholder="Enter  Employee Id"
                          maxLength="10"
                          id="mail"
                          value={this.state.employeeid}
                          onChange={this.handleemployeeidChange}
                          name="text"
                        />
                      </div>
                    </div>
                      <div className="col-md-3">
                        <div className="jcpopupinputfielddivinside">
                          <label className="accountdetilsinputlabel3">
                            Pin<span>*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control amountdetilsinput10"
                            placeholder="Enter PIN"
                            maxLength="10"
                            id="mail"
                            value={this.state.PIN}
                            onChange={this.handlePINChange}
                            name="text"
                          />
                        </div>
                      </div></> : null}
                    </div>
                    <center>
                      <label class="main2">
                        Here by I accept Legal251's{" "}
                        <a
                          href="https://legal251.com/terms-and-conditions"
                          target="_blank"
                        >
                          Terms & Condition
                        </a>
                        ,{" "}
                        <a
                          href="https://legal251.com/privacy-policy"
                          target="_blank"
                        >
                          Privacy Policy
                        </a>
                        , &{" "}
                        <a
                          href="https://legal251.com/refund-policy"
                          target="_blank"
                        >
                          Refund policy
                        </a>
                        . Also I am giving my consent to activate new portal.
                        <input
                          type="checkbox"
                          value="Legal"
                          onChange={this.checkboxhandle3}
                          checked={this.state.conditinschecked}
                        />
                        <span class="geekmark2"></span>
                      </label>
                      <div className="errorsidediv">
                        <p className="errorside errorsidejcpay">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/img/Icon_feather-alert-circle.svg"
                            }
                            className="errorsideimg"
                          />
                          &nbsp;
                          <span className="errorsidespan"></span>
                        </p>
                      </div>
                    </center>
                  </div>
                  <center>
                    <button
                      className="accountdetilsinputsubmit"
                      onClick={this.submitutrno}
                    >
                      Submit
                    </button>
                    <img
                      className="addserviceloader"
                      src={process.env.PUBLIC_URL + "/img/loader.gif"}
                      alt=""
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
          <br /> <br /> <br /> <br /> <br />
        </div>
      </div>
    );
  }
}

export default JcpayLegalmerge;

import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $, { timers } from "jquery";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import SupportDetails from "./SupportDetails";
import SupportDetailsMobile from "./SupportDetailsMobile";
import JSZipUtils from "jszip-utils";
import JSZip from "jszip";
import { saveAs } from "file-saver";
var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);

let ChooseNowBtnsDisable = "No";
let Decserviceid;
let Decplan;
let Decservicename;
let Decuserid;
let Decagentid;
let Decjwttoken;
let mainstatusVar;
let additionalReupload = false;
let mainaction = "upload";
let temp = "general";
let additionalFilesIndex;
let additionalReuploadIndex;
let additionalReuploadStatus;
let additionalFilesNames = [];
let supportName;
let supportContact;
let supportEmail;

let mainactionPANCard = "upload";
let PANCardStatus;
let mainactionAddressProof = "upload";
let AddressProofStatus;
let mainactionMsmeStartupCertificate = "upload";
let MsmeStartupCertificateStatus;
let mainactionProofOfTM = "upload";
let ProofOfTMStatus;
let mainactionBrandLogo = "upload";
let BrandLogoStatus;

let mainDocs = [
  {
    doc: "PANCardStatus",
    status: PANCardStatus,
  },
  {
    doc: "AddressProofStatus",
    status: AddressProofStatus,
  },
];

let msmeDoc = [
  {
    doc: "MsmeStartupCertificateStatus",
    status: MsmeStartupCertificateStatus,
  },
];

let brandNameDoc = [
  {
    doc: "ProofOfTMStatus",
    status: ProofOfTMStatus,
  },
];

let brandLogoDoc = [
  {
    doc: "BrandLogoStatus",
    status: BrandLogoStatus,
  },
];

let additionalStatusArrayCheck;
let AnyReuploadOccured = "No";
let MSMEReupload = "No";
let proceednowforword;

export class ActiveTr extends Component {
  constructor(props) {
    super(props);

    this.state = {
      serviceType: "",
      serviceTypeText: "",
      fileName: "File Name",
      fileBase64: "",
      documentType: "",
      documentName: "",
      comment: "",
      commentHistroyarray: [],
      brandName_radio: "No",
      trademark_radio: "Logo",
      Date: "",
      trademarkWord: "",
      businessNature: "",
      PANCardDocLink: "#",
      AddressProofDocLink: "#",
      MsmeStartupCertificateDocLink: "#",
      BrandLogoDocLink: "#",
      ProofOfTMDocLink: "#",
      IsAddFileDisabled: true,
      additionalFiles: [],
      api_additionalFiles: "No",
      Add_DocName: "",            
      Name:"",
      Contact:"",
      Timeline:[],
      Timelinedata: false,
      timelcount: 2,
      filtertimel: [],
      
      supportName:"",
      supportContact:"",
      supportEmail:"",
      deliveredDocuments: [],
      referralPartner: true,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    $(document.body).css("background-color", "#2d2d2d");
    let mainuserid = localStorage.getItem("relleIsugad");
    Decuserid = encryptor.decrypt(mainuserid);
    let mainagentid = localStorage.getItem("qazxswedcvfrtgb");
    Decagentid = encryptor.decrypt(mainagentid);
    let mainjwttoken = localStorage.getItem("toeljgtkewlna");
    Decjwttoken = encryptor.decrypt(mainjwttoken);
    let mainserviceid = localStorage.getItem("vrellvaiecstiadeceig");
    let mainaservicename = localStorage.getItem("eieltaiclsraaemevcvnge");
    let mainplan = localStorage.getItem("ipacneglatvlael");
    supportName = localStorage.getItem("supportName");
    supportContact = localStorage.getItem("supportContact");
    supportEmail = localStorage.getItem("supportEmail");
    
    this.setState({
      supportName:supportName,
      supportContact:supportContact,
      supportEmail:supportEmail,
    })

    if(this.state.referralPartner == true){
      const script = document.createElement("script");
      script.src = "/js/main.js";
      script.async = true;
      document.body.appendChild(script);
    }
    // ----------------------------temporary -----------------------------------------


    let temptoken = localStorage.getItem("fdgshdjfkgjhyhdnxhsgdhskcj")
    let Dectemptoken = encryptor.decrypt(temptoken)
    let paramsUserId2 = this.props.match.params.id2;
    if (Dectemptoken != null && Dectemptoken == paramsUserId2) {
      temp = "temporary"
    } else {
      temp = "general"
    }



    // ----------------------------temporary -----------------------------------------
    if (mainserviceid != null && mainaservicename != null) {
      let paramsId = this.props.match.params.id;
      let paramsUserId = this.props.match.params.id2;
      Decserviceid = encryptor.decrypt(mainserviceid);
      Decservicename = encryptor.decrypt(mainaservicename);
      Decplan = encryptor.decrypt(mainplan);
      if (paramsId != Decserviceid || paramsUserId != Decuserid) {
        this.props.history.push("/dashboard");
      }
    } else {
      this.props.history.push("/dashboard");
    }
    this.getserviceInformationApi();

     
    let str = document.getElementsByClassName("myservicesortbyfilterbystatus").innerHTML = this.props.location.pathname.replaceAll("/", " > ")
    let mainstr = document.getElementsByClassName("myservicesortbyfilterbystatus").innerHTML = str.replace(" > ", " ")
    $(".myservicesortbyfilterbystatus").html(mainstr)


    this.getnameanumber();


  }

  getnameanumber = () => {
    axios.post(
      process.env.REACT_APP_API_BASE_URL + "p=empexecutiveDetails",
      {
        agentId: Decagentid,
        serviceName: Decservicename,
        serviceId: Decserviceid, 
      },
      {
        headers: {
          Authkey: process.env.REACT_APP_API_KEY,
          "App-Token-Access": Decjwttoken,
        },
        auth: {
          username: process.env.REACT_APP_API_USERNAME,
          password: process.env.REACT_APP_API_PASSWORD,
        },
      }
    )
    .then((res) => {
      if (res.data.code == "200" && res.data.status == "success") {
        this.setState({
          Name: res.data.teamLeaderName,
            Contact: res.data.teamLeaderNumber,
        })
      } else if (res.data.code == "201" && res.data.status == "invalidauth") {
        this.logout();
      } else {
          this.showNotification(res.data.message);
        } 
    })
  }

  getserviceInformationApi = () => {
    $(".serviceoverlay").show();
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=serviceInformation",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          plan: "",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        $(".serviceoverlay").hide();
        if (res.data.code == "200" && res.data.status == "success") {

          this.setState({
            deliveredDocuments: res.data.deliveredDocuments,
          });

          ChooseNowBtnsDisable = "No";
          $(".upload-photo").attr("disabled", false);
          mainstatusVar = res.data.mainstatus;
          // additional
          if (res.data.informationStatus == "lock") {
            $(".proceednowbtn").hide();
            if (res.data.addDocInformation.length == 0) {
              $("#additionalDocumentDiv").hide();
            } else {
              $("#Adddocumentbtn").hide();
            }
          }
          if (res.data.addDocInformation.length > 0) {
            this.setState(
              {
                additionalFiles: [...res.data.addDocInformation],
                Add_DocName: "File Name",
                api_additionalFiles: "Yes",
              },
              () => {}
            );
          } else if (
            res.data.addDocInformation.length == 0 &&
            res.data.informationStatus != "lock"
          ) {
            this.setState({
              Add_DocName: "",
              additionalFiles: [1],
              api_additionalFiles: "No",
            });
          }

          // input fields
          if (res.data.inputFields.length > 0) {
            let inputFields = res.data.inputFields;
            if (inputFields[1].PreviousTM == null) {
              this.setState({
                brandName_radio: "No",
              });
            } else if (inputFields[3].WantToTrademark == null) {
              this.setState({
                trademark_radio: "Logo",
              });
            } else {
              this.setState(
                {
                  brandName_radio: inputFields[1].PreviousTM,
                  trademark_radio: inputFields[3].WantToTrademark,
                },
                () => {
                  if (this.state.brandName_radio == "Yes") {
                    $(".brandName-yes").css("display", "flex");
                  } else {
                    $(".brandName-yes").hide();
                  }
                  if (this.state.trademark_radio != "Logo") {
                    $(".trademark-logo").hide();
                    $(".trademark-word").addClass("lastdisplayflex");
                    $(".border-change")
                      .removeClass("uploaddatacol")
                      .addClass("uploadlastdatacol");
                  } else if (this.state.trademark_radio == "Logo") {
                    $(".trademark-logo").show();
                    $(".trademark-word").removeClass("lastdisplayflex");
                    $(".border-change")
                      .removeClass("uploadlastdatacol")
                      .addClass("uploaddatacol");
                  }
                }
              );
            }
            if (
              inputFields[0].status == "review" ||
              inputFields[0].status == "done"
            ) {
              this.setState({
                businessNature: inputFields[0].Nature,
              });
              $(".uploadtextarea").attr("disabled", true);
            }
            if (
              inputFields[1].status == "review" ||
              inputFields[1].status == "done"
            ) {
              this.setState({
                brandName_radio: inputFields[1].PreviousTM,
              });
              $("#test2").attr("disabled", true);
              $("#test3").attr("disabled", true);
            }
            if (
              inputFields[2].status == "review" ||
              inputFields[2].status == "done"
            ) {
              this.setState({
                Date: inputFields[2].TMUseDate,
              });
              $(".uploaddatefield").attr("disabled", true);
            }
            if (
              inputFields[3].status == "review" ||
              inputFields[3].status == "done"
            ) {
              this.setState({
                trademark_radio: inputFields[3].WantToTrademark,
              });
              $("#test4").attr("disabled", true);
              $("#test5").attr("disabled", true);
            }
            if (
              inputFields[4].status == "review" ||
              inputFields[4].status == "done"
            ) {
              this.setState({
                trademarkWord: inputFields[4].BrandName,
              });
              $("#uploadtextfield_trademarkWord").attr("disabled", true);
            }
          }

          // documents
          if (res.data.documents.length > 0) {
            let doc = res.data.documents;
            if (doc[0].status == "remove") {
              mainactionPANCard = "upload";
              PANCardStatus = "remove";
              mainDocs[0].status = PANCardStatus;
              this.setState(
                {
                  PANCardDocLink: doc[0].PANCard,
                },
                () => {
                  $("#viewDocLink_PANCard").css("display", "block");
                  $("#choose_PANCard").hide();
                  $("#X-btn_PANCard").show();
                  $("#done-btn_PANCard").hide();
                  $("#review-btn_PANCard").hide();
                }
              );
            } else if (doc[0].status == "review") {
              mainactionPANCard = "upload";
              PANCardStatus = "review";
              mainDocs[0].status = PANCardStatus;
              this.setState(
                {
                  PANCardDocLink: doc[0].PANCard,
                },
                () => {
                  $("#viewDocLink_PANCard").css("display", "block");
                  $("#choose_PANCard").hide();
                  $("#X-btn_PANCard").hide();
                  $("#done-btn_PANCard").hide();
                  $("#review-btn_PANCard").show();
                }
              );
            } else if (doc[0].status == "reupload") {
              mainactionPANCard = "reupload";
              PANCardStatus = "reupload";
              mainDocs[0].status = PANCardStatus;
              $("#choose_PANCard").show();
              $("#viewDocLink_PANCard").hide();
              $("#X-btn_PANCard").hide();
              $("#done-btn_PANCard").hide();
              $("#review-btn_PANCard").hide();
            } else if (doc[0].status == "done") {
              PANCardStatus = "done";
              mainactionPANCard = "upload";
              mainDocs[0].status = PANCardStatus;
              $("#choose_PANCard").hide();
              $("#X-btn_PANCard").hide();
              $("#done-btn_PANCard").show();
              $("#review-btn_PANCard").hide();
            } else {
              PANCardStatus = "open";
              mainDocs[0].status = PANCardStatus;
            }
            if (doc[1].status == "remove") {
              mainactionAddressProof = "upload";
              AddressProofStatus = "remove";
              mainDocs[1].status = AddressProofStatus;
              this.setState(
                {
                  AddressProofDocLink: doc[1].AddressProof,
                },
                () => {
                  $("#viewDocLink_AddressProof").css("display", "block");
                  $("#choose_AddressProof").hide();
                  $("#X-btn_AddressProof").show();
                  $("#done-btn_AddressProof").hide();
                  $("#review-btn_AddressProof").hide();
                }
              );
            } else if (doc[1].status == "review") {
              mainactionAddressProof = "upload";
              AddressProofStatus = "review";
              mainDocs[1].status = AddressProofStatus;
              this.setState(
                {
                  AddressProofDocLink: doc[1].AddressProof,
                },
                () => {
                  $("#viewDocLink_AddressProof").css("display", "block");
                  $("#choose_AddressProof").hide();
                  $("#X-btn_AddressProof").hide();
                  $("#done-btn_AddressProof").hide();
                  $("#review-btn_AddressProof").show();
                }
              );
            } else if (doc[1].status == "reupload") {
              mainactionAddressProof = "reupload";
              AddressProofStatus = "reupload";
              mainDocs[1].status = AddressProofStatus;
              $("#choose_AddressProof").show();
              $("#viewDocLink_AddressProof").hide();
              $("#X-btn_AddressProof").hide();
              $("#done-btn_AddressProof").hide();
              $("#review-btn_AddressProof").hide();
            } else if (doc[1].status == "done") {
              AddressProofStatus = "done";
              mainactionAddressProof = "upload";
              mainDocs[1].status = AddressProofStatus;
              $("#choose_AddressProof").hide();
              $("#X-btn_AddressProof").hide();
              $("#done-btn_AddressProof").show();
              $("#review-btn_AddressProof").hide();
            } else {
              AddressProofStatus = "open";
              mainDocs[1].status = AddressProofStatus;
            }
            if (doc[2].status == "remove") {
              mainactionMsmeStartupCertificate = "upload";
              MsmeStartupCertificateStatus = "remove";
              msmeDoc[0].status = MsmeStartupCertificateStatus;
              this.setState(
                {
                  MsmeStartupCertificateDocLink: doc[2].MsmeStartupCertificate,
                },
                () => {
                  $("#viewDocLink_MsmeStartupCertificate").css(
                    "display",
                    "block"
                  );
                  $("#choose_MsmeStartupCertificate").hide();
                  $("#X-btn_MsmeStartupCertificate").show();
                  $("#done-btn_MsmeStartupCertificate").hide();
                  $("#review-btn_MsmeStartupCertificate").hide();
                }
              );
            } else if (doc[2].status == "review") {
              mainactionMsmeStartupCertificate = "upload";
              MsmeStartupCertificateStatus = "review";
              msmeDoc[0].status = MsmeStartupCertificateStatus;
              this.setState(
                {
                  MsmeStartupCertificateDocLink: doc[2].MsmeStartupCertificate,
                },
                () => {
                  $("#viewDocLink_MsmeStartupCertificate").css(
                    "display",
                    "block"
                  );
                  $("#choose_MsmeStartupCertificate").hide();
                  $("#X-btn_MsmeStartupCertificate").hide();
                  $("#done-btn_MsmeStartupCertificate").hide();
                  $("#review-btn_MsmeStartupCertificate").show();
                }
              );
            } else if (doc[2].status == "reupload") {
              mainactionMsmeStartupCertificate = "reupload";
              MsmeStartupCertificateStatus = "reupload";
              msmeDoc[0].status = MsmeStartupCertificateStatus;
              $("#choose_MsmeStartupCertificate").show();
              $("#viewDocLink_MsmeStartupCertificate").hide();
              $("#X-btn_MsmeStartupCertificate").hide();
              $("#done-btn_MsmeStartupCertificate").hide();
              $("#review-btn_MsmeStartupCertificate").hide();
            } else if (doc[2].status == "done") {
              MsmeStartupCertificateStatus = "done";
              mainactionMsmeStartupCertificate = "upload";
              msmeDoc[0].status = MsmeStartupCertificateStatus;
              $("#choose_MsmeStartupCertificate").hide();
              $("#X-btn_MsmeStartupCertificate").hide();
              $("#done-btn_MsmeStartupCertificate").show();
              $("#review-btn_MsmeStartupCertificate").hide();
            } else {
              MsmeStartupCertificateStatus = "open";
              msmeDoc[0].status = MsmeStartupCertificateStatus;
            }
            if (doc[3].status == "remove") {
              mainactionProofOfTM = "upload";
              ProofOfTMStatus = "remove";
              brandNameDoc[0].status = ProofOfTMStatus;
              this.setState(
                {
                  ProofOfTMDocLink: doc[3].ProofOfTM,
                },
                () => {
                  $("#viewDocLink_ProofOfTM").css("display", "block");
                  $("#choose_ProofOfTM").hide();
                  $("#X-btn_ProofOfTM").show();
                  $("#done-btn_ProofOfTM").hide();
                  $("#review-btn_ProofOfTM").hide();
                }
              );
            } else if (doc[3].status == "review") {
              mainactionProofOfTM = "upload";
              ProofOfTMStatus = "review";
              brandNameDoc[0].status = ProofOfTMStatus;
              this.setState(
                {
                  ProofOfTMDocLink: doc[3].ProofOfTM,
                },
                () => {
                  $("#viewDocLink_ProofOfTM").css("display", "block");
                  $("#choose_ProofOfTM").hide();
                  $("#X-btn_ProofOfTM").hide();
                  $("#done-btn_ProofOfTM").hide();
                  $("#review-btn_ProofOfTM").show();
                }
              );
            } else if (doc[3].status == "reupload") {
              mainactionProofOfTM = "reupload";
              ProofOfTMStatus = "reupload";
              brandNameDoc[0].status = ProofOfTMStatus;
              $("#choose_ProofOfTM").show();
              $("#viewDocLink_ProofOfTM").hide();
              $("#X-btn_ProofOfTM").hide();
              $("#done-btn_ProofOfTM").hide();
              $("#review-btn_ProofOfTM").hide();
            } else if (doc[3].status == "done") {
              ProofOfTMStatus = "done";
              mainactionProofOfTM = "upload";
              brandNameDoc[0].status = ProofOfTMStatus;
              $("#choose_ProofOfTM").hide();
              $("#X-btn_ProofOfTM").hide();
              $("#done-btn_ProofOfTM").show();
              $("#review-btn_ProofOfTM").hide();
            } else {
              ProofOfTMStatus = "open";
              brandNameDoc[0].status = ProofOfTMStatus;
            }
            if (doc[4].status == "remove") {
              mainactionBrandLogo = "upload";
              BrandLogoStatus = "remove";
              brandLogoDoc[0].status = BrandLogoStatus;
              this.setState(
                {
                  BrandLogoDocLink: doc[4].BrandLogo,
                },
                () => {
                  $("#viewDocLink_BrandLogo").css("display", "block");
                  $("#choose_BrandLogo").hide();
                  $("#X-btn_BrandLogo").show();
                  $("#done-btn_BrandLogo").hide();
                  $("#review-btn_BrandLogo").hide();
                }
              );
            } else if (doc[4].status == "review") {
              mainactionBrandLogo = "upload";
              BrandLogoStatus = "review";
              brandLogoDoc[0].status = BrandLogoStatus;
              this.setState(
                {
                  BrandLogoDocLink: doc[4].BrandLogo,
                },
                () => {
                  $("#viewDocLink_BrandLogo").css("display", "block");
                  $("#choose_BrandLogo").hide();
                  $("#X-btn_BrandLogo").hide();
                  $("#done-btn_BrandLogo").hide();
                  $("#review-btn_BrandLogo").show();
                }
              );
            } else if (doc[4].status == "reupload") {
              mainactionBrandLogo = "reupload";
              BrandLogoStatus = "reupload";
              brandLogoDoc[0].status = BrandLogoStatus;
              $("#choose_BrandLogo").show();
              $("#viewDocLink_BrandLogo").hide();
              $("#X-btn_BrandLogo").hide();
              $("#done-btn_BrandLogo").hide();
              $("#review-btn_BrandLogo").hide();
            } else if (doc[4].status == "done") {
              BrandLogoStatus = "done";
              mainactionBrandLogo = "upload";
              brandLogoDoc[0].status = BrandLogoStatus;
              $("#choose_BrandLogo").hide();
              $("#X-btn_BrandLogo").hide();
              $("#done-btn_BrandLogo").show();
              $("#review-btn_BrandLogo").hide();
            } else {
              BrandLogoStatus = "open";
              brandLogoDoc[0].status = BrandLogoStatus;
            }
          } else {
            mainDocs[0].status = "open";
            mainDocs[1].status = "open";
            msmeDoc[0].status = "open";
            brandLogoDoc[0].status = "open";
            brandNameDoc[0].status = "open";
            BrandLogoStatus = "open";
            PANCardStatus = "open";
            AddressProofStatus = "open";
            MsmeStartupCertificateStatus = "open";
            ProofOfTMStatus = "open";
          }
          // serviceType
          if (res.data.serviceType == "ISP") {
            this.setState({
              serviceType: res.data.serviceType,
              serviceTypeText: "Individual / Sole Proprietor",
            });
          } else if (res.data.serviceType == "CPG") {
            this.setState({
              serviceType: res.data.serviceType,
              serviceTypeText: "Company / Proprietorship / Group of Individual",
            });
          } else if (res.data.serviceType == "MS") {
            this.setState({
              serviceType: res.data.serviceType,
              serviceTypeText: "MSME / Startup",
            });
          }

          if (res.data.serviceType != "MS") {
            let statusCheck = this.statusCheckFunction("reupload", mainDocs);
            additionalStatusArrayCheck = this.statusCheckFunction(
              "reupload",
              this.state.additionalFiles
            );
            let goodtogo_brandName = "No";
            let goodtogo_trademark = "No";
            if (this.state.brandName_radio == "Yes") {
              if (brandNameDoc[0].status != "reupload") {
                goodtogo_brandName = "Yes";
              } else {
                goodtogo_brandName = "No";
              }
            } else {
              goodtogo_brandName = "Yes";
            }

            if (this.state.trademark_radio == "Logo") {
              if (brandLogoDoc[0].status != "reupload") {
                goodtogo_trademark = "Yes";
              } else {
                goodtogo_trademark = "No";
              }
            } else {
              goodtogo_trademark = "Yes";
            }

            if (
              goodtogo_trademark == "Yes" &&
              goodtogo_brandName == "Yes" &&
              statusCheck == undefined &&
              additionalStatusArrayCheck == undefined &&
              res.data.mainstatus == "true"
            ) {
              MSMEReupload = "Yes";
            } else {
              MSMEReupload = "No";
            }
          }

          this.checkRemainingReuploads();
          this.checkhandleProceedBtn();
          if (
            res.data.informationStatus == "lock" &&
            res.data.mainstatus == "false"
          ) {
            ChooseNowBtnsDisable = "Yes";
            $(".upload-photo").attr("disabled", true);
            $(".proceednowbtn").hide();
          } else if (
            res.data.informationStatus == "lock" &&
            res.data.mainstatus == "true"
          ) {
            ChooseNowBtnsDisable = "No";
            $(".upload-photo").attr("disabled", false);
            $(".proceednowbtn").show();
            $(".proceednowbtn").attr("disabled", true);
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "docuploadnotallowed") {
          this.setState({
            referralPartner:false,
          })
          this.showNotification(res.data.message);
        }  else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $(".serviceoverlay").hide();
        this.showNotification("Something went wrong, Try again!");
      });
  };

  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };

  logout = () => {
    localStorage.removeItem("gglltakoinoeenl");
    localStorage.removeItem("toeljgtkewlna");
    localStorage.removeItem("relleIsugad");
    this.props.history.push({
      pathname: `/`,
    });
  };
  renderCommenthistory() {
    if (this.state.commentHistroyarray.length > 0) {
      return this.state.commentHistroyarray.map((activedoc, index) => {
        if (activedoc.commentBy == "user" && activedoc.special == "no") {
          return (
            <div className="rightconvtextdiv" key={index}>
              <p className="rightconvtext">{activedoc.comment}</p>
              <p className="rightconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "user" &&
          activedoc.special == "green"
        ) {
          return (
            <div className="rightconvtextdiv" key={index}>
              <p className="rightgreenconvtext">{activedoc.comment}</p>
              <p className="rightconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "employee" &&
          activedoc.special == "no"
        ) {
          return (
            <div className="leftconvtextdiv" key={index}>
              <p className="leftconvtext">{activedoc.comment}</p>
              <p className="leftconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "user" &&
          activedoc.special == "red"
        ) {
          return (
            <div className="rightunappconvtextdiv" key={index}>
              <p className="rightunappconvtext">{activedoc.comment}</p>
              <p className="rightconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "employee" &&
          activedoc.special == "green"
        ) {
          return (
            <div className="leftgreenconvtextdiv" key={index}>
              <p className="leftgreenconvtext">{activedoc.comment}</p>
              <p className="leftconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "employee" &&
          activedoc.special == "red"
        ) {
          return (
            <div className="leftconvtextdiv" key={index}>
              <p className="leftunappconvtext">{activedoc.comment}</p>
              <p className="leftconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        }
      });
    } else if (this.state.commentHistroyarray.length == 0) {
      return <p className="convservicename">No comments available!</p>;
    }
  }
  handleComment = (e) => {
    this.setState({
      comment: e.target.value,
    });
  };

  handlebusinessNatureChange = (e) => {
    this.setState({
      businessNature: e.target.value,
    });
  };

  handleDateChange = (e) => {
    this.setState({
      Date: e.target.value,
    });
  };

  brandName_radioChange = (e) => {
    this.setState(
      {
        brandName_radio: e.currentTarget.value,
      },
      () => {
        this.checkhandleProceedBtn();
        if (this.state.brandName_radio == "Yes") {
          $(".brandName-yes").css("display", "flex");
        } else {
          $(".brandName-yes").hide();
        }
      }
    );
  };

  trademark_radioChange = (e) => {
    this.setState(
      {
        trademark_radio: e.currentTarget.value,
      },
      () => {
        if (this.state.trademark_radio != "Logo") {
          $(".trademark-logo").hide();
          $(".trademark-word").addClass("lastdisplayflex");
          $(".border-change")
            .removeClass("uploaddatacol")
            .addClass("uploadlastdatacol");
        } else if (this.state.trademark_radio == "Logo") {
          $(".trademark-logo").show();
          $(".trademark-word").removeClass("lastdisplayflex");
          $(".border-change")
            .removeClass("uploadlastdatacol")
            .addClass("uploaddatacol");
        }
        this.checkhandleProceedBtn();
      }
    );
  };

  handletrademarkWordChange = (e) => {
    this.setState({
      trademarkWord: e.target.value,
    });
  };

  statusCheckFunction = (status, array) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i].status == status) {
        return i;
      }
    }
  };

  IsMSMERequired = (e) => {
    if (this.state.serviceType == "MS") {
      return <sup className="requireddocstar">*</sup>;
    }
  };

  // PANCard
  handlePANCardChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "PANCard",
          },
          () => {
            $("#PANCardModal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "PANCard",
          });
        }
      });
      $("#PANCardModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPANCard = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PANCard").attr("disabled", true);
      $("#upload_btn20_PANCard").hide();
      $("#upload_btnloader_PANCard").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PANCardDocLink: res.data.data.PANCard,
                },
                () => $("#viewDocLink_PANCard").css("display", "block")
              );
              PANCardStatus = "remove";
              mainDocs[0].status = PANCardStatus;
              this.checkhandleProceedBtn();
              $("#PANCardModal").modal("hide");
              $("#cancelbtn_PANCard").attr("disabled", false);
              $("#upload_btn20_PANCard").show();
              $("#upload_btnloader_PANCard").hide();
              $("#choose_PANCard").hide();
              $("#X-btn_PANCard").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PANCard").attr("disabled", false);
            $("#upload_btn20_PANCard").show();
            $("#upload_btnloader_PANCard").hide();
            $("#PANCardModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PANCard").attr("disabled", false);
            $("#upload_btn20_PANCard").show();
            $("#upload_btnloader_PANCard").hide();
            $("#PANCardModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PANCard").attr("disabled", false);
          $("#upload_btn20_PANCard").show();
          $("#upload_btnloader_PANCard").hide();
          $("#PANCardModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePANCard = () => {
    $("#X-btn_PANCard").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: "",
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "PANCard",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PANCardStatus = res.data.data.status;
          mainDocs[0].status = PANCardStatus;
          this.checkhandleProceedBtn();
          $("#choose_PANCard").show();
          $("#X-btn_PANCard").hide();
          $("#X-btn_PANCard").attr("disabled", false);
          $("#viewDocLink_PANCard").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PANCard").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PANCard").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePANCardseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PANCardseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PANCard",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PANCardseecommentsbtn").hide();
          $("#PANCardhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePANCardhidecommentsbtn = () => {
    $("#PANCardseecommentsbtn").show();
    $("#PANCardseecommentsbtn").attr("disabled", false);
    $("#PANCardhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // AddressProof
  handleAddressProofChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "AddressProof",
          },
          () => {
            $("#AddressProofModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "AddressProof",
          });
        }
      });
      $("#AddressProofModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadAddressProof = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_AddressProof").attr("disabled", true);
      $("#upload_btn20_AddressProof").hide();
      $("#upload_btnloader_AddressProof").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  AddressProofDocLink: res.data.data.AddressProof,
                },
                () => $("#viewDocLink_AddressProof").css("display", "block")
              );
              AddressProofStatus = "remove";
              mainDocs[1].status = AddressProofStatus;
              this.checkhandleProceedBtn();
              $("#AddressProofModal").modal("hide");
              $("#cancelbtn_AddressProof").attr("disabled", false);
              $("#upload_btn20_AddressProof").show();
              $("#upload_btnloader_AddressProof").hide();
              $("#choose_AddressProof").hide();
              $("#X-btn_AddressProof").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_AddressProof").attr("disabled", false);
            $("#upload_btn20_AddressProof").show();
            $("#upload_btnloader_AddressProof").hide();
            $("#AddressProofModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_AddressProof").attr("disabled", false);
            $("#upload_btn20_AddressProof").show();
            $("#upload_btnloader_AddressProof").hide();
            $("#AddressProofModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_AddressProof").attr("disabled", false);
          $("#upload_btn20_AddressProof").show();
          $("#upload_btnloader_AddressProof").hide();
          $("#AddressProofModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeAddressProof = () => {
    $("#X-btn_AddressProof").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: "",
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "AddressProof",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          AddressProofStatus = res.data.data.status;
          mainDocs[1].status = AddressProofStatus;
          this.checkhandleProceedBtn();
          $("#choose_AddressProof").show();
          $("#X-btn_AddressProof").hide();
          $("#X-btn_AddressProof").attr("disabled", false);
          $("#viewDocLink_AddressProof").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_AddressProof").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_AddressProof").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleAddressProofseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#AddressProofseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "AddressProof",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#AddressProofseecommentsbtn").hide();
          $("#AddressProofhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleAddressProofhidecommentsbtn = () => {
    $("#AddressProofseecommentsbtn").show();
    $("#AddressProofseecommentsbtn").attr("disabled", false);
    $("#AddressProofhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // MsmeStartupCertificate
  handleMsmeStartupCertificateChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "MsmeStartupCertificate",
          },
          () => {
            $("#MsmeStartupCertificateModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "MsmeStartupCertificate",
          });
        }
      });
      $("#MsmeStartupCertificateModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadMsmeStartupCertificate = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_MsmeStartupCertificate").attr("disabled", true);
      $("#upload_btn20_MsmeStartupCertificate").hide();
      $("#upload_btnloader_MsmeStartupCertificate").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            if (MSMEReupload == "Yes") {
              MSMEReupload = "No";
            }
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  MsmeStartupCertificateDocLink:
                    res.data.data.MsmeStartupCertificate,
                },
                () =>
                  $("#viewDocLink_MsmeStartupCertificate").css(
                    "display",
                    "block"
                  )
              );
              MsmeStartupCertificateStatus = "remove";
              msmeDoc[0].status = MsmeStartupCertificateStatus;
              this.checkhandleProceedBtn();
              $("#MsmeStartupCertificateModal").modal("hide");
              $("#cancelbtn_MsmeStartupCertificate").attr("disabled", false);
              $("#upload_btn20_MsmeStartupCertificate").show();
              $("#upload_btnloader_MsmeStartupCertificate").hide();
              $("#choose_MsmeStartupCertificate").hide();
              $("#X-btn_MsmeStartupCertificate").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_MsmeStartupCertificate").attr("disabled", false);
            $("#upload_btn20_MsmeStartupCertificate").show();
            $("#upload_btnloader_MsmeStartupCertificate").hide();
            $("#MsmeStartupCertificateModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_MsmeStartupCertificate").attr("disabled", false);
            $("#upload_btn20_MsmeStartupCertificate").show();
            $("#upload_btnloader_MsmeStartupCertificate").hide();
            $("#MsmeStartupCertificateModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_MsmeStartupCertificate").attr("disabled", false);
          $("#upload_btn20_MsmeStartupCertificate").show();
          $("#upload_btnloader_MsmeStartupCertificate").hide();
          $("#MsmeStartupCertificateModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeMsmeStartupCertificate = () => {
    $("#X-btn_MsmeStartupCertificate").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: "",
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "MsmeStartupCertificate",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          MsmeStartupCertificateStatus = res.data.data.status;
          msmeDoc[0].status = MsmeStartupCertificateStatus;
          this.checkhandleProceedBtn();
          $("#choose_MsmeStartupCertificate").show();
          $("#X-btn_MsmeStartupCertificate").hide();
          $("#X-btn_MsmeStartupCertificate").attr("disabled", false);
          $("#viewDocLink_MsmeStartupCertificate").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_MsmeStartupCertificate").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_MsmeStartupCertificate").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleMsmeStartupCertificateseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#MsmeStartupCertificateseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "MsmeStartupCertificate",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#MsmeStartupCertificateseecommentsbtn").hide();
          $("#MsmeStartupCertificatehidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleMsmeStartupCertificatehidecommentsbtn = () => {
    $("#MsmeStartupCertificateseecommentsbtn").show();
    $("#MsmeStartupCertificateseecommentsbtn").attr("disabled", false);
    $("#MsmeStartupCertificatehidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // BrandLogo
  handleBrandLogoChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "BrandLogo",
          },
          () => {
            $("#BrandLogoModal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "BrandLogo",
          });
        }
      });
      $("#BrandLogoModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadBrandLogo = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_BrandLogo").attr("disabled", true);
      $("#upload_btn20_BrandLogo").hide();
      $("#upload_btnloader_BrandLogo").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  BrandLogoDocLink: res.data.data.BrandLogo,
                },
                () => $("#viewDocLink_BrandLogo").css("display", "block")
              );
              BrandLogoStatus = "remove";
              brandLogoDoc[0].status = BrandLogoStatus;
              this.checkhandleProceedBtn();
              $("#BrandLogoModal").modal("hide");
              $("#cancelbtn_BrandLogo").attr("disabled", false);
              $("#upload_btn20_BrandLogo").show();
              $("#upload_btnloader_BrandLogo").hide();
              $("#choose_BrandLogo").hide();
              $("#X-btn_BrandLogo").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_BrandLogo").attr("disabled", false);
            $("#upload_btn20_BrandLogo").show();
            $("#upload_btnloader_BrandLogo").hide();
            $("#BrandLogoModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_BrandLogo").attr("disabled", false);
            $("#upload_btn20_BrandLogo").show();
            $("#upload_btnloader_BrandLogo").hide();
            $("#BrandLogoModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_BrandLogo").attr("disabled", false);
          $("#upload_btn20_BrandLogo").show();
          $("#upload_btnloader_BrandLogo").hide();
          $("#BrandLogoModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeBrandLogo = () => {
    $("#X-btn_BrandLogo").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: "",
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "BrandLogo",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          BrandLogoStatus = res.data.data.status;
          brandLogoDoc[0].status = BrandLogoStatus;
          this.checkhandleProceedBtn();
          $("#choose_BrandLogo").show();
          $("#X-btn_BrandLogo").hide();
          $("#X-btn_BrandLogo").attr("disabled", false);
          $("#viewDocLink_BrandLogo").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_BrandLogo").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_BrandLogo").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleBrandLogoseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#BrandLogoseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "BrandLogo",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#BrandLogoseecommentsbtn").hide();
          $("#BrandLogohidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleBrandLogohidecommentsbtn = () => {
    $("#BrandLogoseecommentsbtn").show();
    $("#BrandLogoseecommentsbtn").attr("disabled", false);
    $("#BrandLogohidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // ProofofTm
  handleProofOfTMChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "ProofOfTM",
          },
          () => {
            $("#ProofOfTMModal").modal({ backdrop: "static", keyboard: false });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], { base64: true });
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        if (content.size > 104857600) {
          this.showNotification("File too big!");
        } else {
          this.setState({
            fileName: "download.zip",
            fileBase64: content,
            documentType: "general",
            documentName: "ProofOfTM",
          });
        }
      });
      $("#ProofOfTMModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadProofOfTM = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_ProofOfTM").attr("disabled", true);
      $("#upload_btn20_ProofOfTM").hide();
      $("#upload_btnloader_ProofOfTM").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  ProofOfTMDocLink: res.data.data.ProofOfTM,
                },
                () => $("#viewDocLink_ProofOfTM").css("display", "block")
              );
              ProofOfTMStatus = "remove";
              brandNameDoc[0].status = ProofOfTMStatus;
              this.checkhandleProceedBtn();
              $("#ProofOfTMModal").modal("hide");
              $("#cancelbtn_ProofOfTM").attr("disabled", false);
              $("#upload_btn20_ProofOfTM").show();
              $("#upload_btnloader_ProofOfTM").hide();
              $("#choose_ProofOfTM").hide();
              $("#X-btn_ProofOfTM").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_ProofOfTM").attr("disabled", false);
            $("#upload_btn20_ProofOfTM").show();
            $("#upload_btnloader_ProofOfTM").hide();
            $("#ProofOfTMModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_ProofOfTM").attr("disabled", false);
            $("#upload_btn20_ProofOfTM").show();
            $("#upload_btnloader_ProofOfTM").hide();
            $("#ProofOfTMModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_ProofOfTM").attr("disabled", false);
          $("#upload_btn20_ProofOfTM").show();
          $("#upload_btnloader_ProofOfTM").hide();
          $("#ProofOfTMModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeProofOfTM = () => {
    $("#X-btn_ProofOfTM").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: "",
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "ProofOfTM",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          ProofOfTMStatus = res.data.data.status;
          brandNameDoc[0].status = ProofOfTMStatus;
          this.checkhandleProceedBtn();
          $("#choose_ProofOfTM").show();
          $("#X-btn_ProofOfTM").hide();
          $("#X-btn_ProofOfTM").attr("disabled", false);
          $("#viewDocLink_ProofOfTM").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_ProofOfTM").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_ProofOfTM").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleProofOfTMseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#ProofOfTMseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ProofOfTM",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#ProofOfTMseecommentsbtn").hide();
          $("#ProofOfTMhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleProofOfTMhidecommentsbtn = () => {
    $("#ProofOfTMseecommentsbtn").show();
    $("#ProofOfTMseecommentsbtn").attr("disabled", false);
    $("#ProofOfTMhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // Additional
  handleAddFileChange = (e, index, status) => {
    additionalReuploadIndex = index;
    additionalReuploadStatus = status;
    $(".upload_btn20_7").show();
    $(".upload_btnloader_7").hide();
    const file = e.target.files[0];
    if (!additionalFilesNames.includes(this.state.Add_DocName.trim())) {
      const myfile = e.target.files;
      if (ChooseNowBtnsDisable == "Yes") {
        this.showNotification(
          "Files cannot be uploaded when the service is locked!"
        );
      } else if (myfile.length == 1) {
        const file = myfile[0];
        if (file.type.match("audio.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.type.match("video.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.size > 104857600) {
          this.showNotification("File too big!");
        } else if (file.name.length > 100) {
          this.showNotification("File name limit exceed!");
        } else {
          this.setState(
            {
              fileName: file.name,
              fileBase64: file,
              documentType: "additional",
              documentName: this.state.Add_DocName,
            },
            () => {
              $(".AddModal").modal({ backdrop: "static", keyboard: false });
            }
          );
        }
      } else if (myfile.length > 1) {
        var zip = new JSZip();
        for (let i = 0; i < myfile.length; i++) {
          zip.file(myfile[i].name, myfile[i], { base64: true });
        }
        zip.generateAsync({ type: "blob" }).then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "additional",
              documentName: this.state.Add_DocName,
            });
          }
        });
        $(".AddModal").modal({ backdrop: "static", keyboard: false });
      } else {
        this.showNotification("File cannot be null!");
      }
    } else {
      this.showNotification(
        "Document names can not be the same, Try again with different name!"
      );
    }
  };

  uploadFile = (FileIndex, FileStatus) => {
    let AdditionaldocumentName1;
    if (FileStatus == "reupload") {
      $(".proceednowbtn").attr("disabled", true);
      let fileTobeUploaded = this.state.additionalFiles[FileIndex];
      AdditionaldocumentName1 = Object.keys(fileTobeUploaded)[0];
      mainaction = "reupload";
    } else {
      AdditionaldocumentName1 = this.state.documentName;
      mainaction = "upload";
    }
    if (AdditionaldocumentName1.trim() == "") {
      $(".AddModal").modal("hide");
      this.showNotification("Enter document Name first");
    } else if (this.state.comment.length <= 250) {
      $(".cancelbtn_7").attr("disabled", true);
      $(".upload_btn20_7").hide();
      $(".upload_btnloader_7").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", AdditionaldocumentName1);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            $(".AddModal").modal("hide");
            $(".cancelbtn_7").attr("disabled", false);
            $(`#${FileIndex}Fileseecommentsbtn`).attr("disabled", false);
            if (additionalReupload == false) {
              additionalFilesNames.push(this.state.Add_DocName);
              this.state.additionalFiles.pop();
              this.setState(
                {
                  comment: "",
                  additionalFiles: [
                    ...this.state.additionalFiles,
                    res.data.data,
                  ],
                },
                () => {
                  this.setState(
                    {
                      api_additionalFiles: "Yes",
                    },
                    () => {
                      this.additionalFilesArea();
                      this.checkhandleProceedBtn();
                    }
                  );

                  $(".additionalInput").attr("disabled", true);
                }
              );
            } else if (additionalReupload == true) {
              $(".proceednowbtn").attr("disabled", false);
              var array = [...this.state.additionalFiles];
              array.splice(FileIndex, 1, res.data.data);
              this.setState(
                {
                  comment: "",
                  api_additionalFiles: "Yes",
                  additionalFiles: array,
                },
                () => {
                  this.additionalFilesArea();
                  this.checkhandleProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".AddModal").modal("hide");
            this.showNotification("Something Went Wrong!");
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".AddModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          this.showNotification("Something went wrong, Try again!");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeFile = (index) => {
    $("#X-btn_7").attr("disabled", true);
    let fileTobeDeleted = this.state.additionalFiles[index];
    let NameOfAddFile = Object.keys(fileTobeDeleted)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: "",
          FY: "",
          Month: "",
          documentType: "additional",
          documentName: NameOfAddFile,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          var array = [...this.state.additionalFiles];
          if (res.data.data.status == "reupload") {
            array[index].status = "reupload";
            this.setState(
              {
                additionalFiles: array,
              },
              () => {
                this.additionalFilesArea();
                this.checkhandleProceedBtn();
              }
            );
            this.showNotification(res.data.message);
          } else {
            array.splice(index, 1);
            if (array.length > 0) {
              this.setState(
                {
                  additionalFiles: array,
                },
                () => {
                  this.additionalFilesArea();
                  this.checkhandleProceedBtn();
                }
              );
            } else if (array.length == 0) {
              this.setState(
                {
                  Add_DocName: "",
                  api_additionalFiles: "No",
                  IsAddFileDisabled: true,
                },
                () => {
                  this.additionalFilesArea();
                  this.checkhandleProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_7").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleFileseeComments = (index) => {
    $(".commentsbtn").attr("disabled", false);
    $(`#${index}Fileseecommentsbtn`).attr("disabled", true);
    let SelectedFile = this.state.additionalFiles[index];
    let NameOfAddFile = Object.keys(SelectedFile)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "additional",
          documentName: NameOfAddFile,
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(`#${index}Fileseecommentsbtn`).hide();
          $(".hidecommentsbtn").hide();
          $(`#${index}Filehidecommentsbtn`).show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleFilehideComments = (index) => {
    $(".seecommentsbtn").show();
    $(`#${index}Fileseecommentsbtn`).show();
    $(`#${index}Fileseecommentsbtn`).attr("disabled", false);
    $(`#${index}Filehidecommentsbtn`).hide();
    $(".hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  checkAddCommentFiles = (FileIndex, isDisabled) => {
    if (isDisabled == true) {
      return (
        <>
          <button
            className="seecommentsbtn Fileseecommentsbtn"
            id={`${FileIndex}Fileseecommentsbtn`}
            disabled
            onClick={() => this.handleFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn Filehidecommentsbtn"
            id={`${FileIndex}Filehidecommentsbtn`}
            onClick={() => this.handleFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    } else if (isDisabled == false) {
      return (
        <>
          <button
            className="seecommentsbtn commentsbtn Fileseecommentsbtn"
            id={`${FileIndex}Fileseecommentsbtn`}
            onClick={() => this.handleFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn Filehidecommentsbtn"
            id={`${FileIndex}Filehidecommentsbtn`}
            onClick={() => this.handleFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    }
  };

  checkStatusAddFiles = (status, link, index) => {
    additionalFilesIndex = index;
    if (ChooseNowBtnsDisable == "Yes") {
      $(".proceednowbtn").hide();
      if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">X</button>
          </>
        );
      }
    } else {
      if (status == undefined) {
        status = "open";
      }
      if (status == "open") {
        mainaction = "upload";
        additionalReupload = true;
        $(".proceednowbtn").show();
        return (
          <>
            <label
              htmlFor={`ul_file_Add${index}`}
              className="choose_btn choose_7"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              multiple
              name="file[]"
              className="upload-photo"
              id={`ul_file_Add${index}`}
              // disabled={this.state.IsAddFileDisabled}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handleAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "remove") {
        mainaction = "upload";
        $(".proceednowbtn").show();
        return (
          <>
            <button
              className="X-btn X-btn_7"
              onClick={() => this.removeFile(index)}
            >
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_1025.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "reupload") {
        additionalReupload = true;
        mainaction = "reupload";
        $(".proceednowbtn").show();
        $(".proceednowbtn").attr("disabled", true);
        return (
          <>
            <label
              htmlFor={`ul_file_Add${index}`}
              className="choose_btn choose_7"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              multiple
              name="file[]"
              className="upload-photo"
              id={`ul_file_Add${index}`}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handleAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">X</button>
          </>
        );
      }
    }
  };

  AdditionalFileDisabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero additionalInput"
        value={NameOfAddFile}
        disabled
        onChange={(e) => this.handleAdd_DocNameChange(e)}
      />
    );
  };

  AdditionalFileEnabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero additionalInput"
        value={NameOfAddFile}
        onChange={(e) => this.handleAdd_DocNameChange(e)}
      />
    );
  };

  additionalFilesArea = () => {
    additionalFilesNames = [];
    if (this.state.api_additionalFiles == "Yes") {
      return this.state.additionalFiles.map((file, index) => {
        let NameOfAddFile = Object.keys(file)[0];
        additionalFilesNames.push(NameOfAddFile);
        let link = file[Object.keys(file)[0]];
        let AdditionalFileInputField;
        let commentDisabled;
        if (file.status == "open" || file.status == undefined) {
          AdditionalFileInputField =
            this.AdditionalFileEnabledField(NameOfAddFile);
          commentDisabled = true;
        } else if (file.status != "open") {
          AdditionalFileInputField =
            this.AdditionalFileDisabledField(NameOfAddFile);
          commentDisabled = false;
        }
        return (
          <div className="displayflex" key={index}>
            <div className="uploadnocol">
              <p className="uploadadditionalsidetext">{index + 1}&#41;</p>
            </div>
            <div className="uploadadditionaldatacol">
              <div className="row mb-2">
                <div className="col-md-5">{AdditionalFileInputField}</div>
                <div className="col-md-7">
                  <div className="float-md-right float-left">
                    {this.checkAddCommentFiles(index, commentDisabled)}
                    {this.checkStatusAddFiles(file.status, link, index)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.api_additionalFiles == "No") {
      return (
        <div className="displayflex">
          <div className="uploadnocol">
            <p className="uploadadditionalsidetext">1&#41;</p>
          </div>
          <div className="uploadadditionaldatacol">
            <div className="row mb-2">
              <div className="col-md-5">
                <input
                  type="text"
                  maxLength="150"
                  placeholder="Document name..."
                  className="uploadtextfield marginzero"
                  value={this.state.Add_DocName}
                  onChange={(e) => this.handleAdd_DocNameChange(e)}
                />
              </div>
              <div className="col-md-7">
                <div className="fl_right">
                  {this.checkAddCommentFiles(0, true)}
                  {this.checkStatusAddFiles("open", null, 0)}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  handleAdd_DocNameChange = (e) => {
    this.setState(
      {
        Add_DocName: e.target.value,
      },
      () => {
        if (this.state.Add_DocName.trim().length > 0) {
          this.setState({
            IsAddFileDisabled: false,
          });
        } else {
          this.setState({
            IsAddFileDisabled: true,
          });
        }
      }
    );
  };

  handleAddFileBtn = (FileIndex) => {
    const { Add_DocName, additionalFiles } = this.state;

    let prevFile = additionalFiles[FileIndex];
    let NameOfPrevFile = Object.keys(prevFile)[0];
    let valueOffile = prevFile[Object.keys(prevFile)[0]];

    if (FileIndex == undefined) {
      this.showNotification("empty fields");
    } else if (Add_DocName.trim() == "") {
      this.showNotification("Required fields cannot be empty!");
    } else if (valueOffile == null) {
      this.showNotification("Choose a file");
    } else {
      this.setState(
        {
          additionalFiles: [...this.state.additionalFiles, ""],
          Add_DocName: "",
          IsAddFileDisabled: true,
        },
        () => {
          $(".additionalInput").attr("disabled", false);
        }
      );
    }
  };

  // checkProceed
  checkRemainingReuploads = () => {
    if (
      PANCardStatus != "reupload" &&
      AddressProofStatus != "reupload" &&
      MsmeStartupCertificateStatus != "reupload" &&
      ProofOfTMStatus != "reupload" &&
      BrandLogoStatus != "reupload" &&
      mainstatusVar == "false"
    ) {
      AnyReuploadOccured = "No";
    } else {
      $(".proceednowbtn").show();
      AnyReuploadOccured = "Yes";
    }
  };

  checkhandleProceedBtn = () => {
    let statusCheck = this.statusCheckFunction("reupload", mainDocs);
    let msmeDocCheck = this.statusCheckFunction("reupload", msmeDoc);
    additionalStatusArrayCheck = this.statusCheckFunction(
      "reupload",
      this.state.additionalFiles
    );
    let goodtogo_brandName = "No";
    let goodtogo_trademark = "No";

    if (this.state.brandName_radio == "Yes") {
      if (
        brandNameDoc[0].status != "reupload" &&
        brandNameDoc[0].status != "upload" &&
        brandNameDoc[0].status != "open"
      ) {
        goodtogo_brandName = "Yes";
      } else {
        goodtogo_brandName = "No";
      }
    } else {
      goodtogo_brandName = "Yes";
    }

    if (this.state.trademark_radio == "Logo") {
      if (
        brandLogoDoc[0].status != "reupload" &&
        brandLogoDoc[0].status != "upload" &&
        brandLogoDoc[0].status != "open"
      ) {
        goodtogo_trademark = "Yes";
      } else {
        goodtogo_trademark = "No";
      }
    } else {
      goodtogo_trademark = "Yes";
    }

    if (this.state.serviceType == "MS") {
      if (
        AnyReuploadOccured == "Yes" &&
        statusCheck == undefined &&
        goodtogo_brandName == "Yes" &&
        goodtogo_trademark == "Yes" &&
        msmeDocCheck == undefined &&
        additionalStatusArrayCheck == undefined
      ) {
        $(".proceednowbtn").attr("disabled", false);
        proceednowforword = "success";
      } else if (
        PANCardStatus == "remove" &&
        AddressProofStatus == "remove" &&
        MsmeStartupCertificateStatus == "remove" &&
        goodtogo_brandName == "Yes" &&
        goodtogo_trademark == "Yes"
      ) {
        $(".proceednowbtn").attr("disabled", false);
        proceednowforword = "success";
      } else {
        $(".proceednowbtn").attr("disabled", true);
        proceednowforword = "fail";
      }
    } else {
      if (
        AnyReuploadOccured == "Yes" &&
        statusCheck == undefined &&
        goodtogo_brandName == "Yes" &&
        goodtogo_trademark == "Yes" &&
        // MSMEReupload == "No" &&
        additionalStatusArrayCheck == undefined
      ) {
        $(".proceednowbtn").attr("disabled", false);
        proceednowforword = "success";
      } else if (
        PANCardStatus == "remove" &&
        AddressProofStatus == "remove" &&
        goodtogo_brandName == "Yes" &&
        goodtogo_trademark == "Yes"
      ) {
        $(".proceednowbtn").attr("disabled", false);
        proceednowforword = "success";
      } else {
        $(".proceednowbtn").attr("disabled", true);
        proceednowforword = "fail";
      }
    }
  };

  handleProceedBtn = () => {
    this.checkhandleProceedBtn();
    let inputFields;
    inputFields = [
      {
        inputFieldName: "Nature",
        inputFieldValue: this.state.businessNature,
        inputFieldtype: "general",
      },
      {
        inputFieldName: "PreviousTM",
        inputFieldValue: this.state.brandName_radio,
        inputFieldtype: "general",
      },
      {
        inputFieldName: "WantToTrademark",
        inputFieldValue: this.state.trademark_radio,
        inputFieldtype: "general",
      },
      {
        inputFieldName: "BrandName",
        inputFieldValue: this.state.trademarkWord,
        inputFieldtype: "general",
      },
    ];
    if (this.state.brandName_radio == "Yes" && this.state.Date == "") {
      this.showNotification("Required fields can not be empty!");
    } else if (
      this.state.businessNature.trim() != "" &&
      this.state.trademarkWord.trim() != "" &&
      proceednowforword == "success"
    ) {
      $(".proceednowbtn").attr("disabled", true);

      if (this.state.brandName_radio == "Yes") {
        inputFields.push({
          inputFieldName: "TMUseDate",
          inputFieldValue: this.state.Date,
          inputFieldtype: "general",
        });
      }

      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=lockService",
          {
            userId: Decuserid,
            requestType: temp,
            agentId: Decagentid,

            serviceId: Decserviceid,
            serviceName: Decservicename,
            serviceType: this.state.serviceType,
            FY: "",
            Month: "",
            inputFields: inputFields,
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.showNotification(res.data.message);
            this.getserviceInformationApi();
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else {
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {});
    } else {
      this.showNotification("Required fields can not be empty!");
    }
  };
  backbutton = () => {
    this.props.history.push({
      pathname: `/myuser/${Decuserid}/userservices`,
    });
  };

  

  
  handletimeline = () => {
    
   
    $("#timeline_btnloader").show();
    axios.post(
      process.env.REACT_APP_API_BASE_URL + "p=timeLine",
      {
        agentId: Decagentid,
        userId: Decuserid,
        serviceId: Decserviceid, 
      },
      {
        headers: {
          Authkey: process.env.REACT_APP_API_KEY,
          "App-Token-Access": Decjwttoken,
        },
        auth: {
          username: process.env.REACT_APP_API_USERNAME,
          password: process.env.REACT_APP_API_PASSWORD,
        },
      }
    )
    .then((res) => {
      if (res.data.code == "200" && res.data.status == "success") {
        
        if (res.data.timeLine.length > 0) {
          let length = res.data.timeLine.length;
          let ftimeline =  res.data.timeLine.filter((items,index) => {
            return (index <= this.state.timelcount )
          })
          this.setState({ 
            filtertimel: ftimeline,
            timelcount: this.state.timelcount + 3,
            Timeline: res.data.timeLine,
            Timelinedata: true,
          });
          this.setState({ 
            
          });
          // .splice(this.state.timelcount,length)
        }
      } else if (res.data.code == "201" && res.data.status == "invalidauth") {
        this.logout();
      } else { 
          this.showNotification(res.data.message);
      } 
    })
  }

  handlemoretimeline = () => {
 
    let ftimeline =  this.state.Timeline.filter((items,index) => {
      return (index <= this.state.timelcount )
    })
    this.setState({ 
      filtertimel: ftimeline,
      timelcount: this.state.timelcount + 3
    });
   
  }

  completerender() {
    if (this.state.deliveredDocuments.length > 0) {
      return this.state.deliveredDocuments.map((activedoc, index) => {
        return (
          <div className="displayflex documentmaincard" key={index}>
            <div>
              <img
                src={process.env.PUBLIC_URL + "/img/Path 247.svg"}
                className="docutypeimg"
              />
            </div>
            <div className="docucontdiv">
              <div className="row">
                <div className="col-md-6">
                  <p className="nameofdoc">{activedoc.documentName}</p>
                  <p className="datesenttext">
                    Date received:-{" "}
                    <span className="datesentspan">{activedoc.datesent}</span>
                  </p>
                </div>
                <div className="col-md-6">
                  <div className="fl_right">
                    <div className="displayflex">
                      <div className="viewbtndivpadding">
                        <a
                          href={activedoc.documentLink}
                          target="_blank"
                          className="viewbtn"
                        >
                          View{" "}
                          <img
                            src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                          />
                        </a>
                      </div>
                      <div>
                        <a
                          href={activedoc.documentLink}
                          className="download_btn"
                          target="_blank"
                          download
                        >
                          Download{" "}
                          <img
                            src={
                              process.env.PUBLIC_URL + "/img/download_icon.svg"
                            }
                            alt="download"
                          />
                        </a>
                      </div>
                    </div>
                    <p className="docushareviatext">
                      Share via:{" "}
                      <a
                        onClick={() =>
                          this.whatsapp(
                            activedoc.documentName,
                            activedoc.documentLink
                          )
                        }
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/whatsapp.svg"}
                          className="docushareviawhats"
                        />
                      </a>{" "}
                      <a
                        onClick={() =>
                          this.email(
                            activedoc.documentName,
                            activedoc.documentLink
                          )
                        }
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/mail.svg"}
                          className="docushareviamail"
                        />
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.deliveredDocuments.length == 0) {
      return (
        <center className="myservicequiteimgcenter">
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
            className="emptydeliveredDocuments"
          />
        </center>
      );
    }
  }




  render() {
    if (
      localStorage.getItem("gglltakoinoeenl") ==
      process.env.REACT_APP_LOGIN_KEY &&
      localStorage.getItem("toeljgtkewlna") != null &&
      localStorage.getItem("qazxswedcvfrtgb") != null
    ) {
      return (
        <div className="cont">
          <div id="notifContainer"></div>

          <Helmet>
            <link
              rel="stylesheet"
              href={process.env.PUBLIC_URL + "/css/select2.css"}
            />
          </Helmet>

          <Header />

          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
            className="sidebaropenicon"
          />
          <img
            onClick={this.backbutton}
            src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
            className="sidebarbackicon"
          />
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"}
            className="sidebarexpandicon"
          />

          <div className="overlay1"></div>
          <div className="sidebar sidebarshift">
            <div className="side_div_2 sidebar_txt active">
              <Link to="/dashboard">Overview</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <Link to="/myservices">My Services</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <a href="#">Payments Invoices</a>
            </div>
            <div className="side_div_1 sidebar_txt">
              <a href="#">Documents Record</a>
            </div>
            <div className="side_div_1 sidebar_txt">
              <a href="#">Add service</a>
            </div>
            <div></div>
          </div>
          <div className="arrow-left2 visibilityhidden"></div>
          <div className="white_div expandwhite_div">
            <div className="serviceoverlay">
              <div className="serviceoverlayinside">
                <center>
                  <img
                    src={process.env.PUBLIC_URL + "/img/loader.gif"}
                    className="serviceloader"
                  />
                </center>
              </div>
            </div>
            <div className="white_box">
            {this.state.referralPartner ? 
              <div className="uploaddocu">
                <div className="row">
                  {/* <div className="myservicesortbyfilterbystatus mt-1 ml-1"></div> */}
                 
                  </div>
                  <div className="tabs-content">
                <div id="tab1" className="tab-content">
                <div className="row">
                {/* <div className="myservicesortbyfilterbystatus mt-1 ml-1">
                  
                  </div> */}

<div className="col-lg-9 ">
                  <div className="row">
                      <div className="col-md-6">
                        <div className="inner-header d-flex justify-content-between">
                          <div className="slider-navigation-tab">
                            <header className="tabs-nav position-relative">
                              <ul className="d-flex slider-navigation-tab-ul">
                                <li
                                  className="active slider-navigation-tab-li"
                                  id="activeTab"
                                >
                                  <a href="#tab1" className="px-2 py-1">
                                    Information given
                                  </a>
                                </li>
                                <li
                                  className="slider-navigation-tab-li"
                                  id="completedTab"
                                >
                                  <a href="#tab2" className="px-2 py-1">
                                    Deliverables
                                  </a>
                                </li>
                                <hr className="slider-navigation-tab-vertical-line" />
                                <div className="sliderpage-slider"></div>
                              </ul>
                            </header>
                          </div>
                        </div>
                      </div>
                     
                    </div>
                    {/* <p className="uploadtext">Upload Information :-</p> */}
                    <div className="uploadscrolldiv">
                      <div className="uploadscrollcardinside">
                        {/* PANCard */}
                        <div className="displayflex">
                          <div className="uploadnocol">
                            <p className="uploadnormaltext documentno"></p>
                          </div>
                          <div className="uploaddatacol">
                            <div className="row">
                              <div className="col-md-5">
                                <p className="uploadnormaltext">
                                  PAN Card
                                  <sup className="requireddocstar">*</sup>
                                </p>
                                <p className="uploadinstr">Soft copy</p>
                              </div>
                              <div className="col-md-7">
                                <div className="fl_right">
                                  <a
                                    href="https://docs.legal251.com/example/documents/PANCard.pdf"
                                    className="viewexamplelink"
                                    target="_blank"
                                  >
                                    View example document{" "}
                                    <img
                                      className="viewDocLinkimg"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path_20245.svg"
                                      }
                                    />
                                  </a>
                                  <button
                                    className="seecommentsbtn commentsbtn"
                                    id="PANCardseecommentsbtn"
                                    onClick={this.handlePANCardseecommentsbtn}
                                  >
                                    See comments{" "}
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path 19498.svg"
                                      }
                                    />
                                  </button>
                                  <button
                                    className="hidecommentsbtn"
                                    id="PANCardhidecommentsbtn"
                                    onClick={this.handlePANCardhidecommentsbtn}
                                  >
                                    Hide comments{" "}
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path 19498.svg"
                                      }
                                    />
                                  </button>
                                  <label
                                    htmlFor="ul_file_1"
                                    className="choose_btn"
                                    id="choose_PANCard"
                                  >
                                    Choose file{" "}
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path_15188.svg"
                                      }
                                      alt="file"
                                    />
                                  </label>
                                  <input
                                    type="file"
                                    multiple
                                    name="file[]"
                                    className="upload-photo"
                                    id="ul_file_1"
                                    onClick={(event) => {
                                      event.target.value = null;
                                    }}
                                    onChange={(e) =>
                                      this.handlePANCardChange(e)
                                    }
                                  />
                                  <button
                                    className="X-btn"
                                    id="X-btn_PANCard"
                                    onClick={(e) => this.removePANCard(e)}
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path_1025.svg"
                                      }
                                    />
                                  </button>
                                  <button
                                    className="review-btn"
                                    id="review-btn_PANCard"
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path_19663.svg"
                                      }
                                    />
                                  </button>
                                  <button
                                    className="done-btn"
                                    id="done-btn_PANCard"
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL + "/img/tick.svg"
                                      }
                                    />
                                  </button>
                                  <br />
                                  <a
                                    href={this.state.PANCardDocLink}
                                    className="viewDocLink"
                                    id="viewDocLink_PANCard"
                                    target="_blank"
                                  >
                                    View uploaded document{" "}
                                    <img
                                      className="viewDocLinkimg"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path_20245.svg"
                                      }
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* AddressProof */}
                        <div className="displayflex">
                          <div className="uploadnocol">
                            <p className="uploadnormaltext documentno"></p>
                          </div>
                          <div className="uploaddatacol">
                            <div className="row">
                              <div className="col-md-5">
                                <p className="uploadnormaltext">
                                  Aadhaar Card / Driving Licence / Voter ID
                                  <sup className="requireddocstar">*</sup>
                                </p>
                                <p className="uploadinstr">(Any one)</p>
                              </div>
                              <div className="col-md-7">
                                <div className="fl_right">
                                  <a
                                    href="https://docs.legal251.com/example/documents/IDProof.pdf"
                                    className="viewexamplelink"
                                    target="_blank"
                                  >
                                    View example document{" "}
                                    <img
                                      className="viewDocLinkimg"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path_20245.svg"
                                      }
                                    />
                                  </a>
                                  <button
                                    className="seecommentsbtn commentsbtn"
                                    id="AddressProofseecommentsbtn"
                                    onClick={
                                      this.handleAddressProofseecommentsbtn
                                    }
                                  >
                                    See comments{" "}
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path 19498.svg"
                                      }
                                    />
                                  </button>
                                  <button
                                    className="hidecommentsbtn"
                                    id="AddressProofhidecommentsbtn"
                                    onClick={
                                      this.handleAddressProofhidecommentsbtn
                                    }
                                  >
                                    Hide comments{" "}
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path 19498.svg"
                                      }
                                    />
                                  </button>
                                  <label
                                    htmlFor="ul_file_2"
                                    className="choose_btn"
                                    id="choose_AddressProof"
                                  >
                                    Choose file{" "}
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path_15188.svg"
                                      }
                                      alt="file"
                                    />
                                  </label>
                                  <input
                                    type="file"
                                    multiple
                                    name="file[]"
                                    className="upload-photo"
                                    id="ul_file_2"
                                    onClick={(event) => {
                                      event.target.value = null;
                                    }}
                                    onChange={(e) =>
                                      this.handleAddressProofChange(e)
                                    }
                                  />
                                  <button
                                    className="X-btn"
                                    id="X-btn_AddressProof"
                                    onClick={(e) => this.removeAddressProof(e)}
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path_1025.svg"
                                      }
                                    />
                                  </button>
                                  <button
                                    className="review-btn"
                                    id="review-btn_AddressProof"
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path_19663.svg"
                                      }
                                    />
                                  </button>
                                  <button
                                    className="done-btn"
                                    id="done-btn_AddressProof"
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL + "/img/tick.svg"
                                      }
                                    />
                                  </button>
                                  <br />
                                  <a
                                    href={this.state.AddressProofDocLink}
                                    className="viewDocLink"
                                    id="viewDocLink_AddressProof"
                                    target="_blank"
                                  >
                                    View uploaded document{" "}
                                    <img
                                      className="viewDocLinkimg"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path_20245.svg"
                                      }
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Type of application */}
                        <div className="displayflex">
                          <div className="uploadnocol ">
                            <p className="uploadspecialnormaltext documentno"></p>
                          </div>
                          <div className="uploadspecialdatacol">
                            <div className="displayflex flex-md-row flex-column">
                              <div>
                                <p className="uploadnormaltext">
                                  Type of application:-
                                </p>
                              </div>
                              <div>
                                <input
                                  type="text"
                                  className="lockeduploadtextfiels"
                                  value={this.state.serviceTypeText}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* MsmeStartupCertificate */}
                        <div className="displayflex">
                          <div className="uploadnocol">
                            <p className="uploadnormaltext documentno"></p>
                          </div>
                          <div className="uploaddatacol">
                            <div className="row">
                              <div className="col-md-5">
                                <p className="uploadnormaltext">
                                  MSME / Startup recognition certificate
                                  {this.IsMSMERequired()}
                                </p>
                                <p className="uploadinstr">
                                  Soft copy if applicable
                                </p>
                              </div>
                              <div className="col-md-7">
                                <div className="fl_right">
                                  <a
                                    href="https://docs.legal251.com/example/documents/MsmeStartupCertificate.pdf"
                                    className="viewexamplelink"
                                    target="_blank"
                                  >
                                    View example document{" "}
                                    <img
                                      className="viewDocLinkimg"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path_20245.svg"
                                      }
                                    />
                                  </a>
                                  <button
                                    className="seecommentsbtn commentsbtn"
                                    id="MsmeStartupCertificateseecommentsbtn"
                                    onClick={
                                      this
                                        .handleMsmeStartupCertificateseecommentsbtn
                                    }
                                  >
                                    See comments{" "}
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path 19498.svg"
                                      }
                                    />
                                  </button>
                                  <button
                                    className="hidecommentsbtn"
                                    id="MsmeStartupCertificatehidecommentsbtn"
                                    onClick={
                                      this
                                        .handleMsmeStartupCertificatehidecommentsbtn
                                    }
                                  >
                                    Hide comments{" "}
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path 19498.svg"
                                      }
                                    />
                                  </button>
                                  <label
                                    htmlFor="ul_file_3"
                                    className="choose_btn"
                                    id="choose_MsmeStartupCertificate"
                                  >
                                    Choose file{" "}
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path_15188.svg"
                                      }
                                      alt="file"
                                    />
                                  </label>
                                  <input
                                    type="file"
                                    multiple
                                    name="file[]"
                                    className="upload-photo"
                                    id="ul_file_3"
                                    onClick={(event) => {
                                      event.target.value = null;
                                    }}
                                    onChange={(e) =>
                                      this.handleMsmeStartupCertificateChange(e)
                                    }
                                  />
                                  <button
                                    className="X-btn"
                                    id="X-btn_MsmeStartupCertificate"
                                    onClick={(e) =>
                                      this.removeMsmeStartupCertificate(e)
                                    }
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path_1025.svg"
                                      }
                                    />
                                  </button>
                                  <button
                                    className="review-btn"
                                    id="review-btn_MsmeStartupCertificate"
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path_19663.svg"
                                      }
                                    />
                                  </button>
                                  <button
                                    className="done-btn"
                                    id="done-btn_MsmeStartupCertificate"
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL + "/img/tick.svg"
                                      }
                                    />
                                  </button>
                                  <br />
                                  <a
                                    href={
                                      this.state.MsmeStartupCertificateDocLink
                                    }
                                    className="viewDocLink"
                                    id="viewDocLink_MsmeStartupCertificate"
                                    target="_blank"
                                  >
                                    View uploaded document{" "}
                                    <img
                                      className="viewDocLinkimg"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path_20245.svg"
                                      }
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Nature */}
                        <div className="displayflex">
                          <div className="uploadnocol">
                            <p className="uploadnormaltext documentno"></p>
                          </div>
                          <div className="uploaddatacol">
                            <p className="uploadnormaltext">
                              Nature of your Business / Brand:-
                              <sup className="requireddocstar">*</sup>
                            </p>
                            <textarea
                              className="uploadtextarea"
                              placeholder="Ex:- Kirana Shop / Shoes Brand / Hardware company..."
                              value={this.state.businessNature}
                              onChange={this.handlebusinessNatureChange}
                            ></textarea>
                          </div>
                        </div>

                        {/* BrandName */}
                        <div className="displayflex">
                          <div className="uploadnocol">
                            <p className="uploadnormaltext documentno"></p>
                          </div>
                          <div className="uploaddatacol">
                            <p className="uploadnormaltext">
                              Are you already using your Brand Name / Logo?
                              <sup className="requireddocstar">*</sup>
                            </p>

                            <input
                              type="radio"
                              id="test2"
                              name="radio-group"
                              value="Yes"
                              checked={this.state.brandName_radio === "Yes"}
                              onChange={this.brandName_radioChange}
                            />
                            <label htmlFor="test2" className="uploadradiotext">
                              YES
                            </label>
                            <br />
                            <input
                              type="radio"
                              id="test3"
                              name="radio-group"
                              value="No"
                              checked={this.state.brandName_radio === "No"}
                              onChange={this.brandName_radioChange}
                            />
                            <label htmlFor="test3" className="uploadradiotext">
                              NO
                            </label>
                          </div>
                        </div>

                        {/* TMUseDate */}
                        <div className="displayflex brandName-yes">
                          <div className="uploadnocol">
                            <p className="uploadnormaltext documentno"></p>
                          </div>
                          <div className="uploaddatacol">
                            <p className="uploadnormaltext">
                              Date from which you're using your Brand Name/Logo?
                              <sup className="requireddocstar">*</sup>
                            </p>
                            <input
                              type="date"
                              placeholder="Type here..."
                              className="uploaddatefield"
                              value={this.state.Date}
                              onChange={this.handleDateChange}
                            />
                          </div>
                        </div>

                        {/* ProofOfTM */}
                        <div className="displayflex brandName-yes">
                          <div className="uploadnocol">
                            <p className="uploadnormaltext documentno"></p>
                          </div>
                          <div className="uploaddatacol">
                            <div className="row">
                              <div className="col-md-5">
                                <p className="uploadnormaltext">
                                  Proof of trademark use:-
                                  <sup className="requireddocstar">*</sup>
                                </p>
                                <p className="uploadinstr">
                                  Ex: Letterhead/Invoice/Any other proof
                                </p>
                              </div>
                              <div className="col-md-7">
                                <div className="fl_right">
                                  <a
                                    href="https://docs.legal251.com/example/documents/ProofOfTM.pdf"
                                    className="viewexamplelink"
                                    target="_blank"
                                  >
                                    View example document{" "}
                                    <img
                                      className="viewDocLinkimg"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path_20245.svg"
                                      }
                                    />
                                  </a>
                                  <button
                                    className="seecommentsbtn commentsbtn"
                                    id="ProofOfTMseecommentsbtn"
                                    onClick={this.handleProofOfTMseecommentsbtn}
                                  >
                                    See comments{" "}
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path 19498.svg"
                                      }
                                    />
                                  </button>
                                  <button
                                    className="hidecommentsbtn"
                                    id="ProofOfTMhidecommentsbtn"
                                    onClick={
                                      this.handleProofOfTMhidecommentsbtn
                                    }
                                  >
                                    Hide comments{" "}
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path 19498.svg"
                                      }
                                    />
                                  </button>
                                  <label
                                    htmlFor="ul_file_4"
                                    className="choose_btn"
                                    id="choose_ProofOfTM"
                                  >
                                    Choose file{" "}
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path_15188.svg"
                                      }
                                      alt="file"
                                    />
                                  </label>
                                  <input
                                    type="file"
                                    multiple
                                    name="file[]"
                                    className="upload-photo"
                                    id="ul_file_4"
                                    onClick={(event) => {
                                      event.target.value = null;
                                    }}
                                    onChange={(e) =>
                                      this.handleProofOfTMChange(e)
                                    }
                                  />
                                  <button
                                    className="X-btn"
                                    id="X-btn_ProofOfTM"
                                    onClick={(e) => this.removeProofOfTM(e)}
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path_1025.svg"
                                      }
                                    />
                                  </button>
                                  <button
                                    className="review-btn"
                                    id="review-btn_ProofOfTM"
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path_19663.svg"
                                      }
                                    />
                                  </button>
                                  <button
                                    className="done-btn"
                                    id="done-btn_ProofOfTM"
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL + "/img/tick.svg"
                                      }
                                    />
                                  </button>
                                  <br />
                                  <a
                                    href={this.state.ProofOfTMDocLink}
                                    className="viewDocLink"
                                    id="viewDocLink_ProofOfTM"
                                    target="_blank"
                                  >
                                    View uploaded document{" "}
                                    <img
                                      className="viewDocLinkimg"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path_20245.svg"
                                      }
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* trademark */}
                        <div className="displayflex">
                          <div className="uploadnocol">
                            <p className="uploadnormaltext documentno"></p>
                          </div>
                          <div className="uploaddatacol">
                            <p className="uploadnormaltext">
                              What do you want to trademark?
                              <sup className="requireddocstar">*</sup>
                            </p>

                            <input
                              type="radio"
                              id="test4"
                              name="radio-group2"
                              value="Word"
                              checked={this.state.trademark_radio === "Word"}
                              onChange={this.trademark_radioChange}
                            />
                            <label htmlFor="test4" className="uploadradiotext">
                              Word
                            </label>
                            <br />
                            <input
                              type="radio"
                              id="test5"
                              name="radio-group2"
                              value="Logo"
                              checked={this.state.trademark_radio === "Logo"}
                              onChange={this.trademark_radioChange}
                            />
                            <label htmlFor="test5" className="uploadradiotext">
                              Logo
                            </label>
                          </div>
                        </div>

                        {/* BrandName */}
                        <div className="displayflex trademark-word">
                          <div className="uploadnocol">
                            <p className="uploadnormaltext documentno"></p>
                          </div>
                          <div className="uploaddatacol border-change">
                            <p className="uploadnormaltext">
                              Trademark word?
                              <sup className="requireddocstar">*</sup>
                            </p>
                            <input
                              type="text"
                              placeholder="Type here..."
                              className="uploadtextfield"
                              id="uploadtextfield_trademarkWord"
                              value={this.state.trademarkWord}
                              maxLength="100"
                              onChange={this.handletrademarkWordChange}
                            />
                          </div>
                        </div>

                        {/* BrandLogo */}
                        <div className="displayflex lastdisplayflex trademark-logo">
                          <div className="uploadnocol">
                            <p className="uploadnormaltext documentno"></p>
                          </div>
                          <div className="uploadlastdatacol">
                            <div className="row">
                              <div className="col-md-5">
                                <p className="uploadnormaltext">
                                  Trademark logo?
                                  <sup className="requireddocstar">*</sup>
                                </p>
                                <p className="uploadinstr">
                                  Ex: Letterhead/Invoice/Any other proof
                                </p>
                              </div>
                              <div className="col-md-7">
                                <div className="fl_right">
                                  <a
                                    href="https://docs.legal251.com/example/documents/BrandLogo.jpeg"
                                    className="viewexamplelink"
                                    target="_blank"
                                  >
                                    View example document{" "}
                                    <img
                                      className="viewDocLinkimg"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path_20245.svg"
                                      }
                                    />
                                  </a>
                                  <button
                                    className="seecommentsbtn commentsbtn"
                                    id="BrandLogoseecommentsbtn"
                                    onClick={this.handleBrandLogoseecommentsbtn}
                                  >
                                    See comments{" "}
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path 19498.svg"
                                      }
                                    />
                                  </button>
                                  <button
                                    className="hidecommentsbtn"
                                    id="BrandLogohidecommentsbtn"
                                    onClick={
                                      this.handleBrandLogohidecommentsbtn
                                    }
                                  >
                                    Hide comments{" "}
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path 19498.svg"
                                      }
                                    />
                                  </button>
                                  <label
                                    htmlFor="ul_file_5"
                                    className="choose_btn"
                                    id="choose_BrandLogo"
                                  >
                                    Choose file{" "}
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path_15188.svg"
                                      }
                                      alt="file"
                                    />
                                  </label>
                                  <input
                                    type="file"
                                    multiple
                                    name="file[]"
                                    className="upload-photo"
                                    id="ul_file_5"
                                    onClick={(event) => {
                                      event.target.value = null;
                                    }}
                                    onChange={(e) =>
                                      this.handleBrandLogoChange(e)
                                    }
                                  />
                                  <button
                                    className="X-btn"
                                    id="X-btn_BrandLogo"
                                    onClick={(e) => this.removeBrandLogo(e)}
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path_1025.svg"
                                      }
                                    />
                                  </button>
                                  <button
                                    className="review-btn"
                                    id="review-btn_BrandLogo"
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path_19663.svg"
                                      }
                                    />
                                  </button>
                                  <button
                                    className="done-btn"
                                    id="done-btn_BrandLogo"
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL + "/img/tick.svg"
                                      }
                                    />
                                  </button>
                                  <br />
                                  <a
                                    href={this.state.BrandLogoDocLink}
                                    className="viewDocLink"
                                    id="viewDocLink_BrandLogo"
                                    target="_blank"
                                  >
                                    View uploaded document{" "}
                                    <img
                                      className="viewDocLinkimg"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path_20245.svg"
                                      }
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div id="additionalDocumentDiv">
                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadadditionalsidetext visibilityhidden">
                                1&#41;
                              </p>
                            </div>
                            <div className="uploadadditionaldatacol">
                              <p className="uploadadditionalnormaltext">
                                Additional documents:-
                              </p>
                            </div>
                          </div>
                          {this.additionalFilesArea()}

                          <div className="displayflex">
                            <div className="uploadnocol">
                              <p className="uploadadditionalsidetext visibilityhidden">
                                1&#41;
                              </p>
                            </div>
                            <div className="uploadadditionaldatacol">
                              <button
                                className="adddocumentbtn"
                                id="Adddocumentbtn"
                                onClick={() =>
                                  this.handleAddFileBtn(additionalFilesIndex)
                                }
                              >
                                Add document{" "}
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/img/Path 19524.svg"
                                  }
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <center>
                      <button
                        className="proceednowbtn"
                        onClick={this.handleProceedBtn}
                      >
                        Proceed now{" "}
                        <img
                          alt="loading..."
                          src={process.env.PUBLIC_URL + "/img/tick.svg"}
                        />
                      </button>
                    </center>
                  </div>

                  <div className="col-lg-3 colbtwborder">
                    <div className="commentimg-section">
                      <SupportDetails
                        name={this.state.Name}
                        role={"Relationship Manager"}
                        contact={this.state.Contact}
                      />

                      <div className='supportDetails'>
                      <img
                        src={process.env.PUBLIC_URL + "/img/24-7_Support.png"}

                        alt="image"
                      />
                      <div>
                        <h5>{supportName}</h5>
                        <p>Agent Support Executive</p>
                        <span><b>Contact: </b> +91 {supportContact}</span>
                        <p className='mb-1'><b>Working Hours: </b> 11:00 AM - 06:30 PM</p>
                      </div>
                    </div>
                      <div className="mm">
                        <center>
                          <a
                            className="video-btn"
                            data-toggle="modal"
                            data-src="https://www.youtube.com/embed/fiC-nHoFJEY"
                            data-target="#youtubeModal"
                          >
                            <img
                              src={process.env.PUBLIC_URL + "/img/play.jpg"}
                              id="play"
                              className="play_img play_img2"
                              alt="image"
                            />
                          </a>
                        </center>
                      </div>
                      <button  data-toggle="modal" onClick={this.handletimeline}  data-src="" data-target="#timeline"  className="dashBoardBtn mt-5 newbmar" >
                        Timeline
                       </button>
                     
                       <p class="uploadinstr mt-2 uploddoc">You can also upload documents using Whatsapp on <a
                                  href="https://web.whatsapp.com/send?phone=917772877729&amp;text=Hello%20Team%20Legal251!"
                                  target="_blank"
                                >
                                  {" "}
                                  <img
                                    src={process.env.PUBLIC_URL + "/img/call.svg"}
                                    alt="call"
                                    className="callimg"
                                  />
                                </a> +91 77728-77729</p>
                    </div>
                    <div className="TDRightSide">
                      <div className="convheader">
                        <div>
                          <img
                            src={process.env.PUBLIC_URL + "/img/person.png"}
                            className="converpersonimg"
                          />
                        </div>
                        <div className="convheadercontdiv">
                          <p className="convservicename">{this.state.Name}</p>
                          <p className="convpersonname">Relationship Manager</p>
                          <p className="convuniquename">
                            Contact:{" "}
                            <span style={{ color: "#2d2d2d" }}>
                              {this.state.Contact}
                            </span>
                          </p>
                          <p className="convuniquename">
                            Service ID :{" "}
                            <span className="convuniquenamespan">
                              {Decserviceid}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="convmaindiv">
                        {this.renderCommenthistory()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="tab2" className="tab-content">

<div className="doucumainscrolldiv2">
      <div className="doucumainscrolldivinside2">
        {this.completerender()}
      </div>
    </div>
</div>
            </div>
          </div>
          :<center>
          <img alt="notaccess" className="notuploaddoc" src={process.env.PUBLIC_URL + "/img/Warning-rafiki.svg"} />

          <h4 class="my-linkmodal-text youcant">You can't upload document because you're Referal Partner. Please contact support executive for any help and more information.</h4>
          </center>
        }
          </div>
          </div>
          <SupportDetailsMobile
            name={this.state.Name}
            role={"Relationship Manager"}
            contact={this.state.Contact}
          />
          <Footer />
          <div className="modal fade" id="PANCardModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PANCard"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PANCard"
                        onClick={this.uploadPANCard}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PANCard"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="AddressProofModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_AddressProof"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_AddressProof"
                        onClick={this.uploadAddressProof}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_AddressProof"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="MsmeStartupCertificateModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_MsmeStartupCertificate"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_MsmeStartupCertificate"
                        onClick={this.uploadMsmeStartupCertificate}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_MsmeStartupCertificate"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="ProofOfTMModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_ProofOfTM"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_ProofOfTM"
                        onClick={this.uploadProofOfTM}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_ProofOfTM"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="BrandLogoModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_BrandLogo"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_BrandLogo"
                        onClick={this.uploadBrandLogo}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_BrandLogo"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade AddModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    maxLength="250"
                    placeholder="Type here..."
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn cancelbtn_7"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20 upload_btn20_7"
                        onClick={() =>
                          this.uploadFile(
                            additionalReuploadIndex,
                            additionalReuploadStatus
                          )
                        }
                      >
                        Upload
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />
                      </button>
                      <button className="upload_btnloader upload_btnloader_7">
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="youtubeModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered yt_modal "
              role="document"
            >
              <div className="modal-content">
                <div className="modal-body modal_b">
                  <button
                    type="button"
                    className="close close1"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      className="embed-responsive-item"
                      id="video"
                      allowscriptaccess="always"
                      src="https://www.youtube.com/embed/fiC-nHoFJEY?&amp;modestbranding=1&amp;showinfo=0"
                      allowFullScreen="allowfullscreen"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>

          


          <div className="modal fade"
            id="timeline"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true">

            <div
              className="modal-dialog modal-dialog-centered yt_modal "
              role="document"
            >
              <div className="modal-content videoModal">
                <div className="modal-body modal_b mt-4 ">
                  <button
                    type="button"
                    className="close close1 close1tl closeMain mr-2 dbtime"
                    data-dismiss="modal"
                    aria-label="Close"

                  >
                    <span aria-hidden="true">&times;</span>
                  </button>

                  {this.state.Timelinedata ?


                    this.state.Timeline.length > 0 ?

                      <div class="timeline scrollbtimel">
                        <div class="container">
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="timeline-container">
                                <div class="timeline-end">
                                  <button id="load-more" onClick={this.handlemoretimeline} >More..</button>
                                </div>
                                <div class="timeline-continue">

                                  {this.state.filtertimel.map((item, index) => {

                                    if (index % 2 == 0) {
                                      return (

                                        <div class="row timeline-left">
                                          <div class="col-md-6 d-md-none d-block">
                                            <p class="timeline-date">

                                            </p>
                                          </div>
                                          <div class="col-md-6">
                                            <div class="timeline-box">
                                              <div class="timeline-icon d-md-none d-block">
                                                <i class="fa fa-business-time"></i>
                                              </div>

                                              <div class="timeline-text">
                                                <p class="mainSpan">{item.time}</p>
                                                <h3>{item.timeLine}</h3>
                                                <p class="mode_p">Date : <span class="mode_span"> {item.date}</span></p>
                                                <h5 className="tlremark">{item.remark}</h5>
                                                {/* <p class="mode_p">
                                                      <span class="mode_span">
                                                          {{$TimeLine->TimeLine}}
                                                      </span>
                                                  </p>  */}


                                              </div>

                                              <div class="timeline-icon d-md-block d-none">
                                                <i class="fa fa-business-time"></i>
                                              </div>

                                            </div>
                                          </div>
                                          <div class="col-md-6 d-md-block d-none">
                                            <p class="timeline-date">

                                            </p>
                                          </div>
                                        </div>

                                      )
                                    } else {
                                      return (
                                        <div class="row timeline-right">
                                          <div class="col-md-6">
                                            <p class="timeline-date">
                                            </p>
                                          </div>
                                          <div class="col-md-6">
                                            <div class="timeline-box">
                                              <div class="timeline-icon">
                                                <i class="fa fa-gift"></i>
                                              </div>

                                              <div class="timeline-text">
                                                <p class="mainSpan">{item.time}</p>
                                                {/* <p class="">khkh</p> */}

                                                <h3>{item.timeLine}</h3>
                                                <p class="mode_p">Date : <span class="mode_span">{item.date} </span></p>
                                                <h5 className="tlremark">{item.remark}</h5>
                                                {/* <p class="mode_p">
                                                                              <span class="mode_span">
                                                                                  
                                                                              </span>
                                                                          </p> */}


                                              </div>

                                            </div>
                                          </div>
                                        </div>
                                      )
                                    }




                                  })}




                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      <center className="">
                        <img
                          alt="loading..."
                          src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
                          className="emptydeliveredDocuments"
                        />
                      </center>

                    : null
                  }
                  <center>
                    <img
                      className="timelineloder"
                      src={process.env.PUBLIC_URL + "/img/loader.gif"}
                      alt="loading"
                    />
                  </center>
                </div>
              </div>
            </div>

          </div>

        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}
export default ActiveTr;

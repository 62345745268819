import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $, { isNumeric } from "jquery";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import SupportDetails from "./SupportDetails";
import SupportDetailsMobile from "./SupportDetailsMobile";
import JSZipUtils from "jszip-utils";
import JSZip from "jszip";
import { saveAs } from "file-saver";

var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);
let Decserviceid;
let Decservicename;
let Decplan;
let Decuserid;
let Decagentid;
let Decjwttoken;
let temp = "general";
let mainaction = "upload";
let HideProceedBtn = "No";
let ChooseNowBtnsDisable = "No";
// Sole P/I
let mainactionSAadhar = "upload";
let SAadharStatus;
let mainactionSPic = "upload";
let SPicStatus;
let mainactionSPUnit = "upload";
let SPUnitStatus;
let mainactionSLayoutPlan = "upload";
let SLayoutPlanStatus;
let mainactionSListofEquip = "upload";
let SListofEquipStatus;
let mainactionSWAReport = "upload";
let SWAReportStatus;
let mainactionSListofDir = "upload";
let SListofDirStatus;
let mainactionSRecallPlan = "upload";
let SRecallPlanStatus;
let mainactionSCopyOfRegistry = "upload";
let SCopyOfRegistryStatus;
let mainactionSRentAgreement = "upload";
let SRentAgreementStatus;
let supportName;
let supportContact;
let supportEmail;
let SStatusArray = [
  {
    doc: "SAadharStatus",
    status: SAadharStatus,
  },
  {
    doc: "SPicStatus",
    status: SPicStatus,
  },
  {
    doc: "SPUnitStatus",
    status: SPUnitStatus,
  },
  {
    doc: "SLayoutPlanStatus",
    status: SLayoutPlanStatus,
  },
  {
    doc: "SListofEquipStatus",
    status: SListofEquipStatus,
  },
  {
    doc: "SWAReportStatus",
    status: SWAReportStatus,
  },
  {
    doc: "SListofDirStatus",
    status: SListofDirStatus,
  },
  {
    doc: "SRecallPlanStatus",
    status: SRecallPlanStatus,
  },
];
let SOptStatusArray = [
  {
    doc: "SCopyOfRegistryStatus",
    status: SCopyOfRegistryStatus,
  },
  {
    doc: "SRentAgreementStatus",
    status: SRentAgreementStatus,
  },
];
let SadditionalReupload = false;
let SadditionalFilesIndex;
let SadditionalReuploadIndex;
let SadditionalReuploadStatus;
let SadditionalFilesNames = [];
let Sproceednowforword;
let AnyReuploadOccuredS = "No";

// Partnership
let mainactionPAadhar1 = "upload";
let PAadhar1Status;
let mainactionPAadhar2 = "upload";
let PAadhar2Status;
let mainactionPPic1 = "upload";
let PPic1Status;
let mainactionPPic2 = "upload";
let PPic2Status;
let mainactionPPan1 = "upload";
let PPan1Status;
let mainactionPPUnit = "upload";
let PPUnitStatus;
let mainactionPLayoutPlan = "upload";
let PLayoutPlanStatus;
let mainactionPListofEquip = "upload";
let PListofEquipStatus;
let mainactionPWAReport = "upload";
let PWAReportStatus;
let mainactionPListofDir = "upload";
let PListofDirStatus;
let mainactionPRecallPlan = "upload";
let PRecallPlanStatus;
let mainactionPCopyOfRegistry = "upload";
let PCopyOfRegistryStatus;
let mainactionPRentAgreement = "upload";
let PRentAgreementStatus;

let PStatusArray = [
  {
    doc: "PAadhar1Status",
    status: PAadhar1Status,
  },
  {
    doc: "PAadhar2Status",
    status: PAadhar2Status,
  },
  {
    doc: "PPic1Status",
    status: PPic1Status,
  },
  {
    doc: "PPic2Status",
    status: PPic2Status,
  },
  {
    doc: "PPan1Status",
    status: PPan1Status,
  },
  {
    doc: "PPUnitStatus",
    status: PPUnitStatus,
  },
  {
    doc: "PLayoutPlanStatus",
    status: PLayoutPlanStatus,
  },
  {
    doc: "PListofEquipStatus",
    status: PListofEquipStatus,
  },
  {
    doc: "PWAReportStatus",
    status: PWAReportStatus,
  },
  {
    doc: "PListofDirStatus",
    status: PListofDirStatus,
  },

  {
    doc: "PRecallPlanStatus",
    status: PRecallPlanStatus,
  },
];
let POptStatusArray = [
  {
    doc: "PCopyOfRegistryStatus",
    status: PCopyOfRegistryStatus,
  },
  {
    doc: "PRentAgreementStatus",
    status: PRentAgreementStatus,
  },
];
let PadditionalReupload = false;
let PadditionalFilesIndex;
let PadditionalReuploadIndex;
let PadditionalReuploadStatus;
let PadditionalFilesNames = [];
let Pproceednowforword;
let AnyReuploadOccuredP = "No";

// Private Limited
let mainactionPLAadhar1 = "upload";
let PLAadhar1Status;
let mainactionPLAadhar2 = "upload";
let PLAadhar2Status;
let mainactionPLPic1 = "upload";
let PLPic1Status;
let mainactionPLPic2 = "upload";
let PLPic2Status;
let mainactionPLCI = "upload";
let PLCIStatus;
let mainactionPLMOA = "upload";
let PLMOAStatus;
let mainactionPLAOA = "upload";
let PLAOAStatus;
let mainactionPLPUnit = "upload";
let PLPUnitStatus;
let mainactionPLLayoutPlan = "upload";
let PLLayoutPlanStatus;
let mainactionPLListofEquip = "upload";
let PLListofEquipStatus;
let mainactionPLWAReport = "upload";
let PLWAReportStatus;
let mainactionPLListofDir = "upload";
let PLListofDirStatus;
let mainactionPLRecallPlan = "upload";
let PLRecallPlanStatus;
let mainactionPLCopyOfRegistry = "upload";
let PLCopyOfRegistryStatus;
let mainactionPLRentAgreement = "upload";
let PLRentAgreementStatus;
let mainactionPLPR = "upload";
let PLPRStatus;
let PLStatusArray = [
  {
    doc: "PLAadhar1Status",
    status: PLAadhar1Status,
  },
  {
    doc: "PLAadhar2Status",
    status: PLAadhar2Status,
  },
  {
    doc: "PLPic1Status",
    status: PLPic1Status,
  },
  {
    doc: "PLPic2Status",
    status: PLPic2Status,
  },
  {
    doc: "PLCIStatus",
    status: PLCIStatus,
  },
  {
    doc: "PLMOAStatus",
    status: PLMOAStatus,
  },
  {
    doc: "PLAOAStatus",
    status: PLAOAStatus,
  },
  {
    doc: "PLPUnitStatus",
    status: PLPUnitStatus,
  },
  {
    doc: "PLLayoutPlanStatus",
    status: PLLayoutPlanStatus,
  },
  {
    doc: "PLListofEquipStatus",
    status: PLListofEquipStatus,
  },
  {
    doc: "PLWAReportStatus",
    status: PLWAReportStatus,
  },
  {
    doc: "PLListofDirStatus",
    status: PLListofDirStatus,
  },
  {
    doc: "PLRecallPlanStatus",
    status: PLRecallPlanStatus,
  },
];
let PLOptStatusArray = [
  {
    doc: "PLCopyOfRegistryStatus",
    status: PLCopyOfRegistryStatus,
  },
  {
    doc: "PLRentAgreementStatus",
    status: PLRentAgreementStatus,
  },
];
let PLadditionalReupload = false;
let PLadditionalFilesIndex;
let PLadditionalReuploadIndex;
let PLadditionalReuploadStatus;
let PLadditionalFilesNames = [];
let PLproceednowforword;
let AnyReuploadOccuredPL = "No";

// HUF
let mainactionHUFAadhar = "upload";
let HUFAadharStatus;
let mainactionHUFPic = "upload";
let HUFPicStatus;
let mainactionHUFDeed = "upload";
let HUFDeedStatus;
let mainactionHUFPUnit = "upload";
let HUFPUnitStatus;
let mainactionHUFLayoutPlan = "upload";
let HUFLayoutPlanStatus;
let mainactionHUFListofEquip = "upload";
let HUFListofEquipStatus;
let mainactionHUFWAReport = "upload";
let HUFWAReportStatus;
let mainactionHUFListofDir = "upload";
let HUFListofDirStatus;
let mainactionHUFRecallPlan = "upload";
let HUFRecallPlanStatus;
let mainactionHUFCopyOfRegistry = "upload";
let HUFCopyOfRegistryStatus;
let mainactionHUFRentAgreement = "upload";
let HUFRentAgreementStatus;
let HUFStatusArray = [
  {
    doc: "HUFAadharStatus",
    status: HUFAadharStatus,
  },
  {
    doc: "HUFPicStatus",
    status: HUFPicStatus,
  },
  {
    doc: "HUFDeedStatus",
    status: HUFDeedStatus,
  },
  {
    doc: "HUFPUnitStatus",
    status: HUFPUnitStatus,
  },
  {
    doc: "HUFLayoutPlanStatus",
    status: HUFLayoutPlanStatus,
  },
  {
    doc: "HUFListofEquipStatus",
    status: HUFListofEquipStatus,
  },
  {
    doc: "HUFWAReportStatus",
    status: HUFWAReportStatus,
  },
  {
    doc: "HUFListofDirStatus",
    status: HUFListofDirStatus,
  },
  {
    doc: "HUFRecallPlanStatus",
    status: HUFRecallPlanStatus,
  },
];
let HUFOptStatusArray = [
  {
    doc: "HUFCopyOfRegistryStatus",
    status: HUFCopyOfRegistryStatus,
  },
  {
    doc: "HUFRentAgreementStatus",
    status: HUFRentAgreementStatus,
  },
];
let HUFadditionalReupload = false;
let HUFadditionalFilesIndex;
let HUFadditionalReuploadIndex;
let HUFadditionalReuploadStatus;
let HUFadditionalFilesNames = [];
let HUFproceednowforword;
let AnyReuploadOccuredHUF = "No";

// ST
let mainactionSTAadhar1 = "upload";
let STAadhar1Status;
let mainactionSTAadhar2 = "upload";
let STAadhar2Status;
let mainactionSTPic1 = "upload";
let STPic1Status;
let mainactionSTPic2 = "upload";
let STPic2Status;
let mainactionSTCR = "upload";
let STCRStatus;
let mainactionSTPUnit = "upload";
let STPUnitStatus;
let mainactionSTLayoutPlan = "upload";
let STLayoutPlanStatus;
let mainactionSTListofEquip = "upload";
let STListofEquipStatus;
let mainactionSTWAReport = "upload";
let STWAReportStatus;
let mainactionSTListofDir = "upload";
let STListofDirStatus;
let mainactionSTRecallPlan = "upload";
let STRecallPlanStatus;
let mainactionSTCopyOfRegistry = "upload";
let STCopyOfRegistryStatus;
let mainactionSTRentAgreement = "upload";
let STRentAgreementStatus;
let mainactionSTPR = "upload";
let STPRStatus;
let STStatusArray = [
  {
    doc: "STAadhar1Status",
    status: STAadhar1Status,
  },
  {
    doc: "STAadhar2Status",
    status: STAadhar2Status,
  },
  {
    doc: "STPic1Status",
    status: STPic1Status,
  },
  {
    doc: "STPic2Status",
    status: STPic2Status,
  },
  {
    doc: "STCRStatus",
    status: STCRStatus,
  },
  {
    doc: "STPUnitStatus",
    status: STPUnitStatus,
  },
  {
    doc: "STLayoutPlanStatus",
    status: STLayoutPlanStatus,
  },
  {
    doc: "STListofEquipStatus",
    status: STListofEquipStatus,
  },
  {
    doc: "STWAReportStatus",
    status: STWAReportStatus,
  },
  {
    doc: "STListofDirStatus",
    status: STListofDirStatus,
  },
  {
    doc: "STRecallPlanStatus",
    status: STRecallPlanStatus,
  },
];
let STOptStatusArray = [
  {
    doc: "STCopyOfRegistryStatus",
    status: STCopyOfRegistryStatus,
  },
  {
    doc: "STRentAgreementStatus",
    status: STRentAgreementStatus,
  },
];
let STadditionalReupload = false;
let STadditionalFilesIndex;
let STadditionalReuploadIndex;
let STadditionalReuploadStatus;
let STadditionalFilesNames = [];
let STproceednowforword;
let AnyReuploadOccuredST = "No";

export class ActiveFoodlicense extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // general
      licenseType: "",
      dropDownValue: "",
      serviceType: "",
      fileName: "File Name",
      fileBase64: "",
      documentType: "",
      documentName: "",
      comment: "",
      commentHistroyarray: [],
      // S
      SAadharDocLink: "#",
      SPanDocLink: "#",
      SPicDocLink: "#",
      SBPDocLink: "#",
      SAddressProofType_radio: "CopyOfRegistry",
      SRAddressProofType_radio: "CopyOfRegistry",
      SCopyOfRegistryDocLink: "#",
      SRentAgreementDocLink: "#",
      SbusinessNature: "",
      SPRDocLink: "#",
      SPUnitDocLink: "#",
      SLayoutPlanDocLink: "#",
      SListofEquipDocLink: "#",
      SListofDirDocLink: "#",
      SWAReportDocLink: "#",
      SRecallPlanDocLink: "#",
      IsSAddFileDisabled: true,
      SadditionalFiles: [],
      api_SadditionalFiles: "No",
      SAdd_DocName: "",
      // P
      PAadhar1DocLink: "#",
      PAadhar2DocLink: "#",
      PPan1DocLink: "#",
      PPan2DocLink: "#",
      PPic1DocLink: "#",
      PPic2DocLink: "#",
      PBPDocLink: "#",
      PPbusinessNature: "",
      PAddressProofType_radio: "CopyOfRegistry",
      PRAddressProofType_radio: "CopyOfRegistry",
      PCopyOfRegistryDocLink: "#",
      PRentAgreementDocLink: "#",
      PPRDocLink: "#",
      PPUnitDocLink: "#",
      PLayoutPlanDocLink: "#",
      PListofEquipDocLink: "#",
      PListofDirDocLink: "#",
      PWAReportDocLink: "#",
      PRecallPlanDocLink: "#",
      IsPAddFileDisabled: true,
      PadditionalFiles: [],
      api_PadditionalFiles: "No",
      PAdd_DocName: "",
      // PL
      PLAadhar1DocLink: "#",
      PLAadhar2DocLink: "#",
      PLPic1DocLink: "#",
      PLPic2DocLink: "#",
      PLCIDocLink: "#",
      PLMOADocLink: "#",
      PLAOADocLink: "#",
      PLbusinessNature: "",
      PLAddressProofType_radio: "CopyOfRegistry",
      PLRAddressProofType_radio: "CopyOfRegistry",
      PLCopyOfRegistryDocLink: "#",
      PLRentAgreementDocLink: "#",
      PLPRDocLink: "#",
      PLPUnitDocLink: "#",
      PLLayoutPlanDocLink: "#",
      PLListofEquipDocLink: "#",
      PLListofDirDocLink: "#",
      PLWAReportDocLink: "#",
      PLRecallPlanDocLink: "#",
      IsPLAddFileDisabled: true,
      PLadditionalFiles: [],
      api_PLadditionalFiles: "No",
      PLAdd_DocName: "",
      // HUF
      HUFAadharDocLink: "#",
      HUFPan1DocLink: "#",
      HUFPicDocLink: "#",
      HUFDeedDocLink: "#",
      HUFbusinessNature: "",
      HUFAddressProofType_radio: "CopyOfRegistry",
      HUFRAddressProofType_radio: "CopyOfRegistry",
      HUFCopyOfRegistryDocLink: "#",
      HUFRentAgreementDocLink: "#",
      HUFPRDocLink: "#",
      HUFPUnitDocLink: "#",
      HUFLayoutPlanDocLink: "#",
      HUFListofEquipDocLink: "#",
      HUFListofDirDocLink: "#",
      HUFWAReportDocLink: "#",
      HUFRecallPlanDocLink: "#",
      IsHUFAddFileDisabled: true,
      HUFadditionalFiles: [],
      api_HUFadditionalFiles: "No",
      HUFAdd_DocName: "",
      // ST
      STAadhar1DocLink: "#",
      STAadhar2DocLink: "#",
      STPan1DocLink: "#",
      STPan2DocLink: "#",
      STPic1DocLink: "#",
      STPic2DocLink: "#",
      STPremisePicDocLink: "#",
      STCRDocLink: "#",
      STBPDocLink: "#",
      STBRDocLink: "#",
      STbusinessNature: "",
      STAddressProofType_radio: "CopyOfRegistry",
      STRAddressProofType_radio: "CopyOfRegistry",
      STCopyOfRegistryDocLink: "#",
      STRentAgreementDocLink: "#",
      STPRDocLink: "#",
      STPUnitDocLink: "#",
      STLayoutPlanDocLink: "#",
      STListofEquipDocLink: "#",
      STListofDirDocLink: "#",
      STWAReportDocLink: "#",
      STRecallPlanDocLink: "#",
      IsSTAddFileDisabled: true,
      STadditionalFiles: [],
      api_STadditionalFiles: "No",
      STAdd_DocName: "",
      Name: "",
      Contact: "",
      Timeline: [],
      Timelinedata: false,
      Timeline: [],
      timelcount: 2,
      filtertimel: [],
      deliveredDocuments: [],
      referralPartner: true
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    $(document.body).css("background-color", "#2d2d2d");
    let mainuserid = localStorage.getItem("relleIsugad");
    Decuserid = encryptor.decrypt(mainuserid);
    let mainagentid = localStorage.getItem("qazxswedcvfrtgb");
    Decagentid = encryptor.decrypt(mainagentid);
    let mainjwttoken = localStorage.getItem("toeljgtkewlna");
    Decjwttoken = encryptor.decrypt(mainjwttoken);
    let mainserviceid = localStorage.getItem("vrellvaiecstiadeceig");
    let mainaservicename = localStorage.getItem("eieltaiclsraaemevcvnge");
    let mainplan = localStorage.getItem("ipacneglatvlael");
    supportName = localStorage.getItem("supportName");
    supportContact = localStorage.getItem("supportContact");
    supportEmail = localStorage.getItem("supportEmail");
    
    this.setState({
      supportName:supportName,
      supportContact:supportContact,
      supportEmail:supportEmail,
    })

    if(this.state.referralPartner == true){
      const script = document.createElement("script");
      script.src = "/js/main.js";
      script.async = true;
      document.body.appendChild(script);
    }
    // ----------------------------temporary -----------------------------------------

    let temptoken = localStorage.getItem("fdgshdjfkgjhyhdnxhsgdhskcj");
    let Dectemptoken = encryptor.decrypt(temptoken);
    let paramsUserId2 = this.props.match.params.id2;
    if (Dectemptoken != null && Dectemptoken == paramsUserId2) {
      temp = "temporary";
    } else {
      temp = "general";
    }
    // ----------------------------temporary -----------------------------------------
    if (this.state.dropDownValue == "") {
      $("#FoodLicense_default_Img").show();
      $("#FoodLicense_SolePI").hide();
      $("#FoodLicense_P_LLP").hide();
      $("#FoodLicense_PL").hide();
      $("#FoodLicense_HUF").hide();
      $("#FoodLicense_Society").hide();
      SStatusArray.map((a) => (a.status = "open"));
      SOptStatusArray.map((a) => (a.status = "open"));
      PStatusArray.map((a) => (a.status = "open"));
      POptStatusArray.map((a) => (a.status = "open"));
      PLStatusArray.map((a) => (a.status = "open"));
      PLOptStatusArray.map((a) => (a.status = "open"));
      HUFStatusArray.map((a) => (a.status = "open"));
      HUFOptStatusArray.map((a) => (a.status = "open"));
      STStatusArray.map((a) => (a.status = "open"));
      STOptStatusArray.map((a) => (a.status = "open"));
    }

    if (mainserviceid != null && mainaservicename != null && mainplan != null) {
      let paramsId = this.props.match.params.id;
      let paramsUserId = this.props.match.params.id2;
      Decserviceid = encryptor.decrypt(mainserviceid);
      Decservicename = encryptor.decrypt(mainaservicename);
      Decplan = encryptor.decrypt(mainplan);
      if (paramsId != Decserviceid || paramsUserId != Decuserid) {
        this.props.history.push("/dashboard");
      }
    } else {
      this.props.history.push("/dashboard");
    }
    this.getserviceInformationApi();
    this.setState(
      {
        SAddressProofType_radio: "CopyOfRegistry",
        PAddressProofType_radio: "CopyOfRegistry",
        PLAddressProofType_radio: "CopyOfRegistry",
        HUFAddressProofType_radio: "CopyOfRegistry",
        STAddressProofType_radio: "CopyOfRegistry",
      },
      () => {
        $(".CopyOfRegistry").css("display", "flex");
        $(".RentAgreement").hide();
      }
    );
    let str = (document.getElementsByClassName(
      "myservicesortbyfilterbystatus"
    ).innerHTML = this.props.location.pathname.replaceAll("/", " > "));
    let mainstr = (document.getElementsByClassName(
      "myservicesortbyfilterbystatus"
    ).innerHTML = str.replace(" > ", " "));
    $(".myservicesortbyfilterbystatus").html(mainstr);

    this.getnameanumber();



  }

  completerender() {
    if (this.state.deliveredDocuments.length > 0) {
      return this.state.deliveredDocuments.map((activedoc, index) => {
        return (
          <div className="displayflex documentmaincard" key={index}>
            <div>
              <img
                src={process.env.PUBLIC_URL + "/img/Path 247.svg"}
                className="docutypeimg"
              />
            </div>
            <div className="docucontdiv">
              <div className="row">
                <div className="col-md-6">
                  <p className="nameofdoc">{activedoc.documentName}</p>
                  <p className="datesenttext">
                    Date received:-{" "}
                    <span className="datesentspan">{activedoc.datesent}</span>
                  </p>
                </div>
                <div className="col-md-6">
                  <div className="fl_right">
                    <div className="displayflex">
                      <div className="viewbtndivpadding">
                        <a
                          href={activedoc.documentLink}
                          target="_blank"
                          className="viewbtn"
                        >
                          View{" "}
                          <img
                            src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                          />
                        </a>
                      </div>
                      <div>
                        <a
                          href={activedoc.documentLink}
                          className="download_btn"
                          target="_blank"
                          download
                        >
                          Download{" "}
                          <img
                            src={
                              process.env.PUBLIC_URL + "/img/download_icon.svg"
                            }
                            alt="download"
                          />
                        </a>
                      </div>
                    </div>
                    <p className="docushareviatext">
                      Share via:{" "}
                      <a
                        onClick={() =>
                          this.whatsapp(
                            activedoc.documentName,
                            activedoc.documentLink
                          )
                        }
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/whatsapp.svg"}
                          className="docushareviawhats"
                        />
                      </a>{" "}
                      <a
                        onClick={() =>
                          this.email(
                            activedoc.documentName,
                            activedoc.documentLink
                          )
                        }
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/mail.svg"}
                          className="docushareviamail"
                        />
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.deliveredDocuments.length == 0) {
      return (
        <center className="myservicequiteimgcenter">
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
            className="emptydeliveredDocuments"
          />
        </center>
      );
    }
  }

  getnameanumber = () => {
    axios.post(
      process.env.REACT_APP_API_BASE_URL + "p=empexecutiveDetails",
      {
        agentId: Decagentid,
        serviceName: Decservicename,
        serviceId: Decserviceid,
      },
      {
        headers: {
          Authkey: process.env.REACT_APP_API_KEY,
          "App-Token-Access": Decjwttoken,
        },
        auth: {
          username: process.env.REACT_APP_API_USERNAME,
          password: process.env.REACT_APP_API_PASSWORD,
        },
      }
    )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            Name: res.data.teamLeaderName,
            Contact: res.data.teamLeaderNumber,
          })
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
  }

  getserviceInformationApi = () => {
    $(".serviceoverlay").show();
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=serviceInformation",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          plan: Decplan,
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        this.setState({
          licenseType: res.data.plan,
        });
        $(".serviceoverlay").hide();
        if (res.data.code == "200" && res.data.status == "success") {

          this.setState({
            deliveredDocuments: res.data.deliveredDocuments,
          });


          HideProceedBtn = "No";
          $(".proceednowbtn").show();
          if (res.data.documents.length == 0) {
            $(".proceednowbtn").attr("disabled", true);
          }
          if (
            res.data.inputFields.length == 0 ||
            res.data.inputFields.AddressProofType == null
          ) {
            this.setState(
              {
                SAddressProofType_radio: "CopyOfRegistry",
                PAddressProofType_radio: "CopyOfRegistry",
                PLAddressProofType_radio: "CopyOfRegistry",
                HUFAddressProofType_radio: "CopyOfRegistry",
                STAddressProofType_radio: "CopyOfRegistry",
              },
              () => {
                $(".CopyOfRegistry").css("display", "flex");
                $(".RentAgreement").hide();
              }
            );
          }

          if (
            res.data.informationStatus == "lock" &&
            res.data.mainstatus == "false"
          ) {
            HideProceedBtn = "Yes";
            $(".proceednowbtn").hide();
            $("#test11-11").attr("disabled", true);
            $("#test12-22").attr("disabled", true);
            $("#test21-11").attr("disabled", true);
            $("#test22-22").attr("disabled", true);
            $("#test3-1").attr("disabled", true);
            $("#test3-2").attr("disabled", true);
            $("#test44-14").attr("disabled", true);
            $("#test44-24").attr("disabled", true);
            $("#test55-15").attr("disabled", true);
            $("#test55-25").attr("disabled", true);
          } else if (
            res.data.informationStatus == "lock" &&
            res.data.mainstatus == "true"
          ) {
            $("#test11-11").attr("disabled", true);
            $("#test12-22").attr("disabled", true);
            $("#test22-11").attr("disabled", true);
            $("#test22-22").attr("disabled", true);
            $("#test3-1").attr("disabled", true);
            $("#test3-2").attr("disabled", true);
            $("#test44-14").attr("disabled", true);
            $("#test44-24").attr("disabled", true);
            $("#test55-15").attr("disabled", true);
            $("#test55-25").attr("disabled", true);
          }
          if (res.data.serviceType == null) {
            this.setState(
              {
                dropDownValue: "volvo",
                SAdd_DocName: "",
                SadditionalFiles: [1],
                api_SadditionalFiles: "No",
                PAdd_DocName: "",
                PadditionalFiles: [1],
                api_PadditionalFiles: "No",
                PLAdd_DocName: "",
                PLadditionalFiles: [1],
                api_PLadditionalFiles: "No",
                HUFAdd_DocName: "",
                HUFadditionalFiles: [1],
                api_HUFadditionalFiles: "No",
                STAdd_DocName: "",
                STadditionalFiles: [1],
                api_STadditionalFiles: "No",
              },
              () => {
                if (this.state.dropDownValue == "volvo") {
                  $("#FoodLicense_default_Img").show();
                  $("#FoodLicense_SolePI").hide();
                  $("#FoodLicense_P_LLP").hide();
                  $("#FoodLicense_PL").hide();
                  $("#FoodLicense_HUF").hide();
                  $("#FoodLicense_Society").hide();
                  SStatusArray.map((a) => (a.status = "open"));
                  SOptStatusArray.map((a) => (a.status = "open"));
                  PStatusArray.map((a) => (a.status = "open"));
                  POptStatusArray.map((a) => (a.status = "open"));
                  PLStatusArray.map((a) => (a.status = "open"));
                  PLOptStatusArray.map((a) => (a.status = "open"));
                  HUFStatusArray.map((a) => (a.status = "open"));
                  HUFOptStatusArray.map((a) => (a.status = "open"));
                  STStatusArray.map((a) => (a.status = "open"));
                  STOptStatusArray.map((a) => (a.status = "open"));
                }
              }
            );
          } else if (res.data.serviceType == "FL_SolePI_Documents") {
            $(".selectcateselect").attr("disabled", true);
            this.setState(
              {
                dropDownValue: "FL_SolePI_Documents",
                serviceType: "FL_SolePI_Documents",
              },
              () => {
                if (this.state.dropDownValue == "FL_SolePI_Documents") {
                  $("#FoodLicense_default_Img").hide();
                  $("#FoodLicense_SolePI").show();
                  $("#FoodLicense_P_LLP").hide();
                  $("#FoodLicense_PL").hide();
                  $("#FoodLicense_HUF").hide();
                  $("#FoodLicense_Society").hide();
                }
              }
            );
            if (res.data.informationStatus == "lock") {
              if (res.data.addDocInformation.length == 0) {
                $("#SadditionalDocumentDiv").hide();
              } else {
                $("#SAdddocumentbtn").hide();
              }
            }
            if (res.data.addDocInformation.length > 0) {
              this.setState(
                {
                  SadditionalFiles: [...res.data.addDocInformation],
                  SAdd_DocName: "File Name",
                  api_SadditionalFiles: "Yes",
                },
                () => { }
              );
            } else if (
              res.data.addDocInformation.length == 0 &&
              res.data.informationStatus != "lock"
            ) {
              this.setState({
                SAdd_DocName: "",
                SadditionalFiles: [1],
                api_SadditionalFiles: "No",
              });
            }
            if (res.data.inputFields.length > 0) {
              if (
                res.data.inputFields[0].status == "review" ||
                res.data.inputFields[0].status == "done"
              ) {
                this.setState({
                  SbusinessNature: res.data.inputFields[0].Nature,
                });
                $(".uploadtextarea").attr("disabled", true);
              }
              if (
                res.data.inputFields[1].AddressProofType == "CopyOfRegistry"
              ) {
                this.setState(
                  {
                    SAddressProofType_radio: "CopyOfRegistry",
                  },
                  () => {
                    $(".CopyOfRegistry").css("display", "flex");
                    $(".RentAgreement").hide();
                  }
                );
              } else if (
                res.data.inputFields[1].AddressProofType == "RentAgreement"
              ) {
                this.setState(
                  {
                    SAddressProofType_radio: "RentAgreement",
                  },
                  () => {
                    $(".RentAgreement").css("display", "flex");
                    $(".CopyOfRegistry").hide();
                  }
                );
              }
            }
            if (res.data.documents.length > 0) {
              let doc = res.data.documents;
              if (doc[1].status == "remove") {
                mainactionSAadhar = "upload";
                SAadharStatus = "remove";
                SStatusArray[0].status = SAadharStatus;
                this.setState(
                  {
                    SAadharDocLink: doc[1].IDProofApplicant,
                  },
                  () => {
                    $("#viewDocLink_SAadhar").css("display", "block");
                    $("#choose_SAadhar").hide();
                    $("#X-btn_SAadhar").show();
                    $("#done-btn_SAadhar").hide();
                    $("#review-btn_SAadhar").hide();
                  }
                );
              } else if (doc[1].status == "review") {
                mainactionSAadhar = "upload";
                SAadharStatus = "review";
                SStatusArray[0].status = SAadharStatus;
                this.setState(
                  {
                    SAadharDocLink: doc[1].IDProofApplicant,
                  },
                  () => {
                    $("#viewDocLink_SAadhar").css("display", "block");
                    $("#choose_SAadhar").hide();
                    $("#X-btn_SAadhar").hide();
                    $("#done-btn_SAadhar").hide();
                    $("#review-btn_SAadhar").show();
                  }
                );
              } else if (doc[1].status == "reupload") {
                mainactionSAadhar = "reupload";
                SAadharStatus = "reupload";
                SStatusArray[0].status = SAadharStatus;
                $("#choose_SAadhar").show();
                $("#viewDocLink_SAadhar").hide();
                $("#X-btn_SAadhar").hide();
                $("#done-btn_SAadhar").hide();
                $("#review-btn_SAadhar").hide();
              } else if (doc[1].status == "done") {
                SAadharStatus = "done";
                mainactionSAadhar = "upload";
                SStatusArray[0].status = SAadharStatus;
                $("#choose_SAadhar").hide();
                $("#X-btn_SAadhar").hide();
                $("#done-btn_SAadhar").show();
                $("#review-btn_SAadhar").hide();
              } else {
                SAadharStatus = "open";
                SStatusArray[0].status = SAadharStatus;
              }
              if (doc[0].status == "remove") {
                mainactionSPic = "upload";
                SPicStatus = "remove";
                SStatusArray[1].status = SPicStatus;
                this.setState(
                  {
                    SPicDocLink: doc[0].ApplicantPhoto,
                  },
                  () => {
                    $("#viewDocLink_SPic").css("display", "block");
                    $("#choose_SPic").hide();
                    $("#X-btn_SPic").show();
                    $("#done-btn_SPic").hide();
                    $("#review-btn_SPic").hide();
                  }
                );
              } else if (doc[0].status == "review") {
                mainactionSPic = "upload";
                SPicStatus = "review";
                SStatusArray[1].status = SPicStatus;
                this.setState(
                  {
                    SPicDocLink: doc[0].ApplicantPhoto,
                  },
                  () => {
                    $("#viewDocLink_SPic").css("display", "block");
                    $("#choose_SPic").hide();
                    $("#X-btn_SPic").hide();
                    $("#done-btn_SPic").hide();
                    $("#review-btn_SPic").show();
                  }
                );
              } else if (doc[0].status == "reupload") {
                mainactionSPic = "reupload";
                SPicStatus = "reupload";
                SStatusArray[1].status = SPicStatus;
                $("#choose_SPic").show();
                $("#viewDocLink_SPic").hide();
                $("#X-btn_SPic").hide();
                $("#done-btn_SPic").hide();
                $("#review-btn_SPic").hide();
              } else if (doc[0].status == "done") {
                SPicStatus = "done";
                mainactionSPic = "upload";
                SStatusArray[1].status = SPicStatus;
                $("#choose_SPic").hide();
                $("#X-btn_SPic").hide();
                $("#done-btn_SPic").show();
                $("#review-btn_SPic").hide();
              } else {
                SPicStatus = "open";
                SStatusArray[1].status = SPicStatus;
              }
              if (doc[2].status == "remove") {
                mainactionSCopyOfRegistry = "upload";
                SCopyOfRegistryStatus = "remove";
                SOptStatusArray[0].status = SCopyOfRegistryStatus;
                this.setState(
                  {
                    SCopyOfRegistryDocLink: doc[2].OwnershipDeed,
                  },
                  () => {
                    $("#viewDocLink_SCopyOfRegistry").css("display", "block");
                    $("#choose_SCopyOfRegistry").hide();
                    $("#X-btn_SCopyOfRegistry").show();
                    $("#done-btn_SCopyOfRegistry").hide();
                    $("#review-btn_SCopyOfRegistry").hide();
                  }
                );
              } else if (doc[2].status == "review") {
                mainactionSCopyOfRegistry = "upload";
                SCopyOfRegistryStatus = "review";
                SOptStatusArray[0].status = SCopyOfRegistryStatus;
                this.setState(
                  {
                    SCopyOfRegistryDocLink: doc[2].OwnershipDeed,
                  },
                  () => {
                    $("#viewDocLink_SCopyOfRegistry").css("display", "block");
                    $("#choose_SCopyOfRegistry").hide();
                    $("#X-btn_SCopyOfRegistry").hide();
                    $("#done-btn_SCopyOfRegistry").hide();
                    $("#review-btn_SCopyOfRegistry").show();
                  }
                );
              } else if (doc[2].status == "reupload") {
                mainactionSCopyOfRegistry = "reupload";
                SCopyOfRegistryStatus = "reupload";
                SOptStatusArray[0].status = SCopyOfRegistryStatus;
                $("#choose_SCopyOfRegistry").show();
                $("#viewDocLink_SCopyOfRegistry").hide();
                $("#X-btn_SCopyOfRegistry").hide();
                $("#done-btn_SCopyOfRegistry").hide();
                $("#review-btn_SCopyOfRegistry").hide();
              } else if (doc[2].status == "done") {
                SCopyOfRegistryStatus = "done";
                mainactionSCopyOfRegistry = "upload";
                SOptStatusArray[0].status = SCopyOfRegistryStatus;
                $("#choose_SCopyOfRegistry").hide();
                $("#X-btn_SCopyOfRegistry").hide();
                $("#done-btn_SCopyOfRegistry").show();
                $("#review-btn_SCopyOfRegistry").hide();
              } else {
                SCopyOfRegistryStatus = "open";
                SOptStatusArray[0].status = SCopyOfRegistryStatus;
              }
              if (doc[3].status == "remove") {
                mainactionSRentAgreement = "upload";
                SRentAgreementStatus = "remove";
                SOptStatusArray[1].status = SRentAgreementStatus;
                this.setState(
                  {
                    SRentAgreementDocLink: doc[3].RentAgreement,
                  },
                  () => {
                    $("#viewDocLink_SRentAgreement").css("display", "block");
                    $("#choose_SRentAgreement").hide();
                    $("#X-btn_SRentAgreement").show();
                    $("#done-btn_SRentAgreement").hide();
                    $("#review-btn_SRentAgreement").hide();
                  }
                );
              } else if (doc[3].status == "review") {
                mainactionSRentAgreement = "upload";
                SRentAgreementStatus = "review";
                SOptStatusArray[1].status = SRentAgreementStatus;
                this.setState(
                  {
                    SRentAgreementDocLink: doc[3].RentAgreement,
                  },
                  () => {
                    $("#viewDocLink_SRentAgreement").css("display", "block");
                    $("#choose_SRentAgreement").hide();
                    $("#X-btn_SRentAgreement").hide();
                    $("#done-btn_SRentAgreement").hide();
                    $("#review-btn_SRentAgreement").show();
                  }
                );
              } else if (doc[3].status == "reupload") {
                mainactionSRentAgreement = "reupload";
                SRentAgreementStatus = "reupload";
                SOptStatusArray[1].status = SRentAgreementStatus;
                $("#choose_SRentAgreement").show();
                $("#viewDocLink_SRentAgreement").hide();
                $("#X-btn_SRentAgreement").hide();
                $("#done-btn_SRentAgreement").hide();
                $("#review-btn_SRentAgreement").hide();
              } else if (doc[3].status == "done") {
                SRentAgreementStatus = "done";
                mainactionSRentAgreement = "upload";
                SOptStatusArray[1].status = SRentAgreementStatus;
                $("#choose_SRentAgreement").hide();
                $("#X-btn_SRentAgreement").hide();
                $("#done-btn_SRentAgreement").show();
                $("#review-btn_SRentAgreement").hide();
              } else {
                SRentAgreementStatus = "open";
                SOptStatusArray[1].status = SRentAgreementStatus;
              }
              if (doc[4].status == "remove") {
                mainactionSPUnit = "upload";
                SPUnitStatus = "remove";
                SStatusArray[2].status = SPUnitStatus;
                this.setState(
                  {
                    SPUnitDocLink: doc[4].ProductionUnitPhoto,
                  },
                  () => {
                    $("#viewDocLink_SPUnit").css("display", "block");
                    $("#choose_SPUnit").hide();
                    $("#X-btn_SPUnit").show();
                    $("#done-btn_SPUnit").hide();
                    $("#review-btn_SPUnit").hide();
                  }
                );
              } else if (doc[4].status == "review") {
                mainactionSPUnit = "upload";
                SPUnitStatus = "review";
                SStatusArray[2].status = SPUnitStatus;
                this.setState(
                  {
                    SPUnitDocLink: doc[4].ProductionUnitPhoto,
                  },
                  () => {
                    $("#viewDocLink_SPUnit").css("display", "block");
                    $("#choose_SPUnit").hide();
                    $("#X-btn_SPUnit").hide();
                    $("#done-btn_SPUnit").hide();
                    $("#review-btn_SPUnit").show();
                  }
                );
              } else if (doc[4].status == "reupload") {
                mainactionSPUnit = "reupload";
                SPUnitStatus = "reupload";
                SStatusArray[2].status = SPUnitStatus;
                $("#choose_SPUnit").show();
                $("#viewDocLink_SPUnit").hide();
                $("#X-btn_SPUnit").hide();
                $("#done-btn_SPUnit").hide();
                $("#review-btn_SPUnit").hide();
              } else if (doc[4].status == "done") {
                SPUnitStatus = "done";
                mainactionSPUnit = "upload";
                SStatusArray[2].status = SPUnitStatus;
                $("#choose_SPUnit").hide();
                $("#X-btn_SPUnit").hide();
                $("#done-btn_SPUnit").show();
                $("#review-btn_SPUnit").hide();
              } else {
                SPUnitStatus = "open";
                SStatusArray[2].status = SPUnitStatus;
              }

              if (doc[5].status == "remove") {
                mainactionSLayoutPlan = "upload";
                SLayoutPlanStatus = "remove";
                SStatusArray[3].status = SLayoutPlanStatus;
                this.setState(
                  {
                    SLayoutPlanDocLink: doc[5].LayoutPlanUnit,
                  },
                  () => {
                    $("#viewDocLink_SLayoutPlan").css("display", "block");
                    $("#choose_SLayoutPlan").hide();
                    $("#X-btn_SLayoutPlan").show();
                    $("#done-btn_SLayoutPlan").hide();
                    $("#review-btn_SLayoutPlan").hide();
                  }
                );
              } else if (doc[5].status == "review") {
                mainactionSLayoutPlan = "upload";
                SLayoutPlanStatus = "review";
                SStatusArray[3].status = SLayoutPlanStatus;
                this.setState(
                  {
                    SLayoutPlanDocLink: doc[5].LayoutPlanUnit,
                  },
                  () => {
                    $("#viewDocLink_SLayoutPlan").css("display", "block");
                    $("#choose_SLayoutPlan").hide();
                    $("#X-btn_SLayoutPlan").hide();
                    $("#done-btn_SLayoutPlan").hide();
                    $("#review-btn_SLayoutPlan").show();
                  }
                );
              } else if (doc[5].status == "reupload") {
                mainactionSLayoutPlan = "reupload";
                SLayoutPlanStatus = "reupload";
                SStatusArray[3].status = SLayoutPlanStatus;
                $("#choose_SLayoutPlan").show();
                $("#viewDocLink_SLayoutPlan").hide();
                $("#X-btn_SLayoutPlan").hide();
                $("#done-btn_SLayoutPlan").hide();
                $("#review-btn_SLayoutPlan").hide();
              } else if (doc[5].status == "done") {
                SLayoutPlanStatus = "done";
                mainactionSLayoutPlan = "upload";
                SStatusArray[3].status = SLayoutPlanStatus;
                $("#choose_SLayoutPlan").hide();
                $("#X-btn_SLayoutPlan").hide();
                $("#done-btn_SLayoutPlan").show();
                $("#review-btn_SLayoutPlan").hide();
              } else {
                SLayoutPlanStatus = "open";
                SStatusArray[3].status = SLayoutPlanStatus;
              }

              if (doc[6].status == "remove") {
                mainactionSListofEquip = "upload";
                SListofEquipStatus = "remove";
                SStatusArray[4].status = SListofEquipStatus;
                this.setState(
                  {
                    SListofEquipDocLink: doc[6].ListOfEquipment,
                  },
                  () => {
                    $("#viewDocLink_SListofEquip").css("display", "block");
                    $("#choose_SListofEquip").hide();
                    $("#X-btn_SListofEquip").show();
                    $("#done-btn_SListofEquip").hide();
                    $("#review-btn_SListofEquip").hide();
                  }
                );
              } else if (doc[6].status == "review") {
                mainactionSListofEquip = "upload";
                SListofEquipStatus = "review";
                SStatusArray[4].status = SListofEquipStatus;
                this.setState(
                  {
                    SListofEquipDocLink: doc[6].ListOfEquipment,
                  },
                  () => {
                    $("#viewDocLink_SListofEquip").css("display", "block");
                    $("#choose_SListofEquip").hide();
                    $("#X-btn_SListofEquip").hide();
                    $("#done-btn_SListofEquip").hide();
                    $("#review-btn_SListofEquip").show();
                  }
                );
              } else if (doc[6].status == "reupload") {
                mainactionSListofEquip = "reupload";
                SListofEquipStatus = "reupload";
                SStatusArray[4].status = SListofEquipStatus;
                $("#choose_SListofEquip").show();
                $("#viewDocLink_SListofEquip").hide();
                $("#X-btn_SListofEquip").hide();
                $("#done-btn_SListofEquip").hide();
                $("#review-btn_SListofEquip").hide();
              } else if (doc[6].status == "done") {
                SListofEquipStatus = "done";
                mainactionSListofEquip = "upload";
                SStatusArray[4].status = SListofEquipStatus;
                $("#choose_SListofEquip").hide();
                $("#X-btn_SListofEquip").hide();
                $("#done-btn_SListofEquip").show();
                $("#review-btn_SListofEquip").hide();
              } else {
                SListofEquipStatus = "open";
                SStatusArray[4].status = SListofEquipStatus;
              }

              if (doc[7].status == "remove") {
                mainactionSWAReport = "upload";
                SWAReportStatus = "remove";
                SStatusArray[5].status = SWAReportStatus;
                this.setState(
                  {
                    SWAReportDocLink: doc[7].WaterAnalysisReport,
                  },
                  () => {
                    $("#viewDocLink_SWAReport").css("display", "block");
                    $("#choose_SWAReport").hide();
                    $("#X-btn_SWAReport").show();
                    $("#done-btn_SWAReport").hide();
                    $("#review-btn_SWAReport").hide();
                  }
                );
              } else if (doc[7].status == "review") {
                mainactionSWAReport = "upload";
                SWAReportStatus = "review";
                SStatusArray[5].status = SWAReportStatus;
                this.setState(
                  {
                    SWAReportDocLink: doc[7].WaterAnalysisReport,
                  },
                  () => {
                    $("#viewDocLink_SWAReport").css("display", "block");
                    $("#choose_SWAReport").hide();
                    $("#X-btn_SWAReport").hide();
                    $("#done-btn_SWAReport").hide();
                    $("#review-btn_SWAReport").show();
                  }
                );
              } else if (doc[7].status == "reupload") {
                mainactionSWAReport = "reupload";
                SWAReportStatus = "reupload";
                SStatusArray[5].status = SWAReportStatus;
                $("#choose_SWAReport").show();
                $("#viewDocLink_SWAReport").hide();
                $("#X-btn_SWAReport").hide();
                $("#done-btn_SWAReport").hide();
                $("#review-btn_SWAReport").hide();
              } else if (doc[7].status == "done") {
                SWAReportStatus = "done";
                mainactionSWAReport = "upload";
                SStatusArray[5].status = SWAReportStatus;
                $("#choose_SWAReport").hide();
                $("#X-btn_SWAReport").hide();
                $("#done-btn_SWAReport").show();
                $("#review-btn_SWAReport").hide();
              } else {
                SWAReportStatus = "open";
                SStatusArray[5].status = SWAReportStatus;
              }

              if (doc[8].status == "remove") {
                mainactionSListofDir = "upload";
                SListofDirStatus = "remove";
                SStatusArray[6].status = SListofDirStatus;
                this.setState(
                  {
                    SListofDirDocLink: doc[8].DirectorsFullAddress,
                  },
                  () => {
                    $("#viewDocLink_SListofDir").css("display", "block");
                    $("#choose_SListofDir").hide();
                    $("#X-btn_SListofDir").show();
                    $("#done-btn_SListofDir").hide();
                    $("#review-btn_SListofDir").hide();
                  }
                );
              } else if (doc[8].status == "review") {
                mainactionSListofDir = "upload";
                SListofDirStatus = "review";
                SStatusArray[6].status = SListofDirStatus;
                this.setState(
                  {
                    SListofDirDocLink: doc[8].DirectorsFullAddress,
                  },
                  () => {
                    $("#viewDocLink_SListofDir").css("display", "block");
                    $("#choose_SListofDir").hide();
                    $("#X-btn_SListofDir").hide();
                    $("#done-btn_SListofDir").hide();
                    $("#review-btn_SListofDir").show();
                  }
                );
              } else if (doc[8].status == "reupload") {
                mainactionSListofDir = "reupload";
                SListofDirStatus = "reupload";
                SStatusArray[6].status = SListofDirStatus;
                $("#choose_SListofDir").show();
                $("#viewDocLink_SListofDir").hide();
                $("#X-btn_SListofDir").hide();
                $("#done-btn_SListofDir").hide();
                $("#review-btn_SListofDir").hide();
              } else if (doc[8].status == "done") {
                SListofDirStatus = "done";
                mainactionSListofDir = "upload";
                SStatusArray[6].status = SListofDirStatus;
                $("#choose_SListofDir").hide();
                $("#X-btn_SListofDir").hide();
                $("#done-btn_SListofDir").show();
                $("#review-btn_SListofDir").hide();
              } else {
                SListofDirStatus = "open";
                SStatusArray[6].status = SListofDirStatus;
              }
              if (doc[9].status == "remove") {
                mainactionSRecallPlan = "upload";
                SRecallPlanStatus = "remove";
                SStatusArray[7].status = SRecallPlanStatus;
                this.setState(
                  {
                    SRecallPlanDocLink: doc[9].RecallPlan,
                  },
                  () => {
                    $("#viewDocLink_SRecallPlan").css("display", "block");
                    $("#choose_SRecallPlan").hide();
                    $("#X-btn_SRecallPlan").show();
                    $("#done-btn_SRecallPlan").hide();
                    $("#review-btn_SRecallPlan").hide();
                  }
                );
              } else if (doc[9].status == "review") {
                mainactionSRecallPlan = "upload";
                SRecallPlanStatus = "review";
                SStatusArray[7].status = SRecallPlanStatus;
                this.setState(
                  {
                    SRecallPlanDocLink: doc[9].RecallPlan,
                  },
                  () => {
                    $("#viewDocLink_SRecallPlan").css("display", "block");
                    $("#choose_SRecallPlan").hide();
                    $("#X-btn_SRecallPlan").hide();
                    $("#done-btn_SRecallPlan").hide();
                    $("#review-btn_SRecallPlan").show();
                  }
                );
              } else if (doc[9].status == "reupload") {
                mainactionSRecallPlan = "reupload";
                SRecallPlanStatus = "reupload";
                SStatusArray[7].status = SRecallPlanStatus;
                $("#choose_SRecallPlan").show();
                $("#viewDocLink_SRecallPlan").hide();
                $("#X-btn_SRecallPlan").hide();
                $("#done-btn_SRecallPlan").hide();
                $("#review-btn_SRecallPlan").hide();
              } else if (doc[9].status == "done") {
                SRecallPlanStatus = "done";
                mainactionSRecallPlan = "upload";
                SStatusArray[7].status = SRecallPlanStatus;
                $("#choose_SRecallPlan").hide();
                $("#X-btn_SRecallPlan").hide();
                $("#done-btn_SRecallPlan").show();
                $("#review-btn_SRecallPlan").hide();
              } else {
                SRecallPlanStatus = "open";
                SStatusArray[7].status = SRecallPlanStatus;
              }
            }

            this.ScheckRemainingReuploads();
            this.checkhandleSProceedBtn();
          } else if (res.data.serviceType == "FL_P_LLP_Documents") {
            $(".selectcateselect").attr("disabled", true);
            this.setState(
              {
                dropDownValue: "FL_P_LLP_Documents",
                serviceType: "FL_P_LLP_Documents",
              },
              () => {
                if (this.state.dropDownValue == "FL_P_LLP_Documents") {
                  $("#FoodLicense_default_Img").hide();
                  $("#FoodLicense_SolePI").hide();
                  $("#FoodLicense_P_LLP").show();
                  $("#FoodLicense_PL").hide();
                  $("#FoodLicense_HUF").hide();
                  $("#FoodLicense_Society").hide();
                }
              }
            );
            if (res.data.informationStatus == "lock") {
              if (res.data.addDocInformation.length == 0) {
                $("#PadditionalDocumentDiv").hide();
              } else {
                $("#PAdddocumentbtn").hide();
              }
            }
            if (res.data.addDocInformation.length > 0) {
              this.setState(
                {
                  PadditionalFiles: [...res.data.addDocInformation],
                  PAdd_DocName: "File Name",
                  api_PadditionalFiles: "Yes",
                },
                () => { }
              );
            } else if (
              res.data.addDocInformation.length == 0 &&
              res.data.informationStatus != "lock"
            ) {
              this.setState({
                PAdd_DocName: "",
                PadditionalFiles: [1],
                api_PadditionalFiles: "No",
              });
            }

            if (res.data.inputFields.length > 0) {
              if (
                res.data.inputFields[0].status == "review" ||
                res.data.inputFields[0].status == "done"
              ) {
                this.setState({
                  PPbusinessNature: res.data.inputFields[0].Nature,
                });
                $(".uploadtextarea").attr("disabled", true);
              }
              if (
                res.data.inputFields[1].AddressProofType == "CopyOfRegistry"
              ) {
                this.setState(
                  {
                    PAddressProofType_radio: "CopyOfRegistry",
                  },
                  () => {
                    $(".CopyOfRegistry").css("display", "flex");
                    $(".RentAgreement").hide();
                  }
                );
              } else if (
                res.data.inputFields[1].AddressProofType == "RentAgreement"
              ) {
                this.setState(
                  {
                    PAddressProofType_radio: "RentAgreement",
                  },
                  () => {
                    $(".RentAgreement").css("display", "flex");
                    $(".CopyOfRegistry").hide();
                  }
                );
              }
            }
            if (res.data.documents.length > 0) {
              let doc = res.data.documents;
              if (doc[2].status == "remove") {
                mainactionPAadhar1 = "upload";
                PAadhar1Status = "remove";
                PStatusArray[0].status = PAadhar1Status;
                this.setState(
                  {
                    PAadhar1DocLink: doc[2].IDProofApplicant1,
                  },
                  () => {
                    $("#viewDocLink_PAadhar1").css("display", "block");
                    $("#choose_PAadhar1").hide();
                    $("#X-btn_PAadhar1").show();
                    $("#done-btn_PAadhar1").hide();
                    $("#review-btn_PAadhar1").hide();
                  }
                );
              } else if (doc[2].status == "review") {
                mainactionPAadhar1 = "upload";
                PAadhar1Status = "review";
                PStatusArray[0].status = PAadhar1Status;
                this.setState(
                  {
                    PAadhar1DocLink: doc[2].IDProofApplicant1,
                  },
                  () => {
                    $("#viewDocLink_PAadhar1").css("display", "block");
                    $("#choose_PAadhar1").hide();
                    $("#X-btn_PAadhar1").hide();
                    $("#done-btn_PAadhar1").hide();
                    $("#review-btn_PAadhar1").show();
                  }
                );
              } else if (doc[2].status == "reupload") {
                mainactionPAadhar1 = "reupload";
                PAadhar1Status = "reupload";
                PStatusArray[0].status = PAadhar1Status;
                $("#choose_PAadhar1").show();
                $("#viewDocLink_PAadhar1").hide();
                $("#X-btn_PAadhar1").hide();
                $("#done-btn_PAadhar1").hide();
                $("#review-btn_PAadhar1").hide();
              } else if (doc[2].status == "done") {
                PAadhar1Status = "done";
                mainactionPAadhar1 = "upload";
                PStatusArray[0].status = PAadhar1Status;
                $("#choose_PAadhar1").hide();
                $("#X-btn_PAadhar1").hide();
                $("#done-btn_PAadhar1").show();
                $("#review-btn_PAadhar1").hide();
              } else {
                PAadhar1Status = "open";
                PStatusArray[0].status = PAadhar1Status;
              }
              if (doc[3].status == "remove") {
                mainactionPAadhar2 = "upload";
                PAadhar2Status = "remove";
                PStatusArray[1].status = PAadhar2Status;
                this.setState(
                  {
                    PAadhar2DocLink: doc[3].IDProofApplicant2,
                  },
                  () => {
                    $("#viewDocLink_PAadhar2").css("display", "block");
                    $("#choose_PAadhar2").hide();
                    $("#X-btn_PAadhar2").show();
                    $("#done-btn_PAadhar2").hide();
                    $("#review-btn_PAadhar2").hide();
                  }
                );
              } else if (doc[3].status == "review") {
                mainactionPAadhar2 = "upload";
                PAadhar2Status = "review";
                PStatusArray[1].status = PAadhar2Status;
                this.setState(
                  {
                    PAadhar2DocLink: doc[3].IDProofApplicant2,
                  },
                  () => {
                    $("#viewDocLink_PAadhar2").css("display", "block");
                    $("#choose_PAadhar2").hide();
                    $("#X-btn_PAadhar2").hide();
                    $("#done-btn_PAadhar2").hide();
                    $("#review-btn_PAadhar2").show();
                  }
                );
              } else if (doc[2].status == "reupload") {
                mainactionPAadhar2 = "reupload";
                PAadhar2Status = "reupload";
                PStatusArray[1].status = PAadhar2Status;
                $("#choose_PAadhar2").show();
                $("#viewDocLink_PAadhar2").hide();
                $("#X-btn_PAadhar2").hide();
                $("#done-btn_PAadhar2").hide();
                $("#review-btn_PAadhar2").hide();
              } else if (doc[2].status == "done") {
                PAadhar2Status = "done";
                mainactionPAadhar2 = "upload";
                PStatusArray[1].status = PAadhar2Status;
                $("#choose_PAadhar2").hide();
                $("#X-btn_PAadhar2").hide();
                $("#done-btn_PAadhar2").show();
                $("#review-btn_PAadhar2").hide();
              } else {
                PAadhar2Status = "open";
                PStatusArray[1].status = PAadhar2Status;
              }
              if (doc[0].status == "remove") {
                mainactionPPic1 = "upload";
                PPic1Status = "remove";
                PStatusArray[2].status = PPic1Status;
                this.setState(
                  {
                    PPic1DocLink: doc[0].ApplicantPhoto1,
                  },
                  () => {
                    $("#viewDocLink_PPic1").css("display", "block");
                    $("#choose_PPic1").hide();
                    $("#X-btn_PPic1").show();
                    $("#done-btn_PPic1").hide();
                    $("#review-btn_PPic1").hide();
                  }
                );
              } else if (doc[0].status == "review") {
                mainactionPPic1 = "upload";
                PPic1Status = "review";
                PStatusArray[2].status = PPic1Status;
                this.setState(
                  {
                    PPic1DocLink: doc[0].ApplicantPhoto1,
                  },
                  () => {
                    $("#viewDocLink_PPic1").css("display", "block");
                    $("#choose_PPic1").hide();
                    $("#X-btn_PPic1").hide();
                    $("#done-btn_PPic1").hide();
                    $("#review-btn_PPic1").show();
                  }
                );
              } else if (doc[0].status == "reupload") {
                mainactionPPic1 = "reupload";
                PPic1Status = "reupload";
                PStatusArray[2].status = PPic1Status;
                $("#choose_PPic1").show();
                $("#viewDocLink_PPic1").hide();
                $("#X-btn_PPic1").hide();
                $("#done-btn_PPic1").hide();
                $("#review-btn_PPic1").hide();
              } else if (doc[0].status == "done") {
                PPic1Status = "done";
                mainactionPPic1 = "upload";
                PStatusArray[2].status = PPic1Status;
                $("#choose_PPic1").hide();
                $("#X-btn_PPic1").hide();
                $("#done-btn_PPic1").show();
                $("#review-btn_PPic1").hide();
              } else {
                PPic1Status = "open";
                PStatusArray[2].status = PPic1Status;
              }
              if (doc[1].status == "remove") {
                mainactionPPic2 = "upload";
                PPic2Status = "remove";
                PStatusArray[3].status = PPic2Status;
                this.setState(
                  {
                    PPic2DocLink: doc[1].ApplicantPhoto2,
                  },
                  () => {
                    $("#viewDocLink_PPic2").css("display", "block");
                    $("#choose_PPic2").hide();
                    $("#X-btn_PPic2").show();
                    $("#done-btn_PPic2").hide();
                    $("#review-btn_PPic2").hide();
                  }
                );
              } else if (doc[1].status == "review") {
                mainactionPPic2 = "upload";
                PPic2Status = "review";
                PStatusArray[3].status = PPic2Status;
                this.setState(
                  {
                    PPic2DocLink: doc[1].ApplicantPhoto2,
                  },
                  () => {
                    $("#viewDocLink_PPic2").css("display", "block");
                    $("#choose_PPic2").hide();
                    $("#X-btn_PPic2").hide();
                    $("#done-btn_PPic2").hide();
                    $("#review-btn_PPic2").show();
                  }
                );
              } else if (doc[1].status == "reupload") {
                mainactionPPic2 = "reupload";
                PPic2Status = "reupload";
                PStatusArray[3].status = PPic2Status;
                $("#choose_PPic2").show();
                $("#viewDocLink_PPic2").hide();
                $("#X-btn_PPic2").hide();
                $("#done-btn_PPic2").hide();
                $("#review-btn_PPic2").hide();
              } else if (doc[1].status == "done") {
                PPic2Status = "done";
                mainactionPPic2 = "upload";
                PStatusArray[3].status = PPic2Status;
                $("#choose_PPic2").hide();
                $("#X-btn_PPic2").hide();
                $("#done-btn_PPic2").show();
                $("#review-btn_PPic2").hide();
              } else {
                PPic2Status = "open";
                PStatusArray[3].status = PPic2Status;
              }

              if (doc[4].status == "remove") {
                mainactionPPan1 = "upload";
                PPan1Status = "remove";
                PStatusArray[4].status = PPan1Status;
                this.setState(
                  {
                    PPan1DocLink: doc[4].PartnershipDeed,
                  },
                  () => {
                    $("#viewDocLink_PPan1").css("display", "block");
                    $("#choose_PPan1").hide();
                    $("#X-btn_PPan1").show();
                    $("#done-btn_PPan1").hide();
                    $("#review-btn_PPan1").hide();
                  }
                );
              } else if (doc[4].status == "review") {
                mainactionPPan1 = "upload";
                PPan1Status = "review";
                PStatusArray[4].status = PPan1Status;
                this.setState(
                  {
                    PPan1DocLink: doc[4].PartnershipDeed,
                  },
                  () => {
                    $("#viewDocLink_PPan1").css("display", "block");
                    $("#choose_PPan1").hide();
                    $("#X-btn_PPan1").hide();
                    $("#done-btn_PPan1").hide();
                    $("#review-btn_PPan1").show();
                  }
                );
              } else if (doc[4].status == "reupload") {
                mainactionPPan1 = "reupload";
                PPan1Status = "reupload";
                PStatusArray[4].status = PPan1Status;
                $("#choose_PPan1").show();
                $("#viewDocLink_PPan1").hide();
                $("#X-btn_PPan1").hide();
                $("#done-btn_PPan1").hide();
                $("#review-btn_PPan1").hide();
              } else if (doc[4].status == "done") {
                PPan1Status = "done";
                mainactionPPan1 = "upload";
                PStatusArray[4].status = PPan1Status;
                $("#choose_PPan1").hide();
                $("#X-btn_PPan1").hide();
                $("#done-btn_PPan1").show();
                $("#review-btn_PPan1").hide();
              } else {
                PPan1Status = "open";
                PStatusArray[4].status = PPan1Status;
              }

              if (doc[6].status == "remove") {
                mainactionPCopyOfRegistry = "upload";
                PCopyOfRegistryStatus = "remove";
                POptStatusArray[0].status = PCopyOfRegistryStatus;
                this.setState(
                  {
                    PCopyOfRegistryDocLink: doc[6].OwnershipDeed,
                  },
                  () => {
                    $("#viewDocLink_PCopyOfRegistry").css("display", "block");
                    $("#choose_PCopyOfRegistry").hide();
                    $("#X-btn_PCopyOfRegistry").show();
                    $("#done-btn_PCopyOfRegistry").hide();
                    $("#review-btn_PCopyOfRegistry").hide();
                  }
                );
              } else if (doc[6].status == "review") {
                mainactionPCopyOfRegistry = "upload";
                PCopyOfRegistryStatus = "review";
                POptStatusArray[0].status = PCopyOfRegistryStatus;
                this.setState(
                  {
                    PCopyOfRegistryDocLink: doc[6].OwnershipDeed,
                  },
                  () => {
                    $("#viewDocLink_PCopyOfRegistry").css("display", "block");
                    $("#choose_PCopyOfRegistry").hide();
                    $("#X-btn_PCopyOfRegistry").hide();
                    $("#done-btn_PCopyOfRegistry").hide();
                    $("#review-btn_PCopyOfRegistry").show();
                  }
                );
              } else if (doc[6].status == "reupload") {
                mainactionPCopyOfRegistry = "reupload";
                PCopyOfRegistryStatus = "reupload";
                POptStatusArray[0].status = PCopyOfRegistryStatus;
                $("#choose_PCopyOfRegistry").show();
                $("#viewDocLink_PCopyOfRegistry").hide();
                $("#X-btn_PCopyOfRegistry").hide();
                $("#done-btn_PCopyOfRegistry").hide();
                $("#review-btn_PCopyOfRegistry").hide();
              } else if (doc[6].status == "done") {
                PCopyOfRegistryStatus = "done";
                mainactionPCopyOfRegistry = "upload";
                POptStatusArray[0].status = PCopyOfRegistryStatus;
                $("#choose_PCopyOfRegistry").hide();
                $("#X-btn_PCopyOfRegistry").hide();
                $("#done-btn_PCopyOfRegistry").show();
                $("#review-btn_PCopyOfRegistry").hide();
              } else {
                PCopyOfRegistryStatus = "open";
                POptStatusArray[0].status = PCopyOfRegistryStatus;
              }
              if (doc[5].status == "remove") {
                mainactionPRentAgreement = "upload";
                PRentAgreementStatus = "remove";
                POptStatusArray[1].status = PRentAgreementStatus;
                this.setState(
                  {
                    PRentAgreementDocLink: doc[5].RentAgreement,
                  },
                  () => {
                    $("#viewDocLink_PRentAgreement").css("display", "block");
                    $("#choose_PRentAgreement").hide();
                    $("#X-btn_PRentAgreement").show();
                    $("#done-btn_PRentAgreement").hide();
                    $("#review-btn_PRentAgreement").hide();
                  }
                );
              } else if (doc[5].status == "review") {
                mainactionPRentAgreement = "upload";
                PRentAgreementStatus = "review";
                POptStatusArray[1].status = PRentAgreementStatus;
                this.setState(
                  {
                    PRentAgreementDocLink: doc[5].RentAgreement,
                  },
                  () => {
                    $("#viewDocLink_PRentAgreement").css("display", "block");
                    $("#choose_PRentAgreement").hide();
                    $("#X-btn_PRentAgreement").hide();
                    $("#done-btn_PRentAgreement").hide();
                    $("#review-btn_PRentAgreement").show();
                  }
                );
              } else if (doc[5].status == "reupload") {
                mainactionPRentAgreement = "reupload";
                PRentAgreementStatus = "reupload";
                POptStatusArray[1].status = PRentAgreementStatus;
                $("#choose_PRentAgreement").show();
                $("#viewDocLink_PRentAgreement").hide();
                $("#X-btn_PRentAgreement").hide();
                $("#done-btn_PRentAgreement").hide();
                $("#review-btn_PRentAgreement").hide();
              } else if (doc[5].status == "done") {
                PRentAgreementStatus = "done";
                mainactionPRentAgreement = "upload";
                POptStatusArray[1].status = PRentAgreementStatus;
                $("#choose_PRentAgreement").hide();
                $("#X-btn_PRentAgreement").hide();
                $("#done-btn_PRentAgreement").show();
                $("#review-btn_PRentAgreement").hide();
              } else {
                PRentAgreementStatus = "open";
                POptStatusArray[1].status = PRentAgreementStatus;
              }

              if (doc[7].status == "remove") {
                mainactionPPUnit = "upload";
                PPUnitStatus = "remove";
                PStatusArray[5].status = PPUnitStatus;
                this.setState(
                  {
                    PPUnitDocLink: doc[7].ProductionUnitPhoto,
                  },
                  () => {
                    $("#viewDocLink_PPUnit").css("display", "block");
                    $("#choose_PPUnit").hide();
                    $("#X-btn_PPUnit").show();
                    $("#done-btn_PPUnit").hide();
                    $("#review-btn_PPUnit").hide();
                  }
                );
              } else if (doc[7].status == "review") {
                mainactionPPUnit = "upload";
                PPUnitStatus = "review";
                PStatusArray[5].status = PPUnitStatus;
                this.setState(
                  {
                    PPUnitDocLink: doc[7].ProductionUnitPhoto,
                  },
                  () => {
                    $("#viewDocLink_PPUnit").css("display", "block");
                    $("#choose_PPUnit").hide();
                    $("#X-btn_PPUnit").hide();
                    $("#done-btn_PPUnit").hide();
                    $("#review-btn_PPUnit").show();
                  }
                );
              } else if (doc[7].status == "reupload") {
                mainactionPPUnit = "reupload";
                PPUnitStatus = "reupload";
                PStatusArray[5].status = PPUnitStatus;
                $("#choose_PPUnit").show();
                $("#viewDocLink_PPUnit").hide();
                $("#X-btn_PPUnit").hide();
                $("#done-btn_PPUnit").hide();
                $("#review-btn_PPUnit").hide();
              } else if (doc[7].status == "done") {
                PPUnitStatus = "done";
                mainactionPPUnit = "upload";
                PStatusArray[5].status = PPUnitStatus;
                $("#choose_PPUnit").hide();
                $("#X-btn_PPUnit").hide();
                $("#done-btn_PPUnit").show();
                $("#review-btn_PPUnit").hide();
              } else {
                PPUnitStatus = "open";
                PStatusArray[5].status = PPUnitStatus;
              }

              if (doc[8].status == "remove") {
                mainactionPLayoutPlan = "upload";
                PLayoutPlanStatus = "remove";
                PStatusArray[6].status = PLayoutPlanStatus;
                this.setState(
                  {
                    PLayoutPlanDocLink: doc[8].LayoutPlanUnit,
                  },
                  () => {
                    $("#viewDocLink_PLayoutPlan").css("display", "block");
                    $("#choose_PLayoutPlan").hide();
                    $("#X-btn_PLayoutPlan").show();
                    $("#done-btn_PLayoutPlan").hide();
                    $("#review-btn_PLayoutPlan").hide();
                  }
                );
              } else if (doc[8].status == "review") {
                mainactionPLayoutPlan = "upload";
                PLayoutPlanStatus = "review";
                PStatusArray[6].status = PLayoutPlanStatus;
                this.setState(
                  {
                    PLayoutPlanDocLink: doc[8].LayoutPlanUnit,
                  },
                  () => {
                    $("#viewDocLink_PLayoutPlan").css("display", "block");
                    $("#choose_PLayoutPlan").hide();
                    $("#X-btn_PLayoutPlan").hide();
                    $("#done-btn_PLayoutPlan").hide();
                    $("#review-btn_PLayoutPlan").show();
                  }
                );
              } else if (doc[8].status == "reupload") {
                mainactionPLayoutPlan = "reupload";
                PLayoutPlanStatus = "reupload";
                PStatusArray[6].status = PLayoutPlanStatus;
                $("#choose_PLayoutPlan").show();
                $("#viewDocLink_PLayoutPlan").hide();
                $("#X-btn_PLayoutPlan").hide();
                $("#done-btn_PLayoutPlan").hide();
                $("#review-btn_PLayoutPlan").hide();
              } else if (doc[8].status == "done") {
                PLayoutPlanStatus = "done";
                mainactionPLayoutPlan = "upload";
                PStatusArray[6].status = PLayoutPlanStatus;
                $("#choose_PLayoutPlan").hide();
                $("#X-btn_PLayoutPlan").hide();
                $("#done-btn_PLayoutPlan").show();
                $("#review-btn_PLayoutPlan").hide();
              } else {
                PLayoutPlanStatus = "open";
                PStatusArray[6].status = PLayoutPlanStatus;
              }

              if (doc[9].status == "remove") {
                mainactionPListofEquip = "upload";
                PListofEquipStatus = "remove";
                PStatusArray[7].status = PListofEquipStatus;
                this.setState(
                  {
                    PListofEquipDocLink: doc[9].ListOfEquipment,
                  },
                  () => {
                    $("#viewDocLink_PListofEquip").css("display", "block");
                    $("#choose_PListofEquip").hide();
                    $("#X-btn_PListofEquip").show();
                    $("#done-btn_PListofEquip").hide();
                    $("#review-btn_PListofEquip").hide();
                  }
                );
              } else if (doc[9].status == "review") {
                mainactionPListofEquip = "upload";
                PListofEquipStatus = "review";
                PStatusArray[7].status = PListofEquipStatus;
                this.setState(
                  {
                    PListofEquipDocLink: doc[9].ListOfEquipment,
                  },
                  () => {
                    $("#viewDocLink_PListofEquip").css("display", "block");
                    $("#choose_PListofEquip").hide();
                    $("#X-btn_PListofEquip").hide();
                    $("#done-btn_PListofEquip").hide();
                    $("#review-btn_PListofEquip").show();
                  }
                );
              } else if (doc[9].status == "reupload") {
                mainactionPListofEquip = "reupload";
                PListofEquipStatus = "reupload";
                PStatusArray[7].status = PListofEquipStatus;
                $("#choose_PListofEquip").show();
                $("#viewDocLink_PListofEquip").hide();
                $("#X-btn_PListofEquip").hide();
                $("#done-btn_PListofEquip").hide();
                $("#review-btn_PListofEquip").hide();
              } else if (doc[9].status == "done") {
                PListofEquipStatus = "done";
                mainactionPListofEquip = "upload";
                PStatusArray[7].status = PListofEquipStatus;
                $("#choose_PListofEquip").hide();
                $("#X-btn_PListofEquip").hide();
                $("#done-btn_PListofEquip").show();
                $("#review-btn_PListofEquip").hide();
              } else {
                PListofEquipStatus = "open";
                PStatusArray[7].status = PListofEquipStatus;
              }

              if (doc[10].status == "remove") {
                mainactionPWAReport = "upload";
                PWAReportStatus = "remove";
                PStatusArray[8].status = PWAReportStatus;
                this.setState(
                  {
                    PWAReportDocLink: doc[10].WaterAnalysisReport,
                  },
                  () => {
                    $("#viewDocLink_PWAReport").css("display", "block");
                    $("#choose_PWAReport").hide();
                    $("#X-btn_PWAReport").show();
                    $("#done-btn_PWAReport").hide();
                    $("#review-btn_PWAReport").hide();
                  }
                );
              } else if (doc[10].status == "review") {
                mainactionPWAReport = "upload";
                PWAReportStatus = "review";
                PStatusArray[8].status = PWAReportStatus;
                this.setState(
                  {
                    PWAReportDocLink: doc[10].WaterAnalysisReport,
                  },
                  () => {
                    $("#viewDocLink_PWAReport").css("display", "block");
                    $("#choose_PWAReport").hide();
                    $("#X-btn_PWAReport").hide();
                    $("#done-btn_PWAReport").hide();
                    $("#review-btn_PWAReport").show();
                  }
                );
              } else if (doc[10].status == "reupload") {
                mainactionPWAReport = "reupload";
                PWAReportStatus = "reupload";
                PStatusArray[8].status = PWAReportStatus;
                $("#choose_PWAReport").show();
                $("#viewDocLink_PWAReport").hide();
                $("#X-btn_PWAReport").hide();
                $("#done-btn_PWAReport").hide();
                $("#review-btn_PWAReport").hide();
              } else if (doc[10].status == "done") {
                PWAReportStatus = "done";
                mainactionPWAReport = "upload";
                PStatusArray[8].status = PWAReportStatus;
                $("#choose_PWAReport").hide();
                $("#X-btn_PWAReport").hide();
                $("#done-btn_PWAReport").show();
                $("#review-btn_PWAReport").hide();
              } else {
                PWAReportStatus = "open";
                PStatusArray[8].status = PWAReportStatus;
              }

              if (doc[11].status == "remove") {
                mainactionPListofDir = "upload";
                PListofDirStatus = "remove";
                PStatusArray[9].status = PListofDirStatus;
                this.setState(
                  {
                    PListofDirDocLink: doc[11].DirectorsFullAddress,
                  },
                  () => {
                    $("#viewDocLink_PListofDir").css("display", "block");
                    $("#choose_PListofDir").hide();
                    $("#X-btn_PListofDir").show();
                    $("#done-btn_PListofDir").hide();
                    $("#review-btn_PListofDir").hide();
                  }
                );
              } else if (doc[11].status == "review") {
                mainactionPListofDir = "upload";
                PListofDirStatus = "review";
                PStatusArray[9].status = PListofDirStatus;
                this.setState(
                  {
                    PListofDirDocLink: doc[11].DirectorsFullAddress,
                  },
                  () => {
                    $("#viewDocLink_PListofDir").css("display", "block");
                    $("#choose_PListofDir").hide();
                    $("#X-btn_PListofDir").hide();
                    $("#done-btn_PListofDir").hide();
                    $("#review-btn_PListofDir").show();
                  }
                );
              } else if (doc[11].status == "reupload") {
                mainactionPListofDir = "reupload";
                PListofDirStatus = "reupload";
                PStatusArray[9].status = PListofDirStatus;
                $("#choose_PListofDir").show();
                $("#viewDocLink_PListofDir").hide();
                $("#X-btn_PListofDir").hide();
                $("#done-btn_PListofDir").hide();
                $("#review-btn_PListofDir").hide();
              } else if (doc[11].status == "done") {
                PListofDirStatus = "done";
                mainactionPListofDir = "upload";
                PStatusArray[9].status = PListofDirStatus;
                $("#choose_PListofDir").hide();
                $("#X-btn_PListofDir").hide();
                $("#done-btn_PListofDir").show();
                $("#review-btn_PListofDir").hide();
              } else {
                PListofDirStatus = "open";
                PStatusArray[9].status = PListofDirStatus;
              }
              if (doc[12].status == "remove") {
                mainactionPRecallPlan = "upload";
                PRecallPlanStatus = "remove";
                PStatusArray[10].status = PRecallPlanStatus;
                this.setState(
                  {
                    PRecallPlanDocLink: doc[12].RecallPlan,
                  },
                  () => {
                    $("#viewDocLink_PRecallPlan").css("display", "block");
                    $("#choose_PRecallPlan").hide();
                    $("#X-btn_PRecallPlan").show();
                    $("#done-btn_PRecallPlan").hide();
                    $("#review-btn_PRecallPlan").hide();
                  }
                );
              } else if (doc[12].status == "review") {
                mainactionPRecallPlan = "upload";
                PRecallPlanStatus = "review";
                PStatusArray[10].status = PRecallPlanStatus;
                this.setState(
                  {
                    PRecallPlanDocLink: doc[12].RecallPlan,
                  },
                  () => {
                    $("#viewDocLink_PRecallPlan").css("display", "block");
                    $("#choose_PRecallPlan").hide();
                    $("#X-btn_PRecallPlan").hide();
                    $("#done-btn_PRecallPlan").hide();
                    $("#review-btn_PRecallPlan").show();
                  }
                );
              } else if (doc[12].status == "reupload") {
                mainactionPRecallPlan = "reupload";
                PRecallPlanStatus = "reupload";
                PStatusArray[10].status = PRecallPlanStatus;
                $("#choose_PRecallPlan").show();
                $("#viewDocLink_PRecallPlan").hide();
                $("#X-btn_PRecallPlan").hide();
                $("#done-btn_PRecallPlan").hide();
                $("#review-btn_PRecallPlan").hide();
              } else if (doc[12].status == "done") {
                PRecallPlanStatus = "done";
                mainactionPRecallPlan = "upload";
                PStatusArray[10].status = PRecallPlanStatus;
                $("#choose_PRecallPlan").hide();
                $("#X-btn_PRecallPlan").hide();
                $("#done-btn_PRecallPlan").show();
                $("#review-btn_PRecallPlan").hide();
              } else {
                PRecallPlanStatus = "open";
                PStatusArray[10].status = PRecallPlanStatus;
              }
            }
            this.PcheckRemainingReuploads();
            this.checkhandlePProceedBtn();
          } else if (res.data.serviceType == "FL_PRL_PL_OP_Documents") {
            $(".selectcateselect").attr("disabled", true);
            this.setState(
              {
                dropDownValue: "FL_PRL_PL_OP_Documents",
                serviceType: "FL_PRL_PL_OP_Documents",
              },
              () => {
                if (this.state.dropDownValue == "FL_PRL_PL_OP_Documents") {
                  $("#FoodLicense_default_Img").hide();
                  $("#FoodLicense_SolePI").hide();
                  $("#FoodLicense_P_LLP").hide();
                  $("#FoodLicense_PL").show();
                  $("#FoodLicense_HUF").hide();
                  $("#FoodLicense_Society").hide();
                }
              }
            );
            if (res.data.informationStatus == "lock") {
              if (res.data.addDocInformation.length == 0) {
                $("#PLadditionalDocumentDiv").hide();
              } else {
                $("#PLAdddocumentbtn").hide();
              }
            }
            if (res.data.addDocInformation.length > 0) {
              this.setState(
                {
                  PLadditionalFiles: [...res.data.addDocInformation],
                  PLAdd_DocName: "File Name",
                  api_PLadditionalFiles: "Yes",
                },
                () => { }
              );
            } else if (
              res.data.addDocInformation.length == 0 &&
              res.data.informationStatus != "lock"
            ) {
              this.setState({
                PLAdd_DocName: "",
                PLadditionalFiles: [1],
                api_PLadditionalFiles: "No",
              });
            }
            if (res.data.inputFields.length > 0) {
              if (
                res.data.inputFields[0].status == "review" ||
                res.data.inputFields[0].status == "done"
              ) {
                this.setState({
                  PLbusinessNature: res.data.inputFields[0].Nature,
                });
                $(".uploadtextarea").attr("disabled", true);
              }
              if (
                res.data.inputFields[1].AddressProofType == "CopyOfRegistry"
              ) {
                this.setState(
                  {
                    PLAddressProofType_radio: "CopyOfRegistry",
                  },
                  () => {
                    $(".CopyOfRegistry").css("display", "flex");
                    $(".RentAgreement").hide();
                  }
                );
              } else if (
                res.data.inputFields[1].AddressProofType == "RentAgreement"
              ) {
                this.setState(
                  {
                    PLAddressProofType_radio: "RentAgreement",
                  },
                  () => {
                    $(".RentAgreement").css("display", "flex");
                    $(".CopyOfRegistry").hide();
                  }
                );
              }
            }
            if (res.data.documents.length > 0) {
              let doc = res.data.documents;
              if (doc[2].status == "remove") {
                mainactionPLAadhar1 = "upload";
                PLAadhar1Status = "remove";
                PLStatusArray[0].status = PLAadhar1Status;
                this.setState(
                  {
                    PLAadhar1DocLink: doc[2].IDProofApplicant1,
                  },
                  () => {
                    $("#viewDocLink_PLAadhar1").css("display", "block");
                    $("#choose_PLAadhar1").hide();
                    $("#X-btn_PLAadhar1").show();
                    $("#done-btn_PLAadhar1").hide();
                    $("#review-btn_PLAadhar1").hide();
                  }
                );
              } else if (doc[2].status == "review") {
                mainactionPLAadhar1 = "upload";
                PLAadhar1Status = "review";
                PLStatusArray[0].status = PLAadhar1Status;
                this.setState(
                  {
                    PLAadhar1DocLink: doc[2].IDProofApplicant1,
                  },
                  () => {
                    $("#viewDocLink_PLAadhar1").css("display", "block");
                    $("#choose_PLAadhar1").hide();
                    $("#X-btn_PLAadhar1").hide();
                    $("#done-btn_PLAadhar1").hide();
                    $("#review-btn_PLAadhar1").show();
                  }
                );
              } else if (doc[2].status == "reupload") {
                mainactionPLAadhar1 = "reupload";
                PLAadhar1Status = "reupload";
                PLStatusArray[0].status = PLAadhar1Status;
                $("#choose_PLAadhar1").show();
                $("#viewDocLink_PLAadhar1").hide();
                $("#X-btn_PLAadhar1").hide();
                $("#done-btn_PLAadhar1").hide();
                $("#review-btn_PLAadhar1").hide();
              } else if (doc[2].status == "done") {
                PLAadhar1Status = "done";
                mainactionPLAadhar1 = "upload";
                PLStatusArray[0].status = PLAadhar1Status;
                $("#choose_PLAadhar1").hide();
                $("#X-btn_PLAadhar1").hide();
                $("#done-btn_PLAadhar1").show();
                $("#review-btn_PLAadhar1").hide();
              } else {
                PLAadhar1Status = "open";
                PLStatusArray[0].status = PLAadhar1Status;
              }
              if (doc[3].status == "remove") {
                mainactionPLAadhar2 = "upload";
                PLAadhar2Status = "remove";
                PLStatusArray[1].status = PLAadhar2Status;
                this.setState(
                  {
                    PLAadhar2DocLink: doc[3].IDProofApplicant2,
                  },
                  () => {
                    $("#viewDocLink_PLAadhar2").css("display", "block");
                    $("#choose_PLAadhar2").hide();
                    $("#X-btn_PLAadhar2").show();
                    $("#done-btn_PLAadhar2").hide();
                    $("#review-btn_PLAadhar2").hide();
                  }
                );
              } else if (doc[3].status == "review") {
                mainactionPLAadhar2 = "upload";
                PLAadhar2Status = "review";
                PLStatusArray[1].status = PLAadhar2Status;
                this.setState(
                  {
                    PLAadhar2DocLink: doc[3].IDProofApplicant2,
                  },
                  () => {
                    $("#viewDocLink_PLAadhar2").css("display", "block");
                    $("#choose_PLAadhar2").hide();
                    $("#X-btn_PLAadhar2").hide();
                    $("#done-btn_PLAadhar2").hide();
                    $("#review-btn_PLAadhar2").show();
                  }
                );
              } else if (doc[3].status == "reupload") {
                mainactionPLAadhar2 = "reupload";
                PLAadhar2Status = "reupload";
                PLStatusArray[1].status = PLAadhar2Status;
                $("#choose_PLAadhar2").show();
                $("#viewDocLink_PLAadhar2").hide();
                $("#X-btn_PLAadhar2").hide();
                $("#done-btn_PLAadhar2").hide();
                $("#review-btn_PLAadhar2").hide();
              } else if (doc[3].status == "done") {
                PLAadhar2Status = "done";
                mainactionPLAadhar2 = "upload";
                PLStatusArray[1].status = PLAadhar2Status;
                $("#choose_PLAadhar2").hide();
                $("#X-btn_PLAadhar2").hide();
                $("#done-btn_PLAadhar2").show();
                $("#review-btn_PLAadhar2").hide();
              } else {
                PLAadhar2Status = "open";
                PLStatusArray[1].status = PLAadhar2Status;
              }
              if (doc[0].status == "remove") {
                mainactionPLPic1 = "upload";
                PLPic1Status = "remove";
                PLStatusArray[2].status = PLPic1Status;
                this.setState(
                  {
                    PLPic1DocLink: doc[0].ApplicantPhoto1,
                  },
                  () => {
                    $("#viewDocLink_PLPic1").css("display", "block");
                    $("#choose_PLPic1").hide();
                    $("#X-btn_PLPic1").show();
                    $("#done-btn_PLPic1").hide();
                    $("#review-btn_PLPic1").hide();
                  }
                );
              } else if (doc[0].status == "review") {
                mainactionPLPic1 = "upload";
                PLPic1Status = "review";
                PLStatusArray[2].status = PLPic1Status;
                this.setState(
                  {
                    PLPic1DocLink: doc[0].ApplicantPhoto1,
                  },
                  () => {
                    $("#viewDocLink_PLPic1").css("display", "block");
                    $("#choose_PLPic1").hide();
                    $("#X-btn_PLPic1").hide();
                    $("#done-btn_PLPic1").hide();
                    $("#review-btn_PLPic1").show();
                  }
                );
              } else if (doc[0].status == "reupload") {
                mainactionPLPic1 = "reupload";
                PLPic1Status = "reupload";
                PLStatusArray[2].status = PLPic1Status;
                $("#choose_PLPic1").show();
                $("#viewDocLink_PLPic1").hide();
                $("#X-btn_PLPic1").hide();
                $("#done-btn_PLPic1").hide();
                $("#review-btn_PLPic1").hide();
              } else if (doc[0].status == "done") {
                PLPic1Status = "done";
                mainactionPLPic1 = "upload";
                PLStatusArray[2].status = PLPic1Status;
                $("#choose_PLPic1").hide();
                $("#X-btn_PLPic1").hide();
                $("#done-btn_PLPic1").show();
                $("#review-btn_PLPic1").hide();
              } else {
                PLPic1Status = "open";
                PLStatusArray[2].status = PLPic1Status;
              }
              if (doc[1].status == "remove") {
                mainactionPLPic2 = "upload";
                PLPic2Status = "remove";
                PLStatusArray[3].status = PLPic2Status;
                this.setState(
                  {
                    PLPic2DocLink: doc[1].ApplicantPhoto2,
                  },
                  () => {
                    $("#viewDocLink_PLPic2").css("display", "block");
                    $("#choose_PLPic2").hide();
                    $("#X-btn_PLPic2").show();
                    $("#done-btn_PLPic2").hide();
                    $("#review-btn_PLPic2").hide();
                  }
                );
              } else if (doc[1].status == "review") {
                mainactionPLPic2 = "upload";
                PLPic2Status = "review";
                PLStatusArray[3].status = PLPic2Status;
                this.setState(
                  {
                    PLPic2DocLink: doc[1].ApplicantPhoto2,
                  },
                  () => {
                    $("#viewDocLink_PLPic2").css("display", "block");
                    $("#choose_PLPic2").hide();
                    $("#X-btn_PLPic2").hide();
                    $("#done-btn_PLPic2").hide();
                    $("#review-btn_PLPic2").show();
                  }
                );
              } else if (doc[1].status == "reupload") {
                mainactionPLPic2 = "reupload";
                PLPic2Status = "reupload";
                PLStatusArray[3].status = PLPic2Status;
                $("#choose_PLPic2").show();
                $("#viewDocLink_PLPic2").hide();
                $("#X-btn_PLPic2").hide();
                $("#done-btn_PLPic2").hide();
                $("#review-btn_PLPic2").hide();
              } else if (doc[1].status == "done") {
                PLPic2Status = "done";
                mainactionPLPic2 = "upload";
                PLStatusArray[3].status = PLPic2Status;
                $("#choose_PLPic2").hide();
                $("#X-btn_PLPic2").hide();
                $("#done-btn_PLPic2").show();
                $("#review-btn_PLPic2").hide();
              } else {
                PLPic2Status = "open";
                PLStatusArray[3].status = PLPic2Status;
              }
              if (doc[4].status == "remove") {
                mainactionPLCI = "upload";
                PLCIStatus = "remove";
                PLStatusArray[4].status = PLCIStatus;
                this.setState(
                  {
                    PLCIDocLink: doc[4].CertificateOfIncorporation,
                  },
                  () => {
                    $("#viewDocLink_PLCI").css("display", "block");
                    $("#choose_PLCI").hide();
                    $("#X-btn_PLCI").show();
                    $("#done-btn_PLCI").hide();
                    $("#review-btn_PLCI").hide();
                  }
                );
              } else if (doc[4].status == "review") {
                mainactionPLCI = "upload";
                PLCIStatus = "review";
                PLStatusArray[4].status = PLCIStatus;
                this.setState(
                  {
                    PLCIDocLink: doc[4].CertificateOfIncorporation,
                  },
                  () => {
                    $("#viewDocLink_PLCI").css("display", "block");
                    $("#choose_PLCI").hide();
                    $("#X-btn_PLCI").hide();
                    $("#done-btn_PLCI").hide();
                    $("#review-btn_PLCI").show();
                  }
                );
              } else if (doc[4].status == "reupload") {
                mainactionPLCI = "reupload";
                PLCIStatus = "reupload";
                PLStatusArray[4].status = PLCIStatus;
                $("#choose_PLCI").show();
                $("#viewDocLink_PLCI").hide();
                $("#X-btn_PLCI").hide();
                $("#done-btn_PLCI").hide();
                $("#review-btn_PLCI").hide();
              } else if (doc[4].status == "done") {
                PLCIStatus = "done";
                mainactionPLCI = "upload";
                PLStatusArray[4].status = PLCIStatus;
                $("#choose_PLCI").hide();
                $("#X-btn_PLCI").hide();
                $("#done-btn_PLCI").show();
                $("#review-btn_PLCI").hide();
              } else {
                PLCIStatus = "open";
                PLStatusArray[4].status = PLCIStatus;
              }
              if (doc[5].status == "remove") {
                mainactionPLMOA = "upload";
                PLMOAStatus = "remove";
                PLStatusArray[5].status = PLMOAStatus;
                this.setState(
                  {
                    PLMOADocLink: doc[5].MOA,
                  },
                  () => {
                    $("#viewDocLink_PLMOA").css("display", "block");
                    $("#choose_PLMOA").hide();
                    $("#X-btn_PLMOA").show();
                    $("#done-btn_PLMOA").hide();
                    $("#review-btn_PLMOA").hide();
                  }
                );
              } else if (doc[5].status == "review") {
                mainactionPLMOA = "upload";
                PLMOAStatus = "review";
                PLStatusArray[5].status = PLMOAStatus;
                this.setState(
                  {
                    PLMOADocLink: doc[5].MOA,
                  },
                  () => {
                    $("#viewDocLink_PLMOA").css("display", "block");
                    $("#choose_PLMOA").hide();
                    $("#X-btn_PLMOA").hide();
                    $("#done-btn_PLMOA").hide();
                    $("#review-btn_PLMOA").show();
                  }
                );
              } else if (doc[5].status == "reupload") {
                mainactionPLMOA = "reupload";
                PLMOAStatus = "reupload";
                PLStatusArray[5].status = PLMOAStatus;
                $("#choose_PLMOA").show();
                $("#viewDocLink_PLMOA").hide();
                $("#X-btn_PLMOA").hide();
                $("#done-btn_PLMOA").hide();
                $("#review-btn_PLMOA").hide();
              } else if (doc[5].status == "done") {
                PLMOAStatus = "done";
                mainactionPLMOA = "upload";
                PLStatusArray[5].status = PLMOAStatus;
                $("#choose_PLMOA").hide();
                $("#X-btn_PLMOA").hide();
                $("#done-btn_PLMOA").show();
                $("#review-btn_PLMOA").hide();
              } else {
                PLMOAStatus = "open";
                PLStatusArray[5].status = PLMOAStatus;
              }
              if (doc[6].status == "remove") {
                mainactionPLAOA = "upload";
                PLAOAStatus = "remove";
                PLStatusArray[6].status = PLAOAStatus;
                this.setState(
                  {
                    PLAOADocLink: doc[6].AOA,
                  },
                  () => {
                    $("#viewDocLink_PLAOA").css("display", "block");
                    $("#choose_PLAOA").hide();
                    $("#X-btn_PLAOA").show();
                    $("#done-btn_PLAOA").hide();
                    $("#review-btn_PLAOA").hide();
                  }
                );
              } else if (doc[6].status == "review") {
                mainactionPLAOA = "upload";
                PLAOAStatus = "review";
                PLStatusArray[6].status = PLAOAStatus;
                this.setState(
                  {
                    PLAOADocLink: doc[6].AOA,
                  },
                  () => {
                    $("#viewDocLink_PLAOA").css("display", "block");
                    $("#choose_PLAOA").hide();
                    $("#X-btn_PLAOA").hide();
                    $("#done-btn_PLAOA").hide();
                    $("#review-btn_PLAOA").show();
                  }
                );
              } else if (doc[6].status == "reupload") {
                mainactionPLAOA = "reupload";
                PLAOAStatus = "reupload";
                PLStatusArray[6].status = PLAOAStatus;
                $("#choose_PLAOA").show();
                $("#viewDocLink_PLAOA").hide();
                $("#X-btn_PLAOA").hide();
                $("#done-btn_PLAOA").hide();
                $("#review-btn_PLAOA").hide();
              } else if (doc[6].status == "done") {
                PLAOAStatus = "done";
                mainactionPLAOA = "upload";
                PLStatusArray[6].status = PLAOAStatus;
                $("#choose_PLAOA").hide();
                $("#X-btn_PLAOA").hide();
                $("#done-btn_PLAOA").show();
                $("#review-btn_PLAOA").hide();
              } else {
                PLAOAStatus = "open";
                PLStatusArray[6].status = PLAOAStatus;
              }
              if (doc[7].status == "remove") {
                mainactionPLCopyOfRegistry = "upload";
                PLCopyOfRegistryStatus = "remove";
                PLOptStatusArray[0].status = PLCopyOfRegistryStatus;
                this.setState(
                  {
                    PLCopyOfRegistryDocLink: doc[7].OwnershipDeed,
                  },
                  () => {
                    $("#viewDocLink_PLCopyOfRegistry").css("display", "block");
                    $("#choose_PLCopyOfRegistry").hide();
                    $("#X-btn_PLCopyOfRegistry").show();
                    $("#done-btn_PLCopyOfRegistry").hide();
                    $("#review-btn_PLCopyOfRegistry").hide();
                  }
                );
              } else if (doc[7].status == "review") {
                mainactionPLCopyOfRegistry = "upload";
                PLCopyOfRegistryStatus = "review";
                PLOptStatusArray[0].status = PLCopyOfRegistryStatus;
                this.setState(
                  {
                    PLCopyOfRegistryDocLink: doc[7].CopyOfRegistry,
                  },
                  () => {
                    $("#viewDocLink_PLCopyOfRegistry").css("display", "block");
                    $("#choose_PLCopyOfRegistry").hide();
                    $("#X-btn_PLCopyOfRegistry").hide();
                    $("#done-btn_PLCopyOfRegistry").hide();
                    $("#review-btn_PLCopyOfRegistry").show();
                  }
                );
              } else if (doc[7].status == "reupload") {
                mainactionPLCopyOfRegistry = "reupload";
                PLCopyOfRegistryStatus = "reupload";
                PLOptStatusArray[0].status = PLCopyOfRegistryStatus;
                $("#choose_PLCopyOfRegistry").show();
                $("#viewDocLink_PLCopyOfRegistry").hide();
                $("#X-btn_PLCopyOfRegistry").hide();
                $("#done-btn_PLCopyOfRegistry").hide();
                $("#review-btn_PLCopyOfRegistry").hide();
              } else if (doc[7].status == "done") {
                PLCopyOfRegistryStatus = "done";
                mainactionPLCopyOfRegistry = "upload";
                PLOptStatusArray[0].status = PLCopyOfRegistryStatus;
                $("#choose_PLCopyOfRegistry").hide();
                $("#X-btn_PLCopyOfRegistry").hide();
                $("#done-btn_PLCopyOfRegistry").show();
                $("#review-btn_PLCopyOfRegistry").hide();
              } else {
                PLCopyOfRegistryStatus = "open";
                PLOptStatusArray[0].status = PLCopyOfRegistryStatus;
              }
              if (doc[8].status == "remove") {
                mainactionPLRentAgreement = "upload";
                PLRentAgreementStatus = "remove";
                PLOptStatusArray[1].status = PLRentAgreementStatus;
                this.setState(
                  {
                    PLRentAgreementDocLink: doc[8].RentAgreement,
                  },
                  () => {
                    $("#viewDocLink_PLRentAgreement").css("display", "block");
                    $("#choose_PLRentAgreement").hide();
                    $("#X-btn_PLRentAgreement").show();
                    $("#done-btn_PLRentAgreement").hide();
                    $("#review-btn_PLRentAgreement").hide();
                  }
                );
              } else if (doc[8].status == "review") {
                mainactionPLRentAgreement = "upload";
                PLRentAgreementStatus = "review";
                PLOptStatusArray[1].status = PLRentAgreementStatus;
                this.setState(
                  {
                    PLRentAgreementDocLink: doc[8].RentAgreement,
                  },
                  () => {
                    $("#viewDocLink_PLRentAgreement").css("display", "block");
                    $("#choose_PLRentAgreement").hide();
                    $("#X-btn_PLRentAgreement").hide();
                    $("#done-btn_PLRentAgreement").hide();
                    $("#review-btn_PLRentAgreement").show();
                  }
                );
              } else if (doc[8].status == "reupload") {
                mainactionPLRentAgreement = "reupload";
                PLRentAgreementStatus = "reupload";
                PLOptStatusArray[1].status = PLRentAgreementStatus;
                $("#choose_PLRentAgreement").show();
                $("#viewDocLink_PLRentAgreement").hide();
                $("#X-btn_PLRentAgreement").hide();
                $("#done-btn_PLRentAgreement").hide();
                $("#review-btn_PLRentAgreement").hide();
              } else if (doc[8].status == "done") {
                PLRentAgreementStatus = "done";
                mainactionPLRentAgreement = "upload";
                PLOptStatusArray[1].status = PLRentAgreementStatus;
                $("#choose_PLRentAgreement").hide();
                $("#X-btn_PLRentAgreement").hide();
                $("#done-btn_PLRentAgreement").show();
                $("#review-btn_PLRentAgreement").hide();
              } else {
                PLRentAgreementStatus = "open";
                PLOptStatusArray[1].status = PLRentAgreementStatus;
              }
              if (doc[9].status == "remove") {
                mainactionPLPUnit = "upload";
                PLPUnitStatus = "remove";
                PLStatusArray[7].status = PLPUnitStatus;
                this.setState(
                  {
                    PLPUnitDocLink: doc[9].ProductionUnitPhoto,
                  },
                  () => {
                    $("#viewDocLink_PLPUnit").css("display", "block");
                    $("#choose_PLPUnit").hide();
                    $("#X-btn_PLPUnit").show();
                    $("#done-btn_PLPUnit").hide();
                    $("#review-btn_PLPUnit").hide();
                  }
                );
              } else if (doc[9].status == "review") {
                mainactionPLPUnit = "upload";
                PLPUnitStatus = "review";
                PLStatusArray[7].status = PLPUnitStatus;
                this.setState(
                  {
                    PLPUnitDocLink: doc[9].ProductionUnitPhoto,
                  },
                  () => {
                    $("#viewDocLink_PLPUnit").css("display", "block");
                    $("#choose_PLPUnit").hide();
                    $("#X-btn_PLPUnit").hide();
                    $("#done-btn_PLPUnit").hide();
                    $("#review-btn_PLPUnit").show();
                  }
                );
              } else if (doc[9].status == "reupload") {
                mainactionPLPUnit = "reupload";
                PLPUnitStatus = "reupload";
                PLStatusArray[7].status = PLPUnitStatus;
                $("#choose_PLPUnit").show();
                $("#viewDocLink_PLPUnit").hide();
                $("#X-btn_PLPUnit").hide();
                $("#done-btn_PLPUnit").hide();
                $("#review-btn_PLPUnit").hide();
              } else if (doc[9].status == "done") {
                PLPUnitStatus = "done";
                mainactionPLPUnit = "upload";
                PLStatusArray[7].status = PLPUnitStatus;
                $("#choose_PLPUnit").hide();
                $("#X-btn_PLPUnit").hide();
                $("#done-btn_PLPUnit").show();
                $("#review-btn_PLPUnit").hide();
              } else {
                PLPUnitStatus = "open";
                PLStatusArray[7].status = PLPUnitStatus;
              }

              if (doc[10].status == "remove") {
                mainactionPLLayoutPlan = "upload";
                PLLayoutPlanStatus = "remove";
                PLStatusArray[8].status = PLLayoutPlanStatus;
                this.setState(
                  {
                    PLLayoutPlanDocLink: doc[10].LayoutPlanUnit,
                  },
                  () => {
                    $("#viewDocLink_PLLayoutPlan").css("display", "block");
                    $("#choose_PLLayoutPlan").hide();
                    $("#X-btn_PLLayoutPlan").show();
                    $("#done-btn_PLLayoutPlan").hide();
                    $("#review-btn_PLLayoutPlan").hide();
                  }
                );
              } else if (doc[10].status == "review") {
                mainactionPLLayoutPlan = "upload";
                PLLayoutPlanStatus = "review";
                PLStatusArray[8].status = PLLayoutPlanStatus;
                this.setState(
                  {
                    PLLayoutPlanDocLink: doc[10].LayoutPlanUnit,
                  },
                  () => {
                    $("#viewDocLink_PLLayoutPlan").css("display", "block");
                    $("#choose_PLLayoutPlan").hide();
                    $("#X-btn_PLLayoutPlan").hide();
                    $("#done-btn_PLLayoutPlan").hide();
                    $("#review-btn_PLLayoutPlan").show();
                  }
                );
              } else if (doc[10].status == "reupload") {
                mainactionPLLayoutPlan = "reupload";
                PLLayoutPlanStatus = "reupload";
                PLStatusArray[8].status = PLLayoutPlanStatus;
                $("#choose_PLLayoutPlan").show();
                $("#viewDocLink_PLLayoutPlan").hide();
                $("#X-btn_PLLayoutPlan").hide();
                $("#done-btn_PLLayoutPlan").hide();
                $("#review-btn_PLLayoutPlan").hide();
              } else if (doc[10].status == "done") {
                PLLayoutPlanStatus = "done";
                mainactionPLLayoutPlan = "upload";
                PLStatusArray[8].status = PLLayoutPlanStatus;
                $("#choose_PLLayoutPlan").hide();
                $("#X-btn_PLLayoutPlan").hide();
                $("#done-btn_PLLayoutPlan").show();
                $("#review-btn_PLLayoutPlan").hide();
              } else {
                PLLayoutPlanStatus = "open";
                PLStatusArray[8].status = PLLayoutPlanStatus;
              }

              if (doc[11].status == "remove") {
                mainactionPLListofEquip = "upload";
                PLListofEquipStatus = "remove";
                PLStatusArray[9].status = PLListofEquipStatus;
                this.setState(
                  {
                    PLListofEquipDocLink: doc[11].ListOfEquipment,
                  },
                  () => {
                    $("#viewDocLink_PLListofEquip").css("display", "block");
                    $("#choose_PLListofEquip").hide();
                    $("#X-btn_PLListofEquip").show();
                    $("#done-btn_PLListofEquip").hide();
                    $("#review-btn_PLListofEquip").hide();
                  }
                );
              } else if (doc[11].status == "review") {
                mainactionPLListofEquip = "upload";
                PLListofEquipStatus = "review";
                PLStatusArray[9].status = PLListofEquipStatus;
                this.setState(
                  {
                    PLListofEquipDocLink: doc[11].ListOfEquipment,
                  },
                  () => {
                    $("#viewDocLink_PLListofEquip").css("display", "block");
                    $("#choose_PLListofEquip").hide();
                    $("#X-btn_PLListofEquip").hide();
                    $("#done-btn_PLListofEquip").hide();
                    $("#review-btn_PLListofEquip").show();
                  }
                );
              } else if (doc[11].status == "reupload") {
                mainactionPLListofEquip = "reupload";
                PLListofEquipStatus = "reupload";
                PLStatusArray[9].status = PLListofEquipStatus;
                $("#choose_PLListofEquip").show();
                $("#viewDocLink_PLListofEquip").hide();
                $("#X-btn_PLListofEquip").hide();
                $("#done-btn_PLListofEquip").hide();
                $("#review-btn_PLListofEquip").hide();
              } else if (doc[11].status == "done") {
                PLListofEquipStatus = "done";
                mainactionPLListofEquip = "upload";
                PLStatusArray[9].status = PLListofEquipStatus;
                $("#choose_PLListofEquip").hide();
                $("#X-btn_PLListofEquip").hide();
                $("#done-btn_PLListofEquip").show();
                $("#review-btn_PLListofEquip").hide();
              } else {
                PLListofEquipStatus = "open";
                PLStatusArray[9].status = PLListofEquipStatus;
              }

              if (doc[12].status == "remove") {
                mainactionPLWAReport = "upload";
                PLWAReportStatus = "remove";
                PLStatusArray[10].status = PLWAReportStatus;
                this.setState(
                  {
                    PLWAReportDocLink: doc[12].WaterAnalysisReport,
                  },
                  () => {
                    $("#viewDocLink_PLWAReport").css("display", "block");
                    $("#choose_PLWAReport").hide();
                    $("#X-btn_PLWAReport").show();
                    $("#done-btn_PLWAReport").hide();
                    $("#review-btn_PLWAReport").hide();
                  }
                );
              } else if (doc[12].status == "review") {
                mainactionPLWAReport = "upload";
                PLWAReportStatus = "review";
                PLStatusArray[10].status = PLWAReportStatus;
                this.setState(
                  {
                    PLWAReportDocLink: doc[12].WaterAnalysisReport,
                  },
                  () => {
                    $("#viewDocLink_PLWAReport").css("display", "block");
                    $("#choose_PLWAReport").hide();
                    $("#X-btn_PLWAReport").hide();
                    $("#done-btn_PLWAReport").hide();
                    $("#review-btn_PLWAReport").show();
                  }
                );
              } else if (doc[12].status == "reupload") {
                mainactionPLWAReport = "reupload";
                PLWAReportStatus = "reupload";
                PLStatusArray[10].status = PLWAReportStatus;
                $("#choose_PLWAReport").show();
                $("#viewDocLink_PLWAReport").hide();
                $("#X-btn_PLWAReport").hide();
                $("#done-btn_PLWAReport").hide();
                $("#review-btn_PLWAReport").hide();
              } else if (doc[12].status == "done") {
                PLWAReportStatus = "done";
                mainactionPLWAReport = "upload";
                PLStatusArray[10].status = PLWAReportStatus;
                $("#choose_PLWAReport").hide();
                $("#X-btn_PLWAReport").hide();
                $("#done-btn_PLWAReport").show();
                $("#review-btn_PLWAReport").hide();
              } else {
                PLWAReportStatus = "open";
                PLStatusArray[10].status = PLWAReportStatus;
              }

              if (doc[13].status == "remove") {
                mainactionPLListofDir = "upload";
                PLListofDirStatus = "remove";
                PLStatusArray[11].status = PLListofDirStatus;
                this.setState(
                  {
                    PLListofDirDocLink: doc[13].DirectorsFullAddress,
                  },
                  () => {
                    $("#viewDocLink_PLListofDir").css("display", "block");
                    $("#choose_PLListofDir").hide();
                    $("#X-btn_PLListofDir").show();
                    $("#done-btn_PLListofDir").hide();
                    $("#review-btn_PLListofDir").hide();
                  }
                );
              } else if (doc[13].status == "review") {
                mainactionPLListofDir = "upload";
                PLListofDirStatus = "review";
                PLStatusArray[11].status = PLListofDirStatus;
                this.setState(
                  {
                    PLListofDirDocLink: doc[13].DirectorsFullAddress,
                  },
                  () => {
                    $("#viewDocLink_PLListofDir").css("display", "block");
                    $("#choose_PLListofDir").hide();
                    $("#X-btn_PLListofDir").hide();
                    $("#done-btn_PLListofDir").hide();
                    $("#review-btn_PLListofDir").show();
                  }
                );
              } else if (doc[13].status == "reupload") {
                mainactionPLListofDir = "reupload";
                PLListofDirStatus = "reupload";
                PLStatusArray[11].status = PLListofDirStatus;
                $("#choose_PLListofDir").show();
                $("#viewDocLink_PLListofDir").hide();
                $("#X-btn_PLListofDir").hide();
                $("#done-btn_PLListofDir").hide();
                $("#review-btn_PLListofDir").hide();
              } else if (doc[13].status == "done") {
                PLListofDirStatus = "done";
                mainactionPLListofDir = "upload";
                PLStatusArray[11].status = PLListofDirStatus;
                $("#choose_PLListofDir").hide();
                $("#X-btn_PLListofDir").hide();
                $("#done-btn_PLListofDir").show();
                $("#review-btn_PLListofDir").hide();
              } else {
                PLListofDirStatus = "open";
                PLStatusArray[11].status = PLListofDirStatus;
              }
              if (doc[14].status == "remove") {
                mainactionPLRecallPlan = "upload";
                PLRecallPlanStatus = "remove";
                PLStatusArray[12].status = PLRecallPlanStatus;
                this.setState(
                  {
                    PLRecallPlanDocLink: doc[14].RecallPlan,
                  },
                  () => {
                    $("#viewDocLink_PLRecallPlan").css("display", "block");
                    $("#choose_PLRecallPlan").hide();
                    $("#X-btn_PLRecallPlan").show();
                    $("#done-btn_PLRecallPlan").hide();
                    $("#review-btn_PLRecallPlan").hide();
                  }
                );
              } else if (doc[14].status == "review") {
                mainactionPLRecallPlan = "upload";
                PLRecallPlanStatus = "review";
                PLStatusArray[12].status = PLRecallPlanStatus;
                this.setState(
                  {
                    PLRecallPlanDocLink: doc[14].RecallPlan,
                  },
                  () => {
                    $("#viewDocLink_PLRecallPlan").css("display", "block");
                    $("#choose_PLRecallPlan").hide();
                    $("#X-btn_PLRecallPlan").hide();
                    $("#done-btn_PLRecallPlan").hide();
                    $("#review-btn_PLRecallPlan").show();
                  }
                );
              } else if (doc[14].status == "reupload") {
                mainactionPLRecallPlan = "reupload";
                PLRecallPlanStatus = "reupload";
                PLStatusArray[12].status = PLRecallPlanStatus;
                $("#choose_PLRecallPlan").show();
                $("#viewDocLink_PLRecallPlan").hide();
                $("#X-btn_PLRecallPlan").hide();
                $("#done-btn_PLRecallPlan").hide();
                $("#review-btn_PLRecallPlan").hide();
              } else if (doc[14].status == "done") {
                PLRecallPlanStatus = "done";
                mainactionPLRecallPlan = "upload";
                PLStatusArray[12].status = PLRecallPlanStatus;
                $("#choose_PLRecallPlan").hide();
                $("#X-btn_PLRecallPlan").hide();
                $("#done-btn_PLRecallPlan").show();
                $("#review-btn_PLRecallPlan").hide();
              } else {
                PLRecallPlanStatus = "open";
                PLStatusArray[12].status = PLRecallPlanStatus;
              }
            }
            if (res.data.inputFields.length > 0) {
              if (
                res.data.inputFields[0].AddressProofType == "CopyOfRegistry"
              ) {
                this.setState(
                  {
                    PLAddressProofType_radio: "CopyOfRegistry",
                  },
                  () => {
                    $(".CopyOfRegistry").css("display", "flex");
                    $(".RentAgreement").hide();
                  }
                );
              } else if (
                res.data.inputFields[0].AddressProofType == "RentAgreement"
              ) {
                this.setState(
                  {
                    PLAddressProofType_radio: "RentAgreement",
                  },
                  () => {
                    $(".RentAgreement").css("display", "flex");
                    $(".CopyOfRegistry").hide();
                  }
                );
              }
            }
            this.PLcheckRemainingReuploads();
            this.checkhandlePLProceedBtn();
          } else if (res.data.serviceType == "FL_HUF_Documents") {
            $(".selectcateselect").attr("disabled", true);
            this.setState(
              {
                dropDownValue: "FL_HUF_Documents",
                serviceType: "FL_HUF_Documents",
              },
              () => {
                if (this.state.dropDownValue == "FL_HUF_Documents") {
                  $("#FoodLicense_default_Img").hide();
                  $("#FoodLicense_SolePI").hide();
                  $("#FoodLicense_P_LLP").hide();
                  $("#FoodLicense_PL").hide();
                  $("#FoodLicense_HUF").show();
                  $("#FoodLicense_Society").hide();
                }
              }
            );
            if (res.data.informationStatus == "lock") {
              if (res.data.addDocInformation.length == 0) {
                $("#HUFadditionalDocumentDiv").hide();
              } else {
                $("#HUFAdddocumentbtn").hide();
              }
            }
            if (res.data.addDocInformation.length > 0) {
              this.setState(
                {
                  HUFadditionalFiles: [...res.data.addDocInformation],
                  HUFAdd_DocName: "File Name",
                  api_HUFadditionalFiles: "Yes",
                },
                () => { }
              );
            } else if (
              res.data.addDocInformation.length == 0 &&
              res.data.informationStatus != "lock"
            ) {
              this.setState({
                HUFAdd_DocName: "",
                HUFadditionalFiles: [1],
                api_HUFadditionalFiles: "No",
              });
            }
            if (res.data.inputFields.length > 0) {
              if (
                res.data.inputFields[0].status == "review" ||
                res.data.inputFields[0].status == "done"
              ) {
                this.setState({
                  HUFbusinessNature: res.data.inputFields[0].Nature,
                });
                $(".uploadtextarea").attr("disabled", true);
              }
              if (
                res.data.inputFields[1].AddressProofType == "CopyOfRegistry"
              ) {
                this.setState(
                  {
                    HUFAddressProofType_radio: "CopyOfRegistry",
                  },
                  () => {
                    $(".CopyOfRegistry").css("display", "flex");
                    $(".RentAgreement").hide();
                  }
                );
              } else if (
                res.data.inputFields[1].AddressProofType == "RentAgreement"
              ) {
                this.setState(
                  {
                    HUFAddressProofType_radio: "RentAgreement",
                  },
                  () => {
                    $(".RentAgreement").css("display", "flex");
                    $(".CopyOfRegistry").hide();
                  }
                );
              }
            }
            if (res.data.documents.length > 0) {
              let doc = res.data.documents;
              if (doc[0].status == "remove") {
                mainactionHUFAadhar = "upload";
                HUFAadharStatus = "remove";
                HUFStatusArray[0].status = HUFAadharStatus;
                this.setState(
                  {
                    HUFAadharDocLink: doc[0].IDProofApplicant,
                  },
                  () => {
                    $("#viewDocLink_HUFAadhar").css("display", "block");
                    $("#choose_HUFAadhar").hide();
                    $("#X-btn_HUFAadhar").show();
                    $("#done-btn_HUFAadhar").hide();
                    $("#review-btn_HUFAadhar").hide();
                  }
                );
              } else if (doc[0].status == "review") {
                mainactionHUFAadhar = "upload";
                HUFAadharStatus = "review";
                HUFStatusArray[0].status = HUFAadharStatus;
                this.setState(
                  {
                    HUFAadharDocLink: doc[0].IDProofApplicant,
                  },
                  () => {
                    $("#viewDocLink_HUFAadhar").css("display", "block");
                    $("#choose_HUFAadhar").hide();
                    $("#X-btn_HUFAadhar").hide();
                    $("#done-btn_HUFAadhar").hide();
                    $("#review-btn_HUFAadhar").show();
                  }
                );
              } else if (doc[0].status == "reupload") {
                mainactionHUFAadhar = "reupload";
                HUFAadharStatus = "reupload";
                HUFStatusArray[0].status = HUFAadharStatus;
                $("#choose_HUFAadhar").show();
                $("#viewDocLink_HUFAadhar").hide();
                $("#X-btn_HUFAadhar").hide();
                $("#done-btn_HUFAadhar").hide();
                $("#review-btn_HUFAadhar").hide();
              } else if (doc[0].status == "done") {
                HUFAadharStatus = "done";
                mainactionHUFAadhar = "upload";
                HUFStatusArray[0].status = HUFAadharStatus;
                $("#choose_HUFAadhar").hide();
                $("#X-btn_HUFAadhar").hide();
                $("#done-btn_HUFAadhar").show();
                $("#review-btn_HUFAadhar").hide();
              } else {
                HUFAadharStatus = "open";
                HUFStatusArray[0].status = HUFAadharStatus;
              }
              if (doc[1].status == "remove") {
                mainactionHUFPic = "upload";
                HUFPicStatus = "remove";
                HUFStatusArray[1].status = HUFPicStatus;
                this.setState(
                  {
                    HUFPicDocLink: doc[1].ApplicantPhoto,
                  },
                  () => {
                    $("#viewDocLink_HUFPic").css("display", "block");
                    $("#choose_HUFPic").hide();
                    $("#X-btn_HUFPic").show();
                    $("#done-btn_HUFPic").hide();
                    $("#review-btn_HUFPic").hide();
                  }
                );
              } else if (doc[1].status == "review") {
                mainactionHUFPic = "upload";
                HUFPicStatus = "review";
                HUFStatusArray[1].status = HUFPicStatus;
                this.setState(
                  {
                    HUFPicDocLink: doc[1].ApplicantPhoto,
                  },
                  () => {
                    $("#viewDocLink_HUFPic").css("display", "block");
                    $("#choose_HUFPic").hide();
                    $("#X-btn_HUFPic").hide();
                    $("#done-btn_HUFPic").hide();
                    $("#review-btn_HUFPic").show();
                  }
                );
              } else if (doc[1].status == "reupload") {
                mainactionHUFPic = "reupload";
                HUFPicStatus = "reupload";
                HUFStatusArray[1].status = HUFPicStatus;
                $("#choose_HUFPic").show();
                $("#viewDocLink_HUFPic").hide();
                $("#X-btn_HUFPic").hide();
                $("#done-btn_HUFPic").hide();
                $("#review-btn_HUFPic").hide();
              } else if (doc[1].status == "done") {
                HUFPicStatus = "done";
                mainactionHUFPic = "upload";
                HUFStatusArray[1].status = HUFPicStatus;
                $("#choose_HUFPic").hide();
                $("#X-btn_HUFPic").hide();
                $("#done-btn_HUFPic").show();
                $("#review-btn_HUFPic").hide();
              } else {
                HUFPicStatus = "open";
                HUFStatusArray[1].status = HUFPicStatus;
              }
              if (doc[2].status == "remove") {
                mainactionHUFDeed = "upload";
                HUFDeedStatus = "remove";
                HUFStatusArray[2].status = HUFDeedStatus;
                this.setState(
                  {
                    HUFDeedDocLink: doc[2].HUFDeedDocument,
                  },
                  () => {
                    $("#viewDocLink_HUFDeed").css("display", "block");
                    $("#choose_HUFDeed").hide();
                    $("#X-btn_HUFDeed").show();
                    $("#done-btn_HUFDeed").hide();
                    $("#review-btn_HUFDeed").hide();
                  }
                );
              } else if (doc[2].status == "review") {
                mainactionHUFDeed = "upload";
                HUFDeedStatus = "review";
                HUFStatusArray[2].status = HUFDeedStatus;
                this.setState(
                  {
                    HUFDeedDocLink: doc[2].HUFDeedDocument,
                  },
                  () => {
                    $("#viewDocLink_HUFDeed").css("display", "block");
                    $("#choose_HUFDeed").hide();
                    $("#X-btn_HUFDeed").hide();
                    $("#done-btn_HUFDeed").hide();
                    $("#review-btn_HUFDeed").show();
                  }
                );
              } else if (doc[2].status == "reupload") {
                mainactionHUFDeed = "reupload";
                HUFDeedStatus = "reupload";
                HUFStatusArray[2].status = HUFDeedStatus;
                $("#choose_HUFDeed").show();
                $("#viewDocLink_HUFDeed").hide();
                $("#X-btn_HUFDeed").hide();
                $("#done-btn_HUFDeed").hide();
                $("#review-btn_HUFDeed").hide();
              } else if (doc[2].status == "done") {
                HUFDeedStatus = "done";
                mainactionHUFDeed = "upload";
                HUFStatusArray[2].status = HUFDeedStatus;
                $("#choose_HUFDeed").hide();
                $("#X-btn_HUFDeed").hide();
                $("#done-btn_HUFDeed").show();
                $("#review-btn_HUFDeed").hide();
              } else {
                HUFDeedStatus = "open";
                HUFStatusArray[2].status = HUFDeedStatus;
              }
              if (doc[3].status == "remove") {
                mainactionHUFCopyOfRegistry = "upload";
                HUFCopyOfRegistryStatus = "remove";
                HUFOptStatusArray[0].status = HUFCopyOfRegistryStatus;
                this.setState(
                  {
                    HUFCopyOfRegistryDocLink: doc[3].CopyOfRegistry,
                  },
                  () => {
                    $("#viewDocLink_HUFCopyOfRegistry").css("display", "block");
                    $("#choose_HUFCopyOfRegistry").hide();
                    $("#X-btn_HUFCopyOfRegistry").show();
                    $("#done-btn_HUFCopyOfRegistry").hide();
                    $("#review-btn_HUFCopyOfRegistry").hide();
                  }
                );
              } else if (doc[3].status == "review") {
                mainactionHUFCopyOfRegistry = "upload";
                HUFCopyOfRegistryStatus = "review";
                HUFOptStatusArray[0].status = HUFCopyOfRegistryStatus;
                this.setState(
                  {
                    HUFCopyOfRegistryDocLink: doc[3].CopyOfRegistry,
                  },
                  () => {
                    $("#viewDocLink_HUFCopyOfRegistry").css("display", "block");
                    $("#choose_HUFCopyOfRegistry").hide();
                    $("#X-btn_HUFCopyOfRegistry").hide();
                    $("#done-btn_HUFCopyOfRegistry").hide();
                    $("#review-btn_HUFCopyOfRegistry").show();
                  }
                );
              } else if (doc[3].status == "reupload") {
                mainactionHUFCopyOfRegistry = "reupload";
                HUFCopyOfRegistryStatus = "reupload";
                HUFOptStatusArray[0].status = HUFCopyOfRegistryStatus;
                $("#choose_HUFCopyOfRegistry").show();
                $("#viewDocLink_HUFCopyOfRegistry").hide();
                $("#X-btn_HUFCopyOfRegistry").hide();
                $("#done-btn_HUFCopyOfRegistry").hide();
                $("#review-btn_HUFCopyOfRegistry").hide();
              } else if (doc[3].status == "done") {
                HUFCopyOfRegistryStatus = "done";
                mainactionHUFCopyOfRegistry = "upload";
                HUFOptStatusArray[0].status = HUFCopyOfRegistryStatus;
                $("#choose_HUFCopyOfRegistry").hide();
                $("#X-btn_HUFCopyOfRegistry").hide();
                $("#done-btn_HUFCopyOfRegistry").show();
                $("#review-btn_HUFCopyOfRegistry").hide();
              } else {
                HUFCopyOfRegistryStatus = "open";
                HUFOptStatusArray[0].status = HUFCopyOfRegistryStatus;
              }
              if (doc[4].status == "remove") {
                mainactionHUFRentAgreement = "upload";
                HUFRentAgreementStatus = "remove";
                HUFOptStatusArray[1].status = HUFRentAgreementStatus;
                this.setState(
                  {
                    HUFRentAgreementDocLink: doc[4].RentAgreement,
                  },
                  () => {
                    $("#viewDocLink_HUFRentAgreement").css("display", "block");
                    $("#choose_HUFRentAgreement").hide();
                    $("#X-btn_HUFRentAgreement").show();
                    $("#done-btn_HUFRentAgreement").hide();
                    $("#review-btn_HUFRentAgreement").hide();
                  }
                );
              } else if (doc[4].status == "review") {
                mainactionHUFRentAgreement = "upload";
                HUFRentAgreementStatus = "review";
                HUFOptStatusArray[1].status = HUFRentAgreementStatus;
                this.setState(
                  {
                    HUFRentAgreementDocLink: doc[4].RentAgreement,
                  },
                  () => {
                    $("#viewDocLink_HUFRentAgreement").css("display", "block");
                    $("#choose_HUFRentAgreement").hide();
                    $("#X-btn_HUFRentAgreement").hide();
                    $("#done-btn_HUFRentAgreement").hide();
                    $("#review-btn_HUFRentAgreement").show();
                  }
                );
              } else if (doc[4].status == "reupload") {
                mainactionHUFRentAgreement = "reupload";
                HUFRentAgreementStatus = "reupload";
                HUFOptStatusArray[1].status = HUFRentAgreementStatus;
                $("#choose_HUFRentAgreement").show();
                $("#viewDocLink_HUFRentAgreement").hide();
                $("#X-btn_HUFRentAgreement").hide();
                $("#done-btn_HUFRentAgreement").hide();
                $("#review-btn_HUFRentAgreement").hide();
              } else if (doc[4].status == "done") {
                HUFRentAgreementStatus = "done";
                mainactionHUFRentAgreement = "upload";
                HUFOptStatusArray[1].status = HUFRentAgreementStatus;
                $("#choose_HUFRentAgreement").hide();
                $("#X-btn_HUFRentAgreement").hide();
                $("#done-btn_HUFRentAgreement").show();
                $("#review-btn_HUFRentAgreement").hide();
              } else {
                HUFRentAgreementStatus = "open";
                HUFOptStatusArray[1].status = HUFRentAgreementStatus;
              }

              if (doc[5].status == "remove") {
                mainactionHUFPUnit = "upload";
                HUFPUnitStatus = "remove";
                HUFStatusArray[3].status = HUFPUnitStatus;
                this.setState(
                  {
                    HUFPUnitDocLink: doc[5].ProductionUnitPhoto,
                  },
                  () => {
                    $("#viewDocLink_HUFPUnit").css("display", "block");
                    $("#choose_HUFPUnit").hide();
                    $("#X-btn_HUFPUnit").show();
                    $("#done-btn_HUFPUnit").hide();
                    $("#review-btn_HUFPUnit").hide();
                  }
                );
              } else if (doc[5].status == "review") {
                mainactionHUFPUnit = "upload";
                HUFPUnitStatus = "review";
                HUFStatusArray[3].status = HUFPUnitStatus;
                this.setState(
                  {
                    HUFPUnitDocLink: doc[5].ProductionUnitPhoto,
                  },
                  () => {
                    $("#viewDocLink_HUFPUnit").css("display", "block");
                    $("#choose_HUFPUnit").hide();
                    $("#X-btn_HUFPUnit").hide();
                    $("#done-btn_HUFPUnit").hide();
                    $("#review-btn_HUFPUnit").show();
                  }
                );
              } else if (doc[5].status == "reupload") {
                mainactionHUFPUnit = "reupload";
                HUFPUnitStatus = "reupload";
                HUFStatusArray[3].status = HUFPUnitStatus;
                $("#choose_HUFPUnit").show();
                $("#viewDocLink_HUFPUnit").hide();
                $("#X-btn_HUFPUnit").hide();
                $("#done-btn_HUFPUnit").hide();
                $("#review-btn_HUFPUnit").hide();
              } else if (doc[5].status == "done") {
                HUFPUnitStatus = "done";
                mainactionHUFPUnit = "upload";
                HUFStatusArray[3].status = HUFPUnitStatus;
                $("#choose_HUFPUnit").hide();
                $("#X-btn_HUFPUnit").hide();
                $("#done-btn_HUFPUnit").show();
                $("#review-btn_HUFPUnit").hide();
              } else {
                HUFPUnitStatus = "open";
                HUFStatusArray[3].status = HUFPUnitStatus;
              }

              if (doc[6].status == "remove") {
                mainactionHUFLayoutPlan = "upload";
                HUFLayoutPlanStatus = "remove";
                HUFStatusArray[4].status = HUFLayoutPlanStatus;
                this.setState(
                  {
                    HUFLayoutPlanDocLink: doc[6].LayoutPlanUnit,
                  },
                  () => {
                    $("#viewDocLink_HUFLayoutPlan").css("display", "block");
                    $("#choose_HUFLayoutPlan").hide();
                    $("#X-btn_HUFLayoutPlan").show();
                    $("#done-btn_HUFLayoutPlan").hide();
                    $("#review-btn_HUFLayoutPlan").hide();
                  }
                );
              } else if (doc[6].status == "review") {
                mainactionHUFLayoutPlan = "upload";
                HUFLayoutPlanStatus = "review";
                HUFStatusArray[4].status = HUFLayoutPlanStatus;
                this.setState(
                  {
                    HUFLayoutPlanDocLink: doc[6].LayoutPlanUnit,
                  },
                  () => {
                    $("#viewDocLink_HUFLayoutPlan").css("display", "block");
                    $("#choose_HUFLayoutPlan").hide();
                    $("#X-btn_HUFLayoutPlan").hide();
                    $("#done-btn_HUFLayoutPlan").hide();
                    $("#review-btn_HUFLayoutPlan").show();
                  }
                );
              } else if (doc[6].status == "reupload") {
                mainactionHUFLayoutPlan = "reupload";
                HUFLayoutPlanStatus = "reupload";
                HUFStatusArray[4].status = HUFLayoutPlanStatus;
                $("#choose_HUFLayoutPlan").show();
                $("#viewDocLink_HUFLayoutPlan").hide();
                $("#X-btn_HUFLayoutPlan").hide();
                $("#done-btn_HUFLayoutPlan").hide();
                $("#review-btn_HUFLayoutPlan").hide();
              } else if (doc[6].status == "done") {
                HUFLayoutPlanStatus = "done";
                mainactionHUFLayoutPlan = "upload";
                HUFStatusArray[4].status = HUFLayoutPlanStatus;
                $("#choose_HUFLayoutPlan").hide();
                $("#X-btn_HUFLayoutPlan").hide();
                $("#done-btn_HUFLayoutPlan").show();
                $("#review-btn_HUFLayoutPlan").hide();
              } else {
                HUFLayoutPlanStatus = "open";
                HUFStatusArray[4].status = HUFLayoutPlanStatus;
              }

              if (doc[7].status == "remove") {
                mainactionHUFListofEquip = "upload";
                HUFListofEquipStatus = "remove";
                HUFStatusArray[5].status = HUFListofEquipStatus;
                this.setState(
                  {
                    HUFListofEquipDocLink: doc[7].ListOfEquipment,
                  },
                  () => {
                    $("#viewDocLink_HUFListofEquip").css("display", "block");
                    $("#choose_HUFListofEquip").hide();
                    $("#X-btn_HUFListofEquip").show();
                    $("#done-btn_HUFListofEquip").hide();
                    $("#review-btn_HUFListofEquip").hide();
                  }
                );
              } else if (doc[7].status == "review") {
                mainactionHUFListofEquip = "upload";
                HUFListofEquipStatus = "review";
                HUFStatusArray[5].status = HUFListofEquipStatus;
                this.setState(
                  {
                    HUFListofEquipDocLink: doc[7].ListOfEquipment,
                  },
                  () => {
                    $("#viewDocLink_HUFListofEquip").css("display", "block");
                    $("#choose_HUFListofEquip").hide();
                    $("#X-btn_HUFListofEquip").hide();
                    $("#done-btn_HUFListofEquip").hide();
                    $("#review-btn_HUFListofEquip").show();
                  }
                );
              } else if (doc[7].status == "reupload") {
                mainactionHUFListofEquip = "reupload";
                HUFListofEquipStatus = "reupload";
                HUFStatusArray[5].status = HUFListofEquipStatus;
                $("#choose_HUFListofEquip").show();
                $("#viewDocLink_HUFListofEquip").hide();
                $("#X-btn_HUFListofEquip").hide();
                $("#done-btn_HUFListofEquip").hide();
                $("#review-btn_HUFListofEquip").hide();
              } else if (doc[7].status == "done") {
                HUFListofEquipStatus = "done";
                mainactionHUFListofEquip = "upload";
                HUFStatusArray[5].status = HUFListofEquipStatus;
                $("#choose_HUFListofEquip").hide();
                $("#X-btn_HUFListofEquip").hide();
                $("#done-btn_HUFListofEquip").show();
                $("#review-btn_HUFListofEquip").hide();
              } else {
                HUFListofEquipStatus = "open";
                HUFStatusArray[5].status = HUFListofEquipStatus;
              }

              if (doc[8].status == "remove") {
                mainactionHUFWAReport = "upload";
                HUFWAReportStatus = "remove";
                HUFStatusArray[6].status = HUFWAReportStatus;
                this.setState(
                  {
                    HUFWAReportDocLink: doc[8].WaterAnalysisReport,
                  },
                  () => {
                    $("#viewDocLink_HUFWAReport").css("display", "block");
                    $("#choose_HUFWAReport").hide();
                    $("#X-btn_HUFWAReport").show();
                    $("#done-btn_HUFWAReport").hide();
                    $("#review-btn_HUFWAReport").hide();
                  }
                );
              } else if (doc[8].status == "review") {
                mainactionHUFWAReport = "upload";
                HUFWAReportStatus = "review";
                HUFStatusArray[6].status = HUFWAReportStatus;
                this.setState(
                  {
                    HUFWAReportDocLink: doc[8].WaterAnalysisReport,
                  },
                  () => {
                    $("#viewDocLink_HUFWAReport").css("display", "block");
                    $("#choose_HUFWAReport").hide();
                    $("#X-btn_HUFWAReport").hide();
                    $("#done-btn_HUFWAReport").hide();
                    $("#review-btn_HUFWAReport").show();
                  }
                );
              } else if (doc[8].status == "reupload") {
                mainactionHUFWAReport = "reupload";
                HUFWAReportStatus = "reupload";
                HUFStatusArray[8].status = HUFWAReportStatus;
                $("#choose_HUFWAReport").show();
                $("#viewDocLink_HUFWAReport").hide();
                $("#X-btn_HUFWAReport").hide();
                $("#done-btn_HUFWAReport").hide();
                $("#review-btn_HUFWAReport").hide();
              } else if (doc[8].status == "done") {
                HUFWAReportStatus = "done";
                mainactionHUFWAReport = "upload";
                HUFStatusArray[6].status = HUFWAReportStatus;
                $("#choose_HUFWAReport").hide();
                $("#X-btn_HUFWAReport").hide();
                $("#done-btn_HUFWAReport").show();
                $("#review-btn_HUFWAReport").hide();
              } else {
                HUFWAReportStatus = "open";
                HUFStatusArray[6].status = HUFWAReportStatus;
              }

              if (doc[9].status == "remove") {
                mainactionHUFListofDir = "upload";
                HUFListofDirStatus = "remove";
                HUFStatusArray[7].status = HUFListofDirStatus;
                this.setState(
                  {
                    HUFListofDirDocLink: doc[9].DirectorsFullAddress,
                  },
                  () => {
                    $("#viewDocLink_HUFListofDir").css("display", "block");
                    $("#choose_HUFListofDir").hide();
                    $("#X-btn_HUFListofDir").show();
                    $("#done-btn_HUFListofDir").hide();
                    $("#review-btn_HUFListofDir").hide();
                  }
                );
              } else if (doc[9].status == "review") {
                mainactionHUFListofDir = "upload";
                HUFListofDirStatus = "review";
                HUFStatusArray[7].status = HUFListofDirStatus;
                this.setState(
                  {
                    HUFListofDirDocLink: doc[9].DirectorsFullAddress,
                  },
                  () => {
                    $("#viewDocLink_HUFListofDir").css("display", "block");
                    $("#choose_HUFListofDir").hide();
                    $("#X-btn_HUFListofDir").hide();
                    $("#done-btn_HUFListofDir").hide();
                    $("#review-btn_HUFListofDir").show();
                  }
                );
              } else if (doc[9].status == "reupload") {
                mainactionHUFListofDir = "reupload";
                HUFListofDirStatus = "reupload";
                HUFStatusArray[7].status = HUFListofDirStatus;
                $("#choose_HUFListofDir").show();
                $("#viewDocLink_HUFListofDir").hide();
                $("#X-btn_HUFListofDir").hide();
                $("#done-btn_HUFListofDir").hide();
                $("#review-btn_HUFListofDir").hide();
              } else if (doc[9].status == "done") {
                HUFListofDirStatus = "done";
                mainactionHUFListofDir = "upload";
                HUFStatusArray[7].status = HUFListofDirStatus;
                $("#choose_HUFListofDir").hide();
                $("#X-btn_HUFListofDir").hide();
                $("#done-btn_HUFListofDir").show();
                $("#review-btn_HUFListofDir").hide();
              } else {
                HUFListofDirStatus = "open";
                HUFStatusArray[7].status = HUFListofDirStatus;
              }

              if (doc[10].status == "remove") {
                mainactionHUFRecallPlan = "upload";
                HUFRecallPlanStatus = "remove";
                HUFStatusArray[8].status = HUFRecallPlanStatus;
                this.setState(
                  {
                    HUFRecallPlanDocLink: doc[10].RecallPlan,
                  },
                  () => {
                    $("#viewDocLink_HUFRecallPlan").css("display", "block");
                    $("#choose_HUFRecallPlan").hide();
                    $("#X-btn_HUFRecallPlan").show();
                    $("#done-btn_HUFRecallPlan").hide();
                    $("#review-btn_HUFRecallPlan").hide();
                  }
                );
              } else if (doc[10].status == "review") {
                mainactionHUFRecallPlan = "upload";
                HUFRecallPlanStatus = "review";
                HUFStatusArray[8].status = HUFRecallPlanStatus;
                this.setState(
                  {
                    HUFRecallPlanDocLink: doc[10].RecallPlan,
                  },
                  () => {
                    $("#viewDocLink_HUFRecallPlan").css("display", "block");
                    $("#choose_HUFRecallPlan").hide();
                    $("#X-btn_HUFRecallPlan").hide();
                    $("#done-btn_HUFRecallPlan").hide();
                    $("#review-btn_HUFRecallPlan").show();
                  }
                );
              } else if (doc[10].status == "reupload") {
                mainactionHUFRecallPlan = "reupload";
                HUFRecallPlanStatus = "reupload";
                HUFStatusArray[8].status = HUFRecallPlanStatus;
                $("#choose_HUFRecallPlan").show();
                $("#viewDocLink_HUFRecallPlan").hide();
                $("#X-btn_HUFRecallPlan").hide();
                $("#done-btn_HUFRecallPlan").hide();
                $("#review-btn_HUFRecallPlan").hide();
              } else if (doc[10].status == "done") {
                HUFRecallPlanStatus = "done";
                mainactionHUFRecallPlan = "upload";
                HUFStatusArray[8].status = HUFRecallPlanStatus;
                $("#choose_HUFRecallPlan").hide();
                $("#X-btn_HUFRecallPlan").hide();
                $("#done-btn_HUFRecallPlan").show();
                $("#review-btn_HUFRecallPlan").hide();
              } else {
                HUFRecallPlanStatus = "open";
                HUFStatusArray[8].status = HUFRecallPlanStatus;
              }
            }

            this.HUFcheckRemainingReuploads();
            this.checkhandleHUFProceedBtn();
          } else if (res.data.serviceType == "FL_STC_Documents") {
            $(".selectcateselect").attr("disabled", true);
            this.setState(
              {
                dropDownValue: "FL_STC_Documents",
                serviceType: "FL_STC_Documents",
              },
              () => {
                if (this.state.dropDownValue == "FL_STC_Documents") {
                  $("#FoodLicense_default_Img").hide();
                  $("#FoodLicense_SolePI").hide();
                  $("#FoodLicense_P_LLP").hide();
                  $("#FoodLicense_PL").hide();
                  $("#FoodLicense_HUF").hide();
                  $("#FoodLicense_Society").show();
                }
              }
            );
            if (res.data.informationStatus == "lock") {
              if (res.data.addDocInformation.length == 0) {
                $("#STadditionalDocumentDiv").hide();
              } else {
                $("#STAdddocumentbtn").hide();
              }
            }
            if (res.data.addDocInformation.length > 0) {
              this.setState(
                {
                  STadditionalFiles: [...res.data.addDocInformation],
                  STAdd_DocName: "File Name",
                  api_STadditionalFiles: "Yes",
                },
                () => { }
              );
            } else if (
              res.data.addDocInformation.length == 0 &&
              res.data.informationStatus != "lock"
            ) {
              this.setState({
                STAdd_DocName: "",
                STadditionalFiles: [1],
                api_STadditionalFiles: "No",
              });
            }

            if (res.data.inputFields.length > 0) {
              if (
                res.data.inputFields[0].status == "review" ||
                res.data.inputFields[0].status == "done"
              ) {
                this.setState({
                  STbusinessNature: res.data.inputFields[0].Nature,
                });
                $(".uploadtextarea").attr("disabled", true);
              }
              if (
                res.data.inputFields[1].AddressProofType == "CopyOfRegistry"
              ) {
                this.setState(
                  {
                    STAddressProofType_radio: "CopyOfRegistry",
                  },
                  () => {
                    $(".CopyOfRegistry").css("display", "flex");
                    $(".RentAgreement").hide();
                  }
                );
              } else if (
                res.data.inputFields[1].AddressProofType == "RentAgreement"
              ) {
                this.setState(
                  {
                    STAddressProofType_radio: "RentAgreement",
                  },
                  () => {
                    $(".RentAgreement").css("display", "flex");
                    $(".CopyOfRegistry").hide();
                  }
                );
              }
            }

            if (res.data.documents.length > 0) {
              let doc = res.data.documents;
              if (doc[2].status == "remove") {
                mainactionSTAadhar1 = "upload";
                STAadhar1Status = "remove";
                STStatusArray[0].status = STAadhar1Status;
                this.setState(
                  {
                    STAadhar1DocLink: doc[0].IDProofApplicant1,
                  },
                  () => {
                    $("#viewDocLink_STAadhar1").css("display", "block");
                    $("#choose_STAadhar1").hide();
                    $("#X-btn_STAadhar1").show();
                    $("#done-btn_STAadhar1").hide();
                    $("#review-btn_STAadhar1").hide();
                  }
                );
              } else if (doc[2].status == "review") {
                mainactionSTAadhar1 = "upload";
                STAadhar1Status = "review";
                STStatusArray[0].status = STAadhar1Status;
                this.setState(
                  {
                    STAadhar1DocLink: doc[2].IDProofApplicant1,
                  },
                  () => {
                    $("#viewDocLink_STAadhar1").css("display", "block");
                    $("#choose_STAadhar1").hide();
                    $("#X-btn_STAadhar1").hide();
                    $("#done-btn_STAadhar1").hide();
                    $("#review-btn_STAadhar1").show();
                  }
                );
              } else if (doc[2].status == "reupload") {
                mainactionSTAadhar1 = "reupload";
                STAadhar1Status = "reupload";
                STStatusArray[0].status = STAadhar1Status;
                $("#choose_STAadhar1").show();
                $("#viewDocLink_STAadhar1").hide();
                $("#X-btn_STAadhar1").hide();
                $("#done-btn_STAadhar1").hide();
                $("#review-btn_STAadhar1").hide();
              } else if (doc[2].status == "done") {
                STAadhar1Status = "done";
                mainactionSTAadhar1 = "upload";
                STStatusArray[0].status = STAadhar1Status;
                $("#choose_STAadhar1").hide();
                $("#X-btn_STAadhar1").hide();
                $("#done-btn_STAadhar1").show();
                $("#review-btn_STAadhar1").hide();
              } else {
                STAadhar1Status = "open";
                STStatusArray[0].status = STAadhar1Status;
              }
              if (doc[3].status == "remove") {
                mainactionSTAadhar2 = "upload";
                STAadhar2Status = "remove";
                STStatusArray[1].status = STAadhar2Status;
                this.setState(
                  {
                    STAadhar2DocLink: doc[1].IDProofApplicant2,
                  },
                  () => {
                    $("#viewDocLink_STAadhar2").css("display", "block");
                    $("#choose_STAadhar2").hide();
                    $("#X-btn_STAadhar2").show();
                    $("#done-btn_STAadhar2").hide();
                    $("#review-btn_STAadhar2").hide();
                  }
                );
              } else if (doc[3].status == "review") {
                mainactionSTAadhar2 = "upload";
                STAadhar2Status = "review";
                STStatusArray[1].status = STAadhar2Status;
                this.setState(
                  {
                    STAadhar2DocLink: doc[3].IDProofApplicant2,
                  },
                  () => {
                    $("#viewDocLink_STAadhar2").css("display", "block");
                    $("#choose_STAadhar2").hide();
                    $("#X-btn_STAadhar2").hide();
                    $("#done-btn_STAadhar2").hide();
                    $("#review-btn_STAadhar2").show();
                  }
                );
              } else if (doc[3].status == "reupload") {
                mainactionSTAadhar2 = "reupload";
                STAadhar2Status = "reupload";
                STStatusArray[1].status = STAadhar2Status;
                $("#choose_STAadhar2").show();
                $("#viewDocLink_STAadhar2").hide();
                $("#X-btn_STAadhar2").hide();
                $("#done-btn_STAadhar2").hide();
                $("#review-btn_STAadhar2").hide();
              } else if (doc[3].status == "done") {
                STAadhar2Status = "done";
                mainactionSTAadhar2 = "upload";
                STStatusArray[1].status = STAadhar2Status;
                $("#choose_STAadhar2").hide();
                $("#X-btn_STAadhar2").hide();
                $("#done-btn_STAadhar2").show();
                $("#review-btn_STAadhar2").hide();
              } else {
                STAadhar2Status = "open";
                STStatusArray[1].status = STAadhar2Status;
              }
              if (doc[0].status == "remove") {
                mainactionSTPic1 = "upload";
                STPic1Status = "remove";
                STStatusArray[2].status = STPic1Status;
                this.setState(
                  {
                    STPic1DocLink: doc[0].ApplicantPhoto1,
                  },
                  () => {
                    $("#viewDocLink_STPic1").css("display", "block");
                    $("#choose_STPic1").hide();
                    $("#X-btn_STPic1").show();
                    $("#done-btn_STPic1").hide();
                    $("#review-btn_STPic1").hide();
                  }
                );
              } else if (doc[0].status == "review") {
                mainactionSTPic1 = "upload";
                STPic1Status = "review";
                STStatusArray[2].status = STPic1Status;
                this.setState(
                  {
                    STPic1DocLink: doc[0].ApplicantPhoto1,
                  },
                  () => {
                    $("#viewDocLink_STPic1").css("display", "block");
                    $("#choose_STPic1").hide();
                    $("#X-btn_STPic1").hide();
                    $("#done-btn_STPic1").hide();
                    $("#review-btn_STPic1").show();
                  }
                );
              } else if (doc[0].status == "reupload") {
                mainactionSTPic1 = "reupload";
                STPic1Status = "reupload";
                STStatusArray[2].status = STPic1Status;
                $("#choose_STPic1").show();
                $("#viewDocLink_STPic1").hide();
                $("#X-btn_STPic1").hide();
                $("#done-btn_STPic1").hide();
                $("#review-btn_STPic1").hide();
              } else if (doc[0].status == "done") {
                STPic1Status = "done";
                mainactionSTPic1 = "upload";
                STStatusArray[2].status = STPic1Status;
                $("#choose_STPic1").hide();
                $("#X-btn_STPic1").hide();
                $("#done-btn_STPic1").show();
                $("#review-btn_STPic1").hide();
              } else {
                STPic1Status = "open";
                STStatusArray[2].status = STPic1Status;
              }
              if (doc[1].status == "remove") {
                mainactionSTPic2 = "upload";
                STPic2Status = "remove";
                STStatusArray[3].status = STPic2Status;
                this.setState(
                  {
                    STPic2DocLink: doc[1].ApplicantPhoto2,
                  },
                  () => {
                    $("#viewDocLink_STPic2").css("display", "block");
                    $("#choose_STPic2").hide();
                    $("#X-btn_STPic2").show();
                    $("#done-btn_STPic2").hide();
                    $("#review-btn_STPic2").hide();
                  }
                );
              } else if (doc[1].status == "review") {
                mainactionSTPic2 = "upload";
                STPic2Status = "review";
                STStatusArray[3].status = STPic2Status;
                this.setState(
                  {
                    STPic2DocLink: doc[1].ApplicantPhoto2,
                  },
                  () => {
                    $("#viewDocLink_STPic2").css("display", "block");
                    $("#choose_STPic2").hide();
                    $("#X-btn_STPic2").hide();
                    $("#done-btn_STPic2").hide();
                    $("#review-btn_STPic2").show();
                  }
                );
              } else if (doc[1].status == "reupload") {
                mainactionSTPic2 = "reupload";
                STPic2Status = "reupload";
                STStatusArray[3].status = STPic2Status;
                $("#choose_STPic2").show();
                $("#viewDocLink_STPic2").hide();
                $("#X-btn_STPic2").hide();
                $("#done-btn_STPic2").hide();
                $("#review-btn_STPic2").hide();
              } else if (doc[1].status == "done") {
                STPic2Status = "done";
                mainactionSTPic2 = "upload";
                STStatusArray[3].status = STPic2Status;
                $("#choose_STPic2").hide();
                $("#X-btn_STPic2").hide();
                $("#done-btn_STPic2").show();
                $("#review-btn_STPic2").hide();
              } else {
                STPic2Status = "open";
                STStatusArray[3].status = STPic2Status;
              }
              if (doc[4].status == "remove") {
                mainactionSTCR = "upload";
                STCRStatus = "remove";
                STStatusArray[4].status = STCRStatus;
                this.setState(
                  {
                    STCRDocLink: doc[4].CertificateOfRegistration,
                  },
                  () => {
                    $("#viewDocLink_STCR").css("display", "block");
                    $("#choose_STCR").hide();
                    $("#X-btn_STCR").show();
                    $("#done-btn_STCR").hide();
                    $("#review-btn_STCR").hide();
                  }
                );
              } else if (doc[4].status == "review") {
                mainactionSTCR = "upload";
                STCRStatus = "review";
                STStatusArray[4].status = STCRStatus;
                this.setState(
                  {
                    STCRDocLink: doc[4].CertificateOfRegistration,
                  },
                  () => {
                    $("#viewDocLink_STCR").css("display", "block");
                    $("#choose_STCR").hide();
                    $("#X-btn_STCR").hide();
                    $("#done-btn_STCR").hide();
                    $("#review-btn_STCR").show();
                  }
                );
              } else if (doc[4].status == "reupload") {
                mainactionSTCR = "reupload";
                STCRStatus = "reupload";
                STStatusArray[4].status = STCRStatus;
                $("#choose_STCR").show();
                $("#viewDocLink_STCR").hide();
                $("#X-btn_STCR").hide();
                $("#done-btn_STCR").hide();
                $("#review-btn_STCR").hide();
              } else if (doc[4].status == "done") {
                STCRStatus = "done";
                mainactionSTCR = "upload";
                STStatusArray[4].status = STCRStatus;
                $("#choose_STCR").hide();
                $("#X-btn_STCR").hide();
                $("#done-btn_STCR").show();
                $("#review-btn_STCR").hide();
              } else {
                STCRStatus = "open";
                STStatusArray[4].status = STCRStatus;
              }
              if (doc[5].status == "remove") {
                mainactionSTCopyOfRegistry = "upload";
                STCopyOfRegistryStatus = "remove";
                STOptStatusArray[0].status = STCopyOfRegistryStatus;
                this.setState(
                  {
                    STCopyOfRegistryDocLink: doc[5].OwnershipDeed,
                  },
                  () => {
                    $("#viewDocLink_STCopyOfRegistry").css("display", "block");
                    $("#choose_STCopyOfRegistry").hide();
                    $("#X-btn_STCopyOfRegistry").show();
                    $("#done-btn_STCopyOfRegistry").hide();
                    $("#review-btn_STCopyOfRegistry").hide();
                  }
                );
              } else if (doc[5].status == "review") {
                mainactionSTCopyOfRegistry = "upload";
                STCopyOfRegistryStatus = "review";
                STOptStatusArray[0].status = STCopyOfRegistryStatus;
                this.setState(
                  {
                    STCopyOfRegistryDocLink: doc[5].OwnershipDeed,
                  },
                  () => {
                    $("#viewDocLink_STCopyOfRegistry").css("display", "block");
                    $("#choose_STCopyOfRegistry").hide();
                    $("#X-btn_STCopyOfRegistry").hide();
                    $("#done-btn_STCopyOfRegistry").hide();
                    $("#review-btn_STCopyOfRegistry").show();
                  }
                );
              } else if (doc[5].status == "reupload") {
                mainactionSTCopyOfRegistry = "reupload";
                STCopyOfRegistryStatus = "reupload";
                STOptStatusArray[0].status = STCopyOfRegistryStatus;
                $("#choose_STCopyOfRegistry").show();
                $("#viewDocLink_STCopyOfRegistry").hide();
                $("#X-btn_STCopyOfRegistry").hide();
                $("#done-btn_STCopyOfRegistry").hide();
                $("#review-btn_STCopyOfRegistry").hide();
              } else if (doc[5].status == "done") {
                STCopyOfRegistryStatus = "done";
                mainactionSTCopyOfRegistry = "upload";
                STOptStatusArray[0].status = STCopyOfRegistryStatus;
                $("#choose_STCopyOfRegistry").hide();
                $("#X-btn_STCopyOfRegistry").hide();
                $("#done-btn_STCopyOfRegistry").show();
                $("#review-btn_STCopyOfRegistry").hide();
              } else {
                STCopyOfRegistryStatus = "open";
                STOptStatusArray[0].status = STCopyOfRegistryStatus;
              }
              if (doc[6].status == "remove") {
                mainactionSTRentAgreement = "upload";
                STRentAgreementStatus = "remove";
                STOptStatusArray[1].status = STRentAgreementStatus;
                this.setState(
                  {
                    STRentAgreementDocLink: doc[6].RentAgreement,
                  },
                  () => {
                    $("#viewDocLink_STRentAgreement").css("display", "block");
                    $("#choose_STRentAgreement").hide();
                    $("#X-btn_STRentAgreement").show();
                    $("#done-btn_STRentAgreement").hide();
                    $("#review-btn_STRentAgreement").hide();
                  }
                );
              } else if (doc[6].status == "review") {
                mainactionSTRentAgreement = "upload";
                STRentAgreementStatus = "review";
                STOptStatusArray[1].status = STRentAgreementStatus;
                this.setState(
                  {
                    STRentAgreementDocLink: doc[6].RentAgreement,
                  },
                  () => {
                    $("#viewDocLink_STRentAgreement").css("display", "block");
                    $("#choose_STRentAgreement").hide();
                    $("#X-btn_STRentAgreement").hide();
                    $("#done-btn_STRentAgreement").hide();
                    $("#review-btn_STRentAgreement").show();
                  }
                );
              } else if (doc[6].status == "reupload") {
                mainactionSTRentAgreement = "reupload";
                STRentAgreementStatus = "reupload";
                STOptStatusArray[1].status = STRentAgreementStatus;
                $("#choose_STRentAgreement").show();
                $("#viewDocLink_STRentAgreement").hide();
                $("#X-btn_STRentAgreement").hide();
                $("#done-btn_STRentAgreement").hide();
                $("#review-btn_STRentAgreement").hide();
              } else if (doc[6].status == "done") {
                STRentAgreementStatus = "done";
                mainactionSTRentAgreement = "upload";
                STOptStatusArray[1].status = STRentAgreementStatus;
                $("#choose_STRentAgreement").hide();
                $("#X-btn_STRentAgreement").hide();
                $("#done-btn_STRentAgreement").show();
                $("#review-btn_STRentAgreement").hide();
              } else {
                STRentAgreementStatus = "open";
                STOptStatusArray[1].status = STRentAgreementStatus;
              }
              if (doc[7].status == "remove") {
                mainactionHUFPUnit = "upload";
                STPUnitStatus = "remove";
                STStatusArray[5].status = STPUnitStatus;
                this.setState(
                  {
                    STPUnitDocLink: doc[7].ProductionUnitPhoto,
                  },
                  () => {
                    $("#viewDocLink_STPUnit").css("display", "block");
                    $("#choose_STPUnit").hide();
                    $("#X-btn_STPUnit").show();
                    $("#done-btn_STPUnit").hide();
                    $("#review-btn_STPUnit").hide();
                  }
                );
              } else if (doc[7].status == "review") {
                mainactionSTPUnit = "upload";
                STPUnitStatus = "review";
                STStatusArray[5].status = STPUnitStatus;
                this.setState(
                  {
                    STPUnitDocLink: doc[7].ProductionUnitPhoto,
                  },
                  () => {
                    $("#viewDocLink_STPUnit").css("display", "block");
                    $("#choose_STPUnit").hide();
                    $("#X-btn_STPUnit").hide();
                    $("#done-btn_STPUnit").hide();
                    $("#review-btn_STPUnit").show();
                  }
                );
              } else if (doc[7].status == "reupload") {
                mainactionSTPUnit = "reupload";
                STPUnitStatus = "reupload";
                STStatusArray[5].status = STPUnitStatus;
                $("#choose_STPUnit").show();
                $("#viewDocLink_STPUnit").hide();
                $("#X-btn_STPUnit").hide();
                $("#done-btn_STPUnit").hide();
                $("#review-btn_STPUnit").hide();
              } else if (doc[7].status == "done") {
                STPUnitStatus = "done";
                mainactionSTPUnit = "upload";
                STStatusArray[5].status = STPUnitStatus;
                $("#choose_STPUnit").hide();
                $("#X-btn_STPUnit").hide();
                $("#done-btn_STPUnit").show();
                $("#review-btn_STPUnit").hide();
              } else {
                STPUnitStatus = "open";
                STStatusArray[5].status = STPUnitStatus;
              }

              if (doc[11].status == "remove") {
                mainactionSTLayoutPlan = "upload";
                STLayoutPlanStatus = "remove";
                STStatusArray[6].status = STLayoutPlanStatus;
                this.setState(
                  {
                    STLayoutPlanDocLink: doc[11].LayoutPlanUnit,
                  },
                  () => {
                    $("#viewDocLink_STLayoutPlan").css("display", "block");
                    $("#choose_STLayoutPlan").hide();
                    $("#X-btn_STLayoutPlan").show();
                    $("#done-btn_STLayoutPlan").hide();
                    $("#review-btn_STLayoutPlan").hide();
                  }
                );
              } else if (doc[11].status == "review") {
                mainactionSTLayoutPlan = "upload";
                STLayoutPlanStatus = "review";
                STStatusArray[6].status = STLayoutPlanStatus;
                this.setState(
                  {
                    STLayoutPlanDocLink: doc[11].LayoutPlanUnit,
                  },
                  () => {
                    $("#viewDocLink_STLayoutPlan").css("display", "block");
                    $("#choose_STLayoutPlan").hide();
                    $("#X-btn_STLayoutPlan").hide();
                    $("#done-btn_STLayoutPlan").hide();
                    $("#review-btn_STLayoutPlan").show();
                  }
                );
              } else if (doc[11].status == "reupload") {
                mainactionSTLayoutPlan = "reupload";
                STLayoutPlanStatus = "reupload";
                STStatusArray[6].status = STLayoutPlanStatus;
                $("#choose_STLayoutPlan").show();
                $("#viewDocLink_STLayoutPlan").hide();
                $("#X-btn_STLayoutPlan").hide();
                $("#done-btn_STLayoutPlan").hide();
                $("#review-btn_STLayoutPlan").hide();
              } else if (doc[11].status == "done") {
                STLayoutPlanStatus = "done";
                mainactionSTLayoutPlan = "upload";
                STStatusArray[6].status = STLayoutPlanStatus;
                $("#choose_STLayoutPlan").hide();
                $("#X-btn_STLayoutPlan").hide();
                $("#done-btn_STLayoutPlan").show();
                $("#review-btn_STLayoutPlan").hide();
              } else {
                STLayoutPlanStatus = "open";
                STStatusArray[6].status = STLayoutPlanStatus;
              }

              if (doc[8].status == "remove") {
                mainactionSTListofEquip = "upload";
                STListofEquipStatus = "remove";
                STStatusArray[7].status = STListofEquipStatus;
                this.setState(
                  {
                    STListofEquipDocLink: doc[8].ListOfEquipment,
                  },
                  () => {
                    $("#viewDocLink_STListofEquip").css("display", "block");
                    $("#choose_STListofEquip").hide();
                    $("#X-btn_STListofEquip").show();
                    $("#done-btn_STListofEquip").hide();
                    $("#review-btn_STListofEquip").hide();
                  }
                );
              } else if (doc[8].status == "review") {
                mainactionSTListofEquip = "upload";
                STListofEquipStatus = "review";
                STStatusArray[7].status = STListofEquipStatus;
                this.setState(
                  {
                    STListofEquipDocLink: doc[8].ListOfEquipment,
                  },
                  () => {
                    $("#viewDocLink_STListofEquip").css("display", "block");
                    $("#choose_STListofEquip").hide();
                    $("#X-btn_STListofEquip").hide();
                    $("#done-btn_STListofEquip").hide();
                    $("#review-btn_STListofEquip").show();
                  }
                );
              } else if (doc[8].status == "reupload") {
                mainactionSTListofEquip = "reupload";
                STListofEquipStatus = "reupload";
                STStatusArray[7].status = STListofEquipStatus;
                $("#choose_STListofEquip").show();
                $("#viewDocLink_STListofEquip").hide();
                $("#X-btn_STListofEquip").hide();
                $("#done-btn_STListofEquip").hide();
                $("#review-btn_STListofEquip").hide();
              } else if (doc[8].status == "done") {
                STListofEquipStatus = "done";
                mainactionSTListofEquip = "upload";
                STStatusArray[7].status = STListofEquipStatus;
                $("#choose_STListofEquip").hide();
                $("#X-btn_STListofEquip").hide();
                $("#done-btn_STListofEquip").show();
                $("#review-btn_STListofEquip").hide();
              } else {
                STListofEquipStatus = "open";
                STStatusArray[7].status = STListofEquipStatus;
              }

              if (doc[9].status == "remove") {
                mainactionSTWAReport = "upload";
                STWAReportStatus = "remove";
                STStatusArray[8].status = STWAReportStatus;
                this.setState(
                  {
                    STWAReportDocLink: doc[9].WaterAnalysisReport,
                  },
                  () => {
                    $("#viewDocLink_STWAReport").css("display", "block");
                    $("#choose_STWAReport").hide();
                    $("#X-btn_STWAReport").show();
                    $("#done-btn_STWAReport").hide();
                    $("#review-btn_STWAReport").hide();
                  }
                );
              } else if (doc[9].status == "review") {
                mainactionSTWAReport = "upload";
                STWAReportStatus = "review";
                STStatusArray[8].status = STWAReportStatus;
                this.setState(
                  {
                    STWAReportDocLink: doc[9].WaterAnalysisReport,
                  },
                  () => {
                    $("#viewDocLink_STWAReport").css("display", "block");
                    $("#choose_STWAReport").hide();
                    $("#X-btn_STWAReport").hide();
                    $("#done-btn_STWAReport").hide();
                    $("#review-btn_STWAReport").show();
                  }
                );
              } else if (doc[9].status == "reupload") {
                mainactionSTWAReport = "reupload";
                STWAReportStatus = "reupload";
                STStatusArray[8].status = STWAReportStatus;
                $("#choose_STWAReport").show();
                $("#viewDocLink_STWAReport").hide();
                $("#X-btn_STWAReport").hide();
                $("#done-btn_STWAReport").hide();
                $("#review-btn_STWAReport").hide();
              } else if (doc[9].status == "done") {
                STWAReportStatus = "done";
                mainactionSTWAReport = "upload";
                STStatusArray[8].status = STWAReportStatus;
                $("#choose_STWAReport").hide();
                $("#X-btn_STWAReport").hide();
                $("#done-btn_STWAReport").show();
                $("#review-btn_STWAReport").hide();
              } else {
                STWAReportStatus = "open";
                STStatusArray[8].status = STWAReportStatus;
              }

              if (doc[10].status == "remove") {
                mainactionSTListofDir = "upload";
                STListofDirStatus = "remove";
                STStatusArray[9].status = STListofDirStatus;
                this.setState(
                  {
                    STListofDirDocLink: doc[10].DirectorsFullAddress,
                  },
                  () => {
                    $("#viewDocLink_STListofDir").css("display", "block");
                    $("#choose_STListofDir").hide();
                    $("#X-btn_STListofDir").show();
                    $("#done-btn_STListofDir").hide();
                    $("#review-btn_STListofDir").hide();
                  }
                );
              } else if (doc[10].status == "review") {
                mainactionSTListofDir = "upload";
                STListofDirStatus = "review";
                STStatusArray[9].status = STListofDirStatus;
                this.setState(
                  {
                    STListofDirDocLink: doc[10].DirectorsFullAddress,
                  },
                  () => {
                    $("#viewDocLink_STListofDir").css("display", "block");
                    $("#choose_STListofDir").hide();
                    $("#X-btn_STListofDir").hide();
                    $("#done-btn_STListofDir").hide();
                    $("#review-btn_STListofDir").show();
                  }
                );
              } else if (doc[10].status == "reupload") {
                mainactionSTListofDir = "reupload";
                STListofDirStatus = "reupload";
                STStatusArray[9].status = STListofDirStatus;
                $("#choose_STListofDir").show();
                $("#viewDocLink_STListofDir").hide();
                $("#X-btn_STListofDir").hide();
                $("#done-btn_STListofDir").hide();
                $("#review-btn_STListofDir").hide();
              } else if (doc[10].status == "done") {
                STListofDirStatus = "done";
                mainactionSTListofDir = "upload";
                STStatusArray[9].status = STListofDirStatus;
                $("#choose_STListofDir").hide();
                $("#X-btn_STListofDir").hide();
                $("#done-btn_STListofDir").show();
                $("#review-btn_STListofDir").hide();
              } else {
                STListofDirStatus = "open";
                STStatusArray[9].status = STListofDirStatus;
              }

              if (doc[11].status == "remove") {
                mainactionSTRecallPlan = "upload";
                STRecallPlanStatus = "remove";
                STStatusArray[10].status = STRecallPlanStatus;
                this.setState(
                  {
                    STRecallPlanDocLink: doc[11].RecallPlan,
                  },
                  () => {
                    $("#viewDocLink_STRecallPlan").css("display", "block");
                    $("#choose_STRecallPlan").hide();
                    $("#X-btn_STRecallPlan").show();
                    $("#done-btn_STRecallPlan").hide();
                    $("#review-btn_STRecallPlan").hide();
                  }
                );
              } else if (doc[11].status == "review") {
                mainactionSTRecallPlan = "upload";
                STRecallPlanStatus = "review";
                STStatusArray[10].status = STRecallPlanStatus;
                this.setState(
                  {
                    STRecallPlanDocLink: doc[11].RecallPlan,
                  },
                  () => {
                    $("#viewDocLink_STRecallPlan").css("display", "block");
                    $("#choose_STRecallPlan").hide();
                    $("#X-btn_STRecallPlan").hide();
                    $("#done-btn_STRecallPlan").hide();
                    $("#review-btn_STRecallPlan").show();
                  }
                );
              } else if (doc[11].status == "reupload") {
                mainactionSTRecallPlan = "reupload";
                STRecallPlanStatus = "reupload";
                STStatusArray[10].status = STRecallPlanStatus;
                $("#choose_STRecallPlan").show();
                $("#viewDocLink_STRecallPlan").hide();
                $("#X-btn_STRecallPlan").hide();
                $("#done-btn_STRecallPlan").hide();
                $("#review-btn_STRecallPlan").hide();
              } else if (doc[11].status == "done") {
                STRecallPlanStatus = "done";
                mainactionSTRecallPlan = "upload";
                STStatusArray[10].status = STRecallPlanStatus;
                $("#choose_STRecallPlan").hide();
                $("#X-btn_STRecallPlan").hide();
                $("#done-btn_STRecallPlan").show();
                $("#review-btn_STRecallPlan").hide();
              } else {
                STRecallPlanStatus = "open";
                STStatusArray[10].status = STRecallPlanStatus;
              }
            }
            this.STcheckRemainingReuploads();
            this.checkhandleSTProceedBtn();
          } else {
            this.showNotification("Something went wrong");
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "docuploadnotallowed") {
          this.setState({
            referralPartner:false,
          })
          this.showNotification(res.data.message);
        }  else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $(".serviceoverlay").hide();
      });
  };

  checkDropdownValue = () => {
    let sremove = this.statusCheckFunction("remove", SStatusArray);
    let sreview = this.statusCheckFunction("review", SStatusArray);
    let sdone = this.statusCheckFunction("done", SStatusArray);
    let sOptremove = this.statusCheckFunction("remove", SOptStatusArray);
    let sOptreview = this.statusCheckFunction("review", SOptStatusArray);
    let sOptdone = this.statusCheckFunction("done", SOptStatusArray);
    let saddremove = this.statusCheckFunction(
      "remove",
      this.state.SadditionalFiles
    );
    let saddreview = this.statusCheckFunction(
      "review",
      this.state.SadditionalFiles
    );
    let sadddone = this.statusCheckFunction(
      "done",
      this.state.SadditionalFiles
    );
    let premove = this.statusCheckFunction("remove", PStatusArray);
    let preview = this.statusCheckFunction("review", PStatusArray);
    let pdone = this.statusCheckFunction("done", PStatusArray);
    let pOptremove = this.statusCheckFunction("remove", POptStatusArray);
    let pOptreview = this.statusCheckFunction("review", POptStatusArray);
    let pOptdone = this.statusCheckFunction("done", POptStatusArray);
    let paddremove = this.statusCheckFunction(
      "remove",
      this.state.PadditionalFiles
    );
    let paddreview = this.statusCheckFunction(
      "review",
      this.state.PadditionalFiles
    );
    let padddone = this.statusCheckFunction(
      "done",
      this.state.PadditionalFiles
    );
    let plremove = this.statusCheckFunction("remove", PLStatusArray);
    let plreview = this.statusCheckFunction("review", PLStatusArray);
    let pldone = this.statusCheckFunction("done", PLStatusArray);
    let plOptremove = this.statusCheckFunction("remove", PLOptStatusArray);
    let plOptreview = this.statusCheckFunction("review", PLOptStatusArray);
    let plOptdone = this.statusCheckFunction("done", PLOptStatusArray);
    let pladdremove = this.statusCheckFunction(
      "remove",
      this.state.PLadditionalFiles
    );
    let pladdreview = this.statusCheckFunction(
      "review",
      this.state.PLadditionalFiles
    );
    let pladddone = this.statusCheckFunction(
      "done",
      this.state.PLadditionalFiles
    );
    let hufremove = this.statusCheckFunction("remove", HUFStatusArray);
    let hufreview = this.statusCheckFunction("review", HUFStatusArray);
    let hufdone = this.statusCheckFunction("done", HUFStatusArray);
    let hufOptremove = this.statusCheckFunction("remove", HUFOptStatusArray);
    let hufOptreview = this.statusCheckFunction("review", HUFOptStatusArray);
    let hufOptdone = this.statusCheckFunction("done", HUFOptStatusArray);
    let hufaddremove = this.statusCheckFunction(
      "remove",
      this.state.HUFadditionalFiles
    );
    let hufaddreview = this.statusCheckFunction(
      "review",
      this.state.HUFadditionalFiles
    );
    let hufadddone = this.statusCheckFunction(
      "done",
      this.state.HUFadditionalFiles
    );
    let stremove = this.statusCheckFunction("remove", STStatusArray);
    let streview = this.statusCheckFunction("review", STStatusArray);
    let stdone = this.statusCheckFunction("done", STStatusArray);
    let stOptremove = this.statusCheckFunction("remove", STOptStatusArray);
    let stOptreview = this.statusCheckFunction("review", STOptStatusArray);
    let stOptdone = this.statusCheckFunction("done", STOptStatusArray);
    let staddremove = this.statusCheckFunction(
      "remove",
      this.state.STadditionalFiles
    );
    let staddreview = this.statusCheckFunction(
      "review",
      this.state.STadditionalFiles
    );
    let stadddone = this.statusCheckFunction(
      "done",
      this.state.STadditionalFiles
    );

    if (this.state.dropDownValue == "FL_SolePI_Documents") {
      if (
        sremove == undefined &&
        sreview == undefined &&
        sdone == undefined &&
        sOptremove == undefined &&
        sOptreview == undefined &&
        sOptdone == undefined &&
        saddremove == undefined &&
        saddreview == undefined &&
        sadddone == undefined
      ) {
        $(".selectcateselect").attr("disabled", false);
      } else {
        $(".selectcateselect").attr("disabled", true);
      }
    } else if (this.state.dropDownValue == "FL_P_LLP_Documents") {
      if (
        premove == undefined &&
        preview == undefined &&
        pdone == undefined &&
        pOptremove == undefined &&
        pOptreview == undefined &&
        pOptdone == undefined &&
        paddremove == undefined &&
        paddreview == undefined &&
        padddone == undefined
      ) {
        $(".selectcateselect").attr("disabled", false);
      } else {
        $(".selectcateselect").attr("disabled", true);
      }
    } else if (this.state.dropDownValue == "FL_PRL_PL_OP_Documents") {
      if (
        plremove == undefined &&
        plreview == undefined &&
        pldone == undefined &&
        plOptremove == undefined &&
        plOptreview == undefined &&
        plOptdone == undefined &&
        pladdremove == undefined &&
        pladdreview == undefined &&
        pladddone == undefined
      ) {
        $(".selectcateselect").attr("disabled", false);
      } else {
        $(".selectcateselect").attr("disabled", true);
      }
    } else if (this.state.dropDownValue == "FL_HUF_Documents") {
      if (
        hufremove == undefined &&
        hufreview == undefined &&
        hufdone == undefined &&
        hufOptremove == undefined &&
        hufOptreview == undefined &&
        hufOptdone == undefined &&
        hufaddremove == undefined &&
        hufaddreview == undefined &&
        hufadddone == undefined
      ) {
        $(".selectcateselect").attr("disabled", false);
      } else {
        $(".selectcateselect").attr("disabled", true);
      }
    } else if (this.state.dropDownValue == "FL_STC_Documents") {
      if (
        stremove == undefined &&
        streview == undefined &&
        stdone == undefined &&
        stOptremove == undefined &&
        stOptreview == undefined &&
        stOptdone == undefined &&
        staddremove == undefined &&
        staddreview == undefined &&
        stadddone == undefined
      ) {
        $(".selectcateselect").attr("disabled", false);
      } else {
        $(".selectcateselect").attr("disabled", true);
      }
    }
  };

  logout = () => {
    localStorage.removeItem("gglltakoinoeenl");
    localStorage.removeItem("toeljgtkewlna");
    localStorage.removeItem("relleIsugad");
    this.props.history.push({
      pathname: `/`,
    });
  };
  handledropDownValueChange = (e) => {
    $(".proceednowbtn").attr("disabled", true);
    $("#volvo").attr("disabled", true);
    this.setState(
      {
        dropDownValue: e.target.value,
      },
      () => {
        if (this.state.dropDownValue == "FL_SolePI_Documents") {
          this.setState(
            {
              dropDownValue: "FL_SolePI_Documents",
              serviceType: "FL_SolePI_Documents",
            },
            () => {
              if (this.state.dropDownValue == "FL_SolePI_Documents") {
                $("#FoodLicense_default_Img").hide();
                $("#FoodLicense_SolePI").show();
                $("#FoodLicense_P_LLP").hide();
                $("#FoodLicense_PL").hide();
                $("#FoodLicense_HUF").hide();
                $("#FoodLicense_Society").hide();
              }
            }
          );
        } else if (this.state.dropDownValue == "FL_P_LLP_Documents") {
          this.setState(
            {
              dropDownValue: "FL_P_LLP_Documents",
              serviceType: "FL_P_LLP_Documents",
            },
            () => {
              if (this.state.dropDownValue == "FL_P_LLP_Documents") {
                $("#FoodLicense_default_Img").hide();
                $("#FoodLicense_SolePI").hide();
                $("#FoodLicense_P_LLP").show();
                $("#FoodLicense_PL").hide();
                $("#FoodLicense_HUF").hide();
                $("#FoodLicense_Society").hide();
              }
            }
          );
        } else if (this.state.dropDownValue == "FL_PRL_PL_OP_Documents") {
          this.setState(
            {
              dropDownValue: "FL_PRL_PL_OP_Documents",
              serviceType: "FL_PRL_PL_OP_Documents",
            },
            () => {
              if (this.state.dropDownValue == "FL_PRL_PL_OP_Documents") {
                $("#FoodLicense_default_Img").hide();
                $("#FoodLicense_SolePI").hide();
                $("#FoodLicense_P_LLP").hide();
                $("#FoodLicense_PL").show();
                $("#FoodLicense_HUF").hide();
                $("#FoodLicense_Society").hide();
              }
            }
          );
        } else if (this.state.dropDownValue == "FL_HUF_Documents") {
          this.setState(
            {
              dropDownValue: "FL_HUF_Documents",
              serviceType: "FL_HUF_Documents",
            },
            () => {
              if (this.state.dropDownValue == "FL_HUF_Documents") {
                $("#FoodLicense_default_Img").hide();
                $("#FoodLicense_SolePI").hide();
                $("#FoodLicense_P_LLP").hide();
                $("#FoodLicense_PL").hide();
                $("#FoodLicense_HUF").show();
                $("#FoodLicense_Society").hide();
              }
            }
          );
        } else if (this.state.dropDownValue == "FL_STC_Documents") {
          this.setState(
            {
              dropDownValue: "FL_STC_Documents",
              serviceType: "FL_STC_Documents",
            },
            () => {
              if (this.state.dropDownValue == "FL_STC_Documents") {
                $("#FoodLicense_default_Img").hide();
                $("#FoodLicense_SolePI").hide();
                $("#FoodLicense_P_LLP").hide();
                $("#FoodLicense_PL").hide();
                $("#FoodLicense_HUF").hide();
                $("#FoodLicense_Society").show();
              }
            }
          );
        }
      }
    );
  };

  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };

  statusCheckFunction = (status, array) => {
    if (this.state.licenseType == "T") {
      for (var i = 0; i < array.length - 5; i++) {
        if (array[i].status == status) {
          return i;
        }
      }
    } else {
      for (var i = 0; i < array.length; i++) {
        if (array[i].status == status) {
          return i;
        }
      }
    }
  };

  handleComment = (e) => {
    this.setState({
      comment: e.target.value,
    });
  };

  renderCommenthistory() {
    if (this.state.commentHistroyarray.length > 0) {
      return this.state.commentHistroyarray.map((activedoc, index) => {
        if (activedoc.commentBy == "user" && activedoc.special == "no") {
          return (
            <div className="rightconvtextdiv" key={index}>
              <p className="rightconvtext">{activedoc.comment}</p>
              <p className="rightconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "user" &&
          activedoc.special == "green"
        ) {
          return (
            <div className="rightconvtextdiv" key={index}>
              <p className="rightgreenconvtext">{activedoc.comment}</p>
              <p className="rightconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "employee" &&
          activedoc.special == "no"
        ) {
          return (
            <div className="leftconvtextdiv" key={index}>
              <p className="leftconvtext">{activedoc.comment}</p>
              <p className="leftconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "user" &&
          activedoc.special == "red"
        ) {
          return (
            <div className="rightunappconvtextdiv" key={index}>
              <p className="rightunappconvtext">{activedoc.comment}</p>
              <p className="rightconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "employee" &&
          activedoc.special == "green"
        ) {
          return (
            <div className="leftgreenconvtextdiv" key={index}>
              <p className="leftgreenconvtext">{activedoc.comment}</p>
              <p className="leftconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "employee" &&
          activedoc.special == "red"
        ) {
          return (
            <div className="leftconvtextdiv" key={index}>
              <p className="leftunappconvtext">{activedoc.comment}</p>
              <p className="leftconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        }
      });
    } else if (this.state.commentHistroyarray.length == 0) {
      return <p className="convservicename">No comments available!</p>;
    }
  }

  // Sole P/I

  // SAadhar
  handleSAadharChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "IDProofApplicant",
          },
          () => {
            $("#SAadharModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "IDProofApplicant",
            });
          }
        });
      $("#SAadharModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSAadhar = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_SAadhar").attr("disabled", true);
      $("#upload_btn20_SAadhar").hide();
      $("#upload_btnloader_SAadhar").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  SAadharDocLink: res.data.data.IDProofApplicant,
                },
                () => $("#viewDocLink_SAadhar").css("display", "block")
              );
              SAadharStatus = "remove";
              SStatusArray[0].status = SAadharStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#SAadharModal").modal("hide");
              $("#cancelbtn_SAadhar").attr("disabled", false);
              $("#upload_btn20_SAadhar").show();
              $("#upload_btnloader_SAadhar").hide();
              $("#choose_SAadhar").hide();
              $("#X-btn_SAadhar").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_SAadhar").attr("disabled", false);
            $("#upload_btn20_SAadhar").show();
            $("#upload_btnloader_SAadhar").hide();
            $("#SAadharModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_SAadhar").attr("disabled", false);
            $("#upload_btn20_SAadhar").show();
            $("#upload_btnloader_SAadhar").hide();
            $("#SAadharModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_SAadhar").attr("disabled", false);
          $("#upload_btn20_SAadhar").show();
          $("#upload_btnloader_SAadhar").hide();
          $("#SAadharModal").modal("hide");

          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSAadhar = () => {
    $("#X-btn_SAadhar").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "IDProofApplicant",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          SAadharStatus = res.data.data.status;
          SStatusArray[0].status = SAadharStatus;
          this.checkDropdownValue();
          this.checkhandleSProceedBtn();
          $("#choose_SAadhar").show();
          $("#X-btn_SAadhar").hide();
          $("#X-btn_SAadhar").attr("disabled", false);
          $("#viewDocLink_SAadhar").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_SAadhar").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_SAadhar").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSAadharseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SAadharseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "IDProofApplicant",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#SAadharseecommentsbtn").hide();
          $("#SAadharhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSAadharhidecommentsbtn = () => {
    $("#SAadharseecommentsbtn").show();
    $("#SAadharseecommentsbtn").attr("disabled", false);
    $("#SAadharhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // SPan

  // SPic
  handleSPicChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "ApplicantPhoto",
          },
          () => {
            $("#SPicModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "ApplicantPhoto",
            });
          }
        });
      $("#SPicModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSPic = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_SPic").attr("disabled", true);
      $("#upload_btn20_SPic").hide();
      $("#upload_btnloader_SPic").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  SPicDocLink: res.data.data.ApplicantPhoto,
                },
                () => $("#viewDocLink_SPic").css("display", "block")
              );
              SPicStatus = "remove";
              SStatusArray[1].status = SPicStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#SPicModal").modal("hide");
              $("#cancelbtn_SPic").attr("disabled", false);
              $("#upload_btn20_SPic").show();
              $("#upload_btnloader_SPic").hide();
              $("#choose_SPic").hide();
              $("#X-btn_SPic").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_SPic").attr("disabled", false);
            $("#upload_btn20_SPic").show();
            $("#upload_btnloader_SPic").hide();
            $("#SPicModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_SPic").attr("disabled", false);
            $("#upload_btn20_SPic").show();
            $("#upload_btnloader_SPic").hide();
            $("#SPicModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_SPic").attr("disabled", false);
          $("#upload_btn20_SPic").show();
          $("#upload_btnloader_SPic").hide();
          $("#SPicModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSPic = () => {
    $("#X-btn_SPic").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "ApplicantPhoto",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          SPicStatus = res.data.data.status;
          SStatusArray[1].status = SPicStatus;
          this.checkDropdownValue();
          this.checkhandleSProceedBtn();
          $("#choose_SPic").show();
          $("#X-btn_SPic").hide();
          $("#X-btn_SPic").attr("disabled", false);
          $("#viewDocLink_SPic").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_SPic").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_SPic").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSPicseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SPicseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "SolePhoto",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#SPicseecommentsbtn").hide();
          $("#SPichidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSPichidecommentsbtn = () => {
    $("#SPicseecommentsbtn").show();
    $("#SPicseecommentsbtn").attr("disabled", false);
    $("#SPichidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // SPUnit
  handleSPUnitChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "ProductionUnitPhoto",
          },
          () => {
            $("#SPUnitModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "ProductionUnitPhoto",
            });
          }
        });
      $("#SPUnitModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSPUnit = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_SPUnit").attr("disabled", true);
      $("#upload_btn20_SPUnit").hide();
      $("#upload_btnloader_SPUnit").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  SPUnitDocLink: res.data.data.ProductionUnitPhoto,
                },
                () => $("#viewDocLink_SPUnit").css("display", "block")
              );
              SPUnitStatus = "remove";
              SStatusArray[2].status = SPUnitStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#SPUnitModal").modal("hide");
              $("#cancelbtn_SPUnit").attr("disabled", false);
              $("#upload_btn20_SPUnit").show();
              $("#upload_btnloader_SPUnit").hide();
              $("#choose_SPUnit").hide();
              $("#X-btn_SPUnit").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_SPUnit").attr("disabled", false);
            $("#upload_btn20_SPUnit").show();
            $("#upload_btnloader_SPUnit").hide();
            $("#SPUnitModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_SPUnit").attr("disabled", false);
            $("#upload_btn20_SPUnit").show();
            $("#upload_btnloader_SPUnit").hide();
            $("#SPUnitModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_SPUnit").attr("disabled", false);
          $("#upload_btn20_SPUnit").show();
          $("#upload_btnloader_SPUnit").hide();
          $("#SPUnitModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSPUnit = () => {
    $("#X-btn_SPUnit").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "ProductionUnitPhoto",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          SPUnitStatus = res.data.data.status;
          SStatusArray[2].status = SPUnitStatus;
          this.checkDropdownValue();
          this.checkhandleSProceedBtn();
          $("#choose_SPUnit").show();
          $("#X-btn_SPUnit").hide();
          $("#X-btn_SPUnit").attr("disabled", false);
          $("#viewDocLink_SPUnit").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_SPUnit").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_SPUnit").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSPUnitseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SPUnitseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ProductionUnitPhoto",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#SPUnitseecommentsbtn").hide();
          $("#SPUnithidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSPUnithidecommentsbtn = () => {
    $("#SPUnitseecommentsbtn").show();
    $("#SPUnitseecommentsbtn").attr("disabled", false);
    $("#SPUnithidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // SLayoutPlan
  handleSLayoutPlanChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "LayoutPlanUnit",
          },
          () => {
            $("#SLayoutPlanModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "LayoutPlanUnit",
            });
          }
        });
      $("#SLayoutPlanModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSLayoutPlan = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_SLayoutPlan").attr("disabled", true);
      $("#upload_btn20_SLayoutPlan").hide();
      $("#upload_btnloader_SLayoutPlan").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  SLayoutPlanDocLink: res.data.data.LayoutPlanUnit,
                },
                () => $("#viewDocLink_SLayoutPlan").css("display", "block")
              );
              SLayoutPlanStatus = "remove";
              SStatusArray[3].status = SLayoutPlanStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#SLayoutPlanModal").modal("hide");
              $("#cancelbtn_SLayoutPlan").attr("disabled", false);
              $("#upload_btn20_SLayoutPlan").show();
              $("#upload_btnloader_SLayoutPlan").hide();
              $("#choose_SLayoutPlan").hide();
              $("#X-btn_SLayoutPlan").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_SLayoutPlan").attr("disabled", false);
            $("#upload_btn20_SLayoutPlan").show();
            $("#upload_btnloader_SLayoutPlan").hide();
            $("#SLayoutPlanModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_SLayoutPlan").attr("disabled", false);
            $("#upload_btn20_SLayoutPlan").show();
            $("#upload_btnloader_SLayoutPlan").hide();
            $("#SLayoutPlanModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_SLayoutPlan").attr("disabled", false);
          $("#upload_btn20_SLayoutPlan").show();
          $("#upload_btnloader_SLayoutPlan").hide();
          $("#SLayoutPlanModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSLayoutPlan = () => {
    $("#X-btn_SLayoutPlan").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "LayoutPlanUnit",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          SLayoutPlanStatus = res.data.data.status;
          SStatusArray[3].status = SLayoutPlanStatus;
          this.checkDropdownValue();
          this.checkhandleSProceedBtn();
          $("#choose_SLayoutPlan").show();
          $("#X-btn_SLayoutPlan").hide();
          $("#X-btn_SLayoutPlan").attr("disabled", false);
          $("#viewDocLink_SLayoutPlan").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_SLayoutPlan").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_SLayoutPlan").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSLayoutPlanseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SLayoutPlanseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "LayoutPlanUnit",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#SLayoutPlanseecommentsbtn").hide();
          $("#SLayoutPlanhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSLayoutPlanhidecommentsbtn = () => {
    $("#SLayoutPlanseecommentsbtn").show();
    $("#SLayoutPlanseecommentsbtn").attr("disabled", false);
    $("#SLayoutPlanhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // SListofEquip
  handleSListofEquipChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "ListOfEquipment",
          },
          () => {
            $("#SListofEquipModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "ListOfEquipment",
            });
          }
        });
      $("#SListofEquipModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSListofEquip = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_SListofEquip").attr("disabled", true);
      $("#upload_btn20_SListofEquip").hide();
      $("#upload_btnloader_SListofEquip").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  SListofEquipDocLink: res.data.data.ListOfEquipment,
                },
                () => $("#viewDocLink_SListofEquip").css("display", "block")
              );
              SListofEquipStatus = "remove";
              SStatusArray[4].status = SListofEquipStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#SListofEquipModal").modal("hide");
              $("#cancelbtn_SListofEquip").attr("disabled", false);
              $("#upload_btn20_SListofEquip").show();
              $("#upload_btnloader_SListofEquip").hide();
              $("#choose_SListofEquip").hide();
              $("#X-btn_SListofEquip").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_SListofEquip").attr("disabled", false);
            $("#upload_btn20_SListofEquip").show();
            $("#upload_btnloader_SListofEquip").hide();
            $("#SListofEquipModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_SListofEquip").attr("disabled", false);
            $("#upload_btn20_SListofEquip").show();
            $("#upload_btnloader_SListofEquip").hide();
            $("#SListofEquipModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_SListofEquip").attr("disabled", false);
          $("#upload_btn20_SListofEquip").show();
          $("#upload_btnloader_SListofEquip").hide();
          $("#SListofEquipModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSListofEquip = () => {
    $("#X-btn_SListofEquip").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "ListOfEquipment",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          SListofEquipStatus = res.data.data.status;
          SStatusArray[4].status = SListofEquipStatus;
          this.checkDropdownValue();
          this.checkhandleSProceedBtn();
          $("#choose_SListofEquip").show();
          $("#X-btn_SListofEquip").hide();
          $("#X-btn_SListofEquip").attr("disabled", false);
          $("#viewDocLink_SListofEquip").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_SListofEquip").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_SListofEquip").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSListofEquipseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SListofEquipseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ListOfEquipment",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#SListofEquipseecommentsbtn").hide();
          $("#SListofEquiphidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSListofEquiphidecommentsbtn = () => {
    $("#SListofEquipseecommentsbtn").show();
    $("#SListofEquipseecommentsbtn").attr("disabled", false);
    $("#SListofEquiphidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // SWAReport
  handleSWAReportChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "WaterAnalysisReport",
          },
          () => {
            $("#SWAReportModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "WaterAnalysisReport",
            });
          }
        });
      $("#SWAReportModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSWAReport = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_SWAReport").attr("disabled", true);
      $("#upload_btn20_SWAReport").hide();
      $("#upload_btnloader_SWAReport").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  SWAReportDocLink: res.data.data.WaterAnalysisReport,
                },
                () => $("#viewDocLink_SWAReport").css("display", "block")
              );
              SWAReportStatus = "remove";
              SStatusArray[5].status = SWAReportStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#SWAReportModal").modal("hide");
              $("#cancelbtn_SWAReport").attr("disabled", false);
              $("#upload_btn20_SWAReport").show();
              $("#upload_btnloader_SWAReport").hide();
              $("#choose_SWAReport").hide();
              $("#X-btn_SWAReport").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_SWAReport").attr("disabled", false);
            $("#upload_btn20_SWAReport").show();
            $("#upload_btnloader_SWAReport").hide();
            $("#SWAReportModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_SWAReport").attr("disabled", false);
            $("#upload_btn20_SWAReport").show();
            $("#upload_btnloader_SWAReport").hide();
            $("#SWAReportModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_SWAReport").attr("disabled", false);
          $("#upload_btn20_SWAReport").show();
          $("#upload_btnloader_SWAReport").hide();
          $("#SWAReportModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSWAReport = () => {
    $("#X-btn_SWAReport").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "WaterAnalysisReport",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          SWAReportStatus = res.data.data.status;
          SStatusArray[5].status = SWAReportStatus;
          this.checkDropdownValue();
          this.checkhandleSProceedBtn();
          $("#choose_SWAReport").show();
          $("#X-btn_SWAReport").hide();
          $("#X-btn_SWAReport").attr("disabled", false);
          $("#viewDocLink_SWAReport").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_SWAReport").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_SWAReport").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSWAReportseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SWAReportseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "WaterAnalysisReport",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#SWAReportseecommentsbtn").hide();
          $("#SWAReporthidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSWAReporthidecommentsbtn = () => {
    $("#SWAReportseecommentsbtn").show();
    $("#SWAReportseecommentsbtn").attr("disabled", false);
    $("#SWAReporthidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // SListofDir
  handleSListofDirChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "DirectorsFullAddress",
          },
          () => {
            $("#SListofDirModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "DirectorsFullAddress",
            });
          }
        });
      $("#SListofDirModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSListofDir = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_SListofDir").attr("disabled", true);
      $("#upload_btn20_SListofDir").hide();
      $("#upload_btnloader_SListofDir").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  SListofDirDocLink: res.data.data.DirectorsFullAddress,
                },
                () => $("#viewDocLink_SListofDir").css("display", "block")
              );
              SListofDirStatus = "remove";
              SStatusArray[6].status = SListofDirStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#SListofDirModal").modal("hide");
              $("#cancelbtn_SListofDir").attr("disabled", false);
              $("#upload_btn20_SListofDir").show();
              $("#upload_btnloader_SListofDir").hide();
              $("#choose_SListofDir").hide();
              $("#X-btn_SListofDir").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_SListofDir").attr("disabled", false);
            $("#upload_btn20_SListofDir").show();
            $("#upload_btnloader_SListofDir").hide();
            $("#SListofDirModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_SListofDir").attr("disabled", false);
            $("#upload_btn20_SListofDir").show();
            $("#upload_btnloader_SListofDir").hide();
            $("#SListofDirModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_SListofDir").attr("disabled", false);
          $("#upload_btn20_SListofDir").show();
          $("#upload_btnloader_SListofDir").hide();
          $("#SListofDirModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSListofDir = () => {
    $("#X-btn_SListofDir").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "DirectorsFullAddress",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          SListofDirStatus = res.data.data.status;
          SStatusArray[6].status = SListofDirStatus;
          this.checkDropdownValue();
          this.checkhandleSProceedBtn();
          $("#choose_SListofDir").show();
          $("#X-btn_SListofDir").hide();
          $("#X-btn_SListofDir").attr("disabled", false);
          $("#viewDocLink_SListofDir").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_SListofDir").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_SListofDir").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSListofDirseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SListofDirseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "DirectorsFullAddress",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#SListofDirseecommentsbtn").hide();
          $("#SListofDirhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSListofDirhidecommentsbtn = () => {
    $("#SListofDirseecommentsbtn").show();
    $("#SListofDirseecommentsbtn").attr("disabled", false);
    $("#SListofDirhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // SRecallPlan
  handleSRecallPlanChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "RecallPlan",
          },
          () => {
            $("#SRecallPlanModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "RecallPlan",
            });
          }
        });
      $("#SRecallPlanModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSRecallPlan = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_SRecallPlan").attr("disabled", true);
      $("#upload_btn20_SRecallPlan").hide();
      $("#upload_btnloader_SRecallPlan").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  SRecallPlanDocLink: res.data.data.RecallPlan,
                },
                () => $("#viewDocLink_SRecallPlan").css("display", "block")
              );
              SRecallPlanStatus = "remove";
              SStatusArray[7].status = SRecallPlanStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#SRecallPlanModal").modal("hide");
              $("#cancelbtn_SRecallPlan").attr("disabled", false);
              $("#upload_btn20_SRecallPlan").show();
              $("#upload_btnloader_SRecallPlan").hide();
              $("#choose_SRecallPlan").hide();
              $("#X-btn_SRecallPlan").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_SRecallPlan").attr("disabled", false);
            $("#upload_btn20_SRecallPlan").show();
            $("#upload_btnloader_SRecallPlan").hide();
            $("#SRecallPlanModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_SRecallPlan").attr("disabled", false);
            $("#upload_btn20_SRecallPlan").show();
            $("#upload_btnloader_SRecallPlan").hide();
            $("#SRecallPlanModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_SRecallPlan").attr("disabled", false);
          $("#upload_btn20_SRecallPlan").show();
          $("#upload_btnloader_SRecallPlan").hide();
          $("#SRecallPlanModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSRecallPlan = () => {
    $("#X-btn_SRecallPlan").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "RecallPlan",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          SRecallPlanStatus = res.data.data.status;
          SStatusArray[7].status = SRecallPlanStatus;
          this.checkDropdownValue();
          this.checkhandleSProceedBtn();
          $("#choose_SRecallPlan").show();
          $("#X-btn_SRecallPlan").hide();
          $("#X-btn_SRecallPlan").attr("disabled", false);
          $("#viewDocLink_SRecallPlan").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_SRecallPlan").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_SRecallPlan").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSRecallPlanseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SRecallPlanseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "RecallPlan",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#SRecallPlanseecommentsbtn").hide();
          $("#SRecallPlanhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSRecallPlanhidecommentsbtn = () => {
    $("#SRecallPlanseecommentsbtn").show();
    $("#SRecallPlanseecommentsbtn").attr("disabled", false);
    $("#SRecallPlanhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // SBP

  // SGumasta

  // SNature
  handleSbusinessNatureChange = (e) => {
    this.setState({
      SbusinessNature: e.target.value,
    });
  };

  // SAddressProofType
  SAddressProofType_radioChange = (e) => {
    this.setState(
      {
        SAddressProofType_radio: e.currentTarget.value,
      },
      () => {
        if (this.state.SAddressProofType_radio != "CopyOfRegistry") {
          $(".RentAgreement").css("display", "flex");
          $(".CopyOfRegistry").hide();
        } else if (this.state.SAddressProofType_radio == "CopyOfRegistry") {
          $(".CopyOfRegistry").css("display", "flex");
          $(".RentAgreement").hide();
        }
        this.checkhandleSProceedBtn();
      }
    );
  };

  // SCopyOfRegistry
  handleSCopyOfRegistryChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "OwnershipDeed",
          },
          () => {
            $("#SCopyOfRegistryModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "OwnershipDeed",
            });
          }
        });
      $("#SCopyOfRegistryModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSCopyOfRegistry = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_SCopyOfRegistry").attr("disabled", true);
      $("#upload_btn20_SCopyOfRegistry").hide();
      $("#upload_btnloader_SCopyOfRegistry").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  SCopyOfRegistryDocLink: res.data.data.OwnershipDeed,
                },
                () => $("#viewDocLink_SCopyOfRegistry").css("display", "block")
              );
              SCopyOfRegistryStatus = "remove";
              SOptStatusArray[0].status = SCopyOfRegistryStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#SCopyOfRegistryModal").modal("hide");
              $("#cancelbtn_SCopyOfRegistry").attr("disabled", false);
              $("#upload_btn20_SCopyOfRegistry").show();
              $("#upload_btnloader_SCopyOfRegistry").hide();
              $("#choose_SCopyOfRegistry").hide();
              $("#X-btn_SCopyOfRegistry").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_SCopyOfRegistry").attr("disabled", false);
            $("#upload_btn20_SCopyOfRegistry").show();
            $("#upload_btnloader_SCopyOfRegistry").hide();
            $("#SCopyOfRegistryModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_SCopyOfRegistry").attr("disabled", false);
            $("#upload_btn20_SCopyOfRegistry").show();
            $("#upload_btnloader_SCopyOfRegistry").hide();
            $("#SCopyOfRegistryModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_SCopyOfRegistry").attr("disabled", false);
          $("#upload_btn20_SCopyOfRegistry").show();
          $("#upload_btnloader_SCopyOfRegistry").hide();
          $("#SCopyOfRegistryModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSCopyOfRegistry = () => {
    $("#X-btn_SCopyOfRegistry").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "OwnershipDeed",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          SCopyOfRegistryStatus = res.data.data.status;
          SOptStatusArray[0].status = SCopyOfRegistryStatus;
          this.checkDropdownValue();
          this.checkhandleSProceedBtn();
          $("#choose_SCopyOfRegistry").show();
          $("#X-btn_SCopyOfRegistry").hide();
          $("#X-btn_SCopyOfRegistry").attr("disabled", false);
          $("#viewDocLink_SCopyOfRegistry").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_SCopyOfRegistry").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_SCopyOfRegistry").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSCopyOfRegistryseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SCopyOfRegistryseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "OwnershipDeed",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#SCopyOfRegistryseecommentsbtn").hide();
          $("#SCopyOfRegistryhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSCopyOfRegistryhidecommentsbtn = () => {
    $("#SCopyOfRegistryseecommentsbtn").show();
    $("#SCopyOfRegistryseecommentsbtn").attr("disabled", false);
    $("#SCopyOfRegistryhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // SRentAgreement
  handleSRentAgreementChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "RentAgreement",
          },
          () => {
            $("#SRentAgreementModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "RentAgreement",
            });
          }
        });
      $("#SRentAgreementModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSRentAgreement = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_SRentAgreement").attr("disabled", true);
      $("#upload_btn20_SRentAgreement").hide();
      $("#upload_btnloader_SRentAgreement").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  SRentAgreementDocLink: res.data.data.RentAgreement,
                },
                () => $("#viewDocLink_SRentAgreement").css("display", "block")
              );
              SRentAgreementStatus = "remove";
              SOptStatusArray[1].status = SRentAgreementStatus;
              this.checkDropdownValue();
              this.checkhandleSProceedBtn();
              $("#SRentAgreementModal").modal("hide");
              $("#cancelbtn_SRentAgreement").attr("disabled", false);
              $("#upload_btn20_SRentAgreement").show();
              $("#upload_btnloader_SRentAgreement").hide();
              $("#choose_SRentAgreement").hide();
              $("#X-btn_SRentAgreement").show();
            }
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_SRentAgreement").attr("disabled", false);
            $("#upload_btn20_SRentAgreement").show();
            $("#upload_btnloader_SRentAgreement").hide();
            $("#SRentAgreementModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_SRentAgreement").attr("disabled", false);
            $("#upload_btn20_SRentAgreement").show();
            $("#upload_btnloader_SRentAgreement").hide();
            $("#SRentAgreementModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_SRentAgreement").attr("disabled", false);
          $("#upload_btn20_SRentAgreement").show();
          $("#upload_btnloader_SRentAgreement").hide();
          $("#SRentAgreementModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSRentAgreement = () => {
    $("#X-btn_SRentAgreement").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "RentAgreement",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          SRentAgreementStatus = res.data.data.status;
          SOptStatusArray[1].status = SRentAgreementStatus;
          this.checkDropdownValue();
          this.checkhandleSProceedBtn();
          $("#choose_SRentAgreement").show();
          $("#X-btn_SRentAgreement").hide();
          $("#X-btn_SRentAgreement").attr("disabled", false);
          $("#viewDocLink_SRentAgreement").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_SRentAgreement").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_SRentAgreement").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSRentAgreementseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SRentAgreementseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "RentAgreement",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#SRentAgreementseecommentsbtn").hide();
          $("#SRentAgreementhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSRentAgreementhidecommentsbtn = () => {
    $("#SRentAgreementseecommentsbtn").show();
    $("#SRentAgreementseecommentsbtn").attr("disabled", false);
    $("#SRentAgreementhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // SAdditional
  handleSAddFileChange = (e, index, status) => {
    SadditionalReuploadIndex = index;
    SadditionalReuploadStatus = status;
    $(".upload_btn20_7").show();
    $(".upload_btnloader_7").hide();
    const file = e.target.files[0];
    if (!SadditionalFilesNames.includes(this.state.SAdd_DocName.trim())) {
      const myfile = e.target.files;
      if (ChooseNowBtnsDisable == "Yes") {
        this.showNotification(
          "Files cannot be uploaded when the service is locked!"
        );
      } else if (myfile.length == 1) {
        const file = myfile[0];
        if (file.type.match("audio.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.type.match("video.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.size > 104857600) {
          this.showNotification("File too big!");
        } else if (file.name.length > 100) {
          this.showNotification("File name limit exceed!");
        } else {
          this.setState(
            {
              fileName: file.name,
              fileBase64: file,
              documentType: "additional",
              documentName: this.state.SAdd_DocName,
            },
            () => {
              $(".SAddModal").modal({
                backdrop: "static",
                keyboard: false,
              });
            }
          );
        }
      } else if (myfile.length > 1) {
        var zip = new JSZip();
        for (let i = 0; i < myfile.length; i++) {
          zip.file(myfile[i].name, myfile[i], {
            base64: true,
          });
        }
        zip
          .generateAsync({
            type: "blob",
          })
          .then((content) => {
            if (content.size > 104857600) {
              this.showNotification("File too big!");
            } else {
              this.setState({
                fileName: "download.zip",
                fileBase64: content,
                documentType: "additional",
                documentName: this.state.SAdd_DocName,
              });
            }
          });
        $(".SAddModal").modal({
          backdrop: "static",
          keyboard: false,
        });
      } else {
        this.showNotification("File cannot be null!");
      }
    } else {
      this.showNotification(
        "Document names can not be the same, Try again with different name!"
      );
    }
  };

  uploadSFile = (FileIndex, FileStatus) => {
    let AdditionaldocumentName1;
    if (FileStatus == "reupload") {
      $(".proceednowbtn").attr("disabled", true);
      let fileTobeUploaded = this.state.SadditionalFiles[FileIndex];
      AdditionaldocumentName1 = Object.keys(fileTobeUploaded)[0];
      mainaction = "reupload";
    } else {
      AdditionaldocumentName1 = this.state.documentName;
      mainaction = "upload";
    }
    if (AdditionaldocumentName1.trim() == "") {
      $(".SAddModal").modal("hide");
      this.showNotification("Enter document Name first");
    } else if (this.state.comment.length <= 250) {
      $(".cancelbtn_7").attr("disabled", true);
      $(".upload_btn20_7").hide();
      $(".upload_btnloader_7").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", AdditionaldocumentName1);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            $(".SAddModal").modal("hide");
            $(".cancelbtn_7").attr("disabled", false);
            $(`#${FileIndex}SFileseecommentsbtn`).attr("disabled", false);
            if (SadditionalReupload == false) {
              SadditionalFilesNames.push(this.state.SAdd_DocName);
              this.state.SadditionalFiles.pop();
              this.setState(
                {
                  comment: "",
                  SadditionalFiles: [
                    ...this.state.SadditionalFiles,
                    res.data.data,
                  ],
                },
                () => {
                  this.setState(
                    {
                      api_SadditionalFiles: "Yes",
                    },
                    () => {
                      this.SadditionalFilesArea();
                      this.checkDropdownValue();
                      this.checkhandleSProceedBtn();
                    }
                  );

                  $(".SadditionalInput").attr("disabled", true);
                }
              );
            } else if (SadditionalReupload == true) {
              $(".proceednowbtn").attr("disabled", false);
              var array = [...this.state.SadditionalFiles];
              array.splice(FileIndex, 1, res.data.data);
              this.setState(
                {
                  comment: "",
                  api_SadditionalFiles: "Yes",
                  SadditionalFiles: array,
                },
                () => {
                  this.SadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleSProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".SAddModal").modal("hide");
            this.showNotification("Something Went Wrong!");
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".SAddModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          this.showNotification("Something went wrong, Try again!");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSFile = (index) => {
    $("#X-btn_7").attr("disabled", true);
    let fileTobeDeleted = this.state.SadditionalFiles[index];
    let NameOfAddFile = Object.keys(fileTobeDeleted)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "additional",
          documentName: NameOfAddFile,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          var array = [...this.state.SadditionalFiles];
          if (res.data.data.status == "reupload") {
            array[index].status = "reupload";
            this.setState(
              {
                SadditionalFiles: array,
              },
              () => {
                this.SadditionalFilesArea();
                this.checkDropdownValue();
                this.checkhandleSProceedBtn();
              }
            );
            this.showNotification(res.data.message);
          } else {
            array.splice(index, 1);
            if (array.length > 0) {
              this.setState(
                {
                  SadditionalFiles: array,
                },
                () => {
                  this.SadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleSProceedBtn();
                }
              );
            } else if (array.length == 0) {
              this.setState(
                {
                  SadditionalFiles: [],
                  SAdd_DocName: "",
                  api_SadditionalFiles: "No",
                  IsSAddFileDisabled: true,
                },
                () => {
                  this.SadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleSProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_7").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSFileseeComments = (index) => {
    $(".commentsbtn").attr("disabled", false);
    $(`#${index}SFileseecommentsbtn`).attr("disabled", true);
    let SelectedFile = this.state.SadditionalFiles[index];
    let NameOfAddFile = Object.keys(SelectedFile)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "additional",
          documentName: NameOfAddFile,
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(`#${index}SFileseecommentsbtn`).hide();
          $(".hidecommentsbtn").hide();
          $(`#${index}SFilehidecommentsbtn`).show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSFilehideComments = (index) => {
    $(".seecommentsbtn").show();
    $(`#${index}SFileseecommentsbtn`).show();
    $(`#${index}SFileseecommentsbtn`).attr("disabled", false);
    $(`#${index}SFilehidecommentsbtn`).hide();
    $(".hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  checkAddCommentSFiles = (FileIndex, isDisabled) => {
    if (isDisabled == true) {
      return (
        <>
          <button
            className="seecommentsbtn SFileseecommentsbtn"
            id={`${FileIndex}SFileseecommentsbtn`}
            disabled
            onClick={() => this.handleSFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn SFilehidecommentsbtn"
            id={`${FileIndex}SFilehidecommentsbtn`}
            onClick={() => this.handleSFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    } else if (isDisabled == false) {
      return (
        <>
          <button
            className="seecommentsbtn commentsbtn SFileseecommentsbtn"
            id={`${FileIndex}SFileseecommentsbtn`}
            onClick={() => this.handleSFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn SFilehidecommentsbtn"
            id={`${FileIndex}SFilehidecommentsbtn`}
            onClick={() => this.handleSFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    }
  };

  checkStatusSAddFiles = (status, link, index) => {
    SadditionalFilesIndex = index;
    if (HideProceedBtn == "Yes") {
      $(".proceednowbtn").hide();
      if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
          </>
        );
      }
    } else {
      if (status == undefined) {
        status = "open";
      }
      if (status == "open") {
        mainaction = "upload";
        SadditionalReupload = true;
        $(".proceednowbtn").show();
        return (
          <>
            <label
              htmlFor={`ul_file_SAdd${index}`}
              className="choose_btn choose_7"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              multiple
              name="file[]"
              className="upload-photo"
              id={`ul_file_SAdd${index}`}
              // disabled={this.state.IsSAddFileDisabled}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handleSAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "remove") {
        mainaction = "upload";
        $(".proceednowbtn").show();
        return (
          <>
            <button
              className="X-btn X-btn_7"
              onClick={() => this.removeSFile(index)}
            >
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_1025.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "reupload") {
        SadditionalReupload = true;
        mainaction = "reupload";
        $(".proceednowbtn").show();
        $(".proceednowbtn").attr("disabled", true);
        return (
          <>
            <label
              htmlFor={`ul_file_SAdd${index}`}
              className="choose_btn choose_7"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              multiple
              name="file[]"
              className="upload-photo"
              id={`ul_file_SAdd${index}`}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handleSAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
          </>
        );
      }
    }
  };

  AdditionalSFileDisabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero SadditionalInput"
        value={NameOfAddFile}
        disabled
        onChange={(e) => this.handleSAdd_DocNameChange(e)}
      />
    );
  };

  AdditionalSFileEnabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero SadditionalInput"
        value={NameOfAddFile}
        onChange={(e) => this.handleSAdd_DocNameChange(e)}
      />
    );
  };

  SadditionalFilesArea = () => {
    SadditionalFilesNames = [];
    if (this.state.api_SadditionalFiles == "Yes") {
      return this.state.SadditionalFiles.map((file, index) => {
        let NameOfAddFile = Object.keys(file)[0];
        SadditionalFilesNames.push(NameOfAddFile);
        let link = file[Object.keys(file)[0]];
        let AdditionalFileInputField;
        let commentDisabled;
        if (file.status == "open" || file.status == undefined) {
          AdditionalFileInputField =
            this.AdditionalSFileEnabledField(NameOfAddFile);
          commentDisabled = true;
        } else if (file.status != "open") {
          AdditionalFileInputField =
            this.AdditionalSFileDisabledField(NameOfAddFile);
          commentDisabled = false;
        }
        return (
          <div className="displayflex" key={index}>
            <div className="uploadnocol">
              <p className="uploadadditionalsidetext">{index + 1}&#41;</p>
            </div>
            <div className="uploadadditionaldatacol">
              <div className="row mb-2">
                <div className="col-md-5">{AdditionalFileInputField}</div>
                <div className="col-md-7">
                  <div className="float-md-right float-left">
                    {this.checkAddCommentSFiles(index, commentDisabled)}
                    {this.checkStatusSAddFiles(file.status, link, index)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.api_SadditionalFiles == "No") {
      return (
        <div className="displayflex">
          <div className="uploadnocol">
            <p className="uploadadditionalsidetext">1&#41;</p>
          </div>
          <div className="uploadadditionaldatacol">
            <div className="row mb-2">
              <div className="col-md-5">
                <input
                  type="text"
                  placeholder="Document name..."
                  maxLength="150"
                  className="uploadtextfield marginzero"
                  value={this.state.SAdd_DocName}
                  onChange={(e) => this.handleSAdd_DocNameChange(e)}
                />
              </div>
              <div className="col-md-7">
                <div className="fl_right">
                  {this.checkAddCommentSFiles(0, true)}
                  {this.checkStatusSAddFiles("open", null, 0)}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  handleSAdd_DocNameChange = (e) => {
    this.setState(
      {
        SAdd_DocName: e.target.value,
      },
      () => {
        if (this.state.SAdd_DocName.trim().length > 0) {
          this.setState({
            IsSAddFileDisabled: false,
          });
        } else {
          this.setState({
            IsSAddFileDisabled: true,
          });
        }
      }
    );
  };

  handleAddSFileBtn = (FileIndex) => {
    const { SAdd_DocName, SadditionalFiles } = this.state;

    let prevFile = SadditionalFiles[FileIndex];

    let NameOfPrevFile = Object.keys(prevFile)[0];
    let valueOffile = prevFile[Object.keys(prevFile)[0]];

    if (FileIndex == undefined) {
      this.showNotification("empty fields");
    } else if (SAdd_DocName.trim() == "") {
      this.showNotification("Required fields cannot be empty!");
    } else if (valueOffile == null) {
      this.showNotification("Choose a file");
    } else {
      this.setState(
        {
          SadditionalFiles: [...this.state.SadditionalFiles, ""],
          SAdd_DocName: "",
          IsSAddFileDisabled: true,
        },
        () => {
          $(".SadditionalInput").attr("disabled", false);
        }
      );
    }
  };

  // Proceed Checks
  ScheckRemainingReuploads = () => {
    if (this.state.licenseType == "T") {
      if (this.state.SAddressProofType_radio == "CopyOfRegistry") {
        if (
          SAadharStatus != "reupload" &&
          SPicStatus != "reupload" &&
          SCopyOfRegistryStatus != "reupload"
        ) {
          AnyReuploadOccuredS = "No";
        } else {
          $(".proceednowbtn1").show();
          AnyReuploadOccuredS = "Yes";
        }
      } else {
        if (
          SAadharStatus != "reupload" &&
          SPicStatus != "reupload" &&
          SRentAgreementStatus != "reupload"
        ) {
          AnyReuploadOccuredS = "No";
        } else {
          $(".proceednowbtn1").show();
          AnyReuploadOccuredS = "Yes";
        }
      }
    } else {
      if (this.state.SAddressProofType_radio == "CopyOfRegistry") {
        if (
          SAadharStatus != "reupload" &&
          SPicStatus != "reupload" &&
          SPUnitStatus != "reupload" &&
          SLayoutPlanStatus != "reupload" &&
          SListofEquipStatus != "reupload" &&
          SWAReportStatus != "reupload" &&
          SListofDirStatus != "reupload" &&
          SRecallPlanStatus != "reupload" &&
          SCopyOfRegistryStatus != "reupload"
        ) {
          AnyReuploadOccuredS = "No";
        } else {
          $(".proceednowbtn1").show();
          AnyReuploadOccuredS = "Yes";
        }
      } else {
        if (
          SAadharStatus != "reupload" &&
          SPicStatus != "reupload" &&
          SPUnitStatus != "reupload" &&
          SLayoutPlanStatus != "reupload" &&
          SListofEquipStatus != "reupload" &&
          SWAReportStatus != "reupload" &&
          SListofDirStatus != "reupload" &&
          SRecallPlanStatus != "reupload" &&
          SRentAgreementStatus != "reupload"
        ) {
          AnyReuploadOccuredS = "No";
        } else {
          $(".proceednowbtn1").show();
          AnyReuploadOccuredS = "Yes";
        }
      }
    }
  };

  checkhandleSProceedBtn = () => {
    let statusCheckReupload = this.statusCheckFunction(
      "reupload",
      SStatusArray
    );
    let SadditionalStatusArrayCheck = this.statusCheckFunction(
      "reupload",
      this.state.SadditionalFiles
    );
    let goodtogo = "No";
    if (this.state.SAddressProofType_radio == "CopyOfRegistry") {
      if (
        SOptStatusArray[0].status != "reupload" &&
        SOptStatusArray[0].status != "upload" &&
        SOptStatusArray[0].status != "open"
      ) {
        goodtogo = "Yes";
      } else {
        goodtogo = "No";
      }
    } else if (this.state.SAddressProofType_radio == "RentAgreement") {
      if (
        SOptStatusArray[1].status != "reupload" &&
        SOptStatusArray[1].status != "upload" &&
        SOptStatusArray[1].status != "open"
      ) {
        goodtogo = "Yes";
      } else {
        goodtogo = "No";
      }
    } else {
      goodtogo = "No";
    }

    if (
      AnyReuploadOccuredS == "Yes" &&
      statusCheckReupload == undefined &&
      goodtogo == "Yes" &&
      SadditionalStatusArrayCheck == undefined
    ) {
      $(".proceednowbtn1").attr("disabled", false);
      Sproceednowforword = "success";
    } else if (
      SAadharStatus == "remove" &&
      SPicStatus == "remove" &&
      this.state.licenseType == "T" &&
      goodtogo == "Yes"
    ) {
      $(".proceednowbtn1").attr("disabled", false);
      Sproceednowforword = "success";
    } else if (
      SAadharStatus == "remove" &&
      SPicStatus == "remove" &&
      SPUnitStatus == "remove" &&
      SLayoutPlanStatus == "remove" &&
      SListofEquipStatus == "remove" &&
      SWAReportStatus == "remove" &&
      SListofDirStatus == "remove" &&
      SRecallPlanStatus == "remove" &&
      this.state.licenseType == "M" &&
      goodtogo == "Yes"
    ) {
      $(".proceednowbtn1").attr("disabled", false);
      Sproceednowforword = "success";
    } else {
      $(".proceednowbtn1").attr("disabled", true);
      Sproceednowforword = "fail";
    }
  };

  handleSProceedBtn = () => {
    this.checkhandleSProceedBtn();
    if (this.state.SbusinessNature.trim() == "") {
      this.showNotification("Required fields can not be empty!");
    } else if (this.state.SbusinessNature.trim().length > 200) {
      this.showNotification("Nature of your Business / Brand limit exceed!");
    } else if (Sproceednowforword == "success") {
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=lockService",
          {
            userId: Decuserid,
            requestType: temp,
            agentId: Decagentid,

            serviceId: Decserviceid,
            serviceName: Decservicename,
            serviceType: this.state.serviceType,
            FY: "",
            Month: "",
            inputFields: [
              {
                inputFieldName: "AddressProofType",
                inputFieldValue: this.state.SAddressProofType_radio,
                inputFieldtype: "general",
              },
              {
                inputFieldName: "Nature",
                inputFieldValue: this.state.SbusinessNature,
                inputFieldtype: "general",
              },
            ],
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.showNotification(res.data.message);
            this.getserviceInformationApi();
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else {
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => { });
    }
  };

  // Partnership

  // PAadhar1
  handlePAadhar1Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "IDProofApplicant1",
          },
          () => {
            $("#PAadhar1Modal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "IDProofApplicant1",
            });
          }
        });
      $("#PAadhar1Modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPAadhar1 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PAadhar1").attr("disabled", true);
      $("#upload_btn20_PAadhar1").hide();
      $("#upload_btnloader_PAadhar1").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PAadhar1DocLink: res.data.data.IDProofApplicant1,
                },
                () => $("#viewDocLink_PAadhar1").css("display", "block")
              );
              PAadhar1Status = "remove";
              PStatusArray[0].status = PAadhar1Status;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PAadhar1Modal").modal("hide");
              $("#cancelbtn_PAadhar1").attr("disabled", false);
              $("#upload_btn20_PAadhar1").show();
              $("#upload_btnloader_PAadhar1").hide();
              $("#choose_PAadhar1").hide();
              $("#X-btn_PAadhar1").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PAadhar1").attr("disabled", false);
            $("#upload_btn20_PAadhar1").show();
            $("#upload_btnloader_PAadhar1").hide();
            $("#PAadhar1Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PAadhar1").attr("disabled", false);
            $("#upload_btn20_PAadhar1").show();
            $("#upload_btnloader_PAadhar1").hide();
            $("#PAadhar1Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PAadhar1").attr("disabled", false);
          $("#upload_btn20_PAadhar1").show();
          $("#upload_btnloader_PAadhar1").hide();
          $("#PAadhar1Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePAadhar1 = () => {
    $("#X-btn_PAadhar1").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "IDProofApplicant1",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PAadhar1Status = res.data.data.status;
          PStatusArray[0].status = PAadhar1Status;
          this.checkDropdownValue();
          this.checkhandlePProceedBtn();
          $("#choose_PAadhar1").show();
          $("#X-btn_PAadhar1").hide();
          $("#X-btn_PAadhar1").attr("disabled", false);
          $("#viewDocLink_PAadhar1").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PAadhar1").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PAadhar1").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePAadhar1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PAadhar1seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "IDProofApplicant1",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PAadhar1seecommentsbtn").hide();
          $("#PAadhar1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePAadhar1hidecommentsbtn = () => {
    $("#PAadhar1seecommentsbtn").show();
    $("#PAadhar1seecommentsbtn").attr("disabled", false);
    $("#PAadhar1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PAadhar2
  handlePAadhar2Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "IDProofApplicant2",
          },
          () => {
            $("#PAadhar2Modal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "IDProofApplicant2",
            });
          }
        });
      $("#PAadhar2Modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPAadhar2 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PAadhar2").attr("disabled", true);
      $("#upload_btn20_PAadhar2").hide();
      $("#upload_btnloader_PAadhar2").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PAadhar2DocLink: res.data.data.IDProofApplicant2,
                },
                () => $("#viewDocLink_PAadhar2").css("display", "block")
              );
              PAadhar2Status = "remove";
              PStatusArray[1].status = PAadhar2Status;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PAadhar2Modal").modal("hide");
              $("#cancelbtn_PAadhar2").attr("disabled", false);
              $("#upload_btn20_PAadhar2").show();
              $("#upload_btnloader_PAadhar2").hide();
              $("#choose_PAadhar2").hide();
              $("#X-btn_PAadhar2").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PAadhar2").attr("disabled", false);
            $("#upload_btn20_PAadhar2").show();
            $("#upload_btnloader_PAadhar2").hide();
            $("#PAadhar2Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PAadhar2").attr("disabled", false);
            $("#upload_btn20_PAadhar2").show();
            $("#upload_btnloader_PAadhar2").hide();
            $("#PAadhar2Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PAadhar2").attr("disabled", false);
          $("#upload_btn20_PAadhar2").show();
          $("#upload_btnloader_PAadhar2").hide();
          $("#PAadhar2Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePAadhar2 = () => {
    $("#X-btn_PAadhar2").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "IDProofApplicant2",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PAadhar2Status = res.data.data.status;
          PStatusArray[1].status = PAadhar2Status;
          this.checkDropdownValue();
          this.checkhandlePProceedBtn();
          $("#choose_PAadhar2").show();
          $("#X-btn_PAadhar2").hide();
          $("#X-btn_PAadhar2").attr("disabled", false);
          $("#viewDocLink_PAadhar2").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PAadhar2").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PAadhar2").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePAadhar2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PAadhar2seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "IDProofApplicant2",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PAadhar2seecommentsbtn").hide();
          $("#PAadhar2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePAadhar2hidecommentsbtn = () => {
    $("#PAadhar2seecommentsbtn").show();
    $("#PAadhar2seecommentsbtn").attr("disabled", false);
    $("#PAadhar2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PPan1
  handlePPan1Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "PartnershipDeed",
          },
          () => {
            $("#PPan1Modal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "PartnershipDeed",
            });
          }
        });
      $("#PPan1Modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPPan1 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PPan1").attr("disabled", true);
      $("#upload_btn20_PPan1").hide();
      $("#upload_btnloader_PPan1").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PPan1DocLink: res.data.data.PartnershipDeed,
                },
                () => $("#viewDocLink_PPan1").css("display", "block")
              );
              PPan1Status = "remove";
              PStatusArray[4].status = PPan1Status;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PPan1Modal").modal("hide");
              $("#cancelbtn_PPan1").attr("disabled", false);
              $("#upload_btn20_PPan1").show();
              $("#upload_btnloader_PPan1").hide();
              $("#choose_PPan1").hide();
              $("#X-btn_PPan1").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PPan1").attr("disabled", false);
            $("#upload_btn20_PPan1").show();
            $("#upload_btnloader_PPan1").hide();
            $("#PPan1Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PPan1").attr("disabled", false);
            $("#upload_btn20_PPan1").show();
            $("#upload_btnloader_PPan1").hide();
            $("#PPan1Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PPan1").attr("disabled", false);
          $("#upload_btn20_PPan1").show();
          $("#upload_btnloader_PPan1").hide();
          $("#PPan1Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePPan1 = () => {
    $("#X-btn_PPan1").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "PartnershipDeed",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PPan1Status = res.data.data.status;
          PStatusArray[4].status = PPan1Status;
          this.checkDropdownValue();
          this.checkhandlePProceedBtn();
          $("#choose_PPan1").show();
          $("#X-btn_PPan1").hide();
          $("#X-btn_PPan1").attr("disabled", false);
          $("#viewDocLink_PPan1").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PPan1").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PPan1").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePPan1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PPan1seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PANCardApplicant1",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PPan1seecommentsbtn").hide();
          $("#PPan1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePPan1hidecommentsbtn = () => {
    $("#PPan1seecommentsbtn").show();
    $("#PPan1seecommentsbtn").attr("disabled", false);
    $("#PPan1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PPan2

  // PPic1
  handlePPic1Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "ApplicantPhoto1",
          },
          () => {
            $("#PPic1Modal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "ApplicantPhoto1",
            });
          }
        });
      $("#PPic1Modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPPic1 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PPic1").attr("disabled", true);
      $("#upload_btn20_PPic1").hide();
      $("#upload_btnloader_PPic1").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PPic1DocLink: res.data.data.ApplicantPhoto1,
                },
                () => $("#viewDocLink_PPic1").css("display", "block")
              );
              PPic1Status = "remove";
              PStatusArray[2].status = PPic1Status;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PPic1Modal").modal("hide");
              $("#cancelbtn_PPic1").attr("disabled", false);
              $("#upload_btn20_PPic1").show();
              $("#upload_btnloader_PPic1").hide();
              $("#choose_PPic1").hide();
              $("#X-btn_PPic1").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PPic1").attr("disabled", false);
            $("#upload_btn20_PPic1").show();
            $("#upload_btnloader_PPic1").hide();
            $("#PPic1Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PPic1").attr("disabled", false);
            $("#upload_btn20_PPic1").show();
            $("#upload_btnloader_PPic1").hide();
            $("#PPic1Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PPic1").attr("disabled", false);
          $("#upload_btn20_PPic1").show();
          $("#upload_btnloader_PPic1").hide();
          $("#PPic1Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePPic1 = () => {
    $("#X-btn_PPic1").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "ApplicantPhoto1",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PPic1Status = res.data.data.status;
          PStatusArray[2].status = PPic1Status;
          this.checkDropdownValue();
          this.checkhandlePProceedBtn();
          $("#choose_PPic1").show();
          $("#X-btn_PPic1").hide();
          $("#X-btn_PPic1").attr("disabled", false);
          $("#viewDocLink_PPic1").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PPic1").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PPic1").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePPic1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PPic1seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ApplicantPhoto1",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PPic1seecommentsbtn").hide();
          $("#PPic1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePPic1hidecommentsbtn = () => {
    $("#PPic1seecommentsbtn").show();
    $("#PPic1seecommentsbtn").attr("disabled", false);
    $("#PPic1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PPic2
  handlePPic2Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "ApplicantPhoto2",
          },
          () => {
            $("#PPic2Modal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "ApplicantPhoto2",
            });
          }
        });
      $("#PPic2Modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPPic2 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PPic2").attr("disabled", true);
      $("#upload_btn20_PPic2").hide();
      $("#upload_btnloader_PPic2").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PPic2DocLink: res.data.data.ApplicantPhoto2,
                },
                () => $("#viewDocLink_PPic2").css("display", "block")
              );
              PPic2Status = "remove";
              PStatusArray[3].status = PPic2Status;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PPic2Modal").modal("hide");
              $("#cancelbtn_PPic2").attr("disabled", false);
              $("#upload_btn20_PPic2").show();
              $("#upload_btnloader_PPic2").hide();
              $("#choose_PPic2").hide();
              $("#X-btn_PPic2").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PPic2").attr("disabled", false);
            $("#upload_btn20_PPic2").show();
            $("#upload_btnloader_PPic2").hide();
            $("#PPic2Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PPic2").attr("disabled", false);
            $("#upload_btn20_PPic2").show();
            $("#upload_btnloader_PPic2").hide();
            $("#PPic2Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PPic2").attr("disabled", false);
          $("#upload_btn20_PPic2").show();
          $("#upload_btnloader_PPic2").hide();
          $("#PPic2Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePPic2 = () => {
    $("#X-btn_PPic2").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "ApplicantPhoto2",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PPic2Status = res.data.data.status;
          PStatusArray[3].status = PPic2Status;
          this.checkDropdownValue();
          this.checkhandlePProceedBtn();
          $("#choose_PPic2").show();
          $("#X-btn_PPic2").hide();
          $("#X-btn_PPic2").attr("disabled", false);
          $("#viewDocLink_PPic2").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PPic2").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PPic2").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePPic2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PPic2seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ApplicantPhoto2",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PPic2seecommentsbtn").hide();
          $("#PPic2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePPic2hidecommentsbtn = () => {
    $("#PPic2seecommentsbtn").show();
    $("#PPic2seecommentsbtn").attr("disabled", false);
    $("#PPic2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PBP
  // PGumasta
  // PPUnit
  handlePPUnitChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "ProductionUnitPhoto",
          },
          () => {
            $("#PPUnitModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "ProductionUnitPhoto",
            });
          }
        });
      $("#PPUnitModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPPUnit = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PPUnit").attr("disabled", true);
      $("#upload_btn20_PPUnit").hide();
      $("#upload_btnloader_PPUnit").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PPUnitDocLink: res.data.data.ProductionUnitPhoto,
                },
                () => $("#viewDocLink_PPUnit").css("display", "block")
              );
              PPUnitStatus = "remove";
              PStatusArray[5].status = PPUnitStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PPUnitModal").modal("hide");
              $("#cancelbtn_PPUnit").attr("disabled", false);
              $("#upload_btn20_PPUnit").show();
              $("#upload_btnloader_PPUnit").hide();
              $("#choose_PPUnit").hide();
              $("#X-btn_PPUnit").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PPUnit").attr("disabled", false);
            $("#upload_btn20_PPUnit").show();
            $("#upload_btnloader_PPUnit").hide();
            $("#PPUnitModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PPUnit").attr("disabled", false);
            $("#upload_btn20_PPUnit").show();
            $("#upload_btnloader_PPUnit").hide();
            $("#PPUnitModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PPUnit").attr("disabled", false);
          $("#upload_btn20_PPUnit").show();
          $("#upload_btnloader_PPUnit").hide();
          $("#PPUnitModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePPUnit = () => {
    $("#X-btn_PPUnit").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "ProductionUnitPhoto",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PPUnitStatus = res.data.data.status;
          PStatusArray[5].status = PPUnitStatus;
          this.checkDropdownValue();
          this.checkhandlePProceedBtn();
          $("#choose_PPUnit").show();
          $("#X-btn_PPUnit").hide();
          $("#X-btn_PPUnit").attr("disabled", false);
          $("#viewDocLink_PPUnit").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PPUnit").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PPUnit").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePPUnitseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PPUnitseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ProductionUnitPhoto",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PPUnitseecommentsbtn").hide();
          $("#PPUnithidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePPUnithidecommentsbtn = () => {
    $("#PPUnitseecommentsbtn").show();
    $("#PPUnitseecommentsbtn").attr("disabled", false);
    $("#PPUnithidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLayoutPlan
  handlePLayoutPlanChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "LayoutPlanUnit",
          },
          () => {
            $("#PLayoutPlanModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "LayoutPlanUnit",
            });
          }
        });
      $("#PLayoutPlanModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLayoutPlan = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLayoutPlan").attr("disabled", true);
      $("#upload_btn20_PLayoutPlan").hide();
      $("#upload_btnloader_PLayoutPlan").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLayoutPlanDocLink: res.data.data.LayoutPlanUnit,
                },
                () => $("#viewDocLink_PLayoutPlan").css("display", "block")
              );
              PLayoutPlanStatus = "remove";
              PStatusArray[6].status = PLayoutPlanStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PLayoutPlanModal").modal("hide");
              $("#cancelbtn_PLayoutPlan").attr("disabled", false);
              $("#upload_btn20_PLayoutPlan").show();
              $("#upload_btnloader_PLayoutPlan").hide();
              $("#choose_PLayoutPlan").hide();
              $("#X-btn_PLayoutPlan").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLayoutPlan").attr("disabled", false);
            $("#upload_btn20_PLayoutPlan").show();
            $("#upload_btnloader_PLayoutPlan").hide();
            $("#PLayoutPlanModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLayoutPlan").attr("disabled", false);
            $("#upload_btn20_PLayoutPlan").show();
            $("#upload_btnloader_PLayoutPlan").hide();
            $("#PLayoutPlanModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLayoutPlan").attr("disabled", false);
          $("#upload_btn20_PLayoutPlan").show();
          $("#upload_btnloader_PLayoutPlan").hide();
          $("#PLayoutPlanModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLayoutPlan = () => {
    $("#X-btn_PLayoutPlan").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "LayoutPlanUnit",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLayoutPlanStatus = res.data.data.status;
          PStatusArray[6].status = PLayoutPlanStatus;
          this.checkDropdownValue();
          this.checkhandlePProceedBtn();
          $("#choose_PLayoutPlan").show();
          $("#X-btn_PLayoutPlan").hide();
          $("#X-btn_PLayoutPlan").attr("disabled", false);
          $("#viewDocLink_PLayoutPlan").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLayoutPlan").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLayoutPlan").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLayoutPlanseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLayoutPlanseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "LayoutPlanUnit",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLayoutPlanseecommentsbtn").hide();
          $("#PLayoutPlanhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLayoutPlanhidecommentsbtn = () => {
    $("#PLayoutPlanseecommentsbtn").show();
    $("#PLayoutPlanseecommentsbtn").attr("disabled", false);
    $("#PLayoutPlanhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PListofEquip
  handlePListofEquipChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "ListOfEquipment",
          },
          () => {
            $("#PListofEquipModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "ListOfEquipment",
            });
          }
        });
      $("#PListofEquipModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPListofEquip = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PListofEquip").attr("disabled", true);
      $("#upload_btn20_PListofEquip").hide();
      $("#upload_btnloader_PListofEquip").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PListofEquipDocLink: res.data.data.ListOfEquipment,
                },
                () => $("#viewDocLink_PListofEquip").css("display", "block")
              );
              PListofEquipStatus = "remove";
              PStatusArray[7].status = PListofEquipStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PListofEquipModal").modal("hide");
              $("#cancelbtn_PListofEquip").attr("disabled", false);
              $("#upload_btn20_PListofEquip").show();
              $("#upload_btnloader_PListofEquip").hide();
              $("#choose_PListofEquip").hide();
              $("#X-btn_PListofEquip").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PListofEquip").attr("disabled", false);
            $("#upload_btn20_PListofEquip").show();
            $("#upload_btnloader_PListofEquip").hide();
            $("#PListofEquipModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PListofEquip").attr("disabled", false);
            $("#upload_btn20_PListofEquip").show();
            $("#upload_btnloader_PListofEquip").hide();
            $("#PListofEquipModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PListofEquip").attr("disabled", false);
          $("#upload_btn20_PListofEquip").show();
          $("#upload_btnloader_PListofEquip").hide();
          $("#PListofEquipModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePListofEquip = () => {
    $("#X-btn_PListofEquip").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "ListOfEquipment",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PListofEquipStatus = res.data.data.status;
          PStatusArray[7].status = PListofEquipStatus;
          this.checkDropdownValue();
          this.checkhandlePProceedBtn();
          $("#choose_PListofEquip").show();
          $("#X-btn_PListofEquip").hide();
          $("#X-btn_PListofEquip").attr("disabled", false);
          $("#viewDocLink_PListofEquip").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PListofEquip").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PListofEquip").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePListofEquipseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PListofEquipseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ListOfEquipment",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PListofEquipseecommentsbtn").hide();
          $("#PListofEquiphidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePListofEquiphidecommentsbtn = () => {
    $("#PListofEquipseecommentsbtn").show();
    $("#PListofEquipseecommentsbtn").attr("disabled", false);
    $("#PListofEquiphidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PWAReport
  handlePWAReportChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "WaterAnalysisReport",
          },
          () => {
            $("#PWAReportModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "WaterAnalysisReport",
            });
          }
        });
      $("#PWAReportModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPWAReport = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PWAReport").attr("disabled", true);
      $("#upload_btn20_PWAReport").hide();
      $("#upload_btnloader_PWAReport").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PWAReportDocLink: res.data.data.WaterAnalysisReport,
                },
                () => $("#viewDocLink_PWAReport").css("display", "block")
              );
              PWAReportStatus = "remove";
              PStatusArray[8].status = PWAReportStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PWAReportModal").modal("hide");
              $("#cancelbtn_PWAReport").attr("disabled", false);
              $("#upload_btn20_PWAReport").show();
              $("#upload_btnloader_PWAReport").hide();
              $("#choose_PWAReport").hide();
              $("#X-btn_PWAReport").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PWAReport").attr("disabled", false);
            $("#upload_btn20_PWAReport").show();
            $("#upload_btnloader_PWAReport").hide();
            $("#PWAReportModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PWAReport").attr("disabled", false);
            $("#upload_btn20_PWAReport").show();
            $("#upload_btnloader_PWAReport").hide();
            $("#PWAReportModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PWAReport").attr("disabled", false);
          $("#upload_btn20_PWAReport").show();
          $("#upload_btnloader_PWAReport").hide();
          $("#PWAReportModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePWAReport = () => {
    $("#X-btn_PWAReport").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "WaterAnalysisReport",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PWAReportStatus = res.data.data.status;
          PStatusArray[8].status = PWAReportStatus;
          this.checkDropdownValue();
          this.checkhandlePProceedBtn();
          $("#choose_PWAReport").show();
          $("#X-btn_PWAReport").hide();
          $("#X-btn_PWAReport").attr("disabled", false);
          $("#viewDocLink_PWAReport").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PWAReport").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PWAReport").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePWAReportseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PWAReportseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "WaterAnalysisReport",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PWAReportseecommentsbtn").hide();
          $("#PWAReporthidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePWAReporthidecommentsbtn = () => {
    $("#PWAReportseecommentsbtn").show();
    $("#PWAReportseecommentsbtn").attr("disabled", false);
    $("#PWAReporthidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PListofDir
  handlePListofDirChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "DirectorsFullAddress",
          },
          () => {
            $("#PListofDirModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "DirectorsFullAddress",
            });
          }
        });
      $("#PListofDirModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPListofDir = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PListofDir").attr("disabled", true);
      $("#upload_btn20_PListofDir").hide();
      $("#upload_btnloader_PListofDir").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PListofDirDocLink: res.data.data.DirectorsFullAddress,
                },
                () => $("#viewDocLink_PListofDir").css("display", "block")
              );
              PListofDirStatus = "remove";
              PStatusArray[9].status = PListofDirStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PListofDirModal").modal("hide");
              $("#cancelbtn_PListofDir").attr("disabled", false);
              $("#upload_btn20_PListofDir").show();
              $("#upload_btnloader_PListofDir").hide();
              $("#choose_PListofDir").hide();
              $("#X-btn_PListofDir").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PListofDir").attr("disabled", false);
            $("#upload_btn20_PListofDir").show();
            $("#upload_btnloader_PListofDir").hide();
            $("#PListofDirModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PListofDir").attr("disabled", false);
            $("#upload_btn20_PListofDir").show();
            $("#upload_btnloader_PListofDir").hide();
            $("#PListofDirModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PListofDir").attr("disabled", false);
          $("#upload_btn20_PListofDir").show();
          $("#upload_btnloader_PListofDir").hide();
          $("#PListofDirModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePListofDir = () => {
    $("#X-btn_PListofDir").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "DirectorsFullAddress",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PListofDirStatus = res.data.data.status;
          PStatusArray[9].status = PListofDirStatus;
          this.checkDropdownValue();
          this.checkhandlePProceedBtn();
          $("#choose_PListofDir").show();
          $("#X-btn_PListofDir").hide();
          $("#X-btn_PListofDir").attr("disabled", false);
          $("#viewDocLink_PListofDir").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PListofDir").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PListofDir").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePListofDirseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PListofDirseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "DirectorsFullAddress",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PListofDirseecommentsbtn").hide();
          $("#PListofDirhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePListofDirhidecommentsbtn = () => {
    $("#PListofDirseecommentsbtn").show();
    $("#PListofDirseecommentsbtn").attr("disabled", false);
    $("#PListofDirhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PRecallPlan
  handlePRecallPlanChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "RecallPlan",
          },
          () => {
            $("#PRecallPlanModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "RecallPlan",
            });
          }
        });
      $("#PRecallPlanModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPRecallPlan = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PRecallPlan").attr("disabled", true);
      $("#upload_btn20_PRecallPlan").hide();
      $("#upload_btnloader_PRecallPlan").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PRecallPlanDocLink: res.data.data.RecallPlan,
                },
                () => $("#viewDocLink_PRecallPlan").css("display", "block")
              );
              PRecallPlanStatus = "remove";
              PStatusArray[10].status = PRecallPlanStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PRecallPlanModal").modal("hide");
              $("#cancelbtn_PRecallPlan").attr("disabled", false);
              $("#upload_btn20_PRecallPlan").show();
              $("#upload_btnloader_PRecallPlan").hide();
              $("#choose_PRecallPlan").hide();
              $("#X-btn_PRecallPlan").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PRecallPlan").attr("disabled", false);
            $("#upload_btn20_PRecallPlan").show();
            $("#upload_btnloader_PRecallPlan").hide();
            $("#PRecallPlanModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PRecallPlan").attr("disabled", false);
            $("#upload_btn20_PRecallPlan").show();
            $("#upload_btnloader_PRecallPlan").hide();
            $("#PRecallPlanModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PRecallPlan").attr("disabled", false);
          $("#upload_btn20_PRecallPlan").show();
          $("#upload_btnloader_PRecallPlan").hide();
          $("#PRecallPlanModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePRecallPlan = () => {
    $("#X-btn_PRecallPlan").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "RecallPlan",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PRecallPlanStatus = res.data.data.status;
          PStatusArray[10].status = PRecallPlanStatus;
          this.checkDropdownValue();
          this.checkhandlePProceedBtn();
          $("#choose_PRecallPlan").show();
          $("#X-btn_PRecallPlan").hide();
          $("#X-btn_PRecallPlan").attr("disabled", false);
          $("#viewDocLink_PRecallPlan").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PRecallPlan").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PRecallPlan").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePRecallPlanseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PRecallPlanseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "RecallPlan",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PRecallPlanseecommentsbtn").hide();
          $("#PRecallPlanhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePRecallPlanhidecommentsbtn = () => {
    $("#PRecallPlanseecommentsbtn").show();
    $("#PRecallPlanseecommentsbtn").attr("disabled", false);
    $("#PRecallPlanhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PTradeLicence
  handlePPbusinessNatureChange = (e) => {
    this.setState({
      PPbusinessNature: e.target.value,
    });
  };
  // PAddressProofType
  PAddressProofType_radioChange = (e) => {
    this.setState(
      {
        PAddressProofType_radio: e.currentTarget.value,
      },
      () => {
        if (this.state.PAddressProofType_radio != "CopyOfRegistry") {
          $(".RentAgreement").css("display", "flex");
          $(".CopyOfRegistry").hide();
        } else if (this.state.PAddressProofType_radio == "CopyOfRegistry") {
          $(".CopyOfRegistry").css("display", "flex");
          $(".RentAgreement").hide();
        }
        this.checkhandlePProceedBtn();
      }
    );
  };

  // PCopyOfRegistry
  handlePCopyOfRegistryChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "OwnershipDeed",
          },
          () => {
            $("#PCopyOfRegistryModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "OwnershipDeed",
            });
          }
        });
      $("#PCopyOfRegistryModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPCopyOfRegistry = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PCopyOfRegistry").attr("disabled", true);
      $("#upload_btn20_PCopyOfRegistry").hide();
      $("#upload_btnloader_PCopyOfRegistry").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PCopyOfRegistryDocLink: res.data.data.OwnershipDeed,
                },
                () => $("#viewDocLink_PCopyOfRegistry").css("display", "block")
              );
              PCopyOfRegistryStatus = "remove";
              POptStatusArray[0].status = PCopyOfRegistryStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PCopyOfRegistryModal").modal("hide");
              $("#cancelbtn_PCopyOfRegistry").attr("disabled", false);
              $("#upload_btn20_PCopyOfRegistry").show();
              $("#upload_btnloader_PCopyOfRegistry").hide();
              $("#choose_PCopyOfRegistry").hide();
              $("#X-btn_PCopyOfRegistry").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PCopyOfRegistry").attr("disabled", false);
            $("#upload_btn20_PCopyOfRegistry").show();
            $("#upload_btnloader_PCopyOfRegistry").hide();
            $("#PCopyOfRegistryModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PCopyOfRegistry").attr("disabled", false);
            $("#upload_btn20_PCopyOfRegistry").show();
            $("#upload_btnloader_PCopyOfRegistry").hide();
            $("#PCopyOfRegistryModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PCopyOfRegistry").attr("disabled", false);
          $("#upload_btn20_PCopyOfRegistry").show();
          $("#upload_btnloader_PCopyOfRegistry").hide();
          $("#PCopyOfRegistryModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePCopyOfRegistry = () => {
    $("#X-btn_PCopyOfRegistry").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "OwnershipDeed",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PCopyOfRegistryStatus = res.data.data.status;
          POptStatusArray[0].status = PCopyOfRegistryStatus;
          this.checkDropdownValue();
          this.checkhandlePProceedBtn();
          $("#choose_PCopyOfRegistry").show();
          $("#X-btn_PCopyOfRegistry").hide();
          $("#X-btn_PCopyOfRegistry").attr("disabled", false);
          $("#viewDocLink_PCopyOfRegistry").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PCopyOfRegistry").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PCopyOfRegistry").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePCopyOfRegistryseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PCopyOfRegistryseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "CopyOfRegistry",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PCopyOfRegistryseecommentsbtn").hide();
          $("#PCopyOfRegistryhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePCopyOfRegistryhidecommentsbtn = () => {
    $("#PCopyOfRegistryseecommentsbtn").show();
    $("#PCopyOfRegistryseecommentsbtn").attr("disabled", false);
    $("#PCopyOfRegistryhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PRentAgreement
  handlePRentAgreementChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "RentAgreement",
          },
          () => {
            $("#PRentAgreementModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "RentAgreement",
            });
          }
        });
      $("#PRentAgreementModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPRentAgreement = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PRentAgreement").attr("disabled", true);
      $("#upload_btn20_PRentAgreement").hide();
      $("#upload_btnloader_PRentAgreement").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PRentAgreementDocLink: res.data.data.RentAgreement,
                },
                () => $("#viewDocLink_PRentAgreement").css("display", "block")
              );
              PRentAgreementStatus = "remove";
              POptStatusArray[1].status = PRentAgreementStatus;
              this.checkDropdownValue();
              this.checkhandlePProceedBtn();
              $("#PRentAgreementModal").modal("hide");
              $("#cancelbtn_PRentAgreement").attr("disabled", false);
              $("#upload_btn20_PRentAgreement").show();
              $("#upload_btnloader_PRentAgreement").hide();
              $("#choose_PRentAgreement").hide();
              $("#X-btn_PRentAgreement").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PRentAgreement").attr("disabled", false);
            $("#upload_btn20_PRentAgreement").show();
            $("#upload_btnloader_PRentAgreement").hide();
            $("#PRentAgreementModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PRentAgreement").attr("disabled", false);
            $("#upload_btn20_PRentAgreement").show();
            $("#upload_btnloader_PRentAgreement").hide();
            $("#PRentAgreementModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PRentAgreement").attr("disabled", false);
          $("#upload_btn20_PRentAgreement").show();
          $("#upload_btnloader_PRentAgreement").hide();
          $("#PRentAgreementModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePRentAgreement = () => {
    $("#X-btn_PRentAgreement").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "RentAgreement",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PRentAgreementStatus = res.data.data.status;
          POptStatusArray[1].status = PRentAgreementStatus;
          this.checkDropdownValue();
          this.checkhandlePProceedBtn();
          $("#choose_PRentAgreement").show();
          $("#X-btn_PRentAgreement").hide();
          $("#X-btn_PRentAgreement").attr("disabled", false);
          $("#viewDocLink_PRentAgreement").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PRentAgreement").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PRentAgreement").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePRentAgreementseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PRentAgreementseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "RentAgreement",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PRentAgreementseecommentsbtn").hide();
          $("#PRentAgreementhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePRentAgreementhidecommentsbtn = () => {
    $("#PRentAgreementseecommentsbtn").show();
    $("#PRentAgreementseecommentsbtn").attr("disabled", false);
    $("#PRentAgreementhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PPR

  // PAdditional
  handlePAddFileChange = (e, index, status) => {
    PadditionalReuploadIndex = index;
    PadditionalReuploadStatus = status;
    $(".upload_btn20_7").show();
    $(".upload_btnloader_7").hide();
    const file = e.target.files[0];
    if (!PadditionalFilesNames.includes(this.state.PAdd_DocName.trim())) {
      const myfile = e.target.files;
      if (ChooseNowBtnsDisable == "Yes") {
        this.showNotification(
          "Files cannot be uploaded when the service is locked!"
        );
      } else if (myfile.length == 1) {
        const file = myfile[0];
        if (file.type.match("audio.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.type.match("video.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.size > 104857600) {
          this.showNotification("File too big!");
        } else if (file.name.length > 100) {
          this.showNotification("File name limit exceed!");
        } else {
          this.setState(
            {
              fileName: file.name,
              fileBase64: file,
              documentType: "additional",
              documentName: this.state.PAdd_DocName,
            },
            () => {
              $(".PAddModal").modal({
                backdrop: "static",
                keyboard: false,
              });
            }
          );
        }
      } else if (myfile.length > 1) {
        var zip = new JSZip();
        for (let i = 0; i < myfile.length; i++) {
          zip.file(myfile[i].name, myfile[i], {
            base64: true,
          });
        }
        zip
          .generateAsync({
            type: "blob",
          })
          .then((content) => {
            if (content.size > 104857600) {
              this.showNotification("File too big!");
            } else {
              this.setState({
                fileName: "download.zip",
                fileBase64: content,
                documentType: "additional",
                documentName: this.state.PAdd_DocName,
              });
            }
          });
        $(".PAddModal").modal({
          backdrop: "static",
          keyboard: false,
        });
      } else {
        this.showNotification("File cannot be null!");
      }
    } else {
      this.showNotification(
        "Document names can not be the same, Try again with different name!"
      );
    }
  };

  uploadPFile = (FileIndex, FileStatus) => {
    let AdditionaldocumentName1;
    if (FileStatus == "reupload") {
      $(".proceednowbtn").attr("disabled", true);
      let fileTobeUploaded = this.state.PadditionalFiles[FileIndex];
      AdditionaldocumentName1 = Object.keys(fileTobeUploaded)[0];
      mainaction = "reupload";
    } else {
      AdditionaldocumentName1 = this.state.documentName;
      mainaction = "upload";
    }
    if (AdditionaldocumentName1.trim() == "") {
      $(".PAddModal").modal("hide");
      this.showNotification("Enter document Name first");
    } else if (this.state.comment.length <= 250) {
      $(".cancelbtn_7").attr("disabled", true);
      $(".upload_btn20_7").hide();
      $(".upload_btnloader_7").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", AdditionaldocumentName1);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            $(".PAddModal").modal("hide");
            $(".cancelbtn_7").attr("disabled", false);
            $(`#${FileIndex}PFileseecommentsbtn`).attr("disabled", false);
            if (PadditionalReupload == false) {
              PadditionalFilesNames.push(this.state.PAdd_DocName);
              this.state.PadditionalFiles.pop();
              this.setState(
                {
                  comment: "",
                  PadditionalFiles: [
                    ...this.state.PadditionalFiles,
                    res.data.data,
                  ],
                },
                () => {
                  this.setState(
                    {
                      api_PadditionalFiles: "Yes",
                    },
                    () => {
                      this.PadditionalFilesArea();
                      this.checkDropdownValue();
                      this.checkhandlePProceedBtn();
                    }
                  );

                  $(".PadditionalInput").attr("disabled", true);
                }
              );
            } else if (PadditionalReupload == true) {
              $(".proceednowbtn").attr("disabled", false);
              var array = [...this.state.PadditionalFiles];
              array.splice(FileIndex, 1, res.data.data);
              this.setState(
                {
                  comment: "",
                  api_PadditionalFiles: "Yes",
                  PadditionalFiles: array,
                },
                () => {
                  this.PadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandlePProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".PAddModal").modal("hide");
            this.showNotification("Something Went Wrong!");
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".PAddModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          this.showNotification("Something went wrong, Try again!");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePFile = (index) => {
    $("#X-btn_7").attr("disabled", true);
    let fileTobeDeleted = this.state.PadditionalFiles[index];
    let NameOfAddFile = Object.keys(fileTobeDeleted)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "additional",
          documentName: NameOfAddFile,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          var array = [...this.state.PadditionalFiles];
          if (res.data.data.status == "reupload") {
            array[index].status = "reupload";
            this.setState(
              {
                PadditionalFiles: array,
              },
              () => {
                this.PadditionalFilesArea();
                this.checkDropdownValue();
                this.checkhandlePProceedBtn();
              }
            );
            this.showNotification(res.data.message);
          } else {
            array.splice(index, 1);
            if (array.length > 0) {
              this.setState(
                {
                  PadditionalFiles: array,
                },
                () => {
                  this.PadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandlePProceedBtn();
                }
              );
            } else if (array.length == 0) {
              this.setState(
                {
                  PadditionalFiles: [],
                  PAdd_DocName: "",
                  api_PadditionalFiles: "No",
                  IsPAddFileDisabled: true,
                },
                () => {
                  this.PadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandlePProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_7").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePFileseeComments = (index) => {
    $(".commentsbtn").attr("disabled", false);
    $(`#${index}PFileseecommentsbtn`).attr("disabled", true);
    let SelectedFile = this.state.PadditionalFiles[index];
    let NameOfAddFile = Object.keys(SelectedFile)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "additional",
          documentName: NameOfAddFile,
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(`#${index}PFileseecommentsbtn`).hide();
          $(".hidecommentsbtn").hide();
          $(`#${index}PFilehidecommentsbtn`).show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePFilehideComments = (index) => {
    $(".seecommentsbtn").show();
    $(`#${index}PFileseecommentsbtn`).show();
    $(`#${index}PFileseecommentsbtn`).attr("disabled", false);
    $(`#${index}PFilehidecommentsbtn`).hide();
    $(".hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  checkAddCommentPFiles = (FileIndex, isDisabled) => {
    if (isDisabled == true) {
      return (
        <>
          <button
            className="seecommentsbtn PFileseecommentsbtn"
            id={`${FileIndex}PFileseecommentsbtn`}
            disabled
            onClick={() => this.handlePFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn PFilehidecommentsbtn"
            id={`${FileIndex}PFilehidecommentsbtn`}
            onClick={() => this.handlePFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    } else if (isDisabled == false) {
      return (
        <>
          <button
            className="seecommentsbtn commentsbtn PFileseecommentsbtn"
            id={`${FileIndex}PFileseecommentsbtn`}
            onClick={() => this.handlePFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn PFilehidecommentsbtn"
            id={`${FileIndex}PFilehidecommentsbtn`}
            onClick={() => this.handlePFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    }
  };

  checkStatusPAddFiles = (status, link, index) => {
    PadditionalFilesIndex = index;
    if (HideProceedBtn == "Yes") {
      $(".proceednowbtn").hide();
      if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
          </>
        );
      }
    } else {
      if (status == undefined) {
        status = "open";
      }
      if (status == "open") {
        mainaction = "upload";
        PadditionalReupload = true;
        $(".proceednowbtn").show();
        return (
          <>
            <label
              htmlFor={`ul_file_PAdd${index}`}
              className="choose_btn choose_7"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              multiple
              name="file[]"
              className="upload-photo"
              id={`ul_file_PAdd${index}`}
              // disabled={this.state.IsPAddFileDisabled}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handlePAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "remove") {
        mainaction = "upload";
        $(".proceednowbtn").show();
        return (
          <>
            <button
              className="X-btn X-btn_7"
              onClick={() => this.removePFile(index)}
            >
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_1025.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "reupload") {
        PadditionalReupload = true;
        mainaction = "reupload";
        $(".proceednowbtn").show();
        $(".proceednowbtn").attr("disabled", true);
        return (
          <>
            <label
              htmlFor={`ul_file_PAdd${index}`}
              className="choose_btn choose_7"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              multiple
              name="file[]"
              className="upload-photo"
              id={`ul_file_PAdd${index}`}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handlePAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
          </>
        );
      }
    }
  };

  AdditionalPFileDisabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero PadditionalInput"
        value={NameOfAddFile}
        disabled
        onChange={(e) => this.handlePAdd_DocNameChange(e)}
      />
    );
  };

  AdditionalPFileEnabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero PadditionalInput"
        value={NameOfAddFile}
        onChange={(e) => this.handlePAdd_DocNameChange(e)}
      />
    );
  };

  PadditionalFilesArea = () => {
    PadditionalFilesNames = [];
    if (this.state.api_PadditionalFiles == "Yes") {
      return this.state.PadditionalFiles.map((file, index) => {
        let NameOfAddFile = Object.keys(file)[0];
        PadditionalFilesNames.push(NameOfAddFile);
        let link = file[Object.keys(file)[0]];
        let AdditionalFileInputField;
        let commentDisabled;
        if (file.status == "open" || file.status == undefined) {
          AdditionalFileInputField =
            this.AdditionalPFileEnabledField(NameOfAddFile);
          commentDisabled = true;
        } else if (file.status != "open") {
          AdditionalFileInputField =
            this.AdditionalPFileDisabledField(NameOfAddFile);
          commentDisabled = false;
        }
        return (
          <div className="displayflex" key={index}>
            <div className="uploadnocol">
              <p className="uploadadditionalsidetext">{index + 1}&#41;</p>
            </div>
            <div className="uploadadditionaldatacol">
              <div className="row mb-2">
                <div className="col-md-5">{AdditionalFileInputField}</div>
                <div className="col-md-7">
                  <div className="float-md-right float-left">
                    {this.checkAddCommentPFiles(index, commentDisabled)}
                    {this.checkStatusPAddFiles(file.status, link, index)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.api_PadditionalFiles == "No") {
      return (
        <div className="displayflex">
          <div className="uploadnocol">
            <p className="uploadadditionalsidetext">1&#41;</p>
          </div>
          <div className="uploadadditionaldatacol">
            <div className="row mb-2">
              <div className="col-md-5">
                <input
                  type="text"
                  placeholder="Document name..."
                  maxLength="150"
                  className="uploadtextfield marginzero"
                  value={this.state.PAdd_DocName}
                  onChange={(e) => this.handlePAdd_DocNameChange(e)}
                />
              </div>
              <div className="col-md-7">
                <div className="fl_right">
                  {this.checkAddCommentPFiles(0, true)}
                  {this.checkStatusPAddFiles("open", null, 0)}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  handlePAdd_DocNameChange = (e) => {
    this.setState(
      {
        PAdd_DocName: e.target.value,
      },
      () => {
        if (this.state.PAdd_DocName.trim().length > 0) {
          this.setState({
            IsPAddFileDisabled: false,
          });
        } else {
          this.setState({
            IsPAddFileDisabled: true,
          });
        }
      }
    );
  };

  handleAddPFileBtn = (FileIndex) => {
    const { PAdd_DocName, PadditionalFiles } = this.state;

    let prevFile = PadditionalFiles[FileIndex];
    let NameOfPrevFile = Object.keys(prevFile)[0];
    let valueOffile = prevFile[Object.keys(prevFile)[0]];

    if (FileIndex == undefined) {
      this.showNotification("empty fields");
    } else if (PAdd_DocName.trim() == "") {
      this.showNotification("Required fields cannot be empty!");
    } else if (valueOffile == null) {
      this.showNotification("Choose a file");
    } else {
      this.setState(
        {
          PadditionalFiles: [...this.state.PadditionalFiles, ""],
          PAdd_DocName: "",
          IsPAddFileDisabled: true,
        },
        () => {
          $(".PadditionalInput").attr("disabled", false);
        }
      );
    }
  };

  // Proceed Checks
  PcheckRemainingReuploads = () => {
    if (this.state.licenseType == "T") {
      if (this.state.PAddressProofType_radio == "CopyOfRegistry") {
        if (
          PAadhar1Status != "reupload" &&
          PAadhar2Status != "reupload" &&
          PPic1Status != "reupload" &&
          PPan1Status != "reupload" &&
          PPic2Status != "reupload" &&
          PCopyOfRegistryStatus != "reupload"
        ) {
          AnyReuploadOccuredP = "No";
        } else {
          $(".proceednowbtn2").show();
          AnyReuploadOccuredP = "Yes";
        }
      } else {
        if (
          PAadhar1Status != "reupload" &&
          PAadhar2Status != "reupload" &&
          PPic1Status != "reupload" &&
          PPan1Status != "reupload" &&
          PPic2Status != "reupload" &&
          PRentAgreementStatus != "reupload"
        ) {
          AnyReuploadOccuredP = "No";
        } else {
          $(".proceednowbtn2").show();
          AnyReuploadOccuredP = "Yes";
        }
      }
    } else {
      if (this.state.PAddressProofType_radio == "CopyOfRegistry") {
        if (
          PAadhar1Status != "reupload" &&
          PAadhar2Status != "reupload" &&
          PPic1Status != "reupload" &&
          PPic2Status != "reupload" &&
          PPan1Status != "reupload" &&
          PPUnitStatus != "reupload" &&
          PLayoutPlanStatus != "reupload" &&
          PListofEquipStatus != "reupload" &&
          PWAReportStatus != "reupload" &&
          PListofDirStatus != "reupload" &&
          PRecallPlanStatus != "reupload" &&
          PCopyOfRegistryStatus != "reupload"
        ) {
          AnyReuploadOccuredP = "No";
        } else {
          $(".proceednowbtn2").show();
          AnyReuploadOccuredP = "Yes";
        }
      } else {
        if (
          PAadhar1Status != "reupload" &&
          PAadhar2Status != "reupload" &&
          PPic1Status != "reupload" &&
          PPic2Status != "reupload" &&
          PPan1Status != "reupload" &&
          PPUnitStatus != "reupload" &&
          PLayoutPlanStatus != "reupload" &&
          PListofEquipStatus != "reupload" &&
          PWAReportStatus != "reupload" &&
          PRecallPlanStatus != "reupload" &&
          PListofDirStatus != "reupload" &&
          PRentAgreementStatus != "reupload"
        ) {
          AnyReuploadOccuredP = "No";
        } else {
          $(".proceednowbtn2").show();
          AnyReuploadOccuredP = "Yes";
        }
      }
    }
  };

  checkhandlePProceedBtn = () => {
    let statusCheckReupload = this.statusCheckFunction(
      "reupload",
      PStatusArray
    );
    let PadditionalStatusArrayCheck = this.statusCheckFunction(
      "reupload",
      this.state.PadditionalFiles
    );
    let goodtogo = "No";
    if (this.state.PAddressProofType_radio == "CopyOfRegistry") {
      if (
        POptStatusArray[0].status != "reupload" &&
        POptStatusArray[0].status != "upload" &&
        POptStatusArray[0].status != "open"
      ) {
        goodtogo = "Yes";
      } else {
        goodtogo = "No";
      }
    } else if (this.state.PAddressProofType_radio == "RentAgreement") {
      if (
        POptStatusArray[1].status != "reupload" &&
        POptStatusArray[1].status != "upload" &&
        POptStatusArray[1].status != "open"
      ) {
        goodtogo = "Yes";
      } else {
        goodtogo = "No";
      }
    } else {
      goodtogo = "No";
    }

    if (
      AnyReuploadOccuredP == "Yes" &&
      statusCheckReupload == undefined &&
      goodtogo == "Yes" &&
      PadditionalStatusArrayCheck == undefined
    ) {
      $(".proceednowbtn2").attr("disabled", false);
      Pproceednowforword = "success";
    } else if (
      PAadhar1Status == "remove" &&
      PAadhar2Status == "remove" &&
      PPic1Status == "remove" &&
      PPic2Status == "remove" &&
      PPan1Status == "remove" &&
      this.state.licenseType == "T" &&
      goodtogo == "Yes"
    ) {
      $(".proceednowbtn2").attr("disabled", false);
      Pproceednowforword = "success";
    } else if (
      PAadhar1Status == "remove" &&
      PAadhar2Status == "remove" &&
      PPic1Status == "remove" &&
      PPic2Status == "remove" &&
      PPan1Status == "remove" &&
      PPUnitStatus == "remove" &&
      PLayoutPlanStatus == "remove" &&
      PListofEquipStatus == "remove" &&
      PWAReportStatus == "remove" &&
      PListofDirStatus == "remove" &&
      PRecallPlanStatus == "remove" &&
      this.state.licenseType == "M" &&
      goodtogo == "Yes"
    ) {
      $(".proceednowbtn2").attr("disabled", false);
      Pproceednowforword = "success";
    } else {
      $(".proceednowbtn2").attr("disabled", true);
      Pproceednowforword = "fail";
    }
  };

  handlePProceedBtn = () => {
    this.checkhandlePProceedBtn();
    if (this.state.PPbusinessNature.trim() == "") {
      this.showNotification("Required fields can not be empty!");
    } else if (this.state.PPbusinessNature.trim().length > 200) {
      this.showNotification("Nature of your Business / Brand limit exceed!");
    } else if (Pproceednowforword == "success") {
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=lockService",
          {
            userId: Decuserid,
            requestType: temp,
            agentId: Decagentid,

            serviceId: Decserviceid,
            serviceName: Decservicename,
            serviceType: this.state.serviceType,
            FY: "",
            Month: "",
            inputFields: [
              {
                inputFieldName: "AddressProofType",
                inputFieldValue: this.state.PAddressProofType_radio,
                inputFieldtype: "general",
              },
              {
                inputFieldName: "Nature",
                inputFieldValue: this.state.PPbusinessNature,
                inputFieldtype: "general",
              },
            ],
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.showNotification(res.data.message);
            this.getserviceInformationApi();
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else {
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => { });
    }
  };

  // Private Limited

  // PLAadhar
  handlePLAadhar1Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "IDProofApplicant1",
          },
          () => {
            $("#PLAadhar1Modal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "IDProofApplicant1",
            });
          }
        });
      $("#PLAadhar1Modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLAadhar1 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLAadhar1").attr("disabled", true);
      $("#upload_btn20_PLAadhar1").hide();
      $("#upload_btnloader_PLAadhar1").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLAadhar1DocLink: res.data.data.IDProofApplicant1,
                },
                () => $("#viewDocLink_PLAadhar1").css("display", "block")
              );
              PLAadhar1Status = "remove";
              PLStatusArray[0].status = PLAadhar1Status;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLAadhar1Modal").modal("hide");
              $("#cancelbtn_PLAadhar1").attr("disabled", false);
              $("#upload_btn20_PLAadhar1").show();
              $("#upload_btnloader_PLAadhar1").hide();
              $("#choose_PLAadhar1").hide();
              $("#X-btn_PLAadhar1").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLAadhar1").attr("disabled", false);
            $("#upload_btn20_PLAadhar1").show();
            $("#upload_btnloader_PLAadhar1").hide();
            $("#PLAadhar1Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLAadhar1").attr("disabled", false);
            $("#upload_btn20_PLAadhar1").show();
            $("#upload_btnloader_PLAadhar1").hide();
            $("#PLAadhar1Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLAadhar1").attr("disabled", false);
          $("#upload_btn20_PLAadhar1").show();
          $("#upload_btnloader_PLAadhar1").hide();
          $("#PLAadhar1Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLAadhar1 = () => {
    $("#X-btn_PLAadhar1").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "IDProofApplicant1",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLAadhar1Status = res.data.data.status;
          PLStatusArray[0].status = PLAadhar1Status;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLAadhar1").show();
          $("#X-btn_PLAadhar1").hide();
          $("#X-btn_PLAadhar1").attr("disabled", false);
          $("#viewDocLink_PLAadhar1").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLAadhar1").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLAadhar1").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLAadhar1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLAadhar1seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "IDProofApplicant1",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLAadhar1seecommentsbtn").hide();
          $("#PLAadhar1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLAadhar1hidecommentsbtn = () => {
    $("#PLAadhar1seecommentsbtn").show();
    $("#PLAadhar1seecommentsbtn").attr("disabled", false);
    $("#PLAadhar1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLAadhar2
  handlePLAadhar2Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "IDProofApplicant2",
          },
          () => {
            $("#PLAadhar2Modal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "IDProofApplicant2",
            });
          }
        });
      $("#PLAadhar2Modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLAadhar2 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLAadhar2").attr("disabled", true);
      $("#upload_btn20_PLAadhar2").hide();
      $("#upload_btnloader_PLAadhar2").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLAadhar2DocLink: res.data.data.IDProofApplicant2,
                },
                () => $("#viewDocLink_PLAadhar2").css("display", "block")
              );
              PLAadhar2Status = "remove";
              PLStatusArray[1].status = PLAadhar2Status;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLAadhar2Modal").modal("hide");
              $("#cancelbtn_PLAadhar2").attr("disabled", false);
              $("#upload_btn20_PLAadhar2").show();
              $("#upload_btnloader_PLAadhar2").hide();
              $("#choose_PLAadhar2").hide();
              $("#X-btn_PLAadhar2").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLAadhar2").attr("disabled", false);
            $("#upload_btn20_PLAadhar2").show();
            $("#upload_btnloader_PLAadhar2").hide();
            $("#PLAadhar2Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLAadhar2").attr("disabled", false);
            $("#upload_btn20_PLAadhar2").show();
            $("#upload_btnloader_PLAadhar2").hide();
            $("#PLAadhar2Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLAadhar2").attr("disabled", false);
          $("#upload_btn20_PLAadhar2").show();
          $("#upload_btnloader_PLAadhar2").hide();
          $("#PLAadhar2Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLAadhar2 = () => {
    $("#X-btn_PLAadhar2").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "IDProofApplicant2",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLAadhar2Status = res.data.data.status;
          PLStatusArray[1].status = PLAadhar2Status;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLAadhar2").show();
          $("#X-btn_PLAadhar2").hide();
          $("#X-btn_PLAadhar2").attr("disabled", false);
          $("#viewDocLink_PLAadhar2").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLAadhar2").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLAadhar2").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLAadhar2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLAadhar2seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "IDProofApplicant2",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLAadhar2seecommentsbtn").hide();
          $("#PLAadhar2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLAadhar2hidecommentsbtn = () => {
    $("#PLAadhar2seecommentsbtn").show();
    $("#PLAadhar2seecommentsbtn").attr("disabled", false);
    $("#PLAadhar2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLPan1

  // PLPan2

  // PLPic1
  handlePLPic1Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "ApplicantPhoto1",
          },
          () => {
            $("#PLPic1Modal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "ApplicantPhoto1",
            });
          }
        });
      $("#PLPic1Modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLPic1 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLPic1").attr("disabled", true);
      $("#upload_btn20_PLPic1").hide();
      $("#upload_btnloader_PLPic1").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLPic1DocLink: res.data.data.ApplicantPhoto1,
                },
                () => $("#viewDocLink_PLPic1").css("display", "block")
              );
              PLPic1Status = "remove";
              PLStatusArray[2].status = PLPic1Status;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLPic1Modal").modal("hide");
              $("#cancelbtn_PLPic1").attr("disabled", false);
              $("#upload_btn20_PLPic1").show();
              $("#upload_btnloader_PLPic1").hide();
              $("#choose_PLPic1").hide();
              $("#X-btn_PLPic1").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLPic1").attr("disabled", false);
            $("#upload_btn20_PLPic1").show();
            $("#upload_btnloader_PLPic1").hide();
            $("#PLPic1Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLPic1").attr("disabled", false);
            $("#upload_btn20_PLPic1").show();
            $("#upload_btnloader_PLPic1").hide();
            $("#PLPic1Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLPic1").attr("disabled", false);
          $("#upload_btn20_PLPic1").show();
          $("#upload_btnloader_PLPic1").hide();
          $("#PLPic1Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLPic1 = () => {
    $("#X-btn_PLPic1").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "ApplicantPhoto1",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLPic1Status = res.data.data.status;
          PLStatusArray[2].status = PLPic1Status;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLPic1").show();
          $("#X-btn_PLPic1").hide();
          $("#X-btn_PLPic1").attr("disabled", false);
          $("#viewDocLink_PLPic1").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLPic1").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLPic1").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLPic1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLPic1seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ApplicantPhoto1",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLPic1seecommentsbtn").hide();
          $("#PLPic1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLPic1hidecommentsbtn = () => {
    $("#PLPic1seecommentsbtn").show();
    $("#PLPic1seecommentsbtn").attr("disabled", false);
    $("#PLPic1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLPic2
  handlePLPic2Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "ApplicantPhoto2",
          },
          () => {
            $("#PLPic2Modal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "ApplicantPhoto2",
            });
          }
        });
      $("#PLPic2Modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLPic2 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLPic2").attr("disabled", true);
      $("#upload_btn20_PLPic2").hide();
      $("#upload_btnloader_PLPic2").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLPic2DocLink: res.data.data.ApplicantPhoto2,
                },
                () => $("#viewDocLink_PLPic2").css("display", "block")
              );
              PLPic2Status = "remove";
              PLStatusArray[3].status = PLPic2Status;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLPic2Modal").modal("hide");
              $("#cancelbtn_PLPic2").attr("disabled", false);
              $("#upload_btn20_PLPic2").show();
              $("#upload_btnloader_PLPic2").hide();
              $("#choose_PLPic2").hide();
              $("#X-btn_PLPic2").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLPic2").attr("disabled", false);
            $("#upload_btn20_PLPic2").show();
            $("#upload_btnloader_PLPic2").hide();
            $("#PLPic2Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLPic2").attr("disabled", false);
            $("#upload_btn20_PLPic2").show();
            $("#upload_btnloader_PLPic2").hide();
            $("#PLPic2Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLPic2").attr("disabled", false);
          $("#upload_btn20_PLPic2").show();
          $("#upload_btnloader_PLPic2").hide();
          $("#PLPic2Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLPic2 = () => {
    $("#X-btn_PLPic2").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "ApplicantPhoto2",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLPic2Status = res.data.data.status;
          PLStatusArray[3].status = PLPic2Status;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLPic2").show();
          $("#X-btn_PLPic2").hide();
          $("#X-btn_PLPic2").attr("disabled", false);
          $("#viewDocLink_PLPic2").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLPic2").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLPic2").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLPic2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLPic2seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ApplicantPhoto2",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLPic2seecommentsbtn").hide();
          $("#PLPic2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLPic2hidecommentsbtn = () => {
    $("#PLPic2seecommentsbtn").show();
    $("#PLPic2seecommentsbtn").attr("disabled", false);
    $("#PLPic2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLPan3

  // PLCI
  handlePLCIChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "CertificateOfIncorporation",
          },
          () => {
            $("#PLCIModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "CertificateOfIncorporation",
            });
          }
        });
      $("#PLCIModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLCI = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLCI").attr("disabled", true);
      $("#upload_btn20_PLCI").hide();
      $("#upload_btnloader_PLCI").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLCIDocLink: res.data.data.CertificateOfIncorporation,
                },
                () => $("#viewDocLink_PLCI").css("display", "block")
              );
              PLCIStatus = "remove";
              PLStatusArray[4].status = PLCIStatus;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLCIModal").modal("hide");
              $("#cancelbtn_PLCI").attr("disabled", false);
              $("#upload_btn20_PLCI").show();
              $("#upload_btnloader_PLCI").hide();
              $("#choose_PLCI").hide();
              $("#X-btn_PLCI").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLCI").attr("disabled", false);
            $("#upload_btn20_PLCI").show();
            $("#upload_btnloader_PLCI").hide();
            $("#PLCIModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLCI").attr("disabled", false);
            $("#upload_btn20_PLCI").show();
            $("#upload_btnloader_PLCI").hide();
            $("#PLCIModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLCI").attr("disabled", false);
          $("#upload_btn20_PLCI").show();
          $("#upload_btnloader_PLCI").hide();
          $("#PLCIModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLCI = () => {
    $("#X-btn_PLCI").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "CertificateOfIncorporation",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLCIStatus = res.data.data.status;
          PLStatusArray[4].status = PLCIStatus;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLCI").show();
          $("#X-btn_PLCI").hide();
          $("#X-btn_PLCI").attr("disabled", false);
          $("#viewDocLink_PLCI").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLCI").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLCI").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLCIseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLCIseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "CertificateOfIncorporation",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLCIseecommentsbtn").hide();
          $("#PLCIhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLCIhidecommentsbtn = () => {
    $("#PLCIseecommentsbtn").show();
    $("#PLCIseecommentsbtn").attr("disabled", false);
    $("#PLCIhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLMOA
  handlePLMOAChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "MOA",
          },
          () => {
            $("#PLMOAModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "MOA",
            });
          }
        });
      $("#PLMOAModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLMOA = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLMOA").attr("disabled", true);
      $("#upload_btn20_PLMOA").hide();
      $("#upload_btnloader_PLMOA").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLMOADocLink: res.data.data.MOA,
                },
                () => $("#viewDocLink_PLMOA").css("display", "block")
              );
              PLMOAStatus = "remove";
              PLStatusArray[5].status = PLMOAStatus;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLMOAModal").modal("hide");
              $("#cancelbtn_PLMOA").attr("disabled", false);
              $("#upload_btn20_PLMOA").show();
              $("#upload_btnloader_PLMOA").hide();
              $("#choose_PLMOA").hide();
              $("#X-btn_PLMOA").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLMOA").attr("disabled", false);
            $("#upload_btn20_PLMOA").show();
            $("#upload_btnloader_PLMOA").hide();
            $("#PLMOAModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLMOA").attr("disabled", false);
            $("#upload_btn20_PLMOA").show();
            $("#upload_btnloader_PLMOA").hide();
            $("#PLMOAModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLMOA").attr("disabled", false);
          $("#upload_btn20_PLMOA").show();
          $("#upload_btnloader_PLMOA").hide();
          $("#PLMOAModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLMOA = () => {
    $("#X-btn_PLMOA").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "MOA",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLMOAStatus = res.data.data.status;
          PLStatusArray[5].status = PLMOAStatus;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLMOA").show();
          $("#X-btn_PLMOA").hide();
          $("#X-btn_PLMOA").attr("disabled", false);
          $("#viewDocLink_PLMOA").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLMOA").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLMOA").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLMOAseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLMOAseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "MOA",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLMOAseecommentsbtn").hide();
          $("#PLMOAhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLMOAhidecommentsbtn = () => {
    $("#PLMOAseecommentsbtn").show();
    $("#PLMOAseecommentsbtn").attr("disabled", false);
    $("#PLMOAhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLAOA
  handlePLAOAChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "AOA",
          },
          () => {
            $("#PLAOAModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "AOA",
            });
          }
        });
      $("#PLAOAModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLAOA = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLAOA").attr("disabled", true);
      $("#upload_btn20_PLAOA").hide();
      $("#upload_btnloader_PLAOA").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLAOADocLink: res.data.data.AOA,
                },
                () => $("#viewDocLink_PLAOA").css("display", "block")
              );
              PLAOAStatus = "remove";
              PLStatusArray[6].status = PLAOAStatus;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLAOAModal").modal("hide");
              $("#cancelbtn_PLAOA").attr("disabled", false);
              $("#upload_btn20_PLAOA").show();
              $("#upload_btnloader_PLAOA").hide();
              $("#choose_PLAOA").hide();
              $("#X-btn_PLAOA").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLAOA").attr("disabled", false);
            $("#upload_btn20_PLAOA").show();
            $("#upload_btnloader_PLAOA").hide();
            $("#PLAOAModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLAOA").attr("disabled", false);
            $("#upload_btn20_PLAOA").show();
            $("#upload_btnloader_PLAOA").hide();
            $("#PLAOAModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLAOA").attr("disabled", false);
          $("#upload_btn20_PLAOA").show();
          $("#upload_btnloader_PLAOA").hide();
          $("#PLAOAModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLAOA = () => {
    $("#X-btn_PLAOA").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "AOA",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLAOAStatus = res.data.data.status;
          PLStatusArray[6].status = PLAOAStatus;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLAOA").show();
          $("#X-btn_PLAOA").hide();
          $("#X-btn_PLAOA").attr("disabled", false);
          $("#viewDocLink_PLAOA").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLAOA").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLAOA").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLAOAseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLAOAseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "AOA",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLAOAseecommentsbtn").hide();
          $("#PLAOAhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLAOAhidecommentsbtn = () => {
    $("#PLAOAseecommentsbtn").show();
    $("#PLAOAseecommentsbtn").attr("disabled", false);
    $("#PLAOAhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLBR

  // PLBP

  // PLGumasta
  // PLPUnit
  handlePLPUnitChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "ProductionUnitPhoto",
          },
          () => {
            $("#PLPUnitModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "ProductionUnitPhoto",
            });
          }
        });
      $("#PLPUnitModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLPUnit = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLPUnit").attr("disabled", true);
      $("#upload_btn20_PLPUnit").hide();
      $("#upload_btnloader_PLPUnit").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLPUnitDocLink: res.data.data.ProductionUnitPhoto,
                },
                () => $("#viewDocLink_PLPUnit").css("display", "block")
              );
              PLPUnitStatus = "remove";
              PLStatusArray[7].status = PLPUnitStatus;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLPUnitModal").modal("hide");
              $("#cancelbtn_PLPUnit").attr("disabled", false);
              $("#upload_btn20_PLPUnit").show();
              $("#upload_btnloader_PLPUnit").hide();
              $("#choose_PLPUnit").hide();
              $("#X-btn_PLPUnit").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLPUnit").attr("disabled", false);
            $("#upload_btn20_PLPUnit").show();
            $("#upload_btnloader_PLPUnit").hide();
            $("#PLPUnitModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLPUnit").attr("disabled", false);
            $("#upload_btn20_PLPUnit").show();
            $("#upload_btnloader_PLPUnit").hide();
            $("#PLPUnitModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLPUnit").attr("disabled", false);
          $("#upload_btn20_PLPUnit").show();
          $("#upload_btnloader_PLPUnit").hide();
          $("#PLPUnitModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLPUnit = () => {
    $("#X-btn_PLPUnit").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "ProductionUnitPhoto",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLPUnitStatus = res.data.data.status;
          PLStatusArray[7].status = PLPUnitStatus;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLPUnit").show();
          $("#X-btn_PLPUnit").hide();
          $("#X-btn_PLPUnit").attr("disabled", false);
          $("#viewDocLink_PLPUnit").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLPUnit").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLPUnit").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLPUnitseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLPUnitseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ProductionUnitPhoto",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLPUnitseecommentsbtn").hide();
          $("#PLPUnithidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLPUnithidecommentsbtn = () => {
    $("#PLPUnitseecommentsbtn").show();
    $("#PLPUnitseecommentsbtn").attr("disabled", false);
    $("#PLPUnithidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLLayoutPlan
  handlePLLayoutPlanChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "LayoutPlanUnit",
          },
          () => {
            $("#PLLayoutPlanModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "LayoutPlanUnit",
            });
          }
        });
      $("#PLLayoutPlanModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLLayoutPlan = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLLayoutPlan").attr("disabled", true);
      $("#upload_btn20_PLLayoutPlan").hide();
      $("#upload_btnloader_PLLayoutPlan").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLLayoutPlanDocLink: res.data.data.LayoutPlanUnit,
                },
                () => $("#viewDocLink_PLLayoutPlan").css("display", "block")
              );
              PLLayoutPlanStatus = "remove";
              PLStatusArray[8].status = PLLayoutPlanStatus;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLLayoutPlanModal").modal("hide");
              $("#cancelbtn_PLLayoutPlan").attr("disabled", false);
              $("#upload_btn20_PLLayoutPlan").show();
              $("#upload_btnloader_PLLayoutPlan").hide();
              $("#choose_PLLayoutPlan").hide();
              $("#X-btn_PLLayoutPlan").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLLayoutPlan").attr("disabled", false);
            $("#upload_btn20_PLLayoutPlan").show();
            $("#upload_btnloader_PLLayoutPlan").hide();
            $("#PLLayoutPlanModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLLayoutPlan").attr("disabled", false);
            $("#upload_btn20_PLLayoutPlan").show();
            $("#upload_btnloader_PLLayoutPlan").hide();
            $("#PLLayoutPlanModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLLayoutPlan").attr("disabled", false);
          $("#upload_btn20_PLLayoutPlan").show();
          $("#upload_btnloader_PLLayoutPlan").hide();
          $("#PLLayoutPlanModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLLayoutPlan = () => {
    $("#X-btn_PLLayoutPlan").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "LayoutPlanUnit",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLLayoutPlanStatus = res.data.data.status;
          PLStatusArray[8].status = PLLayoutPlanStatus;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLLayoutPlan").show();
          $("#X-btn_PLLayoutPlan").hide();
          $("#X-btn_PLLayoutPlan").attr("disabled", false);
          $("#viewDocLink_PLLayoutPlan").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLLayoutPlan").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLLayoutPlan").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLLayoutPlanseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLLayoutPlanseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "LayoutPlanUnit",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLLayoutPlanseecommentsbtn").hide();
          $("#PLLayoutPlanhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLLayoutPlanhidecommentsbtn = () => {
    $("#PLLayoutPlanseecommentsbtn").show();
    $("#PLLayoutPlanseecommentsbtn").attr("disabled", false);
    $("#PLLayoutPlanhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLListofEquip
  handlePLListofEquipChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "ListOfEquipment",
          },
          () => {
            $("#PLListofEquipModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "ListOfEquipment",
            });
          }
        });
      $("#PLListofEquipModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLListofEquip = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLListofEquip").attr("disabled", true);
      $("#upload_btn20_PLListofEquip").hide();
      $("#upload_btnloader_PLListofEquip").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLListofEquipDocLink: res.data.data.ListOfEquipment,
                },
                () => $("#viewDocLink_PLListofEquip").css("display", "block")
              );
              PLListofEquipStatus = "remove";
              PLStatusArray[9].status = PLListofEquipStatus;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLListofEquipModal").modal("hide");
              $("#cancelbtn_PLListofEquip").attr("disabled", false);
              $("#upload_btn20_PLListofEquip").show();
              $("#upload_btnloader_PLListofEquip").hide();
              $("#choose_PLListofEquip").hide();
              $("#X-btn_PLListofEquip").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLListofEquip").attr("disabled", false);
            $("#upload_btn20_PLListofEquip").show();
            $("#upload_btnloader_PLListofEquip").hide();
            $("#PLListofEquipModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLListofEquip").attr("disabled", false);
            $("#upload_btn20_PLListofEquip").show();
            $("#upload_btnloader_PLListofEquip").hide();
            $("#PLListofEquipModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLListofEquip").attr("disabled", false);
          $("#upload_btn20_PLListofEquip").show();
          $("#upload_btnloader_PLListofEquip").hide();
          $("#PLListofEquipModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLListofEquip = () => {
    $("#X-btn_PLListofEquip").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "ListOfEquipment",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLListofEquipStatus = res.data.data.status;
          PLStatusArray[9].status = PLListofEquipStatus;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLListofEquip").show();
          $("#X-btn_PLListofEquip").hide();
          $("#X-btn_PLListofEquip").attr("disabled", false);
          $("#viewDocLink_PLListofEquip").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLListofEquip").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLListofEquip").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLListofEquipseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLListofEquipseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ListOfEquipment",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLListofEquipseecommentsbtn").hide();
          $("#PLListofEquiphidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLListofEquiphidecommentsbtn = () => {
    $("#PLListofEquipseecommentsbtn").show();
    $("#PLListofEquipseecommentsbtn").attr("disabled", false);
    $("#PLListofEquiphidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLWAReport
  handlePLWAReportChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "WaterAnalysisReport",
          },
          () => {
            $("#PLWAReportModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "WaterAnalysisReport",
            });
          }
        });
      $("#PLWAReportModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLWAReport = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLWAReport").attr("disabled", true);
      $("#upload_btn20_PLWAReport").hide();
      $("#upload_btnloader_PLWAReport").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLWAReportDocLink: res.data.data.WaterAnalysisReport,
                },
                () => $("#viewDocLink_PLWAReport").css("display", "block")
              );
              PLWAReportStatus = "remove";
              PLStatusArray[10].status = PLWAReportStatus;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLWAReportModal").modal("hide");
              $("#cancelbtn_PLWAReport").attr("disabled", false);
              $("#upload_btn20_PLWAReport").show();
              $("#upload_btnloader_PLWAReport").hide();
              $("#choose_PLWAReport").hide();
              $("#X-btn_PLWAReport").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLWAReport").attr("disabled", false);
            $("#upload_btn20_PLWAReport").show();
            $("#upload_btnloader_PLWAReport").hide();
            $("#PLWAReportModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLWAReport").attr("disabled", false);
            $("#upload_btn20_PLWAReport").show();
            $("#upload_btnloader_PLWAReport").hide();
            $("#PLWAReportModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLWAReport").attr("disabled", false);
          $("#upload_btn20_PLWAReport").show();
          $("#upload_btnloader_PLWAReport").hide();
          $("#PLWAReportModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLWAReport = () => {
    $("#X-btn_PLWAReport").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "WaterAnalysisReport",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLWAReportStatus = res.data.data.status;
          PLStatusArray[10].status = PLWAReportStatus;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLWAReport").show();
          $("#X-btn_PLWAReport").hide();
          $("#X-btn_PLWAReport").attr("disabled", false);
          $("#viewDocLink_PLWAReport").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLWAReport").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLWAReport").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLWAReportseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLWAReportseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "WaterAnalysisReport",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLWAReportseecommentsbtn").hide();
          $("#PLWAReporthidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLWAReporthidecommentsbtn = () => {
    $("#PLWAReportseecommentsbtn").show();
    $("#PLWAReportseecommentsbtn").attr("disabled", false);
    $("#PLWAReporthidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLListofDir
  handlePLListofDirChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "DirectorsFullAddress",
          },
          () => {
            $("#PLListofDirModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "DirectorsFullAddress",
            });
          }
        });
      $("#PLListofDirModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLListofDir = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLListofDir").attr("disabled", true);
      $("#upload_btn20_PLListofDir").hide();
      $("#upload_btnloader_PLListofDir").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLListofDirDocLink: res.data.data.DirectorsFullAddress,
                },
                () => $("#viewDocLink_PLListofDir").css("display", "block")
              );
              PLListofDirStatus = "remove";
              PLStatusArray[11].status = PLListofDirStatus;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLListofDirModal").modal("hide");
              $("#cancelbtn_PLListofDir").attr("disabled", false);
              $("#upload_btn20_PLListofDir").show();
              $("#upload_btnloader_PLListofDir").hide();
              $("#choose_PLListofDir").hide();
              $("#X-btn_PLListofDir").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLListofDir").attr("disabled", false);
            $("#upload_btn20_PLListofDir").show();
            $("#upload_btnloader_PLListofDir").hide();
            $("#PLListofDirModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLListofDir").attr("disabled", false);
            $("#upload_btn20_PLListofDir").show();
            $("#upload_btnloader_PLListofDir").hide();
            $("#PLListofDirModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLListofDir").attr("disabled", false);
          $("#upload_btn20_PLListofDir").show();
          $("#upload_btnloader_PLListofDir").hide();
          $("#PLListofDirModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLListofDir = () => {
    $("#X-btn_PLListofDir").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "DirectorsFullAddress",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLListofDirStatus = res.data.data.status;
          PLStatusArray[11].status = PLListofDirStatus;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLListofDir").show();
          $("#X-btn_PLListofDir").hide();
          $("#X-btn_PLListofDir").attr("disabled", false);
          $("#viewDocLink_PLListofDir").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLListofDir").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLListofDir").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLListofDirseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLListofDirseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "DirectorsFullAddress",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLListofDirseecommentsbtn").hide();
          $("#PLListofDirhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLListofDirhidecommentsbtn = () => {
    $("#PLListofDirseecommentsbtn").show();
    $("#PLListofDirseecommentsbtn").attr("disabled", false);
    $("#PLListofDirhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLRecallPlan
  handlePLRecallPlanChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "RecallPlan",
          },
          () => {
            $("#PLRecallPlanModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "RecallPlan",
            });
          }
        });
      $("#PLRecallPlanModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLRecallPlan = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLRecallPlan").attr("disabled", true);
      $("#upload_btn20_PLRecallPlan").hide();
      $("#upload_btnloader_PLRecallPlan").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLRecallPlanDocLink: res.data.data.RecallPlan,
                },
                () => $("#viewDocLink_PLRecallPlan").css("display", "block")
              );
              PLRecallPlanStatus = "remove";
              PLStatusArray[12].status = PLRecallPlanStatus;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLRecallPlanModal").modal("hide");
              $("#cancelbtn_PLRecallPlan").attr("disabled", false);
              $("#upload_btn20_PLRecallPlan").show();
              $("#upload_btnloader_PLRecallPlan").hide();
              $("#choose_PLRecallPlan").hide();
              $("#X-btn_PLRecallPlan").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLRecallPlan").attr("disabled", false);
            $("#upload_btn20_PLRecallPlan").show();
            $("#upload_btnloader_PLRecallPlan").hide();
            $("#PLRecallPlanModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLRecallPlan").attr("disabled", false);
            $("#upload_btn20_PLRecallPlan").show();
            $("#upload_btnloader_PLRecallPlan").hide();
            $("#PLRecallPlanModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLRecallPlan").attr("disabled", false);
          $("#upload_btn20_PLRecallPlan").show();
          $("#upload_btnloader_PLRecallPlan").hide();
          $("#PLRecallPlanModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLRecallPlan = () => {
    $("#X-btn_PLRecallPlan").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "RecallPlan",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLRecallPlanStatus = res.data.data.status;
          PLStatusArray[12].status = PLRecallPlanStatus;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLRecallPlan").show();
          $("#X-btn_PLRecallPlan").hide();
          $("#X-btn_PLRecallPlan").attr("disabled", false);
          $("#viewDocLink_PLRecallPlan").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLRecallPlan").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLRecallPlan").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLRecallPlanseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLRecallPlanseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "RecallPlan",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLRecallPlanseecommentsbtn").hide();
          $("#PLRecallPlanhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLRecallPlanhidecommentsbtn = () => {
    $("#PLRecallPlanseecommentsbtn").show();
    $("#PLRecallPlanseecommentsbtn").attr("disabled", false);
    $("#PLRecallPlanhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLTradeLicence
  handlePLbusinessNatureChange = (e) => {
    this.setState({
      PLbusinessNature: e.target.value,
    });
  };

  // PLAddressProofType
  PLAddressProofType_radioChange = (e) => {
    this.setState(
      {
        PLAddressProofType_radio: e.currentTarget.value,
      },
      () => {
        if (this.state.PLAddressProofType_radio != "CopyOfRegistry") {
          $(".RentAgreement").css("display", "flex");
          $(".CopyOfRegistry").hide();
        } else if (this.state.PLAddressProofType_radio == "CopyOfRegistry") {
          $(".CopyOfRegistry").css("display", "flex");
          $(".RentAgreement").hide();
        }
        this.checkhandlePLProceedBtn();
      }
    );
  };

  // PLCopyOfRegistry
  handlePLCopyOfRegistryChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "OwnershipDeed",
          },
          () => {
            $("#PLCopyOfRegistryModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "OwnershipDeed",
            });
          }
        });
      $("#PLCopyOfRegistryModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLCopyOfRegistry = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLCopyOfRegistry").attr("disabled", true);
      $("#upload_btn20_PLCopyOfRegistry").hide();
      $("#upload_btnloader_PLCopyOfRegistry").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLCopyOfRegistryDocLink: res.data.data.OwnershipDeed,
                },
                () => $("#viewDocLink_PLCopyOfRegistry").css("display", "block")
              );
              PLCopyOfRegistryStatus = "remove";
              PLOptStatusArray[0].status = PLCopyOfRegistryStatus;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLCopyOfRegistryModal").modal("hide");
              $("#cancelbtn_PLCopyOfRegistry").attr("disabled", false);
              $("#upload_btn20_PLCopyOfRegistry").show();
              $("#upload_btnloader_PLCopyOfRegistry").hide();
              $("#choose_PLCopyOfRegistry").hide();
              $("#X-btn_PLCopyOfRegistry").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLCopyOfRegistry").attr("disabled", false);
            $("#upload_btn20_PLCopyOfRegistry").show();
            $("#upload_btnloader_PLCopyOfRegistry").hide();
            $("#PLCopyOfRegistryModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLCopyOfRegistry").attr("disabled", false);
            $("#upload_btn20_PLCopyOfRegistry").show();
            $("#upload_btnloader_PLCopyOfRegistry").hide();
            $("#PLCopyOfRegistryModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLCopyOfRegistry").attr("disabled", false);
          $("#upload_btn20_PLCopyOfRegistry").show();
          $("#upload_btnloader_PLCopyOfRegistry").hide();
          $("#PLCopyOfRegistryModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLCopyOfRegistry = () => {
    $("#X-btn_PLCopyOfRegistry").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "OwnershipDeed",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLCopyOfRegistryStatus = res.data.data.status;
          PLOptStatusArray[0].status = PLCopyOfRegistryStatus;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLCopyOfRegistry").show();
          $("#X-btn_PLCopyOfRegistry").hide();
          $("#X-btn_PLCopyOfRegistry").attr("disabled", false);
          $("#viewDocLink_PLCopyOfRegistry").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLCopyOfRegistry").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLCopyOfRegistry").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLCopyOfRegistryseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLCopyOfRegistryseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "OwnershipDeed",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLCopyOfRegistryseecommentsbtn").hide();
          $("#PLCopyOfRegistryhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLCopyOfRegistryhidecommentsbtn = () => {
    $("#PLCopyOfRegistryseecommentsbtn").show();
    $("#PLCopyOfRegistryseecommentsbtn").attr("disabled", false);
    $("#PLCopyOfRegistryhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLRentAgreement
  handlePLRentAgreementChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "RentAgreement",
          },
          () => {
            $("#PLRentAgreementModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "RentAgreement",
            });
          }
        });
      $("#PLRentAgreementModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLRentAgreement = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLRentAgreement").attr("disabled", true);
      $("#upload_btn20_PLRentAgreement").hide();
      $("#upload_btnloader_PLRentAgreement").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLRentAgreementDocLink: res.data.data.RentAgreement,
                },
                () => $("#viewDocLink_PLRentAgreement").css("display", "block")
              );
              PLRentAgreementStatus = "remove";
              PLOptStatusArray[1].status = PLRentAgreementStatus;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLRentAgreementModal").modal("hide");
              $("#cancelbtn_PLRentAgreement").attr("disabled", false);
              $("#upload_btn20_PLRentAgreement").show();
              $("#upload_btnloader_PLRentAgreement").hide();
              $("#choose_PLRentAgreement").hide();
              $("#X-btn_PLRentAgreement").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLRentAgreement").attr("disabled", false);
            $("#upload_btn20_PLRentAgreement").show();
            $("#upload_btnloader_PLRentAgreement").hide();
            $("#PLRentAgreementModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLRentAgreement").attr("disabled", false);
            $("#upload_btn20_PLRentAgreement").show();
            $("#upload_btnloader_PLRentAgreement").hide();
            $("#PLRentAgreementModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLRentAgreement").attr("disabled", false);
          $("#upload_btn20_PLRentAgreement").show();
          $("#upload_btnloader_PLRentAgreement").hide();
          $("#PLRentAgreementModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLRentAgreement = () => {
    $("#X-btn_PLRentAgreement").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "RentAgreement",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLRentAgreementStatus = res.data.data.status;
          PLOptStatusArray[1].status = PLRentAgreementStatus;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLRentAgreement").show();
          $("#X-btn_PLRentAgreement").hide();
          $("#X-btn_PLRentAgreement").attr("disabled", false);
          $("#viewDocLink_PLRentAgreement").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLRentAgreement").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLRentAgreement").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLRentAgreementseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLRentAgreementseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "RentAgreement",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLRentAgreementseecommentsbtn").hide();
          $("#PLRentAgreementhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLRentAgreementhidecommentsbtn = () => {
    $("#PLRentAgreementseecommentsbtn").show();
    $("#PLRentAgreementseecommentsbtn").attr("disabled", false);
    $("#PLRentAgreementhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLPR
  handlePLPRChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "PropertyTaxReceipt",
          },
          () => {
            $("#PLPRModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "PropertyTaxReceipt",
            });
          }
        });
      $("#PLPRModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadPLPR = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_PLPR").attr("disabled", true);
      $("#upload_btn20_PLPR").hide();
      $("#upload_btnloader_PLPR").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  PLPRDocLink: res.data.data.PropertyTaxReceipt,
                },
                () => $("#viewDocLink_PLPR").css("display", "block")
              );
              PLPRStatus = "remove";
              PLStatusArray[14].status = PLPRStatus;
              this.checkDropdownValue();
              this.checkhandlePLProceedBtn();
              $("#PLPRModal").modal("hide");
              $("#cancelbtn_PLPR").attr("disabled", false);
              $("#upload_btn20_PLPR").show();
              $("#upload_btnloader_PLPR").hide();
              $("#choose_PLPR").hide();
              $("#X-btn_PLPR").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_PLPR").attr("disabled", false);
            $("#upload_btn20_PLPR").show();
            $("#upload_btnloader_PLPR").hide();
            $("#PLPRModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_PLPR").attr("disabled", false);
            $("#upload_btn20_PLPR").show();
            $("#upload_btnloader_PLPR").hide();
            $("#PLPRModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_PLPR").attr("disabled", false);
          $("#upload_btn20_PLPR").show();
          $("#upload_btnloader_PLPR").hide();
          $("#PLPRModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLPR = () => {
    $("#X-btn_PLPR").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "PropertyTaxReceipt",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          PLPRStatus = res.data.data.status;
          PLStatusArray[14].status = PLPRStatus;
          this.checkDropdownValue();
          this.checkhandlePLProceedBtn();
          $("#choose_PLPR").show();
          $("#X-btn_PLPR").hide();
          $("#X-btn_PLPR").attr("disabled", false);
          $("#viewDocLink_PLPR").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_PLPR").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_PLPR").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLPRseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLPRseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PropertyTaxReceipt",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#PLPRseecommentsbtn").hide();
          $("#PLPRhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLPRhidecommentsbtn = () => {
    $("#PLPRseecommentsbtn").show();
    $("#PLPRseecommentsbtn").attr("disabled", false);
    $("#PLPRhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // PLAdditional
  handlePLAddFileChange = (e, index, status) => {
    PLadditionalReuploadIndex = index;
    PLadditionalReuploadStatus = status;
    $(".upload_btn20_7").show();
    $(".upload_btnloader_7").hide();
    const file = e.target.files[0];
    if (!PLadditionalFilesNames.includes(this.state.PLAdd_DocName.trim())) {
      const myfile = e.target.files;
      if (ChooseNowBtnsDisable == "Yes") {
        this.showNotification(
          "Files cannot be uploaded when the service is locked!"
        );
      } else if (myfile.length == 1) {
        const file = myfile[0];
        if (file.type.match("audio.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.type.match("video.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.size > 104857600) {
          this.showNotification("File too big!");
        } else if (file.name.length > 100) {
          this.showNotification("File name limit exceed!");
        } else {
          this.setState(
            {
              fileName: file.name,
              fileBase64: file,
              documentType: "additional",
              documentName: this.state.PLAdd_DocName,
            },
            () => {
              $(".PLAddModal").modal({
                backdrop: "static",
                keyboard: false,
              });
            }
          );
        }
      } else if (myfile.length > 1) {
        var zip = new JSZip();
        for (let i = 0; i < myfile.length; i++) {
          zip.file(myfile[i].name, myfile[i], {
            base64: true,
          });
        }
        zip
          .generateAsync({
            type: "blob",
          })
          .then((content) => {
            if (content.size > 104857600) {
              this.showNotification("File too big!");
            } else {
              this.setState({
                fileName: "download.zip",
                fileBase64: content,
                documentType: "additional",
                documentName: this.state.PLAdd_DocName,
              });
            }
          });
        $(".PLAddModal").modal({
          backdrop: "static",
          keyboard: false,
        });
      } else {
        this.showNotification("File cannot be null!");
      }
    } else {
      this.showNotification(
        "Document names can not be the same, Try again with different name!"
      );
    }
  };

  uploadPLFile = (FileIndex, FileStatus) => {
    let AdditionaldocumentName1;
    if (FileStatus == "reupload") {
      $(".proceednowbtn").attr("disabled", true);
      let fileTobeUploaded = this.state.PLadditionalFiles[FileIndex];
      AdditionaldocumentName1 = Object.keys(fileTobeUploaded)[0];
      mainaction = "reupload";
    } else {
      AdditionaldocumentName1 = this.state.documentName;
      mainaction = "upload";
    }
    if (AdditionaldocumentName1.trim() == "") {
      $(".PLAddModal").modal("hide");
      this.showNotification("Enter document Name first");
    } else if (this.state.comment.length <= 250) {
      $(".cancelbtn_7").attr("disabled", true);
      $(".upload_btn20_7").hide();
      $(".upload_btnloader_7").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", AdditionaldocumentName1);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            $(".PLAddModal").modal("hide");
            $(".cancelbtn_7").attr("disabled", false);
            $(`#${FileIndex}PLFileseecommentsbtn`).attr("disabled", false);
            if (PLadditionalReupload == false) {
              PLadditionalFilesNames.push(this.state.PLAdd_DocName);
              this.state.PLadditionalFiles.pop();
              this.setState(
                {
                  comment: "",
                  PLadditionalFiles: [
                    ...this.state.PLadditionalFiles,
                    res.data.data,
                  ],
                },
                () => {
                  this.setState(
                    {
                      api_PLadditionalFiles: "Yes",
                    },
                    () => {
                      this.PLadditionalFilesArea();
                      this.checkDropdownValue();
                      this.checkhandlePLProceedBtn();
                    }
                  );

                  $(".PLadditionalInput").attr("disabled", true);
                }
              );
            } else if (PLadditionalReupload == true) {
              $(".proceednowbtn").attr("disabled", false);
              var array = [...this.state.PLadditionalFiles];
              array.splice(FileIndex, 1, res.data.data);
              this.setState(
                {
                  comment: "",
                  api_PLadditionalFiles: "Yes",
                  PLadditionalFiles: array,
                },
                () => {
                  this.PLadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandlePLProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".PLAddModal").modal("hide");
            this.showNotification("Something Went Wrong!");
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".PLAddModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          this.showNotification("Something went wrong, Try again!");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removePLFile = (index) => {
    $("#X-btn_7").attr("disabled", true);
    let fileTobeDeleted = this.state.PLadditionalFiles[index];
    let NameOfAddFile = Object.keys(fileTobeDeleted)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "additional",
          documentName: NameOfAddFile,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          var array = [...this.state.PLadditionalFiles];
          if (res.data.data.status == "reupload") {
            array[index].status = "reupload";
            this.setState(
              {
                PLadditionalFiles: array,
              },
              () => {
                this.PLadditionalFilesArea();
                this.checkDropdownValue();
                this.checkhandlePLProceedBtn();
              }
            );
            this.showNotification(res.data.message);
          } else {
            array.splice(index, 1);
            if (array.length > 0) {
              this.setState(
                {
                  PLadditionalFiles: array,
                },
                () => {
                  this.PLadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandlePLProceedBtn();
                }
              );
            } else if (array.length == 0) {
              this.setState(
                {
                  PLadditionalFiles: [],
                  PLAdd_DocName: "",
                  api_PLadditionalFiles: "No",
                  IsPLAddFileDisabled: true,
                },
                () => {
                  this.PLadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandlePLProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_7").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handlePLFileseeComments = (index) => {
    $(".commentsbtn").attr("disabled", false);
    $(`#${index}PLFileseecommentsbtn`).attr("disabled", true);
    let SelectedFile = this.state.PLadditionalFiles[index];
    let NameOfAddFile = Object.keys(SelectedFile)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "additional",
          documentName: NameOfAddFile,
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(`#${index}PLFileseecommentsbtn`).hide();
          $(".hidecommentsbtn").hide();
          $(`#${index}PLFilehidecommentsbtn`).show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLFilehideComments = (index) => {
    $(".seecommentsbtn").show();
    $(`#${index}PLFileseecommentsbtn`).show();
    $(`#${index}PLFileseecommentsbtn`).attr("disabled", false);
    $(`#${index}PLFilehidecommentsbtn`).hide();
    $(".hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  checkAddCommentPLFiles = (FileIndex, isDisabled) => {
    if (isDisabled == true) {
      return (
        <>
          <button
            className="seecommentsbtn PLFileseecommentsbtn"
            id={`${FileIndex}PLFileseecommentsbtn`}
            disabled
            onClick={() => this.handlePLFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn PLFilehidecommentsbtn"
            id={`${FileIndex}PLFilehidecommentsbtn`}
            onClick={() => this.handlePLFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    } else if (isDisabled == false) {
      return (
        <>
          <button
            className="seecommentsbtn commentsbtn PLFileseecommentsbtn"
            id={`${FileIndex}PLFileseecommentsbtn`}
            onClick={() => this.handlePLFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn PLFilehidecommentsbtn"
            id={`${FileIndex}PLFilehidecommentsbtn`}
            onClick={() => this.handlePLFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    }
  };

  checkStatusPLAddFiles = (status, link, index) => {
    PLadditionalFilesIndex = index;
    if (HideProceedBtn == "Yes") {
      $(".proceednowbtn").hide();
      if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
          </>
        );
      }
    } else {
      if (status == undefined) {
        status = "open";
      }
      if (status == "open") {
        mainaction = "upload";
        PLadditionalReupload = true;
        $(".proceednowbtn").show();
        return (
          <>
            <label
              htmlFor={`ul_file_PLAdd${index}`}
              className="choose_btn choose_7"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              multiple
              name="file[]"
              className="upload-photo"
              id={`ul_file_PLAdd${index}`}
              // disabled={this.state.IsPLAddFileDisabled}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handlePLAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "remove") {
        mainaction = "upload";
        $(".proceednowbtn").show();
        return (
          <>
            <button
              className="X-btn X-btn_7"
              onClick={() => this.removePLFile(index)}
            >
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_1025.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "reupload") {
        PLadditionalReupload = true;
        mainaction = "reupload";
        $(".proceednowbtn").show();
        $(".proceednowbtn").attr("disabled", true);
        return (
          <>
            <label
              htmlFor={`ul_file_PLAdd${index}`}
              className="choose_btn choose_7"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              multiple
              name="file[]"
              className="upload-photo"
              id={`ul_file_PLAdd${index}`}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handlePLAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
          </>
        );
      }
    }
  };

  AdditionalPLFileDisabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero PLadditionalInput"
        value={NameOfAddFile}
        disabled
        onChange={(e) => this.handlePLAdd_DocNameChange(e)}
      />
    );
  };

  AdditionalPLFileEnabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero PLadditionalInput"
        value={NameOfAddFile}
        onChange={(e) => this.handlePLAdd_DocNameChange(e)}
      />
    );
  };

  PLadditionalFilesArea = () => {
    PLadditionalFilesNames = [];
    if (this.state.api_PLadditionalFiles == "Yes") {
      return this.state.PLadditionalFiles.map((file, index) => {
        let NameOfAddFile = Object.keys(file)[0];
        PLadditionalFilesNames.push(NameOfAddFile);
        let link = file[Object.keys(file)[0]];
        let AdditionalFileInputField;
        let commentDisabled;
        if (file.status == "open" || file.status == undefined) {
          AdditionalFileInputField =
            this.AdditionalPLFileEnabledField(NameOfAddFile);
          commentDisabled = true;
        } else if (file.status != "open") {
          AdditionalFileInputField =
            this.AdditionalPLFileDisabledField(NameOfAddFile);
          commentDisabled = false;
        }
        return (
          <div className="displayflex" key={index}>
            <div className="uploadnocol">
              <p className="uploadadditionalsidetext">{index + 1}&#41;</p>
            </div>
            <div className="uploadadditionaldatacol">
              <div className="row mb-2">
                <div className="col-md-5">{AdditionalFileInputField}</div>
                <div className="col-md-7">
                  <div className="float-md-right float-left">
                    {this.checkAddCommentPLFiles(index, commentDisabled)}
                    {this.checkStatusPLAddFiles(file.status, link, index)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.api_PLadditionalFiles == "No") {
      return (
        <div className="displayflex">
          <div className="uploadnocol">
            <p className="uploadadditionalsidetext">1&#41;</p>
          </div>
          <div className="uploadadditionaldatacol">
            <div className="row mb-2">
              <div className="col-md-5">
                <input
                  type="text"
                  placeholder="Document name..."
                  maxLength="150"
                  className="uploadtextfield marginzero"
                  value={this.state.PLAdd_DocName}
                  onChange={(e) => this.handlePLAdd_DocNameChange(e)}
                />
              </div>
              <div className="col-md-7">
                <div className="fl_right">
                  {this.checkAddCommentPLFiles(0, true)}
                  {this.checkStatusPLAddFiles("open", null, 0)}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  handlePLAdd_DocNameChange = (e) => {
    this.setState(
      {
        PLAdd_DocName: e.target.value,
      },
      () => {
        if (this.state.PLAdd_DocName.trim().length > 0) {
          this.setState({
            IsPLAddFileDisabled: false,
          });
        } else {
          this.setState({
            IsPLAddFileDisabled: true,
          });
        }
      }
    );
  };

  handleAddPLFileBtn = (FileIndex) => {
    const { PLAdd_DocName, PLadditionalFiles } = this.state;

    let prevFile = PLadditionalFiles[FileIndex];
    let NameOfPrevFile = Object.keys(prevFile)[0];
    let valueOffile = prevFile[Object.keys(prevFile)[0]];

    if (FileIndex == undefined) {
      this.showNotification("empty fields");
    } else if (PLAdd_DocName.trim() == "") {
      this.showNotification("Required fields cannot be empty!");
    } else if (valueOffile == null) {
      this.showNotification("Choose a file");
    } else {
      this.setState(
        {
          PLadditionalFiles: [...this.state.PLadditionalFiles, ""],
          PLAdd_DocName: "",
          IsPLAddFileDisabled: true,
        },
        () => {
          $(".PLadditionalInput").attr("disabled", false);
        }
      );
    }
  };

  // Proceed Checks
  PLcheckRemainingReuploads = () => {
    if (this.state.licenseType == "T") {
      if (this.state.PLAddressProofType_radio == "CopyOfRegistry") {
        if (
          PLAadhar1Status != "reupload" &&
          PLAadhar2Status != "reupload" &&
          PLPic1Status != "reupload" &&
          PLPic2Status != "reupload" &&
          PLCIStatus != "reupload" &&
          PLMOAStatus != "reupload" &&
          PLAOAStatus != "reupload" &&
          PLCopyOfRegistryStatus != "reupload"
        ) {
          AnyReuploadOccuredPL = "No";
        } else {
          $(".proceednowbtn3").show();
          AnyReuploadOccuredPL = "Yes";
        }
      } else {
        if (
          PLAadhar1Status != "reupload" &&
          PLAadhar2Status != "reupload" &&
          PLPic1Status != "reupload" &&
          PLPic2Status != "reupload" &&
          PLCIStatus != "reupload" &&
          PLMOAStatus != "reupload" &&
          PLAOAStatus != "reupload" &&
          PLRentAgreementStatus != "reupload"
        ) {
          AnyReuploadOccuredPL = "No";
        } else {
          $(".proceednowbtn3").show();
          AnyReuploadOccuredPL = "Yes";
        }
      }
    } else {
      if (this.state.PLAddressProofType_radio == "CopyOfRegistry") {
        if (
          PLAadhar1Status != "reupload" &&
          PLAadhar2Status != "reupload" &&
          PLPic1Status != "reupload" &&
          PLPic2Status != "reupload" &&
          PLCIStatus != "reupload" &&
          PLMOAStatus != "reupload" &&
          PLAOAStatus != "reupload" &&
          PLPUnitStatus != "reupload" &&
          PLLayoutPlanStatus != "reupload" &&
          PLListofEquipStatus != "reupload" &&
          PLWAReportStatus != "reupload" &&
          PLListofDirStatus != "reupload" &&
          PLRecallPlanStatus != "reupload" &&
          PLCopyOfRegistryStatus != "reupload"
        ) {
          AnyReuploadOccuredPL = "No";
        } else {
          $(".proceednowbtn3").show();
          AnyReuploadOccuredPL = "Yes";
        }
      } else {
        if (
          PLAadhar1Status != "reupload" &&
          PLAadhar2Status != "reupload" &&
          PLPic1Status != "reupload" &&
          PLPic2Status != "reupload" &&
          PLCIStatus != "reupload" &&
          PLMOAStatus != "reupload" &&
          PLAOAStatus != "reupload" &&
          PLPUnitStatus != "reupload" &&
          PLLayoutPlanStatus != "reupload" &&
          PLListofEquipStatus != "reupload" &&
          PLWAReportStatus != "reupload" &&
          PLListofDirStatus != "reupload" &&
          PLRecallPlanStatus != "reupload" &&
          PLRentAgreementStatus != "reupload"
        ) {
          AnyReuploadOccuredPL = "No";
        } else {
          $(".proceednowbtn3").show();
          AnyReuploadOccuredPL = "Yes";
        }
      }
    }
  };

  checkhandlePLProceedBtn = () => {
    let statusCheckReupload = this.statusCheckFunction(
      "reupload",
      PLStatusArray
    );
    let PLadditionalStatusArrayCheck = this.statusCheckFunction(
      "reupload",
      this.state.PLadditionalFiles
    );
    let goodtogo = "No";
    if (this.state.PLAddressProofType_radio == "CopyOfRegistry") {
      if (
        PLOptStatusArray[0].status != "reupload" &&
        PLOptStatusArray[0].status != "upload" &&
        PLOptStatusArray[0].status != "open"
      ) {
        goodtogo = "Yes";
      } else {
        goodtogo = "No";
      }
    } else if (this.state.PLAddressProofType_radio == "RentAgreement") {
      if (
        PLOptStatusArray[1].status != "reupload" &&
        PLOptStatusArray[1].status != "upload" &&
        PLOptStatusArray[1].status != "open"
      ) {
        goodtogo = "Yes";
      } else {
        goodtogo = "No";
      }
    } else {
      goodtogo = "No";
    }

    if (
      AnyReuploadOccuredPL == "Yes" &&
      statusCheckReupload == undefined &&
      goodtogo == "Yes" &&
      PLadditionalStatusArrayCheck == undefined
    ) {
      $(".proceednowbtn3").attr("disabled", false);
      PLproceednowforword = "success";
    } else if (
      PLAadhar1Status == "remove" &&
      PLAadhar2Status == "remove" &&
      PLPic1Status == "remove" &&
      PLPic2Status == "remove" &&
      PLCIStatus == "remove" &&
      PLMOAStatus == "remove" &&
      PLAOAStatus == "remove" &&
      this.state.licenseType == "T" &&
      goodtogo == "Yes"
    ) {
      $(".proceednowbtn3").attr("disabled", false);
      PLproceednowforword = "success";
    } else if (
      PLAadhar1Status == "remove" &&
      PLAadhar2Status == "remove" &&
      PLPic1Status == "remove" &&
      PLPic2Status == "remove" &&
      PLCIStatus == "remove" &&
      PLMOAStatus == "remove" &&
      PLAOAStatus == "remove" &&
      PLPUnitStatus == "remove" &&
      PLLayoutPlanStatus == "remove" &&
      PLListofEquipStatus == "remove" &&
      PLWAReportStatus == "remove" &&
      PLListofDirStatus == "remove" &&
      PLRecallPlanStatus == "remove" &&
      this.state.licenseType == "M" &&
      goodtogo == "Yes"
    ) {
      $(".proceednowbtn3").attr("disabled", false);
      PLproceednowforword = "success";
    } else {
      $(".proceednowbtn3").attr("disabled", true);
      PLproceednowforword = "fail";
    }
  };

  handlePLProceedBtn = () => {
    this.checkhandlePLProceedBtn();
    if (this.state.PLbusinessNature.trim() == "") {
      this.showNotification("Required fields can not be empty!");
    } else if (this.state.PLbusinessNature.trim().length > 200) {
      this.showNotification("Nature of your Business / Brand limit exceed!");
    } else if (PLproceednowforword == "success") {
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=lockService",
          {
            userId: Decuserid,
            requestType: temp,
            agentId: Decagentid,

            serviceId: Decserviceid,
            serviceName: Decservicename,
            serviceType: this.state.serviceType,
            FY: "",
            Month: "",
            inputFields: [
              {
                inputFieldName: "AddressProofType",
                inputFieldValue: this.state.PLAddressProofType_radio,
                inputFieldtype: "general",
              },
              {
                inputFieldName: "Nature",
                inputFieldValue: this.state.PLbusinessNature,
                inputFieldtype: "general",
              },
            ],
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.showNotification(res.data.message);
            this.getserviceInformationApi();
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else {
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => { });
    }
  };

  // HUF

  // HUFAadhar
  handleHUFAadharChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "IDProofApplicant",
          },
          () => {
            $("#HUFAadharModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "IDProofApplicant",
            });
          }
        });
      $("#HUFAadharModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadHUFAadhar = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_HUFAadhar").attr("disabled", true);
      $("#upload_btn20_HUFAadhar").hide();
      $("#upload_btnloader_HUFAadhar").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  HUFAadharDocLink: res.data.data.IDProofApplicant,
                },
                () => $("#viewDocLink_HUFAadhar").css("display", "block")
              );
              HUFAadharStatus = "remove";
              HUFStatusArray[0].status = HUFAadharStatus;
              this.checkDropdownValue();
              this.checkhandleHUFProceedBtn();
              $("#HUFAadharModal").modal("hide");
              $("#cancelbtn_HUFAadhar").attr("disabled", false);
              $("#upload_btn20_HUFAadhar").show();
              $("#upload_btnloader_HUFAadhar").hide();
              $("#choose_HUFAadhar").hide();
              $("#X-btn_HUFAadhar").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_HUFAadhar").attr("disabled", false);
            $("#upload_btn20_HUFAadhar").show();
            $("#upload_btnloader_HUFAadhar").hide();
            $("#HUFAadharModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_HUFAadhar").attr("disabled", false);
            $("#upload_btn20_HUFAadhar").show();
            $("#upload_btnloader_HUFAadhar").hide();
            $("#HUFAadharModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_HUFAadhar").attr("disabled", false);
          $("#upload_btn20_HUFAadhar").show();
          $("#upload_btnloader_HUFAadhar").hide();
          $("#HUFAadharModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeHUFAadhar = () => {
    $("#X-btn_HUFAadhar").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "IDProofApplicant",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          HUFAadharStatus = res.data.data.status;
          HUFStatusArray[0].status = HUFAadharStatus;
          this.checkDropdownValue();
          this.checkhandleHUFProceedBtn();
          $("#choose_HUFAadhar").show();
          $("#X-btn_HUFAadhar").hide();
          $("#X-btn_HUFAadhar").attr("disabled", false);
          $("#viewDocLink_HUFAadhar").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_HUFAadhar").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_HUFAadhar").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleHUFAadharseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFAadharseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "IDProofApplicant",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#HUFAadharseecommentsbtn").hide();
          $("#HUFAadharhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFAadharhidecommentsbtn = () => {
    $("#HUFAadharseecommentsbtn").show();
    $("#HUFAadharseecommentsbtn").attr("disabled", false);
    $("#HUFAadharhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // HUFPan1

  // HUFPic
  handleHUFPicChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "ApplicantPhoto",
          },
          () => {
            $("#HUFPicModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "ApplicantPhoto",
            });
          }
        });
      $("#HUFPicModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadHUFPic = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_HUFPic").attr("disabled", true);
      $("#upload_btn20_HUFPic").hide();
      $("#upload_btnloader_HUFPic").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  HUFPicDocLink: res.data.data.ApplicantPhoto,
                },
                () => $("#viewDocLink_HUFPic").css("display", "block")
              );
              HUFPicStatus = "remove";
              HUFStatusArray[1].status = HUFPicStatus;
              this.checkDropdownValue();
              this.checkhandleHUFProceedBtn();
              $("#HUFPicModal").modal("hide");
              $("#cancelbtn_HUFPic").attr("disabled", false);
              $("#upload_btn20_HUFPic").show();
              $("#upload_btnloader_HUFPic").hide();
              $("#choose_HUFPic").hide();
              $("#X-btn_HUFPic").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_HUFPic").attr("disabled", false);
            $("#upload_btn20_HUFPic").show();
            $("#upload_btnloader_HUFPic").hide();
            $("#HUFPicModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_HUFPic").attr("disabled", false);
            $("#upload_btn20_HUFPic").show();
            $("#upload_btnloader_HUFPic").hide();
            $("#HUFPicModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_HUFPic").attr("disabled", false);
          $("#upload_btn20_HUFPic").show();
          $("#upload_btnloader_HUFPic").hide();
          $("#HUFPicModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeHUFPic = () => {
    $("#X-btn_HUFPic").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "ApplicantPhoto",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          HUFPicStatus = res.data.data.status;
          HUFStatusArray[1].status = HUFPicStatus;
          this.checkDropdownValue();
          this.checkhandleHUFProceedBtn();
          $("#choose_HUFPic").show();
          $("#X-btn_HUFPic").hide();
          $("#X-btn_HUFPic").attr("disabled", false);
          $("#viewDocLink_HUFPic").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_HUFPic").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_HUFPic").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleHUFPicseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFPicseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ApplicantPhoto",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#HUFPicseecommentsbtn").hide();
          $("#HUFPichidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFPichidecommentsbtn = () => {
    $("#HUFPicseecommentsbtn").show();
    $("#HUFPicseecommentsbtn").attr("disabled", false);
    $("#HUFPichidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // HUFPan2

  // HUFDeed
  handleHUFDeedChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "HUFDeedDocument",
          },
          () => {
            $("#HUFDeedModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "HUFDeedDocument",
            });
          }
        });
      $("#HUFDeedModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadHUFDeed = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_HUFDeed").attr("disabled", true);
      $("#upload_btn20_HUFDeed").hide();
      $("#upload_btnloader_HUFDeed").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  HUFDeedDocLink: res.data.data.HUFDeedDocument,
                },
                () => $("#viewDocLink_HUFDeed").css("display", "block")
              );
              HUFDeedStatus = "remove";
              HUFStatusArray[2].status = HUFDeedStatus;
              this.checkDropdownValue();
              this.checkhandleHUFProceedBtn();
              $("#HUFDeedModal").modal("hide");
              $("#cancelbtn_HUFDeed").attr("disabled", false);
              $("#upload_btn20_HUFDeed").show();
              $("#upload_btnloader_HUFDeed").hide();
              $("#choose_HUFDeed").hide();
              $("#X-btn_HUFDeed").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_HUFDeed").attr("disabled", false);
            $("#upload_btn20_HUFDeed").show();
            $("#upload_btnloader_HUFDeed").hide();
            $("#HUFDeedModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_HUFDeed").attr("disabled", false);
            $("#upload_btn20_HUFDeed").show();
            $("#upload_btnloader_HUFDeed").hide();
            $("#HUFDeedModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_HUFDeed").attr("disabled", false);
          $("#upload_btn20_HUFDeed").show();
          $("#upload_btnloader_HUFDeed").hide();
          $("#HUFDeedModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeHUFDeed = () => {
    $("#X-btn_HUFDeed").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "HUFDeedDocument",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          HUFDeedStatus = res.data.data.status;
          HUFStatusArray[2].status = HUFDeedStatus;
          this.checkDropdownValue();
          this.checkhandleHUFProceedBtn();
          $("#choose_HUFDeed").show();
          $("#X-btn_HUFDeed").hide();
          $("#X-btn_HUFDeed").attr("disabled", false);
          $("#viewDocLink_HUFDeed").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_HUFDeed").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_HUFDeed").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleHUFDeedseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFDeedseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "HUFDeedDocument",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#HUFDeedseecommentsbtn").hide();
          $("#HUFDeedhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFDeedhidecommentsbtn = () => {
    $("#HUFDeedseecommentsbtn").show();
    $("#HUFDeedseecommentsbtn").attr("disabled", false);
    $("#HUFDeedhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // HUFBP

  // HUFGumasta

  // HUFPUnit
  handleHUFPUnitChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "ProductionUnitPhoto",
          },
          () => {
            $("#HUFPUnitModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "ProductionUnitPhoto",
            });
          }
        });
      $("#HUFPUnitModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadHUFPUnit = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_HUFPUnit").attr("disabled", true);
      $("#upload_btn20_HUFPUnit").hide();
      $("#upload_btnloader_HUFPUnit").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  HUFPUnitDocLink: res.data.data.ProductionUnitPhoto,
                },
                () => $("#viewDocLink_HUFPUnit").css("display", "block")
              );
              HUFPUnitStatus = "remove";
              HUFStatusArray[3].status = HUFPUnitStatus;
              this.checkDropdownValue();
              this.checkhandleHUFroceedBtn();
              $("#HUFPUnitModal").modal("hide");
              $("#cancelbtn_HUFPUnit").attr("disabled", false);
              $("#upload_btn20_HUFPUnit").show();
              $("#upload_btnloader_HUFPUnit").hide();
              $("#choose_HUFPUnit").hide();
              $("#X-btn_HUFPUnit").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_HUFPUnit").attr("disabled", false);
            $("#upload_btn20_HUFPUnit").show();
            $("#upload_btnloader_HUFPUnit").hide();
            $("#HUFPUnitModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_HUFPUnit").attr("disabled", false);
            $("#upload_btn20_HUFPUnit").show();
            $("#upload_btnloader_HUFPUnit").hide();
            $("#HUFPUnitModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_HUFPUnit").attr("disabled", false);
          $("#upload_btn20_HUFPUnit").show();
          $("#upload_btnloader_HUFPUnit").hide();
          $("#HUFPUnitModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeHUFPUnit = () => {
    $("#X-btn_HUFPUnit").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "ProductionUnitPhoto",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          HUFPUnitStatus = res.data.data.status;
          HUFStatusArray[3].status = HUFPUnitStatus;
          this.checkDropdownValue();
          this.checkhandleHUFroceedBtn();
          $("#choose_HUFPUnit").show();
          $("#X-btn_HUFPUnit").hide();
          $("#X-btn_HUFPUnit").attr("disabled", false);
          $("#viewDocLink_HUFPUnit").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_HUFPUnit").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_HUFPUnit").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleHUFPUnitseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFPUnitseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ProductionUnitPhoto",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#HUFPUnitseecommentsbtn").hide();
          $("#HUFPUnithidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFPUnithidecommentsbtn = () => {
    $("#HUFPUnitseecommentsbtn").show();
    $("#HUFPUnitseecommentsbtn").attr("disabled", false);
    $("#HUFPUnithidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // HUFLayoutPlan
  handleHUFLayoutPlanChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "LayoutPlanUnit",
          },
          () => {
            $("#HUFLayoutPlanModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "LayoutPlanUnit",
            });
          }
        });
      $("#HUFLayoutPlanModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadHUFLayoutPlan = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_HUFLayoutPlan").attr("disabled", true);
      $("#upload_btn20_HUFLayoutPlan").hide();
      $("#upload_btnloader_HUFLayoutPlan").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  HUFLayoutPlanDocLink: res.data.data.LayoutPlanUnit,
                },
                () => $("#viewDocLink_HUFLayoutPlan").css("display", "block")
              );
              HUFLayoutPlanStatus = "remove";
              HUFStatusArray[4].status = HUFLayoutPlanStatus;
              this.checkDropdownValue();
              this.checkhandleHUFroceedBtn();
              $("#HUFLayoutPlanModal").modal("hide");
              $("#cancelbtn_HUFLayoutPlan").attr("disabled", false);
              $("#upload_btn20_HUFLayoutPlan").show();
              $("#upload_btnloader_HUFLayoutPlan").hide();
              $("#choose_HUFLayoutPlan").hide();
              $("#X-btn_HUFLayoutPlan").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_HUFLayoutPlan").attr("disabled", false);
            $("#upload_btn20_HUFLayoutPlan").show();
            $("#upload_btnloader_HUFLayoutPlan").hide();
            $("#HUFLayoutPlanModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_HUFLayoutPlan").attr("disabled", false);
            $("#upload_btn20_HUFLayoutPlan").show();
            $("#upload_btnloader_HUFLayoutPlan").hide();
            $("#HUFLayoutPlanModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_HUFLayoutPlan").attr("disabled", false);
          $("#upload_btn20_HUFLayoutPlan").show();
          $("#upload_btnloader_HUFLayoutPlan").hide();
          $("#HUFLayoutPlanModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeHUFLayoutPlan = () => {
    $("#X-btn_HUFLayoutPlan").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "LayoutPlanUnit",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          HUFLayoutPlanStatus = res.data.data.status;
          HUFStatusArray[4].status = HUFLayoutPlanStatus;
          this.checkDropdownValue();
          this.checkhandleHUFroceedBtn();
          $("#choose_HUFLayoutPlan").show();
          $("#X-btn_HUFLayoutPlan").hide();
          $("#X-btn_HUFLayoutPlan").attr("disabled", false);
          $("#viewDocLink_HUFLayoutPlan").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_HUFLayoutPlan").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_HUFLayoutPlan").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleHUFLayoutPlanseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFLayoutPlanseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "LayoutPlanUnit",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#HUFLayoutPlanseecommentsbtn").hide();
          $("#HUFLayoutPlanhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFLayoutPlanhidecommentsbtn = () => {
    $("#HUFLayoutPlanseecommentsbtn").show();
    $("#HUFLayoutPlanseecommentsbtn").attr("disabled", false);
    $("#HUFLayoutPlanhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // HUFListofEquip
  handleHUFListofEquipChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "ListOfEquipment",
          },
          () => {
            $("#HUFListofEquipModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "ListOfEquipment",
            });
          }
        });
      $("#HUFListofEquipModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadHUFListofEquip = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_HUFListofEquip").attr("disabled", true);
      $("#upload_btn20_HUFListofEquip").hide();
      $("#upload_btnloader_HUFListofEquip").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  HUFListofEquipDocLink: res.data.data.ListOfEquipment,
                },
                () => $("#viewDocLink_HUFListofEquip").css("display", "block")
              );
              HUFListofEquipStatus = "remove";
              HUFStatusArray[5].status = HUFListofEquipStatus;
              this.checkDropdownValue();
              this.checkhandleHUFProceedBtn();
              $("#HUFListofEquipModal").modal("hide");
              $("#cancelbtn_HUFListofEquip").attr("disabled", false);
              $("#upload_btn20_HUFListofEquip").show();
              $("#upload_btnloader_HUFListofEquip").hide();
              $("#choose_HUFListofEquip").hide();
              $("#X-btn_HUFListofEquip").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_HUFListofEquip").attr("disabled", false);
            $("#upload_btn20_HUFListofEquip").show();
            $("#upload_btnloader_HUFListofEquip").hide();
            $("#HUFListofEquipModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_HUFListofEquip").attr("disabled", false);
            $("#upload_btn20_HUFListofEquip").show();
            $("#upload_btnloader_HUFListofEquip").hide();
            $("#HUFListofEquipModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_HUFListofEquip").attr("disabled", false);
          $("#upload_btn20_HUFListofEquip").show();
          $("#upload_btnloader_HUFListofEquip").hide();
          $("#HUFListofEquipModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeHUFListofEquip = () => {
    $("#X-btn_HUFListofEquip").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "ListOfEquipment",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          HUFListofEquipStatus = res.data.data.status;
          HUFStatusArray[5].status = HUFListofEquipStatus;
          this.checkDropdownValue();
          this.checkhandleHUFProceedBtn();
          $("#choose_HUFListofEquip").show();
          $("#X-btn_HUFListofEquip").hide();
          $("#X-btn_HUFListofEquip").attr("disabled", false);
          $("#viewDocLink_HUFListofEquip").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_HUFListofEquip").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_HUFListofEquip").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleHUFListofEquipseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFListofEquipseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ListOfEquipment",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#HUFListofEquipseecommentsbtn").hide();
          $("#HUFListofEquiphidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFListofEquiphidecommentsbtn = () => {
    $("#HUFListofEquipseecommentsbtn").show();
    $("#HUFListofEquipseecommentsbtn").attr("disabled", false);
    $("#HUFListofEquiphidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // HUFWAReport
  handleHUFWAReportChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "WaterAnalysisReport",
          },
          () => {
            $("#HUFWAReportModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "WaterAnalysisReport",
            });
          }
        });
      $("#HUFWAReportModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadHUFWAReport = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_HUFWAReport").attr("disabled", true);
      $("#upload_btn20_HUFWAReport").hide();
      $("#upload_btnloader_HUFWAReport").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  HUFWAReportDocLink: res.data.data.WaterAnalysisReport,
                },
                () => $("#viewDocLink_HUFWAReport").css("display", "block")
              );
              HUFWAReportStatus = "remove";
              HUFStatusArray[6].status = HUFWAReportStatus;
              this.checkDropdownValue();
              this.checkhandleHUFProceedBtn();
              $("#HUFWAReportModal").modal("hide");
              $("#cancelbtn_HUFWAReport").attr("disabled", false);
              $("#upload_btn20_HUFWAReport").show();
              $("#upload_btnloader_HUFWAReport").hide();
              $("#choose_HUFWAReport").hide();
              $("#X-btn_HUFWAReport").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_HUFWAReport").attr("disabled", false);
            $("#upload_btn20_HUFWAReport").show();
            $("#upload_btnloader_HUFWAReport").hide();
            $("#HUFWAReportModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_HUFWAReport").attr("disabled", false);
            $("#upload_btn20_HUFWAReport").show();
            $("#upload_btnloader_HUFWAReport").hide();
            $("#HUFWAReportModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_HUFWAReport").attr("disabled", false);
          $("#upload_btn20_HUFWAReport").show();
          $("#upload_btnloader_HUFWAReport").hide();
          $("#HUFWAReportModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeHUFWAReport = () => {
    $("#X-btn_HUFWAReport").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "WaterAnalysisReport",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          HUFWAReportStatus = res.data.data.status;
          HUFStatusArray[6].status = HUFWAReportStatus;
          this.checkDropdownValue();
          this.checkhandleHUFProceedBtn();
          $("#choose_HUFWAReport").show();
          $("#X-btn_HUFWAReport").hide();
          $("#X-btn_HUFWAReport").attr("disabled", false);
          $("#viewDocLink_HUFWAReport").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_HUFWAReport").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_HUFWAReport").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleHUFWAReportseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFWAReportseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "WaterAnalysisReport",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#HUFWAReportseecommentsbtn").hide();
          $("#HUFWAReporthidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFWAReporthidecommentsbtn = () => {
    $("#HUFWAReportseecommentsbtn").show();
    $("#HUFWAReportseecommentsbtn").attr("disabled", false);
    $("#HUFWAReporthidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // HUFListofDir
  handleHUFListofDirChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "DirectorsFullAddress",
          },
          () => {
            $("#HUFListofDirModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "DirectorsFullAddress",
            });
          }
        });
      $("#HUFListofDirModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadHUFListofDir = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_HUFListofDir").attr("disabled", true);
      $("#upload_btn20_HUFListofDir").hide();
      $("#upload_btnloader_HUFListofDir").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  HUFListofDirDocLink: res.data.data.DirectorsFullAddress,
                },
                () => $("#viewDocLink_HUFListofDir").css("display", "block")
              );
              HUFListofDirStatus = "remove";
              HUFStatusArray[7].status = HUFListofDirStatus;
              this.checkDropdownValue();
              this.checkhandleHUFProceedBtn();
              $("#HUFListofDirModal").modal("hide");
              $("#cancelbtn_HUFListofDir").attr("disabled", false);
              $("#upload_btn20_HUFListofDir").show();
              $("#upload_btnloader_HUFListofDir").hide();
              $("#choose_HUFListofDir").hide();
              $("#X-btn_HUFListofDir").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_HUFListofDir").attr("disabled", false);
            $("#upload_btn20_HUFListofDir").show();
            $("#upload_btnloader_HUFListofDir").hide();
            $("#HUFListofDirModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_HUFListofDir").attr("disabled", false);
            $("#upload_btn20_HUFListofDir").show();
            $("#upload_btnloader_HUFListofDir").hide();
            $("#HUFListofDirModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_HUFListofDir").attr("disabled", false);
          $("#upload_btn20_HUFListofDir").show();
          $("#upload_btnloader_HUFListofDir").hide();
          $("#HUFListofDirModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeHUFListofDir = () => {
    $("#X-btn_HUFListofDir").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "DirectorsFullAddress",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          HUFListofDirStatus = res.data.data.status;
          HUFStatusArray[7].status = HUFListofDirStatus;
          this.checkDropdownValue();
          this.checkhandleHUFProceedBtn();
          $("#choose_HUFListofDir").show();
          $("#X-btn_HUFListofDir").hide();
          $("#X-btn_HUFListofDir").attr("disabled", false);
          $("#viewDocLink_HUFListofDir").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_HUFListofDir").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_HUFListofDir").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleHUFListofDirseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFListofDirseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "DirectorsFullAddress",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#HUFListofDirseecommentsbtn").hide();
          $("#HUFListofDirhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFListofDirhidecommentsbtn = () => {
    $("#HUFListofDirseecommentsbtn").show();
    $("#HUFListofDirseecommentsbtn").attr("disabled", false);
    $("#HUFListofDirhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // HUFRecallPlan
  handleHUFRecallPlanChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "RecallPlan",
          },
          () => {
            $("#HUFRecallPlanModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "RecallPlan",
            });
          }
        });
      $("#HUFRecallPlanModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadHUFRecallPlan = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_HUFRecallPlan").attr("disabled", true);
      $("#upload_btn20_HUFRecallPlan").hide();
      $("#upload_btnloader_HUFRecallPlan").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  HUFRecallPlanDocLink: res.data.data.RecallPlan,
                },
                () => $("#viewDocLink_HUFRecallPlan").css("display", "block")
              );
              HUFRecallPlanStatus = "remove";
              HUFStatusArray[8].status = HUFRecallPlanStatus;
              this.checkDropdownValue();
              this.checkhandleHUFProceedBtn();
              $("#HUFRecallPlanModal").modal("hide");
              $("#cancelbtn_HUFRecallPlan").attr("disabled", false);
              $("#upload_btn20_HUFRecallPlan").show();
              $("#upload_btnloader_HUFRecallPlan").hide();
              $("#choose_HUFRecallPlan").hide();
              $("#X-btn_HUFRecallPlan").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_HUFRecallPlan").attr("disabled", false);
            $("#upload_btn20_HUFRecallPlan").show();
            $("#upload_btnloader_HUFRecallPlan").hide();
            $("#HUFRecallPlanModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_HUFRecallPlan").attr("disabled", false);
            $("#upload_btn20_HUFRecallPlan").show();
            $("#upload_btnloader_HUFRecallPlan").hide();
            $("#HUFRecallPlanModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_HUFRecallPlan").attr("disabled", false);
          $("#upload_btn20_HUFRecallPlan").show();
          $("#upload_btnloader_HUFRecallPlan").hide();
          $("#HUFRecallPlanModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeHUFRecallPlan = () => {
    $("#X-btn_HUFRecallPlan").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "RecallPlan",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          HUFRecallPlanStatus = res.data.data.status;
          HUFStatusArray[8].status = HUFRecallPlanStatus;
          this.checkDropdownValue();
          this.checkhandleHUFProceedBtn();
          $("#choose_HUFRecallPlan").show();
          $("#X-btn_HUFRecallPlan").hide();
          $("#X-btn_HUFRecallPlan").attr("disabled", false);
          $("#viewDocLink_HUFRecallPlan").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_HUFRecallPlan").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_HUFRecallPlan").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleHUFRecallPlanseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFRecallPlanseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "RecallPlan",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#HUFRecallPlanseecommentsbtn").hide();
          $("#HUFRecallPlanhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFRecallPlanhidecommentsbtn = () => {
    $("#HUFRecallPlanseecommentsbtn").show();
    $("#HUFRecallPlanseecommentsbtn").attr("disabled", false);
    $("#HUFRecallPlanhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // HUFTradeLicence
  handleHUFbusinessNatureChange = (e) => {
    this.setState({
      HUFbusinessNature: e.target.value,
    });
  };

  // HUFAddressProofType
  HUFAddressProofType_radioChange = (e) => {
    this.setState(
      {
        HUFAddressProofType_radio: e.currentTarget.value,
      },
      () => {
        if (this.state.HUFAddressProofType_radio != "CopyOfRegistry") {
          $(".RentAgreement").css("display", "flex");
          $(".CopyOfRegistry").hide();
        } else if (this.state.HUFAddressProofType_radio == "CopyOfRegistry") {
          $(".CopyOfRegistry").css("display", "flex");
          $(".RentAgreement").hide();
        }
        this.checkhandleHUFProceedBtn();
      }
    );
  };
  // HUFCopyOfRegistry
  handleHUFCopyOfRegistryChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "CopyOfRegistry",
          },
          () => {
            $("#HUFCopyOfRegistryModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "CopyOfRegistry",
            });
          }
        });
      $("#HUFCopyOfRegistryModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadHUFCopyOfRegistry = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_HUFCopyOfRegistry").attr("disabled", true);
      $("#upload_btn20_HUFCopyOfRegistry").hide();
      $("#upload_btnloader_HUFCopyOfRegistry").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  HUFCopyOfRegistryDocLink: res.data.data.CopyOfRegistry,
                },
                () =>
                  $("#viewDocLink_HUFCopyOfRegistry").css("display", "block")
              );
              HUFCopyOfRegistryStatus = "remove";
              HUFOptStatusArray[0].status = HUFCopyOfRegistryStatus;
              this.checkDropdownValue();
              this.checkhandleHUFProceedBtn();
              $("#HUFCopyOfRegistryModal").modal("hide");
              $("#cancelbtn_HUFCopyOfRegistry").attr("disabled", false);
              $("#upload_btn20_HUFCopyOfRegistry").show();
              $("#upload_btnloader_HUFCopyOfRegistry").hide();
              $("#choose_HUFCopyOfRegistry").hide();
              $("#X-btn_HUFCopyOfRegistry").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_HUFCopyOfRegistry").attr("disabled", false);
            $("#upload_btn20_HUFCopyOfRegistry").show();
            $("#upload_btnloader_HUFCopyOfRegistry").hide();
            $("#HUFCopyOfRegistryModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_HUFCopyOfRegistry").attr("disabled", false);
            $("#upload_btn20_HUFCopyOfRegistry").show();
            $("#upload_btnloader_HUFCopyOfRegistry").hide();
            $("#HUFCopyOfRegistryModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_HUFCopyOfRegistry").attr("disabled", false);
          $("#upload_btn20_HUFCopyOfRegistry").show();
          $("#upload_btnloader_HUFCopyOfRegistry").hide();
          $("#HUFCopyOfRegistryModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeHUFCopyOfRegistry = () => {
    $("#X-btn_HUFCopyOfRegistry").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "CopyOfRegistry",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          HUFCopyOfRegistryStatus = res.data.data.status;
          HUFOptStatusArray[0].status = HUFCopyOfRegistryStatus;
          this.checkDropdownValue();
          this.checkhandleHUFProceedBtn();
          $("#choose_HUFCopyOfRegistry").show();
          $("#X-btn_HUFCopyOfRegistry").hide();
          $("#X-btn_HUFCopyOfRegistry").attr("disabled", false);
          $("#viewDocLink_HUFCopyOfRegistry").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_HUFCopyOfRegistry").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_HUFCopyOfRegistry").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleHUFCopyOfRegistryseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFCopyOfRegistryseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "CopyOfRegistry",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#HUFCopyOfRegistryseecommentsbtn").hide();
          $("#HUFCopyOfRegistryhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFCopyOfRegistryhidecommentsbtn = () => {
    $("#HUFCopyOfRegistryseecommentsbtn").show();
    $("#HUFCopyOfRegistryseecommentsbtn").attr("disabled", false);
    $("#HUFCopyOfRegistryhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // HUFRentAgreement
  handleHUFRentAgreementChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "RentAgreement",
          },
          () => {
            $("#HUFRentAgreementModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "RentAgreement",
            });
          }
        });
      $("#HUFRentAgreementModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadHUFRentAgreement = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_HUFRentAgreement").attr("disabled", true);
      $("#upload_btn20_HUFRentAgreement").hide();
      $("#upload_btnloader_HUFRentAgreement").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  HUFRentAgreementDocLink: res.data.data.RentAgreement,
                },
                () => $("#viewDocLink_HUFRentAgreement").css("display", "block")
              );
              HUFRentAgreementStatus = "remove";
              HUFOptStatusArray[1].status = HUFRentAgreementStatus;
              this.checkDropdownValue();
              this.checkhandleHUFProceedBtn();
              $("#HUFRentAgreementModal").modal("hide");
              $("#cancelbtn_HUFRentAgreement").attr("disabled", false);
              $("#upload_btn20_HUFRentAgreement").show();
              $("#upload_btnloader_HUFRentAgreement").hide();
              $("#choose_HUFRentAgreement").hide();
              $("#X-btn_HUFRentAgreement").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_HUFRentAgreement").attr("disabled", false);
            $("#upload_btn20_HUFRentAgreement").show();
            $("#upload_btnloader_HUFRentAgreement").hide();
            $("#HUFRentAgreementModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_HUFRentAgreement").attr("disabled", false);
            $("#upload_btn20_HUFRentAgreement").show();
            $("#upload_btnloader_HUFRentAgreement").hide();
            $("#HUFRentAgreementModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_HUFRentAgreement").attr("disabled", false);
          $("#upload_btn20_HUFRentAgreement").show();
          $("#upload_btnloader_HUFRentAgreement").hide();
          $("#HUFRentAgreementModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeHUFRentAgreement = () => {
    $("#X-btn_HUFRentAgreement").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "RentAgreement",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          HUFRentAgreementStatus = res.data.data.status;
          HUFOptStatusArray[1].status = HUFRentAgreementStatus;
          this.checkDropdownValue();
          this.checkhandleHUFProceedBtn();
          $("#choose_HUFRentAgreement").show();
          $("#X-btn_HUFRentAgreement").hide();
          $("#X-btn_HUFRentAgreement").attr("disabled", false);
          $("#viewDocLink_HUFRentAgreement").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          $("#X-btn_HUFRentAgreement").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_HUFRentAgreement").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleHUFRentAgreementseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFRentAgreementseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "RentAgreement",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#HUFRentAgreementseecommentsbtn").hide();
          $("#HUFRentAgreementhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFRentAgreementhidecommentsbtn = () => {
    $("#HUFRentAgreementseecommentsbtn").show();
    $("#HUFRentAgreementseecommentsbtn").attr("disabled", false);
    $("#HUFRentAgreementhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // HUFPR

  // HUFAdditional
  handleHUFAddFileChange = (e, index, status) => {
    HUFadditionalReuploadIndex = index;
    HUFadditionalReuploadStatus = status;
    $(".upload_btn20_7").show();
    $(".upload_btnloader_7").hide();
    const file = e.target.files[0];
    if (!HUFadditionalFilesNames.includes(this.state.HUFAdd_DocName.trim())) {
      const myfile = e.target.files;
      if (ChooseNowBtnsDisable == "Yes") {
        this.showNotification(
          "Files cannot be uploaded when the service is locked!"
        );
      } else if (myfile.length == 1) {
        const file = myfile[0];
        if (file.type.match("audio.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.type.match("video.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.size > 104857600) {
          this.showNotification("File too big!");
        } else if (file.name.length > 100) {
          this.showNotification("File name limit exceed!");
        } else {
          this.setState(
            {
              fileName: file.name,
              fileBase64: file,
              documentType: "additional",
              documentName: this.state.HUFAdd_DocName,
            },
            () => {
              $(".HUFAddModal").modal({
                backdrop: "static",
                keyboard: false,
              });
            }
          );
        }
      } else if (myfile.length > 1) {
        var zip = new JSZip();
        for (let i = 0; i < myfile.length; i++) {
          zip.file(myfile[i].name, myfile[i], {
            base64: true,
          });
        }
        zip
          .generateAsync({
            type: "blob",
          })
          .then((content) => {
            if (content.size > 104857600) {
              this.showNotification("File too big!");
            } else {
              this.setState({
                fileName: "download.zip",
                fileBase64: content,
                documentType: "additional",
                documentName: this.state.HUFAdd_DocName,
              });
            }
          });
        $(".HUFAddModal").modal({
          backdrop: "static",
          keyboard: false,
        });
      } else {
        this.showNotification("File cannot be null!");
      }
    } else {
      this.showNotification(
        "Document names can not be the same, Try again with different name!"
      );
    }
  };

  uploadHUFFile = (FileIndex, FileStatus) => {
    let AdditionaldocumentName1;
    if (FileStatus == "reupload") {
      $(".proceednowbtn").attr("disabled", true);
      let fileTobeUploaded = this.state.HUFadditionalFiles[FileIndex];
      AdditionaldocumentName1 = Object.keys(fileTobeUploaded)[0];
      mainaction = "reupload";
    } else {
      AdditionaldocumentName1 = this.state.documentName;
      mainaction = "upload";
    }
    if (AdditionaldocumentName1.trim() == "") {
      $(".HUFAddModal").modal("hide");
      this.showNotification("Enter document Name first");
    } else if (this.state.comment.length <= 250) {
      $(".cancelbtn_7").attr("disabled", true);
      $(".upload_btn20_7").hide();
      $(".upload_btnloader_7").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", AdditionaldocumentName1);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            $(".HUFAddModal").modal("hide");
            $(".cancelbtn_7").attr("disabled", false);
            $(`#${FileIndex}HUFFileseecommentsbtn`).attr("disabled", false);
            if (HUFadditionalReupload == false) {
              HUFadditionalFilesNames.push(this.state.HUFAdd_DocName);
              this.state.HUFadditionalFiles.pop();
              this.setState(
                {
                  comment: "",
                  HUFadditionalFiles: [
                    ...this.state.HUFadditionalFiles,
                    res.data.data,
                  ],
                },
                () => {
                  this.setState(
                    {
                      api_HUFadditionalFiles: "Yes",
                    },
                    () => {
                      this.HUFadditionalFilesArea();
                      this.checkDropdownValue();
                      this.checkhandleHUFProceedBtn();
                    }
                  );

                  $(".HUFadditionalInput").attr("disabled", true);
                }
              );
            } else if (HUFadditionalReupload == true) {
              $(".proceednowbtn").attr("disabled", false);
              var array = [...this.state.HUFadditionalFiles];
              array.splice(FileIndex, 1, res.data.data);
              this.setState(
                {
                  comment: "",
                  api_HUFadditionalFiles: "Yes",
                  HUFadditionalFiles: array,
                },
                () => {
                  this.HUFadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleHUFProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".HUFAddModal").modal("hide");
            this.showNotification("Something Went Wrong!");
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".HUFAddModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          this.showNotification("Something went wrong, Try again!");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeHUFFile = (index) => {
    $("#X-btn_7").attr("disabled", true);
    let fileTobeDeleted = this.state.HUFadditionalFiles[index];
    let NameOfAddFile = Object.keys(fileTobeDeleted)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "additional",
          documentName: NameOfAddFile,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          var array = [...this.state.HUFadditionalFiles];
          if (res.data.data.status == "reupload") {
            array[index].status = "reupload";
            this.setState(
              {
                HUFadditionalFiles: array,
              },
              () => {
                this.HUFadditionalFilesArea();
                this.checkDropdownValue();
                this.checkhandleHUFProceedBtn();
              }
            );
            this.showNotification(res.data.message);
          } else {
            array.splice(index, 1);
            if (array.length > 0) {
              this.setState(
                {
                  HUFadditionalFiles: array,
                },
                () => {
                  this.HUFadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleHUFProceedBtn();
                }
              );
            } else if (array.length == 0) {
              this.setState(
                {
                  HUFadditionalFiles: [],
                  HUFAdd_DocName: "",
                  api_HUFadditionalFiles: "No",
                  IsHUFAddFileDisabled: true,
                },
                () => {
                  this.HUFadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleHUFProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_7").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleHUFFileseeComments = (index) => {
    $(".commentsbtn").attr("disabled", false);
    $(`#${index}HUFFileseecommentsbtn`).attr("disabled", true);
    let SelectedFile = this.state.HUFadditionalFiles[index];
    let NameOfAddFile = Object.keys(SelectedFile)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "additional",
          documentName: NameOfAddFile,
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(`#${index}HUFFileseecommentsbtn`).hide();
          $(".hidecommentsbtn").hide();
          $(`#${index}HUFFilehidecommentsbtn`).show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFFilehideComments = (index) => {
    $(".seecommentsbtn").show();
    $(`#${index}HUFFileseecommentsbtn`).show();
    $(`#${index}HUFFileseecommentsbtn`).attr("disabled", false);
    $(`#${index}HUFFilehidecommentsbtn`).hide();
    $(".hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  checkAddCommentHUFFiles = (FileIndex, isDisabled) => {
    if (isDisabled == true) {
      return (
        <>
          <button
            className="seecommentsbtn HUFFileseecommentsbtn"
            id={`${FileIndex}HUFFileseecommentsbtn`}
            disabled
            onClick={() => this.handleHUFFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn HUFFilehidecommentsbtn"
            id={`${FileIndex}HUFFilehidecommentsbtn`}
            onClick={() => this.handleHUFFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    } else if (isDisabled == false) {
      return (
        <>
          <button
            className="seecommentsbtn commentsbtn HUFFileseecommentsbtn"
            id={`${FileIndex}HUFFileseecommentsbtn`}
            onClick={() => this.handleHUFFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn HUFFilehidecommentsbtn"
            id={`${FileIndex}HUFFilehidecommentsbtn`}
            onClick={() => this.handleHUFFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    }
  };

  checkStatusHUFAddFiles = (status, link, index) => {
    HUFadditionalFilesIndex = index;
    if (HideProceedBtn == "Yes") {
      $(".proceednowbtn").hide();
      if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
          </>
        );
      }
    } else {
      if (status == undefined) {
        status = "open";
      }
      if (status == "open") {
        mainaction = "upload";
        HUFadditionalReupload = true;
        $(".proceednowbtn").show();
        return (
          <>
            <label
              htmlFor={`ul_file_HUFAdd${index}`}
              className="choose_btn choose_7"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              multiple
              name="file[]"
              className="upload-photo"
              id={`ul_file_HUFAdd${index}`}
              // disabled={this.state.IsHUFAddFileDisabled}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handleHUFAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "remove") {
        mainaction = "upload";
        $(".proceednowbtn").show();
        return (
          <>
            <button
              className="X-btn X-btn_7"
              onClick={() => this.removeHUFFile(index)}
            >
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_1025.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "reupload") {
        HUFadditionalReupload = true;
        mainaction = "reupload";
        $(".proceednowbtn").show();
        $(".proceednowbtn").attr("disabled", true);
        return (
          <>
            <label
              htmlFor={`ul_file_HUFAdd${index}`}
              className="choose_btn choose_7"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              multiple
              name="file[]"
              className="upload-photo"
              id={`ul_file_HUFAdd${index}`}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handleHUFAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
          </>
        );
      }
    }
  };

  AdditionalHUFFileDisabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero HUFadditionalInput"
        value={NameOfAddFile}
        disabled
        onChange={(e) => this.handleHUFAdd_DocNameChange(e)}
      />
    );
  };

  AdditionalHUFFileEnabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero HUFadditionalInput"
        value={NameOfAddFile}
        onChange={(e) => this.handleHUFAdd_DocNameChange(e)}
      />
    );
  };

  HUFadditionalFilesArea = () => {
    HUFadditionalFilesNames = [];
    if (this.state.api_HUFadditionalFiles == "Yes") {
      return this.state.HUFadditionalFiles.map((file, index) => {
        let NameOfAddFile = Object.keys(file)[0];
        HUFadditionalFilesNames.push(NameOfAddFile);
        let link = file[Object.keys(file)[0]];
        let AdditionalFileInputField;
        let commentDisabled;
        if (file.status == "open" || file.status == undefined) {
          AdditionalFileInputField =
            this.AdditionalHUFFileEnabledField(NameOfAddFile);
          commentDisabled = true;
        } else if (file.status != "open") {
          AdditionalFileInputField =
            this.AdditionalHUFFileDisabledField(NameOfAddFile);
          commentDisabled = false;
        }
        return (
          <div className="displayflex" key={index}>
            <div className="uploadnocol">
              <p className="uploadadditionalsidetext">{index + 1}&#41;</p>
            </div>
            <div className="uploadadditionaldatacol">
              <div className="row mb-2" key={index}>
                <div className="col-md-5">{AdditionalFileInputField}</div>
                <div className="col-md-7">
                  <div className="float-md-right float-left">
                    {this.checkAddCommentHUFFiles(index, commentDisabled)}
                    {this.checkStatusHUFAddFiles(file.status, link, index)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.api_HUFadditionalFiles == "No") {
      return (
        <div className="displayflex">
          <div className="uploadnocol">
            <p className="uploadadditionalsidetext">1&#41;</p>
          </div>
          <div className="uploadadditionaldatacol">
            <div className="row mb-2">
              <div className="col-md-5">
                <input
                  type="text"
                  placeholder="Document name..."
                  maxLength="150"
                  className="uploadtextfield marginzero"
                  value={this.state.HUFAdd_DocName}
                  onChange={(e) => this.handleHUFAdd_DocNameChange(e)}
                />
              </div>
              <div className="col-md-7">
                <div className="fl_right">
                  {this.checkAddCommentHUFFiles(0, true)}
                  {this.checkStatusHUFAddFiles("open", null, 0)}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  handleHUFAdd_DocNameChange = (e) => {
    this.setState(
      {
        HUFAdd_DocName: e.target.value,
      },
      () => {
        if (this.state.HUFAdd_DocName.trim().length > 0) {
          this.setState({
            IsHUFAddFileDisabled: false,
          });
        } else {
          this.setState({
            IsHUFAddFileDisabled: true,
          });
        }
      }
    );
  };

  handleAddHUFFileBtn = (FileIndex) => {
    const { HUFAdd_DocName, HUFadditionalFiles } = this.state;

    let prevFile = HUFadditionalFiles[FileIndex];
    let NameOfPrevFile = Object.keys(prevFile)[0];
    let valueOffile = prevFile[Object.keys(prevFile)[0]];

    if (FileIndex == undefined) {
      this.showNotification("empty fields");
    } else if (HUFAdd_DocName.trim() == "") {
      this.showNotification("Required fields cannot be empty!");
    } else if (valueOffile == null) {
      this.showNotification("Choose a file");
    } else {
      this.setState(
        {
          HUFadditionalFiles: [...this.state.HUFadditionalFiles, ""],
          HUFAdd_DocName: "",
          IsHUFAddFileDisabled: true,
        },
        () => {
          $(".HUFadditionalInput").attr("disabled", false);
        }
      );
    }
  };

  // Proceed Checks
  HUFcheckRemainingReuploads = () => {
    if (this.state.licenseType == "T") {
      if (this.state.HUFAddressProofType_radio == "CopyOfRegistry") {
        if (
          HUFAadharStatus != "reupload" &&
          HUFPicStatus != "reupload" &&
          HUFDeedStatus != "reupload" &&
          HUFCopyOfRegistryStatus != "reupload"
        ) {
          AnyReuploadOccuredHUF = "No";
        } else {
          $(".proceednowbtn4").show();
          AnyReuploadOccuredHUF = "Yes";
        }
      } else {
        if (
          HUFAadharStatus != "reupload" &&
          HUFPicStatus != "reupload" &&
          HUFDeedStatus != "reupload" &&
          HUFRentAgreementStatus != "reupload"
        ) {
          AnyReuploadOccuredHUF = "No";
        } else {
          $(".proceednowbtn4").show();
          AnyReuploadOccuredHUF = "Yes";
        }
      }
    } else {
      if (this.state.HUFAddressProofType_radio == "CopyOfRegistry") {
        if (
          HUFAadharStatus != "reupload" &&
          HUFPicStatus != "reupload" &&
          HUFDeedStatus != "reupload" &&
          HUFPUnitStatus != "reupload" &&
          HUFLayoutPlanStatus != "reupload" &&
          HUFListofEquipStatus != "reupload" &&
          HUFWAReportStatus != "reupload" &&
          HUFListofDirStatus != "reupload" &&
          HUFRecallPlanStatus != "reupload" &&
          HUFCopyOfRegistryStatus != "reupload"
        ) {
          AnyReuploadOccuredHUF = "No";
        } else {
          $(".proceednowbtn4").show();
          AnyReuploadOccuredHUF = "Yes";
        }
      } else {
        if (
          HUFAadharStatus != "reupload" &&
          HUFPicStatus != "reupload" &&
          HUFDeedStatus != "reupload" &&
          HUFPUnitStatus != "reupload" &&
          HUFLayoutPlanStatus != "reupload" &&
          HUFListofEquipStatus != "reupload" &&
          HUFWAReportStatus != "reupload" &&
          HUFListofDirStatus != "reupload" &&
          HUFRecallPlanStatus != "reupload" &&
          HUFRentAgreementStatus != "reupload"
        ) {
          AnyReuploadOccuredHUF = "No";
        } else {
          $(".proceednowbtn4").show();
          AnyReuploadOccuredHUF = "Yes";
        }
      }
    }
  };

  checkhandleHUFProceedBtn = () => {
    let statusCheckReupload = this.statusCheckFunction(
      "reupload",
      HUFStatusArray
    );
    let HUFadditionalStatusArrayCheck = this.statusCheckFunction(
      "reupload",
      this.state.HUFadditionalFiles
    );
    let goodtogo = "No";
    if (this.state.HUFAddressProofType_radio == "CopyOfRegistry") {
      if (
        HUFOptStatusArray[0].status != "reupload" &&
        HUFOptStatusArray[0].status != "upload" &&
        HUFOptStatusArray[0].status != "open"
      ) {
        goodtogo = "Yes";
      } else {
        goodtogo = "No";
      }
    } else if (this.state.HUFAddressProofType_radio == "RentAgreement") {
      if (
        HUFOptStatusArray[1].status != "reupload" &&
        HUFOptStatusArray[1].status != "upload" &&
        HUFOptStatusArray[1].status != "open"
      ) {
        goodtogo = "Yes";
      } else {
        goodtogo = "No";
      }
    } else {
      goodtogo = "No";
    }

    if (
      AnyReuploadOccuredHUF == "Yes" &&
      statusCheckReupload == undefined &&
      goodtogo == "Yes" &&
      HUFadditionalStatusArrayCheck == undefined
    ) {
      $(".proceednowbtn4").attr("disabled", false);
      HUFproceednowforword = "success";
    } else if (
      HUFAadharStatus == "remove" &&
      HUFPicStatus == "remove" &&
      HUFDeedStatus == "remove" &&
      this.state.licenseType == "T" &&
      goodtogo == "Yes"
    ) {
      $(".proceednowbtn4").attr("disabled", false);
      HUFproceednowforword = "success";
    } else if (
      HUFAadharStatus == "remove" &&
      HUFPicStatus == "remove" &&
      HUFDeedStatus == "remove" &&
      HUFPUnitStatus == "remove" &&
      HUFLayoutPlanStatus == "remove" &&
      HUFListofEquipStatus == "remove" &&
      HUFWAReportStatus == "remove" &&
      HUFListofDirStatus == "remove" &&
      HUFRecallPlanStatus == "remove" &&
      this.state.licenseType == "M" &&
      goodtogo == "Yes"
    ) {
      $(".proceednowbtn4").attr("disabled", false);
      HUFproceednowforword = "success";
    } else {
      $(".proceednowbtn4").attr("disabled", true);
      HUFproceednowforword = "fail";
    }
  };

  handleHUFProceedBtn = () => {
    this.checkhandleHUFProceedBtn();
    if (this.state.HUFbusinessNature.trim() == "") {
      this.showNotification("Required fields can not be empty!");
    } else if (this.state.HUFbusinessNature.trim().length > 200) {
      this.showNotification("Nature of your Business / Brand limit exceed!");
    } else if (HUFproceednowforword == "success") {
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=lockService",
          {
            userId: Decuserid,
            requestType: temp,
            agentId: Decagentid,

            serviceId: Decserviceid,
            serviceName: Decservicename,
            serviceType: this.state.serviceType,
            FY: "",
            Month: "",
            inputFields: [
              {
                inputFieldName: "AddressProofType",
                inputFieldValue: this.state.HUFAddressProofType_radio,
                inputFieldtype: "general",
              },
              {
                inputFieldName: "Nature",
                inputFieldValue: this.state.HUFbusinessNature,
                inputFieldtype: "general",
              },
            ],
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.showNotification(res.data.message);
            this.getserviceInformationApi();
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else {
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => { });
    }
  };

  // ST

  // STAadhar1
  handleSTAadhar1Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "IDProofApplicant1",
          },
          () => {
            $("#STAadhar1Modal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "IDProofApplicant1",
            });
          }
        });
      $("#STAadhar1Modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTAadhar1 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STAadhar1").attr("disabled", true);
      $("#upload_btn20_STAadhar1").hide();
      $("#upload_btnloader_STAadhar1").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STAadhar1DocLink: res.data.data.IDProofApplicant1,
                },
                () => $("#viewDocLink_STAadhar1").css("display", "block")
              );
              STAadhar1Status = "remove";
              STStatusArray[0].status = STAadhar1Status;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STAadhar1Modal").modal("hide");
              $("#cancelbtn_STAadhar1").attr("disabled", false);
              $("#upload_btn20_STAadhar1").show();
              $("#upload_btnloader_STAadhar1").hide();
              $("#choose_STAadhar1").hide();
              $("#X-btn_STAadhar1").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STAadhar1").attr("disabled", false);
            $("#upload_btn20_STAadhar1").show();
            $("#upload_btnloader_STAadhar1").hide();
            $("#STAadhar1Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STAadhar1").attr("disabled", false);
            $("#upload_btn20_STAadhar1").show();
            $("#upload_btnloader_STAadhar1").hide();
            $("#STAadhar1Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STAadhar1").attr("disabled", false);
          $("#upload_btn20_STAadhar1").show();
          $("#upload_btnloader_STAadhar1").hide();
          $("#STAadhar1Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTAadhar1 = () => {
    $("#X-btn_STAadhar1").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "IDProofApplicant1",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STAadhar1Status = res.data.data.status;
          STStatusArray[0].status = STAadhar1Status;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STAadhar1").show();
          $("#X-btn_STAadhar1").hide();
          $("#X-btn_STAadhar1").attr("disabled", false);
          $("#viewDocLink_STAadhar1").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STAadhar1").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STAadhar1").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTAadhar1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STAadhar1seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "IDProofApplicant1",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STAadhar1seecommentsbtn").hide();
          $("#STAadhar1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTAadhar1hidecommentsbtn = () => {
    $("#STAadhar1seecommentsbtn").show();
    $("#STAadhar1seecommentsbtn").attr("disabled", false);
    $("#STAadhar1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STAadhar2
  handleSTAadhar2Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "IDProofApplicant2",
          },
          () => {
            $("#STAadhar2Modal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "IDProofApplicant2",
            });
          }
        });
      $("#STAadhar2Modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTAadhar2 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STAadhar2").attr("disabled", true);
      $("#upload_btn20_STAadhar2").hide();
      $("#upload_btnloader_STAadhar2").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STAadhar2DocLink: res.data.data.IDProofApplicant2,
                },
                () => $("#viewDocLink_STAadhar2").css("display", "block")
              );
              STAadhar2Status = "remove";
              STStatusArray[1].status = STAadhar2Status;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STAadhar2Modal").modal("hide");
              $("#cancelbtn_STAadhar2").attr("disabled", false);
              $("#upload_btn20_STAadhar2").show();
              $("#upload_btnloader_STAadhar2").hide();
              $("#choose_STAadhar2").hide();
              $("#X-btn_STAadhar2").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STAadhar2").attr("disabled", false);
            $("#upload_btn20_STAadhar2").show();
            $("#upload_btnloader_STAadhar2").hide();
            $("#STAadhar2Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STAadhar2").attr("disabled", false);
            $("#upload_btn20_STAadhar2").show();
            $("#upload_btnloader_STAadhar2").hide();
            $("#STAadhar2Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STAadhar2").attr("disabled", false);
          $("#upload_btn20_STAadhar2").show();
          $("#upload_btnloader_STAadhar2").hide();
          $("#STAadhar2Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTAadhar2 = () => {
    $("#X-btn_STAadhar2").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "IDProofApplicant2",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STAadhar2Status = res.data.data.status;
          STStatusArray[1].status = STAadhar2Status;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STAadhar2").show();
          $("#X-btn_STAadhar2").hide();
          $("#X-btn_STAadhar2").attr("disabled", false);
          $("#viewDocLink_STAadhar2").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STAadhar2").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STAadhar2").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTAadhar2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STAadhar2seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "IDProofApplicant2",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STAadhar2seecommentsbtn").hide();
          $("#STAadhar2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTAadhar2hidecommentsbtn = () => {
    $("#STAadhar2seecommentsbtn").show();
    $("#STAadhar2seecommentsbtn").attr("disabled", false);
    $("#STAadhar2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STPan1

  // STPan2

  // STPic1
  handleSTPic1Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "ApplicantPhoto1",
          },
          () => {
            $("#STPic1Modal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "ApplicantPhoto1",
            });
          }
        });
      $("#STPic1Modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTPic1 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STPic1").attr("disabled", true);
      $("#upload_btn20_STPic1").hide();
      $("#upload_btnloader_STPic1").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STPic1DocLink: res.data.data.ApplicantPhoto1,
                },
                () => $("#viewDocLink_STPic1").css("display", "block")
              );
              STPic1Status = "remove";
              STStatusArray[2].status = STPic1Status;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STPic1Modal").modal("hide");
              $("#cancelbtn_STPic1").attr("disabled", false);
              $("#upload_btn20_STPic1").show();
              $("#upload_btnloader_STPic1").hide();
              $("#choose_STPic1").hide();
              $("#X-btn_STPic1").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STPic1").attr("disabled", false);
            $("#upload_btn20_STPic1").show();
            $("#upload_btnloader_STPic1").hide();
            $("#STPic1Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STPic1").attr("disabled", false);
            $("#upload_btn20_STPic1").show();
            $("#upload_btnloader_STPic1").hide();
            $("#STPic1Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STPic1").attr("disabled", false);
          $("#upload_btn20_STPic1").show();
          $("#upload_btnloader_STPic1").hide();
          $("#STPic1Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTPic1 = () => {
    $("#X-btn_STPic1").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "ApplicantPhoto1",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STPic1Status = res.data.data.status;
          STStatusArray[2].status = STPic1Status;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STPic1").show();
          $("#X-btn_STPic1").hide();
          $("#X-btn_STPic1").attr("disabled", false);
          $("#viewDocLink_STPic1").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STPic1").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STPic1").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTPic1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STPic1seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ApplicantPhoto1",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STPic1seecommentsbtn").hide();
          $("#STPic1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTPic1hidecommentsbtn = () => {
    $("#STPic1seecommentsbtn").show();
    $("#STPic1seecommentsbtn").attr("disabled", false);
    $("#STPic1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STPic2
  handleSTPic2Change = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "ApplicantPhoto2",
          },
          () => {
            $("#STPic2Modal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "ApplicantPhoto2",
            });
          }
        });
      $("#STPic2Modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTPic2 = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STPic2").attr("disabled", true);
      $("#upload_btn20_STPic2").hide();
      $("#upload_btnloader_STPic2").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STPic2DocLink: res.data.data.ApplicantPhoto2,
                },
                () => $("#viewDocLink_STPic2").css("display", "block")
              );
              STPic2Status = "remove";
              STStatusArray[3].status = STPic2Status;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STPic2Modal").modal("hide");
              $("#cancelbtn_STPic2").attr("disabled", false);
              $("#upload_btn20_STPic2").show();
              $("#upload_btnloader_STPic2").hide();
              $("#choose_STPic2").hide();
              $("#X-btn_STPic2").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STPic2").attr("disabled", false);
            $("#upload_btn20_STPic2").show();
            $("#upload_btnloader_STPic2").hide();
            $("#STPic2Modal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STPic2").attr("disabled", false);
            $("#upload_btn20_STPic2").show();
            $("#upload_btnloader_STPic2").hide();
            $("#STPic2Modal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STPic2").attr("disabled", false);
          $("#upload_btn20_STPic2").show();
          $("#upload_btnloader_STPic2").hide();
          $("#STPic2Modal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTPic2 = () => {
    $("#X-btn_STPic2").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "ApplicantPhoto2",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STPic2Status = res.data.data.status;
          STStatusArray[3].status = STPic2Status;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STPic2").show();
          $("#X-btn_STPic2").hide();
          $("#X-btn_STPic2").attr("disabled", false);
          $("#viewDocLink_STPic2").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STPic2").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STPic2").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTPic2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STPic2seecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ApplicantPhoto2",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STPic2seecommentsbtn").hide();
          $("#STPic2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTPic2hidecommentsbtn = () => {
    $("#STPic2seecommentsbtn").show();
    $("#STPic2seecommentsbtn").attr("disabled", false);
    $("#STPic2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STPan3

  // STCR
  handleSTCRChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "CertificateOfRegistration",
          },
          () => {
            $("#STCRModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "CertificateOfRegistration",
            });
          }
        });
      $("#STCRModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTCR = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STCR").attr("disabled", true);
      $("#upload_btn20_STCR").hide();
      $("#upload_btnloader_STCR").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STCRDocLink: res.data.data.CertificateOfRegistration,
                },
                () => $("#viewDocLink_STCR").css("display", "block")
              );
              STCRStatus = "remove";
              STStatusArray[4].status = STCRStatus;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STCRModal").modal("hide");
              $("#cancelbtn_STCR").attr("disabled", false);
              $("#upload_btn20_STCR").show();
              $("#upload_btnloader_STCR").hide();
              $("#choose_STCR").hide();
              $("#X-btn_STCR").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STCR").attr("disabled", false);
            $("#upload_btn20_STCR").show();
            $("#upload_btnloader_STCR").hide();
            $("#STCRModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STCR").attr("disabled", false);
            $("#upload_btn20_STCR").show();
            $("#upload_btnloader_STCR").hide();
            $("#STCRModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STCR").attr("disabled", false);
          $("#upload_btn20_STCR").show();
          $("#upload_btnloader_STCR").hide();
          $("#STCRModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTCR = () => {
    $("#X-btn_STCR").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "CertificateOfRegistration",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STCRStatus = res.data.data.status;
          STStatusArray[4].status = STCRStatus;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STCR").show();
          $("#X-btn_STCR").hide();
          $("#X-btn_STCR").attr("disabled", false);
          $("#viewDocLink_STCR").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STCR").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STCR").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTCRseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STCRseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "CertificateOfRegistration",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STCRseecommentsbtn").hide();
          $("#STCRhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTCRhidecommentsbtn = () => {
    $("#STCRseecommentsbtn").show();
    $("#STCRseecommentsbtn").attr("disabled", false);
    $("#STCRhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STBP

  // STBR

  // STGumasta

  // STPUnit
  handleSTPUnitChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "ProductionUnitPhoto",
          },
          () => {
            $("#STPUnitModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "ProductionUnitPhoto",
            });
          }
        });
      $("#STPUnitModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTPUnit = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STPUnit").attr("disabled", true);
      $("#upload_btn20_STPUnit").hide();
      $("#upload_btnloader_STPUnit").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STPUnitDocLink: res.data.data.ProductionUnitPhoto,
                },
                () => $("#viewDocLink_STPUnit").css("display", "block")
              );
              STPUnitStatus = "remove";
              STStatusArray[5].status = STPUnitStatus;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STPUnitModal").modal("hide");
              $("#cancelbtn_STPUnit").attr("disabled", false);
              $("#upload_btn20_STPUnit").show();
              $("#upload_btnloader_STPUnit").hide();
              $("#choose_STPUnit").hide();
              $("#X-btn_STPUnit").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STPUnit").attr("disabled", false);
            $("#upload_btn20_STPUnit").show();
            $("#upload_btnloader_STPUnit").hide();
            $("#STPUnitModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STPUnit").attr("disabled", false);
            $("#upload_btn20_STPUnit").show();
            $("#upload_btnloader_STPUnit").hide();
            $("#STPUnitModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STPUnit").attr("disabled", false);
          $("#upload_btn20_STPUnit").show();
          $("#upload_btnloader_STPUnit").hide();
          $("#STPUnitModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTPUnit = () => {
    $("#X-btn_STPUnit").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "ProductionUnitPhoto",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STPUnitStatus = res.data.data.status;
          STStatusArray[5].status = STPUnitStatus;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STPUnit").show();
          $("#X-btn_STPUnit").hide();
          $("#X-btn_STPUnit").attr("disabled", false);
          $("#viewDocLink_STPUnit").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STPUnit").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STPUnit").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTPUnitseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STPUnitseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ProductionUnitPhoto",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STPUnitseecommentsbtn").hide();
          $("#STPUnithidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTPUnithidecommentsbtn = () => {
    $("#STPUnitseecommentsbtn").show();
    $("#STPUnitseecommentsbtn").attr("disabled", false);
    $("#STPUnithidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STLayoutPlan
  handleSTLayoutPlanChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "LayoutPlanUnit",
          },
          () => {
            $("#STLayoutPlanModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "LayoutPlanUnit",
            });
          }
        });
      $("#STLayoutPlanModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTLayoutPlan = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STLayoutPlan").attr("disabled", true);
      $("#upload_btn20_STLayoutPlan").hide();
      $("#upload_btnloader_STLayoutPlan").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STLayoutPlanDocLink: res.data.data.LayoutPlanUnit,
                },
                () => $("#viewDocLink_STLayoutPlan").css("display", "block")
              );
              STLayoutPlanStatus = "remove";
              STStatusArray[6].status = STLayoutPlanStatus;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STLayoutPlanModal").modal("hide");
              $("#cancelbtn_STLayoutPlan").attr("disabled", false);
              $("#upload_btn20_STLayoutPlan").show();
              $("#upload_btnloader_STLayoutPlan").hide();
              $("#choose_STLayoutPlan").hide();
              $("#X-btn_STLayoutPlan").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STLayoutPlan").attr("disabled", false);
            $("#upload_btn20_STLayoutPlan").show();
            $("#upload_btnloader_STLayoutPlan").hide();
            $("#STLayoutPlanModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STLayoutPlan").attr("disabled", false);
            $("#upload_btn20_STLayoutPlan").show();
            $("#upload_btnloader_STLayoutPlan").hide();
            $("#STLayoutPlanModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STLayoutPlan").attr("disabled", false);
          $("#upload_btn20_STLayoutPlan").show();
          $("#upload_btnloader_STLayoutPlan").hide();
          $("#STLayoutPlanModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTLayoutPlan = () => {
    $("#X-btn_STLayoutPlan").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "LayoutPlanUnit",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STLayoutPlanStatus = res.data.data.status;
          STStatusArray[6].status = STLayoutPlanStatus;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STLayoutPlan").show();
          $("#X-btn_STLayoutPlan").hide();
          $("#X-btn_STLayoutPlan").attr("disabled", false);
          $("#viewDocLink_STLayoutPlan").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STLayoutPlan").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STLayoutPlan").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTLayoutPlanseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STLayoutPlanseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "LayoutPlanUnit",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STLayoutPlanseecommentsbtn").hide();
          $("#STLayoutPlanhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTLayoutPlanhidecommentsbtn = () => {
    $("#STLayoutPlanseecommentsbtn").show();
    $("#STLayoutPlanseecommentsbtn").attr("disabled", false);
    $("#STLayoutPlanhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STListofEquip
  handleSTListofEquipChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "ListOfEquipment",
          },
          () => {
            $("#STListofEquipModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "ListOfEquipment",
            });
          }
        });
      $("#STListofEquipModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTListofEquip = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STListofEquip").attr("disabled", true);
      $("#upload_btn20_STListofEquip").hide();
      $("#upload_btnloader_STListofEquip").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STListofEquipDocLink: res.data.data.ListOfEquipment,
                },
                () => $("#viewDocLink_STListofEquip").css("display", "block")
              );
              STListofEquipStatus = "remove";
              STStatusArray[7].status = STListofEquipStatus;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STListofEquipModal").modal("hide");
              $("#cancelbtn_STListofEquip").attr("disabled", false);
              $("#upload_btn20_STListofEquip").show();
              $("#upload_btnloader_STListofEquip").hide();
              $("#choose_STListofEquip").hide();
              $("#X-btn_STListofEquip").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STListofEquip").attr("disabled", false);
            $("#upload_btn20_STListofEquip").show();
            $("#upload_btnloader_STListofEquip").hide();
            $("#STListofEquipModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STListofEquip").attr("disabled", false);
            $("#upload_btn20_STListofEquip").show();
            $("#upload_btnloader_STListofEquip").hide();
            $("#STListofEquipModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STListofEquip").attr("disabled", false);
          $("#upload_btn20_STListofEquip").show();
          $("#upload_btnloader_STListofEquip").hide();
          $("#STListofEquipModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTListofEquip = () => {
    $("#X-btn_STListofEquip").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "ListOfEquipment",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STListofEquipStatus = res.data.data.status;
          STStatusArray[7].status = STListofEquipStatus;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STListofEquip").show();
          $("#X-btn_STListofEquip").hide();
          $("#X-btn_STListofEquip").attr("disabled", false);
          $("#viewDocLink_STListofEquip").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STListofEquip").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STListofEquip").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTListofEquipseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STListofEquipseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ListOfEquipment",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STListofEquipseecommentsbtn").hide();
          $("#STListofEquiphidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTListofEquiphidecommentsbtn = () => {
    $("#STListofEquipseecommentsbtn").show();
    $("#STListofEquipseecommentsbtn").attr("disabled", false);
    $("#STListofEquiphidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STWAReport
  handleSTWAReportChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "WaterAnalysisReport",
          },
          () => {
            $("#STWAReportModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "WaterAnalysisReport",
            });
          }
        });
      $("#STWAReportModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTWAReport = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STWAReport").attr("disabled", true);
      $("#upload_btn20_STWAReport").hide();
      $("#upload_btnloader_STWAReport").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STWAReportDocLink: res.data.data.WaterAnalysisReport,
                },
                () => $("#viewDocLink_STWAReport").css("display", "block")
              );
              STWAReportStatus = "remove";
              STStatusArray[9].status = STWAReportStatus;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STWAReportModal").modal("hide");
              $("#cancelbtn_STWAReport").attr("disabled", false);
              $("#upload_btn20_STWAReport").show();
              $("#upload_btnloader_STWAReport").hide();
              $("#choose_STWAReport").hide();
              $("#X-btn_STWAReport").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STWAReport").attr("disabled", false);
            $("#upload_btn20_STWAReport").show();
            $("#upload_btnloader_STWAReport").hide();
            $("#STWAReportModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STWAReport").attr("disabled", false);
            $("#upload_btn20_STWAReport").show();
            $("#upload_btnloader_STWAReport").hide();
            $("#STWAReportModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STWAReport").attr("disabled", false);
          $("#upload_btn20_STWAReport").show();
          $("#upload_btnloader_STWAReport").hide();
          $("#STWAReportModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTWAReport = () => {
    $("#X-btn_STWAReport").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "WaterAnalysisReport",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STWAReportStatus = res.data.data.status;
          STStatusArray[9].status = STWAReportStatus;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STWAReport").show();
          $("#X-btn_STWAReport").hide();
          $("#X-btn_STWAReport").attr("disabled", false);
          $("#viewDocLink_STWAReport").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STWAReport").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STWAReport").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTWAReportseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STWAReportseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "WaterAnalysisReport",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STWAReportseecommentsbtn").hide();
          $("#STWAReporthidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTWAReporthidecommentsbtn = () => {
    $("#STWAReportseecommentsbtn").show();
    $("#STWAReportseecommentsbtn").attr("disabled", false);
    $("#STWAReporthidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STListofDir
  handleSTListofDirChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "DirectorsFullAddress",
          },
          () => {
            $("#STListofDirModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "DirectorsFullAddress",
            });
          }
        });
      $("#STListofDirModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTListofDir = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STListofDir").attr("disabled", true);
      $("#upload_btn20_STListofDir").hide();
      $("#upload_btnloader_STListofDir").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STListofDirDocLink: res.data.data.DirectorsFullAddress,
                },
                () => $("#viewDocLink_STListofDir").css("display", "block")
              );
              STListofDirStatus = "remove";
              STStatusArray[9].status = STListofDirStatus;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STListofDirModal").modal("hide");
              $("#cancelbtn_STListofDir").attr("disabled", false);
              $("#upload_btn20_STListofDir").show();
              $("#upload_btnloader_STListofDir").hide();
              $("#choose_STListofDir").hide();
              $("#X-btn_STListofDir").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STListofDir").attr("disabled", false);
            $("#upload_btn20_STListofDir").show();
            $("#upload_btnloader_STListofDir").hide();
            $("#STListofDirModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STListofDir").attr("disabled", false);
            $("#upload_btn20_STListofDir").show();
            $("#upload_btnloader_STListofDir").hide();
            $("#STListofDirModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STListofDir").attr("disabled", false);
          $("#upload_btn20_STListofDir").show();
          $("#upload_btnloader_STListofDir").hide();
          $("#STListofDirModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTListofDir = () => {
    $("#X-btn_STListofDir").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "DirectorsFullAddress",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STListofDirStatus = res.data.data.status;
          STStatusArray[9].status = STListofDirStatus;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STListofDir").show();
          $("#X-btn_STListofDir").hide();
          $("#X-btn_STListofDir").attr("disabled", false);
          $("#viewDocLink_STListofDir").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STListofDir").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STListofDir").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTListofDirseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STListofDirseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "DirectorsFullAddress",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STListofDirseecommentsbtn").hide();
          $("#STListofDirhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTListofDirhidecommentsbtn = () => {
    $("#STListofDirseecommentsbtn").show();
    $("#STListofDirseecommentsbtn").attr("disabled", false);
    $("#STListofDirhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STRecallPlan
  handleSTRecallPlanChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "RecallPlan",
          },
          () => {
            $("#STRecallPlanModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "RecallPlan",
            });
          }
        });
      $("#STRecallPlanModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTRecallPlan = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STRecallPlan").attr("disabled", true);
      $("#upload_btn20_STRecallPlan").hide();
      $("#upload_btnloader_STRecallPlan").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STRecallPlanDocLink: res.data.data.RecallPlan,
                },
                () => $("#viewDocLink_STRecallPlan").css("display", "block")
              );
              STRecallPlanStatus = "remove";
              STStatusArray[10].status = STRecallPlanStatus;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STRecallPlanModal").modal("hide");
              $("#cancelbtn_STRecallPlan").attr("disabled", false);
              $("#upload_btn20_STRecallPlan").show();
              $("#upload_btnloader_STRecallPlan").hide();
              $("#choose_STRecallPlan").hide();
              $("#X-btn_STRecallPlan").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STRecallPlan").attr("disabled", false);
            $("#upload_btn20_STRecallPlan").show();
            $("#upload_btnloader_STRecallPlan").hide();
            $("#STRecallPlanModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STRecallPlan").attr("disabled", false);
            $("#upload_btn20_STRecallPlan").show();
            $("#upload_btnloader_STRecallPlan").hide();
            $("#STRecallPlanModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STRecallPlan").attr("disabled", false);
          $("#upload_btn20_STRecallPlan").show();
          $("#upload_btnloader_STRecallPlan").hide();
          $("#STRecallPlanModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTRecallPlan = () => {
    $("#X-btn_STRecallPlan").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "RecallPlan",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STRecallPlanStatus = res.data.data.status;
          STStatusArray[10].status = STRecallPlanStatus;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STRecallPlan").show();
          $("#X-btn_STRecallPlan").hide();
          $("#X-btn_STRecallPlan").attr("disabled", false);
          $("#viewDocLink_STRecallPlan").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STRecallPlan").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STRecallPlan").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTRecallPlanseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STRecallPlanseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "RecallPlan",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STRecallPlanseecommentsbtn").hide();
          $("#STRecallPlanhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTRecallPlanhidecommentsbtn = () => {
    $("#STRecallPlanseecommentsbtn").show();
    $("#STRecallPlanseecommentsbtn").attr("disabled", false);
    $("#STRecallPlanhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STTradeLicence

  handleSTbusinessNatureChange = (e) => {
    this.setState({
      STbusinessNature: e.target.value,
    });
  };

  // STAddressProofType
  STAddressProofType_radioChange = (e) => {
    this.setState(
      {
        STAddressProofType_radio: e.currentTarget.value,
      },
      () => {
        if (this.state.STAddressProofType_radio != "CopyOfRegistry") {
          $(".RentAgreement").css("display", "flex");
          $(".CopyOfRegistry").hide();
        } else if (this.state.STAddressProofType_radio == "CopyOfRegistry") {
          $(".CopyOfRegistry").css("display", "flex");
          $(".RentAgreement").hide();
        }
        this.checkhandleSTProceedBtn();
      }
    );
  };

  // STCopyOfRegistry
  handleSTCopyOfRegistryChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "OwnershipDeed",
          },
          () => {
            $("#STCopyOfRegistryModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "OwnershipDeed",
            });
          }
        });
      $("#STCopyOfRegistryModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTCopyOfRegistry = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STCopyOfRegistry").attr("disabled", true);
      $("#upload_btn20_STCopyOfRegistry").hide();
      $("#upload_btnloader_STCopyOfRegistry").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STCopyOfRegistryDocLink: res.data.data.OwnershipDeed,
                },
                () => $("#viewDocLink_STCopyOfRegistry").css("display", "block")
              );
              STCopyOfRegistryStatus = "remove";
              STOptStatusArray[0].status = STCopyOfRegistryStatus;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STCopyOfRegistryModal").modal("hide");
              $("#cancelbtn_STCopyOfRegistry").attr("disabled", false);
              $("#upload_btn20_STCopyOfRegistry").show();
              $("#upload_btnloader_STCopyOfRegistry").hide();
              $("#choose_STCopyOfRegistry").hide();
              $("#X-btn_STCopyOfRegistry").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STCopyOfRegistry").attr("disabled", false);
            $("#upload_btn20_STCopyOfRegistry").show();
            $("#upload_btnloader_STCopyOfRegistry").hide();
            $("#STCopyOfRegistryModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STCopyOfRegistry").attr("disabled", false);
            $("#upload_btn20_STCopyOfRegistry").show();
            $("#upload_btnloader_STCopyOfRegistry").hide();
            $("#STCopyOfRegistryModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STCopyOfRegistry").attr("disabled", false);
          $("#upload_btn20_STCopyOfRegistry").show();
          $("#upload_btnloader_STCopyOfRegistry").hide();
          $("#STCopyOfRegistryModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTCopyOfRegistry = () => {
    $("#X-btn_STCopyOfRegistry").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "OwnershipDeed",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STCopyOfRegistryStatus = res.data.data.status;
          STOptStatusArray[0].status = STCopyOfRegistryStatus;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STCopyOfRegistry").show();
          $("#X-btn_STCopyOfRegistry").hide();
          $("#X-btn_STCopyOfRegistry").attr("disabled", false);
          $("#viewDocLink_STCopyOfRegistry").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STCopyOfRegistry").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STCopyOfRegistry").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTCopyOfRegistryseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STCopyOfRegistryseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "OwnershipDeed",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STCopyOfRegistryseecommentsbtn").hide();
          $("#STCopyOfRegistryhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTCopyOfRegistryhidecommentsbtn = () => {
    $("#STCopyOfRegistryseecommentsbtn").show();
    $("#STCopyOfRegistryseecommentsbtn").attr("disabled", false);
    $("#STCopyOfRegistryhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STRentAgreement
  handleSTRentAgreementChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "RentAgreement",
          },
          () => {
            $("#STRentAgreementModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "RentAgreement",
            });
          }
        });
      $("#STRentAgreementModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTRentAgreement = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STRentAgreement").attr("disabled", true);
      $("#upload_btn20_STRentAgreement").hide();
      $("#upload_btnloader_STRentAgreement").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STRentAgreementDocLink: res.data.data.RentAgreement,
                },
                () => $("#viewDocLink_STRentAgreement").css("display", "block")
              );
              STRentAgreementStatus = "remove";
              STOptStatusArray[1].status = STRentAgreementStatus;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STRentAgreementModal").modal("hide");
              $("#cancelbtn_STRentAgreement").attr("disabled", false);
              $("#upload_btn20_STRentAgreement").show();
              $("#upload_btnloader_STRentAgreement").hide();
              $("#choose_STRentAgreement").hide();
              $("#X-btn_STRentAgreement").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STRentAgreement").attr("disabled", false);
            $("#upload_btn20_STRentAgreement").show();
            $("#upload_btnloader_STRentAgreement").hide();
            $("#STRentAgreementModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STRentAgreement").attr("disabled", false);
            $("#upload_btn20_STRentAgreement").show();
            $("#upload_btnloader_STRentAgreement").hide();
            $("#STRentAgreementModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STRentAgreement").attr("disabled", false);
          $("#upload_btn20_STRentAgreement").show();
          $("#upload_btnloader_STRentAgreement").hide();
          $("#STRentAgreementModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTRentAgreement = () => {
    $("#X-btn_STRentAgreement").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "RentAgreement",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STRentAgreementStatus = res.data.data.status;
          STOptStatusArray[1].status = STRentAgreementStatus;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STRentAgreement").show();
          $("#X-btn_STRentAgreement").hide();
          $("#X-btn_STRentAgreement").attr("disabled", false);
          $("#viewDocLink_STRentAgreement").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STRentAgreement").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STRentAgreement").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTRentAgreementseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STRentAgreementseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "RentAgreement",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STRentAgreementseecommentsbtn").hide();
          $("#STRentAgreementhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTRentAgreementhidecommentsbtn = () => {
    $("#STRentAgreementseecommentsbtn").show();
    $("#STRentAgreementseecommentsbtn").attr("disabled", false);
    $("#STRentAgreementhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STPR
  handleSTPRChange = (e) => {
    const myfile = e.target.files;
    if (ChooseNowBtnsDisable == "Yes") {
      this.showNotification(
        "Files cannot be uploaded when the service is locked!"
      );
    } else if (myfile.length == 1) {
      const file = myfile[0];
      if (file.type.match("audio.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.type.match("video.*")) {
        this.showNotification("File extension not allowed!");
      } else if (file.size > 104857600) {
        this.showNotification("File too big!");
      } else if (file.name.length > 100) {
        this.showNotification("File name limit exceed!");
      } else {
        this.setState(
          {
            fileName: file.name,
            fileBase64: file,
            documentType: "general",
            documentName: "PropertyTaxReceipt",
          },
          () => {
            $("#STPRModal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        );
      }
    } else if (myfile.length > 1) {
      var zip = new JSZip();
      for (let i = 0; i < myfile.length; i++) {
        zip.file(myfile[i].name, myfile[i], {
          base64: true,
        });
      }
      zip
        .generateAsync({
          type: "blob",
        })
        .then((content) => {
          if (content.size > 104857600) {
            this.showNotification("File too big!");
          } else {
            this.setState({
              fileName: "download.zip",
              fileBase64: content,
              documentType: "general",
              documentName: "PropertyTaxReceipt",
            });
          }
        });
      $("#STPRModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    } else {
      this.showNotification("File cannot be null!");
    }
  };

  uploadSTPR = () => {
    if (this.state.comment.length <= 250) {
      $("#cancelbtn_STPR").attr("disabled", true);
      $("#upload_btn20_STPR").hide();
      $("#upload_btnloader_STPR").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", this.state.documentName);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.setState({
              comment: "",
            });
            if (res.data.data.status == "remove") {
              this.setState(
                {
                  STPRDocLink: res.data.data.PropertyTaxReceipt,
                },
                () => $("#viewDocLink_STPR").css("display", "block")
              );
              STPRStatus = "remove";
              STStatusArray[12].status = STPRStatus;
              this.checkDropdownValue();
              this.checkhandleSTProceedBtn();
              $("#STPRModal").modal("hide");
              $("#cancelbtn_STPR").attr("disabled", false);
              $("#upload_btn20_STPR").show();
              $("#upload_btnloader_STPR").hide();
              $("#choose_STPR").hide();
              $("#X-btn_STPR").show();
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $("#cancelbtn_STPR").attr("disabled", false);
            $("#upload_btn20_STPR").show();
            $("#upload_btnloader_STPR").hide();
            $("#STPRModal").modal("hide");
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $("#cancelbtn_STPR").attr("disabled", false);
            $("#upload_btn20_STPR").show();
            $("#upload_btnloader_STPR").hide();
            $("#STPRModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          $("#cancelbtn_STPR").attr("disabled", false);
          $("#upload_btn20_STPR").show();
          $("#upload_btnloader_STPR").hide();
          $("#STPRModal").modal("hide");
          this.showNotification("Something went wrong");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTPR = () => {
    $("#X-btn_STPR").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "general",
          documentName: "PropertyTaxReceipt",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          STPRStatus = res.data.data.status;
          STStatusArray[12].status = STPRStatus;
          this.checkDropdownValue();
          this.checkhandleSTProceedBtn();
          $("#choose_STPR").show();
          $("#X-btn_STPR").hide();
          $("#X-btn_STPR").attr("disabled", false);
          $("#viewDocLink_STPR").hide();
          this.showNotification(res.data.message);
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          $("#X-btn_STPR").attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_STPR").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTPRseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STPRseecommentsbtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PropertyTaxReceipt",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(".hidecommentsbtn").hide();
          $("#STPRseecommentsbtn").hide();
          $("#STPRhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTPRhidecommentsbtn = () => {
    $("#STPRseecommentsbtn").show();
    $("#STPRseecommentsbtn").attr("disabled", false);
    $("#STPRhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  // STAdditional
  handleSTAddFileChange = (e, index, status) => {
    STadditionalReuploadIndex = index;
    STadditionalReuploadStatus = status;
    $(".upload_btn20_7").show();
    $(".upload_btnloader_7").hide();
    const file = e.target.files[0];
    if (!STadditionalFilesNames.includes(this.state.STAdd_DocName.trim())) {
      const myfile = e.target.files;
      if (ChooseNowBtnsDisable == "Yes") {
        this.showNotification(
          "Files cannot be uploaded when the service is locked!"
        );
      } else if (myfile.length == 1) {
        const file = myfile[0];
        if (file.type.match("audio.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.type.match("video.*")) {
          this.showNotification("File extension not allowed!");
        } else if (file.size > 104857600) {
          this.showNotification("File too big!");
        } else if (file.name.length > 100) {
          this.showNotification("File name limit exceed!");
        } else {
          this.setState(
            {
              fileName: file.name,
              fileBase64: file,
              documentType: "additional",
              documentName: this.state.STAdd_DocName,
            },
            () => {
              $(".STAddModal").modal({
                backdrop: "static",
                keyboard: false,
              });
            }
          );
        }
      } else if (myfile.length > 1) {
        var zip = new JSZip();
        for (let i = 0; i < myfile.length; i++) {
          zip.file(myfile[i].name, myfile[i], {
            base64: true,
          });
        }
        zip
          .generateAsync({
            type: "blob",
          })
          .then((content) => {
            if (content.size > 104857600) {
              this.showNotification("File too big!");
            } else {
              this.setState({
                fileName: "download.zip",
                fileBase64: content,
                documentType: "additional",
                documentName: this.state.STAdd_DocName,
              });
            }
          });
        $(".STAddModal").modal({
          backdrop: "static",
          keyboard: false,
        });
      } else {
        this.showNotification("File cannot be null!");
      }
    } else {
      this.showNotification(
        "Document names can not be the same, Try again with different name!"
      );
    }
  };

  uploadSTFile = (FileIndex, FileStatus) => {
    let AdditionaldocumentName1;
    if (FileStatus == "reupload") {
      $(".proceednowbtn").attr("disabled", true);
      let fileTobeUploaded = this.state.STadditionalFiles[FileIndex];
      AdditionaldocumentName1 = Object.keys(fileTobeUploaded)[0];
      mainaction = "reupload";
    } else {
      AdditionaldocumentName1 = this.state.documentName;
      mainaction = "upload";
    }
    if (AdditionaldocumentName1.trim() == "") {
      $(".STAddModal").modal("hide");
      this.showNotification("Enter document Name first");
    } else if (this.state.comment.length <= 250) {
      $(".cancelbtn_7").attr("disabled", true);
      $(".upload_btn20_7").hide();
      $(".upload_btnloader_7").show();
      var formData = new FormData();
      formData.append("userId", Decuserid);
      formData.append("requestType", temp);
      formData.append("agentId", Decagentid);
      formData.append("serviceId", Decserviceid);
      formData.append("serviceName", Decservicename);
      formData.append("serviceType", this.state.serviceType);
      formData.append("plan", Decplan);
      formData.append("Month", "");
      formData.append("FY", "");
      formData.append("documentType", this.state.documentType);
      formData.append("documentName", AdditionaldocumentName1);
      formData.append("fileName", this.state.fileName);
      formData.append("comment", this.state.comment);
      formData.append("file", this.state.fileBase64);
      formData.append("action", mainaction);
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=multipart_uploadDocument",
          formData,
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            $(".STAddModal").modal("hide");
            $(".cancelbtn_7").attr("disabled", false);
            $(`#${FileIndex}STFileseecommentsbtn`).attr("disabled", false);
            if (STadditionalReupload == false) {
              STadditionalFilesNames.push(this.state.STAdd_DocName);
              this.state.STadditionalFiles.pop();
              this.setState(
                {
                  comment: "",
                  STadditionalFiles: [
                    ...this.state.STadditionalFiles,
                    res.data.data,
                  ],
                },
                () => {
                  this.setState(
                    {
                      api_STadditionalFiles: "Yes",
                    },
                    () => {
                      this.STadditionalFilesArea();
                      this.checkDropdownValue();
                      this.checkhandleSTProceedBtn();
                    }
                  );

                  $(".STadditionalInput").attr("disabled", true);
                }
              );
            } else if (STadditionalReupload == true) {
              $(".proceednowbtn").attr("disabled", false);
              var array = [...this.state.STadditionalFiles];
              array.splice(FileIndex, 1, res.data.data);
              this.setState(
                {
                  comment: "",
                  api_STadditionalFiles: "Yes",
                  STadditionalFiles: array,
                },
                () => {
                  this.STadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleSTProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          } else if (res.data.code == "201" && res.data.status == "fail") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".STAddModal").modal("hide");
            this.showNotification("Something Went Wrong!");
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else if (res.data.code == "201" && res.data.status == "denied") {
            $(".cancelbtn_7").attr("disabled", false);
            $("#upload_btn20_7").show();
            $("#upload_btnloader_7").hide();
            $(".STAddModal").modal("hide");
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          this.showNotification("Something went wrong, Try again!");
        });
    } else {
      this.showNotification("Comment length exceed!");
    }
  };

  removeSTFile = (index) => {
    $("#X-btn_7").attr("disabled", true);
    let fileTobeDeleted = this.state.STadditionalFiles[index];
    let NameOfAddFile = Object.keys(fileTobeDeleted)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=removeDocument",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          serviceType: this.state.serviceType,
          plan: Decplan,
          FY: "",
          Month: "",
          documentType: "additional",
          documentName: NameOfAddFile,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          var array = [...this.state.STadditionalFiles];
          if (res.data.data.status == "reupload") {
            array[index].status = "reupload";
            this.setState(
              {
                STadditionalFiles: array,
              },
              () => {
                this.STadditionalFilesArea();
                this.checkDropdownValue();
                this.checkhandleSTProceedBtn();
              }
            );
            this.showNotification(res.data.message);
          } else {
            array.splice(index, 1);
            if (array.length > 0) {
              this.setState(
                {
                  STadditionalFiles: array,
                },
                () => {
                  this.STadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleSTProceedBtn();
                }
              );
            } else if (array.length == 0) {
              this.setState(
                {
                  STadditionalFiles: [],
                  STAdd_DocName: "",
                  api_STadditionalFiles: "No",
                  IsSTAddFileDisabled: true,
                },
                () => {
                  this.STadditionalFilesArea();
                  this.checkDropdownValue();
                  this.checkhandleSTProceedBtn();
                }
              );
            }
            this.showNotification(res.data.message);
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $("#X-btn_7").attr("disabled", false);
        this.showNotification("Something went wrong");
      });
  };

  handleSTFileseeComments = (index) => {
    $(".commentsbtn").attr("disabled", false);
    $(`#${index}STFileseecommentsbtn`).attr("disabled", true);
    let SelectedFile = this.state.STadditionalFiles[index];
    let NameOfAddFile = Object.keys(SelectedFile)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,

          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "additional",
          documentName: NameOfAddFile,
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".seecommentsbtn").show();
          $(`#${index}STFileseecommentsbtn`).hide();
          $(".hidecommentsbtn").hide();
          $(`#${index}STFilehidecommentsbtn`).show();
          $(".TDRightSide").show();
          $(".commentimg-section").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTFilehideComments = (index) => {
    $(".seecommentsbtn").show();
    $(`#${index}STFileseecommentsbtn`).show();
    $(`#${index}STFileseecommentsbtn`).attr("disabled", false);
    $(`#${index}STFilehidecommentsbtn`).hide();
    $(".hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section").show();
  };

  checkAddCommentSTFiles = (FileIndex, isDisabled) => {
    if (isDisabled == true) {
      return (
        <>
          <button
            className="seecommentsbtn STFileseecommentsbtn"
            id={`${FileIndex}STFileseecommentsbtn`}
            disabled
            onClick={() => this.handleSTFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn STFilehidecommentsbtn"
            id={`${FileIndex}STFilehidecommentsbtn`}
            onClick={() => this.handleSTFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    } else if (isDisabled == false) {
      return (
        <>
          <button
            className="seecommentsbtn commentsbtn STFileseecommentsbtn"
            id={`${FileIndex}STFileseecommentsbtn`}
            onClick={() => this.handleSTFileseeComments(FileIndex)}
          >
            See comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
          <button
            className="hidecommentsbtn STFilehidecommentsbtn"
            id={`${FileIndex}STFilehidecommentsbtn`}
            onClick={() => this.handleSTFilehideComments(FileIndex)}
          >
            Hide comments{" "}
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
            />
          </button>
        </>
      );
    }
  };

  checkStatusSTAddFiles = (status, link, index) => {
    STadditionalFilesIndex = index;
    if (HideProceedBtn == "Yes") {
      $(".proceednowbtn").hide();
      if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
          </>
        );
      }
    } else {
      if (status == undefined) {
        status = "open";
      }
      if (status == "open") {
        mainaction = "upload";
        STadditionalReupload = true;
        $(".proceednowbtn").show();
        return (
          <>
            <label
              htmlFor={`ul_file_STAdd${index}`}
              className="choose_btn choose_7"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              multiple
              name="file[]"
              className="upload-photo"
              id={`ul_file_STAdd${index}`}
              // disabled={this.state.IsSTAddFileDisabled}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handleSTAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "remove") {
        mainaction = "upload";
        $(".proceednowbtn").show();
        return (
          <>
            <button
              className="X-btn X-btn_7"
              onClick={() => this.removeSTFile(index)}
            >
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_1025.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "review") {
        mainaction = "upload";
        return (
          <>
            <button className="review-btn review-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Path_19663.svg"}
              />
            </button>
            <br />
            <a
              href={link}
              className="viewDocLink viewDocLink_7"
              target="_blank"
            >
              View uploaded document{" "}
              <img
                className="viewDocLinkimg"
                src={process.env.PUBLIC_URL + "/img/Path_20245.svg"}
              />
            </a>
          </>
        );
      } else if (status == "reupload") {
        STadditionalReupload = true;
        mainaction = "reupload";
        $(".proceednowbtn").show();
        $(".proceednowbtn").attr("disabled", true);
        return (
          <>
            <label
              htmlFor={`ul_file_STAdd${index}`}
              className="choose_btn choose_7"
            >
              Choose file{" "}
              <img
                src={process.env.PUBLIC_URL + "/img/Path_15188.svg"}
                alt="file"
              />
            </label>
            <input
              type="file"
              multiple
              name="file[]"
              className="upload-photo"
              id={`ul_file_STAdd${index}`}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={(e) => this.handleSTAddFileChange(e, index, status)}
            />
          </>
        );
      } else if (status == "done") {
        mainaction = "upload";
        return (
          <>
            <button className="done-btn done-btn_7">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/tick.svg"}
              />
            </button>
          </>
        );
      }
    }
  };

  AdditionalSTFileDisabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero STadditionalInput"
        value={NameOfAddFile}
        disabled
        onChange={(e) => this.handleSTAdd_DocNameChange(e)}
      />
    );
  };

  AdditionalSTFileEnabledField = (NameOfAddFile) => {
    return (
      <input
        type="text"
        placeholder="Document name..."
        maxLength="150"
        className="uploadtextfield marginzero STadditionalInput"
        value={NameOfAddFile}
        onChange={(e) => this.handleSTAdd_DocNameChange(e)}
      />
    );
  };

  STadditionalFilesArea = () => {
    STadditionalFilesNames = [];
    if (this.state.api_STadditionalFiles == "Yes") {
      return this.state.STadditionalFiles.map((file, index) => {
        let NameOfAddFile = Object.keys(file)[0];
        STadditionalFilesNames.push(NameOfAddFile);
        let link = file[Object.keys(file)[0]];
        let AdditionalFileInputField;
        let commentDisabled;
        if (file.status == "open" || file.status == undefined) {
          AdditionalFileInputField =
            this.AdditionalSTFileEnabledField(NameOfAddFile);
          commentDisabled = true;
        } else if (file.status != "open") {
          AdditionalFileInputField =
            this.AdditionalSTFileDisabledField(NameOfAddFile);
          commentDisabled = false;
        }
        return (
          <div className="displayflex" key={index}>
            <div className="uploadnocol">
              <p className="uploadadditionalsidetext">{index + 1}&#41;</p>
            </div>
            <div className="uploadadditionaldatacol">
              <div className="row mb-2">
                <div className="col-md-5">{AdditionalFileInputField}</div>
                <div className="col-md-7">
                  <div className="float-md-right float-left">
                    {this.checkAddCommentSTFiles(index, commentDisabled)}
                    {this.checkStatusSTAddFiles(file.status, link, index)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.api_STadditionalFiles == "No") {
      return (
        <div className="displayflex">
          <div className="uploadnocol">
            <p className="uploadadditionalsidetext">1&#41;</p>
          </div>
          <div className="uploadadditionaldatacol">
            <div className="row mb-2">
              <div className="col-md-5">
                <input
                  type="text"
                  placeholder="Document name..."
                  maxLength="150"
                  className="uploadtextfield marginzero"
                  value={this.state.STAdd_DocName}
                  onChange={(e) => this.handleSTAdd_DocNameChange(e)}
                />
              </div>
              <div className="col-md-7">
                <div className="fl_right">
                  {this.checkAddCommentSTFiles(0, true)}
                  {this.checkStatusSTAddFiles("open", null, 0)}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  handleSTAdd_DocNameChange = (e) => {
    this.setState(
      {
        STAdd_DocName: e.target.value,
      },
      () => {
        if (this.state.STAdd_DocName.trim().length > 0) {
          this.setState({
            IsSTAddFileDisabled: false,
          });
        } else {
          this.setState({
            IsSTAddFileDisabled: true,
          });
        }
      }
    );
  };

  handleAddSTFileBtn = (FileIndex) => {
    const { STAdd_DocName, STadditionalFiles } = this.state;

    let prevFile = STadditionalFiles[FileIndex];
    let NameOfPrevFile = Object.keys(prevFile)[0];
    let valueOffile = prevFile[Object.keys(prevFile)[0]];

    if (FileIndex == undefined) {
      this.showNotification("empty fields");
    } else if (STAdd_DocName.trim() == "") {
      this.showNotification("Required fields cannot be empty!");
    } else if (valueOffile == null) {
      this.showNotification("Choose a file");
    } else {
      this.setState(
        {
          STadditionalFiles: [...this.state.STadditionalFiles, ""],
          STAdd_DocName: "",
          IsSTAddFileDisabled: true,
        },
        () => {
          $(".STadditionalInput").attr("disabled", false);
        }
      );
    }
  };

  // Proceed Checks
  STcheckRemainingReuploads = () => {
    if (this.state.licenseType == "T") {
      if (this.state.STAddressProofType_radio == "CopyOfRegistry") {
        if (
          STAadhar1Status != "reupload" &&
          STAadhar2Status != "reupload" &&
          STPic1Status != "reupload" &&
          STPic2Status != "reupload" &&
          STCRStatus != "reupload" &&
          STCopyOfRegistryStatus != "reupload"
        ) {
          AnyReuploadOccuredST = "No";
        } else {
          $(".proceednowbtn5").show();
          AnyReuploadOccuredST = "Yes";
        }
      } else {
        if (
          STAadhar1Status != "reupload" &&
          STAadhar2Status != "reupload" &&
          STPic1Status != "reupload" &&
          STPic2Status != "reupload" &&
          STCRStatus != "reupload" &&
          STRentAgreementStatus != "reupload"
        ) {
          AnyReuploadOccuredST = "No";
        } else {
          $(".proceednowbtn5").show();
          AnyReuploadOccuredST = "Yes";
        }
      }
    } else {
      if (this.state.STAddressProofType_radio == "CopyOfRegistry") {
        if (
          STAadhar1Status != "reupload" &&
          STAadhar2Status != "reupload" &&
          STPic1Status != "reupload" &&
          STPic2Status != "reupload" &&
          STCRStatus != "reupload" &&
          STPUnitStatus != "reupload" &&
          STLayoutPlanStatus != "reupload" &&
          STListofEquipStatus != "reupload" &&
          STWAReportStatus != "reupload" &&
          STListofDirStatus != "reupload" &&
          STRecallPlanStatus != "reupload" &&
          STCopyOfRegistryStatus != "reupload"
        ) {
          AnyReuploadOccuredST = "No";
        } else {
          $(".proceednowbtn5").show();
          AnyReuploadOccuredST = "Yes";
        }
      } else {
        if (
          STAadhar1Status != "reupload" &&
          STAadhar2Status != "reupload" &&
          STPic1Status != "reupload" &&
          STPic2Status != "reupload" &&
          STCRStatus != "reupload" &&
          STPUnitStatus != "reupload" &&
          STLayoutPlanStatus != "reupload" &&
          STListofEquipStatus != "reupload" &&
          STWAReportStatus != "reupload" &&
          STListofDirStatus != "reupload" &&
          STRecallPlanStatus != "reupload" &&
          STRentAgreementStatus != "reupload"
        ) {
          AnyReuploadOccuredST = "No";
        } else {
          $(".proceednowbtn5").show();
          AnyReuploadOccuredST = "Yes";
        }
      }
    }
  };

  checkhandleSTProceedBtn = () => {
    let statusCheckReupload = this.statusCheckFunction(
      "reupload",
      STStatusArray
    );
    let STadditionalStatusArrayCheck = this.statusCheckFunction(
      "reupload",
      this.state.STadditionalFiles
    );
    let goodtogo = "No";
    if (this.state.STAddressProofType_radio == "CopyOfRegistry") {
      if (
        STOptStatusArray[0].status != "reupload" &&
        STOptStatusArray[0].status != "upload" &&
        STOptStatusArray[0].status != "open"
      ) {
        goodtogo = "Yes";
      } else {
        goodtogo = "No";
      }
    } else if (this.state.STAddressProofType_radio == "RentAgreement") {
      if (
        STOptStatusArray[1].status != "reupload" &&
        STOptStatusArray[1].status != "upload" &&
        STOptStatusArray[1].status != "open"
      ) {
        goodtogo = "Yes";
      } else {
        goodtogo = "No";
      }
    } else {
      goodtogo = "No";
    }

    if (
      AnyReuploadOccuredST == "Yes" &&
      statusCheckReupload == undefined &&
      goodtogo == "Yes" &&
      STadditionalStatusArrayCheck == undefined
    ) {
      $(".proceednowbtn5").attr("disabled", false);
      STproceednowforword = "success";
    } else if (
      STAadhar1Status == "remove" &&
      STAadhar2Status == "remove" &&
      STPic1Status == "remove" &&
      STPic2Status == "remove" &&
      STCRStatus == "remove" &&
      this.state.licenseType == "T" &&
      goodtogo == "Yes"
    ) {
      $(".proceednowbtn5").attr("disabled", false);
      STproceednowforword = "success";
    } else if (
      STAadhar1Status == "remove" &&
      STAadhar2Status == "remove" &&
      STPic1Status == "remove" &&
      STPic2Status == "remove" &&
      STCRStatus == "remove" &&
      STPUnitStatus == "remove" &&
      STLayoutPlanStatus == "remove" &&
      STListofEquipStatus == "remove" &&
      STWAReportStatus == "remove" &&
      STListofDirStatus == "remove" &&
      STRecallPlanStatus == "remove" &&
      this.state.licenseType == "M" &&
      goodtogo == "Yes"
    ) {
      $(".proceednowbtn5").attr("disabled", false);
      STproceednowforword = "success";
    } else {
      $(".proceednowbtn5").attr("disabled", true);
      STproceednowforword = "fail";
    }
  };

  handleSTProceedBtn = () => {
    this.checkhandleHUFProceedBtn();
    if (this.state.STbusinessNature.trim() == "") {
      this.showNotification("Required fields can not be empty!");
    } else if (this.state.STbusinessNature.trim().length > 200) {
      this.showNotification("Nature of your Business / Brand limit exceed!");
    } else if (STproceednowforword == "success") {
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=lockService",
          {
            userId: Decuserid,
            requestType: temp,
            agentId: Decagentid,

            serviceId: Decserviceid,
            serviceName: Decservicename,
            serviceType: this.state.serviceType,
            FY: "",
            Month: "",
            inputFields: [
              {
                inputFieldName: "AddressProofType",
                inputFieldValue: this.state.STAddressProofType_radio,
                inputFieldtype: "general",
              },
              {
                inputFieldName: "Nature",
                inputFieldValue: this.state.STbusinessNature,
                inputFieldtype: "general",
              },
            ],
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            this.showNotification(res.data.message);
            this.getserviceInformationApi();
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else if (res.data.code == "201" && res.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          } else {
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => { });
    }
  };
  backbutton = () => {
    this.props.history.push({
      pathname: `/myuser/${Decuserid}/userservices`,
    });
  };




  handletimeline = () => {


    $("#timeline_btnloader").show();
    axios.post(
      process.env.REACT_APP_API_BASE_URL + "p=timeLine",
      {
        agentId: Decagentid,
        userId: Decuserid,
        serviceId: Decserviceid,
      },
      {
        headers: {
          Authkey: process.env.REACT_APP_API_KEY,
          "App-Token-Access": Decjwttoken,
        },
        auth: {
          username: process.env.REACT_APP_API_USERNAME,
          password: process.env.REACT_APP_API_PASSWORD,
        },
      }
    )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {

          if (res.data.timeLine.length > 0) {
            let length = res.data.timeLine.length;
            let ftimeline = res.data.timeLine.filter((items, index) => {
              return (index <= this.state.timelcount)
            })
            this.setState({
              filtertimel: ftimeline,
              timelcount: this.state.timelcount + 3,
              Timeline: res.data.timeLine,
              Timelinedata: true,
            });
            this.setState({

            });
            // .splice(this.state.timelcount,length)
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
  }

  handlemoretimeline = () => {

    let ftimeline = this.state.Timeline.filter((items, index) => {
      return (index <= this.state.timelcount)
    })
    this.setState({
      filtertimel: ftimeline,
      timelcount: this.state.timelcount + 3
    });

  }




  render() {
    if (
      localStorage.getItem("gglltakoinoeenl") ==
      process.env.REACT_APP_LOGIN_KEY &&
      localStorage.getItem("toeljgtkewlna") != null &&
      localStorage.getItem("qazxswedcvfrtgb") != null
    ) {
      return (
        <div className="cont">
          <div id="notifContainer"></div>
          <Helmet>
            <link
              rel="stylesheet"
              href={process.env.PUBLIC_URL + "/css/select2.css"}
            />
          </Helmet>

          <Header />

          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
            className="sidebaropenicon"
          />
          <img
            onClick={this.backbutton}
            src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
            className="sidebarbackicon"
          />
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"}
            className="sidebarexpandicon"
          />

          <div className="overlay1"></div>
          <div className="sidebar sidebarshift">
            <div className="side_div_2 sidebar_txt active">
              <Link to="/dashboard">Overview</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <Link to="/myservices">My Services</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <Link to="/paymentinvoice">Payments Invoices</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <Link to="/documentrecords">Documents Record</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <Link to="/addservice">Add service</Link>
            </div>
            <div></div>
          </div>
          <div className="arrow-left2 visibilityhidden"></div>
          <div className="white_div expandwhite_div">
            <div className="serviceoverlay">
              <div className="serviceoverlayinside">
                <center>
                  <img
                    src={process.env.PUBLIC_URL + "/img/loader.gif"}
                    className="serviceloader"
                  />
                </center>
              </div>
            </div>
            <div className="white_box">
            {this.state.referralPartner ? 
                <div className="uploaddocu">
                  <div className="row">
                    <div className="myservicesortbyfilterbystatus mt-1 ml-1"></div>
                    <div className="col-lg-9 mt-3">
                      <div className="row">
                        <div className="col-md-6">
                        <div className="inner-header d-flex justify-content-between">
                          <div className="slider-navigation-tab">
                            <header className="tabs-nav position-relative">
                              <ul className="d-flex slider-navigation-tab-ul">
                                <li
                                  className="active slider-navigation-tab-li"
                                  id="activeTab"
                                >
                                  <a href="#tab1" className="px-2 py-1">
                                    Information given
                                  </a>
                                </li>
                                <li
                                  className="slider-navigation-tab-li"
                                  id="completedTab"
                                >
                                  <a href="#tab2" className="px-2 py-1">
                                    Deliverables
                                  </a>
                                </li>
                                <hr className="slider-navigation-tab-vertical-line" />
                                <div className="sliderpage-slider"></div>
                              </ul>
                            </header>
                          </div>
                        </div>
                          {/* <p className="uploadtext">Upload Information :-</p> */}
                        </div>
                        <div className="col-md-6 selectcateselectpadding">
                          <select
                            className="selectcateselect"
                            value={this.state.dropDownValue}
                            onChange={this.handledropDownValueChange}
                          >
                            <option
                              value="volvo"
                              id="volvo"
                              className="selectcateselectoption2"
                            >
                              Select category...
                            </option>
                            <option
                              value="FL_SolePI_Documents"
                              className="selectcateselectoption"
                            >
                              Sole Proprietorship / Individual
                            </option>
                            <option
                              value="FL_P_LLP_Documents"
                              className="selectcateselectoption"
                            >
                              Partnership / LLP
                            </option>
                            <option
                              value="FL_PRL_PL_OP_Documents"
                              className="selectcateselectoption"
                            >
                              Private limited / Public limited / One person
                              company
                            </option>
                            <option
                              value="FL_HUF_Documents"
                              className="selectcateselectoption"
                            >
                              HUF
                            </option>
                            <option
                              value="FL_STC_Documents"
                              className="selectcateselectoption"
                            >
                              Society / Trust / Club
                            </option>
                          </select>
                        </div>
                      </div>
                      </div>
                </div>

                      <div className="tabs-content">
                  <div id="tab1" className="tab-content">
                    {this.state.licenseType == "T" ? (
                      <div className="row">
                        <div className="myservicesortbyfilterbystatus mt-1 ml-1"></div>
                        <div className="col-lg-9 ">

                      {/* None */}
                      <center id="FoodLicense_default_Img">
                        <img
                          src={process.env.PUBLIC_URL + "/img/Group_3582.svg"}
                          className="selectcategoryimg"
                        />
                      </center>

                      {/* Sole P/I */}
                      <div id="FoodLicense_SolePI">
                        <div className="uploadscrolldiv">
                          <div className="uploadscrollcardinside">
                            {/* SPic */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Passport size Photograph of the proprietor
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/PassportSizePhoto.jpeg"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="SPicseecommentsbtn"
                                        onClick={this.handleSPicseecommentsbtn}
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="SPichidecommentsbtn"
                                        onClick={this.handleSPichidecommentsbtn}
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_3"
                                        className="choose_btn"
                                        id="choose_SPic"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_3"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleSPicChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_SPic"
                                        onClick={(e) => this.removeSPic(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_SPic"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__SPic"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.SPicDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_SPic"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* SAadhar */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Aadhar Card/Voter ID/ Passport/Pan Card/
                                      Driving License
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr"></p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/IDProof.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="SAadharseecommentsbtn"
                                        onClick={
                                          this.handleSAadharseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="SAadharhidecommentsbtn"
                                        onClick={
                                          this.handleSAadharhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_1"
                                        className="choose_btn"
                                        id="choose_SAadhar"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={"/img/Path_15188.svg"}
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_1"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleSAadharChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_SAadhar"
                                        onClick={(e) => this.removeSAadhar(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_SAadhar"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn_SAadhar"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.SAadharDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_SAadhar"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* SPan */}

                            {/* Nature */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <p className="uploadnormaltext">
                                  Nature of your Business / Brand:-
                                  <sup className="requireddocstar">*</sup>
                                </p>
                                <textarea
                                  className="uploadtextarea"
                                  maxLength={200}
                                  placeholder="Ex:- Kirana Shop / Shoes Brand / Hardware company..."
                                  value={this.state.SbusinessNature}
                                  onChange={this.handleSbusinessNatureChange}
                                ></textarea>
                              </div>
                            </div>
                            {/* SBP */}

                            {/* SGumasta */}

                            {/* STradeLicence */}

                            {/* SAddressProofType */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <p className="uploadnormaltext">
                                  Office address proof:
                                  <sup className="requireddocstar">*</sup>
                                </p>

                                <input
                                  type="radio"
                                  id="test11-11"
                                  name="radio-group11"
                                  value="CopyOfRegistry"
                                  checked={
                                    this.state.SAddressProofType_radio ===
                                    "CopyOfRegistry"
                                  }
                                  onChange={this.SAddressProofType_radioChange}
                                />
                                <label
                                  htmlFor="test11-11"
                                  className="uploadradiotext"
                                >
                                  Own office
                                </label>
                                <br />
                                <input
                                  type="radio"
                                  id="test12-22"
                                  name="radio-group11"
                                  value="RentAgreement"
                                  checked={
                                    this.state.SAddressProofType_radio ===
                                    "RentAgreement"
                                  }
                                  onChange={this.SAddressProofType_radioChange}
                                />
                                <label
                                  htmlFor="test12-22"
                                  className="uploadradiotext"
                                >
                                  Rented office
                                </label>
                              </div>
                            </div>

                            {/* SCopyOfRegistry */}

                            {/* SRentAgreement */}
                            <div className="displayflex RentAgreement lastdisplayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol borderHtao">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Rental Agreement / NOC from Any Relative
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/RentAgreement.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="SRentAgreementseecommentsbtn"
                                        onClick={
                                          this
                                            .handleSRentAgreementseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="SRentAgreementhidecommentsbtn"
                                        onClick={
                                          this
                                            .handleSRentAgreementhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_8"
                                        className="choose_btn"
                                        id="choose_SRentAgreement"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_8"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleSRentAgreementChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_SRentAgreement"
                                        onClick={(e) =>
                                          this.removeSRentAgreement(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_SRentAgreement"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__SRentAgreement"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.SRentAgreementDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_SRentAgreement"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* SCopyOfRegistry */}
                            <div className="displayflex lastdisplayflex CopyOfRegistry">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploadlastdatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Registry or Ownership Deed
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">
                                      (in the name of owner)
                                    </p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/CopyOfRegistry.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="SCopyOfRegistryseecommentsbtn"
                                        onClick={
                                          this
                                            .handleSCopyOfRegistryseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="SCopyOfRegistryhidecommentsbtn"
                                        onClick={
                                          this
                                            .handleSCopyOfRegistryhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_9"
                                        className="choose_btn"
                                        id="choose_SCopyOfRegistry"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_9"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleSCopyOfRegistryChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_SCopyOfRegistry"
                                        onClick={(e) =>
                                          this.removeSCopyOfRegistry(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_SCopyOfRegistry"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__SCopyOfRegistry"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.SCopyOfRegistryDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_SCopyOfRegistry"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* SAdditional */}
                            <div id="SadditionalDocumentDiv">
                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadadditionalsidetext visibilityhidden">
                                    1&#41;
                                  </p>
                                </div>
                                <div className="uploadadditionaldatacol">
                                  <p className="uploadadditionalnormaltext">
                                    Additional documents:-
                                  </p>
                                </div>
                              </div>
                              {this.SadditionalFilesArea()}

                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadadditionalsidetext visibilityhidden">
                                    1&#41;
                                  </p>
                                </div>
                                <div className="uploadadditionaldatacol">
                                  <button
                                    className="adddocumentbtn"
                                    id="SAdddocumentbtn"
                                    onClick={() =>
                                      this.handleAddSFileBtn(
                                        SadditionalFilesIndex
                                      )
                                    }
                                  >
                                    Add document{" "}
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path 19524.svg"
                                      }
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <center>
                          <button
                            className="proceednowbtn proceednowbtn1"
                            onClick={this.handleSProceedBtn}
                          >
                            Proceed now{" "}
                            <img
                              alt="loading..."
                              src={process.env.PUBLIC_URL + "/img/tick.svg"}
                            />
                          </button>
                        </center>
                      </div>
                      {/* Partnership */}
                      <div id="FoodLicense_P_LLP">
                        <div className="uploadscrolldiv">
                          <div className="uploadscrollcardinside">
                            {/* PPic1 */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Passport size Photograph (Partner 1)
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/PassportSizePhoto.jpeg"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PPic1seecommentsbtn"
                                        onClick={this.handlePPic1seecommentsbtn}
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PPic1hidecommentsbtn"
                                        onClick={
                                          this.handlePPic1hidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_14"
                                        className="choose_btn"
                                        id="choose_PPic1"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_14"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePPic1Change(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PPic1"
                                        onClick={(e) => this.removePPic1(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PPic1"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PPic1"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PPic1DocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PPic1"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* PPic2 */}

                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Passport size Photograph (Partner 2)
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/PassportSizePhoto.jpeg"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PPic2seecommentsbtn"
                                        onClick={this.handlePPic2seecommentsbtn}
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PPic2hidecommentsbtn"
                                        onClick={
                                          this.handlePPic2hidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_15"
                                        className="choose_btn"
                                        id="choose_PPic2"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_15"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePPic2Change(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PPic2"
                                        onClick={(e) => this.removePPic2(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PPic2"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PPic2"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PPic2DocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PPic2"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* PAadhar1 */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Aadhar Card/ Voter ID/ Passport/ Pan Card/
                                      Driving License (Partner 1)
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr"></p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/IDProof.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PAadhar1seecommentsbtn"
                                        onClick={
                                          this.handlePAadhar1seecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PAadhar1hidecommentsbtn"
                                        onClick={
                                          this.handlePAadhar1hidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_10"
                                        className="choose_btn"
                                        id="choose_PAadhar1"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_10"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePAadhar1Change(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PAadhar1"
                                        onClick={(e) => this.removePAadhar1(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PAadhar1"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PAadhar1"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PAadhar1DocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PAadhar1"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* PAadhar2 */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Aadhar Card/ Voter ID/ Passport/ Pan Card/
                                      Driving License (Partner 2)
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr"></p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/IDproof.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PAadhar2seecommentsbtn"
                                        onClick={
                                          this.handlePAadhar2seecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PAadhar2hidecommentsbtn"
                                        onClick={
                                          this.handlePAadhar2hidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_11"
                                        className="choose_btn"
                                        id="choose_PAadhar2"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_11"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePAadhar2Change(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PAadhar2"
                                        onClick={(e) => this.removePAadhar2(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PAadhar2"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PAadhar2"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PAadhar2DocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PAadhar2"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* PPan1 */}

                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Partnership Deed
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/PartnershipDeed.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PPan1seecommentsbtn"
                                        onClick={this.handlePPan1seecommentsbtn}
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PPan1hidecommentsbtn"
                                        onClick={
                                          this.handlePPan1hidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_12"
                                        className="choose_btn"
                                        id="choose_PPan1"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_12"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePPan1Change(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PPan1"
                                        onClick={(e) => this.removePPan1(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PPan1"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PPan1"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PPan1DocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PPan1"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* Nature */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <p className="uploadnormaltext">
                                  Nature of your Business / Brand:-
                                  <sup className="requireddocstar">*</sup>
                                </p>
                                <textarea
                                  maxLength={200}
                                  className="uploadtextarea"
                                  placeholder="Ex:- Kirana Shop / Shoes Brand / Hardware company..."
                                  value={this.state.PPbusinessNature}
                                  onChange={this.handlePPbusinessNatureChange}
                                ></textarea>
                              </div>
                            </div>

                            {/* PPan2 */}

                            {/* PBP */}
                            {/* PAddressProofType */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <p className="uploadnormaltext">
                                  Office address proof:
                                  <sup className="requireddocstar">*</sup>
                                </p>

                                <input
                                  type="radio"
                                  id="test22-11"
                                  name="radio-group12"
                                  value="CopyOfRegistry"
                                  checked={
                                    this.state.PAddressProofType_radio ===
                                    "CopyOfRegistry"
                                  }
                                  onChange={this.PAddressProofType_radioChange}
                                />
                                <label
                                  htmlFor="test22-11"
                                  className="uploadradiotext"
                                >
                                  Own office
                                </label>
                                <br />
                                <input
                                  type="radio"
                                  id="test22-22"
                                  name="radio-group12"
                                  value="RentAgreement"
                                  checked={
                                    this.state.PAddressProofType_radio ===
                                    "RentAgreement"
                                  }
                                  onChange={this.PAddressProofType_radioChange}
                                />
                                <label
                                  htmlFor="test22-22"
                                  className="uploadradiotext"
                                >
                                  Rented office
                                </label>
                              </div>
                            </div>

                            {/* PCopyOfRegistry */}

                            {/* PRentAgreement */}
                            <div className="displayflex lastdisplayflex RentAgreement">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol borderHtao">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Rental agreement
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/RentAgreement.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PRentAgreementseecommentsbtn"
                                        onClick={
                                          this
                                            .handlePRentAgreementseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PRentAgreementhidecommentsbtn"
                                        onClick={
                                          this
                                            .handlePRentAgreementhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_20"
                                        className="choose_btn"
                                        id="choose_PRentAgreement"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_20"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePRentAgreementChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PRentAgreement"
                                        onClick={(e) =>
                                          this.removePRentAgreement(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PRentAgreement"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PRentAgreement"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PRentAgreementDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PRentAgreement"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* PCopyOfRegistry */}
                            <div className="displayflex lastdisplayflex CopyOfRegistry">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploadlastdatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Registry or Ownership Deed in the name of
                                      Partnership firm
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">
                                      (in the name of owner)
                                    </p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/CopyOfRegistry.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PCopyOfRegistryseecommentsbtn"
                                        onClick={
                                          this
                                            .handlePCopyOfRegistryseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PCopyOfRegistryhidecommentsbtn"
                                        onClick={
                                          this
                                            .handlePCopyOfRegistryhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_21"
                                        className="choose_btn"
                                        id="choose_PCopyOfRegistry"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_21"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePCopyOfRegistryChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PCopyOfRegistry"
                                        onClick={(e) =>
                                          this.removePCopyOfRegistry(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PCopyOfRegistry"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PCopyOfRegistry"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PCopyOfRegistryDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PCopyOfRegistry"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* PAdditional */}
                            <div id="PadditionalDocumentDiv">
                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadadditionalsidetext visibilityhidden">
                                    1&#41;
                                  </p>
                                </div>
                                <div className="uploadadditionaldatacol">
                                  <p className="uploadadditionalnormaltext">
                                    Additional documents:-
                                  </p>
                                </div>
                              </div>
                              {this.PadditionalFilesArea()}

                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadadditionalsidetext visibilityhidden">
                                    1&#41;
                                  </p>
                                </div>
                                <div className="uploadadditionaldatacol">
                                  <button
                                    className="adddocumentbtn"
                                    id="PAdddocumentbtn"
                                    onClick={() =>
                                      this.handleAddPFileBtn(
                                        PadditionalFilesIndex
                                      )
                                    }
                                  >
                                    Add document{" "}
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path 19524.svg"
                                      }
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <center>
                          <button
                            className="proceednowbtn proceednowbtn2"
                            onClick={this.handlePProceedBtn}
                          >
                            Proceed now{" "}
                            <img
                              alt="loading..."
                              src={process.env.PUBLIC_URL + "/img/tick.svg"}
                            />
                          </button>
                        </center>
                      </div>

                      {/* Private limited */}
                      <div id="FoodLicense_PL">
                        <div className="uploadscrolldiv">
                          <div className="uploadscrollcardinside">
                            {/* PLPic1 */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Passport size Photograph (Director 1)
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/PassportSizePhoto.jpeg"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PLPic1seecommentsbtn"
                                        onClick={
                                          this.handlePLPic1seecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PLPic1hidecommentsbtn"
                                        onClick={
                                          this.handlePLPic1hidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_26"
                                        className="choose_btn"
                                        id="choose_PLPic1"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_26"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePLPic1Change(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PLPic1"
                                        onClick={(e) => this.removePLPic1(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PLPic1"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PLPic1"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PLPic1DocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PLPic1"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* PLPic2 */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Passport size Photograph (Director 2)
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/PassportSizePhoto.jpeg"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PLPic2seecommentsbtn"
                                        onClick={
                                          this.handlePLPic2seecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PLPic2hidecommentsbtn"
                                        onClick={
                                          this.handlePLPic2hidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_27"
                                        className="choose_btn"
                                        id="choose_PLPic2"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_27"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePLPic2Change(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PLPic2"
                                        onClick={(e) => this.removePLPic2(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PLPic2"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PLPic2"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PLPic2DocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PLPic2"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* PLAadhar1 */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Aadhaar card (Director 1)
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">
                                      Front and back both side
                                    </p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/AadhaarCard.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PLAadhar1seecommentsbtn"
                                        onClick={
                                          this.handlePLAadhar1seecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PLAadhar1hidecommentsbtn"
                                        onClick={
                                          this.handlePLAadhar1hidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_22"
                                        className="choose_btn"
                                        id="choose_PLAadhar1"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_22"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePLAadhar1Change(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PLAadhar1"
                                        onClick={(e) => this.removePLAadhar1(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PLAadhar1"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PLAadhar1"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PLAadhar1DocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PLAadhar1"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* PLAadhar2 */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Aadhaar card (Director 2)
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">
                                      Front and back both side
                                    </p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/AadhaarCard.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PLAadhar2seecommentsbtn"
                                        onClick={
                                          this.handlePLAadhar2seecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PLAadhar2hidecommentsbtn"
                                        onClick={
                                          this.handlePLAadhar2hidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_23"
                                        className="choose_btn"
                                        id="choose_PLAadhar2"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_23"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePLAadhar2Change(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PLAadhar2"
                                        onClick={(e) => this.removePLAadhar2(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PLAadhar2"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PLAadhar2"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PLAadhar2DocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PLAadhar2"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* PLPan3 */}

                            {/* PLCI */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Certificate of Incorporation
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/CertificateOfIncorporation.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PLCIseecommentsbtn"
                                        onClick={this.handlePLCIseecommentsbtn}
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PLCIhidecommentsbtn"
                                        onClick={this.handlePLCIhidecommentsbtn}
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_29"
                                        className="choose_btn"
                                        id="choose_PLCI"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_29"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePLCIChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PLCI"
                                        onClick={(e) => this.removePLCI(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PLCI"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PLCI"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PLCIDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PLCI"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* PLMOA */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      MOA
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/MOA.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PLMOAseecommentsbtn"
                                        onClick={this.handlePLMOAseecommentsbtn}
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PLMOAhidecommentsbtn"
                                        onClick={
                                          this.handlePLMOAhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_30"
                                        className="choose_btn"
                                        id="choose_PLMOA"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_30"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePLMOAChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PLMOA"
                                        onClick={(e) => this.removePLMOA(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PLMOA"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PLMOA"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PLMOADocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PLMOA"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* PLAOA */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      AOA
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/AOA.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PLAOAseecommentsbtn"
                                        onClick={this.handlePLAOAseecommentsbtn}
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PLAOAhidecommentsbtn"
                                        onClick={
                                          this.handlePLAOAhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_31"
                                        className="choose_btn"
                                        id="choose_PLAOA"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_31"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePLAOAChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PLAOA"
                                        onClick={(e) => this.removePLAOA(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PLAOA"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PLAOA"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PLAOADocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PLAOA"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* PLNature */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <p className="uploadnormaltext">
                                  Nature of your Business / Brand:-
                                  <sup className="requireddocstar">*</sup>
                                </p>
                                <textarea
                                  className="uploadtextarea"
                                  maxLength={200}
                                  placeholder="Ex:- Kirana Shop / Shoes Brand / Hardware company..."
                                  value={this.state.PLbusinessNature}
                                  onChange={this.handlePLbusinessNatureChange}
                                ></textarea>
                              </div>
                            </div>
                            {/* PLBR */}

                            {/* PLAddressProofType */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <p className="uploadnormaltext">
                                  Office address proof:
                                  <sup className="requireddocstar">*</sup>
                                </p>

                                <input
                                  type="radio"
                                  id="test3-1"
                                  name="radio-group3"
                                  value="CopyOfRegistry"
                                  checked={
                                    this.state.PLAddressProofType_radio ===
                                    "CopyOfRegistry"
                                  }
                                  onChange={this.PLAddressProofType_radioChange}
                                />
                                <label
                                  htmlFor="test3-1"
                                  className="uploadradiotext"
                                >
                                  Own office
                                </label>
                                <br />
                                <input
                                  type="radio"
                                  id="test3-2"
                                  name="radio-group3"
                                  value="RentAgreement"
                                  checked={
                                    this.state.PLAddressProofType_radio ===
                                    "RentAgreement"
                                  }
                                  onChange={this.PLAddressProofType_radioChange}
                                />
                                <label
                                  htmlFor="test3-2"
                                  className="uploadradiotext"
                                >
                                  Rented office
                                </label>
                              </div>
                            </div>

                            {/* PLCopyOfRegistry */}

                            {/* PLRentAgreement */}
                            <div className="displayflex lastdisplayflex RentAgreement">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol borderHtao">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Rental agreement
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/RentAgreement.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PLRentAgreementseecommentsbtn"
                                        onClick={
                                          this
                                            .handlePLRentAgreementseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PLRentAgreementhidecommentsbtn"
                                        onClick={
                                          this
                                            .handlePLRentAgreementhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_37"
                                        className="choose_btn"
                                        id="choose_PLRentAgreement"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_37"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePLRentAgreementChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PLRentAgreement"
                                        onClick={(e) =>
                                          this.removePLRentAgreement(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PLRentAgreement"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PLRentAgreement"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PLRentAgreementDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PLRentAgreement"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* PLCopyOfRegistry */}
                            <div className="displayflex lastdisplayflex CopyOfRegistry">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploadlastdatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Registry or Ownership Deed of Company
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">
                                      (in the name of owner)
                                    </p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/CopyOfRegistry.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PLCopyOfRegistryseecommentsbtn"
                                        onClick={
                                          this
                                            .handlePLCopyOfRegistryseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PLCopyOfRegistryhidecommentsbtn"
                                        onClick={
                                          this
                                            .handlePLCopyOfRegistryhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_38"
                                        className="choose_btn"
                                        id="choose_PLCopyOfRegistry"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_38"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePLCopyOfRegistryChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PLCopyOfRegistry"
                                        onClick={(e) =>
                                          this.removePLCopyOfRegistry(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PLCopyOfRegistry"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PLCopyOfRegistry"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={
                                          this.state.PLCopyOfRegistryDocLink
                                        }
                                        className="viewDocLink"
                                        id="viewDocLink_PLCopyOfRegistry"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* PLAdditional */}
                            <div id="PLadditionalDocumentDiv">
                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadadditionalsidetext visibilityhidden">
                                    1&#41;
                                  </p>
                                </div>
                                <div className="uploadadditionaldatacol">
                                  <p className="uploadadditionalnormaltext">
                                    Additional documents:-
                                  </p>
                                </div>
                              </div>
                              {this.PLadditionalFilesArea()}

                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadadditionalsidetext visibilityhidden">
                                    1&#41;
                                  </p>
                                </div>
                                <div className="uploadadditionaldatacol">
                                  <button
                                    className="adddocumentbtn"
                                    id="PLAdddocumentbtn"
                                    onClick={() =>
                                      this.handleAddPLFileBtn(
                                        PLadditionalFilesIndex
                                      )
                                    }
                                  >
                                    Add document{" "}
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path 19524.svg"
                                      }
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <center>
                          <button
                            className="proceednowbtn proceednowbtn3"
                            onClick={this.handlePLProceedBtn}
                          >
                            Proceed now{" "}
                            <img
                              alt="loading..."
                              src={process.env.PUBLIC_URL + "/img/tick.svg"}
                            />
                          </button>
                        </center>
                      </div>

                      {/* HUF */}
                      <div id="FoodLicense_HUF">
                        <div className="uploadscrolldiv">
                          <div className="uploadscrollcardinside">
                            {/* HUFPic */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Passport size Photo of Applicant
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/PassportSizePhoto.jpeg"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="HUFPicseecommentsbtn"
                                        onClick={
                                          this.handleHUFPicseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="HUFPichidecommentsbtn"
                                        onClick={
                                          this.handleHUFPichidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_41"
                                        className="choose_btn"
                                        id="choose_HUFPic"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_41"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleHUFPicChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_HUFPic"
                                        onClick={(e) => this.removeHUFPic(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_HUFPic"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__HUFPic"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.HUFPicDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_HUFPic"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* HUFAadhar */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Aadhar Card/Voter ID/ Passport/Pan Card/
                                      Driving License of karta
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">
                                      Front and back both side
                                    </p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/IDProof.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="HUFAadharseecommentsbtn"
                                        onClick={
                                          this.handleHUFAadharseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="HUFAadharhidecommentsbtn"
                                        onClick={
                                          this.handleHUFAadharhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_390"
                                        className="choose_btn"
                                        id="choose_HUFAadhar"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_390"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleHUFAadharChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_HUFAadhar"
                                        onClick={(e) => this.removeHUFAadhar(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_HUFAadhar"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__HUFAadhar"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.HUFAadharDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_HUFAadhar"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* HUFPan1 */}

                            {/* HUFPan2 */}

                            {/* HUFDeed */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      HUF Deed Document
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/HUFDeedDocument.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="HUFDeedseecommentsbtn"
                                        onClick={
                                          this.handleHUFDeedseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="HUFDeedhidecommentsbtn"
                                        onClick={
                                          this.handleHUFDeedhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_43"
                                        className="choose_btn"
                                        id="choose_HUFDeed"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_43"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleHUFDeedChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_HUFDeed"
                                        onClick={(e) => this.removeHUFDeed(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_HUFDeed"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__HUFDeed"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.HUFDeedDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_HUFDeed"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* HUFBP */}

                            {/* Nature */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <p className="uploadnormaltext">
                                  Nature of your Business / Brand:-
                                  <sup className="requireddocstar">*</sup>
                                </p>
                                <textarea
                                  className="uploadtextarea"
                                  maxLength={200}
                                  placeholder="Ex:- Kirana Shop / Shoes Brand / Hardware company..."
                                  value={this.state.HUFbusinessNature}
                                  onChange={this.handleHUFbusinessNatureChange}
                                ></textarea>
                              </div>
                            </div>

                            {/* HUFAddressProofType */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <p className="uploadnormaltext">
                                  Office address proof:
                                  <sup className="requireddocstar">*</sup>
                                </p>

                                <input
                                  type="radio"
                                  id="test44-14"
                                  name="radio-group14"
                                  value="CopyOfRegistry"
                                  checked={
                                    this.state.HUFAddressProofType_radio ===
                                    "CopyOfRegistry"
                                  }
                                  onChange={
                                    this.HUFAddressProofType_radioChange
                                  }
                                />
                                <label
                                  htmlFor="test44-14"
                                  className="uploadradiotext"
                                >
                                  Own office
                                </label>
                                <br />
                                <input
                                  type="radio"
                                  id="test44-24"
                                  name="radio-group14"
                                  value="RentAgreement"
                                  checked={
                                    this.state.HUFAddressProofType_radio ===
                                    "RentAgreement"
                                  }
                                  onChange={
                                    this.HUFAddressProofType_radioChange
                                  }
                                />
                                <label
                                  htmlFor="test44-24"
                                  className="uploadradiotext"
                                >
                                  Rented office
                                </label>
                              </div>
                            </div>

                            {/* HUFCopyOfRegistry */}
                            <div className="displayflex CopyOfRegistry">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Copy of Registry
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/CopyOfRegistry.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="HUFCopyOfRegistryseecommentsbtn"
                                        onClick={
                                          this
                                            .handleHUFCopyOfRegistryseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="HUFCopyOfRegistryhidecommentsbtn"
                                        onClick={
                                          this
                                            .handleHUFCopyOfRegistryhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_470"
                                        className="choose_btn"
                                        id="choose_HUFCopyOfRegistry"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_470"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleHUFCopyOfRegistryChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_HUFCopyOfRegistry"
                                        onClick={(e) =>
                                          this.removeHUFCopyOfRegistry(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_HUFCopyOfRegistry"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__HUFCopyOfRegistry"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={
                                          this.state.HUFCopyOfRegistryDocLink
                                        }
                                        className="viewDocLink"
                                        id="viewDocLink_HUFCopyOfRegistry"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* HUFRentAgreement */}
                            <div className="displayflex RentAgreement">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Rent agreement
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/RentAgreement.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="HUFRentAgreementseecommentsbtn"
                                        onClick={
                                          this
                                            .handleHUFRentAgreementseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="HUFRentAgreementhidecommentsbtn"
                                        onClick={
                                          this
                                            .handleHUFRentAgreementhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_480"
                                        className="choose_btn"
                                        id="choose_HUFRentAgreement"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_480"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleHUFRentAgreementChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_HUFRentAgreement"
                                        onClick={(e) =>
                                          this.removeHUFRentAgreement(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_HUFRentAgreement"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__HUFRentAgreement"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={
                                          this.state.HUFRentAgreementDocLink
                                        }
                                        className="viewDocLink"
                                        id="viewDocLink_HUFRentAgreement"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* HUFPR */}

                            {/* HUFAdditional */}
                            <div id="HUFadditionalDocumentDiv">
                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadadditionalsidetext visibilityhidden">
                                    1&#41;
                                  </p>
                                </div>
                                <div className="uploadadditionaldatacol">
                                  <p className="uploadadditionalnormaltext">
                                    Additional documents:-
                                  </p>
                                </div>
                              </div>
                              {this.HUFadditionalFilesArea()}

                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadadditionalsidetext visibilityhidden">
                                    1&#41;
                                  </p>
                                </div>
                                <div className="uploadadditionaldatacol">
                                  <button
                                    className="adddocumentbtn"
                                    id="HUFAdddocumentbtn"
                                    onClick={() =>
                                      this.handleAddHUFFileBtn(
                                        HUFadditionalFilesIndex
                                      )
                                    }
                                  >
                                    Add document{" "}
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path 19524.svg"
                                      }
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <center>
                          <button
                            className="proceednowbtn proceednowbtn4"
                            onClick={this.handleHUFProceedBtn}
                          >
                            Proceed now{" "}
                            <img
                              alt="loading..."
                              src={process.env.PUBLIC_URL + "/img/tick.svg"}
                            />
                          </button>
                        </center>
                      </div>
                      {/* Society/ trust/ club */}
                      <div id="FoodLicense_Society">
                        <div className="uploadscrolldiv">
                          <div className="uploadscrollcardinside">
                            {/* STPic1 */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Passport size Photo (President or
                                      Secretary) (1)
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/PassportSizePhoto.jpeg"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="STPic1seecommentsbtn"
                                        onClick={
                                          this.handleSTPic1seecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="STPic1hidecommentsbtn"
                                        onClick={
                                          this.handleSTPic1hidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_54"
                                        className="choose_btn"
                                        id="choose_STPic1"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_54"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleSTPic1Change(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_STPic1"
                                        onClick={(e) => this.removeSTPic1(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_STPic1"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__STPic1"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.STPic1DocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_STPic1"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* STPic2 */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Passport size Photo (President or
                                      Secretary) (2)
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/PassportSizePhoto.jpeg"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="STPic2seecommentsbtn"
                                        onClick={
                                          this.handleSTPic2seecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="STPic2hidecommentsbtn"
                                        onClick={
                                          this.handleSTPic2hidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_55"
                                        className="choose_btn"
                                        id="choose_STPic2"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_55"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleSTPic2Change(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_STPic2"
                                        onClick={(e) => this.removeSTPic2(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_STPic2"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__STPic2"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.STPic2DocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_STPic2"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* STAadhar1 */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Aadhar Card/ Voter ID/ Passport/ Pan Card/
                                      Driving License (President or Secretary)
                                      (1)
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">
                                      Front and back both side
                                    </p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/IDProof.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="STAadhar1seecommentsbtn"
                                        onClick={
                                          this.handleSTAadhar1seecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="STAadhar1hidecommentsbtn"
                                        onClick={
                                          this.handleSTAadhar1hidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_50"
                                        className="choose_btn"
                                        id="choose_STAadhar1"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_50"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleSTAadhar1Change(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_STAadhar1"
                                        onClick={(e) => this.removeSTAadhar1(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_STAadhar1"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__STAadhar1"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.STAadhar1DocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_STAadhar1"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* STAadhar2 */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Aadhar Card/ Voter ID/ Passport/ Pan Card/
                                      Driving License (President or Secretary)
                                      (2)
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">
                                      Front and back both side
                                    </p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/IDProof.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="STAadhar2seecommentsbtn"
                                        onClick={
                                          this.handleSTAadhar2seecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="STAadhar2hidecommentsbtn"
                                        onClick={
                                          this.handleSTAadhar2hidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_51"
                                        className="choose_btn"
                                        id="choose_STAadhar2"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_51"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleSTAadhar2Change(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_STAadhar2"
                                        onClick={(e) => this.removeSTAadhar2(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_STAadhar2"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__STAadhar2"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.STAadhar2DocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_STAadhar2"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* STPan1 */}
                            {/* STPan2 */}

                            {/* STPan3 */}

                            {/* STCR */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Society Registration Certificate
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/CertificateOfRegistration.pdf "
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="STCRseecommentsbtn"
                                        onClick={this.handleSTCRseecommentsbtn}
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="STCRhidecommentsbtn"
                                        onClick={this.handleSTCRhidecommentsbtn}
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_57"
                                        className="choose_btn"
                                        id="choose_STCR"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_57"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleSTCRChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_STCR"
                                        onClick={(e) => this.removeSTCR(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_STCR"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__STCR"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.STCRDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_STCR"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* Nature */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <p className="uploadnormaltext">
                                  Nature of your Business / Brand:-
                                  <sup className="requireddocstar">*</sup>
                                </p>
                                <textarea
                                  className="uploadtextarea"
                                  maxLength={200}
                                  placeholder="Ex:- Kirana Shop / Shoes Brand / Hardware company..."
                                  value={this.state.STbusinessNature}
                                  onChange={this.handleSTbusinessNatureChange}
                                ></textarea>
                              </div>
                            </div>
                            {/* STBP */}

                            {/* STBR */}

                            {/* STGumasta */}

                            {/* STTradeLicence */}

                            {/* STAddressProofType */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <p className="uploadnormaltext">
                                  Office address proof:
                                  <sup className="requireddocstar">*</sup>
                                </p>
                                <input
                                  type="radio"
                                  id="test55-15"
                                  name="radio-group15"
                                  value="CopyOfRegistry"
                                  checked={
                                    this.state.STAddressProofType_radio ===
                                    "CopyOfRegistry"
                                  }
                                  onChange={this.STAddressProofType_radioChange}
                                />
                                <label
                                  htmlFor="test55-15"
                                  className="uploadradiotext"
                                >
                                  Own office
                                </label>
                                <br />
                                <input
                                  type="radio"
                                  id="test55-25"
                                  name="radio-group15"
                                  value="RentAgreement"
                                  checked={
                                    this.state.STAddressProofType_radio ===
                                    "RentAgreement"
                                  }
                                  onChange={this.STAddressProofType_radioChange}
                                />
                                <label
                                  htmlFor="test55-25"
                                  className="uploadradiotext"
                                >
                                  Rented office
                                </label>
                              </div>
                            </div>

                            {/* STCopyOfRegistry */}
                            <div className="displayflex CopyOfRegistry">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Copy of Registry
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/CopyOfRegistry.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="STCopyOfRegistryseecommentsbtn"
                                        onClick={
                                          this
                                            .handleSTCopyOfRegistryseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="STCopyOfRegistryhidecommentsbtn"
                                        onClick={
                                          this
                                            .handleSTCopyOfRegistryhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_62"
                                        className="choose_btn"
                                        id="choose_STCopyOfRegistry"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_62"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleSTCopyOfRegistryChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_STCopyOfRegistry"
                                        onClick={(e) =>
                                          this.removeSTCopyOfRegistry(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_STCopyOfRegistry"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__STCopyOfRegistry"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={
                                          this.state.STCopyOfRegistryDocLink
                                        }
                                        className="viewDocLink"
                                        id="viewDocLink_STCopyOfRegistry"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* STRentAgreement */}
                            <div className="displayflex RentAgreement lastdisplayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Rent agreement
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/RentAgreement.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="STRentAgreementseecommentsbtn"
                                        onClick={
                                          this
                                            .handleSTRentAgreementseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="STRentAgreementhidecommentsbtn"
                                        onClick={
                                          this
                                            .handleSTRentAgreementhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_63"
                                        className="choose_btn"
                                        id="choose_STRentAgreement"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_63"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleSTRentAgreementChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_STRentAgreement"
                                        onClick={(e) =>
                                          this.removeSTRentAgreement(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_STRentAgreement"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__STRentAgreement"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.STRentAgreementDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_STRentAgreement"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* STPR */}

                            {/* STAdditional */}
                            <div id="STadditionalDocumentDiv">
                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadadditionalsidetext visibilityhidden">
                                    1&#41;
                                  </p>
                                </div>
                                <div className="uploadadditionaldatacol">
                                  <p className="uploadadditionalnormaltext">
                                    Additional documents:-
                                  </p>
                                </div>
                              </div>
                              {this.STadditionalFilesArea()}

                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadadditionalsidetext visibilityhidden">
                                    1&#41;
                                  </p>
                                </div>
                                <div className="uploadadditionaldatacol">
                                  <button
                                    className="adddocumentbtn"
                                    id="STAdddocumentbtn"
                                    onClick={() =>
                                      this.handleAddSTFileBtn(
                                        STadditionalFilesIndex
                                      )
                                    }
                                  >
                                    Add document{" "}
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path 19524.svg"
                                      }
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <center>
                          <button
                            className="proceednowbtn proceednowbtn5"
                            onClick={this.handleSTProceedBtn}
                          >
                            Proceed now{" "}
                            <img
                              alt="loading..."
                              src={process.env.PUBLIC_URL + "/img/tick.svg"}
                            />
                          </button>
                        </center>
                      </div>
                    </div>

                    {/* comments */}
                    <div className="col-lg-3 colbtwborder">
                      <div className="commentimg-section">
                        <SupportDetails
                          name={this.state.Name}
                          role={"Relationship Manager"}
                          contact={this.state.Contact}
                        />
                        <div className='supportDetails'>
                          <img
                            src={process.env.PUBLIC_URL + "/img/24-7_Support.png"}

                            alt="image"
                          />
                          <div>
                            <h5>{supportName}</h5>
                            <p>Agent Support Executive</p>
                            <span><b>Contact: </b> +91 {supportContact}</span>
                            <p className='mb-1'><b>Working Hours: </b>11:00 AM - 06:30 PM</p>
                          </div>
                        </div>
                        <div className="mm">
                          <center>
                            <a
                              className="video-btn"
                              data-toggle="modal"
                              data-src="https://www.youtube.com/embed/fiC-nHoFJEY"
                              data-target="#youtubeModal"
                            >
                              <img
                                src={process.env.PUBLIC_URL + "/img/play.jpg"}
                                id="play"
                                className="play_img play_img2"
                                alt="image"
                              />
                            </a>
                          </center>
                        </div>



                        <button data-toggle="modal" onClick={this.handletimeline} data-src="" data-target="#timeline" className="dashBoardBtn mt-5 newbmar" >
                          Timeline
                        </button>

                        <p class="uploadinstr mt-2 uploddoc">You can also upload documents using Whatsapp on <a
                                  href="https://web.whatsapp.com/send?phone=917772877729&amp;text=Hello%20Team%20Legal251!"
                                  target="_blank"
                                >
                                  {" "}
                                  <img
                                    src={process.env.PUBLIC_URL + "/img/call.svg"}
                                    alt="call"
                                    className="callimg"
                                  />
                                </a> +91 77728-77729</p>
                        
                      </div>
                      <div className="TDRightSide">
                        <div className="convheader">
                          <div>
                            <img
                              src={process.env.PUBLIC_URL + "/img/person.png"}
                              className="converpersonimg"
                            />
                          </div>
                          <div className="convheadercontdiv">
                            <p className="convservicename">{this.state.Name}</p>
                            <p className="convpersonname">Relationship Manager</p>
                            <p className="convuniquename">
                              Contact:{" "}
                              <span style={{ color: "#2d2d2d" }}>
                                {this.state.Contact}
                              </span>
                            </p>
                            <p className="convuniquename">
                              Service ID :{" "}
                              <span className="convuniquenamespan">
                                {Decserviceid}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="convmaindiv">
                          {this.renderCommenthistory()}
                        </div>
                      </div>
                    </div>
                  </div>
               
              ) : (
                <div className="uploaddocu">
                  <div className="row">
                    {/* <div className="myservicesortbyfilterbystatus mt-1 ml-1"></div> */}
                    <div className="col-lg-9 ">
                      <div className="row">
                        <div className="col-md-6">
                          <p className="uploadtext">Upload Information :-</p>
                        </div>
                        <div className="col-md-6 selectcateselectpadding">
                          <select
                            className="selectcateselect"
                            value={this.state.dropDownValue}
                            onChange={this.handledropDownValueChange}
                          >
                            <option
                              value="volvo"
                              id="volvo"
                              className="selectcateselectoption2"
                            >
                              Select category...
                            </option>
                            <option
                              value="FL_SolePI_Documents"
                              className="selectcateselectoption"
                            >
                              Sole Proprietorship / Individual
                            </option>
                            <option
                              value="FL_P_LLP_Documents"
                              className="selectcateselectoption"
                            >
                              Partnership / LLP
                            </option>
                            <option
                              value="FL_PRL_PL_OP_Documents"
                              className="selectcateselectoption"
                            >
                              Private limited / Public limited / One person
                              company
                            </option>
                            <option
                              value="FL_HUF_Documents"
                              className="selectcateselectoption"
                            >
                              HUF
                            </option>
                            <option
                              value="FL_STC_Documents"
                              className="selectcateselectoption"
                            >
                              Society / Trust / Club
                            </option>
                          </select>
                        </div>
                      </div>

                      {/* None */}
                      <center id="FoodLicense_default_Img">
                        <img
                          src={process.env.PUBLIC_URL + "/img/Group_3582.svg"}
                          className="selectcategoryimg"
                        />
                      </center>

                      {/* Sole P/I */}
                      <div id="FoodLicense_SolePI">
                        <div className="uploadscrolldiv">
                          <div className="uploadscrollcardinside">
                            {/* SPic */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Passport size Photograph of the proprietor
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/PassportSizePhoto.jpeg"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="SPicseecommentsbtn"
                                        onClick={this.handleSPicseecommentsbtn}
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="SPichidecommentsbtn"
                                        onClick={this.handleSPichidecommentsbtn}
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_3"
                                        className="choose_btn"
                                        id="choose_SPic"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_3"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleSPicChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_SPic"
                                        onClick={(e) => this.removeSPic(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_SPic"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__SPic"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.SPicDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_SPic"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* SAadhar */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Aadhar Card/Voter ID/ Passport/Pan Card/
                                      Driving License
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr"></p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/IDProof.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="SAadharseecommentsbtn"
                                        onClick={
                                          this.handleSAadharseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="SAadharhidecommentsbtn"
                                        onClick={
                                          this.handleSAadharhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_1"
                                        className="choose_btn"
                                        id="choose_SAadhar"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={"/img/Path_15188.svg"}
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_1"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleSAadharChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_SAadhar"
                                        onClick={(e) => this.removeSAadhar(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_SAadhar"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn_SAadhar"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.SAadharDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_SAadhar"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Nature */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <p className="uploadnormaltext">
                                  Nature of your Business / Brand:-
                                  <sup className="requireddocstar">*</sup>
                                </p>
                                <textarea
                                  className="uploadtextarea"
                                  maxLength={200}
                                  placeholder="Ex:- Kirana Shop / Shoes Brand / Hardware company..."
                                  value={this.state.SbusinessNature}
                                  onChange={this.handleSbusinessNatureChange}
                                ></textarea>
                              </div>
                            </div>
                            {/* SBP */}

                            {/* SGumasta */}

                            {/* STradeLicence */}

                            {/* SAddressProofType */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <p className="uploadnormaltext">
                                  Office address proof:
                                  <sup className="requireddocstar">*</sup>
                                </p>

                                <input
                                  type="radio"
                                  id="test11-11"
                                  name="radio-group11"
                                  value="CopyOfRegistry"
                                  checked={
                                    this.state.SAddressProofType_radio ===
                                    "CopyOfRegistry"
                                  }
                                  onChange={this.SAddressProofType_radioChange}
                                />
                                <label
                                  htmlFor="test11-11"
                                  className="uploadradiotext"
                                >
                                  Own office
                                </label>
                                <br />
                                <input
                                  type="radio"
                                  id="test12-22"
                                  name="radio-group11"
                                  value="RentAgreement"
                                  checked={
                                    this.state.SAddressProofType_radio ===
                                    "RentAgreement"
                                  }
                                  onChange={this.SAddressProofType_radioChange}
                                />
                                <label
                                  htmlFor="test12-22"
                                  className="uploadradiotext"
                                >
                                  Rented office
                                </label>
                              </div>
                            </div>

                            {/* SCopyOfRegistry */}

                            {/* SRentAgreement */}
                            <div className="displayflex RentAgreement ">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol normalBorder">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Rental Agreement / NOC from Any Relative
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/RentAgreement.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="SRentAgreementseecommentsbtn"
                                        onClick={
                                          this
                                            .handleSRentAgreementseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="SRentAgreementhidecommentsbtn"
                                        onClick={
                                          this
                                            .handleSRentAgreementhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_8"
                                        className="choose_btn"
                                        id="choose_SRentAgreement"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_8"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleSRentAgreementChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_SRentAgreement"
                                        onClick={(e) =>
                                          this.removeSRentAgreement(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_SRentAgreement"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__SRentAgreement"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.SRentAgreementDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_SRentAgreement"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* SCopyOfRegistry */}
                            <div className="displayflex  CopyOfRegistry">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploadlastdatacol normalBorder">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Registry or Ownership Deed
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">
                                      (in the name of owner)
                                    </p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/CopyOfRegistry.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="SCopyOfRegistryseecommentsbtn"
                                        onClick={
                                          this
                                            .handleSCopyOfRegistryseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="SCopyOfRegistryhidecommentsbtn"
                                        onClick={
                                          this
                                            .handleSCopyOfRegistryhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_9"
                                        className="choose_btn"
                                        id="choose_SCopyOfRegistry"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_9"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleSCopyOfRegistryChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_SCopyOfRegistry"
                                        onClick={(e) =>
                                          this.removeSCopyOfRegistry(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_SCopyOfRegistry"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__SCopyOfRegistry"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.SCopyOfRegistryDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_SCopyOfRegistry"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* SPUnit */}

                            <div className="displayflex ">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol ">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Production Unit Photograph
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/ContactSupport.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="SPUnitseecommentsbtn"
                                        onClick={
                                          this.handleSPUnitseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="SPUnithidecommentsbtn"
                                        onClick={
                                          this.handleSPUnithidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_2SPUnit"
                                        className="choose_btn"
                                        id="choose_SPUnit"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_2SPUnit"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleSPUnitChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_SPUnit"
                                        onClick={(e) => this.removeSPUnit(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_SPUnit"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__SPUnit"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.SPUnitDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_SPUnit"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* SLayoutPlan */}

                            <div className="displayflex ">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol ">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Blue Print / Layout Plan of Unit showing
                                      dimension in meters / square metre and
                                      operation wise area allocation
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/ContactSupport.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="SLayoutPlanseecommentsbtn"
                                        onClick={
                                          this.handleSLayoutPlanseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="SLayoutPlanhidecommentsbtn"
                                        onClick={
                                          this.handleSLayoutPlanhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_2SLayoutPlan"
                                        className="choose_btn"
                                        id="choose_SLayoutPlan"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_2SLayoutPlan"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleSLayoutPlanChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_SLayoutPlan"
                                        onClick={(e) =>
                                          this.removeSLayoutPlan(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_SLayoutPlan"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__SLayoutPlan"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.SLayoutPlanDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_SLayoutPlan"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* SListofEquip */}
                            <div className="displayflex ">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol ">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Name and List of Equipment and Machinery
                                      along with numbers and installed capacity
                                      also horse power used
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/ContactSupport.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="SListofEquipseecommentsbtn"
                                        onClick={
                                          this.handleSListofEquipseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="SListofEquiphidecommentsbtn"
                                        onClick={
                                          this.handleSListofEquiphidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_2SListofEquip"
                                        className="choose_btn"
                                        id="choose_SListofEquip"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_2SListofEquip"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleSListofEquipChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_SListofEquip"
                                        onClick={(e) =>
                                          this.removeSListofEquip(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_SListofEquip"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__SListofEquip"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.SListofEquipDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_SListofEquip"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* SWAReport */}
                            <div className="displayflex ">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol ">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Water Analysis Report for using as
                                      ingredient in food from a
                                      recognized/public health laboratory
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/ContactSupport.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="SWAReportseecommentsbtn"
                                        onClick={
                                          this.handleSWAReportseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="SWAReporthidecommentsbtn"
                                        onClick={
                                          this.handleSWAReporthidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_2SWAReport"
                                        className="choose_btn"
                                        id="choose_SWAReport"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_2SWAReport"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleSWAReportChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_SWAReport"
                                        onClick={(e) => this.removeSWAReport(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_SWAReport"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__SWAReport"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.SWAReportDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_SWAReport"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* SListofDir */}
                            <div className="displayflex ">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol normalBorder">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      List of All Proprietor with Full Address
                                      and Contact Details
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/ContactSupport.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="SListofDirseecommentsbtn"
                                        onClick={
                                          this.handleSListofDirseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="SListofDirhidecommentsbtn"
                                        onClick={
                                          this.handleSListofDirhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_2ListofDir"
                                        className="choose_btn"
                                        id="choose_SListofDir"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_2ListofDir"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleSListofDirChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_SListofDir"
                                        onClick={(e) =>
                                          this.removeSListofDir(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_SListofDir"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__SListofDir"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.SListofDirDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_SListofDir"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* SRecallPlan */}
                            <div className="displayflex lastdisplayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol borderHtao">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Recall Plan
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/ContactSupport.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="SRecallPlanseecommentsbtn"
                                        onClick={
                                          this.handleSRecallPlanseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="SRecallPlanhidecommentsbtn"
                                        onClick={
                                          this.handleSRecallPlanhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_2RecallPlan"
                                        className="choose_btn"
                                        id="choose_SRecallPlan"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_2RecallPlan"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleSRecallPlanChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_SRecallPlan"
                                        onClick={(e) =>
                                          this.removeSRecallPlan(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_SRecallPlan"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__SRecallPlan"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.SRecallPlanDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_SRecallPlan"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* SAdditional */}
                            <div id="SadditionalDocumentDiv">
                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadadditionalsidetext visibilityhidden">
                                    1&#41;
                                  </p>
                                </div>
                                <div className="uploadadditionaldatacol">
                                  <p className="uploadadditionalnormaltext">
                                    Additional documents:-
                                  </p>
                                </div>
                              </div>
                              {this.SadditionalFilesArea()}

                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadadditionalsidetext visibilityhidden">
                                    1&#41;
                                  </p>
                                </div>
                                <div className="uploadadditionaldatacol">
                                  <button
                                    className="adddocumentbtn"
                                    id="SAdddocumentbtn"
                                    onClick={() =>
                                      this.handleAddSFileBtn(
                                        SadditionalFilesIndex
                                      )
                                    }
                                  >
                                    Add document{" "}
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path 19524.svg"
                                      }
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <center>
                          <button
                            className="proceednowbtn proceednowbtn1"
                            onClick={this.handleSProceedBtn}
                          >
                            Proceed now{" "}
                            <img
                              alt="loading..."
                              src={process.env.PUBLIC_URL + "/img/tick.svg"}
                            />
                          </button>
                        </center>
                      </div>
                      {/* Partnership */}
                      <div id="FoodLicense_P_LLP">
                        <div className="uploadscrolldiv">
                          <div className="uploadscrollcardinside">
                            {/* PPic1 */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Passport size Photograph (Partner 1)
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/PassportSizePhoto.jpeg"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PPic1seecommentsbtn"
                                        onClick={this.handlePPic1seecommentsbtn}
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PPic1hidecommentsbtn"
                                        onClick={
                                          this.handlePPic1hidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_14"
                                        className="choose_btn"
                                        id="choose_PPic1"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_14"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePPic1Change(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PPic1"
                                        onClick={(e) => this.removePPic1(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PPic1"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PPic1"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PPic1DocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PPic1"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* PPic2 */}

                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Passport size Photograph (Partner 2)
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/PassportSizePhoto.jpeg"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PPic2seecommentsbtn"
                                        onClick={this.handlePPic2seecommentsbtn}
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PPic2hidecommentsbtn"
                                        onClick={
                                          this.handlePPic2hidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_15"
                                        className="choose_btn"
                                        id="choose_PPic2"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_15"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePPic2Change(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PPic2"
                                        onClick={(e) => this.removePPic2(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PPic2"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PPic2"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PPic2DocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PPic2"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* PAadhar1 */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Aadhar Card/ Voter ID/ Passport/ Pan Card/
                                      Driving License (Partner 1)
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr"></p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/IDProof.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PAadhar1seecommentsbtn"
                                        onClick={
                                          this.handlePAadhar1seecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PAadhar1hidecommentsbtn"
                                        onClick={
                                          this.handlePAadhar1hidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_10"
                                        className="choose_btn"
                                        id="choose_PAadhar1"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_10"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePAadhar1Change(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PAadhar1"
                                        onClick={(e) => this.removePAadhar1(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PAadhar1"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PAadhar1"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PAadhar1DocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PAadhar1"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* PAadhar2 */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Aadhar Card/ Voter ID/ Passport/ Pan Card/
                                      Driving License (Partner 2)
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr"></p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/IDProof.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PAadhar2seecommentsbtn"
                                        onClick={
                                          this.handlePAadhar2seecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PAadhar2hidecommentsbtn"
                                        onClick={
                                          this.handlePAadhar2hidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_11"
                                        className="choose_btn"
                                        id="choose_PAadhar2"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_11"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePAadhar2Change(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PAadhar2"
                                        onClick={(e) => this.removePAadhar2(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PAadhar2"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PAadhar2"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PAadhar2DocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PAadhar2"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* PPan2 */}

                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Partnership Deed
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/PartnershipDeed.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PPan1seecommentsbtn"
                                        onClick={this.handlePPan1seecommentsbtn}
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PPan1hidecommentsbtn"
                                        onClick={
                                          this.handlePPan1hidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_12"
                                        className="choose_btn"
                                        id="choose_PPan1"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_12"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePPan1Change(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PPan1"
                                        onClick={(e) => this.removePPan1(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PPan1"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PPan1"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PPan1DocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PPan1"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* Nature */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <p className="uploadnormaltext">
                                  Nature of your Business / Brand:-
                                  <sup className="requireddocstar">*</sup>
                                </p>
                                <textarea
                                  className="uploadtextarea"
                                  maxLength={200}
                                  placeholder="Ex:- Kirana Shop / Shoes Brand / Hardware company..."
                                  value={this.state.PPbusinessNature}
                                  onChange={this.handlePPbusinessNatureChange}
                                ></textarea>
                              </div>
                            </div>

                            {/* PPan2 */}

                            {/* PBP */}
                            {/* PAddressProofType */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <p className="uploadnormaltext">
                                  Office address proof:
                                  <sup className="requireddocstar">*</sup>
                                </p>

                                <input
                                  type="radio"
                                  id="test22-11"
                                  name="radio-group12"
                                  value="CopyOfRegistry"
                                  checked={
                                    this.state.PAddressProofType_radio ===
                                    "CopyOfRegistry"
                                  }
                                  onChange={this.PAddressProofType_radioChange}
                                />
                                <label
                                  htmlFor="test22-11"
                                  className="uploadradiotext"
                                >
                                  Own office
                                </label>
                                <br />
                                <input
                                  type="radio"
                                  id="test22-22"
                                  name="radio-group12"
                                  value="RentAgreement"
                                  checked={
                                    this.state.PAddressProofType_radio ===
                                    "RentAgreement"
                                  }
                                  onChange={this.PAddressProofType_radioChange}
                                />
                                <label
                                  htmlFor="test22-22"
                                  className="uploadradiotext"
                                >
                                  Rented office
                                </label>
                              </div>
                            </div>

                            {/* PCopyOfRegistry */}

                            {/* PRentAgreement */}
                            <div className="displayflex  RentAgreement">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol normalBorder">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Rental agreement
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/RentAgreement.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PRentAgreementseecommentsbtn"
                                        onClick={
                                          this
                                            .handlePRentAgreementseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PRentAgreementhidecommentsbtn"
                                        onClick={
                                          this
                                            .handlePRentAgreementhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_20"
                                        className="choose_btn"
                                        id="choose_PRentAgreement"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_20"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePRentAgreementChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PRentAgreement"
                                        onClick={(e) =>
                                          this.removePRentAgreement(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PRentAgreement"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PRentAgreement"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PRentAgreementDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PRentAgreement"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* PCopyOfRegistry */}
                            <div className="displayflex  CopyOfRegistry">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploadlastdatacol normalBorder">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Registry or Ownership Deed in the name of
                                      Partnership firm
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">
                                      (in the name of owner)
                                    </p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/CopyOfRegistry.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PCopyOfRegistryseecommentsbtn"
                                        onClick={
                                          this
                                            .handlePCopyOfRegistryseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PCopyOfRegistryhidecommentsbtn"
                                        onClick={
                                          this
                                            .handlePCopyOfRegistryhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_21"
                                        className="choose_btn"
                                        id="choose_PCopyOfRegistry"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_21"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePCopyOfRegistryChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PCopyOfRegistry"
                                        onClick={(e) =>
                                          this.removePCopyOfRegistry(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PCopyOfRegistry"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PCopyOfRegistry"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PCopyOfRegistryDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PCopyOfRegistry"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* PPUnit */}
                            <div className="displayflex ">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol ">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Production Unit Photograph
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/ContactSupport.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PPUnitseecommentsbtn"
                                        onClick={
                                          this.handlePPUnitseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PPUnithidecommentsbtn"
                                        onClick={
                                          this.handlePPUnithidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_12PPUnit"
                                        className="choose_btn"
                                        id="choose_PPUnit"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_12PPUnit"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePPUnitChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PPUnit"
                                        onClick={(e) => this.removePPUnit(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PPUnit"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PPUnit"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PPUnitDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PPUnit"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* PLayoutPlan */}
                            <div className="displayflex ">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol ">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Blue Print / Layout Plan of Unit showing
                                      dimension in meters / square metre and
                                      operation wise area allocation
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/ContactSupport.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PLayoutPlanseecommentsbtn"
                                        onClick={
                                          this.handlePLayoutPlanseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PLayoutPlanhidecommentsbtn"
                                        onClick={
                                          this.handlePLayoutPlanhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_12PLayoutPlan"
                                        className="choose_btn"
                                        id="choose_PLayoutPlan"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_12PLayoutPlan"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePLayoutPlanChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PLayoutPlan"
                                        onClick={(e) =>
                                          this.removePLayoutPlan(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PLayoutPlan"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PLayoutPlan"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PLayoutPlanDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PLayoutPlan"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* PListofEquip */}
                            <div className="displayflex ">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol ">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Name and List of Equipment and Machinery
                                      along with numbers and installed capacity
                                      also horse power used
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/ContactSupport.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PListofEquipseecommentsbtn"
                                        onClick={
                                          this.handlePListofEquipseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PListofEquiphidecommentsbtn"
                                        onClick={
                                          this.handlePListofEquiphidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_12PListofEquip"
                                        className="choose_btn"
                                        id="choose_PListofEquip"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_12PListofEquip"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePListofEquipChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PListofEquip"
                                        onClick={(e) =>
                                          this.removePListofEquip(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PListofEquip"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PListofEquip"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PListofEquipDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PListofEquip"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* PWAReport */}
                            <div className="displayflex ">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol ">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Water Analysis Report for using as
                                      ingredient in food from a
                                      recognized/public health laboratory
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/ContactSupport.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PWAReportseecommentsbtn"
                                        onClick={
                                          this.handlePWAReportseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PWAReporthidecommentsbtn"
                                        onClick={
                                          this.handlePWAReporthidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_12PWAReport"
                                        className="choose_btn"
                                        id="choose_PWAReport"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_12PWAReport"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePWAReportChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PWAReport"
                                        onClick={(e) => this.removePWAReport(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PWAReport"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PWAReport"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PWAReportDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PWAReport"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* PListofDir */}
                            <div className="displayflex ">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol normalBorder">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      List of All Partners with Full Address and
                                      Contact Details
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/ContactSupport.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PListofDirseecommentsbtn"
                                        onClick={
                                          this.handlePListofDirseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PListofDirhidecommentsbtn"
                                        onClick={
                                          this.handlePListofDirhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_12PListofDir"
                                        className="choose_btn"
                                        id="choose_PListofDir"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_12PListofDir"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePListofDirChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PListofDir"
                                        onClick={(e) =>
                                          this.removePListofDir(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PListofDir"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PListofDir"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PListofDirDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PListofDir"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* PRecallPlan */}
                            <div className="displayflex lastdisplayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol borderHtao">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Recall Plan
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/ContactSupport.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PRecallPlanseecommentsbtn"
                                        onClick={
                                          this.handlePRecallPlanseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PRecallPlanhidecommentsbtn"
                                        onClick={
                                          this.handlePRecallPlanhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_12PRecallPlan"
                                        className="choose_btn"
                                        id="choose_PRecallPlan"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_12PRecallPlan"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePRecallPlanChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PRecallPlan"
                                        onClick={(e) =>
                                          this.removePRecallPlan(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PRecallPlan"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PRecallPlan"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PRecallPlanDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PRecallPlan"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* PAdditional */}
                            <div id="PadditionalDocumentDiv">
                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadadditionalsidetext visibilityhidden">
                                    1&#41;
                                  </p>
                                </div>
                                <div className="uploadadditionaldatacol">
                                  <p className="uploadadditionalnormaltext">
                                    Additional documents:-
                                  </p>
                                </div>
                              </div>
                              {this.PadditionalFilesArea()}

                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadadditionalsidetext visibilityhidden">
                                    1&#41;
                                  </p>
                                </div>
                                <div className="uploadadditionaldatacol">
                                  <button
                                    className="adddocumentbtn"
                                    id="PAdddocumentbtn"
                                    onClick={() =>
                                      this.handleAddPFileBtn(
                                        PadditionalFilesIndex
                                      )
                                    }
                                  >
                                    Add document{" "}
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path 19524.svg"
                                      }
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <center>
                          <button
                            className="proceednowbtn proceednowbtn2"
                            onClick={this.handlePProceedBtn}
                          >
                            Proceed now{" "}
                            <img
                              alt="loading..."
                              src={process.env.PUBLIC_URL + "/img/tick.svg"}
                            />
                          </button>
                        </center>
                      </div>

                      {/* Private limited */}
                      <div id="FoodLicense_PL">
                        <div className="uploadscrolldiv">
                          <div className="uploadscrollcardinside">
                            {/* PLPic1 */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Passport size Photograph (Director 1)
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/PassportSizePhoto.jpeg"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PLPic1seecommentsbtn"
                                        onClick={
                                          this.handlePLPic1seecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PLPic1hidecommentsbtn"
                                        onClick={
                                          this.handlePLPic1hidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_26"
                                        className="choose_btn"
                                        id="choose_PLPic1"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_26"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePLPic1Change(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PLPic1"
                                        onClick={(e) => this.removePLPic1(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PLPic1"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PLPic1"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PLPic1DocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PLPic1"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* PLPic2 */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Passport size Photograph (Director 2)
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/PassportSizePhoto.jpeg"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PLPic2seecommentsbtn"
                                        onClick={
                                          this.handlePLPic2seecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PLPic2hidecommentsbtn"
                                        onClick={
                                          this.handlePLPic2hidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_27"
                                        className="choose_btn"
                                        id="choose_PLPic2"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_27"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePLPic2Change(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PLPic2"
                                        onClick={(e) => this.removePLPic2(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PLPic2"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PLPic2"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PLPic2DocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PLPic2"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* PLAadhar1 */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Aadhaar card (Director 1)
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">
                                      Front and back both side
                                    </p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/AadhaarCard.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PLAadhar1seecommentsbtn"
                                        onClick={
                                          this.handlePLAadhar1seecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PLAadhar1hidecommentsbtn"
                                        onClick={
                                          this.handlePLAadhar1hidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_22"
                                        className="choose_btn"
                                        id="choose_PLAadhar1"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_22"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePLAadhar1Change(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PLAadhar1"
                                        onClick={(e) => this.removePLAadhar1(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PLAadhar1"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PLAadhar1"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PLAadhar1DocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PLAadhar1"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* PLAadhar2 */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Aadhaar card (Director 2)
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">
                                      Front and back both side
                                    </p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/AadhaarCard.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PLAadhar2seecommentsbtn"
                                        onClick={
                                          this.handlePLAadhar2seecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PLAadhar2hidecommentsbtn"
                                        onClick={
                                          this.handlePLAadhar2hidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_23"
                                        className="choose_btn"
                                        id="choose_PLAadhar2"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_23"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePLAadhar2Change(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PLAadhar2"
                                        onClick={(e) => this.removePLAadhar2(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PLAadhar2"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PLAadhar2"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PLAadhar2DocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PLAadhar2"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* PLCI */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Certificate of Incorporation
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/CertificateOfIncorporation.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PLCIseecommentsbtn"
                                        onClick={this.handlePLCIseecommentsbtn}
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PLCIhidecommentsbtn"
                                        onClick={this.handlePLCIhidecommentsbtn}
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_29"
                                        className="choose_btn"
                                        id="choose_PLCI"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_29"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePLCIChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PLCI"
                                        onClick={(e) => this.removePLCI(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PLCI"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PLCI"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PLCIDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PLCI"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* PLMOA */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      MOA
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/MOA.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PLMOAseecommentsbtn"
                                        onClick={this.handlePLMOAseecommentsbtn}
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PLMOAhidecommentsbtn"
                                        onClick={
                                          this.handlePLMOAhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_30"
                                        className="choose_btn"
                                        id="choose_PLMOA"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_30"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePLMOAChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PLMOA"
                                        onClick={(e) => this.removePLMOA(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PLMOA"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PLMOA"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PLMOADocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PLMOA"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* PLAOA */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      AOA
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/AOA.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PLAOAseecommentsbtn"
                                        onClick={this.handlePLAOAseecommentsbtn}
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PLAOAhidecommentsbtn"
                                        onClick={
                                          this.handlePLAOAhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_31"
                                        className="choose_btn"
                                        id="choose_PLAOA"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_31"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePLAOAChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PLAOA"
                                        onClick={(e) => this.removePLAOA(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PLAOA"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PLAOA"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PLAOADocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PLAOA"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* PLNature */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <p className="uploadnormaltext">
                                  Nature of your Business / Brand:-
                                  <sup className="requireddocstar">*</sup>
                                </p>
                                <textarea
                                  className="uploadtextarea"
                                  maxLength={200}
                                  placeholder="Ex:- Kirana Shop / Shoes Brand / Hardware company..."
                                  value={this.state.PLbusinessNature}
                                  onChange={this.handlePLbusinessNatureChange}
                                ></textarea>
                              </div>
                            </div>
                            {/* PLBR */}

                            {/* PLAddressProofType */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <p className="uploadnormaltext">
                                  Office address proof:
                                  <sup className="requireddocstar">*</sup>
                                </p>

                                <input
                                  type="radio"
                                  id="test3-1"
                                  name="radio-group3"
                                  value="CopyOfRegistry"
                                  checked={
                                    this.state.PLAddressProofType_radio ===
                                    "CopyOfRegistry"
                                  }
                                  onChange={this.PLAddressProofType_radioChange}
                                />
                                <label
                                  htmlFor="test3-1"
                                  className="uploadradiotext"
                                >
                                  Own office
                                </label>
                                <br />
                                <input
                                  type="radio"
                                  id="test3-2"
                                  name="radio-group3"
                                  value="RentAgreement"
                                  checked={
                                    this.state.PLAddressProofType_radio ===
                                    "RentAgreement"
                                  }
                                  onChange={this.PLAddressProofType_radioChange}
                                />
                                <label
                                  htmlFor="test3-2"
                                  className="uploadradiotext"
                                >
                                  Rented office
                                </label>
                              </div>
                            </div>

                            {/* PLCopyOfRegistry */}

                            {/* PLRentAgreement */}
                            <div className="displayflex  RentAgreement">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol normalBorder">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Rental agreement
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/RentAgreement.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PLRentAgreementseecommentsbtn"
                                        onClick={
                                          this
                                            .handlePLRentAgreementseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PLRentAgreementhidecommentsbtn"
                                        onClick={
                                          this
                                            .handlePLRentAgreementhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_37"
                                        className="choose_btn"
                                        id="choose_PLRentAgreement"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_37"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePLRentAgreementChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PLRentAgreement"
                                        onClick={(e) =>
                                          this.removePLRentAgreement(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PLRentAgreement"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PLRentAgreement"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PLRentAgreementDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PLRentAgreement"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* PLCopyOfRegistry */}
                            <div className="displayflex  CopyOfRegistry">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploadlastdatacol normalBorder">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Registry or Ownership Deed of Company
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">
                                      (in the name of owner)
                                    </p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/CopyOfRegistry.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PLCopyOfRegistryseecommentsbtn"
                                        onClick={
                                          this
                                            .handlePLCopyOfRegistryseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PLCopyOfRegistryhidecommentsbtn"
                                        onClick={
                                          this
                                            .handlePLCopyOfRegistryhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_38"
                                        className="choose_btn"
                                        id="choose_PLCopyOfRegistry"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_38"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePLCopyOfRegistryChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PLCopyOfRegistry"
                                        onClick={(e) =>
                                          this.removePLCopyOfRegistry(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PLCopyOfRegistry"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PLCopyOfRegistry"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={
                                          this.state.PLCopyOfRegistryDocLink
                                        }
                                        className="viewDocLink"
                                        id="viewDocLink_PLCopyOfRegistry"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* PLPUnit */}
                            <div className="displayflex ">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol ">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Production Unit Photograph
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr"></p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/ContactSupport.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PLPUnitseecommentsbtn"
                                        onClick={
                                          this.handlePLPUnitseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PLPUnithidecommentsbtn"
                                        onClick={
                                          this.handlePLPUnithidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_28PLPUnit"
                                        className="choose_btn"
                                        id="choose_PLPUnit"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_28PLPUnit"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePLPUnitChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PLPUnit"
                                        onClick={(e) => this.removePLPUnit(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PLPUnit"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PLPUnit"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PLPUnitDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PLPUnit"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* PLLayoutPlan */}
                            <div className="displayflex ">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol ">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Blue Print / Layout Plan of Unit showing
                                      dimension in meters / square metre and
                                      operation wise area allocation
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr"></p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/ContactSupport.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PLLayoutPlanseecommentsbtn"
                                        onClick={
                                          this.handlePLLayoutPlanseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PLLayoutPlanhidecommentsbtn"
                                        onClick={
                                          this.handlePLLayoutPlanhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_28PLLayoutPlan"
                                        className="choose_btn"
                                        id="choose_PLLayoutPlan"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_28PLLayoutPlan"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePLLayoutPlanChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PLLayoutPlan"
                                        onClick={(e) =>
                                          this.removePLLayoutPlan(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PLLayoutPlan"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PLLayoutPlan"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PLLayoutPlanDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PLLayoutPlan"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* PLListofEquip */}
                            <div className="displayflex ">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol ">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Name and List of Equipment and Machinery
                                      along with numbers and installed capacity
                                      also horse power used
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr"></p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/ContactSupport.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PLListofEquipseecommentsbtn"
                                        onClick={
                                          this.handlePLListofEquipseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PLListofEquiphidecommentsbtn"
                                        onClick={
                                          this
                                            .handlePLListofEquiphidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_28PLListofEquip"
                                        className="choose_btn"
                                        id="choose_PLListofEquip"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_28PLListofEquip"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePLListofEquipChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PLListofEquip"
                                        onClick={(e) =>
                                          this.removePLListofEquip(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PLListofEquip"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PLListofEquip"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PLListofEquipDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PLListofEquip"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* PLWAReport */}
                            <div className="displayflex ">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol ">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Water Analysis Report for using as
                                      ingredient in food from a
                                      recognized/public health laboratory
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr"></p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/ContactSupport.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PLWAReportseecommentsbtn"
                                        onClick={
                                          this.handlePLWAReportseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PLWAReporthidecommentsbtn"
                                        onClick={
                                          this.handlePLWAReporthidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_28PLWAReport"
                                        className="choose_btn"
                                        id="choose_PLWAReport"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_28PLWAReport"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePLWAReportChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PLWAReport"
                                        onClick={(e) =>
                                          this.removePLWAReport(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PLWAReport"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PLWAReport"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PLWAReportDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PLWAReport"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* PLListofDir */}
                            <div className="displayflex ">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol normalBorder">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      List of All Directors with Full Address
                                      and Contact Details
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr"></p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/ContactSupport.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PLListofDirseecommentsbtn"
                                        onClick={
                                          this.handlePLListofDirseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PLListofDirhidecommentsbtn"
                                        onClick={
                                          this.handlePLListofDirhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_28PLListofDir"
                                        className="choose_btn"
                                        id="choose_PLListofDir"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_28PLListofDir"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePLListofDirChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PLListofDir"
                                        onClick={(e) =>
                                          this.removePLListofDir(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PLListofDir"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PLListofDir"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PLListofDirDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PLListofDir"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* PLRecallPlan */}
                            <div className="displayflex lastdisplayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol borderHtao">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Recall Plan
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr"></p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/ContactSupport.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="PLRecallPlanseecommentsbtn"
                                        onClick={
                                          this.handlePLRecallPlanseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="PLRecallPlanhidecommentsbtn"
                                        onClick={
                                          this.handlePLRecallPlanhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_28PLRecallPlan"
                                        className="choose_btn"
                                        id="choose_PLRecallPlan"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_28PLRecallPlan"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handlePLRecallPlanChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_PLRecallPlan"
                                        onClick={(e) =>
                                          this.removePLRecallPlan(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_PLRecallPlan"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__PLRecallPlan"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.PLRecallPlanDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_PLRecallPlan"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* PLAdditional */}
                            <div id="PLadditionalDocumentDiv">
                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadadditionalsidetext visibilityhidden">
                                    1&#41;
                                  </p>
                                </div>
                                <div className="uploadadditionaldatacol">
                                  <p className="uploadadditionalnormaltext">
                                    Additional documents:-
                                  </p>
                                </div>
                              </div>
                              {this.PLadditionalFilesArea()}

                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadadditionalsidetext visibilityhidden">
                                    1&#41;
                                  </p>
                                </div>
                                <div className="uploadadditionaldatacol">
                                  <button
                                    className="adddocumentbtn"
                                    id="PLAdddocumentbtn"
                                    onClick={() =>
                                      this.handleAddPLFileBtn(
                                        PLadditionalFilesIndex
                                      )
                                    }
                                  >
                                    Add document{" "}
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path 19524.svg"
                                      }
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <center>
                          <button
                            className="proceednowbtn proceednowbtn3"
                            onClick={this.handlePLProceedBtn}
                          >
                            Proceed now{" "}
                            <img
                              alt="loading..."
                              src={process.env.PUBLIC_URL + "/img/tick.svg"}
                            />
                          </button>
                        </center>
                      </div>

                      {/* HUF */}
                      <div id="FoodLicense_HUF">
                        <div className="uploadscrolldiv">
                          <div className="uploadscrollcardinside">
                            {/* HUFPic */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Passport size Photo of Applicant
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/PassportSizePhoto.jpeg"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="HUFPicseecommentsbtn"
                                        onClick={
                                          this.handleHUFPicseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="HUFPichidecommentsbtn"
                                        onClick={
                                          this.handleHUFPichidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_41"
                                        className="choose_btn"
                                        id="choose_HUFPic"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_41"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleHUFPicChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_HUFPic"
                                        onClick={(e) => this.removeHUFPic(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_HUFPic"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__HUFPic"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.HUFPicDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_HUFPic"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* HUFAadhar */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Aadhar Card/Voter ID/ Passport/Pan Card/
                                      Driving License of karta
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">
                                      Front and back both side
                                    </p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/IDProof.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="HUFAadharseecommentsbtn"
                                        onClick={
                                          this.handleHUFAadharseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="HUFAadharhidecommentsbtn"
                                        onClick={
                                          this.handleHUFAadharhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_39"
                                        className="choose_btn"
                                        id="choose_HUFAadhar"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_39"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleHUFAadharChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_HUFAadhar"
                                        onClick={(e) => this.removeHUFAadhar(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_HUFAadhar"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__HUFAadhar"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.HUFAadharDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_HUFAadhar"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* HUFPan1 */}

                            {/* HUFPan2 */}

                            {/* HUFDeed */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      HUF Deed Document
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/HUFDeedDocument.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="HUFDeedseecommentsbtn"
                                        onClick={
                                          this.handleHUFDeedseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="HUFDeedhidecommentsbtn"
                                        onClick={
                                          this.handleHUFDeedhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_43"
                                        className="choose_btn"
                                        id="choose_HUFDeed"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_43"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleHUFDeedChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_HUFDeed"
                                        onClick={(e) => this.removeHUFDeed(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_HUFDeed"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__HUFDeed"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.HUFDeedDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_HUFDeed"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Nature */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <p className="uploadnormaltext">
                                  Nature of your Business / Brand:-
                                  <sup className="requireddocstar">*</sup>
                                </p>
                                <textarea
                                  className="uploadtextarea"
                                  maxLength={200}
                                  placeholder="Ex:- Kirana Shop / Shoes Brand / Hardware company..."
                                  value={this.state.HUFbusinessNature}
                                  onChange={this.handleHUFbusinessNatureChange}
                                ></textarea>
                              </div>
                            </div>

                            {/* HUFAddressProofType */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <p className="uploadnormaltext">
                                  Office address proof:
                                  <sup className="requireddocstar">*</sup>
                                </p>

                                <input
                                  type="radio"
                                  id="test44-14"
                                  name="radio-group14"
                                  value="CopyOfRegistry"
                                  checked={
                                    this.state.HUFAddressProofType_radio ===
                                    "CopyOfRegistry"
                                  }
                                  onChange={
                                    this.HUFAddressProofType_radioChange
                                  }
                                />
                                <label
                                  htmlFor="test44-14"
                                  className="uploadradiotext"
                                >
                                  Own office
                                </label>
                                <br />
                                <input
                                  type="radio"
                                  id="test44-24"
                                  name="radio-group14"
                                  value="RentAgreement"
                                  checked={
                                    this.state.HUFAddressProofType_radio ===
                                    "RentAgreement"
                                  }
                                  onChange={
                                    this.HUFAddressProofType_radioChange
                                  }
                                />
                                <label
                                  htmlFor="test44-24"
                                  className="uploadradiotext"
                                >
                                  Rented office
                                </label>
                              </div>
                            </div>

                            {/* HUFCopyOfRegistry */}
                            <div className="displayflex CopyOfRegistry">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Copy of Registry
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/CopyOfRegistry.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="HUFCopyOfRegistryseecommentsbtn"
                                        onClick={
                                          this
                                            .handleHUFCopyOfRegistryseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="HUFCopyOfRegistryhidecommentsbtn"
                                        onClick={
                                          this
                                            .handleHUFCopyOfRegistryhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_47"
                                        className="choose_btn"
                                        id="choose_HUFCopyOfRegistry"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_47"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleHUFCopyOfRegistryChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_HUFCopyOfRegistry"
                                        onClick={(e) =>
                                          this.removeHUFCopyOfRegistry(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_HUFCopyOfRegistry"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__HUFCopyOfRegistry"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={
                                          this.state.HUFCopyOfRegistryDocLink
                                        }
                                        className="viewDocLink"
                                        id="viewDocLink_HUFCopyOfRegistry"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* HUFRentAgreement */}
                            <div className="displayflex RentAgreement">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Rent agreement
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/RentAgreement.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="HUFRentAgreementseecommentsbtn"
                                        onClick={
                                          this
                                            .handleHUFRentAgreementseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="HUFRentAgreementhidecommentsbtn"
                                        onClick={
                                          this
                                            .handleHUFRentAgreementhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_48"
                                        className="choose_btn"
                                        id="choose_HUFRentAgreement"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_48"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleHUFRentAgreementChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_HUFRentAgreement"
                                        onClick={(e) =>
                                          this.removeHUFRentAgreement(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_HUFRentAgreement"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__HUFRentAgreement"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={
                                          this.state.HUFRentAgreementDocLink
                                        }
                                        className="viewDocLink"
                                        id="viewDocLink_HUFRentAgreement"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* HUFPR */}

                            {/* HUFPUnit */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Production Unit Photograph
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr"></p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/ContactSupport.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="HUFPUnitseecommentsbtn"
                                        onClick={
                                          this.handleHUFPUnitseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="HUFPUnithidecommentsbtn"
                                        onClick={
                                          this.handleHUFPUnithidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_44pHUFPUnit"
                                        className="choose_btn"
                                        id="choose_HUFPUnit"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_44pHUFPUnit"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleHUFPUnitChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_HUFPUnit"
                                        onClick={(e) => this.removeHUFPUnit(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_HUFPUnit"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__HUFPUnit"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.HUFPUnitDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_HUFPUnit"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* HUFLayoutPlan */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Blue Print / Layout Plan of Unit showing
                                      dimension in meters / square metre and
                                      operation wise area allocation
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr"></p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/ContactSupport.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="HUFLayoutPlanseecommentsbtn"
                                        onClick={
                                          this.handleHUFLayoutPlanseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="HUFLayoutPlanhidecommentsbtn"
                                        onClick={
                                          this
                                            .handleHUFLayoutPlanhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_44pHUFLayoutPlan"
                                        className="choose_btn"
                                        id="choose_HUFLayoutPlan"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_44pHUFLayoutPlan"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleHUFLayoutPlanChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_HUFLayoutPlan"
                                        onClick={(e) =>
                                          this.removeHUFLayoutPlan(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_HUFLayoutPlan"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__HUFLayoutPlan"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.HUFLayoutPlanDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_HUFLayoutPlan"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* HUFListofEquip */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Name and List of Equipment and Machinery
                                      along with numbers and installed capacity
                                      also horse power used
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr"></p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/ContactSupport.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="HUFListofEquipseecommentsbtn"
                                        onClick={
                                          this
                                            .handleHUFListofEquipseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="HUFListofEquiphidecommentsbtn"
                                        onClick={
                                          this
                                            .handleHUFListofEquiphidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_44pHUFListofEquip"
                                        className="choose_btn"
                                        id="choose_HUFListofEquip"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_44pHUFListofEquip"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleHUFListofEquipChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_HUFListofEquip"
                                        onClick={(e) =>
                                          this.removeHUFListofEquip(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_HUFListofEquip"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__HUFListofEquip"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.HUFListofEquipDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_HUFListofEquip"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* HUFWAReport */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Water Analysis Report for using as
                                      ingredient in food from a
                                      recognized/public health laboratory
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr"></p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/ContactSupport.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="HUFWAReportseecommentsbtn"
                                        onClick={
                                          this.handleHUFWAReportseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="HUFWAReporthidecommentsbtn"
                                        onClick={
                                          this.handleHUFWAReporthidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_44pHUFWAReport"
                                        className="choose_btn"
                                        id="choose_HUFWAReport"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_44pHUFWAReport"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleHUFWAReportChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_HUFWAReport"
                                        onClick={(e) =>
                                          this.removeHUFWAReport(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_HUFWAReport"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__HUFWAReport"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.HUFWAReportDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_HUFWAReport"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* HUFListofDir */}
                            <div className="displayflex ">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol normalBorder">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      List of All Directors with Full Address
                                      and Contact Details
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr"></p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/ContactSupport.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="HUFListofDirseecommentsbtn"
                                        onClick={
                                          this.handleHUFListofDirseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="HUFListofDirhidecommentsbtn"
                                        onClick={
                                          this.handleHUFListofDirhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_44pHUFListofDir"
                                        className="choose_btn"
                                        id="choose_HUFListofDir"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_44pHUFListofDir"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleHUFListofDirChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_HUFListofDir"
                                        onClick={(e) =>
                                          this.removeHUFListofDir(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_HUFListofDir"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__HUFListofDir"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.HUFListofDirDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_HUFListofDir"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* HUFRecallPlan */}
                            <div className="displayflex lastdisplayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol borderHtao">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Recall Plan
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr"></p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/ContactSupport.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="HUFRecallPlanseecommentsbtn"
                                        onClick={
                                          this.handleHUFRecallPlanseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="HUFRecallPlanhidecommentsbtn"
                                        onClick={
                                          this
                                            .handleHUFRecallPlanhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_44pHUFRecallPlan"
                                        className="choose_btn"
                                        id="choose_HUFRecallPlan"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_44pHUFRecallPlan"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleHUFRecallPlanChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_HUFRecallPlan"
                                        onClick={(e) =>
                                          this.removeHUFRecallPlan(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_HUFRecallPlan"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__HUFRecallPlan"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.HUFRecallPlanDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_HUFRecallPlan"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* HUFAdditional */}
                            <div id="HUFadditionalDocumentDiv">
                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadadditionalsidetext visibilityhidden">
                                    1&#41;
                                  </p>
                                </div>
                                <div className="uploadadditionaldatacol">
                                  <p className="uploadadditionalnormaltext">
                                    Additional documents:-
                                  </p>
                                </div>
                              </div>
                              {this.HUFadditionalFilesArea()}

                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadadditionalsidetext visibilityhidden">
                                    1&#41;
                                  </p>
                                </div>
                                <div className="uploadadditionaldatacol">
                                  <button
                                    className="adddocumentbtn"
                                    id="HUFAdddocumentbtn"
                                    onClick={() =>
                                      this.handleAddHUFFileBtn(
                                        HUFadditionalFilesIndex
                                      )
                                    }
                                  >
                                    Add document{" "}
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path 19524.svg"
                                      }
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <center>
                          <button
                            className="proceednowbtn proceednowbtn4"
                            onClick={this.handleHUFProceedBtn}
                          >
                            Proceed now{" "}
                            <img
                              alt="loading..."
                              src={process.env.PUBLIC_URL + "/img/tick.svg"}
                            />
                          </button>
                        </center>
                      </div>
                      {/* Society/ trust/ club */}
                      <div id="FoodLicense_Society">
                        <div className="uploadscrolldiv">
                          <div className="uploadscrollcardinside">
                            {/* STPic1 */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Passport size Photo (President or
                                      Secretary) (1)
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/PassportSizePhoto.jpeg"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="STPic1seecommentsbtn"
                                        onClick={
                                          this.handleSTPic1seecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="STPic1hidecommentsbtn"
                                        onClick={
                                          this.handleSTPic1hidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_54"
                                        className="choose_btn"
                                        id="choose_STPic1"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_54"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleSTPic1Change(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_STPic1"
                                        onClick={(e) => this.removeSTPic1(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_STPic1"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__STPic1"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.STPic1DocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_STPic1"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* STPic2 */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Passport size Photo (President or
                                      Secretary) (2)
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/PassportSizePhoto.jpeg"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="STPic2seecommentsbtn"
                                        onClick={
                                          this.handleSTPic2seecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="STPic2hidecommentsbtn"
                                        onClick={
                                          this.handleSTPic2hidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_55"
                                        className="choose_btn"
                                        id="choose_STPic2"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_55"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleSTPic2Change(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_STPic2"
                                        onClick={(e) => this.removeSTPic2(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_STPic2"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__STPic2"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.STPic2DocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_STPic2"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* STAadhar1 */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Aadhar Card/ Voter ID/ Passport/ Pan Card/
                                      Driving License (President or Secretary)
                                      (1)
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">
                                      Front and back both side
                                    </p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/IDProof.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="STAadhar1seecommentsbtn"
                                        onClick={
                                          this.handleSTAadhar1seecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="STAadhar1hidecommentsbtn"
                                        onClick={
                                          this.handleSTAadhar1hidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_50"
                                        className="choose_btn"
                                        id="choose_STAadhar1"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_50"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleSTAadhar1Change(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_STAadhar1"
                                        onClick={(e) => this.removeSTAadhar1(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_STAadhar1"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__STAadhar1"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.STAadhar1DocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_STAadhar1"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* STAadhar2 */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Aadhar Card/ Voter ID/ Passport/ Pan Card/
                                      Driving License (President or Secretary)
                                      (2)
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">
                                      Front and back both side
                                    </p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/IDProof.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="STAadhar2seecommentsbtn"
                                        onClick={
                                          this.handleSTAadhar2seecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="STAadhar2hidecommentsbtn"
                                        onClick={
                                          this.handleSTAadhar2hidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_51"
                                        className="choose_btn"
                                        id="choose_STAadhar2"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_51"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleSTAadhar2Change(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_STAadhar2"
                                        onClick={(e) => this.removeSTAadhar2(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_STAadhar2"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__STAadhar2"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.STAadhar2DocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_STAadhar2"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* STPan1 */}
                            {/* STPan2 */}

                            {/* STCR */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Society Registration Certificate
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/CertificateOfRegistration.pdf "
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="STCRseecommentsbtn"
                                        onClick={this.handleSTCRseecommentsbtn}
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="STCRhidecommentsbtn"
                                        onClick={this.handleSTCRhidecommentsbtn}
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_57"
                                        className="choose_btn"
                                        id="choose_STCR"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_57"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleSTCRChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_STCR"
                                        onClick={(e) => this.removeSTCR(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_STCR"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__STCR"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.STCRDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_STCR"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* Nature */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <p className="uploadnormaltext">
                                  Nature of your Business / Brand:-
                                  <sup className="requireddocstar">*</sup>
                                </p>
                                <textarea
                                  className="uploadtextarea"
                                  maxLength={200}
                                  placeholder="Ex:- Kirana Shop / Shoes Brand / Hardware company..."
                                  value={this.state.STbusinessNature}
                                  onChange={this.handleSTbusinessNatureChange}
                                ></textarea>
                              </div>
                            </div>
                            {/* STBP */}

                            {/* STBR */}

                            {/* STGumasta */}

                            {/* STTradeLicence */}

                            {/* STAddressProofType */}
                            <div className="displayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <p className="uploadnormaltext">
                                  Office address proof:
                                  <sup className="requireddocstar">*</sup>
                                </p>
                                <input
                                  type="radio"
                                  id="test55-15"
                                  name="radio-group15"
                                  value="CopyOfRegistry"
                                  checked={
                                    this.state.STAddressProofType_radio ===
                                    "CopyOfRegistry"
                                  }
                                  onChange={this.STAddressProofType_radioChange}
                                />
                                <label
                                  htmlFor="test55-15"
                                  className="uploadradiotext"
                                >
                                  Own office
                                </label>
                                <br />
                                <input
                                  type="radio"
                                  id="test55-25"
                                  name="radio-group15"
                                  value="RentAgreement"
                                  checked={
                                    this.state.STAddressProofType_radio ===
                                    "RentAgreement"
                                  }
                                  onChange={this.STAddressProofType_radioChange}
                                />
                                <label
                                  htmlFor="test55-25"
                                  className="uploadradiotext"
                                >
                                  Rented office
                                </label>
                              </div>
                            </div>

                            {/* STCopyOfRegistry */}
                            <div className="displayflex CopyOfRegistry">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Copy of Registry
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/CopyOfRegistry.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="STCopyOfRegistryseecommentsbtn"
                                        onClick={
                                          this
                                            .handleSTCopyOfRegistryseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="STCopyOfRegistryhidecommentsbtn"
                                        onClick={
                                          this
                                            .handleSTCopyOfRegistryhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_62"
                                        className="choose_btn"
                                        id="choose_STCopyOfRegistry"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_62"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleSTCopyOfRegistryChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_STCopyOfRegistry"
                                        onClick={(e) =>
                                          this.removeSTCopyOfRegistry(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_STCopyOfRegistry"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__STCopyOfRegistry"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={
                                          this.state.STCopyOfRegistryDocLink
                                        }
                                        className="viewDocLink"
                                        id="viewDocLink_STCopyOfRegistry"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* STRentAgreement */}
                            <div className="displayflex RentAgreement ">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol normalBorder">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Rent agreement
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/RentAgreement.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="STRentAgreementseecommentsbtn"
                                        onClick={
                                          this
                                            .handleSTRentAgreementseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="STRentAgreementhidecommentsbtn"
                                        onClick={
                                          this
                                            .handleSTRentAgreementhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_63"
                                        className="choose_btn"
                                        id="choose_STRentAgreement"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_63"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleSTRentAgreementChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_STRentAgreement"
                                        onClick={(e) =>
                                          this.removeSTRentAgreement(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_STRentAgreement"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__STRentAgreement"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.STRentAgreementDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_STRentAgreement"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* STPR */}
                            {/* STPUnit */}
                            <div className="displayflex ">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol ">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Production Unit Photograph
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/ContactSupport.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="STPUnitseecommentsbtn"
                                        onClick={
                                          this.handleSTPUnitseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="STPUnithidecommentsbtn"
                                        onClick={
                                          this.handleSTPUnithidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_56pSTPUnit"
                                        className="choose_btn"
                                        id="choose_STPUnit"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_56pSTPUnit"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleSTPUnitChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_STPUnit"
                                        onClick={(e) => this.removeSTPUnit(e)}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_STPUnit"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__STPUnit"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.STPUnitDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_STPUnit"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* STLayoutPlan */}
                            <div className="displayflex ">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol ">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Blue Print / Layout Plan of Unit showing
                                      dimension in meters / square metre and
                                      operation wise area allocation
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/ContactSupport.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="STLayoutPlanseecommentsbtn"
                                        onClick={
                                          this.handleSTLayoutPlanseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="STLayoutPlanhidecommentsbtn"
                                        onClick={
                                          this.handleSTLayoutPlanhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_56pSTLayoutPlan"
                                        className="choose_btn"
                                        id="choose_STLayoutPlan"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_56pSTLayoutPlan"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleSTLayoutPlanChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_STLayoutPlan"
                                        onClick={(e) =>
                                          this.removeSTLayoutPlan(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_STLayoutPlan"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__STLayoutPlan"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.STLayoutPlanDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_STLayoutPlan"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* STListofEquip */}
                            <div className="displayflex ">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol ">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Name and List of Equipment and Machinery
                                      along with numbers and installed capacity
                                      also horse power used
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/ContactSupport.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="STListofEquipseecommentsbtn"
                                        onClick={
                                          this.handleSTListofEquipseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="STListofEquiphidecommentsbtn"
                                        onClick={
                                          this
                                            .handleSTListofEquiphidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_56pSTListofEquip"
                                        className="choose_btn"
                                        id="choose_STListofEquip"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_56pSTListofEquip"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleSTListofEquipChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_STListofEquip"
                                        onClick={(e) =>
                                          this.removeSTListofEquip(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_STListofEquip"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__STListofEquip"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.STListofEquipDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_STListofEquip"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* STWAReport */}
                            <div className="displayflex ">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol ">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Water Analysis Report for using as
                                      ingredient in food from a
                                      recognized/public health laboratory
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/ContactSupport.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="STWAReportseecommentsbtn"
                                        onClick={
                                          this.handleSTWAReportseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="STWAReporthidecommentsbtn"
                                        onClick={
                                          this.handleSTWAReporthidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_56pSTWAReport"
                                        className="choose_btn"
                                        id="choose_STWAReport"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_56pSTWAReport"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleSTWAReportChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_STWAReport"
                                        onClick={(e) =>
                                          this.removeSTWAReport(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_STWAReport"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__STWAReport"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.STWAReportDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_STWAReport"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* STListofDir */}
                            <div className="displayflex ">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol normalBorder">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      List of All Executive Member of Society /
                                      Trust with Full Address and Contact
                                      Details
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr">Soft copy</p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/ContactSupport.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="STListofDirseecommentsbtn"
                                        onClick={
                                          this.handleSTListofDirseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="STListofDirhidecommentsbtn"
                                        onClick={
                                          this.handleSTListofDirhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_56pSTListofDir"
                                        className="choose_btn"
                                        id="choose_STListofDir"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_56pSTListofDir"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleSTListofDirChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_STListofDir"
                                        onClick={(e) =>
                                          this.removeSTListofDir(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_STListofDir"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__STListofDir"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.STListofDirDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_STListofDir"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* STRecallPlan */}
                            <div className="displayflex lastdisplayflex">
                              <div className="uploadnocol">
                                <p className="uploadnormaltext documentno"></p>
                              </div>
                              <div className="uploaddatacol borderHtao">
                                <div className="row">
                                  <div className="col-md-5">
                                    <p className="uploadnormaltext">
                                      Recall Plan
                                      <sup className="requireddocstar">*</sup>
                                    </p>
                                    <p className="uploadinstr"></p>
                                  </div>
                                  <div className="col-md-7">
                                    <div className="fl_right">
                                      <a
                                        href="https://docs.legal251.com/example/documents/ContactSupport.pdf"
                                        className="viewexamplelink"
                                        target="_blank"
                                      >
                                        View example document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                      <button
                                        className="seecommentsbtn commentsbtn"
                                        id="STRecallPlanseecommentsbtn"
                                        onClick={
                                          this.handleSTRecallPlanseecommentsbtn
                                        }
                                      >
                                        See comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="hidecommentsbtn"
                                        id="STRecallPlanhidecommentsbtn"
                                        onClick={
                                          this.handleSTRecallPlanhidecommentsbtn
                                        }
                                      >
                                        Hide comments{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path 19498.svg"
                                          }
                                        />
                                      </button>
                                      <label
                                        htmlFor="ul_file_56pSTRecallPlan"
                                        className="choose_btn"
                                        id="choose_STRecallPlan"
                                      >
                                        Choose file{" "}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_15188.svg"
                                          }
                                          alt="file"
                                        />
                                      </label>
                                      <input
                                        type="file"
                                        multiple
                                        name="file[]"
                                        className="upload-photo"
                                        id="ul_file_56pSTRecallPlan"
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(e) =>
                                          this.handleSTRecallPlanChange(e)
                                        }
                                      />
                                      <button
                                        className="X-btn"
                                        id="X-btn_STRecallPlan"
                                        onClick={(e) =>
                                          this.removeSTRecallPlan(e)
                                        }
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_1025.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="review-btn"
                                        id="review-btn_STRecallPlan"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_19663.svg"
                                          }
                                        />
                                      </button>
                                      <button
                                        className="done-btn"
                                        id="done-btn__STRecallPlan"
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/tick.svg"
                                          }
                                        />
                                      </button>
                                      <br />
                                      <a
                                        href={this.state.STRecallPlanDocLink}
                                        className="viewDocLink"
                                        id="viewDocLink_STRecallPlan"
                                        target="_blank"
                                      >
                                        View uploaded document{" "}
                                        <img
                                          className="viewDocLinkimg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Path_20245.svg"
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* STAdditional */}
                            <div id="STadditionalDocumentDiv">
                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadadditionalsidetext visibilityhidden">
                                    1&#41;
                                  </p>
                                </div>
                                <div className="uploadadditionaldatacol">
                                  <p className="uploadadditionalnormaltext">
                                    Additional documents:-
                                  </p>
                                </div>
                              </div>
                              {this.STadditionalFilesArea()}

                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadadditionalsidetext visibilityhidden">
                                    1&#41;
                                  </p>
                                </div>
                                <div className="uploadadditionaldatacol">
                                  <button
                                    className="adddocumentbtn"
                                    id="STAdddocumentbtn"
                                    onClick={() =>
                                      this.handleAddSTFileBtn(
                                        STadditionalFilesIndex
                                      )
                                    }
                                  >
                                    Add document{" "}
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Path 19524.svg"
                                      }
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <center>
                          <button
                            className="proceednowbtn proceednowbtn5"
                            onClick={this.handleSTProceedBtn}
                          >
                            Proceed now{" "}
                            <img
                              alt="loading..."
                              src={process.env.PUBLIC_URL + "/img/tick.svg"}
                            />
                          </button>
                        </center>
                      </div>
                    </div>

                    {/* comments */}
                    <div className="col-lg-3 colbtwborder">
                      <div className="commentimg-section">
                        <SupportDetails
                          name={this.state.Name}
                          role={"Relationship Manager"}
                          contact={this.state.Contact}
                        />
                        <div className="mm">
                          <center>
                            <a
                              className="video-btn"
                              data-toggle="modal"
                              data-src="https://www.youtube.com/embed/fiC-nHoFJEY"
                              data-target="#youtubeModal"
                            >
                              <img
                                src={process.env.PUBLIC_URL + "/img/play.jpg"}
                                id="play"
                                className="play_img play_img2"
                                alt="image"
                              />
                            </a>
                          </center>
                        </div>
                        
                      </div>
                      <div className="TDRightSide">
                        <div className="convheader">
                          <div>
                            <img
                              src={process.env.PUBLIC_URL + "/img/person.png"}
                              className="converpersonimg"
                            />
                          </div>
                          <div className="convheadercontdiv">
                            <p className="convservicename">{this.state.Name}</p>
                            <p className="convpersonname">Relationship Manager</p>
                            <p className="convuniquename">
                              Contact:{" "}
                              <span style={{ color: "#2d2d2d" }}>
                                {this.state.Contact}
                              </span>
                            </p>
                            <p className="convuniquename">
                              Service ID :{" "}
                              <span className="convuniquenamespan">
                                {Decserviceid}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="convmaindiv">
                          {this.renderCommenthistory()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div id="tab2" className="tab-content">
                    <div className="doucumainscrolldiv2">
                      <div className="doucumainscrolldivinside2">
                        {this.completerender()}
                      </div>
                    </div>
                  </div>
                  </div>
              </div>

              :<center>
            <img alt="notaccess" className="notuploaddoc" src={process.env.PUBLIC_URL + "/img/Warning-rafiki.svg"} />

            <h4 class="my-linkmodal-text youcant">You can't upload document because you're Referal Partner. Please contact support executive for any help and more information.</h4>
            </center>
          }
            </div>
          </div>
          
          <SupportDetailsMobile
            name={this.state.Name}
            role={"Relationship Manager"}
            contact={this.state.Contact}
          />

          <Footer />

          <div className="modal fade" id="SAadharModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SAadhar"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SAadhar"
                        onClick={this.uploadSAadhar}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SAadhar"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="SPanModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SPan"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SPan"
                        onClick={this.uploadSPan}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SPan"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="SPicModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SPic"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SPic"
                        onClick={this.uploadSPic}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SPic"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="SPUnitModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SPUnit"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SPUnit"
                        onClick={this.uploadSPUnit}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SPUnit"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="SLayoutPlanModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SLayoutPlan"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SLayoutPlan"
                        onClick={this.uploadSLayoutPlan}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SLayoutPlan"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="SListofEquipModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SListofEquip"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SListofEquip"
                        onClick={this.uploadSListofEquip}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SListofEquip"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="SWAReportModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SWAReport"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SWAReport"
                        onClick={this.uploadSWAReport}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SWAReport"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="SListofDirModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SListofDir"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SListofDir"
                        onClick={this.uploadSListofDir}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SListofDir"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="SRecallPlanModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SRecallPlan"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SRecallPlan"
                        onClick={this.uploadSRecallPlan}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SRecallPlan"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="SBPModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SBP"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SBP"
                        onClick={this.uploadSBP}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SBP"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="SGumastaModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SGumasta"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SGumasta"
                        onClick={this.uploadSGumasta}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SGumasta"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STradeLicenceModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STradeLicence"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STradeLicence"
                        onClick={this.uploadSTradeLicence}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STradeLicence"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="SCopyOfRegistryModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SCopyOfRegistry"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SCopyOfRegistry"
                        onClick={this.uploadSCopyOfRegistry}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SCopyOfRegistry"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="SRentAgreementModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SRentAgreement"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SRentAgreement"
                        onClick={this.uploadSRentAgreement}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SRentAgreement"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="SPRModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_SPR"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_SPR"
                        onClick={this.uploadSPR}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_SPR"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade SAddModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    maxLength="250"
                    placeholder="Type here..."
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn cancelbtn_7"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20 upload_btn20_7"
                        onClick={() =>
                          this.uploadSFile(
                            SadditionalReuploadIndex,
                            SadditionalReuploadStatus
                          )
                        }
                      >
                        Upload
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />
                      </button>
                      <button className="upload_btnloader upload_btnloader_7">
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Partnership */}
          <div className="modal fade" id="PAadhar1Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PAadhar1"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PAadhar1"
                        onClick={this.uploadPAadhar1}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PAadhar1"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PAadhar2Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PAadhar2"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PAadhar2"
                        onClick={this.uploadPAadhar2}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PAadhar2"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PPan1Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PPan1"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PPan1"
                        onClick={this.uploadPPan1}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PPan1"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PPan2Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PPan2"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PPan2"
                        onClick={this.uploadPPan2}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PPan2"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PPic1Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PPic1"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PPic1"
                        onClick={this.uploadPPic1}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PPic1"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PPic2Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PPic2"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PPic2"
                        onClick={this.uploadPPic2}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PPic2"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PPUnitModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PPUnit"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PPUnit"
                        onClick={this.uploadPPUnit}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PPUnit"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLayoutPlanModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLayoutPlan"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLayoutPlan"
                        onClick={this.uploadPLayoutPlan}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLayoutPlan"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PListofEquipModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PListofEquip"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PListofEquip"
                        onClick={this.uploadPListofEquip}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PListofEquip"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PWAReportModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PWAReport"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PWAReport"
                        onClick={this.uploadPWAReport}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PWAReport"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PListofDirModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PListofDir"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PListofDir"
                        onClick={this.uploadPListofDir}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PListofDir"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PRecallPlanModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PRecallPlan"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PRecallPlan"
                        onClick={this.uploadPRecallPlan}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PRecallPlan"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PBPModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PBP"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PBP"
                        onClick={this.uploadPBP}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PBP"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PGumastaModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PGumasta"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PGumasta"
                        onClick={this.uploadPGumasta}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PGumasta"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PTradeLicenceModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PTradeLicence"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PTradeLicence"
                        onClick={this.uploadPTradeLicence}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PTradeLicence"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PCopyOfRegistryModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PCopyOfRegistry"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PCopyOfRegistry"
                        onClick={this.uploadPCopyOfRegistry}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PCopyOfRegistry"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PRentAgreementModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PRentAgreement"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PRentAgreement"
                        onClick={this.uploadPRentAgreement}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PRentAgreement"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PPRModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PPR"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PPR"
                        onClick={this.uploadPPR}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PPR"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade PAddModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    maxLength="250"
                    placeholder="Type here..."
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn cancelbtn_7"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20 upload_btn20_7"
                        onClick={() =>
                          this.uploadPFile(
                            PadditionalReuploadIndex,
                            PadditionalReuploadStatus
                          )
                        }
                      >
                        Upload
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />
                      </button>
                      <button className="upload_btnloader upload_btnloader_7">
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLAadhar1Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLAadhar1"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLAadhar1"
                        onClick={this.uploadPLAadhar1}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLAadhar1"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLAadhar2Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLAadhar2"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLAadhar2"
                        onClick={this.uploadPLAadhar2}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLAadhar2"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLPan1Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLPan1"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLPan1"
                        onClick={this.uploadPLPan1}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLPan1"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLPan2Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLPan2"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLPan2"
                        onClick={this.uploadPLPan2}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLPan2"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLPic1Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLPic1"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLPic1"
                        onClick={this.uploadPLPic1}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLPic1"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLPic2Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLPic2"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLPic2"
                        onClick={this.uploadPLPic2}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLPic2"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLPUnitModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLPUnit"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLPUnit"
                        onClick={this.uploadPLPUnit}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLPUnit"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLLayoutPlanModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLLayoutPlan"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLLayoutPlan"
                        onClick={this.uploadPLLayoutPlan}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLLayoutPlan"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLListofEquipModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLListofEquip"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLListofEquip"
                        onClick={this.uploadPLListofEquip}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLListofEquip"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLWAReportModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLWAReport"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLWAReport"
                        onClick={this.uploadPLWAReport}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLWAReport"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLListofDirModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLListofDir"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLListofDir"
                        onClick={this.uploadPLListofDir}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLListofDir"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLRecallPlanModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLRecallPlan"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLRecallPlan"
                        onClick={this.uploadPLRecallPlan}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLRecallPlan"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLPan3Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLPan3"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLPan3"
                        onClick={this.uploadPLPan3}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLPan3"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLCIModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLCI"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLCI"
                        onClick={this.uploadPLCI}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLCI"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLMOAModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLMOA"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLMOA"
                        onClick={this.uploadPLMOA}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLMOA"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLAOAModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLAOA"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLAOA"
                        onClick={this.uploadPLAOA}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLAOA"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLBRModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLBR"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLBR"
                        onClick={this.uploadPLBR}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLBR"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLBPModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLBP"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLBP"
                        onClick={this.uploadPLBP}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLBP"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLGumastaModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLGumasta"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLGumasta"
                        onClick={this.uploadPLGumasta}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLGumasta"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLTradeLicenceModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLTradeLicence"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLTradeLicence"
                        onClick={this.uploadPLTradeLicence}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLTradeLicence"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLCopyOfRegistryModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLCopyOfRegistry"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLCopyOfRegistry"
                        onClick={this.uploadPLCopyOfRegistry}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLCopyOfRegistry"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLRentAgreementModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLRentAgreement"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLRentAgreement"
                        onClick={this.uploadPLRentAgreement}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLRentAgreement"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="PLCopyOfRegistryModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_PLPR"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_PLPR"
                        onClick={this.uploadPLPR}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_PLPR"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade PLAddModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    maxLength="250"
                    placeholder="Type here..."
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn cancelbtn_7"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20 upload_btn20_7"
                        onClick={() =>
                          this.uploadPLFile(
                            PLadditionalReuploadIndex,
                            PLadditionalReuploadStatus
                          )
                        }
                      >
                        Upload
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />
                      </button>
                      <button className="upload_btnloader upload_btnloader_7">
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFAadharModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFAadhar"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFAadhar"
                        onClick={this.uploadHUFAadhar}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFAadhar"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFPan1Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFPan1"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFPan1"
                        onClick={this.uploadHUFPan1}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFPan1"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFPicModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFPic"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFPic"
                        onClick={this.uploadHUFPic}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFPic"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFPUnitModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFPUnit"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFPUnit"
                        onClick={this.uploadHUFPUnit}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFPUnit"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFLayoutPlanModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFLayoutPlan"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFLayoutPlan"
                        onClick={this.uploadHUFLayoutPlan}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFLayoutPlan"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFListofEquipModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFListofEquip"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFListofEquip"
                        onClick={this.uploadHUFListofEquip}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFListofEquip"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFWAReportModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFWAReport"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFWAReport"
                        onClick={this.uploadHUFWAReport}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFWAReport"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFListofDirModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFListofDir"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFListofDir"
                        onClick={this.uploadHUFListofDir}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFListofDir"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFRecallPlanModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFRecallPlan"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFRecallPlan"
                        onClick={this.uploadHUFRecallPlan}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFRecallPlan"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFPan2Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFPan2"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFPan2"
                        onClick={this.uploadHUFPan2}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFPan2"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFDeedModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFDeed"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFDeed"
                        onClick={this.uploadHUFDeed}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFDeed"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFPremisePicModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFPremisePic"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFPremisePic"
                        onClick={this.uploadHUFBP}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFPremisePic"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFGumastaModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFGumasta"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFGumasta"
                        onClick={this.uploadHUFGumasta}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFGumasta"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFTradeLicenceModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFTradeLicence"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFTradeLicence"
                        onClick={this.uploadHUFTradeLicence}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFTradeLicence"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFCopyOfRegistryModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFCopyOfRegistry"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFCopyOfRegistry"
                        onClick={this.uploadHUFCopyOfRegistry}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFCopyOfRegistry"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFRentAgreementModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFRentAgreement"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFRentAgreement"
                        onClick={this.uploadHUFRentAgreement}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFRentAgreement"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="HUFPRModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_HUFPR"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_HUFPR"
                        onClick={this.uploadHUFPR}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_HUFPR"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade HUFAddModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    maxLength="250"
                    placeholder="Type here..."
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn cancelbtn_7"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20 upload_btn20_7"
                        onClick={() =>
                          this.uploadHUFFile(
                            HUFadditionalReuploadIndex,
                            HUFadditionalReuploadStatus
                          )
                        }
                      >
                        Upload
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />
                      </button>
                      <button className="upload_btnloader upload_btnloader_7">
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STAadhar1Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STAadhar1"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STAadhar1"
                        onClick={this.uploadSTAadhar1}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STAadhar1"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STAadhar2Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STAadhar2"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STAadhar2"
                        onClick={this.uploadSTAadhar2}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STAadhar2"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STPan1Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STPan1"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STPan1"
                        onClick={this.uploadSTPan1}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STPan1"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STPan2Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STPan2"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STPan2"
                        onClick={this.uploadSTPan2}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STPan2"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STPic1Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STPic1"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STPic1"
                        onClick={this.uploadSTPic1}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STPic1"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STPUnitModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STPUnit"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STPUnit"
                        onClick={this.uploadSTPUnit}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STPUnit"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="STLayoutPlanModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STLayoutPlan"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STLayoutPlan"
                        onClick={this.uploadSTLayoutPlan}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STLayoutPlan"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STListofEquipModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STListofEquip"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STListofEquip"
                        onClick={this.uploadSTListofEquip}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STListofEquip"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STWAReportModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STWAReport"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STWAReport"
                        onClick={this.uploadSTWAReport}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STWAReport"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STListofDirModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STListofDir"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STListofDir"
                        onClick={this.uploadSTListofDir}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STListofDir"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STRecallPlanModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STRecallPlan"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STRecallPlan"
                        onClick={this.uploadSTRecallPlan}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STRecallPlan"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STPic2Modal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STPic2"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STPic2"
                        onClick={this.uploadSTPic2}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STPic2"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STPremisePicModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STPremisePic"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STPremisePic"
                        onClick={this.uploadSTPremisePic}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STPremisePic"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STCRModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STCR"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STCR"
                        onClick={this.uploadSTCR}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STCR"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STBPModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STBP"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STBP"
                        onClick={this.uploadSTBP}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STBP"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STBRModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STBR"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STBR"
                        onClick={this.uploadSTBR}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STBR"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STGumastaModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STGumasta"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STGumasta"
                        onClick={this.uploadSTGumasta}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STGumasta"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STTradeLicenceModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STTradeLicence"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STTradeLicence"
                        onClick={this.uploadSTTradeLicence}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STTradeLicence"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STCopyOfRegistryModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STCopyOfRegistry"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STCopyOfRegistry"
                        onClick={this.uploadSTCopyOfRegistry}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STCopyOfRegistry"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STRentAgreementModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STRentAgreement"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STRentAgreement"
                        onClick={this.uploadSTRentAgreement}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STRentAgreement"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="STPRModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    placeholder="Type here..."
                    maxLength="250"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn"
                          id="cancelbtn_STPR"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20"
                        id="upload_btn20_STPR"
                        onClick={this.uploadSTPR}
                      >
                        Upload{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />{" "}
                      </button>
                      <button
                        className="upload_btnloader"
                        id="upload_btnloader_STPR"
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="youtubeModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered yt_modal "
              role="document"
            >
              <div className="modal-content">
                <div className="modal-body modal_b">
                  <button
                    type="button"
                    className="close close1"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      className="embed-responsive-item"
                      id="video"
                      allowscriptaccess="always"
                      src="https://www.youtube.com/embed/fiC-nHoFJEY?&amp;modestbranding=1&amp;showinfo=0"
                      allowFullScreen="allowfullscreen"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade STAddModal">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-header uploadmainpopupheader">
                  <div className="uploadpopupheader">
                    <p className="popupdocunametext d-flex">
                      <div>
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 15188.svg"}
                          className="popupdocumentimg"
                        />
                      </div>
                      <div className="pl-1">{this.state.fileName}</div>
                    </p>
                  </div>
                </div>

                <div className="modal-body uploadpopupbody">
                  <p className="uploadaddcomm">Add a comment (optional)</p>
                  <input
                    type="text"
                    className="uploadpopupinput"
                    maxLength="250"
                    placeholder="Type here..."
                    value={this.state.comment}
                    onChange={this.handleComment}
                  />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn cancelbtn_7"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20 upload_btn20_7"
                        onClick={() =>
                          this.uploadSTFile(
                            STadditionalReuploadIndex,
                            STadditionalReuploadStatus
                          )
                        }
                      >
                        Upload
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_230.svg"}
                          alt="download"
                        />
                      </button>
                      <button className="upload_btnloader upload_btnloader_7">
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>







          <div className="modal fade"
            id="timeline"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true">

            <div
              className="modal-dialog modal-dialog-centered yt_modal "
              role="document"
            >
              <div className="modal-content videoModal">
                <div className="modal-body modal_b mt-4 ">
                  <button
                    type="button"
                    className="close close1 close1tl closeMain mr-2 dbtime"
                    data-dismiss="modal"
                    aria-label="Close"

                  >
                    <span aria-hidden="true">&times;</span>
                  </button>

                  {this.state.Timelinedata ?


                    this.state.Timeline.length > 0 ?

                      <div class="timeline scrollbtimel">
                        <div class="container">
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="timeline-container">
                                <div class="timeline-end">
                                  <button id="load-more" onClick={this.handlemoretimeline} >More..</button>
                                </div>
                                <div class="timeline-continue">

                                  {this.state.filtertimel.map((item, index) => {

                                    if (index % 2 == 0) {
                                      return (

                                        <div class="row timeline-left">
                                          <div class="col-md-6 d-md-none d-block">
                                            <p class="timeline-date">

                                            </p>
                                          </div>
                                          <div class="col-md-6">
                                            <div class="timeline-box">
                                              <div class="timeline-icon d-md-none d-block">
                                                <i class="fa fa-business-time"></i>
                                              </div>

                                              <div class="timeline-text">
                                                <p class="mainSpan">{item.time}</p>
                                                <h3>{item.timeLine}</h3>
                                                <p class="mode_p">Date : <span class="mode_span"> {item.date}</span></p>
                                                <h5 className="tlremark">{item.remark}</h5>
                                                {/* <p class="mode_p">
                                                      <span class="mode_span">
                                                          {{$TimeLine->TimeLine}}
                                                      </span>
                                                  </p>  */}


                                              </div>

                                              <div class="timeline-icon d-md-block d-none">
                                                <i class="fa fa-business-time"></i>
                                              </div>

                                            </div>
                                          </div>
                                          <div class="col-md-6 d-md-block d-none">
                                            <p class="timeline-date">

                                            </p>
                                          </div>
                                        </div>

                                      )
                                    } else {
                                      return (
                                        <div class="row timeline-right">
                                          <div class="col-md-6">
                                            <p class="timeline-date">
                                            </p>
                                          </div>
                                          <div class="col-md-6">
                                            <div class="timeline-box">
                                              <div class="timeline-icon">
                                                <i class="fa fa-gift"></i>
                                              </div>

                                              <div class="timeline-text">
                                                <p class="mainSpan">{item.time}</p>
                                                {/* <p class="">khkh</p> */}

                                                <h3>{item.timeLine}</h3>
                                                <p class="mode_p">Date : <span class="mode_span">{item.date} </span></p>
                                                <h5 className="tlremark">{item.remark}</h5>
                                                {/* <p class="mode_p">
                                                                              <span class="mode_span">
                                                                                  
                                                                              </span>
                                                                          </p> */}


                                              </div>

                                            </div>
                                          </div>
                                        </div>
                                      )
                                    }




                                  })}




                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      <center className="">
                        <img
                          alt="loading..."
                          src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
                          className="emptydeliveredDocuments"
                        />
                      </center>

                    : null
                  }
                  <center>
                    <img
                      className="timelineloder"
                      src={process.env.PUBLIC_URL + "/img/loader.gif"}
                      alt="loading"
                    />
                  </center>
                </div>
              </div>
            </div>

          </div>
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}
export default ActiveFoodlicense;

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  integrations: [new Sentry.Replay()],
});

let mainuserid = localStorage.getItem("relleIsugad");
   let  Decuserid = encryptor.decrypt(mainuserid);
let mainagentid = localStorage.getItem("qazxswedcvfrtgb");
let Decagentid = encryptor.decrypt(mainagentid);
Sentry.setUser({ id: Decagentid});
Sentry.setTag( "userid",Decuserid);
ReactDOM.render( 
    <App />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals


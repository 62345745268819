import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import axios from "axios";
import { Redirect } from "react-router";
import Error from "./Error";
var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);

let DecToken;
let DecName;
let DecEmail;
let DecContact;
let DecPassword;

class VerifyYourself extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contact: "",
      email: "",
      otp: "",
      otp2: "",
      isValid: true,
    
    };
  }

  componentDidMount() {
    $(document.body).css("background-color", "#fff");
    window.scrollTo(0, 0);

    document.querySelector("head").innerHTML +=
      '<link rel="stylesheet" href="../../../css/loginL.css" type="text/css"/>';
    document.querySelector("head").innerHTML +=
      '<link rel="stylesheet" href="../../../css/loginWithPss.css" type="text/css"/>';
    document.querySelector("head").innerHTML +=
      '<link rel="stylesheet" href="../../../css/verifyUrself.css" type="text/css"/>';
    document.querySelector("head").innerHTML +=
      '<link rel="stylesheet" href="../../../css/setPassword.css" type="text/css"/>';

    let Token = localStorage.getItem("noTeklagel");
    if (Token == null) {
      this.setState({
        isValid: false,
      });
    }
    let Name = localStorage.getItem("mnealeagl");
    let apiEmail = localStorage.getItem("liapEiam");
    let apiContact = localStorage.getItem("teiaoclaCgtlapn");
    let Password = localStorage.getItem("adsoalrPewgls");
    DecToken = encryptor.decrypt(Token);
    DecName = encryptor.decrypt(Name);
    DecEmail = encryptor.decrypt(apiEmail);
    DecContact = encryptor.decrypt(apiContact);
    DecPassword = encryptor.decrypt(Password);

    this.setState(
      {
        contact: DecContact,
        email:DecEmail
      },
      () => {
        this.handleotpsend();
      }
    );
  }


  handleotpsend = (e) => {
    $(".error1").hide();
    $(".resend-loader-gif").show();
    $(".resend").hide();
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=otp",
        {
          contact: this.state.contact,
          emailToken: DecToken,
          email: DecEmail,
          type:"Both"
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        
        $(".resend-loader-gif").hide();
        $(".resend").show();

        if (res.data.code == 200 && res.data.status == "success") {
          $(".error1email").show();
          $(".error1email").css("color", "green");
          $(".error1email").html("OTP sent");
          var counter = 30;
          $("#mobile_number").prop("readonly", true);
          $("#emailid").prop("readonly", true);
  
          var interval = setInterval(function () {
            counter--;
            if (counter <= 0) {
              clearInterval(interval);
              $(".resend").html("Resend ");
              $(".resend").prop("disabled", false);
              return;
            } else {
              $(".resend").attr("disabled", true);
              $(".resend").text("Resend " + "(" + counter + "s)");
            }
          }, 1000);
        } else if (
          (res.data.code == 201 && res.data.status == "fail") ||
          (res.data.code == 201 && res.data.status == "alreadyexist")
        ) {
          $(".error1email").show();
          $(".error1email").css("color", "red");
          $(".error1email").html(res.data.message);
        }
      })
      .catch((err) => {});
  };







  handleResend = (e) => {
    $(".error1").hide();
    $(".resend-loader-gif1").show();
    $(".resend-1").hide();
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=otp",
        {
          contact: this.state.contact,
          emailToken: DecToken,
          email: DecEmail,
          type:"Contact"
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        
        $(".resend-loader-gif1").hide();
        $(".resend-1").show();

        if (res.data.code == 200 && res.data.status == "success") {
          $(".error1contact ").show();
          $(".error1contact ").css("color", "green");
          $(".error1contact ").html("OTP sent");
          var counter = 30;
          $("#mobile_number").prop("readonly", true);
          $("#emailid").prop("readonly", true);
          var interval = setInterval(function () {
            counter--;
            if (counter <= 0) {
              clearInterval(interval);
              $(".resend-1").html("Resend ");
              $(".resend-1").prop("disabled", false);
              return;
            } else {
              $(".resend-1").attr("disabled", true);
              $(".resend-1").text("Resend " + "(" + counter + "s)");
            }
          }, 1000);
        } else if (
          (res.data.code == 201 && res.data.status == "fail") ||
          (res.data.code == 201 && res.data.status == "alreadyexist")
        ) {
          $(".error1contact ").show();
          $(".error1contact ").css("color", "red");
          $(".error1contact ").html(res.data.message);
        }
      })
      .catch((err) => {});
  };


  handleResend2 = (e) => {
    $(".error1").hide();
    $(".resend-loader-gif2").show();
    $(".resend-2").hide();
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=otp",
        {
          contact: this.state.contact,
          emailToken: DecToken,
          email: DecEmail,
          type:"Email"
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        
        $(".resend-loader-gif2").hide();
        $(".resend-2").show();

        if (res.data.code == 200 && res.data.status == "success") {
          $(".error1email").show();
          $(".error1email").css("color", "green");
          $(".error1email").html("OTP sent");
          var counter = 30;
          $("#mobile_number").prop("readonly", true);
          $("#emailid").prop("readonly", true);
          var interval = setInterval(function () {
            counter--;
            if (counter <= 0) {
              clearInterval(interval);
              $(".resend-2").html("Resend ");
              $(".resend-2").prop("disabled", false);
              return;
            } else {
              $(".resend-2").attr("disabled", true);
              $(".resend-2").text("Resend " + "(" + counter + "s)");
            }
          }, 1000);
        } else if (
          (res.data.code == 201 && res.data.status == "fail") ||
          (res.data.code == 201 && res.data.status == "alreadyexist")
        ) {
          $(".error1email").show();
          $(".error1email").css("color", "red");
          $(".error1email").html(res.data.message);
        }
      })
      .catch((err) => {});
  };

  handleotp = (e) => {
    e.preventDefault();
    this.setState({
      otp: e.target.value.trim(),
    });
  };

  handleotp2= (e) => {
    e.preventDefault();
    this.setState({
      otp2: e.target.value.trim(),
    });
  };

  handleNextBtn = (e) => {
    $(".submit-loader-gif").show();
    $(".next").hide();
    $(".error1").hide();

    if (this.state.otp.trim() == "") {
      $(".error1contact ").show();
      $(".error1contact ").html("Please Enter Mobile Otp");
    }
   else if (this.state.otp2.trim() == "") {
      $(".error1email ").show();
      $(".error1email ").html("Please Enter Email Otp");
    }
    else {
      $(".submit-loader-gif").show();
      $(".next").hide();
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=verifyAgent",
          {
            otp: this.state.otp.trim(),
            emailOTP:this.state.otp2.trim(),
            contact: this.state.contact,
            email: DecEmail,
            emailToken: DecToken,
            password: DecPassword,
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
        
          $(".submit-loader-gif").hide();
          $(".next").show();

          if (res.data.code == 200 && res.data.status == "success") {
            localStorage.removeItem("noTeklagel");
            localStorage.removeItem("mnealeagl");
            localStorage.removeItem("liapEiam");
            localStorage.removeItem("teiaoclaCgtlapn");
            localStorage.removeItem("adsoalrPewgls");
            localStorage.setItem(
              "qazxswedcvfrtgb",
              encryptor.encrypt(res.data.agentId)
            );
            localStorage.setItem(
              "toeljgtkewlna",
              encryptor.encrypt(res.data.jwt)
            );
            localStorage.setItem(
              "gglltakoinoeenl",
              process.env.REACT_APP_LOGIN_KEY
            );
            localStorage.setItem(
              "qwedfgewerewrwer",(res.data.logo)
            );
            localStorage.setItem(
              "qwedfgewerewrwer",
              encryptor.encrypt(res.data.type)
            );
            this.props.history.push({
              pathname: `/dashboard`,
            });
          } else if (res.data.code == 201 && res.data.status == "bothotpinvalid") {
            $(".submit-loader-gif").hide();
            $(".next").show();
            $(".error1").show();
            $(".error1").css("color", "red");
            $(".error1email").html("Invalid OTP");
            $(".error1contact ").html("Invalid OTP");
          }
          else if (res.data.code == 201 && res.data.status == "emailotpinvalid") {
            $(".submit-loader-gif").hide();
            $(".next").show();
            $(".error1email").show();
            $(".error1email").css("color", "red");
            $(".error1email").html("Invalid OTP");
          }
          else if (res.data.code == 201 && res.data.status == "contactotpinvalid") {
            $(".submit-loader-gif").hide();
            $(".next").show();
            $(".error1contact").show();
            $(".error1contact").css("color", "red");
            $(".error1contact").html("Invalid OTP");
          }
        })
        .catch((err) => {
        
          $(".submit-loader-gif").hide();
          $(".next").show();
          $(".error1email").show();
          $(".error1email").css("color", "red");
          $(".error1email").html("Something went wrong");
        });
    }
  };

 

  render() {
    if (this.state.isValid) {
      return (
        <div className="main-bg">
          {/* <Helmet>
            <link
              rel="stylesheet"
              href={process.env.PUBLIC_URL + "/css/setPassword.css"}
            />
            <link
              rel="stylesheet"
              href={process.env.PUBLIC_URL + "/css/loginWithPss.css"}
            />
            <link
              rel="stylesheet"
              href={process.env.PUBLIC_URL + "/css/verifyUrself.css"}
            />
            <link
              rel="stylesheet"
              href={process.env.PUBLIC_URL + "/css/loginL.css"}
            />
          </Helmet> */}
          <div className="pass_div1 pass_div1_res">
            <p className="pass_txtverify">Verify your mobile no.</p>
            <center>
              <div className="div_verify">
                <p className="otp">Please enter the OTP that we've sent you</p>
                {/* <!-- mobile number --> */}
                <div className="border_4">
                  <div className="input-group border_3">
                    <p className="border_txtm">Mobile no.</p>
                    <input
                      type="tel"
                      className="form-control inp_l3"
                      maxLength="10"
                      minLength="10"
                      readOnly
                      id="mobile_number"
                      value={this.state.contact}
                      placeholder="Type here..."
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                    />
                  </div>
                  </div>
                  <div className="border_4">
                  <div className="input-group border_3">
                    <p className="border_txtm">Email ID.</p>
                    <input
                      type="email"
                      className="form-control inp_l3"
                      maxLength="100"
                      readOnly
                      id="emailid"
                      value={this.state.email}
                      placeholder="Type here..."
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                    />
                  </div>
                </div>
                <div className="roe_height">
                  <div className="row height_1">
                    {/* <!-- otp --> */}
                    <div className="col-md-6 clr7">
                      <div className="input-group border_2otp">
                        <p className="border_txtotp">Mobile OTP</p>
                        <input
                          type="tel"
                          className="form-control inp_lotp"
                          maxLength="6"
                          minLength="6"
                          placeholder="Enter OTP..."
                          id="otp"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          value={this.state.otp}
                          onChange={this.handleotp}
                        />
                      </div>
                    </div>
                    {/* <!-- resend --> */}
                    <div className="col-md-6 cll7">
                      <button className="resend resend-1" onClick={this.handleResend}>
                        Resend (30<span>s</span> )
                      </button>
                      <img
                        className="resend-loader-gif resend-loader-gif1"
                        src={process.env.PUBLIC_URL + "/img/loader.gif"}
                        alt="loader"
                      />
                    </div>
                  </div>
                  <p className="error1 error1contact">This is demo error message</p>
                </div>


                <div className="roe_height">
                  <div className="row height_1">
                    {/* <!-- otp --> */}
                    <div className="col-md-6 clr7">
                      <div className="input-group border_2otp">
                        <p className="border_txtotp">Email OTP</p>
                        <input
                          type="tel"
                          className="form-control inp_lotp"
                          maxLength="6"
                          minLength="6"
                          placeholder="Enter OTP..."
                          id="otp"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          value={this.state.otp2}
                          onChange={this.handleotp2}
                        />
                      </div>
                    </div>
                    {/* <!-- resend --> */}
                    <div className="col-md-6 cll7">
                      <button className="resend resend-2" onClick={this.handleResend2}>
                        Resend (30<span>s</span> )
                      </button>
                      <img
                        className="resend-loader-gif resend-loader-gif2" 
                        src={process.env.PUBLIC_URL + "/img/loader.gif"}
                        alt="loader"
                      />
                    </div>
                  </div>
                  <p className="error1 error1email">This is demo error message</p>
                </div>

              </div>
            </center>
            {/* <!-- next --> */}
            <center className="mt-3">
              {" "}
              <button className="next" onClick={this.handleNextBtn}>
                Next
              </button>
              <img
                className="submit-loader-gif"
                src={process.env.PUBLIC_URL + "/img/loader.gif"}
                alt="loader"
              />
            </center>
          </div>
        </div>
      );
    } else if (!this.state.isValid) {
      return <Redirect to="/" />;
    } else {
      <Helmet>
        {/* <link
          rel="stylesheet"
          href={process.env.PUBLIC_URL + "/css/setPassword.css"}
        />
        <link
          rel="stylesheet"
          href={process.env.PUBLIC_URL + "/css/loginWithPss.css"}
        />
        <link
          rel="stylesheet"
          href={process.env.PUBLIC_URL + "/css/verifyUrself.css"}
        />
        <link
          rel="stylesheet"
          href={process.env.PUBLIC_URL + "/css/loginL.css"}
        /> */}
      </Helmet>;
    }
  }
}

export default VerifyYourself;

import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import { Redirect } from "react-router";
import customservices from "../customservices.json"
import AllServices from "../AllServices.json"
import Sidebar from "./Sidebar";

var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);
let Decuserid;
let Decagentid;
let Decjwttoken;
let Services
export class Addagentservice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      serviceselectvalue: "",
      serviceName: [
        "GST Registration",
      ]
    };
  }
  componentDidMount = () => {
    this.setState({
      serviceName: Object.values(AllServices)
    })
    window.scrollTo(0, 0);
    $(document.body).css("background-color", "#2d2d2d");
    let mainjwttoken = localStorage.getItem("toeljgtkewlna");
    Decjwttoken = encryptor.decrypt(mainjwttoken);
    sessionStorage.setItem("srewrercgdgfdgf", encryptor.encrypt(false));
  }

  logout = () => {
    localStorage.removeItem("gglltakoinoeenl");
    localStorage.removeItem("toeljgtkewlna");
    localStorage.removeItem("qazxswedcvfrtgb");
    localStorage.removeItem("lshhihfnkff");
    this.props.history.push({
      pathname: `/`,
    });
  };
  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };

  selectservicehandle = (event) => {
    if (event.target.value != "") {

      this.setState({
        serviceselectvalue: event.target.value,
      });
      if (event.target.value == "GST Return Filing") {
        this.props.history.push({
          pathname: `/addservice/gstrf`,
        });
      }
      else if (event.target.value == "GST Registration") {
        this.props.history.push({
          pathname: `/addservice/gstr`,
        });
      } else if (event.target.value == "ITR Filing") {

        this.props.history.push({
          pathname: `/addservice/itrf`,
        });
      } else if (event.target.value == "Trademark Registration") {
        this.props.history.push({
          pathname: `/addservice/tr`,
        });
      } else if (event.target.value == "Online Accounting") {
        this.props.history.push({
          pathname: `/addservice/oa`,
        });
      } else if (event.target.value == "Gumasta Licence (S&E)") {
        this.props.history.push({
          pathname: `/addservice/gumasta`,
        });
      } else if (event.target.value == "Trade Licence") {
        this.props.history.push({
          pathname: `/addservice/tl`,
        });
      } else if (event.target.value == "Food Licence") {
        this.props.history.push({
          pathname: `/addservice/fl`,
        });
      } else if (event.target.value == "Legal Notice Reply") {
        this.props.history.push({
          pathname: `/addservice/repl`,
        });
      } else if (event.target.value == "Personal Loan") {
        this.props.history.push({
          pathname: `/addservice/personal-loan`,
        });
      } else if (event.target.value == "Home Loan") {
        this.props.history.push({
          pathname: `/addservice/home-loan`,
        });
      } else if (event.target.value == "Business Loan") {
        this.props.history.push({
          pathname: `/addservice/business-loan`,
        });
      } else if (event.target.value == "Loan Against Property") {
        this.props.history.push({
          pathname: `/addservice/loan-against-property`,
        });
      } else if (event.target.value == "Car Loan") {
        this.props.history.push({
          pathname: `addservice/car-loan`,
        });
      } else if (event.target.value == "Gold Loan") {
        this.props.history.push({
          pathname: `/addservice/gold-loan`,
        });
      } else if (event.target.value == "Axis Bank Credit Card") {
        this.props.history.push({
          pathname: `/addservice/axis-bank-credit-card`,
        });
      } else if (event.target.value == "Indusind Bank Credit Card") {
        this.props.history.push({
          pathname: `/addservice/indusind-bank-credit-card`,
        });
      } else if (event.target.value == "Yes Bank Credit Card") {
        this.props.history.push({
          pathname: `/addservice/yes-bank-credit-card`,
        });
      } else if (event.target.value == "State Bank of India Credit Card") {
        this.props.history.push({
          pathname: `/addservice/sbi-bank-credit-card`,
        });
      } else if (event.target.value == "AU Bank Credit Card") {
        this.props.history.push({
          pathname: `/addservice/au-bank-credit-card`,
        });
      } else if (event.target.value == "Stashfin Bank Credit Card") {
        this.props.history.push({
          pathname: `/addservice/stashfin-bank-credit-card`,
        });
      } else if (event.target.value == "Annual ROC Compliance(Company)") {
        this.props.history.push({
          pathname: `/addservice/roccomp`,
        });
      } else if (event.target.value == "Annual ROC Compliance(LLP)") {
        this.props.history.push({
          pathname: `/addservice/rocllp`,
        });
      } else if (event.target.value == "CMA Reports") {
        this.props.history.push({
          pathname: `/addservice/cma`,
        });
      } else if (event.target.value == "TDS Return Filing") {
        this.props.history.push({
          pathname: `/addservice/tds`,
        });
      } else if (event.target.value == "Net Worth Certificate Consultancy") {
        this.props.history.push({
          pathname: `/addservice/nwc`,
        });
      } else if (event.target.value == "Statutory Audit Consultancy") {
        this.props.history.push({
          pathname: `/addservice/sta`,
        });
      } else if (event.target.value == "Income Tax Audit Consultancy") {
        this.props.history.push({
          pathname: `/addservice/ita`,
        });
      } else if (event.target.value == "Project Report Prepration") {
        this.props.history.push({
          pathname: `/addservice/pr`,
        });
      } else if (event.target.value == "PF Return Filing") {
        this.props.history.push({
          pathname: `/addservice/pfrf`,
        });
      } else if (event.target.value == "ESIC Return Filing") {
        this.props.history.push({
          pathname: `/addservice/esicrf`,
        });
      }  else {
        
        let name = event.target.value
        let valueindex = this.state.serviceName.indexOf(name);
        let mainshortCode = Object.keys(AllServices)[valueindex]
        let result = customservices[mainshortCode]
        this.props.history.push({
          pathname: `/addservice/${result.shortCode.toLowerCase()}`, state: result
        });
      }
    }
  };
  render() {
    if (
      localStorage.getItem("gglltakoinoeenl") ==
      process.env.REACT_APP_LOGIN_KEY &&
      localStorage.getItem("toeljgtkewlna") != null &&
      localStorage.getItem("qazxswedcvfrtgb") != null
    ) {
      return (
        <div className="cont">
          <div id="notifContainer"></div>
          <Helmet>
            <link
              rel="stylesheet"
              href={process.env.PUBLIC_URL + "/css/select2.css"}
            />
            <link rel="stylesheet" href="css/services.css" />
          </Helmet>

          <Header />

          <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Path_217.svg"} className="sidebaropenicon" />
          <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
            onClick={this.backbutton}
            className="sidebarbackicon"
          />
          <img alt="loading..." src={process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"}
            className="sidebarexpandicon"
          />

          <div className="overlay1"></div>
          <Sidebar />
          <div className="arrow-left6"></div>
          <div className="white_div">
            <div className="white_box addagentservback">

              {" "}
              <p className="download agentadd ml-lg-4 ml-md-3 ml-0 mb-0 pl-2">Add Service</p>

              <div className="row addserivemaindiv px-lg-5 px-md-4 px-4 mt-lg-2 mt-md-2 mt-1">
                <h2 className="agsup-head mb-4">
                  Get Started By Selecting any
                  <br /><span>Legal251</span> Service!
                </h2>

                <div className="addserviceselectdiv mt-2 ml-lg-4 ml-md-2 ml-0">
                  <Select2
                    id="serviceselect"
                    onChange={this.selectservicehandle}
                    value={this.state.serviceselectvalue}
                    data={this.state.serviceName}
                    options={{
                      placeholder: "Select service...",
                    }}
                  />
                </div>
              </div>

            </div>
          </div>



          <Footer />
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default Addagentservice

import React, { Component } from "react";
import { Link } from "react-router-dom";
import $, { data } from "jquery";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import jsondata from "../states-and-districts.json"

const key = process.env.REACT_APP_ENCRYPTOR_KEY;
let baseUrl = process.env.REACT_APP_API_BASE_URL;
var encryptor = require("simple-encryptor")(key);
export class Agentregistration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toCheckout: false,
      pay: true,
      data: jsondata,
      states: [],
      districts: [],
      workdistricts: [],
      applicationid: "",
      file: "",
      firstName: "",
      lastName: "",
      contactNo: "",
      email: "",
      DOB: "",
      gender: "c",
      applicationType: "a",
      applicationOther: "",
      koid: "",
      state: "",
      city_district: "",
      landmark: "",
      address: "",
      pinCode: "",
      aadharCard: "",
      aadharCardback:"",
      aadharCardbackbase64:"",
      panCard: "",
      addressproof: "",
      cancelCheque: "",
      profile: "",
      profilephoto: "",
      partnerCode: "",
      bankAccountName: "",
      accountNo: "",
      IFSCcode: "",
      workstate: "",
      workcity_district: "",
      workaddress: "",
      worklandmark: "",
      workpinCode: "",
      workaddressproof: "",
      sameAddress: "N",
      previousStatus: "N",
      previousInsuranceStatus: "N",
      panCardbase64: "",
      aadharCardbase64: "",
      cancelChequebase64: "",
      profilebase64: "",
      profilephotobase64: "",
      addressproofbase64: "",
      workaddressproofbase64: "",
      applicationTypeArray:[],
      panNumber:"",
      aadharNumber:"",
    };
  }

  showCommission = () =>{
      $("#viewCommission").modal("show")
  }
  componentDidMount() {
    window.scrollTo(0, 0);
   
    $(document.body).css("background-color", "#fff");

  
    const apptype = new URLSearchParams(this.props.location.search).get(
      "applicationtype"
    );
   
    if (apptype == "nictagent") {
      this.setState({ applicationType: "NICT" });
      $("#koidinput").show();
    }
    axios.post(`${baseUrl}p=getApplicationType`,{ },
    {
      headers: {
        Authkey: process.env.REACT_APP_API_KEY,
      },
      auth: {
        username: process.env.REACT_APP_API_USERNAME,
        password: process.env.REACT_APP_API_PASSWORD,
      },
    }
    ).then((res) => {
      if(res.data.code == 200 && res.data.status =="success"){
        this.setState({applicationTypeArray:res.data.Data})
      }else{
        this.showNotification("Something Went Wrong! Try Again Later")
      }
    }).catch(err => {
      this.showNotification("Something Went Wrong! Try Again Later")
    })
  }

  getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();
      // Convert the file to base64 text
      if (file) {
        reader.readAsDataURL(file);
        // on reader load somthing...
        reader.onload = () => {
          // Make a fileInfo Object
          baseURL = reader.result;
          resolve(baseURL);
        };
      }
    });
  };

  handleFileInputChange1 = (e) => {
    let { file } = this.state;

    file = e.target.files[0];
    if (file.size > 20971520  ) {
      this.showNotification("File too big!");
      $(".warn").hide();
      $("#cheque-warnsize").show();
      $("#cheque-warnsize").html("File too big!");
      $("#upload1").focus();
      this.setState({ file: "" });
    }else if (file.name.length > 100) {
      this.showNotification("File name limit exceed!");
      $(".warn").hide();
      $("#cheque-warnsize").show();
      $("#cheque-warnsize").html("File name limit exceed!");
      $("#upload1").focus();
      this.setState({ file: "" });
    } else if (
      file.type == "image/jpg" ||
      file.type == "image/jpeg" ||
      file.type == "image/png" ||
      file.type == "application/pdf"
    ) {
      this.getBase64(file)
        .then((result) => {
          file["base64"] = result;

          this.setState({
            cancelChequebase64: result,
            cancelCheque: file.name,
            file,
          });
          $("#upload1-label").hide();
          $("#remove-file1").show();
          $("#remove-file1").css("display", "flex");
        })
        .catch((err) => {
          this.showNotification("Something went wrong");
        });

      this.setState({
        file: e.target.files[0],
      });
    } else this.showNotification("File extension not allowed!");
  };

  handleFileInputChange2 = (e) => {
    let { file } = this.state;

    file = e.target.files[0];
    if (file.size > 20971520  ) {
      this.showNotification("File too big!");
      $(".warn").hide();
      $("#addressproof-warnsize").show();
      $("#addressproof-warnsize").html("File too big!");
      $("#upload2").focus();
      this.setState({ file: "" });
    }else if (file.name.length > 100) {
      this.showNotification("File name limit exceed!");
      $(".warn").hide();
      $("#addressproof-warnsize").show();
      $("#addressproof-warnsize").html("File name limit exceed!");
      $("#upload2").focus();
      this.setState({ file: "" });
    } else if (
      file.type == "image/jpg" ||
      file.type == "image/jpeg" ||
      file.type == "image/png" ||
      file.type == "application/pdf"
    ) {
      this.getBase64(file)
        .then((result) => {
          file["base64"] = result;

          this.setState({
            addressproofbase64: result,
            addressproof: file.name,
            file,
          });
          $("#upload2-label").hide();
          $("#remove-file2").show();
          $("#remove-file2").css("display", "flex");
        })
        .catch((err) => {
          // 
          this.showNotification("Something went wrong");
        });

      this.setState({
        file: e.target.files[0],
      });
    } else this.showNotification("File extension not allowed!");
  };

  handleFileInputChange3 = (e) => {
    let { file } = this.state;

    file = e.target.files[0];
    if (file.size > 20971520 ) {
      this.showNotification("File too big!");
      $(".warn").hide();
      $("#workaddressproof-warnsize").show();
      $("#workaddressproof-warnsize").html("File too big!");
      $("#upload3").focus();
      this.setState({ file: "" });
    }else if (file.name.length > 100) {
      this.showNotification("File name limit exceed!");
      $(".warn").hide();
      $("#workaddressproof-warnsize").show();
      $("#workaddressproof-warnsize").html("File name limit exceed!");
      $("#upload3").focus();
      this.setState({ file: "" });
    } else if (
      file.type == "image/jpg" ||
      file.type == "image/jpeg" ||
      file.type == "image/png" ||
      file.type == "application/pdf"
    ) {
      this.getBase64(file)
        .then((result) => {
          file["base64"] = result;

          this.setState({
            workaddressproofbase64: result,
            workaddressproof: file.name,
            file,
          });
          $("#upload3-label").hide();
          $("#remove-file3").show();
          $("#remove-file3").css("display", "flex");
        })
        .catch((err) => {
          this.showNotification("Something went wrong");
        });

      this.setState({
        file: e.target.files[0],
      });
    } else this.showNotification("File extension not allowed!");
  };

  handleFileInputChange4 = (e) => {
    let { file } = this.state;

    file = e.target.files[0];
    if (file.size > 20971520  ) {
      this.showNotification("File too big!");
      $(".warn").hide();
      $("#aadhar-warnsize").show();
      $("#aadhar-warnsize").html("File name limit exceed!");
      $("#upload4").focus();
      this.setState({ file: "" });
    }else if (file.name.length > 100) {
      this.showNotification("File name limit exceed!");
      $(".warn").hide();
      $("#aadhar-warnsize").show();
      $("#aadhar-warnsize").html("File name limit exceed!");
      $("#upload4").focus();
      this.setState({ file: "" });
    } else if (
      file.type == "image/jpg" ||
      file.type == "image/jpeg" ||
      file.type == "image/png" ||
      file.type == "application/pdf"
    ) {
      this.getBase64(file)
        .then((result) => {
          file["base64"] = result;

          this.setState({
            aadharCardbase64: result,
            aadharCard: file.name,
            file,
          });
          $("#upload4-label").hide();
          $("#remove-file4").show();
          $("#remove-file4").css("display", "flex");
        })
        .catch((err) => {
          this.showNotification("Something went wrong");
        });

      this.setState({
        file: e.target.files[0],
      });
    } else this.showNotification("File extension not allowed!");
  };

  handleFileInputChange5 = (e) => {
    let { file } = this.state;

    file = e.target.files[0];
    if (file.size > 20971520  ) {
      this.showNotification("File too big!");
      $(".warn").hide();
      $("#pan-warnsize").show();
      $("#pan-warnsize").html("File too big!");
      $("#upload5").focus();
      this.setState({ file: "" });
    }if (file.name.length > 100) {
      this.showNotification("File name limit exceed!");
      $(".warn").hide();
      $("#pan-warnsize").show();
      $("#pan-warnsize").html("File name limit exceed!");
      $("#upload5").focus();
      this.setState({ file: "" });
    } else if (
      file.type == "image/jpg" ||
      file.type == "image/jpeg" ||
      file.type == "image/png" ||
      file.type == "application/pdf"
    ) {
      this.getBase64(file)
        .then((result) => {
          file["base64"] = result;

          this.setState({
            panCardbase64: result,
            panCard: file.name,
            file,
          });
          $("#upload5-label").hide();
          $("#remove-file5").show();
          $("#remove-file5").css("display", "flex");
        })
        .catch((err) => {
          // 
          this.showNotification("Something went wrong");
        });

      this.setState({
        file: e.target.files[0],
      });
    } else this.showNotification("File extension not allowed!");
  };
  handleFileInputChange6 = (e) => {
    let { file } = this.state;

    file = e.target.files[0];

    if (file.size > 20971520  || file.name.length > 100) {
      this.showNotification("File too big!");
      $(".warn").hide();
      $("#profile-warnsize").show();
      $("#profile-warnsize").html("File too big!");
      $("#upload6").focus();
      this.setState({ file: "" });
    }else if (file.name.length > 100) {
      this.showNotification("File name limit exceed!");
      $(".warn").hide();
      $("#profile-warnsize").show();
      $("#profile-warnsize").html("File name limit exceed!");
      $("#upload6").focus();
      this.setState({ file: "" });
    } else if (
      file.type == "image/jpg" ||
      file.type == "image/jpeg" ||
      file.type == "image/png" ||
      file.type == "application/pdf"
    ) {
      this.getBase64(file)
        .then((result) => {
          file["base64"] = result;

          this.setState({
            profilebase64: result,
            profile: file.name,
            file,
          });
          $("#upload6-label").hide();
          $("#remove-file6").show();
          $("#remove-file6").css("display", "flex");
        })
        .catch((err) => {
          this.showNotification("Something went wrong");
        });

      this.setState({
        file: e.target.files[0],
      });
    } else this.showNotification("File extension not allowed!");
  };
  handleFileInputChange7 = (e) => {
    let { file } = this.state;

    file = e.target.files[0];

    if (file.size > 20971520  ) {
      this.showNotification("File too big!");
      $(".warn").hide();
      $("#profilephoto-warnsize").show();
      $("#profilephoto-warnsize").html("File too big!");
      $("#upload7").focus();
      this.setState({ file: "" });
    }else if (file.name.length > 100) {
      this.showNotification("File name limit exceed!");
      $(".warn").hide();
      $("#profilephoto-warnsize").show();
      $("#profilephoto-warnsize").html("File name limit exceed!");
      $("#upload7").focus();
      this.setState({ file: "" });
    } else if (
      file.type == "image/jpg" ||
      file.type == "image/jpeg" ||
      file.type == "image/png" ||
      file.type == "application/pdf"
    ) {
      this.getBase64(file)
        .then((result) => {
          file["base64"] = result;

          this.setState({
            profilephotobase64: result,
            profilephoto: file.name,
            file,
          });
          $("#upload7-label").hide();
          $("#remove-file7").show();
          $("#remove-file7").css("display", "flex");
        })
        .catch((err) => {
          this.showNotification("Something went wrong");
        });

      this.setState({
        file: e.target.files[0],
      });
    } else this.showNotification("File extension not allowed!");
  };
  handleFileInputChange8 = (e) => {
    let { file } = this.state;

    file = e.target.files[0];

    if (file.size > 20971520  ) {
      this.showNotification("File too big!");
      $(".warn").hide();
      $("#aadharCardback-warnsize").show();
      $("#aadharCardback-warnsize").html("File too big!");
      $("#upload8").focus();
      this.setState({ file: "" });
    }else if (file.name.length > 100) {
      this.showNotification("File name limit exceed!");
      $(".warn").hide();
      $("#aadharCardback-warnsize").show();
      $("#aadharCardback-warnsize").html("File name limit exceed!");
      $("#upload8").focus();
      this.setState({ file: "" });
    } else if (
      file.type == "image/jpg" ||
      file.type == "image/jpeg" ||
      file.type == "image/png" ||
      file.type == "application/pdf"
    ) {
      this.getBase64(file)
        .then((result) => {
          file["base64"] = result;

          this.setState({
            aadharCardbackbase64: result,
            aadharCardback: file.name,
            file,
          });
          $("#upload8-label").hide();
          $("#remove-file8").show();
          $("#remove-file8").css("display", "flex");
        })
        .catch((err) => {
          this.showNotification("Something went wrong");
        });

      this.setState({
        file: e.target.files[0],
      });
    } else this.showNotification("File extension not allowed!");
  };

  handleSubmit = (e) => {
    const nameregex = /^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i;
    const pincoderegex = /^[1-9][0-9]{5}$/;
    const contactNoregex = /^[6-9]\d{9}$/;
    const emailregex = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    const aadharRegex = /^[2-9]{1}[0-9]{3}\s{1}[0-9]{4}\s{1}[0-9]{4}$/;
    const panRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    e.preventDefault();
    if (this.state.applicationType.trim() == "a") {
      $(".warn").hide();
      $("#applicationtype-warn").show();
      $("#applicationtype").focus();
    } else if (this.state.firstName.trim() == "") {
      $(".warn").hide();
      $("#firstname-warn").show();
      $("#firstname").focus();
    } else if (this.state.firstName.length > 50) {
      $(".warn").hide();
      $("#firstname-text-warn").show();
      $("#firstname").focus();
    } else if (!nameregex.test(this.state.firstName)) {
      $(".warn").hide();
      $("#firstname-invalidtext-warn").show();
      $("#firstname").focus();
    } else if (this.state.lastName.trim() == "") {
      $(".warn").hide();
      $("#lastname-warn").show();
      $("#lastname").focus();
    } else if (this.state.lastName.length > 50) {
      $(".warn").hide();
      $("#lastname-text-warn").show();
      $("#lastname").focus();
    } else if (!nameregex.test(this.state.lastName)) {
      $(".warn").hide();
      $("#lastname-invalidtext-warn").show();
      $("#lastname").focus();
    } else if (this.state.email.trim() == "") {
      $(".warn").hide();
      $("#email-warn-empty").show();
      $("#email").focus();
    } else if (this.state.email.length > 100) {
      $(".warn").hide();
      $("#email-text-warn").show();
      $("#email").focus();
    } else if (!emailregex.test(this.state.email)) {
      $(".warn").hide();
      $("#email-warn").show();
      $("#email").focus();
    } else if (this.state.contactNo.trim() == "") {
      $(".warn").hide();
      $("#mobile-warn-empty").show();
      $("#mobile").focus();
    } else if (this.state.contactNo.length > 10) {
      $(".warn").hide();
      $("#mobile-text-warn").show();
      $("#mobile").focus();
    } else if (!contactNoregex.test(this.state.contactNo)) {
      $(".warn").hide();
      $("#mobile-warn").show();
      $("#mobile").focus();
    }  
    else if (this.state.state.trim() == "") {
      $(".warn").hide();
      $("#state-warn").show();
      $("#state").focus();
      this.showNotification("Please select a state");
    } else if (this.state.state.length > 40) {
      $(".warn").hide();
      $("#state-text-warn").show();
      $("#state").focus();
    } else if (this.state.city_district.trim() == "") {
      $(".warn").hide();
      $("#city-warn").show();
      $("#city").focus();
    } else if (this.state.city_district.length > 50) {
      $(".warn").hide();
      $("#city-text-warn").show();
      $("#city").focus();
      this.showNotification("Please select district");
    }
    else {
  
      var mySentence = this.state.firstName.trim();
      mySentence = mySentence.replace(/\s+/g, " ");
      const words = mySentence.split(" ");

      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
      var mywords = words.join(" ");
      var mySentence2 = this.state.lastName.trim();
      mySentence2 = mySentence2.replace(/\s+/g, " ");
      const words2 = mySentence2.split(" ");

      for (let i = 0; i < words2.length; i++) {
        words2[i] = words2[i][0].toUpperCase() + words2[i].substr(1);
      }
      var mywords2 = words2.join(" ");

      $(".warn").hide();
      this.setState({ pay: false });
      axios
        .post(
          `${baseUrl}p=register`,
          {
            firstName: mywords.trim(),
            lastName: mywords2.trim(),
            contactNo: this.state.contactNo.trim(),
            email: this.state.email.trim(),
            idType: this.state.applicationType.trim(),
            state: this.state.state,
            city_district: this.state.city_district,
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          
          if (res.data.code == 200 && res.data.status == "success") {
            var encryptedApplicationId = encryptor.encrypt(
              res.data.applicationid
            );
            sessionStorage.setItem("applicationid", encryptedApplicationId);
            var GSt = encryptor.encrypt(res.data.GST);
            sessionStorage.setItem("GST", GSt);
            var finalprice = encryptor.encrypt(res.data.finalprice);
            sessionStorage.setItem("finalprice", finalprice);
            var amounttopay = encryptor.encrypt(res.data.amount);
            sessionStorage.setItem("amount", amounttopay);
            this.setState({ applicationid: encryptedApplicationId });
            this.setState({ pay: true });
            const encryptedContactno = encryptor.encrypt(this.state.contactNo);
            const encryptedEmail = encryptor.encrypt(this.state.email);
            const name = encryptor.encrypt(
              `${this.state.firstName} ${this.state.lastName}`
            );
            sessionStorage.setItem("contactno", encryptedContactno);
            sessionStorage.setItem("email", encryptedEmail);
            sessionStorage.setItem("name", name);

            this.setState({ toCheckout: true });
          } else if (
            res.data.code == 201 &&
            res.data.status == "alreadyexist"
          ) {
            this.showNotification(res.data.message);
            this.setState({ pay: true });
          } else if (res.data.code == 201) {
            this.showNotification(res.data.message);
            this.setState({ pay: true });
          }
        })
        .catch((err) => {
          // 
          this.showNotification("Something went wrong");
          this.setState({ pay: true });
        });
    }
  };
  onRadioChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };
  filterDistricts = () => {
    const filtered = this.state.data.filter((item) => {
      if (item.state == this.state.state) {
        this.setState({ districts: item.districts });
      }
    });
 
  };
  filterDistricts2 = () => {
    const filtered = this.state.data.filter((item) => {
      if (item.state == this.state.workstate) {
        this.setState({ workdistricts: item.districts });
      }
    });
   
  };

  showtutorial = () => {
    $("#youtubeModal").modal("show")
  }
  render() {
    const options = {
      placeholder: "Select State",
    };
    const options2 = {
      placeholder: "Select District",
    };

    return (
  

      <div className="row main-div p-0 fixheight">
        <div id="notifContainer"></div>
        <div className="overlay"></div>
        <div className="tutorial-main" onClick={() => this.showtutorial()}><img src="/img/knowmore.svg" width="20px" /></div><div className="tutorial" onClick={() => this.showtutorial()}><img src="/img/play.svg" width="20px" /></div>
        <Helmet>
          <link
            rel="stylesheet"
            href={process.env.PUBLIC_URL + "/css/select2.css"}
          />
        </Helmet>
        <div className="left-form col-lg-7 col-12 p-0">
          {/* Header------------------------------------------------------------------------------------- */}
          <header className="row justify-content-between px-2 py-2">
            <div className="fixed-top d-flex  agent-header justify-content-between">
              <a href="https://legal251.com" className="ml-2">
                <img className="logo" src="/img/Legal_logo_full.svg" alt="" />
              </a>
              <div className="d-flex align-items-center">
                <a   href="https://web.whatsapp.com/send?phone=917772877729&amp;text=Hello%20Team%20Legal251!"
                    target="_blank" className="mx-1 app">
                  <img src="/img/Grou 215.svg" height="20px" alt="" />
                </a>
                <a href="tel:7772877729"
                    target="_blank" className="mx-1 app head-text">
                  <img src="/img/phone.svg" height="20px" alt="" />
                  <span className="ml-2">(+91) 77728 77729</span>
                </a>
                {/* <a href="#" className="mx-2 my-auto head-text app">
                 
                </a> */}
                <Link to="/" className="ml-4 mr-2 agent-login head-text">
                  Login
                </Link>
                <a className="mr-lg-2 mr-4 head-text point" onClick={() => this.showCommission()}>Commission</a>
              </div>
            </div>
          </header>
          {/* BODY-------------------------------------------------------------------------------------- */}

          <div className="agent-form">
            <div>
              <span className="agent-head">
                <h2 className="text-orange main-head">Partner Registration</h2>
              </span>
            </div>
            <br />

            {/* Personal Details------------------------------------------------------------------------ */}
            <span className="type-head">
              <u>Personal details:</u>
            </span>
            <div className="row justify-content-between my-2">
              <div className="col-lg-6 col-12 appli lefttt">
                <select
                  name="application"
                  id="applicationtype"

                  className="agent-select  agent-left"
                  value={this.state.applicationType}
                  onChange={(e) => {
                    this.setState({ applicationType: e.target.value });
                  }}
                >
                  <option value="">Select Id Type</option>
                  <option value="RP">Referral Partner  (Rs 1251/-) - 10% Commission</option>
                  <option value="KP">KIOSK Partner ( Rs 3251/-) 20% Commission</option>
                  <option value="PP">Professional Partner (Rs 5251/-) - 20% Commission</option>
                </select>
                <span className="  warn" id="applicationtype-warn">
                  Please select Id Type
                </span>
              </div>
            </div>
            <div className="row justify-content-between my-2">
              <div className="col-lg-6 col-12 lefttt">
                <fieldset className="my-fieldset agent-left">
                  <legend className="agent-legend">&nbsp;First Name</legend>
                  <input
                    className="agent-input"
                    id="firstname"
                    value={this.state.firstName}
                    type="text"
                    maxLength="50"
                    autoComplete="off"
                    placeholder="Enter First Name"
                    onChange={(e) =>
                      this.setState({ firstName: e.target.value })
                    }
                  />
                </fieldset>
                <span className="  warn " id="firstname-warn">
                  Please enter your First name.
                </span>
                <span className="  warn " id="firstname-invalidtext-warn">
                  Please enter a valid Firstname.
                </span>
                <span className="  warn " id="firstname-text-warn">
                  Text too long (Max 25)
                </span>
              </div>
              <div className="col-lg-6 col-12 righttt">
                <fieldset className=" my-fieldset agent-right">
                  <legend className="agent-legend">&nbsp;Last Name</legend>
                  <input
                    className="agent-input"
                    id="lastname"
                    value={this.state.lastName}
                    type="text"
                    maxLength="50"
                    autoComplete="off"
                    placeholder="Enter Last Name"
                    onChange={(e) =>
                      this.setState({ lastName: e.target.value })
                    }
                  />
                </fieldset>
                <span className="  warn" id="lastname-invalidtext-warn">
                  Please enter a valid Lastname.
                </span>
                <span className="  warn" id="lastname-warn">
                  Please enter your Last name.
                </span>
                <span className="  warn " id="lastname-text-warn">
                  Text too long (Max 25)
                </span>
              </div>
            </div>
            <div className="row justify-content-between my-2">
              <div className="col-lg-6 col-12 lefttt">
                <fieldset className=" my-fieldset agent-left">
                  <legend className="agent-legend">&nbsp;Email</legend>
                  <input
                    className="agent-input-email"
                    id="email"
                    type="text"
                    maxLength="50"
                    autoComplete="off"
                    value={this.state.email}
                    placeholder="Enter Your Email"
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </fieldset>
                <span className="  warn" id="email-warn">
                  Please enter a valid email
                </span>
                <span className="  warn" id="email-warn-empty">
                  Please enter your email.
                </span>
                <span className="  warn " id="email-text-warn">
                  Text too long (Max 100)
                </span>
              </div>
              <div className="col-lg-6 col-12 righttt">
                <fieldset className=" my-fieldset agent-right">
                  <legend className="agent-legend">&nbsp;Mobile No.</legend>
                  <input
                    className="agent-input"
                    id="mobile"
                    type="tel"
                    autoComplete="off"
                    maxLength="10"
                    value={this.state.contactNo}
                    placeholder="Enter Your Mobile No."
                    onChange={(e) =>
                      this.setState({ contactNo: e.target.value })
                    }
                  />
                </fieldset>
                <span className="  warn" id="mobile-warn">
                  Please enter a valid Mobile No.
                </span>
                <span className="  warn" id="mobile-warn-empty">
                  Please enter your mobie no.
                </span>
                <span className="  warn " id="mobile-text-warn">
                  Mobile no. too long (Max 10)
                </span>
              </div>          
            </div>


            <div className="row justify-content-between my-2">
              <div className="col-lg-6 col-12 lefttt state-select">
                <fieldset className=" my-fieldset agent-left">
                  <legend className="agent-legend">&nbsp;State</legend>
                  <Select2
                    className="form-control"
                    id="state"
                    autoComplete="off"
                    value={this.state.state}
                    options={options}
                    onChange={(e) => {
                      this.setState({ state: e.target.value });
                      
                      this.filterDistricts();
                    }}
                    data={[
                      "Andaman and Nicobar Island (UT)",
                      "Andhra Pradesh",
                      "Arunachal Pradesh",
                      "Assam",
                      "Bihar",
                      "Chandigarh (UT)",
                      "Chhattisgarh",
                      "Dadra and Nagar Haveli (UT)",
                      "Daman and Diu (UT)",
                      "Delhi (NCT)",
                      "Goa",
                      "Gujarat",
                      "Haryana",
                      "Himachal Pradesh",
                      "Jammu and Kashmir(UT)",
                      "Jharkhand",
                      "Karnataka",
                      "Kerala",
                      "Ladakh(UT)",
                      "Lakshadweep (UT)",
                      "Madhya Pradesh",
                      "Maharashtra",
                      "Manipur",
                      "Meghalaya",
                      "Mizoram",
                      "Nagaland",
                      "Odisha",
                      "Puducherry (UT)",
                      "Punjab",
                      "Rajasthan",
                      "Sikkim",
                      "Tamil Nadu",
                      "Telangana",
                      "Tripura",
                      "Uttarakhand",
                      "Uttar Pradesh",
                      "West Bengal",
                    ]}
                  />
                </fieldset>
                <span className="  warn" id="state-warn">
                  Please select your state.
                </span>
                {/* <span className="  warn " id="state-text-warn">
                  State name too long (Max 20)
                </span> */}
              </div>
              <div className="col-lg-6 col-12 righttt district-select">
                <fieldset className=" my-fieldset agent-right">
                  <legend className="agent-legend">
                    &nbsp;City / District
                  </legend>
                  <Select2
                    className="form-control"
                    id="city"
                    value={this.state.city_district}
                    options={options2}
                    onChange={(e) => {
                      this.setState({ city_district: e.target.value });
                    }}
                    data={this.state.districts}
                  />
                </fieldset>
                <span className="  warn" id="city-warn">
                  Please select your city / district.
                </span>
                {/* <span className="  warn " id="city-text-warn">
                  City name too long (Max 50)
                </span> */}
              </div>
            </div>



            <div className="pay mb-5">
              <div className="pay-btn-div">
                {this.state.pay ? (
                  <button onClick={this.handleSubmit} className="pay-btn btn">
                    Proceed to Pay
                  </button>
                ) : (
                  <img src="/img/loader.gif" height="50px" alt="" />
                )}
              </div>

              {this.state.toCheckout ? <Redirect to="/checkout" /> : null}
              {this.state.applicationType === "a" ||  this.state.applicationType === ""
              ? 
              null
              :
              <>
              
              <div className=" mt-2 fee">
               <span className="app-fee">Your Registration Fees :  <span className="text-orange">
                   <b>&#8377;{this.state.applicationType == "RP" ? "1251" : this.state.applicationType == "KP" ? "3251" : this.state.applicationType == "PP" ? "5251" :  null} </b>{" "} 
                 </span></span>
         
              
             </div>
             </> }
            </div>
          </div>
        </div>
        <div className="image-right col-lg-5 p-0"></div>
        <div
            className="modal fade"
            id="youtubeModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered yt_modal "
              role="document"
            >
              <div className="modal-content">
                <div className="modal-body modal_b">
                  <button
                    type="button"
                    className="close close1"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      className="embed-responsive-item"
                      id="video"
                      allowscriptaccess="always"
                      src="https://www.youtube.com/embed/UG7VDgRJTcY"
                      allowFullScreen="allowfullscreen"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div
          className="modal fade"
          id="viewCommission"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered " role="document">
            <div className="modal-content back-modal comm-modal">
              <button
                type="button"
                className="border-0 bg-transparent ml-auto p-2 pr-3 text-right my-back-modal-close"
                data-dismiss="modal"
              >
                <img src={process.env.PUBLIC_URL + "/img/Page-1.svg"} />
              </button>
                  <h5 className="text-center">Commission</h5>
              <div className="modal-body border-0 text-center pb-0">
              <p className="notificationNote mt-3">
                     FOR Commission  Related Information Please CALL ON 77728-77729 AND DIAL 75.
                    <br/>कमीशन से संबंधित जानकारी के लिए कृपया 77728-77729 पर कॉल करें और 75 डायल करें
                  </p>
              </div>
              <div className="modal-footer border-0 justify-content-center mb-4">
             
              </div>
            </div>
          </div>
        </div>
      </div>
      
    );
  }
}

export default Agentregistration;

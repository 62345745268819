import $, { data } from "jquery";
function filterFn2(e) {
    const ths = $(`#${e} th`);
    let sortOrder = 1;
    ths.on("click", function () {
        if(this.firstElementChild.classList.value.includes("rot0")){
            this.firstElementChild.classList.remove("rot0")
            this.firstElementChild.classList.add("rot180")
        }else{
            this.firstElementChild.classList.add("rot0")
            this.firstElementChild.classList.remove("rot180")
        }
        const rows = sortRows(this, e);
        rebuildTbody(rows,this,e);
        sortOrder *= -1;
    })

    function sortRows(th, e) {
        const rows = $.makeArray($('#'+e+' tbody > tr'));
        const col = th.cellIndex;
        const type = th.dataset.type;
        rows.sort(function (a, b) {
            return compare(a, b, col, type) * sortOrder;
        });
        return rows;
    }

    function compare(a, b, col, type) {
        let _a = a.children[col].textContent;
        let _b = b.children[col].textContent;
        if (type === "number") {
            _a *= 1;
            _b *= 1;

        } else if (type === "string") {

            _a = _a.toLowerCase();
            _b = _b.toLowerCase();


        }

        if (_a < _b) {
            return -1;
        }
        if (_a > _b) {
            return 1;
        }
        return 0;
    }

    function rebuildTbody(rows,th) {
        const tbody = $('#'+e+' tbody');
        while (tbody.firstChild) {
            tbody.remove(tbody.firstChild);
        }
        let j;
        for (j = 0; j < rows.length; j++) {
            tbody.append(rows[j]);
        }
    }


    

}

export default filterFn2
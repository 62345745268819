import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import HeaderAgent from "./HeaderAgent";
import Footer from "./Footer";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import ScriptTag from "react-script-tag";

const key = process.env.REACT_APP_ENCRYPTOR_KEY;

const api_key = process.env.REACT_APP_API_KEY;

var encryptor = require("simple-encryptor")(key);

let name = "";
let id = "";
let email = "";
let contact = "";
let amount = "";
let finalprice = "";
let gst = "";

var sha256 = require("js-sha256");
class Checkout__AgentRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mtime: 90,
      etime: 90,
      contactOtp: "",
      emailOtp: "",
      orderid: "",
      razorpaysig: "",
      payid: "",
      paynow: false,
      sendingemail: false,
      sendingmobile: false,
      gstno: "",
    };
  }

  componentDidMount() {
    $("#emailresend-loader").hide();
    $("#mobileresend-loader").hide();
    window.scrollTo(0, 0);
    $(document.body).css("background-color", "#2d2d2d");
    name = encryptor.decrypt(sessionStorage.getItem("name"));
    id = encryptor.decrypt(sessionStorage.getItem("applicationid"));
    email = encryptor.decrypt(sessionStorage.getItem("email"));
    contact = encryptor.decrypt(sessionStorage.getItem("contactno"));
    amount = encryptor.decrypt(sessionStorage.getItem("amount"));
    gst = encryptor.decrypt(sessionStorage.getItem("GST"));
    finalprice = encryptor.decrypt(sessionStorage.getItem("finalprice"));
    
    this.countdown();
    this.countdownemail();
    if (!id) {
      this.props.history.push("/register");
    }
  }

  signatureverification = () => {
  

    const esig = sha256.hmac(
      process.env.REACT_APP_RAZORPAY_SECRETKEY,
      this.state.orderid + "|" + this.state.payid
    );

    if (esig == this.state.razorpaysig) {   
      this.props.history.push(`/register/incompletedata?partnerid=${id}`, {
        order_id: this.state.orderid,
        applicationid: id,
      });
      sessionStorage.clear();
     
    } else {sessionStorage.clear();
    this.history.push("/paymentfailed", { data: "hello" });}
  };

  countdownemail() {
    $("#emailresend-loader").hide();
    function clear() {
      clearInterval(interval);
      $("#emailresend-loader").hide();
    }
    let interval = setInterval(() => {
      if (this.state.etime == 1) {
        clear(interval);
        $("#emailresend-loader").hide();
      } else {
      }
      this.setState({ etime: this.state.etime - 1 });
    }, 1000);
  }

  countdown() {
    $("#mobileresend-loader").hide();
    function clear() {
      clearInterval(interval);
      $("#mobileresend-loader").hide();
    }
    let interval = setInterval(() => {
      if (this.state.mtime == 1) {
        clear(interval);
        $("#mobileresend-loader").hide();
      } else {
      }
      this.setState({ mtime: this.state.mtime - 1 });
    }, 1000);
  }

  handlemobileresend = () => {
    $("#invalidemailotpempty").hide();
    $("#invalidmobileotpempty").hide();
    $("#mobileresend").hide();
    $("#mobileresend-loader").show();
    $("#invalidemailotp").hide();
    $("#invalidmobileotp").hide();
   
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}p=contactOTP`,
        { applicationid: id, contactNo: contact },
        {
          headers: {
            Authkey: api_key,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        
        if (res.data.code == 200 && res.data.status == "success") {
          this.setState({ mtime: 90 });
          this.countdown();

          $("#mobileotpsent").show();
          $("#invalidmobileotp").hide();
          $("#mobileresend").show();
          $("#mobileresend-loader").hide();
        } else {
          this.showNotification(res.data.message);
          $("#mobileotpsent").hide();
          $("#sendingmobile").show();
          $("#mobileresend").show();
          $("#mobileresend-loader").hide();
        }
      })
      .catch((err) => {
        
        $("#mobileotpsent").hide();
        $("#mobileresend").show();
        $("#mobileresend-loader").hide();
        this.setState({ sendingmobile: false });
        this.showNotification("Something went wrong");
      });
  };

  handleemailresend = () => {
    $("#invalidemailotpempty").hide();
    $("#invalidmobileotpempty").hide();
    $("#emailresend").hide();
    $("#emailresend-loader").show();
    $("#invalidemailotp").hide();
    $("#invalidmobileotp").hide();

    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}p=emailOTP`,
        { applicationid: id, email: email },
        {
          headers: {
            Authkey: api_key,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        
        if (res.data.code == 200 && res.data.status == "success") {
          $("#emailotpsent").show();
          $("#invalidemailotp").hide();
          this.setState({ sendingemail: true });
          this.setState({ etime: 90 });
          $("#emailresend").show();
          $("#emailresend-loader").hide();
          this.countdownemail();
        } else {
          $("#emailresend").show();
          $("#emailresend-loader").hide();
          this.showNotification(res.data.message);
          $("#emailotpsent").hide();
          this.setState({ sendingemail: false });
        }
      })
      .catch((err) => {
        
        $("#emailresend").show();
        $("#emailresend-loader").hide();
        $("#emailotpsent").hide();
        this.showNotification("Something went wrong");
        this.setState({ sendingemail: false });
      });
  };
  displayRazorpay = () => {
    $("#invalidemailotpempty").hide();
    $("#invalidmobileotpempty").hide();
    $("#invalidemailotp").hide();
    $("#invalidmobileotp").hide();
    $("#emailotpsent").hide();
    $("#mobileotpsent").hide();
    $("#invalidgstno").hide();
    let gstregex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    this.setState({ paynow: true });
    if (this.state.emailOtp.trim() == "" && this.state.contactOtp.trim() == "") {
      $("#invalidemailotpempty").show();
      $("#invalidmobileotpempty").show();
      this.setState({ paynow: false });
    } else if (this.state.contactOtp.trim() == "") {
      $("#invalidmobileotpempty").show();
      this.setState({ paynow: false });
    } else if (isNaN(this.state.contactOtp)) {
      $("#invalidmobileotp").show();
      this.setState({ paynow: false });
    } else if (this.state.contactOtp.length > 6 || this.state.contactOtp < 6) {
      $("#invalidmobileotp").show();
      this.setState({ paynow: false });
    } else if (this.state.emailOtp.trim() == "") {
      $("#invalidemailotpempty").show();
      this.setState({ paynow: false });
    } else if (isNaN(this.state.emailOtp)) {
      $("#invalidemailotp").show();
      this.setState({ paynow: false });
    } else if (this.state.emailOtp.length > 6 || this.state.emailOtp < 6) {
      $("#invalidemailotp").show();
      this.setState({ paynow: false });
    } else if (
      this.state.gstno.trim() != "" &&
      !gstregex.test(this.state.gstno)
    ) {
      $("#invalidgstno").show();
      this.setState({ paynow: false });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}p=createOrder`,
          {
            applicationid: id,
            contactOTP: this.state.contactOtp,
            emailOTP: this.state.emailOtp,
            currency: "INR",
            gstno: null,
            govtfees: null,
          },
          {
            headers: {
              Authkey: api_key,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          

          if (res.data.code == 200 && res.data.status == "success") {
            this.setState({ paynow: false });
            $("#invalidemailotp").hide();
            $("#invalidmobileotp").hide();
            $("#invalidgstno").hide();
            // this.showNotification("success");
            this.setState({ orderid: res.data.orderid });
            var options = {
              key: process.env.REACT_APP_RAZORPAY_KEY,
              amount: "108000",
              currency: "INR",
              name: "Legal 251",
              description: "Agent Registration",
              image: "https://legal251.com/images/common/legal.svg",
              order_id: this.state.orderid,
              handler: (response) => {
               
                this.setState({ razorpaysig: response.razorpay_signature });
                if (
                  response.razorpay_payment_id != null &&
                  response.razorpay_payment_id.trim() != "" &&
                  response.razorpay_payment_id != false
                ) {
                  this.setState({ payid: response.razorpay_payment_id });
                  this.signatureverification();
                } else {
                  sessionStorage.clear();
                  this.history.push("/paymentfailed", { data: "hello" });
                }
              },
              prefill: {
                name: name,
                email: email,
                contact: contact,
              },
              notes: {
                requestid: id,
                from: "AGP",
                servicetype: "RE",
                agentid: "",
                userid: "",
                couponstatus: "",
                couponcode: "",
                discount: "",
                gstnumber: this.state.gstno,
                upgrade: "false",
                renew: "false",
                paymentmode:"gateway",
                invoice:"bill"
              },
              theme: {
                color: "#f8951d",
              },
            };
            var rzp1 = new window.Razorpay(options);
            rzp1.on("payment.failed", function (response) {
              sessionStorage.clear();
              this.history.push("/paymentfailed");
            });
            rzp1.open();
          } else if (
            res.data.code == 201 &&
            res.data.status == "contactotpinvalid"
          ) {
        
            $("#invalidmobileotp").show();
            $("#invalidemailotp").hide();
            this.setState({ paynow: false });
          } else if (
            res.data.code == 201 &&
            res.data.status == "emailotpinvalid"
          ) {
          
            $("#invalidemailotp").show();
            this.setState({ paynow: false });

            $("#invalidmobileotp").hide();
          } else if (
            res.data.code == 201 &&
            res.data.status == "bothotpinvalid"
          ) {
            $("#invalidemailotp").show();
            $("#invalidmobileotp").show();
            this.setState({ paynow: false });
          } else {
            this.showNotification(res.data.message);
            $("#invalidemailotp").hide();
            $("#invalidmobileotp").hide();
            this.setState({ paynow: false });
          }
        })
        .catch((err) => {
          
          this.showNotification("Something went wrong");
          this.setState({ paynow: false });
        });
    }
  };

  backtoRegister = () => {
    sessionStorage.clear();
    this.props.history.push("/register", "");
  };

  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };

  render() {
    return (
      <div className="cont">
        {/* {encryptor.decrypt(sessionStorage.getItem("applicationid")) == null ? (
          <Redirect to="/register" />
        ) : null} */}
        <div id="notifContainer"></div>
        <div className="overlay"></div>
        <Helmet>
          <link
            rel="stylesheet"
            href={process.env.PUBLIC_URL + "/css/select2.css"}
          />
        </Helmet>

        <HeaderAgent />

        {/* <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter">
  Launch demo modal
</button> */}

        <div
          className="modal fade"
          id="backtoRegister"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered " role="document">
            <div className="modal-content back-modal">
              <button
                type="button"
                className="border-0 bg-transparent ml-auto p-2 pr-3 text-right my-back-modal-close"
                data-dismiss="modal"
              >
                <img src={process.env.PUBLIC_URL + "/img/Page-1.svg"} />
              </button>

              <div className="modal-body border-0 text-center pb-0">
                <h4 className="my-warn-text ">
                  Do you really want to go back ?
                </h4>
                <img
                  className="p-2"
                  src={process.env.PUBLIC_URL + "/img/Octicons.svg"}
                  width="80px"
                />
                <p className="my-warn-para m-0">
                  All of your data will be lost !!
                </p>
              </div>
              <div className="modal-footer border-0 justify-content-center mb-4">
                <button
                  type="button"
                  onClick={this.backtoRegister}
                  data-dismiss="modal"
                  className="btn backtoregister-btn text-white text-white p-2 pl-3 pr-3"
                >
                  Back to Registration page
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <img
          src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
          className="sidebaropenicon"
        /> */}
        <img
          src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
          // onClick={this.backtoRegister}
          data-toggle="modal"
          data-target="#backtoRegister"
          className="sidebarbackicon"
        />
        <img
          src={process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"}
          className="sidebarexpandicon"
        />

        <div className="overlay1"></div>
        <div className="sidebar sidebarshift">
          <div className="side_div_2 sidebar_txt active">
            <Link to="/dashboard">Overview</Link>
          </div>
          <div className="side_div_1 sidebar_txt">
            <Link to="/myservices">My Services</Link>
          </div>
          <div className="side_div_1 sidebar_txt">
            <a href="#">Payments Invoices</a>
          </div>
          <div className="side_div_1 sidebar_txt">
            <a href="#">Documents Record</a>
          </div>
          <div className="side_div_1 sidebar_txt">
            <a href="#">Add service</a>
          </div>
        </div>
        <div className="arrow-left2 visibilityhidden"></div>
        <div className="white_div expandwhite_div">
          <div className="white_box">
            <div className="white_boxscrolldiv">
              <div className="checkoutmainpadding">
                <div className="row">
                  <div className="col-md-7 customcol1">
                    <p className="reviewheading">Review your order</p>
                    <div className="row">
                      <div className="col-sm-7 customcol3">
                        <center>
                          {" "}
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/img/review_your_order.svg"
                            }
                            className="revieworderimg"
                          />
                        </center>
                      </div>
                      <div className="col-sm-7 customcol4">
                        <div className="Packagediv">
                          <p className="Packageheading">Agent Kit Including :-</p>
                          <br />
                          <div className="Packagedivinside">
                            <p className="paymentpoint paymentpointone">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/img/Icon_awesome-location-arrow.svg"
                                }
                                className="registerarrow"
                              />
                             Promotional Flex
                            </p>

                            <p className="paymentpoint">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/img/Icon_awesome-location-arrow.svg"
                                }
                                className="registerarrow"
                              />{" "}
                              Promotional Pamphlets
                            </p>
                            <p className="paymentpoint">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/img/Icon_awesome-location-arrow.svg"
                                }
                                className="registerarrow"
                              />{" "}
                             Authorisation Certificate 
                            </p>
                            <p className="paymentpoint">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/img/Icon_awesome-location-arrow.svg"
                                }
                                className="registerarrow"
                              />{" "}
                             Documents & Price Booklet
                            </p>
                            <p className="paymentpoint">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/img/Icon_awesome-location-arrow.svg"
                                }
                                className="registerarrow"
                              />{" "}
                             Commission Sheet
                            </p>
                            <p className="paymentpoint">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/img/Icon_awesome-location-arrow.svg"
                                }
                                className="registerarrow"
                              />{" "}
                              Dashboard Access
                            </p>
                            <p className="paymentpoint">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/img/Icon_awesome-location-arrow.svg"
                                }
                                className="registerarrow"
                              />{" "}
                              T-Shirt & ID (Not for all)
                            </p>
                            <p className="paymentpoint">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/img/Icon_awesome-location-arrow.svg"
                                }
                                className="registerarrow"
                              />{" "}
                              Stickers ( Not for all)
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 customcol2">
                    <div className="detailsdivision">
                      <div className="detailsdivisionpadding">
                        <p className="Detailsheading">Details:</p>
                        <div className="detailsdivisioninsidepadding">
                          <div className="row">
                            <div className="col-5">
                              <p className="serviceregistername">
                                Application ID:
                              </p>
                            </div>
                            <div className="col-7">
                              <p className="serviceregistername">
                                {encryptor.decrypt(
                                  sessionStorage.getItem("applicationid")
                                )}
                              </p>
                            </div>
                            <div className="col-5">
                              <p className="serviceregistername2">
                                Mobile OTP:
                              </p>
                            </div>

                            <div className="col-7">
                              <input
                                type="tel"
                                className="codeinput"
                                placeholder="OTP"
                                maxLength="6"
                                value={this.state.contactOtp}
                                onChange={(e) =>
                                  this.setState({ contactOtp: e.target.value })
                                }
                              />
                              {this.state.mtime != 0 ? (
                                <button className="applybtn op" disabled>
                                  Resend ({this.state.mtime} s)
                                </button>
                              ) : (
                                <>
                                  <button
                                    id="mobileresend"
                                    className="applybtn "
                                    onClick={this.handlemobileresend}
                                  >
                                    Resend
                                  </button>
                                  <img
                                    id="mobileresend-loader"
                                    src="/img/loader.gif"
                                    height="50px"
                                    alt=""
                                  />{" "}
                                </>
                              )}

                              <p className="" id="mobileotpsent">
                                OTP sent successfully{" "}
                              </p>
                              <p className="" id="invalidmobileotp">
                                <img
                                  className="mr-2"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/img/Icon_feather-alert-circle.svg"
                                  }
                                />
                                &nbsp; Invalid OTP
                              </p>

                              <p className="warn" id="invalidmobileotpempty">
                                <img
                                  className="mr-2"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/img/Icon_feather-alert-circle.svg"
                                  }
                                />
                                &nbsp; Please enter mobile OTP
                              </p>
                            </div>
                            <div className="col-5">
                              <p className="serviceregistername2">Email OTP:</p>
                            </div>

                            <div className="col-7">
                              <input
                                type="tel"
                                className="codeinput"
                                placeholder="OTP"
                                maxLength="6"
                                value={this.state.emailOtp}
                                onChange={(e) =>
                                  this.setState({ emailOtp: e.target.value })
                                }
                              />
                              {this.state.etime != 0 ? (
                                <button className="applybtn op" disabled>
                                  Resend ({this.state.etime} s)
                                </button>
                              ) : (
                                <>
                                  <button
                                    className="applybtn"
                                    id="emailresend"
                                    onClick={this.handleemailresend}
                                  >
                                    Resend
                                  </button>
                                  <img
                                    id="emailresend-loader"
                                    src="/img/loader.gif"
                                    height="50px"
                                    alt=""
                                  />{" "}
                                </>
                              )}

                              <p className="" id="emailotpsent">
                                OTP sent successfully{" "}
                              </p>
                              <p className="" id="invalidemailotp">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/img/Icon_feather-alert-circle.svg"
                                  }
                                  className="codemessageimg"
                                />{" "}
                                &nbsp; Invalid OTP
                              </p>
                              <p className="warn" id="invalidemailotpempty">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/img/Icon_feather-alert-circle.svg"
                                  }
                                  className="codemessageimg"
                                />{" "}
                                &nbsp; Please enter email OTP
                              </p>
                            </div>
                            <div className="col-5">
                              <p className="serviceregistername2">Fees:</p>
                            </div>
                            <div className="col-7">
                              <p className="serviceregistername2">
                                ₹ {amount}/-
                              </p>
                            </div>
                            <div className="col-5">
                              <p className="serviceregistername2 ">
                                GST (18%) :
                              </p>
                            </div>
                            <div className="col-7">
                              <p className="serviceregistername2">₹ {gst}/-</p>
                            </div>
                            <div id="accordion" className="col-12 gstAccordion">
                              <div className="card gstnoCard bg-transparent col-12 w-100  p-0">
                                <div
                                  className="card-header pb-0 gstnoHeader pl-0"
                                  id="headingOne"
                                >
                                  <div className="col-12 p-0">
                                    <p
                                      className="serviceregistername2 addGst"
                                      data-toggle="collapse"
                                      data-target="#collapseOne"
                                      aria-expanded="false"
                                      aria-controls="collapseOne"
                                    >
                                      Add GST No. +
                                    </p>
                                  </div>
                                </div>

                                <div
                                  id="collapseOne"
                                  className="collapse"
                                  aria-labelledby="headingOne"
                                  data-parent="#accordion"
                                >
                                  <div className="card-body row pl-0">
                                    <div className="col-5  pr-0" id="gstnumber">
                                      <p className="serviceregistername2">
                                        GST No :
                                      </p>
                                    </div>
                                    <div className="col-lg-7 col-12 row">
                                      <div
                                        className="  col-12 "
                                        id="gstnoInput"
                                      >
                                        <input
                                          type="text"
                                          className="codeinput gstno"
                                          placeholder="GST no."
                                          maxLength="15"
                                          value={this.state.gstno}
                                          onChange={(e) =>
                                            this.setState({
                                              gstno:
                                                e.target.value.toUpperCase(),
                                            })
                                          }
                                        />
                                      </div>
                                      <p className="col-12" id="invalidgstno">
                                        <img
                                          className="mr-2"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/img/Icon_feather-alert-circle.svg"
                                          }
                                        />
                                        &nbsp; Please enter a valid GST no.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className="checkouthrpadding">
                        <hr className="checkoutdivhr" />
                      </div>
                      <div className="grandtotalpadding">
                        <div className="row">
                          <div className="col-5">
                            <p className="totalheading">Grand Total:</p>
                          </div>
                          <div className="col-7">
                            <p className="totalheading">₹ {finalprice}/-</p>
                          </div>
                        </div>
                      </div>
                      <div className="paynowpadding">
                        <center>
                          {" "}
                          {this.state.paynow ? (
                            <img src="/img/loader.gif" height="50px" alt="" />
                          ) : (
                            <button
                              className="paynowbtn"
                              onClick={this.displayRazorpay}
                            >
                              PAY NOW{" "}
                              <img
                                src={
                                  process.env.PUBLIC_URL + "/img/longarrow.svg"
                                }
                                alt=""
                              />
                            </button>
                          )}
                          <img
                            src={process.env.PUBLIC_URL + "/img/loader.gif"}
                            className="paymentloader"
                          />
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScriptTag src="https://checkout.razorpay.com/v1/checkout.js" />
        <Footer />
      </div>
    );
  }
}

export default Checkout__AgentRegistration;

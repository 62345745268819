import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "./Header";
import Footer from "./Footer";
import $, { data } from "jquery";
import axios from "axios";
import { Redirect } from "react-router";
import filterFn2 from "./TargetedFilterFcn";
import Sidebar from "./Sidebar";

var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);
let baseUrl = process.env.REACT_APP_API_BASE_URL;
let Decagentid;
let Decjwttoken;
export class Paymentinvoicesagent extends Component {
    constructor(props) {
        super(props);
    
      
    this.state = {
      mydata: [],
      mySearch:[],
      filteredData: [],
      filter: false,
      pageCount: 0,   
      userid: "",
      currentPage: 0,
      entry: 10,
      latest: true,
      searchinputtype: "",
      searchinput: "",
      searchdone: false,
    };
  }
    
  searchdata = () => {
    $("#payagent-empty").hide()
    axios
      .post(
        `${baseUrl}p=searchUser`,
        {
          agentId: Decagentid,
          type: this.state.searchinputtype.trim(),
          value: this.state.searchinput.trim(),
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        $("#searchfieldpay").removeAttr('disabled');
        
        if(res.data.code == "200" && res.data.status == "success"){
          this.setState({mySearch: res.data.myUser, searchdone: true})
          if(res.data.myUser.length == 0){
            $("#payagent-empty").show()}
        }else {
          this.showNotification(res.data.message)
        }
      }).catch(err => {
        $("#searchfieldpay").removeAttr('disabled');
        // this.showNotification("Something went wrong");
      })
  };
  handleSearchinput = (e) => {
    $("#searchfieldpay").attr("disabled", "disabled")
    e.preventDefault();
    const contactNoregex = /^[6-9]\d{9}$/;
    const emailregex = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
   
    if(this.state.searchinput.trim() != "" && this.state.searchinput.length < 100){
    $("#payagent-loader").show()
    if (emailregex.test(this.state.searchinput)) {
      this.setState({ searchinputtype: "email" }, this.searchdata);
    } else if (contactNoregex.test(this.state.searchinput)) {
      
      this.setState({ searchinputtype: "contact" }, this.searchdata);
    } else if (isNaN(this.state.searchinput)) {
      var mySentence = this.state.searchinput.trim();
      mySentence=mySentence.replace(/\s+/g, " ");
      const words = mySentence.split(" ");
      for (let i = 0; i < words.length; i++) {
          words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
    var mywords= words.join(" ");
    this.setState({ searchinputtype: "username" });
      this.setState({ searchinput: mywords.trim() }, this.searchdata);
    } else if (!isNaN(this.state.searchinput)) {
      this.setState({ searchinputtype: "userId" }, this.searchdata);
    } else {
      this.showNotification("Invalid Search");
    }}else { this.setState({searchdone: false});
    $("#searchfieldpay").removeAttr('disabled');
    this.setState({searchinput: ""})
    this.showNotification("No data found")
  }
  };
  rendersearch= () => {

    $(".filter-sort-btn").attr("disabled" , true)
    $("#payagent-loader").hide()
    return this.state.mySearch.map((data, index) => {
      return (
        <tr key={index}>
          <td scope="row" className="sno">
            {index + 1}
          </td>
          <td className="userid-pay userid-td">{data.userId}</td>
          <td className="username-pay">{data.username}</td>
          <td className="email-pay">{data.emailverifySt == true ? <span className="text-success">{data.email}</span> : <span className="text-danger">{data.email}</span>}</td>
          <td className="contact-pay ">{data.contactverifySt == true ? <span className="text-success">{data.contact}</span> : <span className="text-danger">{data.contact}</span>}</td>
          <td className="services-pay">
            {data.services.length == 0 ? <span >-</span> :data.services.map((numbers, index) => {
              
              if (index == data.services.length - 1) {
                return <span key={index}>{numbers}</span>;
              } else {
                return <span key={index}>{numbers + ","}</span>;
              }
            })}
          </td>
          {/* <td className="status-pay">{data.status}</td> */}
          <td className="details-invoice">
            <button
              className="agent__viewbtn mt-2"
              onClick={() => this.clickViewBtn(data)}
            >
              View <img src={process.env.PUBLIC_URL + "/img/Path 19498.svg"} />
            </button>
          </td>
        </tr>
      );
    });
  }
  fetchData = () => {
    $("#payagent-loader").show()
    $("#payagent-empty").hide()
    axios
      .post(
        `${baseUrl}p=myUser`,
        {
          agentId: Decagentid,
          pageCount: this.state.currentPage + 1,
          entry: this.state.entry
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
auth: {
    username: process.env.REACT_APP_API_USERNAME,
    password:  process.env.REACT_APP_API_PASSWORD
  }
        }
      )
      .then((res) => {
        
        if(res.data.code == 200 && res.data.status == "success"){
          $("#payagent-loader").hide()
          if(res.data.myUser.length == 0){
            $("#payagent-empty").show()
          } 
        this.setState({ mydata: res.data.myUser });
        this.setState({ pageCount: res.data.totalpageCount });
        }else {
          $("#payagent-loader").hide()
          this.showNotification("Something went wrong");
        }
      })
      .catch((err) => {
        $("#payagent-loader").hide()
        this.showNotification("Something went wrong");
      });
  };

  clickViewBtn = (data) => {
    localStorage.setItem("relleIsugad", encryptor.encrypt(data.userId));
    localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
    this.props.history.push({
      pathname: `/paymentinvoices/${data.userId}/paymentinvoice`, state: {name: data.username, email: data.email, contact: data.contact}
    });
  };
    
      componentDidMount = () => {
        window.scrollTo(0, 0);
        $(document.body).css("background-color", "#2d2d2d");
        let mainagentid = localStorage.getItem("qazxswedcvfrtgb");
        Decagentid = encryptor.decrypt(mainagentid);
        let mainjwttoken = localStorage.getItem("toeljgtkewlna");
        Decjwttoken = encryptor.decrypt(mainjwttoken);
        this.fetchData();
        filterFn2("invoiceTable")
      };
      handlePageClick = ({ selected: selectedPage }) => {
        this.setState({mydata: []})
        this.setState({currentPage:selectedPage},this.fetchData)
      };
     
      showNotification = (message) => {
        const notifContainer = document.getElementById("notifContainer");
        const notif = document.createElement("div");
        notif.classList.add("notifBox");
        notif.innerText = message;
        notifContainer.appendChild(notif);
        setTimeout(() => $(notif).addClass("pop"), 10);
        setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
      };

      renderlatestdata= () => {
        $("#payagent-empty").hide();
        $(".filter-sort-btn").removeAttr("disabled")
        if (!this.state.filter) {
          if(this.state.mydata.length == 0){
            $("#payagent-empty").show();
          }else{
      return  this.state.mydata.map((data , index) => {
          return (
            <tr key={index}>
              <td scope="row" className="sno">
              {(this.state.entry*(this.state.currentPage)) + index + 1 }
              </td>
              <td className="userid-pay userid-td">{data.userId}</td>
              <td className="username-pay">
              {data.username}
              </td>
              <td className="email-pay">
              {data.emailverifySt == true ? <span className="text-success">{data.email}</span> : <span className="text-danger">{data.email}</span>}
              </td>
              <td className="contact-pay ">{data.contactverifySt == true ? <span className="text-success">{data.contact}</span> : <span className="text-danger">{data.contact}</span>}</td>
              <td className="services-pay">
              {data.services.length == 0 ? <span >-</span> :data.services.map((numbers, index) => {
            
            if (index == data.services.length - 1) {
              return <span key={index}>{numbers}</span>;
            } else {
              return (
                <span key={index}>{numbers + ","}</span>
              );
            }
          })}
              </td>
            
              <td className="details-invoice">
              <button
                className="agent__viewbtn mt-2"
                onClick={() => this.clickViewBtn(data)}
              >
                View{" "}
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/img/Path 19498.svg"
                  }
                />
              </button>
              </td>
            </tr>
          );
        })}}else {
          if (this.state.filteredData.length == 0) {
            $("#payagent-empty").show();
          } else 
          return  this.state.filteredData.map((data , index) => {
            return (
              <tr key={index}>
                <td scope="row" className="sno">
                {(this.state.entry*(this.state.currentPage)) + index + 1 }
                </td>
                <td className="userid-pay userid-td">{data.userId}</td>
                <td className="username-pay">
                {data.username}
                </td>
                <td className="email-pay">
                {data.email}
                </td>
                <td className="contact-pay ">{data.contact}</td>
                <td className="services-pay">
                {data.services.length == 0 ? <span >-</span> :data.services.map((numbers, index) => {
              
              if (index == data.services.length - 1) {
                return <span key={index}>{numbers}</span>;
              } else {
                return (
                  <span key={index}>{numbers + ","}</span>
                );
              }
            })}
                </td>
              
                <td className="details-invoice">
                <button
                  className="agent__viewbtn mt-2"
                  onClick={() => this.clickViewBtn(data)}
                >
                  View{" "}
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/img/Path 19498.svg"
                    }
                  />
                </button>
                </td>
              </tr>
            );
          })
        }
      }

      renderoldest = ( ) => {
        $("#payagent-empty").hide();
        $(".filter-sort-btn").removeAttr("disabled")
        if (!this.state.filter) {
          if(this.state.mydata.length == 0){
            $("#payagent-empty").show();
          }else{
        return this.state.mydata.slice().reverse().map((data , index) => {
          return (
            <tr key={index}>
              <td scope="row" className="sno">
              {(this.state.entry*(this.state.currentPage)) + index + 1 }
              </td>
              <td className="userid-pay userid-td">{data.userId}</td>
              <td className="username-pay">
              {data.username}
              </td>
              <td className="email-pay">
              {data.emailverifySt == true ? <span className="text-success">{data.email}</span> : <span className="text-danger">{data.email}</span>}
              </td>
              <td className="contact-pay ">{data.contactverifySt == true ? <span className="text-success">{data.contact}</span> : <span className="text-danger">{data.contact}</span>}</td>
              <td className="services-pay">
              {data.services.length == 0 ? <span >-</span> : data.services.map((numbers, index) => {
            
            if (index == data.services.length - 1) {
              return <span key={index}>{numbers}</span>;
            } else {
              return (
                <span key={index}>{numbers + ","}</span>
              );
            }
          })}
              </td>
            
              <td className="details-invoice">
                  <button
                className="agent__viewbtn mt-2"
                onClick={() => this.clickViewBtn(data)}
              >
                View{" "}
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/img/Path 19498.svg"
                  }
                />
              </button>
              </td>
            </tr>
          );
        })}}else {
          if (this.state.filteredData.length == 0) {
            $("#payagent-empty").show();
          } else  return this.state.filteredData.slice().reverse().map((data , index) => {
            return (
              <tr key={index}>
                <td scope="row" className="sno">
                {(this.state.entry*(this.state.currentPage)) + index + 1 }
                </td>
                <td className="userid-pay userid-td">{data.userId}</td>
                <td className="username-pay">
                {data.username}
                </td>
                <td className="email-pay">
                {data.email}
                </td>
                <td className="contact-pay ">{data.contact}</td>
                <td className="services-pay">
                {data.services.length == 0 ? <span >-</span> :data.services.map((numbers, index) => {
              
              if (index == data.services.length - 1) {
                return <span key={index}>{numbers}</span>;
              } else {
                return (
                  <span key={index}>{numbers + ","}</span>
                );
              }
            })}
                </td>
              
                <td className="details-invoice">
                    <button
                  className="agent__viewbtn mt-2"
                  onClick={() => this.clickViewBtn(data)}
                >
                  View{" "}
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/img/Path 19498.svg"
                    }
                  />
                </button>
                </td>
              </tr>
            );
          })
        }
      }

      filterItrf = (t) => {
        $("#payagent-empty").hide();
        if(t == "all"){
        this.setState({ filter: false }, this.state.latest?this.renderlatestdata: this.renderoldest);
      }else{
        this.setState({ filter: true });
        let filter = this.state.mydata.filter((el) => {
      
          if (el.services.includes(t)) {
            return true;
          }
        });
        this.setState({ filteredData: filter }, this.state.latest?this.renderlatestdata: this.renderoldest);
        
      }
      };
      render() {

        if (
          localStorage.getItem("gglltakoinoeenl") == process.env.REACT_APP_LOGIN_KEY &&
          localStorage.getItem("toeljgtkewlna") != null &&
          localStorage.getItem("qazxswedcvfrtgb") != null
        ) {
        
        return (
          <div>
            <div className="cont">
              <div id="notifContainer"></div>
              <Helmet>
                <link
                  rel="stylesheet"
                  href={process.env.PUBLIC_URL + "/css/select2.css"}
                />
                <link rel="stylesheet" href="css/services.css" />
              </Helmet>
    
              <Header />
    
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL +"/img/Path_217.svg"}
                className="sidebaropenicon"
              />
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL +"/img/Path_231.svg"}
                className="sidebarbackicon"
              />
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL +"/img/Icon_ionic-ios-arrow-down.svg"}
                className="sidebarexpandicon"
              />
    
              <div className="overlay1"></div>
              <Sidebar/>
    
              <div className="arrow-left4"></div>
              <div className="white_div">
                <div className="white_box">
                  <div className="row main-row">
                  <div className="row justify-content-lg-between justify-content-center align-items-center w-100 mb-3 ml-3 mr-3">
                  <div className="row col-lg-7 col-12 justify-content-lg-between justify-content-center ">
                  <div className="col-lg-6 col-12">
                      <form onSubmit={this.handleSearchinput}>
                        <input
                          type="text"
                          className="agent__overview__search_box"
                          placeholder="Search by UserId or name"
                          id="searchfieldpay"
                          value={this.state.searchinput}
                          maxLength="100"
                          autoComplete="off"
                          onChange={(e) =>
                            {this.setState({ searchinput: e.target.value, searchdone: false, mySearch: []  })}
                          }
                        />
                      </form>
                    </div>
                    <h4 className="agentheader-text text-center col-lg-3 col-12 mr-lg-5 mr-0">User Payments / Invoices</h4>
                      </div>
                     
                      <div className="  pl-0 button-bar">
                        <div className="dropdown  px-0 px-2 pl-lg-4">
                          <button
                            className="btn agent-header-btn header-btn py-1 px-md-2 px-lg-4 px-2 filter-sort-btn"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <img src={process.env.PUBLIC_URL +"/img/Path 19648.svg"} alt="" />
                            <span>Sort by</span>
                          </button>
                          <div
                            className="dropdown-menu drop myser-mar-l-05"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <a
                              className="dropdown-item cursorpointer"
                              onClick={() => this.setState({latest: true})}
                            >
                              Latest to Lowest
                            </a>
                            <a
                              className="dropdown-item cursorpointer"
                              onClick={() => this.setState({latest: false})}
                            >
                              Lowest to Latest
                            </a>
                          </div>
                        </div>
                        <div className="dropdown">
                              <button
                                className="btn agent-header-btn header-btn py-1 px-md-2 px-lg-4 px-2 filter-sort-btn"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img
                                  alt="loading..."
                                  src={
                                    process.env.PUBLIC_URL + "/img/Path 210.svg"
                                  }
                                />
                                <span>Filter by</span>
                              </button>
                              <div
                                className="dropdown-menu drop agent__dropdown__filter dash-mar-l-04"
                                aria-labelledby="dropdownMenuButton"
                              >
                                     <a
                                  className="dropdown-item cursorpointer"
                                  onClick={() => this.filterItrf("all")}
                                >
                                 All
                                </a>
                                <a
                                  className="dropdown-item cursorpointer"
                                  onClick={() => this.filterItrf("GSTR")}
                                >
                                 GSTR
                                </a>
                                <a
                                  className="dropdown-item cursorpointer"
                                  onClick={() => this.filterItrf("GSTRF")}
                                >
                                   GSTRF
                                </a>
                                <a
                                  className="dropdown-item cursorpointer"
                                  onClick={() => this.filterItrf("ITRF")}
                                >
                                   ITRF
                                </a>
                                <a
                                  className="dropdown-item cursorpointer"
                                  onClick={() => this.filterItrf("TR")}
                                >
                                   TR
                                </a>
                                <a
                                  className="dropdown-item cursorpointer"
                                  onClick={() => this.filterItrf("OA")}
                                >
                                   OA
                                </a>
                                <a
                                  className="dropdown-item cursorpointer"
                                  onClick={() => this.filterItrf("GUMASTA")}
                                >
                                   GUMASTA
                                </a>
                                <a
                                  className="dropdown-item cursorpointer"
                                  onClick={() => this.filterItrf("TradeLicence")}
                                >
                                   Trade Licence
                                </a>
                                <a
                                  className="dropdown-item cursorpointer"
                                  onClick={() => this.filterItrf("FoodLicence")}
                                >
                                   Food Licence
                                </a>
                              </div>
                            </div>
                        <div className="dropdown ml-md-0 ml-lg-2 ml-2 px-2">
                         
                        <select className="agent__selectcateselectagent filter-sort-btn" value={this.state.entry} onChange={(e) => {this.setState({entry: e.target.value, mydata: [], pageCount:0})
                      this.handlePageClick({selected: 0})}}>
                    <option value="10" className="selectcateselectoption2">
                      10
                    </option>
                    <option value="20" className="selectcateselectoption2">
                      20
                    </option>
                    <option value="50" className="selectcateselectoption2">
                      50
                    </option>
                    <option value="100" className="selectcateselectoption2">
                      100
                    </option>
                  </select>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive my-table">
                      <table className="table mr-3" id="invoiceTable">
                        <thead className="my-thead ">
                          <tr className="invoiceTable">
                            <th className="my-th sno-pay" data-type="number">Sno <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt=""/></th>
                            <th className="my-th userid-pay">User Id <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt=""/></th>
                            <th className="my-th username-pay">Username <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt=""/></th>
                            <th className="my-th email-pay">Email <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt=""/></th>
                            <th className="my-th contact-pay ">Contact <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt=""/></th>
                            <th className="my-th services-pay">Services <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt=""/></th>
                            
                            <th className="my-th details-invoice">View Payments / Invoices <img className="arrowImg rot0" src={process.env.PUBLIC_URL + "/img/downArrow.png"} height="15px" alt=""/></th>
                          </tr>
                        </thead>
                       
                    <div className="loader-abs" id="payagent-loader"><img src="/img/loader.gif" /></div>
                    
                    <div className="empty-abs" id="payagent-empty"><img src="/img/quiteempty.svg" className="nullImg" /></div>
                   
                        <tbody className="my-tbody my-tbodysupport">
                          {this.state.searchdone?this.rendersearch():
                         this.state.latest?
                         this.renderlatestdata() : this.renderoldest()}
                            
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="mt-3 w-100">
                    <ReactPaginate
                      previousLabel={<img src={process.env.PUBLIC_URL +"/img/Path_21772.svg"} width="15px" />}
                      nextLabel={<img src={process.env.PUBLIC_URL +"/img/Path_21773.svg"} width="15px" />}
                      pageCount={this.state.pageCount}
                      onPageChange={this.handlePageClick}
                      forcePage={this.state.currentPage}
                      containerClassName={"pagination"}
                      previousLinkClassName={"pagination__link"}
                      nextLinkClassName={"pagination__link"}
                      disabledClassName={"pagination__link--disabled"}
                      activeClassName={"pagination__link----active"}
                    />
                  </div>
                </div>
              </div>
    
              <Footer />
            </div>
       
          </div>
        );
      } else {
        return <Redirect to="/" />;
      }
      }
    }
export default Paymentinvoicesagent

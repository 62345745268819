import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import axios from "axios";
import { Redirect } from "react-router";
import Error from "./Error";
var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);

class SetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      m: 0,
      name: "",
      newPassword: "",
      confirmPassword: "",
      isVerified: false,
      requestFailed: "",
      goodtogo: false,
    };
  }

  componentDidMount() {
    $(document.body).css("background-color", "#fff");
    window.scrollTo(0, 0);
    document.querySelector("head").innerHTML +=
      '<link rel="stylesheet" href="../../../css/loginL.css" type="text/css"/>';
    document.querySelector("head").innerHTML +=
      '<link rel="stylesheet" href="../../../css/loginWithPss.css" type="text/css"/>';
    document.querySelector("head").innerHTML +=
      '<link rel="stylesheet" href="../../../css/verifyUrself.css" type="text/css"/>';
    document.querySelector("head").innerHTML +=
      '<link rel="stylesheet" href="../../../css/setPassword.css" type="text/css"/>';

    let id = this.props.match.params.id;
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=verifyAgentToken",
        {
          emailToken: id,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
     
        if (res.data.code == 200 && res.data.status == "success") {
          localStorage.setItem("liapEiam", encryptor.encrypt(res.data.email));
          localStorage.setItem(
            "teiaoclaCgtlapn",
            encryptor.encrypt(res.data.contact)
          );
          localStorage.setItem("noTeklagel", encryptor.encrypt(id));
          this.setState({
            isVerified: true,
            requestFailed: "no",
          });
        } else if (res.data.code == 201 && res.data.status == "fail") {
          this.setState({
            requestFailed: "yes",
            isVerified: false,
          });
        }
      })
      .catch((err) => {});
  }

  handleName = (e) => {
    $(".error").hide();
    this.setState({
      name: e.target.value,
    });
  };

  handleNewPassword = (e) => {
    e.preventDefault();
    $(".error").hide();
    this.setState({
      newPassword: e.target.value,
    });
  };

  showNewPassword = (e) => {
    e.preventDefault();
    const { m } = this.state;
    if (m == 0) {
      $("#new_pss").attr("type", "text");
      $("#btn_pass2").css({
        background: '#fff url("/img/hidden.svg")',
        "background-repeat": "no-repeat",
        "background-size": "22px 22px",
        "background-position": "center",
      });
      this.setState({
        m: 1,
      });
    } else {
      $("#new_pss").attr("type", "password");
      $("#btn_pass2").css({
        background: '#fff url("/img/show.svg")',
        "background-repeat": "no-repeat",
        "background-size": "22px 22px",
        "background-position": "center",
      });
      this.setState({
        m: 0,
      });
    }
  };

  handleConfirmPassword = (e) => {
    e.preventDefault();
    $(".error").hide();
    this.setState({
      confirmPassword: e.target.value.trim(),
    });
  };

  showConfirmPassword = (e) => {
    e.preventDefault();
    const { m } = this.state;
    if (m === 0) {
      $("#confirm_pss").attr("type", "text");
      $("#btn_pass1").css({
        background: '#fff url("/img/hidden.svg")',
        "background-repeat": "no-repeat",
        "background-size": "22px 22px",
        "background-position": "center",
      });
      this.setState({
        m: 1,
      });
    } else {
      $("#confirm_pss").attr("type", "password");
      $("#btn_pass1").css({
        background: '#fff url("/img/show.svg")',
        "background-repeat": "no-repeat",
        "background-size": "22px 22px",
        "background-position": "center",
      });
      this.setState({
        m: 0,
      });
    }
  };
  capitalize(input) {
    var words = input.split(" ");
    var CapitalizedWords = [];
    words.forEach((element) => {
      CapitalizedWords.push(
        element[0].toUpperCase() + element.slice(1, element.length)
      );
    });
    return CapitalizedWords.join(" ");
  }
  handleNextBtn = (e) => {
    if (
      // this.state.name.trim() == "" ||
      this.state.newPassword.trim() == "" ||
      this.state.confirmPassword.trim() == ""
    ) {
      $(".error").show();
      $(".error").html("Required fields cannot be empty!");
    }
    //  else if (!this.state.name.match(/^([a-zA-Z'-.]+(?: [a-zA-Z'-.]+)?)$/)) {
    //   $(".error").show();
    //   $(".error").html("Please enter a valid name");
    // }
     else if (
      this.state.newPassword.length < 8 ||
      this.state.newPassword.length > 21
    ) {
      $(".error").show();
      $(".error").html("Password must be between 8 to 21 letters");
    } else if (this.state.newPassword != this.state.confirmPassword) {
      $(".error").show();
      $(".error").html("Passwords don't match! Try again");
    } else if (this.state.isVerified) {
      // var mySentence = this.state.name;
      // mySentence=mySentence.replace(/\s+/g, " ");
      // const words = mySentence.split(" ");

      // for (let i = 0; i < words.length; i++) {
      //   words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      // }

      // var mywords = words.join(" ");
      // localStorage.setItem("mnealeagl", encryptor.encrypt(mywords.trim()));
      localStorage.setItem(
        "adsoalrPewgls",
        encryptor.encrypt(this.state.newPassword.trim())
      );
      this.setState({
        goodtogo: true,
      });
    }
  };

  render() {
    if (this.state.goodtogo) {
      return <Redirect to="/verifycontactno" />;
    } else if (this.state.requestFailed == "no") {
      return (
        <div className="main-bg">
          {/* <Helmet>
            <link
              rel="stylesheet"
              href={process.env.PUBLIC_URL + "/css/setPassword.css"}
            />
            <link
              rel="stylesheet"
              href={process.env.PUBLIC_URL + "/css/loginWithPss.css"}
            />
            <link
              rel="stylesheet"
              href={process.env.PUBLIC_URL + "/css/verifyUrself.css"}
            />
            <link
              rel="stylesheet"
              href={process.env.PUBLIC_URL + "/css/loginL.css"}
            />
          </Helmet> */}

          <div className="pass_div">
            <h1 className="pass_txt">Set new password</h1>
            {/* <!-- full name --> */}
            {/* <div className="input-group border_p">
              <p className="border_txtfull_name"> Full name</p>
              <input
                type="text"
                className="form-control inp_lfullname"
                maxLength="50"
                placeholder="Type here..."
                id="name"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                value={this.state.name}
                onChange={this.handleName}
              />
            </div> */}
            {/* <!-- password --> */}
            <div className="input-group border_p">
              <p className="border_txtp"> New password</p>
              <input
                type="password"
                className="form-control inp_l2"
                id="new_pss"
                maxLength="21"
                placeholder="**********"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                value={this.state.newPassword}
                onChange={this.handleNewPassword}
              />
              <div className="input-group-append">
                <button
                  className="login_btnp"
                  id="btn_pass2"
                  type="button"
                  onClick={this.showNewPassword}
                ></button>
              </div>
            </div>
            {/* <!--confirm password --> */}
            <div className="input-group border_2c">
              <p className="border_txtpss"> Confirm password</p>
              <input
                type="password"
                className="form-control inp_l2c"
                maxLength="21"
                id="confirm_pss"
                placeholder="**********"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                value={this.state.confirmPassword}
                onChange={this.handleConfirmPassword}
              />
              <div className="input-group-append">
                <button
                  className="login_btnp"
                  id="btn_pass1"
                  type="button"
                  onClick={this.showConfirmPassword}
                ></button>
              </div>
            </div>
            <div className="error_div1">
              <p className="error text-center">
                This is the demo error message
              </p>
            </div>
            {/* <!-- submit --> */}
            <center>
              <button
                className="next btn_timer"
                id="next"
                onClick={this.handleNextBtn}
              >
                Next
              </button>
            </center>
          </div>
        </div>
      );
    } else if (this.state.requestFailed == "yes") {
      return <Error />;
    } else {
      return (
        <div>
          {/* <Helmet>

            <link
              rel="stylesheet"
              href={process.env.PUBLIC_URL + "/css/setPassword.css"}
            />
            <link
              rel="stylesheet"
              href={process.env.PUBLIC_URL + "/css/loginWithPss.css"}
            />
            <link
              rel="stylesheet"
              href={process.env.PUBLIC_URL + "/css/verifyUrself.css"}
            />
            <link
              rel="stylesheet"
              href={process.env.PUBLIC_URL + "/css/loginL.css"}
            />
          </Helmet> */}
          <div />
        </div>
      );
    }
  }
}

export default SetPassword;

import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import $, { data } from "jquery";
import axios from "axios";
import { Redirect } from "react-router";
export class Whitebox extends Component {
  constructor(props) {
    super(props);
  }
render() {
      return (
        <div>
          <div className="cont">
            <div id="notifContainer"></div>
            <Helmet>
              <link
                rel="stylesheet"
                href={process.env.PUBLIC_URL + "/css/select2.css"}
              />
              <link rel="stylesheet" href="css/services.css" />
            </Helmet>

            <Header />

            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
              className="sidebaropenicon"
            />
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
              className="sidebarbackicon"
            />
            <img
              alt="loading..."
              src={
                process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"
              }
              className="sidebarexpandicon"
            />

            <div className="overlay1"></div>
            <div className="sidebar">
              <div className="side_div_2 sidebar_txt active">
                <Link to="/dashboard">Overview</Link>
              </div>
              <div className="side_div_1 sidebar_txt">
                <Link to="/myuser">My Users</Link>
              </div>
              <div className="side_div_1 sidebar_txt">
                <Link to="/paymentinvoices">User Invoices / Payments</Link>
              </div>
              <div className="side_div_1 sidebar_txt">
                <Link to="/documentrecords">Documents Records</Link>
              </div>
              <div className="side_div_1 sidebar_txt">
                <Link to="/addservice">Add service</Link>
              </div>
              <div className="side_div_1 sidebar_txt">
                <Link to="/agentservices">Agent Services</Link>
              </div>
              <div className="side_div_1 sidebar_txt">
                <Link to="/commission">My Commission</Link>
              </div>
              <div className="side_div_1 sidebar_txt">
                <Link to="/support">Support</Link>
              </div>  <div className="side_div_1 sidebar_txt">
                <Link to="/profile">Profile</Link>
              </div>
              <div></div>
            </div>

            <div className="arrow-left2"></div>
            <div className="white_div">
              <div className="white_box">
                  {this.props.children}
                  </div>
                  </div>
                  </div>
                  <Footer/>
                  </div>
      )
}}

export default Whitebox;
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $, { timers } from "jquery";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import SupportDetails from "./SupportDetails"
import SupportDetailsMobile from "./SupportDetailsMobile"

let type;
var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);
var today = new Date();
let CurrentMonthName;
let Decuserid;
let Decagentid;
let Decjwttoken;
let Decserviceid;
let Decservicename;
let Decplan;
let mainaction = "upload";
let temp = "general";
let mainFyear;
let mainmonth;
let selectshow;
let firsttime = 0;

let GSTRegistrationCertificatestatus;
let GSTR3Bstatus;
let GSTR1status;
let NormalExcelfileSalesPurchasestatus;
let SalesInvoicestatus;
let PurchaseInvoicestatus;
let proceednowforword;
let supportName;
let supportContact;
let supportEmail;
var sharedocumentarray = [
  {
    documentname: "",
    link: "",
  },
];

export class CompleteOA extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showpassword: false,
      showpassword2: false,
      serviceType: "",
      BankStatement1Link: "",
      BankStatement2Link: "",
      SalesBill2Link: "",
      SalesBill1Link: "",
      PurchaseBill2Link: "",
      PurchaseBill1Link: "",
      LoanStatements1Link: "",
      LoanStatements2Link: "",
      PBalanceSheet1Link: "",
      PBalanceSheet2Link: "",
      AllExpenses2Link: "",
      AllExpenses1Link: "",
      AllIncomes2Link: "",
      AllIncomes1Link: "",
      AssetPurchase2Link: "",
      AssetPurchase1Link: "",
      StockSummaryLink: "",
      fileName: "File Name",
      fileBase64: "",
      documentType: "",
      documentName: "",
      comment: "",
      Fyear: "",
      Preyear: "",
      SelectedYear: "",
      CurrentYearsMonths: true,
      commentHistroyarray: [],
      selectedMonth: "",
      planstate: "",
      deliveredDocuments: [],
      monthYearDetails: [],
      data: [],
      month: "",
      dataindex: 0,
      additionalArray1: [],
      additionalArray2: [],
      sharewhatsapp_radio: "Personal",
      shareemail_radio: "Legal251",
      whatsppcontactno: "",
      Selectedwhatsapptimeperiod: "select",
      Selectedemailtimeperiod: "select",
      customwhatsappdate: "",
      customemaildate: "",
      sharedocumentname: "",
      sharedocumentlink: "",
      emailmail: "",
      Timeline: [],
      Timelinedata: false,
      timelcount: 2,
      filtertimel: [],
      Name:"",
      Contact:"",
      serviceId:""
    };
  }

  logout = () => {
    localStorage.removeItem("gglltakoinoeenl");
    localStorage.removeItem("toeljgtkewlna");
    localStorage.removeItem("qazxswedcvfrtgb");
    localStorage.removeItem("lshhihfnkff");
    this.props.history.push({
      pathname: `/`,
    });
  };
  renderCommenthistory() {
    if (this.state.commentHistroyarray.length > 0) {
      return this.state.commentHistroyarray.map((activedoc, index) => {
        if (activedoc.commentBy == "user" && activedoc.special == "no") {
          return (
            <div className="rightconvtextdiv" key={index}>
              <p className="rightconvtext">{activedoc.comment}</p>
              <p className="rightconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "user" &&
          activedoc.special == "green"
        ) {
          return (
            <div className="rightconvtextdiv" key={index}>
              <p className="rightgreenconvtext">{activedoc.comment}</p>
              <p className="rightconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "employee" &&
          activedoc.special == "no"
        ) {
          return (
            <div className="leftconvtextdiv" key={index}>
              <p className="leftconvtext">{activedoc.comment}</p>
              <p className="leftconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "user" &&
          activedoc.special == "red"
        ) {
          return (
            <div className="rightunappconvtextdiv" key={index}>
              <p className="rightunappconvtext">{activedoc.comment}</p>
              <p className="rightconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "employee" &&
          activedoc.special == "green"
        ) {
          return (
            <div className="leftgreenconvtextdiv" key={index}>
              <p className="leftgreenconvtext">{activedoc.comment}</p>
              <p className="leftconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "employee" &&
          activedoc.special == "red"
        ) {
          return (
            <div className="leftconvtextdiv" key={index}>
              <p className="leftunappconvtext">{activedoc.comment}</p>
              <p className="leftconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        }
      });
    } else if (this.state.commentHistroyarray.length == 0) {
      return <p className="convservicename">No comments available!</p>;
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    $(document.body).css("background-color", "#2d2d2d");
    let mainuserid = localStorage.getItem("relleIsugad");
    Decuserid = encryptor.decrypt(mainuserid);
    let mainagentid = localStorage.getItem("qazxswedcvfrtgb");
    Decagentid = encryptor.decrypt(mainagentid);
    let mainjwttoken = localStorage.getItem("toeljgtkewlna");
    Decjwttoken = encryptor.decrypt(mainjwttoken);
    const script = document.createElement("script");
    script.src = "/js/main.js";
    script.async = true;
    document.body.appendChild(script);

    firsttime = 0;
    let mainserviceid = localStorage.getItem("vrellvaiecstiadeceig");
    let mainaservicename = localStorage.getItem("eieltaiclsraaemevcvnge");
    let mainplan = localStorage.getItem("ipacneglatvlael");
    Decserviceid = encryptor.decrypt(mainserviceid);
    Decservicename = encryptor.decrypt(mainaservicename);
    Decplan = encryptor.decrypt(mainplan);
    supportName = localStorage.getItem("supportName");
    supportContact = localStorage.getItem("supportContact");
    supportEmail = localStorage.getItem("supportEmail");

    this.setState({
      supportName: supportName,
      supportContact: supportContact,
      supportEmail: supportEmail,
    })
    // ----------------------------temporary -----------------------------------------


    let temptoken = localStorage.getItem("fdgshdjfkgjhyhdnxhsgdhskcj")
    let Dectemptoken = encryptor.decrypt(temptoken)
    let paramsUserId2 = this.props.match.params.id2;
    if (Dectemptoken != null && Dectemptoken == paramsUserId2) {
      temp = "temporary"
    } else {
      temp = "general"
    }

    // ----------------------------temporary -----------------------------------------
    if (mainserviceid != null && mainaservicename != null && mainplan != null) {
      let paramsId = this.props.match.params.id;
      let paramsUserId = this.props.match.params.id2;
      let completedstatus = localStorage.getItem("pojasdsdjisjddd");
      Decserviceid = encryptor.decrypt(mainserviceid);
      Decservicename = encryptor.decrypt(mainaservicename);
      Decplan = encryptor.decrypt(mainplan);
      let Deccompletedstatus = encryptor.decrypt(completedstatus);
      this.getnameanumber();
      this.setState({
        serviceId: Decserviceid,
      });
      if (Deccompletedstatus == "Success") {
        this.getserviceInformationApi();
      } else {
        $(".mainsectionuploadsection").hide();
        $(".closedservicesection").show();
      }
      if (paramsId != Decserviceid || paramsUserId != Decuserid) {
        this.props.history.push("/dashboard");
      }
    } else {
      this.props.history.push("/dashboard");
    }

    if (Decplan == "S") {

    } else {
      this.setState({
        planstate: "second",
      });
    }
    let str = document.getElementsByClassName("myservicesortbyfilterbystatus").innerHTML = this.props.location.pathname.replaceAll("/", " > ")
    let mainstr = document.getElementsByClassName("myservicesortbyfilterbystatus").innerHTML = str.replace(" > ", " ")
    $(".myservicesortbyfilterbystatus").html(mainstr)
  }

  getnameanumber = () => {
    axios.post(
      process.env.REACT_APP_API_BASE_URL + "p=empexecutiveDetails",
      {
        agentId: Decagentid,
        serviceName: Decservicename,
        serviceId: Decserviceid, 
      },
      {
        headers: {
          Authkey: process.env.REACT_APP_API_KEY,
          "App-Token-Access": Decjwttoken,
        },
        auth: {
          username: process.env.REACT_APP_API_USERNAME,
          password: process.env.REACT_APP_API_PASSWORD,
        },
      }
    )
    .then((res) => {
      if (res.data.code == "200" && res.data.status == "success") {
        this.setState({
          Name: res.data.teamLeaderName,
            Contact: res.data.teamLeaderNumber,
        })
      } else if (res.data.code == "201" && res.data.status == "invalidauth") {
        this.logout();
      } else {
          this.showNotification(res.data.message);
        } 
    })
  }

  handlewhatsppcontactno = (e) => {
    this.setState({
      whatsppcontactno: e.target.value,
    });
  };
  handleemailmail = (e) => {
    this.setState({
      emailmail: e.target.value,
    });
  };

  handlecustomwhatsappdateChange = (e) => {
    this.setState({
      customwhatsappdate: e.target.value,
    });
  };

  handlecustomemaildateChange = (e) => {
    this.setState({
      customemaildate: e.target.value,
    });
  };

  handlewhatsapptimeperiodChange = (e) => {
    this.setState({
      Selectedwhatsapptimeperiod: e.target.value,
    });
    if (e.target.value == "custom") {
      $("#whatsappdateinput").show();
    } else if (e.target.value == "always") {
      $("#whatsappdateinput").hide();
      $(".sharedocmentwhatsapplifeerrormessage").show();
    } else {
      $("#whatsappdateinput").hide();
      $(".sharedocmentwhatsapplifeerrormessage").hide();
    }
  };
  handleemailtimeperiodChange = (e) => {
    $(".sharedocmentemaildateerrormessage").hide();
    this.setState({
      Selectedemailtimeperiod: e.target.value,
    });
    if (e.target.value == "custom") {
      $("#emaildateinput").show();
    } else if (e.target.value == "always") {
      $("#emaildateinput").hide();
      $(".sharedocmentemaillifeerrormessage").show();
    } else {
      $("#emaildateinput").hide();
      $(".sharedocmentemaillifeerrormessage").hide();
    }
  };

  whatsapp = (docname, link) => {
    $(".myAddModal1").modal({ backdrop: "static", keyboard: false });
    this.setState({
      sharedocumentname: docname,
      sharedocumentlink: link,
    });
    sharedocumentarray[0].documentname = docname;
    sharedocumentarray[0].link = link;
  };

  email = (docname, link) => {
    $(".myAddModal2").modal({ backdrop: "static", keyboard: false });
    this.setState({
      sharedocumentname: docname,
      sharedocumentlink: link,
    });
    sharedocumentarray[0].documentname = docname;
    sharedocumentarray[0].link = link;
  };

  submitemail = () => {
    let ToDate = new Date();
    if (this.state.emailmail.trim() === "") {
      $(".sharedocmentemailerrormessage").show();
      $(".sharedocmentemailerrormessage").html("Please enter  E-mail");
    } else if (this.state.emailmail.trim().length > 50) {
      $(".sharedocmentemailerrormessage").show();
      $(".sharedocmentemailerrormessage").html(
        "E-mail should be less than 50 characters"
      );
    } else if (
      !this.state.emailmail
        .trim()
        .match(/^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i)
    ) {
      $(".sharedocmentemailerrormessage").show();
      $(".sharedocmentemailerrormessage").html("Please enter  valid E-mail");
    } else if (this.state.Selectedemailtimeperiod == "select") {
      $(".sharedocmentemailerrormessage").hide();
      $(".sharedocmentemailselecterrormessage").show();
      $(".sharedocmentemailselecterrormessage").html("Please select a option");
    } else if (
      this.state.Selectedemailtimeperiod == "custom" &&
      this.state.customemaildate == ""
    ) {
      $(".sharedocmentemailerrormessage").hide();
      $(".sharedocmentemailselecterrormessage").hide();
      $(".sharedocmentemaildateerrormessage").html("Please enter date");
      $(".sharedocmentemaildateerrormessage").show();
    } else if (
      this.state.Selectedemailtimeperiod == "custom" &&
      new Date(this.state.customemaildate).getTime() < ToDate.getTime()
    ) {
      $(".sharedocmentemailerrormessage").hide();
      $(".sharedocmentemailselecterrormessage").hide();
      $(".sharedocmentemaildateerrormessage").html("Please enter a vaild date");
      $(".sharedocmentemaildateerrormessage").show();
    } else {
      $(".upload_btn20_70").hide();
      $(".upload_btnloader_70").show();
      $(".cancelbtn_70").attr("disabled", true);
      var localemaildate = this.state.customemaildate;
      if (
        this.state.Selectedemailtimeperiod != "custom" &&
        this.state.customemaildate != ""
      ) {
        localemaildate = "";
      }

      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=sharedocumentEmailNormal",
          {
            userId: Decuserid,
            requestType: temp,
            agentId: Decagentid,
            emailto: this.state.emailmail.trim(),
            duration: this.state.Selectedemailtimeperiod,
            custom: localemaildate,
            type: this.state.shareemail_radio,
            shareableDocument: sharedocumentarray,
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((resp) => {
          $(".upload_btn20_70").show();
          $(".upload_btnloader_70").hide();
          $(".cancelbtn_70").attr("disabled", false);
          $(".myAddModal2").modal("hide");
          $(".sharedocmentemaillifeerrormessage").hide();
          $(".sharedocmentemailerrormessage").hide();
          $(".sharedocmentemailselecterrormessage").hide();
          $(".sharedocmentemaildateerrormessage").hide();
          var localemailmail = this.state.emailmail;
          var localradio = this.state.shareemail_radio;
          this.setState({
            Selectedemailtimeperiod: "select",
            emailmail: "",
            shareemail_radio: "Legal251",
            customemaildate: "",
          });
          $("#emaildateinput").hide();
          if (resp.data.code == 200 && resp.data.status == "success") {
            if (localradio == "Personal") {
              window.open(
                `mailto:${localemailmail}?subject=Shared%20Documents%20-%20Legal251&body=Hi%20There%20!!%20I%20am%20sharing%20following%20documents%20with%20you%20via%20my%20Legal251%20Panel:-%0a${resp.data.documentLinks[0].documentname}%0a${resp.data.documentLinks[0].link}`,
                "_blank"
              );
            } else {
              $(".myAddModal3").modal({ backdrop: "static", keyboard: false });
              setTimeout(function () {
                $(".myAddModal3").modal("hide");
              }, 3000);
            }
          } else if (
            resp.data.code == "201" &&
            resp.data.status == "invalidauth"
          ) {
            this.logout();
          }
          else if (resp.data.code == "201" && resp.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          }
          else {
            this.showNotification(resp.data.message);
          }
        })
        .catch((err) => {
          this.showNotification("Something went wrong, Try again!");
        });
    }
  };

  submitwhatsapp = () => {
    let ToDate = new Date();
    if (this.state.whatsppcontactno.trim() === "") {
      $(".sharedocmentwhatsapperrormessage").show();
      $(".sharedocmentwhatsapperrormessage").html("Please enter  Mobile No");
    } else if (!this.state.whatsppcontactno.trim().match(/^[6-9]\d{9}$/)) {
      $(".sharedocmentwhatsapperrormessage").show();
      $(".sharedocmentwhatsapperrormessage").html(
        "Please enter  valid Mobile No"
      );
    } else if (this.state.Selectedwhatsapptimeperiod == "select") {
      $(".sharedocmentwhatsapperrormessage").hide();
      $(".sharedocmentwhatsappselecterrormessage").show();
      $(".sharedocmentwhatsappselecterrormessage").html(
        "Please select a option"
      );
    } else if (
      this.state.Selectedwhatsapptimeperiod == "custom" &&
      this.state.customwhatsappdate == ""
    ) {
      $(".sharedocmentwhatsapperrormessage").hide();
      $(".sharedocmentwhatsappselecterrormessage").hide();
      $(".sharedocmentwhatsappdateerrormessage").html("Please enter date");
      $(".sharedocmentwhatsappdateerrormessage").show();
    } else if (
      this.state.Selectedwhatsapptimeperiod == "custom" &&
      new Date(this.state.customwhatsappdate).getTime() < ToDate.getTime()
    ) {
      $(".sharedocmentwhatsapperrormessage").hide();
      $(".sharedocmentwhatsappselecterrormessage").hide();
      $(".sharedocmentwhatsappdateerrormessage").html(
        "Please a enter vaild date"
      );
      $(".sharedocmentwhatsappdateerrormessage").show();
    } else {
      $(".upload_btn20_90").hide();
      $(".upload_btnloader_90").show();
      $(".cancelbtn_90").attr("disabled", true);
      var localwhatsappdate = this.state.customwhatsappdate;
      if (
        this.state.Selectedwhatsapptimeperiod != "custom" &&
        this.state.customwhatsappdate != ""
      ) {
        localwhatsappdate = "";
      }
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=sharedocumentLinkNormal",
          {
            userId: Decuserid,
            requestType: temp,
            agentId: Decagentid,
            duration: this.state.Selectedwhatsapptimeperiod,
            custom: localwhatsappdate,
            shareableDocument: sharedocumentarray,
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((resp) => {
          $(".upload_btn20_90").show();
          $(".upload_btnloader_90").hide();
          $(".cancelbtn_90").attr("disabled", false);
          $(".myAddModal1").modal("hide");
          $(".sharedocmentwhatsapperrormessage").hide();
          $(".sharedocmentwhatsapplifeerrormessage").hide();
          $(".sharedocmentwhatsappselecterrormessage").hide();
          $(".sharedocmentwhatsappdateerrormessage").hide();
          var localwhatsppcontactno = this.state.whatsppcontactno;
          this.setState({
            Selectedwhatsapptimeperiod: "select",
            customemaildate: "",
            whatsppcontactno: "",
          });
          $("#whatsappdateinput").hide();

          if (resp.data.code == 200 && resp.data.status == "success") {
            window.open(
              `https://web.whatsapp.com/send?phone=91${localwhatsppcontactno}&text=Hi%20There%20!!%20I%20am%20sharing%20following%20documents%20with%20you%20via%20my%20Legal251%20Panel:-%0a${resp.data.documentLinks[0].documentname}%0a${resp.data.documentLinks[0].link}`,
              "_blank"
            );
          } else if (
            resp.data.code == "201" &&
            resp.data.status == "invalidauth"
          ) {
            this.logout();
          }
          else if (resp.data.code == "201" && resp.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          }
          else {
            this.showNotification(resp.data.message);
          }
        })
        .catch((err) => {
          this.showNotification("Something went wrong, Try again!");
        });
    }
  };

  sharewhatsapp_radioChange = (e) => {
    this.setState({
      sharewhatsapp_radio: e.currentTarget.value,
    });
  };
  shareemail_radioChange = (e) => {
    this.setState({
      shareemail_radio: e.currentTarget.value,
    });
  };

  getserviceInformationApi2 = () => {
    $(".serviceoverlay").show();
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=completedDetails",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: this.state.month,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        $(".serviceoverlay").hide();
        if (res.data.code == 200 && res.data.status == "success") {
          this.setState({
            additionalArray1: [],
            additionalArray2: [],
          });

          if (res.data.serviceType == "OA_WI_Documents") {



            if (res.data.informationGiven.documents.length > 0) {
              if (res.data.informationGiven.documents[0].status == "done") {
                if (res.data.informationGiven.documents[0].BankStatement == null) {
                  $("#BankStatement1").hide();
                } else {
                  $("#BankStatement1").show();
                  this.setState({
                    BankStatement1Link: res.data.informationGiven.documents[0].BankStatement,
                  });
                }
              }

              if (res.data.informationGiven.documents[1].status == "done") {
                if (res.data.informationGiven.documents[1].SalesBills == null) {
                  $("#SalesBills1").hide();
                } else {
                  $("#SalesBills1").show();
                  this.setState({
                    GSTR11Link: res.data.informationGiven.documents[1].SalesBills,
                  });
                }
              }

              if (res.data.informationGiven.documents[2].status == "done") {
                if (
                  res.data.informationGiven.documents[2]
                    .PurchaseBills == null
                ) {
                  $("#PurchaseBills1").hide();
                } else {
                  $("#PurchaseBills1").show();
                  this.setState({
                    PurchaseBill1Link:
                      res.data.informationGiven.documents[2]
                        .PurchaseBills,
                  });
                }
              }
              if (res.data.informationGiven.documents[3].status == "done") {
                if (
                  res.data.informationGiven.documents[3]
                    .AllExpenses == null
                ) {
                  $("#AllExpenses1").hide();
                } else {
                  $("#AllExpenses1").show();
                  this.setState({
                    AllExpenses1Link:
                      res.data.informationGiven.documents[3]
                        .AllExpenses,
                  });
                }
              }

              if (res.data.informationGiven.documents[4].status == "done") {
                if (
                  res.data.informationGiven.documents[4]
                    .AllIncomes == null
                ) {
                  $("#AllIncomes1").hide();
                } else {
                  $("#AllIncomes1").show();
                  this.setState({
                    AllIncomes1Link:
                      res.data.informationGiven.documents[4]
                        .AllIncomes,
                  });
                }
              }


              if (res.data.informationGiven.documents[5].status == "done") {
                if (
                  res.data.informationGiven.documents[5]
                    .LoanStatements == null
                ) {
                  $("#LoanStatements1").hide();
                } else {
                  $("#LoanStatements1").show();
                  this.setState({
                    LoanStatements1Link:
                      res.data.informationGiven.documents[5]
                        .LoanStatements,
                  });
                }
              }


              if (res.data.informationGiven.documents[6].status == "done") {
                if (
                  res.data.informationGiven.documents[6]
                    .StockSummary == null
                ) {
                  $("#StockSummary1").hide();
                } else {
                  $("#StockSummary1").show();
                  this.setState({
                    StockSummary1Link:
                      res.data.informationGiven.documents[6]
                        .StockSummary,
                  });
                }
              }

              if (res.data.informationGiven.documents[7].status == "done") {
                if (
                  res.data.informationGiven.documents[7]
                    .PreviousBS_Audit == null
                ) {
                  $("#PBalanceSheet1").hide();
                } else {
                  $("#PBalanceSheet1").show();
                  this.setState({
                    PBalanceSheet1Link:
                      res.data.informationGiven.documents[7]
                        .PreviousBS_Audit,
                  });
                }
              }

              if (res.data.informationGiven.documents[8].status == "done") {
                if (
                  res.data.informationGiven.documents[8]
                    .Data == null
                ) {
                  $("#PTallyData1").hide();
                } else {
                  $("#PTallyData1").show();
                  this.setState({
                    PTallyData1Link:
                      res.data.informationGiven.documents[8]
                        .Data,
                  });
                }
              }

              if (res.data.informationGiven.documents[8].status == "done") {
                if (
                  res.data.informationGiven.documents[8]
                    .PurchaseBill == null
                ) {
                  $("#AssetPurchase1").hide();
                } else {
                  $("#AssetPurchase1").show();
                  this.setState({
                    AssetPurchase1Link:
                      res.data.informationGiven.documents[8]
                        .PurchaseBill,
                  });
                }
              }
            }
            if (res.data.informationGiven.addDocInformation.length > 0) {
              $("#additionalDocumentDiv").show();
              this.setState({
                additionalArray1: [
                  ...res.data.informationGiven.addDocInformation,
                ],
              });
            } else {
              $("#additionalDocumentDiv").hide();
            }
          } else if (Decplan == "OA_WOI_Documents") {

            if (res.data.informationGiven.documents.length > 0) {
              if (res.data.informationGiven.documents[0].status == "done") {
                if (
                  res.data.informationGiven.documents[0].BankStatement == null
                ) {
                  $("#BankStatement2").hide();
                } else {
                  $("#BankStatement2").show();
                  this.setState({
                    BankStatement2Link:
                      res.data.informationGiven.documents[0].BankStatement,
                  });
                }
              }

              if (res.data.informationGiven.documents[1].status == "done") {
                if (
                  res.data.informationGiven.documents[1].SalesBills == null
                ) {
                  $("#SalesBills2").hide();
                } else {
                  $("#SalesBills2").show();
                  this.setState({
                    SalesBill2Link:
                      res.data.informationGiven.documents[1].SalesBills,
                  });
                }
              }

              if (res.data.informationGiven.documents[2].status == "done") {
                if (res.data.informationGiven.documents[2].PurchaseBills == null) {
                  $("#PurchaseBills2").hide();
                } else {
                  $("#PurchaseBills2").show();
                  this.setState({
                    PurchaseBill2Link: res.data.informationGiven.documents[2].PurchaseBills,
                  });
                }
              }

              if (res.data.informationGiven.documents[3].status == "done") {
                if (res.data.informationGiven.documents[3].AllExpenses == null) {
                  $("#AllExpenses2").hide();
                } else {
                  $("#AllExpenses2").show();
                  this.setState({
                    AllExpenses2Link: res.data.informationGiven.documents[3].AllExpenses,
                  });
                }
              }
              if (res.data.informationGiven.documents[4].status == "done") {
                if (res.data.informationGiven.documents[4].AllIncomes == null) {
                  $("#AllIncomes2").hide();
                } else {
                  $("#AllIncomes2").show();
                  this.setState({
                    AllIncomes2Link: res.data.informationGiven.documents[4].AllIncomes,
                  });
                }
              }

              if (res.data.informationGiven.documents[5].status == "done") {
                if (res.data.informationGiven.documents[5].LoanStatements == null) {
                  $("#LoanStatements2").hide();
                } else {
                  $("#LoanStatements2").show();
                  this.setState({
                    LoanStatements2Link: res.data.informationGiven.documents[5].LoanStatements,
                  });
                }
              }

              if (res.data.informationGiven.documents[6].status == "done") {
                if (res.data.informationGiven.documents[6].PreviousBS_Audit == null) {
                  $("#PBalanceSheet2").hide();
                } else {
                  $("#PBalanceSheet2").show();
                  this.setState({
                    PBalanceSheet22Link: res.data.informationGiven.documents[6].PreviousBS_Audit,
                  });
                }
              }
              if (res.data.informationGiven.documents[7].status == "done") {
                if (res.data.informationGiven.documents[7].Data == null) {
                  $("#PTallyData2").hide();
                } else {
                  $("#PTallyData2").show();
                  this.setState({
                    PTallyData2Link: res.data.informationGiven.documents[7].Data,
                  });
                }
              }

              if (res.data.informationGiven.documents[8].status == "done") {
                if (res.data.informationGiven.documents[8].PurchaseBill == null) {
                  $("#AssetPurchase2").hide();
                } else {
                  $("#AssetPurchase2").show();
                  this.setState({
                    AssetPurchase2Link: res.data.informationGiven.documents[8].PurchaseBill,
                  });
                }
              }
              if (res.data.informationGiven.documents[4].status == "done") {
                if (
                  res.data.informationGiven.documents[4]
                    .ExcelOfSalesAndPurchase == null
                ) {
                  $("#NormalExcelfileSalesPurchasediv2").hide();
                } else {
                  $("#NormalExcelfileSalesPurchasediv2").show();
                  this.setState({
                    NormalExcelfileSalesPurchase2Link:
                      res.data.informationGiven.documents[4]
                        .ExcelOfSalesAndPurchase,
                  });
                }
              }
            }
            if (res.data.informationGiven.addDocInformation.length > 0) {
              $("#additionalDocumentDiv1").show();
              this.setState({
                additionalArray2: [
                  ...res.data.informationGiven.addDocInformation,
                ],
              });
            } else {
              $("#additionalDocumentDiv1").hide();
            }
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $(".serviceoverlay").hide();
      });
  };

  getserviceInformationApi = () => {
    $(".serviceoverlay").show();
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=completedDetails",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          plan: Decplan,
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        $(".serviceoverlay").hide();

        if (res.data.code == 200 && res.data.status == "success") {
          this.setState({
            monthYearDetails: res.data.monthYearDetails,
          });
          if (res.data.monthYearDetails.length != 0) {
            this.setState({
              SelectedYear: res.data.monthYearDetails[0].FY,
            });
            mainFyear = res.data.monthYearDetails[0].FY;
            mainmonth = res.data.monthYearDetails[0].Month[0];
          }
          if (res.data.serviceType == "OA_WI_Documents") {

            this.setState({
              planstate: "first",
            });

            if (res.data.informationGiven.documents.length > 0) {
              if (res.data.informationGiven.documents[0].status == "done") {
                if (res.data.informationGiven.documents[0].BankStatement == null) {
                  $("#BankStatement1").hide();
                } else {
                  $("#BankStatement1").show();
                  this.setState({
                    BankStatement1Link: res.data.informationGiven.documents[0].BankStatement,
                  });
                }
              }

              if (res.data.informationGiven.documents[1].status == "done") {
                if (res.data.informationGiven.documents[1].SalesBills == null) {
                  $("#SalesBills1").hide();
                } else {
                  $("#SalesBills1").show();
                  this.setState({
                    GSTR11Link: res.data.informationGiven.documents[1].SalesBills,
                  });
                }
              }

              if (res.data.informationGiven.documents[2].status == "done") {
                if (
                  res.data.informationGiven.documents[2]
                    .PurchaseBills == null
                ) {
                  $("#PurchaseBills1").hide();
                } else {
                  $("#PurchaseBills1").show();
                  this.setState({
                    PurchaseBill1Link:
                      res.data.informationGiven.documents[2]
                        .PurchaseBills,
                  });
                }
              }
              if (res.data.informationGiven.documents[3].status == "done") {
                if (
                  res.data.informationGiven.documents[3]
                    .AllExpenses == null
                ) {
                  $("#AllExpenses1").hide();
                } else {
                  $("#AllExpenses1").show();
                  this.setState({
                    AllExpenses1Link:
                      res.data.informationGiven.documents[3]
                        .AllExpenses,
                  });
                }
              }

              if (res.data.informationGiven.documents[4].status == "done") {
                if (
                  res.data.informationGiven.documents[4]
                    .AllIncomes == null
                ) {
                  $("#AllIncomes1").hide();
                } else {
                  $("#AllIncomes1").show();
                  this.setState({
                    AllIncomes1Link:
                      res.data.informationGiven.documents[4]
                        .AllIncomes,
                  });
                }
              }


              if (res.data.informationGiven.documents[5].status == "done") {
                if (
                  res.data.informationGiven.documents[5]
                    .LoanStatements == null
                ) {
                  $("#LoanStatements").hide();
                } else {
                  $("#LoanStatements").show();
                  this.setState({
                    LoanStatements1Link:
                      res.data.informationGiven.documents[5]
                        .LoanStatements,
                  });
                }
              }


              if (res.data.informationGiven.documents[6].status == "done") {
                if (
                  res.data.informationGiven.documents[6]
                    .StockSummary == null
                ) {
                  $("#StockSummary").hide();
                } else {
                  $("#StockSummary").show();
                  this.setState({
                    StockSummaryLink:
                      res.data.informationGiven.documents[6]
                        .StockSummary,
                  });
                }
              }

              if (res.data.informationGiven.documents[7].status == "done") {
                if (
                  res.data.informationGiven.documents[7]
                    .PreviousBS_Audit == null
                ) {
                  $("#PBalanceSheet1").hide();
                } else {
                  $("#PBalanceSheet1").show();
                  this.setState({
                    PBalanceSheet1Link:
                      res.data.informationGiven.documents[7]
                        .PreviousBS_Audit,
                  });
                }
              }

              if (res.data.informationGiven.documents[8].status == "done") {
                if (
                  res.data.informationGiven.documents[8]
                    .Data == null
                ) {
                  $("#PTallyData1").hide();
                } else {
                  $("#PTallyData1").show();
                  this.setState({
                    PTallyData1Link:
                      res.data.informationGiven.documents[8]
                        .Data,
                  });
                }
              }

              if (res.data.informationGiven.documents[8].status == "done") {
                if (
                  res.data.informationGiven.documents[8]
                    .PurchaseBill == null
                ) {
                  $("#AssetPurchase1").hide();
                } else {
                  $("#AssetPurchase1").show();
                  this.setState({
                    AssetPurchase1Link:
                      res.data.informationGiven.documents[8]
                        .PurchaseBill,
                  });
                }
              }
            }
            if (res.data.informationGiven.addDocInformation.length > 0) {
              $("#additionalDocumentDiv").show();
              this.setState({
                additionalArray1: [
                  ...res.data.informationGiven.addDocInformation,
                ],
              });
            } else {
              $("#additionalDocumentDiv").hide();
            }
          } else if (res.data.serviceType == "OA_WOI_Documents") {
            this.setState({
              planstate: "second",
            });
            if (res.data.informationGiven.documents.length > 0) {
              if (res.data.informationGiven.documents[0].status == "done") {
                if (
                  res.data.informationGiven.documents[0].BankStatement == null
                ) {
                  $("#BankStatement2").hide();
                } else {
                  $("#BankStatement2").show();
                  this.setState({
                    BankStatement2Link:
                      res.data.informationGiven.documents[0].BankStatement,
                  });
                }
              }

              if (res.data.informationGiven.documents[1].status == "done") {
                if (
                  res.data.informationGiven.documents[1].SalesBills == null
                ) {
                  $("#SalesBills2").hide();
                } else {
                  $("#SalesBills2").show();
                  this.setState({
                    SalesBill2Link:
                      res.data.informationGiven.documents[1].SalesBills,
                  });
                }
              }

              if (res.data.informationGiven.documents[2].status == "done") {
                if (res.data.informationGiven.documents[2].PurchaseBills == null) {
                  $("#PurchaseBills2").hide();
                } else {
                  $("#PurchaseBills2").show();
                  this.setState({
                    PurchaseBill2Link: res.data.informationGiven.documents[2].PurchaseBills,
                  });
                }
              }

              if (res.data.informationGiven.documents[3].status == "done") {
                if (res.data.informationGiven.documents[3].AllExpenses == null) {
                  $("#AllExpenses2").hide();
                } else {
                  $("#AllExpenses2").show();
                  this.setState({
                    AllExpenses2Link: res.data.informationGiven.documents[3].AllExpenses,
                  });
                }
              }
              if (res.data.informationGiven.documents[4].status == "done") {
                if (res.data.informationGiven.documents[4].AllIncomes == null) {
                  $("#AllIncomes2").hide();
                } else {
                  $("#AllIncomes2").show();
                  this.setState({
                    AllIncomes2Link: res.data.informationGiven.documents[4].AllIncomes,
                  });
                }
              }

              if (res.data.informationGiven.documents[5].status == "done") {
                if (res.data.informationGiven.documents[5].LoanStatements == null) {
                  $("#LoanStatements2").hide();
                } else {
                  $("#LoanStatements2").show();
                  this.setState({
                    LoanStatements2Link: res.data.informationGiven.documents[5].LoanStatements,
                  });
                }
              }

              if (res.data.informationGiven.documents[6].status == "done") {
                if (res.data.informationGiven.documents[6].PreviousBS_Audit == null) {
                  $("#PBalanceSheet2").hide();
                } else {
                  $("#PBalanceSheet2").show();
                  this.setState({
                    PBalanceSheet22Link: res.data.informationGiven.documents[6].PreviousBS_Audit,
                  });
                }
              }
              if (res.data.informationGiven.documents[7].status == "done") {
                if (res.data.informationGiven.documents[7].Data == null) {
                  $("#PTallyData2").hide();
                } else {
                  $("#PTallyData2").show();
                  this.setState({
                    PTallyData2Link: res.data.informationGiven.documents[7].Data,
                  });
                }
              }

              if (res.data.informationGiven.documents[8].status == "done") {
                if (res.data.informationGiven.documents[8].PurchaseBill == null) {
                  $("#AssetPurchase2").hide();
                } else {
                  $("#AssetPurchase2").show();
                  this.setState({
                    AssetPurchase2Link: res.data.informationGiven.documents[8].PurchaseBill,
                  });
                }
              }
              if (res.data.informationGiven.documents[4].status == "done") {
                if (
                  res.data.informationGiven.documents[4]
                    .ExcelOfSalesAndPurchase == null
                ) {
                  $("#NormalExcelfileSalesPurchasediv2").hide();
                } else {
                  $("#NormalExcelfileSalesPurchasediv2").show();
                  this.setState({
                    NormalExcelfileSalesPurchase2Link:
                      res.data.informationGiven.documents[4]
                        .ExcelOfSalesAndPurchase,
                  });
                }
              }
            }
            if (res.data.informationGiven.addDocInformation.length > 0) {
              $("#additionalDocumentDiv1").show();
              this.setState({
                additionalArray2: [
                  ...res.data.informationGiven.addDocInformation,
                ],
              });
            } else {
              $("#additionalDocumentDiv").hide();
            }
          } else if (Decplan == "A" || Decplan == "PR" || Decplan == "PP") {

            if (res.data.informationGiven.OneTime[0].documents.length > 0) {
              if (
                res.data.informationGiven.OneTime[0].documents[0].status ==
                "done"
              ) {
                if (
                  res.data.informationGiven.OneTime[0].documents[0]
                    .GSTRegistrationCertificate == null
                ) {
                  $("#GSTRegistrationCertificatediv2").hide();
                } else {
                  $("#GSTRegistrationCertificatediv2").show();

                  this.setState({
                    BankStatement2Link:
                      res.data.informationGiven.OneTime[0].documents[0]
                        .GSTRegistrationCertificate,
                  });
                }
              }
            }
            if (res.data.informationGiven.documents.length > 0) {
              if (res.data.informationGiven.documents[0].status == "done") {
                if (
                  res.data.informationGiven.documents[0].InvoiceSales == null
                ) {
                  $("#SalesInvoicesdiv").hide();
                } else {
                  $("#SalesInvoicesdiv").show();
                  this.setState({
                    SalesInvoicesLink:
                      res.data.informationGiven.documents[0].InvoiceSales,
                  });
                }
              }

              if (res.data.informationGiven.documents[1].status == "done") {
                if (
                  res.data.informationGiven.documents[1].InvoicePurchase == null
                ) {
                  $("#PurchaseInvoicesdiv").hide();
                } else {
                  $("#PurchaseInvoicesdiv").show();
                  this.setState({
                    PurchaseInvoicesLink:
                      res.data.informationGiven.documents[1].InvoicePurchase,
                  });
                }
              }
              if (
                res.data.informationGiven.documents[0].InvoiceSales == null &&
                res.data.informationGiven.documents[1].InvoicePurchase == null
              ) {
                $("#SalesInvoicesPurchaseInvoicesmaindiv").hide();
              } else {
                $("#SalesInvoicesPurchaseInvoicesmaindiv").show();
              }
              if (
                res.data.informationGiven.documents[2].GSTR3B == null &&
                res.data.informationGiven.documents[3].GSTR1 == null
              ) {
                $("#GSTR3BGSTR1maindiv2").hide();
              } else {
                $("#GSTR3BGSTR1maindiv2").show();
              }

              if (res.data.informationGiven.documents[2].status == "done") {
                if (res.data.informationGiven.documents[2].GSTR3B == null) {
                  $("#GSTR3Bdiv2").hide();
                } else {
                  $("#GSTR3Bdiv2").show();
                  this.setState({
                    GSTR3B2Link: res.data.informationGiven.documents[2].GSTR3B,
                  });
                }
              }
              if (res.data.informationGiven.documents[3].status == "done") {
                if (res.data.informationGiven.documents[3].GSTR1 == null) {
                  $("#GSTR1div2").hide();
                } else {
                  $("#GSTR1div2").show();
                  this.setState({
                    GSTR12Link: res.data.informationGiven.documents[3].GSTR1,
                  });
                }
              }
              if (res.data.informationGiven.documents[4].status == "done") {
                if (
                  res.data.informationGiven.documents[4]
                    .ExcelOfSalesAndPurchase == null
                ) {
                  $("#NormalExcelfileSalesPurchasediv2").hide();
                } else {
                  $("#NormalExcelfileSalesPurchasediv2").show();
                  this.setState({
                    NormalExcelfileSalesPurchase2Link:
                      res.data.informationGiven.documents[4]
                        .ExcelOfSalesAndPurchase,
                  });
                }
              }
            }

            if (res.data.informationGiven.addDocInformation.length > 0) {
              $("#additionalDocumentDiv1").show();
              this.setState({
                additionalArray2: [
                  ...res.data.informationGiven.addDocInformation,
                ],
              });
            } else {
              $("#additionalDocumentDiv1").hide();
            }
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $(".serviceoverlay").hide();
      });
  };

  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };

  handleYearChange = (e) => {
    selectshow = true;
    this.setState({
      month: "select",
      SelectedYear: e.target.value,
      dataindex: e.target[e.target.selectedIndex].id,
      activeservicemainarray: [],
      deliveredDocuments: [],
      activeservicemainarraylength: 0,
      deliveredDocumentslength: 0,
    });
  };
  handleMonthChange = (e) => {
    mainFyear = this.state.SelectedYear;
    mainmonth = e.target.value;
    this.setState(
      {
        month: e.target.value,
      },
      () => {
        $(".commentsbtn").show();
        $(".commentsbtn").attr("disabled", false);
        $(".hidecommentshistorybtn").hide();
        $(".TDRightSide").hide();
        $(".commentimg-section2").show();
        this.getserviceInformationApi2();
      }
    );
  };

  AdditionalFilesArea = () => {
    if (this.state.additionalArray1.length > 0) {
      return this.state.additionalArray1.map((file, index) => {
        let NameOfAddFile = Object.keys(file)[0];
        let link = file[Object.keys(file)[0]];
        return (
          <div className="displayflex" key={index}>
            <div className="uploadnocol">
              <p className="uploadadditionalsidetext">{index + 1}&#41;</p>
            </div>
            <div className="uploadadditionaldatacol">
              <div className="row mb-2">
                <div className="col-md-7">
                  <input
                    type="text"
                    className="uploadtextfield marginzero"
                    value={NameOfAddFile}
                    readOnly
                  />
                </div>
                <div className="col-md-5">
                  <div className="float-md-right float-left">
                    <div className="displayflex">
                      <div className="viewbtndivpadding">
                        <a className="viewbtn" href={link} target="_blank">
                          View{" "}
                          <img
                            src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                          />
                        </a>
                      </div>
                      <div>
                        <button className="tick_btn">
                          {" "}
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/tick.svg"}
                          />
                        </button>
                      </div>
                    </div>
                    <button
                      className="seecommentshistorybtn commentsbtn Fileseecommentsbtn1"
                      id={`${index}Fileseecommentsbtn1`}
                      onClick={() => this.handleFileseeComments1(index)}
                    >
                      See comment history{" "}
                      <img
                        src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                      />
                    </button>
                    <button
                      className="hidecommentshistorybtn Filehidecommentsbtn1"
                      id={`${index}Filehidecommentsbtn1`}
                      onClick={() => this.handleFilehideComments1(index)}
                    >
                      Hide comment history{" "}
                      <img
                        src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else {
      $("#additionalDocumentDiv").hide();
    }
  };

  handleFileseeComments1 = (index) => {
    $(".commentsbtn").attr("disabled", false);
    $(`#${index}Fileseecommentsbtn1`).attr("disabled", true);
    let SelectedFile = this.state.additionalArray1[index];
    let NameOfAddFile = Object.keys(SelectedFile)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "additional",
          documentName: NameOfAddFile,
          FY: mainFyear,
          Month: mainmonth,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(`#${index}Fileseecommentsbtn1`).hide();
          $(".hidecommentshistorybtn").hide();
          $(`#${index}Filehidecommentsbtn1`).show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          $(`#${index}Fileseecommentsbtn1`).attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $(`#${index}Fileseecommentsbtn1`).attr("disabled", false);
        this.showNotification("Something Went wrong");
      });
  };

  handleFilehideComments1 = (index) => {
    $(".commentsbtn").show();
    $(`#${index}Fileseecommentsbtn1`).show();
    $(`#${index}Fileseecommentsbtn1`).attr("disabled", false);
    $(`#${index}Filehidecommentsbtn1`).hide();
    $(".hidecommentshistorybtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  planfirst() {
    return (
      <div>
        <div className="uploadscrolldiv">
          <div className="uploadscrollcardinside">

            <div className="displayflex" id="BankStatement1">
              <div className="uploadnocol">
                <p className="uploadnormaltext documentno"></p>
              </div>
              <div className="uploaddatacol borderHtao">
                <div className="row">
                  <div className="col-md-5">
                    <p className="uploadnormaltext">
                      Bank Statement
                      <sup className="requireddocstar">*</sup>
                    </p>
                  </div>
                  <div className="col-md-7">
                    <div className="fl_right">
                      <div className="displayflex">
                        <div className="viewbtndivpadding">
                          <a
                            className="viewbtn"
                            href={this.state.BankStatement1Link}
                            id="viewDocLink_10"
                            target="_blank"
                          >
                            View{" "}
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/Path 19498.svg"
                              }
                            />
                          </a>
                        </div>
                        <div>
                          <button className="tick_btn">
                            {" "}
                            <img
                              src={process.env.PUBLIC_URL + "/img/tick.svg"}
                            />
                          </button>
                        </div>
                      </div>
                      <button
                        className="seecommentshistorybtn commentsbtn"
                        id="handleBankStatementcommentsseebtn"
                        onClick={this.handleBankStatementcomments}
                      >
                        See comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>

                      <button
                        className="hidecommentshistorybtn"
                        id="handleBankStatementcommentshidebtn"
                        onClick={
                          this.handleBankStatementhideComments
                        }
                      >
                        Hide comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="displayflex"
              id="SalesBills1"
            >
              <div className="uploadnocol">
                <p className="uploadnormaltext documentno"></p>
              </div>
              <div className="uploaddatacol borderHtao">
                <div className="row">
                  <div className="col-md-5">
                    <p className="uploadnormaltext">
                      Sales Bills /Service Bills
                      <sup className="requireddocstar">*</sup>
                    </p>
                  </div>
                  <div className="col-md-7">
                    <div className="fl_right">
                      <div className="displayflex">
                        <div className="viewbtndivpadding">
                          <a
                            className="viewbtn"
                            href={this.state.SalesBill1Link}
                            id="viewDocLink_10"
                            target="_blank"
                          >
                            View{" "}
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/Path 19498.svg"
                              }
                            />
                          </a>
                        </div>
                        <div>
                          <button className="tick_btn">
                            {" "}
                            <img
                              src={process.env.PUBLIC_URL + "/img/tick.svg"}
                            />
                          </button>
                        </div>
                      </div>
                      <button
                        className="seecommentshistorybtn commentsbtn"
                        id="handleSalesBillscommentsseebtn"
                        onClick={this.handleSalesBillscomments}
                      >
                        See comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>

                      <button
                        className="hidecommentshistorybtn"
                        id="handleSalesBillscommentshidebtn"
                        onClick={
                          this.handleSalesBillshideComments
                        }
                      >
                        Hide comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="displayflex" id="PurchaseBills1">
              <div className="uploadnocol">
                <p className="uploadnormaltext documentno"></p>
              </div>
              <div className="uploaddatacol borderHtao">
                <div className="row">
                  <div className="col-md-5">
                    <p className="uploadnormaltext">
                      Purchase Bills
                      <sup className="requireddocstar">*</sup>
                    </p>
                  </div>
                  <div className="col-md-7">
                    <div className="fl_right">
                      <div className="displayflex">
                        <div className="viewbtndivpadding">
                          <a
                            className="viewbtn"
                            href={this.state.PurchaseBill1Link}
                            id="viewDocLink_10"
                            target="_blank"
                          >
                            View{" "}
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/Path 19498.svg"
                              }
                            />
                          </a>
                        </div>
                        <div>
                          <button className="tick_btn">
                            {" "}
                            <img
                              src={process.env.PUBLIC_URL + "/img/tick.svg"}
                            />
                          </button>
                        </div>
                      </div>
                      <button
                        className="seecommentshistorybtn commentsbtn"
                        id="handlePurchaseBillscommentsseebtn"
                        onClick={this.handlePurchaseBillscomments}
                      >
                        See comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>

                      <button
                        className="hidecommentshistorybtn"
                        id="handlePurchaseBillscommentshidebtn"
                        onClick={
                          this.handlePurchaseBillshideComments
                        }
                      >
                        Hide comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="displayflex" id="AllExpenses1">
              <div className="uploadnocol">
                <p className="uploadnormaltext documentno"></p>
              </div>
              <div className="uploaddatacol borderHtao">
                <div className="row">
                  <div className="col-md-5">
                    <p className="uploadnormaltext">
                      All Expenses (BILLS /VOUCHERS)
                      <sup className="requireddocstar">*</sup>
                    </p>
                  </div>
                  <div className="col-md-7">
                    <div className="fl_right">
                      <div className="displayflex">
                        <div className="viewbtndivpadding">
                          <a
                            className="viewbtn"
                            href={this.state.AllExpenses1Link}
                            id="viewDocLink_10"
                            target="_blank"
                          >
                            View{" "}
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/Path 19498.svg"
                              }
                            />
                          </a>
                        </div>
                        <div>
                          <button className="tick_btn">
                            {" "}
                            <img
                              src={process.env.PUBLIC_URL + "/img/tick.svg"}
                            />
                          </button>
                        </div>
                      </div>
                      <button
                        className="seecommentshistorybtn commentsbtn"
                        id="handleAllExpensescommentsseebtn"
                        onClick={this.handleAllExpensescomments}
                      >
                        See comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>

                      <button
                        className="hidecommentshistorybtn"
                        id="handleAllExpensescommentshidebtn"
                        onClick={
                          this.handleAllExpenseshideComments
                        }
                      >
                        Hide comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="displayflex" id="AllIncomes2">
              <div className="uploadnocol">
                <p className="uploadnormaltext documentno"></p>
              </div>
              <div className="uploaddatacol borderHtao">
                <div className="row">
                  <div className="col-md-5">
                    <p className="uploadnormaltext">
                      All Incomes (DETAILS /DOCUMENTS)
                      <sup className="requireddocstar">*</sup>
                    </p>
                  </div>
                  <div className="col-md-7">
                    <div className="fl_right">
                      <div className="displayflex">
                        <div className="viewbtndivpadding">
                          <a
                            className="viewbtn"
                            href={this.state.AllIncomes1Link}
                            id="viewDocLink_10"
                            target="_blank"
                          >
                            View{" "}
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/Path 19498.svg"
                              }
                            />
                          </a>
                        </div>
                        <div>
                          <button className="tick_btn">
                            {" "}
                            <img
                              src={process.env.PUBLIC_URL + "/img/tick.svg"}
                            />
                          </button>
                        </div>
                      </div>
                      <button
                        className="seecommentshistorybtn commentsbtn"
                        id="handleAllIncomescommentsseebtn"
                        onClick={this.handleAllIncomescomments}
                      >
                        See comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>

                      <button
                        className="hidecommentshistorybtn"
                        id="handleAllIncomescommentshidebtn"
                        onClick={
                          this.handleAllIncomeshideComments
                        }
                      >
                        Hide comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="displayflex" id="LoanStatements">
              <div className="uploadnocol">
                <p className="uploadnormaltext documentno"></p>
              </div>
              <div className="uploaddatacol borderHtao">
                <div className="row">
                  <div className="col-md-5">
                    <p className="uploadnormaltext">
                      All Loan Statements

                    </p>
                  </div>
                  <div className="col-md-7">
                    <div className="fl_right">
                      <div className="displayflex">
                        <div className="viewbtndivpadding">
                          <a
                            className="viewbtn"
                            href={this.state.LoanStatements1Link}
                            id="viewDocLink_10"
                            target="_blank"
                          >
                            View{" "}
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/Path 19498.svg"
                              }
                            />
                          </a>
                        </div>
                        <div>
                          <button className="tick_btn">
                            {" "}
                            <img
                              src={process.env.PUBLIC_URL + "/img/tick.svg"}
                            />
                          </button>
                        </div>
                      </div>
                      <button
                        className="seecommentshistorybtn commentsbtn"
                        id="handleLoanStatementscommentsseebtn"
                        onClick={this.handleLoanStatementscomments}
                      >
                        See comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>

                      <button
                        className="hidecommentshistorybtn"
                        id="handleLoanStatementscommentshidebtn"
                        onClick={
                          this.handleLoanStatementshideComments
                        }
                      >
                        Hide comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="displayflex" id="StockSummary">
              <div className="uploadnocol">
                <p className="uploadnormaltext documentno"></p>
              </div>
              <div className="uploaddatacol borderHtao">
                <div className="row">
                  <div className="col-md-5">
                    <p className="uploadnormaltext">
                      Stock Summary as on Last 31st March

                    </p>
                  </div>
                  <div className="col-md-7">
                    <div className="fl_right">
                      <div className="displayflex">
                        <div className="viewbtndivpadding">
                          <a
                            className="viewbtn"
                            href={this.state.StockSummaryLink}
                            id="viewDocLink_10"
                            target="_blank"
                          >
                            View{" "}
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/Path 19498.svg"
                              }
                            />
                          </a>
                        </div>
                        <div>
                          <button className="tick_btn">
                            {" "}
                            <img
                              src={process.env.PUBLIC_URL + "/img/tick.svg"}
                            />
                          </button>
                        </div>
                      </div>
                      <button
                        className="seecommentshistorybtn commentsbtn"
                        id="handleStockSummarycommentsseebtn"
                        onClick={this.handleStockSummarycomments}
                      >
                        See comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>

                      <button
                        className="hidecommentshistorybtn"
                        id="handleStockSummarycommentshidebtn"
                        onClick={
                          this.handleStockSummaryhideComments
                        }
                      >
                        Hide comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="displayflex" id="PBalanceSheet1">
              <div className="uploadnocol">
                <p className="uploadnormaltext documentno"></p>
              </div>
              <div className="uploaddatacol borderHtao">
                <div className="row">
                  <div className="col-md-5">
                    <p className="uploadnormaltext">
                      Previous year Balance Sheet /Audit Report

                    </p>
                  </div>
                  <div className="col-md-7">
                    <div className="fl_right">
                      <div className="displayflex">
                        <div className="viewbtndivpadding">
                          <a
                            className="viewbtn"
                            href={this.state.PBalanceSheet1Link}
                            id="viewDocLink_10"
                            target="_blank"
                          >
                            View{" "}
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/Path 19498.svg"
                              }
                            />
                          </a>
                        </div>
                        <div>
                          <button className="tick_btn">
                            {" "}
                            <img
                              src={process.env.PUBLIC_URL + "/img/tick.svg"}
                            />
                          </button>
                        </div>
                      </div>
                      <button
                        className="seecommentshistorybtn commentsbtn"
                        id="handlePBalanceSheetcommentsseebtn"
                        onClick={this.handlePBalanceSheetcomments}
                      >
                        See comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>

                      <button
                        className="hidecommentshistorybtn"
                        id="handlePBalanceSheetcommentshidebtn"
                        onClick={
                          this.handlePBalanceSheethideComments
                        }
                      >
                        Hide comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="displayflex" id="PTallyData1">
              <div className="uploadnocol">
                <p className="uploadnormaltext documentno"></p>
              </div>
              <div className="uploaddatacol borderHtao">
                <div className="row">
                  <div className="col-md-5">
                    <p className="uploadnormaltext">
                      Previous year Tally Data

                    </p>
                  </div>
                  <div className="col-md-7">
                    <div className="fl_right">
                      <div className="displayflex">
                        <div className="viewbtndivpadding">
                          <a
                            className="viewbtn"
                            href={this.state.PTallyData1Link}
                            id="viewDocLink_10"
                            target="_blank"
                          >
                            View{" "}
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/Path 19498.svg"
                              }
                            />
                          </a>
                        </div>
                        <div>
                          <button className="tick_btn">
                            {" "}
                            <img
                              src={process.env.PUBLIC_URL + "/img/tick.svg"}
                            />
                          </button>
                        </div>
                      </div>
                      <button
                        className="seecommentshistorybtn commentsbtn"
                        id="handlePTallyDatacommentsseebtn"
                        onClick={this.handlePTallyDatacomments}
                      >
                        See comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>

                      <button
                        className="hidecommentshistorybtn"
                        id="handlePTallyDatacommentshidebtn"
                        onClick={
                          this.handlePTallyDatahideComments
                        }
                      >
                        Hide comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="displayflex" id="AssetPurchase1">
              <div className="uploadnocol">
                <p className="uploadnormaltext documentno"></p>
              </div>
              <div className="uploaddatacol borderHtao">
                <div className="row">
                  <div className="col-md-5">
                    <p className="uploadnormaltext">
                      Assets purchase Bill

                    </p>
                  </div>
                  <div className="col-md-7">
                    <div className="fl_right">
                      <div className="displayflex">
                        <div className="viewbtndivpadding">
                          <a
                            className="viewbtn"
                            href={this.state.AssetPurchase1Link}
                            id="viewDocLink_10"
                            target="_blank"
                          >
                            View{" "}
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/Path 19498.svg"
                              }
                            />
                          </a>
                        </div>
                        <div>
                          <button className="tick_btn">
                            {" "}
                            <img
                              src={process.env.PUBLIC_URL + "/img/tick.svg"}
                            />
                          </button>
                        </div>
                      </div>
                      <button
                        className="seecommentshistorybtn commentsbtn"
                        id="handleAssetPurchasecommentsseebtn"
                        onClick={this.handleAssetPurchasecomments}
                      >
                        See comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>

                      <button
                        className="hidecommentshistorybtn"
                        id="handleAssetPurchasecommentshidebtn"
                        onClick={
                          this.handleAssetPurchasehideComments
                        }
                      >
                        Hide comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="displayflex" id="additionalDocumentDiv1">
              <div className="uploadadditionaldatacol">
                <p className="uploadadditionalnormaltext">
                  Additional documents:-
                </p>

                {this.AdditionalFilesArea()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleBankStatementhideComments = () => {
    $("#handleBankStatementcommentsseebtn").show();
    $("#handleBankStatementcommentsseebtn").attr(
      "disabled",
      false
    );
    $("#handleBankStatementcommentshidebtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleBankStatement2hideComments = () => {
    $("#handleBankStatement2commentsseebtn").show();
    $("#handleBankStatement2commentsseebtn").attr(
      "disabled",
      false
    );
    $("#handleBankStatement2commentshidebtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleSalesBillshideComments = () => {
    $("#handleSalesBillscommentsseebtn").show();
    $("#handleSalesBillscommentsseebtn").attr(
      "disabled",
      false
    );
    $("#handleSalesBillscommentshidebtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleSalesBills2hideComments = () => {
    $("#handleSalesBills2commentsseebtn").show();
    $("#handleSalesBills2commentsseebtn").attr(
      "disabled",
      false
    );
    $("#handleSalesBills2commentshidebtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePurchaseBillshideComments = () => {
    $("#handlePurchaseBillscommentsseebtn").show();
    $("#handlePurchaseBillscommentsseebtn").attr(
      "disabled",
      false
    );
    $("#handlePurchaseBillscommentshidebtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePurchaseBills2hideComments = () => {
    $("#handlePurchaseBills2commentsseebtn").show();
    $("#handlePurchaseBills2commentsseebtn").attr(
      "disabled",
      false
    );
    $("#handlePurchaseBills2commentshidebtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleAllExpenseshideComments = () => {
    $("#handleAllExpensescommentsseebtn").show();
    $("#handleAllExpensescommentsseebtn").attr(
      "disabled",
      false
    );
    $("#handleAllExpensescommentshidebtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleAllExpenses2hideComments = () => {
    $("#handleAllExpenses2commentsseebtn").show();
    $("#handleAllExpenses2commentsseebtn").attr(
      "disabled",
      false
    );
    $("#handleAllExpenses2commentshidebtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleAllIncomeshideComments = () => {
    $("#handleAllIncomescommentsseebtn").show();
    $("#handleAllIncomescommentsseebtn").attr(
      "disabled",
      false
    );
    $("#handleAllIncomescommentshidebtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleAllIncomes2hideComments = () => {
    $("#handleAllIncomes2commentsseebtn").show();
    $("#handleAllIncomes2commentsseebtn").attr(
      "disabled",
      false
    );
    $("#handleAllIncomes2commentshidebtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleLoanStatementshideComments = () => {
    $("#handleLoanStatementscommentsseebtn").show();
    $("#handleLoanStatementscommentsseebtn").attr(
      "disabled",
      false
    );
    $("#handleLoanStatementscommentshidebtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleLoanStatements2hideComments = () => {
    $("#handleLoanStatements2commentsseebtn").show();
    $("#handleLoanStatements2commentsseebtn").attr(
      "disabled",
      false
    );
    $("#handleLoanStatements2commentshidebtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePBalanceSheethideComments = () => {
    $("#handlePBalanceSheetcommentsseebtn").show();
    $("#handlePBalanceSheetcommentsseebtn").attr(
      "disabled",
      false
    );
    $("#handlePBalanceSheetcommentshidebtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePBalanceSheet2hideComments = () => {
    $("#handlePBalanceSheet2commentsseebtn").show();
    $("#handlePBalanceSheet2commentsseebtn").attr(
      "disabled",
      false
    );
    $("#handlePBalanceSheet2commentshidebtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePTallyDatahideComments = () => {
    $("#handlePTallyDatacommentsseebtn").show();
    $("#handlePTallyDatacommentsseebtn").attr(
      "disabled",
      false
    );
    $("#handlePTallyDatacommentshidebtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePTallyData2hideComments = () => {
    $("#handlePTallyData2commentsseebtn").show();
    $("#handlePTallyData2commentsseebtn").attr(
      "disabled",
      false
    );
    $("#handlePTallyData2commentshidebtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleAssetPurchasehideComments = () => {
    $("#handleAssetPurchasecommentsseebtn").show();
    $("#handleAssetPurchasecommentsseebtn").attr(
      "disabled",
      false
    );
    $("#handleAssetPurchasecommentshidebtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleAssetPurchase2hideComments = () => {
    $("#handleAssetPurchase2commentsseebtn").show();
    $("#handleAssetPurchase2commentsseebtn").attr(
      "disabled",
      false
    );
    $("#handleAssetPurchase2commentshidebtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleStockSummaryhideComments = () => {
    $("#handleStockSummarycommentsseebtn").show();
    $("#handleStockSummarycommentsseebtn").attr(
      "disabled",
      false
    );
    $("#handleStockSummarycommentshidebtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleBankStatementcomments = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#handleBankStatementcommentsseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "BankStatement",
          FY: mainFyear,
          Month: mainmonth,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#handleBankStatementcommentsseebtn").hide();
          $("#handleBankStatementcommentshidebtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleBankStatement2comments = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#handleBankStatement2commentsseebtn").attr(
      "disabled",
      true
    );
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "BankStatement",
          FY: mainFyear,
          Month: mainmonth,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#handleBankStatement2commentsseebtn").hide();
          $("#handleBankStatement2commentshidebtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };
  handleSalesBillscomments = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#handleSalesBillscommentsseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "SalesBills",
          FY: mainFyear,
          Month: mainmonth,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#handleSalesBillscommentsseebtn").hide();
          $("#handleSalesBillscommentshidebtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSalesBills2comments = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#handleSalesBills2commentsseebtn").attr(
      "disabled",
      true
    );
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "SalesBills",
          FY: mainFyear,
          Month: mainmonth,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#handleSalesBills2commentsseebtn").hide();
          $("#handleSalesBills2commentshidebtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePurchaseBillscomments = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#handlePurchaseBillscommentsseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PurchaseBills",
          FY: mainFyear,
          Month: mainmonth,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#handlePurchaseBillscommentsseebtn").hide();
          $("#handlePurchaseBillscommentshidebtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePurchaseBills2comments = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#handlePurchaseBills2commentsseebtn").attr(
      "disabled",
      true
    );
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PurchaseBills",
          FY: mainFyear,
          Month: mainmonth,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#handlePurchaseBills2commentsseebtn").hide();
          $("#handlePurchaseBills2commentshidebtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };


  handleAllExpensescomments = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#handleAllExpensescommentsseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "AllExpenses",
          FY: mainFyear,
          Month: mainmonth,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#handleAllExpensescommentsseebtn").hide();
          $("#handleAllExpensescommentshidebtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleAllExpenses2comments = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#handleAllExpenses2commentsseebtn").attr(
      "disabled",
      true
    );
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "AllExpenses",
          FY: mainFyear,
          Month: mainmonth,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#handleAllExpenses2commentsseebtn").hide();
          $("#handleAllExpenses2commentshidebtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };



  handleAllIncomescomments = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#handleAllIncomescommentsseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "AllIncomes",
          FY: mainFyear,
          Month: mainmonth,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#handleAllIncomescommentsseebtn").hide();
          $("#handleAllIncomescommentshidebtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleAllIncomes2comments = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#handleAllIncomes2commentsseebtn").attr(
      "disabled",
      true
    );
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "AllIncomes",
          FY: mainFyear,
          Month: mainmonth,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#handleAllIncomes2commentsseebtn").hide();
          $("#handleAllIncomes2commentshidebtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleLoanStatementscomments = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#handleLoanStatementscommentsseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "LoanStatements",
          FY: mainFyear,
          Month: mainmonth,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#handleLoanStatementscommentsseebtn").hide();
          $("#handleLoanStatementscommentshidebtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleLoanStatements2comments = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#handleLoanStatements2commentsseebtn").attr(
      "disabled",
      true
    );
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "LoanStatements",
          FY: mainFyear,
          Month: mainmonth,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#handleLoanStatements2commentsseebtn").hide();
          $("#handleLoanStatements2commentshidebtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePBalanceSheetcomments = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#handlePBalanceSheetcommentsseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PreviousBS_Audit",
          FY: mainFyear,
          Month: mainmonth,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#handlePBalanceSheetcommentsseebtn").hide();
          $("#handlePBalanceSheetcommentshidebtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePBalanceSheet2comments = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#handlePBalanceSheet2commentsseebtn").attr(
      "disabled",
      true
    );
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PreviousBS_Audit",
          FY: mainFyear,
          Month: mainmonth,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#handlePBalanceSheet2commentsseebtn").hide();
          $("#handlePBalanceSheet2commentshidebtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };


  handlePTallyDatacomments = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#handlePTallyDatacommentsseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "Data",
          FY: mainFyear,
          Month: mainmonth,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#handlePTallyDatacommentsseebtn").hide();
          $("#handlePTallyDatacommentshidebtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePTallyData2comments = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#handlePTallyData2commentsseebtn").attr(
      "disabled",
      true
    );
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "Data",
          FY: mainFyear,
          Month: mainmonth,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#handlePTallyData2commentsseebtn").hide();
          $("#handlePTallyData2commentshidebtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };


  handleAssetPurchasecomments = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#handleAssetPurchasecommentsseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PurchaseBill",
          FY: mainFyear,
          Month: mainmonth,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#handleAssetPurchasecommentsseebtn").hide();
          $("#handleAssetPurchasecommentshidebtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleAssetPurchase2comments = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#handleAssetPurchase2commentsseebtn").attr(
      "disabled",
      true
    );
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PurchaseBill",
          FY: mainFyear,
          Month: mainmonth,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#handleAssetPurchase2commentsseebtn").hide();
          $("#handleAssetPurchase2commentshidebtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleStockSummarycomments = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#handleStockSummarycommentsseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "StockSummary",
          FY: mainFyear,
          Month: mainmonth,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#handleStockSummarycommentsseebtn").hide();
          $("#handleStockSummarycommentshidebtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  AdditionalFilesArea1 = () => {
    if (this.state.additionalArray2.length > 0) {
      return this.state.additionalArray2.map((file, index) => {
        let NameOfAddFile = Object.keys(file)[0];
        let link = file[Object.keys(file)[0]];
        return (
          <div className="displayflex" key={index}>
            <div className="uploadnocol">
              <p className="uploadadditionalsidetext">{index + 1}&#41;</p>
            </div>
            <div className="uploadadditionaldatacol">
              <div className="row mb-2">
                <div className="col-md-7">
                  <input
                    type="text"
                    className="uploadtextfield marginzero"
                    value={NameOfAddFile}
                    readOnly
                  />
                </div>
                <div className="col-md-5">
                  <div className="float-md-right float-left">
                    <div className="displayflex">
                      <div className="viewbtndivpadding">
                        <a className="viewbtn" href={link} target="_blank">
                          View{" "}
                          <img
                            src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                          />
                        </a>
                      </div>
                      <div>
                        <button className="tick_btn">
                          {" "}
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/tick.svg"}
                          />
                        </button>
                      </div>
                    </div>
                    <button
                      className="seecommentshistorybtn commentsbtn Fileseecommentsbtn2"
                      id={`${index}Fileseecommentsbtn2`}
                      onClick={() => this.handleFileseeComments2(index)}
                    >
                      See comment history{" "}
                      <img
                        src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                      />
                    </button>
                    <button
                      className="hidecommentshistorybtn Filehidecommentsbtn2"
                      id={`${index}Filehidecommentsbtn2`}
                      onClick={() => this.handleFilehideComments2(index)}
                    >
                      Hide comment history{" "}
                      <img
                        src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else {
      $("#additionalDocumentDiv1").hide();
    }
  };

  handleFileseeComments2 = (index) => {
    $(".commentsbtn").attr("disabled", false);
    $(`#${index}Fileseecommentsbtn2`).attr("disabled", true);
    let SelectedFile = this.state.additionalArray2[index];
    let NameOfAddFile = Object.keys(SelectedFile)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "additional",
          documentName: NameOfAddFile,
          FY: mainFyear,
          Month: mainmonth,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(`#${index}Fileseecommentsbtn2`).hide();
          $(".hidecommentshistorybtn").hide();
          $(`#${index}Filehidecommentsbtn2`).show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          $(`#${index}Fileseecommentsbtn2`).attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $(`#${index}Fileseecommentsbtn2`).attr("disabled", false);
        this.showNotification("Something Went wrong");
      });
  };

  handleFilehideComments2 = (index) => {
    $(".commentsbtn").show();
    $(`#${index}Fileseecommentsbtn2`).show();
    $(`#${index}Fileseecommentsbtn2`).attr("disabled", false);
    $(`#${index}Filehidecommentsbtn2`).hide();
    $(".hidecommentshistorybtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  plansecond() {

    return (
      <div>
        <div className="uploadscrolldiv">
          <div className="uploadscrollcardinside">

            <div className="displayflex" id="BankStatement2">
              <div className="uploadnocol">
                <p className="uploadnormaltext documentno"></p>
              </div>
              <div className="uploaddatacol borderHtao">
                <div className="row">
                  <div className="col-md-5">
                    <p className="uploadnormaltext">
                      Bank Statement
                      <sup className="requireddocstar">*</sup>
                    </p>
                  </div>
                  <div className="col-md-7">
                    <div className="fl_right">
                      <div className="displayflex">
                        <div className="viewbtndivpadding">
                          <a
                            className="viewbtn"
                            href={this.state.BankStatement2Link}
                            id="viewDocLink_10"
                            target="_blank"
                          >
                            View{" "}
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/Path 19498.svg"
                              }
                            />
                          </a>
                        </div>
                        <div>
                          <button className="tick_btn">
                            {" "}
                            <img
                              src={process.env.PUBLIC_URL + "/img/tick.svg"}
                            />
                          </button>
                        </div>
                      </div>
                      <button
                        className="seecommentshistorybtn commentsbtn"
                        id="handleBankStatement2commentsseebtn"
                        onClick={this.handleBankStatement2comments}
                      >
                        See comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>

                      <button
                        className="hidecommentshistorybtn"
                        id="handleBankStatement2commentshidebtn"
                        onClick={
                          this.handleBankStatement2hideComments
                        }
                      >
                        Hide comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="displayflex"
              id="SalesBills2"
            >
              <div className="uploadnocol">
                <p className="uploadnormaltext documentno"></p>
              </div>
              <div className="uploaddatacol borderHtao">
                <div className="row">
                  <div className="col-md-5">
                    <p className="uploadnormaltext">
                      Sales Bills /Service Bills
                      <sup className="requireddocstar">*</sup>
                    </p>
                  </div>
                  <div className="col-md-7">
                    <div className="fl_right">
                      <div className="displayflex">
                        <div className="viewbtndivpadding">
                          <a
                            className="viewbtn"
                            href={this.state.SalesBill2Link}
                            id="viewDocLink_10"
                            target="_blank"
                          >
                            View{" "}
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/Path 19498.svg"
                              }
                            />
                          </a>
                        </div>
                        <div>
                          <button className="tick_btn">
                            {" "}
                            <img
                              src={process.env.PUBLIC_URL + "/img/tick.svg"}
                            />
                          </button>
                        </div>
                      </div>
                      <button
                        className="seecommentshistorybtn commentsbtn"
                        id="handleSalesBills2commentsseebtn"
                        onClick={this.handleSalesBills2comments}
                      >
                        See comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>

                      <button
                        className="hidecommentshistorybtn"
                        id="handleSalesBills2commentshidebtn"
                        onClick={
                          this.handleSalesBills2hideComments
                        }
                      >
                        Hide comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="displayflex" id="PurchaseBills2">
              <div className="uploadnocol">
                <p className="uploadnormaltext documentno"></p>
              </div>
              <div className="uploaddatacol borderHtao">
                <div className="row">
                  <div className="col-md-5">
                    <p className="uploadnormaltext">
                      Purchase Bills
                      <sup className="requireddocstar">*</sup>
                    </p>
                  </div>
                  <div className="col-md-7">
                    <div className="fl_right">
                      <div className="displayflex">
                        <div className="viewbtndivpadding">
                          <a
                            className="viewbtn"
                            href={this.state.PurchaseBill2Link}
                            id="viewDocLink_10"
                            target="_blank"
                          >
                            View{" "}
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/Path 19498.svg"
                              }
                            />
                          </a>
                        </div>
                        <div>
                          <button className="tick_btn">
                            {" "}
                            <img
                              src={process.env.PUBLIC_URL + "/img/tick.svg"}
                            />
                          </button>
                        </div>
                      </div>
                      <button
                        className="seecommentshistorybtn commentsbtn"
                        id="handlePurchaseBills2commentsseebtn"
                        onClick={this.handlePurchaseBills2comments}
                      >
                        See comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>

                      <button
                        className="hidecommentshistorybtn"
                        id="handlePurchaseBills2commentshidebtn"
                        onClick={
                          this.handlePurchaseBills2hideComments
                        }
                      >
                        Hide comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="displayflex" id="AllExpenses2">
              <div className="uploadnocol">
                <p className="uploadnormaltext documentno"></p>
              </div>
              <div className="uploaddatacol borderHtao">
                <div className="row">
                  <div className="col-md-5">
                    <p className="uploadnormaltext">
                      All Expenses (BILLS /VOUCHERS)
                      <sup className="requireddocstar">*</sup>
                    </p>
                  </div>
                  <div className="col-md-7">
                    <div className="fl_right">
                      <div className="displayflex">
                        <div className="viewbtndivpadding">
                          <a
                            className="viewbtn"
                            href={this.state.AllExpenses2Link}
                            id="viewDocLink_10"
                            target="_blank"
                          >
                            View{" "}
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/Path 19498.svg"
                              }
                            />
                          </a>
                        </div>
                        <div>
                          <button className="tick_btn">
                            {" "}
                            <img
                              src={process.env.PUBLIC_URL + "/img/tick.svg"}
                            />
                          </button>
                        </div>
                      </div>
                      <button
                        className="seecommentshistorybtn commentsbtn"
                        id="handleAllExpenses2commentsseebtn"
                        onClick={this.handleAllExpenses2comments}
                      >
                        See comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>

                      <button
                        className="hidecommentshistorybtn"
                        id="handleAllExpenses2commentshidebtn"
                        onClick={
                          this.handleAllExpenses2hideComments
                        }
                      >
                        Hide comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="displayflex" id="AllIncomes2">
              <div className="uploadnocol">
                <p className="uploadnormaltext documentno"></p>
              </div>
              <div className="uploaddatacol borderHtao">
                <div className="row">
                  <div className="col-md-5">
                    <p className="uploadnormaltext">
                      All Incomes (DETAILS /DOCUMENTS)
                      <sup className="requireddocstar">*</sup>
                    </p>
                  </div>
                  <div className="col-md-7">
                    <div className="fl_right">
                      <div className="displayflex">
                        <div className="viewbtndivpadding">
                          <a
                            className="viewbtn"
                            href={this.state.AllIncomes2Link}
                            id="viewDocLink_10"
                            target="_blank"
                          >
                            View{" "}
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/Path 19498.svg"
                              }
                            />
                          </a>
                        </div>
                        <div>
                          <button className="tick_btn">
                            {" "}
                            <img
                              src={process.env.PUBLIC_URL + "/img/tick.svg"}
                            />
                          </button>
                        </div>
                      </div>
                      <button
                        className="seecommentshistorybtn commentsbtn"
                        id="handleAllIncomes2commentsseebtn"
                        onClick={this.handleAllIncomes2comments}
                      >
                        See comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>

                      <button
                        className="hidecommentshistorybtn"
                        id="handleAllIncomes2commentshidebtn"
                        onClick={
                          this.handleAllIncomes2hideComments
                        }
                      >
                        Hide comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="displayflex" id="LoanStatements2">
              <div className="uploadnocol">
                <p className="uploadnormaltext documentno"></p>
              </div>
              <div className="uploaddatacol borderHtao">
                <div className="row">
                  <div className="col-md-5">
                    <p className="uploadnormaltext">
                      All Loan Statements

                    </p>
                  </div>
                  <div className="col-md-7">
                    <div className="fl_right">
                      <div className="displayflex">
                        <div className="viewbtndivpadding">
                          <a
                            className="viewbtn"
                            href={this.state.LoanStatements2Link}
                            id="viewDocLink_10"
                            target="_blank"
                          >
                            View{" "}
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/Path 19498.svg"
                              }
                            />
                          </a>
                        </div>
                        <div>
                          <button className="tick_btn">
                            {" "}
                            <img
                              src={process.env.PUBLIC_URL + "/img/tick.svg"}
                            />
                          </button>
                        </div>
                      </div>
                      <button
                        className="seecommentshistorybtn commentsbtn"
                        id="handleLoanStatements2commentsseebtn"
                        onClick={this.handleLoanStatements2comments}
                      >
                        See comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>

                      <button
                        className="hidecommentshistorybtn"
                        id="handleLoanStatements2commentshidebtn"
                        onClick={
                          this.handleLoanStatements2hideComments
                        }
                      >
                        Hide comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="displayflex" id="PBalanceSheet2">
              <div className="uploadnocol">
                <p className="uploadnormaltext documentno"></p>
              </div>
              <div className="uploaddatacol borderHtao">
                <div className="row">
                  <div className="col-md-5">
                    <p className="uploadnormaltext">
                      Previous year Balance Sheet /Audit Report

                    </p>
                  </div>
                  <div className="col-md-7">
                    <div className="fl_right">
                      <div className="displayflex">
                        <div className="viewbtndivpadding">
                          <a
                            className="viewbtn"
                            href={this.state.PBalanceSheet2Link}
                            id="viewDocLink_10"
                            target="_blank"
                          >
                            View{" "}
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/Path 19498.svg"
                              }
                            />
                          </a>
                        </div>
                        <div>
                          <button className="tick_btn">
                            {" "}
                            <img
                              src={process.env.PUBLIC_URL + "/img/tick.svg"}
                            />
                          </button>
                        </div>
                      </div>
                      <button
                        className="seecommentshistorybtn commentsbtn"
                        id="handlePBalanceSheet2commentsseebtn"
                        onClick={this.handlePBalanceSheet2comments}
                      >
                        See comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>

                      <button
                        className="hidecommentshistorybtn"
                        id="handlePBalanceSheet2commentshidebtn"
                        onClick={
                          this.handlePBalanceSheet2hideComments
                        }
                      >
                        Hide comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="displayflex" id="PTallyData2">
              <div className="uploadnocol">
                <p className="uploadnormaltext documentno"></p>
              </div>
              <div className="uploaddatacol borderHtao">
                <div className="row">
                  <div className="col-md-5">
                    <p className="uploadnormaltext">
                      Previous year Tally Data

                    </p>
                  </div>
                  <div className="col-md-7">
                    <div className="fl_right">
                      <div className="displayflex">
                        <div className="viewbtndivpadding">
                          <a
                            className="viewbtn"
                            href={this.state.PTallyData2Link}
                            id="viewDocLink_10"
                            target="_blank"
                          >
                            View{" "}
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/Path 19498.svg"
                              }
                            />
                          </a>
                        </div>
                        <div>
                          <button className="tick_btn">
                            {" "}
                            <img
                              src={process.env.PUBLIC_URL + "/img/tick.svg"}
                            />
                          </button>
                        </div>
                      </div>
                      <button
                        className="seecommentshistorybtn commentsbtn"
                        id="handlePTallyData2commentsseebtn"
                        onClick={this.handlePTallyData2comments}
                      >
                        See comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>

                      <button
                        className="hidecommentshistorybtn"
                        id="handlePTallyData2commentshidebtn"
                        onClick={
                          this.handlePTallyData2hideComments
                        }
                      >
                        Hide comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="displayflex" id="AssetPurchase2">
              <div className="uploadnocol">
                <p className="uploadnormaltext documentno"></p>
              </div>
              <div className="uploaddatacol borderHtao">
                <div className="row">
                  <div className="col-md-5">
                    <p className="uploadnormaltext">
                      Assets purchase Bill

                    </p>
                  </div>
                  <div className="col-md-7">
                    <div className="fl_right">
                      <div className="displayflex">
                        <div className="viewbtndivpadding">
                          <a
                            className="viewbtn"
                            href={this.state.AssetPurchase2Link}
                            id="viewDocLink_10"
                            target="_blank"
                          >
                            View{" "}
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/Path 19498.svg"
                              }
                            />
                          </a>
                        </div>
                        <div>
                          <button className="tick_btn">
                            {" "}
                            <img
                              src={process.env.PUBLIC_URL + "/img/tick.svg"}
                            />
                          </button>
                        </div>
                      </div>
                      <button
                        className="seecommentshistorybtn commentsbtn"
                        id="handleAssetPurchase2commentsseebtn"
                        onClick={this.handleAssetPurchase2comments}
                      >
                        See comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>

                      <button
                        className="hidecommentshistorybtn"
                        id="handleAssetPurchase2commentshidebtn"
                        onClick={
                          this.handleAssetPurchase2hideComments
                        }
                      >
                        Hide comment history{" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/See_comment_history.svg"
                          }
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="displayflex" id="additionalDocumentDiv1">
              <div className="uploadadditionaldatacol">
                <p className="uploadadditionalnormaltext">
                  Additional documents:-
                </p>

                {this.AdditionalFilesArea1()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  monthsToBeShown() {
    if (this.state.monthYearDetails.length > 0) {
      return this.state.monthYearDetails[this.state.dataindex].Month.map(
        (month, index) => {
          if (selectshow == true && index == 0) {
            return (
              <>
                <option key="select" value="select" selected disabled>
                  select
                </option>
                <option key={month} value={month}>
                  {month}
                </option>
              </>
            );
          } else {
            return (
              <option key={month} value={month}>
                {month}
              </option>
            );
          }
        }
      );
    }
  }
  YearsToBeShown() {
    return this.state.monthYearDetails.map((month, index) => {
      return (
        <option key={month.FY} value={month.FY} id={index}>
          {month.FY}
        </option>
      );
    });
  }
  completerender() {
    if (this.state.deliveredDocuments.length > 0) {
      return this.state.deliveredDocuments.map((activedoc, index) => {
        return (
          <div className="displayflex documentmaincard" key={index}>
            <div>
              <img
                src={process.env.PUBLIC_URL + "/img/Path 247.svg"}
                className="docutypeimg"
              />
            </div>
            <div className="docucontdiv">
              <div className="row">
                <div className="col-md-6">
                  <p className="nameofdoc">{activedoc.documentName}</p>
                  <p className="datesenttext">
                    Date received:-{" "}
                    <span className="datesentspan">{activedoc.datesent}</span>
                  </p>
                </div>
                <div className="col-md-6">
                  <div className="fl_right">
                    <div className="displayflex">
                      <div className="viewbtndivpadding">
                        <a
                          href={activedoc.documentLink}
                          target="_blank"
                          className="viewbtn"
                        >
                          View{" "}
                          <img
                            src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                          />
                        </a>
                      </div>
                      <div>
                        <a
                          href={activedoc.documentLink}
                          className="download_btn"
                          target="_blank"
                          download
                        >
                          Download{" "}
                          <img
                            src={
                              process.env.PUBLIC_URL + "/img/download_icon.svg"
                            }
                            alt="download"
                          />
                        </a>
                      </div>
                    </div>
                    <p className="docushareviatext">
                      Share via:{" "}
                      <a
                        onClick={() =>
                          this.whatsapp(
                            activedoc.documentName,
                            activedoc.documentLink
                          )
                        }
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/whatsapp.svg"}
                          className="docushareviawhats"
                        />
                      </a>{" "}
                      <a
                        onClick={() =>
                          this.email(
                            activedoc.documentName,
                            activedoc.documentLink
                          )
                        }
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/mail.svg"}
                          className="docushareviamail"
                        />
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.deliveredDocuments.length == 0) {
      return (
        <center className="myservicequiteimgcenter">
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
            className="emptydeliveredDocuments"
          />
        </center>
      );
    }
  }
  backbutton = () => {
    this.props.history.push({
      pathname: `/myuser/${Decuserid}/userservices`,
    });
  };

  handletimeline = () => {


    $("#timeline_btnloader").show();
    axios.post(
      process.env.REACT_APP_API_BASE_URL + "p=timeLine",
      {
        agentId: Decagentid,
        userId: Decuserid,
        serviceId: Decserviceid,
      },
      {
        headers: {
          Authkey: process.env.REACT_APP_API_KEY,
          "App-Token-Access": Decjwttoken,
        },
        auth: {
          username: process.env.REACT_APP_API_USERNAME,
          password: process.env.REACT_APP_API_PASSWORD,
        },
      }
    )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {

          if (res.data.timeLine.length > 0) {
            let length = res.data.timeLine.length;
            let ftimeline = res.data.timeLine.filter((items, index) => {
              return (index <= this.state.timelcount)
            })
            this.setState({
              filtertimel: ftimeline,
              timelcount: this.state.timelcount + 3,
              Timeline: res.data.timeLine,
              Timelinedata: true,
            });
            this.setState({

            });
            // .splice(this.state.timelcount,length)
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
  }

  handlemoretimeline = () => {

    let ftimeline = this.state.Timeline.filter((items, index) => {
      return (index <= this.state.timelcount)
    })
    this.setState({
      filtertimel: ftimeline,
      timelcount: this.state.timelcount + 3
    });

  }


  render() {
    const { Fyear, Preyear } = this.state;
    if (
      localStorage.getItem("gglltakoinoeenl") ==
      process.env.REACT_APP_LOGIN_KEY &&
      localStorage.getItem("toeljgtkewlna") != null &&
      localStorage.getItem("qazxswedcvfrtgb") != null
    ) {
      return (
        <div className="cont">
          <div id="notifContainer"></div>
          <Helmet>
            <link
              rel="stylesheet"
              href={process.env.PUBLIC_URL + "/css/select2.css"}
            />
          </Helmet>
          <Header />
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
            className="sidebaropenicon"
          />
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
            onClick={this.backbutton}
            className="sidebarbackicon"
          />
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"}
            className="sidebarexpandicon"
          />
          <div className="overlay1"></div>
          <div className="sidebar sidebarshift">
            <div className="side_div_2 sidebar_txt active">
              <Link to="/dashboard">Overview</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <Link to="/myservices">My Services</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <Link to="/paymentinvoice">Payments Invoices</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <Link to="/documentrecords">Documents Record</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <Link to="/addservice">Add service</Link>
            </div>
            <div></div>
          </div>
          <div className="arrow-left2 visibilityhidden"></div>
          <div className="white_div expandwhite_div">
            <div className="serviceoverlay">
              <div className="serviceoverlayinside">
                <center>
                  <img
                    src={process.env.PUBLIC_URL + "/img/loader.gif"}
                    className="serviceloader"
                  />
                </center>
              </div>
            </div>
            <div className="white_box">
              <div className="uploaddocu">
                <div className="row">
                  <div className="myservicesortbyfilterbystatus mt-1 ml-1">

                  </div>
                  <div className="col-lg-9 mt-5">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="inner-header d-flex justify-content-between">
                          <div className="slider-navigation-tab">
                            <header className="tabs-nav position-relative">
                              <ul className="d-flex slider-navigation-tab-ul">
                                <li
                                  className="active slider-navigation-tab-li"
                                  id="activeTab"
                                >
                                  <a href="#tab1" className="px-2 py-1">
                                    Information given
                                  </a>
                                </li>
                                <li
                                  className="slider-navigation-tab-li"
                                  id="completedTab"
                                >
                                  <a href="#tab2" className="px-2 py-1">
                                    Deliverables
                                  </a>
                                </li>
                                <hr className="slider-navigation-tab-vertical-line" />
                                <div className="sliderpage-slider"></div>
                              </ul>
                            </header>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 selectcateselectpadding">
                        <div className="fl_right">
                          <select
                            className="selectfinancialyear2"
                            value={this.state.SelectedYear}
                            onChange={this.handleYearChange}
                            id="10"
                          >
                            {this.YearsToBeShown()}
                          </select>
                          <select
                            className="selectfinancialmonth2"
                            value={this.state.month}
                            onChange={this.handleMonthChange}
                          >
                            {this.monthsToBeShown()}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tabs-content">
                  <div id="tab1" className="tab-content">
                    <div className="row">
                      <div className="col-lg-9">
                      <div className="mainsectionuploadsection">
                        <div className="gsrtf-select-img">
                          <center>
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/Group_3582.svg"
                              }
                              className="selectcategoryimg"
                            />
                          </center>
                        </div>
                        <div className="gsrtf-main-content">
                          {this.state.planstate === "first"
                            ? this.planfirst()
                            : this.plansecond()}
                        </div>
                        </div>
                        <div className="closedservicesection">
                        <center>
                        <br/>
                          <br/>
                          <br/>
                          <p className="closedstatustext"><span>Service ID</span>:- {this.state.serviceId}</p>
                          <br/>
                          <p className="closedstatusdesc">
                            Dear customer, your service is marked as
                            completed/closed because of following status.
                          </p>
                          <p  className="closedstatustext"><span>Status</span>:- Unapproved</p>
                          <img
                            src={process.env.PUBLIC_URL + "/img/warning.png"}
                            className="warningerrorimg"
                            alt="image"
                          />
                          <br/>    <br/>
                          <p className="closedstatusdesc2">
                            Please contact your allocated support executive or
                            call on 77728-77729 for support.
                          </p>
                          </center>
                        </div>
                      </div>

                      <div className="col-lg-3 colbtwborder">
                        <div className="commentimg-section2">
                          <SupportDetails name={this.state.Name} role={"Relationship Manager"} contact={this.state.Contact} />
                          {supportName != "" && supportContact != "" ?

                            <div className='supportDetails'>
                              <img
                                src={process.env.PUBLIC_URL + "/img/24-7_Support.png"}

                                alt="image"
                              />
                              <div>
                                <h5>{supportName}</h5>
                                <p>Agent Support Executive</p>
                                <span><b>Contact: </b> +91 {supportContact}</span>
                                <p className='mb-1'><b>Working Hours: </b> 11:00 AM - 06:30 PM</p>
                              </div>
                            </div>

                            : null}
                          <div className="mm">
                            <center>
                              <a
                                className="video-btn"
                                data-toggle="modal"
                                data-src="https://www.youtube.com/embed/fiC-nHoFJEY"
                                data-target="#youtubeModal"
                              >
                                <img
                                  src={process.env.PUBLIC_URL + "/img/play.jpg"}
                                  id="play"
                                  className="play_img play_img2"
                                  alt="image"
                                />
                              </a>
                            </center>
                          </div>

                          <p class="uploadinstr mt-2 uploddoc">You can also upload documents using Whatsapp on <a
                                  href="https://web.whatsapp.com/send?phone=917772877729&amp;text=Hello%20Team%20Legal251!"
                                  target="_blank"
                                >
                                  {" "}
                                  <img
                                    src={process.env.PUBLIC_URL + "/img/call.svg"}
                                    alt="call"
                                    className="callimg"
                                  />
                                </a> +91 77728-77729</p>
                        </div>
                        <div className="TDRightSide">
                          <div className="convheader2">
                            <div>
                              <img
                                src={process.env.PUBLIC_URL + "/img/person.png"}
                                className="converpersonimg"
                              />
                            </div>
                            <div className="convheadercontdiv">
                              <p className="convservicename">{this.state.Name}</p>
                              <p className="convpersonname">Relationship Manager</p>
                              <p className="convuniquename">
                                Contact: <span style={{ color: "#2d2d2d" }}>{this.state.Contact}</span></p>
                              <p className="convuniquename">
                                Service ID : <span className="convuniquenamespan">{Decserviceid}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="convmaindiv">
                            {this.renderCommenthistory()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="tab2" className="tab-content">
                    <div className="doucumainscrolldiv2">
                      <div className="doucumainscrolldivinside2">
                        {this.completerender()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SupportDetailsMobile name={this.state.Name} role={"Relationship Manager"} contact={this.state.Contact} />

          <Footer />{" "}
          <div className="modal fade"
            id="timeline"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true">

            <div
              className="modal-dialog modal-dialog-centered yt_modal "
              role="document"
            >
              <div className="modal-content videoModal">
                <div className="modal-body modal_b mt-4 ">
                  <button
                    type="button"
                    className="close close1 closeMain mr-2 dbtime"
                    data-dismiss="modal"
                    aria-label="Close"

                  >
                    <span aria-hidden="true">&times;</span>
                  </button>

                  {this.state.Timelinedata ?


                    this.state.Timeline.length > 0 ?

                      <div class="timeline scrollbtimel">
                        <div class="container">
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="timeline-container">
                                <div class="timeline-end">
                                  <button id="load-more" onClick={this.handlemoretimeline} >More..</button>
                                </div>
                                <div class="timeline-continue">

                                  {this.state.filtertimel.map((item, index) => {

                                    if (index % 2 == 0) {
                                      return (

                                        <div class="row timeline-left">
                                          <div class="col-md-6 d-md-none d-block">
                                            <p class="timeline-date">

                                            </p>
                                          </div>
                                          <div class="col-md-6">
                                            <div class="timeline-box">
                                              <div class="timeline-icon d-md-none d-block">
                                                <i class="fa fa-business-time"></i>
                                              </div>

                                              <div class="timeline-text">
                                                <p class="mainSpan">{item.time}</p>
                                                <h3>{item.timeLine}</h3>
                                                <p class="mode_p">Date : <span class="mode_span"> {item.date}</span></p>
                                                <h5 className="tlremark">{item.remark}</h5>
                                                {/* <p class="mode_p">
                                                      <span class="mode_span">
                                                          {{$TimeLine->TimeLine}}
                                                      </span>
                                                  </p>  */}


                                              </div>

                                              <div class="timeline-icon d-md-block d-none">
                                                <i class="fa fa-business-time"></i>
                                              </div>

                                            </div>
                                          </div>
                                          <div class="col-md-6 d-md-block d-none">
                                            <p class="timeline-date">

                                            </p>
                                          </div>
                                        </div>

                                      )
                                    } else {
                                      return (
                                        <div class="row timeline-right">
                                          <div class="col-md-6">
                                            <p class="timeline-date">
                                            </p>
                                          </div>
                                          <div class="col-md-6">
                                            <div class="timeline-box">
                                              <div class="timeline-icon">
                                                <i class="fa fa-gift"></i>
                                              </div>

                                              <div class="timeline-text">
                                                <p class="mainSpan">{item.time}</p>
                                                {/* <p class="">khkh</p> */}

                                                <h3>{item.timeLine}</h3>
                                                <p class="mode_p">Date : <span class="mode_span">{item.date} </span></p>
                                                <h5 className="tlremark">{item.remark}</h5>
                                                {/* <p class="mode_p">
                                                                              <span class="mode_span">
                                                                                  
                                                                              </span>
                                                                          </p> */}


                                              </div>

                                            </div>
                                          </div>
                                        </div>
                                      )
                                    }




                                  })}




                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      <center className="">
                        <img
                          alt="loading..."
                          src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
                          className="emptydeliveredDocuments"
                        />
                      </center>

                    :
                    <center>
                      <img
                        className="timelineloder"
                        src={process.env.PUBLIC_URL + "/img/loader.gif"}
                        alt="loading"
                      />
                    </center>


                  }

                </div>
              </div>
            </div>

          </div>
          <div className="modal fade myAddModal1">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-body uploadpopupbody">
                  <p className="sharepopupheading">
                    Please enter the recipient's mobile no.
                  </p>
                  <div className="sharepopupinputdiv">
                    <img
                      src={process.env.PUBLIC_URL + "/img/whatsapp.svg"}
                      className="docushareviawhatspopup"
                    />
                    <input
                      type="text"
                      className="sharepopupinput"
                      maxLength="10"
                      placeholder="Type here..."
                      value={this.state.whatsppcontactno}
                      onChange={this.handlewhatsppcontactno}
                    />
                    <p className="sharedocmentwhatsapperrormessage">
                      error message
                    </p>
                  </div>
                  <p className="sharepopupheading text-left">Share via:</p>
                  {/* <input
                    type="radio"
                    id="test30"
                    name="radio-group"
                    value="Yes"
                    checked={this.state.sharewhatsapp_radio === "Yes"}
                    onChange={this.sharewhatsapp_radioChange}
                  />
                  <label htmlFor="test30" className="uploadradiotext">
                    Legal251
                  </label> */}

                  <input
                    type="radio"
                    id="test31"
                    name="radio-group"
                    value="Personal"
                    checked={this.state.sharewhatsapp_radio === "Personal"}
                    onChange={this.sharewhatsapp_radioChange}
                  />
                  <label htmlFor="test31" className="uploadradiotext ">
                    Personal Mobile no.
                  </label>
                  <p className="sharepopupheading text-left">Share for:</p>
                  <select
                    className="selectimeperiod"
                    value={this.state.Selectedwhatsapptimeperiod}
                    onChange={this.handlewhatsapptimeperiodChange}
                  >
                    <option value="select" disabled>
                      select time period
                    </option>
                    <option value="1hr">share for 1 hour</option>
                    <option value="1day">share for 1 day</option>
                    <option value="3days">share for 3 day</option>
                    <option value="1week">share for 1 week</option>
                    <option value="1month">share for 1 month</option>
                    <option value="custom">custom</option>
                    <option value="always">lifetime</option>
                  </select>
                  <p className="sharedocmentwhatsapplifeerrormessage">
                    We don't recommend you to share documents for lifetime for
                    security reasons Instead you can select a custom time
                    period.
                  </p>
                  <p className="sharedocmentwhatsappselecterrormessage">
                    error message
                  </p>
                  <input
                    type="date"
                    id="whatsappdateinput"
                    className="othertextinput"
                    value={this.state.customwhatsappdate}
                    onChange={this.handlecustomwhatsappdateChange}
                    placeholder="Type here..."
                  />
                  <p className="sharedocmentwhatsappdateerrormessage">
                    error message
                  </p>
                  <br />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn cancelbtn_90"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20 upload_btn20_90"
                        onClick={this.submitwhatsapp}
                      >
                        Send&nbsp;
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_19481.svg"}
                          alt="download"
                        />
                      </button>
                      <button className="upload_btnloader upload_btnloader_90">
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade myAddModal2">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-body uploadpopupbody">
                  <p className="sharepopupheading">
                    Please enter the recipient's email ID
                  </p>
                  <div className="sharepopupinputdiv">
                    <img
                      src={process.env.PUBLIC_URL + "/img/Group_4339.svg"}
                      className="docushareviawhatspopup"
                    />
                    <input
                      type="text"
                      className="sharepopupinput"
                      maxLength="50"
                      placeholder="Type here..."
                      value={this.state.emailmail}
                      onChange={this.handleemailmail}
                    />
                    <p className="sharedocmentemailerrormessage">
                      error message
                    </p>
                  </div>
                  <p className="sharepopupheading text-left">Share via:</p>
                  <input
                    type="radio"
                    id="test40"
                    name="radio-group2"
                    value="Legal251"
                    checked={this.state.shareemail_radio === "Legal251"}
                    onChange={this.shareemail_radioChange}
                  />
                  <label htmlFor="test40" className="uploadradiotext">
                    Legal251
                  </label>

                  <input
                    type="radio"
                    id="test41"
                    name="radio-group2"
                    value="Personal"
                    checked={this.state.shareemail_radio === "Personal"}
                    onChange={this.shareemail_radioChange}
                  />
                  <label
                    htmlFor="test41"
                    className="uploadradiotext rightradio"
                  >
                    Personal Mail
                  </label>
                  <p className="sharepopupheading text-left">Share for:</p>
                  <select
                    className="selectimeperiod"
                    value={this.state.Selectedemailtimeperiod}
                    onChange={this.handleemailtimeperiodChange}
                  >
                    <option value="select" disabled>
                      select time period
                    </option>
                    <option value="1hr">share for 1 hour</option>
                    <option value="1day">share for 1 day</option>
                    <option value="3days">share for 3 day</option>
                    <option value="1week">share for 1 week</option>
                    <option value="1month">share for 1 month</option>
                    <option value="custom">custom</option>
                    <option value="always">lifetime</option>
                  </select>
                  <p className="sharedocmentemaillifeerrormessage">
                    We don't recommend you to share documents for lifetime for
                    security reasons Instead you can select a custom time
                    period.
                  </p>
                  <p className="sharedocmentemailselecterrormessage">
                    error message
                  </p>
                  <input
                    type="date"
                    id="emaildateinput"
                    className="othertextinput"
                    value={this.state.customemaildate}
                    onChange={this.handlecustomemaildateChange}
                    placeholder="Type here..."
                  />
                  <p className="sharedocmentemaildateerrormessage">
                    error message
                  </p>
                  <br />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn cancelbtn_70"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20 upload_btn20_70"
                        onClick={this.submitemail}
                      >
                        Send&nbsp;
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_19481.svg"}
                          alt="download"
                        />
                      </button>
                      <button className="upload_btnloader upload_btnloader_70">
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade myAddModal3 ">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-body uploadpopupbody">
                  <center>
                    <p className="sharesucesstext">Sent Sucessfully</p>
                    <img
                      src={process.env.PUBLIC_URL + "/img/Path_19474.svg"}
                      className="sharesucessimg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="youtubeModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered yt_modal "
              role="document"
            >
              <div className="modal-content">
                <div className="modal-body modal_b">
                  <button
                    type="button"
                    className="close close1"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      className="embed-responsive-item"
                      id="video"
                      allowscriptaccess="always"
                      src="https://www.youtube.com/embed/fiC-nHoFJEY?&amp;modestbranding=1&amp;showinfo=0"
                      allowFullScreen="allowfullscreen"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default CompleteOA;

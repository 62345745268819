import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link, Redirect } from "react-router-dom";
import $ from "jquery";
import Footer from "./Footer";
import Header from "./Header";
import axios from "axios";
import Sidebar from "./Sidebar";
var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);
let Decagentid;
let Decjwttoken;
let baseUrl = process.env.REACT_APP_API_BASE_URL;
let clrinterval = false

export class AgentPanelServices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notiusername: "",
      notiemail: "",
      notiecontact: "",
      notieagentid: "",
      userinfo: "contact",
      userinfoplaceholder: "Enter mobile no. here",
      userinfoinput: "",
      mobileotp: "",
      emailotp: "",
      rtime: 90,
      userid: "",
    };
  }
  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };

  componentDidMount() {
    $(document.body).css("background-color", "#2d2d2d");
    let mainagentid = localStorage.getItem("qazxswedcvfrtgb");
    Decagentid = encryptor.decrypt(mainagentid);
    let mainjwttoken = localStorage.getItem("toeljgtkewlna");
    Decjwttoken = encryptor.decrypt(mainjwttoken);
    this.headerApi();
  }
  countdown() {
    this.setState({ rtime: 90 });
    $("#mobileresend-loader").hide();
    function clear() {
      clearInterval(interval);
      $("#mobileresend-loader").hide();
    }
    let interval = setInterval(() => {
      if (this.state.rtime == 1 || clrinterval) {
        clear(interval);
        $("#mobileresend-loader").hide();
      } else {
      }
      this.setState({ rtime: this.state.rtime - 1 });
    }, 1000);
  }
  SubmitOTP = () => {

    $("#invalidtempusermobileotp").hide();
    $("#invalidtempuseremailotp").hide();
    $("#otpsentsuccesstemp").hide();
    if (
      this.state.mobileotp.trim() == "" &&
      this.state.emailotp.trim() == ""
    ) {

      $(".invalidmobile").html("Please enter mobile OTP")
      $(".invalidemail").html("Please enter email OTP")
      $("#invalidtempusermobileotp").show();
      $("#invalidtempuseremailotp").show();
    } else if (
      this.state.mobileotp.trim() == "" ||
      this.state.mobileotp.length > 6 ||
      this.state.mobileotp.length < 6 ||
      isNaN(this.state.mobileotp)
    ) {
      $(".invalidmobile").html("Please enter valid mobile OTP")
      $("#invalidtempusermobileotp").show();
    } else if (
      this.state.emailotp.trim() == "" ||
      this.state.emailotp.length > 6 ||
      this.state.emailotp.length < 6 ||
      isNaN(this.state.emailotp)
    ) {
      $(".invalidemail").html("Please enter valid email OTP")
      $("#invalidtempuseremailotp").show();
    } else {
      // $("#otpmodalagentservices").hide();
      $(".sendOtpLoader").show();
      $(".submitOtpbtn").hide();
      axios
        .post(
          `${baseUrl}p=verifyuserotp`,
          {
            agentId: Decagentid,
            userId: this.state.userid.trim(),
            contactOTP: this.state.mobileotp.trim(),
            emailOTP: this.state.emailotp.trim(),
            type: "tempuserotp",
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {

          if (res.data.code == 200 && res.data.status == "success") {
            localStorage.setItem("fdgshdjfkgjhyhdnxhsgdhskcj", encryptor.encrypt(res.data.userId));
            $(".sendOtpLoader").hide();
            $(".submitOtpbtn").show();
            this.setState({ userid: res.data.userId });
            // this.showNotification(res.data.message)
            $("#agentuserinfomodal").hide();
            $("#otpmodalagentservices").hide();
            // $("#success-modal").show();
            this.countdown();

            $("#Temp-User").modal("hide")
            localStorage.setItem("relleIsugad", encryptor.encrypt(res.data.userId));
            this.props.history.push({
              pathname: `/myuser/${this.state.userid}`,
              state: { name: res.data.username, email: res.data.email, contact: res.data.contact, },
            });
          } else if (
            res.data.code == 201 &&
            res.data.status == "contactotpinvalid"
          ) {
            $(".invalidmobile").html("Please enter valid mobile OTP")
            $("#invalidtempusermobileotp").show();
          } else if (
            res.data.code == 201 &&
            res.data.status == "emailotpinvalid"
          ) {
            $(".invalidemail").html("Please enter valid email OTP")
            $("#invalidtempuseremailotp").show();
          } else if (
            res.data.code == 201 &&
            res.data.status == "bothotpinvalid"
          ) {
            $(".invalidemail").html("Please enter valid email OTP")
            $(".invalidmobile").html("Please enter valid mobile OTP")
            $("#otpmodalagentservices").show();
            $("#invalidtempusermobileotp").show();
            $("#invalidtempuseremailotp").show();
          } else if (res.data.code == 201 && res.data.status == "fail") {
            this.showNotification(res.data.message);
            $("#agentuserinfomodal").hide();
            $("#otpmodalagentservices").hide();
            // $("#unsuccess-modal").show();
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else {
            this.showNotification(res.data.message);

          }
        })
        .catch((err) => {
          // 
          this.showNotification("Something went wrong");
          // $(".sendOtpLoader").hide();
          //   $(".sendOtpBtn").show();
        });
    }
  };
  resenduserOtp = () => {

    $("#invalidtempusermobileotp").hide();
    $("#invalidtempuseremailotp").hide();
    $("#otpsentsuccesstemp").hide();
    if (this.state.rtime == 0) {
      axios
        .post(
          `${baseUrl}p=tempuserwork`,
          {
            agentId: Decagentid,
            inputtype: "userId",
            inputvalue: this.state.userid.trim(),
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {

          if (res.data.code == 200 && res.data.status == "success") {
            $("#otpsentsuccesstemp").show();
            this.setState({ rtime: 90 }, this.countdown);
          } else if (res.data.code == 201 && res.data.status == "fail") {
            this.showNotification(res.data.message);
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          }
        })
        .catch((err) => {
          // 
          this.showNotification("Something went wrong");
          // $(".sendOtpLoader").hide();
          //   $(".sendOtpBtn").show();
        });
    } else {
      return null;
    }
  };
  TempUser = () => {
    $("#Temp-User").modal({ backdrop: "static", keyboard: false });
    $(".sendOtpBtn").show();
    $("#agentuserinfomodal").show();
    $("#otpmodalagentservices").hide();
  };
  showTickets = () => {
    this.props.history.push({ pathname: "/support", state: { type: "user", } })
  }
  changeuserinfo = () => {
    $("#invalidtempusermobileotp").hide();
    $("#invalidtempuseremailotp").hide();
    $("#agentuserinfomodal").show();
    $("#otpmodalagentservices").hide();
    clrinterval = true
  };
  SendOTP = (e) => {
    clrinterval = false
    this.setState({ mobileotp: "", emailotp: "" })
    e.preventDefault();
    $("#invalidtempusermobileotp").hide();
    $("#invalidtempuseremailotp").hide();
    $("#otpsentsuccesstemp").hide();
    $("#invalidagentuserinfoinput").hide();
    $("#invaliduserdetails").hide();
    $("#unverifieduser").hide();
    $("#userexists").hide();
    // this.setState({ userinfoinput: e.target.value });
    const contactNoregex = /^[6-9]\d{9}$/;
    const emailregex = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    if (this.state.userinfoinput.trim() == "" && this.state.userinfo == "contact") {
      $(".erruserinfo").html("Please enter mobile no");
      $("#invalidagentuserinfoinput").show();
    } else if (
      this.state.userinfo == "contact" &&
      isNaN(this.state.userinfoinput)
    ) {
      $(".erruserinfo").html("Please enter valid mobile no");
      $("#invalidagentuserinfoinput").show();
    } else if (
      this.state.userinfo == "contact" &&
      !contactNoregex.test(this.state.userinfoinput)
    ) {
      $(".erruserinfo").html("Please enter valid mobile no");
      $("#invalidagentuserinfoinput").show();
    } else if (
        this.state.userinfo == "contact" &&
        this.state.userinfoinput.length > 10
    ) {
      $(".erruserinfo").html("Please enter valid mobile no");
      $("#invalidagentuserinfoinput").show();
    } else if (this.state.userinfoinput.trim() == "" && this.state.userinfo == "email") {
      $("#invalidagentuserinfoinput").show();
      $(".erruserinfo").html("Please enter email");
    } else if (
      this.state.userinfo == "email" &&
      !emailregex.test(this.state.userinfoinput)
    ) {
      $(".erruserinfo").html("Please enter valid email");
      $("#invalidagentuserinfoinput").show();
    } else if (
      this.state.userinfo == "email" &&
      this.state.userinfoinput.length > 50
    ) {
      $(".erruserinfo").html("Please enter valid email");
      $("#invalidagentuserinfoinput").show();
    } else if (
      this.state.userinfo == "userId" &&
      this.state.userinfoinput.length > 20
    ) {
      $(".erruserinfo").html("Please enter valid userid");
      $("#invalidagentuserinfoinput").show();
    } else if (
      this.state.userinfo == "userId" &&
      isNaN(this.state.userinfoinput)
    ) {
      $(".erruserinfo").html("Please enter valid userid");
      $("#invalidagentuserinfoinput").show();
    } else {
      $(".sendOtpLoader").show();
      $(".sendOtpBtn").hide();
      $(".erruserinfo").html("Please enter userid");
      $("#invalidagentuserinfoinput").hide();

      axios
        .post(
          `${baseUrl}p=tempuserwork`,
          {
            agentId: Decagentid,
            inputtype: this.state.userinfo.trim(),
            inputvalue: this.state.userinfoinput.trim(),
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {

          if (res.data.code == 200 && res.data.status == "success") {
            $(".sendOtpLoader").hide();
            $(".sendOtpBtn").show();
            this.setState({ userid: res.data.UserID });
            // this.showNotification(res.data.message);
            $("#agentuserinfomodal").hide();
            $("#otpmodalagentservices").show();
            this.countdown();
          } else if (res.data.code == 201 && res.data.status == "notfound") {
            // this.showNotification(res.data.message);
            $("#invaliduserdetails").show();
            $(".sendOtpLoader").hide();
            $(".sendOtpBtn").show();
          } else if (
            res.data.code == 201 &&
            res.data.status == "alreadyexist"
          ) {
            // this.showNotification(res.data.message);
            $(".useralreadyexists").html(res.data.message);
            $("#userexists").show();
            $(".sendOtpLoader").hide();
            $(".sendOtpBtn").show();
          } else if (
            res.data.code == 201 &&
            res.data.status == "unverifiedAccount"
          ) {
            $("#unverifiedUser").html(res.data.message);
            $("#unverifieduser").show();
            $(".sendOtpLoader").hide();
            $(".sendOtpBtn").show();
          } else if (res.data.code == 201 && res.data.status == "fail") {
            $("#unverifiedUser").html(res.data.message);
            $("#unverifieduser").show();
            $(".sendOtpLoader").hide();
            $(".sendOtpBtn").show();
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          }
        })
        .catch((err) => {
          // 
          this.showNotification("Something went wrong");
          $(".sendOtpLoader").hide();
          $(".sendOtpBtn").show();
        });
    }
  };

  headerApi = () => {
    $(".useropt__rs__div").hide();
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=notificationProfile",
        {
          agentId: Decagentid,
        },
        {
          headers: {
            AuthKey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == 200 && res.data.status == "success") {
          $(".useropt__rs__div").show();
          this.setState({
            notiusername: res.data.username,
            notiemail: res.data.email,
            notiecontact: res.data.contact,
            notieagentid: res.data.AgentId,
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        }
      })
      .catch((err) => { });
  };

  OpenSupport = () => {
    this.props.history.push({
      pathname: `/agentservices/usersupport`,
    });
  };

  render() {
    if (
      localStorage.getItem("gglltakoinoeenl") ==
      process.env.REACT_APP_LOGIN_KEY &&
      localStorage.getItem("toeljgtkewlna") != null &&
      localStorage.getItem("qazxswedcvfrtgb") != null
    ) {

      $(".resendOTP").addClass("text-muted");
      if (this.state.rtime == 0) {
        $(".resendOTP").removeClass("text-muted");
      }
      return (
        <div className="cont">
          <div id="notifContainer"></div>
          <Helmet>
            <link
              rel="stylesheet"
              href={process.env.PUBLIC_URL + "/css/select2.css"}
            />
          </Helmet>

          <Header />

          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
            className="sidebaropenicon"
          />
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
            className="sidebarbackicon"
          />
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"}
            className="sidebarexpandicon"
          />
          <div className="overlay1"></div>
          <Sidebar />
          <div className="arrow-left7"></div>
          <div className="white_div">
            <div className="white_box">
              <div className="overviewrow">
                <div className="row paddingmarginzero">
                  {/*  */}
                  <div className="col-md-9 col-12 paddingmarginzero">
                    <div className="border_r">
                      <div className="agent__useropt__main__div">
                        <div className="agent__services__header">
                          {/* <img
                          src="/img/Path_231.svg"
                          alt="back"
                          className="agent__useropt__backbtn"
                        /> */}
                        </div>

                        <p className="agent__overview__head pl-2">
                          Agent Services :
                        </p>

                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-xl-4 col-md-6 col-12 mb-4">
                              <div
                                className="card useropt__card"
                                onClick={this.TempUser}
                                data-toggle="modal"
                                data-target="#link-User"
                              >
                                <h1 className="useropt__card__head">
                                  Fast User Services
                                </h1>
                                <img
                                  alt="loading..."
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/img/Group_5581.svg"
                                  }
                                  className="useropt__card__img1"
                                />
                              </div>
                            </div>

                            <div className="col-xl-4 col-md-6 col-12 mb-4">
                              <div
                                className="card useropt__card "
                                onClick={this.OpenSupport}
                              >
                                <h1 className="useropt__card__head">
                                  Raise Ticket For User
                                </h1>
                                <img
                                  alt="loading..."
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/img/Group 5510.svg"
                                  }
                                  className="useropt__card__img1"
                                />
                              </div>
                            </div>

                            <div className="col-xl-4 col-md-6 col-12 mb-4">
                              <div
                                className="card useropt__card"
                                onClick={this.showTickets}
                              >
                                <h1 className="useropt__card__head">
                                  User Tickets
                                </h1>
                                <img
                                  alt="loading..."
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/img/Group_5586.svg"
                                  }
                                  className="useropt__card__img1"
                                />
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*  */}
                  <div className="col-md-3 col-12 paddingmarginzero">
                    <div className="row">
                      <div className="useropt__rs__div">
                        <img
                          src={process.env.PUBLIC_URL + "/img/Group_5505.svg"}
                          alt="profile"
                          className="useropt__rs__img"
                        />
                        <div className="useropt_rs_data__div">
                          <p className="useropt_rs_data">
                            <span className="useropt_rs__head">
                              Partner Name:&nbsp;
                            </span>
                            {this.state.notiusername}
                          </p>
                          <p className="useropt_rs_data">
                            <span className="useropt_rs__head">
                              Partner ID:&nbsp;
                            </span>
                            {this.state.notieagentid}
                          </p>

                          <p className="useropt_rs_data">
                            <span className="useropt_rs__head">
                              Email:&nbsp;
                            </span>
                            {this.state.notiemail}
                          </p>
                          <p className="useropt_rs_data">
                            <span className="useropt_rs__head">
                              Contact:&nbsp;
                            </span>
                            {this.state.notiecontact}
                          </p>
                        </div>
                        {/* <Link to="/agent_1" className="useropt__rn__addBtn mt-2">
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_1025.svg"}
                        />{" "}
                        Add Service to this user
                      </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
          <div
            className="modal fade"
            id="Temp-User"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered "
              role="document"
            >
              <div
                className="modal-content back-modal"
                id="agentuserinfomodal"
              >
                <div className="w-100 d-flex">
                  <button
                    type="button"
                    className="border-0 bg-transparent ml-auto p-2 pr-3  text-right my-back-modal-close"
                    data-dismiss="modal"
                    onClick={() => {
                      this.setState({ rtime: 1 });
                      $("#invalidagentuserinfoinput").hide();
                    }}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/img/Page-1.svg"}
                      width="17px"
                    />
                  </button>
                </div>
                <div className="text-center">
                  <h4 className="my-linkmodal-text ">User Information</h4>
                </div>
                <div className="modal-body linkuser-modal border-0 text-center pb-0">
                  <select
                    name="linkUser"
                    id="LinkUser"
                    value={this.state.userinfo}
                    onChange={(e) => {
                      $("#invalidagentuserinfoinput").hide();
                      this.setState({ userinfo: e.target.value });
                      if (e.target.value == "contact") {
                        $("#userinfoinput").attr(
                          "placeholder",
                          "Enter mobile no. here"
                        );
                        $("#userinfoinput").attr("maxLength", "10");
                        this.setState({ userinfoinput: "" });
                      } else if (e.target.value == "email") {
                        $("#userinfoinput").attr(
                          "placeholder",
                          "Enter email here"
                        );
                        $("#userinfoinput").attr("maxLength", "50");
                        this.setState({ userinfoinput: "" });
                      } else {
                        $("#userinfoinput").attr(
                          "placeholder",
                          "Enter user id here"
                        );
                        $("#userinfoinput").attr("maxLength", "10");
                        this.setState({ userinfoinput: "" });
                      }
                    }}
                  >
                    <option value="contact">Mobile Number</option>
                    <option value="email">Email</option>
                    <option value="userId">User Id</option>
                  </select>
                </div>
                <div className="input-userinfo">
                  <input
                    className="Userinfo-input"
                    maxLength="10"
                    value={this.state.userinfoinput}
                    onChange={(e) =>
                      this.setState({ userinfoinput: e.target.value })
                    }
                    id="userinfoinput"
                    type="text"
                    placeholder={this.state.userinfoplaceholder}
                  />
                  <p
                    className=" mt-2 warn align-items-center"
                    id="invalidagentuserinfoinput"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/Icon_feather-alert-circle.svg"
                      }
                      width="12px"
                      className=" mr-1"
                    />{" "}
                    <span className="erruserinfo"></span>
                  </p>
                  <p
                    className=" mt-2 warn align-items-center"
                    id="invaliduserdetails"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/Icon_feather-alert-circle.svg"
                      }
                      width="12px"
                      className=" mr-1"
                    />{" "}
                    No such user found !
                  </p>
                  <p className=" mt-2 warn align-items-center" id="userexists">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/Icon_feather-alert-circle.svg"
                      }
                      width="12px"
                      className=" mr-1"
                    />{" "}
                    <span className="useralreadyexists"></span>
                  </p>
                  <p
                    className=" mt-2  warn align-items-center"
                    id="unverifieduser"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/Icon_feather-alert-circle.svg"
                      }
                      width="12px"
                      className=" mr-1"
                    />{" "}
                    <span id="unverifiedUser"></span>
                  </p>
                </div>
                <div className="modal-footer border-0 justify-content-center mb-4">
                  <img
                    className=" p-2 pl-3 pr-3 sendOtpLoader"
                    src="/img/loader.gif"
                    width="80px"
                    alt=""
                  />
                  <button
                    type="button"
                    onClick={this.SendOTP}
                    className="btn  send-btn text-white  sendOtpBtn"
                  >
                    Send OTP
                  </button>
                </div>
              </div>

              <div
                className="modal-content back-modal "
                id="otpmodalagentservices"
              >
                <div className="w-100 d-flex">
                  <button
                    type="button"
                    className="border-0 bg-transparent ml-auto p-2 pr-3  text-right my-back-modal-close"
                    data-dismiss="modal"
                    onClick={() => {
                      this.setState({ rtime: 1, userinfoinput: "" });
                      $("#invalidagentuserinfoinput").hide();
                    }}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/img/Page-1.svg"}
                      width="17px"
                    />
                  </button>
                </div>
                <div className="text-center">
                  <h4 className="my-linkmodal-text enterotp">Enter OTP</h4>
                </div>
                <div className="input-userinfo otpinput emailotp">
                  <h5 className="otpinput-head">
                    Enter OTP sent to user's mobile
                  </h5>
                  <input
                    className="Userinfo-input otp-input"
                    maxLength="6"
                    type="tel"
                    value={this.state.mobileotp}
                    onChange={(e) =>
                      this.setState({ mobileotp: e.target.value })
                    }
                    id="userinfoinput"
                    placeholder="Enter OTP here..."
                  />
                  <p
                    className=" mt-2 warn align-items-center m-0"
                    id="invalidtempusermobileotp"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/Icon_feather-alert-circle.svg"
                      }
                      width="15px"
                      className=" mr-1"
                    />{" "}
                    <span className="invalidmobile"></span>
                  </p>
                </div>
                <div className="input-userinfo otpinput emailotp">
                  <h5 className="otpinput-head">
                    Enter OTP sent to user's email
                  </h5>
                  <input
                    className="Userinfo-input otp-input "
                    maxLength="6"
                    type="tel"
                    value={this.state.emailotp}
                    onChange={(e) =>
                      this.setState({ emailotp: e.target.value })
                    }
                    id="userinfoinput"
                    placeholder="Enter OTP here..."
                  />
                  <p
                    className=" mt-2 warn align-items-center m-0"
                    id="invalidtempuseremailotp"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/Icon_feather-alert-circle.svg"
                      }
                      width="15px"
                      className=" mr-1"
                    />{" "}
                    <span className="invalidemail"></span>
                  </p>
                </div>

                <div className="d-flex justify-content-between  otpmodaloptions">
                  <h6 onClick={this.changeuserinfo}>Change mobile/email</h6>{" "}
                  <h6
                    className="text-muted resendOTP"
                    id=""
                    onClick={this.resenduserOtp}
                  >
                    Resend OTP  {this.state.rtime != 0 ? `(${this.state.rtime} Sec)` : " "}
                  </h6>
                </div>
                <p className="" id="otpsentsuccesstemp">
                  OTP sent successfully{" "}
                </p>
                <div className="modal-footer border-0 justify-content-center mb-4">
                  <img
                    className=" p-2 pl-3 pr-3 sendOtpLoader"
                    src="/img/loader.gif"
                    width="80px"
                    alt=""
                  />
                  <button
                    type="button"
                    onClick={this.SubmitOTP}
                    className="btn  send-btn text-white"
                    id="submitOtpbtn"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default AgentPanelServices;

import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { Link } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import { Redirect } from "react-router";
import jsonData from "../State__and__dist.json";
import AllPricing from "../data.json";
import json2 from "../State_shortcode.json";

let servicestate;
let Decagentid;
let Decjwttoken;
var data = [];
var sessiondata;
let supportName;
let supportContact;
let supportEmail;
var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var myform;
let pricingJson;
var encryptor = require("simple-encryptor")(key);
class Addserviceoa extends Component {
  constructor(props) {
    super(props);

    this.state = {
      serviceselectvalue: "",
      citydistrictselectvalue: "",
      servicefullname: "",
      serviceemail: "",
      servicemobileno: "",
      dropDownValue: "1",
      firstdropdownvalue: "Y",
      seconddropdownvalue: "Y",
      thirddropdownvalue: "Y",
      fourdropdownvalue: "Y",
      fivedropdownvalue: "Y",
      sixdropdownvalue: "Y",
      sevendropdownvalue: "Y",
      eightdropdownvalue: "Y",
      ninedropdownvalue: "Y",
      tendropdownvalue: "Y",
      elevendropdownvalue: "Y",
      twelvedropdownvalue: "Y",
      thirteendropdownvalue: "Y",
      fourteendropdownvalue: "Y",
      fifteendropdownvalue: "Y",
      sixteendropdownvalue: "Y",
      seventeendropdownvalue: "Y",
      eighteendropdownvalue: "Y",
      nineteendropdownvalue: "Y",
      twentydropdownvalue: "Y",
      mainpackage: "WI",

      supportName: "",
      supportContact: "",
      supportEmail: "",

       // With Inventory

      //  Transactions - 1

      //  BASIC //

      OA1BWIM: "",
      OA1BWIQ: "",
      OA1BWIH: "",
      OA1BWIY: "",
      OA1BWIY2: "",

      // No Discount Price

      OA1BWIMnodis: "",
      OA1BWIQnodis: "",
      OA1BWIHnodis: "",
      OA1BWIYnodis: "",
      OA1BWIY2nodis: "",

      // Discount Percentage

      OA1BWIMDisPer: "",
      OA1BWIQDisPer: "",
      OA1BWIHDisPer: "",
      OA1BWIYDisPer: "",
      OA1BWIY2DisPer: "",

      // PRO //

      OA1PWIM: "",
      OA1PWIQ: "",
      OA1PWIH: "",
      OA1PWIY: "",
      OA1PWIY2: "",

      // No Discount Price

      OA1PWIMnodis: "",
      OA1PWIQnodis: "",
      OA1PWIHnodis: "",
      OA1PWIYnodis: "",
      OA1PWIY2nodis: "",

      // Discount Percentage

      OA1PWIMDisPer: "",
      OA1PWIQDisPer: "",
      OA1PWIHDisPer: "",
      OA1PWIYDisPer: "",
      OA1PWIY2DisPer: "",

      //  Transactions - 2
      //  BASIC //

      OA2BWIM: "",
      OA2BWIQ: "",
      OA2BWIH: "",
      OA2BWIY: "",
      OA2BWIY2: "",

      OA2BWIMnodis: "",
      OA2BWIQnodis: "",
      OA2BWIHnodis: "",
      OA2BWIYnodis: "",
      OA2BWIY2nodis: "",

      // Discount Percentage

      OA2BWIMDisPer: "",
      OA2BWIQDisPer: "",
      OA2BWIHDisPer: "",
      OA2BWIYDisPer: "",
      OA2BWIY2DisPer: "",

      // PRO //

      OA2PWIM: "",
      OA2PWIQ: "",
      OA2PWIH: "",
      OA2PWIY: "",
      OA2PWIY2: "",

      // No Discount Price

      OA2PWIMnodis: "",
      OA2PWIQnodis: "",
      OA2PWIHnodis: "",
      OA2PWIYnodis: "",
      OA2PWIY2nodis: "",

      // Discount Percentage

      OA2PWIMDisPer: "",
      OA2PWIQDisPer: "",
      OA2PWIHDisPer: "",
      OA2PWIYDisPer: "",
      OA2PWIY2DisPer: "",

      //  Transactions - 3
      //  BASIC //

      OA3BWIM: "",
      OA3BWIQ: "",
      OA3BWIH: "",
      OA3BWIY: "",
      OA3BWIY2: "",

      // No Discount Price

      OA3BWIMnodis: "",
      OA3BWIQnodis: "",
      OA3BWIHnodis: "",
      OA3BWIYnodis: "",
      OA3BWIY2nodis: "",

      // Discount Percentage

      OA3BWIMDisPer: "",
      OA3BWIQDisPer: "",
      OA3BWIHDisPer: "",
      OA3BWIYDisPer: "",
      OA3BWIY2DisPer: "",

      // PRO //

      OA3PWIM: "",
      OA3PWIQ: "",
      OA3PWIH: "",
      OA3PWIY: "",
      OA3PWIY2: "",

      // No Discount Price

      OA3PWIMnodis: "",
      OA3PWIQnodis: "",
      OA3PWIHnodis: "",
      OA3PWIYnodis: "",
      OA3PWIY2nodis: "",

      // Discount Percentage

      OA3PWIMDisPer: "",
      OA3PWIQDisPer: "",
      OA3PWIHDisPer: "",
      OA3PWIYDisPer: "",
      OA3PWIY2DisPer: "",

      //  Transactions - 4
      //  BASIC //

      OA4BWIM: "",
      OA4BWIQ: "",
      OA4BWIH: "",
      OA4BWIY: "",
      OA4BWIY2: "",

      // No Discount Price

      OA4BWIMnodis: "",
      OA4BWIQnodis: "",
      OA4BWIHnodis: "",
      OA4BWIYnodis: "",
      OA4BWIY2nodis: "",

      // Discount Percentage

      OA4BWIMDisPer: "",
      OA4BWIQDisPer: "",
      OA4BWIHDisPer: "",
      OA4BWIYDisPer: "",
      OA4BWIY2DisPer: "",

      // PRO //

      OA4PWIM: "",
      OA4PWIQ: "",
      OA4PWIH: "",
      OA4PWIY: "",
      OA4PWIY2: "",

      // No Discount Price

      OA4PWIMnodis: "",
      OA4PWIQnodis: "",
      OA4PWIHnodis: "",
      OA4PWIYnodis: "",
      OA4PWIY2nodis: "",

      // Discount Percentage

      OA4PWIMDisPer: "",
      OA4PWIQDisPer: "",
      OA4PWIHDisPer: "",
      OA4PWIYDisPer: "",
      OA4PWIY2DisPer: "",

      //  Transactions - 5
      //  BASIC //

      OA5BWIM: "",
      OA5BWIQ: "",
      OA5BWIH: "",
      OA5BWIY: "",
      OA5BWIY2: "",

      // No Discount Price

      OA5BWIMnodis: "",
      OA5BWIQnodis: "",
      OA5BWIHnodis: "",
      OA5BWIYnodis: "",
      OA5BWIY2nodis: "",

      // Discount Percentage

      OA5BWIMDisPer: "",
      OA5BWIQDisPer: "",
      OA5BWIHDisPer: "",
      OA5BWIYDisPer: "",
      OA5BWIY2DisPer: "",

      // PRO //

      OA5PWIM: "",
      OA5PWIQ: "",
      OA5PWIH: "",
      OA5PWIY: "",
      OA5PWIY2: "",

      // No Discount Price

      OA5PWIMnodis: "",
      OA5PWIQnodis: "",
      OA5PWIHnodis: "",
      OA5PWIYnodis: "",
      OA5PWIY2nodis: "",

      // Discount Percentage

      OA5PWIMDisPer: "",
      OA5PWIQDisPer: "",
      OA5PWIHDisPer: "",
      OA5PWIYDisPer: "",
      OA5PWIY2DisPer: "",

      // Without Inventory

      //  Transactions - 1
      //  BASIC //

      OA1BWOIM: "",
      OA1BWOIQ: "",
      OA1BWOIH: "",
      OA1BWOIY: "",
      OA1BWOIY2: "",

      // No Discount Price

      OA1BWOIMnodis: "",
      OA1BWOIQnodis: "",
      OA1BWOIHnodis: "",
      OA1BWOIYnodis: "",
      OA1BWOIY2nodis: "",

      // Discount Percentage

      OA1BWOIMDisPer: "",
      OA1BWOIQDisPer: "",
      OA1BWOIHDisPer: "",
      OA1BWOIYDisPer: "",
      OA1BWOIY2DisPer: "",

      // PRO //

      OA1PWOIM: "",
      OA1PWOIQ: "",
      OA1PWOIH: "",
      OA1PWOIY: "",
      OA1PWOIY2: "",

      // No Discount Price

      OA1PWOIMnodis: "",
      OA1PWOIQnodis: "",
      OA1PWOIHnodis: "",
      OA1PWOIYnodis: "",
      OA1PWOIY2nodis: "",

      // Discount Percentage

      OA1PWOIMDisPer: "",
      OA1PWOIQDisPer: "",
      OA1PWOIHDisPer: "",
      OA1PWOIYDisPer: "",
      OA1PWOIY2DisPer: "",

      //  Transactions - 2
      //  BASIC //

      OA2BWOIM: "",
      OA2BWOIQ: "",
      OA2BWOIH: "",
      OA2BWOIY: "",
      OA2BWOIY2: "",

      // No Discount Price

      OA2BWOIMnodis: "",
      OA2BWOIQnodis: "",
      OA2BWOIHnodis: "",
      OA2BWOIYnodis: "",
      OA2BWOIY2nodis: "",

      // Discount Percentage

      OA2BWOIMDisPer: "",
      OA2BWOIQDisPer: "",
      OA2BWOIHDisPer: "",
      OA2BWOIYDisPer: "",
      OA2BWOIY2DisPer: "",

      // PRO //

      OA2PWOIM: "",
      OA2PWOIQ: "",
      OA2PWOIH: "",
      OA2PWOIY: "",
      OA2PWOIY2: "",

      // No Discount Price

      OA2PWOIMnodis: "",
      OA2PWOIQnodis: "",
      OA2PWOIHnodis: "",
      OA2PWOIYnodis: "",
      OA2PWOIY2nodis: "",

      // Discount Percentage

      OA2PWOIMDisPer: "",
      OA2PWOIQDisPer: "",
      OA2PWOIHDisPer: "",
      OA2PWOIYDisPer: "",
      OA2PWOIY2DisPer: "",

      //  Transactions - 3
      //  BASIC //

      OA3BWOIM: "",
      OA3BWOIQ: "",
      OA3BWOIH: "",
      OA3BWOIY: "",
      OA3BWOIY2: "",

      // No Discount Price

      OA3BWOIMnodis: "",
      OA3BWOIQnodis: "",
      OA3BWOIHnodis: "",
      OA3BWOIYnodis: "",
      OA3BWOIY2nodis: "",

      // Discount Percentage

      OA3BWOIMDisPer: "",
      OA3BWOIQDisPer: "",
      OA3BWOIHDisPer: "",
      OA3BWOIYDisPer: "",
      OA3BWOIY2DisPer: "",

      // PRO //

      OA3PWOIM: "",
      OA3PWOIQ: "",
      OA3PWOIH: "",
      OA3PWOIY: "",
      OA3PWOIY2: "",

      // No Discount Price

      OA3PWOIMnodis: "",
      OA3PWOIQnodis: "",
      OA3PWOIHnodis: "",
      OA3PWOIYnodis: "",
      OA3PWOIY2nodis: "",

      // Discount Percentage

      OA3PWOIMDisPer: "",
      OA3PWOIQDisPer: "",
      OA3PWOIHDisPer: "",
      OA3PWOIYDisPer: "",
      OA3PWOIY2DisPer: "",

      //  Transactions - 4
      //  BASIC //

      OA4BWOIM: "",
      OA4BWOIQ: "",
      OA4BWOIH: "",
      OA4BWOIY: "",
      OA4BWOIY2: "",

      // No Discount Price

      OA4BWOIMnodis: "",
      OA4BWOIQnodis: "",
      OA4BWOIHnodis: "",
      OA4BWOIYnodis: "",
      OA4BWOIY2nodis: "",

      // Discount Percentage

      OA4BWOIMDisPer: "",
      OA4BWOIQDisPer: "",
      OA4BWOIHDisPer: "",
      OA4BWOIYDisPer: "",
      OA4BWOIY2DisPer: "",

      // PRO //

      OA4PWOIM: "",
      OA4PWOIQ: "",
      OA4PWOIH: "",
      OA4PWOIY: "",
      OA4PWOIY2: "",

      // No Discount Price

      OA4PWOIMnodis: "",
      OA4PWOIQnodis: "",
      OA4PWOIHnodis: "",
      OA4PWOIYnodis: "",
      OA4PWOIY2nodis: "",

      // Discount Percentage

      OA4PWOIMDisPer: "",
      OA4PWOIQDisPer: "",
      OA4PWOIHDisPer: "",
      OA4PWOIYDisPer: "",
      OA4PWOIY2DisPer: "",

      //  Transactions - 5
      //  BASIC //

      OA5BWOIM: "",
      OA5BWOIQ: "",
      OA5BWOIH: "",
      OA5BWOIY: "",
      OA5BWOIY2: "",

      // No Discount Price

      OA5BWOIMnodis: "",
      OA5BWOIQnodis: "",
      OA5BWOIHnodis: "",
      OA5BWOIYnodis: "",
      OA5BWOIY2nodis: "",

      // Discount Percentage

      OA5BWOIMDisPer: "",
      OA5BWOIQDisPer: "",
      OA5BWOIHDisPer: "",
      OA5BWOIYDisPer: "",
      OA5BWOIY2DisPer: "",

      // PRO //

      OA5PWOIM: "",
      OA5PWOIQ: "",
      OA5PWOIH: "",
      OA5PWOIY: "",
      OA5PWOIY2: "",

      // No Discount Price

      OA5PWOIMnodis: "",
      OA5PWOIQnodis: "",
      OA5PWOIHnodis: "",
      OA5PWOIYnodis: "",
      OA5PWOIY2nodis: "",

      // Discount Percentage

      OA5PWOIMDisPer: "",
      OA5PWOIQDisPer: "",
      OA5PWOIHDisPer: "",
      OA5PWOIYDisPer: "",
      OA5PWOIY2DisPer: "",
    };
  }
  firstdropdownlichange = (e) => {
    this.setState({
      firstdropdownvalue: $(e.currentTarget).attr("value"),
    });
    var getValue = $(e.currentTarget).html();
    $(".firstdropdown").html(getValue);
  };
  seconddropdownlichange = (e) => {
    this.setState({
      seconddropdownvalue: $(e.currentTarget).attr("value"),
    });
    var getValue = $(e.currentTarget).html();
    $(".seconddropdown").html(getValue);
  };
  thirddropdownlichange = (e) => {
    this.setState({
      thirddropdownvalue: $(e.currentTarget).attr("value"),
    });
    var getValue = $(e.currentTarget).html();
    $(".thirddropdown").html(getValue);
  };
  fourdropdownlichange = (e) => {
    this.setState({
      fourdropdownvalue: $(e.currentTarget).attr("value"),
    });
    var getValue = $(e.currentTarget).html();
    $(".fourdropdown").html(getValue);
  };
  fivedropdownlichange = (e) => {
    this.setState({
      fivedropdownvalue: $(e.currentTarget).attr("value"),
    });
    var getValue = $(e.currentTarget).html();
    $(".fivedropdown").html(getValue);
  };
  sixdropdownlichange = (e) => {
    this.setState({
      sixdropdownvalue: $(e.currentTarget).attr("value"),
    });
    var getValue = $(e.currentTarget).html();
    $(".sixdropdown").html(getValue);
  };
  sevendropdownlichange = (e) => {
    this.setState({
      sevendropdownvalue: $(e.currentTarget).attr("value"),
    });
    var getValue = $(e.currentTarget).html();
    $(".sevendropdown").html(getValue);
  };
  eightdropdownlichange = (e) => {
    this.setState({
      eightdropdownvalue: $(e.currentTarget).attr("value"),
    });
    var getValue = $(e.currentTarget).html();
    $(".eightdropdown").html(getValue);
  };
  ninedropdownlichange = (e) => {
    this.setState({
      ninedropdownvalue: $(e.currentTarget).attr("value"),
    });
    var getValue = $(e.currentTarget).html();
    $(".ninedropdown").html(getValue);
  };
  tendropdownlichange = (e) => {
    this.setState({
      tendropdownvalue: $(e.currentTarget).attr("value"),
    });
    var getValue = $(e.currentTarget).html();
    $(".tendropdown").html(getValue);
  };
  elevendropdownlichange = (e) => {
    this.setState({
      elevendropdownvalue: $(e.currentTarget).attr("value"),
    });
    var getValue = $(e.currentTarget).html();
    $(".elevendropdown").html(getValue);
  };
  twelvedropdownlichange = (e) => {
    this.setState({
      twelvedropdownvalue: $(e.currentTarget).attr("value"),
    });
    var getValue = $(e.currentTarget).html();
    $(".twelvedropdown").html(getValue);
  };
  thirteendropdownlichange = (e) => {
    this.setState({
      thirteendropdownvalue: $(e.currentTarget).attr("value"),
    });
    var getValue = $(e.currentTarget).html();
    $(".thirteendropdown").html(getValue);
  };
  fourteendropdownlichange = (e) => {
    this.setState({
      fourteendropdownvalue: $(e.currentTarget).attr("value"),
    });
    var getValue = $(e.currentTarget).html();
    $(".fourteendropdown").html(getValue);
  };
  fifteendropdownlichange = (e) => {
    this.setState({
      fourteendropdownvalue: $(e.currentTarget).attr("value"),
    });
    var getValue = $(e.currentTarget).html();
    $(".fifteendropdown").html(getValue);
  };
  sixteendropdownlichange = (e) => {
    this.setState({
      fourteendropdownvalue: $(e.currentTarget).attr("value"),
    });
    var getValue = $(e.currentTarget).html();
    $(".sixteendropdown").html(getValue);
  };
  seventeendropdownlichange = (e) => {
    this.setState({
      fourteendropdownvalue: $(e.currentTarget).attr("value"),
    });
    var getValue = $(e.currentTarget).html();
    $(".seventeendropdown").html(getValue);
  };
  eighteendropdownlichange = (e) => {
    this.setState({
      fourteendropdownvalue: $(e.currentTarget).attr("value"),
    });
    var getValue = $(e.currentTarget).html();
    $(".eighteendropdown").html(getValue);
  };
  nineteendropdownlichange = (e) => {
    this.setState({
      fourteendropdownvalue: $(e.currentTarget).attr("value"),
    });
    var getValue = $(e.currentTarget).html();
    $(".nineteendropdown").html(getValue);
  };
  twentydropdownlichange = (e) => {
    this.setState({
      fourteendropdownvalue: $(e.currentTarget).attr("value"),
    });
    var getValue = $(e.currentTarget).html();
    $(".twentydropdown").html(getValue);
  };

  firstdropdowngetstartedclick = (e) => {
    $(".plangetstartedbtn").show();
    $(".getform").hide();
    $(e.target).closest(".formplacer").append(myform.slideDown());
    $(".hidesalcon1").hide();
    $(".hidesalcon2").show();
    $(".hidesalcon3").show();
    $(".hidesalcon4").show();
    $(".hidesalcon5").show();
    $(".hidesalcon6").show();
    $(".hidesalcon7").show();
    $(".hidesalcon8").show();
    $(".hidesalcon9").show();
    $(".hidesalcon10").show();
    $(".hidesalcon11").show();
    $(".hidesalcon12").show();
    $(".hidesalcon13").show();
    $(".hidesalcon14").show();
    $(".hidesalcon15").show();
    $(".hidesalcon16").show();
    $(".hidesalcon17").show();
    $(".hidesalcon18").show();
    $(".hidesalcon19").show();
    $(".hidesalcon20").show();

    this.setState({
      mainduration: this.state.firstdropdownvalue,
      mainplan: "B",
      citydistrictselectvalue: "",
      servicefullname: "",
    });
  };
  seconddropdowngetstartedclick = (e) => {
    $(".plangetstartedbtn").show();
    $(".getform2").hide();
    $(e.target).closest(".formplacer").append(myform.slideDown());
    $(".hidesalcon1").show();
    $(".hidesalcon2").hide();
    $(".hidesalcon3").show();
    $(".hidesalcon4").show();
    $(".hidesalcon5").show();
    $(".hidesalcon6").show();
    $(".hidesalcon7").show();
    $(".hidesalcon8").show();
    $(".hidesalcon9").show();
    $(".hidesalcon10").show();
    $(".hidesalcon11").show();
    $(".hidesalcon12").show();
    $(".hidesalcon13").show();
    $(".hidesalcon14").show();
    $(".hidesalcon15").show();
    $(".hidesalcon16").show();
    $(".hidesalcon17").show();
    $(".hidesalcon18").show();
    $(".hidesalcon19").show();
    $(".hidesalcon20").show();

    this.setState({
      mainduration: this.state.seconddropdownvalue,
      mainplan: "P",
      citydistrictselectvalue: "",
      servicefullname: "",
    });
  };
  thirddropdowngetstartedclick = (e) => {
    $(".plangetstartedbtn").show();
    $(".getform3").hide();
    $(e.target).closest(".formplacer").append(myform.slideDown());
    $(".hidesalcon1").show();
    $(".hidesalcon2").show();
    $(".hidesalcon3").hide();
    $(".hidesalcon4").show();
    $(".hidesalcon5").show();
    $(".hidesalcon6").show();
    $(".hidesalcon7").show();
    $(".hidesalcon8").show();
    $(".hidesalcon9").show();
    $(".hidesalcon10").show();
    $(".hidesalcon11").show();
    $(".hidesalcon12").show();
    $(".hidesalcon13").show();
    $(".hidesalcon14").show();
    $(".hidesalcon15").show();
    $(".hidesalcon16").show();
    $(".hidesalcon17").show();
    $(".hidesalcon18").show();
    $(".hidesalcon19").show();
    $(".hidesalcon20").show();

    this.setState({
      mainduration: this.state.thirddropdownvalue,
      mainplan: "B",
      citydistrictselectvalue: "",
      servicefullname: "",
    });
  };
  fourdropdowngetstartedclick = (e) => {
    $(".plangetstartedbtn").show();
    $(".getform4").hide();
    $(e.target).closest(".formplacer").append(myform.slideDown());
    $(".hidesalcon1").show();
    $(".hidesalcon2").show();
    $(".hidesalcon3").show();
    $(".hidesalcon4").hide();
    $(".hidesalcon5").show();
    $(".hidesalcon6").show();
    $(".hidesalcon7").show();
    $(".hidesalcon8").show();
    $(".hidesalcon9").show();
    $(".hidesalcon10").show();
    $(".hidesalcon11").show();
    $(".hidesalcon12").show();
    $(".hidesalcon13").show();
    $(".hidesalcon14").show();
    $(".hidesalcon15").show();
    $(".hidesalcon16").show();
    $(".hidesalcon17").show();
    $(".hidesalcon18").show();
    $(".hidesalcon19").show();
    $(".hidesalcon20").show();

    this.setState({
      mainduration: this.state.fourdropdownvalue,
      mainplan: "P",
      citydistrictselectvalue: "",
      servicefullname: "",
    });
  };
  fivedropdowngetstartedclick = (e) => {
    $(".plangetstartedbtn").show();
    $(".getform5").hide();
    $(e.target).closest(".formplacer").append(myform.slideDown());
    $(".hidesalcon1").show();
    $(".hidesalcon2").show();
    $(".hidesalcon3").show();
    $(".hidesalcon4").show();
    $(".hidesalcon5").hide();
    $(".hidesalcon6").show();
    $(".hidesalcon7").show();
    $(".hidesalcon8").show();
    $(".hidesalcon9").show();
    $(".hidesalcon10").show();
    $(".hidesalcon11").show();
    $(".hidesalcon12").show();
    $(".hidesalcon13").show();
    $(".hidesalcon14").show();
    $(".hidesalcon15").show();
    $(".hidesalcon16").show();
    $(".hidesalcon17").show();
    $(".hidesalcon18").show();
    $(".hidesalcon19").show();
    $(".hidesalcon20").show();

    this.setState({
      mainduration: this.state.fivedropdownvalue,
      mainplan: "B",
      citydistrictselectvalue: "",
      servicefullname: "",
    });
  };

  sixdropdowngetstartedclick = (e) => {
    $(".plangetstartedbtn").show();
    $(".getform6").hide();
    $(e.target).closest(".formplacer").append(myform.slideDown());
    $(".hidesalcon1").show();
    $(".hidesalcon2").show();
    $(".hidesalcon3").show();
    $(".hidesalcon4").show();
    $(".hidesalcon5").show();
    $(".hidesalcon6").hide();
    $(".hidesalcon7").show();
    $(".hidesalcon8").show();
    $(".hidesalcon9").show();
    $(".hidesalcon10").show();
    $(".hidesalcon11").show();
    $(".hidesalcon12").show();
    $(".hidesalcon13").show();
    $(".hidesalcon14").show();
    $(".hidesalcon15").show();
    $(".hidesalcon16").show();
    $(".hidesalcon17").show();
    $(".hidesalcon18").show();
    $(".hidesalcon19").show();
    $(".hidesalcon20").show();

    this.setState({
      mainduration: this.state.sixdropdownvalue,
      mainplan: "P",
      citydistrictselectvalue: "",
      servicefullname: "",
    });
  };
  sevendropdowngetstartedclick = (e) => {
    $(".plangetstartedbtn").show();
    $(".getform7").hide();
    $(e.target).closest(".formplacer").append(myform.slideDown());
    $(".hidesalcon1").show();
    $(".hidesalcon2").show();
    $(".hidesalcon3").show();
    $(".hidesalcon4").show();
    $(".hidesalcon5").show();
    $(".hidesalcon6").show();
    $(".hidesalcon7").hide();
    $(".hidesalcon8").show();
    $(".hidesalcon9").show();
    $(".hidesalcon10").show();
    $(".hidesalcon11").show();
    $(".hidesalcon12").show();
    $(".hidesalcon13").show();
    $(".hidesalcon14").show();
    $(".hidesalcon15").show();
    $(".hidesalcon16").show();
    $(".hidesalcon17").show();
    $(".hidesalcon18").show();
    $(".hidesalcon19").show();
    $(".hidesalcon20").show();

    this.setState({
      mainduration: this.state.sevendropdownvalue,
      mainplan: "B",
      citydistrictselectvalue: "",
      servicefullname: "",
    });
  };
  eightdropdowngetstartedclick = (e) => {
    $(".plangetstartedbtn").show();
    $(".getform8").hide();
    $(e.target).closest(".formplacer").append(myform.slideDown());
    $(".hidesalcon1").show();
    $(".hidesalcon2").show();
    $(".hidesalcon3").show();
    $(".hidesalcon4").show();
    $(".hidesalcon5").show();
    $(".hidesalcon6").show();
    $(".hidesalcon7").show();
    $(".hidesalcon8").hide();
    $(".hidesalcon9").show();
    $(".hidesalcon10").show();
    $(".hidesalcon11").show();
    $(".hidesalcon12").show();
    $(".hidesalcon13").show();
    $(".hidesalcon14").show();
    $(".hidesalcon15").show();
    $(".hidesalcon16").show();
    $(".hidesalcon17").show();
    $(".hidesalcon18").show();
    $(".hidesalcon19").show();
    $(".hidesalcon20").show();

    this.setState({
      mainduration: this.state.eightdropdownvalue,
      mainplan: "P",
      citydistrictselectvalue: "",
      servicefullname: "",
    });
  };
  ninedropdowngetstartedclick = (e) => {
    $(".plangetstartedbtn").show();
    $(".getform9").hide();
    $(e.target).closest(".formplacer").append(myform.slideDown());
    $(".hidesalcon1").show();
    $(".hidesalcon2").show();
    $(".hidesalcon3").show();
    $(".hidesalcon4").show();
    $(".hidesalcon5").show();
    $(".hidesalcon6").show();
    $(".hidesalcon7").show();
    $(".hidesalcon8").show();
    $(".hidesalcon9").hide();
    $(".hidesalcon10").show();
    $(".hidesalcon11").show();
    $(".hidesalcon12").show();
    $(".hidesalcon13").show();
    $(".hidesalcon14").show();
    $(".hidesalcon15").show();
    $(".hidesalcon16").show();
    $(".hidesalcon17").show();
    $(".hidesalcon18").show();
    $(".hidesalcon19").show();
    $(".hidesalcon20").show();

    this.setState({
      mainduration: this.state.ninedropdownvalue,
      mainplan: "B",
      citydistrictselectvalue: "",
      servicefullname: "",
    });
  };
  tendropdowngetstartedclick = (e) => {
    $(".plangetstartedbtn").show();
    $(".getform10").hide();
    $(e.target).closest(".formplacer").append(myform.slideDown());
    $(".hidesalcon1").show();
    $(".hidesalcon2").show();
    $(".hidesalcon3").show();
    $(".hidesalcon4").show();
    $(".hidesalcon5").show();
    $(".hidesalcon6").show();
    $(".hidesalcon7").show();
    $(".hidesalcon8").show();
    $(".hidesalcon9").show();
    $(".hidesalcon10").hide();
    $(".hidesalcon11").show();
    $(".hidesalcon12").show();
    $(".hidesalcon13").show();
    $(".hidesalcon14").show();
    $(".hidesalcon15").show();
    $(".hidesalcon16").show();
    $(".hidesalcon17").show();
    $(".hidesalcon18").show();
    $(".hidesalcon19").show();
    $(".hidesalcon20").show();

    this.setState({
      mainduration: this.state.tendropdownvalue,
      mainplan: "P",
      citydistrictselectvalue: "",
      servicefullname: "",
    });
  };

  elevendropdowngetstartedclick = (e) => {
    $(".plangetstartedbtn").show();
    $(".getform11").hide();
    $(e.target).closest(".formplacer").append(myform.slideDown());
    $(".hidesalcon1").show();
    $(".hidesalcon2").show();
    $(".hidesalcon3").show();
    $(".hidesalcon4").show();
    $(".hidesalcon5").show();
    $(".hidesalcon6").show();
    $(".hidesalcon7").show();
    $(".hidesalcon8").show();
    $(".hidesalcon9").show();
    $(".hidesalcon10").show();
    $(".hidesalcon11").hide();
    $(".hidesalcon12").show();
    $(".hidesalcon13").show();
    $(".hidesalcon14").show();
    $(".hidesalcon15").show();
    $(".hidesalcon16").show();
    $(".hidesalcon17").show();
    $(".hidesalcon18").show();
    $(".hidesalcon19").show();
    $(".hidesalcon20").show();

    this.setState({
      mainduration: this.state.elevendropdownvalue,
      mainplan: "B",
      citydistrictselectvalue: "",
      servicefullname: "",
    });
  };
  twelvedropdowngetstartedclick = (e) => {
    $(".plangetstartedbtn").show();
    $(".getform12").hide();
    $(e.target).closest(".formplacer").append(myform.slideDown());
    $(".hidesalcon1").show();
    $(".hidesalcon2").show();
    $(".hidesalcon3").show();
    $(".hidesalcon4").show();
    $(".hidesalcon5").show();
    $(".hidesalcon6").show();
    $(".hidesalcon7").show();
    $(".hidesalcon8").show();
    $(".hidesalcon9").show();
    $(".hidesalcon10").show();
    $(".hidesalcon11").show();
    $(".hidesalcon12").hide();
    $(".hidesalcon13").show();
    $(".hidesalcon14").show();
    $(".hidesalcon15").show();
    $(".hidesalcon16").show();
    $(".hidesalcon17").show();
    $(".hidesalcon18").show();
    $(".hidesalcon19").show();
    $(".hidesalcon20").show();

    this.setState({
      mainduration: this.state.twelvedropdownvalue,
      mainplan: "P",
      citydistrictselectvalue: "",
      servicefullname: "",
    });
  };
  thirteendropdowngetstartedclick = (e) => {
    $(".plangetstartedbtn").show();
    $(".getform13").hide();
    $(e.target).closest(".formplacer").append(myform.slideDown());
    $(".hidesalcon1").show();
    $(".hidesalcon2").show();
    $(".hidesalcon3").show();
    $(".hidesalcon4").show();
    $(".hidesalcon5").show();
    $(".hidesalcon6").show();
    $(".hidesalcon7").show();
    $(".hidesalcon8").show();
    $(".hidesalcon9").show();
    $(".hidesalcon10").show();
    $(".hidesalcon11").show();
    $(".hidesalcon12").show();
    $(".hidesalcon13").hide();
    $(".hidesalcon14").show();
    $(".hidesalcon15").show();
    $(".hidesalcon16").show();
    $(".hidesalcon17").show();
    $(".hidesalcon18").show();
    $(".hidesalcon19").show();
    $(".hidesalcon20").show();

    this.setState({
      mainduration: this.state.thirteendropdownvalue,
      mainplan: "B",
      citydistrictselectvalue: "",
      servicefullname: "",
    });
  };
  fourteendropdowngetstartedclick = (e) => {
    $(".plangetstartedbtn").show();
    $(".getform14").hide();
    $(e.target).closest(".formplacer").append(myform.slideDown());
    $(".hidesalcon1").show();
    $(".hidesalcon2").show();
    $(".hidesalcon3").show();
    $(".hidesalcon4").show();
    $(".hidesalcon5").show();
    $(".hidesalcon6").show();
    $(".hidesalcon7").show();
    $(".hidesalcon8").show();
    $(".hidesalcon9").show();
    $(".hidesalcon10").show();
    $(".hidesalcon11").show();
    $(".hidesalcon12").show();
    $(".hidesalcon13").show();
    $(".hidesalcon14").hide();
    $(".hidesalcon15").show();
    $(".hidesalcon16").show();
    $(".hidesalcon17").show();
    $(".hidesalcon18").show();
    $(".hidesalcon19").show();
    $(".hidesalcon20").show();

    this.setState({
      mainduration: this.state.fourteendropdownvalue,
      mainplan: "P",
      citydistrictselectvalue: "",
      servicefullname: "",
    });
  };

  fifteendropdowngetstartedclick = (e) => {
    $(".plangetstartedbtn").show();
    $(".getform15").hide();
    $(e.target).closest(".formplacer").append(myform.slideDown());
    $(".hidesalcon1").show();
    $(".hidesalcon2").show();
    $(".hidesalcon3").show();
    $(".hidesalcon4").show();
    $(".hidesalcon5").show();
    $(".hidesalcon6").show();
    $(".hidesalcon7").show();
    $(".hidesalcon8").show();
    $(".hidesalcon9").show();
    $(".hidesalcon10").show();
    $(".hidesalcon11").show();
    $(".hidesalcon12").show();
    $(".hidesalcon13").show();
    $(".hidesalcon14").show();
    $(".hidesalcon15").hide();
    $(".hidesalcon16").show();
    $(".hidesalcon17").show();
    $(".hidesalcon18").show();
    $(".hidesalcon19").show();
    $(".hidesalcon20").show();

    this.setState({
      mainduration: this.state.fourteendropdownvalue,
      mainplan: "B",
      citydistrictselectvalue: "",
      servicefullname: "",
    });
  };
  sixteendropdowngetstartedclick = (e) => {
    $(".plangetstartedbtn").show();
    $(".getform16").hide();
    $(e.target).closest(".formplacer").append(myform.slideDown());
    $(".hidesalcon1").show();
    $(".hidesalcon2").show();
    $(".hidesalcon3").show();
    $(".hidesalcon4").show();
    $(".hidesalcon5").show();
    $(".hidesalcon6").show();
    $(".hidesalcon7").show();
    $(".hidesalcon8").show();
    $(".hidesalcon9").show();
    $(".hidesalcon10").show();
    $(".hidesalcon11").show();
    $(".hidesalcon12").show();
    $(".hidesalcon13").show();
    $(".hidesalcon14").show();
    $(".hidesalcon15").show();
    $(".hidesalcon16").hide();
    $(".hidesalcon17").show();
    $(".hidesalcon18").show();
    $(".hidesalcon19").show();
    $(".hidesalcon20").show();

    this.setState({
      mainduration: this.state.fourteendropdownvalue,
      mainplan: "P",
      citydistrictselectvalue: "",
      servicefullname: "",
    });
  };
  seventeendropdowngetstartedclick = (e) => {
    $(".plangetstartedbtn").show();
    $(".getform17").hide();
    $(e.target).closest(".formplacer").append(myform.slideDown());
    $(".hidesalcon1").show();
    $(".hidesalcon2").show();
    $(".hidesalcon3").show();
    $(".hidesalcon4").show();
    $(".hidesalcon5").show();
    $(".hidesalcon6").show();
    $(".hidesalcon7").show();
    $(".hidesalcon8").show();
    $(".hidesalcon9").show();
    $(".hidesalcon10").show();
    $(".hidesalcon11").show();
    $(".hidesalcon12").show();
    $(".hidesalcon13").show();
    $(".hidesalcon14").show();
    $(".hidesalcon15").show();
    $(".hidesalcon16").show();
    $(".hidesalcon17").hide();
    $(".hidesalcon18").show();
    $(".hidesalcon19").show();
    $(".hidesalcon20").show();

    this.setState({
      mainduration: this.state.fourteendropdownvalue,
      mainplan: "B",
      citydistrictselectvalue: "",
      servicefullname: "",
    });
  };
  eighteendropdowngetstartedclick = (e) => {
    $(".plangetstartedbtn").show();
    $(".getform18").hide();
    $(e.target).closest(".formplacer").append(myform.slideDown());
    $(".hidesalcon1").show();
    $(".hidesalcon2").show();
    $(".hidesalcon3").show();
    $(".hidesalcon4").show();
    $(".hidesalcon5").show();
    $(".hidesalcon6").show();
    $(".hidesalcon7").show();
    $(".hidesalcon8").show();
    $(".hidesalcon9").show();
    $(".hidesalcon10").show();
    $(".hidesalcon11").show();
    $(".hidesalcon12").show();
    $(".hidesalcon13").show();
    $(".hidesalcon14").show();
    $(".hidesalcon15").show();
    $(".hidesalcon16").show();
    $(".hidesalcon17").show();
    $(".hidesalcon18").hide();
    $(".hidesalcon19").show();
    $(".hidesalcon20").show();

    this.setState({
      mainduration: this.state.fourteendropdownvalue,
      mainplan: "P",
      citydistrictselectvalue: "",
      servicefullname: "",
    });
  };
  nineteendropdowngetstartedclick = (e) => {
    $(".plangetstartedbtn").show();
    $(".getform19").hide();
    $(e.target).closest(".formplacer").append(myform.slideDown());
    $(".hidesalcon1").show();
    $(".hidesalcon2").show();
    $(".hidesalcon3").show();
    $(".hidesalcon4").show();
    $(".hidesalcon5").show();
    $(".hidesalcon6").show();
    $(".hidesalcon7").show();
    $(".hidesalcon8").show();
    $(".hidesalcon9").show();
    $(".hidesalcon10").show();
    $(".hidesalcon11").show();
    $(".hidesalcon12").show();
    $(".hidesalcon13").show();
    $(".hidesalcon14").show();
    $(".hidesalcon15").show();
    $(".hidesalcon16").show();
    $(".hidesalcon17").show();
    $(".hidesalcon18").show();
    $(".hidesalcon19").hide();
    $(".hidesalcon20").show();

    this.setState({
      mainduration: this.state.fourteendropdownvalue,
      mainplan: "B",
      citydistrictselectvalue: "",
      servicefullname: "",
    });
  };
  twentydropdowngetstartedclick = (e) => {
    $(".plangetstartedbtn").show();
    $(".getform20").hide();
    $(e.target).closest(".formplacer").append(myform.slideDown());
    $(".hidesalcon1").show();
    $(".hidesalcon2").show();
    $(".hidesalcon3").show();
    $(".hidesalcon4").show();
    $(".hidesalcon5").show();
    $(".hidesalcon6").show();
    $(".hidesalcon7").show();
    $(".hidesalcon8").show();
    $(".hidesalcon9").show();
    $(".hidesalcon10").show();
    $(".hidesalcon11").show();
    $(".hidesalcon12").show();
    $(".hidesalcon13").show();
    $(".hidesalcon14").show();
    $(".hidesalcon15").show();
    $(".hidesalcon16").show();
    $(".hidesalcon17").show();
    $(".hidesalcon18").show();
    $(".hidesalcon19").show();
    $(".hidesalcon20").hide();

    this.setState({
      mainduration: this.state.fourteendropdownvalue,
      mainplan: "P",
      citydistrictselectvalue: "",
      servicefullname: "",
    });
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    myform = $("#multiform").hide(0);
    $(".WithoutInventorydiv").hide();

    $(document.body).css("background-color", "#2d2d2d");
    let mainjwttoken = localStorage.getItem("toeljgtkewlna");
    let mainagentid = localStorage.getItem("qazxswedcvfrtgb");
    Decagentid = encryptor.decrypt(mainagentid);
    Decjwttoken = encryptor.decrypt(mainjwttoken);
    supportName = localStorage.getItem("supportName");
    supportContact = localStorage.getItem("supportContact");
    supportEmail = localStorage.getItem("supportEmail");

    this.setState({
      supportName: supportName,
      supportContact: supportContact,
      supportEmail: supportEmail,
    });

    sessiondata = JSON.parse(sessionStorage.getItem("data"));

    if (sessiondata == null || sessiondata == undefined || sessiondata == "") {
      sessiondata = [];
    }
    // else  {
    //   sessiondata = JSON.parse(sessionStorage.getItem("data"))
    //  }

    if (sessiondata.length > 0) {
      if (sessiondata[0].userId == "") {
        var semail = sessionStorage.getItem("newuseremail");
        var scontact = sessionStorage.getItem("newusercontact");
        if (scontact != "" && semail != "") {
          this.setState({
            serviceemail: semail,
            servicemobileno: scontact,
          });
        }
      } else if (sessiondata[0].userId != "") {
        sessiondata = [];
        sessionStorage.removeItem("data");
      }
    } else {
      this.setState({
        serviceemail: "",
        servicemobileno: "",
      });
    }

    fetch(process.env.REACT_APP_API_PRICING_JSON_URL)
        .then((response) => response.json())
        .then((data) => {
          // Store the JSON data in localStorage
          localStorage.setItem("lshhihfnkff", JSON.stringify(data));
          this.priceUpdate();
        })
        .catch((error) => console.error("Error fetching data:", error));
  }

  priceUpdate = () => {
    pricingJson = JSON.parse(localStorage.getItem("lshhihfnkff"));

    // With Inventory

    //  Transactions - 1
    // BASIC

    let OA1BWIM = pricingJson["OA1BWIM"].price;
    let OA1BWIQ = pricingJson["OA1BWIQ"].price;
    let OA1BWIH = pricingJson["OA1BWIH"].price;
    let OA1BWIY = pricingJson["OA1BWIY"].price;
    let OA1BWIY2 = pricingJson["OA1BWIY2"].price;

    // No Discount Price

    let OA1BWIMnodi = pricingJson["OA1BWIM"].nodiscountprice;
    let OA1BWIQnodi = pricingJson["OA1BWIQ"].nodiscountprice;
    let OA1BWIHnodi = pricingJson["OA1BWIH"].nodiscountprice;
    let OA1BWIYnodi = pricingJson["OA1BWIY"].nodiscountprice;
    let OA1BWIY2nodi = pricingJson["OA1BWIY2"].nodiscountprice;

    // Discount Percentage

    let OA1BWIMDispe = pricingJson["OA1BWIM"].discountpercentage;
    let OA1BWIQDispe = pricingJson["OA1BWIQ"].discountpercentage;
    let OA1BWIHDispe = pricingJson["OA1BWIH"].discountpercentage;
    let OA1BWIYDispe = pricingJson["OA1BWIY"].discountpercentage;
    let OA1BWIY2Dispe = pricingJson["OA1BWIY2"].discountpercentage;

    this.setState({
      OA1BWIM: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA1BWIM * 0.18 + OA1BWIM)),
      OA1BWIQ: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA1BWIQ * 0.18 + OA1BWIQ)),
      OA1BWIH: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA1BWIH * 0.18 + OA1BWIH)),
      OA1BWIY: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA1BWIY * 0.18 + OA1BWIY)),
      OA1BWIY2: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA1BWIY2 * 0.18 + OA1BWIY2)),

      // No Discount Price

      OA1BWIMnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA1BWIMnodi),
      OA1BWIQnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA1BWIQnodi),
      OA1BWIHnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA1BWIHnodi),
      OA1BWIYnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA1BWIYnodi),
      OA1BWIY2nodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA1BWIY2nodi),

      // Discount Percentage

      OA1BWIMDisPer: OA1BWIMDispe,
      OA1BWIQDisPer: OA1BWIQDispe,
      OA1BWIHDisPer: OA1BWIHDispe,
      OA1BWIYDisPer: OA1BWIYDispe,
      OA1BWIY2DisPer: OA1BWIY2Dispe,
    });

    // PRO

    let OA1PWIM = pricingJson["OA1PWIM"].price;
    let OA1PWIQ = pricingJson["OA1PWIQ"].price;
    let OA1PWIH = pricingJson["OA1PWIH"].price;
    let OA1PWIY = pricingJson["OA1PWIY"].price;
    let OA1PWIY2 = pricingJson["OA1PWIY2"].price;

    // No Discount Price

    let OA1PWIMnodi = pricingJson["OA1PWIM"].nodiscountprice;
    let OA1PWIQnodi = pricingJson["OA1PWIQ"].nodiscountprice;
    let OA1PWIHnodi = pricingJson["OA1PWIH"].nodiscountprice;
    let OA1PWIYnodi = pricingJson["OA1PWIY"].nodiscountprice;
    let OA1PWIY2nodi = pricingJson["OA1PWIY2"].nodiscountprice;

    // Discount Percentage

    let OA1PWIMDispe = pricingJson["OA1PWIM"].discountpercentage;
    let OA1PWIQDispe = pricingJson["OA1PWIQ"].discountpercentage;
    let OA1PWIHDispe = pricingJson["OA1PWIH"].discountpercentage;
    let OA1PWIYDispe = pricingJson["OA1PWIY"].discountpercentage;
    let OA1PWIY2Dispe = pricingJson["OA1PWIY2"].discountpercentage;

    this.setState({
      OA1PWIM: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA1PWIM * 0.18 + OA1PWIM)),
      OA1PWIQ: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA1PWIQ * 0.18 + OA1PWIQ)),
      OA1PWIH: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA1PWIH * 0.18 + OA1PWIH)),
      OA1PWIY: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA1PWIY * 0.18 + OA1PWIY)),
      OA1PWIY2: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA1PWIY2 * 0.18 + OA1PWIY2)),

      // No Discount Price

      OA1PWIMnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA1PWIMnodi),
      OA1PWIQnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA1PWIQnodi),
      OA1PWIHnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA1PWIHnodi),
      OA1PWIYnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA1PWIYnodi),
      OA1PWIY2nodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA1PWIY2nodi),

      // Discount Percentage

      OA1PWIMDisPer: OA1PWIMDispe,
      OA1PWIQDisPer: OA1PWIQDispe,
      OA1PWIHDisPer: OA1PWIHDispe,
      OA1PWIYDisPer: OA1PWIYDispe,
      OA1PWIY2DisPer: OA1PWIY2Dispe,
    });

    //  Transactions - 2
    // BASIC

    let OA2BWIM = pricingJson["OA2BWIM"].price;
    let OA2BWIQ = pricingJson["OA2BWIQ"].price;
    let OA2BWIH = pricingJson["OA2BWIH"].price;
    let OA2BWIY = pricingJson["OA2BWIY"].price;
    let OA2BWIY2 = pricingJson["OA2BWIY2"].price;

    // No Discount Price

    let OA2BWIMnodi = pricingJson["OA2BWIM"].nodiscountprice;
    let OA2BWIQnodi = pricingJson["OA2BWIQ"].nodiscountprice;
    let OA2BWIHnodi = pricingJson["OA2BWIH"].nodiscountprice;
    let OA2BWIYnodi = pricingJson["OA2BWIY"].nodiscountprice;
    let OA2BWIY2nodi = pricingJson["OA2BWIY2"].nodiscountprice;

    // Discount Percentage

    let OA2BWIMDispe = pricingJson["OA2BWIM"].discountpercentage;
    let OA2BWIQDispe = pricingJson["OA2BWIQ"].discountpercentage;
    let OA2BWIHDispe = pricingJson["OA2BWIH"].discountpercentage;
    let OA2BWIYDispe = pricingJson["OA2BWIY"].discountpercentage;
    let OA2BWIY2Dispe = pricingJson["OA2BWIY2"].discountpercentage;

    this.setState({
      OA2BWIM: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA2BWIM * 0.18 + OA2BWIM)),
      OA2BWIQ: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA2BWIQ * 0.18 + OA2BWIQ)),
      OA2BWIH: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA2BWIH * 0.18 + OA2BWIH)),
      OA2BWIY: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA2BWIY * 0.18 + OA2BWIY)),
      OA2BWIY2: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA2BWIY2 * 0.18 + OA2BWIY2)),

      // No Discount Price

      OA2BWIMnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA2BWIMnodi),
      OA2BWIQnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA2BWIQnodi),
      OA2BWIHnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA2BWIHnodi),
      OA2BWIYnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA2BWIYnodi),
      OA2BWIY2nodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA2BWIY2nodi),

      // Discount Percentage

      OA2BWIMDisPer: OA2BWIMDispe,
      OA2BWIQDisPer: OA2BWIQDispe,
      OA2BWIHDisPer: OA2BWIHDispe,
      OA2BWIYDisPer: OA2BWIYDispe,
      OA2BWIY2DisPer: OA2BWIY2Dispe,
    });

    // PRO

    let OA2PWIM = pricingJson["OA2PWIM"].price;
    let OA2PWIQ = pricingJson["OA2PWIQ"].price;
    let OA2PWIH = pricingJson["OA2PWIH"].price;
    let OA2PWIY = pricingJson["OA2PWIY"].price;
    let OA2PWIY2 = pricingJson["OA2PWIY2"].price;

    // No Discount Price

    let OA2PWIMnodi = pricingJson["OA2PWIM"].nodiscountprice;
    let OA2PWIQnodi = pricingJson["OA2PWIQ"].nodiscountprice;
    let OA2PWIHnodi = pricingJson["OA2PWIH"].nodiscountprice;
    let OA2PWIYnodi = pricingJson["OA2PWIY"].nodiscountprice;
    let OA2PWIY2nodi = pricingJson["OA2PWIY2"].nodiscountprice;

    // Discount Percentage

    let OA2PWIMDispe = pricingJson["OA2PWIM"].discountpercentage;
    let OA2PWIQDispe = pricingJson["OA2PWIQ"].discountpercentage;
    let OA2PWIHDispe = pricingJson["OA2PWIH"].discountpercentage;
    let OA2PWIYDispe = pricingJson["OA2PWIY"].discountpercentage;
    let OA2PWIY2Dispe = pricingJson["OA2PWIY2"].discountpercentage;

    this.setState({
      OA2PWIM: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA2PWIM * 0.18 + OA2PWIM)),
      OA2PWIQ: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA2PWIQ * 0.18 + OA2PWIQ)),
      OA2PWIH: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA2PWIH * 0.18 + OA2PWIH)),
      OA2PWIY: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA2PWIY * 0.18 + OA2PWIY)),
      OA2PWIY2: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA2PWIY2 * 0.18 + OA2PWIY2)),

      // No Discount Price

      OA2PWIMnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA2PWIMnodi),
      OA2PWIQnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA2PWIQnodi),
      OA2PWIHnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA2PWIHnodi),
      OA2PWIYnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA2PWIYnodi),
      OA2PWIY2nodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA2PWIY2nodi),

      // Discount Percentage

      OA2PWIMDisPer: OA2PWIMDispe,
      OA2PWIQDisPer: OA2PWIQDispe,
      OA2PWIHDisPer: OA2PWIHDispe,
      OA2PWIYDisPer: OA2PWIYDispe,
      OA2PWIY2DisPer: OA2PWIY2Dispe,
    });

    //  Transactions - 3
    // BASIC

    let OA3BWIM = pricingJson["OA3BWIM"].price;
    let OA3BWIQ = pricingJson["OA3BWIQ"].price;
    let OA3BWIH = pricingJson["OA3BWIH"].price;
    let OA3BWIY = pricingJson["OA3BWIY"].price;
    let OA3BWIY2 = pricingJson["OA3BWIY2"].price;

    // No Discount Price

    let OA3BWIMnodi = pricingJson["OA3BWIM"].nodiscountprice;
    let OA3BWIQnodi = pricingJson["OA3BWIQ"].nodiscountprice;
    let OA3BWIHnodi = pricingJson["OA3BWIH"].nodiscountprice;
    let OA3BWIYnodi = pricingJson["OA3BWIY"].nodiscountprice;
    let OA3BWIY2nodi = pricingJson["OA3BWIY2"].nodiscountprice;

    // Discount Percentage

    let OA3BWIMDispe = pricingJson["OA3BWIM"].discountpercentage;
    let OA3BWIQDispe = pricingJson["OA3BWIQ"].discountpercentage;
    let OA3BWIHDispe = pricingJson["OA3BWIH"].discountpercentage;
    let OA3BWIYDispe = pricingJson["OA3BWIY"].discountpercentage;
    let OA3BWIY2Dispe = pricingJson["OA3BWIY2"].discountpercentage;

    this.setState({
      OA3BWIM: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA3BWIM * 0.18 + OA3BWIM)),
      OA3BWIQ: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA3BWIQ * 0.18 + OA3BWIQ)),
      OA3BWIH: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA3BWIH * 0.18 + OA3BWIH)),
      OA3BWIY: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA3BWIY * 0.18 + OA3BWIY)),
      OA3BWIY2: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA3BWIY2 * 0.18 + OA3BWIY2)),

      // No Discount Price

      OA3BWIMnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA3BWIMnodi),
      OA3BWIQnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA3BWIQnodi),
      OA3BWIHnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA3BWIHnodi),
      OA3BWIYnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA3BWIYnodi),
      OA3BWIY2nodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA3BWIY2nodi),

      // Discount Percentage

      OA3BWIMDisPer: OA3BWIMDispe,
      OA3BWIQDisPer: OA3BWIQDispe,
      OA3BWIHDisPer: OA3BWIHDispe,
      OA3BWIYDisPer: OA3BWIYDispe,
      OA3BWIY2DisPer: OA3BWIY2Dispe,
    });

    // PRO

    let OA3PWIM = pricingJson["OA3PWIM"].price;
    let OA3PWIQ = pricingJson["OA3PWIQ"].price;
    let OA3PWIH = pricingJson["OA3PWIH"].price;
    let OA3PWIY = pricingJson["OA3PWIY"].price;
    let OA3PWIY2 = pricingJson["OA3PWIY2"].price;

    // No Discount Price

    let OA3PWIMnodi = pricingJson["OA3PWIM"].nodiscountprice;
    let OA3PWIQnodi = pricingJson["OA3PWIQ"].nodiscountprice;
    let OA3PWIHnodi = pricingJson["OA3PWIH"].nodiscountprice;
    let OA3PWIYnodi = pricingJson["OA3PWIY"].nodiscountprice;
    let OA3PWIY2nodi = pricingJson["OA3PWIY2"].nodiscountprice;

    // Discount Percentage

    let OA3PWIMDispe = pricingJson["OA3PWIM"].discountpercentage;
    let OA3PWIQDispe = pricingJson["OA3PWIQ"].discountpercentage;
    let OA3PWIHDispe = pricingJson["OA3PWIH"].discountpercentage;
    let OA3PWIYDispe = pricingJson["OA3PWIY"].discountpercentage;
    let OA3PWIY2Dispe = pricingJson["OA3PWIY2"].discountpercentage;

    this.setState({
      OA3PWIM: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA3PWIM * 0.18 + OA3PWIM)),
      OA3PWIQ: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA3PWIQ * 0.18 + OA3PWIQ)),
      OA3PWIH: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA3PWIH * 0.18 + OA3PWIH)),
      OA3PWIY: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA3PWIY * 0.18 + OA3PWIY)),
      OA3PWIY2: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA3PWIY2 * 0.18 + OA3PWIY2)),

      // No Discount Price

      OA3PWIMnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA3PWIMnodi),
      OA3PWIQnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA3PWIQnodi),
      OA3PWIHnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA3PWIHnodi),
      OA3PWIYnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA3PWIYnodi),
      OA3PWIY2nodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA3PWIY2nodi),

      // Discount Percentage

      OA3PWIMDisPer: OA3PWIMDispe,
      OA3PWIQDisPer: OA3PWIQDispe,
      OA3PWIHDisPer: OA3PWIHDispe,
      OA3PWIYDisPer: OA3PWIYDispe,
      OA3PWIY2DisPer: OA3PWIY2Dispe,
    });

    //  Transactions - 4
    // BASIC

    let OA4BWIM = pricingJson["OA4BWIM"].price;
    let OA4BWIQ = pricingJson["OA4BWIQ"].price;
    let OA4BWIH = pricingJson["OA4BWIH"].price;
    let OA4BWIY = pricingJson["OA4BWIY"].price;
    let OA4BWIY2 = pricingJson["OA4BWIY2"].price;

    // No Discount Price

    let OA4BWIMnodi = pricingJson["OA4BWIM"].nodiscountprice;
    let OA4BWIQnodi = pricingJson["OA4BWIQ"].nodiscountprice;
    let OA4BWIHnodi = pricingJson["OA4BWIH"].nodiscountprice;
    let OA4BWIYnodi = pricingJson["OA4BWIY"].nodiscountprice;
    let OA4BWIY2nodi = pricingJson["OA4BWIY2"].nodiscountprice;

    // Discount Percentage

    let OA4BWIMDispe = pricingJson["OA4BWIM"].discountpercentage;
    let OA4BWIQDispe = pricingJson["OA4BWIQ"].discountpercentage;
    let OA4BWIHDispe = pricingJson["OA4BWIH"].discountpercentage;
    let OA4BWIYDispe = pricingJson["OA4BWIY"].discountpercentage;
    let OA4BWIY2Dispe = pricingJson["OA4BWIY2"].discountpercentage;

    this.setState({
      OA4BWIM: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA4BWIM * 0.18 + OA4BWIM)),
      OA4BWIQ: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA4BWIQ * 0.18 + OA4BWIQ)),
      OA4BWIH: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA4BWIH * 0.18 + OA4BWIH)),
      OA4BWIY: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA4BWIY * 0.18 + OA4BWIY)),
      OA4BWIY2: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA4BWIY2 * 0.18 + OA4BWIY2)),

      // No Discount Price

      OA4BWIMnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA4BWIMnodi),
      OA4BWIQnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA4BWIQnodi),
      OA4BWIHnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA4BWIHnodi),
      OA4BWIYnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA4BWIYnodi),
      OA4BWIY2nodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA4BWIY2nodi),

      // Discount Percentage

      OA4BWIMDisPer: OA4BWIMDispe,
      OA4BWIQDisPer: OA4BWIQDispe,
      OA4BWIHDisPer: OA4BWIHDispe,
      OA4BWIYDisPer: OA4BWIYDispe,
      OA4BWIY2DisPer: OA4BWIY2Dispe,
    });

    // PRO

    let OA4PWIM = pricingJson["OA4PWIM"].price;
    let OA4PWIQ = pricingJson["OA4PWIQ"].price;
    let OA4PWIH = pricingJson["OA4PWIH"].price;
    let OA4PWIY = pricingJson["OA4PWIY"].price;
    let OA4PWIY2 = pricingJson["OA4PWIY2"].price;

    // No Discount Price

    let OA4PWIMnodi = pricingJson["OA4PWIM"].nodiscountprice;
    let OA4PWIQnodi = pricingJson["OA4PWIQ"].nodiscountprice;
    let OA4PWIHnodi = pricingJson["OA4PWIH"].nodiscountprice;
    let OA4PWIYnodi = pricingJson["OA4PWIY"].nodiscountprice;
    let OA4PWIY2nodi = pricingJson["OA4PWIY2"].nodiscountprice;

    // Discount Percentage

    let OA4PWIMDispe = pricingJson["OA4PWIM"].discountpercentage;
    let OA4PWIQDispe = pricingJson["OA4PWIQ"].discountpercentage;
    let OA4PWIHDispe = pricingJson["OA4PWIH"].discountpercentage;
    let OA4PWIYDispe = pricingJson["OA4PWIY"].discountpercentage;
    let OA4PWIY2Dispe = pricingJson["OA4PWIY2"].discountpercentage;

    this.setState({
      OA4PWIM: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA4PWIM * 0.18 + OA4PWIM)),
      OA4PWIQ: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA4PWIQ * 0.18 + OA4PWIQ)),
      OA4PWIH: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA4PWIH * 0.18 + OA4PWIH)),
      OA4PWIY: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA4PWIY * 0.18 + OA4PWIY)),
      OA4PWIY2: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA4PWIY2 * 0.18 + OA4PWIY2)),

      // No Discount Price

      OA4PWIMnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA4PWIMnodi),
      OA4PWIQnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA4PWIQnodi),
      OA4PWIHnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA4PWIHnodi),
      OA4PWIYnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA4PWIYnodi),
      OA4PWIY2nodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA4PWIY2nodi),

      // Discount Percentage

      OA4PWIMDisPer: OA4PWIMDispe,
      OA4PWIQDisPer: OA4PWIQDispe,
      OA4PWIHDisPer: OA4PWIHDispe,
      OA4PWIYDisPer: OA4PWIYDispe,
      OA4PWIY2DisPer: OA4PWIY2Dispe,
    });

    //  Transactions - 5
    // BASIC

    let OA5BWIM = pricingJson["OA5BWIM"].price;
    let OA5BWIQ = pricingJson["OA5BWIQ"].price;
    let OA5BWIH = pricingJson["OA5BWIH"].price;
    let OA5BWIY = pricingJson["OA5BWIY"].price;
    let OA5BWIY2 = pricingJson["OA5BWIY2"].price;

    // No Discount Price

    let OA5BWIMnodi = pricingJson["OA5BWIM"].nodiscountprice;
    let OA5BWIQnodi = pricingJson["OA5BWIQ"].nodiscountprice;
    let OA5BWIHnodi = pricingJson["OA5BWIH"].nodiscountprice;
    let OA5BWIYnodi = pricingJson["OA5BWIY"].nodiscountprice;
    let OA5BWIY2nodi = pricingJson["OA5BWIY2"].nodiscountprice;

    // Discount Percentage

    let OA5BWIMDispe = pricingJson["OA5BWIM"].discountpercentage;
    let OA5BWIQDispe = pricingJson["OA5BWIQ"].discountpercentage;
    let OA5BWIHDispe = pricingJson["OA5BWIH"].discountpercentage;
    let OA5BWIYDispe = pricingJson["OA5BWIY"].discountpercentage;
    let OA5BWIY2Dispe = pricingJson["OA5BWIY2"].discountpercentage;

    this.setState({
      OA5BWIM: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA5BWIM * 0.18 + OA5BWIM)),
      OA5BWIQ: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA5BWIQ * 0.18 + OA5BWIQ)),
      OA5BWIH: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA5BWIH * 0.18 + OA5BWIH)),
      OA5BWIY: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA5BWIY * 0.18 + OA5BWIY)),
      OA5BWIY2: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA5BWIY2 * 0.18 + OA5BWIY2)),

      OA5BWIMnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA5BWIMnodi),
      OA5BWIQnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA5BWIQnodi),
      OA5BWIHnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA5BWIHnodi),
      OA5BWIYnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA5BWIYnodi),
      OA5BWIY2nodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA5BWIY2nodi),

      // Discount Percentage

      OA5BWIMDisPer: OA5BWIMDispe,
      OA5BWIQDisPer: OA5BWIQDispe,
      OA5BWIHDisPer: OA5BWIHDispe,
      OA5BWIYDisPer: OA5BWIYDispe,
      OA5BWIY2DisPer: OA5BWIY2Dispe,
    });

    // PRO

    let OA5PWIM = pricingJson["OA5PWIM"].price;
    let OA5PWIQ = pricingJson["OA5PWIQ"].price;
    let OA5PWIH = pricingJson["OA5PWIH"].price;
    let OA5PWIY = pricingJson["OA5PWIY"].price;
    let OA5PWIY2 = pricingJson["OA5PWIY2"].price;

    // No Discount Price

    let OA5PWIMnodi = pricingJson["OA5PWIM"].nodiscountprice;
    let OA5PWIQnodi = pricingJson["OA5PWIQ"].nodiscountprice;
    let OA5PWIHnodi = pricingJson["OA5PWIH"].nodiscountprice;
    let OA5PWIYnodi = pricingJson["OA5PWIY"].nodiscountprice;
    let OA5PWIY2nodi = pricingJson["OA5PWIY2"].nodiscountprice;

    // Discount Percentage

    let OA5PWIMDispe = pricingJson["OA5PWIM"].discountpercentage;
    let OA5PWIQDispe = pricingJson["OA5PWIQ"].discountpercentage;
    let OA5PWIHDispe = pricingJson["OA5PWIH"].discountpercentage;
    let OA5PWIYDispe = pricingJson["OA5PWIY"].discountpercentage;
    let OA5PWIY2Dispe = pricingJson["OA5PWIY2"].discountpercentage;

    this.setState({
      OA5PWIM: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA5PWIM * 0.18 + OA5PWIM)),
      OA5PWIQ: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA5PWIQ * 0.18 + OA5PWIQ)),
      OA5PWIH: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA5PWIH * 0.18 + OA5PWIH)),
      OA5PWIY: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA5PWIY * 0.18 + OA5PWIY)),
      OA5PWIY2: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA5PWIY2 * 0.18 + OA5PWIY2)),

      // No Discount Price

      OA5PWIMnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA5PWIMnodi),
      OA5PWIQnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA5PWIQnodi),
      OA5PWIHnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA5PWIHnodi),
      OA5PWIYnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA5PWIYnodi),
      OA5PWIY2nodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA5PWIY2nodi),

      // Discount Percentage

      OA5PWIMDisPer: OA5PWIMDispe,
      OA5PWIQDisPer: OA5PWIQDispe,
      OA5PWIHDisPer: OA5PWIHDispe,
      OA5PWIYDisPer: OA5PWIYDispe,
      OA5PWIY2DisPer: OA5PWIY2Dispe,
    });

    // Without Inventory

    //  Transactions - 1
    // BASIC

    let OA1BWOIM = pricingJson["OA1BWOIM"].price;
    let OA1BWOIQ = pricingJson["OA1BWOIQ"].price;
    let OA1BWOIH = pricingJson["OA1BWOIH"].price;
    let OA1BWOIY = pricingJson["OA1BWOIY"].price;
    let OA1BWOIY2 = pricingJson["OA1BWOIY2"].price;

    // No Discount Price

    let OA1BWOIMnodi = pricingJson["OA1BWOIM"].nodiscountprice;
    let OA1BWOIQnodi = pricingJson["OA1BWOIQ"].nodiscountprice;
    let OA1BWOIHnodi = pricingJson["OA1BWOIH"].nodiscountprice;
    let OA1BWOIYnodi = pricingJson["OA1BWOIY"].nodiscountprice;
    let OA1BWOIY2nodi = pricingJson["OA1BWOIY2"].nodiscountprice;

    // Discount Percentage

    let OA1BWOIMDispe = pricingJson["OA1BWOIM"].discountpercentage;
    let OA1BWOIQDispe = pricingJson["OA1BWOIQ"].discountpercentage;
    let OA1BWOIHDispe = pricingJson["OA1BWOIH"].discountpercentage;
    let OA1BWOIYDispe = pricingJson["OA1BWOIY"].discountpercentage;
    let OA1BWOIY2Dispe = pricingJson["OA1BWOIY2"].discountpercentage;

    this.setState({
      OA1BWOIM: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA1BWOIM * 0.18 + OA1BWOIM)),
      OA1BWOIQ: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA1BWOIQ * 0.18 + OA1BWOIQ)),
      OA1BWOIH: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA1BWOIH * 0.18 + OA1BWOIH)),
      OA1BWOIY: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA1BWOIY * 0.18 + OA1BWOIY)),
      OA1BWOIY2: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA1BWOIY2 * 0.18 + OA1BWOIY2)),

      OA1BWOIMnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA1BWOIMnodi),
      OA1BWOIQnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA1BWOIQnodi),
      OA1BWOIHnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA1BWOIHnodi),
      OA1BWOIYnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA1BWOIYnodi),
      OA1BWOIY2nodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA1BWOIY2nodi),

      // Discount Percentage

      OA1BWOIMDisPer: OA1BWOIMDispe,
      OA1BWOIQDisPer: OA1BWOIQDispe,
      OA1BWOIHDisPer: OA1BWOIHDispe,
      OA1BWOIYDisPer: OA1BWOIYDispe,
      OA1BWOIY2DisPer: OA1BWOIY2Dispe,
    });

    // PRO

    let OA1PWOIM = pricingJson["OA1PWOIM"].price;
    let OA1PWOIQ = pricingJson["OA1PWOIQ"].price;
    let OA1PWOIH = pricingJson["OA1PWOIH"].price;
    let OA1PWOIY = pricingJson["OA1PWOIY"].price;
    let OA1PWOIY2 = pricingJson["OA1PWOIY2"].price;

    // No Discount Price

    let OA1PWOIMnodi = pricingJson["OA1PWOIM"].nodiscountprice;
    let OA1PWOIQnodi = pricingJson["OA1PWOIQ"].nodiscountprice;
    let OA1PWOIHnodi = pricingJson["OA1PWOIH"].nodiscountprice;
    let OA1PWOIYnodi = pricingJson["OA1PWOIY"].nodiscountprice;
    let OA1PWOIY2nodi = pricingJson["OA1PWOIY2"].nodiscountprice;

    // Discount Percentage

    let OA1PWOIMDispe = pricingJson["OA1PWOIM"].discountpercentage;
    let OA1PWOIQDispe = pricingJson["OA1PWOIQ"].discountpercentage;
    let OA1PWOIHDispe = pricingJson["OA1PWOIH"].discountpercentage;
    let OA1PWOIYDispe = pricingJson["OA1PWOIY"].discountpercentage;
    let OA1PWOIY2Dispe = pricingJson["OA1PWOIY2"].discountpercentage;

    this.setState({
      OA1PWOIM: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA1PWOIM * 0.18 + OA1PWOIM)),
      OA1PWOIQ: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA1PWOIQ * 0.18 + OA1PWOIQ)),
      OA1PWOIH: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA1PWOIH * 0.18 + OA1PWOIH)),
      OA1PWOIY: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA1PWOIY * 0.18 + OA1PWOIY)),
      OA1PWOIY2: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA1PWOIY2 * 0.18 + OA1PWOIY2)),

      // No Discount Price

      OA1PWOIMnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA1PWOIMnodi),
      OA1PWOIQnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA1PWOIQnodi),
      OA1PWOIHnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA1PWOIHnodi),
      OA1PWOIYnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA1PWOIYnodi),
      OA1PWOIY2nodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA1PWOIY2nodi),

      // Discount Percentage

      OA1PWOIMDisPer: OA1PWOIMDispe,
      OA1PWOIQDisPer: OA1PWOIQDispe,
      OA1PWOIHDisPer: OA1PWOIHDispe,
      OA1PWOIYDisPer: OA1PWOIYDispe,
      OA1PWOIY2DisPer: OA1PWOIY2Dispe,
    });

    //  Transactions - 2
    // BASIC

    let OA2BWOIM = pricingJson["OA2BWOIM"].price;
    let OA2BWOIQ = pricingJson["OA2BWOIQ"].price;
    let OA2BWOIH = pricingJson["OA2BWOIH"].price;
    let OA2BWOIY = pricingJson["OA2BWOIY"].price;
    let OA2BWOIY2 = pricingJson["OA2BWOIY2"].price;

    // No Discount Price

    let OA2BWOIMnodi = pricingJson["OA2BWOIM"].nodiscountprice;
    let OA2BWOIQnodi = pricingJson["OA2BWOIQ"].nodiscountprice;
    let OA2BWOIHnodi = pricingJson["OA2BWOIH"].nodiscountprice;
    let OA2BWOIYnodi = pricingJson["OA2BWOIY"].nodiscountprice;
    let OA2BWOIY2nodi = pricingJson["OA2BWOIY2"].nodiscountprice;

    // Discount Percentage

    let OA2BWOIMDispe = pricingJson["OA2BWOIM"].discountpercentage;
    let OA2BWOIQDispe = pricingJson["OA2BWOIQ"].discountpercentage;
    let OA2BWOIHDispe = pricingJson["OA2BWOIH"].discountpercentage;
    let OA2BWOIYDispe = pricingJson["OA2BWOIY"].discountpercentage;
    let OA2BWOIY2Dispe = pricingJson["OA2BWOIY2"].discountpercentage;

    this.setState({
      OA2BWOIM: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA2BWOIM * 0.18 + OA2BWOIM)),
      OA2BWOIQ: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA2BWOIQ * 0.18 + OA2BWOIQ)),
      OA2BWOIH: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA2BWOIH * 0.18 + OA2BWOIH)),
      OA2BWOIY: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA2BWOIY * 0.18 + OA2BWOIY)),
      OA2BWOIY2: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA2BWOIY2 * 0.18 + OA2BWOIY2)),

      // No Discount Price

      OA2BWOIMnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA2BWOIMnodi),
      OA2BWOIQnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA2BWOIQnodi),
      OA2BWOIHnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA2BWOIHnodi),
      OA2BWOIYnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA2BWOIYnodi),
      OA2BWOIY2nodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA2BWOIY2nodi),

      // Discount Percentage

      OA2BWOIMDisPer: OA2BWOIMDispe,
      OA2BWOIQDisPer: OA2BWOIQDispe,
      OA2BWOIHDisPer: OA2BWOIHDispe,
      OA2BWOIYDisPer: OA2BWOIYDispe,
      OA2BWOIY2DisPer: OA2BWOIY2Dispe,
    });

    // PRO

    let OA2PWOIM = pricingJson["OA2PWOIM"].price;
    let OA2PWOIQ = pricingJson["OA2PWOIQ"].price;
    let OA2PWOIH = pricingJson["OA2PWOIH"].price;
    let OA2PWOIY = pricingJson["OA2PWOIY"].price;
    let OA2PWOIY2 = pricingJson["OA2PWOIY2"].price;

    // No Discount Price

    let OA2PWOIMnodi = pricingJson["OA2PWOIM"].nodiscountprice;
    let OA2PWOIQnodi = pricingJson["OA2PWOIQ"].nodiscountprice;
    let OA2PWOIHnodi = pricingJson["OA2PWOIH"].nodiscountprice;
    let OA2PWOIYnodi = pricingJson["OA2PWOIY"].nodiscountprice;
    let OA2PWOIY2nodi = pricingJson["OA2PWOIY2"].nodiscountprice;

    // Discount Percentage

    let OA2PWOIMDispe = pricingJson["OA2PWOIM"].discountpercentage;
    let OA2PWOIQDispe = pricingJson["OA2PWOIQ"].discountpercentage;
    let OA2PWOIHDispe = pricingJson["OA2PWOIH"].discountpercentage;
    let OA2PWOIYDispe = pricingJson["OA2PWOIY"].discountpercentage;
    let OA2PWOIY2Dispe = pricingJson["OA2PWOIY2"].discountpercentage;

    this.setState({
      OA2PWOIM: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA2PWOIM * 0.18 + OA2PWOIM)),
      OA2PWOIQ: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA2PWOIQ * 0.18 + OA2PWOIQ)),
      OA2PWOIH: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA2PWOIH * 0.18 + OA2PWOIH)),
      OA2PWOIY: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA2PWOIY * 0.18 + OA2PWOIY)),
      OA2PWOIY2: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA2PWOIY2 * 0.18 + OA2PWOIY2)),

      // No Discount Price

      OA2PWOIMnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA2PWOIMnodi),
      OA2PWOIQnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA2PWOIQnodi),
      OA2PWOIHnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA2PWOIHnodi),
      OA2PWOIYnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA2PWOIYnodi),
      OA2PWOIY2nodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA2PWOIY2nodi),

      // Discount Percentage

      OA2PWOIMDisPer: OA2PWOIMDispe,
      OA2PWOIQDisPer: OA2PWOIQDispe,
      OA2PWOIHDisPer: OA2PWOIHDispe,
      OA2PWOIYDisPer: OA2PWOIYDispe,
      OA2PWOIY2DisPer: OA2PWOIY2Dispe,
    });

    //  Transactions - 3
    // BASIC

    let OA3BWOIM = pricingJson["OA3BWOIM"].price;
    let OA3BWOIQ = pricingJson["OA3BWOIQ"].price;
    let OA3BWOIH = pricingJson["OA3BWOIH"].price;
    let OA3BWOIY = pricingJson["OA3BWOIY"].price;
    let OA3BWOIY2 = pricingJson["OA3BWOIY2"].price;

    // No Discount Price

    let OA3BWOIMnodi = pricingJson["OA3BWOIM"].nodiscountprice;
    let OA3BWOIQnodi = pricingJson["OA3BWOIQ"].nodiscountprice;
    let OA3BWOIHnodi = pricingJson["OA3BWOIH"].nodiscountprice;
    let OA3BWOIYnodi = pricingJson["OA3BWOIY"].nodiscountprice;
    let OA3BWOIY2nodi = pricingJson["OA3BWOIY2"].nodiscountprice;

    // Discount Percentage

    let OA3BWOIMDispe = pricingJson["OA3BWOIM"].discountpercentage;
    let OA3BWOIQDispe = pricingJson["OA3BWOIQ"].discountpercentage;
    let OA3BWOIHDispe = pricingJson["OA3BWOIH"].discountpercentage;
    let OA3BWOIYDispe = pricingJson["OA3BWOIY"].discountpercentage;
    let OA3BWOIY2Dispe = pricingJson["OA3BWOIY2"].discountpercentage;

    this.setState({
      OA3BWOIM: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA3BWOIM * 0.18 + OA3BWOIM)),
      OA3BWOIQ: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA3BWOIQ * 0.18 + OA3BWOIQ)),
      OA3BWOIH: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA3BWOIH * 0.18 + OA3BWOIH)),
      OA3BWOIY: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA3BWOIY * 0.18 + OA3BWOIY)),
      OA3BWOIY2: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA3BWOIY2 * 0.18 + OA3BWOIY2)),

      // No Discount Price

      OA3BWOIMnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA3BWOIMnodi),
      OA3BWOIQnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA3BWOIQnodi),
      OA3BWOIHnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA3BWOIHnodi),
      OA3BWOIYnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA3BWOIYnodi),
      OA3BWOIY2nodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA3BWOIY2nodi),

      // Discount Percentage

      OA3BWOIMDisPer: OA3BWOIMDispe,
      OA3BWOIQDisPer: OA3BWOIQDispe,
      OA3BWOIHDisPer: OA3BWOIHDispe,
      OA3BWOIYDisPer: OA3BWOIYDispe,
      OA3BWOIY2DisPer: OA3BWOIY2Dispe,
    });

    // PRO

    let OA3PWOIM = pricingJson["OA3PWOIM"].price;
    let OA3PWOIQ = pricingJson["OA3PWOIQ"].price;
    let OA3PWOIH = pricingJson["OA3PWOIH"].price;
    let OA3PWOIY = pricingJson["OA3PWOIY"].price;
    let OA3PWOIY2 = pricingJson["OA3PWOIY2"].price;

    // No Discount Price

    let OA3PWOIMnodi = pricingJson["OA3PWOIM"].nodiscountprice;
    let OA3PWOIQnodi = pricingJson["OA3PWOIQ"].nodiscountprice;
    let OA3PWOIHnodi = pricingJson["OA3PWOIH"].nodiscountprice;
    let OA3PWOIYnodi = pricingJson["OA3PWOIY"].nodiscountprice;
    let OA3PWOIY2nodi = pricingJson["OA3PWOIY2"].nodiscountprice;

    // Discount Percentage

    let OA3PWOIMDispe = pricingJson["OA3PWOIM"].discountpercentage;
    let OA3PWOIQDispe = pricingJson["OA3PWOIQ"].discountpercentage;
    let OA3PWOIHDispe = pricingJson["OA3PWOIH"].discountpercentage;
    let OA3PWOIYDispe = pricingJson["OA3PWOIY"].discountpercentage;
    let OA3PWOIY2Dispe = pricingJson["OA3PWOIY2"].discountpercentage;

    this.setState({
      OA3PWOIM: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA3PWOIM * 0.18 + OA3PWOIM)),
      OA3PWOIQ: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA3PWOIQ * 0.18 + OA3PWOIQ)),
      OA3PWOIH: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA3PWOIH * 0.18 + OA3PWOIH)),
      OA3PWOIY: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA3PWOIY * 0.18 + OA3PWOIY)),
      OA3PWOIY2: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA3PWOIY2 * 0.18 + OA3PWOIY2)),

      // No Discount Price

      OA3PWOIMnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA3PWOIMnodi),
      OA3PWOIQnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA3PWOIQnodi),
      OA3PWOIHnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA3PWOIHnodi),
      OA3PWOIYnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA3PWOIYnodi),
      OA3PWOIY2nodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA3PWOIY2nodi),

      // Discount Percentage

      OA3PWOIMDisPer: OA3PWOIMDispe,
      OA3PWOIQDisPer: OA3PWOIQDispe,
      OA3PWOIHDisPer: OA3PWOIHDispe,
      OA3PWOIYDisPer: OA3PWOIYDispe,
      OA3PWOIY2DisPer: OA3PWOIY2Dispe,
    });

    //  Transactions - 4
    // BASIC

    let OA4BWOIM = pricingJson["OA4BWOIM"].price;
    let OA4BWOIQ = pricingJson["OA4BWOIQ"].price;
    let OA4BWOIH = pricingJson["OA4BWOIH"].price;
    let OA4BWOIY = pricingJson["OA4BWOIY"].price;
    let OA4BWOIY2 = pricingJson["OA4BWOIY2"].price;

    // No Discount Price

    let OA4BWOIMnodi = pricingJson["OA4BWOIM"].nodiscountprice;
    let OA4BWOIQnodi = pricingJson["OA4BWOIQ"].nodiscountprice;
    let OA4BWOIHnodi = pricingJson["OA4BWOIH"].nodiscountprice;
    let OA4BWOIYnodi = pricingJson["OA4BWOIY"].nodiscountprice;
    let OA4BWOIY2nodi = pricingJson["OA4BWOIY2"].nodiscountprice;

    // Discount Percentage

    let OA4BWOIMDispe = pricingJson["OA4BWOIM"].discountpercentage;
    let OA4BWOIQDispe = pricingJson["OA4BWOIQ"].discountpercentage;
    let OA4BWOIHDispe = pricingJson["OA4BWOIH"].discountpercentage;
    let OA4BWOIYDispe = pricingJson["OA4BWOIY"].discountpercentage;
    let OA4BWOIY2Dispe = pricingJson["OA4BWOIY2"].discountpercentage;

    this.setState({
      OA4BWOIM: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA4BWOIM * 0.18 + OA4BWOIM)),
      OA4BWOIQ: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA4BWOIQ * 0.18 + OA4BWOIQ)),
      OA4BWOIH: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA4BWOIH * 0.18 + OA4BWOIH)),
      OA4BWOIY: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA4BWOIY * 0.18 + OA4BWOIY)),
      OA4BWOIY2: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA4BWOIY2 * 0.18 + OA4BWOIY2)),

      // No Discount Price

      OA4BWOIMnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA4BWOIMnodi),
      OA4BWOIQnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA4BWOIQnodi),
      OA4BWOIHnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA4BWOIHnodi),
      OA4BWOIYnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA4BWOIYnodi),
      OA4BWOIY2nodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA4BWOIY2nodi),

      // Discount Percentage

      OA4BWOIMDisPer: OA4BWOIMDispe,
      OA4BWOIQDisPer: OA4BWOIQDispe,
      OA4BWOIHDisPer: OA4BWOIHDispe,
      OA4BWOIYDisPer: OA4BWOIYDispe,
      OA4BWOIY2DisPer: OA4BWOIY2Dispe,
    });

    // PRO

    let OA4PWOIM = pricingJson["OA4PWOIM"].price;
    let OA4PWOIQ = pricingJson["OA4PWOIQ"].price;
    let OA4PWOIH = pricingJson["OA4PWOIH"].price;
    let OA4PWOIY = pricingJson["OA4PWOIY"].price;
    let OA4PWOIY2 = pricingJson["OA4PWOIY2"].price;

    // No Discount Price

    let OA4PWOIMnodi = pricingJson["OA4PWOIM"].nodiscountprice;
    let OA4PWOIQnodi = pricingJson["OA4PWOIQ"].nodiscountprice;
    let OA4PWOIHnodi = pricingJson["OA4PWOIH"].nodiscountprice;
    let OA4PWOIYnodi = pricingJson["OA4PWOIY"].nodiscountprice;
    let OA4PWOIY2nodi = pricingJson["OA4PWOIY2"].nodiscountprice;

    // Discount Percentage

    let OA4PWOIMDispe = pricingJson["OA4PWOIM"].discountpercentage;
    let OA4PWOIQDispe = pricingJson["OA4PWOIQ"].discountpercentage;
    let OA4PWOIHDispe = pricingJson["OA4PWOIH"].discountpercentage;
    let OA4PWOIYDispe = pricingJson["OA4PWOIY"].discountpercentage;
    let OA4PWOIY2Dispe = pricingJson["OA4PWOIY2"].discountpercentage;

    this.setState({
      OA4PWOIM: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA4PWOIM * 0.18 + OA4PWOIM)),
      OA4PWOIQ: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA4PWOIQ * 0.18 + OA4PWOIQ)),
      OA4PWOIH: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA4PWOIH * 0.18 + OA4PWOIH)),
      OA4PWOIY: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA4PWOIY * 0.18 + OA4PWOIY)),
      OA4PWOIY2: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA4PWOIY2 * 0.18 + OA4PWOIY2)),

      // No Discount Price

      OA4PWOIMnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA4PWOIMnodi),
      OA4PWOIQnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA4PWOIQnodi),
      OA4PWOIHnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA4PWOIHnodi),
      OA4PWOIYnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA4PWOIYnodi),
      OA4PWOIY2nodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA4PWOIY2nodi),

      // Discount Percentage

      OA4PWOIMDisPer: OA4PWOIMDispe,
      OA4PWOIQDisPer: OA4PWOIQDispe,
      OA4PWOIHDisPer: OA4PWOIHDispe,
      OA4PWOIYDisPer: OA4PWOIYDispe,
      OA4PWOIY2DisPer: OA4PWOIY2Dispe,
    });

    //  Transactions - 5
    // BASIC

    let OA5BWOIM = pricingJson["OA5BWOIM"].price;
    let OA5BWOIQ = pricingJson["OA5BWOIQ"].price;
    let OA5BWOIH = pricingJson["OA5BWOIH"].price;
    let OA5BWOIY = pricingJson["OA5BWOIY"].price;
    let OA5BWOIY2 = pricingJson["OA5BWOIY2"].price;

    // No Discount Price

    let OA5BWOIMnodi = pricingJson["OA5BWOIM"].nodiscountprice;
    let OA5BWOIQnodi = pricingJson["OA5BWOIQ"].nodiscountprice;
    let OA5BWOIHnodi = pricingJson["OA5BWOIH"].nodiscountprice;
    let OA5BWOIYnodi = pricingJson["OA5BWOIY"].nodiscountprice;
    let OA5BWOIY2nodi = pricingJson["OA5BWOIY2"].nodiscountprice;

    // Discount Percentage

    let OA5BWOIMDispe = pricingJson["OA5BWOIM"].discountpercentage;
    let OA5BWOIQDispe = pricingJson["OA5BWOIQ"].discountpercentage;
    let OA5BWOIHDispe = pricingJson["OA5BWOIH"].discountpercentage;
    let OA5BWOIYDispe = pricingJson["OA5BWOIY"].discountpercentage;
    let OA5BWOIY2Dispe = pricingJson["OA5BWOIY2"].discountpercentage;

    this.setState({
      OA5BWOIM: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA5BWOIM * 0.18 + OA5BWOIM)),
      OA5BWOIQ: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA5BWOIQ * 0.18 + OA5BWOIQ)),
      OA5BWOIH: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA5BWOIH * 0.18 + OA5BWOIH)),
      OA5BWOIY: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA5BWOIY * 0.18 + OA5BWOIY)),
      OA5BWOIY2: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA5BWOIY2 * 0.18 + OA5BWOIY2)),

      // No Discount Price

      OA5BWOIMnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA5BWOIMnodi),
      OA5BWOIQnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA5BWOIQnodi),
      OA5BWOIHnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA5BWOIHnodi),
      OA5BWOIYnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA5BWOIYnodi),
      OA5BWOIY2nodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA5BWOIY2nodi),

      // Discount Percentage

      OA5BWOIMDisPer: OA5BWOIMDispe,
      OA5BWOIQDisPer: OA5BWOIQDispe,
      OA5BWOIHDisPer: OA5BWOIHDispe,
      OA5BWOIYDisPer: OA5BWOIYDispe,
      OA5BWOIY2DisPer: OA5BWOIY2Dispe,
    });

    // PRO

    let OA5PWOIM = pricingJson["OA5PWOIM"].price;
    let OA5PWOIQ = pricingJson["OA5PWOIQ"].price;
    let OA5PWOIH = pricingJson["OA5PWOIH"].price;
    let OA5PWOIY = pricingJson["OA5PWOIY"].price;
    let OA5PWOIY2 = pricingJson["OA5PWOIY2"].price;

    // No Discount Price

    let OA5PWOIMnodi = pricingJson["OA5PWOIM"].nodiscountprice;
    let OA5PWOIQnodi = pricingJson["OA5PWOIQ"].nodiscountprice;
    let OA5PWOIHnodi = pricingJson["OA5PWOIH"].nodiscountprice;
    let OA5PWOIYnodi = pricingJson["OA5PWOIY"].nodiscountprice;
    let OA5PWOIY2nodi = pricingJson["OA5PWOIY2"].nodiscountprice;

    // Discount Percentage

    let OA5PWOIMDispe = pricingJson["OA5PWOIM"].discountpercentage;
    let OA5PWOIQDispe = pricingJson["OA5PWOIQ"].discountpercentage;
    let OA5PWOIHDispe = pricingJson["OA5PWOIH"].discountpercentage;
    let OA5PWOIYDispe = pricingJson["OA5PWOIY"].discountpercentage;
    let OA5PWOIY2Dispe = pricingJson["OA5PWOIY2"].discountpercentage;

    this.setState({
      OA5PWOIM: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA5PWOIM * 0.18 + OA5PWOIM)),
      OA5PWOIQ: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA5PWOIQ * 0.18 + OA5PWOIQ)),
      OA5PWOIH: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA5PWOIH * 0.18 + OA5PWOIH)),
      OA5PWOIY: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA5PWOIY * 0.18 + OA5PWOIY)),
      OA5PWOIY2: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(Math.round(OA5PWOIY2 * 0.18 + OA5PWOIY2)),

      // No Discount Price

      OA5PWOIMnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA5PWOIMnodi),
      OA5PWOIQnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA5PWOIQnodi),
      OA5PWOIHnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA5PWOIHnodi),
      OA5PWOIYnodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA5PWOIYnodi),
      OA5PWOIY2nodis: new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(OA5PWOIY2nodi),

      // Discount Percentage

      OA5PWOIMDisPer: OA5PWOIMDispe,
      OA5PWOIQDisPer: OA5PWOIQDispe,
      OA5PWOIHDisPer: OA5PWOIHDispe,
      OA5PWOIYDisPer: OA5PWOIYDispe,
      OA5PWOIY2DisPer: OA5PWOIY2Dispe,
    });
  };

  addToCartseconddropdowngetstartedclick = () => {
    this.upcard();
  };

  upcard = () => {
    const contactNoregex = /^[6-9]\d{9}$/;
    const emailregex = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    if (this.state.servicefullname.trim() == "") {
      $(".errorside").show();
      $(".errorsidespan").show().html(" Please enter your name.");
      $("#name").css({
        "border-bottom-color": "#fe0c0c",
      });
      $("#name").focus();
    } else if (
      this.state.servicefullname.trim() == "" ||
      this.state.servicefullname.length > 50
    ) {
      $(".errorside").show();
      $(".errorsidespan").show().html(" Please enter a valid name.");
      $("#name").css({
        "border-bottom-color": "#fe0c0c",
      });
      $("#name").focus();
    } else if (this.state.serviceemail.trim() == "") {
      $(".errorside").show();
      $(".errorsidespan").show().html(" Please enter your email.");
      $("#name").css({
        "border-bottom-color": "#809aae",
      });
      $("#email").css({
        "border-bottom-color": "#fe0c0c",
      });
      $("#email").focus();
    } else if (
      !emailregex.test(this.state.serviceemail) ||
      this.state.serviceemail.length > 50
    ) {
      $(".errorside").show();
      $(".errorsidespan").show().html(" Please enter valid email.");
      $("#email").css({
        "border-bottom-color": "#fe0c0c",
      });
      $("#email").focus();
    } else if (this.state.servicemobileno.trim() == "") {
      $(".errorside").show();
      $(".errorsidespan").show().html(" Please enter your number.");
      $("#name").css({
        "border-bottom-color": "#809aae",
      });
      $("#email").css({
        "border-bottom-color": "#809aae",
      });
      $("#contact").css({
        "border-bottom-color": "#fe0c0c",
      });
      $("#contact").focus();
    } else if (
      this.state.servicemobileno.trim() == "" ||
      !contactNoregex.test(this.state.servicemobileno)
    ) {
      $(".errorside").show();
      $(".errorsidespan").show().html(" Please enter valid number.");
      $("#name").css({
        "border-bottom-color": "#809aae",
      });
      $("#email").css({
        "border-bottom-color": "#809aae",
      });
      $("#contact").css({
        "border-bottom-color": "#fe0c0c",
      });
      $("#contact").focus();
    } else if (this.state.citydistrictselectvalue.trim() == "") {
      $(".errorside").show();
      $(".errorsidespan").show().html(" Please enter your city.");
      $("#name").css({
        "border-bottom-color": "#809aae",
      });
      $("#email").css({
        "border-bottom-color": "#809aae",
      });
      $("#contact").css({
        "border-bottom-color": "#809aae",
      });
      $("#city").css({
        "border-bottom-color": "#fe0c0c",
      });
      $("#city").focus();
    } else {
      this.filterDistricts();

      var mySentence = this.state.servicefullname.trim();
      mySentence = mySentence.replace(/\s+/g, " ");
      const words = mySentence.split(" ");

      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }

      var mywords = words.join(" ");

      var mainobject =
      pricingJson[
          "OA" +
            this.state.dropDownValue +
            this.state.mainplan +
            this.state.mainpackage +
            this.state.mainduration
        ];
      var stateshortcode = Object.keys(json2[0]).find(
        (key) => json2[0][key] === servicestate
      );
      var gstvalue = mainobject.price * 0.18;
      sessionStorage.setItem("newuseremail", this.state.serviceemail);
      sessionStorage.setItem("newusercontact", this.state.servicemobileno);
      data = {
        userId: "",
        serviceName: "OA",
        purchasename: this.state.servicefullname,
        citydistrict: this.state.citydistrictselectvalue,
        state: servicestate,
        serviceType: "",
        duration: this.state.mainduration,
        plan: this.state.mainplan,
        turnover: this.state.dropDownValue,
        package: this.state.mainpackage,
        price: mainobject.price,
        gstvalue: gstvalue,
        govtfees: mainobject.govtfees,
        servicefullname: "Online Accounting",
        billingName: this.state.servicefullname,
        billingCity: this.state.citydistrictselectvalue,
        billingState: jsonData[0][this.state.citydistrictselectvalue],
        stateShortCode: stateshortcode,
      };

      var sessiondata;
      sessiondata = sessionStorage.getItem("data");
      if (
        sessiondata == null ||
        sessiondata == undefined ||
        sessiondata == ""
      ) {
        sessiondata = [];
      } else {
        sessiondata = JSON.parse(sessionStorage.getItem("data"));
      }

      if (sessiondata.length == 0) {
        var localvar = [];
        localvar.push(data);
        sessionStorage.setItem("data", JSON.stringify(localvar));
        this.showNotification("Item Added to Cart");
      } else if (sessiondata.length > 0) {
        var arraylen = sessiondata.length;
        // for (let index = 0; index < arraylen; index++) {
        //   if (sessiondata[index].serviceName == "OA") {
        //     sessiondata.splice(index, 1);
        //     break;
        //   }
        // }
        sessiondata.push(data);
        sessionStorage.setItem("data", JSON.stringify(sessiondata));
        this.showNotification("Item Added to Cart");
      }
      this.setState({
        dataupdated: true,
      });
    }
  };
  handledropDownValueChange = () => {
    if (this.state.dropDownValue == "1" && this.state.mainpackage == "WI") {
      $("#1").show();
      $("#2").hide();
      $("#3").hide();
      $("#4").hide();
      $("#5").hide();
      $("#6").hide();
      $("#7").hide();
      $("#8").hide();
      $("#9").hide();
      $("#10").hide();
    } else if (
      this.state.dropDownValue == "2" &&
      this.state.mainpackage == "WI"
    ) {
      $("#1").hide();
      $("#2").show();
      $("#3").hide();
      $("#4").hide();
      $("#5").hide();
      $("#6").hide();
      $("#7").hide();
      $("#8").hide();
      $("#9").hide();
      $("#10").hide();
    } else if (
      this.state.dropDownValue == "3" &&
      this.state.mainpackage == "WI"
    ) {
      $("#1").hide();
      $("#2").hide();
      $("#3").show();
      $("#4").hide();
      $("#5").hide();
      $("#6").hide();
      $("#7").hide();
      $("#8").hide();
      $("#9").hide();
      $("#10").hide();
    } else if (
      this.state.dropDownValue == "4" &&
      this.state.mainpackage == "WI"
    ) {
      $("#1").hide();
      $("#2").hide();
      $("#3").hide();
      $("#4").show();
      $("#5").hide();
      $("#6").hide();
      $("#7").hide();
      $("#8").hide();
      $("#9").hide();
      $("#10").hide();
    } else if (
      this.state.dropDownValue == "5" &&
      this.state.mainpackage == "WI"
    ) {
      $("#1").hide();
      $("#2").hide();
      $("#3").hide();
      $("#4").hide();
      $("#5").show();
      $("#6").hide();
      $("#7").hide();
      $("#8").hide();
      $("#9").hide();
      $("#10").hide();
    } else if (
      this.state.dropDownValue == "1" &&
      this.state.mainpackage == "WOI"
    ) {
      $("#1").hide();
      $("#2").hide();
      $("#3").hide();
      $("#4").hide();
      $("#5").hide();
      $("#6").show();
      $("#7").hide();
      $("#8").hide();
      $("#9").hide();
      $("#10").hide();
    } else if (
      this.state.dropDownValue == "2" &&
      this.state.mainpackage == "WOI"
    ) {
      $("#1").hide();
      $("#2").hide();
      $("#3").hide();
      $("#4").hide();
      $("#5").hide();
      $("#6").hide();
      $("#7").show();
      $("#8").hide();
      $("#9").hide();
      $("#10").hide();
    } else if (
      this.state.dropDownValue == "3" &&
      this.state.mainpackage == "WOI"
    ) {
      $("#1").hide();
      $("#2").hide();
      $("#3").hide();
      $("#4").hide();
      $("#5").hide();
      $("#6").hide();
      $("#7").hide();
      $("#8").show();
      $("#9").hide();
      $("#10").hide();
    } else if (
      this.state.dropDownValue == "4" &&
      this.state.mainpackage == "WOI"
    ) {
      $("#1").hide();
      $("#2").hide();
      $("#3").hide();
      $("#4").hide();
      $("#5").hide();
      $("#6").hide();
      $("#7").hide();
      $("#8").hide();
      $("#9").show();
      $("#10").hide();
    } else if (
      this.state.dropDownValue == "5" &&
      this.state.mainpackage == "WOI"
    ) {
      $("#1").hide();
      $("#2").hide();
      $("#3").hide();
      $("#4").hide();
      $("#5").hide();
      $("#6").hide();
      $("#7").hide();
      $("#8").hide();
      $("#9").hide();
      $("#10").show();
    }
  };

  toggleContent = () => {
    if ($(".morepaymentpoint2").attr("aria-expanded") === "true") {
      $(".morepaymentpoint2").text("More");
    } else if ($(".morepaymentpoint2").attr("aria-expanded") === "false") {
      $(".morepaymentpoint2").text("Less");
    } else {
      $(".morepaymentpoint2").text("Less");
    }
  };

  planInclusions = () => {
    return (
      <div id="accordion">
        <div className="card bg-white border-0">
          <div className="card-header bg-white border-0 py-0" id="headingOne">
            <div
              className="mb-0 row mr-2  align-items-baseline"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              <div
                className="text-left btn w-100 bg-white m-0 col-9 ques-2 p-0"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                <p className="planstarlinetext">
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                    className="planstarlineimg"
                  />
                  Included Transactions:-
                </p>
              </div>
              <div className="ml-auto btn pr-0 faq-btn">
                <img src="/img//Icon ionic-ios-arrow-down.svg" />
              </div>
            </div>
          </div>

          <div
            id="collapseOne"
            className="collapse "
            aria-labelledby="headingOne"
            data-parent="#accordion"
          >
            <div className="card-body pl-1">
              <p className="plancommonlinetext">
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                  className="plancircleimg"
                />
                Sales and Purchase Invoice
              </p>
              <p className="plancommonlinetext">
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                  className="plancircleimg"
                />
                Service Invoice
              </p>
              <p className="plancommonlinetext">
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                  className="plancircleimg"
                />
                Cash and Bank Entries
              </p>
              <p className="plancommonlinetext">
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                  className="plancircleimg"
                />
                Income and Expenses Entries
              </p>
              <p className="plancommonlinetext">
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                  className="plancircleimg"
                />
                All Other Financial Transactions
              </p>
            </div>
          </div>
        </div>
        <div className="card bg-white border-0">
          <div className="card-header bg-white border-0 py-0" id="headingTwo">
            <div
              className="mb-0 row mr-2  align-items-baseline"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              <div className="text-left btn w-100 bg-white m-0 col-9 ques-2 p-0">
                <p className="planstarlinetext">
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                    className="planstarlineimg"
                  />
                  Balance Enquiry :-
                </p>
              </div>
              <div className="ml-auto btn pr-0 faq-btn">
                <img src="/img//Icon ionic-ios-arrow-down.svg" />
              </div>
            </div>
          </div>
        </div>
        <div
          id="collapseTwo"
          className="collapse"
          aria-labelledby="headingTwo"
          data-parent="#accordion"
        >
          <div className="card-body pl-1">
            <p className="plancommonlinetext">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                className="plancircleimg"
              />
              Cash Balance
            </p>
            <p className="plancommonlinetext">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                className="plancircleimg"
              />
              Party Ledger Balance
            </p>
            <p className="plancommonlinetext">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                className="plancircleimg"
              />
              Party Last Transaction
            </p>
            <p className="plancommonlinetext">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                className="plancircleimg"
              />
              Sales and Purchase Figures
            </p>
            <p className="plancommonlinetext">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                className="plancircleimg"
              />
              Taxation Liability Balance
            </p>
          </div>
        </div>

        <div className="card bg-white border-0">
          <div className="card-header bg-white border-0 py-0" id="headingThree">
            <div
              className="mb-0 row mr-2  align-items-baseline"
              data-toggle="collapse"
              data-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              <div className="text-left btn w-100 bg-white m-0 col-9 ques-2 p-0">
                <p className="planstarlinetext">
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                    className="planstarlineimg"
                  />
                  Reconciliation :-
                </p>
              </div>
              <div className="ml-auto btn pr-0 faq-btn">
                <img src="/img//Icon ionic-ios-arrow-down.svg" />
              </div>
            </div>
          </div>
        </div>
        <div
          id="collapseThree"
          className="collapse"
          aria-labelledby="headingThree"
          data-parent="#accordion"
        >
          <div className="card-body pl-1">
            <p className="plancommonlinetext">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                className="plancircleimg"
              />
              Bank Accounts
            </p>
            <p className="plancommonlinetext">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                className="plancircleimg"
              />
              Loan Accounts
            </p>
            <p className="plancommonlinetext">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                className="plancircleimg"
              />
              Party Accounts on Demand
            </p>
          </div>
        </div>

        <div className="card bg-white border-0">
          <div className="card-header bg-white border-0 py-0" id="headingFour">
            <div
              className="mb-0 row mr-2  align-items-baseline"
              data-toggle="collapse"
              data-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              <div className="text-left btn w-100 bg-white m-0 col-9 ques-2 p-0">
                <p className="planstarlinetext">
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                    className="planstarlineimg"
                  />
                  Ledger and Statements:-
                </p>
              </div>
              <div className="ml-auto btn pr-0 faq-btn">
                <img src="/img//Icon ionic-ios-arrow-down.svg" />
              </div>
            </div>
          </div>
        </div>
        <div
          id="collapseFour"
          className="collapse"
          aria-labelledby="headingFour"
          data-parent="#accordion"
        >
          <div className="card-body pl-1">
            <p className="plancommonlinetext">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                className="plancircleimg"
              />
              Party Ledger on Demand
            </p>
            <p className="plancommonlinetext">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                className="plancircleimg"
              />
              Tax Liabilty Ledger on Demand
            </p>
            <p className="plancommonlinetext">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                className="plancircleimg"
              />
              Stock Statement on Demand
            </p>
          </div>
        </div>

        <div className="card bg-white border-0">
          <div className="card-header bg-white border-0 py-0" id="headingFive">
            <div className="mb-0 row mr-2  align-items-baseline">
              <div className="text-left btn w-100 bg-white m-0 col-9 ques-2 p-0">
                <p className="planstarlinetext">
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                    className="planstarlineimg"
                  />
                  Books Finanalization:-
                </p>
              </div>
              <div className="ml-auto btn pr-0 faq-btn"></div>
            </div>
          </div>
        </div>
        <div className="card bg-white border-0">
          <div className="card-header bg-white border-0 py-0" id="headingSix">
            <div
              className="mb-0 row mr-2  align-items-baseline"
              data-toggle="collapse"
              data-target="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseSix"
            >
              <div className="text-left btn w-100 bg-white m-0 col-9 ques-2 p-0">
                <p className="planstarlinetext">
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                    className="planstarlineimg"
                  />
                  Included Reports:-
                </p>
              </div>
              <div className="ml-auto btn pr-0 faq-btn">
                <img src="/img//Icon ionic-ios-arrow-down.svg" />
              </div>
            </div>
          </div>
          <div
            id="collapseSix"
            className="collapse"
            aria-labelledby="headingSix"
            data-parent="#accordion"
          >
            <div className="card-body pl-1">
              <p className="plancommonlinetext">
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                  className="plancircleimg"
                />
                Trial Balance
              </p>
              <p className="plancommonlinetext">
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                  className="plancircleimg"
                />
                Balance Sheet
              </p>
              <p className="plancommonlinetext">
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                  className="plancircleimg"
                />
                Profit and Loss Account
              </p>
            </div>
          </div>
        </div>
        <div className="card bg-white border-0">
          <div className="card-header bg-white border-0 py-0" id="headingSeven">
            <div className="mb-0 row mr-2  align-items-baseline">
              <div className="text-left btn w-100 bg-white m-0 col-9 ques-2 p-0">
                <p className="planstarlinetext">
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                    className="planstarlineimg"
                  />
                  Yearly Books of Accounts (PDF):-
                </p>
              </div>
              <div className="ml-auto btn pr-0 faq-btn"></div>
            </div>
          </div>
        </div>
        <div className="card bg-white border-0">
          <div className="card-header bg-white border-0 py-0" id="headingEight">
            <div className="mb-0 row mr-2  align-items-baseline">
              <div className="text-left btn w-100 bg-white m-0 col-9 ques-2 p-0">
                <p className="planstarlinetext">
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                    className="planstarlineimg"
                  />
                  Yearly Books of Accounts (Hard Copy):-
                </p>
              </div>
              <div className="ml-auto btn pr-0 faq-btn"></div>
            </div>
          </div>
        </div>
        <div className="card bg-white border-0">
          <div className="card-header bg-white border-0 py-0">
            <div
              className="mb-0 row mr-2  align-items-baseline"
              id="headingTen"
              data-toggle="collapse"
              data-target="#collapseTen"
              aria-expanded="false"
              aria-controls="collapseTen"
            >
              <div className="text-left btn w-100 bg-white m-0 col-8 ques-2 p-0">
                <p className="planstarlinetext">
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                    className="planstarlineimg"
                  />
                  More:-
                </p>
              </div>
              <div className="ml-auto btn pr-0">
                <img src="/img//Icon ionic-ios-arrow-down.svg" />
              </div>
            </div>
          </div>
          <div
            id="collapseTen"
            className="collapse"
            aria-labelledby="headingTen"
            data-parent="#accordion"
          >
            <div className="card-body pl-1">
              <p className="plancommonlinetext">
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                  className="plantickimg"
                />
                E-way bill facility
              </p>
              <p className="plancommonlinetext">
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                  className="plantickimg"
                />
                Dashboard for managing service
              </p>
              <p className="plancommonlinetext">
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                  className="plantickimg"
                />
                Mobile application support
              </p>
              <p className="plancommonlinetext">
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                  className="plantickimg"
                />
                Documents sharing facility
              </p>
              <p className="plancommonlinetext">
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                  className="plantickimg"
                />
                Download deliverables any time
              </p>
              <p className="plancommonlinetext">
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                  className="plantickimg"
                />
                Password sharing
              </p>
              <p className="plancommonlinetext">
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                  className="plantickimg"
                />
                Round the clock support
              </p>
              <p className="plancommonlinetext">
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                  className="plantickimg"
                />
                Time to time updates & notifications
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  planInclusionspro = () => {
    return (
      <div id="accordion">
        <div className="card bg-white border-0">
          <div className="card-header bg-white border-0 py-0" id="headingNine">
            <div
              className="mb-0 row mr-2  align-items-baseline"
              data-toggle="collapse"
              data-target="#collapseNine"
              aria-expanded="false"
              aria-controls="collapseNine"
            >
              <div
                className="text-left btn w-100 bg-white m-0 col-9 ques-2 p-0"
                data-toggle="collapse"
                data-target="#collapseNine"
                aria-expanded="false"
                aria-controls="collapseNine"
              >
                <p className="planstarlinetext">
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                    className="planstarlineimg"
                  />
                  Included Invoice Making:-
                </p>
              </div>
              <div className="ml-auto btn pr-0 faq-btn">
                <img src="/img//Icon ionic-ios-arrow-down.svg" />
              </div>
            </div>
          </div>

          <div
            id="collapseNine"
            className="collapse "
            aria-labelledby="headingNine"
            data-parent="#accordion"
          >
            <div className="card-body pl-1">
              <p className="plancommonlinetext">
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                  className="plancircleimg"
                />
                Sales Invoice
              </p>
              <p className="plancommonlinetext">
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                  className="plancircleimg"
                />
                Service Invoice
              </p>
            </div>
          </div>
        </div>
        <div className="card bg-white border-0">
          <div
            className="card-header bg-white border-0 py-0"
            id="headingEleven"
          >
            <div
              className="mb-0 row mr-2  align-items-baseline"
              data-toggle="collapse"
              data-target="#collapseEleven"
              aria-expanded="false"
              aria-controls="collapseEleven"
            >
              <div
                className="text-left btn w-100 bg-white m-0 col-9 ques-2 p-0"
                data-toggle="collapse"
                data-target="#collapseEleven"
                aria-expanded="false"
                aria-controls="collapseEleven"
              >
                <p className="planstarlinetext">
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                    className="planstarlineimg"
                  />
                  Included Transactions:-
                </p>
              </div>
              <div className="ml-auto btn pr-0 faq-btn">
                <img src="/img//Icon ionic-ios-arrow-down.svg" />
              </div>
            </div>
          </div>

          <div
            id="collapseEleven"
            className="collapse "
            aria-labelledby="headingEleven"
            data-parent="#accordion"
          >
            <div className="card-body pl-1">
              <p className="plancommonlinetext">
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                  className="plancircleimg"
                />
                Sales and Purchase Invoice
              </p>
              <p className="plancommonlinetext">
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                  className="plancircleimg"
                />
                Service Invoice
              </p>
              <p className="plancommonlinetext">
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                  className="plancircleimg"
                />
                Cash and Bank Entries
              </p>
              <p className="plancommonlinetext">
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                  className="plancircleimg"
                />
                Income and Expenses Entries
              </p>
              <p className="plancommonlinetext">
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                  className="plancircleimg"
                />
                All Other Financial Transactions
              </p>
            </div>
          </div>
        </div>
        <div className="card bg-white border-0">
          <div
            className="card-header bg-white border-0 py-0"
            id="headingTwelve"
          >
            <div
              className="mb-0 row mr-2  align-items-baseline"
              data-toggle="collapse"
              data-target="#collapseTwelve"
              aria-expanded="false"
              aria-controls="collapseTwelve"
            >
              <div className="text-left btn w-100 bg-white m-0 col-9 ques-2 p-0">
                <p className="planstarlinetext">
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                    className="planstarlineimg"
                  />
                  Balance Enquiry :-
                </p>
              </div>
              <div className="ml-auto btn pr-0 faq-btn">
                <img src="/img//Icon ionic-ios-arrow-down.svg" />
              </div>
            </div>
          </div>
        </div>
        <div
          id="collapseTwelve"
          className="collapse"
          aria-labelledby="headingTwelve"
          data-parent="#accordion"
        >
          <div className="card-body pl-1">
            <p className="plancommonlinetext">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                className="plancircleimg"
              />
              Cash Balance
            </p>
            <p className="plancommonlinetext">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                className="plancircleimg"
              />
              Party Ledger Balance
            </p>
            <p className="plancommonlinetext">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                className="plancircleimg"
              />
              Party Last Transaction
            </p>
            <p className="plancommonlinetext">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                className="plancircleimg"
              />
              Sales and Purchase Figures
            </p>
            <p className="plancommonlinetext">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                className="plancircleimg"
              />
              Taxation Liability Balance
            </p>
          </div>
        </div>

        <div className="card bg-white border-0">
          <div
            className="card-header bg-white border-0 py-0"
            id="headingThrteen"
          >
            <div
              className="mb-0 row mr-2  align-items-baseline"
              data-toggle="collapse"
              data-target="#collapseThrteen"
              aria-expanded="false"
              aria-controls="collapseThrteen"
            >
              <div className="text-left btn w-100 bg-white m-0 col-9 ques-2 p-0">
                <p className="planstarlinetext">
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                    className="planstarlineimg"
                  />
                  Reconciliation :-
                </p>
              </div>
              <div className="ml-auto btn pr-0 faq-btn">
                <img src="/img//Icon ionic-ios-arrow-down.svg" />
              </div>
            </div>
          </div>
        </div>
        <div
          id="collapseThrteen"
          className="collapse"
          aria-labelledby="headingThrteen"
          data-parent="#accordion"
        >
          <div className="card-body pl-1">
            <p className="plancommonlinetext">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                className="plancircleimg"
              />
              Bank Accounts
            </p>
            <p className="plancommonlinetext">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                className="plancircleimg"
              />
              Loan Accounts
            </p>
            <p className="plancommonlinetext">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                className="plancircleimg"
              />
              Party Accounts on Demand
            </p>
          </div>
        </div>

        <div className="card bg-white border-0">
          <div
            className="card-header bg-white border-0 py-0"
            id="headingFourteen"
          >
            <div
              className="mb-0 row mr-2  align-items-baseline"
              data-toggle="collapse"
              data-target="#collapseFourteen"
              aria-expanded="false"
              aria-controls="collapseFourteen"
            >
              <div className="text-left btn w-100 bg-white m-0 col-9 ques-2 p-0">
                <p className="planstarlinetext">
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                    className="planstarlineimg"
                  />
                  Ledger and Statements:-
                </p>
              </div>
              <div className="ml-auto btn pr-0 faq-btn">
                <img src="/img//Icon ionic-ios-arrow-down.svg" />
              </div>
            </div>
          </div>
        </div>
        <div
          id="collapseFourteen"
          className="collapse"
          aria-labelledby="headingFourteen"
          data-parent="#accordion"
        >
          <div className="card-body pl-1">
            <p className="plancommonlinetext">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                className="plancircleimg"
              />
              Party Ledger on Demand
            </p>
            <p className="plancommonlinetext">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                className="plancircleimg"
              />
              Tax Liabilty Ledger on Demand
            </p>
            <p className="plancommonlinetext">
              <img
                alt="loading..."
                src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                className="plancircleimg"
              />
              Stock Statement on Demand
            </p>
          </div>
        </div>

        <div className="card bg-white border-0">
          <div
            className="card-header bg-white border-0 py-0"
            id="headingFifteen"
          >
            <div className="mb-0 row mr-2  align-items-baseline">
              <div className="text-left btn w-100 bg-white m-0 col-9 ques-2 p-0">
                <p className="planstarlinetext">
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                    className="planstarlineimg"
                  />
                  Books Finanalization:-
                </p>
              </div>
              <div className="ml-auto btn pr-0 faq-btn"></div>
            </div>
          </div>
        </div>
        <div className="card bg-white border-0">
          <div
            className="card-header bg-white border-0 py-0"
            id="headingSixteen"
          >
            <div
              className="mb-0 row mr-2  align-items-baseline"
              data-toggle="collapse"
              data-target="#collapseSixteen"
              aria-expanded="false"
              aria-controls="collapseSixteen"
            >
              <div className="text-left btn w-100 bg-white m-0 col-9 ques-2 p-0">
                <p className="planstarlinetext">
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                    className="planstarlineimg"
                  />
                  Included Reports:-
                </p>
              </div>
              <div className="ml-auto btn pr-0 faq-btn">
                <img src="/img//Icon ionic-ios-arrow-down.svg" />
              </div>
            </div>
          </div>
          <div
            id="collapseSixteen"
            className="collapse"
            aria-labelledby="headingSixteen"
            data-parent="#accordion"
          >
            <div className="card-body pl-1">
              <p className="plancommonlinetext">
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                  className="plancircleimg"
                />
                Trial Balance
              </p>
              <p className="plancommonlinetext">
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                  className="plancircleimg"
                />
                Balance Sheet
              </p>
              <p className="plancommonlinetext">
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                  className="plancircleimg"
                />
                Profit and Loss Account
              </p>
            </div>
          </div>
        </div>
        <div className="card bg-white border-0">
          <div
            className="card-header bg-white border-0 py-0"
            id="headingSeventeen"
          >
            <div className="mb-0 row mr-2  align-items-baseline">
              <div className="text-left btn w-100 bg-white m-0 col-9 ques-2 p-0">
                <p className="planstarlinetext">
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                    className="planstarlineimg"
                  />
                  Yearly Books of Accounts (PDF):-
                </p>
              </div>
              <div className="ml-auto btn pr-0 faq-btn"></div>
            </div>
          </div>
        </div>
        <div className="card bg-white border-0">
          <div
            className="card-header bg-white border-0 py-0"
            id="headingEighteen"
          >
            <div className="mb-0 row mr-2  align-items-baseline">
              <div className="text-left btn w-100 bg-white m-0 col-9 ques-2 p-0">
                <p className="planstarlinetext">
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                    className="planstarlineimg"
                  />
                  Yearly Books of Accounts (Hard Copy):-
                </p>
              </div>
              <div className="ml-auto btn pr-0 faq-btn"></div>
            </div>
          </div>
        </div>
        <div className="card bg-white border-0">
          <div className="card-header bg-white border-0 py-0">
            <div
              className="mb-0 row mr-2  align-items-baseline"
              id="headingTwenty"
              data-toggle="collapse"
              data-target="#collapseTwenty"
              aria-expanded="false"
              aria-controls="collapseTwenty"
            >
              <div className="text-left btn w-100 bg-white m-0 col-8 ques-2 p-0">
                <p className="planstarlinetext">
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Icon open-star.svg"}
                    className="planstarlineimg"
                  />
                  More:-
                </p>
              </div>
              <div className="ml-auto btn pr-0">
                <img src="/img//Icon ionic-ios-arrow-down.svg" />
              </div>
            </div>
          </div>
          <div
            id="collapseTwenty"
            className="collapse"
            aria-labelledby="headingTwenty"
            data-parent="#accordion"
          >
            <div className="card-body pl-1">
              <p className="plancommonlinetext">
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                  className="plantickimg"
                />
                E-way bill facility
              </p>
              <p className="plancommonlinetext">
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                  className="plantickimg"
                />
                Dashboard for managing service
              </p>
              <p className="plancommonlinetext">
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                  className="plantickimg"
                />
                Mobile application support
              </p>
              <p className="plancommonlinetext">
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                  className="plantickimg"
                />
                Documents sharing facility
              </p>
              <p className="plancommonlinetext">
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                  className="plantickimg"
                />
                Download deliverables any time
              </p>
              <p className="plancommonlinetext">
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                  className="plantickimg"
                />
                Password sharing
              </p>
              <p className="plancommonlinetext">
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                  className="plantickimg"
                />
                Round the clock support
              </p>
              <p className="plancommonlinetext">
                <img
                  alt="loading..."
                  src={process.env.PUBLIC_URL + "/img/Mask Group 201.svg"}
                  className="plantickimg"
                />
                Time to time updates & notifications
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  logout = () => {
    localStorage.removeItem("gglltakoinoeenl");
    localStorage.removeItem("toeljgtkewlna");
    localStorage.removeItem("qazxswedcvfrtgb");
    localStorage.removeItem("lshhihfnkff");
    this.props.history.push({
      pathname: `/`,
    });
  };
  filterDistricts = () => {
    servicestate = jsonData[0][this.state.citydistrictselectvalue];
  };
  createPurchaseReqNormal = () => {
    const contactNoregex = /^[6-9]\d{9}$/;
    const emailregex = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    if (this.state.servicefullname.trim() == "") {
      $(".errorside").show();
      $(".errorsidespan").show().html(" Please enter your name.");
      $("#name").css({
        "border-bottom-color": "#fe0c0c",
      });
      $("#name").focus();
    } else if (
      this.state.servicefullname.trim() == "" ||
      this.state.servicefullname.length > 50
    ) {
      $(".errorside").show();
      $(".errorsidespan").show().html(" Please enter a valid name.");
      $("#name").css({
        "border-bottom-color": "#fe0c0c",
      });
      $("#name").focus();
    } else if (this.state.serviceemail.trim() == "") {
      $(".errorside").show();
      $(".errorsidespan").show().html(" Please enter your email.");
      $("#name").css({
        "border-bottom-color": "#809aae",
      });
      $("#email").css({
        "border-bottom-color": "#fe0c0c",
      });
      $("#email").focus();
    } else if (
      !emailregex.test(this.state.serviceemail) ||
      this.state.serviceemail.length > 50
    ) {
      $(".errorside").show();
      $(".errorsidespan").show().html(" Please enter valid email.");
      $("#email").css({
        "border-bottom-color": "#fe0c0c",
      });
      $("#email").focus();
    } else if (this.state.servicemobileno.trim() == "") {
      $(".errorside").show();
      $(".errorsidespan").show().html(" Please enter your number.");
      $("#name").css({
        "border-bottom-color": "#809aae",
      });
      $("#email").css({
        "border-bottom-color": "#809aae",
      });
      $("#contact").css({
        "border-bottom-color": "#fe0c0c",
      });
      $("#contact").focus();
    } else if (
      this.state.servicemobileno.trim() == "" ||
      !contactNoregex.test(this.state.servicemobileno)
    ) {
      $(".errorside").show();
      $(".errorsidespan").show().html(" Please enter valid number.");
      $("#name").css({
        "border-bottom-color": "#809aae",
      });
      $("#email").css({
        "border-bottom-color": "#809aae",
      });
      $("#contact").css({
        "border-bottom-color": "#fe0c0c",
      });
      $("#contact").focus();
    } else if (this.state.citydistrictselectvalue.trim() == "") {
      $(".errorside").show();
      $(".errorsidespan").show().html(" Please enter your city.");
      $("#name").css({
        "border-bottom-color": "#809aae",
      });
      $("#email").css({
        "border-bottom-color": "#809aae",
      });
      $("#contact").css({
        "border-bottom-color": "#809aae",
      });
      $("#city").css({
        "border-bottom-color": "#fe0c0c",
      });
      $("#city").focus();
    } else {
      this.filterDistricts();
      var mySentence = this.state.servicefullname.trim();
      mySentence = mySentence.replace(/\s+/g, " ");
      const words = mySentence.split(" ");

      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }

      var mywords = words.join(" ");
      $(".planformsubmitbutton").hide();
      $("#addserviceloadermain").show();
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=createPurchaseNew",
          {
            agentId: Decagentid,
            contact: this.state.servicemobileno,
            email: this.state.serviceemail,
            serviceName: "OA",
            purchasename: mywords,
            citydistrict: this.state.citydistrictselectvalue,
            state: servicestate,
            serviceType: "",
            duration: this.state.mainduration,
            plan: this.state.mainplan,
            turnover: this.state.dropDownValue,
            package: this.state.mainpackage,
            comeFrom: "AGP",
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "200" && res.data.status == "success") {
            $(".planformsubmitbutton").show();
            $("#addserviceloadermain").hide();
            $(".planformsubmitbutton").attr("disabled", false);
            sessionStorage.setItem(
              "uegiqlsartdele",
              encryptor.encrypt(res.data.requestid)
            );
            sessionStorage.setItem(
              "aprcillgee",
              encryptor.encrypt(parseFloat(res.data.price))
            );
            sessionStorage.setItem(
              "ssdfsdfwekrfewpfk",
              encryptor.encrypt(parseFloat(res.data.gstvalue))
            );
            sessionStorage.setItem(
              "lslgerotnscmvheearaei",
              encryptor.encrypt("OA")
            );
            sessionStorage.setItem("evlgsoaeetgfl", encryptor.encrypt(0));
            sessionStorage.setItem(
              "egalllpna",
              encryptor.encrypt(this.state.mainplan)
            );
            // sessionStorage.setItem("relleIsugad", encryptor.encrypt(res.data.userId));
            sessionStorage.setItem(
              "ielndmlgeaefelvsdrlucaa",
              encryptor.encrypt(mywords.trim())
            );
            sessionStorage.setItem(
              "ylcvciretaireditdgisdtacesl",
              encryptor.encrypt(this.state.citydistrictselectvalue)
            );
            sessionStorage.setItem(
              "srleleddgaeaemvcnia",
              encryptor.encrypt("Online Accounting")
            );
            this.props.history.push({
              pathname: `/addservice/checkout`,
            });
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else {
            $(".planformsubmitbutton").show();
            $("#addserviceloadermain").hide();
            $(".planformsubmitbutton").attr("disabled", false);
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {});
    }
  };

  handleWithInventorybtn = () => {
    this.setState(
      {
        mainpackage: "WI",
        dropDownValue: "1",
      },
      () => {
        this.handledropDownValueChange();
        $(".WithoutInventorydiv").hide();
        $(".WithInventorydiv").show();
        $(".WithInventoryperbut").addClass("activewi");
        $(".WithoutInventoryprobut").removeClass("activewoi");
        $(".WithInventoryperbut").addClass("oaplans");
        $(".WithoutInventoryprobut").removeClass("oaplans");
      }
    );
  };

  handleWithoutInventorybtn = () => {
    this.setState(
      {
        mainpackage: "WOI",
        dropDownValue: "1",
      },
      () => {
        this.handledropDownValueChange();
        $(".WithInventorydiv").hide();
        $(".WithoutInventorydiv").show();
        $(".WithoutInventoryprobut").addClass("activewoi");
        $(".WithInventoryperbut").removeClass("activewi");
        $(".WithInventoryperbut").removeClass("oaplans");
        $(".WithoutInventoryprobut").addClass("oaplans");
      }
    );
  };

  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };
  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };
  backbutton = () => {
    this.props.history.push({
      pathname: `/addservice`,
    });
  };
  citydistrictselecthandle = (event) => {
    this.setState({
      citydistrictselectvalue: event.target.value,
    });
  };
  handleservicefullnameChange = (e) => {
    this.setState({
      servicefullname: e.target.value,
    });
  };
  handleserviceserviceemailChange = (e) => {
    this.setState({
      serviceemail: e.target.value.toLowerCase(),
    });
  };
  handleserviceservicemobilenoChange = (e) => {
    this.setState({
      servicemobileno: e.target.value,
    });
  };
  handlesupport = () => {
    var x = document.getElementById("myDIV");
    if (x.style.display == "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  };
  render() {
    if (
      localStorage.getItem("gglltakoinoeenl") ==
        process.env.REACT_APP_LOGIN_KEY &&
      localStorage.getItem("toeljgtkewlna") != null &&
      localStorage.getItem("qazxswedcvfrtgb") != null
    ) {
      return (
        <div className="cont">
          <div id="notifContainer"></div>
          <Helmet>
            <link
              rel="stylesheet"
              href={process.env.PUBLIC_URL + "/css/select2new2.css"}
            />
          </Helmet>

          <Header />

          <img
            src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
            className="sidebaropenicon"
          />
          <img
            src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
            onClick={this.backbutton}
            className="sidebarbackicon"
          />
          <img
            src={process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"}
            className="sidebarexpandicon"
          />

          <div className="overlay1"></div>
          <div className="sidebar sidebarshift">
            <div className="side_div_2 sidebar_txt active">
              <Link to="/dashboard">Overview</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <Link to="/myservices">My Services</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <a href="#">Payments Invoices</a>
            </div>
            <div className="side_div_1 sidebar_txt">
              <a href="#">Documents Record</a>
            </div>
            <div className="side_div_1 sidebar_txt">
              <a href="#">Add service</a>
            </div>
            <div></div>
          </div>

          <div id="multiform" className="multiformdiv">
            <p className="planformtext1">Name:</p>
            <input
              type="text"
              pattern="[A-Za-z ]{3,150}"
              id="name"
              className="appendnameinput planforminput"
              value={this.state.servicefullname}
              maxLength="100"
              onChange={this.handleservicefullnameChange}
              required
            />
            <p className="planform2">Email:</p>
            <input
              type="email"
              maxLength="50"
              id="email"
              className="appendemailinput planforminput"
              value={this.state.serviceemail}
              onChange={this.handleserviceserviceemailChange}
              required
            />
            <p className="planform2">Contact no:</p>
            <input
              type="tel"
              pattern="[6-9]{1}[0-9]{9}"
              maxLength="10"
              id="contact"
              className="appendcontactno planforminput"
              value={this.state.servicemobileno}
              onChange={this.handleserviceservicemobilenoChange}
            />
            <p className="planform2">Select Location:</p>
            <Select2
              id="city"
              onChange={this.citydistrictselecthandle}
              value={this.state.citydistrictselectvalue}
              data={[
                "Adilabad",
                "Agar Malwa",
                "Agra",
                "Ahmedabad",
                "Ahmednagar",
                "Aizawl",
                "Ajmer",
                "Akola",
                "Alappuzha",
                "Aligarh",
                "Alipurduar",
                "Alirajpur",
                "Allahabad",
                "Almora",
                "Alwar",
                "Ambala",
                "Ambedkar Nagar",
                "Amethi (Chatrapati Sahuji Mahraj Nagar)",
                "Amravati",
                "Amreli",
                "Amritsar",
                "Amroha",
                "Anand",
                "Anantapur",
                "Anantnag",
                "Angul",
                "Anjaw",
                "Anuppur",
                "Araria",
                "Aravalli",
                "Ariyalur",
                "Arwal",
                "Ashoknagar",
                "Auraiya",
                "Aurangabad (BH)",
                "Aurangabad (MH)",
                "Azamgarh",
                "Bagalkot",
                "Bageshwar",
                "Baghpat",
                "Bahraich",
                "Baksa",
                "Balaghat",
                "Balangir",
                "Balasore",
                "Ballari (Bellary)",
                "Ballia",
                "Balod",
                "Baloda Bazar",
                "Balrampur (CG)",
                "Balrampur (UP)",
                "Banaskantha (Palanpur)",
                "Banda",
                "Bandipore",
                "Banka",
                "Bankura",
                "Banswara",
                "Barabanki",
                "Baramulla",
                "Baran",
                "Bareilly",
                "Bargarh",
                "Barmer",
                "Barnala",
                "Barpeta",
                "Barwani",
                "Bastar",
                "Basti",
                "Bathinda",
                "Beed",
                "Begusarai",
                "Belagavi (Belgaum)",
                "Bemetara",
                "Bengaluru (Bangalore) Rural",
                "Bengaluru (Bangalore) Urban",
                "Betul",
                "Bhadohi",
                "Bhadradri Kothagudem",
                "Bhadrak",
                "Bhagalpur",
                "Bhandara",
                "Bharatpur",
                "Bharuch",
                "Bhavnagar",
                "Bhilwara",
                "Bhind",
                "Bhiwani",
                "Bhojpur",
                "Bhopal",
                "Bidar",
                "Bijapur",
                "Bijnor",
                "Bikaner",
                "Bilaspur (CG)",
                "Bilaspur (HP)",
                "Birbhum",
                "Bishnupur",
                "Biswanath",
                "Bokaro",
                "Bongaigaon",
                "Botad",
                "Boudh",
                "Budaun",
                "Budgam",
                "Bulandshahr",
                "Buldhana",
                "Bundi",
                "Burhanpur",
                "Buxar",
                "Cachar",
                "Central Delhi",
                "Chamarajanagar",
                "Chamba",
                "Chamoli",
                "Champawat",
                "Champhai",
                "Chandauli",
                "Chandel",
                "Chandigarh",
                "Chandrapur",
                "Changlang",
                "Charaideo",
                "Charkhi Dadri",
                "Chatra",
                "Chengalpattu",
                "Chennai",
                "Chhatarpur",
                "Chhindwara",
                "Chhota Udepur",
                "Chikballapur",
                "Chikkamagaluru (Chikmagalur)",
                "Chirang",
                "Chitradurga",
                "Chitrakoot",
                "Chittoor",
                "Chittorgarh",
                "Churachandpur",
                "Churu",
                "Coimbatore",
                "Cooch Behar",
                "Cuddalore",
                "Cuttack",
                "Dadra & Nagar Haveli",
                "Dahod",
                "Dakshin Dinajpur (South Dinajpur)",
                "Dakshina Kannada",
                "Daman",
                "Damoh",
                "Dangs (Ahwa)",
                "Dantewada (South Bastar)",
                "Darbhanga",
                "Darjeeling",
                "Darrang",
                "Datia",
                "Dausa",
                "Davangere",
                "Dehradun",
                "Deogarh",
                "Deoghar",
                "Deoria",
                "Devbhoomi Dwarka",
                "Dewas",
                "Dhalai",
                "Dhamtari",
                "Dhanbad",
                "Dhar",
                "Dharmapuri",
                "Dharwad",
                "Dhemaji",
                "Dhenkanal",
                "Dholpur",
                "Dhubri",
                "Dhule",
                "Dibang Valley",
                "Dibrugarh",
                "Dima Hasao (North Cachar Hills)",
                "Dimapur",
                "Dindigul",
                "Dindori",
                "Diu",
                "Doda",
                "Dumka",
                "Dungarpur",
                "Durg",
                "East Champaran (Motihari)",
                "East Delhi",
                "East Garo Hills",
                "East Godavari",
                "East Jaintia Hills",
                "East Kameng",
                "East Khasi Hills",
                "East Siang",
                "East Sikkim",
                "East Singhbhum",
                "Ernakulam",
                "Erode",
                "Etah",
                "Etawah",
                "Faizabad",
                "Faridabad",
                "Faridkot",
                "Farrukhabad",
                "Fatehabad",
                "Fatehgarh Sahib",
                "Fatehpur",
                "Fazilka",
                "Ferozepur",
                "Firozabad",
                "Gadag",
                "Gadchiroli",
                "Gajapati",
                "Ganderbal",
                "Gandhinagar",
                "Ganjam",
                "Garhwa",
                "Gariyaband",
                "Gautam Buddha Nagar",
                "Gaya",
                "Ghaziabad",
                "Ghazipur",
                "Gir Somnath",
                "Giridih",
                "Goalpara",
                "Godda",
                "Golaghat",
                "Gomati",
                "Gonda",
                "Gondia",
                "Gopalganj",
                "Gorakhpur",
                "Gumla",
                "Guna",
                "Guntur",
                "Gurdaspur",
                "Gurugram (Gurgaon)",
                "Gwalior",
                "Hailakandi",
                "Hamirpur (HP)",
                "Hamirpur (UP)",
                "Hanumangarh",
                "Hapur (Panchsheel Nagar)",
                "Harda",
                "Hardoi",
                "Haridwar",
                "Hassan",
                "Hathras",
                "Haveri",
                "Hazaribag",
                "Hingoli",
                "Hisar",
                "Hojai",
                "Hooghly",
                "Hoshangabad",
                "Hoshiarpur",
                "Howrah",
                "Hyderabad",
                "Idukki",
                "Imphal East",
                "Imphal West",
                "Indore",
                "Jabalpur",
                "Jagatsinghapur",
                "Jagtial",
                "Jaipur",
                "Jaisalmer",
                "Jajpur",
                "Jalandhar",
                "Jalaun",
                "Jalgaon",
                "Jalna",
                "Jalore",
                "Jalpaiguri",
                "Jammu",
                "Jamnagar",
                "Jamtara",
                "Jamui",
                "Jangaon",
                "Janjgir-Champa",
                "Jashpur",
                "Jaunpur",
                "Jayashankar Bhoopalpally",
                "Jehanabad",
                "Jhabua",
                "Jhajjar",
                "Jhalawar",
                "Jhansi",
                "Jhargram",
                "Jharsuguda",
                "Jhunjhunu",
                "Jind",
                "Jiribam",
                "Jodhpur",
                "Jogulamba Gadwal",
                "Jorhat",
                "Junagadh",
                "Kabirdham (Kawardha)",
                "Kachchh",
                "Kaimur (Bhabua)",
                "Kaithal",
                "Kakching",
                "Kalaburagi (Gulbarga)",
                "Kalahandi",
                "Kalimpong",
                "Kallakurichi",
                "Kamareddy",
                "Kamjong",
                "Kamle",
                "Kamrup",
                "Kamrup Metropolitan",
                "Kanchipuram",
                "Kandhamal",
                "Kangpokpi",
                "Kangra",
                "Kanker (North Bastar)",
                "Kannauj",
                "Kannur",
                "Kanpur Dehat",
                "Kanpur Nagar",
                "Kanshiram Nagar (Kasganj)",
                "Kanyakumari",
                "Kapurthala",
                "Karaikal",
                "Karauli",
                "Karbi Anglong",
                "Kargil",
                "Karimganj",
                "Karimnagar",
                "Karnal",
                "Karur",
                "Kasaragod",
                "Kathua",
                "Katihar",
                "Katni",
                "Kaushambi",
                "Kendrapara",
                "Kendujhar (Keonjhar)",
                "Khagaria",
                "Khammam",
                "Khandwa",
                "Khargone",
                "Kheda (Nadiad)",
                "Khordha",
                "Khowai",
                "Khunti",
                "Kinnaur",
                "Kiphire",
                "Kishanganj",
                "Kishtwar",
                "Kodagu",
                "Koderma",
                "Kohima",
                "Kokrajhar",
                "Kolar",
                "Kolasib",
                "Kolhapur",
                "Kolkata",
                "Kollam",
                "Komaram Bheem Asifabad",
                "Kondagaon",
                "Koppal",
                "Koraput",
                "Korba",
                "Korea (Koriya)",
                "Kota",
                "Kottayam",
                "Kozhikode",
                "Kra Daadi",
                "Krishna",
                "Krishnagiri",
                "Kulgam",
                "Kullu",
                "Kupwara",
                "Kurnool",
                "Kurukshetra",
                "Kurung Kumey",
                "Kushinagar (Padrauna)",
                "Lahaul & Spiti",
                "Lakhimpur",
                "Lakhimpur - Kheri",
                "Lakhisarai",
                "Lakshadweep",
                "Lalitpur",
                "Latehar",
                "Latur",
                "Lawngtlai",
                "Leh",
                "Lepa Rada",
                "Lohardaga",
                "Lohit",
                "Longding",
                "Longleng",
                "Lower Dibang Valley",
                "Lower Siang",
                "Lower Subansiri",
                "Lucknow",
                "Ludhiana",
                "Lunglei",
                "Madhepura",
                "Madhubani",
                "Madurai",
                "Mahabubabad",
                "Mahabubnagar",
                "Maharajganj",
                "Mahasamund",
                "Mahe",
                "Mahendragarh",
                "Mahisagar",
                "Mahoba",
                "Mainpuri",
                "Majuli",
                "Malappuram",
                "Malda",
                "Malkangiri",
                "Mamit",
                "Mancherial",
                "Mandi",
                "Mandla",
                "Mandsaur",
                "Mandya",
                "Mansa",
                "Mathura",
                "Mau",
                "Mayurbhanj",
                "Medak",
                "Medchal",
                "Meerut",
                "Mehsana",
                "Mirzapur",
                "Moga",
                "Mokokchung",
                "Mon",
                "Moradabad",
                "Morbi",
                "Morena",
                "Morigaon",
                "Muktsar",
                "Mumbai City",
                "Mumbai Suburban",
                "Mungeli",
                "Munger (Monghyr)",
                "Murshidabad",
                "Muzaffarnagar",
                "Muzaffarpur",
                "Mysuru (Mysore)",
                "Nabarangpur",
                "Nadia",
                "Nagaon",
                "Nagapattinam",
                "Nagarkurnool",
                "Nagaur",
                "Nagpur",
                "Nainital",
                "Nalanda",
                "Nalbari",
                "Nalgonda",
                "Namakkal",
                "Namsai",
                "Nanded",
                "Nandurbar",
                "Narayanpur",
                "Narmada (Rajpipla)",
                "Narsinghpur",
                "Nashik",
                "Navsari",
                "Nawada",
                "Nawanshahr (Shahid Bhagat Singh Nagar)",
                "Nayagarh",
                "Neemuch",
                "New Delhi",
                "Nicobar",
                "Nilgiris",
                "Nirmal",
                "Nizamabad",
                "Noney",
                "North 24 Parganas",
                "North Delhi",
                "North East Delhi",
                "North Garo Hills",
                "North Goa",
                "North Sikkim",
                "North Tripura",
                "North West Delhi",
                "North and Middle Andaman",
                "Nuapada",
                "Nuh",
                "Osmanabad",
                "Pakke Kessang",
                "Pakur",
                "Palakkad",
                "Palamu",
                "Palghar",
                "Pali",
                "Palwal",
                "Panchkula",
                "Panchmahal (Godhra)",
                "Panipat",
                "Panna",
                "Papum Pare",
                "Parbhani",
                "Paschim (West) Burdwan (Bardhaman)",
                "Paschim Medinipur (West Medinipur)",
                "Patan",
                "Pathanamthitta",
                "Pathankot",
                "Patiala",
                "Patna",
                "Pauri Garhwal",
                "Peddapalli",
                "Perambalur",
                "Peren",
                "Phek",
                "Pherzawl",
                "Pilibhit",
                "Pithoragarh",
                "Poonch",
                "Porbandar",
                "Prakasam",
                "Pratapgarh (RJ)",
                "Pratapgarh (UP)",
                "Puducherry",
                "Pudukkottai",
                "Pulwama",
                "Pune",
                "Purba Burdwan (Bardhaman)",
                "Purba Medinipur (East Medinipur)",
                "Puri",
                "Purnia (Purnea)",
                "Purulia",
                "RaeBareli",
                "Raichur",
                "Raigad",
                "Raigarh",
                "Raipur",
                "Raisen",
                "Rajanna Sircilla",
                "Rajgarh",
                "Rajkot",
                "Rajnandgaon",
                "Rajouri",
                "Rajsamand",
                "Ramanagara",
                "Ramanathapuram",
                "Ramban",
                "Ramgarh",
                "Rampur",
                "Ranchi",
                "Rangareddy",
                "Ranipet",
                "Ratlam",
                "Ratnagiri",
                "Rayagada",
                "Reasi",
                "Rewa",
                "Rewari",
                "Ri Bhoi",
                "Rohtak",
                "Rohtas",
                "Rudraprayag",
                "Rupnagar",
                "Sabarkantha (Himmatnagar)",
                "Sagar",
                "Saharanpur",
                "Saharsa",
                "Sahibganj",
                "Sahibzada Ajit Singh Nagar (Mohali)",
                "Saiha",
                "Salem",
                "Samastipur",
                "Samba",
                "Sambalpur",
                "Sambhal (Bhim Nagar)",
                "Sangareddy",
                "Sangli",
                "Sangrur",
                "Sant Kabir Nagar",
                "Saran",
                "Satara",
                "Satna",
                "Sawai Madhopur",
                "Sehore",
                "Senapati",
                "Seoni",
                "Sepahijala",
                "Seraikela-Kharsawan",
                "Serchhip",
                "Shahdara",
                "Shahdol",
                "Shahjahanpur",
                "Shajapur",
                "Shamali (Prabuddh Nagar)",
                "Sheikhpura",
                "Sheohar",
                "Sheopur",
                "Shi Yomi",
                "Shimla",
                "Shivamogga (Shimoga)",
                "Shivpuri",
                "Shopian",
                "Shravasti",
                "Siang",
                "Siddharth Nagar",
                "Siddipet",
                "Sidhi",
                "Sikar",
                "Simdega",
                "Sindhudurg",
                "Singrauli",
                "Sirmaur (Sirmour)",
                "Sirohi",
                "Sirsa",
                "Sitamarhi",
                "Sitapur",
                "Sivaganga",
                "Sivasagar",
                "Siwan",
                "Solan",
                "Solapur",
                "Sonbhadra",
                "Sonepur",
                "Sonipat",
                "Sonitpur",
                "South 24 Parganas",
                "South Andaman",
                "South Delhi",
                "South East Delhi",
                "South Garo Hills",
                "South Goa",
                "South Salamara-Mankachar",
                "South Sikkim",
                "South Tripura",
                "South West Delhi",
                "South West Garo Hills",
                "South West Khasi Hills",
                "Sri Ganganagar",
                "Sri Potti Sriramulu Nellore ",
                "Srikakulam",
                "Srinagar",
                "Sukma",
                "Sultanpur",
                "Sundargarh",
                "Supaul",
                "Surajpur",
                "Surat",
                "Surendranagar",
                "Surguja",
                "Suryapet",
                "Tamenglong",
                "Tapi (Vyara)",
                "Tarn Taran",
                "Tawang",
                "Tehri Garhwal",
                "Tengnoupal",
                "Tenkasi",
                "Thane",
                "Thanjavur",
                "Theni",
                "Thiruvananthapuram",
                "Thoothukudi (Tuticorin)",
                "Thoubal",
                "Thrissur",
                "Tikamgarh",
                "Tinsukia",
                "Tirap",
                "Tiruchirappalli",
                "Tirunelveli",
                "Tirupathur",
                "Tiruppur",
                "Tiruvallur",
                "Tiruvannamalai",
                "Tiruvarur",
                "Tonk",
                "Tuensang",
                "Tumakuru (Tumkur)",
                "Udaipur",
                "Udalguri",
                "Udham Singh Nagar",
                "Udhampur",
                "Udupi",
                "Ujjain",
                "Ukhrul",
                "Umaria",
                "Una",
                "Unakoti",
                "Unnao",
                "Upper Siang",
                "Upper Subansiri",
                "Uttar Dinajpur (North Dinajpur)",
                "Uttara Kannada (Karwar)",
                "Uttarkashi",
                "Vadodara",
                "Vaishali",
                "Valsad",
                "Varanasi",
                "Vellore",
                "Vidisha",
                "Vijayapura (Bijapur)",
                "Vikarabad",
                "Viluppuram",
                "Virudhunagar",
                "Visakhapatnam",
                "Vizianagaram",
                "Wanaparthy",
                "Warangal (Rural)",
                "Warangal (Urban)",
                "Wardha",
                "Washim",
                "Wayanad",
                "West Champaran",
                "West Delhi",
                "West Garo Hills",
                "West Godavari",
                "West Jaintia Hills",
                "West Kameng",
                "West Karbi Anglong",
                "West Khasi Hills",
                "West Siang",
                "West Sikkim",
                "West Singhbhum",
                "West Tripura",
                "Wokha",
                "YSR District, Kadapa (Cuddapah)",
                "Yadadri Bhuvanagiri",
                "Yadgir",
                "Yamunanagar",
                "Yanam",
                "Yavatmal",
                "Zunheboto",
              ]}
              options={{
                placeholder: "City / District",
              }}
            />
            <div className="errorsidediv">
              <p className="errorside">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/img/Icon_feather-alert-circle.svg"
                  }
                  className="errorsideimg"
                />
                <span className="errorsidespan"></span>
              </p>
            </div>
            <center>
              <img
                className="addserviceloader"
                id="addserviceloadermain"
                src={process.env.PUBLIC_URL + "/img/loader.gif"}
                alt=""
              />
              <button
                id="normalsubmit"
                onClick={this.createPurchaseReqNormal}
                type="submit"
                className="planformsubmitbutton"
              >
                SUBMIT
              </button>
              <button
                className="addtocartbutton2"
                id="submit"
                onClick={this.addToCartseconddropdowngetstartedclick}
              >
                Add to cart &nbsp;
                <img src={process.env.PUBLIC_URL + "/img/Path_33782_1.svg"} />
              </button>
            </center>
          </div>

          <div className="arrow-left2 visibilityhidden"></div>
          <div className="white_div expandwhite_div">
            <div className="white_box">
              <div className="white_boxscrolldiv">
                <center>
                  {" "}
                  <p className="download">Add Service</p>
                </center>
                <center>
                  <button
                    value="S"
                    className="WithInventoryperbut oaplans activewi"
                    onClick={this.handleWithInventorybtn}
                  >
                    With Inventory
                  </button>
                  <button
                    value="A"
                    className="WithoutInventoryprobut"
                    onClick={this.handleWithoutInventorybtn}
                  >
                    Without Inventory
                  </button>
                </center>
                <center>
                  <p className="pleaseselecttunovertext">
                    Please select Transactions:
                  </p>
                  <select
                    id="turnover"
                    className="selectturnover"
                    value={this.state.dropDownValue}
                    onChange={(e) => {
                      this.setState(
                        { dropDownValue: e.target.value },
                        this.handledropDownValueChange
                      );
                    }}
                  >
                    <option value="1">0 to 500 Transactions</option>
                    <option value="2">500 to 1200 Transactions</option>
                    <option value="3">1200 to 2500 Transactions</option>
                    <option value="4">2500 to 5000 Transactions</option>
                    <option value="5">5000 to 7500 Transactions </option>
                  </select>
                </center>
                <hr className="buttonbetweenrsd3" />
                <div className="WithInventorydiv">
                  <div className="plantwocards " id="1">
                    <br />
                    <div className="row paddingmarginzero">
                      <div className="col-sm-6 plans2cardcol">
                        <div className="bluestartercard">
                          <p className="planmainheading">Basic</p>
                          <div className="plancardinsidepadding">
                            <div className="formplacer">
                              <div className="plandropdownpadding">
                                <p className="selectatenureheading">
                                  Select the Tenure:
                                </p>
                                <div className="dropdown">
                                    <img
                                      alt="loading..."
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Icon ionic-ios-arrow-down.svg"
                                      }
                                      className="salarieddropdownimg servicedropdownimg9"
                                    />
                                    <button
                                      id="dLabel"
                                      className="dropdown-select plandropdownbutton firstdropdown"
                                      type="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.OA1BWIY}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.OA1BWIYnodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.OA1BWIYDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </button>
                                    <ul
                                      className="dropdown-menu firstdropdown-menu plandropdownul"
                                      aria-labelledby="dLabel"
                                    >
                                      <li
                                        value="M"
                                        className="linebtplandrop"
                                        onClick={this.firstdropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Month &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA1BWIM}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Monthly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA1BWIMnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA1BWIMDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Q"
                                        className="linebtplandrop"
                                        onClick={this.firstdropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              3 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA1BWIQ}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Quarterly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA1BWIQnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA1BWIQDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="H"
                                        className="linebtplandrop"
                                        onClick={this.firstdropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              6 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA1BWIH}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Half-yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA1BWIHnodis}
                                            </span>
                                            <br />
                                            <div className="planhalfyearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA1BWIHDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y"
                                        className="linebtplandrop"
                                        onClick={this.firstdropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA1BWIY}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA1BWIYnodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA1BWIYDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y2"
                                        onClick={this.firstdropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              2 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA1BWIY2}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA1BWIY2nodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA1BWIY2DisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                              </div>
                              <div className="hidesalcon1">
                                <center>
                                  <button
                                    className="getform plangetstartedbtn"
                                    id="3S"
                                    onClick={this.firstdropdowngetstartedclick} 
                                  >
                                    GET STARTED
                                  </button>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/img/loader.gif"
                                    }
                                    className="addserviceloader"
                                    id="addserviceloaderid1"
                                  />
                                </center>
                                {this.planInclusions()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 plans2cardcol">
                        <img
                          alt="loading..."
                          src={process.env.PUBLIC_URL + "/img/Group_3320.svg"}
                          className="salariedcchocieporimg"
                        />
                        <div className="basicplancard">
                          <p className="planmainheading">Pro</p>
                          <div className="plancardinsidepadding">
                            <div className="formplacer">
                              <div className="plandropdownpadding">
                                <p className="selectatenureheading">
                                  Select the Tenure:
                                </p>
                                <div className="dropdown">
                                    <img
                                      alt="loading..."
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Icon ionic-ios-arrow-down.svg"
                                      }
                                      className="salarieddropdownimg servicedropdownimg10"
                                    />
                                    <button
                                      id="dLabel"
                                      className="dropdown-select plandropdownbutton seconddropdown"
                                      type="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.OA1PWIY}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.OA1PWIYnodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.OA1PWIYDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </button>
                                    <ul
                                      className="dropdown-menu seconddropdown-menu plandropdownul"
                                      aria-labelledby="dLabel"
                                    >
                                      <li
                                        value="M"
                                        className="linebtplandrop"
                                        onClick={this.seconddropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Month &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA1PWIM}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Monthly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA1PWIMnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA1PWIMDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Q"
                                        className="linebtplandrop"
                                        onClick={this.seconddropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              3 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA1PWIQ}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Quarterly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA1PWIQnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA1PWIQDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="H"
                                        className="linebtplandrop"
                                        onClick={this.seconddropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              6 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA1PWIH}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Half-yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA1PWIHnodis}
                                            </span>
                                            <br />
                                            <div className="planhalfyearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA1PWIHDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y"
                                        className="linebtplandrop"
                                        onClick={this.seconddropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA1PWIY}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA1PWIYnodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA1PWIYDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y2"
                                        onClick={this.seconddropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              2 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA1PWIY2}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA1PWIY2nodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA1PWIY2DisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                              </div>
                              <div className="hidesalcon2">
                                <center>
                                  <button
                                    className="getform2 plangetstartedbtn"
                                    id="3B"
                                    onClick={this.seconddropdowngetstartedclick}
                                  >
                                    GET STARTED
                                  </button>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/img/loader.gif"
                                    }
                                    className="addserviceloader"
                                    id="addserviceloaderid2"
                                  />
                                </center>
                                {this.planInclusionspro()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="plantwocards planhide" id="2">
                    <br />
                    <div className="row paddingmarginzero">
                      <div className="col-sm-6 plans2cardcol">
                        <div className="bluestartercard">
                          <p className="planmainheading">Basic</p>
                          <div className="plancardinsidepadding">
                            <div className="formplacer">
                              <div className="plandropdownpadding">
                                <p className="selectatenureheading">
                                  Select the Tenure:
                                </p>
                                <div className="dropdown">
                                    <img
                                      alt="loading..."
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Icon ionic-ios-arrow-down.svg"
                                      }
                                      className="salarieddropdownimg servicedropdownimg11"
                                    />
                                    <button
                                      id="dLabel"
                                      className="dropdown-select plandropdownbutton thirddropdown"
                                      type="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.OA2BWIY}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.OA2BWIYnodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.OA2BWIYDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </button>
                                    <ul
                                      className="dropdown-menu thirddropdown-menu plandropdownul"
                                      aria-labelledby="dLabel"
                                    >
                                      <li
                                        value="M"
                                        className="linebtplandrop"
                                        onClick={this.thirddropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Months &nbsp;&nbsp;@
                                            </span>{" "}
                                            <span className="planmainpricespan">
                                            {this.state.OA2BWIM}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Monthly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA2BWIMnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA2BWIMDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Q"
                                        className="linebtplandrop"
                                        onClick={this.thirddropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              3 Months &nbsp;&nbsp;@
                                            </span>{" "}
                                            <span className="planmainpricespan">
                                            {this.state.OA2BWIQ}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Quarterly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA2BWIQnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA2BWIQDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="H"
                                        className="linebtplandrop"
                                        onClick={this.thirddropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              6 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA2BWIH}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Half-yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA2BWIHnodis}
                                            </span>
                                            <br />
                                            <div className="planhalfyearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA2BWIHDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y"
                                        className="linebtplandrop"
                                        onClick={this.thirddropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA2BWIY}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA2BWIYnodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA2BWIYDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y2"
                                        onClick={this.thirddropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              2 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA2BWIY2}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA2BWIY2nodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA2BWIY2DisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                              </div>
                              <div className="hidesalcon3">
                                <center>
                                  <button
                                    className="getform3 plangetstartedbtn"
                                    id="4S"
                                    onClick={this.thirddropdowngetstartedclick}
                                  >
                                    GET STARTED
                                  </button>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/img/loader.gif"
                                    }
                                    className="addserviceloader"
                                    id="addserviceloaderid3"
                                  />
                                </center>
                                {this.planInclusions()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 plans2cardcol">
                        <img
                          alt="loading..."
                          src={process.env.PUBLIC_URL + "/img/Group_3320.svg"}
                          className="salariedcchocieporimg"
                        />
                        <div className="basicplancard">
                          <p className="planmainheading">pro</p>
                          <div className="plancardinsidepadding">
                            <div className="formplacer">
                              <div className="plandropdownpadding">
                                <p className="selectatenureheading">
                                  Select the Tenure:
                                </p>
                                <div className="dropdown">
                                    <img
                                      alt="loading..."
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Icon ionic-ios-arrow-down.svg"
                                      }
                                      className="salarieddropdownimg servicedropdownimg12"
                                    />
                                    <button
                                      id="dLabel"
                                      className="dropdown-select plandropdownbutton fourdropdown"
                                      type="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.OA2PWIY}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.OA2PWIYnodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.OA2PWIYDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </button>
                                    <ul
                                      className="dropdown-menu twelvedropdown-menu plandropdownul"
                                      aria-labelledby="dLabel"
                                    >
                                      <li
                                        value="M"
                                        className="linebtplandrop"
                                        onClick={this.fourdropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Month &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA2PWIM}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Monthly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA2PWIMnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA2PWIMDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Q"
                                        className="linebtplandrop"
                                        onClick={this.fourdropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              3 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA2PWIQ}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Quarterly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA2PWIQnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA2PWIQDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="H"
                                        className="linebtplandrop"
                                        onClick={this.fourdropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              6 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA2PWIH}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Half-yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA2PWIHnodis}
                                            </span>
                                            <br />
                                            <div className="planhalfyearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save  {this.state.OA2PWIHDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y"
                                        className="linebtplandrop"
                                        onClick={this.fourdropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA2PWIY}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA2PWIYnodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA2PWIYDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y2"
                                        onClick={this.fourdropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              2 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA2PWIY2}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA2PWIYnodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA2PWIYDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                              </div>
                              <div className="hidesalcon4">
                                <center>
                                  <button
                                    className="getform4 plangetstartedbtn"
                                    id="4B"
                                    onClick={this.fourdropdowngetstartedclick}
                                  >
                                    GET STARTED
                                  </button>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/img/loader.gif"
                                    }
                                    className="addserviceloader"
                                    id="addserviceloaderid4"
                                  />
                                </center>
                                {this.planInclusionspro()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="plantwocards planhide" id="3">
                    <br />
                    <div className="row paddingmarginzero">
                      <div className="col-sm-6 plans2cardcol">
                        <div className="bluestartercard">
                          <p className="planmainheading">Basic</p>
                          <div className="plancardinsidepadding">
                            <div className="formplacer">
                              <div className="plandropdownpadding">
                                <p className="selectatenureheading">
                                  Select the Tenure:
                                </p>
                                <div className="dropdown">
                                    <img
                                      alt="loading..."
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Icon ionic-ios-arrow-down.svg"
                                      }
                                      className="salarieddropdownimg servicedropdownimg13"
                                    />
                                    <button
                                      id="dLabel"
                                      className="dropdown-select plandropdownbutton fivedropdown"
                                      type="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.OA3BWIY}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.OA3BWIYnodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.OA3BWIYDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </button>
                                    <ul
                                      className="dropdown-menu fivedropdown-menu plandropdownul"
                                      aria-labelledby="dLabel"
                                    >
                                      <li
                                        value="M"
                                        className="linebtplandrop"
                                        onClick={this.fivedropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Month &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA3BWIM}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Monthly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA3BWIMnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA3BWIMDisPer}

                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Q"
                                        className="linebtplandrop"
                                        onClick={this.fivedropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              3 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA3BWIQ}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Quarterly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA3BWIQnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA3BWIQDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="H"
                                        className="linebtplandrop"
                                        onClick={this.fivedropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              6 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA3BWIH}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Half-yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA3BWIHnodis}
                                            </span>
                                            <br />
                                            <div className="planhalfyearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA3BWIHDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y"
                                        className="linebtplandrop"
                                        onClick={this.fivedropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA3BWIY}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA3BWIYnodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA3BWIYDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y2"
                                        onClick={this.fivedropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              2 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA3BWIY2}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA3BWIY2nodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA3BWIY2DisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                              </div>
                              <div className="hidesalcon5">
                                <center>
                                  <button
                                    className="getform5 plangetstartedbtn"
                                    id="5S"
                                    onClick={this.fivedropdowngetstartedclick}
                                  >
                                    GET STARTED
                                  </button>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/img/loader.gif"
                                    }
                                    className="addserviceloader"
                                    id="addserviceloaderid5"
                                  />
                                </center>
                                {this.planInclusions()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 plans2cardcol">
                        <img
                          alt="loading..."
                          src={process.env.PUBLIC_URL + "/img/Group_3320.svg"}
                          className="salariedcchocieporimg"
                        />
                        <div className="basicplancard">
                          <p className="planmainheading">Pro</p>
                          <div className="plancardinsidepadding">
                            <div className="formplacer">
                              <div className="plandropdownpadding">
                                <p className="selectatenureheading">
                                  Select the Tenure:
                                </p>
                                <div className="dropdown">
                                    <img
                                      alt="loading..."
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Icon ionic-ios-arrow-down.svg"
                                      }
                                      className="salarieddropdownimg servicedropdownimg14"
                                    />
                                    <button
                                      id="dLabel"
                                      className="dropdown-select plandropdownbutton sixdropdown"
                                      type="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.OA3PWIY}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.OA3PWIYnodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.OA3PWIYDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </button>
                                    <ul
                                      className="dropdown-menu sixdropdown-menu plandropdownul"
                                      aria-labelledby="dLabel"
                                    >
                                      <li
                                        value="M"
                                        className="linebtplandrop"
                                        onClick={this.sixdropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Month &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA3PWIM}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Monthly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA3PWIMnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA3PWIMDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Q"
                                        className="linebtplandrop"
                                        onClick={this.sixdropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              3 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA3PWIQ}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Quarterly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA3PWIQnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save  {this.state.OA3PWIQDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="H"
                                        className="linebtplandrop"
                                        onClick={this.sixdropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              6 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA3PWIH}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Half-yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA3PWIHnodis}
                                            </span>
                                            <br />
                                            <div className="planhalfyearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA3PWIHDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y"
                                        className="linebtplandrop"
                                        onClick={this.sixdropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA3PWIY}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA3PWIYnodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA3PWIYDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y2"
                                        onClick={this.sixdropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              2 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA3PWIY2}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA3PWIY2nodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA3PWIY2DisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                              </div>
                              <div className="hidesalcon6">
                                <center>
                                  <button
                                    className="getform6 plangetstartedbtn"
                                    id="5B"
                                    onClick={this.sixdropdowngetstartedclick}
                                  >
                                    GET STARTED
                                  </button>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/img/loader.gif"
                                    }
                                    className="addserviceloader"
                                    id="addserviceloaderid6"
                                  />
                                </center>
                                {this.planInclusionspro()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="plantwocards planhide" id="4">
                    <br />
                    <div className="row paddingmarginzero">
                      <div className="col-sm-6 plans2cardcol">
                        <div className="bluestartercard">
                          <p className="planmainheading">Basic</p>
                          <div className="plancardinsidepadding">
                            <div className="formplacer">
                              <div className="plandropdownpadding">
                                <p className="selectatenureheading">
                                  Select the Tenure:
                                </p>
                                <div className="dropdown">
                                    <img
                                      alt="loading..."
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Icon ionic-ios-arrow-down.svg"
                                      }
                                      className="salarieddropdownimg servicedropdownimg13"
                                    />
                                    <button
                                      id="dLabel"
                                      className="dropdown-select plandropdownbutton sevendropdown"
                                      type="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.OA4BWIY}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.OA4BWIYnodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.OA4BWIYDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </button>
                                    <ul
                                      className="dropdown-menu sevendropdown-menu plandropdownul"
                                      aria-labelledby="dLabel"
                                    >
                                      <li
                                        value="M"
                                        className="linebtplandrop"
                                        onClick={this.sevendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Month &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA4BWIM}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Monthly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA4BWIMnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA4BWIMDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Q"
                                        className="linebtplandrop"
                                        onClick={this.sevendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              3 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA4BWIQ}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Quarterly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA4BWIQnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA4BWIQDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="H"
                                        className="linebtplandrop"
                                        onClick={this.sevendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              6 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA4BWIH}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Half-yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA4BWIHnodis}
                                            </span>
                                            <br />
                                            <div className="planhalfyearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA4BWIHDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y"
                                        className="linebtplandrop"
                                        onClick={this.sevendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA4BWIY}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA4BWIYnodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA4BWIYDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y2"
                                        onClick={this.sevendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              2 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA4BWIY2}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA4BWIY2nodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA4BWIY2DisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                              </div>
                              <div className="hidesalcon7">
                                <center>
                                  <button
                                    className="getform7 plangetstartedbtn"
                                    id="5S"
                                    onClick={this.sevendropdowngetstartedclick}
                                  >
                                    GET STARTED
                                  </button>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/img/loader.gif"
                                    }
                                    className="addserviceloader"
                                    id="addserviceloaderid7"
                                  />
                                </center>
                                {this.planInclusions()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 plans2cardcol">
                        <img
                          alt="loading..."
                          src={process.env.PUBLIC_URL + "/img/Group_3320.svg"}
                          className="salariedcchocieporimg"
                        />
                        <div className="basicplancard">
                          <p className="planmainheading">pro</p>
                          <div className="plancardinsidepadding">
                            <div className="formplacer">
                              <div className="plandropdownpadding">
                                <p className="selectatenureheading">
                                  Select the Tenure:
                                </p>
                                <div className="dropdown">
                                    <img
                                      alt="loading..."
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Icon ionic-ios-arrow-down.svg"
                                      }
                                      className="salarieddropdownimg servicedropdownimg14"
                                    />
                                    <button
                                      id="dLabel"
                                      className="dropdown-select plandropdownbutton eightdropdown"
                                      type="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.OA4PWIY}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.OA4PWIYnodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.OA4PWIYDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </button>
                                    <ul
                                      className="dropdown-menu eightdropdown-menu plandropdownul"
                                      aria-labelledby="dLabel"
                                    >
                                      <li
                                        value="M"
                                        className="linebtplandrop"
                                        onClick={this.eightdropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Month &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA4PWIM}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Monthly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA4PWIMnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA4PWIMDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Q"
                                        className="linebtplandrop"
                                        onClick={this.eightdropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              3 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA4PWIQ}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Quarterly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA4PWIQnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA4PWIQDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="H"
                                        className="linebtplandrop"
                                        onClick={this.eightdropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              6 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA4PWIH}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Half-yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA4PWIHnodis}
                                            </span>
                                            <br />
                                            <div className="planhalfyearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA4PWIHDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y"
                                        className="linebtplandrop"
                                        onClick={this.eightdropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA4PWIY}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA4PWIYnodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA4PWIYDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y2"
                                        onClick={this.eightdropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              2 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA4PWIY2}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA4PWIY2nodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA4PWIY2DisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                              </div>
                              <div className="hidesalcon8">
                                <center>
                                  <button
                                    className="getform8 plangetstartedbtn"
                                    id="5B"
                                    onClick={this.eightdropdowngetstartedclick}
                                  >
                                    GET STARTED
                                  </button>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/img/loader.gif"
                                    }
                                    className="addserviceloader"
                                    id="addserviceloaderid8"
                                  />
                                </center>
                                {this.planInclusionspro()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="plantwocards planhide" id="5">
                    <br />
                    <div className="row paddingmarginzero">
                      <div className="col-sm-6 plans2cardcol">
                        <div className="bluestartercard">
                          <p className="planmainheading">Basic</p>
                          <div className="plancardinsidepadding">
                            <div className="formplacer">
                              <div className="plandropdownpadding">
                                <p className="selectatenureheading">
                                  Select the Tenure:
                                </p>
                                <div className="dropdown">
                                    <img
                                      alt="loading..."
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Icon ionic-ios-arrow-down.svg"
                                      }
                                      className="salarieddropdownimg servicedropdownimg13"
                                    />
                                    <button
                                      id="dLabel"
                                      className="dropdown-select plandropdownbutton ninedropdown"
                                      type="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.OA5BWIY}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.OA5BWIYnodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.OA5BWIYDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </button>
                                    <ul
                                      className="dropdown-menu ninedropdown-menu plandropdownul"
                                      aria-labelledby="dLabel"
                                    >
                                      <li
                                        value="M"
                                        className="linebtplandrop"
                                        onClick={this.ninedropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Month &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA5BWIM}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Monthly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA5BWIMnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA5BWIMDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Q"
                                        className="linebtplandrop"
                                        onClick={this.ninedropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              3 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA5BWIQ}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Quarterly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA5BWIQnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA5BWIQDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="H"
                                        className="linebtplandrop"
                                        onClick={this.ninedropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              6 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA5BWIH}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Half-yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA5BWIHnodis}
                                            </span>
                                            <br />
                                            <div className="planhalfyearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save  {this.state.OA5BWIHDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y"
                                        className="linebtplandrop"
                                        onClick={this.ninedropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA5BWIY}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA5BWIYnodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA5BWIYDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y2"
                                        onClick={this.ninedropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              2 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA5BWIY2}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA5BWIY2nodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA5BWIY2DisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                              </div>
                              <div className="hidesalcon9">
                                <center>
                                  <button
                                    className="getform9 plangetstartedbtn"
                                    id="5S"
                                    onClick={this.ninedropdowngetstartedclick}
                                  >
                                    GET STARTED
                                  </button>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/img/loader.gif"
                                    }
                                    className="addserviceloader"
                                    id="addserviceloaderid9"
                                  />
                                </center>
                                {this.planInclusions()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 plans2cardcol">
                        <img
                          alt="loading..."
                          src={process.env.PUBLIC_URL + "/img/Group_3320.svg"}
                          className="salariedcchocieporimg"
                        />
                        <div className="basicplancard">
                          <p className="planmainheading">pro</p>
                          <div className="plancardinsidepadding">
                            <div className="formplacer">
                              <div className="plandropdownpadding">
                                <p className="selectatenureheading">
                                  Select the Tenure:
                                </p>
                                <div className="dropdown">
                                    <img
                                      alt="loading..."
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Icon ionic-ios-arrow-down.svg"
                                      }
                                      className="salarieddropdownimg servicedropdownimg14"
                                    />
                                    <button
                                      id="dLabel"
                                      className="dropdown-select plandropdownbutton tendropdown"
                                      type="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.OA5PWIY}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.OA5PWIYnodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.OA5PWIYDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </button>
                                    <ul
                                      className="dropdown-menu tendropdown-menu plandropdownul"
                                      aria-labelledby="dLabel"
                                    >
                                      <li
                                        value="M"
                                        className="linebtplandrop"
                                        onClick={this.tendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Month &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA5PWIM}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Monthly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA5PWIMnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA5PWIMDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Q"
                                        className="linebtplandrop"
                                        onClick={this.tendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              3 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA5PWIQ}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Quarterly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA5PWIQnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA5PWIQDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="H"
                                        className="linebtplandrop"
                                        onClick={this.tendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              6 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA5PWIH}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Half-yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA5PWIHnodis}
                                            </span>
                                            <br />
                                            <div className="planhalfyearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA5PWIHDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y"
                                        className="linebtplandrop"
                                        onClick={this.tendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA5PWIY}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA5PWIYnodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA5PWIYDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y2"
                                        onClick={this.tendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              2 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA5PWIY2}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA5PWIY2nodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA5PWIY2DisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                              </div>
                              <div className="hidesalcon10">
                                <center>
                                  <button
                                    className="getform10 plangetstartedbtn"
                                    id="5B"
                                    onClick={this.tendropdowngetstartedclick}
                                  >
                                    GET STARTED
                                  </button>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/img/loader.gif"
                                    }
                                    className="addserviceloader"
                                    id="addserviceloaderid10"
                                  />
                                </center>
                                {this.planInclusionspro()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="WithoutInventorydiv">
                  <div className="plantwocards WithoutInventorydiv" id="6">
                    <br />
                    <div className="row paddingmarginzero">
                      <div className="col-sm-6 plans2cardcol">
                        <div className="bluestartercard">
                          <p className="planmainheading">Basic</p>
                          <div className="plancardinsidepadding">
                            <div className="formplacer">
                              <div className="plandropdownpadding">
                                <p className="selectatenureheading">
                                  Select the Tenure:
                                </p>
                                <div className="dropdown">
                                    <img
                                      alt="loading..."
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Icon ionic-ios-arrow-down.svg"
                                      }
                                      className="salarieddropdownimg servicedropdownimg9"
                                    />
                                    <button
                                      id="dLabel"
                                      className="dropdown-select plandropdownbutton elevendropdown"
                                      type="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.OA1BWOIY}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.OA1BWOIYnodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.OA1BWOIYDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </button>
                                    <ul
                                      className="dropdown-menu elevendropdown-menu plandropdownul"
                                      aria-labelledby="dLabel"
                                    >
                                      <li
                                        value="M"
                                        className="linebtplandrop"
                                        onClick={this.elevendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Month &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA1BWOIM}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Monthly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA1BWOIMnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA1BWOIMDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Q"
                                        className="linebtplandrop"
                                        onClick={this.elevendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              3 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA1BWOIQ}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Quarterly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA1BWOIQnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA1BWOIQDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="H"
                                        className="linebtplandrop"
                                        onClick={this.elevendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              6 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA1BWOIH}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Half-yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA1BWOIHnodis}
                                            </span>
                                            <br />
                                            <div className="planhalfyearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA1BWOIHDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y"
                                        className="linebtplandrop"
                                        onClick={this.elevendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA1BWOIY}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA1BWOIYnodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save  {this.state.OA1BWOIYDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y2"
                                        onClick={this.elevendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              2 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA1BWOIY2}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA1BWOIY2nodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA1BWOIY2DisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                              </div>
                              <div className="hidesalcon11">
                                <center>
                                  <button
                                    className="getfor11 plangetstartedbtn"
                                    id="3S"
                                    onClick={this.elevendropdowngetstartedclick}
                                  >
                                    GET STARTED
                                  </button>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/img/loader.gif"
                                    }
                                    className="addserviceloader"
                                    id="addserviceloaderid9"
                                  />
                                </center>
                                {this.planInclusions()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 plans2cardcol">
                        <img
                          alt="loading..."
                          src={process.env.PUBLIC_URL + "/img/Group_3320.svg"}
                          className="salariedcchocieporimg"
                        />
                        <div className="basicplancard">
                          <p className="planmainheading">Pro</p>
                          <div className="plancardinsidepadding">
                            <div className="formplacer">
                              <div className="plandropdownpadding">
                                <p className="selectatenureheading">
                                  Select the Tenure:
                                </p>
                                <div className="dropdown">
                                    <img
                                      alt="loading..."
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Icon ionic-ios-arrow-down.svg"
                                      }
                                      className="salarieddropdownimg servicedropdownimg10"
                                    />
                                    <button
                                      id="dLabel"
                                      className="dropdown-select plandropdownbutton twelvedropdown"
                                      type="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.OA1PWOIY}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.OA1PWOIYnodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.OA1PWOIYDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </button>
                                    <ul
                                      className="dropdown-menu twelvedropdown-menu plandropdownul"
                                      aria-labelledby="dLabel"
                                    >
                                      <li
                                        value="M"
                                        className="linebtplandrop"
                                        onClick={this.twelvedropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Month &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA1PWOIM}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Monthly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA1PWOIMnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save  {this.state.OA1PWOIMDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Q"
                                        className="linebtplandrop"
                                        onClick={this.twelvedropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              3 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA1PWOIQ}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Quarterly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA1PWOIQnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA1PWOIQDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="H"
                                        className="linebtplandrop"
                                        onClick={this.twelvedropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              6 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA1PWOIH}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Half-yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA1PWOIHnodis}
                                            </span>
                                            <br />
                                            <div className="planhalfyearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA1PWOIHDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y"
                                        className="linebtplandrop"
                                        onClick={this.twelvedropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA1PWOIY}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA1PWOIYnodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA1PWOIYDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y2"
                                        onClick={this.twelvedropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              2 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA1PWOIY2}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA1PWOIY2nodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA1PWOIY2DisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                              </div>
                              <div className="hidesalcon12">
                                <center>
                                  <button
                                    className="getform12 plangetstartedbtn"
                                    id="3B"
                                    onClick={this.twelvedropdowngetstartedclick}
                                  >
                                    GET STARTED
                                  </button>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/img/loader.gif"
                                    }
                                    className="addserviceloader"
                                    id="addserviceloaderid10"
                                  />
                                </center>
                                {this.planInclusionspro()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="plantwocards planhide" id="7">
                    <br />
                    <div className="row paddingmarginzero">
                      <div className="col-sm-6 plans2cardcol">
                        <div className="bluestartercard">
                          <p className="planmainheading">Basic</p>
                          <div className="plancardinsidepadding">
                            <div className="formplacer">
                              <div className="plandropdownpadding">
                                <p className="selectatenureheading">
                                  Select the Tenure:
                                </p>
                                <div className="dropdown">
                                    <img
                                      alt="loading..."
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Icon ionic-ios-arrow-down.svg"
                                      }
                                      className="salarieddropdownimg servicedropdownimg11"
                                    />
                                    <button
                                      id="dLabel"
                                      className="dropdown-select plandropdownbutton thirteendropdown"
                                      type="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.OA2BWOIY}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.OA2BWOIYnodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.OA2BWOIYDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </button>
                                    <ul
                                      className="dropdown-menu thirteendropdown-menu plandropdownul"
                                      aria-labelledby="dLabel"
                                    >
                                      <li
                                        value="M"
                                        className="linebtplandrop"
                                        onClick={this.thirteendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Month &nbsp;&nbsp;@
                                            </span>{" "}
                                            <span className="planmainpricespan">
                                            {this.state.OA2BWOIM}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Monthly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA2BWOIMnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA2BWOIMDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Q"
                                        className="linebtplandrop"
                                        onClick={this.thirteendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              3 Months &nbsp;&nbsp;@
                                            </span>{" "}
                                            <span className="planmainpricespan">
                                            {this.state.OA2BWOIQ}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Quarterly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA2BWOIQnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA2BWOIQDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="H"
                                        className="linebtplandrop"
                                        onClick={this.thirteendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              6 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA2BWOIH}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Half-yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA2BWOIHnodis}
                                            </span>
                                            <br />
                                            <div className="planhalfyearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA2BWOIHDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y"
                                        className="linebtplandrop"
                                        onClick={this.thirteendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA2BWOIY}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA2BWOIYnodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save  {this.state.OA2BWOIYDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y2"
                                        onClick={this.thirteendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              2 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA2BWOIY2}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA2BWOIY2nodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA2BWOIY2DisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                              </div>
                              <div className="hidesalcon13">
                                <center>
                                  <button
                                    className="getform13 plangetstartedbtn"
                                    id="4S"
                                    onClick={
                                      this.thirteendropdowngetstartedclick
                                    }
                                  >
                                    GET STARTED
                                  </button>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/img/loader.gif"
                                    }
                                    className="addserviceloader"
                                    id="addserviceloaderid11"
                                  />
                                </center>
                                {this.planInclusions()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 plans2cardcol">
                        <img
                          alt="loading..."
                          src={process.env.PUBLIC_URL + "/img/Group_3320.svg"}
                          className="salariedcchocieporimg"
                        />
                        <div className="basicplancard">
                          <p className="planmainheading">pro</p>
                          <div className="plancardinsidepadding">
                            <div className="formplacer">
                              <div className="plandropdownpadding">
                                <p className="selectatenureheading">
                                  Select the Tenure:
                                </p>
                                <div className="dropdown">
                                    <img
                                      alt="loading..."
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Icon ionic-ios-arrow-down.svg"
                                      }
                                      className="salarieddropdownimg servicedropdownimg12"
                                    />
                                    <button
                                      id="dLabel"
                                      className="dropdown-select plandropdownbutton fourteendropdown"
                                      type="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.OA2PWOIY}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.OA2PWOIYnodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.OA2PWOIYDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </button>
                                    <ul
                                      className="dropdown-menu fourteendropdown-menu plandropdownul"
                                      aria-labelledby="dLabel"
                                    >
                                      <li
                                        value="M"
                                        className="linebtplandrop"
                                        onClick={this.fourteendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Month &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA2PWOIM}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Monthly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA2PWOIMnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA2PWOIMDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Q"
                                        className="linebtplandrop"
                                        onClick={this.fourteendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              3 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA2PWOIQ}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Quarterly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA2PWOIQnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA2PWOIQDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="H"
                                        className="linebtplandrop"
                                        onClick={this.fourteendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              6 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA2PWOIH}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Half-yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA2PWOIHnodis}
                                            </span>
                                            <br />
                                            <div className="planhalfyearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA2PWOIHDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y"
                                        className="linebtplandrop"
                                        onClick={this.fourteendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA2PWOIY}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA2PWOIYnodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA2PWOIYDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y2"
                                        onClick={this.fourteendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              2 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA2PWOIY2}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA2PWOIY2nodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA2PWOIY2DisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                              </div>
                              <div className="hidesalcon14">
                                <center>
                                  <button
                                    className="getform14 plangetstartedbtn"
                                    id="4B"
                                    onClick={
                                      this.fourteendropdowngetstartedclick
                                    }
                                  >
                                    GET STARTED
                                  </button>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/img/loader.gif"
                                    }
                                    className="addserviceloader"
                                    id="addserviceloaderid12"
                                  />
                                </center>
                                {this.planInclusionspro()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="plantwocards planhide" id="8">
                    <br />
                    <div className="row paddingmarginzero">
                      <div className="col-sm-6 plans2cardcol">
                        <div className="bluestartercard">
                          <p className="planmainheading">Basic</p>
                          <div className="plancardinsidepadding">
                            <div className="formplacer">
                              <div className="plandropdownpadding">
                                <p className="selectatenureheading">
                                  Select the Tenure:
                                </p>
                                <div className="dropdown">
                                    <img
                                      alt="loading..."
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Icon ionic-ios-arrow-down.svg"
                                      }
                                      className="salarieddropdownimg servicedropdownimg13"
                                    />
                                    <button
                                      id="dLabel"
                                      className="dropdown-select plandropdownbutton fifteendropdown"
                                      type="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.OA3BWOIY}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.OA3BWOIYnodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.OA3BWOIYDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </button>
                                    <ul
                                      className="dropdown-menu fifteendropdown-menu plandropdownul"
                                      aria-labelledby="dLabel"
                                    >
                                      <li
                                        value="M"
                                        className="linebtplandrop"
                                        onClick={this.fifteendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Month &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA3BWOIM}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Monthly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA3BWOIMnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save  {this.state.OA3BWOIMDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Q"
                                        className="linebtplandrop"
                                        onClick={this.fifteendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              3 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA3BWOIQ}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Quarterly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA3BWOIQnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA3BWOIQDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="H"
                                        className="linebtplandrop"
                                        onClick={this.fifteendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              6 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA3BWOIH}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Half-yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA3BWOIHnodis}
                                            </span>
                                            <br />
                                            <div className="planhalfyearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA3BWOIHDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y"
                                        className="linebtplandrop"
                                        onClick={this.fifteendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA3BWOIY}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA3BWOIYnodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA3BWOIYDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y2"
                                        onClick={this.fifteendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              2 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA3BWOIY2}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA3BWOIY2nodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA3BWOIY2DisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                              </div>
                              <div className="hidesalcon15">
                                <center>
                                  <button
                                    className="getform15 plangetstartedbtn"
                                    id="5S"
                                    onClick={
                                      this.fifteendropdowngetstartedclick
                                    }
                                  >
                                    GET STARTED
                                  </button>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/img/loader.gif"
                                    }
                                    className="addserviceloader"
                                    id="addserviceloaderid13"
                                  />
                                </center>
                                {this.planInclusions()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 plans2cardcol">
                        <img
                          alt="loading..."
                          src={process.env.PUBLIC_URL + "/img/Group_3320.svg"}
                          className="salariedcchocieporimg"
                        />
                        <div className="basicplancard">
                          <p className="planmainheading">Pro</p>
                          <div className="plancardinsidepadding">
                            <div className="formplacer">
                              <div className="plandropdownpadding">
                                <p className="selectatenureheading">
                                  Select the Tenure:
                                </p>
                                <div className="dropdown">
                                    <img
                                      alt="loading..."
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Icon ionic-ios-arrow-down.svg"
                                      }
                                      className="salarieddropdownimg servicedropdownimg14"
                                    />
                                    <button
                                      id="dLabel"
                                      className="dropdown-select plandropdownbutton sixteendropdown"
                                      type="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.OA3PWOIY}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.OA3PWOIYnodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.OA3PWOIYDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </button>
                                    <ul
                                      className="dropdown-menu sixteendropdown-menu plandropdownul"
                                      aria-labelledby="dLabel"
                                    >
                                      <li
                                        value="M"
                                        className="linebtplandrop"
                                        onClick={this.sixteendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Month &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA3PWOIM}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Monthly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA3PWOIMnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA3PWOIMDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Q"
                                        className="linebtplandrop"
                                        onClick={this.sixteendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              3 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA3PWOIQ}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Quarterly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA3PWOIQnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA3PWOIQDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="H"
                                        className="linebtplandrop"
                                        onClick={this.sixteendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              6 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA3PWOIH}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Half-yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA3PWOIHnodis}
                                            </span>
                                            <br />
                                            <div className="planhalfyearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA3PWOIHDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y"
                                        className="linebtplandrop"
                                        onClick={this.sixteendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA3PWOIY}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA3PWOIYnodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA3PWOIYDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y2"
                                        onClick={this.sixteendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              2 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA3PWOIY2}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA3PWOIY2nodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA3PWOIY2DisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                              </div>
                              <div className="hidesalcon16">
                                <center>
                                  <button
                                    className="getform16 plangetstartedbtn"
                                    id="5B"
                                    onClick={
                                      this.sixteendropdowngetstartedclick
                                    }
                                  >
                                    GET STARTED
                                  </button>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/img/loader.gif"
                                    }
                                    className="addserviceloader"
                                    id="addserviceloaderid14"
                                  />
                                </center>
                                {this.planInclusionspro()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="plantwocards planhide" id="9">
                    <br />
                    <div className="row paddingmarginzero">
                      <div className="col-sm-6 plans2cardcol">
                        <div className="bluestartercard">
                          <p className="planmainheading">Basic</p>
                          <div className="plancardinsidepadding">
                            <div className="formplacer">
                              <div className="plandropdownpadding">
                                <p className="selectatenureheading">
                                  Select the Tenure:
                                </p>
                                <div className="dropdown">
                                    <img
                                      alt="loading..."
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Icon ionic-ios-arrow-down.svg"
                                      }
                                      className="salarieddropdownimg servicedropdownimg13"
                                    />
                                    <button
                                      id="dLabel"
                                      className="dropdown-select plandropdownbutton seventeendropdown"
                                      type="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.OA4BWOIY}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.OA4BWOIYnodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.OA4BWOIYDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </button>
                                    <ul
                                      className="dropdown-menu seventeendropdown-menu plandropdownul"
                                      aria-labelledby="dLabel"
                                    >
                                      <li
                                        value="M"
                                        className="linebtplandrop"
                                        onClick={this.seventeendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Month &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA4BWOIM}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Monthly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA4BWOIMnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA4BWOIMDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Q"
                                        className="linebtplandrop"
                                        onClick={this.seventeendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              3 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA4BWOIQ}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Quarterly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA4BWOIQnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA4BWOIQDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="H"
                                        className="linebtplandrop"
                                        onClick={this.seventeendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              6 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA4BWOIH}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Half-yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA4BWOIHnodis}
                                            </span>
                                            <br />
                                            <div className="planhalfyearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA4BWOIHDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y"
                                        className="linebtplandrop"
                                        onClick={this.seventeendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA4BWOIY}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA4BWOIYnodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA4BWOIYDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y2"
                                        onClick={this.seventeendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              2 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA4BWOIY2}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA4BWOIY2nodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA4BWOIY2DisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                              </div>
                              <div className="hidesalcon17">
                                <center>
                                  <button
                                    className="getform17 plangetstartedbtn"
                                    id="5S"
                                    onClick={
                                      this.seventeendropdowngetstartedclick
                                    }
                                  >
                                    GET STARTED
                                  </button>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/img/loader.gif"
                                    }
                                    className="addserviceloader"
                                    id="addserviceloaderid13"
                                  />
                                </center>
                                {this.planInclusions()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 plans2cardcol">
                        <img
                          alt="loading..."
                          src={process.env.PUBLIC_URL + "/img/Group_3320.svg"}
                          className="salariedcchocieporimg"
                        />
                        <div className="basicplancard">
                          <p className="planmainheading">pro</p>
                          <div className="plancardinsidepadding">
                            <div className="formplacer">
                              <div className="plandropdownpadding">
                                <p className="selectatenureheading">
                                  Select the Tenure:
                                </p>
                                <div className="dropdown">
                                    <img
                                      alt="loading..."
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Icon ionic-ios-arrow-down.svg"
                                      }
                                      className="salarieddropdownimg servicedropdownimg14"
                                    />
                                    <button
                                      id="dLabel"
                                      className="dropdown-select plandropdownbutton eighteendropdown"
                                      type="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.OA4PWOIY}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.OA4PWOIYnodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.OA4PWOIYDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </button>
                                    <ul
                                      className="dropdown-menu eighteendropdown-menu plandropdownul"
                                      aria-labelledby="dLabel"
                                    >
                                      <li
                                        value="M"
                                        className="linebtplandrop"
                                        onClick={this.eighteendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Month &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA4PWOIM}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Monthly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA4PWOIMnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save  {this.state.OA4PWOIMDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Q"
                                        className="linebtplandrop"
                                        onClick={this.eighteendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              3 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA4PWOIQ}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Quarterly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA4PWOIQnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA4PWOIQDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="H"
                                        className="linebtplandrop"
                                        onClick={this.eighteendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              6 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA4PWOIH}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Half-yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA4PWOIHnodis}
                                            </span>
                                            <br />
                                            <div className="planhalfyearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA4PWOIHDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y"
                                        className="linebtplandrop"
                                        onClick={this.eighteendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA4PWOIY}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA4PWOIYnodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA4PWOIYDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y2"
                                        onClick={this.eighteendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              2 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA4PWOIY2}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA4PWOIY2nodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA4PWOIY2DisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                              </div>
                              <div className="hidesalcon18">
                                <center>
                                  <button
                                    className="getform18 plangetstartedbtn"
                                    id="5B"
                                    onClick={
                                      this.eighteendropdowngetstartedclick
                                    }
                                  >
                                    GET STARTED
                                  </button>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/img/loader.gif"
                                    }
                                    className="addserviceloader"
                                    id="addserviceloaderid14"
                                  />
                                </center>
                                {this.planInclusionspro()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="plantwocards planhide" id="10">
                    <br />
                    <div className="row paddingmarginzero">
                      <div className="col-sm-6 plans2cardcol">
                        <div className="bluestartercard">
                          <p className="planmainheading">Basic</p>
                          <div className="plancardinsidepadding">
                            <div className="formplacer">
                              <div className="plandropdownpadding">
                                <p className="selectatenureheading">
                                  Select the Tenure:
                                </p>
                                <div className="dropdown">
                                    <img
                                      alt="loading..."
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Icon ionic-ios-arrow-down.svg"
                                      }
                                      className="salarieddropdownimg servicedropdownimg13"
                                    />
                                    <button
                                      id="dLabel"
                                      className="dropdown-select plandropdownbutton nineteendropdown"
                                      type="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.OA5BWOIY}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.OA5BWOIYnodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.OA5BWOIYDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </button>
                                    <ul
                                      className="dropdown-menu nineteendropdown-menu plandropdownul"
                                      aria-labelledby="dLabel"
                                    >
                                      <li
                                        value="M"
                                        className="linebtplandrop"
                                        onClick={this.nineteendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Month &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA5BWOIM}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Monthly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA5BWOIMnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA5BWOIMDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Q"
                                        className="linebtplandrop"
                                        onClick={this.nineteendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              3 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA5BWOIQ}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Quarterly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA5BWOIQnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA5BWOIQDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="H"
                                        className="linebtplandrop"
                                        onClick={this.nineteendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              6 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA5BWOIH}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Half-yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA5BWOIHnodis}
                                            </span>
                                            <br />
                                            <div className="planhalfyearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save  {this.state.OA5BWOIHDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y"
                                        className="linebtplandrop"
                                        onClick={this.nineteendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA5BWOIY}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA5BWOIYnodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA5BWOIYDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y2"
                                        onClick={this.nineteendropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              2 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA5BWOIY2}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA5BWOIY2nodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA5BWOIY2DisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                              </div>
                              <div className="hidesalcon19">
                                <center>
                                  <button
                                    className="getform19 plangetstartedbtn"
                                    id="5S"
                                    onClick={
                                      this.nineteendropdowngetstartedclick
                                    }
                                  >
                                    GET STARTED
                                  </button>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/img/loader.gif"
                                    }
                                    className="addserviceloader"
                                    id="addserviceloaderid13"
                                  />
                                </center>
                                {this.planInclusions()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 plans2cardcol">
                        <img
                          alt="loading..."
                          src={process.env.PUBLIC_URL + "/img/Group_3320.svg"}
                          className="salariedcchocieporimg"
                        />
                        <div className="basicplancard">
                          <p className="planmainheading">pro</p>
                          <div className="plancardinsidepadding">
                            <div className="formplacer">
                              <div className="plandropdownpadding">
                                <p className="selectatenureheading">
                                  Select the Tenure:
                                </p>
                                <div className="dropdown">
                                    <img
                                      alt="loading..."
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/img/Icon ionic-ios-arrow-down.svg"
                                      }
                                      className="salarieddropdownimg servicedropdownimg14"
                                    />
                                    <button
                                      id="dLabel"
                                      className="dropdown-select plandropdownbutton twentydropdown"
                                      type="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <div className="plandropdowninsidediv">
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="plantimespan">
                                            1 Year &nbsp;&nbsp;@
                                          </span>
                                          <span className="planmainpricespan">
                                            {" "}
                                            {this.state.OA5PWOIY}
                                          </span>
                                        </div>
                                        <div className="plandropdowninsideeachdiv">
                                          <span className="planmonthtype">
                                            Yearly
                                          </span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <span className="planlinecutpricespan">
                                          {this.state.OA5PWOIYnodis}
                                          </span>
                                          <br />
                                          <div className="plan1yearsavediv">
                                            <span className="plansavespan">
                                              {" "}
                                              Save {this.state.OA5PWOIYDisPer}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </button>
                                    <ul
                                      className="dropdown-menu twentydropdown-menu plandropdownul"
                                      aria-labelledby="dLabel"
                                    >
                                      <li
                                        value="M"
                                        className="linebtplandrop"
                                        onClick={this.twentydropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Month &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA5PWOIM}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Monthly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA5PWOIMnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA5PWOIMDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Q"
                                        className="linebtplandrop"
                                        onClick={this.twentydropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              3 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA5PWOIQ}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Quarterly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA5PWOIQnodis}
                                            </span>
                                            <br />
                                            <div className="plan3monthssavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA5PWOIQDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="H"
                                        className="linebtplandrop"
                                        onClick={this.twentydropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              6 Months &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                            {this.state.OA5PWOIH}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Half-yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA5PWOIHnodis}
                                            </span>
                                            <br />
                                            <div className="planhalfyearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA5PWOIHDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y"
                                        className="linebtplandrop"
                                        onClick={this.twentydropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              1 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA5PWOIY}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA5PWOIHnodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA5PWOIHDisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li
                                        value="Y2"
                                        onClick={this.twentydropdownlichange}
                                      >
                                        <div className="plandropdowninsidediv">
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="plantimespan">
                                              2 Year &nbsp;&nbsp;@
                                            </span>
                                            <span className="planmainpricespan">
                                              {" "}
                                              {this.state.OA5PWOIY2}
                                            </span>
                                          </div>
                                          <div className="plandropdowninsideeachdiv">
                                            <span className="planmonthtype">
                                              Yearly
                                            </span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span className="planlinecutpricespan">
                                            {this.state.OA5PWOIY2nodis}
                                            </span>
                                            <br />
                                            <div className="plan1yearsavediv">
                                              <span className="plansavespan">
                                                {" "}
                                                Save {this.state.OA5PWOIY2DisPer}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                              </div>
                              <div className="hidesalcon20">
                                <center>
                                  <button
                                    className="getform20 plangetstartedbtn"
                                    id="5B"
                                    onClick={this.twentydropdowngetstartedclick}
                                  >
                                    GET STARTED
                                  </button>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/img/loader.gif"
                                    }
                                    className="addserviceloader"
                                    id="addserviceloaderid14"
                                  />
                                </center>
                                {this.planInclusionspro()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="myDIV">
            <div className="supportDetails">
              <img
                src={process.env.PUBLIC_URL + "/img/24-7_Support.png"}
                alt="image"
              />
              <div>
                <h5>{supportName}</h5>
                <p>Agent Support Executive</p>
                <span>
                  <b>Contact: </b> +91 {supportContact}
                </span>
                <p className="mb-1">
                  <b>Working Hours: </b> 11:00 AM - 06:30 PM
                </p>
              </div>
            </div>
          </div>
          <button className="support" onClick={this.handlesupport}>
            <img
              src={process.env.PUBLIC_URL + "/img/headPhone.svg"}
              width="50%"
              height="50px"
            />
          </button>

          <Footer />
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default Addserviceoa;

import React, { Component } from 'react'
import { Link } from "react-router-dom";
var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);
let DecmainsideMenuAccess;
export class Sidebar extends Component {
  componentDidMount() {
    let mainsideMenuAccess = localStorage.getItem("sideMenuAccess");
    DecmainsideMenuAccess = encryptor.decrypt(mainsideMenuAccess);
  }
  render() {
    return (
        <div className="sidebar">
           <div className="side_div_2 sidebar_txt active">
          <Link to="/dashboard">Dashboard</Link>
        </div>
        <div className="side_div_2 sidebar_txt ">
          <Link to="/overview">Overview</Link>
        </div>
        <div className="side_div_1 sidebar_txt">
          <Link to="/myuser">My Users</Link>
        </div>
        <div className="side_div_1 sidebar_txt">
          <Link to="/paymentinvoices">User Invoices / Payments</Link>
        </div>
        <div className="side_div_1 sidebar_txt">
          <Link to="/documentrecords">Documents Records</Link>
        </div>
        <div className="side_div_1 sidebar_txt">
          <Link to="/addservice">Add service</Link>
        </div>
       
       
        <div className="side_div_1 sidebar_txt">
          <Link to="/agentservices">Agent Services</Link>
        </div>
        <div className="side_div_1 sidebar_txt">
          <Link to="/commission">My Commission</Link>
        </div>
        <div className="side_div_1 sidebar_txt">
          <Link to="/support">Support</Link>
        </div> 
        <div className="side_div_1 sidebar_txt">
          <Link to="/materials">Materials</Link>
        </div> 
        <div className="side_div_1 sidebar_txt">
          <Link to="/profile">Profile</Link>
        </div>
        {
          DecmainsideMenuAccess == "RP" ? 
          <div className="side_div_1 sidebar_txt">
          <Link to="/referral-lead">Lead</Link>
          </div>: <></>
        }
        <div></div>
      </div>
    )
  }
}

export default Sidebar
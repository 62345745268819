import React, { Component } from 'react'
import { Redirect } from "react-router";
export class Error extends Component {

    render() {
        if (
            localStorage.getItem("gglltakoinoeenl") == process.env.REACT_APP_LOGIN_KEY &&
            localStorage.getItem("toeljgtkewlna") != null &&
            localStorage.getItem("qazxswedcvfrtgb") != null
          ) {
        return (
            <Redirect to="/dashboard" />
        )
          }
          else
          {
            return (
                <Redirect to="/" />
            )
          }
        }
}

export default Error
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export class Footer extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            year: ""
        };
      }

    componentDidMount() {
        var d = new Date();
        this.setState({
            year: d.getFullYear(),
        })
        
    }
    render() {
        return (
            <footer className="footer">

            <div className="row">
                <div className="col-lg-6 col-md-6 side_div_3">
                    <Link to="/support" className="sidebar_txt1">Help  <img src={process.env.PUBLIC_URL + "/img/Path 181.svg"}  alt="help"/> </Link>
                </div>
                <div className="col-lg-6 col-12">
                    <p className="copyright">© {this.state.year} ISONOMY LEGAL251 Private Limited. All Rights Reserved</p>
                </div>
            </div>
        </footer>
        )
    }
}

export default Footer

import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import axios from "axios";
import { Redirect } from "react-router";
import jsonData from "../State__and__dist.json"

let Decagentid;
let Decjwttoken;
let servicestate;

let supportName;
let supportContact;
let supportEmail;
let baseUrl = process.env.REACT_APP_API_BASE_URL;
var key = process.env.REACT_APP_ENCRYPTOR_KEY;
let clrinterval = false

var encryptor = require("simple-encryptor")(key);
class Addservicegstr extends Component {
  constructor(props) {
    super(props);

    this.state = {
      serviceselectvalue: "",
      citydistrictselectvalue: "",
      servicefullname: "",
      dob: "",
      Income: "",
      Salarytype: "",
      Loanamount: "",
      serviceemail: "",
      servicemobileno: "",
      mobileotp: "",
      emailotp: "",
      requestid: "",
      mobtime: 90,
      emailtime: 90,
      
supportName:"",
supportContact:"",
supportEmail:"",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    $(document.body).css("background-color", "#2d2d2d");
    let mainjwttoken = localStorage.getItem("toeljgtkewlna");
    let mainagentid = localStorage.getItem("qazxswedcvfrtgb");
    Decagentid = encryptor.decrypt(mainagentid);
    Decjwttoken = encryptor.decrypt(mainjwttoken);
    supportName = localStorage.getItem("supportName");
    supportContact = localStorage.getItem("supportContact");
    supportEmail = localStorage.getItem("supportEmail");
    
    this.setState({
      supportName:supportName,
      supportContact:supportContact,
      supportEmail:supportEmail,
    })
  }
  logout = () => {
    localStorage.removeItem("gglltakoinoeenl");
    localStorage.removeItem("toeljgtkewlna");
    localStorage.removeItem("qazxswedcvfrtgb");
    localStorage.removeItem("lshhihfnkff");
    this.props.history.push({
      pathname: `/`,
    });
  };
  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };

  filterDistricts = () => {
    servicestate = jsonData[0][this.state.citydistrictselectvalue]
  };



  handledobChange = (e) => {
    this.setState({
      dob: e.target.value,
    });
  };
  handleIncomeChange = (e) => {
    this.setState({
      Income: e.target.value.replace(/\D/g, ''),
    });
  };
  handleSalarytypeChange = (e) => {
    this.setState({
      Salarytype: e.target.value,
    });
  };

  handleLoanamountChange = (e) => {
    this.setState({
      Loanamount: e.target.value.replace(/\D/g, ''),
    });
  };


 

  changeuserinfo = () => {
    $("#invalidtempusermobileotp").hide();
    $("#invalidtempuseremailotp").hide();
    $("#agentuserinfomodal").show();
    $("#otpmodalagentservices").hide();
    clrinterval = true
  };



  SubmitOTP = () => {

    $("#invalidtempusermobileotp").hide();
    $("#invalidtempuseremailotp").hide();
    $("#otpsentsuccesstemp").hide();
    if (
      this.state.mobileotp.trim() == "" &&
      this.state.emailotp.trim() == ""
    ) {

      $(".invalidmobile").html("Please enter mobile OTP")
      $(".invalidemail").html("Please enter email OTP")
      $("#invalidtempusermobileotp").show();
      $("#invalidtempuseremailotp").show();
    } else if (
      this.state.mobileotp.trim() == "" ||
      this.state.mobileotp.length > 6 ||
      this.state.mobileotp.length < 6 ||
      isNaN(this.state.mobileotp)
    ) {
      $(".invalidmobile").html("Please enter valid mobile OTP")
      $("#invalidtempusermobileotp").show();
    } else if (
      this.state.emailotp.trim() == "" ||
      this.state.emailotp.length > 6 ||
      this.state.emailotp.length < 6 ||
      isNaN(this.state.emailotp)
    ) {
      $(".invalidemail").html("Please enter valid email OTP")
      $("#invalidtempuseremailotp").show();
    } else {
      // $("#otpmodalagentservices").hide();
      $(".sendOtpLoader").show();
      $(".submitOtpbtn").hide();
      axios
        .post(

          `${baseUrl}p=createOrderLoanNewUser`,

          {
            agentId: Decagentid,
            serviceName: "LOANPR",
            requestid: this.state.requestid,
            gstno: "",
            govtfees: 0,
            emailOTP: this.state.emailotp.trim(),
            contactOTP: this.state.mobileotp.trim(),
            discount: "",
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {

          if (res.data.code == 200 && res.data.status == "paid") {
            $("#Temp-User").modal("hide");
            $(".sendOtpBtn").hide();
            $("#agentuserinfomodal").hide();
            $("#otpmodalagentservices").hide();
            this.props.history.push({
              pathname: `/addservice/paymentsuccessfull2`,

              state: {
                servicerequestid: this.state.ServiceId,
              },

            });





          } else if (
            res.data.code == 201 &&
            res.data.status == "contactotpinvalid"
          ) {
            $(".invalidmobile").html("Please enter valid mobile OTP")
            $("#invalidtempusermobileotp").show();
            $(".sendOtpLoader").hide();
            $(".submitOtpbtn").show();
          } else if (
            res.data.code == 201 &&
            res.data.status == "emailotpinvalid"
          ) {
            $(".invalidemail").html("Please enter valid email OTP")
            $("#invalidtempuseremailotp").show();
            $(".sendOtpLoader").hide();
            $(".submitOtpbtn").show();
          } else if (
            res.data.code == 201 &&
            res.data.status == "bothotpinvalid"
          ) {
            $(".invalidemail").html("Please enter valid email OTP")
            $(".invalidmobile").html("Please enter valid mobile OTP")
            $("#otpmodalagentservices").show();
            $("#invalidtempusermobileotp").show();
            $("#invalidtempuseremailotp").show();
            $(".sendOtpLoader").hide();
            $(".submitOtpbtn").show();
          } else if (res.data.code == 201 && res.data.status == "fail") {
            this.showNotification(res.data.message);
            $("#agentuserinfomodal").hide();
            $("#otpmodalagentservices").hide();
            $(".sendOtpLoader").hide();
            $(".submitOtpbtn").show();
            // $("#unsuccess-modal").show();
          } else if (
            res.data.code == "201" &&
            res.data.status == "invalidauth"
          ) {
            this.logout();
          } else {
            this.showNotification(res.data.message);


          }
        })
        .catch((err) => {
          // 
          this.showNotification("Something went wrong");
          $(".sendOtpLoader").hide();
          $(".submitOtpbtn").show();
          // $(".sendOtpLoader").hide();
          //   $(".sendOtpBtn").show();
        });
    }
  };









  createPurchaseReqNormal = () => {
    $('.errorside').hide()
    $('#dob1').css({
      'border-bottom-color': '#01355D'
    });
    $('#AnnualProfit2').css({
      'border-bottom-color': '#01355D'
    });
    $('#Salarytype3').css({
      'border-bottom-color': '#01355D'
    });
    $('#Loanamount4').css({
      'border-bottom-color': '#01355D'
    });
    $("#otpmodalagentservices").hide();



    var sep = new Date(this.state.dob);
    var today = new Date();
    var diffD = Math.floor((sep - today) / (1000 * 60 * 60 * 24));


    const contactNoregex = /^[6-9]\d{9}$/;
    const emailregex = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    if (this.state.servicefullname.trim() == "") {
      $('.errorside').show()
      $('.errorsidespan').show().html(
        ' Please enter your name.');
      $('#email').css({
        'border-bottom-color': '#809aae'
      });
      $('#contact').css({
        'border-bottom-color': '#809aae'
      });
      $('#city').css({
        'border-bottom-color': '#01355D'
      });
      $("#Income2").css({
        "border-bottom-color": "#01355D",
      });
      $('#dob1').css({
        'border-bottom-color': '#01355D'
      });
      $("#Salarytype3").css({
        "border-bottom-color": "#01355D",
      });
      $("#Loanamount4").css({
        "border-bottom-color": "#01355D",
      });
      $("#company5").css({
        "border-bottom-color": "#01355D",
      });
      $("#propertytype1").css({
        "border-bottom-color": "#01355D",
      });
      $('#name').css({
        'border-bottom-color': '#fe0c0c'
      });
      $('#name').focus();
    } else if (this.state.servicefullname.length > 50) {
      $('.errorside').show()
      $('.errorsidespan').show().html(
        ' Please enter a valid name.');
      $('#email').css({
        'border-bottom-color': '#809aae'
      });
      $('#contact').css({
        'border-bottom-color': '#809aae'
      });
      $('#city').css({
        'border-bottom-color': '#01355D'
      });
      $("#Income2").css({
        "border-bottom-color": "#01355D",
      });
      $("#propertytype1").css({
        "border-bottom-color": "#01355D",
      });
      $("#Salarytype3").css({
        "border-bottom-color": "#01355D",
      });
      $("#Loanamount4").css({
        "border-bottom-color": "#01355D",
      });
      $("#company5").css({
        "border-bottom-color": "#01355D",
      });
      $('#name').css({
        'border-bottom-color': '#fe0c0c'
      });
      $('#name').focus();
    } else if (this.state.serviceemail.trim() == "") {
      $('.errorside').show()
      $('.errorsidespan').show().html(
        ' Please enter your email.');
      $('#name').css({
        'border-bottom-color': '#809aae'
      });
      $('#contact').css({
        'border-bottom-color': '#809aae'
      });
      $('#city').css({
        'border-bottom-color': '#01355D'
      });
      $("#Income2").css({
        "border-bottom-color": "#01355D",
      });
      $('#dob1').css({
        'border-bottom-color': '#01355D'
      });
      $("#Loanamount4").css({
        "border-bottom-color": "#01355D",
      });
      $("#propertytype1").css({
        "border-bottom-color": "#01355D",
      });
      $("#company5").css({
        "border-bottom-color": "#01355D",
      });
      $("#Salarytype3").css({
        "border-bottom-color": "#01355D",
      });
      $('#email').css({
        'border-bottom-color': '#fe0c0c'
      });

      $('#email').focus();
    } else if (!emailregex.test(this.state.serviceemail) || this.state.serviceemail.length > 50) {
      $('.errorside').show()
      $('.errorsidespan').show().html(
        ' Please enter valid email.');
      $('#name').css({
        'border-bottom-color': '#809aae'
      });
      $('#contact').css({
        'border-bottom-color': '#809aae'
      });
      $('#city').css({
        'border-bottom-color': '#01355D'
      });
      $("#Income2").css({
        "border-bottom-color": "#01355D",
      });
      $('#dob1').css({
        'border-bottom-color': '#01355D'
      });
      $("#propertytype1").css({
        "border-bottom-color": "#01355D",
      });
      $("#company5").css({
        "border-bottom-color": "#01355D",
      });
      $("#Loanamount4").css({
        "border-bottom-color": "#01355D",
      });
      $("#Salarytype3").css({
        "border-bottom-color": "#01355D",
      });
      $('#email').css({
        'border-bottom-color': '#fe0c0c'
      });
      $('#email').focus();
    } else if (this.state.servicemobileno.trim() == "") {
      $('.errorside').show()
      $('.errorsidespan').show().html(
        ' Please enter your number.');
      $('#name').css({
        'border-bottom-color': '#809aae'
      });
      $('#email').css({
        'border-bottom-color': '#809aae'
      });
      $('#city').css({
        'border-bottom-color': '#01355D'
      });
      $("#Income2").css({
        "border-bottom-color": "#01355D",
      });
      $('#dob1').css({
        'border-bottom-color': '#01355D'
      });
      $("#propertytype1").css({
        "border-bottom-color": "#01355D",
      });
      $("#company5").css({
        "border-bottom-color": "#01355D",
      });
      $("#Loanamount4").css({
        "border-bottom-color": "#01355D",
      });

      $("#Salarytype3").css({
        "border-bottom-color": "#01355D",
      });
      $('#contact').css({
        'border-bottom-color': '#fe0c0c'
      });
      $('#contact').focus();
    } else if (this.state.servicemobileno.trim() == "" || !contactNoregex.test(this.state.servicemobileno)) {
      $('.errorside').show()
      $('.errorsidespan').show().html(
        ' Please enter valid number.');
      $('#name').css({
        'border-bottom-color': '#809aae'
      });
      $('#email').css({
        'border-bottom-color': '#809aae'
      });
      $('#city').css({
        'border-bottom-color': '#01355D'
      });
      $("#company5").css({
        "border-bottom-color": "#01355D",
      });
      $("#Income2").css({
        "border-bottom-color": "#01355D",
      });
      $("#propertytype1").css({
        "border-bottom-color": "#01355D",
      });
      $('#dob1').css({
        'border-bottom-color': '#01355D'
      });
      $("#company5").css({
        "border-bottom-color": "#01355D",
      });
      $("#Salarytype3").css({
        "border-bottom-color": "#01355D",
      });
      $("#Loanamount4").css({
        "border-bottom-color": "#01355D",
      });
      $('#contact').css({
        'border-bottom-color': '#fe0c0c'
      });
      $('#contact').focus();
    } else if (this.state.citydistrictselectvalue.trim() == "") {
      $('.errorside').show()
      $('.errorsidespan').show().html(
        ' Please enter your city.');
      $('#name').css({
        'border-bottom-color': '#809aae'
      });
      $('#email').css({
        'border-bottom-color': '#809aae'
      });
      $("#Income2").css({
        "border-bottom-color": "#01355D",
      });
      $('#contact').css({
        'border-bottom-color': '#809aae'
      });
      $("#propertytype1").css({
        "border-bottom-color": "#01355D",
      });
      $('#dob1').css({
        'border-bottom-color': '#01355D'
      });
      $("#company5").css({
        "border-bottom-color": "#01355D",
      });
      $("#Salarytype3").css({
        "border-bottom-color": "#01355D",
      });
      $("#Loanamount4").css({
        "border-bottom-color": "#01355D",
      });
      $('#city').css({
        'border-bottom-color': '#fe0c0c'
      });
      $('#city').focus();

    } else if (this.state.dob.trim() == "") {
      $('.errorside').show()
      $('.errorsidespan').show().html(
        'Please enter  Date of Birth');
      $('#name').css({
        'border-bottom-color': '#01355D'
      });
      $('#email').css({
        'border-bottom-color': '#01355D'
      });
      $('#contact').css({
        'border-bottom-color': '#01355D'
      });
      $('.select2-container--default .select2-selection--single').css({
        'border-bottom-color': '#01355D'
      });
      $('#city').css({
        'border-bottom-color': '#01355D'
      });
      $('#dob1').css({
        'border-bottom-color': '#fe0c0c'
      });
      $('#dob1').focus();
    } else if (diffD == -1 || diffD == 0 || diffD > 0) {
      $('.errorside').show()
      $('.errorsidespan').show().html(
        'Please enter valid Date of Birth');
      $('#name').css({
        'border-bottom-color': '#01355D'
      });
      $('#email').css({
        'border-bottom-color': '#01355D'
      });
      $('#contact').css({
        'border-bottom-color': '#01355D'
      });
      $('.select2-container--default .select2-selection--single').css({
        'border-bottom-color': '#01355D'
      });
      $('#city').css({
        'border-bottom-color': '#01355D'
      });
      $('#dob1').css({
        'border-bottom-color': '#fe0c0c'
      });
      $('#dob1').focus();
    } else if (this.state.Income.trim() == "") {
      $(".errorside").show();
      $(".errorsidespan").show().html("Please enter Monthy Income");
      $("#name").css({
        "border-bottom-color": "#01355D",
      });
      $("#email").css({
        "border-bottom-color": "#01355D",
      });
      $("#contact").css({
        "border-bottom-color": "#01355D",
      });
      $(".select2-container--default .select2-selection--single").css({
        "border-bottom-color": "#01355D",
      });
      $("#propertytype1").css({
        "border-bottom-color": "#01355D",
      });
      $('#city').css({
        'border-bottom-color': '#01355D'
      });
      $("#Loanamount4").css({
        "border-bottom-color": "#01355D",
      });
      $('#dob1').css({
        'border-bottom-color': '#01355D'
      });
      $("#company5").css({
        "border-bottom-color": "#01355D",
      });
      $("#Salarytype3").css({
        "border-bottom-color": "#01355D",
      });
      $("#Income2").css({
        "border-bottom-color": "#fe0c0c",
      });
      $("#Income2").focus();
    } else if (this.state.Salarytype == "") {
      $(".errorside").show();
      $(".errorsidespan").show().html("Please select Salary Mode");
      $("#name").css({
        "border-bottom-color": "#01355D",
      });
      $("#propertytype1").css({
        "border-bottom-color": "#01355D",
      });
      $('#dob1').css({
        'border-bottom-color': '#01355D'
      });
      $("#email").css({
        "border-bottom-color": "#01355D",
      });
      $("#Income2").css({
        "border-bottom-color": "#01355D",
      });
      $('#city').css({
        'border-bottom-color': '#01355D'
      });
      $("#contact").css({
        "border-bottom-color": "#01355D",
      });
      $(".select2-container--default .select2-selection--single").css({
        "border-bottom-color": "#01355D",
      });
      $("#company5").css({
        "border-bottom-color": "#01355D",
      });
      $("#Loanamount4").css({
        "border-bottom-color": "#01355D",
      });
      $("#Salarytype3").css({
        "border-bottom-color": "#fe0c0c",
      });
      $("#Salarytype3").focus();

    } else if (this.state.Loanamount.trim() == "") {
      $(".errorside").show();
      $(".errorsidespan").show().html("Please enter Loan Amount");
      $(".select2-container--default .select2-selection--single").css({
        "border-bottom-color": "#01355D",
      });
      $('#name').css({
        'border-bottom-color': '#809aae'
      });
      $('#email').css({
        'border-bottom-color': '#809aae'
      });
      $("#Salarytype3").css({
        "border-bottom-color": "#01355D",
      });
      $("#propertytype1").css({
        "border-bottom-color": "#01355D",
      });
      $("#Income2").css({
        "border-bottom-color": "#01355D",
      });
      $('#dob1').css({
        'border-bottom-color': '#01355D'
      });
      $('#contact').css({
        'border-bottom-color': '#809aae'
      });
      $("#company5").css({
        "border-bottom-color": "#01355D",
      });
      $('#city').css({
        'border-bottom-color': '#01355D'
      });
      $("#Loanamount4").css({
        "border-bottom-color": "#fe0c0c",
      });
      $("#Loanamount4").focus();
    } else if (this.state.Loanamount < 500000) {
      $(".errorside").show();
      $(".errorsidespan").show().html("Please enter greater than 5 lakh Loan Amount");
      $(".select2-container--default .select2-selection--single").css({
        "border-bottom-color": "#01355D",
      });
      $('#name').css({
        'border-bottom-color': '#809aae'
      });
      $('#email').css({
        'border-bottom-color': '#809aae'
      });
      $("#Income2").css({
        "border-bottom-color": "#01355D",
      });
      $("#Salarytype3").css({
        "border-bottom-color": "#01355D",
      });
      $("#company5").css({
        "border-bottom-color": "#01355D",
      });
      $("#propertytype1").css({
        "border-bottom-color": "#01355D",
      });
      $('#dob1').css({
        'border-bottom-color': '#01355D'
      });
      $('#contact').css({
        'border-bottom-color': '#809aae'
      });
      $('#city').css({
        'border-bottom-color': '#01355D'
      });
      $("#company5").css({
        "border-bottom-color": "#01355D",
      });
      $("#Loanamount4").css({
        "border-bottom-color": "#fe0c0c",
      });
      $("#Loanamount4").focus();
    }

    else {
      this.filterDistricts();
      var mySentence = this.state.servicefullname.trim();
      mySentence = mySentence.replace(/\s+/g, " ");
      const words = mySentence.split(" ");

      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }

      var mywords = words.join(" ");
      $(".proceednowbutton").hide();
      $(".addserviceloader").show();
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + 'p=createLoanPurchaseNew',
          {
            agentId: Decagentid,
            serviceName: "LOANPR",
            loantype: "Personal Loan",
            occupation: "Salaried",
            purchasename: this.state.servicefullname,
            city: this.state.citydistrictselectvalue,
            email: this.state.serviceemail,
            mobile: this.state.servicemobileno,
            state: servicestate,
            dob: this.state.dob,
            monthlyincome: this.state.Income,
            salarymode: this.state.Salarytype,
            loanamount: this.state.Loanamount,
            propertyvalue: "",
            companyname: "",
            propertytype: "",
            annualprofit: "",
            obligation: "",
            comeFrom:"AGP"
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD
            }
          }
        )
        .then((res) => {


          if (res.data.code == "200" && res.data.status == "success") {
            $(".proceednowbutton").show();
            $(".addserviceloader").hide();
            $(".dnone").css("display", "block");
            this.setState({ requestid: res.data.requestid });
            this.setState({ ServiceId: res.data.ServiceId });
            this.emailcountdown();
            this.mobcountdown();
            $("#Temp-User").modal({ backdrop: "static", keyboard: false });
            $(".sendOtpBtn").show();
            $("#agentuserinfomodal").show();
            $("#otpmodalagentservices").hide();




          } else if (res.data.code == "201" && res.data.status == "invalidauth") {
            this.logout();
          } else {
            $(".proceednowbutton").show();
            $(".addserviceloader").hide();
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => { });
    }
  };

  backbutton = () => {
    this.props.history.push({
      pathname: `/addservice`,
    });
  };
  citydistrictselecthandle = (event) => {
    this.setState({
      citydistrictselectvalue: event.target.value,
    });

  };
  handleservicefullnameChange = (e) => {
    this.setState({
      servicefullname: e.target.value,
    });
  };
  handleserviceserviceemailChange = (e) => {
    this.setState({
      serviceemail: e.target.value.toLowerCase(),
    });
  };
  handleserviceservicemobilenoChange = (e) => {
    this.setState({
      servicemobileno: e.target.value,
    });
  };

  handlesalaryselfechange = (e) => {
    if (e.target.value == "Self-Employed") {
      this.props.history.push({
        pathname: `/addservice/business-loan`,
      });

    }
  }


  mobcountdown() {
    this.setState({ mobtime: 90 });
    $("#mobileresend-loader").hide();
    function clear() {
        clearInterval(interval);
        $("#mobileresend-loader").hide();
    }
    let interval = setInterval(() => {
        if (this.state.mobtime == 1 || clrinterval) {
            clear(interval);
            $("#mobileresend-loader").hide();
        } else {
        }
        this.setState({ mobtime: this.state.mobtime - 1 });
    }, 1000);
  }
  
  
  emailcountdown() {
    this.setState({ emailtime: 90 });
    $("#mobileresend-loader").hide();
    function clear() {
        clearInterval(interval);
        $("#mobileresend-loader").hide();
    }
    let interval = setInterval(() => {
        if (this.state.emailtime == 1 || clrinterval) {
            clear(interval);
            $("#mobileresend-loader").hide();
        } else {
        }
        this.setState({ emailtime: this.state.emailtime - 1 });
    }, 1000);
  }
  
  resenduseremailOtp = () => {
    this.setState({ emailtime: 90 }); 
    $("#invalidtempusermobileotp").hide();
    $("#invalidtempuseremailotp").hide();
    $("#otpsentsuccesstemp").hide();
  
    
    
    if (this.state.emailtime == 0) {
            
        axios
            .post(
                `${baseUrl}p=ResendEmailOTP`,
                {
                    agentId: Decagentid,
                    requestid: this.state.requestid,
                    serviceName: "LOANPR",
  
                },
                {
                    headers: {
                        Authkey: process.env.REACT_APP_API_KEY,
                        "App-Token-Access": Decjwttoken,
                    },
                    auth: {
                        username: process.env.REACT_APP_API_USERNAME,
                        password: process.env.REACT_APP_API_PASSWORD,
                    },
                }
            )
            .then((res) => {
  
                if (res.data.code == 200 && res.data.status == "success") {
                  this.setState({ emailtime: 90 }); 
                    $("#otpsentsuccesstemp").show();
                    this.setState({ emailtime: 90 }, this.emailcountdown);
                } else if (res.data.code == 201 && res.data.status == "fail") {
                    this.showNotification(res.data.message);
                } else if (
                    res.data.code == "201" &&
                    res.data.status == "invalidauth"
                ) {
                    this.logout();
                }
            })
            .catch((err) => {
                // 
                this.showNotification("Something went wrong");
                // $(".sendOtpLoader").hide();
                //   $(".sendOtpBtn").show();
            });
    } else {
        return null;
    }
  };
  
  resendusermobOtp = () => {
    this.setState({ mobtime: 90 }); 
    $("#invalidtempusermobileotp").hide();
    $("#invalidtempuseremailotp").hide();
    $("#otpsentsuccesstemp").hide();
  
  
    
    
    if (this.state.mobtime == 0) {
            
        axios
            .post(
                `${baseUrl}p=ResendContactOTP`,
                {
                    agentId: Decagentid,
                    requestid: this.state.requestid,
                    serviceName: "LOANPR",
                   
                },
                {
                    headers: {
                        Authkey: process.env.REACT_APP_API_KEY,
                        "App-Token-Access": Decjwttoken,
                    },
                    auth: {
                        username: process.env.REACT_APP_API_USERNAME,
                        password: process.env.REACT_APP_API_PASSWORD,
                    },
                }
            )
            .then((res) => {
  
                if (res.data.code == 200 && res.data.status == "success") {
                  this.setState({ mobtime: 90 }); 
                    $("#otpsentsuccesstemp").show();
                    this.setState({ mobtime: 90 }, this.mobcountdown);
                } else if (res.data.code == 201 && res.data.status == "fail") {
                    this.showNotification(res.data.message);
                } else if (
                    res.data.code == "201" &&
                    res.data.status == "invalidauth"
                ) {
                    this.logout();
                }
            })
            .catch((err) => {
                // 
                this.showNotification("Something went wrong");
                // $(".sendOtpLoader").hide();
                //   $(".sendOtpBtn").show();
            });
    } else {
        return null;
    }
  };
  handlesupport = () => {
    var x = document.getElementById("myDIV");
    if (x.style.display == "block") {
      x.style.display = "none";
    }else {
      x.style.display = "block";
    }
  }

  render() {
    if (
      localStorage.getItem("gglltakoinoeenl") == process.env.REACT_APP_LOGIN_KEY &&
      localStorage.getItem("toeljgtkewlna") != null &&
      localStorage.getItem("qazxswedcvfrtgb") != null
    ) {

      $(".mobresendOTP").addClass("mob-text-muted");
      $(".emailresendOTP").addClass("email-text-muted");
      if ( this.state.mobtime == 0  ) {
          $(".mobresendOTP").removeClass("mob-text-muted");
      } 
      if(this.state.emailtime == 0 ){
        $(".emailresendOTP").removeClass("email-text-muted");
      }

      return (
        <div className="cont">
          <div id="notifContainer"></div>
          <Helmet>
            <link rel="stylesheet" href={process.env.PUBLIC_URL + "/css/select2new.css"} />
          </Helmet>

          <Header />

          <img
            src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
            className="sidebaropenicon"
          />
          <img
            src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
            onClick={this.backbutton}
            className="sidebarbackicon"
          />
          <img
            src={process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"}
            className="sidebarexpandicon"
          />

          <div className="overlay1"></div>
          <div className="sidebar sidebarshift">
            <div className="side_div_2 sidebar_txt active">
              <Link to="/dashboard">Overview</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <Link to="/myservices">My Services</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <a href="#">Payments Invoices</a>
            </div>
            <div className="side_div_1 sidebar_txt">
              <a href="#">Documents Record</a>
            </div>
            <div className="side_div_1 sidebar_txt">
              <a href="#">Add service</a>
            </div>
            <div></div>
          </div>
          <div className="arrow-left2 visibilityhidden"></div>
          <div className="white_div expandwhite_div">
            <div className="white_box">
              <div className="white_boxscrolldiv">
                <center>
                  {" "}
                  <p className="download">Add Service</p>
                </center>
                <div className="row addserivemaindiv">
                  <div className="col-md-7">
                    <div className="servicecol2">
                      <p className="serviceamainheading">Personal Loan</p>
                      <p className="serviceamainheadingtext">
                      Now manage your finances by availing Personal Loan easily with <br />
                      minimal documentation. Apply with Legal251 and stay stress-free.
                      </p>










                      <div id="formpricediv">
                        <div className="service1formdiv">
                          <div className="row">
                            <div className="col-md-6">
                              <center>
                              <select className="serviceinput" id="salaryselfe" onChange={this.handlesalaryselfechange} >
                                  <option value="Salaried" selected>Salaried</option>
                                  <option value="Self-Employed">Self-Employed</option>
                                </select>
                                <br /><br />
                                <input type="text" pattern="[A-Za-z ]{3,150}" id="name" className="serviceinput capatalizeMe" placeholder="  Name" value={this.state.servicefullname}
                                  maxLength="100"
                                  onChange={this.handleservicefullnameChange} required />
                                <br /><br />
                                <input type="email" maxLength="50" id="email" className="serviceinput" placeholder="  E-mail" value={this.state.serviceemail}
                                  onChange={this.handleserviceserviceemailChange} required />
                                <br /><br />
                                <input type="tel" pattern="[6-9]{1}[0-9]{9}" maxLength="10" id="contact" className="serviceinput" placeholder="  Contact no." value={this.state.servicemobileno}
                                  onChange={this.handleserviceservicemobilenoChange} required />
                                <br /><br />
                                <Select2 id="city"
                                  onChange={this.citydistrictselecthandle}
                                  value={this.state.citydistrictselectvalue}
                                  data={["Adilabad",
                                    "Agar Malwa",
                                    "Agra",
                                    "Ahmedabad",
                                    "Ahmednagar",
                                    "Aizawl",
                                    "Ajmer",
                                    "Akola",
                                    "Alappuzha",
                                    "Aligarh",
                                    "Alipurduar",
                                    "Alirajpur",
                                    "Allahabad",
                                    "Almora",
                                    "Alwar",
                                    "Ambala",
                                    "Ambedkar Nagar",
                                    "Amethi (Chatrapati Sahuji Mahraj Nagar)",
                                    "Amravati",
                                    "Amreli",
                                    "Amritsar",
                                    "Amroha",
                                    "Anand",
                                    "Anantapur",
                                    "Anantnag",
                                    "Angul",
                                    "Anjaw",
                                    "Anuppur",
                                    "Araria",
                                    "Aravalli",
                                    "Ariyalur",
                                    "Arwal",
                                    "Ashoknagar",
                                    "Auraiya",
                                    "Aurangabad (BH)",
                                    "Aurangabad (MH)",
                                    "Azamgarh",
                                    "Bagalkot",
                                    "Bageshwar",
                                    "Baghpat",
                                    "Bahraich",
                                    "Baksa",
                                    "Balaghat",
                                    "Balangir",
                                    "Balasore",
                                    "Ballari (Bellary)",
                                    "Ballia",
                                    "Balod",
                                    "Baloda Bazar",
                                    "Balrampur (CG)",
                                    "Balrampur (UP)",
                                    "Banaskantha (Palanpur)",
                                    "Banda",
                                    "Bandipore",
                                    "Banka",
                                    "Bankura",
                                    "Banswara",
                                    "Barabanki",
                                    "Baramulla",
                                    "Baran",
                                    "Bareilly",
                                    "Bargarh",
                                    "Barmer",
                                    "Barnala",
                                    "Barpeta",
                                    "Barwani",
                                    "Bastar",
                                    "Basti",
                                    "Bathinda",
                                    "Beed",
                                    "Begusarai",
                                    "Belagavi (Belgaum)",
                                    "Bemetara",
                                    "Bengaluru (Bangalore) Rural",
                                    "Bengaluru (Bangalore) Urban",
                                    "Betul",
                                    "Bhadohi",
                                    "Bhadradri Kothagudem",
                                    "Bhadrak",
                                    "Bhagalpur",
                                    "Bhandara",
                                    "Bharatpur",
                                    "Bharuch",
                                    "Bhavnagar",
                                    "Bhilwara",
                                    "Bhind",
                                    "Bhiwani",
                                    "Bhojpur",
                                    "Bhopal",
                                    "Bidar",
                                    "Bijapur",
                                    "Bijnor",
                                    "Bikaner",
                                    "Bilaspur (CG)",
                                    "Bilaspur (HP)",
                                    "Birbhum",
                                    "Bishnupur",
                                    "Biswanath",
                                    "Bokaro",
                                    "Bongaigaon",
                                    "Botad",
                                    "Boudh",
                                    "Budaun",
                                    "Budgam",
                                    "Bulandshahr",
                                    "Buldhana",
                                    "Bundi",
                                    "Burhanpur",
                                    "Buxar",
                                    "Cachar",
                                    "Central Delhi",
                                    "Chamarajanagar",
                                    "Chamba",
                                    "Chamoli",
                                    "Champawat",
                                    "Champhai",
                                    "Chandauli",
                                    "Chandel",
                                    "Chandigarh",
                                    "Chandrapur",
                                    "Changlang",
                                    "Charaideo",
                                    "Charkhi Dadri",
                                    "Chatra",
                                    "Chengalpattu",
                                    "Chennai",
                                    "Chhatarpur",
                                    "Chhindwara",
                                    "Chhota Udepur",
                                    "Chikballapur",
                                    "Chikkamagaluru (Chikmagalur)",
                                    "Chirang",
                                    "Chitradurga",
                                    "Chitrakoot",
                                    "Chittoor",
                                    "Chittorgarh",
                                    "Churachandpur",
                                    "Churu",
                                    "Coimbatore",
                                    "Cooch Behar",
                                    "Cuddalore",
                                    "Cuttack",
                                    "Dadra & Nagar Haveli",
                                    "Dahod",
                                    "Dakshin Dinajpur (South Dinajpur)",
                                    "Dakshina Kannada",
                                    "Daman",
                                    "Damoh",
                                    "Dangs (Ahwa)",
                                    "Dantewada (South Bastar)",
                                    "Darbhanga",
                                    "Darjeeling",
                                    "Darrang",
                                    "Datia",
                                    "Dausa",
                                    "Davangere",
                                    "Dehradun",
                                    "Deogarh",
                                    "Deoghar",
                                    "Deoria",
                                    "Devbhoomi Dwarka",
                                    "Dewas",
                                    "Dhalai",
                                    "Dhamtari",
                                    "Dhanbad",
                                    "Dhar",
                                    "Dharmapuri",
                                    "Dharwad",
                                    "Dhemaji",
                                    "Dhenkanal",
                                    "Dholpur",
                                    "Dhubri",
                                    "Dhule",
                                    "Dibang Valley",
                                    "Dibrugarh",
                                    "Dima Hasao (North Cachar Hills)",
                                    "Dimapur",
                                    "Dindigul",
                                    "Dindori",
                                    "Diu",
                                    "Doda",
                                    "Dumka",
                                    "Dungarpur",
                                    "Durg",
                                    "East Champaran (Motihari)",
                                    "East Delhi",
                                    "East Garo Hills",
                                    "East Godavari",
                                    "East Jaintia Hills",
                                    "East Kameng",
                                    "East Khasi Hills",
                                    "East Siang",
                                    "East Sikkim",
                                    "East Singhbhum",
                                    "Ernakulam",
                                    "Erode",
                                    "Etah",
                                    "Etawah",
                                    "Faizabad",
                                    "Faridabad",
                                    "Faridkot",
                                    "Farrukhabad",
                                    "Fatehabad",
                                    "Fatehgarh Sahib",
                                    "Fatehpur",
                                    "Fazilka",
                                    "Ferozepur",
                                    "Firozabad",
                                    "Gadag",
                                    "Gadchiroli",
                                    "Gajapati",
                                    "Ganderbal",
                                    "Gandhinagar",
                                    "Ganjam",
                                    "Garhwa",
                                    "Gariyaband",
                                    "Gautam Buddha Nagar",
                                    "Gaya",
                                    "Ghaziabad",
                                    "Ghazipur",
                                    "Gir Somnath",
                                    "Giridih",
                                    "Goalpara",
                                    "Godda",
                                    "Golaghat",
                                    "Gomati",
                                    "Gonda",
                                    "Gondia",
                                    "Gopalganj",
                                    "Gorakhpur",
                                    "Gumla",
                                    "Guna",
                                    "Guntur",
                                    "Gurdaspur",
                                    "Gurugram (Gurgaon)",
                                    "Gwalior",
                                    "Hailakandi",
                                    "Hamirpur (HP)",
                                    "Hamirpur (UP)",
                                    "Hanumangarh",
                                    "Hapur (Panchsheel Nagar)",
                                    "Harda",
                                    "Hardoi",
                                    "Haridwar",
                                    "Hassan",
                                    "Hathras",
                                    "Haveri",
                                    "Hazaribag",
                                    "Hingoli",
                                    "Hisar",
                                    "Hojai",
                                    "Hooghly",
                                    "Hoshangabad",
                                    "Hoshiarpur",
                                    "Howrah",
                                    "Hyderabad",
                                    "Idukki",
                                    "Imphal East",
                                    "Imphal West",
                                    "Indore",
                                    "Jabalpur",
                                    "Jagatsinghapur",
                                    "Jagtial",
                                    "Jaipur",
                                    "Jaisalmer",
                                    "Jajpur",
                                    "Jalandhar",
                                    "Jalaun",
                                    "Jalgaon",
                                    "Jalna",
                                    "Jalore",
                                    "Jalpaiguri",
                                    "Jammu",
                                    "Jamnagar",
                                    "Jamtara",
                                    "Jamui",
                                    "Jangaon",
                                    "Janjgir-Champa",
                                    "Jashpur",
                                    "Jaunpur",
                                    "Jayashankar Bhoopalpally",
                                    "Jehanabad",
                                    "Jhabua",
                                    "Jhajjar",
                                    "Jhalawar",
                                    "Jhansi",
                                    "Jhargram",
                                    "Jharsuguda",
                                    "Jhunjhunu",
                                    "Jind",
                                    "Jiribam",
                                    "Jodhpur",
                                    "Jogulamba Gadwal",
                                    "Jorhat",
                                    "Junagadh",
                                    "Kabirdham (Kawardha)",
                                    "Kachchh",
                                    "Kaimur (Bhabua)",
                                    "Kaithal",
                                    "Kakching",
                                    "Kalaburagi (Gulbarga)",
                                    "Kalahandi",
                                    "Kalimpong",
                                    "Kallakurichi",
                                    "Kamareddy",
                                    "Kamjong",
                                    "Kamle",
                                    "Kamrup",
                                    "Kamrup Metropolitan",
                                    "Kanchipuram",
                                    "Kandhamal",
                                    "Kangpokpi",
                                    "Kangra",
                                    "Kanker (North Bastar)",
                                    "Kannauj",
                                    "Kannur",
                                    "Kanpur Dehat",
                                    "Kanpur Nagar",
                                    "Kanshiram Nagar (Kasganj)",
                                    "Kanyakumari",
                                    "Kapurthala",
                                    "Karaikal",
                                    "Karauli",
                                    "Karbi Anglong",
                                    "Kargil",
                                    "Karimganj",
                                    "Karimnagar",
                                    "Karnal",
                                    "Karur",
                                    "Kasaragod",
                                    "Kathua",
                                    "Katihar",
                                    "Katni",
                                    "Kaushambi",
                                    "Kendrapara",
                                    "Kendujhar (Keonjhar)",
                                    "Khagaria",
                                    "Khammam",
                                    "Khandwa",
                                    "Khargone",
                                    "Kheda (Nadiad)",
                                    "Khordha",
                                    "Khowai",
                                    "Khunti",
                                    "Kinnaur",
                                    "Kiphire",
                                    "Kishanganj",
                                    "Kishtwar",
                                    "Kodagu",
                                    "Koderma",
                                    "Kohima",
                                    "Kokrajhar",
                                    "Kolar",
                                    "Kolasib",
                                    "Kolhapur",
                                    "Kolkata",
                                    "Kollam",
                                    "Komaram Bheem Asifabad",
                                    "Kondagaon",
                                    "Koppal",
                                    "Koraput",
                                    "Korba",
                                    "Korea (Koriya)",
                                    "Kota",
                                    "Kottayam",
                                    "Kozhikode",
                                    "Kra Daadi",
                                    "Krishna",
                                    "Krishnagiri",
                                    "Kulgam",
                                    "Kullu",
                                    "Kupwara",
                                    "Kurnool",
                                    "Kurukshetra",
                                    "Kurung Kumey",
                                    "Kushinagar (Padrauna)",
                                    "Lahaul & Spiti",
                                    "Lakhimpur",
                                    "Lakhimpur - Kheri",
                                    "Lakhisarai",
                                    "Lakshadweep",
                                    "Lalitpur",
                                    "Latehar",
                                    "Latur",
                                    "Lawngtlai",
                                    "Leh",
                                    "Lepa Rada",
                                    "Lohardaga",
                                    "Lohit",
                                    "Longding",
                                    "Longleng",
                                    "Lower Dibang Valley",
                                    "Lower Siang",
                                    "Lower Subansiri",
                                    "Lucknow",
                                    "Ludhiana",
                                    "Lunglei",
                                    "Madhepura",
                                    "Madhubani",
                                    "Madurai",
                                    "Mahabubabad",
                                    "Mahabubnagar",
                                    "Maharajganj",
                                    "Mahasamund",
                                    "Mahe",
                                    "Mahendragarh",
                                    "Mahisagar",
                                    "Mahoba",
                                    "Mainpuri",
                                    "Majuli",
                                    "Malappuram",
                                    "Malda",
                                    "Malkangiri",
                                    "Mamit",
                                    "Mancherial",
                                    "Mandi",
                                    "Mandla",
                                    "Mandsaur",
                                    "Mandya",
                                    "Mansa",
                                    "Mathura",
                                    "Mau",
                                    "Mayurbhanj",
                                    "Medak",
                                    "Medchal",
                                    "Meerut",
                                    "Mehsana",
                                    "Mirzapur",
                                    "Moga",
                                    "Mokokchung",
                                    "Mon",
                                    "Moradabad",
                                    "Morbi",
                                    "Morena",
                                    "Morigaon",
                                    "Muktsar",
                                    "Mumbai City",
                                    "Mumbai Suburban",
                                    "Mungeli",
                                    "Munger (Monghyr)",
                                    "Murshidabad",
                                    "Muzaffarnagar",
                                    "Muzaffarpur",
                                    "Mysuru (Mysore)",
                                    "Nabarangpur",
                                    "Nadia",
                                    "Nagaon",
                                    "Nagapattinam",
                                    "Nagarkurnool",
                                    "Nagaur",
                                    "Nagpur",
                                    "Nainital",
                                    "Nalanda",
                                    "Nalbari",
                                    "Nalgonda",
                                    "Namakkal",
                                    "Namsai",
                                    "Nanded",
                                    "Nandurbar",
                                    "Narayanpur",
                                    "Narmada (Rajpipla)",
                                    "Narsinghpur",
                                    "Nashik",
                                    "Navsari",
                                    "Nawada",
                                    "Nawanshahr (Shahid Bhagat Singh Nagar)",
                                    "Nayagarh",
                                    "Neemuch",
                                    "New Delhi",
                                    "Nicobar",
                                    "Nilgiris",
                                    "Nirmal",
                                    "Nizamabad",
                                    "Noney",
                                    "North 24 Parganas",
                                    "North Delhi",
                                    "North East Delhi",
                                    "North Garo Hills",
                                    "North Goa",
                                    "North Sikkim",
                                    "North Tripura",
                                    "North West Delhi",
                                    "North and Middle Andaman",
                                    "Nuapada",
                                    "Nuh",
                                    "Osmanabad",
                                    "Pakke Kessang",
                                    "Pakur",
                                    "Palakkad",
                                    "Palamu",
                                    "Palghar",
                                    "Pali",
                                    "Palwal",
                                    "Panchkula",
                                    "Panchmahal (Godhra)",
                                    "Panipat",
                                    "Panna",
                                    "Papum Pare",
                                    "Parbhani",
                                    "Paschim (West) Burdwan (Bardhaman)",
                                    "Paschim Medinipur (West Medinipur)",
                                    "Patan",
                                    "Pathanamthitta",
                                    "Pathankot",
                                    "Patiala",
                                    "Patna",
                                    "Pauri Garhwal",
                                    "Peddapalli",
                                    "Perambalur",
                                    "Peren",
                                    "Phek",
                                    "Pherzawl",
                                    "Pilibhit",
                                    "Pithoragarh",
                                    "Poonch",
                                    "Porbandar",
                                    "Prakasam",
                                    "Pratapgarh (RJ)",
                                    "Pratapgarh (UP)",
                                    "Puducherry",
                                    "Pudukkottai",
                                    "Pulwama",
                                    "Pune",
                                    "Purba Burdwan (Bardhaman)",
                                    "Purba Medinipur (East Medinipur)",
                                    "Puri",
                                    "Purnia (Purnea)",
                                    "Purulia",
                                    "RaeBareli",
                                    "Raichur",
                                    "Raigad",
                                    "Raigarh",
                                    "Raipur",
                                    "Raisen",
                                    "Rajanna Sircilla",
                                    "Rajgarh",
                                    "Rajkot",
                                    "Rajnandgaon",
                                    "Rajouri",
                                    "Rajsamand",
                                    "Ramanagara",
                                    "Ramanathapuram",
                                    "Ramban",
                                    "Ramgarh",
                                    "Rampur",
                                    "Ranchi",
                                    "Rangareddy",
                                    "Ranipet",
                                    "Ratlam",
                                    "Ratnagiri",
                                    "Rayagada",
                                    "Reasi",
                                    "Rewa",
                                    "Rewari",
                                    "Ri Bhoi",
                                    "Rohtak",
                                    "Rohtas",
                                    "Rudraprayag",
                                    "Rupnagar",
                                    "Sabarkantha (Himmatnagar)",
                                    "Sagar",
                                    "Saharanpur",
                                    "Saharsa",
                                    "Sahibganj",
                                    "Sahibzada Ajit Singh Nagar (Mohali)",
                                    "Saiha",
                                    "Salem",
                                    "Samastipur",
                                    "Samba",
                                    "Sambalpur",
                                    "Sambhal (Bhim Nagar)",
                                    "Sangareddy",
                                    "Sangli",
                                    "Sangrur",
                                    "Sant Kabir Nagar",
                                    "Saran",
                                    "Satara",
                                    "Satna",
                                    "Sawai Madhopur",
                                    "Sehore",
                                    "Senapati",
                                    "Seoni",
                                    "Sepahijala",
                                    "Seraikela-Kharsawan",
                                    "Serchhip",
                                    "Shahdara",
                                    "Shahdol",
                                    "Shahjahanpur",
                                    "Shajapur",
                                    "Shamali (Prabuddh Nagar)",
                                    "Sheikhpura",
                                    "Sheohar",
                                    "Sheopur",
                                    "Shi Yomi",
                                    "Shimla",
                                    "Shivamogga (Shimoga)",
                                    "Shivpuri",
                                    "Shopian",
                                    "Shravasti",
                                    "Siang",
                                    "Siddharth Nagar",
                                    "Siddipet",
                                    "Sidhi",
                                    "Sikar",
                                    "Simdega",
                                    "Sindhudurg",
                                    "Singrauli",
                                    "Sirmaur (Sirmour)",
                                    "Sirohi",
                                    "Sirsa",
                                    "Sitamarhi",
                                    "Sitapur",
                                    "Sivaganga",
                                    "Sivasagar",
                                    "Siwan",
                                    "Solan",
                                    "Solapur",
                                    "Sonbhadra",
                                    "Sonepur",
                                    "Sonipat",
                                    "Sonitpur",
                                    "South 24 Parganas",
                                    "South Andaman",
                                    "South Delhi",
                                    "South East Delhi",
                                    "South Garo Hills",
                                    "South Goa",
                                    "South Salamara-Mankachar",
                                    "South Sikkim",
                                    "South Tripura",
                                    "South West Delhi",
                                    "South West Garo Hills",
                                    "South West Khasi Hills",
                                    "Sri Ganganagar",
                                    "Sri Potti Sriramulu Nellore ",
                                    "Srikakulam",
                                    "Srinagar",
                                    "Sukma",
                                    "Sultanpur",
                                    "Sundargarh",
                                    "Supaul",
                                    "Surajpur",
                                    "Surat",
                                    "Surendranagar",
                                    "Surguja",
                                    "Suryapet",
                                    "Tamenglong",
                                    "Tapi (Vyara)",
                                    "Tarn Taran",
                                    "Tawang",
                                    "Tehri Garhwal",
                                    "Tengnoupal",
                                    "Tenkasi",
                                    "Thane",
                                    "Thanjavur",
                                    "Theni",
                                    "Thiruvananthapuram",
                                    "Thoothukudi (Tuticorin)",
                                    "Thoubal",
                                    "Thrissur",
                                    "Tikamgarh",
                                    "Tinsukia",
                                    "Tirap",
                                    "Tiruchirappalli",
                                    "Tirunelveli",
                                    "Tirupathur",
                                    "Tiruppur",
                                    "Tiruvallur",
                                    "Tiruvannamalai",
                                    "Tiruvarur",
                                    "Tonk",
                                    "Tuensang",
                                    "Tumakuru (Tumkur)",
                                    "Udaipur",
                                    "Udalguri",
                                    "Udham Singh Nagar",
                                    "Udhampur",
                                    "Udupi",
                                    "Ujjain",
                                    "Ukhrul",
                                    "Umaria",
                                    "Una",
                                    "Unakoti",
                                    "Unnao",
                                    "Upper Siang",
                                    "Upper Subansiri",
                                    "Uttar Dinajpur (North Dinajpur)",
                                    "Uttara Kannada (Karwar)",
                                    "Uttarkashi",
                                    "Vadodara",
                                    "Vaishali",
                                    "Valsad",
                                    "Varanasi",
                                    "Vellore",
                                    "Vidisha",
                                    "Vijayapura (Bijapur)",
                                    "Vikarabad",
                                    "Viluppuram",
                                    "Virudhunagar",
                                    "Visakhapatnam",
                                    "Vizianagaram",
                                    "Wanaparthy",
                                    "Warangal (Rural)",
                                    "Warangal (Urban)",
                                    "Wardha",
                                    "Washim",
                                    "Wayanad",
                                    "West Champaran",
                                    "West Delhi",
                                    "West Garo Hills",
                                    "West Godavari",
                                    "West Jaintia Hills",
                                    "West Kameng",
                                    "West Karbi Anglong",
                                    "West Khasi Hills",
                                    "West Siang",
                                    "West Sikkim",
                                    "West Singhbhum",
                                    "West Tripura",
                                    "Wokha",
                                    "YSR District, Kadapa (Cuddapah)",
                                    "Yadadri Bhuvanagiri",
                                    "Yadgir",
                                    "Yamunanagar",
                                    "Yanam",
                                    "Yavatmal",
                                    "Zunheboto"
                                  ]}
                                  options={{
                                    placeholder: "City / District"
                                  }}

                                />
                                <br /><br />
                                
                                <input type="date" id="dob1" className="serviceinput " placeholder="Name" value={this.state.dob}
                                  maxLength="100"
                                  onChange={this.handledobChange} required />
                                <br /><br />
                                <input type="tel" maxLength="10" id="Income2" className="serviceinput" placeholder="Net Monthly Income" value={this.state.Income}
                                  onChange={this.handleIncomeChange} required />
                                <br />

                                <select
                                  className="serviceinputselect2 sp-Margin"
                                  id="Salarytype3"
                                  value={this.state.Salarytype}
                                  onChange={this.handleSalarytypeChange}
                                >
                                  <option value="">Select Salary Mode</option>
                                  <option value="Cash">Cash</option>
                                  <option value="Cheque">Cheque</option>
                                  <option value="Bank Credited">Bank Credited</option>
                                </select>
                                <br /><br />
                                <input type="tel" maxLength="10" id="Loanamount4" className="serviceinput" placeholder="Loan Amount" value={this.state.Loanamount}
                                  onChange={this.handleLoanamountChange} required />
                                <br /><br />
                                <div className="errorsidediv" >
                                  <p className="errorside"><img src={process.env.PUBLIC_URL + "/img/Icon_feather-alert-circle.svg"} className="errorsideimg" />
                                    <span className="errorsidespan"></span>
                                  </p>
                                </div>
                                <br />

                                <button id="submit" onClick={this.createPurchaseReqNormal} className="proceednowbutton">Proceed
                                  now <img src={process.env.PUBLIC_URL + "/img/Path 4125.svg"} className="proceednowbuttonicon" /></button>
                                <img className="addserviceloader" src={process.env.PUBLIC_URL + "/img/loader.gif"} alt="" />
                              </center>
                            </div>
                            <div className="col-md-6">
                              <center>
                                <br />
                                <p className="serviceprice" >
                                  <sup className="servicepricesup" >@No Charges</sup>
                                </p>
                                <p className="servicepricelimited" >
                                  (*Limited time period offer)</p>
                                <br />
                                <img src={process.env.PUBLIC_URL + "/img/1 (37).png"} className="serviceillustration" />

                              </center>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                  </div>
                  <div className="col-md-5">
                    <img
                      src={process.env.PUBLIC_URL + "/img/trilustration.png"}
                      className="serviceilustration"
                    />
                  </div>

                </div>















              </div>
            </div>
          </div>
          <div id="myDIV">

                        <div className='supportDetails'>
                            <img
                                src={process.env.PUBLIC_URL + "/img/24-7_Support.png"}

                                alt="image"
                            />
                            <div>
                                <h5>{supportName}</h5>
                                <p>Agent Support Executive</p>
                                <span><b>Contact: </b> +91 {supportContact}</span>
                                <p className='mb-1'><b>Working Hours: </b> 11:00 AM - 06:30 PM</p>
                            </div>
                        </div>


                    </div>
                    <button className="support" onClick={this.handlesupport} >
                        <img src={process.env.PUBLIC_URL + "/img/headPhone.svg"} width="50%" height="50px" />
                    </button>

          <Footer />


          <div
            className="modal fade"
            id="Temp-User"

          >
            <div
              className="modal-dialog modal-dialog-centered "
              role="document"
            >
              <div
                className="modal-content back-modal"
                id="agentuserinfomodal"
              >
                <div className="w-100 d-flex">
                  <button
                    type="button"
                    className="border-0 bg-transparent ml-auto p-2 pr-3  text-right my-back-modal-close"
                    data-dismiss="modal"
                    onClick={() => {
                      this.setState({ rtime: 1 });
                      $("#invalidagentuserinfoinput").hide();
                    }}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/img/Page-1.svg"}
                      width="17px"
                    />
                  </button>
                </div>
                <div className="text-center">
                  <h4 className="my-linkmodal-text enterotp">Enter OTP</h4>
                </div>
                <div className="input-userinfo otpinput emailotp">
                  <h5 className="otpinput-head">
                    Enter OTP sent to user's mobile
                  </h5>
                  <input
                    className="Userinfo-input otp-input"
                    maxLength="6"
                    type="tel"
                    value={this.state.mobileotp}
                    onChange={(e) =>
                      this.setState({ mobileotp: e.target.value })
                    }
                    id="userinfoinput"
                    placeholder="Enter OTP here..."
                  />
                  <p
                    className=" mt-2 warn align-items-center m-0"
                    id="invalidtempusermobileotp"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/Icon_feather-alert-circle.svg"
                      }
                      width="15px"
                      className=" mr-1"
                    />{" "}
                    <span className="invalidmobile"></span>
                  </p>
                </div>
                <div className="d-flex justify-content-between  otpmodaloptions">
                    <h6
                       className="mob-text-muted mobresendOTP"
                       id=""
                       onClick={this.resendusermobOtp}
                       >
                      Resend OTP  {this.state.mobtime != 0 ? `(${this.state.mobtime} Sec)` : " "}
                     </h6>
                 </div>
                <div className="input-userinfo otpinput emailotp">
                  <h5 className="otpinput-head">
                    Enter OTP sent to user's email
                  </h5>
                  <input
                    className="Userinfo-input otp-input "
                    maxLength="6"
                    type="tel"
                    value={this.state.emailotp}
                    onChange={(e) =>
                      this.setState({ emailotp: e.target.value })
                    }
                    id="userinfoinput"
                    placeholder="Enter OTP here..."
                  />
                  <p
                    className=" mt-2 warn align-items-center m-0"
                    id="invalidtempuseremailotp"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/Icon_feather-alert-circle.svg"
                      }
                      width="15px"
                      className=" mr-1"
                    />{" "}
                    <span className="invalidemail"></span>
                  </p>
                </div>

                <div className="d-flex justify-content-between  otpmodaloptions">
                   <h6
                    className="email-text-muted emailresendOTP"
                    id=""
                    onClick={this.resenduseremailOtp}
                     >
                    Resend OTP  {this.state.emailtime != 0 ? `(${this.state.emailtime} Sec)` : " "}
                  </h6>
                </div>
                <p className="" id="otpsentsuccesstemp">
                  OTP sent successfully{" "}
                </p>
                <div className="modal-footer border-0 justify-content-center mb-4">
                  <img
                    className=" p-2 pl-3 pr-3 sendOtpLoader"
                    src="/img/loader.gif"
                    width="80px"
                    alt=""
                  />
                  <button
                    type="button"
                    onClick={this.SubmitOTP}
                    className="btn  send-btn text-white submitOtpbtn"
                    id="submitOtpbtn"
                  >
                    Submit
                  </button>
                </div>



              </div>


            </div>
          </div>
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default Addservicegstr;

import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $, { timers } from "jquery";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import SupportDetails from "./SupportDetails";
import SupportDetailsMobile from "./SupportDetailsMobile";

var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);
let Decuserid;
let Decjwttoken;
let Decagentid;
let Decserviceid;
let Decservicename;
let Decplan;
let temp = "general";
let supportName;
let supportContact;
let supportEmail;
var sharedocumentarray = [
  {
    documentname: "",
    link: "",
  },
];
export class CompleteTr extends Component {
  constructor(props) {
    super(props);

    this.state = {
      PANCardLink: "#",
      AadharLink: "#",
      MsmeLink: "#",
      ProofOfTMLink: "#",
      BrandLogoLink: "#",
      serviceType: "",
      serviceTypeText: "",
      businessNature: "",
      brandName_radio: "",
      Date: "",
      trademarkWord: "",
      trademark_radio: "",
      deliveredDocuments: [],
      additionalArray1: [],
      commentHistroyarray: [],
      sharewhatsapp_radio: "Personal",
      shareemail_radio: "Legal251",
      whatsppcontactno: "",
      Selectedwhatsapptimeperiod: "select",
      Selectedemailtimeperiod: "select",
      customwhatsappdate: "",
      customemaildate: "",
      sharedocumentname: "",
      sharedocumentlink: "",
      emailmail: "",
      Timeline: [],
      Timelinedata: false,
      timelcount: 2,
      filtertimel: [],
      Name:"",
      Contact:"",
      serviceId:""
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    $(document.body).css("background-color", "#2d2d2d");
    const script = document.createElement("script");
    script.src = "/js/main.js";
    script.async = true;
    document.body.appendChild(script);
    let mainuserid = localStorage.getItem("relleIsugad");
    Decuserid = encryptor.decrypt(mainuserid);
    let mainagentid = localStorage.getItem("qazxswedcvfrtgb");
    Decagentid = encryptor.decrypt(mainagentid);
    let mainjwttoken = localStorage.getItem("toeljgtkewlna");
    Decjwttoken = encryptor.decrypt(mainjwttoken);
    let mainserviceid = localStorage.getItem("vrellvaiecstiadeceig");
    let mainaservicename = localStorage.getItem("eieltaiclsraaemevcvnge");
    let mainplan = localStorage.getItem("ipacneglatvlael");
    // Decserviceid = encryptor.decrypt(mainserviceid);
    // Decservicename = encryptor.decrypt(mainaservicename);
    // Decplan = encryptor.decrypt(mainplan);

    supportName = localStorage.getItem("supportName");
    supportContact = localStorage.getItem("supportContact");
    supportEmail = localStorage.getItem("supportEmail");

    this.setState({
      supportName: supportName,
      supportContact: supportContact,
      supportEmail: supportEmail,
    })
    // ----------------------------temporary -----------------------------------------


    let temptoken = localStorage.getItem("fdgshdjfkgjhyhdnxhsgdhskcj")
    let Dectemptoken = encryptor.decrypt(temptoken)
    let paramsUserId2 = this.props.match.params.id2;
    if (Dectemptoken != null && Dectemptoken == paramsUserId2) {
      temp = "temporary"
    } else {
      temp = "general"
    }

    // ----------------------------temporary -----------------------------------------
    if (mainserviceid != null && mainaservicename != null && mainplan != null) {
      let paramsId = this.props.match.params.id;
      let paramsUserId = this.props.match.params.id2;
      let completedstatus = localStorage.getItem("pojasdsdjisjddd");
      Decserviceid = encryptor.decrypt(mainserviceid);
      Decservicename = encryptor.decrypt(mainaservicename);
      Decplan = encryptor.decrypt(mainplan);
      let Deccompletedstatus = encryptor.decrypt(completedstatus);
      this.getnameanumber();
      this.setState({
        serviceId: Decserviceid,
      });
      if (Deccompletedstatus == "Success") {
        this.getserviceInformationApi();
      } else {
        $(".mainsectionuploadsection").hide();
        $(".closedservicesection").show();
      }
      if (paramsId != Decserviceid || paramsUserId != Decuserid) {
        this.props.history.push("/dashboard");
      }
    } else {
      this.props.history.push("/dashboard");
    }



    let str = document.getElementsByClassName("myservicesortbyfilterbystatus").innerHTML = this.props.location.pathname.replaceAll("/", " > ")
    let mainstr = document.getElementsByClassName("myservicesortbyfilterbystatus").innerHTML = str.replace(" > ", " ")
    $(".myservicesortbyfilterbystatus").html(mainstr)
 
  }

  getnameanumber = () => {
    axios.post(
      process.env.REACT_APP_API_BASE_URL + "p=empexecutiveDetails",
      {
        agentId: Decagentid,
        serviceName: Decservicename,
        serviceId: Decserviceid, 
      },
      {
        headers: {
          Authkey: process.env.REACT_APP_API_KEY,
          "App-Token-Access": Decjwttoken,
        },
        auth: {
          username: process.env.REACT_APP_API_USERNAME,
          password: process.env.REACT_APP_API_PASSWORD,
        },
      }
    )
    .then((res) => {
      if (res.data.code == "200" && res.data.status == "success") {
        this.setState({
          Name: res.data.teamLeaderName,
            Contact: res.data.teamLeaderNumber,
        })
      } else if (res.data.code == "201" && res.data.status == "invalidauth") {
        this.logout();
      } else {
          this.showNotification(res.data.message);
        } 
    })
  }

  getserviceInformationApi = () => {
    $(".serviceoverlay").show();
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=completedDetails",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          plan: Decplan,
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        $(".serviceoverlay").hide();
        if (res.data.code == 200 && res.data.status == "success") {
          if (res.data.serviceType == "ISP") {
            this.setState({
              serviceType: res.data.serviceType,
              serviceTypeText: "Individual / Sole Proprietor",
              deliveredDocuments: res.data.deliveredDocuments,
            });
          } else if (res.data.serviceType == "CPG") {
            this.setState({
              serviceType: res.data.serviceType,
              serviceTypeText: "Company / Proprietorship / Group of Individual",
              deliveredDocuments: res.data.deliveredDocuments,
            });
          } else if (res.data.serviceType == "MS") {
            this.setState({
              serviceType: res.data.serviceType,
              serviceTypeText: "MSME / Startup",
              deliveredDocuments: res.data.deliveredDocuments,
            });
          }

          if (res.data.informationGiven.documents.length > 0) {
            let doc = res.data.informationGiven.documents;
            if (doc[0].status == "done") {
              if (doc[0].PANCard == null) {
                $("#PANCard").hide();
              } else {
                $("#PANCard").show();
                this.setState({
                  PANCardLink: doc[0].PANCard,
                });
              }
            } else {
              $("#PANCard").hide();
            }
            if (doc[1].status == "done") {
              if (doc[1].AddressProof == null) {
                $("#Aadhar").hide();
              } else {
                $("#Aadhar").show();
                this.setState({
                  AadharLink: doc[1].AddressProof,
                });
              }
            } else {
              $("#Aadhar").hide();
            }
            if (doc[2].status == "done") {
              if (doc[2].MsmeStartupCertificate == null) {
                $("#Msme").hide();
              } else {
                $("#Msme").show();
                this.setState({
                  MsmeLink: doc[2].MsmeStartupCertificate,
                });
              }
            } else {
              $("#Msme").hide();
            }
            if (doc[3].status == "done") {
              if (doc[3].ProofOfTM == null) {
                $("#ProofOfTM").hide();
              } else {
                $("#ProofOfTM").show();
                this.setState({
                  ProofOfTMLink: doc[3].ProofOfTM,
                });
              }
            } else {
              $("#ProofOfTM").hide();
            }
            if (doc[4].status == "done") {
              if (doc[4].BrandLogo == null) {
                $("#BrandLogo").hide();
              } else {
                $("#BrandLogo").show();
                this.setState({
                  BrandLogoLink: doc[4].BrandLogo,
                });
              }
            } else {
              $("#BrandLogo").hide();
            }
          }

          if (res.data.informationGiven.inputFields.length > 0) {
            let doc = res.data.informationGiven.inputFields;
            this.setState(
              {
                businessNature: doc[0].Nature,
                brandName_radio: doc[1].PreviousTM,
                Date: doc[2].TMUseDate,
                trademark_radio: doc[3].WantToTrademark,
                trademarkWord: doc[4].BrandName,
              },
              () => {
                if (this.state.brandName_radio == "No") {
                  $("#Date").hide();
                  $("#ProofOfTM").hide();
                } else {
                  $("#Date").show();
                  $("#ProofOfTM").show();
                }

                if (this.state.trademark_radio == "Word") {
                  $("#BrandLogo").hide();
                } else {
                  $("#BrandLogo").show();
                }
              }
            );
          }

          if (res.data.informationGiven.addDocInformation.length > 0) {
            $("#additionalDocumentDiv").show();
            this.setState({
              additionalArray1: [
                ...res.data.informationGiven.addDocInformation,
              ],
            });
          } else {
            $("#additionalDocumentDiv").hide();
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        }
      })
      .catch((err) => {
        $(".serviceoverlay").hide();
      });
  };
  handlewhatsppcontactno = (e) => {
    this.setState({
      whatsppcontactno: e.target.value,
    });
  };
  handleemailmail = (e) => {
    this.setState({
      emailmail: e.target.value,
    });
  };

  handlecustomwhatsappdateChange = (e) => {
    this.setState({
      customwhatsappdate: e.target.value,
    });
  };

  handlecustomemaildateChange = (e) => {
    this.setState({
      customemaildate: e.target.value,
    });
  };

  handlewhatsapptimeperiodChange = (e) => {
    this.setState({
      Selectedwhatsapptimeperiod: e.target.value,
    });
    if (e.target.value == "custom") {
      $("#whatsappdateinput").show();
    } else if (e.target.value == "always") {
      $("#whatsappdateinput").hide();
      $(".sharedocmentwhatsapplifeerrormessage").show();
    } else {
      $("#whatsappdateinput").hide();
      $(".sharedocmentwhatsapplifeerrormessage").hide();
    }
  };
  handleemailtimeperiodChange = (e) => {
    $(".sharedocmentemaildateerrormessage").hide();
    this.setState({
      Selectedemailtimeperiod: e.target.value,
    });
    if (e.target.value == "custom") {
      $("#emaildateinput").show();
    } else if (e.target.value == "always") {
      $("#emaildateinput").hide();
      $(".sharedocmentemaillifeerrormessage").show();
    } else {
      $("#emaildateinput").hide();
      $(".sharedocmentemaillifeerrormessage").hide();
    }
  };

  whatsapp = (docname, link) => {
    $(".myAddModal1").modal({ backdrop: "static", keyboard: false });
    this.setState({
      sharedocumentname: docname,
      sharedocumentlink: link,
    });
    sharedocumentarray[0].documentname = docname;
    sharedocumentarray[0].link = link;
  };

  email = (docname, link) => {
    $(".myAddModal2").modal({ backdrop: "static", keyboard: false });
    this.setState({
      sharedocumentname: docname,
      sharedocumentlink: link,
    });
    sharedocumentarray[0].documentname = docname;
    sharedocumentarray[0].link = link;
  };

  submitemail = () => {
    let ToDate = new Date();
    if (this.state.emailmail.trim() === "") {
      $(".sharedocmentemailerrormessage").show();
      $(".sharedocmentemailerrormessage").html("Please enter  E-mail");
    } else if (this.state.emailmail.trim().length > 50) {
      $(".sharedocmentemailerrormessage").show();
      $(".sharedocmentemailerrormessage").html(
        "E-mail should be less than 50 characters"
      );
    } else if (
      !this.state.emailmail
        .trim()
        .match(/^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i)
    ) {
      $(".sharedocmentemailerrormessage").show();
      $(".sharedocmentemailerrormessage").html("Please enter  valid E-mail");
    } else if (this.state.Selectedemailtimeperiod == "select") {
      $(".sharedocmentemailerrormessage").hide();
      $(".sharedocmentemailselecterrormessage").show();
      $(".sharedocmentemailselecterrormessage").html("Please select a option");
    } else if (
      this.state.Selectedemailtimeperiod == "custom" &&
      this.state.customemaildate == ""
    ) {
      $(".sharedocmentemailerrormessage").hide();
      $(".sharedocmentemailselecterrormessage").hide();
      $(".sharedocmentemaildateerrormessage").html("Please enter date");
      $(".sharedocmentemaildateerrormessage").show();
    } else if (
      this.state.Selectedemailtimeperiod == "custom" &&
      new Date(this.state.customemaildate).getTime() < ToDate.getTime()
    ) {
      $(".sharedocmentemailerrormessage").hide();
      $(".sharedocmentemailselecterrormessage").hide();
      $(".sharedocmentemaildateerrormessage").html("Please enter a vaild date");
      $(".sharedocmentemaildateerrormessage").show();
    } else {
      $(".upload_btn20_70").hide();
      $(".upload_btnloader_70").show();
      $(".cancelbtn_70").attr("disabled", true);
      var localemaildate = this.state.customemaildate;
      if (
        this.state.Selectedemailtimeperiod != "custom" &&
        this.state.customemaildate != ""
      ) {
        localemaildate = "";
      }

      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=sharedocumentEmailNormal",
          {
            userId: Decuserid,
            requestType: temp,
            agentId: Decagentid,
            emailto: this.state.emailmail.trim(),
            duration: this.state.Selectedemailtimeperiod,
            custom: localemaildate,
            type: this.state.shareemail_radio,
            shareableDocument: sharedocumentarray,
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((resp) => {
          $(".upload_btn20_70").show();
          $(".upload_btnloader_70").hide();
          $(".cancelbtn_70").attr("disabled", false);
          $(".myAddModal2").modal("hide");
          $(".sharedocmentemaillifeerrormessage").hide();
          $(".sharedocmentemailerrormessage").hide();
          $(".sharedocmentemailselecterrormessage").hide();
          $(".sharedocmentemaildateerrormessage").hide();
          var localemailmail = this.state.emailmail;
          var localradio = this.state.shareemail_radio;
          this.setState({
            Selectedemailtimeperiod: "select",
            emailmail: "",
            shareemail_radio: "Legal251",
            customemaildate: "",
          });
          $("#emaildateinput").hide();
          if (resp.data.code == 200 && resp.data.status == "success") {
            if (localradio == "Personal") {
              window.open(
                `mailto:${localemailmail}?subject=Shared%20Documents%20-%20Legal251&body=Hi%20There%20!!%20I%20am%20sharing%20following%20documents%20with%20you%20via%20my%20Legal251%20Panel:-%0a${resp.data.documentLinks[0].documentname}%0a${resp.data.documentLinks[0].link}`,
                "_blank"
              );
            } else {
              $(".myAddModal3").modal({ backdrop: "static", keyboard: false });
              setTimeout(function () {
                $(".myAddModal3").modal("hide");
              }, 3000);
            }
          } else if (
            resp.data.code == "201" &&
            resp.data.status == "invalidauth"
          ) {
            this.logout();
          }
          else if (resp.data.code == "201" && resp.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          }
          else {
            this.showNotification(resp.data.message);
          }
        })
        .catch((err) => {
          this.showNotification("Something went wrong, Try again!");
        });
    }
  };

  submitwhatsapp = () => {
    let ToDate = new Date();
    if (this.state.whatsppcontactno.trim() === "") {
      $(".sharedocmentwhatsapperrormessage").show();
      $(".sharedocmentwhatsapperrormessage").html("Please enter  Mobile No");
    } else if (!this.state.whatsppcontactno.trim().match(/^[6-9]\d{9}$/)) {
      $(".sharedocmentwhatsapperrormessage").show();
      $(".sharedocmentwhatsapperrormessage").html(
        "Please enter  valid Mobile No"
      );
    } else if (this.state.Selectedwhatsapptimeperiod == "select") {
      $(".sharedocmentwhatsapperrormessage").hide();
      $(".sharedocmentwhatsappselecterrormessage").show();
      $(".sharedocmentwhatsappselecterrormessage").html(
        "Please select a option"
      );
    } else if (
      this.state.Selectedwhatsapptimeperiod == "custom" &&
      this.state.customwhatsappdate == ""
    ) {
      $(".sharedocmentwhatsapperrormessage").hide();
      $(".sharedocmentwhatsappselecterrormessage").hide();
      $(".sharedocmentwhatsappdateerrormessage").html("Please enter date");
      $(".sharedocmentwhatsappdateerrormessage").show();
    } else if (
      this.state.Selectedwhatsapptimeperiod == "custom" &&
      new Date(this.state.customwhatsappdate).getTime() < ToDate.getTime()
    ) {
      $(".sharedocmentwhatsapperrormessage").hide();
      $(".sharedocmentwhatsappselecterrormessage").hide();
      $(".sharedocmentwhatsappdateerrormessage").html(
        "Please a enter vaild date"
      );
      $(".sharedocmentwhatsappdateerrormessage").show();
    } else {
      $(".upload_btn20_90").hide();
      $(".upload_btnloader_90").show();
      $(".cancelbtn_90").attr("disabled", true);
      var localwhatsappdate = this.state.customwhatsappdate;
      if (
        this.state.Selectedwhatsapptimeperiod != "custom" &&
        this.state.customwhatsappdate != ""
      ) {
        localwhatsappdate = "";
      }
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=sharedocumentLinkNormal",
          {
            userId: Decuserid,
            requestType: temp,
            agentId: Decagentid,
            duration: this.state.Selectedwhatsapptimeperiod,
            custom: localwhatsappdate,
            shareableDocument: sharedocumentarray,
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((resp) => {
          $(".upload_btn20_90").show();
          $(".upload_btnloader_90").hide();
          $(".cancelbtn_90").attr("disabled", false);
          $(".myAddModal1").modal("hide");
          $(".sharedocmentwhatsapperrormessage").hide();
          $(".sharedocmentwhatsapplifeerrormessage").hide();
          $(".sharedocmentwhatsappselecterrormessage").hide();
          $(".sharedocmentwhatsappdateerrormessage").hide();
          var localwhatsppcontactno = this.state.whatsppcontactno;
          this.setState({
            Selectedwhatsapptimeperiod: "select",
            customemaildate: "",
            whatsppcontactno: "",
          });
          $("#whatsappdateinput").hide();

          if (resp.data.code == 200 && resp.data.status == "success") {
            window.open(
              `https://web.whatsapp.com/send?phone=91${localwhatsppcontactno}&text=Hi%20There%20!!%20I%20am%20sharing%20following%20documents%20with%20you%20via%20my%20Legal251%20Panel:-%0a${resp.data.documentLinks[0].documentname}%0a${resp.data.documentLinks[0].link}`,
              "_blank"
            );
          } else if (
            resp.data.code == "201" &&
            resp.data.status == "invalidauth"
          ) {
            this.logout();
          }
          else if (resp.data.code == "201" && resp.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          }
          else {
            this.showNotification(resp.data.message);
          }
        })
        .catch((err) => {
          this.showNotification("Something went wrong, Try again!");
        });
    }
  };

  sharewhatsapp_radioChange = (e) => {
    this.setState({
      sharewhatsapp_radio: e.currentTarget.value,
    });
  };
  shareemail_radioChange = (e) => {
    this.setState({
      shareemail_radio: e.currentTarget.value,
    });
  };

  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };

  renderCommenthistory() {
    if (this.state.commentHistroyarray.length > 0) {
      return this.state.commentHistroyarray.map((activedoc, index) => {
        if (activedoc.commentBy == "user" && activedoc.special == "no") {
          return (
            <div className="rightconvtextdiv" key={index}>
              <p className="rightconvtext">{activedoc.comment}</p>
              <p className="rightconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "user" &&
          activedoc.special == "green"
        ) {
          return (
            <div className="rightconvtextdiv" key={index}>
              <p className="rightgreenconvtext">{activedoc.comment}</p>
              <p className="rightconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "employee" &&
          activedoc.special == "no"
        ) {
          return (
            <div className="leftconvtextdiv" key={index}>
              <p className="leftconvtext">{activedoc.comment}</p>
              <p className="leftconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "user" &&
          activedoc.special == "red"
        ) {
          return (
            <div className="rightunappconvtextdiv" key={index}>
              <p className="rightunappconvtext">{activedoc.comment}</p>
              <p className="rightconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "employee" &&
          activedoc.special == "green"
        ) {
          return (
            <div className="leftgreenconvtextdiv" key={index}>
              <p className="leftgreenconvtext">{activedoc.comment}</p>
              <p className="leftconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "employee" &&
          activedoc.special == "red"
        ) {
          return (
            <div className="leftconvtextdiv" key={index}>
              <p className="leftunappconvtext">{activedoc.comment}</p>
              <p className="leftconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        }
      });
    } else if (this.state.commentHistroyarray.length == 0) {
      return <p className="convservicename">No comments available!</p>;
    }
  }

  logout = () => {
    localStorage.removeItem("gglltakoinoeenl");
    localStorage.removeItem("toeljgtkewlna");
    localStorage.removeItem("qazxswedcvfrtgb");
    localStorage.removeItem("lshhihfnkff");
    this.props.history.push({
      pathname: `/`,
    });
  };

  handlePANCardseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PANCardseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PANCard",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PANCardseebtn").hide();
          $("#PANCardhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePANCardhidecommentsbtn = () => {
    $("#PANCardseebtn").show();
    $("#PANCardseebtn").attr("disabled", false);
    $("#PANCardhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleAadharseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#Aadharseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "AddressProof",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#Aadharseebtn").hide();
          $("#Aadharhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleAadharhidecommentsbtn = () => {
    $("#Aadharseebtn").show();
    $("#Aadharseebtn").attr("disabled", false);
    $("#Aadharhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleMsmeseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#Msmeseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "MsmeStartupCertificate",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#Msmeseebtn").hide();
          $("#Msmehidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleMsmehidecommentsbtn = () => {
    $("#Msmeseebtn").show();
    $("#Msmeseebtn").attr("disabled", false);
    $("#Msmehidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleProofOfTMseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#ProofOfTMseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ProofOfTM",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#ProofOfTMseebtn").hide();
          $("#ProofOfTMhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleProofOfTMhidecommentsbtn = () => {
    $("#ProofOfTMseebtn").show();
    $("#ProofOfTMseebtn").attr("disabled", false);
    $("#ProofOfTMhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleBrandLogoseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#BrandLogoseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "BrandLogo",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#BrandLogoseebtn").hide();
          $("#BrandLogohidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleBrandLogohidecommentsbtn = () => {
    $("#BrandLogoseebtn").show();
    $("#BrandLogoseebtn").attr("disabled", false);
    $("#BrandLogohidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  AdditionalFilesArea = () => {
    if (this.state.additionalArray1.length > 0) {
      return this.state.additionalArray1.map((file, index) => {
        let NameOfAddFile = Object.keys(file)[0];
        let link = file[Object.keys(file)[0]];
        return (
          <div className="displayflex" key={index}>
            <div className="uploadnocol">
              <p className="uploadadditionalsidetext">{index + 1}&#41;</p>
            </div>
            <div className="uploadadditionaldatacol">
              <div className="row mb-2">
                <div className="col-md-7">
                  <input
                    type="text"
                    className="uploadtextfield marginzero"
                    value={NameOfAddFile}
                    readOnly
                  />
                </div>
                <div className="col-md-5">
                  <div className="float-md-right float-left">
                    <div className="displayflex">
                      <div className="viewbtndivpadding">
                        <a className="viewbtn" href={link} target="_blank">
                          View{" "}
                          <img
                            src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                          />
                        </a>
                      </div>
                      <div>
                        <button className="tick_btn">
                          {" "}
                          <img
                            alt="loading..."
                            src={process.env.PUBLIC_URL + "/img/tick.svg"}
                          />
                        </button>
                      </div>
                    </div>
                    <button
                      className="seecommentshistorybtn commentsbtn Fileseecommentsbtn1"
                      id={`${index}Fileseecommentsbtn1`}
                      onClick={() => this.handleFileseeComments1(index)}
                    >
                      See comment history{" "}
                      <img
                        src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                      />
                    </button>
                    <button
                      className="hidecommentshistorybtn Filehidecommentsbtn1"
                      id={`${index}Filehidecommentsbtn1`}
                      onClick={() => this.handleFilehideComments1(index)}
                    >
                      Hide comment history{" "}
                      <img
                        src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else {
      $("#additionalDocumentDiv").hide();
    }
  };

  handleFileseeComments1 = (index) => {
    $(".commentsbtn").attr("disabled", false);
    $(`#${index}Fileseecommentsbtn1`).attr("disabled", true);
    let SelectedFile = this.state.additionalArray1[index];
    let NameOfAddFile = Object.keys(SelectedFile)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "additional",
          documentName: NameOfAddFile,
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(`#${index}Fileseecommentsbtn1`).hide();
          $(".hidecommentshistorybtn").hide();
          $(`#${index}Filehidecommentsbtn1`).show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          $(`#${index}Fileseecommentsbtn1`).attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $(`#${index}Fileseecommentsbtn1`).attr("disabled", false);
        this.showNotification("Something Went wrong");
      });
  };

  handleFilehideComments1 = (index) => {
    $(".commentsbtn").show();
    $(`#${index}Fileseecommentsbtn1`).show();
    $(`#${index}Fileseecommentsbtn1`).attr("disabled", false);
    $(`#${index}Filehidecommentsbtn1`).hide();
    $(".hidecommentshistorybtn  ").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  showDeliveredDocuments() {
    if (this.state.deliveredDocuments.length > 0) {
      return this.state.deliveredDocuments.map((activedoc, index) => {
        return (
          <div className="displayflex documentmaincard" key={index}>
            <div>
              <img
                src={process.env.PUBLIC_URL + "/img/Path 247.svg"}
                className="docutypeimg"
              />
            </div>
            <div className="docucontdiv">
              <div className="row">
                <div className="col-md-6">
                  <p className="nameofdoc">{activedoc.documentName}</p>
                  <p className="datesenttext">
                    Date received:-{" "}
                    <span className="datesentspan">{activedoc.datesent}</span>
                  </p>
                </div>
                <div className="col-md-6">
                  <div className="fl_right">
                    <div className="displayflex">
                      <div className="viewbtndivpadding">
                        <a
                          href={activedoc.documentLink}
                          target="_blank"
                          className="viewbtn"
                        >
                          View{" "}
                          <img
                            src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                          />
                        </a>
                      </div>
                      <div>
                        <a
                          href={activedoc.documentLink}
                          className="download_btn"
                          target="_blank"
                          download
                        >
                          Download{" "}
                          <img
                            src={
                              process.env.PUBLIC_URL + "/img/download_icon.svg"
                            }
                            alt="download"
                          />
                        </a>
                      </div>
                    </div>
                    <p className="docushareviatext">
                      Share via:{" "}
                      <a
                        onClick={() =>
                          this.whatsapp(
                            activedoc.documentName,
                            activedoc.documentLink
                          )
                        }
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/whatsapp.svg"}
                          className="docushareviawhats"
                        />
                      </a>{" "}
                      <a
                        onClick={() =>
                          this.email(
                            activedoc.documentName,
                            activedoc.documentLink
                          )
                        }
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/mail.svg"}
                          className="docushareviamail"
                        />
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.deliveredDocuments.length == 0) {
      return (
        <center className="myservicequiteimgcenter">
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
            className="emptydeliveredDocuments"
          />
        </center>
      );
    }
  }

  backbutton = () => {
    this.props.history.push({
      pathname: `/myuser/${Decuserid}/userservices`,
    });
  };


  handletimeline = () => {


    $("#timeline_btnloader").show();
    axios.post(
      process.env.REACT_APP_API_BASE_URL + "p=timeLine",
      {
        agentId: Decagentid,
        userId: Decuserid,
        serviceId: Decserviceid,
      },
      {
        headers: {
          Authkey: process.env.REACT_APP_API_KEY,
          "App-Token-Access": Decjwttoken,
        },
        auth: {
          username: process.env.REACT_APP_API_USERNAME,
          password: process.env.REACT_APP_API_PASSWORD,
        },
      }
    )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {

          if (res.data.timeLine.length > 0) {
            let length = res.data.timeLine.length;
            let ftimeline = res.data.timeLine.filter((items, index) => {
              return (index <= this.state.timelcount)
            })
            this.setState({
              filtertimel: ftimeline,
              timelcount: this.state.timelcount + 3,
              Timeline: res.data.timeLine,
              Timelinedata: true,
            });
            this.setState({

            });
            // .splice(this.state.timelcount,length)
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
  }

  handlemoretimeline = () => {

    let ftimeline = this.state.Timeline.filter((items, index) => {
      return (index <= this.state.timelcount)
    })
    this.setState({
      filtertimel: ftimeline,
      timelcount: this.state.timelcount + 3
    });

  }


  render() {
    if (
      localStorage.getItem("gglltakoinoeenl") ==
      process.env.REACT_APP_LOGIN_KEY &&
      localStorage.getItem("toeljgtkewlna") != null &&
      localStorage.getItem("qazxswedcvfrtgb") != null
    ) {
      return (
        <div className="cont">
          <div id="notifContainer"></div>

          <Helmet>
            <link
              rel="stylesheet"
              href={process.env.PUBLIC_URL + "/css/select2.css"}
            />
          </Helmet>

          <Header />

          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
            className="sidebaropenicon"
          />
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
            onClick={this.backbutton}
            className="sidebarbackicon"
          />
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"}
            className="sidebarexpandicon"
          />

          <div className="overlay1"></div>
          <div className="sidebar sidebarshift">
            <div className="side_div_2 sidebar_txt active">
              <Link to="/dashboard">Overview</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <Link to="/myservices">My Services</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <a href="#">Payments Invoices</a>
            </div>
            <div className="side_div_1 sidebar_txt">
              <a href="#">Documents Record</a>
            </div>
            <div className="side_div_1 sidebar_txt">
              <a href="#">Add service</a>
            </div>
            <div></div>
          </div>
          <div className="arrow-left2 visibilityhidden"></div>
          <div className="white_div expandwhite_div">
            <div className="serviceoverlay">
              <div className="serviceoverlayinside">
                <center>
                  <img
                    src={process.env.PUBLIC_URL + "/img/loader.gif"}
                    className="serviceloader"
                  />
                </center>
              </div>
            </div>
            <div className="white_box">
              <div className="uploaddocu">
                <div className="row">
                  <div className="myservicesortbyfilterbystatus mt-1 ml-1">

                  </div>
                  <div className="col-lg-9 mt-5">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="inner-header d-flex justify-content-between">
                          <div className="slider-navigation-tab">
                            <header className="tabs-nav position-relative">
                              <ul className="d-flex slider-navigation-tab-ul">
                                <li
                                  className="active slider-navigation-tab-li"
                                  id="activeTab"
                                >
                                  <a href="#tab1" className="px-2 py-1">
                                    Information given
                                  </a>
                                </li>
                                <li
                                  className="slider-navigation-tab-li"
                                  id="completedTab"
                                >
                                  <a href="#tab2" className="px-2 py-1">
                                    Deliverables
                                  </a>
                                </li>
                                <hr className="slider-navigation-tab-vertical-line" />
                                <div className="sliderpage-slider"></div>
                              </ul>
                            </header>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tabs-content">
                  <div id="tab1" className="tab-content">
                    <div className="row">
                      <div className="col-lg-9">
                      <div className="mainsectionuploadsection">
                        <div className="tr-main-content">
                          <div className="uploadscrolldiv">
                            <div className="uploadscrollcardinside">
                              <div className="displayflex" id="PANCard">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        PAN Card
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.PANCardLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PANCardseebtn"
                                          onClick={
                                            this.handlePANCardseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PANCardhidecommentsbtn"
                                          onClick={
                                            this.handlePANCardhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="displayflex" id="Aadhar">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Aadhaar Card / Driving Licence / Voter
                                        ID
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.AadharLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="Aadharseebtn"
                                          onClick={
                                            this.handleAadharseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="Aadharhidecommentsbtn"
                                          onClick={
                                            this.handleAadharhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="displayflex">
                                <div className="uploadnocol ">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploadspecialdatacol">
                                  <div className="displayflex flex-md-row flex-column">
                                    <div>
                                      <p className="uploadnormaltext">
                                        Type of application:-
                                      </p>
                                    </div>
                                    <div>
                                      <input
                                        type="text"
                                        className="lockeduploadtextfiels"
                                        value={this.state.serviceTypeText}
                                        disabled
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="Msme">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        MSME / Startup recognition certificate
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.MsmeLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="Msmeseebtn"
                                          onClick={
                                            this.handleMsmeseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="Msmehidecommentsbtn"
                                          onClick={
                                            this.handleMsmehidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <p className="uploadnormaltext">
                                    Nature of your Business / Brand:-
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <textarea
                                    className="uploadtextarea"
                                    value={this.state.businessNature}
                                    disabled
                                  ></textarea>
                                </div>
                              </div>

                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <p className="uploadnormaltext">
                                    Are you already using your Brand Name /
                                    Logo?
                                    <sup className="requireddocstar">*</sup>
                                  </p>

                                  <input
                                    type="radio"
                                    id="test1"
                                    name="radio-group"
                                    value="Yes"
                                    checked={
                                      this.state.brandName_radio === "Yes"
                                    }
                                    disabled
                                  />
                                  <label
                                    htmlFor="test2"
                                    className="uploadradiotext"
                                  >
                                    YES
                                  </label>
                                  <br />
                                  <input
                                    type="radio"
                                    id="test2"
                                    name="radio-group"
                                    value="No"
                                    checked={
                                      this.state.brandName_radio === "No"
                                    }
                                    disabled
                                  />
                                  <label
                                    htmlFor="test3"
                                    className="uploadradiotext"
                                  >
                                    NO
                                  </label>
                                </div>
                              </div>

                              <div className="displayflex" id="Date">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <p className="uploadnormaltext">
                                    Date from which you're using your Brand
                                    Name/Logo?
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <input
                                    type="date"
                                    placeholder="Type here..."
                                    className="uploaddatefield"
                                    value={this.state.Date}
                                    disabled
                                  />
                                </div>
                              </div>

                              <div className="displayflex" id="ProofOfTM">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Proof of trademark use:-
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.ProofOfTMLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="ProofOfTMseebtn"
                                          onClick={
                                            this.handleProofOfTMseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="ProofOfTMhidecommentsbtn"
                                          onClick={
                                            this.handleProofOfTMhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <p className="uploadnormaltext">
                                    What do you want to trademark?
                                    <sup className="requireddocstar">*</sup>
                                  </p>

                                  <input
                                    type="radio"
                                    id="test4"
                                    name="radio-group2"
                                    value="Word"
                                    checked={
                                      this.state.trademark_radio === "Word"
                                    }
                                    disabled
                                  />
                                  <label
                                    htmlFor="test4"
                                    className="uploadradiotext"
                                  >
                                    Word
                                  </label>
                                  <br />
                                  <input
                                    type="radio"
                                    id="test5"
                                    name="radio-group2"
                                    value="Logo"
                                    checked={
                                      this.state.trademark_radio === "Logo"
                                    }
                                    disabled
                                  />
                                  <label
                                    htmlFor="test5"
                                    className="uploadradiotext"
                                  >
                                    Logo
                                  </label>
                                </div>
                              </div>

                              <div className="displayflex trademark-word">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol border-change">
                                  <p className="uploadnormaltext">
                                    Trademark word?
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <input
                                    type="text"
                                    placeholder="Type here..."
                                    className="uploadtextfield"
                                    id="uploadtextfield_trademarkWord"
                                    value={this.state.trademarkWord}
                                    disabled
                                  />
                                </div>
                              </div>

                              <div
                                className="displayflex lastdisplayflex"
                                id="BrandLogo"
                              >
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploadlastdatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Trademark logo?
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.BrandLogoLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="BrandLogoseebtn"
                                          onClick={
                                            this.handleBrandLogoseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="BrandLogohidecommentsbtn"
                                          onClick={
                                            this.handleBrandLogohidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="displayflex"
                                id="additionalDocumentDiv"
                              >
                                <div className="uploadadditionaldatacol">
                                  <p className="uploadadditionalnormaltext">
                                    Additional documents:-
                                  </p>
                                  {this.AdditionalFilesArea()}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>
                        <div className="closedservicesection">
                        <center>
                        <br/>
                          <br/>
                          <br/>
                          <p className="closedstatustext"><span>Service ID</span>:- {this.state.serviceId}</p>
                          <br/>
                          <p className="closedstatusdesc">
                            Dear customer, your service is marked as
                            completed/closed because of following status.
                          </p>
                          <p  className="closedstatustext"><span>Status</span>:- Unapproved</p>
                          <img
                            src={process.env.PUBLIC_URL + "/img/warning.png"}
                            className="warningerrorimg"
                            alt="image"
                          />
                          <br/>    <br/>
                          <p className="closedstatusdesc2">
                            Please contact your allocated support executive or
                            call on 77728-77729 for support.
                          </p>
                          </center>
                        </div>
                      </div>

                      <div className="col-lg-3 colbtwborder">
                        <div className="commentimg-section2">
                          <SupportDetails name={this.state.Name} role={"Relationship Manager"} contact={this.state.Contact} />
                          {supportName != "" && supportContact != "" ?

                            <div className='supportDetails'>
                              <img
                                src={process.env.PUBLIC_URL + "/img/24-7_Support.png"}

                                alt="image"
                              />
                              <div>
                                <h5>{supportName}</h5>
                                <p>Agent Support Executive</p>
                                <span><b>Contact: </b> +91 {supportContact}</span>
                                <p className='mb-1'><b>Working Hours: </b> 11:00 AM - 06:30 PM</p>
                              </div>
                            </div>

                            : null}
                          <div className="mm">
                            <center>
                              <a
                                className="video-btn"
                                data-toggle="modal"
                                data-src="https://www.youtube.com/embed/fiC-nHoFJEY"
                                data-target="#youtubeModal"
                              >
                                <img
                                  src={process.env.PUBLIC_URL + "/img/play.jpg"}
                                  id="play"
                                  className="play_img play_img2"
                                  alt="image"
                                />
                              </a>
                            </center>
                          </div>

                          <p class="uploadinstr mt-2 uploddoc">You can also upload documents using Whatsapp on <a
                                  href="https://web.whatsapp.com/send?phone=917772877729&amp;text=Hello%20Team%20Legal251!"
                                  target="_blank"
                                >
                                  {" "}
                                  <img
                                    src={process.env.PUBLIC_URL + "/img/call.svg"}
                                    alt="call"
                                    className="callimg"
                                  />
                                </a> +91 77728-77729</p>
                        </div>
                        <div className="TDRightSide">
                          <div className="convheader2">
                            <div>
                              <img
                                src={process.env.PUBLIC_URL + "/img/person.png"}
                                className="converpersonimg"
                              />
                            </div>
                            <div className="convheadercontdiv">
                              <p className="convservicename">{this.state.Name}</p>
                              <p className="convpersonname">Relationship Manager</p>
                              <p className="convuniquename">
                                Contact: <span style={{ color: "#2d2d2d" }}>{this.state.Contact}</span></p>
                              <p className="convuniquename">
                                Service ID : <span className="convuniquenamespan">{Decserviceid}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="convmaindiv">
                            {this.renderCommenthistory()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="tab2" className="tab-content">
                    <div className="doucumainscrolldiv2">
                      <div className="doucumainscrolldivinside2">
                        {this.showDeliveredDocuments()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SupportDetailsMobile name={this.state.Name} role={"Relationship Manager"} contact={this.state.Contact} />

          <Footer />
          <div className="modal fade"
            id="timeline"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true">

            <div
              className="modal-dialog modal-dialog-centered yt_modal "
              role="document"
            >
              <div className="modal-content videoModal">
                <div className="modal-body modal_b mt-4 ">
                  <button
                    type="button"
                    className="close close1 closeMain mr-2 dbtime"
                    data-dismiss="modal"
                    aria-label="Close"

                  >
                    <span aria-hidden="true">&times;</span>
                  </button>

                  {this.state.Timelinedata ?


                    this.state.Timeline.length > 0 ?

                      <div class="timeline scrollbtimel">
                        <div class="container">
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="timeline-container">
                                <div class="timeline-end">
                                  <button id="load-more" onClick={this.handlemoretimeline} >More..</button>
                                </div>
                                <div class="timeline-continue">

                                  {this.state.filtertimel.map((item, index) => {

                                    if (index % 2 == 0) {
                                      return (

                                        <div class="row timeline-left">
                                          <div class="col-md-6 d-md-none d-block">
                                            <p class="timeline-date">

                                            </p>
                                          </div>
                                          <div class="col-md-6">
                                            <div class="timeline-box">
                                              <div class="timeline-icon d-md-none d-block">
                                                <i class="fa fa-business-time"></i>
                                              </div>

                                              <div class="timeline-text">
                                                <p class="mainSpan">{item.time}</p>
                                                <h3>{item.timeLine}</h3>
                                                <p class="mode_p">Date : <span class="mode_span"> {item.date}</span></p>
                                                <h5 className="tlremark">{item.remark}</h5>
                                                {/* <p class="mode_p">
                                                      <span class="mode_span">
                                                          {{$TimeLine->TimeLine}}
                                                      </span>
                                                  </p>  */}


                                              </div>

                                              <div class="timeline-icon d-md-block d-none">
                                                <i class="fa fa-business-time"></i>
                                              </div>

                                            </div>
                                          </div>
                                          <div class="col-md-6 d-md-block d-none">
                                            <p class="timeline-date">

                                            </p>
                                          </div>
                                        </div>

                                      )
                                    } else {
                                      return (
                                        <div class="row timeline-right">
                                          <div class="col-md-6">
                                            <p class="timeline-date">
                                            </p>
                                          </div>
                                          <div class="col-md-6">
                                            <div class="timeline-box">
                                              <div class="timeline-icon">
                                                <i class="fa fa-gift"></i>
                                              </div>

                                              <div class="timeline-text">
                                                <p class="mainSpan">{item.time}</p>
                                                {/* <p class="">khkh</p> */}

                                                <h3>{item.timeLine}</h3>
                                                <p class="mode_p">Date : <span class="mode_span">{item.date} </span></p>
                                                <h5 className="tlremark">{item.remark}</h5>
                                                {/* <p class="mode_p">
                                                                              <span class="mode_span">
                                                                                  
                                                                              </span>
                                                                          </p> */}


                                              </div>

                                            </div>
                                          </div>
                                        </div>
                                      )
                                    }




                                  })}




                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      <center className="">
                        <img
                          alt="loading..."
                          src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
                          className="emptydeliveredDocuments"
                        />
                      </center>

                    :
                    <center>
                      <img
                        className="timelineloder"
                        src={process.env.PUBLIC_URL + "/img/loader.gif"}
                        alt="loading"
                      />
                    </center>


                  }

                </div>
              </div>
            </div>

          </div>
          <div className="modal fade myAddModal1">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-body uploadpopupbody">
                  <p className="sharepopupheading">
                    Please enter the recipient's mobile no.
                  </p>
                  <div className="sharepopupinputdiv">
                    <img
                      src={process.env.PUBLIC_URL + "/img/whatsapp.svg"}
                      className="docushareviawhatspopup"
                    />
                    <input
                      type="text"
                      className="sharepopupinput"
                      maxLength="10"
                      placeholder="Type here..."
                      value={this.state.whatsppcontactno}
                      onChange={this.handlewhatsppcontactno}
                    />
                    <p className="sharedocmentwhatsapperrormessage">
                      error message
                    </p>
                  </div>
                  <p className="sharepopupheading text-left">Share via:</p>
                  {/* <input
                    type="radio"
                    id="test30"
                    name="radio-group03"
                    value="Yes"
                    checked={this.state.sharewhatsapp_radio === "Yes"}
                    onChange={this.sharewhatsapp_radioChange}
                  />
                  <label htmlFor="test30" className="uploadradiotext">
                    Legal251
                  </label> */}

                  <input
                    type="radio"
                    id="test31"
                    name="radio-group03"
                    value="Personal"
                    checked={this.state.sharewhatsapp_radio === "Personal"}
                    onChange={this.sharewhatsapp_radioChange}
                  />
                  <label htmlFor="test31" className="uploadradiotext ">
                    Personal Mobile no.
                  </label>
                  <p className="sharepopupheading text-left">Share for:</p>
                  <select
                    className="selectimeperiod"
                    value={this.state.Selectedwhatsapptimeperiod}
                    onChange={this.handlewhatsapptimeperiodChange}
                  >
                    <option value="select" disabled>
                      select time period
                    </option>
                    <option value="1hr">share for 1 hour</option>
                    <option value="1day">share for 1 day</option>
                    <option value="3days">share for 3 day</option>
                    <option value="1week">share for 1 week</option>
                    <option value="1month">share for 1 month</option>
                    <option value="custom">custom</option>
                    <option value="always">lifetime</option>
                  </select>
                  <p className="sharedocmentwhatsapplifeerrormessage">
                    We don't recommend you to share documents for lifetime for
                    security reasons Instead you can select a custom time
                    period.
                  </p>
                  <p className="sharedocmentwhatsappselecterrormessage">
                    error message
                  </p>
                  <input
                    type="date"
                    id="whatsappdateinput"
                    className="othertextinput"
                    value={this.state.customwhatsappdate}
                    onChange={this.handlecustomwhatsappdateChange}
                    placeholder="Type here..."
                  />
                  <p className="sharedocmentwhatsappdateerrormessage">
                    error message
                  </p>
                  <br />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn cancelbtn_90"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20 upload_btn20_90"
                        onClick={this.submitwhatsapp}
                      >
                        Send&nbsp;
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_19481.svg"}
                          alt="download"
                        />
                      </button>
                      <button className="upload_btnloader upload_btnloader_90">
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade myAddModal2">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-body uploadpopupbody">
                  <p className="sharepopupheading">
                    Please enter the recipient's email ID
                  </p>
                  <div className="sharepopupinputdiv">
                    <img
                      src={process.env.PUBLIC_URL + "/img/Group_4339.svg"}
                      className="docushareviawhatspopup"
                    />
                    <input
                      type="text"
                      className="sharepopupinput"
                      maxLength="50"
                      placeholder="Type here..."
                      value={this.state.emailmail}
                      onChange={this.handleemailmail}
                    />
                    <p className="sharedocmentemailerrormessage">
                      error message
                    </p>
                  </div>
                  <p className="sharepopupheading text-left">Share via:</p>
                  <input
                    type="radio"
                    id="test40"
                    name="radio-group200"
                    value="Legal251"
                    checked={this.state.shareemail_radio === "Legal251"}
                    onChange={this.shareemail_radioChange}
                  />
                  <label htmlFor="test40" className="uploadradiotext">
                    Legal251
                  </label>

                  <input
                    type="radio"
                    id="test41"
                    name="radio-group200"
                    value="Personal"
                    checked={this.state.shareemail_radio === "Personal"}
                    onChange={this.shareemail_radioChange}
                  />
                  <label
                    htmlFor="test41"
                    className="uploadradiotext rightradio"
                  >
                    Personal Mail
                  </label>
                  <p className="sharepopupheading text-left">Share for:</p>
                  <select
                    className="selectimeperiod"
                    value={this.state.Selectedemailtimeperiod}
                    onChange={this.handleemailtimeperiodChange}
                  >
                    <option value="select" disabled>
                      select time period
                    </option>
                    <option value="1hr">share for 1 hour</option>
                    <option value="1day">share for 1 day</option>
                    <option value="3days">share for 3 day</option>
                    <option value="1week">share for 1 week</option>
                    <option value="1month">share for 1 month</option>
                    <option value="custom">custom</option>
                    <option value="always">lifetime</option>
                  </select>
                  <p className="sharedocmentemaillifeerrormessage">
                    We don't recommend you to share documents for lifetime for
                    security reasons Instead you can select a custom time
                    period.
                  </p>
                  <p className="sharedocmentemailselecterrormessage">
                    error message
                  </p>
                  <input
                    type="date"
                    id="emaildateinput"
                    className="othertextinput"
                    value={this.state.customemaildate}
                    onChange={this.handlecustomemaildateChange}
                    placeholder="Type here..."
                  />
                  <p className="sharedocmentemaildateerrormessage">
                    error message
                  </p>
                  <br />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn cancelbtn_70"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20 upload_btn20_70"
                        onClick={this.submitemail}
                      >
                        Send&nbsp;
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_19481.svg"}
                          alt="download"
                        />
                      </button>
                      <button className="upload_btnloader upload_btnloader_70">
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade myAddModal3 ">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-body uploadpopupbody">
                  <center>
                    <p className="sharesucesstext">Sent Sucessfully</p>
                    <img
                      src={process.env.PUBLIC_URL + "/img/Path_19474.svg"}
                      className="sharesucessimg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="youtubeModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered yt_modal "
              role="document"
            >
              <div className="modal-content">
                <div className="modal-body modal_b">
                  <button
                    type="button"
                    className="close close1"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      className="embed-responsive-item"
                      id="video"
                      allowscriptaccess="always"
                      src="https://www.youtube.com/embed/fiC-nHoFJEY?&amp;modestbranding=1&amp;showinfo=0"
                      allowFullScreen="allowfullscreen"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}
export default CompleteTr;

import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import Sidebar from "./Sidebar";
import AllServices from "../AllServices.json"

let baseUrl = process.env.REACT_APP_API_BASE_URL;
var activeserviceGSTR = [];
var activeserviceGSTRF = [];
var activeserviceITRF = [];
var activeserviceTR = [];
var activeserviceOA = [];
var activeserviceGUMASTA = [];
var activeserviceTradeLicence = [];
var activeserviceFoodLicence = [];
var completeserviceGSTR = [];
var completeserviceGSTRF = [];
var completeserviceITRF = [];
var completeserviceTR = [];
var completeserviceOA = [];
var completeserviceGUMASTA = [];
var completeserviceTradeLicence = [];
var completeserviceFoodLicence = [];
let Decuserid;
let Decagentid;
let Decjwttoken;
let Deccontact;
let Decemail;
let supportName;
let supportContact;
let supportEmail;
let temp = "general";
var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);

class MyServices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeservicemainarray: [],
      completedservice: [],
      activeservicestate: "Latest",
      completeservicestate: "Latest",
      userid: "",
      email: "",
      contact: "",
      username: "",
      mobileotp: "",
      emailotp: "",
      rtime: 90,
      serviceid: "",
      servicename: "",
      servicetype: "active",
      myplan: "",
    };
  }

  componentDidMount() {


    let str = document.getElementsByClassName("myservicesortbyfilterbystatus").innerHTML = this.props.location.pathname.replaceAll("/", " > ")
    let mainstr = document.getElementsByClassName("myservicesortbyfilterbystatus").innerHTML = str.replace(" > ", " ")
    $(".myservicesortbyfilterbystatus").html(mainstr)

    window.scrollTo(0, 0);
    activeserviceGSTR = [];
    activeserviceGSTRF = [];
    activeserviceITRF = [];
    activeserviceTR = [];
    activeserviceOA = [];
    activeserviceGUMASTA = [];
    activeserviceTradeLicence = [];
    activeserviceFoodLicence = [];
    completeserviceGSTR = [];
    completeserviceGSTRF = [];
    completeserviceITRF = [];
    completeserviceTR = [];
    completeserviceOA = [];
    completeserviceGUMASTA = [];
    completeserviceTradeLicence = [];
    completeserviceFoodLicence = [];
    $(".sendUnlockOtpLoader").hide();
    $("#sendUnlockOtp").show();
    $("#submitunlockOtpbtn").show();

    $(document.body).css("background-color", "#2d2d2d");
    let mainuserid = localStorage.getItem("relleIsugad");
    Decuserid = encryptor.decrypt(mainuserid);
    let mainagentid = localStorage.getItem("qazxswedcvfrtgb");
    Decagentid = encryptor.decrypt(mainagentid);
    let mainjwttoken = localStorage.getItem("toeljgtkewlna");
    Decjwttoken = encryptor.decrypt(mainjwttoken);
    supportName = localStorage.getItem("supportName");
    supportContact = localStorage.getItem("supportContact");
    supportEmail = localStorage.getItem("supportEmail");

    // let mainemail = localStorage.getItem("email");
    // Decemail = encryptor.decrypt(mainemail);
    // let maincontact = localStorage.getItem("contact");
    // Deccontact = encryptor.decrypt(maincontact);
    // ----------------------------temporary -----------------------------------------


    let temptoken = localStorage.getItem("fdgshdjfkgjhyhdnxhsgdhskcj")
    let Dectemptoken = encryptor.decrypt(temptoken)
    let paramsUserId2 = this.props.match.params.id2;
    if (Dectemptoken != null && Dectemptoken == paramsUserId2) {
      temp = "temporary"
      $(".useropt__rn__addBtn").hide()
    } else {
      temp = "general"
      $(".useropt__rn__addBtn").show()
    }

    // ----------------------------temporary -----------------------------------------
    let paramsUserId = this.props.match.params.id2;
    if (paramsUserId != Decuserid) {
      this.props.history.push("/dashboard");
    } else {
      this.handleMyservicesapi();
    }
    window.addEventListener("click", function (event) {
      if (event.target.className != "search-box") {
        $(".search-box").removeClass("search-focus");
      }
    });

    const script = document.createElement("script");
    script.src = "/js/main.js";
    script.async = true;
    document.body.appendChild(script);
  }
  countdown() {
    this.setState({ rtime: 90 });
    $("#mobileresend-loader").hide();
    function clear() {
      clearInterval(interval);
      $("#mobileresend-loader").hide();
    }
    let interval = setInterval(() => {
      if (this.state.rtime == 1) {
        clear(interval);
        $("#mobileresend-loader").hide();
      } else {
      }
      this.setState({ rtime: this.state.rtime - 1 });
    }, 1000);
  }
  handleSearchBox = (e) => {
    e.stopPropagation();
    $(".search-box").addClass("search-focus");
  };
  logout = () => {
    localStorage.removeItem("gglltakoinoeenl");
    localStorage.removeItem("toeljgtkewlna");
    localStorage.removeItem("qazxswedcvfrtgb");
    localStorage.removeItem("lshhihfnkff");
    this.props.history.push({
      pathname: `/`,
    });
  };

  addServices = () => {
    this.props.history.push({
      pathname: `/addservice/${Decuserid}/addservice`, state: { name: this.state.username }
    });
  };

  handleMyservicesapi = () => {
    $(".myseriveloaderdiv").show();
    $(".header-btn").hide();
    axios({
      method: "post",
      url: process.env.REACT_APP_API_BASE_URL + "p=userServices",
      data: {
        userId: Decuserid,
        requestType: temp,
        agentId: Decagentid,
      },
      headers: {
        Authkey: process.env.REACT_APP_API_KEY,
        "App-Token-Access": Decjwttoken,
      },
      auth: {
        username: process.env.REACT_APP_API_USERNAME,
        password: process.env.REACT_APP_API_PASSWORD,
      },
    })
      .then((resp) => {

        $(".myseriveloaderdiv").hide();
        $(".header-btn").show();
        if (resp.data.code == 200 && resp.data.status == "success") {

          var myArray = resp.data.activeServices.filter(function (obj) {
            return obj.serviceName !== "EXEC";
          });
          var myArray2 = resp.data.completedServices.filter(function (obj) {
            return obj.serviceName !== "EXEC";
          });

          this.setState(
            {
              activeservicemainarray: myArray,
              completedservice: myArray2,
              userid: resp.data.userId,
              email: resp.data.email,
              contact: resp.data.contact,
              username: resp.data.username,
            },
            () => {
              if (
                this.state.activeservicemainarray.length == 0 ||
                this.state.completedservice.length == 0
              ) {
                $(".myservicequiteimgstart").show();
              }
            }
          );
        } else if (
          resp.data.code == "201" &&
          resp.data.status == "invalidauth"
        ) {
          this.logout();
        } else if (resp.data.code == "201" && resp.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.showNotification(resp.data.message)
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (resp.data.code == "203" && resp.data.status == "notfound") {
          this.setState(
            {
              activeservicemainarray: [],
              completedservice: [],
            },
            () => {
              if (
                this.state.activeservicemainarray.length == 0 ||
                this.state.completedservice.length == 0
              ) {
                $(".myservicequiteimgstart").show();
              }
            }
          );
        } else {
          this.showNotification(resp.data.message);
        }
      })
      .catch((error) => {
        $(".myseriveloaderdiv").hide();
      });
  };

  myServiceslatestsort = () => {
    this.setState({
      activeservicestate: "Latest",
      completeservicestate: "Latest",
    });
    //  
    // $(".myservicesortbyfilterbystatus").html("Latest to Oldest");
  };
  myServicesLowestsort = () => {
    this.setState({
      activeservicestate: "Lowest",
      completeservicestate: "Lowest",
    });
    //  
    // $(".myservicesortbyfilterbystatus").html("Oldest to Latest");
  };
  Checkplan = (plan) => {
    if (plan == "S") {
      return <>Starter</>;
    } else if (plan == "B") {
      return <>Basic</>;
    } else if (plan == "P") {
      return <>Pro</>;
    } else if (plan == "PR") {
      return <>Premium </>;
    } else if (plan == "PP") {
      return <>Pro+</>;
    } else if (plan == null) {
      return <>-</>;
    }
  };
  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };
  myServicesGSTRfilter = () => {
    this.setState(
      {
        activeservicestate: "GSTR",
        completeservicestate: "GSTR",
      },
      () => {
        if (activeserviceGSTR.length == 0 || completeserviceGSTR.length == 0) {
          $(".myservicequiteimgstart").show();
        }
        //  
        // $(".myservicesortbyfilterbystatus").html("GSTR");
      }
    );
  };
  myServicesGSTRFfilter = () => {
    this.setState(
      {
        activeservicestate: "GSTRF",
        completeservicestate: "GSTRF",
      },
      () => {
        if (
          activeserviceGSTRF.length == 0 ||
          completeserviceGSTRF.length == 0
        ) {
          $(".myservicequiteimgstart").show();
        }
        //  
        // $(".myservicesortbyfilterbystatus").html("GSTRF");
      }
    );
  };
  myServicesITRFfilter = () => {
    this.setState(
      {
        activeservicestate: "ITRF",
        completeservicestate: "ITRF",
      },
      () => {
        if (activeserviceITRF.length == 0 || completeserviceITRF.length == 0) {
          $(".myservicequiteimgstart").show();
        }
        //  
        // $(".myservicesortbyfilterbystatus").html("ITRF");
      }
    );
  };
  myServicesTRfilter = () => {
    this.setState(
      {
        activeservicestate: "TR",
        completeservicestate: "TR",
      },
      () => {
        if (activeserviceTR.length == 0 || completeserviceTR.length == 0) {
          $(".myservicequiteimgstart").show();
        }
        //  
        // $(".myservicesortbyfilterbystatus").html("TR");
      }
    );
  };
  myServicesOAfilter = () => {
    this.setState(
      {
        activeservicestate: "OA",
        completeservicestate: "OA",
      },
      () => {
        if (activeserviceOA.length == 0 || completeserviceOA.length == 0) {
          $(".myservicequiteimgstart").show();
        }
        //  
        // $(".myservicesortbyfilterbystatus").html("TR");
      }
    );
  };

  myServicesGUMASTAfilter = () => {
    this.setState(
      {
        activeservicestate: "GUMASTA",
        completeservicestate: "GUMASTA",
      },
      () => {
        if (activeserviceGUMASTA.length == 0 || completeserviceGUMASTA.length == 0) {
          $(".myservicequiteimgstart").show();
        }
        //  
        // $(".myservicesortbyfilterbystatus").html("TR");
      }
    );
  };

  myServicesTradeLicencefilter = () => {
    this.setState(
      {
        activeservicestate: "TL",
        completeservicestate: "TL",
      },
      () => {
        if (activeserviceTradeLicence.length == 0 || completeserviceTradeLicence.length == 0) {
          $(".myservicequiteimgstart").show();
        }
        //  
        // $(".myservicesortbyfilterbystatus").html("TR");
      }
    );
  };

  myServicesFoodLicencefilter = () => {
    this.setState(
      {
        activeservicestate: "FL",
        completeservicestate: "FL",
      },
      () => {
        if (activeserviceFoodLicence.length == 0 || completeserviceFoodLicence.length == 0) {
          $(".myservicequiteimgstart").show();
        }
        //  
        // $(".myservicesortbyfilterbystatus").html("TR");
      }
    );
  };

  handlemyserviceclick = (serviceId, serviceName, plan, type) => {
    this.setState({ servicetype: "active" });
    this.setState({ myplan: plan });
    if (type == "lock") {
      this.setState({ serviceid: serviceId, servicename: serviceName });
      $("#unlockotp").show();
      $("#unlockotpmodal").hide();
      $("#Unlock-Service").modal("show");
    } else {
      if (plan == null) {
        plan = "";
      }
      localStorage.setItem(
        "vrellvaiecstiadeceig",
        encryptor.encrypt(serviceId)
      );
      localStorage.setItem(
        "eieltaiclsraaemevcvnge",
        encryptor.encrypt(serviceName)
      );
      localStorage.setItem("ipacneglatvlael", encryptor.encrypt(plan));

      if (serviceName == "TR") {
        this.props.history.push({
          pathname: `/myuser/${Decuserid}/userservices/active/tr/${serviceId}`,
        });
      } else if (serviceName == "GSTR") {
        this.props.history.push({
          pathname: `/myuser/${Decuserid}/userservices/active/gstr/${serviceId}`,
        });
      } else if (serviceName == "GSTRF") {
        this.props.history.push({
          pathname: `/myuser/${Decuserid}/userservices/active/gstrf/${serviceId}`,
        });
      } else if (serviceName == "ITRF") {
        this.props.history.push({
          pathname: `/myuser/${Decuserid}/userservices/active/itrf/${serviceId}`,
        });
      } else if (serviceName == "OA") {
        this.props.history.push({
          pathname: `/myuser/${Decuserid}/userservices/active/oa/${serviceId}`,
        });
      } else if (serviceName == "GUMASTA") {
        this.props.history.push({
          pathname: `/myuser/${Decuserid}/userservices/active/gumasta/${serviceId}`,
        });
      } else if (serviceName == "TL") {
        this.props.history.push({
          pathname: `/myuser/${Decuserid}/userservices/active/tl/${serviceId}`,
        });
      } else if (serviceName == "FL") {
        this.props.history.push({
          pathname: `/myuser/${Decuserid}/userservices/active/fl/${serviceId}`,
        });
      } else {
        this.props.history.push({
          pathname: `/myuser/${Decuserid}/userservices/active/${serviceName.toLowerCase()}/${serviceId}`,
        });
      }
    }
  };

  handlemyserviceclick2 = (serviceId, serviceName, plan, type,completestatus) => {
    this.setState({ servicetype: "complete" });

    this.setState({ myplan: plan });
    if (type == "lock") {
      this.setState({ serviceid: serviceId, servicename: serviceName });
      $("#unlockotp").show();
      $("#unlockotpmodal").hide();
      $("#Unlock-Service").modal("show");
    } else {
      if (plan == null) {
        plan = "";
      }
      localStorage.setItem(
        "vrellvaiecstiadeceig",
        encryptor.encrypt(serviceId)
      );
      localStorage.setItem(
        "eieltaiclsraaemevcvnge",
        encryptor.encrypt(serviceName)
      );
      localStorage.setItem("ipacneglatvlael", encryptor.encrypt(plan));
      localStorage.setItem("pojasdsdjisjddd", encryptor.encrypt(completestatus));
      if (serviceName == "TR") {
        this.props.history.push({
          pathname: `/myuser/${Decuserid}/userservices/completed/tr/${serviceId}`,
        });
      } else if (serviceName == "GSTR") {
        this.props.history.push({
          pathname: `/myuser/${Decuserid}/userservices/completed/gstr/${serviceId}`,
        });
      } else if (serviceName == "GSTRF") {
        this.props.history.push({
          pathname: `/myuser/${Decuserid}/userservices/completed/gstrf/${serviceId}`,
        });
      } else if (serviceName == "ITRF") {
        this.props.history.push({
          pathname: `/myuser/${Decuserid}/userservices/completed/itrf/${serviceId}`,
        });
      }
      else if (serviceName == "OA") {
        this.props.history.push({
          pathname: `/myuser/${Decuserid}/userservices/completed/oa/${serviceId}`,
        });
      }
      else if (serviceName == "GUMASTA") {
        this.props.history.push({
          pathname: `/myuser/${Decuserid}/userservices/completed/gumasta/${serviceId}`,
        });
      }
      else if (serviceName == "TL") {
        this.props.history.push({
          pathname: `/myuser/${Decuserid}/userservices/completed/tl/${serviceId}`,
        });
      }
      else if (serviceName == "FL") {
        this.props.history.push({
          pathname: `/myuser/${Decuserid}/userservices/completed/fl/${serviceId}`,
        });
      }
      else {
        this.props.history.push({
          pathname: `/myuser/${Decuserid}/userservices/completed/${serviceName.toLowerCase()}/${serviceId}`,
        });
      }
    }
  };

  renderactiveserveData() {
    if (this.state.activeservicestate == "Latest") {
      activeserviceGSTR = [];
      activeserviceGSTRF = [];
      activeserviceITRF = [];
      activeserviceTR = [];
      activeserviceOA = [];
      activeserviceGUMASTA = [];
      activeserviceTradeLicence = [];
      activeserviceFoodLicence = [];
      if (this.state.activeservicemainarray.length > 0) {
        return this.state.activeservicemainarray.map((activedoc, index) => {
          if (
            this.state.activeservicemainarray[index].serviceName.includes(
              "GSTR"
            ) &&
            !this.state.activeservicemainarray[index].serviceName.includes(
              "GSTRF"
            )
          ) {
            activeserviceGSTR.push(this.state.activeservicemainarray[index]);
          } else if (
            this.state.activeservicemainarray[index].serviceName.includes(
              "GSTRF"
            )
          ) {
            activeserviceGSTRF.push(this.state.activeservicemainarray[index]);
          } else if (
            this.state.activeservicemainarray[index].serviceName.includes(
              "ITRF"
            )
          ) {
            activeserviceITRF.push(this.state.activeservicemainarray[index]);
          } else if (
            this.state.activeservicemainarray[index].serviceName.includes("TR")
          ) {
            activeserviceTR.push(this.state.activeservicemainarray[index]);
          } else if (
            this.state.activeservicemainarray[index].serviceName.includes("OA")
          ) {

            activeserviceOA.push(this.state.activeservicemainarray[index]);
          }
          else if (
            this.state.activeservicemainarray[index].serviceName.includes("GUMASTA")
          ) {

            activeserviceGUMASTA.push(this.state.activeservicemainarray[index]);
          }
          else if (
            this.state.activeservicemainarray[index].serviceName.includes("TL")
          ) {

            activeserviceTradeLicence.push(this.state.activeservicemainarray[index]);
          }
          else if (
            this.state.activeservicemainarray[index].serviceName.includes("FL")
          ) {

            activeserviceFoodLicence.push(this.state.activeservicemainarray[index]);
          }
          return (
            <div
              className="col-xl-3 col-lg-4 col-md-6 col-12 card-gap"
              key={index}
            >
              <div
                className="card border-0 cursorpointer"
                onClick={() =>
                  this.handlemyserviceclick(
                    activedoc.serviceId,
                    activedoc.serviceName,
                    activedoc.plan,
                    activedoc.type
                  )
                }
              >
                <div className="card-body py-3 px-3">
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.userName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Purchased By:-&nbsp;</span>
                      {activedoc.purchasedBy}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Purchased On:-&nbsp;</span>
                      {activedoc.purchaseDate}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Plan:-&nbsp;</span>
                      {this.Checkplan(activedoc.plan)}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Status:-&nbsp;</span>
                      {this.getservicefullstatus(activedoc.status)}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Remark:-&nbsp;</span>
                      {activedoc.remark}
                    </p>
                  </div>
                </div>
                {activedoc.type == "lock" ? (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                    className="card-img"
                  />
                ) : (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group 852.svg"}
                    className="card-img"
                  />
                )}
              </div>
            </div>
          );
        });
      } else if (this.state.activeservicemainarray.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              src={process.env.PUBLIC_URL + "/img/dontactivesevice.svg"}
              className="myservicequiteimgstart"
            />
          </center>
        );
      }
    } else if (this.state.activeservicestate == "Lowest") {
      if (this.state.activeservicemainarray.length > 0) {
        return this.state.activeservicemainarray
          .slice()
          .reverse()
          .map((activedoc, index) => {
            return (
              <div
                className="col-xl-3 col-lg-4 col-md-6 col-12 card-gap "
                key={index}
              >
                <div
                  className="card border-0 cursorpointer"
                  onClick={() =>
                    this.handlemyserviceclick(
                      activedoc.serviceId,
                      activedoc.serviceName,
                      activedoc.plan,
                      activedoc.type
                    )
                  }
                >
                  <div className="card-body py-3 px-3">
                    <div className="card-head">
                      {activedoc.serviceFullName}
                    </div>
                    <div className="card-data py-2 pl-2">
                      <p className="d-flex">
                        <span className="data-title">Name:-&nbsp;</span>
                        {activedoc.userName}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Service ID:-&nbsp;</span>
                        {activedoc.serviceId}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Purchased By:-&nbsp;</span>
                        {activedoc.purchasedBy}
                      </p>
                      <p className="d-flex">
                      <span className="data-title">Purchased On:-&nbsp;</span>
                      {activedoc.purchaseDate}
                    </p>
                      <p className="d-flex">
                        <span className="data-title">Plan:-&nbsp;</span>
                        {this.Checkplan(activedoc.plan)}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Status:-&nbsp;</span>
                        {this.getservicefullstatus(activedoc.status)}
                      </p>
                      <p className="d-flex">
                      <span className="data-title">Remark:-&nbsp;</span>
                      {activedoc.remark}
                    </p>
                    </div>
                  </div>
                  {activedoc.type == "lock" ? (
                    <img
                      alt="loading..."
                      src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                      className="card-img"
                    />
                  ) : (
                    <img
                      alt="loading..."
                      src={process.env.PUBLIC_URL + "/img/Group 852.svg"}
                      className="card-img"
                    />
                  )}
                </div>
              </div>
            );
          });
      } else if (this.state.activeservicemainarray.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              src={process.env.PUBLIC_URL + "/img/dontactivesevice.svg"}
              className="myservicequiteimgstart"
            />
          </center>
        );
      }
    } else if (this.state.activeservicestate == "GSTR") {
      if (activeserviceGSTR.length > 0) {
        return activeserviceGSTR.map((activedoc, index) => {
          return (
            <div
              className="col-xl-3 col-lg-4 col-md-6 col-12 card-gap "
              key={index}
            >
              <div
                className="card border-0 cursorpointer"
                onClick={() =>
                  this.handlemyserviceclick(
                    activedoc.serviceId,
                    activedoc.serviceName,
                    activedoc.plan,
                    activedoc.type
                  )
                }
              >
                <div className="card-body py-3 px-3">
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.userName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Purchased By:-&nbsp;</span>
                      {activedoc.purchasedBy}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Purchased On:-&nbsp;</span>
                      {activedoc.purchaseDate}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Plan:-&nbsp;</span>
                      {this.Checkplan(activedoc.plan)}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Status:-&nbsp;</span>
                      {this.getservicefullstatus(activedoc.status)}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Remark:-&nbsp;</span>
                      {activedoc.remark}
                    </p>
                  </div>
                </div>
                {activedoc.type == "lock" ? (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                    className="card-img"
                  />
                ) : (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group 852.svg"}
                    className="card-img"
                  />
                )}
              </div>
            </div>
          );
        });
      } else if (activeserviceGSTR.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    } else if (this.state.activeservicestate == "GSTRF") {
      if (activeserviceGSTRF.length > 0) {
        return activeserviceGSTRF.map((activedoc, index) => {
          return (
            <div
              className="col-xl-3 col-lg-4 col-md-6 col-12 card-gap "
              key={index}
            >
              <div
                className="card border-0 cursorpointer"
                onClick={() =>
                  this.handlemyserviceclick(
                    activedoc.serviceId,
                    activedoc.serviceName,
                    activedoc.plan,
                    activedoc.type
                  )
                }
              >
                <div className="card-body py-3 px-3">
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.userName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Purchased By:-&nbsp;</span>
                      {activedoc.purchasedBy}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Purchased On:-&nbsp;</span>
                      {activedoc.purchaseDate}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Plan:-&nbsp;</span>
                      {this.Checkplan(activedoc.plan)}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Status:-&nbsp;</span>
                      {this.getservicefullstatus(activedoc.status)}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Remark:-&nbsp;</span>
                      {activedoc.remark}
                    </p>
                  </div>
                </div>
                {activedoc.type == "lock" ? (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                    className="card-img"
                  />
                ) : (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group 852.svg"}
                    className="card-img"
                  />
                )}
              </div>
            </div>
          );
        });
      } else if (activeserviceGSTRF.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    } else if (this.state.activeservicestate == "ITRF") {
      if (activeserviceITRF.length > 0) {
        return activeserviceITRF.map((activedoc, index) => {
          return (
            <div
              className="col-xl-3 col-lg-4 col-md-6 col-12 card-gap "
              key={index}
            >
              <div
                className="card border-0 cursorpointer"
                onClick={() =>
                  this.handlemyserviceclick(
                    activedoc.serviceId,
                    activedoc.serviceName,
                    activedoc.plan,
                    activedoc.type
                  )
                }
              >
                <div className="card-body py-3 px-3">
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.userName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Purchased By:-&nbsp;</span>
                      {activedoc.purchasedBy}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Purchased On:-&nbsp;</span>
                      {activedoc.purchaseDate}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Plan:-&nbsp;</span>
                      {this.Checkplan(activedoc.plan)}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Status:-&nbsp;</span>
                      {this.getservicefullstatus(activedoc.status)}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Remark:-&nbsp;</span>
                      {activedoc.remark}
                    </p>
                  </div>
                </div>
                {activedoc.type == "lock" ? (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                    className="card-img"
                  />
                ) : (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group 852.svg"}
                    className="card-img"
                  />
                )}
              </div>
            </div>
          );
        });
      } else if (activeserviceITRF.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    } else if (this.state.activeservicestate == "TR") {
      if (activeserviceTR.length > 0) {
        return activeserviceTR.map((activedoc, index) => {
          return (
            <div
              className="col-xl-3 col-lg-4 col-md-6 col-12 card-gap "
              key={index}
            >
              <div
                className="card border-0 cursorpointer"
                onClick={() =>
                  this.handlemyserviceclick(
                    activedoc.serviceId,
                    activedoc.serviceName,
                    activedoc.plan,
                    activedoc.type
                  )
                }
              >
                <div className="card-body py-3 px-3">
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.userName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Plan:-&nbsp;</span>
                      {this.Checkplan(activedoc.plan)}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Purchased By:-&nbsp;</span>
                      {activedoc.purchasedBy}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Purchased On:-&nbsp;</span>
                      {activedoc.purchaseDate}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Status:-&nbsp;</span>
                      {this.getservicefullstatus(activedoc.status)}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Remark:-&nbsp;</span>
                      {activedoc.remark}
                    </p>
                  </div>
                </div>
                {activedoc.type == "lock" ? (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                    className="card-img"
                  />
                ) : (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group 852.svg"}
                    className="card-img"
                  />
                )}
              </div>
            </div>
          );
        });
      } else if (activeserviceTR.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    } else if (this.state.activeservicestate == "OA") {
      if (activeserviceOA.length > 0) {
        return activeserviceOA.map((activedoc, index) => {
          return (
            <div
              className="col-xl-3 col-lg-4 col-md-6 col-12 card-gap "
              key={index}
            >
              <div
                className="card border-0 cursorpointer"
                onClick={() =>
                  this.handlemyserviceclick(
                    activedoc.serviceId,
                    activedoc.serviceName,
                    activedoc.plan,
                    activedoc.type
                  )
                }
              >
                <div className="card-body py-3 px-3">
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.userName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Plan:-&nbsp;</span>
                      {this.Checkplan(activedoc.plan)}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Purchased By:-&nbsp;</span>
                      {activedoc.purchasedBy}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Purchased On:-&nbsp;</span>
                      {activedoc.purchaseDate}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Status:-&nbsp;</span>
                      {this.getservicefullstatus(activedoc.status)}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Remark:-&nbsp;</span>
                      {activedoc.remark}
                    </p>
                  </div>
                </div>
                {activedoc.type == "lock" ? (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                    className="card-img"
                  />
                ) : (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group 852.svg"}
                    className="card-img"
                  />
                )}
              </div>
            </div>
          );
        });
      } else if (activeserviceOA.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    } else if (this.state.activeservicestate == "GUMASTA") {
      if (activeserviceGUMASTA.length > 0) {
        return activeserviceGUMASTA.map((activedoc, index) => {
          return (
            <div
              className="col-xl-3 col-lg-4 col-md-6 col-12 card-gap "
              key={index}
            >
              <div
                className="card border-0 cursorpointer"
                onClick={() =>
                  this.handlemyserviceclick(
                    activedoc.serviceId,
                    activedoc.serviceName,
                    activedoc.plan,
                    activedoc.type
                  )
                }
              >
                <div className="card-body py-3 px-3">
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.userName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Plan:-&nbsp;</span>
                      {this.Checkplan(activedoc.plan)}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Purchased By:-&nbsp;</span>
                      {activedoc.purchasedBy}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Purchased On:-&nbsp;</span>
                      {activedoc.purchaseDate}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Status:-&nbsp;</span>
                      {this.getservicefullstatus(activedoc.status)}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Remark:-&nbsp;</span>
                      {activedoc.remark}
                    </p>
                  </div>
                </div>
                {activedoc.type == "lock" ? (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                    className="card-img"
                  />
                ) : (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group 852.svg"}
                    className="card-img"
                  />
                )}
              </div>
            </div>
          );
        });
      } else if (activeserviceGUMASTA.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    } else if (this.state.activeservicestate == "TL") {
      if (activeserviceTradeLicence.length > 0) {
        return activeserviceTradeLicence.map((activedoc, index) => {
          return (
            <div
              className="col-xl-3 col-lg-4 col-md-6 col-12 card-gap "
              key={index}
            >
              <div
                className="card border-0 cursorpointer"
                onClick={() =>
                  this.handlemyserviceclick(
                    activedoc.serviceId,
                    activedoc.serviceName,
                    activedoc.plan,
                    activedoc.type
                  )
                }
              >
                <div className="card-body py-3 px-3">
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.userName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Plan:-&nbsp;</span>
                      {this.Checkplan(activedoc.plan)}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Purchased By:-&nbsp;</span>
                      {activedoc.purchasedBy}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Purchased On:-&nbsp;</span>
                      {activedoc.purchaseDate}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Status:-&nbsp;</span>
                      {this.getservicefullstatus(activedoc.status)}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Remark:-&nbsp;</span>
                      {activedoc.remark}
                    </p>
                  </div>
                </div>
                {activedoc.type == "lock" ? (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                    className="card-img"
                  />
                ) : (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group 852.svg"}
                    className="card-img"
                  />
                )}
              </div>
            </div>
          );
        });
      } else if (activeserviceTradeLicence.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    } else if (this.state.activeservicestate == "FL") {
      if (activeserviceFoodLicence.length > 0) {
        return activeserviceFoodLicence.map((activedoc, index) => {
          return (
            <div
              className="col-xl-3 col-lg-4 col-md-6 col-12 card-gap "
              key={index}
            >
              <div
                className="card border-0 cursorpointer"
                onClick={() =>
                  this.handlemyserviceclick(
                    activedoc.serviceId,
                    activedoc.serviceName,
                    activedoc.plan,
                    activedoc.type
                  )
                }
              >
                <div className="card-body py-3 px-3">
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.userName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Plan:-&nbsp;</span>
                      {this.Checkplan(activedoc.plan)}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Purchased By:-&nbsp;</span>
                      {activedoc.purchasedBy}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Purchased On:-&nbsp;</span>
                      {activedoc.purchaseDate}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Status:-&nbsp;</span>
                      {this.getservicefullstatus(activedoc.status)}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Remark:-&nbsp;</span>
                      {activedoc.remark}
                    </p>
                  </div>
                </div>
                {activedoc.type == "lock" ? (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                    className="card-img"
                  />
                ) : (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group 852.svg"}
                    className="card-img"
                  />
                )}
              </div>
            </div>
          );
        });
      } else if (activeserviceFoodLicence.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    }
  }
  rendercompletedserveData() {
    if (this.state.completeservicestate == "Latest") {
      completeserviceGSTR = [];
      completeserviceGSTRF = [];
      completeserviceITRF = [];
      completeserviceTR = [];
      completeserviceOA = [];
      completeserviceGUMASTA = [];
      completeserviceTradeLicence = [];
      completeserviceFoodLicence = [];
      if (this.state.completedservice.length > 0) {
        return this.state.completedservice.map((activedoc, index) => {
          if (
            this.state.completedservice[index].serviceName.includes("GSTR") &&
            !this.state.completedservice[index].serviceName.includes("GSTRF")
          ) {
            completeserviceGSTR.push(this.state.completedservice[index]);
          } else if (
            this.state.completedservice[index].serviceName.includes("GSTRF")
          ) {
            completeserviceGSTRF.push(this.state.completedservice[index]);
          } else if (
            this.state.completedservice[index].serviceName.includes("ITRF")
          ) {
            completeserviceITRF.push(this.state.completedservice[index]);
          } else if (
            this.state.completedservice[index].serviceName.includes("TR")
          ) {
            completeserviceTR.push(this.state.completedservice[index]);
          } else if (
            this.state.completedservice[index].serviceName.includes("OA")
          ) {
            completeserviceOA.push(this.state.completedservice[index]);
          } else if (
            this.state.completedservice[index].serviceName.includes("GUMASTA")
          ) {
            completeserviceGUMASTA.push(this.state.completedservice[index]);
          } else if (
            this.state.completedservice[index].serviceName.includes("TL")
          ) {
            completeserviceTradeLicence.push(this.state.completedservice[index]);
          } else if (
            this.state.completedservice[index].serviceName.includes("FL")
          ) {
            completeserviceFoodLicence.push(this.state.completedservice[index]);
          }
          return (
            <div
              className="col-xl-3 col-lg-4 col-md-6 col-12 card-gap cursorpointer"
              key={index}
              onClick={() =>
                this.handlemyserviceclick2(
                  activedoc.serviceId,
                  activedoc.serviceName,
                  activedoc.plan,
                  activedoc.type,
                  activedoc.completeStatus
                )
              }
            >
              <div className="card border-0">
                <div className="card-body py-3 px-3">
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.userName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Purchased By:-&nbsp;</span>
                      {activedoc.purchasedBy}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Purchased On:-&nbsp;</span>
                      {activedoc.purchaseDate}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Started on:-&nbsp;</span>
                      {activedoc.purchasedOn}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Completed on:-&nbsp;</span>
                      {activedoc.completedOn}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Completed status:-&nbsp;</span>
                      {activedoc.completeStatus}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Remark:-&nbsp;</span>
                      {activedoc.remark}
                    </p>
                  </div>
                </div>
                {activedoc.type == "lock" ? (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                    className="card-img"
                  />
                ) : (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group 2521.svg"}
                    className="card-img"
                  />
                )}
              </div>
            </div>
          );
        });
      } else if (this.state.completedservice.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimgstart"
            />
          </center>
        );
      }
    } else if (this.state.completeservicestate == "Lowest") {
      if (this.state.completedservice.length > 0) {
        return this.state.completedservice
          .slice()
          .reverse()
          .map((activedoc, index) => {
            return (
              <div
                className="col-xl-3 col-lg-4 col-md-6 col-12 card-gap "
                key={index}
              >
                <div
                  className="card border-0 cursorpointer"
                  onClick={() =>
                    this.handlemyserviceclick2(
                      activedoc.serviceId,
                      activedoc.serviceName,
                      activedoc.plan,
                      activedoc.type,
                      activedoc.completeStatus
                    )
                  }
                >
                  <div className="card-body py-3 px-3">
                    <div className="card-head">
                      {activedoc.serviceFullName}
                    </div>
                    <div className="card-data py-2 pl-2">
                      <p className="d-flex">
                        <span className="data-title">Name:-&nbsp;</span>
                        {activedoc.userName}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Service ID:-&nbsp;</span>
                        {activedoc.serviceId}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Purchased By:-&nbsp;</span>
                        {activedoc.purchasedBy}
                      </p>
                      <p className="d-flex">
                      <span className="data-title">Purchased On:-&nbsp;</span>
                      {activedoc.purchaseDate}
                    </p>
                      <p className="d-flex">
                        <span className="data-title">Started on:-&nbsp;</span>
                        {activedoc.purchasedOn}
                      </p>
                      <p className="d-flex">
                        <span className="data-title">Completed on:-&nbsp;</span>
                        {activedoc.completedOn}
                      </p>
                      <p className="d-flex">
                      <span className="data-title">Completed status:-&nbsp;</span>
                      {activedoc.completeStatus}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Remark:-&nbsp;</span>
                      {activedoc.remark}
                    </p>
                    </div>
                  </div>
                  {activedoc.type == "lock" ? (
                    <img
                      alt="loading..."
                      src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                      className="card-img"
                    />
                  ) : (
                    <img
                      alt="loading..."
                      src={process.env.PUBLIC_URL + "/img/Group 2521.svg"}
                      className="card-img"
                    />
                  )}
                </div>
              </div>
            );
          });
      } else if (this.state.completedservice.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimgstart"
            />
          </center>
        );
      }
    } else if (this.state.completeservicestate == "GSTR") {
      if (completeserviceGSTR.length > 0) {
        return completeserviceGSTR.map((activedoc, index) => {
          return (
            <div
              className="col-xl-3 col-lg-4 col-md-6 col-12 card-gap "
              key={index}
            >
              <div
                className="card border-0 cursorpointer"
                onClick={() =>
                  this.handlemyserviceclick2(
                    activedoc.serviceId,
                    activedoc.serviceName,
                    activedoc.plan,
                    activedoc.type,
                    activedoc.completeStatus
                  )
                }
              >
                <div className="card-body py-3 px-3">
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.userName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Purchased By:-&nbsp;</span>
                      {activedoc.purchasedBy}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Purchased On:-&nbsp;</span>
                      {activedoc.purchaseDate}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Started on:-&nbsp;</span>
                      {activedoc.purchasedOn}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Completed on:-&nbsp;</span>
                      {activedoc.completedOn}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Completed status:-&nbsp;</span>
                      {activedoc.completeStatus}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Remark:-&nbsp;</span>
                      {activedoc.remark}
                    </p>
                  </div>
                </div>
                {activedoc.type == "lock" ? (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                    className="card-img"
                  />
                ) : (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group 2521.svg"}
                    className="card-img"
                  />
                )}
              </div>
            </div>
          );
        });
      } else if (completeserviceGSTR.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    } else if (this.state.completeservicestate == "GSTRF") {
      if (completeserviceGSTRF.length > 0) {
        return completeserviceGSTRF.map((activedoc, index) => {
          return (
            <div
              className="col-xl-3 col-lg-4 col-md-6 col-12 card-gap "
              key={index}
            >
              <div
                className="card border-0 cursorpointer"
                onClick={() =>
                  this.handlemyserviceclick2(
                    activedoc.serviceId,
                    activedoc.serviceName,
                    activedoc.plan,
                    activedoc.type,
                    activedoc.completeStatus
                  )
                }
              >
                <div className="card-body py-3 px-3">
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.userName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Purchased By:-&nbsp;</span>
                      {activedoc.purchasedBy}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Purchased On:-&nbsp;</span>
                      {activedoc.purchaseDate}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Started on:-&nbsp;</span>
                      {activedoc.purchasedOn}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Completed on:-&nbsp;</span>
                      {activedoc.completedOn}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Completed status:-&nbsp;</span>
                      {activedoc.completeStatus}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Remark:-&nbsp;</span>
                      {activedoc.remark}
                    </p>
                  </div>
                </div>
                {activedoc.type == "lock" ? (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                    className="card-img"
                  />
                ) : (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group 2521.svg"}
                    className="card-img"
                  />
                )}
              </div>
            </div>
          );
        });
      } else if (completeserviceGSTRF.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    } else if (this.state.completeservicestate == "ITRF") {
      if (completeserviceITRF.length > 0) {
        return completeserviceITRF.map((activedoc, index) => {
          return (
            <div
              className="col-xl-3 col-lg-4 col-md-6 col-12 card-gap "
              key={index}
            >
              <div
                className="card border-0 cursorpointer"
                onClick={() =>
                  this.handlemyserviceclick2(
                    activedoc.serviceId,
                    activedoc.serviceName,
                    activedoc.plan,
                    activedoc.type,
                    activedoc.completeStatus
                  )
                }
              >
                <div className="card-body py-3 px-3">
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.userName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Purchased By:-&nbsp;</span>
                      {activedoc.purchasedBy}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Purchased On:-&nbsp;</span>
                      {activedoc.purchaseDate}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Started on:-&nbsp;</span>
                      {activedoc.purchasedOn}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Completed on:-&nbsp;</span>
                      {activedoc.completedOn}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Completed status:-&nbsp;</span>
                      {activedoc.completeStatus}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Remark:-&nbsp;</span>
                      {activedoc.remark}
                    </p>
                  </div>
                </div>
                {activedoc.type == "lock" ? (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                    className="card-img"
                  />
                ) : (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group 2521.svg"}
                    className="card-img"
                  />
                )}
              </div>
            </div>
          );
        });
      } else if (completeserviceITRF.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    } else if (this.state.completeservicestate == "TR") {
      if (completeserviceTR.length > 0) {
        return completeserviceTR.map((activedoc, index) => {
          return (
            <div
              className="col-xl-3 col-lg-4 col-md-6 col-12 card-gap "
              key={index}
            >
              <div
                className="card border-0 cursorpointer"
                onClick={() =>
                  this.handlemyserviceclick2(
                    activedoc.serviceId,
                    activedoc.serviceName,
                    activedoc.plan,
                    activedoc.type,
                    activedoc.completeStatus
                  )
                }
              >
                <div className="card-body py-3 px-3">
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.userName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Purchased By:-&nbsp;</span>
                      {activedoc.purchasedBy}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Purchased On:-&nbsp;</span>
                      {activedoc.purchaseDate}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Started on:-&nbsp;</span>
                      {activedoc.purchasedOn}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Completed on:-&nbsp;</span>
                      {activedoc.completedOn}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Completed status:-&nbsp;</span>
                      {activedoc.completeStatus}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Remark:-&nbsp;</span>
                      {activedoc.remark}
                    </p>
                  </div>
                </div>
                {activedoc.type == "lock" ? (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                    className="card-img"
                  />
                ) : (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group 2521.svg"}
                    className="card-img"
                  />
                )}
              </div>
            </div>
          );
        });
      } else if (completeserviceTR.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    } else if (this.state.completeservicestate == "OA") {
      if (completeserviceOA.length > 0) {
        return completeserviceOA.map((activedoc, index) => {
          return (
            <div
              className="col-xl-3 col-lg-4 col-md-6 col-12 card-gap "
              key={index}
            >
              <div
                className="card border-0 cursorpointer"
                onClick={() =>
                  this.handlemyserviceclick2(
                    activedoc.serviceId,
                    activedoc.serviceName,
                    activedoc.plan,
                    activedoc.type,
                    activedoc.completeStatus
                  )
                }
              >
                <div className="card-body py-3 px-3">
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.userName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Purchased By:-&nbsp;</span>
                      {activedoc.purchasedBy}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Purchased On:-&nbsp;</span>
                      {activedoc.purchaseDate}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Started on:-&nbsp;</span>
                      {activedoc.purchasedOn}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Completed on:-&nbsp;</span>
                      {activedoc.completedOn}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Completed status:-&nbsp;</span>
                      {activedoc.completeStatus}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Remark:-&nbsp;</span>
                      {activedoc.remark}
                    </p>
                  </div>
                </div>
                {activedoc.type == "lock" ? (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                    className="card-img"
                  />
                ) : (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group 2521.svg"}
                    className="card-img"
                  />
                )}
              </div>
            </div>
          );
        });
      } else if (completeserviceOA.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    } else if (this.state.completeservicestate == "GUMASTA") {
      if (completeserviceGUMASTA.length > 0) {
        return completeserviceGUMASTA.map((activedoc, index) => {
          return (
            <div
              className="col-xl-3 col-lg-4 col-md-6 col-12 card-gap "
              key={index}
            >
              <div
                className="card border-0 cursorpointer"
                onClick={() =>
                  this.handlemyserviceclick2(
                    activedoc.serviceId,
                    activedoc.serviceName,
                    activedoc.plan,
                    activedoc.type,
                    activedoc.completeStatus
                  )
                }
              >
                <div className="card-body py-3 px-3">
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.userName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Purchased By:-&nbsp;</span>
                      {activedoc.purchasedBy}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Purchased On:-&nbsp;</span>
                      {activedoc.purchaseDate}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Started on:-&nbsp;</span>
                      {activedoc.purchasedOn}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Completed on:-&nbsp;</span>
                      {activedoc.completedOn}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Completed status:-&nbsp;</span>
                      {activedoc.completeStatus}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Remark:-&nbsp;</span>
                      {activedoc.remark}
                    </p>
                  </div>
                </div>
                {activedoc.type == "lock" ? (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                    className="card-img"
                  />
                ) : (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group 2521.svg"}
                    className="card-img"
                  />
                )}
              </div>
            </div>
          );
        });
      } else if (completeserviceGUMASTA.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    } else if (this.state.completeservicestate == "TL") {
      if (completeserviceTradeLicence.length > 0) {
        return completeserviceTradeLicence.map((activedoc, index) => {
          return (
            <div
              className="col-xl-3 col-lg-4 col-md-6 col-12 card-gap "
              key={index}
            >
              <div
                className="card border-0 cursorpointer"
                onClick={() =>
                  this.handlemyserviceclick2(
                    activedoc.serviceId,
                    activedoc.serviceName,
                    activedoc.plan,
                    activedoc.type,
                    activedoc.completeStatus
                  )
                }
              >
                <div className="card-body py-3 px-3">
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.userName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Purchased By:-&nbsp;</span>
                      {activedoc.purchasedBy}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Purchased On:-&nbsp;</span>
                      {activedoc.purchaseDate}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Started on:-&nbsp;</span>
                      {activedoc.purchasedOn}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Completed on:-&nbsp;</span>
                      {activedoc.completedOn}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Completed status:-&nbsp;</span>
                      {activedoc.completeStatus}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Remark:-&nbsp;</span>
                      {activedoc.remark}
                    </p>
                  </div>
                </div>
                {activedoc.type == "lock" ? (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                    className="card-img"
                  />
                ) : (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group 2521.svg"}
                    className="card-img"
                  />
                )}
              </div>
            </div>
          );
        });
      } else if (completeserviceTradeLicence.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    } else if (this.state.completeservicestate == "FL") {
      if (completeserviceFoodLicence.length > 0) {
        return completeserviceFoodLicence.map((activedoc, index) => {
          return (
            <div
              className="col-xl-3 col-lg-4 col-md-6 col-12 card-gap "
              key={index}
            >
              <div
                className="card border-0 cursorpointer"
                onClick={() =>
                  this.handlemyserviceclick2(
                    activedoc.serviceId,
                    activedoc.serviceName,
                    activedoc.plan,
                    activedoc.type,
                    activedoc.completeStatus
                  )
                }
              >
                <div className="card-body py-3 px-3">
                  <div className="card-head">
                    {activedoc.serviceFullName}
                  </div>
                  <div className="card-data py-2 pl-2">
                    <p className="d-flex">
                      <span className="data-title">Name:-&nbsp;</span>
                      {activedoc.userName}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Service ID:-&nbsp;</span>
                      {activedoc.serviceId}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Purchased By:-&nbsp;</span>
                      {activedoc.purchasedBy}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Purchased On:-&nbsp;</span>
                      {activedoc.purchaseDate}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Started on:-&nbsp;</span>
                      {activedoc.purchasedOn}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Completed on:-&nbsp;</span>
                      {activedoc.completedOn}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Completed status:-&nbsp;</span>
                      {activedoc.completeStatus}
                    </p>
                    <p className="d-flex">
                      <span className="data-title">Remark:-&nbsp;</span>
                      {activedoc.remark}
                    </p>
                  </div>
                </div>
                {activedoc.type == "lock" ? (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group_5523.svg"}
                    className="card-img"
                  />
                ) : (
                  <img
                    alt="loading..."
                    src={process.env.PUBLIC_URL + "/img/Group 2521.svg"}
                    className="card-img"
                  />
                )}
              </div>
            </div>
          );
        });
      } else if (completeserviceFoodLicence.length == 0) {
        return (
          <center className="myservicequiteimgcenter">
            <img
              alt="loading..."
              src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
              className="myservicequiteimg"
            />
          </center>
        );
      }
    }
  }

  getservicefullname(name) {
    var fullname = AllServices[name]
    return <>{fullname}</>;
  }

  getservicefullstatus(status) {
    if (status == "pending") {
      return <span style={{ color: "#2d2d2d" }}>Upload Pending</span>;
    } else if (status == "undereview") {
      return <span style={{ color: "#6378FF" }}>Under Review</span>;
    } else if (status == "complete") {
      return <span style={{ color: "#63D963" }}>Complete</span>;
    } else if (status == "invaliddoc") {
      return <span style={{ color: "#FF4D4D" }}>Invalid Documents</span>;
    } else if (status == "working") {
      return <span style={{ color: "#63D963" }}>Work in Progress</span>;
    }
  }

  backbutton = () => {
    if (
      this.state.userid != "" &&
      this.state.email != "" &&
      this.state.contact != "" &&
      this.state.username != ""
    ) {
      this.props.history.push({
        pathname: `/myuser/${Decuserid}`,
        search: "?query=abc",
        state: {
          userid: this.state.userid,
          email: this.state.email,
          contact: this.state.contact,
          name: this.state.username,
        },
      });
    } else {
      this.props.history.push({
        pathname: "/myuser",
      });
    }
  };

  serviceunlock = (serviceid, servicename) => {
    $(".sendUnlockOtpLoader").show();
    $("#sendUnlockOtp").hide();
    this.setState({ mobileotp: "", emailotp: "" });
    axios
      .post(
        `${baseUrl}p=serviceUnlock`,
        {
          agentId: Decagentid,
          userId: Decuserid,
          requestType: temp,
          serviceId: serviceid,
          serviceName: servicename,
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {

        if (res.data.code == 200 && res.data.status == "success") {
          $(".sendUnlockOtpLoader").hide();
          $("#sendUnlockOtp").show();
          $("#invalidunlockmobileotp").hide();
          $("#invalidunlockemailotp").hide();
          // this.showNotification(res.data.message);
          $("#unlockotpmodal").show();
          $("#unlockotp").hide();
          $("#otpsentunlocksuccess").hide();

          this.countdown();
        } else if (res.data.code == 201 && res.data.status == "fail") {
          this.showNotification(res.data.message);
          $(".sendUnlockOtpLoader").hide();
          $("#sendUnlockOtp").show();
        }
      })
      .catch((err) => {
        // 
        this.showNotification("Something went wrong");
        // $(".sendOtpLoader").hide();
        // $(".sendOtpBtn").show();
        $(".sendUnlockOtpLoader").hide();
        $("#sendUnlockOtp").show();
      });
  };

  SubmitOTP = () => {
    $("#invalidunlockmobileotp").hide();
    $("#invalidunlockemailotp").hide();
    $("#otpsentunlocksuccess").hide();

    if (
      this.state.mobileotp.trim() == "" &&
      this.state.mobileotp.trim() == ""
    ) {
      $(".invalidmobile").html("Please enter mobile OTP")
      $(".invalidemail").html("Please enter mobile OTP")
      $("#invalidunlockmobileotp").show();
      $("#invalidunlockemailotp").show();
    } else if (
      this.state.mobileotp.trim() == "" ||
      this.state.mobileotp.length > 6 ||
      this.state.mobileotp.length < 6 ||
      isNaN(this.state.mobileotp)
    ) {
      $(".invalidmobile").html("Please enter valid mobile OTP")
      $("#invalidunlockmobileotp").show();
    } else if (
      this.state.emailotp.trim() == "" ||
      this.state.emailotp.length > 6 ||
      this.state.emailotp.length < 6 ||
      isNaN(this.state.emailotp)
    ) {
      $(".invalidemail").html("Please enter valid email OTP")
      $("#invalidunlockemailotp").show();
    } else {
      // $("#otpmodal").hide();
      $("#submitunlockOtpbtn").hide();
      $(".sendUnlockOtpLoader").show();
      axios
        .post(
          `${baseUrl}p=verifyuserotp`,
          {
            agentId: Decagentid,
            userId: Decuserid,
            requestType: temp,
            contactOTP: this.state.mobileotp,
            emailOTP: this.state.emailotp,
            type: "serviceUnlock",
            serviceId: this.state.serviceid,
            serviceName: this.state.servicename,
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {

          if (res.data.code == 200 && res.data.status == "success") {
            $("#submitunlockOtpbtn").show();
            $(".sendOtpLoader").hide();
            $(".sendUnlockOtpLoader").hide();
            $(".sendOtpBtn").show();
            localStorage.setItem(
              "vrellvaiecstiadeceig",
              encryptor.encrypt(this.state.serviceid)
            );
            localStorage.setItem(
              "eieltaiclsraaemevcvnge",
              encryptor.encrypt(this.state.servicename)
            );
            localStorage.setItem(
              "ipacneglatvlael",
              encryptor.encrypt(this.state.myplan)
            );
            if (
              this.state.servicename == "TR" &&
              this.state.servicetype == "active"
            ) {
              $("#Unlock-Service").modal("hide");
              this.props.history.push({
                pathname: `/myuser/${Decuserid}/userservices/active/tr/${this.state.serviceid}`,
              });
            } else if (
              this.state.servicename == "GSTR" &&
              this.state.servicetype == "active"
            ) {
              $("#Unlock-Service").modal("hide");
              this.props.history.push({
                pathname: `/myuser/${Decuserid}/userservices/active/gstr/${this.state.serviceid}`,
              });
            } else if (
              this.state.servicename == "GSTRF" &&
              this.state.servicetype == "active"
            ) {
              $("#Unlock-Service").modal("hide");
              this.props.history.push({
                pathname: `/myuser/${Decuserid}/userservices/active/gstrf/${this.state.serviceid}`,
              });
            } else if (
              this.state.servicename == "ITRF" &&
              this.state.servicetype == "active"
            ) {
              $("#Unlock-Service").modal("hide");
              this.props.history.push({
                pathname: `/myuser/${Decuserid}/userservices/active/itrf/${this.state.serviceid}`,
              });
            } else if (
              this.state.servicename == "OA" &&
              this.state.servicetype == "active"
            ) {
              $("#Unlock-Service").modal("hide");
              this.props.history.push({
                pathname: `/myuser/${Decuserid}/userservices/active/oa/${this.state.serviceid}`,
              });
            } else if (
              this.state.servicename == "GUMASTA" &&
              this.state.servicetype == "active"
            ) {
              $("#Unlock-Service").modal("hide");
              this.props.history.push({
                pathname: `/myuser/${Decuserid}/userservices/active/gumasta/${this.state.serviceid}`,
              });
            } else if (
              this.state.servicename == "TL" &&
              this.state.servicetype == "active"
            ) {
              $("#Unlock-Service").modal("hide");
              this.props.history.push({
                pathname: `/myuser/${Decuserid}/userservices/active/tl/${this.state.serviceid}`,
              });
            } else if (
              this.state.servicename == "FL" &&
              this.state.servicetype == "active"
            ) {
              $("#Unlock-Service").modal("hide");
              this.props.history.push({
                pathname: `/myuser/${Decuserid}/userservices/active/fl/${this.state.serviceid}`,
              });
            }
            else if (
              this.state.servicetype == "active"
            ) {
              $("#Unlock-Service").modal("hide");
              this.props.history.push({
                pathname: `/myuser/${Decuserid}/userservices/active/${this.state.servicename.toLowerCase()}/${this.state.serviceid}`,
              });
            }

            else if (
              this.state.servicename == "TR" &&
              this.state.servicetype == "complete"
            ) {
              $("#Unlock-Service").modal("hide");
              this.props.history.push({
                pathname: `/myuser/${Decuserid}/userservices/completed/tr/${this.state.serviceid}`,
              });
            } else if (
              this.state.servicename == "GSTR" &&
              this.state.servicetype == "complete"
            ) {
              $("#Unlock-Service").modal("hide");
              this.props.history.push({
                pathname: `/myuser/${Decuserid}/userservices/completed/gstr/${this.state.serviceid}`,
              });
            } else if (
              this.state.servicename == "GSTRF" &&
              this.state.servicetype == "complete"
            ) {
              $("#Unlock-Service").modal("hide");
              this.props.history.push({
                pathname: `/myuser/${Decuserid}/userservices/completed/gstrf/${this.state.serviceid}`,
              });
            } else if (
              this.state.servicename == "ITRF" &&
              this.state.servicetype == "complete"
            ) {
              $("#Unlock-Service").modal("hide");
              this.props.history.push({
                pathname: `/myuser/${Decuserid}/userservices/completed/itrf/${this.state.serviceid}`,
              });
            } else if (
              this.state.servicename == "OA" &&
              this.state.servicetype == "complete"
            ) {
              $("#Unlock-Service").modal("hide");
              this.props.history.push({
                pathname: `/myuser/${Decuserid}/userservices/completed/oa/${this.state.serviceid}`,
              });
            } else if (
              this.state.servicename == "GUMASTA" &&
              this.state.servicetype == "complete"
            ) {
              $("#Unlock-Service").modal("hide");
              this.props.history.push({
                pathname: `/myuser/${Decuserid}/userservices/completed/oa/${this.state.serviceid}`,
              });
            } else if (
              this.state.servicename == "TL" &&
              this.state.servicetype == "complete"
            ) {
              $("#Unlock-Service").modal("hide");
              this.props.history.push({
                pathname: `/myuser/${Decuserid}/userservices/completed/tl/${this.state.serviceid}`,
              });
            } else if (
              this.state.servicename == "FL" &&
              this.state.servicetype == "complete"
            ) {
              $("#Unlock-Service").modal("hide");
              this.props.history.push({
                pathname: `/myuser/${Decuserid}/userservices/completed/oa/${this.state.serviceid}`,
              });
            }
            else if (
              this.state.servicetype == "complete"
            ) {
              $("#Unlock-Service").modal("hide");
              this.props.history.push({
                pathname: `/myuser/${Decuserid}/userservices/completed/${this.state.servicename.toLowerCase()}/${this.state.serviceid}`,
              });
            }

            this.countdown();
          } else if (
            res.data.code == 201 &&
            res.data.status == "contactotpinvalid"
          ) {
            $(".invalidmobile").html("Please enter valid mobile OTP")
            $("#invalidunlockmobileotp").show();
            $("#submitunlockOtpbtn").show();
            $(".sendUnlockOtpLoader").hide();
          } else if (
            res.data.code == 201 &&
            res.data.status == "emailotpinvalid"
          ) {
            $(".invalidemail").html("Please enter valid email OTP")
            $("#invalidunlockemailotp").show();
            $("#submitunlockOtpbtn").show();
            $(".sendUnlockOtpLoader").hide();
          } else if (
            res.data.code == 201 &&
            res.data.status == "bothotpinvalid"
          ) {
            $(".invalidmobile").html("Please enter valid mobile OTP")
            $(".invalidemail").html("Please enter valid email OTP")
            $("#invalidunlockmobileotp").show();
            $("#invalidunlockemailotp").show();
            $("#submitunlockOtpbtn").show();
            $(".sendUnlockOtpLoader").hide();
          } else if (res.data.code == 201 && res.data.status == "fail") {
            this.showNotification(res.data.message);
            $("#submitunlockOtpbtn").show();
            $(".sendUnlockOtpLoader").hide();
          }
        })
        .catch((err) => {
          $("#submitunlockOtpbtn").show();
          $(".sendUnlockOtpLoader").hide();
          this.showNotification("Something went wrong");
        });
    }
  };
  resenduserOtp = () => {
    $("#invalidunlockmobileotp").hide();
    $("#invalidunlockemailotp").hide();
    $("#otpsentunlocksuccess").hide();
    if (this.state.rtime == 0) {
      axios
        .post(
          `${baseUrl}p=serviceUnlock`,
          {
            agentId: Decagentid,
            userId: Decuserid,
            requestType: temp,
            serviceId: this.state.serviceid,
            serviceName: this.state.servicename,
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((res) => {

          if (res.data.code == 200 && res.data.status == "success") {
            $("#otpsentunlocksuccess").show();
            this.setState({ rtime: 90 }, this.countdown);
          } else if (res.data.code == 201 && res.data.status == "fail") {
            this.showNotification(res.data.message);
          }
        })
        .catch((err) => {
          // 
          this.showNotification("Something went wrong");
          $(".sendOtpLoader").hide();
          $(".sendOtpBtn").show();
        });
    } else {
      return null;
    }
  };
  render() {
    if (
      localStorage.getItem("gglltakoinoeenl") ==
      process.env.REACT_APP_LOGIN_KEY &&
      localStorage.getItem("toeljgtkewlna") != null &&
      localStorage.getItem("relleIsugad") != null
    ) {
      $("#resendUnlockOTP").addClass("text-muted");
      if (this.state.rtime == 0) {
        $("#resendUnlockOTP").removeClass("text-muted");
      }
      return (
        <div className="cont">
          <div id="notifContainer"></div>
          <Helmet>
            <link
              rel="stylesheet"
              href={process.env.PUBLIC_URL + "/css/select2.css"}
            />
          </Helmet>

          <Header />

          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "img/Path_217.svg"}
            className="sidebaropenicon"
          />
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "img/Path_231.svg"}
            className="sidebarbackicon"
          />
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "img/Icon_ionic-ios-arrow-down.svg"}
            className="sidebarexpandicon"
          />

          <div className="overlay1"></div>
          <Sidebar />

          <div className="arrow-left3"></div>
          <div className="white_div">
            <div className="white_box">
              <div className="main-row">
                <div className="myservicesortbyfilterbystatus">

                </div>
                {/* <!-- header --> */}
                <div className="inner-header d-flex flex-md-row flex-column justify-content-between px-2">
                  {/* <!-- search --> */}
                  <div className="pb-md-0 pb-5 removeSearch_margin">
                    <img
                      src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
                      alt="back"
                      className="agent__useropt__backbtn"
                      onClick={this.backbutton}
                    />
                    <form className="invisible">
                      <input
                        type="text"
                        className="search-box"
                        placeholder="Search"
                        onClick={this.handleSearchBox}
                      />
                    </form>
                  </div>

                  {/* <!-- nav-tabs -->      */}
                  <div className="navigation-tab">
                    <header className="tabs-nav position-relative">
                      <ul className="d-flex navigation-tab-ul">
                        <li className="active navigation-tab-li" id="activeTab">
                          <a href="#tab1" className="px-2 py-1">
                            Active Services
                          </a>
                        </li>
                        <li className="navigation-tab-li" id="completedTab">
                          <a href="#tab2" className="px-2 py-1">
                            Completed Services
                          </a>
                        </li>
                        <hr className="navigation-tab-vertical-line" />
                        <div className="slider"></div>
                      </ul>
                    </header>
                  </div>

                  {/* <!-- dropdowns --> */}
                  <div className="d-flex justify-content-between">
                    <div className="dropdown px-md-3 px-0">
                      <button
                        className="btn header-btn py-1 px-md-2 px-4"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          alt="loading..."
                          src={process.env.PUBLIC_URL + "/img/Path 19648.svg"}
                        />
                        <span>Sort by</span>
                      </button>
                      <div
                        className="dropdown-menu drop myser-mar-l-05"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a
                          className="dropdown-item cursorpointer"
                          onClick={this.myServiceslatestsort}
                        >
                          Latest to Oldest
                        </a>
                        <a
                          className="dropdown-item cursorpointer"
                          onClick={this.myServicesLowestsort}
                        >
                          Oldest to Latest
                        </a>
                      </div>
                    </div>

                    <div className="dropdown ml-md-0 ml-4">
                      <button
                        className="btn header-btn py-1 px-md-2 px-4"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          alt="loading..."
                          src={process.env.PUBLIC_URL + "/img/Path 210.svg"}
                        />
                        <span>Filter by</span>
                      </button>
                      <div
                        className="dropdown-menu drop myser-mar-l-04"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a
                          className="dropdown-item cursorpointer"
                          onClick={this.myServicesGSTRfilter}
                        >
                          GSTR
                        </a>
                        <a
                          className="dropdown-item cursorpointer"
                          onClick={this.myServicesGSTRFfilter}
                        >
                          GSTRF
                        </a>
                        <a
                          className="dropdown-item cursorpointer"
                          onClick={this.myServicesITRFfilter}
                        >
                          ITRF
                        </a>
                        <a
                          className="dropdown-item cursorpointer"
                          onClick={this.myServicesTRfilter}
                        >
                          TR
                        </a>
                        <a
                          className="dropdown-item cursorpointer"
                          onClick={this.myServicesOAfilter}
                        >
                          OA
                        </a>
                        <a
                          className="dropdown-item cursorpointer"
                          onClick={this.myServicesGUMASTAfilter}
                        >
                          GUMASTA
                        </a>
                        <a
                          className="dropdown-item cursorpointer"
                          onClick={this.myServicesTradeLicencefilter}
                        >
                          Trade Licence
                        </a>
                        <a
                          className="dropdown-item cursorpointer"
                          onClick={this.myServicesFoodLicencefilter}
                        >
                          Food Licence
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="userinfobar row">
                  <div>
                    <span className="userinfobar-head">User ID -</span>{" "}
                    {Decuserid}
                  </div>
                  <div>
                    <span className="userinfobar-head2">Contact -</span>{" "}
                    {this.state.contact}
                  </div>
                  <div>
                    <span className="userinfobar-head2">Email -</span>{" "}
                    {this.state.email}
                  </div>
                  <div className="ml-auto userinfobar-btn-div">
                    <button
                      className="userinfobar-btn btn text-white useropt__rn__addBtn"
                      onClick={this.addServices}
                    >
                      <img
                        className="mr-2"
                        src={process.env.PUBLIC_URL + "/img/Path_111.svg"}
                      />
                      Add service to this user
                    </button>
                    

                  </div>
                </div>
                {/* <!-- page-content --> */}
                <div className="tabs-content py-md-3 py-3 mt-md-0 mt-2">
                  {/* <!-- active --> */}
                  <div id="tab1" className="tab-content">
                    <div className="myservicescrolldiv myservicescrolldiv2">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="myseriveloaderdiv">
                            <div className="myseriveloaderinsidediv">
                              <center>
                                <img
                                  src={
                                    process.env.PUBLIC_URL + "/img/loader.gif"
                                  }
                                  className="myseriveloader"
                                />
                              </center>
                            </div>
                          </div>
                          {this.renderactiveserveData()}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- completed --> */}
                  <div id="tab2" className="tab-content">
                    <div className="myservicescrolldiv myservicescrolldiv2">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="myseriveloaderdiv">
                            <div className="myseriveloaderinsidediv">
                              <center>
                                <img
                                  src={
                                    process.env.PUBLIC_URL + "/img/loader.gif"
                                  }
                                  className="myseriveloader"
                                />
                              </center>
                            </div>
                          </div>
                          {this.rendercompletedserveData()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
          <div
            className="modal fade"
            id="Unlock-Service"
            tabIndex="-1"
            role="dialog"
            data-backdrop="static"
            data-keyboard="false"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered "
              role="document"
            >
              <div className="modal-content back-modal " id="unlockotpmodal">
                <div className="w-100 d-flex">
                  <button
                    type="button"
                    className="border-0 bg-transparent ml-auto p-2 pr-3  text-right my-back-modal-close"
                    data-dismiss="modal"
                    onClick={() => this.setState({ rtime: 1 })}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/img/Page-1.svg"}
                      width="17px"
                    />
                  </button>
                </div>
                <div className="text-center">
                  <h4 className="my-linkmodal-text enterotp">Enter OTP</h4>
                </div>
                <div className="input-userinfo otpinput emailotp">
                  <h5 className="otpinput-head">
                    Enter OTP sent to user's mobile
                  </h5>
                  <input
                    className="Userinfo-input otp-input"
                    maxLength="6"
                    type="tel"
                    value={this.state.mobileotp}
                    onChange={(e) =>
                      this.setState({ mobileotp: e.target.value })
                    }
                    id="unlockmobileinput"
                    placeholder="Enter OTP here..."
                  />
                  <p
                    className=" mt-2 warn align-items-center m-0"
                    id="invalidunlockmobileotp"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/Icon_feather-alert-circle.svg"
                      }
                      width="15px"
                      className=" mr-1"
                    />{" "}
                    <span className="invalidmobile"></span>
                  </p>
                </div>
                <div className="input-userinfo otpinput emailotp">
                  <h5 className="otpinput-head">
                    Enter OTP sent to user's email
                  </h5>
                  <input
                    className="Userinfo-input otp-input "
                    maxLength="6"
                    type="tel"
                    value={this.state.emailotp}
                    onChange={(e) =>
                      this.setState({ emailotp: e.target.value })
                    }
                    id="unlockemailinput"
                    placeholder="Enter OTP here..."
                  />
                  <p
                    className=" mt-2 warn align-items-center m-0"
                    id="invalidunlockemailotp"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/Icon_feather-alert-circle.svg"
                      }
                      width="15px"
                      className=" mr-1"
                    />{" "}
                    <span className="invalidemail"></span>
                  </p>
                </div>

                <div className="d-flex justify-content-end  otpmodaloptions">
                  {/* <h6 onClick={this.changeuserinfo}>Change mobile/email</h6>{" "} */}
                  <h6
                    className="text-muted"
                    id="resendUnlockOTP"
                    onClick={this.resenduserOtp}
                  >
                    Resend OTP{" "}
                    {this.state.rtime != 0 ? `(${this.state.rtime} Sec)` : " "}
                  </h6>
                </div>
                <p className="mb-0" id="otpsentunlocksuccess">
                  OTP sent successfully{" "}
                </p>
                <div className="modal-footer border-0 justify-content-center mb-4">
                  <img
                    className=" p-2 pl-3 pr-3 sendUnlockOtpLoader"
                    src={process.env.PUBLIC_URL + "/img/loader.gif"}
                    width="80px"
                    alt=""
                  />
                  <button
                    type="button"
                    onClick={this.SubmitOTP}
                    className="btn  send-btn text-white"
                    id="submitunlockOtpbtn"
                  >
                    Submit
                  </button>
                </div>
              </div>

              <div className="modal-content back-modal " id="unlockotp">
                <div className="w-100 d-flex">
                  <button
                    type="button"
                    className="border-0 bg-transparent ml-auto p-2 pr-3  text-right my-back-modal-close"
                    data-dismiss="modal"
                    onClick={() => this.setState({ rtime: 1 })}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/img/Page-1.svg"}
                      width="17px"
                    />
                  </button>
                </div>
                <div className="modal-body border-0 text-center pb-0">
                  <h4 className="my-warn-text unlock-text">
                    Send OTP to unlock user's information!
                  </h4>
                  <img
                    className="p-2"
                    src={process.env.PUBLIC_URL + "/img/Group 5572.svg"}
                    width="120px"
                  />
                  <p className="my-success-para m-0 contotp">
                    Continue to send OTP ?
                  </p>
                </div>
                <div className="modal-footer border-0 justify-content-center mb-4">
                  <img
                    className=" p-2 pl-3 pr-3 sendUnlockOtpLoader"
                    src={process.env.PUBLIC_URL + "/img/loader.gif"}
                    width="80px"
                    alt=""
                  />
                  <button
                    type="button"
                    onClick={() =>
                      this.serviceunlock(
                        this.state.serviceid,
                        this.state.servicename
                      )
                    }
                    className="btn  send-btn text-white  "
                    id="sendUnlockOtp"
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default MyServices;

import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import SupportDetails from "./SupportDetails";
import SupportDetailsMobile from "./SupportDetailsMobile";

var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);
let Decuserid;
let Decjwttoken;
let Decagentid;
let Decserviceid;
let Decservicename;
let Decplan;
let supportName;
let supportContact;
let supportEmail;
let temp = "general";
var sharedocumentarray = [
  {
    documentname: "",
    link: "",
  },
];
class Completeitrf extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropDownValue: "",
      monthYearDetails: [],
      commentHistroyarray: [],
      SelectedYear: "",
      serviceType: "",
      Aadhar1Link: "#",
      Aadhar2Link: "#",
      Pan1Link: "#",
      Pan2Link: "#",
      BPLink: "#",
      F16Link: "#",
      BankSLink: "#",
      PBSLink: "#",
      PRLink: "#",
      RCLink: "#",
      ICLink: "#",
      MOALink: "#",
      AOALink: "#",
      DeedLink: "#",
      DataLink: "#",
      GSTReturnLink: "#",
      TDSReturnLink: "#",
      ChallanLink: "#",
      deliveredDocuments: [],
      additionalIncdArray: [],
      additionalArray1: [],
      sharewhatsapp_radio: "Personal",
      shareemail_radio: "Legal251",
      whatsppcontactno: "",
      Selectedwhatsapptimeperiod: "select",
      Selectedemailtimeperiod: "select",
      customwhatsappdate: "",
      customemaildate: "",
      sharedocumentname: "",
      sharedocumentlink: "",
      emailmail: "",
      itrfpassword: "",
      itrfusername: "",
      Timeline: [],
      Timelinedata: false,
      timelcount: 2,
      filtertimel: [],
      Name:"",
      Contact:"",
      serviceId:""
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    $(document.body).css("background-color", "#2d2d2d");
    let mainuserid = localStorage.getItem("relleIsugad");
    Decuserid = encryptor.decrypt(mainuserid);
    let mainagentid = localStorage.getItem("qazxswedcvfrtgb");
    Decagentid = encryptor.decrypt(mainagentid);
    let mainjwttoken = localStorage.getItem("toeljgtkewlna");
    Decjwttoken = encryptor.decrypt(mainjwttoken);
    const script = document.createElement("script");
    script.src = "/js/main.js";
    script.async = true;
    document.body.appendChild(script);
    let mainserviceid = localStorage.getItem("vrellvaiecstiadeceig");
    let mainaservicename = localStorage.getItem("eieltaiclsraaemevcvnge");
    let mainplan = localStorage.getItem("ipacneglatvlael");
    // Decserviceid = encryptor.decrypt(mainserviceid);
    // Decservicename = encryptor.decrypt(mainaservicename);
    // Decplan = encryptor.decrypt(mainplan);
    supportName = localStorage.getItem("supportName");
    supportContact = localStorage.getItem("supportContact");
    supportEmail = localStorage.getItem("supportEmail");

    this.setState({
      supportName: supportName,
      supportContact: supportContact,
      supportEmail: supportEmail,
    })

    // ----------------------------temporary -----------------------------------------


    let temptoken = localStorage.getItem("fdgshdjfkgjhyhdnxhsgdhskcj")
    let Dectemptoken = encryptor.decrypt(temptoken)
    let paramsUserId2 = this.props.match.params.id2;
    if (Dectemptoken != null && Dectemptoken == paramsUserId2) {
      temp = "temporary"
    } else {
      temp = "general"
    }

    // ----------------------------temporary -----------------------------------------

    if (mainserviceid != null && mainaservicename != null && mainplan != null) {
      let paramsId = this.props.match.params.id;
      let paramsUserId = this.props.match.params.id2;
      let completedstatus = localStorage.getItem("pojasdsdjisjddd");
      Decserviceid = encryptor.decrypt(mainserviceid);
      Decservicename = encryptor.decrypt(mainaservicename);
      Decplan = encryptor.decrypt(mainplan);
      let Deccompletedstatus = encryptor.decrypt(completedstatus);
      this.getnameanumber();
      this.setState({
        serviceId: Decserviceid,
      });
      if (Deccompletedstatus == "Success") {
        this.getserviceInformationApi();
      } else {
        $(".mainsectionuploadsection").hide();
        $(".closedservicesection").show();
      }
      if (paramsId != Decserviceid || paramsUserId != Decuserid) {
        this.props.history.push("/dashboard");
      }
    } else {
      this.props.history.push("/dashboard");
    }
    let str = document.getElementsByClassName("myservicesortbyfilterbystatus").innerHTML = this.props.location.pathname.replaceAll("/", " > ")
    let mainstr = document.getElementsByClassName("myservicesortbyfilterbystatus").innerHTML = str.replace(" > ", " ")
    $(".myservicesortbyfilterbystatus").html(mainstr)
  }

  getnameanumber = () => {
    axios.post(
      process.env.REACT_APP_API_BASE_URL + "p=empexecutiveDetails",
      {
        agentId: Decagentid,
        serviceName: Decservicename,
        serviceId: Decserviceid, 
      },
      {
        headers: {
          Authkey: process.env.REACT_APP_API_KEY,
          "App-Token-Access": Decjwttoken,
        },
        auth: {
          username: process.env.REACT_APP_API_USERNAME,
          password: process.env.REACT_APP_API_PASSWORD,
        },
      }
    )
    .then((res) => {
      if (res.data.code == "200" && res.data.status == "success") {
        this.setState({
          Name: res.data.teamLeaderName,
            Contact: res.data.teamLeaderNumber,
        })
      } else if (res.data.code == "201" && res.data.status == "invalidauth") {
        this.logout();
      } else {
          this.showNotification(res.data.message);
        } 
    })
  }

  getserviceInformationApi = () => {
    $(".serviceoverlay").show();
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=completedDetails",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          plan: Decplan,
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        $(".serviceoverlay").hide();
        if (res.data.code == 200 && res.data.status == "success") {
          this.setState(
            {
              SelectedYear: res.data.monthYearDetails[0].FY,
            },
            () => {
              this.apiResponses(res);
            }
          );
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        }
      })
      .catch((err) => {
        $(".serviceoverlay").hide();
      });
  };

  getserviceInformationApi2 = () => {
    $(".serviceoverlay").show();
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=completedDetails",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          plan: Decplan,
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        $(".serviceoverlay").hide();
        if (res.data.code == 200 && res.data.status == "success") {
          this.setState(
            {
              additionalIncdArray: [],
              additionalArray1: [],
            },
            () => {
              this.apiResponses(res);
            }
          );
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        }
      })
      .catch((err) => {
        $(".serviceoverlay").hide();
      });
  };

  apiResponses = (res) => {
    this.setState(
      {
        monthYearDetails: res.data.monthYearDetails,
        deliveredDocuments: res.data.deliveredDocuments,
      },
      () => {
        if (res.data.serviceType == "ITRF_S_OneTime_Documents") {
          this.setState(
            {
              dropDownValue: "ITRF_S_OneTime_Documents",
              serviceType: "ITRF_S_OneTime_Documents",
            },
            () => {
              if (this.state.dropDownValue == "ITRF_S_OneTime_Documents") {
                $("#itrf_S").show();
                $("#itrf_P").hide();
                $("#itrf_PF").hide();
                $("#itrf_CL").hide();
              }
            }
          );

          if (res.data.informationGiven.OneTime.length > 0) {
            let doc = res.data.informationGiven.OneTime[0].documents;
            let inputField = res.data.informationGiven.OneTime[0].inputFields;
            if (doc[0].status == "done") {
              if (doc[0].AadhaarCard == null) {
                $("#SAadhar1").hide();
              } else {
                $("#SAadhar1").show();
                this.setState({
                  Aadhar1Link: doc[0].AadhaarCard,
                });
              }
            } else {
              $("#SAadhar1").hide();
            }
            if (doc[1].status == "done") {
              if (doc[1].PANCard == null) {
                $("#SPan1").hide();
              } else {
                $("#SPan1").show();
                this.setState({
                  Pan1Link: doc[1].PANCard,
                });
              }
            } else {
              $("#SPan1").hide();
            }
            if (doc[2].status == "done") {
              if (doc[2].BankProof == null) {
                $("#SBP").hide();
              } else {
                $("#SBP").show();
                this.setState({
                  BPLink: doc[2].BankProof,
                });
              }
            } else {
              $("#SBP").hide();
            }
            if (doc[3].status == "done") {
              if (doc[3].PreviousReturns == null) {
                $("#SPR").hide();
              } else {
                $("#SPR").show();
                this.setState({
                  PRLink: doc[3].PreviousReturns,
                });
              }
            } else {
              $("#SPR").hide();
            }
            if (doc[4].status == "done") {
              if (doc[4].PreviousBS == null) {
                $("#SPBS").hide();
              } else {
                $("#SPBS").show();
                this.setState({
                  PBSLink: doc[4].PreviousBS,
                });
              }
            } else {
              $("#SPBS").hide();
            }

          }

          if (res.data.informationGiven.documents.length > 0) {
            let doc = res.data.informationGiven.documents;
            if (doc[0].status == "done") {
              if (doc[0].Form16 == null) {
                $("#SF16").hide();
              } else {
                $("#SF16").show();
                this.setState({
                  F16Link: doc[0].Form16,
                });
              }
            } else {
              $("#SF16").hide();
            }
            // yaha
            if (doc[1].status == "done") {
              if (doc[1].BankStatement == null) {
                $("#SBankS").hide();
              } else {
                $("#SBankS").show();
                this.setState({
                  BankSLink: doc[1].BankStatement,
                });
              }
            } else {
              $("#SBankS").hide();
            }
          } else {
            $("#SF16").hide();
            $("#SBankS").hide();
            $("#SRecurringDiv").hide();
          }

          if (res.data.informationGiven.incD.length > 0) {
            $("#additionalincdDocumentDiv1").show();
            this.setState({
              additionalIncdArray: [
                ...res.data.informationGiven.incD[0].documents,
              ],
            });
          } else {
            $("#additionalincdDocumentDiv1").hide();
          }

          if (res.data.informationGiven.addDocInformation.length > 0) {
            $("#additionalDocumentDiv1").show();
            this.setState({
              additionalArray1: [
                ...res.data.informationGiven.addDocInformation,
              ],
            });
          } else {
            $("#additionalDocumentDiv1").hide();
          }
        } else if (res.data.serviceType == "ITRF_BP_OneTime_Documents") {
          this.setState(
            {
              dropDownValue: "ITRF_BP_OneTime_Documents",
              serviceType: "ITRF_BP_OneTime_Documents",
            },
            () => {
              if (this.state.dropDownValue == "ITRF_BP_OneTime_Documents") {
                $("#itrf_S").hide();
                $("#itrf_P").show();
                $("#itrf_PF").hide();
                $("#itrf_CL").hide();
              }
            }
          );

          if (res.data.informationGiven.OneTime.length > 0) {
            let doc = res.data.informationGiven.OneTime[0].documents;
            if (doc[0].status == "done") {
              if (doc[0].AadhaarCard == null) {
                $("#PAadhar1").hide();
              } else {
                $("#PAadhar1").show();
                this.setState({
                  Aadhar1Link: doc[0].AadhaarCard,
                });
              }
            } else {
              $("#PAadhar1").hide();
            }
            if (doc[1].status == "done") {
              if (doc[1].PANCard == null) {
                $("#PPan1").hide();
              } else {
                $("#PPan1").show();
                this.setState({
                  Pan1Link: doc[1].PANCard,
                });
              }
            } else {
              $("#PPan1").hide();
            }
            if (doc[2].status == "done") {
              if (doc[2].BankProof == null) {
                $("#PBP").hide();
              } else {
                $("#PBP").show();
                this.setState({
                  BPLink: doc[2].BankProof,
                });
              }
            } else {
              $("#PBP").hide();
            }
            if (doc[3].status == "done") {
              if (doc[3].PreviousReturns == null) {
                $("#PPR").hide();
              } else {
                $("#PPR").show();
                this.setState({
                  PRLink: doc[3].PreviousReturns,
                });
              }
            } else {
              $("#PPR").hide();
            }
            if (doc[4].status == "done") {
              if (doc[4].PreviousBS_Audit == null) {
                $("#PPBS").hide();
              } else {
                $("#PPBS").show();
                this.setState({
                  PBSLink: doc[4].PreviousBS_Audit,
                });
              }
            } else {
              $("#PPBS").hide();
            }
          }

          if (res.data.informationGiven.documents.length > 0) {
            let doc = res.data.informationGiven.documents;
            if (doc[0].status == "done") {
              if (doc[0].Data == null) {
                $("#PData").hide();
              } else {
                $("#PData").show();
                this.setState({
                  DataLink: doc[0].Data,
                });
              }
            } else {
              $("#PData").hide();
            }
            if (doc[1].status == "done") {
              if (doc[1].GSTReturn == null) {
                $("#PGSTReturn").hide();
              } else {
                $("#PGSTReturn").show();
                this.setState({
                  GSTReturnLink: doc[1].GSTReturn,
                });
              }
            } else {
              $("#PGSTReturn").hide();
            }
            if (doc[2].status == "done") {
              if (doc[2].TDSReturn == null) {
                $("#PTDSReturn").hide();
              } else {
                $("#PTDSReturn").show();
                this.setState({
                  TDSReturnLink: doc[2].TDSReturn,
                });
              }
            } else {
              $("#PTDSReturn").hide();
            }
            if (doc[3].status == "done") {
              if (doc[3].Challan == null) {
                $("#PChallan").hide();
              } else {
                $("#PChallan").show();
                this.setState({
                  ChallanLink: doc[3].Challan,
                });
              }
            } else {
              $("#PChallan").hide();
            }
            if (doc[4].status == "done") {
              if (doc[4].BankStatement == null) {
                $("#PBankS").hide();
              } else {
                $("#PBankS").show();
                this.setState({
                  BankSLink: doc[4].BankStatement,
                });
              }
            } else {
              $("#PBankS").hide();
            }
          }

          if (res.data.informationGiven.incD.length > 0) {
            $("#additionalincdDocumentDiv2").show();
            this.setState({
              additionalIncdArray: [
                ...res.data.informationGiven.incD[0].documents,
              ],
            });
          } else {
            $("#additionalincdDocumentDiv2").hide();
          }

          if (res.data.informationGiven.addDocInformation.length > 0) {
            $("#additionalDocumentDiv2").show();
            this.setState({
              additionalArray1: [
                ...res.data.informationGiven.addDocInformation,
              ],
            });
          } else {
            $("#additionalDocumentDiv2").hide();
          }
        } else if (res.data.serviceType == "ITRF_BPP_OneTime_Documents") {
          this.setState(
            {
              dropDownValue: "ITRF_BPP_OneTime_Documents",
              serviceType: "ITRF_BPP_OneTime_Documents",
            },
            () => {
              if (this.state.dropDownValue == "ITRF_BPP_OneTime_Documents") {
                $("#itrf_S").hide();
                $("#itrf_P").hide();
                $("#itrf_PF").show();
                $("#itrf_CL").hide();
              }
            }
          );
          if (res.data.informationGiven.OneTime.length > 0) {
            let doc = res.data.informationGiven.OneTime[0].documents;
            if (doc[0].status == "done") {
              if (doc[0].AadhaarCardApplicant1 == null) {
                $("#PFAadhar1").hide();
              } else {
                $("#PFAadhar1").show();
                this.setState({
                  Aadhar1Link: doc[0].AadhaarCardApplicant1,
                });
              }
            } else {
              $("#PFAadhar1").hide();
            }
            if (doc[1].status == "done") {
              if (doc[1].AadhaarCardApplicant2 == null) {
                $("#PFAadhar2").hide();
              } else {
                $("#PFAadhar2").show();
                this.setState({
                  Aadhar2Link: doc[1].AadhaarCardApplicant2,
                });
              }
            } else {
              $("#PFAadhar2").hide();
            }
            if (doc[2].status == "done") {
              if (doc[2].PANCardApplicant1 == null) {
                $("#PFPan1").hide();
              } else {
                $("#PFPan1").show();
                this.setState({
                  Pan1Link: doc[2].PANCardApplicant1,
                });
              }
            } else {
              $("#PFPan1").hide();
            }
            if (doc[3].status == "done") {
              if (doc[3].PANCardApplicant2 == null) {
                $("#PFPan2").hide();
              } else {
                $("#PFPan2").show();
                this.setState({
                  Pan2Link: doc[3].PANCardApplicant2,
                });
              }
            } else {
              $("#PFPan2").hide();
            }
            if (doc[4].status == "done") {
              if (doc[4].Deed == null) {
                $("#PFDeed").hide();
              } else {
                $("#PFDeed").show();
                this.setState({
                  DeedLink: doc[4].Deed,
                });
              }
            } else {
              $("#PFDeed").hide();
            }
            if (doc[5].status == "done") {
              if (doc[5].BankProof == null) {
                $("#PFBP").hide();
              } else {
                $("#PFBP").show();
                this.setState({
                  BPLink: doc[5].BankProof,
                });
              }
            } else {
              $("#PFBP").hide();
            }
            if (doc[6].status == "done") {
              if (doc[6].RegistrationCertificate == null) {
                $("#PFRC").hide();
              } else {
                $("#PFRC").show();
                this.setState({
                  RCLink: doc[6].RegistrationCertificate,
                });
              }
            } else {
              $("#PFRC").hide();
            }
            if (doc[7].status == "done") {
              if (doc[7].PreviousBS_Audit == null) {
                $("#PFPBS").hide();
              } else {
                $("#PFPBS").show();
                this.setState({
                  PBSLink: doc[7].PreviousBS_Audit,
                });
              }
            } else {
              $("#PFPBS").hide();
            }
            if (doc[8].status == "done") {
              if (doc[8].PreviousReturns == null) {
                $("#PFPR").hide();
              } else {
                $("#PFPR").show();
                this.setState({
                  PRLink: doc[8].PreviousReturns,
                });
              }
            } else {
              $("#PFPR").hide();
            }
          }

          if (res.data.informationGiven.documents.length > 0) {
            let doc = res.data.informationGiven.documents;
            if (doc[0].status == "done") {
              if (doc[0].Data == null) {
                $("#PFData").hide();
              } else {
                $("#PFData").show();
                this.setState({
                  DataLink: doc[0].Data,
                });
              }
            } else {
              $("#PFData").hide();
            }
            if (doc[1].status == "done") {
              if (doc[1].GSTReturn == null) {
                $("#PFGSTReturn").hide();
              } else {
                $("#PFGSTReturn").show();
                this.setState({
                  GSTReturnLink: doc[1].GSTReturn,
                });
              }
            } else {
              $("#PFGSTReturn").hide();
            }
            if (doc[2].status == "done") {
              if (doc[2].TDSReturn == null) {
                $("#PFTDSReturn").hide();
              } else {
                $("#PFTDSReturn").show();
                this.setState({
                  TDSReturnLink: doc[2].TDSReturn,
                });
              }
            } else {
              $("#PFTDSReturn").hide();
            }
            if (doc[3].status == "done") {
              if (doc[3].Challan == null) {
                $("#PFChallan").hide();
              } else {
                $("#PFChallan").show();
                this.setState({
                  ChallanLink: doc[3].Challan,
                });
              }
            } else {
              $("#PFChallan").hide();
            }
            if (doc[4].status == "done") {
              if (doc[4].BankStatement == null) {
                $("#PFBankS").hide();
              } else {
                $("#PFBankS").show();
                this.setState({
                  BankSLink: doc[4].BankStatement,
                });
              }
            } else {
              $("#PFBankS").hide();
            }
          }

          if (res.data.informationGiven.incD.length > 0) {
            $("#additionalincdDocumentDiv3").show();
            this.setState({
              additionalIncdArray: [
                ...res.data.informationGiven.incD[0].documents,
              ],
            });
          } else {
            $("#additionalincdDocumentDiv3").hide();
          }

          if (res.data.informationGiven.addDocInformation.length > 0) {
            $("#additionalDocumentDiv3").show();
            this.setState({
              additionalArray1: [
                ...res.data.informationGiven.addDocInformation,
              ],
            });
          } else {
            $("#additionalDocumentDiv3").hide();
          }
        } else if (res.data.serviceType == "ITRF_BPC_OneTime_Documents") {
          this.setState(
            {
              dropDownValue: "ITRF_BPC_OneTime_Documents",
              serviceType: "ITRF_BPC_OneTime_Documents",
            },
            () => {
              if (this.state.dropDownValue == "ITRF_BPC_OneTime_Documents") {
                $("#itrf_S").hide();
                $("#itrf_P").hide();
                $("#itrf_PF").hide();
                $("#itrf_CL").show();
              }
            }
          );

          if (res.data.informationGiven.OneTime.length > 0) {
            let doc = res.data.informationGiven.OneTime[0].documents;
            if (doc[0].status == "done") {
              if (doc[0].AadhaarCardApplicant1 == null) {
                $("#CLAadhar1").hide();
              } else {
                $("#CLAadhar1").show();
                this.setState({
                  Aadhar1Link: doc[0].AadhaarCardApplicant1,
                });
              }
            } else {
              $("#CLAadhar1").hide();
            }
            if (doc[1].status == "done") {
              if (doc[1].AadhaarCardApplicant2 == null) {
                $("#CLAadhar2").hide();
              } else {
                $("#CLAadhar2").show();
                this.setState({
                  Aadhar2Link: doc[1].AadhaarCardApplicant2,
                });
              }
            } else {
              $("#CLAadhar2").hide();
            }
            if (doc[2].status == "done") {
              if (doc[2].PANCardApplicant1 == null) {
                $("#CLPan1").hide();
              } else {
                $("#CLPan1").show();
                this.setState({
                  Pan1Link: doc[2].PANCardApplicant1,
                });
              }
            } else {
              $("#CLPan1").hide();
            }
            if (doc[3].status == "done") {
              if (doc[3].PANCardApplicant2 == null) {
                $("#CLPan2").hide();
              } else {
                $("#CLPan2").show();
                this.setState({
                  Pan2Link: doc[3].PANCardApplicant2,
                });
              }
            } else {
              $("#CLPan2").hide();
            }
            if (doc[4].status == "done") {
              if (doc[4].IncorporationCertificate == null) {
                $("#CLIC").hide();
              } else {
                $("#CLIC").show();
                this.setState({
                  ICLink: doc[4].IncorporationCertificate,
                });
              }
            } else {
              $("#CLIC").hide();
            }
            if (doc[5].status == "done") {
              if (doc[5].MOA == null) {
                $("#CLMOA").hide();
              } else {
                $("#CLMOA").show();
                this.setState({
                  MOALink: doc[5].MOA,
                });
              }
            } else {
              $("#CLMOA").hide();
            }
            if (doc[6].status == "done") {
              if (doc[6].AOA == null) {
                $("#CLAOA").hide();
              } else {
                $("#CLAOA").show();
                this.setState({
                  AOALink: doc[6].AOA,
                });
              }
            } else {
              $("#CLAOA").hide();
            }
            if (doc[7].status == "done") {
              if (doc[7].BankProof == null) {
                $("#CLBP").hide();
              } else {
                $("#CLBP").show();
                this.setState({
                  BPLink: doc[7].BankProof,
                });
              }
            } else {
              $("#CLBP").hide();
            }
            if (doc[8].status == "done") {
              if (doc[8].PreviousBS_Audit == null) {
                $("#CLPBS").hide();
              } else {
                $("#CLPBS").show();
                this.setState({
                  PBSLink: doc[8].PreviousBS_Audit,
                });
              }
            } else {
              $("#CLPBS").hide();
            }
            if (doc[9].status == "done") {
              if (doc[9].PreviousReturns == null) {
                $("#CLPR").hide();
              } else {
                $("#CLPR").show();
                this.setState({
                  PRLink: doc[9].PreviousReturns,
                });
              }
            } else {
              $("#CLPR").hide();
            }
          }

          if (res.data.informationGiven.documents.length > 0) {
            let doc = res.data.informationGiven.documents;
            if (doc[0].status == "done") {
              if (doc[0].Data == null) {
                $("#CLData").hide();
              } else {
                $("#CLData").show();
                this.setState({
                  DataLink: doc[0].Data,
                });
              }
            } else {
              $("#CLData").hide();
            }
            if (doc[1].status == "done") {
              if (doc[1].GSTReturn == null) {
                $("#CLGSTReturn").hide();
              } else {
                $("#CLGSTReturn").show();
                this.setState({
                  GSTReturnLink: doc[1].GSTReturn,
                });
              }
            } else {
              $("#CLGSTReturn").hide();
            }
            if (doc[2].status == "done") {
              if (doc[2].TDSReturn == null) {
                $("#CLTDSReturn").hide();
              } else {
                $("#CLTDSReturn").show();
                this.setState({
                  TDSReturnLink: doc[2].TDSReturn,
                });
              }
            } else {
              $("#CLTDSReturn").hide();
            }
            if (doc[3].status == "done") {
              if (doc[3].Challan == null) {
                $("#CLChallan").hide();
              } else {
                $("#CLChallan").show();
                this.setState({
                  ChallanLink: doc[3].Challan,
                });
              }
            } else {
              $("#CLChallan").hide();
            }
            if (doc[4].status == "done") {
              if (doc[4].BankStatement == null) {
                $("#CLBankS").hide();
              } else {
                $("#CLBankS").show();
                this.setState({
                  BankSLink: doc[4].BankStatement,
                });
              }
            } else {
              $("#CLBankS").hide();
            }
          }

          if (res.data.informationGiven.incD.length > 0) {
            $("#additionalincdDocumentDiv4").show();
            this.setState({
              additionalIncdArray: [
                ...res.data.informationGiven.incD[0].documents,
              ],
            });
          } else {
            $("#additionalincdDocumentDiv4").hide();
          }

          if (res.data.informationGiven.addDocInformation.length > 0) {
            $("#additionalDocumentDiv4").show();
            this.setState({
              additionalArray1: [
                ...res.data.informationGiven.addDocInformation,
              ],
            });
          } else {
            $("#additionalDocumentDiv4").hide();
          }
        }
        if (res.data.informationGiven.OneTime[0].inputFields.length > 0) {
          if (res.data.informationGiven.OneTime[0].inputFields[0].PortalUserID != null && res.data.informationGiven.OneTime[0].inputFields[1].PortalPassword != null) {
            this.setState({
              itrfusername: res.data.informationGiven.OneTime[0].inputFields[0].PortalUserID,
              itrfpassword: res.data.informationGiven.OneTime[0].inputFields[1].PortalPassword,
            })
            $(".itrfusername").attr("disabled", true)
            $(".itrfpassword").attr("disabled", true)
          }
        }
      }
    );
  };

  handleYearChange = (e) => {
    this.setState(
      {
        SelectedYear: e.target.value,
      },
      () => {
        $(".commentsbtn").show();
        $(".commentsbtn").attr("disabled", false);
        $(".hidecommentshistorybtn").hide();
        $(".TDRightSide").hide();
        $(".commentimg-section2").show();
        this.getserviceInformationApi2();
      }
    );
  };

  YearsToBeShown() {
    return this.state.monthYearDetails.map((month, index) => {
      return (
        <option key={month.FY} value={month.FY} id={index}>
          {month.FY}
        </option>
      );
    });
  }

  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };

  renderCommenthistory() {
    if (this.state.commentHistroyarray.length > 0) {
      return this.state.commentHistroyarray.map((activedoc, index) => {
        if (activedoc.commentBy == "user" && activedoc.special == "no") {
          return (
            <div className="rightconvtextdiv" key={index}>
              <p className="rightconvtext">{activedoc.comment}</p>
              <p className="rightconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "user" &&
          activedoc.special == "green"
        ) {
          return (
            <div className="rightconvtextdiv" key={index}>
              <p className="rightgreenconvtext">{activedoc.comment}</p>
              <p className="rightconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "employee" &&
          activedoc.special == "no"
        ) {
          return (
            <div className="leftconvtextdiv" key={index}>
              <p className="leftconvtext">{activedoc.comment}</p>
              <p className="leftconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "user" &&
          activedoc.special == "red"
        ) {
          return (
            <div className="rightunappconvtextdiv" key={index}>
              <p className="rightunappconvtext">{activedoc.comment}</p>
              <p className="rightconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "employee" &&
          activedoc.special == "green"
        ) {
          return (
            <div className="leftgreenconvtextdiv" key={index}>
              <p className="leftgreenconvtext">{activedoc.comment}</p>
              <p className="leftconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "employee" &&
          activedoc.special == "red"
        ) {
          return (
            <div className="leftconvtextdiv" key={index}>
              <p className="leftunappconvtext">{activedoc.comment}</p>
              <p className="leftconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        }
      });
    } else if (this.state.commentHistroyarray.length == 0) {
      return <p className="convservicename">No comments available!</p>;
    }
  }

  logout = () => {
    localStorage.removeItem("gglltakoinoeenl");
    localStorage.removeItem("toeljgtkewlna");
    localStorage.removeItem("qazxswedcvfrtgb");
    localStorage.removeItem("lshhihfnkff");
    this.props.history.push({
      pathname: `/`,
    });
  };

  AdditionalincdFilesArea = (serviceTypeName) => {
    if (this.state.dropDownValue == serviceTypeName) {
      if (this.state.additionalIncdArray.length > 0) {
        return this.state.additionalIncdArray.map((file, index) => {
          let NameOfAddFile = Object.keys(file)[0];
          let link = file[Object.keys(file)[0]];
          return (
            <div className="displayflex" key={index}>
              <div className="uploadnocol">
                <p className="uploadadditionalsidetext">{index + 1}&#41;</p>
              </div>
              <div className="uploadadditionaldatacol">
                <div className="row mb-2">
                  <div className="col-md-7">
                    <input
                      type="text"
                      className="uploadtextfield marginzero"
                      value={NameOfAddFile}
                      readOnly
                    />
                  </div>
                  <div className="col-md-5">
                    <div className="float-md-right float-left">
                      <div className="displayflex">
                        <div className="viewbtndivpadding">
                          <a className="viewbtn" href={link} target="_blank">
                            View{" "}
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/Path 19498.svg"
                              }
                            />
                          </a>
                        </div>
                        <div>
                          <button className="tick_btn">
                            {" "}
                            <img
                              src={process.env.PUBLIC_URL + "/img/tick.svg"}
                            />
                          </button>
                        </div>
                      </div>
                      <button
                        className="seecommentshistorybtn commentsbtn Fileseecommentsbtnincd"
                        id={`${index}Fileseecommentsbtnincd`}
                        onClick={() => this.handleFileseeCommentsincd(index)}
                      >
                        See comment history{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                        />
                      </button>
                      <button
                        className="hidecommentshistorybtn Filehidecommentsbtnincd"
                        id={`${index}Filehidecommentsbtnincd`}
                        onClick={() => this.handleFilehideCommentsincd(index)}
                      >
                        Hide comment history{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        });
      } else {
        $("#additionalincdDocumentDiv1").hide();
        $("#additionalincdDocumentDiv2").hide();
        $("#additionalincdDocumentDiv3").hide();
        $("#additionalincdDocumentDiv4").hide();
      }
    }
  };

  handleFileseeCommentsincd = (index) => {
    $(".commentsbtn").attr("disabled", false);
    $(`#${index}Fileseecommentsbtnincd`).attr("disabled", true);
    let SelectedFile = this.state.additionalIncdArray[index];
    let NameOfAddFile = Object.keys(SelectedFile)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "incd",
          documentName: NameOfAddFile,
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(`#${index}Fileseecommentsbtnincd`).hide();
          $(".hidecommentshistorybtn").hide();
          $(`#${index}Filehidecommentsbtnincd`).show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        }
        else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        }
        else {
          $(`#${index}Fileseecommentsbtnincd`).attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $(`#${index}Fileseecommentsbtnincd`).attr("disabled", false);
        this.showNotification("Something Went wrong");
      });
  };

  handleFilehideCommentsincd = (index) => {
    $(".commentsbtn").show();
    $(`#${index}Fileseecommentsbtnincd`).show();
    $(`#${index}Fileseecommentsbtnincd`).attr("disabled", false);
    $(`#${index}Filehidecommentsbtnincd`).hide();
    $(".hidecommentshistorybtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  AdditionalFilesArea = (serviceTypeName) => {
    if (this.state.dropDownValue == serviceTypeName) {
      if (this.state.additionalArray1.length > 0) {
        return this.state.additionalArray1.map((file, index) => {
          let NameOfAddFile = Object.keys(file)[0];
          let link = file[Object.keys(file)[0]];
          return (
            <div className="displayflex" key={index}>
              <div className="uploadnocol">
                <p className="uploadadditionalsidetext">{index + 1}&#41;</p>
              </div>
              <div className="uploadadditionaldatacol">
                <div className="row mb-2">
                  <div className="col-md-7">
                    <input
                      type="text"
                      className="uploadtextfield marginzero"
                      value={NameOfAddFile}
                      readOnly
                    />
                  </div>
                  <div className="col-md-5">
                    <div className="float-md-right float-left">
                      <div className="displayflex">
                        <div className="viewbtndivpadding">
                          <a className="viewbtn" href={link} target="_blank">
                            View{" "}
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/Path 19498.svg"
                              }
                            />
                          </a>
                        </div>
                        <div>
                          <button className="tick_btn">
                            {" "}
                            <img
                              src={process.env.PUBLIC_URL + "/img/tick.svg"}
                            />
                          </button>
                        </div>
                      </div>
                      <button
                        className="seecommentshistorybtn commentsbtn Fileseecommentsbtn1"
                        id={`${index}Fileseecommentsbtn1`}
                        onClick={() => this.handleFileseeComments1(index)}
                      >
                        See comment history{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                        />
                      </button>
                      <button
                        className="hidecommentshistorybtn Filehidecommentsbtn1"
                        id={`${index}Filehidecommentsbtn1`}
                        onClick={() => this.handleFilehideComments1(index)}
                      >
                        Hide comment history{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        });
      } else {
        $("#additionalDocumentDiv1").hide();
        $("#additionalDocumentDiv2").hide();
        $("#additionalDocumentDiv3").hide();
        $("#additionalDocumentDiv4").hide();
      }
    }
  };

  handleFileseeComments1 = (index) => {
    $(".commentsbtn").attr("disabled", false);
    $(`#${index}Fileseecommentsbtn1`).attr("disabled", true);
    let SelectedFile = this.state.additionalArray1[index];
    let NameOfAddFile = Object.keys(SelectedFile)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "additional",
          documentName: NameOfAddFile,
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(`#${index}Fileseecommentsbtn1`).hide();
          $(".hidecommentshistorybtn").hide();
          $(`#${index}Filehidecommentsbtn1`).show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          $(`#${index}Fileseecommentsbtn1`).attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $(`#${index}Fileseecommentsbtn1`).attr("disabled", false);
        this.showNotification("Something Went wrong");
      });
  };

  handleFilehideComments1 = (index) => {
    $(".commentsbtn").show();
    $(`#${index}Fileseecommentsbtn1`).show();
    $(`#${index}Fileseecommentsbtn1`).attr("disabled", false);
    $(`#${index}Filehidecommentsbtn1`).hide();
    $(".hidecommentshistorybtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleSAadhar1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SAadhar1seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "AadhaarCard",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#SAadhar1seebtn").hide();
          $("#SAadhar1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSAadhar1hidecommentsbtn = () => {
    $("#SAadhar1seebtn").show();
    $("#SAadhar1seebtn").attr("disabled", false);
    $("#SAadhar1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleSPan1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SPan1seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "PANCard",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#SPan1seebtn").hide();
          $("#SPan1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSPan1hidecommentsbtn = () => {
    $("#SPan1seebtn").show();
    $("#SPan1seebtn").attr("disabled", false);
    $("#SPan1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleSBPseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SBPseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "BankProof",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#SBPseebtn").hide();
          $("#SBPhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSBPhidecommentsbtn = () => {
    $("#SBPseebtn").show();
    $("#SBPseebtn").attr("disabled", false);
    $("#SBPhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleSPRseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SPRseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "PreviousReturns",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#SPRseebtn").hide();
          $("#SPRhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSPRhidecommentsbtn = () => {
    $("#SPRseebtn").show();
    $("#SPRseebtn").attr("disabled", false);
    $("#SPRhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleSPBSseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SPBSseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "PreviousBS",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#SPBSseebtn").hide();
          $("#SPBShidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSPBShidecommentsbtn = () => {
    $("#SPBSseebtn").show();
    $("#SPBSseebtn").attr("disabled", false);
    $("#SPBShidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleSBankSseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SBankSseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "BankStatement",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#SBankSseebtn").hide();
          $("#SBankShidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSBankShidecommentsbtn = () => {
    $("#SBankSseebtn").show();
    $("#SBankSseebtn").attr("disabled", false);
    $("#SBankShidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleSF16seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SF16seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "Form16",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#SF16seebtn").hide();
          $("#SF16hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSF16hidecommentsbtn = () => {
    $("#SF16seebtn").show();
    $("#SF16seebtn").attr("disabled", false);
    $("#SF16hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePAadhar1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PAadhar1seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "AadhaarCard",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PAadhar1seebtn").hide();
          $("#PAadhar1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePAadhar1hidecommentsbtn = () => {
    $("#PAadhar1seebtn").show();
    $("#PAadhar1seebtn").attr("disabled", false);
    $("#PAadhar1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePPan1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PPan1seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "PANCard",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PPan1seebtn").hide();
          $("#PPan1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePPan1hidecommentsbtn = () => {
    $("#PPan1seebtn").show();
    $("#PPan1seebtn").attr("disabled", false);
    $("#PPan1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePBPseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PBPseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "BankProof",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PBPseebtn").hide();
          $("#PBPhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePBPhidecommentsbtn = () => {
    $("#PBPseebtn").show();
    $("#PBPseebtn").attr("disabled", false);
    $("#PBPhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePPRseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PPRseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "PreviousReturns",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PPRseebtn").hide();
          $("#PPRhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlewhatsppcontactno = (e) => {
    this.setState({
      whatsppcontactno: e.target.value,
    });
  };
  handleemailmail = (e) => {
    this.setState({
      emailmail: e.target.value,
    });
  };

  handlecustomwhatsappdateChange = (e) => {
    this.setState({
      customwhatsappdate: e.target.value,
    });
  };

  handlecustomemaildateChange = (e) => {
    this.setState({
      customemaildate: e.target.value,
    });
  };

  handlewhatsapptimeperiodChange = (e) => {
    this.setState({
      Selectedwhatsapptimeperiod: e.target.value,
    });
    if (e.target.value == "custom") {
      $("#whatsappdateinput").show();
    } else if (e.target.value == "always") {
      $("#whatsappdateinput").hide();
      $(".sharedocmentwhatsapplifeerrormessage").show();
    } else {
      $("#whatsappdateinput").hide();
      $(".sharedocmentwhatsapplifeerrormessage").hide();
    }
  };
  handleemailtimeperiodChange = (e) => {
    $(".sharedocmentemaildateerrormessage").hide();
    this.setState({
      Selectedemailtimeperiod: e.target.value,
    });
    if (e.target.value == "custom") {
      $("#emaildateinput").show();
    } else if (e.target.value == "always") {
      $("#emaildateinput").hide();
      $(".sharedocmentemaillifeerrormessage").show();
    } else {
      $("#emaildateinput").hide();
      $(".sharedocmentemaillifeerrormessage").hide();
    }
  };

  whatsapp = (docname, link) => {
    $(".myAddModal1").modal({ backdrop: "static", keyboard: false });
    this.setState({
      sharedocumentname: docname,
      sharedocumentlink: link,
    });
    sharedocumentarray[0].documentname = docname;
    sharedocumentarray[0].link = link;
  };

  email = (docname, link) => {
    $(".myAddModal2").modal({ backdrop: "static", keyboard: false });
    this.setState({
      sharedocumentname: docname,
      sharedocumentlink: link,
    });
    sharedocumentarray[0].documentname = docname;
    sharedocumentarray[0].link = link;
  };

  submitemail = () => {
    let ToDate = new Date();
    if (this.state.emailmail.trim() === "") {
      $(".sharedocmentemailerrormessage").show();
      $(".sharedocmentemailerrormessage").html("Please enter  E-mail");
    } else if (this.state.emailmail.trim().length > 50) {
      $(".sharedocmentemailerrormessage").show();
      $(".sharedocmentemailerrormessage").html(
        "E-mail should be less than 50 characters"
      );
    } else if (
      !this.state.emailmail
        .trim()
        .match(/^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i)
    ) {
      $(".sharedocmentemailerrormessage").show();
      $(".sharedocmentemailerrormessage").html("Please enter  valid E-mail");
    } else if (this.state.Selectedemailtimeperiod == "select") {
      $(".sharedocmentemailerrormessage").hide();
      $(".sharedocmentemailselecterrormessage").show();
      $(".sharedocmentemailselecterrormessage").html("Please select a option");
    } else if (
      this.state.Selectedemailtimeperiod == "custom" &&
      this.state.customemaildate == ""
    ) {
      $(".sharedocmentemailerrormessage").hide();
      $(".sharedocmentemailselecterrormessage").hide();
      $(".sharedocmentemaildateerrormessage").html("Please enter date");
      $(".sharedocmentemaildateerrormessage").show();
    } else if (
      this.state.Selectedemailtimeperiod == "custom" &&
      new Date(this.state.customemaildate).getTime() < ToDate.getTime()
    ) {
      $(".sharedocmentemailerrormessage").hide();
      $(".sharedocmentemailselecterrormessage").hide();
      $(".sharedocmentemaildateerrormessage").html("Please enter a vaild date");
      $(".sharedocmentemaildateerrormessage").show();
    } else {
      $(".upload_btn20_70").hide();
      $(".upload_btnloader_70").show();
      $(".cancelbtn_70").attr("disabled", true);
      var localemaildate = this.state.customemaildate;
      if (
        this.state.Selectedemailtimeperiod != "custom" &&
        this.state.customemaildate != ""
      ) {
        localemaildate = "";
      }

      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=sharedocumentEmailNormal",
          {
            userId: Decuserid,
            requestType: temp,
            agentId: Decagentid,
            emailto: this.state.emailmail.trim(),
            duration: this.state.Selectedemailtimeperiod,
            custom: localemaildate,
            type: this.state.shareemail_radio,
            shareableDocument: sharedocumentarray,
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((resp) => {
          $(".upload_btn20_70").show();
          $(".upload_btnloader_70").hide();
          $(".cancelbtn_70").attr("disabled", false);
          $(".myAddModal2").modal("hide");
          $(".sharedocmentemaillifeerrormessage").hide();
          $(".sharedocmentemailerrormessage").hide();
          $(".sharedocmentemailselecterrormessage").hide();
          $(".sharedocmentemaildateerrormessage").hide();
          var localemailmail = this.state.emailmail;
          var localradio = this.state.shareemail_radio;
          this.setState({
            Selectedemailtimeperiod: "select",
            emailmail: "",
            shareemail_radio: "Legal251",
            customemaildate: "",
          });
          $("#emaildateinput").hide();
          if (resp.data.code == 200 && resp.data.status == "success") {
            if (localradio == "Personal") {
              window.open(
                `mailto:${localemailmail}?subject=Shared%20Documents%20-%20Legal251&body=Hi%20there%20!!%20I%20just%20shared%20following%20documents%20with%20you:-%0a${resp.data.documentLinks[0].documentname}%0a${resp.data.documentLinks[0].link}`,
                "_blank"
              );
            } else {
              $(".myAddModal3").modal({ backdrop: "static", keyboard: false });
              setTimeout(function () {
                $(".myAddModal3").modal("hide");
              }, 3000);
            }
          } else if (
            resp.data.code == "201" &&
            resp.data.status == "invalidauth"
          ) {
            this.logout();
          }
          else if (resp.data.code == "201" && resp.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          }
          else {
            this.showNotification(resp.data.message);
          }
        })
        .catch((err) => {
          this.showNotification("Something went wrong, Try again!");
        });
    }
  };

  submitwhatsapp = () => {
    let ToDate = new Date();
    if (this.state.whatsppcontactno.trim() === "") {
      $(".sharedocmentwhatsapperrormessage").show();
      $(".sharedocmentwhatsapperrormessage").html("Please enter  Mobile No");
    } else if (!this.state.whatsppcontactno.trim().match(/^[6-9]\d{9}$/)) {
      $(".sharedocmentwhatsapperrormessage").show();
      $(".sharedocmentwhatsapperrormessage").html(
        "Please enter  valid Mobile No"
      );
    } else if (this.state.Selectedwhatsapptimeperiod == "select") {
      $(".sharedocmentwhatsapperrormessage").hide();
      $(".sharedocmentwhatsappselecterrormessage").show();
      $(".sharedocmentwhatsappselecterrormessage").html(
        "Please select a option"
      );
    } else if (
      this.state.Selectedwhatsapptimeperiod == "custom" &&
      this.state.customwhatsappdate == ""
    ) {
      $(".sharedocmentwhatsapperrormessage").hide();
      $(".sharedocmentwhatsappselecterrormessage").hide();
      $(".sharedocmentwhatsappdateerrormessage").html("Please enter date");
      $(".sharedocmentwhatsappdateerrormessage").show();
    } else if (
      this.state.Selectedwhatsapptimeperiod == "custom" &&
      new Date(this.state.customwhatsappdate).getTime() < ToDate.getTime()
    ) {
      $(".sharedocmentwhatsapperrormessage").hide();
      $(".sharedocmentwhatsappselecterrormessage").hide();
      $(".sharedocmentwhatsappdateerrormessage").html(
        "Please a enter vaild date"
      );
      $(".sharedocmentwhatsappdateerrormessage").show();
    } else {
      $(".upload_btn20_90").hide();
      $(".upload_btnloader_90").show();
      $(".cancelbtn_90").attr("disabled", true);
      var localwhatsappdate = this.state.customwhatsappdate;
      if (
        this.state.Selectedwhatsapptimeperiod != "custom" &&
        this.state.customwhatsappdate != ""
      ) {
        localwhatsappdate = "";
      }
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=sharedocumentLinkNormal",
          {
            userId: Decuserid,
            requestType: temp,
            agentId: Decagentid,
            duration: this.state.Selectedwhatsapptimeperiod,
            custom: localwhatsappdate,
            shareableDocument: sharedocumentarray,
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((resp) => {
          $(".upload_btn20_90").show();
          $(".upload_btnloader_90").hide();
          $(".cancelbtn_90").attr("disabled", false);
          $(".myAddModal1").modal("hide");
          $(".sharedocmentwhatsapperrormessage").hide();
          $(".sharedocmentwhatsapplifeerrormessage").hide();
          $(".sharedocmentwhatsappselecterrormessage").hide();
          $(".sharedocmentwhatsappdateerrormessage").hide();
          var localwhatsppcontactno = this.state.whatsppcontactno;
          this.setState({
            Selectedwhatsapptimeperiod: "select",
            customemaildate: "",
            whatsppcontactno: "",
          });
          $("#whatsappdateinput").hide();

          if (resp.data.code == 200 && resp.data.status == "success") {
            window.open(
              `https://web.whatsapp.com/send?phone=91${localwhatsppcontactno}&text=Hi%20there%20!!%20I%20just%20shared%20following%20documents%20with%20you:-%0a${resp.data.documentLinks[0].documentname}%0a${resp.data.documentLinks[0].link}`,
              "_blank"
            );
          } else if (
            resp.data.code == "201" &&
            resp.data.status == "invalidauth"
          ) {
            this.logout();
          } else {
            this.showNotification(resp.data.message);
          }
        })
        .catch((err) => {
          this.showNotification("Something went wrong, Try again!");
        });
    }
  };

  sharewhatsapp_radioChange = (e) => {
    this.setState({
      sharewhatsapp_radio: e.currentTarget.value,
    });
  };
  shareemail_radioChange = (e) => {
    this.setState({
      shareemail_radio: e.currentTarget.value,
    });
  };

  handlePPRhidecommentsbtn = () => {
    $("#PPRseebtn").show();
    $("#PPRseebtn").attr("disabled", false);
    $("#PPRhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePPBSseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PPBSseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "PreviousBS_Audit",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PPBSseebtn").hide();
          $("#PPBShidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePPBShidecommentsbtn = () => {
    $("#PPBSseebtn").show();
    $("#PPBSseebtn").attr("disabled", false);
    $("#PPBShidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePDataseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PDataseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "Data",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PDataseebtn").hide();
          $("#PDatahidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePDatahidecommentsbtn = () => {
    $("#PDataseebtn").show();
    $("#PDataseebtn").attr("disabled", false);
    $("#PDatahidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePBankSseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PBankSseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "BankStatement",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PBankSseebtn").hide();
          $("#PBankShidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePBankShidecommentsbtn = () => {
    $("#PBankSseebtn").show();
    $("#PBankSseebtn").attr("disabled", false);
    $("#PBankShidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePGSTReturnseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PGSTReturnseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "GSTReturn",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PGSTReturnseebtn").hide();
          $("#PGSTReturnhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePGSTReturnhidecommentsbtn = () => {
    $("#PGSTReturnseebtn").show();
    $("#PGSTReturnseebtn").attr("disabled", false);
    $("#PGSTReturnhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePTDSReturnseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PTDSReturnseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "TDSReturn",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PTDSReturnseebtn").hide();
          $("#PTDSReturnhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePTDSReturnhidecommentsbtn = () => {
    $("#PTDSReturnseebtn").show();
    $("#PTDSReturnseebtn").attr("disabled", false);
    $("#PTDSReturnhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePChallanseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PChallanseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "Challan",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PChallanseebtn").hide();
          $("#PChallanhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePChallanhidecommentsbtn = () => {
    $("#PChallanseebtn").show();
    $("#PChallanseebtn").attr("disabled", false);
    $("#PChallanhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePFAadhar1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PFAadhar1seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "AadhaarCardApplicant1",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PFAadhar1seebtn").hide();
          $("#PFAadhar1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePFAadhar1hidecommentsbtn = () => {
    $("#PFAadhar1seebtn").show();
    $("#PFAadhar1seebtn").attr("disabled", false);
    $("#PFAadhar1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePFAadhar2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PFAadhar2seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "AadhaarCardApplicant2",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PFAadhar2seebtn").hide();
          $("#PFAadhar2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePFAadhar2hidecommentsbtn = () => {
    $("#PFAadhar2seebtn").show();
    $("#PFAadhar2seebtn").attr("disabled", false);
    $("#PFAadhar2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePFPan1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PFPan1seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "PANCardApplicant1",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PFPan1seebtn").hide();
          $("#PFPan1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePFPan1hidecommentsbtn = () => {
    $("#PFPan1seebtn").show();
    $("#PFPan1seebtn").attr("disabled", false);
    $("#PFPan1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePFPan2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PFPan2seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "PANCardApplicant2",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PFPan2seebtn").hide();
          $("#PFPan2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePFPan2hidecommentsbtn = () => {
    $("#PFPan2seebtn").show();
    $("#PFPan2seebtn").attr("disabled", false);
    $("#PFPan2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePFDeedseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PFDeedseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "Deed",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PFDeedseebtn").hide();
          $("#PFDeedhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePFDeedhidecommentsbtn = () => {
    $("#PFDeedseebtn").show();
    $("#PFDeedseebtn").attr("disabled", false);
    $("#PFDeedhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePFBPseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PFBPseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "BankProof",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PFBPseebtn").hide();
          $("#PFBPhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePFBPhidecommentsbtn = () => {
    $("#PFBPseebtn").show();
    $("#PFBPseebtn").attr("disabled", false);
    $("#PFBPhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePFRCseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PFRCseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "RegistrationCertificate",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PFRCseebtn").hide();
          $("#PFRChidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePFRChidecommentsbtn = () => {
    $("#PFRCseebtn").show();
    $("#PFRCseebtn").attr("disabled", false);
    $("#PFRChidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePFPBSseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PFPBSseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "PreviousBS_Audit",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PFPBSseebtn").hide();
          $("#PFPBShidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePFPBShidecommentsbtn = () => {
    $("#PFPBSseebtn").show();
    $("#PFPBSseebtn").attr("disabled", false);
    $("#PFPBShidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePFPRseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PFPRseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "PreviousReturns",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PFPRseebtn").hide();
          $("#PFPRhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePFPRhidecommentsbtn = () => {
    $("#PFPRseebtn").show();
    $("#PFPRseebtn").attr("disabled", false);
    $("#PFPRhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePFDataseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PFDataseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "Data",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PFDataseebtn").hide();
          $("#PFDatahidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePFDatahidecommentsbtn = () => {
    $("#PFDataseebtn").show();
    $("#PFDataseebtn").attr("disabled", false);
    $("#PFDatahidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePFBankSseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PFBankSseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "BankStatement",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PFBankSseebtn").hide();
          $("#PFBankShidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePFBankShidecommentsbtn = () => {
    $("#PFBankSseebtn").show();
    $("#PFBankSseebtn").attr("disabled", false);
    $("#PFBankShidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePFGSTReturnseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PFGSTReturnseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "GSTReturn",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PFGSTReturnseebtn").hide();
          $("#PFGSTReturnhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePFGSTReturnhidecommentsbtn = () => {
    $("#PFGSTReturnseebtn").show();
    $("#PFGSTReturnseebtn").attr("disabled", false);
    $("#PFGSTReturnhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePFTDSReturnseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PFTDSReturnseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "TDSReturn",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PFTDSReturnseebtn").hide();
          $("#PFTDSReturnhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePFTDSReturnhidecommentsbtn = () => {
    $("#PFTDSReturnseebtn").show();
    $("#PFTDSReturnseebtn").attr("disabled", false);
    $("#PFTDSReturnhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePFChallanseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PFChallanseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "Challan",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PFChallanseebtn").hide();
          $("#PFChallanhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePFChallanhidecommentsbtn = () => {
    $("#PFChallanseebtn").show();
    $("#PFChallanseebtn").attr("disabled", false);
    $("#PFChallanhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleCLAadhar1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#CLAadhar1seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "AadhaarCardApplicant1",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#CLAadhar1seebtn").hide();
          $("#CLAadhar1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleCLAadhar1hidecommentsbtn = () => {
    $("#CLAadhar1seebtn").show();
    $("#CLAadhar1seebtn").attr("disabled", false);
    $("#CLAadhar1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleCLAadhar2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#CLAadhar2seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "AadhaarCardApplicant2",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#CLAadhar2seebtn").hide();
          $("#CLAadhar2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleCLAadhar2hidecommentsbtn = () => {
    $("#CLAadhar2seebtn").show();
    $("#CLAadhar2seebtn").attr("disabled", false);
    $("#CLAadhar2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleCLPan1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#CLPan1seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "PANCardApplicant1",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#CLPan1seebtn").hide();
          $("#CLPan1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleCLPan1hidecommentsbtn = () => {
    $("#CLPan1seebtn").show();
    $("#CLPan1seebtn").attr("disabled", false);
    $("#CLPan1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleCLPan2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#CLPan2seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "PANCardApplicant2",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#CLPan2seebtn").hide();
          $("#CLPan2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleCLPan2hidecommentsbtn = () => {
    $("#CLPan2seebtn").show();
    $("#CLPan2seebtn").attr("disabled", false);
    $("#CLPan2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleCLICseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#CLICseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "IncorporationCertificate",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#CLICseebtn").hide();
          $("#CLIChidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleCLIChidecommentsbtn = () => {
    $("#CLICseebtn").show();
    $("#CLICseebtn").attr("disabled", false);
    $("#CLIChidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleCLMOAseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#CLMOAseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "MOA",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#CLMOAseebtn").hide();
          $("#CLMOAhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleCLMOAhidecommentsbtn = () => {
    $("#CLMOAseebtn").show();
    $("#CLMOAseebtn").attr("disabled", false);
    $("#CLMOAhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleCLAOAseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#CLAOAseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "AOA",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#CLAOAseebtn").hide();
          $("#CLAOAhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleCLAOAhidecommentsbtn = () => {
    $("#CLAOAseebtn").show();
    $("#CLAOAseebtn").attr("disabled", false);
    $("#CLAOAhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleCLBPseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#CLBPseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "BankProof",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#CLBPseebtn").hide();
          $("#CLBPhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleCLBPhidecommentsbtn = () => {
    $("#CLBPseebtn").show();
    $("#CLBPseebtn").attr("disabled", false);
    $("#CLBPhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleCLPBSseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#CLPBSseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "PreviousBS_Audit",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#CLPBSseebtn").hide();
          $("#CLPBShidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleCLPBShidecommentsbtn = () => {
    $("#CLPBSseebtn").show();
    $("#CLPBSseebtn").attr("disabled", false);
    $("#CLPBShidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleCLPRseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#CLPRseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "onetime",
          documentName: "PreviousReturns",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#CLPRseebtn").hide();
          $("#CLPRhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleCLPRhidecommentsbtn = () => {
    $("#CLPRseebtn").show();
    $("#CLPRseebtn").attr("disabled", false);
    $("#CLPRhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleCLDataseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#CLDataseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "Data",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#CLDataseebtn").hide();
          $("#CLDatahidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleCLDatahidecommentsbtn = () => {
    $("#CLDataseebtn").show();
    $("#CLDataseebtn").attr("disabled", false);
    $("#CLDatahidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleCLBankSseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#CLBankSseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "BankStatement",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#CLBankSseebtn").hide();
          $("#CLBankShidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleCLBankShidecommentsbtn = () => {
    $("#CLBankSseebtn").show();
    $("#CLBankSseebtn").attr("disabled", false);
    $("#CLBankShidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleCLGSTReturnseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#CLGSTReturnseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "GSTReturn",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#CLGSTReturnseebtn").hide();
          $("#CLGSTReturnhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleCLGSTReturnhidecommentsbtn = () => {
    $("#CLGSTReturnseebtn").show();
    $("#CLGSTReturnseebtn").attr("disabled", false);
    $("#CLGSTReturnhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleCLTDSReturnseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#CLTDSReturnseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "TDSReturn",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#CLTDSReturnseebtn").hide();
          $("#CLTDSReturnhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleCLTDSReturnhidecommentsbtn = () => {
    $("#CLTDSReturnseebtn").show();
    $("#CLTDSReturnseebtn").attr("disabled", false);
    $("#CLTDSReturnhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleCLChallanseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#CLChallanseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "Challan",
          FY: this.state.SelectedYear,
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#CLChallanseebtn").hide();
          $("#CLChallanhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleCLChallanhidecommentsbtn = () => {
    $("#CLChallanseebtn").show();
    $("#CLChallanseebtn").attr("disabled", false);
    $("#CLChallanhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  showDeliveredDocuments() {
    if (this.state.deliveredDocuments.length > 0) {
      return this.state.deliveredDocuments.map((activedoc, index) => {
        return (
          <div className="displayflex documentmaincard" key={index}>
            <div>
              <img
                src={process.env.PUBLIC_URL + "/img/Path 247.svg"}
                className="docutypeimg"
              />
            </div>
            <div className="docucontdiv">
              <div className="row">
                <div className="col-md-6">
                  <p className="nameofdoc">{activedoc.documentName}</p>
                  <p className="datesenttext">
                    Date received:-{" "}
                    <span className="datesentspan">{activedoc.datesent}</span>
                  </p>
                </div>
                <div className="col-md-6">
                  <div className="fl_right">
                    <div className="displayflex">
                      <div className="viewbtndivpadding">
                        <a
                          href={activedoc.documentLink}
                          target="_blank"
                          className="viewbtn"
                        >
                          View{" "}
                          <img
                            src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                          />
                        </a>
                      </div>
                      <div>
                        <a
                          href={activedoc.documentLink}
                          className="download_btn"
                          target="_blank"
                          download
                        >
                          Download{" "}
                          <img
                            src={
                              process.env.PUBLIC_URL + "/img/download_icon.svg"
                            }
                            alt="download"
                          />
                        </a>
                      </div>
                    </div>
                    <p className="docushareviatext">
                      Share via:{" "}
                      <a
                        onClick={() =>
                          this.whatsapp(
                            activedoc.documentName,
                            activedoc.documentLink
                          )
                        }
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/whatsapp.svg"}
                          className="docushareviawhats"
                        />
                      </a>{" "}
                      <a
                        onClick={() =>
                          this.email(
                            activedoc.documentName,
                            activedoc.documentLink
                          )
                        }
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/mail.svg"}
                          className="docushareviamail"
                        />
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.deliveredDocuments.length == 0) {
      return (
        <center className="myservicequiteimgcenter">
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
            className="emptydeliveredDocuments"
          />
        </center>
      );
    }
  }

  backbutton = () => {
    this.props.history.push({
      pathname: `/myuser/${Decuserid}/userservices`,
    });
  };
  credentials = () => {
    return (<><div className="displayflex">
      <div className="uploadnocol">
        <p className="uploadadditionalsidetext2">1)</p>
      </div>
      <div className="uploadadditionaldatacol2">
        <p className="uploadadditionalnormaltext">
          ITR portal credentials:-
        </p>
        <input
          type="text"
          placeholder="User ID..."
          value={this.state.itrfusername}
          onChange={() => { }}
          className="uploadtextfield marginzero itrfusername"
        />
        <sup className="requireddocstar2">*</sup>
      </div>
    </div>
      <div className="displayflex lastdisplayflex">
        <div className="uploadnocol">
          <p className="uploadnormaltext2">2)</p>
        </div>
        <div className="uploadlastdatacol">
          <input
            type="password"
            placeholder="Password..."
            value={this.state.itrfpassword}
            className="uploadtextfield marginzero itrfpassword"
            onChange={() => { }}
          />{this.state.showpassword ? <img className="showpassword-btn"
            src={process.env.PUBLIC_URL + "/img/hidden.svg"}
            onClick={() => this.showpassword()}
          /> : <img className="showpassword-btn"
            src={process.env.PUBLIC_URL + "/img/show.svg"}
            onClick={() => this.showpassword()} />}
        </div>
      </div>
    </>
    )
  }


  handletimeline = () => {


    $("#timeline_btnloader").show();
    axios.post(
      process.env.REACT_APP_API_BASE_URL + "p=timeLine",
      {
        agentId: Decagentid,
        userId: Decuserid,
        serviceId: Decserviceid,
      },
      {
        headers: {
          Authkey: process.env.REACT_APP_API_KEY,
          "App-Token-Access": Decjwttoken,
        },
        auth: {
          username: process.env.REACT_APP_API_USERNAME,
          password: process.env.REACT_APP_API_PASSWORD,
        },
      }
    )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {

          if (res.data.timeLine.length > 0) {
            let length = res.data.timeLine.length;
            let ftimeline = res.data.timeLine.filter((items, index) => {
              return (index <= this.state.timelcount)
            })
            this.setState({
              filtertimel: ftimeline,
              timelcount: this.state.timelcount + 3,
              Timeline: res.data.timeLine,
              Timelinedata: true,
            });
            this.setState({

            });
            // .splice(this.state.timelcount,length)
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
  }

  handlemoretimeline = () => {

    let ftimeline = this.state.Timeline.filter((items, index) => {
      return (index <= this.state.timelcount)
    })
    this.setState({
      filtertimel: ftimeline,
      timelcount: this.state.timelcount + 3
    });

  }


  render() {
    if (
      localStorage.getItem("gglltakoinoeenl") ==
      process.env.REACT_APP_LOGIN_KEY &&
      localStorage.getItem("toeljgtkewlna") != null &&
      localStorage.getItem("qazxswedcvfrtgb") != null
    ) {
      return (
        <div className="cont">
          <div id="notifContainer"></div>
          <Helmet>
            <link
              rel="stylesheet"
              href={process.env.PUBLIC_URL + "/css/select2.css"}
            />
          </Helmet>

          <Header />

          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
            className="sidebaropenicon"
          />
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
            onClick={this.backbutton}
            className="sidebarbackicon"
          />
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"}
            className="sidebarexpandicon"
          />

          <div className="overlay1"></div>
          <div className="sidebar sidebarshift">
            <div className="side_div_2 sidebar_txt active">
              <Link to="/dashboard">Overview</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <Link to="/myservices">My Services</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <a href="#">Payments Invoices</a>
            </div>
            <div className="side_div_1 sidebar_txt">
              <a href="#">Documents Record</a>
            </div>
            <div className="side_div_1 sidebar_txt">
              <a href="#">Add service</a>
            </div>
            <div></div>
          </div>
          <div className="arrow-left2 visibilityhidden"></div>
          <div className="white_div expandwhite_div">
            <div className="serviceoverlay">
              <div className="serviceoverlayinside">
                <center>
                  <img
                    src={process.env.PUBLIC_URL + "/img/loader.gif"}
                    className="serviceloader"
                  />
                </center>
              </div>
            </div>
            <div className="white_box">
              <div className="uploaddocu">
                <div className="row">
                  <div className="myservicesortbyfilterbystatus mt-1 ml-1">

                  </div>
                  <div className="col-lg-9 mt-5">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="inner-header d-flex justify-content-between">
                          <div className="slider-navigation-tab">
                            <header className="tabs-nav position-relative">
                              <ul className="d-flex slider-navigation-tab-ul">
                                <li
                                  className="active slider-navigation-tab-li"
                                  id="activeTab"
                                >
                                  <a href="#tab1" className="px-2 py-1">
                                    Information given
                                  </a>
                                </li>
                                <li
                                  className="slider-navigation-tab-li"
                                  id="completedTab"
                                >
                                  <a href="#tab2" className="px-2 py-1">
                                    Deliverables
                                  </a>
                                </li>
                                <hr className="slider-navigation-tab-vertical-line" />
                                <div className="sliderpage-slider"></div>
                              </ul>
                            </header>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 selectcateselectpadding">
                        <div className="fl_right d-flex">
                          <select
                            className="selectfinancialyear2"
                            value={this.state.dropDownValue}
                            disabled
                          >
                            <option
                              value="ITRF_S_OneTime_Documents"
                              className="selectcateselectoption"
                            >
                              Salaried
                            </option>
                            <option
                              value="ITRF_BP_OneTime_Documents"
                              className="selectcateselectoption"
                            >
                              Proprietor
                            </option>
                            <option
                              value="ITRF_BPP_OneTime_Documents"
                              className="selectcateselectoption"
                            >
                              Partnership firm company
                            </option>
                            <option
                              value="ITRF_BPC_OneTime_Documents"
                              className="selectcateselectoption"
                            >
                              Company & LLP
                            </option>
                          </select>
                          <select
                            className="selectfinancialyear2"
                            value={this.state.SelectedYear}
                            onChange={this.handleYearChange}
                          >
                            {this.YearsToBeShown()}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tabs-content">
                  <div id="tab1" className="tab-content">
                    <div className="row">
                      <div className="col-lg-9">
                      <div className="mainsectionuploadsection">
                        <div className="itrf-main-content">
                          {/* Salaried */}
                          <div className="uploadscrolldiv" id="itrf_S">
                            <div className="uploadscrollcardinside">
                              {this.credentials()}
                              <div className="displayflex" id="SAadhar1">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Aadhaar card
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.Aadhar1Link}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="SAadhar1seebtn"
                                          onClick={
                                            this.handleSAadhar1seecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="SAadhar1hidecommentsbtn"
                                          onClick={
                                            this.handleSAadhar1hidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="SPan1">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        PAN Card
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.Pan1Link}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="SPan1seebtn"
                                          onClick={
                                            this.handleSPan1seecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="SPan1hidecommentsbtn"
                                          onClick={
                                            this.handleSPan1hidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="displayflex" id="SBP">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Bank Proof
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.BPLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="SBPseebtn"
                                          onClick={this.handleSBPseecommentsbtn}
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="SBPhidecommentsbtn"
                                          onClick={
                                            this.handleSBPhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="SPBS">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Previous year Balance sheet
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.PBSLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="SPBSseebtn"
                                          onClick={
                                            this.handleSPBSseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="SPBShidecommentsbtn"
                                          onClick={
                                            this.handleSPBShidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="SPR">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Previous year return
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.PRLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="SPRseebtn"
                                          onClick={this.handleSPRseecommentsbtn}
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="SPRhidecommentsbtn"
                                          onClick={
                                            this.handleSPRhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div id="SRecurringDiv">
                                <div className="displayflex">
                                  <p className="uploadadditionalnormaltext">
                                    Business/Profession (Recurring)
                                  </p>
                                </div>

                                <div className="displayflex" id="SF16">
                                  <div className="uploaddatacol">
                                    <div className="row">
                                      <div className="col-md-5 d-flex">
                                        <div className="uploadnocol">
                                          <p className="uploadnormaltext itrfdocumentno"></p>
                                        </div>
                                        <p className="uploadnormaltext">
                                          Form - 16 / Salaried Certificate
                                        </p>
                                      </div>

                                      <div className="col-md-7">
                                        <div className="fl_right">
                                          <div className="displayflex">
                                            <div className="viewbtndivpadding">
                                              <a
                                                className="viewbtn"
                                                href={this.state.F16Link}
                                                target="_blank"
                                              >
                                                View{" "}
                                                <img
                                                  src={
                                                    process.env.PUBLIC_URL +
                                                    "/img/Path 19498.svg"
                                                  }
                                                />
                                              </a>
                                            </div>
                                            <div>
                                              <button className="tick_btn">
                                                {" "}
                                                <img
                                                  src={
                                                    process.env.PUBLIC_URL +
                                                    "/img/tick.svg"
                                                  }
                                                />
                                              </button>
                                            </div>
                                          </div>
                                          <button
                                            className="seecommentshistorybtn commentsbtn"
                                            id="SF16seebtn"
                                            onClick={
                                              this.handleSF16seecommentsbtn
                                            }
                                          >
                                            See comment history{" "}
                                            <img
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/img/See_comment_history.svg"
                                              }
                                            />
                                          </button>

                                          <button
                                            className="hidecommentshistorybtn"
                                            id="SF16hidecommentsbtn"
                                            onClick={
                                              this.handleSF16hidecommentsbtn
                                            }
                                          >
                                            Hide comment history{" "}
                                            <img
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/img/See_comment_history.svg"
                                              }
                                            />
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="displayflex" id="SBankS">
                                  <div className="uploadnocol">
                                    <p className="uploadnormaltext itrfdocumentno"></p>
                                  </div>
                                  <div className="uploaddatacol">
                                    <div className="row">
                                      <div className="col-md-5">
                                        <p className="uploadnormaltext">
                                          Bank Statement
                                        </p>
                                      </div>
                                      <div className="col-md-7">
                                        <div className="fl_right">
                                          <div className="displayflex">
                                            <div className="viewbtndivpadding">
                                              <a
                                                className="viewbtn"
                                                href={this.state.BankSLink}
                                                target="_blank"
                                              >
                                                View{" "}
                                                <img
                                                  src={
                                                    process.env.PUBLIC_URL +
                                                    "/img/Path 19498.svg"
                                                  }
                                                />
                                              </a>
                                            </div>
                                            <div>
                                              <button className="tick_btn">
                                                {" "}
                                                <img
                                                  src={
                                                    process.env.PUBLIC_URL +
                                                    "/img/tick.svg"
                                                  }
                                                />
                                              </button>
                                            </div>
                                          </div>
                                          <button
                                            className="seecommentshistorybtn commentsbtn"
                                            id="SBankSseebtn"
                                            onClick={
                                              this.handleSBankSseecommentsbtn
                                            }
                                          >
                                            See comment history{" "}
                                            <img
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/img/See_comment_history.svg"
                                              }
                                            />
                                          </button>

                                          <button
                                            className="hidecommentshistorybtn"
                                            id="SBankShidecommentsbtn"
                                            onClick={
                                              this.handleSBankShidecommentsbtn
                                            }
                                          >
                                            Hide comment history{" "}
                                            <img
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/img/See_comment_history.svg"
                                              }
                                            />
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="displayflex"
                                id="additionalincdDocumentDiv1"
                              >
                                <div className="uploadadditionaldatacol">
                                  <p className="uploadadditionalnormaltext">
                                    Any Income / Deduction doucments
                                  </p>
                                  {this.AdditionalincdFilesArea(
                                    "ITRF_S_OneTime_Documents"
                                  )}
                                </div>
                              </div>

                              <div
                                className="displayflex"
                                id="additionalDocumentDiv1"
                              >
                                <div className="uploadnocol"></div>
                                <div className="uploadadditionaldatacol">
                                  <p className="uploadadditionalnormaltext">
                                    Additional documents:-
                                  </p>
                                  {this.AdditionalFilesArea(
                                    "ITRF_S_OneTime_Documents"
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Proprietor */}
                          <div className="uploadscrolldiv" id="itrf_P">
                            <div className="uploadscrollcardinside">
                              {this.credentials()}
                              <div className="displayflex" id="PAadhar1">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Aadhaar card
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.Aadhar1Link}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PAadhar1seebtn"
                                          onClick={
                                            this.handlePAadhar1seecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PAadhar1hidecommentsbtn"
                                          onClick={
                                            this.handlePAadhar1hidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="PPan1">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        PAN card
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.Pan1Link}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PPan1seebtn"
                                          onClick={
                                            this.handlePPan1seecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PPan1hidecommentsbtn"
                                          onClick={
                                            this.handlePPan1hidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="displayflex" id="PBP">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Bank proof
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.BPLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PBPseebtn"
                                          onClick={this.handlePBPseecommentsbtn}
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PBPhidecommentsbtn"
                                          onClick={
                                            this.handlePBPhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="PPBS">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Previous year Balance sheet / Audit
                                        report
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.PBSLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PPBSseebtn"
                                          onClick={
                                            this.handlePPBSseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PPBShidecommentsbtn"
                                          onClick={
                                            this.handlePPBShidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="displayflex" id="PPR">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Previous year return
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.PRLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PPRseebtn"
                                          onClick={this.handlePPRseecommentsbtn}
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PPRhidecommentsbtn"
                                          onClick={
                                            this.handlePPRhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="displayflex"
                                id="additionalincdDocumentDiv2"
                              >
                                <div className="uploadadditionaldatacol">
                                  <p className="uploadadditionalnormaltext">
                                    Any Income / Deduction doucments
                                  </p>
                                  {this.AdditionalincdFilesArea(
                                    "ITRF_BP_OneTime_Documents"
                                  )}
                                </div>
                              </div>

                              <div className="displayflex" id="PData">
                                <div className="uploaddatacol">
                                  <p className="uploadadditionalnormaltext">
                                    Business/Profession (Recurring)
                                  </p>
                                  <div className="row">
                                    <div className="col-md-5 d-flex">
                                      <div className="uploadnocol">
                                        <p className="uploadnormaltext itrfdocumentno"></p>
                                      </div>
                                      <p className="uploadnormaltext">
                                        Current year Tally data / Audit report /
                                        Balance sheet
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.DataLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PDataseebtn"
                                          onClick={
                                            this.handlePDataseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PDatahidecommentsbtn"
                                          onClick={
                                            this.handlePDatahidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="PBankS">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext itrfdocumentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Bank Statement
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.BankSLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PBankSseebtn"
                                          onClick={
                                            this.handlePBankSseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PBankShidecommentsbtn"
                                          onClick={
                                            this.handlePBankShidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="PGSTReturn">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext itrfdocumentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        GST Return
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.GSTReturnLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PGSTReturnseebtn"
                                          onClick={
                                            this.handlePGSTReturnseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PGSTReturnhidecommentsbtn"
                                          onClick={
                                            this.handlePGSTReturnhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="PTDSReturn">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext itrfdocumentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        TDS Return
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.TDSReturnLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PTDSReturnseebtn"
                                          onClick={
                                            this.handlePTDSReturnseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PTDSReturnhidecommentsbtn"
                                          onClick={
                                            this.handlePTDSReturnhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="displayflex" id="PChallan">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext itrfdocumentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Tax Deposit Challan
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.ChallanLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PChallanseebtn"
                                          onClick={
                                            this.handlePChallanseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PChallanhidecommentsbtn"
                                          onClick={
                                            this.handlePChallanhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="displayflex"
                                id="additionalDocumentDiv2"
                              >
                                <div className="uploadnocol"></div>
                                <div className="uploadadditionaldatacol">
                                  <p className="uploadadditionalnormaltext">
                                    Additional documents:-
                                  </p>
                                  {this.AdditionalFilesArea(
                                    "ITRF_BP_OneTime_Documents"
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Partnership firm */}
                          <div className="uploadscrolldiv" id="itrf_PF">
                            {this.credentials()}

                            <div className="uploadscrollcardinside">
                              <div className="displayflex" id="PFAadhar1">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Aadhaar card (Partner 1)
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.Aadhar1Link}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PFAadhar1seebtn"
                                          onClick={
                                            this.handlePFAadhar1seecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PFAadhar1hidecommentsbtn"
                                          onClick={
                                            this.handlePFAadhar1hidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="displayflex" id="PFAadhar2">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Aadhaar card (Partner 2)
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.Aadhar2Link}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PFAadhar2seebtn"
                                          onClick={
                                            this.handlePFAadhar2seecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PFAadhar2hidecommentsbtn"
                                          onClick={
                                            this.handlePFAadhar2hidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="displayflex" id="PFPan1">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        PAN card (Partner 1)
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.Pan1Link}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PFPan1seebtn"
                                          onClick={
                                            this.handlePFPan1seecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PFPan1hidecommentsbtn"
                                          onClick={
                                            this.handlePFPan1hidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="displayflex" id="PFPan2">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        PAN card (Partner 2)
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.Pan2Link}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PFPan2seebtn"
                                          onClick={
                                            this.handlePFPan2seecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PFPan2hidecommentsbtn"
                                          onClick={
                                            this.handlePFPan2hidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="displayflex" id="PFDeed">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Partnership deed
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.DeedLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PFDeedseebtn"
                                          onClick={
                                            this.handlePFDeedseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PFDeedhidecommentsbtn"
                                          onClick={
                                            this.handlePFDeedhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="displayflex" id="PFBP">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Bank proof
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.BPLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PFBPseebtn"
                                          onClick={
                                            this.handlePFBPseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PFBPhidecommentsbtn"
                                          onClick={
                                            this.handlePFBPhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="displayflex" id="PFRC">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Registration Certificate
                                        <sup className="requireddocstar"></sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.RCLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PFRCseebtn"
                                          onClick={
                                            this.handlePFRCseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PFRChidecommentsbtn"
                                          onClick={
                                            this.handlePFRChidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="PFPBS">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Previous year Balance sheet / Audit
                                        report
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.PBSLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PFPBSseebtn"
                                          onClick={
                                            this.handlePFPBSseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PFPBShidecommentsbtn"
                                          onClick={
                                            this.handlePFPBShidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="PFPR">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Previous year return
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.PRLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PFPRseebtn"
                                          onClick={
                                            this.handlePFPRseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PFPRhidecommentsbtn"
                                          onClick={
                                            this.handlePFPRhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="displayflex"
                                id="additionalincdDocumentDiv3"
                              >
                                <div className="uploadadditionaldatacol">
                                  <p className="uploadadditionalnormaltext">
                                    Any Income / Deduction doucments
                                  </p>
                                  {this.AdditionalincdFilesArea(
                                    "ITRF_BPP_OneTime_Documents"
                                  )}
                                </div>
                              </div>

                              <div className="displayflex" id="PFData">
                                <div className="uploaddatacol">
                                  <p className="uploadadditionalnormaltext">
                                    Business/Profession (Recurring)
                                  </p>
                                  <div className="row">
                                    <div className="col-md-5 d-flex">
                                      <div className="uploadnocol">
                                        <p className="uploadnormaltext itrfdocumentno"></p>
                                      </div>
                                      <p className="uploadnormaltext">
                                        Current year Tally data / Audit report /
                                        Balance sheet
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.DataLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PFDataseebtn"
                                          onClick={
                                            this.handlePFDataseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PFDatahidecommentsbtn"
                                          onClick={
                                            this.handlePFDatahidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="PFBankS">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext itrfdocumentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Bank Statement
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.BankSLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PFBankSseebtn"
                                          onClick={
                                            this.handlePFBankSseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PFBankShidecommentsbtn"
                                          onClick={
                                            this.handlePFBankShidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="PFGSTReturn">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext itrfdocumentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        GST Return
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.GSTReturnLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PFGSTReturnseebtn"
                                          onClick={
                                            this.handlePFGSTReturnseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PFGSTReturnhidecommentsbtn"
                                          onClick={
                                            this
                                              .handlePFGSTReturnhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="PFTDSReturn">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext itrfdocumentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        TDS Return
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.TDSReturnLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PFTDSReturnseebtn"
                                          onClick={
                                            this.handlePFTDSReturnseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PFTDSReturnhidecommentsbtn"
                                          onClick={
                                            this
                                              .handlePFTDSReturnhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="displayflex" id="PFChallan">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext itrfdocumentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Tax Deposit Challan
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.ChallanLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PFChallanseebtn"
                                          onClick={
                                            this.handlePFChallanseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PFChallanhidecommentsbtn"
                                          onClick={
                                            this.handlePFChallanhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="displayflex"
                                id="additionalDocumentDiv3"
                              >
                                <div className="uploadadditionaldatacol">
                                  <p className="uploadadditionalnormaltext">
                                    Additional documents:-
                                  </p>
                                  {this.AdditionalFilesArea(
                                    "ITRF_BPP_OneTime_Documents"
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Company & LLP */}
                          <div className="uploadscrolldiv" id="itrf_CL">
                            {this.credentials()}

                            <div className="uploadscrollcardinside">
                              <div className="displayflex" id="CLAadhar1">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Aadhaar card (Director 1)
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.Aadhar1Link}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="CLAadhar1seebtn"
                                          onClick={
                                            this.handleCLAadhar1seecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="CLAadhar1hidecommentsbtn"
                                          onClick={
                                            this.handleCLAadhar1hidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="displayflex" id="CLAadhar2">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Aadhaar card (Director 2)
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.Aadhar2Link}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="CLAadhar2seebtn"
                                          onClick={
                                            this.handleCLAadhar2seecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="CLAadhar2hidecommentsbtn"
                                          onClick={
                                            this.handleCLAadhar2hidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="displayflex" id="CLPan1">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        PAN card (Director 1)
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.Pan1Link}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="CLPan1seebtn"
                                          onClick={
                                            this.handleCLPan1seecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="CLPan1hidecommentsbtn"
                                          onClick={
                                            this.handleCLPan1hidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="displayflex" id="CLPan2">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        PAN card (Director 2)
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.Pan2Link}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="CLPan2seebtn"
                                          onClick={
                                            this.handleCLPan2seecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="CLPan2hidecommentsbtn"
                                          onClick={
                                            this.handleCLPan2hidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="displayflex" id="CLIC">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Incorporation Certificate
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.ICLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="CLICseebtn"
                                          onClick={
                                            this.handleCLICseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="CLIChidecommentsbtn"
                                          onClick={
                                            this.handleCLIChidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="displayflex" id="CLMOA">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        MOA
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.MOALink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="CLMOAseebtn"
                                          onClick={
                                            this.handleCLMOAseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="CLMOAhidecommentsbtn"
                                          onClick={
                                            this.handleCLMOAhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="displayflex" id="CLAOA">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        AOA
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.AOALink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="CLAOAseebtn"
                                          onClick={
                                            this.handleCLAOAseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="CLAOAhidecommentsbtn"
                                          onClick={
                                            this.handleCLAOAhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="displayflex" id="CLBP">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Bank proof
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.BPLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="CLBPseebtn"
                                          onClick={
                                            this.handleCLBPseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="CLBPhidecommentsbtn"
                                          onClick={
                                            this.handleCLBPhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="displayflex" id="CLPBS">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Previous year Balance sheet / Audit
                                        report
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.PBSLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="CLPBSseebtn"
                                          onClick={
                                            this.handleCLPBSseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="CLPBShidecommentsbtn"
                                          onClick={
                                            this.handleCLPBShidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="displayflex" id="CLPR">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Previous year return
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.PRLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="CLPRseebtn"
                                          onClick={
                                            this.handleCLPRseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="CLPRhidecommentsbtn"
                                          onClick={
                                            this.handleCLPRhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="displayflex"
                                id="additionalincdDocumentDiv4"
                              >
                                <div className="uploadadditionaldatacol">
                                  <p className="uploadadditionalnormaltext">
                                    Any Income / Deduction doucments
                                  </p>
                                  {this.AdditionalincdFilesArea(
                                    "ITRF_BPC_OneTime_Documents"
                                  )}
                                </div>
                              </div>

                              <div className="displayflex" id="CLData">
                                <div className="uploaddatacol">
                                  <p className="uploadadditionalnormaltext">
                                    Business/Profession (Recurring)
                                  </p>
                                  <div className="row">
                                    <div className="col-md-5 d-flex">
                                      <div className="uploadnocol">
                                        <p className="uploadnormaltext itrfdocumentno"></p>
                                      </div>
                                      <p className="uploadnormaltext">
                                        Current year Tally data / Audit report /
                                        Balance sheet
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.DataLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="CLDataseebtn"
                                          onClick={
                                            this.handleCLDataseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="CLDatahidecommentsbtn"
                                          onClick={
                                            this.handleCLDatahidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="CLBankS">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext itrfdocumentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Bank Statement
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.BankSLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="CLBankSseebtn"
                                          onClick={
                                            this.handleCLBankSseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="CLBankShidecommentsbtn"
                                          onClick={
                                            this.handleCLBankShidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="CLGSTReturn">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext itrfdocumentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        GST Return
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.GSTReturnLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="CLGSTReturnseebtn"
                                          onClick={
                                            this.handleCLGSTReturnseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="CLGSTReturnhidecommentsbtn"
                                          onClick={
                                            this
                                              .handleCLGSTReturnhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="CLTDSReturn">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext itrfdocumentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        TDS Return
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.TDSReturnLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="CLTDSReturnseebtn"
                                          onClick={
                                            this.handleCLTDSReturnseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="CLTDSReturnhidecommentsbtn"
                                          onClick={
                                            this
                                              .handleCLTDSReturnhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="displayflex" id="CLChallan">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext itrfdocumentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Tax Deposit Challan
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.ChallanLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="CLChallanseebtn"
                                          onClick={
                                            this.handleCLChallanseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="CLChallanhidecommentsbtn"
                                          onClick={
                                            this.handleCLChallanhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="displayflex"
                                id="additionalDocumentDiv4"
                              >
                                <div className="uploadadditionaldatacol">
                                  <p className="uploadadditionalnormaltext">
                                    Additional documents:-
                                  </p>
                                  {this.AdditionalFilesArea(
                                    "ITRF_BPC_OneTime_Documents"
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
</div>
<div className="closedservicesection">
                        <center>
                        <br/>
                          <br/>
                          <br/>
                          <p className="closedstatustext"><span>Service ID</span>:- {this.state.serviceId}</p>
                          <br/>
                          <p className="closedstatusdesc">
                            Dear customer, your service is marked as
                            completed/closed because of following status.
                          </p>
                          <p  className="closedstatustext"><span>Status</span>:- Unapproved</p>
                          <img
                            src={process.env.PUBLIC_URL + "/img/warning.png"}
                            className="warningerrorimg"
                            alt="image"
                          />
                          <br/>    <br/>
                          <p className="closedstatusdesc2">
                            Please contact your allocated support executive or
                            call on 77728-77729 for support.
                          </p>
                          </center>
                        </div>
                      </div>

                      <div className="col-lg-3 colbtwborder">
                        <div className="commentimg-section2">
                          <SupportDetails name={this.state.Name} role={"Relationship Manager"} contact={this.state.Contact} />
                          {supportName != "" && supportContact != "" ?

                            <div className='supportDetails'>
                              <img
                                src={process.env.PUBLIC_URL + "/img/24-7_Support.png"}
                                alt="image"
                              />
                              <div>
                                <h5>{supportName}</h5>
                                <p>Agent Support Executive</p>
                                <span><b>Contact: </b> +91 {supportContact}</span>
                                <p className='mb-1'><b>Working Hours: </b> 11:00 AM - 06:30 PM</p>
                              </div>
                            </div>

                            : null}
                          <div className="mm">
                            <center>
                              <a
                                className="video-btn"
                                data-toggle="modal"
                                data-src="https://www.youtube.com/embed/fiC-nHoFJEY"
                                data-target="#youtubeModal"
                              >
                                <img
                                  src={process.env.PUBLIC_URL + "/img/play.jpg"}
                                  id="play"
                                  className="play_img play_img2"
                                  alt="image"
                                />
                              </a>
                            </center>
                          </div>

                          <p class="uploadinstr mt-2 uploddoc">You can also upload documents using Whatsapp on <a
                                  href="https://web.whatsapp.com/send?phone=917772877729&amp;text=Hello%20Team%20Legal251!"
                                  target="_blank"
                                >
                                  {" "}
                                  <img
                                    src={process.env.PUBLIC_URL + "/img/call.svg"}
                                    alt="call"
                                    className="callimg"
                                  />
                                </a> +91 77728-77729</p>
                        </div>
                        <div className="TDRightSide">
                          <div className="convheader2">
                            <div>
                              <img
                                src={process.env.PUBLIC_URL + "/img/person.png"}
                                className="converpersonimg"
                              />
                            </div>
                            <div className="convheadercontdiv">
                              <p className="convservicename">{this.state.Name}</p>
                              <p className="convpersonname">Relationship Manager</p>
                              <p className="convuniquename">
                                Contact: <span style={{ color: "#2d2d2d" }}>{this.state.Contact}</span></p>
                              <p className="convuniquename">
                                Service ID : <span className="convuniquenamespan">{Decserviceid}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="convmaindiv">
                            {this.renderCommenthistory()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="tab2" className="tab-content">
                    <div className="doucumainscrolldiv2">
                      <div className="doucumainscrolldivinside2">
                        {this.showDeliveredDocuments()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SupportDetailsMobile name={this.state.Name} role={"Relationship Manager"} contact={this.state.Contact} />

          <Footer />
          <div className="modal fade"
            id="timeline"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true">

            <div
              className="modal-dialog modal-dialog-centered yt_modal "
              role="document"
            >
              <div className="modal-content videoModal">
                <div className="modal-body modal_b mt-4 ">
                  <button
                    type="button"
                    className="close close1 closeMain mr-2 dbtime"
                    data-dismiss="modal"
                    aria-label="Close"

                  >
                    <span aria-hidden="true">&times;</span>
                  </button>

                  {this.state.Timelinedata ?


                    this.state.Timeline.length > 0 ?

                      <div class="timeline scrollbtimel">
                        <div class="container">
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="timeline-container">
                                <div class="timeline-end">
                                  <button id="load-more" onClick={this.handlemoretimeline} >More..</button>
                                </div>
                                <div class="timeline-continue">

                                  {this.state.filtertimel.map((item, index) => {

                                    if (index % 2 == 0) {
                                      return (

                                        <div class="row timeline-left">
                                          <div class="col-md-6 d-md-none d-block">
                                            <p class="timeline-date">

                                            </p>
                                          </div>
                                          <div class="col-md-6">
                                            <div class="timeline-box">
                                              <div class="timeline-icon d-md-none d-block">
                                                <i class="fa fa-business-time"></i>
                                              </div>

                                              <div class="timeline-text">
                                                <p class="mainSpan">{item.time}</p>
                                                <h3>{item.timeLine}</h3>
                                                <p class="mode_p">Date : <span class="mode_span"> {item.date}</span></p>
                                                <h5 className="tlremark">{item.remark}</h5>
                                                {/* <p class="mode_p">
                                                      <span class="mode_span">
                                                          {{$TimeLine->TimeLine}}
                                                      </span>
                                                  </p>  */}


                                              </div>

                                              <div class="timeline-icon d-md-block d-none">
                                                <i class="fa fa-business-time"></i>
                                              </div>

                                            </div>
                                          </div>
                                          <div class="col-md-6 d-md-block d-none">
                                            <p class="timeline-date">

                                            </p>
                                          </div>
                                        </div>

                                      )
                                    } else {
                                      return (
                                        <div class="row timeline-right">
                                          <div class="col-md-6">
                                            <p class="timeline-date">
                                            </p>
                                          </div>
                                          <div class="col-md-6">
                                            <div class="timeline-box">
                                              <div class="timeline-icon">
                                                <i class="fa fa-gift"></i>
                                              </div>

                                              <div class="timeline-text">
                                                <p class="mainSpan">{item.time}</p>
                                                {/* <p class="">khkh</p> */}

                                                <h3>{item.timeLine}</h3>
                                                <p class="mode_p">Date : <span class="mode_span">{item.date} </span></p>
                                                <h5 className="tlremark">{item.remark}</h5>
                                                {/* <p class="mode_p">
                                                                              <span class="mode_span">
                                                                                  
                                                                              </span>
                                                                          </p> */}


                                              </div>

                                            </div>
                                          </div>
                                        </div>
                                      )
                                    }




                                  })}




                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      <center className="">
                        <img
                          alt="loading..."
                          src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
                          className="emptydeliveredDocuments"
                        />
                      </center>

                    :
                    <center>
                      <img
                        className="timelineloder"
                        src={process.env.PUBLIC_URL + "/img/loader.gif"}
                        alt="loading"
                      />
                    </center>


                  }

                </div>
              </div>
            </div>

          </div>
          <div className="modal fade myAddModal1">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-body uploadpopupbody">
                  <p className="sharepopupheading">
                    Please enter the recipient's mobile no.
                  </p>
                  <div className="sharepopupinputdiv">
                    <img
                      src={process.env.PUBLIC_URL + "/img/whatsapp.svg"}
                      className="docushareviawhatspopup"
                    />
                    <input
                      type="text"
                      className="sharepopupinput"
                      maxLength="10"
                      placeholder="Type here..."
                      value={this.state.whatsppcontactno}
                      onChange={this.handlewhatsppcontactno}
                    />
                    <p className="sharedocmentwhatsapperrormessage">
                      error message
                    </p>
                  </div>
                  <p className="sharepopupheading text-left">Share via:</p>
                  {/* <input
                    type="radio"
                    id="test30"
                    name="radio-group"
                    value="Yes"
                    checked={this.state.sharewhatsapp_radio === "Yes"}
                    onChange={this.sharewhatsapp_radioChange}
                  />
                  <label htmlFor="test30" className="uploadradiotext">
                    Legal251
                  </label> */}

                  <input
                    type="radio"
                    id="test31"
                    name="radio-group"
                    value="Personal"
                    checked={this.state.sharewhatsapp_radio === "Personal"}
                    onChange={this.sharewhatsapp_radioChange}
                  />
                  <label htmlFor="test31" className="uploadradiotext ">
                    Personal Mobile no.
                  </label>
                  <p className="sharepopupheading text-left">Share for:</p>
                  <select
                    className="selectimeperiod"
                    value={this.state.Selectedwhatsapptimeperiod}
                    onChange={this.handlewhatsapptimeperiodChange}
                  >
                    <option value="select" disabled>
                      select time period
                    </option>
                    <option value="1hr">share for 1 hour</option>
                    <option value="1day">share for 1 day</option>
                    <option value="3days">share for 3 day</option>
                    <option value="1week">share for 1 week</option>
                    <option value="1month">share for 1 month</option>
                    <option value="custom">custom</option>
                    <option value="always">lifetime</option>
                  </select>
                  <p className="sharedocmentwhatsapplifeerrormessage">
                    We don't recommend you to share documents for lifetime for
                    security reasons Instead you can select a custom time
                    period.
                  </p>
                  <p className="sharedocmentwhatsappselecterrormessage">
                    error message
                  </p>
                  <input
                    type="date"
                    id="whatsappdateinput"
                    className="othertextinput"
                    value={this.state.customwhatsappdate}
                    onChange={this.handlecustomwhatsappdateChange}
                    placeholder="Type here..."
                  />
                  <p className="sharedocmentwhatsappdateerrormessage">
                    error message
                  </p>
                  <br />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn cancelbtn_90"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20 upload_btn20_90"
                        onClick={this.submitwhatsapp}
                      >
                        Send&nbsp;
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_19481.svg"}
                          alt="download"
                        />
                      </button>
                      <button className="upload_btnloader upload_btnloader_90">
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade myAddModal2">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-body uploadpopupbody">
                  <p className="sharepopupheading">
                    Please enter the recipient's email ID
                  </p>
                  <div className="sharepopupinputdiv">
                    <img
                      src={process.env.PUBLIC_URL + "/img/Group_4339.svg"}
                      className="docushareviawhatspopup"
                    />
                    <input
                      type="text"
                      className="sharepopupinput"
                      maxLength="50"
                      placeholder="Type here..."
                      value={this.state.emailmail}
                      onChange={this.handleemailmail}
                    />
                    <p className="sharedocmentemailerrormessage">
                      error message
                    </p>
                  </div>
                  <p className="sharepopupheading text-left">Share via:</p>
                  <input
                    type="radio"
                    id="test40"
                    name="radio-group2"
                    value="Legal251"
                    checked={this.state.shareemail_radio === "Legal251"}
                    onChange={this.shareemail_radioChange}
                  />
                  <label htmlFor="test40" className="uploadradiotext">
                    Legal251
                  </label>

                  <input
                    type="radio"
                    id="test41"
                    name="radio-group2"
                    value="Personal"
                    checked={this.state.shareemail_radio === "Personal"}
                    onChange={this.shareemail_radioChange}
                  />
                  <label
                    htmlFor="test41"
                    className="uploadradiotext rightradio"
                  >
                    Personal Mail
                  </label>
                  <p className="sharepopupheading text-left">Share for:</p>
                  <select
                    className="selectimeperiod"
                    value={this.state.Selectedemailtimeperiod}
                    onChange={this.handleemailtimeperiodChange}
                  >
                    <option value="select" disabled>
                      select time period
                    </option>
                    <option value="1hr">share for 1 hour</option>
                    <option value="1day">share for 1 day</option>
                    <option value="3days">share for 3 day</option>
                    <option value="1week">share for 1 week</option>
                    <option value="1month">share for 1 month</option>
                    <option value="custom">custom</option>
                    <option value="always">lifetime</option>
                  </select>
                  <p className="sharedocmentemaillifeerrormessage">
                    We don't recommend you to share documents for lifetime for
                    security reasons Instead you can select a custom time
                    period.
                  </p>
                  <p className="sharedocmentemailselecterrormessage">
                    error message
                  </p>
                  <input
                    type="date"
                    id="emaildateinput"
                    className="othertextinput"
                    value={this.state.customemaildate}
                    onChange={this.handlecustomemaildateChange}
                    placeholder="Type here..."
                  />
                  <p className="sharedocmentemaildateerrormessage">
                    error message
                  </p>
                  <br />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn cancelbtn_70"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20 upload_btn20_70"
                        onClick={this.submitemail}
                      >
                        Send&nbsp;
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_19481.svg"}
                          alt="download"
                        />
                      </button>
                      <button className="upload_btnloader upload_btnloader_70">
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade myAddModal3 ">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-body uploadpopupbody">
                  <center>
                    <p className="sharesucesstext">Sent Sucessfully</p>
                    <img
                      src={process.env.PUBLIC_URL + "/img/Path_19474.svg"}
                      className="sharesucessimg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="youtubeModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered yt_modal "
              role="document"
            >
              <div className="modal-content">
                <div className="modal-body modal_b">
                  <button
                    type="button"
                    className="close close1"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      className="embed-responsive-item"
                      id="video"
                      allowscriptaccess="always"
                      src="https://www.youtube.com/embed/fiC-nHoFJEY?&amp;modestbranding=1&amp;showinfo=0"
                      allowFullScreen="allowfullscreen"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default Completeitrf;

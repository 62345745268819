import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";

import Footer from "./Footer";
import { Link } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import { Redirect } from "react-router";
import HeaderAgent from "./HeaderAgent";

var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);
let Decuserid;
let Decagentid;
let Decjwttoken;
let order_id = "";
let applicationid = ""
let paymentmade = ""
let contactno = ""
let time = ""
export class Paymentsuccessfull__agent extends Component {
  constructor(props) {
    super(props);
   
    this.state = {
      date: paymentmade,
      time: time,
      orderid: order_id,
      applicationId: applicationid,
      contactno: contactno
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    $(document.body).css("background-color", "#2d2d2d");
  
    if(this.props.location.state ){
      applicationid = this.props.location.state.applicationid; 
      this.setState({applicationId: applicationid})
       this.props.history.replace('/paymentsuccessfull', null);
    }else this.props.history.push("/register")
   
  }
  

  render() {
      return (
        <div className="cont">
          <Helmet>
            <link rel="stylesheet" href={process.env.PUBLIC_URL + "/css/select2.css"} />
          </Helmet>

          <HeaderAgent />

          <img
            src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
            className="sidebaropenicon"
          />
          <img
            
            src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
            className="sidebarbackicon"
            onClick={() => this.props.history.push("/register")}
          />
          <img
            src={process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"}
            className="sidebarexpandicon"
          />

          <div className="overlay1"></div>
          <div className="sidebar sidebarshift">
            <div className="side_div_2 sidebar_txt active">
              <Link to="/dashboard">Overview</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <Link to="/myservices">My Services</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <a href="#">Payments Invoices</a>
            </div>
            <div className="side_div_1 sidebar_txt">
              <a href="#">Documents Record</a>
            </div>
            <div className="side_div_1 sidebar_txt">
              <a href="#">Add service</a>
            </div>
            <div></div>
          </div>
          <div className="arrow-left2 visibilityhidden"></div>
          <div className="white_div expandwhite_div">
            <div className="white_box">
              <div className="white_boxscrolldiv">
                <div className="container-fluid">
                  <center>
                    <p className="success-heading success-heading8">
                    Application Submitted successfully
                      <img
                        className="tick-icon"
                        src={process.env.PUBLIC_URL + "/img/blue-tick.svg"}
                        alt=""
                      />{" "}
                    </p>
                    <p className="thankyoutext">Thank you for choosing us!</p>
                    
                  </center>
                  <div className="row">
                    <div className="col-md-6">
                      <center>
                        <p className="ServiceIDheading">
                          Application ID:-{" "}
                          <span className="ServiceIDheadingspan">
                            {this.state.applicationId}
                          </span>
                        </p>
                        <hr className="paymenthr" />
                        <br />
                      
  
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_22201.svg"}
                          alt=""
                          className="quoteimg img-fluid"
                        />
                      </center>
                    </div>
                    <div className="col-md-6">
                      <center>
                        <div className="custompadd">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/img/payment_sucessful.svg"
                            }
                            className="paymentsucessimg img-fluid"
                            alt=""
                          />
                        </div>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      );
  }
}

export default Paymentsuccessfull__agent;

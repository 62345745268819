import React, { Component } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import SupportDetails from "./SupportDetails";
import SupportDetailsMobile from "./SupportDetailsMobile";

var key = process.env.REACT_APP_ENCRYPTOR_KEY;
var encryptor = require("simple-encryptor")(key);
let Decuserid;
let Decjwttoken;
let Decagentid;
let Decserviceid;
let Decservicename;
let Decplan;
let supportName;
let supportContact;
let supportEmail;
let temp = "general";
var sharedocumentarray = [
  {
    documentname: "",
    link: "",
  },
];
export class Completegumasta extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropDownValue: "",
      commentHistroyarray: [],
      AddressProofType: "",
      RAddressProofType: "",
      Aadhar1Link: "#",
      Aadhar2Link: "#",
      Pic1Link: "#",
      Pic2Link: "#",
      Pic3Link: "#",
      CILink: "#",
      CRLink: "#",
      MOALink: "#",
      AOALink: "#",
      DeedLink: "#",
      businessNature: "",
      CopyOfRegistryLink: "#",
      RCopyOfRegistryLink: "#",
      RentAgreementLink: "#",
      RRentAgreementLink: "#",
      PRLink: "#",
      deliveredDocuments: [],
      additionalArray1: [],
      sharewhatsapp_radio: "Personal",
      shareemail_radio: "Legal251",
      whatsppcontactno: "",
      Selectedwhatsapptimeperiod: "select",
      Selectedemailtimeperiod: "select",
      customwhatsappdate: "",
      customemaildate: "",
      sharedocumentname: "",
      sharedocumentlink: "",
      emailmail: "",
      Timeline: [],
      Timelinedata: false,
      timelcount: 2,
      filtertimel: [],
      Name:"",
      Contact:"",
      serviceId:""
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    $(document.body).css("background-color", "#2d2d2d");
    let mainuserid = localStorage.getItem("relleIsugad");
    Decuserid = encryptor.decrypt(mainuserid);
    let mainagentid = localStorage.getItem("qazxswedcvfrtgb");
    Decagentid = encryptor.decrypt(mainagentid);
    let mainjwttoken = localStorage.getItem("toeljgtkewlna");
    Decjwttoken = encryptor.decrypt(mainjwttoken);
    const script = document.createElement("script");
    script.src = "/js/main.js";
    script.async = true;
    document.body.appendChild(script);
    let mainserviceid = localStorage.getItem("vrellvaiecstiadeceig");
    let mainaservicename = localStorage.getItem("eieltaiclsraaemevcvnge");
    let mainplan = localStorage.getItem("ipacneglatvlael");
    Decserviceid = encryptor.decrypt(mainserviceid);
    Decservicename = encryptor.decrypt(mainaservicename);
    Decplan = encryptor.decrypt(mainplan);
    supportName = localStorage.getItem("supportName");
    supportContact = localStorage.getItem("supportContact");
    supportEmail = localStorage.getItem("supportEmail");

    this.setState({
      supportName: supportName,
      supportContact: supportContact,
      supportEmail: supportEmail,
    })

    // ----------------------------temporary -----------------------------------------


    let temptoken = localStorage.getItem("fdgshdjfkgjhyhdnxhsgdhskcj")
    let Dectemptoken = encryptor.decrypt(temptoken)
    let paramsUserId2 = this.props.match.params.id2;
    if (Dectemptoken != null && Dectemptoken == paramsUserId2) {
      temp = "temporary"
    } else {
      temp = "general"
    }

    // ----------------------------temporary -----------------------------------------

    if (mainserviceid != null && mainaservicename != null && mainplan != null) {
      let paramsId = this.props.match.params.id;
      let paramsUserId = this.props.match.params.id2;
      let completedstatus = localStorage.getItem("pojasdsdjisjddd");
      Decserviceid = encryptor.decrypt(mainserviceid);
      Decservicename = encryptor.decrypt(mainaservicename);
      Decplan = encryptor.decrypt(mainplan);
      let Deccompletedstatus = encryptor.decrypt(completedstatus);
      this.getnameanumber();
      this.setState({
        serviceId: Decserviceid,
      });
      if (Deccompletedstatus == "Success") {
        this.getserviceInformationApi();
      } else {
        $(".mainsectionuploadsection").hide();
        $(".closedservicesection").show();
      }
      if (paramsId != Decserviceid || paramsUserId != Decuserid) {
        this.props.history.push("/dashboard");
      }
    } else {
      this.props.history.push("/dashboard");
    }
    $("#gumasta_C_SolePI").hide();
    $("#gumasta_C_P_LLP").hide();
    $("#gumasta_C_PL").hide();
    $("#gumasta_C_HUF").hide();
    $("#gumasta_C_Society").hide();
    this.getserviceInformationApi();


    let str = document.getElementsByClassName("myservicesortbyfilterbystatus").innerHTML = this.props.location.pathname.replaceAll("/", " > ")
    let mainstr = document.getElementsByClassName("myservicesortbyfilterbystatus").innerHTML = str.replace(" > ", " ")
    $(".myservicesortbyfilterbystatus").html(mainstr)
    this.getnameanumber()
  }

  getnameanumber = () => {
    axios.post(
      process.env.REACT_APP_API_BASE_URL + "p=empexecutiveDetails",
      {
        agentId: Decagentid,
        serviceName: Decservicename,
        serviceId: Decserviceid, 
      },
      {
        headers: {
          Authkey: process.env.REACT_APP_API_KEY,
          "App-Token-Access": Decjwttoken,
        },
        auth: {
          username: process.env.REACT_APP_API_USERNAME,
          password: process.env.REACT_APP_API_PASSWORD,
        },
      }
    )
    .then((res) => {
      if (res.data.code == "200" && res.data.status == "success") {
        this.setState({
          Name: res.data.teamLeaderName,
            Contact: res.data.teamLeaderNumber,
        })
      } else if (res.data.code == "201" && res.data.status == "invalidauth") {
        this.logout();
      } else {
          this.showNotification(res.data.message);
        } 
    })
  }

  logout = () => {
    localStorage.removeItem("gglltakoinoeenl");
    localStorage.removeItem("toeljgtkewlna");
    localStorage.removeItem("qazxswedcvfrtgb");
    localStorage.removeItem("lshhihfnkff");
    this.props.history.push({
      pathname: `/`,
    });
  };
  getserviceInformationApi = () => {
    $(".serviceoverlay").show();
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=completedDetails",

        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          plan: Decplan,
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        $(".serviceoverlay").hide();
        if (res.data.code == "200" && res.data.status == "success") {
          if (res.data.serviceType == "GUMASTA_SolePI_Documents") {
            this.setState(
              {
                dropDownValue: "GUMASTA_SolePI_Documents",
                serviceType: "GUMASTA_SolePI_Documents",
                deliveredDocuments: res.data.deliveredDocuments,
              },
              () => {
                if (this.state.dropDownValue == "GUMASTA_SolePI_Documents") {
                  $("#gumasta_C_SolePI").show();
                  $("#gumasta_C_P_LLP").hide();
                  $("#gumasta_C_PL").hide();
                  $("#gumasta_C_HUF").hide();
                  $("#gumasta_C_Society").hide();
                }
              }
            );

            if (res.data.informationGiven.documents.length > 0) {
              let doc = res.data.informationGiven.documents;
              if (doc[1].status == "done") {
                if (doc[1].IDProofApplicant == null) {
                  $("#SAadhar").hide();
                } else {
                  $("#SAadhar").show();
                  this.setState({
                    Aadhar1Link: doc[1].IDProofApplicant,
                  });
                }
              } else {
                $("#SAadhar").hide();
              }
              if (doc[2].status == "done") {
                if (doc[2].PremisePhoto == null) {
                  $("#SPan").hide();
                } else {
                  $("#SPan").show();
                  this.setState({
                    Pan1Link: doc[2].PremisePhoto,
                  });
                }
              } else {
                $("#SPan").hide();
              }
              if (doc[0].status == "done") {
                if (doc[0].ApplicantPhoto == null) {
                  $("#SPic").hide();
                } else {
                  $("#SPic").show();
                  this.setState({
                    Pic1Link: doc[0].ApplicantPhoto,
                  });
                }
              } else {
                $("#SPic").hide();
              }
              if (doc[4].status == "done") {
                if (doc[4].CopyOfRegistry == null) {
                  $("#SRCopyOfRegistry").hide();
                } else {
                  $("#SRCopyOfRegistry").show();
                  this.setState({
                    RCopyOfRegistryLink: doc[4].CopyOfRegistry,
                  });
                }
              } else {
                $("#SRCopyOfRegistry").hide();
              }
              if (doc[6].status == "done") {
                if (doc[6].OwnershipDeed == null) {
                  $("#SCopyOfRegistry").hide();
                } else {
                  $("#SCopyOfRegistry").show();
                  this.setState({
                    CopyOfRegistryLink: doc[6].OwnershipDeed,
                  });
                }
              } else {
                $("#SCopyOfRegistry").hide();
              }
              if (doc[3].status == "done") {
                if (doc[3].ResidentialRentAgreement == null) {
                  $("#SRRentAgreement").hide();
                } else {
                  $("#SRRentAgreement").show();
                  this.setState({
                    RRentAgreementLink: doc[3].ResidentialRentAgreement,
                  });
                }
              } else {
                $("#SRRentAgreement").hide();
              }
              if (doc[5].status == "done") {
                if (doc[5].RentAgreement == null) {
                  $("#SRentAgreement").hide();
                } else {
                  $("#SRentAgreement").show();
                  this.setState({
                    RentAgreementLink: doc[5].RentAgreement,
                  });
                }
              } else {
                $("#SRentAgreement").hide();
              }
            }
            if (res.data.informationGiven.inputFields.length > 0) {
              this.setState(
                {
                  businessNature:
                    res.data.informationGiven.inputFields[0].Nature,
                }
              );
              // let AddressProofType =
              //   res.data.informationGiven.inputFields[0].AddressProofType;
              this.setState(
                {
                  AddressProofType:
                    res.data.informationGiven.inputFields[2].AddressProofType,
                },
                () => {
                  if (this.state.AddressProofType == "OwnershipDeed") {
                    $("#SCopyOfRegistry").show();
                    $("#SRentAgreement").hide();
                  } else if (this.state.AddressProofType == "RentAgreement") {
                    $("#SRentAgreement").show();
                    $("#SCopyOfRegistry").hide();
                  }
                }
              );
              this.setState(
                {
                  RAddressProofType:
                    res.data.informationGiven.inputFields[1].ResidentialAddressProofType,
                },
                () => {
                  if (this.state.RAddressProofType == "CopyOfRegistry") {
                    $("#SRCopyOfRegistry").show();
                    $("#SRRentAgreement").hide();
                  } else if (this.state.RAddressProofType == "ResidentialRentAgreement") {
                    $("#SRRentAgreement").show();
                    $("#SRCopyOfRegistry").hide();
                  }
                }
              );
            }
            if (res.data.informationGiven.addDocInformation.length > 0) {
              $("#additionalDocumentDiv1").show();
              this.setState({
                additionalArray1: [
                  ...res.data.informationGiven.addDocInformation,
                ],
              });
            } else {
              $("#additionalDocumentDiv1").hide();
            }
          } else if (res.data.serviceType == "GUMASTA_P_LLP_Documents") {
            this.setState(
              {
                dropDownValue: "GUMASTA_P_LLP_Documents",
                serviceType: "GUMASTA_P_LLP_Documents",
                deliveredDocuments: res.data.deliveredDocuments,
              },
              () => {
                if (this.state.dropDownValue == "GUMASTA_P_LLP_Documents") {
                  $("#gumasta_C_SolePI").hide();
                  $("#gumasta_C_P_LLP").show();
                  $("#gumasta_C_PL").hide();
                  $("#gumasta_C_HUF").hide();
                  $("#gumasta_C_Society").hide();
                }
              }
            );

            if (res.data.informationGiven.documents.length > 0) {
              let doc = res.data.informationGiven.documents;
              if (doc[2].status == "done") {
                if (doc[2].IDProofApplicant1 == null) {
                  $("#PAadhar1").hide();
                } else {
                  $("#PAadhar1").show();
                  this.setState({
                    Aadhar1Link: doc[2].IDProofApplicant1,
                  });
                }
              } else {
                $("#PAadhar1").hide();
              }
              if (doc[3].status == "done") {
                if (doc[3].IDProofApplicant2 == null) {
                  $("#PAadhar2").hide();
                } else {
                  $("#PAadhar2").show();
                  this.setState({
                    Aadhar2Link: doc[3].IDProofApplicant2,
                  });
                }
              } else {
                $("#PAadhar2").hide();
              }
              if (doc[5].status == "done") {
                if (doc[5].PremisePhoto == null) {
                  $("#PPan1").hide();
                } else {
                  $("#PPan1").show();
                  this.setState({
                    Pan1Link: doc[5].PremisePhoto,
                  });
                }
              } else {
                $("#PPan1").hide();
              }
              if (doc[4].status == "done") {
                if (doc[4].PartnershipDeed == null) {
                  $("#PPan2").hide();
                } else {
                  $("#PPan2").show();
                  this.setState({
                    Pan2Link: doc[4].PartnershipDeed,
                  });
                }
              } else {
                $("#PPan2").hide();
              }
              if (doc[0].status == "done") {
                if (doc[0].ApplicantPhoto1 == null) {
                  $("#PPic1").hide();
                } else {
                  $("#PPic1").show();
                  this.setState({
                    Pic1Link: doc[0].ApplicantPhoto1,
                  });
                }
              } else {
                $("#PPic1").hide();
              }
              if (doc[1].status == "done") {
                if (doc[1].ApplicantPhoto2 == null) {
                  $("#PPic2").hide();
                } else {
                  $("#PPic2").show();
                  this.setState({
                    Pic2Link: doc[1].ApplicantPhoto2,
                  });
                }
              } else {
                $("#PPic2").hide();
              }
              if (doc[8].status == "done") {
                if (doc[8].CopyOfRegistry == null) {
                  $("#PRCopyOfRegistry").hide();
                } else {
                  $("#PRCopyOfRegistry").show();
                  this.setState({
                    RCopyofRegistryLink: doc[8].CopyOfRegistry,
                  });
                }
              } else {
                $("#PCopyOfRegistry").hide();
              }
              if (doc[9].status == "done") {
                if (doc[9].OwnershipDeed == null) {
                  $("#PRCopyOfRegistry").hide();
                } else {
                  $("#PRCopyOfRegistry").show();
                  this.setState({
                    CopyofRegistryLink: doc[9].OwnershipDeed,
                  });
                }
              } else {
                $("#PCopyOfRegistry").hide();
              }
              if (doc[7].status == "done") {
                if (doc[7].RentAgreement == null) {
                  $("#PRentAgreement").hide();
                } else {
                  $("#PRentAgreement").show();
                  this.setState({
                    RentAgreementLink: doc[7].RentAgreement,
                  });
                }
              } else {
                $("#PRentAgreement").hide();
              }
              if (doc[5].status == "done") {
                if (doc[5].ResidentialRentAgreement == null) {
                  $("#PRRentAgreement").hide();
                } else {
                  $("#PRRentAgreement").show();
                  this.setState({
                    RRentAgreementLink: doc[5].ResidentialRentAgreement,
                  });
                }
              } else {
                $("#PRRentAgreement").hide();
              }
            }

            if (res.data.informationGiven.inputFields.length > 0) {
              this.setState(
                {
                  businessNature: res.data.informationGiven.inputFields[0].Nature,
                }
              );
              let AddressProofType =
                res.data.informationGiven.inputFields[2].AddressProofType;
              this.setState(
                {
                  AddressProofType: AddressProofType,
                },
                () => {
                  if (this.state.AddressProofType == "OwnershipDeed") {
                    $("#PCopyOfRegistry").show();
                    $("#PRentAgreement").hide();
                  } else if (this.state.AddressProofType == "RentAgreement") {
                    $("#PCopyOfRegistry").hide();
                    $("#PRentAgreement").show();
                  }
                }
              );

              let ResidentialAddressProofType =
                res.data.informationGiven.inputFields[1].ResidentialAddressProofType;
              this.setState(
                {
                  RAddressProofType: ResidentialAddressProofType,
                },
                () => {
                  if (this.state.RAddressProofType == "CopyOfRegistry") {
                    $("#PRCopyOfRegistry").show();
                    $("#PRRentAgreement").hide();
                  } else if (this.state.RAddressProofType == "ResidentialRentAgreement") {
                    $("#PRCopyOfRegistry").hide();
                    $("#PRRentAgreement").show();
                  }
                }
              );
            }
            if (res.data.informationGiven.addDocInformation.length > 0) {
              $("#additionalDocumentDiv2").show();
              this.setState({
                additionalArray1: [
                  ...res.data.informationGiven.addDocInformation,
                ],
              });
            } else {
              $("#additionalDocumentDiv2").hide();
            }
          } else if (res.data.serviceType == "GUMASTA_PRL_PL_OP_Documents") {
            this.setState(
              {
                dropDownValue: "GUMASTA_PRL_PL_OP_Documents",
                serviceType: "GUMASTA_PRL_PL_OP_Documents",
                deliveredDocuments: res.data.deliveredDocuments,
              },
              () => {
                if (this.state.dropDownValue == "GUMASTA_PRL_PL_OP_Documents") {
                  $("#gumasta_C_SolePI").hide();
                  $("#gumasta_C_P_LLP").hide();
                  $("#gumasta_C_PL").show();
                  $("#gumasta_C_HUF").hide();
                  $("#gumasta_C_Society").hide();
                }
              }
            );
            if (res.data.informationGiven.documents.length > 0) {
              let doc = res.data.informationGiven.documents;
              if (doc[2].status == "done") {
                if (doc[2].IDProofApplicant1 == null) {
                  $("#PLAadhar1").hide();
                } else {
                  $("#PLAadhar1").show();
                  this.setState({
                    Aadhar1Link: doc[2].IDProofApplicant1,
                  });
                }
              } else {
                $("#PLAadhar1").hide();
              }
              if (doc[3].status == "done") {
                if (doc[3].IDProofApplicant2 == null) {
                  $("#PLAadhar2").hide();
                } else {
                  $("#PLAadhar2").show();
                  this.setState({
                    Aadhar2Link: doc[3].IDProofApplicant2,
                  });
                }
              } else {
                $("#PLAadhar2").hide();
              }
              if (doc[0].status == "done") {
                if (doc[0].ApplicantPhoto1 == null) {
                  $("#PLPic1").hide();
                } else {
                  $("#PLPic1").show();
                  this.setState({
                    Pic1Link: doc[0].ApplicantPhoto1,
                  });
                }
              } else {
                $("#PLPic1").hide();
              }
              if (doc[1].status == "done") {
                if (doc[1].ApplicantPhoto2 == null) {
                  $("#PLPic2").hide();
                } else {
                  $("#PLPic2").show();
                  this.setState({
                    Pic2Link: doc[1].ApplicantPhoto2,
                  });
                }
              } else {
                $("#PLPic2").hide();
              }
              if (doc[7].status == "done") {
                if (doc[7].PremisePhoto == null) {
                  $("#PLPan3").hide();
                } else {
                  $("#PLPan3").show();
                  this.setState({
                    Pan3Link: doc[7].PremisePhoto,
                  });
                }
              } else {
                $("#PLPan3").hide();
              }
              if (doc[4].status == "done") {
                if (doc[4].CertificateOfIncorporation == null) {
                  $("#PLCI").hide();
                } else {
                  $("#PLCI").show();
                  this.setState({
                    CILink: doc[4].CertificateOfIncorporation,
                  });
                }
              } else {
                $("#PLCI").hide();
              }
              if (doc[6].status == "done") {
                if (doc[6].MOA == null) {
                  $("#PLMOA").hide();
                } else {
                  $("#PLMOA").show();
                  this.setState({
                    MOALink: doc[6].MOA,
                  });
                }
              } else {
                $("#PLMOA").hide();
              }
              if (doc[5].status == "done") {
                if (doc[5].AOA == null) {
                  $("#PLAOA").hide();
                } else {
                  $("#PLAOA").show();
                  this.setState({
                    AOALink: doc[5].AOA,
                  });
                }
              } else {
                $("#PLAOA").hide();
              }
              if (doc[9].status == "done") {
                if (doc[9].CopyOfRegistry == null) {
                  $("#PLRCopyOfRegistry").hide();
                } else {
                  $("#PLRCopyOfRegistry").show();
                  this.setState({
                    RCopyOfRegistryLink: doc[9].CopyOfRegistry,
                  });
                }
              } else {
                $("#PLRCopyOfRegistry").hide();
              }
              if (doc[11].status == "done") {
                if (doc[11].OwnershipDeed == null) {
                  $("#PLCopyOfRegistry").hide();
                } else {
                  $("#PLCopyOfRegistry").show();
                  this.setState({
                    CopyOfRegistryLink: doc[11].OwnershipDeed,
                  });
                }
              } else {
                $("#PLCopyOfRegistry").hide();
              }
              if (doc[10].status == "done") {
                if (doc[10].RentAgreement == null) {
                  $("#PLRentAgreement").hide();
                } else {
                  $("#PLRentAgreement").show();
                  this.setState({
                    RentAgreementLink: doc[10].RentAgreement,
                  });
                }
              } else {
                $("#PLRentAgreement").hide();
              }
              if (doc[8].status == "done") {
                if (doc[8].ResidentialRentAgreement == null) {
                  $("#PLRRentAgreement").hide();
                } else {
                  $("#PLRRentAgreement").show();
                  this.setState({
                    RRentAgreementLink: doc[8].ResidentialRentAgreement,
                  });
                }
              } else {
                $("#PLRRentAgreement").hide();
              }
            }

            if (res.data.informationGiven.inputFields.length > 0) {
              let AddressProofType =
                res.data.informationGiven.inputFields[2].AddressProofType;
              this.setState(
                {
                  AddressProofType: AddressProofType,
                },
                () => {
                  if (this.state.AddressProofType == "OwnershipDeed") {
                    $("#PLCopyOfRegistry").show();
                    $("#PLRentAgreement").hide();
                  } else if (this.state.AddressProofType == "RentAgreement") {
                    $("#PLCopyOfRegistry").hide();
                    $("#PLRentAgreement").show();
                  }
                }
              );

              let ResidentialAddressProofType =
                res.data.informationGiven.inputFields[1].ResidentialAddressProofType;
              this.setState(
                {
                  AddressProofType: ResidentialAddressProofType,
                },
                () => {
                  if (this.state.RAddressProofType == "CopyOfRegistry") {
                    $("#PLCopyOfRegistry").show();
                    $("#PLRentAgreement").hide();
                  } else if (this.state.RAddressProofType == "ResidentialRentAgreement") {
                    $("#PLCopyOfRegistry").hide();
                    $("#PLRentAgreement").show();
                  }
                }
              );
              this.setState(
                {
                  businessNature: res.data.informationGiven.inputFields[0].Nature,
                }
              )
            }

            if (res.data.informationGiven.addDocInformation.length > 0) {
              $("#additionalDocumentDiv3").show();
              this.setState({
                additionalArray1: [
                  ...res.data.informationGiven.addDocInformation,
                ],
              });
            } else {
              $("#additionalDocumentDiv3").hide();
            }
          } else if (res.data.serviceType == "GUMASTA_HUF_Documents") {
            this.setState(
              {
                dropDownValue: "GUMASTA_HUF_Documents",
                serviceType: "GUMASTA_HUF_Documents",
                deliveredDocuments: res.data.deliveredDocuments,
              },
              () => {
                if (this.state.dropDownValue == "GUMASTA_HUF_Documents") {
                  $("#gumasta_C_SolePI").hide();
                  $("#gumasta_C_P_LLP").hide();
                  $("#gumasta_C_PL").hide();
                  $("#gumasta_C_HUF").show();
                  $("#gumasta_C_Society").hide();
                }
              }
            );
            if (res.data.informationGiven.documents.length > 0) {
              let doc = res.data.informationGiven.documents;
              if (doc[0].status == "done") {
                if (doc[0].IDProofApplicant == null) {
                  $("#HUFAadhar1").hide();
                } else {
                  $("#HUFAadhar1").show();
                  this.setState({
                    Aadhar1Link: doc[0].IDProofApplicant,
                  });
                }
              } else {
                $("#HUFAadhar1").hide();
              }
              if (doc[1].status == "done") {
                if (doc[1].ApplicantPhoto1 == null) {
                  $("#HUFPic1").hide();
                } else {
                  $("#HUFPic1").show();
                  this.setState({
                    Pic1Link: doc[1].ApplicantPhoto1,
                  });
                }
              } else {
                $("#HUFPic1").hide();
              }
              if (doc[2].status == "done") {
                if (doc[2].HUFDeedDocument == null) {
                  $("#HUFDeed").hide();
                } else {
                  $("#HUFDeed").show();
                  this.setState({
                    DeedLink: doc[2].HUFDeedDocument,
                  });
                }
              } else {
                $("#HUFDeed").hide();
              }
              if (doc[3].status == "done") {
                if (doc[3].PremisePhoto == null) {
                  $("#HUFBP").hide();
                } else {
                  $("#HUFBP").show();
                  this.setState({
                    BPLink: doc[3].PremisePhoto,
                  });
                }
              } else {
                $("#HUFBP").hide();
              }
              if (doc[5].status == "done") {
                if (doc[5].CopyOfRegistry == null) {
                  $("#HUFRCopyOfRegistry").hide();
                } else {
                  $("#HUFRCopyOfRegistry").show();
                  this.setState({
                    RCopyOfRegistryLink: doc[5].CopyOfRegistry,
                  });
                }
              } else {
                $("#HUFRCopyOfRegistry").hide();
              }
              if (doc[4].status == "done") {
                if (doc[4].ResidentialRentAgreement == null) {
                  $("#HUFRentAgreement").hide();
                } else {
                  $("#HUFRentAgreement").show();
                  this.setState({
                    RRentAgreementLink: doc[4].ResidentialRentAgreement,
                  });
                }
              } else {
                $("#HUFRRentAgreement").hide();
              }
              if (doc[6].status == "done") {
                if (doc[6].RentAgreement == null) {
                  $("#HUFRentAgreement").hide();
                } else {
                  $("#HUFRentAgreement").show();
                  this.setState({
                    RentAgreementLink: doc[6].RentAgreement,
                  });
                }
              } else {
                $("#HUFRentAgreement").hide();
              }
              if (doc[7].status == "done") {
                if (doc[7].OwnershipDeed == null) {
                  $("#HUFCopyOfRegistry").hide();
                } else {
                  $("#HUFCopyOfRegistry").show();
                  this.setState({
                    CopyOfRegistryLink: doc[7].OwnershipDeed,
                  });
                }
              } else {
                $("#HUFCopyOfRegistry").hide();
              }
            }

            if (res.data.informationGiven.inputFields.length > 0) {
              let AddressProofType =
                res.data.informationGiven.inputFields[2].AddressProofType;
              this.setState(
                {
                  AddressProofType: AddressProofType,
                },
                () => {
                  if (this.state.AddressProofType == "OwnershipDeed") {
                    $("#HUFCopyOfRegistry").show();
                    $("#HUFRentAgreement").hide();
                  } else if (this.state.AddressProofType == "RentAgreement") {
                    $("#HUFCopyOfRegistry").hide();
                    $("#HUFRentAgreement").show();
                  }
                }
              );
              let ResidentialAddressProofType =
                res.data.informationGiven.inputFields[1].ResidentialAddressProofType;
              this.setState(
                {
                  RAddressProofType: ResidentialAddressProofType,
                },
                () => {
                  if (this.state.RAddressProofType == "CopyOfRegistry") {
                    $("#HUFRCopyOfRegistry").show();
                    $("#HUFRRentAgreement").hide();
                  } else if (this.state.RAddressProofType == "ResidentialRentAgreement") {
                    $("#HUFRCopyOfRegistry").hide();
                    $("#HUFRRentAgreement").show();
                  }
                }
              );

              this.setState(
                {
                  businessNature: res.data.informationGiven.inputFields[0].Nature,
                })
            }

            if (res.data.informationGiven.addDocInformation.length > 0) {
              $("#additionalDocumentDiv4").show();
              this.setState({
                additionalArray1: [
                  ...res.data.informationGiven.addDocInformation,
                ],
              });
            } else {
              $("#additionalDocumentDiv4").hide();
            }
          } else if (res.data.serviceType == "GUMASTA_STC_Documents") {
            this.setState(
              {
                dropDownValue: "GUMASTA_STC_Documents",
                serviceType: "GUMASTA_STC_Documents",
                deliveredDocuments: res.data.deliveredDocuments,
              },
              () => {
                if (this.state.dropDownValue == "GUMASTA_STC_Documents") {
                  $("#gumasta_C_SolePI").hide();
                  $("#gumasta_C_P_LLP").hide();
                  $("#gumasta_C_PL").hide();
                  $("#gumasta_C_HUF").hide();
                  $("#gumasta_C_Society").show();
                }
              }
            );
            if (res.data.informationGiven.documents.length > 0) {
              let doc = res.data.informationGiven.documents;
              if (doc[2].status == "done") {
                if (doc[2].IDProofApplicant1 == null) {
                  $("#STAadhar1").hide();
                } else {
                  $("#STAadhar1").show();
                  this.setState({
                    Aadhar1Link: doc[2].IDProofApplicant1,
                  });
                }
              } else {
                $("#STAadhar1").hide();
              }
              if (doc[3].status == "done") {
                if (doc[3].IDProofApplicant2 == null) {
                  $("#STAadhar2").hide();
                } else {
                  $("#STAadhar2").show();
                  this.setState({
                    Aadhar2Link: doc[3].IDProofApplicant2,
                  });
                }
              } else {
                $("#STAadhar2").hide();
              }
              if (doc[0].status == "done") {
                if (doc[0].ApplicantPhoto1 == null) {
                  $("#STPic1").hide();
                } else {
                  $("#STPic1").show();
                  this.setState({
                    Pic1Link: doc[0].ApplicantPhoto1,
                  });
                }
              } else {
                $("#STPic1").hide();
              }
              if (doc[1].status == "done") {
                if (doc[1].ApplicantPhoto2 == null) {
                  $("#STPic2").hide();
                } else {
                  $("#STPic2").show();
                  this.setState({
                    Pic2Link: doc[1].ApplicantPhoto2,
                  });
                }
              } else {
                $("#STPic2").hide();
              }
              if (doc[5].status == "done") {
                if (doc[5].PremisePhoto == null) {
                  $("#STPan3").hide();
                } else {
                  $("#STPan3").show();
                  this.setState({
                    Pan3Link: doc[5].PremisePhoto,
                  });
                }
              } else {
                $("#STPan3").hide();
              }
              if (doc[4].status == "done") {
                if (doc[4].CertificateOfRegistration == null) {
                  $("#STCR").hide();
                } else {
                  $("#STCR").show();
                  this.setState({
                    CRLink: doc[4].CertificateOfRegistration,
                  });
                }
              } else {
                $("#STCR").hide();
              }
              if (doc[8].status == "done") {
                if (doc[8].BankProof == null) {
                  $("#STBP").hide();
                } else {
                  $("#STBP").show();
                  this.setState({
                    BPLink: doc[8].BankProof,
                  });
                }
              } else {
                $("#STBP").hide();
              }
              if (doc[9].status == "done") {
                if (doc[9].CopyOfRegistry == null) {
                  $("#STRCopyOfRegistry").hide();
                } else {
                  $("#STRCopyOfRegistry").show();
                  this.setState({
                    RCopyOfRegistryLink: doc[9].CopyOfRegistry,
                  });
                }
              } else {
                $("#STRCopyOfRegistry").hide();
              }
              if (doc[7].status == "done") {
                if (doc[7].OwnershipDeed == null) {
                  $("#STCopyOfRegistry").hide();
                } else {
                  $("#STCopyOfRegistry").show();
                  this.setState({
                    CopyOfRegistryLink: doc[7].OwnershipDeed,
                  });
                }
              } else {
                $("#STCopyOfRegistry").hide();
              }
              if (doc[8].status == "done") {
                if (doc[8].RentAgreement == null) {
                  $("#STRentAgreement").hide();
                } else {
                  $("#STRentAgreement").show();
                  this.setState({
                    RentAgreementLink: doc[8].RentAgreement,
                  });
                }
              } else {
                $("#STRentAgreement").hide();
              }
              if (doc[6].status == "done") {
                if (doc[6].ResidentialRentAgreement == null) {
                  $("#STRRentAgreement").hide();
                } else {
                  $("#STRRentAgreement").show();
                  this.setState({
                    RRentAgreementLink: doc[6].ResidentialRentAgreement,
                  });
                }
              } else {
                $("#STRRentAgreement").hide();
              }
            }

            if (res.data.informationGiven.inputFields.length > 0) {
              let AddressProofType =
                res.data.informationGiven.inputFields[2].AddressProofType;
              this.setState(
                {
                  AddressProofType: AddressProofType,
                },
                () => {
                  if (this.state.AddressProofType == "OwnershipDeed") {
                    $("#STCopyOfRegistry").show();
                    $("#STRentAgreement").hide();
                  } else if (this.state.AddressProofType == "RentAgreement") {
                    $("#STCopyOfRegistry").hide();
                    $("#STRentAgreement").show();
                  }
                }
              );
              let ResidentialAddressProofType =
                res.data.informationGiven.inputFields[1].ResidentialAddressProofType;
              this.setState(
                {
                  RAddressProofType: ResidentialAddressProofType,
                },
                () => {
                  if (this.state.RAddressProofType == "CopyOfRegistry") {
                    $("#STRCopyOfRegistry").show();
                    $("#STRRentAgreement").hide();
                  } else if (this.state.RAddressProofType == "ResidentialRentAgreement") {
                    $("#STRCopyOfRegistry").hide();
                    $("#STRRentAgreement").show();
                  }
                }
              );
              this.setState(
                {
                  businessNature: res.data.informationGiven.inputFields[0].Nature,
                }
              )
            }

            if (res.data.informationGiven.addDocInformation.length > 0) {
              $("#additionalDocumentDiv5").show();
              this.setState({
                additionalArray1: [
                  ...res.data.informationGiven.addDocInformation,
                ],
              });
            } else {
              $("#additionalDocumentDiv5").hide();
            }
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $(".serviceoverlay").hide();
      });
  };

  renderCommenthistory() {
    if (this.state.commentHistroyarray.length > 0) {
      return this.state.commentHistroyarray.map((activedoc, index) => {
        if (activedoc.commentBy == "user" && activedoc.special == "no") {
          return (
            <div className="rightconvtextdiv" key={index}>
              <p className="rightconvtext">{activedoc.comment}</p>
              <p className="rightconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "user" &&
          activedoc.special == "green"
        ) {
          return (
            <div className="rightconvtextdiv" key={index}>
              <p className="rightgreenconvtext">{activedoc.comment}</p>
              <p className="rightconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "employee" &&
          activedoc.special == "no"
        ) {
          return (
            <div className="leftconvtextdiv" key={index}>
              <p className="leftconvtext">{activedoc.comment}</p>
              <p className="leftconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "user" &&
          activedoc.special == "red"
        ) {
          return (
            <div className="rightunappconvtextdiv" key={index}>
              <p className="rightunappconvtext">{activedoc.comment}</p>
              <p className="rightconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "employee" &&
          activedoc.special == "green"
        ) {
          return (
            <div className="leftgreenconvtextdiv" key={index}>
              <p className="leftgreenconvtext">{activedoc.comment}</p>
              <p className="leftconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        } else if (
          activedoc.commentBy == "employee" &&
          activedoc.special == "red"
        ) {
          return (
            <div className="leftconvtextdiv" key={index}>
              <p className="leftunappconvtext">{activedoc.comment}</p>
              <p className="leftconvtexttime">{activedoc.dateTime}</p>
            </div>
          );
        }
      });
    } else if (this.state.commentHistroyarray.length == 0) {
      return <p className="convservicename">No comments available!</p>;
    }
  }

  showNotification = (message) => {
    const notifContainer = document.getElementById("notifContainer");
    const notif = document.createElement("div");
    notif.classNameList.add("notifBox");
    notif.innerText = message;
    notifContainer.appendChild(notif);
    setTimeout(() => $(notif).addClass("pop"), 10);
    setTimeout(() => $(notif).removeClass("pop"), 3000);
    setTimeout(() => $(notif).remove(), 3100);
  };

  handleSAadharseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SAadharseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "AadhaarCard",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#SAadharseebtn").hide();
          $("#SAadharhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };
  handlewhatsppcontactno = (e) => {
    this.setState({
      whatsppcontactno: e.target.value,
    });
  };
  handleemailmail = (e) => {
    this.setState({
      emailmail: e.target.value,
    });
  };

  handlecustomwhatsappdateChange = (e) => {
    this.setState({
      customwhatsappdate: e.target.value,
    });
  };

  handlecustomemaildateChange = (e) => {
    this.setState({
      customemaildate: e.target.value,
    });
  };

  handlewhatsapptimeperiodChange = (e) => {
    this.setState({
      Selectedwhatsapptimeperiod: e.target.value,
    });
    if (e.target.value == "custom") {
      $("#whatsappdateinput").show();
    } else if (e.target.value == "always") {
      $("#whatsappdateinput").hide();
      $(".sharedocmentwhatsapplifeerrormessage").show();
    } else {
      $("#whatsappdateinput").hide();
      $(".sharedocmentwhatsapplifeerrormessage").hide();
    }
  };
  handleemailtimeperiodChange = (e) => {
    $(".sharedocmentemaildateerrormessage").hide();
    this.setState({
      Selectedemailtimeperiod: e.target.value,
    });
    if (e.target.value == "custom") {
      $("#emaildateinput").show();
    } else if (e.target.value == "always") {
      $("#emaildateinput").hide();
      $(".sharedocmentemaillifeerrormessage").show();
    } else {
      $("#emaildateinput").hide();
      $(".sharedocmentemaillifeerrormessage").hide();
    }
  };

  whatsapp = (docname, link) => {
    $(".myAddModal1").modal({ backdrop: "static", keyboard: false });
    this.setState({
      sharedocumentname: docname,
      sharedocumentlink: link,
    });
    sharedocumentarray[0].documentname = docname;
    sharedocumentarray[0].link = link;
  };

  email = (docname, link) => {
    $(".myAddModal2").modal({ backdrop: "static", keyboard: false });
    this.setState({
      sharedocumentname: docname,
      sharedocumentlink: link,
    });
    sharedocumentarray[0].documentname = docname;
    sharedocumentarray[0].link = link;
  };

  submitemail = () => {
    let ToDate = new Date();
    if (this.state.emailmail.trim() === "") {
      $(".sharedocmentemailerrormessage").show();
      $(".sharedocmentemailerrormessage").html("Please enter  E-mail");
    } else if (this.state.emailmail.trim().length > 50) {
      $(".sharedocmentemailerrormessage").show();
      $(".sharedocmentemailerrormessage").html(
        "E-mail should be less than 50 characters"
      );
    } else if (
      !this.state.emailmail
        .trim()
        .match(/^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i)
    ) {
      $(".sharedocmentemailerrormessage").show();
      $(".sharedocmentemailerrormessage").html("Please enter  valid E-mail");
    } else if (this.state.Selectedemailtimeperiod == "select") {
      $(".sharedocmentemailerrormessage").hide();
      $(".sharedocmentemailselecterrormessage").show();
      $(".sharedocmentemailselecterrormessage").html("Please select a option");
    } else if (
      this.state.Selectedemailtimeperiod == "custom" &&
      this.state.customemaildate == ""
    ) {
      $(".sharedocmentemailerrormessage").hide();
      $(".sharedocmentemailselecterrormessage").hide();
      $(".sharedocmentemaildateerrormessage").html("Please enter date");
      $(".sharedocmentemaildateerrormessage").show();
    } else if (
      this.state.Selectedemailtimeperiod == "custom" &&
      new Date(this.state.customemaildate).getTime() < ToDate.getTime()
    ) {
      $(".sharedocmentemailerrormessage").hide();
      $(".sharedocmentemailselecterrormessage").hide();
      $(".sharedocmentemaildateerrormessage").html("Please enter a vaild date");
      $(".sharedocmentemaildateerrormessage").show();
    } else {
      $(".upload_btn20_70").hide();
      $(".upload_btnloader_70").show();
      $(".cancelbtn_70").attr("disabled", true);
      var localemaildate = this.state.customemaildate;
      if (
        this.state.Selectedemailtimeperiod != "custom" &&
        this.state.customemaildate != ""
      ) {
        localemaildate = "";
      }

      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=sharedocumentEmailNormal",
          {
            userId: Decuserid,
            requestType: temp,
            agentId: Decagentid,
            emailto: this.state.emailmail.trim(),
            duration: this.state.Selectedemailtimeperiod,
            custom: localemaildate,
            type: this.state.shareemail_radio,
            shareableDocument: sharedocumentarray,
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((resp) => {
          $(".upload_btn20_70").show();
          $(".upload_btnloader_70").hide();
          $(".cancelbtn_70").attr("disabled", false);
          $(".myAddModal2").modal("hide");
          $(".sharedocmentemaillifeerrormessage").hide();
          $(".sharedocmentemailerrormessage").hide();
          $(".sharedocmentemailselecterrormessage").hide();
          $(".sharedocmentemaildateerrormessage").hide();
          var localemailmail = this.state.emailmail;
          var localradio = this.state.shareemail_radio;
          this.setState({
            Selectedemailtimeperiod: "select",
            emailmail: "",
            shareemail_radio: "Legal251",
            customemaildate: "",
          });
          $("#emaildateinput").hide();
          if (resp.data.code == 200 && resp.data.status == "success") {
            if (localradio == "Personal") {
              window.open(
                `mailto:${localemailmail}?subject=Shared%20Documents%20-%20Legal251&body=Hi%20There%20!!%20I%20am%20sharing%20following%20documents%20with%20you%20via%20my%20Legal251%20Panel:-%0a${resp.data.documentLinks[0].documentname}%0a${resp.data.documentLinks[0].link}`,
                "_blank"
              );
            } else {
              $(".myAddModal3").modal({ backdrop: "static", keyboard: false });
              setTimeout(function () {
                $(".myAddModal3").modal("hide");
              }, 3000);
            }
          } else if (
            resp.data.code == "201" &&
            resp.data.status == "invalidauth"
          ) {
            this.logout();
          }
          else if (resp.data.code == "201" && resp.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          }
          else {
            this.showNotification(resp.data.message);
          }
        })
        .catch((err) => {
          this.showNotification("Something went wrong, Try again!");
        });
    }
  };

  submitwhatsapp = () => {
    let ToDate = new Date();
    if (this.state.whatsppcontactno.trim() === "") {
      $(".sharedocmentwhatsapperrormessage").show();
      $(".sharedocmentwhatsapperrormessage").html("Please enter  Mobile No");
    } else if (!this.state.whatsppcontactno.trim().match(/^[6-9]\d{9}$/)) {
      $(".sharedocmentwhatsapperrormessage").show();
      $(".sharedocmentwhatsapperrormessage").html(
        "Please enter  valid Mobile No"
      );
    } else if (this.state.Selectedwhatsapptimeperiod == "select") {
      $(".sharedocmentwhatsapperrormessage").hide();
      $(".sharedocmentwhatsappselecterrormessage").show();
      $(".sharedocmentwhatsappselecterrormessage").html(
        "Please select a option"
      );
    } else if (
      this.state.Selectedwhatsapptimeperiod == "custom" &&
      this.state.customwhatsappdate == ""
    ) {
      $(".sharedocmentwhatsapperrormessage").hide();
      $(".sharedocmentwhatsappselecterrormessage").hide();
      $(".sharedocmentwhatsappdateerrormessage").html("Please enter date");
      $(".sharedocmentwhatsappdateerrormessage").show();
    } else if (
      this.state.Selectedwhatsapptimeperiod == "custom" &&
      new Date(this.state.customwhatsappdate).getTime() < ToDate.getTime()
    ) {
      $(".sharedocmentwhatsapperrormessage").hide();
      $(".sharedocmentwhatsappselecterrormessage").hide();
      $(".sharedocmentwhatsappdateerrormessage").html(
        "Please a enter vaild date"
      );
      $(".sharedocmentwhatsappdateerrormessage").show();
    } else {
      $(".upload_btn20_90").hide();
      $(".upload_btnloader_90").show();
      $(".cancelbtn_90").attr("disabled", true);
      var localwhatsappdate = this.state.customwhatsappdate;
      if (
        this.state.Selectedwhatsapptimeperiod != "custom" &&
        this.state.customwhatsappdate != ""
      ) {
        localwhatsappdate = "";
      }
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "p=sharedocumentLinkNormal",
          {
            userId: Decuserid,
            requestType: temp,
            agentId: Decagentid,
            duration: this.state.Selectedwhatsapptimeperiod,
            custom: localwhatsappdate,
            shareableDocument: sharedocumentarray,
          },
          {
            headers: {
              Authkey: process.env.REACT_APP_API_KEY,
              "App-Token-Access": Decjwttoken,
            },
            auth: {
              username: process.env.REACT_APP_API_USERNAME,
              password: process.env.REACT_APP_API_PASSWORD,
            },
          }
        )
        .then((resp) => {
          $(".upload_btn20_90").show();
          $(".upload_btnloader_90").hide();
          $(".cancelbtn_90").attr("disabled", false);
          $(".myAddModal1").modal("hide");
          $(".sharedocmentwhatsapperrormessage").hide();
          $(".sharedocmentwhatsapplifeerrormessage").hide();
          $(".sharedocmentwhatsappselecterrormessage").hide();
          $(".sharedocmentwhatsappdateerrormessage").hide();
          var localwhatsppcontactno = this.state.whatsppcontactno;
          this.setState({
            Selectedwhatsapptimeperiod: "select",
            customemaildate: "",
            whatsppcontactno: "",
          });
          $("#whatsappdateinput").hide();

          if (resp.data.code == 200 && resp.data.status == "success") {
            window.open(
              `https://web.whatsapp.com/send?phone=91${localwhatsppcontactno}&text=Hi%20There%20!!%20I%20am%20sharing%20following%20documents%20with%20you%20via%20my%20Legal251%20Panel:-%0a${resp.data.documentLinks[0].documentname}%0a${resp.data.documentLinks[0].link}`,
              "_blank"
            );
          } else if (
            resp.data.code == "201" &&
            resp.data.status == "invalidauth"
          ) {
            this.logout();
          }
          else if (resp.data.code == "201" && resp.data.status == "timeOut") {
            localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
            this.props.history.push({
              pathname: "/dashboard",
              state: { timeout: true },
            });
          }
          else {
            this.showNotification(resp.data.message);
          }
        })
        .catch((err) => {
          this.showNotification("Something went wrong, Try again!");
        });
    }
  };

  sharewhatsapp_radioChange = (e) => {
    this.setState({
      sharewhatsapp_radio: e.currentTarget.value,
    });
  };
  shareemail_radioChange = (e) => {
    this.setState({
      shareemail_radio: e.currentTarget.value,
    });
  };

  handleSAadharhidecommentsbtn = () => {
    $("#SAadharseebtn").show();
    $("#SAadharseebtn").attr("disabled", false);
    $("#SAadharhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleSPanseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SPanseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PANCard",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#SPanseebtn").hide();
          $("#SPanhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSPanhidecommentsbtn = () => {
    $("#SPanseebtn").show();
    $("#SPanseebtn").attr("disabled", false);
    $("#SPanhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleSPicseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SPicseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "SolePhoto",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#SPicseebtn").hide();
          $("#SPichidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSPichidecommentsbtn = () => {
    $("#SPicseebtn").show();
    $("#SPicseebtn").attr("disabled", false);
    $("#SPichidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleSBPseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SBPseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "BankProof",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#SBPseebtn").hide();
          $("#SBPhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSBPhidecommentsbtn = () => {
    $("#SBPseebtn").show();
    $("#SBPseebtn").attr("disabled", false);
    $("#SBPhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleSGumastaseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SGumastaseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "Gumasta",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#SGumastaseebtn").hide();
          $("#SGumastahidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSGumastahidecommentsbtn = () => {
    $("#SGumastaseebtn").show();
    $("#SGumastaseebtn").attr("disabled", false);
    $("#SGumastahidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleSTradeLicenceseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STradeLicenceseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "TradeLicence",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#STradeLicenceseebtn").hide();
          $("#STradeLicencehidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTradeLicencehidecommentsbtn = () => {
    $("#STradeLicenceseebtn").show();
    $("#STradeLicenceseebtn").attr("disabled", false);
    $("#STradeLicencehidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleSCopyOfRegistryseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SCopyOfRegistryseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "CopyOfRegistry",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#SCopyOfRegistryseebtn").hide();
          $("#SCopyOfRegistryhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSCopyOfRegistryhidecommentsbtn = () => {
    $("#SCopyOfRegistryseebtn").show();
    $("#SCopyOfRegistryseebtn").attr("disabled", false);
    $("#SCopyOfRegistryhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleSRentAgreementseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SRentAgreementseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "RentAgreement",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#SRentAgreementseebtn").hide();
          $("#SRentAgreementhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSRentAgreementhidecommentsbtn = () => {
    $("#SRentAgreementseebtn").show();
    $("#SRentAgreementseebtn").attr("disabled", false);
    $("#SRentAgreementhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleSPRseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#SPRseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PropertyTaxReceipt",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#SPRseebtn").hide();
          $("#SPRhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSPRhidecommentsbtn = () => {
    $("#SPRseebtn").show();
    $("#SPRseebtn").attr("disabled", false);
    $("#SPRhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  AdditionalFilesArea = (serviceTypeName) => {
    if (this.state.dropDownValue == serviceTypeName) {
      if (this.state.additionalArray1.length > 0) {
        return this.state.additionalArray1.map((file, index) => {
          let NameOfAddFile = Object.keys(file)[0];
          let link = file[Object.keys(file)[0]];
          return (
            <div className="displayflex" key={index}>
              <div className="uploadnocol">
                <p className="uploadadditionalsidetext">{index + 1}&#41;</p>
              </div>
              <div className="uploadadditionaldatacol">
                <div className="row mb-2">
                  <div className="col-md-7">
                    <input
                      type="text"
                      className="uploadtextfield marginzero"
                      value={NameOfAddFile}
                      readOnly
                    />
                  </div>
                  <div className="col-md-5">
                    <div className="float-md-right float-left">
                      <div className="displayflex">
                        <div className="viewbtndivpadding">
                          <a className="viewbtn" href={link} target="_blank">
                            View{" "}
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/Path 19498.svg"
                              }
                            />
                          </a>
                        </div>
                        <div>
                          <button className="tick_btn">
                            {" "}
                            <img
                              alt="loading..."
                              src={process.env.PUBLIC_URL + "/img/tick.svg"}
                            />
                          </button>
                        </div>
                      </div>
                      <button
                        className="seecommentshistorybtn commentsbtn Fileseecommentsbtn1"
                        id={`${index}Fileseecommentsbtn1`}
                        onClick={() => this.handleFileseeComments1(index)}
                      >
                        See comment history{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                        />
                      </button>
                      <button
                        className="hidecommentshistorybtn Filehidecommentsbtn1"
                        id={`${index}Filehidecommentsbtn1`}
                        onClick={() => this.handleFilehideComments1(index)}
                      >
                        Hide comment history{" "}
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        });
      } else {
        $("#additionalDocumentDiv1").hide();
        $("#additionalDocumentDiv2").hide();
        $("#additionalDocumentDiv3").hide();
        $("#additionalDocumentDiv4").hide();
        $("#additionalDocumentDiv5").hide();
      }
    }
  };

  handleFileseeComments1 = (index) => {
    $(".commentsbtn").attr("disabled", false);
    $(`#${index}Fileseecommentsbtn1`).attr("disabled", true);
    let SelectedFile = this.state.additionalArray1[index];
    let NameOfAddFile = Object.keys(SelectedFile)[0];
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "additional",
          documentName: NameOfAddFile,
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(`#${index}Fileseecommentsbtn1`).hide();
          $(".hidecommentshistorybtn").hide();
          $(`#${index}Filehidecommentsbtn1`).show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          $(`#${index}Fileseecommentsbtn1`).attr("disabled", false);
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        $(`#${index}Fileseecommentsbtn1`).attr("disabled", false);
        this.showNotification("Something Went wrong");
      });
  };

  handleFilehideComments1 = (index) => {
    $(".commentsbtn").show();
    $(`#${index}Fileseecommentsbtn1`).show();
    $(`#${index}Fileseecommentsbtn1`).attr("disabled", false);
    $(`#${index}Filehidecommentsbtn1`).hide();
    $(".hidecommentshistorybtn  ").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  // P
  handlePAadhar1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PAadhar1seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "AadhaarCardApplicant1",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PAadhar1seebtn").hide();
          $("#PAadhar1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePAadhar1hidecommentsbtn = () => {
    $("#PAadhar1seebtn").show();
    $("#PAadhar1seebtn").attr("disabled", false);
    $("#PAadhar1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePAadhar2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PAadhar2seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "AadhaarCardApplicant2",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PAadhar2seebtn").hide();
          $("#PAadhar2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePAadhar2hidecommentsbtn = () => {
    $("#PAadhar2seebtn").show();
    $("#PAadhar2seebtn").attr("disabled", false);
    $("#PAadhar2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePPan1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PPan1seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PANCardApplicant1",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PPan1seebtn").hide();
          $("#PPan1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePPan1hidecommentsbtn = () => {
    $("#PPan1seebtn").show();
    $("#PPan1seebtn").attr("disabled", false);
    $("#PPan1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePPan2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PPan2seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PANCardApplicant2",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PPan2seebtn").hide();
          $("#PPan2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePPan2hidecommentsbtn = () => {
    $("#PPan2seebtn").show();
    $("#PPan2seebtn").attr("disabled", false);
    $("#PPan2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePPic1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PPic1seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ApplicantPhoto1",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PPic1seebtn").hide();
          $("#PPic1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePPic1hidecommentsbtn = () => {
    $("#PPic1seebtn").show();
    $("#PPic1seebtn").attr("disabled", false);
    $("#PPic1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePPic2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PPic2seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ApplicantPhoto2",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PPic2seebtn").hide();
          $("#PPic2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePPic2hidecommentsbtn = () => {
    $("#PPic2seebtn").show();
    $("#PPic2seebtn").attr("disabled", false);
    $("#PPic2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePBPseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PBPseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "BankProof",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PBPseebtn").hide();
          $("#PBPhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePBPhidecommentsbtn = () => {
    $("#PBPseebtn").show();
    $("#PBPseebtn").attr("disabled", false);
    $("#PBPhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePGumastaseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PGumastaseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "Gumasta",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PGumastaseebtn").hide();
          $("#PGumastahidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePGumastahidecommentsbtn = () => {
    $("#PGumastaseebtn").show();
    $("#PGumastaseebtn").attr("disabled", false);
    $("#PGumastahidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePTradeLicenceseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PTradeLicenceseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "TradeLicence",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PTradeLicenceseebtn").hide();
          $("#PTradeLicencehidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePTradeLicencehidecommentsbtn = () => {
    $("#PTradeLicenceseebtn").show();
    $("#PTradeLicenceseebtn").attr("disabled", false);
    $("#PTradeLicencehidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePCopyOfRegistryseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PCopyOfRegistryseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "CopyOfRegistry",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PCopyOfRegistryseebtn").hide();
          $("#PCopyOfRegistryhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePCopyOfRegistryhidecommentsbtn = () => {
    $("#PCopyOfRegistryseebtn").show();
    $("#PCopyOfRegistryseebtn").attr("disabled", false);
    $("#PCopyOfRegistryhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePRentAgreementseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PRentAgreementseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "RentAgreement",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PRentAgreementseebtn").hide();
          $("#PRentAgreementhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePRentAgreementhidecommentsbtn = () => {
    $("#PRentAgreementseebtn").show();
    $("#PRentAgreementseebtn").attr("disabled", false);
    $("#PRentAgreementhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePPRseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PPRseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PropertyTaxReceipt",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PPRseebtn").hide();
          $("#PPRhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePPRhidecommentsbtn = () => {
    $("#PPRseebtn").show();
    $("#PPRseebtn").attr("disabled", false);
    $("#PPRhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePLAadhar1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLAadhar1seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "AadhaarCardApplicant1",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PLAadhar1seebtn").hide();
          $("#PLAadhar1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLAadhar1hidecommentsbtn = () => {
    $("#PLAadhar1seebtn").show();
    $("#PLAadhar1seebtn").attr("disabled", false);
    $("#PLAadhar1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePLAadhar2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLAadhar2seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "AadhaarCardApplicant2",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PLAadhar2seebtn").hide();
          $("#PLAadhar2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLAadhar2hidecommentsbtn = () => {
    $("#PLAadhar2seebtn").show();
    $("#PLAadhar2seebtn").attr("disabled", false);
    $("#PLAadhar2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePLPan1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLPan1seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PANCardApplicant1",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PLPan1seebtn").hide();
          $("#PLPan1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLPan1hidecommentsbtn = () => {
    $("#PLPan1seebtn").show();
    $("#PLPan1seebtn").attr("disabled", false);
    $("#PLPan1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePLPan2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLPan2seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PANCardApplicant2",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PLPan2seebtn").hide();
          $("#PLPan2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLPan2hidecommentsbtn = () => {
    $("#PLPan2seebtn").show();
    $("#PLPan2seebtn").attr("disabled", false);
    $("#PLPan2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePLPic1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLPic1seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ApplicantPhoto1",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PLPic1seebtn").hide();
          $("#PLPic1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLPic1hidecommentsbtn = () => {
    $("#PLPic1seebtn").show();
    $("#PLPic1seebtn").attr("disabled", false);
    $("#PLPic1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePLPic2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLPic2seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ApplicantPhoto2",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PLPic2seebtn").hide();
          $("#PLPic2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLPic2hidecommentsbtn = () => {
    $("#PLPic2seebtn").show();
    $("#PLPic2seebtn").attr("disabled", false);
    $("#PLPic2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePLPan3seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLPan3seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PANCardCompany",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PLPan3seebtn").hide();
          $("#PLPan3hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLPan3hidecommentsbtn = () => {
    $("#PLPan3seebtn").show();
    $("#PLPan3seebtn").attr("disabled", false);
    $("#PLPan3hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePLCIseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLCIseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "CertificateOfIncorporation",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PLCIseebtn").hide();
          $("#PLCIhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLCIhidecommentsbtn = () => {
    $("#PLCIseebtn").show();
    $("#PLCIseebtn").attr("disabled", false);
    $("#PLCIhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePLMOAseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLMOAseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "MOA",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PLMOAseebtn").hide();
          $("#PLMOAhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLMOAhidecommentsbtn = () => {
    $("#PLMOAseebtn").show();
    $("#PLMOAseebtn").attr("disabled", false);
    $("#PLMOAhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePLAOAseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLAOAseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "AOA",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PLAOAseebtn").hide();
          $("#PLAOAhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLAOAhidecommentsbtn = () => {
    $("#PLAOAseebtn").show();
    $("#PLAOAseebtn").attr("disabled", false);
    $("#PLAOAhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePLBRseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLBRseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "BoardResolution",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PLBRseebtn").hide();
          $("#PLBRhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLBRhidecommentsbtn = () => {
    $("#PLBRseebtn").show();
    $("#PLBRseebtn").attr("disabled", false);
    $("#PLBRhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePLBPseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLBPseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "BankProof",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PLBPseebtn").hide();
          $("#PLBPhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLBPhidecommentsbtn = () => {
    $("#PLBPseebtn").show();
    $("#PLBPseebtn").attr("disabled", false);
    $("#PLBPhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePLGumastaseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLGumastaseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "Gumasta",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PLGumastaseebtn").hide();
          $("#PLGumastahidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLGumastahidecommentsbtn = () => {
    $("#PLGumastaseebtn").show();
    $("#PLGumastaseebtn").attr("disabled", false);
    $("#PLGumastahidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePLTradeLicenceseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLTradeLicenceseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "TradeLicence",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PLTradeLicenceseebtn").hide();
          $("#PLTradeLicencehidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLTradeLicencehidecommentsbtn = () => {
    $("#PLTradeLicenceseebtn").show();
    $("#PLTradeLicenceseebtn").attr("disabled", false);
    $("#PLTradeLicencehidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePLCopyOfRegistryseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLCopyOfRegistryseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "CopyOfRegistry",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PLCopyOfRegistryseebtn").hide();
          $("#PLCopyOfRegistryhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLCopyOfRegistryhidecommentsbtn = () => {
    $("#PLCopyOfRegistryseebtn").show();
    $("#PLCopyOfRegistryseebtn").attr("disabled", false);
    $("#PLCopyOfRegistryhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePLRentAgreementseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLRentAgreementseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "RentAgreement",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PLRentAgreementseebtn").hide();
          $("#PLRentAgreementhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLRentAgreementhidecommentsbtn = () => {
    $("#PLRentAgreementseebtn").show();
    $("#PLRentAgreementseebtn").attr("disabled", false);
    $("#PLRentAgreementhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handlePLPRseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#PLPRseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PropertyTaxReceipt",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#PLPRseebtn").hide();
          $("#PLPRhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handlePLPRhidecommentsbtn = () => {
    $("#PLPRseebtn").show();
    $("#PLPRseebtn").attr("disabled", false);
    $("#PLPRhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleHUFAadhar1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFAadhar1seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "AadhaarCardKarta",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#HUFAadhar1seebtn").hide();
          $("#HUFAadhar1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFAadhar1hidecommentsbtn = () => {
    $("#HUFAadhar1seebtn").show();
    $("#HUFAadhar1seebtn").attr("disabled", false);
    $("#HUFAadhar1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleHUFPan1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFPan1seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PANCardKarta",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#HUFPan1seebtn").hide();
          $("#HUFPan1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFPan1hidecommentsbtn = () => {
    $("#HUFPan1seebtn").show();
    $("#HUFPan1seebtn").attr("disabled", false);
    $("#HUFPan1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleHUFPic1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFPic1seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ApplicantPhoto1",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#HUFPic1seebtn").hide();
          $("#HUFPic1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFPic1hidecommentsbtn = () => {
    $("#HUFPic1seebtn").show();
    $("#HUFPic1seebtn").attr("disabled", false);
    $("#HUFPic1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleHUFPan2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFPan2seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PANCardHUF",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#HUFPan2seebtn").hide();
          $("#HUFPan2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFPan2hidecommentsbtn = () => {
    $("#HUFPan2seebtn").show();
    $("#HUFPan2seebtn").attr("disabled", false);
    $("#HUFPan2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleHUFDeedseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFDeedseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "HUFDeedDocument",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#HUFDeedseebtn").hide();
          $("#HUFDeedhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFDeedhidecommentsbtn = () => {
    $("#HUFDeedseebtn").show();
    $("#HUFDeedseebtn").attr("disabled", false);
    $("#HUFDeedhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleHUFBPseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFBPseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "BankProof",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#HUFBPseebtn").hide();
          $("#HUFBPhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFBPhidecommentsbtn = () => {
    $("#HUFBPseebtn").show();
    $("#HUFBPseebtn").attr("disabled", false);
    $("#HUFBPhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleHUFGumastaseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFGumastaseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "Gumasta",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#HUFGumastaseebtn").hide();
          $("#HUFGumastahidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFGumastahidecommentsbtn = () => {
    $("#HUFGumastaseebtn").show();
    $("#HUFGumastaseebtn").attr("disabled", false);
    $("#HUFGumastahidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleHUFTradeLicenceseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFTradeLicenceseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "TradeLicence",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#HUFTradeLicenceseebtn").hide();
          $("#HUFTradeLicencehidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFTradeLicencehidecommentsbtn = () => {
    $("#HUFTradeLicenceseebtn").show();
    $("#HUFTradeLicenceseebtn").attr("disabled", false);
    $("#HUFTradeLicencehidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleHUFCopyOfRegistryseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFCopyOfRegistryseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "CopyOfRegistry",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#HUFCopyOfRegistryseebtn").hide();
          $("#HUFCopyOfRegistryhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFCopyOfRegistryhidecommentsbtn = () => {
    $("#HUFCopyOfRegistryseebtn").show();
    $("#HUFCopyOfRegistryseebtn").attr("disabled", false);
    $("#HUFCopyOfRegistryhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleHUFRentAgreementseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFRentAgreementseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "RentAgreement",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#HUFRentAgreementseebtn").hide();
          $("#HUFRentAgreementhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFRentAgreementhidecommentsbtn = () => {
    $("#HUFRentAgreementseebtn").show();
    $("#HUFRentAgreementseebtn").attr("disabled", false);
    $("#HUFRentAgreementhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleHUFPRseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#HUFPRseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PropertyTaxReceipt",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#HUFPRseebtn").hide();
          $("#HUFPRhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleHUFPRhidecommentsbtn = () => {
    $("#HUFPRseebtn").show();
    $("#HUFPRseebtn").attr("disabled", false);
    $("#HUFPRhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleSTAadhar1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STAadhar1seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "AadhaarCardApplicant1",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#STAadhar1seebtn").hide();
          $("#STAadhar1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTAadhar1hidecommentsbtn = () => {
    $("#STAadhar1seebtn").show();
    $("#STAadhar1seebtn").attr("disabled", false);
    $("#STAadhar1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleSTAadhar2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STAadhar2seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "AadhaarCardApplicant2",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#STAadhar2seebtn").hide();
          $("#STAadhar2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTAadhar2hidecommentsbtn = () => {
    $("#STAadhar2seebtn").show();
    $("#STAadhar2seebtn").attr("disabled", false);
    $("#STAadhar2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleSTPan1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STPan1seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PANCardApplicant1",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#STPan1seebtn").hide();
          $("#STPan1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTPan1hidecommentsbtn = () => {
    $("#STPan1seebtn").show();
    $("#STPan1seebtn").attr("disabled", false);
    $("#STPan1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleSTPan2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STPan2seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PANCardApplicant2",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#STPan2seebtn").hide();
          $("#STPan2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTPan2hidecommentsbtn = () => {
    $("#STPan2seebtn").show();
    $("#STPan2seebtn").attr("disabled", false);
    $("#STPan2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleSTPic1seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STPic1seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ApplicantPhoto1",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#STPic1seebtn").hide();
          $("#STPic1hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTPic1hidecommentsbtn = () => {
    $("#STPic1seebtn").show();
    $("#STPic1seebtn").attr("disabled", false);
    $("#STPic1hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleSTPic2seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STPic2seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "ApplicantPhoto2",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#STPic2seebtn").hide();
          $("#STPic2hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTPic2hidecommentsbtn = () => {
    $("#STPic2seebtn").show();
    $("#STPic2seebtn").attr("disabled", false);
    $("#STPic2hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleSTPan3seecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STPan3seebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PANCardSTC",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#STPan3seebtn").hide();
          $("#STPan3hidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTPan3hidecommentsbtn = () => {
    $("#STPan3seebtn").show();
    $("#STPan3seebtn").attr("disabled", false);
    $("#STPan3hidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleSTCRseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STCRseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "CertificateOfRegistration",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#STCRseebtn").hide();
          $("#STCRhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTCRhidecommentsbtn = () => {
    $("#STCRseebtn").show();
    $("#STCRseebtn").attr("disabled", false);
    $("#STCRhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleSTBPseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STBPseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "BankProof",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#STBPseebtn").hide();
          $("#STBPhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTBPhidecommentsbtn = () => {
    $("#STBPseebtn").show();
    $("#STBPseebtn").attr("disabled", false);
    $("#STBPhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleSTBRseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STBRseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "BoardResolution",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#STBRseebtn").hide();
          $("#STBRhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTBRhidecommentsbtn = () => {
    $("#STBRseebtn").show();
    $("#STBRseebtn").attr("disabled", false);
    $("#STBRhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleSTGumastaseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STGumastaseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "Gumasta",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#STGumastaseebtn").hide();
          $("#STGumastahidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTGumastahidecommentsbtn = () => {
    $("#STGumastaseebtn").show();
    $("#STGumastaseebtn").attr("disabled", false);
    $("#STGumastahidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleSTTradeLicenceseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STTradeLicenceseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "TradeLicence",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#STTradeLicenceseebtn").hide();
          $("#STTradeLicencehidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTTradeLicencehidecommentsbtn = () => {
    $("#STTradeLicenceseebtn").show();
    $("#STTradeLicenceseebtn").attr("disabled", false);
    $("#STTradeLicencehidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleSTCopyOfRegistryseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STCopyOfRegistryseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "CopyOfRegistry",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#STCopyOfRegistryseebtn").hide();
          $("#STCopyOfRegistryhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTCopyOfRegistryhidecommentsbtn = () => {
    $("#STCopyOfRegistryseebtn").show();
    $("#STCopyOfRegistryseebtn").attr("disabled", false);
    $("#STCopyOfRegistryhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleSTRentAgreementseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STRentAgreementseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "RentAgreement",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#STRentAgreementseebtn").hide();
          $("#STRentAgreementhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTRentAgreementhidecommentsbtn = () => {
    $("#STRentAgreementseebtn").show();
    $("#STRentAgreementseebtn").attr("disabled", false);
    $("#STRentAgreementhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  handleSTPRseecommentsbtn = () => {
    $(".commentsbtn").attr("disabled", false);
    $("#STPRseebtn").attr("disabled", true);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "p=viewDocumentComments",
        {
          userId: Decuserid,
          requestType: temp,
          agentId: Decagentid,
          serviceId: Decserviceid,
          serviceName: Decservicename,
          documentType: "general",
          documentName: "PropertyTaxReceipt",
          FY: "",
          Month: "",
        },
        {
          headers: {
            Authkey: process.env.REACT_APP_API_KEY,
            "App-Token-Access": Decjwttoken,
          },
          auth: {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
          },
        }
      )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {
          this.setState({
            commentHistroyarray: res.data.commentHistroy,
          });
          $(".commentsbtn").show();
          $(".hidecommentshistorybtn").hide();
          $("#STPRseebtn").hide();
          $("#STPRhidecommentsbtn").show();
          $(".TDRightSide").show();
          $(".commentimg-section2").hide();
        } else if (res.data.code == "201" && res.data.status == "timeOut") {
          localStorage.removeItem("fdgshdjfkgjhyhdnxhsgdhskcj");
          this.props.history.push({
            pathname: "/dashboard",
            state: { timeout: true },
          });
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
      .catch((err) => {
        this.showNotification("Something Went wrong");
      });
  };

  handleSTPRhidecommentsbtn = () => {
    $("#STPRseebtn").show();
    $("#STPRseebtn").attr("disabled", false);
    $("#STPRhidecommentsbtn").hide();
    $(".TDRightSide").hide();
    $(".commentimg-section2").show();
  };

  showDeliveredDocuments() {
    if (this.state.deliveredDocuments.length > 0) {
      return this.state.deliveredDocuments.map((activedoc, index) => {
        return (
          <div className="displayflex documentmaincard" key={index}>
            <div>
              <img
                src={process.env.PUBLIC_URL + "/img/Path 247.svg"}
                className="docutypeimg"
              />
            </div>
            <div className="docucontdiv">
              <div className="row">
                <div className="col-md-6">
                  <p className="nameofdoc">{activedoc.documentName}</p>
                  <p className="datesenttext">
                    Date received:-{" "}
                    <span className="datesentspan">{activedoc.datesent}</span>
                  </p>
                </div>
                <div className="col-md-6">
                  <div className="fl_right">
                    <div className="displayflex">
                      <div className="viewbtndivpadding">
                        <a
                          href={activedoc.documentLink}
                          target="_blank"
                          className="viewbtn"
                        >
                          View{" "}
                          <img
                            src={process.env.PUBLIC_URL + "/img/Path 19498.svg"}
                          />
                        </a>
                      </div>
                      <div>
                        <a
                          href={activedoc.documentLink}
                          className="download_btn"
                          target="_blank"
                          download
                        >
                          Download{" "}
                          <img
                            src={
                              process.env.PUBLIC_URL + "/img/download_icon.svg"
                            }
                            alt="download"
                          />
                        </a>
                      </div>
                    </div>
                    <p className="docushareviatext">
                      Share via:{" "}
                      <a
                        onClick={() =>
                          this.whatsapp(
                            activedoc.documentName,
                            activedoc.documentLink
                          )
                        }
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/whatsapp.svg"}
                          className="docushareviawhats"
                        />
                      </a>{" "}
                      <a
                        onClick={() =>
                          this.email(
                            activedoc.documentName,
                            activedoc.documentLink
                          )
                        }
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/img/mail.svg"}
                          className="docushareviamail"
                        />
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.deliveredDocuments.length == 0) {
      return (
        <center className="myservicequiteimgcenter">
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
            className="emptydeliveredDocuments"
          />
        </center>
      );
    }
  }

  backbutton = () => {
    this.props.history.push({
      pathname: `/myuser/${Decuserid}/userservices`,
    });
  };


  handletimeline = () => {


    $("#timeline_btnloader").show();
    axios.post(
      process.env.REACT_APP_API_BASE_URL + "p=timeLine",
      {
        agentId: Decagentid,
        userId: Decuserid,
        serviceId: Decserviceid,
      },
      {
        headers: {
          Authkey: process.env.REACT_APP_API_KEY,
          "App-Token-Access": Decjwttoken,
        },
        auth: {
          username: process.env.REACT_APP_API_USERNAME,
          password: process.env.REACT_APP_API_PASSWORD,
        },
      }
    )
      .then((res) => {
        if (res.data.code == "200" && res.data.status == "success") {

          if (res.data.timeLine.length > 0) {
            let length = res.data.timeLine.length;
            let ftimeline = res.data.timeLine.filter((items, index) => {
              return (index <= this.state.timelcount)
            })
            this.setState({
              filtertimel: ftimeline,
              timelcount: this.state.timelcount + 3,
              Timeline: res.data.timeLine,
              Timelinedata: true,
            });
            this.setState({

            });
            // .splice(this.state.timelcount,length)
          }
        } else if (res.data.code == "201" && res.data.status == "invalidauth") {
          this.logout();
        } else {
          this.showNotification(res.data.message);
        }
      })
  }

  handlemoretimeline = () => {

    let ftimeline = this.state.Timeline.filter((items, index) => {
      return (index <= this.state.timelcount)
    })
    this.setState({
      filtertimel: ftimeline,
      timelcount: this.state.timelcount + 3
    });

  }


  render() {
    if (
      localStorage.getItem("gglltakoinoeenl") ==
      process.env.REACT_APP_LOGIN_KEY &&
      localStorage.getItem("toeljgtkewlna") != null &&
      localStorage.getItem("qazxswedcvfrtgb") != null
    ) {
      return (
        <div className="cont">
          <div id="notifContainer"></div>

          <Header />

          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Path_217.svg"}
            className="sidebaropenicon"
          />
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Path_231.svg"}
            onClick={this.backbutton}
            className="sidebarbackicon"
          />
          <img
            alt="loading..."
            src={process.env.PUBLIC_URL + "/img/Icon_ionic-ios-arrow-down.svg"}
            className="sidebarexpandicon"
          />

          <div className="overlay1"></div>
          <div className="sidebar sidebarshift">
            <div className="side_div_2 sidebar_txt active">
              <Link to="/dashboard">Overview</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <Link to="/myservices">My Services</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <Link to="/paymentinvoice">Payments Invoices</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <Link to="/documentrecords">Documents Record</Link>
            </div>
            <div className="side_div_1 sidebar_txt">
              <Link to="/addservice">Add service</Link>
            </div>
            <div></div>
          </div>
          <div className="arrow-left2 visibilityhidden"></div>
          <div className="white_div expandwhite_div">
            <div className="serviceoverlay">
              <div className="serviceoverlayinside">
                <center>
                  <img
                    src={process.env.PUBLIC_URL + "/img/loader.gif"}
                    className="serviceloader"
                  />
                </center>
              </div>
            </div>
            <div className="white_box">
              <div className="uploaddocu">
                <div className="row">
                  <div className="myservicesortbyfilterbystatus mt-1 ml-1">

                  </div>
                  <div className="col-lg-9 mt-5">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="inner-header d-flex justify-content-between">
                          <div className="slider-navigation-tab">
                            <header className="tabs-nav position-relative">
                              <ul className="d-flex slider-navigation-tab-ul">
                                <li
                                  className="active slider-navigation-tab-li"
                                  id="activeTab"
                                >
                                  <a href="#tab1" className="px-2 py-1">
                                    Information given
                                  </a>
                                </li>
                                <li
                                  className="slider-navigation-tab-li"
                                  id="completedTab"
                                >
                                  <a href="#tab2" className="px-2 py-1">
                                    Deliverables
                                  </a>
                                </li>
                                <hr className="slider-navigation-tab-vertical-line" />
                                <div className="sliderpage-slider"></div>
                              </ul>
                            </header>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 selectcateselectpadding">
                        <div className="fl_right">
                          <select
                            className="selectcateselect"
                            value={this.state.dropDownValue}
                            disabled
                          >
                            <option
                              value="GUMASTA_SolePI_Documents"
                              className="selectcateselectoption"
                            >
                              Sole Proprietorship / Individual
                            </option>
                            <option
                              value="GUMASTA_P_LLP_Documents"
                              className="selectcateselectoption"
                            >
                              Partnership / LLP
                            </option>
                            <option
                              value="GUMASTA_PRL_PL_OP_Documents"
                              className="selectcateselectoption"
                            >
                              Private limited / Public limited / One person
                              company
                            </option>
                            <option
                              value="GUMASTA_HUF_Documents"
                              className="selectcateselectoption"
                            >
                              HUF
                            </option>
                            <option
                              value="GUMASTA_STC_Documents"
                              className="selectcateselectoption"
                            >
                              Society / Trust / Club
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tabs-content">
                  <div id="tab1" className="tab-content">
                    <div className="row">
                      <div className="col-lg-9">
                      <div className="mainsectionuploadsection">
                        <div className="gsrtf-select-img">
                          <center>
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/Group_3582.svg"
                              }
                              className="selectcategoryimg"
                            />
                          </center>
                        </div>
                        <div className="gsrtf-main-content">
                          {/* Sole */}
                          <div className="uploadscrolldiv" id="gumasta_C_SolePI">
                            <div className="uploadscrollcardinside">

                              <div className="displayflex" id="SPic">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Passport size Photograph of the proprietor
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.Pic1Link}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="SPicseebtn"
                                          onClick={
                                            this.handleSPicseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className=" hidecommentshistorybtn "
                                          id="SPichidecommentsbtn"
                                          onClick={
                                            this.handleSPichidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* SAadhar */}
                              <div className="displayflex" id="SAadhar">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Photo Identity Proof of Applicant (Voter Id Card/Passport/Pan Card/Driving License)
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.Aadhar1Link}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="SAadharseebtn"
                                          onClick={
                                            this.handleSAadharseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className=" hidecommentshistorybtn "
                                          id="SAadharhidecommentsbtn"
                                          onClick={
                                            this.handleSAadharhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* SPan */}
                              <div className="displayflex" id="SPan">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Photograph of Shop with name of shop/office clearly visible
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.Pan1Link}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="SPanseebtn"
                                          onClick={
                                            this.handleSPanseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className=" hidecommentshistorybtn "
                                          id="SPanhidecommentsbtn"
                                          onClick={
                                            this.handleSPanhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>


                              <div className="displayflex" >
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <p className="uploadnormaltext">
                                    Nature of your Business / Brand:-
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <textarea
                                    className="uploadtextarea"
                                    value={this.state.businessNature}
                                    disabled
                                  ></textarea>
                                </div>
                              </div>

                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <p className="uploadnormaltext">
                                    Residential address proof:
                                    <sup className="requireddocstar">*</sup>
                                  </p>

                                  <input
                                    type="radio"
                                    id="test11-11"
                                    name="radio-group11"
                                    value="CopyOfRegistry"
                                    disabled
                                    checked={
                                      this.state.RAddressProofType ===
                                      "CopyOfRegistry"
                                    }
                                  />
                                  <label
                                    htmlFor="test11-11"
                                    className="uploadradiotext"
                                  >
                                    Own House
                                  </label>
                                  <br />
                                  <input
                                    type="radio"
                                    id="test11-21"
                                    name="radio-group11"
                                    value="ResidentialRentAgreement"
                                    disabled
                                    checked={
                                      this.state.RAddressProofType ===
                                      "ResidentialRentAgreement"
                                    }
                                  />
                                  <label
                                    htmlFor="test11-21"
                                    className="uploadradiotext"
                                  >
                                    Rented House
                                  </label>
                                </div>
                              </div>

                              <div className="displayflex" id="SRCopyOfRegistry">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Ration Card/Electricity Bill/Telephone Bill/LPG Connection Document
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={
                                                this.state.RCopyOfRegistryLink
                                              }
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="SRCopyOfRegistryseebtn"
                                          onClick={
                                            this
                                              .handleSRCopyOfRegistryseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className=" hidecommentshistorybtn "
                                          id="SRCopyOfRegistryhidecommentsbtn"
                                          onClick={
                                            this
                                              .handleSRCopyOfRegistryhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="displayflex" id="SRRentAgreement">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Rental Agreement / NOC from Any Relative and Ownership Electricity bill
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={
                                                this.state.RRentAgreementLink
                                              }
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="SRRentAgreementseebtn"
                                          onClick={
                                            this
                                              .handleSRRentAgreementseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className=" hidecommentshistorybtn "
                                          id="SRRentAgreementhidecommentsbtn"
                                          onClick={
                                            this
                                              .handleSRRentAgreementhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <p className="uploadnormaltext">
                                    Office address proof:
                                    <sup className="requireddocstar">*</sup>
                                  </p>

                                  <input
                                    type="radio"
                                    id="test1-1"
                                    name="radio-group1"
                                    value="OwnershipDeed"
                                    disabled
                                    checked={
                                      this.state.AddressProofType ===
                                      "OwnershipDeed"
                                    }
                                  />
                                  <label
                                    htmlFor="test1-1"
                                    className="uploadradiotext"
                                  >
                                    Own office
                                  </label>
                                  <br />
                                  <input
                                    type="radio"
                                    id="test1-2"
                                    name="radio-group1"
                                    value="RentAgreement"
                                    disabled
                                    checked={
                                      this.state.AddressProofType ===
                                      "RentAgreement"
                                    }
                                  />
                                  <label
                                    htmlFor="test1-2"
                                    className="uploadradiotext"
                                  >
                                    Rented office
                                  </label>
                                </div>
                              </div>

                              <div className="displayflex" id="SCopyOfRegistry">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Electricity bill/Telephone bill
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={
                                                this.state.CopyOfRegistryLink
                                              }
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="SCopyOfRegistryseebtn"
                                          onClick={
                                            this
                                              .handleSCopyOfRegistryseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className=" hidecommentshistorybtn "
                                          id="SCopyOfRegistryhidecommentsbtn"
                                          onClick={
                                            this
                                              .handleSCopyOfRegistryhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="displayflex" id="SRentAgreement">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Rental Agreement / NOC from Any Relative and Ownership Electricity bill
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={
                                                this.state.RentAgreementLink
                                              }
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="SRentAgreementseebtn"
                                          onClick={
                                            this
                                              .handleSRentAgreementseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className=" hidecommentshistorybtn "
                                          id="SRentAgreementhidecommentsbtn"
                                          onClick={
                                            this
                                              .handleSRentAgreementhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>


                              <div
                                className="displayflex"
                                id="additionalDocumentDiv1"
                              >
                                <div className="uploadadditionaldatacol">
                                  <p className="uploadadditionalnormaltext">
                                    Additional documents:-
                                  </p>
                                  {this.AdditionalFilesArea(
                                    "GUMASTA_SolePI_Documents"
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Partnership */}
                          <div className="uploadscrolldiv" id="gumasta_C_P_LLP">
                            <div className="uploadscrollcardinside">
                              <div className="displayflex" id="PPic1">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Passport size Photograph (Partner 1)
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.Pic1Link}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PPic1seebtn"
                                          onClick={
                                            this.handlePPic1seecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PPic1hidecommentsbtn"
                                          onClick={
                                            this.handlePPic1hidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="displayflex" id="PPic2">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Passport size Photograph (Partner 2)
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.Pic2Link}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PPic2seebtn"
                                          onClick={
                                            this.handlePPic2seecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PPic2hidecommentsbtn"
                                          onClick={
                                            this.handlePPic2hidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="displayflex" id="PAadhar1">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Photo Identity Proof of Applicant (Voter Id Card/Passport/Pan Card/Driving License) (Partner 1)
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.Aadhar1Link}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PAadhar1seebtn"
                                          onClick={
                                            this.handlePAadhar1seecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PAadhar1hidecommentsbtn"
                                          onClick={
                                            this.handlePAadhar1hidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="displayflex" id="PAadhar2">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Photo Identity Proof of Applicant (Voter Id Card/Passport/Pan Card/Driving License) (Partner 2)
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.Aadhar2Link}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PAadhar2seebtn"
                                          onClick={
                                            this.handlePAadhar2seecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PAadhar2hidecommentsbtn"
                                          onClick={
                                            this.handlePAadhar2hidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="displayflex" id="PPan1">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Photograph of Shop with name of shop/office clearly visible
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.Pan1Link}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PPan1seebtn"
                                          onClick={
                                            this.handlePPan1seecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PPan1hidecommentsbtn"
                                          onClick={
                                            this.handlePPan1hidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="displayflex" id="PPan2">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Partnership Deed
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.Pan2Link}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PPan2seebtn"
                                          onClick={
                                            this.handlePPan2seecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PPan2hidecommentsbtn"
                                          onClick={
                                            this.handlePPan2hidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <p className="uploadnormaltext">
                                    Nature of your Business / Brand:-
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <textarea
                                    className="uploadtextarea"
                                    value={this.state.businessNature}
                                    disabled
                                  ></textarea>
                                </div>
                              </div>

                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <p className="uploadnormaltext">
                                    Residential address proof:
                                    <sup className="requireddocstar">*</sup>
                                  </p>

                                  <input
                                    type="radio"
                                    id="test21-11"
                                    name="radio-group22"
                                    value="CopyOfRegistry"
                                    checked={
                                      this.state.RAddressProofType ===
                                      "CopyOfRegistry"
                                    }
                                    disabled
                                  />
                                  <label
                                    htmlFor="test21-11"
                                    className="uploadradiotext"
                                  >
                                    Own house
                                  </label>
                                  <br />
                                  <input
                                    type="radio"
                                    id="test21-21"
                                    name="radio-group22"
                                    value="ResidentialRentAgreement"
                                    checked={
                                      this.state.RAddressProofType ===
                                      "ResidentialRentAgreement"
                                    }
                                    disabled
                                  />
                                  <label
                                    htmlFor="test21-21"
                                    className="uploadradiotext"
                                  >
                                    Rented house
                                  </label>
                                </div>
                              </div>
                              <div className="displayflex" id="PRCopyOfRegistry">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Ration Card/Electricity Bill/Telephone Bill/LPG Connection Document
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={
                                                this.state.RCopyOfRegistryLink
                                              }
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PRCopyOfRegistryseebtn"
                                          onClick={
                                            this
                                              .handlePRCopyOfRegistryseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className=" hidecommentshistorybtn "
                                          id="PRCopyOfRegistryhidecommentsbtn"
                                          onClick={
                                            this
                                              .handlePRCopyOfRegistryhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="displayflex" id="PRRentAgreement">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Rental Agreement / NOC from Any Relative and Ownership Electricity bill
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={
                                                this.state.RRentAgreementLink
                                              }
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PRRentAgreementseebtn"
                                          onClick={
                                            this
                                              .handlePRRentAgreementseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className=" hidecommentshistorybtn "
                                          id="PRRentAgreementhidecommentsbtn"
                                          onClick={
                                            this
                                              .handlePRRentAgreementhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <p className="uploadnormaltext">
                                    Office address proof:
                                    <sup className="requireddocstar">*</sup>
                                  </p>

                                  <input
                                    type="radio"
                                    id="test2-1"
                                    name="radio-group2"
                                    value="OwnershipDeed"
                                    checked={
                                      this.state.AddressProofType ===
                                      "OwnershipDeed"
                                    }
                                    disabled
                                  />
                                  <label
                                    htmlFor="test2-1"
                                    className="uploadradiotext"
                                  >
                                    Own office
                                  </label>
                                  <br />
                                  <input
                                    type="radio"
                                    id="test2-2"
                                    name="radio-group2"
                                    value="RentAgreement"
                                    checked={
                                      this.state.AddressProofType ===
                                      "RentAgreement"
                                    }
                                    disabled
                                  />
                                  <label
                                    htmlFor="test2-2"
                                    className="uploadradiotext"
                                  >
                                    Rented office
                                  </label>
                                </div>
                              </div>
                              <div className="displayflex" id="PCopyOfRegistry">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Electricity bill/Telephone bill
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={
                                                this.state.CopyOfRegistryLink
                                              }
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PCopyOfRegistryseebtn"
                                          onClick={
                                            this
                                              .handlePCopyOfRegistryseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className=" hidecommentshistorybtn "
                                          id="PCopyOfRegistryhidecommentsbtn"
                                          onClick={
                                            this
                                              .handlePCopyOfRegistryhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="displayflex" id="PRentAgreement">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Rental Agreement / NOC from Any Relative and Ownership Electricity bill
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={
                                                this.state.RentAgreementLink
                                              }
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PRentAgreementseebtn"
                                          onClick={
                                            this
                                              .handlePRentAgreementseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className=" hidecommentshistorybtn "
                                          id="PRentAgreementhidecommentsbtn"
                                          onClick={
                                            this
                                              .handlePRentAgreementhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="displayflex"
                                id="additionalDocumentDiv2"
                              >
                                <div className="uploadadditionaldatacol">
                                  <p className="uploadadditionalnormaltext">
                                    Additional documents:-
                                  </p>
                                  {this.AdditionalFilesArea(
                                    "GUMASTA_P_LLP_Documents"
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* PL */}
                          <div className="uploadscrolldiv" id="gumasta_C_PL">
                            <div className="uploadscrollcardinside">

                              <div className="displayflex" id="PLPic1">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Passport size Photograph (Director 1)
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.Pic1Link}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PLPic1seebtn"
                                          onClick={
                                            this.handlePLPic1seecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PLPic1hidecommentsbtn"
                                          onClick={
                                            this.handlePLPic1hidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="PLPic2">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Passport size Photograph (Director 2)
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.Pic2Link}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PLPic2seebtn"
                                          onClick={
                                            this.handlePLPic2seecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PLPic2hidecommentsbtn"
                                          onClick={
                                            this.handlePLPic2hidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="PLAadhar1">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Photo Identity Proof of Applicant (Voter Id Card/Passport/Pan Card/Driving License) (Director 1)
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.Aadhar1Link}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PLAadhar1seebtn"
                                          onClick={
                                            this.handlePLAadhar1seecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PLAadhar1hidecommentsbtn"
                                          onClick={
                                            this.handlePLAadhar1hidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="PLAadhar2">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Photo Identity Proof of Applicant (Voter Id Card/Passport/Pan Card/Driving License) (Director 2)
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.Aadhar2Link}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PLAadhar2seebtn"
                                          onClick={
                                            this.handlePLAadhar2seecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PLAadhar2hidecommentsbtn"
                                          onClick={
                                            this.handlePLAadhar2hidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="PLPan3">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Photograph of Shop with name of shop/office clearly visible
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.Pan3Link}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PLPan3seebtn"
                                          onClick={
                                            this.handlePLPan3seecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PLPan3hidecommentsbtn"
                                          onClick={
                                            this.handlePLPan3hidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="PLCI">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Certificate of Incorporation
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.CILink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PLCIseebtn"
                                          onClick={
                                            this.handlePLCIseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PLCIhidecommentsbtn"
                                          onClick={
                                            this.handlePLCIhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="PLMOA">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        MOA
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.MOALink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PLMOAseebtn"
                                          onClick={
                                            this.handlePLMOAseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PLMOAhidecommentsbtn"
                                          onClick={
                                            this.handlePLMOAhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="PLAOA">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        AOA
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.AOALink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PLAOAseebtn"
                                          onClick={
                                            this.handlePLAOAseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PLAOAhidecommentsbtn"
                                          onClick={
                                            this.handlePLAOAhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <p className="uploadnormaltext">
                                    Nature of your Business / Brand:-
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <textarea
                                    className="uploadtextarea"
                                    value={this.state.businessNature}
                                    disabled
                                  ></textarea>
                                </div>
                              </div>

                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <p className="uploadnormaltext">
                                    Residential address proof:
                                    <sup className="requireddocstar">*</sup>
                                  </p>

                                  <input
                                    type="radio"
                                    id="test31-11"
                                    name="radio-group33"
                                    value="CopyOfRegistry"
                                    disabled
                                    checked={
                                      this.state.RAddressProofType ===
                                      "CopyOfRegistry"
                                    }
                                  />
                                  <label
                                    htmlFor="test31-11"
                                    className="uploadradiotext"
                                  >
                                    Own House
                                  </label>
                                  <br />
                                  <input
                                    type="radio"
                                    id="test31-21"
                                    name="radio-group33"
                                    value="RentAgreement"
                                    disabled
                                    checked={
                                      this.state.RAddressProofType ===
                                      "RentAgreement"
                                    }
                                  />
                                  <label
                                    htmlFor="test3-2"
                                    className="uploadradiotext"
                                  >
                                    Rented Houze
                                  </label>
                                </div>
                              </div>

                              <div className="displayflex" id="PLRCopyOfRegistry">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Ration Card/Electricity Bill/Telephone Bill/LPG Connection Document
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={
                                                this.state.RCopyOfRegistryLink
                                              }
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PLRCopyOfRegistryseebtn"
                                          onClick={
                                            this
                                              .handlePLRCopyOfRegistryseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PLRCopyOfRegistryhidecommentsbtn"
                                          onClick={
                                            this
                                              .handlePLRCopyOfRegistryhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="PLRRentAgreement">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Rental Agreement / NOC from Any Relative and Ownership Electricity bill
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={
                                                this.state.RRentAgreementLink
                                              }
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PLRRentAgreementseebtn"
                                          onClick={
                                            this
                                              .handlePLRRentAgreementseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PLRRentAgreementhidecommentsbtn"
                                          onClick={
                                            this
                                              .handlePLRRentAgreementhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>


                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <p className="uploadnormaltext">
                                    Office address proof:
                                    <sup className="requireddocstar">*</sup>
                                  </p>

                                  <input
                                    type="radio"
                                    id="test3-1"
                                    name="radio-group3"
                                    value="OwnershipDeed"
                                    disabled
                                    checked={
                                      this.state.AddressProofType ===
                                      "OwnershipDeed"
                                    }
                                  />
                                  <label
                                    htmlFor="test3-1"
                                    className="uploadradiotext"
                                  >
                                    Own office
                                  </label>
                                  <br />
                                  <input
                                    type="radio"
                                    id="test3-2"
                                    name="radio-group3"
                                    value="RentAgreement"
                                    disabled
                                    checked={
                                      this.state.AddressProofType ===
                                      "RentAgreement"
                                    }
                                  />
                                  <label
                                    htmlFor="test3-2"
                                    className="uploadradiotext"
                                  >
                                    Rented office
                                  </label>
                                </div>
                              </div>

                              <div className="displayflex" id="PLCopyOfRegistry">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Electricity bill/Telephone bill
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={
                                                this.state.CopyOfRegistryLink
                                              }
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PLCopyOfRegistryseebtn"
                                          onClick={
                                            this
                                              .handlePLCopyOfRegistryseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PLCopyOfRegistryhidecommentsbtn"
                                          onClick={
                                            this
                                              .handlePLCopyOfRegistryhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="PLRentAgreement">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Rental Agreement / NOC from Any Relative and Ownership Electricity bill
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={
                                                this.state.RentAgreementLink
                                              }
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="PLRentAgreementseebtn"
                                          onClick={
                                            this
                                              .handlePLRentAgreementseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="PLRentAgreementhidecommentsbtn"
                                          onClick={
                                            this
                                              .handlePLRentAgreementhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>


                              <div
                                className="displayflex"
                                id="additionalDocumentDiv3"
                              >
                                <div className="uploadadditionaldatacol">
                                  <p className="uploadadditionalnormaltext">
                                    Additional documents:-
                                  </p>
                                  {this.AdditionalFilesArea(
                                    "GUMASTA_PRL_PL_OP_Documents"
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* HUF */}
                          <div className="uploadscrolldiv" id="gumasta_C_HUF">
                            <div className="uploadscrollcardinside">
                              <div className="displayflex" id="HUFPic1">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Passport size Photo of Applicant
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.Pic1Link}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="HUFPic1seebtn"
                                          onClick={
                                            this.handleHUFPic1seecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="HUFPic1hidecommentsbtn"
                                          onClick={
                                            this.handleHUFPic1hidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="HUFAadhar1">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Photo Identity Proof of karta (Voter Id Card/Passport/Pan Card/Driving License)
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.Aadhar1Link}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="HUFAadhar1seebtn"
                                          onClick={
                                            this.handleHUFAadhar1seecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="HUFAadhar1hidecommentsbtn"
                                          onClick={
                                            this.handleHUFAadhar1hidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="HUFDeed">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        HUF Deed Document
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.DeedLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="HUFDeedseebtn"
                                          onClick={
                                            this.handleHUFDeedseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="HUFDeedhidecommentsbtn"
                                          onClick={
                                            this.handleHUFDeedhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="HUFBP">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Photograph of Shop with name of shop/office clearly visible
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.BPLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="HUFBPseebtn"
                                          onClick={
                                            this.handleHUFBPseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="HUFBPhidecommentsbtn"
                                          onClick={
                                            this.handleHUFBPhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <p className="uploadnormaltext">
                                    Nature of your Business / Brand:-
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <textarea
                                    className="uploadtextarea"
                                    value={this.state.businessNature}
                                    disabled
                                  ></textarea>
                                </div>
                              </div>


                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <p className="uploadnormaltext">
                                    Residential address proof:
                                    <sup className="requireddocstar">*</sup>
                                  </p>

                                  <input
                                    type="radio"
                                    id="test41-11"
                                    name="radio-group44"
                                    value="CopyOfRegistry"
                                    disabled
                                    checked={
                                      this.state.RAddressProofType ===
                                      "CopyOfRegistry"
                                    }
                                  />
                                  <label
                                    htmlFor="test41-11"
                                    className="uploadradiotext"
                                  >
                                    Own house
                                  </label>
                                  <br />
                                  <input
                                    type="radio"
                                    id="test41-21"
                                    name="radio-group44"
                                    value="RentAgreement"
                                    disabled
                                    checked={
                                      this.state.RAddressProofType ===
                                      "RentAgreement"
                                    }
                                  />
                                  <label
                                    htmlFor="test4-2"
                                    className="uploadradiotext"
                                  >
                                    Rented house
                                  </label>
                                </div>
                              </div>

                              <div className="displayflex" id="HUFRCopyOfRegistry" >
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Ration Card/Electricity Bill/Telephone Bill/LPG Connection Document
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={
                                                this.state.RCopyOfRegistryLink
                                              }
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="HUFRCopyOfRegistryseebtn"
                                          onClick={
                                            this
                                              .handleHUFRCopyOfRegistryseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="HUFRCopyOfRegistryhidecommentsbtn"
                                          onClick={
                                            this
                                              .handleHUFRCopyOfRegistryhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="HUFRRentAgreement" >
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Rental Agreement / NOC from Any Relative and Ownership Electricity bill
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={
                                                this.state.RRentAgreementLink
                                              }
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="HUFRRentAgreementseebtn"
                                          onClick={
                                            this
                                              .handleHUFRentAgreementseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="HUFRRentAgreementhidecommentsbtn"
                                          onClick={
                                            this
                                              .handleHUFRRentAgreementhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>


                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <p className="uploadnormaltext">
                                    Office address proof:
                                    <sup className="requireddocstar">*</sup>
                                  </p>

                                  <input
                                    type="radio"
                                    id="test4-1"
                                    name="radio-group4"
                                    value="OwnershipDeed"
                                    disabled
                                    checked={
                                      this.state.AddressProofType ===
                                      "OwnershipDeed"
                                    }
                                  />
                                  <label
                                    htmlFor="test4-1"
                                    className="uploadradiotext"
                                  >
                                    Own office
                                  </label>
                                  <br />
                                  <input
                                    type="radio"
                                    id="test4-2"
                                    name="radio-group4"
                                    value="RentAgreement"
                                    disabled
                                    checked={
                                      this.state.AddressProofType ===
                                      "RentAgreement"
                                    }
                                  />
                                  <label
                                    htmlFor="test4-2"
                                    className="uploadradiotext"
                                  >
                                    Rented office
                                  </label>
                                </div>
                              </div>

                              <div className="displayflex" id="HUFCopyOfRegistry">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Electricity bill/Telephone bill
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={
                                                this.state.CopyOfRegistryLink
                                              }
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="HUFCopyOfRegistryseebtn"
                                          onClick={
                                            this
                                              .handleHUFCopyOfRegistryseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="HUFCopyOfRegistryhidecommentsbtn"
                                          onClick={
                                            this
                                              .handleHUFCopyOfRegistryhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="HUFRentAgreement"
                              >
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Rental Agreement / NOC from Any Relative and Ownership Electricity bill
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={
                                                this.state.RentAgreementLink
                                              }
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="HUFRentAgreementseebtn"
                                          onClick={
                                            this
                                              .handleHUFRentAgreementseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="HUFRentAgreementhidecommentsbtn"
                                          onClick={
                                            this
                                              .handleHUFRentAgreementhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>


                              <div
                                className="displayflex"
                                id="additionalDocumentDiv4"
                              >
                                <div className="uploadadditionaldatacol">
                                  <p className="uploadadditionalnormaltext">
                                    Additional documents:-
                                  </p>
                                  {this.AdditionalFilesArea(
                                    "GUMASTA_HUF_Documents"
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Society */}
                          <div className="uploadscrolldiv" id="gumasta_C_Society">
                            <div className="uploadscrollcardinside">

                              <div className="displayflex" id="STPic1">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Passport size Photo (President or
                                        Secretary) (1)
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.Pic1Link}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="STPic1seebtn"
                                          onClick={
                                            this.handleSTPic1seecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="STPic1hidecommentsbtn"
                                          onClick={
                                            this.handleSTPic1hidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="STPic2">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Passport size Photo (President or
                                        Secretary) (2)
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.Pic2Link}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="STPic2seebtn"
                                          onClick={
                                            this.handleSTPic2seecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="STPic2hidecommentsbtn"
                                          onClick={
                                            this.handleSTPic2hidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="STAadhar1">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Photo Identity Proof of Applicant (Voter Id Card/Passport/Pan Card/Driving License) (President or Secretary) (1)
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.Aadhar1Link}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="STAadhar1seebtn"
                                          onClick={
                                            this.handleSTAadhar1seecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="STAadhar1hidecommentsbtn"
                                          onClick={
                                            this.handleSTAadhar1hidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="STAadhar2">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Photo Identity Proof of Applicant (Voter Id Card/Passport/Pan Card/Driving License) (President or Secretary) (2)
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.Aadhar2Link}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="STAadhar2seebtn"
                                          onClick={
                                            this.handleSTAadhar2seecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="STAadhar2hidecommentsbtn"
                                          onClick={
                                            this.handleSTAadhar2hidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="STPan3">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Photograph of Shop with name of shop/office clearly visible
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.Pan3Link}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="STPan3seebtn"
                                          onClick={
                                            this.handleSTPan3seecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="STPan3hidecommentsbtn"
                                          onClick={
                                            this.handleSTPan3hidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="STCR">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Society Registration Certificate
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={this.state.CRLink}
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="STCRseebtn"
                                          onClick={
                                            this.handleSTCRseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="STCRhidecommentsbtn"
                                          onClick={
                                            this.handleSTCRhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <p className="uploadnormaltext">
                                    Nature of your Business / Brand:-
                                    <sup className="requireddocstar">*</sup>
                                  </p>
                                  <textarea
                                    className="uploadtextarea"
                                    value={this.state.businessNature}
                                    disabled
                                  ></textarea>
                                </div>
                              </div>

                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <p className="uploadnormaltext">
                                    Residential address proof:
                                    <sup className="requireddocstar">*</sup>
                                  </p>

                                  <input
                                    type="radio"
                                    id="test51-11"
                                    name="radio-group55"
                                    value="CopyOfRegistry"
                                    disabled
                                    checked={
                                      this.state.RAddressProofType ===
                                      "CopyOfRegistry"
                                    }
                                  />
                                  <label
                                    htmlFor="test51-11"
                                    className="uploadradiotext"
                                  >
                                    Own house
                                  </label>
                                  <br />
                                  <input
                                    type="radio"
                                    id="test51-21"
                                    name="radio-group55"
                                    value="ResidentialRentAgreement"
                                    disabled
                                    checked={
                                      this.state.RAddressProofType ===
                                      "ResidentialRentAgreement"
                                    }
                                  />
                                  <label
                                    htmlFor="test51-21"
                                    className="uploadradiotext"
                                  >
                                    Rented House
                                  </label>
                                </div>
                              </div>

                              <div className="displayflex" id="STRCopyOfRegistry">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Ration Card/Electricity Bill/Telephone Bill/LPG Connection Document
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={
                                                this.state.RCopyOfRegistryLink
                                              }
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="STRCopyOfRegistryseebtn"
                                          onClick={
                                            this
                                              .handleSTRCopyOfRegistryseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="STRCopyOfRegistryhidecommentsbtn"
                                          onClick={
                                            this
                                              .handleSTRCopyOfRegistryhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="STRRentAgreement">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Rental Agreement / NOC from Any Relative and Ownership Electricity bill
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={
                                                this.state.RRentAgreementLink
                                              }
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="STRRentAgreementseebtn"
                                          onClick={
                                            this
                                              .handleSTRRentAgreementseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="STRRentAgreementhidecommentsbtn"
                                          onClick={
                                            this
                                              .handleSTRRentAgreementhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <p className="uploadnormaltext">
                                    Office address proof:
                                    <sup className="requireddocstar">*</sup>
                                  </p>

                                  <input
                                    type="radio"
                                    id="test5-1"
                                    name="radio-group5"
                                    value="OwnershipDeed"
                                    disabled
                                    checked={
                                      this.state.AddressProofType ===
                                      "OwnershipDeed"
                                    }
                                  />
                                  <label
                                    htmlFor="test5-1"
                                    className="uploadradiotext"
                                  >
                                    Own office
                                  </label>
                                  <br />
                                  <input
                                    type="radio"
                                    id="test5-2"
                                    name="radio-group5"
                                    value="RentAgreement"
                                    disabled
                                    checked={
                                      this.state.AddressProofType ===
                                      "RentAgreement"
                                    }
                                  />
                                  <label
                                    htmlFor="test5-2"
                                    className="uploadradiotext"
                                  >
                                    Rented office
                                  </label>
                                </div>
                              </div>

                              <div className="displayflex" id="STCopyOfRegistry">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Electricity bill/Telephone bill
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={
                                                this.state.CopyOfRegistryLink
                                              }
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="STCopyOfRegistryseebtn"
                                          onClick={
                                            this
                                              .handleSTCopyOfRegistryseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="STCopyOfRegistryhidecommentsbtn"
                                          onClick={
                                            this
                                              .handleSTCopyOfRegistryhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="displayflex" id="STRentAgreement">
                                <div className="uploadnocol">
                                  <p className="uploadnormaltext documentno"></p>
                                </div>
                                <div className="uploaddatacol">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <p className="uploadnormaltext">
                                        Rental Agreement / NOC from Any Relative and Ownership Electricity bill
                                        <sup className="requireddocstar">*</sup>
                                      </p>
                                    </div>
                                    <div className="col-md-7">
                                      <div className="fl_right">
                                        <div className="displayflex">
                                          <div className="viewbtndivpadding">
                                            <a
                                              className="viewbtn"
                                              href={
                                                this.state.RentAgreementLink
                                              }
                                              target="_blank"
                                            >
                                              View{" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/Path 19498.svg"
                                                }
                                              />
                                            </a>
                                          </div>
                                          <div>
                                            <button className="tick_btn">
                                              {" "}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/img/tick.svg"
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>
                                        <button
                                          className="seecommentshistorybtn commentsbtn"
                                          id="STRentAgreementseebtn"
                                          onClick={
                                            this
                                              .handleSTRentAgreementseecommentsbtn
                                          }
                                        >
                                          See comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>

                                        <button
                                          className="hidecommentshistorybtn"
                                          id="STRentAgreementhidecommentsbtn"
                                          onClick={
                                            this
                                              .handleSTRentAgreementhidecommentsbtn
                                          }
                                        >
                                          Hide comment history{" "}
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/img/See_comment_history.svg"
                                            }
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="displayflex"
                                id="additionalDocumentDiv5"
                              >
                                <div className="uploadadditionaldatacol">
                                  <p className="uploadadditionalnormaltext">
                                    Additional documents:-
                                  </p>
                                  {this.AdditionalFilesArea(
                                    "GUMASTA_STC_Documents"
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
</div>
<div className="closedservicesection">
                        <center>
                        <br/>
                          <br/>
                          <br/>
                          <p className="closedstatustext"><span>Service ID</span>:- {this.state.serviceId}</p>
                          <br/>
                          <p className="closedstatusdesc">
                            Dear customer, your service is marked as
                            completed/closed because of following status.
                          </p>
                          <p  className="closedstatustext"><span>Status</span>:- Unapproved</p>
                          <img
                            src={process.env.PUBLIC_URL + "/img/warning.png"}
                            className="warningerrorimg"
                            alt="image"
                          />
                          <br/>    <br/>
                          <p className="closedstatusdesc2">
                            Please contact your allocated support executive or
                            call on 77728-77729 for support.
                          </p>
                          </center>
                        </div>
                      </div>

                      <div className="col-lg-3 colbtwborder">
                        <div className="commentimg-section2">
                          <SupportDetails name={this.state.Name} role={"Relationship Manager"} contact={this.state.Contact} />
                          {supportName != "" && supportContact != "" ?

                            <div className='supportDetails'>
                              <img
                                src={process.env.PUBLIC_URL + "/img/24-7_Support.png"}

                                alt="image"
                              />
                              <div>
                                <h5>{supportName}</h5>
                                <p>Agent Support Executive</p>
                                <span><b>Contact: </b> +91 {supportContact}</span>
                                <p className='mb-1'><b>Working Hours: </b> 11:00 AM - 06:30 PM</p>
                              </div>
                            </div>

                            : null}
                          <div className="mm">
                            <center>
                              <a
                                className="video-btn"
                                data-toggle="modal"
                                data-src="https://www.youtube.com/embed/fiC-nHoFJEY"
                                data-target="#youtubeModal"
                              >
                                <img
                                  src={process.env.PUBLIC_URL + "/img/play.jpg"}
                                  id="play"
                                  className="play_img play_img2"
                                  alt="image"
                                />
                              </a>
                            </center>
                          </div>

                          <p class="uploadinstr mt-2 uploddoc">You can also upload documents using Whatsapp on <a
                                  href="https://web.whatsapp.com/send?phone=917772877729&amp;text=Hello%20Team%20Legal251!"
                                  target="_blank"
                                >
                                  {" "}
                                  <img
                                    src={process.env.PUBLIC_URL + "/img/call.svg"}
                                    alt="call"
                                    className="callimg"
                                  />
                                </a> +91 77728-77729</p>
                        </div>
                        <div className="TDRightSide">
                          <div className="convheader2">
                            <div>
                              <img
                                src={process.env.PUBLIC_URL + "/img/person.png"}
                                className="converpersonimg"
                              />
                            </div>
                            <div className="convheadercontdiv">
                              <p className="convservicename">{this.state.Name}</p>
                              <p className="convpersonname">Relationship Manager</p>
                              <p className="convuniquename">
                                Contact: <span style={{ color: "#2d2d2d" }}>{this.state.Contact}</span></p>
                              <p className="convuniquename">
                                Service ID : <span className="convuniquenamespan">{Decserviceid}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="convmaindiv">
                            {this.renderCommenthistory()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="tab2" className="tab-content">
                    <div className="doucumainscrolldiv2">
                      <div className="doucumainscrolldivinside2">
                        {this.showDeliveredDocuments()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SupportDetailsMobile name={this.state.Name} role={"Relationship Manager"} contact={this.state.Contact} />

          <Footer />
          <div className="modal fade"
            id="timeline"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true">

            <div
              className="modal-dialog modal-dialog-centered yt_modal "
              role="document"
            >
              <div className="modal-content videoModal">
                <div className="modal-body modal_b mt-4 ">
                  <button
                    type="button"
                    className="close close1 closeMain mr-2 dbtime"
                    data-dismiss="modal"
                    aria-label="Close"

                  >
                    <span aria-hidden="true">&times;</span>
                  </button>

                  {this.state.Timelinedata ?


                    this.state.Timeline.length > 0 ?

                      <div class="timeline scrollbtimel">
                        <div class="container">
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="timeline-container">
                                <div class="timeline-end">
                                  <button id="load-more" onClick={this.handlemoretimeline} >More..</button>
                                </div>
                                <div class="timeline-continue">

                                  {this.state.filtertimel.map((item, index) => {

                                    if (index % 2 == 0) {
                                      return (

                                        <div class="row timeline-left">
                                          <div class="col-md-6 d-md-none d-block">
                                            <p class="timeline-date">

                                            </p>
                                          </div>
                                          <div class="col-md-6">
                                            <div class="timeline-box">
                                              <div class="timeline-icon d-md-none d-block">
                                                <i class="fa fa-business-time"></i>
                                              </div>

                                              <div class="timeline-text">
                                                <p class="mainSpan">{item.time}</p>
                                                <h3>{item.timeLine}</h3>
                                                <p class="mode_p">Date : <span class="mode_span"> {item.date}</span></p>
                                                <h5 className="tlremark">{item.remark}</h5>
                                                {/* <p class="mode_p">
                                                      <span class="mode_span">
                                                          {{$TimeLine->TimeLine}}
                                                      </span>
                                                  </p>  */}


                                              </div>

                                              <div class="timeline-icon d-md-block d-none">
                                                <i class="fa fa-business-time"></i>
                                              </div>

                                            </div>
                                          </div>
                                          <div class="col-md-6 d-md-block d-none">
                                            <p class="timeline-date">

                                            </p>
                                          </div>
                                        </div>

                                      )
                                    } else {
                                      return (
                                        <div class="row timeline-right">
                                          <div class="col-md-6">
                                            <p class="timeline-date">
                                            </p>
                                          </div>
                                          <div class="col-md-6">
                                            <div class="timeline-box">
                                              <div class="timeline-icon">
                                                <i class="fa fa-gift"></i>
                                              </div>

                                              <div class="timeline-text">
                                                <p class="mainSpan">{item.time}</p>
                                                {/* <p class="">khkh</p> */}

                                                <h3>{item.timeLine}</h3>
                                                <p class="mode_p">Date : <span class="mode_span">{item.date} </span></p>
                                                <h5 className="tlremark">{item.remark}</h5>
                                                {/* <p class="mode_p">
                                                                              <span class="mode_span">
                                                                                  
                                                                              </span>
                                                                          </p> */}


                                              </div>

                                            </div>
                                          </div>
                                        </div>
                                      )
                                    }




                                  })}




                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      <center className="">
                        <img
                          alt="loading..."
                          src={process.env.PUBLIC_URL + "/img/quiteempty.svg"}
                          className="emptydeliveredDocuments"
                        />
                      </center>

                    :
                    <center>
                      <img
                        className="timelineloder"
                        src={process.env.PUBLIC_URL + "/img/loader.gif"}
                        alt="loading"
                      />
                    </center>


                  }

                </div>
              </div>
            </div>

          </div>
          <div className="modal fade myAddModal1">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-body uploadpopupbody">
                  <p className="sharepopupheading">
                    Please enter the recipient's mobile no.
                  </p>
                  <div className="sharepopupinputdiv">
                    <img
                      src={process.env.PUBLIC_URL + "/img/whatsapp.svg"}
                      className="docushareviawhatspopup"
                    />
                    <input
                      type="text"
                      className="sharepopupinput"
                      maxLength="10"
                      placeholder="Type here..."
                      value={this.state.whatsppcontactno}
                      onChange={this.handlewhatsppcontactno}
                    />
                    <p className="sharedocmentwhatsapperrormessage">
                      error message
                    </p>
                  </div>
                  <p className="sharepopupheading text-left">Share via:</p>
                  {/* <input
                    type="radio"
                    id="test30"
                    name="radio-group6"
                    value="Yes"
                    checked={this.state.sharewhatsapp_radio === "Yes"}
                    onChange={this.sharewhatsapp_radioChange}
                  />
                  <label htmlFor="test30" className="uploadradiotext">
                    Legal251
                  </label> */}

                  <input
                    type="radio"
                    id="test31"
                    name="radio-group6"
                    value="Personal"
                    checked={this.state.sharewhatsapp_radio === "Personal"}
                    onChange={this.sharewhatsapp_radioChange}
                  />
                  <label htmlFor="test31" className="uploadradiotext ">
                    Personal Mobile no.
                  </label>
                  <p className="sharepopupheading text-left">Share for:</p>
                  <select
                    className="selectimeperiod"
                    value={this.state.Selectedwhatsapptimeperiod}
                    onChange={this.handlewhatsapptimeperiodChange}
                  >
                    <option value="select" disabled>
                      select time period
                    </option>
                    <option value="1hr">share for 1 hour</option>
                    <option value="1day">share for 1 day</option>
                    <option value="3days">share for 3 day</option>
                    <option value="1week">share for 1 week</option>
                    <option value="1month">share for 1 month</option>
                    <option value="custom">custom</option>
                    <option value="always">lifetime</option>
                  </select>
                  <p className="sharedocmentwhatsapplifeerrormessage">
                    We don't recommend you to share documents for lifetime for
                    security reasons Instead you can select a custom time
                    period.
                  </p>
                  <p className="sharedocmentwhatsappselecterrormessage">
                    error message
                  </p>
                  <input
                    type="date"
                    id="whatsappdateinput"
                    className="othertextinput"
                    value={this.state.customwhatsappdate}
                    onChange={this.handlecustomwhatsappdateChange}
                    placeholder="Type here..."
                  />
                  <p className="sharedocmentwhatsappdateerrormessage">
                    error message
                  </p>
                  <br />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn cancelbtn_90"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20 upload_btn20_90"
                        onClick={this.submitwhatsapp}
                      >
                        Send&nbsp;
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_19481.svg"}
                          alt="download"
                        />
                      </button>
                      <button className="upload_btnloader upload_btnloader_90">
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade myAddModal2">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-body uploadpopupbody">
                  <p className="sharepopupheading">
                    Please enter the recipient's email ID
                  </p>
                  <div className="sharepopupinputdiv">
                    <img
                      src={process.env.PUBLIC_URL + "/img/Group_4339.svg"}
                      className="docushareviawhatspopup"
                    />
                    <input
                      type="text"
                      className="sharepopupinput"
                      maxLength="50"
                      placeholder="Type here..."
                      value={this.state.emailmail}
                      onChange={this.handleemailmail}
                    />
                    <p className="sharedocmentemailerrormessage">
                      error message
                    </p>
                  </div>
                  <p className="sharepopupheading text-left">Share via:</p>
                  <input
                    type="radio"
                    id="test40"
                    name="radio-group20"
                    value="Legal251"
                    checked={this.state.shareemail_radio === "Legal251"}
                    onChange={this.shareemail_radioChange}
                  />
                  <label htmlFor="test40" className="uploadradiotext">
                    Legal251
                  </label>

                  <input
                    type="radio"
                    id="test41"
                    name="radio-group20"
                    value="Personal"
                    checked={this.state.shareemail_radio === "Personal"}
                    onChange={this.shareemail_radioChange}
                  />
                  <label
                    htmlFor="test41"
                    className="uploadradiotext rightradio"
                  >
                    Personal Mail
                  </label>
                  <p className="sharepopupheading text-left">Share for:</p>
                  <select
                    className="selectimeperiod"
                    value={this.state.Selectedemailtimeperiod}
                    onChange={this.handleemailtimeperiodChange}
                  >
                    <option value="select" disabled>
                      select time period
                    </option>
                    <option value="1hr">share for 1 hour</option>
                    <option value="1day">share for 1 day</option>
                    <option value="3days">share for 3 day</option>
                    <option value="1week">share for 1 week</option>
                    <option value="1month">share for 1 month</option>
                    <option value="custom">custom</option>
                    <option value="always">lifetime</option>
                  </select>
                  <p className="sharedocmentemaillifeerrormessage">
                    We don't recommend you to share documents for lifetime for
                    security reasons Instead you can select a custom time
                    period.
                  </p>
                  <p className="sharedocmentemailselecterrormessage">
                    error message
                  </p>
                  <input
                    type="date"
                    id="emaildateinput"
                    className="othertextinput"
                    value={this.state.customemaildate}
                    onChange={this.handlecustomemaildateChange}
                    placeholder="Type here..."
                  />
                  <p className="sharedocmentemaildateerrormessage">
                    error message
                  </p>
                  <br />
                  <div className="modal_btns_div">
                    <div className="displayflex">
                      <div className="cancelbuttondiv">
                        <button
                          className="cancelbtn cancelbtn_70"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                      <button
                        className="upload_btn20 upload_btn20_70"
                        onClick={this.submitemail}
                      >
                        Send&nbsp;
                        <img
                          src={process.env.PUBLIC_URL + "/img/Path_19481.svg"}
                          alt="download"
                        />
                      </button>
                      <button className="upload_btnloader upload_btnloader_70">
                        <img
                          src={process.env.PUBLIC_URL + "/img/loader.gif"}
                          alt="loading"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade myAddModal3 ">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content uploadpopupcontent">
                <div className="modal-body uploadpopupbody">
                  <center>
                    <p className="sharesucesstext">Sent Sucessfully</p>
                    <img
                      src={process.env.PUBLIC_URL + "/img/Path_19474.svg"}
                      className="sharesucessimg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="youtubeModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered yt_modal "
              role="document"
            >
              <div className="modal-content">
                <div className="modal-body modal_b">
                  <button
                    type="button"
                    className="close close1"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      className="embed-responsive-item"
                      id="video"
                      allowscriptaccess="always"
                      src="https://www.youtube.com/embed/fiC-nHoFJEY?&amp;modestbranding=1&amp;showinfo=0"
                      allowFullScreen="allowfullscreen"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default Completegumasta;

import React, { Component } from 'react'

export class SupportDetails extends Component {
    constructor(props) {
      super(props)
    }
  render() {
    return (
      <div className='supportDetails'>
           <img
                              src={process.env.PUBLIC_URL + "/img/24-7_Support.png"}
                             
                              alt="image"
                            />
            <div>
                <h5>{this.props.name}</h5>
                <p>{this.props.role}</p>
                <span><b>Contact: </b> +91 {this.props.contact.substring(0,5)}-{this.props.contact.substring(5)}</span>
                <p className='mb-1'><b>Working Hours: </b> 10:30 AM - 07:30 PM</p>
            </div>
      </div>
    )
  }
}

export default SupportDetails